import { useContext, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '../../../../../../components/Fab';
import { IconButton } from '../../../../../../components/IconButton';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { useModal } from '../../../../../../components/ModalsProvider';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import {
  CaseReminderEventsContext,
  CreateReminderEventModal
} from '../../../../../../components/cases/reminders/events';

export const ActionsBar = ({
  width,
  middleScreenWidth,
  largeScreenWidth,
  filterOpenButton
}) => {
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { relationsForFilter, addReminderEvent } = useContext(CaseReminderEventsContext);
  const isMobile = width < middleScreenWidth;

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const addEvent = () => {
    openModal(CreateReminderEventModal, {
      payload: {
        initialValues: {
          group_id: relationsForFilter?.group_id || null
        }
      },

      onModalResolved: (reminderEvent) => {
        addReminderEvent(reminderEvent);
      }
    });
  };

  useEffect(() => {
    if (width < largeScreenWidth && viewVariant === viewVariantsMap.list) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width ]);

  return (
    <PageHeader>
      {!isMobile &&
        <PageHeaderTitle>
          Reminder Events
        </PageHeaderTitle>
      }

      <PageHeaderActions startPosition>
        <Fab
          variant="extended"
          color="primary"
          size={isMobile ? 'small' : 'medium'}
          startIcon={<AddIcon />}
          onClick={addEvent}
        >
          {isMobile ? 'Add' : 'Add event'}
        </Fab>
      </PageHeaderActions>

      <PageHeaderActions disableSpacing>
        {isMobile && filterOpenButton}

        {width > largeScreenWidth && (
          <>
            <Tooltip title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon/>
              </IconButton>
            </Tooltip>

            <Tooltip title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </PageHeaderActions>
    </PageHeader>
  );
};
