import { api } from '../api';

export const fetchExpenses = (config = {}) => {
  return api.get('/expenses', config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => { throw error.data; });
};

export const createExpense = (expenseData) => {
  return api.post('/expenses', expenseData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchExpense = (id) => {
  return api.get(`/expenses/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateExpense = (expenseData) => {
  return api.put(`/expenses/${expenseData.id}`, expenseData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteExpense = (id) => {
  return api.delete(`/expenses/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteExpenses = (IDs) => {
  return api.delete('/expenses/delete', { params: { expense_ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchExpenseFiles = (id) => {
  return api.get(`/expenses/expense_files/${id}`)
    .then(({ data: { data, pagination } }) => ({ data, pagination }))
    .catch((error) => { throw error.data; });
};

export const massAssignExpense = (values) => {
  return api.post('/expenses/assign', values)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const massUnassignExpense = (values) => {
  return api.post('/expenses/unassign', values)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const massExpensesApprove = (IDs) => {
  return api.post('/expenses/approved', { expense_ids: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massExpensesDisapprove = (IDs) => {
  return api.post('/expenses/cancel', { expense_ids: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const getExpensesCounters = (config = {}) => {
  return api.get('/expenses/counters', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
