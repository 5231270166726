import { useContext } from 'react';
import moment from 'moment';
import { Box, makeStyles, Typography, SvgIcon } from '@material-ui/core';
import { mdiAttachment, mdiCommentMultipleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CalendarIcon from '@material-ui/icons/Event';
import { stopPropagation } from '../../../../../../helpers/dom';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Popper } from '../../../../../../components/Popper';
import { hasRole } from '../../../../../../utils/hasRole';
import { MRIsMenu } from '../../MRIsMenu';
import { MRIsContext } from '../../MRIsProvider';
import { PreviewMRIModal } from '../../PreviewMRIModal';
import { minRowHeight, columnsWidths, widthBreakpointMedium } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ width, mri }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { selectedIDs, toggleItemSelection, fetchMRIs, deleteMRI } = useContext(MRIsContext);
  const isSelected = selectedIDs.indexOf(mri.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(mri.id);
  };

  const openPreview = () => {
    openModal(PreviewMRIModal, {
      payload: {
        mri,
        onUpdate: fetchMRIs,
        onProcedureDelete: deleteMRI
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <ListRow
      button
      minHeight={minRowHeight}
      onClick={stopPropagation(openPreview)}
    >
      {!hasRole('client', 'patient') &&
        <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />
      }

      <ListRowCell grow column flexBasis={columnsWidths.name}>
        <Typography
          gutterBottom
          noWrap
          variant="h5"
          title={mri.name}
        >
          {mri.name}
        </Typography>

        <Typography noWrap component="div" className={classes.content}>
          <Box display="flex" alignItems="center" fontSize={15}>
            <SvgIcon color="inherit"><Icon path={mdiAttachment} /></SvgIcon>

            <Box pl={0.5}>
              <Typography color="inherit">{mri?.media_count || 0}</Typography>
            </Box>
          </Box>
          <VerticalDivider verticalGutters={0.25} horizontalGutters={1} />

          <Box display="flex" alignItems="center" color="info.main" fontSize={15}>
            <SvgIcon color="inherit" fontSize="inherit"><Icon path={mdiCommentMultipleOutline} /></SvgIcon>

            <Box pl={0.5}>
              <Typography color="inherit">{mri?.count_comments || 0}</Typography>
            </Box>
          </Box>

          <VerticalDivider verticalGutters={0.25} horizontalGutters={1} />

          <Typography noWrap color="textSecondary" title={mri.note}>
            {mri.note}
          </Typography>
        </Typography>
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointMedium} flexBasis={columnsWidths.date}>
        <Box mr={1}>
          <CalendarIcon fontSize="small" />
        </Box>

        <Typography noWrap title={moment.unix(mri.date).format('L, LT')}>
          {moment.unix(mri.date).format('L, LT')}
        </Typography>
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointMedium} flexBasis={columnsWidths.provider}>
        <UserLink
          noWrap
          size="md"
          variant="inherit"
          user={mri.physician}
          onClick={stopPropagation(openUserPreview(mri?.physician?.id))}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        <OfficeLink
          noWrap
          isExistTooltip={!!mri?.office?.id && !!mri?.office?.full_address}
          tooltipTitle={mri?.office?.full_address}
          office={mri?.office}
        />
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <MRIsMenu mri={mri} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
