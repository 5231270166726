import { api } from '../../api';

export const fetchHistories = (config) => {
  return api.get('/profile/medical/weight-histories', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteHistory = (id) => {
  return api.delete(`/profile/medical/weight-histories/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createWeightHistory = (data) => {
  return api.post('/profile/medical/weight-histories', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateWeightHistory = ({ id, ...data }) => {
  return api.put(`/profile/medical/weight-histories/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteWeightHistories = (IDs) => {
  return api.delete('/profile/medical/weight-histories', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
