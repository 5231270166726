import { Yup } from '../../../utils/validation';

export const validationSchema = Yup.object().shape({
  cpt_id: Yup.mixed().nullable().required(),
  pos_id: Yup.mixed().nullable().required(),
  unit: Yup.number().nullable().min(1),
  price: Yup.mixed().nullable().required(),
  modifiers: Yup.array().of(Yup.mixed()),
  icd: Yup.array().of(Yup.mixed()),
  side: Yup.mixed().nullable(),
  levels: Yup.mixed().nullable(),
  surgery: Yup.string().nullable().min(1).max(1000)
});
