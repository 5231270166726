import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Box, Collapse, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { Scrollbars } from '../../../components/Scrollbars';
import { Tab, Tabs } from '../../../components/Tabs';
import { getActiveRoute, Route } from '../../../components/router';
import { FilesFilterContextProvider } from '../files-common';
import { filterFieldsMap } from './MyFilesPage/FiltersBar/filterFieldsMap';
import { LeftSidebar } from './LeftSidebar';
import { TemplateEditPage } from './TemplatesPage/TemplateEditPage';
import { FilesFromPrintersPage } from './FilesFromPrintersPage';
import { routes } from './routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FilesPage = () => {
  const [ isOpenSidebar, setIsOpenSideBar ] = useState(true);
  const classes = useStyles({ isOpenSidebar });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileXS = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileXs));
  const hiddenFields = !isMobile ? [ filterFieldsMap.tags ] : [];
  const { pathname } = window.location;
  const {
    openFiltersBar,
    toggleFiltersBar
  } = useMobileFilterCollapse();
  const FilterBarWrapper = isMobile ? Collapse : Fragment;
  const filterBarWrapperProps = isMobile ? {
    in: openFiltersBar,
    component: Box,
    flexShrink: 0,
    minHeight: '50%'
  } : {};
  const ScrollWrapper = !isMobile ? Fragment : Scrollbars;
  const scrollWrapperProps = !isMobile ? {} : {
    autoHeight: isMobileXS ? !openFiltersBar : true,
    autoHeightMax: '100%',
    style: openFiltersBar ? {
      minHeight: '50%'
    } : {},
    width: '100%'
  };

  const toggleSideBar = () => {
    setIsOpenSideBar((state) => !state);
  };

  return (
    <FilesFilterContextProvider>
      <div className={classes.root}>
        {isMobile &&
          <div className={classes.navigation}>
            <Box mx={2.25} mt={1}>
              <IconButton size="medium" onClick={toggleFiltersBar}>
                <ListIcon />
              </IconButton>
            </Box>
          </div>
        }

        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <Tabs
              value={getActiveRoute(routes)}
              orientation={isMobile ? 'vertical' : 'horizontal'}
              classes={{ flexContainer: classes.tabsWrapper }}
            >
              {routes.map(({ path, icon, label }) => icon && (
                <Tab
                  key={path}
                  component={Link}
                  to={path}
                  value={path}
                  icon={icon}
                  label={label}
                  onClick={isTablet ? toggleFiltersBar : undefined}
                />
              ))}
            </Tabs>
          </FilterBarWrapper>
        </ScrollWrapper>

        <div className={classes.wrapper}>
          <div className={classes.content}>
            {!isMobile && (
              <Route exact path={[ '/files', '/files/recent' ]} render={
                () => (
                  <div className={cn(classes.sidebar, { [classes.sidebarOpen]: isOpenSidebar })}>
                    <LeftSidebar
                      isOpenSidebar={isOpenSidebar}
                      toggleSideBar={toggleSideBar}
                    />
                  </div>
                )}
              />
            )}

            <div className={classes.main}>
              <LayoutContextProvider>
                {routes.map(({ path, component: Component, icon }) => pathname === path && (
                  <Route
                    key={path}
                    path={path}
                    render={() => (
                      <Component
                        isOpenSidebar={isOpenSidebar}
                        hiddenFields={hiddenFields}
                        toggleSideBar={toggleSideBar}
                      />
                    )}
                  />
                ))}

                <Route
                  path="/files/templates/edit/:id"
                  render={() => (
                    <TemplateEditPage isOpenSidebar={isOpenSidebar} toggleSideBar={toggleSideBar} />
                  )}
                />

                <Route
                  path="/files/from-printers/:printerId"
                  component={FilesFromPrintersPage}
                />
              </LayoutContextProvider>
            </div>
          </div>
        </div>
      </div>
    </FilesFilterContextProvider>
  );
};
