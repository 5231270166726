import moment from 'moment';
import { useModal as useModalHook } from 'react-modal-hook';
import { Box, Grid, Typography, Link as MuiLink } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { FilePreviewModal } from '../../../../app/Dashboard/files-common';
import { stopPropagation } from '../../../../helpers/dom';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { Button } from '../../../Button';
import { ColorPreview } from '../../../ColorPreview';
import { MapModal } from '../../../MapModal';
import { useModal } from '../../../ModalsProvider';
import { OfficeLink } from '../../../OfficeLink';
import { ReadonlyCheckbox } from '../../../ReadonlyCheckbox';
import { UserLink } from '../../../UserLink';
import { Date } from '../../../Date';
import { AuthorizationViewModal } from '../../AuthorizationViewModal';
import { UserPreviewModal } from '../../../users';
import { ArrivalStatus } from './ArrivalStatus';
import { ConfirmedByOffice } from './ConfirmedByOffice';

export const AppointmentInfo = ({ disableUpdate = false, appointment, onUpdate }) => {
  const { openModal } = useModal();

  const [ openFilesPreview, closeFilesPreview ] = useModalHook(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file: appointment?.media?.[0],
        files: appointment?.media
      }}
    />
  ), [ appointment?.media?.length ]);

  const viewAuthorizationNumber = () => {
    openModal(AuthorizationViewModal, {
      payload: {
        authorization: appointment.authorization
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  const openOfficeAddress = () => {
    openModal(MapModal, {
      payload: {
        locationName: appointment.office?.name,
        address: appointment.office?.full_address,
        lat: appointment.office?.lat,
        lng: appointment.office?.lng
      }
    });
  };

  return (
    <>
      <Box color="info.main">
        <Typography variant="h4" color="inherit">Appointment Info</Typography>
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Appt. Book
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {appointment?.appointment_book?.appointment_book_name || '-'}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Appt. Timezone
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography>{appointment.timezone || '-'}</Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Appt. Date
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Date date={appointment.appointment_at} format="L LT" />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Appt. Duration
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Box clone display="flex" alignItems="center">
            <Typography>
              <Box clone mr={1}>
                <TimelapseIcon />
              </Box>

              {moment.duration(appointment?.time, 'seconds').format('h[h] m[min]')}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Office
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <OfficeLink office={appointment?.office} variant="body1" />

          {!!appointment?.office?.full_address &&
            <Box clone display="flex" alignItems="flex-start">
              <MuiLink onClick={openOfficeAddress}>
                <Box clone mr={1}>
                  <LocationOnIcon />
                </Box>

                {appointment?.office?.full_address}
              </MuiLink>
            </Box>
          }
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Pri. Care Physician
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <UserLink
            size="sm"
            user={appointment?.appointment_book?.provider}
            onClick={stopPropagation(
              openUserPreview(appointment?.appointment_book?.provider_id)
            )}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Appt. Type
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center">
            {appointment?.appointment_type?.color &&
              <Box display="flex" mr={1}>
                <ColorPreview color={appointment?.appointment_type?.color} />
              </Box>
            }

            <Typography>
              {appointment?.appointment_type?.code} - {appointment?.appointment_type?.description}
            </Typography>
          </Box>
        </Grid>

        {!hasRole(rolesMap.client, rolesMap.patient) &&
          <>
            <Box clone display="flex" alignItems="center">
              <Grid item xs={5}>
                <Typography noWrap color="textSecondary">
                  Arrival Status
                </Typography>
              </Grid>
            </Box>

            <Grid item xs={7}>
              <ArrivalStatus
                disableUpdate={disableUpdate}
                appointment={appointment}
                onUpdate={onUpdate}
              />
            </Grid>

            <Box clone display="flex" alignItems="center">
              <Grid item xs={5}>
                <Typography noWrap color="textSecondary">
                  Confirmed By Office
                </Typography>
              </Grid>
            </Box>

            <Grid item xs={7}>
              <ConfirmedByOffice
                disableUpdate={disableUpdate}
                appointment={appointment}
                onUpdate={onUpdate}
              />
            </Grid>
          </>
        }

        <Box clone display="flex" alignItems="center">
          <Grid item xs={5}>
            <Typography color="textSecondary">
              Eligibility manually verified
            </Typography>
          </Grid>
        </Box>

        <Grid item xs={7}>
          <ReadonlyCheckbox checked={!!appointment?.eligibility_manually_verified} />
        </Grid>

        <Box clone display="flex" alignItems="center">
          <Grid item xs={5}>
            <Typography noWrap color="textSecondary">
              Do not bill claim
            </Typography>
          </Grid>
        </Box>

        <Grid item xs={7}>
          <ReadonlyCheckbox checked={!!appointment?.do_not_bill_claim} />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Authorization #
          </Typography>
        </Grid>

        <Grid item xs={7}>
          {appointment?.authorization?.authorization ? (
            <MuiLink variant="body1" onClick={viewAuthorizationNumber}>
              {appointment?.authorization?.authorization}
            </MuiLink>
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Chief complaint
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            {appointment.chief_complaint || '-'}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Attachments
          </Typography>
        </Grid>

        <Grid item>
          <Button
            size="small"
            disabled={!appointment?.media?.length}
            color="primary"
            startIcon={<AttachFileIcon />}
            onClick={openFilesPreview}
          >
            {appointment?.media?.length}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
