import { forwardRef } from 'react';
import { CustomScrollbars } from '../CustomScrollbars';
import { Scrollbars } from '../Scrollbars';

export const ScrollbarsCustom = forwardRef(({ scrollElementRef, autoHeight = false, children, ...props }, ref) => {
  return autoHeight ? (
    <Scrollbars autoHeight ref={ref} autoHeightMax="100%" viewRef={scrollElementRef} {...props}>
      {children}
    </Scrollbars>
  ) : (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }} {...props}>
      {children}
    </CustomScrollbars>
  );
});
