import { Dialog } from '@material-ui/core';
import { MainContent } from './MainContent';
import { SchedulesContextProvider } from './SchedulesContext';

export const ScheduleListPreview = ({
  DialogProps,
  handleModalReject,
  payload: {
    initialValues = {}
  }
}) => {
  const initialState = {
    filter: {
      procedure_code_id: initialValues.codeID
    }
  };

  return (
    <Dialog maxWidth="md" {...DialogProps}>
      <SchedulesContextProvider initialState={initialState} >
        <MainContent
          parent={initialValues.parent}
          parentID={initialValues.codeID}
          handleModalReject={handleModalReject}
        />
      </SchedulesContextProvider>
    </Dialog>
  );
};
