import { makeStyles, Typography } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../../../../../components/ReadonlyCheckbox';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Allergies = ({ allergies }) => {
  const classes = useStyles();

  return (
    !allergies.length ?
      <Typography color="textSecondary" className={classes.title}>No info.</Typography>
      :
      allergies.map((allergy, index) => (
        <div key={index} className={classes.row}>
          <Typography className={classes.index}>{index + 1}.</Typography>

          <ReadonlyCheckbox
            checked
            title={allergy.name}
          />
        </div>
      )
      )
  );
};
