export const styles = ({
  spacing,
  palette: {
    primary,
    darkgreen,
    lightgreen,
    darkblue
  },
  typography: { pxToRem }
}) => ({
  statusLabel: {
    color: primary.contrastText
  },

  invoiceIcon: {
    fontSize: pxToRem(18),
    color: darkgreen,
    marginRight: spacing(),
    marginTop: spacing(-0.25)
  },

  invoiceCheckboxIcon: {
    marginRight: spacing(),
    color: lightgreen
  },

  invoiceCheckboxIcon_green: {
    marginRight: spacing(),
    color: darkblue
  }
});
