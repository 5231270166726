import { Fragment } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Grid, IconButton, SvgIcon } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {
  actionOptions,
  actionToFieldMap,
  renderOptionField
} from '../../../../../../components/emails/options';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Select } from '../../../../../../components/FormField';
import { Button } from '../../../../../../components/Button';

export const BodyActions = () => {
  const { openModal } = useModal();
  const { values, setFieldValue } = useFormikContext();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  const handleChangeAction = (index) => () => {
    setFieldValue(`actions[${index}].value`, null);
  };

  return (
    <FieldArray
      name="actions"
      render={({ remove, push }) => (
        <>
          <Grid container spacing={5}>
            {values?.actions?.map((condition, i) => (
              <Fragment key={i}>
                <Grid item sm={6} xs={12}>
                  <Select
                    label="Select an action"
                    name={`actions.${i}.key`}
                    options={actionOptions}
                    onChangeField={handleChangeAction(i)}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Box display="flex">
                    <Box flexGrow={1} mb={4}>
                      {values?.actions[i]?.key &&
                        renderOptionField(
                          actionToFieldMap,
                          values.actions[i].key?.value,
                          {
                            name: `actions[${i}].value`
                          }
                        )
                      }
                    </Box>

                    <Box ml={1} mb={4}>
                      {values?.actions?.length > 1 &&
                        <Box mt={1.5}>
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={handleClearField(remove, i)}
                          >
                            <SvgIcon color="error">
                              <CloseIcon />
                            </SvgIcon>
                          </IconButton>
                        </Box>
                      }
                    </Box>
                  </Box>
                </Grid>
              </Fragment>
            ))}
          </Grid>

          <Box mt={2}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => push({ key: actionOptions[0], values: null })}
            >
              Add another action
            </Button>
          </Box>
        </>
      )}
    />
  );
};
