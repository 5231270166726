import { useEffect, useState } from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { labelsColorMap, labelsMap } from '../Chart/labelsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ index, item, data }) => {
  const [ color, setColor ] = useState(labelsColorMap[item]);
  const classes = useStyles({ color });
  const transformData = data.map((item) => item > 0 ? item.toFixed(2) : item);

  useEffect(() => {
    setColor(labelsColorMap[item]);
  }, [ item ]);

  return (
    <div className={classes.row}>
      <div className={classes.title}>
        <div className={classes.titleLegend} />

        {labelsMap[item]}
      </div>

      <div className={cn(classes.title, classes.title_total)}>
        <CurrencyFormat value={transformData[index]}/>
      </div>
    </div>
  );
};
