import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { Date } from '../../../../../../../../components/Date';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ user }) => {
  const classes = useStyles();

  return (
    <Box p={0.5}>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">Ins.ID # {user?.insuranceId || '-'}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h2">
              {user.type_insurance || '-'}
            </Typography>

            <Typography color="textSecondary" variant="caption">
              Type Ins.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box display="flex" flexDirection="column">
            <Date disableIcon variant="h2" date={user.expiration_day} format="D MMM, YY" />

            <Typography color="textSecondary" variant="caption">
              Expiration date
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
