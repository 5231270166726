export const styles = ({ spacing, typography: { fontWeightMedium } }) => ({
  formContent: {
    fontWeight: fontWeightMedium,
    marginRight: spacing(2)
  },

  content: {
    marginBottom: spacing(3)
  },

  content_row: {
    display: 'flex',
    alignItems: 'center'
  },

  contentTitle: {
    paddingTop: spacing(2)
  },

  contentInfo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(2)
  },

  contentInfo__inner: {
    marginRight: spacing(2)
  },

  contentInfoTitle: {
    textTransform: 'capitalize'
  },

  contentGroup: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },

  contentGroupInfo: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(6),
    marginTop: spacing()
  },

  contentGroupInfoTitle: {
    textTransform: 'capitalize',
    fontWeight: fontWeightMedium,
    marginRight: spacing()
  }
});
