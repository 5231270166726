import { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { rolesMap } from '../../../../../../dataMaps';
import { filtersKeysMap } from '../../../../../../store/lastFilters';
import { fieldsKeysMap } from '../../../../../../components/appointments/FiltersBar/fieldsKeysMap';
import { Appointments as AppointmentsComponent } from '../../../../AppointmentsPage/Appointments';

const hiddenFilterFields = [
  fieldsKeysMap.patients
];

export const Appointments = ({ caseItem }) => {
  const patient = useMemo(() => {
    return caseItem?.case_users.find(({ case_role }) => case_role === rolesMap.applicant)?.user;
  });

  return !patient ? (
    <Box p={2} width="100%">
      <Typography variant="h3" align="center">Add applicant first</Typography>
    </Box>
  ) : (
    <AppointmentsComponent
      patient={patient}
      filter={{ patients: [ patient?.id ] }}
      hiddenFilterFields={hiddenFilterFields}
      filterKey={filtersKeysMap.cases_appointments}
    />
  );
};
