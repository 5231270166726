import { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Box, Paper, makeStyles } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import AlarmOffOutlinedIcon from '@material-ui/icons/AlarmOffOutlined';
import { mdiWrenchClock } from '@mdi/js';
import Icon from '@mdi/react';
import * as workSessionsApi from '../../../../../api/work-clocks';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { workClockTypesMap } from '../../../../../store/dashboard/workClock/workClockTypesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { Button } from '../../../../Button';
import { IconButton } from '../../../../IconButton';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../ListRow';
import { Loader } from '../../../../Loader';
import { Date } from '../../../../Date';
import { SvgIcon } from '../../../../SvgIcon';
import { Tooltip } from '../../../../Tooltip';
import { UserLink } from '../../../../UserLink';
import { useWorkSession } from '../../../useWorkSession';
import { WorkSessionsContext } from '../../../WorkSessionsProvider';
import { TracksTooltip } from '../../TracksTooltip';
import { useLiveSessionDuration } from '../../useLiveSessionDuration';
import { largeScreenWidth } from '../../WorkSessions';
import { columnsWidths, columnsMap } from '../listConfig';
import { Tracks } from './Tracks';

const useStyles = makeStyles(({ palette }) => ({
  row: {
    background: lighten(palette.warning.main, 0.85)
  }
}));

export const Row = ({
  item: workSession = {},
  width,
  isLoaded,
  recalculateHeight,
  hiddenColumns = []
}) => {
  const classes = useStyles();
  const [ isCollapsed, setIsCollapsed ] = useState(true);
  const [ isStopping, setIsStopping ] = useState(false);
  const workSessionsContext = useContext(WorkSessionsContext);
  const breakOvertime = workSession.time_info?.break - 20 * 60;
  const liveDuration = useLiveSessionDuration(workSession.session_tracks, workSession.active_track);
  const {
    hasAccess,
    addTrack,
    getTracksByType,
    deleteWorkSession
  } = useWorkSession(workSession);

  const isActiveActiveSessionType = (type) => {
    return workSession.active_track?.type === type;
  };

  const stopWorkSession = () => {
    setIsStopping(true);

    workSessionsApi.stopWorkSession(workSession.id).finally(() => {
      setIsStopping(false);
    });
  };

  const onTrackDelete = (trackId) => () => {
    workSessionsContext.deleteTrack(workSession.id, trackId);
  };

  const toggleCollapsed = () => {
    setIsCollapsed((isCollapsed) => !isCollapsed);

    setTimeout(() => {
      recalculateHeight();
    });
  };

  useEffect(() => {
    if (isLoaded) {
      recalculateHeight();
    }
  }, [ isLoaded, recalculateHeight, workSession ]);

  return !isLoaded ? <Loader p={1} /> : (
    <Paper elevation={0}>
      <ListRow px={2} className={cn({ [classes.row]: workSession.hasChangedTracks })}>
        <ListRowCell flexBasis={columnsWidths.icon}>
          <Tooltip
            title={workSession.hasChangedTracks ? 'Session modified manually' : 'Session completely of the work clock'}
          >
            {workSession.hasChangedTracks ? (
              <SvgIcon><Icon path={mdiWrenchClock} /></SvgIcon>
            ) : (
              <AlarmOnOutlinedIcon />
            )}
          </Tooltip>
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.user) &&
          <ListRowCell grow flexBasis={columnsWidths.user}>
            <UserLink user={workSession.user} />
          </ListRowCell>
        }

        <ListRowCell
          grow={width > largeScreenWidth || hiddenColumns.includes(columnsMap.user)}
          flexBasis={columnsWidths.start}
        >
          <Date disableIcon date={workSession.started_at} format="L LT" />
        </ListRowCell>

        <ListRowCell grow={width > largeScreenWidth} flexBasis={columnsWidths.end}>
          {!workSession.finished_at && hasRole(rolesMap.admin, rolesMap.supervisor) ? (
            <Loader surface loading={isStopping} render={
              () => (
                <Button
                  disabled={isStopping}
                  color="error"
                  startIcon={<AlarmOffOutlinedIcon />}
                  onClick={stopWorkSession}
                >
                  Clock-Out
                </Button>
              )}
            />
          ) : (
            <Date disableIcon date={workSession.finished_at} format="L LT" />
          )}
        </ListRowCell>

        <ListRowCell grow={width > largeScreenWidth} flexBasis={columnsWidths.duration}>
          <Tooltip
            isExistTooltip={getTracksByType(workClockTypesMap.work).length}
            title={<TracksTooltip tracks={getTracksByType(workClockTypesMap.work)} />}
          >
            <div>
              {isActiveActiveSessionType(workClockTypesMap.work)
                ? <Box color="success.main">{liveDuration}</Box>
                : moment.duration(workSession.time_info.work, 'seconds').format('h[h] m[min]')
              }
            </div>
          </Tooltip>
        </ListRowCell>

        <ListRowCell grow={width > largeScreenWidth} flexBasis={columnsWidths.breaks}>
          <Tooltip
            isExistTooltip={getTracksByType(workClockTypesMap.break).length}
            title={<TracksTooltip tracks={getTracksByType(workClockTypesMap.break)} />}
          >
            <div>
              {isActiveActiveSessionType(workClockTypesMap.break)
                ? <Box color="success.main">{liveDuration}</Box>
                : moment.duration(workSession.time_info.break, 'seconds').format('h[h] m[min]')
              }

              {breakOvertime > 0 && (
                <>
                  &nbsp;/&nbsp;

                  <Box color="error.main" component="span">
                    {moment.duration(breakOvertime, 'seconds').format('h[h] m[min]')}
                  </Box>
                </>
              )}
            </div>
          </Tooltip>
        </ListRowCell>

        <ListRowCell grow={width > largeScreenWidth} flexBasis={columnsWidths.lunches}>
          <Tooltip
            isExistTooltip={getTracksByType(workClockTypesMap.lunch).length}
            title={<TracksTooltip tracks={getTracksByType(workClockTypesMap.lunch)} />}
          >
            <div>
              {isActiveActiveSessionType(workClockTypesMap.lunch)
                ? <Box color="success.main">{liveDuration}</Box>
                : moment.duration(workSession.time_info.lunch, 'seconds').format('h[h] m[min]')
              }
            </div>
          </Tooltip>
        </ListRowCell>

        <ListRowCell actions noDisable flexBasis={columnsWidths.actions}>
          <ListRowCellActions>
            {!!workSession?.session_tracks?.length && (
              <IconButton size="small" onClick={toggleCollapsed}>
                {isCollapsed ? <ExpandMoreIcon color="inherit" /> : <ExpandLessIcon color="inherit" />}
              </IconButton>
            )}

            {hasAccess && (
              <IconButton color="primary" size="small" onClick={addTrack}>
                <AddIcon />
              </IconButton>
            )}

            {hasRole(rolesMap.admin, rolesMap.supervisor) && (
              <IconButton
                size="small"
                edge="end"
                title="Delete"
                color="error"
                onClick={deleteWorkSession}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </ListRowCellActions>
        </ListRowCell>
      </ListRow>

      {!isCollapsed && (
        <Tracks
          tracks={workSession.session_tracks}
          onTrackUpdate={workSessionsContext.updateTrack}
          onTrackDelete={onTrackDelete}
        />
      )}
    </Paper>
  );
};
