import { useContext, useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Loader } from '../../../Loader';
import { ScrollbarsLoader } from '../../../ScrollbarsLoader';
import { ThreadsContext } from '../../../chat';
import { Row } from './Row';

const useStyles = makeStyles({
  root: {
    width: '100% !important',
    listStyle: 'none'
  }
});

export const ChatsList = ({ selectedThreadsIDs, onToggleThreadIDSelection }) => {
  const {
    threads,
    isFetching,
    isFetched,
    loadNextPage,
    resetThreads,
    filter: { total }
  } = useContext(ThreadsContext);
  const classes = useStyles();

  useEffect(() => {
    resetThreads();
  }, []);

  return (
    <ScrollbarsLoader
      className={classes.root}
      total={total}
      rowsCount={threads.length}
      isFetching={isFetching}
      isFetched={isFetched}
      onNeedNextPage={loadNextPage}
    >
      <Loader fullWidth p={2} loading={!isFetched} render={
        () => !total ? (
          <Box p={2}>
            <Typography align="center">No threads found</Typography>
          </Box>
        ) : threads.map((item) => (
          <Row
            key={item.id}
            item={item}
            isLoaded={isFetched}
            selectedThreadsIDs={selectedThreadsIDs}
            onToggleThreadIDSelection={onToggleThreadIDSelection}
          />
        ))}
      />
    </ScrollbarsLoader>
  );
};
