import { Date, Text, Bool, Company, CurrencyFormat, UserActivityLink, ContactActivity, Case } from '../../fields';
import { Participant } from '../Participant';

export const invoiceFieldsMap = {
  address_from: (before, after) => <Text before={before} after={after} fieldLabel="Address From" />,
  address_to: (before, after) => <Text before={before} after={after} fieldLabel="Address To" />,
  email_from: (before, after) => <Text before={before} after={after} fieldLabel="Email From" />,
  email_to: (before, after) => <Text before={before} after={after} fieldLabel="Email To" />,
  balance: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Balance" />,
  paid: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Paid" />,
  total: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Total" />,
  paid_at: (before, after) => <Date before={before} after={after} fieldLabel="Paid At" />,
  bill_from: (before, after) => <Participant before={before} after={after} fieldLabel="Bill From" />,
  bill_to: (before, after) => <Participant before={before} after={after} fieldLabel="Bill To" />,
  notes: (before, after) => <Text before={before} after={after} fieldLabel="Notes" />,
  company_from_id: (before, after) => <Company before={before} after={after} fieldLabel="Company From" />,
  company_to_id: (before, after) => <Company before={before} after={after} fieldLabel="Company To" />,
  confirmed_by: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Confirmed By" />,
  user_from_id: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="User From" />,
  user_to_id: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="User To" />,
  date: (before, after) => <Date before={before} after={after} fieldLabel="Date" />,
  deleted_at: (before, after) => <Date before={before} after={after} fieldLabel="Deleted At" />,
  due_date: (before, after) => <Date before={before} after={after} fieldLabel="Due Date" />,
  is_confirmed: (before, after) => <Bool before={before} after={after} fieldLabel="Confirmed" />,
  is_copay: (before, after) => <Bool before={before} after={after} fieldLabel="Copay" />,
  number: (before, after) => <Text before={before} after={after} fieldLabel="Number" />,
  po: (before, after) => <Text before={before} after={after} fieldLabel="PO" />,
  status: (before, after) => <Text before={before?.name} after={after?.name} fieldLabel="Status" />,
  terms: (before, after) => <Text before={before} after={after} fieldLabel="Terms" />,
  phone_from: (before, after) => <ContactActivity before={before} after={after} fieldLabel="Phone From" />,
  phone_to: (before, after) => <ContactActivity before={before} after={after} fieldLabel="Phone To" />,
  case_id: (before, after) => <Case before={before} after={after} fieldLabel="Case" />
};
