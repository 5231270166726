export const styles = ({
  spacing,
  palette: { grey, divider, ...palette },
  typography: { pxToRem, fontWeightMedium },
  transitions
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minWidth: 300,
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 3,
    overflow: 'hidden'
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: pxToRem(42),
    paddingLeft: spacing(2.5),
    background: grey[600],
    color: palette.common.white,
    fontSize: pxToRem(16),
    fontWeight: fontWeightMedium
  },

  content: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: spacing(1, 0)
  },

  content__action: {
    position: 'relative'
  },

  content__shadow: {
    position: 'absolute',
    width: '100%',
    height: '20px',
    top: '-20px',
    left: 0,
    right: 0,
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%)'
  },

  creationButton: {
    color: grey[400],
    transition: transitions.create(),

    '&:hover': {
      color: grey[500]
    }
  },

  creationButton__icon: {
    marginRight: spacing(1),
    fontSize: pxToRem(18)
  }
});
