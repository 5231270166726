import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Box, Grid, SvgIcon, Typography, Link, useTheme, useMediaQuery } from '@material-ui/core';
import LocalPhoneIcon from '@material-ui/icons/LocalPhoneOutlined';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import { mdiCalendarArrowRight } from '@mdi/js';
import { Icon } from '@mdi/react';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../../dataMaps/apiModels';
import { AppointmentsFilterContext, AppointmentViewModal } from '../../../../../../../components/appointments';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';
import { CardHeader, CardItem } from '../../../../../../../components/Cards';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../components/UserLink';
import { Contact } from '../../../../../../../components/Contact';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { UserSSN } from '../../../../../../../components/users';
import { Date } from '../../../../../../../components/Date';
import { PatientsMenu } from '../../PatientsMenu';
import { Body } from './Body';

export const Card = ({ item: user = {} }) => {
  const history = useHistory();
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const appointmentsFilterContext = useContext(AppointmentsFilterContext);
  const isSelectedPatientForAppointments = !!appointmentsFilterContext.filter?.patients?.find((id) => id === user.id);

  const filterAppointments = () => {
    appointmentsFilterContext.changeFilter({ patients: isSelectedPatientForAppointments ? [] : [ user.id ] });
    appointmentsFilterContext.setRelationsForFilter({ patients: isSelectedPatientForAppointments ? [] : [ user ] });
  };

  const getUserPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.patient(user.id) + '/medical-info/medical-forms');
    }
  };

  const openAppointmentPreview = () => {
    if (user.last_visited_appointment_id) {
      openModal(AppointmentViewModal, {
        payload: {
          appointmentID: user.last_visited_appointment_id,
          disableUpdate: true
        }
      });
    }
  };

  return (
    <CardItem p={2} selected={isSelectedPatientForAppointments} onClick={getUserPage}>
      <CardHeader
        disableCheckBox
        alignItems="flex-start"
        icon={isMobile ? null :
          <IconButton
            color={isSelectedPatientForAppointments ? 'error' : 'info'}
            edge="end"
            title={isSelectedPatientForAppointments ? 'Clear appointments filter' : 'Show patient appointments'}
            onClick={stopPropagation(filterAppointments)}
          >
            {isSelectedPatientForAppointments ? (
              <EventBusyIcon />
            ) : (
              <SvgIcon>
                <Icon path={mdiCalendarArrowRight} />
              </SvgIcon>
            )}
          </IconButton>
        }
        menuComponent={PatientsMenu}
        menuComponentProps={{ user }}
      >
        <UserLink
          size="xl"
          variant="h6"
          user={user}
          childrenProps={{ display: 'flex', alignItems: 'flex-start' }}
          parentProps={{ alignItems: 'center' }}
        >
          <Typography variant="caption" color="textSecondary">
            {moment(user?.birthday).format('L')}
          </Typography>

          <VerticalDivider verticalGutters={0.25} horizontalGutters={1} />

          <UserSSN
            user={user}
            title="SSN"
            TypographyProps={{
              variant: 'caption',
              color: 'textSecondary'
            }}
          />
        </UserLink>
      </CardHeader>

      <Box pt={2} px={2} pb={2}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography color="textSecondary">Appt. Date</Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              {user.last_visited_appointment_id &&
                <Link onClick={stopPropagation(openAppointmentPreview)}>
                  <Date iconFontSize="small" variant="h5" date={user.last_visited_appointment} />

                  <Box component="span" ml={1.5} mt={0.5}>
                    <Date
                      disableDateIcon
                      format="LT"
                      iconFontSize="small"
                      variant="h5"
                      date={user.last_visited_appointment}
                    />
                  </Box>
                </Link>
              }
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Typography color="textSecondary">Location</Typography>
          </Grid>

          <Grid item xs={8}>
            <Tooltip
              isExistTooltip={!!user?.last_visited_location?.full_address}
              title={user?.last_visited_location?.full_address}
            >
              <Typography noWrap>{user?.last_visited_location?.full_address || '-'}</Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Body user={user} />

      <Box pt={2} display="flex" alignItems="center">
        <Box mr={1}>
          <EmailIcon fontSize="small" />
        </Box>

        <Contact
          variant="h5"
          type="mailto"
          contact={user.email}
        />
      </Box>

      <Box pt={1} display="flex" alignItems="center">
        <Box mr={1}>
          <LocalPhoneIcon fontSize="small" />
        </Box>

        <Contact
          variant="h5"
          type="tel"
          contact={user.phone}
        />
      </Box>
    </CardItem>
  );
};
