import { Dialog, SvgIcon } from '@material-ui/core';
import { mdiFileDocumentEditOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { ClaimsList, ClaimsProvider } from '../../index';
import { columnsMap } from '../../ClaimsList';
import { ModalContainer, ModalHeader, ModalBody } from '../../../Modal';
import {
  FiltersBar
} from '../../../../app/Dashboard/BillingPage/ClaimsPage/Claims/FiltersBar';
import { Row } from './Row';

const hiddenColumns = [
  columnsMap.checkbox,
  columnsMap.caseNo,
  columnsMap.totalAmount,
  columnsMap.actions
];

const ListRowProps = {
  px: 2
};

export const ClaimsSelectModal = ({
  payload: {
    filter = {}
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  return (
    <Dialog maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader
          icon={<SvgIcon><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>}
          onClose={handleModalReject}
        >
          Claims
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <ClaimsProvider filter={filter}>
            <FiltersBar />

            <ClaimsList
              scrollProps={{
                autoHeight: true,
                autoHeightMax: '100%'
              }}
              hiddenColumns={hiddenColumns}
              Row={Row}
              RowProps={{ onSelect: handleModalResolve, ListRowProps }}
            />
          </ClaimsProvider>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
