import { useState } from 'react';
import { Box } from '@material-ui/core';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { DirectReferralContent, EditDirectReferralModal } from './DirectReferralContent';

export const DirectReferral = ({
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  onCaseItemUpdate = () => {},
  ...props
}) => {
  const { openModal } = useModal();
  const [ dirreffInfo, setDirreffInfo ] = useState({
    dirreff_attorney: caseItem.dirreff_attorney,
    direct_attorney_company: caseItem.direct_attorney_company
  });

  const openWidgetModal = () => {
    openModal(EditDirectReferralModal, {
      payload: { dirreffInfo: { id: caseItem.id, ...dirreffInfo } },
      onModalResolved: (value) => {
        setDirreffInfo({
          dirreff_attorney: value.dirreff_attorney,
          direct_attorney_company: value.direct_attorney_company
        });
        onCaseItemUpdate(value);
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      title="Direct Referral to attorney"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          isEditable={!hasRole(rolesMap.patient, rolesMap.client)}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
          openWidgetModal={openWidgetModal}
        />
      ]}
      {...props}
    >
      <Box height={height || '100%'}>
        <DirectReferralContent dirreffInfo={dirreffInfo}/>
      </Box>
    </ContentCard>
  );
};
