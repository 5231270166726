import { useContext, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../components/TablePagination';
import { Loader } from '../../../../../components/Loader';
import { Cards } from '../../../../../components/Cards';
import { PrescriptionsContext } from '../PrescriptionsProvider';
import { Card } from './Card';

export const Grid = ({ hiddenColumns }) => {
  const { isFetched, meta, prescriptions, resetPrescriptions } = useContext(PrescriptionsContext);
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isMobileSM = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  useEffect(() => {
    resetPrescriptions();
  }, []);

  return (
    <Box height="100%" flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
        <Loader p={3} loading={!isFetched} render={
          () => !prescriptions.length ? (
            <Box p={3}>
              <Typography align="center">No notes found</Typography>
            </Box>
          ) : (
            <>
              <Cards
                size={isMobileSM ? 200 : 300}
                list={prescriptions}
                component={Card}
                cardProps={{ hiddenColumns }}
              />
              <TablePagination borderTop pagination={meta} onChange={resetPrescriptions} />
            </>
          )}
        />
      </CustomScrollbars>
    </Box>
  );
};
