import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Box, Button, IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import { Tooltip } from '../../../../../../components/Tooltip';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { Loader } from '../../../../../../components/Loader';
import { Print } from '../../ClaimPreview/ActionsBar/Print';
import { styles } from '../../ClaimPreview/ActionsBar/styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({ isSubmitting, claim, sidebarIsOpen, setSidebarIsOpen }) => {
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setSidebarIsOpen((isOpen) => !isOpen);
  };

  return (
    <div className={classes.root}>
      <IconButton
        edge="start"
        color="primary"
        title={sidebarIsOpen ? 'Hide sidebar' : 'Show sidebar'}
        onClick={handleDrawerOpen}
      >
        {sidebarIsOpen ? <ChevronLeftIcon /> : <MenuIcon />}
      </IconButton>

      <Box ml={1}>
        <Typography variant="h4">Claim # {claim.claim_number || '-'}</Typography>
      </Box>

      <VerticalDivider verticalGutters={1} horizontalGutters={3} />

      <Print claimID={claim.id} />

      <Box display="flex" ml="auto" pl={1}>
        <Box mr={1}>
          <Tooltip title="Back to claim view">
            <Button component={RouterLink} to={`/billing/claims/${claim.id}`}>
              Cancel
            </Button>
          </Tooltip>
        </Box>

        <Loader surface loading={isSubmitting} render={
          () => (
            <Button
              autoFocus
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          )}
        />
      </Box>
    </div>
  );
};
