import { useContext } from 'react';
import { Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { SelectedItemsCount } from '../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../components/VerticalDivider';
import { PageHeaderActions } from '../../../../components/Page';
import { IconButton } from '../../../../components/IconButton';
import { Fab } from '../../../../components/Fab';

export const ActionsBar = ({
  title,
  width,
  selectedIDs,
  pagination,
  addNewContact,
  massDelete,
  filterOpenButton
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  return (
    <>
      <PageHeaderActions startPosition>
        <Fab
          variant="extended"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addNewContact}
        >
          {title}
        </Fab>
      </PageHeaderActions>

      <PageHeaderActions disableSpacing>
        {!!selectedIDs.length &&
          <>
            <SelectedItemsCount length={selectedIDs.length} total={pagination.total} />
            <VerticalDivider verticalGutters={1} horizontalGutters={1} />

            <Tooltip
              arrow
              title={<Typography>Delete selected items</Typography>}
            >
              <IconButton
                color="error"
                onClick={massDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <VerticalDivider verticalGutters={1} horizontalGutters={1} />
          </>
        }

        {(width < 825 || isTablet) && filterOpenButton}

        {(width > 825 || !isTablet) &&
          <>
            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon />
            </IconButton>

            <IconButton
              edge="end"
              color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.grid)}
            >
              <ViewModuleIcon />
            </IconButton>
          </>
        }
      </PageHeaderActions>
    </>
  );
};
