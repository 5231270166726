import { useRef, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutContextProvider, viewVariantsMap } from '../../../../components/LayoutContext';
import { PageHeader, PageBody, Page } from '../../../../components/Page';
import { useResizeObserver } from '../../../../helpers/hooks/useResizeObserver';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { Header } from './Header';
import { Content } from './Content';

export const minWidthForListView = 1250;

export const Appointments = ({
  patient,
  filter,
  initialViewVariant = viewVariantsMap.calendar,
  hiddenFilterFields,
  filterKey
}) => {
  const rootRef = useRef(null);
  const [ isFilterVisible, setIsFilterVisible ] = useState(true);
  const { width } = useResizeObserver({ ref: rootRef });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.tabletLg));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('tabletLg', width);

  const toggleFilterVisibility = () => {
    setIsFilterVisible((isFilterVisible) => !isFilterVisible);
  };

  return (
    <LayoutContextProvider initialViewVariant={initialViewVariant}>
      <Page elevation={isMobile ? 0 : 2} ref={rootRef}>
        <PageHeader>
          <Header
            isFilterVisible={isFilterVisible}
            width={width}
            patient={patient}
            filterOpenButton={filterOpenButton}
            onFilterVisibilityChange={toggleFilterVisibility}
          />
        </PageHeader>

        <PageBody fullHeight disablePaddings disableScrollbars>
          <Content
            isFilterVisible={isFilterVisible}
            filter={filter}
            patient={patient}
            width={width}
            FilterBarWrapper={FilterBarWrapper}
            filterBarWrapperProps={filterBarWrapperProps}
            ScrollWrapper={ScrollWrapper}
            scrollWrapperProps={scrollWrapperProps}
            hiddenFilterFields={hiddenFilterFields}
            filterKey={filterKey}
          />
        </PageBody>
      </Page>
    </LayoutContextProvider>
  );
};
