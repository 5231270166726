import { Box, Link, Typography } from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { ColorPreview } from '../../../../../../components/ColorPreview';
import { useModal } from '../../../../../../components/ModalsProvider';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { stopPropagation } from '../../../../../../helpers/dom';
import { Label } from '../../../../TasksPage/MainWindow/Label';
import { TaskModal } from '../../../../TasksPage/TaskModal';

export const TaskModel = ({
  model,
  variant = 'body2',
  color = 'textSecondary',
  iconColor = 'primary'
}) => {
  const { openModal } = useModal();

  const openPreview = () => {
    openModal(TaskModal, {
      payload: {
        id: model?.id
      }
    });
  };

  return !model ? (
    <Typography>-</Typography>
  ) : (
    <>
      <PlaylistAddCheckIcon color={iconColor} />

      <Box display="flex" flexDirection="column">
        <Box ml={1} overflow="hidden" textOverflow="ellipsis">
          {model?.name && (
            <Typography
              noWrap
              variant={variant}
              color={color}
              component={Link}
              onClick={stopPropagation(openPreview)}
            >
              {model?.name}
            </Typography>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          {model.priorityLabel && (
            <Label variant={variant} name={model.priorityLabel} color={model.priority} />
          )}

          <VerticalDivider horizontalGutters={0.5} />

          {model?.type?.color && (
            <Box display="flex" alignItems="center">
              <ColorPreview color={model?.type?.color} />

              <Typography variant={variant}>
                {model?.type?.name || '-'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
