import { useContext } from 'react';
import { ChatThreadContext } from '../../../../../../components/chat';
import { ThreadFilesProvider } from './ThreadFilesProvider';
import { List } from './List';

export const FilesList = () => {
  const { currentThread } = useContext(ChatThreadContext);

  return (
    <ThreadFilesProvider threadId={currentThread?.id}>
      <List />
    </ThreadFilesProvider>
  );
};
