import { Box } from '@material-ui/core';
import { useState } from 'react';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { WidgetModal } from '../WidgetModal';
import { InsuranceContent } from './InsuranceContent';
import { EditInsuranceContent } from './EditInsuranceContent';

export const Insurance = ({
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},
  onClose,
  onOpen,
  onPutItem,
  onMinimize,
  onMaximized,
  ...props
}) => {
  const { openModal } = useModal();
  const [ claimInfo, setClaimInfo ] = useState(caseItem.claim_insurance_information);

  const openWidgetModal = () => {
    openModal(WidgetModal, {
      payload: {
        caseItem: {
          ...caseItem,

          claim_insurance_information: claimInfo
        },
        maxWidth: 900,
        Component: EditInsuranceContent,
        title: 'Edit Claims Insurance Information'
      },
      onModalResolved: ({ claim_insurance_information }) => {
        setClaimInfo(claim_insurance_information);
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      title="Claim Insurance Information"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          isEditable={!hasRole(rolesMap.patient, rolesMap.client)}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
          openWidgetModal={openWidgetModal}
        />
      ]}
      {...props}
    >
      <Box height={height || '100%'}>
        <InsuranceContent claimInfo={claimInfo} />
      </Box>
    </ContentCard>
  );
};
