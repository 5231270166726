import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from '../FilesContext/types';

export const reducer = createHookReducer({
  [types.RESET_FILES]: (state) => {
    return {
      ...state,

      selectedFilesIDs: []
    };
  },

  [types.DELETE_FILES]: ({ selectedFilesIDs, ...state }, filesIDs) => {
    return {
      ...state,

      selectedFilesIDs: selectedFilesIDs.filter((id) => !filesIDs.find((deletedID) => deletedID === id))
    };
  },

  [types.ADD_FILES_TO_SELECTED]: ({ selectedFilesIDs, ...state }, filesIDs) => {
    return {
      ...state,

      selectedFilesIDs: state.isSingleSelection
        ? filesIDs
        : [ ...new Set(selectedFilesIDs.concat(filesIDs)) ]
    };
  },

  [types.DELETE_FILES_FROM_SELECTED]: ({ selectedFilesIDs, ...state }, filesIDs) => {
    return {
      ...state,

      selectedFilesIDs: selectedFilesIDs.filter((id) => filesIDs.indexOf(id) === -1)
    };
  }
});
