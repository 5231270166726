import Color from 'color';

export const styles = ({ palette, transitions }) => ({
  root: {
    display: 'flex',
    height: '100%',

    '& .fc-timegrid-event': {
      minHeight: 45
    },

    '& .fc-timegrid-slot-lane': {
      cursor: 'pointer',
      transition: transitions.create()
    },

    '& .fc-timegrid-slot-lane:hover': {
      background: Color(palette.primary.light).alpha(0.2).string()
    }
  },

  calendar: {
    flexGrow: 1,
    height: '100%'
  }
});
