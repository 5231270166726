import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { CurrencyFormat } from '../../CurrencyFormat';
import { Date } from '../../Date';

export const PaymentContent = ({ billingInfo }) => {
  return (
    <>
      <Grid item xs={5}>
        <Typography noWrap color="textSecondary">
          Copay
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <CurrencyFormat value={billingInfo?.copay_charged} />
      </Grid>

      <Grid item xs={5}>
        <Typography noWrap color="textSecondary">
          Copay Balance
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <CurrencyFormat
          value={billingInfo?.copay_balance}
          TypographyProps={{
            color: billingInfo?.copay_balance > 0 ? 'error' : 'textPrimary'
          }}
        />
      </Grid>

      <Grid item xs={5}>
        <Typography noWrap color="textSecondary">
          Insurance Balance
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <CurrencyFormat value={billingInfo?.insurance_balance} />
      </Grid>

      <Grid item xs={5}>
        <Typography noWrap color="textSecondary">
          Total Balance
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <CurrencyFormat value={billingInfo?.total_balance} />
      </Grid>

      <Grid item xs={5}>
        <Typography noWrap color="textSecondary">
          Overdraft
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <CurrencyFormat value={billingInfo?.patient_overdraft} />
      </Grid>

      {!!billingInfo?.last_payment_date &&
        <>
          <Grid item xs={5}>
            <Typography noWrap color="textSecondary">
              Last Payment Date
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Date date={moment(billingInfo?.last_payment_date).unix()} format="L" />
          </Grid>
        </>
      }
    </>
  );
};
