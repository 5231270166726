import { useContext } from 'react';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { ProcedureContext } from '../../ProcedureProvider';
import {
  columnsWidths,
  widthBreakpointMedium,
  widthBreakpointLarge,
  widthBreakpointSmall
} from '../List';

export const Header = ({ width }) => {
  const { selectedIDs, allItemsIsSelected, toggleAllItemsSelection } = useContext(ProcedureContext);

  return (
    <ListRow header>
      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <ListRowCheckbox
          indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
          checked={allItemsIsSelected()}
          onClick={toggleAllItemsSelection}
        />
      }

      <ListRowCell grow flexBasis={columnsWidths.name}>
        Procedure Code # / Description
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.diagnosis}>
        DX Sequence / Diagnosis
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.date}>
        Appt. From
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.date}>
        Appt. To
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.provider}>
          Provider
        </ListRowCell>
      }

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.office}>
          Office
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.actions} />
    </ListRow>
  );
};
