import { useContext } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';
import { getUserFullName } from '../../../helpers/users';
import { Scrollbars } from '../../Scrollbars';
import { ChatThreadContext } from '../ChatThreadContext';
import { MessageContent } from '../MessageContent';
import { Attachments } from '../Attachments';

export const Reply = () => {
  const {
    resetCurrentAction,
    forwardedMessage,
    setForwardedMessage
  } = useContext(ChatThreadContext);

  const discardForward = () => {
    resetCurrentAction();
    setForwardedMessage(null);
  };

  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Box p={1}>
        <ReplyIcon color="primary" />
      </Box>

      <Box flexGrow={1}>
        <Typography variant="body1" color="primary">
          {getUserFullName(forwardedMessage?.user)}
        </Typography>

        <Typography variant="body2" component="div">
          <MessageContent message={forwardedMessage?.body || forwardedMessage?.parent?.body} />
        </Typography>

        <Scrollbars autoHeight autoHeightMax={160}>
          <Attachments message={forwardedMessage} />
        </Scrollbars>
      </Box>

      <Box ml="auto">
        <IconButton onClick={discardForward}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
