import { api } from '../api';

export const fetchFaxes = (config) => {
  return api.get('/sip/fax', config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const fetchFaxNumbers = (config) => {
  return api.get('/sip/fax/numbers', config).then((data) => {
    // TODO: this checks for mock data on BE side,
    //  if it's mock we have only data if real data -> data.data
    return data?.data || data;
  }).catch((error) => {
    throw error;
  });
};

export const sendFax = (data, config) => {
  return api.post('/sip/fax/send', data, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const resendFax = (faxID, config) => {
  return api.post(`/sip/fax/${faxID}/resend`, null, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const copyFile = (data, config) => {
  return api.post('/sip/fax/files/copy', data, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const multipleCopy = (data, config) => {
  return api.post('/sip/fax/bulk/copy', data, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const multipleShare = (data, config) => {
  return api.post('/sip/fax/bulk/share', data, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const deleteFax = (id, config) => {
  return api.delete(`/sip/fax/${id}`, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const deleteFaxes = (ids, config) => {
  return api.post('/sip/fax/bulk/delete', { ids }, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const updateFaxStatus = ({ id, status }) => {
  return api.patch(`/sip/fax/${id}/status`, { status }).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};
