import { Formik } from 'formik';
import { Dialog, Button, FormControlLabel, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as emailsApi from '../../../../../api/emails';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { transformAccountsToOptions } from '../../../../../components/emails/options';
import {
  Checkbox,
  transformAccountToOption
} from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../../components/Modal';
import { useModal } from '../../../../../components/ModalsProvider';
import { TagsSelect } from '../../../../../components/Tags/TagsSelect';
import { UsersSelect } from '../../../../../components/users';
import { CreateEmailsRuleFormModal } from '../../Rules/MainContent/CreateEmailsRuleFormModal';
import { validationSchema } from './validationSchema';

export const EmailSaveModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    disableCheckbox = false,
    folderID,
    messageUIDs,
    message = {},
    accounts
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const accountsOptions = transformAccountsToOptions(accounts);
  const account = accounts.find(({ id }) => id === message.config_id);
  const selectedConfig = account?.id ? transformAccountToOption(account) : accountsOptions[0];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const saveRule = (values, setFieldValue, handleSubmit) => (event) => {
    const regex = /[\s]*?(Re:|Fw:)/gi;
    const subject = message.subject.replace(regex, '');

    if (event.target.checked) {
      openModal(CreateEmailsRuleFormModal, {
        payload: {
          accounts,
          initialRule: {
            config_id: selectedConfig,
            conditions: [
              { key: 'from', values: message?.from },
              { key: 'to', values: message?.to },
              { key: 'subject', values: [ subject ] }
            ],
            actions: [
              { key: values.patient && 'attach_patient', value: values?.patient },
              { key: values.case && 'attach_case', value: values?.case }
            ],
            stop_processing: false,
            is_active: 1
          }
        },
        onModalResolved: () => {
          handleSubmit(values);
        },
        onModalRejected: () => {
          setFieldValue('isRule', false);
        }
      });
    }
  };

  const handleCaseChange = (setFieldValue) => (caseItem) => {
    setFieldValue('case', caseItem || null);
  };

  const handlePatientChange = (setFieldValue) => (user) => {
    setFieldValue('patient', user || null);
  };

  const saveEmail = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return emailsApi.saveMessages({
      ...values,

      tags_id: (values.myTags || []).concat(values.globalTags || [])
    }).then(() => {
      enqueueSnackbar('Emails saved', { variant: 'success' });
      handleModalResolve();
    }).catch(({ errors }) => {
      enqueueSnackbar('Emails not saved', { variant: 'error' });
      setErrors(errors);
    });
  };

  return (
    <Formik
      initialValues={{
        folder_id: folderID,
        message_uid: messageUIDs,
        tags_id: null,
        case_id: null,
        case: null,
        patient_id: null,
        patient: null,
        isRule: false
      }}
      validationSchema={validationSchema}
      onSubmit={saveEmail}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
        <Dialog fullScreen={isMobile} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Save to Attach / Save with tags
            </ModalHeader>

            <ModalBody>
              <TagsSelect
                multiple
                name="myTags"
                label="Select my tags"
                placeholder="Select my tags..."
                margin="dense"
                params={{ is_system: 0 }}
              />

              <TagsSelect
                multiple
                name="globalTags"
                label="Select global tags"
                placeholder="Select global tags..."
                margin="dense"
                params={{ is_system: 1 }}
              />

              <CasesSelect
                name="case_id"
                label="Select case"
                placeholder="Select case..."
                margin="dense"
                onChange={handleCaseChange(setFieldValue)}
              />

              <UsersSelect
                name="patient_id"
                label="Select patient"
                placeholder="Select patient"
                params={{ is_patient: 1 }}
                margin="dense"
                onChange={handlePatientChange(setFieldValue)}
              />

              {!disableCheckbox &&
                <Box my={2}>
                  <FormControlLabel
                    label="Create rule"
                    control={
                      <Checkbox
                        disabled={!values.case_id && !values.patient_id}
                        color="primary"
                        name="isRule"
                        onChange={saveRule(values, setFieldValue, handleSubmit)}
                      />
                    }
                  />
                </Box>
              }
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
