import { useContext, useEffect, useState } from 'react';
import { CasesSelect } from '../../../../../../components/cases/CasesSelect';
import { PatientsSelect } from '../../../../../../components/users/PatientsSelect';
import { useDebounce } from '../../../../../../helpers/hooks';
import { TextField } from '../../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { RulesEmailsContext } from '../../RulesEmailsContext';
import { filterFieldsMap, filterFieldsLabels } from './filterFieldsMap';

const initialValues = {
  case_id: null,
  patient_id: null,
  from: null,
  to: null,
  body: null,
  subject: null
};

export const FiltersBar = ({ parent_type = '' }) => {
  const { filter, applyFilter } = useContext(RulesEmailsContext);
  const [ fields, setFields ] = useState(filter);
  const debouncedFields = useDebounce(fields);

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, config_id: filter.config_id, [name]: value });
  };

  const handlePatientChange = (patient) => {
    setFields(({ ...fields, config_id: filter.config_id, patient_id: patient?.id ||  null }));
  };

  const handleCasesChange = (caseItem) => {
    setFields(({ ...fields, config_id: filter.config_id, case_id: caseItem?.id ||  null }));
  };

  useEffect(() => {
    applyFilter(debouncedFields);
  }, [ debouncedFields ]);

  return (
    <FiltersBarComponent
      disableTopPaddings
      isResetForm
      initialValues={initialValues}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.caseID,
          label: filterFieldsLabels[filterFieldsMap.caseID],
          field: (
            <CasesSelect
              name="case_id"
              placeholder="Case..."
              minWidth={170}
              onChange={handleCasesChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.patientID,
          label: filterFieldsLabels[filterFieldsMap.patientID],
          field: (
            <PatientsSelect
              name="patient_id"
              label="Patient"
              minWidth={180}
              onChange={handlePatientChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.subject,
          label: filterFieldsLabels[filterFieldsMap.subject],
          field: (
            <TextField
              name="subject"
              label="Subject"
              onChange={handleFieldChange('subject')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.body,
          label: filterFieldsLabels[filterFieldsMap.body],
          field: (
            <TextField
              name="body"
              label="Body"
              onChange={handleFieldChange('body')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.from,
          label: filterFieldsLabels[filterFieldsMap.from],
          field: (
            <TextField
              name="from"
              label="From"
              onChange={handleFieldChange('from')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.to,
          label: filterFieldsLabels[filterFieldsMap.to],
          field: (
            <TextField
              name="to"
              label="To"
              onChange={handleFieldChange('to')}
            />
          )
        }
      ]}
    />
  );
};
