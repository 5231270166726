import * as codesApi from '../../../../api/codes/procedures';
import { Autocomplete } from '../../Autocomplete';

const fetchTOSCodes = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return codesApi.fetchTOSCodes({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const getOptionValue = (option) => option?.id;

const getOptionSelected = (option, value) => option.id === value?.id;

const getOptionLabel = (option) => {
  return option?.description ? `${option.code} - ${option.description}` : option.code;
};

export const TOSCodesSelect = ({ params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      placeholder="Select code..."
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      getOptionSelected={getOptionSelected}
      onNeedFetch={fetchTOSCodes(params)}

      {...props}
    />
  );
};
