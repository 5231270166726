import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog } from '@material-ui/core';
import * as accountsApi from '../../../../api/login-queues/accounts';
import { ModalFooter, ModalHeader, ModalBody } from '../../../Modal';
import { Loader } from '../../../Loader';
import { AccountForm, initialValues, validationSchema } from '../AccountForm';

export const EditAccountModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    accountId
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ account, setAccount ] = useState(null);

  const editAccount = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return accountsApi.updateAccount(values).then((account) => {
      enqueueSnackbar('Account successfully updated', { variant: 'success' });
      handleModalResolve(account);
    }).catch(({ data: { errors } }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Account not updated', { variant: 'error' });
    });
  };

  useEffect(() => {
    accountsApi.fetchAccount(accountId).then((account) => {
      setAccount(account);
    }).catch(() => {
      enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      handleModalReject();
    });
  }, []);

  return (
    <Dialog maxWidth="sm" {...DialogProps}>
      {!account ? <Loader p={2} /> : (
        <Formik
          initialValues={{
            ...initialValues,
            ...account,

            office_id: account?.office,
            login_queue_id: account?.loginQueue
          }}
          validationSchema={validationSchema}
          onSubmit={editAccount}
        >
          {({ isSubmitting, handleSubmit }) => (
            <form noValidate autoComplete="off" role="presentation" onSubmit={handleSubmit}>
              <ModalHeader onClose={handleModalReject}>
                Edit account
              </ModalHeader>

              <ModalBody>
                <AccountForm />
              </ModalBody>

              <ModalFooter>
                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />
              </ModalFooter>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
