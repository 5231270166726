import { useState } from 'react';
import { Box, IconButton, makeStyles, SvgIcon, Tooltip } from '@material-ui/core';
import { mdiHelpCircle } from '@mdi/js';
import { Icon } from '@mdi/react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ContentCard } from '../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../components/WidgetsActionButtons';
import { MainContent } from './MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const OverdueBills = ({
  isOpen,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const classes = useStyles();
  const [ isFetchedData, setIsFetchedData ] = useState(false);
  const [ filterType, setFilterType ] = useState(null);
  const title = filterType === 'paid' ? 'Paid Invoices' : 'Billed Invoices';

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      isResizable={isOpen}
      title={isFetchedData ? title : 'Billing summary'}
      icon={
        !isFetchedData &&
          <Tooltip
            title="When you click on one of the bar graphs,
                it will take you to another screen breaking down the data for that figure. "
            placement="bottom-start"
          >
            <SvgIcon
              color="primary"
              className={classes.contentIcon}
            >
              <Icon path={mdiHelpCircle} />
            </SvgIcon>
          </Tooltip>
      }
      leftActions={[
        isFetchedData ?
          <IconButton
            color="primary"
            className={classes.actionContentIcon}
            onClick={() => setIsFetchedData(false)}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          :
          null
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
          isFetchedData={isFetchedData}
          setIsFetchedData={setIsFetchedData}
        />
      ]}
      {...props}
    >
      <Box minHeight={height || '100%'} height="100%">
        <MainContent
          isFetchedData={isFetchedData}
          filterType={filterType}
          setIsFetchedData={setIsFetchedData}
          setFilterType={setFilterType}
        />
      </Box>
    </ContentCard>
  );
};
