import { api } from '../../api';

export const fetchPrescriptionTemplates = (config) => {
  return api.get('/prescription-templates', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchPrescriptionTemplate = (id, config) => {
  return api.get(`/prescription-templates/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createPrescriptionTemplate = (data) => {
  return api.post('/prescription-templates', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updatePrescriptionTemplate = ({ id, ...data }) => {
  return api.put(`/prescription-templates/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deletePrescriptionTemplate = (id) => {
  return api.delete(`/prescription-templates/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
