import { store } from '../../store';

export const getAggregateTypeFromMIME = (mime) => {
  const { aggregate_types } = store.getState().apiConfig.config.files;

  for (let type in aggregate_types) if (aggregate_types.hasOwnProperty(type)) {
    if (aggregate_types[type].mime_types.find((type) => type === mime)) {
      return type;
    }
  }
};
