import { MenuItem, ListItemText, Typography } from '@material-ui/core';

export const Option = ({ children, ...props }) => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isSelected}
    component="div"

    {...props.innerProps}
  >
    <ListItemText
      disableTypography
      primary={<Typography variant="h5" color="secondary">{children}</Typography>}
      primaryTypographyProps={{ noWrap: true, component: 'div' }}
    />
  </MenuItem>
);
