import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths, widthBreakpointLarge, widthBreakpointXS } from '../Contacts';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ width }) => {
  const classes = useStyles();

  return (
    <ListRow header className={classes.root}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        User name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.case_role}>
        Case role
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.mobile_phone_number}>
          Mobile phone
        </ListRowCell>
      }

      <ListRowCell grow flexBasis={columnsWidths.email}>
        Email
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.company}>
        Company
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        Office
      </ListRowCell>

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={columnsWidths.position}>
         Position
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.action}/>
    </ListRow>
  );
};
