import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import { TextField } from '../../../../../../components/FormField';
import { Medications } from '../Medications';

export const SinglePrescription = () => {
  const { values } = useFormikContext();

  return (
    <Box flex={1}>
      <Box my={4} color="info.main">
        <Typography variant="h3" color="inherit">
          Prescriptions
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="flex-start">
        <Grid item sm={6} xs={12}>
          <TextField
            required
            name="prescription"
            label="Prescription #"
          />
        </Grid>

        <Grid item xs={12}>
          <Medications
            name="medications"
            values={values?.medications}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
