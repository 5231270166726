import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import ListAddIcon from '@material-ui/icons/PlaylistAdd';
import { setCasesLastGlobalAction } from '../../../../../store/globalActions';
import * as casesApi from '../../../../../api/cases';
import { TextField } from '../../../../../components/FormField/TextField';
import { Loader } from '../../../../../components/Loader';
import {
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalContainer
} from '../../../../../components/Modal';
import * as types from '../CasesContext/types';
import { validationSchema } from './validationSchema';

export const CaseModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    initialValues = {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const addCase = (caseData, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.createCase(caseData).then((data) => {
      dispatch(setCasesLastGlobalAction({ type: types.ADD_CASE, payload: [ data ] }));
      enqueueSnackbar('Case successfully created');
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  const updateCase = (caseData, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.updateCase({ id: initialValues.caseItem, ...caseData })
      .then((data) => {
        dispatch(setCasesLastGlobalAction({ type: types.UPDATE_CASE_IN_LIST, payload: data }));
        enqueueSnackbar('Case successfully updated');
        handleModalResolve(data);
      })
      .catch(({ status, data: { errors } = {} }) => {
        if (status === 422) {
          errors && setErrors(errors);
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues.caseItem || {
        name: ''
      }}
      validationSchema={validationSchema}
      onSubmit={!initialValues.caseItem ? addCase : updateCase}
    >
      {
        ({ handleSubmit, isSubmitting }) => (
          <Dialog {...DialogProps}>
            <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
              <ModalHeader
                icon={!initialValues.caseItem && <SvgIcon><ListAddIcon /></SvgIcon>}
                onClose={handleModalReject}
              >
                {!initialValues.caseItem ? 'Add New Case' : 'Edit Case'}
              </ModalHeader>

              <ModalBody>
                <TextField
                  required
                  name="name"
                  label="Case name"
                />
              </ModalBody>

              <ModalFooter>
                <Button onClick={handleModalReject}>Cancel</Button>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      autoFocus
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  )}
                />
              </ModalFooter>
            </ModalContainer>
          </Dialog>
        )
      }
    </Formik>
  );
};
