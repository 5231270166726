import { Fragment } from 'react';
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import { stopPropagation } from '../../../../../../helpers/dom';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Contact } from '../../../../../../components/Contact';
import { Divider } from '../../../../../../components/Divider';
import { Loader } from '../../../../../../components/Loader';
import { PreviewCompanyModal } from '../../../../Contacts/Companies/PreviewCompanyModal';
import { OfficesInfo } from './OfficesInfo';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ApplicantInfo = ({ user }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { openModal } = useModal();

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: user?.work?.company?.id }
    });
  };

  return (
    <Box width="100%" px={1} py={3}>
      <Loader loading={!user} p={3} render={
        () => (
          <Grid
            container
            spacing={2}
            component={Paper}
            square={false}
            variant="outlined"
          >
            <Grid
              item
              xs={isMobile ? 12 : 6}
              container
              spacing={isMobile ? 1 : 2}
              alignContent="flex-start"
              component={Box}
            >
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.title}>
                  <PersonIcon className={classes.listItemIcon} />

                  User Info
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography color="textSecondary">Address</Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography variant="h5">
                  {user?.home?.full_address || '-'}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography color="textSecondary">Phone</Typography>
              </Grid>

              <Grid item xs={9}>
                <Contact
                  needIcon
                  type="tel"
                  variant="h5"
                  contact={user.phone_number || user.mobile_phone_number}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography color="textSecondary">Email</Typography>
              </Grid>

              <Grid item xs={9}>
                <Contact
                  needIcon
                  type="mailto"
                  variant="h5"
                  contact={user.email}
                />
              </Grid>
            </Grid>

            {isMobile &&
              <Grid item xs={12}>
                <Divider gutter={1} type="dashed" />
              </Grid>
            }

            <Grid
              item
              xs={isMobile ? 12 : 6}
              container
              spacing={isMobile ? 1 : 2}
            >
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.title}>
                  <BusinessIcon className={classes.listItemIcon} />

                  Work Info
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="h5" className={classes.workInfoTitle}>
                  Company
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <CompanyLink
                  variant="h5"
                  company={user?.work?.company}
                  onClick={stopPropagation(openPreviewCompanyModal)}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography color="textSecondary">Address</Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography variant="h5">
                  {user?.work?.company?.full_address || '-'}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography color="textSecondary">Phone</Typography>
              </Grid>

              <Grid item xs={9}>
                <Contact
                  needIcon
                  type="tel"
                  variant="h5"
                  contact={user?.work?.company?.phone}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider type="dashed" />
              </Grid>

              {!!user?.work?.offices?.length ? user?.work?.offices?.map((office) => (
                <Fragment key={office?.id}>
                  <Grid item xs={3}>
                    <Typography variant="h5" className={classes.workInfoTitle}>
                      Office
                    </Typography>
                  </Grid>

                  <Grid item xs={9}>
                    <OfficeLink
                      variant="h5"
                      office={office}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <OfficesInfo office={office} />
                  </Grid>
                </Fragment>
              )) : (user?.work?.office ?
                <>
                  <Grid item xs={3}>
                    <Typography variant="h5" className={classes.workInfoTitle}>
                      Office
                    </Typography>
                  </Grid>

                  <Grid item xs={9}>
                    <OfficeLink
                      variant="h5"
                      office={user?.work?.office}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <OfficesInfo office={user?.work?.office} />
                  </Grid>
                </>
                :
                <>
                  <Grid item xs={3}>
                    <Typography variant="h5" className={classes.workInfoTitle}>
                      Office
                    </Typography>
                  </Grid>

                  <Grid item xs={9}>
                    <Typography color="textSecondary">No info</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}
      />
    </Box>
  );
};
