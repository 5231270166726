import { useState } from 'react';
import { fade } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { Icon } from '@mdi/react';
import { makeStyles, Box, Collapse, List, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { Fab } from '../../../../../../Fab';
import { useModal } from '../../../../../../ModalsProvider';
import { SvgIcon } from '../../../../../../SvgIcon';
import { ServiceLineFormModal } from '../../../../../ServiceLineFormModal';
import { transformServiceLineForForm } from '../../../../../ServiceLineFormModal/ServiceLineForm';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

const useStyles = makeStyles(({ palette }) => ({
  header: {
    backgroundColor: fade(palette.primary.light, 0.2),
    color: palette.info.main
  }
}));

export const columnsWidths = {
  updated_at: 100,
  certificationRevision: 100,
  lastCertification: 100,
  beginTherapy: 100,
  actions: 120
};

export const ServiceLines = ({ serviceLines, procedureIndex }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { values: claim, setFieldValue } = useFormikContext();
  const { appointment } = claim;
  const [ isCollapsed, setIsCollapsed ] = useState(false);
  const classes = useStyles();
  const { openModal } = useModal();

  const toggleCollapsed = () => {
    setIsCollapsed((state) => !state);
  };

  const addServiceLine = () => {
    openModal(ServiceLineFormModal, {
      payload: {
        claim,
        initialValues: {
          medical_procedure_id: appointment.procedures[procedureIndex].id
        }
      },

      onModalResolved: (serviceLine) => {
        setFieldValue(
          `appointment.procedures[${procedureIndex}].service_line`,
          (appointment.procedures[procedureIndex].service_line || [])
            .concat(transformServiceLineForForm({ serviceLine, appointment: claim.appointment }))
        );

        enqueueSnackbar('Service Line successfully created', { variant: 'success' });
      }
    });
  };

  return (
    <Box border={1} borderColor="divider">
      <List component="div" disablePadding className={classes.header}>
        <ListItem button onClick={toggleCollapsed}>
          <ListItemIcon>
            <SvgIcon color="info">
              <Icon path={mdiTextBoxCheckOutline} />
            </SvgIcon>
          </ListItemIcon>

          <Box display="flex" alignItems="center" mr="auto">
            <Box mr={2}>
              <Typography variant="h4" color="inherit">
                Service Lines
              </Typography>
            </Box>

            <Fab
              size="small"
              variant="extended"
              color="primary"
              startIcon={<AddIcon />}
              onClick={stopPropagation(addServiceLine)}
            >
              Add
            </Fab>
          </Box>

          {isCollapsed ? <ExpandMoreIcon color="inherit" /> : <ExpandLessIcon color="inherit" />}
        </ListItem>
      </List>

      <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
        {!serviceLines?.length ? (
          <Box p={2}>
            <Typography>No service lines</Typography>
          </Box>
        ) : (
          <>
            <TableHeader />

            {serviceLines?.map((serviceLine, index) => (
              <Row
                key={serviceLine.id}
                claim={claim}
                serviceLine={serviceLine}
                procedureIndex={procedureIndex}
                serviceLineIndex={index}
              />
            ))}
          </>
        )}
      </Collapse>
    </Box>
  );
};
