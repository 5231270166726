import { Yup } from '../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required(),
  description: Yup.string().nullable().required(),
  color: Yup.string().nullable().min(7).max(7).required(),
  _hours: Yup.number().integer().positive().nullable().typeError('Must be a number'),
  _minutes: Yup
    .number()
    .integer()
    .nullable()
    .typeError('Must be a number')
    .when('_hours', (hours, schema) => {
      return hours ? schema : schema.required().min(5);
    }),
  is_active: Yup.boolean().nullable()
});
