import { Box, FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { accidentTypesTitles } from '../../../../../components/medical/accidentTypes';
import { RadioGroup, TextField } from '../../../../../components/FormField';

export const Body = ({ form }) => {
  const { values } = useFormikContext();

  return (
    <>
      {form?.accident_type === accidentTypesTitles.car_accident && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">
              Were you the driver, passenger (front or back)? *
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <RadioGroup
              row
              name="forms.accident_information_ortho.car_accident.were_you_the_driver_passenger_front_or_back"
            >
              <FormControlLabel
                value="Driver"
                label={<Typography>Driver</Typography>}
                control={<Radio color="primary" />}
              />

              <FormControlLabel
                value="Passenger"
                label={<Typography>Passenger</Typography>}
                control={<Radio color="primary" />}
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            <TextField
              // eslint-disable-next-line max-len
              required={values.forms.accident_information_ortho?.car_accident?.were_you_the_driver_passenger_front_or_back === 'Passenger'}
              name="forms.accident_information_ortho.car_accident.were_you_the_driver_description"
              label="Describe"
              placeholder="Please describe..."
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant="h5">
              Were you driving or a passenger for UBER or LYFT? *
            </Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <RadioGroup
              row
              // eslint-disable-next-line max-len
              name="forms.accident_information_ortho.car_accident.were_you_driving_or_a_passenger_for_uber_or_lyft"
            >
              <FormControlLabel
                value="yes"
                label={<Typography>Yes</Typography>}
                control={<Radio color="primary" />}
              />

              <FormControlLabel
                value="no"
                label={<Typography>No</Typography>}
                control={<Radio color="primary" />}
              />
            </RadioGroup>
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant="h5">
              Did you go to an emergency or urgent care after the accident? *
            </Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <RadioGroup
              row
              // eslint-disable-next-line max-len
              name="forms.accident_information_ortho.car_accident.did_you_go_to_an_emergency_or_urgent_care_after_the_accident"
            >
              <FormControlLabel
                value="yes"
                label={<Typography>Yes</Typography>}
                control={<Radio color="primary" />}
              />

              <FormControlLabel
                value="no"
                label={<Typography>No</Typography>}
                control={<Radio color="primary" />}
              />
            </RadioGroup>
          </Grid>

          <Grid item md={3} xs={12}>
            <Box pl={2}>
              <Typography>If so when?</Typography>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <TextField
              label="Describe"
              placeholder="Describe..."
              // eslint-disable-next-line max-len
              required={values.forms.accident_information_ortho.car_accident.did_you_go_to_an_emergency_or_urgent_care_after_the_accident === 'yes'}
              name="forms.accident_information_ortho.car_accident.if_so_when"
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <Box pl={2}>
              <Typography>What did they do?</Typography>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <TextField
              label="Describe"
              placeholder="Describe..."
              // eslint-disable-next-line max-len
              required={values.forms.accident_information_ortho.car_accident.did_you_go_to_an_emergency_or_urgent_care_after_the_accident === 'yes'}
              name="forms.accident_information_ortho.car_accident.what_did_they_do"
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <Box pl={2}>
              <Typography>Were you prescribed any medications?</Typography>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <TextField
              label="Describe"
              placeholder="Describe..."
              // eslint-disable-next-line max-len
              required={values.forms.accident_information_ortho.car_accident.did_you_go_to_an_emergency_or_urgent_care_after_the_accident === 'yes'}
              name="forms.accident_information_ortho.car_accident.were_you_prescribed_any_medications"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant="h5">
              Was a police report filed? *
            </Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <RadioGroup
              row
              name="forms.accident_information_ortho.car_accident.was_a_police_report_filed"
            >
              <FormControlLabel
                value="yes"
                label={<Typography>Yes</Typography>}
                control={<Radio color="primary" />}
              />

              <FormControlLabel
                value="no"
                label={<Typography>No</Typography>}
                control={<Radio color="primary" />}
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">
              Briefly explain how the accident occurred: *
            </Typography>

            <TextField
              fullWidth
              multiline
              disableUnderline
              // eslint-disable-next-line max-len
              name="forms.accident_information_ortho.car_accident.briefly_explain_how_the_accident_occurred"
              placeholder="Please describe"
              margin="dense"
              variant="filled"
              rows={3}
            />
          </Grid>
        </>
      )}

      {form?.accident_type === accidentTypesTitles.slip_and_fall && (
        <>
          <Grid item md={3} xs={12}>
            <Typography variant="h5">
              Where did the accident occur: *
            </Typography>
          </Grid>

          <Grid item md={9} xs={12}>
            <TextField
              name="forms.accident_information_ortho.slip_and_fall.where_did_the_accident_occur"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant="h5">
              Was a police report filed? *
            </Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <RadioGroup
              row
              name="forms.accident_information_ortho.slip_and_fall.was_a_police_report_filed"
            >
              <FormControlLabel
                value="yes"
                label={<Typography>Yes</Typography>}
                control={<Radio color="primary" />}
              />

              <FormControlLabel
                value="no"
                label={<Typography>No</Typography>}
                control={<Radio color="primary" />}
              />
            </RadioGroup>
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant="h5">
              Did you go to an emergency or urgent care after the accident? *
            </Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <RadioGroup
              row
              // eslint-disable-next-line max-len
              name="forms.accident_information_ortho.slip_and_fall.did_you_go_to_an_emergency_or_urgent_care_after_the_accident"
            >
              <FormControlLabel
                value="yes"
                label={<Typography>Yes</Typography>}
                control={<Radio color="primary" />}
              />

              <FormControlLabel
                value="no"
                label={<Typography>No</Typography>}
                control={<Radio color="primary" />}
              />
            </RadioGroup>
          </Grid>

          <Grid item md={3} xs={12}>
            <Box pl={2}>
              <Typography>If so when?</Typography>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <TextField
              name="forms.accident_information_ortho.slip_and_fall.if_so_when"
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <Box pl={2}>
              <Typography>What did they do?</Typography>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <TextField
              name="forms.accident_information_ortho.slip_and_fall.what_did_they_do"
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <Box pl={2}>
              <Typography>Were you prescribed any medications?</Typography>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <TextField
              // eslint-disable-next-line max-len
              name="forms.accident_information_ortho.slip_and_fall.were_you_prescribed_any_medications"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">
              Briefly explain how the accident occurred: *
            </Typography>

            <TextField
              fullWidth
              multiline
              disableUnderline
              // eslint-disable-next-line max-len
              name="forms.accident_information_ortho.slip_and_fall.briefly_explain_how_the_accident_occurred"
              placeholder="Please describe"
              margin="dense"
              variant="filled"
              rows={3}
            />
          </Grid>
        </>
      )}

      {form?.accident_type === accidentTypesTitles.other && (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              disableUnderline
              name="forms.accident_information_ortho.other.text"
              placeholder="Please describe"
              margin="dense"
              variant="filled"
              rows={3}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">
              Describe the circumstances around the onset of your pain: *
            </Typography>

            <TextField
              fullWidth
              multiline
              disableUnderline
              name="forms.accident_information_ortho.other.describe_the_circumstances_around_the_onset_of_your_pain"
              placeholder="Please describe"
              margin="dense"
              variant="filled"
              rows={3}
            />
          </Grid>
        </>
      )}
    </>
  );
};
