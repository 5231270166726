import { api } from '../api';

export const login = (data) => {
  return api.post('/login', data).then(({ data }) => {
    return data;
  }).catch(({ data }) => {
    throw data;
  });
};

export const loginAs = (userId) => {
  return api.post(`/loginAs/${userId}`).then(({ data }) => {
    return data;
  }).catch(({ data }) => {
    throw data;
  });
};

export const loginConfirmation = (data) => {
  return api.post('/auth/2fa_email', data).then(({ data }) => {
    return data;
  }).catch(({ data }) => {
    throw data;
  });
};

export const publicAccessConfirmation = (data, config) => {
  return api.post('/public-access-confirmation', data, config).then(({ data }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const publicAccessRenew = (data, config) => {
  return api.put('/public-access-confirmation', data, config).then(({ data }) => {
    return data;
  }).catch(({ data }) => {
    throw data;
  });
};
