export const styles = ({ palette, typography: { fontWeightMedium } }) => ({
  fillContent: {
    maxWidth: 45,
    backgroundColor: palette.grey[200],
    borderRadius: 20,
    textAlign: 'center',
    fontWeight: fontWeightMedium
  },

  fillContent_fullfilled: {
    color: palette.primary.contrastText,
    backgroundColor: palette.darkgreen
  }
});
