export const styles = ({
  spacing,
  palette,
  typography: { pxToRem, fontWeightMedium },
  shape: { borderRadius },
  breakpoints
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    [breakpoints.up('md')]: {
      marginLeft: 4,
      marginRight: 4,
      borderRadius
    }
  },

  title: {
    fontSize: pxToRem(14)
  },

  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: spacing(1),
    overflow: 'auto',

    [breakpoints.up('md')]: {
      padding: spacing(2)
    }
  },

  header: {
    display: 'flex',
    borderWidth: 2,
    borderColor: palette.grey[100],
    padding: spacing(),

    [breakpoints.up('md')]: {
      borderWidth: 4,
      padding: spacing(1, 2),
      alignItems: 'stretch',
      minHeight: 114
    }
  },

  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    paddingLeft: spacing(2)
  },

  mainInfo__date: {
    display: 'flex',
    alignItems: 'flex-start'
  },

  mainInfo__title: {
    fontSize: pxToRem(18),
    fontWeight: fontWeightMedium,

    [breakpoints.up('md')]: {
      fontSize: pxToRem(30),
      fontWeight: 'normal'
    }
  },

  userInfo: {
    display: 'none',

    [breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      alignSelf: 'flex-start',
      flexGrow: 0
    }
  },

  userName: {
    minHeight: 32
  }
});
