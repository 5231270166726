import { useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { Loader } from '../../../../../../../components/Loader';
import { Cards } from '../../../../../../../components/Cards';
import { useResizeObserver } from '../../../../../../../helpers/hooks/useResizeObserver';
import { Card } from './Card';

export const Grid = ({ formContext }) => {
  const scrollElementRef = useRef();
  const { isFetched, forms, meta, resetForms, medicalInfoPathname = '' } = formContext;
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });

  return (
    <Box ref={rootRef} flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
        <Loader p={3} loading={!isFetched} render={
          () => !forms.length ? (
            <Box p={3}>
              <Typography align="center">No procedures found</Typography>
            </Box>
          ) : (
            <>
              <Cards
                size={width < 770 ? 300 : 370}
                list={forms}
                component={Card}
                cardProps={{ disablePatient: !!medicalInfoPathname?.length, formContext }}
              />
              <TablePagination borderTop pagination={meta} onChange={resetForms} />
            </>
          )}
        />
      </CustomScrollbars>
    </Box>
  );
};
