import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { MenuItem, ListItemIcon, ListItemText, List } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import * as appointmentsApi from '../../../api/appointments';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { Tooltip } from '../../Tooltip';
import { AppointmentsDeleteModal } from '../AppointmentsDeleteModal';
import { AppointmentEditModal } from '../AppointmentEditModal';
import { AppointmentViewModal } from '../AppointmentViewModal';
import { arrivalTypesMap } from '../arrivalTypes';

export const MenuList = ({
  appointment,
  onClose = {},
  onAppointmentUpdate = () => {},
  onAppointmentDelete = () => {}
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const viewAppointment = () => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: appointment.id,

        onAppointmentUpdate: (appointment) => {
          onAppointmentUpdate(appointment);
        },

        onAppointmentDelete: () => {
          onAppointmentUpdate(appointment);
        }
      }
    });
  };

  const editAppointment = () => {
    openModal(AppointmentEditModal, {
      payload: {
        appointmentID: appointment.id
      },

      onModalResolved: (appointment) => {
        onAppointmentUpdate(appointment);
      }
    });
  };

  const deleteAppointment = () => {
    if (appointment.group_id) {
      openModal(AppointmentsDeleteModal, {
        payload: {
          appointment
        },
        onModalResolved: () => {
          onAppointmentDelete();
        }
      });
    } else {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          appointmentsApi.deleteAppointment(appointment.id).then(() => {
            onAppointmentDelete(appointment);
            enqueueSnackbar('Appointment successfully deleted', { variant: 'success' });
          });
        }
      });
    }
  };

  return (
    <List>
      <MenuItem onClick={_.flow(viewAppointment, onClose)}>
        <ListItemIcon>
          <EyeIcon />
        </ListItemIcon>

        <ListItemText primary="View appointment" />
      </MenuItem>

      {(
        hasRole(rolesMap.client, rolesMap.patient) &&
        !appointment?.office_id &&
        appointment?.arrival_status?.arrival_type === arrivalTypesMap.waitingList
      ) && (
        <>
          <MenuItem onClick={_.flow(editAppointment, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" />
            </ListItemIcon>

            <ListItemText primary="Edit appointment" />
          </MenuItem>

          <MenuItem onClick={_.flow(deleteAppointment, onClose)}>
            <ListItemIcon>
              <DeleteIcon color="error" />
            </ListItemIcon>

            <ListItemText primary="Delete appointment" />
          </MenuItem>
        </>
      )}

      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <>
          <MenuItem onClick={_.flow(editAppointment, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" />
            </ListItemIcon>

            <ListItemText primary="Edit appointment" />
          </MenuItem>

          <Tooltip
            isExistTooltip={appointment.has_claim}
            title="Appointment is connected with a claim so it cannot be deleted"
          >
            <div>
              <MenuItem
                disabled={appointment.has_claim}
                onClick={_.flow(deleteAppointment, onClose)}
              >
                <ListItemIcon>
                  <DeleteIcon color="error" />
                </ListItemIcon>

                <ListItemText primary="Delete appointment" />
              </MenuItem>
            </div>
          </Tooltip>
        </>
      }
    </List>
  );
};
