export const styles = ({
  spacing,
  palette,
  typography: { pxToRem, fontWeightMedium }
}) => ({
  root: {
    paddingLeft: spacing(),
    paddingRight: spacing()
  },

  date: {
    fontWeight: fontWeightMedium
  },

  badge: {
    right: 8,
    bottom: 8
  },

  badgeIcon: {
    color: palette.success.main,
    backgroundColor: palette.background.default,
    fontSize: pxToRem(15),
    borderRadius: 50
  }
});
