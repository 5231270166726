import { useContext, useState } from 'react';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import { preventDefault, stopPropagation } from '../../../../../../../../helpers/dom';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../../../components/ListRow';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Checkbox } from '../../../../../../../../components/FormField';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { PreviewCompanyModal } from '../../../../../../Contacts/Companies/PreviewCompanyModal';
import { EditScheduleModal } from '../../EditScheduleModal';
import { SchedulesContext } from '../../SchedulesContext';
import { columnsWidths, minRowHeight } from '../MainContent';

export const Row = ({ schedule }) => {
  const [ isActive, setIsActive ] = useState(schedule.deactivated_at);
  const {
    selectedSchedulesIDs,
    toggleItemSelection,
    deleteFeeSchedule,
    massActiveFeeSchedules,
    massInActiveFeeSchedules
  } = useContext(SchedulesContext);
  const { openModal } = useModal();
  const isSelected = selectedSchedulesIDs.indexOf(schedule.id) !== -1;

  const deleteSchedule = () => {
    deleteFeeSchedule(schedule);
  };

  const handleItemSelection = () => {
    toggleItemSelection(schedule.id);
  };

  const toggleActiveItem = () => {
    isActive ? massActiveFeeSchedules([ schedule.id ]) : massInActiveFeeSchedules([ schedule.id ]);
    setIsActive((state) => !state);
  };

  const openScheduleModal= () => {
    openModal(EditScheduleModal, {
      payload: { parentID: schedule.id }
    });
  };

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: {
        companyID: schedule?.payer?.id
      }
    });
  };

  return (
    <ListRow selected={isSelected} minHeight={minRowHeight}>
      <ListRowCell flexBasis={columnsWidths.checkbox}>
        <Checkbox checked={isSelected} onClick={preventDefault(handleItemSelection)}/>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.payer}>
        {!!schedule?.payer?.name ?
          <CompanyLink
            noWrap
            company={schedule?.payer}
            onClick={stopPropagation(openPreviewCompanyModal)}
          />
          :
          <Typography color="textPrimary">All payers</Typography>
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.charge_amount}>
        <CurrencyFormat value={schedule.charge_amount || 0}/>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.expected_amount}>
        <CurrencyFormat value={schedule.expected_amount || 0}/>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <ListRowCellActions>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              variant="contained"
              onClick={openScheduleModal}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={isActive ? 'Activate' : 'Deactivate'}>
            <IconButton
              variant="contained"
              color={isActive ? 'warning' : 'success'}
              onClick={toggleActiveItem}
            >
              {isActive ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              edge="end"
              variant="contained"
              color="error"
              onClick={deleteSchedule}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
