import { useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  Dialog,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  SvgIcon
} from '@material-ui/core';
import * as emailsApi from '../../../../../../../../api/emails';
import { Loader } from '../../../../../../../../components/Loader';
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '../../../../../../../../components/Modal';
import { validationSchema } from '../validationSchema';
import { EmailAccountForm } from './EmailAccountForm';
import { emailConfigType, emailConfigs } from './emailConfigs';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmailsAddAccountModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const [ mailboxType, setMailboxType ] = useState(emailConfigType.gmail);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const changeMailboxType = (event) => {
    setMailboxType(event.target.value);
  };

  const createEmailAccount = (config, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return emailsApi.createEmailConfig(config).then(({ data }) => {
      enqueueSnackbar('Email account created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors?.username) {
        enqueueSnackbar('This account is already connected to another user', { variant: 'error' });
      } else {
        enqueueSnackbar('Email account not added', { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={emailConfigs[mailboxType].config}
      validationSchema={validationSchema}
      onSubmit={createEmailAccount}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Add New Account
            </ModalHeader>

            <ModalBody>
              <RadioGroup row value={mailboxType} onChange={changeMailboxType}>
                {Object.entries(emailConfigs).map(([ type, config ]) => (
                  <FormControlLabel
                    key={type}
                    value={type}
                    label={
                      <>
                        {config.icon && <SvgIcon fontSize="large"><config.icon /></SvgIcon>}
                        {config.name}
                      </>
                    }
                    control={<Radio color="primary" />}
                    classes={{ root: classes.mailboxControl, label: classes.mailboxControlLabel }}
                  />
                ))}
              </RadioGroup>

              <EmailAccountForm
                disableAdvancedSettings={mailboxType !== emailConfigType.other}
                mailboxType={mailboxType}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Create
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
