import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { getAggregateTypeFromMIME } from '../../helpers/files';
import * as filesApi from '../../api/files';
import { useModal } from '../ModalsProvider';
import { FileValidationConflictModal } from '../../app/Dashboard/files-common';

export const Attachments = ({ selectedFiles, setSelectedFiles, children, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const validateFile = (file) => {
    file.original_filename = file.original_filename || file.name;

    if (file?.original_filename?.length >= 120) {
      openModal(FileValidationConflictModal, {
        payload: {
          title: 'The original filename may not be greater than 120 characters',
          file
        },
        onModalResolved: (file) => {
          validateFile(file);
        }
      });
    } else {
      if (selectedFiles.find(({ original_filename }) => original_filename === file.original_filename)) {
        openModal(FileValidationConflictModal, {
          payload: { file },
          onModalResolved: (file) => {
            validateFile(file);
          }
        });
      } else {
        filesApi.validateFile(file).then(() => {
          file.aggregate_type = getAggregateTypeFromMIME(file.type);

          setSelectedFiles((item) => [ file ].concat(item));
        }).catch(({ status }) => {
          if (status === 409) {
            openModal(FileValidationConflictModal, {
              payload: { file },
              onModalResolved: (file) => {
                validateFile(file);
              }
            });
          } else {
            enqueueSnackbar(`File "${file.original_filename}" is invalid`, { variant: 'error' });
          }
        });
      }
    }
  };

  const handleDropAccepted = (files) => {
    files.forEach((file) => validateFile(file));
  };

  const { getInputProps, open } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: handleDropAccepted,

    ...props
  });

  return (
    <div onClick={open}>
      <input {...getInputProps()} />

      {children}
    </div>
  );
};
