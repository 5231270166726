import { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Grid, makeStyles, Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../../components/ModalsProvider';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { Fab } from '../../../../../../components/Fab';
import { AddBooksModal } from './AddBooksButton/AddBooksModal';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AvailableApptBooks = ({ formFieldName }) => {
  const { values: { id } } = useFormikContext();
  const [ , { initialValue }, { setValue } ] = useField(formFieldName);
  const [ books, setBooks ] = useState([]);
  const { openModal } = useModal();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const addBooks = () => {
    openModal(AddBooksModal, {
      onModalResolved: (newBooks) => {
        setBooks((books) => {
          const filteredBooks = newBooks.reduce((filteredBooks, appointment_book) => {
            const isNewBook = !books.find(({ appointment_book: { id } }) => appointment_book.id === id);

            return isNewBook ? filteredBooks.concat({
              appointment_book,
              appointment_book_id: appointment_book.id,
              is_current_active: true
            }) : filteredBooks;
          }, []);

          return books.concat(filteredBooks);
        });
      }
    });
  };

  const toggleActive = (bookID) => {
    setBooks((books) => books.map((book) => bookID !== book.appointment_book_id
      ? book
      : ({ ...book, is_current_active: !book.is_current_active })
    ));
  };

  const deleteBook = (bookID) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setBooks((books) => books.filter(({ appointment_book_id }) => appointment_book_id !== bookID));
      }
    });
  };

  useEffect(() => {
    if (initialValue) {
      setBooks(initialValue);
    }
  }, [ initialValue ]);

  useEffect(() => {
    setValue(books);
  }, [ books ]);

  return !!id && (
    <div className={classes.root}>
      <Box mt={4} mb={2}>
        <Grid container spacing={1} alignItems="center" component={Box} pb={1}>
          <Grid item>
            <Typography variant="h3">Available appointment books</Typography>
          </Grid>

          <Grid item>
            <Fab
              color="primary"
              variant="extended"
              size={isMobile ? 'small' : 'medium'}
              startIcon={<AddIcon />}
              onClick={addBooks}
            >
              Add appt. book
            </Fab>
          </Grid>
        </Grid>
      </Box>

      <List books={books} onToggleActive={toggleActive} onBookDelete={deleteBook} />
    </div>
  );
};
