import { useRef, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, omit } from 'lodash';
import { Box } from '@material-ui/core';
import { billingParticipantsTypesMap } from '../../../../../../../components/billing';
import { CasesSelect } from '../../../../../../../components/cases/CasesSelect';
import { rolesMap } from '../../../../../../../dataMaps';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../../../../helpers/dates';
import { saveFilter } from '../../../../../../../store/lastFilters';
import {
  TextField,
  BillingParticipantsSelect,
  InvoicesStatusesSelect,
  OrderBy,
  KeyboardDatePicker
} from '../../../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../../../components/FiltersBar';
import { AppointmentsField } from '../../../../../../../components/appointments';
import { IconComponent } from '../../../../../../../components/saved-filters';
import { ClaimsField } from '../../../../../../../components/claims';
import { InvoicesContext, orderByOptions } from '../../../InvoicesContext';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { transformRelationsForFilterToOptions } from './utils';
import { columnsMap, columnsWidths, List } from './List';
import { initialValues } from './initialValues';
import { CardContent } from './CardContent';

const MODAL_WIDTH = 1460;

export const FiltersBar = ({
  disableInitialFilters = false,
  caseItem,
  paymentForm,
  setSelectedInvoices,
  hiddenFields = [],
  filterKey
}) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { filter, relationsForFilter, changeRelationsForFilter, applyFilter } = useContext(InvoicesContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const filtersModalWidth = hiddenFields.includes(filterFieldsMap.caseId)
    ? MODAL_WIDTH - columnsWidths[columnsMap.cases]
    : MODAL_WIDTH;
  const patient = useMemo(() => {
    return caseItem?.case_users.find(({ case_role }) => case_role === rolesMap.applicant)?.user;
  }, []);

  const handleInvoiceNumberChange = debounce((event) => {
    const value = event.target.value;

    applyFilter({ number: value });
    changeRelationsForFilter({ number: value });
  }, 600);

  const handleSelectChange = (name) => (option) => {
    paymentForm && setSelectedInvoices([]);
    applyFilter({ [name]: option?.value });
    changeRelationsForFilter({ [name]: option?.data });
  };

  const handleCaseChange = (caseItem) => {
    paymentForm && setSelectedInvoices([]);
    applyFilter({ case_id: caseItem?.id });
    changeRelationsForFilter({ case_id: caseItem });
  };

  const handleUsersChange = (users) => {
    const companiesIDs = relationsForFilter?._companies?.map(({ id }) => id) || [];
    const usersIDs = users?.map(({ id }) => id) || [];

    if (paymentForm) {
      setSelectedInvoices([]);
    }

    applyFilter({ billing_participant_ids: companiesIDs.concat(usersIDs) });
    changeRelationsForFilter({ _users: users });
  };

  const handleCompaniesChange = (companies) => {
    const usersIDs = relationsForFilter?._users?.map(({ id }) => id) || [];
    const companiesIDs = companies?.map(({ id }) => id) || [];

    paymentForm && setSelectedInvoices([]);

    applyFilter({ billing_participant_ids: usersIDs.concat(companiesIDs) });
    changeRelationsForFilter({ _companies: companies });
  };

  const handleAppointmentChange = (appointment) => {
    applyFilter({ appointment_id: appointment?.id });
    changeRelationsForFilter({ appointment_id: appointment });
  };

  const handleClaimChange = (claim) => {
    applyFilter({ claim_id: claim?.id });
    changeRelationsForFilter({ claim_id: claim });
  };

  const handleDatePickerChange = (name, transformer) => (date) => {
    const transformedDate = transformer?.(date) || date;

    applyFilter(({ [name]: transformedDate }));
    changeRelationsForFilter({ [name]: transformedDate });
  };

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ order_direction: orderDirection });
    changeRelationsForFilter((state) => ({ ...state, order_direction: orderDirection }));
  };

  const applySavedFilter = (relationsForFilter) => {
    const { _users, _companies, ...filter } = relationsForFilter;
    const billingParticipants = (filter?._users || [])?.concat(filter?._companies || [])?.map(({ id }) => id);

    formikRef?.current?.setValues(transformRelationsForFilterToOptions(relationsForFilter));

    applyFilter(omit({
      ...filter,

      appointment_id: filter?.appointment_id?.id || null,
      claim_id: filter?.claim_id?.id || null,
      billing_participant_ids: billingParticipants?.length ? billingParticipants : null,
      case_id: filter?.case_id?.id,
      status_id: filter?.status_id?.value,
      order_by: filter?.order_by?.value || filter?.order_by
    }, hiddenFields));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters && !disableInitialFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      border={0}
      initialValues={transformRelationsForFilterToOptions(initialValues)}
      onReset={() => {
        changeRelationsForFilter({
          ...initialValues,

          _users: null,
          _companies: null
        });
        applySavedFilter(initialValues);
      }}
      filterKey={filterKey}
      iconComponent={!disableInitialFilters && (
        <IconComponent
          modalWidth={filtersModalWidth}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      hiddenFields={hiddenFields}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.caseId,
          label: filterFieldsLabels[filterFieldsMap.caseId],
          field: <CasesSelect
            name="case_id"
            label="All cases"
            placeholder="Search case by name..."
            onChange={handleCaseChange}
          />
        },
        {
          fieldKey: filterFieldsMap.number,
          label: filterFieldsLabels[filterFieldsMap.number],
          field: <TextField
            name="number"
            label="Inv number"
            placeholder="Search..."
            onChange={handleInvoiceNumberChange}
          />
        },
        {
          fieldKey: filterFieldsMap.users,
          label: filterFieldsLabels[filterFieldsMap.users],
          field: <Box minWidth={200}>
            <BillingParticipantsSelect
              multiple
              name="_users"
              label="Users"
              params={{ type: billingParticipantsTypesMap.user }}
              onChange={handleUsersChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.companies,
          label: filterFieldsLabels[filterFieldsMap.companies],
          field: <Box minWidth={200}>
            <BillingParticipantsSelect
              multiple
              name="_companies"
              label="Companies"
              params={{ type: billingParticipantsTypesMap.company }}
              onChange={handleCompaniesChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.appointmentId,
          label: filterFieldsLabels[filterFieldsMap.appointmentId],
          field: <AppointmentsField
            name="appointment_id"
            label="Appointment"
            placeholder="Search appt..."
            filter={{ patient: patient && [ patient.id ] }}
            onChange={handleAppointmentChange}
          />
        },
        {
          fieldKey: filterFieldsMap.claimId,
          label: filterFieldsLabels[filterFieldsMap.claimId],
          field: <ClaimsField
            name="claim_id"
            label="Claim"
            placeholder="Search claim..."
            filter={{ cases: caseItem && [ caseItem.id ] }}
            onChange={handleClaimChange}
          />
        },
        {
          fieldKey: filterFieldsMap.dueFrom,
          label: filterFieldsLabels[filterFieldsMap.dueFrom],
          field: <Box minWidth={160}>
            <KeyboardDatePicker
              clearable
              outputFormat="YYYY-MM-DD"
              name="due_date_from"
              label="From"
              onChange={handleDatePickerChange('due_date_from', unixToStartOfDayUnix)}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.dueTo,
          label: filterFieldsLabels[filterFieldsMap.dueTo],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              outputFormat="YYYY-MM-DD"
              name="due_date_to"
              label="To"
              onChange={handleDatePickerChange('due_date_to', unixToEndOfDayUnix)}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.statusId,
          label: filterFieldsLabels[filterFieldsMap.statusId],
          field: <InvoicesStatusesSelect
            isClearable
            name="status_id"
            label="Filter by statuses"
            onChange={handleSelectChange('status_id')}
          />
        },
        {
          fieldKey: filterFieldsMap.orderBy,
          label: filterFieldsLabels[filterFieldsMap.orderBy],
          field: <Box minWidth={160}>
            <OrderBy
              name="order_by"
              options={orderByOptions}
              orderDirection={filter.order_direction}
              onOptionChange={handleSelectChange('order_by')}
              onOrderDirectionChange={toggleOrderDirection}
            />
          </Box>
        }
      ]}
    />
  );
};
