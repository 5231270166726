import { useContext } from 'react';
import { flow } from 'lodash';
import {
  List,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { SelectedItemsCount } from '../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../components/VerticalDivider';
import { stopPropagation } from '../../../../helpers/dom';
import { SelectAvailableFilesModal } from '../../files-common';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { PageHeader, PageHeaderActions } from '../../../../components/Page';
import { useModal } from '../../../../components/ModalsProvider';
import { IconButton } from '../../../../components/IconButton';
import { Popper } from '../../../../components/Popper';
import { Fab } from '../../../../components/Fab';
import { Tooltip } from '../../../../components/Tooltip';
import { CopyFileModal } from '../FaxMenu/CopyFileModal';
import { FaxContext } from '../FaxProvider';
import { FilesUploadModal } from './FilesUploadModal';
import { SendFaxModal } from './SendFaxModal';

export const ActionsBar = ({ isTablet, filterOpenButton }) => {
  const { openModal } = useModal();
  const {
    filter,
    selectedFaxesIDs,
    medias,
    resetFaxes,
    deleteFaxes,
    shareByEmail,
    resetFaxesFromSelected
  } = useContext(FaxContext);
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const openAvailableFilesModal = () => {
    openModal(SelectAvailableFilesModal, {
      payload: {
        disableCoping: true,
        filter: {
          aggregate_type: 'pdf'
        }
      },

      onModalResolved: (files) => {
        openModal(SendFaxModal, {
          payload: {
            initialValues: {
              file_id: files[0]?.id
            }
          },

          onModalResolved: resetFaxes
        });
      }
    });
  };

  const copyFiles = () => {
    openModal(CopyFileModal, {
      payload: {
        mutiple: true,
        medias
      },
      onModalResolved: resetFaxesFromSelected
    });
  };

  const uploadFile = () => {
    openModal(FilesUploadModal, {
      onModalResolved: (files) => {
        openModal(SendFaxModal, {
          payload: {
            initialValues: {
              file: files[0]
            }
          },

          onModalResolved: resetFaxes
        });
      }
    });
  };

  const handleShareByEmail = () => {
    const filesIDs = medias?.map(({ id }) => id);

    shareByEmail(filesIDs);
  };

  return (
    <PageHeader>
      <Popper
        placement="bottom-start"
        anchorRenderer={({ anchorRef, handleToggle }) => (
          <Fab
            variant="extended"
            color="primary"
            startIcon={<AddIcon />}
            buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}
          >
            Send New Fax
          </Fab>
        )}
      >
        {({ handleClose }) => (
          <List>
            <MenuItem onClick={flow(uploadFile, handleClose)}>
              <ListItemIcon>
                <CloudUploadIcon />
              </ListItemIcon>

              <ListItemText primary="Upload new file" />
            </MenuItem>

            <MenuItem onClick={flow(openAvailableFilesModal, handleClose)}>
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>

              <ListItemText primary="Select from available" />
            </MenuItem>
          </List>
        )}
      </Popper>

      <PageHeaderActions disableSpacing endPosition>
        {!!selectedFaxesIDs?.length && (
          <>
            <SelectedItemsCount length={selectedFaxesIDs.length} total={filter.total} />
            <VerticalDivider verticalGutters={1} horizontalGutters={1} />

            <Tooltip title="Copy files">
              <IconButton disabled={!medias?.length} color="secondary" onClick={copyFiles}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Share files by email">
              <IconButton
                disabled={!medias?.length}
                color="info"
                onClick={handleShareByEmail}
              >
                <MailOutlinedIcon />
              </IconButton>
            </Tooltip>

            {/* TODO: open after BE updates */}
            {/*<Tooltip title="Download selected files">*/}
            {/*  <IconButton*/}
            {/*    disabled={!medias?.length}*/}
            {/*    color="primary"*/}
            {/*    onClick={handleDownloadSelectedFiles}*/}
            {/*  >*/}
            {/*    <DownloadIcon />*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}

            <Tooltip title="Delete selected faxes">
              <IconButton onClick={deleteFaxes}>
                <DeleteIcon color="error" fontSize="inherit"/>
              </IconButton>
            </Tooltip>

            <VerticalDivider verticalGutters={1} horizontalGutters={1} />
          </>
        )}

        {!isTablet ? (
          <>
            <Tooltip title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : filterOpenButton}
      </PageHeaderActions>
    </PageHeader>
  );
};
