import { FieldArray, useFormikContext } from 'formik';
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Fab,
  InputAdornment,
  useTheme,
  useMediaQuery,
  FormControlLabel
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import {
  KeyboardDatePicker,
  LabelSelect,
  Switch,
  TextField
} from '../../../../../components/FormField';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { BodyPartsSelect } from '../../../../../components/FormField';
import { DoiForm } from '../CreateCasePage/DoiForm/DoiForm';
import { statusesOptionsMap } from '../CreateCasePage/statusesOptionsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ companyID }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { openModal } = useModal();
  const { values, setFieldValue } = useFormikContext();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  const toggleRange = (_, index) => (event) => {
    const { checked } = event.target;

    if (checked) {
      setFieldValue(`doi[${index}].date`, null);
    } else {
      setFieldValue(`doi[${index}].from`, null);
      setFieldValue(`doi[${index}].to`, null);
    }
  };

  const handleChangeCode = (index) => (option) => {
    setFieldValue(`body_parts.${index}.code`, option?.code || null);
    setFieldValue(
      `body_parts.${index}.description`,
      option?.description || null
    );
  };

  return (
    <Grid container spacing={isMobile ? 1 : 2} justify="space-between" alignItems="flex-end">
      <Grid item xs={isMobile ? 12 : 3}>
        <TextField
          name="number"
          label="Claim No"
          placeholder="Select claim number..."
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 3}>
        <KeyboardDatePicker
          outputFormat="YYYY-MM-DD"
          name="date"
          label="DOS"
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 3}>
        <LabelSelect
          formattedValue
          name="status"
          label="Claim status"
          options={statusesOptionsMap}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 3}>
        <FormControlLabel
          label="Government claim"
          control={
            <Switch name="government_claim" color="primary" />
          }
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 3}>
        <KeyboardDatePicker
          outputFormat="YYYY-MM-DD"
          name="claim_filled_at"
          label="Claim filed date"
        />
      </Grid>

      {!!values?.adj?.length &&
        <Grid item xs={12}>
          <FieldArray
            name="adj"
            render={() => (
              <div className={classes.adjRoot}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography variant="h3">ADJ number</Typography>
                </Box>

                {values?.adj?.map((adj_number, index) => (
                  <Grid
                    container
                    spacing={isMobile ? 1 : 2}
                    key={index}
                    direction="row"
                    alignItems="flex-end"
                    justify={isMobile ? 'flex-end' : 'flex-start'}
                    wrap={isMobile ? 'wrap' : 'nowrap'}
                  >
                    <Grid item>
                      {index + 1}.
                    </Grid>

                    <Grid item xs={isMobile ? 11 : 4}>
                      <KeyboardDatePicker
                        required
                        zeroMinWidth
                        label="Date"
                        name={`adj[${index}].date`}
                        placeholder="Select a date"
                        outputFormat="YYYY-MM-DD"
                      />
                    </Grid>

                    <Grid
                      item
                      xs={isMobile ? 11 : 8}
                      container
                      direction="row"
                      alignItems="center"
                      wrap="nowrap"
                      className={classes.adjContent}
                    >
                      <TextField
                        multiline
                        required
                        label="EAMS number"
                        name={`adj[${index}].number`}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              disableTypography
                              position="start"
                              className={classes.defaultText}
                            >
                              ADJ
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </div>
            )}
          />
        </Grid>
      }

      <Grid item xs={12}>
        <DoiForm
          name="doi"
          toggleRange={toggleRange}
          handleClearField={handleClearField}
        />
      </Grid>

      <Grid item xs={12}>
        <FieldArray
          name="body_parts"
          render={(arrayHelpers) => (
            <>
              <Box display="flex" alignItems="center" mt={isMobile ? 0 : 3} mb={1}>
                <Typography variant="h3">Injured body parts</Typography>

                <Box component="span" ml={2}>
                  <Fab
                    type="button"
                    color="primary"
                    size="small"
                    onClick={() => arrayHelpers.push({ id: '', note: '' })}
                  >
                    <AddIcon fontSize="small" />
                  </Fab>
                </Box>
              </Box>

              {values?.body_parts?.map((item, index) => (
                <div key={index} className={classes.doiRoot}>
                  <Typography>{index + 1}.</Typography>

                  <Box display="flex" flexDirection="column" flexGrow={1} ml={1}>
                    <BodyPartsSelect
                      name={`body_parts[${index}].id`}
                      onChange={handleChangeCode(index)}
                    />

                    <TextField
                      multiline
                      rowsMax={5}
                      disabled={!values?.body_parts[index].id}
                      label={
                        <div className={classes.notesIcon}>
                          <NotesIcon fontSize="small"/>

                          {values?.body_parts[index]?.notes ? 'Note' : 'Add note'}
                        </div>
                      }
                      InputProps={{
                        className: classes.notesInput
                      }}
                      name={`body_parts[${index}].note`}
                    />
                  </Box>

                  <IconButton
                    size="small"
                    color="error"
                    onClick={handleClearField(arrayHelpers, index)}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </div>
              ))}
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};
