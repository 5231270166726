import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { getProviderIdType } from '../../../utils/getProviderIdType';

export const MedicUserInfo = ({ user }) => {
  const lic = getProviderIdType(user?.provider_id_types, 'LIC');
  const npi = getProviderIdType(user?.provider_id_types, 'NPI');

  return (
    <Grid container spacing={1} alignItems="center" component={Box} pb={2}>
      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Position/Specialty</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="h5">
          {user?.work?.position || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Prov. Type</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="h5">
          {user?.work?.provider_type || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Department</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="h5">
          {user?.work?.department || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">NPI</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="h5">
          {npi?.identifier || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Rate</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="h5">
          $ {user?.rate || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">LIC#</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="h5">
          {lic?.identifier || '-'}
        </Typography>
      </Grid>

      <Hidden xsDown>
        <Grid item xs={6} />
      </Hidden>

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Tax ID</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="h5">
          {user?.work?.taxonomy || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};
