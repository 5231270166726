import { useState } from 'react';
import { Box, Collapse, Grid, Typography } from '@material-ui/core';
import { Button } from '../../../../../../components/Button';
import { Contact } from '../../../../../../components/Contact';

export const OfficesInfo = ({ office }) => {
  const [ isOpenOffice, setIsOpenOffice ] = useState(false);

  const toggleOfficeCollapse = () => {
    setIsOpenOffice((state) => !state);
  };

  return (
    <>
      <Collapse in={isOpenOffice}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography color="textSecondary">Address</Typography>
          </Grid>

          <Grid item xs={9}>
            <Typography variant="h5">
              {office?.full_address || '-'}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography color="textSecondary">Phone</Typography>
          </Grid>

          <Grid item xs={9}>
            <Contact
              needIcon
              type="tel"
              variant="h5"
              contact={office?.phone}
            />
          </Grid>

          <Grid item xs={3}>
            <Typography color="textSecondary">Fax</Typography>
          </Grid>

          <Grid item xs={9}>
            <Contact
              needIcon
              type="tel"
              variant="h5"
              contact={office?.fax}
            />
          </Grid>

          <Grid item xs={3}>
            <Typography color="textSecondary">Email</Typography>
          </Grid>

          <Grid item xs={9}>
            <Contact
              needIcon
              type="mailto"
              variant="h5"
              contact={office?.email}
            />
          </Grid>
        </Grid>
      </Collapse>

      <Box mt={isOpenOffice ? 0 : -2} display="flex" justifyContent="flex-end">
        <Button size="small" color="primary" onClick={toggleOfficeCollapse}>
          {isOpenOffice ? 'less' : 'more'}
        </Button>
      </Box>
    </>
  );
};
