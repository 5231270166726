import { useState } from 'react';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import { useModal } from '../../../../components/ModalsProvider';
// import { useVoiceRecognition } from '../../../../utils/useVoiceRecognition';
import { IconButton } from '../../../../components/IconButton';
import { Tooltip } from '../../../../components/Tooltip';
import { VoiceRecognitionModal } from './VoiceRecognitionModal';

export const VoiceRecognitionButton = ({ editorRef }) => {
  const { openModal } = useModal();
  const [ isSpeechRecognitionEnabled ] = useState(false);

  const toggleSpeechRecognition = () => {
    openModal(VoiceRecognitionModal, {
      onModalResolved: (recognizedText) => {
        editorRef.current?.insertHtml?.(recognizedText.replaceAll('\n', '</br>'));
      }
    });

    // setIsSpeechRecognitionEnabled((isSpeechRecognitionEnabled) => {
    //   if (isSpeechRecognitionEnabled) {
    //     stopRecognition();
    //   } else {
    //     startRecognition();
    //   }
    //
    //   return !isSpeechRecognitionEnabled;
    // });
  };

  // useEffect(() => {
  //   if (isSpeechRecognitionEnabled && !isRecognitionInProgress) {
  //     editorRef.current?.editor?.insertText?.(lastRecognizedText + (lastRecognizedText ? ' ' : ''));
  //   }
  // }, [
  //   isSpeechRecognitionEnabled,
  //   isRecognitionInProgress,
  //   lastRecognizedText
  // ]);

  return (
    <Tooltip
      title={isSpeechRecognitionEnabled ? 'Stop speech recognition': 'Start speech recognition'}
    >
      <IconButton onClick={toggleSpeechRecognition}>
        {isSpeechRecognitionEnabled
          ? <RecordVoiceOverIcon />
          : <RecordVoiceOverOutlinedIcon />
        }
      </IconButton>
    </Tooltip>
  );
};
