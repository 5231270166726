export const styles = ({
  palette: { divider, grey, ...palette },
  breakpoints,
  transitions
}) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    border: `1px solid ${divider}`
  },

  sidebar: {
    flexShrink: 0,
    height: '100%',
    transition: transitions.create([ 'width' ]),

    [breakpoints.up('lg')]: {
      width: 290
    },

    [breakpoints.down('md')]: {
      width: 0,
      overflow: 'hidden'
    }
  },

  sidebarOpen: {
    width: 290,
    transition: transitions.create([ 'width' ]),

    [breakpoints.down(breakpoints.values.sm)]: {
      width: '100%'
    }
  },

  main: {
    flexGrow: 1,
    height: '100%'
  },

  tabsWrapper: {
    [breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      width: '100%'
    }
  },

  navigation: {
    backgroundColor: palette.primary.contrastText
  }
});
