import { Box, makeStyles, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Avatar } from '../../../../../../../components/Avatar';
import { IconButton } from '../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { EditCompanyModal } from '../../../EditCompanyModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ company, setCompany }) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const handleUpdateCompany = () => {
    openModal(EditCompanyModal, {
      payload: {
        company_id: company.id
      },
      onModalResolved: (data) => setCompany(data)
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Avatar
          size="xxxl"
          src={company.avatar}
          alt={company.name[0].toUpperCase()}
          className={classes.logoAvatar}
        />

        <Box ml={14} mr={4}>
          <Typography
            noWrap
            color="inherit"
            title={company.name}
            variant="h2"
          >
            {company.name}
          </Typography>
        </Box>
      </div>

      <IconButton className={classes.action} onClick={handleUpdateCompany}>
        <EditIcon />
      </IconButton>
    </div>
  );
};
