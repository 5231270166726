import { useTheme } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import { expensesCategoryMap } from '../../../../../../../../components/billing';

export const Chart = ({ data, labels }) => {
  const { palette } = useTheme();
  const chartData = data.map((item) => item.total);
  const backgroundColor = labels.map((item) => {
    return expensesCategoryMap[item] ? expensesCategoryMap[item].color : palette.grey[200];
  });

  const getChartData = () => ({
    labels,
    datasets: [
      {
        backgroundColor,
        hoverBackgroundColor: backgroundColor,
        data: chartData
      }
    ]
  });

  const chartOptions = {
    maintainAspectRatio: false,
    legend:{
      display:false
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem, data) => {
          const dataSets = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const labels = data.labels[tooltipItem.index];

          return labels + ' $ ' + new Intl.NumberFormat('en-US').format(dataSets);
        }
      }
    }
  };

  return (
    <Doughnut options={chartOptions} data={getChartData()} />
  );
};
