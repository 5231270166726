import { api } from '../api';

export const fetchClaims = (config) => {
  return api.get('/claims', config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => { throw error; });
};

export const fetchClaim = (id, config) => {
  return api.get(`/claims/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createClaim = (data) => {
  return api.post('/claims', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const updateClaim = ({ id, ...data }) => {
  return api.put(`/claims/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteClaim = (id) => {
  return api.delete(`/claims/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchClaimStatuses = (config = {}) => {
  return api.get('/claim/statuses', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const getClaimPDF = (claimID, config) => {
  return api.get(`/hicf-form/${claimID}/pdf`, {
    responseType: 'arraybuffer',
    ...config
  })
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
