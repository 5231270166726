import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { List as MuiList, Typography, useMediaQuery } from '@material-ui/core';
import { CustomScrollbars } from '../CustomScrollbars';
import { Loader } from '../Loader';
import { InfiniteListLoader } from '../InfiniteLoader';
import { ActivityContext } from './ActivityProvider';
import { Row } from './Row';

export const minRowHeight = 54;

export const Activities = ({ maxHeight, parent, parentType, disableVirtual }) => {
  const {
    activities,
    isFetching,
    isFetched,
    filter: { per_page, last_page, page },
    loadNextPage,
    resetActivities
  } = useContext(ActivityContext);
  const scrollElementRef = useRef();
  const tasksLastGlobalAction = useSelector(({ globalActions }) => globalActions.tasksLastGlobalAction);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.md));

  useEffect(() => {
    resetActivities();
  }, [ tasksLastGlobalAction ]);

  return (
    <CustomScrollbars
      translateContentSizesToHolder={!!disableVirtual}
      scrollerRef={scrollElementRef}
      style={disableVirtual ? { maxHeight: 400 } : { height: isMobile && maxHeight ? maxHeight : '100%' }}
    >
      <Loader loading={!isFetched} render={
        () => (!activities.length ?
          <Typography align="center">No activities found</Typography>
          :
          <MuiList disablePadding>
            <InfiniteListLoader
              items={activities}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={{ per_page, last_page, page }}
              RowComponent={Row}
              RowProps={{ parent, parentType }}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
            />
          </MuiList>
        )}
      />
    </CustomScrollbars>
  );
};
