export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  },

  indicators: {
    padding: spacing(2),
    marginBottom: spacing(2)
  },

  reminders: {
    flexGrow: 1
  }
});
