import { withStyles } from '@material-ui/core';
import { height } from './ViewControls';

const styles = {
  root: {
    minHeight: height
  }
};

export const ViewControlsStub = withStyles(styles)(({ classes }) => <div className={classes.root} />);
