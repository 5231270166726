import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { useFormikContext } from 'formik';
import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CurrencyField, TextField } from '../../../../../components/FormField';
import { invoiceBlocksMap, invoicesTypesMap } from '../../../../../components/billing';
import { Divider } from '../../../../../components/Divider';
import { Body } from '../EditInvoice/Body';
import { Expenses } from '../Expenses';
import { IncomesContent } from './IncomesContent';
import { BillInfo } from './BillInfo';
import { MainInfo } from './MainInfo';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FormContent = ({
  isEditable = false,
  totalTime,
  expenses,
  onTotalTimeUpdate = () => {},
  onExpensesUpdate = () => {}
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { values, setFieldValue } = useFormikContext();

  const handleUpdateTotal = (incomeOrExpense, name, isAdd = false) => {
    let total = +values?.total;

    if (isAdd) {
      const totalVal = name === invoiceBlocksMap.invoices ? incomeOrExpense?.total : incomeOrExpense?.amount;

      total = total + +totalVal;

      return setFieldValue('total', total);
    }

    let totalIncomes = 0;
    let totalExpenses = 0;

    if (name === invoiceBlocksMap.invoices) {
      const incomesResult = total - +incomeOrExpense?.total;

      if (incomesResult > 0) {
        total = incomesResult;

        return setFieldValue('total', total);
      }

      totalIncomes = values.incomes
        ?.filter((income) => !isEqual(income, incomeOrExpense))
        ?.reduce((prev, next) => prev + +next.total, 0);
      totalExpenses = expenses.reduce((prev, next) => prev + +next.amount, 0);

      total = totalIncomes + totalExpenses;
    } else {
      const expensesResult = total - +incomeOrExpense?.amount;

      if (expensesResult > 0) {
        total = expensesResult;

        return setFieldValue('total', total);
      }

      totalIncomes = values.incomes?.reduce((prev, next) => prev + +next.total, 0);
      totalExpenses = expenses?.filter((expense) => expense.id !== incomeOrExpense?.id)
        ?.reduce((prev, next) => prev + +next.amount, 0);

      total = totalIncomes + totalExpenses;
    }

    setFieldValue('total', total);
  };

  useEffect(() => {
    if (!isEditable || !values.total) {
      const totalIncomes = values.incomes.reduce((prev, next) => prev + +next.total, 0);
      const totalExpenses = expenses.reduce((prev, next) => prev + +next.amount, 0);

      setFieldValue('total', totalIncomes + totalExpenses);
    }
  }, [ values.incomes, expenses ]);

  return (
    <>
      <div className={classes.mainInfo}>
        <MainInfo />
      </div>

      <BillInfo />
      <Divider gutter={isMobile ? 2 : 5} />

      {values.model_type === invoicesTypesMap.case && (
        <Box mb={2}>
          <IncomesContent
            totalTime={totalTime}
            onTotalTimeUpdate={onTotalTimeUpdate}
            onUpdateMainTotal={isEditable ? handleUpdateTotal : undefined}
          />
        </Box>
      )}

      <Box mb={2}>
        <Expenses
          invoice={isEditable && values}
          expenses={expenses}
          onExpensesUpdate={onExpensesUpdate}
          onUpdateMainTotal={isEditable ? handleUpdateTotal : undefined}
        />
      </Box>

      {isEditable ? <Body /> :
        <div className={classes.footer}>
          <div className={classes.note}>
            <TextField
              multiline
              rows={8}
              variant="filled"
              name="notes"
              label="Notes"
              placeholder="Enter notes..."
            />
          </div>

          <Box minWidth={isMobile ? '100%' : 320} px={isMobile ? 0 : 2} mb={isMobile ? 1 : 0}>
            <Typography paragraph variant="h4">Total</Typography>

            <CurrencyField name="total" />
          </Box>
        </div>
      }
    </>
  );
};
