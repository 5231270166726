import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Dialog, SvgIcon, Fab } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/HistoryOutlined';
import { Yup } from '../../../../utils/validation';
import { setFilesLastGlobalAction } from '../../../../store/globalActions';
import * as documentsApi from '../../../../api/files/html-documents';
import TemplateSvg from '../../../../components/icons/template.svg';
import EditFileSvg from '../../../../components/icons/file-edit.svg';
import { ModalHeader, ModalBody, ModalContainer } from '../../../../components/Modal';
import { useModal } from '../../../../components/ModalsProvider';
import { Loader } from '../../../../components/Loader';
import { TextField } from '../../../../components/FormField';
import { VoiceRecognitionButton } from '../VoiceRecognitionButton';
import { getPrintStyles } from '../DocumentEditor/getPrintStyles';
import { addReplaceableDataTypePlugin } from '../DocumentEditor/replaceableDataTypePlugin';
import * as filesActionTypes from '../FilesContext/types';
import { MoveFileModal } from '../MoveFileModal';
import { SelectTemplateModal } from '../SelectTemplateModal';
import { DocumentEditor } from '../DocumentEditor';
import { InfoBar } from './InfoBar';
import { DocumentVersionsModal } from './DocumentVersionsModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EditDocumentModal = ({
  DialogProps,
  payload: { documentID },
  handleModalReject
}) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const editorRef = useRef();
  const [ document, setDocument ] = useState();
  const [ editorCss, setEditorCss ] = useState('');
  const availablePaperSizes = useSelector(({ apiConfig }) => apiConfig.config.html_documents.page_sizes);

  const openVersionsPreview = () => {
    openModal(DocumentVersionsModal, {
      payload: {
        documentID: document.id,
        onVersionChange: (version) => {
          editorRef.current.setData(version.html);
          enqueueSnackbar(
            `Document successfully checkout to ${moment.unix(version.created_at).format('L LT')} version`
          );
        }
      }
    });
  };

  const handleEditorInit = (CKEDITOR) => {
    addReplaceableDataTypePlugin({ CKEDITOR });
  };

  const handleAddTemplate = () => {
    openModal(SelectTemplateModal, {
      onModalResolved: ({ isReplaceable, template }) => {
        editorRef.current[isReplaceable ? 'setData' : 'insertHtml'](template.html);
      }
    });
  };

  const handleFileOwnerChange = () => {
    openModal(MoveFileModal, {
      payload: {
        file: document.file
      },
      onModalResolved: (file) => {
        setDocument((document) => ({ ...document, file }));
      }
    });
  };

  const saveDocument = ({ name }, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const html = editorRef.current.getData();

    if (!html) {
      setSubmitting(false);

      enqueueSnackbar('The document cannot be empty', {
        variant: 'error'
      });

      return;
    }

    return documentsApi.updateDocument({
      ...document,
      name,
      html,
      css: editorCss + getPrintStyles()
    }).then((data) => {
      enqueueSnackbar('Document successfully updated');
      dispatch(setFilesLastGlobalAction({ type: filesActionTypes.UPDATE_FILE, payload: data.file }));
      setDocument(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  useEffect(() => {
    documentsApi.getDocument(documentID).then(setDocument);
  }, []);

  return (
    <Dialog
      disableEnforceFocus
      maxWidth="lg"
      PaperProps={{ style: { height: '100%' } }}
      {...DialogProps}
    >
      <ModalContainer>
        <ModalHeader icon={<SvgIcon><EditFileSvg /></SvgIcon>} onClose={handleModalReject}>
          Edit document
        </ModalHeader>

        <ModalBody disablePaddings>
          {!document ? <Loader p={2} /> :
            <Formik
              enableReinitialize
              initialValues={{
                name: document.name
              }}
              validationSchema={Yup.object().shape({ name: Yup.string().required().min(2).max(255) })}
              onSubmit={saveDocument}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form noValidate className={classes.form} onSubmit={handleSubmit}>
                  <InfoBar
                    isSubmitting={isSubmitting}
                    document={document}
                    onFileOwnerChange={handleFileOwnerChange}
                  />

                  <div className={classes.top}>
                    <TextField
                      required
                      name="name"
                      label="Document name"
                      placeholder="Enter file name..."
                      margin="dense"
                      className={classes.title}
                    />

                    <VoiceRecognitionButton editorRef={editorRef} />

                    <Fab
                      color="secondary"
                      title="Use template"
                      onClick={handleAddTemplate}
                    >
                      <SvgIcon fontSize="small"><TemplateSvg /></SvgIcon>
                    </Fab>

                    <Fab
                      color="secondary"
                      title="Show versions"
                      onClick={openVersionsPreview}
                    >
                      <HistoryIcon fontSize="small" />
                    </Fab>
                  </div>

                  <div className={classes.bottom}>
                    <DocumentEditor
                      ref={editorRef}
                      initData={document.html}
                      paperSize={availablePaperSizes[document.page_size]}
                      paperMargins={document.page_fields}
                      onBeforeLoad={handleEditorInit}
                      onStylesReady={setEditorCss}
                    />
                  </div>

                  {/*<Drawer anchor="right" open={versionsIsOpen} onClose={toggleVersionsOpen}>*/}
                  {/*  <VersionsList*/}
                  {/*    documentID={documentID}*/}
                  {/*    onClose={toggleVersionsOpen}*/}
                  {/*    onVersionSelect={handleVersionSelect}*/}
                  {/*  />*/}
                  {/*</Drawer>*/}
                </form>
              )}
            </Formik>
          }
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
