import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_ACTIVITIES_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_ACTIVITIES_WITH_PAGINATION_SUCCESS]: (
    { filter, insurances, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      activities: data
    };
  },

  [types.FETCH_ACTIVITIES_SUCCESS]: (
    { filter, activities, ...state },
    { data: newActivities, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      activities: page > 1 ? (
        activities.filter(({ id }) => !newActivities.find((loadedActivity) => {
          return id === loadedActivity.id;
        })).concat(newActivities)
      ) : (
        newActivities
      )
    };
  },

  [types.FETCH_ACTIVITIES_ERROR]: (state) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      error: 'You do not have enough rights'
    };
  },

  [types.RESET_ACTIVITIES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  }
});
