import { chessBackground } from '../../../../../../../assets/chessBackground';

export const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    height: ({ scale }) => scale > 1 ? null : '100%'
  },

  image: {
    ...chessBackground,

    maxWidth: ({ scale }) => scale > 1 ? 'none' : '100%',
    maxHeight: ({ scale }) => scale > 1 ? 'none' : '100%',
    margin: 'auto',
    transition: '0.3s'
  },

  loader: {
    margin: 'auto'
  }
};
