import { api } from '../api';

export const fetchStatuses = (config) => (
  api.get('/tasks/statuses', config)
    .then((data) => data)
    .catch((error) => { throw error.data; })
);

export const createStatus = (status) => (
  api.post('/tasks/statuses', status)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; })
);

export const updateStatus = (status) => (
  api.put(`/tasks/statuses/${status.id}`, status)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; })
);

export const deleteStatus = (id) => (
  api.delete(`/tasks/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; })
);
