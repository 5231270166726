import Color from 'color';

export const styles = ({
  spacing,
  palette: { divider, primary },
  typography: { pxToRem },
  zIndex
}) => ({
  uploadTrigger: {
    height: '100%',
    width: '100%'
  },

  uploadTrigger_active: {
    position: 'relative',

    '& $childrenWrapper': {
      filter: 'blur(2px)'
    }
  },

  uploadTrigger_hover: {
    background: divider
  },

  uploadIcon: {
    marginRight: spacing()
  },

  childrenWrapper: {
    height: '100%'
  },

  backdrop: {
    zIndex: zIndex.appBar + 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: Color(primary.main).alpha(0.1).string(),
    border: `2px solid ${primary.main}`
  },

  backdrop__iconContainer: {
    padding: spacing(3),
    border: `1px solid ${primary.main}`,
    borderRadius: '50%',
    background: Color(primary.main).alpha(0.4).string(),
    color: primary.contrastText,
    animation: 'pulse .5s infinite alternate'
  },

  backdrop__icon: {
    fontSize: pxToRem(160)
  },

  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(1.1)'
    }
  }
});
