export const styles = ({ spacing, palette, shape: { borderRadius }, shadows }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    height: 70,
    marginTop: spacing(2),
    backgroundColor: palette.grey[25],
    overflow: 'hidden',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
    borderRadius
  },

  noteContent: {
    overflow: 'hidden',
    position: 'relative',
    maxHeight: 40
  },

  noteContent_cropped: {
    '&:before': {
      content: '"..."',
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: 48,
      marginLeft: -spacing(6),
      paddingRight: spacing(),
      textAlign: 'right',
      color: palette.text.secondary,
      background: 'linear-gradient(to right, rgba(255, 255, 255, 0), white 40%, white)',
      zIndex: 1
    }
  },

  filePreviewIcon: {
    fontSize: 80
  },

  filePreview: {
    alignSelf: 'flex-start',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
});
