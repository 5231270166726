import { Date, Text, Bool, EventDescription, Office, Case, EventType } from '../../fields';

export const eventFieldsMap = {
  description: (before, after) => <EventDescription before={before} after={after} fieldLabel="Description" />,
  finished_at: (before, after) => <Date before={before} after={after} fieldLabel="Finished" />,
  started_at: (before, after) => <Date before={before} after={after} fieldLabel="Started" />,
  is_duration_corresponds_to_type: (before, after) => (
    <Bool before={before} after={after} fieldLabel="Duration corresponds to type" />
  ),
  is_system: (before, after) => <Bool before={before} after={after} fieldLabel="System" />,
  office: (before, after) => <Office before={before} after={after} fieldLabel="Office" />,
  parent: (before, after) => <Case before={before} after={after} fieldLabel="Case" />,
  title: (before, after) => <Text before={before} after={after} fieldLabel="Title" />,
  timezone: (before, after) => <Text before={before} after={after} fieldLabel="Timezone" />,
  type: (before, after) => <EventType before={before} after={after} fieldLabel="Event Type" />
};
