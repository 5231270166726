import { Box } from '@material-ui/core';
import { negotiationsFieldsMap } from './fieldsMaps/negotiationsFieldsMap';
import { WidgetActivity } from './WidgetActivity';

export const Negotiations = ({
  isDeleted = false,
  newNegotiation = false,
  fieldLabel,
  activityLabel,
  payload
}) => {
  if (isDeleted) {
    return 'Removed Negotiation';
  }

  return (
    <>
      {newNegotiation && (
        <Box component="span" display="flex" alignItems="center">
          Added new Negotiation
        </Box>
      )}

      <WidgetActivity
        {...payload?.properties}
        showInfo
        fieldLabel={fieldLabel}
        activityLabel={activityLabel}
        fieldsMap={negotiationsFieldsMap}
      />
    </>
  );
};
