export const styles = ({
  spacing,
  palette: { grey, primary }
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: spacing()
  },

  contact: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    width: '70%'
  },

  contactTitle: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    paddingRight: spacing()
  },

  contactClient: {
    color: grey[400]
  },

  contactCompany: {
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  avatar: {
    marginRight: spacing(),
    width: 35,
    height: 35
  },

  statusLabel: {
    color: primary.contrastText
  }
});
