import moment from 'moment';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { useContext } from 'react';
import { AddressLink } from '../../../../AddressLink';
import { AppointmentsContext, AppointmentViewModal, arrivalTypes } from '../../../../appointments';
import { Date } from '../../../../Date';
import { Label } from '../../../../Label';
import { Loader } from '../../../../Loader';
import { useModal } from '../../../../ModalsProvider';
import { ReadonlyCheckbox } from '../../../../ReadonlyCheckbox';
import { UserLink } from '../../../../UserLink';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  width,
  item: appointment = {},
  isLoaded
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { reloadAppointments } = useContext(AppointmentsContext);

  const openPreview = () => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: appointment.id,
        onAppointmentUpdate: reloadAppointments,
        onAppointmentDelete: reloadAppointments
      }
    });
  };

  return !isLoaded ? (
    <Box display="flex" alignItems="center" height={80} p={2}>
      <Loader />
    </Box>
  ) : (
    <div className={classes.root} onClick={openPreview}>
      <Grid container spacing={2} justify="space-between" wrap="nowrap">
        <Box clone flexShrink={0} minWidth={172} style={{ marginRight: width > 440 ? '50px' : '0' }}>
          <Grid item>
            <Grid container>
              <Grid item >
                <Date color="primary" date={appointment?.appointment_at} format="L" />
              </Grid>
            </Grid>

            <Grid container spacing={1} wrap="nowrap" alignItems="center">
              <Grid item>
                <AlarmOnOutlinedIcon fontSize="small" />
              </Grid>

              <Grid item>
                <Typography variant="body1">
                  {appointment.appointment_at && appointment.time
                    ? moment.unix(appointment.appointment_at).format('LT')
                    + ' - '
                    + moment.unix(appointment.appointment_at).add(appointment.time, 'minutes').format('LT')
                    : '-'
                  }
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} wrap="nowrap" alignItems="center">
              <Grid item>
                <TimelapseIcon fontSize="small" />
              </Grid>

              <Grid item>
                <Typography variant="body1">
                  {moment.duration(appointment.time, 'seconds').format('h[h] m[min]')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box clone flexGrow={1} overflow="hidden">
          <Grid item zeroMinWidth>
            <Grid container spacing={1} alignItems="center">
              <Grid container>
                <Grid item>
                  <UserLink
                    size="xs"
                    color="inherit"
                    variant="subtitle1"
                    user={appointment?.appointment_book?.provider}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} wrap="nowrap" alignItems="center">
                {!appointment.arrival_status ? '-' : (
                  <Grid item zeroMinWidth>
                    <Box mt={0.5}>
                      <Label
                        color={arrivalTypes[appointment?.arrival_status?.arrival_type]?.color}
                        label={
                          <Typography noWrap component="span">
                            {appointment?.arrival_status?.description}
                          </Typography>
                        }
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>

              <Grid container>
                <Grid item>
                  <Box mt={0.5}>
                    <Typography>
                      {appointment?.appointment_type?.description}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item>
                  <Box mt={0.8} ml={0.8}>
                    <ReadonlyCheckbox disableTitle checked={!!appointment?.appointment_confirmed_by_office}/>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {!!appointment.office?.full_address &&
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <LocationOnOutlinedIcon fontSize="small" />
          </Grid>

          <Grid item zeroMinWidth>
            <AddressLink stopPropagation address={appointment.office?.full_address}>
              {appointment.office?.full_address}
            </AddressLink>
          </Grid>
        </Grid>
      }
    </div>
  );
};
