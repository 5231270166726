export const styles = ({ spacing, palette: { primary }, transitions }) => ({
  root: {
    paddingLeft: spacing(),
    paddingRight: spacing(),
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      backgroundColor: 'rgba(0, 188, 212, 0.05)',
      borderBottom: `1px solid ${primary.main}`
    }
  }
});
