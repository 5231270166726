export const styles = ({ palette }) => ({
  row: {
    borderLeft: `1px solid ${palette.divider}`,
    borderRight: `1px solid ${palette.divider}`
  },

  row_header: {
    borderTop: `1px solid ${palette.divider}`
  }
});
