import { useMemo } from 'react';
import { Formik } from 'formik';
import { Dialog, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Yup } from '../../../../../utils/validation';
import * as filesApi from '../../../../../api/files';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { TextField } from '../../../../../components/FormField';
import { pagesSelectingTypesMap } from '../pagesSelectingTypes';

export const GeneratePdfModal = ({
  DialogProps,
  payload: {
    documents
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const files = useMemo(() => {
    return documents.map(({ id, pagesSelectingType, pagesList, pages }) => {
      const isAll = pagesSelectingType === pagesSelectingTypesMap.all;
      const isIncluding = pagesSelectingType === pagesSelectingTypesMap.include;

      return {
        id,
        pages: isAll ? [] : isIncluding ? pages : pagesList.filter((page) => !pages.includes(page))
      };
    });
  }, [ documents ]);

  const generateDocument = ({ filename }, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return filesApi.generatePdf({
      files,
      filename: filename + '.pdf'
    }).then(() => {
      enqueueSnackbar('The document will be created in the background, you will receive a notification', {
        variant: 'success'
      });
      handleModalResolve();
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        setErrors({
          ...errors,
          filename: errors.extension || errors.filename
        });
      } else if (status === 409) {
        setErrors({
          filename: [ 'File with same name is now exist' ]
        });
      }
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          filename: null
        }}
        validationSchema={Yup.object().shape({ filename: Yup.string().nullable().required().max(120) })}
        onSubmit={generateDocument}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Generate PDF
            </ModalHeader>

            <ModalBody>
              <TextField
                required
                name="filename"
                label="File name"
                placeholder="Enter file name..."
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button
                variant="contained"
                color="primary"
                onClick={handleModalReject}
              >
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Generate
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
