export const styles = ({ spacing }) => ({
  header: {
    padding: spacing(0, 3)
  },

  actionBar: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  }
});
