export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexGrow: 1,
    paddingTop: spacing()
  },

  notes: {
    paddingTop: spacing(),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap'
  },

  avatarIcon: {
    marginRight: spacing()
  }
});
