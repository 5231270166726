export const FETCH_SCHEDULE_REQUEST = 'FETCH_SCHEDULE_REQUEST';
export const FETCH_SCHEDULE_SUCCESS = 'FETCH_SCHEDULE_SUCCESS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const RESET_SCHEDULE = 'RESET_SCHEDULE';
export const ADD_SCHEDULE_EVENT = 'ADD_SCHEDULE_EVENT';
export const UPDATE_SCHEDULE_EVENT = 'UPDATE_VISIT_REASON';
export const DELETE_SCHEDULE_EVENT = 'DELETE_VISIT_REASON';
export const TOGGLE_EVENT_SELECTION = 'TOGGLE_EVENT_SELECTION';
export const TOGGLE_SINGLE_EVENT_SELECTION = 'TOGGLE_SINGLE_EVENT_SELECTION';
export const TOGGLE_ALL_EVENTS_SELECTION = 'TOGGLE_ALL_EVENTS_SELECTION';
