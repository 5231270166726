import { useContext } from 'react';
import { List, MenuItem, ListItemIcon, ListItemText, SvgIcon, Box } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline, mdiShareOutline } from '@mdi/js';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { AccountsContext } from '../../../../AccountsContext';
import { MessagesContext } from '../../../../MessagesContext';
import { EmailsForwardModal } from '../../../EmailsForwardModal';
import { EmailSaveModal } from '../../../EmailSaveModal';

export const Menu = ({ onClose }) => {
  const { accounts } = useContext(AccountsContext);
  const { filter, messageItem, deleteMessages } = useContext(MessagesContext);
  const { openModal } = useModal();

  const openForwardEmailModal = () => {
    openModal(EmailsForwardModal, {
      payload: {
        folderID: filter.folder_id,
        message: messageItem
      },
      onModalResolved: () => {
        onClose();
      }
    });
  };

  const openSaveEmailModal = () => {
    openModal(EmailSaveModal, {
      payload: {
        folderID: filter.folder_id,
        messageUIDs: [ messageItem.uid ],
        message: messageItem,
        accounts
      },
      onModalResolved: () => {
        onClose();
      }
    });
  };

  const deleteMessage = () => {
    deleteMessages(filter.folder_id, [ messageItem.uid ]);
  };

  return (
    <List>
      <MenuItem onClick={openForwardEmailModal}>
        <ListItemIcon>
          <SvgIcon>
            <Icon path={mdiShareOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Forward" />
      </MenuItem>

      <MenuItem divider onClick={openSaveEmailModal}>
        <ListItemIcon>
          <Box color="success.main">
            <SaveOutlinedIcon color="inherit" />
          </Box>
        </ListItemIcon>

        <ListItemText primary="Save" />
      </MenuItem>

      <MenuItem onClick={deleteMessage}>
        <ListItemIcon>
          <SvgIcon color="error">
            <Icon path={mdiTrashCanOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
