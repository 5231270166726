import { useModal } from '../../../../../../../components/ModalsProvider';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { CourtInformationContent } from './CourtInformationContent';
import { EditCourtInformationModal } from './EditCourtInformationModal';

export const CourtInformation = ({
  disableRightActions,
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  WidgetsActionButtonsProps = {},

  ...props
}) => {
  const { openModal } = useModal();

  const editCourtInformation = () => {
    openModal(EditCourtInformationModal, {
      payload: {
        caseID: caseItem.id,
        courtInformation: caseItem.court_information
      },
      onModalResolved: (court_information) => {
        onCaseItemUpdate({ ...caseItem, court_information });
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      title="Court Information"
      rightActions={!disableRightActions && [
        <WidgetsActionButtons
          isMinimizable
          isEditable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
          openWidgetModal={editCourtInformation}

          {...WidgetsActionButtonsProps}
        />
      ]}
      {...props}
    >
      <CourtInformationContent courtInformation={caseItem.court_information} />
    </ContentCard>
  );
};
