import { useContext } from 'react';
import { useModal } from 'react-modal-hook';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Popper } from '../../../../../../components/Popper';
import {
  FilesContext,
  FileMenu,
  FileTypeIcon,
  FilePreviewModal,
  getFileMenuOptions
} from '../../../../files-common';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ item: file }) => {
  const classes = useStyles();
  const filesContext = useContext(FilesContext);
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      getFileMenuOptions={getFileMenuOptions(filesContext)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        files: filesContext.files,
        isFetched: filesContext.isFetched,
        isFetching: filesContext.isFetching,
        filter: filesContext.filter,
        onNeedNextPage: filesContext.loadNextPage
      }}
    />
  ), [ file, filesContext ]);

  return (
    <div className={classes.root}>
      <div className={classes.filePreview} onClick={openFilesPreview}>
        {file.previews['280'] || file.aggregate_type === 'vector' ?
          <img
            src={file.previews['280'] || file.url}
            alt={file.extension}
            className={classes.filePreview__image}
          />
          :
          <FileTypeIcon className={classes.filePreview__icon} file={file} />
        }
      </div>

      <div className={classes.fileInfo}>
        <FileTypeIcon className={classes.fileInfo__icon} file={file} />

        <Typography noWrap className={classes.fileInfo__name} title={file.original_filename}>
          {file.original_filename}
        </Typography>

        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton
                buttonRef={anchorRef}
                onClick={handleToggle}
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <FileMenu
              file={file}
              menuList={getFileMenuOptions(filesContext)(file)}
              onClose={handleClose}
            />
          )}
        </Popper>
      </div>
    </div>
  );
};
