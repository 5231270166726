import { Box } from '@material-ui/core';
import { WidgetsActionButtons } from '../../../../components/WidgetsActionButtons';
import { ContentCard } from '../../../../components/ContentCard';
import { StatisticsFilterContextProvider } from './StatisticsFilterContext';
import { MainContent } from './MainContent';

export const TaskStatusStatistics = ({
  isOpen,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      isResizable={isOpen}
      title="Task statistics by status"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box minHeight={height || '100%'} height="100%">
        <StatisticsFilterContextProvider filter={{ type: 'status' }}>
          <MainContent isOpen={isOpen} />
        </StatisticsFilterContextProvider>
      </Box>
    </ContentCard>
  );
};
