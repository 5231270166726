export const styles = ({
  spacing,
  palette: { text, ...palette },
  typography: { pxToRem },
  breakpoints
}) => ({
  actions: {
    display: 'flex',
    padding: ({ disablePaddings }) => !disablePaddings ? spacing(0.8, 1, 0.8, 2) : spacing(0, 1, 0, 2),
    alignItems: 'center',
    justifyContent:  ({ disablePaddings }) => disablePaddings ? 'space-between' : 'inherit',
    borderBottom: `1px solid ${palette.divider}`,

    [breakpoints.down('md')]: {
      padding: spacing(0, 1, 0, 2),
      flexWrap: 'wrap'
    }
  },

  count: {
    marginLeft: spacing(1),
    fontSize: pxToRem(18)
  }
});
