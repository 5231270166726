export const styles = ({ spacing, palette: { divider } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },

  header: {
    background: 'none',
    borderTop: `1px solid ${divider}`
  },

  headerToolbar: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },

  header__title: {
    flexGrow: 1
  },

  search: {
    minHeight: 48,
    padding: spacing(0.5, 1.75, 0.5, 2)
  },

  searchButton: {
    position: 'relative',
    bottom: 10,
    marginRight: spacing(0.5)
  }
});
