import { useContext, useState } from 'react';
import { Formik } from 'formik';
import { mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Box, Collapse, FormControlLabel, Grid, SvgIcon } from '@material-ui/core';
import { allClaimsStatuses, ClaimsContext } from '../../../../../../components/claims';
import {
  Autocomplete,
  DatePicker,
  OfficesLocationSelect,
  Switch
} from '../../../../../../components/FormField';
import { IconButton } from '../../../../../../components/IconButton';
import { UsersSelect } from '../../../../../../components/users';

export const FiltersBar = ({ claim }) => {
  const [ filterIsOpen, setFilterIsOpen ] = useState(false);
  const { filter, resetClaims } = useContext(ClaimsContext);

  const toggleFilterIsOpen = () => {
    setFilterIsOpen((filterIsOpen) => !filterIsOpen);
  };

  const handleMultipleAutocompleteChange = (name) => (options) => {
    resetClaims({ [name]: options?.map(({ value }) => value) });
  };

  const handleDatePickerChange = (name) => (date) => {
    resetClaims({ [name]: date });
  };

  const handleOfficesChange = (offices) => {
    resetClaims({ offices: offices?.map(({ id }) => id) });
  };

  const handleProvidersMultiSelectChange = (users) => {
    resetClaims({ providers: users.map(({ id }) => id) });
  };

  const handleFollowUpChange = ({ target }) => {
    resetClaims({ 'follow_up': target.checked ? target.value : null });
  };

  return (
    <Formik initialValues={filter} onSubmit={null}>
      <>
        <Grid container spacing={1} alignItems="center">
          <Box clone alignSelf="flex-end">
            <Grid item>
              <IconButton edge="start" color="primary" title="Collapse filters" onClick={toggleFilterIsOpen}>
                <SvgIcon>
                  <Icon path={mdiFilter} />
                </SvgIcon>
              </IconButton>
            </Grid>
          </Box>

          <Grid item xs>
            <Autocomplete
              multiple
              name="statuses"
              label="Claim Status"
              options={allClaimsStatuses}
              onChange={handleMultipleAutocompleteChange('statuses')}
            />
          </Grid>

          {!!claim?.appointment_id &&
            <Grid item>
              <FormControlLabel
                labelPlacement="top"
                label="Follow Up"
                control={
                  <Switch
                    name="follow_up"
                    value={claim.appointment_id}
                    color="primary"
                    onChange={handleFollowUpChange}
                  />
                }
              />
            </Grid>
          }
        </Grid>

        <Collapse in={filterIsOpen}>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePicker
                  clearable
                  zeroMinWidth
                  name="appointment_from"
                  label="DOS From"
                  onChange={handleDatePickerChange('appointment_from')}
                />
              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  clearable
                  zeroMinWidth
                  name="appointment_to"
                  label="DOS To"
                  onChange={handleDatePickerChange('appointment_to')}
                />
              </Grid>

              <Grid item xs={12}>
                <OfficesLocationSelect
                  multiple
                  name="offices"
                  label="Offices"
                  onChange={handleOfficesChange}
                />
              </Grid>

              <Grid item xs={12}>
                <UsersSelect
                  multiple
                  name="providers"
                  label="Providers"
                  params={{ role: 'doctor' }}
                  onChange={handleProvidersMultiSelectChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </>
    </Formik>
  );
};
