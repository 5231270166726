import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as notesApi from '../../../../../api/profile';
import * as formsApi from '../../../../../api/profile/medical';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { Attachments } from '../Attachments';
import { Body } from './Body';
import { Header } from './Header';
import { validationSchema } from './validationSchema';

export const CreateNoteModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { user = {}, medicalProfile = {} }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [ form, setForm ] = useState({});
  const [ isFetching, setIsFetching ] = useState(false);

  const createNote = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const formData = new FormData();

    Object.entries(values).forEach(([ key, value ]) => {
      if (Array.isArray(value)) {
        value.forEach((value) => formData.append(`${key}[]`, value));
      } else {
        formData.append(key, value ?? '');
      }
    });

    return notesApi.createNote(formData).then((data) => {
      enqueueSnackbar('Note successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      setErrors(errors);
    });
  };

  useEffect(() => {
    if (medicalProfile?.last_medical_form?.id) {
      setIsFetching(true);

      formsApi.fetchMedicalForm(medicalProfile?.last_medical_form?.id).then(setForm).catch(() => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }).finally(() => setIsFetching(false));
    }
  }, []);

  return (
    <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
      <Loader p={3} loading={isFetching} render={
        () => (
          <Formik
            initialValues={{
              provider_id: (
                form?.appointment?.appointment_book?.provider || form?.treating_physician
              ) || null,
              office_id: form?.appointment?.appointment_book?.office || form?.office || null,
              patient_id: user,
              dob: user?.birthday || null,
              files: [],
              name: null,
              note: null,
              doi: form.doi || null,
              doi_from: form?.doi_from || null,
              doi_to: form?.doi_to || null,
              is_range: !form?.doi && !!form?.doi_from && !!form.doi_to,
              dor: moment().unix()
            }}
            validationSchema={validationSchema}
            onSubmit={createNote}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader onClose={handleModalReject}>
                  Add New Clinical Notes
                </ModalHeader>

                <ModalBody>
                  <Header />
                  <Body />
                  <Attachments />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>Cancel</Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
