import { Box, makeStyles, Typography } from '@material-ui/core';
import { getUserFullName } from '../../../../../helpers/users';
import { Date } from '../../../../Date';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const HeaderInfo = ({ report }) => {
  const classes = useStyles();

  return (
    <div className={classes.userInfo}>
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Typography variant="h5">DOI</Typography>
        </Box>

        <Date disableIcon disableMoment date={report?.general_data?.doi_string || report?.general_data?.doi} />
      </Box>

      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Typography variant="h5">DOB</Typography>
        </Box>

        <Date disableIcon disableMoment date={report?.general_data?.dob} />
      </Box>

      <Typography variant="h4">
        {getUserFullName(report?.patient)}
      </Typography>
    </div>
  );
};
