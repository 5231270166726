import { IntakeForm } from '../MediasFields';

export const mediasFieldsMap = {
  intake_form_completed: (before, after) => (
    <IntakeForm before={before} after={after} fieldLabel="Intake Form Completed" />
  ),
  scene_photos: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Scene Photos" />,
  pd_photos: (before, after) => <IntakeForm before={before} after={after} fieldLabel="PD Photos" />,
  um_case: (before, after) => <IntakeForm before={before} after={after} fieldLabel="UM Case" />,
  rep_ltr_to_def_one_ins: (before, after) => (
    <IntakeForm before={before} after={after} fieldLabel="Rep Ltr to Def-1 Ins" />
  ),
  rep_ltr_to_def_two_ins: (before, after) => (
    <IntakeForm before={before} after={after} fieldLabel="RRep Ltr to Def-2 Ins" />
  ),
  rep_ltr_own_ins: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Rep Ltr Own Ins" />,
  rep_ltr_def_three: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Rep Ltr (Def-3)" />,
  witness_statement: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Witness Statement" />,
  witnesses: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Witnesses" />,
  insurance_card_number: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Insurance Card" />,
  loe: (before, after) => <IntakeForm before={before} after={after} fieldLabel="LOE" />,
  medical: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Medi-Cal" />,
  medicare_filled_at: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Medical Filled" />,
  medical_filled_at: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Medical Fille" />,
  medical_final_lien: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Medical Final Lien" />,
  medicare: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Medicare" />,
  medicare_final_lien: (before, after) => (
    <IntakeForm before={before} after={after} fieldLabel="Medicare Final Lien" />
  ),
  no_health_ins: (before, after) => <IntakeForm before={before} after={after} fieldLabel="No Health Ins" />,
  officer_id: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Officer ID" />,
  other: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Notes" />,
  passengers: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Passengers" />,
  pd_received: (before, after) => <IntakeForm before={before} after={after} fieldLabel="PD Received" />,
  pd_requested: (before, after) => <IntakeForm before={before} after={after} fieldLabel="PD Requested" />,
  plf_dec_sheet: (before, after) => <IntakeForm before={before} after={after} fieldLabel="PLF Dec Sheet" />,
  police_report: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Police Report" />,
  primary_health_ins: (before, after) => <IntakeForm before={before} after={after} fieldLabel="Pri Health Ins" />,
  primary_health_ins_final_lien: (before, after) => (
    <IntakeForm before={before} after={after} fieldLabel="Pri Health Ins Final Lien" />
  ),
  sr_nineteen_sent: (before, after) => <IntakeForm before={before} after={after} fieldLabel="SR-19 Sent" />,
  sr_one_sent: (before, after) => <IntakeForm before={before} after={after} fieldLabel="SR-1 Sent" />,
  tcr_number: (before, after) => <IntakeForm before={before} after={after} fieldLabel="TCR Number" />
};
