export const styles = ({
  spacing,
  palette: { primary, darkgreen, grey },
  typography: { fontWeightMedium },
  transitions
}) => ({
  root: {
    cursor: 'pointer',
    transition: transitions.create(),
    paddingLeft: spacing(),

    '&:hover': {
      backgroundColor: 'rgba(0, 188, 212, 0.05)',
      borderBottom: `1px solid ${primary.main}`
    }
  },

  userInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },

  userInfoLink: {
    fontWeight: fontWeightMedium,
    color: grey[600],

    '&:hover': {
      textDecoration: 'underline'
    }
  },

  avatar: {
    marginRight: spacing()
  },

  statusLabel: {
    color: primary.contrastText
  },

  balance: {
    color: darkgreen
  }
});
