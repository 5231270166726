export const styles = ({ palette, spacing, shape: { borderRadius }, typography: { pxToRem } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 360,
    minHeight: 180,
    padding: spacing(2),
    background: palette.common.white,
    borderRadius
  },

  title: {
    marginBottom: spacing(2.5)
    // fontSize: pxToRem(18)
  },

  icon: {
    fontSize: 80
  }
});
