import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../components/ModalsProvider';
import { PageHeader } from '../../../../../components/Page';
import { Fab } from '../../../../../components/Fab';
import { ShareByEmailModal } from '../../../files-common';
import { AccessControlContext } from '../AccessControlProvider';

export const ActionsBar = () => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { resetEmails } = useContext(AccessControlContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const addEmail = () => {
    openModal(ShareByEmailModal, {
      onModalResolved: () => {
        resetEmails();
        enqueueSnackbar('Link with access has been sent to the email', { variant: 'success' });
      }
    });
  };

  return (
    <PageHeader>
      <Fab
        variant="extended"
        color="primary"
        size={isTablet ? 'small' : 'medium'}
        startIcon={<AddIcon />}
        onClick={addEmail}
      >
        Add email
      </Fab>
    </PageHeader>
  );
};
