import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useRef } from 'react';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { ReadonlyCheckbox } from '../../../../../../../../components/ReadonlyCheckbox';
import { AddressLink } from '../../../../../../../../components/AddressLink';
import { Date } from '../../../../../../../../components/Date';
import { useResizeObserver } from '../../../../../../../../helpers/hooks';
import { Attachment } from '../Attachment';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChecklistContent = ({ checklist = {}, caseID, onCaseItemUpdate }) => {
  const classes = useStyles();
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const smallWidth = width > 660;

  return (
    <Grid ref={rootRef} container component={Box}>
      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Intake Form Completed</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox strict checked={checklist?.intake_form_completed} />
          </Grid>
        </Grid>

        <Attachment
          name="intake_form_completed"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Witnesses</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.witnesses} />
          </Grid>
        </Grid>

        <Attachment
          name="witnesses"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Officer ID</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {checklist?.officer_id || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Attachment
          name="officer_id"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Scene Photos</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox strict checked={checklist?.scene_photos} />
          </Grid>
        </Grid>

        <Attachment
          name="scene_photos"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Witness Statement</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.witness_statement} />
          </Grid>
        </Grid>

        <Attachment
          name="witness_statement"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">SR-1 Sent</Typography>
          </Grid>

          <Grid item>
            <Date date={checklist?.sr_one_sent} format="L" />
          </Grid>
        </Grid>

        <Attachment
          name="sr_one_sent"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">PD Photos</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox strict checked={checklist?.pd_photos} />
          </Grid>
        </Grid>

        <Attachment
          name="pd_photos"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Passengers</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.passengers} />
          </Grid>
        </Grid>

        <Attachment
          name="passengers"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">SR-19 Sent</Typography>
          </Grid>

          <Grid item>
            <Date date={checklist?.sr_nineteen_sent} format="L" />
          </Grid>
        </Grid>

        <Attachment
          name="sr_nineteen_sent"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Rep Ltr to Def-1 Ins</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.rep_ltr_to_def_one_ins} />
          </Grid>
        </Grid>

        <Attachment
          name="rep_ltr_to_def_one_ins"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">UM Case</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox strict checked={checklist?.um_case} />
          </Grid>
        </Grid>

        <Attachment
          name="um_case"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">PLF Dec Sheet</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox strict checked={checklist?.plf_dec_sheet} />
          </Grid>
        </Grid>

        <Attachment
          name="plf_dec_sheet"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Rep Ltr to Def-2 Ins</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.rep_ltr_to_def_two_ins} />
          </Grid>
        </Grid>

        <Attachment
          name="rep_ltr_to_def_two_ins"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Police Report</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox strict checked={checklist?.police_report} />
          </Grid>
        </Grid>

        <Attachment
          name="police_report"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">PD Requested</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox strict checked={checklist?.pd_requested} />
          </Grid>
        </Grid>

        <Attachment
          name="pd_requested"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Rep Ltr Own Ins</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.rep_ltr_own_ins} />
          </Grid>
        </Grid>

        <Attachment
          name="rep_ltr_own_ins"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Typography color="textSecondary">Police Department</Typography>

        <CompanyLink company={checklist.police_department}>
          <AddressLink address={checklist.police_department?.full_address} />
        </CompanyLink>

        <Attachment
          name="police_department"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">PD Received</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox strict checked={checklist?.pd_received} />
          </Grid>
        </Grid>

        <Attachment
          name="pd_received"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Rep Ltr (Def-3)</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.rep_ltr_def_three} />
          </Grid>
        </Grid>

        <Attachment
          name="rep_ltr_def_three"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">TCR Number</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {checklist?.tcr_number || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Attachment
          name="tcr_number"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">LOE</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.loe} />
          </Grid>
        </Grid>

        <Attachment
          name="loe"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item xs={12} style={{ padding: !smallWidth ? '10px' : '15px 30px' }}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography variant="h5">Note</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {checklist?.other || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Attachment
          name="other"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item xs={12} style={{ padding: !smallWidth ? '10px' : '20px 30px' }}>
        <Typography variant="h3">Medicals</Typography>
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Medicare</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.medicare} />
          </Grid>
        </Grid>

        <Attachment
          name="medicare"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Filed</Typography>
          </Grid>

          <Grid item>
            <Date date={checklist?.medicare_filled_at} />
          </Grid>
        </Grid>

        <Attachment
          name="medicare_filled_at"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Final Lien</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.medicare_final_lien} />
          </Grid>
        </Grid>

        <Attachment
          name="medicare_final_lien"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Medi-Cal</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.medical} />
          </Grid>
        </Grid>

        <Attachment
          name="medical"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Filed</Typography>
          </Grid>

          <Grid item>
            <Date date={checklist?.medical_filled_at} format="L" />
          </Grid>
        </Grid>

        <Attachment
          name="medical_filled_at"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Final Lien</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.medical_final_lien} />
          </Grid>
        </Grid>

        <Attachment
          name="medical_final_lien"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Pri Health Ins</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.primary_health_ins} />
          </Grid>
        </Grid>

        <Attachment
          name="primary_health_ins"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder} />

      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Final Lien</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.primary_health_ins_final_lien} />
          </Grid>
        </Grid>

        <Attachment
          name="primary_health_ins_final_lien"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">No Health Ins</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={checklist?.no_health_ins} />
          </Grid>
        </Grid>

        <Attachment
          name="no_health_ins"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder} />
      <Grid item md={4} className={!smallWidth ? classes.smallNoBorderBox : classes.mediumNoBorderBox} />

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Insurance Card #</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {checklist?.insurance_card_number || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Attachment
          name="insurance_card_number"
          medias={checklist.medias}
          caseID={caseID}
          onUpdate={onCaseItemUpdate}
        />
      </Grid>

      <Grid item md={4} className={!smallWidth ? classes.smallBoxBorder : classes.mediumBoxBorder} />
    </Grid>
  );
};
