export const styles = ({
  spacing,
  palette: { grey, ...palette },
  shape: { borderRadius },
  typography: { pxToRem },
  transitions,
  breakpoints
}) => ({
  checkbox: {
    alignSelf: 'center',
    color: grey[400]
  },

  mainInfoCell: {
    display: 'flex',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },

  mainInfoCell__preview: {
    position: 'relative',
    flexShrink: 0,
    width: 46,
    height: 46,
    marginRight: spacing(2),
    overflow: 'hidden',
    textAlign: 'center',


    '&:hover': {
      borderRadius
    }
  },

  mainInfoCell__previewBackdrop: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.primary.contrastText,
    background: palette.primary.main,
    opacity: 0,
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      opacity: 1
    }
  },

  mainInfoCell__previewImage: {
    width: '100%'
  },

  mainInfoCell__previewIcon: {
    fontSize: pxToRem(48)
  },

  viewingIndicator: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: pxToRem(24),
    height: pxToRem(24),
    background: palette.primary.main,
    borderRadius
  },

  viewingIndicator__icon: {
    fontSize: pxToRem(14),
    color: palette.primary.contrastText
  },

  mainInfoCell__tags: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  mainInfoCell__tag: {
    margin: spacing(0.25)
  }
});
