import { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { UsersContext } from '../../../UsersContext';
import {
  columnsWidths,
  widthBreakpointMedium,
  widthBreakpointSmall
} from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ width }) => {
  const classes = useStyles();
  const { selectedIDs, allUsersIsSelected, toggleAllUsersSelection } = useContext(UsersContext);

  return (
    <ListRow header className={classes.root}>
      <ListRowCheckbox
        onClick={toggleAllUsersSelection}
        checked={allUsersIsSelected()}
        indeterminate={!!selectedIDs.length && !allUsersIsSelected()}
      />

      <ListRowCell grow flexBasis={columnsWidths.name}>
        User name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.ssn}>
        SSN
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.phone_number}>
        Phone
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.role}>
        Role
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        Status
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.attend}>
          Last attended
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
