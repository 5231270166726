export const SCHEDULES_FETCH_REQUEST = 'SCHEDULES_FETCH_REQUEST';
export const SCHEDULES_FETCH_SUCCESS = 'SCHEDULES_FETCH_SUCCESS';
export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const UPDATE_SCHEDULE_IN_LIST = 'UPDATE_SCHEDULE_IN_LIST';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const TOGGLE_SCHEDULE_SELECTION = 'TOGGLE_SCHEDULE_SELECTION';
export const TOGGLE_ALL_SCHEDULES_SELECTION = 'TOGGLE_ALL_SCHEDULES_SELECTION';
export const RESET_SCHEDULES = 'RESET_SCHEDULES';
export const MASS_DELETE_SCHEDULES = 'MASS_DELETE_SCHEDULES';
export const MASS_ACTIVE_SCHEDULES = 'MASS_ACTIVE_SCHEDULES';
export const MASS_INACTIVE_SCHEDULES = 'MASS_INACTIVE_SCHEDULES';
