import { useEffect, useState } from 'react';
import cn from 'classnames';
import NotesIcon from '@material-ui/icons/Notes';
import { Box, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { NestedList } from '../../../../../../../components/NestedList';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { styles } from '../NeckPain/styles';
import { painMap } from '../painMap';

const useStyles = makeStyles(styles);

export const MiddlePain = ({ isOpenSwitches, form }) => {
  const classes = useStyles();
  const [ isOpen, setIsOpen ] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const name = form?.forms?.middle_back;
  const experiences = !!name?.experience_following ? Object.keys(name?.experience_following) : [];

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(form?.forms?.middle_back) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="If pain in middle back"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box p={isSmallScreen ? 1 : 3}>
        <div className={classes.content}>
          <Typography className={classes.formContent}>Does the pain radiate to?</Typography>

          <Box display="flex" alignItems="center" my={1}>
            {!!name?.does_the_pain_radiate_to?.parts?.shoulder_blades && (
              <ReadonlyCheckbox
                checked
                title="Shoulder Blades"
                className={classes.contentInfoTitle}
              />
            )}

            {!!name?.does_the_pain_radiate_to?.parts?.chest && (
              <ReadonlyCheckbox
                checked
                title="Chest"
                className={classes.contentInfoTitle}
              />
            )}

            {name?.does_the_pain_radiate_to?.parts?.other && (
              <ReadonlyCheckbox
                checked
                title="Other"
                className={classes.contentInfoTitle}
              />
            )}
          </Box>

          {!!name?.does_the_pain_radiate_to?.description &&
            <Typography color="textSecondary">
              {name?.does_the_pain_radiate_to?.description}
            </Typography>
          }
        </div>

        {!!name?.how_often_do_you_experience_the_pain && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              How often is your pain?
            </Typography>

            <ReadonlyCheckbox
              title={name?.how_often_do_you_experience_the_pain}
              checked={!!name?.how_often_do_you_experience_the_pain}
              className={classes.contentInfoTitle}
            />
          </div>
        )}

        {!!name?.what_severity_your_pain && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              What is the severity of your pain?
            </Typography>

            <ReadonlyCheckbox
              title={name?.what_severity_your_pain}
              checked={!!name?.what_severity_your_pain}
              className={classes.contentInfoTitle}
            />
          </div>
        )}

        {!!experiences?.length && (
          <div className={classes.content}>
            <Typography className={cn(classes.formContent, classes.formContent__title)}>
              Do you experience any of the following?
            </Typography>

            {experiences.map((item, index) => (
              <Grid key={index} container spacing={2}>
                <Grid item sm={3} xs={12}>
                  <ReadonlyCheckbox
                    checked={name?.experience_following?.[item]?.value}
                    title={painMap[item]}
                  />
                </Grid>

                {!!name?.experience_following?.[item]?.description &&
                  <Grid container item sm={6} xs={12} alignItems="flex-start">
                    <Grid item>
                      <div className={classes.noteIcon}>
                        <NotesIcon fontSize="small" color="inherit" /> Note
                      </div>
                    </Grid>

                    <Grid item xs={10}>
                      <Typography color="textSecondary">
                        {name?.experience_following?.[item]?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              </Grid>
            ))}
          </div>
        )}

        {name?.symptoms_increase_while_twisting && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Do your symptoms increase while twisting, turning, extending or flexing the spine?
            </Typography>

            <ReadonlyCheckbox
              checked
              title={name?.symptoms_increase_while_twisting}
            />
          </div>
        )}

        <div className={cn(classes.content, classes.content_row)}>
          <Typography className={classes.formContent}>
            Do your symptoms increase with activity involving the use of muscles in the middle back,
            vigorous activity, bending over or with change in temperature?
          </Typography>

          <ReadonlyCheckbox
            checked
            title={name?.symptoms_increase_with_activity}
          />
        </div>
      </Box>
    </NestedList>
  );
};
