import { useContext, useEffect } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { PageBody, PageHeader } from '../../../../components/Page';
import { FormFiltersBar } from '../../ProfilePage/MedicalInfo/MedicalForms/FormFiltersBar';
import { ActionsBar, Grid, List } from '../../ProfilePage/MedicalInfo/MedicalForms/MainContent';
import { MedicalFormsContext } from '../../ProfilePage/MedicalInfo/MedicalForms/MedicalFormsProvider';

export const ConfirmedForms = ({ width, filterKey, hiddenFilterFields }) => {
  const { viewVariant } = useContext(LayoutContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { resetForms, ...formContext } = useContext(MedicalFormsContext);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  useEffect(() => {
    resetForms();
  }, []);

  return (
    <>
      <PageHeader disableBorderBottom>
        <ActionsBar filterOpenButton={filterOpenButton} />
      </PageHeader>

      <PageBody fullHeight disablePaddings disableScrollbars>
        <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
          <ScrollWrapper {...scrollWrapperProps}>
            <FilterBarWrapper {...filterBarWrapperProps}>
              <FormFiltersBar
                filterKey={filterKey}
                hiddenFilterFields={hiddenFilterFields}
                resetForms={resetForms}
              />
            </FilterBarWrapper>
          </ScrollWrapper>

          {!isTablet ? (
            (viewVariant === viewVariantsMap.list)
              ? <List width={width} resetForms={resetForms} formContext={formContext} />
              : (viewVariant === viewVariantsMap.grid) ? <Grid formContext={formContext} /> : null
          ) : (
            <Grid formContext={formContext} />
          )}
        </Box>
      </PageBody>
    </>
  );
};
