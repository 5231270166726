export const styles = ({ spacing, palette: { grey }, transitions, typography: { pxToRem } }) => ({
  itemExpand: {
    marginRight: spacing(),
    transform: 'rotate(-90deg)',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest
    })
  },

  itemExpandOpened: {
    transform: 'rotate(0deg)'
  },

  itemText: {
    padding: spacing(0),
    fontWeight: 500
  },

  itemBadge: {
    transform: 'scale(1) translate(0, -50%)'
  },

  badge: {
    marginRight: spacing(0.5)
  },

  subList: {
    marginBottom: spacing(1.5)
  },

  subItem: {
    padding: spacing(0.5, 6, 0.5, 4.25)
  },

  itemSubText: {
    margin: 0
  },

  itemSubPrimary: {
    fontSize: pxToRem(14),
    fontWeight: 500,
    color: grey[800]
  },

  itemSecondary: {
    fontSize: pxToRem(12)
  }
});
