import moment from 'moment';
import { makeStyles, Box, Typography } from '@material-ui/core';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import { Tooltip } from '../../Tooltip';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Event = ({ event }) => {
  const classes = useStyles({ event });

  return !!event && (
    <div className={classes.root}>
      <Box display="flex">
        {!!event.is_recurring_dummy &&
          <Box mr={0.5}>
            <Tooltip title="Recurring event">
              <LoopOutlinedIcon fontSize="small" className={classes.loopIcon} />
            </Tooltip>
          </Box>
        }

        <Box flexGrow={1} overflow="hidden">
          <Typography noWrap variant="body2" title={event.title} color="textPrimary">
            {event.title}
          </Typography>
        </Box>
      </Box>

      <Typography variant="body2" color="textPrimary">
        {moment.unix(event.started_at).format('LT')}
        {' - '}
        {moment.unix(event.finished_at).format('LT')}
      </Typography>
    </div>
  );
};
