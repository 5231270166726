import cn from 'classnames';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { CurrencyFormat } from '../../../../../../../../../components/CurrencyFormat';
import { Date } from '../../../../../../../../../components/Date';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ expense }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center">
        <Box component="span" mr={2}>
          <Date date={expense.date} />
        </Box>

        <Date disableDateIcon date={expense.date} format="LT" />
      </Box>

      <div className={classes.mainContent}>
        <Box display="flex" flexDirection="column" width="50%">
          <Typography variant="h6" color="textSecondary">
            <CurrencyFormat value={expense.amount}/>
          </Typography>

          <Typography color="textSecondary" variant="body2">
            Amount
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" width="50%">
          <Typography
            variant="h6"
            className={
              cn(
                classes.balanceTotal_darkgreen,
                { [classes.balanceTotal_red]: expense.amount > expense.budget }
              )
            }
            color="textSecondary"
          >
            <CurrencyFormat value={expense.budget}/>
          </Typography>

          <Typography color="textSecondary" variant="body2">
            Budget
          </Typography>
        </Box>
      </div>
    </div>
  );
};
