import { useContext } from 'react';
import { Paper } from '@material-ui/core';
import { Checkbox } from '../../../../../../../../../components/FormField/Checkbox';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { AppointmentTypesContext } from '../../../../../AppointmentTypes/AppointmentTypesContext';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  const { selectedCodesIDs, ...appointmentTypesContext } = useContext(AppointmentTypesContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell flexBasis={columnsWidths.checkbox}>
          <Checkbox
            withoutFormik
            checked={appointmentTypesContext.allCodesIsSelected()}
            indeterminate={!!selectedCodesIDs.length && !appointmentTypesContext.allCodesIsSelected()}
            onChange={appointmentTypesContext.toggleAllCodesSelected}
          />
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.code}>
          Code
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.description}>
          Description
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.duration}>
          Duration
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.claimRequired}>
          Claim required
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.ordering}>
          Ordering
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.apptBooks}>
          Appt. books
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
