import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useContext } from 'react';
import { useModal } from '../../../../components/ModalsProvider';
import { PageHeaderActions, PageHeaderTitle } from '../../../../components/Page';
import { CreateTaskTemplateModal } from './CreateTaskTemplateModal';
import { TaskTemplatesContext } from './TaskTemplatesProvider';

export const Header = () => {
  const { openModal } = useModal();
  const { addTaskTemplate } = useContext(TaskTemplatesContext);

  const createTaskTemplate = () => {
    openModal(CreateTaskTemplateModal, {
      onModalResolved: (template) => {
        addTaskTemplate(template);
      }
    });
  };

  return (
    <>
      <PageHeaderTitle>Task Templates</PageHeaderTitle>

      <PageHeaderActions>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={createTaskTemplate}
        >
          Add template
        </Button>
      </PageHeaderActions>
    </>
  );
};
