export const styles = ({ spacing, typography: { pxToRem } }) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: spacing(3, 6, 2, 4)
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '30%'
  },

  title: {
    fontSize: pxToRem(14),
    marginBottom: spacing(1.5)
  },

  contentInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: spacing()
  },

  selectContent: {
    width: '63%'
  }
});
