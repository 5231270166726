import T from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { VerticalDivider } from '../VerticalDivider';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',

    '& > * + *': {
      marginLeft: ({ disableGutters }) => !disableGutters && spacing()
    }
  },

  divider: {
    marginLeft: spacing()
  }
}));

const propTypes = {
  disableGutters: T.bool
};

export const ModalHeaderActions = ({ children, disableGutters }) => {
  const classes = useStyles({ disableGutters });

  return (
    <div className={classes.root}>
      {children}

      <VerticalDivider verticalGutters={1} className={classes.divider} />
    </div>
  );
};

ModalHeaderActions.propTypes = propTypes;
