import moment from 'moment';
import { caseInsuranceTypes } from '../../../../../../../dataMaps';
import { Yup } from '../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  claim_insurance: Yup.object().shape({
    claim_insurance_type: Yup.string().nullable().required(),
    client_id: Yup.mixed().nullable(),
    office_id: Yup.mixed().nullable(),
    examiner_id: Yup.mixed().nullable(),
    insurance_company_id: Yup.mixed().nullable().when('claim_insurance_type', {
      is: (value) => value !== caseInsuranceTypes.other,
      then: Yup.mixed().nullable().required()
    }),
    amount: Yup.mixed().nullable().when('claim_insurance_type', {
      is: (value) => value === caseInsuranceTypes.self_insured,
      then: Yup.mixed().nullable().required()
    }),
    insurance_carrier: Yup.string().nullable().max(1000),
    policy_period_start: Yup.date().format('YYYY-MM-DD').nullable(),
    policy_period_finish: Yup
      .date()
      .format('YYYY-MM-DD')
      .nullable()
      .when('policy_period_start', (startedAt, schema) => {
        const startMomentDate = moment(startedAt);

        return startMomentDate.isValid()
          ? schema.min(startMomentDate.add(1, 'minute'), 'Must be after the start date')
          : schema;
      })
  })
});
