import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { PageHeaderActions } from '../../../../../../../components/Page';
import { Fab } from '../../../../../../../components/Fab';
import { HeaderActions } from '../../../HeaderActions';
import { CreateCodeCPTModal } from '../../CreateCodeCPTModal';
import { CodesContext } from '../../CodesContext';

export const ActionsBar = ({ filterOpenButton }) => {
  const {
    codes,
    selectedIDs,
    pagination,
    filter,
    fetchCodes,
    massDeleteCodes
  } = useContext(CodesContext);
  const { openModal } = useModal();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const openCodeCreationModal = () => {
    openModal(CreateCodeCPTModal, {
      payload: {
        codesContext: {
          fetchCodes,
          codes,
          filter
        }
      }
    });
  };

  const handleMassDelete = () => {
    massDeleteCodes(selectedIDs);
  };

  return (
    <>
      <PageHeaderActions startPosition>
        <Fab
          size={isMobile ? 'small' : 'medium'}
          component={Link}
          to="/codes/procedure-codes/cpt/create"
          color="primary"
          variant="extended"
          startIcon={<AddIcon />}
        >
          Add code
        </Fab>

        <Button
          size={isTablet ? 'small' : 'medium'}
          color="primary"
          startIcon={<AddIcon />}
          onClick={openCodeCreationModal}
        >
          Add custom CPT
        </Button>
      </PageHeaderActions>

      <HeaderActions
        selectedCodesIDs={selectedIDs}
        pagination={pagination}
        filterOpenButton={filterOpenButton}
        onMassDelete={handleMassDelete}
      />
    </>
  );
};
