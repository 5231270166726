import { orderDirectionsMap } from '../../../../../components/FormField';
import { orderByMap } from '../../../ProfilePage/MedicalInfo/MedicalForms/FormFiltersBar/orderByOptions';

export const initialState = {
  isFetching: false,
  isFetched: false,
  filter: {
    page: 1,
    per_page: 15,
    order_direction: orderDirectionsMap.desc,
    order_by: orderByMap.updated_at
  },
  pagination: {
    total: 0,
    last_page: 1
  },
  forms: [],
  selectedIDs: []
};
