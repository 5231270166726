import { useFormikContext } from 'formik';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ApptBooksSelect } from '../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../components/users';
import { CalendarInfo } from '../../../../AppointmentsPage/WaitingList/CreateModal/CalendarInfo';

export const ApptBody = ({ appointmentDate }) => {
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const provider = values?.treating_physician_id?.id || values?.treating_physician_id;

  const handlePhysicianChange = () => {
    setFieldValue('appointment_book_id', null);
  };

  const handleAppointmentBookChange = (appointmentBook) => {
    if (!values?.office_id) {
      setFieldValue('office_id', appointmentBook?.office_id || null);
    }

    if (!values?.treating_physician_id) {
      setFieldValue('treating_physician_id', appointmentBook?.provider || null);
    }
  };

  return (
    <>
      <Box pl={isSmallScreen ? 1 : 1.5}>
        <Typography variant="h4" color="secondary">
          Request an appointment
        </Typography>
      </Box>

      <Grid
        container
        spacing={isSmallScreen ? 2 : 3}
        component={Box}
        alignItems="flex-start"
        justify="space-between"
        py={2}
        width="100% !important"
        m="0 !important"
      >
        <Grid item md={6} xs={12}>
          <UsersSelect
            name="treating_physician_id"
            label="Physician"
            params={{ role: 'doctor' }}
            onChange={handlePhysicianChange}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <ApptBooksSelect
            required
            name="appointment_book_id"
            params={{
              providers: provider ? [ provider ] : [],
              is_active: 1
            }}
            onChange={handleAppointmentBookChange}
          />
        </Grid>

        <Grid item lg={12} xs={12} container spacing={2}>
          {values?.appointment_book_id && <CalendarInfo appointmentDate={appointmentDate} />}
        </Grid>
      </Grid>
    </>
  );
};
