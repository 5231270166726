import { useContext, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { InfiniteGridLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { OfficesContext } from '../OfficesContext';
import { Card } from './Card';

export const Grid = () => {
  const {
    isFetched,
    isFetching,
    offices,
    meta: { last_page, page },
    loadNextPage,
    resetOffices
  } = useContext(OfficesContext);
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  useEffect(() => {
    resetOffices();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={isMobile ? 1 : 3} pr={0}>
        <Loader loading={!isFetched} render={
          () => !offices.length ? (
            <Typography align="center">No offices found</Typography>
          ) : (
            <InfiniteGridLoader
              items={offices}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={{ last_page, page }}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={380}
              cellHeight={420}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
