export const paperSizesOptions = [
  {
    value: 'Letter',
    label: 'Letter 8.5 x 11'
  },
  {
    value: 'Legal',
    label: 'Legal 8.5 x 14'
  },
  {
    value: 'A4',
    label: 'A4 8.3 x 11.7'
  }
];
