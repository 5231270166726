import { Link as MuiLink } from '@material-ui/core';
import { useModal } from '../../../../../components/ModalsProvider';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';

export const TermsAndConditions = () => {
  const { openModal } = useModal();

  const showTermsAndConditions = () => {
    openModal(TermsAndConditionsModal);
  };

  return (
    <MuiLink onClick={showTermsAndConditions}>
      Terms and Conditions
    </MuiLink>
  );
};
