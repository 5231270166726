export const styles = ({ typography: { pxToRem } }) => ({
  input: {
    display: 'flex',
    height: ({ isMulti, hasValue, TextFieldProps: { margin } = {} }) => {
      return isMulti && hasValue ? 'auto' : margin === 'dense' ? pxToRem(26.6333) : pxToRem(29.6333);
    },
    padding: 0,
    cursor: ({ isSearchable }) => isSearchable ? 'text' : 'pointer'
  },

  label: {
    width: '100%',
    paddingRight: 30,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  label_shrink: {
    paddingRight: 0
  }
});
