import { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { Popper } from '../../../../components/Popper';
import { activityTimerFetch } from '../../../../store/dashboard/activityTimer';
import { ActivityList } from './ActivityList/ActivityList';
import { TimerButton } from './TimerButton';

const mapStateToProps = ({ activityTimer }) => ({
  activeTimer: activityTimer.activeTimer,
  isTimerFetching: activityTimer.isFetching
});

const enhancer = connect(mapStateToProps, {
  activityTimerFetch
});

const propTypes = {
  // passed by react-redux connect
  activityTimerFetch: T.func,
  activeTimer: T.object,
  isTimerFetching: T.bool
};

export const ActivityTimer = enhancer(({ activityTimerFetch, activeTimer, isTimerFetching }) => {
  useEffect(() => {
    activityTimerFetch();
  }, []);

  return (
    <Popper anchorRenderer={
      ({ anchorRef, handleToggle }) => (
        <TimerButton
          ref={anchorRef}
          activeTimer={activeTimer}
          isTimerFetching={isTimerFetching}
          UIButtonsProps={{
            onClick: handleToggle
          }}
        />
      )}
    >
      <ActivityList />
    </Popper>
  );
});

ActivityTimer.propTypes = propTypes;
