export const apiModelsMap = {
  task: 'task',
  case: 'case',
  user: 'user',
  contacts_users: 'contacts_users',
  profile: 'profile',
  patient: 'patient',
  invoice: 'invoice',
  payment: 'payment',
  overdraft: 'overdraft',
  company: 'company',
  office: 'office',
  appointment: 'appointment',
  mri: 'mri',
  schedule_event: 'schedule_event'

};

export const itemsRoutesByModels = {
  [apiModelsMap.task]: (id) => `/tasks/${id}`,
  [apiModelsMap.case]: (id) => `/cases/${id}`,
  [apiModelsMap.invoice]: (id) => `/billing/invoices/${id}`,
  [apiModelsMap.payment]: (id) => `/billing/payments/${id}`,
  [apiModelsMap.overdraft]: (id) => `/billing/payments/assign/${id}`,
  [apiModelsMap.user]: (id) => `/members/users/${id}`,
  [apiModelsMap.profile]: (id) => `/members/users/${id}`,
  [apiModelsMap.patient]: (id) => `/patients/${id}`,
  [apiModelsMap.company]: (id) => `/contacts/companies/${id}`,
  [apiModelsMap.office]: (id) => `/contacts/offices/${id}`,
  [apiModelsMap.contacts_users]: (id) => `/contacts/users/${id}`
};
