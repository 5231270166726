export const getFormRoutes = ({ medicalInfoPathname, id }) => {
  return {
    0: `${medicalInfoPathname}/medical-forms/${id}/first-step`,
    1: `${medicalInfoPathname}/medical-forms/${id}/second-step`,
    2: `${medicalInfoPathname}/medical-forms/${id}/third-step`,
    3: `${medicalInfoPathname}/medical-forms/${id}/fourth-step`,
    4: `${medicalInfoPathname}/medical-forms/${id}/fifth-step`,
    5: `${medicalInfoPathname}/medical-forms/${id}/six-step`,
    6: `${medicalInfoPathname}/medical-forms/${id}/seven-step`
  };
};
