import { Yup } from '../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  report_type: Yup.mixed().required(),
  provider_id: Yup.mixed().nullable().required(),
  visit_reason_id: Yup.mixed().nullable(),
  office_id: Yup.mixed().required(),
  age: Yup.string().nullable().required(),
  side: Yup.string().nullable(),
  name: Yup.string().nullable().required().max(255)
});
