import { SvgIcon } from '@material-ui/core';
import LinkedServicesSvg from '../../../../../../components/icons/linked-services.svg';

export const servicesMenu = [
  {
    name: 'Services',
    icon: (props) => (
      <SvgIcon {...props}>
        <LinkedServicesSvg />
      </SvgIcon>
    ),
    path: '/services'
  }
];
