import { useContext } from 'react';
import { Box, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiFilter, mdiFilterMinus } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { filtersKeysMap } from '../../../../../../store/lastFilters';
import { Button } from '../../../../../../components/Button';
import { IconButton } from '../../../../../../components/IconButton';
import { Page, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { CodesContext } from '../CodesContext';
import { FiltersBar } from './FiltersBar';
import { List } from './List';

export const columnsWidths = {
  procedure_code: 130,
  description: 240
};


export const MainContent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { importCodes } = useContext(CodesContext);
  const {
    openFiltersBar,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps,
    toggleFiltersBar
  } = useMobileFilterCollapse();

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>
          ICD codes
        </PageHeaderTitle>

        <PageHeaderActions>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              size={isMobile ? 'small' : 'medium'}
              variant="contained"
              color="primary"
              onClick={importCodes}
            >
              Import
            </Button>


            {isMobile && (
              <Box clone ml={2}>
                <IconButton color="primary" onClick={toggleFiltersBar}>
                  <SvgIcon color="inherit">
                    {!openFiltersBar ? <Icon path={mdiFilter} /> : <Icon path={mdiFilterMinus} />}
                  </SvgIcon>
                </IconButton>
              </Box>
            )}
          </Box>
        </PageHeaderActions>
      </PageHeader>

      <Box clone mt={isMobile ? 0 : 2} mx={isMobile ? 0 : 3}>
        <Page variant={isMobile ? 'elevation' : 'outlined'} square>
          <ScrollWrapper {...scrollWrapperProps}>
            <FilterBarWrapper {...filterBarWrapperProps}>
              <FiltersBar filterKey={filtersKeysMap.codes_icd} />
            </FilterBarWrapper>
          </ScrollWrapper>

          <List />
        </Page>
      </Box>
    </Page>
  );
};
