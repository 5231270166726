import { useContext } from 'react';
import { Checkbox, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { CodesContext } from '../../../CodesContext';
import { CreateCodeNDCModal } from '../../../CreateCodeNDCModal';
import { minRowHeight, columnsWidthsSm, columnsWidthsLg } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ code }) => {
  const classes = useStyles();
  const {
    selectedCodesIDs,
    toggleItemSelection,
    deleteCode,
    massInactiveCodes,
    massActiveCodes
  } = useContext(CodesContext);
  const { openModal } = useModal();
  const isSelected = selectedCodesIDs.indexOf(code.id) !== -1;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const columnsWidths = isTablet ? columnsWidthsSm : columnsWidthsLg;

  const handleDeleteCode = () => deleteCode(code);

  const handleUpdateCode = () => {
    openModal(CreateCodeNDCModal, {
      payload: {
        initialCode: code
      }
    });
  };

  const toggleActiveItem = () => {
    code.deactivated_at ? massActiveCodes([ code.id ]) : massInactiveCodes([ code.id ]);
  };

  const handleItemSelection = (event) => {
    event.preventDefault();

    toggleItemSelection(code.id);
  };

  return (
    <ListRow minHeight={minRowHeight} selected={isSelected}>
      <Checkbox className={classes.checkbox} checked={isSelected} onClick={handleItemSelection}/>

      <ListRowCell grow={!isTablet} flexBasis={columnsWidths.procedure_code}>
        {code.code}
      </ListRowCell>

      <ListRowCell column={isTablet} grow flexBasis={columnsWidths.name}>
        <Typography noWrap>
          {code.name}
        </Typography>

        {isTablet && (
          <Typography noWrap variant="body2">
            {code.description}
          </Typography>
        )}

        {isMobile && (
          <Typography noWrap variant="body2" color="secondary">
            Dose: {code.dose}
          </Typography>
        )}
      </ListRowCell>

      {!isTablet && (
        <ListRowCell grow flexBasis={columnsWidths.description}>
          <Typography noWrap>
            {code.description}
          </Typography>
        </ListRowCell>
      )}

      {!isMobile && (
        <ListRowCell grow={!isTablet} flexBasis={columnsWidths.dose}>
          {code.dose}
        </ListRowCell>
      )}

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
        {code.deactivated_at &&
          <>
            <Tooltip title="Edit code">
              <IconButton
                size={isTablet ? 'small' : 'medium'}
                color="primary"
                variant="contained"
                onClick={handleUpdateCode}
              >
                <EditIcon fontSize={isTablet ? 'small' : 'default'} />
              </IconButton>
            </Tooltip>
            &nbsp;
          </>
        }

        <Tooltip title={code.deactivated_at ? 'Activate code' : 'Deactivate code'}>
          <IconButton
            size={isTablet ? 'small' : 'medium'}
            variant="contained"
            color={code.deactivated_at ? 'warning' : 'success'}
            onClick={toggleActiveItem}
          >
            {code.deactivated_at
              ? <LockIcon fontSize={isTablet ? 'small' : 'default'} />
              : <LockOpenIcon fontSize={isTablet ? 'small' : 'default'} />
            }
          </IconButton>
        </Tooltip>
        &nbsp;
        <Tooltip title="Delete code">
          <IconButton
            size={isTablet ? 'small' : 'medium'}
            variant="contained"
            color="error"
            onClick={handleDeleteCode}
          >
            <DeleteIcon fontSize={isTablet ? 'small' : 'default'} />
          </IconButton>
        </Tooltip>
      </ListRowCell>
    </ListRow>
  );
};
