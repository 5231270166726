import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import {
  List,
  ListItem,
  Collapse,
  Icon as MuiIcon,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { preventDefault, stopPropagation } from '../../../../../../helpers/dom';
import { toggleSidebar } from '../../../../../../store/dashboard/layout';
import { IconButton } from '../../../../../../components/IconButton';
import { MenuItems } from './MenuItems';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Item = ({
  isDeleted,
  isCollapsed,
  exact,
  name,
  icon: Icon,
  path,
  routes,
  onDeleteItem = () => {},

  ...props
}) => {
  const classes = useStyles(props);
  const { pathname } = useLocation();
  const isActive = exact ? pathname === path : pathname.indexOf(path) === 0;
  const [ isOpenItem, setIsOpen ] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const sidebarIsOpen = useSelector(({ layout: { sidebarIsOpen } }) => sidebarIsOpen);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const openMenuItem = () => {
    if (isMobile && sidebarIsOpen) {
      dispatch(toggleSidebar());
    }

    setIsOpen(true);
  };

  const toggleMenuItem = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsOpen((state) => !state);
  };

  useEffect(() => {
    if (isActive) {
      setIsOpen(true);
    }
  }, []);

  return (
    <div className={classes.root} key={path}>
      <ListItem
        button
        title={name}
        to={path}
        component={Link}
        className={cn(
          classes.listItem,
          {
            [classes.listItem_collapsed]: isCollapsed,
            [classes.listItem_activeParent]: isActive && routes,
            [classes.listItem_active]: isActive && !routes
          }
        )}
        onClick={openMenuItem}
      >
        <ListItemIcon className={classes.listItem__icon}>
          {Icon ? <Icon color="inherit" /> : <MuiIcon />}
        </ListItemIcon>

        {!isCollapsed &&
          <ListItemText
            primary={name}
            classes={{ primary: classes.listItem__primaryText }}
            primaryTypographyProps={{ noWrap: true }}
          />
        }

        {!!routes && !isCollapsed && (
          <IconButton
            color="inherit"
            onClick={toggleMenuItem}
          >
            {isOpenItem
              ? <ExpandLessIcon/>
              : <ExpandMoreIcon/>
            }
          </IconButton>
        )}

        {isDeleted &&
          <IconButton
            color="inherit"
            onClick={preventDefault(stopPropagation(onDeleteItem))}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      </ListItem>

      {!!routes &&
        <Collapse in={isOpenItem}>
          <List disablePadding>
            <MenuItems isCollapsed={isCollapsed} routes={routes} {...props} />
          </List>
        </Collapse>
      }
    </div>
  );
};
