import { useState } from 'react';
import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import {
  makeStyles,
  Checkbox,
  Typography,
  IconButton,
  List,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SvgIcon
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Popper } from '../../../../../../components/Popper';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  isCheckedHidden,
  listItem,
  onUpdate,
  index,
  EditForm,
  onDelete = () => {}
}) => {
  const classes = useStyles();
  const [ isChecked, setIsChecked ] = useState(!!listItem.is_checked);
  const [ isEditable, setIsEditable ] = useState(false);

  const toggleIsEditable = (handleMenuClose) => () => {
    handleMenuClose();
    setIsEditable((state) => !state);
  };

  const handleChecked = () => {
    setIsChecked((state) => !state);

    onUpdate({ ...listItem, is_checked: isChecked ? 0 : 1 });
  };

  const handleEdit = (listItem, text) => {
    return onUpdate(listItem, text);
  };

  const handleDelete = (event) => {
    event.preventDefault();
    onDelete(listItem);
  };

  return (
    <Draggable draggableId={`item - ${listItem.id || listItem.text}`} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          className={
            cn(
              classes.root,
              { [classes.root_main]: !isEditable },
              { [classes.root_selected]: isChecked && !isEditable },
              { [classes.root_hidden]: isChecked && isCheckedHidden }
            )
          }
          ref={innerRef}
          { ...draggableProps }
          { ...dragHandleProps }
        >
          <div className={cn(classes.title, { [classes.title_edit]: isEditable })}>
            <Checkbox className={classes.checkbox} checked={isChecked} onClick={handleChecked}/>

            {!isEditable ?
              <Typography className={classes.content}>
                {listItem.text}
              </Typography>
              :
              <EditForm
                isEditable={isEditable}
                listItem={listItem}
                setIsEditable={setIsEditable}
                onUpdate={handleEdit}
              />
            }
          </div>

          <Popper
            placement="right-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton
                  buttonRef={anchorRef}
                  className={cn(classes.menuButton, { [classes.menuButton_edit]: isEditable })}
                  onClick={handleToggle}
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <List>
                <MenuItem disabled={isEditable} onClick={toggleIsEditable(handleClose)}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>

                  <ListItemText primary="Edit" />
                </MenuItem>

                <MenuItem onClick={handleDelete}>
                  <ListItemIcon>
                    <SvgIcon fontSize="small">
                      <DeleteIcon />
                    </SvgIcon>
                  </ListItemIcon>

                  <ListItemText primary="Delete" />
                </MenuItem>
              </List>
            )}
          </Popper>
        </div>
      )}
    </Draggable>
  );
};
