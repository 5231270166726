import { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ThreadMessagesContext, MessageContextProvider } from '../../../../../components/chat';
import { ScrollbarsLoader } from '../../../../../components/ScrollbarsLoader';
import { Loader } from '../../../../../components/Loader';
import { MessageItem } from './MessageItem';

export const MessageList = ({ threadId }) => {
  const {
    threadMessages,
    isFetching,
    isFetched,
    loadNextPage,
    resetMessages,
    pagination,
    scrollerRef
  } = useContext(ThreadMessagesContext);
  const [ isScrollDisabled, setIsScrollDisabled ] = useState(false);

  const handleMenuToggled = (isOpened) => {
    setIsScrollDisabled(isOpened);
  };

  useEffect(() => {
    if (threadId) {
      resetMessages();
    }
  }, [ threadId ]);

  return (
    <Box height="100%">
      <Loader loading={!isFetched} p={2} render={
        () => (!threadMessages.length ? (
          <Box p={2}>
            <Typography variant="h4" align="center">Enter first message to chat</Typography>
          </Box>
        ) : (
          <ScrollbarsLoader
            reverse
            noScroll={isScrollDisabled}
            scrollerRef={scrollerRef}
            isFetching={isFetching}
            isFetched={isFetched}
            total={pagination.total}
            rowsCount={threadMessages.length}
            threshold={400}
            onNeedNextPage={loadNextPage}
          >
            {threadMessages.map((message, index) => (
              <MessageContextProvider message={message} messages={threadMessages} index={index} key={message.id}>
                <MessageItem onMenuToggled={handleMenuToggled} />
              </MessageContextProvider>
            ))}
          </ScrollbarsLoader>
        ))}
      />
    </Box>
  );
};
