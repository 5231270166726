import { api } from '../api';

export const fetchAppointments = (config) => {
  return api.get('/appointments', config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => { throw error; });
};

export const fetchCalendar = (config) => {
  return api.get('/appointments-calendar', config)
    .then(({ data }) => {
      return {
        daysData: data,
        appointments: Object.values(data).reduce((result, { appointments }) => {
          return result.concat(appointments);
        }, [])
      };
    })
    .catch((error) => { throw error; });
};

export const fetchAppointment = (id, config) => {
  return api.get(`/appointments/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createAppointment = (data) => {
  return api.post('/appointments', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const updateAppointment = ({ id, ...data }) => {
  return api.put(`/appointments/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteAppointment = (id) => {
  return api.delete(`/appointments/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteAppointmentsGroup = (id, config) => {
  return api.delete(`/appointments-groups/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchMedicalHistory = (config) => {
  return api.get('/appointments/medical-history', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const checkIn = (token, config) => {
  return api.get(`/calendar/check-in/${token}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
