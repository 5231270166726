import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_SUCCESS]: (
    { filter, groups, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      groups: (page > 1
        ? groups.filter(({ id }) => {
          return !data.find((group) => id === group.id);
        }).concat(data)
        : data
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE]: (state, updatedGroup) => {
    return {
      ...state,

      groups: state.groups.map((group) => {
        return group.id === updatedGroup.id ? { ...group, ...updatedGroup } : group;
      })
    };
  },

  [types.ADD]: (state, group) => {
    const { pagination, filter, groups } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((groups.length + 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      groups: [ group, ...groups ]
    };
  },

  [types.DELETE]: (state, groupID) => {
    const { pagination, filter, groups } = state;
    const filteredgroups = groups.filter(({ id }) => groupID !== id);

    if (filteredgroups.length === groups.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((groups.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      groups: filteredgroups
    };
  }
});
