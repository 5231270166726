import { api } from '../../api';

export const fetchNotes = (config) => {
  return api.get('/medical-report/clinical-notes', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchNote = (id, config) => {
  return api.get(`/medical-report/clinical-notes/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createNote = (data) => {
  return api.post('/medical-report/clinical-notes', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateNote = ({ id, ...data }) => {
  return api.put(`/medical-report/clinical-notes/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteNote = (id) => {
  return api.delete(`/medical-report/clinical-notes/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteNotes = (IDs) => {
  return api.delete('/medical-report/clinical-notes', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
