import { CaseRemindersProvider } from '../../../../../../../components/cases/reminders/case-reminders';
import { Card } from './Card';

export const Reminders = ({ caseItem, onCaseItemUpdate, ...props }) => {
  return (
    <CaseRemindersProvider caseId={caseItem.id}>
      <Card {...props} />
    </CaseRemindersProvider>
  );
};
