import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, useTheme, useMediaQuery } from '@material-ui/core';
import * as templatesApi from '../../../../api/schedule-events/schedule-event-templates';
import { errorsMap } from '../../../../utils/validation';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../Modal';
import { Loader } from '../../../Loader';
import {
  transformTimeZoneToOption,
  transformScheduleEventTypeToOption
} from '../../../FormField';
import { eventsParentsTypesOptions } from '../eventsParentsTypesOptions';
import { EventTemplateForm, validationSchema } from '../EventTemplateForm';

const getInitialValues = (event) => {
  return {
    ...event,

    type_id: transformScheduleEventTypeToOption(event.type),
    parent_type: eventsParentsTypesOptions.find(({ value }) => value === event.parent_type),
    parent_id: event.parent,
    office_id: event.office,
    timezone: transformTimeZoneToOption(event.timezone)
  };
};

export const EditEventTemplateModal = ({
  DialogProps,
  payload: {
    initialValues
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const updateEvent = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return templatesApi.updateEventTemplate(values).then((event) => {
      enqueueSnackbar(`Template "${event.name}" successfully updated`, { variant: 'success' });
      handleModalResolve(event);
    }).catch(({ data: { errors } = {} }) => {
      if (errors?.name?.includes(errorsMap.unique)) {
        setErrors({ name: 'Template already exists' });
      } else if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Template not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={getInitialValues(initialValues)}
      validationSchema={validationSchema}
      onSubmit={updateEvent}
    >
      {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
        <Dialog disableEnforceFocus fullScreen={isMobile} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Edit template
            </ModalHeader>

            <ModalBody>
              <EventTemplateForm values={values} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
