import { useLocation, Link } from 'react-router-dom';
import { Box, Tab, Tabs, Paper } from '@material-ui/core';
import { Route } from '../../../../components/router';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { TimeReports } from './TimeReports';
import { WorkSessions } from './WorkSessions';

const tabsRoutes = {
  workSessions: '/profile/work-sessions',
  timeReports: '/profile/work-sessions/time-reports'
};

export const WorkSessionsPage = () => {
  const location = useLocation();

  return (
    <Paper component={Box} display="flex" flexDirection="column" flexGrow={1} width="100%">
      <Tabs
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          component={Link}
          value={tabsRoutes.workSessions}
          to={tabsRoutes.workSessions}
          label="Work Sessions"
        />

        <Tab
          component={Link}
          value={tabsRoutes.timeReports}
          to={tabsRoutes.timeReports}
          label="Time Reports"
        />
      </Tabs>

      <Route exact path={tabsRoutes.workSessions}>
        <WorkSessions filterKey={filtersKeysMap.profile_work_sessions} />
      </Route>

      <Route exact path={tabsRoutes.timeReports}>
        <TimeReports filterKey={filtersKeysMap.profile_time_reports} />
      </Route>
    </Paper>
  );
};
