import { useContext } from 'react';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../../components/Loader';
import { PageBody } from '../../../../../../../components/Page';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { CodesContext } from '../../CodesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 52;
export const columnsWidths = {
  code: 130,
  description: 240,
  indicator: 240,
  action: 120
};

export const List = ({ baseUrl }) => {
  const classes = useStyles();
  const {
    codes,
    isFetched,
    filter,
    pagination: { total },
    resetCodes
  } = useContext(CodesContext);

  return (
    <Loader
      p={2}
      loading={!isFetched}
      render={() =>
        !codes.length ? (
          <Typography align="center" className={classes.title}>
            No codes found
          </Typography>
        ) : (
          <>
            <TableHeader />

            <PageBody fullHeight disablePaddings>
              <MuiList disablePadding>
                {codes.map((code) => (
                  <Row key={code.id} code={code} baseUrl={baseUrl} />
                ))}
              </MuiList>

              <TablePagination pagination={{ ...filter, total }} onChange={resetCodes} />
            </PageBody>
          </>
        )
      }
    />
  );
};
