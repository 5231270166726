import { makeStyles, Chip } from '@material-ui/core';
import { emphasize } from '@material-ui/core/styles';
import classNames from 'classnames';
import CancelIcon from '@material-ui/icons/Cancel';

export const styles = ({ spacing, palette: { grey, ...palette } }) => ({
  chip: {
    margin: spacing(0.25)
  },

  chipFocused: {
    backgroundColor: emphasize(
      palette.type === 'light' ? grey[300] : grey[700],
      0.08
    )
  }
});

const useStyles = makeStyles(styles);

export const MultiValue = ({ isFocused, removeProps: { onClick, removeProps }, children }) => {
  const classes = useStyles();

  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(classes.chip, {
        [classes.chipFocused]: isFocused
      })}
      onDelete={onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
};
