import { useRef } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useResizeObserver } from '../../../../../../helpers/hooks/useResizeObserver';
import { billingParticipantsTypesOptions, billingParticipantsTypesMap } from '../../../../../../components/billing';
import {
  Autocomplete,
  InvoicesStatusesSelect,
  KeyboardDatePicker,
  TextField,
  Select,
  CompaniesAutocomplete
} from '../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../components/users';
import { widthBreakpointMedium  } from '../../InvoiceItem';
import { fetchTerms } from '../../helpers';
import { mapOfTerms } from './mapOfTerms';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const BillInfo = () => {
  const theme = useTheme();
  const rootRef = useRef();
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const gridSmall = width < widthBreakpointMedium ? 5 : 3;
  const gridLarge = width < widthBreakpointMedium ? 8 : 4;

  const handleParticipantTypeChange = (participantFieldName) => () => {
    setFieldValue(participantFieldName, null);
  };

  const handleTermsChange = (option) => {
    if (option) {
      const newDate = moment(values.date).add(mapOfTerms[option.value], 'days').format('YYYY-MM-DD');

      setFieldValue('due_date', newDate);
    }
  };

  return (
    <Grid
      ref={rootRef}
      container
      spacing={isMobile ? 1 : 3}
      direction="row"
      justify="space-between"
      alignItems="flex-start"
    >
      <Grid item xs={isMobile ? 12 : gridSmall}>
        <Typography variant="h6" className={classes.headerFirst}>Bill from</Typography>

        <Autocomplete
          required
          preventManualChangeNotification
          name="bill_from.type"
          label="Select participant type"
          options={billingParticipantsTypesOptions}
          onChange={handleParticipantTypeChange('bill_from.id')}
        />

        {values?.bill_from?.type === billingParticipantsTypesMap.user &&
          <UsersSelect
            required
            name="bill_from.id"
            label="Select user"
            margin="dense"
          />
        }

        {values?.bill_from?.type === billingParticipantsTypesMap.company &&
          <CompaniesAutocomplete
            required
            name="bill_from.id"
            label="Select company"
            margin="dense"
          />
        }
      </Grid>

      <Grid item xs={isMobile ? 12 : gridSmall}>
        <Typography variant="h6" className={classes.headerSecond}>Bill To</Typography>

        <Autocomplete
          required
          preventManualChangeNotification
          name="bill_to.type"
          label="Select participant type"
          options={billingParticipantsTypesOptions}
          onChange={handleParticipantTypeChange('bill_to.id')}
        />

        {values?.bill_to?.type === billingParticipantsTypesMap.user &&
          <UsersSelect
            required
            name="bill_to.id"
            label="Select user"
            margin="dense"
          />
        }

        {values?.bill_to?.type === billingParticipantsTypesMap.company &&
          <CompaniesAutocomplete
            required
            name="bill_to.id"
            label="Select company"
            margin="dense"
          />
        }
      </Grid>

      <Grid item xs={isMobile ? 12 : gridLarge}>
        <Typography variant="h6" className={classes.headerThird}>Invoice details</Typography>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          wrap="wrap"
        >
          <Grid item xs={isMobile ? 12 : 5}>
            <KeyboardDatePicker
              required
              zeroMinWidth
              outputFormat="YYYY-MM-DD"
              name="date"
              label="Invoice date"
              placeholder="date"
              margin="dense"
            />
          </Grid>

          <Grid item xs={isMobile ? 12 : 5}>
            <KeyboardDatePicker
              required
              zeroMinWidth
              outputFormat="YYYY-MM-DD"
              name="due_date"
              label="Payments due date"
              placeholder="due date"
              margin="dense"
            />
          </Grid>

          <Grid item xs={isMobile ? 12 : 5}>
            <InvoicesStatusesSelect
              isClearable
              name="status_id"
              label="Invoice status"
              placeholder="Select status..."
              TextFieldProps={{ margin: 'dense' }}
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              name="po"
              label="P.O.no"
              margin="dense"
            />
          </Grid>

          <Grid item xs={isMobile ? 6 : 5}>
            <Select
              formattedValue
              name="terms"
              label="Terms"
              isDisabled={!values.date}
              promiseWithOptions={fetchTerms}
              TextFieldProps={{ required: true, margin: 'dense' }}
              onChange={handleTermsChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
