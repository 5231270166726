import cn from 'classnames';
import { Dialog, makeStyles } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { DocumentVersionsContextProvider } from './DocumentVersionsContext';
import { VersionPreviewContext, VersionPreviewContextProvider } from './VersionPreviewContext';
import { PreviewWindow } from './PreviewWindow';
import { VersionsList } from './VersionsList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const DocumentVersionsModal = ({
  isModalEntered,
  DialogProps,
  payload: { documentID, onVersionChange },
  handleModalReject
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      classes={{
        paper: classes.paper
      }}
      BackdropProps={{
        invisible: true
      }}
      {...DialogProps}
    >
      <DocumentVersionsContextProvider documentID={documentID}>
        <VersionPreviewContextProvider>
          <VersionPreviewContext.Consumer>
            {({ isFetching, sidebarIsOpen, handleVersionSelect }) => (
              <div className={classes.root}>
                <div className={classes.preview}>
                  {isModalEntered && !isFetching ?
                    <PreviewWindow onModalClose={handleModalReject} onVersionChange={onVersionChange} />
                    :
                    <Loader className={classes.loader} />
                  }
                </div>

                <div className={cn(classes.list, !sidebarIsOpen && classes.list_collapsed)}>
                  {isModalEntered ?
                    <VersionsList documentID={documentID} onVersionSelect={handleVersionSelect} />
                    :
                    <Loader className={classes.loader}/>
                  }
                </div>
              </div>
            )}
          </VersionPreviewContext.Consumer>
        </VersionPreviewContextProvider>
      </DocumentVersionsContextProvider>
    </Dialog>
  );
};
