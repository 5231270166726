import { Box } from '@material-ui/core';
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Page, PageBody } from '../../../../../components/Page';
import { Route } from '../../../../../components/router';
import { MedicalFormItemContext } from '../MedicalFormItemProvider';
import { FormSteps } from '../FormSteps';
import { formComponentsByTypes } from './formComponentsByTypes';
import { FollowUp } from './FollowUp';

export const FormItemComponent = ({ disableFollowUp = false, medicalInfoPathname = '' }) => {
  const { type } = useContext(MedicalFormItemContext);

  return (
    <Page
      square={false}
      elevation={0}
      component={Box}
      pt={0.5}
    >
      <PageBody fullHeight disablePaddings>
        {!disableFollowUp && (
          <Route
            exact
            path={`${medicalInfoPathname}/medical-forms/:medicalFormID/edit`}
            component={FollowUp}
          />
        )}

        {type ?
          <Route
            exact
            path={`${medicalInfoPathname}/medical-forms/create`}
            component={formComponentsByTypes[type]}
          />
          :
          <Route exact path={`${medicalInfoPathname}/medical-forms/create`}>
            <Redirect to={`${medicalInfoPathname}/medical-forms`} />
          </Route>
        }

        <Route
          exact
          path={`${medicalInfoPathname}/medical-forms/:medicalFormID/first-step`}
          component={FormSteps}
        />

        <Route
          exact
          path={`${medicalInfoPathname}/medical-forms/:medicalFormID/second-step`}
          component={FormSteps}
        />

        <Route
          exact
          path={`${medicalInfoPathname}/medical-forms/:medicalFormID/third-step`}
          component={FormSteps}
        />

        <Route
          exact
          path={`${medicalInfoPathname}/medical-forms/:medicalFormID/fourth-step`}
          component={FormSteps}
        />

        <Route
          exact
          path={`${medicalInfoPathname}/medical-forms/:medicalFormID/fifth-step`}
          component={FormSteps}
        />

        <Route
          exact
          path={`${medicalInfoPathname}/medical-forms/:medicalFormID/six-step`}
          component={FormSteps}
        />

        <Route
          exact
          path={`${medicalInfoPathname}/medical-forms/:medicalFormID/seven-step`}
          component={FormSteps}
        />
      </PageBody>
    </Page>
  );
};
