import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_SCREENSHOTS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_SCREENSHOTS_SUCCESS]: (
    { filter, screenshots, ...state },
    { data: newScreenshots, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      screenshots: (page > 1
        ? screenshots.filter(({ id }) => !newScreenshots.find((video) => id === video.id)).concat(newScreenshots)
        : newScreenshots
      )
    };
  },

  [types.RESET_SCREENSHOTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.DELETE_SCREENSHOT]: (state, { id }) => {
    return {
      ...state,
      screenshots: state.screenshots.filter((item) => item.id !== id)
    };
  },

  [types.ADD_SCREENSHOT]: (state, payload) => {
    const { pagination, filter, screenshots, ...otherState } = state;

    const total = pagination.total + 1;
    const page = Math.ceil((screenshots.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      screenshots: screenshots.concat(payload)
    };
  }
});
