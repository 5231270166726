export const steps = [
  'General info',
  'Appointment',
  'Current Symptoms',
  'Neck/Lower Back/Middle Back',
  'Medical history',
  'Review of Symptoms',
  'Form Preview'
];

export const injurySteps = [
  'General info',
  'Appointment',
  'Current Symptoms',
  'Accident information',
  'Neck/Lower Back/Middle Back',
  'Medical history',
  'Form Preview'
];

export const injuryOrthoSteps = [
  'General info',
  'Appointment',
  'Patient history',
  'Accident information',
  'Past medical history',
  'Review of Symptoms',
  'Form Preview'
];

export const stepsTitle = {
  'create': 0,
  'first-step': 0,
  'second-step': 1,
  'third-step': 2,
  'fourth-step': 3,
  'fifth-step': 4,
  'six-step': 5,
  'preview': 6
};

export const getExistingFormSteps = (form) => {
  return {
    0: true,
    1: !!form?.appointment?.id,
    2: !!form?.forms?.current_symptoms,
    3: !!form?.forms?.pain_in_neck || !!form?.forms?.pain_in_low_back || !!form?.forms?.middle_back,
    4: !!form?.forms?.medical_history,
    5: !!form?.forms?.review_of_symptoms,
    6: true
  };
};

export const getExistingInjuryFormSteps = (form) => {
  return {
    0: true,
    1: !!form?.appointment?.id,
    2: !!form?.forms?.current_symptoms_pi,
    3: !!form?.forms?.accident_information,
    4: !!form?.forms?.pain_in_neck || !!form?.forms?.pain_in_low_back || !!form?.forms?.middle_back,
    5: !!form?.forms?.medical_history,
    6: true
  };
};

export const getExistingInjuryOrthoFormSteps = (form) => {
  return {
    0: true,
    1: !!form?.appointment?.id,
    2: !!form?.forms?.patient_history,
    3: !!form?.forms?.accident_information_ortho,
    4: !!form?.forms?.past_medical_history,
    5: !!form?.forms?.review_of_orthopedic_symptoms,
    6: true
  };
};
