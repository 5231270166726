export const styles = ({ palette }) => ({
  mainInfo: {
    display: 'flex',
    alignItems: 'flex-end'
  },

  borderTop: {
    borderTop: `1px solid ${palette.divider}`
  },

  borderBottom: {
    borderBottom: `1px solid ${palette.divider}`
  },

  borderLeft: {
    borderLeft: `1px solid ${palette.divider}`
  },

  borderRight: {
    borderRight: `1px solid ${palette.divider}`
  }
});
