import { useContext } from 'react';
import { Box, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { SortableHandle } from 'react-sortable-hoc';
import { generateDescription } from '../../../../../../../components/emails/options';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../components/IconButton';
import { Switch } from '../../../../../../../components/FormField';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { Date } from '../../../../../../../components/Date';
import {
  ListRow,
  ListRowCell,
  ListRowCellActions
} from '../../../../../../../components/ListRow';
import { AccountsContext } from '../../../../AccountsContext';
import { RulesEmailsContext } from '../../../RulesEmailsContext';
import { EmailsRuleFormModal } from '../../EmailsRuleFormModal';
import { columnsWidths } from '../Rules';

const RowDragHandle = SortableHandle(() => {
  return (
    <ListRowCell flexBasis={columnsWidths.sortAction}>
      <Box display="display" alignItems="alignItems">
        <DragIndicatorIcon />
      </Box>
    </ListRowCell>
  );
});

export const RuleItem = ({ item: rule = {}, disableDragHandle }) => {
  const { accounts } = useContext(AccountsContext);
  const { toggleActive, editRule, fetchRules, deleteRule } = useContext(RulesEmailsContext);
  const { openModal } = useModal();
  const theme = useTheme();
  const isTable = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  if (rule) {
    rule.description = generateDescription(rule.conditions, 'condition');
    rule.description += `, ${generateDescription(rule.actions, 'action', undefined, !rule.stop_processing)}`;
    rule.description += rule.stop_processing ? ' and stop processing more rules on this message' : '';
  }

  const handleToggleActive = () => {
    toggleActive(rule);
  };

  const handleEditEmailRule = () => {
    openModal(EmailsRuleFormModal, {
      payload: {
        accounts,
        initialRule: rule
      },
      onModalResolved: (rule) => {
        if (disableDragHandle) {
          fetchRules();
        } else {
          editRule(rule);
        }
      }
    });
  };

  const handleDeleteEmailRule = () => {
    deleteRule(rule.id);
  };

  return !!rule && (
    <Paper>
      <ListRow button px={isTable ? 1 : 3}>
        {!disableDragHandle && <RowDragHandle />}

        <ListRowCell flexBasis={columnsWidths.lock}>
          <Switch
            withoutFormik
            checked={rule.is_active}
            color="primary"
            onChange={handleToggleActive}
          />
        </ListRowCell>

        <ListRowCell grow={isTable} column flexBasis={columnsWidths.account}>
          <Tooltip isExistTooltip={!!rule?.config?.email} title={rule?.config?.email}>
            <Typography gutterBottom={isTable} noWrap variant="h5">{rule?.config?.email || '-'}</Typography>
          </Tooltip>

          {isTable && (
            <Typography gutterBottom={!isTable} noWrap variant="h4">{rule.name}</Typography>
          )}
        </ListRowCell>

        {!isTable && (
          <ListRowCell grow column flexBasis={columnsWidths.description}>
            <Typography gutterBottom noWrap variant="h4">{rule.name}</Typography>
            <Typography noWrap>{rule.description || '-'}</Typography>
          </ListRowCell>
        )}

        {!isMobile && (
          <ListRowCell column flexBasis={columnsWidths.date}>
            <Date date={rule.updated_at || rule.created_at} />
          </ListRowCell>
        )}

        <ListRowCell flexBasis={columnsWidths.action}>
          <ListRowCellActions>
            <IconButton
              variant="contained"
              color="primary"
              onClick={handleEditEmailRule}
            >
              <EditIcon />
            </IconButton>

            <IconButton
              edge="end"
              variant="contained"
              color="error"
              onClick={handleDeleteEmailRule}
            >
              <DeleteIcon />
            </IconButton>
          </ListRowCellActions>
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
