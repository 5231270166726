import { useSnackbar } from 'notistack';
import { Box, Typography } from '@material-ui/core';
import * as eventsApi from '../../api/schedule-events';
import { eventConfirmationMap } from '../../components/schedule-events';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { Button } from '../../components/Button';
import { useModal } from '../../components/ModalsProvider';

export const ConfirmationSection = ({ isExpired, token, event, onConfirm }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const confirmEmailByToken = (confirmation) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        eventsApi.confirmEventByToken(token, {
          status: confirmation
        }).then(() => {
          onConfirm(confirmation);
          enqueueSnackbar('Your answer accepted!', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Event already confirmed', { variant: 'error' });
        });
      }
    });
  };

  return (
    <Box mt={3} display="flex" alignItems="center">
      {isExpired ? (
        <Typography variant="h4">
          Event already expired!
        </Typography>
      ) : event.confirmation ? (
        <Typography variant="h4">
          You have already answered "{event.confirmation}".
        </Typography>
      ) : (
        <Box display="flex" alignItems="center">
          <Typography variant="h4">
            Please confirm event
          </Typography>

          <Box ml={2} display="flex">
            <Button
              size="medium"
              color="success"
              onClick={confirmEmailByToken(eventConfirmationMap.yes)}
            >
              Yes
            </Button>

            <Button
              size="medium"
              color="error"
              onClick={confirmEmailByToken(eventConfirmationMap.no)}
            >
              No
            </Button>

            <Button
              size="medium"
              color="warning"
              onClick={confirmEmailByToken(eventConfirmationMap.maybe)}
            >
              Maybe
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
