import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { Button, Checkbox, Dialog, FormControlLabel, Typography } from '@material-ui/core';
import { participantsUserRoles } from '../../../../../../../../dataMaps';
import { updateUserStorage } from '../../../../../../../../store/globalUser/operations';
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContainer
} from '../../../../../../../../components/Modal';

export const CaseUsersModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const dispatch = useDispatch();
  const userGlobalStore = useSelector(({ globalUser }) => globalUser);
  const participants = userGlobalStore?.data?.participants;
  const [ rolesList, setRolesList ] = useState(
    participantsUserRoles.map((role) => {
      const existedParticipant = participants?.find(({ option }) => role.value === option.value);

      return { label: role.label, value: existedParticipant?.value ?? true, option: role };
    })
  );
  const [ checkedAll, setCheckedAll ] = useState([]);

  const handleMultiChangeCheckbox = () => {
    const allItemIsSelected = checkedAll.length === rolesList.length;

    setCheckedAll(allItemIsSelected ? [] : rolesList.map((item) => item.label));
    setRolesList(rolesList.map((item) => ({ ...item, value: !allItemIsSelected })));
  };

  const handleChangeCheckbox = (labelValue) => (event) => {
    const { checked } = event.target;
    const role = rolesList.find(({ label }) => label === labelValue);

    role.value = checked;
    let result = [];

    if (!checked) {
      result = checkedAll.filter((item) => item !== role.label);
    } else {
      result = checkedAll.concat(role.label);
    }

    setCheckedAll(result);
    setRolesList(rolesList.map((item) => isEqual(role, item) ? role : item));
  };

  const onSubmit = () => {
    dispatch(updateUserStorage({ participants: rolesList?.filter(({ option }) => !isEmpty(option)) }));
    handleModalResolve(rolesList);
  };

  useEffect(() => {
    const checkedList = rolesList?.filter((item) => item.value)?.map((item) => item.label);

    setCheckedAll(checkedList);
  }, []);

  return (
    <Dialog {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedAll.length === rolesList.length}
                indeterminate={checkedAll.length !== rolesList.length}
                value="secondary"
                color="primary"
                onChange={handleMultiChangeCheckbox}
              />
            }
            label={<Typography variant="h4">Toggle All</Typography>}
          />
        </ModalHeader>

        <ModalBody>
          {rolesList.map((role) => (
            <FormControlLabel
              key={role.label}
              control={
                <Checkbox
                  value="secondary"
                  color="primary"
                  checked={role.value}
                  onChange={handleChangeCheckbox(role.label)}
                />
              }
              label={role.label}
            />
          ))}
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleModalReject}>Cancel</Button>

          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Dialog>
  );
};
