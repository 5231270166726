import { fileToObject, getFileNameExtension } from '../../helpers/files';
import { api } from '../api';
import { tagsActionsMap, tagsChangingMethodsMap } from './tags';
import * as deletedFiles from './deleted-files';

const transformFilesConfig = (config) => {
  if (config?.params?.tasks?.length) {
    return {
      ...config,

      params: {
        ...config?.params,

        cases: null
      }
    };
  }

  return config;
};

export const fetchFiles = (config = {}) => {
  return api.get('/files', transformFilesConfig(config)).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const fetchSharedFiles = (config = {}) => {
  return api.get('/shared-files', transformFilesConfig(config)).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const fetchPersonalSharedFiles = (config = {}) => {
  return api.get('/personal-shared-files', {
    cancelToken: new api.CancelToken((cancel) => fetchFiles.cancel = cancel),
    ...config
  }).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const downloadPersonalSharedFiles = (config = {}) => {
  return api.get('/personal-shared-files/archive', config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const searchFiles = (config = {}) => {
  return api.get('/files/search', {
    cancelToken: new api.CancelToken((cancel) => fetchFiles.cancel = cancel),
    ...config
  }).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const uploadFile = (data, config) => {
  return api.post('/files', data, config).then(({ data: { data } }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const uploadPersonalSharedFile = (data, config) => {
  return api.post('/personal-shared-files', data, config).then(({ data: { data } }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const copyFile = ({ id, ...data }, options) => {
  return api.post(`/files/${id}`, data, options).then(({ data: { data } }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const openFile = ({ id }, options) => {
  return api.post(`/files/${id}/opened_at`, options).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const getEditFileUrl = (id, options) => {
  return api.get(`/files/${id}/edit-url`, options).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const changeTags = ({ files, tags, actionType }, options) => {
  const method = tagsChangingMethodsMap[actionType];

  if (!method) {
    throw new Error(`actionType must be one of: "${Object.values(tagsActionsMap)}"`);
  }

  const data = { files, tags };
  const isDelete = method === tagsChangingMethodsMap[tagsActionsMap.delete];

  return api[method]('/files-tags', isDelete ? { params: data } : data, options);
};

export const deleteFiles = deletedFiles.addFiles;

const transformFileReq = (file) => {
  return {
    mime: file.type,
    extension: getFileNameExtension(file.original_filename),

    ...fileToObject(file)
  };
};

export const validateFile = (file) => {
  return api.post('/files/validation', transformFileReq(file));
};

export const validatePersonalSharedFile = (file) => {
  return api.post('/personal-shared-files/validation', transformFileReq(file));
};

export const renameFile = ({ id, original_filename }) => {
  return api.put(`/files/${id}`, { original_filename });
};

export const createArchive = (data) => {
  return api.post('/archives', data).then(({ data }) => {
    return data;
  }).catch(({ data: { errors } }) => {
    throw errors;
  });
};

export const generatePdf = (data) => {
  return api.post('/edit-pdf', data).then(({ data }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const downloadArchive = (id, config) => api.get(`/archives/${id}`, config);

export const convertFile = (id, config) => api.get(`/converting/${id}/pdf`, config);

export const shareFiles = (data, options) => api.post('/shared-files', data, options);

export const preventShareFiles = (config) => api.delete('/shared-files', config);

export const shareByEmail = (data, options) => {
  return api.post('/third-party-shared-files', data, options).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const preventShareByEmail = (config) => {
  return api.delete('/third-party-shared-files', config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};
