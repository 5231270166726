import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Button, Collapse, makeStyles } from '@material-ui/core';
import { focusTextInput } from '../../../helpers/dom';
import { getUserNameAbbr } from '../../../helpers/users';
import { TextField } from '../../FormField';
import { Avatar } from '../../Avatar';
import { Loader } from '../../Loader';
import { CommentsContext } from '../CommentsContext';
import { CommentActions } from '../CommentActions';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EditForm = ({
  comment,
  setIsUpdate,
  isUpdate
}) => {
  const classes = useStyles();
  const { updateComment } = useContext(CommentsContext);
  const currentUser = useSelector(({ profile }) => profile.user);

  const replayComment = (values, { setSubmitting, resetForm, setErrors }) => {
    return updateComment({ id: comment.id, ...values })
      .then(() => {
        resetForm();
        setIsUpdate(false);
      })
      .catch(({ status, data: { errors } = {} }) => {
        if (status === 422) {
          setSubmitting(false);
          errors && setErrors(errors);
        }
      });
  };

  const handleFormReset = (callback) => () => {
    setIsUpdate(false);
    callback();
  };

  return (
    <Collapse in={isUpdate} className={classes.root}>
      <Formik
        initialValues={{
          text: comment.text
        }}
        onSubmit={replayComment}
      >
        {({ handleSubmit, isSubmitting, dirty, resetForm }) => (
          <form
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <div className={classes.formField}>
              <Avatar
                src={currentUser?.profile?.avatar || currentUser?.avatar}
                alt={getUserNameAbbr(currentUser)}
                className={classes.userImage}
              />

              <TextField
                multiline
                name="text"
                placeholder="Add a replay"
                margin="dense"
                inputRef={focusTextInput}
              />
            </div>

            <CommentActions>
              <Button
                className={classes.cancelBtn}
                onClick={handleFormReset(resetForm)}
              >
                Cancel
              </Button>

              <Loader  surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!dirty || isSubmitting}
                  >
                    Update
                  </Button>
                )}
              />
            </CommentActions>
          </form>
        )}
      </Formik>
    </Collapse>
  );
};
