// Cyclic dependency, dont shortening path
import { orderDirectionsMap } from '../../../../components/FormField/selects/OrderBy/orderDirectionsMap';
import { orderByOptions } from './orderByOptions';

export const initialState = {
  isFetched: false,
  isFetching: false,
  filter: {
    original_filename: null,
    order_by: orderByOptions[0].value,
    order_direction: orderDirectionsMap.desc,
    tags: []
  },
  pagination: {
    total: 0,
    last_page: 1
  },
  files: [],
  selectedFilesIDs: []
};
