import cn from 'classnames';
import { Chip, makeStyles, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { Avatar } from '../../../Avatar';
import { styles } from '../Select/components/MultiValue';

const useStyles = makeStyles(styles);

export const MultiValue = ({
  isFocused,
  removeProps,
  children
}) => {
  const classes = useStyles();
  const { first_name = '', last_name = '', avatar } = children.profile || children;

  return (
    <Chip
      tabIndex={-1}
      avatar={<Avatar alt={first_name[0] + last_name[0]} src={avatar} />}
      label={<Typography noWrap>{`${first_name} ${last_name}`}</Typography>}
      className={cn(classes.chip, {
        [classes.chipFocused]: isFocused
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
};
