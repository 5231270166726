import {
  Box,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { mdiLink, mdiVirusOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { formTypesMap } from '../../../../../../../components/medical/forms';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { Date } from '../../../../../../../components/Date';
import { CovidPreviewFormModal } from '../../CovidPreviewFormModal';
import { FormPreviewModal } from '../../FormPreviewModal';

export const FormChildrenInfo = ({ forms })  => {
  const { openModal } = useModal();

  const openFormPreviewModal = (form) => () => {
    if (form.form_type === formTypesMap.covid_form) {
      openModal(CovidPreviewFormModal, {
        payload: { formID: form.id }
      });
    } else {
      openModal(FormPreviewModal, {
        payload: {
          formID: form.id
        }
      });
    }
  };

  return forms?.map((form) => (
    <List key={form.id}>
      <MenuItem onClick={openFormPreviewModal(form)}>
        <ListItemIcon>
          <Box color="info.main">
            <SvgIcon>
              <Icon
                path={form.form_type === formTypesMap.covid_form ? mdiVirusOutline : mdiLink}
              />
            </SvgIcon>
          </Box>
        </ListItemIcon>

        <ListItemText
          primary={<Typography variant="h5">{form.form_type}</Typography>}
          secondary={<Date variant="caption" disableIcon date={form?.created_at} />}
        />
      </MenuItem>
    </List>
  ));
};
