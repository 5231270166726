import { get } from 'lodash';
import { Line } from 'react-chartjs-2';
import { palette } from '../../../../../../../themes/palette';

export const Chart = ({ data, labels }) => {
  const paymentsData = data.map((item) => get(item, 'sum', 0));

  const getChartData = () => ({
    labels,
    datasets: [
      {
        fillColor: 'rgba(0, 188, 212, 0.05)',
        backgroundColor: 'rgba(0, 188, 212, 0.05)',
        hoverBackgroundColor: 'rgba(0, 188, 212, 0.05)',
        borderColor: palette().primary.main,
        borderCapStyle: 'butt',
        lineTension: 0.5,
        borderWidth: 2,
        pointBorderWidth: 1,
        pointBorderColor: palette().primary.main,
        pointBackgroundColor: palette().primary.main,
        pointHoverBackgroundColor: palette().primary.main,
        pointHoverRadius: 5,
        pointRadius: 3,
        pointHitRadius: 10,
        pointHoverBorderWidth: 2,
        data: paymentsData
      }
    ]
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend:{
      display: false
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem) => ' $ ' + new Intl.NumberFormat('en-US').format(tooltipItem.value)
      }
    },
    scales: {
      yAxes: [ {
        ticks: {
          beginAtZero:true,
          callback: (label) => {
            label = new Intl.NumberFormat('en-US').format(label);

            return label;
          }
        },
        gridLines: {
          color: palette().grey[200],
          borderDash: [ 2, 3 ]
        }
      } ],
      xAxes: [ {
        gridLines: {
          drawOnChartArea: false,
          color: palette().grey[200],
          borderDash: [ 2, 3 ]
        },
        ticks: {
          beginAtZero: true
        }
      } ]
    }
  };

  return (
    <Line data={getChartData} options={chartOptions} />
  );
};
