import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { Label } from '../MainWindow/Label';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const DueDate = ({ task }) => {
  const classes = useStyles();
  const today = moment().unix();
  const createdAt = task.due_at - task.created_at;
  const rest = task.due_at - today;
  const timeLeftInPercents = rest > 0 && ~~(rest * 100  / createdAt);

  const colorClosedArchived = today < task.due_at &&
                task.archived_at < task.due_at &&
                task.closed_at < task.due_at
    ? 'low': 'red';

  const colorDate = timeLeftInPercents > 15 ? 'lightGrey' : 'medium';
  const color = rest > 0 && task?.status?.alias !== 'archived'
    && task?.status?.alias !== 'closed' ? colorDate : colorClosedArchived;

  return (
    <div className={classes.root}>
      <Label
        name={moment.unix(task.due_at).format('L')}
        color={color}
      />
    </div>
  );
};
