import { useContext, useRef } from 'react';
import { Typography, Box, List as MuiList } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
import { SavedEmailsContext } from '../SavedEmailsContext';
import { EmailsListItem } from './EmailsListItem';

export const widthBreakpointMedium = 520;
export const widthBreakpointLarge = 765;
export const minRowHeight = 80;
export const columnsWidths = {
  name: 200,
  info: 210,
  actions: 50
};

export const EmailsList = () => {
  const { isFetching, isFetched, meta, messages, loadNextPage } = useContext(SavedEmailsContext);
  const scrollElementRef = useRef();
  const { width } = useResizeObserver({ ref: scrollElementRef });

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%', flexGrow: 1 }}>
      <Loader loading={!isFetched} p={2} render={
        () => !messages.length ? (
          <Box p={2}>
            <Typography align="center">There is no messages</Typography>
          </Box>
        ) : (
          <MuiList disablePadding component="nav">
            <InfiniteListLoader
              items={messages}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={meta}
              RowComponent={EmailsListItem}
              RowProps={{ width }}
              scrollElementRef={scrollElementRef}
              onNeedNextPage={loadNextPage}
            />
          </MuiList>
        )}
      />
    </CustomScrollbars>
  );
};
