export const transformPlaceDetailsToAddress = (placeDetails) => {
  const location = placeDetails?.geometry?.location;
  const address = {
    street_number: '',
    street: '',
    state: '',
    country: '',
    city: '',
    zip: '',
    lat: location?.lat ? '' + location?.lat() : null,
    lng: location?.lng ? '' + location?.lng() : null
  };

  placeDetails?.address_components?.forEach(({ long_name, short_name, types }) => {
    if (types.includes('postal_code')) {
      address.zip = short_name;
    } else if (types.includes('country')) {
      address.country = short_name;
    } else if (types.includes('neighborhood')) {
      address.city = long_name;
    } else if (types.includes('administrative_area_level_3')) {
      address.city = long_name;
    } else if (types.includes('locality')) {
      address.city = long_name;
    } else if (types.includes('administrative_area_level_1')) {
      address.state = short_name;
    } else if (types.includes('route')) {
      address.street = long_name;
    } else if (types.includes('street_number')) {
      address.street_number = short_name;
    }
  });

  return address;
};
