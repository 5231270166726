import { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { merge } from 'lodash';
import { useSnackbar } from 'notistack';
import * as formsApi from '../../../../../../api/profile';
import { api } from '../../../../../../api';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { formTypesMap } from '../../../../../../components/medical/forms';
import { UserContext } from '../../../../Members/User/UserProvider';
import * as types from './types';
import { initialState } from './initialState';
import { reducer } from './reducer';

export const MedicalFormsContext = createContext(null);

export const MedicalFormsProvider = ({ initialState: initialStateProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const {
    form,
    isFetched,
    filter,
    pagination,
    forms,
    medicalInfoPathname,
    selectedIDs
  } = state;
  const [ isUnconfirmed, setIsUnconfirmed ] = useState(false);
  const parentRoute = `${medicalInfoPathname}/medical-forms`;
  const cancelFetch = useRef(() => {});
  const cancelUnconfirmedFetch = useRef(() => {});
  const userContext = useContext(UserContext);

  const fetchForms = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FORMS_REQUEST });

    formsApi.fetchMedicalForms({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      setIsUnconfirmed(false);
      dispatch({ type: types.FETCH_FORMS_SUCCESS, payload: data });
    });
  };

  const fetchUnconfirmedForms = (newFilter = {}) => {
    cancelUnconfirmedFetch.current();

    dispatch({ type: types.FETCH_UNCOMFIRMED_FORMS_REQUEST });

    formsApi.fetchUnconfirmedMedicalForms({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelUnconfirmedFetch.current = cancel)
    }).then((data) => {
      setIsUnconfirmed(true);
      dispatch({ type: types.FETCH_UNCOMFIRMED_FORMS_SUCCESS, payload: data });
    });
  };

  const approveForm = (form) => {
    formsApi.approveMedicalForm(form.id).then(() => {
      fetchUnconfirmedForms();
      enqueueSnackbar('Successfully approved', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Form not approved', { variant: 'error' });
    });
  };

  const deleteForm = (form) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        formsApi.deleteMedicalForm(form.id).then(() => {
          if (pagination.last_page > filter.page || form?.form_type === formTypesMap.follow_up) {
            isUnconfirmed
              ? fetchUnconfirmedForms({ page: filter.page })
              : fetchForms({ page: filter.page });
          } else if (forms.length - 1 === 0 && filter.page !== 1) {
            isUnconfirmed
              ? fetchUnconfirmedForms({ page: filter.page - 1 })
              : fetchForms({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_FORM, payload: form });
          }
        });
      }
    });
  };

  const allFormsIsSelected = () => {
    return forms.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_FORM_SELECTION, payload: id });
  };

  const toggleAllFormsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_FORMS_SELECTION });
  };

  const massDeleteForms = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        formsApi.massDeleteForms(IDs).then(() => {
          IDs.forEach((id) => toggleItemSelection(id));

          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            isUnconfirmed
              ? fetchUnconfirmedForms({ page: filter.page })
              : fetchForms({ page: filter.page });
          } else if (forms.length - selectedIDs.length === 0 && filter.page !== 1) {
            isUnconfirmed
              ? fetchUnconfirmedForms({ page: filter.page - 1 })
              : fetchForms({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.MASS_DELETE_FORMS, payload: IDs });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Forms are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const resetForms = (newFilter) => {
    dispatch({ type: types.RESET_FORMS, payload: newFilter });

    fetchForms({ page: 1, ...newFilter });
  };

  const resetUnconfirmedForms = (newFilter) => {
    dispatch({ type: types.RESET_FORMS, payload: newFilter });

    fetchUnconfirmedForms({ page: 1, ...newFilter });
  };

  const providerValue = {
    ...state,
    form,
    isUnconfirmed,
    isFetched,
    forms,
    filter,
    pagination,
    parentRoute,
    selectedIDs,
    medicalInfoPathname,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    approveForm,
    fetchForms,
    fetchUnconfirmedForms,
    resetForms,
    resetUnconfirmedForms,
    toggleItemSelection,
    toggleAllFormsSelection,
    allFormsIsSelected,
    deleteForm,
    massDeleteForms
  };

  useEffect(() => {
    !!userContext?.user?.medical?.id && resetForms({ medical_id: userContext?.user?.medical?.id });
  }, [ userContext?.user?.medical?.id ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
      cancelUnconfirmedFetch.current();
    };
  }, []);

  return (
    <MedicalFormsContext.Provider value={providerValue}>
      {children}
    </MedicalFormsContext.Provider>
  );
};
