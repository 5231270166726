import { objectKeysToMap } from '../../../../helpers/objectKeysToMap';

export const columnsWidths = {
  supervisor: 200,
  user: 200,
  start: 160,
  end: 160,
  supervisorApprove: 80,
  workerApprove: 80,
  actions: 40
};

export const columnsMap = objectKeysToMap(columnsWidths);
