import { useContext, useEffect } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { AppointmentsFilterProvider, AppointmentsProvider } from '../../../../../components/appointments';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { FiltersBar } from '../../../../../components/appointments/FiltersBar';
import { ErrorBoundary } from '../../../../../components/ErrorBoundary';
import { minWidthForListView } from '../Appointments';
import { CalendarProvider } from '../CalendarProvider';
import { Calendar } from './Calendar';
import { Grid } from './Grid';
import { List } from './List';

export const Content = ({
  isFilterVisible,
  filter,
  patient,
  width,
  hiddenFilterFields,
  filterKey,
  FilterBarWrapper,
  filterBarWrapperProps,
  ScrollWrapper,
  scrollWrapperProps
}) => {
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const isListView = viewVariant === viewVariantsMap.list;
  const isGridView = viewVariant === viewVariantsMap.grid;
  const theme = useTheme();
  const isTablet = width <= theme.breakpoints.values.tablet;
  const isListOrGridView = isListView || isGridView;

  useEffect(() => {
    if (width < minWidthForListView && viewVariant === viewVariantsMap.list) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width, viewVariant ] );

  useEffect(() => {
    if (isTablet) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width, viewVariant ] );

  return (
    <ErrorBoundary>
      {isListOrGridView &&
        <AppointmentsFilterProvider filter={filter} filterKey={filterKey}>
          <Box height="100%" display="flex" flexDirection="column">
            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                {isFilterVisible &&
                  <FiltersBar
                    width={width}
                    viewVariant={viewVariant}
                    hiddenFields={hiddenFilterFields}
                    filterKey={filterKey}
                  />
                }
              </FilterBarWrapper>
            </ScrollWrapper>

            <Box flexGrow={1} bgcolor="white">
              <AppointmentsProvider>
                {isTablet || isGridView
                  ? <Grid />
                  : isListView
                    ? <List />
                    : null
                }
              </AppointmentsProvider>
            </Box>
          </Box>
        </AppointmentsFilterProvider>
      }

      {!isTablet && viewVariant === viewVariantsMap.calendar &&
        <AppointmentsFilterProvider filter={filter} filterKey={filterKey}>
          <Box height="100%" display="flex" flexDirection="column">
            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                {isFilterVisible &&
                  <FiltersBar
                    viewVariant={viewVariant}
                    hiddenFields={hiddenFilterFields}
                    filterKey={filterKey}
                  />
                }
              </FilterBarWrapper>
            </ScrollWrapper>

            <Box flexGrow={1} bgcolor="white" display="flex" flexDirection="column">
              <CalendarProvider>
                <Calendar width={width} patient={patient} />
              </CalendarProvider>
            </Box>
          </Box>
        </AppointmentsFilterProvider>
      }
    </ErrorBoundary>
  );
};
