import { useEffect, useState } from 'react';
import cn from 'classnames';
import { makeStyles, Box, Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { mdiFilePlusOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { ContentCard } from '../../../ContentCard';
import { useModal } from '../../../ModalsProvider';
import { IconButton } from '../../../IconButton';
import { SvgIcon } from '../../../SvgIcon';
import { Fab } from '../../../Fab';
import { SituationalNumberFormModal, transformSituationalNumberForForm } from '../../SituationalNumberFormModal';
import { styles } from './styles';
import { SupplementalInformation } from './SupplementalInformation';
import { ChiropractorClaims } from './ChiropractorClaims';
import { VisionClaims } from './VisionClaims';
import { MainInfo } from './MainInfo';
import { Medicaid } from './Medicaid';
import { WorkInfo } from './WorkInfo';
import { Hospice } from './Hospice';

const useStyles = makeStyles(styles);

export const SituationalNumber = ({ claim, onChange }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ isCollapsed, setIsCollapsed ] = useState(isMobile);

  const toggleCollapsed = () => {
    setIsCollapsed((state) => !state);
  };

  const updateSituationalNumber = () => {
    openModal(SituationalNumberFormModal, {
      payload: {
        initialValues: {
          claim_id: claim.id,

          ...transformSituationalNumberForForm(claim.appointment?.medical_form_info),
          ...claim.situational_number
        }
      },
      onModalResolved: (situationalNumber) => {
        onChange(situationalNumber);
      }
    });
  };

  useEffect(() => {
    if (isMobile) {
      toggleCollapsed();
    }
  }, [ isMobile ]);

  return (
    <ContentCard
      variant="outlined"
      color="info"
      isCollapsed={isCollapsed}
      title="Situational Number"
      leftActions={[
        <Box mr={1}>
          <SvgIcon color="info"><Icon path={mdiFilePlusOutline}/></SvgIcon>
        </Box>
      ]}
      rightActions={!claim?.id ? [] : [
        !claim.situational_number ? null : (
          <IconButton onClick={updateSituationalNumber}>
            <EditIcon />
          </IconButton>
        ),

        <IconButton edge="end" color="info" onClick={toggleCollapsed}>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      ]}
    >
      {!claim?.id ? (
        <Box display="flex" justifyContent="center" p={2} color="info.main">
          <Box mr={1}>
            <InfoOutlinedIcon color="inherit" />
          </Box>

          <Typography color="inherit">Сan be added after saving claim</Typography>
        </Box>
      ) : !claim.situational_number ? (
        hasRole(rolesMap.patient) ? (
          <Typography>Situational Number not found</Typography>
        ) : (
          <Box p={2}>
            <Fab
              variant="extended"
              color="primary"
              size={isMobile ? 'small' : 'medium'}
              startIcon={<AddIcon />}
              onClick={updateSituationalNumber}
            >
              Add Situational Number
            </Fab>
          </Box>
        )
      ) : (
        <Grid container component={Box} overflow="hidden">
          <Grid
            item
            sm={6}
            xs={12}
            className={cn(
              classes.mainInfo,
              classes.borderLeft,
              classes.borderTop,
              classes.borderBottom,
              classes.borderRight
            )}
          >
            <MainInfo claim={claim} />
          </Grid>

          <Grid item sm={6} xs={12} className={cn(classes.borderTop, classes.borderBottom, classes.borderRight)}>
            <Medicaid claim={claim} />
          </Grid>

          <Grid item sm={6} xs={12} className={cn(classes.borderBottom, classes.borderLeft, classes.borderRight)}>
            <WorkInfo claim={claim} />
          </Grid>

          <Grid item sm={6} xs={12} className={cn(classes.borderBottom, classes.borderRight)}>
            <ChiropractorClaims claim={claim} />
          </Grid>

          <Grid item sm={6} xs={12} className={cn(classes.borderBottom, classes.borderLeft, classes.borderRight)}>
            <VisionClaims claim={claim} />
          </Grid>

          <Grid item sm={6} xs={12} className={cn(classes.borderBottom, classes.borderRight)}>
            <SupplementalInformation claim={claim} />
          </Grid>

          <Grid item sm={6} xs={12} className={cn(classes.borderLeft, classes.borderRight, classes.borderBottom)}>
            <Hospice claim={claim} />
          </Grid>

          <Grid item md={6} />
        </Grid>
      )}
    </ContentCard>
  );
};
