import { Polar } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';

export const Chart = ({ data, labels }) => {
  const { palette } = useTheme();
  const color = data.map((item) => item?.color) || null;
  const chartData = data.map((item) => item?.tasks_count) || null;

  const getChartData = () => ({
    labels: labels.map((item) => (
      item
        .split('_')
        .map((item, i) => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ')
    )),
    datasets: [
      {
        label: 'Task Statistics',
        backgroundColor: color || [
          palette.primary.main,
          palette.pink,
          palette.amberyellow,
          '#3F51B5',
          palette.purple,
          '#CDDC39'
        ],
        borderWidth: 1,
        data: chartData
      }
    ]
  });

  const chartOptions = {
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 11,
        fontSize: 11
      }
    }
  };

  return (
    <Polar options={chartOptions} data={getChartData()} />
  );
};
