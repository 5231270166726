import { Formik } from 'formik';
import { Dialog, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { CasesSelect } from '../../../../../../../components/cases/CasesSelect';
import { Loader } from '../../../../../../../components/Loader';
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '../../../../../../../components/Modal';
import * as emailsApi from '../../../../../../../api/emails';
import { validationSchema } from './validationSchema';

export const AddAttachmentToCase = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { selectedFiles = [], folderID, messageUIDs, attachmentName }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const saveEmail = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return emailsApi.addAttachmentToCase(values).then(() => {
      enqueueSnackbar('Attachment added to case', { variant: 'success' });
      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Attachment not added to case', { variant: 'error' });
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        folder_id: folderID,
        message_uid: messageUIDs,
        attachments: !attachmentName ? selectedFiles : [ attachmentName ],
        case_id: null
      }}
      onSubmit={saveEmail}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Add attachment to case
            </ModalHeader>

            <ModalBody>
              <CasesSelect
                name="case_id"
                label="Select case"
                placeholder="Select case..."
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
