export const transformToOption = (item) => item && ({
  value: item,
  label: item
});

export const transformIsActiveToOption = (item, text) => item && ({
  value: item,
  label: text
});

export const transformDataIdToOption = (item) => item && ({
  value: item.id,
  label: item.name
});

