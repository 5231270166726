import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { orderByLabelsMap } from '../../../../../../components/claims';
import { ColorPreview } from '../../../../../../components/ColorPreview';
import { Tooltip } from '../../../../../../components/Tooltip';
import { UserLink } from '../../../../../../components/UserLink';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {!!params.patients?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Patients:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.patients?.map((user) => (
                <Box display="flex" width="100%" alignItems="center" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    key={user.id}
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params.providers?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Providers:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.providers?.map((user) => (
                <Box display="flex" width="100%" alignItems="center" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    key={user.id}
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params.appointment_books?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Appt. Books:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.appointment_books?.map((appointmentBook) => (
                <Tooltip key={appointmentBook.id} title={appointmentBook?.appointment_book_name}>
                  <Typography noWrap>
                    {appointmentBook?.appointment_book_name}
                  </Typography>
                </Tooltip>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.appointment_types?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Appt. Types:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.appointment_types.map((appointmentType) => (
                <Box key={appointmentType.id} display="flex" alignItems="center">
                  <Box display="flex" alignItems="center" mr={0.25}>
                    <ColorPreview color={appointmentType.color} />
                  </Box>

                  <Typography noWrap>
                    {appointmentType.code}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.visit_reasons?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Visit Reasons:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.visit_reasons?.map((reason) => (
                <Typography noWrap key={reason.id}>
                  {reason.code + (reason.description ? ` - ${reason.description}` : '')}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.appointment_day_from &&
        <>
          <Grid item xs={4}>
            <Typography>
              Appt. Day From:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment.unix(params.appointment_day_from).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.appointment_day_to &&
        <>
          <Grid item xs={4}>
            <Typography>
              Appt. Day To:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment.unix(params.appointment_day_to).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.order_by &&
        <>
          <Grid item xs={4}>
            <Typography>
              Order By:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {orderByLabelsMap[params?.order_by]}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
