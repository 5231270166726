import { createContext, useRef, useEffect, useReducer, useCallback } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { usePrevious } from '../../../../../../../../helpers/hooks';
import { api } from '../../../../../../../../api';
import * as discoveriesApi from '../../../../../../../../api/cases/discovery';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const CaseDiscoveriesContext = createContext();

export const CaseDiscoveriesProvider = ({ caseItem, filter: filterProp = {}, children, onCaseItemUpdate }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    discoveries
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchDiscoveries = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_DISCOVERIES_REQUEST });

    discoveriesApi.fetchCaseDiscoveries(caseItem.id, {
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_DISCOVERIES_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchDiscoveries({ page: filter.page + 1 });
    }
  };

  const resetDiscoveries = (newFilter) => {
    dispatch({ type: types.RESET_DISCOVERIES, payload: newFilter });

    fetchDiscoveries({ page: 1, ...newFilter });
  };

  const addDiscovery = useCallback((discovery) => {
    dispatch({ type: types.ADD_DISCOVERY, payload: discovery });
  });

  const updateDiscovery = useCallback((discovery) => {
    dispatch({ type: types.UPDATE_DISCOVERY, payload: discovery });
  });

  const deleteDiscovery = (discovery) => {
    dispatch({ type: types.DELETE_DISCOVERY, payload: discovery.id });
  };

  const providerValue = {
    isFetched,
    isFetching,
    caseItem,
    discoveries,
    filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    addDiscovery,
    resetDiscoveries,
    fetchDiscoveries,
    updateDiscovery,
    loadNextPage,
    applyFilter,
    onCaseItemUpdate,
    deleteDiscovery
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      resetDiscoveries(newFilter);
    }
  }, [ filter, filterProp ]);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ] );

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetDiscoveries(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    resetDiscoveries();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CaseDiscoveriesContext.Provider value={providerValue}>
      {children}
    </CaseDiscoveriesContext.Provider>
  );
};
