import { useContext } from 'react';
import { truncate } from 'lodash';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarmsOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import { useModal } from '../../../../../../ModalsProvider';
import { ScheduleContext, ViewScheduleEventModal } from '../../../../../../schedule-events';
import { CardHeader, CardItem } from '../../../../../../Cards';
import { OfficeLink } from '../../../../../../OfficeLink';
import { UserLink } from '../../../../../../UserLink';
import { UserAvatar } from '../../../../../../users';

export const Card = ({ item: event = {}, isMulti = false, ListRowProps = {} }) => {
  const { selectedEventsIDs = [], toggleSingleEventSelection, toggleEventSelection } = useContext(ScheduleContext);
  const isSelected = selectedEventsIDs?.includes(event?.id);
  const { openModal } = useModal();

  const handleSelect = () => {
    toggleEventSelection(event.id);
  };

  const handleSingleSelect = () => {
    toggleSingleEventSelection(event.id);
  };

  const handleEventClick = () => {
    openModal(ViewScheduleEventModal, {
      payload: { event, disableActions: true }
    });
  };

  return (
    <CardItem py={1} selected={isSelected} {...ListRowProps} onClick={handleEventClick}>
      <CardHeader
        isSelected={isSelected}
        disableCardMenu
        onItemSelect={isMulti ? handleSelect : handleSingleSelect}
        pb={1}
      >
        <Typography noWrap variant="h5">
          {event?.title || '-'}
        </Typography>
      </CardHeader>

      <Grid container spacing={1}>
        <Grid container spacing={1} alignItems="center" item xs={12}>
          <Grid item xs={2}>
            <Typography variant="caption" color="primary">
              Start
            </Typography>
          </Grid>

          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <EventOutlinedIcon fontSize="small" />
              </Grid>

              <Grid item>
                <Typography variant="subtitle2">
                  {moment.unix(event.started_at).format('L')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <AccessAlarmsIcon fontSize="small" />
              </Grid>

              <Grid item>
                <Typography variant="subtitle2">
                  {moment.unix(event.started_at).format('LT')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} alignItems="center" item xs={12}>
          <Grid item xs={2}>
            <Typography variant="caption" color="primary">
              Finish
            </Typography>
          </Grid>

          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <EventOutlinedIcon fontSize="small" />
              </Grid>

              <Grid item>
                <Typography variant="subtitle2">
                  {moment.unix(event.finished_at).format('L')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <AccessAlarmsIcon fontSize="small" />
              </Grid>

              <Grid item>
                <Typography variant="subtitle2">
                  {moment.unix(event.finished_at).format('LT')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption">Description:</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            {truncate(event.description, { length: 80 })}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography>Office:</Typography>
        </Grid>

        <Grid item xs={8}>
          <OfficeLink
            variant="h5"
            office={event?.office}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>Organizer:</Typography>
        </Grid>

        <Grid item xs={8}>
          <UserLink
            key={event?.creator?.id}
            user={event?.creator}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>Participants:</Typography>
        </Grid>

        <Grid item xs={8}>
          <Box display="flex" alignItems="center" flewWrap="wrap">
            {!event?.users?.length ? '-' : event?.users?.map((user) => (
              <UserAvatar key={user.id} user={user} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </CardItem>
  );
};
