import bg from './bg.png';

const fullSidebarWidth = 275;
const collapsedSidebarWidth = 55;

export const styles = ({ breakpoints }) => ({
  drawer: {
    flexShrink: 0
  },

  paper: {
    overflow: 'hidden',
    background: `#282C2E url(${bg}) center / cover`,
    position: 'absolute'
  },

  drawerAndPaper: {
    width: collapsedSidebarWidth,

    [breakpoints.down('sm')]: {
      width: 0
    }
  },

  drawerAndPaperOpened: {
    width: fullSidebarWidth,

    [breakpoints.down('sm')]: {
      width: '100%'
    }
  }
});
