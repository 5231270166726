import { useContext, useState } from 'react';
import { debounce } from 'lodash';
import { Formik } from 'formik';
import { Fab, Box, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { TextField } from '../../../../../components/FormField';
import { useModal } from '../../../../../components/ModalsProvider';
import { AddChatModal, MessengerContext } from '../../../../../components/chat';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChatsNavigation = () => {
  const classes = useStyles();
  const { openModal } = useModal();
  const [ fields, setFields ] = useState({});
  const { applyFilter } = useContext(MessengerContext);
  const { setCurrentThreadId } = useContext(MessengerContext);

  const addChat = () => {
    openModal(AddChatModal, {
      onModalResolved: ({ id }) => {
        setCurrentThreadId(id);
      }
    });
  };

  const handleSearchChange = debounce((event) => {
    const value = event.target.value;

    applyFilter({ name: value });
    setFields({ ...fields, name: value });
  }, 600);

  return (
    <div className={classes.root}>
      <Formik initialValues={{ name: null }} onSubmit={null}>
        <TextField
          fullWidth
          name="name"
          variant="outlined"
          placeholder="Search chat"
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <SearchIcon color="primary" className={classes.searchIcon} />
            ),

            endAdornment: !hasRole(rolesMap.patient, rolesMap.client) && (
              <Box flexShrink={0}>
                <Fab
                  variant="extended"
                  color="primary"
                  onClick={addChat}
                >
                  Add chat
                </Fab>
              </Box>
            )
          }}
        />
      </Formik>
    </div>
  );
};
