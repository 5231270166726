import { createContext, useRef, useEffect, useReducer, useCallback } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { usePrevious } from '../../../helpers/hooks';
import * as workSessionsApi from '../../../api/work-clocks';
import * as workClockApi from '../../../api/work-clocks';
import { api } from '../../../api';
import { eventBus, eventBusEvents } from '../../../utils/eventBus';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const WorkSessionsContext = createContext();

export const WorkSessionsProvider = ({ filter: filterProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const initialFilter = useRef(state.filter);
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    workSessions,
    selectedWorkSessionsIDs,
    selectedWorkSessions
  } = state;
  const { openModal } = useModal();
  const prevFilter = usePrevious(filter);
  const { enqueueSnackbar } = useSnackbar();
  const cancelFetch = useRef(() => {});

  const fetchWorkSessions = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_WORK_SESSIONS_REQUEST });

    workSessionsApi.fetchWorkSessions({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_WORK_SESSIONS_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchWorkSessions({ page: filter.page + 1 });
    }
  };

  const resetWorkSessions = (newFilter) => {
    dispatch({ type: types.RESET_WORK_SESSIONS, payload: newFilter });

    fetchWorkSessions({ page: 1, ...newFilter });
  };

  const addWorkSessionsToSelected = (workSessions) => {
    dispatch({ type: types.ADD_WORK_SESSIONS_TO_SELECTED, payload: workSessions });
  };

  const addWorkSession = useCallback((workSession) => {
    dispatch({ type: types.ADD_WORK_SESSION, payload: workSession });
  });

  const updateWorkSession = useCallback((workSession) => {
    dispatch({ type: types.UPDATE_WORK_SESSION, payload: workSession });
  });

  const updateTrack = (track) => {
    dispatch({ type: types.UPDATE_TRACK, payload: track });
  };

  const deleteWorkSessionsFromSelected = (workSessionsIDs) => {
    dispatch({ type: types.DELETE_WORK_SESSIONS_FROM_SELECTED, payload: workSessionsIDs });
  };

  const toggleWorkSessionSelected = (workSession) => {
    if (selectedWorkSessionsIDs.indexOf(workSession.id) === -1) addWorkSessionsToSelected([ workSession ]);
    else deleteWorkSessionsFromSelected([ workSession.id ]);
  };

  const allWorkSessionsIsSelected = () => {
    return selectedWorkSessionsIDs
      .filter((id) => workSessions.find((workSession) => workSession.id === id))
      .length === workSessions.length;
  };

  const toggleAllWorkSessionsSelected = () => {
    const workSessionsIDs = workSessions.map(({ id }) => id);

    if (allWorkSessionsIsSelected()) {
      deleteWorkSessionsFromSelected(workSessionsIDs);
    } else {
      addWorkSessionsToSelected(workSessions);
    }
  };

  const deleteWorkSession = (workSession) => {
    dispatch({ type: types.DELETE_WORK_SESSION, payload: workSession.id });
  };

  const addTrack = (workSessionId, track) => {
    dispatch({ type: types.ADD_TRACK, payload: { workSessionId, track } });
  };

  const deleteTrack = (workSessionId, trackId, cb = () => {}) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        workClockApi.deleteTimer(trackId).then(() => {
          dispatch({ type: types.DELETE_TRACK, payload: { workSessionId, trackId } });
          enqueueSnackbar('Track successfully deleted', { variant: 'success' });
          cb();
        }).catch(() => {
          enqueueSnackbar('Track not deleted', { variant: 'error' });
        });
      }
    });
  };

  const providerValue = {
    isFetched,
    isFetching,
    workSessions,
    selectedWorkSessionsIDs,
    selectedWorkSessions,
    initialFilter: initialFilter.current,
    filter: filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    resetWorkSessions,
    fetchWorkSessions,
    updateTrack,
    updateWorkSession,
    loadNextPage,
    applyFilter,
    deleteTrack,
    addTrack,
    deleteWorkSession,
    toggleWorkSessionSelected,
    deleteWorkSessionsFromSelected,
    allWorkSessionsIsSelected,
    toggleAllWorkSessionsSelected
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ] );

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetWorkSessions(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    eventBus.on(eventBusEvents.workSessionAdd, addWorkSession);
    eventBus.on(eventBusEvents.workSessionUpdate, updateWorkSession);

    return () => {
      eventBus.remove(eventBusEvents.workSessionAdd, addWorkSession);
      eventBus.remove(eventBusEvents.workSessionUpdate, updateWorkSession);

      cancelFetch.current();
    };
  }, []);

  return (
    <WorkSessionsContext.Provider value={providerValue}>
      {children}
    </WorkSessionsContext.Provider>
  );
};
