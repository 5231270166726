import { useContext, useEffect } from 'react';
import { Box, List as MuiList, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { Loader } from '../../../../../../../components/Loader';
import { DocumentsContext } from '../DocumentsProvider';
import { ActionsBar } from './ActionsBar';
import { Grid } from './Grid';
import { Row } from './Row';
import { TableHeader } from './TableHeader';

export const minRowHeight = 52;
export const columnsWidths = {
  name: 150,
  created_at: 175,
  updated_at: 175,
  signed_users: 170,
  action: 70
};

export const MainContent = () => {
  const { isFetched, documents, filter, resetDocuments } = useContext(DocumentsContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    resetDocuments();
  }, []);

  return (
    <Box mt={3} display="flex" flexDirection="column" height="100%">
      <Box pr={2}>
        <ActionsBar />
      </Box>

      <Box flexGrow={1}>
        <Loader p={3} loading={!isFetched} render={
          () => !documents.length ? (
            <Box p={3}>
              <Typography align="center">No documents found</Typography>
            </Box>
          ) : isSmallScreen ? (
            <>
              <Grid documents={documents}/>
              <TablePagination pagination={filter} onChange={resetDocuments}/>
            </>
          ) : (
            <>
              <TableHeader/>

              <MuiList disablePadding>
                {documents.map((document) => (
                  <Row key={document.id} document={document}/>
                ))}
              </MuiList>

              <TablePagination pagination={filter} onChange={resetDocuments}/>
            </>
          )}
        />
      </Box>
    </Box>
  );
};
