import { Box } from '@material-ui/core';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsMap = {
  code: 'code',
  laterality: 'laterality',
  visits: 'visits',
  min: 'min'
};

export const columnsLargeWidths = {
  [columnsMap.code]: 270,
  [columnsMap.laterality]: 100,
  [columnsMap.visits]: 100,
  [columnsMap.min]: 100
};

export const columnsMobileWidths = {
  [columnsMap.code]: 120,
  [columnsMap.laterality]: 80,
  [columnsMap.visits]: 70,
  [columnsMap.min]: 70
};

export const Procedures = ({ procedures }) => {
  return (
    <Box border={1} borderColor="divider">
      <TableHeader />

      {procedures.map((procedure) => (
        <Row key={procedure.id} procedure={procedure} />
      ))}
    </Box>
  );
};
