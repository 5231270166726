import { activitiesActionsMap } from '../activitiesActionsMap';
import { activityActionMap, activitySessionTypesMap } from '../activitiesHelpers';
import { NewFileActivity } from '../fields';
import { billingActionTypesMap } from './billingActionTypesMap';
import { caseActionTypesMap } from './caseActionTypesMap';
import { commentActionTypesMap } from './commentActionTypesMap';
import { eventActionTypesMap } from './eventActionTypesMap';
import { taskActionTypesMap } from './taskActionTypesMap';
import { workSessionActionTypesMap } from './workSessionActionTypesMap';

export const getActivityComponent = ({ payload, logName, ...props }) => {
  const Component = activitiesActionsMap?.[logName]?.component;

  return !Component ? [] : ([
    <Component
      payload={payload}
      logName={logName}
      fieldLabel={activitiesActionsMap?.[logName]?.label}
      activityLabel={activitiesActionsMap?.[logName]?.label}
      {...(props || {})}
    />
  ]);
};

export const actionTypesMap = {
  ...taskActionTypesMap,
  ...commentActionTypesMap,
  ...billingActionTypesMap,
  ...caseActionTypesMap,
  ...eventActionTypesMap,
  ...workSessionActionTypesMap,

  [activityActionMap.createEmail]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.createProfileFile]: ({ file }) => {
    return [ <NewFileActivity file={file} /> ];
  },

  [activityActionMap.deleteProfileFile]: ({ file }) => {
    return [ <NewFileActivity disableAttach file={file} /> ];
  },

  [activityActionMap.createSharedFile]: (payload) => {
    return [ <NewFileActivity file={payload?.properties?.after?.file} /> ];
  },

  [activityActionMap.deleteSharedFile]: (payload) => {
    return [ <NewFileActivity showPreview disableAttach file={payload?.properties?.after?.file} /> ];
  },

  [activityActionMap.create]: (payload, logName) => {
    return getActivityComponent({ payload, logName, isNew: true });
  },

  [activityActionMap.update]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.delete]: (payload, logName) => {
    return getActivityComponent({ payload, logName, isDeleted: true });
  },

  [activityActionMap.updateUserSipCallReport]: (payload, logName) => (
    getActivityComponent({ payload, logName, isUser: true })
  ),

  [activityActionMap.updateCaseSipCallReport]: (payload, logName) => (
    getActivityComponent({ payload, logName, isCase: true })
  ),

  [activityActionMap.updateCaseReminderGroup]: (payload, logName) => (
    getActivityComponent({
      payload,
      logName,
      isGroup: true,
      isDeleted: logName === activitySessionTypesMap.removeCaseReminderGroupFromCase
    })
  ),

  [activityActionMap.updateCaseReminder]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  )
};

export const getActionMessages = ({ log_data, log_name = null, subject_type = null }) => {
  const name = log_name === 'default' ? subject_type || log_name : log_name;

  return actionTypesMap[log_data?.type]?.(log_data?.payload, name);
};
