import { useContext } from 'react';
import { Formik } from 'formik';
import { Box, Button } from '@material-ui/core';
import { Loader } from '../../../../../../components/Loader';
import { formTypesMap } from '../../../../../../components/medical/forms';
import { ReportsContext } from '../../../../../../components/medical/reports';
import { validationSchema } from '../validationSchema';
import { StepContext } from '../StepProvider';
import { StaffSignature } from './StaffSignature';
import { Body } from './Body';

export const FourthStep = () => {
  const { formValues, handleUpdateForm, handleBack } = useContext(StepContext);
  const { doi } = useContext(ReportsContext);
  const disableSignature = formValues?.medical_form?.form_type === formTypesMap.private;

  const updateForm = (values, { isSubmitting, setErrors }) => {
    handleUpdateForm({ values, isSubmitting, setErrors });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...formValues,
        forms: {
          ...formValues.forms,
          treatment_plan: formValues.forms?.treatment_plan || [ { text: null, approve: false } ]
        },
        doi
      }}
      validationSchema={validationSchema}
      onSubmit={updateForm}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Box
          noValidate
          component="form"
          display="flex"
          flexDirection="column"
          overflow="hidden"
          onSubmit={handleSubmit}
          height="100%"
        >
          <Body />

          {!disableSignature && <StaffSignature />}

          <Box display="flex" alignItems="center" ml="auto" p={2} pr={0}>
            <Box mr={2}>
              <Button
                color="primary"
                onClick={handleBack}
              >
                Back
              </Button>
            </Box>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  disabled={isSubmitting}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              )}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
};
