import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { preventDefault } from '../../../../../helpers/dom';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../components/TablePagination';
import { useModal } from '../../../../../components/ModalsProvider';
import { Button } from '../../../../../components/Button';
import { Sticky } from '../../../../../components/Sticky';
import { Loader } from '../../../../../components/Loader';
import { BillingSettingsContext } from '../BillingSettingsContext';
import { CreateCategoryModal } from '../CreateCategoryModal';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 54;
export const columnsWidths = {
  name: 300,
  action: 140,
  description: 300
};

export const MainContent = () => {
  const theme = useTheme();
  const { openModal } = useModal();
  const scrollElementRef = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { categories, isFetched, meta, resetCategories, fetchCategories } = useContext(BillingSettingsContext);

  const addCategory = () => {
    openModal(CreateCategoryModal, {
      onModalResolved: () => {
        fetchCategories();
      }
    });
  };

  useEffect(() => {
    resetCategories();
  }, []);

  return (
    <Box display="flex" height="100%" px={isMobile ? 0 : 3} py={isMobile ? 0 : 2} bgcolor="grey.100">
      <Page>
        <PageHeader>
          <PageHeaderTitle>Expense preference</PageHeaderTitle>

          <PageHeaderActions>
            <Button
              size={isMobile ? 'small' : 'medium'}
              color="primary"
              variant="contained"
              onClick={preventDefault(addCategory)}
              startIcon={<AddIcon />}
            >
              Add category
            </Button>
          </PageHeaderActions>
        </PageHeader>

        <PageBody fullHeight disableScrollbars disablePaddings>
          <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
            <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
              <Box p={isMobile ? 0 : 2}>
                <Loader
                  p={3}
                  loading={!isFetched}
                  render={() =>
                    !categories.length ? (
                      <Box p={3}>
                        <Typography align="center">No categories found</Typography>
                      </Box>
                    ) : (
                      <>
                        <Sticky containerElement={scrollElementRef.current}>
                          <TableHeader />
                        </Sticky>

                        <MuiList disablePadding>
                          {categories.map((category) => (
                            <Row key={category.id} category={category} categories={categories} />
                          ))}
                        </MuiList>

                        <TablePagination pagination={meta} onChange={resetCategories} />
                      </>
                    )
                  }
                />
              </Box>
            </CustomScrollbars>
          </Box>
        </PageBody>
      </Page>
    </Box>
  );
};
