import { List, ListItem, makeStyles } from '@material-ui/core';
import { MemberIndicator } from '../../../../../components/chat/MemberIndicator';
import { UserLink } from '../../../../../components/UserLink';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChatMembers = ({ members, owner }) => {
  const classes = useStyles();

  return (
    <List>
      {members?.map((user, index) => (
        <ListItem className={classes.chatMember} key={index}>
          <MemberIndicator invisible={owner?.id !== user.id}>
            <UserLink
              disableName
              user={user}
            />
          </MemberIndicator>

          <UserLink
            noAvatar
            user={user}
          />
        </ListItem>
      ))}
    </List>
  );
};
