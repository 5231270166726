export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { pxToRem, fontWeightMedium }
}) => ({
  filtersBar: {
    borderBottom: `1px solid ${grey[100]}`
  },

  dayWrapper: {
    position: 'relative'
  },

  day: {
    width: 36,
    height: 36,
    margin: '0 2px',
    color: 'inherit',
    fontSize: pxToRem(12)
  },

  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${palette.secondary.main}`,
    borderRadius: '50%'
  },

  nonCurrentMonthDay: {
    color: palette.text.primary
  },

  highlightNonCurrentMonthDay: {
    color: palette.text.primary
  },

  highlight: {
    background: palette.primary.main,
    color: palette.primary.contrastText
  },

  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },

  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  },

  datePicker: {
    display: 'none'
  },

  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(1, 2)
  },

  actionButton__title: {
    flexGrow: 1,
    fontWeight: fontWeightMedium,
    marginRight: spacing(2),
    color: palette.darkgreen
  },

  actionButton__periodBtn: {
    borderRadius: 0
  }
});
