import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import { downloadFile } from '../../../../../../../helpers/files';
import { Echo } from '../../../../../../../utils/echo';
import { downloadFileCodes } from '../../../../../../../api/codes/procedures';
import { Loader } from '../../../../../../../components/Loader';
import { CodesContext } from '../../CodesContext';

export const ExportCPTCodes = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const { exportCodes } = useContext(CodesContext);
  const [ isFetchingCodes, setIsFetchingCodes ] = useState(false);
  const currentUser = useSelector(({ profile }) => profile.user);

  const exportListCodes = () => {
    setIsFetchingCodes(true);
    exportCodes();
  };

  useEffect(() => {
    const jobsChanel = Echo.private(`jobs.${currentUser.id}`);

    const handleJobFinished = () => {
      downloadFileCodes({
        responseType: 'arraybuffer'
      }).then((data) => {
        downloadFile({
          src: new Blob([ data ], { type: 'application/octet-stream' }),
          name: `cpt codes ${moment().format('L LT')}.csv`
        });
      }).finally(() => setIsFetchingCodes(false));
    };

    const handleJobFailed = ({ error: { status } }) => {
      if (status === 404) {
        enqueueSnackbar('No files for archiving', {
          variant: 'error'
        });
      }
    };

    jobsChanel
      .listen('.finished', handleJobFinished)
      .listen('.failed', handleJobFailed);

    return () => {
      jobsChanel
        .stopListening('.finished', handleJobFinished)
        .stopListening('.failed', handleJobFailed);
    };
  }, []);

  return (
    <Loader
      surface
      loading={isFetchingCodes}
      render={() => (
        <Button
          variant="contained"
          color="secondary"
          disabled={isFetchingCodes}
          size={isMobile ? 'small' : 'medium'}
          onClick={exportListCodes}
        >
          export codes
        </Button>
      )}
    />
  );
};
