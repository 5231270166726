import bg from './bg.png';

export const styles = ({ spacing, palette, shape: { borderRadius }, shadows }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    background: `${palette.grey[500]} url(${bg}) center / cover`,
    padding: spacing(2, 3),
    minHeight: 90
  },

  title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    flexGrow: 1,
    color: palette.common.white
  },

  action: {
    color: palette.common.white
  },

  logoAvatar: {
    position: 'absolute',
    left: 0,
    top: -spacing(2),
    boxShadow: shadows[2]
  }
});
