import { Bool, Company, CurrencyFormat, Date, Text, UserActivityLink } from '../fields';

export const fieldsMap = {
  user: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="User" />,
  supervisor: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Supervisor" />,
  approvedBy: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Approved By" />,
  createdBy: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Created By" />,
  updatedBy: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Updated By" />,
  changed: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Changed User" />,
  report: (before, after) => <Text before={before?.id || before} after={after?.id || after} fieldLabel="Report" />,
  stop_type: (before, after) => (
    <Text before={before?.id || before} after={after?.id || after} fieldLabel="Stop Type" />
  ),
  date_to: (before, after) => <Date before={before} after={after} fieldLabel="Date To" />,
  date_from: (before, after) => <Date before={before} after={after} fieldLabel="Date From" />,
  created_at: (before, after) => <Date before={before} after={after} fieldLabel="Date of creation" />,
  updated_at: (before, after) => <Date before={before} after={after} fieldLabel="Date of updating" />,
  started_at: (before, after) => <Date before={before} after={after} fieldLabel="Started At" />,
  finished_at: (before, after) => <Date before={before} after={after} fieldLabel="Finished At" />,
  approved_at: (before, after) => <Date before={before} after={after} fieldLabel="Approved At" />,
  hasChangedTracks: (before, after) => <Bool before={before} after={after} fieldLabel="Has Changed Tracks" />,
  type: (before, after) => <Text before={before} after={after} fieldLabel="Type" />,
  user_id: (before, after) => <Text before={before} after={after} fieldLabel="User Id" />,
  session_id: (before, after) => <Text before={before} after={after} fieldLabel="Session Id" />,
  note: (before, after) => <Text before={before} after={after} fieldLabel="Note" />,
  report_id: (before, after) => <Text before={before} after={after} fieldLabel="Report ID" />,
  changed_id: (before, after) => <Text before={before} after={after} fieldLabel="Changed ID" />,
  created_by_id: (before, after) => <Text before={before} after={after} fieldLabel="Created By ID" />,
  company: (before, after) => <Company before={before} after={after} fieldLabel="Company" />,
  rate: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Current Rate" />,
  worker_approve: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Worker Approve" />,
  worker_approved_at: (before, after) => <Date before={before} after={after} fieldLabel="Worker Approved At" />,
  deleted_at: (before, after) => <Date before={before} after={after} fieldLabel="Date of deleting" />
};
