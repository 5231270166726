export const styles = ({ spacing }) => ({
  contentCard: {
    width: '100%'
  },

  icon: {
    marginRight: spacing()
  },

  contentCardWrapperInner: {
    padding: 0
  }
});
