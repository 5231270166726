import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../helpers/hooks';
import { Scrollbars } from '../../Scrollbars';
import { ScheduleContext } from '../ScheduleProvider';
import { InfiniteListLoader } from '../../InfiniteLoader';
import { Loader } from '../../Loader';
import { TableHeader } from './TableHeader';

export const ScheduleEventsList = ({ autoHeight, hiddenColumns, Row, RowProps = {}, ...props }) => {
  const {
    events,
    isFetched,
    isFetching,
    filter,
    loadNextPage,
    reloadEvents
  } = useContext(ScheduleContext);
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const scrollElementRef = useRef();

  useEffect(() => {
    reloadEvents();
  }, []);

  return (
    <Box ref={rootRef} display="flex" flexDirection="column" height="100%" {...props}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Box p={2}>
            <Typography align="center">No events found</Typography>
          </Box>
        ) : (
          <>
            <TableHeader
              width={width}
              isMulti={RowProps?.isMulti}
              ListRowProps={RowProps?.ListRowProps}
              hiddenColumns={hiddenColumns}
            />

            <Scrollbars viewRef={scrollElementRef} height="100%">
              <MuiList disablePadding>
                <InfiniteListLoader
                  items={events}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  RowProps={{ hiddenColumns, width, ...RowProps }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </Scrollbars>
          </>
        )}
      />
    </Box>
  );
};
