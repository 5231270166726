export const styles = ({ spacing, typography: { fontWeightMedium } }) => ({
  invoiceLink: {
    textDecoration: 'none'
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 170,
    padding: spacing(1, 4, 1, 4)
  },

  noteFooterContent: {
    minWidth: '50%',
    paddingLeft: spacing(2)
  },

  noteContent: {
    padding: spacing(1, 4, 0, 4)
  },

  noteIcon: {
    display: 'flex',
    alignItems:'center',
    fontWeight: fontWeightMedium
  },

  totalContent: {
    minWidth: 170,
    paddingLeft: spacing(2)
  }
});
