import { Formik } from 'formik';
import { Dialog, Button } from '@material-ui/core';
import * as publicAccessApi from '../../../../api/public-access';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../components/Modal';
import { KeyboardDateTimePicker, EmailsSelect } from '../../../../components/FormField';
import { Loader } from '../../../../components/Loader';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

export const ShareByEmailModal = ({
  DialogProps,
  payload: { usersOptions = [] },
  handleModalResolve,
  handleModalReject
}) => {
  const handleSubmit = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return publicAccessApi.shareByEmail(values).then((data) => {
      handleModalResolve(data);
    }).catch(({ data: { errors } = {} }) => {
      if (errors) {
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Share By Email
            </ModalHeader>

            <ModalBody>
              <EmailsSelect
                isAsync={!usersOptions?.length}
                isCreatable={!usersOptions?.length}
                options={usersOptions?.length ? usersOptions : null}
                name="access_email"
                label="Email"
                placeholder="Enter email..."
                margin="dense"
              />

              <KeyboardDateTimePicker
                disablePast
                label="Expire date"
                name="expired_at"
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Share
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
