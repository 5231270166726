import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_APPOINTMENT_BOOKS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_APPOINTMENT_BOOKS_SUCCESS]: (
    { filter, appointmentBooks, ...state },
    { data: newArrivalStatuses, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      appointmentBooks: (page > 1 ?
        appointmentBooks.filter(({ id }) => {
          return !newArrivalStatuses.find((loadedFile) => id === loadedFile.id);
        }).concat(newArrivalStatuses)
        :
        newArrivalStatuses
      )
    };
  },

  [types.RESET_APPOINTMENT_BOOKS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.UPDATE_APPOINTMENT_BOOK]: (state, appointmentBook) => {
    return {
      ...state,

      appointmentBooks: state.appointmentBooks.map((item) => {
        return item.id === appointmentBook.id ? { ...item, ...appointmentBook } : item;
      })
    };
  },

  [types.DELETE_APPOINTMENT_BOOK]: (state, appointmentBookID) => {
    const { pagination, filter, appointmentBooks } = state;
    const filteredArrivalStatuses = appointmentBooks.filter(({ id }) => appointmentBookID !== id);

    if (filteredArrivalStatuses.length === appointmentBooks.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((appointmentBooks.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      appointmentBooks: filteredArrivalStatuses
    };
  },

  [types.ADD_BOOKS_TO_SELECTED]: ({ selectedBooks, selectedBooksIDs, ...state }, books) => {
    return {
      ...state,

      selectedBooksIDs: [ ...new Set(selectedBooksIDs.concat(books.map(({ id }) => id))) ],
      selectedBooks: selectedBooks.concat(books.filter(({ id }) => !selectedBooksIDs.includes(id)))
    };
  },

  [types.DELETE_BOOKS_FROM_SELECTED]: ({ selectedBooksIDs, selectedBooks, ...state }, booksIDs) => {
    return {
      ...state,

      selectedBooksIDs: selectedBooksIDs.filter((id) => !booksIDs.includes(id)),
      selectedBooks: selectedBooks.filter(({ id }) => !booksIDs.includes(id))
    };
  }
});
