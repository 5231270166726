import { Yup } from '../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    review_of_orthopedic_symptoms: Yup.object().shape({
      constitutional: Yup.object().shape({
        none: Yup.boolean(),
        weight_gain: Yup.boolean(),
        weight_loss:Yup.boolean(),
        chills: Yup.boolean(),
        fever: Yup.boolean(),
        weakness_fatigue: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      eyes: Yup.object().shape({
        none: Yup.boolean(),
        blurred_vision: Yup.boolean(),
        glasses:Yup.boolean(),
        contacts: Yup.boolean(),
        eye_pain: Yup.boolean(),
        redness: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      ears_nose_throat: Yup.object().shape({
        none: Yup.boolean(),
        nose_bleeds: Yup.boolean(),
        ear_ache_or_infection: Yup.boolean(),
        ringing_in_ear: Yup.boolean(),
        hoarseness: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      cardiovascular: Yup.object().shape({
        none: Yup.boolean(),
        chest_pain: Yup.boolean(),
        swelling_in_legs: Yup.boolean(),
        shortness_in_breath: Yup.boolean(),
        palpitations: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      respiratory: Yup.object().shape({
        none: Yup.boolean(),
        shortness_of_breath: Yup.boolean(),
        wheezing_asthma: Yup.boolean(),
        frequent_cough: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      gastrointestinal: Yup.object().shape({
        none: Yup.boolean(),
        heartburn: Yup.boolean(),
        vomiting: Yup.boolean(),
        nausea: Yup.boolean(),
        abdominal_pain: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      musculoskeletal: Yup.object().shape({
        none: Yup.boolean(),
        stiffness: Yup.boolean(),
        muscle_aches: Yup.boolean(),
        swelling_of_joints_instability: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      skin: Yup.object().shape({
        none: Yup.boolean(),
        rash_itching: Yup.boolean(),
        redness: Yup.boolean(),
        keloid_scars: Yup.boolean(),
        psoriasis: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      neurological: Yup.object().shape({
        none: Yup.boolean(),
        headaches: Yup.boolean(),
        numbness_tingling_loss_of_sensation_i_any_part_of_body: Yup.boolean(),
        dizziness: Yup.boolean(),
        fainting_spells: Yup.boolean(),
        seizures: Yup.boolean(),
        poor_balance: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      psychiatric: Yup.object().shape({
        none: Yup.boolean(),
        depression: Yup.boolean(),
        nervousness: Yup.boolean(),
        anxiety: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      endocrine: Yup.object().shape({
        none: Yup.boolean(),
        excessive_thirst_or_hunger: Yup.boolean(),
        hot_cold_intolerance: Yup.boolean(),
        hot_flashes: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      hematological: Yup.object().shape({
        none: Yup.boolean(),
        easy_bruising: Yup.boolean(),
        easy_bleeding: Yup.boolean(),
        varicose_veins: Yup.boolean(),
        blood_clots: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      })
    })
  })
});
