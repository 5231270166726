import { Box, List as MuiList, Paper, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../ListRow';

const columnsWidths = {
  name: 120,
  count: 150,
  sum: 150
};

export const PayCodeSummary = ({ report = {} }) => {
  return (
    <Box py={3}>
      <Typography gutterBottom variant="h6">Pay Code Summary</Typography>

      <Paper elevation={0}>
        <ListRow header px={2}>
          <ListRowCell flexBasis={columnsWidths.name}>
            PAY CODE
          </ListRowCell>

          <ListRowCell grow justifyContent="flex-end" flexBasis={columnsWidths.sum}>
            TOTAL, $
          </ListRowCell>

          <ListRowCell grow justifyContent="flex-end" flexBasis={columnsWidths.count}>
            HOURS
          </ListRowCell>
        </ListRow>
      </Paper>

      <MuiList disablePadding>
        <ListRow px={2}>
          <ListRowCell flexBasis={columnsWidths.name}>
            OVERTIME
          </ListRowCell>

          <ListRowCell grow justifyContent="flex-end" flexBasis={columnsWidths.sum}>
            <Typography>
              $ {report?.report_data?.user_rate?.overtime || '0'}
            </Typography>
          </ListRowCell>

          <ListRowCell grow justifyContent="flex-end" flexBasis={columnsWidths.count}>
            {report?.report_data?.overtime}
          </ListRowCell>
        </ListRow>

        <ListRow px={2}>
          <ListRowCell flexBasis={columnsWidths.name}>
            DOUBLE TIME
          </ListRowCell>

          <ListRowCell grow justifyContent="flex-end" flexBasis={columnsWidths.sum}>
            <Typography>
              $ {report?.report_data?.user_rate?.double_time || '0'}
            </Typography>
          </ListRowCell>

          <ListRowCell grow justifyContent="flex-end" flexBasis={columnsWidths.count}>
            {report?.report_data?.double_time}
          </ListRowCell>
        </ListRow>

        <ListRow px={2}>
          <ListRowCell flexBasis={columnsWidths.name}>
            REGULAR
          </ListRowCell>

          <ListRowCell grow justifyContent="flex-end" flexBasis={columnsWidths.sum}>
            <Typography>
              $ {report?.report_data?.user_rate?.regular || '0'}
            </Typography>
          </ListRowCell>

          <ListRowCell grow justifyContent="flex-end" flexBasis={columnsWidths.count}>
            {report?.report_data?.regular}
          </ListRowCell>
        </ListRow>

        <ListRow px={2}>
          <ListRowCell flexBasis={columnsWidths.name}>
            GROSS TOTALS:
          </ListRowCell>

          <ListRowCell grow justifyContent="flex-end" flexBasis={columnsWidths.sum}>
            <Typography variant="h5">
              $ {report?.report_data?.user_rate?.total || '0'}
            </Typography>
          </ListRowCell>

          <ListRowCell grow justifyContent="flex-end" flexBasis={columnsWidths.count}>
            {report?.report_data?.gross_totals}
          </ListRowCell>
        </ListRow>
      </MuiList>
    </Box>
  );
};
