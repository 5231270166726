import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Label = ({ name, labelClass, color, ...props }) => {
  const classes = useStyles({ color });

  return (
    <Typography
      component="span"
      className={cn(classes.label, classes[color], classes[labelClass])}
      {...props}
    >
      {name}
    </Typography>
  );
};
