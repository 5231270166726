export const styles = ({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: spacing(2)
  },

  video:  {
    display: 'flex',
    background: 'black',
    position: 'relative',
    border: '1px solid lightgrey',
    height: 280,
    width: 280,
    margin: spacing()
  },

  videoLocal: {
    display: 'flex',
    background: 'black',
    position: 'absolute',
    border: '1px solid lightgrey',
    height: 52,
    width: 52,
    bottom: spacing(),
    left: spacing(),
    transform: 'scaleX(-1)'
  }
});
