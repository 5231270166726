import { useEffect, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { KeyboardCapslock } from '@material-ui/icons';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { IconButton } from '../../IconButton';
import { Tooltip } from '../../Tooltip';
import { TextField } from '../TextField';

export const PasswordField = ({ defaultColor = false, visibilityButtonProps = {}, ...props }) => {
  const [ passwordIsVisible, setPasswordIsVisible ] = useState(false);
  const [ isCapsLockModifier, setIsCapsLockModifier ] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordIsVisible((isVisible) => !isVisible);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      setIsCapsLockModifier(event?.getModifierState?.('CapsLock'));
    };

    document.addEventListener('keypress', handleKeyDown);

    return () => {
      document.removeEventListener('keypress', handleKeyDown);
    };
  }, []);

  return (
    <TextField
      type={passwordIsVisible ? 'text' : 'password'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" color="primary">
            {isCapsLockModifier &&
              <Tooltip title="Caps lock is ON">
                <KeyboardCapslock />
              </Tooltip>
            }
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              title={passwordIsVisible ? 'Hide password' : 'Show password'}
              onClick={togglePasswordVisibility}

              {...visibilityButtonProps}
            >
              {passwordIsVisible ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}

      { ...props }
    />
  );
};
