import moment from 'moment';
import { useEffect, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  AddInsuranceInfoModal,
  EditInsuranceInfoModal
} from '../../../../app/Dashboard/ProfilePage/insurance';
import {
  insuranceFormTypesMap
} from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/insuranceFormTypesMap';
import { getUserFullName } from '../../../../helpers/users';
import { CompanyListModal } from '../../../CompanyListModal';
import { useModal } from '../../../ModalsProvider';
import { Fab } from '../../../Fab';
import { formStatusesMap, formTypesMap } from '../formTypesMap';

export const InsuranceButton = ({ user }) => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();
  const [ company, setCompany ] = useState(null);
  const [ insurance, setInsurance ] = useState(null);
  const modalParams = {
    type_insurance: values?.form_type !== formTypesMap.private
      ? insuranceFormTypesMap?.[values?.form_type]
      : null,
    status_type: values?.form_type === formTypesMap.private
      ? formStatusesMap.primary
      : formStatusesMap.law_office,
    medicalFormID: values?.is_editable ? values.id : null
  };
  const insuranceHolder = values?.insuranceInfo?.primary_insurance_holder_first_name || '' +
    values?.insuranceInfo?.primary_insurance_holder_last_name || '';
  const userName = values.first_name + values.last_name;
  const isInsuranceAddressSame = insuranceHolder === userName || !values.relationship_to_patient;

  const addInsuranceValues = (data = null) => {
    setInsurance(data);
    setFieldValue('attorney_id', data?.attorney || null);
    setFieldValue('primary_insurance_id', data);
    setFieldValue('primary_insuranceId', data?.insuranceId || null);
    setFieldValue('groupID', data?.groupId || null);
    setFieldValue('primary_insurance_holder', getUserFullName(data?.subscriber_info) || null);
    setFieldValue('relationship_to_patient', data?.subscriber_info?.subscriber || null);
    setFieldValue(
      'relationship_dob',
      data?.subscriber_info?.dob ? moment(data?.subscriber_info?.dob).unix() : null
    );
  };

  const handleDelete = async (id, callback) => {
    await addInsuranceValues();
    await callback();
  };

  const handleCreateInsurance = (data) => {
    openModal(AddInsuranceInfoModal, {
      payload: {
        user,
        disableScan: true,
        insuranceInfo: {
          ...values?.insuranceInfo,

          first_name: values?.insuranceInfo?.primary_insurance_holder_first_name || values?.first_name,
          last_name: values?.insuranceInfo?.primary_insurance_holder_last_name || values?.last_name,
          dob: values?.insuranceInfo?.primary_insurance_holder_dob || values.birthday || null,
          address: isInsuranceAddressSame ? values.home || {} : {},
          insurance_company_id: company || data,
          user_address: values.home || {}
        },

        ...modalParams
      },
      onModalResolved: (data) => {
        addInsuranceValues(data);
      }
    });
  };

  const handleEditInsurance = () => {
    openModal(EditInsuranceInfoModal, {
      payload: {
        user,
        insurance,
        onDelete: handleDelete
      },
      onModalResolved: (data) => {
        addInsuranceValues(data);
      }
    });
  };

  const openCompaniesModal = () => {
    openModal(CompanyListModal, {
      payload: {
        insuranceInfo: values?.insuranceInfo,
        modalParams
      },
      onModalResolved: (data) => {
        setCompany(data);
        handleCreateInsurance(data);
      }
    });
  };

  useEffect(() => {
    setInsurance(values?.primary_insurance ? {
      ...values?.primary_insurance,
      attorney: values?.primary_insurance?.attorney || values?.attorney
    } : null);
    setCompany(values?.primary_insurance?.insurance_company || null);
  }, []);

  return (!values?.primary_insurance_id || !values?.primary_insurance?.id || !values.is_confirmed) && (
    <Grid item xs={12}>
      <Box mb={2}>
        {!!company?.id && !!insurance?.id ? (
          <Fab
            size="small"
            color="primary"
            variant="extended"
            startIcon={<AddIcon/>}
            onClick={handleEditInsurance}
          >
            Edit Insurance
          </Fab>
        ) : (
          <Fab
            size="small"
            color="primary"
            variant="extended"
            startIcon={<AddIcon/>}
            onClick={openCompaniesModal}
          >
            Add Insurance
          </Fab>
        )}
      </Box>

      <Typography variant="caption" color="error">
        <ErrorMessage name="primary_insurance_id" />
      </Typography>
    </Grid>
  );
};
