import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Grid, makeStyles, Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { Fab } from '../../../../../../components/Fab';
import { useModal } from '../../../../../../components/ModalsProvider';
import { List } from './List';
import { AddUsersModal } from './AddUsersModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AssignedUsers = ({ formFieldName }) => {
  const classes = useStyles();
  const [ , { initialValue }, { setValue } ] = useField(formFieldName);
  const [ users, setUsers ] = useState([]);
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const addUsers = (newUsers) => {
    setUsers(users.concat(newUsers.filter((user) => !users.find(({ id }) => user.id === id))));
  };

  const deleteUser = (user) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setUsers(users.filter(({ id }) => id !== user.id));
      }
    });
  };

  const addUser = () => {
    openModal(AddUsersModal, {
      onModalResolved: (users) => {
        addUsers(users);
      }
    });
  };

  useEffect(() => {
    if (initialValue) {
      setUsers(initialValue);
    }
  }, [ initialValue ]);

  useEffect(() => {
    setValue(users && users.map(({ id }) => id));
  }, [ users ]);

  return (
    <div className={classes.root}>
      <Box mt={4} mb={2}>
        <Grid container spacing={1} alignItems="center" component={Box} pb={1}>
          <Grid item>
            <Typography variant="h3">Assigned users</Typography>
          </Grid>

          <Grid item>
            <Fab
              color="primary"
              variant="extended"
              size={isMobile ? 'small' : 'medium'}
              startIcon={<AddIcon />}
              onClick={addUser}
            >
              Add user
            </Fab>
          </Grid>
        </Grid>

        <Typography variant="body1" color="textSecondary">
          Selected users have access to this record book
        </Typography>
      </Box>

      {!!(users && users.length) &&
        <List users={users} onUserDelete={deleteUser} />
      }
    </div>
  );
};
