import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { transformToOption } from '../../../../../../../helpers/fetchDataForAsyncSelect';
import { saveFilter } from '../../../../../../../store/lastFilters';
import { participantsUserRoles } from '../../../../../../../dataMaps';
import {
  Select,
  OfficesLocationSelect,
  CompaniesAutocomplete
} from '../../../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../../../components/FiltersBar';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';

const initialValues = {
  company: [],
  office: [],
  position: [],
  case_role: []
};

const transformRelationsForFilterToOptions = (filter) => {
  return {
    ...filter,

    position: filter?.position?.map(transformToOption),
    case_role: filter?.case_role?.map(transformToOption)
  };
};

export const FiltersBar = ({ filterKey, caseUsers = [], handleSetFilter }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const positions = useMemo(() => {
    const positions = caseUsers.reduce((positions, { user }) => {
      const position = user?.work?.position;

      return position ? positions.concat(position) : positions;
    }, []);

    return [ ...new Set(positions) ];
  }, [ caseUsers ]);

  const handleMultiSelectChange = (name) => (options) => {
    handleSetFilter((state) => ({ ...state, [name]: options.map(({ value }) => value) }));
    setRelationsForFilter((state) => ({ ...state, [name]: options?.map((option) => option?.data || option) }));
  };

  const handleOfficesChange = (offices) => {
    handleSetFilter((state) => ({ ...state, office: offices.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, office: offices }));
  };

  const handleCompaniesChange = (companies) => {
    handleSetFilter((state) => ({ ...state, company: companies.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, company: companies }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(transformRelationsForFilterToOptions(filter));
    handleSetFilter(filter);
  };

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);


  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableTopPaddings
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      onReset={() => applySavedFilter(initialValues)}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.company,
          label: filterFieldsLabels[filterFieldsMap.company],
          field: <Box minWidth={210}>
            <CompaniesAutocomplete
              multiple
              name="company"
              label="Company"
              margin="dense"
              onChange={handleCompaniesChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.office,
          label: filterFieldsLabels[filterFieldsMap.office],
          field: <OfficesLocationSelect
            multiple
            name="office"
            label="Office"
            onChange={handleOfficesChange}
            TextFieldProps={{ margin: 'dense' }}
          />
        },
        {
          fieldKey: filterFieldsMap.position,
          label: filterFieldsLabels[filterFieldsMap.position],
          field: <Select
            isMulti
            name="position"
            label="Position"
            formattedValue
            options={positions.map((value) => ({ value, label: value }))}
            onChange={handleMultiSelectChange('position')}
            TextFieldProps={{ margin: 'dense' }}
          />
        },
        {
          fieldKey: filterFieldsMap.caseRole,
          label: filterFieldsLabels[filterFieldsMap.caseRole],
          field: <Select
            isMulti
            isClearable
            formattedValue
            name="case_role"
            label="Select user role"
            options={participantsUserRoles}
            onChange={handleMultiSelectChange('case_role')}
            TextFieldProps={{ margin: 'dense' }}
          />
        }
      ]}
    />
  );
};
