import { CodesContextProvider } from './CodesContext';
import { MainContent } from './MainContent';

export const CodesNDC = ({ filterKey }) => {
  return (
    <CodesContextProvider>
      <MainContent filterKey={filterKey} />
    </CodesContextProvider>
  );
};
