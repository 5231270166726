import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import * as waitingListApi from '../../../../../../../api/appointments';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { getAgeByDOB } from '../../../../../../../helpers/users';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { IconButton } from '../../../../../../../components/IconButton';
import {
  AppointmentEditModal,
  AppointmentViewModal
} from '../../../../../../../components/appointments';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { CardItem, CardHeader } from '../../../../../../../components/Cards';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { OfficeLink } from '../../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../../components/UserLink';
import { SvgIcon } from '../../../../../../../components/SvgIcon';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { hasRole } from '../../../../../../../utils/hasRole';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import { Body } from './Body';

export const Card = ({ isFollowUp = false, isUpdatable = false, appointment = {}, onUpdateAppointment = () => {} }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const [ isDeletable, setIsDeletable ] = useState(false);
  const medicalFormItemContext = useContext(MedicalFormItemContext);
  const hasRoleForEdit = hasRole(rolesMap.doctor, rolesMap.medic, rolesMap.admin, rolesMap.supervisor);

  const handleUpdateAppt = (data) => {
    onUpdateAppointment(data);
  };

  const openAppointmentEditModal = () => {
    openModal(AppointmentEditModal, {
      payload: {
        insurance: !!medicalFormItemContext?.form ? {
          insurance_details: medicalFormItemContext?.form?.primary_insurance ? {
            ...medicalFormItemContext?.form?.primary_insurance,
            company: medicalFormItemContext?.form?.primary_insurance?.insurance_company || null
          } : null,
          patient_insurance_pcp: medicalFormItemContext?.form?.primary_insurance?.pcp,
          patient_insurance_spc: medicalFormItemContext?.form?.primary_insurance?.spc
        } : null,
        form_type: medicalFormItemContext?.form?.form_type || null,
        medical_forms_id: medicalFormItemContext?.form?.id
          ? [ medicalFormItemContext?.form?.id ] : null,
        appointmentID: appointment.id
      },
      onModalResolved: (appointment) => {
        handleUpdateAppt(appointment);
      }
    });
  };

  const handleDeleteAppt = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        waitingListApi.deleteWaitingListItem(appointment.id).then(() => {
          onUpdateAppointment({});
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Item is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const openPreview = () => {
    openModal(AppointmentViewModal, {
      payload: {
        insurance_info: !!medicalFormItemContext?.form ? {
          insurance_details: medicalFormItemContext?.form?.primary_insurance ? {
            ...medicalFormItemContext?.form?.primary_insurance,
            company: medicalFormItemContext?.form?.primary_insurance?.insurance_company || null
          } : null,
          patient_insurance_pcp: medicalFormItemContext?.form?.primary_insurance?.pcp,
          patient_insurance_spc: medicalFormItemContext?.form?.primary_insurance?.spc
        } : null,
        form_type: medicalFormItemContext?.form?.form_type || null,
        medical_forms_id: medicalFormItemContext?.form?.id
          ? [ medicalFormItemContext?.form?.id ] : null,
        disableApptActions: true,
        onAppointmentUpdate: handleUpdateAppt,
        onAppointmentDelete: handleDeleteAppt,
        appointmentID: appointment.id
      }
    });
  };

  useEffect(() => {
    const start = moment(moment.unix(appointment.appointment_at));
    const end = moment().startOf('day');
    const coundOfDays = start.diff(end, 'days');

    setIsDeletable(!appointment?.appointment_confirmed_by_office && coundOfDays < 15);
  }, []);

  return (
    <CardItem py={1} px={2} onClick={stopPropagation(openPreview)}>
      <CardHeader
        disableCheckBox
        disableCardMenu
        childrenWidth={11}
        icon={
          <>
            {isUpdatable && hasRoleForEdit && (
              <Box clone mr={1}>
                <Tooltip isExistTooltip title="Edit an appointment">
                  <IconButton
                    color="info"
                    size="small"
                    onClick={stopPropagation(openAppointmentEditModal)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}

            {!isFollowUp && isAuthenticated && !hasRole(rolesMap.patient) && !!appointment?.id && (
              <Tooltip
                isExistTooltip={!!appointment?.appointment_confirmed_by_office}
                title="You cannot delete an appointment, it's already confirmed"
              >
                <IconButton
                  disabled={isDeletable}
                  color="error"
                  size="small"
                  onClick={stopPropagation(handleDeleteAppt)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <UserLink
              size="lg"
              variant="h5"
              user={appointment?.patient}
            >
              <Typography color="textSecondary">
                Age {getAgeByDOB(moment.unix(appointment?.patient?.birthday))} y.o.
              </Typography>
            </UserLink>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="h4">
              <CurrencyFormat value={appointment?.billing_info?.total_balance} />
            </Typography>
            <Typography variant="caption" color="textSecondary">Balance</Typography>
          </Grid>
        </Grid>
      </CardHeader>

      <Body appointment={appointment} />

      <Box py={1}>
        <UserLink
          size="lg"
          variant="h5"
          user={appointment?.appointment_book?.provider}
        >
          <Box display="flex" flexDirection="column">
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <OfficeLink office={appointment.office} variant="caption" />
              </Grid>

              {!!appointment.appointment_confirmed_by_office &&
                <Grid item>
                  <Tooltip isExistTooltip title="Confirmed by office">
                    <Box bgcolor="success.contrastText" borderRadius="50%">
                      <SvgIcon color="success">
                        <CheckCircleIcon />
                      </SvgIcon>
                    </Box>
                  </Tooltip>
                </Grid>
              }

              <Box display="flex" overflow="hidden">
                <Box clone mr={0.5}>
                  <LocationOnIcon fontSize="small" />
                </Box>

                <AddressLink noWrap variant="caption" address={appointment.office?.full_address}>
                  {appointment.office?.full_address || '-'}
                </AddressLink>
              </Box>
            </Grid>
          </Box>
        </UserLink>
      </Box>
    </CardItem>
  );
};
