import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles, Typography, Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { ActionButtons } from '../../../../../../../components/medical/forms';
import * as formsApi from '../../../../../../../api/profile';
import { Divider } from '../../../../../../../components/Divider';
import { Loader } from '../../../../../../../components/Loader';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import * as types from '../../MedicalFormsProvider/types';
import { CheckboxesContent } from './CheckboxesContent';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ReviewSystems = () => {
  const classes = useStyles();
  const params = useParams();
  const theme = useTheme();
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetched, form, handleNext, handleBack } = useContext(MedicalFormItemContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updateReviewOfSystems(values).then(({ data }) => {
      dispatchRedux({ type: types.UPDATE_FORM, payload: data });
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleNext(form.id);
    }).catch(({ errors }) => {
      if (errors) {
        errors && setErrors(errors);
        enqueueSnackbar('Form not updated', { variant: 'error' });
      }
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            form_id: form?.id,
            forms: {
              review_of_orthopedic_symptoms: form?.forms?.review_of_orthopedic_symptoms || {}
            }
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <Grid
                container
                spacing={isSmallScreen ? 2 : 3}
                alignItems="flex-start"
                component={Box}
                pb={3}
              >
                <Grid item xs={12}>
                  <Typography variant={isSmallScreen ? 'h4' : 'h6'} color="secondary">
                    Review of Symptoms
                  </Typography>
                </Grid>

                <CheckboxesContent />
              </Grid>

              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
