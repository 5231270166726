export const filterFieldsMap = {
  dob: 'dob',
  ssn: 'ssn',
  appointmentFrom: 'appointment_from',
  appointmentTo: 'appointment_to',
  providerId: 'provider_id',
  insuranceType: 'insurance_type',
  expirationDay: 'expiration_day'
};

export const filterFieldsLabels = {
  [filterFieldsMap.dob]: 'DOB',
  [filterFieldsMap.ssn]: 'SSN',
  [filterFieldsMap.appointmentFrom]: 'Appt. To',
  [filterFieldsMap.appointmentTo]: 'Appt. From',
  [filterFieldsMap.providerId]: 'Provider',
  [filterFieldsMap.insuranceType]: 'Insurance Type',
  [filterFieldsMap.expirationDay]: 'Expiration Date'
};
