import { api } from '../../api';

export const fetchNDCCodes = (config) => {
  return api.get('/procedure-codes/ndc-code', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createNDCCode = (data) => {
  return api.post('/procedure-codes/ndc-code', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateNDCCode = ({ id, ...data }) => {
  return api.put(`/procedure-codes/ndc-code/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteNDCCode = (id) => {
  return api.delete(`/procedure-codes/ndc-code/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massActiveNDCCodes = (IDs) => {
  return api.post('/procedure-codes/ndc-code/active', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massInactiveNDCCodes = (IDs) => {
  return api.post('/procedure-codes/ndc-code/inactive', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteNDCCodes = (IDs) => {
  return api.delete('procedure-codes/ndc-code', { params: { id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
