export const styles = ({ spacing, palette: { grey, divider }, transitions }) => ({
  uploadTrigger: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 260,
    padding: spacing(2),
    background: grey[50],
    border: `1px dashed ${divider}`,
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      background: grey[100]
    }
  }
});
