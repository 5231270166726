import { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import filesize from 'filesize';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  IconButton,
  List as MuiList,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { getAggregateTypeFromMIME } from '../../../../../helpers/files';
import { setFilesLastGlobalAction, setPaymentsLastGlobalAction } from '../../../../../store/globalActions';
import { paymentMethodsMap } from '../../../../../dataMaps/paymentMethodsMap';
import * as paymentsApi from '../../../../../api/billing';
import * as filesApi from '../../../../../api/files';
import { billingParticipantsTypesOptions } from '../../../../../components/billing';
import { TextField } from '../../../../../components/FormField';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { FileDropZone } from '../../../../../components/FileDropZone';
import { useModal } from '../../../../../components/ModalsProvider';
import { Loader } from '../../../../../components/Loader';
import { FileTypeIcon, FileValidationConflictModal } from '../../../files-common';
import * as filesTypes from '../../FilesContext/types';
import { InvoicesContextProvider } from '../../Invoices/InvoicesContext';
import { FiltersBar } from '../../Invoices/InvoicesPage/InvoicesContent/FiltersBar';
import { List } from '../../Invoices/InvoicesPage/InvoicesContent/List';
import { SelectedInvoices } from '../CreatePayment/MainCreateContent/SelectedInvoices';
import { FilterHeader } from '../CreatePayment/MainCreateContent/FilterHeader';
import { PaymentForm } from '../CreatePayment/PaymentForm';
import { validationSchema } from '../CreatePayment';
import * as types from '../../../../../components/billing/payments/PaymentsContext/types';
import { Header } from '../CreatePayment/Header';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 72;
export const columnsWidths = {
  paid_date: 200,
  due_on: 100,
  number: 300,
  status: 100,
  total: 150,
  amount: 150,
  balance: 150
};

export const EditPayment = () => {
  const params = useParams();
  const history = useHistory();
  const rootRef = useRef();
  const classes = useStyles();
  const { openModal } = useModal();
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [ payment, setPayment ] = useState({});
  const [ invoice, setInvoice ] = useState({});
  const [ selectedFiles, setSelectedFiles ] = useState([]);
  const [ selectedInvoices, setSelectedInvoices ] = useState([]);
  const [ isFetched, setIsFetched ] = useState(false);
  const { width } = useResizeObserver({ ref: rootRef });

  const initialState = {
    filter: {
      is_confirmed: 1,
      is_paid: 0,
      model_to_type: payment?.payer?.participant_type || null,
      model_to_id: payment?.payer?.participant?.id || null
    }
  };

  const validateFile = (file) => {
    file.original_filename = file.original_filename || file.name;

    if (selectedFiles.find(({ original_filename }) => original_filename === file.original_filename)) {
      openModal(FileValidationConflictModal, {
        payload: { file },
        onModalResolved: (file) => {
          validateFile(file);
        }
      });
    } else {
      filesApi.validateFile(file).then(() => {
        file.aggregate_type = getAggregateTypeFromMIME(file.type);

        setSelectedFiles((payment) => [ file ].concat(payment));
      }).catch(({ status }) => {
        if (status === 409) {
          openModal(FileValidationConflictModal, {
            payload: { file },
            onModalResolved: (file) => {
              validateFile(file);
            }
          });
        } else {
          enqueueSnackbar(`File "${file.original_filename}" is invalid`, {
            variant: 'error'
          });
        }
      });
    }
  };

  const handleDropAccepted = (files) => {
    files.forEach((file) => {
      validateFile(file);
    });
  };

  const uploadFiles = ({
    owner_id,
    owner_type,
    selectedFiles,
    setSubmitting,
    setErrors
  }  = {}) => {
    selectedFiles.forEach((file) => {
      const formData = new FormData();

      formData.append('file', file, file.original_filename);

      file.requestData = formData;

      paymentsApi.uploadFile(formData, { params: { owner_id, owner_type } }).then((data) => {
        dispatchRedux(setFilesLastGlobalAction({ type: filesTypes.FILE_UPLOAD_SUCCESS, payload: data }));
        enqueueSnackbar('Files successfully uploaded to payment', { variant: 'success' });
      }).catch(({ status, data: { errors } = {} }) => {
        setSubmitting(false);

        if (status === 422 && errors) {
          setErrors(errors);
        }
      });
    });
  };

  const updatePayment = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (selectedInvoices.length === 0) {
      enqueueSnackbar('You should choose invoice', { variant: 'error' });
      setSubmitting(false);
    } else {
      if (selectedInvoices.length > 1) {
        enqueueSnackbar('You can choose only one invoice', { variant: 'warning' });
        setSubmitting(false);
      } else {

        return paymentsApi.updatePayment({
          ...values,

          id: payment.id,
          invoice_id: selectedInvoices[0].id,
          amount: +values.amount,
          method: values?.method?.value || values?.method,
          payer: {
            id: values?.payer?.id?.value || values?.payer?.id,
            type: values?.payer?.type?.value || values?.payer?.type
          }
        }).then((data) => {
          if (selectedFiles.length > 0) {
            const fileList = selectedFiles.filter((item) => !item.id);

            uploadFiles({
              owner_id: data.id,
              owner_type: 'payment',
              selectedFiles: fileList,
              setSubmitting,
              setErrors
            });
          }

          dispatchRedux(setPaymentsLastGlobalAction({ type: types.UPDATE_PAYMENT_IN_LIST, payload: data }));
          enqueueSnackbar('Payment successfully updated', { variant: 'success' });
          history.goBack();
        }).catch((errors) => {
          if (errors) {
            setErrors(errors);
            enqueueSnackbar('Payment not updated', { variant: 'error' });
          }
        });
      }
    }
  };

  useEffect(() => {
    setIsFetched(false);

    paymentsApi.fetchPayment(+params.id).then((data) => {
      setPayment(data);
      setSelectedFiles(data.files);

      if (data?.invoice) {
        setInvoice(data.invoice || {});
        setSelectedInvoices(data?.invoice ? [ data.invoice ] : []);
      } else {
        setInvoice(data?.causer_invoice || {});
        setSelectedInvoices(data?.causer_invoice ? [ data.causer_invoice ] : []);
      }

      setIsFetched(true);
    });
  }, [ params.id ]);

  return (
    <Box ref={rootRef} display="flex" height="100%" p={isMobile ? 0 : 2}>
      <Loader loading={!isFetched} p={3} render={
        () => (
          <Formik
            initialValues={{
              ...payment,

              payer: {
                type: billingParticipantsTypesOptions?.find(({ value }) =>
                  value === payment?.payer?.participant_type
                ),
                id: payment?.payer?.participant,
                info: payment?.payer?.participant || payment?.payer?.info
              },
              amount: payment.amount,
              method: paymentMethodsMap?.find(({ value }) => value === payment.method),
              due_date: invoice.due_date,
              date: payment.date || moment().unix()
            }}
            validationSchema={validationSchema}
            onSubmit={updatePayment}
          >
            {({ values, handleSubmit, isSubmitting }) => (
              <Page
                noValidate
                square={false}
                component="form"
                className={classes.root}
                onSubmit={handleSubmit}
              >
                <PageHeader>
                  <IconButton
                    color="primary"
                    edge="start"
                    onClick={history.goBack}
                  >
                    <KeyboardBackspaceOutlinedIcon />
                  </IconButton>

                  <PageHeaderTitle>
                    Edit Payment {payment?.number || ''}
                  </PageHeaderTitle>

                  <PageHeaderActions>
                    <Button
                      color="primary"
                      size={isMobile ? 'small' : 'medium'}
                      className={classes.actionsBackButton}
                      onClick={history.goBack}
                    >
                      Cancel
                    </Button>

                    <Loader surface loading={isSubmitting} render={
                      () => (
                        <Button
                          autoFocus
                          disabled={isSubmitting}
                          size={isMobile ? 'small' : 'medium'}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      )}
                    />
                  </PageHeaderActions>
                </PageHeader>

                <PageBody disablePaddings>
                  <FilterHeader parentPage setInvoice={setInvoice} />
                  <Header invoice={invoice} amount={values.amount} />

                  <PaymentForm
                    amount={values.amount}
                    width={width}
                    invoice={invoice}
                    selectedInvoices={selectedInvoices}
                  />

                  <div className={classes.footer}>
                    <div className={classes.footerInfo}>
                      <MuiList className={classes.list}>
                        {!selectedFiles.length &&
                          <Typography paragraph align="center">Files not found</Typography>
                        }

                        {selectedFiles.map((file, index) => (
                          <Fragment key={index}>
                            <ListItem>
                              <FileTypeIcon
                                file={{
                                  aggregate_type: getAggregateTypeFromMIME(file.type),

                                  ...file
                                }}
                              />

                              <ListItemText
                                primary={file.original_filename || file.name}
                                primaryTypographyProps={{ noWrap: true }}
                              />

                              <ListItemText primary={filesize(file.size)} className={classes.filesListItem} />
                            </ListItem>
                          </Fragment>
                        ))}
                      </MuiList>

                      <div className={classes.footerContent}>
                        <FileDropZone
                          multiple
                          onDropAccepted={handleDropAccepted}
                        />
                      </div>
                    </div>

                    <div className={classes.noteField}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        name="note"
                        placeholder="Add notes"
                        variant="filled"
                        label={
                          <div className={classes.noteIcon}>
                            <NotesIcon fontSize="small" /> Note
                          </div>
                        }
                      />
                    </div>
                  </div>

                  {selectedInvoices.length > 0 &&
                    <div className={classes.invoiceContent}>
                      <Typography gutterBottom variant="h6">
                        {!!payment?.invoice ? 'Paid invoice' : 'Selected invoice'}
                      </Typography>

                      <SelectedInvoices width={width} selectedInvoices={selectedInvoices} />
                    </div>
                  }

                  <div className={classes.invoiceContent}>
                    <Typography gutterBottom variant="h6">
                      Choose invoice
                    </Typography>

                    <InvoicesContextProvider initialState={initialState}>
                      <FiltersBar paymentForm setSelectedInvoices={setSelectedInvoices} />

                      <List
                        paymentForm
                        parentPage="edit"
                        payment={payment}
                        isEditable={!!payment?.invoice}
                        modelID={values?.payer?.id?.id || values?.payer?.id || null}
                        modelType={values?.payer?.type?.value || values?.payer?.type || null}
                        selectedInvoices={selectedInvoices}
                        setSelectedInvoices={setSelectedInvoices}
                      />
                    </InvoicesContextProvider>
                  </div>
                </PageBody>
              </Page>
            )}
          </Formik>
        )}
      />
    </Box>
  );
};
