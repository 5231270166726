export const styles = ({
  shape: { borderRadius },
  palette: { grey, ...palette },
  typography: { pxToRem },
  breakpoints,
  spacing
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& + .react-resizable-handle': {
      display: 'none'
    },

    [breakpoints.down('lg')]: {
      marginBottom: spacing(0.5)
    }
  },

  defaultVariant: {
    borderRadius,

    '& $toolbar': {
      borderTopRightRadius: borderRadius,
      borderTopLeftRadius: borderRadius
    },

    '& $divider': {
      position: 'absolute',
      left: spacing(2),
      right: spacing(2),
      bottom: 0
    },

    '& $header': {
      backgroundColor: grey[600],
      borderTopRightRadius: borderRadius,
      borderTopLeftRadius: borderRadius
    }
  },

  outlinedVariant: {
    borderWidth: 4
  },

  resizable: {
    '& + .react-resizable-handle': {
      display: 'block'
    }
  },

  fullHeight: {
    height: '100%'
  },

  autoHeight: {
    [breakpoints.up(breakpoints.values.md)]: {
      maxHeight: '100%',
      overflow: 'hidden'
    }
  },

  toolbar: {
    color: ({ color }) => palette[color]?.main || palette.common.white,
    border: 0,
    overflow: 'hidden',

    '&[rgl-handle="true"]': {
      cursor: 'grab'
    }
  },

  divider: {
    position: 'absolute',
    left: spacing(2),
    right: spacing(2),
    bottom: 0
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(0.75, 2),

    [breakpoints.down('md')]: {
      justifyContent: 'flex-start'
    }
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    marginRight: spacing(),
    fontSize: pxToRem(16),
    minWidth: ({ minWidth = 'inherit' }) => minWidth
  },

  title__text: {
    lineHeight: 1.15
  },

  title__icon: {
    marginLeft: spacing(),

    '& > * + *': {
      marginLeft: spacing()
    }
  },

  rightActions: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },

  actionsButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },

  collapseContainer: {
    height: '100%'
  },

  collapseWrapper: {
    height: '100%'
  },

  content: {
    height: '100%',
    padding: ({ disableContentPaddings }) => disableContentPaddings ? null : spacing(2)
  }
});
