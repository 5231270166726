import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, FormControl, FormControlLabel, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as workSessionsApi from '../../../api/work-clocks';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { workerRoles } from '../../../dataMaps';
import { KeyboardDateTimePicker, Switch } from '../../FormField';
import { Loader } from '../../Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../Modal';
import { UsersSelect } from '../../users';
import { validationSchema } from './validationSchema';

export const WorkSessionCreateModal = ({
  DialogProps,
  payload: {
    initialValues = {}
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(({ profile }) => profile.user);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleSubmit = (data, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const values = { ...data };

    if (!values.lunch) {
      delete values.lunch_started_at;
      delete values.lunch_end_at;
    }

    delete values.lunch;

    return workSessionsApi.createWorkSession(values).then((track) => {
      enqueueSnackbar('Work session successfully created', { variant: 'success' });
      handleModalResolve(track);
    }).catch(() => {
      enqueueSnackbar('Work session not created', { variant: 'error' });
    });
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <Formik
        validateOnMount
        initialValues={{
          user_id: null,
          started_at: null,
          finished_at: null,
          lunch: null,
          lunch_started_at: null,
          lunch_end_at: null,

          ...initialValues
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, handleSubmit }) => (
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Create Work Session
            </ModalHeader>

            <ModalBody>
              {!initialValues.user_id && (
                <UsersSelect
                  name="user_id"
                  label="User"
                  params={{
                    company_id: hasRole(rolesMap.admin) ? null : currentUser.work?.company_id,
                    roles: workerRoles
                  }}
                />
              )}

              <KeyboardDateTimePicker
                required
                name="started_at"
                label="Started date"
                margin="dense"
              />

              <FormControl margin="dense">
                <FormControlLabel
                  label="With lunch"
                  control={<Switch name="lunch" color="primary" />}
                />
              </FormControl>

              {!!values.lunch &&
                <>
                  <KeyboardDateTimePicker
                    required
                    name="lunch_started_at"
                    label="Lunch started date"
                    margin="dense"
                  />

                  <KeyboardDateTimePicker
                    required
                    disableFuture
                    name="lunch_end_at"
                    label="Lunch finished date"
                    margin="dense"
                  />
                </>
              }

              <KeyboardDateTimePicker
                disableFuture
                name="finished_at"
                label="Finished date"
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
