import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isEqual, merge } from 'lodash';
import { api } from '../../../../../../api';
import * as incomesApi from '../../../../../../api/billing';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { initialState as initialStateConfig } from './initialState';
import { IncomesFilterContext } from './IncomesFilterContext';
import { reducer } from './reducer';
import * as types from './types';

export const IncomesContext = createContext();

export const IncomesContextProvider = ({ children, initialState = {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const incomesLastGlobalAction = useSelector(({ globalActions }) => globalActions.incomesLastGlobalAction);
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialStateConfig, initialState));
  const {
    incomes,
    pagination,
    isFetching,
    isFetched,
    filter
  } = state;
  const { filter: commonFilter } = useContext(IncomesFilterContext);
  const cancelFetch = useRef(() => {});

  const fetchIncomes = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.INCOMES_FETCH_REQUEST });

    incomesApi.fetchIncomes({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.INCOMES_FETCH_SUCCESS, payload: data });
    });
  };

  const createIncome = ({ incomesData, setSubmitting, setErrors, handleModalResolve } = {}) => {
    incomesApi.createIncome(incomesData).then((data) => {
      dispatch({ type: types.ADD_INCOMES, payload: data });
      enqueueSnackbar('Income successfully created', { variant: 'success' });
      handleModalResolve();
    }).catch(({ errors, message }) => {
      errors && setErrors(errors);
      enqueueSnackbar(message, { variant: 'error' });
      setSubmitting(false);
    });
  };

  const updateIncome = ({ incomeData, setSubmitting, setErrors, handleModalResolve } = {}) => {
    incomesApi.updateIncome(incomeData).then((data) => {
      dispatch({ type: types.UPDATE_INCOMES_IN_LIST, payload: data });
      enqueueSnackbar('Income successfully updated', { variant: 'success' });
      handleModalResolve();
    }).catch(({ message, errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar(message, { variant: 'error' });
      setSubmitting(false);
    });
  };

  const deleteIncome = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        incomesApi.deleteIncome(id).then(() => {
          dispatch({ type: types.DELETE_INCOMES, payload: id });
          enqueueSnackbar('Income successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Income is not deleted!', {
            variant: 'error'
          });
        });
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchIncomes({ page: filter.page + 1 });
    }
  };

  const resetIncomes = (newFilter) => {
    dispatch({ type: types.RESET_INCOMES, payload: newFilter });

    fetchIncomes({ page: 1, ...newFilter });
  };

  useEffect(() => {
    incomesLastGlobalAction && dispatch(incomesLastGlobalAction);
  }, [ incomesLastGlobalAction ]);

  useEffect(() => {
    if (!isEqual(filter, { ...filter, ...commonFilter })) {
      resetIncomes({ ...filter, ...commonFilter });
    }
  }, [ commonFilter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  const providerValue = {
    incomes,
    isFetching,
    isFetched,
    filter: {
      ...filter,
      ...pagination
    },

    loadNextPage,
    resetIncomes,
    deleteIncome,
    createIncome,
    updateIncome,
    fetchIncomes
  };

  return (
    <IncomesContext.Provider value={providerValue}>
      {children}
    </IncomesContext.Provider>
  );
};
