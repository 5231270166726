import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { createContext, useEffect, useReducer, useRef } from 'react';
import { usePrevious } from '../../../../helpers/hooks';
import { api } from '../../../../api';
import * as rulesApi from '../../../../api/emails-rules';
import { initialState } from '../../../../app/Dashboard/EmailsPage/Rules/RulesEmailsContext/initialState';
import { reducer } from '../../../../app/Dashboard/EmailsPage/Rules/RulesEmailsContext/reducer';
import * as types from '../../../../app/Dashboard/EmailsPage/Rules/RulesEmailsContext/types';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { useModal } from '../../../ModalsProvider';

export const RuleTemplatesContext = createContext();

export const RuleTemplatesProvider = ({ filter: filterProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const { filter, pagination } = state;
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchRules = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_RULES_REQUEST });

    rulesApi.getRuleTemplates({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_RULES_SUCCESS, payload: data });
    });
  };

  const resetRules = (newFilter) => {
    dispatch({ type: types.RESET_RULES, payload: newFilter });

    fetchRules({ page: 1, ...newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchRules({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.SET_FILTER, payload: newFilter });
  };

  const deleteRule = (ruleID) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        rulesApi.deleteRuleTemplate(ruleID).then(() => {
          dispatch({ type: types.DELETE_RULE, payload: ruleID });
          enqueueSnackbar('Rule deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Rule not deleted', { variant: 'error' });
        });
      }
    });
  };

  const providerValue = {
    ...state,
    filter,
    pagination,
    meta: {
      ...pagination,
      ...filter
    },

    // functions
    fetchRules,
    resetRules,
    applyFilter,
    loadNextPage,
    deleteRule
  };

  useEffect(() => {
    const newFilter = { ...filterProp, ...filter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, filterProp ]);


  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetRules();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <RuleTemplatesContext.Provider value={providerValue}>
      {children}
    </RuleTemplatesContext.Provider>
  );
};
