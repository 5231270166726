import { AllSchedules } from '../../../GeneralInfo/Widgets/AllSchedules';
import * as cardsTypes from '../Layouts';
import { InsuranceDetails } from './InsuranceDetails';
import { MedicalHistory } from './MedicalHistory';
import { MedicalReport } from './MedicalReport';
import { NextAppointment } from './NextAppointment';
import { PatientPacket } from './PatientPacket';

export const widgetsMap = {
  [cardsTypes.NEXT_APPOINTMENT]: {
    title: 'Next Appointment',
    component: NextAppointment
  },

  [cardsTypes.ALL_SCHEDULES]: {
    title: 'All Schedules',
    component: AllSchedules
  },

  [cardsTypes.INSURANCE_DETAILES]: {
    title: 'Insurance Details',
    component: InsuranceDetails
  },

  [cardsTypes.PATIENT_PACKET]: {
    title: 'Patient Packet',
    component: PatientPacket
  },

  [cardsTypes.MEDICAL_REPORT]: {
    title: 'Medical Report',
    component: MedicalReport
  },

  [cardsTypes.MEDICAL_HISTORY]: {
    title: 'Medical History',
    component: MedicalHistory
  }
};
