import { Typography } from '@material-ui/core';
import * as codesApi from '../../../../api/codes/procedures';
import { Autocomplete } from '../../Autocomplete';

const fetchIcdCodes = ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return codesApi.fetchICDCodes({
    params: { search, page: page + 1 }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => {
  return (
    <Typography>{option?.code} - {option?.description}</Typography>
  );
};

export const IcdCodesSelect = ({ formattedValue = true, ...props }) => {
  return (
    <Autocomplete
      isAsync
      placeholder="Select code..."
      onNeedFetch={fetchIcdCodes}
      renderOption={renderOption}
      getOptionLabel={(option) => option && `${option?.code} - ${option?.description}`}
      getOptionValue={(option) => !formattedValue ? option : option?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}

      {...props}
    />
  );
};
