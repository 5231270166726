import { api } from '../../api';

export const fetchFiles = (config = {}) => {
  return api.get('/deleted-files', config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};


export const addFiles = (IDs) => {
  return api.post('/deleted-files', { files: IDs });
};

export const restoreFiles = (IDs) => {
  return api.put('/deleted-files', { files: IDs });
};

export const deleteFiles = (IDs) => {
  return api.delete('/deleted-files', { params: { files: IDs } });
};

export const emptyTrash = (params) => {
  return api.delete('/deleted-files', { params });
};
