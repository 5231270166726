import { transformToOption } from '../../helpers/selectHelpers';
import { actionOptions, conditionOptions } from './options';

export const getRuleInitialValues = ({ initialRule }) => {
  return {
    ...initialRule,

    config_id: initialRule.config_id,
    name: initialRule.name,
    conditions: initialRule?.conditions?.map((condition) => ({
      key: conditionOptions.find((conditionOption) => conditionOption.value === condition.key),
      ...(condition?.values && {
        values: condition?.key === 'subject' || condition?.key === 'subject_body'
          ? condition?.values?.map(transformToOption)
          : condition?.values
      })
    })).filter((item) => !!item.key) || [ { key: conditionOptions[0], values: [] } ],
    actions: initialRule?.actions?.map((action) => ({
      key: actionOptions.find((actionOption) => actionOption.value === action.key),
      value: action.value || null
    })).filter((item) => !!item.key) || [ { key: actionOptions[0] } ],
    stop_processing: initialRule.stop_processing,
    is_active: initialRule.is_active
  };
};
