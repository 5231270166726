import { Switch } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { filtersKeysMap } from '../../../../../store/lastFilters';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { LayoutContextProvider } from '../../../../../components/LayoutContext';
import { Route } from '../../../../../components/router';
import { PaymentsHistory } from '../PaymentsHistory';
import { Overdraft } from './Overdraft';
import { Received } from './Received';
import { Header } from './Header';

const tabsRoutes = {
  payments: '/billing/payments/received',
  overdraft: '/billing/payments/overdraft',
  paymentsHistory: '/billing/payments/charts'
};

export const PaymentsPage = ({ disableHistory = false, filter = {} }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <CustomScrollbars style={{ flexGrow: 1 }}>
      <Box display="flex" flexDirection="column" height="100%" p={isMobile ? 0 : 2}>
        {!disableHistory && !isTablet && (
          <PaymentsHistory filterKey={filtersKeysMap.billing_payments_history} />
        )}

        <Header tabsRoutes={tabsRoutes} />

        <Switch>
          <LayoutContextProvider>
            <Route exact path={tabsRoutes.payments} render={
              () => (
                <Received filter={filter} />
              )}
            />

            <Route exact path={tabsRoutes.overdraft} render={
              () => (
                <Overdraft filter={filter} />
              )}
            />

            {!disableHistory && isTablet &&
              <Route exact path={tabsRoutes.paymentsHistory} render={
                () => (
                  <PaymentsHistory filterKey={filtersKeysMap.billing_payments_history} />
                )}
              />
            }
          </LayoutContextProvider>
        </Switch>
      </Box>
    </CustomScrollbars>
  );
};
