import { Box, useMediaQuery, useTheme } from '@material-ui/core';
// eslint-disable-next-line max-len
import { Card } from '../../../../../../app/Dashboard/BillingPage/ClaimsPage/ClaimPreview/MainContent/TabsContent/Appointment/Procedures/Card';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidths = {
  cpt: 270,
  pos: 270,
  dx: 110,
  modifiers: 100,
  unit: 100,
  price: 100,
  actions: 48
};

export const Procedures = ({ procedures }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box border={1} borderColor="divider">
      {!isMobile && <TableHeader />}

      {procedures.map((procedure, index) => (
        isMobile
          ? <Card key={procedure.id} procedure={procedure} />
          : <Row key={procedure.id} procedure={procedure} procedureIndex={index} />
      ))}
    </Box>
  );
};
