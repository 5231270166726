export const initialValues = {
  intake_form_completed: false,
  scene_photos: null,
  pd_photos: null,
  um_case: false,
  police_report: null,
  tcr_number: null,
  officer_id: null,
  sr_one_sent: null,
  sr_nineteen_sent: null,
  plf_dec_sheet: false,
  pd_requested: null,
  pd_received: false,
  loe: false,
  rep_ltr_to_def_one_ins: false,
  rep_ltr_to_def_two_ins: false,
  rep_ltr_own_ins: false,
  rep_ltr_def_three: false,
  witnesses: false,
  witness_statement: false,
  passengers: false,
  other: null,
  police_department_id: null,

  // Medical
  medicare: false,
  medical: false,
  primary_health_ins: false,
  no_health_ins: false,
  insurance_card_number: null,
  medicare_filled_at: null,
  medical_filled_at: null,
  medicare_final_lien: false,
  medical_final_lien: false,
  primary_health_ins_final_lien: false
};
