import { MainContent } from './MainContent';
import { TemplatesProvider } from './TemplatesProvider';

export const Templates = () => {
  return (
    <TemplatesProvider>
      <MainContent />
    </TemplatesProvider>
  );
};
