import { flow } from 'lodash';
import { useContext } from 'react';
import {
  Box,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useModal } from '../../../../../components/ModalsProvider';
import { Tooltip } from '../../../../../components/Tooltip';
import { PrescriptionsContext } from '../PrescriptionsProvider';
import { EditModal } from '../EditModal';

export const PrescriptionsMenu = ({ prescription, onClose }) => {
  const { openModal } = useModal();
  const { updatePrescription, deletePrescription } = useContext(PrescriptionsContext);

  const handleDeleteTemplate = () => {
    deletePrescription(prescription.id);
  };

  const onUpdate = () => {
    openModal(EditModal, {
      payload: { id: prescription.id },
      onModalResolved: (value) => {
        updatePrescription(value);
      }
    });
  };

  return (
    <List>
      <Tooltip
        isExistTooltip={!!prescription?.signature?.id}
        title="Prescription signed, create a new one please"
      >
        <div>
          <MenuItem disabled={!!prescription?.signature?.id} onClick={flow(onUpdate, onClose)}>
            <ListItemIcon>
              <Box color="info.main">
                <EditIcon fontSize="inherit" color="inherit" />
              </Box>
            </ListItemIcon>

            <ListItemText primary="Edit" />
          </MenuItem>
        </div>
      </Tooltip>

      <MenuItem onClick={flow(handleDeleteTemplate, onClose)}>
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
