import { useContext, useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ScrollbarsLoader } from '../../../../ScrollbarsLoader';
import { ThreadsContext } from '../../../ThreadsContext';
import { Loader } from '../../../../Loader';
import { ThreadLink } from './ThreadLink';

const useStyles = makeStyles({
  root: {
    width: '100% !important'
  }
});

export const ThreadsList = ({ isMinimized }) => {
  const {
    threads,
    isFetching,
    isFetched,
    loadNextPage,
    resetThreads,
    filter: { total }
  } = useContext(ThreadsContext);
  const classes = useStyles();

  useEffect(() => {
    resetThreads();
  }, []);

  return (
    <ScrollbarsLoader
      translateContentSizesToHolder
      className={classes.root}
      total={total}
      rowsCount={threads.length}
      isFetching={isFetching}
      isFetched={isFetched}
      onNeedNextPage={loadNextPage}
      style={{ maxHeight: '100%' }}
    >
      <Loader fullWidth p={2} loading={!isFetched} render={
        () => !total && !isMinimized ? (
          <Box p={2}>
            <Typography align="center">No threads found</Typography>
          </Box>
        ) : (
          threads.map((item) => (
            <ThreadLink
              key={item.id}
              item={item}
              isMinimized={isMinimized}
            />
          ))
        )}
      />
    </ScrollbarsLoader>
  );
};
