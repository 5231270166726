import { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { filtersKeysMap } from '../../store/lastFilters';
import { useMobileFilterCollapse } from '../../utils/useMobileFilterCollapse';
import { CompaniesContextProvider } from '../../app/Dashboard/Contacts/Companies/CompaniesContext';
import { CreateCompanyModal } from '../../app/Dashboard/Contacts/Companies/CreateCompanyModal';
import { companiesMap, List } from '../../app/Dashboard/Contacts/Companies/List';
import { FiltersBar } from '../../app/Dashboard/Contacts/Companies/FiltersBar';
import { Grid } from '../../app/Dashboard/Contacts/Companies/Grid';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderActions
} from '../Modal';
import { useModal } from '../ModalsProvider';
import { hasRole } from '../../utils/hasRole';
import { rolesMap } from '../../dataMaps/rolesMap';

const hiddenColumns = [
  companiesMap.actions
];

export const CompanyListModal = ({
  payload: {
    insuranceInfo = {},
    modalParams = {}
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    toggleFiltersBar
  } = useMobileFilterCollapse();
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  const handleCompanySelect = (company) => {
    handleModalResolve(company);
  };

  const handleCreateCompany = () => {
    openModal(CreateCompanyModal, {
      payload: {
        insuranceInfo,
        ...modalParams
      },
      onModalResolved: (data) => {
        handleModalResolve(data);
      }
    });
  };

  useEffect(() => {
    toggleFiltersBar();
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Select Insurance Company

          {!hasRole(rolesMap.patient) &&
            <ModalHeaderActions>
              <Button
                color="primary"
                startIcon={<AddIcon/>}
                onClick={handleCreateCompany}
              >
                {isMobile ? 'Add' : ' Add Company'}
              </Button>

              {isTablet && filterOpenButton}
            </ModalHeaderActions>
          }
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <Box height={!isMobile ? 500 : '100%'}>
            <CompaniesContextProvider>
              <Box height="100%" display="flex" flexDirection="column">
                <ScrollWrapper {...scrollWrapperProps}>
                  <FilterBarWrapper {...filterBarWrapperProps}>
                    <FiltersBar
                      disableInitialFilters
                      companyName={insuranceInfo?.name || null}
                      filterKey={filtersKeysMap.contacts_companies}
                      fullWidthFields={isMobile}
                    />
                  </FilterBarWrapper>
                </ScrollWrapper>

                <Box flexGrow={1}>
                  {!isMobile
                    ? <List hiddenColumns={hiddenColumns} onCompanySelect={handleCompanySelect} />
                    : <Grid hiddenColumns={hiddenColumns} onCompanySelect={handleCompanySelect} />
                  }
                </Box>
              </Box>
            </CompaniesContextProvider>
          </Box>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
