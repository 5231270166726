import { FormControlLabel, Grid, Hidden } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { CaseUsersSelect } from '../../../../../../components/cases/CaseUsersSelect';
import {
  Checkbox,
  CurrencyField,
  KeyboardDatePicker,
  TextField
} from '../../../../../../components/FormField';
import { rolesMap } from '../../../../../../dataMaps';

export const DefendantForm = () => {
  const { values } = useFormikContext();

  return (
    <>
      <Grid item sm={6} xs={12}>
        <CaseUsersSelect
          name="attorney_id"
          label="Adj. (BI)"
          margin="dense"
          params={{
            role: rolesMap.adjuster,
            office_id: values.office_id?.value || values.office_id,
            company_id: values.insurance_company_id?.id
          }}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <CaseUsersSelect
          name="provider_id"
          label="Adj. (UM)"
          margin="dense"
          params={{
            role: rolesMap.adjuster,
            office_id: values.office_id?.value || values.office_id,
            company_id: values.insurance_company_id?.id
          }}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="policy_number"
          label="Policy #"
          margin="dense"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="claim_number"
          label="Claim #"
          margin="dense"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="insured"
          label="Insured"
          margin="dense"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="driver"
          label="Driver"
          margin="dense"
        />
      </Grid>

      <Hidden only={[ 'sm', 'xs' ]}>
        <Grid item md={6} />
      </Hidden>

      <Grid item md={3} sm={6} xs={12}>
        <FormControlLabel
          control={<Checkbox name="refused_dislosure" />}
          label="Refused Dislosure"
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <KeyboardDatePicker
          name="requested_limit_date"
          label="Request Limits"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <CurrencyField
          name="policy_limit"
          label="Policy Limits"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="umbrella_insurance"
          label="Umbrella Ins."
          margin="dense"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="vehicle.license_number"
          label="Veh Lic #"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <KeyboardDatePicker
          zeroMinWidth
          name="vehicle.year"
          label="Year/Make"
          views={[ 'year' ]}
          format="YYYY"
          mask="____"
          placeholder="YYYY"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="vehicle.model"
          label="Model"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="vehicle.color"
          label="Color"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="dl_number"
          label="DL #"
        />
      </Grid>
    </>
  );
};
