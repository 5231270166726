export const styles = ({ spacing, palette: { grey }, typography: { pxToRem } }) => ({
  contentAmount: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: spacing(1, 2),
    backgroundColor: grey[50],
    height: '100%',
    width: '100%'
  },

  currencyFormat: {
    fontSize: pxToRem(48)
  },

  address: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: spacing(),
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  }
});
