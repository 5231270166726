import { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { api } from '../../../api';
import * as chatsApi from '../../../api/chat';
import { Echo } from '../../../utils/echo';
import { MessengerContext } from '../MessengerProvider';
import { useThread } from '../utils/useThread';
import { actionTypesMap } from './actionTypesMap';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const ChatThreadContext = createContext(null);

export const ChatThreadContextProvider = ({ children }) => {
  const { currentThreadId } = useContext(MessengerContext);
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const [ typing, setTyping ] = useState(null);
  const cancelFetch = useRef(() => {});
  const {
    currentThread,
    isFetching,
    isFetched,
    selectedMessages,
    currentAction,
    messageToEdit,
    forwardedMessage
  } = state;

  const fetchCurrentThread = (threadId) => {
    cancelFetch.current();

    dispatch({ type: types.THREAD_FETCH_REQUEST });

    chatsApi.fetchCurrentThread(threadId, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.THREAD_FETCH_SUCCESS, payload: { data } });

      chatsApi.threadSeen(threadId);
    });
  };

  const updateCurrentThread = (updatedThread) => {
    dispatch({ type: types.UPDATE_CURRENT_THREAD, payload: updatedThread });
  };

  const handleThreadLikeChange = (payload) => {
    dispatch({ type: types.TOGGLE_THREAD_AS_FAVORITE, payload });
  };

  const addMessagesToSelected = (messages) => {
    dispatch({ type: types.ADD_MESSAGES_TO_SELECTED, payload: messages });
  };

  const deleteMessagesFromSelected = (messages) => {
    dispatch({ type: types.DELETE_MESSAGES_FROM_SELECTED, payload: messages });
  };

  const toggleMessageSelect = (message) => {
    setCurrentAction(actionTypesMap.select);

    if (!selectedMessages.includes(message)) {
      addMessagesToSelected([ message ]);
    } else {
      deleteMessagesFromSelected([ message ]);
    }
  };

  const resetSelectedMessages = () => {
    dispatch({ type: types.DELETE_MESSAGES_FROM_SELECTED, payload: selectedMessages });
  };

  const setCurrentAction = (newAction) => {
    dispatch({ type: types.SET_CURRENT_ACTION, payload: { newAction: newAction } });
  };

  const resetCurrentAction = () => {
    dispatch({ type: types.SET_CURRENT_ACTION, payload: { newAction: '' } });
  };

  const setMessageToEdit = (selectedMessage) => {
    dispatch({ type: types.SET_MESSAGE_TO_EDIT, payload: { selectedMessage } });
  };

  const resetMessageToEdit = () => {
    dispatch({ type: types.SET_MESSAGE_TO_EDIT, payload: { messageToEdit: '' } });
  };

  const setForwardedMessage = (message) => {
    dispatch({ type: types.SET_FORWARDED_MESSAGE, payload: { message } });
  };

  const providerValue = {
    currentThread,
    isFetching,
    isFetched,
    selectedMessages,
    currentAction,
    messageToEdit,
    forwardedMessage,
    typing,
    fetchCurrentThread,
    updateCurrentThread,
    toggleMessageSelect,
    resetSelectedMessages,
    setCurrentAction,
    resetCurrentAction,
    setMessageToEdit,
    resetMessageToEdit,
    setForwardedMessage
  };

  useThread({
    threadId: currentThread?.id,
    onThreadUpdated: updateCurrentThread,
    onThreadLikeChange: handleThreadLikeChange
  });

  useEffect(() => {
    return () => {
      cancelFetch.current();
      resetCurrentAction();
    };
  }, []);

  useEffect(() => {
    if (!currentThreadId) {
      return;
    } else {
      fetchCurrentThread(currentThreadId);
    }

    const threadsChannel = Echo.private(`threads.${currentThreadId}`);

    const handleTyping = (data) => {
      setTyping(data);
    };

    threadsChannel.listenForWhisper('typing', handleTyping);

    return () => {
      setTyping(null);

      threadsChannel.stopListeningForWhisper('typing', handleTyping);
    };
  }, [ currentThreadId ]);

  return (
    <ChatThreadContext.Provider value={providerValue}>
      {children}
    </ChatThreadContext.Provider>
  );
};
