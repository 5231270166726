import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow header>
      <ListRowCell flexBasis={columnsWidths.from}>
        Appt.From
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.to}>
        Appt.To
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.cptId}>
        Procedure Code#
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.icdId}>
        Diagnosis
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        Provider
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        Office
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
