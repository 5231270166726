import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Box, Button, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import { Loader } from '../../../../../../components/Loader';
import { Tooltip } from '../../../../../../components/Tooltip';
import { styles } from '../../ClaimPreview/ActionsBar/styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({ isSubmitting, sidebarIsOpen, setSidebarIsOpen }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleDrawerOpen = () => {
    setSidebarIsOpen((isOpen) => !isOpen);
  };

  return (
    <div className={classes.root}>
      <IconButton
        edge="start"
        color="primary"
        title={sidebarIsOpen ? 'Hide sidebar' : 'Show sidebar'}
        onClick={handleDrawerOpen}
      >
        {sidebarIsOpen ? <ChevronLeftIcon /> : <MenuIcon />}
      </IconButton>

      <Box ml={1}>
        <Typography variant="h4">Create new claim</Typography>
      </Box>

      <Box display="flex" ml="auto" pl={1}>
        <Box mr={1}>
          <Tooltip title="Back to claims page">
            <Button
              size={isMobile ? 'small' : 'medium'}
              component={RouterLink}
              to="/billing/claims"
            >
              Cancel
            </Button>
          </Tooltip>
        </Box>

        <Loader surface loading={isSubmitting} render={
          () => (
            <Button
              autoFocus
              size={isMobile ? 'small' : 'medium'}
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              Create
            </Button>
          )}
        />
      </Box>
    </div>
  );
};
