import { api } from '../api';

export const fetchUsersWithAccess = ({ type, ...config }) => {
  return api.get(`/access-users/${type}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const addUserWithAccess = (data) => {
  return api.post('/access-users', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateUser = ({ id, ...data }) => {
  return api.put(`/access-users/${id}`, data)
    .then((data) => data)
    .catch((error) => { throw error; });
};

export const removeUserWithAccess = (id) => {
  return api.delete(`/access-users/${id}`)
    .then((data) => data)
    .catch((error) => { throw error; });
};
