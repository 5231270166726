import { makeStyles } from '@material-ui/core';
import { arrivalTypes } from '../../../../../../../components/appointments';
import { ArrivalType } from './ArrivalType';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflowX: 'auto',
    padding: spacing(1.5, 1),

    [breakpoints.down(breakpoints.values.md)]: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    }
  },

  indicator: {
    marginRight: spacing(2),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      flexBasis: '25%'
    },

    '& + &': {
      paddingLeft: spacing(2),
      borderLeft: `1px solid ${palette.divider}`,

      [breakpoints.down(breakpoints.values.md)]: {
        paddingLeft: 0,
        borderLeft: 'none'
      }
    }
  }
}));

export const ArrivalTypesIndicators = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {Object.values(arrivalTypes).map((type) => (
        <div key={type.name} className={classes.indicator}>
          <ArrivalType type={type} />
        </div>
      ))}
    </div>
  );
};
