export const usersRolesOptions = [
  {
    value: 'admin',
    label: 'Admin'
  },
  {
    value: 'advocate',
    label: 'Advocate'
  },
  {
    value: 'medic',
    label: 'Medic'
  },
  {
    value: 'doctor',
    label: 'Doctor'
  },
  {
    value: 'secretary',
    label: 'Secretary'
  },
  {
    value: 'supervisor',
    label: 'Supervisor'
  },
  {
    value: 'patient',
    label: 'Patient'
  },
  {
    value: 'client',
    label: 'Adjuster'
  }
];

export const workersRolesOptions = [
  {
    value: 'admin',
    label: 'Admin'
  },
  {
    value: 'advocate',
    label: 'Advocate'
  },
  {
    value: 'medic',
    label: 'Medic'
  },
  {
    value: 'doctor',
    label: 'Doctor'
  },
  {
    value: 'secretary',
    label: 'Secretary'
  },
  {
    value: 'supervisor',
    label: 'Supervisor'
  }
];
