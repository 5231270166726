import { Component } from 'react';
import { get } from 'lodash';
import {
  connect as formikConnect,
  Field as FormikField
} from 'formik';
import { getDisplayName } from '../../helpers/getComponentName';

export const withBaseField = (OriginComponent) => {
  return formikConnect(class FormField extends Component {
    static displayName = `FormField(${getDisplayName(OriginComponent)})`;

    render() {
      const { formik, ...props } = this.props;
      const isFormikField = !!formik;

      return (
        isFormikField ?
          <FormikField
            {...props}
            isFormikField={true}
            error={get(formik.errors, props.name)}
            component={OriginComponent}
          />
          :
          <OriginComponent {...props} isFormikField={false} />
      );
    }
  });
};
