import { ClaimsField } from '../../../../../../../../../components/claims';
import {
  TextField,
  DatePicker,
  InvoicesStatusesSelect,
  BillingParticipantsSelect
} from '../../../../../../../../../components/FormField';
import {
  unixToEndOfDayUnix,
  unixToStartOfDayUnix
} from '../../../../../../../../../helpers/dates';

export const getInvoicesFilterFields = () => {
  return [
    <TextField
      name="number"
      label="Inv number"
      placeholder="Search..."
    />,

    <BillingParticipantsSelect
      formattedValue={false}
      name="billing_participant_id"
      label="Participants"
    />,

    <ClaimsField
      formattedValue={false}
      name="claim_id"
      label="Claim"
      placeholder="Search claim..."
    />,

    <DatePicker
      clearable
      outputFormat="YYYY-MM-DD"
      name="due_date_from"
      label="From"
    />,

    <DatePicker
      clearable
      outputFormat="YYYY-MM-DD"
      name="due_date_to"
      label="To"
    />,

    <InvoicesStatusesSelect
      isClearable
      formattedValue={false}
      name="status_id"
      label="Filter by statuses"
    />
  ];
};

export const getInvoicesFiltersValues = (filters) => {
  const transformedFromDate = unixToStartOfDayUnix?.(filters?.due_date_from) || filters?.due_date_from;
  const transformedToDate = unixToEndOfDayUnix?.(filters?.due_date_to) || filters?.due_date_to;

  return {
    billing_participant_id: filters?.billing_participant_id?.value || null,
    number: filters?.number || null,
    claim_id: filters?.claim_id?.id || null,
    due_date_from: transformedFromDate || null,
    due_date_to: transformedToDate || null,
    status_id: filters?.status_id?.value || null
  };
};
