import { useContext, useEffect, useRef } from 'react';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../components/Loader';
import { ThreadFilesContext } from '../ThreadFilesProvider';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const List = () => {
  const classes = useStyles();
  const {
    isFetched,
    isFetching,
    filter,
    files,
    loadNextPage,
    resetFiles
  } = useContext(ThreadFilesContext);
  const scrollElementRef = useRef();
  const virtualListRef = useRef();

  useEffect(() => {
    resetFiles();
  }, []);

  return (
    <div className={classes.root}>
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
        <Loader p={2} loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center" className={classes.notFoundText}>No files found</Typography>
          ) : (
            <MuiList className={classes.list}>
              <InfiniteListLoader
                items={files}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                scrollElementRef={scrollElementRef}
                virtualListRef={virtualListRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      </CustomScrollbars>
    </div>
  );
};
