import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Divider } from '../../../../../../../../components/Divider';
import {
  Radio,
  TextField,
  KeyboardDatePicker,
  PhoneField,
  RadioGroup,
  CompaniesAutocomplete
} from '../../../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../../../components/users';

export const Body = ({ caseItem }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleCompanyChange = (company) => {
    setFieldValue('substituted_out_company_address', company?.full_address || null);
    setFieldValue('substituted_out_company_phone', company?.data?.phone || null);
  };

  useEffect(() => {
    if (values.subbed_out > 0) {
      setFieldValue('substituted_out_company_id', caseItem?.substituted_out);
    } else {
      setFieldValue('substituted_out_company_id', null);
    }
  }, [ values.subbed_out ]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <KeyboardDatePicker
            disabled
            name="closed_at"
            label="Date Case Closed"
            margin="dense"
          />
        </Grid>

        <Grid item xs={6}>
          <UsersSelect
            disabled
            name="closed_by"
            label="Case Closed By"
            margin="dense"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <KeyboardDatePicker
            name="physical_file_scanned_at"
            label="Date Physical File Scanned"
            margin="dense"
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="flex-end"
      >
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
          item
          xs={6}
        >
          <Typography variant="h5">Case Subbed Out</Typography>

          <RadioGroup
            row
            name="subbed_out"
            FormControlProps={{ margin: 'dense' }}
          >
            <FormControlLabel
              value="1"
              label={<Typography>Yes</Typography>}
              control={<Radio name="subbed_out" color="primary" />}
            />

            <FormControlLabel
              value="0"
              label={<Typography>No</Typography>}
              control={<Radio name="subbed_out" color="primary" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={6}>
          <KeyboardDatePicker
            disabled
            name="subbed_out_at"
            label="Date Case Subbed Out"
            margin="dense"
          />
        </Grid>
      </Grid>

      {values.subbed_out > 0 &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={4} mb={1}>
              <Typography variant="h4">Substituted out information</Typography>

              <Box flexGrow={1} ml={4}>
                <Divider type="dashed" />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <CompaniesAutocomplete
              name="substituted_out_company_id"
              label="Company name"
              placeholder="Select company..."
              onChange={handleCompanyChange}
            />
          </Grid>

          <Grid item xs={4}>
            <PhoneField
              zeroMinWidth
              name="substituted_out_company_phone"
              label="Phone"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              name="substituted_out_company_address"
              label="Address"
            />
          </Grid>
        </Grid>
      }
    </>
  );
};
