export const styles = ({
  palette: { grey },
  typography: { pxToRem },
  spacing,
  breakpoints
}) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexGrow: 1,

    [breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },

  leftSideBar: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
    paddingLeft: spacing(2),
    paddingRight: spacing(6),

    [breakpoints.down('md')]: {
      padding: spacing(1, 2)
    },

    '& > div': {
      paddingBottom: spacing(1),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),

      [breakpoints.down('md')]: {
        padding: spacing(1),
        paddingBottom: spacing(2)
      }
    }
  },

  rightSideBar: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '320px',
    minWidth: '320px',
    backgroundColor: grey[50],

    [breakpoints.down('md')]: {
      position: 'static',
      width: '100%'
    }
  },

  input: {
    color: grey[500],
    fontSize: pxToRem(21),
    flexGrow: 1
  },

  submitButton: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: spacing(1.5, 4, 1.5, 2.5)
  },

  cancelBtn: {
    color: grey[200],
    marginRight: spacing(1.5)
  }
});
