import { Box, Grid, Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { Divider } from '../../../../../../components/Divider';
import { useModal } from '../../../../../../components/ModalsProvider';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../components/UserLink';
import { UserPreviewModal } from '../../../../../../components/users';
import { stopPropagation } from '../../../../../../helpers/dom';
import { PreviewCompanyModal } from '../../../../Contacts/Companies/PreviewCompanyModal';
import { insuranceStatusMap, insuranceTypes, statusTypeCaseOptions } from '../../AddInsuranceInfoModal';
import { Body } from '../Body';
import { DefendantBody } from '../DefendantBody';
import { ImagePreview } from '../ImagePreview';
import { PlaintiffBody } from '../PlaintiffBody';

export const ViewInsuranceBody = ({ insurance }) => {
  const { openModal } = useModal();
  const statusType = statusTypeCaseOptions.find(({ value }) => value === insurance?.status_type)?.label ||
    insurance?.status_type;
  const isCaseInsurance = statusType === insuranceStatusMap.case_insurance;

  const openCompanyViewModal = () => {
    if (insurance?.insurance_company?.id) {
      openModal(PreviewCompanyModal, {
        payload: { companyID: insurance?.insurance_company?.id }
      });
    }
  };

  const openUserPreview = () => {
    if (insurance?.attorney?.id) {
      openModal(UserPreviewModal, {
        payload: { id: insurance?.attorney?.id }
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography color="textSecondary">
            Insurance
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="h5">
            {statusType}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography color="textSecondary">
            Type Insurance
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="h5">
            {insurance.type_insurance}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography color="textSecondary">
            Insurance Company
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <CompanyLink
            company={insurance?.insurance_company}
            onClick={stopPropagation(openCompanyViewModal)}
            variant="h5"
          />

          {insurance?.insurance_company?.full_address &&
            <Typography variant="h5">
              <Box component="span" mr={1}>
                <RoomIcon />
              </Box>
              {insurance?.insurance_company?.full_address}
            </Typography>
          }
        </Grid>

        {insurance?.office?.id &&
          <>
            <Grid item xs={3}>
              <Typography color="textSecondary">
                Insurance Office
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <OfficeLink variant="h5" office={insurance?.office} />
            </Grid>
          </>
        }

        {!isCaseInsurance && insurance?.attorney?.id && (
          <>
            <Grid item xs={3}>
              <Typography color="textSecondary">
                Attorney
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <UserLink
                variant="h5"
                user={insurance?.attorney}
                onClick={openUserPreview}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Box width="100%" pt={2} pb={3}>
        <Divider />
      </Box>

      {!isCaseInsurance && <Body insurance={insurance} />}

      {isCaseInsurance && (insurance?.type_insurance === insuranceTypes.plaintiff ? (
        <PlaintiffBody insurance={insurance} />
      ) : (
        <DefendantBody insurance={insurance} />
      ))}

      {(insurance.url_scan_front || insurance.url_scan_back) && (
        <Box px={1} py={3}>
          <Box pb={3}>
            <Typography gutterBottom variant="h3">Scanned insurance card</Typography>
          </Box>

          <div>
            {insurance.url_scan_front &&
              <ImagePreview imageUrl={insurance.url_scan_front} name="Front" />
            }

            {insurance.url_scan_back &&
              <ImagePreview imageUrl={insurance.url_scan_back} name="Back" />
            }
          </div>
        </Box>
      )}
    </>
  );
};
