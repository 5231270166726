import { api } from '../../api';

export const fetchAccessPatients = (config) => {
  return api.get('/access-users/patients', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createAccessPatient = (data) => {
  return api.post('/access-users', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const removeAccessPatient = (id) => {
  return api.delete(`/access-users/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateAccessPatient = ({ id, ...data }) => {
  return api.put(`/access-users/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massReplacePatient = (data) => {
  return api.post('/access-users/mass-replace', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
