import moment from 'moment';
import { Yup } from '../../../utils/validation';
import { payerTypesMap } from './ClaimForm';

export const validationSchema = Yup.object().shape({
  payer_type: Yup.string().nullable().required(),
  local_use_19: Yup.string().nullable(),
  note: Yup.string().nullable(),
  status: Yup.string().nullable().required(),
  hospitalization_from: Yup.number().nullable().when('hospitalization_to', {
    is: (value) => value,
    then: Yup.number().nullable().required()
  }),
  hospitalization_to: Yup
    .date()
    .format('X')
    .max(moment(), 'Date must be earlier than current')
    .nullable()
    .when('hospitalization_from', (hospitalizationFrom, schema) => (
      hospitalizationFrom ? schema.required() : schema
    )),
  is_paper_claim: Yup.boolean(),
  is_auth_required: Yup.boolean(),
  is_use_clia: Yup.boolean(),
  is_use_last_dx: Yup.boolean(),
  patient_id: Yup.mixed().nullable().required(),
  insurance_id: Yup.mixed().nullable().when('payer_type', (payerType, schema) => {
    return payerType === payerTypesMap.insurance ? schema.required() : schema;
  }),
  adjuster_id: Yup.mixed().nullable().when('insurance_id', (insurance_id, schema) => {
    return insurance_id ? schema.required() : schema;
  }),
  office_id: Yup.mixed().nullable().required(),
  claim_filing_indicator_id: Yup.mixed().nullable(),
  special_program_code_id: Yup.mixed().nullable(),
  local_use_id: Yup.mixed().nullable(),
  provider_rendering_id: Yup.mixed().nullable(),
  provider_supervising_id: Yup.mixed().nullable(),
  provider_ordering_id: Yup.mixed().nullable(),
  provider_primary_care_id: Yup.mixed().nullable(),
  provider_referring_id: Yup.mixed().nullable(),
  appointments: Yup.array().of(Yup.mixed().nullable().required()).nullable(),
  icd_codes: Yup.array().of(Yup.object().nullable().required()).nullable()
}, [ 'hospitalization_from', 'hospitalization_to' ]);
