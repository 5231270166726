import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Sticky } from '../../../../../components/Sticky';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { TableHeader } from './TableHeader';
import { OfficesContext } from '../OfficesContext';
import { Row } from './Row';

export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1150;
export const widthBreakpointLarge = 1375;
export const minRowHeight = 54;
export const columnsWidths = {
  name: 250,
  name_company: 250,
  address: 180,
  phone: 150,
  email: 180,
  office_contacts: 200,
  actions: 50
};

export const List = () => {
  const {
    isFetched,
    isFetching,
    offices,
    meta: { per_page, last_page, page },
    loadNextPage,
    resetOffices
  } = useContext(OfficesContext);
  const scrollElementRef = useRef();
  const { width } = useResizeObserver({ ref: scrollElementRef });

  useEffect(() => {
    resetOffices();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader p={3} loading={!isFetched} render={
        () => !offices.length ? (
          <Box p={3}>
            <Typography align="center">No offices found</Typography>
          </Box>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader width={width} />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={offices}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={{ per_page, last_page, page }}
                RowComponent={Row}
                onNeedNextPage={loadNextPage}
                RowProps={{ offices, width }}
                scrollElementRef={scrollElementRef}
                minRowHeight={minRowHeight}
              />
            </MuiList>
          </>
        )}
      />
    </CustomScrollbars>
  );
};
