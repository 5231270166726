import { useContext, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../components/TablePagination';
import { Loader } from '../../../../../components/Loader';
import { Cards } from '../../../../../components/Cards';
import { WaitingListContext } from '../WaitingListProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    meta,
    isFetched,
    waiting_list,
    resetWaitingList
  } = useContext(WaitingListContext);
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    resetWaitingList();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box py={2} px={isMobile ? 1: 2}>
        <Loader loading={!isFetched} render={
          () => !meta.total ? (
            <Box p={3}>
              <Typography align="center">No info found</Typography>
            </Box>
          ) : (
            <>
              <Cards size={isMobile ? 300 : 320} list={waiting_list} component={Card} />
              <TablePagination borderTop pagination={meta} onChange={resetWaitingList} />
            </>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
