import { Yup } from '../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required().nullable().max(255),
  certificate_data: Yup.object().shape({
    countryName: Yup.string().length(2, 'This field has to be exactly 2 characters!').nullable(),
    stateOrProvinceName: Yup.string().nullable().min(2).max(36),
    localityName: Yup.string().nullable().min(2).max(36),
    organizationName: Yup.string().nullable().min(2).max(36),
    organizationalUnitName: Yup.string().nullable().min(2).max(36),
    commonName: Yup.string().required().nullable().min(2).max(36),
    emailAddress: Yup.string().required().nullable().max(36)
  })
});
