import { SvgIcon } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import WorkIcon from '@material-ui/icons/WorkOutlineOutlined';
import { Icon } from '@mdi/react';
import { mdiHomeCityOutline } from '@mdi/js';
import { apiModelsMap, itemsRoutesByModels } from '../../../../../dataMaps/apiModels';
import { linksBySystemTypesMap } from './linksBySystemTypesMap';

export const searchResultsMap = {
  'case': ({ relation_id }) => ({
    link: itemsRoutesByModels[apiModelsMap.case](relation_id),
    Icon: WorkIcon
  }),

  'task': ({ relation_id }) => ({
    link: itemsRoutesByModels[apiModelsMap.task](relation_id),
    Icon: PlaylistAddCheckIcon
  }),

  'company': ({ relation_id }) => ({
    link: itemsRoutesByModels[apiModelsMap.company](relation_id),
    Icon: (props) => <SvgIcon {...props}><Icon path={mdiHomeCityOutline} /></SvgIcon>
  }),

  'navigation': ({ system_type, relation_id }) => ({
    link: linksBySystemTypesMap[system_type]?.(relation_id),
    Icon: LinkIcon
  }),

  'contact': ({ relation_id }) => ({
    link: itemsRoutesByModels[apiModelsMap.user](relation_id),
    Icon: PersonOutlinedIcon
  })
};
