export const initialValues = {
  // Dates
  onset_date: null,
  first_consult_date: null,
  assumed_care_date: null,
  hearing_date: null,
  vision_rx_date: null,
  lmp_date: null,
  last_seen_date: null,
  relinquished_care_date: null,
  accident_date: null,

  accident_type: null,
  auto_accident_state: null,
  auto_accident_country: null,
  qualifier: null,

  // Work Comp
  disability: null,
  return_work_indicator: null,
  last_work_date: null,

  // Unable to Work
  unable_work_from: null,
  unable_work_to: null,

  // Situational Number
  mammography_certification: null,
  investigation_device_exception: null,

  // Vision Claim
  is_certification_code_indicator: false,
  certification_type: null,
  condition_indicators: null,

  // Hospice
  care_plan_oversight: null,
  hospice: null,

  // Medicaid
  resubmission_code: null,
  original_reference: null,
  service_auth_exception_code: null,
  medical_referral_access: null,
  is_family_planning: false,
  is_epsdt: false,
  is_copay_exempt: false,
  epsdt_condition_code: null,
  epsdt_referral_code: null,

  // Chiropractor Claims
  initial_treatment_date: null,
  acute_manifestation_date: null,
  last_xray_date: null,
  nature_condition: null,
  description: null,
  xray_available: null,

  // Supplemental Information
  report_type_code_id: null,
  report_transmission: null,
  attach_ctrl_number: null,

  // MISC (Claim Note)
  note_reference_code: null,
  note: null
};
