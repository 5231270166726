import { useContext } from 'react';
import { debounce } from 'lodash';
import { Formik } from 'formik';
import { makeStyles, InputAdornment, FormControlLabel, Grid, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { AppBar } from '../../../../../components/AppBar';
import { TextField, Switch } from '../../../../../components/FormField';
import { TagsContext } from '../../../files-common';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FiltersBar = () => {
  const classes = useStyles();
  const { filter, applyFilter, resetTags } = useContext(TagsContext);

  const handleNameChange = debounce((event) => {
    applyFilter({ ...filter, name: event.target.value });
  }, 600);

  const handleSwitchChange = ({ target: { checked } }) => {
    resetTags({ is_system: +checked });
  };

  return (
    <AppBar className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <Formik initialValues={filter} onSubmit={null}>
        <Grid container spacing={2} alignItems="center">
          <Grid item component={Box} flexGrow={1}>
            <TextField
              autoFocus
              name="name"
              placeholder="Search..."
              onChange={handleNameChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              label="Show public tags"
              control={
                <Switch
                  name="is_system"
                  color="primary"
                  onChange={handleSwitchChange}
                />
              }
            />
          </Grid>
        </Grid>
      </Formik>
    </AppBar>
  );
};
