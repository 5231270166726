export const styles = ({ breakpoints, transitions }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexGrow: 1
  },

  content: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden'
  },

  sidebar: {
    flexShrink: 0,
    height: '100%',

    [breakpoints.up('lg')]: {
      width: 278
    },

    [breakpoints.down('md')]: {
      width: 0,
      overflow: 'hidden'
    }
  },

  sidebarOpen: {
    width: 278,
    transition: transitions.create([ 'width' ]),

    [breakpoints.down(breakpoints.values.sm)]: {
      width: '100%'
    }
  },

  main: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'hidden'
  },

  mainContent: {
    flex: 1,
    overflow: 'hidden'
  }
});
