import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import printJS from 'print-js';
import { makeStyles, List, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import * as invoicesApi from '../../../../api/billing';
import { itemsRoutesByModels } from '../../../../dataMaps/apiModels';
import { useModal } from '../../../ModalsProvider';
import { PaymentModal } from '../PaymentModal';
import { PaymentsContext } from '../PaymentsContext';

const useStyles = makeStyles({
  root: {
    minWidth: 172
  }
});

export const PaymentMenu = ({ disableInvoicePreview, parentPage, payment }) => {
  const history = useHistory();
  const classes = useStyles();
  const paymentsContext = useContext(PaymentsContext);
  const { openModal } = useModal();

  const deletePayment = () => paymentsContext.deletePayment(payment.id);

  const goToPaymentItem = () => {
    !parentPage ?
      history.push(itemsRoutesByModels.payment(payment.id))
      :
      openModal(PaymentModal, {
        payload: {
          initialValues: {
            disableInvoicePreview,
            title: payment.number,
            id: payment.id
          }
        }
      });
  };

  const handlePrintInvoice = () => {
    invoicesApi.downloadInvoicePDF(payment?.invoice?.id).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      printJS({
        printable: url,
        type: 'pdf'
      });
    });
  };

  return (
    <List className={classes.root}>
      <MenuItem onClick={goToPaymentItem}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="See Details" />
      </MenuItem>

      <MenuItem disabled={!payment?.invoice?.id} onClick={handlePrintInvoice}>
        <ListItemIcon>
          <PrintIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Print Invoice" />
      </MenuItem>

      <MenuItem
        component={Link}
        to={`/billing/payments/edit/${payment.id}`}
      >
        <ListItemIcon>
          <EditIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={deletePayment}>
        <ListItemIcon>
          <DeleteIcon fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete payment" />
      </MenuItem>
    </List>
  );
};
