import { useContext } from 'react';
import { SvgIcon, Tooltip, Typography } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Icon from '@mdi/react';
import { mdiAccountArrowLeftOutline, mdiBriefcaseCheckOutline } from '@mdi/js';
import { CallReportsContext } from '../../../../../../../components/call-reports/CallReportsProvider';
import { CopyToModal } from '../../../../../../../components/call-reports/CopyToModal';
import { parentTypesMap } from '../../../../../../../components/call-reports/CopyToModal/parentTypeOptions';
import { SelectedItemsCount } from '../../../../../../../components/SelectedItemsCount';
import { LayoutContext, viewVariantsMap } from '../../../../../../../components/LayoutContext';
import { IconButton } from '../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';

export const ActionsBar = ({ isTabPage, isTablet, payload, filterOpenButton }) => {
  const {
    meta,
    deleteParent,
    resetCallReports,
    selectedCallReportsIDs,
    deleteCallReportsFromSelected
  } = useContext(CallReportsContext);
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const copyReports = (parentType) => () => {
    openModal(CopyToModal, {
      payload: { ids: selectedCallReportsIDs, parent_type: parentType },
      onModalResolved: () => {
        deleteCallReportsFromSelected(selectedCallReportsIDs);
        resetCallReports();
      }
    });
  };

  const onDelete = () => {
    deleteParent({
      ...payload,
      ids: selectedCallReportsIDs
    });
  };

  return (
    <>
      {!!selectedCallReportsIDs?.length && (
        <>
          <SelectedItemsCount length={selectedCallReportsIDs.length} total={meta.total} />
          <VerticalDivider verticalGutters={1} horizontalGutters={1} />

          <Tooltip
            arrow
            title={<Typography>Attach selected call reports to case</Typography>}
          >
            <IconButton color="white" onClick={copyReports(parentTypesMap.case)}>
              <SvgIcon color="inherit">
                <Icon path={mdiBriefcaseCheckOutline} />
              </SvgIcon>
            </IconButton>
          </Tooltip>

          <Tooltip
            arrow
            title={<Typography>Attach selected call reports to user</Typography>}
          >
            <IconButton color="white" onClick={copyReports(parentTypesMap.user)}>
              <SvgIcon color="inherit">
                <Icon path={mdiAccountArrowLeftOutline} />
              </SvgIcon>
            </IconButton>
          </Tooltip>

          <Tooltip
            arrow
            title={<Typography>Delete selected call reports</Typography>}
          >
            <IconButton onClick={onDelete}>
              <DeleteIcon color="error" fontSize="inherit"/>
            </IconButton>
          </Tooltip>

          {/* TODO: add after BE updates */}
          {/*<Tooltip*/}
          {/*  arrow*/}
          {/*  title={<Typography>Download files from selected call reports</Typography>}*/}
          {/*>*/}
          {/*  <IconButton*/}
          {/*    color="primary"*/}
          {/*    onClick={() => {}}*/}
          {/*  >*/}
          {/*    <DownloadIcon />*/}
          {/*  </IconButton>*/}
          {/*</Tooltip>*/}
        </>
      )}

      {!isTablet && isTabPage && (
        <>
          <VerticalDivider verticalGutters={1} horizontalGutters={1} />

          <IconButton
            color={viewVariant === viewVariantsMap.list ? 'primary' : 'white'}
            onClick={toggleViewVariant(viewVariantsMap.list)}
          >
            <ViewListIcon />
          </IconButton>

          <IconButton
            edge="end"
            color={viewVariant === viewVariantsMap.grid ? 'primary' : 'white'}
            onClick={toggleViewVariant(viewVariantsMap.grid)}
          >
            <ViewModuleIcon />
          </IconButton>
        </>
      )}

      {isTabPage && isTablet && (
        <>
          <VerticalDivider verticalGutters={1} horizontalGutters={1} />
          {filterOpenButton}
        </>
      )}
    </>
  );
};
