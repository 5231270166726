import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormikField } from '../useFormikField';
import { DatePickerBase } from './DatePickerBase';

export const KeyboardDatePicker = forwardRef(({
  withoutFormik,
  name,
  minWidth,
  value: valueProp,
  outputFormat = 'X',
  format = 'MM/DD/YYYY',
  onChange = () => {},

  ...props
}, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField(name);
  const { fieldProps: [ field, , formikHelpers = {} ] = [] } = formikFieldProps;
  const [ value, setValue ] = useState(field?.value ? moment(field?.value, outputFormat) : null);
  const formikValueRef = useRef(field?.value);
  // React Hook Form
  const formContext = useFormContext();

  const handleChange = (date, value = null) => {
    const isValid = moment(value, format, true)?.isValid();
    const formattedDate = outputFormat === 'X' ? date?.startOf('day')?.unix() : date?.format(outputFormat);
    const newValue = isValid ? formattedDate : value;

    formikValueRef.current = newValue;
    formikHelpers?.setValue?.(newValue);
    formContext?.setValue(name, newValue, { shouldValidate: true });
    setValue(date);
    onChange(isValid ? formattedDate : null, date);
  };

  useEffect(() => {
    if (withoutFormik) {
      return;
    }

    const newValue = valueProp?.format?.(outputFormat);

    if (valueProp?.isValid() && (newValue !== formikValueRef.current)) {
      formikValueRef.current = newValue;
      formikHelpers.setValue(newValue);
    }
  }, [ valueProp ]);

  return (
    <DatePickerBase
      autoOk
      withoutFormik={withoutFormik}
      formikValue={formikValueRef.current}
      Component={MuiKeyboardDatePicker}
      name={name}
      outputFormat={outputFormat}
      format={format}
      placeholder="MM/DD/YYYY"
      value={valueProp || value}
      onChange={handleChange}

      {...props}

      ref={ref}
    />
  );
});
