import { useEffect, useState } from 'react';
import { Box, Chip } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import { stopPropagation } from '../../../helpers/dom';
import { useModal } from '../../ModalsProvider';
import { IconButton } from '../../IconButton';
import { useFormikField, TextField } from '../../FormField';
import { AppointmentsSelectModal } from './AppointmentsSelectModal';

export const AppointmentsField = ({ filter = {}, onChange = () => {}, ...props }) => {
  const { openModal } = useModal();
  const formikFieldProps = useFormikField(props.name);
  const { fieldProps: [ field, , formikHelpers = {} ] = [] } = formikFieldProps;
  const [ appointment, setAppointment ] = useState(field.value);

  const openAppointmentsModal = () => {
    openModal(AppointmentsSelectModal, {
      payload: {
        filter
      },

      onModalResolved: (appointment) => {
        formikHelpers.setValue(appointment.id);
        setAppointment(appointment);
        onChange(appointment);
      }
    });
  };

  const clear = () => {
    formikHelpers.setValue(null);
    setAppointment(null);
    onChange(null);
  };

  useEffect(() => {
    if (typeof(field.value) === 'object') {
      formikHelpers.setValue(field.value?.value);
      setAppointment(field.value?.data);
    }
  }, [ field.value ]);

  return (
    <TextField
      label="Appointment"
      InputProps={{
        inputComponent: ({ inputRef, ...props }) => (
          <Box flexGrow={1} {...(!appointment && props)}>
            {!!appointment &&
              <Chip
                tabIndex={-1}
                icon={<CalendarIcon />}
                label={moment.unix(appointment?.appointment_at).format('L LT')}
              />
            }
          </Box>
        ),

        endAdornment: (
          <>
            {!!appointment &&
              <IconButton size="small" title="Clear" onClick={stopPropagation(clear)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            }

            <IconButton size="small" edge="end" title="Search appointments">
              <SearchOutlinedIcon fontSize="small" />
            </IconButton>
          </>
        )
      }}
      onClick={openAppointmentsModal}

      {...props}
    />
  );
};
