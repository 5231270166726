export const styles = ({ spacing, breakpoints }) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    paddingTop: spacing(3),
    paddingLeft: spacing(4),
    paddingRight: spacing(3),

    [breakpoints.down('md')]: {
      padding: spacing(1.5),
      paddingRight: spacing(2)
    }
  }
});
