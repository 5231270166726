export const styles = ({
  spacing,
  palette,
  shape: { borderRadius },
  shadows
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: 0,
    boxShadow: shadows[2],
    flexGrow: 1,
    margin: spacing(0.1, 0.5, 0.4, 0.5),
    backgroundColor: palette.common.white,
    borderRadius
  }
});
