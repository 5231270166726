import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { mdiAccountHeartOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { CaseLink } from '../../../../../../../components/cases/CaseLink';
import { Date } from '../../../../../../../components/Date';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { SvgIcon } from '../../../../../../../components/SvgIcon';
import { UserLink } from '../../../../../../../components/UserLink';

export const PatientInfo = ({ claim }) => {
  return (
    <ContentCard
      fullHeight
      disableScrollbars
      variant="outlined"
      color="info"
      title="Patient Info"
      leftActions={[
        <Box mr={1}>
          <SvgIcon color="info"><Icon path={mdiAccountHeartOutline} /></SvgIcon>
        </Box>
      ]}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4}>
          <Typography color="textSecondary">Patient Name</Typography>
        </Grid>

        <Grid item xs={8}>
          <UserLink
            size="md"
            variant="h5"
            user={claim.patient}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography color="textSecondary">MRN</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {claim?.patient?.medical?.med_rec || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">Phone</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {claim?.patient?.phone_number || claim?.patient?.mobile_phone_number || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">DOB</Typography>
        </Grid>

        <Grid item xs={8}>
          <Date
            disableMoment
            variant="h5"
            iconFontSize="small"
            date={claim?.patient?.birthday && moment(claim?.patient?.birthday).format('L')}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">Gender</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {claim?.patient?.gender || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">Address</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            Patient Address
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">
            Case Name
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <CaseLink caseItem={claim?.case} />
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">Case Number</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {claim?.case?.file_number || '-'}
          </Typography>
        </Grid>
      </Grid>
    </ContentCard>
  );
};
