import { useContext, useEffect } from 'react';
import { useModal as useModalHook } from 'react-modal-hook';
import moment from 'moment';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import { FilePreviewModal } from '../../../../../app/Dashboard/files-common';
import { stopPropagation } from '../../../../../helpers/dom';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../ListRow';
import { IconButton } from '../../../../IconButton';
import { Loader } from '../../../../Loader';
import { useModal } from '../../../../ModalsProvider';
import { Popper } from '../../../../Popper';
import { CallReportsModal } from '../../../CallReportsModal';
import { CallReportsContext } from '../../../CallReportsProvider';
import { callerTypesMap, parentTypesMap } from '../../../CopyToModal/parentTypeOptions';
import { dispositionsLabelsMap } from '../../../dispositionsMap';
import { getCallReportGroupName } from '../../../getCallReportGroupName';
import { CallReportsMenu } from '../../CallReportsMenu';
import { Causer } from '../../Causer/Causer';
import { CausersList } from '../../CausersList';
import { UserContact } from '../../UserContact';
import { columnsWidths } from '../listConfig';

export const Row = ({
  item: callReport = {},
  isLoaded,
  recalculateHeight = () => {}
}) => {
  const { openModal } = useModal();
  const callReportsContext = useContext(CallReportsContext);
  const { disableCauser, selectedCallReportsIDs, toggleCallReportSelected } = callReportsContext || {};
  const isSelected = selectedCallReportsIDs?.indexOf(callReport.id) !== -1;
  const [ openFilesPreview, closeFilesPreview ] = useModalHook(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        disableFetch: true,
        file: callReport.files?.[0],
        singleView: true
      }}
    />
  ), [ callReport.files?.[0] ]);
  const callee = callReport?.callee || callReport?.toContact || callReport?.defaultView;
  const caller = callReport?.caller || callReport?.fromContact || callReport?.defaultView;

  const handleItemSelection = () => {
    toggleCallReportSelected(callReport);
  };

  const openGroupView = () => {
    openModal(CallReportsModal, {
      payload: {
        reports: callReport.group_calls
      }
    });
  };

  const openCallbackForView = () => {
    openModal(CallReportsModal, {
      payload: {
        reports: [ callReport.callback_for ]
      }
    });
  };

  const openCallbackView = () => {
    openModal(CallReportsModal, {
      payload: {
        reports: [ callReport.callback ]
      }
    });
  };

  useEffect(() => {
    if (isLoaded) {
      recalculateHeight();
    }
  }, [ isLoaded, recalculateHeight ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow px={2}>
      {!!callReportsContext &&
        <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />
      }

      <ListRowCell grow noWrap={!caller} flexBasis={columnsWidths.from}>
        <UserContact
          disableTransform={
            callReport?.caller_phone?.length <= 3 || callReport?.caller_phone === callerTypesMap.anonymous
          }
          user={caller}
          phone={callReport?.caller_phone}
        />
      </ListRowCell>

      <ListRowCell grow noWrap={!callee} flexBasis={columnsWidths.to}>
        <UserContact
          disableTransform={callReport?.callee_phone?.length <= 3 }
          user={callee}
          phone={callReport?.callee_phone}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.type}>
        {callReport.is_group_call ? callReport.group_calls?.length ? (
          <Link onClick={openGroupView}>
            {getCallReportGroupName(callReport.group_number)}
          </Link>
        ) : getCallReportGroupName(callReport.group_number) : 'Direct'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.disposition}>
        {!!callReport.files?.length
          ? 'Voicemail'
          : dispositionsLabelsMap[callReport.disposition] || '-'
        }

        {!!callReport.callback && (
          <>
            ,&nbsp;
            <Link onClick={openCallbackView}>Has callback</Link>
          </>
        )}

        {!!callReport.callback_for && (
          <>
            ,&nbsp;
            <Link onClick={openCallbackForView}>It's callback</Link>
          </>
        )}
      </ListRowCell>

      <ListRowCell column flexBasis={columnsWidths.startEndDate}>
        <Typography>
          {callReport.started_at ? moment(callReport.started_at).format('L LT') : '-'}
        </Typography>

        <Typography>
          {callReport.finished_at ? moment(callReport.finished_at).format('L LT') : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.duration}>
        {callReport.duration
          ? moment.duration(+callReport.duration, 'seconds').format('h[h] m[min] s[s]')
          : '-'
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.file}>
        {callReport.files?.length ? (
          <IconButton color="primary" component="span" onClick={openFilesPreview}>
            <VoicemailIcon />
          </IconButton>
        ) : '-'}
      </ListRowCell>

      {!disableCauser && (
        <ListRowCell flexBasis={columnsWidths.causer}>
          <Grid
            container
            spacing={0}
            justify="space-between"
            component={Box}
            mx={0}
            width="100% !important"
            wrap="nowrap"
          >
            <Grid item xs={8}>
              <Causer parent={callReport?.parentUsers?.[0]} parentType={parentTypesMap.user} />
              <Box pb={0.5} />
              <Causer parent={callReport?.parentCases?.[0]} parentType={parentTypesMap.case} />
            </Grid>

            <Grid item xs={4}>
              <CausersList caseCausers={callReport?.parentCases} userCausers={callReport?.parentUsers} />
            </Grid>
          </Grid>
        </ListRowCell>
      )}

      {!!callReportsContext &&
        <ListRowCell flexBasis={columnsWidths.actions}>
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                  <MoreVertIcon/>
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <CallReportsMenu callReport={callReport} onClose={handleClose} />
            )}
          </Popper>
        </ListRowCell>
      }
    </ListRow>
  );
};
