export const styles = ({ spacing, palette }) => ({
  listItem: {
    paddingRight: spacing(8.5),
    wordWrap: 'break-word',

    '&:hover': {
      background: palette.primary
    }
  },

  listItemText: {
    fontWeight: 'bold'
  },

  secondaryAction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    paddingTop: spacing(2),
    paddingLeft: spacing(6),
    minWidth: spacing(8)
  },

  controlLabel: {
    marginRight: 0
  },

  secondaryActionActive: {
    color: palette.primary.contrastText
  }
});
