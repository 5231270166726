export const styles = ({ spacing, palette: { background } }) => ({
  root: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)'
  },

  toolbar: {
    display: 'flex',
    paddingTop: spacing(),
    paddingBottom: spacing(),
    paddingLeft: spacing(3),
    background: background.primary
  },

  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  form__textField: {
    width: '376px'
  },

  form__selectField: {
    marginLeft: spacing(2),
    marginRight: spacing(2),
    width: '200px'
  }
});
