import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [ types.FETCH_USER_REGISTRATION_REQUEST ]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_USER_REGISTRATION_SUCCESS]: (state, payload) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      statistics: payload
    };
  },

  [ types.FILTER_USER_REGISTRATION ]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  }
});
