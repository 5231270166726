import { useContext } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import CalendarIcon from '@material-ui/icons/Event';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../../helpers/dom';
import { UserPreviewModal } from '../../../../../../components/users/UserPreviewModal';
import { AppointmentsContext, AppointmentViewModal } from '../../../../../../components/appointments';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Popper } from '../../../../../../components/Popper';
import { Loader } from '../../../../../../components/Loader';
import { ConsultationsMenu } from '../../ConsultationsMenu';
import { columnsWidths, minRowHeight } from '../List';

export const Row = ({ item: appointment = {}, isLoaded }) => {
  const { openModal } = useModal();
  const { reloadAppointments } = useContext(AppointmentsContext);

  const openPreviewModal = () => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: appointment.id,
        onAppointmentUpdate: reloadAppointments,
        onAppointmentDelete: reloadAppointments
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow button minHeight={minRowHeight} onClick={openPreviewModal}>
      <ListRowCell flexBasis={columnsWidths.date}>
        <Box mr={1}>
          <CalendarIcon fontSize="small" />
        </Box>

        {moment.unix(appointment.appointment_at).format('L')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.time}>
        <Box mr={1}>
          <AlarmIcon fontSize="small" />
        </Box>

        {moment.unix(appointment.appointment_at).format('LT')}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.apptBook}>
        <Typography noWrap>
          {get(appointment.appointment_book, 'appointment_book_name', '-')}
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.provider}>
        <UserLink
          noWrap
          size="md"
          variant="inherit"
          user={appointment?.appointment_book?.provider}
          onClick={stopPropagation(openUserPreview(appointment?.appointment_book?.provider?.id))}
        />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.office}>
        <Typography noWrap>{appointment?.office?.name}</Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.insuranceType}>
        <Typography noWrap>{appointment?.patient_insurance?.type_insurance}</Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.insuranceID}>
        <Typography noWrap>{appointment?.patient_insurance?.insuranceId}</Typography>
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <ConsultationsMenu appointment={appointment} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
