import { Yup } from '../../../../../utils/validation';
// Cyclic dependency, dont shortening path
import {
  validationSchema as apptValidationSchema
} from '../../../../../components/appointments/AppointmentForm/validationSchema';

export const validationSchema = apptValidationSchema.shape({
  immunizations: Yup.array().of(Yup.object().shape({
    immunization_id: Yup.mixed().nullable().required(),
    note: Yup.string().nullable().min(1).max(1000)
  })).required()
});
