import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  SvgIcon,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { mdiDraw } from '@mdi/js';
import { Icon } from '@mdi/react';
import { FileTypeIcon } from '../../../../app/Dashboard/files-common';
import { KeyboardDateTimePicker, TextField } from '../../../FormField';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { styles } from '../../../UserSignature/styles';
import { useModal } from '../../../ModalsProvider';
import { Divider } from '../../../Divider';
import { Button } from '../../../Button';
import { Date } from '../../../Date';

const useStyles = makeStyles(styles);

export const StaffSignature = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { values, setFieldValue } = useFormikContext();
  const isSignature = !!values?.staff_signature;
  const currentUser = useSelector(({ profile }) => profile.user);
  const date = values?.staff_signature?.date || moment().unix();

  const handleSignForm = () => {
    if (currentUser.signature) {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          setFieldValue('staff_signature', currentUser?.signature || null);
          setFieldValue('staff_sign_id', currentUser?.signature?.id || null);
        }
      });
    } else {
      enqueueSnackbar('You don\'t have a signature yet', { variant: 'warning' });
    }
  };

  return (
    <Grid container spacing={isMobile ? 2 : 3} alignItems="flex-end">
      <Grid item xs={12}>
        <Divider gutter={5} disableBottomGutter />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">FOR STAFF ONLY:</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h5">Temperature</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          type="number"
          label="Enter value"
          name="temperature"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                &deg;F
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 6}>
        <Typography variant="h5">Date / Time</Typography>
      </Grid>

      <Grid item xs={isMobile ? 12 : 6}>
        <KeyboardDateTimePicker
          disableFuture
          name="date"
          label="Date / Time"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          Initials
        </Typography>
      </Grid>

      <Grid item md={6} xs={12}>
        <Box className={classes.signature}>
          {!!isSignature ?
            <img
              src={values?.staff_signature?.fax_signature_file || values?.staff_signature?.url}
              alt="signature"
            />
            :
            <Box display="flex" alignItems="center" justifyContent="center" height="165px">
              <FileTypeIcon className={classes.signaturePreviewIcon} file={false} />
            </Box>
          }
        </Box>
      </Grid>

      <Grid item md={6} xs={12}>
        {!isSignature ?
          <Button
            size="small"
            color="primary"
            startIcon={<SvgIcon><Icon path={mdiDraw} /></SvgIcon>}
            onClick={handleSignForm}
          >
            Sign
          </Button>
          :
          <Date date={date} />
        }
      </Grid>
    </Grid>
  );
};
