import { useContext, useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ErrorBoundary } from '../../../../../../../components/ErrorBoundary';
import { Loader } from '../../../../../../../components/Loader';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { ExpensesContext } from '../../../Expenses';
import { Card } from './Card';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Grid = ({ indexIDs, onExpenseSelect }) => {
  const classes = useStyles();
  const {
    isFetched,
    expenses,
    meta,
    resetExpenses
  } = useContext(ExpensesContext);

  useEffect(() => {
    resetExpenses();
  }, []);

  return (
    <Box p={2}>
      <Loader loading={!isFetched} render={
        () => !expenses?.length ? (
          <Typography align="center">No expenses found</Typography>
        ) : (
          <>
            <div className={classes.cards}>
              {expenses.map((expense) => (
                <ErrorBoundary key={expense.id}>
                  <Card
                    indexIDs={indexIDs}
                    expense={expense}
                    onExpenseSelect={onExpenseSelect}
                  />
                </ErrorBoundary>
              ))}
            </div>

            <TablePagination pagination={meta} onChange={resetExpenses} />
          </>
        )}
      />
    </Box>
  );
};
