import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { Divider } from '../../../../../../components/Divider';
import { TooltipTitle } from '../../../../../../components/Tooltip/TooltipTitle';
import { Label } from '../../../../../../components/Label';
import { Date } from '../../../../../../components/Date';

export const TooltipContent = ({ index, claim, length }) => {
  const theme = useTheme();

  return (
    <TooltipTitle
      itemTitle={'Claim # ' + (claim?.number || '-')}
      flexDirection="column"
    >
      <Grid container spacing={1} component={Box} minWidth={250}>
        <Grid item xs={2}>
          <Typography noWrap variant="body2">DOS</Typography>
        </Grid>

        <Grid item xs={5}>
          <Date noWrap disableMoment date={claim.date} variant="body2" />
        </Grid>

        <Grid item xs={5}>
          <Label
            color={theme.palette.lightgreen}
            label={<Typography noWrap variant="subtitle2">{claim?.status}</Typography>}
          />
        </Grid>

        {index !== length &&
          <Grid item xs={12}>
            <Divider gutter={0.5} />
          </Grid>
        }
      </Grid>
    </TooltipTitle>
  );
};
