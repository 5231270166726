import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Box, Tooltip, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { ClaimsContext, CreateClaimModal } from '../../../../../../components/claims';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Fab } from '../../../../../../components/Fab';
import { IconButton } from '../../../../../../components/IconButton';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { UserContext } from '../../../../Members/User/UserProvider';

const MobileWrapper = ({ isMobile, children }) => !isMobile ? children : (
  <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
    {children}
  </Box>
);

export const ActionsBar = ({ isTablet, isFixedCase, filterOpenButton, creationInitialValues = {} }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { resetClaims } = useContext(ClaimsContext);
  const match = useRouteMatch();
  const { openModal } = useModal();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const currentUser = useSelector(({ profile }) => profile.user);
  const user = userContext ? userContext?.user : currentUser;

  const addClaim = () => {
    if (match.url === '/billing/claims') {
      history.push('/billing/claims/create');

      return;
    }

    openModal(CreateClaimModal, {
      payload: {
        isFixedPatient: true,
        isFixedCase,
        initialValues: {
          patient_id: user?.id,
          patient: user,

          ...creationInitialValues
        }
      },

      onModalResolved: () => {
        resetClaims();
      }
    });
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'flex-end' : 'center'}
    >
      <MobileWrapper isMobile={isTablet}>
        {!hasRole(rolesMap.patient, rolesMap.client) &&
          <Fab
            variant="extended"
            color="primary"
            size={isMobile ? 'small' : 'large'}
            startIcon={<AddIcon />}
            onClick={addClaim}
          >
            Add claim
          </Fab>
        }

        {isTablet && filterOpenButton}
      </MobileWrapper>

      {!isTablet &&
        <Box ml="auto">
          <Tooltip title="List view">
            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Grid view">
            <IconButton
              edge="end"
              color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.grid)}
            >
              <ViewModuleIcon />
            </IconButton>
          </Tooltip>
        </Box>
      }
    </Box>
  );
};
