import { useSelector } from 'react-redux';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { fieldsKeysMap } from '../../../../components/appointments/FiltersBar/fieldsKeysMap';
import { Appointments as AppointmentsComponent } from '../../AppointmentsPage/Appointments';

const hiddenFilterFields = [
  fieldsKeysMap.patients
];

export const Appointments = () => {
  const currentUser = useSelector(({ profile }) => profile.user);

  return (
    <AppointmentsComponent
      patient={currentUser}
      filter={{ patients: [ currentUser.id ] }}
      hiddenFilterFields={hiddenFilterFields}
      filterKey={filtersKeysMap.members_appointments + currentUser.id}
    />
  );
};
