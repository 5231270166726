import { Box } from '@material-ui/core';
import { paymentFieldsMap } from './fieldsMaps/paymentFieldsMap';
import { WidgetActivity } from './WidgetActivity';

export const Payment = ({
  isDeleted = false,
  isNew = false,
  payload
}) => {

  if (isDeleted) {
    return 'Removed payment';
  }

  return (
    <Box display="flex" flexDirection="column">
      {isNew && (
        <Box component="span" display="flex" alignItems="center">
          Added new payment
        </Box>
      )}

      <WidgetActivity {...payload?.properties} fieldsMap={paymentFieldsMap} />
    </Box>
  );
};
