import * as filesApi from '../../../../api/files';
import * as filesActionsTypes from '../../../../app/Dashboard/files-common/FilesContext/types';
import { setFilesLastGlobalAction } from '../../../globalActions';
import {
  filesFetchRequest,
  filesFetchSuccess,
  setFilesFilter,
  addFile
} from './actions';

export const fetchFiles = () => (dispatch, getStore) => {
  const { recentlyOpenedFiles: { filter } } = getStore();

  dispatch(filesFetchRequest());

  return filesApi.fetchFiles({
    params: {
      only_opened: 1,
      order_by: 'opened_at',

      ...filter
    }
  }).then((data) => {
    dispatch(filesFetchSuccess(data));

    return data;
  });
};

export const applyFilesFilter = (payload) => (dispatch) => {
  dispatch(setFilesFilter(payload));

  return dispatch(fetchFiles());
};

export const addFileToRecent = (file) => (dispatch) => {
  return filesApi.openFile(file).then((data) => {
    dispatch(addFile(data));
    dispatch(setFilesLastGlobalAction({ type: filesActionsTypes.UPDATE_FILE, payload: data }));
  });
};

export const createFile = (data) => (dispatch) => {
  return filesApi.uploadFile(data).then(({ data }) => {
    dispatch(addFile(data));

    return data;
  }).catch((error) => {
    throw error.data;
  });
};
