import { useContext, useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { arrivalTypes } from '../../../../../../../components/appointments';
import { Button } from '../../../../../../../components/Button';
import { ColorPreview } from '../../../../../../../components/ColorPreview';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../components/Loader';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { CodesMenu } from '../../../CodesMenu';
import { ArrivalStatusesContext } from '../../ArrivalStatusesContext';
import { minRowHeight, columnsWidthsLg, columnsWidthsSm } from '../List';

export const Row = ({ item: arrivalStatus = {}, baseUrl, isLoaded, recalculateHeight }) => {
  const arrivalStatusesContext = useContext(ArrivalStatusesContext);
  const [ isBusy, setIsBusy ] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.tablet));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;
  const Wrapper = isMobile ? Box : Fragment;
  const wrapperProps = isMobile ? {
    display: 'flex',
    alignItems: 'center'
  } : {};

  const toggleActiveArrivalStatus = () => {
    const data = { ...arrivalStatus, is_active: !arrivalStatus.is_active };

    setIsBusy(true);

    arrivalStatusesContext.toggleActiveArrivalStatus(data).then(() => {
      setIsBusy(false);
    });
  };

  const deleteArrivalStatus = () => {
    setIsBusy(true);

    arrivalStatusesContext.deleteArrivalStatus(arrivalStatus).then(() => {
      setIsBusy(false);
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ recalculateHeight, isLoaded ]);

  return (
    <Loader p={isBusy ? 0 : 2} surface={isBusy} fullWidth loading={!isLoaded || isBusy} render={
      () => (
        <ListRow disabled={!arrivalStatus.is_active} minHeight={minRowHeight}>
          <ListRowCell grow actions noWrap column={isMobile} flexBasis={columnsWidths.code}>
            <Wrapper {...wrapperProps}>
              <ColorPreview color={arrivalTypes[arrivalStatus.arrival_type].color} />

              {isMobile && <>&nbsp;&nbsp;</>}
              <span>
                {arrivalStatus.code}
              </span>
            </Wrapper>

            {isMobile && (
              <Typography noWrap variant="body2">
                {arrivalStatus.description}
              </Typography>
            )}
          </ListRowCell>

          {!isMobile && (
            <ListRowCell flexBasis={columnsWidths.description}>
              {arrivalStatus.description}
            </ListRowCell>
          )}

          <ListRowCell flexBasis={columnsWidths.arrivalType}>
            {arrivalStatus.arrival_type}
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.ordering}>
            {arrivalStatus.ordering}
          </ListRowCell>

          <ListRowCell actions noDisable justifyContent="flex-end" flexBasis={columnsWidths.actions}>
            {isMobile ? (
              <CodesMenu
                disabled={!!arrivalStatus.is_system}
                isActive={arrivalStatus.is_active}
                url={`${baseUrl}/arrival-statuses/${arrivalStatus.id}/edit`}
                onToggle={toggleActiveArrivalStatus}
                onDelete={deleteArrivalStatus}
              />
            ) : (
              <>
                <Tooltip
                  isExistTooltip={arrivalStatus.is_system}
                  placement="bottom"
                  title="This is a system status, you cannot update or delete it"
                >
                  <div>
                    <Button
                      disabled={arrivalStatus.is_system}
                      variant="contained"
                      color={arrivalStatus.is_active ? 'warning' : 'success'}
                      size="small"
                      onClick={toggleActiveArrivalStatus}
                    >
                      {arrivalStatus.is_active ? 'Inactive' : 'Active'}
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip
                  isExistTooltip={arrivalStatus.is_system}
                  placement="bottom"
                  title="This is a system status, you cannot update or delete it"
                >
                  <div>
                    <Button
                      disabled={arrivalStatus.is_system}
                      component={Link}
                      to={`${baseUrl}/arrival-statuses/${arrivalStatus.id}/edit`}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      Edit
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip
                  isExistTooltip={arrivalStatus.is_system}
                  placement="bottom"
                  title="This is a system status, you cannot update or delete it"
                >
                  <div>
                    <Button
                      disabled={arrivalStatus.is_system}
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={deleteArrivalStatus}
                    >
                      Delete
                    </Button>
                  </div>
                </Tooltip>
              </>
            )}
          </ListRowCell>
        </ListRow>
      )}
    />
  );
};
