export const dispositionsMap = {
  answered: 'ANSWERED',
  no_answer: 'NO ANSWER',
  group_missed: 'GROUP_MISSED',
  congestion: 'CONGESTION',
  failed: 'FAILED',
  busy: 'BUSY'
};

export const dispositionsLabelsMap = {
  [dispositionsMap.answered]: 'Answered',
  [dispositionsMap.no_answer]: 'Missed',
  [dispositionsMap.group_missed]: 'Group Missed',
  [dispositionsMap.congestion]: 'Congestion',
  [dispositionsMap.failed]: 'Failed',
  [dispositionsMap.busy]: 'Busy'
};
