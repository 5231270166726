import { useFormikContext } from 'formik';
import cn from 'classnames';
import { makeStyles, FormControlLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Checkbox } from '../../../FormField';
import { MiddlePain } from '../MiddlePain';
import { BackPain } from '../BackPain';
import { NeckPain } from '../NeckPain';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PainForm = () => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <div className={classes.mainContent}>
        <div className={cn(classes.content, classes.content__header)}>
          <Typography variant={isSmallScreen ? 'h4' : 'h6'} className={classes.mainTitle}>
            Where is your pain?
          </Typography>

          <div className={classes.contentInfo}>
            <FormControlLabel
              label={
                <Typography variant="h5" className={classes.formLabel}>
                  If pain in neck
                </Typography>
              }
              control={<Checkbox color="primary" name="forms.pain_in_neck.is_neck"/>}
            />

            <FormControlLabel
              label={
                <Typography variant="h5" className={classes.formLabel}>
                  If pain in middle back
                </Typography>
              }
              control={
                <Checkbox
                  color="primary"
                  name="forms.middle_back.is_middle_back"
                />
              }
            />

            <FormControlLabel
              label={
                <Typography variant="h5" className={classes.formLabel}>
                  If pain in low back
                </Typography>
              }
              control={<Checkbox color="primary" name="forms.pain_in_low_back.is_low_back"/>}
            />
          </div>
        </div>
      </div>

      {values?.forms?.pain_in_neck?.is_neck && (
        <div className={classes.content}>
          <NeckPain/>
        </div>
      )}

      {values?.forms?.middle_back?.is_middle_back && (
        <div className={classes.content}>
          <MiddlePain />
        </div>
      )}

      {values?.forms?.pain_in_low_back?.is_low_back && (
        <div className={classes.content}>
          <BackPain/>
        </div>
      )}
    </>
  );
};
