export const styles = ({
  spacing,
  palette: { grey },
  typography: { pxToRem },
  transitions
}) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    '&, &:active, &:focus': {
      background: 'none'
    }
  },

  root_main: {
    borderRadius: pxToRem(4),

    '&:hover': {
      background: grey[100],

      '& > $menuButton': {
        opacity: 1
      }
    }
  },

  root_selected: {
    '&, &:focus': {
      textDecoration: 'line-through'
    }
  },

  root_hidden: {
    display: 'none'
  },

  title: {
    display: 'flex',
    width: '100%'
  },

  title_edit: {
    alignItems: 'flex-start'
  },

  content: {
    paddingTop: spacing(),
    wordBreak: 'break-word'
  },

  checkbox: {
    alignSelf: 'flex-start',
    color: grey[400],
    padding: spacing(0.75)
  },

  menuButton: {
    opacity: 0,
    transition: transitions.create()
  },

  menuButton_edit: {
    opacity: 1
  }
});
