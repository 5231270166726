import { Box } from '@material-ui/core';
import { UsersActivity } from '../fields';
import { taskFieldsMap } from '../fields/fieldsMaps';
import { WidgetActivity } from '../fields/WidgetActivity';

export const TaskActivity = ({
  isDeleted = false,
  isNew = false,
  fieldLabel,
  activityLabel,
  payload
}) => {
  const properties = payload?.task?.properties || payload?.properties;

  if (isDeleted) {
    return 'Removed Task';
  }

  return (
    <>
      {isNew && (
        <Box component="span" display="flex" alignItems="center">
          Created New Task
        </Box>
      )}

      {properties?.users ? (
        <UsersActivity users={properties?.users} />
      ) : (
        <WidgetActivity
          {...properties}
          fieldLabel={fieldLabel}
          activityLabel={activityLabel}
          fieldsMap={taskFieldsMap}
        />
      )}
    </>
  );
};
