export const initialState = {
  isFetching: false,
  isFetched: false,
  isFirstLoaded: false,
  filter: {
    page: 1,
    per_page: 15,
    case_id: null,
    patient_id: null,
    tags: []
  },
  pagination: {
    total: 0
  },
  messages: [],
  selectedMessages: [],
  messageItem: null
};
