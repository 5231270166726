import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_CALL_REPORTS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_CALL_REPORTS_SUCCESS]: (
    { filter, callReports, ...state },
    { data: newCallReport, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      callReports: (page > 1
        ? callReports.filter(({ id }) => {
          return !newCallReport.find((loadedClaim) => id === loadedClaim.id);
        }).concat(newCallReport)
        : newCallReport
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_CALL_REPORTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_CALL_REPORT]: (state, callReport) => {
    return {
      ...state,

      callReports: state.callReports.map((item) => {
        return item.id === callReport.id ? { ...item, ...callReport } : item;
      })
    };
  },

  [types.DELETE_CALL_REPORT]: (state, callReportID) => {
    const { pagination, filter, callReports, selectedCallReportsIDs } = state;
    const filteredCallReports = callReports.filter(({ id }) => callReportID !== id);

    if (filteredCallReports.length === callReports.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((callReports.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      callReports: filteredCallReports,
      selectedCallReportsIDs: selectedCallReportsIDs.filter((id) => callReportID !== id)
    };
  },

  [types.ADD_CALL_REPORTS_TO_SELECTED]: ({
    selectedCallReports,
    selectedCallReportsIDs,

    ...state
  }, callReports) => {
    return {
      ...state,

      selectedCallReports: selectedCallReports.concat(
        callReports.filter(({ id }) => !selectedCallReportsIDs.includes(id))
      ),
      selectedCallReportsIDs: [ ...new Set(selectedCallReportsIDs.concat(callReports.map(({ id }) => id))) ]
    };
  },

  [types.DELETE_CALL_REPORTS_FROM_SELECTED]: ({ selectedCallReportsIDs, ...state }, callReportsIDs) => {
    return {
      ...state,

      selectedCallReportsIDs: selectedCallReportsIDs.filter((id) => callReportsIDs.indexOf(id) === -1)
    };
  }
});
