import { Link as RouterLink, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { IconButton, makeStyles, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { itemsRoutesByModels } from '../../../../../../dataMaps/apiModels';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({
  id,
  title,
  overdraft,
  openDrawer,
  onDrawerUpdate = () => {},

  ...props
}) => {
  const history = useHistory();
  const classes = useStyles();

  const handleDrawerOpen = () => {
    onDrawerUpdate(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.actionGroup}>
        {!props?.handleModalReject &&
          <IconButton
            color="primary"
            onClick={handleDrawerOpen}
            className={cn(classes.menuButton, openDrawer && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        }

        <IconButton
          color="primary"
          onClick={props?.handleModalReject || history.goBack}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      </div>

      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to={overdraft ? itemsRoutesByModels.overdraft(id) : `/billing/payments/edit/${id}`}
      >
        {overdraft ? 'Apply to Inv.' : 'Edit'}
      </Button>
    </div>
  );
};
