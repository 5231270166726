export const styles = ({ spacing }) => ({
  logo: {
    padding: spacing(1.25)
  },

  logo_full: {
    padding: spacing(2)
  },

  logo__icon: {
    width: '100%',
    maxWidth: 172,
    margin: 'auto'
  }
});

export const getOverriderStyles = ({ zIndex }) => ({
  input: (provided) => ({
    ...provided,

    color: 'rgba(255, 255, 255, 0.7)'
  }),

  menuPortal: (provided) => ({
    ...provided,
    zIndex: zIndex.modal
  })
});
