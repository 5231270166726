export const styles = ({
  spacing,
  palette: { grey, divider, ...palette },
  typography: { pxToRem, fontWeightRegular, fontWeightBold },
  breakpoints
}) => ({
  root: {
    borderBottom: `1px solid ${divider}`
  },

  rootSeen: {
    '& $subject': {
      fontWeight: fontWeightRegular
    }
  },

  rootChecked: {
    borderColor: palette.primary.main
  },

  buttons: {
    alignItems: 'center'
  },

  newMessageIcon: {
    marginRight: spacing(0.5)
  },

  subject: {
    fontSize: pxToRem(12),
    fontWeight: fontWeightBold,
    color: palette.text.secondary
  },

  info: {
    textAlign: 'right',
    color: grey[500]
  },

  main: {
    padding: spacing(1, 5, 4, 5),
    borderBottom: `1px solid ${palette.divider}`,

    [breakpoints.down('md')]: {
      padding: spacing()
    }
  }
});
