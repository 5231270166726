import { Yup } from '../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  reason_for_consultation: Yup.string().nullable(),
  history_of_present_illness: Yup.string().nullable(),
  past_medical_history: Yup.string().nullable(),
  personal_history: Yup.string().nullable(),
  medications: Yup.array().nullable().of(Yup.object().shape({
    medication_name: Yup.string().nullable(),
    dosage: Yup.string().nullable(),
    frequency: Yup.string().nullable(),
    how_i_take_it: Yup.string().nullable()
  })),
  allergies: Yup.array().nullable().of(Yup.object().shape({
    name: Yup.string().nullable()
  })),
  current_symptoms_neck: Yup.array().nullable().of(Yup.object().shape({
    text: Yup.string().nullable(),
    location: Yup.string().nullable(),
    character_of_pain: Yup.string().nullable(),
    radiation: Yup.string().nullable(),
    weakness: Yup.string().nullable(),
    stiffness: Yup.string().nullable(),
    tingling_and_numbness: Yup.string().nullable(),
    note: Yup.string().nullable()
  })),
  current_symptoms_back: Yup.array().nullable().of(Yup.object().shape({
    text: Yup.string().nullable(),
    location: Yup.string().nullable(),
    character_of_pain: Yup.string().nullable(),
    radiation: Yup.string().nullable(),
    weakness: Yup.string().nullable(),
    stiffness: Yup.string().nullable(),
    tingling_and_numbness: Yup.string().nullable(),
    note: Yup.string().nullable()
  })),
  walking: Yup.string().nullable(),
  standing: Yup.string().nullable()
});
