import { useContext, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Loader } from '../../../../Loader';
import { PageBody } from '../../../../Page';
import { LoginSessionsProvider } from '../../../sessions';
import { LoginAccountsContext } from '../../LoginAccountsProvider';
import { Account } from './Account';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Accounts = () => {
  const classes = useStyles();
  const {
    isFetched,
    service,
    meta,
    loginAccounts,
    resetLoginAccounts
  } = useContext(LoginAccountsContext);

  useEffect(() => {
    resetLoginAccounts({ login_queue_id: service.id });
  }, []);

  return (
    <Loader loading={!isFetched} p={2}>
      {meta.total ? (
        <PageBody fullHeight className={classes.root}>
          {loginAccounts.map((account) => (
            <LoginSessionsProvider key={account.id} account={account}>
              <Account account={account} />
            </LoginSessionsProvider>
          ))}
        </PageBody>
      ) : (
        <Typography align="center" className={classes.notFound}>
          Accounts not found
        </Typography>
      )}
    </Loader>
  );
};
