export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    height: '100%',
    padding: spacing(2),

    [breakpoints.down('xs')]: {
      padding: 0
    }
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column'
  }
});
