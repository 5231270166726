import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { get } from 'lodash';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as templatesApi from '../../../../../../api/profile';
import { Loader } from '../../../../../../components/Loader';
import { Page, PageBody, PageHeader, PageHeaderActions } from '../../../../../../components/Page';
import { sectionsOptions, sectionsTitleMap } from '../sectionsOptions';
import { Header } from './Header';
import { MobileHeader } from './MobileHeader';
import { ReportSections } from './ReportSections';
import { validationSchema } from './validationSchema';

export const Edit = () => {
  const params = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [ initialValues, setInitialValues ] = useState(null);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    templatesApi.fetchTemplate(params.id).then((data) => {
      const sections = [];

      Object.keys(sectionsTitleMap).forEach((item) => {
        const existSection = sections?.find((item) => item?.value === sectionsTitleMap[item]);

        if (!existSection && get(data, sectionsTitleMap[item]) !== undefined) {
          sections.push(sectionsOptions.find(({ value }) => value === sectionsTitleMap[item]));
        }
      });

      setInitialValues({ ...data, sections });
    });
  }, []);

  const updateTemplate = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return templatesApi.updateTemplate(values).then(() => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.goBack();
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  return (
    <Loader p={3} loading={!initialValues} render={
      () => (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={updateTemplate}
          validationSchema={validationSchema}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <Page component="form" noValidate onSubmit={handleSubmit}>
              <PageHeader disableBorderBottom>
                <PageHeaderActions disableSpacing endPosition>
                  <Box pr={2}>
                    <Button
                      size={isTablet ? 'small' : 'medium'}
                      color="primary"
                      onClick={history.goBack}
                    >
                      Cancel
                    </Button>
                  </Box>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        size={isTablet ? 'small' : 'medium'}
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                  />
                </PageHeaderActions>
              </PageHeader>

              {!isTablet && <Header />}

              <PageBody fullHeight>
                <Box py={isTablet ? 1 : 2} pb={8}>
                  {isTablet && <MobileHeader />}

                  <Alert severity="info">
                    <Typography>
                      You have chosen <strong>{values?.name}</strong> template, if you need additional
                      sections then select them from the list dropdown.
                    </Typography>
                  </Alert>

                  <ReportSections />
                </Box>
              </PageBody>
            </Page>
          )}
        </Formik>
      )}
    />
  );
};
