export const spinalExaminationMap = {
  cervical_region: 'Cervical Region',
  spinous_processes: 'Spinous Processes',
  paraspinous_muscles: 'Paraspinous Muscles',
  trapezius_muscles: 'Trapezius Muscles'
};

export const specialTestsMap = {
  cervical_compression_test: 'cervical_compression_test',
  cervical_facet_loading_maneuver: 'cervical_facet_loading_maneuver',
  cervical_facet_tenderness: 'cervical_facet_tenderness',
  straight_leg_raising_test: 'straight_leg_raising_test',
  lumbar_facet_loading_maneuver: 'lumbar_facet_loading_maneuver',
  lumbar_facet_tenderness: 'lumbar_facet_tenderness',
  sacroiliac_tenderness: 'sacroiliac_tenderness'
};

export const cervicalSpecialTestsNamesMap = {
  [specialTestsMap.cervical_compression_test]: 'Cervical Compression Test:',
  [specialTestsMap.cervical_facet_loading_maneuver]: 'Cervical Facet Loading Maneuver:',
  [specialTestsMap.cervical_facet_tenderness]: 'Cervical Facet Tenderness:'
};

export const lumbarSpecialTestsNamesMap = {
  [specialTestsMap.straight_leg_raising_test]: 'Straight Leg Raising Test:',
  [specialTestsMap.lumbar_facet_loading_maneuver]: 'Lumbar Facet Loading Maneuver:',
  [specialTestsMap.lumbar_facet_tenderness]: 'Lumbar Facet Tenderness:',
  [specialTestsMap.sacroiliac_tenderness]: 'Sacroiliac Tenderness:'
};

export const rangeMotionMap = {
  flexion: 'Flexion',
  extension: 'Extension',
  right_bending: 'Right Bending',
  left_bending: 'Left Bending',
  right_rotation: 'Right Rotation',
  left_rotation: 'Left Rotation'
};

export const motorStrengthMap = {
  deltoids: 'Deltoids',
  rotator_cuffs: 'Rotator Cuffs',
  biceps: 'Biceps',
  triceps: 'Triceps',
  supinators: 'Supinators',
  pronator_teres: 'Pronator Teres',
  wrist_extensors: 'Wrist Extensors',
  wrist_flexors: 'Wrist Flexors',
  hand_intrinsics: 'Hand Intrinsics'
};

export const reflexLowerMap = {
  biceps: 'Biceps',
  triceps: 'Triceps',
  brachioradialis: 'Brachioradialis'
};

export const lumbarMotorStrengthMap = {
  quadriceps: 'Quadriceps',
  hamstrings: 'Hamstrings',
  gastrocsoleus: 'Gastrocsoleus',
  tibialis_anterior: 'Tibialis Anterior',
  extensor_hallucis_longus: 'Extensor Hallucis Longus',
  common_toe_extensors: 'Common Toe Extensors',
  iliopsoas: 'Iliopsoas',
  gluteus_medius: 'Gluteus Medius'
};

export const lumbarReflexLowerMap = {
  knee_jerks: 'Knee Jerks',
  ankle_jerks: 'Ankle Jerks'
};
