import { useState, useEffect } from 'react';
import moment from 'moment';
import { Box, Dialog, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import * as authApi from '../../../api/appointments/authorization';
import { itemsRoutesByModels } from '../../../dataMaps/apiModels';
import { ModalContainer, ModalHeader, ModalBody } from '../../Modal';
import { ReadonlyCheckbox } from '../../ReadonlyCheckbox';
import { UserLink } from '../../UserLink';
import { Loader } from '../../Loader';
import { Label } from '../../Label';
import { getExpirationDateColor } from '../AuthorizationsPage/utils';
import { Procedures } from './Procedures';

export const AuthorizationViewModal = ({
  DialogProps,
  payload: {
    authorization: authorizationProp,
    authorizationID
  },
  handleModalReject
}) => {
  const [ authorization, setAuthorization ] = useState(authorizationProp);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentDate = moment().unix();
  const isExpired = currentDate - authorization.expiration_date >= 0;

  useEffect(() => {
    if (authorizationID) {
      authApi.fetchAuthorization(authorizationID).then(setAuthorization);
    }
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <Loader p={2} loading={!authorization} render={
          () => (
            <>
              <ModalHeader onClose={handleModalReject}>
                Authorization # {authorization.authorization} {isExpired ? '- Expired' : ''}
              </ModalHeader>

              <ModalBody disablePaddings={isMobile}>
                <Grid container spacing={2} component={Box} px={isMobile ? 2 : 0}>
                  <Grid item container spacing={2} alignItems="center">
                    <Grid item xs>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography noWrap color="textSecondary">
                            Referring Physician
                          </Typography>
                        </Grid>

                        <Grid item>
                          <UserLink
                            size="sm"
                            user={authorization.referring_physician}
                            path={itemsRoutesByModels.user(authorization.referring_physician_id)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography noWrap color="textSecondary">
                            Is Pending
                          </Typography>
                        </Grid>

                        <Grid item>
                          <ReadonlyCheckbox checked={authorization.is_pending} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography noWrap color="textSecondary">
                            Effective Date
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography noWrap>
                            {authorization.effective_date
                              ? moment.unix(authorization.effective_date).format('L')
                              : '-'
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography noWrap color="textSecondary">
                            Expiration Date
                          </Typography>
                        </Grid>

                        <Grid item>
                          {authorization.effective_date && authorization.expiration_date ? (
                            <Label
                              label={moment.unix(authorization.expiration_date).format('L')}
                              color={
                                getExpirationDateColor(authorization.effective_date, authorization.expiration_date)
                              }
                            />
                          ) : (
                            <Typography>
                              {authorization.expiration_date
                                ? moment.unix(authorization.expiration_date).format('L')
                                : '-'
                              }
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Box mt={3}>
                  <Procedures procedures={authorization.procedures} />
                </Box>
              </ModalBody>
            </>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
