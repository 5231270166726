export const styles = ({ spacing, palette, shape: { borderRadius } }) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    border: `1px solid ${palette.grey[200]}`,
    borderLeft: `5px solid ${palette.info.main}`,
    height: 64,
    paddingRight: spacing(),
    paddingLeft: spacing(),
    marginBottom: spacing(),
    borderRadius
  }
});
