import { createHookReducer } from '../../../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_DISCOVERIES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_DISCOVERIES_SUCCESS]: (
    { filter, discoveries, ...state },
    { data, pagination: { page, per_page, ...pagination } = {} }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      discoveries: (page > 1
        ? discoveries.filter(({ id }) => {
          return !data.find((discovery) => id === discovery.id);
        }).concat(data)
        : data
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_DISCOVERIES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_DISCOVERY]: (state, updatedDiscovery) => {
    return {
      ...state,

      discoveries: state.discoveries.map((discovery) => {
        return discovery.id === updatedDiscovery.id ? { ...discovery, ...updatedDiscovery } : discovery;
      })
    };
  },

  [types.ADD_DISCOVERY]: (state, discovery) => {
    const { pagination, filter, discoveries } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((discoveries.length + 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      discoveries: [ discovery, ...discoveries ]
    };
  },

  [types.DELETE_DISCOVERY]: (state, discoveryID) => {
    const { pagination, filter, discoveries } = state;
    const filteredDiscoveries = discoveries.filter(({ id }) => discoveryID !== id);

    if (filteredDiscoveries.length === discoveries.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((discoveries.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      discoveries: filteredDiscoveries
    };
  }
});
