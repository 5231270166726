import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.LOGIN_SERVICES_FETCH_REQUEST]: (state) => {
    return {
      ...state,

      isFetching: true
    };
  },

  [types.LOGIN_SERVICES_FETCH_SUCCESS]: (
    { filter, loginServices, ...state },
    { data: newLoginServices, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      loginServices: page > 1 ? (
        loginServices.filter(({ id }) =>
          !newLoginServices.find((loadedLoginService) => id === loadedLoginService.id)).concat(newLoginServices)
      ) : (
        newLoginServices
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.ADD_LOGIN_SERVICE]: (state, loginService) => {
    return {
      ...state,

      loginServices: [ loginService, ...state.loginServices ],
      pagination: {
        ...state.pagination,

        total: state.pagination.total + 1
      }
    };
  },

  [types.DELETE_LOGIN_SERVICE]: ({ pagination, filter, loginServices, ...state }, deletedLoginService) => {
    const total = pagination.total - 1;
    const page = Math.ceil((loginServices.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      loginServices: loginServices.filter((loginService) => loginService.id !== deletedLoginService.id)
    };
  },

  [types.UPDATE_LOGIN_SERVICE]: ({ loginServices, ...state }, updatedLoginService) => {
    return {
      ...state,

      loginServices: loginServices.map((loginService) => {
        return updatedLoginService.id === loginService.id ? updatedLoginService : loginService;
      })
    };
  },

  [types.RESET_LOGIN_SERVICES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  }
});
