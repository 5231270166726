import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isEmpty } from 'lodash';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { IconComponent } from '../../../../../components/saved-filters';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { CompanyTypesSelect, TextField } from '../../../../../components/FormField';
import { saveFilter } from '../../../../../store/lastFilters';
import { CompaniesContext } from '../CompaniesContext';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { CardContent } from './CardContent';
import { List } from './List';

const initialValues = {
  name: null,
  email: null,
  address: null,
  company_type_id: null
};

const MODAL_WIDTH = 800;

export const FiltersBar = ({ disableInitialFilters = false, companyName = null, filterKey, ...props }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});
  const { filter, applyFilter } = useContext(CompaniesContext);

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleTypeChange = (option) => {
    applyFilter({ company_type_id: option?.id || null });
    setRelationsForFilter((state) => ({ ...state, company_type_id: option }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(filter);
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (!isEmpty(lastFilters) && !companyName) {
      applySavedFilter(!disableInitialFilters ? lastFilters : {});
    }

    if (companyName) {
      applySavedFilter({ ...formikRef.current.values, name: companyName });
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      border={0}
      filterKey={filterKey}
      initialValues={{ ...initialValues, name: filter.name || null }}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      fieldsList={[
        {
          fieldKey: filterFieldsMap.name,
          label: filterFieldsLabels[filterFieldsMap.name],
          field: <Box minWidth={isMobile ? '100%' : 250}>
            <TextField
              name="name"
              type="search"
              label="Company name"
              placeholder="Filter by company name"
              onChange={handleFieldChange('name')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.address,
          label: filterFieldsLabels[filterFieldsMap.address],
          field: <Box minWidth={isMobile ? '100%' : 250}>
            <TextField
              name="address"
              type="search"
              label="Location"
              placeholder="Filter by location"
              onChange={handleFieldChange('address')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.email,
          label: filterFieldsLabels[filterFieldsMap.email],
          field: <Box minWidth={isMobile ? '100%' : 250}>
            <TextField
              name="email"
              label="Email"
              type="search"
              placeholder="Filter by email"
              onChange={handleFieldChange('email')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.company_type_id,
          label: filterFieldsLabels[filterFieldsMap.company_type_id],
          field: <Box minWidth={isMobile ? '100%' : 250}>
            <CompanyTypesSelect
              name="company_type_id"
              label="Type"
              onChange={handleTypeChange}
            />
          </Box>
        }
      ]}

      {...props}
    />
  );
};
