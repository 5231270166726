import { Typography } from '@material-ui/core';
import { amFormatDuration } from '../../../helpers/dates';

export const IncomeTime = ({ before = null, after = null, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Set&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;to&nbsp;

        <Typography variant="subtitle1">
          {amFormatDuration(after, 'seconds', 'h[h] m[min]')}
        </Typography>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Typography variant="subtitle1">
          {amFormatDuration(before, 'seconds', 'h[h] m[min]')}
        </Typography>
      </>
    );
  } else if (before !== after) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Typography variant="subtitle1">
          {amFormatDuration(before, 'seconds', 'h[h] m[min]')}
        </Typography>
        &nbsp;to&nbsp;
        <Typography variant="subtitle1">
          {amFormatDuration(after, 'seconds', 'h[h] m[min]')}
        </Typography>
      </>
    );
  } else {
    return (
      <>Updated <Typography variant="subtitle1">{fieldLabel} time</Typography></>
    );
  }
};
