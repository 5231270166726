import { useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Collapse, Grid, Link, List as MuiList, makeStyles, Typography } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AppointmentViewModal } from '../../../../../../../../components/appointments';
import { SigningMembers } from '../../../../../../../../components/medical/SigningMembers';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { formAbbrTypesMap, formTypesMap } from '../../../../../../../../components/medical/forms';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { IconButton } from '../../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Popper } from '../../../../../../../../components/Popper';
import { Date } from '../../../../../../../../components/Date';
import { CovidPreviewFormModal } from '../../../CovidPreviewFormModal';
import { FormPreviewModal } from '../../../FormPreviewModal';
import { FormsMenu } from '../FormsMenu';
import {
  columnsWidths,
  widthBreakpointLg,
  widthBreakpointMd,
  widthBreakpointSm,
  widthBreakpointXS
} from '../listConfig';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  disablePatient = false,
  isChild,
  isTabsPage,
  width,
  form,
  formContext = {}
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const {
    isDeleted = false,
    selectedIDs,
    isUnconfirmed = false,
    fetchForms,
    fetchUnconfirmedForms = () => {},
    restoreDeletedMedicalForms = () => {},
    toggleItemSelection
  } = formContext;
  const isSelected = selectedIDs?.indexOf(form.id) !== -1;
  const [ isOpen, setIsOpen ] = useState(false);
  const stepsCount = isUnconfirmed ? 6 : 7;
  const isCovidForm = form.form_type === formTypesMap.covid_form;
  const onAppointmentUpdate = isUnconfirmed ? fetchUnconfirmedForms : fetchForms;

  const openAppointmentPreview = () => {
    if (isDeleted) return;

    if (form.appointment_id) {
      openModal(AppointmentViewModal, {
        payload: {
          appointmentID: form.appointment_id,
          onWaitingListUpdate: onAppointmentUpdate,
          onAppointmentUpdate
        }
      });
    }
  };

  const openFormPreviewModal = () => {
    if (isCovidForm) {
      openModal(CovidPreviewFormModal, {
        payload: { formID: form.id, onUpdateForm: fetchForms }
      });
    } else {
      openModal(FormPreviewModal, {
        payload: {
          isDeleted,
          formID: form.id,
          onRestore: restoreDeletedMedicalForms,
          onApprove: () => {
            fetchUnconfirmedForms();
          }
        }
      });
    }
  };

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  const handleItemSelection = () => {
    toggleItemSelection(form.id);
  };

  return (
    <>
      <ListRow
        button
        className={{ [classes.rowBackground]: isChild }}
        onClick={stopPropagation(openFormPreviewModal)}
      >
        {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.medic) && !isTabsPage && !isChild &&
          <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />
        }

        {!disablePatient && (
          <ListRowCell grow flexBasis={columnsWidths.patient}>
            {!isChild && (
              <UserLink
                disablePreview={!form?.patient}
                disableLink={!form?.patient}
                noWrap
                size="md"
                variant="inherit"
                user={form?.patient || form}
              />
            )}
          </ListRowCell>
        )}

        {width > widthBreakpointLg &&
          <ListRowCell actions flexBasis={columnsWidths.date}>
            <Date
              date={form.created_at || form.updated_at}
              iconFontSize="small"
              format="L, LT"
            />
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.type}>
          <Grid container spacing={1} wrap="nowrap" justify="space-between">
            <Grid item xs={9}>
              <Typography variant={!isCovidForm ? 'inherit' : 'h5'} noWrap title={form.form_type}>
                {form.abbreviated_name || formAbbrTypesMap?.[form?.form_type] || form?.form_type}
              </Typography>
            </Grid>

            <Grid item>
              {form.form_type !== formTypesMap.follow_up && !isChild &&
                <Typography
                  className={
                    cn(
                      classes.fillContent,
                      { [classes.fillContent_fullfilled]: form.filling === stepsCount }
                    )
                  }
                >
                  {form.filling ? `${form.filling}/${stepsCount}` : `1/${stepsCount}`}
                </Typography>
              }
            </Grid>
          </Grid>
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.children}>
          {!!form?.children?.length ? (
            <>
              <Typography variant="h5" className={classes.count}>
                {form?.children?.length}
              </Typography>

              {!isOpen ? (
                <IconButton color="primary" onClick={stopPropagation(handleOpenListItem)}>
                  <ExpandMore />
                </IconButton>
              ) : (
                <IconButton color="primary" onClick={stopPropagation(handleOpenListItem)}>
                  <ExpandLess />
                </IconButton>
              )}
            </>
          ) : (
            <Typography noWrap color="textSecondary">
              {!isChild ? 'No info.' : ''}
            </Typography>
          )}
        </ListRowCell>

        {width > widthBreakpointSm &&
          <ListRowCell actions flexBasis={columnsWidths.doi}>
            <Date
              disableMoment
              date={form?.doi_string || moment(form.doi).format('L')}
              iconFontSize="small"
            />
          </ListRowCell>
        }

        {width > widthBreakpointXS &&
          <ListRowCell grow actions flexBasis={columnsWidths.name}>
            <UserLink
              noWrap
              size="md"
              variant="inherit"
              user={form.treating_physician}
            />
          </ListRowCell>
        }

        {width > widthBreakpointMd &&
          <ListRowCell flexBasis={columnsWidths.insurance_name}>
            {!form.is_confirmed ? (
              <Typography>{form?.primary_insurance_name || '-'}</Typography>
            ) : (
              <CompanyLink
                noWrap
                company={form?.primary_insurance?.insurance_company}
              />
            )}
          </ListRowCell>
        }

        <ListRowCell column flexBasis={columnsWidths.insurance_id}>
          <Typography noWrap variant="h5">
            {form?.primary_insurance?.type_insurance || '-'}
          </Typography>

          <Typography noWrap>
            {form?.primary_insurance?.insuranceId || '-'}
          </Typography>
        </ListRowCell>

        <ListRowCell actions flexBasis={columnsWidths.appointment_date}>
          {!isCovidForm && (
            form.appointment_id ? (isDeleted
              ? <Date color="primary" date={form.appointment_at} iconFontSize="small" />
              : (
                <Link onClick={stopPropagation(openAppointmentPreview)}>
                  <Date date={form.appointment_at} iconFontSize="small" />
                </Link>
              )
            ) : (
              <Typography color="textSecondary">No info.</Typography>
            )
          )}
        </ListRowCell>

        {!isUnconfirmed && (
          <ListRowCell actions flexBasis={columnsWidths.users}>
            {!isChild && <SigningMembers signInfo={form?.sign_info} />}
          </ListRowCell>
        )}

        {!isDeleted && (
          <ListRowCell flexBasis={columnsWidths.action}>
            <Popper
              placement="left-start"
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                    <MoreVertIcon/>
                  </IconButton>
                )
              }
            >
              {({ handleClose }) => (
                <FormsMenu
                  disableCreatable={!isChild}
                  form={form}
                  onClose={handleClose}
                />
              )}
            </Popper>
          </ListRowCell>
        )}
      </ListRow>

      {!!form?.children?.length &&
        <Collapse
          unmountOnExit
          in={isOpen}
          timeout="auto"
        >
          <MuiList disablePadding>
            {form?.children?.map((formItem) => (
              <Row
                isChild
                disablePatient={disablePatient}
                width={width}
                key={formItem.id}
                form={formItem}
                formContext={formContext}
              />
            ))}
          </MuiList>
        </Collapse>
      }
    </>
  );
};
