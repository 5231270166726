import { modelTypeOptions } from './activityDataMaps';

export const transformRelationsForFilterToOptions = (relationsForFilter) => {
  return {
    ...relationsForFilter,

    model: modelTypeOptions?.find(({ value }) => value === relationsForFilter?.model)
  };
};

export const transformRelationsToReset = (filter) => {
  return {
    ...filter,

    model_id: filter?.model_id?.id || null,
    log_name: filter?.log_name?.value || filter?.log_name || null,
    subject_type: filter?.subject_type?.value || filter?.subject_type || null,
    model: filter?.model_id?.id ? filter?.model : null,
    user_ids: filter?.user_ids?.map((user) => user?.id) || []
  };
};
