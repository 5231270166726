export const styles = ({ spacing }) => ({
  root: {
    overflow: 'hidden'
  },

  mainContent: {
    height: '100%',
    flexGrow: 1,
    overflow: 'auto',
    paddingBottom: spacing(3)
  },

  invoiceContent: {
    margin: spacing(3, 6, 2, 4)
  }
});
