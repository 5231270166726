export const styles = ({
  spacing,
  palette: { grey, primary, error },
  typography: { fontWeightMedium, pxToRem }
}) => ({
  root: {
    color: error.main,
    fontSize: pxToRem(12),
    marginRight: spacing(1.5),
    marginLeft: spacing(1.5)
  }
});
