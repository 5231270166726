import { Box, Link, Typography } from '@material-ui/core';
import { CaseViewModal } from '../../../app/Dashboard/CasesPage/Cases/CaseViewModal';
import { ColorPreview } from '../../ColorPreview';
import { useModal } from '../../ModalsProvider';

export const ReminderActivity = ({
  isDeleted = false,
  isGroup = false,
  payload
}) => {
  const { openModal } = useModal();
  const activityGroupTitle = isDeleted ? 'Removed:' : 'Added:';
  const parentSubTitle = isDeleted ? 'From case:' : 'To case:';

  const openCasePreview = (id) => () => {
    if (id) {
      openModal(CaseViewModal, {
        payload: { id }
      });
    }
  };

  return (
    <>
      <Box fontSize={14} display="flex" alignItems="center" flexWrap="wrap">
        <Box display="flex" alignItems="center" mr={1} flexWrap="wrap">
          <Typography variant="subtitle1">
            {isGroup ? activityGroupTitle : 'Updated reminder:'}&nbsp;
          </Typography>

          <Box display="flex" mr={1}>
            <ColorPreview color={payload?.case_reminder_group?.color}/>
          </Box>

          <Typography>
            {payload?.case_reminder_group?.name || payload?.case_reminder_group?.description || '-'}
          </Typography>

          <Typography variant="subtitle1">
            &nbsp;{activityGroupTitle ? parentSubTitle : 'in case'}&nbsp;
          </Typography>
          <Link variant="subtitle1" onClick={openCasePreview(payload?.case?.id)}>{payload?.case?.name}</Link>
        </Box>
      </Box>
    </>
  );
};
