import T from 'prop-types';
import cn from 'classnames';
import {
  makeStyles,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Collapse,
  Box
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { stopPropagation } from '../../helpers/dom';
import { Divider } from '../Divider';
import { Fab } from '../Fab';
import { IconButton } from '../IconButton';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  disablePadding: T.bool,
  isOpen: T.bool,
  title: T.oneOfType([ T.node, T.string ]),
  actionButton: T.node,
  actionIcon: T.node,
  children: T.node,
  onOpen: T.func,
  onCreate: T.func,
  TypographyProps: T.object
};

const defaultProps = {
  onOpen: () => {}
};

export const NestedList = ({
  disableDivider,
  disableBackground = false,
  isOpen,
  noWrap,
  buttonSize = 'medium',
  disablePadding,
  TypographyProps = {},
  actionTitle,
  actionIcon,
  actionButton,
  title,
  className,
  children,
  onOpen,
  onCreate = () => {}
}) => {
  const classes = useStyles({ TypographyProps });

  return (
    <>
      <List component="nav" disablePadding={disablePadding}>
        <ListItem
          button
          onClick={onOpen}
          className={cn(
            classes.listItem,
            { [classes.listItem_action]: !!actionTitle },
            { [classes.listItem_background]: !disableBackground }
          )
          }
        >
          <ListItemText
            primary={
              <Box display="flex" alignItems="center">
                <Typography noWrap={noWrap} variant="h2" className={classes.title}>
                  {title}
                </Typography>

                {actionTitle &&
                  <Box ml={2}>
                    <Fab
                      size={buttonSize}
                      variant="extended"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={stopPropagation(onCreate)}
                    >
                      {actionTitle}
                    </Fab>
                  </Box>
                }
              </Box>
            }
          />

          <ListItemSecondaryAction>
            <Box display="flex" alignItems="center">
              {actionButton &&
                <Box component="span" mr={1}>
                  {actionButton}
                </Box>
              }

              {!isOpen ? (
                <IconButton color="primary" onClick={onOpen}>
                  <ExpandMore />
                </IconButton>
              ) : (
                <IconButton color="primary" onClick={onOpen}>
                  <ExpandLess />
                </IconButton>
              )}

              {actionIcon &&
                <Box component="span" ml={2}>
                  {actionIcon}
                </Box>
              }
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <Collapse in={isOpen} unmountOnExit className={cn(classes.content, className)}>
        {children}
      </Collapse>

      {!disableDivider && isOpen && <Divider gutter={0} />}
    </>
  );
};

NestedList.propTypes = propTypes;
NestedList.defaultProps = defaultProps;
