import bg from './bg.png';

export const styles = ({
  spacing,
  palette: { primary, grey, ...palette },
  shape: { borderRadius },
  shadows,
  breakpoints
}) => ({
  root: {
    marginBottom: spacing(),
    boxShadow: shadows[2],
    borderRadius: borderRadius,
    backgroundColor: palette.common.white,
    overflowX: 'hidden'
  },

  userHeader: {
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    background: `${grey[500]} url(${bg}) center / cover`,
    padding: spacing(1, 1, 2, 2),
    margin: '0 !important',
    width: '100% !important'
  },

  content: {
    [breakpoints.up('sm')]: {
      borderRight: '1px solid rgba(0, 0, 0, 0.2)'
    }
  },

  avatar: {
    position: 'absolute',
    width: 110,
    height: 110,
    top: 10,
    left: 0,
    zIndex: 1
  },

  collapseContent: {
    width: '100%',
    borderTop: `1px solid ${primary.main}`,
    marginTop: spacing(2)
  },

  note: {
    minHeight: 70,
    backgroundColor: grey[50],
    padding: spacing()
  }
});
