import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import filesize from 'filesize';
import {
  Dialog,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { getAggregateTypeFromMIME } from '../../../../../../helpers/files';
import { getUserFullName } from '../../../../../../helpers/users';
import * as filesApi from '../../../../../../api/files';
import { TextField } from '../../../../../../components/FormField';
import { useModal } from '../../../../../../components/ModalsProvider';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../components/Modal';
import { Loader } from '../../../../../../components/Loader';
import { FileDropZone } from '../../../../../../components/FileDropZone';
import { FileTypeIcon, FileValidationConflictModal } from '../../../../files-common';
import { validationSchema } from './validationSchema';

export const CertificateUploadModal = ({
  DialogProps,
  payload: { onUpload } = {},
  handleModalResolve,
  handleModalReject
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ selectedFiles, setSelectedFiles ] = useState([]);
  const { user } = useSelector(({ profile }) => profile);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const validateFile = (file) => {
    file.original_filename = file.original_filename || file.name;

    if (selectedFiles.find(({ original_filename }) => original_filename === file.original_filename)) {
      openModal(FileValidationConflictModal, {
        payload: { file },
        onModalResolved: (file) => {
          validateFile(file);
        }
      });
    } else {
      filesApi.validateFile(file).then(() => {
        file.aggregate_type = getAggregateTypeFromMIME(file.type);

        setSelectedFiles((state) => [ file ].concat(state));
      }).catch(({ status }) => {
        if (status === 409) {
          openModal(FileValidationConflictModal, {
            payload: { file },
            onModalResolved: (file) => {
              validateFile(file);
            }
          });
        } else {
          enqueueSnackbar(`File "${file.original_filename}" is invalid`, {
            variant: 'error'
          });
        }
      });
    }
  };

  const onUploadFiles = (values, { setErrors, setSubmitting }) => {
    if (!selectedFiles.length) {
      setSubmitting(false);

      return;
    }

    selectedFiles.forEach((file) => {
      const formData = new FormData();
      const certificate = { file, name: values.name };

      for (let key in certificate) if (certificate.hasOwnProperty(key)) {
        formData.append(key, certificate[key]);
      }

      onUpload({ formData, setErrors, setSubmitting, handleModalResolve });
    });
  };

  const handleDropAccepted = (files) => {
    files.forEach((file) => {
      validateFile(file);
    });
  };

  return (
    <Dialog fullScreen={isMobile} {...DialogProps}>
      <Formik
        initialValues={{
          name: getUserFullName(user) || null
        }}
        validationSchema={validationSchema}
        onSubmit={onUploadFiles}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Upload files
            </ModalHeader>

            <ModalBody>
              <TextField
                required
                margin="dense"
                name="name"
                label="Certificate Name"
              />

              <FileDropZone multiple onDropAccepted={handleDropAccepted} />

              <List>
                {selectedFiles.map((file, index) => (
                  <Fragment key={index}>
                    {index > 0 && <Divider />}

                    <ListItem>
                      <FileTypeIcon
                        file={{
                          aggregate_type: getAggregateTypeFromMIME(file.type),

                          ...file
                        }}
                      />

                      <ListItemText
                        primary={file.original_filename || file.name}
                        primaryTypographyProps={{ noWrap: true }}
                      />

                      <ListItemText primary={filesize(file.size)} />
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            </ModalBody>

            <ModalFooter>
              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Upload
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
