import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'react-modal-hook';
import jss from 'jss';
import preset from 'jss-preset-default';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import { TransitionGroup } from 'react-transition-group';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, CssBaseline, IconButton } from '@material-ui/core';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { OnlineUsersProvider } from '../components/users/OnlineUsersProvider';
import * as themes from '../themes';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { ModalsProvider } from '../components/ModalsProvider';
import { SnackbarStack } from '../components/SnackbarStack';
import { GlobalCss } from './GlobalCss';

jss.setup(preset());

export const Providers = ({ children }) => {
  const currentTheme = useSelector(({ theme }) => theme.currentTheme);
  const notistackRef = useRef();

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={createMuiTheme(themes[currentTheme])}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter>
            <ErrorBoundary>
              <SnackbarProvider
                ref={notistackRef}
                autoHideDuration={8000}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                action={(key) => (
                  <IconButton
                    color="inherit"
                    onClick={() => notistackRef.current.closeSnackbar(key)}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              >
                <ModalProvider container={TransitionGroup}>
                  <ModalsProvider>
                    <OnlineUsersProvider>
                      {children}

                      <SnackbarStack />
                    </OnlineUsersProvider>
                  </ModalsProvider>
                </ModalProvider>
              </SnackbarProvider>
            </ErrorBoundary>
          </BrowserRouter>

          <CssBaseline />
          <GlobalCss />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};
