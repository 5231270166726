import { Box, Grid, Typography } from '@material-ui/core';
import { AddressContent } from '../../../../app/Dashboard/Members/CreateCustomerModal';
import { PhoneField } from '../../../FormField';

export const Contacts = () => {
  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        <Box pt={3}>
          <Typography variant="h6">Phone</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} lg={4}>
        <PhoneField
          name="phone_number"
          label="Phone number"
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <PhoneField
          name="mobile_phone_number"
          label="Mobile phone number"
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <PhoneField
          name="fax"
          label="Fax"
        />
      </Grid>

      <Grid item xs={12}>
        <AddressContent />
      </Grid>
    </Grid>
  );
};
