import { useState, useEffect } from 'react';
import {
  makeStyles,
  Typography,
  IconButton,
  Collapse
} from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { AttachmentItem } from './AttachmentItem';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SavedEmailsAttachments = ({ messageItem = {}, attachmentsLength, attachments = [] }) => {
  const classes = useStyles();
  const [ expanded, setExpanded ] = useState(false);
  const [ selectedFiles, setSelectedFiles ] = useState([]);

  const toggleItemSelection = (name) => {
    setSelectedFiles(
      selectedFiles.indexOf(name) !== -1
        ? selectedFiles.filter((item) => item !== name)
        : selectedFiles.concat(name)
    );
  };

  const toggleMore = () => {
    setExpanded((expanded) => !expanded);
  };

  useEffect(() => {
    setExpanded(false);
  }, [ messageItem.attachments ]);

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <div className={classes.col}>
          <Typography variant="h4" className={classes.title}>
            <AttachmentIcon className={classes.filesIcon} />
            {attachmentsLength} Attachments
          </Typography>
        </div>

        <div className={classes.col}>
          <IconButton color="primary" size="small" className={classes.button} onClick={toggleMore}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>

      <Collapse in={expanded} className={classes.attachments}>
        <div className={classes.attachmentsRow}>
          {attachments.map((attachment, index) => (
            <AttachmentItem
              isSavedAttachments
              key={index}
              selectedFiles={selectedFiles}
              attachment={attachment}
              attachments={attachments}
              toggleItemSelection={toggleItemSelection}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};
