export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { pxToRem, fontWeightMedium, fontWeightBold }
}) => ({
  date: {
    display: 'inline-block',
    marginBottom: spacing(0.5),
    padding: spacing(0, 0.75),
    fontSize: pxToRem(14),
    fontWeight: fontWeightBold,
    lineHeight: pxToRem(20),
    color: palette.common.white
  },

  dateLeft: {
    display: 'block',
    fontSize: pxToRem(11),
    fontWeight: fontWeightMedium
  },

  infoIcon: {
    fontSize: pxToRem(12)
  }
});
