import { useContext } from 'react';
import { Checkbox, Paper, useMediaQuery, useTheme, Box } from '@material-ui/core';
import { ScheduleContext } from '../../ScheduleProvider';
import { ListRow, ListRowCell } from '../../../ListRow';
import { columnsMap, columnsWidths } from '../columns';
import { BREAKPOINT_HIDE_OFFICE, BREAKPOINT_LARGE, BREAKPOINT_LARGE_GROW } from '../ScheduleEventRow';

export const TableHeader = ({ width, isMulti, ListRowProps = {}, hiddenColumns = [] }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { allEventsIsSelected, toggleAllEventsSelection } = useContext(ScheduleContext);

  return (
    <Paper elevation={0} component={Box}>
      <ListRow header {...ListRowProps}>
        {isMulti && (
          <ListRowCell>
            <Checkbox edge="start" checked={allEventsIsSelected()} onClick={toggleAllEventsSelection} />
          </ListRowCell>
        )}

        <ListRowCell flexGrow={1} flexBasis={columnsWidths.date}>
          Date event
        </ListRowCell>

        <ListRowCell flexGrow={1} flexBasis={columnsWidths.duration}>
          Time/Duration
        </ListRowCell>

        <ListRowCell flexGrow={1} flexBasis={columnsWidths.description}>
          Name/Description
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.type) && !isTablet &&
          <ListRowCell flexGrow={width > BREAKPOINT_LARGE_GROW ? 1 : null} flexBasis={columnsWidths.type}>
            Type
          </ListRowCell>
        }

        {BREAKPOINT_HIDE_OFFICE < width &&
          <ListRowCell flexGrow={width > BREAKPOINT_LARGE_GROW ? 1 : null} flexBasis={columnsWidths.office}>
            Office/Address
          </ListRowCell>
        }

        {BREAKPOINT_LARGE < width &&
          <ListRowCell flexGrow={width > BREAKPOINT_LARGE_GROW ? 1 : null} flexBasis={columnsWidths.participants}>
            Participants
          </ListRowCell>
        }

        {!hiddenColumns.includes(columnsMap.action) &&
          <ListRowCell flexBasis={columnsWidths.action} />
        }
      </ListRow>
    </Paper>
  );
};
