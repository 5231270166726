import { Yup } from '../../../utils/validation';

export const userValidationSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required().max(255),
  last_name: Yup.string().nullable().required().max(255),
  phone_number: Yup.string().nullable().max(255).required(),
  mobile_phone: Yup.string().nullable().max(255),
  home: Yup.object().shape({
    street: Yup.string().nullable().required().min(2).max(255),
    country: Yup.string().nullable().required().min(2).max(255),
    zip: Yup.string().nullable().required().min(1).max(255),
    city: Yup.string().nullable().required().min(2).max(255),
    state: Yup.string().nullable().required().min(2).max(255),
    designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
      return unitNumber ? schema.required() : schema;
    }),
    unit_number: Yup.string().nullable().min(1).max(255)
  }),
  birthday: Yup.mixed()
});
