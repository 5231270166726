import { useContext } from 'react';
import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { UsersSelect } from '../../../../../components/users';
import { getUserFullName, getUserNameAbbr } from '../../../../../helpers/users';
import { TaskPreviewContext } from '../../../../../components/tasks/TasksProvider';
import { Avatar } from '../../../../../components/Avatar';
import { Date } from '../../../../../components/Date';
import { Members } from '../../Members';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const RightSidebar = ({ isStaticPage }) => {
  const classes = useStyles();
  const { selectedTask, setSelectedTask } = useContext(TaskPreviewContext);

  return (
    <>
      <div className={cn(classes.preview, isStaticPage && classes.preview_static)}>
        <div className={classes.preview__owner}>
          <Typography className={classes.preview__title}>
            Creator
          </Typography>

          <Avatar
            src={selectedTask?.created_by?.profile?.avatar}
            alt={getUserNameAbbr(selectedTask?.created_by)}
            className={classes.preview__avatar}
          />

          {selectedTask.created_by &&
            <Typography className={classes.preview__userName}>
              {getUserFullName(selectedTask?.created_by)}
            </Typography>
          }
        </div>

        <UsersSelect name="owner_id" label="Owner" />
      </div>

      <div className={cn(classes.preview, isStaticPage && classes.preview_static)}>
        <Members task={selectedTask} setInitialData={setSelectedTask} />
      </div>

      <div className={cn(classes.preview, isStaticPage && classes.preview_static)}>
        <Typography className={classes.preview__dateTitle}>
          IMPORTANT DATE
        </Typography>

        <Typography className={classes.preview__title}>
          Created
        </Typography>

        <div className={classes.preview__dataBorder}>
          <Date variant="body2" disableIcon date={selectedTask.created_at} format="MM/DD/YYYY" />
          <Date variant="body2" disableIcon date={selectedTask.created_at} format="h:mm a" />
        </div>

        {selectedTask.opened_at &&
          <>
            <Typography className={classes.preview__title}>
              Open
            </Typography>

            <div className={classes.preview__dataBorder}>
              <Date variant="body2" disableIcon date={selectedTask.opened_at} format="MM/DD/YYYY" />
              <Date variant="body2" disableIcon date={selectedTask.opened_at} format="h:mm a" />
            </div>
          </>
        }

        {selectedTask.status_changed_at &&
          <>
            <Typography className={classes.preview__title}>
              Changed status at
            </Typography>

            <div className={classes.preview__dataBorder}>
              <Date variant="body2" disableIcon date={selectedTask.status_changed_at} format="MM/DD/YYYY" />
              <Date variant="body2" disableIcon date={selectedTask.status_changed_at} format="h:mm a" />
            </div>
          </>
        }

        {selectedTask.updated_at &&
          <>
            <Typography className={classes.preview__title}>
              Updated
            </Typography>

            <div className={classes.preview__dataBorder}>
              <Date variant="body2" disableIcon date={selectedTask.updated_at} format="MM/DD/YYYY" />
              <Date variant="body2" disableIcon date={selectedTask.updated_at} format="h:mm a" />
            </div>
          </>
        }

        {selectedTask.extended_at &&
          <>
            <Typography className={classes.preview__title}>
              Extended
            </Typography>

            <div className={classes.preview__dataBorder}>
              <Date variant="body2" disableIcon date={selectedTask.extended_at} format="MM/DD/YYYY" />
              <Date variant="body2" disableIcon date={selectedTask.extended_at} format="h:mm a" />
            </div>
          </>
        }
      </div>
    </>
  );
};
