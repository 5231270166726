import { useContext, useEffect, useRef } from 'react';
import { omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { saveFilter } from '../../../../../store/lastFilters';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { IconComponent } from '../../../../../components/saved-filters';
import {
  ApptBooksSelect,
  ApptTypesSelect,
  KeyboardDatePicker,
  VisitReasonsSelect
} from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';
import { WaitingListFilterContext } from '../WaitingListFilterProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { SavedFiltersList } from './SavedFiltersList';
import { CardContent } from './CardContent';
import { initialValues } from './initialValues';

const MODAL_WIDTH = 1362;

export const MainFiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const {
    filter,
    relationsForFilter,
    setRelationsForFilter,
    applyFilter
  } = useContext(WaitingListFilterContext);

  const handleUsersMultiSelect = (name) => (options) => {
    const users = options?.length ? options.map(({ id }) => id) : null;

    applyFilter({ [name]: users });
    setRelationsForFilter((state) => ({ ...state, [name]: options }));
  };

  const handleVisitReasonsChange = (options) => {
    applyFilter({ visit_reasons: options?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, visit_reasons: options }));
  };

  const handleApptBooksChange = (options) => {
    applyFilter(({ appointment_books: options?.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, appointment_books: options }));
  };

  const handleApptTypesChange = (apptTypes) => {
    applyFilter(({ appointment_types: apptTypes?.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, appointment_types: apptTypes }));
  };

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(omit(filter, hiddenFields));

    applyFilter(omit({
      patients: filter?.patients?.map(({ id }) => id),
      providers: filter?.providers?.map(({ id }) => id),
      appointment_books: filter?.appointment_books?.map(({ id }) => id),
      appointment_types: filter?.appointment_types?.map(({ id }) => id),
      visit_reasons: filter?.visit_reasons?.map(({ id }) => id),
      order_by: filter?.order_by?.value
    }, hiddenFields));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      initialValues={initialValues}
      hiddenFields={hiddenFields}
      filterKey={filterKey}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={SavedFiltersList}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      onReset={() => applySavedFilter(initialValues)}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.patients,
          label: filterFieldsLabels[filterFieldsMap.patients],
          field: (
            <UsersSelect
              multiple
              name="patients"
              label="Patients"
              params={{ is_patient: 1 }}
              onChange={handleUsersMultiSelect('patients')}
              minWidth={210}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.providers,
          label: filterFieldsLabels[filterFieldsMap.providers],
          field: (
            <UsersSelect
              multiple
              name="providers"
              label="Providers"
              params={{ role: 'doctor' }}
              onChange={handleUsersMultiSelect('providers')}
              minWidth={210}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.apptBooks,
          label: filterFieldsLabels[filterFieldsMap.apptBooks],
          field: <ApptBooksSelect
            multiple
            name="appointment_books"
            label="Appt. books"
            params={{ providers: filter?.providers || null }}
            onChange={handleApptBooksChange}
          />
        },
        {
          fieldKey: filterFieldsMap.apptTypes,
          label: filterFieldsLabels[filterFieldsMap.apptTypes],
          field: <ApptTypesSelect
            multiple
            name="appointment_types"
            label="Appointment types"
            onChange={handleApptTypesChange}
          />
        },
        {
          fieldKey: filterFieldsMap.visitReasons,
          label: filterFieldsLabels[filterFieldsMap.visitReasons],
          field: <VisitReasonsSelect
            multiple
            name="visit_reasons"
            label="Visit reasons"
            onChange={handleVisitReasonsChange}
          />
        },
        {
          fieldKey: filterFieldsMap.appointmentDayFrom,
          label: filterFieldsLabels[filterFieldsMap.appointmentDayFrom],
          field: <Box minWidth={150}>
            <KeyboardDatePicker
              clearable
              name="appointment_day_from"
              label="Appt. From"
              onChange={handleDatePickerChange('appointment_day_from')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.appointmentDayTo,
          label: filterFieldsLabels[filterFieldsMap.appointmentDayTo],
          field: <Box minWidth={150}>
            <KeyboardDatePicker
              clearable
              name="appointment_day_to"
              label="Appt. To"
              onChange={handleDatePickerChange('appointment_day_to')}
            />
          </Box>
        }
      ]}
    />
  );
};
