import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Tooltip, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { SelectedItemsCount } from '../../../../../../components/SelectedItemsCount';
import { PaymentsContext } from '../../../../../../components/billing/payments';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { IconButton } from '../../../../../../components/IconButton';
import { Fab } from '../../../../../../components/Fab';

export const ActionsBar = ({ isTablet, filterOpenButton }) => {
  const history = useHistory();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { selectedPaymentsIDs, pagination, ...paymentsContext } = useContext(PaymentsContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const massDeletePayments = () => {
    paymentsContext.massDeletePayments(selectedPaymentsIDs);
  };

  const goToCreatePaymentPage = () => {
    history.push('/billing/payments/create');
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap={isTablet ? 'wrap' : 'nowrap'}
      py={1.5}
      px={3}
      bgcolor="common.white"
    >
      <Fab
        size={isMobile ? 'small' : 'medium'}
        color="primary"
        variant="extended"
        onClick={goToCreatePaymentPage}
        startIcon={<AddIcon />}
      >
        Create payment
      </Fab>

      <Box display="flex" alignItems="center">
        {!!selectedPaymentsIDs.length &&
          <>
            <SelectedItemsCount length={selectedPaymentsIDs.length} total={pagination.total} />

            <VerticalDivider verticalGutters={1} horizontalGutters={2}/>

            <Tooltip title="Print selected payments">
              <IconButton edge="start">
                <PrintIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Download selected payments">
              <IconButton>
                <DownloadIcon/>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete selected payments">
              <IconButton
                edge="end"
                color="error"
                onClick={massDeletePayments}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <VerticalDivider verticalGutters={1} horizontalGutters={2} />
          </>
        }

        {isTablet && filterOpenButton }

        {!isTablet &&
          <>
            <Tooltip title="List view">
              <IconButton
                edge="start"
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      </Box>
    </Box>
  );
};
