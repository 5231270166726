import { useFormikContext } from 'formik';
import { Collapse, Typography, Box, Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import NotesIcon from '@material-ui/icons/Notes';
import { TextField, KeyboardDatePicker } from '../../FormField';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { IconButton } from '../../IconButton';

export const SurgeriesList = ({ arrayHelpers, onAddFields }) => {
  const { openModal } = useModal();
  const { values } = useFormikContext();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  return (
    <Collapse
      unmountOnExit
      timeout="auto"
      in={!!values?.forms?.past_medical_history?.past_surgical_history?.length}
    >
      {!!values?.forms?.past_medical_history?.past_surgical_history?.length && (
        values?.forms?.past_medical_history?.past_surgical_history?.map((date, index) => (
          <Grid key={index} container spacing={2} alignItems="flex-end">
            <Grid item>
              <Typography>{index + 1}.</Typography>
            </Grid>

            <Grid item md={2} xs={6}>
              <TextField
                fullWidth
                name={`forms.past_medical_history.past_surgical_history.${index}.surgery`}
              />
            </Grid>

            <Grid item md={2} sm={3} xs={10}>
              <KeyboardDatePicker
                name={`forms.past_medical_history.past_surgical_history.${index}.date_from`}
                label="Add date from"
                outputFormat="YYYY-MM-DD"
                placeholder="MM/DD/YYYY"
              />
            </Grid>

            <Grid item md={2} sm={3} xs={10}>
              <KeyboardDatePicker
                name={`forms.past_medical_history.past_surgical_history.${index}.date_to`}
                label="Add date to"
                outputFormat="YYYY-MM-DD"
                placeholder="MM/DD/YYYY"
              />
            </Grid>

            <Grid item>
              <IconButton
                size="small"
                color="error"
                onClick={handleClearField(arrayHelpers, index)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                name={`forms.past_medical_history.past_surgical_history.${index}.surgeon`}
                label={
                  <Box fontWeight="fontWeightMedium">
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </Box>
                }
                onKeyDown={onAddFields(arrayHelpers, {
                  surgery: '',
                  date: null,
                  surgeon: ''
                })}
              />
            </Grid>
          </Grid>
        ))
      )}
    </Collapse>
  );
};
