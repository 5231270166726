export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { pxToRem }
}) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    marginBottom: spacing(3)
  },

  iconContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    marginRight: spacing(2),
    minWidth: 30
  },

  divider: {
    height: '100%',
    margin: '10px auto',
    width: pxToRem(1),
    borderLeft: `1px dashed ${grey[400]}`
  },

  activityIcon: {
    fontSize: pxToRem(26),
    color: grey[400]
  },

  activityIcon_default: {
    color: palette.slategray
  },

  activityIcon_person: {
    color: palette.purple
  },

  activityIcon_disablePerson: {
    color: palette.violet
  },

  activityIcon_work: {
    fontSize: pxToRem(28),
    color: palette.darkgreen
  },

  activityIcon_task: {
    color: palette.lightgreen
  },

  activityIcon_event: {
    color: palette.info.main
  },

  activityIcon_comment: {
    color: palette.indigo
  },

  activityIcon_invoice: {
    color: grey[900]
  },

  activityIcon_email: {
    color: palette.primary.main
  },

  activityIcon_negotiat: {
    color: grey['A400']
  },

  activityIcon_claim: {
    color: palette.secondary.main
  }
});
