import { useState } from 'react';
import { Formik } from 'formik';
import { makeStyles, Button, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import { invoicesTypesMap, invoicesTypesOptions } from '../../../../../../../../components/billing';
import { ModalBody, ModalHeader, ModalFooter, ModalContainer } from '../../../../../../../../components/Modal';
import { Loader } from '../../../../../../../../components/Loader';
import { FormContent } from '../../../../../../BillingPage/Invoices/CreateInvoice/FormContent';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const InvoiceModal = ({
  DialogProps,
  payload: {
    model = {},
    invoiceID = null,
    ...payload
  },
  handleModalResolve,
  handleModalReject
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const [ totalTime, setTotalTime ] = useState(0);
  const [ expenses, setExpenses ] = useState([]);

  const createInvoice = (invoiceData, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    invoiceData.expenses = expenses;
    invoiceData.total = +totalTime;

    payload.createInvoice({ invoiceData, setSubmitting, setErrors, handleModalResolve });
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <Formik
        initialValues={{
          model_type: model
            ? invoicesTypesOptions?.find(({ value }) => value === invoicesTypesMap.case)
            : null,
          model_id: model || null,
          bill_from: {
            type: null,
            id: null
          },
          bill_to: {
            type: null,
            id: null
          },
          date: null,
          due_date: null,
          model,
          incomes: []
        }}
        validationSchema={validationSchema}
        onSubmit={createInvoice}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer
            component="form"
            noValidate
            className={classes.root}
            onSubmit={handleSubmit}
          >
            <ModalHeader onClose={handleModalReject}>
              New Invoice
            </ModalHeader>

            <ModalBody>
              <FormContent
                totalTime={totalTime}
                expenses={expenses}
                onTotalTimeUpdate={setTotalTime}
                onExpensesUpdate={setExpenses}
              />
            </ModalBody>

            <ModalFooter>
              <div className={classes.actions}>
                <Button onClick={handleModalResolve} className={classes.actions__backButton}>Back</Button>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      autoFocus
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />
              </div>
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
