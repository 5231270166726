import { createContext, useRef, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../../api';
import * as appointmentTypesApi from '../../../../../../api/codes/appointments/appointment-types';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const AppointmentTypesContext = createContext();

export const AppointmentTypesProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    appointmentTypes,
    selectedCodesIDs,
    selectedCodes
  } = state;
  const cancelFetch = useRef(() => {});
  const { openModal } = useModal();

  const fetchAppointmentTypes = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_APPOINTMENT_TYPES_REQUEST });

    appointmentTypesApi.getApptTypeById({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_APPOINTMENT_TYPES_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchAppointmentTypes({ page: filter.page + 1 });
    }
  };

  const resetAppointmentTypes = (newFilter) => {
    dispatch({ type: types.RESET_APPOINTMENT_TYPES, payload: newFilter });

    fetchAppointmentTypes({ page: 1, ...newFilter });
  };

  const toggleActiveAppointmentType = (appointmentType) => {
    return appointmentTypesApi.updateAppointmentType(appointmentType).then((appointmentType) => {
      dispatch({ type: types.UPDATE_APPOINTMENT_TYPE, payload: appointmentType });
    });
  };

  const addCodesToSelected = ((codes) => {
    dispatch({ type: types.ADD_CODES_TO_SELECTED, payload: codes });
  });

  const deleteCodesFromSelected = ((codesIDs) => {
    dispatch({ type: types.DELETE_CODES_FROM_SELECTED, payload: codesIDs });
  });

  const toggleCodeSelected = (code) => {
    if (selectedCodesIDs.indexOf(code.id) === -1) addCodesToSelected([ code ]);
    else deleteCodesFromSelected([ code.id ]);
  };

  const allCodesIsSelected = () => {
    return selectedCodesIDs
      .filter((id) => appointmentTypes.find((code) => code.id === id))
      .length === appointmentTypes.length;
  };

  const toggleAllCodesSelected = () => {
    const codesIDs = appointmentTypes.map(({ id }) => id);

    allCodesIsSelected() ? deleteCodesFromSelected(codesIDs) : addCodesToSelected(appointmentTypes);
  };

  const deleteAppointmentType = (appointmentType) => {
    return new Promise((resolve) => {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          appointmentTypesApi.deleteAppointmentType(appointmentType.id).then(() => {
            dispatch({ type: types.DELETE_APPOINTMENT_TYPE, payload: appointmentType.id });
            enqueueSnackbar(`Appt. type "${appointmentType.code}" successfully deleted`, { variant: 'success' });
            resolve();
          });
        },

        onModalRejected: resolve
      });
    });
  };

  const providerValue = {
    isFetched,
    isFetching,
    appointmentTypes,
    selectedCodesIDs,
    selectedCodes,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetAppointmentTypes,
    fetchAppointmentTypes,
    loadNextPage,
    toggleActiveAppointmentType,
    deleteAppointmentType,
    toggleCodeSelected,
    allCodesIsSelected,
    toggleAllCodesSelected
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <AppointmentTypesContext.Provider value={providerValue}>
      {children}
    </AppointmentTypesContext.Provider>
  );
};
