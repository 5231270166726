import { useState } from 'react';
import { Box, List as MuiList } from '@material-ui/core';
import { NestedList } from '../../../../../../components/NestedList';
import { MainContent } from '../../../Dashboard/Widgets/Payment/MainContent';

export const PaymentsContent = () => {
  const [ openListItem, setOpenListItem ] = useState(false);

  const handleOpenListItem = () => {
    setOpenListItem((state) => !state);
  };

  return (
    <Box my={1} mx={2}>
      <NestedList
        disablePadding
        disableDivider
        disableBackground
        isOpen={openListItem}
        buttonSize="small"
        title="Payments"
        TypographyProps={{ fontSize: 'medium' }}
        onOpen={handleOpenListItem}
      >
        <MuiList disablePadding>
          <Box flexGrow={1} height={250} border={1} borderColor="divider">
            <MainContent disableInvoicePreview parentPage="invoice" />
          </Box>
        </MuiList>
      </NestedList>
    </Box>
  );
};
