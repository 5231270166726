import { useContext, useEffect } from 'react';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../../components/Loader';
import { PageBody } from '../../../../../../../components/Page/PageBody';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { CodesContext } from '../../CodesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 52;
export const columnsWidths = {
  code: 130,
  name: 150,
  address: 200,
  state: 100,
  city: 150,
  phone: 160,
  class: 140,
  edi: 140,
  action: 55
};

export const List = ({ baseUrl }) => {
  const classes = useStyles();
  const {
    codes,
    isFetched,
    filter,
    pagination: { total },
    resetCodes
  } = useContext(CodesContext);

  useEffect(() => {
    resetCodes();
  }, []);

  return (
    <Loader
      p={2}
      loading={!isFetched}
      render={() =>
        !codes.length ? (
          <Typography align="center" className={classes.title}>
            No codes found
          </Typography>
        ) : (
          <>
            <TableHeader />

            <PageBody fullHeight disablePaddings>
              <MuiList disablePadding>
                {codes.map((code) => (
                  <Row key={code.id} code={code} baseUrl={baseUrl} />
                ))}
              </MuiList>

              <TablePagination pagination={{ ...filter, total }} onChange={resetCodes} />
            </PageBody>
          </>
        )
      }
    />
  );
};
