import { Droppable } from 'react-beautiful-dnd';
import { makeStyles, Typography } from '@material-ui/core';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const List = ({
  EditForm,
  isCheckedHidden = false,
  checklist = [],
  onUpdate = () => {},
  onDelete = () => {}
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!checklist?.length
        ? <Typography align="center">No items found</Typography>
        : (
          <Droppable droppableId="droppable">
            {({ innerRef, placeholder, droppableProps }) => (
              <div ref={innerRef} { ...droppableProps }>
                {checklist?.map((item, index) => (
                  <Row
                    key={item.id || item.text}
                    index={index}
                    isCheckedHidden={isCheckedHidden}
                    listItem={item}
                    EditForm={EditForm}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                  />
                ))}

                {placeholder}
              </div>
            )}
          </Droppable>
        )
      }
    </div>
  );
};
