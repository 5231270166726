import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { mdiFileAlertOutline, mdiFilter, mdiFilterMinus } from '@mdi/js';
import { Icon } from '@mdi/react';
import { makeStyles, Box, SvgIcon, useTheme, useMediaQuery } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { hasRole } from '../../../../../utils/hasRole';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { Button } from '../../../../../components/Button';
import { RulesView } from '../../../../../components/emails/RuleTemplates/RulesView';
import { IconButton } from '../../../../../components/IconButton';
import { useModal } from '../../../../../components/ModalsProvider';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { Tooltip } from '../../../../../components/Tooltip';
import { UserContext } from '../../../Members/User/UserProvider';
import { AccountsContext } from '../../AccountsContext';
import { EmailsSendModal } from '../../EmailsClient/EmailsSendModal';
import { SavedEmailsContext } from '../SavedEmailsContext';
import { Filter } from './Filter';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const LeftSidebar = ({
  onOpenFiltersBar,
  openFiltersBar,
  onOpenSideBar,
  isOpenSidebar
}) => {
  const classes = useStyles();
  const { accounts } = useContext(AccountsContext);
  const {
    patientItem,
    caseItem,
    fetchSavedMessages
  } = useContext(SavedEmailsContext);
  const { openModal } = useModal();
  const currentUser = useSelector(({ profile }) => profile.user);
  const userContext = useContext(UserContext);
  const params = useParams();
  const isExistRulesButton = !!params.caseID || !!params.patientID;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const openSendEmailModal = () => {
    accounts.length &&
      openModal(EmailsSendModal, {
        payload: {
          accounts,
          caseItem,
          patient: userContext?.user || currentUser || null,
          to: patientItem ? [ patientItem ] : []
        },
        onModalResolved: () => {
          setTimeout(() => {
            fetchSavedMessages();
          }, 30 * 1000);
        }
      });
  };

  const openRulesModal = () => {
    if (accounts.length) {
      openModal(RulesView, {
        payload: {
          model_id: params.caseID || params.patientID || null,
          model_type: params.caseID ? 'case' : 'patient',
          model: caseItem || patientItem
        }
      });
    }
  };

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        ml={3}
        mr={isTablet ? 1 : 2}
        my={1}
      >
        {!hasRole(rolesMap.patient) && (
          <Button
            fullWidth={!isExistRulesButton}
            variant="contained"
            color="primary"
            size={isTablet ? 'small' : 'medium'}
            onClick={openSendEmailModal}
          >
            Compose message
          </Button>
        )}

        {isExistRulesButton &&
          <Tooltip isExistTooltip title="Emails rules">
            <IconButton color="primary" onClick={openRulesModal}>
              <SvgIcon>
                <Icon path={mdiFileAlertOutline} />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        }

        {isTablet && (
          <Box ml={2} display="flex" alignItems="center">
            <IconButton color="primary" onClick={onOpenFiltersBar}>
              <SvgIcon color="inherit">
                {!openFiltersBar ? <Icon path={mdiFilter} /> : <Icon path={mdiFilterMinus} />}
              </SvgIcon>
            </IconButton>

            <IconButton color="primary" onClick={onOpenSideBar}>
              <SvgIcon color="inherit">
                {isOpenSidebar ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon color="primary" />}
              </SvgIcon>
            </IconButton>
          </Box>
        )}
      </Box>

      <Scrollbars>
        <Filter />
      </Scrollbars>
    </div>
  );
};
