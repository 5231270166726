import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { Box } from '@material-ui/core';
import { Select } from '../../../../../../components/FormField';
import { templateComponents } from '../CreateTemplateReport/templateComponents';
import { sectionsOptions, sectionsTitleMap } from '../sectionsOptions';

export const ReportSections = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleChangeReportSections = (options) => {
    Object.keys(sectionsTitleMap).forEach((item) => {
      const option = options?.find(({ data }) => data === item);

      if (!option && !!get(values, sectionsTitleMap[item])) {
        setFieldValue(sectionsTitleMap[item], null);
      }

      if (option) {
        setFieldValue(option?.value, get(values, option?.value) || null);
      }
    });
  };

  return (
    <>
      <Box py={2}>
        <Select
          isMulti
          required
          name="sections"
          label="Select sections for the report"
          options={sectionsOptions}
          onChange={handleChangeReportSections}
          TextFieldProps={{ required: true }}
        />
      </Box>

      {values?.sections?.map((item, i) => {
        const Component = templateComponents[item?.data];

        return Component && (
          <Component key={i} />
        );
      })}
    </>
  );
};
