import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <ListRow header={true}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        Name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.size}>
        Size
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        Uploaded at
      </ListRowCell>

      {!hasRole(rolesMap.advocate) &&
        <ListRowCell flexBasis={columnsWidths.action}/>
      }
    </ListRow>
  );
};
