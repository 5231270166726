export const styles = ({ spacing, palette, breakpoints }) => ({
  toolbar: {
    paddingTop: spacing(),
    paddingBottom: spacing(),
    paddingLeft: spacing(4),
    background: palette.background.primary,

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      paddingLeft: spacing(1.5)
    }
  }
});
