import { Fragment } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Grid, IconButton, SvgIcon } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { conditionOptions, conditionToFieldMap, renderOptionField } from '../../options';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { useModal } from '../../../ModalsProvider';
import { Select } from '../../../FormField';
import { Button } from '../../../Button';

export const BodyConditions = () => {
  const { values } = useFormikContext();
  const { openModal } = useModal();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  return (
    <FieldArray
      name="conditions"
      render={({ remove, push }) => (
        <>
          <Grid container spacing={5}>
            {values?.conditions?.map((condition, index) => (
              <Fragment key={index}>
                <Grid item xs={6}>
                  <Select
                    label="Select a condition"
                    name={`conditions.${index}.key`}
                    options={conditionOptions}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Box display="flex">
                    <Box flexGrow={1} mb={4} overflow="hidden">
                      {values?.conditions[index]?.key &&
                        renderOptionField(
                          conditionToFieldMap,
                          values.conditions[index]?.key?.value,
                          {
                            name: `conditions.${index}.values`
                          }
                        )
                      }
                    </Box>

                    <Box ml={1} mb={4}>
                      {values?.conditions?.length > 1 &&
                        <Box mt={1.5}>
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={handleClearField(remove, index)}
                          >
                            <SvgIcon color="error">
                              <CloseIcon />
                            </SvgIcon>
                          </IconButton>
                        </Box>
                      }
                    </Box>
                  </Box>
                </Grid>
              </Fragment>
            ))}
          </Grid>

          <Box mt={1} mb={2}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => push({ key: conditionOptions[0], values: '' })}
            >
              Add another condition
            </Button>
          </Box>
        </>
      )}
    />
  );
};
