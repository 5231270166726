import { Fragment, useContext } from 'react';
import cn from 'classnames';
import { Box, Collapse, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Scrollbars } from '../../../../components/Scrollbars';
import { UserInfo as MembersUserInfo } from '../../Members/User/MainContent/UserInfo';
import { UserContext } from '../../Members/User/UserProvider';
import { styles } from '../MedicalInfo/MainInfo/LeftSidebar/styles';
import { UserInfo } from './UserInfo';
import { Signature } from './Signature';
import { Account } from './Account';

const useStyles = makeStyles(styles);

export const LeftSidebar = ({ user, openUserInfo = false, toggleInfoOpen = () => {} }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const Wrapper = isMobile ? Collapse : Fragment;
  const wrapperProps = isMobile ? {
    in: openUserInfo,
    component: Box,
    height: 55,
    flexShrink: 0,
    collapsedHeight: 55
  } : {};

  return (
    <div
      className={
        cn(
          classes.root,
          { [classes.rootInfoClose]: !openUserInfo }
        )
      }
    >
      <Scrollbars>
        <Wrapper {...wrapperProps}>
          {userContext ? (
            <MembersUserInfo openUserInfo={openUserInfo} toggleInfoOpen={toggleInfoOpen} />
          ) : (
            <UserInfo openUserInfo={openUserInfo} toggleInfoOpen={toggleInfoOpen} />
          )}

          <Account user={user} />

          {!userContext && <Signature />}
        </Wrapper>
      </Scrollbars>
    </div>
  );
};
