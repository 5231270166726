export const initialState = {
  isFetched: false,
  isFetching: false,
  filter: {
    page: 1,
    per_page: 12
  },
  pagination: {
    total: 0,
    last_page: 1
  },
  files: [],
  selectedFilesIDs: []
};
