export const steps = [
  'Medical Form info',
  'Physical examination',
  'Diagnostic studies',
  'Treatment & Plan',
  'Discussion',
  'Medical Causation'
];

export const stepsTitle = {
  'first-step': 0,
  'second-step': 1,
  'third-step': 2,
  'fourth-step': 3,
  'fifth-step': 4,
  'six-step': 5
};

export const getExistingFormSteps = (form) => {
  return {
    0: !!form?.forms?.medical_form_info,
    1: !!form?.forms?.physical_examination,
    2: !!form?.forms?.diagnostic_studies,
    3: !!form?.forms?.treatment_plan,
    4: !!form?.forms?.discussion,
    5: !!form?.forms?.medical_causation
  };
};
