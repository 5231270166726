import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow header>
      <ListRowCell flexBasis={columnsWidths.dateFrom}>
        Date From
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateTo}>
        Date To
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.immunizationId}>
        Immunization
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.appointmentBookId}>
        Appointment Book
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        Office
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
