import cn from 'classnames';
import { makeStyles, Grid, Typography, Box, SvgIcon } from '@material-ui/core';
import { mdiLink } from '@mdi/js';
import { Icon } from '@mdi/react';
import moment from 'moment';
import { stopPropagation } from '../../../../../../../helpers/dom';
import {
  SigningMembers
} from '../../../../../../../components/medical/SigningMembers';
import { Popper } from '../../../../../../../components/Popper';
import { Date } from '../../../../../../../components/Date';
import { Fab } from '../../../../../../../components/Fab';
import { formTypesMap } from '../../../../../../../components/medical/forms';
import { FormChildrenInfo } from './FormChildrenInfo';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ form }) => {
  const classes = useStyles();
  const stepsCount = 7;

  return (
    <Grid container spacing={2} alignItems="flex-start" component={Box} pt={1} pb={2}>
      <Grid item xs={8}>
        <Typography noWrap variant="h4" title={form.form_type}>
          {form.form_type}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        {form.form_type !== formTypesMap.follow_up &&
          <Typography
            className={
              cn(
                classes.fillContent,
                { [classes.fillContent_fullfilled]: form.filling === stepsCount }
              )
            }
          >
            {form.filling ? `${form.filling}/${stepsCount}` : `1/${stepsCount}`}
          </Typography>
        }
      </Grid>

      <Grid item xs={6}>
        <Date
          color="textSecondary"
          date={form.created_at || form.updated_at}
          iconFontSize="small"
          format="L"
        />
      </Grid>

      <Grid item xs={6}>
        <Date
          disableMoment
          date={form?.doi_string || moment(form.doi).format('L')}
          color="textSecondary"
          title="DOI"
          iconFontSize="small"
        />
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h5">
          Ins.Type
          <Box component="span" ml={2}>{form?.primary_insurance?.type_insurance || '-'}</Box>
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h5">
          Ins.ID#
          <Box component="span" ml={2}>{form?.primary_insurance?.insuranceId || '-'}</Box>
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h5">Follow Ups / Covid</Typography>
      </Grid>

      <Grid item xs={6}>
        {!!form?.children?.length ?
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <Fab
                  buttonRef={anchorRef}
                  variant="extended"
                  size="small"
                  color="info"
                  endIcon={<SvgIcon><Icon path={mdiLink} /></SvgIcon>}
                  onClick={stopPropagation(handleToggle)}
                >
                  {form?.children?.length}
                </Fab>
              )
            }
          >
            {({ handleClose }) => (
              <FormChildrenInfo
                forms={form.children || []}
                onClose={handleClose}
              />
            )}
          </Popper>
          :
          <Typography color="textSecondary">No info.</Typography>
        }
      </Grid>

      {form?.is_confirmed && (
        <>
          <Grid item xs={6}>
            <Typography variant="h5">Sign info</Typography>
          </Grid>

          <Grid item xs={6}>
            <SigningMembers signInfo={form?.sign_info} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
