import { useState, useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Autocomplete, DatePicker, WeekPicker, KeyboardDatePicker } from '../../index';
import { periodsMap } from './periodsMap';
import { periodsOptions } from './periodsOptions';

export const PeriodPicker = ({
  initialPeriod = periodsMap.month,
  fromDate: fromDateProp,
  toDate: toDateProp,
  onChange = () => {}
}) => {
  const [ period, setPeriod ] = useState(periodsOptions.find(({ value }) => value === initialPeriod));
  const [ fromDate, setFromDate ] = useState(() => {
    return fromDateProp ? moment.unix(fromDateProp) : null;
  });
  const [ lastValidFromDate, setLastValidFromDate ] = useState(fromDate);
  const [ toDate, setToDate ] = useState(() => {
    return toDateProp ? moment.unix(toDateProp) : null;
  });

  const handlePeriodChange = (newPeriod) => {
    const period = newPeriod?.value;
    if (!period) return;

    setPeriod(newPeriod);

    if (period !== periodsMap.range) {
      setFromDate(moment().startOf(period));
      setToDate(moment().endOf(period));
    }
  };

  const handleDateChange = (period) => (formattedDate, date) =>  {
    if (date?.isValid()) {
      setFromDate(date.clone().startOf(period));
      setLastValidFromDate(date.clone().startOf(period));
      setToDate(date.clone().endOf(period));
    } else {
      setFromDate(null);
    }
  };

  const handleRangeFromChange = (date) => {
    setFromDate(date && moment.unix(date).startOf('day'));
  };

  const handleRangeToChange = (date) => {
    setToDate(date && moment.unix(date).endOf('day'));
  };

  const handleFromDateFocus = () => {
    setLastValidFromDate(fromDate);
  };

  const handleFromDateBlur = () => {
    if (!fromDate?.isValid()) {
      setFromDate(lastValidFromDate);
    }
  };

  useEffect(() => {
    onChange({
      period: period.value,
      fromDate,
      toDate
    });
  }, [ period, fromDate, toDate ]);

  useEffect(() => {
    if (period?.value === periodsMap.month && !fromDate && !toDate) {
      setFromDate(moment().startOf(period?.value));
      setToDate(moment().endOf(period?.value));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Autocomplete
          withoutFormik
          disableClearable
          minWidth={100}
          name="period"
          label="Period"
          placeholder="Select period..."
          value={period}
          options={periodsOptions}
          onChange={handlePeriodChange}
        />
      </Grid>

      {period.value === periodsMap.day &&
        <Grid item style={{ width: 124 }}>
          <KeyboardDatePicker
            withoutFormik
            zeroMinWidth
            name="day"
            label="Day"
            value={fromDate}
            TextFieldComponentProps={{
              onFocus: handleFromDateFocus,
              onBlur: handleFromDateBlur
            }}
            onChange={handleDateChange(periodsMap.day)}
          />
        </Grid>
      }

      {period.value === periodsMap.week &&
        <Grid item>
          <WeekPicker
            name="week"
            label="Week"
            value={fromDate}
            onChange={handleDateChange(periodsMap.week)}
          />
        </Grid>
      }

      {period.value === periodsMap.month &&
        <Grid item>
          <DatePicker
            withoutFormik
            name="month"
            label="Month"
            value={fromDate}
            views={[ 'year', 'month' ]}
            openTo="month"
            format="YYYY, MMMM"
            onChange={handleDateChange(periodsMap.month)}
          />
        </Grid>
      }

      {period.value === periodsMap.year &&
        <Grid item style={{ width: 90 }}>
          <KeyboardDatePicker
            withoutFormik
            zeroMinWidth
            name="year"
            label="Year"
            value={fromDate}
            views={[ 'year' ]}
            format="YYYY"
            mask="____"
            placeholder="ГГГГ"
            TextFieldComponentProps={{
              onFocus: handleFromDateFocus,
              onBlur: handleFromDateBlur
            }}
            onChange={handleDateChange(periodsMap.year)}
          />
        </Grid>
      }

      {period.value === periodsMap.range &&
        <>
          <Grid item style={{ width: 160 }}>
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              withoutFormik
              name="rangeFrom"
              label="From"
              value={fromDate}
              onChange={handleRangeFromChange}
            />
          </Grid>

          <Grid item style={{ width: 160 }}>
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              withoutFormik
              name="rangeTo"
              label="To"
              value={toDate}
              onChange={handleRangeToChange}
            />
          </Grid>
        </>
      }
    </Grid>
  );
};
