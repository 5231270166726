import { api } from '../../api';

export const fetchAppointmentBooks = (config) => {
  return api.get('/appointment-books', config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => { throw error; });
};

export const fetchApptBooksList = (config) => {
  return api.get('/appointment-books/list', config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => { throw error; });
};

export const fetchAppointmentBook = (id, config) => {
  return api.get(`/appointment-books/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createAppointmentBook = (data) => {
  return api.post('/appointment-books', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const updateAppointmentBook = ({ id, ...data }) => {
  return api.put(`/appointment-books/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteAppointmentBook = (id) => {
  return api.delete(`/appointment-books/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
