import { useContext, useEffect } from 'react';
import cn from 'classnames';
import { fade } from '@material-ui/core/styles';
import { makeStyles, Box } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { ColorPreview } from '../../../../../../../../components/ColorPreview';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../../../components/users';
import { Date } from '../../../../../../../../components/Date';
import { Loader } from '../../../../../../../../components/Loader';
import { UserLink } from '../../../../../../../../components/UserLink';
import { AppointmentsContext, arrivalTypes } from '../../../../../../../../components/appointments';
import { columnsWidths } from '../../../SideBar';

const useStyles = makeStyles(({ spacing, palette, transitions }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1.5, 2),
    cursor: 'pointer',
    transition: transitions.create([ 'backgroundColor' ]),

    '&:hover': {
      backgroundColor: palette.grey[50]
    }
  },

  selected: {
    backgroundColor: fade(palette.primary.light, 0.2)
  }
}));

export const Row = ({
  item: appointment = {},
  selectedAppointment,
  activeClaim,
  isLoaded,
  recalculateHeight,
  onAppointmentSelect
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { filter } = useContext(AppointmentsContext);

  const handleAppointmentClick = () => {
    onAppointmentSelect(appointment);
  };

  const openUserPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id }
    });
  };

  useEffect(() => {
    recalculateHeight();
  }, [ isLoaded, selectedAppointment?.id !== appointment.id ]);

  return !isLoaded ? <Loader p={2} /> : selectedAppointment?.id !== appointment.id && (
    <div
      className={cn(classes.root, (activeClaim?.id === appointment.id) && classes.selected )}
      onClick={handleAppointmentClick}
    >
      <Box display="flex" flexDirection="column" flexBasis={columnsWidths.date} pr={2}>
        <Date
          color="secondary"
          variant="subtitle2"
          iconFontSize="small"
          format="L"
          date={appointment.appointment_at}
        />

        {!!appointment.arrival_status &&
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <ColorPreview color={arrivalTypes[appointment.arrival_status?.arrival_type]?.color} />
            </Box>

            {appointment.arrival_status?.description}
          </Box>
        }
      </Box>

      <Box display="flex" alignItems="center" flexGrow={1}>
        {filter?.patients?.length ? (
          <UserLink
            noWrap
            size="sm"
            user={appointment.appointment_book?.provider}
            onClick={stopPropagation(openUserPreview(appointment.appointment_book?.provider_id))}
          />
        ) : (
          <UserLink
            noWrap
            size="sm"
            user={appointment.patient}
            onClick={stopPropagation(openUserPreview(appointment.patient_id))}
          />
        )}
      </Box>
    </div>
  );
};
