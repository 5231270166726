import { CurrentSymptomsInjury } from './CurrentSymptomsInjury';
import { PastMedicalHistory } from './PastMedicalHistory';
import { AccidentInfoOrtho } from './AccidentInfoOrtho';
import { GeneralInfoInjury } from './GeneralInfoInjury';
import { AppointmetsInfo } from './AppointmetsInfo';
import { MedicalHistory } from './MedicalHistory';
import { PatientHistory } from './PatientHistory';
import { ReviewSymptoms } from './ReviewSymptoms';
import { ReviewOfSymptoms } from './ReviewOfSymptoms';
import { AccidentInfo } from './AccidentInfo';
import { GeneralInfo } from './GeneralInfo';
import { MiddlePain } from './MiddlePain';
import { Documents } from './Documents';
import { BackPain } from './BackPain';
import { NeckPain } from './NeckPain';

export const componentsMapPrivate = [
  GeneralInfo,
  AppointmetsInfo,
  CurrentSymptomsInjury,
  NeckPain,
  MiddlePain,
  BackPain,
  MedicalHistory,
  ReviewSymptoms,
  Documents
];

export const componentsMapInjury = [
  GeneralInfoInjury,
  AppointmetsInfo,
  CurrentSymptomsInjury,
  AccidentInfo,
  NeckPain,
  MiddlePain,
  BackPain,
  MedicalHistory,
  Documents
];

export const componentsMapInjuryOrtho = [
  GeneralInfoInjury,
  AppointmetsInfo,
  PatientHistory,
  AccidentInfoOrtho,
  PastMedicalHistory,
  ReviewOfSymptoms,
  Documents
];
