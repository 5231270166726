import { useMediaQuery, useTheme } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { Fab } from '../../../../../components/Fab';
import { VisitReasonsContextProvider } from './VisitReasonsContext';
import { List } from './List';

export const VisitReasons = ({ baseUrl }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>
          Visit reasons
        </PageHeaderTitle>

        <PageHeaderActions>
          <Fab
            component={Link}
            size={isMobile ? 'small' : 'medium'}
            to={`${pathname}/create`}
            variant="extended"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add visit reason
          </Fab>
        </PageHeaderActions>
      </PageHeader>

      <PageBody fullHeight disableScrollbars>
        <VisitReasonsContextProvider>
          <List baseUrl={baseUrl} />
        </VisitReasonsContextProvider>
      </PageBody>
    </Page>
  );
};
