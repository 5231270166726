import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import * as rtcApi from '../../../../api/claims/report-type-codes';
import { Select } from '../Select';

export const transformRtcToOption = (code) => code && ({
  value: code.id,
  label: `${code.code} - ${code.description}`,
  data: code
});

const fetchReportTypeCodes = (params) => fetchDataForAsyncSelect((search, { page }) => {
  return rtcApi.fetchReportTypeCodes({ params: { search, page, ...params } });
}, {
  transformDataToOption: transformRtcToOption
});

export const ReportTypeCodesSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      name="report_type_code_id"
      label="Report Type"
      promiseWithOptions={fetchReportTypeCodes(params)}

      {...props}
    />
  );
};
