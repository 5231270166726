import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../ListRow';
import { columnsWidths } from '../Cases';

export const Header = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          Case #
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.date}>
          Date Opened
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.type}>
          Case Type
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.status}>
          Case Status
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.members}>
          Participants
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
