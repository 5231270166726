export const filterFieldsMap = {
  code: 'code',
  description: 'description',
  type: 'type',
  year: 'year',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.code]: 'Code',
  [filterFieldsMap.description]: 'Description',
  [filterFieldsMap.type]: 'Code type',
  [filterFieldsMap.year]: 'Year',
  [filterFieldsMap.orderBy]: 'Order By'
};
