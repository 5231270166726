import { useContext, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../components/TablePagination';
import { Loader } from '../../../../../components/Loader';
import { Cards } from '../../../../../components/Cards';
import { ImmunizationContext } from '../ImmunizationProvider';
import { Card } from './Card';

export const Grid = () => {
  const { isFetched, meta, immunizations, resetImmunizationReports } = useContext(ImmunizationContext);
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    resetImmunizationReports();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={isTablet ? 1 : 2}>
        <Loader p={3} loading={!isFetched} render={
          () => !immunizations.length ? (
            <Box p={3}>
              <Typography align="center">No procedures found</Typography>
            </Box>
          ) : (
            <>
              <Cards size={isTablet ? 290 : 370} list={immunizations} component={Card} />
              <TablePagination borderTop pagination={meta} onChange={resetImmunizationReports} />
            </>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
