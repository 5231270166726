import { api } from '../../index';

export const fetchGuestMedicalForm = (id, config) => {
  return api.get(`/medical-forms/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createGuestMedicalForm = (data) => {
  return api.post('/medical-forms', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestMedicalForm = ({ id, ...data }) => {
  return api.put(`/medical-forms/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const submitForm = (id, config) => {
  return api.get(`/medical-forms/${id}/submit`, config)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestCovidMedicalForm = ({ id, ...data }) => {
  return api.put(`/medical-forms/covid/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const getGuestBodyParts = (config) => {
  return api.get('/medical-forms/body-parts', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const getGuestFormSymptoms = () => {
  return api.get('/medical-forms/get-symptoms')
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestCurrentSymptoms = (data) => {
  return api.post('/medical-forms/current-symptoms', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestCurrentSymptomsPI = (data) => {
  return api.post('/medical-forms/current-symptoms-pi', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
export const updateGuestPatientHistory = (data) => {
  return api.post('/medical-forms/patient-history', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestPainContent = (data) => {
  return api.post('/medical-forms/pain-in', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestPastMedicalHistoryContent = (data) => {
  return api.post('/medical-forms/past-medical-history', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestMedicalHistory = (data) => {
  return api.post('/medical-forms/medical-history', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestReviewOfSystems = (data) => {
  return api.post('/medical-forms/review-of-orthopedic-symptoms', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestReviewOfSymptoms = (data) => {
  return api.post('/medical-forms/review-of-symptoms', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestAccidentInformation = (data) => {
  return api.post('/medical-forms/accident-information', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestAccidentInformationOrtho = (data) => {
  return api.post('/medical-forms/accident-information-ortho', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createGuestMedicalAppt = (data) => {
  return api.post('/medical-forms/appointment', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateGuestMedicalAppt = (data) => {
  return api.put('/medical-forms/appointment', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchGuestProvidersList = (config = {}) => {
  return api.get('/medical-forms/providers', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchGuestApptBooksList = (config = {}) => {
  return api.get('/medical-forms/book-list', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchGuestFreeDatesList = (config = {}) => {
  return api.get('/medical-forms/free-dates', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchGuestMultipleFreeDates = (config = {}) => {
  return api.get('/medical-forms/provider-list', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchMultipleFreeDates = (config = {}) => {
  return api.get('/waiting-lists/provider-list', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchGuestOffices = (config = {}) => {
  return api.get('/medical-forms/offices', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
