import { useContext } from 'react';
import { Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { filtersKeysMap } from '../../../../../store/lastFilters';
import { LayoutContextProvider } from '../../../../../components/LayoutContext';
import { Loader } from '../../../../../components/Loader';
import { Page } from '../../../../../components/Page';
import { Route } from '../../../../../components/router';
import { fieldsKeysMap } from '../../../../../components/users/MedicalInfo';
import { MedicalProfileContext } from '../MainInfo/MedicalProfileProvider';
import { MedicalFormItemProvider } from '../MedicalFormItemProvider';
import { MedicalFormsProvider } from './MedicalFormsProvider';
import { FormItemComponent } from './FormItemComponent';
import { MainContent } from './MainContent';

const hiddenFilterFields = [
  fieldsKeysMap.users,
  fieldsKeysMap.name,
  fieldsKeysMap.search
];

export const MedicalForms = () => {
  const { isFetched, medicalProfile, medicalInfoPathname } = useContext(MedicalProfileContext);

  const initialState = {
    filter: {
      medical_id: medicalProfile.id
    },
    medicalInfoPathname
  };

  return (
    <LayoutContextProvider>
      <Box width="100%" height="100%" minHeight="100%" bgcolor="grey.100" pb={0}>
        <Page>
          <Loader loading={!isFetched} p={3} render={
            () => (
              <Switch>
                <Route
                  exact
                  path={`${medicalInfoPathname}/medical-forms`}
                  render={() => (
                    <MedicalFormsProvider initialState={initialState}>
                      <MainContent
                        filterKey={filtersKeysMap.patient_medical_forms}
                        hiddenFilterFields={hiddenFilterFields}
                      />
                    </MedicalFormsProvider>
                  )}
                />

                <Route
                  strict
                  path={`${medicalInfoPathname}/medical-forms/`}
                  render={() => (
                    <MedicalFormItemProvider initialState={initialState}>
                      <FormItemComponent medicalInfoPathname={medicalInfoPathname} />
                    </MedicalFormItemProvider>
                  )}
                />
              </Switch>
            )}
          />
        </Page>
      </Box>
    </LayoutContextProvider>
  );
};
