import { useContext } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { PageBody } from '../../../../../components/Page';
import { FiltersBar } from '../FiltersBar';
import { Grid } from '../Grid';
import { List } from '../List';

export const PatientsPage = ({
  filterKey,
  FilterBarWrapper,
  filterBarWrapperProps,
  ScrollWrapper,
  scrollWrapperProps
}) => {
  const { viewVariant } = useContext(LayoutContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageBody fullHeight disablePaddings disableScrollbars>
      <Box height="100%" display="flex" flexDirection="column">
        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <FiltersBar filterKey={filterKey} />
          </FilterBarWrapper>
        </ScrollWrapper>

        <Box flexGrow={1}>
          {!isTablet ? (
            (viewVariant === viewVariantsMap.list)
              ? <List />
              : (viewVariant === viewVariantsMap.grid)
                ? <Grid /> : null
          ) : (
            <Grid />
          )}
        </Box>
      </Box>
    </PageBody>
  );
};
