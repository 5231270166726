export const filterFieldsMap = {
  fileNumber: 'file_number',
  name: 'name',
  createdFrom: 'created_from',
  createdTo: 'created_to',
  statuses: 'statuses',
  caseRoles: 'case_roles',
  users: 'users',
  types: 'types',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.fileNumber]: 'Case Number',
  [filterFieldsMap.name]: 'Case Name',
  [filterFieldsMap.createdFrom]: 'Created From',
  [filterFieldsMap.createdTo]: 'Created To',
  [filterFieldsMap.statuses]: 'Case Statuses',
  [filterFieldsMap.caseRoles]: 'Users roles',
  [filterFieldsMap.users]: 'Case Participants',
  [filterFieldsMap.types]: 'Case Types',
  [filterFieldsMap.orderBy]: 'Order By'
};
