import { useContext } from 'react';
import cn from 'classnames';
import { Box, List as MuiList, makeStyles, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { ReportsPreviewContext } from '../../../../ReportsPreviewProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const columnsWidths = {
  name: 200,
  value: 200,
  range: 200
};

export const ItemsList = () => {
  const classes = useStyles();
  const { selectedItem: test } = useContext(ReportsPreviewContext);

  return (
    <Box display="flex" flexDirection="column" pb={5}>
      <MuiList disablePadding>
        <ListRow header className={cn(classes.row, classes.row_header)}>
          <ListRowCell grow flexBasis={columnsWidths.name}>
            Medication Name
          </ListRowCell>

          <ListRowCell grow flexBasis={columnsWidths.value}>
            Your Value
          </ListRowCell>

          <ListRowCell grow flexBasis={columnsWidths.range}>
            Standart Range
          </ListRowCell>
        </ListRow>

        {!!test?.items?.length ? test?.items?.map((item) => (
          <ListRow key={item?.test_id} className={classes.row}>
            <ListRowCell grow noWrap flexBasis={columnsWidths.name}>
              <Typography noWrap>
                {item?.name}
              </Typography>
            </ListRowCell>

            <ListRowCell grow flexBasis={columnsWidths.value}>
              <Box color="success.main">
                <Typography noWrap color="inherit" variant="h5">
                  {item.value}
                </Typography>
              </Box>
            </ListRowCell>

            <ListRowCell grow flexBasis={columnsWidths.range}>
              <Typography>
                {item.standard_range}
              </Typography>
            </ListRowCell>
          </ListRow>
        )) : (
          <ListRow className={classes.row}>
            <ListRowCell grow noWrap flexBasis={columnsWidths.name}>
              <Typography noWrap>
                {test?.test?.name}
              </Typography>
            </ListRowCell>

            <ListRowCell grow flexBasis={columnsWidths.value}>
              <Box color="success.main">
                <Typography noWrap color="inherit" variant="h5">
                  {test?.value}
                </Typography>
              </Box>
            </ListRowCell>

            <ListRowCell grow flexBasis={columnsWidths.range}>
              <Typography>
                {test?.test?.standard_range}
              </Typography>
            </ListRowCell>
          </ListRow>
        )}
      </MuiList>
    </Box>
  );
};
