import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { orderByOptions } from '../../../../files-common';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.original_filename &&
        <>
          <Grid item xs={4}>
            <Typography>
              File name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.original_filename}
            </Typography>
          </Grid>
        </>
      }

      {params?.updated_after &&
        <>
          <Grid item xs={4}>
            <Typography>
              Update after:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment.unix(params?.updated_after).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.updated_before &&
        <>
          <Grid item xs={4}>
            <Typography>
              Update before:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment.unix(params?.updated_before).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {!!params?.cases?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Cases:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.cases?.map((item, index) => (
                <Typography noWrap key={index} title={item.name}>
                  {item.name + ' '}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.tasks?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Tasks:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.tasks?.map((item, index) => (
                <Typography noWrap key={index} title={item.name}>
                  {item.name + ' '}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.order_by &&
        <>
          <Grid item xs={4}>
            <Typography>
              Order:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {orderByOptions.find(({ value }) => value === params.order_by)?.label}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
