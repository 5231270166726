import { useContext } from 'react';
import { Tooltip } from '@material-ui/core';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import AddIcon from '@material-ui/icons/Add';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { LayoutContext, viewVariantsMap } from '../../../LayoutContext';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../Page';
import { useModal } from '../../../ModalsProvider';
import { IconButton } from '../../../IconButton';
import { Fab } from '../../../Fab';
import { CreateScheduleEventModal } from '../../CreateScheduleEventModal';
import { ScheduleContext } from '../../ScheduleProvider';
import { CalendarContext } from '../CalendarProvider';

export const Header = ({ isMobileFilterActive, isTablet, filterOpenButton, createScheduleEventModalProps = {} }) => {
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const calendarContext = useContext(CalendarContext);
  const scheduleContext = useContext(ScheduleContext);

  const createEvent = () => {
    const start = scheduleContext?.filter?.started_from;
    const end = scheduleContext?.filter?.started_to;

    openModal(CreateScheduleEventModal, {
      payload: {
        ...createScheduleEventModalProps,

        initialValues: {
          ...createScheduleEventModalProps.initialValues,

          started_at: start,
          finished_at: end
        }
      },

      onModalResolved: (event) => {
        if (event.recurring_rule) {
          calendarContext.reloadCalendar();
        } else {
          calendarContext.addEvent(event);
        }

        scheduleContext.reloadEvents();
      }
    });
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  return (
    <PageHeader>
      {!isTablet &&
        <PageHeaderTitle>
          Schedule Events
        </PageHeaderTitle>
      }

      <PageHeaderActions startPosition>
        {!hasRole(rolesMap.client, rolesMap.patient) &&
          <>
            <Fab
              variant="extended"
              color="primary"
              size={isTablet ? 'small' : 'medium'}
              startIcon={<AddIcon/>}
              onClick={createEvent}
            >
              {isTablet ? 'Add' : 'Add event'}
            </Fab>
          </>
        }
      </PageHeaderActions>

      <PageHeaderActions disableSpacing>
        {isMobileFilterActive && filterOpenButton}

        {isTablet || (
          <>
            <Tooltip title="Calendar view">
              <IconButton
                color={viewVariant === viewVariantsMap.calendar ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.calendar)}
              >
                <EventNoteOutlinedIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="List view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </PageHeaderActions>
    </PageHeader>
  );
};
