import { fade } from '@material-ui/core/styles';

export const getFilledButtonColorStyle = ({ palette, color }) => palette[color] ? {
  color: palette[color].contrastText,
  backgroundColor: palette[color].main,

  '&:hover': {
    backgroundColor: palette[color].dark,

    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: palette[color].main
    }
  }
} : {};

export const getOutlinedButtonColorStyle = ({ palette, color }) => palette[color] ? {
  color: palette[color].main,
  border: '1px solid',

  '&:hover': {
    backgroundColor: fade(palette[color].main, palette.action.hoverOpacity)
  }
} : {};

export const getTextButtonColorStyle = ({ palette, color }) => palette[color] ? {
  color: palette[color].main,

  '&:hover': {
    backgroundColor: fade(palette[color].main, palette.action.hoverOpacity)
  }
} : {};

export const styles = ({ palette }) => ({
  root: ({ color, variant }) => variant === 'contained'
    ? getFilledButtonColorStyle({ palette, color })
    : variant === 'outlined'
      ? getOutlinedButtonColorStyle({ palette, color })
      : getTextButtonColorStyle({ palette, color })
});
