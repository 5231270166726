export const styles = ({ spacing, palette }) => ({
  root: {
    height: 70,
    marginTop: spacing(2),
    border: `1px dashed ${palette.grey[200]}`,
    backgroundColor: palette.grey[25]
  },

  root_column: {
    padding: spacing(1, 2),
    overflow: 'hidden'
  },

  root_row: {
    display: 'flex',
    alignItems: 'center'
  },

  content: {
    width: '50%',
    padding: spacing(1, 2)
  }
});
