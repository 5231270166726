import cn from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  }
}));

export const ModalContainer = ({ className, component: Component = 'div', children, ...props }) => {
  const classes = useStyles();

  return (
    <Component className={cn(classes.root, className)} {...props}>
      {children}
    </Component>
  );
};
