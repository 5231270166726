import { orderDirectionsMap } from '../../FormField/selects/OrderBy';
import { orderByOptions } from '../orderByOptions';

export const initialState = {
  isFetched: false,
  isFetching: false,
  filter: {
    page: 1,
    per_page: 12,
    order_by: orderByOptions[0].value,
    order_direction: orderDirectionsMap.desc
  },
  pagination: {
    total: 0,
    last_page: 1
  },
  claims: [],
  selectedClaimsIDs: [],
  selectedClaims: []
};
