import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { makeStyles, IconButton, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { preventDefault } from '../../../../../../helpers/dom';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { Popper } from '../../../../../../components/Popper';
import { Loader } from '../../../../../../components/Loader';
import { Label } from '../../../../../../components/Label';
import { templatesTypesColors, templatesTypesNames } from '../../templatesTypes';
import { columnsWidths } from '../List';
import { Menu } from './Menu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  item: template = {},
  isLoaded,
  recalculateHeight
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={2}/> : (
    <ListRow button component={Link} to={`/files/templates/edit/${template.id}`}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <div className={classes.mainInfoCell}>
          {/* TODO: Add icon */}

          <Typography component="div" noWrap>
            {template.name}
          </Typography>

          {isMobile && (
            <Label label={templatesTypesNames[template.type]} color={templatesTypesColors[template.type]} />
          )}
        </div>
      </ListRowCell>

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.type}>
          <Label label={templatesTypesNames[template.type]} color={templatesTypesColors[template.type]} />
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.isSystem}>
        {template.is_system ? 'Yes' : 'No'}
      </ListRowCell>

      <ListRowCell grow={isMobile} flexBasis={columnsWidths.date}>
        {moment.unix(template.created_at).format('L LT')}
      </ListRowCell>

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.date}>
          {moment.unix(template.updated_at).format('L LT')}
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <IconButton buttonRef={anchorRef} onClick={preventDefault(handleToggle)}>
              <MoreVertIcon />
            </IconButton>
          )}
        >
          <Menu template={template} />
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
