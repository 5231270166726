import { Link, Typography } from '@material-ui/core';
import { AuthorizationViewModal } from '../../appointments';
import { useModal } from '../../ModalsProvider';

export const Authorization = ({ before, after }) => {
  const { openModal } = useModal();

  const openAuthorizationPreview = (id) => () => {
    openModal(AuthorizationViewModal, {
      payload: {
        authorizationID: id
      }
    });
  };

  if (!before && after) {
    return (
      <>
        Added&nbsp;<Typography variant="subtitle1">Authorization #</Typography>
        &nbsp;
        <Link onClick={openAuthorizationPreview(after)}>{after}</Link>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;<Typography variant="subtitle1">Authorization #</Typography>
        &nbsp;
        <Link onClick={openAuthorizationPreview(before)}>{before}</Link>
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;<Typography variant="subtitle1">Authorization #</Typography>
        &nbsp;from&nbsp;
        <Link onClick={openAuthorizationPreview(before)}>{before}</Link>
        &nbsp;to&nbsp;
        <Link onClick={openAuthorizationPreview(after)}>{after}</Link>
      </>
    );
  } else {
    return (
      <>
        Empty&nbsp;
        <Typography variant="subtitle1">Authorization #</Typography>
      </>
    );
  }
};
