import { useContext, useState } from 'react';
import moment from 'moment';
import { Box, makeStyles, Typography, List as MuiList, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../helpers/dom';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Divider } from '../../../../../../components/Divider';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Popper } from '../../../../../../components/Popper';
import { Date } from '../../../../../../components/Date';
import { TestPreviewModal } from '../../TestPreviewModal';
import { TestsMenu } from '../../TestsMenu';
import { TestsContext } from '../../TestsProvider';
import {
  minRowHeight,
  columnsWidths,
  widthBreakpointMedium,
  widthBreakpointSmall,
  widthBreakpointLarge,
  widthBreakpointXS
} from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ isChild, width, test }) => {
  const { openModal } = useModal();
  const classes = useStyles();
  const [ isOpen, setIsOpen ] = useState(false);
  const {
    selectedIDs,
    toggleItemSelection,
    updateTestReport,
    fetchTestReports
  } = useContext(TestsContext);
  const isSelected = selectedIDs.indexOf(test.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(test.id);
  };

  const openPreview = () => {
    openModal(TestPreviewModal, {
      payload: {
        testId: test.id,
        onTestUpdate: updateTestReport,
        onListUpdate: fetchTestReports
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  return (
    <>
      <ListRow
        button={!isChild}
        minHeight={minRowHeight}
        onClick={!isChild ? stopPropagation(openPreview) : undefined}
      >
        {!isChild && !hasRole(rolesMap.client, rolesMap.patient) &&
          <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />
        }

        <ListRowCell grow pl={isChild && 5.5} flexBasis={columnsWidths.name}>
          {isChild &&
            <Box component="span" mr={1} width={15} color="text.primary">
              <Divider gutter={0} color="inherit" />
            </Box>
          }

          <Typography noWrap title={test.name || test?.test?.name}>
            {test.name || test?.test?.name || '-'}
          </Typography>
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.value}>
          {!!test?.items?.length ? (
            <>
              <Typography variant="h5" className={classes.count}>
                {test?.items?.length}
              </Typography>

              {!isOpen ? (
                <IconButton color="primary" onClick={stopPropagation(handleOpenListItem)}>
                  <ExpandMore />
                </IconButton>
              ) : (
                <IconButton color="primary" onClick={stopPropagation(handleOpenListItem)}>
                  <ExpandLess />
                </IconButton>
              )}
            </>
          ) : (
            <Typography noWrap>{test.value || '-'}</Typography>
          )}
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.range}>
          <Typography noWrap title={test.standard_range || test?.test?.standard_range}>
            {test.standard_range || test?.test?.standard_range || '-'}
          </Typography>
        </ListRowCell>

        {width > widthBreakpointXS &&
          <ListRowCell grow={width < widthBreakpointMedium} flexBasis={columnsWidths.date}>
            <Tooltip
              isExistTooltip={width < widthBreakpointMedium && !!test.date_collected}
              title={moment.unix(test.date_collected).format('L, LT')}
            >
              <Date
                noWrap
                disableWrapper
                date={test.date_collected}
                format="L, LT"
                iconFontSize="small"
              />
            </Tooltip>
          </ListRowCell>
        }

        {width > widthBreakpointSmall &&
          <ListRowCell grow={width < widthBreakpointMedium} flexBasis={columnsWidths.date}>
            <Tooltip
              isExistTooltip={width < widthBreakpointMedium && !!test.date_resulted}
              title={moment.unix(test.date_resulted).format('L, LT')}
            >
              <Date
                noWrap
                disableWrapper
                date={test.date_resulted}
                format="L, LT"
                iconFontSize="small"
              />
            </Tooltip>
          </ListRowCell>
        }

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.provider}>
            {!isChild &&
              <UserLink
                noWrap
                size="md"
                user={test?.ordered}
                onClick={stopPropagation(openUserPreview(test?.ordered?.id))}
              />
            }
          </ListRowCell>
        }

        <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
          {!isChild &&
            <Popper
              placement="left-start"
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                    <MoreVertIcon/>
                  </IconButton>
                )
              }
            >
              {({ handleClose }) => (
                <TestsMenu test={test} onClose={handleClose} />
              )}
            </Popper>
          }
        </ListRowCell>
      </ListRow>

      {!!test?.items?.length &&
        <Collapse
          unmountOnExit
          in={isOpen}
          timeout="auto"
        >
          <MuiList disablePadding>
            {test?.items?.map((item) => (
              <Row
                isChild
                width={width}
                key={item.test_id}
                test={{
                  ...item,
                  date_resulted: test.date_resulted,
                  date_collected: test.date_collected
                }}
              />
            ))}
          </MuiList>
        </Collapse>
      }
    </>
  );
};
