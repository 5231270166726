import T from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles, Link as MuiLink } from '@material-ui/core';
import { apiModelsMap, itemsRoutesByModels } from '../../../../dataMaps/apiModels';
import { modelTypesMap } from './modelTypesMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  modelType: T.oneOf(Object.values(apiModelsMap)),
  model: T.object
};

export const ModelLink = ({ modelType, model }) => {
  const classes = useStyles();
  const Icon = modelTypesMap[modelType]?.icon;
  const link = itemsRoutesByModels[modelType]?.(model.id);

  const handleClick = (event) => {
    event.stopPropagation();
  };

  return !!link && (
    <MuiLink
      component={Link}
      to={link}
      className={classes.root}
      onClick={handleClick}
    >
      <div
        title={modelTypesMap[modelType].title}
        className={classes.label}
      >
        <Icon className={classes.label__icon} />
      </div>

      {modelTypesMap[modelType].getName(model)}
    </MuiLink>
  );
};

ModelLink.propTypes = propTypes;
