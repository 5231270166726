export const styles = ({ spacing }) => ({
  root: {
    marginTop: spacing(2)
  },

  row: {
    display: 'flex',
    alignItems: 'center'
  },

  textField: {
    maxWidth: 300,
    marginLeft: spacing()
  }
});
