import { api } from '../api';

export const fetchEventTypes = (config) => {
  return api.get('/schedule-event-types', config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => { throw error; });
};

export const createEventType = (data) => {
  return api.post('/schedule-event-types', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const updateEventType = ({ id, ...data }) => {
  return api.put(`/schedule-event-types/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteEventType = (id) => {
  return api.delete(`/schedule-event-types/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
