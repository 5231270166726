import { useContext, useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { Fab } from '../../../../../../../components/Fab';
import { Loader } from '../../../../../../../components/Loader';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { MedicalProfileContext } from '../../MedicalProfileProvider';
import { styles } from '../PatientInfo/styles';
import { Contact } from './Contact';
import { ContactsModal } from './ContactsModal';
import { Employer } from './Employer';

const useStyles = makeStyles(styles);

export const Contacts = () => {
  const classes = useStyles();
  const { isFetched, medicalProfile } = useContext(MedicalProfileContext);
  const [ initialValues, setInitialValues ] = useState(null);
  const { openModal } = useModal();

  const openWidgetModal = () => {
    openModal(ContactsModal, {
      payload: { initialValues },
      onModalResolved: (data) => {
        setInitialValues(data);
      }
    });
  };

  useEffect(() => {
    isFetched && setInitialValues(medicalProfile);
  }, [ medicalProfile ]);

  return (
    <ContentCard
      autoHeightMax={800}
      className={classes.root}
      title="Contacts/Emergency Contact"
      icon={
        <Fab
          size="small"
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={openWidgetModal}
        >
          Add
        </Fab>
      }
    >
      <Loader loading={!initialValues} render={
        () => (!!initialValues?.contacts ?
          <>
            <Employer
              contacts={{ id: initialValues.id, ...initialValues.contacts }}
              users={initialValues?.contacts?.employer || []}
              setInitialValues={setInitialValues}
            />

            <Contact
              contacts={{ id: initialValues.id, ...initialValues.contacts }}
              users={initialValues?.contacts?.contact || []}
              setInitialValues={setInitialValues}
            />
          </>
          :
          <Typography align="center">No contacts found</Typography>
        )}
      />
    </ContentCard>
  );
};
