import { Grid, Box, Typography, SvgIcon } from '@material-ui/core';
import { mdiLink } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Date } from '../../../../../../../../components/Date';
import { IconButton } from '../../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { Divider } from '../../../../../../../../components/Divider';
import { UserLink } from '../../../../../../../../components/UserLink';
import { FormPreviewModal } from '../../../../MedicalForms/FormPreviewModal';
import { NotesContent } from '../NotesContent';

export const PatientContent = ({ initialValues }) => {
  const { openModal } = useModal();

  const openFormPreviewModal = () => {
    openModal(FormPreviewModal, {
      payload: {
        formID: initialValues?.last_medical_form?.id
      }
    });
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={2}>
        <Typography color="textSecondary">
          MRN
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography noWrap variant="h5">
          {initialValues.med_rec || '-'}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">
          Ext. MRN
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography noWrap variant="h5">
          {initialValues.ext_med_rec || '-'}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider type="dashed" />
      </Grid>

      <Grid item xs={6}>
        <Typography color="textSecondary">
          Referring Physician
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <UserLink
          size="md"
          variant="h5"
          user={initialValues.referring_physician}
        />
      </Grid>

      <Grid item xs={6}>
        <Typography color="textSecondary">
          Referral Name
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <UserLink
          size="md"
          variant="h5"
          user={initialValues.preferred}
        />
      </Grid>

      <Grid item xs={6}>
        <Typography color="textSecondary">
          Pri. Care Physician
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <UserLink
          size="md"
          variant="h5"
          user={initialValues.treating_physician}
        />
      </Grid>

      <Grid item xs={6}>
        <Typography color="textSecondary">
          {initialValues?.last_medical_form?.doi
            ? 'Date of Injury'
            : 'Created at'
          }
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Box display="flex" alignItems="center" flexWrap="nowrap">
          <Date
            variant="h5"
            date={initialValues?.last_medical_form?.doi || initialValues?.last_medical_form?.created_at}
          />

          {initialValues?.last_medical_form?.id &&
            <Box ml={2}>
              <Tooltip
                isExistTooltip={!!initialValues?.last_medical_form?.id}
                title="Show form"
              >
                <IconButton color="primary" size="small" onClick={openFormPreviewModal}>
                  <SvgIcon><Icon path={mdiLink} /></SvgIcon>
                </IconButton>
              </Tooltip>
            </Box>
          }
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Divider type="dashed" />
      </Grid>

      <Grid item xs={12}>
        <NotesContent notes={initialValues.notes} />
      </Grid>
    </Grid>
  );
};
