import { Date, Case, UserActivityLink, CurrencyFormat } from '../../fields';
import { RecedivedFounds } from '../RecedivedFounds';

export const negotiationsFieldsMap = {
  case: (before, after) => <Case before={before} after={after} fieldLabel="Case" />,
  user: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="User" />,
  created_at: (before, after) => <Date before={before} after={after} fieldLabel="Created At" />,
  deleted_at: (before, after) => <Date before={before} after={after} fieldLabel="Deleted At" />,
  updated_at: (before, after) => <Date before={before} after={after} fieldLabel="Updated At" />,
  offer_date: (before, after) => <Date before={before} after={after} fieldLabel="Offer Date" />,
  counter_date: (before, after) => <Date before={before} after={after} fieldLabel="Counter Date" />,
  settled_date: (before, after) => <Date before={before} after={after} fieldLabel="Settled Date" />,
  demand_due_date: (before, after) => <Date before={before} after={after} fieldLabel="Demand Due Date" />,
  demand_sent_date: (before, after) => <Date before={before} after={after} fieldLabel="Demand Sent Date" />,
  demand_um_date: (before, after) => <Date before={before} after={after} fieldLabel="Demand Um Date" />,
  settled_um_date: (before, after) => <Date before={before} after={after} fieldLabel="Settled Um Date" />,
  demand_um_due_date: (before, after) => <Date before={before} after={after} fieldLabel="Demand Um Date" />,
  offer_amount: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Offer Amount" />
  ),
  meds_amount: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Meds Amount" />
  ),
  demand_amount: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Demand Amount" />
  ),
  counter_amount: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Counter Amount" />
  ),
  demand_um_amount: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Demand Um Amount" />
  ),
  atty_costs_custom: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Atty Costs Custom" />
  ),
  settled_um_amount: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Settled Um Amount" />
  ),
  atty_fee_legal_costs: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Atty Fee Legal Costs" />
  ),
  settled_third_party_amount: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Settled Third Party Amount" />
  ),
  atty_costs_six: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Atty Costs Six" />
  ),
  atty_costs_five: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Atty Costs Five" />
  ),
  received_founds: (before, after) => {
    return (
      <RecedivedFounds
        before={before}
        after={after}
        fieldLabel="Received Founds"
        boxProps={{ mr: 3 }}
      />
    );
  }
};
