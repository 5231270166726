import { useContext } from 'react';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { PrescriptionsContext } from '../../PrescriptionsProvider';
import { columnsWidths, widthBreakpointMedium, widthBreakpointSmall } from '../List';

export const Header = ({ width }) => {
  const { selectedIDs, allItemsIsSelected, toggleAllItemsSelection } = useContext(PrescriptionsContext);

  return (
    <ListRow header>
      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <ListRowCheckbox
          indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
          checked={allItemsIsSelected()}
          onClick={toggleAllItemsSelection}
        />
      }

      <ListRowCell grow={width < widthBreakpointMedium} flexBasis={columnsWidths.name}>
        Prescription #
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        Prescribed
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.ndc}>
        Name Medication/Drug Name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.approved}>
        Approved by
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.office}>
          Office
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.actions} />
    </ListRow>
  );
};
