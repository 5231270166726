import { Box, Typography } from '@material-ui/core';
import { Editor } from '../../../../../../../components/FormField/Editor';

export const MedicalCausation = () => {
  return (
    <>
      <Box mt={3}>
        <Typography variant="h4">
          Medical Causation
        </Typography>
      </Box>

      <Editor name="forms.medical_causation.text" margin="normal" />
    </>
  );
};
