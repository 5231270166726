export const styles = ({ breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight:  ({ maxHeight = '100%' }) => maxHeight,
    height: ({ maxHeight = '100%' }) => maxHeight,
    width: '100%',

    [breakpoints.up('md')]: {
      minHeight: 300
    }
  }
});
