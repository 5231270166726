import { useContext, useEffect } from 'react';
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { Page, PageBody, PageHeader } from '../../../../../components/Page';
import { filtersKeysMap } from '../../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { CasesContextProvider } from '../CasesContext';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainWindow = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const filterKey = filtersKeysMap.cases_main;
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('md');

  useEffect(() => {
    if (isDownMd) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ isDownMd ]);

  return (
    <CasesContextProvider>
      <Box display="flex" width="100%" height="100%" bgcolor="grey.100" p={isDownMd ? 0 : 2} pb={1}>
        <Page>
          <PageHeader disableBorderBottom ToolbarProps={{ className: classes.toolbar }}>
            <ActionsBar filterOpenButton={filterOpenButton} />
          </PageHeader>

          <PageBody fullHeight disablePaddings disableScrollbars>
            <Box height="100%" display="flex" flexDirection="column">
              <ScrollWrapper {...scrollWrapperProps}>
                <FilterBarWrapper {...filterBarWrapperProps}>
                  <FiltersBar filterKey={filterKey} />
                </FilterBarWrapper>
              </ScrollWrapper>

              <Box flexGrow={1}>
                {(viewVariant === viewVariantsMap.list) && <List />}
                {(viewVariant === viewVariantsMap.grid) && <Grid />}
              </Box>
            </Box>
          </PageBody>
        </Page>
      </Box>
    </CasesContextProvider>
  );
};
