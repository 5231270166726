import { createContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addFileToRecent } from '../../../../store/dashboard/files/recentlyOpenedFiles';
import * as types from '../FilesContext/types';

export const FilePreviewContext = createContext();

export const FilePreviewContextProvider = ({
  disableFetch,
  children,
  initialFile,
  files
}) => {
  const [ selectedFile, setSelectedFile ] = useState();
  const [ viewWidth, setViewWidth ] = useState();
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState(true);
  const filesLastGlobalAction = useSelector(({ globalActions: { filesLastGlobalAction } }) => filesLastGlobalAction);
  const dispatch = useDispatch();

  const handleFileSelect = (file) => {
    if (!disableFetch && (!selectedFile || file.id !== selectedFile?.id) && !file?.deleted_at) {
      dispatch(addFileToRecent(file));
    }

    setSelectedFile(file);
  };

  const toggleSidebarIsOpen = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  useEffect(() => {
    handleFileSelect(initialFile);
  }, []);

  useEffect(() => {
    if (selectedFile && files.length && !files.find(({ id }) => id === selectedFile?.id)) {
      handleFileSelect({});
    }
  }, [ files ]);

  useEffect(() => {
    if (!selectedFile && !filesLastGlobalAction) {
      return;
    }

    if (filesLastGlobalAction.type === types.UPDATE_FILE) {
      if (selectedFile && filesLastGlobalAction.payload.id === selectedFile?.id) {
        handleFileSelect(filesLastGlobalAction.payload);
      }
    }

    if (filesLastGlobalAction.type === types.DELETE_FILES) {
      if (selectedFile && filesLastGlobalAction.payload?.some((id) => id === selectedFile?.id)) {
        setSelectedFile(null);
      }
    }
  }, [ filesLastGlobalAction ]);

  const providerValue = {
    selectedFile,
    viewWidth,
    sidebarIsOpen,
    setViewWidth,
    handleFileSelect,
    toggleSidebarIsOpen
  };

  return (
    <FilePreviewContext.Provider value={providerValue}>
      {children}
    </FilePreviewContext.Provider>
  );
};
