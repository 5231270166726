import { useHistory, useRouteMatch } from 'react-router-dom';
import { ClaimRow } from '../../../../../../../components/claims';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ClaimPreviewModal } from '../../../ClaimPreviewModal';

export const Row = ({ item: claim = {}, view = 'page', ...props }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { openModal } = useModal();

  const goClaimPage = () => {
    if (view === 'modal') {
      openModal(ClaimPreviewModal, {
        payload: {
          id: claim.id
        }
      });
    } else {
      history.push(`${match.url}/${claim.id}`);
    }
  };

  return (
    <ClaimRow view={view} claim={claim} onClick={goClaimPage} {...props} />
  );
};
