import { api } from '../../api';

export const fetchCaseReminderGroups = (config = {}) => {
  return api.get('/case-reminders/groups', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchCaseReminderGroupsForCase = (caseId, config = {}) => {
  return api.get(`/case-reminders/case/${caseId}/groups`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createCaseReminderGroup = (reminderGroup) => {
  return api.post('/case-reminders/groups', reminderGroup)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateCaseReminderGroup = (reminderGroup) => {
  return api.put(`/case-reminders/groups/${reminderGroup.id}`, reminderGroup)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteCaseReminderGroup = (id) => {
  return api.delete(`/case-reminders/groups/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteCaseReminderCaseGroups = (caseId, config) => {
  return api.delete(`/case-reminders/case/${caseId}/groups`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
