import { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/EditOutlined';
import PeopleAltIcon from '@material-ui/icons/PeopleAltOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import { ChatThreadContext, UpdateChatMembersModal } from '../../../../../../components/chat';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { IconButton } from '../../../../../../components/IconButton';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../helpers/dom';
import { ChatMembers } from '../../ChatMembers';
import { FilesList } from '../FilesList';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const InfoTab = ({ thread }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const scrollElementRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(({ profile }) => profile.user);
  const { updateCurrentThread } = useContext(ChatThreadContext);
  const [ isDetailsOpen, setIsDetailsOpen ] = useState(true);
  const [ isMembersOpen, setIsMembersOpen ] = useState(true);
  const [ isFilesOpen, setIsFilesOpen ] = useState(false);
  const isCreator = currentUser.id === thread?.user?.id;

  const toggleSidebarIsOpen = () => {
    setIsDetailsOpen((isDetailsOpen) => !isDetailsOpen);
  };

  const openUpdateChatModal = () => {
    openModal(UpdateChatMembersModal, {
      payload: { thread },
      onModalResolved: (data) => {
        enqueueSnackbar('Chat members successfully updated!', { variant: 'success' });
        updateCurrentThread(data);
      }
    });
  };

  return (
    <CustomScrollbars
      style={{ height: '100%' }}
      scrollerRef={scrollElementRef}
    >
      <List
        disablePadding
        component="nav"
        className={classes.body}
      >
        <ListItem button className={classes.listItem} onClick={toggleSidebarIsOpen}>
          <ListItemText
            primary={
              <Typography variant="h4">Channel details</Typography>
            }
          />

          {isDetailsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse
          unmountOnExit
          in={isDetailsOpen}
          timeout="auto"
        >
          <List disablePadding>
            {thread?.name &&
              <ListItem>
                <ListItemText
                  primary="Name"
                  secondary={
                    <Typography variant="h5" style={{ wordBreak: 'break-all' }}>{thread.name}</Typography>
                  }
                />
              </ListItem>
            }

            <ListItem>
              <ListItemText
                primary="Description"
                secondary={
                  <Typography variant="h5">
                    {thread.description || 'No description'}
                  </Typography>
                }
              />
            </ListItem>

            {thread.user &&
              <ListItem>
                <Box px={1} display="flex" flexDirection="column">

                  <Typography variant="body1">Created</Typography>

                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Box mr={0.5}>
                      <Typography variant="h5">
                        Created by
                      </Typography>
                    </Box>

                    <UserLink
                      noWrap
                      noAvatar
                      user={thread.user}
                    />

                    <Typography variant="h5">
                      &nbsp;on {moment.unix(thread.created_at).format('MMMM DD, YYYY')}
                    </Typography>
                  </Box>
                </Box>
              </ListItem>
            }
          </List>
        </Collapse>

        <ListItem
          button
          className={classes.listItem}
          onClick={() => setIsMembersOpen(!isMembersOpen)}
        >
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>

          <ListItemText primaryTypographyProps={{ className: classes.membersHeader }}>
            <Typography variant="h4">{thread.users.length} members</Typography>

            {isCreator &&
              <IconButton
                color="primary"
                onClick={stopPropagation(openUpdateChatModal)}
              >
                <EditIcon />
              </IconButton>
            }
          </ListItemText>

          {isMembersOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse
          unmountOnExit
          in={isMembersOpen}
          timeout="auto"
        >
          <ChatMembers owner={thread?.user} members={thread?.users} />
        </Collapse>

        <ListItem
          button
          className={classes.listItem}
          onClick={() => setIsFilesOpen(!isFilesOpen)}
        >
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>

          <ListItemText
            primary={
              <Typography variant="h4">Files</Typography>
            }
          />

          {isFilesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse
          unmountOnExit
          in={isFilesOpen}
          timeout="auto"
        >
          <Box height={400}>
            <FilesList />
          </Box>
        </Collapse>
      </List>
    </CustomScrollbars>
  );
};
