import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow header>
      {!hasRole(rolesMap.client, rolesMap.patient) && (
        <ListRowCell flexBasis={columnsWidths.patient}>
          Patients
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.name}>
        Name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateFrom}>
        Date From
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateTo}>
        Date To
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        Provider
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        Office
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
