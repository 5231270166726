import { FormControlLabel, Grid } from '@material-ui/core';
import { Checkbox, TextField } from '../../../FormField';

export const SessionForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          multiline
          required
          rowsMax={5}
          variant="filled"
          name="note"
          label="Notes"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          label="Send an e-mail with a notification"
          control={<Checkbox name="send_email" />}
        />
      </Grid>
    </Grid>
  );
};
