import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, InputAdornment, Tooltip, useTheme, useMediaQuery } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import LockIcon from '@material-ui/icons/LockOutlined';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { useDebounce } from '../../../../../../helpers/hooks';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { SelectedItemsCount } from '../../../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { IconButton } from '../../../../../../components/IconButton';
import { FiltersBar } from '../../../../../../components/FiltersBar';
import { TextField } from '../../../../../../components/FormField';
import { Page } from '../../../../../../components/Page';
import { Fab } from '../../../../../../components/Fab';
import { CodesContext } from '../CodesContext';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = ({ baseUrl }) => {
  const classes = useStyles();
  const {
    resetCodes,
    pagination,
    selectedCodesIDs,
    massActiveFinancialClass,
    massInactiveFinancialClass,
    massDeleteCodes
  } = useContext(CodesContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [ fields, setFields ] = useState({});
  const debouncedFields = useDebounce(fields, 300);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper
  } = useMobileFilterCollapse();
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
  };

  const toggleMassActivatedItems = () => {
    massActiveFinancialClass(selectedCodesIDs);
  };

  const toggleMassInactivatedItems = () => {
    massInactiveFinancialClass(selectedCodesIDs);
  };

  const handleMassDelete = () => {
    massDeleteCodes(selectedCodesIDs);
  };

  useEffect(() => {
    resetCodes(debouncedFields);
  }, [ debouncedFields ]);

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>Financial class</PageHeaderTitle>
      </PageHeader>

      <PageHeader>
        <PageHeaderActions startPosition>
          <Fab
            size={isTablet ? 'small' : 'medium'}
            component={Link}
            to={`${baseUrl}/financial-class/create`}
            color="primary"
            variant="extended"
            startIcon={<AddIcon />}
          >
            Add financial class
          </Fab>
        </PageHeaderActions>

        {!!selectedCodesIDs.length && (
          <PageHeaderActions>
            <SelectedItemsCount length={selectedCodesIDs.length} total={pagination.total} />
            <VerticalDivider verticalGutters={1} horizontalGutters={1} />

            <IconButton variant="contained" color="warning" onClick={toggleMassInactivatedItems}>
              <Tooltip title="Mass Deactivate Classes">
                <LockIcon />
              </Tooltip>
            </IconButton>

            <IconButton variant="contained" color="success" onClick={toggleMassActivatedItems}>
              <Tooltip title="Mass Activate Classes">
                <LockOpenIcon />
              </Tooltip>
            </IconButton>

            <IconButton variant="contained" color="error" onClick={handleMassDelete}>
              <Tooltip title="Mass Delete Classes">
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </PageHeaderActions>
        )}

        {isTablet && (
          <PageHeaderActions disablePosition>
            {filterOpenButton}
          </PageHeaderActions>
        )}
      </PageHeader>

      <Page variant="outlined" square className={classes.mainContent}>
        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <FiltersBar
              isResetForm
              disableTopPaddings
              initialValues={{
                code: null,
                description: null
              }}
              fields={[
                <TextField
                  label="Code"
                  margin="dense"
                  name="code"
                  placeholder="Search..."
                  onChange={handleFieldChange('code')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className={classes.iconSearch} />
                      </InputAdornment>
                    )
                  }}
                />,
                <TextField
                  label="Description"
                  name="description"
                  placeholder="Search..."
                  onChange={handleFieldChange('description')}
                  margin="dense"
                />
              ]}
            />
          </FilterBarWrapper>
        </ScrollWrapper>

        <List baseUrl={baseUrl} />
      </Page>
    </Page>
  );
};
