import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';

const styles = ({ palette: { grey }, shape: { borderRadius }, typography: { pxToRem, fontWeightMedium } }) => ({
  root: {
    minHeight: 20,
    color: ({ color = grey[500] }) => color,
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
    borderRadius
  }
});

const useStyles = makeStyles(styles);

export const TextLabel = ({ children, color, className, ...props }) => {
  const classes = useStyles({ color });

  return (
    <Typography className={cn(classes.root, className)} {...props}>
      {children}
    </Typography>
  );
};
