import { Dialog, useMediaQuery } from '@material-ui/core';
import { AppointmentsFilterProvider, AppointmentsProvider } from '../index';
import { ModalContainer, ModalHeader, ModalBody } from '../../Modal';
import { Grid } from './Grid';
import { List } from './List';

export const AppointmentsHistoryModal = ({
  payload: {
    patientID
  },
  DialogProps,
  handleModalReject
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Appointments History
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <AppointmentsFilterProvider filter={{ patients: !patientID ? [] : [ patientID ] }}>
            <AppointmentsProvider makeFetchOnMount>
              {isMobile ? <Grid /> : <List />}
            </AppointmentsProvider>
          </AppointmentsFilterProvider>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
