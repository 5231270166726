import { createContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../api';
import * as activitiesApi from '../../../api/activities';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const ActivityContext = createContext();

export const ActivityProvider = ({ allActivities = false, filter: filterProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    error,
    activities,
    pagination,
    isFetching,
    isFetched,
    filter
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchActivities = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_ACTIVITIES_REQUEST });

    activitiesApi.fetchActivities({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({
        type: allActivities ? types.FETCH_ACTIVITIES_WITH_PAGINATION_SUCCESS : types.FETCH_ACTIVITIES_SUCCESS,
        payload: data
      });
    }).catch(({ status }) => {
      if (status === 403) {
        dispatch({ type: types.FETCH_ACTIVITIES_ERROR });
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchActivities({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetActivities = (newFilter) => {
    dispatch({ type: types.RESET_ACTIVITIES, payload: newFilter });

    fetchActivities({ page: 1, ...newFilter });
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(omit(filter, [ 'page', 'model' ]), omit(newFilter, [ 'page', 'model' ]))) {
      resetActivities(newFilter);
    }
  }, [ filter, filterProp ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  const providerValue = {
    error,
    activities,
    isFetching,
    isFetched,
    filter: {
      ...filter,
      ...pagination
    },

    applyFilter,
    loadNextPage,
    fetchActivities,
    resetActivities
  };

  return (
    <ActivityContext.Provider value={providerValue}>
      {children}
    </ActivityContext.Provider>
  );
};
