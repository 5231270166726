import { Yup } from '../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  general_appearance: Yup.string().nullable(),
  spinal_examination_cervical_spine: Yup.object().shape({
    cervical_region: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      midline: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    spinous_processes: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      midline: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    paraspinous_muscles: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      midline: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    trapezius_muscles: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      midline: Yup.string().nullable(),
      left: Yup.string().nullable()
    }))
  }),
  bp: Yup.string().nullable(),
  hr: Yup.string().nullable(),
  rr: Yup.string().nullable(),
  temp: Yup.string().nullable(),
  vas: Yup.string().nullable(),
  alert: Yup.boolean().nullable(),
  no_distress: Yup.boolean().nullable(),
  normal_speech: Yup.boolean().nullable(),
  normal_mood_affect: Yup.boolean().nullable(),
  perrla: Yup.boolean().nullable(),
  chest_clear: Yup.boolean().nullable(),
  heart_reg: Yup.boolean().nullable(),
  motor_strength: Yup.string().nullable(),
  reflexes: Yup.string().nullable(),
  sensory: Yup.string().nullable(),
  back_flexion: Yup.string().nullable(),
  back_extension: Yup.string().nullable(),
  neck_flexion: Yup.string().nullable(),
  neck_extension: Yup.string().nullable(),
  slr_r: Yup.string().nullable(),
  slr_l: Yup.string().nullable(),
  paravertebral_tenderness: Yup.array().nullable(),
  check: Yup.boolean().nullable(),
  text: Yup.string().nullable(),
  other: Yup.string().nullable(),
  range_of_motion_cervical_spine: Yup.array().nullable().of(Yup.object().shape({
    flexion: Yup.array().nullable().of(Yup.object().shape({
      degrees_normal: Yup.string().nullable(),
      range_of_motion: Yup.string().nullable(),
      fraction_of_normal: Yup.string().nullable(),
      pain: Yup.string().nullable()
    })),
    extension: Yup.array().nullable().of(Yup.object().shape({
      degrees_normal: Yup.string().nullable(),
      range_of_motion: Yup.string().nullable(),
      fraction_of_normal: Yup.string().nullable(),
      pain: Yup.string().nullable()
    })),
    right_bending: Yup.array().nullable().of(Yup.object().shape({
      degrees_normal: Yup.string().nullable(),
      range_of_motion: Yup.string().nullable(),
      fraction_of_normal: Yup.string().nullable(),
      pain: Yup.string().nullable()
    })),
    left_bending: Yup.array().nullable().of(Yup.object().shape({
      degrees_normal: Yup.string().nullable(),
      range_of_motion: Yup.string().nullable(),
      fraction_of_normal: Yup.string().nullable(),
      pain: Yup.string().nullable()
    })),
    right_rotation: Yup.array().nullable().of(Yup.object().shape({
      degrees_normal: Yup.string().nullable(),
      range_of_motion: Yup.string().nullable(),
      fraction_of_normal: Yup.string().nullable(),
      pain: Yup.string().nullable()
    })),
    left_rotation: Yup.array().nullable().of(Yup.object().shape({
      degrees_normal: Yup.string().nullable(),
      range_of_motion: Yup.string().nullable(),
      fraction_of_normal: Yup.string().nullable(),
      pain: Yup.string().nullable()
    }))
  })),
  motor_strength_upper_extremities: Yup.array().nullable().of(Yup.object().shape({
    deltoids: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    rotator_cuffs: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    biceps: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    triceps: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    supinators: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    pronator_teres: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    wrist_extensors: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    wrist_flexors: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    hand_intrinsics: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    }))
  })),
  reflexes_lower_extremities: Yup.array().nullable().of(Yup.object().shape({
    biceps: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    triceps: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    })),
    brachioradialis: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    }))
  })),
  special_tests: Yup.array().nullable().of(Yup.object().shape({
    cervical_compression_test: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      left: Yup.string().nullable()
    }))
  })),
  spinal_examination_lumbar_spine: Yup.array().nullable().of(Yup.object().shape({
    note: Yup.string().nullable(),
    paraspinal_muscle_tenderness: Yup.array().nullable().of(Yup.object().shape({
      right: Yup.string().nullable(),
      midline: Yup.string().nullable(),
      left: Yup.string().nullable()
    }))
  })),
  lumbar_spine: Yup.array().nullable().of(Yup.object().shape({
    range_of_motion_cervical_spine: Yup.array().nullable().of(Yup.object().shape({
      flexion: Yup.array().nullable().of(Yup.object().shape({
        degrees_normal: Yup.string().nullable(),
        range_of_motion: Yup.string().nullable(),
        fraction_of_normal: Yup.string().nullable(),
        pain: Yup.string().nullable()
      })),
      extension: Yup.array().nullable().of(Yup.object().shape({
        degrees_normal: Yup.string().nullable(),
        range_of_motion: Yup.string().nullable(),
        fraction_of_normal: Yup.string().nullable(),
        pain: Yup.string().nullable()
      })),
      right_bending: Yup.array().nullable().of(Yup.object().shape({
        degrees_normal: Yup.string().nullable(),
        range_of_motion: Yup.string().nullable(),
        fraction_of_normal: Yup.string().nullable(),
        pain: Yup.string().nullable()
      })),
      left_bending: Yup.array().nullable().of(Yup.object().shape({
        degrees_normal: Yup.string().nullable(),
        range_of_motion: Yup.string().nullable(),
        fraction_of_normal: Yup.string().nullable(),
        pain: Yup.string().nullable()
      })),
      right_rotation: Yup.array().nullable().of(Yup.object().shape({
        degrees_normal: Yup.string().nullable(),
        range_of_motion: Yup.string().nullable(),
        fraction_of_normal: Yup.string().nullable(),
        pain: Yup.string().nullable()
      })),
      left_rotation: Yup.array().nullable().of(Yup.object().shape({
        degrees_normal: Yup.string().nullable(),
        range_of_motion: Yup.string().nullable(),
        fraction_of_normal: Yup.string().nullable(),
        pain: Yup.string().nullable()
      }))
    })),
    motor_strenght_lover_extremities: Yup.array().nullable().of(Yup.object().shape({
      quadriceps: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        left: Yup.string().nullable()
      })),
      hamstrings: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        left: Yup.string().nullable()
      })),
      gastrocsoleus: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        left: Yup.string().nullable()
      })),
      tibialis_anterior: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        left: Yup.string().nullable()
      })),
      extensor_hallucis_longus: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        left: Yup.string().nullable()
      })),
      common_toe_extensors: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        left: Yup.string().nullable()
      })),
      iliopsoas: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        left: Yup.string().nullable()
      })),
      gluteus_medius: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        left: Yup.string().nullable()
      }))
    })),
    reflexes_lower_extremities: Yup.array().nullable().of(Yup.object().shape({
      knee_jerks: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        left: Yup.string().nullable()
      })),
      ankle_jerks: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        left: Yup.string().nullable()
      }))
    })),
    special_tests: Yup.array().nullable().of(Yup.object().shape({
      straight_leg_raising_test: Yup.array().nullable().of(Yup.object().shape({
        right: Yup.string().nullable(),
        right_pain: Yup.string().nullable(),
        left: Yup.string().nullable(),
        left_pain: Yup.string().nullable()
      }))
    }))
  }))
});
