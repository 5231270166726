import T from 'prop-types';
import cn from 'classnames';
import { makeStyles, Typography, Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  title: T.node,
  fullWidth: T.bool,
  checked: T.bool,
  disableTitle: T.bool,
  BoxProps: T.object
};

export const ReadonlyCheckbox = ({
  strict,
  fullWidth,
  disableTitle = false,
  color,
  iconColor,
  checked,
  title,
  className,
  rootClassName,
  position = 'center',
  BoxProps,
  ...props
}) => {
  const classes = useStyles({ color, iconColor, position });
  const isStrictAndExist = strict && typeof checked === 'boolean';

  return (
    <Box display="flex" alignItems={position} width={fullWidth ? '100%' : null} {...BoxProps}>
      {(isStrictAndExist || !strict) && (checked ? (
        <CheckCircleIcon
          fontSize="small"
          className={
            cn(
              classes.icon,
              { [classes.icon_checked]: checked }
            )
          }
        />
      ) : (
        <RemoveCircleIcon
          fontSize="small"
          className={
            cn(
              classes.icon,
              { [classes.icon_unchecked]: !checked }
            )
          }
        />
      ))}

      {!disableTitle &&
        <Typography noWrap className={cn(classes.title, className)} {...props}>
          {(isStrictAndExist || !strict)
            ? title ? title : checked ? 'Yes' : 'No'
            : 'No info'
          }
        </Typography>
      }
    </Box>
  );
};

ReadonlyCheckbox.propTypes = propTypes;
