import { useFormikContext } from 'formik';
import {
  makeStyles,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Checkbox, RadioGroup, TextField } from '../../../FormField';
import { getTextColor } from '../../getTextColor';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const NeckPain = () => {
  const classes = useStyles();
  const { errors } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Typography className={classes.formTitle}>
        IF PAIN IN NECK
      </Typography>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_neck?.your_dominant_hand)}
        >
          1. Which is your dominant hand? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_neck.your_dominant_hand">
            <FormControlLabel
              value="left"
              label={<Typography>Left</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="right"
              label={<Typography>Right</Typography>}
              control={<Radio color="primary"/>}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography className={classes.title}>
          2. Where is your pain?
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="space-around" flexWrap="wrap">
          <FormControlLabel
            label={<Typography>Neck</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.where_your_pain.parts.neck"/>}
          />

          <FormControlLabel
            label={<Typography>Hand</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.where_your_pain.parts.hand"/>}
          />

          <FormControlLabel
            label={<Typography>Other</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.where_your_pain.parts.other"/>}
          />

          <FormControlLabel
            label={<Typography>Shoulder</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.where_your_pain.parts.shoulder"/>}
          />

          <FormControlLabel
            label={<Typography>Arms</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.where_your_pain.parts.arms"/>}
          />
        </Box>

        <TextField
          fullWidth
          multiline
          disableUnderline
          name="forms.pain_in_neck.where_your_pain.description"
          margin="dense"
          variant="filled"
          placeholder="Please describe"
          rows={5}
        />
      </div>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_neck?.how_often_your_pain)}
        >
          3. How often is your pain? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_neck.how_often_your_pain">
            <FormControlLabel
              value="occasional"
              label={<Typography>Occasional</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="frequent"
              label={<Typography>Frequent</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="constant"
              label={<Typography>Constant</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_neck?.what_severity_your_pain)}
        >
          4. What is the severity of your pain? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup
            row
            name="forms.pain_in_neck.what_severity_your_pain"
          >
            <FormControlLabel
              value="mild"
              label={<Typography>Mild</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="moderate"
              label={<Typography>Moderate</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="severe"
              label={<Typography>Severe</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_neck?.your_pain_radiate_other_parts)}
        >
          5. Does your pain radiate to other parts of your body? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_neck.your_pain_radiate_other_parts">
            <FormControlLabel
              value="1"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="0"
              label={<Typography>No</Typography>}
              control={<Radio color="primary"/>}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography className={classes.title}>
          6. If yes, how often?
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_neck.if_yes_how_often">
            <FormControlLabel
              value="occasional"
              label={<Typography>Occasional</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="frequent"
              label={<Typography>Frequent</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="constant"
              label={<Typography>Constant</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography className={classes.title}>
          7. Where does your pain radiate to?
        </Typography>

        <div className={classes.contentInfoPain}>
          <FormControlLabel
            label={<Typography>Forearm</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.where_pain_radiate_too.forearm"/>}
          />

          <FormControlLabel
            label={<Typography>Hand</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.where_pain_radiate_too.hand"/>}
          />

          <FormControlLabel
            label={<Typography>Fingers</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.where_pain_radiate_too.fingers"/>}
          />
        </div>
      </div>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_neck?.which_side_body_pain_radiate)}
        >
          8. Which side of body does the pain radiate to? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_neck.which_side_body_pain_radiate">
            <FormControlLabel
              value="left"
              label={<Typography>Left</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="right"
              label={<Typography>Right</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="bilaterally"
              label={<Typography>Bilaterally</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </div>
      </div>

      <Grid className={classes.content}>
        <Typography className={classes.title}>
          9. Do you experience any of the following?
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Weakness</Typography>}
                control={<Checkbox color="primary" name="forms.pain_in_neck.experience_following.weakness.value"/>}
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_neck.experience_following.weakness.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>

            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Muscle Spasms</Typography>}
                control={<Checkbox color="primary" name="forms.pain_in_neck.experience_following.muscle_spasms.value"/>}
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_neck.experience_following.muscle_spasms.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit"/> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>

            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Stiffness</Typography>}
                control={<Checkbox color="primary" name="forms.pain_in_neck.experience_following.stiffness.value"/>}
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_neck.experience_following.stiffness.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Limping</Typography>}
                control={<Checkbox color="primary" name="forms.pain_in_neck.experience_following.limping.value"/>}
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_neck.experience_following.limping.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>

            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Numbness</Typography>}
                control={<Checkbox color="primary" name="forms.pain_in_neck.experience_following.numbness.value"/>}
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_neck.experience_following.numbness.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>

            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Tingling</Typography>}
                control={<Checkbox color="primary" name="forms.pain_in_neck.experience_following.tingling.value"/>}
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_neck.experience_following.tingling.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_neck?.symptoms_increase_while_twisting)}
        >
          10. Do your symptoms increase while twisting, turning,
          extending or flexing the neck or keeping it in an awkward or prolonged position? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_neck.symptoms_increase_while_twisting">
            <FormControlLabel
              value="Yes"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="No"
              label={<Typography>No</Typography>}
              control={<Radio color="primary"/>}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography className={classes.title}>
          11. Do you experience any of the following as a result of your injury?
        </Typography>

        <div className={classes.painExperience}>
          <FormControlLabel
            label={<Typography>Headaches</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.experience_result_injury.headaches"/>}
            className={classes.painExperienceRow}
          />

          <FormControlLabel
            label={<Typography>Loss of memory</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.experience_result_injury.loss_of_memory"/>}
            className={classes.painExperienceRow}
          />

          <FormControlLabel
            label={<Typography>Dizziness</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.experience_result_injury.dizziness"/>}
            className={classes.painExperienceRow}
          />

          <FormControlLabel
            label={<Typography>Difficulty thinking</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_neck.experience_result_injury.difficulty_thinking"/>}
            className={classes.painExperienceRow}
          />
        </div>
      </div>

      <div className={classes.content}>
        <Typography className={classes.title}>
          12. Do your symptoms increase with activity involving the use of muscles in the neck,
          vigorous activity, bending over or by the cold environment?
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_neck.symptoms_increase_with_activity">
            <FormControlLabel
              value="Yes"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="No"
              label={<Typography>No</Typography>}
              control={<Radio color="primary"/>}
            />
          </RadioGroup>
        </div>
      </div>
    </>
  );
};
