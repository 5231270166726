export const filterFieldsMap = {
  name: 'name',
  address: 'address',
  company_type_id: 'company_type_id',
  email: 'email'
};

export const filterFieldsLabels = {
  [filterFieldsMap.name]: 'Company name',
  [filterFieldsMap.address]: 'Address',
  [filterFieldsMap.company_type_id]: 'Type',
  [filterFieldsMap.email]: 'Email'
};
