import { Box, Grid, Typography } from '@material-ui/core';
import { Date } from '../../../../Date';

export const MainInfo = ({ claim }) => {
  const data = claim?.situational_number;

  return (
    <Grid container spacing={2} component={Box} px={2} py={3}>
      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Onset Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          variant="subtitle2"
          iconFontSize="small"
          date={data?.onset_date}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          LMP Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          variant="subtitle2"
          iconFontSize="small"
          date={data?.lmp_date}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          First Consult Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          variant="subtitle2"
          iconFontSize="small"
          date={data?.first_consult_date}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Last Seen Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          variant="subtitle2"
          iconFontSize="small"
          date={data?.last_seen_date}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Assumed Care Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          variant="subtitle2"
          iconFontSize="small"
          date={data?.assumed_care_date}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Relinquished Care Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          variant="subtitle2"
          iconFontSize="small"
          date={data?.relinquished_care_date}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Hearing Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          variant="subtitle2"
          iconFontSize="small"
          date={data?.hearing_date}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Vision Rx Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          variant="subtitle2"
          iconFontSize="small"
          date={data?.vision_rx_date}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Accident Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          variant="subtitle2"
          iconFontSize="small"
          date={data?.accident_date}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Accident Type
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {data?.accident_type?.label || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Accident State
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {data?.auto_accident_state?.label || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Accident Country
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {data?.auto_accident_country || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};
