import moment from 'moment';
import { Link, Typography } from '@material-ui/core';
import { AppointmentViewModal } from '../../appointments/AppointmentViewModal';
import { useModal } from '../../ModalsProvider';

export const Appointment = ({ before, after }) => {
  const { openModal } = useModal();
  const beforeDate = moment.unix(before?.appointment_at).format('L LT');
  const afterDate = moment.unix(after?.appointment_at).format('L LT');

  const openViewAppointmentModal = (appointmentID) => () => {
    if (!appointmentID) {
      return;
    }

    openModal(AppointmentViewModal, {
      payload: {
        appointmentID
      }
    });
  };

  if (!before && after) {
    return (
      <>
        Added
        &nbsp;
        <Link variant="subtitle1" onClick={openViewAppointmentModal(after?.id)}>Appointment</Link>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed
        &nbsp;
        <Link variant="subtitle1" onClick={openViewAppointmentModal(before?.id)}>Appointment</Link>
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed <Typography variant="subtitle1">Appointment</Typography>
        &nbsp;from&nbsp;
        <Link onClick={openViewAppointmentModal(before?.id)}>{beforeDate}</Link>
        &nbsp;to&nbsp;
        <Link onClick={openViewAppointmentModal(after?.id)}>{afterDate}</Link>
      </>
    );
  } else {
    return (
      <>
        Empty&nbsp;
        <Typography variant="subtitle1">Appointment</Typography>
      </>
    );
  }
};
