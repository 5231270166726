import { fieldsKeysMap } from '../fieldsKeysMap';

export const columnsWidths = {
  [fieldsKeysMap.providers]: 150,
  [fieldsKeysMap.offices]: 150,
  [fieldsKeysMap.apptBooks]: 200,
  [fieldsKeysMap.patients]: 150,
  [fieldsKeysMap.arrivalStatuses]: 175,
  [fieldsKeysMap.appointmentTypes]: 200,
  [fieldsKeysMap.visitReasons]: 150,
  [fieldsKeysMap.action]: 52
};
