import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReplyIcon from '@material-ui/icons/ReplyOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';
import * as chatsApi from '../../../../../../../api/chat';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import {
  ThreadMessagesContext,
  ChatThreadContext,
  actionTypesMap,
  ForwardModal
} from '../../../../../../../components/chat';

export const Menu = ({ isCurrentUser, message, onClick = () => {} }) => {
  const {
    setCurrentAction,
    setMessageToEdit,
    toggleMessageSelect,
    resetCurrentAction,
    setForwardedMessage
  } = useContext(ChatThreadContext);
  const { deleteMessages } = useContext(ThreadMessagesContext);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const handleSelect = () => {
    setCurrentAction(actionTypesMap.select);
    toggleMessageSelect(message);
  };

  const handleCopy = () => {
    const text = message.parent?.body && message.body
      ? `${message.parent?.body}\n${message?.body}`
      : message.parent?.body || message.body;

    window.navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar('Text copied!', { variant: 'success' });
    });
  };

  const handleEdit = () => {
    setCurrentAction(actionTypesMap.edit);
    setMessageToEdit(message);
  };

  const handleCurrentAction = (type) => () => {
    setCurrentAction(type);

    if (type === actionTypesMap.edit) {
      setMessageToEdit(message);
    } else if (type === actionTypesMap.select) {
      toggleMessageSelect(message);
    }
  };

  const handleReply = () => {
    setCurrentAction(actionTypesMap.reply);
    setForwardedMessage(message);
  };

  const handleForward = () => {
    handleCurrentAction(actionTypesMap.move);

    openModal(ForwardModal, {
      payload: {
        selectedMessages: [ message ]
      },
      onModalResolved: () => {
        resetCurrentAction();
      },
      onModalRejected: () => {
        resetCurrentAction();
      }
    });
  };

  const handleDelete = () => {
    setCurrentAction(actionTypesMap.default);

    openModal(ConfirmationModal, {
      payload: {
        title: 'Delete message',
        content: 'Are you sure you want to delete this message? This cannot be undone.'
      },

      onModalResolved: () => {
        chatsApi.deleteMessage(message.id).then(() => {
          deleteMessages([ message.id ]);
          enqueueSnackbar('Message deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Message not deleted', { variant: 'error' });
        });
      }
    });
  };

  return (
    <List gutters={2} onClick={onClick}>
      {isCurrentUser &&
        <ListItem button onClick={handleEdit}>
          <ListItemIcon>
            <CreateOutlinedIcon/>
          </ListItemIcon>

          <ListItemText primary="Edit"/>
        </ListItem>
      }

      <ListItem button onClick={handleReply}>
        <ListItemIcon>
          <ReplyIcon/>
        </ListItemIcon>

        <ListItemText primary="Reply"/>
      </ListItem>

      <ListItem button onClick={handleForward}>
        <ListItemIcon>
          <ForwardOutlinedIcon/>
        </ListItemIcon>

        <ListItemText primary="Forward"/>
      </ListItem>

      <ListItem button onClick={handleCopy}>
        <ListItemIcon>
          <FileCopyOutlinedIcon/>
        </ListItemIcon>

        <ListItemText primary="Copy text"/>
      </ListItem>

      <ListItem button onClick={handleSelect}>
        <ListItemIcon>
          <CheckCircleOutlineIcon/>
        </ListItemIcon>

        <ListItemText primary="Select Message"/>
      </ListItem>

      {isCurrentUser &&
        <>
          <Divider/>

          <ListItem button onClick={handleDelete}>
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon/>
            </ListItemIcon>

            <ListItemText primary="Delete"/>
          </ListItem>
        </>
      }
    </List>
  );
};
