import { api } from '../../api';

export const tagsActionsMap = {
  add: 'add',
  delete: 'delete',
  replace: 'replace'
};

export const tagsChangingMethodsMap = {
  [tagsActionsMap.add]: 'post',
  [tagsActionsMap.replace]: 'put',
  [tagsActionsMap.delete]: 'delete'
};

export const fetchTags = (config) => {
  return api.get('/tags', config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error.data;
  });
};

export const createTag = (tag) => {
  return api.post('/tags', tag).then(({ data: { data } }) => {
    return data;
  }).catch(({ data: { errors } }) => {
    throw errors;
  });
};

export const updateTag = (tag) => {
  return api.put(`/tags/${tag.id}`, tag).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error.data;
  });
};

export const updateTagsPositions = (id, config) => {
  return api.put(`/tags/position/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteTag = (id) => {
  return api.delete(`/tags/${id}`).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error.data;
  });
};
