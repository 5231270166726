import { Box, Grid, Typography } from '@material-ui/core';
import { PreviewCompanyModal } from '../../../app/Dashboard/Contacts/Companies/PreviewCompanyModal';
import { CompanyLink } from '../../CompanyLink';
import { useModal } from '../../ModalsProvider';
import { Tooltip } from '../../Tooltip';

export const InsuranceInfo = ({ appointment }) => {
  const { openModal } = useModal();

  const openCompanyViewModal = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: appointment?.patient_insurance?.insurance_company?.id }
    });
  };

  return (
    <>
      <Box color="info.main">
        <Typography variant="h4" color="inherit">Insurance Info</Typography>
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Ins Company
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Tooltip
            isExistTooltip={!!appointment?.patient_insurance?.insurance_company?.name}
            title={appointment?.patient_insurance?.insurance_company?.name}
            placement="top"
          >
            <CompanyLink
              variant="h5"
              company={appointment?.patient_insurance?.insurance_company}
              onClick={openCompanyViewModal}
            />
          </Tooltip>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Type Insurance
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {appointment?.patient_insurance?.type_insurance || '-'}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Insurance ID
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {appointment?.patient_insurance?.insuranceId || '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
