import { useEffect, useState } from 'react';
import cn from 'classnames';
import { get } from 'lodash';
import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { NestedList } from '../../../../../../../components/NestedList';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { maritalStatusesLabelsMap } from '../../../../../../../components/medical/forms';
import { socialHistoryTypes } from '../PastMedicalHistory/socialHistoryTypes';
import { Allergies } from './Allergies';
import { Medications } from './Medications';
import { medicationsMap } from './medicationsMap';
import { SurgeriesList } from './SurgeriesList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MedicalHistory = ({ isOpenSwitches, form }) => {
  const classes = useStyles();
  const [ isOpen, setIsOpen ] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const medications = Object.keys(get(form, 'forms.medical_history.blood_thinning_medications', {}));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(form?.forms?.medical_history) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Medical History"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box p={isSmallScreen ? 1 : 3}>
        {!!form?.forms?.medical_history?.medical_problems && (
          <div className={classes.content}>
            <Typography gutterBottom className={classes.formContent}>
              Please list all current and past MEDICAL PROBLEMS:
            </Typography>

            <Typography color="textSecondary">
              {form?.forms?.medical_history?.medical_problems}
            </Typography>
          </div>
        )}

        {!!form?.forms?.medical_history?.current_medications?.length && (
          <div className={classes.content}>
            <Typography className={classes.formContent}>
              Current Medications:
            </Typography>

            <Medications medications={form?.forms?.medical_history?.current_medications || []} />
          </div>
        )}

        {!!form?.forms?.medical_history?.allergies_or_reactions?.length && (
          <div className={classes.content}>
            <Typography className={classes.formContent}>
              List allergies or reactions to medications:
            </Typography>

            <Allergies allergies={form?.forms?.medical_history?.allergies_or_reactions || []} />
          </div>
        )}

        {!!medications.length && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Do you take any blood thinning medications? If yes which of the following?
            </Typography>

            {medications.map((item, i) => (
              form?.forms?.medical_history?.blood_thinning_medications?.[item]) && (
              <ReadonlyCheckbox
                checked
                key={i}
                title={medicationsMap[item]}
                className={classes.contentInfoTitle}
              />
            ))}
          </div>
        )}

        {!!form?.forms?.medical_history?.surgeries_dates?.length && (
          <div className={classes.content}>
            <Typography className={classes.formContent}>
              Please list all surgeries and their dates:
            </Typography>

            <SurgeriesList surgeries={form?.forms?.medical_history?.surgeries_dates} />
          </div>
        )}

        {!!form?.forms?.medical_history?.family_medical_history && (
          <div className={classes.content}>
            <Typography gutterBottom className={classes.formContent}>
              Family Medical History: please list any illness or disease that runs in your family.
            </Typography>

            <Typography color="textSecondary">
              {form?.forms?.medical_history?.family_medical_history}
            </Typography>
          </div>
        )}

        {!!form?.forms?.medical_history?.marital_status && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Marital Status:
            </Typography>

            <ReadonlyCheckbox
              checked
              title={maritalStatusesLabelsMap[form?.forms?.medical_history?.marital_status] || '-'}
              className={classes.contentInfoTitle}
            />
          </div>
        )}

        <div className={classes.content}>
          {!!form?.forms?.medical_history?.occupation && (
            <>
              <Typography className={classes.formContent}>
                Occupation:
              </Typography>

              <Box py={1}>
                <ReadonlyCheckbox
                  checked={!!form?.forms?.medical_history?.occupation}
                  title={form?.forms?.medical_history?.occupation === socialHistoryTypes.yes ? 'Yes' : 'No'}
                />
              </Box>
            </>
          )}

          <Typography color="textSecondary">
            {form?.forms?.medical_history?.occupation_value}
          </Typography>
        </div>

        <div className={classes.content}>
          <Typography className={classes.formContent}>
            Do you use tobacco?
          </Typography>

          <ReadonlyCheckbox
            checked={!!form?.forms?.medical_history?.you_use_tobacco}
            title={form?.forms?.medical_history?.you_use_tobacco === 'yes' ? 'Yes' : 'No'}
          />

          {!!form?.forms?.medical_history?.you_use_tobacco_value &&
            <Typography color="textSecondary">
              {form?.forms?.medical_history?.you_use_tobacco_value}
            </Typography>
          }
        </div>

        <div className={classes.content}>
          <Typography className={classes.formContent}>
            Do you consume alcohol?
          </Typography>

          <ReadonlyCheckbox
            checked={!!form?.forms?.medical_history?.you_use_alcohol}
            title={form?.forms?.medical_history?.you_use_alcohol === 'yes' ? 'Yes' : 'No'}
          />

          {!!form?.forms?.medical_history?.you_use_alcohol_value &&
            <Typography color="textSecondary">
              {form?.forms?.medical_history?.you_use_alcohol_value}
            </Typography>
          }
        </div>

        <div className={classes.content}>
          <Typography className={classes.formContent}>
            Do you currently or have you ever used recreational drugs, including cannabis?
          </Typography>

          <ReadonlyCheckbox
            checked={!!form?.forms?.medical_history?.used_recreational_drugs}
            title={form?.forms?.medical_history?.used_recreational_drugs === 'yes' ? 'Yes' : 'No'}
          />

          {!!form?.forms?.medical_history?.used_recreational_drugs_value &&
            <Typography color="textSecondary">
              {form?.forms?.medical_history?.used_recreational_drugs_value}
            </Typography>
          }
        </div>
      </Box>
    </NestedList>
  );
};
