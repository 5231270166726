import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography, Box } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../components/Loader';
import { FilesContext } from '../../../../FilesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 50;
export const columnsWidths = {
  name: 300,
  size: 200,
  date: 200,
  action: 64
};

export const List = ({ isOpen, setFilesCount = () => {} }) => {
  const {
    files,
    isFetched,
    isFetching,
    loadNextPage,
    filter: { per_page, last_page, page },
    resetFiles
  } = useContext(FilesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    if (isOpen) {
      resetFiles();
    }
  }, [ isOpen ]);

  useEffect(() => {
    if (isFetched) {
      setFilesCount(files?.length);
    }
  }, [ isFetched, files ]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      border={1}
      borderColor="divider"
    >
      <Loader loading={!isFetched && !isOpen} p={2} render={
        () => !files.length ? (
          <Box p={3}>
            <Typography align="center">Files not found</Typography>
          </Box>
        ) : (
          <>
            <TableHeader />

            <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
              <MuiList disablePadding>
                <InfiniteListLoader
                  items={files}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={{ per_page, last_page, page }}
                  RowComponent={Row}
                  minRowHeight={minRowHeight}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </CustomScrollbars>
          </>
        )}
      />
    </Box>
  );
};
