import cn from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect, useLocation, Switch } from 'react-router-dom';
import { Box, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { MedicalReportsTabs } from '../../../../components/MedicalReportsTabs';
import { renderRoutes, Route } from '../../../../components/router';
import { Tabs, Tab } from '../../../../components/Tabs';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { getReportsRoutes } from '../../MedicalReports';
import { styles } from '../../PatientsPage/MedicalInfo/styles';
import { GeneralAndMedicalSwitcher } from '../GeneralAndMedicalSwitcher';
import { LeftSidebar } from './MainInfo/LeftSidebar';
import { MedicalProfileProvider } from './MainInfo/MedicalProfileProvider';
import { routes } from './routes';

const useStyles = makeStyles(styles);

export const MedicalInfo = () => {
  const classes = useStyles();
  const location = useLocation();
  const reportRoutes = getReportsRoutes('/profile/medical-info/medical-report');
  const theme = useTheme();
  const userSidebarIsOpen = useSelector(({ layout: { userInfoIsOpen = true } }) => userInfoIsOpen);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [ isUserInfoOpen, setIsUserInfoOpen ] = useState(false);
  const {
    FilterBarWrapper,
    filterBarWrapperProps,
    toggleFiltersBar,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  const toggleInfoOpen = () => {
    setIsUserInfoOpen((state) => !state);
  };

  const getActiveRoute = () => {
    const route = routes.find(({ path }) => location.pathname === path);
    const path = location.pathname.includes('/medical-forms')
      ? '/profile/medical-info/medical-forms'
      : '/profile/medical-info/medical-report';

    return route ? route.path : path;
  };

  if (location.pathname === '/profile/medical-info/medical-report') {
    return (
      <Switch>
        <Route exact path={location.pathname}>
          <Redirect to={location.pathname + '/consultation'} />
        </Route>
      </Switch>
    );
  }

  return (
    <MedicalProfileProvider medicalInfoPathname="/profile/medical-info">
      <Box display="flex" flexDirection="column" height="100%" bgcolor="grey.100" overflow="hidden" minHeight="100%">
        {isTablet &&
          <div className={classes.navigation}>
            <Box mx={2.25} mt={1}>
              <IconButton size="medium" onClick={toggleFiltersBar}>
                <ListIcon />
              </IconButton>
            </Box>
          </div>
        }

        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <Box
              display="flex"
              flexWrap="nowrap"
              flexDirection={isTablet ? 'column' : 'row'}
              alignItems={isTablet ? 'stretch' : 'center'}
              pl={1.25}
            >
              <Box display="flex" pr={2}>
                <GeneralAndMedicalSwitcher
                  isMedical
                  generalLink="/profile"
                  medicalLink="/profile/medical-info/medical-forms"
                />
              </Box>

              <Box flexGrow={1} overflow="hidden">
                <Tabs
                  value={getActiveRoute()}
                  orientation={isTablet ? 'vertical' : 'horizontal'}
                  classes={{ flexContainer: classes.tabsWrapper }}
                >
                  {routes.map(({ path, icon, label }) => (
                    <Tab
                      key={path}
                      component={Link}
                      to={path}
                      value={path}
                      icon={icon}
                      label={label}
                      onClick={isTablet ? toggleFiltersBar : undefined}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </FilterBarWrapper>
        </ScrollWrapper>

        <Route path="/profile/medical-info/medical-report">
          <MedicalReportsTabs pathname="/profile/medical-info/medical-report"/>
        </Route>

        <div className={classes.content}>
          {userSidebarIsOpen && (
            <div className={cn(classes.leftSidebar, { [classes.leftSidebarFullHeight]: isUserInfoOpen })}>
              <LeftSidebar openUserInfo={isUserInfoOpen} toggleInfoOpen={toggleInfoOpen}/>
            </div>
          )}

          <div className={cn(classes.main, { [classes.mainFullHeight]: !isUserInfoOpen })}>
            {renderRoutes(routes)}

            <Route path="/profile/medical-info/medical-report">
              <Box px={0.5} height="100%">
                {renderRoutes(reportRoutes)}
              </Box>
            </Route>
          </div>
        </div>
      </Box>
    </MedicalProfileProvider>
  );
};
