import Typography from '@material-ui/core/Typography/Typography';
import { useState, useEffect } from 'react';
import moment from 'moment';

export const CurrentTime = ({ TypographyProps }) => {
  const [ date, setDate ] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(moment());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Typography variant="h3" align="center" {...TypographyProps}>
      {date.format('L hh:mm:ss A')}
    </Typography>
  );
};
