export const styles = ({
  spacing,
  palette: { primary },
  shape: { borderRadius },
  shadows,
  breakpoints
}) => ({
  mainContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    flexWrap: 'wrap',
    backgroundColor: primary.contrastText,
    marginBottom: spacing(),
    padding: spacing(0, 2, 1, 2),
    boxShadow: shadows[2],
    zIndex: 1,
    borderRadius
  },

  filterTitle: {
    marginTop: spacing(2),
    marginRight: spacing(3)
  },

  filterIcon: {
    marginTop: spacing(2),
    marginRight: spacing(2)
  },

  filterField: {
    marginRight: spacing(2),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      marginRight: 0
    }
  },

  filterField_medium: {
    width: 150
  },

  filterField_user: {
    width: 240,
    marginBottom: spacing(0.5),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      width: '100%'
    }
  },

  dateField: {
    width: 100,
    minWidth: 0,
    marginRight: spacing(2),

    '& label ~ div input': {
      cursor: 'pointer'
    }
  },

  filterResetButton: {
    color: primary.main,
    marginTop: spacing(2)
  },

  filterResetButton_hidden: {
    display: 'none'
  }
});
