import { Fragment } from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { Date } from '../../../../../../../components/Date';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { Medications } from './Medications';
import { socialHistoryTypes } from './socialHistoryTypes';

export const GastroHistory = ({ history }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          GASTROINTESTINAL HISTORY
        </Typography>
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography gutterBottom variant="h5">
          Do you have a history of Peptic Ulcer Disease?
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <ReadonlyCheckbox
          checked
          title={
            history?.gastrointestinal_history?.do_you_have_a_history_of_peptic_ulcer_disease === socialHistoryTypes.yes
              ? 'Yes' : 'No'
          }
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography gutterBottom variant="h5">
          Do you have a history of GI, Stomach bleed?
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <ReadonlyCheckbox
          checked
          title={
            history?.gastrointestinal_history?.do_you_have_a_history_of_gi_stomach_bleed === socialHistoryTypes.yes
              ? 'Yes' : 'No'
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom variant="h5">
          {/* eslint-disable-next-line max-len */}
          Do you take any medications for your stomach? (please include over the counter medications: e.g. Pepcid, Tums, Zantac, etc, dosage and frequency)
        </Typography>
      </Grid>

      {history?.gastrointestinal_history?.do_you_take_any_medications_for_your_stomach &&
        <Grid item xs={12}>
          <Typography>
            {history?.gastrointestinal_history?.do_you_take_any_medications_for_your_stomach}
          </Typography>
        </Grid>
      }

      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          PAST SURGICAL HISTORY
        </Typography>
      </Grid>

      {history?.past_surgical_history?.map((item, index) => (
        <Fragment key={index}>
          <Grid item xs={3}>
            <Typography variant="h5">
              {item?.surgery}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Box display="flex" alignItems="center">
              {item.date_from && (
                <>
                  <Date disableMoment date={item.date_from ? moment(item.date_from).format('L') : ''} />
                  &nbsp;
                  &nbsp;
                </>
              )}

              {item.date_to && (
                <Date disableMoment date={moment(item.date_to).format('L')} />
              )}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography>{item?.surgeon}</Typography>
          </Grid>
        </Fragment>
      ))}

      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          ALLERGIES
        </Typography>
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography gutterBottom variant="h5">
          Are you allergic to any medication?
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <ReadonlyCheckbox
          checked
          title={
            history?.allergies?.are_you_allergic_to_any_medication === socialHistoryTypes.yes
              ? 'Yes' : 'No'
          }
        />
      </Grid>

      {history?.allergies?.if_yes_please_list_all_medications && (
        <>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5">
              {/* eslint-disable-next-line max-len */}
              If yes, please list all medications that you are allergic to and the associated reaction (i.e. penicillin (hives) etc):
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              {history?.allergies?.if_yes_please_list_all_medications}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography gutterBottom variant="h5">
          Are you allergic to:
        </Typography>
      </Grid>

      <Grid item md={4} xs={12}>
        <Box display="flex" alignItems="center">
          <Box component="span" mr={2}>
            <Typography variant="h5">Sulfa?</Typography>
          </Box>

          <ReadonlyCheckbox
            checked
            title={history?.allergies?.are_you_allergic_to?.sulfa === socialHistoryTypes.yes ? 'Yes' : 'No'}
          />
        </Box>
      </Grid>

      <Grid item md={4} xs={12}>
        <Box display="flex" alignItems="center">
          <Box component="span" mr={2}>
            <Typography variant="h5">Latex?</Typography>
          </Box>

          <ReadonlyCheckbox
            checked
            title={
              history?.allergies?.are_you_allergic_to?.latex === socialHistoryTypes.yes
                ? 'Yes' : 'No'
            }
          />
        </Box>
      </Grid>

      <Grid item md={4} xs={12}>
        <Box display="flex" alignItems="center">
          <Box component="span" mr={2}>
            <Typography variant="h5">Steroids?</Typography>
          </Box>

          <ReadonlyCheckbox
            checked
            title={
              history?.allergies?.are_you_allergic_to?.steroids === socialHistoryTypes.yes
                ? 'Yes' : 'No'
            }
          />
        </Box>
      </Grid>

      {history?.allergies?.are_you_allergic_to?.please_list_all_food_allergens && (
        <>
          <Grid item md={4} xs={12}>
            <Typography gutterBottom variant="h5">
              Please list all food allergens (i.e. eggs, shellfish):
            </Typography>
          </Grid>

          <Grid item md={8} xs={12}>
            <Typography>
              {history?.allergies?.are_you_allergic_to?.please_list_all_food_allergens}
            </Typography>
          </Grid>
        </>
      )}

      {!!history?.medications?.length && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" color="secondary">
              MEDICATIONS
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Medications medications={history?.medications || {}} />
          </Grid>
        </>
      )}
    </>
  );
};
