import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Dialog, Grid, IconButton, Link, Typography } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { ChatThread } from '../../../../../app/Dashboard/MessengerPage/ChatThread';
import { ModalContainer, ModalHeader } from '../../../../Modal';
import { ChatThreadContext, ChatThreadContextProvider } from '../../../ChatThreadContext';
import { getThreadName } from '../../../utils';
import { MessengerContextProvider } from '../../../MessengerProvider';
import { ThreadAvatar } from '../../../ThreadAvatar';

export const ChatModal = ({
  DialogProps,
  handleModalReject,
  payload: {
    threadID
  }
}) => {
  const currentUser = useSelector(({ profile }) => profile.user);

  return (
    <MessengerContextProvider currentThreadId={threadID}>
      <ChatThreadContextProvider>
        <Dialog disableEnforceFocus fullScreen maxWidth="lg" {...DialogProps}>
          <ModalContainer>
            <ModalHeader onClose={handleModalReject}>
              <ChatThreadContext.Consumer>
                {({ currentThread }) => !!currentThread && (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <ThreadAvatar thread={currentThread} />
                    </Grid>

                    <Grid item>
                      <Typography variant="h4" style={{ wordBreak: 'break-all' }}>
                        {getThreadName({ thread: currentThread, currentUser })}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </ChatThreadContext.Consumer>

              <Box display="flex" justifyContent="flex-end" ml="auto">
                <Link component={RouterLink} color="inherit" to={`/messenger/${threadID}`}>
                  <IconButton edge="end" color="inherit">
                    <LaunchIcon />
                  </IconButton>
                </Link>
              </Box>
            </ModalHeader>

            <ChatThread isModal />
          </ModalContainer>
        </Dialog>
      </ChatThreadContextProvider>
    </MessengerContextProvider>
  );
};
