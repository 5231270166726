import { Text, CurrencyFormat, UserActivityLink, CategoryActivity, Date } from '../../fields';

export const expenseFieldsMap = {
  amount: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Amount" />,
  budget: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Budget" />,
  category: (before, after) => <CategoryActivity before={before} after={after} fieldLabel="Category" />,
  date: (before, after) => <Date before={before} after={after} fieldLabel="Date" />,
  updated_at: (before, after) => <Date before={before} after={after} fieldLabel="Updated At" />,
  user: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="User" />,
  notes: (before, after) => <Text before={before} after={after} fieldLabel="Notes" />
};
