export const styles = ({
  spacing,
  typography: { fontWeightMedium }
}) => ({
  root: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },

  root_space: {
    paddingRight: spacing(4)
  },

  content__title: {
    fontWeight: fontWeightMedium
  }
});
