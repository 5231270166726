export const filterFieldsMap = {
  code: 'code',
  description: 'description',
  status: 'is_active',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.code]: 'Code',
  [filterFieldsMap.description]: 'Description',
  [filterFieldsMap.status]: 'Status',
  [filterFieldsMap.orderBy]: 'Order By'
};
