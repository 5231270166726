import { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Paper } from '@material-ui/core';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import { FeedsContext } from '../../../../../components/feeds/FeedsProvider';
import { Popper } from '../../../../../components/Popper';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Feeds = () => {
  const { feeds } = useContext(FeedsContext);
  const classes = useStyles({ rowsCount: feeds });

  return (
    <Popper
      placement="bottom-start"
      anchorRenderer={({ anchorRef, handleToggle }) => (
        <IconButton buttonRef={anchorRef} onClick={handleToggle}>
          <NotificationImportantOutlinedIcon />
        </IconButton>
      )}
    >
      {({ handleClose }) => (
        <Paper elevation={4} className={classes.root}>
          <List onClose={handleClose} />
        </Paper>
      )}
    </Popper>
  );
};
