import { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core';
import { api } from '../../../../../../../api';
import { Portal } from '../../../../../../../components/Portal';
import { Loader } from '../../../../../../../components/Loader';
import { FilePreviewContext } from '../../../FilePreviewContext';
import { ViewControls, ViewControlsStub } from '../ViewControls';
import { PreviewContent } from '../PreviewContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Image = ({ topLayerPortalElement }) => {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ scale, setScale ] = useState(1);
  const classes = useStyles({ scale });
  const { selectedFile } = useContext(FilePreviewContext);
  const imgRef = useRef();
  const cancelFetch = useRef(() => {});

  const zoomOut = () => {
    const width = imgRef.current.width;

    imgRef.current.width = width - Math.ceil(width / 10);
    setScale((scale) => +(scale - 0.1).toFixed(10));
  };

  const zoomReset = () => {
    imgRef.current.removeAttribute('width');
    setScale(1);
  };

  const zoomIn = () => {
    const width = imgRef.current.width;

    imgRef.current.width = width + Math.ceil(width / 10);
    setScale((scale) => +(scale + 0.1).toFixed(10));
  };

  const handleImgLoaded = ({ target }) => {
    zoomReset();
    imgRef.current.width = target.width;
  };

  useEffect(() => {
    setIsLoaded(false);

    imgRef.current?.removeAttribute('width');

    axios.get(selectedFile?.url, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(() => {
      setIsLoaded(true);
    }).catch((thrown) => {
      if (axios.isCancel(thrown)) {
        return null;
      }
    });

    return cancelFetch.current;
  }, [ selectedFile?.url ]);

  return (
    <PreviewContent fullSize>
      <div className={classes.content}>
        {!isLoaded ? <Loader className={classes.loader} /> : (
          <>
            <img
              src={selectedFile?.url}
              alt={selectedFile?.original_filename}
              ref={imgRef}
              className={classes.image}
              onLoad={handleImgLoaded}
            />

            <ViewControlsStub/>

            <Portal container={topLayerPortalElement}>
              <ViewControls
                onZoomIn={zoomIn}
                onZoomOut={zoomOut}
                onZoomReset={zoomReset}
                scale={scale}
              />
            </Portal>
          </>
        )}
      </div>
    </PreviewContent>
  );
};
