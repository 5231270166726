export const styles = ({ palette, spacing }) => ({
  root: {
    color: 'inherit',
    textDecoration: 'none',
    padding: spacing(1, 2),
    margin: 0,
    borderBottom: `1px solid ${palette.grey[100]}`
  },

  messagesCounter: {
    border: `1px solid ${palette.primary.main}`,
    minWidth: '20px',
    minHeight: '20px',
    borderRadius: '50%'
  }
});
