import { useRef, useContext, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { InfiniteGridLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { VideosContext } from '../../VideosContext';
import { Card } from './Card';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Grid = () => {
  const {
    videos,
    isFetched,
    isFetching,
    loadNextPage,
    filter: { last_page, page },
    ...videosContext
  } = useContext(VideosContext);
  const classes = useStyles();
  const scrollElementRef = useRef();

  useEffect(() => {
    videosContext.resetVideos();
  }, []);

  return (
    <div ref={scrollElementRef} className={classes.root}>
      <Loader loading={!isFetched} render={
        () => (
          <>
            {!videos.length &&
              <Typography align="center">
                No videos found
              </Typography>
            }

            {!!videos.length &&
              <InfiniteGridLoader
                items={videos}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={{ last_page, page }}
                CellComponent={Card}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
                cellWidth={200}
                cellHeight={200}
              />
            }
          </>
        )}
      />
    </div>
  );
};
