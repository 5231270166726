import moment from 'moment';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../helpers/dom';
import { Contact } from '../../../../Contact';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { useModal } from '../../../../ModalsProvider';
import { OfficeLink } from '../../../../OfficeLink';
import { Popper } from '../../../../Popper';
import { UserLink } from '../../../../UserLink';
import { UserPreviewModal } from '../../../UserPreviewModal';
import { MemberMenu } from '../../MemberMenu';
import { columnsWidths } from '../List';

export const Row = ({ item }) => {
  const { openModal } = useModal();

  const openUserPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id }
    });
  };

  return (
    <ListRow disabled={!item?.is_active} px={0}>
      <ListRowCell grow flexBasis={columnsWidths.userName}>
        <UserLink
          noWrap
          user={item?.user}
          onClick={stopPropagation(openUserPreview(item?.user?.id))}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.role}>
        {item?.user?.role || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.office}>
        <OfficeLink
          variant="h5"
          office={item?.user?.office}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.phone}>
        <Contact
          type="tel"
          color="primary"
          title={item?.user?.phone || '-'}
          contact={item?.user?.phone}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.email}>
        <Contact
          noWrap
          color="primary"
          type="mailto"
          contact={item?.user?.email}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.accessDate}>
        {item?.opened_at ? moment.unix(item?.opened_at).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.closeDate}>
        {item?.closed_at ? moment.unix(item?.closed_at).format('L') : '-'}
      </ListRowCell>

      <ListRowCell noDisable flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
              <MoreVertIcon />
            </IconButton>
          )}
        >
          {({ handleClose }) => (
            <MemberMenu item={item} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
