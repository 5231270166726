import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { mdiCalendarEdit } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as proceduresApi from '../../../../api/profile';
import {
  transformModifiersCodesToOption,
  transformLevelToOption
} from '../../../FormField';
import { Loader } from '../../../Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../Modal';
import { sidesOptions } from '../sidesOptions';
import { validationSchema } from '../validationSchema';
import { ProcedureForm } from '../ProcedureForm';

export const ProcedureEditModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { procedureID }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ procedure, setProcedure ] = useState(null);

  const updateProcedure = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return proceduresApi.updateMedicalReport(values).then((data) => {
      enqueueSnackbar('Procedure successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Procedure not updated', { variant: 'error' });
    });
  };

  useEffect(() => {
    proceduresApi.fetchMedicalReport(procedureID).then((procedure) => {
      setProcedure(procedure);
    });
  }, []);

  return (
    <Dialog maxWidth="md" {...DialogProps}>
      <Loader p={3} loading={!procedure} render={
        () => (
          <Formik
            initialValues={{
              ...procedure,

              cpt_id: procedure?.cpt,
              pos_id: procedure?.pos,
              modifiers: procedure?.modifiers?.map(transformModifiersCodesToOption),
              levels: procedure?.levels?.map(transformLevelToOption),
              side: sidesOptions?.find(({ value }) => value === procedure.side)
            }}
            validationSchema={validationSchema}
            onSubmit={updateProcedure}
          >
            {({ isSubmitting, handleSubmit }) => (
              <ModalContainer component="form" onSubmit={handleSubmit}>
                <ModalHeader
                  icon={<SvgIcon><Icon path={mdiCalendarEdit} /></SvgIcon>}
                  onClose={handleModalReject}
                >
                  Edit Procedure
                </ModalHeader>

                <ModalBody>
                  <ProcedureForm />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
