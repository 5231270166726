/* eslint-disable max-len */
import { cardsLayout as statisticsLayout } from '../../app/Dashboard/Analytics/Layout';
import { cardsLayout as billingLayout } from '../../app/Dashboard/BillingPage/Dashboard/Widgets/cardsLayout';
import { cardsLayout as clientCasesLayout } from '../../app/Dashboard/CasesPage/Cases/CaseItem/Item/ClientLayouts';
import { cardsLayout as casesLayout } from '../../app/Dashboard/CasesPage/Cases/CaseItem/Item/Layouts';
import { cardsLayout as userProfileLayout } from '../../app/Dashboard/Members/User/MainContent/GeneralInfo/Layouts';
import { cardsLayout as medicalUserProfileLayout } from '../../app/Dashboard/Members/User/MainContent/MedicalInfo/MainInfo/Layouts';
import { cardsLayout as clientProfileLayout } from '../../app/Dashboard/ProfilePage/GeneralInfo/ClientLayouts';
import { cardsLayout as profileLayout } from '../../app/Dashboard/ProfilePage/GeneralInfo/Layouts';
import { cardsLayout as medicalClientProfile } from '../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/ClientLayouts';
import { cardsLayout as medicalProfileLayout } from '../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/Layouts';
import * as pages from './pages';

export const initialState = {
  [pages.profile]: {
    cardsLayoutOptions: profileLayout,
    prevLayoutsOptions: {
      isOpen: {},
      isMinimize: {},
      layouts: {},
      toolbox: {}
    }
  },
  [pages.clientProfile]: {
    cardsLayoutOptions: clientProfileLayout,
    prevLayoutsOptions: {
      isOpen: {},
      isMinimize: {},
      layouts: {},
      toolbox: {}
    }
  },
  [pages.userProfile]: {
    cardsLayoutOptions: userProfileLayout,
    prevLayoutsOptions: {
      isOpen: {},
      isMinimize: {},
      layouts: {},
      toolbox: {}
    }
  },
  [pages.cases]: {
    cardsLayoutOptions: casesLayout,
    prevLayoutsOptions: {
      isOpen: {},
      isMinimize: {},
      layouts: {},
      toolbox: {}
    }
  },
  [pages.clientCases]: {
    cardsLayoutOptions: clientCasesLayout,
    prevLayoutsOptions: {
      isOpen: {},
      isMinimize: {},
      layouts: {},
      toolbox: {}
    }
  },
  [pages.billing]: {
    cardsLayoutOptions: billingLayout,
    prevLayoutsOptions: {
      isOpen: {},
      isMinimize: {},
      layouts: {},
      toolbox: {}
    }
  },
  [pages.statistics]: {
    cardsLayoutOptions: statisticsLayout,
    prevLayoutsOptions: {
      isOpen: {},
      isMinimize: {},
      layouts: {},
      toolbox: {}
    }
  },
  [pages.medicalProfile]: {
    cardsLayoutOptions: medicalProfileLayout,
    prevLayoutsOptions: {
      isOpen: {},
      isMinimize: {},
      layouts: {},
      toolbox: {}
    }
  },
  [pages.medicalUserProfile]: {
    cardsLayoutOptions: medicalUserProfileLayout,
    prevLayoutsOptions: {
      isOpen: {},
      isMinimize: {},
      layouts: {},
      toolbox: {}
    }
  },
  [pages.medicalClientProfile]: {
    cardsLayoutOptions: medicalClientProfile,
    prevLayoutsOptions: {
      isOpen: {},
      isMinimize: {},
      layouts: {},
      toolbox: {}
    }
  }
};

export const mobileInitialState = {
  openWidgets: {
    [pages.profile]: null,
    [pages.clientProfile]: null,
    [pages.userProfile]: null,
    [pages.cases]: null,
    [pages.clientCases]: null,
    [pages.billing]: null,
    [pages.statistics]: null,
    [pages.medicalProfile]: null,
    [pages.medicalUserProfile]: null,
    [pages.medicalClientProfile]: null
  },

  checkedWidgets: {
    [pages.profile]: null,
    [pages.clientProfile]: null,
    [pages.userProfile]: null,
    [pages.cases]: null,
    [pages.clientCases]: null,
    [pages.billing]: null,
    [pages.statistics]: null,
    [pages.medicalProfile]: null,
    [pages.medicalUserProfile]: null,
    [pages.medicalClientProfile]: null
  }
};
