import { useContext } from 'react';
import cn from 'classnames';
import { makeStyles, Box, List as MuiList, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { Tooltip, TooltipTitle } from '../../../../../../../components/Tooltip';
import { ReportsPreviewContext } from '../../../../ReportsPreviewProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const columnsWidths = {
  name: 180,
  dose: 180,
  note: 250
};

export const MedicationsList = () => {
  const classes = useStyles();
  const { selectedItem: prescription } = useContext(ReportsPreviewContext);

  return (
    <Box display="flex" flexDirection="column">
      {!prescription?.medications?.length ? (
        <Box p={3}>
          <Typography align="center">No medications found</Typography>
        </Box>
      ) : (
        <MuiList disablePadding>
          <ListRow header className={cn(classes.row, classes.row_header)}>
            <ListRowCell grow flexBasis={columnsWidths.name}>
              Medication Name
            </ListRowCell>

            <ListRowCell grow flexBasis={columnsWidths.dose}>
              Dose
            </ListRowCell>

            <ListRowCell grow flexBasis={columnsWidths.note}>
              Notes
            </ListRowCell>
          </ListRow>

          {prescription?.medications?.map((item) => (
            <ListRow key={item?.ndc_code?.id} className={classes.row}>
              <ListRowCell grow noWrap flexBasis={columnsWidths.name}>
                <Tooltip
                  isExistTooltip={!!item?.ndc_code?.code}
                  title={
                    <TooltipTitle itemTitle={item?.ndc_code?.code}>
                      {item?.ndc_code?.name &&
                        <Typography noWrap>
                          - {item?.ndc_code?.name}
                        </Typography>
                      }
                    </TooltipTitle>
                  }
                >
                  <Typography noWrap>
                    {item?.ndc_code?.code + (item?.ndc_code?.name && ' - ' + item?.ndc_code?.name)}
                  </Typography>
                </Tooltip>
              </ListRowCell>

              <ListRowCell grow flexBasis={columnsWidths.dose}>
                <Box color="success.main">
                  <Typography noWrap color="inherit" variant="h5">
                    {item.dose}
                  </Typography>
                </Box>
              </ListRowCell>

              <ListRowCell grow flexBasis={columnsWidths.note}>
                <Typography>
                  {item.note}
                </Typography>
              </ListRowCell>
            </ListRow>
          ))}
        </MuiList>
      )}
    </Box>
  );
};
