import bg from './bg.png';

export const styles = ({
  spacing,
  palette: { primary, grey, ...palette },
  shape: { borderRadius },
  shadows
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 500,
    marginBottom: spacing(),
    boxShadow: shadows[2],
    borderRadius: borderRadius,
    backgroundColor: palette.common.white,
    overflowX: 'hidden'
  },

  userHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: 125,
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    background: `${grey[500]} url(${bg}) center / cover`,
    padding: spacing(),
    color: palette.common.white
  },

  actionButton: {
    display: 'block',
    marginLeft: 'auto',
    color: palette.common.white
  },

  userInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: spacing(2)
  },

  userBirthdayDate: {
    paddingRight: spacing(),
    marginTop: 'auto',
    marginBottom: -spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },

  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%'
  },

  avatar: {
    position: 'absolute',
    width: 120,
    height: 120,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 1
  },

  role: {
    textTransform: 'uppercase',
    color: palette.info.main,
    textAlign: 'center'
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: spacing(2, 3, 3, 3)
  },

  activeContent: {
    display: 'flex',
    marginLeft: 'auto'
  },

  activeContent_scanButton: {
    marginTop: spacing(1),
    marginRight: -spacing(2)
  }
});
