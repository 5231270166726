import { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { NestedList } from '../../../../../NestedList';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../helpers/isEmptyDeep';

export const Allergies = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(report) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Allergies"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box py={2}>
        {report?.nkda &&
          <ReadonlyCheckbox
            checked={report?.nkda}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="NKDA"
          />
        }

        {report?.allergic_to &&
          <ReadonlyCheckbox
            checked={report?.allergic_to}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="Allergic to"
          />
        }

        {report?.note &&
          <Box my={2} display="flex" alignItems="flex-start">
            <Box pl={3.5} display="flex" alignItems="center">
              <NotesIcon fontSize="small" />

              <Box mr={1.5} ml={0.5}>
                <Typography variant="subtitle2">
                  Note
                </Typography>
              </Box>
            </Box>

            <Typography>
              {report?.note}
            </Typography>
          </Box>
        }

        {!isEmptyDeep(report?.denies_history_of) &&
          <>
            <Box pl={1}>
              <Typography gutterBottom variant="h5">Denies history of:</Typography>
            </Box>

            {report?.denies_history_of?.sedation &&
              <ReadonlyCheckbox
                checked={report?.denies_history_of?.sedation}
                BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
                iconColor="success"
                title="Sedation"
              />
            }

            {report?.denies_history_of?.nauseal_vomitting &&
              <ReadonlyCheckbox
                checked={report?.denies_history_of?.nauseal_vomitting}
                BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
                iconColor="success"
                title="Nauseal Vomitting"
              />
            }

            {report?.denies_history_of?.constipation &&
              <ReadonlyCheckbox
                checked={report?.denies_history_of?.constipation}
                BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
                iconColor="success"
                title="Constipation"
              />
            }

            {report?.denies_history_of?.pruritis &&
              <ReadonlyCheckbox
                checked={report?.denies_history_of?.pruritis}
                BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
                iconColor="success"
                title="Pruritis"
              />
            }
          </>
        }
      </Box>
    </NestedList>
  );
};
