import { Box, Typography, SvgIcon } from '@material-ui/core';
import InvoiceSvg from './invoice-icon.svg';

export const ClaimEmptyContent = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      m="0 auto"
      textAlign="center"
      p={2}
    >
      <Box maxWidth={450} mb={3}>
        <Typography variant="h4">
          There will be a list of invoices indicating the status that were billed
          to the patient
        </Typography>
      </Box>

      <Box color="text.secondary" fontSize={95}>
        <SvgIcon color="inherit" fontSize="inherit"><InvoiceSvg /></SvgIcon>
      </Box>
    </Box>
  );
};
