export const styles = () => ({
  root: {
    height: '100%'
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: 'white'
  }
});
