import { makeStyles, Typography } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { useModal } from '../../../../../../components/ModalsProvider';
import { ImageViewModal } from './ImageViewModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ImagePreview = ({ imageUrl, name }) => {
  const { openModal } = useModal();
  const classes = useStyles();

  const handleView = () => {
    openModal(ImageViewModal, {
      payload: { imageUrl }
    });
  };

  return (
    <div className={classes.root} onClick={handleView}>
      <div className={classes.imageWrap}>
        <img src={imageUrl} alt="" className={classes.image} />

        <div className={classes.previewIndicator}>
          <EyeIcon className={classes.previewIndicatorIcon} />
        </div>

        <div className={classes.previewBackdrop}>
          <EyeIcon />
        </div>
      </div>
      <Typography className={classes.name}>{name}</Typography>
    </div>
  );
};
