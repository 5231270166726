import { useState, useContext, useEffect } from 'react';
import { saveAs } from 'file-saver';
import {
  makeStyles,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Collapse
} from '@material-ui/core';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as emailsApi from '../../../../api/emails';
import { stopPropagation } from '../../../../helpers/dom';
import { Popper } from '../../../../components/Popper';
import { Checkbox } from '../../../../components/FormField';
import { MessagesContext } from '../MessagesContext';
import { AttachmentItem } from './AttachmentItem';
import { Menu } from './AttachmentItem/Menu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Attachments = ({ filter = {}, messageItem = {}, attachmentsLength, attachments }) => {
  const classes = useStyles();
  const [ expanded, setExpanded ] = useState(false);
  const messagesContext = useContext(MessagesContext);
  const [ selectedFiles, setSelectedFiles ] = useState([]);
  const media = messagesContext?.media?.length ? messagesContext?.media : attachments;

  const allItemsIsSelected = () => {
    return attachments.length === selectedFiles.length;
  };

  const toggleItemSelection = (name) => {
    setSelectedFiles(
      selectedFiles.indexOf(name) !== -1
        ? selectedFiles.filter((item) => item !== name)
        : selectedFiles.concat(name)
    );
  };

  const toggleAllItemsSelection = () => {
    setSelectedFiles(allItemsIsSelected() ? [] : attachments.map((item) => item.original_filename || item.file_name));
  };

  const toggleMore = () => {
    if (messagesContext && !messagesContext?.isFetchedMedia) {
      return;
    }

    setExpanded((expanded) => !expanded);
  };

  const downloadAllAttachments = () => {
    emailsApi.downloadAllAttachment({
      params: {
        folder_id: filter.folder_id,
        message_uid: messageItem.uid
      }
    }).then(({ data }) => {
      const blob = new Blob([ data ]);

      saveAs(blob, 'attachments.zip');
    });
  };

  useEffect(() => {
    setExpanded(false);
  }, [ messageItem.attachments ]);

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <div className={classes.col}>
          <Typography variant="h4" className={classes.title}>
            <AttachmentIcon className={classes.filesIcon} />
            {attachmentsLength} Attachments
          </Typography>
        </div>

        <div className={classes.col}>
          {!!messagesContext && !!selectedFiles.length &&
            <Checkbox
              withoutFormik
              size="small"
              checked={allItemsIsSelected()}
              indeterminate={!!selectedFiles.length && !allItemsIsSelected()}
              onClick={stopPropagation(toggleAllItemsSelection)}
            />
          }

          {!!messagesContext &&
            <Tooltip title="Download all" placement="top-start">
              <IconButton
                className={classes.button}
                color="inherit"
                size="small"
                onClick={downloadAllAttachments}
              >
                <CloudDownloadOutlinedIcon/>
              </IconButton>
            </Tooltip>
          }

          {!!messagesContext && !!selectedFiles.length &&
            <Popper
              placement="left-start"
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <IconButton buttonRef={anchorRef} color="inherit" size="small" onClick={handleToggle}>
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                )
              }
            >
              {({ handleClose }) => (
                <Menu
                  isDisabledView={messagesContext && !messagesContext?.isFetchedMedia}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  onClose={handleClose}
                />
              )}
            </Popper>
          }

          <Divider orientation="vertical" className={classes.divider} />

          <IconButton
            disabled={messagesContext ? !messagesContext?.isFetchedMedia : false}
            color="primary" size="small"
            className={classes.button}
            onClick={toggleMore}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>

      <Collapse in={expanded} className={classes.attachments}>
        <div className={classes.attachmentsRow}>
          {media.map((attachment, index) => (
            <AttachmentItem
              key={index}
              selectedFiles={selectedFiles}
              attachment={attachment}
              attachments={media}
              toggleItemSelection={toggleItemSelection}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};
