import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button } from '@material-ui/core';
import * as documentsApi from '../../../../../../../api/profile';
import { TextField, Select } from '../../../../../../../components/FormField';
import { Loader } from '../../../../../../../components/Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../../components/Modal';
import { fetchMedicalDocumentTypes } from '../DocumentsProvider';

export const CreateDocumentModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { formID, document = {}, fetchDocuments }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ type, setType ] = useState(null);

  const handleCreateDocument = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.type = values?.type?.value || values?.type;

    return documentsApi.createMedicalDocument(values).then(() => {
      fetchDocuments();
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve();
    }).catch(({ errors }) => {
      if (errors) {
        errors && setErrors(errors);
        enqueueSnackbar('Document not created', { variant: 'error' });
      }
    });
  };

  const handleUpdateDocument = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.type = values?.type?.value || values?.type;

    return documentsApi.updateMedicalDocument(values).then(() => {
      fetchDocuments();
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      handleModalResolve();
    }).catch(({ errors, message }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        errors && setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  useEffect(() => {
    !type && fetchMedicalDocumentTypes().then((data) => {
      setType(data.find(({ value }) => value === document.type));
    });
  }, []);

  return (
    <Dialog {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={{
          ...document,

          model_type: 'medical_form',
          model_id: Number(formID),
          medical_form_id: Number(formID),
          type
        }}
        onSubmit={document?.id ? handleUpdateDocument : handleCreateDocument}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              {document?.id ? 'Update document' : 'Add document'}
            </ModalHeader>

            <ModalBody>
              <TextField
                name="name"
                label="Document name"
                margin="dense"
              />

              <Select
                isSearchable={false}
                name="type"
                label="Document Type"
                promiseWithOptions={fetchMedicalDocumentTypes}
                TextFieldProps={{ margin: 'dense' }}
              />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
