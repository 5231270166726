import { useFormikContext } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { autoAccidentStatesOptions } from '../../../claims/SituationalNumberFormModal/SituationalNumberForm/data';
import { Autocomplete, KeyboardDatePicker } from '../../../FormField';
import { autoAccidentCountryOptions } from '../../../../dataMaps';
import { typeOfAccidentOptions } from '../../accidentTypes';
import { DoiForm } from '../DoiForm';
import { formTypesMap } from '../formTypesMap';

export const FormContent = () => {
  const { values } = useFormikContext();

  return (
    <Grid container spacing={2}>
      <DoiForm disableHidden />

      {values?.medical_form !== formTypesMap.private &&
        <>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              name="accident_type"
              label="Type of accident"
              options={typeOfAccidentOptions}
              margin="dense"
            />
          </Grid>

          {values?.medical_form !== formTypesMap.compensation &&
            <>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  name="auto_accident_state"
                  label="Accident State"
                  margin="dense"
                  options={autoAccidentStatesOptions}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <Autocomplete
                  name="auto_accident_country"
                  label="Accident Country"
                  margin="dense"
                  options={autoAccidentCountryOptions}
                />
              </Grid>
            </>
          }
        </>
      }

      {values?.medical_form === formTypesMap.compensation &&
        <>
          <Grid item xs={12}>
            <Typography variant="h6">
              Unable to Work
            </Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              clearable
              name="unable_work_date_from"
              label="From"
              outputFormat="YYYY-MM-DD"
              margin="dense"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              clearable
              name="unable_work_date_to"
              label="To"
              outputFormat="YYYY-MM-DD"
              margin="dense"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              clearable
              name="last_work_date"
              label="Last Work Date"
              outputFormat="YYYY-MM-DD"
              margin="dense"
            />
          </Grid>
        </>
      }
    </Grid>
  );
};
