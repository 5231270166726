import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { toggleSidebar } from '../../../../../store/dashboard/layout';
import { updateUserStorage } from '../../../../../store/globalUser/operations';
import { getOverriderStyles } from './styles';

const useStyles = makeStyles({
  input: {
    color: 'rgba(255, 255, 255, 0.8)'
  }
});

export const CaseInfo = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const storageInfo = useSelector(({ globalUser }) => globalUser?.data);
  const [ caseItem, setCaseItem ] = useState(null);
  const classes = useStyles();

  const handleCaseChange = (option) => {
    if (!option) {
      return;
    }

    const caseItem = storageInfo?.cases?.find(({ id }) => id === option?.id);

    if (!caseItem) {
      dispatch(updateUserStorage({
        cases: [ ...storageInfo?.cases, option ]
      }));
    }

    if (option?.id) {
      history.push(`/cases/${option?.id}`);
    }

    setCaseItem(option);
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    const storageCase = storageInfo?.cases?.find(({ id }) => id === caseItem?.id);

    if (storageCase) {
      setCaseItem(null);
    }
  }, [ caseItem, storageInfo?.cases ]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        case_id: caseItem
      }}
      onSubmit={null}
    >
      {() => (
        <CasesSelect
          label={false}
          name="case_id"
          placeholder="Select case"
          styles={getOverriderStyles(theme)}
          margin="dense"
          hiddenOptions={storageInfo?.cases}
          TextFieldProps={{
            InputProps: {
              classes: {
                root: classes.input
              }
            }
          }}
          onChange={handleCaseChange}
        />
      )}
    </Formik>
  );
};
