import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { flow } from 'lodash';
import { Dialog, Box, Grid, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import * as appointmentsApi from '../../../api/appointments';
import { EditModal } from '../../../app/Dashboard/AppointmentsPage/WaitingList/EditModal';
import { Loader } from '../../Loader';
import { IconButton } from '../../IconButton';
import { useModal } from '../../ModalsProvider';
import { ConfirmationModal } from '../../ConfirmationModal';
import { ModalContainer, ModalHeader, ModalBody, ModalHeaderActions } from '../../Modal';
import { Divider } from '../../Divider';
import { Tooltip } from '../../Tooltip';
import { VerticalDivider } from '../../VerticalDivider';
import { AppointmentsDeleteModal } from '../AppointmentsDeleteModal';
import { AppointmentEditModal } from '../AppointmentEditModal';
import { Activity } from '../Activity';
import { AppointmentInfo } from './AppointmentInfo';
import { InsuranceInfo } from './InsuranceInfo';
import { PatientInfo } from './PatientInfo';
import { PaymentInfo } from './PaymentInfo';

export const AppointmentViewModal = ({
  DialogProps,
  payload: {
    disableApptActions,
    appointmentID,
    disableUpdate = false,
    form_type = null,
    insurance_info = null,
    medical_forms_id = null,
    disableApptBook = false,
    onAppointmentUpdate = () => {},
    onAppointmentDelete = () => {},
    onWaitingListUpdate = () => {}
  },
  handleModalResolve,
  handleModalReject
}) => {
  const [ appointment, setAppointment ] = useState(null);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isEditable = !disableUpdate && !!appointment && !hasRole(rolesMap.client, rolesMap.patient);

  const handleDeleteAppointment = () => {
    if (appointment.group_id) {
      openModal(AppointmentsDeleteModal, {
        payload: {
          appointment
        },
        onModalResolved: () => {
          onAppointmentDelete();
          handleModalResolve();
        }
      });
    } else {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          appointmentsApi.deleteAppointment(appointment.id).then(() => {
            enqueueSnackbar('Appointment successfully deleted', { variant: 'success' });
            onAppointmentDelete();
            handleModalResolve();
          });
        }
      });
    }
  };

  const handleUpdateAppointment = (appointment) => {
    onAppointmentUpdate(appointment);
    setAppointment(appointment);
    onWaitingListUpdate();
  };

  const openAppointmentEditModal = () => {
    openModal(AppointmentEditModal, {
      payload: {
        insurance: insurance_info,
        medical_forms_id,
        form_type,
        appointmentID: appointment.id,
        disableApptBook
      },

      onModalResolved: (appointment) => {
        handleUpdateAppointment(appointment);
      }
    });
  };

  const handleWaitingListItemUpdate = () => {
    openModal(EditModal, {
      payload: { appointment },
      onModalResolved: (data) => {
        handleUpdateAppointment(data);
      }
    });
  };

  const handleWaitingListItemDelete = () => {
    onAppointmentDelete(appointment.id);
  };

  const fetchAppointment = () => {
    setAppointment(null);

    appointmentsApi.fetchAppointment(appointmentID).then((appointment) => {
      setAppointment(appointment);
    });
  };

  useEffect(() => {
    fetchAppointment();
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader icon={<InsertInvitationOutlinedIcon />} onClose={handleModalReject}>
          Appointment Details

          {isEditable &&
            <ModalHeaderActions disableGutters>
              <IconButton
                color="info"
                onClick={hasRole(rolesMap.patient) ? handleWaitingListItemUpdate : openAppointmentEditModal}
              >
                <EditOutlinedIcon />
              </IconButton>

              <Tooltip
                isExistTooltip={appointment.has_claim}
                title="Appointment is connected with a claim so it cannot be deleted"
              >
                <div>
                  <IconButton
                    disabled={appointment.has_claim}
                    color="error"
                    onClick={disableApptActions
                      ? flow(handleWaitingListItemDelete, handleModalReject)
                      : handleDeleteAppointment
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </ModalHeaderActions>
          }
        </ModalHeader>

        <Loader p={2} loading={!appointment} render={
          () => (
            <ModalBody disablePaddings>
              <Grid container wrap={isMobile ? 'wrap' : 'nowrap'}>
                <Grid item md={8} xs={12} container>
                  <Grid item xs={12} container wrap={isMobile ? 'wrap' : 'nowrap'}>
                    <Grid item md={6} xs={12} component={Box} pt={2} pr={2} pb={1.5} pl={3.5}>
                      <PatientInfo appointment={appointment} />
                    </Grid>

                    <Hidden only={[ 'sm', 'xs' ]}>
                      <VerticalDivider />
                    </Hidden>

                    <Grid item md={6} xs={12} component={Box} pt={2} pr={2} pb={1.5} pl={3}>
                      <AppointmentInfo
                        disableUpdate={disableUpdate}
                        appointment={appointment}
                        onUpdate={handleUpdateAppointment}
                      />
                    </Grid>
                  </Grid>

                  <Hidden only={[ 'sm', 'xs' ]}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Hidden>

                  <Grid item xs={12} container wrap={isMobile ? 'wrap' : 'nowrap'}>
                    <Grid item md={6} xs={12} component={Box} pt={2} pr={2} pb={1.5} pl={3}>
                      <PaymentInfo appointment={appointment} onUpdate={fetchAppointment} />
                    </Grid>

                    <Hidden only={[ 'sm', 'xs' ]}>
                      <VerticalDivider />
                    </Hidden>

                    <Grid item md={6} xs={12} component={Box} pt={2} pr={2} pb={1.5} pl={3.5}>
                      <InsuranceInfo appointment={appointment} />
                    </Grid>
                  </Grid>
                </Grid>

                <Hidden only={[ 'sm', 'xs' ]}>
                  <VerticalDivider />
                </Hidden>

                <Grid item md={4} xs={12}>
                  <Box display="flex" height="100%" px={isMobile ? 1 : 0}>
                    <Activity appointment={appointment} />
                  </Box>
                </Grid>
              </Grid>
            </ModalBody>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
