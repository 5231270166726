import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles, Button, IconButton, Grid } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as codesApi from '../../../../../../api/codes/payer';
import { TextField, ClaimFillingIndicatorsSelect } from '../../../../../../components/FormField';
import { Loader } from '../../../../../../components/Loader';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { validationSchema } from '../validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Create = ({ baseUrl }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const createCode = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return codesApi.createFinancialClass(values).then(() => {
      enqueueSnackbar('Code successfully created', { variant: 'success' });
      history.push(`${baseUrl}/financial-class`);
    }).catch(({ errors, message }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      initialValues={{
        claim_filing_indicator_id: '',
        code: '',
        description: ''
      }}
      validationSchema={validationSchema}
      onSubmit={createCode}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form noValidate className={classes.root} onSubmit={handleSubmit}>
          <Page>
            <PageHeader>
              <IconButton component={Link} to={`${baseUrl}/financial-class`} color="primary" edge="start">
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>

              <PageHeaderTitle>Add Financial Class</PageHeaderTitle>

              <PageHeaderActions>
                <Button component={Link} to={`${baseUrl}/financial-class`} color="primary">
                  Cancel
                </Button>

                <Loader
                  surface
                  loading={isSubmitting}
                  render={() => (
                    <Button autoFocus disabled={isSubmitting} type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  )}
                />
              </PageHeaderActions>
            </PageHeader>

            <PageBody>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    required
                    name="code"
                    label="Code"
                    placeholder="Enter code..."
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <TextField
                    required
                    name="description"
                    label="Description"
                    placeholder="Enter description..."
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <ClaimFillingIndicatorsSelect isClearable name="claim_filing_indicator_id" />
                </Grid>
              </Grid>
            </PageBody>
          </Page>
        </form>
      )}
    </Formik>
  );
};
