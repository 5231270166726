import { mdiAccountEditOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import cn from 'classnames';
import { makeStyles, SvgIcon } from '@material-ui/core';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { IconLabel } from '../../../../../../components/IconLabel';
import { Scrollbars } from '../../../../../../components/Scrollbars';
import { expensesCategoryMap } from '../../../../../../components/billing';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const List = ({ statistics }) => {
  const classes = useStyles();
  const statisticsData = statistics.map((item) => ({
    title: item.title.toLowerCase(),
    ...item
  }));

  return (
    <div className={classes.root}>
      <Scrollbars>
        {statisticsData.map((item, i) => (
          <div key={i} className={classes.row}>
            <div className={classes.title}>
              {!!expensesCategoryMap[item.title] ?
                <IconLabel
                  label={expensesCategoryMap[item.title].name}
                  color={expensesCategoryMap[item.title].color}
                  icon={expensesCategoryMap[item.title].icon}
                  className={classes.iconLabel}
                  variant="outlined"
                />
                :
                <IconLabel
                  label={item.title}
                  icon={<SvgIcon><Icon path={mdiAccountEditOutline} /></SvgIcon>}
                  className={classes.iconLabel}
                  variant="outlined"
                />
              }
            </div>

            <div className={cn(classes.title, classes.title_total)}>
              <CurrencyFormat value={item.total} />
            </div>
          </div>
        ))}
      </Scrollbars>
    </div>
  );
};
