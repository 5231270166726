import { orderByOptions } from './orderByOptions';

export const transformRelationsForFilterToOptions = (relationsForFilter) => {
  const orderByValue = relationsForFilter?.order_by?.value ?? relationsForFilter?.order_by;

  return {
    ...relationsForFilter,

    order_by: orderByOptions.find(({ value }) => value === orderByValue)
  };
};

export const transformRelationsToReset = (filter) => {
  return {
    ...filter,

    type_insurance: filter?.type_insurance?.value ?? filter?.type_insurance,
    status_type: filter?.status_type?.value ?? filter?.status_type,
    order_by: filter?.order_by?.value ?? filter?.order_by
  };
};
