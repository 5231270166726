export const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${palette.divider}`,
    margin: 0,
    padding: spacing(1.25, 3)
  },

  actions: {
    marginLeft: 'auto',
    padding: 0,

    '& > * + *': {
      marginLeft: spacing()
    }
  }
});
