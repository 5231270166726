import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, omit } from 'lodash';
import { useSelector } from 'react-redux';
import { api } from '../../../api';
import * as chatsApi from '../../../api/chat';
import { MessengerContext } from '../MessengerProvider';
import { useThreads } from '../utils/useThreads';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const ThreadsContext = createContext(null);

export const ThreadsContextProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    threads,
    pagination,
    isFetching,
    isFetched,
    filter
  } = state;
  const cancelFetch = useRef(() => {});
  const { filter: commonFilter } = useContext(MessengerContext);
  const currentUser = useSelector(({ profile }) => profile.user);

  const fetchThreads = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.THREADS_FETCH_REQUEST });

    chatsApi.fetchThreads({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.THREADS_FETCH_SUCCESS, payload: data });
    });
  };

  const addThread = (thread) => {
    dispatch({ type: types.ADD_THREAD, payload: thread });
  };

  const deleteThread = (thread) => {
    dispatch({ type: types.DELETE_THREAD, payload: thread });
  };

  const updateThread = (thread) => {
    dispatch({ type: types.UPDATE_THREAD, payload: thread });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchThreads({ page: filter.page + 1 });
    }
  };

  const resetThreads = (newFilter) => {
    dispatch({ type: types.RESET_THREADS, payload: newFilter });

    fetchThreads({ page: 1, ...newFilter });
  };

  const raiseThread = ({ thread }) => {
    dispatch({ type: types.RAISE_THREAD, payload: thread });
  };

  const handleThreadSeen = ({ thread_id, user_id }) => {
    if (currentUser.id === user_id) {
      dispatch({ type: types.MARK_THREAD_AS_SEEN, payload: { threadId: thread_id } });
    }
  };

  const handleThreadLikeChange = (payload) => {
    dispatch({ type: types.TOGGLE_THREAD_AS_FAVORITE, payload });
  };

  const providerValue = {
    threads,
    isFetched,
    isFetching,
    filter: {
      ...filter,
      ...pagination
    },
    fetchThreads,
    addThread,
    deleteThread,
    resetThreads,
    loadNextPage
  };

  useThreads({
    onMessageCreated: raiseThread,
    onThreadUpdated: updateThread,
    onThreadCreated: addThread,
    onThreadArchived: deleteThread,
    onThreadSeen: handleThreadSeen,
    onThreadLikeChange: handleThreadLikeChange
  });

  useEffect(() => {
    const newFilter = omit({ ...filter, ...commonFilter }, [ 'page' ]);

    if (!isEqual(omit(filter, [ 'page' ]), newFilter)) {
      resetThreads(newFilter);
    }
  }, [ commonFilter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ThreadsContext.Provider value={providerValue}>
      {children}
    </ThreadsContext.Provider>
  );
};
