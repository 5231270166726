import { forwardRef } from 'react';
import { CKEditor } from 'ckeditor4-react';
import cn from 'classnames';
import { makeStyles, FormHelperText } from '@material-ui/core';
import { useFormikField } from '../useFormikField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Editor = forwardRef(({ name, margin, onChange = () => {}, ...props }, ref) => {
  const classes = useStyles({ margin });
  const {
    fieldProps: [ { value } = {}, { touched } = {}, { setTouched, setValue } ],
    error
  } = useFormikField(name);

  const handleBeforeLoad = (CKEDITOR) => {
    CKEDITOR.disableAutoInline = true;
    CKEDITOR.config.removePlugins = 'htmlwriter';
    CKEDITOR.on('instanceCreated', () => {
      CKEDITOR.addCss(`
        p {
          margin: 0;
        }
      `);
    });
  };

  const handleChange = (event) => {
    setValue(event.editor.getData());
    setTouched(true);
    onChange(event);
  };

  const handleInstanceReady = ({ editor }) => {
    if (ref) {
      ref.current = editor;
    }
  };

  return (
    <div
      className={cn({
        [classes.marginNormal]: margin === 'normal',
        [classes.marginDense]: margin === 'dense'
      })}
    >
      <CKEditor
        editorUrl="https://cdn.ckeditor.com/4.22.1/full-all/ckeditor.js"
        config={{
          versionCheck: false,
          toolbar: [
            { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
            { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
            {
              name: 'basicstyles',
              items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting' ]
            },
            { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
            { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
            { name: 'links', items: [ 'Link', 'Unlink' ] },
            {
              name: 'paragraph',
              items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ]
            },
            { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule' ] },
            { name: 'tools', items: [ 'Maximize' ] },
            { name: 'editing', items: [ 'Scayt' ] }
          ],
          extraPlugins: 'tableresize',
          contentsCss: []
        }}
        initData={value}
        onBeforeLoad={handleBeforeLoad}
        onChange={handleChange}
        onInstanceReady={handleInstanceReady}

        {...props}
      />

      {touched && error &&
        <FormHelperText error>{error}</FormHelperText>
      }
    </div>
  );
});
