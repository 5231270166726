export const styles = ({ spacing, palette: { primary }, transitions }) => ({
  actionLink: {
    transition: transitions.create(),

    '&:hover': {
      color: primary.main
    }
  },

  actionIcon: {
    marginRight: spacing(0.5)
  }
});
