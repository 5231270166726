import { ListRow, ListRowCell } from '../../../../../ListRow';
import { fieldsKeysMap } from '../../filterKeysMap';
import { columnsWidths } from '../List';

export const ListHeader = ({ hiddenColumns = [] }) => {
  return (
    <ListRow header>
      <ListRowCell flexBasis={columnsWidths.search}>
        Search
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.createDate}>
        Create Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dueDate}>
        Due Date
      </ListRowCell>

      {!hiddenColumns.includes(fieldsKeysMap.cases) && (
        <ListRowCell flexBasis={columnsWidths.cases}>
          Cases
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.types}>
        Types
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.priorities}>
        Priorities
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.statuses}>
        Statuses
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.users}>
        Users
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
