import moment from 'moment';
import { emailsInfoToString } from '../../../../helpers/emailsToString';

export const getMessageDate = (replyTo) => {
  const utcOffset = moment().utcOffset();
  return moment.unix(replyTo.date)
    [utcOffset > 0 ? 'subtract' : 'add'](utcOffset, 'm')
    .format('ddd, MMM D, YYYY [at] h:mm A');
};

export const getSignature = (signature) => {
  return signature
    ? `<div><br/><br/><br/><br/>${signature}</div>`
    : '';
};

export const getOriginalMessageSignature = (replyTo) => {
  return `
    <div>
      <hr/>
      -- Original message -- <br/>
      <b>From:</b> ${emailsInfoToString(replyTo.from || [])}<br/>
      <b>Date:</b> ${getMessageDate(replyTo)}<br/>
      <b>Subject:</b> ${replyTo.subject}<br/>
      <b>To:</b> ${emailsInfoToString(replyTo.to || [])}<br/>
      <b>СС:</b> ${emailsInfoToString(replyTo.cc || [])}<br/>
      ${replyTo.body}
      </div>
  `;
};

export const getForwardedMessageTemplate = (message) => {
  return `
    <blockquote type="cite">
      <div>Begin forwarded message:</div>
      <br>

      <b>From:</b> ${emailsInfoToString(message.from || [])}<br/>
      <b>Subject:</b> ${message.subject}<br/>
      <b>Date:</b> ${getMessageDate(message)}<br/>
      <b>To:</b> ${emailsInfoToString(message.to || [])}<br/>
      <b>Reply-To:</b> ${emailsInfoToString(message.reply_to || [])}<br/>
      ${message.body}
    </blockquote>
  `;
};
