import { makeStyles, FormControlLabel, Typography, Box } from '@material-ui/core';
import { Checkbox } from '../../../../../../components/FormField';
import { formTypesMap } from '../../../../../../components/medical/forms';
import {
  symptomsInjuryTypesList,
  symptomsMap,
  symptomsTypesMap,
  symptomsTypesList
} from '../MedicalFormsProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CheckboxesComponent = ({ type, symptoms }) => {
  const classes = useStyles();
  const symptomsList = type === formTypesMap.injury ? symptomsInjuryTypesList : symptomsTypesList;

  return (
    <div className={classes.root}>
      {symptomsList.map((item, i) => (
        <div key={i} className={classes.column}>
          <div className={classes.columnTitle}>
            {symptomsTypesMap[item].icon}

            <Typography variant="h4" className={classes.title}>
              {symptomsTypesMap[item].name}
            </Typography>
          </div>

          <Box display="flex" flexDirection="column" pl={1}>
            {symptoms && symptoms[item].map((symptom, i) => (
              <FormControlLabel
                key={i}
                label={
                  <Typography className={classes.checkboxTitle}>
                    {symptomsMap[symptom]}
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name={
                      type === formTypesMap.injury ?
                        `forms.current_symptoms_pi.${item}.${symptom}`
                        :
                        `forms.review_of_symptoms.${item}.${symptom}`
                    }
                  />
                }
              />
            ))}
          </Box>
        </div>
      ))}
    </div>
  );
};
