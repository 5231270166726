import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { columnsMap, columnsWidths } from '../List';

export const TableHeader = ({ hiddenColumns = [] }) => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.discovery}>
          Discovery
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.to}>
          To
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.from}>
          From
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.sentDate) &&
          <ListRowCell flexBasis={columnsWidths.sentDate}>
            Sent Date
          </ListRowCell>
        }

        {!hiddenColumns.includes(columnsMap.extension) &&
          <ListRowCell flexBasis={columnsWidths.extension}>
            Extension
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.dueDate}>
          Due Date
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.receivedDate) &&
          <ListRowCell flexBasis={columnsWidths.receivedDate}>
            Received Date
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.endDate}>
          End Date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
