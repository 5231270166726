export const handleAppointmentChange = (appointment, { setFieldValue = () => {} } = {}) => {
  if (!appointment) {
    return;
  }

  if (appointment.patient_insurance) {
    setFieldValue('insurance_id', appointment.patient_insurance);
    setFieldValue('insurance', appointment.patient_insurance);
  }

  setFieldValue('patient_id', appointment.patient);
  setFieldValue('office_id', appointment.office);
  setFieldValue('provider_primary_care_id', appointment.appointment_book?.provider);
  setFieldValue('appointment', appointment);
  setFieldValue('appointment_id', appointment.id);
};
