import { Formik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as waitingListApi from '../../../../../api/appointments';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { validationSchema } from '../validationSchema';
import { FormBody } from './FormBody';
import { initialValues } from './initialValues';

export const CreateModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { enqueueSnackbar } = useSnackbar();

  const createWaitingListItem = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return waitingListApi.createWaitingListItem({
      ...values,

      time: moment.duration(`${values._hours || 0}:${values._minutes || 0}`).asSeconds() || null
    }).then(() => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve();
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Appointment not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createWaitingListItem}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarPlus} /></SvgIcon>} onClose={handleModalReject}>
              Add appointment
            </ModalHeader>

            <ModalBody>
              <FormBody />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Add
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
