import { useContext } from 'react';
import { Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { stopPropagation } from '../../../../../../helpers/dom';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { UserPreviewModal } from '../../../../../../components/users';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Popper } from '../../../../../../components/Popper';
import { Date } from '../../../../../../components/Date';
import { ImmunizationContext } from '../../ImmunizationProvider';
import { ImmunizationsMenu } from '../../ImmunizationsMenu';
import { PreviewImmunizationModal } from '../../PreviewImmunizationModal';
import { minRowHeight, columnsWidths, widthBreakpointSmall, widthBreakpointMedium } from '../List';

export const Row = ({ width, immunization }) => {
  const { openModal } = useModal();
  const { selectedIDs, toggleItemSelection, fetchImmunizationReports } = useContext(ImmunizationContext);
  const isSelected = selectedIDs.indexOf(immunization.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(immunization.id);
  };

  const openPreview = () => {
    openModal(PreviewImmunizationModal, {
      payload: {
        immunization,
        onUpdate: fetchImmunizationReports
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <ListRow
      button
      minHeight={minRowHeight}
      onClick={stopPropagation(openPreview)}
    >
      {!hasRole('client', 'patient') &&
        <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />
      }

      <ListRowCell flexBasis={columnsWidths.date}>
        <Date date={immunization.date} iconFontSize="small" />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.name}>
        <Tooltip isExistTooltip={!!immunization?.immunization?.name} title={immunization?.immunization?.name}>
          <Typography noWrap>
            {immunization?.immunization?.name || '-'}
          </Typography>
        </Tooltip>
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell grow flexBasis={columnsWidths.note}>
          <Tooltip isExistTooltip={!!immunization?.note} title={immunization.note}>
            <Typography noWrap>
              {immunization.note || '-'}
            </Typography>
          </Tooltip>
        </ListRowCell>
      }

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.approved_by}>
        <UserLink
          noWrap
          size="md"
          user={immunization.approved}
          onClick={stopPropagation(openUserPreview(immunization?.approved?.id))}
        />
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.office}>
        <OfficeLink
          noWrap
          tooltipTitle={immunization?.office?.full_address}
          isExistTooltip={!!immunization?.office?.full_address}
          office={immunization?.office}
        />
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <ImmunizationsMenu immunization={immunization} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
