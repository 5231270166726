import { forwardRef } from 'react';
import T from 'prop-types';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import { AppBar } from '../../AppBar';

const propTypes = {
  disablePaddings: T.bool
};

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    borderBottom: ({ disableBorderBottom }) => disableBorderBottom && 0
  },

  toolbar: {
    display: 'flex',
    minHeight: 54,
    padding: ({ disablePaddings }) => disablePaddings ? 0 : spacing(1, 3),
    backgroundColor: ({ color }) => color || null,

    [breakpoints.down('xs')]: {
      padding: ({ disablePaddings }) => disablePaddings ? 0 : spacing()
    },

    [breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },

    '& > * + *': {
      marginLeft: spacing(2)
    }
  }
}));

export const PageHeader = forwardRef(({ children, className, ToolbarProps, ...props }, ref) => {
  const classes = useStyles(props);

  return (
    <AppBar
      ref={ref}
      className={cn(classes.root, className)}
      ToolbarProps={{ className: classes.toolbar, variant: 'regular', ...ToolbarProps }}
    >
      {children}
    </AppBar>
  );
});

PageHeader.propTypes = propTypes;
