import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { usersRolesOptions } from '../../../../../../dataMaps';
import { saveFilter } from '../../../../../../store/lastFilters';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { Select, TextField } from '../../../../../../components/FormField';
import { IconComponent } from '../../../../../../components/saved-filters';
import { UsersContext } from '../../../UsersContext';
import { CardContent } from './CardContent';
import { fieldsKeysMap, filterLabels } from './filterKeysMap';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const MODAL_WIDTH = 650;

const initialValues = {
  name: null,
  email: null,
  role: null
};

export const FiltersBar = ({ filterKey, hiddenFilterFields = [] }) => {
  const classes = useStyles();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [ relationsForFilter, setRelationsForFilter ] = useState({});
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { resetUsers } = useContext(UsersContext);

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    resetUsers({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleSelectChange = (name) => (option) => {
    resetUsers({ [name]: option ? option.value : null });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.value }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues({
      ...filter,

      role: usersRolesOptions.find(({ value }) => value === filter?.role)
    });

    resetUsers({
      ...filter,

      role: usersRolesOptions.find(({ value }) => value === filter?.role)?.value
    });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      enableSettings
      formikRef={formikRef}
      toolbarClassName={classes.toolbar}
      initialValues={initialValues}
      filterKey={filterKey}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          hiddenFields={hiddenFilterFields}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      hiddenFields={hiddenFilterFields}
      onReset={() => applySavedFilter(initialValues)}
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.name,
          label: filterLabels[fieldsKeysMap.name],
          field: (
            <TextField
              name="name"
              label="Search by user name"
              placeholder="Search..."
              margin="dense"
              onChange={handleFieldChange('name')}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.email,
          label: filterLabels[fieldsKeysMap.email],
          field: (
            <TextField
              name="email"
              label="Search by email"
              placeholder="Search..."
              margin="dense"
              onChange={handleFieldChange('email')}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.role,
          label: filterLabels[fieldsKeysMap.role],
          field: (
            <Select
              isClearable
              name="role"
              label="Select user role"
              options={usersRolesOptions}
              onChange={handleSelectChange('role')}
              TextFieldProps={{ margin: 'dense' }}
            />
          )
        }
      ]}
    />
  );
};

