import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiCalendarPlus } from '@mdi/js';
import * as appointmentBooksApi from '../../../../api/codes/appointments/appointment-books';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../Modal';
import { Loader } from '../../../Loader';
import {
  ApptBookForm,
  initialValues,
  validationSchema
} from '../ApptBookForm';

export const CreateApptBookModal = ({
  payload: {
    initialValues: initialValuesProp = {}
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();

  const createAppointmentBook = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.provider_id = values?.provider_id?.id || values?.id;

    return appointmentBooksApi.createAppointmentBook(values).then((apptBook) => {
      enqueueSnackbar(
        'Appointment book successfully created',
        { variant: 'success' }
      );

      handleModalResolve(apptBook);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth="xs"

      {...DialogProps}
    >
      <Formik
        initialValues={{ ...initialValues, ...initialValuesProp }}
        validationSchema={validationSchema}
        onSubmit={createAppointmentBook}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarPlus} /></SvgIcon>} onClose={handleModalReject}>
              Add appt. book
            </ModalHeader>

            <ModalBody>
              <ApptBookForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
