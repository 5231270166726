export const FILES_UPLOADS_OPEN_WIDGET = 'FILES_UPLOADS_OPEN_WIDGET';
export const FILES_UPLOADS_CLOSE_WIDGET = 'FILES_UPLOADS_CLOSE_WIDGET';

export const FILES_UPLOADS_ADD_FILE = 'FILES_UPLOADS_ADD_FILE';
export const FILES_UPLOADS_UPDATE_FILE = 'FILES_UPLOADS_UPDATE_FILE';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL';
export const FILE_UPLOAD_CANCEL = 'FILE_UPLOAD_CANCEL';
export const FILE_UPLOAD_REFRESH = 'FILE_UPLOAD_REFRESH';

export const FILES_UPLOADS_CANCEL_ALL = 'FILES_UPLOADS_CANCEL_ALL';
export const FILES_UPLOADS_CANCEL_OWN_UPLOAD = 'FILES_UPLOADS_CANCEL_OWN_UPLOAD';
export const FILES_UPLOADS_SET_ERROR = 'FILES_UPLOADS_SET_ERROR';
