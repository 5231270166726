import { orderByOptions } from '../../../../../components/claims';
import { orderDirectionsMap } from '../../../../../components/FormField';

export const initialValues = {
  providers: [],
  patients: [],
  appointment_books: [],
  appointment_types: [],
  visit_reasons: [],
  appointment_day_from: null,
  order_direction: orderDirectionsMap.asc,
  appointment_day_to: null,
  order_by: orderByOptions[0].value
};
