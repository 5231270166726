import { useFormikContext } from 'formik';
import {
  makeStyles,
  FormControl,
  FormLabel,
  FormControlLabel,
  Typography,
  InputAdornment,
  Grid
} from '@material-ui/core';
import { TextField, RadioGroup, Radio, KeyboardDatePicker } from '../../../FormField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Ends = () => {
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <FormControl fullWidth component="fieldset" margin="dense">
      <FormLabel component="legend">Ends</FormLabel>

      <RadioGroup row name="ends">
        <Grid container spacing={1} justify="space-between">
          <Grid item xs>
            <FormControlLabel
              value="never"
              className={classes.controlLabel}
              label={<Typography>Never</Typography>}
              control={<Radio name="ends" color="primary" />}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              value="until"
              className={classes.controlLabel}
              name="ends"
              label={
                <div className={classes.field}>
                  <Typography className={classes.field__label}>On</Typography>

                  <KeyboardDatePicker
                    name="until"
                    disabled={values.ends !== 'until'}
                    className={classes.field__input}
                  />
                </div>
              }
              control={<Radio name="ends" color="primary" />}
            />
          </Grid>
        </Grid>

        <FormControlLabel
          value="count"
          className={classes.controlLabel}
          label={
            <div className={classes.field}>
              <Typography className={classes.field__label}>After</Typography>

              <TextField
                name="count"
                disabled={values.ends !== 'count'}
                className={classes.field__input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      occurrences
                    </InputAdornment>
                  )
                }}
              />
            </div>
          }
          control={<Radio name="ends" color="primary" />}
        />
      </RadioGroup>
    </FormControl>
  );
};
