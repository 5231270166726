import { formTypesMap } from '../../../formTypesMap';
import { InsCompanyStep } from './InsCompanyStep';
import { InsuranceStep } from './InsuranceStep';
import { AttorneyStep } from './AttorneyStep';
import { PatientStep } from './PatientStep';

export const getSteps = (form) => {
  const lawForms = [ formTypesMap.injury, formTypesMap.ortho, formTypesMap.compensation ];
  const steps = [
    {
      label: 'Patient',
      value: 'medical_id',
      name: 'medical',
      component: PatientStep
    },
    {
      label: 'Insurance Company',
      value: 'primary_insurance.insurance_company_id',
      name: 'primary_insurance.insurance_company',
      component: InsCompanyStep
    },
    {
      label: 'Insurance',
      value: 'primary_insurance',
      name: 'primary_insurance',
      component: InsuranceStep
    },
    {
      label: 'Attorney',
      value: 'attorney_id',
      name: 'attorney',
      disable: !lawForms.includes(form.form_type),
      component: AttorneyStep
    }
  ];

  return steps?.filter((item) => !item.disable);
};
