import { useEffect, useState } from 'react';
import { Typography, Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { getUserFullName } from '../../../../../../../helpers/users';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { NestedList } from '../../../../../../../components/NestedList';
import { UserLink } from '../../../../../../../components/UserLink';
import { Contact } from '../../../../../../../components/Contact';
import { UserSSN } from '../../../../../../../components/users';
import { Date } from '../../../../../../../components/Date';
import { EmergencyFormContact } from '../EmergencyFormContact';
import { Measurements } from '../Measurements';
import { PhysicianInfo } from '../PhysicianInfo';

export const GeneralInfoInjury = ({ isOpenSwitches, form }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const user = {
    first_name: form?.patient?.first_name || form.first_name,
    last_name: form?.patient?.last_name || form.last_name
  };

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="General info"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Grid
        container
        component={Box}
        px={isSmallScreen ? 0 : 1}
        py={3}
        spacing={isSmallScreen ? 2 : 4}
        alignItems="stretch"
        width="100% !important"
        mx="0 !important"
      >
        <Grid container item sm={6} xs={12} spacing={1}>
          <Grid item xs={5}>
            <Typography color="textSecondary">Name</Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography variant="h5">{getUserFullName(user)}</Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">DOB</Typography>
          </Grid>

          <Grid item xs={7}>
            <Date
              variant="h5"
              date={form?.birthday || form?.patient?.birthday}
            />
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">SSN</Typography>
          </Grid>

          <Grid item xs={7}>
            <UserSSN isShowable={false} user={form} />
          </Grid>
        </Grid>

        <Grid container item sm={6} xs={12} spacing={1}>
          <Grid item xs={5}>
            <Typography color="textSecondary">Phone</Typography>
          </Grid>

          <Grid item xs={7}>
            <Contact
              noWrap
              needIcon
              variant="h5"
              title={form.phone || form?.patient?.phone_number || form?.phone_number}
              type="tel"
              contact={form.phone || form?.patient?.phone_number || form?.phone_number}
            />
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">Mobile Phone</Typography>
          </Grid>

          <Grid item xs={7}>
            <Contact
              noWrap
              needIcon
              variant="h5"
              title={form.mobile_phone || form?.patient?.mobile_phone_number}
              type="tel"
              contact={form.mobile_phone || form?.patient?.mobile_phone_number}
            />
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">Address</Typography>
          </Grid>

          <Grid item xs={7}>
            <AddressLink variant="h5" address={form.full_address || form.address}>
              {form.full_address || form.address}
            </AddressLink>
          </Grid>
        </Grid>

        <Grid container item sm={6} xs={12} spacing={1} alignItems="center">
          <PhysicianInfo form={form} />
        </Grid>

        <Grid item xs={6} />

        <Grid container item sm={6} xs={12} spacing={1} alignItems="center">
          <Grid item xs={5}>
            <Typography color="textSecondary">Law office</Typography>
          </Grid>

          <Grid item xs={7}>
            {form?.primary_insurance?.insurance_company ? (
              <CompanyLink
                variant="h5"
                company={form?.primary_insurance?.insurance_company}
              />
            ) : (
              form?.primary_insurance_name ? (
                <Typography variant="h5">
                  {form?.primary_insurance_name}
                </Typography>
              ) : (
                <Typography color="textSecondary">
                  No info.
                </Typography>
              )
            )}
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">Attorney Name</Typography>
          </Grid>

          <Grid item xs={7}>
            {form.attorney?.id ? (
              <UserLink
                size="md"
                user={form.attorney}
                variant="h5"
              />
            ) : (
              <Typography variant="h5">
                {form?.attorney_first_name} {form?.attorney_last_name}
              </Typography>
            )}
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">Phone Number</Typography>
          </Grid>

          <Grid item xs={7}>
            <Contact
              noWrap
              needIcon
              variant="h5"
              title={form?.attorney?.profile?.phone_number || form?.attorney_phone}
              type="tel"
              contact={form?.attorney?.profile?.phone_number || form?.attorney_phone}
            />
          </Grid>
        </Grid>

        <EmergencyFormContact contacts={form?.emergency_contact || []} />
        <Measurements birthday={form.birthday} measurements={form.measurements} />
      </Grid>
    </NestedList>
  );
};
