import { Grid } from '@material-ui/core';
import { TextField } from '../../../../../components/FormField';

export const VisitReasonForm = () => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="code"
          label="Code"
          placeholder="Enter code..."
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="description"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>
    </Grid>
  );
};
