import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import { Schedule as ScheduleComponent } from '../../../../../../components/schedule-events';
import { apiModelsMap } from '../../../../../../dataMaps/apiModels';
// Cyclic dependency, don't shortening path
import { filterFieldsMap } from '../../../../../../components/schedule-events/FiltersBar/filterFieldsMap';

const hiddenFilterFields = [ filterFieldsMap.cases ];

export const Schedule = ({ caseItem }) => {
  const createScheduleEventModalProps = caseItem?.id ? {
    hideApplyFields: true,
    initialValues: {
      parent_type: apiModelsMap.case,
      parent_id: caseItem?.id,
      users: uniqBy(
        caseItem?.case_users
          ?.filter(({ user }) => user)
          ?.map(({ user }) => user),
        'id'
      )
    }
  } : {};
  const tasksFilterParams = useMemo(() => ({
    cases: caseItem?.id ? [ caseItem.id ] : null
  }), [ caseItem?.id ]);

  return (
    <ScheduleComponent
      hideApplyFields
      hiddenFilterFields={hiddenFilterFields}
      createScheduleEventModalProps={createScheduleEventModalProps}
      tasksFilterParams={tasksFilterParams}
      filter={{ cases: [ caseItem.id ] }}
    />
  );
};
