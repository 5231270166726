import { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { UserPreviewModal } from '../../../../users/UserPreviewModal';
import { VerticalDivider } from '../../../../VerticalDivider';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { useModal } from '../../../../ModalsProvider';
import { UserLink } from '../../../../UserLink';
import { Loader } from '../../../../Loader';
import { Date } from '../../../../Date';
import { MedicalHistoryContext } from '../../MedicalHistoryProvider';
import { minRowHeight } from '../List';

export const Row = ({ item: historyItem, isLoaded }) => {
  const { selectedItem, toggleItemSelection } = useContext(MedicalHistoryContext);
  const { openModal } = useModal();

  const handleItemSelection = () => {
    toggleItemSelection(historyItem);
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow
      button
      selected={selectedItem?.id === historyItem.id}
      minHeight={minRowHeight}
      onClick={handleItemSelection}
    >
      <ListRowCell grow column>
        <Box display="flex" alignItems="center" width="100%">
          <Box flexGrow={1}>
            <Typography variant="h5">
              {historyItem?.sub_data?.title || '-'}
            </Typography>
          </Box>

          <Box component="span" color="info.main">
            <Date color="inherit" date={historyItem?.appointment_at} />
          </Box>
        </Box>

        <Box display="flex" alignItems="center" width="100%">
          <UserLink
            color="textSecondary"
            user={historyItem?.provider}
            onClick={openUserPreview(historyItem?.provider?.id)}
          />

          {historyItem?.sub_data?.text &&
            <VerticalDivider horizontalGutters={1} verticalGutters={1} />
          }

          <Typography noWrap color="textSecondary" variant="body2">
            {historyItem?.sub_data?.text}
          </Typography>
        </Box>
      </ListRowCell>
    </ListRow>
  );
};
