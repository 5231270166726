import { Grid } from '@material-ui/core';
import { PatientDetails } from './PatientDetails';
import { AppointmentDetails } from './AppointmentDetails';
import { AppointmentTimeSection } from './AppointmentTimeSection';

export const AppointmentForm = ({ appointmentType, disableApptBook }) => {
  return (
    <>
      <PatientDetails />

      <Grid container spacing={4} alignItems="flex-start">
        <Grid item sm={6} xs={12}>
          <AppointmentDetails
            appointmentType={appointmentType}
            disableApptBook={disableApptBook}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <AppointmentTimeSection disableApptBook={disableApptBook} />
        </Grid>
      </Grid>
    </>
  );
};
