import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../ListRow';
import { columnsWidths } from './columnsWidths';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.user}>
          User
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.note}>
          Note
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.startedAt}>
          Started At
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.finishedAt}>
          Finished At
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
