import { useState, createContext } from 'react';
import { orderDirectionsMap } from '../../../../../components/FormField';
import { initialState } from './initialState';

export const WaitingListFilterContext = createContext();

export const WaitingListFilterContextProvider = ({ children, ...props }) => {
  const [ filter, setFilter ] = useState({ ...initialState.filter, ...props.filter });
  const [ relationsForFilter, setRelationsForFilter ] = useState({
    order_direction: orderDirectionsMap.asc
  });

  const applyFilter = (newFilter) => {
    setFilter((filter) => ({ ...filter, ...newFilter }));
  };

  const providerValue = {
    filter,
    relationsForFilter,
    setFilter,
    setRelationsForFilter,
    applyFilter
  };

  return (
    <WaitingListFilterContext.Provider value={providerValue}>
      {children}
    </WaitingListFilterContext.Provider>
  );
};
