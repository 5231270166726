import { forwardRef, useRef, useState } from 'react';
import moment from 'moment';
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import { useFormikField } from '../useFormikField';
import { DatePickerBase } from './DatePickerBase';

export const DateTimePicker = forwardRef(({
  withoutFormik,
  name,
  outputFormat = 'X',
  onChange = () => {},

  ...props
}, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField(name);
  const { isFormikField, fieldProps: [ field, , formikHelpers = {} ] = [] } = formikFieldProps;
  const [ value, setValue ] = useState(field?.value ? moment(field?.value, outputFormat) : null);
  const formikValue = useRef(field?.value);

  const handleChange = (date) => {
    const formattedDate = (outputFormat === 'X'
      ? date?.startOf('minutes')?.unix()
      : date?.format(outputFormat)
    ) || null;

    if (isFormikField) {
      formikValue.current = formattedDate;
      formikHelpers.setValue(formattedDate);
    }

    setValue(date);
    onChange(formattedDate);
  };

  return (
    <DatePickerBase
      withoutFormik={withoutFormik}
      formikValue={formikValue.current}
      Component={MuiDateTimePicker}
      name={name}
      outputFormat={outputFormat}
      format="MM/DD/YYYY hh:mm a"
      placeholder="MM/DD/YYYY hh:mm a"
      value={value}
      TextFieldComponentProps={{ readOnly: true }}
      onChange={handleChange}

      {...props}

      ref={ref}
    />
  );
});
