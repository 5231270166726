export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    flexGrow: 1,
    padding: 0,

    [breakpoints.up('md')]: {
      padding: spacing(0, 3)
    }
  }
});
