import { useState } from 'react';
import cn from 'classnames';
import { makeStyles, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { AccountsProvider } from '../AccountsContext';
import { MessagesContext, MessagesProvider } from '../MessagesContext';
import { FiltersBar } from './FiltersBar';
import { LeftSidebar } from './LeftSidebar';
import { EmailsActions } from './EmailsActions';
import { EmailsList } from './EmailsList';
import { EmailView } from './EmailView';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmailsClient = () => {
  const classes = useStyles();
  const [ isOpenSidebar, setIsOpenSideBar ] = useState(true);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    openFiltersBar,
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    toggleFiltersBar
  } = useMobileFilterCollapse();
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  const toggleSideBar = () => {
    setIsOpenSideBar((state) => !state);

    if (isTablet && openFiltersBar) {
      toggleFiltersBar();
    }
  };

  return (
    <AccountsProvider>
      <MessagesProvider>
        <MessagesContext.Consumer>
          {({ isOpenView }) => (
            <Paper elevation={2} className={classes.root}>
              <ScrollWrapper {...scrollWrapperProps}>
                <FilterBarWrapper {...filterBarWrapperProps}>
                  <FiltersBar />
                </FilterBarWrapper>
              </ScrollWrapper>

              <div className={classes.content}>
                <div className={cn(classes.sidebar, { [classes.sidebarOpen]: isOpenSidebar })}>
                  <LeftSidebar
                    isOpenSidebar={isOpenSidebar}
                    filterOpenButton={filterOpenButton}
                    onOpenSideBar={toggleSideBar}
                  />
                </div>

                <div className={classes.main}>
                  <EmailsActions
                    isOpenSidebar={isOpenSidebar}
                    onOpenSideBar={toggleSideBar}
                  />

                  <div className={classes.columns}>
                    <div className={cn(classes.colNav, { [classes.colNavOpen]: !isOpenView })}>
                      <EmailsList />
                    </div>

                    <div className={cn(classes.colMain, { [classes.colMainClose]: !isOpenView })}>
                      <EmailView />
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          )}
        </MessagesContext.Consumer>
      </MessagesProvider>
    </AccountsProvider>
  );
};
