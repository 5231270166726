import { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../../helpers/dom';
import { AddressLink } from '../../../../../../components/AddressLink';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Popper } from '../../../../../../components/Popper';
import { Contact } from '../../../../../../components/Contact';
import { Loader } from '../../../../../../components/Loader';
import { IconButton } from '../../../../../../components/IconButton';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { CompaniesContext } from '../../CompaniesContext';
import { CompaniesMenu } from '../../CompaniesMenu';
import { PreviewCompanyModal } from '../../PreviewCompanyModal';
import {
  columnsWidths,
  companiesMap,
  widthBreakpointLarge,
  widthBreakpointMedium,
  widthBreakpointSmall
} from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  width,
  hiddenColumns = [],
  item: company = {},
  onCompanySelect,
  isLoaded,
  recalculateHeight
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { companies, selectedIDs, toggleItemSelection } = useContext(CompaniesContext);
  const isSelected = selectedIDs.indexOf(company.id) !== -1;

  const handleCompanySelect = () => {
    onCompanySelect(company);
  };

  const handleItemSelection = () => {
    if (onCompanySelect) {
      handleCompanySelect();
    } else {
      toggleItemSelection(company.id);
    }
  };

  const openPreview = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: company?.id }
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, companies.length ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow className={classes.root} onClick={stopPropagation(openPreview)}>
      <ListRowCheckbox
        checked={isSelected}
        onClick={stopPropagation(handleItemSelection)}
      />

      <ListRowCell grow noWrap flexBasis={columnsWidths.name}>
        <CompanyLink underline="none" company={company} />
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.address}>
          <AddressLink
            noWrap
            stopPropagation
            name={company.name}
            address={company.full_address}
          >
            {company.full_address || '-'}
          </AddressLink>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.address}>
        <AddressLink
          noWrap
          stopPropagation
          address={company.billing_full_address}
        >
          {company.billing_full_address || '-'}
        </AddressLink>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.venue}>
        {hiddenColumns?.length ? (
          <Contact
            noWrap
            color="primary"
            type="tel"
            contact={company.phone}
          />
        ) : (
          <AddressLink
            noWrap
            stopPropagation
            address={company.venue_full_address}
          >
            {company.venue_full_address || '-'}
          </AddressLink>
        )}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.email}>
        <Contact
          noWrap
          color="primary"
          type="mailto"
          contact={company.email}
        />
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.phone}>
          <Contact
            noWrap
            type="tel"
            contact={company.phone}
          />
        </ListRowCell>
      }

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.offices}>
          {`${company.offices_count || 0} offices`}
        </ListRowCell>
      }

      {!hiddenColumns.includes(companiesMap.actions) && (
        <ListRowCell flexBasis={columnsWidths.actions}>
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                  <MoreVertIcon/>
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <CompaniesMenu company={company} onClose={handleClose} />
            )}
          </Popper>
        </ListRowCell>
      )}
    </ListRow>
  );
};
