import { useContext } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { IconButton } from '../../../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { columnsWidthsLg, columnsWidthsSm } from '../../../../CodesPOS/MainContent/List';
import { CodesContext } from '../../../CodesContext';
import { CreateCodeBodyPartModal } from '../../../CreateCodeBodyPartModal';
import { minRowHeight } from '../List';

export const Row = ({ code }) => {
  const { deleteCode } = useContext(CodesContext);
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  const handleDeleteCode = () => deleteCode(code);

  const handleUpdateCode = () => {
    openModal(CreateCodeBodyPartModal, {
      payload: {
        codesContext: {
          parentID: code.id
        }
      }
    });
  };

  return (
    <ListRow minHeight={minRowHeight}>
      <ListRowCell flexBasis={columnsWidths.procedure_code}>
        {code.code}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.description}>
        {code.description}
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
        <Tooltip title="Edit code">
          <IconButton
            color="primary"
            variant="contained"
            onClick={handleUpdateCode}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete code">
          <IconButton
            variant="contained"
            color="error"
            onClick={handleDeleteCode}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListRowCell>
    </ListRow>
  );
};
