import { Button, Dialog } from '@material-ui/core';
import LibraryAddIcon from '@material-ui/icons/LibraryAddOutlined';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as casesDepositionApi from '../../../../../../../../api/cases/depositions';
import { Loader } from '../../../../../../../../components/Loader';
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContainer
} from '../../../../../../../../components/Modal';
import { DepositionForm, initialValues, validationSchema } from '../DepositionForm';

export const AddDepositionModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    caseID
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesDepositionApi.createCaseDepositions(caseID, values).then((data) => {
      enqueueSnackbar('Case deposition successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case deposition not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<LibraryAddIcon />}
              onClose={handleModalReject}
            >
              Add Deposition
            </ModalHeader>

            <ModalBody>
              <DepositionForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
