import { PreviewContent } from '../../../../GuestMedicalProfile/PreviewContent';
import { ApptFormContent } from '../ApptFormContent';
import { PainContent } from '../PersonalInjury/PainContent';
import { CurrentSymptoms } from './CurrentSymptoms';
import { Documents } from '../Documents';
import { GeneralInfo } from './GeneralInfo';
import { MedicalHistory } from './MedicalHistory';
import { ReviewContent } from './ReviewContent';

export const formStepComponents = [
  GeneralInfo,
  ApptFormContent,
  CurrentSymptoms,
  PainContent,
  MedicalHistory,
  ReviewContent,
  Documents
];

export const formStepUnconfirmedComponents = [
  GeneralInfo,
  ApptFormContent,
  CurrentSymptoms,
  PainContent,
  MedicalHistory,
  ReviewContent,
  PreviewContent
];
