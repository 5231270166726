import { useContext } from 'react';
import { Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { SelectedItemsCount } from '../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../components/VerticalDivider';
import { PageHeaderActions } from '../../../../components/Page';
import { IconButton } from '../../../../components/IconButton';
import { DeletedMedicalFormsContext } from './DeletedMedicalFormsProvider';

export const ActionsBar = ({ filterOpenButton = () => {} }) => {
  const theme = useTheme();
  const { selectedIDs, restoreDeletedMedicalForms, ...formContext } = useContext(DeletedMedicalFormsContext);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const massDeleteForms = () => {
    formContext.massDeleteForms(selectedIDs);
  };

  const restoreDeletedForms = () => {
    restoreDeletedMedicalForms(selectedIDs);
  };

  return (
    <PageHeaderActions disableSpacing endPosition>
      {!!selectedIDs.length && (
        <>
          <SelectedItemsCount length={selectedIDs.length} total={15} />
          <VerticalDivider verticalGutters={1} horizontalGutters={1} />

          <Tooltip
            arrow
            title="Restore selected forms"
          >
            <IconButton
              color="info"
              onClick={restoreDeletedForms}
            >
              <BackupIcon />
            </IconButton>
          </Tooltip>

          <VerticalDivider verticalGutters={1} horizontalGutters={1} />

          <Tooltip
            arrow
            title="Delete selected forms"
          >
            <IconButton
              color="error"
              onClick={massDeleteForms}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>

          <VerticalDivider verticalGutters={1} horizontalGutters={1}/>
        </>
      )}

      {!isTablet ? (
        <>
          <Tooltip arrow title="List view">
            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Grid view">
            <IconButton
              edge="end"
              color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.grid)}
            >
              <ViewModuleIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <PageHeaderActions>
          {filterOpenButton}
        </PageHeaderActions>
      )}
    </PageHeaderActions>
  );
};
