import { api } from '../../api';

export const fetchPayers = (config) => {
  return api.get('/procedure-codes/payer', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchPayer = (id, config) => {
  return api.get(`/procedure-codes/payer/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createPayer = (data) => {
  return api.post('/procedure-codes/payer', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updatePayer = ({ id, ...data }) => {
  return api.put(`/procedure-codes/payer/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deletePayer = (id) => {
  return api.delete(`/procedure-codes/payer/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massActivePayer = (IDs) => {
  return api.post('/procedure-codes/payer/active', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massInactivePayer = (IDs) => {
  return api.post('/procedure-codes/payer/inactive', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchProviderIDUsed = (config) => {
  return api.get('/procedure-codes/payer/provider', config)
    .then((data) => data)
    .catch((error) => { throw error.data; });
};

export const fetchInsuranceType = (config) => {
  return api.get('/procedure-codes/payer/insurance-type', config)
    .then((data ) => data)
    .catch((error) => { throw error.data; });
};

export const massDeletePayers = (IDs) => {
  return api.delete('/procedure-codes/payer', { params: { id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
