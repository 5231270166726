import * as types from './cardsTypes';

export const extraTypes = [
  types.BILLING_PAYMENTS,
  types.OVERDUE_BILLS,
  types.EXPENSES_ANALYSIS,
  types.BILLABLE_TIME_CARD,
  types.BILLING_SUMMERY,
  types.BALANCES,
  types.BILLING_HISTORY,
  types.PAYMENT,
  types.PAYMENT_STATUSES
];

export const checkedWidgetsTypes = {
  [types.BILLING_PAYMENTS]: true,
  [types.OVERDUE_BILLS]: true,
  [types.EXPENSES_ANALYSIS]: true,
  [types.BILLABLE_TIME_CARD]: true,
  [types.BILLING_SUMMERY]: true,
  [types.BALANCES]: true,
  [types.BILLING_HISTORY]: true,
  [types.PAYMENT]: true,
  [types.PAYMENT_STATUSES]: true
};

export const cardsLayout = {
  breakpoints: { xl: 1440, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  cols: { xl: 24, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  toolbox: { xl: [], lg: [], md: [], sm: [], xs: [], xxs: [] },
  layouts: {
    xl: [
      {
        w: 8,
        h: 10,
        x: 0,
        y: 0,
        i: types.BILLING_PAYMENTS,
        minW: 8,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 8,
        y: 0,
        i: types.OVERDUE_BILLS,
        minW: 8,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 0,
        i: types.EXPENSES_ANALYSIS,
        minW: 8,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 10,
        i: types.BILLABLE_TIME_CARD,
        minW: 8,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 8,
        y: 10,
        i: types.BILLING_SUMMERY,
        minW: 8,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 10,
        i: types.BALANCES,
        minW: 8,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 20,
        i: types.BILLING_HISTORY,
        minW: 16,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 20,
        i: types.PAYMENT,
        minW: 8,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 20,
        i: types.PAYMENT_STATUSES,
        minW: 8,
        maxW: 12,
        minH: 8,
        maxH: 16,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    lg: [
      {
        w: 4,
        h: 10,
        x: 0,
        y: 0,
        i: types.BILLING_PAYMENTS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 4,
        y: 0,
        i: types.OVERDUE_BILLS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 0,
        i: types.EXPENSES_ANALYSIS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 10,
        i: types.BILLABLE_TIME_CARD,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 4,
        y: 10,
        i: types.BILLING_SUMMERY,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 10,
        i: types.BALANCES,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 20,
        i: types.BILLING_HISTORY,
        minW: 8,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 20,
        i: types.PAYMENT,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 20,
        i: types.PAYMENT_STATUSES,
        minW: 8,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    md: [
      {
        w: 5,
        h: 10,
        x: 0,
        y: 0,
        i: types.BILLING_PAYMENTS,
        minW: 5,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 5,
        y: 0,
        i: types.OVERDUE_BILLS,
        minW: 5,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 0,
        y: 10,
        i: types.EXPENSES_ANALYSIS,
        minW: 5,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 5,
        y: 10,
        i: types.BILLABLE_TIME_CARD,
        minW: 5,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 0,
        y: 20,
        i: types.BILLING_SUMMERY,
        minW: 5,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 5,
        y: 20,
        i: types.BALANCES,
        minW: 5,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 30,
        i: types.BILLING_HISTORY,
        minW: 10,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 40,
        i: types.PAYMENT,
        minW: 10,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 40,
        i: types.PAYMENT_STATUSES,
        minW: 10,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    sm: [
      {
        w: 6,
        h: 10,
        x: 0,
        y: 0,
        i: types.BILLING_PAYMENTS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 10,
        i: types.OVERDUE_BILLS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 20,
        i: types.EXPENSES_ANALYSIS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 30,
        i: types.BILLABLE_TIME_CARD,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 40,
        i: types.BILLING_SUMMERY,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 50,
        i: types.BALANCES,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 60,
        i: types.BILLING_HISTORY,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 70,
        i: types.PAYMENT,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 80,
        i: types.PAYMENT_STATUSES,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    xs: [
      {
        w: 4,
        h: 10,
        x: 0,
        y: 0,
        i: types.BILLING_PAYMENTS,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 10,
        i: types.OVERDUE_BILLS,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 20,
        i: types.EXPENSES_ANALYSIS,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 30,
        i: types.BILLABLE_TIME_CARD,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 40,
        i: types.BILLING_SUMMERY,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 50,
        i: types.BALANCES,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 60,
        i: types.BILLING_HISTORY,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 70,
        i: types.PAYMENT,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 80,
        i: types.PAYMENT_STATUSES,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    xxs: [
      {
        w: 2,
        h: 10,
        x: 0,
        y: 0,
        i: types.BILLING_PAYMENTS,
        minW: 2,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 10,
        i: types.OVERDUE_BILLS,
        minW: 2,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 20,
        i: types.EXPENSES_ANALYSIS,
        minW: 2,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 30,
        i: types.BILLABLE_TIME_CARD,
        minW: 2,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 40,
        i: types.BILLING_SUMMERY,
        minW: 2,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 50,
        i: types.BALANCES,
        minW: 2,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 60,
        i: types.BILLING_HISTORY,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 70,
        i: types.PAYMENT,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 80,
        i: types.PAYMENT_STATUSES,
        minW: 2,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ]
  }
};
