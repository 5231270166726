import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { api } from '../../../../../api';
import * as usersApi from '../../../../../api/users';
import {
  Select,
  Checkbox,
  SimpleSelect,
  TextField,
  AddressAutocomplete,
  KeyboardDatePicker,
  UnitDesignator,
  PhoneField
} from '../../../../../components/FormField';
import { gendersMap } from '../../../../../components/users';
import { subscriberOptions, subscribersMap } from './insuranceDataMaps';
import { getSubscriberInfo, setInfo } from './helpers';

export const SubscriberInfo = ({ user = null, insurance = {} }) => {
  const { values, setFieldValue } = useFormikContext();
  const [ isLoading, setIsLoading ] = useState(false);
  const cancelFetch = useRef(() => {});
  const userData = !isEmpty(user) ? user : values?.case_user_id?.user || values?.case_user_id || values?.case_user;

  const handleAddressChange = (option) => {
    if (option) {
      setFieldValue(
        'subscriber_info.address.street',
        (option.street_number ? option.street_number + ' ' : '') + option.street
      );
      setFieldValue('subscriber_info.address.country', option.country);
      setFieldValue('subscriber_info.address.state', option.state);
      setFieldValue('subscriber_info.address.city', option.city);
      setFieldValue('subscriber_info.address.zip', option.zip);
      setFieldValue('subscriber_info.address.lat', option.lat);
      setFieldValue('subscriber_info.address.lng', option.lng);
    }
  };

  const fetchUser = (id, isUpdateInfo = false, disableName = false) => {
    if (!id) {
      return getSubscriberInfo(insurance?.subscriber_info, setFieldValue);
    }

    cancelFetch.current();
    setIsLoading(true);

    return usersApi.fetchUser(id, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((userValues) => {
      setIsLoading(false);
      if (isUpdateInfo) {
        setInfo({
          subscriberInfo: values?.subscriber_info?.subscriber,
          user: userValues,
          setFieldValue,
          fetchUser
        });
      } else {
        getSubscriberInfo(userValues, setFieldValue, disableName);
      }
    }).catch(() => setIsLoading(false));
  };

  const handleChangeSubscriber= (event) => {
    const value = event.target.value;

    if (value === subscribersMap.self) {
      fetchUser(userData?.id, false);
      setFieldValue('subscriber_info.address_same_as_patient', false);
    } else {
      setFieldValue('subscriber_info', {
        subscriber: value,
        address_same_as_patient: false
      });
    }
  };

  const handleToggleAddress = (event) => {
    const checked = event.target.checked;

    if (values?.subscriber_info?.subscriber !== subscribersMap.self) {
      if (checked) {
        if (userData?.id) {
          fetchUser(userData?.id, false, true);
        } else {
          setFieldValue(
            'subscriber_info.address',
            insurance?.subscriber_info?.address || {}
          );
        }
      } else {
        setFieldValue('subscriber_info.address', {});
      }
    }
  };

  useEffect(() => {
    if (values?.case_id || values?.case_user_id) {
      if (!values?.subscriber_info?.subscriber) {
        const newValue = subscriberOptions?.find(({ value }) => value === subscribersMap.self)?.value;

        setFieldValue('subscriber_info.subscriber', newValue);
      }
    }
  }, [ values?.case_id, values?.case_user_id ]);

  return (
    <>
      <Box clone pt={3} pb={2}>
        <Typography variant="h3">Subscriber Info</Typography>
      </Box>

      <Grid container spacing={2} alignItems="flex-end">
        <Grid item sm={6} xs={12}>
          <SimpleSelect
            name="subscriber_info.subscriber"
            label="Subscriber"
            margin="dense"
            options={subscriberOptions}
            onChange={handleChangeSubscriber}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormControlLabel
            label={<Typography>Address Same as Patient</Typography>}
            control={
              <Checkbox
                disabled={values.subscriber_info?.subscriber === subscriberOptions[0].value}
                name="subscriber_info.address_same_as_patient"
                onChange={handleToggleAddress}
              />
            }
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            name="subscriber_info.first_name"
            label="FN"
            margin="dense"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            name="subscriber_info.last_name"
            label="LN"
            margin="dense"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <KeyboardDatePicker
            name="subscriber_info.dob"
            label="DOB"
            margin="dense"
            outputFormat={moment.HTML5_FMT.DATE}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Select
            isLoading={isLoading}
            formattedValue
            name="subscriber_info.gender"
            label="Gender"
            options={gendersMap}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <AddressAutocomplete
            name="subscriber_info.address.street"
            label="Street"
            margin="dense"
            onChange={handleAddressChange}
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <UnitDesignator
            required={!!values.unit_number}
            name="subscriber_info.address.designator"
            label="Unit Designator"
            margin="dense"
            placeholder="Select designator..."
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <TextField
            zeroMinWidth
            margin="dense"
            name="subscriber_info.address.unit_number"
            label="Apt./Suite/Room"
            placeholder="Enter apt/suite/room..."
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            name="subscriber_info.address.city"
            label="City"
            margin="dense"
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <TextField
            name="subscriber_info.address.state"
            label="State/Region"
            margin="dense"
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <TextField
            name="subscriber_info.address.zip"
            label="ZIP Code"
            margin="dense"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            name="subscriber_info.address.country"
            label="Country"
            margin="dense"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <PhoneField
            name="subscriber_info.phone_number"
            label="Phone Number"
            margin="dense"
          />
        </Grid>
      </Grid>
    </>
  );
};
