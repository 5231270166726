import { requiredWith, Yup } from '../../../utils/validation';

export const painValidationSchema = Yup.object().shape({
  is_low_back: Yup.boolean().nullable(),
  is_middle_back: Yup.boolean().nullable(),
  forms: Yup.object().shape({
    pain_in_neck: Yup.object().shape({
      is_neck: Yup.boolean().nullable(),
      your_dominant_hand: requiredWith(Yup.mixed().nullable(), 'is_neck'),
      where_your_pain: Yup.object().shape({
        description: Yup.string().nullable(),
        parts: Yup.object().shape({
          neck: Yup.boolean(),
          hand: Yup.boolean(),
          other: Yup.boolean(),
          shoulder: Yup.boolean(),
          arms: Yup.boolean()
        })
      }),
      how_often_your_pain: requiredWith(Yup.string().nullable(), 'is_neck'),
      what_severity_your_pain: requiredWith(Yup.string().nullable(), 'is_neck'),
      if_yes_how_often: Yup.string().nullable(),
      your_pain_radiate_other_parts: requiredWith(Yup.mixed(), 'is_neck'),
      which_side_body_pain_radiate: requiredWith(Yup.string().nullable(), 'is_neck'),
      symptoms_increase_while_twisting: requiredWith(Yup.string().nullable(), 'is_neck'),
      where_pain_radiate_too: requiredWith(Yup.object().shape({
        forearm: Yup.boolean(),
        hand: Yup.boolean(),
        fingers: Yup.boolean()
      }), 'is_neck'),
      experience_following: Yup.object().shape({
        weakness: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        muscle_spasms: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        stiffness: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        limping: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        numbness: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        tingling: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        })
      }).notRequired(),
      experience_result_injury: Yup.object().shape({
        headaches: Yup.boolean(),
        dizziness: Yup.boolean(),
        loss_of_memory: Yup.boolean(),
        difficulty_thinking: Yup.boolean()
      }).notRequired(),
      symptoms_increase_with_activity: Yup.string().notRequired()
    }, [ 'is_neck' ]),
    middle_back: Yup.object().shape({
      does_the_pain_radiate_to: requiredWith(Yup.object().shape({
        description: Yup.string().nullable(),
        parts: Yup.object().shape({
          neck: Yup.boolean(),
          hand: Yup.boolean(),
          other: Yup.boolean(),
          shoulder: Yup.boolean(),
          arms: Yup.boolean()
        })
      }), 'is_middle_back'),
      what_severity_your_pain: requiredWith(Yup.mixed().nullable(), 'is_middle_back'),
      symptoms_increase_with_activity: requiredWith(Yup.string().nullable(), 'is_middle_back'),
      symptoms_increase_while_twisting: requiredWith(
        Yup.string().nullable(), 'is_middle_back'
      ),
      how_often_do_you_experience_the_pain: requiredWith(
        Yup.string().nullable(), 'is_middle_back'
      )
    }),
    pain_in_low_back: Yup.object().shape({
      how_often_your_pain: requiredWith(Yup.string().nullable(), 'is_low_back'),
      what_severity_your_pain: requiredWith(Yup.string().nullable(), 'is_low_back'),
      pain_radiate_other_parts_body: requiredWith(Yup.mixed().nullable(), 'is_low_back'),
      which_side_pain_radiate_too: requiredWith(Yup.string().nullable(), 'is_low_back'),
      avoided_strenuous_lifting: requiredWith(Yup.mixed().nullable(), 'is_low_back'),
      if_yes_how_often: Yup.string().nullable().notRequired(),
      where_pain_radiate_too: Yup.object().shape({
        forearm: Yup.boolean(),
        hand: Yup.boolean(),
        fingers: Yup.boolean()
      }),
      experience_following: Yup.object().shape({
        weakness: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        muscle_spasms: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        stiffness: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        limping: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        numbness: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        tingling: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        })
      }),
      following_until_pain_interferes: requiredWith(Yup.object().shape({
        sit: Yup.number().nullable(),
        stand: Yup.number().nullable(),
        walk: Yup.number().nullable()
      }), 'is_low_back')
    })
  })
});
