import { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import filesize from 'filesize';
import { Button, Dialog, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { useModal } from '../../../../../components/ModalsProvider';
import { getAggregateTypeFromMIME } from '../../../../../helpers/files';
import * as filesApi from '../../../../../api/files';
import * as billingFilesApi from '../../../../../api/billing';
import { setFilesLastGlobalAction } from '../../../../../store/globalActions';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { FileDropZone } from '../../../../../components/FileDropZone';
import { FileTypeIcon, FileValidationConflictModal } from '../../../files-common';
import * as types from '../../FilesContext/types';

export const InvoiceFileUploadModal = ({
  DialogProps,
  payload: { files, owner_type, owner_id } = {},
  handleModalResolve,
  handleModalReject
}) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ selectedFiles, setSelectedFiles ] = useState([]);

  const validateFile = (file) => {
    file.original_filename = file.original_filename || file.name;

    if (selectedFiles.find(({ original_filename }) => original_filename === file.original_filename)) {
      openModal(FileValidationConflictModal, {
        payload: { file },
        onModalResolved: (file) => {
          validateFile(file);
        }
      });
    } else {
      filesApi.validateFile(file).then(() => {
        file.aggregate_type = getAggregateTypeFromMIME(file.type);

        setSelectedFiles((state) => [ file ].concat(state));
      }).catch(({ status }) => {
        if (status === 409) {
          openModal(FileValidationConflictModal, {
            payload: { file },
            onModalResolved: (file) => {
              validateFile(file);
            }
          });
        } else {
          enqueueSnackbar(`File "${file.original_filename}" is invalid`, {
            variant: 'error'
          });
        }
      });
    }
  };

  const onUploadFiles = (values, { setErrors, setSubmitting }) => {
    if (!selectedFiles.length) {
      setSubmitting(false);

      return;
    }

    selectedFiles.forEach((file) => {
      const formData = new FormData();

      formData.append('file', file, file.original_filename);

      file.requestData = formData;

      billingFilesApi.uploadFile(formData, { params: { owner_id, owner_type } })
        .then((data) => {
          dispatch(setFilesLastGlobalAction({ type: types.FILE_UPLOAD_SUCCESS, payload: data }));
          enqueueSnackbar('Files successfully uploaded', { variant: 'success' });
          handleModalResolve();
        })
        .catch(({ status, data: { errors } = {} }) => {
          setSubmitting(false);

          if (status === 422 && errors) {
            setErrors(errors);
          }
        });
    });
  };

  const handleDropAccepted = (files) => {
    files.forEach((file) => {
      validateFile(file);
    });
  };

  useEffect(() => {
    files && handleDropAccepted(files);
  }, []);

  return (
    <Dialog {...DialogProps}>
      <Formik initialValues={{}} onSubmit={onUploadFiles}>
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Upload files
            </ModalHeader>

            <ModalBody>
              <FileDropZone multiple onDropAccepted={handleDropAccepted} />

              <List>
                {selectedFiles.map((file, index) => (
                  <Fragment key={index}>
                    {index > 0 && <Divider />}

                    <ListItem>
                      <FileTypeIcon
                        file={{
                          aggregate_type: getAggregateTypeFromMIME(file.type),

                          ...file
                        }}
                      />

                      <ListItemText
                        primary={file.original_filename || file.name}
                        primaryTypographyProps={{ noWrap: true }}
                      />

                      <ListItemText primary={filesize(file.size)} />
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            </ModalBody>

            <ModalFooter>
              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Upload
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
