import { Box, Grid, Typography } from '@material-ui/core';
import { AddressLink } from '../../../../../../components/AddressLink';
import { Contact } from '../../../../../../components/Contact';
import { Divider } from '../../../../../../components/Divider';
import { ReadonlyCheckbox } from '../../../../../../components/ReadonlyCheckbox';

export const Body = ({ company }) => {
  return (
    <Box px={6}>
      <Grid container spacing={2} component={Box} minHeight={165} pt={4} pb={2} justify="space-between">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Address
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <AddressLink variant="h5" address={company.full_address}>
            {company.full_address || '-'}
          </AddressLink>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Billing Address
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <AddressLink variant="h5" address={company?.billing?.full_address}>
            {company?.billing?.full_address || '-'}
          </AddressLink>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Venue
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {!company.venue?.length ? (
            <Typography color="textSecondary">No info</Typography>
          ) : company.venue.map((item, i) => (
            <Box key={i} mb={1.5}>
              <Grid container spacing={1} wrap="nowrap">
                {(+item.is_main > 0) &&
                <Grid item>
                  <ReadonlyCheckbox checked disableTitle />
                </Grid>
                }

                <Grid item>
                  <Typography variant="h5">
                    {i + 1}.
                  </Typography>
                </Grid>

                <Grid item>
                  <AddressLink variant="h5" address={item.full_address}>
                    {item.full_address || '-'}
                  </AddressLink>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>

      <Divider disableBottomGutter />

      <Grid container spacing={2} component={Box} pt={4} pb={2} justify="space-between">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Phone
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            noWrap
            needIcon
            variant="h5"
            type="tel"
            contact={company.phone}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Fax
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            noWrap
            needIcon
            variant="h5"
            type="tel"
            contact={company.fax}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Mob number
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={company.mobile_phone}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Timezone
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {company.timezone || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Divider disableBottomGutter />

      <Grid container spacing={2} component={Box} pt={2} pb={4} justify="space-between">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Email
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="mailto"
            contact={company.email}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
