import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { preventDefault } from '../../../helpers/dom';
import { Popper } from '../../Popper';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const WidgetsPopper = ({
  checked = 0,
  total = 0,
  checkedWidgetsTypes = false,
  children
}) => {
  const classes = useStyles();

  return checkedWidgetsTypes && (
    <Popper
      placement="bottom-end"
      arrow={false}
      anchorRenderer={
        ({ anchorRef, handleToggle }) => (
          <TextField
            readOnly
            disabled
            value={`Customise ${checked + '/' + total}`}
            onClick={preventDefault(handleToggle)}
            className={classes.textField}
            ref={anchorRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropDown />
                </InputAdornment>
              ),
              classes: { input: classes.textFieldInput__inner },
              className: classes.textFieldInput
            }}
          />
        )
      }
    >
      {() => children}
    </Popper>
  );
};
