import Dropzone from 'react-dropzone';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FileDropZoneWindow = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Dropzone {...props}>
      {({ getRootProps, isDragActive }) => (
        <div
          {...getRootProps()}
          tabIndex={null}
          onClick={null}
          className={cn(classes.uploadTrigger, { [classes.uploadTrigger_active]: isDragActive })}
        >
          <div className={classes.childrenWrapper}>
            {children}
          </div>

          {isDragActive &&
            <div className={classes.backdrop}>
              <div className={classes.backdrop__iconContainer}>
                <CloudUploadIcon className={classes.backdrop__icon} />
              </div>
            </div>
          }
        </div>
      )}
    </Dropzone>
  );
};
