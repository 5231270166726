import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_FORMS_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_FORMS_SUCCESS]: (
    { filter, forms, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      forms: data,
      form: {}
    };
  },

  [types.FETCH_UNCOMFIRMED_FORMS_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_UNCOMFIRMED_FORMS_SUCCESS]: (
    { filter, forms, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      forms: data,
      form: {}
    };
  },

  [types.ADD_FORM]: (state, payload) => {
    const { pagination, filter, forms, ...otherState } = state;
    const total = pagination.total + 1;
    const last_page = Math.ceil(total / filter.per_page);
    const page = forms.length + 1 > filter.per_page ? filter.page + 1 : filter.page;

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      forms: [ ...forms, payload ]
    };
  },

  [types.UPDATE_FORM]: (state, payload) => {
    return {
      ...state,

      forms: state.forms.map((item) => {
        return item.id === payload.id ? { ...item, ...payload } : item;
      })
    };
  },

  [types.RESET_FORMS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.DELETE_FORM]: ({ forms, pagination, filter, ...state }, { id }) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter,
      forms: forms.filter((item) => item.id !== id)
    };
  },

  [types.TOGGLE_ALL_FORMS_SELECTION]: ({ forms, selectedIDs, ...state }) => {
    const allFormsIsSelected = forms.length === selectedIDs.length;

    return {
      ...state,

      forms,
      selectedIDs: allFormsIsSelected ? [] : forms.map((item) => item.id)
    };
  },

  [types.TOGGLE_FORM_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1 ?
        selectedIDs.filter((item) => item !== id)
        :
        selectedIDs.concat(id)
    };
  },

  [types.MASS_DELETE_FORMS]: ({ forms, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      forms: forms.filter((form) => IDs.indexOf(form.id) < 0)
    };
  },

  [types.FETCH_FORM_REQUEST]: (state) => {
    return {
      ...state,
      isFormFetched: false,
      form: {}
    };
  },

  [types.FETCH_FORM_SUCCESS]: ({ form, ...state }, payload) => {
    return {
      ...state,

      isFormFetched: true,
      form: payload
    };
  }
});
