import moment from 'moment';
import { IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { ReadonlyCheckbox } from '../../../../../../../../components/ReadonlyCheckbox';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { Label } from '../../../../../../../../components/Label';
import {
  PreviewInvoiceModal
} from '../../../../../../CasesPage/Cases/CaseItem/TabsContent/Billing/PreviewInvoiceModal';
import { statusTypesColors, statusTypesNames } from '../../../../../Invoices/InvoicesContext/statusTypes';
import {
  mobileColumnWidths,
  columnsWidths,
  minRowHeight,
  widthBreakpointMedium,
  widthBreakpointSmall,
  widthBreakpointXS
} from '../../../../../Invoices/InvoicesPage/InvoicesContent/List/listConfig';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ invoice = {}, width }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { openModal } = useModal();
  const defaultColumnWidths = isMobile ? mobileColumnWidths : columnsWidths;

  const openInvoiceViewModal = () => {
    openModal(PreviewInvoiceModal, {
      payload: {
        invoiceID: invoice.id,
        disableAction: true,
        disablePayment: true
      }
    });
  };

  return (
    <ListRow
      button
      minHeight={minRowHeight}
      px={isMobile ? 0 : 2}
      onClick={stopPropagation(openInvoiceViewModal)}
    >
      <ListRowCell grow noWrap flexBasis={defaultColumnWidths.name}>
        <ReadonlyCheckbox title={invoice?.number} checked={invoice?.is_confirmed} />
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell grow flexBasis={defaultColumnWidths.billFor}>
          <WorkIcon className={classes.invoiceIcon} />

          <Typography
            noWrap
            color={invoice?.number ? 'inherit' : 'textSecondary'}
            title={invoice?.number}
          >
            {invoice?.number || 'No info.'}
          </Typography>
        </ListRowCell>
      }

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={defaultColumnWidths.dueDate}>
          {moment(invoice?.due_date).format('L')}
        </ListRowCell>
      }

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={defaultColumnWidths.amount}>
          <CurrencyFormat value={invoice?.total} />
        </ListRowCell>
      }

      <ListRowCell flexBasis={defaultColumnWidths.balance}>
        <Typography noWrap color="error" title={invoice?.balance}>
          <CurrencyFormat value={invoice?.balance} />
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={defaultColumnWidths.status}>
        {invoice?.status &&
          <Label
            className={classes.statusLabel}
            label={statusTypesNames[invoice?.status.name
              ? invoice.status.name.toLowerCase()
              : invoice?.status.toLowerCase()
            ]}
            color={statusTypesColors[invoice?.status.name
              ? invoice.status.name.toLowerCase()
              : invoice?.status.toLowerCase()
            ]}
          />
        }
      </ListRowCell>

      <ListRowCell flexBasis={defaultColumnWidths.action}>
        <IconButton onClick={stopPropagation(openInvoiceViewModal)}>
          <EyeIcon color="primary" />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
