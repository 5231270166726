import { useContext } from 'react';
import { Paper } from '@material-ui/core';
import { Checkbox } from '../../../../../../../../../../components/FormField/Checkbox';
import { ListRow, ListRowCell } from '../../../../../../../../../../components/ListRow';
import { AppointmentBooksContext } from '../../../../../../AppointmentBooks/AppointmentBooksContext';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  const { selectedBooksIDs, ...appointmentBooksContext } = useContext(AppointmentBooksContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell>
          <Checkbox
            withoutFormik
            checked={appointmentBooksContext.allBooksIsSelected()}
            indeterminate={!!selectedBooksIDs.length && !appointmentBooksContext.allBooksIsSelected()}
            onChange={appointmentBooksContext.toggleAllBooksSelected}
          />
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.name}>
          Name
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.location}>
          Location
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
