import { Copyright } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { Box, List } from '@material-ui/core';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { UserLink } from '../../../../../components/UserLink';
import { Divider } from '../../../../../components/Divider';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { casesMenuList, clientMenuList, emailsRoute, clientMenuListSm } from './menuLists';
import { LogoLink } from './LogoLink';
import { MenuItems } from './MenuItems';

export const PatientSidebarContent = ({ sidebarIsOpen }) => {
  const { user } = useSelector(({ profile }) => profile);

  return (
    <>
      <LogoLink isClosableByButton sidebarIsOpen={sidebarIsOpen} />

      <Box px={2} pt={2} pb={1} color="common.white">
        <UserLink disableLink variant="h5" user={user} color="inherit" />
      </Box>

      <Scrollbars>
        <List component={Box} display={{ xs: 'none', md: 'block' }}>
          <MenuItems isCollapsed={!sidebarIsOpen} routes={clientMenuList} />

          {hasRole(rolesMap.patient) &&
            <MenuItems isCollapsed={!sidebarIsOpen} routes={emailsRoute} />
          }

          <Box px={2}>
            <Divider gutter={2} />
          </Box>

          <MenuItems isCollapsed={!sidebarIsOpen} routes={casesMenuList} />
        </List>

        <List component={Box} display={{ xs: 'block', md: 'none' }}>
          <MenuItems isCollapsed={!sidebarIsOpen} routes={clientMenuListSm} />
        </List>
      </Scrollbars>

      {sidebarIsOpen &&
        <Copyright />
      }
    </>
  );
};
