import { createContext, useRef, useEffect, useReducer } from 'react';
import { omit, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useMemoEffect, usePrevious } from '../../../../../../../helpers/hooks';
import { api } from '../../../../../../../api';
import * as filesApi from '../../../../../../../api/files';
import * as chatApi from '../../../../../../../api/chat';
import { tagsActionsMap } from '../../../../../../../api/files/tags';
import { setFilesLastGlobalAction } from '../../../../../../../store/globalActions';
import { jobsTypes, addSocketJobID } from '../../../../../../../store/socketJobs';
import { deleteFilesSuccess } from '../../../../../../../store/dashboard/files/recentlyOpenedFiles';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { SendFaxModal } from '../../../../../FaxPage/ActionsBar/SendFaxModal';
import {
  ChangeFilesTagsModal,
  ShareByEmailModal,
  ShareFileModal,
  CopyFileModal,
  MoveFileModal,
  RenameFileModal,
  EditDocumentModal
} from '../../../../../files-common';
import * as tagsActionsTypes from '../../../../../files-common/TagsContext/types';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as filesActionTypes from './types';
import * as types from './types';

export const ThreadFilesContext = createContext();

export const ThreadFilesProvider = ({ threadId, children }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const reduxDispatch = useDispatch();
  const currentUser = useSelector(({ profile: { user } }) => user);
  const lastUploadedFile = useSelector(({ uploads: { lastUploadedFile } }) => lastUploadedFile);
  const { filesLastGlobalAction, tagsLastGlobalAction } = useSelector(({ globalActions }) => globalActions);
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { isFetched, isFetching, filter, pagination } = state;
  const files = useRef(state.files);
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchFiles = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FILES_REQUEST });

    chatApi.fetchThreadFiles(threadId, {
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_FILES_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFiles({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetFiles = (newFilter) => {
    dispatch({ type: types.RESET_FILES, payload: newFilter });
    fetchFiles({ page: 1, ...newFilter });
  };

  const deleteFiles = (filesIDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        filesApi.deleteFiles(filesIDs).then(() => {
          reduxDispatch(setFilesLastGlobalAction({ type: types.DELETE_FILES, payload: filesIDs }));
          reduxDispatch(deleteFilesSuccess(filesIDs));
          enqueueSnackbar(`${filesIDs.length} file successfully deleted`);
        });
      }
    });
  };

  const deleteFilesSharedWithMe = (filesIDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        filesApi.preventShareFiles({ params: { filesIDs, users: [ currentUser.id ] } }).then(() => {
          reduxDispatch(setFilesLastGlobalAction({ type: types.DELETE_FILES, payload: filesIDs }));
          reduxDispatch(deleteFilesSuccess(filesIDs));
          enqueueSnackbar(`${files.length} file successfully deleted`);
        });
      }
    });
  };

  const renameFile = (file) => {
    openModal(RenameFileModal, { payload: { file } });
  };

  const shareFiles = ({ filesIDs, users }) => {
    openModal(ShareFileModal, { payload: { filesIDs, users } });
  };

  const changeFilesTags = (payload) => {
    dispatch({ type: filesActionTypes.CHANGE_FILES_TAGS, payload });
  };

  const shareByEmail = (filesIDs) => {
    openModal(ShareByEmailModal, {
      onModalResolved: (data) => {
        filesApi.shareByEmail({
          share_tag_id: data?.data?.additional_data?.tag?.id,
          files: filesIDs
        }).then(() => {
          changeFilesTags({
            filesIDs,
            tags: [ data?.data?.additional_data?.tag ],
            actionType: tagsActionsMap.add
          });

          enqueueSnackbar(`File${filesIDs.length > 1 ? 's' : ''} successfully shared`, { variant: 'success' });
        });
      }
    });
  };

  const preventShareByEmail = ({ file, tag }) => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Revoke access for this file?'
      },

      onModalResolved: () => {
        filesApi.preventShareByEmail({
          params: {
            share_tag_id: tag.id,
            files: [ file.id ]
          }
        }).then(() => {
          changeFilesTags({ filesIDs: [ file.id ], tags: [ tag ], actionType: tagsActionsMap.delete });

          enqueueSnackbar('Access for this email has been revoked', { variant: 'success' });
        });
      }
    });
  };

  const sendByFax = (fileID) => {
    openModal(SendFaxModal, {
      payload: {
        initialValues: {
          file_id: fileID
        }
      }
    });
  };

  const copyFile = (file) => {
    openModal(CopyFileModal, { payload: { file } });
  };

  const moveFile = (file) => {
    openModal(MoveFileModal, { payload: { file } });
  };

  const editDocument = (documentID) => {
    openModal(EditDocumentModal, { payload: { documentID } });
  };

  const openChangeFilesTagsModal = (filesIDs) => {
    openModal(ChangeFilesTagsModal, { payload: { filesIDs } });
  };

  const downloadFiles = (filesIDs) => {
    filesApi.createArchive({ files: filesIDs }).then(({ job_id }) => {
      reduxDispatch(addSocketJobID({ type: jobsTypes.archives, jobID: job_id }));
    });
  };

  const addFiles = (files) => {
    dispatch({ type: types.ADD_FILES, payload: files });
  };

  const providerValue = {
    isFetched,
    isFetching,
    files: state.files,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    applyFilter,
    resetFiles,
    fetchFiles,
    loadNextPage,
    deleteFiles,
    deleteFilesSharedWithMe,
    downloadFiles,
    renameFile,
    shareFiles,
    shareByEmail,
    preventShareByEmail,
    copyFile,
    moveFile,
    editDocument,
    addFiles,
    openChangeFilesTagsModal,
    changeFilesTags,
    sendByFax
  };

  useEffect(() => {
    files.current = state.files;
  }, [ state.files ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    lastUploadedFile && addFiles([ lastUploadedFile ]);
  }, [ lastUploadedFile ]);

  useEffect(() => {
    if (tagsLastGlobalAction && tagsLastGlobalAction.type === tagsActionsTypes.UPDATE_TAG) {
      dispatch({ type: types.UPDATE_TAG, payload: tagsLastGlobalAction });
    }
  }, [ tagsLastGlobalAction ]);

  useEffect(() => {
    filesLastGlobalAction && dispatch(filesLastGlobalAction);
  }, [ filesLastGlobalAction ]);

  useMemoEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetFiles(newFilter);
    }
  }, [ prevFilter, omit(filter, [ 'page' ]) ]);

  return (
    <ThreadFilesContext.Provider value={providerValue}>
      {children}
    </ThreadFilesContext.Provider>
  );
};
