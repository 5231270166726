import { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { FormStepper } from '../../ProfilePage/MedicalInfo/MedicalForms/FormStepper';
import { styles } from '../../ProfilePage/MedicalInfo/MedicalForms/styles';
import { FormContext, stepsTitle } from '../FormProvider';
import { injurySteps } from '../FormProvider';
import { getFormRoutes } from '../FormProvider/helpers';
import { FormHeader } from '../../../../components/medical/forms';
import { getParams } from '../getParams';
import { formStepComponents } from './formStepComponents';

const useStyles = makeStyles(styles);

export const PersonalInjury = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams();
  const {
    fetchForm,
    form,
    type,
    initialFormValue,
    completed,
    activeStep,
    setType,
    setActiveStep,
    ...props
  } = useContext(FormContext);
  const Component = formStepComponents[activeStep];

  useEffect(() => {
    if (!params.medicalFormID) {
      fetchForm({ id: null });
      setType(getParams(location));
    }
  }, [ location?.search ]);

  return (
    <div className={classes.formRoot}>
      <FormHeader
        form={form}
        type={type}
        activeStep={activeStep}
        initialFormValue={initialFormValue}
      />

      <FormStepper
        steps={injurySteps}
        form={form}
        stepsTitle={stepsTitle}
        completed={completed}
        activeStep={activeStep}
        onActiveStepChange={setActiveStep}
        getFormRoutes={getFormRoutes}
        component={<Component /> }

        { ...props }
      />
    </div>
  );
};
