import { useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Tooltip, useTheme, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { setCasesLastGlobalAction } from '../../../../../../../store/globalActions';
import { useResizeObserver } from '../../../../../../../helpers/hooks';
import * as taskActionTypes from '../../../../../../../components/tasks/TasksProvider/types';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { ReplyModal } from '../../../../../../../components/Comments/ReplyModal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { Fab } from '../../../../../../../components/Fab';
import {
  Comments as WidgetComments,
  CommentsContext
} from '../../../../../../../components/Comments';

export const CommentsBody = ({ caseItem, widgetProps, ...props }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { createComment } = useContext(CommentsContext);
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });

  const onCommentAdded = (comments) => {
    dispatch(setCasesLastGlobalAction({
      type: taskActionTypes.UPDATE_TASK_IN_LIST,
      payload: { ...caseItem, comments_count: comments.length }
    }));
  };

  const handleCancel = (callback) => () => {
    callback();
  };

  const replayComment = (values, { setSubmitting, resetForm, setErrors }) => {
    return createComment(values).then(() => {
      resetForm();
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        setSubmitting(false);
        errors && setErrors(errors);
      }
    });
  };

  const addComment = () => {
    openModal(ReplyModal, {
      payload: {
        parentType: 'case',
        parentCommentID: caseItem.id,
        replayComment,
        onCancel: handleCancel
      }
    });
  };

  return (
    <ContentCard
      disableContentPaddings
      ref={rootRef}
      title="Case Notes"
      leftActions={[
        <QuestionAnswerOutlinedIcon color="inherit" fontSize="small" />
      ]}
      icon={(
        <Tooltip title="Add Note">
          <Fab
            color="primary"
            size={isMobile ? 'small' : 'medium'}
            onClick={addComment}
          >
            <AddIcon fontSize="small" />
          </Fab>
        </Tooltip>
      )}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          type={props?.item?.i}
          {...widgetProps}
        />
      ]}
      {...props}
    >
      <Box padding={1}>
        <WidgetComments
          disableForm
          width={width}
          parentType="case"
          parentID={caseItem.id}
          parentPage="widget"
          onCommentAdded={onCommentAdded}
        />
      </Box>
    </ContentCard>
  );
};
