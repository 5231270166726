import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import { IconButton } from '../IconButton';
import { SettingsComponent } from './SettingsComponent';

export const ActionButtons = ({
  isResetForm,
  enableSettings,
  filterKey,
  visibleFields,
  onFieldRemoved,
  handleToggleFilters,
  isAllFiltersShown,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <>
      {isResetForm && props.dirty &&
        <Grid item>
          <Box mt={isMobile ? 0 : 1}>
            <IconButton
              edge="end"
              color="primary"
              onClick={props.handleReset}
            >
              <BackupIcon />
            </IconButton>
          </Box>
        </Grid>
      }

      {enableSettings &&
        <Grid item>
          <Box mt={isMobile ? 0 : 1}>
            <SettingsComponent
              filterKey={filterKey}
              fieldsList={visibleFields}
              onFieldHidden={onFieldRemoved}
            />
          </Box>
        </Grid>
      }
    </>
  );
};
