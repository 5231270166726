import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ReceiveOverdraft } from './PaymentsPage/Overdraft/ReceiveOverdraft';
import { PaymentsPage } from './PaymentsPage';
import { CreatePayment } from './CreatePayment';
import { EditPayment } from './EditPayment';
import { PaymentItem } from './PaymentItem';

export const Payments = () => {
  const theme = useTheme();
  const history = useHistory();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  if (!isTablet && history?.location?.pathname === '/billing/payments/charts') {
    return (
      <Redirect to="/billing/payments/received" />
    );
  }

  return (
    <Switch>
      <Route exact path="/billing/payments">
        <Redirect to="/billing/payments/received" />
      </Route>

      <Route exact path="/billing/payments/received" component={PaymentsPage} />
      <Route exact path="/billing/payments/overdraft" component={PaymentsPage} />
      <Route exact path="/billing/payments/charts" component={PaymentsPage} />
      <Route exact path="/billing/payments/create" component={CreatePayment} />
      <Route exact path="/billing/payments/edit/:id" component={EditPayment} />
      <Route exact path="/billing/payments/assign/:id" component={ReceiveOverdraft} />
      <Route path="/billing/payments/:id" component={PaymentItem} />
    </Switch>
  );
};
