import { useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import { Box, makeStyles, Typography } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { Loader } from '../../../../../../components/Loader';
import { Tag } from '../../../../../../components/Tag';
import { FileTypeIcon, FilePreviewModal, FileOwner } from '../../../../files-common';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  item: { media: file = {}, text, highlight } = {},
  isLoaded,
  recalculateHeight
}) => {
  const classes = useStyles();
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        singleView: true
      }}
    />
  ), [ file ]);

  useEffect(() => {
    recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={2} /> : (
    <div className={classes.root}>
      <div
        className={classes.preview}
        onClick={openFilesPreview}
      >
        {file?.previews?.['280'] || file.aggregate_type === 'vector' ? (
          <img
            src={file?.previews?.['280'] || file.url}
            alt={file.extension}
            className={classes.preview__image}
          />
        ) : (
          <FileTypeIcon file={file} className={classes.preview__icon}/>
        )}

        {!file.opened_at &&
          <div className={classes.viewingIndicator}>
            <EyeIcon className={classes.viewingIndicator__icon} />
          </div>
        }

        <div
          title={file.opened_at ? '' : 'File not viewed'}
          className={classes.preview__backdrop}
        >
          <EyeIcon/>
        </div>
      </div>

      <div className={classes.mainInfo}>
        <div className={classes.header}>
          <FileTypeIcon file={file} className={classes.header__icon} />

          <Typography className={classes.header__title}>
            {file.original_filename}
          </Typography>
        </div>

        <Typography
          className={classes.documentContent}
          dangerouslySetInnerHTML={{ __html: (
            highlight?.['text.english'] || highlight?.['text'] || text
          ) }}
        />

        <FileOwner owner_type={file.owner_type} owner={file.owner} />

        {file.tags &&
          <Box display="flex" flexWrap="wrap" mt={1}>
            {file.tags.map((tag) => (
              <Box key={tag.id} m={0.25}>
                <Tag tag={tag} />
              </Box>
            ))}
          </Box>
        }
      </div>
    </div>
  );
};
