export const styles = ({ transitions }) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column'
  },

  filesWindow: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden'
  },

  filesWindow__main: {
    display: 'flex',
    flexGrow: 1,
    width: '100%'
  },

  filesWindow__sidebar: {
    width: 0,
    minWidth: 0,
    height: '100%',
    overflow: 'hidden',
    transition: transitions.create()
  },

  filesWindow__sidebar_active: {
    minWidth: 294
  }
});
