import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import * as expensesApi from '../../../api/billing';
import { InvoicesSelect } from '../../FormField';
import { ExpensesSelect } from '../../FormField/ExpensesSelect';
import { Loader } from '../../Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../Modal';
import { validationSchema } from './validationSchema';

export const AssignInvoiceModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    disableInvoice = false,
    title = 'Attach to Invoice',
    expense_id = null,
    invoice_id = null
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = ({ disableInvoice, ...values }, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (disableInvoice) {
      handleModalResolve(values.expense_id);

      return;
    }

    return expensesApi.massAssignExpense({
      ...values,
      expense_ids: [ values.expense_id?.id || values.expense_id ]
    }).then(() => {
      handleModalResolve(values.expense_id);
    }).catch((errors) => {
      if (errors) {
        if (errors?.status === 403 && errors?.data?.code === 'expense.unable_assign') {
          enqueueSnackbar('Expense Status must be Approved', { variant: 'error' });
        } else {
          setErrors(errors);
          enqueueSnackbar('Expense not attached', { variant: 'error' });
        }
      }
    });
  };

  return (
    <Formik
      initialValues={{
        disableInvoice,
        expense_id,
        invoice_id
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<PlaylistAddIcon />} onClose={handleModalReject}>
              {title}
            </ModalHeader>

            <ModalBody>
              {!disableInvoice && expense_id ? (
                <InvoicesSelect
                  required
                  label="Invoice"
                  name="invoice_id"
                />
              ) : (
                <ExpensesSelect
                  required
                  label="Expense"
                  name="expense_id"
                />
              )}
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
