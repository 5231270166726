import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import * as formsApi from '../../../../../api/profile';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { AppointmentsSelect } from '../../../../appointments/AppointmentsSelect';
import { Loader } from '../../../../Loader';
import {
  MedicalFormsSelect,
  OfficesLocationSelect,
  Select
} from '../../../../FormField';
import { UsersSelect } from '../../../../users';
import { reportTypesMap, reportTypesOptions } from './reportTypesOptions';

export const Body = ({ isPatientExist }) => {
  const { values, setFieldValue } = useFormikContext();
  const [ office, setOffice ] = useState(null);
  const formsFilter = values.type === reportTypesMap.exist ? {
    exclude_follow_up: 0,
    form_type: 'Follow Up'
  } : {};

  const handleTypeChange = (option) => {
    if (option?.value === reportTypesMap.exist) {
      setFieldValue('template_id', null);
    }

    setFieldValue('office_id', null);
    setFieldValue('provider_id', null);
    setFieldValue('follow_up_id', null);
    setFieldValue('appointment_id', null);
    setFieldValue('medical_form_id', null);
  };

  const handleUserChange = (name = null) => (user) => {
    if (name) {
      setFieldValue('office_id', null);
      setFieldValue('appointment_id', null);
    } else {
      setFieldValue('medical_id', user?.medical_id || null);
      setFieldValue('medical_form_id', null);
    }
  };

  const handleChangeMedicalForm = (option) => {
    if (option?.data?.id) {
      setFieldValue('isLoading', true);
      setFieldValue('form_type', null);
      setFieldValue('provider_id', null);
      setFieldValue('attorney_id', null);
      setFieldValue('appointment_id', null);
      setFieldValue('office_id', null);

      formsApi.fetchMedicalForm(option?.data?.id).then((data) => {
        const provider = option?.data?.treating_physician || data?.treating_physician
          || data?.appointment?.appointment_book?.provider;

        setFieldValue('isLoading', false);
        setFieldValue('form_type', data?.form_type);
        setFieldValue('provider_id', provider);
        setFieldValue('attorney_id', data?.attorney);
        setFieldValue('appointment_id', data?.appointment);
        setFieldValue('office_id', data?.appointment?.office);
      });
    }
  };

  useEffect(() => {
    if (!values.medical_form_id) {
      setFieldValue('attorney_id', null);
      setFieldValue('office_id', null);
      setFieldValue('appointment_id', null);
      setFieldValue('provider_id', null);
    }
  }, [ values.medical_form_id ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UsersSelect
          required
          disabled={isPatientExist}
          name="patient_id"
          params={{ is_patient: 1 }}
          margin="dense"
          onChange={handleUserChange()}
        />
      </Grid>

      <Grid item xs={12}>
        <Select
          formattedValue
          isDisabled={!values.patient_id}
          name="type"
          label="Сhoice of report form"
          options={reportTypesOptions}
          TextFieldProps={{ margin: 'dense', required: true }}
          onChange={handleTypeChange}
        />
      </Grid>

      <Grid item xs={12}>
        <MedicalFormsSelect
          TextFieldProps={{ required: true }}
          cacheUniq={(values?.type || 0) + (values.patient_id || 0)}
          params={{
            medical_id: values?.medical_id,
            is_used: 0,

            ...formsFilter
          }}
          onChange={handleChangeMedicalForm}
        />
      </Grid>

      <Loader
        fullWidth
        minHeight={125}
        loading={values.isLoading}
        render={() => (
          <>
            <Grid item sm={6} xs={12}>
              <UsersSelect
                required
                params={{ role: 'doctor' }}
                name="provider_id"
                label="Provider"
                onChange={handleUserChange('provider_id')}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <OfficesLocationSelect
                name="office_id"
                params={{ users: values.provider_id ? [ values.provider_id ] : null }}
                onChange={setOffice}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <AppointmentsSelect
                name="appointment_id"
                params={{
                  patients: [ values.patient_id ],
                  is_used: 0,
                  medical_form_id: values.medical_form_id
                }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <UsersSelect
                isCreatable
                name="attorney_id"
                label="Attorney"
                params={{ role: rolesMap.advocate }}
                creatablePayload={{
                  role: rolesMap.advocate,
                  company: office?.company,
                  office: office ? [ office ] : null
                }}
              />
            </Grid>
          </>
        )}
      />
    </Grid>
  );
};
