import { useEffect, useContext, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../../../components/Loader';
import { Scrollbars } from '../../../../../../../../../components/Scrollbars';
import { Sticky } from '../../../../../../../../../components/Sticky';
import { AppointmentBooksContext } from '../../../../../AppointmentBooks/AppointmentBooksContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidths = {
  name: 280,
  location: 300
};

export const List = () => {
  const {
    appointmentBooks,
    isFetching,
    isFetched,
    filter,
    loadNextPage,
    resetAppointmentBooks
  } = useContext(AppointmentBooksContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetAppointmentBooks();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No appointment books found</Typography>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={appointmentBooks}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
