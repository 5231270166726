import { Fragment, useEffect } from 'react';
import cn from 'classnames';
import { makeStyles, Box, Link, Typography } from '@material-ui/core';
import { isEmptyDeep } from '../../../../../../helpers/isEmptyDeep';
import { CaseLink } from '../../../../../../components/cases/CaseLink';
import {
  getActionMessages,
  activityIconsMap,
  activityTypesMap,
  activityMap
} from '../../../../../../components/Activities';
import { UserPreviewModal } from '../../../../../../components/users';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { Loader } from '../../../../../../components/Loader';
import { Date } from '../../../../../../components/Date';
import { TaskModal } from '../../../../TasksPage/TaskModal';
import { minRowHeight } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  item: activity = {},
  isLoaded,
  isScrolling = false,
  recalculateHeight = () => {}
}) => {
  const { openModal } = useModal();
  const classes = useStyles({ minHeight: minRowHeight });
  const actions = isLoaded && getActionMessages(activity);
  const activityType = activity?.log_data?.type;
  const parent = activity?.log_data?.payload?.task || activity?.log_data?.payload?.parent;
  const caseParent = activity?.log_data?.payload?.case;
  // TODO: update activityIconsMap according to new field from BE log_name
  const Icon = activityIconsMap?.[activityType]?.icon
    || activityIconsMap?.[activity?.log_name]?.icon
    || activityIconsMap?.default?.icon;
  const className = activityIconsMap?.[activityType]?.className
    || activityIconsMap?.[activity?.log_name]?.className
    || activityIconsMap?.default?.className;

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  const openTaskPreview = () => {
    if (parent.id) {
      openModal(TaskModal, {
        payload: { id: parent.id }
      });
    }
  };

  useEffect(() => {
    isLoaded && recalculateHeight?.();
  }, [ isLoaded, actions?.length, isScrolling ]);

  return !isLoaded ? <Loader /> : !!actions?.length && (
    <div className={classes.root}>
      <div className={classes.iconContent}>
        <Icon className={cn(classes.activityIcon, classes?.[className])}/>
        <div className={classes.divider}/>
      </div>

      <div className={classes.mainContent}>
        <Box display="flex" alignItems="center">
          <UserLink
            size="md"
            color="primary"
            variant="h5"
            user={activity?.causer}
            childrenProps={{
              mt: -0.5,
              pb: 0.5,
              mr: 2
            }}
            onClick={openUserPreview(activity?.causer?.id)}
          />

          {caseParent?.id && (
            <Box display="flex" alignItems="center" ml={1} fontSize={14}>
              <Typography>case</Typography>
              &nbsp;
              <CaseLink variant="h5" caseItem={caseParent} />
            </Box>
          )}

          {activityMap?.[activityType] === activityTypesMap.task && parent?.id &&
            <Box ml={1} fontSize={14}>
              task &nbsp;
              <Link variant="h5" onClick={openTaskPreview}>
                {parent?.name}
              </Link>
            </Box>
          }
        </Box>

        <Box pl={4.5}>
          <Date
            disableIcon
            variant="body2"
            color="textSecondary"
            date={activity.created_at}
            format="Do MMM YY, h:mm a"
          />
        </Box>

        <Box fontSize={14} pl={4.5}>
          {actions?.map((action, index) => (
            <Fragment key={index}>
              {!!action?.props?.activityLabel && (
                <Box my={0.5}>
                  <Typography variant="h5" color="secondary">{action?.props?.activityLabel}</Typography>
                </Box>
              )}

              <Box display="flex" alignItems="center" mr={1} flexWrap="wrap">
                {action}
                {index !== actions.length - 1 && !isEmptyDeep(action?.props) && ';'}
              </Box>
            </Fragment>
          ))}
        </Box>
      </div>
    </div>
  );
};
