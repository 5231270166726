import { useContext, useEffect } from 'react';
import filesize from 'filesize';
import { makeStyles, IconButton, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useModal } from 'react-modal-hook';
import { Date } from '../../../../../../../../components/Date';
import { Popper } from '../../../../../../../../components/Popper';
import { Loader } from '../../../../../../../../components/Loader';
import { FilePreviewIcon, FileOwner, FileMenu, FilePreviewModal } from '../../../../../../files-common';
import { getFileMenuOptions } from '../../getFileMenuOptions';
import { ThreadFilesContext } from '../../ThreadFilesProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item: file = {}, isLoaded, recalculateHeight }) => {
  const classes = useStyles();
  const threadFilesContext = useContext(ThreadFilesContext);
  const menuList = getFileMenuOptions(threadFilesContext)(file);
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      getFileMenuOptions={getFileMenuOptions(threadFilesContext)}
      previewMenuOptions={getFileMenuOptions(threadFilesContext)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        files: threadFilesContext.files,
        isFetched: threadFilesContext.isFetched,
        isFetching: threadFilesContext.isFetching,
        filter: threadFilesContext.filter,
        onNeedNextPage: threadFilesContext.loadNextPage
      }}
    />
  ), [ file, threadFilesContext ]);

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, file.tags ]);

  return (
    <div className={classes.root}>
      {!isLoaded ? <Loader /> :
        <>
          <div className={classes.preview}>
            <FilePreviewIcon
              file={file}
              className={classes.preview__picture}
              onClick={openFilesPreview}
            />

            <Typography className={classes.preview__fileSize}>
              {filesize(file.size)}
            </Typography>
          </div>

          <div className={classes.mainInfo}>
            <Typography noWrap className={classes.mainInfo__name} title={file.original_filename}>
              {file.original_filename || file.file_name}
            </Typography>

            <Date disableIcon component="span" date={file.created_at} color="textSecondary" />

            {file.owner_type && file.owner &&
              <FileOwner owner_type={file.owner_type} owner={file.owner}/>
            }
          </div>

          {!!menuList.length &&
            <Popper
              placement="left-start"
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <IconButton buttonRef={anchorRef} onClick={handleToggle}>
                    <MoreVertIcon/>
                  </IconButton>
                )
              }
            >
              {({ handleClose }) => (
                <FileMenu
                  file={file}
                  menuList={menuList}
                  onClose={handleClose}
                />
              )}
            </Popper>
          }
        </>
      }
    </div>
  );
};
