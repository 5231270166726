import { useContext, Fragment } from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { getAgeByDOB } from '../../../../../../../helpers/users';
import { AppointmentsContext, AppointmentViewModal } from '../../../../../../../components/appointments';
import { MenuList } from '../../../../../../../components/appointments/Menu/MenuList';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { CardItem, CardHeader } from '../../../../../../../components/Cards';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { OfficeLink } from '../../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../../components/UserLink';
import { SvgIcon } from '../../../../../../../components/SvgIcon';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { ApptHistoryCardHeader } from './ApptHistoryCardHeader';
import { Body } from './Body';

export const Card = ({ item: appointment = {}, apptHistory = false }) => {
  const { openModal } = useModal();
  const { reloadAppointments } = useContext(AppointmentsContext);
  const Wrapper = apptHistory ? Box : Fragment;
  const wrapperProps = apptHistory ? {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between'
  } : {};

  const openPreview = () => {
    if (apptHistory) return;

    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: appointment.id,
        onAppointmentUpdate: reloadAppointments,
        onAppointmentDelete: reloadAppointments
      }
    });
  };

  return (
    <CardItem py={1} px={2} onClick={stopPropagation(openPreview)}>
      <CardHeader
        disableCheckBox
        childrenWidth={11}
        disableCardMenu={apptHistory}
        menuComponent={MenuList}
        menuComponentProps={{
          appointment,
          onAppointmentUpdate: reloadAppointments,
          onAppointmentDelete: reloadAppointments
        }}
      >
        {apptHistory ? <ApptHistoryCardHeader appointment={appointment} /> : (
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <UserLink
                size="lg"
                variant="h5"
                user={appointment?.patient}
              >
                <Typography color="textSecondary">
                  Age {getAgeByDOB(moment(appointment?.patient?.birthday))} y.o.
                </Typography>
              </UserLink>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="h4">
                <CurrencyFormat value={appointment?.billing_info?.total_balance} />
              </Typography>
              <Typography variant="caption" color="textSecondary">Balance</Typography>
            </Grid>
          </Grid>
        )}
      </CardHeader>

      <Wrapper {...wrapperProps}>
        <Body apptHistory={apptHistory} appointment={appointment} />

        <Box py={1}>
          <UserLink
            size="lg"
            variant="h5"
            user={appointment?.appointment_book?.provider}
          >
            <Box display="flex" flexDirection="column">
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <OfficeLink office={appointment.office} variant="caption" />
                </Grid>

                {!!appointment.appointment_confirmed_by_office &&
                  <Grid item>
                    <Tooltip isExistTooltip title="Confirmed by office">
                      <Box bgcolor="success.contrastText" borderRadius="50%">
                        <SvgIcon color="success">
                          <CheckCircleIcon />
                        </SvgIcon>
                      </Box>
                    </Tooltip>
                  </Grid>
                }

                <Box display="flex" overflow="hidden">
                  <Box clone mr={0.5}>
                    <LocationOnIcon fontSize="small" />
                  </Box>

                  <AddressLink noWrap variant="caption" address={appointment.office?.full_address}>
                    {appointment.office?.full_address || '-'}
                  </AddressLink>
                </Box>
              </Grid>
            </Box>
          </UserLink>
        </Box>
      </Wrapper>
    </CardItem>
  );
};
