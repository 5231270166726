import { makeStyles, Grid, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarIcon from '@material-ui/icons/Star';
import { Badge } from '../../../../../../../components/Badge';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { Contact } from '../../../../../../../components/Contact';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { Popper } from '../../../../../../../components/Popper';
import { IconButton } from '../../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { UserAvatar, UserPreviewModal } from '../../../../../../../components/users';
import { getUserFullName } from '../../../../../../../helpers/users';
import { ContactMenu } from '../../ContactMenu';
import {
  columnsWidths,
  widthBreakpointSmall,
  widthBreakpointMedium,
  widthBreakpointLarge,
  widthBreakpointExtraLarge
} from '../List';

const styles = ({ palette, typography: { pxToRem } }) => ({
  badgeIcon: {
    color: palette.warning.main,
    borderRadius: 50,
    stroke: palette.common.white,
    fontSize: pxToRem(18) + ' !important'
  }
});

const useStyles = makeStyles(styles);

export const Row = ({ width, contact }) => {
  const { openModal } = useModal();
  // const { selectedIDs, toggleItemSelection } = useContext(ContactsContext);
  // const isSelected = selectedIDs.includes(contact.id);
  const { contact: user } = contact;
  const classes = useStyles();

  // const handleItemSelection = () => {
  //   toggleItemSelection(contact.id);
  // };

  const openPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: contact.contact_id }
    });
  };

  return (
    <ListRow
      button
      onClick={openPreview}
    >
      {/*<ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />*/}

      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Badge
              invisible={!contact.is_favorite}
              overlap="circle"
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'top'
              }}
              badgeContent={
                <Tooltip
                  isExistTooltip
                  title={<Typography variant="body2">Is favorite contact</Typography>}
                >
                  <StarIcon className={classes.badgeIcon} />
                </Tooltip>
              }
            >
              <UserAvatar size="md" user={user} />
            </Badge>
          </Grid>

          <Grid item xs>
            <Typography noWrap>
              {getUserFullName(user)}
            </Typography>

            {contact.note &&
              <Typography noWrap color="textSecondary">{contact.note}</Typography>
            }
          </Grid>
        </Grid>
      </ListRowCell>

      {width > widthBreakpointExtraLarge &&
        <ListRowCell flexBasis={columnsWidths.position}>
          {user?.position || '-'}
        </ListRowCell>
      }

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.role}>
          {user?.role || '-'}
        </ListRowCell>
      }

      <ListRowCell grow flexBasis={columnsWidths.office_name}>
        {user?.office?.name || '-'}
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.address}>
          <AddressLink
            noWrap
            stopPropagation
            name={user?.office?.name}
            address={user?.office?.full_address}
          >
            {user?.office?.full_address || '-'}
          </AddressLink>
        </ListRowCell>
      }

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.venue}>
          <AddressLink
            noWrap
            stopPropagation
            address={user?.office?.venue?.full_address}
          >
            {user?.office?.venue?.full_address || '-'}
          </AddressLink>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.email}>
        <Contact
          noWrap
          color="primary"
          type="mailto"
          contact={user?.email}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.phone}>
        <Contact
          noWrap
          type="tel"
          contact={user?.phone || user?.mobile_phone_number || user?.phone_number}
        />
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <ContactMenu contact={contact} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
