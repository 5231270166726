import { makeStyles } from '@material-ui/core';
import { CourtInformation } from '../CourtInformation';
import { Depositions } from '../Depositions';
import { CaseDepositionsProvider } from '../Depositions/CaseDepositionsProvider';
import { Discovery } from '../Discovery';
import { CaseDiscoveriesProvider } from '../Discovery/CaseDiscoveriesProvider';
import { columnsMap } from '../Discovery/List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const discoveryHiddenColumns = [
  columnsMap.sentDate,
  columnsMap.extension,
  columnsMap.receivedDate
];

export const Litigation = ({ caseItem, onCaseItemUpdate }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <CaseDiscoveriesProvider
          caseItem={caseItem}
          onCaseItemUpdate={onCaseItemUpdate}
        >
          <Discovery
            disableRightActions
            hiddenColumns={discoveryHiddenColumns}
          />
        </CaseDiscoveriesProvider>
      </div>

      <div className={classes.card}>
        <CaseDepositionsProvider caseID={caseItem.id}>
          <Depositions disableRightActions />
        </CaseDepositionsProvider>
      </div>

      <div className={classes.card}>
        <CourtInformation
          caseItem={caseItem}
          WidgetsActionButtonsProps={{
            isDisabledOpen: true,
            isDisabledClose: true,
            isMinimizable: false
          }}
          onCaseItemUpdate={onCaseItemUpdate}
        />
      </div>
    </div>
  );
};
