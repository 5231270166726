export const initialState = {
  isFetching: false,
  isFetched: false,
  loginAccounts: [],
  pagination: {
    total: 0,
    last_page: 1
  },
  filter: {
    page: 1,
    per_page: 15
  }
};
