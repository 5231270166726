export const styles = ({
  spacing,
  typography: { pxToRem }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(0.75, 2, 2, 0)
  },

  title: {
    flexGrow: 1,
    fontSize: pxToRem(14)
  },

  title_total: {
    flexGrow: 0,
    paddingRight: spacing()
  },

  iconLabel: {
    border: 0
  }
});
