import { api } from '../api';

export const fetchInvoices = (config = {}) => {
  return api.get('/invoices', config)
    .then(({ data: { data, pagination } }) => ({ data, pagination }))
    .catch((error) => { throw error.data; });
};

export const createInvoice = (invoiceData) => {
  return api.post('/invoices', invoiceData)
    .then(({ data: { data } }) => (data))
    .catch((error) => { throw error.data; });
};

export const confirmInvoice = (id) => {
  return api.post(`/invoices/${id}/confirm`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massConfirmInvoices = (IDs) => {
  return api.post('/invoices/confirm', { invoices_id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massCancelInvoices = (IDs) => {
  return api.delete('/invoices/confirm', { params: { invoices_id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const cancelInvoice = (id) => {
  return api.delete(`/invoices/${id}/confirm`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchInvoice = (id) => {
  return api.get(`/invoices/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateInvoice = (invoiceItem) => {
  return api.put(`/invoices/${invoiceItem.id}`, invoiceItem)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteInvoice = (id) => {
  return api.delete(`/invoices/${id}`).then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteInvoices = (IDs) => {
  return api.delete('/invoices/delete', { params: { invoices_id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const getInvoicesCounters = (config = {}) => {
  return api.get('/invoices/counters', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const getInvoiceStatus = () => {
  return api.get('/invoice-statuses')
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchTerms = () => {
  return api.get('/invoices/terms_list')
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchInvoiceFiles = (id, config) => {
  return api.get(`/invoices/invoice_files/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const downloadInvoicePDF = (invoice_id) => {
  return api.get(`/invoices/${invoice_id}/pdf`, { responseType: 'blob' })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const exportInvoiceXLSX = (invoice_id, config) => {
  return api.get(`/invoices/${invoice_id}/export`, config);
};
