export const styles = ({ spacing, palette }) => ({
  header: {
    display: 'flex',
    alignItems: 'stretch',
    borderWidth: 5,
    borderColor: palette.grey[100],
    padding: spacing(0, 2),
    minHeight: 115
  },

  mainIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 50,
    border: `1px solid ${palette.primary.main}`,
    width: 65,
    height: 65,
    flexGrow: 0
  },

  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },

  input: {
    fontSize: 'inherit'
  }
});
