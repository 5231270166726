import { useContext } from 'react';
import moment from 'moment';
import { Box, SvgIcon, Typography } from '@material-ui/core';
import { mdiAttachment, mdiCommentMultipleOutline } from '@mdi/js';
import CalendarIcon from '@material-ui/icons/Event';
import { Icon } from '@mdi/react';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { stopPropagation } from '../../../../../../helpers/dom';
import { UserLink } from '../../../../../../components/UserLink';
import { UserPreviewModal } from '../../../../../../components/users';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { CardItem, CardHeader } from '../../../../../../components/Cards';
import { useModal } from '../../../../../../components/ModalsProvider';
import { PreviewProceduresModal } from '../../PreviewProceduresModal';
import { ProcedureContext } from '../../ProcedureProvider';
import { ProceduresMenu } from '../../ProceduresMenu';
import { Body } from './Body';

export const Card = ({ item: procedure = {} }) => {
  const { openModal } = useModal();
  const { selectedIDs, appointmentType, toggleItemSelection, fetchProcedures } = useContext(ProcedureContext);
  const isSelected = selectedIDs.indexOf(procedure.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(procedure.id);
  };

  const openPreview = () => {
    openModal(PreviewProceduresModal, {
      payload: {
        appointmentType,
        procedure: procedure,
        onUpdate: fetchProcedures
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <CardItem selected={!hasRole('client') && isSelected} py={1} px={2} onClick={stopPropagation(openPreview)}>
      <CardHeader
        isSelected={isSelected}
        disableCheckBox={hasRole(rolesMap.client, rolesMap.patient)}
        disableCardMenu={hasRole(rolesMap.client, rolesMap.patient)}
        onItemSelect={handleItemSelection}
        menuComponent={ProceduresMenu}
        menuComponentProps={{ procedure }}
      >
        <Box display="flex" alignItems="flex-start">
          <Box mr={1}>
            <CalendarIcon fontSize="small" />
          </Box>

          <Typography
            noWrap
            variant="subtitle2"
            title={moment.unix(procedure.from).format('L, LT') + ' - ' + moment.unix(procedure.to).format('L, LT')}
          >
            {moment.unix(procedure.from).format('L, LT') + ' - ' + moment.unix(procedure.to).format('L, LT')}
          </Typography>
        </Box>
      </CardHeader>

      <Body procedure={procedure} />

      <Box display="flex" alignItems="center" justifyContent="space-between" pt={2} pb={0.5}>
        <UserLink
          size="lg"
          variant="h5"
          user={procedure.provider}
          onClick={stopPropagation(openUserPreview(procedure?.provider?.id))}
        >
          <Box mt={-0.5}>
            <Typography color="textSecondary" variant="caption">Provider</Typography>
          </Box>
        </UserLink>

        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" fontSize={15}>
            <SvgIcon color="inherit"><Icon path={mdiAttachment} /></SvgIcon>

            <Box pl={0.5}>
              <Typography color="inherit">{procedure?.count_media || 0}</Typography>
            </Box>
          </Box>

          <VerticalDivider verticalGutters={0.25} horizontalGutters={1} />

          <Box display="flex" alignItems="center" color="info.main" fontSize={15}>
            <SvgIcon color="inherit" fontSize="inherit"><Icon path={mdiCommentMultipleOutline} /></SvgIcon>

            <Box pl={0.5}>
              <Typography color="inherit">{procedure?.count_comments || 0}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </CardItem>
  );
};
