import { Fragment } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Grid, IconButton, SvgIcon } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { actionOptions, actionToFieldMap, renderOptionField } from '../../options';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { useModal } from '../../../ModalsProvider';
import { Select } from '../../../FormField';
import { Button } from '../../../Button';

export const BodyActions = () => {
  const { openModal } = useModal();
  const { values } = useFormikContext();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  return (
    <FieldArray
      name="actions"
      render={({ remove, push }) => (
        <>
          <Grid container spacing={5}>
            {values?.actions?.map((condition, i) => (
              <Fragment key={i}>
                <Grid item xs={6}>
                  <Select
                    disableTransform
                    label="Select an action"
                    name={`actions[${i}].key`}
                    options={actionOptions}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Box display="flex">
                    <Box flexGrow={1} mb={4}>
                      {values?.actions[i]?.key &&
                        renderOptionField(
                          actionToFieldMap,
                          values.actions[i].key?.value,
                          {
                            name: `actions[${i}].value`
                          }
                        )
                      }
                    </Box>

                    <Box ml={1} mb={4}>
                      {values?.actions?.length > 1 &&
                        <Box mt={1.5}>
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={handleClearField(remove, i)}
                          >
                            <SvgIcon color="error">
                              <CloseIcon />
                            </SvgIcon>
                          </IconButton>
                        </Box>
                      }
                    </Box>
                  </Box>
                </Grid>
              </Fragment>
            ))}
          </Grid>

          <Box mt={1} mb={2}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => push({ key: actionOptions[0], values: '' })}
            >
              Add another condition
            </Button>
          </Box>
        </>
      )}
    />
  );
};
