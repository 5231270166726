import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { invoiceBlocksMap, invoicesTypesMap } from '../../../../../components/billing';
import { useModal } from '../../../../../components/ModalsProvider';
import { NestedList } from '../../../../../components/NestedList';
import { CreateIncomeModal } from '../Incomes/CreateIncomeModal';
import { Incomes } from '../Incomes';

export const IncomesContent = ({ totalTime, onTotalTimeUpdate = () => {}, onUpdateMainTotal = () => {} }) => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();
  const [ openListItem, setOpenListItem ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenListItem = () => {
    setOpenListItem((state) => !state);
  };

  const handleUpdateIncomes = (value) => {
    setFieldValue('incomes', value);
  };

  const addIncome = () => {
    if (values.model_type === invoicesTypesMap.case && values.model_id) {
      openModal(CreateIncomeModal, {
        payload: {
          incomes: values?.incomes,
          model: values?.model
        },
        onModalResolved: (data) => {
          setFieldValue('incomes', [ ...values?.incomes, data ]);
          onUpdateMainTotal(data, invoiceBlocksMap.invoices, true);
        }
      });
    } else {
      enqueueSnackbar('You need to choose case', { variant: 'warning' });
    }
  };

  useEffect(() => {
    values?.incomes?.length > 0
      ? onTotalTimeUpdate(values?.incomes?.reduce((prev, next) => prev + +next.total, 0).toFixed(2))
      : onTotalTimeUpdate(0);
  }, [ values?.incomes ]);

  return (
    <NestedList
      disablePadding
      disableDivider
      disableBackground
      isOpen={openListItem}
      actionTitle="Add"
      buttonSize="small"
      title="Incomes"
      TypographyProps={{ fontSize: 'medium' }}
      onCreate={addIncome}
      onOpen={handleOpenListItem}
    >
      <Incomes
        incomes={values?.incomes}
        totalTime={totalTime}
        onTotalTimeUpdate={onTotalTimeUpdate}
        onIncomesUpdate={handleUpdateIncomes}
        onUpdateMainTotal={onUpdateMainTotal}
      />
    </NestedList>
  );
};
