import { useMemo } from 'react';
import moment from 'moment';

export const getWorkScheduleByBook = ({ date, workSchedules }) => {
  const daySchedule = workSchedules?.find((schedule) => {
    return moment.unix(date).isSame(schedule.date, 'day');
  });

  if (daySchedule) {
    return daySchedule;
  }

  return workSchedules?.find(({ day }) => {
    return moment.unix(date).weekday() === day;
  });
};

export const useBookWorkSchedule = ({ date, workSchedules }) => {
  return useMemo(() => {
    return getWorkScheduleByBook({ date, workSchedules });
  }, [ date, workSchedules ]);
};

