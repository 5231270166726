import moment from 'moment';
import { useContext } from 'react';
import { Icon } from '@mdi/react';
import { mdiCertificateOutline } from '@mdi/js';
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  SvgIcon
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { IconButton } from '../../../../../../../components/IconButton';
import { CertificatesContext } from '../../CertificatesContext';

export const Row = ({ certificate }) => {
  const { deleteCertificate } = useContext(CertificatesContext);

  const onDeleteCertificate = () => deleteCertificate(certificate.id);

  return (
    <ListItem disableGutters key={certificate.id} alignItems="flex-start">
      <ListItemIcon>
        <SvgIcon fontSize="small"><Icon path={mdiCertificateOutline} /></SvgIcon>
      </ListItemIcon>

      <ListItemText
        primary={certificate.name}
        primaryTypographyProps={{ noWrap: true }}
      />

      {certificate.expiration_date &&
        <ListItemText
          primary={
            <Typography color="textSecondary" align="right">
              {moment.unix(certificate.expiration_date).format('l')}
            </Typography>
          }
          primaryTypographyProps={{ noWrap: true }}
        />
      }

      <ListItemSecondaryAction>
        <IconButton color="error" onClick={onDeleteCertificate}>
          <DeleteIcon fontSize="small"/>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
