import { useContext } from 'react';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { DocumentsContext } from '../../DocumentsProvider';
import { columnsWidths } from '../MainContent';

export const TableHeader = () => {
  const { selectedIDs, allDocumentsIsSelected, toggleAllDocumentsSelection } = useContext(DocumentsContext);

  return (
    <ListRow header>
      <ListRowCheckbox
        checked={allDocumentsIsSelected()}
        indeterminate={!!selectedIDs.length && !allDocumentsIsSelected()}
        onClick={toggleAllDocumentsSelection}
      />

      <ListRowCell grow flexBasis={columnsWidths.name}>
        Name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.created_at}>
        Created at
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updated_at}>
        Updated at
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.signed_users}>
        Signed
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
