import { Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../ListRow';
import { columnsMap, columnsWidths } from '../AppointmentsList';

export const TableHeader = ({ hiddenColumns = [], ListRowProps = {} }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <Paper elevation={0}>
      <ListRow header {...ListRowProps}>
        <ListRowCell flexBasis={columnsWidths.date}>
          {isTablet ? 'Date / Durat.' : 'Date/Duration'}
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.patient) &&
          <ListRowCell flexBasis={columnsWidths.patient}>
            Patient
          </ListRowCell>
        }

        <ListRowCell grow={isTablet} flexBasis={columnsWidths.provider}>
          Provider / Appt. Book
        </ListRowCell>

        {!isMobile && (
          <ListRowCell grow={isTablet} flexBasis={columnsWidths.apptType}>
            {isTablet ? 'Appt. Type / Status' : 'Appt. Type'}
          </ListRowCell>
        )}

        {!isTablet && (
          <ListRowCell flexBasis={columnsWidths.status}>
            Status
          </ListRowCell>
        )}

        {!hiddenColumns.includes(columnsMap.balance) &&
          <ListRowCell grow={isTablet} flexBasis={columnsWidths.balance}>
            Balance
          </ListRowCell>
        }

        {!hiddenColumns.includes(columnsMap.note) &&
          <ListRowCell grow={!isMobile} flexBasis={columnsWidths.note}>
            Note
          </ListRowCell>
        }

        {!hiddenColumns.includes(columnsMap.actions) &&
          <ListRowCell flexBasis={columnsWidths.actions} />
        }
      </ListRow>
    </Paper>
  );
};
