import { createContext, useReducer, useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { usePrevious } from '../../../../../helpers/hooks';
import { api } from '../../../../../api';
import * as typesApi from '../../../../../api/tasks/types/types';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { CreateTypeModal } from '../CreateTypeModal';
import { UpdateTypeModal } from '../UpdateTypeModal';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as actionTypes from './types';

export const TypesContext = createContext(null);

export const TypesProvider = ({ children }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    isFetched,
    isFetching,
    filter,
    types
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const toggleTypeActive = (type) => {
    return typesApi.updateType(type).then((updatedType) => {
      dispatch({ type: actionTypes.UPDATE_TYPE, payload: updatedType });
    });
  };

  const fetchTypes = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: actionTypes.FETCH_TYPES_REQUEST });

    typesApi.fetchTypes({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: actionTypes.FETCH_TYPES_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: actionTypes.APPLY_FILTER, payload: newFilter });
  };

  const resetTypes = (newFilter) => {
    dispatch({ type: actionTypes.RESET_TYPES, payload: newFilter });
    fetchTypes(newFilter);
  };

  const createType = () => {
    openModal(CreateTypeModal, {
      onModalResolved: (type) => {
        dispatch({ type: actionTypes.ADD_TYPE, payload: type });
        enqueueSnackbar(`Type "${type.name}" successfully added`, { variant: 'success' });
      }
    });
  };

  const updateType = (type) => {
    openModal(UpdateTypeModal, {
      payload: {
        type
      },

      onModalResolved: (updatedType) => {
        dispatch({ type: actionTypes.UPDATE_TYPE, payload: updatedType });
        enqueueSnackbar(`Type "${type.name}" successfully updated`, { variant: 'success' });
      }
    });
  };

  const deleteType = ({ id, name }) => {
    return new Promise((resolve, reject) => {
      openModal(ConfirmationModal, {
        payload: {
          content: 'Are you sure you want to delete it?'
        },

        onModalResolved: () => {
          typesApi.deleteType(id).then(() => {
            dispatch({ type: actionTypes.DELETE_TYPE, payload: id });
            enqueueSnackbar(`Type "${name}" successfully deleted`, { variant: 'success' });
            resolve();
          }).catch(reject);
        },

        onModalRejected: () => {
          reject();
        }
      });
    });
  };

  const providerValue = {
    isFetched,
    isFetching,
    filter,
    types,

    createType,
    updateType,
    deleteType,
    applyFilter,
    resetTypes,
    toggleTypeActive
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    if (!isEqual(prevFilter, filter)) {
      resetTypes(filter);
    }
  }, [ prevFilter, filter ]);

  return (
    <TypesContext.Provider value={providerValue}>
      {children}
    </TypesContext.Provider>
  );
};
