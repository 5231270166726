import { api } from '../api';

export const fetchCompanies = (config = {}) => {
  return api.get('/companies', config)
    .then(({ data }) => data);
};

export const fetchCompany = (id) => {
  return api.get(`/companies/${id}`).then(({ data }) => data);
};

export const createCompany = (data) => {
  return api.post('/companies', data).then(({ data }) => data).catch((error) => {
    throw error;
  });
};

export const updateCompany = ({ id, ...data }) => {
  return api.put(`/companies/${id}`, data).then(({ data }) => data).catch((error) => {
    throw error;
  });
};

export const deleteCompany = (id) => {
  return api.delete(`/companies/${id}`).then(({ data: { data } }) => data);
};

export const massDeleteCompanies = (IDs) => {
  return api.delete('/companies', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
