import { Box, Grid, Typography } from '@material-ui/core';
import { TextField } from '../../../FormField';

export const SituationalNumber = () => {
  return (
    <>
      <Box mb={1}>
        <Typography variant="h3">
          Situational Number
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            name="mammography_certification"
            label="Mammography Certification"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            name="investigation_device_exception"
            label="Investigational Device Exception"
          />
        </Grid>
      </Grid>
    </>
  );
};
