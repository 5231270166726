import cn from 'classnames';
import { makeStyles, Chip, Typography } from '@material-ui/core';

const styles = ({
  spacing,
  palette: { grey }
}) => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    paddingLeft: spacing(),
    color: ({ color = grey[500] }) => color,
    borderColor: 'inherit',
    textTransform: 'capitalize',

    '& > *': {
      color: 'inherit'
    }
  }
});

const useStyles = makeStyles(styles);

export const IconLabel = ({ color, label, className, ...props }) => {
  const classes = useStyles({ color });

  return (
    <Chip
      className={cn(classes.root, className)}
      label={(
        <Typography noWrap title={label}>
          {label}
        </Typography>
      )}
      {...props}
    />
  );
};
