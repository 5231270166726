import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { LayoutContextProvider } from '../../../../../components/LayoutContext';
import { WorkSessionsProvider } from '../../../../../components/work-sessions/WorkSessionsProvider';
import { WorkSessions as WorkSessionsCommon } from '../../../../../components/work-sessions/WorkSesstions';
import { filterFieldsMap } from '../../../../../components/work-sessions/WorkSesstions/FiltersBar';
import { columnsMap } from '../../../../../components/work-sessions/WorkSesstions/List/listConfig';

const hiddenColumns  = [
  columnsMap.user
];

const hiddenFilterFields  = [
  filterFieldsMap.users
];

export const WorkSessions = ({ filterKey }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const currentUser = useSelector(({ profile }) => profile.user);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  return (
    <WorkSessionsProvider filter={{ user_id: currentUser.id }}>
      <LayoutContextProvider>
        {isMobile &&
          <Box display="flex" justifyContent="flex-end" px={2}>
            {filterOpenButton}
          </Box>
        }

        <WorkSessionsCommon
          filterKey={filterKey}
          hiddenColumns={hiddenColumns}
          hiddenFilterFields={hiddenFilterFields}
          FilterBarWrapper={FilterBarWrapper}
          filterBarWrapperProps={filterBarWrapperProps}
          ScrollWrapper={ScrollWrapper}
          scrollWrapperProps={scrollWrapperProps}
        />
      </LayoutContextProvider>
    </WorkSessionsProvider>
  );
};
