import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, debounce } from '@material-ui/core';
import { saveFilter } from '../../../../../store/lastFilters';
import {
  TextField,
  PhoneField,
  KeyboardDatePicker
} from '../../../../../components/FormField';
import { IconComponent } from '../../../../../components/saved-filters';
import { FiltersBar as CommonFiltersBar } from '../../../../../components/FiltersBar';
import { AccessPatientsContext } from '../PatientsProvider';
import { CardContent } from './CardContent';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { SavedFiltersList } from './SavedFiltersList';
import { initialValues } from './initialValues';

const MODAL_WIDTH = 860;

export const FiltersBar = ({ filterKey, hiddenFields }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { applyFilter } = useContext(AccessPatientsContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value || null });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleDatePickerChange = (name, transformer) => (date) => {
    const transformedDate = transformer?.(date) || date;

    applyFilter({ [name]: transformedDate });
    setRelationsForFilter((state) => ({ ...state, [name]: transformedDate }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(filter);
    applyFilter(filter);
  };

  useEffect(() => {
    if (lastFilters) {
      formikRef.current.setValues({
        ...relationsForFilter,
        ...lastFilters
      });
    }
  }, []);

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  return (
    <CommonFiltersBar
      isResetForm
      enableSettings
      formikRef={formikRef}
      initialValues={initialValues}
      onReset={() => applySavedFilter(initialValues)}
      filterKey={filterKey}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={SavedFiltersList}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      fieldsList={[
        {
          fieldKey: filterFieldsMap.patientName,
          label: filterFieldsLabels[filterFieldsMap.patientName],
          field: <TextField
            label="User Name"
            placeholder="Enter name..."
            name="patient_name"
            onChange={handleFieldChange('patient_name')}
          />
        },
        {
          fieldKey: filterFieldsMap.patientEmail,
          label: filterFieldsLabels[filterFieldsMap.patientEmail],
          field: <TextField
            label="User Email"
            placeholder="Enter email..."
            name="patient_email"
            onChange={handleFieldChange('patient_email')}
          />
        },
        {
          fieldKey: filterFieldsMap.patientPhone,
          label: filterFieldsLabels[filterFieldsMap.patientPhone],
          field: <PhoneField
            label="User Phone"
            name="patient_phone"
            onChange={handleFieldChange('patient_phone')}
          />
        },
        {
          fieldKey: filterFieldsMap.patientAddress,
          label: filterFieldsLabels[filterFieldsMap.patientAddress],
          field: <TextField
            label="User Address"
            placeholder="Enter address..."
            name="patient_address"
            onChange={handleFieldChange('patient_address')}
          />
        },
        {
          fieldKey: filterFieldsMap.doi,
          label: filterFieldsLabels[filterFieldsMap.doi],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              name="doi"
              label="DOI"
              outputFormat="YYYY-MM-DD"
              onChange={handleDatePickerChange('doi')}
            />
          </Box>
        }
      ]}
    />
  );
};
