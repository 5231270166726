import { makeStyles, Typography } from '@material-ui/core';
import { AppBar } from '../../../../../../components/AppBar';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const VersionsList = ({ documentID, onVersionSelect }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.header}>
        <Typography variant="h2" color="inherit">Version history</Typography>
      </AppBar>

      <div className={classes.list}>
        <List documentID={documentID} onVersionSelect={onVersionSelect} />
      </div>
    </div>
  );
};
