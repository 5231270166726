import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  Button,
  IconButton,
  Typography,
  Box,
  useMediaQuery
} from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { resetInvoice } from '../../../../../store/dashboard/billing';
import { setPaymentsLastGlobalAction } from '../../../../../store/globalActions';
import * as paymentsApi from '../../../../../api/billing';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { billingParticipantsTypesOptions } from '../../../../../components/billing';
import { Loader } from '../../../../../components/Loader';
import * as types from '../../../../../components/billing/payments/PaymentsContext/types';
import { validationSchema } from '../validationSchema';
import { MainCreateContent } from './MainCreateContent';
import { InvoiceContent } from './InvoiceContent';
import { PaymentForm } from './PaymentForm';
import { Header } from './Header';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointSmall = 1100;
export const minRowHeight = 72;
export const columnsWidths = {
  invoice_date: 200,
  due_on: 200,
  total: 200,
  amount: 200,
  balance: 200
};

export const CreatePayment = () => {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const dispatch = useDispatch();
  const invoice = useSelector(({ billing }) => billing);
  const { enqueueSnackbar } = useSnackbar();
  const [ selectedInvoices, setSelectedInvoices ] = useState(invoice?.id ? [ invoice ] : []);
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });

  const goBack = () => {
    dispatch(resetInvoice());
    history.goBack();
  };

  const createPayment = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return paymentsApi.massCreatePayment({
      ...values,

      amount: +values.amount,

      payer: {
        id: values?.payer?.id?.id || values?.payer?.id,
        type: values?.payer?.type?.value || values?.payer?.type
      },

      invoice_ids: !selectedInvoices.length
        ? invoice?.id ? [ invoice.id ] : null
        : selectedInvoices.map(({ id }) => id)
    }).then((data) => {
      dispatch(setPaymentsLastGlobalAction({ type: types.MASS_ADD_PAYMENT, payload: data }));
      enqueueSnackbar('Payment successfully created', { variant: 'success' });
      goBack();
    }).catch((errors) => {
      if (errors) {
        setErrors(errors);
        enqueueSnackbar('Payment not created', { variant: 'error' });
      }
    });
  };

  return (
    <Box ref={rootRef} display="flex" height="100%" p={isMobile ? 0 : 2}>
      <Formik
        initialValues={{
          amount: invoice?.paid || 0,
          total: invoice?.total || 0,
          method: null,
          date: moment().format(moment.HTML5_FMT.DATE),
          due_date: invoice?.due_date || null,
          parentPage: invoice?.parentPage || null,
          payer: invoice?.bill_to ? {
            type: billingParticipantsTypesOptions?.find(({ value }) =>
              value === invoice?.bill_to?.participant_type
            ),
            id: invoice?.bill_to?.participant,
            info: invoice?.bill_to?.participant || invoice?.bill_to?.info
          } : {
            type: null,
            id: null
          }
        }}
        validationSchema={validationSchema}
        onSubmit={createPayment}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Page
            noValidate
            square={false}
            component="form"
            className={classes.root}
            onSubmit={handleSubmit}
          >
            <PageHeader>
              <IconButton
                color="primary"
                edge="start"
                onClick={goBack}
              >
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>

              <PageHeaderTitle>
                {invoice ? invoice?.title : 'Create Payment'}
              </PageHeaderTitle>

              <PageHeaderActions>
                <Box mr={2}>
                  <Button
                    size={isMobile ? 'small' : 'medium'}
                    color="primary"
                    onClick={goBack}
                  >
                    Cancel
                  </Button>
                </Box>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      autoFocus
                      disabled={isSubmitting}
                      size={isMobile ? 'small' : 'medium'}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Pay
                    </Button>
                  )}
                />
              </PageHeaderActions>
            </PageHeader>

            <PageBody disablePaddings>
              {!invoice?.parentPage ? (
                <MainCreateContent
                  state={invoice}
                  width={width}
                  selectedInvoices={selectedInvoices}
                  setSelectedInvoices={setSelectedInvoices}
                />
              ) : (
                <div className={classes.mainContent}>
                  <Header invoice={invoice} />

                  <PaymentForm
                    parentPage="create"
                    width={width}
                    invoice={invoice}
                    selectedInvoices={selectedInvoices}
                  />

                  <div className={classes.invoiceContent}>
                    <Typography gutterBottom variant="h6">Invoice</Typography>
                    <InvoiceContent width={width} invoice={invoice} />
                  </div>
                </div>
              )}
            </PageBody>
          </Page>
        )}
      </Formik>
    </Box>
  );
};
