import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, useTheme, useMediaQuery } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { Fab } from '../../../../../../../../components/Fab';
import { ScheduleListPreview } from '../../../ScheduleListPreview';
import { CreateScheduleModal } from '../../../ScheduleListPreview/CreateScheduleModal';
import { CodesContext } from '../../../CodesContext';
import { minRowHeight, columnsWidths } from '../../MainContent';

export const Row = ({ code }) => {
  const { selectedIDs, toggleItemSelection, deleteCustomCode } = useContext(CodesContext);
  const { openModal } = useModal();
  const [ schedulesCount, setSchedulesCount ] = useState(code.fee_schedules_count);
  const isSelected = selectedIDs.indexOf(code.id) !== -1;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const baseUnit = !!code.base_unit ? `${code.base_unit} / ` : '';
  const addOn = code.add_on ? 'Yes' : 'No';

  const handleItemSelection = () => {
    toggleItemSelection(code.id);
  };

  const openCodeCPTModal = () => {
    openModal(ScheduleListPreview, {
      payload: {
        initialValues: {
          codeID: code.id,
          parent: code.procedure_code
        }
      }
    });
  };

  const openScheduleModal= () => {
    openModal(CreateScheduleModal, {
      payload: {
        procedure_code_id: code.id,
        onUpdateCount: setSchedulesCount
      }
    });
  };

  const deleteCode = () => {
    deleteCustomCode(code);
  };

  return (
    <ListRow minHeight={minRowHeight} selected={isSelected}>
      <ListRowCheckbox
        checked={isSelected}
        onClick={handleItemSelection}
      />

      <ListRowCell column={isTablet} grow flexBasis={columnsWidths.name}>
        <Typography noWrap title={code.procedure_code}>
          {code.procedure_code}
        </Typography>

        {isTablet && (
          <Typography noWrap title={code.procedure_code}>
            {code.description}
          </Typography>
        )}
      </ListRowCell>

      {!isTablet && (
        <ListRowCell grow flexBasis={columnsWidths.description}>
          <Typography noWrap title={code.procedure_code}>
            {code.description}
          </Typography>
        </ListRowCell>
      )}

      {!isTablet && (
        <ListRowCell flexBasis={columnsWidths.base_unit}>
          {code.base_unit}
        </ListRowCell>
      )}

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.add_on}>
          {isTablet ? `${baseUnit}${addOn}` : addOn}
        </ListRowCell>
      )}

      <ListRowCell flexBasis={isMobile ? 95 : columnsWidths.actionButton}>
        {schedulesCount > 0 ?
          <Fab
            fullWidth
            size={isTablet ? 'small' : 'medium'}
            variant="extended"
            color="info"
            onClick={openCodeCPTModal}
          >
            {isMobile ? 'Schedule' : 'Fee schedule'}
          </Fab>
          :
          isMobile ? (
            <Fab
              size="small"
              color="success"
              variant="round"
              onClick={openScheduleModal}
            >
              <AddIcon />
            </Fab>
          ) : (
            <Fab
              fullWidth
              size={isTablet ? 'small' : 'medium'}
              variant="extended"
              color="success"
              onClick={openScheduleModal}
            >
              Add fee schedule
            </Fab>
          )
        }
      </ListRowCell>

      {hasRole(rolesMap.admin, rolesMap.supervisor) &&
        <ListRowCell noWrap flexBasis={isTablet ? 75 : columnsWidths.action}>
          <Tooltip title="Edit code">
            <IconButton
              size={isTablet ? 'small' : 'medium'}
              color="primary"
              component={Link}
              to={`/codes/procedure-codes/cpt/${code.id}/edit`}
              variant="contained"
            >
              <EditIcon/>
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete code">
            <IconButton
              size={isTablet ? 'small' : 'medium'}
              variant="contained"
              color="error"
              onClick={deleteCode}
            >
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
        </ListRowCell>
      }
    </ListRow>
  );
};
