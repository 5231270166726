import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { List as MuiList } from '@material-ui/core';
import { invoiceBlocksMap } from '../../../../../components/billing';
import { AssignInvoiceModal } from '../../../../../components/billing/AssignInvoiceModal';
import { Button } from '../../../../../components/Button';
import { useModal } from '../../../../../components/ModalsProvider';
import { NestedList } from '../../../../../components/NestedList';
import * as types from './ExpensesContext/types';
import { ExpensesModal } from './ExpensesModal';
import { List } from './List';

export const Expenses = ({
  parentPage,
  invoice,
  onTotalTimeUpdate = () => {},
  onUpdateMainTotal = () => {},
  onExpensesUpdate = () => {}
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ expenses, setExpenses ] = useState(invoice?.expenses || []);
  const [ expense, setExpense ] = useState({});
  const expensesLastGlobalAction = useSelector(({ globalActions }) => globalActions.expensesLastGlobalAction);
  const [ openListItem, setOpenListItem ] = useState(false);
  const [ expensesCount, setExpensesCount ] = useState(invoice?.expenses?.length);

  const handleOpenListItem = () => {
    setOpenListItem((state) => !state);
  };

  const addExpense = () => {
    openModal(ExpensesModal, {
      payload: {
        initialValues: {
          title: 'Create Expense',
          invoice_id: invoice && invoice.id,
          parentPage
        }
      },
      onModalResolved: (data) => {
        if (data) {
          setExpense(data);
          onUpdateMainTotal(data, invoiceBlocksMap.expenses, true);
          onExpensesUpdate([ ...expenses, data ]);
          setExpenses([ ...expenses, data ]);
        }
      }
    });
  };

  const openAssignModal = () => {
    openModal(AssignInvoiceModal, {
      payload: {
        disableInvoice: !parentPage,
        invoice_id: parentPage ? invoice.id : null,
        title: 'Attach Expense'
      },
      onModalResolved: (expenseValue) => {
        const isExist = expenses?.find((expenseItem) => expenseItem?.id === expenseValue?.id);

        if (!isExist) {
          setExpenses((prevExpenses) => [ ...prevExpenses, expenseValue ]);
          onUpdateMainTotal(expenseValue, invoiceBlocksMap.expenses, true);
          enqueueSnackbar('Successfully attached', { variant: 'success' });
        } else {
          enqueueSnackbar('Expense already exist', { variant: 'error' });
        }
      }
    });
  };

  useEffect(() => {
    invoice && setExpenses(invoice?.expenses);
  }, []);

  useEffect(() => {
    const totalIncomes = invoice.incomes?.reduce((prev, next) => prev + +next.total, 0);
    const totalExpenses = expenses?.reduce((prev, next) => prev + +next.amount, 0);

    onExpensesUpdate(expenses);
    onTotalTimeUpdate(totalIncomes + totalExpenses);

    if (parentPage) {
      setExpensesCount(expenses?.length);
    }
  }, [ expenses ]);

  useEffect(() => {
    if (expensesLastGlobalAction) {
      if (expensesLastGlobalAction.type === types.UPDATE_EXPENSE_IN_LIST) {
        setExpense(expensesLastGlobalAction.payload);

        setExpenses(expenses.map((item) => item.id === expensesLastGlobalAction.payload.id
          ? expensesLastGlobalAction.payload
          : item
        ));
      }
    }
  }, [ expensesLastGlobalAction ]);

  return (
    <NestedList
      disablePadding
      disableDivider
      disableBackground
      isOpen={openListItem}
      actionTitle="Add"
      buttonSize="small"
      title={`${parentPage ? expensesCount : ''} Reimbursement expenses`}
      actionButton={(
        <Button variant="contained" color="primary" size="small" onClick={openAssignModal}>
          Attach expense
        </Button>
      )}
      TypographyProps={{ fontSize: 'medium' }}
      onCreate={addExpense}
      onOpen={handleOpenListItem}
    >
      <MuiList component="div" disablePadding>
        <List
          expense={expense}
          parentPage={parentPage}
          invoice={invoice}
          expenses={expenses}
          onUpdateExpenses={setExpenses}
          onUpdateMainTotal={onUpdateMainTotal}
        />
      </MuiList>
    </NestedList>
  );
};
