import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';

const getLiveSessionDuration = (currentDuration, start) => {
  return currentDuration + moment().unix() - start;
};

export const useLiveSessionDuration = (tracks, activeTrack) => {
  const tracksDuration = useMemo(() => {
    return tracks?.reduce((duration, track) => {
      if (track.finished_at && activeTrack?.type === track?.type) {
        return duration + track.duration;
      }

      return duration;
    }, 0);
  }, [ tracks, activeTrack?.type ]);
  const [ liveDuration, setLiveDuration ] = useState(getLiveSessionDuration(tracksDuration, activeTrack?.started_at));
  const liveDurationIntervalRef = useRef(null);

  useEffect(() => {
    clearInterval(liveDurationIntervalRef.current);

    if (activeTrack?.started_at) {
      liveDurationIntervalRef.current = setInterval(() => {
        setLiveDuration(getLiveSessionDuration(tracksDuration, activeTrack?.started_at));
      }, 1000);
    } else {
      clearInterval(liveDurationIntervalRef.current);
    }

    return () => {
      clearInterval(liveDurationIntervalRef.current);
    };
  }, [ tracksDuration, activeTrack?.started_at ]);

  return moment.duration(liveDuration, 'seconds').format('HH:mm:ss', { trim: false });
};
