export const styles = ({
  spacing,
  palette: { grey, primary },
  typography: { pxToRem },
  shape: { borderRadius },
  breakpoints
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: spacing(2),
    paddingTop: spacing(),
    paddingBottom: spacing(),
    flexGrow: 1,
    minHeight: 54,

    [breakpoints.down('md')]: {
      flexWrap: 'wrap',
      padding: 0
    }
  },

  root_edit: {
    paddingRight: spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 50,

    [breakpoints.down('md')]: {
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: 0,
      minHeight: 75
    }
  },

  toolbar: {
    flexWrap: 'wrap',

    [breakpoints.down('md')]: {
      display: 'flex',
      flexWrap: 'no-wrap'
    }
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(),
    color: grey[600],

    '& svg': {
      fontSize: pxToRem(30)
    }
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 500,

    [breakpoints.down('md')]: {
      minWidth: 'auto'
    }
  },

  case: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,

    [breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: spacing(3)
    }
  },

  case__title: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: primary.contrastText,
    backgroundColor: '#48D2A0',
    borderRadius,
    paddingLeft: spacing(),
    paddingRight: spacing(),
    marginRight: spacing(2),
    marginLeft: spacing(-1.5),
    fontSize: pxToRem(14)
  },

  listIcon: {
    marginRight: spacing(1)
  },

  restoreIcon: {
    color: primary.main
  },

  backButton: {
    marginRight: spacing()
  },

  backIcon: {
    fontSize: pxToRem(18),
    marginRight: spacing()
  },

  caseLink: {
    cursor: 'pointer'
  }
});
