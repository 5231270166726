import { makeStyles, Grid, InputAdornment, useTheme, useMediaQuery } from '@material-ui/core';
import * as tasksApi from '../../../../../api/tasks';
import { fetchDataForAsyncSelect } from '../../../../../helpers/fetchDataForAsyncSelect';
import { tasksPrioritiesOptions } from '../../../../../dataMaps';
import { DatePicker } from '../../../../../components/FormField';
import {
  Select,
  PrioritySelect,
  TextField,
  TaskTypesSelect
} from '../../../../../components/FormField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const fetchStatuses = fetchDataForAsyncSelect(() => {
  return tasksApi.fetchStatuses();
}, {
  withoutPagination: true
});

export const Header = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <Grid container item xs={12} className={classes.preview__header}>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={isMobile ? 12 : 3}>
          <TaskTypesSelect
            name="type_id"
            label="Type"
            TextFieldProps={{ margin: 'dense' }}
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 3}>
          <PrioritySelect
            name="priority"
            label="Priority"
            options={tasksPrioritiesOptions}
            TextFieldProps={{ margin: 'dense' }}
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 3}>
          <Select
            isAsync
            formattedValue
            name="status_id"
            label="Status"
            promiseWithOptions={fetchStatuses}
            TextFieldProps={{ margin: 'dense' }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        spacing={1}
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={isMobile ? 12 : 3} container justify="space-between">
          <Grid item xs={isMobile ? 6 : 5}>
            <TextField
              zeroMinWidth
              type="number"
              name="hours"
              label="Hours"
              margin="dense"
              placeholder="Hours"
              InputProps={{
                endAdornment: (<InputAdornment position="end">h</InputAdornment>)
              }}
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              zeroMinWidth
              type="number"
              name="minutes"
              label="Minutes"
              margin="dense"
              placeholder="Minutes"
              InputProps={{
                endAdornment: (<InputAdornment position="end">m</InputAdornment>)
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={isMobile ? 6 : 3}>
          <DatePicker
            name="due_at"
            label="Due date"
            placeholder="Select due date..."
            margin="dense"
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            zeroMinWidth
            name="work_status"
            label="My work status"
            margin="dense"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
