import { useContext } from 'react';
import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../ListRow';
import { TasksContext } from '../../../../TasksProvider';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  const { selectedTasksIDs, allTasksIsSelected, toggleAllTasksSelection } = useContext(TasksContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCheckbox
          onClick={toggleAllTasksSelection}
          checked={allTasksIsSelected()}
          indeterminate={!!selectedTasksIDs.length && !allTasksIsSelected()}
        />

        <ListRowCell grow flexBasis={columnsWidths.name}>
          Name/Description
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.parent}>
          Case
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.type}>
          Type
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.status}>
          Status
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.progress}>
          Progress
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.activity}>
          Due date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.assigned}>
          Members
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.priority}>
          Priority
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
