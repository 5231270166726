import { Dialog } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import { ModalContainer, ModalHeader, ModalBody } from '../../../Modal';
import { AppointmentsFilterProvider, AppointmentsProvider, AppointmentsList } from '../../index';
import { columnsMap } from '../../AppointmentsList';
import { FiltersBar } from '../../FiltersBar';
import { Row } from './Row';

const hiddenColumns = [
  columnsMap.actions
];

const ListRowProps = {
  button: true,
  px: 2
};

const hideTooltipTitle = true;

export const AppointmentsSelectModal = ({
  payload: {
    filter = {}
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  return (
    <Dialog maxWidth="lg" PaperProps={{ style: { maxWidth: 1420 } }} {...DialogProps}>
      <ModalContainer>
        <ModalHeader
          icon={<CalendarIcon />}
          onClose={handleModalReject}
        >
          Appointments
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <AppointmentsFilterProvider>
            <AppointmentsProvider filter={filter}>
              <FiltersBar />

              <AppointmentsList
                autoHeight
                hideTooltipTitle={hideTooltipTitle}
                hiddenColumns={hiddenColumns}
                Row={Row}
                RowProps={{ onSelect: handleModalResolve, ListRowProps }}
              />
            </AppointmentsProvider>
          </AppointmentsFilterProvider>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
