import { LayoutContextProvider } from '../../../../components/LayoutContext';
import { WaitingListFilterContextProvider } from './WaitingListFilterProvider';
import { WaitingListProvider } from './WaitingListProvider';
import { PageContent } from './PageContent';

export const WaitingList = ({ filterKey }) => {
  return (
    <WaitingListFilterContextProvider>
      <WaitingListProvider>
        <LayoutContextProvider>
          <PageContent filterKey={filterKey} />
        </LayoutContextProvider>
      </WaitingListProvider>
    </WaitingListFilterContextProvider>
  );
};
