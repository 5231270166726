export const filterFieldsMap = {
  note: 'note',
  userId: 'user_id',
  officeId: 'office_id'
};

export const filterFieldsLabels = {
  [filterFieldsMap.note]: 'Note',
  [filterFieldsMap.userId]: 'User',
  [filterFieldsMap.officeId]: 'Office'
};
