import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../../../../../api';
import * as profileApi from '../../../../../../api/profile';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const MedicalProfileContext = createContext();

export const MedicalProfileProvider = ({ userID = null, medicalInfoPathname, children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { isFetched, medicalProfile } = state;
  const medicalProfileLastGlobalAction = useSelector(
    ({ globalActions }) => globalActions.medicalProfileLastGlobalAction
  );
  const cancelFetch = useRef(() => {});
  const [ initialType, setInitialType ] = useState(null);
  const [ initialFormValue, setInitialFormValue ] = useState({});

  const getMedicalProfile = () => {
    cancelFetch.current();
    dispatch({ type: types.FETCH_PROFILE_REQUEST });

    profileApi.fetchMedicalProfile({
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_PROFILE_SUCCESS, payload: data });
    });
  };

  const getUserMedicalProfile = () => {
    cancelFetch.current();
    dispatch({ type: types.FETCH_PROFILE_REQUEST });

    profileApi.fetchUserMedicalProfile(userID, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_PROFILE_SUCCESS, payload: data });
    });
  };

  useEffect(() => {
    if (medicalProfileLastGlobalAction) {
      dispatch(medicalProfileLastGlobalAction);

      if (medicalProfileLastGlobalAction?.type === types.FETCH_PROFILE_REQUEST) {
        userID ? getUserMedicalProfile() : getMedicalProfile();
      }
    }
  }, [ medicalProfileLastGlobalAction ]);

  const providerValue = {
    isFetched,
    initialType,
    initialFormValue,
    medicalProfile,
    medicalInfoPathname,
    getMedicalProfile: userID ? getUserMedicalProfile : getMedicalProfile,
    setInitialType,
    setInitialFormValue
  };

  useEffect(() => {
    userID ? getUserMedicalProfile() : getMedicalProfile();
  }, [ userID ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <MedicalProfileContext.Provider value={providerValue}>
      {children}
    </MedicalProfileContext.Provider>
  );
};
