import { Yup } from '../../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  intake_form_completed: Yup.boolean().nullable(),
  scene_photos: Yup.boolean().nullable(),
  pd_photos: Yup.boolean().nullable(),
  um_case: Yup.boolean().nullable(),
  police_report: Yup.boolean().nullable(),
  tcr_number: Yup.number().positive().nullable(),
  officer_id: Yup.string().nullable(),
  sr_one_sent: Yup.date().format('YYYY-MM-DD').nullable(),
  sr_nineteen_sent: Yup.date().format('YYYY-MM-DD').nullable(),
  plf_dec_sheet: Yup.boolean().nullable(),
  pd_requested: Yup.boolean().nullable(),
  pd_received: Yup.boolean().nullable(),
  loe: Yup.boolean().nullable(),
  rep_ltr_to_def_one_ins: Yup.boolean().nullable(),
  rep_ltr_to_def_two_ins: Yup.boolean().nullable(),
  rep_ltr_own_ins: Yup.boolean().nullable(),
  rep_ltr_def_three: Yup.boolean().nullable(),
  witnesses: Yup.boolean().nullable(),
  witness_statement: Yup.boolean().nullable(),
  passengers: Yup.boolean().nullable(),
  other: Yup.string().nullable(),
  police_department_id: Yup.string().nullable(),

  // Medical
  medicare: Yup.boolean().nullable(),
  medical: Yup.boolean().nullable(),
  primary_health_ins: Yup.boolean().nullable(),
  no_health_ins: Yup.boolean().nullable(),
  insurance_card_number: Yup.string().nullable(),
  medicare_filled_at: Yup.date().format('YYYY-MM-DD').nullable(),
  medical_filled_at: Yup.date().format('YYYY-MM-DD').nullable(),
  medicare_final_lien: Yup.boolean().nullable(),
  medical_final_lien: Yup.boolean().nullable(),
  primary_health_ins_final_lien: Yup.boolean().nullable()
});
