import {
  Box,
  List as MuiList,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../components/IconButton';
import { TextField } from '../../../../../../../components/FormField';
import {
  columnsWidthsLg,
  columnsWidthsSm,
  spinalExaminationMap
} from '../../../../../../../components/medical/reports';
import { MotorStrength } from './MotorStrength';
import { ReflexLower } from './ReflexLower';
import { RangeMotion } from './RangeMotion';
import { Body } from './Body';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CervicalSpine = ({ onDelete }) => {
  const classes = useStyles();
  const formPartName = 'forms.physical_examination.cervical';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <>
      <Box mt={4} mb={1} color="info.main" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" color="inherit">
          Spinal examination-Cervical spine
        </Typography>

        {onDelete && (
          <IconButton color="error" onClick={onDelete}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <Typography variant="caption" color="textSecondary">
        Palpation of the spine reveals tenderness (graded 0=none; 1+=mild; 2+=moderate; and 3+=marked)
        in the following regions:
      </Typography>

      <div className={classes.root}>
        <ListRow header>
          <ListRowCell noWrap grow flexBasis={columnsWidths.name} />

          <ListRowCell flexBasis={columnsWidths.right}>
            <Typography variant="subtitle2" color="textSecondary">
              Right
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.midline}>
            <Typography variant="subtitle2" color="textSecondary">
              Midline
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.left}>
            <Typography variant="subtitle2" color="textSecondary">
              Left
            </Typography>
          </ListRowCell>
        </ListRow>

        <MuiList disablePadding>
          {Object.keys(spinalExaminationMap)?.map((item, i) => (
            <ListRow key={i}>
              <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                <Typography noWrap variant="h5">
                  {spinalExaminationMap[item]}:
                </Typography>
              </ListRowCell>

              <ListRowCell noWrap flexBasis={columnsWidths.right}>
                <TextField
                  fullWidth
                  zeroMinWidth
                  disableUnderline
                  placeholder="Add..."
                  name={`${formPartName}.spinal_examination_cervical_spine.${item}.right`}
                />
              </ListRowCell>

              <ListRowCell noWrap flexBasis={columnsWidths.midline}>
                <TextField
                  fullWidth
                  zeroMinWidth
                  disableUnderline
                  placeholder="Add..."
                  name={`${formPartName}.spinal_examination_cervical_spine.${item}.midline`}
                />
              </ListRowCell>

              <ListRowCell noWrap flexBasis={columnsWidths.left}>
                <TextField
                  fullWidth
                  zeroMinWidth
                  disableUnderline
                  placeholder="Add..."
                  name={`${formPartName}.spinal_examination_cervical_spine.${item}.left`}
                />
              </ListRowCell>
            </ListRow>
          ))}
        </MuiList>
      </div>

      <RangeMotion name={'forms.physical_examination.cervical.range_of_motion_cervical_spine'} />
      <MotorStrength name={'forms.physical_examination.cervical.motor_strength_upper_extremities'} />
      <ReflexLower name={'forms.physical_examination.cervical.reflexes_lower_extremities'} />
      <Body formPartName={formPartName} />
    </>
  );
};
