import { useContext } from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import NotesIcon from '@material-ui/icons/Notes';
import { stopPropagation } from '../../../../../../helpers/dom';
import { generateAddress } from '../../../../../../utils/generateAddress';
import { UserPreviewModal } from '../../../../../../components/users';
import { useModal } from '../../../../../../components/ModalsProvider';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { Tooltip } from '../../../../../../components/Tooltip';
import { UserLink } from '../../../../../../components/UserLink';
import { ReportsPreviewContext } from '../../../ReportsPreviewProvider';

export const MainContent = () => {
  const { openModal } = useModal();
  const { selectedItem: mri, appointment } = useContext(ReportsPreviewContext);

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" component={Box} pt={3}>
        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Patient</Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <UserLink
            variant="h5"
            user={mri.patient}
            onClick={stopPropagation(openUserPreview(mri?.patient?.id))}
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography noWrap color="textSecondary">Primary Care Physician</Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <UserLink
            variant="h5"
            user={mri.physician}
            onClick={stopPropagation(openUserPreview(mri?.physician?.id))}
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Date of MRI</Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <CalendarIcon fontSize="small" />
            </Box>

            <Typography variant="h5">
              {moment.unix(mri.date).format('L, LT')}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary">Office</Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Tooltip
            isExistTooltip={!!mri?.office}
            title={mri?.office?.full_address || generateAddress(mri?.office)}
          >
            <OfficeLink
              variant="h5"
              office={mri?.office}
            />
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="flex-start" component={Box} py={5}>
        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Type Insurance</Typography>
        </Grid>

        <Grid item sm={10} xs={6}>
          <Typography variant="h5">
            {appointment?.patient_insurance?.type_insurance}
          </Typography>
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Insurance ID</Typography>
        </Grid>

        <Grid item sm={10} xs={6}>
          <Typography variant="h5">
            {appointment?.patient_insurance?.insuranceId}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} component={Box} pb={5}>
        <Grid item sm={2} xs={6}>
          <Typography variant="h5">Name MRI scan</Typography>
        </Grid>

        <Grid item>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">
              {mri.name}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center" fontWeight={500} mb={0.5}>
            <NotesIcon fontSize="small"/>

            <Box ml={1}>
              <Typography variant="h5">Note</Typography>
            </Box>
          </Box>

          <Typography>
            {mri.note || 'No info'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
