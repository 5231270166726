import { SecondStep } from '../SecondStep';
import { FourthStep } from '../FourthStep';
import { FirstStep } from '../FirstStep';
import { ThirdStep } from '../ThirdStep';
import { FifthStep } from '../FifthStep';
import { SixStep } from '../SixStep';

export const stepComponents = [
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep,
  SixStep
];
