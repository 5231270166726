import { Grid, Typography } from '@material-ui/core';
import { Date } from '../../../../components/Date';
import { UserLink } from '../../../../components/UserLink';
import { statusLabelsMap } from '../List/statusMap';

export const StatusConfirmationPopup = ({ fax }) => {
  return (
    <Grid container spacing={2}>
      {fax?.previous_system_status && (
        <>
          <Grid item xs={5}>
            <Typography>Previous status:</Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography variant="h5">
              {statusLabelsMap[fax?.previous_system_status] || statusLabelsMap.new}
            </Typography>
          </Grid>
        </>
      )}

      {fax?.system_status_changed_at && (
        <>
          <Grid item xs={5}>
            <Typography>Status changed at:</Typography>
          </Grid>

          <Grid item xs={7}>
            <Date
              color="textPrimary"
              format="L LT"
              date={fax?.system_status_changed_at}
            />
          </Grid>
        </>
      )}

      {fax?.statusChangedBy && (
        <>
          <Grid item xs={5}>
            <Typography>Status changed by:</Typography>
          </Grid>

          <Grid item xs={7}>
            <UserLink user={fax?.statusChangedBy} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
