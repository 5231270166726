import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../ListRow';
import {
  columnsMap,
  columnsMobileWidths,
  columnsLargeWidths
} from './Procedures';

export const Row = ({ procedure }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsMobileWidths : columnsLargeWidths;

  return !!procedure && (
    <ListRow>
      <ListRowCell grow column flexBasis={columnsWidths[columnsMap.code]}>
        <Typography noWrap>
          {procedure.procedure?.procedure_code}
        </Typography>

        <Typography noWrap color="textSecondary" title={procedure.procedure?.description}>
          {procedure.procedure?.description}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.laterality}>
        {procedure.laterality}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.visits}>
        {procedure.visits}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.min}>
        {procedure.min}
      </ListRowCell>
    </ListRow>
  );
};
