import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell flexBasis={columnsWidths.originalFilename}>
          File Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.tags}>
          Tags
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.updatedAfter}>
          Updated After
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.updatedBefore}>
          Updated Before
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.orderBy}>
          Order By
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
