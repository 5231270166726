import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { Fab } from '../../../../../components/Fab';
import {
  KeyboardDatePicker,
  TextField,
  CompaniesAutocomplete,
  FinancialClassesSelect,
  CurrencyField,
  PhoneField,
  OfficesLocationSelect
} from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';
import { ApproveAttorneyModal } from '../../../../../components/medical/forms/useMedFormApprove/ApproveAttorneyModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { isPrimary } from '../InsurancesProvider';
import { CaseInsurance } from './CaseInsurance';
import { Header } from './Header';
import {
  insuranceTypes,
  companyTypeIDsMap,
  insuranceTypesMap,
  insuranceTypesOptions,
  insuranceStatusTypesMap,
  caseInsuranceTypesOptions,
  lawOfficeInsuranceTypesOptions,
  caseInsuranceValuesTypesMap
} from './insuranceDataMaps';
import { InsuranceSubscriberForm } from './InsuranceSubscriberForm';
import { UserForm } from './UserForm';

export const MainInfo = ({ mainPage, user = null, onCaseItemUpdate = () => {} }) => {
  const { openModal } = useModal();
  const { isSubmitting, values, setFieldValue } = useFormikContext();
  const isWcOrPi = [ insuranceTypes.wc, insuranceTypes.pi ].includes(values?.type_insurance);
  const isCaseIns = Object.values(caseInsuranceValuesTypesMap).includes(values?.type_insurance);
  const isCreateAttorney = (values?.attorney_phone || values?.attorney_first_name) &&
    !!values.medicalFormID && !values.attorney_id;
  const isNewAttorney = (values?.attorney_first_name || values?.insuranceInfo?.attorney_first_name)
    && !values.attorney_id;
  const initialValues = {
    role: rolesMap.advocate,
    email: values?.attorney_email || values?.insuranceInfo?.attorney_email,
    first_name: values?.attorney_first_name || values?.insuranceInfo?.attorney_first_name,
    last_name: values?.attorney_last_name || values?.insuranceInfo?.attorney_last_name,
    work: {
      company_id: values?.insurance_company_id,
      office_id: values.office && [ values.office ],
      position: rolesMap.advocate
    }
  };

  const handleToggleStatus = (event) => {
    const value = event.target.value;

    setFieldValue('is_main', isPrimary(event.target.value));
    setFieldValue('insurance_company_id', null);

    if (value === insuranceStatusTypesMap.law_office) {
      setFieldValue('type_insurance', lawOfficeInsuranceTypesOptions?.[0]?.value);
    } else if (value === insuranceStatusTypesMap.case_insurance) {
      setFieldValue('type_insurance', caseInsuranceTypesOptions?.[0]?.value);
    } else {
      setFieldValue('type_insurance', insuranceTypesOptions?.[1]?.value);
    }
  };

  const addAttorney = () => {
    openModal(ApproveAttorneyModal, {
      payload: {
        medicalFormID: values.medicalFormID,
        attorneyData: {
          first_name: values.attorney_first_name,
          last_name: values.attorney_last_name,
          phone_number: values.attorney_phone,
          email: values.attorney_email
        }
      },

      onModalResolved: (attorney) => {
        setFieldValue('attorney_id', attorney);
      }
    });
  };

  const handleCompanyChange = () => {
    setFieldValue('office_id', null);
    setFieldValue('attorney_id', null);
  };

  useEffect(() => {
    if (values.status_type) {
      setFieldValue('is_main', isPrimary(values.status_type));
    }
  }, []);

  return (
    <Grid container spacing={2} component={Box} pb={3}>
      <Header handleToggleStatus={handleToggleStatus} />

      {isCaseIns ? (
        <CaseInsurance mainPage={mainPage} user={user} onCaseItemUpdate={onCaseItemUpdate} />
      ) : (
        <>
          {values?.caseItem?.id && <UserForm mainPage={mainPage} onCaseItemUpdate={onCaseItemUpdate} />}

          {mainPage && !values?.caseItem?.id && (
            <InsuranceSubscriberForm />
          )}

          <Grid item sm={6} xs={12}>
            <CompaniesAutocomplete
              required
              isCreatable={!isSubmitting}
              disableValueTransform={isWcOrPi}
              name="insurance_company_id"
              label="Insurance company / Law Office"
              margin="dense"
              insuranceInfo={{
                ...values?.insuranceInfo,

                type_insurance: values?.type_insurance,
                status_type: values.status_type
              }}
              params={{ company_type_id: insuranceTypesMap?.[values?.type_insurance] }}
              onChange={handleCompanyChange}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <OfficesLocationSelect
              required
              isCreatable
              disabled={!values?.insurance_company_id}
              name="office_id"
              label="Office"
              margin="dense"
              attorneyInfo={values?.insuranceInfo}
              params={{
                company_id: values?.insurance_company_id?.id || values?.insurance_company_id
              }}
              companyPayload={values?.insurance_company_id?.data}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            {values.status_type !== insuranceStatusTypesMap.law_office ? (
              <TextField
                name="insuranceId"
                label="Insurance ID"
                margin="dense"
              />
            ) : isCreateAttorney && (
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  {values?.attorney_phone ? (
                    <PhoneField
                      name="attorney_phone"
                      label="Attorney Phone"
                    />
                  ) : (
                    <Box pt={1}>
                      <Typography>{values?.attorney_first_name} {values.attorney_last_name}</Typography>
                    </Box>
                  )}
                </Box>

                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon/>}
                  onClick={addAttorney}
                >
                  Add attorney
                </Fab>
              </Box>
            )}
          </Grid>

          {isWcOrPi && isNewAttorney && (
            <>
              <Grid item sm={3} xs={12}>
                <TextField
                  name="attorney_first_name"
                  label="Attorney First Name"
                />
              </Grid>

              <Grid item sm={3} xs={12}>
                <TextField
                  name="attorney_last_name"
                  label="Attorney Last Name"
                />
              </Grid>

              <Grid item sm={6} xs={12} />
            </>
          )}

          {isWcOrPi && (
            <Grid item sm={6} xs={12}>
              <UsersSelect
                isCreatable={!values.attorney_id}
                disabled={!values?.office_id}
                name="attorney_id"
                label="Attorney"
                params={{
                  role: 'advocate',
                  office_id: values.office_id?.value || values.office_id,
                  company_id: values.insurance_company_id?.id
                }}
                creatablePayload={{ initialValues }}
                margin="dense"
              />
            </Grid>
          )}

          {!isWcOrPi &&
            <>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="groupId"
                  label="Group ID"
                  margin="dense"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <UsersSelect
                  name="provider_id"
                  label="PCP/Ref Provider"
                  params={{ role: 'doctor' }}
                  margin="dense"
                />
              </Grid>

              <Hidden only="xs">
                <Grid item sm={6} />
              </Hidden>

              <Grid item sm={6} xs={12}>
                <CurrencyField
                  name="pcp"
                  label="PCP, $"
                  margin="dense"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <CurrencyField
                  name="spc"
                  label="SPC, $"
                  margin="dense"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <FinancialClassesSelect
                  name="financial_class_id"
                  label="Financial class"
                  TextFieldProps={{ margin: 'dense' }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <CompaniesAutocomplete
                  isCreatable
                  name="tpl_id"
                  label="TPL"
                  margin="dense"
                  params={{ company_type_id: companyTypeIDsMap.insurance }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  name="effective_day"
                  label="Effective day"
                  placeholder="Select a date"
                  margin="dense"
                  maxDate={moment().unix()}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  minDate={moment.unix(values.effective_day).add(1, 'day').unix()}
                  name="expiration_day"
                  label="Expiration day"
                  placeholder="Select a date"
                  margin="dense"
                />
              </Grid>
            </>
          }
        </>
      )}
    </Grid>
  );
};
