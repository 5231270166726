import { Box, Typography } from '@material-ui/core';
import { IcdCodesSelect, TextField } from '../../../../../../../components/FormField';

export const DiagnosticStudies = () => {
  return (
    <>
      <Box mt={3}>
        <Typography paragraph variant="h4">
          Diagnostic studies
        </Typography>
      </Box>

      <TextField
        fullWidth
        multiline
        disableUnderline
        rows={2}
        name="forms.diagnostic_studies.text"
        variant="filled"
      />

      <Box maxWidth={530} my={3}>
        <IcdCodesSelect
          multiple
          name="forms.diagnostic_studies.icd"
          label="Diagnosis(DX)"
        />
      </Box>
    </>
  );
};
