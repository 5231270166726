import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_FILES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_FILES_SUCCESS]: (
    { filter, files, ...state },
    { data: newFiles, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      files: (page > 1 ?
        files.filter(({ id }) => !newFiles.find((loadedFile) => id === loadedFile.id)).concat(newFiles)
        :
        newFiles
      )
    };
  },

  [types.RESET_FILES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_FILES]: (state, payload) => {
    const { pagination, filter, files, ...otherState } = state;
    let suitableFiles = payload;

    if (!suitableFiles.length) {
      return state;
    }

    const total = pagination.total + suitableFiles.length;
    const page = Math.ceil((files.length + suitableFiles.length) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      files: suitableFiles.concat(files)
    };
  },

  [types.DELETE_FILES]: ({ pagination, filter, files, ...state }, filesIDs) => {
    const deletedFiles = files.filter((file) => filesIDs.find((id) => id === file.id));
    const total = pagination.total - deletedFiles.length;
    const page = Math.ceil((files.length - deletedFiles.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      files: files.filter((file) => !deletedFiles.find(({ id }) => id === file.id))
    };
  }
});
