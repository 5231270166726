import { Box } from '@material-ui/core';
import { caseUserRolesTypesMap } from '../../../dataMaps';
import { useModal } from '../../ModalsProvider';
import { UserPreviewModal } from '../../users';
import { UserLink } from '../../UserLink';

export const UserActivity = ({ deletedUser = false, payload }) => {
  const { openModal } = useModal();
  const user = payload?.user;
  const role = caseUserRolesTypesMap?.[payload?.properties?.after?.case_role] || '-';

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  if (deletedUser) {
    return (
      <Box component="span" display="flex" alignItems="center">
        Removed user
       &nbsp;
        <UserLink
          noAvatar
          color="primary"
          variant="subtitle1"
          user={user}
          onClick={openUserPreview(user?.id)}
        />
        &nbsp;
        with role
        &nbsp;
        <b>{role}</b>
      </Box>
    );
  } else {
    return (
      <Box component="span" display="flex" alignItems="center">
        Added user
        &nbsp;
        <UserLink
          noAvatar
          color="primary"
          variant="subtitle1"
          user={user}
          onClick={openUserPreview(user?.id)}
        />
        &nbsp;
        with role
        &nbsp;
        <b>{role}</b>
      </Box>
    );
  }
};
