import { makeStyles } from '@material-ui/core';
import { useMessageContent } from '../../../../../../helpers/hooks';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SystemMessageItem = ({ message }) => {
  const classes = useStyles();
  const messageContent = useMessageContent(message);

  return (
    <div className={classes.root}>
      {messageContent}
    </div>
  );
};
