export const styles = ({
  spacing,
  palette: { grey, primary },
  typography: { pxToRem },
  transitions,
  shadows
}) => ({
  imageInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: ({ size = 180 }) => size,
    height: ({ size = 180 }) => size,
    margin: `${spacing(2)}px auto`,
    borderRadius: '100%',
    boxShadow: shadows[2],
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.35)'
    }
  },

  imageInfo__icon: {
    color: grey[200],
    fontSize: pxToRem(36)
  },

  imageInfo__button: {
    fontWeight: 'normal',
    marginTop: spacing(),
    color: primary.main
  },

  imageInfo__buttonTitle: {
    fontSize: pxToRem(14)
  }
});
