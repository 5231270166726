import { createContext, useRef, useEffect, useReducer } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { usePrevious } from '../../../../helpers/hooks';
import { api } from '../../../../api';
import * as authorizationsApi from '../../../../api/appointments/authorization';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const AuthorizationsContext = createContext();

export const AuthorizationsProvider = ({ filter: filterProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    authorizations,
    selectedAuthorizationsIDs,
    selectedAuthorizations
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchAuthorizations = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_AUTHORIZATIONS_REQUEST });

    authorizationsApi.fetchAuthorizations({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_AUTHORIZATIONS_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchAuthorizations({ page: filter.page + 1 });
    }
  };

  const resetAuthorizations = (newFilter) => {
    dispatch({ type: types.RESET_AUTHORIZATIONS, payload: newFilter });

    fetchAuthorizations({ page: 1, ...newFilter });
  };

  const addAuthorizationsToSelected = (authorizations) => {
    dispatch({ type: types.ADD_AUTHORIZATIONS_TO_SELECTED, payload: authorizations });
  };

  const deleteAuthorizationsFromSelected = (authorizationsIDs) => {
    dispatch({ type: types.DELETE_AUTHORIZATIONS_FROM_SELECTED, payload: authorizationsIDs });
  };

  const toggleAuthorizationSelected = (authorization) => {
    if (selectedAuthorizationsIDs.indexOf(authorization.id) === -1) addAuthorizationsToSelected([ authorization ]);
    else deleteAuthorizationsFromSelected([ authorization.id ]);
  };

  const allAuthorizationsIsSelected = () => {
    return selectedAuthorizationsIDs
      .filter((id) => authorizations.find((authorization) => authorization.id === id))
      .length === authorizations.length;
  };

  const toggleAllAuthorizationsSelected = () => {
    const authorizationsIDs = authorizations.map(({ id }) => id);

    if (allAuthorizationsIsSelected()) {
      deleteAuthorizationsFromSelected(authorizationsIDs);
    } else {
      addAuthorizationsToSelected(authorizations);
    }
  };

  const updateAuthorization = (authorization) => {
    dispatch({ type: types.UPDATE_AUTHORIZATION, payload: authorization });
  };

  const deleteAuthorization = (authorization) => {
    dispatch({ type: types.DELETE_AUTHORIZATION, payload: authorization.id });
  };

  const providerValue = {
    isFetched,
    isFetching,
    authorizations,
    selectedAuthorizationsIDs,
    selectedAuthorizations,
    filter: filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    resetAuthorizations,
    fetchAuthorizations,
    loadNextPage,
    applyFilter,
    updateAuthorization,
    deleteAuthorization,
    toggleAuthorizationSelected,
    deleteAuthorizationsFromSelected,
    allAuthorizationsIsSelected,
    toggleAllAuthorizationsSelected
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      resetAuthorizations(newFilter);
    }
  }, [ filter, filterProp ]);


  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ] );

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetAuthorizations(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <AuthorizationsContext.Provider value={providerValue}>
      {children}
    </AuthorizationsContext.Provider>
  );
};
