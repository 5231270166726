import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles, Typography, Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { ActionButtons } from '../../../../../components/medical/forms';
import * as formsApi from '../../../../../api/profile';
import { Divider } from '../../../../../components/Divider';
import { Loader } from '../../../../../components/Loader';
import {
  CheckboxesContent
} from '../../../ProfilePage/MedicalInfo/MedicalForms/PersonalInjuryOrtho/ReviewSystems/CheckboxesContent';
import { FormContext } from '../../FormProvider';
import { validationSchema } from './validationSchema';
import { styles } from '../../styles';

const useStyles = makeStyles(styles);

export const ReviewSystems = () => {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetched, form, handleComplete, handleBack } = useContext(FormContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updateGuestReviewOfSystems(values).then(({ data }) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleComplete(data);
    }).catch(({ errors }) => {
      setErrors(errors);
      enqueueSnackbar('Form not created', { variant: 'error' });
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            token: form?.token || null,
            form_id: form?.id,
            forms: {
              review_of_orthopedic_symptoms: form?.forms?.review_of_orthopedic_symptoms || {}
            }
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <Grid
                container
                spacing={isSmallScreen ? 2 : 3}
                alignItems="flex-start"
                component={Box}
                pb={3}
                m="0 !important"
                width="100% !important"
              >
                <Grid item xs={12}>
                  <Typography variant="h5" color="secondary">
                    Review of Symptoms
                  </Typography>
                </Grid>

                <CheckboxesContent />
              </Grid>

              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
