import { useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths, columnsWidthsSm } from '../List';

export const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const actionsFlexBasis = !isMobile ? columnsWidths.actions : columnsWidthsSm.actions;

  return (
    <ListRow header>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        Book name
      </ListRowCell>

      {!isMobile && (
        <ListRowCell grow flexBasis={columnsWidths.location}>
          Location
        </ListRowCell>
      )}

      <ListRowCell flexBasis={actionsFlexBasis} />
    </ListRow>
  );
};
