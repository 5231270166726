import { useContext, useEffect, useRef, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { Page, PageBody, PageHeader } from '../../../../../components/Page';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const BREAKPOINT_WIDTH = 1000;

export const PatientsContent = ({ filterKey }) => {
  const classes = useStyles();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});
  const [ savedFilter, setSavedFilter ] = useState({});
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  useEffect(() => {
    if (width < BREAKPOINT_WIDTH) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width ]);

  return (
    <Page elevation={0} ref={rootRef}>
      {/*TODO: for future settings*/}
      {/*<PageHeader disableBorderBottom ToolbarProps={{ className: classes.toolbar }}>*/}
      {/*  <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: 135, width: '100%' }}>*/}
      {/*    <PatientsCard />*/}
      {/*  </CustomScrollbars>*/}
      {/*</PageHeader>*/}

      <PageHeader disablePaddings>
        <ActionsBar
          width={width}
          savedFilter={savedFilter}
          filterOpenButton={filterOpenButton}
          handleSetRelationsForFilter={setRelationsForFilter}
        />
      </PageHeader>

      <PageBody fullHeight disablePaddings disableScrollbars>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          overflow="hidden"
          className={classes.listContent}
        >
          <ScrollWrapper {...scrollWrapperProps}>
            <FilterBarWrapper {...filterBarWrapperProps}>
              <FiltersBar
                filterKey={filterKey}
                relationsForFilter={relationsForFilter}
                handleSetRelationsForFilter={setRelationsForFilter}
                onSavedFilterUpdate={setSavedFilter}
              />
            </FilterBarWrapper>
          </ScrollWrapper>

          {(viewVariant === viewVariantsMap.list) && <List />}
          {(viewVariant === viewVariantsMap.grid) && <Grid />}
        </Box>
      </PageBody>
    </Page>
  );
};
