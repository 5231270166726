import { useFormikContext } from 'formik';
import { Grid, makeStyles, Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { Fab } from '../../../../../../components/Fab';
import { useModal } from '../../../../../../components/ModalsProvider';
import { AddTypesModal } from './AddTypesModal';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AvailableApptTypes = ({ formFieldName }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const addTypes = () => {
    openModal(AddTypesModal, {
      onModalResolved: (newTypes) => {
        const filteredTypes = newTypes.reduce((filteredTypes, type) => {
          const isNewType = !values?.[formFieldName]?.find(({ type: { id } }) => type.id === id);

          return isNewType
            ? filteredTypes.concat({ type, appointment_type_id: type.id, is_current_active: true })
            : filteredTypes;
        }, []);

        setFieldValue(formFieldName, [ ...(values[formFieldName] || []), ...filteredTypes ]);
      }
    });
  };

  const toggleActive = (togglingTypeID) => {
    setFieldValue(
      formFieldName,
      values[formFieldName]?.map((type) => togglingTypeID !== type.appointment_type_id
        ? type
        : ({ ...type, is_current_active: !type.is_current_active })
      )
    );
  };

  const deleteType = (type) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setFieldValue(
          formFieldName,
          values[formFieldName]?.filter(({ appointment_type_id }) => appointment_type_id !== type.id)
        );
      }
    });
  };

  return (
    <div className={classes.root}>
      <Box mt={4} mb={2}>
        <Grid container spacing={1} alignItems="center" component={Box} pb={1}>
          <Grid item>
            <Typography variant="h3">Available appointment types</Typography>
          </Grid>

          <Grid item>
            <Fab
              size={isMobile ? 'small' : 'medium'}
              color="primary"
              variant="extended"
              startIcon={<AddIcon />}
              onClick={addTypes}
            >
              Add appt. type
            </Fab>
          </Grid>
        </Grid>

        <Typography variant="body1" color="textSecondary">
          Selected types are available for this book
        </Typography>
      </Box>

      {!!(values?.[formFieldName]?.length) &&
        <List types={values?.[formFieldName]} onTypeDelete={deleteType} onToggleActive={toggleActive} />
      }
    </div>
  );
};
