import moment from 'moment';
import { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { Loader } from '../../../../Loader';
import { feedMessages } from '../../../feedMessages';
import { columnsWidths } from '../listConfig';

export const Row = ({
  item: feed = {},
  isLoaded,
  recalculateHeight
}) => {
  const message = feedMessages[feed.type];

  useEffect(() => {
    if (isLoaded) {
      recalculateHeight();
    }
  }, [ isLoaded, recalculateHeight ]);

  return !isLoaded ? <Loader p={1} /> : !!message && (
    <Paper elevation={0}>
      <ListRow px={2}>
        <ListRowCell flexBasis={columnsWidths.date}>
          {moment.unix(feed.created_at).format('L LT')}
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.label}>
          {message({ data: feed.data })}
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
