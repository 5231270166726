export const styles = ({ spacing, palette }) => ({
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: spacing(4),
    marginBottom: spacing(2),
    borderBottom: `1px solid ${palette.slategray}`
  },

  title: {
    color: palette.slategray
  }
});
