const sidebarWidth = 280;

export const styles = ({ palette, zIndex, transitions }) => ({
  paper: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  },

  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden'
  },

  preview: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden'
  },

  list: {
    flexShrink: 0,
    overflow: 'hidden',
    position: 'relative',
    width: sidebarWidth,
    background: palette.common.white,
    boxShadow: '-4px 0px 11px rgba(0, 0, 0, 0.25)',
    zIndex: zIndex.appBar,
    transition: transitions.create()
  },

  list_collapsed: {
    marginRight: -sidebarWidth
  },

  loader: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  }
});
