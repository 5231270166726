import { useContext } from 'react';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { Tags } from '../../../../components/Tags';
import { TagsProvider } from '../../files-common';
import { FilesFilterContext } from '../../files-common';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const LeftSidebar = ({ isOpenSidebar = false, toggleSideBar = () => {} }) => {
  const filesFilterContext = useContext(FilesFilterContext);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const handleChangeTags = (tags) => {
    filesFilterContext.setFilter((filter) => ({ ...filter, tags: tags.map(({ value }) => value) }));
    filesFilterContext.setRelationsForFilter((state) => ({
      ...state,

      tags: tags.map(({ label: tag }) => tag)
    }));
  };

  return (
    <div className={classes.root}>
      {!isMobile && (
        <div className={classes.tags}>
          <TagsProvider filter={{ is_active: true }}>
            <Tags
              isOpenSidebar={isOpenSidebar}
              selectedTagIds={filesFilterContext.filter.tags}
              toggleSideBar={toggleSideBar}
              onTagsChange={handleChangeTags}
            />
          </TagsProvider>
        </div>
      )}
    </div>
  );
};
