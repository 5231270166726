import T from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  title: T.string
};

export const PopperFooter = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {title &&
        <Typography variant="subtitle2">
          {title}
        </Typography>
      }

      <div className={classes.actions}>
        {children}
      </div>
    </div>
  );
};

PopperFooter.propTypes = propTypes;
