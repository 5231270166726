import moment from 'moment';
import { makeStyles, Box } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ExpirationDate = ({ date }) => {
  const classes = useStyles();
  const expirationDay = moment.unix(date).add(1, 'd').startOf('day');
  const expirationLeft = expirationDay.diff(moment(), 'days');
  let color;

  if (expirationLeft >= 14) {
    color = 'success.main';
  } else if (expirationLeft < 14 && expirationLeft > 0) {
    color = 'amberyellow';
  } else {
    color = 'error.main';
  }

  return date && (
    <>
      <Box bgcolor={color} component="span" className={classes.date}>
        {expirationDay.format('MM/DD/YYYY')}
      </Box>

      <Box color={color} component="span" className={classes.dateLeft}>
        <InfoOutlinedIcon className={classes.infoIcon} />
        {expirationLeft > 0 ? `${expirationLeft} days left` : 'Expired'}
      </Box>
    </>
  );
};
