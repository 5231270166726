import { IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Popper } from '../../Popper';
import { sidebarTypesMap } from '../../../app/Dashboard/MessengerPage/ChatThread/ChatAbout/sidebarTypesMap';

export const InfoDropDownMenu = ({ aboutTab, onAboutTabChange }) => {
  const handleAboutTabChange = (tab, handleClose) => () => {
    onAboutTabChange(tab);
    handleClose();
  };

  const isCurrentTab = (tab) => {
    return aboutTab === tab;
  };

  return (
    <Popper anchorRenderer={
      ({ anchorRef, handleToggle }) => (
        <IconButton
          color="inherit"
          buttonRef={anchorRef}
          onClick={handleToggle}
        >
          <InfoOutlinedIcon />
        </IconButton>
      )}
    >
      {({ handleClose }) => (
        <List
          gutters={2}
          onClose={handleClose}
        >
          <ListItem
            button
            selected={isCurrentTab(sidebarTypesMap.info)}
            onClick={handleAboutTabChange(sidebarTypesMap.info, handleClose)}
          >
            <ListItemIcon>
              <NotesIcon />
            </ListItemIcon>

            <ListItemText primary="About this channel" />
          </ListItem>

          <ListItem
            button
            selected={isCurrentTab(sidebarTypesMap.files)}
            onClick={handleAboutTabChange(sidebarTypesMap.files, handleClose)}
          >
            <ListItemIcon>
              <FileCopyOutlinedIcon />
            </ListItemIcon>

            <ListItemText primary="Files" />
          </ListItem>
        </List>
      )}
    </Popper>
  );
};
