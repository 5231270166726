export const fieldsKeysMap = {
  insuranceId: 'insuranceId',
  insuranceCompanyId: 'insurance_company_id',
  groupId: 'groupId',
  orderBy: 'order_by',
  typeInsurance: 'type_insurance',
  expirationDay: 'expiration_day',
  effectiveDay: 'effective_day',
  statusType: 'status_type'
};

export const filterLabels = {
  [fieldsKeysMap.orderBy]: 'Order By',
  [fieldsKeysMap.insuranceCompanyId]: 'Insur. Company',
  [fieldsKeysMap.insuranceId]: 'Insurance ID',
  [fieldsKeysMap.groupId]: 'Group ID',
  [fieldsKeysMap.typeInsurance]: 'Insurance type',
  [fieldsKeysMap.expirationDay]: 'Expiration Date',
  [fieldsKeysMap.effectiveDay]: 'Effective Date',
  [fieldsKeysMap.statusType]: 'Status Type'
};
