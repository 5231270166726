import { makeStyles } from '@material-ui/core/styles';
import { animated, useSpring } from '@react-spring/web';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'block'
  },
  wrapperInner: {
    width: 'auto'
  }
}));

export const TransitionComponent = (props) => {
  const classes = useStyles();
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px, 0, 0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px, 0, 0)` }
  });

  return (
    <animated.div style={style}>
      <Collapse classes={classes} {...props} />
    </animated.div>
  );
};
