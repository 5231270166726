import { Box, Grid, Typography } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';

export const Footer = ({ form }) => {
  return (
    <Box display="flex" flexDirection="column" overflow="hidden">
      <Grid
        container
        spacing={3}
        component={Box}
        alignItems="center"
        justify="flex-start"
        py={2}
        m="0 !important"
      >
        {!!form?.what_side_effects_or_symptoms_are_you_having && (
          <>
            <Grid item>
              <Typography variant="h5">
                What <u>side effects</u> or <u>symptoms</u> are you having? Check what best describes your experience
                during the past week? Check all that apply
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" flexWrap="wrap" justifyContent="flex-start" px={2}>
                {!!form?.what_side_effects_or_symptoms_are_you_having?.chest_pain && (
                  <Box component="span" mr={2}>
                    <ReadonlyCheckbox checked title="Chest pain" />
                  </Box>
                )}

                {!!form?.what_side_effects_or_symptoms_are_you_having?.cough && (
                  <Box component="span" mr={2}>
                    <ReadonlyCheckbox checked title="Cough" />
                  </Box>
                )}

                {!!form?.what_side_effects_or_symptoms_are_you_having?.nausea && (
                  <Box component="span" mr={2}>
                    <ReadonlyCheckbox checked title="Nausea" />
                  </Box>
                )}

                {!!form?.what_side_effects_or_symptoms_are_you_having?.stomach_pain && (
                  <Box component="span" mr={2}>
                    <ReadonlyCheckbox checked title="Stomach Pain" />
                  </Box>
                )}

                {!!form?.what_side_effects_or_symptoms_are_you_having?.itch && (
                  <Box component="span" mr={2}>
                    <ReadonlyCheckbox checked title="Itch" />
                  </Box>
                )}

                {!!form?.what_side_effects_or_symptoms_are_you_having?.insomnia && (
                  <Box component="span" mr={2}>
                    <ReadonlyCheckbox checked title="Insomnia" />
                  </Box>
                )}

                {!!form?.what_side_effects_or_symptoms_are_you_having?.depression && (
                  <Box component="span" mr={2}>
                    <ReadonlyCheckbox checked title="Depression" />
                  </Box>
                )}

                {!!form?.what_side_effects_or_symptoms_are_you_having?.sweating && (
                  <Box component="span" mr={2}>
                    <ReadonlyCheckbox checked title="Sweating" />
                  </Box>
                )}
              </Box>
            </Grid>
          </>
        )}

        {!!form?.please_list_any_new_medication_added_since_the_last_visit && (
          <>
            <Grid item>
              <Typography variant="h5">
                Please list any new medication added since the last visit
              </Typography>
            </Grid>

            <Grid item lg={6} xs={11}>
              <Typography color="textSecondary">
                {form?.please_list_any_new_medication_added_since_the_last_visit}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
