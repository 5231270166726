import T from 'prop-types';
import { withStyles, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AppBar } from '../AppBar';
import { VerticalDivider } from '../VerticalDivider';
import { styles } from '../Modal/styles';

const enhancer = withStyles(styles);

const propTypes = {
  icon: T.oneOfType([ T.node, T.string ]),
  onClose: T.func
};

export const PopperHeader = enhancer(({
  icon,
  actionButton,
  children,
  classes,
  onClose
}) => {
  return (
    <AppBar>
      <Typography variant="h6" className={classes.title}>
        {icon &&
          <div className={classes.icon}>
            {icon}
          </div>
        }

        {children}
      </Typography>

      {!!actionButton &&
        <>
          {actionButton}

          <VerticalDivider verticalGutters={1} horizontalGutters={2} />
        </>
      }

      {!!onClose &&
        <IconButton
          className={classes.closeIcon}
          edge="end"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      }
    </AppBar>
  );
});

PopperHeader.propTypes = propTypes;
