import { useMemo } from 'react';
import moment from 'moment';
import { makeStyles, Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { Date } from '../../../../../../../../components/Date';
import { Procedures } from './Procedures';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& + &': {
      marginTop: spacing(2),
      paddingTop: spacing(),
      borderTop: `1px dashed ${palette.divider}`
    }
  }
}));

export const Appointment = ({ appointment }) => {
  const classes = useStyles();
  const proceduresPrice = useMemo(() => {
    return appointment.procedures?.reduce((result, { price }) => result + (price || 0), 0);
  }, [ appointment.id ]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" mb={1}>
        <Date date={appointment.appointment_at} format="L, LT" variant={isMobile ? 'subtitle2' : 'h3'} />

        <Box ml={2}>
          <Typography variant={isMobile ? 'subtitle2' : 'h3'}>
            <Box clone mr={1}>
              <TimelapseIcon />
            </Box>

            {moment.duration(appointment.time, 'm').humanize()}
          </Typography>
        </Box>

        {!!appointment.procedures?.length &&
          <>
            <Box ml="auto" mr={2}>
              <Typography variant={isMobile ? 'subtitle2' : 'h3'}>
                Summary Total:
              </Typography>
            </Box>

            <Box color="secondary.main">
              <Typography variant={isMobile ? 'subtitle2' : 'h3'} color="inherit">
                <CurrencyFormat value={proceduresPrice} />
              </Typography>
            </Box>
          </>
        }
      </Box>

      {!!appointment.procedures?.length &&
        <Procedures appointment={appointment} procedures={appointment.procedures} />
      }
    </div>
  );
};
