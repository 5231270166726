import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  List,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import PlayIcon from '@material-ui/icons/PlayArrowOutlined';
import StopIcon from '@material-ui/icons/Stop';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Tooltip } from '../../../../../components/Tooltip';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { jobsTypes } from '../../../../../store/socketJobs';
import { activityTimerStart, activityTimerStop } from '../../../../../store/dashboard/activityTimer';
import { addSocketJobID } from '../../../../../store/socketJobs';
import { hasRole } from '../../../../../utils/hasRole';
import * as filesApi from '../../../../../api/files';
import { useModal } from '../../../../../components/ModalsProvider';
import { CasesContext } from '../CasesContext';
import { CaseViewModal } from '../CaseViewModal';

const styles = () => ({
  root: {
    minWidth: 172
  }
});

const useStyles = makeStyles(styles);

export const CaseMenu = ({
  caseItem,
  onClose = () => {}
}) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const dispatch = useDispatch();
  const activeTimer = useSelector(({ activityTimer }) => activityTimer.activeTimer);
  const { openModal } = useModal();
  const classes = useStyles();
  const casesContext = useContext(CasesContext);
  const isPlaying = activeTimer && (activeTimer.model === 'case' && activeTimer.model_id === caseItem.id);
  const isCreator = !!caseItem?.case_users?.find((caseUser) => caseUser?.user?.id === currentUser?.id);

  const deleteCase = () => {
    casesContext.deleteCase(caseItem.id);
  };

  const toggleOpen = () => {
    casesContext.toggleOpen(caseItem);
  };

  const toggleFavorite = () => {
    casesContext.toggleFavorite(caseItem);
    onClose();
  };

  const openPreview = () => {
    openModal(CaseViewModal, {
      payload: { id: caseItem.id }
    });
  };

  const handleStartTimer = () => {
    dispatch(activityTimerStart({ model: 'case', model_id: caseItem.id }));
  };

  const handleStopTimer = () => {
    dispatch(activityTimerStop());
  };

  const downloadFiles = () => {
    filesApi.createArchive({ cases: [ caseItem.id ] }).then(({ job_id }) => {
      dispatch(addSocketJobID({ type: jobsTypes.archives, jobID: job_id }));
    });
  };

  return (
    <List className={classes.root}>
      <MenuItem onClick={openPreview}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.doctor, rolesMap.medic) &&
        <>
          <MenuItem
            component={Link}
            to={`/cases/edit/${caseItem.id}`}
          >
            <ListItemIcon>
              <EditIcon fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit" />
          </MenuItem>

          <MenuItem onClick={downloadFiles}>
            <ListItemIcon>
              <DownloadIcon fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Download files" />
          </MenuItem>

          <Divider />

          {!isPlaying &&
            <MenuItem onClick={handleStartTimer}>
              <ListItemIcon>
                <PlayIcon fontSize="inherit" />
              </ListItemIcon>

              <ListItemText primary="Start work" />
            </MenuItem>
          }

          {isPlaying &&
            <MenuItem onClick={handleStopTimer}>
              <ListItemIcon>
                <StopIcon fontSize="inherit" />
              </ListItemIcon>

              <ListItemText primary="Stop work" />
            </MenuItem>
          }

          <Tooltip isExistTooltip={!isCreator} title="Available only for the case creator">
            <div>
              <MenuItem disabled={!isCreator} onClick={toggleFavorite}>
                <ListItemIcon>
                  {caseItem.is_favorite
                    ? <StarIcon fontSize="inherit" />
                    : <StarBorderIcon fontSize="inherit" />
                  }
                </ListItemIcon>

                <ListItemText primary="Toggle favorite" />
              </MenuItem>
            </div>
          </Tooltip>

          <MenuItem onClick={toggleOpen}>
            <ListItemIcon>
              {caseItem.is_opened
                ? <LockOpenIcon fontSize="inherit" />
                : <LockIcon fontSize="inherit" />
              }
            </ListItemIcon>

            <ListItemText primary={caseItem.is_opened ? 'Close case': 'Open case'} />
          </MenuItem>

          <Divider />

          <MenuItem disabled={!!caseItem.is_opened} onClick={deleteCase}>
            <ListItemIcon>
              <DeleteIcon fontSize="inherit"/>
            </ListItemIcon>

            <ListItemText primary="Delete case" />
          </MenuItem>
        </>
      }
    </List>
  );
};
