import { filtersKeysMap } from '../../../../store/lastFilters';
import { AppointmentTypes } from './AppointmentTypes';
import { AppointmentBooks } from './AppointmentBooks';
import { ArrivalStatuses } from './ArrivalStatuses';
import { VisitReasons } from './VisitReasons';

export const routes = [
  {
    exact: true,
    pathName: '/arrival-statuses',
    label: 'Arrival statuses',
    component: ArrivalStatuses
  },
  {
    exact: true,
    pathName: '/appointment-books',
    label: 'Appointment books',
    component: () => <AppointmentBooks filterKey={filtersKeysMap.appointments_book} />
  },
  {
    exact: true,
    pathName: '/appointment-types',
    label: 'Appointment types',
    component: AppointmentTypes
  },
  {
    exact: true,
    pathName: '/visit-reasons',
    label: 'Visit reasons',
    component: VisitReasons
  }
];
