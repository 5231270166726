export const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const RESET_MESSAGES = 'RESET_MESSAGES';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const ADD_CASE_TO_MESSAGES = 'ADD_CASE_TO_MESSAGES';
export const DELETE_CASE_FROM_MESSAGES = 'DELETE_CASE_FROM_MESSAGES';
export const ADD_PATIENT_TO_MESSAGES = 'ADD_PATIENT_TO_MESSAGES';
export const DELETE_PATIENT_FROM_MESSAGES = 'DELETE_PATIENT_FROM_MESSAGES';
export const ADD_TAGS_TO_MESSAGES = 'ADD_TAGS_TO_MESSAGES';
export const REPLACE_TAGS_TO_MESSAGES = 'REPLACE_TAGS_TO_MESSAGES';
export const DELETE_TAGS_FROM_MESSAGES = 'DELETE_TAGS_FROM_MESSAGES';
export const APPLY_FILTER = 'APPLY_FILTER';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const SET_ONE_MESSAGE = 'SET_ONE_MESSAGE';
