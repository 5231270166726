export const styles = ({ spacing, palette, typography: { fontWeightMedium } }) => ({
  root: {
    marginTop: spacing(2)
  },

  noteIcon: {
    display: 'flex',
    alignItems:'center',
    fontWeight: fontWeightMedium,
    color: palette.grey[600]
  },

  datepicker: {
    maxWidth: 150,
    marginLeft: spacing()
  }
});
