import { useState } from 'react';
import { isEqual } from 'lodash';
import {
  Tab,
  Box,
  makeStyles,
  Tooltip,
  SvgIcon
} from '@material-ui/core';
import { SettingsBackupRestore as BackupIcon } from '@material-ui/icons';
import { mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Billing as BillingContent } from '../../../../../../../../components/Widgets/Billing';
import { PaymentsContextProvider } from '../../../../../../../../components/billing/payments';
import { FiltersBarModal } from '../../../../../../../../components/FiltersBarModal';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Tabs } from '../../../../../../../../components/Tabs';
import {
  MainContent as WidgetMainContent
} from '../../../../../../BillingPage/Dashboard/Widgets/Payment/MainContent';
import { InvoicesContextProvider } from '../../../../../../BillingPage/Invoices/InvoicesContext';
import { fieldsMap, initialFilters, tabsMap } from './Filters';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = ({ caseItem }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const [ activeTab, setActiveTab ] = useState(tabsMap.invoices);
  const [ filters, setFilters ] = useState(initialFilters);
  const [ transformedFilters, setTransformedFilters ] = useState(initialFilters);

  const handleActiveTabChange = (event, index) => {
    setActiveTab(index);
  };

  const handleResetFilter = (name) => () => {
    if (!name) return;

    setFilters({ ...filters, [name] : initialFilters?.[name] });
    setTransformedFilters({ ...transformedFilters, [name] : initialFilters?.[name] });
  };

  const handleFiltersChange = (name) => () => {
    if (!name) return;

    openModal(FiltersBarModal, {
      payload: {
        title: `${name} Filters`,
        initialValues: {
          ...fieldsMap?.[name]?.initialValues,
          ...filters[name]
        },
        fields: fieldsMap?.[name]?.fields
      },
      onModalResolved: (values) => {
        const filterValues = fieldsMap?.[name]?.getFiltersValues(values);

        setFilters({
          ...filters,
          [name]: {
            ...initialFilters?.[name],
            ...values
          }
        });
        setTransformedFilters({
          ...transformedFilters,
          [name]: {
            ...initialFilters?.[name],
            ...filterValues
          }
        });
      }
    });
  };

  return (
    <>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        value={activeTab}
        onChange={handleActiveTabChange}
      >
        {Object.values(tabsMap).map((value) => (
          <Tab
            key={value}
            classes={{
              root: classes.root
            }}
            value={value}
            label={(
              <Box display="flex" alignItems="center">
                <Tooltip title="Set Filter">
                  <IconButton
                    component="div"
                    color={activeTab === value ? 'primary' : 'default'}
                    onClick={handleFiltersChange(value)}
                  >
                    <SvgIcon fontSize="small">
                      <Icon path={mdiFilter}/>
                    </SvgIcon>
                  </IconButton>
                </Tooltip>

                <Box px={1}>
                  {value}
                </Box>

                {!isEqual(transformedFilters?.[value], initialFilters?.[value]) && (
                  <Tooltip title="Reset Filter">
                    <IconButton
                      component="div"
                      color="primary"
                      onClick={handleResetFilter(value)}
                    >
                      <BackupIcon fontSize="small"/>
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )}
          />
        ))}
      </Tabs>

      <Box flexGrow={1} display="flex" flexDirection="column">
        {activeTab === tabsMap.invoices && (
          <InvoicesContextProvider
            initialState={{
              filter: {
                case_id: caseItem?.id || null,
                ...transformedFilters?.[tabsMap.invoices]
              }
            }}
          >
            <BillingContent
              isWidgets
              isEqual={isEqual(transformedFilters?.[tabsMap.invoices], initialFilters?.[tabsMap.invoices])}
              filters={transformedFilters?.[tabsMap.invoices]}
              caseItem={caseItem}
            />
          </InvoicesContextProvider>
        )}

        {activeTab === tabsMap.payment && (
          <PaymentsContextProvider
            filter={{
              case_id: caseItem?.id || null,
              ...transformedFilters?.[tabsMap.payment]
            }}
          >
            <WidgetMainContent
              isWidgets
              disableInvoicePreview
              isEqual={isEqual(transformedFilters?.[tabsMap.payment], initialFilters?.[tabsMap.payment])}
              filters={transformedFilters?.[tabsMap.payment]}
            />
          </PaymentsContextProvider>
        )}
      </Box>
    </>
  );
};
