import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Grid, Dialog } from '@material-ui/core';
import * as codesApi from '../../../../../../api/codes/procedures';
import { setCPTCodesLastGlobalAction } from '../../../../../../store/globalActions';
import { TextField } from '../../../../../../components/FormField';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../../../components/Modal';
import { Loader } from '../../../../../../components/Loader';
import * as types from '../CodesContext/types';
import { validationSchema } from './validationSchema';

export const CreateCodeNDCModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    initialCode,
    codesContext
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();

  const createProcedureCode = (code, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    codesApi.createNDCCode(code).then(({ data }) => {
      enqueueSnackbar('Code successfully created', { variant: 'success' });
      handleModalResolve(data);

      if (codesContext.codes.length + 1 > codesContext.filter.per_page) {
        codesContext.fetchCodes({ page: codesContext.filter.page });
      } else {
        dispatchRedux(setCPTCodesLastGlobalAction({ type: types.ADD_CUSTOM_CODE, payload: data }));
      }
    }).catch(({ errors, message }) => {
      setSubmitting(false);

      if (errors) {
        const errorMessage = Object.values(errors);

        errors && setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  const updateCode = (code, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    codesApi.updateNDCCode(code).then((code) => {
      enqueueSnackbar('Code successfully updated', { variant: 'success' });
      dispatchRedux(setCPTCodesLastGlobalAction({ type: types.UPDATE_CODE_IN_LIST, payload: code?.data || code }));
      handleModalResolve(code);
    }).catch(({ errors, message }) => {
      setSubmitting(false);

      if (errors) {
        const errorMessage = Object.values(errors);

        errors && setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={initialCode || {
          code: '',
          qty: '',
          name: '',
          dose: '',
          description: ''
        }}
        validationSchema={validationSchema}
        onSubmit={initialCode ? updateCode : createProcedureCode}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              {initialCode ? 'Update NDC Code' : 'Add New NDC'}
            </ModalHeader>

            <ModalBody>
              <Grid container spacing={2}>
                <Grid item sm={7} xs={12}>
                  <TextField
                    required
                    autoFocus
                    name="code"
                    label="Code NDC"
                  />
                </Grid>

                <Grid item sm={5} xs={12}>
                  <TextField
                    required
                    name="qty"
                    label="Package Quantity"
                  />
                </Grid>

                <Grid item sm={7} xs={12}>
                  <TextField
                    required
                    name="name"
                    label="Drug Name"
                  />
                </Grid>

                <Grid item sm={5} xs={12}>
                  <TextField
                    required
                    name="dose"
                    label="Dose"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    multiline
                    rows={5}
                    variant="filled"
                    name="description"
                    label="Description"
                  />
                </Grid>
              </Grid>

            </ModalBody>

            <ModalFooter>
              <Button type="reset" color="primary" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
