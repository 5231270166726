import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow header>
      <ListRowCell flexBasis={columnsWidths.dateFrom}>
        From
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateTo}>
        To
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.name}>
        Name MRI scan
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.physicianId}>
        Primary Care Physician
      </ListRowCell>


      <ListRowCell flexBasis={columnsWidths.office}>
        Office
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
