import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as profileApi from '../../../../../../../../../api/profile';
import { ConfirmationModal } from '../../../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import { setMedicalProfileLastGlobalAction } from '../../../../../../../../../store/globalActions';
import { SvgIcon } from '../../../../../../../../../components/SvgIcon';
import * as types from '../../../../MedicalProfileProvider/types';
import { EditFamilyContactsModal } from '../../EditFamilyContactsModal';

export const ContactMenu = ({ index, item, medicalProfile, onUpdate, onClose }) => {
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const handleDeleteContact = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        profileApi.updateMedicalProfile({
          id: medicalProfile.id,
          family_tree: medicalProfile.family_tree.filter((item, i) => i !== index)
        }).then((data) => {
          dispatchRedux(setMedicalProfileLastGlobalAction({ type: types.UPDATE_PROFILE, payload: data }));
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(({ errors, message }) => {
          if (errors) {
            const errorMessage = Object.values(errors);

            enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
          }
        });
      }
    });
  };

  const handleOpenEditModal = () => {
    openModal(EditFamilyContactsModal, {
      payload: {
        initialValues: {
          index,
          item
        },
        medicalProfile
      },
      onModalResolved: (data) => {
        onUpdate(data);
      }
    });
  };

  return (
    <List onClick={onClose}>
      <MenuItem onClick={handleOpenEditModal}>
        <ListItemIcon>
          <SvgIcon color="primary"><EditIcon /></SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={handleDeleteContact}>
        <ListItemIcon>
          <SvgIcon color="error"><DeleteIcon /></SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
