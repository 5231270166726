import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Button, Grid, IconButton, Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { setCasesLastGlobalAction } from '../../../../../store/globalActions';
import { Yup } from '../../../../../utils/validation';
import * as casesApi from '../../../../../api/cases';
import { useModal } from '../../../../../components/ModalsProvider';
import { Fab } from '../../../../../components/Fab';
import {
  Page,
  PageHeader,
  PageHeaderTitle,
  PageHeaderActions,
  PageBody
} from '../../../../../components/Page';
import { Loader } from '../../../../../components/Loader';
import * as types from '../CasesContext/types';
import { EAMSIntegrationModal } from './EAMSIntegrationModal';
import { IncidentInformation } from './IncidentInformation';
import { initialValues } from './initialValues';
import { InsuranceClaim } from './InsuranceClaim';
import { transformCaseReq } from './transformCaseReq';
import { validationSchema as caseValidationSchema } from './validationSchema';
import { validationSchema as insClaimValidationSchema } from './insClaimValidationSchema';
import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';
import { Applicant } from './Applicant';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const validationSchema = caseValidationSchema.shape({
  ins_claims: Yup.array().of(insClaimValidationSchema)
});

export const CreateCasePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const history = useHistory();
  const reduxDispatch = useDispatch();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ valueFromAdj, setValueFromAdj ] = useState({});
  const defaultText = 'ADJ';

  const openEAMSIntegrationModal = () => {
    openModal(EAMSIntegrationModal);
  };

  const createCase = (caseData, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.createCase(transformCaseReq(caseData, valueFromAdj)).then((data) => {
      reduxDispatch(setCasesLastGlobalAction({ type: types.ADD_CASE, payload: data }));
      enqueueSnackbar('Case successfully created', { variant: 'success' });
      history.goBack();
    }).catch(({ data: { errors } }) => {
      if (errors) {
        setErrors(errors);
        enqueueSnackbar('Case not created', { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createCase}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form noValidate className={classes.root} onSubmit={handleSubmit}>
          <Page>
            <PageHeader>
              <IconButton
                color="primary"
                edge="start"
                onClick={history.goBack}
              >
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>

              <PageHeaderTitle>Add New Case</PageHeaderTitle>

              <PageHeaderActions>
                <Button color="primary" onClick={history.goBack}>Cancel</Button>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      autoFocus
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />
              </PageHeaderActions>
            </PageHeader>

            <PageBody>
              <Alert icon={false} severity="info">
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" wrap="wrap">
                  <Box mr={2}>
                    <Typography variant="h5">
                      EAMS Integration:
                    </Typography>
                  </Box>

                  <Box mr={2} my={isMobile ? 1 : 0}>
                    <Typography>
                      {/* eslint-disable-next-line max-len */}
                      This search feature provides access to information that helps move cases through the workers' compensation court system efficiently.
                    </Typography>
                  </Box>

                  <Fab
                    variant="extended"
                    color="secondary"
                    onClick={openEAMSIntegrationModal}
                  >
                    Go
                  </Fab>
                </Box>
              </Alert>

              <Grid container spacing={isMobile ? 1 : 2} component={Box} pt={2}>
                <Grid item xs={isMobile ? 12 : 7}>
                  <Box display="flex" flexDirection="column" justifyContent="flex-start">
                    <Header isNewCase />
                    <Body />
                    <Footer />
                    <IncidentInformation />
                    <Applicant />
                  </Box>
                </Grid>

                <Grid item xs={isMobile ? 12 : 5}>
                  <InsuranceClaim
                    setValueFromAdj={setValueFromAdj}
                    defaultText={defaultText}
                  />
                </Grid>
              </Grid>
            </PageBody>
          </Page>
        </form>
      )}
    </Formik>
  );
};
