import { useContext } from 'react';
import cn from 'classnames';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Badge } from '../../../../../../Badge';
import { CaseLink } from '../../../../../CaseLink';
import { CaseReminderCasesContext } from '../../../../cases';
import {
  CaseRemindersList,
  CaseRemindersProvider
} from '../../../index';
import { IconButton } from '../../../../../../IconButton';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Group = ({
  caseItem,
  remindersAmount,
  expandedGroupID,
  onSelectedPanelChange
}) => {
  const classes = useStyles();
  const { filter } = useContext(CaseReminderCasesContext);
  const isExpanded = expandedGroupID === caseItem?.id;

  const handleSelectedPanelChange = () => {
    onSelectedPanelChange(caseItem?.id, !isExpanded);
  };

  return (
    <div className={cn(classes.root, isExpanded && classes.expanded)}>
      <div className={classes.header} onClick={handleSelectedPanelChange}>
        <Box display="flex" flexGrow={1}>
          <CaseLink caseItem={caseItem} />
        </Box>

        <div>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Badge isStatic badgeContent={remindersAmount} color="info">
                <IconButton color="inherit">
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Badge>
            </Grid>
          </Grid>
        </div>
      </div>

      {isExpanded &&
        <div className={classes.body}>
          <CaseRemindersProvider caseId={caseItem.id} filter={filter}>
            <CaseRemindersList />
          </CaseRemindersProvider>
        </div>
      }
    </div>
  );
};
