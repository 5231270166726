import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, useMediaQuery } from '@material-ui/core';
import ListAddIcon from '@material-ui/icons/PlaylistAdd';
import * as templatesApi from '../../../../../api/cases/task-template-groups';
import * as groupsApi from '../../../../../api/cases/task-template-groups';
import { Loader } from '../../../../../components/Loader';
import {
  ModalContainer,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../../../../components/Modal';
import { Form, validationSchema } from '../Form';

export const EditGroupModal = ({
  DialogProps,
  payload: {
    id
  },
  handleModalResolve,
  handleModalReject
}) => {
  const [ group, setGroup ] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { enqueueSnackbar } = useSnackbar();

  const updateGroup = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return groupsApi.updateTaskTemplateGroup(values).then((template) => {
      enqueueSnackbar('Group successfully updated', { variant: 'success' });
      handleModalResolve(template);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Group not updated', { variant: 'error' });
      }
    });
  };

  useEffect(() => {
    if (!id) return;

    templatesApi.fetchTaskTemplateGroup(id)
      .then(setGroup)
      .catch((error) => error && handleModalReject());
  }, [ id ]);

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      {!group ? <Loader p={2} /> : (
        <Formik
          initialValues={{
            ...group,
            case_task_templates: group?.templates || null
          }}
          validationSchema={validationSchema}
          onSubmit={updateGroup}
        >
          {({ isSubmitting, handleSubmit }) => (
            <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
              <ModalHeader icon={<ListAddIcon />} onClose={handleModalReject}>
                Update Task Template Group
              </ModalHeader>

              <ModalBody>
                <Form />
              </ModalBody>

              <ModalFooter>
                <Button onClick={handleModalReject}>Cancel</Button>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />
              </ModalFooter>
            </ModalContainer>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
