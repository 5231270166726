import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../helpers/createReduxReducer';

export const jobsTypes = {
  archives: 'archives',
  codesExports: 'codesExports'
};

const ADD_JOB = 'ADD_JOB';

export const addSocketJobID = (payload) => ({ type: ADD_JOB, payload });

const initialState = {
  [jobsTypes.archives]: [],
  [jobsTypes.codesExports]: []
};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [ADD_JOB]: (state, { type, jobID }) => {
    return { ...state, [type]: state[type].concat(jobID) };
  }
});
