import { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import {
  returnWorkIndicatorsOptions
} from '../../../../../../components/claims/SituationalNumberFormModal/SituationalNumberForm/data';
import { Autocomplete, IcdCodesSelect, TextField } from '../../../../../../components/FormField';
import { StepContext } from '../StepProvider';

export const Body = () => {
  const { dirty } = useFormikContext();
  const { setIsDirty } = useContext(StepContext);

  useEffect(() => {
    setIsDirty(dirty);
  }, [ dirty ]);

  return (
    <Box flexGrow={1} px={2}>
      <Typography paragraph variant="h4" color="secondary">
        Diagnostic studies
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            disableUnderline
            rows={4}
            rowsMax={8}
            name="forms.diagnostic_studies.text"
            variant="filled"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <IcdCodesSelect
            multiple
            disableValueTransform
            name="forms.diagnostic_studies.icd"
            label="Diagnosis(DX)"
          />
        </Grid>

        <Hidden only={[ 'xs', 'sm' ]}>
          <Grid item md={6} />
        </Hidden>

        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Disability"
            name="forms.diagnostic_studies.disability"
            margin="dense"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Autocomplete
            name="forms.diagnostic_studies.return_work_indicator"
            label="Return To Work Indicator"
            margin="dense"
            options={returnWorkIndicatorsOptions}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
