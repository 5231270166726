export const styles = ({
  spacing,
  palette,
  typography: { fontWeightMedium },
  breakpoints
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    flexGrow: 1,
    padding: 0,

    [breakpoints.up('md')]: {
      padding: spacing(0, 3)
    }
  },

  mainContent: {
    flexGrow: 1
  },

  actionButton: {
    flexGrow: 0,
    marginLeft: 'auto',
    paddingBottom: spacing(2)
  },

  backButton: {
    marginRight: spacing()
  },

  formTitle: {
    fontWeight: fontWeightMedium,
    color: palette.darkgreen,
    marginBottom: spacing()
  },

  contentWrapper: {
    marginBottom: spacing(5)
  },

  content: {
    marginBottom: spacing(3),
    marginTop: spacing()
  },

  title: {
    fontWeight: fontWeightMedium
  },

  doiDate: {
    width: 100,
    marginBottom: spacing(3)
  },

  contentInfo: {
    paddingLeft: spacing(2)
  }
});
