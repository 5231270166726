import { FormControlLabel, Grid, Hidden } from '@material-ui/core';
import { useFormikContext } from 'formik';
import {
  Checkbox,
  TextField,
  PhoneField,
  KeyboardDatePicker,
  CompaniesAutocomplete
} from '../../../../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../../../../components/users';
import { companyTypeIDsMap } from '../../../../../../../ProfilePage/insurance';

export const CourtInformationForm = () => {
  const { setFieldValue } = useFormikContext();

  const handleCourtChange = (company) => {
    setFieldValue('court_number', company?.court_number);
    setFieldValue('phone', company?.phone);
    setFieldValue('address', company?.full_address);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <CompaniesAutocomplete
          isCreatable
          name="court_id"
          label="Court"
          creatablePayload={{
            disableCourtField: true,
            company_type_id : { id: 5, name: 'Court', is_system: 1 }
          }}
          params={{
            company_type_id: companyTypeIDsMap.court
          }}
          onChange={handleCourtChange}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          disabled
          name="address"
          label="Address"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <PhoneField
          zeroMinWidth
          isEditable={false}
          name="phone"
          label="Court Phone # "
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          disabled
          name="court_number"
          label="Court Number"
          placeholder="Enter court number..."
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="case_number"
          label="Court Case #"
          placeholder="Enter court case no..."
        />
      </Grid>

      <Hidden smDown>
        <Grid item sm={6} />
      </Hidden>

      <Grid item sm={6} xs={12}>
        <UsersSelect
          name="contact_id"
          label="Judge"
        />
      </Grid>

      <Hidden smDown>
        <Grid item sm={6} />
      </Hidden>

      <Grid item sm={6} xs={12}>
        <TextField
          name="dep"
          label="Dep"
          placeholder="Enter dep..."
        />
      </Grid>

      <Grid item container sm={3} xs={12}>
        <FormControlLabel
          control={<Checkbox name="jury_trial" />}
          label="Jury Trial"
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        <KeyboardDatePicker
          name="trial_date"
          label="Trial"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="fsc"
          label="FSC"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="cmc"
          label="CMC"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="cmc_future_use"
          label="Future Use"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="osc"
          label="OSC"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="mcs"
          label="MSC"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="mcs_future_use"
          label="Future Use"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="mediation"
          label="Mediation"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="arb"
          label="Arb."
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="arb_future_use"
          label="Future Use"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="complaint_filled"
          label="Complaint Filed"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Hidden smDown>
        <Grid item sm={8} />
      </Hidden>

      <Grid item sm={4} xs={12}>
        <TextField
          name="def"
          label="Def-998"
          placeholder="Enter Def-998..."
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="received"
          label="Received"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <KeyboardDatePicker
          name="plf_sent"
          label="PLF-998 Sent"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>
    </Grid>
  );
};
