import { useState, useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { makeStyles, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Badge } from '../../../../../../../../components/Badge';
import { AccountsContext } from '../../../../../AccountsContext';
import { folderNamesMap } from '../../../../../AccountsContext/folderNamesMap';
import { MessagesContext } from '../../../../../MessagesContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FolderItem = ({ selected, folder }) => {
  const classes = useStyles();
  const { counters, error, folderToMap } = useContext(AccountsContext);
  const { filter, applyFilter } = useContext(MessagesContext);
  const [ counter, setCounter ] = useState(0);
  const marker = folder.name === folderNamesMap.inbox
    ? folder.name
    : folder.marker.replace(/[^a-zA-Z]+/g, '');
  const folderName = folderToMap[marker];
  const initialFilter = {
    folder_id: folder.id,
    folder_name: marker
  };

  const handleChangeFolder = () => {
    applyFilter({ folder_id: folder.id, folder_name: marker });
  };

  useEffect(() => {
    if (selected) {
      applyFilter(initialFilter);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(counters) && folderName) {
      const folderCounter = counters[folder.config_id];

      if (folderCounter) {
        setCounter(folderCounter[folderName]);
      }
    }
  }, [ counters ]);

  return (
    <ListItem
      button
      selected={folder.id === filter?.folder_id}
      className={classes.root}
      onClick={handleChangeFolder}
    >
      <ListItemText primary={folder.name} />

      {counter > 0 && !error &&
        <ListItemSecondaryAction>
          <Badge badgeContent={counter} max={999} color="default" classes={{ badge: classes.badge }} />
        </ListItemSecondaryAction>
      }
    </ListItem>
  );
};
