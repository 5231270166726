import { useContext } from 'react';
import { fieldsKeysMap } from '../../../../../../components/appointments/FiltersBar/fieldsKeysMap';
import { viewVariantsMap } from '../../../../../../components/LayoutContext';
import { filtersKeysMap } from '../../../../../../store/lastFilters';
import { Appointments as AppointmentsCommon } from '../../../../AppointmentsPage/Appointments';
import { UserContext } from '../../UserProvider';

const hiddenFilterFields = [
  fieldsKeysMap.patients
];

export const Appointments = () => {
  const { user } = useContext(UserContext);

  return (
    <AppointmentsCommon
      patient={user}
      filter={{ patients: [ user.id ] }}
      initialViewVariant={viewVariantsMap.grid}
      hiddenFilterFields={hiddenFilterFields}
      filterKey={filtersKeysMap.members_appointments + user.id}
    />
  );
};
