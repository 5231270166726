import { makeStyles } from '@material-ui/styles';
import { StickyFallback } from './StickyFallback';

const useStyles = makeStyles({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 1
  }
});

export const Sticky = (props) => {
  const classes = useStyles();

  return CSS.supports('position', 'sticky') ? (
    <div className={classes.root}>
      {props.children}
    </div>
  ) : (
    <StickyFallback {...props} />
  );
};
