import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_ARRIVAL_STATUSES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_ARRIVAL_STATUSES_SUCCESS]: (
    { filter, arrivalStatuses, ...state },
    { data: newArrivalStatuses, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      arrivalStatuses: (page > 1 ?
        arrivalStatuses.filter(({ id }) => {
          return !newArrivalStatuses.find((loadedFile) => id === loadedFile.id);
        }).concat(newArrivalStatuses)
        :
        newArrivalStatuses
      )
    };
  },

  [types.RESET_ARRIVAL_STATUSES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_ARRIVAL_STATUS]: (state, arrivalStatus) => {
    return {
      ...state,

      arrivalStatuses: state.arrivalStatuses.map((item) => {
        return item.id === arrivalStatus.id ? { ...item, ...arrivalStatus } : item;
      })
    };
  },


  [types.DELETE_ARRIVAL_STATUS]: (state, arrivalStatusID) => {
    const { pagination, filter, arrivalStatuses } = state;
    const filteredArrivalStatuses = arrivalStatuses.filter(({ id }) => arrivalStatusID !== id);

    if (filteredArrivalStatuses.length === arrivalStatuses.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((arrivalStatuses.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      arrivalStatuses: filteredArrivalStatuses
    };
  }
});
