import { useContext, useMemo, useRef } from 'react';
import { Box, Paper, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { useResizeObserver } from '../../../../../../helpers/hooks/useResizeObserver';
import { hasRole } from '../../../../../../utils/hasRole';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { ClaimsProvider, columnsMap } from '../../../../../../components/claims';
import { ActionsBar } from '../ActionsBar';
import { FiltersBar } from '../FiltersBar';
import { filterFieldsMap } from '../FiltersBar/filterFieldsMap';
import { Grid } from '../Grid';
import { List } from '../List';

export const ClaimsLayout = ({
  isFixedCase = false,
  view,
  filterKey,
  hiddenFilterFields: hiddenFilterFieldsProp = [],
  hiddenColumns: hiddenColumnsProp = [],
  initialFilter: initialFilterProp = {},
  creationInitialValues
}) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const rootRef = useRef();
  const theme = useTheme();
  const { width } = useResizeObserver({ ref: rootRef });
  const { viewVariant } = useContext(LayoutContext);
  const isTablet = width <= theme.breakpoints.values.tablet;
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper
  } = useMobileFilterCollapse('tablet', width);
  const initialFilter = {
    patients: hasRole(rolesMap.patient) ? [ currentUser.id ] : null,
    ...initialFilterProp
  };
  const hiddenFilterFields = useMemo(() => {
    return (hasRole(rolesMap.patient) ? [ filterFieldsMap.patients ] : []).concat(hiddenFilterFieldsProp);
  }, []);
  const hiddenColumns = useMemo(() => {
    return [ columnsMap.caseNo ].concat(
      hasRole(rolesMap.patient) ? columnsMap.patient : [],
      hiddenColumnsProp
    );
  }, []);

  return (
    <ClaimsProvider filter={initialFilter}>
      <Box display="flex" flexDirection="column" height="100%" overflow="hidden" ref={rootRef}>
        <Paper square elevation={0}>
          <Box p={isTablet ? 1: 2}>
            <ActionsBar
              isTablet={isTablet}
              isFixedCase={isFixedCase}
              filterOpenButton={filterOpenButton}
              creationInitialValues={creationInitialValues}
            />
          </Box>
        </Paper>

        <Box mx={-1}>
          <ScrollWrapper {...scrollWrapperProps}>
            <FilterBarWrapper {...filterBarWrapperProps}>
              <FiltersBar filterKey={filterKey} hiddenFields={hiddenFilterFields} />
            </FilterBarWrapper>
          </ScrollWrapper>
        </Box>

        <Box clone flexGrow={1}>
          <Paper square elevation={0}>
            {isTablet || viewVariant === viewVariantsMap.grid ? (
              <Grid view={view} />
            ) : viewVariant === viewVariantsMap.list ? (
              <List view={view} hiddenColumns={hiddenColumns} />
            ) : null}
          </Paper>
        </Box>
      </Box>
    </ClaimsProvider>
  );
};
