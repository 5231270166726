import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Slider, Typography } from '@material-ui/core';
import { setFontSize } from '../../store/appointmentsScoreboard';
import { sliderMarks } from './sliderMarks';
import { CurrentTime } from './CurrentTime';

const getSliderAriaValueText = (value) => {
  return `${value}%`;
};

export const Header = ({ office }) => {
  const dispatch = useDispatch();
  const fontSize = useSelector(({ appointmentsScoreboard }) => appointmentsScoreboard.fontSize);
  const [ sliderValue, setSliderValue ] = useState(fontSize);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  useEffect(() => {
    dispatch(setFontSize(sliderValue));
  }, [ sliderValue ]);

  return (
    <Box display="flex" flexDirection="column" style={{ fontSize: `${fontSize}%` }}>
      <Box my={2} flexBasis="100%">
        <Slider
          value={sliderValue}
          valueLabelDisplay="auto"
          marks={sliderMarks}
          step={5}
          min={75}
          max={400}
          onChange={handleSliderChange}
          getAriaValueText={getSliderAriaValueText}
        />
      </Box>

      <Box display="flex">
        <Box flexGrow={1} mr={2}>
          <Typography variant="h3" style={{ fontSize: '1em' }}>
            {office.company?.name}, {office.name}
          </Typography>
        </Box>

        <CurrentTime TypographyProps={{ style: { fontSize: '1em' } }} />
      </Box>
    </Box>
  );
};
