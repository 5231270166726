import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { makeStyles, useTheme, Drawer, useMediaQuery } from '@material-ui/core';
import { SidebarContent } from './SidebarContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Sidebar = () => {
  const sidebarIsOpen = useSelector(({ layout: { sidebarIsOpen } }) => sidebarIsOpen);
  const userSidebarIsOpen = useSelector(({ layout: { userInfoIsOpen = true } }) => userInfoIsOpen);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const classes = useStyles();

  useEffect(() => {
    const timeout = theme.transitions.duration[
      sidebarIsOpen || userSidebarIsOpen ? 'leavingScreen' : 'enteringScreen'
    ];

    setTimeout(() => {
      // For resize cards on react-grid-layout
      window.dispatchEvent(new CustomEvent('resize'));
    }, timeout);
  }, [ isMobile, sidebarIsOpen, userSidebarIsOpen ]);

  return (
    <Drawer
      open={sidebarIsOpen}
      variant="permanent"
      className={cn(classes.drawer, sidebarIsOpen ? classes.drawerAndPaperOpened : classes.drawerAndPaper)}
      classes={{
        paper: cn(classes.paper, sidebarIsOpen ? classes.drawerAndPaperOpened : classes.drawerAndPaper)
      }}
    >
      <SidebarContent sidebarIsOpen={sidebarIsOpen} />
    </Drawer>
  );
};
