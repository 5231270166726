import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { unixToEndOfDayUnix, unixToStartOfDayUnix } from '../../../../helpers/dates';
import { saveFilter } from '../../../../store/lastFilters';
import { CompaniesAutocomplete, KeyboardDatePicker, OrderByAutocomplete, orderDirectionsMap } from '../../../FormField';
import { FiltersBar as FiltersBarComponent } from '../../../FiltersBar';
import { IconComponent } from '../../../saved-filters';
import { UsersSelect } from '../../../users';
import { availableRolesForUsersSelect } from '../../../work-sessions/WorkSesstions/FiltersBar';
import { TimeReportsContext } from '../../TimeReportsProvider';
import { CardContent } from './CardContent';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { orderByOptions } from './orderByOptions';
import { List } from './List';

const MODAL_WIDTH = 860;

const initialValues = {
  order_by: orderByOptions[0],
  order_direction: orderDirectionsMap.desc
};

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const theme = useTheme();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { filter, applyFilter } = useContext(TimeReportsContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState(initialValues);

  const handleDatePickerChange = (name, transformer) => (date) => {
    applyFilter({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.value || option?.id });
    setRelationsForFilter((state) => ({ ...state, [name]: option }));
  };

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ order_direction: orderDirection });
    setRelationsForFilter((state) => ({ ...state, order_direction: orderDirection }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);

    applyFilter(omit({
      ...filter,
      user_id: filter?.user_id?.id || null,
      company_id: filter?.company_id?.id || null,
      order_by: filter?.order_by?.value
    }, hiddenFields));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableTopPaddings
      formikRef={formikRef}
      filterKey={filterKey}
      initialValues={initialValues}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      hiddenFields={hiddenFields}
      onReset={() => applySavedFilter(initialValues)}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.company,
          label: filterFieldsLabels[filterFieldsMap.company],
          field: (
            <Box minWidth={isMobile ? '100%' : 250}>
              <CompaniesAutocomplete
                label="Company"
                name="company_id"
                onChange={handleSelectChange('company_id')}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.user,
          label: filterFieldsLabels[filterFieldsMap.user],
          field: (
            <Box minWidth={isMobile ? '100%' : 250}>
              <UsersSelect
                label="User"
                name="user_id"
                onChange={handleSelectChange('user_id')}
                params={{
                  company_id: filter?.company_id?.id || filter?.company_id,
                  roles: availableRolesForUsersSelect
                }}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.startedFrom,
          label: filterFieldsLabels[filterFieldsMap.startedFrom],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                disableFuture
                clearable
                name="date_from"
                label="From"
                outputFormat="YYYY-MM-DD"
                onChange={handleDatePickerChange('date_from', unixToStartOfDayUnix)}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.startedTo,
          label: filterFieldsLabels[filterFieldsMap.startedTo],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                clearable
                name="date_to"
                label="To"
                outputFormat="YYYY-MM-DD"
                onChange={handleDatePickerChange('date_to', unixToEndOfDayUnix)}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.orderBy,
          label: filterFieldsLabels[filterFieldsMap.orderBy],
          field: (
            <Box minWidth={isMobile ? '100%' : 160}>
              <OrderByAutocomplete
                label="Order By"
                name="order_by"
                options={orderByOptions}
                orderDirection={relationsForFilter.order_direction}
                onOrderDirectionChange={toggleOrderDirection}
                onChange={handleSelectChange('order_by')}
              />
            </Box>
          )
        }
      ]}
    />
  );
};
