import T from 'prop-types';
import { Typography } from '@material-ui/core';

export const PreviewTaskTabContainer = ({ children }) => {
  return (
    <Typography component="div" style={{ padding: '16px 0' }}>
      {children}
    </Typography>
  );
};

PreviewTaskTabContainer.propTypes = {
  children: T.node.isRequired
};
