export const styles = ({ palette }) => ({
  root: {
    padding: 0
  },

  root_widget: {
    padding: 0
  },

  loader: {
    alignSelf: 'center'
  },

  trackTypeIcon: {
    color: palette.grey[500]
  },

  trackTypeIcon_user: {
    color: palette.purple
  },

  trackTypeIcon_tracker: {
    color: palette.violet
  },

  parentTitleLink: {
    cursor: 'pointer'
  }
});
