import { useContext } from 'react';
import { Checkbox, Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { AccessPatientsContext } from '../../PatientsProvider';
import { columnsWidths } from '../listConfig';

export const TableHeader = () => {
  const { selectedPatientsIDs, allItemsIsSelected, toggleAllItemsSelection } = useContext(AccessPatientsContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell>
          <Checkbox
            edge="start"
            indeterminate={!!selectedPatientsIDs.length && !allItemsIsSelected()}
            checked={allItemsIsSelected()}
            onClick={toggleAllItemsSelection}
          />
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.name}>
          Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.phone}>
          Phone
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.doi}>
          DOI
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
