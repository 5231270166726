import { Box, Grid, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { Contact } from '../../../../../../../components/Contact';
import { Divider } from '../../../../../../../components/Divider';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { stopPropagation } from '../../../../../../../helpers/dom/stopPropagation';
import { PreviewCompanyModal } from '../../../../Companies/PreviewCompanyModal';

export const Body = ({ office }) => {
  const { openModal } = useModal();

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: office?.company?.id }
    });
  };

  return (
    <Box px={3} pt={2}>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Company Name
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <CompanyLink
            variant="h5"
            company={office?.company}
            onClick={stopPropagation(openPreviewCompanyModal)}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Address
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography noWrap variant="h5" title={office?.full_address || '-'}>
            {office?.full_address || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Billing Address
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography noWrap variant="h5" title={office?.billing_full_address || '-'}>
            {office?.billing_full_address || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Venue
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {office.venue_full_address ? (
              <ReadonlyCheckbox
                checked
                fullWidth
                noWrap
                variant="h5"
                position="flex-start"
                title={office.venue_full_address}
              />
            ) : (
              <Typography color="textSecondary">No main venue</Typography>
            )}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Phone
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={office.phone}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Email
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="mailto"
            contact={office.email}
          />
        </Grid>
      </Grid>

      <Divider disableBottomGutter gutter={2} type="dashed" />
    </Box>
  );
};
