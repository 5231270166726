export const sectionsKeysMap = {
  chief_complaint: 'chief_complaint',
  history_of_present_illness: 'history_of_present_illness',
  past_medical_history: 'past_medical_history',
  medications: 'medications',
  allergies: 'allergies',
  personal_history: 'personal_history',
  past_surgical_history: 'past_surgical_history',
  cervical: 'cervical',
  lumbar: 'lumbar',
  current_symptoms_back: 'current_symptoms_back',
  current_symptoms_neck: 'current_symptoms_neck',
  general_appearance: 'general_appearance',
  diagnostic_studies: 'diagnostic_studies',
  assessment: 'assessment',
  impression: 'impression',
  treatment_plan: 'treatment_plan',
  discussion: 'discussion',
  medical_causation: 'medical_causation',
  cervical_spine: 'cervical_spine',
  lumbar_spine: 'lumbar_spine'
};

export const sectionsTitleMap = {
  [sectionsKeysMap.chief_complaint]: 'forms.medical_form_info.reason_for_consultation',
  [sectionsKeysMap.history_of_present_illness]: 'forms.medical_form_info.history_of_present_illness',
  [sectionsKeysMap.past_medical_history]: 'forms.medical_form_info.past_medical_history',
  [sectionsKeysMap.medications]: 'forms.medical_form_info.medications',
  [sectionsKeysMap.allergies]: 'forms.medical_form_info.allergies',
  [sectionsKeysMap.personal_history]: 'forms.medical_form_info.personal_history',
  [sectionsKeysMap.past_surgical_history]: 'forms.medical_form_info.past_surgical_history',
  [sectionsKeysMap.cervical]: 'forms.physical_examination.cervical',
  [sectionsKeysMap.lumbar]: 'forms.physical_examination.lumbar',
  [sectionsKeysMap.current_symptoms_back]: 'forms.medical_form_info.current_symptoms_back',
  [sectionsKeysMap.current_symptoms_neck]: 'forms.medical_form_info.current_symptoms_neck',
  [sectionsKeysMap.general_appearance]: 'forms.physical_examination.general_appearance',
  [sectionsKeysMap.diagnostic_studies]: 'forms.diagnostic_studies',
  [sectionsKeysMap.assessment]: 'forms.assessment',
  [sectionsKeysMap.impression]: 'forms.impression',
  [sectionsKeysMap.treatment_plan]: 'forms.treatment_plan',
  [sectionsKeysMap.discussion]: 'forms.discussion',
  [sectionsKeysMap.medical_causation]: 'forms.medical_causation'
};

export const sectionsOptions = [
  {
    value: sectionsTitleMap.chief_complaint,
    label: 'CHIEF COMPLAINT',
    data: sectionsKeysMap.chief_complaint
  },
  {
    value: sectionsTitleMap.history_of_present_illness,
    label: 'HISTORY OF PRESENT ILLNESS',
    data: sectionsKeysMap.history_of_present_illness
  },
  {
    value: sectionsTitleMap.past_medical_history,
    label: 'PAST MEDICAL HISTORY',
    data: sectionsKeysMap.past_medical_history
  },
  {
    value: sectionsTitleMap.medications,
    label: 'MEDICATIONS',
    data: sectionsKeysMap.medications
  },
  {
    value: sectionsTitleMap.allergies,
    label: 'ALLERGIES',
    data: sectionsKeysMap.allergies
  },
  {
    value: sectionsTitleMap.personal_history,
    label: 'PERSONAL HISTORY',
    data: sectionsKeysMap.personal_history
  },
  {
    value: sectionsTitleMap.past_surgical_history,
    label: 'SURGERIES/PAST SURGICAL HISTORY',
    data: sectionsKeysMap.past_surgical_history
  },
  {
    value: sectionsTitleMap.current_symptoms_back,
    label: 'CURRENT SYMPTOMS: BACK',
    data: sectionsKeysMap.current_symptoms_back
  },
  {
    value: sectionsTitleMap.current_symptoms_neck,
    label: 'CURRENT SYMPTOMS: NECK',
    data: sectionsKeysMap.current_symptoms_neck
  },
  {
    value: sectionsTitleMap.general_appearance,
    label: 'GENERAL APPEARENCE',
    data: sectionsKeysMap.general_appearance
  },
  {
    value: sectionsTitleMap.cervical,
    label: 'CERVICAL SPINE',
    data: sectionsKeysMap.cervical
  },
  {
    value: sectionsTitleMap.lumbar,
    label: 'LUMBAR SPINE',
    data: sectionsKeysMap.lumbar
  },
  {
    value: sectionsTitleMap.diagnostic_studies,
    label: 'DIAGNOSTIC STUDIES',
    data: sectionsKeysMap.diagnostic_studies
  },
  {
    value: sectionsTitleMap.assessment,
    label: 'INITIAL ASSESSMENT',
    data: sectionsKeysMap.assessment
  },
  {
    value: sectionsTitleMap.impression,
    label: 'IMPRESSION',
    data: sectionsKeysMap.impression
  },
  {
    value: sectionsTitleMap.treatment_plan,
    label: 'TREATMENT PLAN',
    data: sectionsKeysMap.treatment_plan
  },
  {
    value: sectionsTitleMap.discussion,
    label: 'DISCUSSION',
    data: sectionsKeysMap.discussion
  },
  {
    value: sectionsTitleMap.medical_causation,
    label: 'MEDICAL CAUSATION',
    data: sectionsKeysMap.medical_causation
  }
];
