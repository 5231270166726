import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../components/UserLink';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.patient_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Patients:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <UserLink
                noWrap
                size="sm"
                user={params?.patient_id}
              />
            </Box>
          </Grid>
        </>
      }

      {params?.name &&
        <>
          <Grid item xs={4}>
            <Typography>
              Name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.name}
            </Typography>
          </Grid>
        </>
      }

      {params?.date_from &&
        <>
          <Grid item xs={4}>
            <Typography>
              Date From:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {moment(params?.date_from).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.date_to &&
        <>
          <Grid item xs={4}>
            <Typography>
              Date To:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {moment(params?.date_to).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.provider_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Provider:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <UserLink
                noWrap
                size="sm"
                user={params?.provider_id}
              />
            </Box>
          </Grid>
        </>
      }

      {params?.office_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Office:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <OfficeLink noWrap variant="h5" office={params?.office_id} />
            </Box>
          </Grid>
        </>
      }
    </Grid>
  );
};
