import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { grey } }) => ({
  root: {
    color: grey[600],
    textTransform: 'capitalize',
    lineHeight: 1
  },

  secondary: {
    color: grey[600]
  }
}));

export const PageHeaderTitle = ({ children, secondary, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Typography variant={isMobile ? 'h4' : 'h6'} className={classes.root} {...props}>
        {children}
      </Typography>

      {!!secondary && (
        <Typography variant="body1" className={classes.secondary} {...props}>
          {secondary}
        </Typography>
      )}
    </div>

  );
};
