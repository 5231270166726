import { Typography } from '@material-ui/core';
import { DiagnosticImpression } from './DiagnosticImpression';
import { PhysicalExamination } from './PhysicalExamination';
import { CurrentMedications } from './CurrentMedications';
import { CurrentHistory } from './CurrentHistory';
import { RadiologyExams } from './RadiologyExams';
import { TreatmentPlan } from './TreatmentPlan';
import { ReviewSystems } from './ReviewSystems';
import { Allergies } from './Allergies';

export const FollowUp = ({ report, ...props }) => {
  return !!report?.forms ? (
    <>
      {report?.forms?.current_history &&
        <CurrentHistory report={report?.forms?.current_history || {}} {...props} />
      }
      {report?.forms?.review_of_symptoms &&
        <ReviewSystems report={report?.forms?.review_of_symptoms || {}} {...props} />
      }
      {report?.forms?.current_medications &&
        <CurrentMedications report={report?.forms?.current_medications || {}} {...props} />
      }
      {report?.forms?.allergies &&
        <Allergies report={report?.forms?.allergies || {}} {...props} />
      }
      {report?.forms?.physical_examination &&
        <PhysicalExamination report={report?.forms?.physical_examination || {}} {...props} />
      }
      {report?.forms?.radiology_exams_lab_results &&
        <RadiologyExams report={report?.forms?.radiology_exams_lab_results || {}} {...props} />
      }
      {report?.forms?.diagnostic_impression &&
        <DiagnosticImpression report={report?.forms?.diagnostic_impression || {}} {...props} />
      }
      {report?.forms?.treatment_plan &&
        <TreatmentPlan report={report?.forms?.treatment_plan || {}} {...props} />
      }
    </>
  ) : (
    <Typography color="textSecondary">Form is not filled</Typography>
  );
};
