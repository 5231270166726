import { useContext, useEffect, useRef } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { Page, PageBody, PageHeader } from '../../../../../components/Page';
import { useModal } from '../../../../../components/ModalsProvider';
import { ActionsBar } from '../../ActionsBar';
import { AddContactModal } from '../AddContactModal';
import { ContactsContext } from '../ContactsProvider';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

export const MainContent = ({ filterKey }) => {
  const theme = useTheme();
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { selectedIDs, pagination, fetchContacts, ...contactsContext } = useContext(ContactsContext);
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('md');

  const addContact = () => {
    openModal(AddContactModal, {
      onModalResolved: () => {
        fetchContacts();
      }
    });
  };

  const massDeleteContacts = () => {
    contactsContext.massDeleteContacts(selectedIDs);
  };

  useEffect(() => {
    if (width < 825) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width, viewVariant ]);

  return (
    <Page ref={rootRef}>
      <PageHeader>
        <ActionsBar
          title={isMobile ? 'Add' : 'Add contact'}
          width={width}
          pagination={pagination}
          selectedIDs={selectedIDs}
          addNewContact={addContact}
          massDelete={massDeleteContacts}
          filterOpenButton={filterOpenButton}
        />
      </PageHeader>

      <PageBody fullHeight disablePaddings disableScrollbars>
        <Box height="100%" display="flex" flexDirection="column">
          <ScrollWrapper {...scrollWrapperProps}>
            <FilterBarWrapper {...filterBarWrapperProps}>
              <FiltersBar filterKey={filterKey} />
            </FilterBarWrapper>
          </ScrollWrapper>

          <Box flexGrow={1}>
            {(viewVariant === viewVariantsMap.list) && <List width={width} />}
            {(viewVariant === viewVariantsMap.grid) && <Grid />}
          </Box>
        </Box>
      </PageBody>
    </Page>
  );
};
