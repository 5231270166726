import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.PATIENTS_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.PATIENTS_FETCH_SUCCESS]: (
    { filter, patientsList, ...state },
    { data: newPatients, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      patientsList: page === 1 ? newPatients : newPatients.concat(patientsList.filter(({ id }) => {
        return !newPatients.find((loadedPatient) => id === loadedPatient.id);
      }))
    };
  },

  [types.ADD_PATIENT]: ({ pagination, patientsList, ...state }, patient) => {
    return {
      ...state,
      patientsList: [ ...patientsList, patient ],
      pagination: {
        ...pagination,
        total: pagination.total + 1
      }
    };
  },

  [types.DELETE_PATIENT]: ({ pagination, filter, patientsList, ...state }, patientsIDs) => {
    const deletedPatients = patientsList.filter((patient) => patientsIDs.find((id) => id === patient.id));
    const total = pagination.total - deletedPatients.length;
    const page = Math.ceil((patientsList.length - deletedPatients.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      patientsList: patientsList.filter((patient) => !deletedPatients.find(({ id }) => id === patient.id))
    };
  },

  [types.UPDATE_PATIENT]: ({ patientsList, ...state }, updatedPatient) => {
    return {
      ...state,

      patientsList: patientsList?.map((patient) => updatedPatient?.id === patient?.id ? updatedPatient : patient)
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_PATIENTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_PATIENTS_TO_SELECTED]: ({ selectedPatientsIDs, ...state }, itemsIDs) => {
    return {
      ...state,

      selectedPatientsIDs: [ ...new Set(selectedPatientsIDs.concat(itemsIDs)) ]
    };
  },

  [types.DELETE_PATIENTS_FROM_SELECTED]: ({ selectedPatientsIDs, ...state }, itemsIDs) => {
    return {
      ...state,

      selectedPatientsIDs: selectedPatientsIDs.filter((id) => itemsIDs.indexOf(id) === -1 )
    };
  }
});
