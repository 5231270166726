import * as formsApi from '../../../../api/profile';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';
import { SingleValue } from './SingleValue';
import { Option } from './Option';

export const transformMedicalFormsToOption = (form) => form && ({
  label: form,
  value: form.id,
  data: form
});

const components = {
  SingleValue,
  Option
};

const fetchMedicalForms = (params) => fetchDataForAsyncSelect((name, { page }) => {
  return formsApi.fetchMedicalForms({ params: { name, page, ...params } });
}, {
  transformDataToOption: transformMedicalFormsToOption
});

export const MedicalFormsSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      name="medical_form_id"
      label="Select a medical form"
      promiseWithOptions={fetchMedicalForms(params)}
      components={components}
      {...props}
    />
  );
};
