import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { filtersKeysMap } from '../../../../../../../store/lastFilters';
import { InvoicesPage } from '../../../../../BillingPage/Invoices/InvoicesPage';
import { filterFieldsMap } from '../../../../../BillingPage/Payments/PaymentsPage/FiltersBar/filterFieldsMap';

export const Billing = ({ caseItem, hiddenExpensesFilterFields, hiddenInvoicesFilterFields }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display="flex" flexGrow={1} pb={1} pl={isMobile ? 0 : 1}>
      <InvoicesPage
        caseItem={caseItem}
        hiddenExpensesFilterFields={hiddenExpensesFilterFields}
        hiddenInvoicesFilterFields={hiddenInvoicesFilterFields}
        PaymentsProps={{
          ReceivedProps: {
            hiddenFields: [ filterFieldsMap.caseId ],
            filterKey: filtersKeysMap.cases_payments_received
          },
          OverdraftProps: {
            hiddenFields: [ filterFieldsMap.caseId ],
            filterKey: filtersKeysMap.cases_payments_overdraft
          }
        }}
      />
    </Box>
  );
};
