import { dispositionsMap, dispositionsLabelsMap } from './dispositionsMap';

export const dispositionOptions = [
  {
    value: dispositionsMap.answered,
    label: dispositionsLabelsMap[dispositionsMap.answered]
  },
  {
    value: dispositionsMap.no_answer,
    label: dispositionsLabelsMap[dispositionsMap.no_answer]
  },
  {
    value: dispositionsMap.congestion,
    label: dispositionsLabelsMap[dispositionsMap.congestion]
  },
  {
    value: dispositionsMap.failed,
    label: dispositionsLabelsMap[dispositionsMap.failed]
  },
  {
    value: dispositionsMap.busy,
    label: dispositionsLabelsMap[dispositionsMap.busy]
  }
];
