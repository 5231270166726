import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.IMMUNIZATIONS_FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.IMMUNIZATIONS_FETCH_SUCCESS]: (
    { filter, vaccinations, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      selectedIDs: [],
      filter: { ...filter, page, per_page },
      immunizations: data
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_IMMUNIZATIONS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.ADD_IMMUNIZATION]: (state, payload) => {
    const { pagination, filter, immunizations, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((immunizations.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page: page === 0 ? 1 : page },
      immunizations: [ payload, ...immunizations ]
    };
  },

  [types.UPDATE_IMMUNIZATION_IN_LIST]: ({ immunizations, ...state }, payload) => {
    return {
      ...state,

      immunizations: immunizations.map((item) => item.id === payload.id ? payload : item)
    };
  },

  [types.DELETE_IMMUNIZATION_SUCCESS]: ({ immunizations, pagination, filter, ...state }, vaccinationId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      immunizations: immunizations.filter(({ id }) => id !== vaccinationId)
    };
  },

  [types.TOGGLE_ALL_IMMUNIZATIONS_SELECTION]: ({ immunizations, selectedIDs, ...state }) => {
    const allItemIsSelected = immunizations.length === selectedIDs.length;

    return {
      ...state,

      immunizations,
      selectedIDs: allItemIsSelected ? [] : immunizations.map((item) => item.id)
    };
  },

  [types.TOGGLE_IMMUNIZATION_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1 ?
        selectedIDs.filter((item) => item !== id)
        :
        selectedIDs.concat(id)
    };
  },

  [types.MASS_DELETE_IMMUNIZATIONS]: ({ immunizations, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      immunizations: immunizations.filter((immunization) => IDs.indexOf(immunization.id) < 0)
    };
  }
});
