import { Formik } from 'formik';
import { Icon } from '@mdi/react';
import { mdiFilter } from '@mdi/js';
import { makeStyles, SvgIcon } from '@material-ui/core';
import { OfficesLocationSelect, CompaniesAutocomplete } from '../../../../../../components/FormField';
import { AppBar } from '../../../../../../components/AppBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainFilter = ({ filter, setFilter }) => {
  const classes = useStyles();

  const handleOfficeChange = (office) => {
    setFilter({ ...filter, office_id: office?.id });
  };

  const handleCompanyChange = (company) => {
    setFilter({ ...filter, company_id: company?.id });
  };

  return (
    <AppBar className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <Formik
        onSubmit={null}
        initialValues={{
          company_id: null,
          office_id: null
        }}
      >
        {() => (
          <div className={classes.filterRoot}>
            <SvgIcon color="primary" className={classes.filterIcon}>
              <Icon path={mdiFilter} />
            </SvgIcon>

            <OfficesLocationSelect
              label="Filter by name offices"
              name="office_id"
              margin="dense"
              className={classes.selectField}
              onChange={handleOfficeChange}
            />

            <CompaniesAutocomplete
              label="Filter by name company"
              name="company_id"
              margin="dense"
              className={classes.selectField}
              onChange={handleCompanyChange}
            />
          </div>
        )}
      </Formik>
    </AppBar>
  );
};
