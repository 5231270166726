export const parentTypesMap = {
  user: 'USER',
  case: 'CASE'
};

export const parentTypesLabelsMap = {
  [parentTypesMap.user]: 'User',
  [parentTypesMap.case]: 'Case'
};

export const parentTypesOptions = [
  { value: parentTypesMap.user, label: parentTypesLabelsMap[parentTypesMap.user] },
  { value: parentTypesMap.case, label: parentTypesLabelsMap[parentTypesMap.case] }
];

export const callerTypesMap = {
  anonymous: 'anonymous'
};
