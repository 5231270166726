import { SvgIcon, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useRef } from 'react';
import BillingSvg from '../../../../../../../components/icons/billing-icon.svg';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { useResizeObserver } from '../../../../../../../helpers/hooks';
import { MainContent } from './MainContent';

export const Billing = ({
  height = null,
  page,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},

  caseItem,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const theme = useTheme();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      disableScrollbars
      disableContentPaddings
      title="Liens and Bills"
      leftActions={[
        <SvgIcon color="inherit" fontSize="small">
          <BillingSvg />
        </SvgIcon>
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box ref={rootRef} height={isMobile ? height : '100%'} display="flex" flexDirection="column">
        <MainContent width={width} height={height} caseItem={caseItem} />
      </Box>
    </ContentCard>
  );
};
