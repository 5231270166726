import { makeStyles, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { CaseReminders } from '../../../../components/cases/reminders/case-reminders';
import { Indicators } from './Indicators';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Reminders = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isUpSm = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div className={classes.root}>
      {isUpSm &&
        <Paper elevation={0} className={classes.indicators}>
          <Indicators />
        </Paper>
      }

      <div className={classes.reminders}>
        <CaseReminders />
      </div>
    </div>
  );
};
