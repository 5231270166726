import { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import cn from 'classnames';
import {
  makeStyles,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  SvgIcon,
  Fab,
  Box
} from '@material-ui/core';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { mdiAccountOutline } from '@mdi/js';
import AddIcon from '@material-ui/icons/Add';
import { Icon } from '@mdi/react';
import { stopPropagation } from '../../../../../../helpers/dom';
import { getUserNameAbbr, getUserFullName } from '../../../../../../helpers/users';
import { Avatar } from '../../../../../../components/Avatar';
import { IconButton } from '../../../../../../components/IconButton';
import { Tags } from '../../../../../../components/Tags';
import { TagsContext, TagsProvider } from '../../../../files-common';
import { SavedEmailsContext } from '../../SavedEmailsContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Filter = () => {
  const classes = useStyles();
  const {
    isFirstLoaded,
    filter,
    caseItem,
    patientItem,
    messages,
    applyFilter,
    relationsForFilter,
    setRelationsForFilter
  } = useContext(SavedEmailsContext);
  const [ itemOpen, setItemOpen ] = useState({
    cases: true,
    patients: true,
    tags: true
  });
  const [ items, setItems ] = useState({
    cases: null,
    patients: null
  });

  const toggleItemNav = (item) => () => {
    setItemOpen((prevItemOpen) => ({
      ...prevItemOpen,
      [item]: !prevItemOpen[item]
    }));
  };

  const updateFilter = (name, value) => () => {
    setRelationsForFilter({
      ...relationsForFilter,
      [name]: value
    });
  };

  const handleTagsChange = (selectedTags) => {
    applyFilter({
      ...filter,
      tags: selectedTags.map((tag) => tag.value)
    });

    setRelationsForFilter({
      ...relationsForFilter,
      tags: selectedTags
    });
  };

  const getFields = () => {
    const cases = [];
    const patients = [];

    const isFieldExist = (field, array) => {
      return array.find((item) => item.id === field.id) !== undefined;
    };

    messages.forEach((message) => {
      if (message.case && !isFieldExist(message.case, cases)) {
        cases.push(message.case);
      }

      if (message.patient && !isFieldExist(message.patient, patients)) {
        patients.push(message.patient);
      }
    });

    setItems({
      cases,
      patients
    });
  };

  const resetFilter = (item) => () => {
    const filterName = item === 'patients' ? 'patient_id' : 'case_id';

    setRelationsForFilter({
      ...relationsForFilter,
      [filterName]: null
    });
  };

  useEffect(() => {
    getFields();
  }, [ isFirstLoaded ]);

  return (
    <List disablePadding component="nav" className={classes.root}>
      <ListItem
        button
        className={classes.item}
        onClick={!isEmpty(items.cases) ? toggleItemNav('cases') : undefined}
      >
        <ListItemIcon>
          <ExpandMore
            fontSize="small"
            className={cn(
              classes.itemExpand,
              {
                [classes.itemExpandOpened]: itemOpen.cases,
                [classes.itemExpandHidden]: isEmpty(items.cases)
              }
            )}
          />

          <WorkOutlineOutlinedIcon />
        </ListItemIcon>

        <ListItemText
          primary={isEmpty(items.cases) ? 'No cases' : 'Cases'}
          classes={{
            root: classes.itemText,
            primary: classes.itemTextPrimary
          }}
        />

        {!caseItem?.id && filter.case_id &&
          <ListItemSecondaryAction>
            <Box color="info.main">
              <IconButton size="small" color="inherit" onClick={stopPropagation(resetFilter('case'))}>
                <BackupIcon fontSize="small" />
              </IconButton>
            </Box>
          </ListItemSecondaryAction>
        }
      </ListItem>

      {!isEmpty(items.cases) &&
        <Collapse in={itemOpen.cases}>
          <List disablePadding component="nav">
            {items.cases.map((caseItem) => (
              <ListItem
                button
                selected={caseItem.id === relationsForFilter?.case_id?.id}
                key={caseItem.id}
                className={classes.subItem}
                onClick={updateFilter('case_id', caseItem)}
              >
                <ListItemText
                  primary={caseItem.name}
                  secondary={`Case # ${caseItem.file_number || '-'}`}
                  primaryTypographyProps={{
                    noWrap: true
                  }}
                  secondaryTypographyProps={{
                    noWrap: true
                  }}
                  classes={{
                    root: classes.itemText,
                    primary: classes.itemTextPrimary,
                    secondary: classes.itemTextSecondary
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      }

      <ListItem
        button
        className={classes.item}
        onClick={!isEmpty(items.patients) ? toggleItemNav('patients') : undefined}
      >
        <ListItemIcon>
          <ExpandMore
            fontSize="small"
            className={cn(
              classes.itemExpand,
              {
                [classes.itemExpandOpened]: itemOpen.patients,
                [classes.itemExpandHidden]: isEmpty(items.patients)
              }
            )}
          />

          <SvgIcon>
            <Icon path={mdiAccountOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText
          primary={isEmpty(items.patients) ? 'No participants' : 'Participants'}
          classes={{
            root: classes.itemText,
            primary: classes.itemTextPrimary
          }}
        />

        {!patientItem?.id && filter.patient_id &&
          <ListItemSecondaryAction>
            <Box color="info.main">
              <IconButton size="small" color="inherit" onClick={stopPropagation(resetFilter('patients'))}>
                <BackupIcon fontSize="small" />
              </IconButton>
            </Box>
          </ListItemSecondaryAction>
        }
      </ListItem>

      {!isEmpty(items.patients) &&
        <Collapse in={itemOpen.patients}>
          <List disablePadding component="nav">
            {items.patients.map((item) => (
              <ListItem
                button
                selected={item.id === relationsForFilter?.patient_id?.id}
                key={item.id}
                className={classes.subItem}
                onClick={updateFilter('patient_id', item)}
              >
                <ListItemIcon>
                  <Avatar
                    src={item.avatar}
                    alt={getUserNameAbbr(item)}
                    size="md"
                  />
                </ListItemIcon>

                <ListItemText
                  primary={getUserFullName(item)}
                  secondary={item.med_rec && `MRN ${item.med_rec}`}
                  primaryTypographyProps={{
                    noWrap: true
                  }}
                  secondaryTypographyProps={{
                    noWrap: true
                  }}
                  classes={{
                    root: classes.itemText,
                    primary: classes.itemTextPrimary,
                    secondary: classes.itemTextSecondary
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      }

      <TagsProvider filter={{ is_active: true }}>
        <ListItem button className={classes.item} onClick={toggleItemNav('tags')}>
          <ListItemIcon>
            <ExpandMore
              fontSize="small"
              className={cn(
                classes.itemExpand,
                { [classes.itemExpandOpened]: itemOpen.tags }
              )}
            />

            <LocalOfferOutlinedIcon />
          </ListItemIcon>

          <ListItemText
            primary="Tags"
            classes={{
              root: classes.itemText,
              primary: classes.itemTextPrimary
            }}
          />

          {itemOpen.tags &&
            <ListItemSecondaryAction>
              <TagsContext.Consumer>
                {(tagsContext) => (
                  <Fab color="primary" onClick={tagsContext.createTag}>
                    <AddIcon />
                  </Fab>
                )}
              </TagsContext.Consumer>
            </ListItemSecondaryAction>
          }
        </ListItem>

        <Collapse
          in={itemOpen.tags}
          classes={{
            entered: classes.tagsCollapseContainer,
            wrapper: classes.tagsCollapseWrapper,
            wrapperInner: classes.tagsCollapseWrapperInner
          }}
        >
          <Tags withoutAppBar onTagsChange={handleTagsChange} />
        </Collapse>
      </TagsProvider>
    </List>
  );
};
