import { useFormikContext } from 'formik';
import { Grid, Hidden } from '@material-ui/core';
import * as usersApi from '../../../../../../api/users';
import { UsersSelect } from '../../../../../../components/users';
import { getSubscriberInfo } from '../helpers';
import { subscribersMap } from '../insuranceDataMaps';

export const InsuranceSubscriberForm = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleToggleUser = (option) => {
    if (!option) {
      return setFieldValue('subscriber_info', {});
    }

    usersApi.fetchUser(option?.id).then((userValues) => {
      getSubscriberInfo(userValues, setFieldValue, values?.subscriber_info?.subscriber !== subscribersMap.self);
    });
  };

  return (
    <>
      <Hidden only={[ 'xs' ]}>
        <Grid item sm={6} />
      </Hidden>

      <Grid item sm={6} xs={12}>
        <UsersSelect
          required
          isCreatable
          formattedValue={false}
          name="case_user_id"
          label="Subscriber"
          margin="dense"
          params={{
            is_patient: 1
          }}
          onChange={handleToggleUser}
        />
      </Grid>
    </>
  );
};
