export const styles = ({ spacing, typography: { pxToRem } }) => ({
  root: {
    fontSize: pxToRem(14)
  },

  checkIcon: {
    marginRight: spacing(0.25)
  },

  avatar: {
    display: 'inline-flex',
    marginRight: spacing(0.5),
    border: 'none'
  }
});
