import { FormControlLabel, Grid } from '@material-ui/core';
import {
  Checkbox,
  CompaniesAutocomplete,
  CurrencyField,
  TextField
} from '../../../../../../../../components/FormField';

export const PlaintiffsPDForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <CompaniesAutocomplete
          name="towing_co_id"
          label="Towing Co"
        />
      </Grid>

      <Grid item sm={4}>
        <TextField
          name="car_seats"
          label="Car Seats"
          placeholder="Enter Car Seats count..."
        />
      </Grid>

      <Grid item sm={4}>
        <CompaniesAutocomplete
          name="auto_body_shop_id"
          label="Auto Body Shop"
        />
      </Grid>

      <Grid item sm={4}>
        <CurrencyField
          name="towing_paid"
          label="Towing Paid"
          placeholder="Enter Towing Paid..."
        />
      </Grid>

      <Grid item sm={4}>
        <TextField
          name="car_seat_reimb"
          label="Car Seat Reimb."
          placeholder="Enter Car Seat Reimb...."
        />
      </Grid>

      <Grid item sm={4}>
        <CurrencyField
          name="pd_appraised_amount"
          label="PD Appraised Amt."
          placeholder="Enter PD Appraised Amt..."
        />
      </Grid>

      <Grid item sm={4}>
        <CurrencyField
          name="deductible_paid"
          label="Deductible Paid"
          placeholder="Enter Deductible Paid..."
        />
      </Grid>

      <Grid item sm={4}>
        <CurrencyField
          name="final_pd_amount"
          label="Final PD Amount"
          placeholder="Enter Final PD Amount..."
        />
      </Grid>

      <Grid item sm={4}>
        <CompaniesAutocomplete
          name="rental_co_id"
          label="Rental Co"
          placeholder="Enter Rental Co..."
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Total Loss"
          control={<Checkbox name="total_loss" />}
        />
      </Grid>

      <Grid item sm={4}>
        <TextField
          name="rental_paid"
          label="Rental Paid"
          placeholder="Enter Rental Paid..."
        />
      </Grid>

      <Grid item sm={4}>
        <TextField
          name="pd_payer"
          label="PD Payer"
          placeholder="Enter PD Payer..."
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Rental Reimbursed"
          control={<Checkbox name="rental_reimbursed" />}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Deductible Reimbursed"
          control={<Checkbox name="deductible_reimbursed" />}
        />
      </Grid>
    </Grid>
  );
};
