import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { Fab } from '../../../../../components/Fab';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { AppointmentBooksProvider } from './AppointmentBooksContext';
import { FiltersBar } from './FiltersBar';
import { List } from './List';

export const AppointmentBooks = ({ filterKey, hiddenFilterFields = [] }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    filterOpenButton,
    ScrollWrapper,
    scrollWrapperProps,
    FilterBarWrapper,
    filterBarWrapperProps
  } = useMobileFilterCollapse('sm');

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>
          Appointment books
        </PageHeaderTitle>

        <PageHeaderActions>
          <Fab
            size={isMobile ? 'small' : 'medium'}
            component={Link}
            to={`${pathname}/create`}
            variant="extended"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add appt. book
          </Fab>

          {isMobile && filterOpenButton}
        </PageHeaderActions>
      </PageHeader>

      <PageBody fullHeight disablePaddings>
        <AppointmentBooksProvider>
          <ScrollWrapper {...scrollWrapperProps}>
            <FilterBarWrapper {...filterBarWrapperProps}>
              <FiltersBar filterKey={filterKey} hiddenFields={hiddenFilterFields} fullWidthFields={isMobile} />
            </FilterBarWrapper>
          </ScrollWrapper>

          <Box flexGrow={1}>
            <List />
          </Box>
        </AppointmentBooksProvider>
      </PageBody>
    </Page>
  );
};
