import { Box } from '@material-ui/core';
import { CaseModel } from '../../../../app/Dashboard/BillingPage/Invoices/InvoicesPage/InvoicesContent/CaseModel';
import { UserLink } from '../../../UserLink';
import { parentTypesMap } from '../../CopyToModal/parentTypeOptions';

export const Causer = ({ parent, parentType }) => {
  if (!parent) return null;

  return parentType === parentTypesMap.user ? (
    <UserLink size="md" color="primary" user={parent} />
  ) : (
    <Box display="flex" flexDirection="row">
      <CaseModel color="primary" model={parent} variant="body1" iconColor="primary" />
    </Box>
  );
};
