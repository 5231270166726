import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { makeStyles, Grid, InputAdornment, Typography } from '@material-ui/core';
import { TextField } from '../../../FormField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SpentTime = () => {
  const classes = useStyles();
  const { values, errors, setFieldValue } = useFormikContext();
  const isEditable = useRef(false);

  useEffect(() => {
    if (errors?.started_at) return;

    if (!isEditable.current && values?.finished_at && values.started_at) {
      const duration = moment.duration(moment.unix(values.finished_at).diff(moment.unix(values.started_at)));

      setFieldValue('hours', Math.floor(duration.asHours()));
      setFieldValue('minutes', Math.floor(duration.asMinutes()) % 60);
    }
  }, [ values.started_at, values?.finished_at, isEditable.current ]);


  const handleTimeChange = (timeName) => (event) => {
    if (!isEditable.current) {
      return;
    }

    const isHours = timeName === 'h';
    const time = event.target.value || 0;
    const finishedAt = moment.unix(values.started_at)
      .add(isHours ? time : +values.hours || 0, 'h')
      .add(!isHours ? time : +values.minutes || 0, 'm').unix();

    setTimeout(() => {
      setFieldValue('finished_at', finishedAt);
    }, 500);
  };

  const toggleTimeUpdate = (value) => () => {
    isEditable.current = value;
  };

  return (
    <>
      <Grid item md={2} sm={12} xs={12}>
        <Typography variant="subtitle1">
          Time spent
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={6}>
        <TextField
          disabled={!!values.is_edited}
          type="number"
          name="hours"
          label="Hours"
          placeholder="Enter hours..."
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">h</InputAdornment>
            )
          }}
          className={classes.controlField}
          onChange={handleTimeChange('h')}
          onFocus={toggleTimeUpdate(true)}
          onBlur={toggleTimeUpdate(false)}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={6}>
        <TextField
          disabled={!!values.is_edited}
          type="number"
          name="minutes"
          label="Minutes"
          placeholder="Enter minutes..."
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">min</InputAdornment>
            )
          }}
          className={classes.controlField}
          onChange={handleTimeChange('m')}
          onFocus={toggleTimeUpdate(true)}
          onBlur={toggleTimeUpdate(false)}
        />
      </Grid>
    </>
  );
};
