import { useSelector } from 'react-redux';
import { Box, List } from '@material-ui/core';
import { getUserFullName, getUserNameAbbr } from '../../../../../../helpers/users';
import { Scrollbars } from '../../../../../../components/Scrollbars';
import { Divider } from '../../../../../../components/Divider';
import { Avatar } from '../../../../../../components/Avatar';
import { Copyright } from '../Copyright';
import { generateRoutesPaths } from '../generateRoutesPaths';
import { faxes } from '../menuLists/faxes';
import { medicMenuList } from '../menuLists/medicMenuList';
import { MenuItems } from '../MenuItems';
import { LogoLink } from '../LogoLink';
import { UserInfo } from '../UserInfo';
import {
  appointments,
  codesMenu,
  filesMenuList,
  patientMenuList,
  patientsMenuList,
  medicalFormsMenu,
  servicesMenu
} from '../menuLists';

export const MedicSideBarContent = ({ sidebarIsOpen }) => {
  const usersInfo = useSelector(({ globalUser }) => globalUser?.data?.users) || [];

  return (
    <>
      <LogoLink sidebarIsOpen={sidebarIsOpen} />

      <Scrollbars>
        <List>
          <MenuItems isCollapsed={!sidebarIsOpen} routes={medicalFormsMenu} />
          <MenuItems isCollapsed={!sidebarIsOpen} routes={patientsMenuList} />

          {sidebarIsOpen && (
            <Box px={2} py={1}>
              <UserInfo />
            </Box>
          )}

          {usersInfo?.map((user) => (
            <MenuItems
              isDeleted
              name="users"
              item={user}
              key={user?.id}
              isCollapsed={!sidebarIsOpen}
              routes={[ {
                name: getUserFullName(user),
                icon: () => (
                  <Avatar size="md" src={user.avatar} alt={getUserNameAbbr(user)} />
                ),
                path: `/patients/${user.id}`,
                routes: generateRoutesPaths({ list: patientMenuList, params: { patientID: user.id } })
              } ]}
            />
          ))}

          <Divider gutter={2} />
          <MenuItems isCollapsed={!sidebarIsOpen} routes={filesMenuList} />
          <MenuItems isCollapsed={!sidebarIsOpen} routes={[ faxes, appointments ]} />
          <MenuItems isCollapsed={!sidebarIsOpen} routes={medicMenuList} />

          <Box px={2}>
            <Divider gutter={2} />
          </Box>

          <MenuItems isCollapsed={!sidebarIsOpen} routes={codesMenu} />

          <Box px={2}>
            <Divider gutter={2} />
          </Box>

          <MenuItems isCollapsed={!sidebarIsOpen} routes={servicesMenu} />
        </List>
      </Scrollbars>

      {sidebarIsOpen &&
        <Copyright />
      }
    </>
  );
};
