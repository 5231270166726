import { useMediaQuery, useTheme } from '@material-ui/core';
import { useState } from 'react';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import * as pages from '../../../../../../store/dashboard/layout/pages';
import { hasRole } from '../../../../../../utils/hasRole';
import { MobileWidgets } from '../../../../../../components/Widgets/MobileWidgets';
import { widgetsMap } from '../Widgets/widgetsMap';
import { checkedWidgetsTypes } from './Layouts';
import { ClientCase } from './ClientCase';
import { CaseItem } from './CaseItem';
import {
  checkedWidgetsTypes as clientCheckedWidgetsTypes
} from './ClientLayouts';

export const Item = ({ caseItem, setCaseItem }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isClient = hasRole(rolesMap.client, rolesMap.patient);
  const [ widgetFilters, setWidgetFilters ] = useState({});

  const updateFilters = (filters) => {
    setWidgetFilters({ ...widgetFilters, ...filters });
  };

  return isTablet ? (
    <MobileWidgets
      caseItem={caseItem}
      page={isClient ? pages.clientCases : pages.cases}
      onCaseItemUpdate={setCaseItem}
      widgetsMap={widgetsMap}
      widgetFilters={widgetFilters}
      updateFilters={updateFilters}
      checkedWidgetsTypes={isClient ? clientCheckedWidgetsTypes : checkedWidgetsTypes}
    />
  ) : (isClient ?
    <ClientCase
      caseItem={caseItem}
      widgetFilters={widgetFilters}
      updateFilters={updateFilters}
      onCaseItemUpdate={setCaseItem}
    />
    :
    <CaseItem
      caseItem={caseItem}
      widgetFilters={widgetFilters}
      updateFilters={updateFilters}
      onCaseItemUpdate={setCaseItem}
    />
  );
};
