import { useEffect } from 'react';

export const useCalendarEventsCount = ({ isDayGridMonth, daysData, calendarEl }) => {
  useEffect(() => {
    if (!isDayGridMonth) {
      return;
    }

    const days = calendarEl?.querySelectorAll('.fc-daygrid-day');

    days?.forEach((dayEl) => {
      const date = dayEl.closest('[data-date]')?.dataset?.date;
      const dayFrameEl = dayEl.querySelector('.fc-daygrid-day-frame');
      const total = daysData?.[date]?.total;
      const totalEl = dayFrameEl?.querySelector('[data-total-events]');
      const isEqualTotal = +totalEl?.dataset.totalEvents === total;

      if (totalEl && !isEqualTotal) {
        totalEl?.remove();
      }

      if (!total || isEqualTotal) {
        return;
      }

      dayFrameEl?.insertAdjacentHTML('beforeend', `
        <div data-total-events=${total} style="margin: 2px">
          Total: <b>${total}</b>
        </div>
      `);
    });
  }, [ isDayGridMonth, daysData ]);
};
