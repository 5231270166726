import { formTypesMap } from '../../../components/medical/forms';
import { PersonalInjuryOrtho } from './PersonalInjuryOrtho';
import { PersonalInjury } from './PersonalInjury';
import { PrivatePacket } from './PrivatePacket';

export const formComponentsByTypes = {
  [formTypesMap.private]: PrivatePacket,
  [formTypesMap.injury]: PersonalInjury,
  [formTypesMap.ortho]: PersonalInjuryOrtho,
  [formTypesMap.compensation]: PrivatePacket
};
