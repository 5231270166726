import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Box, Dialog, SvgIcon, Typography } from '@material-ui/core';
import { mdiFileAlertOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as rulesApi from '../../../../api/emails-rules';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../Modal';
import { FormStep, validationSchema } from '../../../emails';
import { TextField } from '../../../FormField';
import { Loader } from '../../../Loader';
import { Button } from '../../../Button';
import { BodyActions, BodyConditions, getRuleInitialValues } from '../CreateRuleFormModal';

export const EditRuleFormModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { initialRule }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleEditRule = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return rulesApi.editRuleTemplate(values.id, {
      ...values,

      conditions: values?.conditions?.map((condition) => ({
        key: condition?.key?.value,
        values: condition?.values?.map((value) => value)
      })),

      actions: values?.actions?.map((action) => ({
        key: action?.key?.value,
        value: action?.value
      }))
    }).then(() => {
      enqueueSnackbar('Email rule edited successfully', { variant: 'success' });
      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Email rule not edited', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={getRuleInitialValues({ initialRule })}
      validationSchema={validationSchema}
      onSubmit={handleEditRule}
    >
      {({ errors, touched, isSubmitting, handleSubmit }) => (
        <Dialog fullWidth maxWidth="lg" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiFileAlertOutline} /></SvgIcon>} onClose={handleModalReject}>
              Edit a Rule
            </ModalHeader>

            <ModalBody>
              <FormStep
                withoutTitle
                checked={(touched.name && !errors.name) || (initialRule && !errors.name)}
                step={1}
              >
                <Box width="50%" mb={4} pr={2.5}>
                  <TextField
                    required
                    name="name"
                    label="Name your rule"
                    placeholder="Enter rule name..."
                  />
                </Box>
              </FormStep>

              <FormStep
                checked={(touched.conditions && !errors.conditions) || (initialRule && !errors.conditions)}
                step={2}
              >
                <Typography variant="h4" gutterBottom>Add a condition</Typography>
                <BodyConditions isEditable />
              </FormStep>

              <FormStep
                withoutLine
                checked={(touched.actions && !errors.actions) || (initialRule && !errors.actions)}
                step={3}
              >
                <Typography variant="h4" gutterBottom>Add an action</Typography>
                <BodyActions />
              </FormStep>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
