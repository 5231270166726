export const styles = ({ spacing, typography: { fontWeightMedium } }) => ({
  root: {
    marginTop: spacing(2)
  },

  title: {
    paddingTop: spacing(3)
  },

  noteIcon: {
    display: 'flex',
    alignItems:'center',
    fontWeight: fontWeightMedium,
    paddingRight: spacing(2)
  }
});
