import cn from 'classnames';
import Dropzone from 'react-dropzone';
import { makeStyles, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FileDropZone = (props) => {
  const classes = useStyles();

  return (
    <Dropzone {...props}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={cn(classes.uploadTrigger, { [classes.uploadTrigger_hover]: isDragActive } )}
        >
          <input {...getInputProps()} />

          <CloudUploadIcon color="inherit" classes={{ root: classes.uploadIcon }} />

          <Typography color="inherit">
            Drag 'n' drop some files here, or click to select files
          </Typography>
        </div>
      )}
    </Dropzone>
  );
};
