export const initialValues = {
  number: null,
  _users: null,
  _companies: null,
  billing_participant_ids: null,
  claim_id: null,
  due_date_from: null,
  due_date_to: null,
  status_id: null
};
