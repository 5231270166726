export const styles = ({ spacing, palette: { grey, ...palette }, typography: { fontWeightMedium, pxToRem } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: spacing(),
    border: `1px solid ${palette.divider}`
  },

  videoContent: {
    position: 'relative',
    paddingTop: '56.25%'
  },

  videoImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },

  videoInfo: {
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
    paddingRight: 0,
    maxHeight: '4rem',
    overflow: 'hidden'
  },

  videoTitleName: {
    color: grey[600],
    fontSize: pxToRem(14),
    fontWeight: fontWeightMedium,
    marginLeft: spacing(1)
  },

  videoTitle__subName: {
    color: grey[600],
    fontSize: pxToRem(13)
  }
});
