export const styles = ({
  spacing,
  palette: { primary },
  shape: { borderRadius },
  breakpoints,
  shadows
}) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    width: '100%',
    padding: spacing(2),
    overflow: 'auto',

    [breakpoints.down(breakpoints.values.md)]: {
      padding: spacing(),
      overflow: 'hidden',
      paddingBottom: spacing(5)
    }
  },

  autoSizer: {
    position: 'relative',
    height: '100%',
    width: '100% !important'
  },

  counter: {
    padding: spacing(2),
    margin: spacing(0, 0.15, 1, 1),
    flexShrink: 0,
    overflow: 'auto'
  },

  filterBar: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  }
});
