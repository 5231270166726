import { forwardRef } from 'react';
import {
  RadioGroup as MuiRadioGroup,
  FormControl,
  FormLabel,
  FormHelperText
} from '@material-ui/core';
import { useFormikField } from '../useFormikField';

export const RadioGroup = forwardRef(({
  withoutFormik,
  name,
  value,
  label,
  form,
  FormControlProps = {},
  ...props
}, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField(name);
  const {
    fieldProps: [ field = {}, { touched } = {} ] = [],
    error
  } = formikFieldProps;

  return (
    <FormControl
      ref={ref}
      error={!!(touched && error)}
      {...FormControlProps}
    >
      {!!label && <FormLabel>{label}</FormLabel>}

      <MuiRadioGroup
        {...field}
        name={name}
        value={value || field.value || ''}
        {...props}
      />

      {!!(touched && error) && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
});
