import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import * as expensesApi from '../../../../../../../../../api/billing';
import { ConfirmationModal } from '../../../../../../../../../components/ConfirmationModal';
import { useModal as useModalHook } from '../../../../../../../../../components/ModalsProvider';
import { rolesMap } from '../../../../../../../../../dataMaps/rolesMap';
import { setFilesLastGlobalAction } from '../../../../../../../../../store/globalActions';
import { Label } from '../../../../../../../../../components/Label';
import { hasRole } from '../../../../../../../../../utils/hasRole';
import { FilePreviewModal } from '../../../../../../../files-common';
import { Category } from '../../../../../../BillingSettings/Category';
import * as filesTypes from '../../../../../../FilesContext/types';
import { expensesStatusesMap } from '../../../../../../../../../components/billing';
import { getFileMenuOptions } from '../../../../../Expenses/List/getFileMenuOptions';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Footer = ({ expense }) => {
  const classes = useStyles();
  const { openModal } = useModalHook();
  const { enqueueSnackbar } = useSnackbar();
  const filesLastGlobalAction = useSelector(({ globalActions }) => globalActions.filesLastGlobalAction);
  const [ files, setFiles ] = useState(expense.files || []);
  const isAbleToDeleteFile = !hasRole(rolesMap.advocate);

  const handleDeleteFile = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        expensesApi.deleteFiles([ id ]).then(() => {
          setFilesLastGlobalAction({ type: filesTypes.DELETE_FILES, payload: [ id ] });
          setFiles(files.filter((item) => item.id !== id));
          enqueueSnackbar('File successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      previewMenuOptions={getFileMenuOptions(handleDeleteFile, isAbleToDeleteFile)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file: files?.[0],
        files: files || []
      }}
    />
  ), [ files?.length ]);

  useEffect(() => {
    if (filesLastGlobalAction) {
      if (filesLastGlobalAction.type === filesTypes.FILE_UPLOAD_SUCCESS) {
        if (filesLastGlobalAction.payload.expenseID === expense.id) {
          const file = files.find(({ id }) => id ===  filesLastGlobalAction.payload.file.id);

          setFiles(file ? files : [ ...files, filesLastGlobalAction.payload.file ]);
        }
      }

      if (filesLastGlobalAction.type === filesTypes.DELETE_FILES) {
        if (files.length) {
          setFiles(files.filter(({ id }) => !filesLastGlobalAction.payload.includes(id)));
        }
      }
    }
  }, [ filesLastGlobalAction ]);

  return (
    <div className={classes.root}>
      <div className={classes.contact}>
        {!expense.category ? '-' :
          <Category category={expense.category} />
        }

        {!!files?.length &&
          <div
            className={classes.expensesFiles}
            onClick={openFilesPreview}
          >
            <AttachFileIcon fontSize="inherit"/>

            {files.length}
          </div>
        }
      </div>

      {!expensesStatusesMap[expense.status?.name?.toLowerCase()] ? '-' :
        <Label
          label={expensesStatusesMap[expense.status.name.toLowerCase()].name}
          color={expensesStatusesMap[expense.status.name.toLowerCase()].color}
          className={classes.statusLabel}
        />
      }
    </div>
  );
};
