import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AccountsProvider, SavedEmails } from '../../../EmailsPage';
import { filterFieldsMap } from '../../../EmailsPage/SavedEmails/FiltersBar/filterFieldsMap';
import { UserContext } from '../../../Members/User/UserProvider';
import { styles } from './styles';

const hiddenFilterFields = [
  filterFieldsMap.patientID
];

const useStyles = makeStyles(styles);

export const Emails = () => {
  const classes = useStyles();
  const { isFetched, user } = useContext(UserContext);
  const filter = {
    patient_id: user.id
  };

  return isFetched && (
    <div className={classes.root}>
      <AccountsProvider disableCounters>
        <SavedEmails
          filter={filter}
          patientItem={user}
          hiddenFilterFields={hiddenFilterFields}
        />
      </AccountsProvider>
    </div>
  );
};
