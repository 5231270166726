
export const initialCallReportsFilters = {
  user_id: null,
  parent_user_ids: null,
  parent_case_ids: null,
  phone: null,
  disposition: null,
  date_from: null,
  date_to: null
};
