import { useContext } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../../../../../../components/CompanyLink';
import { CardItem, CardHeader } from '../../../../../../../../../components/Cards';
import { UserLink } from '../../../../../../../../../components/UserLink';
import { CaseDiscoveriesContext } from '../../CaseDiscoveriesProvider';
import { discoveryTypeLabelsMap } from '../../DiscoveryForm';
import { discoveryMemberTypesMap } from '../../DiscoveryForm/discoveryMemberTypes';
import { userTypeLabelsMap } from '../../DiscoveryForm/userTypeOptions';
import { Menu } from './Menu';
import { Body } from './Body';

export const Card = ({ item: discovery = {} }) => {
  const { reloadAppointments } = useContext(CaseDiscoveriesContext);

  return (
    <CardItem disableHover py={1} px={2}>
      <CardHeader
        disableCheckBox
        childrenWidth={11}
        menuComponent={Menu}
        menuComponentProps={{
          discovery,
          onAppointmentUpdate: reloadAppointments,
          onAppointmentDelete: reloadAppointments
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={6}>
            {discovery.from_type === discoveryMemberTypesMap.user ? (
              <UserLink
                size="lg"
                variant="h5"
                user={discovery?.from}
              >
                <Typography>
                  From
                </Typography>
              </UserLink>
            ) : (
              <CompanyLink
                size="lg"
                variant="h5"
                company={discovery?.from}
              >
                <Typography>
                  From
                </Typography>
              </CompanyLink>
            )}
          </Grid>

          <Grid item sm={6}>
            {discovery.to_type === discoveryMemberTypesMap.user ? (
              <UserLink
                size="lg"
                variant="h5"
                user={discovery?.to}
              >
                <Typography>
                  To
                </Typography>
              </UserLink>
            ) : (
              <CompanyLink
                size="lg"
                variant="h5"
                company={discovery?.to}
              >
                <Typography>
                  To
                </Typography>
              </CompanyLink>
            )}
          </Grid>
        </Grid>
      </CardHeader>

      <Box py={2}>
        <Body discovery={discovery} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>
            Discovery Type
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h4">
            {discoveryTypeLabelsMap[discovery.type] || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>
            User Type
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h4">
            {userTypeLabelsMap[discovery.user_type] || '-'}
          </Typography>
        </Grid>
      </Grid>
    </CardItem>
  );
};
