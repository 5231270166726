import moment from 'moment';
import { api } from '../../api';

const transformInsurance = (insurance) => {
  const dob = insurance?.subscriber_info?.dob || null;
  const isUnix = moment(dob, 'X', true).isValid();
  const isIso = moment(dob, moment.HTML5_FMT.DATE, true).isValid();
  const subscriberInfo = !!insurance?.subscriber_info ? {
    subscriber_info: {
      ...insurance?.subscriber_info,
      dob: isIso ? dob : isUnix ? moment(dob, 'X').format(moment.HTML5_FMT.DATE) : null
    }
  } : {};
  const isExpired = !!insurance?.expiration_day && moment.unix(insurance?.expiration_day)
    .add(1, 'd')
    .startOf('day')
    .diff(moment(), 'days') <= 0;

  return {
    ...insurance,
    ...subscriberInfo,

    isExpired
  };
};

export const fetchInsurances = (config = {}) => {
  return api.get('/insurances', config)
    .then(({ data }) => {
      data.data = data.data.map(transformInsurance);

      return data;
    })
    .catch((error) => { throw error.data; });
};

export const fetchInsurance = (id, config) => {
  return api.get(`/insurances/${id}`, config)
    .then(({ data }) => transformInsurance(data))
    .catch((error) => { throw error.data; });
};

export const createInsurance = (data) => {
  return api.post('/insurances', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const editInsurance = (id, data) => {
  return api.post(`/insurances/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateInsurance = (id, data) => {
  return api.put(`/insurances/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteInsurance = (id) => {
  return api.delete(`/insurances/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchInsurancesTypes = (config = {}) => {
  return api.get('/insurances/types', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
