export const orderByMap = {
  updated_at: 'updated_at',
  created_at: 'created_at',
  name: 'name'
};

export const orderByLabelsMap = {
  updated_at: 'Updated At',
  created_at: 'Created At',
  name: 'Name'
};

export const orderByOptions = [
  { label: orderByLabelsMap.updated_at, value: orderByMap.updated_at },
  { label: orderByLabelsMap.created_at, value: orderByMap.created_at },
  { label: orderByLabelsMap.name, value: orderByMap.name }
];
