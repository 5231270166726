import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import {
  Autocomplete,
  CompaniesAutocomplete,
  KeyboardDateTimePicker,
  Select as DefaultSelect,
  TimeTracksCategoriesSelect
} from '../../FormField';
import { UsersSelect } from '../../users';
import { CaseMembersSelect } from '../../users/CaseMembersSelect';
import { tracksTypes, tracksTypesOptions } from '../EditLogTimeModal/tracksTypesOptions';
import { transformInitialValues } from './LogTimeModal';
import { SpentTime } from './SpentTime';

export const TimeContent = ({
  isModelFields = false,
  event,
  caseItem,
  onCaseItemUpdate = () => {}
}) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const [ members, setMembers ] = useState(caseItem?.case_users);
  const Select = values.model ? tracksTypes?.[values.model?.value || values.model].select : DefaultSelect;
  const isCase = (values.model?.value || values.model) === tracksTypes.case.value;

  const handleTrackCategoryChange = (category) => {
    if (!category) {
      return;
    }

    const duration = moment.duration(category?.time, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();

    setFieldValue('hours', hours);
    setFieldValue('minutes', minutes);
    setFieldValue('billing_rate', category?.rate);

    if (values.started_at) {
      const finishedAt = moment.unix(values.started_at)
        .add(hours || 0, 'h')
        .add(minutes || 0, 'm').unix();

      setFieldValue('finished_at', finishedAt);
    }
  };

  const handleAddMembers = (values) => {
    onCaseItemUpdate(values);
    setMembers(values?.case_users);
  };

  const handleChange = async (option) => {
    if (values.model === tracksTypes.schedule_event.value && option) {
      const transformedValues = await transformInitialValues(option);

      setFieldValue('started_at', option.started_at);
      setFieldValue('finished_at', option.finished_at);

      if (!values?.hours && !values.minutes) {
        setFieldValue('hours', transformedValues.hours);
        setFieldValue('minutes', transformedValues.minutes);
      }
    }
  };

  const handleModelChange = () => {
    setFieldValue('model_id', null);
  };

  useEffect(() => {
    if (errors?.started_at) return;

    if (!values?.finished_at && values.started_at) {
      const finishedAt = moment.unix(values.started_at)
        .add(+values.hours || 0, 'h')
        .add(+values.minutes || 0, 'm')
        .unix();

      setFieldValue('finished_at', finishedAt);
    }
  }, [ values.started_at, errors?.started_at ]);

  return (
    <>
      {isModelFields && (
        <>
          <Grid item md={6} sm={6} xs={12}>
            <Autocomplete
              required
              name="model"
              label="Model type"
              options={tracksTypesOptions}
              onChange={handleModelChange}
            />
          </Grid>

          {!!values.model ? (
            <Grid item md={6} sm={6} xs={12}>
              <Select
                required
                name="model_id"
                placeholder="Search..."
                label={tracksTypes?.[values.model]?.label}
                onChange={handleChange}
              />
            </Grid>
          ) : (
            <Grid item sm={6} xs={12}/>
          )}
        </>
      )}

      {hasRole(rolesMap.admin, rolesMap.supervisor) && (
        <Grid item md={4} sm={6} xs={12}>
          {isCase && !isModelFields ? (
            <CaseMembersSelect
              required
              isCreatable
              name="user_id"
              label="User"
              creatablePayload={{
                caseItem,
                onCaseItemUpdate: handleAddMembers
              }}
              options={members}
            />
          ) : (
            <UsersSelect
              name="user_id"
              label="User"
              options={event?.users}
            />
          )}
        </Grid>
      )}

      <Grid item md={4} sm={6} xs={12}>
        <CompaniesAutocomplete
          disabled={!!values.is_edited}
          label="Filter category by name company"
          name="company_id"
        />
      </Grid>

      <Grid item md={4} sm={6} xs={12}>
        <TimeTracksCategoriesSelect
          name="category_id"
          disabled={!!values.is_edited}
          params={{
            is_active: 1,
            time_track_model: values?.time_track_model,
            time_track_model_id: values?.time_track_model_id?.id || values?.time_track_model_id,
            company_id: values?.company_id,
            case_id: isCase ? (values?.model_id?.value || values?.model_id) : null
          }}
          onChange={handleTrackCategoryChange}
        />
      </Grid>

      <Grid item xs={12} container spacing={3} alignItems="flex-end">
        <Grid item md={2} sm={6} xs={12}>
          <KeyboardDateTimePicker
            required
            disabled={!!values.is_edited}
            minDate={moment().utc().subtract(1, 'year').unix()}
            name="started_at"
            label="Time started"
          />
        </Grid>

        <Grid item md={2} sm={6} xs={12}>
          <KeyboardDateTimePicker
            required
            disableFuture
            disabled={!!values.is_edited}
            name="finished_at"
            label="Time finished"
          />
        </Grid>

        <SpentTime />
      </Grid>
    </>
  );
};
