import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, FormControl, FormControlLabel } from '@material-ui/core';
import * as eventsApi from '../../../../../api/schedule-events';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../../Modal';
import { Loader } from '../../../../Loader';
import { RadioGroup, Radio } from '../../../../FormField';

const movingVariantsMap = {
  thisOnly: 'thisOnly',
  thisAndFollowings: 'thisAndFollowings'
};

export const MoveScheduleEventModal = ({
  DialogProps,
  payload: {
    event
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const moveEvent = ({ variant }, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const method = variant === movingVariantsMap.thisOnly ? 'updateEvent' : 'updateRecurringTemplate';

    eventsApi[method](event).then((event) => {
      enqueueSnackbar(`Event "${event.title}" successfully moved`, { variant: 'success' });
      handleModalResolve(event);
    }).catch(() => {
      enqueueSnackbar('Something went wrong. Try again later...', { variant: 'error' });
      handleModalReject();
    });
  };

  return (
    <Formik
      initialValues={{
        variant: movingVariantsMap.thisOnly
      }}
      onSubmit={moveEvent}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="xs" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Move recurring event
            </ModalHeader>

            <ModalBody>
              <FormControl fullWidth component="fieldset" margin="dense">
                <RadioGroup name="variant">
                  <FormControlLabel
                    value={movingVariantsMap.thisOnly}
                    label="This event"
                    control={<Radio name="variant" color="primary" />}
                  />

                  <FormControlLabel
                    value={movingVariantsMap.thisAndFollowings}
                    label="This and following events"
                    control={<Radio name="variant" color="primary" />}
                  />
                </RadioGroup>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Move
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
