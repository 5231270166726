import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox, Select } from '../../../../../../../components/FormField';
import { transformCertificates } from '../helpers';

export const Body = ({ certificates }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    !values.is_certificate && setFieldValue('certificate_id', null);
  }, [ values.is_certificate ]);

  return (
    <>
      <FormControlLabel
        label="Sign with certificate"
        control={<Checkbox name="is_certificate" color="primary" />}
      />

      <Select
        isClearable
        formattedValue
        isDisabled={!values.is_certificate}
        name="certificate_id"
        label="Select certificate"
        options={transformCertificates(certificates)}
      />
    </>
  );
};
