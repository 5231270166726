import { useContext } from 'react';
import {
  makeStyles,
  SvgIcon,
  Typography,
  Link,
  Box,
  Checkbox,
  Grid
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Icon } from '@mdi/react';
import { mdiDomain, mdiMapMarker } from '@mdi/js';
import { Popper } from '../../../../../../../components/Popper';
import { stopPropagation } from '../../../../../../../helpers/dom/stopPropagation';
import { IconButton } from '../../../../../../../components/IconButton';
import { OfficesContext } from '../../../OfficesContext';
import { OfficesMenu } from '../../../OfficesMenu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ office }) => {
  const classes = useStyles();
  const { selectedIDs, toggleItemSelection } = useContext(OfficesContext);
  const isSelected = selectedIDs.indexOf(office.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(office.id);
  };

  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      alignItems="flex-start"
      justify="space-between"
      className={classes.root}
    >
      <Grid item className={classes.logoIcon}>
        <SvgIcon className={classes.icon}>
          <Icon path={mdiDomain} />
        </SvgIcon>
      </Grid>

      <Grid
        item
        xs={7}
        container
        spacing={1}
        direction="column"
        className={classes.header}
      >
        <Grid item xs={12}>
          <Typography noWrap title={office.name} variant="h6">
            {office.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box ml={-0.5} display="flex" alignItems="flex-end">
            <SvgIcon color="primary"><Icon path={mdiMapMarker} /></SvgIcon>

            <Typography
              color="primary"
              component={Link}
              href="#"
            >
              Show on the map
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid item>
        <Box display="flex" alignItems="center">
          <Checkbox checked={isSelected} onClick={stopPropagation(handleItemSelection)}/>

          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                  <MoreVertIcon/>
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <OfficesMenu office={office} onClose={handleClose} />
            )}
          </Popper>
        </Box>
      </Grid>
    </Grid>
  );
};
