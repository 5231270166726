import { useEffect, useState } from 'react';
import cn from 'classnames';
import { get } from 'lodash';
import { Box, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { NestedList } from '../../../../../../../components/NestedList';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { painMap } from '../painMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const BackPain = ({ isOpenSwitches, form }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [ isOpen, setIsOpen ] = useState(false);
  const experiences = Object.keys(get(form, 'forms.pain_in_low_back.experience_following', {}));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(form?.forms?.pain_in_low_back) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="If pain in low back"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box p={isSmallScreen ? 1 : 3}>
        {!!get(form, 'forms.pain_in_low_back.how_often_your_pain') && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              How often is your pain?
            </Typography>

            <ReadonlyCheckbox
              title={get(form, 'forms.pain_in_low_back.how_often_your_pain')}
              checked={!!get(form, 'forms.pain_in_low_back.how_often_your_pain')}
              className={classes.contentInfoTitle}
            />
          </div>
        )}

        {get(form, 'forms.pain_in_low_back.what_severity_your_pain') && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              What is the severity of your pain?
            </Typography>

            <ReadonlyCheckbox
              title={get(form, 'forms.pain_in_low_back.what_severity_your_pain', '-')}
              checked={!!get(form, 'forms.pain_in_low_back.what_severity_your_pain')}
              className={classes.contentInfoTitle}
            />
          </div>
        )}

        {!!form?.forms.pain_in_low_back?.pain_radiate_other_parts_body && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Does your pain radiate to other parts of your body?
            </Typography>

            <ReadonlyCheckbox
              checked
              title={
                form?.forms.pain_in_low_back?.pain_radiate_other_parts_body > 0
                  ? 'Yes' : 'No'
              }
              className={classes.contentInfoTitle}
            />
          </div>
        )}

        {!!form?.forms?.pain_in_low_back?.if_yes_how_often && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              If yes, how often?
            </Typography>

            <ReadonlyCheckbox
              checked
              title={form?.forms?.pain_in_low_back?.if_yes_how_often}
              className={classes.contentInfoTitle}
            />
          </div>
        )}

        {form?.forms?.pain_in_low_back?.where_pain_radiate_too && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Where does your pain radiate to?
            </Typography>

            <div className={classes.contentInfo}>
              {form?.forms?.pain_in_low_back?.where_pain_radiate_too?.thigh && (
                <ReadonlyCheckbox
                  checked
                  title="Thigh"
                  className={classes.contentInfoTitle}
                  rootClassName={classes.checkboxContent}
                />
              )}

              {form?.forms?.pain_in_low_back?.where_pain_radiate_too?.leg && (
                <ReadonlyCheckbox
                  checked
                  title="Leg"
                  className={classes.contentInfoTitle}
                  rootClassName={classes.checkboxContent}
                />
              )}

              {form?.forms?.pain_in_low_back?.where_pain_radiate_too?.foot && (
                <ReadonlyCheckbox
                  checked
                  title="Foot"
                  className={classes.contentInfoTitle}
                  rootClassName={classes.checkboxContent}
                />
              )}
            </div>
          </div>
        )}

        {!!form?.forms?.pain_in_low_back?.which_side_pain_radiate_too && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Which side of body does the pain radiate to?
            </Typography>

            <ReadonlyCheckbox
              checked
              title={form?.forms?.pain_in_low_back?.which_side_pain_radiate_too}
              className={classes.contentInfoTitle}
            />
          </div>
        )}

        {!!experiences.length && (
          <div className={classes.content}>
            <Typography className={cn(classes.formContent, classes.formContent__title)}>
              Do you experience any of the following?
            </Typography>

            {experiences.map((item, index) => (
              <Grid key={index} container spacing={2}>
                <Grid item sm={3} xs={12}>
                  <ReadonlyCheckbox
                    checked={form?.forms?.pain_in_low_back?.experience_following?.[item]?.value}
                    title={painMap[item]}
                  />
                </Grid>

                {!!form?.forms?.pain_in_low_back?.experience_following?.[item]?.description &&
                  <Grid container item sm={6} xs={12} alignItems="flex-start">
                    <Grid item>
                      <div className={classes.noteIcon}>
                        <NotesIcon fontSize="small" color="inherit" /> Note
                      </div>
                    </Grid>

                    <Grid item xs={10}>
                      <Typography color="textSecondary">
                        {form?.forms?.pain_in_low_back?.experience_following?.[item]?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              </Grid>
            ))}
          </div>
        )}

        {!!form?.forms?.pain_in_low_back?.avoided_strenuous_lifting && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Have you avoided strenuous lifting, carrying, pushing, pulling, stooping and bending because
              of your injury?
            </Typography>

            {!!form?.forms?.pain_in_low_back?.avoided_strenuous_lifting &&
              <ReadonlyCheckbox
                checked
                title={
                  form?.forms?.pain_in_low_back?.avoided_strenuous_lifting > 0 ? 'Yes' : 'No'
                }
                className={classes.contentInfoTitle}
              />
            }
          </div>
        )}

        <div className={cn(classes.content, classes.content_row)}>
          <Typography className={classes.formContent}>
            How long can you do the following until the pain interferes?
          </Typography>

          <div className={classes.contentInfo}>
            <Typography color="textSecondary" className={classes.contentInfo__inner}>
              {`Sit 
                ${form?.forms?.pain_in_low_back?.following_until_pain_interferes?.sit || '0'} 
                  minutes`
              }
            </Typography>

            <Typography color="textSecondary" className={classes.contentInfo__inner}>
              {`Walk 
                ${form?.forms?.pain_in_low_back?.following_until_pain_interferes?.walk || '0'} 
                  minutes`
              }
            </Typography>

            <Typography color="textSecondary" className={classes.contentInfo__inner}>
              {`Stand 
                ${form?.forms?.pain_in_low_back?.following_until_pain_interferes?.stand || '0'} 
                  minutes`
              }
            </Typography>
          </div>
        </div>
      </Box>
    </NestedList>
  );
};
