import { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { makeStyles, Grid } from '@material-ui/core';
import { useDebounce } from '../../../../../helpers/hooks';
import { AppBar } from '../../../../../components/AppBar';
import { TextField } from '../../../../../components/FormField';
import { TemplatesContext } from '../../../FilesPage/TemplatesPage/TemplatesContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FiltersBar = () => {
  const classes = useStyles();
  const { resetTemplates } = useContext(TemplatesContext);
  const [ fields, setFields ] = useState({});
  const debouncedFields = useDebounce(fields, 600);

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
  };

  useEffect(() => {
    resetTemplates(debouncedFields);
  }, [ debouncedFields ]);

  return (
    <AppBar className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <Formik initialValues={{}}>
        {() => (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                name="name"
                label="Search"
                placeholder="Search..."
                onChange={handleFieldChange('name')}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </AppBar>
  );
};
