import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Dialog, Button, FormControlLabel, Checkbox, useTheme, useMediaQuery, Box } from '@material-ui/core';
import * as filesApi from '../../../../api/files';
import { setFilesLastGlobalAction } from '../../../../store/globalActions';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../components/Modal';
import { Loader } from '../../../../components/Loader';
import { useModal } from '../../../../components/ModalsProvider';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import {
  FilesFilterContext,
  FilesFilterContextProvider,
  FilesContext,
  FilesContextProvider,
  FileCopingConflictModal
} from '../../files-common';
import * as filesActionTypes from '../FilesContext/types';
import { FiltersBar } from './FiltersBar';
import { List } from './List';

export const SelectAvailableFilesModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    isSingle = false,
    disableCoping,
    owner_type,
    owner_id,
    filter = {},
    hiddenFilterFields
  }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { openModal } = useModal();
  const dispatchRedux = useDispatch();
  const [ withTags, setWithTags ] = useState(true);
  const [ numberOfCopy, setNumberOfCopy ] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  const handleTagsCheckboxToggle = () => {
    setWithTags((checked) => !checked);
  };

  const copyFile = (file) => {
    const data = { ...file, owner_type, owner_id };

    if (withTags && file.tags) {
      data.tags = file.tags.map(({ id }) => id);
    }

    setNumberOfCopy((state) => state + 1);

    filesApi.copyFile(data).then((data) => {
      setNumberOfCopy((state) => state - 1);
      dispatchRedux(setFilesLastGlobalAction({ type: filesActionTypes.ADD_FILES, payload: [ data ] }));
      enqueueSnackbar('Files successfully added', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ status }) => {
      if (status === 409 ) {
        openModal(FileCopingConflictModal, {
          payload: data,
          onModalResolved: () => {
            setNumberOfCopy((state) => state - 1);
          },
          onModalRejected: () => {
            setNumberOfCopy((state) => state - 1);
          }
        });
      } else {
        enqueueSnackbar(`File "${file.original_filename}" is invalid`, {
          variant: 'error'
        });
      }
    });
  };

  const copyFiles = ({ files, selectedFilesIDs }) => {
    selectedFilesIDs.forEach((id) => {
      copyFile(files.find((file) => id === file.id));
    });
  };

  const selectFiles = ({ files, selectedFilesIDs }) => {
    const selectedFiles = files.filter((file) => selectedFilesIDs.includes(file.id));

    handleModalResolve(selectedFiles);
  };

  const applyFiles = ({ files, selectedFilesIDs }) => () => {
    if (disableCoping) {
      selectFiles({ files, selectedFilesIDs });
    } else {
      copyFiles({ files, selectedFilesIDs });
    }
  };

  return (
    <Dialog fullWidth fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <FilesFilterContextProvider isSingleSelection={isSingle}>
        <FilesContextProvider initialState={{ filter }}>
          <FilesFilterContext.Consumer>
            {({ selectedFilesIDs }) => (
              <FilesContext.Consumer>
                {({ files }) => (
                  <ModalContainer>
                    <ModalHeader onClose={handleModalReject}>
                      Select files
                    </ModalHeader>

                    <ModalBody disablePaddings disableScrollbars>
                      {isMobile &&
                        <Box px={2} display="flex" justifyContent="flex-end">
                          {filterOpenButton}
                        </Box>
                      }

                      <ScrollWrapper {...scrollWrapperProps}>
                        <FilterBarWrapper {...filterBarWrapperProps}>
                          <FiltersBar hiddenFilterFields={hiddenFilterFields} />
                        </FilterBarWrapper>
                      </ScrollWrapper>

                      <List />
                    </ModalBody>

                    <ModalFooter
                      title={!disableCoping && (
                        <FormControlLabel
                          label="Copy files with their tags"
                          control={<Checkbox checked={withTags} onChange={handleTagsCheckboxToggle} />}
                        />
                      )}
                    >
                      <Button onClick={handleModalReject}>
                        Cancel
                      </Button>

                      <Loader surface loading={!!numberOfCopy}
                        render={() => (
                          <Button
                            disabled={!selectedFilesIDs.length || !!numberOfCopy}
                            variant="contained"
                            color="primary"
                            onClick={applyFiles({ files, selectedFilesIDs })}
                          >
                            Apply
                          </Button>
                        )}
                      />
                    </ModalFooter>
                  </ModalContainer>
                )}
              </FilesContext.Consumer>
            )}
          </FilesFilterContext.Consumer>
        </FilesContextProvider>
      </FilesFilterContextProvider>
    </Dialog>
  );
};
