import { useEffect, useContext } from 'react';
import { makeStyles, List as MuiList, Typography, useTheme, useMediaQuery, Box } from '@material-ui/core';
import { Loader } from '../../../../../../../../components/Loader';
import { TablePagination } from '../../../../../../../../components/TablePagination';
import { AppointmentTypesContext } from '../../../../AppointmentTypes/AppointmentTypesContext';
import { Card } from '../../../../AppointmentTypes/Grid/Card';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

export const minRowHeight = 54;
export const columnsWidths = {
  checkbox: 58,
  code: 200,
  description: 300,
  duration: 100,
  claimRequired: 120,
  ordering: 100,
  apptBooks: 100
};

const useStyles = makeStyles(styles);

export const List = () => {
  const {
    appointmentTypes,
    isFetched,
    filter,
    resetAppointmentTypes
  } = useContext(AppointmentTypesContext);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    resetAppointmentTypes();
  }, []);

  return (
    <Loader loading={!isFetched} render={
      () => (!filter.total ?
        <Typography align="center">No appointment types found</Typography>
        :
        <div className={classes.list}>
          {!isMobile && <TableHeader />}

          <MuiList disablePadding>
            {appointmentTypes.map((appointmentType) => (
              isMobile ? (
                <Box mb={1} key={appointmentType.id}>
                  <Card item={appointmentType} />
                </Box>
              ) : <Row key={appointmentType.id} appointmentType={appointmentType} />
            ))}
          </MuiList>

          <TablePagination pagination={filter} onChange={resetAppointmentTypes} />
        </div>
      )}
    />
  );
};
