import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { Button, makeStyles, Tooltip, Typography, Dialog, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { useResizeObserver } from '../../../../../../../../helpers/hooks/useResizeObserver';
import * as billingApi from '../../../../../../../../api/billing';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { ModalBody, ModalContainer, ModalHeader } from '../../../../../../../../components/Modal';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { FileDropZoneWindow } from '../../../../../../../../components/FileDropZoneWindow';
import { Loader } from '../../../../../../../../components/Loader';
import { PaymentsContextProvider } from '../../../../../../../../components/billing/payments';
import { setInvoiceItem } from '../../../../../../../../store/dashboard/billing';
import { InvoiceFileUploadModal } from '../../../../../../BillingPage/Invoices/InvoiceFileUploadModal';
import { PaymentsContent } from '../../../../../../BillingPage/Invoices/InvoiceItem/PaymentsContent';
import { InvoicesContextProvider } from '../../../../../../BillingPage/Invoices/InvoicesContext';
import { FilesFilterContextProvider } from '../../../../../../files-common';
import {
  ActionsBar,
  Header,
  Body,
  Attachments,
  IncomesContent
} from '../../../../../../BillingPage/Invoices/InvoiceItem';
import {
  IncomesContextProvider,
  IncomesFilterContextProvider
} from '../../../../../../BillingPage/Invoices/Incomes';
import { FilesContextProvider } from '../../../../../../BillingPage/FilesContext';
import { Expenses } from '../../../../../../BillingPage/Invoices/Expenses';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PreviewInvoiceModal = ({
  DialogProps,
  handleModalReject,
  payload: {
    disableAction = false,
    disablePayment = false,
    invoiceID,
    paymentForm,
    confirmInvoice,
    cancelInvoice
  }
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ invoice, setInvoice ] = useState({});
  const [ total, setTotal ] = useState(0);
  const [ isFetched, setIsFetched ] = useState(false);
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const initialState = {
    filter: {
      invoice_id: invoiceID
    }
  };

  const initialStateOfFiles = {
    filter: {
      owner_type: 'invoice',
      owner_id: invoiceID
    }
  };

  const uploadFiles = (files = []) => () => {
    openModal(InvoiceFileUploadModal, {
      payload: {
        files,
        owner_type: 'invoice',
        owner_id: invoiceID
      }
    });
  };

  const handleFilesDropped = (files) => {
    uploadFiles(files)();
  };

  const handleClose = () => {
    setInvoice({});
    handleModalReject();
  };


  const handleReceivePayment = () => {
    if (invoice.is_confirmed) {
      handleModalReject();

      dispatch(setInvoiceItem({
        title: 'Receive Payment',
        parentPage: 'invoice',

        ...invoice
      }));

      history.push('/billing/payments/create');
    } else {
      enqueueSnackbar('You should confirm invoice');
    }
  };

  useEffect(() => {
    setIsFetched(false);

    billingApi.fetchInvoice(invoiceID).then((invoice) => {
      setInvoice(invoice);
      setTotal(invoice.total);
      setIsFetched(true);
    });
  }, []);

  return (
    <InvoicesContextProvider>
      <FilesFilterContextProvider>
        <FileDropZoneWindow onDropAccepted={handleFilesDropped}>
          <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
            <Loader loading={!isFetched} p={3} render={
              () => (
                <ModalContainer>
                  <ModalHeader disableTypography onClose={handleClose}>
                    <ActionsBar
                      disableAction={disableAction}
                      disableBorder
                      invoice={invoice}
                      confirmInvoice={confirmInvoice}
                      cancelInvoice={cancelInvoice}
                      onInvoiceUpdate={setInvoice}
                      handleModalReject={handleModalReject}
                    />
                  </ModalHeader>

                  <ModalBody disablePaddings>
                    <Header invoice={invoice} />

                    <Body parentPage="preview" width={width} invoice={invoice}/>

                    {!isMobile && (
                      <>
                        <FilesContextProvider initialState={initialStateOfFiles}>
                          <Attachments invoice={invoice} />
                        </FilesContextProvider>

                        <Box my={1} mx={2}>
                          <IncomesFilterContextProvider>
                            <IncomesContextProvider initialState={initialState}>
                              <IncomesContent width={width} invoice={invoice} setTotal={setTotal} />
                            </IncomesContextProvider>
                          </IncomesFilterContextProvider>
                        </Box>

                        <PaymentsContextProvider filter={initialState.filter}>
                          <PaymentsContent invoice={invoice} />
                        </PaymentsContextProvider>

                        <Box my={1} mx={2}>
                          <Expenses
                            onTotalTimeUpdate={setTotal}
                            parentPage="invoice"
                            invoice={invoice}
                          />
                        </Box>
                      </>
                    )}

                    <Typography className={classes.footerTitle}>
                      Notes
                    </Typography>

                    <div className={classes.footer}>
                      <div className={classes.note}>
                        <Typography className={classes.note__title}>
                          {invoice.notes}
                        </Typography>
                      </div>

                      <div className={classes.total}>
                        <div className={classes.totalTitle}>
                          <div>
                            <Typography className={classes.total__title}>Total</Typography>

                            <Typography className={classes.total__title}>Payment</Typography>
                          </div>

                          <Typography variant="h6">Balance due</Typography>
                        </div>

                        <div className={cn(classes.totalTitle, classes.totalTitle_sum)}>
                          <div>
                            <Typography className={classes.total__sum}>
                              <CurrencyFormat value={total} />
                            </Typography>

                            <Typography className={classes.total__sum}>
                              <CurrencyFormat value={invoice.paid} />
                            </Typography>
                          </div>

                          <Typography variant="h6" className={classes.total__balance}>
                            <CurrencyFormat value={invoice.balance} />
                          </Typography>
                        </div>
                      </div>
                    </div>

                    {!paymentForm && !disablePayment &&
                      <div className={classes.actionButton}>
                        <Button
                          className={classes.actionButton__cancel}
                          onClick={handleModalReject}
                        >
                          Back
                        </Button>

                        {!invoice?.confirmed_by?.id ?
                          <Tooltip title="You should confirm invoice">
                            <Button variant="contained">
                              Receive payment
                            </Button>
                          </Tooltip>
                          :
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleReceivePayment}
                          >
                            Receive payment
                          </Button>
                        }
                      </div>
                    }
                  </ModalBody>
                </ModalContainer>
              )}
            />
          </Dialog>
        </FileDropZoneWindow>
      </FilesFilterContextProvider>
    </InvoicesContextProvider>
  );
};
