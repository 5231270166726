import cn from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 0 auto',
    alignItems: 'center',
    maxWidth: '100%',

    [breakpoints.down('sm')]: {
      justifyContent: 'flex-end'
    }
  },

  startPosition: {
    marginRight: 'auto'
  },

  endPosition: {
    marginLeft: 'auto'
  },

  spacing: {
    '& > * + *': {
      marginLeft: spacing()
    }
  }
}));

export const PageHeaderActions = ({ children, startPosition, disableSpacing, disablePosition }) => {
  const classes = useStyles();

  return (
    <div
      className={cn(
        classes.root,
        {
          [classes.startPosition]: startPosition && !disablePosition,
          [classes.endPosition]: !startPosition && !disablePosition,
          [classes.spacing]: !disableSpacing
        }
      )}
    >
      {children}
    </div>
  );
};
