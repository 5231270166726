import { Select } from '../Select';
import { SingleValue } from './SingleValue';
import { MultiValue } from './MultiValue';
import { Option } from './Option';

const components = {
  SingleValue,
  MultiValue,
  Option
};

export const UserSelect = (props) => {
  return (
    <Select components={components} {...props} />
  );
};
