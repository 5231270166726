import { useFormikContext } from 'formik';
import { CaseUsersSelect } from '../../../../../components/cases/CaseUsersSelect';
import { Select } from '../../../../../components/FormField';
import { caseUserRoles } from '../../../../../dataMaps';

export const Body = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleChangeRole = () => {
    setFieldValue('user_id', null);
  };

  return (
    <>
      <Select
        isClearable
        formattedValue
        name="case_role"
        label="Select user role"
        options={caseUserRoles}
        TextFieldProps={{ margin: 'dense' }}
        onChange={handleChangeRole}
      />

      <CaseUsersSelect
        isCreatable
        disabled={!values.case_role}
        name="user_id"
        label="Select by name user"
        margin="dense"
        creatablePayload={{ role: values.case_role }}
        params={{ case_roles: [ values.case_role ] }}
      />
    </>
  );
};
