import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_SCHEDULE_REQUEST]: (state) => {
    return {
      ...state,

      isFetching: true
    };
  },

  [types.FETCH_SCHEDULE_SUCCESS]: (
    { filter, events, ...state },
    { data: newEvents, pagination: { page, per_page, ...pagination }
    }) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      events: page > 1 ? (
        events.filter(({ id }) =>
          !newEvents.find((loadedEvent) => id === loadedEvent.id)).concat(newEvents)
      ) : (
        newEvents
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_SCHEDULE]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      isFetched: false,
      events: [],
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_SCHEDULE_EVENT]: (state, event) => {
    if (!(state.filter.started_from <= event.started_at && state.filter.started_to >= event.started_at)) {
      return state;
    }

    return {
      ...state,

      events: state.events.concat(event)
    };
  },

  [types.UPDATE_SCHEDULE_EVENT]: (state, event) => {
    return {
      ...state,

      events: state.events.map((item) => {
        return item.id === event.id ? { ...item, ...event } : item;
      })
    };
  },

  [types.DELETE_SCHEDULE_EVENT]: (state, eventID) => {
    return {
      ...state,

      events: state.events.filter(({ id }) => eventID !== id)
    };
  },

  [types.TOGGLE_EVENT_SELECTION]: ({ selectedEventsIDs, ...state }, id) => {
    return {
      ...state,

      selectedEventsIDs: selectedEventsIDs.indexOf(id) !== -1
        ? selectedEventsIDs.filter((item) => item !== id)
        : selectedEventsIDs.concat(id)
    };
  },

  [types.TOGGLE_SINGLE_EVENT_SELECTION]: ({ selectedEventsIDs, ...state }, id) => {
    return {
      ...state,

      selectedEventsIDs: [ id ]
    };
  },

  [types.TOGGLE_ALL_EVENTS_SELECTION]: ({ events, selectedEventsIDs, ...state }) => {
    const allItemsIsSelected = events.length === selectedEventsIDs.length;

    return {
      ...state,

      events,
      selectedEventsIDs: allItemsIsSelected ? [] : events.map((item) => item.id)
    };
  }
});
