import _ from 'lodash';
import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_PROFILE_REQUEST]: (state) => {
    return {
      ...state,
      isFetched: false,
      isFetching: true
    };
  },

  [types.FETCH_PROFILE_SUCCESS]: (state, payload) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      medicalProfile: payload
    };
  },

  [types.UPDATE_PROFILE]: (state, payload) => {
    return {
      ...state,

      medicalProfile: _.merge({}, state.user, payload)
    };
  }
});
