import { useSnackbar } from 'notistack';
import { createContext, useRef, useEffect, useReducer, useCallback } from 'react';
import { isEqual, merge, omit } from 'lodash';
import * as workClocksApi from '../../../api/work-clocks';
import { usePrevious } from '../../../helpers/hooks';
import { api } from '../../../api';
import * as timeReportsApi from '../../../api/work-clocks';
import { eventBus, eventBusEvents } from '../../../utils/eventBus';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const TimeReportsContext = createContext();

export const TimeReportsProvider = ({ filter: filterProp = {}, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    timeReports,
    selectedTimeReportsIDs,
    selectedTimeReports
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchTimeReports = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_TIME_REPORTS_REQUEST });

    timeReportsApi.fetchTimeReports({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_TIME_REPORTS_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchTimeReports({ page: filter.page + 1 });
    }
  };

  const updateTimeReport = useCallback((report) => {
    dispatch({ type: types.UPDATE_TIME_REPORT, payload: report });
  });

  const toggleApproveReport = (timeReport) => {
    const clocksApi = timeReport.approved_at
      ? workClocksApi.disapproveTimeReport
      : workClocksApi.approveTimeReport;

    clocksApi(timeReport.id).then((data) => {
      const message = data.approved_at ? 'Report approved' : 'Report disapproved';

      dispatch({ type: types.UPDATE_TIME_REPORT, payload: data });
      enqueueSnackbar(message, { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Report not approved', { variant: 'error' });
    });
  };

  const workerApproveTimeReport = (timeReport) => {
    workClocksApi.workerApproveTimeReport(timeReport.id).then((data) => {
      dispatch({ type: types.UPDATE_TIME_REPORT, payload: data });
      enqueueSnackbar('Report approved', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Report not approved', { variant: 'error' });
    });
  };

  const resetTimeReports = (newFilter = {}) => {
    dispatch({ type: types.RESET_TIME_REPORTS, payload: newFilter });

    fetchTimeReports({ page: 1, ...newFilter });
  };

  const addTimeReportsToSelected = (timeReports) => {
    dispatch({ type: types.ADD_TIME_REPORTS_TO_SELECTED, payload: timeReports });
  };

  const deleteTimeReportsFromSelected = (timeReportsIDs) => {
    dispatch({ type: types.DELETE_TIME_REPORTS_FROM_SELECTED, payload: timeReportsIDs });
  };

  const toggleTimeReportSelected = (timeReport) => {
    if (selectedTimeReportsIDs.indexOf(timeReport.id) === -1) addTimeReportsToSelected([ timeReport ]);
    else deleteTimeReportsFromSelected([ timeReport.id ]);
  };

  const allTimeReportsIsSelected = () => {
    return selectedTimeReportsIDs
      .filter((id) => timeReports.find((timeReport) => timeReport.id === id))
      .length === timeReports.length;
  };

  const toggleAllTimeReportsSelected = () => {
    const timeReportsIDs = timeReports.map(({ id }) => id);

    if (allTimeReportsIsSelected()) {
      deleteTimeReportsFromSelected(timeReportsIDs);
    } else {
      addTimeReportsToSelected(timeReports);
    }
  };

  const deleteTimeReport = (timeReport) => {
    dispatch({ type: types.DELETE_TIME_REPORT, payload: timeReport.id });
  };

  const providerValue = {
    isFetched,
    isFetching,
    timeReports,
    selectedTimeReportsIDs,
    selectedTimeReports,
    filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    updateTimeReport,
    workerApproveTimeReport,
    toggleApproveReport,
    resetTimeReports,
    fetchTimeReports,
    loadNextPage,
    applyFilter,
    deleteTimeReport,
    toggleTimeReportSelected,
    deleteTimeReportsFromSelected,
    allTimeReportsIsSelected,
    toggleAllTimeReportsSelected
  };

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetTimeReports();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    eventBus.on(eventBusEvents.timeReportUpdate, updateTimeReport);

    return () => {
      cancelFetch.current();
      eventBus.remove(eventBusEvents.timeReportUpdate, updateTimeReport);
    };
  }, []);

  return (
    <TimeReportsContext.Provider value={providerValue}>
      {children}
    </TimeReportsContext.Provider>
  );
};
