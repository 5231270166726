import { useEffect, useContext, useRef } from 'react';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { AppBar } from '../../../../../components/AppBar';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { FilePreviewContext } from '../FilePreviewContext';
import { Row } from './Row';
import { styles } from './styles';

export const minRowHeight = 72;
const useStyles = makeStyles(styles);

export const List = ({
  files,
  isFetched,
  isFetching,
  filter,
  listItemMenuOptions,
  getFileMenuOptions,
  onNeedNextPage
}) => {
  const { selectedFile } = useContext(FilePreviewContext);
  const selectedFileIndex = files.findIndex(({ id }) => id === selectedFile?.id);
  const scrollElementRef = useRef();
  const virtualListRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    virtualListRef.current?.scrollToRow(selectedFileIndex);
  }, [ virtualListRef.current, selectedFileIndex ]);

  return (
    <div className={classes.root}>
      <AppBar className={classes.header}>
        <Typography variant="h3" component="h2" color="inherit">All files</Typography>
      </AppBar>

      <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
        {!files?.length ? (
          <Typography align="center" className={classes.filesNotFound}>No files found</Typography>
        ) : (
          <MuiList className={classes.list}>
            <InfiniteListLoader
              items={files}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={filter}
              RowComponent={Row}
              RowProps={{ listItemMenuOptions, getFileMenuOptions }}
              scrollElementRef={scrollElementRef}
              virtualListRef={virtualListRef}
              minRowHeight={minRowHeight}
              onNeedNextPage={onNeedNextPage}
            />
          </MuiList>
        )}
      </CustomScrollbars>
    </div>
  );
};
