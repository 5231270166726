import Color from 'color';

export const styles = ({ spacing, palette: { grey, divider, ...palette }, transitions }) => ({
  root: {
    padding: spacing(2),
    borderBottom: `1px solid ${divider}`,
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      background: grey[100]
    }
  },

  root_selected: {
    background: Color(palette.primary.main).alpha(0.1).string(),
    borderColor: palette.primary.light
  },

  loader: {
    width: '100%',
    alignSelf: 'center'
  }
});
