import { PURGE } from 'redux-persist';
import { logoutSocket } from '../../utils/echo';
import { storeKey } from '../storeKey';
import * as types from './types';

export const loginRequest = () => ({ type: types.LOGIN_REQUEST });
export const loginSuccess = (payload) => ({ type: types.LOGIN_SUCCESS, payload });
export const loginNeedConfirmation = (payload) => ({ type: types.LOGIN_NEED_CONFIRMATION, payload });
export const loginFail = (payload) => ({ type: types.LOGIN_FAIL, payload });
export const setToken = (payload) => ({ type: types.SET_TOKEN, payload });

export const logout = () => (dispatch, getState) => {
  if (getState().auth.isAuthenticated) {
    logoutSocket();

    dispatch({
      type: PURGE,
      key: storeKey,
      result: () => null
    });
  }
};
