import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import * as pages from '../../../../store/dashboard/layout/pages';
import { Widgets } from '../../../../components/Widgets';
import { cardsLayout, checkedWidgetsTypes, extraTypes } from './ClientLayouts';
import { widgetsMap } from './Widgets/clientWidgetsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ClientProfileGeneralInfo = () => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const { user } = useSelector(({ profile }) => profile);

  return (
    <div ref={rootRef} className={classes.root}>
      <Widgets
        rowHeight={25}
        page={pages.clientProfile}
        extraTypes={extraTypes}
        checkedWidgetsTypes={checkedWidgetsTypes}
        widgetsMap={widgetsMap}
        cardsLayout={cardsLayout}
        payload={{ user }}
      />
    </div>
  );
};
