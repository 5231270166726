import { useMemo } from 'react';
import {
  InfiniteLoader,
  List as VirtualList,
  WindowScroller,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer
} from 'react-virtualized';
import { makeStyles } from '@material-ui/core';
import { Loader } from '../../Loader';
import { styles } from './styles';

function generateIndexesForRow(rowIndex, maxItemsPerRow, itemsAmount) {
  const result = [];
  const startIndex = rowIndex * maxItemsPerRow;

  for (let i = startIndex; i < Math.min(startIndex + maxItemsPerRow, itemsAmount); i++) {
    result.push(i);
  }

  return result;
}

const useStyles = makeStyles(styles);

export const InfiniteGridLoader = ({
  items,
  autoSize = false,
  cellWidth,
  cellHeight,
  isFetching,
  pagination: { last_page, page },
  CellComponent,
  CellProps = {},
  onNeedNextPage,
  scrollElementRef
}) => {
  const classes = useStyles();
  const hasNextPage = page < last_page;
  const cache = useMemo(() => {
    return new CellMeasurerCache({
      defaultHeight: cellHeight,
      fixedWidth: true
    });
  }, [ cellHeight, CellMeasurerCache ]);

  const loadMoreRows = () => {
    if (!isFetching) {
      onNeedNextPage();
    }
  };

  const getMaxItemsAmountPerRow = (width) => {
    return Math.max(Math.floor(width / cellWidth), 1);
  };

  const getRowsAmount = (width, loadedItemsAmount, hasNextPage) => {
    const maxItemsPerRow = getMaxItemsAmountPerRow(width);
    const amount = Math.ceil(loadedItemsAmount / maxItemsPerRow);

    return amount + (hasNextPage ? 1 : 0);
  };

  const isRowLoaded = (width) => ({ index }) => {
    const maxItemsPerRow = getMaxItemsAmountPerRow(width);
    const loadedRowsAmount = Math.ceil(items.length / maxItemsPerRow);

    return !hasNextPage || (loadedRowsAmount > (index + 1));
  };

  const rowRenderer = (width) => ({ index: rowIndex, key, style, parent }) => {
    const maxItemsPerRow = getMaxItemsAmountPerRow(width);
    const itemsIndexesInRow = generateIndexesForRow(rowIndex, maxItemsPerRow, items.length);
    const itemsInRow = itemsIndexesInRow.map((itemIndex) => items[itemIndex]);
    const stubsForFillRow = Array(maxItemsPerRow - itemsInRow.length).fill(null);
    const prevRowIsFilled = Math.ceil(items.length / maxItemsPerRow) > rowIndex;
    const cellStyle = { maxWidth: `${100 / maxItemsPerRow}%` };

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        {({ measure, registerChild }) => (
          <div key={key} style={style} className={classes.gridRow} ref={registerChild}>
            {itemsInRow.map((item) => (
              <div key={item.id} className={classes.gridCell} style={cellStyle}>
                <CellComponent
                  item={item}
                  cache={cache}
                  recalculateHeight={measure}

                  {...CellProps}
                />
              </div>
            ))}

            {stubsForFillRow.map((item, index) => (
              <div key={index} className={classes.gridCell} style={cellStyle}>
                {hasNextPage && prevRowIsFilled && index === 0 && <Loader />}
              </div>
            ))}
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <InfiniteLoader
          rowCount={getRowsAmount(width, items.length, hasNextPage)}
          isRowLoaded={isRowLoaded(width)}
          loadMoreRows={loadMoreRows}
        >
          {({ onRowsRendered, registerChild }) => (
            <WindowScroller scrollElement={scrollElementRef.current}>
              {({ height = 0, scrollTop }) => (
                <VirtualList
                  className={classes.gridContainer}
                  ref={registerChild}
                  scrollTop={scrollTop}
                  autoHeight
                  height={height}
                  width={width}
                  rowCount={getRowsAmount(width, items.length, hasNextPage)}
                  rowHeight={autoSize ? cache.rowHeight : cellHeight}
                  onRowsRendered={onRowsRendered}
                  rowRenderer={rowRenderer(width)}
                  deferredMeasurementCache={cache}
                />
              )}
            </WindowScroller>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};
