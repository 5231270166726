const callReportGroupNameMap = {
  7: 'Riverside',
  3: 'Surgical center',
  6: 'Medical records',
  1: 'New patient coordinator',
  5: 'Billing questions',
  8: 'Schedule surgery',
  4: 'NOVA Front Desk',
  2: 'CALBPS Front Desk'
};

export const getCallReportGroupName = (groupNumber) => {
  return callReportGroupNameMap[groupNumber] || 'Group';
};
