import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

const toggleFlags = (message, id) => (
  message.uid !== id ? message : {
    ...message,

    flags: {
      ...message.flags,

      flagged: !message?.flags?.flagged
    }
  }
);

export const reducer = createHookReducer({
  [types.FETCH_MESSAGES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_MESSAGES_SUCCESS]: (
    { filter, messages, messageItem, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      isFirstLoaded: page === 1,
      messageItem: data ? messageItem : null,
      messages: page <= 1 ? data : messages.filter(({ uid }) => {
        return !data.find((message) => uid === message.uid);
      }).concat(data)
    };
  },

  [types.POST_FETCH_MESSAGES_SUCCESS]: ({ messages, ...state }, { data }) => {
    return {
      ...state,

      messages: data.filter(({ uid }) => {
        return !messages.find((message) => uid === message.uid);
      }).concat(messages)
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_MESSAGES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      selectedMessages: [],
      messages: [],
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.TOGGLE_ALL_ITEMS_SELECTION]: ({ messages, selectedMessages, ...state }) => {
    const allItemIsSelected = messages.length === selectedMessages.length;

    return {
      ...state,

      messages,
      selectedMessages: allItemIsSelected ? [] : messages
    };
  },

  [types.TOGGLE_ITEM_SELECTION]: ({ selectedMessages, ...state }, message) => {
    return {
      ...state,

      selectedMessages: selectedMessages.findIndex(({ uid }) => uid === message.uid) !== -1
        ? selectedMessages.filter((item) => item.uid !== message.uid)
        : selectedMessages.concat(message)
    };
  },

  [types.MASS_DELETE_ITEMS]: ({ pagination, filter, messages, messageItem, ...state }, deletedIDs) => {
    const deletedMessages = messages.filter((message) => deletedIDs.find((uid) => uid === message.uid));
    const total = pagination.total - deletedMessages.length;
    const page = Math.ceil((messages.length - deletedMessages.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      selectedMessages: [],
      messages: messages.filter((message) => !deletedMessages.find(({ uid }) => uid === message.uid)),
      messageItem: deletedIDs.indexOf(messageItem?.uid) !== -1 ? null : messageItem
    };
  },

  [types.TOGGLE_MESSAGE_FLAGGED]: ({ messages, ...state }, uid) => {
    return {
      ...state,

      messages: messages.map((message) => toggleFlags(message, uid)),
      messageItem: state?.messageItem && toggleFlags(state?.messageItem, uid)
    };
  },

  [types.TOGGLE_MESSAGE_SEEN]: ({ messages, ...state }, uid) => {
    return {
      ...state,

      messages: messages.map((message) => (
        message.uid === uid ?
          {
            ...message,
            flags: {
              ...message.flags,
              unseen: false,
              seen: true
            }
          } : message
      ))
    };
  },

  [types.SET_ONE_MESSAGE]:  (state, messageItem) => {
    return {
      ...state,

      messageItem
    };
  },

  [types.DELETE_ACCOUNT]:  (state) => {
    return {
      ...state,

      messageItem: null,
      messages: [],
      selectedMessages: [],
      pagination: {
        total: 0,
        page: 1,
        last_page: 1
      }
    };
  }
});
