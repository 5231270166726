import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell grow noWrap variant="h5" flexBasis={columnsWidths.name}>
        Company Name
      </ListRowCell>

      <ListRowCell noWrap variant="h5" flexBasis={columnsWidths.type}>
        Company Type
      </ListRowCell>

      <ListRowCell noWrap variant="h5" flexBasis={columnsWidths.address}>
        Location
      </ListRowCell>

      <ListRowCell noWrap variant="h5" flexBasis={columnsWidths.email}>
        Email
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
