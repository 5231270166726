import { useEffect, useRef, useState } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { api } from '../../../../api';
import { fetchStatuses } from '../../../../api/tasks';
import { useResizeObserver } from '../../../../helpers/hooks/useResizeObserver';
import { TaskPreviewContextProvider, TasksFilterContextProvider } from '../../../../components/tasks/TasksProvider';
import { MainWindow } from './MainWindow';
import { LeftSideBar } from './LeftSideBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointLg = 1340;

export const TaskItem = ({ match: { params } }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [ statuses, setStatuses ] = useState([]);
  const cancelStatusesFetch = useRef(() => {});
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState(false);

  const toggleLeftSidebar = () => {
    setSidebarIsOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    fetchStatuses({
      cancelToken: new api.CancelToken((cancel) => cancelStatusesFetch.current = cancel)
    }).then(({ data }) => {
      setStatuses(data);
    });

    return () => {
      cancelStatusesFetch.current();
    };
  }, []);

  useEffect(() => {
    !isMobile && setSidebarIsOpen(width >= 1240);
  }, [ isMobile ]);

  return (
    <TaskPreviewContextProvider selectedTaskId={params.id}>
      <TasksFilterContextProvider>
        <div ref={rootRef} className={classes.root}>
          <LeftSideBar
            width={width}
            statuses={statuses}
            handleToggleLeftSidebar={toggleLeftSidebar}
            sidebarIsOpen={sidebarIsOpen}
          />

          {isMobile && sidebarIsOpen && <div className={classes.mobileOverlay} onClick={toggleLeftSidebar} />}

          <MainWindow sidebarIsOpen={sidebarIsOpen} width={width} setSidebarIsOpen={setSidebarIsOpen} />
        </div>
      </TasksFilterContextProvider>
    </TaskPreviewContextProvider>
  );
};
