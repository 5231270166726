import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../helpers/createReduxReducer';
import * as types from './types';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  authToken: '',
  page: null
};

export const reducer = createReduxReducer(initialState, {
  // Used for logout
  [PURGE]: () => initialState,

  [types.LOGIN_REQUEST]: (state) => {
    return {
      ...state,

      isLoading: true,
      isAuthenticated: false
    };
  },

  [types.LOGIN_NEED_CONFIRMATION]: (state, { token }) => {
    return {
      ...state,

      isLoading: false,
      authToken: token
    };
  },

  [types.LOGIN_SUCCESS]: (state, { token }) => {
    return {
      ...state,

      isLoading: false,
      isAuthenticated: true,
      authToken: token
    };
  },

  [types.LOGIN_FAIL]: (state) => {
    return {
      ...state,

      isLoading: false,
      isAuthenticated: false
    };
  },

  [types.SET_TOKEN]: (state, { token }) => {
    return {
      ...state,

      authToken: token
    };
  }
});
