import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';
import { Button, Collapse, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as profileApi from '../../../../../../../../api/profile';
import { Select } from '../../../../../../../../components/FormField';
import { Loader } from '../../../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../../../components/Modal';
import { setMedicalProfileLastGlobalAction } from '../../../../../../../../store/globalActions';
import * as types from '../../../MedicalProfileProvider/types';
import { contactComponentsByTypes, contactTypesOptions } from './contactTypesOptions';
import { validationSchema } from './validationSchema';

export const ContactsModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { initialValues }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const updateProfileInfo = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    let contacts;

    if (initialValues.contacts) {
      contacts = {
        ...initialValues.contacts,
        [values.contact_type]: initialValues.contacts[values.contact_type] ?
          [ ...initialValues.contacts[values.contact_type], values ] : [ values ]
      };
    } else {
      contacts = {
        [values.contact_type]: [ values ]
      };
    }

    return profileApi.updateMedicalProfileContacts({ id: initialValues.id, contacts })
      .then((data) => {
        dispatchRedux(setMedicalProfileLastGlobalAction({ type: types.UPDATE_PROFILE, payload: data }));
        enqueueSnackbar('Contacts successfully updated', { variant: 'success' });
        handleModalResolve(data);
      })
      .catch(({ errors, message }) => {
        if (errors) {
          const errorMessage = Object.values(errors);

          errors && setErrors(errors);
          message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
        }
      });
  };

  return (
    <Dialog fullScreen={isMobile} {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={{
          is_checked: false,
          contact_type: !!get(initialValues, 'contact_type') ? contactTypesOptions[0] : null,
          relationship: null,
          phone: null,
          position: null,
          user_id: null,
          first_name: null,
          last_name: null
        }}
        validationSchema={validationSchema}
        onSubmit={updateProfileInfo}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader onClose={handleModalReject}>
              Contacts/Emergency contact
            </ModalHeader>

            <ModalBody>
              <Select
                formattedValue
                isDisabled={!!get(initialValues, 'contact_type')}
                name="contact_type"
                label="Select type of contact"
                placeholder="Select type..."
                options={contactTypesOptions}
                TextFieldProps={{ required: true }}
              />

              <Collapse
                unmountOnExit
                in={!!get(values, 'contact_type')}
                timeout="auto"
              >
                {values.contact_type && contactComponentsByTypes[values.contact_type]}
              </Collapse>
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
