import { PageBody, PageHeader } from '../../../../components/Page';
import { TaskTemplatesProvider } from './TaskTemplatesProvider';
import { Header } from './Header';
import { List } from './List';

export const TaskTemplates = () => {
  return (
    <TaskTemplatesProvider>
      <PageHeader>
        <Header />
      </PageHeader>

      <PageBody fullHeight>
        <List />
      </PageBody>
    </TaskTemplatesProvider>
  );
};
