export const discoveryTypesMap = {
  rfa_one: 'rfa_one',
  rfa_two: 'rfa_two',
  srogs_one: 'srogs_one',
  srogs_two: 'srogs_two',
  frogs_one: 'frogs_one',
  frogs_two: 'frogs_two',
  dtp_one: 'dtp_one',
  dtp_two: 'dtp_two',
  supp_rogs_one: 'supp_rogs_one',
  supp_rogs_two: 'supp_rogs_two',
  supp_dtp_one: 'supp_dtp_one',
  supp_dtp_two: 'supp_dtp_two'
};

export const discoveryTypeLabelsMap = {
  [discoveryTypesMap.rfa_one]: 'RFA 1',
  [discoveryTypesMap.rfa_two]: 'RFA 2',
  [discoveryTypesMap.srogs_one]: 'SROGS 1',
  [discoveryTypesMap.srogs_two]: 'SROGS 2',
  [discoveryTypesMap.frogs_one]: 'FROGS 1',
  [discoveryTypesMap.frogs_two]: 'FROGS 2',
  [discoveryTypesMap.dtp_one]: 'DTP 1',
  [discoveryTypesMap.dtp_two]: 'DTP 2',
  [discoveryTypesMap.supp_rogs_one]: 'SUPP ROGS 1',
  [discoveryTypesMap.supp_rogs_two]: 'SUPP ROGS 2',
  [discoveryTypesMap.supp_dtp_one]: 'SUPP DTP 1',
  [discoveryTypesMap.supp_dtp_two]: 'SUPP DTP 2'
};

export const discoveryTypeOptions = [
  {
    value: discoveryTypesMap.rfa_one,
    label: discoveryTypeLabelsMap[discoveryTypesMap.rfa_one]
  },
  {
    value: discoveryTypesMap.rfa_two,
    label: discoveryTypeLabelsMap[discoveryTypesMap.rfa_two]
  },
  {
    value: discoveryTypesMap.srogs_one,
    label: discoveryTypeLabelsMap[discoveryTypesMap.srogs_one]
  },
  {
    value: discoveryTypesMap.srogs_two,
    label: discoveryTypeLabelsMap[discoveryTypesMap.srogs_two]
  },
  {
    value: discoveryTypesMap.frogs_one,
    label: discoveryTypeLabelsMap[discoveryTypesMap.frogs_one]
  },
  {
    value: discoveryTypesMap.frogs_two,
    label: discoveryTypeLabelsMap[discoveryTypesMap.frogs_two]
  },
  {
    value: discoveryTypesMap.dtp_one,
    label: discoveryTypeLabelsMap[discoveryTypesMap.dtp_one]
  },
  {
    value: discoveryTypesMap.dtp_two,
    label: discoveryTypeLabelsMap[discoveryTypesMap.dtp_two]
  },
  {
    value: discoveryTypesMap.supp_rogs_one,
    label: discoveryTypeLabelsMap[discoveryTypesMap.supp_rogs_one]
  },
  {
    value: discoveryTypesMap.supp_rogs_two,
    label: discoveryTypeLabelsMap[discoveryTypesMap.supp_rogs_two]
  },
  {
    value: discoveryTypesMap.supp_dtp_one,
    label: discoveryTypeLabelsMap[discoveryTypesMap.supp_dtp_one]
  },
  {
    value: discoveryTypesMap.supp_dtp_two,
    label: discoveryTypeLabelsMap[discoveryTypesMap.supp_dtp_two]
  }
];
