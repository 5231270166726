export const styles = ({ spacing, palette }) => ({
  root: {
    padding: spacing(0.5, 6, 0.5, 4.25),
    color: palette.text.secondary
  },

  badge: {
    transform: 'scale(1) translate(0, -50%)'
  }
});
