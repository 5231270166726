export const styles = ({ spacing, palette: { grey }, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    padding: spacing(2, 1, 0, 1),
    backgroundColor: grey[100],

    [breakpoints.down('md')]: {
      padding: spacing(1, 1, 0, 1)
    }
  }
});
