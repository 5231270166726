import { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../helpers/dom';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { ActivityProvider } from '../../Activities';
import { UserPreviewModal } from '../../users';
import { useModal } from '../../ModalsProvider';
import { Divider } from '../../Divider';
import { UserLink } from '../../UserLink';
import { Date } from '../../Date';
import { MedicalFormInfo } from './MedicalFormInfo';
import { List } from './List';

const tabsMap = {
  medicalForm: 'medicalForm',
  activity: 'activity',
  visitSummary: 'visitSummary'
};

export const Activity = ({ appointment }) => {
  const { openModal } = useModal();
  const hasMedicalForms = !!appointment?.medical_forms?.length;
  const [ activeTab, setActive ] = useState(
    tabsMap[hasMedicalForms ? 'medicalForm' : 'activity']
  );

  const handleTabIndexChange = (event, newValue) => {
    setActive(newValue);
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box p={2}>
        <Box color="info.main">
          <Typography noWrap variant="h5" color="inherit">Creator</Typography>
        </Box>

        <Box display="flex" alignItems="center" pt={1}>
          <UserLink
            noWrap
            size="sm"
            user={appointment.created_by}
            onClick={stopPropagation(openUserPreview(appointment?.created_by?.id))}
          >
            <Date date={appointment.created_at} format="L LT" iconFontSize="small" />
          </UserLink>
        </Box>

        <Box color="info.main" pt={2}>
          <Typography noWrap variant="h5" color="inherit">Updating</Typography>
        </Box>

        <Box display="flex" alignItems="center" pt={1}>
          <UserLink
            noWrap
            size="sm"
            user={appointment.updated_by}
            onClick={stopPropagation(openUserPreview(appointment?.updated_by?.id))}
          >
            <Date date={appointment.updated_at} format="L LT" iconFontSize="small" />
          </UserLink>
        </Box>

        <Box color="info.main" pt={2}>
          <Typography noWrap variant="h5" color="inherit">Confirmed</Typography>
        </Box>

        <Box display="flex" alignItems="center" pt={1}>
          <UserLink
            noWrap
            size="sm"
            user={appointment.confirmed_by}
            onClick={stopPropagation(openUserPreview(appointment?.confirmed_by?.id))}
          >
            <Date date={appointment.updated_at} format="L LT" iconFontSize="small" />
          </UserLink>
        </Box>
      </Box>

      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <>
          <Divider />

          <Box display="flex" flexDirection="column" flexGrow={1} minHeight={300}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabIndexChange}
              component={Box}
              border={1}
              borderTop={0}
              borderRight={0}
              borderLeft={0}
              borderColor="grey.200"
            >
              <Tab value={tabsMap.medicalForm} label="Medical forms" />
              <Tab value={tabsMap.activity} label="Activity" />
            </Tabs>

            <Box flexGrow={1}>
              {activeTab === tabsMap.medicalForm &&
                <Box p={2}>
                  <MedicalFormInfo medicalForms={appointment?.medical_forms} />
                </Box>
              }

              {activeTab === tabsMap.activity &&
                <ActivityProvider filter={{ model: 'appointment', model_id: appointment.id }}>
                  <List appointment={appointment} />
                </ActivityProvider>
              }
            </Box>
          </Box>
        </>
      }
    </Box>
  );
};
