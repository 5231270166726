import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useThreads } from '../../utils';
import { Notification } from './Notification';

export const ChatNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(({ profile }) => profile.user);

  const handleMessageCreated = useCallback(({ thread, message }) => {
    if (
      (message.user?.id !== currentUser.id && message.type !== 'system') ||
      (message.type === 'system' && currentUser.id !== message.payload?.user?.id)
    ) {
      enqueueSnackbar(null, {
        autoHideDuration: 8000,
        content: (key) => (
          <Notification
            snackbarKey={key}
            message={message}
            threadId={thread.id}
          />
        ),
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  }, []);

  useThreads({
    onMessageCreated: handleMessageCreated
  });

  return null;
};
