export const widthBreakpointXXS = 650;
export const widthBreakpointXS = 870;
export const widthBreakpointSm = 1000;
export const widthBreakpointMd = 1210;
export const widthBreakpointLg = 1320;

export const columnsWidths = {
  date: 125,
  type: 160,
  doi: 150,
  name: 180,
  patient: 180,
  children: 90,
  insurance_name: 180,
  insurance_id: 150,
  appointment_date: 120,
  users: 130,
  action: 70
};
