import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InvoicesContext } from '../../../app/Dashboard/BillingPage/Invoices/InvoicesContext';
import { useResizeObserver } from '../../../helpers/hooks/useResizeObserver';
import { TablePagination } from '../../TablePagination';
import { Scrollbars } from '../../Scrollbars';
import { Loader } from '../../Loader';
import { TableHeader } from './TableHeader';
import { FiltersBar } from './FiltersBar';
import { Row } from './Row';

export const widthBreakpointXXS = 650;
export const widthBreakpointXS = 800;
export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1350;
export const widthBreakpointLarge = 1500;
export const minRowHeight = 54;
export const BREAKPOINT_LARGE = 1200;

export const columnsMap = {
  checkbox: 'checkbox',
  name: 'name',
  billFor: 'billFor',
  billFrom: 'billFrom',
  billTo: 'billTo',
  date: 'date',
  dueDate: 'dueDate',
  amount: 'amount',
  balance: 'balance',
  status: 'status',
  paid: 'paid',
  action: 'action'
};

export const columnsWidths = {
  [columnsMap.name]: 150,
  [columnsMap.billFor]: 200,
  [columnsMap.billFrom]: 200,
  [columnsMap.billTo]: 200,
  [columnsMap.date]: 140,
  [columnsMap.dueDate]: 140,
  [columnsMap.amount]: 140,
  [columnsMap.balance]: 140,
  [columnsMap.status]: 100,
  [columnsMap.paid]: 100,
  [columnsMap.action]: 60
};

export const Billing = ({ isEqual = false, isWidgets = false, filters = {}, caseItem = null }) => {
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const scrollElementRef = useRef();
  const {
    applyFilter,
    isFetched,
    invoices,
    meta,
    resetInvoices
  } = useContext(InvoicesContext);

  useEffect(() => {
    resetInvoices();
  }, []);

  useEffect(() => {
    if (isWidgets) {
      applyFilter(filters);
    }
  }, [ isEqual, filters ]);

  return (
    <Box ref={rootRef} height="100%" display="flex" flexDirection="column" overflow="hidden">
      {width > BREAKPOINT_LARGE && <FiltersBar />}

      <Box flexGrow={1} display="flex" flexDirection="column">
        <Loader loading={!isFetched} p={2} render={
          () => !invoices.length ? (
            <Box p={2}>
              <Typography align="center">No invoices found</Typography>
            </Box>
          ) : (
            <>
              <TableHeader width={width} />

              <Scrollbars viewRef={scrollElementRef}>
                <MuiList disablePadding>
                  {invoices.map((invoice) => (
                    <Row
                      key={invoice.id}
                      invoice={invoice}
                      caseItem={caseItem}
                      invoices={invoices}
                      width={width}
                    />
                  ))}
                </MuiList>
              </Scrollbars>

              <TablePagination pagination={meta} onChange={resetInvoices} />
            </>
          )}
        />
      </Box>
    </Box>
  );
};
