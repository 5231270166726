import { useContext } from 'react';
import moment from 'moment';
import { Typography, Box, SvgIcon } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { mdiAttachment } from '@mdi/js';
import { Icon } from '@mdi/react';
import { stopPropagation } from '../../../../../../helpers/dom';
import { generateAddress } from '../../../../../../utils/generateAddress';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Popper } from '../../../../../../components/Popper';
import { Date } from '../../../../../../components/Date';
import { NotesContext } from '../../NotesProvider';
import { PreviewNote } from '../../PreviewNote';
import { NotesMenu } from '../../NotesMenu';
import { columnsWidths } from '../List';

export const Row = ({ note }) => {
  const { openModal } = useModal();
  const { selectedIDs, toggleItemSelection, fetchNotes, updateNote } = useContext(NotesContext);
  const isSelected = selectedIDs.indexOf(note.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(note.id);
  };

  const openPreview = () => {
    openModal(PreviewNote, {
      payload: {
        noteId: note.id,
        onNoteUpdate: updateNote,
        onListUpdate: fetchNotes
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <ListRow button onClick={stopPropagation(openPreview)}>
      {!hasRole('client', 'patient') &&
        <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />
      }

      <ListRowCell column grow flexBasis={columnsWidths.name}>
        <Typography noWrap title={note.note}>
          {note.note || '-'}
        </Typography>

        <Box display="flex" alignItems="center" fontSize={15}>
          <SvgIcon color="inherit"><Icon path={mdiAttachment} /></SvgIcon>

          <Box pl={0.5}>
            <Typography color="inherit">{note?.media_count || note?.media?.length || 0}</Typography>
          </Box>
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.doi}>
        <Date
          disableMoment
          date={note?.doi_string || moment(note.doi).format('L')}
          iconFontSize="small"
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dor}>
        <Box display="flex" alignItems="center">
          <Date date={note?.dor} iconFontSize="small" />
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        <UserLink
          noWrap
          size="md"
          user={note?.provider}
          onClick={stopPropagation(openUserPreview(note?.provider?.id))}
        />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.office}>
        <Tooltip
          isExistTooltip={!!note?.office}
          title={note?.office?.full_address || generateAddress(note?.office)}
        >
          <OfficeLink office={note?.office} />
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <NotesMenu note={note} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
