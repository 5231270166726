import { api } from '../api';

export const fetchNegotiations = (caseID, data) => {
  return api.get(`/cases/${caseID}/negotiat`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const addNegotiation = ({ caseID, ...data }) => {
  return api.post(`/cases/${caseID}/negotiat`, data)
    .then(({ data }) => data)
    .catch((error) => { throw  error; });
};

export const updateNegotiation = ({ id, caseID, ...data }) => {
  return api.put(`/cases/${caseID}/negotiat/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteNegotiation = (caseID, id) => {
  return api.delete(`/cases/${caseID}/negotiat/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
