import { Box, List as MuiList, Paper, useMediaQuery } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../ListRow';
import { Row } from './Row';

export const columnsWidths = {
  name: 200,
  type: 60,
  inOut: 170,
  payCode: 150,
  hours: 120,
  total: 100
};

export const Body = ({ report }) => {
  const weeks = report?.report_data?.weeks || [];
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <Box pt={1} pb={4} width="100%">
      {weeks.map((weekItem) => (
        <Box pt={3} key={weekItem.week}>
          <Paper elevation={0}>
            <ListRow header px={2}>
              <ListRowCell grow flexBasis={columnsWidths.name}>
                {`Week ${weekItem.week}`}
              </ListRowCell>

              <ListRowCell grow={isTablet} flexBasis={columnsWidths.inOut}>
                In - Out
              </ListRowCell>

              <ListRowCell grow={isTablet} flexBasis={columnsWidths.payCode}>
                Pay Code
              </ListRowCell>

              <ListRowCell grow={isTablet} flexBasis={columnsWidths.hours}>
                Hours
              </ListRowCell>

              <ListRowCell grow={isTablet} flexBasis={columnsWidths.total}>
                Daily Totals
              </ListRowCell>
            </ListRow>
          </Paper>

          <MuiList disablePadding>
            {weekItem.days?.map((day) => (
              <Row key={day.day_name} day={day} week={weekItem} />
            ))}

            <ListRow header px={2}>
              <ListRowCell grow flexBasis={columnsWidths.name} />
              <ListRowCell grow={isTablet} flexBasis={columnsWidths.inOut} />
              <ListRowCell grow={isTablet} flexBasis={columnsWidths.payCode} />

              <ListRowCell noWrap grow={isTablet} flexBasis={columnsWidths.hours}>
                {isMobile ? 'Totals' : `Week ${weekItem.week} Totals`}
              </ListRowCell>

              <ListRowCell grow={isTablet} flexBasis={columnsWidths.total}>
                {weekItem.week_total_hours || 0}
              </ListRowCell>
            </ListRow>
          </MuiList>
        </Box>
      ))}
    </Box>
  );
};
