import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { flow } from 'lodash';
import { Box, List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { mdiEyeOutline } from '@mdi/js';
import { mdiBriefcaseRemoveOutline } from '@mdi/js';
import { mdiBriefcasePlusOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useLocation } from 'react-router-dom';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { useModal } from '../../../../../../components/ModalsProvider';
import { SvgIcon } from '../../../../../../components/SvgIcon';
import { UserContext } from '../../../../Members/User/UserProvider';
import { insuranceStatusTypesMap } from '../../AddInsuranceInfoModal';
import { EditInsuranceInfoModal } from '../../EditInsuranceInfoModal';
import { ViewInsuranceInfoModal } from '../../ViewInsuranceInfoModal';
import { InsurancesContext } from '../../InsurancesProvider';

export const Menu = ({ insurance, onClose, onCaseItemUpdate = () => {} }) => {
  const { openModal } = useModal();
  const { pathname } = useLocation();
  const userContext = useContext(UserContext);
  const {
    mainInsurance, handleToggleMain, updateInsurance, detachInsuranceCase, deleteInsurance, attachInsuranceCase
  } = useContext(InsurancesContext);
  const disableDate = insurance?.status_type === insuranceStatusTypesMap.case_insurance ||
    insurance?.status_type === insuranceStatusTypesMap.law_office;
  const isMain = mainInsurance && mainInsurance.id === insurance.id;
  const currentUser = useSelector(({ profile }) => profile);
  const user = userContext?.user || insurance?.case_user || currentUser?.user;
  const hasRoleForEdit = hasRole(
    rolesMap.client,
    rolesMap.patient,
    rolesMap.doctor,
    rolesMap.medic,
    rolesMap.supervisor,
    rolesMap.admin
  );
  const isInsuranceList = pathname.includes('insurances');
  const isCasePage = pathname.includes('cases');
  const isCase = insurance?.case?.id;

  const handleView = () => {
    openModal(ViewInsuranceInfoModal, {
      payload: {
        user,
        insurance,
        onEdit: updateInsurance,
        onDelete: isCasePage ? detachInsuranceCase : deleteInsurance,
        onCaseItemUpdate
      }
    });
  };

  const handleEdit = () => {
    openModal(EditInsuranceInfoModal, {
      payload: {
        user,
        insurance,
        onEdit: updateInsurance,
        onCaseItemUpdate
      }
    });
  };

  const handleToggleCase = () => {
    if (isCase) {
      detachInsuranceCase(insurance.id);
    } else {
      attachInsuranceCase(insurance);
    }
  };

  const handleDelete = () => {
    if (isCasePage) {
      detachInsuranceCase(insurance.id);
    } else {
      deleteInsurance(insurance.id);
    }
  };

  return (
    <List>
      {!disableDate &&
        <MenuItem disabled={isMain} onClick={flow(handleToggleMain(insurance), onClose)}>
          <ListItemIcon>
            <Box color="success.main">
              <CheckCircleIcon color={!isMain ? 'inherit' : 'disabled'} />
            </Box>
          </ListItemIcon>

          <ListItemText primary={isMain ? 'Do not use as main' : 'Set as main'} />
        </MenuItem>
      }

      <MenuItem onClick={flow(handleView, onClose)}>
        <ListItemIcon>
          <Box color="info.main">
            <SvgIcon color="inherit"><Icon path={mdiEyeOutline} /></SvgIcon>
          </Box>
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {!insurance?.isExpired && hasRoleForEdit && !insurance?.is_used && (
        <MenuItem onClick={flow(handleEdit, onClose)}>
          <ListItemIcon>
            <EditIcon color="primary" />
          </ListItemIcon>

          <ListItemText primary="Edit"/>
        </MenuItem>
      )}

      {(isInsuranceList || isCasePage) && (
        <MenuItem onClick={flow(handleToggleCase, onClose)}>
          <ListItemIcon>
            <Box color={isCase ? 'error.main' : 'secondary.main'}>
              <SvgIcon color="inherit">
                <Icon path={isCase ? mdiBriefcaseRemoveOutline : mdiBriefcasePlusOutline} />
              </SvgIcon>
            </Box>
          </ListItemIcon>

          <ListItemText primary={isCase ? 'Delete case' : 'Add case'} />
        </MenuItem>
      )}

      {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.medic) && !insurance?.is_used &&
        <MenuItem onClick={flow(handleDelete, onClose)}>
          <ListItemIcon>
            <DeleteIcon color="error"/>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      }
    </List>
  );
};
