import cn from 'classnames';
import { Box, makeStyles, Slider, Typography } from '@material-ui/core';
import { styles } from './styles';

const sliderMarks = [ 15, 18.5, 25, 30, 40 ].map((level) => ({ label: level, value: level }));

const useStyles = makeStyles(styles);

export const CategorySlider = ({ disableTitle = true, color, categoryValue }) => {
  const classes = useStyles({ color });

  return (
    <div className={cn(classes.root, { [classes.root_disableSpacing]: disableTitle })}>
      {!disableTitle &&
        <Typography variant="h3">BMI (Body Mass Index) Scale</Typography>
      }

      <Box mt={5}>
        <Slider
          track={false}
          valueLabelDisplay="on"
          value={categoryValue}
          step={0.1}
          min={15}
          max={40}
          marks={sliderMarks}
          classes={{
            root: classes.sliderRoot,
            active: classes.active,
            rail: classes.rail,
            mark: classes.mark,
            markLabel: classes.markLabel,
            thumb: classes.thumb
          }}
        />
      </Box>
    </div>
  );
};
