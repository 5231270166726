import { createContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { merge } from 'lodash';
import { api } from '../../../../api';
import * as userApi from '../../../../api/users';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useModal } from '../../../../components/ModalsProvider';
import { setUsersLastGlobalAction } from '../../../../store/globalActions';
import { updateUserStorage } from '../../../../store/globalUser/operations';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const UsersContext = createContext();

export const UsersContextProvider = ({
  children,
  initialState: initialStateProp = {}
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();
  const { openModal } = useModal();
  const storageInfo = useSelector(({ globalUser }) => globalUser?.data);
  const usersLastGlobalAction = useSelector(({ globalActions }) => globalActions.usersLastGlobalAction);
  const {
    filter,
    users,
    pagination,
    isFetching,
    isFetched,
    selectedIDs
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchUsers = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.USERS_FETCH_REQUEST });

    userApi.fetchUsers({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.USERS_FETCH_SUCCESS, payload: data });
    });
  };

  const createUser = (user) => {
    return userApi.createUser(user).then((data) => {
      dispatchRedux(setUsersLastGlobalAction({ type: types.ADD_USER, payload: data }));
    });
  };

  const creatBaseUser = (user) => {
    dispatch({ type: types.ADD_USER, payload: user });
  };

  const updateUser = (user) => {
    return userApi.updateUser(user).then((data) => {
      dispatchRedux(setUsersLastGlobalAction({ type: types.UPDATE_USER_IN_LIST, payload: data }));
    });
  };

  const updateSipUserInfo = (user) => {
    dispatch({ type: types.UPDATE_USER_IN_LIST, payload: user });
  };

  const deleteUser = (user) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        userApi.deleteUser(user.id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchUsers({ page: filter.page });
          } else if (users.length - 1 === 0 && filter.page !== 1) {
            fetchUsers({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_USER_SUCCESS, payload: user.id });
          }

          dispatchRedux(updateUserStorage({
            users: storageInfo?.users?.filter(({ id }) => id !== user.id)
          }));

          enqueueSnackbar('User successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('User is not deleted!', { variant: 'error' });
        });
      }
    });
  };

  const resetUsers = (newFilter) => {
    dispatch({ type: types.RESET_USERS, payload: newFilter });

    fetchUsers({ page: 1, ...newFilter });
  };

  const allUsersIsSelected = () => {
    return users.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_USER_SELECTION, payload: id });
  };

  const toggleAllUsersSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_USERS_SELECTION });
  };

  const massDeleteUsers = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        userApi.massDeleteUsers(IDs).then(() => {
          IDs.forEach((id) => toggleItemSelection(id));

          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            fetchUsers({ page: filter.page });
          } else if (users.length - selectedIDs.length === 0 && filter.page !== 1) {
            fetchUsers({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.MASS_DELETE_USERS, payload: IDs });
          }

          dispatchRedux(updateUserStorage({
            users: storageInfo?.users.filter((user) => IDs.indexOf(user.id) < 0)
          }));
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Users are not deleted', { variant: 'error' });
        });
      }
    });
  };

  useEffect(() => {
    usersLastGlobalAction && dispatch(usersLastGlobalAction);
  }, [ usersLastGlobalAction ]);

  const providerValue = {
    users,
    selectedIDs,
    isFetching,
    isFetched,
    filter,
    meta: {
      ...filter,
      ...pagination
    },
    pagination,

    // functions
    updateUser,
    updateSipUserInfo,
    creatBaseUser,
    deleteUser,
    fetchUsers,
    createUser,
    resetUsers,
    massDeleteUsers,
    allUsersIsSelected,
    toggleAllUsersSelection,
    toggleItemSelection
  };

  useEffect(() => {
    resetUsers();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <UsersContext.Provider value={providerValue}>
      {children}
    </UsersContext.Provider>
  );
};
