import { Yup } from '../../../../utils/validation';

export const sendValidationSchema = Yup.object().shape({
  config_id: Yup.number().required(),
  to: Yup.array().nullable().of(Yup.mixed()).required(),
  cc:  Yup.array().nullable().of(Yup.mixed()).notRequired(),
  bcc: Yup.array().nullable().of(Yup.mixed()).notRequired(),
  subject: Yup.string().nullable().required(),
  body: Yup.string().nullable().required(),
  attachments: Yup.mixed().notRequired()
});
