import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { SavedFiltersContext } from '../../../../saved-filters';
import { InfiniteListLoader } from '../../../../InfiniteLoader';
import { Scrollbars } from '../../../../Scrollbars';
import { Loader } from '../../../../Loader';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const SavedFiltersList = ({ onApplySavedFilter }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No saved filters</Typography>
        ) : (
          <>
            <TableHeader />

            <MuiList disablePadding>
              <InfiniteListLoader
                items={savedFilters}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ onApplySavedFilter }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
