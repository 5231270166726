import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import { Box, makeStyles } from '@material-ui/core';
import { saveFilter } from '../../../../store/lastFilters';
import { CasesSelect } from '../../../cases/CasesSelect';
import { LayoutContext, viewVariantsMap } from '../../../LayoutContext';
import { IconComponent } from '../../../saved-filters';
import { FiltersBar as FiltersBarComponent } from '../../../FiltersBar';
import {
  TasksSelect,
  PeriodPicker,
  OfficesLocationSelect,
  ScheduleEventTypesSelect
} from '../../../FormField';
import { UsersSelect } from '../../../users';
import { ScheduleEventTemplatesContext } from '../ScheduleEventTemplatesProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { List, columnsNamesMap, columnsWidths } from './List';
import { CardContent } from './CardContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const MODAL_WIDTH = 860;

export const FiltersBar = ({ tasksFilterParams, filterKey, hiddenFields = [], ...props }) => {
  const classes = useStyles();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { changeFilter, relationsForFilter, setRelationsForFilter } = useContext(ScheduleEventTemplatesContext);
  const { viewVariant } = useContext(LayoutContext);
  const filtersModalWidth = hiddenFields.includes(filterFieldsMap.cases)
    ? MODAL_WIDTH - columnsWidths[columnsNamesMap.cases]
    : MODAL_WIDTH;

  const handleMultiSelectChange = (name) => (options) => {
    changeFilter({ [name]: options?.map(({ value }) => value) });
    setRelationsForFilter((state) => ({ ...state, [name]: options?.map(({ data }) => data) }));
  };

  const handleTasksChange = (tasks) => {
    changeFilter({ tasks: tasks?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, tasks }));
  };

  const handleOfficesChange = (offices) => {
    changeFilter(({ offices: offices?.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, offices }));
  };

  const handleCasesChange = (cases) => {
    changeFilter({ cases: cases?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, cases }));
  };

  const handleUsersChange = (users) => {
    changeFilter({ users: users?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, users }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);
  };

  const handlePeriodChange = ({ fromDate, toDate, period }) => {
    changeFilter({
      started_from: fromDate?.unix(),
      started_to: toDate?.unix()
    });

    setRelationsForFilter((state) => {
      return Object.assign({
        ...state,

        started_from: fromDate?.unix(),
        started_to: toDate?.unix(),

        _period: period
      }, !period ? {} : {
        _started_from: fromDate?.unix(),
        _started_to: toDate?.unix()
      });
    });
  };

  const handlePeriodHide = () => {
    handlePeriodChange({ fromDate: null, toDate: null });
  };

  useEffect(() => {
    if (filterKey) {
      dispatch(saveFilter({
        key: filterKey,
        filter: relationsForFilter
      }));
    }
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (filterKey && lastFilters) {
      applySavedFilter(omit(lastFilters, hiddenFields));
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableResponsiveView
      formikRef={formikRef}
      ToolbarProps={{ className: classes.toolbar }}
      hiddenFields={hiddenFields}
      filterKey={filterKey}
      iconComponent={
        <IconComponent
          modalWidth={filtersModalWidth}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      onReset={() => applySavedFilter({})}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.period,
          label: filterFieldsLabels[filterFieldsMap.period],
          field: viewVariant !== viewVariantsMap.calendar && (
            <PeriodPicker
              initialPeriod={lastFilters?._period}
              fromDate={lastFilters?._started_from}
              toDate={lastFilters?._started_to}
              onChange={handlePeriodChange}
            />
          ),
          onHide: handlePeriodHide
        },
        {
          fieldKey: filterFieldsMap.cases,
          label: filterFieldsLabels[filterFieldsMap.cases],
          field: <CasesSelect
            multiple
            name="cases"
            label="Cases"
            placeholder="Search case by name..."
            onChange={handleCasesChange}
          />
        },
        {
          fieldKey: filterFieldsMap.tasks,
          label: filterFieldsLabels[filterFieldsMap.tasks],
          field: <Box minWidth={160}>
            <TasksSelect
              multiple
              name="tasks"
              label="Tasks"
              placeholder="Search task by name..."
              params={tasksFilterParams}
              onChange={handleTasksChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.users,
          label: filterFieldsLabels[filterFieldsMap.users],
          field: <UsersSelect
            multiple
            name="users"
            label="Users"
            onChange={handleUsersChange}
            minWidth={160}
          />
        },
        {
          fieldKey: filterFieldsMap.offices,
          label: filterFieldsLabels[filterFieldsMap.offices],
          field: <OfficesLocationSelect
            multiple
            name="offices"
            label="Offices"
            onChange={handleOfficesChange}
          />
        },
        {
          fieldKey: filterFieldsMap.types,
          label: filterFieldsLabels[filterFieldsMap.types],
          field: <Box minWidth={175}>
            <ScheduleEventTypesSelect
              isMulti
              isClearable
              name="types"
              label="Types"
              placeholder="Search type..."
              onChange={handleMultiSelectChange('types')}
            />
          </Box>
        }
      ]}

      {...props}
    />
  );
};
