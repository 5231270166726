import {
  makeStyles,
  IconButton,
  SvgIcon,
  Typography,
  Box
} from '@material-ui/core';
import { mdiDomain } from '@mdi/js';
import { Icon } from '@mdi/react';
import CloseIcon from '@material-ui/icons/Close';
import { AddressLink } from '../../../../../../components/AddressLink';
import { Avatar } from '../../../../../../components/Avatar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ company, onClose }) => {
  const classes = useStyles();
  const addresses = company?.offices?.map(({ id, name, full_address, lat, lng }) => ({
    key: id,
    locationName: name,
    address: full_address,
    location: {
      lat,
      lng
    }
  }));

  return (
    <div className={classes.root}>
      <Box mr={1}>
        <Avatar
          src={company.avatar}
          alt={company.name[0]}
          className={classes.logoAvatar}
        />
      </Box>

      <div className={classes.header}>
        <Typography title={company.name} variant="h6">
          {company.name}
        </Typography>

        <Typography color="textSecondary">
          {company?.company_type?.name || '-'}
        </Typography>

        <Box display="flex" alignItems="flex-end" pt={1}>
          <Box display="flex" mr={4}>
            <Box mr={0.5}>
              <SvgIcon><Icon path={mdiDomain} /></SvgIcon>
            </Box>

            <Typography>
              {`${company?.offices?.length || 0} offices`}
            </Typography>
          </Box>

          <AddressLink
            name={company?.main_office?.name}
            address={company?.main_office?.full_address}
            lat={company?.main_office?.lat}
            lng={company?.main_office?.lng}
            addresses={addresses}
            initialAddressKey={company?.main_office?.id}
          >
            Show on the map
          </AddressLink>
        </Box>
      </div>

      <IconButton
        className={classes.actionIcon}
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );
};
