import { useFormikContext } from 'formik';
import { makeStyles, FormControlLabel, Grid } from '@material-ui/core';
import { PhoneField } from '../../../../../../../components/FormField/PhoneField';
import {
  TextField,
  Checkbox,
  Select,
  ClaimFillingIndicatorsSelect,
  FinancialClassesSelect
} from '../../../../../../../components/FormField';
import { fetchInsuranceType, fetchProviderIDUsed } from '../../CodesContext/selectFieldHelpers';
import { box17Map, box24Map, box31Map, box32Map, claimProcessingMap, timelyFilingMap } from '../selectsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = () => {
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" spacing={2}>
        <Grid item sm={8} xs={12}>
          <TextField required name="name" label="Name" margin="dense" />

          <TextField required name="address_1" label="Address1" margin="dense" />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField name="code" label="Code" margin="dense" />

          <PhoneField zeroMinWidth name="main_phone" label="Main Phone" margin="dense" />
        </Grid>

        <Grid item sm={8} xs={12} container justify="space-between" spacing={1}>
          <Grid item sm={3} xs={6}>
            <TextField zeroMinWidth required name="zip" label="ZIP Code" />
          </Grid>

          <Grid item sm={3} xs={6}>
            <TextField zeroMinWidth required name="state" label="State" />
          </Grid>

          <Grid item sm={3} xs={6}>
            <TextField zeroMinWidth required name="city" label="City" />
          </Grid>
        </Grid>

        <Grid item sm={4} xs={12}>
          <PhoneField zeroMinWidth name="claim_phone" label="Claim Phone" />
        </Grid>
      </Grid>

      <Grid container spacing={2} justify="space-between">
        <Grid item sm={8} xs={12} container justify="space-between">
          <Grid item sm={8} xs={12}>
            <FinancialClassesSelect
              isClearable
              name="financial_class_id"
              TextFieldProps={{
                required: true,
                margin: 'dense'
              }}
            />

            <FormControlLabel
              label="NPI Only"
              control={<Checkbox required name="npi_only" />}
              className={classes.formField}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <FormControlLabel
              label="Secondary E-claims"
              control={<Checkbox required name="secondary_e_claims" />}
              className={classes.formField}
            />

            <FormControlLabel
              label="Accept NPI"
              control={<Checkbox required name="accept_npi" />}
              className={classes.formField}
            />
          </Grid>

          <Grid item sm={5} xs={12}>
            <Select
              isClearable
              formattedValue
              isSearchable={false}
              name="claim_processing"
              label="Claim Processing"
              options={claimProcessingMap}
              TextFieldProps={{ margin: 'dense' }}
            />

            <ClaimFillingIndicatorsSelect
              isClearable
              name="claim_filing_id"
              TextFieldProps={{
                required: true,
                margin: 'dense'
              }}
            />

            <Select
              isClearable
              formattedValue
              isSearchable={false}
              name="box_17"
              label="Box 17"
              options={box17Map}
              TextFieldProps={{ margin: 'dense' }}
            />

            <Select
              isClearable
              formattedValue
              isSearchable={false}
              name="box_31"
              label="Box 31"
              options={box31Map}
              TextFieldProps={{ margin: 'dense' }}
            />

            <Select
              isClearable
              formattedValue
              isSearchable={false}
              name="box_33"
              label="Box 33"
              options={box31Map}
              TextFieldProps={{ margin: 'dense' }}
            />
          </Grid>

          <Grid item sm={5} xs={12}>
            <Select
              isClearable
              formattedValue
              isSearchable={false}
              name="timely_filling"
              label="Timely Filing"
              options={timelyFilingMap}
              TextFieldProps={{ margin: 'dense' }}
            />

            <Select
              isClearable
              formattedValue
              name="insurance_type_code"
              label="Insurance Type Code"
              promiseWithOptions={fetchInsuranceType}
              TextFieldProps={{ margin: 'dense' }}
            />

            <Select
              isClearable
              formattedValue
              isSearchable={false}
              name="box_24"
              label="Box 24"
              options={box24Map}
              TextFieldProps={{ margin: 'dense' }}
            />

            <Select
              isClearable
              formattedValue
              isSearchable={false}
              name="box_32"
              label="Box 32"
              options={box32Map}
              TextFieldProps={{ margin: 'dense' }}
            />

            <FormControlLabel
              label="ICD 10"
              control={<Checkbox disabled checked={!!values.company_id} name="icd_10" />}
              className={classes.formField}
            />
          </Grid>
        </Grid>

        <Grid item sm={4} xs={12}>
          <PhoneField zeroMinWidth name="auth_cert_phone" label="Auth/Cert Phone" margin="dense" />

          <TextField name="fax_1" label="Fax 1" margin="dense" />

          <TextField name="fax_2" label="Fax 2" margin="dense" />

          <Select
            isClearable
            formattedValue
            name="provider_id_used"
            label="Provider ID Used"
            TextFieldProps={{
              required: true,
              margin: 'dense'
            }}
            promiseWithOptions={fetchProviderIDUsed}
          />
        </Grid>
      </Grid>
    </div>
  );
};
