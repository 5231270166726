export const filterFieldsMap = {
  caseID: 'case_id',
  patientID: 'patient_id',
  subject: 'subject',
  body: 'body',
  from: 'from',
  to: 'to'
};

export const filterFieldsLabels = {
  [filterFieldsMap.patientID]: 'Patient',
  [filterFieldsMap.caseID]: 'Case',
  [filterFieldsMap.subject]: 'Subject',
  [filterFieldsMap.body]: 'Body',
  [filterFieldsMap.from]: 'From',
  [filterFieldsMap.to]: 'To'
};
