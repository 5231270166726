export const bodyPartsTypesMap = {
  chest: 'chest',
  breast_l: 'breast_l',
  breast_r: 'breast_r',
  belly: 'belly',
  genitals: 'genitals',
  groin: 'groin',
  waist: 'waist',
  lower_back_l: 'lower_back_l',
  lower_back_r: 'lower_back_r',
  lumbar: 'lumbar',
  buttock_l: 'buttock_l',
  buttock_r: 'buttock_r',
  back_m: 'back_m',
  back_l: 'back_l',
  back_r: 'back_r',
  forearm_l: 'forearm_l',
  forearm_r: 'forearm_r',
  shoulder_l: 'shoulder_l',
  shoulder_r: 'shoulder_r',
  armpit_l: 'armpit_l',
  armpit_r: 'armpit_r',
  arm_l: 'arm_l',
  arm_r: 'arm_r',
  elbow_l: 'elbow_l',
  elbow_r: 'elbow_r',
  hand_l: 'hand_l',
  hand_r: 'hand_r',
  wrist_l: 'wrist_l',
  wrist_r: 'wrist_r',
  finger_l: 'finger_l',
  finger_r: 'finger_r',
  palm_l: 'palm_l',
  palm_r: 'palm_r',
  neck: 'neck',
  head: 'head',
  nape: 'nape',
  face: 'face',
  ear_l: 'ear_l',
  ear_r: 'ear_r',
  nose: 'nose',
  chin: 'chin',
  forehead: 'forehead',
  temple_l: 'temple_l',
  temple_r: 'temple_r',
  cheek_l: 'cheek_l',
  cheek_r: 'cheek_r',
  mouth: 'mouth',
  eye_l: 'eye_l',
  eye_r: 'eye_r',
  eyelid_l: 'eyelid_l',
  eyelid_r: 'eyelid_r',
  jaw: 'jaw',
  lip: 'lip',
  tooth: 'tooth',
  ball: 'ball',
  sole_l: 'sole_l',
  sole_r: 'sole_r',
  instep_l: 'instep_l',
  instep_r: 'instep_r',
  toe_l: 'toe_l',
  toe_r: 'toe_r',
  foot_l: 'foot_l',
  foot_r: 'foot_r',
  ankle_l: 'ankle_l',
  ankle_r: 'ankle_r',
  leg_l: 'leg_l',
  leg_r: 'leg_r',
  hip_l: 'hip_l',
  hip_r: 'hip_r',
  shin_l: 'shin_l',
  shin_r: 'shin_r',
  knee_l: 'knee_l',
  knee_r: 'knee_r',
  cal_l: 'cal_l',
  cal_r: 'cal_r',
  heel_l: 'heel_l',
  heel_r: 'heel_r',
  groin_l: 'groin_l',
  groin_r: 'groin_r',
  clavicle_l: 'clavicle_l',
  clavicle_r: 'clavicle_r',
  waist_l: 'waist_l',
  waist_r: 'waist_r'
};
