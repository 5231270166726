import { useContext, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { TasksContext, TasksFilterContext } from '../../../../../../components/tasks/TasksProvider';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Loader } from '../../../../../../components/Loader';
import { Button } from '../../../../../../components/Button';
import { CreateTaskModal } from '../../../CreateTaskModal';
import { ColumnDraggingList } from '../ColumnDraggingList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Column = ({ column, index, status, setStatusTasks }) => {
  const { isFetched, tasks, filter, pagination, fetchTasks } = useContext(TasksContext);
  const { isUpdatedPosition, resultDestination, updateTaskPosition } = useContext(TasksFilterContext);
  const { openModal } = useModal();
  const classes = useStyles();

  const addTask = () => {
    openModal(CreateTaskModal, {
      payload: {
        initialValues: { status },
        onUpdatePosition: updateTaskPosition
      },
      onModalResolved: () => {
        fetchTasks();
      }
    });
  };

  useEffect(() => {
    setStatusTasks((state) => ({
      ...state,

      columns: {
        ...state.columns,

        [status.id]: {
          id: status.id,
          name: status.name,
          items: tasks
        }
      }
    }));
  }, [ isFetched, tasks ]);

  useEffect(() => {
    if (isUpdatedPosition && filter.page > 1 && resultDestination > filter?.per_page) {
      if (filter.page < pagination.last_page) {
        fetchTasks({ page: filter.page });
      }
    }
  }, [ isUpdatedPosition, resultDestination ]);

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>{column?.name || status?.name}</Typography>

      <div className={classes.content}>
        <Loader p={3} loading={!isFetched} render={
          () => (
            <ColumnDraggingList column={column} status={status} index={index}/>
          )}
        />
      </div>

      <div className={classes.content__action}>
        <div className={classes.content__shadow} />

        <Button
          fullWidth
          className={classes.creationButton}
          onClick={addTask}
        >
          <AddIcon className={classes.creationButton__icon}/> Add new
        </Button>
      </div>
    </div>
  );
};
