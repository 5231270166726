import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../../../../api';
import * as codesApi from '../../../../../../api/codes/procedures';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { orderDirectionsMap } from '../../../../../../components/FormField';
import { usePrevious } from '../../../../../../helpers/hooks';
import { useModal } from '../../../../../../components/ModalsProvider';
import { ImportCodesModal } from '../ImportCodesModal';
import { orderByOptions } from '../MainContent/FiltersBar/orderByOptions';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CodesContext = createContext();

export const CodesContextProvider = ({ initialState: initialStateProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { openModal } = useModal();
  const {
    isFetched,
    isFetching,
    filter,
    pagination,
    codes
  } = state;
  const cancelFetch = useRef(() => {});
  const prevFilter = usePrevious(filter);
  const [ relationsForFilter, setRelationsForFilter ] = useState({
    order_by: orderByOptions[0],
    order_direction: orderDirectionsMap.desc
  });

  const fetchCodes = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_CODES_REQUEST });

    codesApi.fetchICDCodes({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_CODES_SUCCESS, payload: data });
    });
  };

  const deleteCustomCode = (code) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        codesApi.deleteICDCode(code.id).then(() => {
          dispatch({ type: types.DELETE_CODE_SUCCESS, payload: code });
        });
      }
    });
  };

  const resetCodes = (newFilter) => {
    dispatch({ type: types.RESET_CODES, payload: newFilter });

    fetchCodes({ page: 1, ...newFilter });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const importCodes = () => {
    openModal(ImportCodesModal, {
      onModalResolved: () => {
        resetCodes({ page: 1 });
      }
    });
  };

  const providerValue = {
    codes,
    isFetched,
    isFetching,
    filter,
    meta: {
      ...filter,
      ...pagination
    },
    relationsForFilter,

    // functions
    deleteCustomCode,
    applyFilter,
    fetchCodes,
    resetCodes,
    importCodes,
    setRelationsForFilter
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetCodes(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CodesContext.Provider value={providerValue}>
      {children}
    </CodesContext.Provider>
  );
};
