import { useContext, useEffect, useRef, useState } from 'react';
import { debounce, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { billingParticipantsTypesMap } from '../../../../../../components/billing';
import { CasesSelect } from '../../../../../../components/cases/CasesSelect';
import { paymentMethodsMap } from '../../../../../../dataMaps/paymentMethodsMap';
import { saveFilter } from '../../../../../../store/lastFilters';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { IconComponent } from '../../../../../../components/saved-filters';
import { PaymentsContext } from '../../../../../../components/billing/payments';
import {
  Select,
  TextField,
  BillingParticipantsSelect,
  KeyboardDatePicker
} from '../../../../../../components/FormField';
import { CardContent } from './CardContent';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { transformRelationsForFilterToOptions } from './utils';
import { initialValues } from './initialValues';
import { List } from './List';

const MODAL_WIDTH = 910;

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { applyFilter } = useContext(PaymentsContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.value });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data || option }));
  };

  const handleCaseChange = (caseItem) => {
    applyFilter({ case_id: caseItem?.id });
    setRelationsForFilter((state) => ({ ...state, case_id: caseItem || null }));
  };

  const handleUsersChange = (users) => {
    const companiesIDs = relationsForFilter?._companies?.map(({ id }) => id) || [];
    const usersIDs = users?.map(({ id }) => id) || [];

    applyFilter({ billing_participant_ids: companiesIDs.concat(usersIDs) });
    setRelationsForFilter((state) => ({ ...state, _users: users }));
  };

  const handleCompaniesChange = (companies) => {
    const usersIDs = relationsForFilter?._users?.map(({ id }) => id) || [];
    const companiesIDs = companies?.map(({ id }) => id) || [];

    applyFilter({ billing_participant_ids: usersIDs.concat(companiesIDs) });
    setRelationsForFilter((state) => ({ ...state, _companies: companies }));
  };

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const applySavedFilter = (relationsForFilter) => {
    const { _users, _companies, ...filter } = relationsForFilter;
    const billingParticipants = (filter?._users || [])?.concat(filter?._companies || [])?.map(({ id }) => id);

    formikRef?.current?.setValues(transformRelationsForFilterToOptions(relationsForFilter));

    applyFilter(omit({
      ...filter,

      billing_participant_ids: billingParticipants?.length ? billingParticipants : null,
      case_id: filter?.case_id?.id,
      method: filter?.method?.value
    }, hiddenFields));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={transformRelationsForFilterToOptions(relationsForFilter)}
      filterKey={filterKey}
      hiddenFields={hiddenFields}
      onReset={() => {
        setRelationsForFilter({
          ...initialValues,

          _users: null,
          _companies: null
        });
        applySavedFilter(initialValues);
      }}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.invoiceNumber,
          label: filterFieldsLabels[filterFieldsMap.invoiceNumber],
          field: <TextField
            name="invoice_number"
            label="Inv number"
            placeholder="Search..."
            onChange={handleFieldChange('invoice_number')}
          />
        },
        {
          fieldKey: filterFieldsMap.users,
          label: filterFieldsLabels[filterFieldsMap.users],
          field: <Box minWidth={200}>
            <BillingParticipantsSelect
              multiple
              name="_users"
              label="Users"
              params={{ type: billingParticipantsTypesMap.user }}
              onChange={handleUsersChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.companies,
          label: filterFieldsLabels[filterFieldsMap.companies],
          field: <Box minWidth={200}>
            <BillingParticipantsSelect
              multiple
              name="_companies"
              label="Companies"
              params={{ type: billingParticipantsTypesMap.company }}
              onChange={handleCompaniesChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.caseId,
          label: filterFieldsLabels[filterFieldsMap.caseId],
          field: <CasesSelect
            name="case_id"
            label="All cases"
            onChange={handleCaseChange}
          />
        },
        {
          fieldKey: filterFieldsMap.dateFrom,
          label: filterFieldsLabels[filterFieldsMap.dateFrom],
          field:  <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              outputFormat="YYYY-MM-DD"
              name="date_from"
              label="From"
              onChange={handleDatePickerChange('date_from')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.dateTo,
          label: filterFieldsLabels[filterFieldsMap.dateTo],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              outputFormat="YYYY-MM-DD"
              name="date_to"
              label="To"
              onChange={handleDatePickerChange('date_to')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.method,
          label: filterFieldsLabels[filterFieldsMap.method],
          field: <Select
            isClearable
            name="method"
            label="Payment Method"
            placeholder="Select method"
            options={paymentMethodsMap}
            onChange={handleSelectChange('method')}
          />
        }
      ]}
    />
  );
};
