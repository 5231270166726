import { FormControlLabel } from '@material-ui/core';
import { TextField, Switch } from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';

export const ContactForm = () => {
  return (
    <>
      <UsersSelect
        isCreatable
        name="contact_id"
        label="User"
        margin="dense"
      />

      <TextField
        multiline
        rows={4}
        name="note"
        placeholder="Add notes..."
        variant="filled"
        margin="dense"
      />

      <FormControlLabel
        label="Is favorite"
        margin="dense"
        control={
          <Switch
            color="primary"
            name="is_favorite"
          />
        }
      />
    </>
  );
};
