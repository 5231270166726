export const filterFieldsMap = {
  companyID: 'company_id',
  usersID: 'users_id',
  caseID: 'case_id'
};

export const filterFieldsLabels = {
  [filterFieldsMap.companyID]: 'Company',
  [filterFieldsMap.usersID]: 'Users',
  [filterFieldsMap.caseID]: 'Case'
};
