import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Grid, Dialog } from '@material-ui/core';
import * as codesApi from '../../../../../../api/codes/procedures';
import { setCPTCodesLastGlobalAction } from '../../../../../../store/globalActions';
import { DatePicker, TextField } from '../../../../../../components/FormField';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../../../components/Modal';
import { Loader } from '../../../../../../components/Loader';
import * as types from '../CodesContext/types';
import { validationSchema } from './validationSchema';

export const CreateModifierCodeModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    initialValues,
    codesContext
  }
}) => {
  const [ initialCodeValues, setInitialCodeValues ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();

  const createModifierCode = (code, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    codesApi.createModifierCode(code).then((code) => {
      handleModalResolve(code);

      if (codesContext.codes.length + 1 > codesContext.filter.per_page) {
        codesContext.fetchCodes({ page: codesContext.filter.page });
      } else {
        dispatchRedux(setCPTCodesLastGlobalAction({ type: types.ADD_CUSTOM_CODE, payload: code }));
      }

      enqueueSnackbar('Code successfully created', { variant: 'success' });
    }).catch(({ errors, message }) => {
      setSubmitting(false);

      if (errors) {
        const errorMessage = Object.values(errors);

        errors && setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  const updateCode = (code, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    codesApi.updateModifierCode(code).then((code) => {
      enqueueSnackbar('Code successfully updated', { variant: 'success' });
      dispatchRedux(setCPTCodesLastGlobalAction({ type: types.UPDATE_CODE_IN_LIST, payload: code }));
      handleModalResolve(code);
    }).catch(({ errors, message }) => {
      setSubmitting(false);

      if (errors) {
        const errorMessage = Object.values(errors);

        errors && setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  useEffect(() => {
    initialValues && initialValues.parentID && codesApi.fetchModifierCode(initialValues.parentID)
      .then(setInitialCodeValues);
  }, []);

  return (
    <Dialog {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={initialCodeValues || {
          code: '',
          description: '',
          date: ''
        }}
        validationSchema={validationSchema}
        onSubmit={initialValues ? updateCode : createModifierCode}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
          >
            <ModalHeader onClose={handleModalReject}>
              {initialValues ? 'Update Modifier Code' : 'Add Modifier Code'}
            </ModalHeader>

            <ModalBody>
              <Grid container spacing={2}>
                <Grid item sm={7} xs={12}>
                  <TextField
                    required
                    autoFocus
                    autoComplete="code"
                    name="code"
                    label="Code"
                  />
                </Grid>

                <Grid item sm={5} xs={12}>
                  <DatePicker
                    name="date"
                    label="Date"
                    InputProps={{ endAdornment: false }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    multiline
                    rows={5}
                    variant="filled"
                    name="description"
                    label="Description"
                  />
                </Grid>
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
