import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import * as workSessionsApi from '../../api/work-clocks';
import { rolesMap } from '../../dataMaps/rolesMap';
import { hasRole } from '../../utils/hasRole';
import { ConfirmationModal } from '../ConfirmationModal';
import { useModal } from '../ModalsProvider';
import { CreateWorkClocksModal } from './CreateWorkClocksModal';
import { WorkSessionsContext } from './WorkSessionsProvider';
import { WorkingSessionInfoModal } from './WorkSesstions/WorkingSessionInfoModal';

export const useWorkSession = (workSession = {}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const workSessionsContext = useContext(WorkSessionsContext);
  const hasAccess = hasRole(rolesMap.admin, rolesMap.supervisor);

  const getTracksByType = (type) => {
    return workSession.session_tracks?.filter((track) => track.type === type);
  };

  const addTrack = () => {
    openModal(CreateWorkClocksModal, {
      payload: { workSession },
      onModalResolved: (track) => {
        workSessionsContext?.addTrack(workSession.id, track);
      }
    });
  };

  const deleteWorkSession = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        workSessionsApi.deleteWorkSession(workSession.id).then(() => {
          workSessionsContext.deleteWorkSession(workSession);
          enqueueSnackbar('Work session deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Work session not deleted', { variant: 'error' });
        });
      }
    });
  };

  const openWorkingSessionInfoModal = () => {
    openModal(WorkingSessionInfoModal, {
      payload: {
        workSession,
        onTrackAdd: workSessionsContext.addTrack,
        onTrackUpdate: workSessionsContext.updateTrack,
        onTrackDelete: workSessionsContext.deleteTrack
      }
    });
  };

  return {
    hasAccess,
    addTrack,
    deleteWorkSession,
    getTracksByType,
    openWorkingSessionInfoModal
  };
};
