export const styles = ({ spacing, palette: { grey }, typography: { pxToRem } }) => ({
  root: {
    padding: spacing(1, 0),
    color: grey[800],

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },

  itemText: {
    margin: 0,
    paddingLeft: 0
  },

  itemPrimary: {
    fontSize: pxToRem(14),
    fontWeight: 500,
    color: grey[800]
  },

  itemSecondary: {
    fontSize: pxToRem(12)
  },

  itemActions: {
    right: 0
  }
});
