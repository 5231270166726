import moment from 'moment';
import { Yup } from '../../../../utils/validation';

export const validationSchema =  Yup.object().shape({
  access_email: Yup.string().email().nullable().required().max(255),
  expired_at: Yup.lazy(() => Yup
    .date()
    .format('X')
    .min(moment().add(1, 'hour'), 'Minimum period is 1 hour')
    .max(moment().add(30, 'days'), 'Maximum period is 30 days')
    .nullable()
  )
});
