import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  tos_id: Yup.mixed().nullable().required(),
  office_id: Yup.mixed().nullable().when('is_all_offices', {
    is: (value) => !value,
    then: Yup.mixed().nullable().required()
  }),
  provider_id: Yup.mixed().nullable().when('is_all_providers', {
    is: (value) => !value,
    then: Yup.mixed().nullable().required()
  }),
  base_unit: Yup.number().nullable().required().min(1).max(1000),
  procedure_code: Yup.string().nullable().required().min(3).max(255),
  region_code_id: Yup.mixed().nullable().required(),
  description: Yup.string().nullable().required().min(3).max(255),
  display_in_box_19: Yup.string().nullable().required().max(255),
  add_on: Yup.bool().required(),
  enable_crosswalk: Yup.bool().required(),
  hcpcs_exceptions: Yup.bool().required(),
  enable_cross_codes: Yup.bool().required()
});
