import { Box } from '@material-ui/core';
import { fieldsMap } from '../../claims/Activity/activityActionTypesMap/fieldsMap';
import { WidgetActivity } from '../fields/WidgetActivity';

export const ClaimActivity = ({
  isDeleted = false,
  isNew = false,
  fieldLabel,
  activityLabel,
  payload
}) => {
  if (isDeleted) {
    return 'Removed claim';
  }

  return (
    <>
      {isNew && (
        <Box component="span" display="flex" alignItems="center">
          Added new Claim
        </Box>
      )}

      <WidgetActivity
        {...payload?.properties}
        fieldLabel={fieldLabel}
        activityLabel={activityLabel}
        fieldsMap={fieldsMap}
      />
    </>
  );
};
