import moment from 'moment';
import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PreviewTaskDate = ({ task }) => {
  const classes = useStyles();

  return(
    <div className={classes.root}>
      <Typography className={classes.mainTitle}>
        IMPORTANT DATE
      </Typography>

      <Typography className={classes.title}>
        Created
      </Typography>

      <div className={classes.date}>
        <Typography className={classes.date__content}>
          {moment.unix(task.created_at).format('MM/DD/YYYY')}
        </Typography>

        <Typography className={classes.date__content}>
          {moment.unix(task.created_at).format('h:mm a')}
        </Typography>
      </div>

      {task.extended_at &&
        <>
          <Typography className={classes.title}>
            Extended
          </Typography>

          <div className={classes.date}>
            <Typography className={classes.date__content}>
              {moment.unix(task.extended_at).format('MM/DD/YYYY')}
            </Typography>

            <Typography className={classes.date__content}>
              {moment.unix(task.extended_at).format('h:mm a')}
            </Typography>
          </div>
        </>
      }


      {task.opened_at &&
        <>
          <Typography className={classes.title}>
            Open
          </Typography>

          <div className={classes.date}>
            <Typography className={classes.date__content}>
              {moment.unix(task.opened_at).format('MM/DD/YYYY')}
            </Typography>

            <Typography className={classes.date__content}>
              {moment.unix(task.opened_at).format('h:mm a')}
            </Typography>
          </div>
        </>
      }

      {task.status_changed_at &&
        <>
          <Typography className={classes.title}>
            Changed status at
          </Typography>

          <div className={classes.date}>
            <Typography className={classes.date__content}>
              {moment.unix(task.status_changed_at).format('MM/DD/YYYY')}
            </Typography>

            <Typography className={classes.date__content}>
              {moment.unix(task.status_changed_at).format('h:mm a')}
            </Typography>
          </div>
        </>
      }

      {task.updated_at &&
        <>
          <Typography className={classes.title}>
            Updated
          </Typography>

          <div className={classes.date}>
            <Typography className={classes.date__content}>
              {moment.unix(task.updated_at).format('MM/DD/YYYY')}
            </Typography>

            <Typography className={classes.date__content}>
              {moment.unix(task.updated_at).format('h:mm a')}
            </Typography>
          </div>
        </>
      }

      {task.due_at &&
        <>
          <Typography className={cn(classes.title, classes.preview__due)}>
            Due date
          </Typography>

          <div className={classes.date}>
            <Typography className={classes.date__content}>
              {moment.unix(task.due_at).format('MM/DD/YYYY')}
            </Typography>

            <Typography className={classes.date__content}>
              {moment.unix(task.due_at).format('h:mm a')}
            </Typography>
          </div>
        </>
      }

      {task.reopened_at &&
        <>
          <Typography className={classes.title}>
            Extended
          </Typography>

          <div className={classes.date}>
            <Typography className={classes.date__content}>
              {moment.unix(task.reopened_at).format('MM/DD/YYYY')}
            </Typography>

            <Typography className={classes.date__content}>
              {moment.unix(task.reopened_at).format('h:mm a')}
            </Typography>
          </div>
        </>
      }
    </div>
  );
};
