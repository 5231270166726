import { Formik } from 'formik';
import { Box, Button, Dialog, Link } from '@material-ui/core';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { usersRolesOptions } from '../../../../dataMaps';
import { stopPropagation } from '../../../../helpers/dom';
import { CompaniesAutocomplete, OfficesLocationSelect, Select } from '../../../FormField';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../Modal';
import { Loader } from '../../../Loader';
import { useModal } from '../../../ModalsProvider';
import { UserLink } from '../../../UserLink';
import { UserPreviewModal } from '../../UserPreviewModal';
import { UsersSelect } from '../../UsersSelect';

const initialValues = {
  role: null,
  office_id: null,
  company_id: null,
  user_id: null
};

export const ReplaceMemberModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { user }
}) => {
  const { openModal } = useModal();

  const onModalResolve = (values) => () => {
    handleModalResolve(values);
  };

  const openPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id }
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={initialValues}
        onSubmit={null}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader
              icon={<PersonAddOutlinedIcon />}
              onClose={handleModalReject}
            >
              <Box display="flex" alignItems="baseline">
                Replace
                <UserLink
                  noAvatar
                  key={user?.id}
                  user={user}
                  variant="h3"
                  color="primary"
                  component={Link}
                  onClick={stopPropagation(openPreview(user?.id))}
                />
                &nbsp;
                for
              </Box>
            </ModalHeader>

            <ModalBody>
              <Select
                isClearable
                formattedValue
                name="role"
                label="Position (Role)"
                options={usersRolesOptions}
                TextFieldProps={{ margin: 'dense' }}
              />

              <CompaniesAutocomplete
                label="Name Company"
                name="company_id"
                margin="dense"
              />

              <OfficesLocationSelect
                label="Name Office"
                name="office_id"
                margin="dense"
                params={{ company_id: values?.company_id || null }}
              />

              <UsersSelect
                name="user_id"
                label="Staff"
                params={{
                  role: values?.role || null,
                  company_id: values?.company_id || null,
                  office_id: values?.office_id || null
                }}
                TextFieldProps={{ margin: 'dense' }}
              />
            </ModalBody>

            <ModalFooter>
              <Box mr={1}>
                <Button onClick={handleModalReject} color="primary">Cancel</Button>
              </Box>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={onModalResolve(values)}
                  >
                    Replace
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
