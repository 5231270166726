export const styles = ({ palette: { grey }, typography: { fontWeightMedium } }) => ({
  textFieldInput: {
    color: `${grey[800]} !important`,

    '&:before': {
      borderBottomStyle: 'solid !important'
    }
  },

  input: {
    color: ({ color = grey[800] }) => color,
    fontWeight: fontWeightMedium
  }
});
