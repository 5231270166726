import { Grid, Typography } from '@material-ui/core';
import { orderByLabelsMap } from '../../../../../../../../components/claims';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.code &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Code:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.code}
            </Typography>
          </Grid>
        </>
      }

      {params?.description &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Description:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.description}
            </Typography>
          </Grid>
        </>
      }

      {params?.order_by &&
        <>
          <Grid item xs={4}>
            <Typography>
              Order By:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {orderByLabelsMap[params?.order_by?.value]}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};

