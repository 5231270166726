export const styles = ({
  spacing,
  palette: { grey, primary, lightgreen, divider, info },
  typography: { pxToRem, fontWeightMedium },
  shape: { borderRadius }
}) => ({
  activeBorder: {
    borderColor: info.main
  }
});
