import { useFormikContext } from 'formik';
import { FormControlLabel, Typography } from '@material-ui/core';
import {
  CompaniesAutocomplete,
  Radio,
  RadioGroup,
  TextField
} from '../../../../../../../../components/FormField';

export const Body = () => {
  const { setFieldValue } = useFormikContext();

  const handleCompanyChange = (company) => {
    setFieldValue('direct_ref_address', company?.full_address || null);
  };

  return (
    <>
      <RadioGroup
        row
        name="dirreff_attorney"
        FormControlProps={{ margin: 'dense' }}
      >
        <FormControlLabel
          value="1"
          label={<Typography>Yes</Typography>}
          control={<Radio name="dirreff_attorney" color="primary" />}
        />

        <FormControlLabel
          value="0"
          label={<Typography>No</Typography>}
          control={<Radio name="dirreff_attorney" color="primary" />}
        />
      </RadioGroup>

      <CompaniesAutocomplete
        name="direct_attorney_company_id"
        label="Company Name"
        onChange={handleCompanyChange}
      />

      <TextField
        disabled
        name="direct_ref_address"
        label="Address"
        margin="dense"
      />
    </>
  );
};
