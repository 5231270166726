import { CurrentSymptoms } from '../../../../Diagnosis/steps/FirstStep/CurrentSymptoms';

export const CurrentSimptomsBack = () => {
  return (
    <CurrentSymptoms
      disableBodyParts
      title="Back"
      name="forms.medical_form_info.current_symptoms_back"
    />
  );
};
