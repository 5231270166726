import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { UserLink } from '../../../../../../../../../components/UserLink';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.number &&
        <>
          <Grid item xs={4}>
            <Typography>
              Inv. number:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.number}
            </Typography>
          </Grid>
        </>
      }

      {!!params?.users_id?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Users:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column">
              {params?.users_id?.map((user) => (
                <Box display="flex" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.case_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Case:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.case_id?.name}
            </Typography>
          </Grid>
        </>
      }

      {params?.from &&
        <>
          <Grid item xs={4}>
            <Typography>
              Date From:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment.unix(params?.from).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.to &&
        <>
          <Grid item xs={4}>
            <Typography>
              Date To:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment.unix(params?.to).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.category_id &&
        <>
          <Grid item xs={4}>
            <Typography title="Expense Category" noWrap>
              Expense Category:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.category_id?.name}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
