import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Typography, Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { ActionButtons } from '../../../../../components/medical/forms';
import * as formsApi from '../../../../../api/profile';
import { Divider } from '../../../../../components/Divider';
import { Loader } from '../../../../../components/Loader';
import {
  carAccidentValidationSchema,
  otherAccidentValidationSchema,
  slipAndFallValidationSchema,
  generateAccidentInitialOrthoValues
} from '../../../../../components/medical/forms/accidentInfoOrtho';
import { accidentTypesTitles } from '../../../../../components/medical/accidentTypes';
import { FormContext } from '../../FormProvider';
import { styles } from '../../styles';
import { Body } from './Body';

const useStyles = makeStyles(styles);

export const AccidentInfo = () => {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { isFetched, form, handleComplete, handleBack } = useContext(FormContext);
  const validationSchema = form?.accident_type === accidentTypesTitles.car_accident
    ? carAccidentValidationSchema
    : form?.accident_type === accidentTypesTitles.slip_and_fall
      ? slipAndFallValidationSchema
      : otherAccidentValidationSchema;

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updateGuestAccidentInformationOrtho(values).then(({ data }) => {
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleComplete(data);
    }).catch(({ errors }) => {
      setErrors(errors);
      enqueueSnackbar('Form not updated', { variant: 'error' });
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            token: form?.token || null,
            form_id: form.id,
            ...generateAccidentInitialOrthoValues(form)
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <Grid
                container
                spacing={isSmallScreen ? 2 : 3}
                alignItems="flex-end"
                component={Box}
                pb={3}
              >
                <Grid item xs={12}>
                  <Typography variant="h5" color="secondary">
                    Accident information - {form?.accident_type}
                  </Typography>
                </Grid>

                <Body form={form} />
              </Grid>

              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
