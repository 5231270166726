import moment from 'moment';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChatDivider = ({ notification }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box p={2}>
        <Typography variant="body1">
          {moment.unix(notification?.created_at).format('MM/DD')}
        </Typography>
      </Box>
    </div>
  );
};
