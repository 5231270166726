export const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRight: `1px solid ${palette.divider}`
  },

  top: {
    flexGrow: 1
  },

  composeBtnWrap: {
    padding: spacing(1.5, 2)
  }
});
