import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as arrivalStatusesApi from '../../../../../../api/codes/appointments/arrival-statuses';
import { arrivalTypesOptions } from '../../../../../../components/appointments';
import { Loader } from '../../../../../../components/Loader';
import {
  Page,
  PageBody,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../../../components/Page';
import { ArrivalStatusForm } from '../ArrivalStatusesForm';
import { validationSchema } from '../validationSchema';

export const Edit = ({ baseUrl }) => {
  const history = useHistory();
  const params = useParams();
  const [ arrivalStatus, setArrivalStatus ] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const updateArrivalStatus = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return arrivalStatusesApi.updateArrivalStatus(values).then(() => {
      history.push(`${baseUrl}/arrival-statuses`);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  useEffect(() => {
    const fetchArrivalStatus = () => {
      arrivalStatusesApi.fetchArrivalStatus(params.id).then((arrivalStatus) => {
        arrivalStatus.arrival_type = arrivalTypesOptions.find(({ type }) => type === arrivalStatus.arrival_type);

        setArrivalStatus(arrivalStatus);
      }).catch(() => {
        history.push(`${baseUrl}/arrival-statuses`);
      });
    };

    fetchArrivalStatus();
  }, []);

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={arrivalStatus || {}}
      onSubmit={updateArrivalStatus}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Page noValidate component="form" onSubmit={handleSubmit}>
          <PageHeader>
            <IconButton
              component={Link}
              to={`${baseUrl}/arrival-statuses`}
              color="primary"
              edge="start"
            >
              <KeyboardBackspaceOutlinedIcon />
            </IconButton>

            <PageHeaderTitle>
              Edit arrival status
            </PageHeaderTitle>

            <PageHeaderActions>
              <Button
                size={isMobile ? 'small' : 'medium'}
                component={Link}
                to={`${baseUrl}/arrival-statuses`}
                color="primary"
              >
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    size={isMobile ? 'small' : 'medium'}
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </PageHeaderActions>
          </PageHeader>

          <PageBody>
            <Loader loading={!arrivalStatus} render={
              () => (
                <ArrivalStatusForm />
              )}
            />
          </PageBody>
        </Page>
      )}
    </Formik>
  );
};
