import { Fragment } from 'react';
import cn from 'classnames';
import { makeStyles, DialogContent } from '@material-ui/core';
import { Scrollbars } from '../Scrollbars';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: ({ disableScrollbars }) => disableScrollbars ? 'hidden' : null,
    margin: 0,
    padding: spacing(2, 3)
  },

  disablePaddings: {
    padding: 0
  },

  disableScrollbars: {
    overflow: 'auto'
  },

  scrollbars: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,

    '& > *': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    }
  }
}));

export const ModalBody = ({ className, disablePaddings, disableScrollbars, ...props }) => {
  const classes = useStyles();
  const Wrapper = disableScrollbars ? Fragment : Scrollbars;
  const wrapperProps = disableScrollbars ? {} : {
    autoHeight: true,
    autoHeightMax: '100%',
    className: classes.scrollbars
  };

  return (
    <Wrapper {...wrapperProps}>
      <DialogContent
        className={cn(classes.root, className, {
          [classes.disablePaddings]: disablePaddings,
          [classes.disableScrollbars]: disableScrollbars
        })}
        {...props}
      />
    </Wrapper>
  );
};
