import { useRef } from 'react';
import { Paper, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../components/ListRow';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { columnsWidths } from '../listConfig';

export const TableHeader = () => {
  const theme = useTheme();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = width <= theme.breakpoints.values.mobileSm;

  return (
    <Paper ref={rootRef} elevation={0}>
      <ListRow header px={0}>
        <ListRowCell px={0} flexBasis={columnsWidths.dragIcon} />

        <ListRowCell grow flexBasis={columnsWidths.file}>
          File
        </ListRowCell>

        <ListRowCell grow={isMobile} flexBasis={columnsWidths.selectPages}>
          Select pages
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
