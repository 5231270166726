import {
  KeyboardDatePicker,
  TextField
} from '../../../../../../../components/FormField';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';

export const filterFields = [
  <TextField
    name="name"
    placeholder="Enter the name..."
    label={filterFieldsLabels[filterFieldsMap.name]}
  />,

  <KeyboardDatePicker
    disableFuture
    clearable
    name="date_received"
    label={filterFieldsLabels[filterFieldsMap.date_received]}
    margin="dense"
    outputFormat="YYYY-MM-DD"
  />,

  <KeyboardDatePicker
    clearable
    name="date_responded"
    label={filterFieldsLabels[filterFieldsMap.date_responded]}
    margin="dense"
    outputFormat="YYYY-MM-DD"
  />,

  <KeyboardDatePicker
    clearable
    name="deposition_date"
    label={filterFieldsLabels[filterFieldsMap.deposition_date]}
    margin="dense"
    outputFormat="YYYY-MM-DD"
  />
];
