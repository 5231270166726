export const pagesSelectingTypesMap = {
  all: 'all',
  include: 'include',
  exclude: 'exclude'
};

export const pagesSelectingTypes = [
  {
    value: pagesSelectingTypesMap.all,
    label: 'All pages'
  },
  {
    value: pagesSelectingTypesMap.include,
    label: 'Include pages'
  },
  {
    value: pagesSelectingTypesMap.exclude,
    label: 'Exclude pages'
  }
];
