import { Typography } from '@material-ui/core';
import { isEqual } from 'lodash';

export const Events = ({ before = null, after = null, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Added&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  } else if (before && after && !isEqual(before, after)) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  } else {
    return (
      <>Updated&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>&nbsp;option</>
    );
  }
};
