import { Typography } from '@material-ui/core';
import moment from 'moment';

export const TracksTooltip = ({ tracks }) => {
  return tracks.map((track) => (
    <Typography key={track.id} color="inherit">
      {moment.unix(track.started_at).format('LT')} - {!!track.finished_at && (
        moment.unix(track.finished_at).format('LT')
      )}
    </Typography>
  ));
};
