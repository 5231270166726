import { Icon } from '@mdi/react';
import {
  mdiClipboardAccountOutline,
  mdiAccountHeartOutline,
  mdiFileMultipleOutline,
  mdiFileAccountOutline,
  mdiFileDocumentOutline
} from '@mdi/js';
import { SvgIcon } from '@material-ui/core';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { GeneralInfo } from './GeneralInfo';
import { Appointments } from './Appointments';
import { EditUser } from './EditUser';
import { Files } from './Files';
import { MainInfo } from './MedicalInfo/MainInfo';
import { MedicalForms } from './MedicalInfo/MedicalForms';

export const clientRoutes = [
  {
    exact: true,
    path: '/profile',
    icon: <SvgIcon><Icon path={mdiClipboardAccountOutline} /></SvgIcon>,
    label: 'General info',
    component: GeneralInfo
  },
  {
    path: '/profile/medical-info/medical-forms',
    icon: <SvgIcon><Icon path={mdiFileAccountOutline} /></SvgIcon>,
    label: 'Medical Forms',
    component: MedicalForms
  },
  {
    exact: true,
    path: '/profile/edit',
    icon: null,
    label: null,
    component: EditUser
  },
  {
    exact: true,
    path: '/profile/medical-info',
    icon: <SvgIcon><Icon path={mdiAccountHeartOutline} /></SvgIcon>,
    label: 'Medical Info',
    component: MainInfo
  },
  {
    path: '/profile/medical-info/medical-report',
    icon: <SvgIcon><Icon path={mdiFileDocumentOutline} /></SvgIcon>,
    label: 'Medical Report'
  },
  {
    exact: true,
    path: '/profile/appointments',
    icon: <InsertInvitationOutlinedIcon />,
    label: 'Appointments',
    component: Appointments
  },
  {
    path: '/profile/files',
    icon: <SvgIcon><Icon path={mdiFileMultipleOutline} /></SvgIcon>,
    label: 'Files',
    component: Files
  }
];
