import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { columnsWidths } from '../CaseCross';

export const TableHeader = () => {
  const isPatient = hasRole(rolesMap.patient);

  return (
    <ListRow header>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        Case Name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.file_number}>
        File No
      </ListRowCell>

      {!isPatient &&
        <ListRowCell flexBasis={columnsWidths.action}/>
      }
    </ListRow>
  );
};
