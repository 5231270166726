import { Box, SvgIcon, Typography } from '@material-ui/core';
import { palette } from '../../../themes/palette';
import { Page, PageBody, PageHeader } from '../../../components/Page';
import LogoSVG from '../../Dashboard/Layout/Sidebar/SidebarContent/logo.svg';

export const EmptyPage = () => {
  return (
    <Page>
      <PageHeader disablePaddings color={palette().grey[600]}>
        <Box py={0.75} fontSize={60} display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
          <SvgIcon fontSize="inherit">
            <LogoSVG />
          </SvgIcon>
        </Box>
      </PageHeader>

      <PageBody>
        <Box my={5} display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
          <Typography variant="h4">
            Check your email inbox
          </Typography>
        </Box>
      </PageBody>
    </Page>
  );
};
