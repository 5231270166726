export const filterFieldsMap = {
  user: 'user_id',
  parentUser: 'parent_user_ids',
  parentCase: 'parent_case_ids',
  phone: 'phone',
  disposition: 'disposition',
  is_group_call: 'is_group_call',
  is_callback: 'is_callback',
  dateFrom: 'date_from',
  dateTo: 'date_to'
};

export const filterFieldsLabels = {
  [filterFieldsMap.user]: 'User',
  [filterFieldsMap.parentUser]: 'Patient',
  [filterFieldsMap.parentCase]: 'Case',
  [filterFieldsMap.phone]: 'Number',
  [filterFieldsMap.disposition]: 'Disposition',
  [filterFieldsMap.is_group_call]: 'Call type',
  [filterFieldsMap.is_callback]: 'Callback',
  [filterFieldsMap.disposition]: 'Disposition',
  [filterFieldsMap.dateFrom]: 'From',
  [filterFieldsMap.dateTo]: 'To'
};
