import { useRef } from 'react';
import { useMediaQuery, Box, useTheme } from '@material-ui/core';
import { ActivityProvider } from '../../../components/Activities';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../components/Page';
import { useResizeObserver } from '../../../helpers/hooks';
import { filtersKeysMap } from '../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { FiltersBar } from './FiltersBar';
import { List } from './List';

export const Activity = () => {
  const rootRef = useRef();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { width } = useResizeObserver({ ref: rootRef });
  const theme = useTheme();
  const isTablet = width <= theme.breakpoints.values.tabletLg;
  const {
    ScrollWrapper,
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps
  } = useMobileFilterCollapse('tabletLg', width);
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  return (
    <ActivityProvider allActivities>
      <Box
        ref={rootRef}
        bgcolor="grey.100"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        height="100%"
        px={isMobile ? 1 : 3}
        py={isMobile ? 1 : 2}
      >
        <Page>
          <PageHeader>
            <PageHeaderTitle>
              Activity
            </PageHeaderTitle>

            {isTablet && (
              <PageHeaderActions disableSpacing endPosition>
                {filterOpenButton}
              </PageHeaderActions>
            )}
          </PageHeader>

          <PageBody disablePaddings disableScrollbars fullHeight>
            <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
              <ScrollWrapper {...scrollWrapperProps}>
                <FilterBarWrapper {...filterBarWrapperProps}>
                  <FiltersBar filterKey={filtersKeysMap.activity} />
                </FilterBarWrapper>
              </ScrollWrapper>

              <Box flexGrow={1}>
                <List />
              </Box>
            </Box>
          </PageBody>
        </Page>
      </Box>
    </ActivityProvider>
  );
};
