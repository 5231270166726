import { Box, Typography } from '@material-ui/core';
import { TextField } from '../../../../../../../components/FormField';

export const Impression = () => {
  return (
    <>
      <Box mt={3}>
        <Typography variant="h4">
          Impression
        </Typography>
      </Box>

      <TextField
        fullWidth
        multiline
        disableUnderline
        rows={3}
        name="forms.impression.text"
        variant="filled"
      />
    </>
  );
};
