import { useHistory } from 'react-router-dom';
import { IconButton, Link, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useEffect } from 'react';
import { EmailsSendModal } from '../../../../../../app/Dashboard/EmailsPage/EmailsClient/EmailsSendModal';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { preventDefault, stopPropagation } from '../../../../../../helpers/dom';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../ListRow';
import { Loader } from '../../../../../Loader';
import { useModal } from '../../../../../ModalsProvider';
import { Popper } from '../../../../../Popper';
import { Tooltip } from '../../../../../Tooltip';
import { UserLink } from '../../../../../UserLink';
import { Menu } from '../../Menu';
import { columnsWidths } from '../listConfig';

export const Row = ({ item: service = {}, isLoaded, recalculateHeight }) => {
  const history = useHistory();
  const { openModal } = useModal();

  const openServiceSessionsPage = () => {
    history.push(`/services/${service.id}`);
  };

  const openServicePage = () => {
    window.open(service.login_url);
  };

  const openEmailLink = () => {
    openModal(EmailsSendModal, {
      payload: {
        to: [ service.email ]
      }
    });
  };

  useEffect(() => {
    if (isLoaded) {
      recalculateHeight();
    }
  }, [ isLoaded, recalculateHeight ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button px={2} onClick={openServiceSessionsPage}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        {service.login_url ? (
          <Link href={service.login_url} onClick={preventDefault(stopPropagation(openServicePage))}>
            {service.name}
          </Link>
        ) : service.name}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.description}>
        <Tooltip title={service.description}>
          <Typography noWrap>
            {service.description}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.email}>
        {service.email ? (
          <Link href={`mailto:${service.email}`} onClick={preventDefault(stopPropagation(openEmailLink))}>
            {service.email}
          </Link>
        ) : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.creator}>
        <UserLink user={service.user} />
      </ListRowCell>

      {hasRole(rolesMap.admin, rolesMap.supervisor) &&
        <ListRowCell flexBasis={columnsWidths.actions}>
          <ListRowCellActions>
            <Popper
              placement="left-start"
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <IconButton ref={anchorRef} onClick={stopPropagation(handleToggle)}>
                    <MoreVertIcon />
                  </IconButton>
                )
              }
            >
              {({ handleClose }) => (
                <Menu
                  service={service}
                  onClose={handleClose}
                />
              )}
            </Popper>
          </ListRowCellActions>
        </ListRowCell>
      }
    </ListRow>
  );
};
