import { useState, useEffect } from 'react';
import * as codesApi from '../../../api/codes/procedures';
import { Autocomplete } from '../Autocomplete';

const fetchICDTypes = (search) => {
  return codesApi.fetchICDTypes({ params: { search } })
    .then(({ data: { data } }) => data);
};

export const ICDCodesTypesAutocomplete = (props) => {
  const [ options, setOptions ] = useState([]);

  useEffect(() => {
    fetchICDTypes().then(setOptions);
  }, []);

  return (
    <Autocomplete
      placeholder="Select type..."
      options={options}
      getOptionLabel={(option) => option && option.name}
      getOptionValue={(option) => option?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}

      {...props}
    />
  );
};
