import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.MESSAGES_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.MESSAGES_FETCH_SUCCESS]: (
    { filter, threadMessages, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    const newMessages = data.reverse();

    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      threadMessages: page === 1 ? newMessages : newMessages.concat(threadMessages.filter(({ id }) => {
        return !newMessages.find((loadedMessage) => id === loadedMessage.id);
      }))
    };
  },

  [types.ADD_MESSAGE]: (state, message) => {
    const { pagination, threadMessages } = state;

    if (threadMessages.some(({ id }) => id === message.id)) {
      return state;
    }

    return {
      ...state,

      threadMessages: [ ...threadMessages, message ],
      pagination: {
        ...pagination,

        total: pagination.total + 1
      }
    };
  },

  [types.DELETE_MESSAGE]: ({ pagination, filter, threadMessages, ...state }, messagesIDs) => {
    const deletedMessages = threadMessages.filter((message) => messagesIDs.find((id) => id === message.id));
    const total = pagination.total - deletedMessages.length;
    const page = Math.ceil((threadMessages.length - deletedMessages.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      threadMessages: threadMessages.filter((message) => !deletedMessages.find(({ id }) => id === message.id))
    };
  },

  [types.UPDATE_MESSAGE]: ({ threadMessages, ...state }, updatedMessage) => {
    return {
      ...state,

      threadMessages: threadMessages.map((message) => updatedMessage.id === message.id ? updatedMessage : message)
    };
  },

  [types.RESET_MESSAGES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.MARK_THREAD_AS_SEEN]: ({ threadMessages, ...state }, { currentUser }) => {
    return {
      ...state,

      threadMessages: threadMessages.map((message) => message?.user?.id === currentUser?.id ? {
        ...message,

        status: 'seen'
      } : message)
    };
  }
});
