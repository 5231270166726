import { orderDirectionsMap } from '../../../../../../components/FormField';
import { orderByOptions } from './orderByOptions';

export const initialValues = {
  file_number: null,
  name: null,
  statuses: [],
  users: [],
  types: [],
  case_roles: [],
  priorities: null,
  created_from: null,
  created_to: null,
  order_by: orderByOptions[0],
  order_direction: orderDirectionsMap.desc
};
