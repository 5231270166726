import {
  Box,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Checkbox, TextField } from '../../../../../../components/FormField';

export const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display="flex" flexDirection="column" p={isSmallScreen ? 1 : 2} pr={2} overflow="hidden">
      <Grid
        container
        spacing={isSmallScreen ? 2 : 3}
        component={Box}
        alignItems="center"
        justify="flex-start"
        py={2}
        m="0 !important"
      >
        <Grid item>
          <Typography variant="h5">
            11. What <u>side effects</u> or <u>symptoms</u> are you having? Check what best describes your experience
            during the past week? Check all that apply
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" justifyContent="space-around" px={2}>
            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Chest pain</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.what_side_effects_or_symptoms_are_you_having.chest_pain"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Cough</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.what_side_effects_or_symptoms_are_you_having.cough"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Nausea</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.what_side_effects_or_symptoms_are_you_having.nausea"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Stomach Pain</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.what_side_effects_or_symptoms_are_you_having.stomach_pain"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Itch</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.what_side_effects_or_symptoms_are_you_having.itch"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Insomnia</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.what_side_effects_or_symptoms_are_you_having.insomnia"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Depression</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.what_side_effects_or_symptoms_are_you_having.depression"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Sweating</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.what_side_effects_or_symptoms_are_you_having.sweating"
                  />
                }
              />
            </Box>
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="h5">
            12. Please list any new medication added since the last visit
          </Typography>
        </Grid>

        <Grid item lg={6} xs={11}>
          <TextField name="forms.please_list_any_new_medication_added_since_the_last_visit" />
        </Grid>
      </Grid>
    </Box>
  );
};
