import { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, Typography } from '@material-ui/core';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { Fab } from '../../../../../../../components/Fab';
import { Loader } from '../../../../../../../components/Loader';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { MedicalProfileContext } from '../../MedicalProfileProvider';
import { styles } from '../PatientInfo/styles';
import { FamilyContactsModal } from './FamilyContactsModal';
import { Row } from './Row';

const useStyles = makeStyles(styles);

export const FamilyTree = () => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { isFetched, medicalProfile, getMedicalProfile } = useContext(MedicalProfileContext);
  const [ initialValues, setInitialValues ] = useState(null);

  const openWidgetModal = () => {
    openModal(FamilyContactsModal, {
      payload: {
        initialValues: medicalProfile
      },
      onModalResolved: () => getMedicalProfile()
    });
  };

  useEffect(() => {
    isFetched && setInitialValues(medicalProfile);
  }, [ isFetched, medicalProfile ]);

  return (
    <ContentCard
      className={classes.root}
      autoHeightMax={800}
      title="Family Tree (Family Unit)"
      icon={
        <Fab
          size="small"
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={openWidgetModal}
        >
          Add
        </Fab>
      }
    >
      <Loader loading={!initialValues} render={
        () => (!!get(initialValues, 'family_tree') ?
          get(initialValues, 'family_tree').map((item, i) => (
            item.first_name &&
              <Row
                key={i}
                index={i}
                item={item}
                medicalProfile={medicalProfile}
                onUpdate={setInitialValues}
              />
          ))
          :
          <Typography align="center">No info found</Typography>
        )}
      />
    </ContentCard>
  );
};
