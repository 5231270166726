import * as codesApi from '../../../../api/codes/procedures';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';

export const transformModifiersCodesToOption = (modifier) => modifier && ({
  value: modifier.id,
  label: modifier.code + ' - ' + modifier.description,
  data: modifier
});

const fetchModifiersCodes = fetchDataForAsyncSelect((code, { page }) => {
  return codesApi.fetchModifierCodes({ params: { code, page, is_active: 1 } });
}, {
  transformDataToOption: transformModifiersCodesToOption
});

export const ModifiersSelect = (props) => {
  return (
    <Select
      isAsync
      formattedValue
      promiseWithOptions={fetchModifiersCodes}

      {...props}
    />
  );
};
