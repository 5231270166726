import { useContext } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { Page, PageBody } from '../../../../components/Page';
import { UsersContextProvider } from '../UsersContext';
import { List, FiltersBar, ActionsBar } from './List';
import { Grid } from './Grid';

export const Users = () => {
  const { viewVariant } = useContext(LayoutContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('md');

  return (
    <UsersContextProvider>
      <Box display="flex" flexDirection="column" height="100%" p={isMobile ? 0 : 2}>
        <Page>
          <ActionsBar filterOpenButton={filterOpenButton} />

          <PageBody fullHeight disablePaddings disableScrollbars>
            <Box height="100%" display="flex" flexDirection="column">
              <ScrollWrapper {...scrollWrapperProps}>
                <FilterBarWrapper { ...filterBarWrapperProps}>
                  <FiltersBar filterKey={filtersKeysMap.users_main} />
                </FilterBarWrapper>
              </ScrollWrapper>

              <Box flexGrow={1}>
                {!isTablet ? (
                  (viewVariant === viewVariantsMap.list)
                    ? <List />
                    : (viewVariant === viewVariantsMap.grid) ? <Grid /> : null
                ) : (
                  <Grid />
                )}
              </Box>
            </Box>
          </PageBody>
        </Page>
      </Box>
    </UsersContextProvider>
  );
};
