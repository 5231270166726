import { caseInsuranceMap } from '../../../../dataMaps';
import { Select } from '../Select';
import { SingleValue } from './SingleValue';
import { Option } from './Option';

const components = {
  SingleValue,
  Option
};

export const ClaimInsuranceTypeSelect = (props) => {
  return (
    <Select
      isClearable
      formattedValue
      label="Claims insurance info"
      options={caseInsuranceMap}
      components={components}
      {...props}
    />
  );
};
