export const styles = ({
  spacing,
  palette: { grey, primary },
  typography: { pxToRem, fontWeightMedium },
  shape: { borderRadius },
  breakpoints
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    paddingTop: spacing(1.8),
    paddingBottom: spacing(1.8),
    flexGrow: 1,

    [breakpoints.down('md')]: {
      flexWrap: 'wrap',
      padding: spacing(1)
    }
  },

  root_edit: {
    paddingTop: spacing(),
    paddingBottom: spacing()
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(),
    color: grey[600],

    '& svg': {
      fontSize: pxToRem(30)
    }
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: fontWeightMedium,
    color: grey[600],
    fontSize: pxToRem(21),
    flexGrow: 1
  },

  case: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1
  },

  addCaseButton: {
    textTransform: 'capitalize'
  },

  addCaseButton__icon: {
    fontSize: pxToRem(16)
  },

  case__title: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: primary.contrastText,
    backgroundColor: '#48D2A0',
    borderRadius,
    paddingLeft: spacing(),
    paddingRight: spacing(),
    marginRight: spacing(2),
    marginLeft: spacing(-1.5),
    fontSize: pxToRem(14)
  },

  icons: {
    display: 'flex',
    alignItems: 'center'
  },

  listIcon: {
    marginRight: spacing(1)
  },

  caseLink: {
    cursor: 'pointer'
  }
});
