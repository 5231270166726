import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { FileRowMobile } from '../../FilesList/FileRowMobile';
import { FileRow } from './FileRow';

export const Row = ({ item: file = {}, isLoaded, recalculateHeight = () => {}, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      recalculateHeight?.();
    }, 0);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [ isLoaded, file.tags, recalculateHeight ]);

  return (
    isMobile ?
      <FileRowMobile isLoaded={isLoaded} file={file} {...props} /> :
      <FileRow isLoaded={isLoaded} file={file} {...props} />
  );
};
