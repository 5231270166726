import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import { AuthorizationsPage } from './MedicalInfo/AuthorizationsPage';
import { clientRoutes } from './clientRoutes';
import { emailsRoutes } from './routes';

export const patientRoutes = [
  ...clientRoutes,

  {
    path: '/profile/authorizations',
    icon: <LocalHospitalOutlinedIcon />,
    label: 'Authorizations',
    component: AuthorizationsPage
  },

  ...emailsRoutes
];
