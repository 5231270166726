export const styles = ({ spacing }) => ({
  root: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },

  root_widget: {
    padding: 0
  }
});
