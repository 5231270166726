import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { NestedList } from '../../../../../../../components/NestedList';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { BodyParts } from '../BodyParts';
import { CheckboxesComponent } from '../CheckboxesComponent';
import { factorsMap, factorsTitlesMap } from '../factorsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CurrentSymptomsInjury = ({ isOpenSwitches, form }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [ isOpen, setIsOpen ] = useState(false);
  const isPIForm = !!form?.forms?.current_symptoms_pi;
  const symptoms = isPIForm ? form?.forms?.current_symptoms_pi : form?.forms?.current_symptoms;
  const factors = !!symptoms?.following_factors_your_pain
    ? Object.keys(symptoms?.following_factors_your_pain)
    : [];

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(symptoms) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Current Symptoms"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box p={isSmallScreen ? 1 : 3}>
        {symptoms?.where_is_pain && (
          <div className={classes.content}>
            <Typography className={classes.formContent}>Where is your pain?</Typography>

            <BodyParts content={symptoms || {}}/>
          </div>
        )}

        {isPIForm && (
          <div className={classes.content}>
            <CheckboxesComponent
              type={form.form_type}
              symptoms={symptoms || {}}
            />
          </div>
        )}

        {symptoms?.describe_circumstances_pain && (
          <div className={classes.content}>
            <Typography className={classes.formContent}>
              Describe the circumstances around the onset of your pain:
            </Typography>

            <Typography color="textSecondary">
              {symptoms?.describe_circumstances_pain}
            </Typography>
          </div>
        )}

        {symptoms?.how_often_your_pain && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              How often is your pain?
            </Typography>

            <ReadonlyCheckbox
              title={symptoms?.how_often_your_pain}
              checked={!!symptoms?.how_often_your_pain}
              className={classes.contentInfoTitle}
            />
          </div>
        )}

        {symptoms?.have_you_avoided_strenuous_lifting && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Have you avoided strenuous lifting, carrying, pushing, pulling, stooping,
              and  bending because of your injury?
            </Typography>

            <ReadonlyCheckbox checked title={symptoms?.have_you_avoided_strenuous_lifting > 0 ? 'Yes' : 'No'} />
          </div>
        )}

        {!!factors?.length && (
          <div className={classes.content}>
            <Typography className={classes.formContent}>
              How do the following factors affect your pain?
            </Typography>

            <div className={classes.contentGroup}>
              {factors.map((item, i) => (
                <div key={i} className={classes.contentGroupInfo}>
                  <Typography className={classes.contentGroupInfoTitle}>
                    {factorsTitlesMap[item]}
                  </Typography>

                  <ReadonlyCheckbox
                    checked
                    title={factorsMap[symptoms?.following_factors_your_pain[item]]}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </Box>
    </NestedList>
  );
};
