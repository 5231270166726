import React from 'react';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';

export const ApptHistoryCardHeader = ({ appointment }) => {
  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item>
        <Typography variant="h3">
          {moment.unix(appointment.appointment_at).format('DD MMM, YYYY')}
        </Typography>

        <Typography color="textSecondary" variant="caption">
          Appt. Date
        </Typography>
      </Grid>

      <Grid item>
        <CurrencyFormat
          value={appointment?.billing_info?.total_balance}
          TypographyProps={{ variant: 'h3' }}
        />

        <Typography color="textSecondary" variant="caption">
          Balance
        </Typography>
      </Grid>
    </Grid>
  );
};
