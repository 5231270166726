// Cyclic dependencies, don't shorten path
import { orderDirectionsMap } from '../../../../../../../components/FormField/selects/OrderBy';
// Cyclic dependencies, don't shorten path
import { orderByOptions } from '../../../../../files-common/FilesFilterContext/orderByOptions';

export const initialState = {
  isFetched: false,
  isFetching: false,
  filter: {
    page: 1,
    per_page: 12,
    original_filename: null,
    order_by: orderByOptions[0].value,
    order_direction: orderDirectionsMap.desc
  },
  pagination: {
    total: 0,
    last_page: 1
  },
  files: []
};
