import { api } from '../api';

export const fetchPrinters = (config = {}) => {
  return api.get('/printers', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createPrinter = (config = {}) => {
  return api.post('/printers', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deletePrinter = (printerId) => {
  return api.delete(`/printers/${printerId}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updatePrinter = (data) => {
  return api.put(`/printers/${data.id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchFilesFromPrinter = (printerId, config) => {
  return api.get(`/printer-files/${printerId}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteFilesFromPrinter = (printerId, config) => {
  return api.delete(`/printer-files/${printerId}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const assignFilesToUser = (printerId, config) => {
  return api.post(`/printer-files/${printerId}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
