import NumberFormat from 'react-number-format';
import { Typography } from '@material-ui/core';

export const CurrencyFormat = ({ value, TypographyProps = {}, ...props }) => {
  return (
    <NumberFormat
      fixedDecimalScale
      thousandSeparator
      value={value || 0}
      decimalScale={2}
      displayType="text"
      prefix="$"
      renderText={(formattedValue) => (
        <Typography {...TypographyProps}>{formattedValue}</Typography>
      )}

      {...props}
    />
  );
};
