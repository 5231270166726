import { useEffect, useContext, useRef } from 'react';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../components/Loader';
import { Scrollbars } from '../../../../../../components/Scrollbars';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Sticky } from '../../../../../../components/Sticky';
import { styles } from '../../ArrivalStatuses/List/styles';
import { AppointmentBooksContext } from '../AppointmentBooksContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidthsLg = {
  name: 300,
  provider: 300,
  actions: 48
};

export const columnsWidthsSm = {
  name: 150,
  provider: 300,
  actions: 48
};

const useStyles = makeStyles(styles);

export const List = () => {
  const {
    appointmentBooks,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    resetAppointmentBooks
  } = useContext(AppointmentBooksContext);
  const scrollElementRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    resetAppointmentBooks();
  }, []);

  return (
    <Scrollbars viewRef={scrollElementRef}>
      <div className={classes.root}>
        <Loader loading={!isFetched} render={
          () => (!filter.total ?
            <Typography align="center">No appointment books found</Typography>
            :
            <div className={classes.list}>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={appointmentBooks}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </div>
          )}
        />
      </div>
    </Scrollbars>
  );
};
