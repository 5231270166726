import { useContext } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { useModal } from '../../../../../../components/ModalsProvider';
import { AccountsContext } from '../../../AccountsContext';
import { EmailsAccountSettingsModal } from './EmailsAccountSettingsModal';

export const Account = () => {
  const { openModal } = useModal();
  const accountsContext = useContext(AccountsContext);

  const openEmailSettings = () => {
    openModal(EmailsAccountSettingsModal, {
      payload: {
        onListUpdate: accountsContext.fetchAccounts
      }
    });
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} px={2}>
      <Typography variant="h3">Mailboxes</Typography>

      <IconButton edge="end" onClick={openEmailSettings}>
        <SettingsOutlinedIcon />
      </IconButton>
    </Box>
  );
};
