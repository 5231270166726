import { SvgIcon } from '@material-ui/core';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { Icon } from '@mdi/react';
import { mdiCircleMedium, mdiCogs } from '@mdi/js';
import HumanQueue from '../../../../../../components/icons/human-queue.svg';

export const appointments = {
  name: 'Appointments',
  icon: InsertInvitationOutlinedIcon,
  path: '/appointments',
  routes: [
    {
      exact: true,
      name: 'My Appointments',
      icon: InsertInvitationOutlinedIcon,
      path: '/appointments'
    },
    {
      name: 'Waiting List',
      icon: (props) => (
        <SvgIcon {...props}>
          <HumanQueue />
        </SvgIcon>
      ),
      path: '/appointments/waiting-list'
    },
    {
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiCogs} />
        </SvgIcon>
      ),
      name: 'System Settings',
      path: '/appointments/system-settings',
      routes: [
        {
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          name: 'Appt. Arrival Statuses',
          path: '/appointments/system-settings/arrival-statuses'
        },
        {
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          name: 'Appt. Books',
          path: '/appointments/system-settings/appointment-books'
        },
        {
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          name: 'Appt. Types',
          path: '/appointments/system-settings/appointment-types'
        },
        {
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          name: 'Appt. Visit Reasons',
          path: '/appointments/system-settings/visit-reasons'
        }
      ]
    }
  ]
};
