/* eslint-disable max-len */

import { useContext } from 'react';
import cn from 'classnames';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { BodyPartsContext } from '../../BodyPartsContext';
import { painLevelColors } from '../../painLevelColors';
import { bodyPartsMap } from '../bodyPartsMap';
import { bodyPartsTypesMap } from '../bodyPartsTypesMap';
import frontImage from './front.png';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const Front = ({ disableHover = false, onClick = () => () => {} }) => {
  const classes = useStyles();
  const { selectedPart, painfulParts } = useContext(BodyPartsContext);

  return (
    <div className={classes.root}>
      <svg
        width="280"
        height="663"
        viewBox="0 0 280 663"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect y="-0.000244141" width="280" height="663" fill="url(#frontBodyPattern)" />

        <path
          d="M140 5.5C121.5 5.5 112.5 21.5 112.5 32.5C113.5 32 116 32 117.5 32.5C119 28.5 123 20.5 140 20.5C157.5 20.5 162.167 28.1667 163 32.5C165 32 165.5 32 167.5 32.5C168 26 163 5.5 140 5.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.head },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.head, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.head)}
        >
          <title>{bodyPartsMap.head}</title>
        </path>

        <path
          d="M117.5 32.5C121 33.5 122.5 36.5 123 38C127.5 36 132.334 38.3334 134 39.5C134 39.5 137 41.5 137 44C137.187 43.4398 137.5 42 137.5 41.5C138.356 40.2515 140 39.5 140 39.5C140.421 39.7105 142.025 40.5801 142.5 41.5C142.711 41.9095 142.5 43 143 44C143 42.5 144 41 145.5 40C150.5 36 155 37 157 38C158 35 160 33 163 32.5C162.5 30 159.5 20.5 140 20.5C120.5 20.5 118.5 31 117.5 32.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.forehead },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.forehead, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.forehead)}
        >
          <title>{bodyPartsMap.forehead}</title>
        </path>

        <path
          d="M113 43L113.5 46C117 46 120 49 121 49.5C120.571 48.641 119.5 47 119 45C118.607 42.5828 119.577 39.9968 123 38C121 32 115.167 31.8334 112.5 32.5V36L113 43Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.temple_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.temple_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.temple_l)}
        >
          <title>{bodyPartsMap.temple_l}</title>
        </path>

        <path
          d="M166.999 43L166.499 46C162.999 46 159.999 49 158.999 49.5C159.429 48.641 160.499 47 160.999 45C161.392 42.5828 160.422 39.9968 156.999 38C158.999 32 164.832 31.8334 167.499 32.5V36L166.999 43Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.temple_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.temple_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.temple_r)}
        >
          <title>{bodyPartsMap.temple_r}</title>
        </path>

        <path
          d="M123 50.5L121 49.5C119.5 48.5 116.5 46 113.5 46C113.5 52 116 60.5 116.5 61.5C117.5 67 119.5 72.5 121.5 75C123.833 77.3333 129.5 81.4 133.5 79C138.5 81 141.5 81 146.5 79C151.5 82 157 76 158.499 75C159.5 73.5 162 69.5 162.5 67.5C163 65 163.079 63.8133 163.499 61.5C163.999 60.5 166.499 52 166.499 46C163.499 46 160.499 48.5 158.999 49.5L156.999 50.5C157.999 50.5 159 50.5 160.499 52.5C162.5 59 157.5 66 152 72C150.568 73.9093 148 76.5 140.5 77C130.5 76.5 129.166 73 127.5 71.5C124.5 68 117 60 119.5 52.5C120.5 50.5 122 50.5 123 50.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.jaw },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.jaw, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.jaw)}
        >
          <title>{bodyPartsMap.jaw}</title>
        </path>

        <path
          d="M127.999 72C124 68 122.999 66 122.999 66C120.957 63.6652 118.955 58.6669 119 55C119.032 52.3848 120.085 50.5 122.999 50.5C127.849 50.8731 134.499 49.5 136.499 46C135.999 49 132.999 59 133.999 59.5C135.499 60.5 137.499 60.5 139.499 61C128.5 61.5 125.226 68.1178 127.999 72Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.cheek_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.cheek_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.cheek_l)}
        >
          <title>{bodyPartsMap.cheek_l}</title>
        </path>

        <path
          d="M152 72C154.5 69.5 156.999 66 156.999 66C159 63.5 161 59 160.999 55C161 52.5 159.718 50.2909 157 50.5C152.149 50.8731 145 49.5 143 45.5C143.5 47.5 146 56 146 59.5C144.5 60.5 142.5 60.5 140.5 61C151.499 61.5 154.773 68.1178 152 72Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.cheek_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.cheek_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.cheek_r)}
        >
          <title>{bodyPartsMap.cheek_r}</title>
        </path>

        <path
          d="M137.5 41.5C137.499 44 132.756 58.671 134 59.5C135.501 60.5 139 61 140 61C142 61 145.5 60.5 146 59.5C146 56.5 144.249 50.6173 143 45.5C142.512 43.5016 142.641 41.9213 142.5 41.5C142.001 40.5 140 39.5 140 39.5C140 39.5 137.999 40.5 137.5 41.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.nose },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.nose, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.nose)}
        >
          <title>{bodyPartsMap.nose}</title>
        </path>

        <path
          d="M122.999 38C128.599 35.2 136.999 40 136.999 44C136.999 49.5 127 51 122.999 50.5C122.344 50.2378 121.55 50.0935 121 49.5C119.5 47 118.631 44.8445 118.999 43C119.282 40.8876 121 39 122.999 38Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.eye_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.eye_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.eye_l)}
        >
          <title>{bodyPartsMap.eye_l}</title>
        </path>

        <path
          d="M157 38C151.4 35.2 143 40 143 44C143 49.5 152.999 51 157 50.5C158 50 158 50 158.999 49.5C160.499 47 161.368 44.8445 161 43C160.717 40.8876 158.999 39 157 38Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.eye_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.eye_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.eye_r)}
        >
          <title>{bodyPartsMap.eye_r}</title>
        </path>

        <path
          d="M108 49.4999C108.5 42.9999 112 42.5 113.5 44.5C113.5 45.8333 113.671 49.0272 114 51C114.5 54.0001 115.5 59 116.5 61.5C116.5 61.5 115.5 63 114 63C112.5 63 108 56.4999 108 49.4999Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.ear_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.ear_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.ear_l)}
        >
          <title>{bodyPartsMap.ear_l}</title>
        </path>

        <path
          d="M173 49C173 42.4364 169.5 41.5 167 44.0377C166.5 45 166.329 49.5272 166 51.5C165.5 54.5001 164.5 59 163.5 61.5C163.5 61.5 165 62.5377 166.5 62.5377C168 62.5377 173 56 173 49Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.ear_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.ear_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.ear_r)}
        >
          <title>{bodyPartsMap.ear_r}</title>
        </path>

        <path
          d="M140 285C135.528 285 130 282 122.5 278C123 284.5 134 319.5 130.5 328C132.5 330 135.5 332.5 138 334C139 333 141 332 142.5 334C146 331.5 148 329.5 149.5 328C146 321.5 156 287.5 157.5 278C153.5 280.5 144 285 140 285Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.genitals },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.genitals, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.genitals)}
        >
          <title>{bodyPartsMap.genitals}</title>
        </path>

        <path
          d="M100 624C100.5 620.5 102.167 609 103 603C105.5 602 114 608.5 119.5 613.5C119.5 618.5 121 625 122.5 627.5C120.5 630 124.5 648 116.5 652.5C113 663.5 104.5 662 102.5 660C101 661 98.5001 660.5 97.5001 660C96.0001 660 94.0001 659 94.0001 658C93.0001 658 92 657.5 91.5 656.5C90 656.5 89.5002 655.5 89.5002 654C89 647 91.0002 644.5 91.0002 642.5C92.0001 639.5 99.5001 630 100 624Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.foot_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.foot_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.foot_l)}
        >
          <title>{bodyPartsMap.foot_l}</title>
        </path>

        <path
          d="M180.106 624C179.606 620.5 178 607.5 177.5 603C175 602 166.106 608.5 160.606 613.5C160.606 618.5 159.106 625 157.606 627.5C159.607 630 155.5 646.5 163.607 652.5C167.5 663 175.5 663 177.607 660C179 661.5 181.606 660.5 182.606 660C184.106 660 186 658.5 186 657.5C187 657.5 188 657 188.5 655.5C189.5 655.5 190.5 653.5 190.5 652C190.5 644.5 188.5 644 188.5 642C187.5 639 180.821 629 180.106 624Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.foot_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.foot_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.foot_r)}
        >
          <title>{bodyPartsMap.foot_r}</title>
        </path>

        <path
          d="M133 614C134 607.5 127.5 603 126.5 600.5C120 603.5 120.167 609.167 119.5 613.5C119.5 618 120 621.5 122.5 627.5C129.5 622.5 132.5 621.5 133 614Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.heel_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.heel_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.heel_l)}
        >
          <title>{bodyPartsMap.heel_l}</title>
        </path>

        <path
          d="M147.106 614C146.106 607.5 152.606 603 153.606 600.5C160.106 603.5 159.94 609.167 160.606 613.5C160.606 618 160.106 621.5 157.606 627.5C150.606 622.5 147.606 621.5 147.106 614Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.heel_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.heel_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.heel_r)}
        >
          <title>{bodyPartsMap.heel_r}</title>
        </path>

        <path
          d="M103 603V587.5C104.833 584.833 107.5 579.5 111 579.5C113.5 579.5 115 584.5 119.5 587C121.709 588.227 123 587 124 586.5C124 587.5 125 589 125.5 592.5C126 595 126.5 596.5 126.5 600.5C123.5 602 120 604 119.5 613.5C116.3 610.3 105.167 601.667 103 603Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.ankle_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.ankle_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.ankle_l)}
        >
          <title>{bodyPartsMap.ankle_l}</title>
        </path>

        <path
          d="M177.5 603V587.5C175.667 584.833 172.606 579.5 169.106 579.5C166.606 579.5 165.106 584.5 160.606 587C158.397 588.227 157.106 587 156.106 586.5C156.106 587.5 155.106 589 154.606 592.5C154.106 595 153.606 596.5 153.606 600.5C156.606 602 160.106 604 160.606 613.5C163.806 610.3 175.333 601.667 177.5 603Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.ankle_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.ankle_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.ankle_r)}
        >
          <title>{bodyPartsMap.ankle_r}</title>
        </path>

        <path
          d="M113.5 482.5C101 479 95.4997 466.5 92.4998 459C92 475.5 90.0418 508.713 90.4994 511C90.9996 513.5 100 562.5 103 587.5C103 587.5 108 578.5 111.5 579.5C114.5 580.5 118.5 590.5 124 586.5C123.704 580.579 125.846 557.651 128.5 539C130.328 526.153 132.929 517.5 133.5 513.5C134.5 506.5 128.5 481.5 127.5 467.5C125.5 474.5 120.5 479 113.5 482.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.shin_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.shin_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.shin_l)}
        >
          <title>{bodyPartsMap.shin_l}</title>
        </path>

        <path
          d="M166.607 482.5C179.107 479 185.001 466 188.001 458.5C188.501 475 191 503.5 189.607 511C189.607 518.5 179 562.5 177.5 587.5C177.5 587.5 172.107 578.5 168.607 579.5C165.607 580.5 161.607 590.5 156.107 586.5C156.403 580.579 154.261 557.651 151.607 539C149.779 526.153 147.572 518.5 147.001 514.5C145.501 504 151.607 481.5 152.607 467.5C154.607 474.5 159.607 479 166.607 482.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.shin_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.shin_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.shin_r)}
        >
          <title>{bodyPartsMap.shin_r}</title>
        </path>

        <path
          d="M140.5 213C112.5 213 111.178 228 108.5 235.5C107.5 239 107.5 264 109.499 269C113.999 273.5 132.999 285 139.499 285C148.02 285 167 273 170.5 269C172.5 260 172.5 240.5 171.5 235.5C168.5 225 166 213.5 140.5 213Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.belly },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.belly, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.belly)}
        >
          <title>{bodyPartsMap.belly}</title>
        </path>

        <path
          d="M91.0003 232C91.0003 227 90.5006 218.5 89.5006 213.5C100.001 218 106 220.5 114.5 222.5C112 224 107 235.5 108 244.5C107.5 251 108.5 267.5 109.5 269C109.5 269.5 110.123 273.618 110.5 275C102 276.5 94 266.5 89.0002 265C89.0002 263.523 88.5007 257 89.0006 250C90.0006 242.5 91.254 235.806 91.0003 232Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.waist_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.waist_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.waist_l)}
        >
          <title>{bodyPartsMap.waist_l}</title>
        </path>

        <path
          d="M189 232C189 227 189.5 218.5 190.5 213.5C180 218 174.278 220.5 165.778 222.5C167.5 223.5 172.278 232.5 172.278 244.5C172.278 251.5 171.5 267.5 170.5 269C170.5 269.5 169.877 273.618 169.5 275C178 276.5 186 266.5 191 265C191 263.523 191.778 257 191.278 250C190.278 242.5 188.746 235.806 189 232Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.waist_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.waist_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.waist_r)}
        >
          <title>{bodyPartsMap.waist_r}</title>
        </path>

        <path
          d="M103 282.5C102.283 280.35 99 272.5 97.5 270.5C101.5 273 105 275.5 110.5 275C110 273 109.5 270 109.5 269C112 271.5 118 275.5 122.5 278C123 284.5 134 319 130.5 328C126.5 324.5 123.5 319.5 121.5 316C118 297 105 288 103 282.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.groin_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.groin_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.groin_l)}
        >
          <title>{bodyPartsMap.groin_l}</title>
        </path>

        <path
          d="M177 282.5C177.717 280.35 181 272.5 182.5 270.5C178.5 273 175 275.5 169.5 275C170 273 170.5 270 170.5 269C168 271.5 162 275.5 157.5 278C157 284.5 146 319 149.5 328C153.5 324.5 156.5 319.5 158.5 316C162 297 175 288 177 282.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.groin_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.groin_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.groin_r)}
        >
          <title>{bodyPartsMap.groin_r}</title>
        </path>

        <path
          d="M112 114C108 116 105 121.5 105 126C108 126 126 123.5 130.5 125.5C136.125 128 135.5 162 134.275 166.5C133.549 169.5 123.5 175.5 119 175.5C105.5 174.5 90.0001 170.5 86.5001 169C83.5 166.5 82.5 160.5 81.5001 155.5C81.4997 163 81.5006 183.5 81.5001 193C82 196 85.5 204.5 89.5 210V213.5C98 217 103.5 219.5 114 222.5C117.5 219.5 120.5 213.5 140 213C150.35 213 162 215.5 166 222.5C176 220 183 216.5 190.5 213.5C190.5 212 191 210.5 191.5 209C195 204.5 198 197 199 193V157.5C198.333 160.5 196.5 166.5 194 169C190.5 171.5 162.999 175.889 159.5 175.5C156 175.111 148 171 145 166.5C144.5 164.5 143 131 148.5 126C155 122.5 167 125 176 126C175 122 175 118 169 114C156 113.5 144.5 119.5 140 119.5C135.5 119.5 122.5 113 112 114Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.chest },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.chest, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.chest)}
        >
          <title>{bodyPartsMap.chest}</title>
        </path>

        <path
          d="M8.9996 317.5C9.7996 316.3 17.4996 307.5 21.4996 304.5C28 303 31.4996 306 33.5 307.5C33.1667 308.167 32.2996 310.3 31.4996 311.5C30 313 27.5 318.5 23.9996 320C19.5 321 18.5 322.095 17.4996 323C16.5 324.5 15.5 329 20.5 332.5C23 336 32 336 37.5 336C43 336.122 44 331.5 45 329.5C44.5 334 42.4996 337 40.9996 340C38.5 344 35.5 359 31.9996 358.5C30 358.5 31 355.5 30.5 354.5C30.0597 355.5 29 366 25.9996 366C22.5 366 23.5 361 23.5 360C23.1377 360.5 22.5 368.5 18.5 368C15.5 368 17.5 361 16.5 357C16 357.5 15 362 12.5 362C11.0016 362 11.0004 359.006 11 358.003V358C10.9996 356.882 11.9996 334.5 11.9996 332C11.9996 329.5 12.5004 325 14 323.5C12.5 323.5 11.9996 326 7.5 329.5C4.5 330.5 3 328 3 327.5C3 326.5 6 325 6.4996 322C6.9996 320 7.9996 319 8.9996 317.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.finger_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.finger_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.finger_l)}
        >
          <title>{bodyPartsMap.finger_l}</title>
        </path>

        <path
          d="M271.5 317.5C269.5 316 264.5 308 259.5 304.5C253.001 303 249.002 305.999 247.001 307.499L247 307.5C247.333 308.167 248.2 310.3 249 311.5C250.5 313 253 318.5 256.5 320C261 321 262 322.095 263 323C264 324.5 265 329 260 332.5C257.5 336 248.5 336 243 336C237.5 336.122 236.5 331.5 235 329.5C235.5 335.5 238 337 239.5 340C243.5 345.5 243.892 358.015 247.5 357.5C250 357.5 249 353.5 249.5 352.5C250.5 357 252 366 255 366C258.5 366 257 359 257 358C257.362 358.5 258 369 262 368.5C265 368.5 263.688 360.5 264 357C264.5 360 265.5 362 268 362C269.498 362 269.5 359.006 269.5 358.003V358C270 352 268.5 336.5 268 329.5C268 327 268.137 325.137 266.5 323.5C268.5 325 269.5 327.5 274 330C277 331 278.5 329.5 278.5 328C278.5 326.5 275 325 274.5 322C274.5 319.5 272.5 319 271.5 317.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.finger_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.finger_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.finger_r)}
        >
          <title>{bodyPartsMap.finger_r}</title>
        </path>

        <path
          d="M57 216.5C53.4996 210 47.9996 207.5 43.5 207C40.4996 212.5 36.4996 228.5 35.9996 232C35.4996 235.5 33 258 33 261C33 264 29.5 287 28 294C31.5 293 35 296 36 297L50 287.5C53.5 280.5 62 265.5 65 258C70 250.5 72.1667 238.166 73 232.5L49.5 240C52.5 231.5 54.5 224 57 216.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.forearm_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.forearm_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.forearm_l)}
        >
          <title>{bodyPartsMap.forearm_l}</title>
        </path>

        <path
          d="M223.5 216.5C227 210 232.5 207.5 237 207C240 212.5 244 228.5 244.5 232C245 235.5 247.5 258 247.5 261C247.5 264 251 287 252.5 294C249 293 245.5 296 244.5 297L230.5 287.5C227 280.5 218.5 265.5 215.5 258C210.5 250.5 208.333 238.166 207.5 232.5L231 240C228 231.5 226 224 223.5 216.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.forearm_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.forearm_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.forearm_r)}
        >
          <title>{bodyPartsMap.forearm_r}</title>
        </path>

        <path
          d="M66.5 209L74 219C74 223.5 73.5 229.5 73 232.5C71.5 233 52.5 239 49.5 240C51 236 56.5 217.5 57 216.5C58 215.639 66.5 209 66.5 209Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.elbow_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.elbow_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.elbow_l)}
        >
          <title>{bodyPartsMap.elbow_l}</title>
        </path>

        <path
          d="M214 209L206.5 219C206.5 223.5 207 229.5 207.5 232.5C209 233 228 239 231 240C229.5 236 224 217.5 223.5 216.5C222.5 215.639 214 209 214 209Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.elbow_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.elbow_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.elbow_r)}
        >
          <title>{bodyPartsMap.elbow_r}</title>
        </path>

        <path
          d="M75.0007 148C78.1895 144 80.0003 142 82.5003 139C82.0003 140.666 81.0003 144.6 81.0003 145V151C81.0003 152.5 81.5002 158.5 81.5003 168.5C77 166 71.5 155 75.0007 148Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.armpit_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.armpit_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.armpit_l)}
        >
          <title>{bodyPartsMap.armpit_l}</title>
        </path>

        <path
          d="M205.499 148C202.311 144 200.5 142 198 139C198.5 140.666 199.5 144.6 199.5 145V151C199.5 152.5 199 158.5 199 168.5C203.5 166 209 155 205.499 148Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.armpit_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.armpit_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.armpit_r)}
        >
          <title>{bodyPartsMap.armpit_r}</title>
        </path>

        <path
          d="M182 104.5C182 104.5 171.5 111 169 114C173.5 117 175 120 176 126C179 127 188.062 128 196.5 137C204.938 146 211 159 226.5 161C229 130.5 216.955 119 212.5 115.5C209.629 113.586 203.985 111.304 198 109.5C191.477 107.533 184.608 106.064 182 104.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.shoulder_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.shoulder_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.shoulder_r)}
        >
          <title>{bodyPartsMap.shoulder_r}</title>
        </path>

        <path
          d="M98.8379 104.5C98.8379 104.5 109.338 111 111.838 114C107.338 117 105.838 120 104.838 126C101.838 127 92.7754 128 84.3379 137C75.5 146 69.5 159 54 161C51.5 130.5 63.8833 119 68.3379 115.5C71.2089 113.586 76.8526 111.304 82.8379 109.5C89.3611 107.533 96.2304 106.064 98.8379 104.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.shoulder_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.shoulder_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.shoulder_l)}
        >
          <title>{bodyPartsMap.shoulder_l}</title>
        </path>

        <path
          d="M46.5 184.5C46.1 186.5 44.3333 200.833 43.5 207C51.1 207.8 55.6667 213.666 57 216.5L66.5 209L74 219C74.6667 214.5 76.5 205.5 77 203.5C77.5 201.5 79.5 196.5 80 194C80.5 191.5 81.5 171 81.5 168.5C79 167.5 71 157 75 148C65.5 159.5 57.5 160.5 54 161C54 161.5 50 169.5 49.5 171.5C49.2288 172.584 47 182 46.5 184.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.arm_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.arm_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.arm_l)}
        >
          <title>{bodyPartsMap.arm_l}</title>
        </path>

        <path
          d="M234 184.5C234.5 191 235.5 201.5 237 207C229.4 207.8 224.833 213.666 223.5 216.5L214 209L206.5 219C205.833 214.5 204 205.5 203.5 203.5C203 201.5 201 196.5 200.5 194C200 191.5 199 171 199 168.5C201.5 167.5 209.5 157 205.5 148C215 159.5 223 160.5 226.5 161C226.5 161.5 230.5 169.5 231 171.5C231.271 172.584 233 177.5 234 184.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.arm_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.arm_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.arm_r)}
        >
          <title>{bodyPartsMap.arm_r}</title>
        </path>

        <path
          d="M25.5 300.5C26.1325 299.235 27.5 296.167 28 294C31.5 293 35 296 36 297L50 287.5C48.6667 291.167 45.3834 298.699 45 301C44.5 304 44.5 305 44.5 306.5C44.5 308.5 49 315.5 45 329.5C34 320 35 311.5 33.5 307.5C30 304.5 26 303.5 21.5 304.5C22.5 303.5 25 301.5 25.5 300.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.wrist_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.wrist_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.wrist_l)}
        >
          <title>{bodyPartsMap.wrist_l}</title>
        </path>

        <path
          d="M255 301C254.368 299.735 253 296.167 252.5 294C249 293 245.5 296 244.5 297L230.5 287.5C231.833 291.167 235.117 298.699 235.5 301C236 304 236 305 236 306.5C236 308.5 231 315.5 235 329.5C247 319 245.5 311.5 247 307.5C250.5 304.5 255 303.5 259.5 304.5C258.5 303.5 255.5 302 255 301Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.wrist_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.wrist_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.wrist_r)}
        >
          <title>{bodyPartsMap.wrist_r}</title>
        </path>

        <path
          d="M31.5 311.5C32 311 33 308.5 33.4993 307.5C34 309 34.5 313.5 36.5 318.5C38 321.5 40 325 44.9995 329.5C44.5 330.5 43.5 333 43 333.5C41 336.5 37.5 336 35.4995 336C31.4995 336 22.9997 336 20.4997 332.5C14.765 328.329 16.9993 324 17.4993 323C19 321.5 21 320.5 24 320C27 319 30.5 312.5 31.5 311.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.palm_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.palm_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.palm_l)}
        >
          <title>{bodyPartsMap.palm_l}</title>
        </path>

        <path
          d="M249 311.5C248.5 311 247.5 308.5 247.001 307.5C246.5 309 246 313.5 244 318.5C242.5 321.5 240 325 235 329.5C235.5 330.5 237 333 237.5 333.5C239.5 336.5 243 336 245 336C249 336 257.5 336 260 332.5C265.735 328.329 263.501 324 263.001 323C261.5 321.5 259.5 320.5 256.5 320C253.5 319 250 312.5 249 311.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.palm_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.palm_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.palm_r)}
        >
          <title>{bodyPartsMap.palm_r}</title>
        </path>

        <path
          d="M140 91C146.5 91 154.5 81.9996 158.5 74.9996C155.831 77.0335 151.5 81.5 146.5 79C144 80 142.373 80.5 140 80.5C137.668 80.6296 135 79.5 133.5 79C129 81.5 123.599 76.9753 121.5 74.9996C121.5 75.9996 133 91.0004 140 91Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.chin },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.chin, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.chin)}
        >
          <title>{bodyPartsMap.chin}</title>
        </path>

        <path
          d="M119 91.4996L118.5 68.9996C121.5 78 134 91 140 91C148 91 157.5 78 162 68.9996L161 92.4996C157.5 101.5 149.5 119 140 119.5C130 119.5 121.667 101 119 91.4996Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.neck },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.neck, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.neck)}
        >
          <title>{bodyPartsMap.neck}</title>
        </path>

        <path
          d="M119 91.4998C116 96.4998 105 102.5 99 104.5C103 107 109.5 111.5 112 114C123 114 123.5 115.5 135.5 118.5C124.5 111.5 120.5 95.4998 119 91.4998Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.clavicle_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.clavicle_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.clavicle_l)}
        >
          <title>{bodyPartsMap.clavicle_l}</title>
        </path>

        <path
          d="M161 91.9998C165 96.4998 175 102.5 182 104.5C178 107 171.5 111.5 169 114C158 114 156 115.5 144 118.5C154 112.5 159 96.4998 161 91.9998Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.clavicle_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.clavicle_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.clavicle_r)}
        >
          <title>{bodyPartsMap.clavicle_r}</title>
        </path>

        <path
          d="M105 126C93.4998 128 85.9998 135 82.4998 139C79.4998 152.5 83 166.375 86.4997 169C88.4997 170.5 114.5 176 119.5 175.5C124.5 175 133.9 169 134.5 166C135.5 162.5 135.499 129 131 126C127.5 123.5 117 125 105 126Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.breast_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.breast_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.breast_l)}
        >
          <title>{bodyPartsMap.breast_l}</title>
        </path>

        <path
          d="M174.602 126C186.102 128 192.5 132 197.999 139C200.999 152.5 197.999 164.5 193.999 169C191.999 170.5 165.102 176 160.102 175.5C155.102 175 145.702 169 145.102 166C144.102 162.5 143.5 129.401 148.602 126C154 122.417 170 125.5 174.602 126Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.breast_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.breast_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.breast_r)}
        >
          <title>{bodyPartsMap.breast_r}</title>
        </path>

        <path
          d="M92 452C92 448.4 91.3333 447.167 90.5 443.5C96 444 102 437.5 103 434L108 438C108 438 111 440 113.5 441C119.5 443 128.5 442.167 132 442L129.5 457C128.5 460.5 128.5 464.5 127 468.5C124.938 474 123 477 113.5 482.5C106.5 480.5 99 475 92.5 459C92.5 458.167 92 455.5 92 452Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.knee_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.knee_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.knee_l)}
        >
          <title>{bodyPartsMap.knee_l}</title>
        </path>

        <path
          d="M188.5 452C189 448.5 189 447 189.606 443.5C184.106 444 178.5 437.5 177.5 434L172.106 438C172.106 438 169.106 440 166.606 441C160.606 443 152 442.167 148.5 442L150.606 457C151 460.5 151 463.5 152.5 467.5C154.562 473 157 477.5 166.606 482.5C174 480.5 182.5 473.5 188 458.5C188.5 456 188.5 454 188.5 452Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.knee_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.knee_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.knee_r)}
        >
          <title>{bodyPartsMap.knee_r}</title>
        </path>

        <path
          d="M76.4992 384.5C71.4997 361 75 333.333 79 316C89 322 104.001 323.5 109.5 335.5C116 346 131.5 352 138 355.5C140 370 135.499 392.5 134.499 397C134.499 402.5 133.499 422.5 131.999 442C123 442.5 118.5 442.5 113.5 441C109 439 106 436.5 103 434C99.9997 441.5 92.4997 444 90.4997 443.5C89.4997 429 82.9997 422.5 80.9997 414.5C79.9997 409.5 77.9995 398 76.4992 384.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.leg_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.leg_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.leg_l)}
        >
          <title>{bodyPartsMap.leg_l}</title>
        </path>

        <path
          d="M204.037 384.5C209.036 361 205.536 333.333 201.536 316C191.536 322 176.536 323.5 171.036 335.5C164.536 346 149.036 352 142.536 355.5C140.536 370 145.037 392.5 146.037 397C146.037 402.5 147.037 422.5 148.537 442C157.536 442.5 162.036 442.5 167.036 441C171.536 439 174.536 436.5 177.536 434C180.536 441.5 188.036 444 190.036 443.5C191.036 429 197.536 422.5 199.536 414.5C200.536 409.5 202.537 398 204.037 384.5Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.leg_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.leg_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.leg_r)}
        >
          <title>{bodyPartsMap.leg_r}</title>
        </path>

        <g>
          <path
            d="M138 334.5C133 330 127 326.5 121.5 316C119.001 301.838 111.816 294.639 104.5 285C102.704 282.904 100.728 275.365 97.5 270.5C96 269.5 92 266 89 265C86.5 268 85 288.5 85 291C85 293 80.5 308 79 316C90 322.5 103.5 324 109 334.5C112.2 342.9 129.833 352 138 355.5V334.5Z"
            className={
              cn(
                classes.bodyPart,
                { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.hip_l },
                { [classes.bodyPart_hover]: !disableHover }
              )
            }
            fill="currentColor"
            style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.hip_l, 'level' ])] || null }}
            onClick={onClick(bodyPartsTypesMap.hip_l)}
          >
            <title>{bodyPartsMap.hip_l}</title>
          </path>
        </g>

        <g>
          <path
            d="M142.5 334.5C145 332 153 326.5 158.5 316C160.999 301.838 168.184 294.639 175.5 285C177.296 282.904 179.272 275.365 182.5 270.5C184 269.5 188 266 191 265C193.5 268 195.5 288.5 195.5 291C195.5 293 200 308 201.5 316C189.5 322.5 177 324 171.5 334.5C168.3 342.9 150.5 352 142 355.5C142 355.5 143.5 337.5 142.5 334.5Z"
            className={
              cn(
                classes.bodyPart,
                { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.hip_r },
                { [classes.bodyPart_hover]: !disableHover }
              )
            }
            fill="currentColor"
            style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.hip_r, 'level' ])] || null }}
            onClick={onClick(bodyPartsTypesMap.hip_r)}
          >
            <title>{bodyPartsMap.hip_r}</title>
          </path>
        </g>

        <ellipse
          cx="140"
          cy="69"
          rx="13"
          ry="8"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.mouth },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.mouth, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.mouth)}
        >
          <title>{bodyPartsMap.mouth}</title>
        </ellipse>

        <defs>
          <pattern id="frontBodyPattern" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#frontBodyImage" transform="translate(0 -0.000300673) scale(0.00163399 0.00069007)" />
          </pattern>

          <image
            id="frontBodyImage"
            width="612"
            height="1450"
            xlinkHref={frontImage}
          />
        </defs>
      </svg>
    </div>
  );
};
