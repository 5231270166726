import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles, Box, useTheme, useMediaQuery, SvgIcon } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IconButton } from '../../../../../components/IconButton';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { useModal } from '../../../../../components/ModalsProvider';
import { Button } from '../../../../../components/Button';
import { AccountsContext } from '../../AccountsContext';
import { EmailsSendModal } from '../EmailsSendModal';
import { Account } from './Account';
import { MailboxNav } from './MailboxNav';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const LeftSidebar = ({
  isOpenSidebar = false,
  onOpenSideBar = () => {},
  filterOpenButton
}) => {
  const classes = useStyles();
  const { accounts } = useContext(AccountsContext);
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const openSendEmailModal = () => {
    if (accounts.length) {
      openModal(EmailsSendModal, {
        payload: { accounts }
      });
    } else {
      enqueueSnackbar('Please add an account', { variant: 'warning' });
    }
  };

  return (
    <div className={classes.root}>
      <Scrollbars>
        <Box flexGrow={1}>
          <Box py={1.5} pl={2} pr={isTablet ? 1 : 2} display="flex" alignItems="center">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size={isTablet ? 'small' : 'medium'}
              onClick={openSendEmailModal}
            >
              Compose message
            </Button>

            {isTablet && (
              <Box ml={2} display="flex" alignItems="center">
                {filterOpenButton}

                <IconButton color="primary" onClick={onOpenSideBar}>
                  <SvgIcon color="inherit">
                    {isOpenSidebar ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon color="primary" />}
                  </SvgIcon>
                </IconButton>
              </Box>
            )}
          </Box>

          <Account />
          <MailboxNav />
        </Box>
      </Scrollbars>
    </div>
  );
};
