import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { makeStyles, IconButton, Typography, Box } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { TaskMenu, DueDate } from '../../../../app/Dashboard/TasksPage';
import { ProgressBar } from '../../../../app/Dashboard/TasksPage/MainWindow/ProgressBar';
import { PreviewTaskMember } from '../../../../app/Dashboard/TasksPage/PreviewTaskMember';
import { itemsRoutesByModels } from '../../../../dataMaps/apiModels';
import { stopPropagation } from '../../../../helpers/dom';
import { Label } from '../../../../app/Dashboard/TasksPage/MainWindow/Label';
import { ListRow, ListRowCell } from '../../../ListRow';
import { Loader } from '../../../Loader';
import { Members } from '../../../Members';
import { Popper } from '../../../Popper';
import { columnsWidths, minRowHeight } from '../Tasks';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item: task = {}, isLoaded, recalculateHeight }) => {
  const classes = useStyles();
  const history = useHistory();
  const owner = task?.users?.find(({ id }) => id === task.owner_id);

  const getTaskPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.task(task.id));
    }
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow
      button
      minHeight={minRowHeight}
      title="Open task page"
      onClick={getTaskPage}
    >
      <ListRowCell grow column flexBasis={columnsWidths.name}>
        <Typography noWrap title={task.name}>
          {task.name}
        </Typography>

        {task.typeLabel &&
          <Label name={task.typeLabel} />
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.case}>
        <Box mr={1}>
          <WorkIcon className={classes.caseIcon} />
        </Box>

        <Typography noWrap title={task?.parent?.file_number}>
          {task?.parent?.file_number || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        <Typography noWrap>-</Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {moment.unix(task.created_at).format('L')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {task.due_at && <DueDate task={task} />}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        <Typography noWrap title={task.status && task.status.name}>
          {task.status && task.status.name}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.progress}>
        <ProgressBar
          logged={task.logged_time}
          estimate={task.estimated_time}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.priority}>
        {task.priorityLabel &&
          <Label name={task.priorityLabel} color={task.priority} />
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.members}>
        <Members
          isParent
          noWrap
          maxVisible={4}
          owner={owner}
          users={task.users || []}
          MenuComponent={PreviewTaskMember}
          menuProps={{
            parent: task
          }}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton
                buttonRef={anchorRef}
                onClick={stopPropagation(handleToggle)}
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <TaskMenu task={task} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
