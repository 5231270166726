import { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../InfiniteLoader';
import { Loader } from '../../../../Loader';
import { RuleTemplatesContext } from '../../RuleTemplatesProvider';
import { RuleItem } from './RuleItem';

export const minRowHeight = 100;
export const columnsWidths = {
  description: 250,
  action: 100
};

export const Rules = () => {
  const {
    rules,
    isRulesFetched,
    meta,
    isRulesFetching,
    updateRulesPositions,
    loadNextPage
  } = useContext(RuleTemplatesContext);
  const scrollElementRef = useRef();

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const item = rules[oldIndex];

      updateRulesPositions({ id: item.id, oldIndex, newIndex });
    }
  };

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isRulesFetched} render={
        () => !rules.length ? (
          <Box p={3}>
            <Typography color="textSecondary">No rules found</Typography>
          </Box>
        ) : (
          <>
            <MuiList disablePadding>
              <InfiniteListLoader
                isSortable
                useDragHandle
                items={rules}
                isFetching={isRulesFetching}
                isFetched={isRulesFetched}
                pagination={meta}
                lockAxis="y"
                RowComponent={RuleItem}
                onSortEnd={handleSortEnd}
                minRowHeight={minRowHeight}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Box>
  );
};
