import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import cn from 'classnames';
import { makeStyles, Paper } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { widthBreakpointXXS } from '../../../../../../../../components/Widgets/Billing';
import { InvoicesContext } from '../../../../InvoicesContext';
import {
  columnsMap,
  columnsWidths,
  widthBreakpointSmall,
  widthBreakpointXS,
  widthBreakpointMedium,
  widthBreakpointLarge
} from '../listConfig';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = ({
  width,
  disableToggle = false,
  paymentForm,
  parentPage,
  selectedInvoices,
  setSelectedInvoices,
  hiddenColumns = []
}) => {
  const {
    filter,
    invoices,
    toggleAllInvoicesSelection,
    toggleItemSelection,
    allInvoicesIsSelected,
    selectedInvoicesIDs
  } = useContext(InvoicesContext);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleToggleAllInvoicesSelection = () => {
    if (!selectedInvoices) {
      toggleAllInvoicesSelection();

      return;
    }

    if (parentPage) {
      if (selectedInvoices.length === 1) {
        toggleItemSelection(selectedInvoices[0].id);
        setSelectedInvoices([]);
      } else {
        enqueueSnackbar('You can choose only one invoice', { variant: 'warning' });
      }
    } else {
      if (!filter.model_to_id) {
        enqueueSnackbar('You must select a participant', { variant: 'warning' });
      } else {
        const allIsSelected = invoices.length === selectedInvoicesIDs.length;

        setSelectedInvoices(allIsSelected ? [] : invoices);
        toggleAllInvoicesSelection();
      }
    }
  };

  return (
    <Paper elevation={0}>
      <ListRow header className={cn(classes.root, { [classes.root_form]: !!paymentForm })}>
        {!disableToggle && !hiddenColumns.includes(columnsMap.checkbox) &&
          <ListRowCheckbox
            onClick={handleToggleAllInvoicesSelection}
            checked={!parentPage ? allInvoicesIsSelected() : false}
            indeterminate={!!selectedInvoicesIDs.length && !allInvoicesIsSelected()}
          />
        }

        <ListRowCell grow flexBasis={columnsWidths.name}>
          Inv. #
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.billFor) && (width > widthBreakpointLarge) &&
          <ListRowCell grow flexBasis={columnsWidths.billFor}>
            Bill For
          </ListRowCell>
        }

        {!paymentForm && width > widthBreakpointXXS &&
          <ListRowCell grow flexBasis={columnsWidths.billFrom}>
            Bill From
          </ListRowCell>
        }

        {!paymentForm &&
          <ListRowCell flexBasis={columnsWidths.billTo}>
            Bill To
          </ListRowCell>
        }

        {width > widthBreakpointMedium &&
          <ListRowCell flexBasis={columnsWidths.dueDate}>
            Due Date
          </ListRowCell>
        }

        {width > widthBreakpointXS &&
          <ListRowCell flexBasis={columnsWidths.amount}>
            Amount
          </ListRowCell>
        }

        {width > widthBreakpointSmall &&
          <ListRowCell flexBasis={columnsWidths.balance}>
            Balance Due
          </ListRowCell>
        }

        {width > widthBreakpointSmall &&
          <ListRowCell flexBasis={columnsWidths.paid}>
            Paid
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.status}>
          Status
        </ListRowCell>

        {!disableToggle && (
          <ListRowCell flexBasis={columnsWidths.action} />
        )}
      </ListRow>
    </Paper>
  );
};
