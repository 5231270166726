export const styles = ({ spacing, palette }) => ({
  borderRight: {
    borderRight: `1px solid ${palette.divider}`
  },

  borderLeft: {
    borderLeft: `1px solid ${palette.divider}`
  },

  borderBottom: {
    borderBottom: `1px solid ${palette.divider}`
  },

  contentSpacing: {
    padding: spacing(2)
  },

  leftContentSlider: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: spacing(0, 2)
  },

  leftContentSlider__title: {
    position: 'absolute',
    right: 16,
    top: 4,
    color: ({ color }) => color || 'inherit'
  },

  leftContentSlider__inner: {
    flexGrow: 0,
    width: 60,
    textAlign: 'center'
  },

  slider: {
    flexGrow: 1,
    marginLeft: spacing(),
    textAlign: 'center'
  },

  rightContentIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.grey[400],
    padding: spacing(2, 1),

    '&:last-child': {
      paddingRight: spacing(2)
    }
  },

  upIcon: {
    transform: `rotate(${270}deg)`
  },

  downIcon: {
    transform: `rotate(${90}deg)`
  }
});
