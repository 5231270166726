import { useContext, useRef } from 'react';
import { Box, Paper, useTheme } from '@material-ui/core';
import { useResizeObserver } from '../../../../../../helpers/hooks';
import { filtersKeysMap } from '../../../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { PaymentsContextProvider } from '../../../../../../components/billing/payments';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { ActionsBar } from '../ActionsBar';
import { FiltersBar } from '../FiltersBar';
import { columnsMap, List } from '../List';
import { Grid } from '../Grid';

const hiddenColumns = [ columnsMap.overdraftActions ];

export const Received = ({
  hiddenFields = [],
  filter = {},
  filterKey = filtersKeysMap.billing_payments_received
}) => {
  const rootRef = useRef();
  const theme = useTheme();
  const { viewVariant } = useContext(LayoutContext);
  const { width } = useResizeObserver({ ref: rootRef });
  const isTablet = width <= theme.breakpoints.values.md;
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper
  } = useMobileFilterCollapse('md', width);
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  return (
    <PaymentsContextProvider filter={{ overdraft: null, ...filter }}>
      <ActionsBar isTablet={isTablet} filterOpenButton={filterOpenButton} />

      <ScrollWrapper {...scrollWrapperProps}>
        <FilterBarWrapper {...filterBarWrapperProps}>
          <FiltersBar hiddenFields={hiddenFields} filterKey={filterKey} />
        </FilterBarWrapper>
      </ScrollWrapper>

      <Paper component={Box} minHeight={500} ref={rootRef}>
        {isTablet || viewVariant === viewVariantsMap.grid ? (
          <Grid width={width} />
        ) : viewVariant === viewVariantsMap.list ? (
          <List hiddenColumns={hiddenColumns} />
        ) : null}
      </Paper>
    </PaymentsContextProvider>
  );
};
