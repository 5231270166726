export const styles = ({
  spacing,
  palette: {
    grey,
    primary,
    slategray,
    error,
    darkgreen
  },
  typography: { pxToRem, fontWeightMedium }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: spacing(2)
  },

  mainContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: spacing(5),
    marginBottom: spacing(5),
    flexWrap: 'wrap'
  },

  content: {
    minWidth: '50%',
    paddingRight: spacing(3),

    '&:nth-child': {
      paddingRight: 0
    }
  },

  content__text: {
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(36),
    color: darkgreen
  },

  contentTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: fontWeightMedium
  },

  title: {
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(18),
    color: grey[500]
  },

  cardTitle: {
    display: 'flex',
    alignItems: 'center'
  },

  counter: {
    color: primary.contrastText,
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(14),
    marginLeft: spacing(),
    padding: spacing(0, 1),
    borderRadius: 50,
    backgroundColor: slategray
  },

  mainInfo: {
    display: 'flex',
    flexDirection: 'column'
  },

  mainInfoContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: spacing()
  },

  mainInfoContent__title: {
    fontSize: pxToRem(16),
    color: grey[500],
    width: 100
  },

  mainInfoContent__sum: {
    fontSize: pxToRem(16),
    fontWeight: fontWeightMedium,
    color: grey[600]
  },

  mainInfoContent__redSum: {
    color: error.main
  }
});
