import { Box, Dialog, Grid, makeStyles, SvgIcon } from '@material-ui/core';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { ModalBody, ModalContainer, ModalHeader } from '../../Modal';
import { DurableMedicalEquipment } from './DurableMedicalEquipment';
import { Indicators } from './Indicators';
import { Lab } from './Lab';
import { NationalDrugCode } from './NationalDrugCode';

const useStyles = makeStyles(({ palette }) => ({
  borderBottom: {
    borderBottom: `1px solid ${palette.divider}`
  },

  borderRight: {
    borderRight: `1px solid ${palette.divider}`
  }
}));

export const ServiceLineViewModal = ({
  payload: {
    authorization,
    serviceLine
  },
  DialogProps,
  handleModalReject
}) => {
  const classes = useStyles();

  return (
    <Dialog maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader
          icon={<SvgIcon><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>}
          onClose={handleModalReject}
        >
          View Service Line
        </ModalHeader>

        <ModalBody disablePaddings>
          <Grid container>
            <Grid item md={6} className={classes.borderRight}>
              <Grid container>
                <Grid item xs={12} className={classes.borderBottom} component={Box} p={4}>
                  <Indicators authorization={authorization} serviceLine={serviceLine} />
                </Grid>

                <Grid item xs={12} component={Box} p={4}>
                  <NationalDrugCode serviceLine={serviceLine} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6}>
              <Grid container>
                <Grid item xs={12} className={classes.borderBottom} component={Box} p={4}>
                  <DurableMedicalEquipment serviceLine={serviceLine} />
                </Grid>

                <Grid item xs={12} component={Box} p={4}>
                  <Lab serviceLine={serviceLine} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
