import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  SvgIcon,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { mdiVirusOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as formsApi from '../../../../api/profile';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { Loader } from '../../../Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../Modal';
import { Body } from './Body';
import { StaffSignature } from './StaffSignature';
import { covidValidationSchema } from './covidValidationSchema';

export const CovidFormModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { isEditable = true, initialValues = null }
}) => {
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const [ form, setForm ] = useState(null);
  const currentUser = useSelector(({ profile }) => profile.user);
  const dob = form?.patient?.birthday && moment(form?.patient?.birthday).format('YYYY-MM-DD');
  const staffSignature = form?.signatures?.find(({ type }) => type === 'worker');
  const patientSignature = form?.signatures?.find(({ type }) => type === 'patient');

  const handleCreateForm = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.temperature = !!values.temperature ? `${values.temperature}` : null;

    return formsApi.createCovidMedicalForm(values).then((data) => {
      enqueueSnackbar('Covid form successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
        enqueueSnackbar('Form not created', { variant: 'error' });
      }
    });
  };

  const handleUpdateForm = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.temperature = !!values.temperature ? `${values.temperature}` : null;

    if (isAuthenticated) {
      return formsApi.updateCovidMedicalForm({ id: form.id, ...values }).then((data) => {
        enqueueSnackbar('Form successfully updated', { variant: 'success' });
        handleModalResolve(data.covid_info);
      }).catch(({ errors }) => {
        errors && setErrors(errors);
        enqueueSnackbar('Form not updated', { variant: 'error' });
      });
    } else {
      return formsApi.updateGuestCovidMedicalForm({ id: form.id, ...values }).then((data) => {
        enqueueSnackbar('Form successfully updated', { variant: 'success' });
        handleModalResolve(data.covid_info);
      }).catch(({ errors }) => {
        errors && setErrors(errors);
        enqueueSnackbar('Form not updated', { variant: 'error' });
      });
    }
  };

  useEffect(() => {
    formsApi.fetchMedicalForm(initialValues.id).then(setForm);
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <Loader loading={!form} p={3} render={
        () => (
          <Formik
            initialValues={{
              ...form,
              medical_form_id: form.id,
              first_name: form?.forms?.first_name || form?.patient?.first_name || form.first_name,
              last_name: form?.forms?.last_name || form?.patient?.last_name || form.last_name,
              is_cough: form?.forms?.is_cough || null,
              dob: form?.forms?.dob || dob || null,
              if_yes: form?.forms?.if_yes || null,
              is_difficulty_breathing: form?.forms?.is_difficulty_breathing || null,
              is_chills: form?.forms?.is_chills || null,
              is_fever: form?.forms?.is_fever || null,
              is_traveled: form?.forms?.is_traveled || null,
              is_airport: form?.forms?.is_airport || null,
              description: form?.forms?.description || null,
              temperature: form?.forms?.temperature ? +form?.forms?.temperature : null,
              date: form?.forms?.date || moment().unix(),
              isStaff: isAuthenticated && currentUser?.role !== rolesMap.patient,
              staff_signature: staffSignature?.signature,
              patient_signature: patientSignature?.signature
            }}
            validationSchema={covidValidationSchema}
            onSubmit={isEditable ? handleUpdateForm : handleCreateForm}
          >
            {({ isSubmitting, handleSubmit }) => (
              <ModalContainer
                noValidate
                component="form"
                onSubmit={handleSubmit}
              >
                <ModalHeader icon={<SvgIcon><Icon path={mdiVirusOutline} /></SvgIcon>} onClose={handleModalReject}>
                  COVID-19
                </ModalHeader>

                <ModalBody>
                  <Typography gutterBottom variant={isMobile ? 'h5' : 'h6'}>
                    THE SAFETY OF OUR PATIENTS AND STAFF IS OUR PRIORITY DURING THE MONITORIG PHASE OF COVID-19
                  </Typography>

                  <Body disableUserSignature={!isAuthenticated} disableUserName={!!form.id} />
                  {isAuthenticated && currentUser?.role !== rolesMap.patient && <StaffSignature />}
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
