import moment from 'moment';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

export const UserMeasuresInfo = ({ dob, height, heightUnit = 'm', weight, weightUnit = 'kg' }) => {
  const age = (dob ? moment().diff(dob, 'years') : 0) + 'y.o';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Typography variant={isMobile ? 'body2' : 'body1'} color="textSecondary">
      {age + '/' + height + heightUnit + '/' + weight + weightUnit }
    </Typography>
  );
};
