import _ from 'lodash';
import { createHookReducer } from '../../../../../../../helpers/createHookReducer';
import { initialState } from './initialState';
import * as types from './types';

export const reducer = createHookReducer({
  [types.SCHEDULES_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.SCHEDULES_FETCH_SUCCESS]: (
    { filter, schedules, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      schedules: data
    };
  },

  [types.ADD_SCHEDULE]: (state, payload) => {
    const { filter, schedules, pagination, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((schedules.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page: page === 0 ? 1 : page },
      schedules: [ ...schedules,  payload ]
    };
  },

  [types.UPDATE_SCHEDULE_IN_LIST]: (
    { filter, schedules, ...state },
    schedule
  ) => {
    return {
      ...state,

      filter,
      schedules: schedules.map((item) => {
        return item.id === schedule.id ? schedule : item;
      })
    };
  },

  [types.DELETE_SCHEDULE]: ({ pagination, filter, schedules, ...state }, scheduleId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      schedules: schedules.filter((schedule) => schedule.id !== scheduleId)
    };
  },

  [types.MASS_DELETE_SCHEDULES]: ({ schedules, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      schedules: schedules.filter((schedule) => IDs.indexOf(schedule.id) < 0)
    };
  },

  [types.RESET_SCHEDULES]: ({ filter, ...state }, newFilter) => {
    const prevFilter = _.isEqual(newFilter, { ...initialState.filter }) && newFilter;

    return {
      ...state,

      isFetched: false,
      filter: prevFilter || { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.TOGGLE_ALL_SCHEDULES_SELECTION]: ({ schedules, selectedSchedulesIDs, ...state }) => {
    const allSchedulesIsSelected = schedules.length === selectedSchedulesIDs.length;

    return {
      ...state,

      schedules,
      selectedSchedulesIDs: allSchedulesIsSelected ? [] : schedules.map((item) => item.id)
    };
  },

  [types.TOGGLE_SCHEDULE_SELECTION]: ({ selectedSchedulesIDs, ...state }, id) => {
    return {
      ...state,

      selectedSchedulesIDs: selectedSchedulesIDs.indexOf(id) !== -1 ?
        selectedSchedulesIDs.filter((item) => item !== id)
        :
        selectedSchedulesIDs.concat(id)
    };
  }
});
