import { pick } from 'lodash';
import { useFormikContext } from 'formik';
import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import * as officeApi from '../../../../../../api/companies';
import {
  TextField,
  CompanyTypesSelect,
  OfficesLocationSelect
} from '../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../components/users';
import { AvatarContent } from '../../../../Members/CreateCustomerModal';
import { companyTypeIDsMap } from '../../../../ProfilePage/insurance';

export const MainInfo = ({ onAvatarUrlChange }) => {
  const { values, setValues } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileSM = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const handleMainOfficeChange = (office) => {
    if (office?.id) {
      officeApi.fetchOffice(office?.id).then((office) => {
        setValues({
          ...values,

          ...pick(office, [
            'country',
            'state',
            'city',
            'zip',
            'street',
            'designator',
            'unit_number',
            'phone',
            'mobile_phone',
            'fax',
            'timezone',
            'email'
          ]),

          is_same: false
        });
      });
    }
  };

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={isMobileSM ? 12 : 8} container spacing={2}>
        {isMobileSM && (
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <AvatarContent size={100} onAvatarUrlChange={onAvatarUrlChange} />
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            required
            name="name"
            label="Full Company Name"
            placeholder="Enter name..."
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="abbr"
            label="Short Company Name"
            placeholder="Enter short name..."
          />
        </Grid>

        <Grid item xs={12}>
          <CompanyTypesSelect required disabled={values.disableCourtField} />
        </Grid>

        {values.company_type_id === companyTypeIDsMap.court &&
          <Grid item xs={12}>
            <TextField
              name="court_number"
              label="Court Number"
              placeholder="Enter court number..."
            />
          </Grid>
        }

        <Grid item xs={12}>
          <UsersSelect
            isCreatable
            name="contact_user_id"
            label="Contact Person"
            creatablePayload={{
              hideWorkOffice: true
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <OfficesLocationSelect
            label="Main office"
            name="main_office_id"
            params={{ without_company: 1 }}
            onChange={handleMainOfficeChange}
          />
        </Grid>
      </Grid>

      {!isMobileSM && (
        <Grid item xs={4}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <AvatarContent size={isMobile ? 100 : 160} onAvatarUrlChange={onAvatarUrlChange} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
