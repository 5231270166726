import * as types from './types';
import { createHookReducer } from '../../../../../helpers/createHookReducer';

export const reducer = createHookReducer({
  [types.FETCH_PRINTERS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_PRINTERS_SUCCESS]: ({ filter, printers, ...state }, { data: newPrinters, pagination }) => {
    const { page, per_page } = pagination;

    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      printers: (page > 1 ?
        printers.filter(({ id }) => !newPrinters.find((loadedPrinter) => id === loadedPrinter.id)).concat(newPrinters)
        :
        newPrinters
      )
    };
  },

  [types.ADD_PRINTER]: ({ pagination, printers, ...state }, printer) => {
    return {
      ...state,

      printers: [ ...printers, printer ],
      pagination: {
        ...pagination,
        total: pagination.total + 1
      }
    };
  },

  [types.UPDATE_PRINTER]: ({ printers, ...state }, updatedPrinter) => {
    return {
      ...state,

      printers: printers.map((printer) => updatedPrinter.id === printer.id ? updatedPrinter : printer)
    };
  },

  [types.DELETE_PRINTERS]: ({ pagination, filter, printers, ...state }, printerIDs) => {
    const deletedPrinters = printers.filter((printer) => printerIDs.find((id) => id === printer.id));
    const total = pagination.total - deletedPrinters.length;
    const page = Math.ceil((printers.length - deletedPrinters.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      printers: printers.filter((printer) => !deletedPrinters.find(({ id }) => id === printer.id))
    };
  },

  [types.RESET_PRINTERS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 },
      printers: []
    };
  }
});
