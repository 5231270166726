import { useContext, useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { IconButton } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import { TagsContext } from '../../../app/Dashboard/files-common';
import { Folders } from './Folders';

export const FoldersList = ({ folders, selectedFolderIds, onFolderToggleSelection, ...props }) => {
  const collapseButtonUid = uniqueId('folders_');
  const { resetTags } = useContext(TagsContext);
  const [ expanded, setExpanded ] = useState([]);

  const handleToggle = (event, nodeIds) => {
    if (event.target.closest(`[data-uid="${collapseButtonUid}"]`)) {
      setExpanded(nodeIds);
    }
  };

  useEffect(() => {
    if (!folders) {
      resetTags();
    }
  }, []);

  return (
    <TreeView
      multiSelect
      defaultCollapseIcon={
        <IconButton data-uid={collapseButtonUid} color="inherit">
          <IndeterminateCheckBoxOutlinedIcon />
        </IconButton>
      }
      defaultExpandIcon={
        <IconButton data-uid={collapseButtonUid} color="inherit">
          <AddBoxOutlinedIcon />
        </IconButton>
      }
      expanded={expanded}
      selected={selectedFolderIds}
      onNodeToggle={handleToggle}

      {...props}
    >
      <Folders
        collapseButtonUid={collapseButtonUid}
        folders={folders}
        selected={selectedFolderIds}
        setExpanded={setExpanded}
        onFolderToggleSelection={onFolderToggleSelection}
      />
    </TreeView>
  );
};
