import { useContext, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../components/TablePagination';
import { Loader } from '../../../../../components/Loader';
import { Cards } from '../../../../../components/Cards';
import { PrescriptionsContext } from '../PrescriptionsProvider';
import { Card } from './Card';

export const Grid = () => {
  const { isFetched, meta, prescriptions, resetPrescriptions } = useContext(PrescriptionsContext);
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    resetPrescriptions();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={isMobile ? 1 : 2}>
        <Loader p={3} loading={!isFetched} render={
          () => !prescriptions.length ? (
            <Box p={3}>
              <Typography align="center">No prescriptions found</Typography>
            </Box>
          ) : (
            <>
              <Cards size={isMobile? 290 : 370} list={prescriptions} component={Card} />
              <TablePagination borderTop pagination={meta} onChange={resetPrescriptions} />
            </>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
