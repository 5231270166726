import { createContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, omit } from 'lodash';
import { api } from '../../../../../api';
import * as statisticsApi from '../../../../../api/statistics';
import { usePrevious } from '../../../../../helpers/hooks';
import { reducer } from './reducer';
import { initialState as initialStateConfig } from './initialState';
import * as types from './types';

export const CaseStatisticsContext = createContext();

export const CaseStatisticsProvider = ({ initialState = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, { ...initialStateConfig, ...initialState });
  const {
    isFetched,
    isFetching,
    filter,
    statistics
  } = state;
  const cancelFetch = useRef(() => {});
  const prevFilter = usePrevious(filter);

  const fetchAnalytics = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_CASE_GRAPH_REQUEST });

    statisticsApi.fetchCaseGraphStatistics({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then((data) => {
      dispatch({ type: types.FETCH_CASE_GRAPH_SUCCESS, payload: data });
    });
  };

  const filterAnalytics = (newFilter) => {
    dispatch({ type: types.FILTER_CASE_GRAPH, payload: newFilter });

    fetchAnalytics({ page: 1, ...newFilter });
  };

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      filterAnalytics(filter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    filterAnalytics();

    return () => {
      cancelFetch.current();
    };
  }, []);

  const providerValue = {
    isFetched,
    isFetching,
    filter,
    statistics,

    filterAnalytics
  };

  return (
    <CaseStatisticsContext.Provider value={providerValue}>
      {children}
    </CaseStatisticsContext.Provider>
  );
};
