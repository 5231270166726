export const columnsWidths = {
  statusType: 125,
  type: 160,
  caseInfo: 160,
  insuranceId: 150,
  company: 180,
  groupId: 120,
  pcp: 100,
  spc: 100,
  financialClass: 120,
  expirationDate: 120,
  action: 70
};
