export const styles = ({
  spacing,
  palette: { grey },
  typography: { pxToRem },
  transitions
}) => ({
  content: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: spacing()
  },

  content__action: {
    position: 'relative'
  },

  content__shadow: {
    position: 'absolute',
    width: '100%',
    height: '20px',
    top: '-20px',
    left: 0,
    right: 0,
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%)'
  },

  creationButton: {
    color: grey[400],
    transition: transitions.create(),
    borderRadius: 0,

    '&:hover': {
      color: grey[500]
    }
  },

  creationButton__icon: {
    marginRight: spacing(1),
    fontSize: pxToRem(18)
  }
});
