import { makeStyles, Paper, Collapse, List as MuiList, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { CurrencyFormat } from '../../../../../../../../../components/CurrencyFormat';
import { IconButton } from '../../../../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { TableHeader } from './TableHeader';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const columnsWidths = {
  name: 130,
  type: 240,
  quantity: 80,
  price: 80,
  action: 60
};

export const List = ({ NDCCodes, onDelete = () => {} }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <Paper variant="outlined" square className={classes.root}>
      {!NDCCodes.length ? (
        <Collapse in={!NDCCodes.length} timeout="auto" unmountOnExit>
          <TableHeader />

          <MuiList disablePadding>
            <ListRow className={classes.row}>
              <ListRowCell grow flexBasis={columnsWidths.name}>
                -
              </ListRowCell>
              {!isMobile && <ListRowCell flexBasis={columnsWidths.type}>-</ListRowCell>}
              <ListRowCell flexBasis={columnsWidths.quantity}>-</ListRowCell>
              <ListRowCell flexBasis={columnsWidths.price}>-</ListRowCell>
            </ListRow>
          </MuiList>
        </Collapse>
      ) : (
        <Collapse in={!!NDCCodes.length} timeout="auto" unmountOnExit>
          <TableHeader />

          <MuiList disablePadding>
            {NDCCodes.map((item, i) => (
              <ListRow key={i}>
                <ListRowCell column={isMobile} grow flexBasis={columnsWidths.name}>
                  <Typography noWrap>{item?.ndc_code?.code || item?.ndc_code_name}</Typography>

                  {isMobile && <Typography>{item?.quantity_type}</Typography>}
                </ListRowCell>

                {!isMobile && (
                  <ListRowCell flexBasis={columnsWidths.type}>{item?.quantity_type}</ListRowCell>
                )}

                <ListRowCell flexBasis={columnsWidths.quantity}>{item?.quantity}</ListRowCell>

                <ListRowCell flexBasis={columnsWidths.price}>
                  <CurrencyFormat value={item?.price} />
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.action}>
                  <IconButton
                    color="error"
                    onClick={onDelete(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListRowCell>
              </ListRow>
            ))}
          </MuiList>
        </Collapse>
      )}
    </Paper>
  );
};
