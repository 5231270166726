export const styles = ({
  spacing,
  palette: { primary },
  shape: { borderRadius },
  shadows,
  breakpoints
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
    flexGrow: 1,
    marginLeft: spacing(1),
    backgroundColor: primary.contrastText,
    boxShadow: shadows[2],
    borderRadius,

    [breakpoints.down('md')]: {
      margin: 0
    }
  }
});
