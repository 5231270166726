export const filterFieldsMap = {
  usersId: 'users_id',
  caseId: 'case_id',
  companyId: 'company_id'
};

export const filterFieldsLabels = {
  [filterFieldsMap.usersId]: 'Users',
  [filterFieldsMap.caseId]: 'Cases',
  [filterFieldsMap.companyId]: 'Company'
};
