import {
  Box,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Checkbox, TextField } from '../../../../../../components/FormField';

export const CurrentHistory = () => {
  return (
    <Grid container spacing={0} component={Box} px={2}>
      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="No Interval Change"
          control={
            <Checkbox
              color="primary"
              name="forms.current_history.no_interval_change"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Flare-up of Pain"
          control={
            <Checkbox
              color="primary"
              name="forms.current_history.flare_up_of_pain"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Persistent pain"
          control={
            <Checkbox
              color="primary"
              name="forms.current_history.persistent_pain"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Questionnaire Reviewed & Discussed"
          control={
            <Checkbox
              color="primary"
              name="forms.current_history.questionnaire_reviewed_discussed"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Current Complaints Discussed"
          control={
            <Checkbox
              color="primary"
              name="forms.current_history.current_complaints_discussed"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <Box mt={2} mb={3}>
          <TextField
            fullWidth
            name="forms.current_history.note"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="subtitle2">
                    <Box component="span" mr={0.5}>
                      <NotesIcon fontSize="inherit" />
                    </Box>
                    Add Note
                  </Typography>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Current Medications Use & Their Side Effects Discussed"
          control={
            <Checkbox
              color="primary"
              name="forms.current_history.current_medications_use_their_side_effects_discussed"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Alternative Treatments Discussed"
          control={
            <Checkbox
              color="primary"
              name="forms.current_history.alternative_treatments_discussed"
            />
          }
        />
      </Grid>
    </Grid>
  );
};
