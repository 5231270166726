import { useState, createContext } from 'react';
import { initialState } from './initialState';

export const StatisticsPaymentsFilterContext = createContext();

export const StatisticsPaymentsFilterContextProvider = ({ children }) => {
  const [ filter, setFilter ] = useState(initialState.filter);

  const providerValue = {
    filter,
    setFilter
  };

  return (
    <StatisticsPaymentsFilterContext.Provider value={providerValue}>
      {children}
    </StatisticsPaymentsFilterContext.Provider>
  );
};
