import { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import { Fab } from '../../../../../../../components/Fab';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { setUsersLastGlobalAction } from '../../../../../../../store/globalActions';
import * as types from '../../../../../Members/User/UserProvider/types';
import { CertificatesContext } from '../../../../LeftSidebar/Signature/CertificatesContext';
import { CertificateSignatureModal } from '../../../../LeftSidebar/Signature/CertificateSignatureModal';
import { UpdateSignatureModal } from '../../../../LeftSidebar/Signature/UpdateSignatureModal';

export const UserSignature = ({
  disableCertificate = false,
  disableSignature = false,
  updateCertificate = () => {}
}) => {
  const {
    isFetching,
    isFetched,
    certificates,
    ...certificatesContext
  } = useContext(CertificatesContext);
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const signature = useSelector(({ profile }) => profile?.user?.signature) || null;
  const { user } = useSelector(({ profile }) => profile);

  const addCertificate = () => {
    updateCertificate(true);

    openModal(CertificateSignatureModal, {
      payload: { certificatesContext },
      onModalResolved: () => {
        updateCertificate(false);
        dispatch(setUsersLastGlobalAction({ type: types.SET_PROFILE_DATA }));
      },
      onModalReject: () => {
        updateCertificate(false);
      }
    });
  };

  const updateSignature = () => {
    const { first_name, middle_name, last_name } = user;
    const defaultSignature = {
      electronic_signature: `${first_name} ${(middle_name ? middle_name?.[0] + '. ' : '') + last_name}`,
      fax_signature: first_name?.[0] + last_name?.[0]
    };

    openModal(UpdateSignatureModal, {
      payload: {
        signature: signature ? {
          electronic_signature: signature?.electronic_signature || defaultSignature?.electronic_signature,
          fax_signature: signature?.fax_signature || defaultSignature?.fax_signature
        } : defaultSignature
      },
      onModalResolved: () => {
        updateCertificate(false);
        dispatch(setUsersLastGlobalAction({ type: types.SET_PROFILE_DATA }));
      },
      onModalReject: () => {
        updateCertificate(false);
      }
    });
  };

  return (
    <>
      <Typography align="center">
        {disableSignature
          ? 'No signature found'
          : disableCertificate
            ? 'No certificates found'
            : 'No signature and certificates found'
        }
      </Typography>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        {disableSignature &&
          <Fab
            size="small"
            color="primary"
            variant="extended"
            startIcon={<AddIcon/>}
            onClick={updateSignature}
          >
            Add signature
          </Fab>
        }

        {disableCertificate &&
          <Fab
            size="small"
            color="primary"
            variant="extended"
            startIcon={<AddIcon/>}
            onClick={addCertificate}
          >
            Add certificate
          </Fab>
        }
      </Box>
    </>
  );
};
