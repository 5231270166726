import _ from 'lodash';
import { createHookReducer } from '../../../../helpers/createHookReducer';
import { initialState } from './initialState';
import * as types from './types';

export const reducer = createHookReducer({
  [types.USERS_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.USERS_FETCH_SUCCESS]: (
    { filter, users, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      users: data
    };
  },

  [types.ADD_USER]: (state, payload) => {
    const { pagination, filter, users, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((users.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, total, page: page === 0 ? 1 : page },
      users: [ payload, ...users ]
    };
  },

  [types.UPDATE_USER_IN_LIST]: (state, payload) => {
    return {
      ...state,

      users: state.users.map((item) => {
        return item.id === payload.id ? payload : item;
      })
    };
  },

  [types.DELETE_USER_SUCCESS]: ({ users, pagination, filter, ...state }, userId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, total },
      users: users.filter(({ id }) => id !== userId)
    };
  },

  [types.RESET_USERS]: ({ filter, ...state }, newFilter) => {
    const prevFilter = _.isEqual(newFilter, { ...initialState.filter }) && newFilter;

    return {
      ...state,

      isFetched: false,
      filter: prevFilter || { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.TOGGLE_ALL_USERS_SELECTION]: ({ users, selectedIDs, ...state }) => {
    const allUsersIsSelected = users.length === selectedIDs.length;

    return {
      ...state,

      users,
      selectedIDs: allUsersIsSelected ? [] : users.map((item) => item.id)
    };
  },

  [types.TOGGLE_USER_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1 ?
        selectedIDs.filter((item) => item !== id)
        :
        selectedIDs.concat(id)
    };
  },

  [types.MASS_DELETE_USERS]: ({ users, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      users: users.filter((user) => IDs.indexOf(user.id) < 0)
    };
  }
});
