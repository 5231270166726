export const initialState = {
  isFetching: false,
  isFetched: false,
  threadMessages: [],
  filter: {
    page: 1,
    per_page: 15
  },
  pagination: {
    total: 0,
    last_page: 1
  }
};
