import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import { paymentMethodsMap } from '../../../../../../../dataMaps/paymentMethodsMap';
import { useResizeObserver } from '../../../../../../../helpers/hooks/useResizeObserver';
import { setPaymentsLastGlobalAction } from '../../../../../../../store/globalActions';
import * as paymentsApi from '../../../../../../../api/billing';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../../components/Page';
import { billingParticipantsTypesOptions } from '../../../../../../../components/billing';
import { Loader } from '../../../../../../../components/Loader';
import { InvoicesContextProvider } from '../../../../Invoices/InvoicesContext';
import { FiltersBar } from '../../../../Invoices/InvoicesPage/InvoicesContent/FiltersBar';
import { List } from '../../../../Invoices/InvoicesPage/InvoicesContent/List';
import { SelectedInvoices } from '../../../CreatePayment/MainCreateContent/SelectedInvoices';
import { PaymentForm } from '../../../CreatePayment/PaymentForm';
import { CaseInfo } from '../../../CreatePayment/CaseInfo';
import { validationSchema } from '../../../CreatePayment';
import * as types from '../../../../../../../components/billing/payments/PaymentsContext/types';
import { Header } from '../../../CreatePayment/Header';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ReceiveOverdraft = ({ match: { params } }) => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [ payment, setPayment ] = useState({});
  const [ invoice, setInvoice ] = useState({});
  const [ selectedInvoices, setSelectedInvoices ] = useState([]);
  const [ isFetched, setIsFetched ] = useState(false);
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });

  const initialState = {
    filter: {
      is_confirmed: 1,
      is_paid: 0 || null,
      model_to_type: payment?.payer?.participant_type || null,
      model_to_id: payment?.payer?.participant?.id || null
    }
  };

  const updatePayment = (paymentValue, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (selectedInvoices.length === 0) {
      enqueueSnackbar('You should choose invoice', { variant: 'error' });
      setSubmitting(false);
    } else {
      return paymentsApi.assignPayment({
        id: payment.id,
        invoice_id: selectedInvoices[0].id
      }).then((data) => {
        dispatchRedux(setPaymentsLastGlobalAction({ type: types.ASSIGN_PAYMENT, payload: data }));
        enqueueSnackbar('Payment successfully updated', { variant: 'success' });
        history.goBack();
      }).catch((errors) => {
        if (errors) {
          setErrors(errors);
          enqueueSnackbar('Payment not updated', { variant: 'error' });
        }
      });
    }
  };

  useEffect(() => {
    setIsFetched(false);

    paymentsApi.fetchPayment(+params.id).then((data) => {
      setPayment(data);
      setInvoice(data.causer_invoice || {});
      setIsFetched(true);
    });
  }, [ params.id ]);

  return (
    <Box ref={rootRef} height="100%" p={2}>
      <Loader loading={!isFetched} p={3} render={
        () => (
          <Formik
            initialValues={{
              ...payment,

              case_id: payment?.causer_invoice?.model || null,
              caseItem: (payment?.causer_invoice?.model_type === 'case' && payment?.causer_invoice?.model) || null,
              amount: payment?.amount || 0,
              method: paymentMethodsMap?.find(({ value }) => value === payment.method),
              payer: {
                type: billingParticipantsTypesOptions?.find(({ value }) =>
                  value === payment?.payer?.participant_type
                ),
                id: payment?.payer?.participant,
                info: payment?.payer?.participant || payment?.payer?.info
              }
            }}
            validationSchema={validationSchema}
            onSubmit={updatePayment}
          >
            {({ values, handleSubmit, isSubmitting }) => (
              <Page
                square={false}
                component="form"
                noValidate
                className={classes.rootContent}
                onSubmit={handleSubmit}
              >
                <PageHeader>
                  <IconButton
                    color="primary"
                    edge="start"
                    onClick={history.goBack}
                  >
                    <KeyboardBackspaceOutlinedIcon />
                  </IconButton>

                  <PageHeaderTitle>
                    Receive Overdraft
                  </PageHeaderTitle>

                  <PageHeaderActions>
                    <Button
                      color="primary"
                      className={classes.actionsBackButton}
                      onClick={history.goBack}
                    >
                      Cancel
                    </Button>

                    <Loader surface loading={isSubmitting} render={
                      () => (
                        <Button
                          autoFocus
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      )}
                    />
                  </PageHeaderActions>
                </PageHeader>

                <PageBody disablePaddings>
                  <Header isOverdraftPage />
                  <CaseInfo />

                  <PaymentForm
                    isOverdraftPage
                    width={width}
                    invoice={invoice}
                    selectedInvoices={selectedInvoices}
                  />

                  {selectedInvoices.length > 0 &&
                    <div className={classes.invoiceContent}>
                      <Typography gutterBottom variant="h6">
                        Paid invoice
                      </Typography>

                      <SelectedInvoices width={width} selectedInvoices={selectedInvoices} />
                    </div>
                  }

                  <div className={classes.invoiceContent}>
                    <Typography gutterBottom variant="h6">
                      Choose invoice
                    </Typography>

                    <InvoicesContextProvider initialState={initialState}>
                      <FiltersBar paymentForm setSelectedInvoices={setSelectedInvoices} />

                      <List
                        paymentForm
                        isEditable
                        payment={payment}
                        parentPage="edit"
                        modelID={values?.payer?.id?.id || values?.payer?.id || null}
                        modelType={values?.payer?.type?.value || values?.payer?.type || null}
                        invoice={invoice}
                        selectedInvoices={selectedInvoices}
                        setSelectedInvoices={setSelectedInvoices}
                      />
                    </InvoicesContextProvider>
                  </div>
                </PageBody>
              </Page>
            )}
          </Formik>
        )}
      />
    </Box>
  );
};
