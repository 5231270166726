import { useContext, useEffect } from 'react';
import { LayoutContext } from '../../LayoutContext';
import { ScheduleEventsFilterContext } from '../ScheduleEventsFilterProvider';
import { ScheduleContext } from '../ScheduleProvider';
import { List } from './List';

export const EventsList = ({
  hideApplyFields,
  selectedDate,
  onEventUpdate = () => {},
  onEventDelete = () => {}
}) => {
  const { changeFilter } = useContext(ScheduleEventsFilterContext);
  const scheduleContext = useContext(ScheduleContext);
  const viewVariantContext = useContext(LayoutContext);

  const handleEventUpdate = (event) => {
    if (event.recurring_rule) {
      scheduleContext.reloadEvents();
    } else {
      scheduleContext.updateEvent(event);
    }

    onEventUpdate(event);
  };

  const handleEventDelete = (event) => {
    scheduleContext.reloadEvents();
    onEventDelete(event);
  };

  useEffect(() => {
    if (selectedDate) {
      changeFilter({
        started_from: selectedDate.startOf('day').unix(),
        started_to: selectedDate.endOf('day').unix()
      });
    }
  }, [ selectedDate, viewVariantContext?.viewVariant ]);

  return (
    <List
      hideApplyFields={hideApplyFields}
      onEventUpdate={handleEventUpdate}
      onEventDelete={handleEventDelete}
    />
  );
};
