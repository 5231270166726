import { useContext } from 'react';
import { Box, Paper } from '@material-ui/core';
import { LayoutContextProvider } from '../../../../../../components/LayoutContext';
import { TimeTracking as TimeTrackingWidget } from '../../../../CasesPage/Cases/CaseItem/Widgets';
import { UserContext } from '../../UserProvider';

export const TimeTracking = () => {
  const { user } = useContext(UserContext);

  const initialFilter = {
    users: [ user.id ]
  };

  return (
    <Paper component={Box} flexGrow={1} width="100%" height="100%">
      <LayoutContextProvider>
        <TimeTrackingWidget isTabPage initialFilter={initialFilter} />
      </LayoutContextProvider>
    </Paper>
  );
};
