import { moveArrayItem } from '../../../../../helpers/moveArrayItem';

export const handleSortEnd = ({ state, setState, onUpdate }) => (result) => {
  if (!result.destination) {
    return;
  }

  if (result.type === 'column') {
    const columnOrder = moveArrayItem(
      state.columnOrder,
      result.source.index,
      result.destination.index
    );

    setState({
      ...state,
      columnOrder
    });

    return;
  }

  if (result.source.droppableId === result.destination.droppableId) {
    if (result.source.index === result.destination.index) {
      return;
    }

    const column = state.columns[result.source.droppableId];
    const item =  state?.columns?.[result?.source?.droppableId]?.items?.[result?.source?.index];

    const items = moveArrayItem(
      column?.items,
      result.source.index,
      result.destination.index
    );

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [column.id]: {
          ...column,
          items
        }
      }
    };

    onUpdate({ id: item?.id, newIndex: result.destination.index, status: +result.destination.droppableId });
    setState(newState);

    return;
  }

  const sourceColumn = state?.columns[result?.source?.droppableId];
  const destinationColumn = state?.columns[result?.destination?.droppableId];
  const item = sourceColumn?.items?.[result?.source?.index];

  const newSourceColumn = {
    ...sourceColumn,
    items: [ ...sourceColumn.items ]
  };

  newSourceColumn.items.splice(result?.source?.index, 1);

  const newDestinationColumn = {
    ...destinationColumn,
    items: [ ...destinationColumn.items ]
  };

  newDestinationColumn.items.splice(result.destination.index, 0, item);

  const newState = {
    ...state,
    columns: {
      ...state.columns,
      [newSourceColumn.id]: newSourceColumn,
      [newDestinationColumn.id]: newDestinationColumn
    }
  };

  onUpdate({ id: item?.id, newIndex: result.destination.index, status: +result.destination.droppableId });
  setState(newState);
};
