import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.description}>
          Description
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dateReceived}>
          Date Received
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dateResponded}>
          Date Responded
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.depositionDate}>
          Deposition Date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
