export const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexGrow: 1
  },

  chatWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },

  toolBarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: palette.grey[50]
  },

  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 0, 0.5),
    marginRight: 'auto'
  },

  subtitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center'
  },

  chatThread: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },

  chatOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    background: 'rgba(0,0,0,.3)'
  }
});
