import { useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { Box, Step, StepLabel, Stepper } from '@material-ui/core';
import { useStepper } from '../../../../../../utils/hooks/useStepper';
import { Button } from '../../../../../Button';
import { getSteps } from './getSteps';

export const FormContent = ({ form, setFormValue, setDisabled }) => {
  const steps = useMemo(() => getSteps(form), [ form ]);
  const { activeStep, handleStep, handleNext, isLastStep } = useStepper({ steps });
  const Component = steps[activeStep].component;

  useEffect(() => {
    const filledSteps = steps?.reduce((filledSteps, step) => {
      if (!step?.disable && !get(form, step.name)) {
        return filledSteps.concat(!!get(form, step.name));
      }

      return filledSteps;
    }, []);

    setDisabled(!!filledSteps?.length);
  }, [ form ]);

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((item, index) => !item?.disable && (
          <Step key={item.name}>
            <StepLabel completed={Boolean(get(form, item.name))} onClick={handleStep(index)}>
              {item.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box py={3}>
        <Component form={form} setFormValue={setFormValue} />
      </Box>

      {!isLastStep() && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
        >
          Next
        </Button>
      )}
    </>
  );
};
