import { PageHeader, PageBody } from '../../../../components/Page';
import { TaskTemplatesGroupsProvider } from './TaskTemplatesGroupsProvider';
import { Header } from './Header';
import { List } from './List';

export const TaskTemplatesGroups = () => {
  return (
    <TaskTemplatesGroupsProvider>
      <PageHeader>
        <Header />
      </PageHeader>

      <PageBody fullHeight>
        <List />
      </PageBody>
    </TaskTemplatesGroupsProvider>
  );
};
