import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../../helpers/createReduxReducer';
import * as types from './types';

const initialState = {
  isFetching: false,
  activeTimer: null
};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [types.ACTIVITY_TIMER_FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.ACTIVITY_TIMER_FETCH_SUCCESS]: (state) => {
    return { ...state, isFetching: false };
  },

  [types.ACTIVITY_TIMER_START_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.ACTIVITY_TIMER_START_SUCCESS]: (state, payload) => {
    return { ...state, activeTimer: payload, isFetching: false };
  },

  [types.ACTIVITY_TIMER_TICK]: ({ activeTimer, ...state }) => {
    const timer = { ...activeTimer };

    timer.time++;

    return { ...state, activeTimer: timer };
  },

  [types.ACTIVITY_TIMER_STOP_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.ACTIVITY_TIMER_STOP_SUCCESS]: (state) => {
    return { ...state, activeTimer: null, isFetching: false };
  }
});
