import { Box } from '@material-ui/core';
import { claimInsInfoFieldsMap } from '../fields/fieldsMaps';
import { WidgetActivity } from '../fields/WidgetActivity';

export const ClaimCaseInsInfoActivity = ({
  isDeleted = false,
  isNew = false,
  fieldLabel,
  activityLabel,
  payload
}) => {
  if (isDeleted) {
    return 'Removed Claim Insurance Information';
  }

  return (
    <>
      {isNew && (
        <Box component="span" display="flex" alignItems="center">
          Added new Claim Insurance Information
        </Box>
      )}

      <WidgetActivity
        {...payload?.properties}
        fieldLabel={fieldLabel}
        activityLabel={activityLabel}
        fieldsMap={claimInsInfoFieldsMap}
      />
    </>
  );
};
