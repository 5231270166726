import { Box, IconButton, SvgIcon, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { mdiTagOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import filesize from 'filesize';
import moment from 'moment';
import { useContext } from 'react';
import { useModal } from 'react-modal-hook';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import { Popper } from '../../../../../../components/Popper';
import { Tag } from '../../../../../../components/Tag';
import { Tooltip } from '../../../../../../components/Tooltip';
import { UserLink } from '../../../../../../components/UserLink';
import { apiModelsMap, itemsRoutesByModels } from '../../../../../../dataMaps/apiModels';
import { FileMenu } from '../../../FileMenu';
import { FilePreviewIcon } from '../../../FilePreviewIcon';
import { FilePreviewModal } from '../../../FilePreviewModal';
import { FilesContext } from '../../../FilesContext';
import { columnsWidths } from '../../../FilesList/listConfig';
import { getFileMenuOptions } from '../../../getFileMenuOptions';

export const widthBreakpointLarge = 765;

export const FileRow = ({ file, width, isLoaded }) => {
  const { files, ...filesContext } = useContext(FilesContext);
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      getFileMenuOptions={getFileMenuOptions(filesContext)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        files,
        isFetched: true,
        isFetching: false,
        filter: {
          total: files.length,
          page: 1,
          last_page: 1
        }
      }}
    />
  ), [ file, files ]);

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow>
      <ListRowCell grow actions flexBasis={columnsWidths.name}>
        <FilePreviewIcon file={file} onClick={openFilesPreview} />

        <Box overflow="hidden">
          <Typography noWrap>
            {file.original_filename}
          </Typography>

          {width > widthBreakpointLarge ?
            <Box display="flex" flexWrap="wrap">
              {file.tags && file.tags.map((tag) => (
                <Box key={tag.id} clone m={0.25}>
                  <Tag cutTagLabel tag={tag}/>
                </Box>
              ))}
            </Box>
            :
            !!file?.tags?.length &&
            <Tooltip
              isExistTooltip={!!file?.tags?.length}
              title={
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {file?.tags?.map((tag) => (
                    <Box key={tag.id} component="span" py={0.5} mr={1}>
                      <Tag cutTagLabel tag={tag}/>
                    </Box>
                  ))}
                </Box>
              }
            >
              <Box display="flex" alignItems="center">
                <SvgIcon fontSize="small" color="secondary">
                  <Icon path={mdiTagOutline} />
                </SvgIcon>

                <Typography variant="subtitle2" color="secondary">
                  {file?.tags?.length}
                </Typography>
              </Box>
            </Tooltip>
          }
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {moment.unix(file.created_at).format('L LT')}
      </ListRowCell>

      <ListRowCell actions flexBasis={columnsWidths.creator}>
        {!file.creator ? '-' : (
          <UserLink
            noWrap
            size="md"
            variant="body1"
            user={file.creator}
            path={itemsRoutesByModels[apiModelsMap.user](file.creator.id)}
          />
        )}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.size}>
        {filesize(file.size)}
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton edge="end" buttonRef={anchorRef} onClick={handleToggle}>
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <FileMenu menuList={getFileMenuOptions(filesContext)(file)} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
