import { Box, Grid, FormControlLabel, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import {
  NumberMaskField,
  TextField,
  PayPeriodsSelect,
  Switch,
  CurrencyField
} from '../../../../../components/FormField';
import { payPeriodsMap } from '../../../../../components/FormField/selects/PayPeriodsSelect/payPeriodsMap';

export const PayrollPreferences = () => {
  const { values, setFieldValue } = useFormikContext();
  const isWeeklyOrBiWeekly = [ payPeriodsMap.weekly, payPeriodsMap.biWeekly ].includes(values.payroll?.pay_period);

  const handlePeriodChange = (option) => {
    if (!option) {
      setFieldValue('payroll.pay_period_range.start', null);
      setFieldValue('payroll.pay_period_range.finish', null);
    }
  };

  return (
    <Box mb={7}>
      <Typography gutterBottom variant="h3">Payroll Preferences</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <PayPeriodsSelect
            margin="dense"
            name="payroll.pay_period"
            onChange={handlePeriodChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CurrencyField
            name="rate"
            margin="dense"
            label="Billing rate"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required={!!values.payroll?.pay_period}
            margin="dense"
            name="payroll.pay_period_range.start"
            label={
              `
                Start ${isWeeklyOrBiWeekly ? 'week' : 'month '} day number
                (${isWeeklyOrBiWeekly ? '0 - 6' : '1 - 28'})
              `
            }
            placeholder="Enter start day..."
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {(values.payroll?.pay_period === payPeriodsMap.semiMonthly) &&
            <TextField
              required
              margin="dense"
              name="payroll.pay_period_range.finish"
              label={
                `
                  Finish ${isWeeklyOrBiWeekly ? 'week' : 'month '} day number
                  (${isWeeklyOrBiWeekly ? '0 - 6' : '1 - 28'})
                `
              }
              placeholder="Enter finish day..."
            />
          }
        </Grid>

        <Grid item xs={12} sm={6}>
          <NumberMaskField
            name="payroll.overtime_preferences.day_overtime"
            label="Day overtime (hours)"
            margin="dense"
            placeholder="Enter day overtime..."
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <NumberMaskField
            name="payroll.overtime_preferences.day_double_time"
            label="Day double time (hours)"
            margin="dense"
            placeholder="Enter day double time..."
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <NumberMaskField
            name="payroll.overtime_rate"
            label="Overtime rate"
            margin="dense"
            placeholder="Enter overtime rate..."
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <NumberMaskField
            name="payroll.double_time_rate"
            label="Double time rate"
            margin="dense"
            placeholder="Enter double time rate..."
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <NumberMaskField
            name="payroll.duration_of_lunch"
            label="Lunch duration (minutes)"
            margin="dense"
            placeholder="Enter lunch duration..."
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            label="Lunch is paid"
            control={
              <Switch
                name="payroll.paid_lunch"
                color="primary"
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
