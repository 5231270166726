export const styles = ({ spacing, typography, palette: { grey } }) => ({
  checkbox: {
    color: grey[500]
  },

  mainContent: {
    paddingLeft: spacing()
  },

  actionContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: spacing(2),
    paddingRight: spacing(2),
    border: 0
  },

  headerCell: {
    fontWeight: typography.fontWeightMedium
  }
});
