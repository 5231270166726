import { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import changelogPath from '../CHANGELOG.md';

export const ChangelogPage = () => {
  const [ changelog, setChangelog ] = useState('');

  useEffect(() => {
    fetch(changelogPath).then((response) => response.text()).then((text) => {
      setChangelog(text);
    });
  }, []);

  return changelog && (
    <Box maxWidth={900} m="auto" p={2}>
      <ReactMarkdown source={changelog} />
    </Box>
  );
};
