import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { ChatThreadContextProvider } from '../../ChatThreadContext';
import { MessengerContext } from '../../MessengerProvider';
import { ChatThread } from './ChatThread';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const OpenedChatThread = ({ widgetIsMinimized }) => {
  const classes = useStyles({ widgetIsMinimized });
  const { currentThreadId } = useContext(MessengerContext);

  return !!currentThreadId && (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.body}>
          <ChatThreadContextProvider>
            <ChatThread />
          </ChatThreadContextProvider>
        </div>
      </div>
    </div>
  );
};
