import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const Header = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell flexBasis={columnsWidths.medRecNo}>
          MRN
        </ListRowCell>

        <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
          Patient
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.appointmentDate}>
          Appointment date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.phone}>
          Phone
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.typeIns}>
          Type Ins.
        </ListRowCell>

        <ListRowCell noWrap grow flexBasis={columnsWidths.expirationDay}>
          Expiration Day
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
