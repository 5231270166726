import { Grid } from '@material-ui/core';
import { AppointmentsSelect } from '../../../../../../components/appointments/AppointmentsSelect';
import {
  KeyboardDatePicker,
  OfficesLocationSelect
} from '../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../components/users';

export const ApptInfo = ({ disableApptFiled = false, name = '' }) => {
  return (
    <Grid container spacing={2} alignItems="flex-end">
      {!disableApptFiled &&
        <>
          <Grid item sm={6} xs={12}>
            <UsersSelect
              isDisabled
              name="patient_id"
              label="Patient"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <AppointmentsSelect />
          </Grid>
        </>
      }

      <Grid item sm={6} xs={12}>
        <KeyboardDatePicker
          required
          clearable
          name={name + 'date_collected'}
          label="Collected"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <KeyboardDatePicker
          required
          clearable
          name={name + 'date_resulted'}
          label="Resulted"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <UsersSelect
          required
          params={{ role: 'doctor' }}
          name={name + 'ordered_id'}
          label="Ordered by"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        {!disableApptFiled &&
          <OfficesLocationSelect
            disabled
            name="office_id"
            label="Office / Practice address"
          />
        }
      </Grid>
    </Grid>
  );
};
