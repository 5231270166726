import { useContext } from 'react';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { ImmunizationContext } from '../../ImmunizationProvider';
import { columnsWidths, widthBreakpointSmall, widthBreakpointMedium } from '../List';

export const Header = ({ width }) => {
  const { selectedIDs, allItemsIsSelected, toggleAllItemsSelection } = useContext(ImmunizationContext);

  return (
    <ListRow header>
      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <ListRowCheckbox
          indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
          checked={allItemsIsSelected()}
          onClick={toggleAllItemsSelection}
        />
      }

      <ListRowCell flexBasis={columnsWidths.date}>
        Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.name}>
        Name Immunization
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell grow flexBasis={columnsWidths.note}>
          Note
        </ListRowCell>
      }

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.approved_by}>
        Approved by
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.office}>
        Practice Address/Office
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions} />
    </ListRow>
  );
};
