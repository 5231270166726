import { useEffect, useRef, useState } from 'react';
import { Typography } from '@material-ui/core';
import { api } from '../../../../api';
import * as casesApi from '../../../../api/cases';
import { Autocomplete } from '../../Autocomplete';

const renderOption = (type) => {
  return (
    <Typography>{type?.name}</Typography>
  );
};

export const CaseTypesSelect = (props) => {
  const [ types, setTypes ] = useState([]);
  const cancelFetch = useRef(() => {});

  useEffect(() => {
    casesApi.fetchTypes({
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(({ data }) => {
      setTypes(data);
    });

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <Autocomplete
      label="Case Types"
      placeholder="Search type..."
      options={types}
      renderOption={renderOption}
      getOptionLabel={(type) => type?.name}
      getOptionValue={(type) => type?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}

      {...props}
    />
  );
};
