import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog } from '@material-ui/core';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import * as contactsApi from '../../../../../api/contacts';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { validationSchema, ContactForm } from '../ContactForm';

export const EditContactModal = ({
  DialogProps,
  payload: {
    contact
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return contactsApi.updateContact(values).then((data) => {
      enqueueSnackbar('Contact successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ data: { errors } }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Contact not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...contact,

        contact_id: contact.contact
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<PermContactCalendarOutlinedIcon />}
              onClose={handleModalReject}
            >
              Edit Contact
            </ModalHeader>

            <ModalBody>
              <ContactForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
