import { api } from '../../api';

export const fetchTimer = () => {
  return api.get('/active-timers');
};

export const startTimer = (payload) => {
  return api.post('/active-timers', payload).then(({ data }) => data.data);
};

export const stopTimer = () => api.delete('/active-timers');
