import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Members } from '../../../../../../../../components/Members';
import { VerticalDivider } from '../../../../../../../../components/VerticalDivider';
import { rolesMap } from '../../../../../../../../dataMaps';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Footer = ({ caseItem }) => {
  const theme = useTheme();
  const classes = useStyles();
  const owner = caseItem?.case_users?.find(({ case_role }) => case_role === rolesMap.heading_attorney);
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <div className={classes.root}>
      <div className={classes.group}>
        <Typography className={classes.group__header}>Participants</Typography>

        <div className={classes.group__content}>
          <Members
            isParent
            maxVisible={3}
            owner={owner}
            users={caseItem.case_users || []}
          />
        </div>
      </div>

      {!isMobile && <VerticalDivider />}

      <div className={classes.group}>
        <Typography className={classes.group__header}>Files</Typography>

        <div className={classes.group__content}>
          {caseItem.files_count}
        </div>
      </div>

      {!isMobile && <VerticalDivider />}

      <div className={classes.group}>
        <Typography className={classes.group__header}>Comments</Typography>

        <div className={classes.group__content}>
          {caseItem.comments_count}
        </div>
      </div>
    </div>
  );
};
