import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.LIST_FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.LIST_FETCH_SUCCESS]: (
    { filter, waiting_list, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      waiting_list: data
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_LIST]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.UPDATE_ITEM]: ({ waiting_list, ...state }, payload) => {
    return {
      ...state,

      waiting_list: waiting_list.map((item) => item.id === payload.id ? payload : item)
    };
  },

  [types.DELETE_ITEM]: ({ waiting_list, pagination, filter, ...state }, itemId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      waiting_list: waiting_list.filter(({ id }) => id !== itemId)
    };
  }
});
