import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell flexBasis={columnsWidths.eventNumber}>
          Event #
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.type}>
          Type
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.group}>
          Group
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.description}>
          Description
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.interval}>
          Interval
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.relative}>
          Relative
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
