import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import ListAddIcon from '@material-ui/icons/PlaylistAdd';
import { setTasksLastGlobalAction } from '../../../../store/globalActions';
import * as tasksApi from '../../../../api/tasks';
import { Loader } from '../../../../components/Loader';
import { transformTaskStatusToOption } from '../../../../components/FormField';
import { ModalContainer, ModalBody, ModalFooter, ModalHeader } from '../../../../components/Modal';
import * as taskActionTypes from '../../../../components/tasks/TasksProvider/types';
import { Body } from './Body';
import { validationSchema } from './validationSchema';

export const CreateTaskModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    initialValues = {},
    onListUpdate = () => {},
    onUpdatePosition = () => {}
  }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const addTask = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const estimated_time = moment.duration(`${values.hours || 0}:${values.minutes || 0}`).asSeconds();

    return tasksApi.createTask({
      ...values,

      parent_type: values.parent_id ? 'case' : null,
      estimated_time: estimated_time === 0 ? null : estimated_time
    }).then((task) => {
      dispatch(setTasksLastGlobalAction({ type: taskActionTypes.ADD_TASKS, payload: [ task ] }));
      !onListUpdate
        ? onUpdatePosition({ id: task?.id, newIndex: 0, status: initialValues.status?.id })
        : onListUpdate();
      enqueueSnackbar('Task successfully created', { variant: 'success' });
      handleModalResolve(task);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Task not created', { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      initialValues={{
        name: '',
        users: [],
        parent_id: initialValues.parent,
        status_id: transformTaskStatusToOption(initialValues.status),
        is_checked: false
      }}
      validationSchema={validationSchema}
      onSubmit={addTask}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<ListAddIcon />} onClose={handleModalReject}>
              Add New Task
            </ModalHeader>

            <ModalBody>
              <Body task={initialValues} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
