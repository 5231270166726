import { makeStyles, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../../../components/UserLink';
import { UserPreviewModal } from '../../../../../../../../components/users/UserPreviewModal';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { PreviewCompanyModal } from '../../../../../../Contacts/Companies/PreviewCompanyModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Footer = ({ payment }) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const openUserPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: payment?.payer?.participant?.id }
    });
  };

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: {
        companyID: payment?.payer?.participant_id
      }
    });
  };

  return (
    <div className={classes.root}>
      {payment?.payer?.participant_type === 'company' ?
        <CompanyLink
          noWrap
          size="lg"
          variant="h5"
          company={payment?.payer?.participant}
          onClick={stopPropagation(openPreviewCompanyModal)}
        />
        :
        <UserLink
          noWrap
          size="lg"
          variant="h5"
          user={payment?.payer?.participant}
          onClick={stopPropagation(openUserPreview)}
        >
          <Typography color="textSecondary">
            Payer
          </Typography>
        </UserLink>
      }
    </div>
  );
};
