import { api } from '../../api';

export const fetchImmunizations = (config) => {
  return api.get('/procedure-codes/immunizations', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchImmunization = (id) => {
  return api.get(`/procedure-codes/immunizations/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createImmunization = (data) => {
  return api.post('/procedure-codes/immunizations', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateImmunization = ({ id, ...data }) => {
  return api.put(`/procedure-codes/immunizations/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteImmunization = (id) => {
  return api.delete(`/procedure-codes/immunizations/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
