import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import ReportIcon from '@material-ui/icons/ReportOutlined';
import { useResizeObserver } from '../../../../../../helpers/hooks/useResizeObserver';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { Divider } from '../../../../../../components/Divider';
import { ExpenseContainer } from './ExpenseContainer';
import { TableHeader } from './TableHeader';
import { Card } from './Card';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 52;
export const widthBreakpointXS = 825;
export const widthBreakpointSmall = 900;
export const widthBreakpointMedium = 1200;

export const columnsWidths = {
  name: 200,
  date: 160,
  category: 200,
  notes: 300,
  attachments: 100,
  budgeted: 120,
  amount: 120,
  action: 90
};

export const List = ({
  parentPage,
  expense,
  expenses = [],
  invoice,
  onUpdateMainTotal = () => {},
  onUpdateExpenses = () => {}
}) => {
  const classes = useStyles();
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const [ amount, setAmount ] = useState(0);
  const [ budgeted, setBudgeted ] = useState(0);
  const ExpenseComponent = width < widthBreakpointSmall ? Card : Row;

  useEffect(() => {
    if (expenses.length > 0) {
      setAmount(expenses.reduce((prev, next) => prev + +next.amount, 0).toFixed(2));
      setBudgeted(expenses.reduce((prev, next) => prev + +next.budget, 0).toFixed(2));
    } else {
      setAmount(0);
      setBudgeted(0);
    }
  }, [ expenses ]);

  return (
    <div ref={rootRef} className={classes.mainContent}>
      {!expenses?.length ?
        <Box p={4}>
          <Typography align="center">Expenses not found</Typography>
        </Box>
        :
        <>
          {(width > widthBreakpointSmall) && <TableHeader width={width} />}

          <CustomScrollbars style={{ flexGrow: 1 }}>
            {expenses.map((item, index) => (
              <ExpenseContainer
                key={index}
                index={index}
                parentPage={parentPage}
                expenseID={expense.id}
                expense={item}
                expenses={expenses}
                invoice={invoice}
                width={width}
                onUpdateExpenses={onUpdateExpenses}
                onUpdateMainTotal={onUpdateMainTotal}
                ExpenseComponent={ExpenseComponent}
              />
            ))}
          </CustomScrollbars>

          <Divider />

          <div className={cn(classes.totalRoot, classes.totalRoot_budget)}>
            <Typography className={classes.totalContent}>Budgeted</Typography>

            <Typography className={cn(classes.totalContent, classes.totalContent_budget)}>
              <CurrencyFormat value={budgeted} />

              {invoice &&
                <Tooltip
                  placement="bottom"
                  title={
                    <>
                      You have <CurrencyFormat value={invoice.balance} /> left of the planned amount of expenses
                    </>
                  }
                >
                  <ReportIcon className={classes.totalContent__icon} />
                </Tooltip>
              }
            </Typography>
          </div>

          <div className={classes.totalRoot}>
            <Typography className={classes.totalContent}>Amount</Typography>

            <Typography className={cn(classes.totalContent, classes.totalContent_amount)}>
              <CurrencyFormat value={amount} />
            </Typography>
          </div>
        </>
      }
    </div>
  );
};
