import { Formik, useFormikContext } from 'formik';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Button } from '../../../../Button';
import { CurrencyField } from '../../../../FormField/CurrencyField';
import { Popper, PopperBody, PopperFooter } from '../../../../Popper';
import { Tooltip } from '../../../../Tooltip';
import { IconButton } from '../../../../IconButton';

export const CorrectedTotal = ({ value = null }) => {
  const { setFieldValue } = useFormikContext();

  const correctTotal = (closePopper) => ({ corrected_total }) => {
    setFieldValue('corrected_total', corrected_total);
    closePopper();
  };

  return (
    <Popper
      anchorRenderer={
        ({ anchorRef, handleToggle }) => (
          <Tooltip title="Correct total price">
            <IconButton
              ref={anchorRef}
              edge="end"
              color="info"
              onClick={handleToggle}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )
      }
    >
      {({ handleClose }) => (
        <Formik
          initialValues={{
            corrected_total: value
          }}
          onSubmit={correctTotal(handleClose)}
        >
          {({ isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <PopperBody>
                <CurrencyField
                  autoFocus
                  name="corrected_total"
                  label="Corrected price"
                />
              </PopperBody>

              <PopperFooter>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </PopperFooter>
            </form>
          )}
        </Formik>
      )}
    </Popper>
  );
};
