import { useContext, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../components/CustomScrollbars';
import { InfiniteGridLoader } from '../../../../components/InfiniteLoader';
import { Loader } from '../../../../components/Loader';
import { FaxContext } from '../FaxProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    faxes,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    resetFaxes
  } = useContext(FaxContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetFaxes();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box py={1} pl={1}>
        <Loader loading={!isFetched} render={
          () => !filter.total ? (
            <Box p={3}>
              <Typography align="center">No faxes found</Typography>
            </Box>
          ) : (
            <InfiniteGridLoader
              items={faxes}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={filter}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={380}
              cellHeight={260}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
