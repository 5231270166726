import T from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';

const propTypes = {
  title: T.string,
  content: T.oneOfType([ T.string, T.node ])
};

export const ConfirmationModal = ({
  payload: {
    title = 'Are you sure?',
    content,
    maxWidth = 'xs',
    disableSubmit = false
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  return (
    <Dialog maxWidth={maxWidth} {...DialogProps}>
      {!!title &&
        <DialogTitle>{title}</DialogTitle>
      }

      {!!content &&
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
      }

      <DialogActions>
        <Button onClick={handleModalReject}>
          Cancel
        </Button>

        {!disableSubmit && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalResolve}
          >
            Agree
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = propTypes;
