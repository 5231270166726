import { useContext, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../components/IconButton';
import { Members } from '../../../../../../../components/Members';
import { Loader } from '../../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.officeName}>
        <Typography noWrap>
          {filter?.params?.name || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.companyName}>
        <CompanyLink
          noWrap
          variant="h5"
          company={filter?.params?.company_id}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.users}>
        <Members
          noWrap
          maxVisible={4}
          users={filter?.params.users || []}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.address}>
        {filter?.params?.address || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.email}>
        <Typography noWrap>
          {filter?.params?.email || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
