import { api } from '../api';

export const getEmailRules = (config = {}) => {
  return api.get('/email-rules', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createEmailRule = (config) => {
  return api.post('/email-rules', config)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const editEmailRule = (id, config) => {
  return api.put(`/email-rules/${id}`, config)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateEmailRulesPositions = (id, config) => {
  return api.put(`/email-rules/position/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteEmailRule = (id) => {
  return api.delete(`/email-rules/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
