import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as workClocks from '../../../api/work-clocks';
import { Loader } from '../../Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../Modal';
import { TimeReportForm, initialValues, validationSchema } from '../TimeReportForm';

export const TimeReportCreationModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    initialValues: initialValuesProp = {},
    hiddenFields = []
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSubmit = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (values.is_all_users) {
      delete values.user_id;
    }

    return workClocks.createTimeReport(values).then((report) => {
      enqueueSnackbar('Time report successfully created', { variant: 'success' });
      handleModalResolve(report);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 409) {
        enqueueSnackbar('Time report already exists!', { variant: 'error' });

        return;
      }

      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Something goes wrong!', { variant: 'error' });
    });
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <Formik
        initialValues={{ ...initialValues, ...initialValuesProp }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Create Time Report
            </ModalHeader>

            <ModalBody>
              <TimeReportForm hiddenFields={hiddenFields} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
