import { useContext, useEffect, useRef } from 'react';
import { makeStyles, List as MuiList, Typography, Box } from '@material-ui/core';
import { ScrollbarsCustom } from '../../../../../../components/ScrollbarsCustom';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../components/Loader';
import { TagsContext } from '../../../../files-common';
import { Group } from '../Group';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TagsList = ({ GroupProps }) => {
  const classes = useStyles();
  const {
    isFetched,
    isFetching,
    tags,
    filter,
    resetTags,
    loadNextPage
  } = useContext(TagsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetTags();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef}>
      <div className={classes.root}>
        <Group isLoaded {...GroupProps} />

        <Loader loading={!isFetched} p={2} render={
          () => !filter.total ? (
            <Box p={2}>
              <Typography align="center">No tags found</Typography>
            </Box>
          ) : (
            <MuiList disablePadding>
              <InfiniteListLoader
                items={tags}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Group}
                RowProps={GroupProps}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      </div>
    </ScrollbarsCustom>
  );
};
