import { useContext } from 'react';
import { Loader } from '../../../../../../../../components/Loader';
import { Scrollbars } from '../../../../../../../../components/Scrollbars';
import { Sticky } from '../../../../../../../../components/Sticky';
import { CaseDepositionsContext } from '../CaseDepositionsProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { Box, List as MuiList, Typography } from '@material-ui/core';

export const columnsMap = {
  name: 'name',
  description: 'description',
  dateReceived: 'dateReceived',
  dateResponded: 'dateResponded',
  depositionDate: 'depositionDate',
  actions: 'actions'
};

export const columnsWidths = {
  [columnsMap.name]: 185,
  [columnsMap.description]: 245,
  [columnsMap.dateReceived]: 160,
  [columnsMap.dateResponded]: 160,
  [columnsMap.depositionDate]: 160,
  [columnsMap.actions]: 80
};

export const List = ({ width }) => {
  const { depositions, isFetching } = useContext(CaseDepositionsContext);

  return (
    <Scrollbars style={{ height: '100%' }}>
      <Box pl={2} py={2}>
        <Loader p={2} loading={isFetching} render={
          () => !depositions.length ? (
            <Box p={2}>
              <Typography align="center">No depositions found</Typography>
            </Box>
          ) : (
            <>
              <Sticky>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                {depositions.map((deposition) => (
                  <Row key={deposition.id} deposition={deposition} width={width} />
                ))}
              </MuiList>
            </>
          )}
        />
      </Box>
    </Scrollbars>
  );
};
