export const styles = ({ spacing, palette, typography }) => ({
  dayWrapper: {
    position: 'relative'
  },

  day: {
    width: 36,
    height: 36,
    fontSize: typography.caption.fontSize,
    marginLeft: spacing(0.25),
    color: 'inherit'
  },

  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 2,
    right: 2,
    border: `1px solid ${palette.secondary.main}`,
    borderRadius: '50%'
  },

  nonCurrentMonthDay: {
    color: palette.text.disabled
  },

  highlightNonCurrentMonthDay: {
    '$nonCurrentMonthDay&': {
      color: palette.grey[100]
    }
  },

  highlight: {
    background: palette.primary.main,
    color: palette.common.white
  },

  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  },

  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  }
});
