export const styles = ({ spacing, palette, typography: { pxToRem }, shape: { borderRadius } }) => ({
  currentVersionMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 400,
    padding: spacing(3),
    background: palette.common.white,
    borderRadius
  },

  root: {
    '& .diff-wrapper.diff': {
      backgroundColor: '#fff',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      border: '1px solid black',
      color: 'black',
      fontFamily: 'monospace',
      fontSize: pxToRem(13),
      width: '100%',
      wordBreak: 'break-all'
    },

    '& .diff-wrapper.diff th': {
      fontWeight: 700
    },

    '& .diff-wrapper.diff td, & .diff-wrapper.diff th': {
      borderCollapse: 'separate',
      border: 'none',
      padding: '1px 2px',
      background: '#fff'
    },

    '& .diff-wrapper.diff td a, & .diff-wrapper.diff th a': {
      color: '#000',
      cursor: 'inherit',
      pointerEvents: 'none'
    },

    '& .diff-wrapper.diff thead th': {
      background: '#a6a6a6',
      borderBottom: '1px solid black',
      padding: 4,
      textAlign: 'left'
    },

    '& .diff-wrapper.diff tbody.skipped': {
      borderTop: '1px solid black'
    },

    '& .diff-wrapper.diff tbody.skipped td, & .diff-wrapper.diff tbody.skipped th': {
      display: 'none'
    },

    '& .diff-wrapper.diff tbody th': {
      background: '#cccccc',
      borderRight: '1px solid black',
      textAlign: 'right',
      verticalAlign: 'top',
      width: '4em'
    },

    '& .diff-wrapper.diff tbody th.sign': {
      background: '#fff',
      borderRight: 'none',
      padding: '1px 0',
      textAlign: 'center',
      width: '1em'
    },

    '& .diff-wrapper.diff tbody th.sign.del': {
      background: '#fbe1e1'
    },

    '& .diff-wrapper.diff tbody th.sign.ins': {
      background: '#e1fbe1'
    },

    '& .diff-wrapper.diff.diff-html': {
      whiteSpace: 'pre-wrap'
    },

    '& .diff-wrapper.diff.diff-html .change.change-eq .old, & .diff-wrapper.diff.diff-html .change.change-eq .new': {
      background: '#fff'
    },

    '& .diff-wrapper.diff.diff-html .change .old': {
      background: '#fbe1e1'
    },

    '& .diff-wrapper.diff.diff-html .change .new': {
      background: '#e1fbe1'
    },

    '& .diff-wrapper.diff.diff-html .change ins, & .diff-wrapper.diff.diff-html .change del': {
      fontWeight: 700,
      textDecoration: 'none'
    },

    '& .diff-wrapper.diff.diff-html .change ins': {
      background: '#94f094'
    },

    '& .diff-wrapper.diff.diff-html .change del': {
      background: '#f09494'
    }
  }
});
