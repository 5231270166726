import { flow } from 'lodash';
import { useContext } from 'react';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { hasRole } from '../../../../../utils/hasRole';
import { AppointmentEditModal } from '../../../../../components/appointments';
import { useModal } from '../../../../../components/ModalsProvider';
import { EditImmunizationModal } from '../EditImmunizationModal';
import { ImmunizationContext } from '../ImmunizationProvider';
import { PreviewImmunizationModal } from '../PreviewImmunizationModal';

export const ImmunizationsMenu = ({ immunization, onClose }) => {
  const { openModal } = useModal();
  const { fetchImmunizationReports, deleteImmunizationReport } = useContext(ImmunizationContext);

  const handleDeleteImmunization = () => {
    deleteImmunizationReport(immunization.id);
  };

  const openPreview = () => {
    openModal(PreviewImmunizationModal, {
      payload: {
        immunization,
        onUpdate: fetchImmunizationReports
      }
    });
  };

  const handleUpdateImmunization = () => {
    openModal(EditImmunizationModal, {
      payload: {
        ID: immunization.id,
        onUpdate: fetchImmunizationReports
      }
    });
  };

  const handleUpdateAppointment = () => {
    openModal(AppointmentEditModal, {
      payload: {
        disableAttachments: true,
        appointmentID: immunization.appointment_id
      },
      onModalResolved: () => {
        fetchImmunizationReports();
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={flow(openPreview, onClose)}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {!hasRole('client', 'patient') &&
        <>
          <MenuItem onClick={flow(handleUpdateImmunization, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit Immunization" />
          </MenuItem>

          <MenuItem disabled={!immunization.appointment_id} onClick={flow(handleUpdateAppointment, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit appointment" />
          </MenuItem>

          <MenuItem onClick={flow(handleDeleteImmunization, onClose)}>
            <ListItemIcon>
              <DeleteIcon color="error" fontSize="inherit"/>
            </ListItemIcon>

            <ListItemText primary="Delete" />
          </MenuItem>
        </>
      }
    </List>
  );
};
