export const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 130,
    border: `1px dashed ${palette.grey[200]}`,
    backgroundColor: palette.grey[25],
    padding: spacing(0.5, 1),
    overflow: 'hidden'
  }
});
