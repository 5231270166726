import cn from 'classnames';
import moment from 'moment';
import { makeStyles, Typography, Box, Link } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { getUserFullName } from '../../../../../../helpers/users';
import { stopPropagation } from '../../../../../../helpers/dom';
import { UserAvatar, UserPreviewModal } from '../../../../../../components/users';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { useModal } from '../../../../../../components/ModalsProvider';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Popper } from '../../../../../../components/Popper';
import { Badge } from '../../../../../../components/Badge';
import { Date } from '../../../../../../components/Date';
import { styles } from '../../../../ProfilePage/MedicalInfo/MedicalForms/Documents/SignedUsers/UserAvatar/styles';
import { PrescriptionPreview } from '../../PrescriptionPreview';
import { PrescriptionsMenu } from '../../PrescriptionsMenu';
import { minRowHeight, columnsWidths, columnsMap } from '../List';

const useStyles = makeStyles(styles);

export const Row = ({ prescription, hiddenColumns = [] }) => {
  const { openModal } = useModal();
  const classes = useStyles();

  const openPreview = () => {
    openModal(PrescriptionPreview, {
      payload: { id: prescription.id }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <ListRow button minHeight={minRowHeight} onClick={openPreview}>
      {!hiddenColumns.includes(columnsMap.patient) && (
        <ListRowCell flexBasis={columnsWidths.patient}>
          <UserLink noWrap user={prescription.patient} />
        </ListRowCell>
      )}

      <ListRowCell column grow flexBasis={columnsWidths.name}>
        <Typography noWrap variant="h5">{prescription.name}</Typography>

        <Typography noWrap color="textSecondary">
          {prescription.text}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        <Date disableMoment date={moment(prescription.date).format('L')} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        <Box display="flex" alignItems="center" width="100%">
          <Box mr={1}>
            <Badge
              overlap="circle"
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              badgeContent={
                prescription?.signature?.id
                  ? <CheckCircleIcon className={classes.badgeIcon}/>
                  : <RemoveCircleIcon className={cn(classes.badgeIcon, classes.badgeIcon__red)}/>
              }
            >
              <UserAvatar size="md" user={prescription.provider} />
            </Badge>
          </Box>

          <Link
            noWrap
            onClick={stopPropagation(openUserPreview(prescription?.provider?.id))}
          >
            {getUserFullName(prescription.provider)}
          </Link>
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        <OfficeLink noWrap office={prescription.office} />
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <PrescriptionsMenu prescription={prescription} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
