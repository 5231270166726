import { SvgIcon } from '@material-ui/core';
import { mdiEye, mdiStomach } from '@mdi/js';
import { Icon } from '@mdi/react';
import BloodCellsIcon from '../../../../../../../components/icons/medical/blood-cells-icon.svg';
import BrainIcon from '../../../../../../../components/icons/medical/brain-icon.svg';
import ChestIcon from '../../../../../../../components/icons/medical/chest-icon.svg';
import HearingIcon from '../../../../../../../components/icons/medical/hearing-icon.svg';
import HeartPulseIcon from '../../../../../../../components/icons/medical/heart-pulse-icon.svg';
import LungsIcon from '../../../../../../../components/icons/medical/lungs.svg';
import PsychiatricIcon from '../../../../../../../components/icons/medical/psychiatric-icon.svg';
import ShoulderIcon from '../../../../../../../components/icons/medical/shoulder-icon.svg';
import ThermometerIcon from '../../../../../../../components/icons/medical/thermometer.svg';
import ThyroidGlandIcon from '../../../../../../../components/icons/medical/thyroid-gland-icon.svg';

export const symptomsTypes = {
  constitutional: 'constitutional',
  eyes: 'eyes',
  ears_nose_throat: 'ears_nose_throat',
  cardiovascular: 'cardiovascular',
  respiratory: 'respiratory',
  gastrointestinal: 'gastrointestinal',
  musculoskeletal: 'musculoskeletal',
  skin: 'skin',
  psychiatric: 'psychiatric',
  neurological: 'neurological',
  hematological: 'hematological',
  endocrine: 'endocrine'
};

export const symptomsTypesList = Object.values(symptomsTypes);

export const symptomChildrenMap = {
  none: 'None',
  weight_gain: 'Weight gain',
  weight_loss: 'Weight loss',
  chills: 'Chills',
  fever: 'Fever',
  weakness_fatigue: 'Weakness / Fatigue',
  other: 'Other',
  blurred_vision: 'Blurred vision',
  glasses: 'Glasses',
  contacts: 'Contacts',
  eye_pain: 'Eye pain',
  redness: 'Redness',
  nose_bleeds: 'Nose bleeds',
  ear_ache_or_infection: 'Ear ache or infection',
  ringing_in_ear: 'Ringing in ear',
  hoarseness: 'Hoarseness',
  chest_pain: 'Chest pain',
  swelling_in_legs: 'Swelling in legs',
  shortness_in_breath: 'Shortness in breath',
  palpitations: 'Palpitations',
  shortness_of_breath: 'Shortness of breath',
  wheezing_asthma: 'Wheezing / Asthma',
  frequent_cough: 'Frequent cough',
  heartburn: 'Heartburn',
  vomiting: 'Vomiting',
  nausea: 'Nausea',
  abdominal_pain: 'Abdominal Pain',
  stiffness: 'Stiffness',
  muscle_aches: 'Muscle aches',
  swelling_of_joints_instability: 'Swelling of joints Instability',
  rash_itching: 'Rash itching',
  keloid_scars: 'Keloid scars',
  psoriasis: 'Psoriasis',
  headaches: 'Headaches',
  numbness_tingling_loss_of_sensation_i_any_part_of_body: 'Numbness, tingling, loss of sensation in any part of body',
  dizziness: 'Dizziness',
  poor_balance: 'Poor balance',
  fainting_spells: 'Fainting spells',
  seizures: 'Seizures',
  depression: 'Depression',
  nervousness: 'Nervousness',
  anxiety: 'Anxiety',
  excessive_thirst_or_hunger: 'Excessive thirst or hunger',
  hot_cold_intolerance: 'Hot/cold intolerance',
  hot_flashes: 'Hot Flashes',
  easy_bruising: 'Easy Bruising',
  easy_bleeding: 'Easy Bleeding',
  varicose_veins: 'Varicose veins',
  blood_clots: 'Blood clots'
};

export const symptomsTypesMap = {
  [symptomsTypes.constitutional]: {
    name: 'Constitutional General',
    icon: <SvgIcon><ThermometerIcon /></SvgIcon>,
    children: {
      none: false,
      weight_gain: false,
      weight_loss: false,
      chills: false,
      fever: false,
      weakness_fatigue: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.eyes]: {
    name: 'Eyes',
    icon: <SvgIcon><Icon path={mdiEye} /></SvgIcon>,
    children: {
      none: false,
      blurred_vision: false,
      glasses: false,
      contacts: false,
      eye_pain: false,
      redness: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.ears_nose_throat]: {
    name: 'Ears, Nose, Throat',
    icon: <SvgIcon><HearingIcon /></SvgIcon>,
    children: {
      none: false,
      nose_bleeds: false,
      ear_ache_or_infection: false,
      ringing_in_ear: false,
      hoarseness: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.cardiovascular]: {
    name: 'Cardiovascular',
    icon: <SvgIcon><HeartPulseIcon /></SvgIcon>,
    children: {
      none: false,
      chest_pain: false,
      swelling_in_legs: false,
      shortness_in_breath: false,
      palpitations: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.respiratory]: {
    name: 'Respiratory',
    icon: <SvgIcon><LungsIcon /></SvgIcon>,
    children: {
      none: false,
      shortness_of_breath: false,
      wheezing_asthma: false,
      frequent_cough: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.gastrointestinal]: {
    name: 'Gastrointestinal',
    icon: <SvgIcon><Icon path={mdiStomach} /></SvgIcon>,
    children: {
      none: false,
      heartburn: false,
      vomiting: false,
      nausea: false,
      abdominal_pain: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.musculoskeletal]: {
    name: 'Musculoskeletal',
    icon: <SvgIcon><ShoulderIcon /></SvgIcon>,
    children: {
      none: false,
      stiffness: false,
      muscle_aches: false,
      swelling_of_joints_instability: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.skin]: {
    name: 'Skin',
    icon: <SvgIcon><ChestIcon /></SvgIcon>,
    children: {
      none: false,
      rash_itching: false,
      redness: false,
      keloid_scars: false,
      psoriasis: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.neurological]: {
    name: 'Neurological',
    icon: <SvgIcon><BrainIcon /></SvgIcon>,
    children: {
      none: false,
      headaches: false,
      numbness_tingling_loss_of_sensation_i_any_part_of_body: false,
      dizziness: false,
      poor_balance: false,
      fainting_spells: false,
      seizures: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.psychiatric]: {
    name: 'Psychiatric',
    icon: <SvgIcon><PsychiatricIcon /></SvgIcon>,
    children: {
      none: false,
      depression: false,
      nervousness: false,
      anxiety: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.endocrine]: {
    name: 'Endocrine',
    icon: <SvgIcon><ThyroidGlandIcon /></SvgIcon>,
    children: {
      none: false,
      excessive_thirst_or_hunger: false,
      hot_cold_intolerance: false,
      hot_flashes: false,
      other: { value: false, text: null }
    }
  },
  [symptomsTypes.hematological]: {
    name: 'Hematological',
    icon: <SvgIcon><BloodCellsIcon /></SvgIcon>,
    children: {
      none: false,
      easy_bruising: false,
      easy_bleeding: false,
      varicose_veins: false,
      blood_clots: false,
      other: { value: false, text: null }
    }
  }
};
