import React from 'react';
import { Formik } from 'formik';
import { Box, Button } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeaderActions
} from '../../../../../components/Modal';
import { isPrimary } from '../InsurancesProvider';
import { validationSchema } from '../validationSchema';
import { IsMainInsuranceToggler } from './IsMainInsuranceToggler';
import { InsuranceForm } from './InsuranceForm';

export const AddInsuranceForm = ({
  initialValues,
  user,
  mainPage,
  disableScan = false,
  disableScrollbars = false,
  insuranceInfo,
  addInsurance,
  onClose,
  onCaseItemUpdate = () => {}
}) => {
  return (
    <Formik
      initialValues={{ ...initialValues, mainPage }}
      validationSchema={validationSchema}
      onSubmit={addInsurance}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          {!disableScrollbars && (
            <ModalHeader onClose={onClose}>
              {isPrimary(values.status_type) ? 'Add Main Insurance Info' : 'Add Insurance Info'}

              <ModalHeaderActions disableGutters>
                <IsMainInsuranceToggler />
              </ModalHeaderActions>
            </ModalHeader>
          )}

          <ModalBody disableScrollbars={disableScrollbars}>
            <InsuranceForm
              user={user}
              mainPage={mainPage}
              disableScan={disableScan}
              insurance={{
                subscriber_info: {
                  ...insuranceInfo,
                  address: insuranceInfo.user_address || insuranceInfo.address || user?.home,
                  ...user
                }
              }}
              onCaseItemUpdate={onCaseItemUpdate}
            />
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              )}
            />
          </ModalFooter>
        </Box>
      )}
    </Formik>
  );
};
