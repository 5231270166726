import { FormControlLabel, Grid } from '@material-ui/core';
import { participantsUserRoles } from '../../../../../dataMaps';
import { KeyboardDatePicker, Select, Switch, TextField } from '../../../../FormField';
import { ReminderEventTypesSelect } from '../../events/ReminderEventTypesSelect';

export const CaseReminderForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          required
          name="position"
          label="Reminder #"
          placeholder="Enter number..."
        />
      </Grid>

      <Grid item xs={6}>
        <ReminderEventTypesSelect
          name="type"
          label="Type"
        />
      </Grid>

      <Grid item xs={12}>
        <KeyboardDatePicker
          name="date"
          label="Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item xs={12}>
        <Select
          isMulti
          isClearable
          formattedValue
          name="case_roles"
          label="Case user roles who receive reminders"
          placeholder="Select roles..."
          options={participantsUserRoles}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          required
          rowsMax={5}
          variant="filled"
          name="description"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          label="Update relative reminders"
          control={<Switch name="update_relation_reminders" color="primary" />}
        />
      </Grid>
    </Grid>
  );
};
