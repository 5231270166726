import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Contact } from '../../../../../../components/Contact';
import { isEmptyDeep } from '../../../../../../helpers/isEmptyDeep';
import { getUserFullName } from '../../../../../../helpers/users';
import { generateAddress } from '../../../../../../utils/generateAddress';
import { ReadonlyCheckbox } from '../../../../../../components/ReadonlyCheckbox';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { ExpirationDate } from '../../../../../../components/ExpirationDate';
import { AddressLink } from '../../../../../../components/AddressLink';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { SvgIcon } from '../../../../../../components/SvgIcon';
import { Date } from '../../../../../../components/Date';
import { insuranceTypes } from '../../AddInsuranceInfoModal';

export const Body = ({ insurance }) => {
  const isAddressSame = +insurance?.subscriber_info?.address_same_as_patient > 0;
  const address = !isEmptyDeep(insurance?.subscriber_info?.address)
    ? generateAddress(insurance?.subscriber_info?.address)
    : '';

  return (
    !(insurance?.type_insurance === insuranceTypes.wc ||
        insurance?.type_insurance === insuranceTypes.pi) && (
      <>
        <Grid container spacing={2}>
          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">
              Insurance ID

              {insurance.is_main &&
                <Box component="span" ml={1}>
                  <SvgIcon color="success" fontSize="small">
                    <CheckCircleIcon />
                  </SvgIcon>
                </Box>
              }
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography variant="h5">
              {insurance.insuranceId}
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">
              Group ID
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography variant="h5">
              {insurance.groupId}
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">
              PCP, $
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <CurrencyFormat
              value={insurance?.pcp}
              TypographyProps={{ variant: 'h5' }}
            />
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">
              SPC, $
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <CurrencyFormat
              value={insurance?.spc}
              TypographyProps={{ variant: 'h5' }}
            />
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">
              Financial Class
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography variant="h5">
              {insurance?.financial_class?.code}
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">
              TPL
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            {insurance?.tpl?.id ?
              <CompanyLink company={insurance.tpl} variant="h5" />
              :
              <Typography color="textSecondary">No info.</Typography>
            }
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">
              Effective day
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Date variant="h5" date={insurance?.effective_day} iconFontSize="small" />
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">
              Expiration day
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            {!insurance.expiration_day ?
              <Typography color="textSecondary">No info.</Typography>
              :
              <Typography variant="h5">
                <ExpirationDate date={insurance.expiration_day} />
              </Typography>
            }
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box pt={3} pb={1}>
              <Typography variant="h3">Subscriber Info</Typography>
            </Box>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">
              Subscriber
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography variant="h5">
              {insurance?.subscriber_info?.subscriber}
            </Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            {isAddressSame && (
              <ReadonlyCheckbox
                checked={isAddressSame}
                fullWidth
                noWrap
                iconColor={isAddressSame ? 'success' : 'info'}
                title="Address Same as Patient"
              />
            )}

            {!!address.length && (
              <>
                <Typography gutterBottom color="textSecondary">Address</Typography>

                <AddressLink variant="h5" address={address}>
                  {address}
                </AddressLink>
              </>
            )}
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">Name</Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography variant="h5">
              {getUserFullName(insurance.subscriber_info)}
            </Typography>
          </Grid>

          <Hidden lgDown>
            <Grid item xs={6} />
          </Hidden>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">DOB</Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            {insurance?.subscriber_info?.dob && (
              <Date variant="h5" date={insurance?.subscriber_info?.dob} iconFontSize="small" />
            )}
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">Phone Number</Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Contact
              needIcon
              type="tel"
              variant="h5"
              color="textPrimary"
              contact={insurance?.subscriber_info?.phone_number}
            />
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">Gender</Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography variant="h5">
              {insurance?.subscriber_info?.gender}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ReadonlyCheckbox
              checked={!!insurance.is_icd}
              fullWidth
              noWrap
              variant="h5"
              iconColor="success"
              BoxProps={{ my: 3 }}
              title="ICD-10"
            />
          </Grid>
        </Grid>
      </>
    )
  );
};
