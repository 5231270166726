import { useFormikContext } from 'formik';
import { FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import {
  IcdCodesSelect,
  OfficesLocationSelect,
  RadioGroup,
  TextField
} from '../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../components/users';

export const Body = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleChangeProvider = () => {
    setFieldValue('office_id', null);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <TextField
          name="name"
          label="Enter a name for the template"
          placeholder="Enter name..."
        />
      </Grid>

      <Grid item xs={12}>
        <UsersSelect
          name="provider_id"
          params={{ role: 'doctor' }}
          label="Physician"
          onChange={handleChangeProvider}
        />
      </Grid>

      <Grid item xs={12}>
        <OfficesLocationSelect
          label="Office"
          name="office_id"
          params={{ users: values.provider_id ? [ values.provider_id ] : null }}
        />
      </Grid>

      <Grid item xs={12}>
        <IcdCodesSelect
          multiple
          label="Diagnosis"
          name="icd_code_ids"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <Typography>
          Generic equivalent acceptable:
        </Typography>
      </Grid>

      <Grid item md={6} xs={12}>
        <RadioGroup
          name="options.generic_equivalent_acceptable"
          style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
        >
          <FormControlLabel
            value="yes"
            label="Yes"
            control={<Radio size="small" />}
          />

          <FormControlLabel
            value="no"
            label="No"
            control={<Radio size="small" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item xs={12}>
        <TextField name="options.refill" label="Refill" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          disableUnderline
          variant="filled"
          name="text"
          label="Describe"
          rows={4}
          rowsMax={8}
        />
      </Grid>
    </Grid>
  );
};
