export const styles = ({ spacing, palette, breakpoints, typography: { pxToRem } }) => ({
  paper: {
    minHeight: '100%',
    maxWidth: 400,

    [breakpoints.up('md')]: {
      maxWidth: 520
    },

    marginLeft: 'auto',
    marginRight: 'auto',
    color: palette.primary.contrastText,
    padding: spacing(4, 3),
    backgroundColor: 'rgba(32, 32, 32, 0.75)'
  },

  paper__header: {
    marginTop: spacing(),
    marginBottom: spacing(),
    textAlign: 'center',

    [breakpoints.up('sm')]: {
      marginTop: spacing(8),
      marginBottom: spacing(8)
    }
  },

  paper__icon: {
    width: '100%',
    maxWidth: 200,
    margin: 'auto'
  },

  paper__title: {
    marginTop: spacing(3),
    fontSize: pxToRem(18),

    [breakpoints.up('sm')]: {
      maxWidth: 340,
      marginTop: spacing(8),
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: pxToRem(26)
    }
  },

  submit: {
    marginTop: spacing(3),
    marginBottom: spacing(3)
  },

  input: {
    '& .MuiInputLabel-root, & .MuiInputBase-input': {
      color: palette.common.white
    }
  }
});
