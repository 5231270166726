import { CurrentSymptoms } from '../../../../Diagnosis/steps/FirstStep/CurrentSymptoms';

export const CurrentSimptomsNeck = () => {
  return (
    <CurrentSymptoms
      disableBodyParts
      title="Neck"
      name="forms.medical_form_info.current_symptoms_neck"
    />
  );
};
