import { Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidthsLg, columnsWidthsSm } from '../List';

export const TableHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.tablet));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.code}>
          Code
        </ListRowCell>

        {!isMobile && (
          <ListRowCell flexBasis={columnsWidths.description}>
            Description
          </ListRowCell>
        )}

        <ListRowCell flexBasis={columnsWidths.arrivalType}>
          Arrival Type
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.ordering}>
          {isMobile ? 'ORD' : 'Ordering'}
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
