import { createContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, merge } from 'lodash';
import { api } from '../../../../../api';
import * as statisticsApi from '../../../../../api/statistics';
import { usePrevious } from '../../../../../helpers/hooks';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const UserRegistrationContext = createContext();

export const UserRegistrationProvider = ({
  initialState: initialStateProp = {},
  children
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const {
    isFetched,
    isFetching,
    filter,
    statistics
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchAnalytics = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_USER_REGISTRATION_REQUEST });

    statisticsApi.fetchUserStatistics({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then((data) => {
      dispatch({ type: types.FETCH_USER_REGISTRATION_SUCCESS, payload: data });
    });
  };

  const filterAnalytics = (newFilter) => {
    dispatch({ type: types.FILTER_USER_REGISTRATION, payload: newFilter });

    fetchAnalytics({ ...newFilter });
  };

  const providerValue = {
    isFetched,
    isFetching,
    filter,
    statistics,

    // functions
    filterAnalytics,
    fetchAnalytics
  };

  useEffect(() => {
    if (!isEqual(prevFilter, filter)) {
      filterAnalytics(filter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <UserRegistrationContext.Provider value={providerValue}>
      {children}
    </UserRegistrationContext.Provider>
  );
};
