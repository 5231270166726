import { TextField } from '../../../FormField';
import { ModalBody } from '../../../Modal';
import { UsersSelect } from '../../../users';

export const AddChatModalBody = () => {
  return (
    <ModalBody>
      <TextField
        name="name"
        label="Name"
        placeholder="Case chat"
      />

      <TextField
        multiline
        rows={8}
        name="description"
        label="Description"
        margin="dense"
        variant="filled"
        helperText="What`s this channel about?"
        InputProps={{
          disableUnderline: true
        }}
      />

      <UsersSelect
        required
        multiple
        name="users"
        label="Participants"
      />
    </ModalBody>
  );
};
