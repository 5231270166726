import { emailsFieldsMap } from './fieldsMaps';
import { WidgetActivity } from './WidgetActivity';

export const Emails = ({ payload, fieldLabel, activityLabel }) => {
  return (
    <WidgetActivity
      {...payload?.properties}
      fieldLabel={fieldLabel}
      activityLabel={activityLabel}
      fieldsMap={emailsFieldsMap}
    />
  );
};
