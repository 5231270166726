import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { Date } from '../../../../Date';
import { Divider } from '../../../../Divider';
import { IconButton } from '../../../../IconButton';
import { tracksTypesLabelsMap } from '../../../trackTypesMap';

export const MobileTracks = ({ tracks, onTrackDelete = () => {} }) => {
  const isShow = hasRole(rolesMap.admin, rolesMap.supervisor);

  return tracks?.map((track) => (
    <Grid container spacing={1} key={track.id}>
      <Grid item xs={4}>
        <Typography>Type:</Typography>
      </Grid>

      <Grid item xs={isShow ? 6 : 8}>
        <Typography>
          {tracksTypesLabelsMap[track.type]}
        </Typography>
      </Grid>

      {isShow && (
        <Grid item xs={2}>
          <IconButton
            edge="end"
            title="Delete"
            color="error"
            onClick={onTrackDelete(track.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}

      <Grid item xs={4}>
        <Typography>Start:</Typography>
      </Grid>

      <Grid item xs={8}>
        <Date disableIcon date={track.started_at} format="L LT" />
      </Grid>

      <Grid item xs={4}>
        <Typography>End:</Typography>
      </Grid>

      <Grid item xs={8}>
        <Date disableIcon date={track.finished_at} format="L LT" />
      </Grid>

      <Grid item xs={4}>
        <Typography>Duration:</Typography>
      </Grid>

      <Grid item xs={8}>
        <Box display="flex" alignItems="center">
          <Typography>
            {!track.finished_at ? '-' : moment.duration(
              track.finished_at - track.started_at,
              'seconds'
            ).format('h[h] m[min]')}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Divider gutter={1} />
      </Grid>
    </Grid>
  ));
};
