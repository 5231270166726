import { FileRow } from '../../../Dashboard/files-common/FilesList/FileRow';
import { getFileMenuOptions } from '../../getFileMenuOptions';

export const Row = ({ item: file = {}, isLoaded, ...props }) => {
  return (
    <FileRow
      hideTags
      isLoaded={isLoaded}
      file={file}
      getFileMenuOptions={getFileMenuOptions}

      {...props}
    />
  );
};
