export const filterFieldsMap = {
  patientID: 'patient_id',
  caseID: 'case_id',
  text: 'text'
};

export const filterFieldsLabels = {
  [filterFieldsMap.patientID]: 'Patient',
  [filterFieldsMap.caseID]: 'Case',
  [filterFieldsMap.text]: 'Text'
};
