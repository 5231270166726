import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import { FormContext } from '../../../app/Dashboard/GuestMedicalProfile/FormProvider';
import { MedicalFormItemContext } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalFormItemProvider';
import { AllSchedulesEmptyContent } from '../../Widgets';
import { Divider } from '../../Divider';

export const EmptyFormAppointment = () => {
  const params = useParams();
  const formContext = useContext(FormContext);
  const medicalFormItemContext = useContext(MedicalFormItemContext);
  const context = formContext || medicalFormItemContext;

  const handleBackStep = () => {
    context.handleBack({ dirty: false, id: params.medicalFormID });
  };

  const handleNextStep = () => {
    context.handleComplete(formContext ? context?.form : context?.form?.id);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      <Box flexGrow={1}>
        <AllSchedulesEmptyContent
          text="The page is under construction"
        />
      </Box>

      <Divider gutter={2} />

      <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
        <Button color="primary" onClick={handleBackStep}>
          Back
        </Button>

        <Button variant="contained" color="primary" onClick={handleNextStep}>
          Next
        </Button>
      </Box>
    </Box>
  );
};
