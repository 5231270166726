import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { Tag } from '../../../../../../../components/Tag';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../components/Loader';
import { orderByLabelsMap } from '../../../../FilesFilterContext';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.originalFilename}>
        {params?.original_filename || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.tags}>
        <Box width="100%" display="flex" flexDirection="column">
          {!params.tags?.length > 0 ? '-' : params?.tags?.map((tag) => (
            <Box key={tag?.id} component="span" py={0.5} mr={1}>
              <Tag tag={tag} />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updatedAfter}>
        {params?.updated_after ? moment.unix(params.updated_after).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updatedBefore}>
        {params?.updated_before ? moment.unix(params.updated_before).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.orderBy}>
        {orderByLabelsMap[params?.order_by] || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
