import { Box } from '@material-ui/core';
import { ContentCard } from '../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../components/WidgetsActionButtons';
import { StatisticsIncomesFilterContextProvider } from './StatisticsIncomesFilterContext';
import { MainContent } from './MainContent';

export const Balances = ({
  isOpen,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      isResizable={props?.item?.isResizable}
      title="Income & Expenses"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
    >
      <Box minHeight={height || '100%'}>
        <StatisticsIncomesFilterContextProvider>
          <MainContent/>
        </StatisticsIncomesFilterContextProvider>
      </Box>
    </ContentCard>
  );
};
