import moment from 'moment';
import * as timerApi from '../../../api/time-tracks/active-timer';
import * as actions from './actions';

let interval = null;

export const activityTimerFetch = (payload) => (dispatch) => {
  dispatch(actions.activityTimerFetchRequest());

  return timerApi.fetchTimer(payload).then(({ data: { is_active, timer } }) => {
    if (is_active) {
      timer.time = moment().unix() - timer.started_at;

      dispatch(actions.activityTimerStartSuccess(timer));

      clearInterval(timer);

      interval = setInterval(() => dispatch(actions.activityTimerTick()), 1000);
    } else {
      dispatch(actions.activityTimerFetchSuccess(timer));
    }
  });
};

export const activityTimerStart = (payload) => (dispatch) => {
  dispatch(actions.activityTimerStartRequest());

  return timerApi.startTimer(payload).then((data) => {
    dispatch(actions.activityTimerStartSuccess(data));

    clearInterval(interval);

    interval = setInterval(() => dispatch(actions.activityTimerTick()), 1000);
  });
};

export const activityTimerStop = () => (dispatch) => {
  dispatch(actions.activityTimerStopRequest());

  return timerApi.stopTimer().then(() => {
    dispatch(actions.activityTimerStopSuccess());
    clearInterval(interval);
  });
};
