import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { Loader } from '../../Loader';
import { ScheduleContext, ScheduleEventsFilterContext } from '../../schedule-events';
import { CalendarContext } from '../../schedule-events/Schedule/CalendarProvider';
import { HorizontalDatepickerFilter } from '../ScheduleEventsWidget/HorizontalDatepickerFilter';
import { MainContentCalendar } from './MainContentCalendar';

export const ScheduleEventsWidgetCalendar = ({ width, height = '100%' }) => {
  const { isFetched } = useContext(ScheduleContext);
  const { changeFilter } = useContext(ScheduleEventsFilterContext);
  const { reloadCalendarEvents } = useContext(CalendarContext);
  const { reloadEvents } = useContext(ScheduleContext);

  useEffect(() => {
    reloadEvents({
      started_from: moment().startOf('day').unix(),
      started_to: moment().endOf('day').unix()
    });

    reloadCalendarEvents({
      started_from: moment().startOf('week').unix(),
      started_to: moment().endOf('week').unix()
    });
  }, []);

  useEffect(() => {
    changeFilter({
      started_from: moment().startOf('day').unix(),
      started_to: moment().endOf('day').unix()
    });
  }, []);

  return (
    <Box display="flex" flexDirection="column" height={height} overflow="hidden">
      <HorizontalDatepickerFilter />

      <Box flexGrow={1}>
        <Loader loading={!isFetched} p={3} render={() => <MainContentCalendar width={width} />} />
      </Box>
    </Box>
  );
};
