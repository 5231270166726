export const validationErrorsMap = {
  PUBLIC_ACCESS_EXPIRED: 'PUBLIC_ACCESS_EXPIRED',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  TOKEN_NOT_CONFIRMED: 'TOKEN_NOT_CONFIRMED'
};

export const codesOfTokenConfirmationReason = [
  validationErrorsMap.TOKEN_EXPIRED,
  validationErrorsMap.TOKEN_NOT_CONFIRMED
];
