import { useContext, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../components/TablePagination';
import { Loader } from '../../../../../components/Loader';
import { Cards } from '../../../../../components/Cards';
import { AccessPatientsContext } from '../PatientsProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    patientsList,
    isFetched,
    filter,
    resetAccessPatients
  } = useContext(AccessPatientsContext);
  const scrollElementRef = useRef();

  return (
    <Box height="100%">
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
        <Box p={1}>
          <Loader loading={!isFetched} p={3} render={
            () => !patientsList.length ? (
              <Typography align="center">No users found</Typography>
            ) : (
              <>
                <Cards size={290} list={patientsList} component={Card} />
                <TablePagination pagination={filter} onChange={resetAccessPatients} />
              </>
            )}
          />
        </Box>
      </CustomScrollbars>
    </Box>
  );
};
