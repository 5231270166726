import { api } from '../api';

export const fetchSessions = (config = {}) => {
  return api.get('/loginQueues/sessions', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const createSession = (data) => {
  return api.post('/loginQueues/sessions', data).then(({ data: { data } }) => data).catch((error) => {
    throw error;
  });
};

export const stopSession = (id) => {
  return api.get(`/loginQueues/sessions/${id}/stop`).then(({ data }) => data).catch((error) => {
    throw error;
  });
};

export const updateSession = ({ id, ...data }) => {
  return api.put(`/loginQueues/sessions/${id}`, data).then(({ data }) => data).catch((error) => {
    throw error;
  });
};

export const deleteSession = (id) => {
  return api.delete(`/loginQueues/sessions/${id}`).then(({ data }) => data).catch((error) => {
    throw error;
  });
};
