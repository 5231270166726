import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Radio,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import * as formsApi from '../../../../../../../api/profile';
import { RadioGroup, TextField } from '../../../../../../../components/FormField';
import { Divider } from '../../../../../../../components/Divider';
import { Loader } from '../../../../../../../components/Loader';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import * as types from '../../MedicalFormsProvider/types';
import {
  CheckboxesContent,
  AllergiesHistory,
  FamilyHistory,
  GastroHistory,
  SocialHistory,
  ActionButtons
} from '../../../../../../../components/medical/forms';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PastMedicalHistory = () => {
  const classes = useStyles();
  const params = useParams();
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetched, form, handleNext, handleBack } = useContext(MedicalFormItemContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updatePastMedicalHistoryContent(values).then(({ data }) => {
      dispatchRedux({ type: types.UPDATE_FORM, payload: data });
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleNext(form.id);
    }).catch(({ errors }) => {
      if (errors) {
        errors && setErrors(errors);
        enqueueSnackbar('Form not updated', { variant: 'error' });
      }
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            form_id: form.id,
            forms: {
              past_medical_history: form?.forms?.past_medical_history || {}
            }
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <Grid
                container
                spacing={isSmallScreen ? 2 : 3}
                alignItems="flex-end"
                component={Box}
                pb={3}
              >
                <Grid item xs={12}>
                  <Typography variant={isSmallScreen ? 'h4' : 'h6'} color="secondary">
                    Past medical history
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5">
                    Check if you currently suffer or have previously suffered from:
                  </Typography>
                </Grid>

                <CheckboxesContent />

                <Grid item md={4} xs={12}>
                  <Typography gutterBottom variant="h5">
                    Have you ever had a blood transfusion?
                  </Typography>
                </Grid>

                <Grid item md={8} xs={12}>
                  <RadioGroup
                    row
                    name="forms.past_medical_history.have_you_ever_had_a_blood_transfusion"
                  >
                    <FormControlLabel
                      value="yes"
                      label={<Typography>Yes</Typography>}
                      control={<Radio color="primary" />}
                    />

                    <FormControlLabel
                      value="no"
                      label={<Typography>No</Typography>}
                      control={<Radio color="primary" />}
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5">If yes, when?</Typography>

                  <TextField
                    fullWidth
                    multiline
                    disableUnderline
                    name="forms.past_medical_history.if_yes_when"
                    placeholder="Please describe"
                    margin="dense"
                    variant="filled"
                    rows={3}
                  />
                </Grid>

                <GastroHistory />
                <AllergiesHistory />
              </Grid>

              <SocialHistory />
              <FamilyHistory />
              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
