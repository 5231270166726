import { useContext } from 'react';
import { debounce } from 'lodash';
import { CasesSelect } from '../../../../components/cases/CasesSelect';
import { FiltersBar as FiltersBarComponent } from '../../../../components/FiltersBar';
import { CompaniesAutocomplete, TextField } from '../../../../components/FormField';
import { CategoriesContext } from './CategoriesProvider';

const initialValues = {
  case_id: null,
  name: null,
  company_id: null
};

export const FiltersBar = () => {
  const { applyFilter } = useContext(CategoriesContext);

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
  }, 200);

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.id });
  };

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      border={0}
      initialValues={initialValues}
      fields={[
        <TextField
          name="name"
          label="Category name"
          placeholder="Search..."
          onChange={handleFieldChange('name')}
        />,
        <CasesSelect
          name="case_id"
          label="Filter by case"
          onChange={handleSelectChange('case_id')}
          minWidth={170}
        />,
        <CompaniesAutocomplete
          name="company_id"
          label="Filter by company"
          onChange={handleSelectChange('company_id')}
          minWidth={170}
        />
      ]}
    />
  );
};
