import { useState } from 'react';
import {
  makeStyles,
  Box,
  List,
  Button
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cn from 'classnames';
import { MessageItem } from './MessageItem';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Messages = ({ messages }) => {
  const [ expanded, setExpanded ] = useState(false);
  const classes = useStyles();

  const toggleMore = () => {
    setExpanded((expanded) => !expanded);
  };

  return (
    <Box className={cn(classes.root, { [classes.rootExpanded]: expanded })}>
      <List className={cn(classes.list, { [classes.listExpanded]: expanded })}>
        {messages.map((message) => (
          <MessageItem message={message} key={message.uid} />
        ))}
      </List>

      {messages.length > 3 &&
        <div className={classes.listMore}>
          <Button
            size="small"
            color="primary"
            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            className={classes.listMoreButton}
            onClick={toggleMore}
          >
            show {expanded ? 'less' : 'more'}
          </Button>
        </div>
      }
    </Box>
  );
};
