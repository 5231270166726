export const styles = ({ spacing, breakpoints }) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    paddingTop: spacing(3),
    paddingLeft: spacing(4),
    paddingRight: spacing(3),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      padding: 0,
      paddingTop: spacing(1)
    }
  }
});
