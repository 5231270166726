import { Box, Grid, Link, Typography } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { stopPropagation } from '../../../helpers/dom';
import { useModal } from '../../ModalsProvider';
import { CaseViewModal } from '../../../app/Dashboard/CasesPage/Cases/CaseViewModal';

export const CaseLink = ({ caseItem, ...props }) => {
  const { openModal } = useModal();

  const openPreview = () => {
    openModal(CaseViewModal, {
      payload: { id: caseItem.id }
    });
  };

  return !caseItem?.name ? (
    <Typography>-</Typography>
  ) : (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item>
        <WorkIcon color="primary" />
      </Grid>

      <Grid item xs component={Box} overflow="auto">
        <Link noWrap display="block" onClick={stopPropagation(openPreview)} {...props}>
          {caseItem?.name}
        </Link>
      </Grid>
    </Grid>
  );
};
