import { api } from '../api';
import * as transformHelpers from './transformHelpers';

const transformResponse = (data) => {
  return {
    total_users: data.total_users,
    ...transformHelpers.transformResponse(data.data)
  };
};

export const fetchUserStatistics = (config) => {
  return api.get('/users-statistics', config)
    .then(({ data }) => {
      return transformResponse(data);
    });
};

