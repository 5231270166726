import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import * as companyApi from '../../../../../api/companies';
import { TextField } from '../../../../../components/FormField';
import { formTypesMap } from '../../../../../components/medical/forms';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { BillingAddress } from '../../BillingAddress';
import { Address } from '../../Address';
import { Venue } from '../../Venue';
import { MainInfo } from './MainInfo';
import { Offices } from './Offices';
import { PayrollPreferences } from './PayrollPreferences';

export const CompanyForm = ({ insuranceInfo, onAvatarUrlChange }) => {
  const { values, setFieldValue } = useFormikContext();
  const showPayroll = hasRole(rolesMap.admin, rolesMap.supervisor) || !!values?.id;

  useEffect(() => {
    if (!values?.type_insurance) {
      return;
    }

    companyApi.fetchCompanyTypes().then((data) => {
      const companyType = insuranceInfo.form_type === formTypesMap.private ? 'Insurance' : 'Law Office';

      setFieldValue('company_type_id', data.find(({ name }) => name === companyType));
    });
  }, []);

  return (
    <>
      <MainInfo onAvatarUrlChange={onAvatarUrlChange} />
      <Address title="Address" />
      <BillingAddress disableContactPerson disableBillingCheckbox />

      {showPayroll && !insuranceInfo?.name &&
        <PayrollPreferences />
      }

      <Venue />

      <Offices />

      <Box display="flex" alignItems="center" fontWeight={500} mt={2} mb={0.5}>
        <NotesIcon fontSize="small"/>

        <Box ml={1}>
          <Typography variant="h5">Note</Typography>
        </Box>
      </Box>

      <TextField
        fullWidth
        multiline
        rows={5}
        name="notes"
        variant="filled"
      />
    </>
  );
};
