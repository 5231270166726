import { useContext } from 'react';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { hasRole } from '../../../../../../utils/hasRole';
import { MRIsContext } from '../../MRIsProvider';
import { columnsWidths, widthBreakpointSmall } from '../List';

export const Header = ({ width }) => {
  const { selectedIDs, allItemsIsSelected, toggleAllItemsSelection } = useContext(MRIsContext);

  return (
    <ListRow header>
      {!hasRole('client', 'patient') &&
        <ListRowCheckbox
          indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
          checked={allItemsIsSelected()}
          onClick={toggleAllItemsSelection}
        />
      }

      <ListRowCell grow flexBasis={columnsWidths.name}>
        Name MRI scan/Notes
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.date}>
        Date of MRI
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.provider}>
        Primary Care Physician
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        Office
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions} />
    </ListRow>
  );
};
