import { makeStyles, Typography } from '@material-ui/core';
import { Menu } from '../../Menu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ScreenshotsCard = ({ item: screenshot }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.videoContent}>
        <img
          src={screenshot.url}
          alt={screenshot.original_filename}
          className={classes.videoImage}
        />
      </div>

      <div className={classes.videoInfo}>
        <Typography
          noWrap
          component="div"
          className={classes.videoTitleName}
        >
          Screenshot name
          {screenshot.original_filename}
        </Typography>

        <Menu screenshot={screenshot}/>
      </div>
    </div>
  );
};
