import { useRef, useContext, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Route } from '../../../../../components/router';
import { Loader } from '../../../../../components/Loader';
import { InfiniteGridLoader } from '../../../../../components/InfiniteLoader';
import { FilesContext } from '../../../files-common';
import { RecentlyOpened } from '../RecentlyOpened';
import { Card } from './Card';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Grid = () => {
  const {
    files,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    ...filesContext
  } = useContext(FilesContext);
  const classes = useStyles();
  const scrollElementRef = useRef();

  useEffect(() => {
    filesContext.resetFiles();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
      <div ref={scrollElementRef} className={classes.root}>
        <Route exact path="/files" component={RecentlyOpened} />

        <Loader loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No files found</Typography>
          ) : (
            <>
              <Route exact path="/files">
                <Typography variant="h3" gutterBottom>Files</Typography>
              </Route>

              <InfiniteGridLoader
                items={files}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                CellComponent={Card}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
                cellWidth={200}
                cellHeight={200}
              />
            </>
          )}
        />
      </div>
    </CustomScrollbars>
  );
};
