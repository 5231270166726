import { Box, Typography } from '@material-ui/core';
import { ColorPreview } from '../../../ColorPreview';

export const SingleValue = ({ children: eventType }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" mr={1}>
        <ColorPreview color={eventType.color} />
      </Box>

      <Typography noWrap title={eventType.name}>
        {eventType.name}
      </Typography>
    </Box>
  );
};
