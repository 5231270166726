import { Box } from '@material-ui/core';
import { TooltipContent } from '../../../app/Dashboard/CasesPage/Cases/CaseItem/CaseCard/TooltipContent';
import { Tooltip } from '../../Tooltip';

export const InsuranceClaimsTooltipCounter = ({ caseItem }) => {
  return (
    <Tooltip
      isExistTooltip={!!caseItem?.ins_claims?.length}
      title={
        <Box px={0.5} py={1}>
          {caseItem?.ins_claims?.map((claim, i) => (
            <TooltipContent
              key={claim.id}
              index={i}
              claim={claim}
              length={caseItem?.ins_claims?.length - 1}
            />
          ))}
        </Box>
      }
    >
      <Box
        display="inline-flex"
        alignItems="center"
        px={1}
        borderRadius={50}
        bgcolor="pink"
        color="primary.contrastText"
        cursor="pointer"
      >
        {caseItem?.ins_claims?.length || 0}
      </Box>
    </Tooltip>
  );
};
