import { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { Button, DialogContent, Dialog } from '@material-ui/core';
import { getCroppedImg } from '../../helpers/dom';
import { ModalFooter } from '../Modal';
import { Loader } from '../Loader';

export const ImageCropModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  isModalEntered,
  payload: { minWidth, minHeight, ...payload }
}) => {
  const [ crop, setCrop ] = useState();
  const imageRef = useRef();

  const handleImageLoaded = (image) => {
    imageRef.current = image;

    const isVertical = image.width < image.height;
    const width = isVertical ? image.width : image.height;
    const height = isVertical ? image.width : image.height;

    setCrop((state) => ({
      ...state,
      width,
      height,
      x: image.width / 2 - width / 2,
      y: image.height / 2 - height / 2
    }));

    return false;
  };

  const handleCropChange = (crop) => {
    setCrop(crop);
  };

  const handleCropComplete = (crop) => {
    setCrop(crop);
  };

  const handleCrop = () => {
    if (payload.parentPageUser) {
      handleModalResolve({
        image: imageRef.current,
        fileType: payload.fileType,
        crop
      });
    } else {
      handleModalResolve({
        dataURL: getCroppedImg({
          image: imageRef.current,
          fileType: payload.fileType,
          crop
        })
      });
    }
  };

  return (
    <Dialog {...DialogProps}>
      <DialogContent>
        <Loader loading={!isModalEntered} render={
          () => (
            <ReactCrop
              keepSelection
              src={payload.src}
              crop={crop}
              minWidth={minWidth}
              minHeight={minHeight}
              onImageLoaded={handleImageLoaded}
              onChange={handleCropChange}
              onCropComplete={handleCropComplete}
              onComplete={handleCropComplete}
            />
          )}
        />
      </DialogContent>

      <ModalFooter>
        <Button onClick={handleModalReject}>
          Cancel
        </Button>

        <Button
          autoFocus
          disabled={!crop}
          variant="contained"
          color="primary"
          onClick={handleCrop}
        >
          Crop
        </Button>
      </ModalFooter>
    </Dialog>
  );
};
