import { Dialog } from '@material-ui/core';
import { ModalContainer } from '../../../Modal';
import { actionOptions } from '../../options';
import { RuleTemplatesProvider } from '../RuleTemplatesProvider';
import { Body } from './Body';

export const RulesView = ({
  DialogProps,
  handleModalReject,
  payload: { model, model_id, model_type }
}) => {
  const filter = { model_type, model_id };
  const key = actionOptions.find(({ value }) => (
    value === (model_type === 'case' ? 'attach_case' : 'attach_patient'))
  );

  return (
    <Dialog maxWidth="lg" PaperProps={{ style: { minHeight: 500 } }} {...DialogProps}>
      <RuleTemplatesProvider filter={filter}>
        <ModalContainer>
          <Body
            model_id={model_id}
            model_type={model_type}
            action={{ key, value: model }}
            onClose={handleModalReject}
          />
        </ModalContainer>
      </RuleTemplatesProvider>
    </Dialog>
  );
};
