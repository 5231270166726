import { useState, useContext, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Formik } from 'formik';
import {
  makeStyles,
  AppBar as MuiAppBar,
  List,
  Fab,
  Typography,
  Tabs,
  Tab,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { rolesMap } from '../../dataMaps/rolesMap';
import { useDebounce } from '../../helpers/hooks';
import { hasRole } from '../../utils/hasRole';
import { TagsContext } from '../../app/Dashboard/files-common';
import { CustomScrollbars } from '../CustomScrollbars';
import { Divider } from '../Divider';
import { IconButton } from '../IconButton';
import { InfiniteListLoader } from '../InfiniteLoader';
import { Loader } from '../Loader';
import { AppBar } from '../AppBar';
import { TagsSelect } from '../FormField';
import { useModal } from '../ModalsProvider';
import { CreateFolderModal } from './CreateFolderModal';
import { FoldersList } from './FoldersList';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const tagsTypesMap = {
  my: 0,
  system: 1,
  folders: true
};

export const Tags = ({
  isOpenSidebar = false,
  initialSelectedTags = [],
  selectedTagIds = [],
  toggleSideBar = () => {},
  disableTabs = false,
  tagsType: tagsTypeProp = tagsTypesMap.system,
  withoutAppBar,
  onTagsChange
}) => {
  const { openModal } = useModal();
  const {
    isFetched,
    isFetching,
    tags,
    filter,
    applyFilter,
    loadNextPage,
    addTag,

    ...tagsContext
  } = useContext(TagsContext);
  const [ tagsType, setTagsType ] = useState(tagsTypeProp);
  const [ searchField, setSearchField ] = useState(filter.name);
  const [ selectedTags, setSelectedTags ] = useState(initialSelectedTags);
  const debouncedSearchField = useDebounce(searchField, 600);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const scrollElementRef = useRef();
  const classes = useStyles();
  const isFolders = tagsType === tagsTypesMap.folders;

  const handleTagsSearch = (name, { action }) => {
    if (action === 'input-change') {
      setSearchField(name);
    }
  };

  const handleTypeChange = (event, newValue) => {
    if (tagsType !== newValue) {
      setTagsType(newValue);
    }
  };

  const tagIsSelected = (tag) => {
    return !!selectedTags.find(({ value }) => value === tag?.id.toString());
  };

  const toggleTagSelection = (tag) => {
    setSelectedTags((options) => {
      if (tagIsSelected(tag)) {
        return options.filter(({ value }) => value !== tag?.id.toString());
      }

      return [ {
        value: tag.id.toString(),
        label: tag
      } ].concat(options);
    });
  };

  const handleSelectedTagsChange = (options) => {
    setSelectedTags(options);
  };

  const createFolder = () => {
    openModal(CreateFolderModal, {
      onModalResolved: (folder) => {
        addTag(folder);
      }
    });
  };

  useEffect(() => {
    if (typeof tagsType === 'number') {
      applyFilter({ is_system: tagsType, is_folder: 0 });
    } else {
      applyFilter({ is_system: null, is_folder: 1, parent_id: '' });
    }
  }, [ tagsType ]);

  useEffect(() => {
    if (debouncedSearchField !== filter.name) {
      applyFilter({ name: debouncedSearchField });
    }
  }, [ debouncedSearchField ]);

  useEffect(() => {
    onTagsChange(selectedTags);
  }, [ selectedTags.length ]);

  return (
    <div className={classes.root}>
      {withoutAppBar ? <Divider /> :
        <AppBar className={classes.header}>
          <Typography variant="h3" className={classes.header__title}>
            {isFolders ? 'Folders' : 'Tags'}
          </Typography>

          {(tagsType !== tagsTypesMap.system || hasRole(rolesMap.admin, rolesMap.supervisor)) &&
            <Fab color="primary" onClick={isFolders ? createFolder : tagsContext.createTag}>
              <AddIcon />
            </Fab>
          }

          {isTablet && (
            <Box mr={-2} ml={1}>
              <IconButton onClick={toggleSideBar}>
                {isOpenSidebar ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon color="primary" />}
              </IconButton>
            </Box>
          )}
        </AppBar>
      }

      {!disableTabs &&
        <MuiAppBar className={classes.tagsTabsHeader} position="static">
          <Tabs
            value={tagsType}
            onChange={handleTypeChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab className={classes.tab} value={tagsTypesMap.my} label="My" />
            <Tab className={classes.tab} value={tagsTypesMap.system} label="Global" />
            <Tab className={classes.tab} value={tagsTypesMap.folders} label="Folders" />
          </Tabs>
        </MuiAppBar>
      }

      <Formik initialValues={{}} onSubmit={null}>
        <TagsSelect
          isMulti
          isAsync={false}
          name="name"
          label={null}
          placeholder={`Search ${isFolders ? 'folder' : 'tag'}...`}
          menuIsOpen={false}
          value={selectedTags}
          inputValue={searchField}
          onChange={handleSelectedTagsChange}
          onInputChange={handleTagsSearch}
          TextFieldProps={{
            InputProps: {
              className: cn(
                classes.search,
                {
                  [classes.search_hasValue]: selectedTags.length
                }
              )
            }
          }}
          components={{
            DropdownIndicator: ({ hasValue }) => {
              return hasValue || <SearchIcon fontSize="small" />;
            },

            IndicatorSeparator: () => null
          }}
          promiseWithOptions={null}
        />
      </Formik>

      <Box display="flex" flexGrow={1} py={1}>
        <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
          <Loader loading={!isFetched} render={
            () => !filter.total ? (
              <Typography align="center">
                No {isFolders ? 'folders' : 'tags'} found
              </Typography>
            ) : isFolders ? (
              <FoldersList
                selectedFolderIds={selectedTagIds}
                folders={tags}
                className={classes.folders}
                onFolderToggleSelection={toggleTagSelection}
              />
            ) : (
              <List disablePadding>
                <InfiniteListLoader
                  items={tags}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  RowProps={{
                    tagIsSelected,
                    onTagToggleSelection: toggleTagSelection
                  }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </List>
            )}
          />
        </CustomScrollbars>
      </Box>
    </div>
  );
};
