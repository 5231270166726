import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().min(1).max(255),
  rate: Yup.number().nullable().min(0).max(999999.99),
  company_id: Yup.mixed().nullable(),
  case_id: Yup.mixed().nullable(),
  is_active: Yup.boolean().nullable().required(),
  hours: Yup.number().nullable(),
  minutes: Yup.number().nullable().when('hours', (hours, schema) => {
    return !hours ? schema.positive().required() : schema;
  })
});
