import { Fragment } from 'react';
import { isEqual } from 'lodash';
import { Typography, SvgIcon } from '@material-ui/core';
import { mdiCircleMedium } from '@mdi/js';
import { Icon } from '@mdi/react';

const renderItem = (item) => (
  <Fragment key={item?.name}>
    <SvgIcon fontSize="small">
      <Icon path={mdiCircleMedium} />
    </SvgIcon>
    &nbsp;

    {item?.name && (
      <>
        User
        &nbsp;
        <Typography variant="subtitle1">{item?.name}</Typography>
        &nbsp;
        with email
      </>
    )}

    {item?.email && (
      <>
        &nbsp;
        <Typography variant="subtitle1">{item?.email}</Typography>
      </>
    )}
  </Fragment>
);

export const EmailField = ({ before = null, after = null, fieldLabel }) => {
  if (!before && after?.length) {
    return (
      <>
        Added &nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        {after?.map((item) => renderItem(item))}
      </>
    );
  } else if (before?.length && !after?.length) {
    return (
      <>
        Removed &nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        {before?.map((item) => renderItem(item))}
      </>
    );
  } else if (before?.length && after?.length && !isEqual(before, after)) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        {after?.map((item) => renderItem(item))}
        &nbsp;to&nbsp;
        {before?.map((item) => renderItem(item))}
      </>
    );
  } else {
    return (
      <>
        Empty&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  }
};
