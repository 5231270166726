import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Button, InputAdornment, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { getUserFullName, getUserNameAbbr } from '../../../helpers/users';
import { TextField } from '../../FormField';
import { Avatar } from '../../Avatar';
import { Loader } from '../../Loader';
import { CommentActions } from '../CommentActions';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FormBody = ({
  comment = {},
  parentCommentID = null,
  parentType = null,
  recipientCommentID = null,
  onCancel = () => {},
  replayComment = () => {}
}) => {
  const classes = useStyles();
  const focusTextInput = (inputEl) => inputEl && inputEl.focus();
  const currentUser = useSelector(({ profile }) => profile.user);
  const recipientName = comment.user ? getUserFullName(comment.user) : null;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.isTablet));
  const initialValues = !recipientCommentID ? {} : { reply_comment_id: recipientCommentID };

  return (
    <Formik
      initialValues={{
        text: '',
        parent_type: parentType || 'comment',
        parent_id: parentCommentID,
        ...initialValues
      }}
      onSubmit={replayComment}
    >
      {({ handleSubmit, isSubmitting, dirty, resetForm }) => (
        <form
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <div className={classes.formField}>
            <Avatar
              src={currentUser.avatar}
              alt={getUserNameAbbr(currentUser)}
              className={classes.userImage}
            />

            <TextField
              multiline
              name="text"
              inputRef={focusTextInput}
              InputProps={!isMobile && !!recipientName && {
                startAdornment: (
                  <InputAdornment disableTypography position="start" className={classes.commentUser}>
                    {recipientName}
                  </InputAdornment>
                )
              }}
            />
          </div>

          <CommentActions>
            <Button
              size={isTablet ? 'small' : 'medium'}
              className={classes.cancelBtn}
              onClick={() => onCancel(resetForm)}
            >
              Cancel
            </Button>

            <Loader  surface loading={isSubmitting} render={
              () => (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size={isTablet ? 'small' : 'medium'}
                  disabled={!dirty || isSubmitting}
                >
                  {!recipientCommentID ? 'Add' : 'Reply'}
                </Button>
              )}
            />
          </CommentActions>
        </form>
      )}
    </Formik>
  );
};
