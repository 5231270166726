import { api } from '../api';

export const fetchInsClaims = (config = {}) => {
  return api.get('/ins-claims', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchInsClaim = (id) => {
  return api.get(`/ins-claims/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createInsClaim = (data) => {
  return api.post('/ins-claims', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateInsClaim = ({ id, ...data }) => {
  return api.put(`/ins-claims/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteInsClaim = (id) => {
  return api.delete(`/ins-claims/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const massInsClaims= (IDs) => {
  return api.delete('/ins-claims', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
