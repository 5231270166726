import { WidgetActivity } from '../fields/WidgetActivity';
import { fieldsMap } from './fieldsMap';

export const WorkSessionActivity = ({
  isDeleted = false,
  fieldLabel,
  activityLabel,
  payload
}) => {
  if (isDeleted) {
    return `Removed ${fieldLabel}`;
  }

  return (
    <WidgetActivity
      {...payload?.properties}
      fieldLabel={fieldLabel}
      after={{ ...(payload?.properties?.work_clock || {}), ...(payload?.properties?.after || {}) }}
      activityLabel={activityLabel}
      fieldsMap={fieldsMap}
    />
  );
};
