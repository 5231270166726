import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';
import { Button, makeStyles, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import * as codesApi from '../../../../../../api/codes/payer';
import { setCPTCodesLastGlobalAction } from '../../../../../../store/globalActions';
import { Loader } from '../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../components/Modal';
import { transformClassToOption, transformDataToOption, transformToOption } from '../CodesContext/selectFieldHelpers';
import * as types from '../CodesContext/types';
import { Body, Header } from '../Create';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CopyPayerModal = ({
  DialogProps,
  payload: { codeID, codesContext },
  handleModalReject,
  handleModalResolve
}) => {
  const classes = useStyles();
  const [ initialValues, setInitialValues ] = useState();
  const [ isFetched, setIsFetched ] = useState(false);
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const createCopyCode = (values, { isSubmitting, setErrors, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    values.claim_filing_id = get(values, 'claim_filing_id.value', values.claim_filing_id);
    values.claim_processing = get(values, 'claim_processing.value', values.claim_processing);
    values.company_id = get(values, 'company_id.value', values.company_id);
    values.timely_filling = get(values, 'timely_filling.value', values.timely_filling);
    values.insurance_type_code = get(values, 'insurance_type_code.value', values.insurance_type_code);
    values.provider_id_used = get(values, 'provider_id_used.value', values.provider_id_used);
    values.financial_class_id = get(values, 'financial_class_id.value', values.financial_class_id);
    values.box_17 = get(values, 'box_17.value', values.box_17);
    values.box_31 = get(values, 'box_31.value', values.box_31);
    values.box_33 = get(values, 'box_33.value', values.box_33);
    values.box_24 = get(values, 'box_24.value', values.box_24);
    values.box_32 = get(values, 'box_32.value', values.box_32);

    codesApi.createPayer(values).then((data) => {
      handleModalResolve(data);

      if (codesContext.codes.length + 1 > codesContext.filter.per_page) {
        codesContext.fetchCodes({ page: codesContext.filter.page });
      } else {
        dispatchRedux(setCPTCodesLastGlobalAction({ type: types.ADD_CUSTOM_CODE, payload: data }));
      }

      enqueueSnackbar('Copy successfully created', { variant: 'success' });
    }).catch(({ errors, message }) => {
      setSubmitting(false);

      if (errors) {
        const errorMessage = Object.values(errors);

        errors && setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  useEffect(() => {
    setIsFetched(false);

    codesApi.fetchPayer(codeID).then((data) => {
      data.company_id = data.company && transformDataToOption(data.company);
      data.financial_class && (data.financial_class_id = transformClassToOption(data.financial_class));
      data.claim_filing && (data.claim_filing_id = transformDataToOption(data.claim_filing));
      data.claim_processing && (data.claim_processing = transformToOption(data.claim_processing));
      data.box_17 && (data.box_17 = transformToOption(data.box_17));
      data.box_31 && (data.box_31 = transformToOption(data.box_31));
      data.box_33 && (data.box_33 = transformToOption(data.box_33));
      data.box_24 && (data.box_24 = transformToOption(data.box_24));
      data.box_32 && (data.box_32 = transformToOption(data.box_32));
      data.timely_filling && (data.timely_filling = transformToOption(data.timely_filling));
      data.insurance_type_code && (data.insurance_type_code = transformToOption(data.insurance_type_code));
      data.provider_id_used && (data.provider_id_used = transformToOption(data.provider_id_used));
      data.secondary_e_claims = !!data.secondary_e_claims;
      data.npi_only = !!data.npi_only;
      data.accept_npi = !!data.accept_npi;
      data.name = null;
      data.code = null;

      setInitialValues(data);
      setIsFetched(true);
    }).catch(() => handleModalReject());
  }, []);

  return (
    <Dialog fullScreen={isTablet} maxWidth="lg" {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={initialValues || {}}
        validationSchema={validationSchema}
        onSubmit={createCopyCode}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <Loader loading={!isFetched} className={classes.loader} render={
              () => (
                <>
                  <ModalHeader onClose={handleModalReject}>
                    Copy Payer
                  </ModalHeader>

                  <ModalBody>
                    <Header/>
                    <Body/>
                  </ModalBody>

                  <ModalFooter>
                    <Button type="reset" onClick={handleModalReject}>
                      Cancel
                    </Button>

                    <Loader surface loading={isSubmitting} render={
                      () => (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      )}
                    />
                  </ModalFooter>
                </>
              )}
            />
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
