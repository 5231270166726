import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setWidgets, toggleWidgets } from '../../store/globalUser/actions';
import { updateUserStorage } from '../../store/globalUser/operations';
import { usePrevious } from './usePrevious';

export const useMobileWidgets = ({ page, checkedWidgetsTypes = {} }) => {
  const dispatch = useDispatch();
  const userGlobalStore = useSelector(({ globalUser }) => globalUser);
  const { isFetched } = userGlobalStore;
  const widgetsOptions = userGlobalStore?.data?.mobileLayout;
  const [ checkedWidgets, setCheckedWidgets ] = useState(
    widgetsOptions?.checkedWidgets?.[page] || checkedWidgetsTypes
  );
  const [ isOpen, setIsOpen ] = useState(
    widgetsOptions?.openWidgets?.[page] || checkedWidgetsTypes
  );
  const prevCheckedWidgets = usePrevious(checkedWidgets);
  const prevIsOpen = usePrevious(isOpen);

  const handleUpdateUserStorage = ({
    openWidgets = isOpen,
    newCheckedWidgets = checkedWidgets
  }) => {
    dispatch(updateUserStorage({
      mobileLayout: {
        ...widgetsOptions,

        openWidgets: { ...isOpen, [page]: openWidgets },
        checkedWidgets: { ...checkedWidgets, [page]: newCheckedWidgets }
      }
    }));
  };

  const onReset = () => {
    setCheckedWidgets(checkedWidgetsTypes);
    setIsOpen(checkedWidgetsTypes);
  };

  const toggleItem = (key, value) => {
    const checkedValues = { ...checkedWidgets, [key]: value };

    setCheckedWidgets(checkedValues);
  };

  const onPutItem = (key) => () => {
    const checkedValues = { ...checkedWidgets, [key]: false };

    setCheckedWidgets(checkedValues);
  };

  const handleMaximized = (key) => () => {
    const toggleValues = { ...isOpen, [key]: true };

    setIsOpen(toggleValues);
  };

  const handleMinimize = (key) => () => {
    const toggleValues = { ...isOpen, [key]: false };

    setIsOpen(toggleValues);
  };

  const updateWidgets = () => {
    dispatch(setWidgets({ page, ...checkedWidgets }));
    handleUpdateUserStorage(checkedWidgets);
  };

  const onToggleWidgets = () => {
    dispatch(toggleWidgets({ page, ...isOpen }));
    handleUpdateUserStorage(isOpen);
  };

  useEffect(() => {
    if (isFetched) {
      setCheckedWidgets(widgetsOptions?.checkedWidgets?.[page] || checkedWidgetsTypes);
      setIsOpen(widgetsOptions?.openWidgets?.[page] || checkedWidgetsTypes);
    }
  }, [ isFetched ]);

  useEffect(() => {
    if (isFetched && !isEqual(prevCheckedWidgets, checkedWidgets)) {
      updateWidgets();
    }
  }, [ checkedWidgets, checkedWidgets ]);

  useEffect(() => {
    if (isFetched && !isEqual(prevCheckedWidgets, prevIsOpen)) {
      onToggleWidgets();
    }
  }, [ prevIsOpen, isOpen ]);

  return {
    isOpen,
    isFetched,
    checkedWidgets,

    // functions
    onReset,
    onPutItem,
    toggleItem,
    handleMinimize,
    handleMaximized
  };
};
