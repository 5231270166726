import { commentsActionsMap } from '../activitiesHelpers/commentsActivityMap';
import { getActivityComponent } from './actionTypesMap';

export const commentActionTypesMap = {
  [commentsActionsMap.createComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [commentsActionsMap.updateComment]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [commentsActionsMap.deleteComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [commentsActionsMap.restoreComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isRestored: true })
  ),

  [commentsActionsMap.createCaseComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [commentsActionsMap.updateCaseComment]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [commentsActionsMap.deleteCaseComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [commentsActionsMap.restoreCaseComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isRestored: true })
  ),

  [commentsActionsMap.createTaskComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [commentsActionsMap.updateTaskComment]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [commentsActionsMap.deleteTaskComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [commentsActionsMap.restoreTaskComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isRestored: true })
  ),

  [commentsActionsMap.createScheduleTemplateComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [commentsActionsMap.updateScheduleTemplateComment]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [commentsActionsMap.deleteScheduleTemplateComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [commentsActionsMap.restoreScheduleTemplateComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isRestored: true })
  ),

  [commentsActionsMap.createEventComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [commentsActionsMap.updateEventComment]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [commentsActionsMap.deleteComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [commentsActionsMap.restoreEventComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isRestored: true })
  ),

  [commentsActionsMap.createPrescriptionComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [commentsActionsMap.updatePrescriptionComment]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [commentsActionsMap.deletePrescriptionComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [commentsActionsMap.restorePrescriptionComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isRestored: true })
  ),

  [commentsActionsMap.createProfileComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [commentsActionsMap.updateProfileComment]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [commentsActionsMap.deleteProfileComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [commentsActionsMap.restoreProfileComment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isRestored: true })
  )
};
