import { useContext, useState } from 'react';
import { flow } from 'lodash';
import { useSnackbar } from 'notistack';
import { Icon } from '@mdi/react';
import { mdiFileMoveOutline } from '@mdi/js';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, SvgIcon } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import * as printersApi from '../../../../../../api/printers';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { Loader } from '../../../../../../components/Loader';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Popper } from '../../../../../../components/Popper';
import { FilesFromPrintersContext } from '../../FilesFromPrintersProvider';

export const Menu = ({ file }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { printerId, deleteFiles } = useContext(FilesFromPrintersContext);
  const { openModal } = useModal();

  const deleteFileFromPrinter = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setIsLoading(true);

        printersApi.deleteFilesFromPrinter(printerId, {
          params: {
            files: [ file.name ]
          }
        }).then(() => {
          enqueueSnackbar('File deleted', { variant: 'success' });
          deleteFiles([ file.name ]);
        }).catch(() => {
          enqueueSnackbar('File is not deleted', { variant: 'error' });
        }).finally(() => {
          setIsLoading(false);
        });
      }
    });
  };

  const assignFileToUser = ({ isDeletable = false }) => () => {
    setIsLoading(true);

    printersApi.assignFilesToUser(printerId, {
      files: [ file.name ],
      delete_after_assign: isDeletable
    }).then(() => {
      enqueueSnackbar('File assigned', { variant: 'success' });

      if (isDeletable) {
        deleteFiles([ file.name ]);
      }
    }).catch(() => {
      enqueueSnackbar('File is not assigned', { variant: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <Popper anchorRenderer={
      ({ anchorRef, handleToggle }) => (
        <Loader surface loading={isLoading} render={
          () => (
            <IconButton
              disabled={isLoading}
              color="inherit"
              buttonRef={anchorRef}
              onClick={handleToggle}
            >
              <MoreVert />
            </IconButton>
          )}
        />
      )}
    >
      {({ handleClose }) => (
        <List gutters={2} onClose={handleClose}>
          <ListItem button onClick={flow(handleClose, assignFileToUser({ isDeletable: false }))}>
            <ListItemIcon>
              <FileCopyIcon />
            </ListItemIcon>

            <ListItemText primary="Copy to Files" />
          </ListItem>

          <ListItem button onClick={flow(handleClose, assignFileToUser({ isDeletable: true }))}>
            <ListItemIcon>
              <SvgIcon><Icon path={mdiFileMoveOutline} /></SvgIcon>
            </ListItemIcon>

            <ListItemText primary="Move to Files" />
          </ListItem>

          <ListItem button onClick={deleteFileFromPrinter}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>

            <ListItemText primary="Delete" />
          </ListItem>
        </List>
      )}
    </Popper>
  );
};
