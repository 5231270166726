import { orderDirectionsMap } from '../../../../../components/FormField';

export const initialState = {
  isFetching: false,
  isFetched: false,
  pagination: {
    total: 0,
    last_page: 1
  },
  filter: {
    order_by: 'appointment_at',
    order_direction: orderDirectionsMap.asc,
    page: 1,
    per_page: 15
  },
  waiting_list: []
};
