import { useContext, useEffect } from 'react';
import { AutoSizer } from 'react-virtualized';
import { List as MuiList, makeStyles, Typography } from '@material-ui/core';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { Loader } from '../../../../../../../components/Loader';
import { StatisticsFilterContext } from '../../../StatisticsFilterProvider';
import { PaymentsContext } from '../../../../../../../components/billing/payments';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointXXS = 440;
export const widthBreakpointXS = 600;
export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1150;
export const minRowHeight = 54;
export const columnsWidths = {
  payer: 300,
  paid_date: 100,
  number: 200,
  due_on: 100,
  total: 100,
  amount: 100,
  balance: 100,
  action: 60
};

export const MainContent = ({
  disableInvoicePreview = false,
  isEqual = false,
  isWidgets = false,
  filters = {},
  parentPage
}) => {
  const classes = useStyles();
  const {
    applyFilter,
    payments,
    isFetched,
    meta,
    resetPayments
  } = useContext(PaymentsContext);
  const statisticsFilterContext = useContext(StatisticsFilterContext);

  useEffect(() => {
    if (isWidgets) {
      applyFilter(filters);
    }
  }, [ isEqual, filters ]);

  useEffect(() => {
    resetPayments();
  }, []);

  useEffect(() => {
    statisticsFilterContext && resetPayments(statisticsFilterContext.filter);
  }, [ statisticsFilterContext && statisticsFilterContext.filter ]);

  return (
    <AutoSizer disableHeight className={classes.autoSizer}>
      {({ width }) => (
        <Loader loading={!isFetched} className={classes.loader} render={
          () => (!payments.length ?
            <Typography align="center" className={classes.title}>No payments found</Typography>
            :
            <>
              <TableHeader parentPage={parentPage} width={width} />

              <Scrollbars>
                <MuiList disablePadding className={classes.list}>
                  {payments.map((payment) => (
                    <Row
                      disableInvoicePreview={disableInvoicePreview}
                      key={payment.id}
                      payment={payment}
                      width={width}
                    />
                  ))}
                </MuiList>
              </Scrollbars>

              <TablePagination borderTop disablePerPage pagination={meta} onChange={resetPayments} />
            </>
          )}
        />
      )}
    </AutoSizer>
  );
};
