import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../../helpers/createReduxReducer';

const SET_INVOICE = 'SET_INVOICE';
const RESET_INVOICE = 'RESET_INVOICE';

export const setInvoiceItem = (payload) => ({ type: SET_INVOICE, payload });
export const resetInvoice = () => ({ type: RESET_INVOICE });

const initialState = {};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [SET_INVOICE]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },

  [RESET_INVOICE]: () => {
    return {};
  }
});
