import { Box, Grid, Typography } from '@material-ui/core';
import { SituationalNumber } from '../../../../../../components/claims/ClaimForm/SituationalNumber';
import { transformSituationalNumberForForm } from '../../../../../../components/claims/SituationalNumberFormModal';
import { DiagnosisInfo } from './DiagnosisInfo';
import { InsuranceInfo } from './InsuranceInfo';
import { BillingSection } from './BillingSection';
import { PatientInfo } from './PatientInfo';
import { ClaimInfo } from './ClaimInfo';
import { Appointment } from './TabsContent/Appointment';

export const MainContent = ({ claim, onClaimChange }) => {
  const handleSituationalNumberChange = (situationalNumber) => {
    onClaimChange({ situational_number: transformSituationalNumberForForm(situationalNumber) });
  };

  return (
    <Box pl={2} pr={1} py={1.5}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <PatientInfo claim={claim} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <InsuranceInfo claim={claim} />
        </Grid>

        <Grid item xs={12}>
          <ClaimInfo claim={claim} />
        </Grid>

        <Grid item xs={12}>
          <BillingSection claim={claim} />
        </Grid>

        <Grid item xs={12}>
          <SituationalNumber claim={claim} onChange={handleSituationalNumberChange} />
        </Grid>

        <Grid item lg={3} xs={12}>
          <DiagnosisInfo claim={claim} />
        </Grid>

        <Grid item lg={9} xs={12}>
          <Box p={1}>
            {claim.appointment ? (
              <Appointment key={claim.appointment?.id} appointment={claim.appointment} />
            ) : (
              <Typography align="center">Appointment not added</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
