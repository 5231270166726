import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell noWrap flexBasis={columnsWidths.users}>
        Users
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.cases}>
        Cases
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.company}>
        Company
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
