import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { isEqual, merge, omit } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import * as categoriesApi from '../../../../../api/time-tracks';
import { api } from '../../../../../api';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { CreateTimeTrackCategoryModal } from '../CreateTimeTrackCategoryModal';
import { UpdateTimeTrackCategoryModal } from '../UpdateTimeTrackCategoryModal';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CategoriesContext = createContext();

export const CategoriesProvider = ({ children, initialState: initialStateProp = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { filter, pagination } = state;
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const cancelFetch = useRef(() => {});
  const prevFilter = usePrevious(filter);

  const fetchCategories = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.CATEGORIES_FETCH_REQUEST });

    categoriesApi.fetchCategories({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.CATEGORIES_FETCH_SUCCESS, payload: data });
    });
  };

  const createCategory = () => {
    openModal(CreateTimeTrackCategoryModal, {
      onModalResolved: (category) => {
        dispatch({ type: types.ADD_CATEGORY, payload: category });
        enqueueSnackbar(`Category "${category.name}" successfully added`, { variant: 'success' });
      }
    });
  };

  const updateCategory = (initialValues) => () => {
    openModal(UpdateTimeTrackCategoryModal, {
      payload: { initialValues },
      onModalResolved: (category) => {
        dispatch({ type: types.UPDATE_CATEGORY_IN_LIST, payload: category });
        enqueueSnackbar(`Category "${category.name}" successfully updated`, { variant: 'success' });
      }
    });
  };

  const deleteCategory = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        categoriesApi.deleteCategory(id).then(() => {
          dispatch({ type: types.DELETE_CATEGORY, payload: id });
          enqueueSnackbar('Category successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Category not deleted', { variant: 'error' });
        });
      }
    });
  };

  const activateCategory = (category) => {
    categoriesApi.activateCategory(category.id).then(() => {
      dispatch({ type: types.TOGGLE_ACTIVATE_CATEGORY, payload: category });
      enqueueSnackbar('Category successfully activated', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Category not activated', { variant: 'error' });
    });
  };

  const deactivateCategory = (category) => {
    categoriesApi.deactivateCategory(category.id).then(() => {
      dispatch({ type: types.TOGGLE_ACTIVATE_CATEGORY, payload: category });
      enqueueSnackbar('Category successfully deactivated', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Category not deactivated', { variant: 'error' });
    });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetCategories = (newFilter) => {
    dispatch({ type: types.RESET_CATEGORIES, payload: newFilter });

    fetchCategories({ page: 1, ...newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchCategories({ page: filter.page + 1 });
    }
  };

  const providerValue = {
    ...state,
    meta: {
      ...pagination,
      ...filter
    },

    // functions
    applyFilter,
    loadNextPage,
    createCategory,
    deleteCategory,
    updateCategory,
    resetCategories,
    activateCategory,
    deactivateCategory
  };

  useEffect(() => {
    const newFilter = { ...filter, ...initialStateProp.filter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, initialStateProp.filter ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetCategories();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CategoriesContext.Provider value={providerValue}>
      {children}
    </CategoriesContext.Provider>
  );
};
