import { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../SavedFiltersProvider';
import { IconButton } from '../../../../../IconButton';
import { CardItem } from '../../../../../Cards';

export const Card = ({
  isLoaded,
  CardContent = () => {},
  item: filter = {},
  cache,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const { params } = filter;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    recalculateHeight();
  }, [ isLoaded ]);

  useEffect(() => {
    if (filter.isNew) {
      cache.clearAll();
    }
  }, [ filter?.isNew ]);

  return (
    <Box py={0.5}>
      <CardItem py={1} px={2} pr={3} onClick={handleSetFieldsFromSaved}>
        <Box display="flex">
          <Box display="flex" justifyContent="flex-end" alignItems="flex-start" order={2} mt={-0.5}>
            <IconButton
              edge="end"
              color="error"
              title="Delete filter"
              onClick={stopPropagation(removeSavedFilter)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>

          <CardContent params={params} />
        </Box>
      </CardItem>
    </Box>
  );
};
