import { useMemo } from 'react';
import { AllAppointments } from '../../../../../../../components/appointments/AllAppointments';
import { rolesMap } from '../../../../../../../dataMaps';

export const Appointments = ({ caseItem, onCaseItemUpdate, ...props }) => {
  const patient = useMemo(() => {
    return caseItem?.case_users?.find(({ case_role }) => case_role === rolesMap.applicant)?.user;
  }, []);

  return (
    <AllAppointments patient={patient} {...props} />
  );
};
