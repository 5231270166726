import { truncate } from 'lodash';

export const getReminderGroupTitle = (reminderGroup) => {
  const shortGroupTitle = reminderGroup?.name || reminderGroup?.description
    ? `
      ${truncate(reminderGroup?.name, { length: 32 })}
      (${truncate(reminderGroup?.description || '-', { length: 20 })})
    `
    : '-';
  const groupTitle = reminderGroup?.name || reminderGroup?.description
    ? `${reminderGroup?.name || '-'} (${reminderGroup?.description || '-'})`
    : '-';

  return {
    shortGroupTitle,
    groupTitle
  };
};
