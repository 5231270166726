export const styles = ({
  spacing,
  palette: { grey }
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(1, 2),
    borderBottom: `1px solid ${grey[100]}`,
    zIndex: 1
  },

  actionGroup: {
    display: 'flex',
    alignItems: 'center'
  },

  hide: {
    display: 'none'
  }
});
