import { useEffect, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Slider } from '../../../../../../components/Slider';
import { painLevelColors } from '../BodyPartsModal/painLevelColors';

export const PainContent = ({ title, sliderValue = 0, name, nameValue, marks, sliderProps = {} }) => {
  const { setFieldValue } = useFormikContext();
  const [ colorIndex, setColorIndex ] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangeSlider = (event, newValue) => {
    setFieldValue(name, marks[newValue]?.label);
    setFieldValue(nameValue, newValue);
    setColorIndex(newValue);
  };

  useEffect(() => {
    setColorIndex(+sliderValue);
  }, []);

  return (
    <Box display="flex" flexDirection="column" p={2} pl={isSmallScreen ? 1.5 : 3.5}>
      {title}

      <Box display="flex" m="0 auto" pt={3} width={isSmallScreen ? '90%' : '70%'}>
        <Slider
          value={+sliderValue}
          marks={marks}
          levelColors={painLevelColors}
          colorIndex={colorIndex}
          onChange={handleChangeSlider}
          {...sliderProps}
        />
      </Box>

      <Typography color="error">
        <ErrorMessage name={name} />
      </Typography>
    </Box>
  );
};
