import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardHeader,
  makeStyles,
  Typography,
  IconButton,
  Box,
  Link,
  Divider,
  Collapse
} from '@material-ui/core';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import LaunchIcon from '@material-ui/icons/Launch';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useModal } from '../../../../ModalsProvider';
import { ChatThreadContext } from '../../../ChatThreadContext';
import { getThreadName } from '../../../utils';
import { MessageInput } from '../../../MessageInput';
import { MessengerContext } from '../../../MessengerProvider';
import { ThreadMessagesContextProvider } from '../../../ThreadMessagesContext';
import { ThreadAvatar } from '../../../ThreadAvatar';
import { TypingIndicator } from '../../../TypingIndicator';
import { UpdateChatMembersModal } from '../../../UpdateChatMembersModal';
import { MessageList } from './MessageList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChatThread = () => {
  const classes = useStyles();
  const { openModal, closeModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { currentThreadId, setCurrentThreadId } = useContext(MessengerContext);
  const { isFetched, currentThread, updateCurrentThread } = useContext(ChatThreadContext);
  const [ expanded, setExpanded ] = useState(true);
  const currentUser = useSelector(({ profile }) => profile.user);
  const chatName = getThreadName({ thread: currentThread, currentUser });
  const updateChatMembersModalIdRef = useRef(null);

  const openUpdateChatModal = () => {
    openModal(UpdateChatMembersModal, {
      payload: { thread: currentThread },
      onModalResolved: (data) => {
        enqueueSnackbar('Chat members successfully updated!', { variant: 'success' });
        updateCurrentThread(data);
      }
    });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleThreadClose = () => {
    setCurrentThreadId(null);
  };

  useEffect(() => {
    return () => {
      closeModal(updateChatMembersModalIdRef.current);
    };
  }, [ currentThreadId ]);

  return (
    <>
      {isFetched &&
        <Card className={classes.root}>
          <CardHeader
            className={classes.header}
            classes={{
              action: classes.action,
              content: classes.cardHeaderContent
            }}
            avatar={
              <ThreadAvatar thread={currentThread} className={classes.threadTypeIndicator} />
            }
            title={
              <>
                <Typography noWrap variant="h5" color="inherit">
                  {chatName}
                </Typography>

                {currentThread?.users?.length > 2 &&
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <IconButton color="inherit" className={classes.iconButton} onClick={openUpdateChatModal}>
                      <PeopleOutlineOutlinedIcon style={{ fontSize: 18 }} />
                    </IconButton>

                    <Typography>{currentThread?.users?.length}</Typography>
                  </Box>
                }
              </>
            }
            action={
              <Box display="flex" alignItems="center" width="100%">
                <Link component={RouterLink} color="inherit" to={`/messenger/${currentThreadId}`}>
                  <IconButton color="inherit">
                    <LaunchIcon />
                  </IconButton>
                </Link>

                <IconButton color="inherit" onClick={handleExpandClick}>
                  {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>

                <IconButton color="inherit" onClick={handleThreadClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            }
          />

          <Divider />

          <Collapse unmountOnExit in={expanded}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <ThreadMessagesContextProvider threadId={currentThreadId}>
                <Box height={252}>
                  <MessageList dense threadId={currentThreadId} />
                </Box>

                <TypingIndicator />

                <MessageInput threadId={currentThreadId} />
              </ThreadMessagesContextProvider>
            </Box>
          </Collapse>
        </Card>
      }
    </>
  );
};
