import { Box, Grid, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../../components/UserLink';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {!!params?.users_id?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Users:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.users_id?.map((user) => (
                <Box display="flex" alignItems="center" alignContent="center" width="100%" key={user.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.case_id &&
        <>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                Cases:
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.case_id?.name}
            </Typography>
          </Grid>
        </>
      }

      {params?.company_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Company:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center" alignContent="center">
              <CompanyLink
                noWrap
                variant="h5"
                company={params?.company_id}
              />
            </Box>
          </Grid>
        </>
      }
    </Grid>
  );
};
