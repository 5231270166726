import { useContext, useEffect, useRef } from 'react';
import moment from 'moment';
import * as appointmentsApi from '../../../../../../api/appointments';
import { useModal } from '../../../../../../components/ModalsProvider';
import { FullCalendar, viewTypes } from '../../../../../../components/FullCalendar';
import {
  AppointmentsContext,
  AppointmentViewModal,
  renderAppointmentContent,
  scrollCalendarToFirstAppt,
  transformAppointmentToCalendarEvent
} from '../../../../../../components/appointments';

export const DayView = ({ selectedDate, onAppointmentUpdate, onAppointmentDelete }) => {
  const { openModal } = useModal();
  const calendarRef = useRef();
  const { appointments, isFetched, reloadAppointments, applyFilter } = useContext(AppointmentsContext);

  const handleAppointmentClick = ({ event }) => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: event.extendedProps?.appointment?.id,

        onAppointmentUpdate: () => {
          onAppointmentUpdate();
          reloadAppointments();
        },

        onAppointmentDelete: () => {
          onAppointmentDelete();
          reloadAppointments();
        }
      }
    });
  };

  const handleAppointmentMove = ({ event: { start, end, extendedProps: { appointment } }, revert, newResource }) => {
    const transformedAppointment = {
      id: appointment.id,
      appointment_at: moment(start).unix(),
      time: moment(end).diff(moment(start), 'minutes'),
      appointment_book_id: newResource ? newResource.id : appointment.appointment_book_id
    };

    appointmentsApi.updateAppointment(transformedAppointment).then(() => {
      reloadAppointments();
      onAppointmentUpdate();
    }).catch(revert);
  };

  useEffect(() => {
    scrollCalendarToFirstAppt({ appointments, calendarRef });
  }, [ appointments ]);

  useEffect(() => {
    if (selectedDate) {
      const calendarApi = calendarRef.current.getApi();

      calendarApi.gotoDate(selectedDate.toDate());

      applyFilter({
        appointment_from: selectedDate.startOf('day').unix(),
        appointment_to: selectedDate.endOf('day').unix()
      });
    }
  }, [ selectedDate ]);

  return (
    <FullCalendar
      ref={calendarRef}
      eventDisplay="block"
      dayHeaders={false}
      allDaySlot={false}
      isLoading={!isFetched}
      headerToolbar={null}
      initialView={viewTypes.timeGridDay}
      eventDataTransform={transformAppointmentToCalendarEvent}
      events={appointments}
      eventDrop={handleAppointmentMove}
      eventResize={handleAppointmentMove}
      eventContent={renderAppointmentContent}
      eventClick={handleAppointmentClick}
    />
  );
};
