import { Box, makeStyles, Typography } from '@material-ui/core';
import { caseUserRolesTypesMap } from '../../../dataMaps';
import { preventDefault } from '../../../helpers/dom';
import { getUserFullName } from '../../../helpers/users';
import { Popper } from '../../Popper';
import { UserLink } from '../../UserLink';
import { styles } from './styles';

const useStyle = makeStyles(styles);

export const ParentUsersList = ({ users, MenuComponent, menuProps }) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      {users.map((user) => user && (
        user?.case_role && !user.user ? (
          <Typography color="textSecondary">User deleted</Typography>
        ) : (
          <Popper
            key={user.id}
            placement="left"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <div ref={anchorRef} className={classes.userLink}>
                  <UserLink
                    disableLink={menuProps?.disablePreview || false}
                    disablePreview={menuProps?.disablePreview || false}
                    user={user?.user || user}
                    tooltipTitle={
                      <Box textAlign="center" minWidth={100}>
                        <Typography variant="h5" color="inherit">
                          {getUserFullName(user?.user || user)}
                        </Typography>

                        {user?.case_role ? (
                          <Typography variant="body2">
                            Case Role: {caseUserRolesTypesMap[user?.case_role] || 'No info.'}
                          </Typography>
                        ) : (
                          <Typography variant="body2">Role: {user?.role || 'No info.'}</Typography>
                        )}
                      </Box>
                    }
                    {...(MenuComponent && {
                      onClick: preventDefault(handleToggle)
                    })}
                  />
                </div>
              )}
          >
            {({ handleClose }) => (
              <MenuComponent
                user={user}
                onClose={handleClose}

                {...menuProps}
              />
            )}
          </Popper>
        )
      ))}
    </div>
  );
};
