export const styles = ({
  spacing,
  palette: { grey, darkgreen },
  typography: { fontWeightMedium },
  shape: { borderRadius },
  breakpoints
}) => ({
  footerTitle: {
    color: grey[400],
    padding: spacing(5, 5, 0, 7),

    [breakpoints.down('md')]: {
      padding: spacing(3, 2, 0, 2)
    }
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: spacing(1, 5, 2, 5),

    [breakpoints.down('md')]: {
      flexWrap: 'wrap',
      padding: spacing(1, 2)
    }
  },

  note: {
    marginRight: spacing(2),
    flexGrow: 1,
    height: 150,
    overflow: 'auto',
    backgroundColor: grey[50],
    padding: spacing(2),
    borderRadius,

    [breakpoints.down('md')]: {
      marginRight: 0
    }
  },

  note__title: {
    color: grey[600]
  },

  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 150,
    padding: spacing(2),
    border: `1px dashed ${darkgreen}`,
    borderRadius,

    [breakpoints.up('md')]: {
      width: 384,
      minWidth: 384
    },

    [breakpoints.down('md')]: {
      width: '100%',
      marginTop: spacing(2)
    }
  },

  total__title: {
    color: grey[400],
    fontWeight: fontWeightMedium,
    marginBottom: spacing(1.5)
  },

  total__sum: {
    marginBottom: spacing(1.5)
  },

  totalTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%'
  },

  totalTitle_sum: {
    flexGrow: 0
  },

  total__balance: {
    color: darkgreen
  },

  actionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItem: 'center',
    padding: spacing(1, 5),
    marginBottom: spacing(3)
  },

  actionButton__cancel: {
    color: grey[400],
    marginRight: spacing(2)
  }
});
