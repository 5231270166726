import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { isEqual, merge, omit } from 'lodash';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { ImportCPTCodesModal } from '../ImportCPTCodesModal';
import { usePrevious } from '../../../../../../helpers/hooks';
import { useModal } from '../../../../../../components/ModalsProvider';
import { api } from '../../../../../../api';
import * as codesApi from '../../../../../../api/codes/procedures';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const CodesContext = createContext();

export const CodesContextProvider = ({ initialState: initialStateProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const cptCodesLastGlobalAction = useSelector(({ globalActions }) => globalActions.cptCodesLastGlobalAction);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isFetched,
    isFetching,
    filter,
    pagination,
    codes,
    selectedIDs
  } = state;
  const cancelFetch = useRef(() => {});
  const prevFilter = usePrevious(filter);

  const fetchCodes = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_CODES_REQUEST });

    codesApi.fetchCodes({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_CODES_SUCCESS, payload: data });
    });
  };

  const deleteCustomCode = (code) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        codesApi.deleteCode(code.id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchCodes({ page: filter.page });
          } else if (codes.length - 1 === 0 && filter.page !== 1) {
            fetchCodes({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_CODE_SUCCESS, payload: code });
          }
        });
      }
    });
  };

  const massDeleteCodes = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        codesApi.massDeleteCodes(IDs).then(() => {
          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            fetchCodes({ page: filter.page });
          } else if (codes.length - selectedIDs.length === 0 && filter.page !== 1) {
            fetchCodes({ page: filter.page - 1 });
          }

          dispatch({ type: types.MASS_DELETE, payload: IDs });
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Codes are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const resetCodes = (newFilter) => {
    dispatch({ type: types.RESET_CODES, payload: newFilter });

    fetchCodes({ page: 1, ...newFilter });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const importCodes = () => {
    openModal(ImportCPTCodesModal, {
      onModalResolved: () => {
        resetCodes({ page: 1 });
      }
    });
  };

  const exportCodes = () => {
    codesApi.exportCode().then((data) => data);
  };

  const allItemsIsSelected = () => {
    return codes.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_ITEM_SELECTION, payload: id });
  };

  const toggleAllItemsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_ITEMS_SELECTION });
  };

  useEffect(() => {
    cptCodesLastGlobalAction && dispatch(cptCodesLastGlobalAction);
  }, [ cptCodesLastGlobalAction ]);

  const providerValue = {
    codes,
    isFetched,
    isFetching,
    filter,
    pagination,
    selectedIDs,

    // functions
    allItemsIsSelected,
    toggleItemSelection,
    toggleAllItemsSelection,
    deleteCustomCode,
    massDeleteCodes,
    fetchCodes,
    resetCodes,
    importCodes,
    exportCodes,
    applyFilter
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetCodes(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CodesContext.Provider value={providerValue}>
      {children}
    </CodesContext.Provider>
  );
};
