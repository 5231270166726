import { useEffect, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Sufferings } from '../../../../../../../components/medical/forms';
import { NestedList } from '../../../../../../../components/NestedList';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { GastroHistory } from './GastroHistory';
import { SocialHistory } from './SocialHistory';
import { FamilyHistory } from './FamilyHistory';
import { socialHistoryTypes } from './socialHistoryTypes';

export const PastMedicalHistory = ({ isOpenSwitches, form }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const history = form?.forms?.past_medical_history || {};
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(history) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Past medical history"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Grid container component={Box} p={isSmallScreen ? 1 : 3} spacing={3} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h5">
            Check if you currently suffer or have previously suffered from:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Sufferings sufferings={history?.check_if_you_currently_suffer_or_have_previously_suffered_from} />
        </Grid>

        {history?.have_you_ever_had_a_blood_transfusion && (
          <>
            <Grid item md={4} xs={12}>
              <Typography gutterBottom variant="h5">
                Have you ever had a blood transfusion?
              </Typography>
            </Grid>

            <Grid item md={8} xs={12}>
              <ReadonlyCheckbox
                checked
                title={history?.have_you_ever_had_a_blood_transfusion === socialHistoryTypes.yes ? 'Yes' : 'No'}
              />
            </Grid>
          </>
        )}

        {!!history?.if_yes_when && (
          <>
            <Grid item md={4} xs={12}>
              <Typography variant="h5">If yes, when?</Typography>
            </Grid>

            <Grid item md={8} xs={12}>
              <Typography>{history?.if_yes_when}</Typography>
            </Grid>
          </>
        )}

        <GastroHistory history={history} />
        <SocialHistory history={history} />
        <FamilyHistory history={history} />
      </Grid>
    </NestedList>
  );
};
