export const styles = ({ spacing, palette: { grey } }) => ({
  body: {
    padding: '0 !important'
  },

  loader: {
    padding: spacing(2)
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  },

  top: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2.5),

    '& > * + *': {
      flexShrink: 0,
      marginLeft: spacing(2)
    }
  },

  title: {
    width: 320,
    marginTop: 0,
    marginRight: 'auto'
  },

  bottom: {
    flexGrow: 1,
    border: `1px solid ${grey[400]}`,
    overflow: 'hidden'
  },

  editor: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: `1px solid ${grey[400]}`,

    '& .cke': {
      height: '100%'
    },

    '& .cke_inner': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },

    '& .cke_contents': {
      flexGrow: 1
    },

    '& .cke_bottom': {
      display: 'none'
    }
  }
});
