import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../components/Loader';
import { IconButton } from '../../../../../../../components/IconButton';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { orderByOptions } from '../../../../../files-common';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap>
          {params?.original_filename || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updateAfter}>
        <Typography noWrap>
          {params.updated_after ? moment.unix(params?.updated_after).format('L') : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updateBefore}>
        <Typography noWrap>
          {params.updated_before ? moment.unix(params?.updated_before).format('L') : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.cases}>
        <Box display="flex" flexDirection="column" width="100%">
          {params?.cases?.map((item, index) => (
            <Typography noWrap key={index} title={item.name}>
              {item.name + ' '}
            </Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.tasks}>
        <Box display="flex" flexDirection="column" width="100%">
          {params?.tasks?.map((item, index) => (
            <Typography noWrap key={index} title={item.name}>
              {item.name + ' '}
            </Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.orderBy}>
        {orderByOptions.find(({ value }) => value === params.order_by)?.label}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="start"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

