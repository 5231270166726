import { makeStyles, Typography } from '@material-ui/core';
import { PreviewContent } from '../../../../FilePreviewModal/PreviewWindow/Preview/PreviewContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Preview = ({ version }) => {
  const classes = useStyles();

  return (
    <PreviewContent className={classes.root}>
      {version.diff ?
        <div dangerouslySetInnerHTML={{ __html: version.diff }} />
        :
        <Typography variant="h2" className={classes.currentVersionMessage}>
          This is current version
        </Typography>
      }
    </PreviewContent>
  );
};
