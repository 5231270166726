import { useContext } from 'react';
import {
  List,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import * as authApi from '../../../../../api/appointments/authorization';
import { AuthorizationFormModal, AuthorizationViewModal } from '../../../index';
import { ConfirmationModal } from '../../../../ConfirmationModal';
import { useModal } from '../../../../ModalsProvider';
import { AuthorizationsContext } from '../../AuthorizationsProvider';

export const Menu = ({ authorization, onClose }) => {
  const { openModal } = useModal();
  const { updateAuthorization, deleteAuthorization, resetAuthorizations } = useContext(AuthorizationsContext);

  const handleAuthorizationView = () => {
    openModal(AuthorizationViewModal, {
      payload: {
        authorization
      },
      onModalRejected: () => {
        onClose();
      }
    });
  };

  const handleAuthorizationUpdate = () => {
    openModal(AuthorizationFormModal, {
      payload: {
        authorization
      },
      onModalResolved: (authorization) => {
        updateAuthorization(authorization);
        onClose();
      },
      onModalRejected: () => {
        onClose();
      }
    });
  };

  const handleAuthorizationDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        authApi.deleteAuthorization(authorization.id).then(() => {
          deleteAuthorization(authorization.id);
          resetAuthorizations();
        });
      }
    });

    onClose();
  };

  return (
    <List>
      <MenuItem onClick={handleAuthorizationView}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      <MenuItem onClick={handleAuthorizationUpdate}>
        <ListItemIcon>
          <EditIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={handleAuthorizationDelete}>
        <ListItemIcon>
          <DeleteIcon fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
