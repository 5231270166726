import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { makeStyles, Box, Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import {
  InsuranceInfo as InjuryInsuranceInfo
} from '../../../../../../../components/medical/forms/personalInjury';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { getHeightFormat } from '../../../../../../../helpers/generateHeight';
import { measureTypes } from '../../../../../../../helpers/measureDataMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import {
  KeyboardDatePicker,
  TextField
} from '../../../../../../../components/FormField';
import {
  Body,
  InsuranceInfo
} from '../../../../../../../components/medical/forms/privatePacket';
import {
  formTypesMap,
  Measurements,
  userValidationSchema,
  generateInitialValues,
  formValidationSchema,
  InitialFormContent,
  generatePrivateInitialValues,
  ActionButtons
} from '../../../../../../../components/medical/forms';
import { Divider } from '../../../../../../../components/Divider';
import { Loader } from '../../../../../../../components/Loader';
import { UserContext } from '../../../../../Members/User/UserProvider';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const GeneralInfo = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const userContext = useContext(UserContext);
  const {
    form,
    isFetched,
    type,
    createFirstStep,
    updateForm,
    parentRoute
  } = useContext(MedicalFormItemContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const hasRoleForEdit = hasRole(rolesMap.doctor, rolesMap.medic, rolesMap.admin, rolesMap.supervisor);
  const hasRoleToConfirm = !hasRole(rolesMap.advocate, rolesMap.patient);

  const handleBack = () => () => {
    history.push(parentRoute);
  };

  const handleCreateStep = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.emergency_contact = values?.emergency_contact?.map(({ emergency_name, ...item }) => item);
    values.measurements.height = getHeightFormat({
      isFeet: values.measurements.height_unit === measureTypes.feet,
      isTransform: true
    })(`${values.measurements.height}`);

    createFirstStep({
      updateFirstStep: true,
      isEditableProfile: !!userContext,
      values,
      setErrors,
      setSubmitting
    });
  };

  const handleUpdateStep = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.id = form.id || params.medicalFormID;
    values.emergency_contact = values?.emergency_contact?.map(({ emergency_name, ...item }) => item);
    values.measurements.height = getHeightFormat({
      isFeet: values.measurements.height_unit === measureTypes.feet,
      isTransform: true
    })(`${values.measurements.height}`);

    updateForm({
      updateFirstStep: true,
      isEditableProfile: !!userContext,
      values,
      setErrors,
      setSubmitting
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            ...generateInitialValues({ type, initialValues: form }),
            ...generatePrivateInitialValues(form),
            auto_accident_country: null,
            is_editable: !!form?.medical?.id || !!form.form_type,
            is_patient: hasRole(rolesMap.patient),
            is_confirmed: hasRoleToConfirm && (form?.is_confirmed || !!form?.medical?.id || !!form?.patient?.id),
            isConfirmed: hasRoleToConfirm && (form?.is_confirmed || !form?.medical?.id) && !hasRoleForEdit
          }}
          validationSchema={userValidationSchema.concat(formValidationSchema)}
          onSubmit={form.form_type ? handleUpdateStep : handleCreateStep}
        >
          {({ values, handleSubmit }) => (
            <form noValidate className={classes.root} onSubmit={handleSubmit}>
              <div className={classes.mainContent}>
                {type !== formTypesMap.private &&
                  <Grid container spacing={isMobile ? 1 : 3}>
                    <InitialFormContent type={type} />
                  </Grid>
                }

                <Box py={2}>
                  <Grid container spacing={isMobile ? 1 : 3}>
                    <Body isUnconfirmed={!form?.medical?.id} />
                  </Grid>
                </Box>

                <Box py={2}>
                  <Grid container spacing={isMobile ? 1 : 3}>
                    {type === formTypesMap.private
                      ? <InsuranceInfo isUnconfirmed={!form?.medical?.id && !values?.patient?.id} />
                      : <InjuryInsuranceInfo isUnconfirmed={!form?.medical?.id && !values?.patient?.id} />
                    }
                  </Grid>
                </Box>

                <Grid item xs={12}>
                  <Typography className={classes.questionTitle}>
                    What is the reason for your visit today?
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    disableUnderline
                    rows={5}
                    variant="filled"
                    name="description"
                  />
                </Grid>

                <Measurements />

                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={isMobile ? 12 : 4}>
                    <KeyboardDatePicker
                      clearable
                      name="hearing_date"
                      label="Hearing Date"
                      outputFormat="YYYY-MM-DD"
                      margin="dense"
                    />
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 4}>
                    <KeyboardDatePicker
                      clearable
                      name="vision_date"
                      label="Vision Rx Date"
                      outputFormat="YYYY-MM-DD"
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </div>

              <Divider gutter={2} />
              <ActionButtons buttonTilte="Close" onBack={handleBack} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
