export const styles = ({
  spacing,
  palette: { grey, primary, text },
  typography: { fontWeightMedium },
  transitions
}) => ({
  root: {
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      backgroundColor: 'rgba(0, 188, 212, 0.05)',
      borderBottom: `1px solid ${primary.main}`
    }
  },

  root_casePage: {
    paddingLeft: spacing(2)
  },

  taskInfo: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },

  checkbox: {
    alignSelf: 'center',
    color: grey[400]
  },

  taskCard: {
    color: text.caption,
    cursor: 'pointer',
    width: '100%'
  },

  taskName: {
    fontWeight: fontWeightMedium,
    marginBottom: spacing(0.25)
  },

  mainInfoCell: {
    display: 'flex',
    alignItems: 'center'
  },

  attachmentIndicator: {
    marginRight: spacing(),

    '&:last-of-type': {
      marginRight: spacing(2)
    }
  },

  attachmentIndicator__taskIcon: {
    marginRight: 0
  }
});
