import { useFormikContext } from 'formik';
import {
  TextField,
  OfficesLocationSelect,
  ApptTypesSelect
} from '../../../FormField';
import { UsersSelect } from '../../../users';

export const ApptBookForm = () => {
  const { values } = useFormikContext();

  return (
    <>
      <TextField
        required
        name="appointment_book_name"
        label="Appointment book name"
        placeholder="Enter book name..."
        margin="dense"
      />

      <UsersSelect
        required
        isCreatable
        isCustomerModal
        formattedValue={false}
        isDisabled={values?.disableProvider}
        name="provider_id"
        label="Provider"
        margin="dense"
        params={{ role: 'doctor' }}
        creatablePayload={{ role: 'doctor', disableRoleField: true }}
      />

      <OfficesLocationSelect
        required
        isCreatable
        name="office_id"
        label="Office"
        margin="dense"
        params={{
          users: values?.provider_id?.id
            ? [ values?.provider_id?.id ]
            : null
        }}
        companyPayload={values?.provider_id?.work?.company}
      />

      {values?.showTypesSelect && (
        <ApptTypesSelect
          disabled
          name="default_appointment_type_id"
          label="Default appointment type"
        />
      )}
    </>
  );
};
