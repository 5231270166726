import { Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { columnsWidths } from '../columnsWidths';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell grow flexBasis={columnsWidths.providers}>
        <Typography noWrap variant="h5">
          Providers
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.offices}>
        <Typography noWrap variant="h5">
          Offices
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.apptBooks}>
        <Typography noWrap variant="h5">
          Appt. Books
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.patients}>
        <Typography noWrap variant="h5">
          Patients
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.arrivalStatuses}>
        <Typography noWrap variant="h5">
          Arrival Statuses
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.appointmentTypes}>
        <Typography noWrap variant="h5">
          Appointment Types
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.visitReasons}>
        <Typography noWrap variant="h5">
          Visit Reasons
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
