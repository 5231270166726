export const filterFieldsMap = {
  name: 'name',
  is_system: 'is_system',
  is_active: 'is_active'
};

export const filterFieldsLabels = {
  [filterFieldsMap.name]: 'Name',
  [filterFieldsMap.is_system]: 'Is system',
  [filterFieldsMap.is_active]: 'Is active'
};
