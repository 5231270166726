import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Dialog, Button, IconButton, InputAdornment } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import { getFileNameWithoutExtension } from '../../../../helpers/files';
import { Yup } from '../../../../utils/validation';
import { setFilesLastGlobalAction } from '../../../../store/globalActions';
import * as filesApi from '../../../../api/files';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../components/Modal';
import { Loader } from '../../../../components/Loader';
import { VerticalDivider } from '../../../../components/VerticalDivider';
import { TextField } from '../../../../components/FormField';
import * as filesActionTypes from '../FilesContext/types';

export const FileCopingConflictModal = ({
  DialogProps,
  payload: file,
  handleModalResolve,
  handleModalReject
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const initialName = getFileNameWithoutExtension(file.original_filename);

  const resetFileName = (setFieldValue) => () => {
    setFieldValue('original_filename', initialName);
  };

  const copyFile = ({ original_filename }, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    file.original_filename = original_filename + '.' + file.extension;

    return filesApi.copyFile(file).then((data) => {
      enqueueSnackbar(
        `File "${data.original_filename}" successfully copied.`,
        { variant: 'success' }
      );
      dispatch(setFilesLastGlobalAction({ type: filesActionTypes.ADD_FILES, payload: [ data ] }));
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 409) {
        setErrors({
          original_filename: 'File with same name is now exist in selected destination'
        });
      } else if (status === 422) {
        setErrors(errors);
      }
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ ...file, original_filename: initialName }}
        validationSchema={Yup.object().shape({ original_filename: Yup.string().required() })}
        onSubmit={copyFile}
      >
        {({ values: { original_filename }, handleSubmit, setFieldValue, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader icon={<FileCopyIcon />} onClose={handleModalReject}>
              Copy file
            </ModalHeader>

            <ModalBody>
              <TextField
                name="original_filename"
                label="File name"
                placeholder="Enter file name..."
                margin="dense"
                InputProps={{
                  autoFocus: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <VerticalDivider />

                      <IconButton
                        title="Reset"
                        disabled={original_filename === initialName}
                        onClick={resetFileName(setFieldValue)}
                      >
                        <SettingsBackupRestoreIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Copy
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
