import { Fragment, useContext } from 'react';
import moment from 'moment';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import { stopPropagation } from '../../../../../../helpers/dom';
import { generateAddress } from '../../../../../../utils/generateAddress';
import { UserPreviewModal } from '../../../../../../components/users';
import { sidesOptions } from '../../../../../../components/appointments/procedures';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { useModal } from '../../../../../../components/ModalsProvider';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../components/Tooltip';
import { ReportsPreviewContext } from '../../../ReportsPreviewProvider';

export const MainContent = () => {
  const { openModal } = useModal();
  const { selectedItem: procedure, appointment } = useContext(ReportsPreviewContext);
  const side = sidesOptions.find(({ value }) => value === procedure?.side)?.label;
  const levels = procedure?.levels?.map((item) => item).join(', ');

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return <>
    <Grid container spacing={2} alignItems="center" component={Box} pt={3}>
      <Grid item sm={2} xs={6}>
        <Typography color="textSecondary">Patient</Typography>
      </Grid>

      <Grid item sm={4} xs={6}>
        <UserLink
          user={appointment.patient}
          variant="h5"
          onClick={stopPropagation(openUserPreview(appointment?.patient?.id))}
        />
      </Grid>

      <Grid item sm={2} xs={6}>
        <Typography color="textSecondary">Provider</Typography>
      </Grid>

      <Grid item sm={4} xs={6}>
        <UserLink
          user={appointment?.appointment_book?.provider}
          variant="h5"
          onClick={stopPropagation(openUserPreview(appointment?.appointment_book?.provider?.id))}
        />
      </Grid>

      <Grid item sm={2} xs={6}>
        <Typography color="textSecondary">Appt. Date From</Typography>
      </Grid>

      <Grid item sm={4} xs={6}>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <CalendarIcon fontSize="small" />
          </Box>

          <Typography variant="h5">
            {appointment.appointment_at ? moment.unix(appointment.appointment_at).format('L, LT') : '-'}
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={2} xs={6}>
        <Typography color="textSecondary">Office</Typography>
      </Grid>

      <Grid item sm={4} xs={6}>
        <Tooltip
          isExistTooltip={!!appointment?.office}
          title={appointment?.office?.full_address || generateAddress(appointment?.office)}
        >
          <OfficeLink variant="h5" office={appointment?.office} />
        </Tooltip>
      </Grid>

      <Grid item sm={2} xs={6}>
        <Typography color="textSecondary">Appt. Date To</Typography>
      </Grid>

      <Grid item sm={4} xs={6}>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <CalendarIcon fontSize="small" />
          </Box>

          <Typography variant="h5">
            {appointment.appointment_at
              ? moment.unix(appointment.appointment_at).add(appointment.time, 'm').format('L, LT')
              : '-'
            }
          </Typography>
        </Box>
      </Grid>
    </Grid>

    <Grid container spacing={2} alignItems="flex-start" component={Box} py={5}>
      <Grid item sm={2} xs={6}>
        <Typography color="textSecondary">Type Insurance</Typography>
      </Grid>

      <Grid item sm={10} xs={6}>
        <Typography variant="h5">
          {appointment?.patient_insurance?.type_insurance}
        </Typography>
      </Grid>

      <Grid item sm={2} xs={6}>
        <Typography color="textSecondary">Insurance ID</Typography>
      </Grid>

      <Grid item sm={10} xs={6}>
        <Typography variant="h5">
          {appointment?.patient_insurance?.insuranceId}
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={2} component={Box} pb={5}>
      <Grid item sm={2} xs={6}>
        <Typography variant="h5">Side</Typography>
      </Grid>

      <Grid item sm={2} xs={6}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">
            {side || '-'}
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={2} xs={6}>
        <Typography variant="h5">Levels</Typography>
      </Grid>

      <Grid item sm={2} xs={6}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">
            {levels || '-'}
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={2} xs={6}>
        <Typography variant="h5">Procedure Code #</Typography>
      </Grid>

      <Grid item sm={2} xs={6}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">
            {procedure?.cpt_code?.procedure_code || procedure?.cpt?.procedure_code}
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={8} xs={12}>
        <Typography>
          {procedure.cpt_code?.description || procedure?.cpt?.description}
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={1} component={Box} pb={3}>
      <Grid item sm={4} xs={12}>
        <Typography variant="h5">Surgeries</Typography>
      </Grid>

      <Grid item sm={8} xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">
            {procedure?.surgery || '-'}
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={2} xs={12}>
        <Typography variant="h5">DX Sequence</Typography>
      </Grid>

      {procedure?.icd?.map((item, i) => (
        <Fragment key={item.id}>
          <Hidden only="xs">
            {i !== 0 && <Grid item xs={2} />}
          </Hidden>

          <Grid item sm={2} xs={6}>
            <Box color="info.main">
              <Typography variant="h5" color="inherit">
                {item.code ? `DX${i + 1}: ${item.code}` : '-'}
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={8} xs={6}>
            <Typography>{item.description}</Typography>
          </Grid>
        </Fragment>
      ))}
    </Grid>

    <Grid container spacing={1} component={Box} pb={5}>
      <Grid item sm={2} xs={6}>
        <Typography color="textSecondary">Unit</Typography>
      </Grid>

      <Grid item sm={4} xs={6}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">{procedure?.unit}</Typography>
        </Box>
      </Grid>

      <Grid item sm={2} xs={6}>
        <Typography color="textSecondary">Price</Typography>
      </Grid>

      <Grid item sm={4} xs={6}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">
            <CurrencyFormat value={procedure?.price || 0}/>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </>;
};
