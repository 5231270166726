import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../../MainContent';

export const Row = ({ code }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ListRow px={isMobile ? 1 : 2}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography>{code.code}</Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.description}>
        <Typography>{code.description}</Typography>
      </ListRowCell>
    </ListRow>
  );
};
