import { createHookReducer } from '../../../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_DEPOSITIONS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_DEPOSITIONS_SUCCESS]: (state, depositions) => {
    return {
      ...state,

      isFetching: false,
      depositions
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_DEPOSITIONS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter },
      depositions: []
    };
  },

  [types.UPDATE_DEPOSITION]: (state, updatedDeposition) => {
    return {
      ...state,

      depositions: state.depositions.map((deposition) => {
        return deposition.id === updatedDeposition.id ? { ...deposition, ...updatedDeposition } : deposition;
      })
    };
  },

  [types.ADD_DEPOSITION]: (state, deposition) => {
    return {
      ...state,

      depositions: [ deposition, ...state.depositions ]
    };
  },

  [types.DELETE_DEPOSITION]: (state, depositionID) => {
    return {
      ...state,

      depositions: state.depositions.filter(({ id }) => depositionID !== id)
    };
  }
});
