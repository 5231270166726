export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexGrow: 1,
    overflow: 'auto'
  },

  card: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 320,
    paddingBottom: spacing(1)
  }
});
