import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { TextField } from '../../../../../../components/FormField';

export const Header = ({ isNewCase = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <Box my={1} px={isMobile ? 1 : 2}>
      <Box color="info.main">
        <Typography paragraph variant="h6">
          General Info
        </Typography>
      </Box>

      <Grid container spacing={3} justify="space-between">
        <Grid item xs={isMobile ? 12 : 7}>
          <TextField
            required
            name="name"
            label="Case name"
            placeholder="Enter case name..."
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            name="file_number"
            label="Case Number/File No"
            placeholder="Enter case number..."
            helperText={isNewCase ? 'Will be generated automatically if left blank' : null}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            disableUnderline
            variant="filled"
            name="description"
            label="Description"
            rows={4}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
