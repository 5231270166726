import { isObject } from 'lodash';

export const jsonToFormData = (data) => {
  const formData = new FormData();

  const buildFormData = function buildFormData (data, parentKey) {
    const conditions = [
      isObject(data),
      !(data instanceof Date),
      !(data instanceof File),
      !(data instanceof Blob)
    ];
    const isDataObject = conditions.every(Boolean);

    if (isDataObject) {
      Object.keys(data).forEach((key) => {
        buildFormData(data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data === null ? '' : data;

      formData.append(parentKey, value);
    }
  };

  buildFormData(data);

  return formData;
};
