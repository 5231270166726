export const styles = ({ spacing, palette, shadows, breakpoints }) => ({
  root: {
    display: 'flex',
    height: '100%',
    boxShadow: shadows[2],
    backgroundColor: palette.background.default
  },

  leftSide: {
    padding: spacing(2, 0),
    width: 240,
    overflow: 'auto',
    borderRight: `1px solid ${palette.grey[200]}`,

    [breakpoints.down(breakpoints.values.md)]: {
      width: 0
    }
  },

  rightSide: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    flexGrow: 1
  }
});
