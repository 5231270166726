export const styles = ({
  spacing,
  palette: { grey },
  typography: { pxToRem, fontWeightMedium },
  shape: { borderRadius }
}) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(0.75, 2, 0.75, 0)
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    color: grey[500],
    fontSize: pxToRem(14),
    fontWeight: fontWeightMedium
  },

  title_total: {
    flexGrow: 0
  },

  titleLegend: {
    width: pxToRem(12),
    height: pxToRem(12),
    marginRight: spacing(),
    border: `1px solid ${grey[300]}`,
    backgroundColor: ({ color = grey[300] }) => color,
    borderRadius
  }
});
