import { useMediaQuery, useTheme } from '@material-ui/core';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MobileWidgets } from '../../../../../../../components/Widgets/MobileWidgets';
import * as pages from '../../../../../../../store/dashboard/layout/pages';
import { UserContext } from '../../../UserProvider';
import { checkedWidgetsTypes } from './Layouts';
import { widgetsMap } from './Widgets';
import { MainInfo } from './MainInfo';

export const MedicalProfile = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const profile = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : profile.user;

  return isTablet ? (
    <MobileWidgets
      page={pages.medicalUserProfile}
      widgetsMap={widgetsMap}
      checkedWidgetsTypes={checkedWidgetsTypes}
      payload={{ user }}
    />
  ) : <MainInfo user={user} />;
};

