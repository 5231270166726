import { createContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../api';
import * as prescriptionsApi from '../../../../../api/profile';
import { usePrevious } from '../../../../../helpers/hooks';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const PrescriptionsContext = createContext(null);

export const PrescriptionsProvider = ({ children, initialState: initialStateProp = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { prescriptions, pagination, filter } = state;
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchPrescriptions = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.PRESCRITIONS_FETCH_REQUEST });

    prescriptionsApi.fetchPrescriptions({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.PRESCRITIONS_FETCH_SUCCESS, payload: data });
    });
  };

  const createPrescription = (data) => {
    dispatch({ type: types.ADD_PRESCRITION, payload: data });
  };

  const updatePrescription = (data) => {
    dispatch({ type: types.UPDATE_PRESCRITION, payload: data });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetPrescriptions = (newFilter = {}) => {
    dispatch({ type: types.RESET_PRESCRITIONS, payload: newFilter });

    fetchPrescriptions({ page: 1, ...newFilter });
  };

  const deletePrescription = (id, callback) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        prescriptionsApi.deletePrescription(id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchPrescriptions({ page: filter.page });
          } else if (prescriptions.length - 1 === 0 && filter.page !== 1) {
            fetchPrescriptions({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_PRESCRITION_SUCCESS, payload: id });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
          callback && callback();
        }).catch(() => {
          enqueueSnackbar('Prescription is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const providerValue = {
    ...state,
    prescriptions,
    pagination,
    filter,
    meta: {
      ...pagination,
      ...filter
    },

    // functions
    fetchPrescriptions,
    createPrescription,
    updatePrescription,
    applyFilter,
    resetPrescriptions,
    deletePrescription
  };

  useEffect(() => {
    const newFilter = { ...filter, ...initialStateProp.filter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, initialStateProp.filter ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetPrescriptions();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <PrescriptionsContext.Provider value={providerValue}>
      {children}
    </PrescriptionsContext.Provider>
  );
};
