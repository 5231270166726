import { createContext, useEffect, useReducer, useRef } from 'react';
import { api } from '../../../../api';
import * as videoApi from '../../../../api/videos';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { RenameVideoModal } from '../RenameVideoModal';
import { UploadVideoModal } from '../UploadVideoModal';
import { reducer } from './reducer';
import { initialState as initialStateConfig } from './initialState';
import * as types from './types';

export const VideosContext = createContext(null);

export const VideoContextProvider = ({ children, initialState = {}, openModal }) => {
  const [ state, dispatch ] = useReducer(reducer, { ...initialStateConfig, ...initialState });
  const {
    isFetched,
    isFetching,
    filter,
    pagination,
    videos
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchVideos = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_VIDEOS_REQUEST });

    videoApi.fetchVideos({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_VIDEOS_SUCCESS, payload: data });
    });
  };

  const resetVideos = (newFilter) => {
    dispatch({ type: types.RESET_VIDEOS, payload: newFilter });

    fetchVideos({ page: 1, ...newFilter });
  };


  const deleteVideo = (video) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        videoApi.deleteVideo(video.id).then(() => {
          dispatch({ type: types.DELETE_VIDEOS, payload: video });
        });
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchVideos({ page: filter.page + 1 });
    }
  };

  const uploadVideo = () => {
    openModal(UploadVideoModal, {
      onModalResolved: () => {
        resetVideos({ page: 1 });
      }
    });
  };

  const renameVideo = (file, newFilter={}) => {
    openModal(RenameVideoModal, {
      payload: { file }, onModalResolved: () => {
        dispatch({ type: types.UPDATE_VIDEO_IN_LIST, payload: newFilter });
      }
    });
  };

  const providerValue = {
    videos,
    isFetching,
    isFetched,
    filter,
    pagination,

    // functions
    resetVideos,
    loadNextPage,
    deleteVideo,
    uploadVideo,
    renameVideo
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <VideosContext.Provider value={providerValue}>
      {children}
    </VideosContext.Provider>
  );
};

