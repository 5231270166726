export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    paddingTop: spacing(2)
  },

  loader: {
    padding: spacing(3)
  },

  mainContent: {
    flexGrow: 1
  },

  row: {
    padding: spacing(0.5, 1, 0.5, 0.5)
  }
});
