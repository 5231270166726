import { useContext } from 'react';
import { Checkbox, Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { FilesFromPrintersContext } from '../../FilesFromPrintersProvider';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  const { selectedFiles, allFilesIsSelected, toggleAllFilesSelection } = useContext(FilesFromPrintersContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell>
          <Checkbox
            edge="start"
            checked={allFilesIsSelected()}
            indeterminate={!!selectedFiles.length && !allFilesIsSelected()}
            onClick={toggleAllFilesSelection}
          />
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.name}>
          File name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.size}>
          Size
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.date}>
          Date of printing
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
