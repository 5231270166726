import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Box, Button, SvgIcon, Dialog, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Icon } from '@mdi/react';
import { mdiAccountMultiplePlusOutline } from '@mdi/js';
import { getUserFullName, getUserNameAbbr } from '../../../../helpers/users';
import { Yup } from '../../../../utils/validation';
import { setFilesLastGlobalAction } from '../../../../store/globalActions';
import * as filesApi from '../../../../api/files';
import { useModal } from '../../../../components/ModalsProvider';
import { Avatar } from '../../../../components/Avatar';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { UsersSelect } from '../../../../components/users';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../../components/Modal';
import { Loader } from '../../../../components/Loader';
import * as filesActionTypes from '../FilesContext/types';

export const ShareFileModal = ({
  DialogProps,
  payload: { filesIDs, users: usersProp = [] },
  handleModalReject
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ users, setUsers ] = useState(usersProp);
  const dispatch = useDispatch();

  const shareFiles = (values, { isSubmitting, setErrors, resetForm }) => {
    if (isSubmitting) {
      return;
    }

    return filesApi.shareFiles({ files: filesIDs, users: values.users.map(({ id }) => id) }).then(() => {
      enqueueSnackbar('Files successfully shared', { variant: 'success' });

      dispatch(setFilesLastGlobalAction({
        type: filesActionTypes.ADD_FILE_SHARED_USERS,
        payload: { fileID: filesIDs[0], users }
      }));

      setUsers((state) => {
        return state.concat(values.users.filter((user) => {
          return !state.find(({ id }) => user.id === id);
        }));
      });

      resetForm();
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        setErrors(errors);
      }
    });
  };

  const deleteUser = (user) => () => {
    const fileID = filesIDs[0];

    openModal(ConfirmationModal, {
      onModalResolved: () => {
        filesApi.preventShareFiles({ params: { files: [ fileID ], users: [ user.id ] } }).then(() => {
          const result = users.filter(({ id }) => id !== user.id);

          enqueueSnackbar(`${getUserFullName(user)} has lost access to the file`, { variant: 'info' });

          dispatch(setFilesLastGlobalAction({
            type: filesActionTypes.DELETE_FILE_SHARED_USERS,
            payload: { fileID, users: result }
          }));

          setUsers(result);
        });
      }
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ users: [] }}
        validationSchema={Yup.array().nullable().of(Yup.object().required()).required()}
        onSubmit={shareFiles}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <ModalContainer component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiAccountMultiplePlusOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Share file with other users
            </ModalHeader>

            <ModalBody>
              <UsersSelect
                multiple
                disableValueTransform
                name="users"
                label="Invite people"
                hiddenOptions={users}
              />

              {!!users?.length &&
                <Box mt={2}>
                  <Box mb={2}>
                    <Typography variant="h3">Who has access</Typography>
                  </Box>

                  {users.map((user) => (
                    <Box mb={1} display="flex" alignItems="center" key={user.id}>
                      <Avatar
                        src={user.profile?.avatar || user.avatar}
                        alt={getUserNameAbbr(user)}
                      />

                      <Box mx={1} flexGrow={1}>
                        <Box clone lineHeight="1 !important">
                          <Typography>{getUserFullName(user)}</Typography>
                        </Box>

                        <Typography variant="body2" color="textSecondary">{user.role}</Typography>
                      </Box>

                      <IconButton edge="end" title="Remove access" onClick={deleteUser(user)}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              }
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !values.users?.length}
                  >
                    Share
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
