import { Grid } from '@material-ui/core';
import { dataURItoBlob } from '../../../../../../../helpers/files';
import { CustomFont } from './CustomFont';
import { TextVariantsForm } from './TextVariantsForm';

export const Choose  = ({ signature, onChange }) => {
  const handleSignatureChange = (key) => (dataURI) => {
    onChange({ [key]: dataURItoBlob(dataURI) });
  };

  return (
    <>
      <TextVariantsForm signature={signature} onChange={onChange} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <CustomFont
            text={signature.electronic_signature}
            onChange={handleSignatureChange('electronic_signature_file')}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <CustomFont
            text={signature.fax_signature}
            onChange={handleSignatureChange('fax_signature_file')}
          />
        </Grid>
      </Grid>
    </>
  );
};
