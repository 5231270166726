import { Box, Typography } from '@material-ui/core';
import { Button } from '../../../../../../components/Button';
import { Loader } from '../../../../../../components/Loader';
import { eventConfirmationMap } from '../../../../../../components/schedule-events';

export const EmailEventConfirmation = ({ scheduleItem = {}, isSubmitting = false, onConfirmEvent = () => {} }) => {
  return (
    <Loader surface loading={isSubmitting} render={
      () => (
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Typography variant="h5">Going?</Typography>
          </Box>

          <Button
            size="medium"
            color="success"
            onClick={onConfirmEvent({ value: eventConfirmationMap.yes, scheduleItem })}
          >
            Yes
          </Button>

          <Box mx={2}>
            <Button
              size="medium"
              color="error"
              onClick={onConfirmEvent({ value: eventConfirmationMap.no, scheduleItem })}
            >
              No
            </Button>
          </Box>

          <Button
            size="medium"
            color="warning"
            onClick={onConfirmEvent({ value: eventConfirmationMap.maybe, scheduleItem })}
          >
            Maybe
          </Button>
        </Box>
      )}
    />
  );
};
