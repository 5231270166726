import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../api';
import * as contactsApi from '../../../../../api/contacts';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const ContactsContext = createContext();

export const ContactsProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const {
    filter,
    contacts,
    pagination,
    isFetching,
    isFetched,
    selectedIDs
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchContacts = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.CONTACTS_FETCH_REQUEST });

    contactsApi.fetchContacts({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.CONTACTS_FETCH_SUCCESS, payload: data });
    });
  };

  const createContact = (contact) => {
    dispatch({ type: types.ADD_CONTACT, payload: contact });
  };

  const updateContact = (contact) => {
    dispatch({ type: types.UPDATE_CONTACT, payload: contact });
  };

  const deleteContact = (contact) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        contactsApi.deleteContact(contact.id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchContacts({ page: filter.page });
          } else if (contacts.length - 1 === 0 && filter.page !== 1) {
            fetchContacts({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_CONTACT, payload: contact.id });
          }

          enqueueSnackbar('Contact successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Contact is not deleted!', { variant: 'error' });
        });
      }
    });
  };

  const resetContacts = (newFilter) => {
    dispatch({ type: types.RESET_CONTACTS, payload: newFilter });

    fetchContacts({ page: 1, ...newFilter });
  };

  const allContactsIsSelected = () => {
    return contacts.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_CONTACT_SELECTION, payload: id });
  };

  const toggleAllContactsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_CONTACTS_SELECTION });
  };

  const providerValue = {
    contacts,
    selectedIDs,
    isFetching,
    isFetched,
    filter,
    meta: {
      ...filter,
      ...pagination
    },
    pagination,

    // functions
    updateContact,
    deleteContact,
    fetchContacts,
    createContact,
    resetContacts,
    allContactsIsSelected,
    toggleAllContactsSelection,
    toggleItemSelection
  };

  useEffect(() => {
    resetContacts();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ContactsContext.Provider value={providerValue}>
      {children}
    </ContactsContext.Provider>
  );
};
