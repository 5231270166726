import { useState } from 'react';
import { Box } from '@material-ui/core';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { CaseClosureContent, EditCaseClosureContent } from './CaseClosureContent';

export const CaseClosure = ({
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  onCaseItemUpdate = () => {},
  ...props
}) => {
  const { openModal } = useModal();
  const [ caseInfo, setCaseInfo ] = useState({
    closed_at: caseItem.closed_at,
    physical_file_scanned_at: caseItem.physical_file_scanned_at,
    closed_by: caseItem.closed_by,
    subbed_out: caseItem.subbed_out,
    subbed_out_at: caseItem.subbed_out_at,
    substituted_out: caseItem.substituted_out
  });

  const openWidgetModal = () => {
    openModal(EditCaseClosureContent, {
      payload: { caseInfo: { id: caseItem.id, ...caseInfo } },
      onModalResolved: (value) => {
        setCaseInfo({
          physical_file_scanned_at: value.physical_file_scanned_at,
          closed_by: value.closed_by,
          subbed_out: value.subbed_out,
          subbed_out_at: value.subbed_out_at,
          substituted_out: value.substituted_out
        });
        onCaseItemUpdate(value);
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      title="Case closure information"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          isEditable={!hasRole(rolesMap.patient, rolesMap.client)}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
          openWidgetModal={openWidgetModal}
        />
      ]}
      {...props}
    >
      <Box height={height || '100%'}>
        <CaseClosureContent caseInfo={caseInfo}/>
      </Box>
    </ContentCard>
  );
};
