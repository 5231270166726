import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './rootReducer';
import { storeKey } from './storeKey';

const persistConfig = {
  key: storeKey,
  storage,
  whitelist: [
    'auth',
    'theme',
    'layout',
    'profile',
    'token',
    'lastFilters',
    'billing'
  ]
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
