export const styles = ({
  spacing,
  palette: { grey, divider, primary },
  typography: { pxToRem, fontWeightMedium }
}) => ({
  checkbox: {
    alignSelf: 'center',
    color: grey[400]
  },

  root: {
    padding: spacing(0, 2, 0, 0),
    marginBottom: spacing(),
    border: `1px solid ${divider}`,
    backgroundColor: primary.contrastText
  },

  rangeContent: {
    display: 'flex',
    alignItems: 'center'
  },

  rangeContent__title: {
    fontWeight: fontWeightMedium,
    color: grey[600],
    marginLeft: spacing()
  },

  dateContent: {
    fontSize: pxToRem(13)
  }
});
