import { useContext, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { TextField } from '../../../../../components/FormField';
import { PatientsSelect } from '../../../../../components/users/PatientsSelect';
import { SavedEmailsContext } from '../SavedEmailsContext';
import { filterFieldsMap, filterFieldsLabels } from './filterFieldsMap';

export const FiltersBar = ({ hiddenFilterFields }) => {
  const formikRef = useRef();
  const {
    applyFilter,
    relationsForFilter,
    setRelationsForFilter
  } = useContext(SavedEmailsContext);

  const handleTextChange = debounce((event) => {
    const value = event.target.value;

    applyFilter({ text: value });
    setRelationsForFilter((state) => ({ ...state, text: value }));
  }, 600);

  const handlePatientChange = (patient) => {
    applyFilter({ patient_id: patient?.id || null });

    if (relationsForFilter?.patient_id?.id !== patient?.id) {
      setRelationsForFilter((state) => ({ ...state, patient_id: patient }));
    }
  };

  const handleCasesChange = (caseItem) => {
    applyFilter({ case_id: caseItem?.id || null });

    if (relationsForFilter?.case_id?.id !== caseItem?.id) {
      setRelationsForFilter((state) => ({ ...state, case_id: caseItem }));
    }
  };

  const applySavedFilter = (filter) => {
    applyFilter({
      ...filter,

      tags: filter?.tags?.map((tag) => tag.value),
      case_id: filter?.case_id?.id,
      patient_id: filter?.patient_id?.id
    });

    formikRef?.current?.setValues({
      ...filter,

      case_id: filter?.case_id || null,
      patient_id: filter?.patient_id || null
    });
  };

  useEffect(() => {
    applySavedFilter(relationsForFilter);
  }, [ relationsForFilter ]);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      formikRef={formikRef}
      initialValues={relationsForFilter}
      hiddenFields={hiddenFilterFields}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.text,
          label: filterFieldsLabels[filterFieldsMap.text],
          field: (
            <TextField
              name="text"
              label="Search"
              placeholder="Search..."
              onChange={handleTextChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.caseID,
          label: filterFieldsLabels[filterFieldsMap.caseID],
          field: (
            <CasesSelect
              name="case_id"
              label="Case"
              placeholder="Select case..."
              minWidth={170}
              onChange={handleCasesChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.patientID,
          label: filterFieldsLabels[filterFieldsMap.patientID],
          field: (
            <PatientsSelect
              name="patient_id"
              label="Patient"
              placeholder="Patient..."
              minWidth={180}
              onChange={handlePatientChange}
            />
          )
        }
      ]}
    />
  );
};
