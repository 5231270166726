export const styles = ({ spacing }) => ({
  checkboxesContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    maxWidth: 800,
    paddingLeft: spacing(2)
  },

  checkboxesContentRow: {
    width: 180
  },

  radioRowGroup: {
    maxWidth: 800
  },

  radioRowGroup__content: {
    width: 180
  }
});
