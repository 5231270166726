import { Box, Grid, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { Contact } from '../../../../../../../../components/Contact';
import { ReadonlyCheckbox } from '../../../../../../../../components/ReadonlyCheckbox';
import { AddressLink } from '../../../../../../../../components/AddressLink';
import { Date } from '../../../../../../../../components/Date';
import { UserLink } from '../../../../../../../../components/UserLink';

export const CourtInformationContent = ({ courtInformation = {} }) => {
  return (
    <Grid container spacing={3} component={Box} pb={4}>
      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Court</Typography>
          </Grid>

          <Grid item>
            <CompanyLink company={courtInformation?.court} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Court Number</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {courtInformation?.court?.court_number || 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Address</Typography>
          </Grid>

          <Grid item>
            <AddressLink address={courtInformation?.court?.full_address} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Court Phone</Typography>
          </Grid>

          <Grid item>
            <Contact type="tel" contact={courtInformation?.court?.phone} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Court Case</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {courtInformation?.case_number || 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Judge</Typography>
          </Grid>

          <Grid item>
            <UserLink user={courtInformation?.contact} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Dep</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {courtInformation?.dep || 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Trial</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.trial_date} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Jury Trial</Typography>
          </Grid>

          <Grid item>
            <ReadonlyCheckbox checked={courtInformation?.jury_trial} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">FSC</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.fsc} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} />

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">CMC</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.cmc} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Future Use</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.cmc_future_use} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">OSC</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.osc} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} />

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">MSC</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.mcs} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Future Use</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.mcs_future_use} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Mediation</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.mediation} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} />

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Arb.</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.arb} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Future Use</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.arb_future_use} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Complaint Filed</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.complaint_filled} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Def-998</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">{courtInformation?.def || '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">Received</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.received} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography color="textSecondary">PLF-998 Sent</Typography>
          </Grid>

          <Grid item>
            <Date date={courtInformation?.plf_sent} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
