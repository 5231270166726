import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { PageBody } from '../../../../../components/Page/PageBody';
import { TablePagination } from '../../../../../components/TablePagination';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { HistoriesContext } from '../HistoriesProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const widthBreakpointXXS = 560;
export const widthBreakpointXS = 740;
export const widthBreakpointSmall = 900;
export const widthBreakpointMedium = 1000;
export const widthBreakpointLarge = 1100;
export const minRowHeight = 54;
export const columnsWidths = {
  date: 200,
  temperature: 130,
  blood_type: 100,
  heart_rate: 130,
  pressure: 200,
  age: 80,
  weight: 80,
  bmi: 80,
  actions: 50
};

export const List = () => {
  const { isFetched, histories, meta, resetHistories } = useContext(HistoriesContext);
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });

  useEffect(() => {
    resetHistories();
  }, []);

  return (
    <Box ref={rootRef} height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => !histories.length ? (
          <Box p={3}>
            <Typography align="center">No histories found</Typography>
          </Box>
        ) : (
          <>
            <TableHeader width={width} />

            <PageBody disablePaddings>
              <MuiList disablePadding>
                {histories.map((history) => (
                  <Row key={history.id} width={width} history={history} />
                ))}
              </MuiList>
            </PageBody>

            <TablePagination borderTop pagination={meta} onChange={resetHistories} />
          </>
        )}
      />
    </Box>
  );
};
