const symptomsTypesMap = {
  fever: 'fever',
  weight_loss: 'weight_loss',
  fatigue: 'fatigue',
  incontinence: 'incontinence',
  pain_on_urination: 'pain_on_urination',
  blood_in_urine: 'blood_in_urine',
  abnormal_bleeding: 'abnormal_bleeding',
  anemia: 'anemia',
  lumps_or_swelling: 'lumps_or_swelling',
  blurred_vision: 'blurred_vision',
  double_vision: 'double_vision',
  loss_of_vision: 'loss_of_vision',
  eye_pain: 'eye_pain',
  eye_redness: 'eye_redness',
  eye_dryness: 'eye_dryness',
  muscle_pain: 'muscle_pain',
  muscle_cramp: 'muscle_cramp',
  muscle_twitches: 'muscle_twitches',
  loss_of_muscle: 'loss_of_muscle',
  neck_pain: 'neck_pain',
  back_pain: 'back_pain',
  joint_swelling: 'joint_swelling',
  excessive_rash: 'excessive_rash',
  excessive_urination: 'excessive_urination',
  heat_or_cold_intolerance: 'heat_or_cold_intolerance',
  trouble_hearing: 'trouble_hearing',
  ringing_in_ears: 'ringing_in_ears',
  dizziness: 'dizziness',
  loss_of_balance: 'loss_of_balance',
  ear_pain: 'ear_pain',
  ear_discharge: 'ear_discharge',
  numbness: 'numbness',
  tingling: 'tingling',
  discoloration: 'discoloration',
  hair_loss: 'hair_loss',
  nail_change: 'nail_change',
  sweating_change: 'sweating_change',
  chest_pain: 'chest_pain',
  irregular_heart_beat: 'irregular_heart_beat',
  high_blood_pressure: 'high_blood_pressure',
  limb_pain_walking: 'limb_pain_walking',
  fainting: 'fainting',
  headache: 'headache',
  face_pain: 'face_pain',
  face_numbness: 'face_numbness',
  weakness: 'weakness',
  tremors: 'tremors',
  blackouts: 'blackouts',
  seizures: 'seizures',
  troubles_with_memory: 'troubles_with_memory',
  indigestion: 'indigestion',
  abdominal_pain: 'abdominal_pain',
  nausea: 'nausea',
  vomiting: 'vomiting',
  regurgitation: 'regurgitation',
  diarrhea: 'diarrhea',
  bloody_stools: 'bloody_stools',
  hallucinations: 'hallucinations',
  feeling_depressed: 'feeling_depressed',
  trouble_sleeping: 'trouble_sleeping',
  suicidal_thoughts: 'suicidal_thoughts',
  inappropriate_crying: 'inappropriate_crying',
  skin_rash: 'skin_rash',
  joint_pain: 'joint_pain',
  dry_mouth_eyes: 'dry_mouth_eyes'
};

export const symptomsMap = {
  [symptomsTypesMap.fever]: 'Fever',
  [symptomsTypesMap.weight_loss]: 'Weight loss',
  [symptomsTypesMap.fatigue]: 'Fatigue',
  [symptomsTypesMap.incontinence]: 'Incontinence',
  [symptomsTypesMap.pain_on_urination]: 'Pain on urination',
  [symptomsTypesMap.blood_in_urine]: 'Blood in urine',
  [symptomsTypesMap.abnormal_bleeding]: 'Abnormal bleeding',
  [symptomsTypesMap.anemia]: 'Anemia',
  [symptomsTypesMap.lumps_or_swelling]: 'Lumps or swelling',
  [symptomsTypesMap.blurred_vision]: 'Blurred vision',
  [symptomsTypesMap.double_vision]: 'Double vision',
  [symptomsTypesMap.loss_of_vision]: 'Loss of vision',
  [symptomsTypesMap.eye_pain]: 'Eye pain',
  [symptomsTypesMap.eye_redness]: 'Eye redness',
  [symptomsTypesMap.eye_dryness]: 'Eye dryness',
  [symptomsTypesMap.muscle_pain]: 'Muscle pain',
  [symptomsTypesMap.muscle_cramp]: 'Muscle cramp',
  [symptomsTypesMap.muscle_twitches]: 'Muscle twitches',
  [symptomsTypesMap.loss_of_muscle]: 'Loss of muscle',
  [symptomsTypesMap.neck_pain]: 'Neck pain',
  [symptomsTypesMap.back_pain]: 'Back pain',
  [symptomsTypesMap.joint_swelling]: 'Joint swelling',
  [symptomsTypesMap.excessive_rash]: 'Excessive rash',
  [symptomsTypesMap.excessive_urination]: 'Excessive urination',
  [symptomsTypesMap.heat_or_cold_intolerance]: 'Heat or cold intolerance',
  [symptomsTypesMap.trouble_hearing]: 'Trouble hearing',
  [symptomsTypesMap.ringing_in_ears]: 'Ringing in ears',
  [symptomsTypesMap.dizziness]: 'Dizziness',
  [symptomsTypesMap.loss_of_balance]: 'Loss of balance',
  [symptomsTypesMap.ear_pain]: 'Ear pain',
  [symptomsTypesMap.ear_discharge]: 'Ear discharge',
  [symptomsTypesMap.numbness]: 'Numbness',
  [symptomsTypesMap.tingling]: 'Tingling',
  [symptomsTypesMap.discoloration]: 'Discoloration',
  [symptomsTypesMap.hair_loss]: 'Hair loss',
  [symptomsTypesMap.nail_change]: 'Nail change',
  [symptomsTypesMap.sweating_change]: 'Sweating change',
  [symptomsTypesMap.chest_pain]: 'Chest pain',
  [symptomsTypesMap.irregular_heart_beat]: 'Irregular heart beat',
  [symptomsTypesMap.high_blood_pressure]: 'High blood pressure',
  [symptomsTypesMap.limb_pain_walking]: 'Limb pain walking',
  [symptomsTypesMap.fainting]: 'Fainting',
  [symptomsTypesMap.headache]: 'Headache',
  [symptomsTypesMap.face_pain]: 'Face pain',
  [symptomsTypesMap.face_numbness]: 'Face numbness',
  [symptomsTypesMap.weakness]: 'Weakness',
  [symptomsTypesMap.tremors]: 'Tremors',
  [symptomsTypesMap.blackouts]: 'Blackouts',
  [symptomsTypesMap.seizures]: 'Seizures',
  [symptomsTypesMap.troubles_with_memory]: 'Troubles with memory',
  [symptomsTypesMap.indigestion]: 'Indigestion',
  [symptomsTypesMap.abdominal_pain]: 'Abdominal pain',
  [symptomsTypesMap.nausea]: 'Nausea',
  [symptomsTypesMap.vomiting]: 'Vomiting',
  [symptomsTypesMap.regurgitation]: 'Regurgitation',
  [symptomsTypesMap.diarrhea]: 'Diarrhea',
  [symptomsTypesMap.bloody_stools]: 'Bloody stools',
  [symptomsTypesMap.hallucinations]: 'Hallucinations',
  [symptomsTypesMap.feeling_depressed]: 'Feeling depressed',
  [symptomsTypesMap.trouble_sleeping]: 'Trouble sleeping',
  [symptomsTypesMap.suicidal_thoughts]: 'Suicidal thoughts',
  [symptomsTypesMap.inappropriate_crying]: 'Inappropriate crying',
  [symptomsTypesMap.skin_rash]: 'Skin rash',
  [symptomsTypesMap.joint_pain]: 'Joint pain',
  [symptomsTypesMap.dry_mouth_eyes]: 'Dry mouth eyes'
};
