import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as serviceLinesApi from '../../../api/claims/service-lines';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { Loader } from '../../Loader';
import { ServiceLineForm, validationSchema, initialValues } from './ServiceLineForm';

export const ServiceLineFormModal = ({
  payload: {
    claim,
    initialValues: initialValuesProp
  },
  DialogProps,
  handleModalReject,
  handleModalResolve
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const createServiceLine = (values, { setErrors }) => {
    return serviceLinesApi.createServiceLine(values).then((data) => {
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Service line not created', { variant: 'error' });
      }
    });
  };

  const updateServiceLine = (values, { setErrors }) => {
    return serviceLinesApi.updateServiceLine(values).then((data) => {
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Service line not updated', { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...initialValuesProp,

        authorization_id: claim?.authorization
      }}
      validationSchema={validationSchema}
      onSubmit={initialValuesProp?.id ? updateServiceLine : createServiceLine}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="lg" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              {initialValuesProp?.id ? 'Edit' : 'Add'} Service Line
            </ModalHeader>

            <ModalBody disablePaddings>
              <ServiceLineForm claim={claim} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {initialValuesProp?.id ? 'Save' : 'Create'}
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
