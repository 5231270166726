import moment from 'moment';
import { Yup } from '../../../../../../../../utils/validation';

export const validationSchema =  Yup.object().shape({
  expired_at: Yup.lazy(() => Yup
    .date()
    .format('X')
    .min(moment().add(1, 'hour'), 'Minimum period is 1 hour')
    .max(moment().add(30, 'days'), 'Maximum period is 30 days')
    .nullable()
  )
});
