export const styles = ({
  spacing,
  palette: {
    grey,
    primary,
    darkgreen,
    lightgreen,
    darkblue
  },
  typography: { pxToRem, fontWeightMedium },
  transitions
}) => ({
  root: {
    fontSize: pxToRem(14),
    borderBottom: `1px solid ${grey[100]}`,
    padding: spacing(),
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      backgroundColor: 'rgba(0, 188, 212, 0.05)',
      borderBottom: `1px solid ${primary.main}`
    }
  },

  userInfoLink: {
    fontWeight: fontWeightMedium,
    color: grey[600],

    '&:hover': {
      textDecoration: 'underline'
    }
  },

  avatar: {
    marginRight: spacing()
  },

  dueDate: {
    color: darkgreen,
    fontSize: pxToRem(12)
  },

  invoiceIcon: {
    fontSize: pxToRem(18),
    color: darkgreen,
    marginRight: spacing(),
    marginTop: spacing(-0.25)
  },

  invoiceCheckboxIcon: {
    marginRight: spacing(),
    color: lightgreen
  },

  invoiceCheckboxIcon_green: {
    marginRight: spacing(),
    color: darkblue
  }
});
