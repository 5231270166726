import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Autocomplete, Checkbox } from '../../../FormField';
import { certificationTypesOptions, conditionIndicatorsOptions } from './data';

export const VisionClaims = () => {
  return (
    <>
      <Box mb={1}>
        <Typography variant="h3">
          Vision Claims
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormControlLabel
            control={<Checkbox name="is_certification_code_indicator" />}
            label="Certification Code Indicator"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Autocomplete
            name="certification_type"
            label="Certification Type"
            options={certificationTypesOptions}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Autocomplete
            multiple
            name="condition_indicators"
            label="Condition Indicators"
            options={conditionIndicatorsOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};
