import { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { NestedList } from '../../../../../NestedList';
import { CervicalSpine } from './CervicalSpine';
import { LumbarSpine } from './LumbarSpine';

export const PhysicalExamination = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isOpenChild, setIsOpenChild ] = useState({
    cervical: true,
    lumbar: true
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenListItem = (name = null) => () => {
    name
      ? setIsOpenChild((state) => ({ ...state, [name]: !state[name] }))
      : setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Physical examination"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem()}
    >
      <Box p={isMobile ? 1 : 3}>
        {!!report?.general_appearance &&
          <Box mb={3}>
            <Typography gutterBottom variant="h5">General appearance</Typography>
            <Typography color="textSecondary">{report?.general_appearance}</Typography>
          </Box>
        }

        {!!report?.cervical &&
          <NestedList
            disablePadding
            disableDivider
            isOpen={isOpenChild.cervical}
            title={
              <Box component="span" ml={-1} color="info.main">
                Spinal examination-Cervical spine
              </Box>
            }
            onOpen={handleOpenListItem('cervical')}
          >
            <CervicalSpine report={report?.cervical || {}} />
          </NestedList>
        }

        {!!report?.lumbar &&
          <Box mb={3}>
            <NestedList
              disablePadding
              disableDivider
              isOpen={isOpenChild.lumbar}
              title={
                <Box component="span" ml={-1} color="info.main">
                  Spinal examination-Lumbar spine
                </Box>
              }
              onOpen={handleOpenListItem('lumbar')}
            >
              <LumbarSpine report={report?.lumbar || {}} />
            </NestedList>
          </Box>
        }
      </Box>
    </NestedList>
  );
};
