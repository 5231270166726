import moment from 'moment';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, FormControl, FormControlLabel } from '@material-ui/core';
import * as appointmentsApi from '../../../api/appointments';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../Modal';
import { Loader } from '../../Loader';
import { RadioGroup, Radio } from '../../FormField';
import { useModal } from '../../ModalsProvider';
import { DeleteOnlyNextAppointmentsModal } from './DeleteOnlyNextAppointmentsModal';

const movingVariantsMap = {
  thisOnly: 'thisOnly',
  thisAndFollowings: 'thisAndFollowings'
};

export const AppointmentsDeleteModal = ({
  DialogProps,
  payload: { appointment },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const deleteAppointment = ({ variant }, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const isSingle = variant === movingVariantsMap.thisOnly;
    const method = isSingle ? 'deleteAppointment' : 'deleteAppointmentsGroup';

    if (!isSingle && moment().add(1, 'minutes').unix() >= appointment.appointment_at) {
      openModal(DeleteOnlyNextAppointmentsModal, {
        onModalResolved: () => {
          return appointmentsApi.deleteAppointmentsGroup(appointment.group_id, {
            params: {
              delete_from: moment().add(1, 'minutes').unix()
            }
          }).then(() => {
            enqueueSnackbar('Appointment deleted', { variant: 'success' });
            handleModalResolve();
          }).catch(() => {
            enqueueSnackbar('Something went wrong. Try again later...', { variant: 'error' });
            handleModalReject();
          });
        }
      });

      return;
    }

    return appointmentsApi[method](isSingle ? appointment.id : appointment.group_id, {
      params: {
        delete_from: !isSingle ? appointment.appointment_at - 1 : null
      }
    }).then(() => {
      enqueueSnackbar('Appointment deleted', { variant: 'success' });
      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Something went wrong. Try again later...', { variant: 'error' });
      handleModalReject();
    });
  };

  return (
    <Formik
      initialValues={{
        variant: movingVariantsMap.thisOnly
      }}
      onSubmit={deleteAppointment}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="xs" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Delete recurring appointment
            </ModalHeader>

            <ModalBody>
              <FormControl fullWidth component="fieldset" margin="dense">
                <RadioGroup name="variant">
                  <FormControlLabel
                    value={movingVariantsMap.thisOnly}
                    label="This appointment"
                    control={<Radio name="variant" color="primary" />}
                  />

                  <FormControlLabel
                    value={movingVariantsMap.thisAndFollowings}
                    label="This and following appointments"
                    control={<Radio name="variant" color="primary" />}
                  />
                </RadioGroup>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Delete
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
