import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { stopPropagation } from '../../../helpers/dom';
import {
  Medications
} from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/FormPreviewModal/MedicalHistory/Medications';
import { CovidFormModal, formTypesMap } from '../../medical/forms';
import {
  getFormRoutes,
  MedicalFormsContext
} from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/MedicalFormsProvider';
import { UserPreviewModal } from '../../users';
import { ReadonlyCheckbox } from '../../ReadonlyCheckbox';
import { useModal } from '../../ModalsProvider';
import { NestedList } from '../../NestedList';
import { UserLink } from '../../UserLink';
import { Fab } from '../../Fab';

export const CurrentMedications = ({ isOpenSwitches = false, report, fetchReports = () => {} }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const { openModal } = useModal();
  const { medicalInfoPathname } = useContext(MedicalFormsContext);
  const history = useHistory();

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  const handleOpenEditPage = () => {
    if (report?.medical_form?.form_type === formTypesMap.follow_up) {
      history.push(`${medicalInfoPathname}/medical-forms/${report?.medical_form?.id}/edit`);
    } else if (report?.medical_form?.form_type === formTypesMap.covid_form) {
      openModal(CovidFormModal, {
        payload: { initialValues: report?.medical_form },
        onModalResolved: fetchReports
      });
    } else {
      history.push(getFormRoutes({ id: report?.medical_form?.id, medicalInfoPathname })[3]);
    }
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Current Medications"
      actionIcon={!!report &&
        <Fab
          size="small"
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={handleOpenEditPage}
        >
          Add
        </Fab>
      }
      onOpen={handleOpenListItem}
    >
      <Box p={3} overflow="hidden">
        {!report ?
          <Typography align="center" color="textSecondary">No info found</Typography>
          :
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Typography color="textSecondary">Medical form type</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="h5">
                {report?.medical_form?.form_type || '-'}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography color="textSecondary">Treating physician</Typography>
            </Grid>

            <Grid item xs={7}>
              <UserLink
                variant="h5"
                user={report?.medical_form?.treating_physician}
                onClick={stopPropagation(openUserPreview(report?.medical_form?.treating_physician?.id))}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography color="textSecondary">Current Medications:</Typography>

              <Box mx={-2}>
                <Medications medications={report?.current_medications || []} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom color="textSecondary">Blood thinning medications:</Typography>

              {!report?.blood_thinning_medications?.length ?
                <Typography color="textSecondary">No info.</Typography>
                :
                (report?.blood_thinning_medications?.map((item, index) => (
                  <ReadonlyCheckbox
                    checked
                    key={index}
                    title={item}
                  />
                )))
              }
            </Grid>
          </Grid>
        }
      </Box>
    </NestedList>
  );
};
