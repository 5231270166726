import { FieldArray, useFormikContext } from 'formik';
import { FormControlLabel, Box, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { Switch, KeyboardDatePicker, LabelSelect, TextField } from '../../../../../../components/FormField';
import { SvgIcon } from '../../../../../../components/SvgIcon';
import { Fab } from '../../../../../../components/Fab';
import { GeneralInfo } from '../GeneralInfo';
import { statusesOptionsMap } from '../statusesOptionsMap';

const initialClaimValues = {
  number: null,
  date: null,
  status: null,
  adj: [],
  doi: [
    {
      is_range: null,
      from: null,
      to: null,
      date: null,
      start_of_limitation: null
    }
  ],
  to: []
};

export const InsuranceClaim = ({ defaultText }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { values } = useFormikContext();

  return (
    <Box my={1} px={2}>
      <FieldArray
        name="ins_claims"
        render={({ remove, unshift }) => (
          <Box>
            <Box display="flex" alignItems="center" color="info.main">
              <Typography variant="h6">
                Insurance Claim
              </Typography>

              <Box ml={2}>
                <Fab
                  variant="extended"
                  color="primary"
                  size="small"
                  onClick={() => unshift(initialClaimValues)}
                  startIcon={<AddIcon />}
                >
                  Add
                </Fab>
              </Box>
            </Box>

            {values?.ins_claims?.map((item, index) => (
              <Grid key={index} container spacing={2} component={Box} pt={isMobile ? 1 : 0}
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={isMobile ? 5 : 4} />
                <Grid item xs={isMobile ? 5 : 4} />

                <Grid item>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      size="small"
                      onClick={() => remove(index)}
                    >
                      <SvgIcon color="error">
                        <CloseIcon />
                      </SvgIcon>
                    </IconButton>
                  </Box>
                </Grid>

                <Grid item xs={isMobile ? 12 : 4}>
                  <TextField
                    name={`ins_claims.${index}.number`}
                    label="Claim No"
                    placeholder="Enter claim number..."
                  />
                </Grid>

                <Grid item xs={isMobile ? 5 : 4}>
                  <KeyboardDatePicker
                    outputFormat="YYYY-MM-DD"
                    name={`ins_claims.${index}.date`}
                    label="DOS"
                  />
                </Grid>

                <Grid item xs={isMobile ? 5 : 4}>
                  <LabelSelect
                    formattedValue
                    name={`ins_claims.${index}.status`}
                    label="Claim status"
                    options={statusesOptionsMap}
                  />
                </Grid>

                <Grid item xs={isMobile ? 12 : 4}>
                  <FormControlLabel
                    label="Government claim"
                    control={
                      <Switch name={`ins_claims.${index}.government_claim`} color="primary" />
                    }
                  />
                </Grid>

                <Grid item xs={isMobile ? 12 : 4}>
                  <KeyboardDatePicker
                    outputFormat="YYYY-MM-DD"
                    name={`ins_claims.${index}.claim_filled_at`}
                    label="Claim filed date"
                  />
                </Grid>

                <Grid item xs={isMobile ? 12 : 4} />

                <Grid item xs={12}>
                  <GeneralInfo parentIndex={index} defaultText={defaultText} />
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
      />
    </Box>
  );
};
