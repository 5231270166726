import T from 'prop-types';
import cn from 'classnames';
import { makeStyles, Link, Typography, Box } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import { stopPropagation } from '../../helpers/dom';
import { hasRole } from '../../utils/hasRole';
import { Tooltip } from '../Tooltip';
import { EmailContact } from './EmailContact';
import { PhoneContact } from './PhoneContact';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  type: T.oneOf([ 'tel', 'mailto' ]),
  href: T.string,
  contact: T.string,
  color: T.string,
  needIcon: T.bool
};

export const Contact = ({
  needIcon = false,
  disableTransform = false,
  size = 'small',
  type,
  contact,
  color = 'primary',
  href,
  className,
  boxProps = {},
  variant = 'body1',

  ...props
}) => {
  const classes = useStyles({ color });
  const phone = type === 'tel' && contact?.replace(/[(|)\s,-]/g, '');
  const phoneNumber = !!phone?.length && phone.substr(phone.length - 10);
  const format = disableTransform ? null : undefined;

  return (
    !contact ?
      <Typography color="textSecondary">No info.</Typography>
      :
      <Tooltip
        isExistTooltip={!!contact}
        title={!phoneNumber
          ? hasRole('client', 'patient') ? contact : `Send email to: ${contact}`
          : <PhoneContact value={phoneNumber} format={format} />
        }
        placement="bottom-start"
      >
        <Box
          display="inline-flex"
          alignItems="center"
          maxWidth="100%"
          component="span"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {needIcon &&
            <Box mr={0.5} component="span" {...boxProps}>
              {type === 'mailto' && <EmailIcon fontSize={size} />}
              {type === 'tel' && <PhoneIcon fontSize={size} />}
            </Box>
          }

          {type === 'mailto' ? (
            <EmailContact
              variant={variant}
              contact={contact}
              color={color}
              className={className}
              {...props}
            />
          ) : (
            <Link
              color={color}
              target="_blank"
              variant={variant}
              href={type ? `${type}:${contact}` : href}
              onClick={stopPropagation()}
              className={cn(
                classes.title,
                { [classes.textColor]: !color },
                className
              )}

              {...props}
            >
              {!phoneNumber ? contact : <PhoneContact value={phoneNumber} format={format} />}
            </Link>
          )}
        </Box>
      </Tooltip>
  );
};

Contact.propTypes = propTypes;
