import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const Header = () => {
  return (
    <ListRow header>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        Permission
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        Creation Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        Activation Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.users}>
        Users
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions} />
    </ListRow>
  );
};
