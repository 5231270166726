export const styles = ({
  spacing,
  palette: {
    grey,
    primary,
    darkgreen,
    lightgreen,
    darkblue,
    divider
  },
  typography: { pxToRem },
  transitions
}) => ({
  root: {
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      backgroundColor: 'rgba(0, 188, 212, 0.05)',
      borderBottom: `1px solid ${primary.main}`
    }
  },

  root_form: {
    borderLeft: `1px solid ${divider}`,
    borderRight: `1px solid ${divider}`
  },

  checkbox: {
    alignSelf: 'center',
    marginLeft: spacing(),
    color: grey[400]
  },

  statusLabel: {
    color: primary.contrastText
  },

  invoiceIcon: {
    fontSize: pxToRem(18),
    color: darkgreen,
    marginRight: spacing(),
    marginTop: spacing(-0.25)
  },

  invoiceCheckboxIcon: {
    marginRight: spacing(),
    color: lightgreen
  },

  invoiceCheckboxIcon_green: {
    marginRight: spacing(),
    color: darkblue
  }
});
