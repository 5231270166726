import * as casesApi from '../../../../../api/cases';

export const transformEamsNumberToOption = (item) => item && ({
  value: item.adj_number,
  label: `ADJ${item.adj_number}`,
  main: {
    case_id: item.case_id,
    case_name:item.case_name,
    case_number: item.case_number,
    adj_number: item.adj_number
  }
});

export const transformDataToOption = (item) => item && ({
  value: item.id,
  label: `${item.code} ${item.description}`
});

export const transformToOption = (item) => item && ({
  value: item.id,
  label: item.name
});

export const fetchTypes = () => {
  return casesApi.fetchTypes().then((data) => data.map(transformToOption));
};
