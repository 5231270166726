import cn from 'classnames';
import Color from 'color';
import { makeStyles, Chip, Typography } from '@material-ui/core';

const styles = ({ palette: { grey, ...palette }, shape: { borderRadius }, typography: { fontWeightMedium } }) => ({
  root: {
    minHeight: 20,
    background: ({ color = grey[500] }) => color,
    color: ({ color }) => Color(color).darken(0.5).isLight() ? grey[600] : palette.common.white,
    fontWeight: fontWeightMedium,
    borderRadius
  }
});

const useStyles = makeStyles(styles);

export const Label = ({ color, className, label, typographyProps = {}, ...props }) => {
  const classes = useStyles({ color });
  const isLabelExist = typeof label === 'string' ? !!label : !!label?.props?.children;

  return !isLabelExist ? (
    <Typography color="textSecondary">No info</Typography>
  ) : (
    <Chip
      className={cn(classes.root, className)}
      label={<Typography noWrap title={label} {...typographyProps}>{label}</Typography>}
      {...props}
    />
  );
};
