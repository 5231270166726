import moment from 'moment';
import { palette } from '../../../themes/palette';

export const getExpirationDateColor = (effectiveDate, expirationDate) => {
  const currentDate = moment().unix();
  const diffOfCurrentAndExpiration = currentDate - expirationDate;
  const duration = expirationDate - effectiveDate;

  if (diffOfCurrentAndExpiration >= 0) {
    return palette().error.main;
  } else if ((effectiveDate + duration / 2) < currentDate) {
    return palette().success.main;
  }

  return palette().warning.main;
};
