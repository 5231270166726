import { useContext, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { InfiniteGridLoader } from '../../../InfiniteLoader';
import { Loader } from '../../../Loader';
import { TasksContext } from '../../TasksProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    tasks,
    isFetching,
    isFetched,
    loadNextPage,
    filter: { per_page, last_page, page, total },
    resetTasks
  } = useContext(TasksContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetTasks();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box py={2} pl={2}>
        <Loader loading={!isFetched} p={3} render={
          () => !total ? (
            <Box p={3}>
              <Typography align="center">No tasks found</Typography>
            </Box>
          ) : (
            <InfiniteGridLoader
              items={tasks}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={{ per_page, last_page, page }}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={370}
              cellHeight={300}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
