import { makeStyles, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ItemTitle = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4">{title}</Typography>
    </div>
  );
};
