import { Grid, Table, TableBody, TableRow, Typography, useTheme } from '@material-ui/core';
import { CaseLink } from '../../../../../components/cases/CaseLink';
import { TableCell } from '../../../../../components/TableCell';
import { UserLink } from '../../../../../components/UserLink';
import { Label } from '../../../../../components/Label';

export const CaseModel = ({ model }) => {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Case Number</Typography>
              </TableCell>

              <TableCell disableBorder>
                <Typography>
                  {model?.file_number || '-'}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Case Name</Typography>
              </TableCell>

              <TableCell disableBorder>
                <CaseLink caseItem={model} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Case Status</Typography>
              </TableCell>

              <TableCell disableBorder>
                {!model?.status?.name ? (
                  <Typography>-</Typography>
                ) : (
                  <Label
                    label={model?.status?.name}
                    color={theme.palette.primary.main}
                  />
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Heading Attorney</Typography>
              </TableCell>

              <TableCell disableBorder>
                <UserLink
                  size="sm"
                  variant="inherit"
                  user={model?.guardian?.user}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
