import moment from 'moment-timezone';
// Cyclic dependency, don't shortening path
import { transformTimeZoneToOption } from '../../../../../components/FormField/selects/TimeZoneSelect';

export const initialValues = {
  bind_contact_to_main_office: true,
  name: null,
  abbr: null,
  company_type_id: null,
  court_number: null,
  avatar_id: null,
  contact_user_id: null,
  country: null,
  state: null,
  city: null,
  zip: null,
  street: null,
  designator: null,
  unit_number: null,
  phone: null,
  mobile_phone: null,
  fax: null,
  email: null,
  main_office_id: null,
  timezone: transformTimeZoneToOption(moment?.tz?.guess(true)),

  billing: {
    country: null,
    state: null,
    city: null,
    zip: null,
    street: null,
    designator: null,
    unit_number: null
  },

  payroll: {
    pay_period: null,
    pay_period_range: {
      start: null,
      finish: null
    },

    overtime_preferences: {
      day_overtime: null,
      day_double_time: null,
      week_overtime: null,
      week_double_time: null
    },

    overtime_rate: null,
    double_time_rate: null,
    paid_lunch: false,
    duration_of_lunch: null
  },

  rate: null,
  notes: null,
  venue: [],
  offices: []
};
