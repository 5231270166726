const fontSize = 14;
const htmlFontSize = 16;
const coefficient = fontSize / 14;

export const pxToRem = (size) => `${(size / htmlFontSize) * coefficient}rem`;

export const typography = ({ palette }) => ({
  htmlFontSize,
  fontSize,
  pxToRem,

  fontWeightNormal: 400,
  fontWeightMedium: 500,

  h1: {
    fontSize: pxToRem(30),
    fontWeight: 500,
    color: palette.text.caption
  },

  h2: {
    fontSize: pxToRem(24),
    fontWeight: 500,
    color: palette.text.caption,
    lineHeight: 1.2
  },

  h3: {
    fontSize: pxToRem(18),
    fontWeight: 500,
    color: palette.text.caption
  },

  h4: {
    fontSize: pxToRem(16),
    fontWeight: 500,
    color: palette.text.caption
  },

  h5: {
    fontSize: pxToRem(14),
    fontWeight: 500,
    color: palette.text.caption
  },

  body1: {
    fontSize: pxToRem(14),
    lineHeight: 1.5
  },

  body2: {
    fontSize: pxToRem(12)
  },

  subtitle1: {
    fontSize: pxToRem(14),
    lineHeight: 1.5,
    fontWeight: 500
  },

  subtitle2: {
    fontSize: pxToRem(12),
    fontWeight: 500
  }
});
