import { useDispatch, useSelector } from 'react-redux';
import { updateUserStorage } from '../../../../../../store/globalUser/operations';
import { Item } from './Item';

export const MenuItems = ({
  isDeleted = false,
  item = {},
  name = '',
  routes,

  ...props
}) => {
  const dispatch = useDispatch();
  const storageInfo = useSelector(({ globalUser }) => globalUser?.data);

  const handleDeleteItem = () => {
    dispatch(updateUserStorage({
      [name]: storageInfo[name]?.filter(({ id }) => id !== item.id)
    }));
  };

  return routes?.map((menuItem) => (
    <Item
      key={menuItem.path}
      isDeleted={isDeleted}
      onDeleteItem={handleDeleteItem}

      {...menuItem}
      {...props}
    />
  ));
};
