import { api } from '../api';

export const fetchWaitingList = (config) => {
  return api.get('/waiting-lists', config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => { throw error; });
};

export const fetchWaitingListItem = (id) => {
  return api.get(`/waiting-lists/${id}`).then(({ data }) => data);
};

export const createWaitingListItem = (data) => {
  return api.post('/waiting-lists', data).then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateWaitingListItem = ({ id, ...data }) => {
  return api.put(`/waiting-lists/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const fetchFreeDates = (config) => {
  return api.get('/waiting-lists/free-dates', config).then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteWaitingListItem = (id) => {
  return api.delete(`/waiting-lists/${id}`).then(({ data: { data } }) => data);
};
