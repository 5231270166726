import { forwardRef } from 'react';
import T from 'prop-types';
import { Button, IconButton } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import { Loader } from '../../../../components/Loader';
import { amFormatDuration } from '../../../../helpers/dates';

const propTypes = {
  activeTimer: T.object,
  isTimerFetching: T.bool,
  UIButtonsProps: T.object
};

export const TimerButton = forwardRef(({ activeTimer, isTimerFetching, UIButtonsProps }, ref) => (
  <div ref={ref}>
    <Loader loading={isTimerFetching} surface inset={false} render={
      () => !activeTimer || isTimerFetching ? (
        <IconButton color="inherit" {...UIButtonsProps}>
          <TimerIcon />
        </IconButton>
      ) : (
        <Button size="large" color="inherit" {...UIButtonsProps}>
          {amFormatDuration(activeTimer.time)}
        </Button>
      )}
    />
  </div>
));

TimerButton.propTypes = propTypes;
