import { useContext, useRef } from 'react';
import { List as MuiList, Typography, Box } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../components/TablePagination';
import { Loader } from '../../../../../components/Loader';
import { UsersContext } from '../../UsersContext';
import { Header } from './Header';
import { Row } from './Row';

export const widthBreakpointSmall = 1300;
export const widthBreakpointMedium = 1400;
export const widthBreakpointLarge = 1600;
export const minRowHeight = 54;
export const columnsWidths = {
  name: 250,
  ssn: 200,
  email: 200,
  phone_number: 180,
  role: 150,
  status: 150,
  attend: 180,
  action: 90
};

export const List = () => {
  const {
    users,
    isFetched,
    meta,
    resetUsers
  } = useContext(UsersContext);
  const scrollElementRef = useRef();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });

  return (
    <Box ref={rootRef} display="flex" flexDirection="column" height="100%">
      <Loader loading={!isFetched} p={3} render={
        () => (
          <>
            {!users.length ?
              <Box p={3}>
                <Typography align="center">No users found</Typography>
              </Box>
              :
              <>
                <Header width={width} />

                <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
                  <MuiList disablePadding>
                    {users.map((user) => (
                      <Row
                        key={user.id}
                        width={width}
                        user={user}
                      />
                    ))}
                  </MuiList>

                  <TablePagination pagination={meta} onChange={resetUsers} />
                </CustomScrollbars>
              </>
            }
          </>
        )}
      />
    </Box>
  );
};
