import { useContext, useEffect, useRef } from 'react';
import { Typography, Box } from '@material-ui/core';
import { Card } from '../../../../app/Dashboard/AppointmentsPage/Appointments/Content/Grid/Card';
import { InfiniteGridLoader } from '../../../InfiniteLoader';
import { ScrollbarsCustom } from '../../../ScrollbarsCustom';
import { Loader } from '../../../Loader';
import { AppointmentsContext } from '../../AppointmentsProvider';

export const Grid = () => {
  const {
    isFetched,
    isFetching,
    appointments,
    meta,
    loadNextPage,
    reloadAppointments
  } = useContext(AppointmentsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    reloadAppointments({
      appointment_from: null,
      appointment_to: null
    });
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%" pt={1} pl={2} pr={0} pb={0} overflow="hidden">
      <ScrollbarsCustom scrollerRef={scrollElementRef} autoHeight>
        <Loader p={2} loading={!isFetched} render={
          () => !appointments.length ? (
            <Typography align="center">No appointments found</Typography>
          ) : (
            <InfiniteGridLoader
              items={appointments}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={meta}
              CellComponent={Card}
              CellProps={{ apptHistory: true }}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={345}
              cellHeight={290}
            />
          )}
        />
      </ScrollbarsCustom>
    </Box>
  );
};
