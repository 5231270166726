import { Grid, Typography } from '@material-ui/core';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../components/UserLink';
import { formTypesMap } from '../../../../../../components/medical/forms/formTypesMap';

export const PhysicianInfo = ({ form }) => {
  const isFollowUp = form.form_type === formTypesMap.follow_up;

  return (
    <>
      <Grid item xs={isFollowUp ? 3 : 5}>
        <Typography color="textSecondary">Physician</Typography>
      </Grid>

      <Grid item xs={isFollowUp ? 9 : 7}>
        <UserLink
          size="md"
          user={form?.parent?.treating_physician || form.treating_physician}
          variant="h5"
        />
      </Grid>

      <Grid item xs={isFollowUp ? 3 : 5}>
        <Typography color="textSecondary">Office</Typography>
      </Grid>

      <Grid item xs={isFollowUp ? 9 : 7}>
        <OfficeLink
          variant="h5"
          office={form.office}
        />
      </Grid>
    </>
  );
};
