import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import {
  Box,
  Dialog,
  SvgIcon,
  Typography,
  FormControlLabel,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { mdiFileAlertOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as emailsRulesApi from '../../../../../../api/emails-rules';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../../../components/Modal';
import { FormStep, getRuleInitialValues, validationSchema } from '../../../../../../components/emails';
import { SimpleSelect, TextField, Checkbox } from '../../../../../../components/FormField';
import { transformAccountsToOptions } from '../../../../../../components/emails/options';
import { Loader } from '../../../../../../components/Loader';
import { Button } from '../../../../../../components/Button';
import { BodyActions, BodyConditions } from '../CreateEmailsRuleFormModal';

export const EmailsRuleFormModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { accounts, initialRule }
}) => {
  const accountsOptions = transformAccountsToOptions(accounts);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isTable = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const handleEditRule = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return emailsRulesApi.editEmailRule(values.id, {
      ...values,

      conditions: values?.conditions?.map((condition) => ({
        key: condition?.key?.value,

        ...(condition?.values && {
          values: condition?.values?.map((value) => value)
        })
      })),

      actions: values?.actions?.map((action) => ({
        key: action?.key?.value,

        ...(action?.value && { value: action?.value })
      }))
    }).then((updatedRule) => {
      enqueueSnackbar('Email rule edited successfully', { variant: 'success' });
      handleModalResolve(updatedRule);
    }).catch(() => {
      enqueueSnackbar('Email rule not edited', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={getRuleInitialValues({ initialRule, accountsOptions })}
      validationSchema={validationSchema}
      onSubmit={handleEditRule}
    >
      {({ isSubmitting, errors, touched, handleSubmit }) => (
        <Dialog fullScreen={isTable} fullWidth maxWidth="lg" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiFileAlertOutline} /></SvgIcon>} onClose={handleModalReject}>
              Edit Rule
            </ModalHeader>

            <ModalBody>
              <Box width={isTable ? '80%' : '50%'} ml={6.5} mb={4} pr={5.75}>
                <SimpleSelect
                  name="config_id"
                  label="Choose email account"
                  options={accountsOptions}
                />
              </Box>

              <FormStep
                withoutTitle
                checked={(touched.name && !errors.name) || (initialRule && !errors.name)}
                step={1}
              >
                <Box width={isTable ? '90%' : '50%'} mb={4} pr={2.5}>
                  <TextField
                    required
                    name="name"
                    label="Name your rule"
                    placeholder="Enter rule name..."
                  />
                </Box>
              </FormStep>

              <FormStep
                checked={(touched.conditions && !errors.conditions) || (initialRule && !errors.conditions)}
                step={2}
              >
                <Typography variant="h4" gutterBottom>Add a condition</Typography>
                <BodyConditions isEditable />
              </FormStep>

              <FormStep
                withoutLine
                checked={(touched.actions && !errors.actions) || (initialRule && !errors.actions)}
                step={3}
              >
                <Typography variant="h4" gutterBottom>Add an action</Typography>
                <BodyActions />
              </FormStep>

              <Box width={isTable ? '80%' : '50%'} ml={6.5} mb={4}>
                <FormControlLabel
                  control={<Checkbox name="stop_processing" />}
                  label="Stop processing more rules"
                />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
