import { saveAs } from 'file-saver';

export const downloadFile = ({ src, name, withLink } = {}) => {
  if (withLink) {
    const link = document.createElement('a');

    link.setAttribute('download', name);
    link.setAttribute('href', src);
    link.setAttribute('target', '_blank');

    link.style.display = 'none';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  } else {
    saveAs(src, name);
  }
};
