import { store } from '../store';

export const generateAggregateFileType = (fileType) => {
  const aggregateTypes = store.getState().apiConfig.config.files.aggregate_types;

  for (let type in aggregateTypes) if (aggregateTypes.hasOwnProperty(type)) {
    if (aggregateTypes[type].mime_types.find((mime) => mime === fileType)) {
      return type;
    }
  }
};
