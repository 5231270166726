import { useContext } from 'react';
import { ClaimRow, ClaimsContext } from '../../../index';

export const Row = ({ item: claim = {}, onSelect, ...props }) => {
  const { selectedClaimsIDs, deleteClaimsFromSelected, toggleClaimSelected } = useContext(ClaimsContext);

  const selectClaim = () => {
    deleteClaimsFromSelected(selectedClaimsIDs);
    toggleClaimSelected(claim);
    onSelect(claim);
  };

  return (
    <ClaimRow claim={claim} onClick={selectClaim} {...props} />
  );
};
