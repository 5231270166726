import { billingActivityMap } from '../activitiesHelpers/billingActivityMap';
import { getActivityComponent } from './actionTypesMap';

export const billingActionTypesMap = {
  [billingActivityMap.createInvoice]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [billingActivityMap.updateInvoice]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [billingActivityMap.deleteInvoice]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [billingActivityMap.createIncome]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [billingActivityMap.updateIncome]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [billingActivityMap.deleteIncome]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [billingActivityMap.createExpense]: (payload, logName = billingActivityMap.expense) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [billingActivityMap.updateExpense]: (payload, logName = billingActivityMap.expense) => (
    getActivityComponent({ payload, logName })
  ),

  [billingActivityMap.deleteExpense]: (payload, logName = billingActivityMap.expense) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [billingActivityMap.createClaimInvoice]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [billingActivityMap.updateClaimInvoice]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [billingActivityMap.deleteClaimInvoice]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [billingActivityMap.createAppointmentInvoice]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [billingActivityMap.updateAppointmentInvoice]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [billingActivityMap.deleteAppointmentInvoice]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [billingActivityMap.createInvoicePayment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [billingActivityMap.updateInvoicePayment]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [billingActivityMap.deleteInvoicePayment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  )
};
