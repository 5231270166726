export const styles = ({ spacing, palette }) => ({
  toolbar: {
    display: 'flex',
    padding: spacing(0, 2),
    backgroundColor: palette.grey[100],
    alignItems: 'center'
  },

  filterIcon: {
    marginBottom: -spacing(2),
    marginRight: spacing()
  },

  filterRoot: {
    display: 'flex',
    alignItems: 'center'
  },

  selectField: {
    marginRight: spacing(2)
  }
});
