import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import { Box, makeStyles } from '@material-ui/core';
import { FiltersBar as FiltersBarComponent } from '../../FiltersBar';
import { IconComponent } from '../../saved-filters';
import { saveFilter } from '../../../store/lastFilters';
import {
  ApptBooksSelect,
  ArrivalStatusesSelect,
  VisitReasonsSelect,
  ApptTypesSelect,
  OfficesLocationSelect,
  PeriodPicker
} from '../../FormField';
import { UsersSelect } from '../../users';
import { AppointmentsFilterContext } from '../index';
import { viewVariantsMap } from '../../LayoutContext';
import { fieldsKeysMap } from './fieldsKeysMap';
import { initialValues } from './initialValues';
import { CardContent } from './CardContent';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FiltersBar = ({ viewVariant, filterKey, hiddenFields = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { filter, changeFilter, relationsForFilter, setRelationsForFilter } = useContext(AppointmentsFilterContext);
  const filtersModalWidth = 1240;

  const handleVisitReasonsChange = (options) => {
    changeFilter(({ visit_reasons: options?.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, visit_reasons: options }));
  };

  const handleArrivalStatusesChange = (options) => {
    changeFilter(({ arrival_statuses: options?.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, arrival_statuses: options }));
  };

  const handleOfficesChange = (offices) => {
    changeFilter(({ offices: offices?.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, offices }));
  };

  const handleApptBooksChange = (options) => {
    changeFilter(({ appointment_books: options?.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, appointment_books: options }));
  };

  const handleApptTypesChange = (apptTypes) => {
    changeFilter(({ appointment_types: apptTypes?.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, appointment_types: apptTypes }));
  };

  const handleUsersMultiSelect = (name) => (options) => {
    const users = options?.length ? options.map(({ id }) => id) : null;

    changeFilter({ [name]: users });
    setRelationsForFilter((state) => ({ ...state, [name]: options }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(omit({
      ...filter,

      appointment_from: filter?._appointment_from,
      appointment_to: filter?._appointment_to
    }, hiddenFields));
  };

  const handlePeriodChange = ({ fromDate, toDate, period }) => {
    changeFilter({
      appointment_from: fromDate?.unix(),
      appointment_to: toDate?.unix()
    });

    setRelationsForFilter((state) => {
      return Object.assign({
        ...state,

        appointment_from: fromDate?.unix(),
        appointment_to: toDate?.unix(),

        _period: period
      }, !period ? {} : {
        _appointment_from: fromDate?.unix(),
        _appointment_to: toDate?.unix()
      });
    });
  };

  const handlePeriodHide = () => {
    handlePeriodChange({ fromDate: null, toDate: null });
  };

  useEffect(() => {
    if (filterKey) {
      dispatch(saveFilter({
        key: filterKey,
        filter: relationsForFilter
      }));
    }
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (filterKey && lastFilters) {
      applySavedFilter(omit(lastFilters, hiddenFields));
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      className={classes.root}
      ToolbarProps={{ className: classes.toolbar }}
      onReset={() => changeFilter(initialValues)}
      filterKey={filterKey}
      iconComponent={(
        <IconComponent
          modalWidth={filtersModalWidth}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      hiddenFields={hiddenFields}
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.providers,
          label: 'Providers',
          field: (
            <UsersSelect
              multiple
              name="providers"
              label="Providers"
              params={{ role: 'doctor' }}
              onChange={handleUsersMultiSelect('providers')}
              minWidth={210}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.offices,
          label: 'Offices',
          field: (
            <OfficesLocationSelect
              multiple
              name="offices"
              label="Offices"
              params={{ users: filter.providers }}
              onChange={handleOfficesChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.apptBooks,
          label: 'Appt. books',
          field: (
            <ApptBooksSelect
              multiple
              name="appointment_books"
              label="Appt. books"
              params={{ providers: filter.providers, offices: filter.offices }}
              onChange={handleApptBooksChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.patients,
          label: 'Patients',
          field: (
            <UsersSelect
              multiple
              name="patients"
              label="Patients"
              params={{ is_patient: 1 }}
              onChange={handleUsersMultiSelect('patients')}
              minWidth={210}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.arrivalStatuses,
          label: 'Arrival statuses',
          field: (
            <Box minWidth={210}>
              <ArrivalStatusesSelect
                multiple
                name="arrival_statuses"
                label="Arrival statuses"
                onChange={handleArrivalStatusesChange}
              />
            </Box>
          )
        },
        {
          fieldKey: fieldsKeysMap.appointmentTypes,
          label: 'Appointment types',
          field: (
            <Box minWidth={210}>
              <ApptTypesSelect
                multiple
                name="appointment_types"
                label="Appointment types"
                onChange={handleApptTypesChange}
              />
            </Box>
          )
        },
        {
          fieldKey: fieldsKeysMap.visitReasons,
          label: 'Visit reasons',
          field: (
            <VisitReasonsSelect
              multiple
              name="visit_reasons"
              label="Visit reasons"
              onChange={handleVisitReasonsChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.period,
          label: 'Period',
          field: viewVariant !== viewVariantsMap.calendar && (
            <PeriodPicker
              initialPeriod={lastFilters?._period}
              fromDate={lastFilters?._appointment_from}
              toDate={lastFilters?._appointment_to}
              onChange={handlePeriodChange}
            />
          ),
          onHide: handlePeriodHide
        }
      ]}
    />
  );
};
