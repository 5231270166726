import {
  Appointment,
  Date,
  Text,
  Money,
  Provider,
  Office,
  Insurance,
  Status,
  Bool,
  Case,
  Authorization
} from '../../../Activities/fields';

export const fieldsMap = {
  authorization_id: (before, after) => <Authorization before={before} after={after} />,
  corrected_total: (before, after) => <Money before={before} after={after} fieldLabel="corrected total price" />,
  referral_number: (before, after) => <Text before={before} after={after} fieldLabel="referral #" />,
  provider_rendering: (before, after) => <Provider before={before} after={after} fieldLabel="rendering" />,
  provider_referring: (before, after) => <Provider before={before} after={after} fieldLabel="referring" />,
  provider_primary_care: (before, after) => <Provider before={before} after={after} fieldLabel="primary care" />,
  provider_ordering: (before, after) => <Provider before={before} after={after} fieldLabel="ordering" />,
  provider_supervising: (before, after) => <Provider before={before} after={after} fieldLabel="supervising" />,
  office: (before, after) => <Office before={before} after={after} fieldLabel="appointment" />,
  insurance_id: (before, after) => <Insurance before={before} after={after} />,
  hospitalization_from: (before, after) => <Date before={before} after={after} fieldLabel="hospitalization from" />,
  hospitalization_to: (before, after) => <Date before={before} after={after} fieldLabel="hospitalization to" />,
  local_use_19: (before, after) => <Text before={before} after={after} fieldLabel="local use 19" />,
  note: (before, after) => <Text before={before} after={after} fieldLabel="note" />,
  is_use_clia: (before, after) => <Bool before={before} after={after} fieldLabel="use CLIA" />,
  is_use_last_dx: (before, after) => <Bool before={before} after={after} fieldLabel="use last DX" />,
  is_paper_claim: (before, after) => <Bool before={before} after={after} fieldLabel="paper claim" />,
  is_auth_required: (before, after) => (
    <Bool before={before} after={after} fieldLabel="authorization no. not required" />
  ),
  status: (before, after) => <Status before={before} after={after} />,
  case: (before, after) => <Case before={before} after={after} fieldLabel="Case" />,
  appointment: (before, after) => <Appointment before={before} after={after} />
};
