import { createContext, useReducer, useEffect, useRef, useState } from 'react';
import { merge, omit, isEqual } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import { api } from '../../../../../api';
import * as reminderEventsApi from '../../../../../api/cases/reminders/events';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CaseReminderEventsContext = createContext(null);

export const CaseReminderEventsProvider = ({ children, filter: filterProp = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    reminderEvents
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});
  const [ relationsForFilter, setRelationsForFilter ] = useState([]);

  const fetchReminderEvents = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_REMINDER_EVENTS_REQUEST });

    reminderEventsApi.fetchCaseReminderEvents({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_REMINDER_EVENTS_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchReminderEvents({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const applyRelationsForFilter = (newRelationsForFilter) => {
    setRelationsForFilter(() => ({
      ...state,
      ...newRelationsForFilter
    }));
  };

  const resetReminderEvents = (newFilter) => {
    dispatch({ type: types.RESET_REMINDER_EVENTS, payload: newFilter });
    fetchReminderEvents({ page: 1, ...newFilter });
  };

  const addReminderEvent = (reminderEvent) => {
    dispatch({ type: types.ADD_REMINDER_EVENT, payload: reminderEvent });
  };

  const updateReminderEvent = (reminderEvent) => {
    dispatch({ type: types.UPDATE_REMINDER_EVENT, payload: reminderEvent });
  };

  const deleteReminderEvent = ({ id }) => {
    dispatch({ type: types.DELETE_REMINDER_EVENT, payload: id });
  };

  const providerValue = {
    isFetched,
    isFetching,
    filter: { ...filter, ...pagination },
    relationsForFilter,
    reminderEvents,

    // functions
    addReminderEvent,
    updateReminderEvent,
    deleteReminderEvent,
    applyFilter,
    applyRelationsForFilter,
    resetReminderEvents,
    loadNextPage
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetReminderEvents(newFilter);
    }
  }, [ prevFilter, filter ]);

  return (
    <CaseReminderEventsContext.Provider value={providerValue}>
      {children}
    </CaseReminderEventsContext.Provider>
  );
};
