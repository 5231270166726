import { Text, CurrencyFormat, UserActivityLink, IncomeTime, Case } from '../../fields';

export const incomeFieldsMap = {
  participant: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Participant" />,
  user: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="User" />,
  notes: (before, after) => (
    <Text
      before={before}
      after={after}
      fieldLabel="Notes"
      disableSemicolon
    />
  ),
  rate: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Rate" />,
  total: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Total" />,
  time: (before, after) => <IncomeTime before={before} after={after} fieldLabel="Time" />,
  parent: (before, after) => <Case before={before} after={after} fieldLabel="Parent" />
};
