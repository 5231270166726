export const styles = ({ palette, breakpoints }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 50,
    border: `1px solid ${palette.primary.main}`,
    width: 50,
    minWidth: 50,
    height: 50,
    flexGrow: 0,

    [breakpoints.up('sm')]: {
      width: 64,
      height: 64,
      maxHeight: 64,
      minWidth: 64
    }
  }
});
