import * as eventsApi from '../../../../../api/cases/reminders/events';
import { Autocomplete } from '../../../../FormField';

const fetchCaseReminderEvents = ({ params, hiddenEvent }) => {
  return ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
    return eventsApi.fetchCaseReminderEvents({
      params: {
        search,
        page: page + 1,
        is_active: 1,

        ...params
      }
    }).then(({ data, pagination }) => {
      const filteredEvents = hiddenEvent?.id ? data?.filter(({ id }) => id !== hiddenEvent?.id) : data;

      return {
        hasMore: pagination.page < pagination.last_page && pagination.total > 0,
        options: loadedOptions.concat(filteredEvents),
        additionalData: {
          page: pagination.page
        }
      };
    });
  };
};

const getOptionLabel = (event) => {
  return event && `${event?.position || ''} ${event?.type} - ${event?.description}`;
};

export const CaseReminderEventsSelect = ({ params = {}, hiddenEvent, ...props }) => {
  return (
    <Autocomplete
      isAsync
      label="Reminder events"
      placeholder="Search event..."
      onNeedFetch={fetchCaseReminderEvents({ params, hiddenEvent })}
      getOptionLabel={getOptionLabel}
      getOptionValue={(option) => option?.id}
      getOptionSelected={(option, value) => option.id === value?.id}

      {...props}
    />
  );
};
