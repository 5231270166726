import { chat } from './chat';
import { contacts } from './contacts';
import { emails } from './emails';
import { schedule } from './schedule';
import { workSessions } from './workSessions';

export const medicMenuList = [
  schedule,
  emails,
  contacts,
  chat,
  workSessions
];
