import { useContext, useEffect, useRef } from 'react';
import { Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Fab } from '../../../../../components/Fab';
import { ScheduleEventTypeFormModal } from '../ScheduleEventTypeFormModal';
import { ScheduleTypesContext } from '../ScheduleTypesProvider';
import { Grid } from '../Grid';
import { List } from '../List';

const BREAKPOINT_WIDTH = 800;

export const PageContent = () => {
  const theme = useTheme();
  const rootRef = useRef();
  const { openModal } = useModal();
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { resetEventTypes } = useContext(ScheduleTypesContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const addEventType = (resetEventTypes) => () => {
    openModal(ScheduleEventTypeFormModal, {
      onModalResolved: () => {
        resetEventTypes();
      }
    });
  };

  useEffect(() => {
    if (width < BREAKPOINT_WIDTH) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width ]);

  return (
    <Page ref={rootRef}>
      <PageHeader>
        <PageHeaderTitle>
          Schedule event types
        </PageHeaderTitle>

        <PageHeaderActions>
          <Fab
            variant="extended"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addEventType(resetEventTypes)}
          >
            Add {!isMobile && 'schedule event type'}
          </Fab>

          {!(width < BREAKPOINT_WIDTH) &&
            <>
              <Tooltip arrow title="List view">
                <IconButton
                  color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                  onClick={toggleViewVariant(viewVariantsMap.list)}
                >
                  <ViewListIcon />
                </IconButton>
              </Tooltip>

              <Tooltip arrow title="Grid view">
                <IconButton
                  edge="end"
                  color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                  onClick={toggleViewVariant(viewVariantsMap.grid)}
                >
                  <ViewModuleIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        </PageHeaderActions>
      </PageHeader>

      <PageBody disableScrollbars fullHeight disablePaddings={isMobile || isTablet}>
        {(viewVariant === viewVariantsMap.list) && <List />}
        {(viewVariant === viewVariantsMap.grid) && <Grid />}
      </PageBody>
    </Page>
  );
};
