import { forwardRef } from 'react';
import { getUserNameAbbr } from '../../../helpers/users';
import { Avatar } from '../../Avatar';
import { OnlineIndicator } from './OnlineIndicator';
import { RegisterIndicator } from './RegisterIndicator';

export const UserAvatar = forwardRef(({
  disableOnlineIndicator = false,
  size,
  user,

  ...props
}, ref) => {
  return !!user && (
    <OnlineIndicator invisible={disableOnlineIndicator} user={user}>
      <RegisterIndicator user={user}>
        <Avatar
          ref={ref}
          size={size}
          src={user?.profile?.avatar || user?.avatar}
          alt={getUserNameAbbr(user)}

          {...props}
        />
      </RegisterIndicator>
    </OnlineIndicator>
  );
});
