import { Dialog, Button, List, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import { addEmailAccount, deleteEmailAccount } from '../../../../../../../store/dashboard/profile';
import {
  ModalContainer,
  ModalHeader,
  ModalHeaderActions,
  ModalBody
} from '../../../../../../../components/Modal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { EmailsAddAccountModal } from './EmailsAddAccountModal';
import { EmailAccountItem } from './EmailAccountItem';

export const EmailsAccountSettingsModal = ({
  DialogProps,
  handleModalReject,
  payload: { onListUpdate = () => {} }
}) => {
  const dispatch = useDispatch();
  const accounts = useSelector(({ profile }) => profile.user?.email_accounts);
  const { openModal } = useModal();

  const handleDelete = (accountId) => {
    dispatch(deleteEmailAccount(accountId));
    onListUpdate();
  };

  const openAddAccountModal = () => {
    openModal(EmailsAddAccountModal, {
      onModalResolved: (account) => {
        dispatch(addEmailAccount(account));

        onListUpdate();
      }
    });
  };

  return (
    <Dialog {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Account settings

          <ModalHeaderActions>
            <Button color="primary" startIcon={<AddIcon />} onClick={openAddAccountModal}>
              Add account
            </Button>
          </ModalHeaderActions>
        </ModalHeader>

        <ModalBody>
          {!accounts.length ? (
            <Typography align="center">There is no accounts</Typography>
          ) : (
            <List component="nav">
              {accounts.map((account) => (
                <EmailAccountItem
                  key={account.id}
                  account={account}
                  onDelete={handleDelete}
                />
              ))}
            </List>
          )}
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
