import { createContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../../../api';
import * as statisticsApi from '../../../../../api/statistics';
import { usePrevious } from '../../../../../helpers/hooks';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const TimeCaseContext = createContext();

export const TimeCaseContextProvider = ({ initialState: initialStateProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const {
    statistics,
    pagination,
    isFetching,
    isFetched,
    filter
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchStatistics = (newFilter = {}) => {
    cancelFetch.current();
    dispatch({ type: types.FETCH_CASE_TIME_STATISTICS_REQUEST });

    statisticsApi.fetchTimeCaseStatistics({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then((data) => {
      dispatch({ type: types.FETCH_CASE_TIME_STATISTICS_SUCCESS, payload: data });
    });
  };

  const resetStatistics = (newFilter) => {
    dispatch({ type: types.RESET_CASE_TIME_STATISTICS, payload: newFilter });

    fetchStatistics({ page: 1, ...newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchStatistics({ page: filter.page + 1 });
    }
  };

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetStatistics(filter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  const providerValue = {
    statistics,
    isFetched,
    isFetching,
    filter: {
      ...filter,
      ...pagination
    },

    resetStatistics,
    loadNextPage
  };

  return (
    <TimeCaseContext.Provider value={providerValue}>
      {children}
    </TimeCaseContext.Provider>
  );
};
