import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NotesIcon from '@material-ui/icons/Notes';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../components/IconButton';
import { TextField } from '../../../../../../../components/FormField';
import {
  columnsWidthsLg,
  columnsWidthsSm
} from '../../../../../../../components/medical/reports';
import {
  MotorStrength,
  RangeMotion,
  ReflexLower
} from '../CervicalSpine';
import { Body } from './Body';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const LumbarSpine = ({ onDelete }) => {
  const classes = useStyles();
  const formPartName = 'forms.physical_examination.lumbar';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <>
      <Box mt={4} mb={2} color="info.main" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" color="inherit">
          Spinal examination-Lumbar spine
        </Typography>

        {onDelete && (
          <IconButton color="error" onClick={onDelete}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <Typography variant="h5">
        <Box component="span" mr={0.5}>
          <NotesIcon fontSize="small" />
        </Box>
        Note
      </Typography>

      <TextField
        fullWidth
        name={`${formPartName}.spinal_examination_lumbar_spine.note`}
      />

      <Box my={2}>
        <Typography paragraph variant="caption" color="textSecondary">
          Palpation of the spine reveals tenderness (graded 0=none; 1+=mild; 2+=moderate; and
          3+=marked) in the following regions:
        </Typography>
      </Box>

      <div className={classes.root}>
        <ListRow header>
          <ListRowCell noWrap grow flexBasis={columnsWidths.name} />

          <ListRowCell flexBasis={columnsWidths.right}>
            <Typography variant="subtitle2" color="textSecondary">
              Right
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.midline}>
            <Typography variant="subtitle2" color="textSecondary">
              Midline
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.left}>
            <Typography variant="subtitle2" color="textSecondary">
              Left
            </Typography>
          </ListRowCell>
        </ListRow>

        <ListRow>
          <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
            <Typography noWrap variant="h5">
              Paraspinal Muscle Tenderness:
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.right}>
            <TextField
              fullWidth
              zeroMinWidth
              disableUnderline
              placeholder="Add..."
              name={`${formPartName}.spinal_examination_lumbar_spine.paraspinal_muscle_tenderness.right`}
            />
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.midline}>
            <TextField
              fullWidth
              zeroMinWidth
              disableUnderline
              placeholder="Add..."
              name={`${formPartName}.spinal_examination_lumbar_spine.paraspinal_muscle_tenderness.midline`}
            />
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.left}>
            <TextField
              fullWidth
              zeroMinWidth
              disableUnderline
              placeholder="Add..."
              name={`${formPartName}.spinal_examination_lumbar_spine.paraspinal_muscle_tenderness.left`}
            />
          </ListRowCell>
        </ListRow>
      </div>

      <RangeMotion name={'forms.physical_examination.lumbar.range_of_motion_cervical_spine'} />

      <MotorStrength
        isLumbarSpine
        name={'forms.physical_examination.lumbar.motor_strenght_lover_extremities'}
      />

      <ReflexLower
        isLumbarSpine
        name={'forms.physical_examination.lumbar.reflexes_lower_extremities'}
      />

      <Body formPartName={formPartName} />
    </>
  );
};
