import { api } from '../api';

export const getRuleTemplates = (config = {}) => {
  return api.get('/rule-templates', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createRuleTemplate = (config) => {
  return api.post('/rule-templates', config)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const editRuleTemplate = (id, config) => {
  return api.put(`/rule-templates/${id}`, config)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteRuleTemplate = (id) => {
  return api.delete(`/rule-templates/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
