import { useFormikContext } from 'formik';
import { Box, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {
  billingParticipantsTypesMap,
  billingParticipantsTypesOptions
} from '../../../../../../../components/billing';
import { Autocomplete, CompaniesAutocomplete } from '../../../../../../../components/FormField';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { UsersSelect } from '../../../../../../../components/users';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FilterHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { values, setFieldValue } = useFormikContext();

  const handleCompanyChange = (company) => {
    if (company) {
      setFieldValue('payer.info', company);
    }
  };

  const handleUsersSelectChange = (user) => {
    if (user) {
      setFieldValue('payer.info', user);
    }
  };

  const handleParticipantTypeChange = () => {
    setFieldValue('payer.id', null);
    setFieldValue('payer.info', null);
  };

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      component={Box}
      py={1}
      px={isMobile ? 2 : 4}
      bgcolor="grey.50"
    >
      <Grid item xs={isMobile ? 12 : 7} container spacing={isMobile ? 1 : 2}>
        <Grid item xs={isMobile ? 12 : 6}>
          <Autocomplete
            required
            name="payer.type"
            label="Select participant type"
            options={billingParticipantsTypesOptions}
            onChange={handleParticipantTypeChange}
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 6}>
          {(values?.payer?.type?.value || values?.payer?.type ) === billingParticipantsTypesMap.user &&
            <UsersSelect
              required
              name="payer.id"
              label="Select user"
              onChange={handleUsersSelectChange}
            />
          }

          {(values?.payer?.type?.value || values?.payer?.type ) === billingParticipantsTypesMap.company &&
            <CompaniesAutocomplete
              required
              name="payer.id"
              label="Select company"
              onChange={handleCompanyChange}
            />
          }
        </Grid>
      </Grid>

      <Grid item xs={isMobile ? 12 : 5}>
        <Box display="flex" flexDirection="column" alignItems="flex-end" mr={4} mt={isMobile ? 1 : 0}>
          <Typography color="textSecondary" variant="body2">Amount paid</Typography>

          <CurrencyFormat value={values.amount} className={classes.currencyFormat} />
        </Box>
      </Grid>
    </Grid>
  );
};
