import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControlLabel, Switch } from '@material-ui/core';
import { MedicalReportContent } from '../../../../../../../components/Widgets';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { MedicalFormsProvider } from '../../../MedicalForms/MedicalFormsProvider';
import { MedicalProfileContext } from '../../MedicalProfileProvider';

export const MedicalReport = ({
  isOpen,
  height = '100%',
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { user } = useSelector(({ profile }) => profile);
  const { medicalInfoPathname } = useContext(MedicalProfileContext);
  const [ isOpenSwitches, setIsOpenSwitches ] = useState(false);
  const initialState = {
    filter: {
      medical_id: user?.medical?.id
    },
    medicalInfoPathname
  };

  const handleSwitchChange = (event) => {
    setIsOpenSwitches(event.target.checked);
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      isResizable={isOpen}
      title="Medical Report"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      endIcon={
        <FormControlLabel
          label="Open all"
          control={
            <Switch
              color="primary"
              checked={isOpenSwitches}
              onChange={handleSwitchChange}
            />
          }
        />
      }
      {...props}
    >
      <MedicalFormsProvider initialState={initialState}>
        <Box display="flex" flexDirection="column" height={height} overflow="hidden">
          <MedicalReportContent
            isOpenSwitches={isOpenSwitches}
            userID={user?.id}
            toggleSwitches={setIsOpenSwitches}
          />
        </Box>
      </MedicalFormsProvider>
    </ContentCard>
  );
};
