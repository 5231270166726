import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { itemsRoutesByModels } from '../../../../../../../dataMaps/apiModels';
import { CardItem, CardHeader } from '../../../../../../../components/Cards';
import {
  PaymentsContext,
  PaymentMenu,
  PaymentModal
} from '../../../../../../../components/billing/payments';
import { columnsMap } from '../../List';
import { OverdraftPaymentMenu } from './OverdraftPaymentMenu';
import { OverdraftBody } from './OverdraftBody';
import { Body } from './Body';
import { Footer } from './Footer';

export const Card = ({ item: payment = {}, hiddenColumns = [] }) => {
  const history = useHistory();
  const { selectedPaymentsIDs, filter, ...paymentsContext } = useContext(PaymentsContext);
  const isSelected = selectedPaymentsIDs.indexOf(payment.id) !== -1;
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const goToPaymentsPage = (event) => {
    if (!isMobile) {
      if (!event.target.closest('[href]')) {
        history.push(itemsRoutesByModels.payment(payment.id));
      }
    } else {
      openModal(PaymentModal, {
        payload: {
          initialValues: {
            title: payment.number,
            id: payment.id
          }
        }
      });
    }
  };

  const handleItemSelection = () => {
    paymentsContext.toggleItemSelection(payment.id);
  };

  return (
    <CardItem
      selected={isSelected}
      py={1}
      px={2}
      onClick={stopPropagation(goToPaymentsPage)}
    >
      <CardHeader
        disableCheckBox={hiddenColumns.includes(columnsMap.checkbox)}
        isSelected={isSelected}
        onItemSelect={handleItemSelection}
        menuComponent={filter?.overdraft > 0 ? OverdraftPaymentMenu : PaymentMenu}
        menuComponentProps={{ payment, history }}
      >
        <Box display="flex" alignItems="flex-start">
          <Typography noWrap title={payment.number} variant="h6">
            {payment.number}
          </Typography>
        </Box>
      </CardHeader>

      {filter?.overdraft > 0 ? (
        <OverdraftBody payment={payment} />
      ) : (
        <Body payment={payment} />
      )}

      <Footer payment={payment} />
    </CardItem>
  );
};
