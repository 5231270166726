export const styles = {
  root: {
    flexGrow: 1,
    minHeight: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  fullSize: {
    height: '100%',
    width: '100%'
  }
};
