import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import * as filesApi from '../../../api/files';
import { useModal } from '../../../components/ModalsProvider';
import { getAggregateTypeFromMIME } from '../../../helpers/files';
import { generateAggregateFileType } from '../../../utils/generateAggregateFileType';
import { FileValidationConflictModal } from './FileValidationConflictModal';
import { SelectAvailableFilesModal } from './SelectAvailableFilesModal';

export const useFileAttachments = ({
  multiple = false,
  initialFile = null,
  owner_type,
  owner_id,
  initialAttachments = [],
  onChangeFiles = () => {},
  onChangeAvailableFilesIDs = () => {},
  onAttachmentsChange = () => {}
} = {}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ files, setFiles ] = useState(initialFile ? [].concat(initialFile) : []);
  const [ availableFiles, setAvailableFiles ] = useState([]);
  const [ attachments, setAttachments ] = useState(initialAttachments);
  const media = useMemo(() => (
    [ ...(files || []), ...(availableFiles || []), ...(attachments || []) ]
  ), [ files, availableFiles, attachments ]);

  const validateFile = (file) => {
    file.original_filename = file.original_filename || file.name;
    file.owner_type = owner_type;
    file.owner_id = owner_id;
    file.aggregate_type = generateAggregateFileType(file.type);

    if (files.find(({ original_filename }) => original_filename === file.original_filename)) {
      openModal(FileValidationConflictModal, {
        payload: { file },
        onModalResolved: (file) => {
          validateFile(file);
        }
      });
    } else {
      filesApi.validateFile(file).then(() => {
        file.aggregate_type = getAggregateTypeFromMIME(file.type);

        if (!multiple) {
          setAvailableFiles([]);
          setAttachments([]);
        }

        setFiles((state) => multiple ? [ file ].concat(state) : [ file ]);
      }).catch(({ status }) => {
        if (status === 409) {
          openModal(FileValidationConflictModal, {
            payload: { file },
            onModalResolved: (file) => {
              validateFile(file);
            }
          });
        } else {
          enqueueSnackbar(`File "${file.original_filename}" is invalid`, {
            variant: 'error'
          });
        }
      });
    }
  };

  const acceptFiles = (acceptFiles) => {
    const isExistFile = (file) => {
      return files.find(({ name, lastModified }) => {
        return name === file.name && lastModified === file.lastModified;
      });
    };

    acceptFiles.forEach((file) => {
      if (!files.length || !isExistFile(file)) {
        validateFile(file);
      }
    });
  };

  const acceptAvailableFiles = (files) => {
    const isExistFile = (file) => availableFiles.find(({ id }) => id === file.id);
    const acceptedAvailableFiles = files.filter((file) => !isExistFile(file));

    if (!multiple) {
      setFiles([]);
      setAttachments([]);
    }

    setAvailableFiles((prevFiles) => {
      return multiple ? [ ...prevFiles, ...acceptedAvailableFiles ] : acceptedAvailableFiles;
    });
  };

  const openAvailableFilesModal = () => {
    openModal(SelectAvailableFilesModal, {
      payload: {
        isSingle: !multiple,
        disableCoping: true
      },
      onModalResolved: acceptAvailableFiles
    });
  };

  const removeFile = (file, index) => {
    if (file.isAttachment) {
      setAttachments((prevFiles) => prevFiles.filter((currentFile) => currentFile.id !== file.id));
    } else if (file.id) {
      setAvailableFiles((prevFiles) => prevFiles.filter((currentFile) => currentFile.id !== file.id));
    } else {
      setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
    }
  };

  const clear = () => {
    setAttachments([]);
    setAvailableFiles([]);
    setFiles([]);
  };

  const { getInputProps: getFileInputProps, open: openUploadFileDialog } = useDropzone({
    multiple,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: acceptFiles
  });

  useEffect(() => {
    onChangeFiles(files);
  }, [ files ]);

  useEffect(() => {
    const filesIDs = availableFiles.map((file) => file.id);

    onChangeAvailableFilesIDs(filesIDs);
  }, [ availableFiles ]);

  useEffect(() => {
    onAttachmentsChange?.(attachments);
  }, [ attachments ]);

  return {
    media,
    openAvailableFilesModal,
    openUploadFileDialog,
    removeFile,
    getFileInputProps,
    clear
  };
};
