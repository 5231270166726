import { Paper, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../ApptList';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell flexBasis={columnsWidths.date}>
          Appt. Date/Time
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.apptBook}>
          <Typography noWrap variant="h5">
            Appt. Book
          </Typography>
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.office}>
          Office
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.provider}>
          Provider
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.confirm}>
          Confirmed
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.status}>
          <Typography noWrap variant="h5">
            Appt. Status
          </Typography>
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
