import { useFormikContext } from 'formik';
import { Grid, Typography, InputAdornment } from '@material-ui/core';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { KeyboardDatePicker, Select, TextField } from '../../../FormField';
import { providerTypeMap } from '../providerTypeMap';

export const WorkInfo = () => {
  const { values } = useFormikContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Work Info</Typography>
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="work.position"
          label="Position"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="work.department"
          label="Department"
        />
      </Grid>

      <Grid item sm={6} xs={12} lg={3}>
        <KeyboardDatePicker
          clearable
          name="work.start_date"
          label="Getting started work date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={6} xs={12} lg={3}>
        <KeyboardDatePicker
          clearable
          name="work.end_date"
          label="End of work date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      {hasRole(rolesMap.admin, rolesMap.supervisor) ? (
        <Grid item xs={6}>
          <TextField
            type="number"
            name="rate"
            label="Rate"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  $
                </InputAdornment>
              )
            }}
          />
        </Grid>
      ) : (
        <Grid item xs={6} />
      )}

      {(values.role === 'medic' || values.role === 'doctor') &&
        <>
          <Grid item sm={6} xs={12}>
            <Select
              formattedValue
              name="work.provider_type"
              label="Provider Type"
              options={providerTypeMap}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">PQRS Category</Typography>

            <TextField
              fullWidth
              multiline
              rows={3}
              name="work.pqrs_category"
              variant="filled"
              InputProps={{
                disableUnderline: true
              }}
            />
          </Grid>
        </>
      }
    </Grid>
  );
};
