import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { flow } from 'lodash';
import {
  makeStyles,
  List,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import AddCommentIcon from '@material-ui/icons/AddCommentOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFileOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { Tooltip } from '../../../../components/Tooltip';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { addSocketJobID, jobsTypes } from '../../../../store/socketJobs';
import * as filesApi from '../../../../api/files';
import { useModal } from '../../../../components/ModalsProvider';
import { TasksContext } from '../../../../components/tasks/TasksProvider';
import { hasRole } from '../../../../utils/hasRole';
import { FileUploadModal } from '../../files-common';
import { tabsMap } from '../PreviewTaskModal/taskMap';

const styles = () => ({
  root: {
    minWidth: 172
  }
});

const useStyles = makeStyles(styles);

export const TaskMenu = ({ task, disablePreview = false, taskPreviewContext, onClose = () => {} }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const currentUser = useSelector(({ profile }) => profile.user);
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { openModal } = useModal();
  const tasksContext = useContext(TasksContext);
  const isTaskMember = hasRole(rolesMap.admin) || !!task?.users?.find((user) => user?.id === currentUser?.id);

  const openTask = () => {
    if (!disablePreview) {
      taskPreviewContext ? history.push(`/tasks/${task.id}`, { isUpdate: false } ) : tasksContext.openPreview(task);
    } else {
      history.push(`/tasks/${task.id}`, { isUpdate: false } );
    }
  };

  const openPreview = () => {
    tasksContext.openPreview(task);
  };

  const deleteTask = () => {
    tasksContext.deleteTask(task.id, history);
  };

  const copyTask = () => {
    tasksContext.copyTask(task.id);
  };

  const updateTask = () => {
    taskPreviewContext ? history.push(`/tasks/${task.id}`, { isUpdate: true } ) : tasksContext.updateTask(task);
  };

  const uploadFiles = () => {
    openModal(FileUploadModal, {
      payload: {
        owner_type: 'task',
        owner_id: task.id
      }
    });
  };

  const addComment = () => {
    history.push(`/tasks/${task.id}`, { isUpdate: false, activeTab: tabsMap.comment } );
  };

  const downloadFiles = () => {
    filesApi.createArchive({ tasks: [ task.id ] }).then(({ job_id, ...props }) => {
      dispatch(addSocketJobID({ type: jobsTypes.archives, jobID: job_id }));
    });
  };

  return (
    <List className={classes.root}>
      {disablePreview && !isMobile &&
        <MenuItem onClick={flow(openPreview, onClose)}>
          <ListItemIcon>
            <EyeIcon fontSize="inherit" />
          </ListItemIcon>

          <ListItemText primary="Preview" />
        </MenuItem>
      }

      <MenuItem onClick={flow(openTask, onClose)}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      <MenuItem onClick={flow(updateTask, onClose)}>
        <ListItemIcon>
          <EditIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={flow(copyTask, onClose)}>
        <ListItemIcon>
          <FileCopyIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Copy" />
      </MenuItem>

      <Divider />

      <MenuItem onClick={flow(uploadFiles, onClose)}>
        <ListItemIcon>
          <AttachFileIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Add files" />
      </MenuItem>

      <Tooltip isExistTooltip={!isTaskMember} title="Available only to task participants">
        <div>
          <MenuItem disabled={!isTaskMember} onClick={flow(downloadFiles, onClose)}>
            <ListItemIcon>
              <DownloadIcon fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Download files" />
          </MenuItem>
        </div>
      </Tooltip>

      <Divider />

      <MenuItem onClick={flow(addComment, onClose)}>
        <ListItemIcon>
          <AddCommentIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Add comment" />
      </MenuItem>

      <Divider />

      <MenuItem onClick={flow(deleteTask, onClose)}>
        <ListItemIcon>
          <SvgIcon fontSize="inherit">
            <Icon path={mdiTrashCanOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
