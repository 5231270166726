import { palette } from '../../themes/palette';
import { measureTypes } from './selectMap';

export const bmiTypes = {
  underweight: 'underweight',
  normal: 'normal',
  overweight: 'overweight',
  obese: 'obese'
};

export const bmiTypesNames = {
  [bmiTypes.underweight]: 'Underweight',
  [bmiTypes.normal]: 'Normal',
  [bmiTypes.overweight]: 'Overweight',
  [bmiTypes.obese]: 'Obese'
};

export const bmiTypesColors = {
  [bmiTypes.underweight]: palette().slategray,
  [bmiTypes.normal]: palette().success.main,
  [bmiTypes.overweight]: palette().warning.main,
  [bmiTypes.obese]: palette().error.main
};

export const getCategoryBMI = (bmi) => {
  return bmi <= 18.5
    ? bmiTypes.underweight
    : bmi <= 25
      ? bmiTypes.normal
      : bmi <= 30
        ? bmiTypes.overweight
        : bmiTypes.obese;
};

export const getBMI = ({ height_unit, weight, height = 0 }) => {
  const multipleHeight = (height * height).toFixed(2) || 0;

  return height > 0 && height_unit
    ? (height_unit === measureTypes.m && weight)
      ? (weight / multipleHeight).toFixed(2)
      : (703 * weight / multipleHeight).toFixed(2)
    : 0;
};
