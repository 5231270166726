import cn from 'classnames';
import { Fragment, forwardRef } from 'react';
import T from 'prop-types';
import { Typography, Link as MuiLink, Box, Link, makeStyles } from '@material-ui/core';
import { PreviewCompanyModal } from '../../app/Dashboard/Contacts/Companies/PreviewCompanyModal';
import { stopPropagation } from '../../helpers/dom';
import { Avatar } from '../Avatar';
import { useModal } from '../ModalsProvider';
import { Tooltip } from '../Tooltip';
import { styles } from '../UserLink/styles';

const useStyles = makeStyles(styles);

const propTypes = {
  company: T.object,
  path: T.string,
  size: T.string,
  tooltipTitle: T.oneOfType([ T.node, T.string ]),
  noWrap: T.bool
};

export const CompanyLink = forwardRef(({
  size = 'md',
  disablePreview = false,
  disableLink = false,
  isExistTooltip = false,
  tooltipTitle,
  noWrap,
  company,
  path,
  children,
  childrenProps,
  parentProps = {},
  disableAvatar,
  className,

  ...props
}, ref) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const Wrapper = noWrap ? Fragment : Box;
  const wrapperProps =  noWrap ? {} : {
    display: 'flex',
    alignItems: !children ? 'center' : 'flex-start',
    ref,

    ...parentProps
  };

  const openPreview = () => {
    if (!disablePreview) {
      openModal(PreviewCompanyModal, {
        payload: { companyID: company?.id }
      });
    }
  };

  const companyLinkProps = !path ? {
    component: !disableLink ? Link : 'p',
    onClick: stopPropagation(openPreview),
    ...props
  } : {
    variant: 'h5',
    target: '_blank',
    component: Link,
    href: path,
    onClick: stopPropagation(),

    ...props
  };

  const CompanyLinkWrapper = disableLink ? Typography : MuiLink;

  return !company ? (
    <Typography color="textSecondary">No info.</Typography>
  ) : (
    <Wrapper {...wrapperProps}>
      {!disableAvatar &&
        <Avatar
          size={size}
          src={company?.avatar}
          alt={company?.name?.[0]}
        />
      }

      <Typography noWrap className={cn(classes.content, className)} component="div">
        <Tooltip
          isExistTooltip={isExistTooltip}
          title={tooltipTitle}
        >
          <CompanyLinkWrapper
            noWrap
            title={!isExistTooltip ? company?.name : undefined}

            {...companyLinkProps}
          >
            {company?.name}
          </CompanyLinkWrapper>
        </Tooltip>

        {children &&
          <Box overflow="hidden" textOverflow="ellipsis" {...childrenProps}>
            {children}
          </Box>
        }
      </Typography>
    </Wrapper>
  );
});

CompanyLink.propTypes = propTypes;
