import cn from 'classnames';
import { makeStyles, Box, Typography, SvgIcon, useTheme, useMediaQuery } from '@material-ui/core';
import { mdiFlaskOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ test }) => {
  const classes = useStyles();
  const children = test?.items?.map(({ name }) => name).join(', ') || [];
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box display="flex" alignItems="center" fontWeight={500} mb={0.5}>
        <SvgIcon><Icon path={mdiFlaskOutline} /></SvgIcon>

        <Box ml={1}>
          <Typography variant={isTablet ? 'h5' : 'h6'}>{test?.test?.name}</Typography>
        </Box>
      </Box>

      {!!test?.items?.length ?
        <div className={cn(classes.root, classes.root_column)}>
          <Tooltip
            isExistTooltip={!!test?.items?.length}
            title={
              <>
                <Box mb={0.5} color="common.white">
                  <Typography variant="h5" color="inherit">
                    Components:
                  </Typography>
                </Box>

                {test?.items?.map((item, i) => (
                  <Typography key={i}>
                    {item.name} -
                    <Box component="span" color="success.main" px={0.5}>{item.value || '-'}</Box>
                    (Standard range {item?.standard_range || '-'})
                  </Typography>
                ))}
              </>
            }
          >
            <Box color="info.main" display="flex" alignItems="center" overflow="hidden">
              <Box component="span" pr={1}>
                <Typography variant={isTablet ? 'h5' : 'h2'} color="inherit">
                  {test?.items?.length}
                </Typography>
              </Box>

              <Typography variant={isTablet ? 'h5' : 'h2'} color="inherit">(</Typography>

              <Typography noWrap variant={isTablet ? 'h5' : 'h2'} color="inherit">{children}</Typography>

              <Typography variant={isTablet ? 'h5' : 'h2'} color="inherit">)</Typography>
            </Box>
          </Tooltip>

          <Typography variant="caption" color="textSecondary">
            Components
          </Typography>
        </div>
        :
        <div className={cn(classes.root, classes.root_row)}>
          <div className={classes.content}>
            <Box component="span" color="success.main">
              <Typography variant={isTablet ? 'h5' : 'h2'} color="inherit">
                {test.value || '-'}
              </Typography>
            </Box>

            <Typography variant="caption" color="textSecondary">
              Your Value
            </Typography>
          </div>

          <div className={classes.content}>
            <Typography noWrap variant={isTablet ? 'h5' : 'h2'} title={test?.test?.standard_range}>
              {test?.test?.standard_range}
            </Typography>

            <Typography variant="caption" color="textSecondary">
              Standart Range
            </Typography>
          </div>
        </div>
      }
    </>
  );
};
