import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { amFormatDuration } from '../../../../../../../helpers/dates';
import { preventDefault } from '../../../../../../../helpers/dom';
import { billingParticipantsTypesMap } from '../../../../../../../components/billing';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { IconButton } from '../../../../../../../components/IconButton';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../../components/UserLink';
import { CardItem } from '../../../../../../../components/Cards';

export const Card = ({
  income,
  parentPage,
  openCasePreview,
  handleUpdateIncome,
  handleDeleteIncome
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const CardWrapper = ({ children }) => isTablet ? <>{children}</> : <Box flexBasis="50%">{children}</Box>;

  return (
    <CardWrapper>
      <CardItem py={1} px={2}>
        <Box display="flex" alignItems="center">
          {income.participant_type === billingParticipantsTypesMap.user || !!income?.participant?.role ? (
            <UserLink
              noWrap
              size="sm"
              variant="h5"
              user={income?.participant}
            />
          ) : (
            <CompanyLink
              noWrap
              size="sm"
              variant="h5"
              company={income?.participant}
            />
          )}

          <Box ml="auto">
            {parentPage &&
              <IconButton color="primary" onClick={handleUpdateIncome}>
                <EditIcon />
              </IconButton>
            }

            <IconButton
              edge="end"
              color="error"
              title="Delete income"
              onClick={preventDefault(handleDeleteIncome)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Typography noWrap>
              Parent:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Link noWrap title={income.name} onClick={openCasePreview}>
              {income.name}
            </Link>
          </Grid>

          <Grid item xs={5}>
            <Typography noWrap>
              Notes:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap title={income.notes}>
              {income.notes}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography noWrap>
              Rate:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography>
              <CurrencyFormat value={income?.rate?.toFixed(2)} />
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography noWrap>
              Hours:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography>
              {income.hours || amFormatDuration(income.time, 'seconds', 'h[h] m[min]')}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography noWrap>
              Total:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography>
              <CurrencyFormat value={income?.total} />
            </Typography>
          </Grid>
        </Grid>
      </CardItem>
    </CardWrapper>
  );
};
