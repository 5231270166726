import { AppointmentsList, columnsMap } from '../../AppointmentsList';
import { Row } from './Row';

const hiddenColumns = [
  columnsMap.patient,
  columnsMap.actions
];

const ListRowProps = {
  px: 2
};

export const List = () => {
  return (
    <AppointmentsList
      autoHeight
      hiddenColumns={hiddenColumns}
      Row={Row}
      RowProps={{ ListRowProps }}
    />
  );
};
