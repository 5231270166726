import { Yup } from '../../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  user_id: Yup.mixed().nullable(),
  meds_amount: Yup.mixed().nullable(),
  demand_amount: Yup.mixed().nullable(),
  demand_sent_date: Yup.date().format('YYYY-MM-DD').nullable(),
  demand_due_date: Yup.date().format('YYYY-MM-DD').nullable(),
  demand_um_date: Yup.date().format('YYYY-MM-DD').nullable(),
  demand_um_due_date: Yup.date().format('YYYY-MM-DD').nullable(),
  demand_um_amount: Yup.mixed().nullable(),
  counter_date: Yup.date().format('YYYY-MM-DD').nullable(),
  counter_amount: Yup.mixed().nullable(),
  offer_date: Yup.date().format('YYYY-MM-DD').nullable(),
  offer_amount: Yup.mixed().nullable(),
  settled_date: Yup.date().format('YYYY-MM-DD').nullable(),
  settled_third_party_amount: Yup.mixed().nullable(),
  settled_um_date: Yup.date().format('YYYY-MM-DD').nullable(),
  settled_um_amount: Yup.mixed().nullable(),
  atty_costs_custom: Yup.number().nullable().max(99),
  received_founds: Yup.array().of(Yup.object().shape({
    date: Yup.date().format('YYYY-MM-DD').nullable(),
    amount: Yup.mixed().nullable(),
    name: Yup.string().nullable()
  })).required()
});
