import { useSelector } from 'react-redux';
import { Schedule as ScheduleComponent } from '../../../../components/schedule-events';
import { filtersKeysMap } from '../../../../store/lastFilters';

export const Schedule = () => {
  const currentUser = useSelector(({ profile }) => profile.user);

  return (
    <ScheduleComponent filterKey={filtersKeysMap.profile_schedule} filter={{ users: [ currentUser.id ] }} />
  );
};
