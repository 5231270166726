import { FormControlLabel, Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { CompaniesAutocomplete, Checkbox, TextField } from '../../../../../../components/FormField';
import { getAddress } from '../../../../../../utils/generateAddress';

export const MainInfo = ({ disableCompany }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleCompanyChange = (company) => {
    if (values?.is_same_address) {
      setFieldValue('billing', getAddress(company?.billing));
    }
  };

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item sm={8} xs={12}>
        <TextField
          required
          name="name"
          label="Full Name Office"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField
          name="practice"
          label="Practice"
        />
      </Grid>

      {!disableCompany && (
        <Grid item sm={8} xs={12}>
          <CompaniesAutocomplete
            disableValueTransform
            name="company_id"
            label="Company name"
            onChange={handleCompanyChange}
          />
        </Grid>
      )}

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label={<Typography>Is Med Office</Typography>}
          control={<Checkbox color="primary" name="is_medical" />}
        />
      </Grid>
    </Grid>
  );
};
