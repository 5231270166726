import { useContext, useEffect, useRef, useState } from 'react';
import { Box, List, ListItem, ListItemText, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiMenuOpen } from '@mdi/js';
import { api } from '../../../api';
import * as emailsApi from '../../../api/emails';
import { AccountsContext } from '../../../app/Dashboard/EmailsPage';
import { Loader } from '../../Loader';
import { Tabs, Tab } from '../../Tabs';
import { FolderItem } from './FolderItem';

export const MainContent = () => {
  const { accounts, setActiveAccountID } = useContext(AccountsContext);
  const [ openAccountID, setOpenAccountID ] = useState(accounts[0]?.id);
  const [ folders, setFolders ] = useState([]);
  const [ tabIndex, setTabIndex ] = useState(1);
  const cancelFetch = useRef(() => {});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const handleTabIndexChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const toggleItemNav = (id) => () => {
    setOpenAccountID(id);
  };

  useEffect(() => {
    setActiveAccountID(openAccountID);
    cancelFetch.current();

    emailsApi.getEmailFolders(openAccountID, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(setFolders);
  }, [ openAccountID ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <Loader loading={!folders.length} p={3} render={
      () => (
        <>
          <Tabs
            variant={isMobile ? 'scrollable' : 'fullWidth'}
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabIndexChange}
          >
            <Tab icon={<SvgIcon><Icon path={mdiMenuOpen} /></SvgIcon>} />

            {folders.map((folder, i) => (
              <Tab
                key={folder.id}
                label={
                  <Box>
                    {folder?.name}
                  </Box>
                }
              />
            ))}
          </Tabs>

          {tabIndex === 0 &&
            <Box value={tabIndex}>
              <List disablePadding component="nav">
                {accounts.map((account, i) => (
                  <ListItem
                    key={i}
                    button
                    selected={account.id === openAccountID}
                    onClick={toggleItemNav(account.id)}
                  >
                    <ListItemText primary={account.email} />
                  </ListItem>
                ))}
              </List>
            </Box>
          }

          {tabIndex > 0 &&
            <Box value={tabIndex} display="flex" flexDirection="column" height="100%" flexGrow={1}>
              <FolderItem folder={folders[tabIndex - 1]} folders={folders} />
            </Box>
          }
        </>
      )}
    />
  );
};
