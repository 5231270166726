import { Route as ReactRouterDomRoute } from 'react-router-dom';
import { ErrorBoundary } from '../../ErrorBoundary';

export const Route = (props) => {
  return (
    <ErrorBoundary>
      <ReactRouterDomRoute {...props} />
    </ErrorBoundary>
  );
};
