export const styles = ({
  spacing,
  breakpoints,
  palette: { grey },
  typography: { fontWeightMedium }
}) => ({
  root: {
    height: '100%'
  },

  mainContent: {
    border: `1px solid ${grey[200]}`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  title: {
    padding: spacing(4)
  },

  listContent: {
    height: '100%',
    maxHeight: 165,
    flexGrow: 1,
    overflow: 'auto',

    [breakpoints.down(breakpoints.values.lg)]: {
      maxHeight: 300
    },

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      maxHeight: 'none',
      height: 'auto'
    }
  },

  list: {
    height: '100%',
    overflow: 'auto',

    [breakpoints.down(breakpoints.values.lg)]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      flexGrow: 1
    },

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      height: 'auto'
    }
  },

  totalRoot: {
    justifyContent: 'flex-end',
    padding: spacing(0, 4.5, 0, 2),
    borderBottom: 0,

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      paddingRight: 0
    }
  },

  totalContent: {
    color: grey[600],
    fontWeight: fontWeightMedium
  }
});
