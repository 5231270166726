import Color from 'color';
import TreeItem from '@material-ui/lab/TreeItem';
import { fade, withStyles } from '@material-ui/core/styles';
import { TransitionComponent } from './TransitionComponent';

export const StyledTreeItem = withStyles(({ palette, spacing }) => ({
  iconContainer: {
    display: ({ disabled }) => disabled && 'none',

    '& .close': {
      opacity: 0.3
    }
  },

  group: {
    marginLeft: spacing(),
    paddingLeft: spacing(),
    borderLeft: ({ background }) => `1px dashed ${background || fade(palette.text.primary, 0.4)}`
  },

  label: {
    backgroundColor: 'transparent !important',
    textDecoration: ({ selected }) => selected && 'underline',

    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },

  content: {
    display: 'inline-flex',
    width: 'auto',
    background: ({ background }) => background,
    color: ({ background }) => background && palette.getContrastText(background),
    cursor: ({ disabled }) => disabled && 'default',
    marginBottom: 2,
    paddingLeft: spacing(),
    borderRadius: spacing(),

    '&:hover': {
      background: ({ background }) => Color(background).darken(0.1).hex()
    }
  }
}))(function StyledTreeItem ({ background, selected, ...props }) {
  return (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
  );
});
