import { rolesMap } from './rolesMap';

export const workerRoles = [
  rolesMap.medic,
  rolesMap.doctor,
  rolesMap.advocate,
  rolesMap.admin,
  rolesMap.secretary,
  rolesMap.supervisor
];
