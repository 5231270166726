import { api } from '../api';

export const fetchEmailSignature = (config = {}) => {
  return api.get('/signature-lines', config)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const createEmailSignature = (data) => {
  return api.post('/signature-lines', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateEmailSignature = ({ id, ...data }) => {
  return api.put(`/signature-lines/config/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};
