import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import { Icon } from '@mdi/react';
import {
  mdiHeartPulse,
  mdiFileAccountOutline,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline
} from '@mdi/js';
import { SvgIcon } from '@material-ui/core';
import { MedicalForms } from '../../../../ProfilePage/MedicalInfo/MedicalForms';
import { AuthorizationsPage } from './AuthorizationsPage';
import { MainInfo } from './MainInfo';
import { Claims } from './Claims';

export const routes = [
  {
    path: '/members/users/:userID/medical-info/medical-forms',
    icon: <SvgIcon><Icon path={mdiFileAccountOutline} /></SvgIcon>,
    label: 'Medical Forms',
    component: MedicalForms
  },
  {
    exact: true,
    path: '/members/users/:userID/medical-info',
    icon: <SvgIcon><Icon path={mdiHeartPulse} /></SvgIcon>,
    label: 'Medical Info',
    component: MainInfo
  },
  {
    path: '/members/users/:userID/medical-info/authorizations',
    icon: <LocalHospitalOutlinedIcon />,
    label: 'Authorizations',
    component: AuthorizationsPage
  },
  {
    path: '/members/users/:userID/medical-info/medical-report',
    icon: <SvgIcon><Icon path={mdiFileDocumentOutline} /></SvgIcon>,
    label: 'Medical Report'
  },
  {
    path: '/members/users/:userID/medical-info/claims',
    icon: <SvgIcon><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>,
    label: 'Claim Details',
    component: Claims
  }
];
