import { Box, Typography } from '@material-ui/core';
import { TooltipTitle } from '../../../../../components/Tooltip/TooltipTitle';
import { Divider } from '../../../../../components/Divider';

export const TooltipInnerTitle = ({ index, item, prescription }) => {
  return (
    <TooltipTitle
      itemTitle={item?.ndc_code?.name}
      flexDirection="column"
    >
      <Box display="flex" alignItems="flex-start" mt={1}>
        <Typography>Dose:</Typography>

        <Box color="success.main" ml={1}>
          <Typography noWrap variant="h5" color="inherit">{item.dose || '-'}</Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="flex-start">
        <Typography>Qty:</Typography>

        <Box color="success.main" ml={1}>
          <Typography noWrap variant="h5" color="inherit">{item.qty || '-'}</Typography>
        </Box>
      </Box>

      {prescription?.medications?.length > 1 && index !== (prescription?.medications.length - 1) &&
        <Box width="100%">
          <Divider gutter={1} />
        </Box>
      }
    </TooltipTitle>
  );
};
