import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { amFormatDuration } from '../../../../../../../../helpers/dates';
import { billingParticipantsTypesMap } from '../../../../../../../../components/billing';

export const TimeTrackContainer = ({
  track,
  selectedTracks,
  setSelectedTracks,
  TimeTrackComponent
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { enqueueSnackbar } = useSnackbar();
  const trackTime = track.time
    ? amFormatDuration(track.time, 'seconds', 'h[h] m[min] s[s]')
    : '';
  const trackRate = ~~(track.billing_rate / 100);
  const isSelected = selectedTracks.some(({ id }) => id === track.id);

  const handleItemSelection = (track) => () => {
    if (!values.participant_id) {
      enqueueSnackbar('Please add participant', { variant: 'warning' });
    } else {
      if (
        !values.rate && (values?.participant_type?.value ||
          values?.participant_type) === billingParticipantsTypesMap.company
      ) {
        setFieldValue('rate', track?.billing_rate / 100);
      } else if (!values?.flat_fee && !values.rate) {
        enqueueSnackbar('Please set the price per hour', { variant: 'warning' });
      }

      isSelected
        ? setSelectedTracks(selectedTracks.filter(({ id }) => id !== track.id))
        : setSelectedTracks(selectedTracks.concat(track));
    }
  };

  useEffect(() => {
    if (!values?.participant_id?.value) {
      setSelectedTracks([]);
    }
  }, [ values.participant_id ]);

  return (
    <TimeTrackComponent
      track={track}
      isSelected={isSelected}
      handleItemSelection={handleItemSelection}
      trackTime={trackTime}
      trackRate={trackRate}
    />
  );
};
