import moment from 'moment';
import { IconButton, Typography } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { useModal } from '../../../../../../components/ModalsProvider';
import { stopPropagation } from '../../../../../../helpers/dom';
import { PreviewInvoiceModal } from '../../../../CasesPage/Cases/CaseItem/TabsContent/Billing/PreviewInvoiceModal';
import { columnsWidths, minRowHeight } from '../PaymentItem';

export const InvoicesList = ({ disableInvoicePreview, payment }) => {
  const { openModal } = useModal();

  const openInvoiceViewModal = () => {
    if (payment?.invoice?.id) {
      openModal(PreviewInvoiceModal, {
        payload: {
          invoiceID: payment?.invoice?.id,
          disableAction: true,
          disablePayment: true
        }
      });
    }
  };

  return (
    <ListRow
      button={!disableInvoicePreview}
      minHeight={minRowHeight}
      border={1}
      borderTop={0}
      borderColor="divider"
      onClick={disableInvoicePreview ? undefined : stopPropagation(openInvoiceViewModal)}
    >
      <ListRowCell grow flexBasis={columnsWidths.number}>
        <Typography noWrap title={payment.number}>
          {payment.number}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.invoice_date}>
        {(!!payment?.invoice?.date && !payment?.causer_invoice?.date) &&
          moment(payment.invoice.date).format('L')
        }

        {(!!payment?.causer_invoice?.date && !payment?.invoice?.date) &&
          moment(payment?.causer_invoice?.date).format('L')
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.due_on}>
        <Typography color="secondary">
          {(!!payment?.invoice?.due_date && !payment?.causer_invoice?.due_date) &&
            moment(payment?.invoice?.due_date).format('L')
          }

          {(!!payment?.causer_invoice?.date && !payment?.invoice?.due_date) &&
            moment(payment?.causer_invoice?.due_date).format('L')
          }
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        <Typography variant="h5">
          {payment.invoice
            ? <CurrencyFormat value={payment?.invoice?.total}/>
            : <CurrencyFormat value={payment?.causer_invoice?.total}/>
          }
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount}>
        <Typography variant="h5">
          <CurrencyFormat value={payment?.amount}/>
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.balance}>
        <Typography variant="h5">
          {payment.invoice
            ? <CurrencyFormat value={payment?.invoice?.balance}/>
            : <CurrencyFormat value={payment?.causer_invoice?.balance}/>
          }
        </Typography>
      </ListRowCell>

      {!disableInvoicePreview && (
        <ListRowCell flexBasis={columnsWidths.action}>
          <IconButton onClick={stopPropagation(openInvoiceViewModal)}>
            <EyeIcon color="primary" />
          </IconButton>
        </ListRowCell>
      )}
    </ListRow>
  );
};
