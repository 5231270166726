import { useContext, useState } from 'react';
import { Formik } from 'formik';
import { uniqWith } from 'lodash';
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { CasesSelect } from '../../../../components/cases/CasesSelect';
import { CaseUsersSelect } from '../../../../components/cases/CaseUsersSelect';
import { caseUserRoles } from '../../../../dataMaps';
import * as tasksApi from '../../../../api/tasks';
import { setTasksLastGlobalAction } from '../../../../store/globalActions';
import { ModalBody, ModalContainer, ModalFooter } from '../../../../components/Modal';
import * as taskActionTypes from '../../../../components/tasks/TasksProvider/types';
import { TaskPreviewContext } from '../../../../components/tasks/TasksProvider';
import { Loader } from '../../../../components/Loader';
import { Select } from '../../../../components/FormField';
import { validationSchema } from './validationSchema';

const styles = () => ({
  root: {
    width: 420
  }
});

const useStyles = makeStyles(styles);

export const AddCase = ({
  withSubmit,
  task,
  onClose = () => {},
  onUpdate = () => {}
}) => {
  const dispatchRedux = useDispatch();
  const { caseFields, setCaseFields } = useContext(TaskPreviewContext);
  const [ selectedCase, setSelectedCase ] = useState(null);
  const classes = useStyles();
  const taskUsers = task?.users?.filter(({ id }) => id !== task?.created_by?.id && id !== task?.owner?.id);
  const users = uniqWith([ ...taskUsers, ...caseFields?.users ]);

  const handleCaseRolesChange = (options) => {
    if (!withSubmit) {
      setCaseFields((state) => ({ ...state, case_roles: options.map((item) => item?.data || item) }));
    }
  };

  const handleUsersChange = (users) => {
    if (!withSubmit) {
      setCaseFields((state) => ({ ...state, users }));
    }
  };

  const handleCaseChange = (caseItem) => {
    if (!withSubmit) {
      setSelectedCase(caseItem);
    }
  };

  const handleSubmit = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (withSubmit) {
      tasksApi.updateTask({ id: task.id, name: task.name, ...values }).then((data) => {
        onUpdate(data);
        dispatchRedux(setTasksLastGlobalAction({ type: taskActionTypes.UPDATE_TASK_IN_LIST, payload: data }));
        setSubmitting(true);
        onClose();
      }).catch((error) => {
        error && setErrors(error);
        setSubmitting(false);
      });
    } else {
      setSubmitting(true);
      onClose();
      setCaseFields((state) => ({ ...state, parent_id: selectedCase }));
    }
  };

  return (
    <Formik
      initialValues={{
        case_roles: caseFields.case_roles,
        users,
        parent_id: caseFields?.parent_id || task.parent,
        parent_type: 'case'
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, handleSubmit }) => (
        <ModalContainer noValidate component="form" onSubmit={handleSubmit} className={classes.root}>
          <ModalBody>
            <Select
              isMulti
              formattedValue
              name="case_roles"
              label="Select user roles"
              options={caseUserRoles}
              TextFieldProps={{ margin: 'dense' }}
              onChange={handleCaseRolesChange}
            />

            <CaseUsersSelect
              multiple
              name="users"
              label="Select users"
              margin="dense"
              params={{ case_roles: values.case_roles }}
              onChange={handleUsersChange}
            />

            <CasesSelect
              required
              name="parent_id"
              label="Cases"
              placeholder="Select case..."
              margin="dense"
              params={{ users: values.users }}
              onChange={handleCaseChange}
            />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              )}
            />
          </ModalFooter>
        </ModalContainer>
      )}
    </Formik>
  );
};
