import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as casesApi from '../../../../../api/cases';
import { Divider } from '../../../../../components/Divider';
import { Loader } from '../../../../../components/Loader';
import { ModalContainer } from '../../../../../components/Modal';
import { Footer } from './Footer';
import { Header } from './Header';
import { Body } from './Body';

export const CaseViewModal = ({
  DialogProps,
  handleModalReject,
  payload: { id }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const [ initialCase, setInitialCase ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);

  useEffect(() => {
    setIsFetched(false);

    casesApi.fetchCase(id).then((data) => {
      setInitialCase(data);
      setIsFetched(true);
    }).catch(() => {
      enqueueSnackbar('Case not found', { variant: 'error' });
      handleModalReject();
    });
  }, []);

  return (
    <Dialog fullScreen={isMobile} disableEnforceFocus maxWidth="sm" {...DialogProps}>
      <Loader p={3} loading={!isFetched} render={
        () => (
          <ModalContainer>
            <Header caseItem={initialCase} onClose={handleModalReject} />
            <Divider gutter={1} />
            <Body caseItem={initialCase} />
            <Footer caseItem={initialCase} />
          </ModalContainer>
        )}
      />
    </Dialog>
  );
};
