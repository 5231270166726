import { createContext, useRef, useEffect, useReducer, useState, useContext } from 'react';
import { isEqual } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { api } from '../../../../../api';
import * as appointmentsApi from '../../../../../api/appointments';
import { usePrevious } from '../../../../../helpers/hooks';
import { AppointmentsFilterContext } from '../../../../../components/appointments';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const CalendarContext = createContext();

export const CalendarProvider = ({ children }) => {
  const appointmentsLastGlobalAction = useSelector(({ globalActions }) => globalActions.appointmentsLastGlobalAction);
  const { filter: commonFilter } = useContext(AppointmentsFilterContext);
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { filter } = state;
  const [ selectedDate, setSelectedDate ] = useState(moment());
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchCalendar = (newFilter = {}) => {
    cancelFetch.current();

    appointmentsApi.fetchCalendar({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_APPOINTMENTS_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const reloadCalendar = (newFilter) => {
    dispatch({ type: types.RESET_APPOINTMENTS, newFilter });
    fetchCalendar(newFilter);
  };

  const addAppointment = (appointment) => {
    dispatch({ type: types.ADD_APPOINTMENT, payload: appointment });
  };

  const updateAppointment = (appointment) => {
    dispatch({ type: types.UPDATE_APPOINTMENT, payload: appointment });
  };

  const deleteAppointment = (id) => {
    dispatch({ type: types.DELETE_APPOINTMENT, payload: id });
  };

  const providerValue = {
    ...state,
    selectedDate,

    // functions
    setSelectedDate,
    fetchCalendar,
    reloadCalendar,
    applyFilter,
    addAppointment,
    updateAppointment,
    deleteAppointment
  };

  useEffect(() => {
    if (appointmentsLastGlobalAction?.type === types.FETCH_APPOINTMENTS_REQUEST) {
      fetchCalendar();
    }
  }, [ appointmentsLastGlobalAction ]);

  useEffect(() => {
    const newFilter = { ...filter, ...commonFilter };

    if (!isEqual(filter, newFilter)) {
      applyFilter(newFilter);
    }
  }, [ filter, commonFilter ]);

  useEffect(() => {
    if (!isEqual(prevFilter, filter)) {
      reloadCalendar();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CalendarContext.Provider value={providerValue}>
      {children}
    </CalendarContext.Provider>
  );
};
