export const styles = ({
  spacing,
  palette: { grey, darkgreen, pink },
  typography: { pxToRem, fontWeightMedium }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(1, 2),
    marginTop: spacing(),
    height: 105,
    border: `1px dashed ${grey[200]}`,
    backgroundColor: grey[25]
  },

  mainContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%'
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '50%',
    paddingRight: spacing(),

    '&:last-child': {
      paddingRight: 0
    }
  },

  contentDate: {
    display: 'flex',
    alignItems: 'cetner',
    fontSize: pxToRem(12)
  },

  contentDateIcon: {
    marginRight: spacing(0.5)
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: spacing()
  },

  balanceTotal: {
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(20),
    color: darkgreen,
    marginBottom: spacing(0.5)
  },

  balanceTotal_red: {
    color: pink
  },

  balanceDue: {
    fontSize: pxToRem(12)
  },

  total: {
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(12)
  },

  dueDate: {
    color: grey[600]
  }
});
