import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import * as formsApi from '../../../../../api/profile';
import { FormContext } from '../../../../../app/Dashboard/GuestMedicalProfile/FormProvider';
import { styles } from '../../../../../app/Dashboard/GuestMedicalProfile/styles';
import { Divider } from '../../../../Divider';
import { Loader } from '../../../../Loader';
import { accidentTypesTitles } from '../../../accidentTypes';
import {
  carAccidentValidationSchema,
  generateAccidentInitialValues,
  otherAccidentValidationSchema,
  slipAndFallValidationSchema
} from '../../accidentInfo';
import { ActionButtons } from '../../ActionButtons';
import { AccidentBody } from './AccidentBody';

const useStyles = makeStyles(styles);

export const AccidentInfo = () => {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetched, form, handleComplete, handleBack } = useContext(FormContext);
  const validationSchema = form?.accident_type === accidentTypesTitles.car_accident
    ? carAccidentValidationSchema
    : form?.accident_type === accidentTypesTitles.slip_and_fall
      ? slipAndFallValidationSchema
      : otherAccidentValidationSchema;

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updateGuestAccidentInformation(values).then(({ data }) => {
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleComplete(data);
    }).catch(({ errors }) => {
      setErrors(errors);
      enqueueSnackbar('Form not updated', { variant: 'error' });
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            token: form?.token || null,
            form_id: form.id,
            ...generateAccidentInitialValues(form)
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <AccidentBody form={form} />
              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
