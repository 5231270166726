export const styles = ({
  spacing,
  breakpoints
}) => ({
  wrapper: {
    paddingTop: spacing(5),
    paddingLeft: spacing(4),
    paddingRight: spacing(6),

    [breakpoints.down(breakpoints.values.md)]: {
      padding: spacing(1)
    }
  }
});
