import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { renderRoutes, Route } from '../../../../components/router';
import { routes as actionRoutes } from './CodesCPT/routes';
import { routes } from './routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CodesProcedure = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Route exact path="/codes/procedure-codes">
        <Redirect to={routes[0].path} />
      </Route>

      {renderRoutes(routes)}
      {renderRoutes(actionRoutes)}
    </div>
  );
};
