import { Link, Typography } from '@material-ui/core';
import { TaskModal } from '../../../app/Dashboard/TasksPage/TaskModal';
import { useModal } from '../../ModalsProvider';

export const TaskField = ({ before, after, fieldLabel }) => {
  const { openModal } = useModal();

  const openTaskPreview = (id) => () => {
    if (id) {
      openModal(TaskModal, {
        payload: { id }
      });
    }
  };

  if (!before && after) {
    return (
      <>
        Added {fieldLabel}&nbsp;
        <Link variant="subtitle1" onClick={openTaskPreview(after?.id)}>{after?.name}</Link>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed {fieldLabel}&nbsp;
        <Link variant="subtitle1" onClick={openTaskPreview(before?.id)}>{before?.name}</Link>
      </>
    );
  } else if (!before && !after) {
    return (
      <>
        Empty {fieldLabel}
      </>
    );
  } else {
    return (
      <>
        Changed
        &nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Link onClick={openTaskPreview(before?.id)}>{before?.name}</Link>
        &nbsp;to&nbsp;
        <Link onClick={openTaskPreview(after?.id)}>{after?.name}</Link>
      </>
    );
  }
};
