import { useContext, useEffect } from 'react';
import {
  makeStyles,
  List as MuiList,
  Typography,
  useTheme,
  useMediaQuery,
  Box
} from '@material-ui/core';
import { TablePagination } from '../../../../../../../../components/TablePagination';
import { Loader } from '../../../../../../../../components/Loader';
import { DocumentsContext } from '../../../Documents/DocumentsProvider';
import { Grid } from '../../../Documents/MainContent/Grid';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 52;
export const columnsWidths = {
  name: 200,
  created_at: 175,
  updated_at: 175,
  signed_users: 170,
  action: 75
};

export const MainContent = ({ setDisableDivider }) => {
  const classes = useStyles();
  const { isFetched, documents, filter, resetDocuments } = useContext(DocumentsContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    resetDocuments();
  }, []);

  useEffect(() => {
    setDisableDivider(!documents.length);
  }, [ isFetched ]);

  return (
    <Box mt={!isSmallScreen ? 3 : 1}>
      <Loader loading={!isFetched} render={
        () => (
          !documents.length ? (
            <Typography align="center">No documents found</Typography>
          ) : isSmallScreen ? (
            <>
              <Grid documents={documents}/>
              <TablePagination pagination={filter} onChange={resetDocuments}/>
            </>
          ) : (
            <div className={classes.list}>
              <TableHeader/>

              <MuiList disablePadding>
                {documents.map((document) => (
                  <Row key={document.id} document={document} />
                ))}
              </MuiList>

              <TablePagination pagination={filter} onChange={resetDocuments} />
            </div>
          ))}
      />
    </Box>
  );
};
