import { useContext, useState } from 'react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { Loader } from '../../../../components/Loader';
import { Scrollbars } from '../../../../components/Scrollbars';
import { InvoicesContext } from '../Invoices/InvoicesContext';
import { statusesMap, statusesList } from '../Invoices/InvoicesPage/statusesMap';
import { PaymentStatusCard } from '../PaymentStatusCard';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const InvoicesHeaderContent = ({ isHovered, isFetched, counters }) => {
  const classes = useStyles();
  const invoicesContext = useContext(InvoicesContext);
  const [ activeStatus, setActiveStatus ] = useState(isHovered && 'all_invoices');

  const handleStatusClick = (name) => (event) => {
    event.preventDefault();

    setActiveStatus(name);
    invoicesContext?.applyFilter({ filter_type: statusesMap[name].filterValue });
  };

  return (
    <Scrollbars autoHeight autoHeightMax="100%">
      <div className={classes.root}>
        <Loader loading={!isFetched} p={2} render={
          () => statusesList.map((status) => (
            <PaymentStatusCard
              key={status}
              isHovered={isHovered}
              isActive={activeStatus === status}
              name={statusesMap[status].name}
              color={statusesMap[status].color}
              count={get(counters, `${status}.count`, 0)}
              amount={get(counters, `${status}.sum`, 0).toFixed(2)}
              className={classes.card}
              onClick={isHovered && handleStatusClick(status)}
            />
          ))}
        />
      </div>
    </Scrollbars>
  );
};
