import { Yup } from '../../../../utils/validation';

export const carAccidentValidationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    accident_information: Yup.object().shape({
      type: Yup.string().nullable(),
      you_driver_passenger: Yup.string().nullable().required(),
      you_driver_description: Yup.string().nullable().when('you_driver_passenger', {
        is: (value) => value === 'Passenger',
        then: Yup.string().nullable().required()
      }),
      car_police_report_filed: Yup.string().nullable().required(),
      car_where_accident_occur: Yup.string().nullable().required(),
      car_how_accident_occurred: Yup.string().nullable().required()
    })
  })
});

export const slipAndFallValidationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    accident_information: Yup.object().shape({
      type: Yup.string().nullable(),
      slip_where_accident_occur: Yup.string().nullable().required(),
      slip_police_report_filed: Yup.string().nullable().required(),
      slip_how_accident_occurred: Yup.string().nullable().required()
    })
  })
});

export const otherAccidentValidationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    accident_information: Yup.object().shape({
      type: Yup.string().nullable(),
      explain: Yup.string().nullable().required()
    })
  })
});
