import { Box, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  Page,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle,
  PageBody
} from '../../../../components/Page';
import { FiltersBar } from './FiltersBar';
import { List } from './List';
import { TypesProvider } from './TypesProvider';
import { TypesContext } from './TypesProvider';

export const TaskTypes = () => {
  return (
    <Box display="flex" height="100%">
      <TypesProvider>
        <Page>
          <PageHeader>
            <PageHeaderTitle>Task Types</PageHeaderTitle>

            <PageHeaderActions>
              <TypesContext.Consumer>
                {({ createType }) => (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={createType}
                  >
                    Add type
                  </Button>
                )}
              </TypesContext.Consumer>
            </PageHeaderActions>
          </PageHeader>

          <PageBody disablePaddings disableScrollbars fullHeight>
            <FiltersBar />
            <List />
          </PageBody>
        </Page>
      </TypesProvider>
    </Box>
  );
};
