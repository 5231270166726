import { core } from './core';
import { overrides } from './overrides';

export const palette = {
  type: 'light',

  text: {
    primary: '#202020',
    secondary: '#737373',
    caption: '#202020'
  },

  background: {
    paper: '#fff',
    default: '#fff',
    primary: '#F7F7F7'
  },

  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(0, 0, 0, 0.14)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)'
  }
};

const theme = core({
  palette
});

theme.overrides = overrides(theme);

export { theme };
