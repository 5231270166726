import moment from 'moment';

export const initialState = {
  isFetching: false,
  isFetched: false,
  statistics: [],
  pagination: {
    total: 0,
    last_page: 1
  },
  filter: {
    page: 1,
    per_page: 15,
    from: moment().utc().startOf('month').unix(),
    to: moment().utc().endOf('month').unix(),
    period: 'month',
    type: 'status'
  }
};
