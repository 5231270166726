import { PURGE } from 'redux-persist';
import moment from 'moment';
import { createReduxReducer } from '../../../helpers/createReduxReducer';
import * as types from './types';
import { workClockTypesMap } from './workClockTypesMap';

const initialState = {
  isFetching: false,
  workClock: null
};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => {
    return initialState;
  },

  [types.RESET_WORK_CLOCK]: () => {
    return initialState;
  },

  [types.WORK_CLOCK_FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.WORK_CLOCK_FETCH_SUCCESS]: (state) => {
    return { ...state, isFetching: false };
  },

  [types.WORK_CLOCK_START_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.WORK_CLOCK_START_SUCCESS]: (state, payload) => {
    return { ...state, workClock: payload, isFetching: false };
  },

  [types.WORK_CLOCK_TICK]: ({ workClock, ...state }) => {
    const activeTrack = workClock?.session?.active_track;
    const isWork = workClockTypesMap.work === activeTrack?.type;
    const time = moment().unix() - (
      isWork
        ? workClock?.session?.started_at + workClock?.session?.time_info?.lunch
        : activeTrack?.started_at
    );

    return {
      ...state,

      workClock: {
        ...workClock,

        time: time > 0 ? time : 0
      }
    };
  },

  [types.WORK_CLOCK_STOP_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.WORK_CLOCK_STOP_SUCCESS]: (state) => {
    return {
      ...state,

      workClock: null,
      isFetching: false
    };
  }
});
