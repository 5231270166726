import { useContext, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as discoveriesApi from '../../../../../../../../../api/cases/discovery';
import { CompanyLink } from '../../../../../../../../../components/CompanyLink';
import { ConfirmationModal } from '../../../../../../../../../components/ConfirmationModal';
import { IconButton } from '../../../../../../../../../components/IconButton';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../../../components/Loader';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import { ReadonlyCheckbox } from '../../../../../../../../../components/ReadonlyCheckbox';
import { UserLink } from '../../../../../../../../../components/UserLink';
import { discoveryTypeLabelsMap } from '../../DiscoveryForm';
import { discoveryMemberTypesMap } from '../../DiscoveryForm/discoveryMemberTypes';
import { EditDiscoveryModal } from '../../EditDiscoveryModal';
import { CaseDiscoveriesContext } from '../../CaseDiscoveriesProvider';
import { columnsMap, columnsWidths } from '../List';

export const Row = ({ isLoaded, item: discovery, recalculateHeight, hiddenColumns = [] }) => {
  const { caseItem, onCaseItemUpdate, updateDiscovery, deleteDiscovery } = useContext(CaseDiscoveriesContext);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const daysLeft = discovery.due_date && moment(discovery.due_date).diff(moment(), 'days');

  const handleEdit = () => {
    openModal(EditDiscoveryModal, {
      payload: {
        discovery,
        caseItem,
        onCaseItemUpdate
      },

      onModalResolved: (discovery) => {
        updateDiscovery(discovery);
      }
    });
  };

  const handleDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        discoveriesApi.deleteCaseDiscovery(caseItem.id, discovery.id).then(() => {
          deleteDiscovery(discovery);
          enqueueSnackbar('Case discovery successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Case discovery not deleted', { variant: 'error' });
        });
      }
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow>
      <ListRowCell
        grow
        noWrap
        flexBasis={columnsWidths.discovery}
        title={discovery.type ? discoveryTypeLabelsMap[discovery.type] : false}
      >
        {discoveryTypeLabelsMap[discovery.type] || '-'}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.to}>
        {discovery.to_type === discoveryMemberTypesMap.user ? (
          <UserLink noWrap user={discovery.to} />
        ) : (
          <CompanyLink noWrap company={discovery.to} />
        )}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.from}>
        {discovery.from_type === discoveryMemberTypesMap.user ? (
          <UserLink noWrap user={discovery.from} />
        ) : (
          <CompanyLink noWrap company={discovery.from} />
        )}
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.sentDate) &&
        <ListRowCell flexBasis={columnsWidths.sentDate}>
          {discovery.send_date ? moment(discovery.send_date).format('L') : '-'}
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.extension) &&
        <ListRowCell flexBasis={columnsWidths.extension}>
          <ReadonlyCheckbox checked={discovery.extension}/>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.dueDate}>
        {discovery.due_date ? moment(discovery.due_date).format('L') : '-'}
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.receivedDate) &&
        <ListRowCell flexBasis={columnsWidths.receivedDate}>
          {discovery.received_date ? moment(discovery.received_date).format('L') : '-'}
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.endDate}>
        <Typography color={daysLeft <= 5 ? 'error' : 'textPrimary'}>
          {discovery.due_date ? moment(discovery.due_date).fromNow() : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        <ListRowCellActions>
          <IconButton color="primary" onClick={handleEdit}>
            <EditIcon />
          </IconButton>

          <IconButton edge="end" color="error" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
