import { Box, Typography } from '@material-ui/core';
import { Divider } from '../../../../../components/Divider';
import { TermsAndConditions } from './TermsAndConditions';

export const Copyright = () => {
  return (
    <>
      <Box px={2} pb={2} mt="auto">
        <Divider gutter={2} />

        <TermsAndConditions />
      </Box>

      <Box px={2} py={0.5} bgcolor="grey.900" color="grey.500">
        <Typography variant="subtitle2" color="inherit">
          Copyright © {new Date().getFullYear()} IT TECH ADMIN
        </Typography>
      </Box>
    </>
  );
};
