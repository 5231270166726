import { api } from '../../index';

export const createDocument = (data, options) => {
  return api.post('/html-documents', data, options).then(({ data: { data } }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const updateDocument = ({ id, ...data }, options) => {
  return api.put(`/html-documents/${id}`, data, options).then(({ data: { data } }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const getDocument = (id) => {
  return api.get(`/html-documents/${id}`).then(({ data }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const getDocumentInPDF = (id, config = {}) => {
  return api.post(`/html-documents/${id}/pdf`, {},{
    responseType: 'arraybuffer',
    ...config
  }).catch((data) => {
    throw data;
  });
};

export const fetchDocumentsVersions = (config = {}) => {
  return api.get('/html-document-versions', config).then(({ data }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const getDocumentVersion = (id, config = {}) => {
  return api.get(`/html-document-versions/${id}`, config).then(({ data }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};
