import { Box, makeStyles } from '@material-ui/core';
import { Page, PageBody, PageHeader } from '../../../../components/Page';
import { TypesProvider } from './TypesContext';
import { ActionsBar } from './List/ActionsBar';
import { List } from './List';

const useStyles = makeStyles(({ palette: { grey } }) => ({
  root: {
    height: '100%',
    backgroundColor: grey[100]
  }
}));

export const CompaniesTypesPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TypesProvider>
        <Box py={2} px={3} height="100%">
          <Page>
            <PageHeader>
              <ActionsBar />
            </PageHeader>

            <PageBody disablePaddings disableScrollbars fullHeight>
              <List />
            </PageBody>
          </Page>
        </Box>
      </TypesProvider>
    </div>
  );
};
