import { Redirect, Route, Switch } from 'react-router-dom';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { CaseItemProvider } from './Cases/CaseItem/CaseItemProvider';
import { CasesFilterContextProvider } from './Cases/CasesContext';
import { CreateCasePage } from './Cases/CreateCasePage';
import { MainWindow } from './Cases/MainWindow';
import { CaseItem } from './Cases/CaseItem';
import { EditCase } from './Cases/EditCase';

export const CasesPage = () => {
  return (
    <CasesFilterContextProvider>
      <LayoutContextProvider>
        <Switch>
          <Route exact path="/cases/task-templates">
            <Redirect to="/cases/task-templates/templates" />
          </Route>

          <Route exact path="/cases" component={MainWindow} />
          <Route exact path="/cases/create" component={CreateCasePage} />
          <Route exact path="/cases/edit/:caseID" component={EditCase} />
          <Route
            path="/cases/:caseID"
            render={() => <CaseItemProvider><CaseItem /></CaseItemProvider>}
          />
        </Switch>
      </LayoutContextProvider>
    </CasesFilterContextProvider>
  );
};
