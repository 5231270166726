import { useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidthsLg, columnsWidthsSm } from './WorkSchedule';

export const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <ListRow header>
      <ListRowCell flexBasis={columnsWidths.day} />

      <ListRowCell flexBasis={columnsWidths.dayOff}>
        {isMobile ? 'DO' : 'Day off'}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.start}>
        Start time
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.end}>
        End time
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
