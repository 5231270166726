export const getRelatedRoutesWithCustomBase = (routes, baseUrl = '') => {
  return routes?.map(({ pathName, component: Component, ...route }) => ({
    ...route,

    path: baseUrl + pathName,
    component: () => (
      <Component baseUrl={baseUrl} />
    )
  }));
};
