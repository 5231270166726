import { Fragment, Children, forwardRef, useEffect, useRef, useState, memo } from 'react';
import { get, isEqual } from 'lodash';
import T from 'prop-types';
import cn from 'classnames';
import { makeStyles, Paper, Typography, Collapse, useTheme, useMediaQuery, Box } from '@material-ui/core';
import { eventBus, eventBusEvents } from '../../utils/eventBus';
import { Divider } from '../Divider';
import { AppBar } from '../AppBar';
import { ErrorBoundary } from '../ErrorBoundary';
import { Scrollbars } from '../Scrollbars';
import { VerticalDivider } from '../VerticalDivider';
import { styles } from './styles';

const contentCardVariants = {
  outlined: 'outlined',
  default: 'default'
};

const useStyles = makeStyles(styles);

const propTypes = {
  children: T.node,
  isCollapsed: T.bool,
  disableContentPaddings: T.bool,
  title: T.string,
  autoHeightMax: T.number,
  variant: T.oneOf(Object.keys(contentCardVariants)),
  leftActions: T.array,
  rightActions: T.oneOfType([ T.array, T.bool ]),
  disableAutoHeight: T.bool,
  disableScrollbars: T.bool,
  fullHeight: T.bool,
  CollapseProps: T.object
};

export const ContentCard = memo(forwardRef(({
  color,
  variant = contentCardVariants.default,
  minWidth,
  children,
  disableDivider,
  disableContentPaddings,
  isCollapsed,
  title,
  leftActions,
  rightActions,
  disableAutoHeight,
  disableScrollbars,
  fullHeight,
  autoHeightMax: autoHeightMaxProp,
  className,
  icon,
  endIcon,
  CollapseProps = {},
  ...props
}, ref) => {
  const classes = useStyles({ color, disableContentPaddings, minWidth });
  const [ autoHeightMax, setAutoHeightMax ] = useState(0);
  const [ preventCollision, setPreventCollision ] = useState(true);
  const contentRef = useRef();
  const isCollapsable = typeof isCollapsed === 'boolean';
  const Wrapper = disableScrollbars ? Fragment : Scrollbars;
  const wrapperProps = disableScrollbars ? {} : {
    autoHeight: !fullHeight,
    autoHeightMax: autoHeightMaxProp || autoHeightMax
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isMovableProps = !isMobile && (preventCollision ?? props.item?.isDraggable) ? {
    'rgl-handle': 'true'
  } : {};

  useEffect(() => {
    const togglePreventCollision = (val) => {
      setPreventCollision(val);
    };

    eventBus.on(eventBusEvents.preventCollision, togglePreventCollision);
    setAutoHeightMax(contentRef?.current?.getBoundingClientRect().height);

    return () => {
      eventBus.remove(eventBusEvents.preventCollision, togglePreventCollision);
    };
  }, []);

  useEffect(() => {
    if (isCollapsed === false) {
      setAutoHeightMax(contentRef?.current?.getBoundingClientRect().height);
    }
  }, [ isCollapsed ]);

  return (
    <Paper
      ref={ref}
      variant={variant === contentCardVariants.outlined ? 'outlined' : 'elevation'}
      square={variant === contentCardVariants.outlined}
      className={
        cn(
          classes.root,
          className,
          { [classes.autoHeight]: !disableAutoHeight },
          { [classes.fullHeight]: fullHeight },
          { [classes.resizable]: preventCollision },
          { [classes.defaultVariant]: variant === contentCardVariants.default },
          { [classes.outlinedVariant]: variant === contentCardVariants.outlined }
        )
      }
      {...props}
    >
      <AppBar
        color="transparent"
        className={classes.toolbar}
        ToolbarProps={{ disableGutters: true, className: classes.header }}
        {...isMovableProps}
      >
        {leftActions?.length && (
          <Box mr={1}>
            {Children.toArray(leftActions)}
          </Box>
        )}

        <div className={classes.title}>
          <Typography
            noWrap
            variant="h4"
            color="inherit"
            title={title}
            className={classes.title__text}
          >
            {title}
          </Typography>

          {!!icon &&
            <div className={cn(classes.title__icon, 'non-draggable')}>
              {icon}
            </div>
          }
        </div>

        <div className={classes.rightActions}>
          {!!endIcon &&
            <div className={cn(classes.actionsButton, 'non-draggable')}>
              {endIcon}

              {!disableDivider &&
                <VerticalDivider horizontalGutters={1} />
              }
            </div>
          }

          <div className={cn(classes.actionsButton, 'non-draggable')}>
            {rightActions && Children.toArray(rightActions)}
          </div>
        </div>

        {(variant === contentCardVariants.outlined) && !isCollapsed &&
          <Divider disableBottomGutter type="dashed" className={classes.divider} />
        }
      </AppBar>

      <Collapse
        in={!isCollapsable || !isCollapsed}
        {...CollapseProps.props}
        classes={{
          ...get(CollapseProps, 'classes', {}),
          container: cn(fullHeight && classes.collapseContainer, get(CollapseProps, 'classes.container')),
          wrapper: cn(fullHeight && classes.collapseWrapper, get(CollapseProps, 'classes.wrapper'))
        }}
      >
        <Wrapper {...wrapperProps}>
          <ErrorBoundary>
            <div className={classes.content} ref={contentRef}>
              {children}
            </div>
          </ErrorBoundary>
        </Wrapper>
      </Collapse>
    </Paper>
  );
}), isEqual);

ContentCard.propTypes = propTypes;
