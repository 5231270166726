import { fade } from '@material-ui/core/styles';

export const styles = ({
  spacing,
  palette: { grey, ...palette },
  shape,
  transitions,
  typography: { pxToRem },
  breakpoints
}) => ({
  root: {
    display: 'inline-block',
    width: 156,
    marginRight: spacing(2),
    verticalAlign: 'top',

    [breakpoints.down('md')]: {
      width: '100%'
    }
  },

  removeButton: {
    display: 'block',
    marginLeft: 'auto'
  },

  uploadTrigger: {
    display: 'flex',
    minHeight: 96,
    marginBottom: spacing(0.75),
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(2),
    border: `1px dashed ${grey[400]}`,
    cursor: 'pointer',
    color: grey[400],
    borderRadius: shape.borderRadius,
    transition: transitions.create(),

    '&:hover': {
      extend: 'uploadTrigger_hover'
    },

    [breakpoints.down('md')]: {
      minHeight: 220
    }
  },

  uploadTrigger_hover: {
    background: grey[100]
  },

  uploadTriggerText: {
    fontSize: pxToRem(12),
    textAlign: 'center'
  },

  uploadPreview: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: palette.common.white
  },

  uploadPreviewImg: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },

  uploadPlaceholder: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    background: fade(palette.primary.main, 0.5),
    color: palette.common.white,
    opacity: 0,
    transition: transitions.create(),

    '&:hover': {
      opacity: 1
    }
  },

  uploadName: {
    fontSize: pxToRem(11),
    color: palette.text.secondary
  }
});
