import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { CompanyLink } from '../../../../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../../../../components/UserLink';
import { discoveryTypeLabelsMap } from '../../DiscoveryForm';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.type &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Discovery type:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {discoveryTypeLabelsMap[params?.type]}
            </Typography>
          </Grid>
        </>
      }

      {!!params?.users?.length &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Users:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.users?.map((user) => (
                <Box display="flex" width="100%" alignItems="center" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    key={user.id}
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.companies?.length &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Companies:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.companies?.map((company) => (
                <Box display="flex" width="100%" alignItems="center" key={company?.id}>
                  <CompanyLink
                    noWrap
                    size="sm"
                    key={company.id}
                    company={company}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.due_date &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Due Date:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment(params.due_date).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.send_date &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Send Date:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment(params.send_date).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.received_date &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Received Date:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment(params.received_date).format('L')}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
