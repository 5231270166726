import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { useSnackbar } from 'notistack';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { setFilesLastGlobalAction } from '../../../../../../../../store/globalActions';
import * as expensesApi from '../../../../../../../../api/billing';
import { useModal as useModalHook } from '../../../../../../../../components/ModalsProvider';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { ListRow, ListRowCell, ListRowCellActions, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { Popper } from '../../../../../../../../components/Popper';
import { Label } from '../../../../../../../../components/Label';
import { Date } from '../../../../../../../../components/Date';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { FilePreviewModal } from '../../../../../../files-common';
import { Category } from '../../../../../BillingSettings/Category';
import * as filesTypes from '../../../../../FilesContext/types';
import { getFileMenuOptions } from '../../../../Expenses/List/getFileMenuOptions';
import { expensesStatusesMap } from '../../../../../../../../components/billing';
import { ExpensesContext } from '../../../../Expenses';
import { ExpensesPreviewModal } from '../../../../ExpensesPreviewModal';
import { widthBreakpointMedium, widthBreakpointSmall } from '../../../InvoicesContent/List/listConfig';
import { ExpenseMenu } from '../ExpenseMenu';
import { columnsWidths } from '../Expenses';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ expense, width, caseID = null, onExpenseSelect }) => {
  const classes = useStyles();
  const { openModal } = useModalHook();
  const dispatch = useDispatch();
  const [ files, setFiles ] = useState(expense.files || []);
  const { enqueueSnackbar } = useSnackbar();
  const { selectedExpensesIDs, ...expensesContext } = useContext(ExpensesContext);
  const isSelected = selectedExpensesIDs.indexOf(expense.id) !== -1;
  const filesLastGlobalAction = useSelector(({ globalActions }) => globalActions.filesLastGlobalAction);
  const widthBreakpoint = caseID ? widthBreakpointMedium : widthBreakpointSmall;
  const isAbleToDeleteFile = !hasRole(rolesMap.advocate);

  const handleItemSelection = () => {
    expensesContext.toggleItemSelection(expense);
  };

  const handleExpenseSelect = () => {
    onExpenseSelect(expense);
  };

  const handleDeleteFile = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        expensesApi.deleteFiles([ id ]).then(() => {
          dispatch(setFilesLastGlobalAction({
            payload: {
              expenseID: id,
              deletedFilesIDs: [ id ]
            }
          }));
          setFiles(files?.filter((item) => item.id !== id));
          enqueueSnackbar('File successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  const openExpensePreviewModal = () => {
    openModal(ExpensesPreviewModal, {
      payload: { id: expense.id }
    });
  };

  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      getFileMenuOptions={getFileMenuOptions(handleDeleteFile, isAbleToDeleteFile)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file: files && files[0],
        files: files || []
      }}
    />
  ), [ files && files.length ]);

  useEffect(() => {
    if (filesLastGlobalAction) {
      if (filesLastGlobalAction.type === filesTypes.FILE_UPLOAD_SUCCESS) {
        if (filesLastGlobalAction?.payload?.expenseID === expense.id) {
          const file = files.find(({ id }) => id ===  filesLastGlobalAction?.payload?.file?.id);

          setFiles(file ? files : [ ...files, filesLastGlobalAction?.payload?.file ]);
        }
      }

      if (filesLastGlobalAction.type === filesTypes.DELETE_FILES) {
        if (files.length) {
          setFiles(files?.filter(({ id }) => !filesLastGlobalAction?.payload?.includes(id)));
        }
      }
    }
  }, [ filesLastGlobalAction ]);

  return (
    <ListRow
      selected={isSelected}
      onClick={
        stopPropagation(
          !onExpenseSelect
            ? openExpensePreviewModal
            : handleExpenseSelect
        )
      }
    >
      {!onExpenseSelect && (
        <ListRowCheckbox
          checked={isSelected}
          onClick={stopPropagation(handleItemSelection)}
        />
      )}

      <ListRowCell grow flexBasis={columnsWidths.parent}>
        {expense?.invoice?.number ?
          <Typography noWrap>
            {expense?.invoice?.number}
          </Typography>
          :
          <UserLink
            noWrap
            size="sm"
            variant="h5"
            user={expense?.user}
          />
        }
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell noWrap flexBasis={columnsWidths.category}>
          {!expense?.category ? '-' :
            <Category category={expense?.category} />
          }
        </ListRowCell>
      }

      <ListRowCell grow flexBasis={columnsWidths.description}>
        <Tooltip
          isExistTooltip={!!expense?.notes}
          placement="bottom"
          title={<Typography>{expense?.notes}</Typography>}
        >
          <Typography noWrap>
            {expense?.notes || '-'}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.attachments}>
        {!files?.length ? '-' :
          <div
            className={classes.expensesFiles}
            onClick={openFilesPreview}
          >
            <AttachFileIcon fontSize="inherit"/>

            {files.length}
          </div>
        }
      </ListRowCell>

      {width > widthBreakpoint &&
        <ListRowCell grow flexBasis={columnsWidths.date}>
          <Date format="L, LT" date={expense?.date} />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.budget}>
        <CurrencyFormat value={expense.budget}/>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount}>
        <CurrencyFormat value={expense.amount}/>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        {!expensesStatusesMap?.[expense?.status?.name.toLowerCase()] ? '-' :
          <Label
            label={expensesStatusesMap?.[expense?.status?.name.toLowerCase()]?.name}
            color={expensesStatusesMap?.[expense?.status?.name.toLowerCase()]?.color}
            className={classes.statusLabel}
          />
        }
      </ListRowCell>

      {!onExpenseSelect && (
        <ListRowCell flexBasis={columnsWidths.action}>
          <ListRowCellActions>
            <Popper
              placement="left-start"
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                    <MoreVertIcon />
                  </IconButton>
                )
              }
            >
              {({ handleClose }) => (
                <ExpenseMenu
                  expense={expense}
                  invoiceId={expense?.invoice?.id}
                  onClose={handleClose}
                />
              )}
            </Popper>
          </ListRowCellActions>
        </ListRowCell>
      )}
    </ListRow>
  );
};
