import { palette } from '../../../../themes/palette';

export const statusTypes = {
  active: 1,
  inactive: 0
};

export const statusTypesNames = {
  [statusTypes.active]: 'Active',
  [statusTypes.inactive]: 'Inactive'
};

export const statusTypesColors = {
  [statusTypes.active]: palette().success.main,
  [statusTypes.inactive]: palette().warning.main
};
