import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as tasksActionsTypes from '../../../../components/tasks/TasksProvider/types';
import { FileDropZoneWindow } from '../../../../components/FileDropZoneWindow';
import { TaskPreviewContext } from '../../../../components/tasks/TasksProvider';
import { useModal } from '../../../../components/ModalsProvider';
import { FilesFilterContextProvider, FilesContextProvider, FileUploadModal } from '../../files-common';
import * as filesActionTypes from '../../files-common/FilesContext/types';
import { PreviewTaskHeader } from './PreviewTaskHeader';
import { PreviewTask } from './PreviewTask';

export const PreviewTaskModal = ({ handleModalReject, deleteTask, setIsEdit }) => {
  const { openModal } = useModal();
  const { selectedTask, setSelectedTask, count } = useContext(TaskPreviewContext);
  const lastUploadedFile = useSelector(({ uploads: { lastUploadedFile } }) => lastUploadedFile);
  const filesLastGlobalAction = useSelector(({ globalActions: { filesLastGlobalAction } }) => filesLastGlobalAction);
  const tasksLastGlobalAction = useSelector(({ globalActions: { tasksLastGlobalAction } }) => tasksLastGlobalAction);

  const handleDeleteTask = () => {
    deleteTask(selectedTask.id);
    handleModalReject();
  };

  const openEditTaskModal = () => {
    setIsEdit(true);
  };

  const handleFilesDropped = (files) => {
    openModal(FileUploadModal, {
      payload: {
        files,
        owner_type: 'task',
        owner_id: selectedTask.id
      }
    });
  };

  useEffect(() => {
    if (selectedTask.files && lastUploadedFile) {
      setSelectedTask(({ files, ...state }) => ({
        ...state,
        files: [ lastUploadedFile, ...files ]
      }));
    }
  }, [ lastUploadedFile ]);

  useEffect(() => {
    if (selectedTask.files && filesLastGlobalAction) {
      if (filesLastGlobalAction.type === filesActionTypes.DELETE_FILES) {
        setSelectedTask(({ files, ...state }) => ({
          ...state,
          files: files.filter((file) => file.id !== filesLastGlobalAction.payload[0])
        }));
      }
    }
  }, [ filesLastGlobalAction ]);

  useEffect(() => {
    if (tasksLastGlobalAction && tasksLastGlobalAction.type === tasksActionsTypes.UPDATE_TASK_IN_LIST) {
      setSelectedTask(({ users, ...state }) => ({
        ...state,
        parent: tasksLastGlobalAction.payload.parent,
        parent_type: tasksLastGlobalAction.payload.parent_type,
        users: tasksLastGlobalAction.payload.users,
        logged_time: tasksLastGlobalAction.payload.logged_time
      }));
    }
  }, [ tasksLastGlobalAction ]);

  return (
    <FilesFilterContextProvider>
      <FilesContextProvider>
        <FileDropZoneWindow onDropAccepted={handleFilesDropped}>
          <PreviewTaskHeader
            onUpdate={openEditTaskModal}
            onDelete={handleDeleteTask}
            onClose={handleModalReject}
          >
            {selectedTask.name}
          </PreviewTaskHeader>

          <PreviewTask count={count}/>
        </FileDropZoneWindow>
      </FilesContextProvider>
    </FilesFilterContextProvider>
  );
};
