export const fieldsKeysMap = {
  name: 'name',
  email: 'email',
  role: 'role'
};

export const filterLabels = {
  [fieldsKeysMap.name]: 'User name',
  [fieldsKeysMap.email]: 'Email',
  [fieldsKeysMap.role]: 'Role'
};
