export const styles = ({ spacing, palette: { grey } }) => ({
  root: {
    textTransform: 'capitalize',
    color: grey[500],

    '& + &': {
      borderLeft: `1px solid ${grey[200]}`
    },

    '& > svg': {
      marginRight: spacing(1.5)
    }
  }
});
