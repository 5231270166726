import { get } from 'lodash';
import {
  makeStyles,
  Typography,
  ListItem,
  ListItemText
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/TrendingFlat';
import ResetIcon from '@material-ui/icons/Autorenew';
import { VerticalDivider } from '../../../../../../../../components/VerticalDivider';
import { bodyPartsMap } from '../../../../../../ProfilePage/MedicalInfo/MedicalForms/BodyPartsModal/Body/bodyPartsMap';
import { painLevelColors } from '../../../../../../ProfilePage/MedicalInfo/MedicalForms/BodyPartsModal/painLevelColors';
import { CountLabel } from '../../../../../../ProfilePage/MedicalInfo/CountLabel';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ stepTitle, item }) => {
  const classes = useStyles();
  const painLevel = 10;

  return (
    <ListItem className={classes.root}>
      <ListItemText
        primary={
          <div className={classes.row}>
            <Typography noWrap className={classes.title}>{bodyPartsMap[item]}</Typography>

            <CountLabel
              color={painLevelColors[get(stepTitle, `${item}.level`)]}
              count={get(stepTitle, `${item}.level`) + '/' + painLevel}
            />

            {(!!get(stepTitle, `${item}.direction`) || get(stepTitle, `${item}.direction`) === 0) &&
              <>
                <VerticalDivider horizontalGutters={1}/>

                {get(stepTitle, `${item}.direction`) !== 360 ?
                  <ArrowIcon style={{ transform: `rotate(${get(stepTitle, `${item}.direction`)}deg)` }} />
                  :
                  <ResetIcon/>
                }
              </>
            }
          </div>
        }
      />
    </ListItem>
  );
};
