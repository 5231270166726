import { Contact } from './Contact';
import { Employer } from './Employer';

export const contactTypesMap = {
  contact: 'contact',
  employer: 'employer'
};

export const contactComponentsByTypes = {
  [contactTypesMap.contact]: <Contact/>,
  [contactTypesMap.employer]: <Employer/>
};

export const contactTypesOptions = [
  {
    value: contactTypesMap.contact,
    label: 'Contact'
  },
  {
    value: contactTypesMap.employer,
    label: 'Employer'
  }
];
