export const medicalHistoryInitialValues = (form) => ({
  forms: {
    medical_history: {
      ...form?.forms?.medical_history,
      is_problems: !!form?.forms?.medical_history
        ? !form?.forms?.medical_history?.medical_problems : null,
      medical_problems: form?.forms?.medical_history?.medical_problems || null,
      occupation: form?.forms?.medical_history?.occupation || null,
      occupation_value: form?.forms?.medical_history?.occupation_value || null,
      you_use_tobacco_value: form?.forms?.medical_history?.you_use_tobacco_value || null,
      you_use_tobacco: form?.forms?.medical_history?.you_use_tobacco || null,
      you_use_alcohol_value: form?.forms?.medical_history?.you_use_alcohol_value || null,
      you_use_alcohol: form?.forms?.medical_history?.you_use_alcohol || null,
      used_recreational_drugs_value: form?.forms?.medical_history?.used_recreational_drugs_value || null,
      used_recreational_drugs: form?.forms?.medical_history?.used_recreational_drugs || null
    }
  }
});
