import { useState } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { rolesMap } from '../../../../../dataMaps';
import { stopPropagation } from '../../../../../helpers/dom';
import { UserPreviewModal } from '../../../../../components/users';
import { ReadonlyCheckbox } from '../../../../../components/ReadonlyCheckbox';
import { useModal } from '../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../components/UserLink';
import { Divider } from '../../../../../components/Divider';
import { Members } from '../../../../../components/Members';

export const Footer = ({ caseItem }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ parent, setParent ] = useState(caseItem);
  const guardian = caseItem?.case_users?.find(({ case_role }) => case_role === rolesMap.heading_attorney);
  const { openModal } = useModal();

  const openPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id }
    });
  };

  return (
    <Grid container spacing={2} component={Box} p={2}>
      <Grid item xs={isMobile ? 6 : 3}>
        <Typography color="textSecondary">
          Office Location
        </Typography>
      </Grid>

      <Grid item xs={isMobile ? 6 : 9}>
        <Typography noWrap variant="h5">
          {caseItem?.office?.full_address || '-'}
        </Typography>
      </Grid>

      <Grid item xs={isMobile ? 6 : 3}>
        <Typography color="textSecondary">
          Venue
        </Typography>
      </Grid>

      <Grid item xs={isMobile ? 6 : 9}>
        {caseItem?.office?.venue?.map((item, index) => (
          <Box key={index} mb={1.5}>
            {+item.is_main > 0 ?
              <Box ml={-3}>
                <ReadonlyCheckbox
                  checked
                  fullWidth
                  noWrap={false}
                  variant="h5"
                  position="flex-start"
                  title={`${index + 1}. ${item.full_address}`}
                />
              </Box>
              :
              <Typography variant="h5">
                {`${index + 1}. ${item.full_address}`}
              </Typography>
            }
          </Box>
        )) || (
          <Typography color="textSecondary">No info.</Typography>
        )}
      </Grid>

      <Grid item xs={isMobile ? 6 : 3}>
        <Typography color="textSecondary">
          Guardian
        </Typography>
      </Grid>

      <Grid item xs={isMobile ? 6 : 9}>
        <UserLink
          size="md"
          variant="h5"
          user={guardian?.user}
          onClick={stopPropagation(openPreview(guardian?.user?.id))}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider gutter={1} />
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">
          Participants
        </Typography>
      </Grid>

      <Grid item xs={9}>
        <Box display="flex" pb={isMobile ? 0 : 3}>
          <Members
            isParent
            noWrap
            isUserPreview
            space={0.2}
            maxVisible={4}
            owner={guardian}
            users={parent.case_users || []}
            menuProps={{
              parent: parent,
              onUpdate: setParent
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
