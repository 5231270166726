import { get } from 'lodash';
import { Icon } from '@mdi/react';
import { mdiAccountEditOutline } from '@mdi/js';
import { SvgIcon } from '@material-ui/core';
import { IconLabel } from '../../../../../components/IconLabel';
import { expensesCategoryMap } from '../../../../../components/billing';

export const Category = ({ category }) => {
  const categoryName = category?.name?.toLowerCase();

  return (
    !!expensesCategoryMap[categoryName] ?
      <IconLabel
        label={expensesCategoryMap[categoryName]?.name}
        color={expensesCategoryMap[categoryName]?.color}
        icon={expensesCategoryMap[categoryName]?.icon}
        variant="outlined"
      />
      :
      <IconLabel
        label={category?.name}
        icon={
          (!category?.icon) ?
            <SvgIcon><Icon path={mdiAccountEditOutline} /></SvgIcon>
            :
            get(expensesCategoryMap, `${category?.icon}.icon`) ||
              <SvgIcon><Icon path={mdiAccountEditOutline} /></SvgIcon>
        }
        color={get(expensesCategoryMap, `${category?.icon}.color`)}
        variant="outlined"
      />
  );
};
