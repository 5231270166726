import { creationClaimsStatuses } from '../claimsStatuses';

export const claimInitialValues = {
  payer_type: null,
  local_use_19: null,
  note: null,
  status: creationClaimsStatuses[0],
  hospitalization_from: null,
  hospitalization_to: null,
  is_paper_claim: false,
  is_auth_required: false,
  is_use_clia: false,
  is_use_last_dx: false,
  patient_id: null,
  insurance_id: null,
  adjuster_id: null,
  office_id: null,
  claim_filing_indicator_id: null,
  special_program_code_id: null,
  provider_rendering_id: null,
  provider_supervising_id: null,
  provider_ordering_id: null,
  provider_primary_care_id: null,
  provider_referring_id: null,
  local_use_id: null,
  appointment_id: null,
  icd_codes: [],
  case_id: null
};
