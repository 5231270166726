export const styles = ({ palette, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    [breakpoints.up('lg')]: {
      border: `1px solid ${palette.divider}`
    }
  },

  mainContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },

  list: {
    flexGrow: 1
  }
});
