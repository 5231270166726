import { flow } from 'lodash';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { AppointmentEditModal } from '../../../../../components/appointments';
import { useModal } from '../../../../../components/ModalsProvider';
import { UserContext } from '../../../Members/User/UserProvider';
import { EditPrescriptionModal } from '../EditPrescriptionModal';
import { PrescriptionsContext } from '../PrescriptionsProvider';
import { PreviewPrescriptionModal } from '../PreviewPrescriptionModal';

export const PrescriptionsMenu = ({ prescription, onClose }) => {
  const { openModal } = useModal();
  const { fetchPrescriptions, updatePrescription, deletePrescription } = useContext(PrescriptionsContext);
  const { user } = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const patient = userContext ? userContext?.user : user;

  const handleDeleteMRI = () => {
    deletePrescription(prescription.id);
  };

  const openPreview = () => {
    openModal(PreviewPrescriptionModal, {
      payload: {
        prescription,
        onUpdate: updatePrescription,
        onDelete: deletePrescription
      }
    });
  };

  const handleUpdatePrescription = () => {
    openModal(EditPrescriptionModal, {
      payload: { prescriptionID: prescription.id },
      onModalResolved: (data) => {
        updatePrescription(data);
      }
    });
  };

  const handleUpdateAppointment = () => {
    openModal(AppointmentEditModal, {
      payload: {
        disableAttachments: true,
        appointmentID: prescription.appointment_id,
        insurance: patient?.medical?.main_insurance
      },
      onModalResolved: () => {
        fetchPrescriptions();
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={flow(openPreview, onClose)}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <>
          <MenuItem onClick={flow(handleUpdatePrescription, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit prescription" />
          </MenuItem>

          <MenuItem onClick={flow(handleUpdateAppointment, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit appointment" />
          </MenuItem>

          <MenuItem onClick={flow(handleDeleteMRI, onClose)}>
            <ListItemIcon>
              <DeleteIcon color="error" fontSize="inherit"/>
            </ListItemIcon>

            <ListItemText primary="Delete" />
          </MenuItem>
        </>
      }
    </List>
  );
};
