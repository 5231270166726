export const styles = ({ spacing, palette, typography: { fontWeightMedium } }) => ({
  formContent: {
    fontWeight: fontWeightMedium,
    marginRight: spacing(2)
  },

  formContent__title: {
    marginBottom: spacing()
  },

  content: {
    marginBottom: spacing(3)
  },

  content_row: {
    display: 'flex',
    alignItems: 'center'
  },

  contentInfo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(2)
  },

  contentInfo__inner: {
    marginRight: spacing(2)
  },

  contentInfoTitle: {
    textTransform: 'capitalize',
    marginRight: spacing(2)
  },

  checkboxContent: {
    marginRight: spacing(4)
  },

  noteIcon: {
    display: 'flex',
    alignItems:'center',
    fontWeight: fontWeightMedium,
    color: palette.grey[600],
    paddingRight: spacing(2)
  }
});
