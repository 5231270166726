import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_MESSAGES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_MESSAGES_SUCCESS]: (
    { filter, messages, messageItem, ...state },
    { data, current_page, per_page, total }
  ) => {
    return {
      ...state,

      isFirstLoaded: true,
      isFetching: false,
      isFetched: true,
      pagination: { total },
      filter: { ...filter, page: current_page, per_page },
      selectedMessages: [],
      messageItem: !!data ? messageItem : null,
      messages: data || []
    };
  },

  [types.RESET_MESSAGES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.TOGGLE_ALL_ITEMS_SELECTION]: ({ messages, selectedMessages, ...state }) => {
    const allItemIsSelected = messages.length === selectedMessages.length;

    return {
      ...state,

      messages,
      selectedMessages: allItemIsSelected ? [] : messages.map((item) => item.id)
    };
  },

  [types.TOGGLE_ITEM_SELECTION]: ({ selectedMessages, ...state }, ID) => {
    return {
      ...state,

      selectedMessages: selectedMessages.indexOf(ID) !== -1
        ? selectedMessages.filter((uid) => uid !== ID)
        : selectedMessages.concat(ID)
    };
  },

  [types.DELETE_MESSAGE]: ({ messages, messageItem, pagination, filter, ...state }, deletedIDs) => {
    const deletedMessages = messages.filter((message) => deletedIDs.find((uid) => uid === message.uid));
    const total = pagination.total - deletedMessages.length;
    const page = Math.ceil((messages.length - deletedMessages.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      selectedMessages: [],
      messages: messages.filter((message) => !deletedMessages.find(({ uid }) => uid === message.uid)),
      messageItem: deletedIDs.indexOf(messageItem?.uid) !== -1 ? null : messageItem
    };
  },

  [types.ADD_CASE_TO_MESSAGES]: (state, [ messageIDs, caseItem ]) => {
    return {
      ...state,

      messages: state.messages.map((message) => messageIDs.includes(message.id) ? ({
        ...message,
        case: {
          id: caseItem.id,
          file_number: caseItem.file_number,
          name: caseItem.name
        }
      }) : message)
    };
  },

  [types.DELETE_CASE_FROM_MESSAGES]: (state, messageIDs) => {
    return {
      ...state,

      messages: state.messages.map((message) => messageIDs.includes(message.id) ? ({
        ...message,
        case: null
      }) : message)
    };
  },

  [types.ADD_PATIENT_TO_MESSAGES]: (state, [ messageIDs, patient ]) => {
    return {
      ...state,

      messages: state.messages.map((message) => messageIDs.includes(message.id) ? ({
        ...message,
        patient
      }) : message)
    };
  },

  [types.DELETE_PATIENT_FROM_MESSAGES]: (state, messageIDs) => {
    return {
      ...state,

      messages: state.messages.map((message) => messageIDs.includes(message.id) ? ({
        ...message,
        patient: null
      }) : message)
    };
  },

  [types.ADD_TAGS_TO_MESSAGES]: (state, [ messageIDs, tags ]) => {
    return {
      ...state,

      messages: state.messages.map((message) => messageIDs.includes(message.id) ? ({
        ...message,

        tags: [
          ...message.tags,
          ...tags.filter((tag) => !message.tags.find((messageTag) => messageTag.id === tag.id))
        ]
      }) : message)
    };
  },

  [types.REPLACE_TAGS_TO_MESSAGES]: (state, [ messageIDs, tags ]) => {
    return {
      ...state,

      messages: state.messages.map((message) => messageIDs.includes(message.id) ? ({
        ...message,
        tags
      }) : message)
    };
  },

  [types.DELETE_TAGS_FROM_MESSAGES]: (state, [ messageIDs, tagsIDs ]) => {
    return {
      ...state,

      messages: state.messages.map((message) => messageIDs.includes(message.id) ? ({
        ...message,
        tags: message.tags.filter((tag) => !tagsIDs.includes(tag.id))
      }) : message)
    };
  },

  [types.SET_ONE_MESSAGE]:  (state, messageItem) => {
    return {
      ...state,
      messageItem
    };
  }
});
