import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import cn from 'classnames';
import { Grid, makeStyles, Box, Typography, SvgIcon } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowRightAlt';
import {
  mdiWeightPound,
  mdiWeightKilogram,
  mdiAccountCheck,
  mdiHeartPulse,
  mdiThermometerLines,
  mdiWater
} from '@mdi/js';
import { Icon } from '@mdi/react';
import {
  bmiTypes,
  bmiTypesColors,
  bmiTypesNames,
  getCategoryBMI,
  measureTypes
} from '../../../../../../../../helpers/measureDataMap';
import PressureSvg from '../../../../../../../../components/icons/medical/pressure-icon.svg';
import { UserContext } from '../../../../../../Members/User/UserProvider';
import { Label } from '../../../../../../TasksPage/MainWindow/Label';
import { getStatus } from '../../../../../../../../components/medical/forms/CreateMeasurementsModal/statusMap';
import { CategorySlider } from '../CategorySlider';
import BodySVG from './body.svg';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = ({ item }) => {
  const [ color, setColor ] = useState(bmiTypesColors[bmiTypes.underweight]);
  const [ category, setCategory ] = useState(bmiTypesNames[bmiTypes.underweight]);
  const classes = useStyles({ color });
  const userContext = useContext(UserContext);
  const profile = useSelector(({ profile }) => profile);
  const user = userContext ? userContext.user : profile.user;
  const status = getStatus(item.systolic_blood_pressure || 0);

  useEffect(() => {
    setColor(item.bmi > 0 ? bmiTypesColors[getCategoryBMI(item.bmi)] : bmiTypesColors[bmiTypes.underweight]);
    setCategory(item.bmi > 0 ? bmiTypesNames[getCategoryBMI(item.bmi)] : bmiTypesNames[bmiTypes.underweight]);
  }, [ item ]);

  return (
    <Grid container>
      <Grid item xs={8} className={cn(classes.contentSpacing, classes.borderBottom, classes.borderRight)}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography variant="h2">
            {item.temperature || 0} &deg;F
          </Typography>

          <Box component="span" color="grey.600" fontSize={30}>
            <SvgIcon fontSize="inherit" color="inherit">
              <Icon path={mdiThermometerLines} />
            </SvgIcon>
          </Box>
        </Box>

        <Typography variant="subtitle2" color="textSecondary">Body temperature</Typography>
      </Grid>

      <Grid item xs={4} className={cn(classes.contentSpacing, classes.borderBottom)}>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">
            {item.blood_type || 0}
          </Typography>

          <Box component="span" ml={2} color="grey.600" fontSize={30}>
            <SvgIcon fontSize="inherit" color="inherit">
              <Icon path={mdiWater} />
            </SvgIcon>
          </Box>
        </Box>

        <Typography variant="subtitle2" color="textSecondary">Blood type</Typography>
      </Grid>

      <Grid item xs={4} className={cn(classes.contentSpacing, classes.borderBottom, classes.borderRight)}>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">
            {item.heart_rate || 0}
          </Typography>

          <Box component="span" ml={3} color="grey.600" fontSize={30}>
            <SvgIcon fontSize="inherit" color="inherit">
              <Icon path={mdiHeartPulse} />
            </SvgIcon>
          </Box>
        </Box>

        <Typography variant="subtitle2" color="textSecondary">Heart rate (bmp)</Typography>
      </Grid>

      <Grid item xs={8} className={cn(classes.contentSpacing, classes.borderBottom)}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h2">
            {`${item.systolic_blood_pressure || 0}/${item.diastolic_blood_pressure || 0}`}
          </Typography>

          {item.systolic_blood_pressure > 0 &&
            <Box component="span">
              <Label name={status.label} color={status.value} />
            </Box>
          }

          <Box component="span" color="grey.600" fontSize={30}>
            <SvgIcon fontSize="inherit" color="inherit">
              <PressureSvg />
            </SvgIcon>
          </Box>
        </Box>

        <Typography variant="subtitle2" color="textSecondary">
          Pressure  sys/dia (mmHg)
        </Typography>
      </Grid>

      <Grid item xs={8} container className={classes.borderRight}>
        <Grid item xs={6} className={cn(classes.contentSpacing, classes.borderBottom)}>
          <Box display="flex" alignItems="flex-end">
            <Typography variant="h2">
              {item.weight || 0}
            </Typography>

            <Box component="span" ml={3} color="grey.600" fontSize={30}>
              <SvgIcon fontSize="inherit" color="inherit">
                <Icon
                  path={item.weight_unit === measureTypes.kg
                    ? mdiWeightKilogram
                    : mdiWeightPound
                  }
                />
              </SvgIcon>
            </Box>
          </Box>

          <Typography variant="subtitle2" color="textSecondary">
            {`Weight (${item.weight_unit || 'lbs'})`}
          </Typography>
        </Grid>

        <Grid item xs={6} className={cn(classes.contentSpacing, classes.borderBottom, classes.borderLeft)}>
          <Box display="flex" alignItems="flex-end">
            <Typography variant="h2">
              {get(user, 'profile.birthday', user.birthday) ?
                moment().diff(moment(get(user, 'profile.birthday', user.birthday)), 'years')
                :
                '-'
              }
            </Typography>

            <Box component="span" ml={3} color="grey.600" fontSize={30}>
              <SvgIcon fontSize="inherit" color="inherit">
                <Icon path={mdiAccountCheck} />
              </SvgIcon>
            </Box>
          </Box>

          <Typography variant="subtitle2" color="textSecondary">
            Age
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.leftContentSlider}>
          <Typography variant="h5" className={classes.leftContentSlider__title}>
            {category}
          </Typography>

          <div className={classes.leftContentSlider__inner}>
            <Typography variant="h2">
              {item.bmi > 0 ? +item.bmi : 0}
            </Typography>

            <Typography variant="h5" color="textSecondary">
              BMI
            </Typography>
          </div>

          <div className={classes.slider}>
            <CategorySlider disableTitle color={color} categoryValue={item.bmi > 0 ? +item.bmi : 0} />
          </div>
        </Grid>
      </Grid>

      <Grid item xs={4} container>
        <Grid item xs={6} className={classes.rightContentIcon}>
          <BodySVG/>
        </Grid>

        <Grid item xs={6} className={classes.rightContentIcon}>
          <ArrowIcon color="inherit" className={classes.upIcon}/>
          <Typography variant="h2">{item.height || 0}</Typography>
          <Typography variant="subtitle2" color="textSecondary">Height</Typography>

          <Typography variant="subtitle2" color="textSecondary">
            {`(${item.height_unit || 'feet'})`}
          </Typography>

          <ArrowIcon color="inherit" className={classes.downIcon}/>
        </Grid>
      </Grid>
    </Grid>
  );
};
