import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { Grid, Hidden } from '@material-ui/core';
import * as usersApi from '../../../../../../api/users';
import { CaseMembersSelect } from '../../../../../../components/users/CaseMembersSelect';
import { rolesMap } from '../../../../../../dataMaps';
import { getExistInsuranceCaseOptions, getOptions } from '../../helpers';
import { getSubscriberInfo } from '../helpers';
import { caseInsuranceDefendantValuesTypesMap, subscribersMap } from '../insuranceDataMaps';

export const UserForm = ({ mainPage, user, disableUserFilters = false, onCaseItemUpdate }) => {
  const { values, setFieldValue } = useFormikContext();
  const { pathname } = useLocation();
  const [ members, setMembers ] = useState(values?.caseItem?.case_users);
  const userRole = Object.values(caseInsuranceDefendantValuesTypesMap).includes(values?.type_insurance)
    ? rolesMap.defendant
    : rolesMap.applicant;
  const disabledUserSelect = !pathname.includes('cases');

  const handleAddMembers = (newUsers) => {
    onCaseItemUpdate(newUsers);
    setMembers(newUsers?.case_users);
  };

  const handleToggleUser = (option) => {
    if (!option) {
      return setFieldValue('subscriber_info', {});
    }

    const userID = option?.user?.id || option?.id;

    usersApi.fetchUser(userID).then((userValues) => {
      getSubscriberInfo(userValues, setFieldValue, values?.subscriber_info?.subscriber !== subscribersMap.self);
    });
  };

  useEffect(() => {
    if (mainPage) {
      setMembers(values?.caseItem?.case_users);
    }
  }, [ values?.caseItem?.case_users ]);

  useEffect(() => {
    if (!mainPage && !values?.case_user_id && !isEmpty(user)) {
      setFieldValue('case_user_id', user);
    }
  }, [ values?.type_insurance ]);

  return (
    <>
      {!disableUserFilters && (
        <Hidden only={[ 'xs' ]}>
          <Grid item sm={6} />
        </Hidden>
      )}

      <Grid item sm={6} xs={12}>
        <CaseMembersSelect
          required
          disabled={!mainPage && disabledUserSelect}
          disableClearable={!mainPage && disabledUserSelect}
          isCreatable
          formattedValue={false}
          name="case_user_id"
          label="Subscriber"
          margin="dense"
          creatablePayload={{
            role: userRole,
            caseItem: values.caseItem,
            onCaseItemUpdate: handleAddMembers
          }}
          options={
            disableUserFilters
              ? getExistInsuranceCaseOptions(members)
              : getOptions(userRole, members)
          }
          onChange={handleToggleUser}
        />
      </Grid>
    </>
  );
};
