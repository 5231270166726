import { Fragment } from 'react';
import { makeStyles, Box, Typography, Link } from '@material-ui/core';
import { getUserFullName, getUserNameAbbr } from '../../../../../helpers/users';
import { Avatar } from '../../../../Avatar';
import { Date } from '../../../../Date';
import { Loader } from '../../../../Loader';
import { useModal } from '../../../../ModalsProvider';
import { UserPreviewModal } from '../../../../users';
import { getActionMessages } from '../../activityActionTypesMap';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    paddingTop: spacing(1.5),
    paddingBottom: spacing(1.5),
    borderBottom: `1px dashed ${palette.divider}`
  }
}));

export const Row = ({ item: activity = {}, isLoaded }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const actions = isLoaded && getActionMessages(activity);

  const openUserPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: activity.causer?.id }
    });
  };

  return !isLoaded ? <Loader p={2} /> : !!actions?.length && (
    <Typography component="div" className={classes.root}>
      <Box mt={0.5} mr={1}>
        <Avatar
          size="md"
          src={activity?.causer?.profile?.avatar || activity?.causer?.avatar}
          alt={getUserNameAbbr(activity?.causer)}
        />
      </Box>

      <div>
        <Date
          color="textSecondary"
          iconFontSize="small"
          format="L LT"
          date={activity.created_at}
        />

        <div>
          <Box component="span" mr={1}>
            {!!activity.causer &&
              <Link noWrap variant="h5" onClick={openUserPreview}>
                {getUserFullName(activity.causer)}
              </Link>
            }
          </Box>

          {actions?.map((action, index) => (
            <Fragment key={index}>
              {index > 0 && '; '}
              {action}
            </Fragment>
          ))}
        </div>
      </div>
    </Typography>
  );
};
