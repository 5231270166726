export const IMMUNIZATIONS_FETCH_REQUEST = 'IMMUNIZATIONS_FETCH_REQUEST';
export const IMMUNIZATIONS_FETCH_SUCCESS = 'IMMUNIZATIONS_FETCH_SUCCESS';
export const ADD_IMMUNIZATION = 'ADD_IMMUNIZATION';
export const UPDATE_IMMUNIZATION_IN_LIST = 'UPDATE_IMMUNIZATION_IN_LIST';
export const DELETE_IMMUNIZATION_SUCCESS = 'DELETE_IMMUNIZATION_SUCCESS';
export const RESET_IMMUNIZATIONS = 'RESET_IMMUNIZATIONS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const MASS_DELETE_IMMUNIZATIONS = 'MASS_DELETE_IMMUNIZATIONS';
export const TOGGLE_IMMUNIZATION_SELECTION = 'TOGGLE_IMMUNIZATION_SELECTION';
export const TOGGLE_ALL_IMMUNIZATIONS_SELECTION = 'TOGGLE_ALL_IMMUNIZATIONS_SELECTION';
