import { useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Box, Button } from '@material-ui/core';
import * as reportsApi from '../../../../../api/profile';
import {
  returnWorkIndicatorsOptions
} from '../../../../../components/claims/SituationalNumberFormModal/SituationalNumberForm/data';
import { Loader } from '../../../../../components/Loader';
import { StepContext, StepProvider, validationSchema } from '../steps';
import { ReportsContext } from '../../../../../components/medical/reports';
import { Allergies } from './Allergies';
import { CurrentMedications } from './CurrentMedications';
import { CurrentHistory } from './CurrentHistory';
import { Diagnostic } from './Diagnostic';
import { PhysicalExamination } from './PhysicalExamination';
import { RadiologyExams } from './RadiologyExams';
import { ReviewSystems } from './ReviewSystems';
import { ItemTitle } from './ItemTitle';
import { TreatmentPlan } from './TreatmentPlan';

export const Consultation = () => {
  const history = useHistory();
  const { doi, pathname, updateReport } = useContext(ReportsContext);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  const updateForm = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return reportsApi.updateDiagnosisReport({
      ...values,

      forms: {
        ...values.forms,

        treatment_plan: {
          ...values.forms.treatment_plan,

          time_spent: values?.forms?.treatment_plan?.time_spent ? values.forms.treatment_plan.time_spent + '' : null
        }
      }
    }).then(({ data }) => {
      enqueueSnackbar('Successfully update info', { variant: 'success' });
      updateReport(data);
      history.push(pathname);
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  return (
    <StepProvider id={params.reportID}>
      <StepContext.Consumer>
        {({ isFetched, formValues }) => (
          <Loader p={3} loading={!isFetched} render={
            () => (
              <Formik
                enableReinitialize
                initialValues={{
                  ...formValues,
                  forms: !!formValues?.forms && {
                    ...formValues.forms,

                    diagnostic_impression: {
                      ...formValues?.forms?.diagnostic_impression,

                      return_work_indicator: returnWorkIndicatorsOptions.find(({ value }) => (
                        value === formValues?.forms?.diagnostic_impression?.return_work_indicator
                      )) || null
                    }
                  },
                  doi
                }}
                validationSchema={validationSchema}
                onSubmit={updateForm}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Box
                    noValidate
                    component="form"
                    display="flex"
                    flexDirection="column"
                    onSubmit={handleSubmit}
                    height="100%"
                  >
                    <Box flexGrow={1}>
                      <ItemTitle title="Current History" />
                      <CurrentHistory />
                      <ItemTitle title="Review of Symptoms" />
                      <ReviewSystems />
                      <ItemTitle title="Current Medications" />
                      <CurrentMedications />
                      <ItemTitle title="Allergies" />
                      <Allergies />
                      <ItemTitle title="Physical examination" />
                      <PhysicalExamination />
                      <ItemTitle title="Radiology exams/Lab results" />
                      <RadiologyExams />
                      <ItemTitle title="Diagnostic Impression" />
                      <Box px={2}><Diagnostic /></Box>
                      <ItemTitle title="Treatment plan" />
                      <TreatmentPlan />
                    </Box>

                    <Box display="flex" alignItems="center" flexGrow={0} ml="auto" pt={2}>
                      <Box mr={2}>
                        <Button
                          color="primary"
                          component={Link}
                          to={pathname}
                        >
                          Back
                        </Button>
                      </Box>

                      <Loader surface loading={isSubmitting} render={
                        () => (
                          <Button
                            disabled={isSubmitting}
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                          >
                            Save
                          </Button>
                        )}
                      />
                    </Box>
                  </Box>
                )}
              </Formik>
            )}
          />
        )}
      </StepContext.Consumer>
    </StepProvider>

  );
};
