import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { makeStyles, useTheme, IconButton, Box } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { widthBreakpointXXS } from '../../../../../../../../components/Widgets/Billing';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import { billingParticipantsTypesMap, invoicesTypesMap } from '../../../../../../../../components/billing';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { UserLink } from '../../../../../../../../components/UserLink';
import { UserPreviewModal } from '../../../../../../../../components/users';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { ReadonlyCheckbox } from '../../../../../../../../components/ReadonlyCheckbox';
import { ListRow, ListRowCell, ListRowCellActions, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { Popper } from '../../../../../../../../components/Popper';
import { Label } from '../../../../../../../../components/Label';
import { PreviewCompanyModal } from '../../../../../../Contacts/Companies/PreviewCompanyModal';
import { InvoicesContext } from '../../../../InvoicesContext';
import { statusTypesColors, statusTypesNames } from '../../../../InvoicesContext/statusTypes';
import {
  PreviewInvoiceModal
} from '../../../../../../CasesPage/Cases/CaseItem/TabsContent/Billing/PreviewInvoiceModal';
import { InvoiceMenu } from '../../InvoiceMenu';
import { ClaimModel } from '../../ClaimModel';
import { ApptModel } from '../../ApptModel';
import { CaseModel } from '../../CaseModel';
import {
  columnsMap,
  minRowHeight,
  columnsWidths,
  widthBreakpointSmall,
  widthBreakpointXS,
  widthBreakpointMedium,
  widthBreakpointLarge
} from '../listConfig';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  invoice,
  isEditable,
  invoices,
  payment,
  width,
  paymentForm,
  parentPage,
  caseItem,
  selectedInvoices,
  setSelectedInvoices,
  hiddenColumns = [],
  onInvoiceSelect
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { selectedInvoicesIDs, confirmInvoice, cancelInvoice, ...invoicesContext } = useContext(InvoicesContext);
  const isSelected = selectedInvoicesIDs.indexOf(invoice.id) !== -1;
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const openCompanyViewModal = (companyID) => () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID }
    });
  };

  const openUserViewModal = (userID) => () => {
    openModal(UserPreviewModal, {
      payload: { id: userID }
    });
  };

  const handleInvoiceSelect = () => {
    onInvoiceSelect(invoice);
  };

  const handleItemSelection = () => {
    if (selectedInvoices) {
      if (parentPage === 'edit') {
        if (selectedInvoicesIDs.length === 1 && !isSelected) {
          enqueueSnackbar('You can choose only one invoice', { variant: 'warning' });
        } else {
          setSelectedInvoices((selectedInvoices) => {
            if (selectedInvoices.indexOf(invoice) !== -1) {
              return selectedInvoices.filter((item) => item.id !== invoice.id);
            } else {
              return selectedInvoices.concat(invoice);
            }
          });

          invoicesContext.toggleItemSelection(invoice.id);
        }
      } else {
        setSelectedInvoices((selectedInvoices) => {
          if (selectedInvoices.indexOf(invoice) !== -1) {
            return selectedInvoices.filter((item) => item.id !== invoice.id);
          } else {
            return selectedInvoices.concat(invoice);
          }
        });

        invoicesContext.toggleItemSelection(invoice.id);
      }
    } else {
      invoicesContext.toggleItemSelection(invoice.id);
    }
  };

  const getInvoicePage = (event) => {
    if (!event.target.closest('[href]')) {
      !paymentForm && history.push(itemsRoutesByModels.invoice(invoice.id), {
        filter: invoicesContext.filter
      });
    }
  };

  const openInvoiceViewModal = () => {
    openModal(PreviewInvoiceModal, {
      payload: {
        invoiceID: invoice.id,
        paymentForm,
        confirmInvoice,
        cancelInvoice
      }
    });
  };

  useEffect(() => {
    if (selectedInvoices) {
      if (parentPage === 'edit') {
        if (isEditable && payment.invoice && payment.invoice.id === invoice.id) {
          !isSelected && invoicesContext.toggleItemSelection(invoice.id);
          setSelectedInvoices([ invoice ]);
        }

        if (isEditable && payment.causer_invoice && payment.causer_invoice.id === invoice.id) {
          !isSelected && invoicesContext.toggleItemSelection(invoice.id);
          setSelectedInvoices([ invoice ]);
        }
      } else {
        if (payment?.invoice && payment.invoice.id === invoice.id) {
          !isSelected && invoicesContext.toggleItemSelection(invoice.id);
          setSelectedInvoices([ invoice ]);
        }

        if (payment && payment.causer_invoice && payment.causer_invoice.id === invoice.id) {
          !isSelected && invoicesContext.toggleItemSelection(invoice.id);
          setSelectedInvoices([ invoice ]);
        }

        if (invoices.length === 1 && !payment) {
          setSelectedInvoices([ invoice ]);
          !isSelected && invoicesContext.toggleItemSelection(invoice.id);
        }

        if (invoices.length > 1 && !payment) {
          isSelected && invoicesContext.toggleItemSelection(invoice.id);
          setSelectedInvoices([]);
        }
      }
    }
  }, [ invoices.length ]);

  return (
    <ListRow
      selected={isSelected}
      minHeight={minRowHeight}
      title="Open invoice page"
      className={cn(classes.root, { [classes.root_form]: !!paymentForm })}
      onClick={onInvoiceSelect ? handleInvoiceSelect : stopPropagation(getInvoicePage)}
    >
      {!onInvoiceSelect && !hiddenColumns.includes(columnsMap.checkbox) &&
        <ListRowCheckbox
          checked={isSelected}
          onClick={stopPropagation(handleItemSelection)}
        />
      }

      <ListRowCell grow flexBasis={columnsWidths.name}>
        <ReadonlyCheckbox fullWidth title={invoice.number} checked={invoice.is_confirmed} />
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.billFor) && (width > widthBreakpointLarge) &&
        <ListRowCell grow flexBasis={columnsWidths.billFor}>
          {invoice?.model_type === invoicesTypesMap.case
            ? <CaseModel model={invoice?.model || {}} />
            : invoice?.model_type === invoicesTypesMap.appointment
              ? <ApptModel model={invoice?.model || {}} />
              : invoice?.model_type === invoicesTypesMap.claim
                ? <ClaimModel model={invoice?.model || {}} />
                : '-'
          }
        </ListRowCell>
      }

      {!paymentForm && width > widthBreakpointXXS &&
        <ListRowCell grow flexBasis={columnsWidths.billFrom}>
          {invoice?.bill_from?.participant_type === billingParticipantsTypesMap.company ? (
            <CompanyLink
              noWrap
              company={invoice?.bill_from?.participant}
              onClick={stopPropagation(openCompanyViewModal(invoice?.bill_from?.participant_id))}
            />
          ) : (
            <UserLink
              noWrap
              user={invoice?.bill_from?.participant}
              onClick={stopPropagation(openUserViewModal(invoice?.bill_from?.participant_id))}
            />
          )}
        </ListRowCell>
      }

      {!paymentForm &&
        <ListRowCell flexBasis={columnsWidths.billTo}>
          {invoice?.bill_to?.participant_type === billingParticipantsTypesMap.company ? (
            <CompanyLink
              noWrap
              company={invoice?.bill_to?.participant}
              onClick={stopPropagation(openCompanyViewModal(invoice?.bill_to?.participant_id))}
            />
          ) : (
            <UserLink
              noWrap
              user={invoice?.bill_to?.participant}
              onClick={stopPropagation(openUserViewModal(invoice?.bill_to?.participant_id))}
            />
          )}
        </ListRowCell>
      }

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.dueDate}>
          {!invoice.due_date ? '-' :  moment(invoice.due_date).format('L')}
        </ListRowCell>
      }

      {width > widthBreakpointXS &&
        <ListRowCell component={Box} flexBasis={columnsWidths.amount}>
          {!invoice.total ? '-' :
            <CurrencyFormat value={invoice.total} />
          }
        </ListRowCell>
      }

      {width > widthBreakpointSmall &&
        <ListRowCell component={Box} flexBasis={columnsWidths.balance}>
          {!invoice.balance ? '-' :
            <CurrencyFormat value={invoice.balance} TypographyProps={{ noWrap: true, color: 'error' }} />
          }
        </ListRowCell>
      }

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.paid}>
          <Label
            className={classes.statusLabel}
            label={invoice.is_paid ? 'Paid' : 'Unpaid'}
            color={theme.palette[invoice.is_paid ? 'success' : 'error'].main}
          />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.status}>
        {invoice.status &&
          <Label
            className={classes.statusLabel}
            label={statusTypesNames[invoice.status.name
              ? invoice.status.name.toLowerCase()
              : invoice.status.toLowerCase()
            ]}
            color={statusTypesColors[invoice.status.name
              ? invoice.status.name.toLowerCase()
              : invoice.status.toLowerCase()
            ]}
          />
        }
      </ListRowCell>

      {!onInvoiceSelect && (
        <ListRowCell flexBasis={columnsWidths.action}>
          <ListRowCellActions>
            {!paymentForm ?
              <Popper
                placement="left-start"
                anchorRenderer={
                  ({ anchorRef, handleToggle }) => (
                    <IconButton
                      edge="end"
                      buttonRef={anchorRef}
                      onClick={stopPropagation(handleToggle)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )
                }
              >
                {({ handleClose }) => (
                  <InvoiceMenu
                    paymentForm={paymentForm}
                    caseItem={caseItem}
                    invoice={invoice}
                    onClose={handleClose}
                  />
                )}
              </Popper>
              :
              <IconButton onClick={stopPropagation(openInvoiceViewModal)}>
                <EyeIcon />
              </IconButton>
            }
          </ListRowCellActions>
        </ListRowCell>
      )}
    </ListRow>
  );
};
