import { EmailsSendModal } from '../../app/Dashboard/EmailsPage/EmailsClient/EmailsSendModal';
import { useModal } from '../../components/ModalsProvider';

export const useEmailsModal = ({ caseItem = null, patient = null }) => {
  const { openModal } = useModal();

  const openSendEmailModal = ({ fetchSavedMessages, accounts = [] }) => () => {
    accounts.length && openModal(EmailsSendModal, {
      payload: { accounts, caseItem, patient },
      onModalResolved: () => {
        setTimeout(() => {
          // TODO: replace it to sockets
          fetchSavedMessages();
        }, 30 * 1000);
      }
    });
  };

  return { onSendEmail: openSendEmailModal };
};
