import { api } from '../../../api';
import {
  rolesFetchRequest,
  rolesFetchSuccess,
  rolesFetchFail,
  addRole,
  updateRoleInList,
  deleteRoleSuccess
} from './actions';

export const fetchRoles = () => (dispatch) => {
  dispatch(rolesFetchRequest());

  return api.get('/roles').then(({ data }) => {
    dispatch(rolesFetchSuccess(data));

    return data;
  }).catch((error) => {
    dispatch(rolesFetchFail());

    throw error.data;
  });
};

export const createRole = (roleData) => (dispatch, getStore, { api }) => {
  return api.post('/roles', roleData).then(({ data: { data } }) => {
    dispatch(addRole(data));

    return data;
  }).catch((error) => {
    throw error.data;
  });
};

export const updateRole = ({ id, ...updatedData }) => (dispatch, getStore, { api }) => {
  return api.put(`/roles/${id}`, updatedData).then(({ data }) => {
    dispatch(updateRoleInList({ id, ...data }));

    return data;
  }).catch((error) => {
    throw error.data;
  });
};

export const deleteRole = ({ id }) => (dispatch, getStore, { api }) => {
  return api.delete(`/roles/${id}`).then(({ data }) => {
    dispatch(deleteRoleSuccess(id));

    return data;
  }).catch((error) => {
    throw error.data;
  });
};
