import { Fragment } from 'react';
import { Box, Collapse, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Scrollbars } from '../../../../../../components/Scrollbars';
import { CaseCard } from '../CaseCard';
import { CaseBody } from '../CaseBody';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CaseInfo = ({ isCaseInfoOpen = false, toggleCaseInfoOpen, caseItem }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  const Wrapper = isMobile ? Collapse : Fragment;
  const wrapperProps = isMobile ? {
    in: isCaseInfoOpen,
    component: Box,
    height: 82,
    flexShrink: 0,
    collapsedHeight: 82
  } : {};

  return (
    <div className={classes.root}>
      <Scrollbars>
        <Wrapper {...wrapperProps}>
          <CaseCard caseItem={caseItem} isCaseInfoOpen={isCaseInfoOpen} handleToggleCaseInfo={toggleCaseInfoOpen} />
          <CaseBody caseItem={caseItem} />
        </Wrapper>
      </Scrollbars>
    </div>
  );
};
