import { ChecklistBool } from '../ChecklistBool';
import { Text } from '../Text';
import { Date } from '../Date';
import { TaskField } from '../TaskField';

export const todoFieldsMap = {
  is_checked: (before, after) => <ChecklistBool before={before} after={after} fieldLabel="Checkbox Item" />,
  text: (before, after) => <Text before={before} after={after} fieldLabel="Checklist Item" />,
  updated_at: (before, after) => <Date before={before} after={after} fieldLabel="Date of updating" />,
  created_at: (before, after) => <Date before={before} after={after} fieldLabel="Date of creation" />,
  deleted_at: (before, after) => <Date before={before} after={after} fieldLabel="Date of deleting" />,
  parent: (before, after) => <TaskField before={before} after={after} fieldLabel="Parent Task" />
};

