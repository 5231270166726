import { api } from '../api';

export const fetchEventTemplates = (config) => {
  return api.get('/schedule-event-templates', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createEventTemplate = (data) => {
  return api.post('/schedule-event-templates', data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateEventTemplate = ({ id, ...data }) => {
  return api.put(`/schedule-event-templates/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteEventTemplate = (id) => {
  return api.delete(`/schedule-event-templates/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
