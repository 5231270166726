import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as appointmentTypesApi from '../../../../../api/codes/appointments/appointment-types';
import * as proceduresApi from '../../../../../api/profile';
import { uploadFiles } from '../../../../../store/dashboard/files/uploads';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { AppointmentForm, apptInitialValues, ApptInfo } from '../../../../../components/appointments';
import { initialValues as procedureInitialValues } from '../../../../../components/appointments/procedures';
import { Loader } from '../../../../../components/Loader';
import { validationSchema as proceduresValidationSchema } from '../EditProceduresModal/validationSchema';
import { Procedures } from './Procedures';
import { ProceduresBody } from './ProceduresBody';
import { validationSchema } from './validationSchema';

export const CreateProceduresModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    appointmentTypeID,
    apptValues = null,
    initialValues = {},
    insurance = {},
    onUpdate = () => {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [ attachments, setAttachments ] = useState([]);
  const [ appointmentType, setAppointmentType ] = useState(null);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const defaultInitialValues = apptValues ? {
    ...procedureInitialValues,
    ...initialValues,

    appointment_id: { id: apptValues?.appointment_id, ...apptValues },
    approved_id: apptValues?.approved_id,
    office_id: apptValues?.office
  } : {
    ...apptInitialValues,
    ...initialValues,

    patient_insurance_pcp: insurance?.pcp,
    patient_insurance_spc: insurance?.spc,
    insurance_details: {
      company: insurance?.insurance_company
    },
    appointment_type_id: appointmentType,
    appointment_type: appointmentType,
    time: appointmentType?.time || 0,
    procedures: [ procedureInitialValues ]
  };

  const massCreateProcedure = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return proceduresApi.massCreateMedicalReports(values).then(({ data }) => {
      if(!!attachments.length) {
        attachments.forEach((attachment, i) => {
          dispatch(uploadFiles({
            files: attachment,
            owner_type: 'medical_procedure',
            owner_id: data?.procedures[i].id
          }));
        });
      }

      enqueueSnackbar('Successfully created', { variant: 'success' });
      onUpdate();
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Procedure not created', { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  const createProcedure = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return proceduresApi.createMedicalReport(values).then(({ data }) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      onUpdate();
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Procedure not created', { variant: 'error' });
    });
  };

  useEffect(() => {
    if (appointmentTypeID) {
      appointmentTypesApi.fetchAppointmentType(appointmentTypeID).then(setAppointmentType);
    }
  }, []);

  return (
    <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
      <Loader p={3} loading={!appointmentType} render={
        () => (
          <Formik
            initialValues={defaultInitialValues}
            validationSchema={apptValues ? proceduresValidationSchema : validationSchema}
            onSubmit={!apptValues ? massCreateProcedure : createProcedure}
          >
            {({ isSubmitting, handleSubmit }) => (
              <ModalContainer
                noValidate
                component="form"
                onSubmit={handleSubmit}
              >
                <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarPlus} /></SvgIcon>} onClose={handleModalReject}>
                  Add New Surgeries/Procedures
                </ModalHeader>

                <ModalBody>
                  {apptValues ? (
                    <>
                      <ApptInfo apptValues={apptValues} />
                      <ProceduresBody companyID={apptValues?.patient_insurance?.insurance_company?.id} />
                    </>
                  ) : (
                    <>
                      <AppointmentForm appointmentType={appointmentType} />
                      <Procedures setAttachments={setAttachments} />
                    </>
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save & Create
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
