import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { TableHeader } from './TableHeader';
import { Card } from './Card';
import { Row } from './Row';

export const columnsWidths = {
  cpt: 270,
  pos: 270,
  dx: 110,
  modifiers: 100,
  unit: 100,
  price: 100
};

export const Procedures = ({ appointment, procedures }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box border={1} borderColor="divider">
      {!isMobile && <TableHeader />}

      {procedures.map((procedure) => (
        isMobile
          ? <Card key={procedure.id} appointment={appointment} procedure={procedure} />
          : <Row key={procedure.id} appointment={appointment} procedure={procedure} />
      ))}
    </Box>
  );
};
