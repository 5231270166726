import { useFormikContext } from 'formik';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import {
  TextField,
  Select,
  NdcCodesSelect,
  CurrencyField
} from '../../../../../../../../components/FormField';
import { Button } from '../../../../../../../../components/Button';
import { quantityMap } from '../../quantityMap';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const NDCCodes = ({ NDCCodes, onNdcCodesUpdate = () => {} }) => {
  const classes = useStyles();
  const { values, setValues } = useFormikContext();

  const handleAddNDCCode = () => {
    setValues({ ...values, ndc: null });
    onNdcCodesUpdate([ ...NDCCodes, values.ndc ]);
  };

  const handleDeleteNDCCode = (item) => () => {
    const codeId = item.ndc_code_id || item.id;
    const filteredCodes = NDCCodes.filter((code) => (
      !!code?.ndc_code_id
        ? code.ndc_code_id !== codeId
        : code.id !== codeId
    ));

    onNdcCodesUpdate(filteredCodes);
  };

  const handleNdcChange = (code) => {
    setValues({
      ...values,

      ndc: {
        ...values.ndc,

        ndc_code_id: code?.id || null,
        ndc_code_name: code?.description || code?.name || null
      }
    });
  };

  return (
    <Paper variant="outlined" square className={classes.root}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h3">
            NDC Codes
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <NdcCodesSelect
            name="ndc.ndc_code_id"
            label="NDC Code"
            onChange={handleNdcChange}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <CurrencyField
            disabled={!values?.ndc?.ndc_code_id}
            name="ndc.price"
            label="Unit Price"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <Select
            isClearable
            formattedValue
            isDisabled={!values?.ndc?.price}
            name="ndc.quantity_type"
            label="Quantity type"
            placeholder="Select modifier..."
            options={quantityMap}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            disabled={!values?.ndc?.quantity_type}
            type="number"
            name="ndc.quantity"
            label="Quantity"
          />
        </Grid>
      </Grid>

      <Grid container justify="flex-end" className={classes.actionButton}>
        <Button
          disabled={
            !values?.ndc?.ndc_code_id || !values?.ndc?.price ||
              !values?.ndc?.quantity_type || !values?.ndc?.quantity
          }
          variant="contained"
          color="primary"
          size="small"
          onClick={handleAddNDCCode}
        >
          Add
        </Button>
      </Grid>

      <List NDCCodes={NDCCodes} onDelete={handleDeleteNDCCode} />
    </Paper>
  );
};
