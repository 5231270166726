export const styles = ({ spacing, palette, typography: { pxToRem }, transitions }) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column'
  },

  item: {
    padding: spacing(1.75, 2, 1.75, 2.75),
    color: palette.text.primary
  },

  itemExpand: {
    marginRight: spacing(1.5),
    transform: 'rotate(-90deg)',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest
    })
  },

  itemExpandOpened: {
    transform: 'rotate(0deg)'
  },

  itemExpandHidden: {
    visibility: 'hidden'
  },

  itemIcon: {
    color: palette.grey[800]
  },

  itemText: {
    margin: 0,
    padding: 0
  },

  itemTextPrimary: {
    fontWeight: 500
  },

  itemTextSecondary: {
    fontSise: pxToRem(12),
    color: palette.text.secondary
  },

  subItem: {
    padding: spacing(0.75, 6)
  },

  tagsCollapseContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },

  tagsCollapseWrapper: {
    flex: 1,
    minHeight: 350
  },

  tagsCollapseWrapperInner: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }
});
