export const statusesOptionsMap = [
  {
    value: 'Accepted',
    label: 'Accepted'
  },
  {
    value: 'Denied',
    label: 'Denied'
  },
  {
    value: 'Partial or Not Yet',
    label: 'Partial or Not Yet'
  },
  {
    value: 'Determined',
    label: 'Determined'
  }
];
