import { useContext, useEffect, useRef } from 'react';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { CaseReminderCasesContext } from '../../../../cases';
import { ScrollbarsCustom } from '../../../../../../ScrollbarsCustom';
import { Loader } from '../../../../../../Loader';
import { Group } from '../Group';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ReminderGroupsList = ({ GroupProps }) => {
  const classes = useStyles();
  const {
    isFetched,
    cases,
    resetCases
  } = useContext(CaseReminderCasesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetCases();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef}>
      <div className={classes.root}>
        <Loader loading={!isFetched} p={2} render={
          () => !cases.length ? (
            <Typography align="center">No cases found</Typography>
          ) : (
            <MuiList disablePadding>
              {cases.map(({ reminders_count, ...caseItem }) =>
                <Group
                  key={caseItem.id}
                  caseItem={caseItem}
                  remindersAmount={reminders_count}

                  {...GroupProps}
                />
              )}
            </MuiList>
          )}
        />
      </div>
    </ScrollbarsCustom>
  );
};
