export const styles = ({ spacing, palette }) => ({
  listItem: {
    padding: spacing(1.5),
    paddingRight: spacing(8.5),
    listStyle: 'none',
    wordWrap: 'break-word',
    borderBottom: `1px solid ${palette.divider}`,

    '&:hover': {
      background: palette.primary
    }
  },

  listItemActive: {
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main,

    '&:hover': {
      color: palette.primary.contrastText,
      backgroundColor: palette.primary.main
    }
  },

  badgeType: {
    left: 2,
    bottom: 2,
    padding: 0,
    backgroundColor: palette.common.white
  },

  navItemIcon: {
    color: palette.grey[500]
  },

  listItemText: {
    fontWeight: 'bold'
  },

  secondaryAction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    padding: spacing(2, 0),
    minWidth: spacing(8),
    color: palette.primary.dark
  },

  secondaryActionMinimized: {
    padding: 0,
    minWidth: 'inherit'
  }
});
