import { useContext } from 'react';
import moment from 'moment';
import { Checkbox } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../../helpers/dom';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { UserPreviewModal } from '../../../../../../components/users';
import { IconButton } from '../../../../../../components/IconButton';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Popper } from '../../../../../../components/Popper';
import { UserLink } from '../../../../../../components/UserLink';
import { Contact } from '../../../../../../components/Contact';
import { Loader } from '../../../../../../components/Loader';
import { AccessPatientsContext } from '../../PatientsProvider';
import { PatientsMenu } from '../../PatientMenu';
import { columnsWidths } from '../listConfig';

export const Row = ({ item = {}, isLoaded }) => {
  const { openModal } = useModal();
  const { selectedPatientsIDs, togglePatientSelection } = useContext(AccessPatientsContext);
  const isSelected = selectedPatientsIDs.indexOf(item?.id) !== -1;

  const handleItemSelection = () => {
    togglePatientSelection(item.id);
  };

  const openUserPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id }
    });
  };

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow selected={isSelected} disabled={!item?.is_active}>
      <ListRowCell>
        <Checkbox edge="start" checked={isSelected} onClick={handleItemSelection} />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.name}>
        <UserLink
          noWrap
          user={item?.patient}
          onClick={stopPropagation(openUserPreview(item?.patient?.id))}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.email}>
        <Contact
          noWrap
          color="primary"
          type="mailto"
          contact={item?.patient?.email}
        />
      </ListRowCell>

      <ListRowCell shrink="0" flexBasis={columnsWidths.phone}>
        <Contact
          noWrap
          color="primary"
          type="tel"
          contact={item?.patient?.phone}
        />
      </ListRowCell>

      <ListRowCell shrink="0" flexBasis={columnsWidths.doi}>
        {item?.patient?.doi ? moment.unix(item?.patient?.doi).format('L') : '-'}
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <PatientsMenu item={item} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
