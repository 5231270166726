import { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import { NestedList } from '../../../../../NestedList';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../helpers/isEmptyDeep';
import { TreatmentItem } from './TreatmentItem';

export const TreatmentPlan = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(report) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Treatment & Plan"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box py={2}>
        <TreatmentItem
          item={report?.physical_therapy?.check}
          title="Physical Therapy"
          text={report?.physical_therapy?.text}
        />

        <TreatmentItem
          item={report?.acupuncture?.check}
          title="Acupuncture"
          text={report?.acupuncture?.text}
        />

        {report?.renew_current_medications?.check &&
          <ReadonlyCheckbox
            checked={report?.renew_current_medications?.check}
            variant="h5"
            BoxProps={{ px: 1, mb: 2 }}
            iconColor="success"
            title="Renew current medications"
          />
        }

        <TreatmentItem
          item={report?.without_modification?.check}
          title="Without modification"
          text={report?.without_modification?.text}
        />

        <TreatmentItem
          item={report?.with_modification?.check}
          title="With modification"
          text={report?.with_modification?.text}
        />

        {report?.urine_test?.check &&
          <ReadonlyCheckbox
            checked={report?.urine_test?.check}
            variant="h5"
            BoxProps={{ px: 1, mb: 2 }}
            iconColor="success"
            title="Urine test"
          />
        }

        {report?.potential_risks_re_reviewed?.check &&
          <ReadonlyCheckbox
            checked={report?.potential_risks_re_reviewed?.check}
            variant="h5"
            BoxProps={{ px: 1, mb: 2 }}
            iconColor="success"
            title="Potential risks re/reviewed"
          />
        }

        <TreatmentItem
          item={report?.investigation_planned?.check}
          title="Investigation Planned:"
          text={report?.investigation_planned?.text}
        />

        {report?.request_authorization?.check &&
          <ReadonlyCheckbox
            checked={report?.request_authorization?.check}
            variant="h5"
            BoxProps={{ px: 1, mb: 2 }}
            iconColor="success"
            title="Request Authorization"
          />
        }

        <TreatmentItem
          item={report?.without_modification_auth?.check}
          title="Without modification"
          text={report?.without_modification_auth?.text}
        />

        <TreatmentItem
          item={report?.procedure_to_be_performed?.check}
          title="Procedure to be performed:"
          text={report?.procedure_to_be_performed?.text}
        />

        <Grid container spacing={0} component={Box} px={2}>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center" pr={4} my={3}>
              <Box mr={1}>
                <Typography variant="h5">Time Spent:</Typography>
              </Box>

              <Typography color="textSecondary">{report?.time_spent || 0} minutes</Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            {report.next_follow_up &&
              <Box display="flex" alignItems="center" pr={4} my={3} color="text.secondary">
                <Box mr={1} minWidth={150}>
                  <Typography variant="h5">Next Follow-Up:</Typography>
                </Box>

                <Box mr={1}>
                  <CalendarIcon fontSize="small" color="inherit" />
                </Box>

                <Typography color="textPrimary">
                  {moment(report.next_follow_up).format('L LT')}
                </Typography>
              </Box>
            }
          </Grid>
        </Grid>
      </Box>
    </NestedList>
  );
};
