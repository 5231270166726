import { Typography } from '@material-ui/core';

export const ApptBook = ({ before, after, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Added&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        {after?.appointment_book_name || '-'}
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        {before?.appointment_book_name || '-'}
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        &nbsp;
        {before?.appointment_book_name || '-'}
        &nbsp;to&nbsp;
        &nbsp;
        {after?.appointment_book_name || '-'}
      </>
    );
  } else {
    return (
      <>
        Empty&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  }
};
