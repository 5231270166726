import { useEffect, useContext, useRef } from 'react';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../components/Loader';
import { Sticky } from '../../../../../../components/Sticky';
import { AppointmentTypesContext } from '../AppointmentTypesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

export const columnsWidths = {
  code: 200,
  description: 180,
  duration: 100,
  claimRequired: 120,
  ordering: 100,
  actions: 264
};

const useStyles = makeStyles(styles);

export const List = ({ baseUrl }) => {
  const {
    appointmentTypes,
    isFetching,
    isFetched,
    filter,
    resetAppointmentTypes,
    loadNextPage
  } = useContext(AppointmentTypesContext);
  const scrollElementRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    resetAppointmentTypes();
  }, []);

  return (
    <div className={classes.root}>
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
        <Loader p={2} loading={!isFetched} render={
          () => (!filter.total ? (
            <Typography align="center">No appointment types found</Typography>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={appointmentTypes}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  RowProps={{ baseUrl }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          ))}
        />
      </CustomScrollbars>
    </div>
  );
};
