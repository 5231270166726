import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as templatesApi from '../../../../../../api/profile';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../components/Modal';
import { sidesOptions } from '../../../../../../components/appointments/procedures';
import { Loader } from '../../../../../../components/Loader';
import { reportTypesOptions } from '../../../../../../components/medical/reports/diagnosis/SelectReportTypeModal';
import { agesOptions, validationSchema, Body } from '../CreateTemplateReport';

export const UpdateTemplateReport = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { id }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ initialValues, setInitialValues ] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const updateReport = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return templatesApi.updateTemplate(values).then((data) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  useEffect(() => {
    templatesApi.fetchTemplate(id).then(setInitialValues);
  }, []);

  return (
    <Loader p={3} loading={!initialValues} render={
      () => (
        <Formik
          initialValues={{
            ...initialValues,

            report_type: reportTypesOptions[0],
            age: initialValues.age
              ? agesOptions.find(({ value }) => value === initialValues.age)
              : agesOptions[0],
            side: initialValues.side
              ? sidesOptions.find(({ value }) => value === initialValues.side)
              : sidesOptions[0],
            provider_id: initialValues.provider,
            office_id: initialValues.office,
            visit_reason_id: initialValues.visit_reason,
            forms: initialValues.forms || {}
          }}
          validationSchema={validationSchema}
          onSubmit={updateReport}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
              <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
                <ModalHeader
                  icon={<SvgIcon><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>}
                  onClose={handleModalReject}>
                  Update Initial Report
                </ModalHeader>

                <ModalBody>
                  <Body />
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            </Dialog>
          )}
        </Formik>
      )}
    />
  );
};
