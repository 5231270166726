import AddIcon from '@material-ui/icons/Add';
import { Button } from '../../../../../../components/Button';
import { useModal } from '../../../../../../components/ModalsProvider';
import { PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { CreateTypesModal } from '../../CreateTypesModal';

export const ActionsBar = () => {
  const { openModal } = useModal();

  const addType = () => {
    openModal(CreateTypesModal);
  };

  return (
    <>
      <PageHeaderTitle>Companies Types</PageHeaderTitle>

      <PageHeaderActions>
        <Button color="primary" variant="contained" startIcon={<AddIcon />} onClick={addType}>
          Add type
        </Button>
      </PageHeaderActions>
    </>
  );
};
