import * as types from './cardsTypes';

export const typesOfWidgets = [
  types.TASKS_STATUS_STATISTICS,
  types.TASKS_TYPE_STATISTICS,
  types.TASKS_TIME_STATISTICS,
  types.ANALYTICS_DATA,
  types.USER_REGISTRATION_STATISTICS,
  types.TASKS_PRIORITY_STATISTICS,
  types.TIME_TASK,
  types.TIME_CASE,
  types.CASE_STATISTICS,
  types.CASES_USERS_STATISTICS,
  types.CASES_STATUS_STATISTICS,
  types.FILES,
  types.INVOICES,
  types.EXPENSES_ANALYSIS,
  types.UPCOMING_PAYMENTS,
  types.PAYMENT_HISTORY
];

export const checkedWidgetsTypes = {
  [types.TASKS_STATUS_STATISTICS]: true,
  [types.TASKS_TYPE_STATISTICS]: true,
  [types.TASKS_TIME_STATISTICS]: true,
  [types.ANALYTICS_DATA]: true,
  [types.USER_REGISTRATION_STATISTICS]: true,
  [types.TASKS_PRIORITY_STATISTICS]: true,
  [types.TIME_TASK]: true,
  [types.TIME_CASE]: true,
  [types.CASE_STATISTICS]: true,
  [types.CASES_USERS_STATISTICS]: true,
  [types.CASES_STATUS_STATISTICS]: true,
  [types.FILES]: true,
  [types.INVOICES]: true,
  [types.EXPENSES_ANALYSIS]: true,
  [types.UPCOMING_PAYMENTS]: true,
  [types.PAYMENT_HISTORY]: true
};

export const cardsLayout = {
  breakpoints: { xl: 1440, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  cols: { xl: 24, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  toolbox: { xl: [], lg: [], md: [], sm: [], xs: [], xxs: [] },
  layouts: {
    xl: [
      {
        w: 8,
        h: 10,
        x: 0,
        y: 0,
        i: types.TASKS_STATUS_STATISTICS,
        minW: 8,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 8,
        y: 0,
        i: types.TASKS_TYPE_STATISTICS,
        minW: 8,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 0,
        i: types.TASKS_TIME_STATISTICS,
        minW: 8,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 12,
        h: 10,
        x: 0,
        y: 10,
        i: types.ANALYTICS_DATA,
        minW: 8,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 12,
        h: 10,
        x: 12,
        y: 10,
        i: types.USER_REGISTRATION_STATISTICS,
        minW: 8,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 0,
        y: 20,
        i: types.TASKS_PRIORITY_STATISTICS,
        minW: 8,
        maxW: 8,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 8,
        y: 20,
        i: types.TIME_TASK,
        minW: 8,
        maxW: 8,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 16,
        y: 20,
        i: types.TIME_CASE,
        minW: 8,
        maxW: 8,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 27,
        i: types.CASE_STATISTICS,
        minW: 16,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 27,
        i: types.CASES_USERS_STATISTICS,
        minW: 8,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 37,
        i: types.CASES_STATUS_STATISTICS,
        minW: 16,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 37,
        i: types.FILES,
        minW: 8,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 47,
        i: types.INVOICES,
        minW: 16,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 47,
        i: types.EXPENSES_ANALYSIS,
        minW: 8,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 57,
        i: types.UPCOMING_PAYMENTS,
        minW: 8,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 8,
        y: 57,
        i: types.PAYMENT_HISTORY,
        minW: 16,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],

    lg: [
      {
        w: 4,
        h: 10,
        x: 0,
        y: 0,
        i: types.TASKS_STATUS_STATISTICS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 4,
        y: 0,
        i: types.TASKS_TYPE_STATISTICS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 0,
        i: types.TASKS_TIME_STATISTICS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 10,
        i: types.ANALYTICS_DATA,
        minW: 6,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 6,
        y: 10,
        i: types.USER_REGISTRATION_STATISTICS,
        minW: 6,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 20,
        i: types.TASKS_PRIORITY_STATISTICS,
        minW: 4,
        maxW: 8,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 4,
        y: 20,
        i: types.TIME_TASK,
        minW: 4,
        maxW: 8,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 8,
        y: 20,
        i: types.TIME_CASE,
        minW: 4,
        maxW: 8,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 27,
        i: types.CASE_STATISTICS,
        minW: 8,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 27,
        i: types.CASES_USERS_STATISTICS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 37,
        i: types.CASES_STATUS_STATISTICS,
        minW: 8,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 37,
        i: types.FILES,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 47,
        i: types.INVOICES,
        minW: 8,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 47,
        i: types.EXPENSES_ANALYSIS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 57,
        i: types.UPCOMING_PAYMENTS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 4,
        y: 57,
        i: types.PAYMENT_HISTORY,
        minW: 8,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],

    md: [
      {
        w: 5,
        h: 10,
        x: 0,
        y: 0,
        i: types.TASKS_STATUS_STATISTICS,
        minW: 5,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 5,
        y: 0,
        i: types.TASKS_TYPE_STATISTICS,
        minW: 5,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 0,
        y: 10,
        i: types.TASKS_TIME_STATISTICS,
        minW: 5,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 5,
        y: 10,
        i: types.ANALYTICS_DATA,
        minW: 5,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 20,
        i: types.USER_REGISTRATION_STATISTICS,
        minW: 10,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 7,
        x: 0,
        y: 30,
        i: types.TASKS_PRIORITY_STATISTICS,
        minW: 5,
        maxW: 10,
        minH: 7,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 7,
        x: 5,
        y: 30,
        i: types.TIME_TASK,
        minW: 5,
        maxW: 10,
        minH: 7,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 7,
        x: 0,
        y: 37,
        i: types.TIME_CASE,
        minW: 5,
        maxW: 10,
        minH: 7,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 47,
        i: types.CASE_STATISTICS,
        minW: 5,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 0,
        y: 57,
        i: types.CASES_USERS_STATISTICS,
        minW: 5,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 67,
        i: types.CASES_STATUS_STATISTICS,
        minW: 5,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 5,
        y: 57,
        i: types.FILES,
        minW: 5,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 0,
        y: 77,
        i: types.INVOICES,
        minW: 5,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 5,
        y: 77,
        i: types.EXPENSES_ANALYSIS,
        minW: 5,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 0,
        y: 87,
        i: types.UPCOMING_PAYMENTS,
        minW: 5,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 5,
        h: 10,
        x: 5,
        y: 87,
        i: types.PAYMENT_HISTORY,
        minW: 5,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],

    sm: [
      {
        w: 6,
        h: 10,
        x: 0,
        y: 0,
        i: types.TASKS_STATUS_STATISTICS,
        minW: 3,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 10,
        i: types.TASKS_TYPE_STATISTICS,
        minW: 3,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 20,
        i: types.TASKS_TIME_STATISTICS,
        minW: 3,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 30,
        i: types.ANALYTICS_DATA,
        minW: 3,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 40,
        i: types.USER_REGISTRATION_STATISTICS,
        minW: 3,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 50,
        i: types.TASKS_PRIORITY_STATISTICS,
        minW: 3,
        maxW: 10,
        minH: 6,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 60,
        i: types.TIME_TASK,
        minW: 3,
        maxW: 10,
        minH: 6,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 70,
        i: types.TIME_CASE,
        minW: 3,
        maxW: 10,
        minH: 6,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 80,
        i: types.CASE_STATISTICS,
        minW: 3,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 90,
        i: types.CASES_USERS_STATISTICS,
        minW: 3,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 100,
        i: types.CASES_STATUS_STATISTICS,
        minW: 3,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 110,
        i: types.FILES,
        minW: 3,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 120,
        i: types.INVOICES,
        minW: 3,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 130,
        i: types.EXPENSES_ANALYSIS,
        minW: 3,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 140,
        i: types.UPCOMING_PAYMENTS,
        minW: 3,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 140,
        i: types.PAYMENT_HISTORY,
        minW: 3,
        maxW: 10,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],

    xs: [
      {
        w: 4,
        h: 10,
        x: 0,
        y: 0,
        i: types.TASKS_STATUS_STATISTICS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 10,
        i: types.TASKS_TYPE_STATISTICS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 11,
        x: 0,
        y: 20,
        i: types.TASKS_TIME_STATISTICS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 30,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 31,
        i: types.ANALYTICS_DATA,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 41,
        i: types.USER_REGISTRATION_STATISTICS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 51,
        i: types.TASKS_PRIORITY_STATISTICS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 61,
        i: types.TIME_TASK,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 71,
        i: types.TIME_CASE,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 81,
        i: types.CASE_STATISTICS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 91,
        i: types.CASES_USERS_STATISTICS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 101,
        i: types.CASES_STATUS_STATISTICS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 111,
        i: types.FILES,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 121,
        i: types.INVOICES,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 131,
        i: types.EXPENSES_ANALYSIS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 141,
        i: types.UPCOMING_PAYMENTS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 121,
        i: types.PAYMENT_HISTORY,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],

    xxs: [
      {
        w: 2,
        h: 10,
        x: 0,
        y: 0,
        i: types.TASKS_STATUS_STATISTICS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 10,
        i: types.TASKS_TYPE_STATISTICS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 20,
        i: types.TASKS_TIME_STATISTICS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 30,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 30,
        i: types.ANALYTICS_DATA,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 40,
        i: types.USER_REGISTRATION_STATISTICS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 50,
        i: types.TASKS_PRIORITY_STATISTICS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 60,
        i: types.TIME_TASK,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 70,
        i: types.TIME_CASE,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 80,
        i: types.CASE_STATISTICS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 90,
        i: types.CASES_USERS_STATISTICS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 100,
        i: types.CASES_STATUS_STATISTICS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 110,
        i: types.FILES,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 120,
        i: types.INVOICES,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 130,
        i: types.EXPENSES_ANALYSIS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 140,
        i: types.UPCOMING_PAYMENTS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 150,
        i: types.PAYMENT_HISTORY,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ]
  }
};
