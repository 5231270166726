import { useState, createContext, useEffect } from 'react';
import { eventBus, eventBusEvents } from '../../../utils/eventBus';

export const MessengerContext = createContext(null);

export const MessengerContextProvider = ({ children, currentThreadId: currentThreadIdProp }) => {
  const [ filter, setFilter ] = useState({});
  const [ currentThreadId, setCurrentThreadId ] = useState(currentThreadIdProp);

  const applyFilter = (newFilter = {}) => {
    setFilter((filter) => ({ ...filter, ...newFilter }));
  };

  const providerValue = {
    filter,
    currentThreadId: currentThreadIdProp || currentThreadId,

    applyFilter,
    setCurrentThreadId
  };

  useEffect(() => {
    eventBus.on(eventBusEvents.openThreadInWidget, setCurrentThreadId);

    return () => {
      eventBus.remove(eventBusEvents.openThreadInWidget, setCurrentThreadId);
    };
  }, []);

  return (
    <MessengerContext.Provider value={providerValue}>
      {children}
    </MessengerContext.Provider>
  );
};

