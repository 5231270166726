import {
  Box,
  ListItem,
  Typography,
  List as MuiList,
  FormControlLabel
} from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { addHiddenFilter, removeHiddenFilter } from '../../../store/globalUser/operations';
import { Checkbox } from '../../FormField';
import { IconButton } from '../../IconButton';
import { Popper } from '../../Popper';

export const SettingsComponent = ({ filterKey, fieldsList, onFieldHidden }) => {
  const hiddenFilterFields = useSelector(({ globalUser }) => globalUser?.data?.hiddenFilterFields?.[filterKey] || []);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const showFilter = (field) => {
    dispatch(removeHiddenFilter(filterKey, field)).catch(() => {
      enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
    });
  };

  const hideFilter = (field) => {
    dispatch(addHiddenFilter(filterKey, field)).catch(() => {
      enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
    });

    onFieldHidden(field);
  };

  const toggleFilterField = (field) => () => {
    hiddenFilterFields?.includes(field) ? showFilter(field) : hideFilter(field);
  };

  return (
    <Popper
      placement="left"
      anchorRenderer={({ anchorRef, handleToggle }) => (
        <IconButton
          color="primary"
          edge="end"
          buttonRef={anchorRef}
          onClick={handleToggle}
        >
          <SettingsOutlinedIcon />
        </IconButton>
      )}
    >
      {() => (
        <Box flexBasis={400} p={2}>
          <MuiList disablePadding>
            {fieldsList.map(({ label, fieldKey }) => !!fieldKey && (
              <ListItem key={fieldKey}>
                <FormControlLabel
                  label={<Typography color="textSecondary">{label}</Typography>}
                  control={
                    <Checkbox
                      withoutFormik
                      color="primary"
                      onClick={toggleFilterField(fieldKey)}
                      checked={!hiddenFilterFields?.includes(fieldKey)}
                    />
                  }
                />
              </ListItem>
            ))}
          </MuiList>
        </Box>
      )}
    </Popper>
  );
};
