import { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setTasksLastGlobalAction } from '../../../store/globalActions';
import * as tasksApi from '../../../api/tasks';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { initialState } from './initialState';
import * as types from './types';

export const TasksFilterContext = createContext();

export const TasksFilterContextProvider = ({ children, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();
  const { openModal } = useModal();
  const [ totalTasks, setTotalTasks ] = useState();
  const [ isUpdatedPosition, setIsUpdatedPosition ] = useState(false);
  const [ resultDestination, setResultDestination ] = useState(null);
  const [ selectedTasksIDs, setSelectedTasksIDs ] = useState([]);
  const [ filter, setFilter ] = useState({
    ...initialState.filter,
    ...props.filter
  });
  const [ relationsForFilter, setRelationsForFilter ] = useState();

  const applyFilter = (newFilter) => {
    setFilter((filter) => ({ ...filter, ...newFilter }));
  };

  const massDeleteTasks = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        tasksApi.massDeleteTasks(IDs).then(() => {
          dispatchRedux(setTasksLastGlobalAction({ type: types.MASS_DELETE_TASK, payload: IDs }));
          enqueueSnackbar('Tasks successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Tasks are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const updateTaskPosition = ({ id, newIndex, status }) => {
    setIsUpdatedPosition(false);
    tasksApi.updateTaskPosition(id, {
      index: newIndex,
      status_id: status,
      ...filter
    }).then(() => {
      setResultDestination(newIndex);
      setIsUpdatedPosition(true);
    }).catch((error) => {
      if (error) {
        dispatchRedux(setTasksLastGlobalAction({ type: types.UPDATE_TASK_POSITION_FAILD }));
        enqueueSnackbar('Position not updated', { variant: 'error' });
      }
    });
  };

  const providerValue = {
    filter,
    relationsForFilter,
    totalTasks,
    selectedTasksIDs,
    isUpdatedPosition,
    resultDestination,

    setFilter,
    setRelationsForFilter,
    applyFilter,
    setTotalTasks,
    setSelectedTasksIDs,
    massDeleteTasks,
    updateTaskPosition
  };

  return (
    <TasksFilterContext.Provider value={providerValue}>
      {children}
    </TasksFilterContext.Provider>
  );
};
