import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_FEATURE_FLAGS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_FEATURE_FLAGS_SUCCESS]: (
    { filter, featureFlags, ...state },
    { data: newFeatureFlag, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      featureFlags: (page > 1
        ? featureFlags.filter(({ id }) => {
          return !newFeatureFlag.find((loadedClaim) => id === loadedClaim.id);
        }).concat(newFeatureFlag)
        : newFeatureFlag
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_FEATURE_FLAGS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_FEATURE_FLAG]: (state, updatedFeatureFlag) => {
    return {
      ...state,

      featureFlags: state.featureFlags.map((featureFlag) => {
        return featureFlag.id === updatedFeatureFlag.id ? { ...featureFlag, ...updatedFeatureFlag } : featureFlag;
      })
    };
  },

  [types.ADD_FEATURE_FLAG]: (state, featureFlag) => {
    const { pagination, filter, featureFlags } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((featureFlags.length + 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      featureFlags: [ featureFlag, ...featureFlags ]
    };
  },

  [types.DELETE_FEATURE_FLAG]: (state, featureFlagID) => {
    const { pagination, filter, featureFlags, selectedFeatureFlagsIDs } = state;
    const filteredFeatureFlags = featureFlags.filter(({ id }) => featureFlagID !== id);

    if (filteredFeatureFlags.length === featureFlags.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((featureFlags.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      featureFlags: filteredFeatureFlags,
      selectedFeatureFlagsIDs: selectedFeatureFlagsIDs.filter((id) => featureFlagID !== id)
    };
  }
});
