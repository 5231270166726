export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    flexGrow: 1
  },

  actionButton: {
    flexGrow: 0,
    marginLeft: 'auto',
    padding: spacing(0, 5, 2, 5 )
  },

  backButton: {
    marginRight: spacing()
  }
});
