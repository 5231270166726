export const styles = ({ spacing, breakpoints }) => ({
  root: {
    height: '100%',
    paddingTop: spacing(3),
    paddingLeft: spacing(4),
    paddingRight: spacing(3),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      paddingTop: spacing(1),
      paddingLeft: spacing(1.5),
      paddingRight: spacing(1)
    }
  }
});
