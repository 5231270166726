import { useContext, useRef } from 'react';
import { get } from 'lodash';
import { Icon } from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import {
  makeStyles,
  Fab,
  Typography,
  Slider,
  SvgIcon,
  Tooltip,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import ResetIcon from '@material-ui/icons/Autorenew';
import ArrowIcon from '@material-ui/icons/TrendingFlat';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { BodyPartsContext } from '../BodyPartsContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const painDirections = [ 0, 45, 90, 135, 180, 225, 270, 315 ];
const painLevelSliderMarks = Array(11).fill(0).map((level, index) => ({ label: index, value: index }));

export const RightSideBar = () => {
  const { painfulParts, selectedPart, updatePainfulPart } = useContext(BodyPartsContext);
  const classes = useStyles(painfulParts[selectedPart]);
  const scrollElementRef = useRef();
  const isAllPainLavelsFilled = Object.values(painfulParts).every(({ level }) => level);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangeSlider = (event, newValue) => {
    updatePainfulPart(selectedPart, {
      ...painfulParts[selectedPart],
      level: newValue
    });
  };

  const handlePainDirectionChange = (deg) => () => {
    updatePainfulPart(selectedPart, {
      ...painfulParts[selectedPart],
      direction: painfulParts[selectedPart].direction === deg ? null : deg
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      {!isAllPainLavelsFilled && !isMobile &&
        <Box p={2} pb={0}>
          <Typography variant="caption" color="error">Level is required</Typography>
        </Box>
      }

      <Scrollbars viewRef={scrollElementRef}>
        <div className={classes.root}>
          {selectedPart ?
            <>
              <div className={classes.control}>
                <Typography variant="h4" className={classes.title}>1.</Typography>

                <Tooltip
                  title="Circle the number that best describes how severe your pain is: (0 = none; 10 = worst)"
                >
                  <SvgIcon fontSize="small" color="error"><Icon path={mdiAlertCircleOutline} /></SvgIcon>
                </Tooltip>

                <Slider
                  orientation="vertical"
                  value={get(painfulParts, `${selectedPart}.level`, 0)}
                  step={1}
                  min={0}
                  max={10}
                  marks={painLevelSliderMarks}
                  classes={{
                    root: classes.sliderRoot,
                    track: classes.track,
                    rail: classes.rail,
                    mark: classes.mark,
                    thumb: classes.thumb
                  }}
                  onChange={handleChangeSlider}
                />
              </div>

              <div className={classes.control}>
                <Typography variant="h4" className={classes.title}>2.</Typography>

                <Tooltip title="Indicate the direction of pain by selecting the appropriate arrow direction">
                  <div>
                    <SvgIcon fontSize="small" color="error"><Icon path={mdiAlertCircleOutline} /></SvgIcon>
                  </div>
                </Tooltip>

                <div className={classes.painDirections}>
                  {painDirections.map((deg) => (
                    <Fab
                      key={deg}
                      color={get(painfulParts, `${selectedPart}.direction`) === deg ? 'primary' : 'default'}
                      style={{ transform: `rotate(${deg}deg)` }}
                      onClick={handlePainDirectionChange(deg)}
                    >
                      <ArrowIcon />
                    </Fab>
                  ))}

                  <Fab
                    color={get(painfulParts, `${selectedPart}.direction`) === 360 ? 'primary' : 'default'}
                    onClick={handlePainDirectionChange(360)}
                  >
                    <ResetIcon/>
                  </Fab>
                </div>
              </div>
            </>
            :
            <Box p={2}>
              <Typography color="textSecondary">
                Please mark the place in the image below where your pain begins and show where it is going,
                using the arrow
              </Typography>
            </Box>
          }
        </div>
      </Scrollbars>
    </Box>
  );
};
