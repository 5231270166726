import { makeStyles, SvgIcon, useMediaQuery } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api';
import * as filesApi from '../../../api/files';
import { AppBar } from '../../../components/AppBar';
import { Button } from '../../../components/Button';
import { Fab } from '../../../components/Fab';
import { IconButton } from '../../../components/IconButton';
import { SelectedItemsCount } from '../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../components/VerticalDivider';
import { downloadFile } from '../../../helpers/files';
import { useQueryParams } from '../../../helpers/hooks/useQueryParams';
import { logout } from '../../../store/auth/actions';
import { uploadFiles } from '../../../store/dashboard/files/uploads';
import { useFileValidation } from '../../../utils/hooks';
import { FilesFilterContext } from '../../Dashboard/files-common';
import { styles } from '../../Dashboard/FilesPage/MyFilesPage/ActionsBar/styles';
import { UploadsWidget } from '../../Dashboard/Layout/Header/UploadsWidget';

const useStyles = makeStyles(styles);

export const ActionsBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { selectedFilesIDs } = useContext(FilesFilterContext);
  const { token } = useQueryParams();
  const [ filesCount, setFilesCount ] = useState();
  const cancelFetch = useRef(() => {});
  const { validateFiles } = useFileValidation({
    isPersonalShared: true,
    onFileValidated: (file) => {
      dispatch(uploadFiles({
        isPersonalShared: true,
        files: [ file ]
      }));
    }
  });

  const handleLogout = () => {
    dispatch(logout());
    history.push('/');
  };

  const fetchFilesCount = () => {
    cancelFetch.current();

    filesApi.fetchPersonalSharedFiles({
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel),
      headers: {
        authorization: token && `Bearer ${token}`
      }
    }).then(({ pagination }) => {
      setFilesCount(pagination.total);
    });
  };

  const handleDropAccepted = (files) => {
    validateFiles(files);
  };

  const { getInputProps, open } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: handleDropAccepted
  });

  const handleDownloadSelectedFiles = () => {
    filesApi.downloadPersonalSharedFiles({
      responseType: 'arraybuffer',
      params: {
        files: selectedFilesIDs
      }
    }).then((data) => {
      downloadFile({
        src: new Blob([ data ], { type: 'application/octet-stream' }),
        name: `Shared files - ${moment().format('L LT')}.zip`
      });
    });
  };

  useEffect(() => {
    selectedFilesIDs.length && fetchFilesCount();

    return () => {
      cancelFetch.current();
    };
  }, [ selectedFilesIDs ]);

  return (
    <AppBar ToolbarProps={{ className: classes.root }}>
      <Fab
        variant="extended"
        color="primary"
        startIcon={<CloudUploadIcon />}
        onClick={open}
      >
        Upload

        <input {...getInputProps()} />
      </Fab>

      <div className={classes.rightSide}>
        {!!selectedFilesIDs.length &&
          <div className={classes.multiActions}>
            <SelectedItemsCount total={filesCount} length={selectedFilesIDs.length} />

            <VerticalDivider verticalGutters={isTablet ? 0 : 1} horizontalGutters={2} />

            <IconButton
              edge="start"
              title="Download selected files"
              onClick={handleDownloadSelectedFiles}
            >
              <SvgIcon>
                <DownloadIcon />
              </SvgIcon>
            </IconButton>
          </div>
        }

        <UploadsWidget isPersonalShared />

        <Button onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </AppBar>
  );
};
