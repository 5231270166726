import { useContext } from 'react';
import { flow } from 'lodash';
import {
  List,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { hasRole } from '../../../../../utils/hasRole';
import { useModal } from '../../../../../components/ModalsProvider';
import { EditNoteModal } from '../EditNoteModal';
import { NotesContext } from '../NotesProvider';
import { PreviewNote } from '../PreviewNote';

export const NotesMenu = ({ note, onClose }) => {
  const { openModal } = useModal();
  const { deleteNote, fetchNotes, updateNote } = useContext(NotesContext);

  const handleUpdateNote = () => {
    openModal(EditNoteModal, {
      payload: {
        initialValues: note
      },
      onModalResolved: (data) => {
        updateNote(data);
      }
    });
  };

  const handleDeleteNote= () => {
    deleteNote(note.id);
  };

  const openPreview = () => {
    openModal(PreviewNote, {
      payload: {
        noteId: note.id,
        onNoteUpdate: updateNote
      },
      onModalResolved: () => {
        fetchNotes();
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={flow(openPreview, onClose)}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {!hasRole('client', 'patient') &&
        <>
          <MenuItem onClick={flow(handleUpdateNote, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit" />
          </MenuItem>

          <MenuItem onClick={flow(handleDeleteNote, onClose)}>
            <ListItemIcon>
              <DeleteIcon color="error" fontSize="inherit"/>
            </ListItemIcon>

            <ListItemText primary="Delete" />
          </MenuItem>
        </>
      }
    </List>
  );
};
