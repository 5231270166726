import {
  Box,
  FormControlLabel,
  Grid,
  Hidden,
  Typography
} from '@material-ui/core';
import { TextField, Checkbox, KeyboardDatePicker } from '../../../../../../components/FormField';

export const RadiologyExams = () => {
  return (
    <Grid container spacing={0} component={Box} px={2}>
      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="No new data"
          control={
            <Checkbox
              color="primary"
              name="forms.radiology_exams_lab_results.no_new_data"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="MRI"
          control={
            <Checkbox
              color="primary"
              name="forms.radiology_exams_lab_results.mri"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="CT"
          control={
            <Checkbox
              color="primary"
              name="forms.radiology_exams_lab_results.ct"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="X-ray"
          control={
            <Checkbox
              color="primary"
              name="forms.radiology_exams_lab_results.x_ray"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Labs"
          control={
            <Checkbox
              color="primary"
              name="forms.radiology_exams_lab_results.labs"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="EMG"
          control={
            <Checkbox
              color="primary"
              name="forms.radiology_exams_lab_results.emg"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Other"
          control={
            <Checkbox
              color="primary"
              name="forms.radiology_exams_lab_results.other"
            />
          }
        />
      </Grid>

      <Hidden only="xs">
        <Grid item xs={8} />
      </Hidden>

      <Grid item sm={4} xs={12}>
        <Box display="flex" alignItems="flex-end" pr={4}>
          <Box mr={1}>
            <Typography variant="h5">Date:</Typography>
          </Box>

          <KeyboardDatePicker
            clearable
            outputFormat="YYYY-MM-DD"
            placeholder="MM/DD/YYYY"
            name="forms.radiology_exams_lab_results.date"
          />
        </Box>
      </Grid>

      <Grid item sm={8} xs={12}>
        <Box display="flex" alignItems="flex-end" pr={4}>
          <Box mr={1}>
            <Typography variant="h5">Findings:</Typography>
          </Box>

          <TextField
            zeroMinWidth
            name="forms.radiology_exams_lab_results.findings"
          />
        </Box>
      </Grid>
    </Grid>
  );
};
