export const styles = ({ spacing }) => ({
  root: {
    paddingTop: spacing(6),
    paddingBottom: spacing(5)
  },

  formField: {
    marginBottom: spacing(2)
  }
});
