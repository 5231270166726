import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../../dataMaps/apiModels';
import { setPaymentsLastGlobalAction } from '../../../../../../../store/globalActions';
import * as paymentsApi from '../../../../../../../api/billing';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { Fab } from '../../../../../../../components/Fab';
import * as types from '../../../../../../../components/billing/payments/PaymentsContext/types';

export const OverdraftActions = ({ payment }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ isRefunded, setIsRefunded ] = useState(!!payment.refunded_at);

  const getReceiveOverdraftPage = () => {
    history.push(itemsRoutesByModels.overdraft(payment.id));
  };

  const handleRefund = () => {
    if (payment?.causer_invoice) {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          paymentsApi.refundPayment({ id: payment.id }).then((data) => {
            dispatch(setPaymentsLastGlobalAction({ type: types.REFUND_PAYMENT, payload: data }));
            enqueueSnackbar('Payment successfully refunded', { variant: 'success' });
            setIsRefunded((state) => !state);
          }).catch(() => {
            enqueueSnackbar('Payment not refunded', { variant: 'error' });
          });
        }
      });
    } else {
      enqueueSnackbar('Payment doesn\'t have invoice', { variant: 'warning' });
    }
  };

  const handleCancelRefund = () => {
    if (payment?.causer_invoice) {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          paymentsApi.cancelRefundPayment({ id: payment.id }).then((data) => {
            dispatch(setPaymentsLastGlobalAction({ type: types.CANCEL_REFUND_PAYMENT, payload: data }));
            enqueueSnackbar('Payment successfully canceled', { variant: 'success' });
            setIsRefunded((state) => !state);
          }).catch(() => {
            enqueueSnackbar('Payment not canceled', { variant: 'error' });
          });
        }
      });
    } else {
      enqueueSnackbar('Payment doesn\'t have invoice', { variant: 'warning' });
    }
  };

  return (
    <>
      {!isRefunded &&
        <Fab
          size="small"
          color="primary"
          variant="extended"
          onClick={stopPropagation(getReceiveOverdraftPage)}
        >
          Apply to Inv.
        </Fab>
      }

      <Fab
        size="small"
        color="secondary"
        variant="extended"
        onClick={stopPropagation(isRefunded ? handleCancelRefund : handleRefund)}
      >
        {isRefunded ? 'Cancel Refunding' : 'Refund'}
      </Fab>
    </>
  );
};
