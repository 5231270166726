import { makeStyles } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import PhotoCameraIcon from '@material-ui/icons/PhotoCameraOutlined';
import { getUserNameAbbr } from '../../../helpers/users';
import { Avatar as AvatarComponent } from '../../Avatar';
import { useModal } from '../../ModalsProvider';
import { styles } from './styles';
import { UploadAvatarModal } from './UploadAvatarModal';

const useStyles = makeStyles(styles);

export const Avatar = ({ user, onSubmit }) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const uploadAvatar = () => {
    openModal(UploadAvatarModal, {
      payload: {
        onSubmit
      }
    });
  };

  return (
    <div className={classes.root}>
      <AvatarComponent
        size="extraLarge"
        className={classes.avatar}
        alt={getUserNameAbbr(user)}
        src={user.avatar}
      />

      <div className={classes.actions}>
        <div className={classes.actions__button} onClick={uploadAvatar}>
          <CloudUploadIcon color="inherit" />
        </div>

        <div className={classes.actions__button} onClick={uploadAvatar}>
          <PhotoCameraIcon color="inherit" />
        </div>
      </div>
    </div>
  );
};
