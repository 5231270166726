import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { preventDefault } from '../../../../../../../helpers/dom';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { IconButton } from '../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../components/UserLink';
import { CardItem } from '../../../../../../../components/Cards';
import { Category } from '../../../../BillingSettings/Category';
import { Date } from '../../../../../../../components/Date';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({
  index,
  parentPage,
  expense,
  onUpdateExpense,
  onDeleteExpense,
  files,
  openFilesPreview
}) => {
  const classes = useStyles();

  return (
    <CardItem py={1} px={2}>
      <Box display="flex" alignItems="center">
        <UserLink
          noWrap
          size="md"
          path={`/members/users/${expense?.user?.id}`}
          user={expense?.user}
        />

        <Box ml="auto">
          {parentPage &&
            <IconButton
              color="primary"
              title="Edit expense"
              onClick={preventDefault(onUpdateExpense(expense))}
            >
              <EditIcon />
            </IconButton>
          }

          <IconButton
            edge="end"
            color="error"
            title="Delete expense"
            onClick={preventDefault(onDeleteExpense(index, expense))}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Typography noWrap>
            Date:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Date disableIcon date={expense.date} format="L, LT" />
        </Grid>

        {expense.category &&
          <>
            <Grid item xs={5}>
              <Typography noWrap>
                Category:
              </Typography>
            </Grid>

            <Grid item xs={7}>
              <Category category={expense?.category} />
            </Grid>
          </>
        }

        <Grid item xs={5}>
          <Typography noWrap>
            Notes:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography noWrap title={expense.notes}>
            {expense.notes}
          </Typography>
        </Grid>

        {(files?.length > 0) &&
          <>
            <Grid item xs={5}>
              <Typography noWrap>
                Attachments:
              </Typography>
            </Grid>

            <Grid item xs={7}>
              <div className={classes.expensesFiles} onClick={openFilesPreview}>
                <AttachFileIcon fontSize="inherit" />

                {files.length}
              </div>
            </Grid>
          </>
        }

        <Grid item xs={5}>
          <Typography noWrap>
            Budgeted:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5" className={classes.budget}>
            <CurrencyFormat value={expense.budget} />
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap>
            Amount:
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            <CurrencyFormat value={expense.amount} />
          </Typography>
        </Grid>
      </Grid>
    </CardItem>
  );
};
