import { StatisticsFilterProvider } from './StatisticsFilterProvider';
import { DashboardContent } from './DashboardContent';

export const widthBreakpointSmall = 1250;
export const widthBreakpointMedium = 1300;
export const widthBreakpointLarge = 1400;

export const Dashboard = () => {
  return (
    <StatisticsFilterProvider>
      <DashboardContent />
    </StatisticsFilterProvider>
  );
};
