export const rolesMap = {
  medic: 'medic',
  doctor: 'doctor',
  advocate: 'advocate',
  client: 'client',
  admin: 'admin',
  secretary: 'secretary',
  supervisor: 'supervisor',
  patient: 'patient'
};
