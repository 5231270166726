import { useContext, useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Grid, Box, Typography, FormControlLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { hasRole } from '../../../../../../utils/hasRole';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { Checkbox, TextField } from '../../../../../../components/FormField';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { Fab } from '../../../../../../components/Fab';
import { StepContext } from '../StepProvider';

export const Body = () => {
  const { values, dirty } = useFormikContext();
  const { setIsDirty } = useContext(StepContext);
  const { openModal } = useModal();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  useEffect(() => {
    setIsDirty(dirty);
  }, [ dirty ]);

  return (
    <Box flexGrow={1} pb={4}>
      <FieldArray
        name="forms.treatment_plan"
        render={({ remove, push }) => (
          <>
            <Box mb={2} color="info.main" display="flex" alignItems="center">
              <Typography variant="h4" color="secondary">
                Treatment & Plan
              </Typography>
            </Box>

            {values?.forms?.treatment_plan?.map((item, index) => (
              <Grid
                container
                spacing={3}
                key={index}
                alignItems="center"
                justify="space-between"
                component={Box}
                pb={4}
              >
                <Grid item xs={11}>
                  <Box textAlign="right">
                    <FormControlLabel
                      label="Approve"
                      control={
                        <Checkbox
                          disabled={!hasRole(rolesMap.advocate)}
                          color="primary"
                          name={`forms.treatment_plan.${index}.approve`}
                        />
                      }
                    />
                  </Box>
                </Grid>

                {values?.forms?.treatment_plan?.length > 1 && (
                  <Grid item>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={handleClearField(remove, index)}
                    >
                      <ClearIcon fontSize="small"/>
                    </IconButton>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    disableUnderline
                    rows={4}
                    name={`forms.treatment_plan.${index}.text`}
                    variant="filled"
                  />
                </Grid>
              </Grid>
            ))}

            <Box component="span">
              <Fab
                size="small"
                color="primary"
                variant="extended"
                startIcon={<AddIcon/>}
                onClick={() => push({ text: null, approve: false })}
              >
                Add new
              </Fab>
            </Box>
          </>
        )}
      />
    </Box>
  );
};
