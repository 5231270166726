export const styles = ({ palette, typography: { pxToRem } }) => ({
  badge: {
    right: 8,
    bottom: 8
  },

  badgeIcon: {
    color: palette.success.main,
    backgroundColor: palette.background.default,
    fontSize: pxToRem(15),
    borderRadius: 50
  }
});
