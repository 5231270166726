import { Paper } from '@material-ui/core';
import React from 'react';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.case}>
          Case
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.company}>
          Company
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.time}>
          Time
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.rate}>
          Rate
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.isActive}>
          Active
        </ListRowCell>

        {hasRole(rolesMap.admin, rolesMap.supervisor) &&
          <ListRowCell flexBasis={columnsWidths.actions}/>
        }
      </ListRow>
    </Paper>
  );
};
