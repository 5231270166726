import { useContext, useEffect, useRef } from 'react';
import { Typography, List as MuiList, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { useResizeObserver } from '../../../../helpers/hooks/useResizeObserver';
import { CustomScrollbars } from '../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../components/InfiniteLoader';
import { Loader } from '../../../../components/Loader';
import { FilesContext } from '../FilesContext';
import { Row } from './Row';

export const Attachments = ({ height, disableFetching, disableVirtual }) => {
  const filesContext = useContext(FilesContext);
  const {
    files,
    filter,
    isFetched,
    isFetching,
    loadNextPage,
    resetFiles
  } = filesContext;
  const theme = useTheme();
  const scrollElementRef = useRef();
  const { width } = useResizeObserver({ ref: scrollElementRef });
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  useEffect(() => {
    resetFiles();
  }, []);

  return (
    <CustomScrollbars
      translateContentSizesToHolder={!!disableVirtual}
      scrollerRef={scrollElementRef}
      style={disableVirtual ? { maxHeight: 400 } : { height: isMobile && height ? height : '100%' }}
    >
      <Loader p={2} loading={!disableFetching && !isFetched} render={
        () => !files.length ? (
          <Box p={3}>
            <Typography align="center">No attachments</Typography>
          </Box>
        ) : (
          <MuiList disablePadding>
            {disableVirtual ? files.map((file) => (
              <Row key={file.id} isLoaded item={file} width={width} filesContext={filesContext} />
            )) : (
              <InfiniteListLoader
                items={files}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ width, filesContext }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            )}
          </MuiList>
        )}
      />
    </CustomScrollbars>
  );
};
