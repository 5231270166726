import { Box } from '@material-ui/core';
import { AccessControlProvider } from './AccessControlProvider';
import { ActionsBar } from './ActionsBar';
import { List } from './List';

export const AccessControlPage = () => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <AccessControlProvider>
        <ActionsBar />
        <List />
      </AccessControlProvider>
    </Box>
  );
};
