import { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../InfiniteLoader';
import { Loader } from '../../../Loader';
import { Scrollbars } from '../../../Scrollbars';
import { AppointmentsContext } from '../../AppointmentsProvider';
import { Row } from './Row';

export const List = ({
  hidePatient,
  hideProvider,
  onAppointmentUpdate,
  onAppointmentDelete
}) => {
  const { isFetched, isFetching, appointments, meta, loadNextPage } = useContext(AppointmentsContext);
  const scrollElementRef = useRef();

  return (
    <Scrollbars viewRef={scrollElementRef}>
      <Box height="100%">
        <Loader p={2} loading={!isFetched} render={
          () => !meta.total ? (
            <Typography align="center">No appointments</Typography>
          ) : (
            <MuiList disablePadding>
              <InfiniteListLoader
                items={appointments}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                RowComponent={Row}
                RowProps={{
                  hidePatient,
                  hideProvider,
                  onAppointmentUpdate,
                  onAppointmentDelete
                }}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
              />
            </MuiList>
          )}
        />
      </Box>
    </Scrollbars>
  );
};
