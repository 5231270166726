import { measureTypes } from '../../../../../../helpers/measureDataMap';
import { UserMeasuresInfo } from './UserMeasuresInfo';

export const UserMRI = ({ form }) => {
  return form.patient?.id && form.medical?.id ? (
    <UserMeasuresInfo
      dob={form.birthday}
      height={form?.medical?.height || 0}
      weight={form?.medical?.weight || 0}
      heightUnit={measureTypes[form?.medical?.height_unit]}
      weightUnit={measureTypes[form?.medical?.weight_unit]}
    />
  ) : null;
};
