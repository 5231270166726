export const sliderMarks = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11 ].map((level) => ({
  label: level === 0 ? 'No pain' : level === 11 ? 'Strong pain' : `${level}`,
  value: level
}));

export const reliefSliderMarks = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11 ].map((level) => ({
  label: level === 0 ? 'No relief' : level === 11 ? 'Total relief' : `${level}`,
  value: level
}));

export const orthoSliderMarks = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ].map((level) => ({
  label: level,
  value: level
}));
