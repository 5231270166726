import * as formsApi from '../../../../api/profile';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';

export const transformMedicalFormsTypesToOption = (type) => type && ({
  label: type,
  value: type,
  data: {
    label: type,
    value: type
  }
});

const fetchMedicalFormsTypes = fetchDataForAsyncSelect(() => {
  return formsApi.fetchMedicalFormsTypes();
}, {
  withoutPagination: true,
  transformDataToOption: transformMedicalFormsTypesToOption
});

export const MedicalFormsTypesSelect = (props) => {
  return (
    <Select
      isAsync
      formattedValue
      name="form_type"
      label="Select a medical form type"
      promiseWithOptions={fetchMedicalFormsTypes}
      {...props}
    />
  );
};
