export const styles = ({ palette, spacing, breakpoints }) => ({
  root: {
    marginBottom: spacing()
  },

  replyButton: {
    marginRight: spacing(2),
    marginBottom: spacing(0.75),
    marginTop: spacing(0.75)
  },

  form: {
    width: '100%',

    [breakpoints.down('sm')]: {
      padding: spacing(2)
    }
  },

  formField: {
    paddingBottom: spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    [breakpoints.down('sm')]: {
      paddingBottom: spacing(2)
    }
  },

  userImage: {
    marginRight: spacing(1.5),

    [breakpoints.down(breakpoints.values.mobileSm)]: {
      display: 'none'
    }
  },

  commentUser: {
    maxHeight: 'none',
    whiteSpace: 'nowrap',
    padding: spacing(0.5, 1),
    background: palette.grey[100]
  }
});
