export const filterFieldsMap = {
  search: 'search',
  group: 'group_id',
  type: 'type'
};

export const filterFieldsLabels = {
  [filterFieldsMap.search]: 'Search',
  [filterFieldsMap.group]: 'Group',
  [filterFieldsMap.type]: 'Type'
};
