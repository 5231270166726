import { LayoutContextProvider } from '../../../components/LayoutContext';
import { FaxProvider } from './FaxProvider';
import { PageContent } from './PageContent';

export const FaxPage = () => {
  return (
    <LayoutContextProvider>
      <FaxProvider>
        <PageContent />
      </FaxProvider>
    </LayoutContextProvider>
  );
};
