import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useFormikContext } from 'formik';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import * as serviceLinesApi from '../../../../../../../api/claims/service-lines';
import { ConfirmationModal } from '../../../../../../ConfirmationModal';
import { IconButton } from '../../../../../../IconButton';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../ListRow';
import { useModal } from '../../../../../../ModalsProvider';
import { columnsWidths } from './ServiceLines';
import { ServiceLineFormModal } from '../../../../../ServiceLineFormModal';
import { transformServiceLineForForm } from '../../../../../ServiceLineFormModal/ServiceLineForm';
import { ServiceLineViewModal } from '../../../../../ServiceLineViewModal';

export const Row = ({ claim, serviceLine, procedureIndex, serviceLineIndex }) => {
  const { openModal } = useModal();
  const { values: { appointment }, setFieldValue } = useFormikContext();
  const { enqueueSnackbar } = useSnackbar();

  const viewServiceLine = () => {
    openModal(ServiceLineViewModal, {
      payload: {
        authorization: claim.authorization,
        serviceLine
      }
    });
  };

  const editServiceLine = () => {
    openModal(ServiceLineFormModal, {
      payload: {
        claim,
        initialValues: serviceLine
      },

      onModalResolved: (serviceLine) => {
        setFieldValue(
          `appointment.procedures[${procedureIndex}].service_line[${serviceLineIndex}]`,
          transformServiceLineForForm({ serviceLine, appointment })
        );

        enqueueSnackbar('Service Line successfully updated', { variant: 'success' });
      }
    });
  };

  const deleteServiceLine = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        serviceLinesApi.deleteServiceLine(serviceLine.id).then(() => {
          setFieldValue(
            `appointment.procedures[${procedureIndex}].service_line`,
            appointment.procedures[procedureIndex].service_line.filter(({ id }) => id !== serviceLine.id)
          );

          enqueueSnackbar('Service Line successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  return (
    <ListRow>
      <ListRowCell grow column flexBasis={columnsWidths.updated_at}>
        {moment.unix(serviceLine.updated_at).format('L LT')}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.certificationRevision}>
        {serviceLine.certification_revision_date ?
          moment(serviceLine.certification_revision_date, 'YYYY-MM-DD').format('L') : '-'
        }
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.lastCertification}>
        {serviceLine.last_certification_date ?
          moment(serviceLine.last_certification_date, 'YYYY-MM-DD').format('L') : '-'
        }
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.beginTherapy}>
        {serviceLine.begin_therapy_date ?
          moment(serviceLine.begin_therapy_date, 'YYYY-MM-DD').format('L') : '-'
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        <ListRowCellActions>
          <IconButton
            title="View"
            color="info"
            onClick={viewServiceLine}
          >
            <EyeIcon />
          </IconButton>

          <IconButton
            title="Edit"
            color="info"
            onClick={editServiceLine}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            edge="end"
            title="Delete"
            color="error"
            onClick={deleteServiceLine}
          >
            <DeleteIcon />
          </IconButton>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
