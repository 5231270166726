export const generateAddress = (address) => {
  const result = {
    street: address?.street || null,
    unit_number: address?.unit_number && address?.designator
      ? address?.designator + ' ' + address?.unit_number
      : address?.unit_number || null,
    city: address?.city || null,
    state: address?.state || null,
    zip: address?.zip || null
  };

  return Object.values(result).filter((addressPart) => addressPart !== null).join(', ') || null;
};

export const getAddress = (address = {}) => {
  return {
    street: address.street || null,
    designator: address.designator || null,
    unit_number: address.unit_number || null,
    city: address.city || null,
    state: address.state || null,
    zip: address.zip || null,
    country: address.country || null,
    phone: address.phone || null,
    fax: address.fax || null,
    mobile_phone: address.mobile_phone || null,
    email: address.email || null
  };
};
