import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplitOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import AddIcon from '@material-ui/icons/Add';
import { LayoutContext, viewVariantsMap } from '../../../../../LayoutContext';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../Page';
import { useModal } from '../../../../../ModalsProvider';
import { IconButton } from '../../../../../IconButton';
import { Fab } from '../../../../../Fab';
import { CaseRemindersFilterContext } from '../../CaseRemindersFiltersProvider';
import { CreateCaseRemindersModal } from '../../CreateCaseRemindersModal';

export const Header = ({ width, middleScreenWidth, largeScreenWidth, filterOpenButton }) => {
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { caseId, applyFilter } = useContext(CaseRemindersFilterContext);
  const isMobile = width < middleScreenWidth;

  const createReminders = () => {
    openModal(CreateCaseRemindersModal, {
      payload: {
        caseId
      },

      onModalResolved: () => {
        applyFilter({ _updatedAt: moment().unix() });
      }
    });
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  useEffect(() => {
    if (width < largeScreenWidth && viewVariant === viewVariantsMap.list) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width ]);

  return (
    <PageHeader>
      {!isMobile &&
        <PageHeaderTitle>
          Reminders
        </PageHeaderTitle>
      }

      <PageHeaderActions startPosition>
        <Fab
          variant="extended"
          color="primary"
          size={isMobile ? 'small' : 'medium'}
          startIcon={<AddIcon />}
          onClick={createReminders}
        >
          {isMobile ? 'Add' : 'Add reminders'}
        </Fab>
      </PageHeaderActions>

      <PageHeaderActions disableSpacing>
        {isMobile ? filterOpenButton : (
          <>
            {width > largeScreenWidth && (
              <Tooltip title="Groups view">
                <IconButton
                  color={viewVariant === viewVariantsMap.groups ? 'primary' : 'default'}
                  onClick={toggleViewVariant(viewVariantsMap.groups)}
                >
                  <HorizontalSplitIcon />
                </IconButton>
              </Tooltip>
            )}

            {width > largeScreenWidth && (
              <Tooltip title="List view">
                <IconButton
                  color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                  onClick={toggleViewVariant(viewVariantsMap.list)}
                >
                  <ViewListIcon/>
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </PageHeaderActions>
    </PageHeader>
  );
};
