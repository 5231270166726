export const payPeriodsMap = {
  weekly: 'weekly',
  biWeekly: 'bi-weekly',
  semiMonthly: 'semi-monthly',
  monthly: 'monthly'
};

export const payPeriodsLabelsMap = {
  [payPeriodsMap.weekly]: 'Weekly',
  [payPeriodsMap.biWeekly]: 'Bi-Weekly',
  [payPeriodsMap.semiMonthly]: 'Semi-Monthly',
  [payPeriodsMap.monthly]: 'Monthly'
};

export const payPeriodsOptions = [
  {
    value: payPeriodsMap.weekly,
    label: payPeriodsLabelsMap[payPeriodsMap.weekly]
  },
  {
    value:  payPeriodsMap.biWeekly,
    label: payPeriodsLabelsMap[payPeriodsMap.biWeekly]
  },
  {
    value:  payPeriodsMap.semiMonthly,
    label: payPeriodsLabelsMap[payPeriodsMap.semiMonthly]
  },
  {
    value:  payPeriodsMap.monthly,
    label: payPeriodsLabelsMap[payPeriodsMap.monthly]
  }
];
