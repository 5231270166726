import { api } from '../api';

export const fetchTracks = (config = {}) => {
  return api.get('/time-tracks', config).then(({ data }) => data);
};

export const fetchAdminTracks = (config = {}) => {
  return api.get('/admin/time-tracks', config).then(({ data }) => data);
};

export const createTrack = (data) => {
  return api.post('/time-tracks', data).then(({ data: { data } }) => data);
};

export const createAdminTrack = (data) => {
  return api.post('/admin/time-tracks', data).then(({ data: { data } }) => data);
};

export const updateTrack = (track) => {
  return api.put(`/time-tracks/${track.id}`, track).then(({ data }) => data);
};

export const deleteTrack = (id) => {
  return api.delete(`/time-tracks/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
