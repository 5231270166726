import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Dialog, SvgIcon, Typography } from '@material-ui/core';
import { mdiFileAlertOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as rulesApi from '../../../../api/emails-rules';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../Modal';
import { FormStep, validationSchema } from '../../../emails';
import { TextField } from '../../../FormField';
import { Loader } from '../../../Loader';
import { Button } from '../../../Button';
import {
  conditionOptions,
  actionOptions
} from '../../options';
import { BodyActions } from './BodyActions';
import { BodyConditions } from './BodyConditions';

export const CreateRuleFormModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { initialRule = {} }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateRule = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return rulesApi.createRuleTemplate({
      ...values,

      conditions: values?.conditions?.map((condition) => ({
        key: condition?.key?.value,
        values: condition?.values?.map((value) => value)
      })),

      actions: values?.actions?.map((action) => ({
        key: action?.key?.value,
        value: action?.value
      }))
    }).then((newRule) => {
      enqueueSnackbar('Email rule created successfully', { variant: 'success' });
      handleModalResolve(newRule);
    }).catch(() => {
      enqueueSnackbar('Email rule not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        model_type: initialRule?.model_type,
        model_id: initialRule?.model_id,
        name: null,
        conditions: [ { key: conditionOptions[0], values: [] } ],
        actions: !!initialRule?.actions?.length ? initialRule.actions : [ { key: actionOptions[0], value: null } ]
      }}
      validationSchema={validationSchema}
      onSubmit={handleCreateRule}
    >
      {({ errors, touched, isSubmitting, handleSubmit }) => (
        <Dialog fullWidth maxWidth="lg" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiFileAlertOutline} /></SvgIcon>} onClose={handleModalReject}>
              Create a Rule2
            </ModalHeader>

            <ModalBody>
              <FormStep
                withoutTitle
                checked={(touched.name && !errors.name) || (initialRule && !errors.name)}
                step={1}
              >
                <Box width="50%" mb={4} pr={2.5}>
                  <TextField
                    required
                    name="name"
                    label="Name your rule"
                    placeholder="Enter rule name..."
                  />
                </Box>
              </FormStep>

              <FormStep
                checked={(touched.conditions && !errors.conditions) || (initialRule && !errors.conditions)}
                step={2}
              >
                <Typography variant="h4" gutterBottom>Add a condition</Typography>

                <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight={50}>
                  <BodyConditions />
                </Box>
              </FormStep>

              <FormStep
                withoutLine
                checked={(touched.actions && !errors.actions) || (initialRule && !errors.actions)}
                step={3}
              >
                <Typography variant="h4" gutterBottom>Add an action</Typography>

                <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight={50}>
                  <BodyActions />
                </Box>
              </FormStep>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
