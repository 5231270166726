import { useCallback, useState } from 'react';
import { Popper } from '../../../../../../../components/Popper';
import { Menu } from './Menu';

export const useMessageMenu = ({
  isCurrentUser = false,
  message,
  onMenuToggled = () => {}
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);

  const openMenu = useCallback((event) => {
    const x = event.clientX;
    const y = event.clientY;
    const size = 1;

    setAnchorEl({
      clientWidth: size,
      clientHeight: size,
      getBoundingClientRect: () => ({
        x: x,
        y: y,
        width: size,
        height: size,
        top: y,
        left: x,
        bottom: y + size,
        right: x + size
      })
    });

    setIsOpen(true);
    onMenuToggled(true);
  }, []);

  const handleMenuClosed = () => {
    setIsOpen(false);
    setAnchorEl(null);
    onMenuToggled(false);
  };

  return {
    openMenu,
    Menu: () => (
      <Popper
        isOpen={isOpen}
        anchorEl={anchorEl}
        placement={isCurrentUser ? 'left' : 'right'}
        onClosed={handleMenuClosed}
      >
        {({ handleClose }) => (
          <Menu
            isCurrentUser={isCurrentUser}
            message={message}
            onClick={handleClose}
          />
        )}
      </Popper>
    )
  };
};
