import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { makeStyles, Grid, Paper, Typography, Box, FormControlLabel } from '@material-ui/core';
import {
  Checkbox,
  TextField,
  CompaniesAutocomplete,
  CodesModifierSelect,
  CurrencyField
} from '../../../../../../../../components/FormField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Schedule = ({ setIsEmptySchedule }) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const toggleAllPayers = (event) => {
    event.target.checked && setFieldValue('schedule.payer_id', '');
  };

  useEffect(() => {
    const isEmpty = Object.values(values.schedule)
      .filter((item) => item !== null && item !== false)?.length === 0;

    setIsEmptySchedule(!!isEmpty);
  }, [ values.schedule ]);

  return (
    <Paper variant="outlined" square className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">
            Fee schedule
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <CompaniesAutocomplete
            required={!values?.schedule?.is_all_payers}
            disabled={values?.schedule?.is_all_payers}
            name="schedule.payer_id"
            label={values?.schedule?.is_all_payers ? 'All payers selected' : 'Payer'}
          />
        </Grid>

        <Grid item xs={3}>
          <Box pt={1.5}>
            <FormControlLabel
              label={<Typography>All payers</Typography>}
              control={
                <Checkbox
                  name="schedule.is_all_payers"
                  color="primary"
                  onChange={toggleAllPayers}
                />
              }
            />
          </Box>
        </Grid>

        <Grid item sm={6} xs={12}>
          <CurrencyField
            required
            name="schedule.charge_amount"
            label="Charge amount"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            required
            name="schedule.default_units"
            label="Default units"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <CurrencyField
            required
            name="schedule.expected_amount"
            label="Expected amount"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <CodesModifierSelect
            TextFieldProps={{ required: true }}
            name="schedule.modifier_id"
            label="Modifier"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            required
            name="schedule.work_rvu"
            label="Work RVU"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            required
            name="schedule.moldx_code"
            label="MolDX Code"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            required
            name="schedule.dx_code"
            label="DX Code"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            required
            name="schedule.total_rvu"
            label="Total RVU"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
