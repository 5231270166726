export const FETCH_WORK_SESSIONS_REQUEST = 'FETCH_WORK_SESSIONS_REQUEST';
export const FETCH_WORK_SESSIONS_SUCCESS = 'FETCH_WORK_SESSIONS_SUCCESS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const RESET_WORK_SESSIONS = 'RESET_WORK_SESSIONS';
export const ADD_WORK_SESSION = 'ADD_WORK_SESSION';
export const UPDATE_WORK_SESSION = 'UPDATE_WORK_SESSION';
export const DELETE_WORK_SESSION = 'DELETE_WORK_SESSION';
export const DELETE_TRACK = 'DELETE_TRACK';
export const ADD_TRACK = 'ADD_TRACK';
export const ADD_WORK_SESSIONS_TO_SELECTED = 'ADD_WORK_SESSIONS_TO_SELECTED';
export const DELETE_WORK_SESSIONS_FROM_SELECTED = 'DELETE_WORK_SESSIONS_FROM_SELECTED';
export const UPDATE_TRACK = 'UPDATE_TRACK';
