import { useContext } from 'react';
import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../ListRow';
import { CallReportsContext } from '../../../CallReportsProvider';
import { columnsWidths } from '../listConfig';

export const TableHeader = () => {
  const callReportsContext = useContext(CallReportsContext);
  const {
    disableCauser,
    selectedCallReportsIDs,
    allCallReportsIsSelected,
    toggleAllCallReportsSelected
  } = callReportsContext || {};

  return (
    <Paper elevation={0}>
      <ListRow header px={2}>
        {!!callReportsContext &&
          <ListRowCheckbox
            onClick={toggleAllCallReportsSelected}
            checked={allCallReportsIsSelected()}
            indeterminate={!!selectedCallReportsIDs.length && !allCallReportsIsSelected()}
          />
        }

        <ListRowCell grow flexBasis={columnsWidths.from}>
          From
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.to}>
          To
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.type}>
          Type
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.disposition}>
          Disposition
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.startEndDate}>
          Start / End Date Time
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.duration}>
          Duration
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.file}>
          File
        </ListRowCell>

        {!disableCauser && (
          <ListRowCell flexBasis={columnsWidths.causer}>
            Case/Patient
          </ListRowCell>
        )}

        {!!callReportsContext &&
          <ListRowCell flexBasis={columnsWidths.actions} />
        }
      </ListRow>
    </Paper>
  );
};
