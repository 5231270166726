import { replaceNbspToBr } from '../../helpers/replaceNbspToBr';
import { api } from '../api';

const transformEventsConfig = (config) => {
  if (config?.params?.tasks?.length) {
    return {
      ...config,

      params: {
        ...config?.params,

        cases: null
      }
    };
  }

  return config;
};

const transformEvent = (event) => {
  return {
    ...event,

    description: replaceNbspToBr(event.description),
    creator: event?.users?.find(({ schedule_event: { is_creator } }) => is_creator)
  };
};

export const fetchCalendarEvents = (config) => {
  return api.get('/calendar', transformEventsConfig(config))
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchCalendar = (config) => {
  return fetchCalendarEvents(config).then((data) => {
    return {
      daysData: data,
      events: Object.values(data).reduce((result, { events }) => {
        return result.concat(events.map(transformEvent));
      }, [])
    };
  }).catch((error) => { throw error; });
};

export const fetchEvents = (config) => {
  return api.get('/schedule-events', transformEventsConfig(config))
    .then(({ data: { data, pagination } }) => ({
      data: data.map(transformEvent),
      pagination
    }))
    .catch((error) => { throw error; });
};

export const fetchEvent = (id, config) => {
  return api.get(`/schedule-events/${id}`, config).then(({ data }) => {
    return transformEvent(data);
  }).catch((error) => {
    throw error;
  });
};

export const createEvent = (data) => {
  return api.post(data.recurring_rule ? '/schedule-recurring-templates' : '/schedule-events', data)
    .then(({ data: { data } }) => transformEvent(data))
    .catch((error) => { throw error; });
};

export const updateEvent = ({ id, ...data }) => {
  return api.put(`/schedule-events/${id}`, { ...data, email_notification: true })
    .then(({ data: { data } }) => transformEvent(data))
    .catch((error) => { throw error; });
};

export const changeEventOrganiser = (eventId, data) => {
  return api.put(`/schedule-events/${eventId}/owner`, data)
    .then(({ data: { data } }) => transformEvent(data))
    .catch((error) => { throw error; });
};

export const updateRecurringTemplate = ({ recurring_template_id, ...data }) => {
  return api.put(`/schedule-recurring-templates/${recurring_template_id}`, { ...data, email_notification: true })
    .then(({ data: { data } }) => transformEvent(data))
    .catch((error) => { throw error; });
};

export const confirmEvent = (event) => {
  const path = event.recurring_rule ? 'schedule-recurring-templates' : 'schedule-events';
  const id = event.recurring_template_id || event.id;

  return api.post(`/${path}/${id}/confirmation`, event)
    .then(({ data: { data } }) => transformEvent(data))
    .catch((error) => { throw error; });
};

export const deleteEvent = (id) => {
  return api.delete(`/schedule-events/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteRecurringTemplate = (id) => {
  return api.delete(`/schedule-recurring-templates/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchEventData = (token) => {
  return api.get(`/calendar/confirmation/${token}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const confirmEventByToken = (token, data) => {
  return api.post(`/calendar/${token}`, data)
    .then((data) => data)
    .catch((error) => { throw error; });
};
