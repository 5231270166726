import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { useField } from 'formik';
import { rrulestr } from 'rrule';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useModal } from '../ModalsProvider';
import { CustomRecurrenceModal } from './CustomRecurrenceModal';
import { selectOptions, recurrenceVariantsMap } from './recurrenceVariantsMap';

const invalidRecurrenceVariants = [
  recurrenceVariantsMap.noRepeat,
  recurrenceVariantsMap.customRecurrence
];

export const RecurrenceSelect = ({ label, name, margin }) => {
  const { openModal } = useModal();
  const [ { value: formikValue }, { initialValue }, { setValue: setFormikValue } ] = useField(name);
  const [ customRule, setCustomRule ] = useState(null);
  const [ value, setValue ] = useState(() => {
    if (!initialValue) {
      return recurrenceVariantsMap.noRepeat;
    }

    const initialOption = selectOptions.find(({ value }) => value === initialValue);

    if (initialOption) {
      return initialOption.value;
    }

    setCustomRule({
      label: capitalize(rrulestr(initialValue).toText()),
      value: initialValue
    });

    return initialValue;
  });

  const handleChange = (event, { props: { value } }) => {
    if (value === recurrenceVariantsMap.customRecurrence) {
      openModal(CustomRecurrenceModal, {
        onModalResolved: (rule) => {
          setCustomRule(rule);
          setValue(rule.value);
          setFormikValue(rule.value);
        }
      });
    } else {
      setValue(value);
      setFormikValue(value === recurrenceVariantsMap.noRepeat ? null : value);
    }
  };

  useEffect(() => {
    if (formikValue && formikValue !== value) {
      const isInvalidRecurrenceVariant = invalidRecurrenceVariants.includes(formikValue);
      const label = isInvalidRecurrenceVariant
        ? formikValue
        : capitalize(rrulestr(formikValue).toText());

      if (!isInvalidRecurrenceVariant) {
        setCustomRule({
          label,
          value: formikValue
        });
      }

      setValue(formikValue);
    }
  }, [ formikValue, value ]);

  return (
    <FormControl fullWidth margin={margin}>
      <InputLabel>{label}</InputLabel>

      <Select
        name={name}
        value={value}
        onChange={handleChange}
      >
        {selectOptions.map(({ title, value }) => (
          <MenuItem key={value} value={value}>{title}</MenuItem>
        ))}

        {customRule &&
          <MenuItem value={customRule.value}>{customRule.label}</MenuItem>
        }

        <MenuItem value={recurrenceVariantsMap.customRecurrence}>Custom recurrence</MenuItem>
      </Select>
    </FormControl>
  );
};
