import * as types from './types';
import { createHookReducer } from '../../../../../helpers/createHookReducer';

export const reducer = createHookReducer({
  [types.FETCH_FILES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_FILES_SUCCESS]: (state, newFiles) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      files: newFiles
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.DELETE_FILES]: ({ files, selectedFiles, ...state }, filesForDelete) => {
    return {
      ...state,

      files: files.filter((file) => !filesForDelete.find((name) => name === file.name)),
      selectedFiles: selectedFiles.filter((file) => !filesForDelete.find((name) => name === file))
    };
  },

  [types.ADD_FILES_TO_SELECTED]: ({ selectedFiles, ...state }, files) => {
    return {
      ...state,

      selectedFiles: [ ...new Set(selectedFiles.concat(files)) ]
    };
  },

  [types.DELETE_FILES_FROM_SELECTED]: ({ selectedFiles, ...state }, files) => {
    return {
      ...state,

      selectedFiles: selectedFiles.filter((file) => !files.includes(file))
    };
  }
});
