import { useFormikContext } from 'formik';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { MedicalFormsSelect } from '../../../../../../components/FormField';
import { FormInfo } from './FormInfo';

export const MedicalFormInfo = () => {
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangeMedicalForm = (option) => {
    setFieldValue('parent', option?.data || null);
    setFieldValue(
      'treating_physician_id',
      option?.data
        ? option?.data?.treating_physician
        : null
    );
  };

  return (
    <Box p={isSmallScreen ? 2 : 4} pb={0}>
      <Typography variant="h4" color="secondary">
        Form Info
      </Typography>

      <Grid container spacing={2} component={Box} pt={2}>
        <Grid item md={6} xs={11}>
          <MedicalFormsSelect
            params={{ medical_id: values?.medical_id }}
            onChange={handleChangeMedicalForm}
          />
        </Grid>
      </Grid>

      <FormInfo form={values.parent || null} />
    </Box>
  );
};
