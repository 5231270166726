import { useFormikContext } from 'formik';
import {
  makeStyles,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import {
  Checkbox,
  RadioGroup,
  TextField
} from '../../../FormField';
import { getTextColor } from '../../getTextColor';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const BackPain = () => {
  const classes = useStyles();
  const { errors } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Typography className={classes.formTitle}>
        IF PAIN IN LOW BACK
      </Typography>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_low_back?.how_often_your_pain)}
        >
          1. How often is your pain? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_low_back.how_often_your_pain">
            <FormControlLabel
              value="occasional"
              label={<Typography>Occasional</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="frequent"
              label={<Typography>Frequent</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="constant"
              label={<Typography>Constant</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_low_back?.what_severity_your_pain)}
        >
          2. What is the severity of your pain? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup
            row
            name="forms.pain_in_low_back.what_severity_your_pain"
          >
            <FormControlLabel
              value="mild"
              label={<Typography>Mild</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="moderate"
              label={<Typography>Moderate</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="severe"
              label={<Typography>Severe</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_low_back?.pain_radiate_other_parts_body)}
        >
          3. Does your pain radiate to other parts of your body? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup name="forms.pain_in_low_back.pain_radiate_other_parts_body">
            <FormControlLabel
              value="1"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="0"
              label={<Typography>No</Typography>}
              control={<Radio color="primary"/>}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography className={classes.title}>
          4. If yes, how often?
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_low_back.if_yes_how_often">
            <FormControlLabel
              value="occasional"
              label={<Typography>Occasional</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="frequent"
              label={<Typography>Frequent</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="constant"
              label={<Typography>Constant</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography className={classes.title}>
          5. Where does your pain radiate to?
        </Typography>

        <div className={classes.contentInfoPain}>
          <FormControlLabel
            label={<Typography>Thigh</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_low_back.where_pain_radiate_too.thigh"/>}
          />

          <FormControlLabel
            label={<Typography>Leg</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_low_back.where_pain_radiate_too.leg"/>}
          />

          <FormControlLabel
            label={<Typography>Foot</Typography>}
            control={<Checkbox color="primary" name="forms.pain_in_low_back.where_pain_radiate_too.foot"/>}
          />
        </div>
      </div>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_low_back?.which_side_pain_radiate_too)}
        >
          6. Which side of body does the pain radiate to? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_low_back.which_side_pain_radiate_too">
            <FormControlLabel
              value="left"
              label={<Typography>Left</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="right"
              label={<Typography>Right</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="bilaterally"
              label={<Typography>Bilaterally</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography className={classes.title}>
          7. Do you experience any of the following? If yes, please explain. (location and frequency)
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Weakness</Typography>}
                control={
                  <Checkbox
                    color="primary"
                    name="forms.pain_in_low_back.experience_following.weakness.value"
                  />
                }
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_low_back.experience_following.weakness.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>

            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Muscle Spasms</Typography>}
                control={
                  <Checkbox
                    color="primary"
                    name="forms.pain_in_low_back.experience_following.muscle_spasms.value"
                  />
                }
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_low_back.experience_following.muscle_spasms.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>

            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Stiffness</Typography>}
                control={
                  <Checkbox
                    color="primary"
                    name="forms.pain_in_low_back.experience_following.stiffness.value"
                  />
                }
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_low_back.experience_following.stiffness.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Limping</Typography>}
                control={
                  <Checkbox
                    color="primary"
                    name="forms.pain_in_low_back.experience_following.limping.value"
                  />
                }
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_low_back.experience_following.limping.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>

            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Numbness</Typography>}
                control={
                  <Checkbox
                    color="primary"
                    name="forms.pain_in_low_back.experience_following.numbness.value"/>
                }
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_low_back.experience_following.numbness.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>

            <Box display="flex" alignItems="flex-end" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <FormControlLabel
                label={<Typography>Tingling</Typography>}
                control={
                  <Checkbox
                    color="primary"
                    name="forms.pain_in_low_back.experience_following.tingling.value"
                  />
                }
                className={classes.experienceControl}
              />

              <TextField
                name="forms.pain_in_low_back.experience_following.tingling.description"
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                margin="normal"
                className={classes.experienceTextField}
              />
            </Box>
          </Grid>
        </Grid>
      </div>

      <div className={classes.content}>
        <Typography
          className={classes.title}
          color={getTextColor(errors?.forms?.pain_in_low_back?.avoided_strenuous_lifting)}
        >
          8. Have you avoided strenuous lifting, carrying, pushing, pulling,
          stooping and bending because of your injury? *
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup row name="forms.pain_in_low_back.avoided_strenuous_lifting">
            <FormControlLabel
              value="1"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="0"
              label={<Typography>No</Typography>}
              control={<Radio color="primary"/>}
            />
          </RadioGroup>
        </div>
      </div>

      <div className={classes.content}>
        <Typography className={classes.title}>
          9. How long can you do the following until the pain interferes?
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
          pl={2}
        >
          <div className={classes.timeInfo}>
            <Typography>Sit</Typography>

            <TextField
              type="number"
              placeholder="30"
              name="forms.pain_in_low_back.following_until_pain_interferes.sit"
              className={classes.timeField}
            />

            <Typography color="textSecondary">minutes.</Typography>
          </div>

          <div className={classes.timeInfo}>
            <Typography>Stand</Typography>

            <TextField
              type="number"
              placeholder="30"
              name="forms.pain_in_low_back.following_until_pain_interferes.stand"
              className={classes.timeField}
            />

            <Typography color="textSecondary">minutes.</Typography>
          </div>

          <div className={classes.timeInfo}>
            <Typography>Walk</Typography>

            <TextField
              type="number"
              placeholder="30"
              name="forms.pain_in_low_back.following_until_pain_interferes.walk"
              className={classes.timeField}
            />

            <Typography color="textSecondary">minutes.</Typography>
          </div>
        </Box>
      </div>
    </>
  );
};
