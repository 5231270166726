import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { UserLink } from '../../../../../../../../components/UserLink';
import { orderByOptions } from '../../../../../../../../components/claims';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../../components/Loader';
import { filterFieldsMap } from '../../filterFieldsMap';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter,
  hiddenColumns = []
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.claimNo}>
        {params?.claim_number || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.providers}>
        <Box display="flex" flexDirection="column" width="100%">
          {!params.providers?.length ? '-' : params?.providers?.map((user) => (
            <Box display="flex" width="100%" alignItems="center" key={user?.id}>
              <UserLink
                noWrap
                size="sm"
                key={user.id}
                user={user}
              />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      {!hiddenColumns.includes(filterFieldsMap.patients) &&
        <ListRowCell flexBasis={columnsWidths.patients}>
          <Box display="flex" flexDirection="column" width="100%">
            {!params.patients?.length ? '-' : params?.patients?.map((user) => (
              <Box display="flex" width="100%" alignItems="center" key={user?.id}>
                <UserLink
                  noWrap
                  size="sm"
                  key={user.id}
                  user={user}
                />
              </Box>
            ))}
          </Box>
        </ListRowCell>
      }

      {!hiddenColumns.includes(filterFieldsMap.adjusters) &&
        <ListRowCell flexBasis={columnsWidths.adjusters}>
          <Box display="flex" flexDirection="column" width="100%">
            {!params.adjusters?.length ? '-' : params?.adjusters?.map((user) => (
              <Box display="flex" width="100%" alignItems="center" key={user?.id}>
                <UserLink
                  noWrap
                  size="sm"
                  key={user.id}
                  user={user}
                />
              </Box>
            ))}
          </Box>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.dosFrom}>
        {params?.appointment_from ? moment.unix(params.appointment_from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dosTo}>
        {params?.appointment_to ? moment.unix(params.appointment_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.offices}>
        <Box display="flex" flexDirection="column" width="100%">
          {!params.offices?.length ? '-' : params?.offices?.map((office) => (
            <Typography noWrap key={office?.id} title={office?.name}>
              {office?.name}
            </Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.claimStatus}>
        {!params.statuses ? '-' : params?.statuses?.map(({ label }) => label).join(', ')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.orderBy}>
        {orderByOptions.find(({ value }) => value === params?.order_by)?.label || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
