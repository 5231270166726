import { Yup } from '../../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  to_type: Yup.mixed().nullable(),
  from_type: Yup.mixed().nullable(),
  to_id: Yup.mixed().nullable(),
  from_id: Yup.mixed().nullable(),
  user_type: Yup.mixed().nullable(),
  type: Yup.mixed().nullable(),
  due_date: Yup.date().format('YYYY-MM-DD').nullable(),
  send_date: Yup.date().format('YYYY-MM-DD').nullable(),
  received_date: Yup.date().format('YYYY-MM-DD').nullable(),
  extension: Yup.string().nullable()
});
