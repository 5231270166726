import { useContext } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { Date } from '../../../../../../../../components/Date';
import { Divider } from '../../../../../../../../components/Divider';
import { Label } from '../../../../../../../../components/Label';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { Popper } from '../../../../../../../../components/Popper';
import { ReadonlyCheckbox } from '../../../../../../../../components/ReadonlyCheckbox';
import { UserLink } from '../../../../../../../../components/UserLink';
import { preventDefault, stopPropagation } from '../../../../../../../../helpers/dom';
import {
  caseInsuranceLabelsTypesMap,
  InsurancesContext,
  insuranceStatusMap,
  insuranceTypes
} from '../../../../../insurance';
import { Menu } from '../../../../../insurance/InsuranceInfo/Menu';
import { ViewInsuranceInfoModal } from '../../../../../insurance/ViewInsuranceInfoModal';

export const CaseInsuranceInfo = ({ insurance, withMenu, onCaseItemUpdate = () => {} }) => {
  const theme = useTheme();
  const { openModal } = useModal();
  const { updateInsurance, deleteInsurance  } = useContext(InsurancesContext);
  const isPlaintiff = insurance?.type_insurance === insuranceTypes.plaintiff;

  const openPreview = () => {
    openModal(ViewInsuranceInfoModal, {
      payload: {
        user: insurance?.case_user,
        insurance,
        onEdit: updateInsurance,
        onDelete: deleteInsurance,
        onCaseItemUpdate
      }
    });
  };

  return (
    <Box minHeight={385} onClick={preventDefault(openPreview)}>
      <Box p={1}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={10}>
            <Label
              color={theme.palette?.slategray}
              label={insuranceStatusMap[insurance?.status_type] }
            />
          </Grid>

          {withMenu ?
            <Grid item>
              <Popper
                placement="left-start"
                anchorRenderer={({ anchorRef, handleToggle }) => (
                  <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                    <MoreVertIcon />
                  </IconButton>
                )}
              >
                {({ handleClose }) => (
                  <Menu
                    insurance={insurance}
                    onClose={handleClose}
                    onCaseItemUpdate={onCaseItemUpdate}
                  />
                )}
              </Popper>
            </Grid>
            :
            <Grid item xs={2} />
          }

          <Grid item xs={6}>
            <Typography noWrap variant="h5" title={insurance?.type_insurance}>
              {caseInsuranceLabelsTypesMap[insurance?.type_insurance]}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <UserLink
              parentProps={{ mb: 1 }}
              size="sm"
              variant="h5"
              user={insurance?.case_user}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography noWrap variant="h5" title={insurance?.type_insurance}>
              Insur. Company
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <CompanyLink
              size="sm"
              company={insurance?.insurance_company}
            />
          </Grid>

        </Grid>
      </Box>

      <Divider gutter={0.5} />

      <Box p={1}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={6}>
            <Typography noWrap color="textSecondary">
              Adj. (BI)
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <UserLink
              size="sm"
              user={insurance?.attorney}
            />
          </Grid>

          {isPlaintiff && (
            <>
              <Grid item xs={6}>
                <Typography noWrap color="textSecondary">
                  Adj. (PD)
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <UserLink size="sm" user={insurance?.attorney_adj} />
              </Grid>
            </>
          )}


          <Grid item xs={6}>
            <Typography noWrap color="textSecondary">
              Adj. (MP)
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <UserLink size="sm" user={isPlaintiff ? insurance?.provider_adj : insurance?.provider} />
          </Grid>

          {isPlaintiff && (
            <>
              <Grid item xs={6}>
                <Typography noWrap color="textSecondary">
                  Adj. (UM)
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <UserLink size="sm" user={insurance?.provider} />
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <Typography noWrap color="textSecondary">
              Policy #
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography noWrap>
              {insurance?.policy_number || 'No info.'}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography noWrap color="textSecondary">
              Claim #
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography noWrap>
              {insurance?.claim_number || 'No info.'}
            </Typography>
          </Grid>

          {isPlaintiff ? (
            <>
              <Grid item xs={6}>
                <Typography noWrap color="textSecondary">
                  Medpay Requested
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <ReadonlyCheckbox
                  strict
                  checked={insurance?.medpay_requested}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <Typography noWrap color="textSecondary">
                  Policy Limits
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <CurrencyFormat value={insurance?.policy_limit} />
              </Grid>

              <Grid item xs={6}>
                <Typography noWrap color="textSecondary">
                  Refused Disclosure
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <ReadonlyCheckbox
                  strict
                  checked={insurance?.refused_dislosure}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider gutter={0.5} type="dashed" />
          </Grid>

          {isPlaintiff ? (
            <>
              <Grid item xs={6}>
                <Typography noWrap variant="h5">
                  Medpay Received
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Date date={insurance?.medpay_received_date} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <Typography noWrap variant="h5">
                  Request Limits
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Date date={insurance?.requested_limit_date} />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
