import { useContext } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CalendarIcon from '@material-ui/icons/Event';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../helpers/dom';
import { generateAddress } from '../../../../../../utils/generateAddress';
import { hasRole } from '../../../../../../utils/hasRole';
import { medicationsListToString } from '../../../../../../utils/medicationsListToString';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Popper } from '../../../../../../components/Popper';
import { PreviewPrescriptionModal } from '../../PreviewPrescriptionModal';
import { PrescriptionsContext } from '../../PrescriptionsProvider';
import { PrescriptionsMenu } from '../../PrescriptionsMenu';
import { TooltipInnerTitle } from '../../TooltipInnerTitle';
import { minRowHeight, columnsWidths, widthBreakpointMedium, widthBreakpointSmall } from '../List';

export const Row = ({ width, prescription }) => {
  const { openModal } = useModal();
  const {
    selectedIDs,
    toggleItemSelection,
    updatePrescription,
    deletePrescription
  } = useContext(PrescriptionsContext);
  const isSelected = selectedIDs.indexOf(prescription.id) !== -1;
  const medications = medicationsListToString(prescription?.medications);

  const handleItemSelection = () => {
    toggleItemSelection(prescription.id);
  };

  const openPreview = () => {
    openModal(PreviewPrescriptionModal, {
      payload: {
        prescription,
        onUpdate: updatePrescription,
        onDelete: deletePrescription
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <ListRow
      button
      minHeight={minRowHeight}
      onClick={stopPropagation(openPreview)}
    >
      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />
      }

      <ListRowCell grow={width < widthBreakpointMedium} flexBasis={columnsWidths.name}>
        <Typography noWrap>{prescription.prescription}</Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        <Box mr={1}>
          <CalendarIcon fontSize="small" />
        </Box>

        {moment.unix(prescription.prescribed_at).format('L')}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.ndc}>
        <Tooltip
          isExistTooltip={!!prescription?.medications?.length}
          title={prescription?.medications?.map((item, i) => (
            <TooltipInnerTitle
              key={item.ndc_code_id}
              index={i}
              item={item}
              prescription={prescription}
            />
          ))}
        >
          <Typography noWrap>{medications || '-'}</Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.approved}>
        <UserLink
          noWrap
          size="md"
          user={prescription?.approved}
          onClick={stopPropagation(openUserPreview(prescription?.approved?.id))}
        />
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.office}>
          <Tooltip
            isExistTooltip={!!prescription?.office}
            title={prescription?.office?.full_address || generateAddress(prescription?.office)}
          >
            <OfficeLink office={prescription?.office} />
          </Tooltip>
        </ListRowCell>
      }

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <PrescriptionsMenu prescription={prescription} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
