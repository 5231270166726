import { useSelector } from 'react-redux';
import { LayoutContextProvider } from '../../../../../components/LayoutContext';
import { Page, PageBody } from '../../../../../components/Page';
import { TimeReports as TimeReportsCommon } from '../../../../../components/time-reports/TimeReports';
import { filterFieldsMap } from '../../../../../components/time-reports/TimeReports/FiltersBar';
import { columnsMap } from '../../../../../components/time-reports/TimeReports/List/listConfig';
import { TimeReportsProvider } from '../../../../../components/time-reports/TimeReportsProvider';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { Header } from './Header';

const hiddenColumns = [
  columnsMap.user
];

const hiddenFilterFields = [
  filterFieldsMap.user,
  filterFieldsMap.company
];

export const TimeReports = ({ filterKey }) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  return (
    <TimeReportsProvider filter={{ user_id: currentUser.id }}>
      <LayoutContextProvider>
        <Page>
          <Header filterOpenButton={filterOpenButton} />

          <PageBody disablePaddings disableScrollbars fullHeight>
            <TimeReportsCommon
              filterKey={filterKey}
              hiddenColumns={hiddenColumns}
              hiddenFilterFields={hiddenFilterFields}
              FilterBarWrapper={FilterBarWrapper}
              filterBarWrapperProps={filterBarWrapperProps}
              ScrollWrapper={ScrollWrapper}
              scrollWrapperProps={scrollWrapperProps}
            />
          </PageBody>
        </Page>
      </LayoutContextProvider>
    </TimeReportsProvider>
  );
};
