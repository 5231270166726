import { Link, Typography } from '@material-ui/core';
import { useModal } from '../../ModalsProvider';
import { ViewInsuranceInfoModal } from '../../../app/Dashboard/ProfilePage/insurance/ViewInsuranceInfoModal';

export const Insurance = ({ before, after, fieldLabel }) => {
  const { openModal } = useModal();

  const openPreview = (id) => () => {
    openModal(ViewInsuranceInfoModal, {
      payload: {
        insuranceId: id
      }
    });
  };

  if (!before && after) {
    return (
      <>
        Added&nbsp;<Typography variant="subtitle1">{fieldLabel || 'Insurance'}</Typography>
        &nbsp;
        <Link onClick={openPreview(after.id)}>{after?.type_insurance || after?.id}</Link>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;<Typography variant="subtitle1">{fieldLabel || 'Insurance'}</Typography>
        &nbsp;
        <Link onClick={openPreview(before.id)}>{before.id}</Link>
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;<Typography variant="subtitle1">{fieldLabel || 'Insurance'}</Typography>
        &nbsp;from&nbsp;
        <Link onClick={openPreview(before.id)}>{before?.type_insurance || before?.id}</Link>
        &nbsp;to&nbsp;
        <Link onClick={openPreview(after.id)}>{after?.type_insurance || after?.id}</Link>
      </>
    );
  } else {
    return (
      <>
        Empty&nbsp;
        <Typography variant="subtitle1">{fieldLabel || 'Insurance'}</Typography>
      </>
    );
  }
};
