import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { NotificationsContent } from '../../PatientsPage/NotificationsPage/NotificationsContent';

export const NotificationsPage = () => {
  const currentUser = useSelector(({ profile }) => profile.user);

  return (
    <Box width="100%">
      <NotificationsContent userID={currentUser.id} />
    </Box>
  );
};
