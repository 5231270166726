import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, FormControlLabel, SvgIcon, Switch } from '@material-ui/core';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { MedicalReportContent } from '../../../../../../../../../components/Widgets';
import { WidgetsActionButtons } from '../../../../../../../../../components/WidgetsActionButtons';
import { ContentCard } from '../../../../../../../../../components/ContentCard';
import { MedicalProfileContext } from '../../../../../../../ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider';
import { MedicalFormsProvider } from '../../../../../../../ProfilePage/MedicalInfo/MedicalForms/MedicalFormsProvider';
import { UserContext } from '../../../../../UserProvider';

export const MedicalReport = ({
  page,
  height = '100%',
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const params = useParams();
  const userContext = useContext(UserContext);
  const { medicalInfoPathname } = useContext(MedicalProfileContext);
  const [ isOpenSwitches, setIsOpenSwitches ] = useState(false);
  const initialState = {
    filter: {
      medical_id: userContext?.user?.medical?.id
    },
    medicalInfoPathname
  };

  const handleSwitchChange = (event) => {
    setIsOpenSwitches(event.target.checked);
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      title="Medical Report"
      leftActions={[
        <Box mr={1}>
          <SvgIcon fontSize="small">
            <Icon path={mdiTextBoxCheckOutline} />
          </SvgIcon>
        </Box>
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      endIcon={
        <FormControlLabel
          label="Open all"
          control={
            <Switch
              color="primary"
              checked={isOpenSwitches}
              onChange={handleSwitchChange}
            />
          }
        />
      }
      {...props}
    >
      <MedicalFormsProvider initialState={initialState}>
        <Box display="flex" flexDirection="column" height={height} overflow="hidden">
          <MedicalReportContent
            isOpenSwitches={isOpenSwitches}
            userID={userContext ? userContext?.user?.id : params?.patientID}
            toggleSwitches={setIsOpenSwitches}
          />
        </Box>
      </MedicalFormsProvider>
    </ContentCard>
  );
};
