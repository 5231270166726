import { useEffect, useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { Loader } from '../../../../../../../components/Loader';
import { useResizeObserver } from '../../../../../../../helpers/hooks/useResizeObserver';
import { ExpensesContext } from '../../../Expenses';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const widthBreakpointXXS = 650;
export const widthBreakpointXS = 800;
export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1150;
export const minRowHeight = 54;
export const columnsWidths = {
  parent: 200,
  category: 160,
  description: 250,
  attachments: 100,
  date: 220,
  budget: 120,
  amount: 120,
  status: 100,
  action: 65
};

export const Expenses = ({ caseID, onExpenseSelect }) => {
  const {
    isFetched,
    expenses,
    meta,
    resetExpenses
  } = useContext(ExpensesContext);
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });

  useEffect(() => {
    resetExpenses();
  }, []);

  return (
    <Box ref={rootRef} height="100%" display="flex" flexDirection="column">
      <Loader loading={!isFetched} p={3} render={
        () => (!expenses.length ?
          <Box p={3}>
            <Typography align="center">No expenses found</Typography>
          </Box>
          :
          <>
            <TableHeader
              isFetched={isFetched}
              disableToggle={!!onExpenseSelect}
              caseID={caseID}
              width={width}
            />

            <MuiList disablePadding>
              {expenses.map((expense) => (
                <Row
                  key={expense.id}
                  expense={expense}
                  width={width}
                  caseID={caseID}
                  onExpenseSelect={onExpenseSelect}
                />
              ))}
            </MuiList>

            <TablePagination pagination={meta} onChange={resetExpenses} />
          </>
        )}
      />
    </Box>
  );
};
