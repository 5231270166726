import { Typography } from '@material-ui/core';
import { Contact } from '../../Contact';

export const ContactActivity = ({ before = null, after = null, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Set&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;to&nbsp;
        <Contact
          type="tel"
          variant="subtitle1"
          contact={after}
        />
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Contact
          type="tel"
          variant="subtitle1"
          contact={before}
        />
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Contact
          type="tel"
          variant="subtitle1"
          contact={before}
        />
        &nbsp;to&nbsp;
        <Contact
          type="tel"
          variant="subtitle1"
          contact={after}
        />
      </>
    );
  } else {
    return (
      <>Empty&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography></>
    );
  }
};
