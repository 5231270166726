import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { TaskPreviewContext } from '../../../../../components/tasks/TasksProvider';
import * as types from '../../../../../components/tasks/TasksProvider/types';
import { Task } from '../Task';
import { EditTask } from '../EditTask';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainWindow = ({ sidebarIsOpen, setSidebarIsOpen }) => {
  const classes = useStyles();
  const { state } = useLocation();
  const tasksLastGlobalAction = useSelector(({ globalActions }) => globalActions.tasksLastGlobalAction);
  const { isFetched, selectedTaskId, setSelectedTask, fetchTask } = useContext(TaskPreviewContext);
  const [ isEdit, setIsEdit ] = useState(false);

  const handleEditTask = () => {
    setIsEdit(false);
  };

  useEffect(() => {
    fetchTask(selectedTaskId);
  }, [ selectedTaskId ]);

  useEffect(() => {
    state && setIsEdit(state.isUpdate);
  }, [ state && state.isUpdate ]);

  useEffect(() => {
    if (tasksLastGlobalAction && tasksLastGlobalAction.type === types.UPDATE_TASK_IN_LIST) {
      setSelectedTask(tasksLastGlobalAction.payload);
    }
  }, [ tasksLastGlobalAction ]);

  return (
    <div className={classes.root}>
      <Loader loading={!isFetched} className={classes.loader} render={
        () => (
          !isEdit ?
            <Task
              sidebarIsOpen={sidebarIsOpen}
              setIsEdit={setIsEdit}
              setSidebarIsOpen={setSidebarIsOpen}
            />
            :
            <EditTask
              sidebarIsOpen={sidebarIsOpen}
              handleEditTask={handleEditTask}
              setSidebarIsOpen={setSidebarIsOpen}
            />
        )}
      />
    </div>
  );
};
