import * as types from './types';

export const setFilesLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_FILES_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setAppointmentsLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_APPOINTMENTS_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setTagsLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_TAGS_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setTasksLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_TASKS_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setCommentsLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_COMMENTS_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setCompaniesTypesLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_COMPANIES_TYPES_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setCasesLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_CASES_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setTracksLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_TRACKS_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setUsersLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_USERS_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setInvoicesLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_INVOICES_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setExpensesLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_EXPENSES_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setPaymentsLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_PAYMENTS_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setCPTCodesLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_CPT_CODES_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setSchedulesLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_SCHEDULES_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setMedicalProfileLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_MEDICAL_PROFILE_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});

export const setHistoryLastGlobalAction = ({ type, payload }) => ({
  type: types.SET_HISTORY_LAST_GLOBAL_ACTION,
  payload: { type, payload }
});
