import { Link, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { amFormatDuration } from '../../../../../../../helpers/dates';
import { preventDefault } from '../../../../../../../helpers/dom';
import { billingParticipantsTypesMap } from '../../../../../../../components/billing';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { IconButton } from '../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../components/UserLink';
import { widthBreakpointSmall, widthBreakpointXS } from '../../../InvoiceItem';
import { columnsWidths, minRowHeight } from '../Incomes';

export const Row = ({
  income,
  width,
  parentPage,
  openCasePreview,
  handleUpdateIncome,
  handleDeleteIncome
}) => {
  return (
    <ListRow px={2} minHeight={minRowHeight}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        {income.participant_type === billingParticipantsTypesMap.user || !!income?.participant?.role ?
          <UserLink
            noWrap
            size="sm"
            variant="h5"
            user={income?.participant}
          />
          :
          <CompanyLink
            noWrap
            size="sm"
            variant="h5"
            company={income?.participant}
          />
        }
      </ListRowCell>

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={columnsWidths.parent}>
          <Link noWrap title={income.name} onClick={openCasePreview}>
            {income.name}
          </Link>
        </ListRowCell>
      }

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.notes}>
          <Typography noWrap title={income.notes}>
            {income.notes}
          </Typography>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.rate}>
        <Typography>
          <CurrencyFormat value={income?.rate?.toFixed(2)} />
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.hours}>
        <Typography>
          {income.hours || amFormatDuration(income.time, 'seconds', 'h[h] m[min]')}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        <Typography>
          <CurrencyFormat value={income?.total} />
        </Typography>
      </ListRowCell>

      <ListRowCell actions justifyContent="flex-end" flexBasis={columnsWidths.action}>
        {parentPage &&
          <IconButton color="primary" onClick={handleUpdateIncome}>
            <EditIcon />
          </IconButton>
        }

        <IconButton
          edge="end"
          color="error"
          title="Delete income"
          onClick={preventDefault(handleDeleteIncome)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
