import { Date, Text, Company, CurrencyFormat, UserActivityLink } from '../../fields';

export const paymentFieldsMap = {
  date: (before, after) => <Date before={before} after={after} fieldLabel="Date" />,
  note: (before, after) => <Text disableSemicolon before={before} after={after} fieldLabel="Notes" />,
  amount: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Amount" />,
  method: (before, after) => <Text before={before} after={after} fieldLabel="Method" />,
  invoice: (before, after) => <Text before={before?.id} after={after?.id} fieldLabel="Invoice" />,
  number: (before, after) => <Text before={before} after={after} fieldLabel="Number" />,
  company: (before, after) => <Company before={before} after={after} fieldLabel="Company" />,
  updated_at: (before, after) => <Date before={before} after={after} fieldLabel="Updated At" />,
  refunded_at: (before, after) => <Date before={before} after={after} fieldLabel="Refunded At" />,
  payer: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Payer" />
};
