import { Box } from '@material-ui/core';
import { FilesCardWidget } from '../../../../files-common/FilesCardWidget';

export const Files = ({ contact }) => {
  return (
    <Box height={630}>
      <FilesCardWidget
        disableRightActions
        title="Related Files"
        payload={{
          filter: { contact_profile_id: contact.profile_id },
          ownerType: 'profile',
          ownerID: contact.profile_id
        }}
      />
    </Box>
  );
};
