import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import {
  AppointmentsFilterProvider,
  AppointmentsProvider,
  AppointmentCreateModal,
  apptTypesMap
} from '../../../../components/appointments';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../../../components/LayoutContext';
import { Page, PageBody, PageHeader } from '../../../../components/Page';
import { useModal } from '../../../../components/ModalsProvider';
import { UserContext } from '../../Members/User/UserProvider';
import { ActionsBar } from '../ActionsBar';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

export const Consultation = () => {
  const { openModal } = useModal();
  const params = useParams();
  const { user } = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const patient = userContext ? userContext?.user : user;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filterOpenButton,
    ScrollWrapper,
    scrollWrapperProps,
    FilterBarWrapper,
    filterBarWrapperProps
  } = useMobileFilterCollapse();

  const addAppointment = () => {
    openModal(AppointmentCreateModal, {
      payload: {
        initialValues: {
          patient,
          patient_id: patient,
          patient_appointment_details: { patient, ...patient },
          patient_insurance_id: patient?.medical?.main_insurance
        },
        title: 'Add New Consultations',
        disableAttachments: true,
        appointmentTypeID: apptTypesMap.consultation
      }
    });
  };

  const appointmentsFilter = {
    appointment_types: [ apptTypesMap.consultation ],
    patients: [ params.patientID || params.userID || user?.id ]
  };

  return (
    <LayoutContextProvider>
      <LayoutContext.Consumer>
        {({ viewVariant }) => (
          <AppointmentsFilterProvider filter={appointmentsFilter}>
            <AppointmentsProvider>
              <Page>
                <PageHeader disableBorderBottom>
                  <ActionsBar
                    disableViewVariant={isTablet}
                    actionTitle="Add new"
                    filterOpenButton={filterOpenButton}
                    onCreate={addAppointment}
                  />
                </PageHeader>

                <PageBody fullHeight disablePaddings disableScrollbars>
                  <Box height="100%" display="flex" flexDirection="column">
                    <ScrollWrapper {...scrollWrapperProps}>
                      <FilterBarWrapper {...filterBarWrapperProps}>
                        <FiltersBar filterKey={filtersKeysMap.consultation} />
                      </FilterBarWrapper>
                    </ScrollWrapper>

                    <Box flexGrow={1}>
                      {!isTablet ? (
                        (viewVariant === viewVariantsMap.list)
                          ? <List />
                          : (viewVariant === viewVariantsMap.grid)
                            ? <Grid /> : null
                      ) : (
                        <Grid />
                      )}
                    </Box>
                  </Box>
                </PageBody>
              </Page>
            </AppointmentsProvider>
          </AppointmentsFilterProvider>
        )}
      </LayoutContext.Consumer>
    </LayoutContextProvider>
  );
};
