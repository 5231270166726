import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import * as formsApi from '../../../../../api/profile';
import {
  ActionButtons,
  PatientHistoryForm,
  generatePatientHistoryValues,
  patientHistoryValidationSchema
} from '../../../../../components/medical/forms';
import { Divider } from '../../../../../components/Divider';
import { Loader } from '../../../../../components/Loader';
import { FormContext } from '../../FormProvider';
import { styles } from '../../styles';

const useStyles = makeStyles(styles);

export const PatientHistory = () => {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { form, isFetched, handleComplete, handleBack } = useContext(FormContext);

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.forms.patient_history.please_rate_your_pain.at_rest =
      +(values?.forms?.patient_history?.please_rate_your_pain?.at_rest || 0);
    values.forms.patient_history.please_rate_your_pain.at_worst =
      +(values?.forms?.patient_history?.please_rate_your_pain?.at_worst || 0);

    return formsApi.updateGuestPatientHistory(values).then(({ data }) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleComplete(data);
    }).catch(({ errors }) => {
      setErrors(errors);
      enqueueSnackbar('Form not created', { variant: 'error' });
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            token: form?.token || null,
            form_id: form?.id,
            ...generatePatientHistoryValues(form)
          }}
          validationSchema={patientHistoryValidationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <PatientHistoryForm />
              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
