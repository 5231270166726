import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <ListRow>
      <ListRowCell noWrap flexBasis={columnsWidths.search}>
        Search
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.providers}>
        Providers
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.appointmentTypes}>
        Appointment types
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.status}>
        Status
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.orderBy}>
        Order By
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
