import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import cn from 'classnames';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { setTasksLastGlobalAction } from '../../../../../store/globalActions';
import * as tasksApi from '../../../../../api/tasks';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { FileDropZoneWindow } from '../../../../../components/FileDropZoneWindow';
import { IconButton } from '../../../../../components/IconButton';
import { useModal } from '../../../../../components/ModalsProvider';
import { Loader } from '../../../../../components/Loader';
import { FilesFilterContextProvider, FilesContextProvider, FileUploadModal } from '../../../files-common';
import { PreviewTaskBody } from '../../PreviewTaskModal/PreviewTaskBody';
import { PreviewTaskActivity } from '../../PreviewTaskModal/PreviewTaskActivity';
import { PreviewRightSidebar } from '../../PreviewTaskModal/PreviewRightSidebar';
import * as types from '../../../../../components/tasks/TasksProvider/types';
import { TaskPreviewContext } from '../../../../../components/tasks/TasksProvider';
import { Header } from '../Header';
import { StatusBar } from '../StatusBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Task = ({
  sidebarIsOpen,
  setIsEdit,
  setSidebarIsOpen
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatchRedux = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const { isFetched, count, selectedTask } = useContext(TaskPreviewContext);
  const [ isRightSidebarOpen, setIsRightSidebarOpen ] = useState(false);

  const handleToggleSideBar = () => {
    setSidebarIsOpen((state) => !state);
  };

  const editTask = () => {
    setIsEdit(true);
  };

  const toggleRightSidebar = () => {
    setIsRightSidebarOpen((isOpen) => !isOpen);
  };

  const handleDeleteTask = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        tasksApi.deleteTask(selectedTask.id).then(() => {
          dispatchRedux(setTasksLastGlobalAction({
            type: types.DELETE_TASK_SUCCESS,
            payload: selectedTask.id
          }));
          enqueueSnackbar('Task successfully deleted', { variant: 'success' });
          history.goBack();
        });
      }
    });
  };

  const handleFilesDropped = (files) => {
    openModal(FileUploadModal, {
      payload: {
        files,
        owner_type: 'task',
        owner_id: selectedTask.id
      }
    });
  };

  return (
    <FilesFilterContextProvider>
      <FilesContextProvider>
        <FileDropZoneWindow onDropAccepted={handleFilesDropped}>
          {!isFetched ? <Loader /> :
            <div className={classes.root}>
              <Header
                onDelete={handleDeleteTask}
                onUpdate={editTask}
                handleToggleRightSidebar={toggleRightSidebar}
                icon={
                  <IconButton edge="start" color="primary" size="small" onClick={handleToggleSideBar}>
                    {sidebarIsOpen
                      ? <ChevronLeftIcon fontSize="small" />
                      : <ChevronRightIcon fontSize="small" />
                    }
                  </IconButton>
                }
              >
                {selectedTask.name}
              </Header>

              <div className={classes.mainContent}>
                <div className={classes.leftSideBar}>
                  <StatusBar />
                  <PreviewTaskBody isStaticPage />
                  <PreviewTaskActivity isStaticPage count={count} />
                </div>

                {isMobile && isRightSidebarOpen &&
                  <div className={classes.mobileOverlay} onClick={toggleRightSidebar} />
                }

                <div className={cn(
                  classes.rightSideBar, {
                    [classes.rightSideBarMobile]: isMobile,
                    [classes.rightSideBarMobileClosed]: isMobile && !isRightSidebarOpen
                  })}
                >
                  <PreviewRightSidebar
                    isStaticPage
                    classes={classes}
                    handleToggleRightSidebar={toggleRightSidebar}
                  />
                </div>
              </div>
            </div>
          }
        </FileDropZoneWindow>
      </FilesContextProvider>
    </FilesFilterContextProvider>
  );
};
