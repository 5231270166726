import { makeStyles, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1" align="center">Page not found</Typography>
    </div>
  );
};
