import {
  Box,
  List as MuiList,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../ListRow';
import { lumbarSpecialTestsNamesMap } from '../../../../spinalExaminationMap';
import { MotorStrength, RangeMotion, ReflexLower } from '../CervicalSpine';
import { specialTestsMap } from '../CervicalSpine/SpecialTest';
import { columnsWidthsLg, columnsWidthsSm } from '../columnsWidths';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const LumbarSpine = ({ report }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;
  const lumbarSpecialTests = Object.keys(lumbarSpecialTestsNamesMap);

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        Palpation of the spine reveals tenderness (graded 0=none; 1+=mild; 2+=moderate; and
        3+=marked) in the following regions:
      </Typography>

      <Box my={2}>
        <div className={classes.root}>
          <ListRow header>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name} />

            <ListRowCell flexBasis={columnsWidths.right}>
              <Typography variant="subtitle2">
                Right
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.midline}>
              <Typography variant="subtitle2">
                Midline
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.left}>
              <Typography variant="subtitle2">
                Left
              </Typography>
            </ListRowCell>
          </ListRow>

          <ListRow>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
              <Typography noWrap variant="h5">
                Paraspinal Muscle Tenderness:
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.right}>
              {report?.spinal_examination_lumbar_spine?.paraspinal_muscle_tenderness?.right || '-'}
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.midline}>
              {report?.spinal_examination_lumbar_spine?.paraspinal_muscle_tenderness?.midline || '-'}
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.left}>
              {report?.spinal_examination_lumbar_spine?.paraspinal_muscle_tenderness?.left || '-'}
            </ListRowCell>
          </ListRow>
        </div>
      </Box>

      <RangeMotion report={report?.range_of_motion_cervical_spine || {}} />
      <MotorStrength isLumbarSpine report={report?.motor_strenght_lover_extremities || {}} />
      <ReflexLower isLumbarSpine report={report?.reflexes_lower_extremities || {}} />

      <Box mt={4}>
        <Typography gutterBottom variant="h5">Special tests</Typography>

        <div className={classes.root}>
          <ListRow header>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name} />

            <ListRowCell flexBasis={columnsWidths.right}>
              <Typography variant="subtitle2">
                Right
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.right}>
              <Typography variant="subtitle2">
                Midline
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.right}>
              <Typography variant="subtitle2">
                Left
              </Typography>
            </ListRowCell>
          </ListRow>

          <MuiList disablePadding>
            {lumbarSpecialTests?.map((item, index) => (
              <ListRow key={index}>
                <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                  <Typography noWrap variant="h5">
                    {lumbarSpecialTestsNamesMap[item]}:
                  </Typography>
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.right}>
                  <Typography color={specialTestsMap?.[report?.special_tests?.[item]?.right]?.color}>
                    {specialTestsMap?.[report?.special_tests?.[item]?.right]?.name || '-'}
                  </Typography>
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.right}>
                  <Typography color={specialTestsMap?.[report?.special_tests?.[item]?.left]?.color}>
                    {specialTestsMap?.[report?.special_tests?.[item]?.left]?.name || '-'}
                  </Typography>
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.right}>
                  <Typography color={specialTestsMap?.[report?.special_tests?.[item]?.expected]?.color}>
                    {specialTestsMap?.[report?.special_tests?.[item]?.expected]?.name || '-'}
                  </Typography>
                </ListRowCell>
              </ListRow>
            ))}
          </MuiList>
        </div>
      </Box>
    </>
  );
};
