import { forwardRef } from 'react';
import { makeStyles, Checkbox as MuiCheckbox } from '@material-ui/core';
import { SwitchBase } from '../SwitchBase';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Checkbox = forwardRef(({ color, ...props }, ref) => {
  const classes = useStyles({ color });

  return (
    <SwitchBase
      ref={ref}
      classes={classes}
      Component={MuiCheckbox}

      {...props}
    />
  );
});
