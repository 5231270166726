import { makeStyles } from '@material-ui/core';
import { TemplatesContextProvider } from './TemplatesContext';
import { FiltersBar } from './FiltersBar';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TemplatesPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TemplatesContextProvider>
        <FiltersBar />

        <div className={classes.list}>
          <List />
        </div>
      </TemplatesContextProvider>
    </div>
  );
};
