import { useContext } from 'react';
import { Schedule as ScheduleComponent } from '../../../../../../components/schedule-events';
import { UserContext } from '../../UserProvider';

export const Schedule = ({ hiddenFilterFields = [] }) => {
  const { user } = useContext(UserContext);

  return (
    <ScheduleComponent filter={{ users: [ user.id ] }} hiddenFilterFields={hiddenFilterFields} />
  );
};
