import { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Box, List as MuiList, makeStyles, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { FiltersBar } from '../../FiltersBar';
import { TimeCaseContext } from '../TimeCaseContext';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 40;
export const columnsWidths = {
  time: 150,
  parent: 80,
  title: 250
};

export const MainContent = () => {
  const classes = useStyles();
  const {
    isFetching,
    isFetched,
    filter,
    statistics,
    resetStatistics,
    loadNextPage
  } = useContext(TimeCaseContext);
  const [ isOpenDayPicker, setIsOpenDayPicker ] = useState(false);
  const [ isOpenWeekPicker, setIsOpenWeekPicker ] = useState(false);
  const [ isOpenMonthPicker, setIsOpenMonthPicker ] = useState(false);
  const [ isOpenYearPicker, setIsOpenYearPicker ] = useState(false);
  const [ message, setMessage ] = useState(moment.unix(filter.from).format('MM/YYYY'));
  const scrollElementRef = useRef();

  useEffect(() => {
    resetStatistics();
  }, []);

  return (
    <div className={classes.root}>
      <FiltersBar
        message={message}
        isOpenDayPicker={isOpenDayPicker}
        isOpenWeekPicker={isOpenWeekPicker}
        isOpenMonthPicker={isOpenMonthPicker}
        isOpenYearPicker={isOpenYearPicker}
        filter={filter}
        onChange={resetStatistics}
        setIsOpenYearPicker={setIsOpenYearPicker}
        setIsOpenMonthPicker={setIsOpenMonthPicker}
        setIsOpenWeekPicker={setIsOpenWeekPicker}
        setIsOpenDayPicker={setIsOpenDayPicker}
        setMessage={setMessage}
      />

      <Scrollbars viewRef={scrollElementRef}>
        <Loader p={2} loading={!isFetched} render={
          () => !filter.total ? (
            <Box p={2}>
              <Typography align="center">No statistics found</Typography>
            </Box>
          ) : (
            <MuiList disablePadding>
              <InfiniteListLoader
                items={statistics}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
              />
            </MuiList>
          )}
        />
      </Scrollbars>
    </div>
  );
};
