import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Dialog, Button } from '@material-ui/core';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { Select } from '../../../../../components/FormField';
import { paperSizesOptions } from '../../../files-common';
import { templatesTypesOptions } from '../templatesTypes';

export const TemplateSettingsModal = ({
  DialogProps,
  payload,
  handleModalResolve,
  handleModalReject
}) => {
  const availablePaperSizes = useSelector(({ apiConfig }) => apiConfig.config.html_documents.page_sizes);
  const paperSizes = useMemo(() => {
    return paperSizesOptions.filter(({ value }) => Object.keys(availablePaperSizes).includes(value));
  }, [ availablePaperSizes ]);

  const handleSubmit = (values) => {
    handleModalResolve(values);
  };

  return (
    <Dialog maxWidth="xs" {...DialogProps}>
      <Formik
        initialValues={{
          type: templatesTypesOptions[0],
          page_size: paperSizes[0],
          page_fields: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          },

          ...payload
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Template settings
            </ModalHeader>

            <ModalBody>
              <Select
                formattedValue
                isSearchable={false}
                name="type"
                label="Template type"
                options={templatesTypesOptions}
                TextFieldProps={{ margin: 'dense' }}
              />

              <Select
                formattedValue
                isSearchable={false}
                name="page_size"
                label="Paper size"
                options={paperSizes}
                TextFieldProps={{ margin: 'dense' }}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                  >
                    Apply
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
