export const styles = ({
  spacing,
  palette: { lightgreen, darkblue, grey },
  typography: { pxToRem, fontWeightMedium }
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },

  title: {
    flexGrow: 1,
    width: '70%',
    paddingRight: spacing()
  },

  invoiceTitle: {
    fontSize: pxToRem(16),
    fontWeight: fontWeightMedium
  },

  checkbox: {
    alignSelf: 'center',
    marginRight: spacing(-0.5),
    color: grey[400]
  },

  menu: {
    marginRight: -spacing()
  },

  invoiceCheckboxIcon: {
    marginRight: spacing(),
    color: lightgreen
  },

  invoiceCheckboxIcon_green: {
    marginRight: spacing(),
    color: darkblue
  }
});
