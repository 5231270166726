import background from './background.jpeg';

export const styles = ({ breakpoints }) => ({
  main: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: ({ disableBackground = false }) => !disableBackground
      ? `url(${background}) left / cover no-repeat` : 'none',

    [breakpoints.up('lg')]: {
      backgroundPosition: 'center'
    }
  },

  root: {
    backgroundColor: 'rgba(32, 32, 32, 0.75)',
    borderBottom: 'none'
  },

  list: {
    minWidth: 300
  }
});
