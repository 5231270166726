import { createContext, useRef, useEffect, useReducer } from 'react';
import { isEqual, omit } from 'lodash';
import { usePrevious } from '../../../../../../helpers/hooks';
import { api } from '../../../../../../api';
import * as searchApi from '../../../../../../api/global-search';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { isFetched, isFetching, filter, pagination, searchResults } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchSearch = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_SEARCH_REQUEST });

    searchApi.fetchSearch({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_SEARCH_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchSearch({ page: filter.page + 1 });
    }
  };

  const resetSearch = (newFilter) => {
    dispatch({ type: types.RESET_SEARCH, payload: newFilter });
    fetchSearch({ page: 1, ...newFilter });
  };

  const providerValue = {
    isFetched,
    isFetching,
    searchResults,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    resetSearch,
    fetchSearch,
    loadNextPage
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetSearch(filter);
    }
  }, [ filter, prevFilter ]);

  return (
    <SearchContext.Provider value={providerValue}>
      {children}
    </SearchContext.Provider>
  );
};
