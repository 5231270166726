import cn from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect, Switch, useLocation } from 'react-router-dom';
import ListIcon from '@material-ui/icons/List';
import { Box, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { getActiveRoute, renderRoutes, Route } from '../../../components/router';
import { MedicalReportsTabs } from '../../../components/MedicalReportsTabs';
import { Tab, Tabs } from '../../../components/Tabs';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { getRoute } from '../PatientsPage/MedicalInfo/routes';
import { getReportsRoutes } from '../MedicalReports';
import { styles } from '../PatientsPage/MedicalInfo/styles';
import { MedicalProfileProvider } from './MedicalInfo/MainInfo/MedicalProfileProvider';
import { clientRoutes } from './clientRoutes';
import { LeftSidebar } from './LeftSidebar';
import { EditUser } from './EditUser';
import { patientRoutes } from './patientRoutes';

const useStyles = makeStyles(styles);

export const ClientProfilePage = () => {
  const classes = useStyles();
  const location = useLocation();
  const userSidebarIsOpen = useSelector(({ layout: { userInfoIsOpen = true } }) => userInfoIsOpen);
  const [ isUserInfoOpen, setIsUserInfoOpen ] = useState(false);
  const currentUser = useSelector(({ profile }) => profile.user);
  const pathname = location.pathname.includes('/medical-forms')
    ? getRoute('/profile/medical-info/medical-forms')
    : getRoute('/profile/medical-info/medical-report');
  const reportRoutes = getReportsRoutes('/profile/medical-info/medical-report');
  const routes = hasRole(rolesMap.patient) ? patientRoutes : clientRoutes;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    FilterBarWrapper,
    filterBarWrapperProps,
    toggleFiltersBar,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  const toggleInfoOpen = () => {
    setIsUserInfoOpen((state) => !state);
  };

  if (location.pathname === '/profile/medical-info/medical-report') {
    return (
      <Switch>
        <Route exact path={location.pathname}>
          <Redirect to={location.pathname + '/consultation'} />
        </Route>
      </Switch>
    );
  }

  if (location.pathname.includes('edit') && !location.pathname.includes('medical-forms')) {
    return <Route path={location.pathname} component={EditUser} />;
  }

  return (
    <MedicalProfileProvider medicalInfoPathname="/profile/medical-info">
      <div className={classes.root}>
        {isTablet &&
          <div className={classes.navigation}>
            <Box mx={2.25} mt={1}>
              <IconButton size="medium" onClick={toggleFiltersBar}>
                <ListIcon />
              </IconButton>
            </Box>
          </div>
        }

        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <Tabs
              value={getActiveRoute(routes, true, pathname)}
              orientation={isTablet ? 'vertical' : 'horizontal'}
              classes={{ flexContainer: classes.tabsWrapper }}
            >
              {routes.map(({ path, icon, label }) => (
                icon &&
                <Tab
                  key={path}
                  component={Link}
                  to={path}
                  value={path}
                  icon={icon}
                  label={label}
                  onClick={isTablet ? toggleFiltersBar : undefined}
                />
              ))}
            </Tabs>
          </FilterBarWrapper>
        </ScrollWrapper>

        <Route path="/profile/medical-info/medical-report">
          <MedicalReportsTabs pathname="/profile/medical-info/medical-report"/>
        </Route>

        <div className={classes.content}>
          {userSidebarIsOpen && (
            <div className={cn(classes.leftSidebar, { [classes.leftSidebarFullHeight]: isUserInfoOpen })}>
              <LeftSidebar
                user={currentUser}
                openUserInfo={isUserInfoOpen}
                toggleInfoOpen={toggleInfoOpen}
              />
            </div>
          )}

          <div className={cn(classes.main, { [classes.mainFullHeight]: !isUserInfoOpen })}>
            {renderRoutes(routes)}
            {renderRoutes(reportRoutes)}
          </div>
        </div>
      </div>
    </MedicalProfileProvider>
  );
};
