import {
  Box,
  List as MuiList,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../ListRow';
import { cervicalSpecialTestsNamesMap } from '../../../../../spinalExaminationMap';
import { columnsWidthsLg, columnsWidthsSm } from '../../columnsWidths';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const specialTestsMap = {
  Negative: { name: 'Negative', color: 'error' },
  Positive: { name: 'Positive', color: 'secondary' }
};

export const SpecialTest = ({ report }) => {
  const classes = useStyles();
  const cervicalSpecialTests = Object.keys(cervicalSpecialTestsNamesMap);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <Box mt={4}>
      <Typography gutterBottom variant="h5">Special tests</Typography>

      <Box my={2}>
        <div className={classes.root}>
          <ListRow header>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name} />

            <ListRowCell flexBasis={columnsWidths.cell}>
              <Typography variant="subtitle2">
                Right
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.cell}>
              <Typography variant="subtitle2">
                Left
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.cell}>
              <Typography color="textSecondary">
                Expected
              </Typography>
            </ListRowCell>
          </ListRow>

          <MuiList disablePadding>
            {cervicalSpecialTests?.map((item, index) => (
              <ListRow key={index}>
                <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                  <Typography noWrap variant="h5">
                    {cervicalSpecialTestsNamesMap[item]}
                  </Typography>
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.cell}>
                  <Typography color={specialTestsMap?.[report?.[item]?.right]?.color}>
                    {specialTestsMap?.[report?.[item]?.right]?.name || '-'}
                  </Typography>
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.cell}>
                  <Typography color={specialTestsMap?.[report?.[item]?.left]?.color}>
                    {specialTestsMap?.[report?.[item]?.left]?.name || '-'}
                  </Typography>
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.cell}>
                  <Typography color={specialTestsMap?.[report?.[item]?.expected]?.color}>
                    {specialTestsMap?.[report?.[item]?.expected]?.name || '-'}
                  </Typography>
                </ListRowCell>
              </ListRow>
            ))}
          </MuiList>
        </div>
      </Box>
    </Box>
  );
};
