import { makeStyles } from '@material-ui/core';
import { VerticalDivider } from '../../../../VerticalDivider';

const styles = ({ typography: { pxToRem }, transitions }) => ({
  root: {
    height: pxToRem(18),
    marginTop: 'auto',
    marginBottom: 'auto',
    transition: transitions.create(),

    '&:first-child': {
      opacity: 0
    }
  }
});

const useStyles = makeStyles(styles);

export const IndicatorSeparator = () => {
  const classes = useStyles();

  return (
    <VerticalDivider horizontalGutters={0.75} className={classes.root} />
  );
};
