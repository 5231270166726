import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, Typography, List as MuiList, Box } from '@material-ui/core';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { Loader } from '../../../../../../../components/Loader';
import { InvoicesContext } from '../../../InvoicesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 60;
export const columnsWidths = {
  number: 160,
  status: 100,
  due_date: 90
};

export const List = () => {
  const location = useLocation();
  const classes = useStyles();
  const {
    invoices,
    isFetched,
    meta,
    applyFilter,
    resetInvoices
  } = useContext(InvoicesContext);

  useEffect(() => {
    if (location.state?.filter) {
      applyFilter(location.state?.filter);
    } else {
      resetInvoices();
    }
  }, []);

  return (
    <div className={classes.root}>
      <Loader loading={!isFetched} p={3} render={
        () => !invoices.length ?
          <Box p={3}>
            <Typography align="center">No invoices found</Typography>
          </Box>
          :
          <>
            <TableHeader/>

            <Scrollbars>
              <MuiList disablePadding>
                {invoices.map((invoice) => (
                  <Row
                    key={invoice.id}
                    invoice={invoice}
                  />
                ))}
              </MuiList>
            </Scrollbars>

            <TablePagination borderTop disablePerPage pagination={meta} onChange={resetInvoices} />
          </>
      }
      />
    </div>
  );
};
