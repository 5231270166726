import { Box, Grid, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { ReadonlyCheckbox } from '../../../../../../../../components/ReadonlyCheckbox';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../../../components/users';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Divider } from '../../../../../../../../components/Divider';
import { Contact } from '../../../../../../../../components/Contact';
import { Date } from '../../../../../../../../components/Date';
import { PreviewCompanyModal } from '../../../../../../Contacts/Companies/PreviewCompanyModal';

export const CaseClosureContent = ({ caseInfo }) => {
  const { openModal } = useModal();

  const openUserPreview = () => {
    if (caseInfo.closed_by?.id) {
      openModal(UserPreviewModal, {
        payload: { id: caseInfo.closed_by?.id }
      });
    }
  };

  const openCompanyPreview = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: caseInfo?.substituted_out?.id }
    });
  };

  return (
    <Grid container spacing={2} component={Box} pb={2}>
      <Grid item xs={3}>
        <Typography color="textSecondary">Date Case Close</Typography>
      </Grid>

      <Grid item xs={3}>
        <Date variant="h5" date={caseInfo.closed_at} />
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">Case Closed By</Typography>
      </Grid>

      <Grid item xs={3}>
        <UserLink
          size="sm"
          variant="h5"
          user={caseInfo?.closed_by}
          onClick={openUserPreview}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography noWrap color="textSecondary">Date Physical File Scanned</Typography>
      </Grid>

      <Grid item xs={3}>
        <Date variant="h5" date={caseInfo.physical_file_scanned_at} />
      </Grid>

      <Grid item xs={6} />

      <Grid item xs={3}>
        <Typography noWrap color="textSecondary">Case Subbed Out</Typography>
      </Grid>

      <Grid item xs={3}>
        <ReadonlyCheckbox title="Yes/No" checked={caseInfo.subbed_out} />
      </Grid>

      <Grid item xs={3}>
        <Typography noWrap color="textSecondary">Date Case Subbed Out</Typography>
      </Grid>

      <Grid item xs={3}>
        <Date variant="h5" date={caseInfo.subbed_out_at} />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={1}>
          <Typography variant="h4">Substituted out information</Typography>

          <Box flexGrow={1} ml={4}>
            <Divider type="dashed" />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">Company name</Typography>
      </Grid>

      <Grid item xs={9}>
        <CompanyLink
          size="sm"
          variant="h5"
          company={caseInfo?.substituted_out}
          onClick={stopPropagation(openCompanyPreview)}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">Phone Number</Typography>
      </Grid>

      <Grid item xs={9}>
        <Contact
          needIcon
          variant="h5"
          type="tel"
          contact={caseInfo?.substituted_out?.phone}
        />
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">Address</Typography>
      </Grid>

      <Grid item xs={9}>
        {caseInfo?.substituted_out?.full_address
          ? <Typography variant="h5">{caseInfo?.substituted_out?.full_address}</Typography>
          : <Typography color="textSecondary">No info.</Typography>
        }
      </Grid>
    </Grid>
  );
};
