import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_APPOINTMENT_TYPES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_APPOINTMENT_TYPES_SUCCESS]: (
    { filter, appointmentTypes, ...state },
    { data: newCodes, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      appointmentTypes: (page > 1
        ? appointmentTypes.filter(({ id }) => {
          return !newCodes.find((loadedCode) => id === loadedCode.id);
        }).concat(newCodes)
        : newCodes
      )
    };
  },

  [types.RESET_APPOINTMENT_TYPES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_APPOINTMENT_TYPE]: (state, appointmentType) => {
    return {
      ...state,

      appointmentTypes: state.appointmentTypes.map((item) => {
        return item.id === appointmentType.id ? { ...item, ...appointmentType } : item;
      })
    };
  },

  [types.DELETE_APPOINTMENT_TYPE]: (state, appointmentTypeID) => {
    const { pagination, filter, appointmentTypes } = state;
    const filteredAppointmentTypes = appointmentTypes.filter(({ id }) => appointmentTypeID !== id);

    if (filteredAppointmentTypes.length === appointmentTypes.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((appointmentTypes.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      appointmentTypes: filteredAppointmentTypes
    };
  },

  [types.ADD_CODES_TO_SELECTED]: ({ selectedCodes, selectedCodesIDs, ...state }, codes) => {
    return {
      ...state,

      selectedCodes: selectedCodes.concat(codes.filter(({ id }) => !selectedCodesIDs.includes(id))),
      selectedCodesIDs: [ ...new Set(selectedCodesIDs.concat(codes.map(({ id }) => id))) ]
    };
  },

  [types.DELETE_CODES_FROM_SELECTED]: ({ selectedCodesIDs, ...state }, codesIDs) => {
    return {
      ...state,

      selectedCodesIDs: selectedCodesIDs.filter((id) => codesIDs.indexOf(id) === -1 )
    };
  }
});
