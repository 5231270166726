import { createContext, useState } from 'react';
import { initialState } from '../CaseRemindersProvider/initialState';

export const CaseRemindersFilterContext = createContext(null);

export const CaseRemindersFiltersProvider = ({ children, caseId, filter: filterProp }) => {
  const [ filter, setFilter ] = useState({ ...initialState.filter, ...filterProp });

  const applyFilter = (newFilter) => {
    setFilter((filter) => ({ ...filter, ...newFilter }));
  };

  const providerValue = {
    caseId,
    filter,
    applyFilter
  };

  return (
    <CaseRemindersFilterContext.Provider value={providerValue}>
      {children}
    </CaseRemindersFilterContext.Provider>
  );
};
