import { useContext } from 'react';
import {
  List,
  SvgIcon,
  MenuItem,
  makeStyles,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import { mdiCloudDownloadOutline, mdiFax, mdiTrashCanOutline, mdiPrinterPosSyncOutline } from '@mdi/js';
import UpdateIcon from '@material-ui/icons/Update';
import ReplyOutlinedIcon from '@material-ui/icons/ReplyOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { Icon } from '@mdi/react';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { parseFaxContact } from '../../../../components/faxes/FaxNumber';
import { useModal } from '../../../../components/ModalsProvider';
import { downloadFile as download, printFile } from '../../../../helpers/files';
import { SendFaxModal } from '../ActionsBar/SendFaxModal';
import { statusLabelsMap, statusesMap } from '../List/statusMap';
import { FaxContext } from '../FaxProvider';
import { StatusConfirmationPopup } from '../StatusConfirmationPopup';
import { CopyFileModal } from './CopyFileModal';
import { ReplyFaxModal } from './ReplyFaxModal';

const useStyles = makeStyles({
  root: {
    minWidth: 172
  }
});

export const FaxMenu = ({ fax, openFilesPreview, onClose }) => {
  const { openModal } = useModal();
  const { deleteFax, shareByEmail, updateStatus, resendFax } = useContext(FaxContext);
  const classes = useStyles();
  const isNew = fax?.system_status === statusesMap.processed;
  const statusLabel = isNew ? statusLabelsMap.new : statusLabelsMap.processed;

  const handleFaxResend = () => {
    resendFax(fax.id);
    onClose();
  };

  const copyFile = () => {
    onClose();

    openModal(CopyFileModal, {
      payload: {
        file: fax?.files?.[0]
      }
    });
  };

  const handleFaxReply = () => {
    onClose();

    openModal(ReplyFaxModal, {
      payload: {
        initialValues: {
          to: parseFaxContact(fax.from).phone,
          name: parseFaxContact(fax.from).name
        }
      }
    });
  };

  const handleFaxDelete = () => {
    deleteFax(fax.id);
    onClose();
  };

  const handleFaxShareByEmail = () => {
    const filesID = fax?.files?.map((item) => item?.id);

    shareByEmail(filesID);
  };

  const sendByFax = () => {
    openModal(SendFaxModal, {
      payload: {
        initialValues: {
          file_id: fax?.files?.[0]?.id
        }
      }
    });
  };

  const onUpdate = () => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Are you about to update the fax status?',
        maxWidth: 'sm',
        content: <StatusConfirmationPopup fax={fax} />
      },
      onModalResolved: () => {
        const newStatus = fax?.system_status === statusesMap.processed
          ? statusesMap.new : statusesMap.processed;

        updateStatus({ id: fax.id, status: newStatus });
      }
    });
  };

  const downloadFax = () => {
    download({ src: fax.files[0]?.upload_url, name: fax?.original_filename || fax?.name });
  };

  const printFax = () => {
    printFile(fax.files[0]);
  };

  return (
    <List className={classes.root}>
      <MenuItem disabled={!fax?.files?.length} onClick={openFilesPreview}>
        <ListItemIcon>
          <EyeIcon />
        </ListItemIcon>

        <ListItemText primary="Preview" />
      </MenuItem>

      {fax?.destination === 'outcome' &&
        <MenuItem disabled={!fax?.files?.length} onClick={handleFaxResend}>
          <ListItemIcon>
            <SvgIcon>
              <Icon path={mdiPrinterPosSyncOutline}/>
            </SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Resend"/>
        </MenuItem>
      }

      {fax?.destination === 'income' &&
        <MenuItem disabled={!fax?.files?.length || !fax.from} onClick={handleFaxReply}>
          <ListItemIcon>
            <ReplyOutlinedIcon />
          </ListItemIcon>

          <ListItemText primary="Reply"/>
        </MenuItem>
      }

      <MenuItem disabled={!fax?.files?.length} onClick={onUpdate}>
        <ListItemIcon>
          <UpdateIcon />
        </ListItemIcon>

        <ListItemText primary={statusLabel} />
      </MenuItem>

      <MenuItem disabled={!fax?.files?.length} onClick={downloadFax}>
        <ListItemIcon>
          <SvgIcon>
            <Icon path={mdiCloudDownloadOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Download" />
      </MenuItem>

      <MenuItem disabled={!fax?.files?.length} onClick={printFax}>
        <ListItemIcon>
          <PrintIcon />
        </ListItemIcon>

        <ListItemText primary="Print" />
      </MenuItem>

      <MenuItem onClick={copyFile}>
        <ListItemIcon>
          <FileCopyIcon />
        </ListItemIcon>

        <ListItemText primary="Copy file to" />
      </MenuItem>

      {!!fax?.files?.[0] &&
        <MenuItem onClick={handleFaxShareByEmail}>
          <ListItemIcon>
            <MailOutlinedIcon/>
          </ListItemIcon>

          <ListItemText primary="Share by email"/>
        </MenuItem>
      }

      {!!fax?.files?.[0] &&
        <MenuItem onClick={sendByFax}>
          <ListItemIcon>
            <SvgIcon><Icon path={mdiFax} /></SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Send by fax" />
        </MenuItem>
      }

      <MenuItem onClick={handleFaxDelete}>
        <ListItemIcon>
          <SvgIcon>
            <Icon path={mdiTrashCanOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
