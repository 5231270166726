import { useState } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { makeStyles, Box, Grid, Typography, Button, Collapse } from '@material-ui/core';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { conditionsOptions } from '../../CreateCasePage/IncidentInformation/conditionsOptions';
import { incidentTypesOptions } from '../../CreateCasePage/IncidentInformation/incidentTypesOptions';
import { Footer } from './Footer';
import { Info } from './Info';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CaseBody = ({ caseItem }) => {
  const classes = useStyles();
  const [ expandedContent, setExpandedContent ] = useState(false);

  const handleExpandClick = () => {
    setExpandedContent(!expandedContent);
  };

  return (
    <Formik
      initialValues={caseItem}
      onSubmit={null}
    >
      <>
        {!!caseItem.office &&
          <div className={classes.content}>
            {caseItem.office &&
              <>
                <Typography gutterBottom variant="body2">Assigned Office Location:</Typography>

                <OfficeLink
                  gutterBottom
                  variant="h5"
                  office={caseItem?.office}
                />
              </>
            }
          </div>
        }

        {caseItem.description &&
          <div className={classes.description}>
            <Typography className={classes.descriptionTitle}>
              Case description
            </Typography>

            <Collapse in={expandedContent}>
              <Typography className={classes.descriptionContent}>
                {!expandedContent
                  ? caseItem.description.substring(0, 100) + '...'
                  : caseItem.description
                }
              </Typography>
            </Collapse>

            <Button
              size="small"
              color="primary"
              onClick={handleExpandClick}
              className={classes.actionButton}
            >
              {!expandedContent ? 'more' : 'less'}
            </Button>
          </div>
        }

        <Typography variant="h6" color="inherit" className={classes.sectionHeader}>
          General Info
        </Typography>

        <Box p={2} mb={3}>
          <Info />
          <Footer />
        </Box>

        <Typography variant="h6" color="inherit" className={classes.sectionHeader}>
          Incident Information
        </Typography>

        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography color="textSecondary">Accident location</Typography>
              <Typography variant="h5">{caseItem.accident_location || '-'}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography color="textSecondary">Liability</Typography>
              <Typography variant="h5">{caseItem.liability || '-'}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography color="textSecondary">Accident time</Typography>

              <Typography variant="h5">
                {caseItem.accident_time
                  ? moment(caseItem.accident_time, 'YYYY-MM-DD H:m:s').format('L hh:mm a')
                  : '-'
                }
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography color="textSecondary">Incident type</Typography>

              <Typography variant="h5">
                {incidentTypesOptions.find(({ value }) => value === caseItem.incident_type)?.label || '-'}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography color="textSecondary">Conditions</Typography>

              <Typography variant="h5">
                {conditionsOptions.find(({ value }) => value === caseItem.conditions)?.label || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    </Formik>
  );
};
