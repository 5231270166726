import { Tooltip, Typography } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { CompanyLink } from '../../../../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../../../components/Loader';
import { orderByLabelsMap } from '../../../../../InvoicesContext';
import { filterFieldsMap } from '../../filterFieldsMap';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  hiddenColumns,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.number}>
        <Tooltip title={params?.number || '-'}>
          <Typography noWrap>{params?.number || '-'}</Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.users}>
        {params?._users?.length ? params?._users.map(({ id, participant }) => (
          <UserLink
            noWrap
            key={id}
            user={participant}
          />
        )) : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.companies}>
        {params?._companies?.length ? params?._companies.map(({ id, participant }) => (
          <CompanyLink
            noWrap
            key={id}
            company={participant}
          />
        )) : '-'}
      </ListRowCell>

      {!hiddenColumns.includes(filterFieldsMap.caseId) &&
        <ListRowCell noWrap flexBasis={columnsWidths.cases}>
          {params?.case_id?.name || '-'}
        </ListRowCell>
      }

      <ListRowCell noWrap flexBasis={columnsWidths.appointments}>
        {!params?.appointment_id?.appointment_at ? '-' :
          moment.unix(params?.appointment_id?.appointment_at).format('MM/DD/YYYY, LT')
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.claims}>
        <Tooltip title={params?.claim_id?.claim_number || '-'}>
          <Typography noWrap>
            {params?.claim_id?.claim_number || '-'}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateFrom}>
        {params?.due_date_from ? moment(params.due_date_from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateTo}>
        {params?.due_date_to ? moment(params.due_date_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.statuses}>
        {params?.status_id?.name || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.orderBy}>
        {(params?.order_by) ? orderByLabelsMap[params?.order_by] : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

