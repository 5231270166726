import { useContext, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Box, Tooltip, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { mdiCalendarClockOutline, mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useSelector } from 'react-redux';
import { FiltersBarModal } from '../../../../../../../components/FiltersBarModal';
import { useResizeObserver } from '../../../../../../../helpers/hooks';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { Badge } from '../../../../../../../components/Badge';
import { SvgIcon } from '../../../../../../../components/SvgIcon';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../components/IconButton';
import { Fab } from '../../../../../../../components/Fab';
import { filterFields } from './filterFields';
import { AddDepositionModal } from './AddDepositionModal/AddDespositionModal';
import { CaseDepositionsContext } from './CaseDepositionsProvider';
import { Grid } from './Grid';
import { List } from './List';

const initialFilter = {
  name: null,
  date_received: null,
  date_responded: null,
  deposition_date: null
};

const getFiltersValuesFromOptions = (values) => ({
  name: values.name ?? null,
  date_received: values.date_received ?? null,
  date_responded: values.date_responded ?? null,
  deposition_date: values.deposition_date ?? null
});

export const Depositions = ({
  isTabPage,
  page,
  disableRightActions = false,
  hiddenColumns,
  height = null,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},
  widgetFilters = {},
  updateFilters = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { openModal } = useModal();
  const isOpen = useSelector(({ globalUser }) => {
    return globalUser?.data?.layout?.[page]?.prevLayoutsOptions?.isOpen?.[props?.item?.i];
  });
  const { caseID, filter, addDeposition, applyFilter } = useContext(CaseDepositionsContext);
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const theme = useTheme();
  const [ filters, setFilters ] = useState({ ...initialFilter, ...widgetFilters?.depositions });
  const isMobile = width <= theme.breakpoints.values.tablet;

  const onFiltersUpdate = (values) => {
    setFilters(values);
    updateFilters({ depositions: values });
  };

  const createDeposition = () => {
    openModal(AddDepositionModal, {
      payload: {
        caseID
      },
      onModalResolved: (deposition) => {
        addDeposition(deposition);
      }
    });
  };

  const handleFiltersChange = (filter, applyFilter) => () => {
    openModal(FiltersBarModal, {
      payload: {
        title: 'Depositions Filters',
        initialValues: filters,
        fields: filterFields,
        setFilters: onFiltersUpdate
      },
      onModalResolved: (values) => {
        applyFilter(getFiltersValuesFromOptions(values));
      }
    });
  };

  const handleResetFilter = (applyFilter) => () => {
    applyFilter(initialFilter);
    onFiltersUpdate(initialFilter);
  };

  useEffect(() => {
    if (!isEmpty(widgetFilters?.depositions)) {
      applyFilter(getFiltersValuesFromOptions(widgetFilters?.depositions));
    }
  }, [ isOpen ]);

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      disableDivider={disableRightActions}
      title="Depositions"
      leftActions={[
        <SvgIcon color="inherit" fontSize="small">
          <Icon path={mdiCalendarClockOutline} />
        </SvgIcon>
      ]}
      icon={(
        <Tooltip title="Add Deposition">
          <Fab
            color="primary"
            size="small"
            onClick={createDeposition}
          >
            <AddIcon fontSize="small" />
          </Fab>
        </Tooltip>
      )}
      endIcon={(
        <Box display="flex" alignItems="center">
          {(!isTabPage || width <= 1000) &&
            <>
              <Tooltip title="Set Filter">
                <IconButton
                  color="primary"
                  onClick={handleFiltersChange(filter, applyFilter)}
                >
                  <Badge
                    invisible={isEmptyDeep(filter)}
                    badgeContent={
                      <Tooltip title="Filter is used">
                        <SvgIcon fontSize="small" color="success">
                          <CheckCircleIcon/>
                        </SvgIcon>
                      </Tooltip>
                    }
                  >
                    <SvgIcon fontSize="small">
                      <Icon path={mdiFilter}/>
                    </SvgIcon>
                  </Badge>
                </IconButton>
              </Tooltip>

              <Tooltip title="Reset Filter">
                <IconButton
                  color="primary"
                  onClick={handleResetFilter(applyFilter)}
                >
                  <BackupIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            </>
          }
        </Box>
      )}
      rightActions={!disableRightActions && [
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box
        ref={rootRef}
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="hidden"
      >
        {isMobile ? <Grid /> : <List width={width} />}
      </Box>
    </ContentCard>
  );
};
