import { makeStyles, Typography, SvgIcon } from '@material-ui/core';
import UnknownIcon from '../../../../../../../components/icons/mimetypes/unknown.svg';
import { PreviewContent } from '../PreviewContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const NoFileSelected = () => {
  const classes = useStyles();

  return (
    <PreviewContent>
      <div className={classes.root}>
        <Typography variant="h2" className={classes.title}>
          No file selected
        </Typography>

        <SvgIcon className={classes.icon}>
          <UnknownIcon />
        </SvgIcon>
      </div>
    </PreviewContent>
  );
};
