import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { filtersKeysMap } from '../../../../../store/lastFilters';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { InfiniteGridLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { CompaniesContext } from '../CompaniesContext';
import { Card } from './Card';

export const Grid = ({ hiddenColumns = [], onCompanySelect }) => {
  const {
    isFetched,
    isFetching,
    companies,
    meta: { last_page, page },
    loadNextPage,
    resetCompanies
  } = useContext(CompaniesContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const scrollElementRef = useRef();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filtersKeysMap.contacts_companies]);

  useEffect(() => {
    if (isEmpty(lastFilters)) {
      resetCompanies();
    }
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={isMobile ? 1 : 3} pr={0}>
        <Loader loading={!isFetched} render={
          () => !companies.length ? (
            <Typography align="center">No companies found</Typography>
          ) : (
            <InfiniteGridLoader
              items={companies}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={{ last_page, page }}
              CellComponent={Card}
              CellProps={{ onCompanySelect, hiddenColumns }}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={isMobile ? 300 : 470}
              cellHeight={isMobile ? 340 : 375}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
