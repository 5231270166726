import { forwardRef, useState } from 'react';
import cn from 'classnames';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles, IconButton } from '@material-ui/core';
import { DatePickerBase } from '../DatePickerBase';
import { Toolbar } from './Toolbar';
import { styles } from './styles';

export const getWeekLabelByDate = (date) => {
  if (!date?.isValid()) {
    return;
  }

  const startDay = date.clone().startOf('week').format('D');
  const endDay = date.clone().endOf('week').format('D');
  const startMonth = date.clone().startOf('week').format('MMM');
  const endMonth = date.clone().endOf('week').format('MMM');
  const isEqualMonths = startMonth === endMonth;
  const startYear = date.clone().startOf('week').format('YYYY');
  const endYear = date.clone().endOf('week').format('YYYY');
  const isEqualYears = startYear === endYear;
  const startWeekLabel = `${startMonth} ${startDay}${isEqualYears ? '' : ', ' + startYear}`;
  const endWeekLabel = `${isEqualMonths ? '' : endMonth + ' '}${endDay}, ${endYear}`;

  return `${startWeekLabel} - ${endWeekLabel}`;
};

const useStyles = makeStyles(styles);

export const WeekPicker = forwardRef(({
  name,
  value: valueProp,
  outputFormat = 'X',
  onChange = () => {},

  ...props
}, ref) => {
  const classes = useStyles();
  const [ value, setValue ] = useState(null);

  const handleChange = (date) => {
    const formattedDate = (outputFormat === 'X' ? date?.startOf('day')?.unix() : date?.format(outputFormat)) || null;

    setValue(date.startOf('week'));
    onChange(formattedDate, date);
  };

  const formatWeekSelectLabel = (date, invalidLabel) => {
    return getWeekLabelByDate(date) || invalidLabel;
  };

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const dateClone = date.clone();
    const selectedDateClone = selectedDate.clone();
    const start = selectedDateClone.startOf('week').toDate();
    const end = selectedDateClone.endOf('week').toDate();
    const dayIsBetween = dateClone.isBetween(start, end, null, []);
    const isFirstDay = dateClone.isSame(start, 'day');
    const isLastDay = dateClone.isSame(end, 'day');
    const wrapperClassName = cn({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });
    const dayClassName = cn(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });

    return (
      <div className={wrapperClassName}>
        <IconButton disabled={!dayInCurrentMonth} className={dayClassName}>
          <span>{dateClone.format('DD')}</span>
        </IconButton>
      </div>
    );
  };

  return (
    <DatePickerBase
      autoOk
      name={name}
      value={valueProp || value}
      Component={DatePicker}
      renderDay={renderWrappedWeekDay}
      labelFunc={formatWeekSelectLabel}
      ToolbarComponent={Toolbar}
      TextFieldComponentProps={{
        InputProps: {
          inputComponent: ({ value, inputRef, ...props }) => {
            return <div {...props}>{value}</div>;
          }
        }
      }}
      onChange={handleChange}

      {...props}

      ref={ref}
    />
  );
});
