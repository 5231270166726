import { Box, Typography } from '@material-ui/core';
import { TextField } from '../../../../../../../components/FormField';

export const TreatmentPlan = () => {
  return (
    <>
      <Box mt={3}>
        <Typography variant="h4">
          Treatment & Plan
        </Typography>
      </Box>

      <TextField
        fullWidth
        multiline
        disableUnderline
        rows={4}
        name="forms.treatment_plan.text"
        variant="filled"
      />
    </>
  );
};
