import { Box } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import { ContentCard } from '../../../../components/ContentCard';
import { Cases as CasesComponent } from '../../../../components/contacts';
import { CasesContextProvider, CasesFilterContextProvider } from '../../CasesPage/Cases/CasesContext';

export const Cases = ({ filter }) => {
  const initialState = { filter };

  return (
    <CasesFilterContextProvider>
      <CasesContextProvider initialState={initialState}>
        <ContentCard
          fullHeight
          disableContentPaddings
          title="Cases"
          component={Box}
          minHeight={630}
          leftActions={[
            <Box mr={1}>
              <WorkIcon color="inherit" fontSize="small" />
            </Box>
          ]}
        >
          <CasesComponent />
        </ContentCard>
      </CasesContextProvider>
    </CasesFilterContextProvider>
  );
};
