import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';

const styles= ({
  spacing,
  palette: { primary },
  typography: { fontWeightMedium, pxToRem },
  shape: { borderRadius }
}) => ({
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 20,
    paddingLeft: spacing(),
    paddingRight: spacing(),
    borderRadius,
    fontSize: pxToRem(12),
    fontWeight: fontWeightMedium,
    color: primary.contrastText,
    backgroundColor: primary.main
  }
});

const useStyles = makeStyles(styles);

export const SingleValue = ({ children, className }) => {
  const classes = useStyles();

  return (
    <Typography
      component="span"
      className={cn(classes.label, className)}
    >
      {children}
    </Typography>
  );
};
