export const initialValues = {
  name: null,
  description: null,
  owner_case_user_role: null,
  users_case_user_roles: null,
  due_at_case_date: null,
  due_at_interval: 0,
  status_id: null,
  type: null,
  priority: null,
  estimated_time: null,
  work_status: null,
  hours: null,
  minutes: null,
  todo_items: []
};
