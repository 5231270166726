import { Box, Typography } from '@material-ui/core';
import { getUserFullName } from '../../../../../../../../helpers/users';
import { UserAvatar } from '../../../../../../../../components/users';
import { Divider } from '../../../../../../../../components/Divider';

export const Header = ({ contact }) => {
  const { contact: user } = contact;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      px={3}
    >
      <Box mb={2}>
        <UserAvatar size="xxxl" user={user} />
      </Box>

      <Typography gutterBottom variant="h6" align="center">
        {getUserFullName(user)}
      </Typography>

      <Typography
        variant="subtitle2"
        align="center"
        color="info"
      >
        {user.role ? user.role.toUpperCase() : '-'}
      </Typography>

      <Typography variant="h5">
        {user?.office?.name || '-'}
      </Typography>

      <Box width="100%">
        <Divider gutter={2} />
      </Box>
    </Box>
  );
};
