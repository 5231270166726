import cn from 'classnames';
import { Box, makeStyles, SvgIcon, useMediaQuery, useTheme, Button } from '@material-ui/core';
import { mdiFileReplaceOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../components/ModalsProvider';
import { AppBar } from '../../../../../components/AppBar';
import { SelectAvailableFilesModal } from '../../../files-common';
import { styles } from '../../MyFilesPage/ActionsBar/styles';
import { GeneratePdfModal } from '../GeneratePdfModal';

const useStyles = makeStyles(styles);

export const ActionsBar = ({ documents, onFilesSelect = () => {} }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const addFiles = () => {
    openModal(SelectAvailableFilesModal, {
      payload: {
        disableCoping: true,
        filter: {
          aggregate_type: 'pdf'
        }
      },

      onModalResolved: (files) => {
        onFilesSelect(files);
      }
    });
  };

  const generateNewPdf = () => {
    openModal(GeneratePdfModal, {
      payload: {
        documents
      }
    });
  };

  return (
    <AppBar ToolbarProps={{ className: cn(classes.root, classes.rootMergePdf) }}>
      <Box minWidth={110}>
        <Button
          size={isTablet ? 'small' : 'medium'}
          variant="contained"
          color="primary"
          startIcon={<AddIcon/>}
          onClick={addFiles}
        >
          Add files
        </Button>
      </Box>

      <div className={classes.rightSide}>
        <Button
          size={isTablet ? 'small' : 'medium'}
          variant="contained"
          color="primary"
          startIcon={<SvgIcon><Icon path={mdiFileReplaceOutline} /></SvgIcon>}
          onClick={generateNewPdf}
        >
          Generate
        </Button>
      </div>
    </AppBar>
  );
};
