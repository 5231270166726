import { useFormikContext } from 'formik';
import { makeStyles, List as MuiList, Collapse } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../../components/IconButton';
import { TextField } from '../../../../../../../../components/FormField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const columnsWidths = {
  name: 180,
  dosage: 180,
  frequency: 180,
  action: 45
};

export const Medications = ({ arrayHelpers, onAddFields }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { values } = useFormikContext();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  return (
    <Collapse
      unmountOnExit
      in={!!values?.forms?.past_medical_history?.medications?.length}
      timeout="auto"
    >
      {!!values?.forms?.past_medical_history?.medications?.length &&
        <>
          <div className={classes.root}>
            <ListRow header className={classes.header}>
              <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                Medication Name
              </ListRowCell>

              <ListRowCell grow flexBasis={columnsWidths.dosage}>
                Dosage
              </ListRowCell>

              <ListRowCell flexBasis={columnsWidths.frequency}>
                Frequency
              </ListRowCell>
              <ListRowCell flexBasis={columnsWidths.action}/>
            </ListRow>

            <MuiList disablePadding>
              {values?.forms?.past_medical_history?.medications?.map((medication, index) => (
                <ListRow key={index}>
                  <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                    <TextField
                      fullWidth
                      zeroMinWidth
                      disableUnderline
                      placeholder="Add..."
                      name={`forms.past_medical_history.medications.${index}.medications`}
                    />
                  </ListRowCell>

                  <ListRowCell noWrap grow flexBasis={columnsWidths.dosage}>
                    <TextField
                      fullWidth
                      zeroMinWidth
                      disableUnderline
                      placeholder="Add..."
                      name={`forms.past_medical_history.medications.${index}.dosage`}
                    />
                  </ListRowCell>

                  <ListRowCell flexBasis={columnsWidths.frequency}>
                    <TextField
                      fullWidth
                      zeroMinWidth
                      disableUnderline
                      placeholder="Add..."
                      name={`forms.past_medical_history.medications.${index}.frequency`}
                      onKeyDown={onAddFields(arrayHelpers, {
                        medications: '',
                        dosage: '',
                        frequency: ''
                      })}
                    />
                  </ListRowCell>

                  <ListRowCell noWrap flexBasis={columnsWidths.action}>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={handleClearField(arrayHelpers, index)}
                    >
                      <ClearIcon fontSize="small"/>
                    </IconButton>
                  </ListRowCell>
                </ListRow>
              ))}
            </MuiList>
          </div>
        </>
      }
    </Collapse>
  );
};
