export const FETCH_CODES_REQUEST = 'CASES_CODES_REQUEST';
export const FETCH_CODES_SUCCESS = 'CODES_FETCH_SUCCESS';
export const RESET_CODES = 'RESET_CODES';
export const ADD_CUSTOM_CODE = 'ADD_CUSTOM_CODE';
export const UPDATE_CODE_IN_LIST = 'UPDATE_CODE_IN_LIST';
export const DELETE_CODE_SUCCESS = 'DELETE_CODE_SUCCESS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
export const MASS_DELETE = 'MASS_DELETE';
