import moment from 'moment';
import { columnWidth } from '../columnWidthMap';
import { ListRow, ListRowCell } from '../../ListRow';

export const Row = ({ npiRecord, onNpiRecordSelected }) => {
  const handleClick = () => {
    onNpiRecordSelected(npiRecord);
  };

  return (
    <ListRow button onClick={handleClick}>
      <ListRowCell grow noWrap flexBasis={columnWidth.name}>
        {(npiRecord.basic.name_prefix?.trim() || '') + npiRecord.basic.name?.trim()}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnWidth.number}>
        {npiRecord.number || ''}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnWidth.enumeration_date}>
        {moment(npiRecord.basic.enumeration_date).format('L')}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnWidth.status}>
        {npiRecord.basic.status?.trim()}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnWidth.last_updated}>
        {moment(npiRecord.basic.last_updated).format('L')}
      </ListRowCell>
    </ListRow>
  );
};
