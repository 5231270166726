import { Dialog, SvgIcon } from '@material-ui/core';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { FiltersBar } from '../../../../../app/Dashboard/MedicalReports/Diagnosis/FiltersBar';
import { fieldsKeysMap } from '../../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/FormFiltersBar';
import { filtersKeysMap } from '../../../../../store/lastFilters';
import { ModalContainer, ModalHeader, ModalBody } from '../../../../Modal';
import { ReportsProvider } from '../ReportsProvider';
import { columnsMap, DiagnosisList } from '../DiagnosisList';
import { Row } from './Row';

const hiddenColumns = [
  columnsMap.checkbox,
  columnsMap.actions
];

const hiddenFilterFields = [
  fieldsKeysMap.patients
];

export const DiagnosisSelectModal = ({
  payload: {
    filter = {}
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  return (
    <Dialog maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader
          icon={<SvgIcon><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>}
          onClose={handleModalReject}
        >
          Diagnosis Reports
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <ReportsProvider filter={filter}>
            <FiltersBar
              hiddenFilterFields={hiddenFilterFields}
              filterKey={filtersKeysMap.diagnosis}
            />

            <DiagnosisList
              autoHeight
              hiddenColumns={hiddenColumns}
              Row={Row}
              RowProps={{ onSelect: handleModalResolve }}
            />
          </ReportsProvider>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
