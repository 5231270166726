import { allClaimsStatuses } from '../../../../../../../components/claims';
import {
  TextField,
  Autocomplete,
  OfficesLocationSelect,
  KeyboardDatePicker
} from '../../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../../components/users';

export const getFilterFields = () => {
  return [
    <TextField
      name="claim_number"
      label="Claim #"
    />,

    <KeyboardDatePicker
      clearable
      name="appointment_from"
      label="DOS From"
    />,

    <KeyboardDatePicker
      clearable
      name="appointment_to"
      label="DOS To"
    />,

    <OfficesLocationSelect
      multiple
      formattedValue={false}
      name="offices"
      label="Offices"
    />,

    <UsersSelect
      multiple
      disableValueTransform
      name="providers"
      label="Providers"
      params={{ role: 'doctor' }}
    />,

    <Autocomplete
      multiple
      name="statuses"
      label="Claim Status"
      options={allClaimsStatuses}
    />
  ];
};

export const getFiltersValues = (filters) => {
  return {
    claim_number: filters?.claim_number || null,
    appointment_from: filters?.appointment_from || null,
    appointment_to: filters?.appointment_to || null,
    offices: !!filters?.offices?.length ? filters?.offices?.map(({ value }) => value) : null,
    providers: !!filters?.providers?.length ? filters?.providers?.map(({ id }) => id) : null,
    statuses: filters?.statuses || null
  };
};
