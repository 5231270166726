import { useState, useRef } from 'react';
import { uniqueId } from 'lodash';
import { Box, Typography, Paper, useTheme, useMediaQuery } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useModal } from '../../../../components/ModalsProvider';
import { ScrollbarsCustom } from '../../../../components/ScrollbarsCustom';
import { Sticky } from '../../../../components/Sticky';
import { moveArrayItem } from '../../../../helpers/moveArrayItem';
import { ActionsBar } from './ActionsBar';
import { TableHeader } from './TableHeader';
import { pagesSelectingTypesMap } from './pagesSelectingTypes';
import { Row } from './Row';

const getDraggableStyle = (style) => {
  if (style.transform) {
    const axisLockY = `translate(0px, ${style.transform.split(',').pop()}`;

    return {
      ...style,
      transform: axisLockY
    };
  }

  return style;
};

export const MergePdf = () => {
  const { openModal } = useModal();
  const scrollElementRef = useRef();
  const [ documents, setDocuments ] = useState([]);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const handleFilesSelect = (newFiles) => {
    setDocuments((documents) => documents.concat(newFiles?.reduce((newDocuments, file) => {
      return newDocuments.concat({
        _id: uniqueId('doc_'),
        id: file.id,
        file,
        pagesSelectingType: pagesSelectingTypesMap.all,
        pages: [],
        pagesList: file.pages && Array.from({ length: file.pages }, (_, index) => index + 1),
        pagesOptions: file.pages && Array.from({ length: file.pages }, (_, index) => {
          const page = index + 1;

          return {
            value: page,
            label: `Page ${page}`
          };
        })
      });
    }, [])));
  };

  const handleDragEnd = ({ source, destination }) => {
    if (destination) {
      setDocuments((documents) => moveArrayItem(documents, source?.index, destination?.index));
    }
  };

  const handleDocumentChange = (changedIndex, changedDocument) => {
    setDocuments((documents) => documents.map((document, index) => {
      return index === changedIndex ? changedDocument : document;
    }));
  };

  const removeDocuments = (removingIndex) => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'The document will only be removed from this list'
      },

      onModalResolved: () => {
        setDocuments((documents) => documents.filter((document, index) => index !== removingIndex));
      }
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ActionsBar documents={documents} onFilesSelect={handleFilesSelect} />

      <ScrollbarsCustom scrollElementRef={scrollElementRef} style={{ flexGrow: 1 }}>
        <Box
          display="flex"
          flexDirection="column"
          pl={isTablet ? 1 : 4}
          pr={isTablet ? 1 : 3}
          py={isTablet ? 0 : 2}
          flexGrow={1}
          overflow="hidden"
        >
          {!documents?.length ? (
            <Box p={2}>
              <Typography align="center">
                Select the files, arrange them in the desired order,
                <br />
                and set the desired pages
              </Typography>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {documents.map((document, index) => (
                        <Draggable
                          key={document._id}
                          draggableId={document._id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getDraggableStyle(provided.draggableProps.style)}
                            >
                              <Paper elevation={snapshot.isDragging ? 4 : 0}>
                                <Row
                                  document={document}
                                  documents={documents}
                                  index={index}
                                  dragHandleProps={provided.dragHandleProps}
                                  onDocumentRemove={removeDocuments}
                                  onDocumentChange={handleDocumentChange}
                                />
                              </Paper>
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          )}
        </Box>
      </ScrollbarsCustom>
    </Box>
  );
};
