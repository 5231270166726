export const styles = ({ spacing }) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    padding: spacing(2)
  },

  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gridGap: spacing(2),
    marginBottom: spacing(2)
  }
});
