import { useContext } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CalendarIcon from '@material-ui/icons/Event';
import {
  SignedUsers
} from '../../../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/Documents/SignedUsers';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../helpers/dom';
import { medicationsListToString } from '../../../../../../utils/medicationsListToString';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../ListRow';
import { Tooltip, TooltipTitle } from '../../../../../Tooltip';
import { IconButton } from '../../../../../IconButton';
import { UserLink } from '../../../../../UserLink';
import { Loader } from '../../../../../Loader';
import { Popper } from '../../../../../Popper';
import { Date } from '../../../../../Date';
import { ReportsContext } from '../../ReportsProvider';
import { ReportsMenu } from '../../ReportsMenu';
import {
  columnsMap,
  columnsWidths,
  widthBreakpointSmall,
  widthBreakpointMedium,
  widthBreakpointLarge,
  widthBreakpointXS
} from '../DiagnosisList';

export const DiagnoseRow = ({
  report = {},
  isLoaded,
  width,
  disablePatient = false,
  hiddenColumns = [],
  ListRowProps = {},
  onClick = () => {}
}) => {
  const { selectedIDs, toggleItemSelection } = useContext(ReportsContext);
  const isSelected = selectedIDs.indexOf(report.id) !== -1;
  const diagnosis = medicationsListToString(report.diagnosis || []);

  const handleItemSelection = () => {
    toggleItemSelection(report.id);
  };

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow
      button
      selected={isSelected}
      onClick={onClick}

      {...ListRowProps}
    >
      {!hasRole(rolesMap.client, rolesMap.patient) && !hiddenColumns.includes(columnsMap.checkbox) &&
        <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />
      }

      {!disablePatient && (
        <ListRowCell grow flexBasis={columnsWidths.patient}>
          <UserLink
            disablePreview={!report?.patient}
            disableLink={!report?.patient}
            noWrap
            size="md"
            variant="inherit"
            user={report?.patient}
          />
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.date}>
        <Box mr={1}>
          <CalendarIcon fontSize="small" />
        </Box>

        {moment.unix(report.updated_at || report.created_at).format('L')}
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointLarge} flexBasis={columnsWidths.type}>
        <Typography noWrap title={report.type}>
          {report.type}
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.diagnosis}>
        <Tooltip
          isExistTooltip={!!report?.diagnosis?.length}
          title={report?.diagnosis?.map((item) => (
            <TooltipTitle key={item.id} itemTitle={item.code}>
              {item.description &&
                <Typography>- {item.description}</Typography>
              }
            </TooltipTitle>
          ))}
        >
          <Typography noWrap>{diagnosis || '-'}</Typography>
        </Tooltip>
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.doi_date}>
          <Date disableMoment date={report?.general_data?.doi_string} iconFontSize="small" />
        </ListRowCell>
      }

      <ListRowCell grow={width < widthBreakpointMedium} flexBasis={columnsWidths.provider}>
        <UserLink
          noWrap
          size="md"
          user={report.provider}
        />
      </ListRowCell>

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={columnsWidths.signed}>
          <SignedUsers document={report} />
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.actions) &&
        <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                  <MoreVertIcon />
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <ReportsMenu report={report} onClose={handleClose} />
            )}
          </Popper>
        </ListRowCell>
      }
    </ListRow>
  );
};
