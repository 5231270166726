import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { OfficeLink } from '../../../../../../../components/OfficeLink';
import { IconButton } from '../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../components/UserLink';
import { Popper } from '../../../../../../../components/Popper';
import { minRowHeight, columnsWidths } from '../List';
import { TemplatesMenu } from '../TemplatesMenu';

export const Row = ({ template }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListRow minHeight={minRowHeight}>
      <ListRowCell column grow flexBasis={columnsWidths.name}>
        <Typography noWrap variant="h5">{template.name}</Typography>

        <Typography noWrap color="textSecondary">
          {template.text}
        </Typography>
      </ListRowCell>

      <ListRowCell grow={isMobile} flexBasis={columnsWidths.provider}>
        {!isMobile ? (
          <UserLink
            noWrap
            color="textPrimary"
            user={template.provider}
          />
        ) : (
          <UserLink noWrap variant="h5" user={template.provider}>
            <OfficeLink noWrap color="textPrimary" office={template.office} />
          </UserLink>
        )}
      </ListRowCell>

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.office}>
          <OfficeLink noWrap color="textPrimary" office={template.office} />
        </ListRowCell>
      )}

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <TemplatesMenu template={template} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
