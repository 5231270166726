import { useRef, useContext, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { InfiniteGridLoader } from '../../../InfiniteLoader';
import { Loader } from '../../../Loader';
import { WorkSessionsContext } from '../../WorkSessionsProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    workSessions,
    isFetched,
    isFetching,
    meta,
    loadNextPage,
    resetWorkSessions
  } = useContext(WorkSessionsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetWorkSessions();
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%" pt={1} pl={2} pr={0} pb={0}>
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
        <Loader p={2} loading={!isFetched} render={
          () => !workSessions.length ? (
            <Box p={1}>
              <Typography align="center">No sessions found</Typography>
            </Box>
          ) : (
            <InfiniteGridLoader
              items={workSessions}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={meta}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={300}
              cellHeight={280}
            />
          )}
        />
      </CustomScrollbars>
    </Box>
  );
};
