export const styles = ({
  spacing,
  palette: { primary, divider },
  transitions,
  shadows
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    border: `1px solid ${divider}`,
    overflow: 'hidden',
    cursor: 'pointer',
    transition: transitions.create(),
    padding: spacing(1, 2),

    '&:hover': {
      borderColor: primary.main,
      boxShadow: shadows[5]
    }
  }
});
