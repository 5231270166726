import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, FormControlLabel, SvgIcon, Typography } from '@material-ui/core';
import { mdiDraw } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as reportsApi from '../../../../../../api/profile';
import * as usersApi from '../../../../../../api/users';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { Checkbox, CertificatesSelect } from '../../../../../../components/FormField';
import { Loader } from '../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../components/Modal';

export const SignatureModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { formID, providerID, onUpdate = () => {} }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ initialValues, setInitialValues ] = useState(null);

  const signReport = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return reportsApi.signReport({ id: formID, ...values }).then(({ data }) => {
      enqueueSnackbar('Successfully update info', { variant: 'success' });
      onUpdate(true);
      handleModalResolve(data?.signature);
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  const toggleCertificate = (setFieldValue) => (event) => {
    const checked = event?.target?.checked;

    setFieldValue('certificate_id', !checked && null);
  };

  useEffect(() => {
    providerID && usersApi.fetchUser(providerID).then(setInitialValues);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        model_type: 'diagnosis_report',
        model_id: formID,
        type: rolesMap.doctor,
        sign_type: 'electronic_signature',
        is_certificate: false
      }}
      onSubmit={signReport}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiDraw} /></SvgIcon>} onClose={handleModalReject}>
              Sign a document
            </ModalHeader>

            <ModalBody>
              {providerID ? (
                initialValues?.signature?.id ?
                  <>
                    <FormControlLabel
                      label="Sign with certificate"
                      control={
                        <Checkbox
                          name="is_certificate"
                          color="primary"
                          onChange={toggleCertificate(setFieldValue)}
                        />
                      }
                    />

                    <CertificatesSelect isDisabled={!values.is_certificate} />
                  </>
                  :
                  <Typography color="textSecondary">No signature found</Typography>
              ) : (
                <Typography color="textSecondary">No provider found</Typography>
              )}
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    save & sign
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
