import { mdiBellCogOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as remindersApi from '../../../../../api/cases/reminders/groups';
import { errorsMap } from '../../../../../utils/validation';
import { ModalFooter, ModalHeader, ModalBody } from '../../../../Modal';
import { Loader } from '../../../../Loader';
import { ReminderGroupForm, initialValues, validationSchema } from '../ReminderGroupForm';

export const CreateReminderGroupModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const addReminderGroup = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return remindersApi.createCaseReminderGroup(values).then((group) => {
      enqueueSnackbar('Reminders group successfully created', { variant: 'success' });
      handleModalResolve(group);
    }).catch(({ data: { errors } }) => {
      if (errors?.name?.includes(errorsMap.unique)) {
        setErrors({ name: 'Group already exists' });
      } else if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Reminders group not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={addReminderGroup}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiBellCogOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Add reminder group
            </ModalHeader>

            <ModalBody>
              <ReminderGroupForm />
            </ModalBody>

            <ModalFooter>
              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
