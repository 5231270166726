import { Box } from '@material-ui/core';
import { Members } from '../../../../../../../components/Members';

export const Footer = ({ office }) => {
  return (
    <Box mt={2.5} px={3}>
      <Members
        space={0.5}
        placement="bottom"
        users={office.users || []}
      />
    </Box>
  );
};
