export const statusTitleMap = {
  hypotension: 'hypotension',
  normal: 'normal',
  prehypertension: 'prehypertension',
  hypertension: 'hypertension'
};

export const statusMap = [
  {
    value: 'hypotension',
    label: 'Hypotension'
  },

  {
    value: 'normal',
    label: 'Normal'
  },

  {
    value: 'prehypertension',
    label: 'Prehypertension'
  },

  {
    value: 'hypertension',
    label: 'Hypertension'
  }
];

export const getStatus = (pressure) => {
  return statusMap.find(({ value }) => value === (
    pressure < 120
      ? statusTitleMap.hypotension
      : pressure < 130
        ? statusTitleMap.normal
        : pressure < 140
          ? statusTitleMap.prehypertension
          : statusTitleMap.hypertension
  ));
};
