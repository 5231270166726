export const styles = ({
  spacing,
  palette: { text, grey, divider },
  typography: { fontWeightMedium }
}) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%'
  },

  leftSidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: 960,
    overflow: 'hidden'
  },

  rightSidebar: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: 320,
    backgroundColor: grey[50]
  },

  preview__header: {
    display: 'flex',
    height: 90,
    marginBottom: spacing(2),
    paddingLeft: spacing(2),
    borderBottom: `1px solid ${divider}`
  },

  preview__subTitle: {
    color: grey[500]
  },

  preview__button: {
    textTransform: 'capitalize',
    marginLeft: spacing(-1.5)
  },

  preview__grid: {
    paddingLeft: spacing(3)
  }
});
