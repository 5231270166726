export const styles = ({ spacing, breakpoints }) => ({
  rootContent: {
    overflow: 'hidden'
  },

  actionsBackButton: {
    marginRight: spacing(1.5)
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: spacing(1, 4, 3, 4)
  },

  footerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    maxHeight: 300,
    flexGrow: 1,
    maxWidth: '50%'
  },

  footerContent: {
    width: '100%',
    marginTop: spacing(2)
  },

  footerTitle: {
    paddingLeft: spacing(3),
    paddingBottom: spacing()
  },

  invoiceContent: {
    margin: spacing(1, 6, 4, 4),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      margin: 0
    }
  }
});
