import { useContext, useEffect } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Members } from '../../../../../../../../components/Members';
import { Loader } from '../../../../../../../../components/Loader';
import { Label } from '../../../../../../../../components/Label';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const theme = useTheme();
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;
  const owner = params?.users?.find(({ case_role }) => case_role === 'creator');

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.name}>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography noWrap>{params?.file_number || '-'}</Typography>
          <Typography noWrap>{params?.name || '-'}</Typography>
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.createdDate}>
        {params?.created_from ? moment(params?.created_from).format('L') : '-'}
        <br />
        {params?.created_to ? moment(params?.created_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        <Box display="flex" flexDirection="column">
          {!params.statuses?.length ? '-' : params?.statuses?.map((status) => (
            <Box key={status?.id}>
              <Label
                label={status?.name}
                color={theme.palette.primary.main}
              />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.roles}>
        <Box display="flex" flexDirection="column" width="100%">
          {params?.case_roles?.map((role) => (
            <Typography key={role?.label}>{role?.label}</Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.members}>
        <Members
          noWrap
          isParent
          maxVisible={6}
          owner={owner}
          users={params?.users}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.types}>
        <Box display="flex" flexDirection="column" width="100%">
          {params?.types?.map((type) => (
            <Typography noWrap key={type?.name}>{type?.name}</Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.orderBy}>
        {params.order_by.label}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

