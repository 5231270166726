import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { makeStyles, Collapse, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { TextField } from '../../../FormField';
import { IconButton } from '../../../IconButton';
import { useModal } from '../../../ModalsProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Allergies = ({ arrayHelpers, onAddFields }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { values } = useFormikContext();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  return (
    <Collapse
      unmountOnExit
      in={!!get(values, 'forms.medical_history.allergies_or_reactions.length')}
      timeout="auto"
      className={classes.root}
    >
      {!!get(values, 'forms.medical_history.allergies_or_reactions.length') &&
          values.forms.medical_history.allergies_or_reactions.map((contact, index) => (
            <div key={index} className={classes.row}>
              <Typography>{index + 1}.</Typography>

              <TextField
                fullWidth
                placeholder="Allergy..."
                name={`forms.medical_history.allergies_or_reactions.${index}.name`}
                className={classes.textField}
                margin="dense"
                onKeyDown={onAddFields(arrayHelpers, { name: '' })}
              />

              <IconButton
                size="small"
                color="error"
                onClick={handleClearField(arrayHelpers, index)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </div>
          )
          )}
    </Collapse>
  );
};
