import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import { getTimezoneForUtcOffset } from '../../../../helpers/dates';
import {
  TimeZoneSelect,
  TextField,
  PhoneField,
  AddressAutocomplete,
  UnitDesignator,
  transformTimeZoneToOption
} from '../../../../components/FormField';

export const Address = ({ title }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddressChange = (option, googlePlaceEntity) => {
    if (option) {
      const timezone = getTimezoneForUtcOffset(option.country, googlePlaceEntity.utc_offset_minutes);

      setFieldValue('street', (option.street_number ? option.street_number + ' ' : '') + option.street);
      setFieldValue('state', option.state);
      setFieldValue('city', option.city);
      setFieldValue('zip', option.zip);
      setFieldValue('country', option.country);
      setFieldValue('timezone', transformTimeZoneToOption(timezone));
      setFieldValue('lat', option.lat);
      setFieldValue('lng', option.lng);
    }
  };

  return (
    <Box my={7}>
      <Typography gutterBottom variant="h3">{title}</Typography>

      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <AddressAutocomplete
            name="street"
            label="Street"
            placeholder="Enter street..."
            onChange={handleAddressChange}
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <UnitDesignator
            required={!!values.unit_number}
            name="designator"
            label="Unit Designator"
            placeholder="Select designator..."
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <TextField
            zeroMinWidth
            name="unit_number"
            label="Apt./Suite/Room"
            placeholder="Enter apt/suite/room..."
          />
        </Grid>

        <Grid item sm={4} xs={8}>
          <TextField
            zeroMinWidth
            name="city"
            label="City"
            placeholder="Enter city..."
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <TextField
            zeroMinWidth
            name="state"
            label="State/Region"
            placeholder="Enter state or region..."
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <TextField
            zeroMinWidth
            name="zip"
            label="ZIP Code"
            placeholder="Enter Zip Code..."
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            zeroMinWidth
            name="country"
            label="Country"
            placeholder="Enter country..."
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} component={Box} pt={5}>
        <Grid item sm={4} xs={12}>
          <PhoneField
            required
            zeroMinWidth
            name="phone"
            label="Phone"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <PhoneField
            zeroMinWidth
            name="fax"
            label="Fax"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TimeZoneSelect name="timezone" />
        </Grid>

        <Grid item sm={4} xs={12}>
          <PhoneField
            zeroMinWidth
            name="mobile_phone"
            label="Mobile Phone"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            zeroMinWidth
            name="email"
            label="Email"
            placeholder="Enter email..."
          />
        </Grid>
      </Grid>
    </Box>
  );
};
