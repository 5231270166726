import { Box, makeStyles, Typography } from '@material-ui/core';
import { caseUserRolesTypesMap } from '../../../../dataMaps';
import { getUserFullName, getUserNameAbbr } from '../../../../helpers/users';
import { roleLabelsMap } from '../../roleLabelsMap';
import { Divider } from '../../../Divider';
import { Avatar } from '../../../Avatar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ disableOffice = false, user, role }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Avatar size="extraLarge" alt={getUserNameAbbr(user)} src={user.avatar}/>

      <Box pt={2}>
        <Typography gutterBottom variant="h2">{getUserFullName(user)}</Typography>
      </Box>

      <Typography gutterBottom variant="h5" className={classes.role}>
        {disableOffice ? caseUserRolesTypesMap[role] : roleLabelsMap[role]}
      </Typography>

      {!disableOffice && (
        user?.work?.offices?.[0]?.name ?
          <Typography gutterBottom variant="h5">
            {user?.work?.offices?.[0]?.name}
          </Typography>
          :
          <Typography color="textSecondary">No info.</Typography>
      )}

      <Box width="100%">
        <Divider gutter={2} />
      </Box>
    </Box>
  );
};
