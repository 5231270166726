import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { useResizeObserver } from '../../../../helpers/hooks/useResizeObserver';
import * as pages from '../../../../store/dashboard/layout/pages';
import { Widgets } from '../../../../components/Widgets';
import { hasRole } from '../../../../utils/hasRole';
import { cardsLayout, checkedMedicWidgetsTypes, checkedWidgetsTypes, extraTypes } from './Layouts';
import { widgetsMap } from './Widgets';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ProfileGeneralInfo = () => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const { user } = useSelector(({ profile }) => profile);
  const checkedWidgets = hasRole(rolesMap.medic, rolesMap.doctor)
    ? checkedMedicWidgetsTypes : checkedWidgetsTypes;
  const widgetsMedicMap = Object.keys(checkedMedicWidgetsTypes).reduce((object, key) => {
    if (!object[key]) {
      object[key] = widgetsMap[key];
    }

    return object;
  }, {});

  return (
    <div ref={rootRef} className={classes.root}>
      <Widgets
        width={width}
        rowHeight={25}
        page={pages.profile}
        extraTypes={extraTypes}
        checkedWidgetsTypes={checkedWidgets}
        widgetsMap={hasRole(rolesMap.medic, rolesMap.doctor) ? widgetsMedicMap : widgetsMap}
        cardsLayout={cardsLayout}
        payload={{ user }}
      />
    </div>
  );
};

