import { useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { makeStyles, Box, Tabs, Tab, AppBar as MuiAppBar, Typography, useMediaQuery } from '@material-ui/core';
import { Divider } from '../../../../../components/Divider';
import {
  AppointmentsFilterProvider,
  AppointmentsProvider,
  arrivalTypesMap
} from '../../../../../components/appointments';
import { ClaimsProvider } from '../../../../../components/claims';
import { handleAppointmentChange } from '../../../../../components/claims/ClaimForm';
import { Activity } from '../../../../../components/claims/Activity';
import { drawerWidth } from '../ClaimPreview/styles';
import { Appointments } from './Appointments';
import { Claims } from './Claims';
import { styles } from './styles';

export const columnsWidths = {
  date: 106,
  user: 120,
  claimNo: 120
};

const tabsMap = {
  appointments: 'appointments',
  claims: 'claims'
};

const dateNow = moment().unix();

const useStyles = makeStyles(styles);

export const SideBar = ({ width, disableAppointments, claim, sidebarIsOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ width: isMobile ? width : drawerWidth });
  const [ activeTab, setActiveTab ] = useState(disableAppointments ? tabsMap.claims : tabsMap.appointments);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setFieldValue, values } = disableAppointments ? {} : useFormikContext();
  const patientID = claim.patient_id?.id || claim.patient_id;
  const appointmentsFilter = {
    has_claims: 0,
    appointment_to: dateNow,
    relations: [ 'medicalProcedures', 'medicalForms' ]
  };
  const claimsFilter = {
    appointment_to: dateNow
  };

  appointmentsFilter.patients = patientID && [ patientID ];
  claimsFilter.patients = patientID && [ patientID ];

  const handleAppointmentSelect = (appointment) => {
    handleAppointmentChange(appointment, { setFieldValue });
  };

  const handleActiveTabChange = (event, index) => {
    setActiveTab(index);
  };

  return (
    <div className={cn(classes.root, !sidebarIsOpen && classes.collapsed)}>
      <MuiAppBar position="static">
        <Tabs
          value={activeTab}
          onChange={handleActiveTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {!disableAppointments &&
            <Tab value={tabsMap.appointments} label="Appointments" />
          }

          <Tab value={tabsMap.claims} label="Claims" />
        </Tabs>
      </MuiAppBar>

      <Box flexGrow={1}>
        {(activeTab === tabsMap.appointments) && !disableAppointments &&
          <AppointmentsFilterProvider>
            <AppointmentsProvider
              initialFilter={{ arrival_types: [ arrivalTypesMap.arrived ] }}
              filter={appointmentsFilter}
            >
              <Appointments
                selectedAppointment={values.appointment}
                onAppointmentSelect={handleAppointmentSelect}
              />
            </AppointmentsProvider>
          </AppointmentsFilterProvider>
        }

        {(activeTab === tabsMap.claims) &&
          <ClaimsProvider filter={claimsFilter}>
            <Claims claim={claim} />
          </ClaimsProvider>
        }
      </Box>

      {claim.id &&
        <>
          <Divider disableBottomGutter gutter={2} />

          <div className={classes.header}>
            <Typography variant="h4">Claim Tracking History</Typography>
          </div>

          <Box flexGrow={1}>
            <Activity claim={claim} />
          </Box>
        </>
      }
    </div>
  );
};
