import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog } from '@material-ui/core';
import LibraryAddIcon from '@material-ui/icons/LibraryAddOutlined';
import * as negotiateApi from '../../../../../../../../api/cases/negotiations';
import { Loader } from '../../../../../../../../components/Loader';
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContainer
} from '../../../../../../../../components/Modal';
import { NegotiationForm, initialValues, validationSchema } from '../NegotiationForm';

export const CreateNegotiationModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    caseItem,
    onCaseItemUpdate = () => {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = ({ caseItem, ...values }, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return negotiateApi.addNegotiation(values).then((data) => {
      enqueueSnackbar('Case negotiation successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case negotiation not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        caseID: caseItem?.id,
        caseItem,
        ...initialValues
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, dirty, handleSubmit }) => (
        <Dialog maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<LibraryAddIcon />}
              onClose={handleModalReject}
            >
              Add Negotiation
            </ModalHeader>

            <ModalBody>
              <NegotiationForm onCaseItemUpdate={onCaseItemUpdate} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting || !dirty}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
