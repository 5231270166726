import { Box, Grid, Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import { preventDefault } from '../../../../../../../helpers/dom';
import { getUserFullName } from '../../../../../../../helpers/users';
import { generateAddress } from '../../../../../../../utils/generateAddress';
import { InviteButton } from '../../../../../../../components/users/InviteButton';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { OfficeLink } from '../../../../../../../components/OfficeLink';
import { Contact } from '../../../../../../../components/Contact';
import { Divider } from '../../../../../../../components/Divider';
import { PreviewCompanyModal } from '../../../../Companies/PreviewCompanyModal';

export const Body = ({ contact }) => {
  const { openModal } = useModal();

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: contact?.work?.company?.id }
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={3}
      mt={5}
    >
      <Typography variant="h3" align="center">
        {getUserFullName(contact)}
      </Typography>

      <Typography variant="subtitle2" align="center" color="error">
        {contact.role ? contact.role.toUpperCase() : '-'}
      </Typography>

      <Grid container spacing={1} component={Box} py={2} alignItems="center">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Company Name
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <CompanyLink
            variant="h5"
            company={contact?.work?.company}
            onClick={preventDefault(openPreviewCompanyModal)}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Office Name
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <OfficeLink
            variant="h5"
            office={contact?.work?.offices?.[0]}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Start Date
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Box display="flex" alignItems="center">
            <Box component="span">
              <Typography variant="h5">
                {contact?.work?.start_date || '-'}
              </Typography>
            </Box>

            {contact?.work?.start_date && (contact?.work?.end_date ?
              <>
                <Box component="span" mx={1}>
                  <Typography variant="h5">-</Typography>
                </Box>

                <Typography variant="h5">{contact?.work?.end_date}</Typography>
              </>
              :
              <Box component="span" mx={1}>
                <Typography color="textSecondary">to today</Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={1} component={Box} py={2} alignItems="flex-start">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Offices Locations
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {contact?.work?.offices?.length ?
            contact?.work?.offices?.map((office) => (
              <Box key={office.id} display="flex" flexDirection="column" mb={1}>
                <OfficeLink
                  variant="h5"
                  office={office}
                />

                <Box display="flex" alignItems="flex-start" color="text.secondary">
                  <Box mr={0.5}>
                    <RoomIcon fontSize="small" color="inherit" />
                  </Box>

                  <Typography variant="body2" color="textSecondary">
                    {office?.full_address || generateAddress(office)}
                  </Typography>
                </Box>
              </Box>
            )) :
            <Typography color="textSecondary">No info.</Typography>
          }
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Main Office Venue
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {contact?.work?.offices?.[0]?.venue ?
            <Typography>
              {contact?.work?.offices?.[0]?.venue?.full_address}
            </Typography>
            :
            <Typography color="textSecondary">No info.</Typography>
          }
        </Grid>
      </Grid>

      <Grid container spacing={1} component={Box} py={2} alignItems="center">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Phone
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={contact.phone_number}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Mob number
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={contact.mobile_phone_number}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Fax
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={contact.fax}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Main Office Timezone
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {contact?.work?.offices?.[0]?.timezone ?
            <Typography variant="h5">
              {contact?.work?.offices?.[0]?.timezone}
            </Typography>
            :
            <Typography color="textSecondary">No info.</Typography>
          }
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={1} component={Box} pt={2} alignItems="center">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Email
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Box display="flex" alignItems="center">
            <Contact noWrap variant="h5" type="mailto" contact={contact.email} />
            <InviteButton contact={contact} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
