import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../../../../helpers/dom';
import { ServiceLineViewModal } from '../../../../../../../../../../components/claims';
import { useModal } from '../../../../../../../../../../components/ModalsProvider';
import { CardItem } from '../../../../../../../../../../components/Cards';

export const Card = ({ authorization, serviceLine }) => {
  const { openModal } = useModal();

  const viewServiceLine = () => {
    openModal(ServiceLineViewModal, {
      payload: {
        authorization,
        serviceLine
      }
    });
  };

  return (
    <CardItem p={1} onClick={stopPropagation(viewServiceLine)}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h5">Updated date</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {moment.unix(serviceLine.updated_at).format('L LT')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5">Certification revision</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {serviceLine.certification_revision_date ?
              moment(serviceLine.certification_revision_date, 'YYYY-MM-DD').format('L') : '-'
            }
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5">Last certification</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {serviceLine.last_certification_date ?
              moment(serviceLine.last_certification_date, 'YYYY-MM-DD').format('L') : '-'
            }
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5">Begin therapy</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {serviceLine.begin_therapy_date ?
              moment(serviceLine.begin_therapy_date, 'YYYY-MM-DD').format('L') : '-'
            }
          </Typography>
        </Grid>
      </Grid>
    </CardItem>
  );
};
