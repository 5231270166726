import { makeStyles, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ reminderEvent }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>{reminderEvent.description}</Typography>
      <Typography variant="body2" color="textSecondary">Description</Typography>
    </div>
  );
};
