import {
  Box,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Checkbox, TextField } from '../../FormField';

export const FamilyHistory = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container spacing={isSmallScreen ? 2 : 3} alignItems="center" component={Box} py={3}>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          FAMILY HISTORY
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Please check family history conditions:</Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="centre" flexWrap="wrap">
          <FormControlLabel
            label={<Typography>Blood Clots</Typography>}
            control={
              <Checkbox
                color="primary"
                // eslint-disable-next-line max-len
                name="forms.past_medical_history.family_history.please_check_family_history_conditions.blood_clots"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Diabetes</Typography>}
            control={
              <Checkbox
                color="primary"
                // eslint-disable-next-line max-len
                name="forms.past_medical_history.family_history.please_check_family_history_conditions.diabetes"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Hypertension</Typography>}
            control={
              <Checkbox
                color="primary"
                // eslint-disable-next-line max-len
                name="forms.past_medical_history.family_history.please_check_family_history_conditions.hypertension"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Rheumatoid Arthritis</Typography>}
            control={
              <Checkbox
                color="primary"
                // eslint-disable-next-line max-len
                name="forms.past_medical_history.family_history.please_check_family_history_conditions.rheumatoid_arthritis"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Anesthetic problems</Typography>}
            control={
              <Checkbox
                color="primary"
                // eslint-disable-next-line max-len
                name="forms.past_medical_history.family_history.please_check_family_history_conditions.anesthetic_problems"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Cancer</Typography>}
            control={
              <Checkbox
                color="primary"
                // eslint-disable-next-line max-len
                name="forms.past_medical_history.family_history.please_check_family_history_conditions.cancer"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Heart Disease</Typography>}
            control={
              <Checkbox
                color="primary"
                // eslint-disable-next-line max-len
                name="forms.past_medical_history.family_history.please_check_family_history_conditions.heart_disease"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Osteoporosis</Typography>}
            control={
              <Checkbox
                color="primary"
                // eslint-disable-next-line max-len
                name="forms.past_medical_history.family_history.please_check_family_history_conditions.osteoporosis"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Stroke</Typography>}
            control={
              <Checkbox
                color="primary"
                // eslint-disable-next-line max-len
                name="forms.past_medical_history.family_history.please_check_family_history_conditions.stroke"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Seizures</Typography>}
            control={
              <Checkbox
                color="primary"
                // eslint-disable-next-line max-len
                name="forms.past_medical_history.family_history.please_check_family_history_conditions.seizures"
              />
            }
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Please describe any immediate family history medical problems: </Typography>

        <TextField
          fullWidth
          multiline
          disableUnderline
          // eslint-disable-next-line max-len
          name="forms.past_medical_history.family_history.please_describe_any_immediate_family_history_medical_problems"
          placeholder="Please describe"
          margin="dense"
          variant="filled"
          rows={2}
        />
      </Grid>
    </Grid>
  );
};
