import { Button, Dialog } from '@material-ui/core';
import LibraryAddIcon from '@material-ui/icons/LibraryAddOutlined';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as casesDiscoveryApi from '../../../../../../../../api/cases/discovery';
import { Loader } from '../../../../../../../../components/Loader';
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContainer
} from '../../../../../../../../components/Modal';
import { DiscoveryForm, initialValues, validationSchema } from '../DiscoveryForm';

export const AddDiscoveryModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    caseItem,
    onCaseItemUpdate
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesDiscoveryApi.createCaseDiscovery(caseItem.id, values).then((data) => {
      enqueueSnackbar('Case discovery successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case discovery not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        caseID: caseItem?.id,
        caseItem,
        ...initialValues
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<LibraryAddIcon />}
              onClose={handleModalReject}
            >
              Add Discovery
            </ModalHeader>

            <ModalBody>
              <DiscoveryForm caseItem={caseItem} onCaseItemUpdate={onCaseItemUpdate} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
