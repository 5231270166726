import { useContext } from 'react';
import { Box, Checkbox, IconButton, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../helpers/dom';
import { CompanyLink } from '../../../CompanyLink';
import { AddressLink } from '../../../AddressLink';
import { CurrencyFormat } from '../../../CurrencyFormat';
import { Date } from '../../../Date';
import { ListRow, ListRowCell } from '../../../ListRow';
import { Popper } from '../../../Popper';
import { Loader } from '../../../Loader';
import { UserLink } from '../../../UserLink';
import {
  ClaimsContext,
  allClaimsStatuses,
  getClaimPayoutStatus,
  claimPayersMap,
  ClaimMenu,
  columnsMap,
  columnsWidths,
  widthBreakpointLarge
} from '../../index';

export const ClaimRow = ({
  claim = {},
  isLoaded,
  view,
  width,
  hiddenColumns = [],
  ListRowProps = {},
  onClick = () => {}
}) => {
  const { selectedClaimsIDs, toggleClaimSelected } = useContext(ClaimsContext);
  const isSelected = selectedClaimsIDs.indexOf(claim.id) !== -1;

  const handleClaimSelection = () => {
    toggleClaimSelected(claim);
  };

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button selected={isSelected} px={0} onClick={onClick} {...ListRowProps}>
      {!hiddenColumns.includes(columnsMap.checkbox) &&
        <ListRowCell>
          <Checkbox edge="start" checked={isSelected} onClick={stopPropagation(handleClaimSelection)} />
        </ListRowCell>
      }

      <ListRowCell grow column flexBasis={columnsWidths.claimNo}>
        <Box display="flex" alignItems="center" color="info.main">
          <Date disableWrapper date={claim.appointment?.appointment_at} iconFontSize="small" />
        </Box>

        <Typography noWrap title={claim.claim_number}>
          {claim.claim_number}
        </Typography>
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.patient) &&
        <ListRowCell grow flexBasis={columnsWidths.patient}>
          <UserLink
            noWrap
            user={claim.patient}
          >
            {!claim.patient?.medical?.med_rec ? '-' : (
              <div>
                <Typography variant="body2" component="span">MRN: </Typography>
                {claim.patient?.medical?.med_rec}
              </div>
            )}
          </UserLink>
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.caseNo) &&
        <ListRowCell flexBasis={columnsWidths.caseNo}>
          -
        </ListRowCell>
      }

      <ListRowCell grow={width < widthBreakpointLarge} flexBasis={columnsWidths.payer}>
        {claim.payer_type === claimPayersMap.insurance ? (
          <CompanyLink
            noWrap
            company={claim.insurance?.insurance_company}
          />
        ) : claim.payer_type === claimPayersMap.patient ? (
          <UserLink
            noWrap
            user={claim.patient}
          />
        ) : (
          '-'
        )}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.provider}>
        <UserLink
          noWrap
          user={claim.provider_primary_care}
        >
          {!!claim.office?.full_address &&
            <Box display="flex" alignItems="center">
              <Box clone mr={1}>
                <LocationOnIcon fontSize="small" />
              </Box>

              <AddressLink
                noWrap
                stopPropagation
                address={claim.office?.full_address}
                name={claim.office?.name}
              >
                {claim.office?.full_address}
              </AddressLink>
            </Box>
          }
        </UserLink>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.chargeAmount}>
        <CurrencyFormat value={claim.billing_info?.total_charged} />
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.totalAmount) &&
        <ListRowCell flexBasis={columnsWidths.totalAmount}>
          <CurrencyFormat value={claim.billing_info?.total_paid} />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.claimBalance}>
        <CurrencyFormat value={claim.billing_info?.total_balance} />
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.payoutStatus}>
          {getClaimPayoutStatus(claim)}
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.claimStatus) && (
        <ListRowCell flexBasis={columnsWidths.claimStatus}>
          {allClaimsStatuses.find((status) => status.value === claim.status)?.label}
        </ListRowCell>
      )}

      {!hiddenColumns.includes(columnsMap.actions) &&
        <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
          <Popper
            placement="left-start"
            anchorRenderer={({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon />
              </IconButton>
            )}
          >
            {({ handleClose }) => (
              <ClaimMenu view={view} claim={claim} onClose={handleClose} />
            )}
          </Popper>
        </ListRowCell>
      }
    </ListRow>
  );
};
