import { useContext, useMemo, useEffect } from 'react';
import cn from 'classnames';
import moment from 'moment';
import {
  makeStyles,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AttachmentIcon from '@material-ui/icons/Attachment';
import StarIcon from '@material-ui/icons/Star';
import { useModal } from '../../../../../../components/ModalsProvider';
import { stopPropagation } from '../../../../../../helpers/dom';
import { Loader } from '../../../../../../components/Loader';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { Checkbox } from '../../../../../../components/FormField';
import { Tooltip } from '../../../../../../components/Tooltip';
import { AccountsContext, folderNamesMap } from '../../../AccountsContext';
import { EmailPreviewModal } from '../../../EmailPreviewModal';
import { MessagesContext } from '../../../MessagesContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmailsListItem = ({
  item: message,
  addEmailView,
  isLoaded,
  recalculateHeight
}) => {
  const {
    filter,
    messageItem,
    selectedMessages,
    toggleItemSelection,
    toggleMessageFlagged,
    fetchMessage,
    toggleEmailView,
    deleteMessage
  } = useContext(MessagesContext);
  const { accounts } = useContext(AccountsContext);
  const classes = useStyles();
  const { openModal } = useModal();
  const isSeen = message?.flags?.seen;
  const isSelected = selectedMessages.findIndex(({ uid }) => uid === message.uid) !== -1;
  const isSent = filter.folder_name === folderNamesMap.sent;
  const senders = useMemo(() => {
    return message?.from?.map(({ name, email }) => name || email).join(', ');
  }, [ message?.from ]);
  const recipients = useMemo(() => {
    return message?.to?.map(({ name, email }) => name || email).join(', ');
  }, [ message?.to ]);
  const sendersOrRecipients = isSent ? recipients : senders;

  const handleItemSelection = () => {
    toggleItemSelection(message);
  };

  const openEmailPreview = () => {
    fetchMessage(message);

    if (addEmailView) {
      openModal(EmailPreviewModal, {
        payload: {
          message,
          filter,
          accounts,
          deleteMessage
        }
      });
    } else {
      toggleEmailView();
    }
  };

  const handleFlagged = (event) => {
    toggleMessageFlagged(message.uid, event.target.checked ? 'flagged' : 'unflagged');
  };

  useEffect(() => {
    if (isLoaded) {
      recalculateHeight();
    }
  }, [ isLoaded, messageItem ]);

  return !isLoaded ? <Loader p={2} /> : (
    <ListItem
      button
      selected={(messageItem?.uid === message.uid) || isSelected}
      className={cn(classes.root, {
        [classes.rootSeen]: isSeen,
        [classes.rootChecked]: isSelected
      })}
      onClick={stopPropagation(openEmailPreview)}
    >
      <ListItemIcon className={classes.buttons}>
        <Checkbox
          withoutFormik
          checked={isSelected}
          edge="start"
          size="small"
          color={isSelected ? 'primary' : 'secondary'}
          onClick={stopPropagation(handleItemSelection)}
        />

        <Checkbox
          withoutFormik
          name="flagged"
          color="warning"
          checked={message?.flags?.flagged}
          icon={<StarBorderIcon />}
          checkedIcon={<StarIcon />}
          onClick={stopPropagation(handleFlagged)}
        />
      </ListItemIcon>

      <ListItemText
        disableTypography
        primary={
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {!isSeen &&
              <FiberManualRecordIcon color="primary" fontSize="small" className={classes.newMessageIcon} />
            }

            <Tooltip isExistTooltip={!!sendersOrRecipients} title={sendersOrRecipients}>
              <Typography noWrap variant="h5" color="textPrimary">
                {sendersOrRecipients}
              </Typography>
            </Tooltip>

            <Box alignSelf="flex-end">
              <Typography variant="body2" align="right" color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
                {moment.unix(message.date).format('LT L')}
              </Typography>
            </Box>
          </Box>
        }
        secondary={
          <Box display="flex" alignItems="center">
            {!!message?.attachments?.length &&
              <>
                <AttachmentIcon />
                <VerticalDivider verticalGutters={0.5} horizontalGutters={1}/>
              </>
            }

            <Typography noWrap className={classes.subject} title={message.subject}>
              {message.subject}
            </Typography>
          </Box>
        }
      />
    </ListItem>
  );
};
