import { Box, Grid, Typography } from '@material-ui/core';
import { Autocomplete, ReportTypeCodesSelect, TextField } from '../../../FormField';
import { reportTransmissionsOptions } from './data';

export const SupplementalInformation = () => {
  return (
    <>
      <Box mb={1}>
        <Typography variant="h3">
          Supplemental Information
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <ReportTypeCodesSelect
            isClearable
            name="report_type_code_id"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Autocomplete
            name="report_transmission"
            label="Report Transmission"
            options={reportTransmissionsOptions}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            name="attach_ctrl_number"
            label="Attach Ctrl Number"
          />
        </Grid>
      </Grid>
    </>
  );
};
