import { useContext, useState } from 'react';
import { Box, SvgIcon, Tab, Typography } from '@material-ui/core';
import { mdiAlertCircleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tabs } from '../../../../../../../components/Tabs';
import { BodyPartsContext } from '../BodyPartsContext';
import { CheckboxesList } from '../CheckboxesList';
import { RightSideBar } from '../RightSideBar';
import { Back, Front } from '../Body';

export const tabsMap = {
  checkboxesList: 'checkboxesList',
  rightSideBar: 'rightSideBar',
  body: 'body'
};

export const TabbedBodyParts = () => {
  const [ activeTab, setActiveTab ] = useState(tabsMap.checkboxesList);
  const { painfulParts, addPainfulPart, setSelectedPart } = useContext(BodyPartsContext);
  const isAllPainLavelsFilled = Object.values(painfulParts).every(({ level }) => level);

  const handleTabIndexChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleClick = (part) => () => {
    if (!painfulParts[part]) {
      addPainfulPart(part);
    }

    setActiveTab(tabsMap.rightSideBar);
    setSelectedPart(part);
  };

  return (
    <Box height="100%" overflow="hidden">
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        onChange={handleTabIndexChange}
      >
        <Tab value={tabsMap.checkboxesList} label="Body parts" />
        <Tab
          value={tabsMap.rightSideBar}
          label={
            !isAllPainLavelsFilled ? (
              <Box display="flex" alignItems="center">
                <SvgIcon fontSize="small" color="error"><Icon path={mdiAlertCircleOutline} /></SvgIcon>

                &nbsp;&nbsp;
                <Typography color="error">Label</Typography>
              </Box>
            ) : 'Level'
          }
        />
        <Tab value={tabsMap.body} label="Body" />
      </Tabs>

      {(activeTab === tabsMap.checkboxesList) && (
        <Box height="100%">
          <CheckboxesList onSetActiveTab={setActiveTab} />
        </Box>
      )}

      {(activeTab === tabsMap.rightSideBar) && (
        <Box height="90%" pb={2}>
          <RightSideBar />
        </Box>
      )}

      {(activeTab === tabsMap.body) && (
        <Box height="100%" width="100%" pt={2} pb={8} overflow="auto">
          <Front onClick={handleClick} />
          <Back onClick={handleClick} />
        </Box>
      )}
    </Box>
  );
};
