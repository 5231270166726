import { Box, Grid, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '../../../FormField';

export const UnableToWork = () => {
  return (
    <>
      <Box mb={1}>
        <Typography variant="h3">
          Unable to Work
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <KeyboardDatePicker
            clearable
            name="unable_work_from"
            label="From"
            outputFormat="YYYY-MM-DD"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <KeyboardDatePicker
            clearable
            name="unable_work_to"
            label="To"
            outputFormat="YYYY-MM-DD"
          />
        </Grid>
      </Grid>
    </>
  );
};
