export const styles = ({ spacing }) => ({
  gridContainer: {
    margin: -spacing()
  },

  gridRow: {
    display: 'flex'
  },

  gridCell: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: spacing()
  }
});
