import { useContext } from 'react';
import { AppointmentsContext } from '../AppointmentsProvider';
import { List } from './List';

export const AppointmentsPreviewsList = ({ hidePatient, hideProvider }) => {
  const appointmentsContext = useContext(AppointmentsContext);

  const handleAppointmentUpdate = (appointment) => {
    if (appointment.recurring_rule) {
      appointmentsContext.reloadAppointments();
    } else {
      appointmentsContext.updateAppointment(appointment);
    }
  };

  const handleAppointmentDelete = (appointment) => {
    if (appointment.recurring_rule) {
      appointmentsContext.reloadAppointments();
    } else {
      appointmentsContext.deleteAppointments([ appointment.id ]);
    }
  };

  return (
    <List
      hidePatient={hidePatient}
      hideProvider={hideProvider}
      onAppointmentUpdate={handleAppointmentUpdate}
      onAppointmentDelete={handleAppointmentDelete}
    />
  );
};
