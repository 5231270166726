import { useRef } from 'react';
import moment from 'moment';
import { Box, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import LocalPhoneIcon from '@material-ui/icons/LocalPhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { useResizeObserver } from '../../../../../../helpers/hooks/useResizeObserver';
import { stopPropagation } from '../../../../../../helpers/dom';
import { billingParticipantsTypesMap } from '../../../../../../components/billing';
import { UserPreviewModal } from '../../../../../../components/users';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../components/ModalsProvider';
import { TextField } from '../../../../../../components/FormField';
import { UserLink } from '../../../../../../components/UserLink';
import { Contact } from '../../../../../../components/Contact';
import { PreviewCompanyModal } from '../../../../Contacts/Companies/PreviewCompanyModal';
import { widthBreakpointXS } from '../PaymentItem';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ disableSpacing = false, amount, method, payment }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const rootRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { width } = useResizeObserver({ ref: rootRef });
  const gridSizeLg = width > widthBreakpointXS ? 4 : 12;
  const gridSizeMd = width > widthBreakpointXS ? 4 : 6;
  const payerParticipantAddress = (
    payment?.payer?.participant?.billing_full_address
    || payment?.participant?.info?.full_address
    || payment?.payer?.info?.billing_address
    || null
  );

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: {
        companyID: payment?.payer?.participant?.id
      }
    });
  };

  const openUserPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: payment?.payer?.participant?.id }
    });
  };

  return (
    <Grid
      container
      spacing={3}
      ref={rootRef}
      alignItems="stretch"
      justifyContent="space-between"
      component={Box}
      py={3}
      px={isMobile ? 1 : !disableSpacing ? 3 : 0}
    >
      <Grid item xs={gridSizeLg}>
        <Typography paragraph variant="h5">Payer</Typography>

        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <PersonIcon color="secondary" />
          </Box>

          {!!payment?.payer ? (
            payment?.payer?.participant_type === billingParticipantsTypesMap.user ?
              <UserLink
                noWrap
                size="sm"
                variant="h5"
                user={payment?.payer?.participant}
                onClick={stopPropagation(openUserPreview)}
              />
              :
              <CompanyLink
                noWrap
                size="sm"
                variant="h5"
                company={payment?.payer?.participant}
                onClick={stopPropagation(openPreviewCompanyModal)}
              />
          ) :
            <Typography color="textSecondary">No info.</Typography>
          }
        </Box>

        <div className={classes.address}>
          <Box component="span" mr={1}>
            <RoomOutlinedIcon color="secondary" />
          </Box>

          {payerParticipantAddress
            ? <Typography>{payerParticipantAddress}</Typography>
            : <Typography color="textSecondary">No info.</Typography>
          }
        </div>

        <Box display="flex" alignItems="center" mt={1}>
          <Box component="span" mr={1}>
            <LocalPhoneIcon color="secondary" />
          </Box>

          <Contact
            type="tel"
            contact={payment?.payer?.participant?.phone}
          />
        </Box>

        <Box display="flex" alignItems="center" mt={1}>
          <Box component="span" mr={1}>
            <EmailOutlinedIcon color="secondary" />
          </Box>

          <Contact
            color="primary"
            type="mailto"
            contact={payment?.payer?.participant?.email}
          />
        </Box>
      </Grid>

      <Grid item sm={gridSizeMd} xs={12}>
        <Typography paragraph variant="h5">Payment info</Typography>

        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <TextField
            isEditable={false}
            withoutFormik
            zeroMinWidth
            label="Payment Method"
            value={method || '-'}
            margin="dense"
          />

          <TextField
            isEditable={false}
            withoutFormik
            zeroMinWidth
            label="Payment Date"
            margin="dense"
            value={payment && (moment(payment.date).format('L') || '')}
          />
        </Box>
      </Grid>

      <Grid item sm={gridSizeMd} xs={12}>
        <Box height="100%" pb={3}>
          <Typography gutterBottom align="right" variant="h5">Amount received</Typography>

          <div className={classes.contentAmount}>
            <CurrencyFormat value={amount} className={classes.currencyFormat}/>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};
