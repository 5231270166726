import { Yup } from '../../utils/validation';

export const validationSchema = Yup.object().shape({
  case_id: Yup.number().nullable().required(),
  templates: Yup.array().nullable().when('template_groups', (templateGroups, schema) => {
    return !templateGroups?.length ? schema.required() : schema;
  }),
  template_groups: Yup.array().nullable().when('templates', (templates, schema) => {
    return !templates?.length ? schema.required() : schema;
  })
}, [ 'template_groups', 'templates' ]);
