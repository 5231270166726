import { useContext, useEffect, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../ModalsProvider';
import { SvgIcon } from '../../SvgIcon';
import { Fab } from '../../Fab';
import {
  KeyboardDatePicker,
  PhoneField,
  Select,
  UserSelect
} from '../../FormField';
import { MedicalProfileContext } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider';
// eslint-disable-next-line max-len
import { transformContactsToOption, transformToOption } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider/selectFieldHelpers';
// eslint-disable-next-line max-len
import { ContactsModal, contactTypesMap, relationshipMap } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/LeftSidebar/Contacts/ContactsModal';

const initialValues = {
  user_id: null,
  phone: null,
  dob: null,
  relationship: null
};

export const EmergencyFormContact = () => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();
  const medicalProfileContext = useContext(MedicalProfileContext);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ options, setOptions ] = useState([]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const onUpdateContactValue = ({ item = null, index }) => {
    if (item?.first_name) {
      setFieldValue(`emergency_contact.${index}.first_name`, item?.first_name || null);
      setFieldValue(`emergency_contact.${index}.last_name`, item?.last_name || null);
      setFieldValue(`emergency_contact.${index}.user_id`, item?.user_id || null);
      setFieldValue(`emergency_contact.${index}.dob`, item?.dob || item?.birthday || null);
      setFieldValue(`emergency_contact.${index}.phone`, item?.phone || null);
      setFieldValue(`emergency_contact.${index}.relationship`, transformToOption(item?.relationship));
    }
  };

  const handleCreateContact = (index) => (option) => {
    if (!option) {
      return;
    }

    if (option?.value) {
      onUpdateContactValue({ item: option?.extendedProps?.item || option?.label, index
      });
    } else {
      setIsLoading(true);

      openModal(ContactsModal, {
        payload: {
          initialValues: {
            contact_type: contactTypesMap.contact,
            ...(values?.medical || values?.patient?.medical)
          }
        },
        onModalResolved: (data) => {
          const contact = data?.contacts?.contact?.[data?.contacts?.contact?.length - 1];

          onUpdateContactValue({ item: contact, index });
          setFieldValue(
            `emergency_contact.${index}.emergency_name`,
            transformContactsToOption(contact)
          );
          setIsLoading(false);
        },
        onModalRejected: () => {
          setIsLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    const contacts = values?.form?.medical?.contacts?.contact || values?.medical?.contacts?.contact;

    setOptions(contacts?.map(transformContactsToOption));
  }, []);

  useEffect(() => {
    if (medicalProfileContext) {
      setOptions(
        medicalProfileContext?.medicalProfile?.contacts?.contact.map(transformContactsToOption)
      );
    }
  }, [ medicalProfileContext ]);

  return (
    <FieldArray
      name="emergency_contact"
      render={({ remove, push }) => (
        <Box mt={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5" color="secondary">Emergency Contact</Typography>

            <Box ml={2}>
              <Fab
                variant="extended"
                color="primary"
                size="small"
                onClick={() => push(initialValues)}
                startIcon={<AddIcon />}
              >
                Add
              </Fab>
            </Box>
          </Box>

          {values?.emergency_contact?.map((item, index) => (
            <Grid key={index} container spacing={2} component={Box} py={2} justify="space-between">
              <Grid item xs={isSmallScreen ? 10 : 4}>
                <UserSelect
                  isClearable
                  isCreatable={!!values?.medical?.id}
                  name={`emergency_contact.${index}.emergency_name`}
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  options={options}
                  label="Emergency Contact"
                  isValidNewOption={() => true}
                  formatCreateLabel={() => '+ Add new contact'}
                  onChange={handleCreateContact(index)}
                />
              </Grid>

              {isSmallScreen &&
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => remove(index)}
                  >
                    <SvgIcon color="error">
                      <CloseIcon />
                    </SvgIcon>
                  </IconButton>
                </Grid>
              }

              <Grid item xs={isSmallScreen ? 6 : 3}>
                <PhoneField
                  required
                  name={`emergency_contact.${index}.phone`}
                  label="Phone"
                />
              </Grid>

              <Grid item xs={isSmallScreen ? 6 : 2}>
                <KeyboardDatePicker
                  name={`emergency_contact.${index}.dob`}
                  label="DOB"
                  outputFormat="YYYY-MM-DD"
                  placeholder="MM/DD/YYYY"
                />
              </Grid>

              <Grid item xs={isSmallScreen ? 12 : 2}>
                <Select
                  isClearable
                  formattedValue
                  name={`emergency_contact.${index}.relationship`}
                  label="Relationship"
                  options={relationshipMap}
                />
              </Grid>

              {!isSmallScreen &&
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => remove(index)}
                  >
                    <SvgIcon color="error">
                      <CloseIcon />
                    </SvgIcon>
                  </IconButton>
                </Grid>
              }
            </Grid>
          ))}
        </Box>
      )}
    />
  );
};
