export const styles = ({ spacing }) => ({
  contentIcon: {
    marginLeft: spacing(),
    marginTop: spacing(0.25),
    cursor: 'pointer'
  },

  actionContentIcon: {
    marginLeft: -spacing()
  }
});
