import { Autocomplete } from '../../../../FormField';
import { reminderEventTypesOptions } from './reminderEventTypesOptions';

export const ReminderEventTypesSelect = (props) => {
  return (
    <Autocomplete
      placeholder="Select type..."
      options={reminderEventTypesOptions}

      {...props}
    />
  );
};
