import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import {
  Dialog,
  SvgIcon,
  Button
} from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiFax } from '@mdi/js';
import * as faxesApi from '../../../../../api/sip';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';
import { Form } from './Form';

export const SendFaxModal = ({
  DialogProps,
  payload: {
    initialValues: initialValuesProp
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const formData = new FormData();

    for (let key in values) {
      formData.append(key, values[key]);
    }

    return faxesApi.sendFax(formData).then(() => {
      enqueueSnackbar('Document sent successfully!', { variant: 'success' });
      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Document not sent', { variant: 'error' });
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ ...initialValues, ...initialValuesProp }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiFax} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Send Fax
            </ModalHeader>

            <ModalBody>
              <Form />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
