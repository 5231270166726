import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import { CasesSelect } from '../../../../../../components/cases/CasesSelect';
import { CaseUsersSelect } from '../../../../../../components/cases/CaseUsersSelect';
import { saveFilter } from '../../../../../../store/lastFilters';
import { CompaniesAutocomplete } from '../../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { IconComponent } from '../../../../../../components/saved-filters';
import { widthBreakpointMedium } from '../../../Dashboard';
import { HistoryPaymentsFilterContext } from '../HistoryPaymentsFilterContext';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { initialValues } from './initialValues';
import { CardContent } from './CardContent';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const MODAL_WIDTH = 610;

export const FiltersBar = ({ filterKey, hiddenFields = [], width }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { setFilter } = useContext(HistoryPaymentsFilterContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleCompanyChange = (company) => {
    setFilter((filter) => ({ ...filter, company_id: company?.id }));
    setRelationsForFilter((state) => ({ ...state, company_id: company }));
  };

  const handleCaseChange = (caseItem) => {
    setFilter((filter) => ({ ...filter, case_id: caseItem?.id }));
    setRelationsForFilter((state) => ({ ...state, case_id: caseItem }));
  };

  const handleUsersChange = (users) => {
    setFilter((state) => ({ ...state, users_id: users?.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, users_id: users }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);

    setFilter(omit({
      ...filter,

      users_id: filter?.appointment_id?.id,
      company_id: filter?.billing_participant_id?.id,
      case_id: filter?.case_id?.id
    }, hiddenFields));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      border={0}
      initialValues={initialValues}
      onReset={() => applySavedFilter(initialValues)}
      filterKey={filterKey}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      hiddenFields={hiddenFields}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.usersId,
          label: filterFieldsLabels[filterFieldsMap.usersId],
          field: <CaseUsersSelect
            multiple
            name="users_id"
            label="Users"
            className={cn(classes.filterField, classes.filterField_user)}
            onChange={handleUsersChange}
          />
        },
        {
          fieldKey: filterFieldsMap.caseId,
          label: filterFieldsLabels[filterFieldsMap.caseId],
          field: <CasesSelect
            name="case_id"
            label="All cases"
            placeholder="Search case by name..."
            margin="dense"
            className={cn(
              classes.filterField,
              { [classes.filterField_medium]: width > widthBreakpointMedium }
            )}
            onChange={handleCaseChange}
          />
        },
        {
          fieldKey: filterFieldsMap.companyId,
          label: filterFieldsLabels[filterFieldsMap.companyId],
          field: <CompaniesAutocomplete
            name="company_id"
            label="Company"
            placeholder="Search company..."
            margin="dense"
            className={classes.filterField}
            onChange={handleCompanyChange}
          />
        }
      ]}
    />
  );
};
