import { createHookReducer } from '../../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_DOCUMENTS_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_DOCUMENTS_SUCCESS]: (
    { filter, documents, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      documents: data
    };
  },

  [types.ADD_DOCUMENT]: (state, payload) => {
    const { pagination, filter, documents, ...otherState } = state;
    const total = pagination.total + 1;
    const last_page = Math.ceil(total / filter.per_page);
    const page = documents.length + 1 > filter.per_page ? filter.page + 1 : filter.page;

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      documents: [ ...documents, payload ]
    };
  },

  [types.ADD_DOCUMENTS]: (state, payload) => {
    const { pagination, filter, documents, ...otherState } = state;
    const total = pagination.total + payload.length;
    const last_page = Math.ceil(total / filter.per_page);
    const page = payload.length + 1 > filter.per_page ? filter.page + 1 : filter.page;

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      documents: payload
    };
  },

  [types.UPDATE_DOCUMENT]: (state, payload) => {
    return {
      ...state,

      documents: state.documents.map((item) => {
        return item.id === payload.id ? { ...item, ...payload } : item;
      })
    };
  },

  [types.SIGN_DOCUMENT]: (state, payload) => {
    return {
      ...state,

      documents: state.documents.map((item) => {
        return item.id === payload.id ? { ...item, ...payload, is_signed: true } : item;
      })
    };
  },

  [types.RESET_DOCUMENTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.TOGGLE_ALL_DOCUMENTS_SELECTION]: ({ documents, selectedIDs, ...state }) => {
    const allFormsIsSelected = documents.length === selectedIDs.length;

    return {
      ...state,

      documents,
      selectedIDs: allFormsIsSelected ? [] : documents.map((item) => item.id)
    };
  },

  [types.TOGGLE_DOCUMENT_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1 ?
        selectedIDs.filter((item) => item !== id)
        :
        selectedIDs.concat(id)
    };
  }
});
