import { api } from '../../api';

export const fetchMRIs = (config) => {
  return api.get('/medical-report/mri', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchMRI = (id, config) => {
  return api.get(`/medical-report/mri/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createMRI = (data) => {
  return api.post('/medical-report/mri', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massCreateMRI = (data) => {
  return api.post('medical-report/mri/bulk', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMRI = ({ id, ...data }) => {
  return api.put(`/medical-report/mri/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteMRI = (id) => {
  return api.delete(`/medical-report/mri/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteMRIs = (IDs) => {
  return api.delete('/medical-report/mri', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
