import { useMediaQuery, useTheme } from '@material-ui/core';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { Fab } from '../../../../../../../components/Fab';
import {
  MedicalInfo as MedicalInfoComponent
} from '../../../../../../../components/users/MedicalInfo';
import {
  SelectMedicalFormModal
} from '../../../../../../../components/medical/forms';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { MedicalFormsProvider } from '../../../MedicalForms/MedicalFormsProvider';
import { MedicalProfileContext } from '../../MedicalProfileProvider';

export const PatientPacket = ({
  isOpen,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { openModal } = useModal();
  const history = useHistory();
  const { user } = useSelector(({ profile }) => profile);
  const { setInitialType, setInitialFormValue } = useContext(MedicalProfileContext);
  const medicalInfoPathname = '/profile/medical-info';
  const initialState = {
    filter: {
      medical_id: user?.medical?.id
    },
    medicalInfoPathname
  };

  const handleSelectComponent = () => {
    openModal(SelectMedicalFormModal, {
      payload: {
        patient: user,
        pathname: medicalInfoPathname,
        onTypeUpdate: setInitialType
      },
      onModalResolved: (data) => {
        setInitialType(data.medical_form);
        setInitialFormValue(data);
        history.push(
          `${medicalInfoPathname}/medical-forms/create`,
          {
            type: data.medical_form,
            form: data
          }
        );
      }
    });
  };

  return (
    <MedicalFormsProvider initialState={initialState}>
      <ContentCard
        fullHeight={!props.autoHeightMax}
        isMovable={!props.autoHeightMax}
        disableContentPaddings
        isResizable={isOpen}
        title="Patient Packet"
        icon={
          <Fab
            size="small"
            color="primary"
            variant={isMobile ? 'round' : 'extended'}
            startIcon={!isMobile && <AddIcon />}
            onClick={handleSelectComponent}
          >
            {isMobile ? <AddIcon /> : 'Medical form'}
          </Fab>
        }
        rightActions={[
          <WidgetsActionButtons
            isMinimizable
            isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
            isDisabledOpen={isDisabledOpen}
            onMinimize={onMinimize}
            onMaximized={onMaximized}
            onClose={onClose}
            onOpen={onOpen}
            onPutItem={onPutItem}
          />
        ]}
        {...props}
      >
        <MedicalInfoComponent height={height} />
      </ContentCard>
    </MedicalFormsProvider>
  );
};
