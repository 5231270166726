import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { objectKeysToMap } from '../../../helpers/objectKeysToMap';
import { InfiniteListLoader } from '../../InfiniteLoader';
import { Loader } from '../../Loader';
import { ScrollbarsCustom } from '../../ScrollbarsCustom';
import { Sticky } from '../../Sticky';
import { AppointmentsContext } from '../AppointmentsProvider';
import { TableHeader } from './TableHeader';

export const columnsWidths = {
  provider: 200,
  patient: 170,
  date: 170,
  duration: 120,
  apptBook: 220,
  apptType: 200,
  status: 204,
  balance: 140,
  note: 210,
  actions: 48
};

export const columnsMap = objectKeysToMap(columnsWidths);

export const AppointmentsList = ({
  autoHeight,
  hiddenColumns,
  Row,
  RowProps = {},
  ...props
}) => {
  const {
    isFetched,
    isFetching,
    appointments,
    meta,
    loadNextPage,
    reloadAppointments
  } = useContext(AppointmentsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    reloadAppointments({
      appointment_from: null,
      appointment_to: null
    });
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} autoHeight={autoHeight}>
      <Box {...props}>
        <Loader p={2} loading={!isFetched} render={
          () => !meta.total ? (
            <Box p={2}>
              <Typography align="center">No appointments found</Typography>
            </Box>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader
                  hiddenColumns={hiddenColumns}
                  ListRowProps={RowProps?.ListRowProps}
                />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={appointments}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={meta}
                  RowComponent={Row}
                  RowProps={{ hiddenColumns, ...RowProps }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
