import { useContext } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { List, MenuItem, ListItemIcon, ListItemText, useMediaQuery } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { ClaimPreviewModal } from '../../../app/Dashboard/BillingPage/ClaimsPage/ClaimPreviewModal';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { useModal } from '../../ModalsProvider';
import { ClaimsContext } from '../ClaimsProvider';
import { EditClaimModal } from '../EditClaimModal';

export const ClaimMenu = ({ view = 'page', claim }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const claimsContext = useContext(ClaimsContext);
  const { openModal } = useModal();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const editClaimModal = () => {
    openModal(EditClaimModal, {
      payload: { id: claim.id },
      onModalResolved: () => {
        claimsContext.fetchClaims();
      }
    });
  };

  const editButtonProps = isMobile || view === 'modal' ? {
    onClick: editClaimModal
  } : {
    component: Link,
    to: `${match.url}/${claim.id}/edit`
  };

  const deleteClaim = () => {
    claimsContext.deleteClaim(claim);
  };

  const goClaimPage = () => {
    if (isMobile || view === 'modal') {
      openModal(ClaimPreviewModal, {
        payload: {
          id: claim.id
        }
      });
    } else {
      history.push(`${match.url}/${claim.id}`);
    }
  };

  return (
    <List>
      <MenuItem onClick={goClaimPage}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {hasRole(rolesMap.admin, rolesMap.supervisor) &&
        <MenuItem {...editButtonProps}>
          <ListItemIcon>
            <EditIcon fontSize="inherit" />
          </ListItemIcon>

          <ListItemText primary="Edit claim" />
        </MenuItem>
      }

      {hasRole(rolesMap.admin, rolesMap.supervisor) &&
        <MenuItem onClick={deleteClaim}>
          <ListItemIcon>
            <DeleteIcon fontSize="inherit" />
          </ListItemIcon>

          <ListItemText primary="Delete claim" />
        </MenuItem>
      }
    </List>
  );
};
