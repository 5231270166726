import { Fragment } from 'react';
import { Box, Link } from '@material-ui/core';
import { ViewScheduleEventModal } from '../../schedule-events';
import { useModal } from '../../ModalsProvider';
import { UserLink } from '../../UserLink';
import { WidgetActivity } from './WidgetActivity';
import { eventFieldsMap } from './fieldsMaps';

export const ScheduleEvent = ({ isDeleted = false, isNew = false, payload }) => {
  const { openModal } = useModal();
  const attachedUsers = payload?.properties?.users?.attached;
  const detachedUsers = payload?.properties?.users?.detached;

  const handleEventClick = () => {
    openModal(ViewScheduleEventModal, {
      payload: {
        eventId: payload?.schedule_event?.id,
        disableActions: true,
        event: payload?.properties?.after
      }
    });
  };

  if (isDeleted) {
    return (
      <Box component="span" display="flex" alignItems="center">
        Removed event
        &nbsp;
        <Link variant="subtitle1" onClick={handleEventClick}>
          {payload?.schedule_event?.title || payload?.properties?.after?.title}
        </Link>
      </Box>
    );
  } else if (isNew) {
    return (
      <Box component="span" display="flex" alignItems="center">
        Added new event
        &nbsp;
        <Link variant="subtitle1" onClick={handleEventClick}>
          {payload?.schedule_event?.title || payload?.properties?.after?.title}
        </Link>
      </Box>
    );
  } else if (attachedUsers?.length) {
    return (
      <Box display="flex" alignItems="center">
        Added users to the event
        &nbsp;
        {attachedUsers?.map((user, index) => (
          <Fragment key={user.id}>
            <UserLink
              noAvatar
              color="primary"
              variant="subtitle1"
              user={user}
            />
            {index !== attachedUsers?.length - 1 ? ', ' : ''}
            &nbsp;
          </Fragment>
        ))}
      </Box>
    );
  } else if (detachedUsers?.length) {
    return (
      <Box display="flex" alignItems="center">
        Removed users from the event
        &nbsp;
        {detachedUsers?.map((user, index) => (
          <Fragment key={user.id}>
            <UserLink
              noAvatar
              color="primary"
              variant="subtitle1"
              user={user}
            />
            {index !== detachedUsers?.length - 1 ? ', ' : ''}
            &nbsp;
          </Fragment>
        ))}
      </Box>
    );
  } else {
    return (
      <WidgetActivity
        {...payload?.properties}
        fieldLabel="Schedule Event"
        activityLabel="Schedule Event"
        fieldsMap={eventFieldsMap}
      />
    );
  }
};
