import { FieldArray } from 'formik';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { RadioGroup, TextField } from '../../FormField';
import { Fab } from '../../Fab';
import {
  MedicationsCards
  // eslint-disable-next-line max-len
} from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/PersonalInjuryOrtho/PastMedicalHistory/Medications/MedicationsCards';
import {
  Medications
  // eslint-disable-next-line max-len
} from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/PersonalInjuryOrtho/PastMedicalHistory/Medications';

export const AllergiesHistory = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleAddFields = (arrayHelpers, fields) => (event) => {
    if ((event.charCode || event.keyCode) === 13) {
      arrayHelpers.push(fields);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          ALLERGIES
        </Typography>
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography gutterBottom variant="h5">
          Are you allergic to any medication?
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <RadioGroup
          row
          // eslint-disable-next-line max-len
          name="forms.past_medical_history.allergies.are_you_allergic_to_any_medication"
        >
          <FormControlLabel
            value="yes"
            label={<Typography>Yes</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="no"
            label={<Typography>No known drug allergies</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography gutterBottom variant="h5">
          {/* eslint-disable-next-line max-len */}
          If yes, please list all medications that you are allergic to and the associated reaction (i.e. penicillin (hives) etc):
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <TextField
          fullWidth
          // eslint-disable-next-line max-len
          name="forms.past_medical_history.allergies.if_yes_please_list_all_medications"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom variant="h5">
          Are you allergic to:
        </Typography>
      </Grid>

      <Grid item md={4} xs={12}>
        <Box display="flex" alignItems="center">
          <Box component="span" mr={2}>
            <Typography variant="h5">Sulfa?</Typography>
          </Box>

          <RadioGroup
            row
            name="forms.past_medical_history.allergies.are_you_allergic_to.sulfa"
          >
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="no"
              label={<Typography>No</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </Box>
      </Grid>

      <Grid item md={4} xs={12}>
        <Box display="flex" alignItems="center">
          <Box component="span" mr={2}>
            <Typography variant="h5">Latex?</Typography>
          </Box>

          <RadioGroup
            row
            name="forms.past_medical_history.allergies.are_you_allergic_to.latex"
          >
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="no"
              label={<Typography>No</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </Box>
      </Grid>

      <Grid item md={4} xs={12}>
        <Box display="flex" alignItems="center">
          <Box component="span" mr={2}>
            <Typography variant="h5">Steroids?</Typography>
          </Box>

          <RadioGroup
            row
            name="forms.past_medical_history.allergies.are_you_allergic_to.steroids"
          >
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="no"
              label={<Typography>No</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </Box>
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography gutterBottom variant="h5">
          Please list all food allergens (i.e. eggs, shellfish):
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <TextField
          fullWidth
          // eslint-disable-next-line max-len
          name="forms.past_medical_history.allergies.are_you_allergic_to.please_list_all_food_allergens"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item xs={12}>
        <FieldArray
          name="forms.past_medical_history.medications"
          render={(arrayHelpers) => (
            <>
              <Box display="flex" alignItems="center" pb={1}>
                <Box pr={2}>
                  <Typography variant="h5" color="secondary">
                    MEDICATIONS:
                  </Typography>
                </Box>

                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon/>}
                  onClick={() => arrayHelpers.push({
                    medications: '',
                    dosage: '',
                    frequency: ''
                  })}
                >
                  Add
                </Fab>
              </Box>

              <Typography>
                {/* eslint-disable-next-line max-len */}
                Please list all medications you are currently taking. Include antibiotics, blood thinner, insulin, heart medications, aspirin, and any over the counter medications. Include vitamin, mineral, and herb supplements.
              </Typography>

              {isSmallScreen
                ? <MedicationsCards arrayHelpers={arrayHelpers} onAddFields={handleAddFields} />
                : <Medications arrayHelpers={arrayHelpers} onAddFields={handleAddFields} />
              }
            </>
          )}
        />
      </Grid>
    </>
  );
};
