import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import {
  makeStyles,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Box
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { api } from '../../../../../../../api';
import * as formsApi from '../../../../../../../api/profile';
import { IconButton } from '../../../../../../../components/IconButton';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { Loader } from '../../../../../../../components/Loader';
import { Fab } from '../../../../../../../components/Fab';
import { BodyPartsContext } from '../BodyPartsContext';
import { bodyPartsMap } from '../Body/bodyPartsMap';
import { tabsMap } from '../TabbedBodyParts';
import { SelectedBodyParts } from './SelectedBodyParts';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CheckboxesList = ({ onSetActiveTab = () => {} }) => {
  const classes = useStyles();
  const [ isFetched, setIsFetched ] = useState(false);
  const [ parts, setParts ] = useState([]);
  const [ search, setSearch ] = useState('');
  const {
    painfulParts,
    selectedPart,
    setSelectedPart,
    addPainfulPart,
    deletePainfulPart
  } = useContext(BodyPartsContext);
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const cancelFetch = useRef(() => {});
  const filteredParts = useMemo(() => {
    return parts.filter((part) => {
      const isPainfulPart = painfulParts.hasOwnProperty(part);
      const isSearchMatching = bodyPartsMap?.[part]?.toLowerCase()?.indexOf(search.toLowerCase()) !== -1;

      return !isPainfulPart && isSearchMatching;
    });
  }, [ parts, painfulParts, search ]);

  const handleResetSearch = () => {
    setSearch('');
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handlePainfulParts = (part) => () => {
    if (painfulParts[part]) {
      deletePainfulPart(part);
    } else {
      addPainfulPart(part);
      setSelectedPart(part);
    }

    onSetActiveTab(tabsMap.rightSideBar);
  };

  useEffect(() => {
    setIsFetched(false);

    if (isAuthenticated) {
      formsApi.getBodyParts({
        cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
      }).then((data) => {
        setIsFetched(true);
        setParts(Object.keys(data));
      });
    } else {
      formsApi.getGuestBodyParts({
        cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
      }).then((data) => {
        setIsFetched(true);
        setParts(Object.keys(data));
      });
    }

    return cancelFetch.current;
  }, []);

  return (
    <div className={classes.root}>
      <Box px={3}>
        <Typography color="textPrimary" variant="h3">
          Choose body parts:
        </Typography>

        <TextField
          fullWidth
          name="search"
          margin="dense"
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
            endAdornment: (search &&
              <InputAdornment
                position="end"
                size="small"
                component={Fab}
                onClick={handleResetSearch}
              >
                <ClearIcon color="error" />
              </InputAdornment>
            )
          }}
        />
      </Box>

      <div className={classes.mainContent}>
        <Scrollbars>
          <Box px={3}>
            <>
              {!!Object.keys(painfulParts).length && <SelectedBodyParts />}

              <Loader loading={!isFetched} className={classes.loader} render={
                () => (!filteredParts.length ?
                  <Typography color="textSecondary">
                    No search results
                  </Typography>
                  :
                  <List disablePadding>
                    {filteredParts.map((part) => (
                      <ListItem
                        button
                        dense
                        key={part}
                        selected={selectedPart === part}
                        className={classes.row}
                        onClick={handlePainfulParts(part)}
                      >
                        <ListItemIcon>
                          <IconButton
                            title="Add selected part"
                            color="primary"
                            onClick={handlePainfulParts(part)}
                          >
                            <AddIcon />
                          </IconButton>
                        </ListItemIcon>

                        <ListItemText primary={<Typography>{bodyPartsMap[part]}</Typography>} />
                      </ListItem>
                    ))}
                  </List>
                )}
              />
            </>
          </Box>
        </Scrollbars>
      </div>
    </div>
  );
};
