import { useFormikContext } from 'formik';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { TextField } from '../../../../../../components/FormField';
import { styles } from './Header/styles';

const useStyles = makeStyles(styles);

export const MobileHeader = () => {
  const classes = useStyles();
  const { values, handleBlur } = useFormikContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="flex-start" color="info.main">
          <Typography variant="h5" color="inherit">
            {values.report_type}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">
          <TextField
            fullWidth
            autoFocus
            multiline
            disableUnderline
            rows={1}
            rowsMax={3}
            name="name"
            InputProps={{
              className: classes.input
            }}
            onBlur={handleBlur}
          />
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography gutterBottom>DOB</Typography>
      </Grid>

      <Grid item xs={9}>
        <Typography gutterBottom>{values.age || '-'}</Typography>
      </Grid>
    </Grid>
  );
};
