import { useState } from 'react';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { TableHeader } from './TableHeader';
import { Card } from './Card';
import { Row } from './Row';

export const minRowHeight = 65;
export const columnsWidths = {
  date: 190,
  provider: 150,
  apptBook: 170,
  office: 170,
  confirm: 100,
  status: 150,
  actions: 80
};

export const ApptList = ({ isFollowUp = false, isUpdatable = false, initialValues }) => {
  const [ appointment, setAppointment ] = useState(initialValues?.appointment);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!appointment) {
    return (
      <Typography align="center">
        No appointments found
      </Typography>
    );
  }

  return isMobile ? (
    <Card
      isFollowUp={isFollowUp}
      isUpdatable={isUpdatable}
      appointment={appointment}
      onUpdateAppointment={setAppointment}
    />
  ) : (
    <>
      <TableHeader />

      <Row
        isFollowUp={isFollowUp}
        isUpdatable={isUpdatable}
        appointment={appointment}
        onUpdateAppointment={setAppointment}
      />
    </>
  );
};
