import { get } from 'lodash';
import { Icon } from '@mdi/react';
import { mdiAccountEditOutline } from '@mdi/js';
import { makeStyles, SvgIcon } from '@material-ui/core';
// eslint-disable-next-line max-len
import { expensesCategoryMap } from '../expensesTypes';
import { IconLabel } from '../../IconLabel';

const styles = ({ spacing }) => ({
  root: {
    marginBottom: spacing(0.25)
  }
});

const useStyles = makeStyles(styles);

export const SingleValue = ({ children, data }) => {
  const classes = useStyles();
  const categoryName = children && children.toLowerCase();

  return (
    <div className={classes.root}>
      {!!expensesCategoryMap[categoryName] ?
        <IconLabel
          label={expensesCategoryMap[categoryName].name}
          color={expensesCategoryMap[categoryName].color}
          icon={expensesCategoryMap[categoryName].icon}
          variant="outlined"
        />
        :
        <IconLabel
          label={children}
          icon={get(expensesCategoryMap, `${data.icon}.icon`) ||
            <SvgIcon><Icon path={mdiAccountEditOutline} /></SvgIcon>
          }
          color={get(expensesCategoryMap, `${data.icon}.color`)}
          variant="outlined"
        />
      }
    </div>
  );
};
