import { useSelector } from 'react-redux';
import { Box, SvgIcon } from '@material-ui/core';
import { mdiHomeCityOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { ContentCard } from '../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../components/WidgetsActionButtons';
import { WorkInfo as WorkInfoComponent } from '../../../../../../components/users/WorkInfo';

export const WorkInfo = ({
  page,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { user } = useSelector(({ profile }) => profile);

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      title="Work Info"
      leftActions={[
        <Box mr={1}>
          <SvgIcon fontSize="small">
            <Icon path={mdiHomeCityOutline} />
          </SvgIcon>
        </Box>
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box flexGrow={1} pb={4}>
        <WorkInfoComponent user={user || {}} />
      </Box>
    </ContentCard>
  );
};
