export const styles = ({
  spacing,
  palette: { grey, primary, ...palette },
  typography: { pxToRem },
  breakpoints
}) => ({
  icon: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: pxToRem(80),
    height: pxToRem(80),
    borderRadius: '50%',
    background: grey[600],
    color: palette.common.white,
    fontSize: pxToRem(26),

    [breakpoints.down('md')]: {
      width: pxToRem(40),
      height: pxToRem(40),
      margin: '0 auto'
    }
  },

  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: spacing(1, 2),
    borderBottom: `1px solid ${grey[300]}`,

    [breakpoints.down('md')]: {
      alignItems: 'center'
    }
  },

  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: pxToRem(90),
    height: pxToRem(90),
    minWidth: 90,
    borderRadius: '50%',
    backgroundColor: grey[100],
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)'
  },

  logo__icon: {
    fontSize: pxToRem(36),
    color: grey[200]
  },

  header: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    paddingRight: spacing(2),
    paddingLeft: spacing(2)
  },

  header__title: {
    width: pxToRem(250),
    minWidth: '100%'
  },

  header__subtitle: {
    color: grey[400]
  },

  header__offices: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: spacing()
  },

  header__officesCounter: {
    marginRight: spacing(2)
  },

  header__icon: {
    fontSize: pxToRem(18),
    color: primary.main,
    marginRight: spacing()
  },

  header__link: {
    fontSize: pxToRem(14),
    cursor: 'pointer'
  }
});
