import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IconButton } from '../../../../../../components/IconButton';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const YearFilter = ({ year, onChange }) => {
  const classes = useStyles();

  const handleIncreseYear = () => {
    onChange(moment.unix(year).add(1, 'year').format('l'));
  };

  const handleDecreaseYear = () => {
    onChange(moment.unix(year).subtract(1, 'year').format('l'));
  };

  return (
    <div className={classes.root}>
      <IconButton
        disabled={year === 0 || year < 0}
        onClick={handleDecreaseYear}
      >
        <ChevronLeftIcon/>
      </IconButton>

      <Typography variant="h3">
        {moment.unix(year).format('YYYY')}
      </Typography>

      <IconButton onClick={handleIncreseYear}>
        <ChevronRightIcon/>
      </IconButton>
    </div>
  );
};
