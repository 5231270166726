import { useContext, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { InfiniteGridLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { TrashContext } from '../TrashContext';
import { Card } from './Card';

export const Grid = () => {
  const {
    files,
    isFetched,
    isFetching,
    loadNextPage,
    filter
  } = useContext(TrashContext);
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box py={2} px={2}>
        <Loader loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No files found</Typography>
          ) : (
            <InfiniteGridLoader
              items={files}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={filter}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={200}
              cellHeight={200}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
