import { ServiceSessions } from '../../../components/login-queues/accounts';
import { Services } from './Services';

export const routes = [
  {
    exact: true,
    path: '/services',
    component: Services
  },
  {
    path: '/services/:serviceId',
    component: ServiceSessions
  }
];
