/* eslint-disable max-len */
import { useContext } from 'react';
import cn from 'classnames';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { BodyPartsContext } from '../../BodyPartsContext';
import { painLevelColors } from '../../painLevelColors';
import { bodyPartsMap } from '../bodyPartsMap';
import { bodyPartsTypesMap } from '../bodyPartsTypesMap';
import backImage from './back.png';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const Back = ({ disableHover = false, onClick = () => {} }) => {
  const classes = useStyles();
  const { selectedPart, painfulParts } = useContext(BodyPartsContext);

  return (
    <div className={classes.root}>
      <svg
        width="285"
        height="663"
        viewBox="0 0 285 663"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="285" height="663" fill="url(#backBodyPattern)" />

        <path
          d="M106 660C113.5 663 116 656.333 117.5 653.5H109.5C106.5 653.5 104.5 653 102 652.5C100.5 652 100 651 96.5 650.5C91.5 650.5 92 649 91.5004 648.5C91.0008 650.5 91.1257 652.5 91.5004 654C92.0003 656 95 656.5 96 657C96.5 659.5 98.5 659.5 100 659.5C102 661 104 660 106 660Z"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.toe_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          fill="currentColor"
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.toe_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.toe_l)}
        >
          <title>{bodyPartsMap.toe_l}</title>
        </path>

        <path
          d="M178.671 660C171.171 663 167.5 657 167.17 653.5H175.171C178.171 653.5 180.171 653 182.67 652.5C184.171 652 185 651 188.5 650C191.5 650.5 192 647.5 192.5 647C192.999 649 193.375 652.5 193 654C192 657 189.671 656.5 188.671 657C188.171 659.5 186.171 659.5 184.671 659.5C182.671 661 180.671 660 178.671 660Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.toe_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.toe_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.toe_r)}
        >
          <title>{bodyPartsMap.toe_r}</title>
        </path>

        <path
          d="M111.501 263C125.001 267.5 134 274.5 142 281.5V331C141 331.5 140 331.5 139 335C137.368 337.238 134.582 341.639 129.001 343.5C117.001 347.5 103.001 334.5 98.5007 328C93.4655 320.727 92.5009 262.5 111.501 263Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.buttock_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.buttock_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.buttock_l)}
        >
          <title>{bodyPartsMap.buttock_l}</title>
        </path>

        <path
          d="M173 262.5C160 267 150 275 142 281.5L142 331C143 331.5 144.5 331 145.5 334.5C147.132 336.738 149.804 341.639 155.386 343.5C167.386 347.5 181.386 334.5 185.886 328C190.921 320.727 192 262 173 262.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.buttock_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.buttock_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.buttock_r)}
        >
          <title>{bodyPartsMap.buttock_r}</title>
        </path>

        <path
          d="M81.0001 402C76.0006 378.5 76.5005 344.5 78.5001 327.5C87.0005 333 92.5005 338 104.501 345C118.001 350.5 134 350 140.5 353.5C142.5 368 136 397 135.5 415C135.5 420.5 134 442.5 130 463.5C130 463.5 126 477.5 115.5 482.5C99 477.5 96 462 94.5 460C93.5 445.5 93 440 91 432C90 427 82.5003 415.5 81.0001 402Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.leg_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.leg_l, 'level' ])] || null }}

          onClick={onClick(bodyPartsTypesMap.leg_l)}
        >
          <title>{bodyPartsMap.leg_l}</title>
        </path>

        <path
          d="M202.951 402C207.95 378.5 207.999 344.5 205.999 327.5C197.499 333 191.45 338 179.45 345C165.95 350.5 150.5 349.5 144 353C142 367.5 148.5 397 149 415C149 420.5 150 442.5 154 463.5C154 463.5 157.951 477.5 168.451 482.5C184.951 477.5 189 462 190.5 460C191.5 445.5 191.5 440 193.5 432C194.5 427 201.451 415.5 202.951 402Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.leg_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.leg_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.leg_r)}
        >
          <title>{bodyPartsMap.leg_r}</title>
        </path>

        <g>
          <path
            d="M139.5 334.5C127 355 106 338.5 98 327.5C95.5 318.5 94.3462 302 96.5 288C97.5 281.5 100.5 262 112 263C106 260.5 95 258 91 257C88.5 260 87 284.5 87 289C86.5 294 79.5 314 78.5 327.5C89 334.5 93 338 101 343C110.5 349.5 132.5 350 140.5 353.5L139.5 334.5Z"
            fill="currentColor"
            className={
              cn(
                classes.bodyPart,
                { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.hip_l },
                { [classes.bodyPart_hover]: !disableHover }
              )
            }
            style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.hip_l, 'level' ])] || null }}
            onClick={onClick(bodyPartsTypesMap.hip_l)}
          >
            <title>{bodyPartsMap.hip_l}</title>
          </path>
        </g>

        <g>
          <path
            d="M145.5 334.5C158 355 178.5 338.5 186.5 327.5C189 318.5 190.154 302 188 288L187.98 287.873C186.961 281.244 183.926 261.506 172.5 262.5C181 259 189 257.5 193.5 257C193 261.5 199 279 197.5 289C198 294 205 314 206 327.5C195.5 334.5 191.5 338 183.5 343C174 349.5 152.167 349.5 144 353L145.5 334.5Z"
            fill="currentColor"
            className={
              cn(
                classes.bodyPart,
                { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.hip_r },
                { [classes.bodyPart_hover]: !disableHover }
              )
            }
            style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.hip_r, 'level' ])] || null }}
            onClick={onClick(bodyPartsTypesMap.hip_r)}
          >
            <title>{bodyPartsMap.hip_r}</title>
          </path>
        </g>

        <path
          d="M143 2.5C115.4 2.5 112.637 27 115 41C117 52.8447 118.5 60 120.5 67C129.5 55.5 135.501 55.5 143 55.5C152.634 55.5 161 62.8333 164 67L169.5 41.5C171.5 26.5 168 2.5 143 2.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.head },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.head, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.head)}
        >
          <title>{bodyPartsMap.head}</title>
        </path>

        <path
          d="M10.9996 315.5C11.7996 314.3 18.5 305.5 22.5 302.5C28.5 301 33 303.5 35.5 305.5C36 307 36.5 309.5 38 313C40.5 321.5 47 327.5 47 327.5C46.5 332 44.4996 335 42.9996 338C39.5 343 38 357.5 33.5 357C31.5004 357 32.5 354 32.5 352.5C32.0597 353.5 30 365 27 365C23.5004 365 24 361 24 360C23.6377 360.5 23 367 19.5 367C16.5 367 18 357.5 18.5 355C18 356.5 17.5 360 14.5 360C12.5 360 12.0004 357 12 356C11.9996 354.882 12.5 338.5 12.5 335C12.5 331 14 325 16 321.5C14.5 321.5 13.5 325.5 8.4996 328C5.5 329 3.5 328.5 3.5 326C3.5 324 5.50082 324.5 6.00042 321.5C6.50042 319.5 9.9996 317 10.9996 315.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.hand_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.hand_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.hand_l)}
        >
          <title>{bodyPartsMap.hand_l}</title>
        </path>

        <path
          d="M273.5 315.5C272.7 314.3 266 305.5 262 302.5C256 301 251.5 303.5 249 305.5C248.5 307 248 309.5 246.5 313C244 321.5 237.5 327.5 237.5 327.5C238 332 240 335 241.5 338C245 343 246.5 357.5 251 357C253 357 252 354 252 352.5C252.44 353.5 254.5 365 257.5 365C261 365 260.5 361 260.5 360C260.862 360.5 261.5 367 265 367C268 367 266.5 357.5 266 355C266.5 356.5 267 360 270 360C272 360 272.5 357 272.5 356C272.5 354.882 272 338.5 272 335C272 331 270.5 325 268.5 321.5C270 321.5 271 325.5 276 328C279 329 281 328.5 281 326C281 324 278.999 324.5 278.5 321.5C278 319.5 274.5 317 273.5 315.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.hand_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.hand_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.hand_r)}
        >
          <title>{bodyPartsMap.hand_r}</title>
        </path>

        <path
          d="M75 230.5C75 230.5 55 238.5 51.5 238C46.5 233 41.5 225 40 219.5C38 223 34.5 255.5 34.5 258.5C34.5 261.5 30.5 284.5 29 291.5C32.5 290.5 37 294 38 295L52 285.5C55.5 278.5 64 263.5 67 256C71.5 248 74.1667 236.167 75 230.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.forearm_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.forearm_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.forearm_l)}
        >
          <title>{bodyPartsMap.forearm_l}</title>
        </path>

        <path
          d="M210 230.5C210 230.5 230 238.5 233.5 238C238.5 233 243 225.5 244.5 220C246.5 223.5 250.5 255.5 250.5 258.5C250.5 261.5 254 284.5 255.5 291.5C252 290.5 247.5 294 246.5 295L232.5 285.5C229 278.5 221 263.5 218 256C213.5 248 210.833 236.167 210 230.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.forearm_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.forearm_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.forearm_r)}
        >
          <title>{bodyPartsMap.forearm_r}</title>
        </path>

        <path
          d="M70.5 205L76.0001 217C76.0001 221.5 75.5001 227.5 75.0001 230.5C73.5001 231 57.0002 238 51.5001 238C51.5001 238 43.5 230.5 40 219.5C40 216.5 45 205 45 205C46 204.139 70.5 205 70.5 205Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.elbow_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.elbow_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.elbow_l)}
        >
          <title>{bodyPartsMap.elbow_l}</title>
        </path>

        <path
          d="M214 205L208.5 217C208.5 221.5 209.5 227.5 210 230.5C211.5 231 228 238 233.5 238C233.5 238 241 231 244.5 220C244.5 217 239.5 205 239.5 205C238.5 204.139 214 205 214 205Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.elbow_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.elbow_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.elbow_r)}
        >
          <title>{bodyPartsMap.elbow_r}</title>
        </path>

        <path
          d="M100.838 102.5C100.838 102.5 111.338 109 113.838 112C109.338 115 107.838 118 106.838 124C103.838 125 91.4375 122 83 131C80 145 71.5 156.5 56 159C53.5 128.5 64.5 117 70 113C72.871 111.086 78.5147 108.804 84.5 107C91.0232 105.033 98.2304 104.065 100.838 102.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.shoulder_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.shoulder_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.shoulder_l)}
        >
          <title>{bodyPartsMap.shoulder_l}</title>
        </path>

        <path
          d="M184.5 102.5C184.5 102.5 174 109 171 112C175.5 115 177 118 178 124C181 125 193.036 122 201.474 131C204.474 145 212.974 156.5 228.474 159C230.974 128.5 219.974 117 214.474 113C211.603 111.086 205.959 108.804 199.974 107C193.45 105.033 187.108 104.065 184.5 102.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.shoulder_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.shoulder_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.shoulder_r)}
        >
          <title>{bodyPartsMap.shoulder_r}</title>
        </path>

        <path
          d="M48.5 182.5C48.5 184.54 45.5 203.5 45 205C48 204 70.5 205 70.5 205L76 217C76.6667 212.5 78.5 203.5 79 201.5C79.5 199.5 81.5 194.5 82 192C83.5 186.5 82 169 82 166.5C81 159 80.5 147.5 80.5 139.5C73 155.5 61 158.5 56 159C56 159.5 51.5 167.5 51 169.5C50.7288 170.585 48.5 180 48.5 182.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.arm_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.arm_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.arm_l)}
        >
          <title>{bodyPartsMap.arm_l}</title>
        </path>

        <path
          d="M236.167 182.5C236.167 184.54 239.167 203.5 239.667 205C236.667 204 214.167 205 214.167 205L208.667 217C208 212.5 206.167 203.5 205.667 201.5C205.167 199.5 203.167 194.5 202.667 192C201.167 186.5 202.667 169 202.667 166.5C203.667 159 204.167 147.5 204.167 139.5C211.667 155.5 223.667 158.5 228.667 159C228.667 159.5 233.167 167.5 233.667 169.5C233.938 170.585 236.167 180 236.167 182.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.arm_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.arm_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.arm_r)}
        >
          <title>{bodyPartsMap.arm_r}</title>
        </path>

        <path
          d="M27 298C27.6325 296.735 28.5 293.667 29 291.5C32.5 290.5 37 294 38 295L52 285.5C50 289 48.5 294 47.5 297C47 300 46 302.5 46 304C46 306 51 313.5 47 327.5C39.5 320.5 37 310.5 35.5 305.5C32 302.5 27 301.5 22.5 302.5C23.5 301.5 26.5 299 27 298Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.wrist_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.wrist_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.wrist_l)}
        >
          <title>{bodyPartsMap.wrist_l}</title>
        </path>

        <path
          d="M257.5 298C256.868 296.735 256 293.667 255.5 291.5C252 290.5 247.5 294 246.5 295L232.5 285.5C234.5 289 236 294 237 297C237.5 300 238.5 302.5 238.5 304C238.5 306 233.5 313.5 237.5 327.5C245 320.5 247.5 310.5 249 305.5C252.5 302.5 257.5 301.5 262 302.5C261 301.5 258 299 257.5 298Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.wrist_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.wrist_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.wrist_r)}
        >
          <title>{bodyPartsMap.wrist_r}</title>
        </path>

        <path
          d="M121 89.5L120.5 67C126.5 58.5 133.5 55.5 141.5 55.5C149.5 55.5 158.5 59.5 164 67L163 90.5C159.5 99.5 151 105 141.5 105.5C131.5 105.5 123.667 99 121 89.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.neck },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.neck, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.neck)}
        >
          <title>{bodyPartsMap.neck}</title>
        </path>

        <path
          d="M121 89.5001C118 94.5 106.5 100 101 102.5C105 105 111.5 109.5 114 112C125 111 137.5 117.5 142 117.5V105.5C126 105.5 122.134 92.5246 121 89.5001Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.clavicle_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.clavicle_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.clavicle_l)}
        >
          <title>{bodyPartsMap.clavicle_l}</title>
        </path>

        <path
          d="M163.5 90C168 94 179 100.5 184.5 102.5C180.5 105 173.5 109.5 171 112C158.5 111.5 146.5 117.5 142 117.5V105.5C156 104.5 161.992 93.3922 163.5 90Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.clavicle_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.clavicle_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.clavicle_r)}
        >
          <title>{bodyPartsMap.clavicle_r}</title>
        </path>

        <path
          d="M109.5 44.0001C109.5 39 114 39.7623 115.5 41.7623C115.5 43.0956 115.671 46.2895 116 48.2623C116.5 51.2624 117 54.5001 118 58.0001C118 58.0001 118 60 115.5 59.5001C114 59.5001 109.959 49.5001 109.5 44.0001Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.ear_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.ear_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.ear_l)}
        >
          <title>{bodyPartsMap.ear_l}</title>
        </path>

        <path
          d="M175 43.5001C175 39 171 39.2377 169.5 42C169 43.5001 168.329 46.5273 168 48.5001C167.5 51.5001 166.5 54.5 166 58.0001C166 58.0001 166.5 60 169 59.5C170.5 59.5 174.541 49.0001 175 43.5001Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.ear_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.ear_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.ear_r)}
        >
          <title>{bodyPartsMap.ear_r}</title>
        </path>

        <path
          d="M118.5 651.5C121.5 651 123.5 643 123.5 638C120.47 642.682 116.663 643.171 114.5 642C113 641 112.5 639 112.5 637C108.5 637.5 106.5 644 107.5 647.5C109 651.5 114.5 651.5 118.5 651.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.ball },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.ball, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.ball)}
        >
          <title>{bodyPartsMap.ball}</title>
        </path>

        <path
          d="M166 651.5C163 651 160.5 643 160.5 638C163.53 642.682 168.008 643.171 170.171 642C171.671 641 172.171 639 172.171 637C176.171 637.5 178.171 644 177.171 647.5C175.671 651.5 170 651.5 166 651.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.ball },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.ball, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.ball)}
        >
          <title>{bodyPartsMap.ball}</title>
        </path>

        <path
          d="M113.5 612.5C122 605.702 126.5 605 133 607C134.5 607.869 135.5 611.97 135.5 615C135.5 618 131.167 621.827 129.5 623.16L125 625.5C125 625.5 120 626 114 633C113.116 634.031 112.5 636 112.5 637C112 637 106.5 638.5 107.5 647.5C108.5 651 114 651.759 119 651.5L117.5 653.5H116C114.308 653.759 114 653.5 111.5 653.5C109 653.5 108 653.66 105 653.16C102 652.66 101.5 652.5 100 651.5C98.5 651 98 650.5 94.5 650.5C92 650 91.8333 649.167 91.5 648.5L99 634.259C100.5 623.759 106.5 618.099 113.5 612.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.sole_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.sole_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.sole_l)}
        >
          <title>{bodyPartsMap.sole_l}</title>
        </path>

        <path
          d="M171.171 612.5C162.671 605.702 158.171 605 151.671 607C150.171 607.869 149.171 611.97 149.171 615C149.171 618 153.505 621.827 155.171 623.16L159.671 625.5C159.671 625.5 164.671 626 170.671 633C171.555 634.031 172.171 636 172.171 637C172.671 637 178.171 638.5 177.171 647.5C176.171 651 170.671 651.759 165.671 651.5L167.171 653.5H168.671C170.364 653.759 170.671 653.5 173.171 653.5C175.671 653.5 176.671 653.66 179.671 653.16C182.671 652.66 183.171 652.5 184.671 651.5C186.171 651 186.5 650 190 650C192.5 648.5 192.501 646.5 192.001 645C190.661 638.301 183 627 183 627C181 617.5 178.171 618.099 171.171 612.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.sole_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.sole_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.sole_r)}
        >
          <title>{bodyPartsMap.sole_r}</title>
        </path>

        <path
          d="M114.5 632.5C118.1 628.5 122.164 625.833 124.998 625.5C124.498 627 123.498 632 123.498 638C120.698 642.8 116.5 643 114.499 642C112.832 640.833 110.9 636.5 114.5 632.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.instep_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.instep_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.instep_l)}
        >
          <title>{bodyPartsMap.instep_l}</title>
        </path>

        <path
          d="M170.171 632.5C166.571 628.5 162.507 625.833 159.673 625.5C160.173 627 160.5 632 160.5 638C163.3 642.8 168.171 643 170.172 642C171.839 640.833 173.771 636.5 170.171 632.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.instep_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.instep_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.instep_r)}
        >
          <title>{bodyPartsMap.instep_r}</title>
        </path>

        <path
          d="M101 627C105 610 105.5 599 105 587C106.833 584.333 110.069 579 113.569 579C116.069 579 117.5 583.5 121.5 586.5C124 587.5 125 586.5 126 586C126 587 127.5 589 128 592.5C128.5 595 128.5 596 128.5 600C129.5 601.5 131.5 603 133.5 607C125 604.5 120 607.5 115.5 611C109 616 105 619 101 627Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.ankle_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.ankle_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.ankle_l)}
        >
          <title>{bodyPartsMap.ankle_l}</title>
        </path>

        <path
          d="M182 623C179 605.5 179.5 597.5 179.5 587C177.667 584.333 174.431 579 170.931 579C168.431 579 167 583.5 163 586.5C160.5 587.5 159.5 586.5 158.5 586C158.5 587 157 589 156.5 592.5C156 595 156 596 156 600C155 601.5 153 603 151 607C159.5 604.5 164.5 607 172.5 613.5C177.643 617.5 179.5 617.5 182 623Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.ankle_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.ankle_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.ankle_r)}
        >
          <title>{bodyPartsMap.ankle_r}</title>
        </path>

        <path
          d="M115.502 482.5C103.002 479 97.5018 467.5 94.502 460C94.0022 476.5 91.5 504.5 92.5005 509.5C92.5 515 102.5 560 105 587C105 587 110.573 578 114.073 579C117.073 580 120.5 590 126 586C125.704 580.079 127.846 556.651 130.5 538C132.328 525.153 134.5 518.5 135 513C136.998 506.5 130.002 479.5 130.002 463.5C128.002 470.5 122.502 479 115.502 482.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.shin_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.shin_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.shin_l)}
        >
          <title>{bodyPartsMap.shin_l}</title>
        </path>

        <path
          d="M168.5 482.5C181 479 187 468 190.5 460C191 476.5 193.126 504.5 192.125 509.5C192.126 515 182 558 179.626 587C179.626 587 174.052 578 170.552 579C167.552 580 164.126 590 158.626 586C158.922 580.079 156.78 556.651 154.126 538C152.298 525.153 150.126 518.5 149.626 513C147.5 505 154.5 480.5 154.5 464.5C156.5 471.5 161.5 479 168.5 482.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.shin_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.shin_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.shin_r)}
        >
          <title>{bodyPartsMap.shin_r}</title>
        </path>

        <path
          d="M125.5 243.5C126.598 235.834 128.012 231.87 131 225C135.299 223.136 138.082 222.61 143.5 222.5L154 224C156.081 228.74 157.032 231.36 158.5 236C159.905 242.84 160.116 246.671 160 253.5C158.534 260.506 157.601 264.367 155.5 271L142 281.5L140 279.5C135.865 276.665 133.577 274.818 129.5 271.5C127.553 265.695 126.703 262.407 125.5 256.5C125.059 251.625 124.752 248.916 125.5 243.5Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.lumbar },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.lumbar, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.lumbar)}
        >
          <title>{bodyPartsMap.lumbar}</title>
        </path>

        <path
          d="M129.446 271C123.318 252.603 123.239 242.503 131 225L120 231.5L112 236C107.814 237.224 105.448 237.9 101.5 238.5L92.5 240C91.3522 246.522 90.9665 250.235 91 257C100.076 258.843 104.592 260.332 112 263C119.457 265.94 123.022 268.054 129.446 271.5V271Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.lower_back_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.lower_back_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.lower_back_l)}
        >
          <title>{bodyPartsMap.lower_back_l}</title>
        </path>

        <path
          d="M155.554 271C161.682 252.203 161.761 241.884 154 224C159.927 227.098 162.957 228.627 169 231C174.077 233.367 176.926 234.544 182 236.5C186.076 237.564 188.445 238.049 193 238.5V257L173 262.5L155.554 271Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.lower_back_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.lower_back_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.lower_back_r)}
        >
          <title>{bodyPartsMap.lower_back_r}</title>
        </path>

        <path
          d="M131.379 225.461C141.281 188.757 135.562 159.126 107.08 123.424C101.622 123.026 97.4962 123.686 92.082 124.924C88.1314 126.251 86.3135 127.369 83.5816 129.924L79.9028 139.19L82.9028 191.19L86.5801 199.424L91.4028 207.19C92.4154 220.079 92.6285 227.304 92.4028 240.19C99.9101 239.475 104.084 238.869 111.403 236.69L131.379 225.461Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.back_l },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.back_l, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.back_l)}
        >
          <title>{bodyPartsMap.back_l}</title>
        </path>

        <path
          d="M153.759 224.828C143.343 189.091 149.027 159.317 177.759 123.136L187.879 123.866L192.259 124.828L196.879 126.366L200.379 128.866L204.879 139.366L202.379 171.366L201.379 189.866L198.259 198.828L193.879 205.866C192.099 218.741 191.603 225.964 192.879 238.866C184.927 238.057 180.859 236.786 173.879 233.866L153.759 224.828Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.back_r },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.back_r, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.back_r)}
        >
          <title>{bodyPartsMap.back_r}</title>
        </path>

        <path
          d="M132.5 114C125.5 112 121.5 111 114 111C106.5 111 107.337 116.852 107 123C110.8 128.134 112.676 130.677 117 136.5C123.512 147.48 127.006 153.689 131 165.5C134.352 176.8 135.661 183.146 136 194.5C135.413 206.692 134.234 213.352 131 225C135.364 223.203 137.998 222.509 143.5 222.5L154 224C150.213 212.283 149.357 205.497 149.5 193C149.531 179.324 151.538 172.127 156 159.5C162.8 144.344 167.694 136.372 178 123C177.617 120.321 177.266 118.869 176 116.5C174.773 113.917 173.804 112.729 170.5 111H165.5C156.288 112.432 151.158 113.969 142 116.5C142 116.5 139.5 116 132.5 114Z"
          fill="currentColor"
          className={
            cn(
              classes.bodyPart,
              { [classes.bodyPart_checked]: selectedPart === bodyPartsTypesMap.back_m },
              { [classes.bodyPart_hover]: !disableHover }
            )
          }
          style={{ color: painLevelColors[get(painfulParts, [ bodyPartsTypesMap.back_m, 'level' ])] || null }}
          onClick={onClick(bodyPartsTypesMap.back_m)}
        >
          <title>{bodyPartsMap.back_m}</title>
        </path>

        <defs>
          <pattern id="backBodyPattern" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#backBodyImage" transform="translate(-0.00102785) scale(0.00161102 0.000692521)" />
          </pattern>

          <image
            id="backBodyImage"
            width="622"
            height="1444"
            xlinkHref={backImage}
          />
        </defs>
      </svg>
    </div>
  );
};
