import { Route, Switch, Redirect } from 'react-router-dom';
import { Box, useMediaQuery } from '@material-ui/core';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { Page } from '../../../components/Page';
import { NotFound } from '../../NotFound';
import { TaskTypes } from './TaskTypes';
import { TaskTemplates } from './TaskTemplates';
import { TaskTemplatesGroups } from './TaskTemplatesGroups';
import { MainWindow } from './MainWindow/MainWindow';
import { TaskItem } from './TaskItem';

export const TasksPage = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <LayoutContextProvider>
      <Box display="flex" width="100%" height="100%" bgcolor="grey.100" p={isMobile ? 0 : 2} pb={1}>
        <Page>
          <Switch>
            <Route exact path="/tasks" component={MainWindow} />
            <Route path="/tasks/not-found" component={NotFound} />

            <Route exact path="/tasks/system-settings">
              <Redirect to="/tasks/system-settings/types" />
            </Route>

            <Route exact path="/tasks/system-settings/types" component={TaskTypes} />
            <Route exact path="/tasks/system-settings/task-templates/templates" component={TaskTemplates} />
            <Route exact path="/tasks/system-settings/task-templates/groups" component={TaskTemplatesGroups} />
            <Route exact path="/tasks/:id" component={TaskItem} />
          </Switch>
        </Page>
      </Box>
    </LayoutContextProvider>
  );
};
