import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../ListRow';
// eslint-disable-next-line max-len
import { lumbarReflexLowerMap, reflexLowerMap } from '../../../../../spinalExaminationMap';
import { columnsWidthsLg, columnsWidthsSm } from '../../columnsWidths';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const ReflexLower = ({ isLumbarSpine = false, report }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;
  const keys = isLumbarSpine ? lumbarReflexLowerMap : reflexLowerMap;

  return (
    <Box mt={4}>
      <Typography gutterBottom variant="h5">Reflexes-lower extremities</Typography>

      <Box my={2}>
        <div className={classes.root}>
          <ListRow header>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name} />

            <ListRowCell flexBasis={columnsWidths.right}>
              <Typography variant="subtitle2">
                Right
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.left}>
              <Typography variant="subtitle2">
                Left
              </Typography>
            </ListRowCell>
          </ListRow>

          {Object.keys(keys)?.map((item, i) => (
            <ListRow key={i}>
              <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                <Typography noWrap variant="h5">
                  {keys[item]}:
                </Typography>
              </ListRowCell>

              <ListRowCell flexBasis={columnsWidths.right}>
                {report[item]?.right || '-'}
              </ListRowCell>

              <ListRowCell flexBasis={columnsWidths.left}>
                {report[item]?.left || '-'}
              </ListRowCell>
            </ListRow>
          ))}
        </div>
      </Box>
    </Box>
  );
};
