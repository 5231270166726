import * as documentsApi from '../../../../../../../api/profile';

export const transformToOption = (item) => item && ({
  value: item.type,
  label: item.name
});

export const fetchMedicalDocumentTypes = () => {
  return documentsApi.fetchMedicalDocumentTypes()
    .then((data) => data.map(transformToOption));
};
