export const styles = ({ spacing, palette: { background } }) => ({
  root: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)'
  },

  toolbar: {
    display: 'flex',
    paddingTop: spacing(),
    paddingBottom: spacing(),
    paddingLeft: spacing(4),
    background: background.primary
  },

  grow: {
    flexGrow: 1
  },

  creationButton: {
    marginTop: spacing(0.5),
    marginLeft: 'auto'
  }
});
