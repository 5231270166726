import { forwardRef, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';
import {
  Box,
  Avatar,
  Button,
  IconButton,
  Typography,
  makeStyles,
  Collapse,
  Divider,
  Grid,
  InputAdornment
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';
import SendIcon from '@material-ui/icons/Send';
import * as chatsApi from '../../../../../api/chat';
import { useMessageContent } from '../../../../../helpers/hooks';
import { getUserFullName, getUserNameAbbr } from '../../../../../helpers/users';
import { TextField } from '../../../../FormField';
import { Loader } from '../../../../Loader';
import { MessageContent } from '../../../MessageContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Notification = forwardRef(({ message, threadId, snackbarKey }, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const inputRef = useRef();
  const [ isExpanded, setIsExpanded ] = useState(false);
  const [ isReplied, setIsReplied ] = useState(false);
  const messageContent = useMessageContent(message);

  const handleClose = () => {
    closeSnackbar(snackbarKey);
  };

  const handleClick = () => {
    setIsExpanded(true);
  };

  const handleReply = () => {
    setIsReplied(true);
  };

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return chatsApi.createMessage(values).then(() => {
      closeSnackbar(snackbarKey);
    });
  };

  useEffect(() => {
    if (isReplied && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [ isReplied ]);

  return (
    <Box ref={ref} maxWidth={340}>
      <Grid
        container
        spacing={2}
        component={Box}
        className={classes.root}
        onClick={handleClick}
      >
        <Grid item xs={10}>
          <Box display="flex">
            <Box m={1} mr={1.5}>
              <Avatar
                size="lg"
                src={message.user.avatar}
                alt={getUserNameAbbr(get(message.user, 'profile', message.user))}
              />
            </Box>

            <Box m={1} ml={0}>
              <Typography noWrap variant="h4" className={classes.messageHeader}>
                {getUserFullName(message.user)}
              </Typography>

              <Typography
                variant="body1"
                component="div"
                className={classes.messageText}
              >
                <MessageContent message={messageContent} />
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <IconButton className={classes.iconButton} onClick={handleClose}>
            <CloseIcon/>
          </IconButton>
        </Grid>

        {message.type !== 'system' && (
          <Grid item xs={12}>
            <Collapse
              unmountOnExit
              in={isExpanded}
              timeout="auto"
            >
              {isReplied ? (
                <Formik
                  initialValues={{
                    body: null,
                    thread: {
                      id: threadId
                    }
                  }}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, values, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Divider/>

                      <Box mx={1.5} my={1} display="flex" alignItems="center" alignContent="center">
                        <TextField
                          name="body"
                          placeholder="Write a message ..."
                          inputRef={inputRef}
                          InputProps={{
                            disableUnderline: true,
                            className: classes.textInput,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Loader surface loading={isSubmitting} render={
                                  () => (
                                    <IconButton
                                      disabled={isSubmitting || values?.body?.length < 1}
                                      type="submit"
                                      color="primary"
                                    >
                                      <SendIcon />
                                    </IconButton>
                                  )}
                                />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                    </form>
                  )}
                </Formik>
              ) : (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    color="primary"
                    className={classes.replyButton}
                    startIcon={
                      <ReplyIcon color="primary" className={classes.replyIcon}/>
                    }
                    onClick={handleReply}
                  >
                    Reply
                  </Button>
                </Box>
              )}
            </Collapse>
          </Grid>
        )}
      </Grid>
    </Box>
  );
});
