import { List as MuiList } from '@material-ui/core';
import { Scrollbars } from '../../../Scrollbars';
import { Sticky } from '../../../Sticky';
import { TableHeader } from '../../CallReports/List/TableHeader';
import { Row } from '../../CallReports/List/Row';

export const List = ({ reports }) => {
  return (
    <Scrollbars autoHeight autoHeightMax="100%">
      <Sticky>
        <TableHeader />
      </Sticky>

      <MuiList disablePadding>
        {reports.map((report) => (
          <Row isLoaded key={report.id} item={report} />
        ))}
      </MuiList>
    </Scrollbars>
  );
};
