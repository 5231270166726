import { isEqual } from 'lodash';
import { Link, Typography } from '@material-ui/core';
import { PreviewCompanyModal } from '../../../app/Dashboard/Contacts/Companies/PreviewCompanyModal';
import { getUserFullName } from '../../../helpers/users';
import { useModal } from '../../ModalsProvider';
import { UserPreviewModal } from '../../users';

export const Participant = ({ before, after, fieldLabel }) => {
  const { openModal } = useModal();

  const openPreview = (participante) => () => {
    const isCompany = participante?.participant_type === 'user';
    const id = participante?.participante?.id;

    if (!id) return;

    if (isCompany) {
      openModal(PreviewCompanyModal, {
        payload: { companyID: id }
      });
    } else {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  if (!before && after) {
    return (
      <>
        Added&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Link onClick={openPreview(after)}>
          {after?.participant?.name || getUserFullName(after?.participant) || ''}
        </Link>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Link onClick={openPreview(before)}>
          {before?.participant?.name || getUserFullName(before?.participant) || ''}
        </Link>
      </>
    );
  } else if (before && after && !isEqual(after, before)) {
    return (
      <>
        Changed&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Link onClick={openPreview(before)}>
          {before?.participant?.name || getUserFullName(before?.participant) || ''}
        </Link>
        &nbsp;to&nbsp;
        <Link onClick={openPreview(after)}>
          {after?.participant?.name || getUserFullName(after?.participant) || ''}
        </Link>
      </>
    );
  } else {
    return (
      <>
        Empty&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  }
};
