import { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { makeStyles, Box, Button, ButtonGroup, useMediaQuery } from '@material-ui/core';
import { Portal } from '../../../../../../../components/Portal';
import { Loader } from '../../../../../../../components/Loader';
import { PreviewContent } from '../PreviewContent';
import { ViewControls, ViewControlsStub } from '../ViewControls';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = () => ({
  page: {
    overflow: 'hidden',

    '& > canvas': {
      margin: 'auto'
    }
  }
});

const useStyles = makeStyles(styles);

export const PdfView = ({ actionsBarPortalElement, topLayerPortalElement, file, viewWidth }) => {
  const [ { totalPages, currentPage, width, scale }, setState ] = useState({
    totalPages: 1,
    currentPage: 1,
    width: 0,
    scale: 1
  });
  const [ isBrowserVariant, setIsBrowserVariant ] = useState(false);
  const classes = useStyles({ width, viewWidth, scale });
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const toggleIsBrowserVariant = () => {
    setIsBrowserVariant((isBrowserVariant) => !isBrowserVariant);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState((state) => ({ ...state, isLoaded: true, totalPages: numPages }));
  };

  const goPreviousPage = () => {
    if (currentPage > 1) {
      setState((state) => ({ ...state, currentPage: currentPage - 1 }));
    }
  };

  const goNextPage = () => {
    if (currentPage < totalPages) {
      setState((state) => ({ ...state, currentPage: currentPage + 1 }));
    }
  };

  const zoomOut = () => {
    setState((state) => ({ ...state, scale: +(scale - 0.1).toFixed(10) }));
  };

  const zoomFit = () => {
    setState((state) => ({ ...state, width: viewWidth, scale: 1 }));
  };

  const zoomReset = () => {
    setState((state) => ({ ...state, width: 0, scale: 1 }));
  };

  const zoomIn = () => {
    setState((state) => ({ ...state, scale: +(scale + 0.1).toFixed(10) }));
  };

  return (
    <>
      {!isTablet &&
        <Portal container={actionsBarPortalElement}>
          <ButtonGroup
            size="small"
            color="primary"
            aria-label="Toggle pdf preview variant"
            onClick={toggleIsBrowserVariant}
          >
            <Button variant={!isBrowserVariant && 'contained'}>Custom view</Button>
            <Button variant={isBrowserVariant && 'contained'}>Browser view</Button>
          </ButtonGroup>
        </Portal>
      }

      {isBrowserVariant ? (
        <Box clone flexGrow={1} overflow="hidden">
          <object data={file} type="application/pdf" width="100%" height="100%">
            HTML5 viewer not supported
          </object>
        </Box>
      ) : (
        <>
          <PreviewContent className={classes.root}>
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Loader/>}
            >
              <Page
                className={classes.page}
                loading={<Loader/>}
                pageNumber={currentPage}
                width={width}
                scale={scale}
              />

              <ViewControlsStub/>
            </Document>
          </PreviewContent>

          <Portal container={topLayerPortalElement}>
            <ViewControls
              onZoomIn={zoomIn}
              onZoomOut={zoomOut}
              onZoomFitScreen={zoomFit}
              onZoomReset={zoomReset}
              viewWidth={viewWidth}
              fileWidth={width}
              scale={scale}
              pagination={{
                total: totalPages,
                page: currentPage,
                onPrevPage: goPreviousPage,
                onNextPage: goNextPage
              }}
            />
          </Portal>
        </>
      )}
    </>
  );
};
