import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { FiltersBar as FiltersBarComponent } from '../../../../../../../components/FiltersBar';
import { TextField, OrderBy, orderDirectionsMap } from '../../../../../../../components/FormField';
import { IconComponent } from '../../../../../../../components/saved-filters';
import { CodesContext } from '../../CodesContext';
import { orderByOptions } from './orderByOptions';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { SavedFiltersList } from './SavedFiltersList';
import { CardContent } from './CardContent';

const MODAL_WIDTH = 490;

const initialValues = {
  order_by: orderByOptions[0],
  order_direction: orderDirectionsMap.desc
};

const transformRelationsForFilterToOptions = (relationsForFilter) => {
  const orderByValue = relationsForFilter?.order_by?.value ?? relationsForFilter?.order_by;

  return {
    ...relationsForFilter,

    order_by: orderByOptions.find(({ value }) => value === orderByValue)
  };
};

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const { applyFilter } = useContext(CodesContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState(initialValues);

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.value });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data }));
  };

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ order_direction: orderDirection });
    setRelationsForFilter((state) => ({ ...state, order_direction: orderDirection }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(transformRelationsForFilterToOptions(filter));

    applyFilter({
      ...filter,

      order_by: filter?.order_by?.value
    });
  };

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableTopPaddings
      formikRef={formikRef}
      initialValues={transformRelationsForFilterToOptions(initialValues)}
      hiddenFields={hiddenFields}
      filterKey={filterKey}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={SavedFiltersList}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      onReset={() => applySavedFilter(initialValues)}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.code,
          label: filterFieldsLabels[filterFieldsMap.code],
          field: (
            <TextField
              label="Procedure Code"
              margin="dense"
              name="code"
              placeholder="Search code..."
              onChange={handleFieldChange('code')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.description,
          label: filterFieldsLabels[filterFieldsMap.description],
          field: (
            <TextField
              label="Description"
              name="description"
              onChange={handleFieldChange('description')}
              margin="dense"
            />
          )
        },
        {
          fieldKey: filterFieldsMap.orderBy,
          label: filterFieldsLabels[filterFieldsMap.orderBy],
          field: <OrderBy
            name="order_by"
            options={orderByOptions}
            orderDirection={relationsForFilter.order_direction}
            onOptionChange={handleSelectChange('order_by')}
            onOrderDirectionChange={toggleOrderDirection}
            TextFieldProps={{
              margin: 'dense'
            }}
          />
        }
      ]}
    />
  );
};
