import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { TextField } from '../../../../../../../../components/FormField';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import {
  rangeMotionMap,
  columnsWidthsLg,
  columnsWidthsSm
} from '../../../../../../../../components/medical/reports';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const RangeMotion = ({ name }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <Box mt={4}>
      <Typography variant="h4">
        Range of motion-Servical spine
      </Typography>

      <div className={classes.root}>
        <ListRow header>
          <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
            <Typography noWrap variant="subtitle2" color="textSecondary">
              Cervical Spine Range of Motion
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.degrees_normal}>
            <Typography variant="subtitle2" color="textSecondary">
              Degrees Normal
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.range_of_motion}>
            <Typography variant="subtitle2" color="textSecondary">
              Range of Motion
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.fraction_of_normal}>
            <Typography variant="subtitle2" color="textSecondary">
              Fraction of Normal
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.pain}>
            <Typography variant="subtitle2" color="textSecondary">
              Pain
            </Typography>
          </ListRowCell>
        </ListRow>

        {Object.keys(rangeMotionMap)?.map((item, i) => (
          <ListRow key={i}>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
              <Typography noWrap variant="h5">
                {rangeMotionMap[item]}:
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.degrees_normal}>
              <TextField
                fullWidth
                zeroMinWidth
                disableUnderline
                placeholder="Add..."
                name={`${name}.${item}.degrees_normal`}
              />
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.range_of_motion}>
              <TextField
                fullWidth
                zeroMinWidth
                disableUnderline
                placeholder="Add..."
                name={`${name}.${item}.range_of_motion`}
              />
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.fraction_of_normal}>
              <TextField
                fullWidth
                zeroMinWidth
                disableUnderline
                placeholder="Add..."
                name={`${name}.${item}.fraction_of_normal`}
              />
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.pain}>
              <TextField
                fullWidth
                zeroMinWidth
                disableUnderline
                placeholder="Add..."
                name={`${name}.${item}.pain`}
              />
            </ListRowCell>
          </ListRow>
        ))}
      </div>
    </Box>
  );
};
