import { makeStyles, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../CreatePayment';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = () => {
  const classes = useStyles();

  return (
    <ListRow header className={classes.root}>
      <ListRowCell grow flexBasis={columnsWidths.invoice_date} className={classes.content}>
        <Typography className={classes.title}>
          Invoice Paid Date
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.due_on} className={classes.content}>
        <Typography className={classes.title}>
          Due On
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total} className={classes.content}>
        <Typography className={classes.title}>
          Total
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount} className={classes.content}>
        <Typography className={classes.title}>
          Payment Amount
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.balance} className={classes.content}>
        <Typography className={classes.title}>
          Balance
        </Typography>
      </ListRowCell>
    </ListRow>
  );
};
