import { Create } from './Create';
import { Edit } from './Edit';

export const routes = [
  {
    pathName: '/appointment-types/create',
    component: Create
  },
  {
    pathName: '/appointment-types/:id/edit',
    component: Edit
  }
];
