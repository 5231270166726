export const styles = ({ spacing, palette, breakpoints, typography: { fontWeightMedium } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    flexGrow: 1,
    padding: 0,

    [breakpoints.up('md')]: {
      padding: spacing(0, 3)
    }
  },

  mainContent: {
    flexGrow: 1,
    overflow: 'auto'
  },

  actionButton: {
    flexGrow: 0,
    marginLeft: 'auto',
    paddingBottom: spacing(2)
  },

  backButton: {
    marginRight: spacing()
  },

  formTitle: {
    fontWeight: fontWeightMedium,
    color: palette.darkgreen,
    marginBottom: spacing(2)
  },

  content: {
    marginBottom: spacing(2)
  },

  title: {
    fontWeight: fontWeightMedium,
    marginBottom: spacing()
  },

  contentInfo: {
    paddingLeft: spacing(2)
  },

  contentText: {
    color: palette.grey[500]
  },

  timeContent: {
    paddingLeft: spacing(2),
    display: 'flex',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },

  timeInfo: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(3),

    [breakpoints.down('sm')]: {
      marginBottom: spacing()
    }
  },

  timeField: {
    minWidth: 0,
    width: 70,
    marginLeft: spacing(1.5),
    marginRight: spacing(1.5)
  },

  painContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    paddingLeft: spacing(2)
  },

  painInfo: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingLeft: spacing(1.5),
    paddingTop: spacing(2),
    maxWidth: 120
  },

  painInfo_success: {
    color: palette.success.main
  },

  painInfo_error: {
    color: palette.error.main
  },

  label: {
    marginBottom: spacing()
  }
});
