export const styles = ({ spacing, breakpoints }) => ({
  root: {
    height: '100%',
    overflow: 'hidden',

    [breakpoints.up('md')]: {
      paddingBottom: spacing(2),
      paddingLeft: 4,
      paddingRight: 4
    },

    [breakpoints.down('md')]: {
      paddingBottom: spacing()
    },

    '& > * + *': {
      marginTop: spacing()
    }
  },

  rootClose: {
    [breakpoints.down('md')]: {
      height: 67
    }
  },

  rootInfoClose: {
    [breakpoints.down('md')]: {
      height: 63
    }
  }
});
