import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { SavedFiltersContext } from '../../../../../../components/saved-filters';
import { Scrollbars } from '../../../../../../components/Scrollbars';
import { Loader } from '../../../../../../components/Loader';
import { ListHeader } from './ListHeader';
import { Row } from './Row';

export const columnsWidths = {
  dateFrom: 120,
  dateTo: 120,
  immunizationId: 190,
  appointmentBookId: 190,
  office: 170,
  action: 60
};

export const List = ({ onApplySavedFilter }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No saved filters</Typography>
        ) : (
          <>
            <ListHeader />

            <MuiList disablePadding>
              <InfiniteListLoader
                items={savedFilters}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ onApplySavedFilter }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
