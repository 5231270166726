export const styles = ({
  palette: { text, divider },
  typography: { fontWeightMedium },
  spacing,
  breakpoints
}) => ({
  preview__header: {
    borderBottom: `1px solid ${divider}`,
    paddingBottom: spacing(1),
    paddingLeft: spacing(5),
    paddingRight: spacing(5),
    maxHeight: 140,

    [breakpoints.down('md')]: {
      maxHeight: '100%',
      padding: spacing(2)
    }
  },

  preview__title: {
    color: text.caption,
    fontWeight: fontWeightMedium
  }
});
