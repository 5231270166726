import { Grid, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { ReadonlyCheckbox } from '../../../../../../../../components/ReadonlyCheckbox';

export const DirectReferralContent = ({ dirreffInfo }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ReadonlyCheckbox checked={dirreffInfo.dirreff_attorney > 0} />
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Company Name</Typography>
      </Grid>

      <Grid item xs={8}>
        <CompanyLink
          size="sm"
          variant="h5"
          company={dirreffInfo.direct_attorney_company}
        />
        <Typography variant="h5">{dirreffInfo.direct_ref_name}</Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Address</Typography>
      </Grid>

      <Grid item xs={8}>
        {dirreffInfo?.direct_attorney_company?.full_address
          ? <Typography variant="h5">{dirreffInfo?.direct_attorney_company?.full_address}</Typography>
          : <Typography color="textSecondary">No info.</Typography>
        }
      </Grid>
    </Grid>
  );
};
