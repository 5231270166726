import { useContext, useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import filesize from 'filesize';
import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Loader } from '../../../../../../../components/Loader';
import { FilePreviewIcon, FilePreviewModal } from '../../../../../files-common';
import { FilesContext } from '../../../../FilesContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item: file = {}, isLoaded, recalculateHeight }) => {
  const classes = useStyles();
  const filesContext = useContext(FilesContext);

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ recalculateHeight, isLoaded ]);

  const deleteFile = () => {
    filesContext.deleteFiles([ file.id ]);
  };

  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        files: filesContext.files,
        isFetched: filesContext.isFetched,
        isFetching: filesContext.isFetching,
        filter: filesContext.filter,
        onNeedNextPage: filesContext.loadNextPage
      }}
    />
  ), [ file, filesContext.files ]);

  return (
    !isLoaded ? <Loader className={classes.loader}/> :
      <ListItem>
        <FilePreviewIcon
          file={file}
          onClick={openFilesPreview}
        />

        <ListItemText
          primary={file.original_filename || file.name}
          primaryTypographyProps={{ noWrap: true }}
        />

        <ListItemText
          primary={filesize(file?.size)}
          className={classes.filesListItemText}
        />

        <ListItemIcon onClick={deleteFile} className={classes.filesListItemText}>
          <DeleteIcon color="secondary" fontSize="small"/>
        </ListItemIcon>
      </ListItem>
  );
};
