import { LayoutContextProvider, viewVariantsMap } from '../../LayoutContext';
import { ScheduleEventsFilterProvider } from '../index';
import { ScheduleProvider } from '../ScheduleProvider';
import { CalendarProvider } from './CalendarProvider';
import { ScheduleBody } from './ScheduleBody';

export const Schedule = ({ filter, ...props }) => {
  return (
    <LayoutContextProvider initialViewVariant={viewVariantsMap.calendar}>
      <ScheduleEventsFilterProvider filter={filter} filterKey={props?.filterKey}>
        <ScheduleProvider>
          <CalendarProvider>
            <ScheduleBody {...props} />
          </CalendarProvider>
        </ScheduleProvider>
      </ScheduleEventsFilterProvider>
    </LayoutContextProvider>
  );
};
