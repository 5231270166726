import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, useMediaQuery } from '@material-ui/core';
import * as workClockApi from '../../../api/work-clocks';
import { trackTypesOptions } from '../../FormField';
import { Loader } from '../../Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { Body, getValidationSchema } from '../CreateWorkClocksModal';

export const EditWorkClocksModal = ({
  DialogProps,
  payload: { trackID },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [ track, setTrack ] = useState(null);
  const type = trackTypesOptions?.find(({ value }) => value === track?.type) || null;

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return workClockApi.updateTimer(values).then((track) => {
      enqueueSnackbar('Track successfully updated', { variant: 'success' });
      handleModalResolve(track);
    }).catch(() => {
      enqueueSnackbar('Track not updated', { variant: 'error' });
    });
  };

  useEffect(() => {
    workClockApi.fetchTrack(trackID).then((track) => {
      setTrack(track);
    });
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <Loader loading={!track} p={3} render={
        () => (
          <Formik
            initialValues={{ ...track, type }}
            validationSchema={getValidationSchema()}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleSubmit }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader onClose={handleModalReject}>
                  Edit
                </ModalHeader>

                <ModalBody>
                  <Body />
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
