import cn from 'classnames';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../ListRow';
import { mobileColumnWidths } from '../TimeTracking';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MobileHeader = ({ isEditable }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <ListRow header className={cn(classes.root, { [classes.root_widget]: isMobile })}>
      <ListRowCell flexBasis={mobileColumnWidths.type}>
        Type/
        Parent
      </ListRowCell>

      <ListRowCell flexBasis={mobileColumnWidths.parentInfo}>
        Parent name / Note
      </ListRowCell>

      <ListRowCell flexBasis={mobileColumnWidths.user}>
        User
      </ListRowCell>

      <ListRowCell flexBasis={mobileColumnWidths.company}>
        Company
      </ListRowCell>

      <ListRowCell grow flexBasis={mobileColumnWidths.category}>
        Category
      </ListRowCell>

      <ListRowCell flexBasis={mobileColumnWidths.startedTime}>
        Started at/Finished at
      </ListRowCell>

      <ListRowCell flexBasis={mobileColumnWidths.total}>
        Duration/Total
      </ListRowCell>

      {isEditable && <ListRowCell flexBasis={mobileColumnWidths.action}/>}
    </ListRow>
  );
};
