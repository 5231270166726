import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required(),
  description: Yup.string().nullable(),
  directory: Yup.string().nullable().required(),
  login: Yup.string().nullable().required(),
  password: Yup.string().nullable().required(),
  users: Yup.array().nullable().required(),
  user_id: Yup.mixed().nullable()
});
