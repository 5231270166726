export const styles = ({ spacing }) => ({
  table: {
    width: '100%',
    textAlign: 'left',

    '& td, & th': {
      paddingBottom: spacing(1.5)
    }
  },

  th: {
    paddingRight: spacing(5),
    verticalAlign: 'initial'
  },

  participants: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  participant: {
    marginRight: spacing(0.5),
    marginBottom: spacing(0.5)
  }
});
