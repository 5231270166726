import { ListRow, ListRowCell } from '../../../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell noWrap flexBasis={columnsWidths.type}>
        Discovery type
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.users}>
        Users
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.companies}>
        Companies
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dueDate}>
        Due Date
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.sendDate}>
        Send Date
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dueDate}>
        Received Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
