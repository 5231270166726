import { palette } from '../../../../../../themes/palette';

export const labelsMap = {
  billed: 'Billed',
  paid: 'Paid',
  unpaid: 'Unpaid',
  balance_due: 'Balance Due',
  over_due: 'Over Due'
};

export const labelsColorMap = {
  billed: palette().grey[300],
  paid: palette().purple,
  unpaid: palette().pink,
  balance_due: palette().amberyellow,
  over_due: palette().lightorange
};
