export const styles = ({ spacing, palette: { text, ...palette } }) => ({
  root: {
    marginBottom: spacing(0.75),
    padding: spacing(0.5, 2),
    background: palette.grey[100],
    borderRadius: 15,
    color: text.secondary
  },

  name: {
    margin: spacing(0, 2.5, 0, 0),
    padding: 0,
    width: 125,
    flex: 'none'
  },

  nameText: {
    fontWeight: 500,
    color: text.primary
  },

  subject: {
    margin: 0,
    padding: 0
  },

  filesIcon: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },

  filesDivider: {
    display: 'inline-block',
    width: 1,
    height: 15,
    margin: spacing(0, 1.5),
    verticalAlign: 'middle',
    backgroundColor: palette.divider
  },

  date: {
    margin: spacing(0, 8, 0, 10),
    padding: 0,
    flex: 'none'
  },

  remove: {
    marginLeft: 'auto',
    padding: 0
  }
});
