import { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TagsProvider } from '../../../../app/Dashboard/files-common';
import { StyledTreeItem } from './StyledTreeItem';
import { Folders } from '../Folders';
import { Menu } from './Menu';

export const Folder = ({ collapseButtonUid, folder, selected, setExpanded, onFolderToggleSelection }) => {
  const nodeId = folder.id.toString();
  const [ isSelected, setIsSelected ] = useState();

  const handleFolderClick = (event) => {
    if (!event.target.closest(`[data-uid="${collapseButtonUid}"]`)) {
      onFolderToggleSelection(folder);
    }
  };

  useEffect(() => {
    setIsSelected(selected?.includes(nodeId));
  }, [ selected ]);

  return (
    <TagsProvider filter={{ parent_id: folder?.id }}>
      <StyledTreeItem
        // When using selected?.includes(nodeId) directly, styles are not applied on first render
        selected={isSelected}
        disabled={!folder.children_count}
        nodeId={nodeId}
        background={folder.color}
        label={
          <Grid container alignItems="center" wrap="nowrap" spacing={1}>
            <Grid item xs>
              <Typography noWrap>
                {folder.name}
              </Typography>
            </Grid>

            <Grid item>
              <Menu folder={folder} setExpanded={setExpanded} />
            </Grid>
          </Grid>
        }
        onClick={handleFolderClick}
      >
        {!!folder.children_count && (
          <Folders
            collapseButtonUid={collapseButtonUid}
            selected={selected}
            setExpanded={setExpanded}
            onFolderToggleSelection={onFolderToggleSelection}
          />
        )}
      </StyledTreeItem>
    </TagsProvider>
  );
};
