export const filterFieldsMap = {
  dateFrom: 'date_from',
  dateTo: 'date_to',
  immunizationId: 'immunization_id',
  appointmentBookId: 'appointment_book_id',
  office: 'office_id'
};

export const filterFieldsLabels = {
  [filterFieldsMap.dateFrom]: 'Date From',
  [filterFieldsMap.dateTo]: 'Date To',
  [filterFieldsMap.immunizationId]: 'Immunization',
  [filterFieldsMap.appointmentBookId]: 'Appointment Book',
  [filterFieldsMap.office]: 'Office'
};
