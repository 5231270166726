import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { makeStyles, Link as MuiLink } from '@material-ui/core';
import WorkOutlineIcon from '@material-ui/icons/WorkOutlineOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import { getUserFullName } from '../../../../helpers/users';
import { filesOwnersTypesMap } from '../filesOwnersTypesOptions';
import { styles } from './styles';

const ownersTypes = {
  [filesOwnersTypesMap.user]: {
    title: 'Personal',
    icon: AccountCircleIcon
  },
  [filesOwnersTypesMap.profile]: {
    title: 'Profile',
    icon: AccountCircleIcon
  },
  [filesOwnersTypesMap.case]: {
    title: 'Case',
    icon: WorkOutlineIcon
  },
  [filesOwnersTypesMap.task]: {
    title: 'Task',
    icon: PlaylistAddCheckIcon
  }
};

export const ownersLinks = {
  [filesOwnersTypesMap.task]: (id) => `/tasks/${id}`,
  [filesOwnersTypesMap.case]: (id) => `/cases/${id}/files`,
  [filesOwnersTypesMap.user]: (id) => `/members/users/${id}`,
  [filesOwnersTypesMap.profile]: (id) => `/members/users/${id}/files`
};


const ownersNames = {
  [filesOwnersTypesMap.user]: getUserFullName,
  [filesOwnersTypesMap.profile]: getUserFullName,
  [filesOwnersTypesMap.case]: (owner) => owner.name,
  [filesOwnersTypesMap.task]: (owner) => owner.name
};

const useStyles = makeStyles(styles);

export const FileOwner = ({ owner_type, owner, className, classes: classesProp = {} }) => {
  const classes = useStyles();
  const { user: currentUser } = useSelector(({ profile }) => profile);
  const getOwnerName = ownersNames[owner_type];

  if (!getOwnerName) {
    return null;
  }

  const Icon = ownersTypes[owner_type].icon;
  const isPersonalFile = owner_type === filesOwnersTypesMap.user && currentUser.id === owner.id;

  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <MuiLink
      component={Link}
      to={isPersonalFile ? '/files' : ownersLinks[owner_type](owner.id)}
      target="_blank"
      className={cn(classes.root, className)}
      onClick={handleClick}
    >
      {!isPersonalFile &&
        <div
          title={ownersTypes[owner_type].title}
          className={classes.label}
        >
          <Icon className={cn(classes.label__icon, classesProp.label__icon)}/>
        </div>
      }

      {isPersonalFile ? 'personal' : getOwnerName(owner)}
    </MuiLink>
  );
};
