import { useState } from 'react';
import { Dialog, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import * as documentsApi from '../../../../api/files/html-documents';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../components/Modal';
import { Loader } from '../../../../components/Loader';
import { TemplatesContextProvider } from '../../FilesPage/TemplatesPage/TemplatesContext';
import { FiltersBar } from './FiltersBar';
import { List } from './List';

export const SelectTemplateModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const [ selectedTemplate, setSelectedTemplate ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isReplaceable, setIsReplaceable ] = useState(true);

  const handleCheckboxToggle = () => {
    setIsReplaceable((checked) => !checked);
  };

  const loadTemplate = () => {
    setIsSubmitting(true);

    documentsApi.getTemplate(selectedTemplate.id).then((template) => {
      handleModalResolve({
        isReplaceable,
        template
      });
    });
  };

  return (
    <Dialog disableEnforceFocus maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Templates
        </ModalHeader>

        <ModalBody disableScrollbars disablePaddings>
          <TemplatesContextProvider>
            <FiltersBar />
            <List selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
          </TemplatesContextProvider>
        </ModalBody>

        <ModalFooter
          title={
            <FormControlLabel
              label="Replace current content"
              control={<Checkbox checked={isReplaceable} onChange={handleCheckboxToggle} />}
            />
          }
        >
          <Loader surface loading={isSubmitting} render={
            () => (
              <Button
                disabled={isSubmitting || !selectedTemplate}
                variant="contained"
                color="primary"
                onClick={loadTemplate}
              >
                Insert
              </Button>
            )}
          />
        </ModalFooter>
      </ModalContainer>
    </Dialog>
  );
};
