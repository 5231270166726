import { Fragment, useRef, useState } from 'react';
import { isUndefined } from 'lodash';
import { Collapse, useMediaQuery, useTheme } from '@material-ui/core';
import { FilterBarToggler } from '../components/FiltersBar';
import { Scrollbars } from '../components/Scrollbars';

export const useMobileFilterCollapse = (size = 'lg', width = null, isActiveProp) => {
  const [ openFiltersBar, setOpenFiltersBar ] = useState(false);
  const theme = useTheme();
  const tablet = useMediaQuery((theme) => theme.breakpoints.down(size));
  const isTablet = width ? width <= theme.breakpoints.values?.[size] : tablet;
  const isActive = isUndefined(isActiveProp) ? isTablet : isActiveProp;
  const FilterBarWrapper = isActive ? Collapse : Fragment;
  const filterBarWrapperProps = isActive ? {
    in: openFiltersBar,
    style: {
      overflow: 'hidden',
      flexShrink: 0
    }
  } : {};
  const ScrollWrapper = isActive ? Scrollbars : Fragment;
  const scrollbarRef = useRef();
  const scrollWrapperProps = !isActive ? {} : {
    autoHeight: true,
    autoHeightMax: `calc(100vh - ${scrollbarRef.current?.getBoundingClientRect().top}px)`,
    containerRef: scrollbarRef
  };

  const toggleFiltersBar = () => {
    setOpenFiltersBar(!openFiltersBar);
  };

  const filterOpenButton = (
    <FilterBarToggler handleToggleFiltersBar={toggleFiltersBar} />
  );

  return {
    filterOpenButton,
    openFiltersBar,
    ScrollWrapper,
    scrollWrapperProps,
    FilterBarWrapper,
    filterBarWrapperProps,
    setOpenFiltersBar,
    toggleFiltersBar
  };
};
