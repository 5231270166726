import { useContext, useEffect } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutContext, viewVariantsMap } from '../../LayoutContext';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

export const TimeReports = ({
  filterKey,
  hiddenFilterFields,
  hiddenColumns,
  FilterBarWrapper,
  filterBarWrapperProps,
  ScrollWrapper,
  scrollWrapperProps
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  useEffect(() => {
    if (isMobile) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ isMobile ]);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} width="100%">
      <ScrollWrapper {...scrollWrapperProps}>
        <FilterBarWrapper {...filterBarWrapperProps}>
          <FiltersBar filterKey={filterKey} hiddenFields={hiddenFilterFields} />
        </FilterBarWrapper>
      </ScrollWrapper>

      <Box flexGrow={1} bgcolor="white">
        {(viewVariant === viewVariantsMap.list) && <List hiddenColumns={hiddenColumns} />}
        {(viewVariant === viewVariantsMap.grid) && <Grid />}
      </Box>
    </Box>
  );
};
