import { useContext } from 'react';
import { Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import AddIcon from '@material-ui/icons/Add';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { useModal } from '../../../../../components/ModalsProvider';
import { PageHeaderActions } from '../../../../../components/Page';
import { IconButton } from '../../../../../components/IconButton';
import { Fab } from '../../../../../components/Fab';
import { BREAKPOINT_WIDTH } from '../PageContent';
import { WaitingListContext } from '../WaitingListProvider';
import { CreateModal } from '../CreateModal';

export const ActionsBar = ({ width, filterOpenButton }) => {
  const theme = useTheme();
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { fetchWaitingList } = useContext(WaitingListContext);

  const toggleViewVariant = (variant) => () => {
    setViewVariant(variant);
  };

  const addWaitingListItem = () => {
    openModal(CreateModal, {
      onModalResolved: () => {
        fetchWaitingList();
      }
    });
  };

  return (
    <>
      <PageHeaderActions startPosition>
        <Fab
          variant="extended"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addWaitingListItem}
        >
          Add new
        </Fab>
      </PageHeaderActions>

      <PageHeaderActions endPosition>
        {isMobile && filterOpenButton}

        {(width > BREAKPOINT_WIDTH) && (
          <>
            <Tooltip title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </PageHeaderActions>
    </>
  );
};
