import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../../../../helpers/hooks/useResizeObserver';
import { TablePagination } from '../../../../../../components/TablePagination';
import { Loader } from '../../../../../../components/Loader';
import { PaymentsContext } from '../../../../../../components/billing/payments';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const widthBreakpointXXS = 650;
export const widthBreakpointXS = 800;
export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1400;
export const widthBreakpointLarge = 1600;
export const columnsMap = {
  checkbox: 'checkbox',
  number: 'number',
  payer: 'payer',
  paidDate: 'paidDate',
  description: 'description',
  dueOn: 'dueOn',
  method: 'method',
  total: 'total',
  amount: 'amount',
  balance: 'balance',
  actions: 'actions',
  overdraftActions: 'overdraftActions'
};

export const columnsWidths = {
  [columnsMap.number]: 150,
  [columnsMap.payer]: 170,
  [columnsMap.paidDate]: 100,
  [columnsMap.description]: 150,
  [columnsMap.dueOn]: 100,
  [columnsMap.method]: 170,
  [columnsMap.total]: 120,
  [columnsMap.amount]: 120,
  [columnsMap.balance]: 120,
  [columnsMap.actions]: 70,
  [columnsMap.overdraftActions]: 240
};

export const List = ({ hiddenColumns }) => {
  const {
    payments,
    isFetched,
    meta,
    resetPayments
  } = useContext(PaymentsContext);
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });

  useEffect(() => {
    resetPayments();
  }, []);

  return (
    <Box ref={rootRef} display="flex" flexDirection="column" height="100%" overflow="hidden">
      <Loader loading={!isFetched} p={3} render={
        () => (!payments.length ?
          <Box p={3}>
            <Typography align="center">No payments found</Typography>
          </Box>
          :
          <>
            <TableHeader width={width} hiddenColumns={hiddenColumns} />

            <MuiList disablePadding>
              {payments.map((payment) => (
                <Row
                  key={payment.id}
                  width={width}
                  payment={payment}
                  hiddenColumns={hiddenColumns}
                />
              ))}
            </MuiList>

            <TablePagination pagination={meta} onChange={resetPayments} />
          </>
        )}
      />
    </Box>
  );
};
