import { useRef, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, SvgIcon, Box, useTheme, useMediaQuery, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import { mdiCalendarPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as claimsApi from '../../../api/claims';
import { useResizeObserver } from '../../../helpers/hooks';
import { SideBar } from '../../../app/Dashboard/BillingPage/ClaimsPage/SideBar';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../Modal';
import { Loader } from '../../Loader';
import { VerticalDivider } from '../../VerticalDivider';
import { ClaimForm, claimInitialValues, validationSchema } from '../ClaimForm';

export const CreateClaimModal = ({
  payload: {
    initialValues = {},
    isFixedCase = false,
    isFixedPatient = false
  },
  isModalEntered,
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState(isDesktop);
  const rootRef = useRef(null);
  const { width = window.innerWidth } = useResizeObserver({ ref: rootRef });

  const createClaim = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.patient_id = values?.patient_id?.id || values.patient_id;
    values.icd_codes = values?.icd_codes?.map((item) => item?.id);

    return claimsApi.createClaim(values).then((claim) => {
      enqueueSnackbar('Claim successfully created', { variant: 'success' });
      handleModalResolve(claim);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Please, check the form', { variant: 'error' });
      }
    });
  };

  const handleDrawerOpen = () => {
    setSidebarIsOpen((isOpen) => !isOpen);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...claimInitialValues,
        ...initialValues
      }}
      validationSchema={validationSchema}
      onSubmit={createClaim}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarPlus} /></SvgIcon>} onClose={handleModalReject}>
              Add new claim
            </ModalHeader>

            <ModalBody disablePaddings>
              <Loader p={2} loading={!isModalEntered} render={
                () => (
                  <Box ref={rootRef} display="flex" height="100%">
                    <Box display="flex">
                      <SideBar width={width} claim={values} sidebarIsOpen={sidebarIsOpen} />
                    </Box>

                    <VerticalDivider />

                    <Box p={2} pt={isMobile ? 0 : 2}>
                      {isMobile && (
                        <IconButton
                          edge="start"
                          color="primary"
                          title={sidebarIsOpen ? 'Hide sidebar' : 'Show sidebar'}
                          onClick={handleDrawerOpen}
                        >
                          {sidebarIsOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                        </IconButton>
                      )}

                      <ClaimForm
                        isFixedPatient={isFixedPatient}
                        isFixedCase={isFixedCase}
                      />
                    </Box>
                  </Box>
                )}
              />
            </ModalBody>

            <ModalFooter>
              <Button disabled={isSubmitting} onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Add
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
