import { useEffect, useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { NestedList } from '../../../../../../../components/NestedList';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { typesNamesMap } from './typesMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AccidentInfo = ({ isOpenSwitches, form }) => {
  const classes = useStyles();
  const [ isOpen, setIsOpen ] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(form?.forms?.accident_information) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Accident information"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box p={isSmallScreen ? 1 : 3}>
        {form?.accident_type ?
          <>
            <div className={cn(classes.content, classes.content_row)}>
              <Typography className={classes.formContent}>Date of Injury</Typography>

              <Typography color="textSecondary">
                {form?.forms?.accident_information?.doi
                  ? moment(form?.forms?.accident_information?.doi).format('L')
                  : form.doi_string
                }
              </Typography>
            </div>

            <div className={cn(classes.content, classes.content_row)}>
              <Typography className={classes.formContent}>
                Type
              </Typography>

              {form?.accident_type &&
                <ReadonlyCheckbox
                  checked
                  title={form?.accident_type}
                />
              }
            </div>

            {form?.accident_type === typesNamesMap.car_accident &&
              <>
                <div className={classes.content}>
                  <Typography className={classes.formContent}>
                    Were you the driver, passenger, or in the back seat?
                  </Typography>

                  <Box py={1}>
                    <ReadonlyCheckbox
                      checked={!!form?.forms?.accident_information?.you_driver_passenger}
                      title={form?.forms?.accident_information?.you_driver_passenger}
                    />
                  </Box>

                  {!!form?.forms?.accident_information?.you_driver_description &&
                    <Typography color="textSecondary">
                      {form?.forms?.accident_information?.you_driver_description}
                    </Typography>
                  }
                </div>

                {form?.forms?.accident_information?.car_police_report_filed && (
                  <div className={cn(classes.content, classes.content_row)}>
                    <Typography className={classes.formContent}>
                      Was a police report filed?
                    </Typography>

                    <ReadonlyCheckbox
                      checked
                      title={form?.forms?.accident_information?.car_police_report_filed > 0 ? 'Yes' : 'No'}
                    />
                  </div>
                )}

                {!!form?.forms?.accident_information?.car_where_accident_occur && (
                  <div className={classes.content}>
                    <Typography className={classes.formContent}>
                      Where did the accident occur?
                    </Typography>

                    <Typography color="textSecondary">
                      {form?.forms?.accident_information?.car_where_accident_occur}
                    </Typography>
                  </div>
                )}

                {!!form?.forms?.accident_information?.car_how_accident_occurred && (
                  <div className={classes.content}>
                    <Typography className={classes.formContent}>
                      Please briefly explain how the accident occurred.
                    </Typography>

                    <Typography color="textSecondary">
                      {form?.forms?.accident_information?.car_how_accident_occurred}
                    </Typography>
                  </div>
                )}
              </>
            }

            {form?.accident_type === typesNamesMap.slip_and_fall &&
              <>
                {!!form?.forms?.accident_information?.slip_where_accident_occur && (
                  <div className={classes.content}>
                    <Typography className={classes.formContent}>
                      Where did the accident occur?
                    </Typography>

                    <Typography color="textSecondary">
                      {form?.forms?.accident_information?.slip_where_accident_occur}
                    </Typography>
                  </div>
                )}

                {form?.forms?.accident_information?.slip_police_report_filed && (
                  <div className={cn(classes.content, classes.content_row)}>
                    <Typography className={classes.formContent}>
                      Was a police report filed?
                    </Typography>

                    <ReadonlyCheckbox
                      checked
                      title={form?.forms?.accident_information?.slip_police_report_filed > 0 ? 'Yes' : 'No'}
                    />
                  </div>
                )}

                {!!form?.forms?.accident_information?.slip_how_accident_occurred && (
                  <div className={classes.content}>
                    <Typography className={classes.formContent}>
                      Please briefly explain how the accident occurred.
                    </Typography>

                    <Typography color="textSecondary">
                      {form?.forms?.accident_information?.slip_how_accident_occurred}
                    </Typography>
                  </div>
                )}
              </>
            }

            {form?.accident_type === typesNamesMap.other &&
              <div className={classes.content}>
                <Typography className={classes.formContent}>
                  Please briefly explain.
                </Typography>

                <Typography color="textSecondary">
                  {form?.forms?.accident_information?.explain || 'No info.'}
                </Typography>
              </div>
            }
          </>
          :
          <Typography color="textSecondary">No info.</Typography>
        }
      </Box>
    </NestedList>
  );
};
