import { RRule } from 'rrule';
import { useField } from 'formik';
import moment from 'moment';
import { Fab, FormControl, FormLabel, Grid } from '@material-ui/core';

const weekdays = moment.weekdaysMin().map((day) => ({
  name: day,
  rrule: RRule[day.toUpperCase()]
}));

export const Weekdays = () => {
  const formikField = useField('byweekday');
  const { value } = formikField[0];
  const { setValue } = formikField[2];

  const isSelected = (day) => {
    return value.indexOf(day) !== -1;
  };

  const handleDayClick = (day) => () => {
    if (!isSelected(day)) {
      setValue(value.concat(day));
    } else if (value.length > 1) {
      setValue(value.filter((selectedDay) => selectedDay !== day));
    }
  };

  return (
    <FormControl fullWidth component="fieldset" margin="dense">
      <FormLabel component="legend">Repeat on</FormLabel>

      <FormControl fullWidth margin="dense">
        <Grid container spacing={1}>
          {weekdays.map(({ name, rrule }) => (
            <Grid item key={name}>
              <Fab
                color={isSelected(rrule) ? 'primary' : 'default'}
                onClick={handleDayClick(rrule)}
              >
                {name[0]}
              </Fab>
            </Grid>
          ))}
        </Grid>
      </FormControl>
    </FormControl>
  );
};
