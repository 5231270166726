import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon, useMediaQuery,
  useTheme
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Icon } from '@mdi/react';
import { mdiCogTransferOutline } from '@mdi/js';
import { IconButton } from '../../../../components/IconButton';

export const Menu = ({ isOpenSidebar = false, routes, toggleSideBar = () => {} }) => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const isActiveRoute = (path) => {
    return location.pathname.indexOf(path) === 0;
  };

  return (
    <>
      {isMobile && (
        <Box mt={-1} pb={1} px={1} display="flex" justifyContent="flex-end">
          <IconButton onClick={toggleSideBar}>
            {isOpenSidebar ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon color="primary" />}
          </IconButton>
        </Box>
      )}

      <List disablePadding component="nav">
        {routes.map(({ path, label }) => (
          <ListItem
            key={path}
            button
            selected={isActiveRoute(path)}
            component={Link}
            to={path}
            onClick={isMobile ? toggleSideBar : undefined}
          >
            <ListItemIcon>
              <SvgIcon><Icon path={mdiCogTransferOutline} /></SvgIcon>
            </ListItemIcon>

            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </>
  );
};
