import { useContext } from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { stopPropagation } from '../../../../../../helpers/dom';
import { generateAddress } from '../../../../../../utils/generateAddress';
import { UserPreviewModal } from '../../../../../../components/users';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../components/Tooltip';
import { ReportsPreviewContext } from '../../../ReportsPreviewProvider';
import { MedicationsList } from './MedicationsList';

export const MainContent = () => {
  const { openModal } = useModal();
  const { selectedItem: prescription, appointment } = useContext(ReportsPreviewContext);

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" component={Box} pt={3}>
        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Patient</Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <UserLink
            variant="h5"
            user={prescription.patient}
            onClick={stopPropagation(openUserPreview(prescription?.patient?.id))}
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Approved by</Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <UserLink
            variant="h5"
            user={prescription?.approved}
            onClick={stopPropagation(openUserPreview(prescription?.approved?.id))}
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Prescribed date</Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <CalendarIcon fontSize="small" />
            </Box>

            <Typography variant="h5">
              {prescription.prescribed_at ? moment.unix(prescription.prescribed_at).format('L, LT') : '-'}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Office/Practice Address</Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Tooltip
            isExistTooltip={!!prescription?.office}
            title={prescription?.office?.full_address || generateAddress(prescription?.office)}
          >
            <OfficeLink
              variant="h5"
              office={prescription?.office}
            />
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="flex-start" component={Box} py={5}>
        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Type Insurance</Typography>
        </Grid>

        <Grid item sm={10} xs={6}>
          <Typography variant="h5">
            {appointment?.patient_insurance?.type_insurance || '-'}
          </Typography>
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Insurance ID</Typography>
        </Grid>

        <Grid item sm={10} xs={6}>
          <Typography variant="h5">
            {appointment?.patient_insurance?.insuranceId || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Box pb={5}>
        <MedicationsList />
      </Box>
    </>
  );
};
