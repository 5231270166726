export const styles = ({ spacing, shape: { borderRadius } }) => ({
  root: {
    margin: spacing(),
    padding: spacing(1, 2),
    borderRadius
  },

  info: {
    marginBottom: spacing()
  },

  label: {
    width: 72
  }
});
