import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import { getUserFullName } from '../../../../../helpers/users';
import {
  TextField,
  CompaniesAutocomplete,
  OfficesLocationSelect
} from '../../../../../components/FormField';

export const WorkInfo = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleCompanyChange = (company) => {
    if (values?.work?.office_id) {
      setFieldValue('work.office_id', null);
    }

    setFieldValue(
      'work.company.contact_name',
      company?.contact_user ? getUserFullName(company?.contact_user) : null
    );
  };

  return (
    <Grid container spacing={2} component={Box} pb={5}>
      <Grid item xs={12}>
        <Typography variant="h4">Work Info</Typography>
      </Grid>

      <Grid item xs={8}/>

      <Grid item xs={6}>
        <CompaniesAutocomplete
          name="work.company_id"
          label="Company"
          placeholder="Select company..."
          onChange={handleCompanyChange}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          name="work.company.contact_name"
          label="Contact Person"
        />
      </Grid>

      <Grid item xs={12}>
        <OfficesLocationSelect
          multiple
          disabled={!values?.work?.company_id}
          name="work.office_id"
          label="Office"
          placeholder="Select office..."
          params={{ company_id: values?.work?.company_id }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="work.position"
          label="Position"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="work.department"
          label="Department"
        />
      </Grid>
    </Grid>
  );
};
