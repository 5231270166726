export const styles = ({
  spacing,
  palette: { text, error, divider },
  typography: { fontWeightMedium, pxToRem }
}) => ({
  preview: {
    width: '100%',
    borderBottom: `1px solid ${divider}`,
    paddingLeft: spacing(5),
    paddingRight: spacing(5),
    paddingTop: spacing(1.5),
    paddingBottom: spacing(2)
  },

  preview_static: {
    paddingLeft: spacing(4),
    paddingRight: spacing(4)
  },

  preview__owner: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '&:first-child': {
      marginBottom: spacing(1)
    }
  },

  preview__avatar: {
    marginRight: spacing(1.5)
  },

  preview__title: {
    color: text.caption,
    fontWeight: fontWeightMedium,
    minWidth: 70
  },

  preview__tracker: {
    color: text.caption,
    fontWeight: fontWeightMedium
  },

  preview__due: {
    color: error.main
  },

  assignments__button: {
    width: pxToRem(30),
    height: pxToRem(30),
    minHeight: pxToRem(30),
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '&:hover, &:active': {
      boxShadow: '0px 7px 8px -4px rgba(0,0,0,0.2)'
    }
  },

  userName: {
    marginLeft: spacing(1.5)
  },

  ownerAvatar: {
    marginLeft: 0
  },

  preview__trackerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
