import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../../../components/LayoutContext';
import { Page, PageBody, PageHeader } from '../../../../components/Page';
import { useModal } from '../../../../components/ModalsProvider';
import { ActionsBar } from '../ActionsBar';
import { PrescriptionsContext, PrescriptionsProvider } from './PrescriptionsProvider';
import { CreateModal } from './CreateModal';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

export const Prescriptions = ({ patient, filter, hiddenColumns, hiddenFilterFields }) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  const openCreateModal = (createPrescription) => () => {
    openModal(CreateModal, {
      payload: {
        patient
      },
      onModalResolved: (data) => {
        createPrescription(data);
      }
    });
  };

  return (
    <LayoutContextProvider>
      <LayoutContext.Consumer>
        {({ viewVariant }) => (
          <PrescriptionsProvider initialState={{ filter }}>
            <Page>
              <PageHeader disableBorderBottom>
                <PrescriptionsContext.Consumer>
                  {({ pagination, createPrescription }) => (
                    <ActionsBar
                      disableViewVariant={isTablet}
                      actionTitle="Add new"
                      pagination={pagination}
                      onCreate={openCreateModal(createPrescription)}
                      filterOpenButton={filterOpenButton}
                    />
                  )}
                </PrescriptionsContext.Consumer>
              </PageHeader>

              <PageBody fullHeight disablePaddings disableScrollbars>
                <ScrollWrapper {...scrollWrapperProps}>
                  <FilterBarWrapper {...filterBarWrapperProps}>
                    <FiltersBar
                      hiddenFilterFields={hiddenFilterFields}
                      filterKey={filtersKeysMap.prescriptions}
                    />
                  </FilterBarWrapper>
                </ScrollWrapper>

                <Box flexGrow={1}>
                  {!isTablet ? (
                    (viewVariant === viewVariantsMap.list)
                      ? <List hiddenColumns={hiddenColumns} />
                      : (viewVariant === viewVariantsMap.grid)
                        ? <Grid hiddenColumns={hiddenColumns} />
                        : null
                  ) : (
                    <Grid hiddenColumns={hiddenColumns} />
                  )}
                </Box>
              </PageBody>
            </Page>
          </PrescriptionsProvider>
        )}
      </LayoutContext.Consumer>
    </LayoutContextProvider>
  );
};
