import { useSelector } from 'react-redux';
import { every, isEmpty } from 'lodash';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { Loader } from '../../../components/Loader';
import { ClientProfilePage } from './ClientProfilePage';
import { MainProfilePage } from './MainProfilePage';

export const ProfilePage = () => {
  const { user } = useSelector(({ profile }) => profile);

  return every(user, isEmpty) ? <Loader p={3} /> : (
    hasRole(rolesMap.client, rolesMap.patient)
      ? <ClientProfilePage />
      : <MainProfilePage />
  );
};
