export const styles = ({ spacing, palette: { text, ...palette } }) => ({
  root: {
    padding: spacing(0, 2.75),
    position: 'relative',
    overflow: 'hidden',
    borderBottom: `1px solid ${palette.divider}`
  },

  rootExpanded: {
    paddingBottom: spacing(5)
  },

  list: {
    maxHeight: 128,
    padding: spacing(1.25, 0, 0.5),
    overflow: 'hidden'
  },

  listExpanded: {
    maxHeight: 310,
    overflow: 'auto'
  },

  listMore: {
    width: '100%',
    padding: spacing(1, 3.5),
    position: 'absolute',
    left: 0,
    bottom: 0,
    textAlign: 'right',
    background: 'linear-gradient(to bottom, transparent, #fff 40%)'
  },

  listMoreButton: {
    textTransform: 'none'
  }
});
