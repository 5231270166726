import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { TextField } from '../../../../../../components/FormField';
import { TableHeader } from '../TableHeader';
import { PaymentCard } from './PaymentCard';
import { PaymentRow } from './PaymentRow';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PaymentForm = ({
  width,
  isOverdraftPage,
  parentPage = '',
  invoice,
  selectedInvoices
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { values, setValues } = useFormikContext();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const total = selectedInvoices?.reduce((prev, next) => prev + next.total, 0);
  const balance = selectedInvoices?.reduce((prev, next) => prev + next.balance, 0);
  const paidDate = invoice?.date
    ? moment(invoice.date).format('L')
    : selectedInvoices.length
      ? moment(selectedInvoices[selectedInvoices.length - 1].date).format('L')
      : null;

  useEffect(() => {
    setValues({
      ...values,

      due_date: selectedInvoices.length
        ? selectedInvoices[selectedInvoices.length - 1].due_date
        : invoice ? invoice.due_date : null
    });
  }, [ selectedInvoices ]);

  return (
    <div className={classes.root}>
      <div className={classes.rootForm}>
        {!isMobile && <TableHeader width={width} />}

        {isMobile ? (
          <PaymentCard
            isOverdraftPage={isOverdraftPage}
            total={total}
            balance={balance}
            invoice={invoice}
            paidDate={paidDate}
          />
        ) : (
          <PaymentRow
            isOverdraftPage={isOverdraftPage}
            total={total}
            balance={balance}
            invoice={invoice}
            paidDate={paidDate}
          />
        )
        }
      </div>

      <div className={classes.totalRoot}>
        <Typography className={classes.totalContent}>Amount to apply</Typography>

        <CurrencyFormat value={values.amount || 0} className={classes.totalContent} />
      </div>

      <div className={classes.totalRoot}>
        <Typography className={classes.totalContent}>Balance to apply</Typography>

        <Typography className={classes.totalContent}>
          <CurrencyFormat value={balance} />
        </Typography>
      </div>

      {parentPage === 'create' &&
        <div className={classes.noteField}>
          <TextField
            fullWidth
            multiline
            rows={5}
            name="note"
            placeholder="Add notes"
            variant="filled"
            label={
              <div className={classes.noteIcon}>
                <NotesIcon fontSize="small" /> Note
              </div>
            }
          />
        </div>
      }
    </div>
  );
};
