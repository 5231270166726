import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as emailsApi from '../../../../../../../../../api/emails';
import { fetchProfile } from '../../../../../../../../../store/dashboard/profile';
import { ModalBody, ModalFooter } from '../../../../../../../../../components/Modal';
import { Loader } from '../../../../../../../../../components/Loader';
import { validationSchema } from '../../validationSchema';
import { EmailAccountForm } from '../../EmailsAddAccountModal/EmailAccountForm';

export const EmailsEditAccountSettings = ({
  account,
  onResolve = () => {},
  onReject = () => {}
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const editEmailAccount = (data, { isSubmitting, setErrors, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return emailsApi.editEmailConfig(data).then((data) => {
      enqueueSnackbar('Account successfully updated', { variant: 'success' });
      dispatch(fetchProfile());
      onResolve(data);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Something went wrong. Try again later...', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...account,
        password: null
      }}
      validationSchema={validationSchema}
      onSubmit={editEmailAccount}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Box
          noValidate
          component="form"
          display="flex"
          flexDirection="column"
          overflow="hidden"
          pt={2}
          onSubmit={handleSubmit}
        >
          <ModalBody>
            <EmailAccountForm />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onReject}>Cancel</Button>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              )}
            />
          </ModalFooter>
        </Box>
      )}
    </Formik>
  );
};
