import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  makeStyles,
  Box,
  Typography,
  List as MuiList,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { Select } from '../../../../../../../components/FormField';
import {
  columnsWidthsLg,
  columnsWidthsSm,
  lumbarSpecialTestsNamesMap
} from '../../../../../../../components/medical/reports';
import { getOption } from '../CervicalSpine/Body';
import { compressionOptions } from '../compressionOptions';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ formPartName }) => {
  const { values, setFieldValue } = useFormikContext();
  const specialTests = values?.forms?.physical_examination?.lumbar?.special_tests;
  const classes = useStyles();
  const lumbarSpecialTests = Object.keys(lumbarSpecialTestsNamesMap);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  useEffect(() => {
    lumbarSpecialTests?.forEach((item) => {
      setFieldValue(
        `${formPartName}.special_tests.${item}.right`,
        getOption({
          options: compressionOptions,
          values: specialTests,
          params: { side: 'right', item }
        })
      );

      setFieldValue(
        `${formPartName}.special_tests.${item}.left`,
        getOption({
          options: compressionOptions,
          values: specialTests,
          params: { side: 'left', item }
        })
      );

      setFieldValue(
        `${formPartName}.special_tests.${item}.expected`,
        getOption({
          options: compressionOptions,
          values: specialTests,
          params: { side: 'expected', item }
        })
      );
    });
  }, []);

  return (
    <Box mt={4}>
      <Typography variant="h4">
        Special tests
      </Typography>

      <div className={classes.root}>
        <ListRow header>
          <ListRowCell noWrap grow flexBasis={columnsWidths.name} />

          <ListRowCell flexBasis={columnsWidths.right}>
            <Typography variant="subtitle2" color="textSecondary">
              Right
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.midline}>
            <Typography variant="subtitle2" color="textSecondary">
              Left
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.left}>
            <Typography variant="subtitle2" color="textSecondary">
              Expected
            </Typography>
          </ListRowCell>
        </ListRow>

        <MuiList disablePadding>
          {lumbarSpecialTests?.map((item, index) => (
            <ListRow key={index}>
              <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                <Typography noWrap variant="h5">
                  {lumbarSpecialTestsNamesMap[item]}:
                </Typography>
              </ListRowCell>

              <ListRowCell flexBasis={columnsWidths.right}>
                <Select
                  formattedValue
                  name={`${formPartName}.special_tests.${item}.right`}
                  options={compressionOptions}
                />
              </ListRowCell>

              <ListRowCell flexBasis={columnsWidths.midline}>
                <Select
                  formattedValue
                  name={`${formPartName}.special_tests.${item}.left`}
                  options={compressionOptions}
                />
              </ListRowCell>

              <ListRowCell flexBasis={columnsWidths.left}>
                <Select
                  formattedValue
                  name={`${formPartName}.special_tests.${item}.expected`}
                  options={compressionOptions}
                />
              </ListRowCell>
            </ListRow>
          ))}
        </MuiList>
      </div>
    </Box>
  );
};
