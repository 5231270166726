import { payPeriodsMap } from '../../../../components/FormField/selects/PayPeriodsSelect/payPeriodsMap';
import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().max(255),
  abbr: Yup.string().nullable().max(10),
  company_type_id: Yup.mixed().nullable().required(),
  court_number: Yup.string().nullable().max(255),
  avatar_id: Yup.mixed().nullable(),
  contact_user_id: Yup.mixed().nullable(),
  country: Yup.string().nullable().min(2).max(255),
  state: Yup.string().nullable().min(2).max(255),
  city: Yup.string().nullable().min(2).max(255),
  zip: Yup.string().nullable().min(1).max(255),
  street: Yup.string().nullable().min(3).max(255),
  designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
    return unitNumber ? schema.required() : schema;
  }),
  unit_number: Yup.string().nullable().max(255),
  phone: Yup.string().required().phone().nullable().min(3).max(25),
  mobile_phone: Yup.string().nullable().phone().min(3).max(25),
  fax: Yup.string().nullable().phone().min(3).max(25),
  timezone: Yup.string().nullable().required().min(3).max(40),
  email: Yup.string().email().nullable().max(255),

  billing: Yup.object().shape({
    country: Yup.string().nullable().min(2).max(255),
    state: Yup.string().nullable().min(2).max(255),
    city: Yup.string().nullable().min(2).max(255),
    zip: Yup.string().nullable().min(1).max(255),
    street: Yup.string().nullable().min(3).max(255),
    designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
      return unitNumber ? schema.required() : schema;
    }),
    unit_number: Yup.string().nullable().max(255),
    phone: Yup.string().nullable().min(3).max(25),
    mobile_phone: Yup.string().nullable().min(3).max(25),
    fax: Yup.string().nullable().min(3).max(25),
    email: Yup.string().nullable().max(255)
  }),

  payroll: Yup.object({
    overtime_preferences: Yup.object().shape({
      day_overtime: Yup.number().nullable().min(0).max(24),
      day_double_time: Yup.number().nullable().positive().max(24).when('day_overtime', (overtime, schema) => {
        return schema.min((overtime || 0) + 1, 'Must be greater than day overtime');
      }),
      week_overtime: Yup.number().nullable().min(0).max(168),
      week_double_time: Yup.number().nullable().min(0).max(168).when('week_overtime', (overtime, schema) => {
        return schema.min((overtime || 0) + 1, 'Must be greater than week overtime');
      })
    }).nullable(),

    overtime_rate: Yup.number().nullable().min(1).max(10),
    double_time_rate: Yup.number().nullable().min(1).max(10),
    pay_period: Yup.string().nullable(),
    paid_lunch: Yup.bool().nullable(),
    duration_of_lunch: Yup.number().nullable().min(1).max(120),
    pay_period_range: Yup.object().nullable().when('pay_period', (payPeriod, schema) => {
      const rangeSchema = Yup.number().positive().integer().nullable();
      const isWeeklyOrBiWeekly = [ payPeriodsMap.weekly, payPeriodsMap.biWeekly ].includes(payPeriod);
      const isMonthlyOrSemiMonthly = [ payPeriodsMap.monthly, payPeriodsMap.semiMonthly ].includes(payPeriod);
      const weeklyOrBiWeekly = rangeSchema.min(0).max(6).required();
      const monthlyOrSemiMonthly = rangeSchema.min(1).max(28).required();

      return schema.shape({
        start: isWeeklyOrBiWeekly ? weeklyOrBiWeekly : isMonthlyOrSemiMonthly ? monthlyOrSemiMonthly : rangeSchema,
        finish: rangeSchema.when('start', (start) => {
          const isSemiMonthly = payPeriod === payPeriodsMap.semiMonthly;

          return isSemiMonthly && typeof(start) === 'number' ? monthlyOrSemiMonthly : rangeSchema;
        })
      });
    })
  }).nullable(),

  venue: Yup.array().of(Yup.object().shape({
    country: Yup.string().nullable().min(2).max(255),
    state: Yup.string().nullable().min(2).max(255),
    city: Yup.string().nullable().min(2).max(255),
    zip: Yup.string().nullable().min(1).max(255),
    street: Yup.string().nullable().min(3).max(255),
    designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
      return unitNumber ? schema.required() : schema;
    }),
    unit_number: Yup.string().nullable().max(255)
  })),

  offices: Yup.array().of(Yup.object()),
  rate: Yup.number().nullable().min(1).max(9999999)
});
