import { HorizontalBar } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';

export const Chart = ({ data, labels }) => {
  const { palette } = useTheme();

  const getChartData = () => ({
    labels,
    datasets: [
      {
        backgroundColor: [
          palette.primary.main,
          palette.warning.main,
          palette.error.main,
          palette.success.main,
          palette.info.main,
          palette.pink,
          palette.amberyellow,
          palette.darkgreen,
          palette.deeporange,
          palette.lightgreen,
          palette.violet,
          '#3F51B5',
          palette.purple,
          palette.slategray,
          palette.lightorange,
          '#CDDC39',
          palette.indigo
        ],
        data
      }
    ]
  });

  const chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      yAxes: [ {
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          beginAtZero: true
        }
      } ],
      xAxes: [ {
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          beginAtZero: true
        }
      } ]
    }
  };

  return (
    <HorizontalBar
      redraw
      data={getChartData()}
      options={chartOptions}
    />
  );
};
