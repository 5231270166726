import T from 'prop-types';
import { makeStyles, IconButton, Typography, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  onDrawerUpdate: T.func,
  SideBarComponent: T.func,
  openDrawer: T.bool,
  title: T.string
};

const fullSidebarWidth = 350;

export const SideBar = ({
  title,
  openDrawer,
  onDrawerUpdate,
  SideBarComponent
}) => {
  const classes = useStyles({ width: fullSidebarWidth });
  const theme = useTheme();

  const handleDrawerClose = () => {
    onDrawerUpdate(false);
  };

  return (
    <div
      style={{ width: openDrawer ? fullSidebarWidth : 0 }}
      className={classes.root}
    >
      <div className={classes.header}>
        <Typography variant="h6">{title}</Typography>

        <IconButton color="primary" onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>

      <SideBarComponent />
    </div>
  );
};

SideBar.propTypes = propTypes;
