import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { widthBreakpointSmall, widthBreakpointXS } from '../../../InvoiceItem';
import { columnsWidths } from '../Incomes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = ({ width, incomes }) => {
  const classes = useStyles();

  return (
    <ListRow header className={cn(classes.root, { [classes.root_space]: incomes.length > 3 })}>
      <ListRowCell grow flexBasis={columnsWidths.name} className={classes.content}>
        <Typography className={classes.content__title}>
          Name
        </Typography>
      </ListRowCell>

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={columnsWidths.parent} className={classes.content}>
          <Typography noWrap title="Parent" className={classes.content__title}>
            Parent
          </Typography>
        </ListRowCell>
      }

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.notes} className={classes.content}>
          <Typography className={classes.content__title}>
            Notes
          </Typography>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.rate} className={classes.content}>
        <Typography className={classes.content__title}>
          Rate
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.hours} className={classes.content}>
        <Typography className={classes.content__title}>
          Hours
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total} className={classes.content}>
        <Typography className={classes.content__title}>
          Total
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}/>
    </ListRow>
  );
};
