import { useContext } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../components/ListRow';
import { CodesContext } from '../../CodesContext';
import { columnsWidths } from '../MainContent';

export const TableHeader = () => {
  const { selectedIDs, allItemsIsSelected, toggleAllItemsSelection } = useContext(CodesContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <ListRow header>
      <ListRowCheckbox
        indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
        checked={allItemsIsSelected()}
        onClick={toggleAllItemsSelection}
      />

      <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
        {isTablet ? 'Code / Description' : 'Procedure Code'}
      </ListRowCell>

      {!isTablet && (
        <ListRowCell grow flexBasis={columnsWidths.description}>
          Description
        </ListRowCell>
      )}

      {!isTablet && (
        <ListRowCell flexBasis={columnsWidths.base_unit}>
          Base Unit
        </ListRowCell>
      )}

      {!isMobile && (
        <ListRowCell noWrap flexBasis={columnsWidths.add_on}>
          {isTablet ? 'Unit / Add on' : 'Add on'}
        </ListRowCell>
      )}

      {!isMobile && <ListRowCell flexBasis={columnsWidths.actionButton} />}

      {hasRole(rolesMap.admin, rolesMap.supervisor) &&
        <ListRowCell flexBasis={columnsWidths.action}/>
      }
    </ListRow>
  );
};
