import { Fragment } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { Contact } from '../../../../../../../components/Contact';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../components/UserLink';
import { UserPreviewModal } from '../../../../../../../components/users';

export const OfficesContent = ({ users }) => {
  const { openModal } = useModal();

  const openPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id }
    });
  };

  return (
    <Box pt={3} pl={2} pr={1}>
      <Box color="info.main">
        <Typography variant="button" component="h3" color="inherit">
          Office contacts
        </Typography>
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2} alignItems="center">
        {users?.map((user) => user && (
          <Fragment key={user.id}>
            <Grid item xs={4}>
              <UserLink
                user={user}
                variant="h5"
                onClick={stopPropagation(openPreview(user.id))}
              >
                {user.position
                  ? <Typography color="textSecondary" noWrap>{user.position}</Typography>
                  : <Typography variant="body2" color="textSecondary">No info.</Typography>
                }
              </UserLink>
            </Grid>

            <Grid item xs={4}>
              <Contact
                needIcon
                noWrap
                variant="h5"
                type="mailto"
                contact={user.email}
              />
            </Grid>

            <Grid item xs={4}>
              <Contact
                needIcon
                noWrap
                variant="h5"
                type="tel"
                contact={user.phone}
              />
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Box>
  );
};
