import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Divider } from '../../../../Divider';

export const SupplementalInformation = ({ claim }) => {
  return (
    <>
      <Grid container spacing={2} component={Box} px={2} pt={3}>
        <Grid item xs={12}>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">
              Supplemental Information
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={3} xs={12}>
          <Typography variant="body2" color="textSecondary">
            Report Type
          </Typography>
        </Grid>

        <Grid item sm={9} xs={12}>
          <Typography variant="subtitle2">
            {!claim?.situational_number?.report_type ? '-' :
              claim?.situational_number?.report_type?.code
              + ' - ' +
              claim?.situational_number?.report_type?.description
            }
          </Typography>
        </Grid>

        <Grid item sm={3} xs={12}>
          <Typography variant="body2" color="textSecondary">
            Report Transmission
          </Typography>
        </Grid>

        <Grid item sm={9} xs={12}>
          <Typography variant="subtitle2">
            {claim?.situational_number?.report_transmission?.label || '-'}
          </Typography>
        </Grid>

        <Grid item sm={3} xs={12}>
          <Typography variant="body2" color="textSecondary">
            Attach Ctrl Number
          </Typography>
        </Grid>

        <Grid item sm={9} xs={12}>
          <Typography variant="subtitle2">
            {claim?.situational_number?.attach_ctrl_number || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Divider gutter={3} />

      <Grid container spacing={2} component={Box} px={2} pb={3}>
        <Grid item xs={12}>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">
              MISC (Claim Note)
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={3} xs={12}>
          <Typography variant="body2" color="textSecondary">
            Note Reference Code
          </Typography>
        </Grid>

        <Grid item sm={9} xs={12}>
          <Typography variant="subtitle2">
            {claim?.situational_number?.note_reference_code?.label || '-'}
          </Typography>
        </Grid>

        <Grid item sm={3} xs={12}>
          <Box display="flex" alignItems="center" color="text.secondary">
            <NotesIcon fontSize="small" color="inherit" />

            <Box mr={1.5} ml={0.5}>
              <Typography variant="body2" color="textSecondary">Claim Note</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item sm={9} xs={12}>
          <Typography variant="subtitle2">
            {claim?.situational_number?.note || '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
