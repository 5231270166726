import { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { FilesContextProvider } from '../../files-common';
import { filterFieldsMap } from '../MyFilesPage/FiltersBar/filterFieldsMap';
import { Grid } from '../MyFilesPage/Grid';
import { List } from '../MyFilesPage/List';
import { FiltersBar } from '../MyFilesPage/FiltersBar';
// import { RightSidebar } from '../MyFilesPage/RightSidebar';
import { styles } from '../MyFilesPage/styles';
import { ActionsBar } from './ActionsBar';

const useStyles = makeStyles(styles);

export const SharedFilesPage = ({ filterKey, hiddenFields = [] }) => {
  const classes = useStyles();
  const { viewVariant } = useContext(LayoutContext);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('lg');

  return (
    <div className={classes.root}>
      <ActionsBar filterOpenButton={filterOpenButton} />

      <ScrollWrapper {...scrollWrapperProps}>
        <FilterBarWrapper {...filterBarWrapperProps}>
          <FiltersBar filterKey={filterKey} hiddenFields={[ ...hiddenFields, filterFieldsMap.tags ]} />
        </FilterBarWrapper>
      </ScrollWrapper>

      <div className={classes.filesWindow}>
        <div className={classes.filesWindow__main}>
          {(viewVariant === viewVariantsMap.list) &&
            <FilesContextProvider isSharedFiles>
              <List />
            </FilesContextProvider>
          }

          {(viewVariant === viewVariantsMap.grid) &&
            <FilesContextProvider isSharedFiles>
              <Grid />
            </FilesContextProvider>
          }
        </div>

        {/*<div*/}
        {/*  className={cn(*/}
        {/*    classes.filesWindow__sidebar,*/}
        {/*    { [classes.filesWindow__sidebar_active]: sidebarIsOpen }*/}
        {/*  )}*/}
        {/*>*/}
        {/*  <RightSidebar />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
