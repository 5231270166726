import { makeStyles, IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(0.25),
    marginRight: -spacing(0.25)
  }
}));

export const DropdownIndicator = ({ isDisabled }) => {
  const classes = useStyles();

  return isDisabled || (
    <IconButton size="small" className={classes.root}>
      <ArrowDropDownIcon />
    </IconButton>
  );
};
