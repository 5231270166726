import { Redirect } from 'react-router-dom';
import { makeStyles, Paper } from '@material-ui/core';
import { renderRoutes, getRelatedRoutesWithCustomBase, Route } from '../../../../components/router';
import { layoutStyles } from '../layoutStyles';
import { routes as arrivalStatusRoutes } from './ArrivalStatuses/routes';
import { routes as appointmentBooksRoutes } from './AppointmentBooks/routes';
import { routes as appointmentTypesRoutes } from './AppointmentTypes/routes';
import { routes as visitReasonsRoutes } from './VisitReasons/routes';
import { routes } from './routes';

const useLayoutStyles = makeStyles(layoutStyles);

export const Appointment = ({ baseUrl }) => {
  const layoutClasses = useLayoutStyles();
  const mainRoutes = getRelatedRoutesWithCustomBase(routes, baseUrl);

  return (
    <Paper elevation={2} className={layoutClasses.root}>
      <Route exact path={baseUrl}>
        <Redirect to={`${baseUrl}/arrival-statuses`} />
      </Route>

      {renderRoutes(mainRoutes)}
      {renderRoutes(getRelatedRoutesWithCustomBase(arrivalStatusRoutes, baseUrl))}
      {renderRoutes(getRelatedRoutesWithCustomBase(appointmentBooksRoutes, baseUrl))}
      {renderRoutes(getRelatedRoutesWithCustomBase(appointmentTypesRoutes, baseUrl))}
      {renderRoutes(getRelatedRoutesWithCustomBase(visitReasonsRoutes, baseUrl))}
    </Paper>
  );
};
