import { Box } from '@material-ui/core';
import * as tagsApi from '../../../api/files/tags';
import { Autocomplete } from '../../FormField';
import { Tag } from '../../Tag';

const fetchTags = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return tagsApi.fetchTags({
    params: {
      search,
      page: page + 1,
      is_active: 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => {
  return (
    <Box key={option.value} overflow="auto">
      <Tag tag={option} />
    </Box>
  );
};

export const TagsSelect = ({ params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      label="Tags"
      placeholder="Search tag..."
      onNeedFetch={fetchTags(params)}
      renderOption={renderOption}
      getOptionLabel={(tag) => tag?.name}
      getOptionValue={(option) => option?.id}
      getOptionSelected={(option, value) => option.id === value?.id}
      renderTags={(tags, getTagProps) => (
        tags.map((tag, index) => (
          <Tag
            cutTagLabel
            tag={tag}

            {...getTagProps({ index })}
          />
        ))
      )}

      {...props}
    />
  );
};
