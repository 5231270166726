export const initialState = {
  isFetching: false,
  isFetched: false,
  isFirstLoaded: false,
  pagination: {
    total: 0,
    last_page: 1
  },
  filter: {
    page: 1,
    per_page: 30,
    folder_id: null,
    folder_name: null
  },
  messages: [],
  selectedMessages: [],
  messageItem: null
};
