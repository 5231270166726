import moment from 'moment';
import { Chip } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';

export const SingleValue = ({ event = null }) => {
  return !!event && (
    <Chip
      tabIndex={-1}
      icon={<CalendarIcon />}
      label={`${event?.title || ''} at ${moment.unix(event?.started_at).format('L LT')}`}
    />
  );
};
