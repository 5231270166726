export const styles = ({
  spacing,
  palette: { grey, primary },
  transitions,
  breakpoints
}) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: spacing(),
    marginRight: spacing(0.15),
    padding: spacing(1.25, 2, 1, 2),
    zIndex: 1,

    [breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
      padding: spacing(0.25, 1),
      marginLeft: 0,
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      marginBottom: spacing(0.5)
    },

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      flexDirection: 'column'
    }
  },

  textField: {
    minWidth: 0,
    width: 190,

    [breakpoints.down('sm')]: {
      width: 140
    }
  },

  textFieldInput: {
    color: `${grey[500]} !important`,
    cursor: 'pointer',

    '&:before': {
      borderBottomStyle: 'solid !important'
    }
  },

  textFieldInput__inner: {
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      color: primary.main
    }
  },

  actionSwitcher: {
    marginRight: spacing(2),
    minWidth: 110
  }
});
