import { useSnackbar } from 'notistack';
import { createContext, useRef, useContext, useEffect, useReducer } from 'react';
import { merge, omit, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePrevious } from '../../../helpers/hooks';
import { useQueryParams } from '../../../helpers/hooks/useQueryParams';
import { jobsTypes, addSocketJobID } from '../../../store/socketJobs';
import { api } from '../../../api';
import * as filesApi from '../../../api/files';
import { FilesFilterContext } from '../../Dashboard/files-common';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';
import { codesOfTokenConfirmationReason } from './validationErrorsMap';

export const SharedFilesContext = createContext();

export const SharedFilesProvider = ({
  children,
  initialState: initialStateProp = {},
  onFilesCountChange = () => {}
}) => {
  const { token } = useQueryParams();
  const history = useHistory();
  const reduxDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const lastUploadedFile = useSelector(({ uploads: { lastUploadedFile } }) => lastUploadedFile);
  const { filesLastGlobalAction } = useSelector(({ globalActions }) => globalActions);
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { isFetched, isFetching, filter, pagination } = state;
  const {
    filter: commonFilter,
    selectedFilesIDs,
    addFilesToSelected,
    deleteFilesFromSelected
  } = useContext(FilesFilterContext);
  const files = useRef(state.files);
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchFiles = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FILES_REQUEST });

    filesApi.fetchPersonalSharedFiles({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel),
      headers: {
        authorization: token && `Bearer ${token}`
      }
    }).then((data) => {
      dispatch({ type: types.FETCH_FILES_SUCCESS, payload: data });
    }).catch(({ status, data }) => {
      if (codesOfTokenConfirmationReason.includes(data?.errors?.code?.[0])) {
        history.push(`/public-files?token=${token}`);
      } else if (status !== 422) {
        enqueueSnackbar('Link is expired or not valid', { variant: 'error' });
        history.push('/');
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFiles({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetFiles = (newFilter) => {
    dispatch({ type: types.RESET_FILES, payload: newFilter });
    deleteFilesFromSelected(files.current.map(({ id }) => id));
    fetchFiles({ page: 1, ...newFilter });
  };

  const downloadFiles = (filesIDs) => {
    filesApi.createArchive({ files: filesIDs }).then(({ job_id }) => {
      reduxDispatch(addSocketJobID({ type: jobsTypes.archives, jobID: job_id }));
    });
  };

  const allFilesIsSelected = () => {
    return selectedFilesIDs.filter((id) => state.files.find((file) => file.id === id)).length === state.files.length;
  };

  const toggleAllFilesSelection = () => {
    const filesIDs = state.files.map(({ id }) => id);

    allFilesIsSelected() ? deleteFilesFromSelected(filesIDs) : addFilesToSelected(filesIDs);
  };

  const providerValue = {
    isFetched,
    isFetching,
    files: state.files,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetFiles,
    fetchFiles,
    loadNextPage,
    downloadFiles,
    allFilesIsSelected,
    toggleAllFilesSelection
  };

  useEffect(() => {
    files.current = state.files;
  }, [ state.files ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
      deleteFilesFromSelected(files.current.map(({ id }) => id));
    };
  }, []);

  useEffect(() => {
    if (lastUploadedFile) {
      resetFiles();
    }
  }, [ lastUploadedFile ]);

  useEffect(() => {
    filesLastGlobalAction && dispatch(filesLastGlobalAction);
  }, [ filesLastGlobalAction ]);

  useEffect(() => {
    const newFilter = { ...filter, ...commonFilter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(commonFilter);
    }
  }, [ filter, commonFilter ]);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetFiles(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    onFilesCountChange(pagination.total);
  }, [ pagination.total ]);

  return (
    <SharedFilesContext.Provider value={providerValue}>
      {children}
    </SharedFilesContext.Provider>
  );
};
