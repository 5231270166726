import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  makeStyles,
  Box,
  Grid,
  SvgIcon,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { mdiDraw } from '@mdi/js';
import { Icon } from '@mdi/react';
import { rolesMap } from '../../dataMaps/rolesMap';
import { ConfirmationModal } from '../ConfirmationModal';
import { useModal } from '../ModalsProvider';
import { Button } from '../Button';
import { Date } from '../Date';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const UserSignature = ({
  userRole = rolesMap.doctor,
  isSignature = false,
  signature = {},
  onSign = () => {}
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(({ profile }) => profile.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const date = signature?.date || moment().unix();

  const handleSign = () => {
    if (currentUser.signature) {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          onSign();
        }
      });
    } else {
      enqueueSnackbar('You don\'t have a signature yet', { variant: 'warning' });
    }
  };

  return (
    <Grid container spacing={isMobile ? 2 : 3} component={Box} pt={4}>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          Signature
        </Typography>
      </Grid>

      <Grid item md={6} xs={12}>
        <Box className={classes.signature}>
          {!!isSignature ? (
            <img
              src={signature?.electronic_signature_file || signature?.url}
              alt="signature"
            />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" height="165px">
              <Typography variant="h5">Not signed yet</Typography>
            </Box>
          )}
        </Box>
      </Grid>

      {currentUser?.role === userRole && (
        <Grid item md={6} xs={12}>
          {!isSignature ?
            <Button
              size="small"
              color="primary"
              startIcon={<SvgIcon><Icon path={mdiDraw} /></SvgIcon>}
              onClick={handleSign}
            >
              Sign
            </Button>
            :
            <Date date={date} />
          }
        </Grid>
      )}
    </Grid>
  );
};
