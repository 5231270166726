import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { makeStyles, Grid, Button, useTheme, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDebounce } from '../../../../../helpers/hooks';
import { TextField } from '../../../../../components/FormField';
import { AppBar } from '../../../../../components/AppBar';
import { Fab } from '../../../../../components/Fab';
import { TemplatesContext } from '../TemplatesContext';
import { orderByOptions } from '../TemplatesContext/orderByOptions';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FiltersBar = () => {
  const classes = useStyles();
  const { filter, resetTemplates } = useContext(TemplatesContext);
  const [ fields, setFields ] = useState({});
  const debouncedFields = useDebounce(fields, 600);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
  };

  useEffect(() => {
    resetTemplates(debouncedFields);
  }, [ debouncedFields ]);

  return (
    <AppBar className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <Formik
        initialValues={{
          name: '',
          order_by: orderByOptions.find(({ value }) => value === filter.order_by)
        }}
        onSubmit={null}
      >
        {() => (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item>
              <TextField
                name="name"
                label="Search"
                placeholder="Search..."
                onChange={handleFieldChange('name')}
              />
            </Grid>

            <Grid item className={classes.creationButton}>
              {isMobile ? (
                <Fab
                  variant="round"
                  color="primary"
                  to="/files/templates/new"
                  component={Link}
                >
                  <AddIcon />
                </Fab>
              ) : (
                <Button
                  to="/files/templates/new"
                  component={Link}
                  variant="contained"
                  color="primary"
                >
                  Add template
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Formik>
    </AppBar>
  );
};
