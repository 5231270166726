import Color from 'color';

export const styles = ({ spacing, palette: { grey, ...palette } }) => ({
  root: {
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, transparent 100%)',
    borderBottom: 0,
    color: palette.common.white,
    padding: 0
  },

  toolbar: {
    paddingLeft: spacing(),
    paddingRight: spacing()
  },

  fileName: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    overflow: 'hidden',
    color: 'inherit'
  },

  fileName__arrow: {
    marginLeft: 0,
    fontSize: 20,

    extend: 'button'
  },

  fileName__name: {
    color: 'inherit',
    marginLeft: spacing(),
    marginRight: spacing(2)
  },

  fileName__icon: {
    fontSize: 24
  },

  button: {
    color: 'inherit',

    '&:hover': {
      backgroundColor: Color(palette.common.white).alpha(0.25).string()
    }
  },

  sidebarToggleButton: {
    marginLeft: spacing(2),
    boxShadow: 'none',
    position: 'relative',
    right: -spacing(),
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    height: 48,
    width: 42,
    backgroundColor: grey[600],
    color: palette.common.white,

    '&:hover': {
      backgroundColor: Color(grey[600]).darken(0.1).string()
    }
  }
});
