import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(3).max(100),
  description: Yup.string().nullable().min(3),
  owner_case_user_role: Yup.mixed().nullable().required(),
  users_case_user_roles: Yup.array().nullable(),
  due_at_case_date: Yup.mixed().nullable().required(),
  due_at_interval: Yup.number().min(0).nullable().required(),
  hours: Yup.number().integer().min(0).nullable().required(),
  minutes: Yup.number().integer().min(0).nullable().required(),
  status_id: Yup.mixed().nullable(),
  type: Yup.mixed().nullable(),
  priority: Yup.mixed().nullable(),
  estimated_time: Yup.number().integer().positive().min(5).nullable(),
  work_status: Yup.string().min(1).max(255).nullable()
});
