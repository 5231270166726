export const styles = ({
  spacing,
  palette: { primary, grey, darkgreen },
  typography: { pxToRem, fontWeightMedium },
  shape: { borderRadius }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: primary.contrastText,
    height: '100%',
    overflow: 'hidden',
    borderRadius
  },

  autoSizer: {
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    width: '100% !important',
    overflow: 'auto !important'
  },

  formTitle: {
    paddingLeft: spacing(6)
  },

  actions: {
    marginLeft: 'auto'
  },

  actions__backButton: {
    color: grey[400],
    marginRight: spacing(1.5)
  },

  title: {
    fontSize: pxToRem(28),
    paddingLeft: spacing(6)
  },

  content: {
    flexGrow: 1,
    overflow: 'auto'
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(5, 6, 2, 6)
  },

  mainContent_incomes: {
    paddingBottom: spacing()
  },

  mainContent__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(2)
  },

  mainContent__btn: {
    paddingRight: spacing(),
    paddingLeft: spacing()
  },

  mainContent__icon: {
    marginRight: spacing()
  },

  expenseContent: {
    padding: spacing(5, 6, 2, 6)
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: spacing(5, 6)
  },

  note: {
    position: 'relative',
    marginRight: spacing(2),
    flexGrow: 1
  },

  noteFieldInput: {
    color: `${grey[800]} !important`,
    backgroundColor: grey[50],
    padding: spacing(),
    borderRadius,

    '&:before': {
      borderBottomStyle: 'none !important'
    }
  },

  noteLabel: {
    top: spacing(-3)
  },

  focusedNoteLabel: {
    top: 0
  },

  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: 384,
    minWidth: 384,
    height: 145,
    padding: spacing(3, 2, 1, 2),
    marginTop: spacing(2),
    border: `1px dashed ${darkgreen}`,
    borderRadius
  },

  total__title: {
    color: grey[400],
    fontWeight: fontWeightMedium,
    marginBottom: spacing(1.5)
  },

  total__sum: {
    marginBottom: spacing(1.5)
  },

  totalTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%'
  },

  totalTitle_sum: {
    flexGrow: 0
  },

  total__balance: {
    color: darkgreen
  },

  totalFields: {
    marginTop: spacing(-3)
  }
});
