import { IconButton, List, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  caseUserRoles,
  caseUserRolesTypesMap,
  rolesMap as caseRolesMap
} from '../../../../../../../../dataMaps';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { UserPreviewModal } from '../../../../../../../../components/users';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { Contact } from '../../../../../../../../components/Contact';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Popper } from '../../../../../../../../components/Popper';
import { Fab } from '../../../../../../../../components/Fab';
import { PreviewCompanyModal } from '../../../../../../Contacts/Companies/PreviewCompanyModal';
import {
  minRowHeight,
  columnsWidths,
  widthBreakpointLarge,
  widthBreakpointXS
} from '../../Contacts';
import { ContactsMenu } from './ContactsMenu';
import { OfficePopper } from './OfficePopper';

export const Row = ({ width, caseUser, caseItem, onCaseItemUpdate }) => {
  const { openModal } = useModal();
  const userRole = caseUserRoles.find((role) => role.value === caseUser?.case_role)?.label
    || caseUserRolesTypesMap[caseRolesMap.creator];

  const openPreviewCompanyModal = (companyID) => () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID }
    });
  };

  const openUserPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: caseUser?.user?.id }
    });
  };

  return caseUser.user && (
    <ListRow
      button
      minHeight={minRowHeight}
      onClick={stopPropagation(openUserPreview)}
    >
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <UserLink
          noWrap
          size="md"
          user={caseUser?.user}
          component="p"
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.case_role}>
        <Typography noWrap>
          {userRole}
        </Typography>
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.mobile_phone_number}>
          <Typography
            noWrap
            title={caseUser?.user?.phone_number || caseUser?.user?.mobile_phone_number}
          >
            {caseUser?.user?.phone_number || caseUser?.user?.mobile_phone_number || '-'}
          </Typography>
        </ListRowCell>
      }

      <ListRowCell grow flexBasis={columnsWidths.email}>
        <Contact
          noWrap
          color="primary"
          type="mailto"
          contact={caseUser.user.email}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.company}>
        <CompanyLink
          noWrap
          company={caseUser?.user?.work?.company}
          onClick={stopPropagation(openPreviewCompanyModal(caseUser?.user?.work?.company?.id))}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        {caseUser?.user?.work?.offices?.length ? (
          <Popper
            anchorRenderer={
              ({ isOpen, anchorRef, handleToggle }) => (
                <Fab
                  size="small"
                  color="secondary"
                  variant="extended"
                  buttonRef={anchorRef}
                  endIcon={!isOpen
                    ? <ExpandMore />
                    : <ExpandLess />
                  }
                  onClick={stopPropagation(handleToggle)}
                >
                  {caseUser?.user?.work?.offices?.length || 0}
                </Fab>
              )
            }
          >
            <List>
              {caseUser?.user?.work?.offices?.map((office) => (
                <OfficePopper key={office.id} office={office} />
              ))}
            </List>
          </Popper>
        ) : (
          <Typography color="textSecondary">No info.</Typography>
        )}
      </ListRowCell>

      {width > widthBreakpointXS &&
        <ListRowCell
          noWrap
          flexBasis={columnsWidths.position}
          title={caseUser?.user?.work?.position}
        >
          {caseUser?.user?.work?.position}
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.action}>
        {!hasRole('client', 'patient') &&
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton
                  buttonRef={anchorRef}
                  onClick={stopPropagation(handleToggle)}
                >
                  <MoreVertIcon />
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <ContactsMenu
                caseUser={caseUser}
                onCaseItemUpdate={onCaseItemUpdate}
                onClose={handleClose}
                caseItem={caseItem}
              />
            )}
          </Popper>
        }
      </ListRowCell>
    </ListRow>
  );
};
