import { makeStyles, Typography } from '@material-ui/core';
import { UserLink } from '../../components/UserLink';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const useMessageContent = (message) => {
  const classes = useStyles();

  if (!message) {
    return null;
  }

  const { system_type, body, parent, payload } = message;

  switch (system_type) {
    case 'dialog_created':
      return (
        <div className={classes.root} >
          <UserLink
            noAvatar
            user={payload.user}
          />

          <Typography component="span">
            &nbsp;created chat
          </Typography>
        </div>
      );

    case 'dialog_updated':
      return (
        <div className={classes.root} >
          <UserLink
            noAvatar
            user={payload.user}
          />

          <Typography component="span">
            &nbsp;updated chat
          </Typography>
        </div>
      );

    case 'dialog_archived':
      return (
        <div className={classes.root} >
          <UserLink
            noAvatar
            user={payload.user}
          />

          <Typography component="span">
            &nbsp;archived chat
          </Typography>
        </div>
      );

    case 'dialog_unarchived':
      return (
        <div className={classes.root} >
          <UserLink
            noAvatar
            user={payload.user}
          />

          <Typography component="span">
            &nbsp;unarchived chat
          </Typography>
        </div>
      );

    case 'dialog_user_removed':
      return (
        <div className={classes.root} >
          {payload.removed_user ?
            <div className={classes.root}>
              <UserLink
                noAvatar
                user={payload.removed_user}
              />

              <Typography component="span">
                &nbsp;left chat
              </Typography>
            </div>
            :
            <div className={classes.root}>
              <UserLink
                noAvatar
                user={payload.user}
              />

              <Typography noWrap component="span">
                &nbsp;removed&nbsp;
              </Typography>

              <UserLink
                noAvatar
                user={payload.participant}
              />
            </div>
          }
        </div>
      );

    case 'dialog_author_changed':
      return (
        <div className={classes.root} >
          <UserLink
            noAvatar
            parentProps={{ mr: 1 }}
            user={payload.author}
          />

          <Typography component="span">
            is new owner
          </Typography>
        </div>
      );

    case 'dialog_user_connected':
      return (
        <div className={classes.root}>
          <UserLink
            noAvatar
            parentProps={{ mr: 1 }}
            user={payload.user}
          />

          <Typography noWrap component="span">
            invited
          </Typography>

          <UserLink
            noAvatar
            parentProps={{ ml: 1 }}
            user={payload.participant}
          />
        </div>
      );

    default:
      return (
        body
        || parent?.body
        || message.files?.[0]?.original_filename
        || parent?.files?.[0]?.original_filename
        || ''
      );
  }
};
