export const statusTypeOptions = [
  { value: 'primary', label: 'Primary Insurance' },
  { value: 'secondary', label: 'Secondary Insurance' },
  { value: 'tertiary', label: 'Tertiary Insurance' },
  { value: 'law_office', label: 'Law Office' }
];

export const statusTypeCaseOptions = [
  { value: 'primary', label: 'Primary Insurance' },
  { value: 'secondary', label: 'Secondary Insurance' },
  { value: 'tertiary', label: 'Tertiary Insurance' },
  { value: 'law_office', label: 'Law Office' },
  { value: 'case_insurance', label: 'Car Insurance' }
];

export const primaryStatusTypeOptions = [
  { value: 'primary', label: 'Primary Insurance' },
  { value: 'secondary', label: 'Secondary Insurance' },
  { value: 'tertiary', label: 'Tertiary Insurance' }
];

export const insuranceStatusTypeOptions = [
  { value: 'law_office', label: 'Law Office' }
];

export const subscribersMap = {
  self: 'Self',
  father: 'Father',
  mother: 'Mother',
  brother: 'Brother',
  sister: 'Sister',
  daughter: 'Daughter',
  guarantor: 'Guarantor',
  son: 'Son',
  spouse: 'Spouse',
  others: 'Others'
};

export const subscriberOptions = [
  { value: subscribersMap.self, label: subscribersMap.self },
  { value: subscribersMap.father, label: subscribersMap.father },
  { value: subscribersMap.mother, label: subscribersMap.mother },
  { value: subscribersMap.brother, label: subscribersMap.brother },
  { value: subscribersMap.sister, label: subscribersMap.sister },
  { value: subscribersMap.daughter, label: subscribersMap.daughter },
  { value: subscribersMap.guarantor, label: subscribersMap.guarantor },
  { value: subscribersMap.son, label: subscribersMap.son },
  { value: subscribersMap.spouse, label: subscribersMap.spouse },
  { value: subscribersMap.others, label: subscribersMap.others }
];

export const insuranceTypesOptions = [
  { value: 'WC', label: 'WC' },
  { value: 'PVT', label: 'PVT' },
  { value: 'PI', label: 'PI' },
  { value: 'MEDICARE', label: 'MEDICARE' },
  { value: 'OTHER', label: 'OTHER' }
];

export const caseInsuranceDefendantValuesTypesMap = {
  defendant: 'DEFENDANT',
  defendantOne: 'DEFENDANT_ONE',
  defendantTwo: 'DEFENDANT_TWO',
  defendantThree: 'DEFENDANT_THREE',
  defendantFour: 'DEFENDANT_FOUR',
  defendantFive: 'DEFENDANT_FIVE',
  defendantSix: 'DEFENDANT_SIX'
};

export const caseInsuranceValuesTypesMap = {
  plaintiff: 'PLAINTIFF',

  ...caseInsuranceDefendantValuesTypesMap
};

export const caseInsuranceLabelsTypesMap = {
  [caseInsuranceValuesTypesMap.plaintiff]: 'Plaintiff',
  [caseInsuranceValuesTypesMap.defendant]: 'Defendant',
  [caseInsuranceValuesTypesMap.defendantOne]: 'Defendant 1',
  [caseInsuranceValuesTypesMap.defendantTwo]: 'Defendant 2',
  [caseInsuranceValuesTypesMap.defendantThree]: 'Defendant 3',
  [caseInsuranceValuesTypesMap.defendantFour]: 'Defendant 4',
  [caseInsuranceValuesTypesMap.defendantFive]: 'Defendant 5',
  [caseInsuranceValuesTypesMap.defendantSix]: 'Defendant 6'
};

export const caseInsuranceTypesOptions = [
  {
    value: caseInsuranceValuesTypesMap.plaintiff,
    label: caseInsuranceLabelsTypesMap[caseInsuranceValuesTypesMap.plaintiff]
  },
  {
    value: caseInsuranceValuesTypesMap.defendant,
    label: caseInsuranceLabelsTypesMap[caseInsuranceValuesTypesMap.defendant]
  },
  {
    value: caseInsuranceValuesTypesMap.defendantOne,
    label: caseInsuranceLabelsTypesMap[caseInsuranceValuesTypesMap.defendantOne]
  },
  {
    value: caseInsuranceValuesTypesMap.defendantTwo,
    label: caseInsuranceLabelsTypesMap[caseInsuranceValuesTypesMap.defendantTwo]
  },
  {
    value: caseInsuranceValuesTypesMap.defendantThree,
    label: caseInsuranceLabelsTypesMap[caseInsuranceValuesTypesMap.defendantThree]
  },
  {
    value: caseInsuranceValuesTypesMap.defendantFour,
    label: caseInsuranceLabelsTypesMap[caseInsuranceValuesTypesMap.defendantFour]
  },
  {
    value: caseInsuranceValuesTypesMap.defendantFive,
    label: caseInsuranceLabelsTypesMap[caseInsuranceValuesTypesMap.defendantFive]
  },
  {
    value: caseInsuranceValuesTypesMap.defendantSix,
    label: caseInsuranceLabelsTypesMap[caseInsuranceValuesTypesMap.defendantSix]
  }
];

export const medicInsuranceTypesOptions = [
  { value: 'PVT', label: 'PVT' },
  { value: 'MEDICARE', label: 'MEDICARE' },
  { value: 'OTHER', label: 'OTHER' }
];

export const lawOfficeInsuranceTypesOptions = [
  { value: 'WC', label: 'WC' },
  { value: 'PI', label: 'PI' }
];

export const companyTypeIDsMap = {
  employer: 1,
  insurance: 2,
  law_office: 3,
  medical_office: 4,
  court: 5,
  police_department: 6,
  auto_rental: 7,
  auto_towing: 8,
  auto_body_shop: 9
};

export const insuranceTypes = {
  wc: 'WC',
  pi: 'PI',
  plaintiff: 'PLAINTIFF',
  defendant: 'DEFENDANT'
};

export const insuranceStatusTypesMap = {
  law_office: 'law_office',
  case_insurance: 'case_insurance',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary'
};

export const insuranceTypesMap = {
  WC: companyTypeIDsMap.law_office,
  PI: companyTypeIDsMap.law_office,
  PVT: companyTypeIDsMap.insurance,
  MEDICARE: companyTypeIDsMap.insurance,
  OTHER: companyTypeIDsMap.insurance,
  PLAINTIFF: companyTypeIDsMap.insurance,
  DEFENDANT: companyTypeIDsMap.insurance
};

export const insuranceStatusMap = {
  [insuranceStatusTypesMap.case_insurance]: 'Car Insurance',
  [insuranceStatusTypesMap.primary]: 'Primary Insurance',
  [insuranceStatusTypesMap.secondary]: 'Secondary Insurance',
  [insuranceStatusTypesMap.tertiary]: 'Tertiary Insurance',
  [insuranceStatusTypesMap.law_office]: 'Law Office'
};
