import { Box } from '@material-ui/core';
import { UserCard } from './UserCard';

export const ParentAssignments = ({
  noWrap,
  users,
  space = -0.5,
  MenuComponent,
  menuProps,
  ...props
}) => {
  return (
    <Box display="inline-flex" flexWrap={noWrap ? 'nowrap' : 'wrap'} pr={1}>
      {users?.map((user) => (
        user &&
          <Box ml={space} key={user.id} {...props}>
            <UserCard
              user={user}
              space={space}
              MenuComponent={MenuComponent}
              menuProps={menuProps}
            />
          </Box>
      ))}
    </Box>
  );
};
