import { useContext } from 'react';
import { FilePreviewContext } from '../../../FilePreviewContext';
import { DOC } from '../DOC';
import { PdfView } from '../PdfView';

export const PDF = ({ actionsBarPortalElement, topLayerPortalElement }) => {
  const { selectedFile, viewWidth } = useContext(FilePreviewContext);
  const isDOC = !selectedFile?.isAttachment && [ 'doc', 'docx' ].includes(selectedFile?.extension);

  return isDOC ? (
    <DOC />
  ) : (
    <PdfView
      actionsBarPortalElement={actionsBarPortalElement}
      topLayerPortalElement={topLayerPortalElement}
      file={selectedFile?.previews?.pdf || selectedFile?.url_pdf || selectedFile?.url}
      viewWidth={viewWidth}
    />
  );
};
