import { useContext, useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';
import { UserContext } from '../../../app/Dashboard/Members/User/UserProvider';
import { useResizeObserver } from '../../../helpers/hooks/useResizeObserver';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../LayoutContext';
import { Page, PageBody, PageHeader } from '../../Page';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { MembersPage } from './MembersPage';
import { MembersProvider } from './MembersContext';

export const BREAKPOINT_WIDTH = 1000;

export const Members = () => {
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const { setViewVariant } = useContext(LayoutContext);
  const { user = {} } = useContext(UserContext);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse(undefined, undefined, width <= BREAKPOINT_WIDTH);

  useEffect(() => {
    if (width <= BREAKPOINT_WIDTH) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width ]);

  return (
    <MembersProvider user={user}>
      <LayoutContextProvider>
        <Page>
          <PageHeader disableBorderBottom>
            <ActionsBar
              width={width}
              accessibleUserID={user.id}
              filterOpenButton={filterOpenButton}
            />
          </PageHeader>

          <PageBody fullHeight disablePaddings disableScrollbars>
            <Box height="100%" display="flex" flexDirection="column" ref={rootRef}>
              <ScrollWrapper {...scrollWrapperProps}>
                <FilterBarWrapper {...filterBarWrapperProps}>
                  <FiltersBar />
                </FilterBarWrapper>
              </ScrollWrapper>

              <MembersPage width={width} />
            </Box>
          </PageBody>
        </Page>
      </LayoutContextProvider>
    </MembersProvider>
  );
};
