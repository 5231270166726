import { Box } from '@material-ui/core';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Button } from '../../../../../../components/Button';
import { folderNamesMap } from '../../../AccountsContext';
import { EmailSaveModal } from '../../EmailSaveModal';
import { EmailsSendDraftModal } from '../../EmailsSendDraftModal';
import { EmailsReplyModal } from '../../EmailsReplyModal';

export const EmailSecondaryActions = ({
  size = 'medium',
  accounts = [],
  filter = {},
  messageItem = {},
  deleteMessage = () => {},
  ...props
}) => {
  const { openModal } = useModal();

  const openSendEmailModal = () => {
    openModal(EmailsSendDraftModal, {
      payload: {
        accounts,
        folderID: filter.folder_id,
        message: messageItem
      },
      onModalResolved: () => {
        deleteMessage([ messageItem.uid ]);
      }
    });
  };

  const openReplyEmailModal = () => {
    openModal(EmailsReplyModal, {
      payload: {
        disableReplayAll: true,
        replyTo: messageItem
      }
    });
  };

  const openReplyAllEmailModal = () => {
    openModal(EmailsReplyModal, {
      payload: {
        replyTo: messageItem
      }
    });
  };

  const openSaveEmailModal = () => {
    openModal(EmailSaveModal, {
      payload: {
        folderID: filter.folder_id,
        messageUIDs: [ messageItem.uid ],
        message: messageItem,
        accounts
      }
    });
  };

  return (
    <Box display="flex" justifyContent="flex-end" mx={3} py={2.5} {...props}>
      <Box mr={2}>
        {filter.folder_name === folderNamesMap.drafts ?
          <Button
            variant="contained"
            color="primary"
            size={size}
            onClick={openSendEmailModal}
          >
            Send
          </Button>
          :
          <>
            <Box component="span" mr={2}>
              <Button
                color="primary"
                size={size}
                onClick={openReplyAllEmailModal}
              >
                Reply all
              </Button>
            </Box>

            <Button
              variant="contained"
              color="primary"
              size={size}
              onClick={openReplyEmailModal}
            >
              Reply
            </Button>
          </>
        }
      </Box>

      <Button
        variant="contained"
        color="secondary"
        size={size}
        onClick={openSaveEmailModal}
      >
        Save to attach
      </Button>
    </Box>
  );
};
