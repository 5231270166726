import {
  Select,
  TextField,
  DatePicker,
  BillingParticipantsSelect
} from '../../../../../../../../../components/FormField';
import { paymentMethodsMap } from '../../../../../../../../../dataMaps/paymentMethodsMap';

export const getPaymentsFilterFields = () => {
  return [
    <TextField
      name="invoice_number"
      label="Inv number"
      placeholder="Search..."
    />,

    <BillingParticipantsSelect
      formattedValue={false}
      name="billing_participant_id"
      label="Participants"
    />,

    <DatePicker
      clearable
      outputFormat="YYYY-MM-DD"
      name="date_from"
      label="From"
    />,

    <DatePicker
      clearable
      outputFormat="YYYY-MM-DD"
      name="date_to"
      label="To"
    />,

    <Select
      isClearable
      name="method"
      label="Payment Method"
      placeholder="Select method"
      options={paymentMethodsMap}
    />
  ];
};

export const getPaymentsFiltersValues = (filters) => {
  return {
    invoice_number: filters?.invoice_number || null,
    billing_participant_id: filters?.billing_participant_id?.value || null,
    date_from: filters?.date_from || null,
    date_to: filters?.date_to || null,
    method: filters?.method?.value || null
  };
};
