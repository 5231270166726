export const typesDelimiter = [
  {
    value: ',',
    label: 'Сomma'
  },
  {
    value: ';',
    label: 'Semicolon'
  }
];
