import { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Date } from '../../../../../../components/Date';
import { UserLink } from '../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { IconButton } from '../../../../../../components/IconButton';
import { Loader } from '../../../../../../components/Loader';
import { ActivityModelUI, modelTypeOptions } from '../../activityDataMaps';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;
  const model = modelTypeOptions?.find(
    (modelOption) => modelOption.value === params?.model
  )?.label;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell grow flexWrap="wrap" flexBasis={columnsWidths.users}>
        {params?.user_ids?.map((user) => (
          <Box key={user?.id} mr={1} mb={0.5}>
            <UserLink disableName key={user?.id} user={user} />
          </Box>
        ))}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.logName}>
        {params?.log_name?.label}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.subjectType}>
        {params?.subject_type?.label}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.model}>
        {model || '-'}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.modelID}>
        <ActivityModelUI modelType={params?.model}  model={params?.model_id || {}} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.date}>
        <Date date={params?.date} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

