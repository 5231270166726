import { api } from '../api';

export const fetchContacts = (config = {}) => {
  return api.get('/personal-contacts', config)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const createContact = (contact) => {
  return api.post('/personal-contacts', contact)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const fetchContact = (id, config) => {
  return api.get(`/personal-contacts/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const updateContact = (contact) => {
  return api.put(`/personal-contacts/${contact.id}`, contact)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const deleteContact = (id) => {
  return api.delete(`/personal-contacts/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};
