import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { hasRole } from '../../../../utils/hasRole';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { TimeReports as TimeReportsCommon } from '../../../../components/time-reports/TimeReports';
import { TimeReportsProvider } from '../../../../components/time-reports/TimeReportsProvider';
import { filterFieldsMap } from '../../../../components/time-reports/TimeReports/FiltersBar';
import { LayoutContextProvider } from '../../../../components/LayoutContext';
import { Page, PageBody } from '../../../../components/Page';
import { Header } from './Header';

export const TimeReports = () => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const initialFilter = {
    company_id: hasRole(rolesMap.admin) ? null : currentUser.work?.company_id,
    user_id: hasRole(rolesMap.admin, rolesMap.supervisor) ? null : currentUser.id
  };
  const hiddenFilterFields = useMemo(() => {
    return !hasRole(rolesMap.admin, rolesMap.supervisor)
      ? [ filterFieldsMap.company, filterFieldsMap.user ]
      : hasRole(rolesMap.supervisor)
        ? [ filterFieldsMap.company ]
        : [];
  }, []);

  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  return (
    <LayoutContextProvider>
      <TimeReportsProvider filter={initialFilter}>
        <Page>
          <Header filterOpenButton={filterOpenButton} />

          <PageBody disablePaddings disableScrollbars fullHeight>
            <TimeReportsCommon
              filterKey={filtersKeysMap.timeReports}
              hiddenFilterFields={hiddenFilterFields}
              FilterBarWrapper={FilterBarWrapper}
              filterBarWrapperProps={filterBarWrapperProps}
              ScrollWrapper={ScrollWrapper}
              scrollWrapperProps={scrollWrapperProps}
            />
          </PageBody>
        </Page>
      </TimeReportsProvider>
    </LayoutContextProvider>
  );
};
