import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Button } from '../../../../../../../components/Button';
import { CardItem, CardHeader } from '../../../../../../../components/Cards';
import { ColorPreview } from '../../../../../../../components/ColorPreview';
import { IconButton } from '../../../../../../../components/IconButton';

export const Card = ({ isActive, appointmentType, onToggleActive, onTypeDelete }) => {
  const deleteAppointmentType = () => {
    onTypeDelete(appointmentType);
  };

  const toggleActiveAppointmentType = () => {
    onToggleActive(appointmentType.id);
  };

  return (
    <CardItem py={1} pl={2} pr={1} mb={2}>
      <CardHeader disableCheckBox disableCardMenu alignItems="center" childrenWidth={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box width="20" display="flex" alignContent="center" mr={1}>
              <ColorPreview color={appointmentType.color} />
            </Box>

            <Typography noWrap>
              {appointmentType.code}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              color={isActive ? 'warning' : 'success'}
              size="small"
              onClick={toggleActiveAppointmentType}
            >
              {isActive ? 'Inactive' : 'Active'}
            </Button>

            <IconButton
              variant="contained"
              color="error"
              edge="end"
              title="Remove from this appt. book"
              onClick={deleteAppointmentType}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </CardHeader>

      <Grid container spacing={1} component={Box} pt={2}>
        <Grid item xs={12}>
          <Typography>
            {appointmentType.description}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>Duration</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {moment.duration(appointmentType.time, 'seconds').format('h[h] m[min]')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>Claim required</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {appointmentType.claim_required ? 'Yes' : 'No'}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>Ordering</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {appointmentType.ordering}
          </Typography>
        </Grid>
      </Grid>
    </CardItem>
  );
};
