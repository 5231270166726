import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { setCasesLastGlobalAction } from '../../../../../../../../store/globalActions';
import * as casesApi from '../../../../../../../../api/cases';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { CaseViewModal } from '../../../../CaseViewModal';
import * as types from '../../../../CasesContext/types';
import { columnsWidths } from '../CaseCross';

export const Row = ({ caseItem, subCase }) => {
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const isPatient = hasRole(rolesMap.patient);

  const handleDetach = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        casesApi.detachCase(caseItem.id, subCase.id).then(({ data }) => {
          dispatch(setCasesLastGlobalAction({
            type: types.UPDATE_CASE_IN_LIST,
            payload: { ...caseItem, sub_cases: data }
          }));
        });
      }
    });
  };

  const openCasePreview = () => {
    if (subCase.id) {
      openModal(CaseViewModal, {
        payload: { id: subCase.id }
      });
    }
  };

  return (
    <ListRow button onClick={stopPropagation(openCasePreview)}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap title={subCase.name}>
          {subCase.name}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.file_number}>
        <Typography noWrap title={subCase.file_number}>
          {subCase.file_number || '-'}
        </Typography>
      </ListRowCell>

      {!isPatient &&
        <ListRowCell flexBasis={columnsWidths.action}>
          <Tooltip isExistTooltip title="Remove attached case">
            <IconButton onClick={stopPropagation(handleDetach)} color="error">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </ListRowCell>
      }
    </ListRow>
  );
};
