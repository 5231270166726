import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useModal } from '../../../../../components/ModalsProvider';
import { api } from '../../../../../api';
import * as typesApi from '../../../../../api/companies';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { reducer } from './reducer';
import * as companyType from './types';
import { initialState } from './initialState';

export const TypesContext = createContext();

export const TypesProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { filter, types, pagination, isFetching, isFetched } = state;
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const companiesTypesLastGlobalAction = useSelector(
    ({ globalActions }) => globalActions.companiesTypesLastGlobalAction
  );
  const cancelFetch = useRef(() => {});

  const fetchTypes = () => {
    dispatch({ type: companyType.FETCH_TYPES_REQUEST });

    typesApi.fetchCompanyTypes({
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then((data) => {
      dispatch({ type: companyType.FETCH_TYPES_SUCCESS, payload: data });
    });
  };

  const deleteType = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        typesApi.deleteCompanyType(id).then(() => {
          dispatch({ type: companyType.DELETE_TYPE, payload: id });
          enqueueSnackbar('Type successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Type is not deleted!', { variant: 'error' });
        });
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchTypes({ page: filter.page + 1 }).catch(() => {});
    }
  };

  useEffect(() => {
    companiesTypesLastGlobalAction && dispatch(companiesTypesLastGlobalAction);
  }, [ companiesTypesLastGlobalAction ]);

  const providerValue = {
    isFetched,
    isFetching,
    types,
    filter: {
      ...filter,
      ...pagination
    },

    loadNextPage,
    deleteType
  };

  useEffect(() => {
    fetchTypes();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return <TypesContext.Provider value={providerValue}>{children}</TypesContext.Provider>;
};
