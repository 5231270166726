export const eventBusEvents = {
  closeSiblingPoppers: 'closeSiblingPoppers',
  openThreadInWidget: 'openThreadInWidget',
  openChatWidget: 'openChatWidget',
  emailAccountDeleted: 'emailAccountDeleted',
  timeReportUpdate: 'timeReportUpdate',
  workSessionAdd: 'workSessionAdd',
  workSessionUpdate: 'workSessionUpdate',
  widgetOpen: 'widgetOpen',
  widgetClose: 'widgetClose',
  preventCollision: 'preventCollision',
  widgetMinimize: 'widgetMinimize'
};
