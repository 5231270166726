import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../../components/Loader';
import { Sticky } from '../../../../../../../../components/Sticky';
import { CaseDiscoveriesContext } from '../CaseDiscoveriesProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsMap = {
  discovery: 'discovery',
  to: 'to',
  from: 'from',
  sentDate: 'sentDate',
  extension: 'extension',
  receivedDate: 'receivedDate',
  dueDate: 'dueDate',
  endDate: 'endDate',
  actions: 'actions'
};

export const columnsWidths = {
  [columnsMap.discovery]: 140,
  [columnsMap.to]: 180,
  [columnsMap.from]: 180,
  [columnsMap.sentDate]: 112,
  [columnsMap.extension]: 112,
  [columnsMap.receivedDate]: 112,
  [columnsMap.dueDate]: 112,
  [columnsMap.endDate]: 112,
  [columnsMap.actions]: 80
};

export const List = ({ hiddenColumns }) => {
  const {
    isFetched,
    isFetching,
    meta,
    discoveries,
    loadNextPage,
    resetDiscoveries
  } = useContext(CaseDiscoveriesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetDiscoveries();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box pl={2} py={2}>
        <Loader p={2} loading={!isFetched} render={
          () => !discoveries.length ? (
            <Box p={2}>
              <Typography align="center">No discoveries found</Typography>
            </Box>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader hiddenColumns={hiddenColumns} />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={discoveries}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={meta}
                  RowComponent={Row}
                  RowProps={{ hiddenColumns }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
