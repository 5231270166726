import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Button, SvgIcon, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiTimerOutline } from '@mdi/js';
import * as categoriesApi from '../../../../../api/time-tracks';
import { Loader } from '../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../components/Modal';
import { Body } from './Body';
import { validationSchema } from './validationSchema';

export const CreateTimeTrackCategoryModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const createCategory = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.time = moment.duration(`${values.hours || 0}:${values.minutes || 0}`).asSeconds();
    values.rate = values.rate ? +values.rate * 100 : null;

    return categoriesApi.createCategory(values).then((data) => {
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Not created', { variant: 'error' });
      }
    });
  };

  return (
    <Dialog fullScreen={isMobile} {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={{
          name: null,
          is_active: true,
          company_id: null,
          case_id: null,
          hours: null,
          minutes: null,
          rate: null
        }}
        validationSchema={validationSchema}
        onSubmit={createCategory}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiTimerOutline}/></SvgIcon>}
              onClose={handleModalReject}
            >
              Create Category
            </ModalHeader>

            <ModalBody>
              <Body />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
