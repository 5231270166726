import { makeStyles } from '@material-ui/core';
import { TableHeader } from '../../InvoiceContent/TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SelectedInvoices = ({ selectedInvoices = [], width }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TableHeader width={width} />

      {selectedInvoices?.map((item, index) => (
        <Row
          key={index}
          invoice={item}
          width={width}
        />
      ))}
    </div>
  );
};
