import { Dialog, Button, Typography } from '@material-ui/core';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../Modal';

export const DeleteOnlyNextAppointmentsModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  return (
    <Dialog maxWidth="xs" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Delete recurring appointment
        </ModalHeader>

        <ModalBody>
          <Typography>
            Past appointments cannot be deleted in bulk. If necessary, delete them one at a time.
          </Typography>
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleModalReject}>
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleModalResolve}
          >
            Delete all next appt's
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Dialog>
  );
};
