export const filterFieldsMap = {
  dateFrom: 'date_from',
  dateTo: 'date_to',
  name: 'name',
  physicianId: 'physician_id',
  officeId: 'office_id'
};

export const filterFieldsLabels = {
  [filterFieldsMap.dateFrom]: 'Date From',
  [filterFieldsMap.dateTo]: 'Date To',
  [filterFieldsMap.name]: 'Immunization',
  [filterFieldsMap.physicianId]: 'Appointment book',
  [filterFieldsMap.officeId]: 'Office'
};
