import { Box } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../../../../../helpers/dates';
import { FiltersBar as FiltersBarComponent } from '../../../../../../../../components/FiltersBar';
import { CaseUsersSelect } from '../../../../../../../../components/cases/CaseUsersSelect';
import { billingParticipantsTypesMap } from '../../../../../../../../components/billing';
import {
  TimeTracksCategoriesSelect,
  KeyboardDatePicker,
  CompaniesAutocomplete
} from '../../../../../../../../components/FormField';

const initialValues = {
  users_id: [],
  company_id: null,
  category_id: null,
  started_after: null,
  finished_before: null
};

export const FiltersBar = ({ filter, setFilter }) => {
  const { values } = useFormikContext();
  const formikRef = useRef();

  const handleDatePickerChange = (name, transformer) => (date) => {
    setFilter((filter) => ({ ...filter, [name]: transformer?.(date) || date }));
  };

  const handleUsersChange = (users) => {
    setFilter((state) => ({ ...state, users_id: users?.map(({ id }) => id) }));
  };

  const handleTimeTrackCategoryChange = (option) => {
    setFilter((state) => ({ ...state, category_id: option?.id }));
  };

  const handleCompanyChange = (company) => {
    setFilter((state) => ({ ...state, company_id: company?.id || null }));
  };

  useEffect(() => {
    if (values.participant?.id) {
      if ((values?.participant_type?.value || values?.participant_type) === billingParticipantsTypesMap.user) {
        formikRef?.current?.setValues({
          ...filter,

          users_id: [ values?.participant ]
        });
      } else {
        formikRef?.current?.setValues({
          ...filter,

          company_id: values.participant
        });
      }
    } else {
      formikRef?.current?.setValues({
        ...filter,

        company_id: null,
        users_id: []
      });
    }
  }, [ values.participant ]);

  return (
    <FiltersBarComponent
      formikRef={formikRef}
      isResetForm
      border={0}
      initialValues={initialValues}
      fields={[
        <CaseUsersSelect
          multiple
          name="users_id"
          label="Users"
          onChange={handleUsersChange}
        />,
        <Box minWidth={210}>
          <CompaniesAutocomplete
            name="company_id"
            label="Company"
            onChange={handleCompanyChange}
          />
        </Box>,
        <TimeTracksCategoriesSelect
          name="category_id"
          label="Category"
          onChange={handleTimeTrackCategoryChange}
        />,
        <KeyboardDatePicker
          clearable
          minWidth={100}
          name="started_after"
          label="Started at"
          placeholder="Started at"
          onChange={handleDatePickerChange('started_after', unixToStartOfDayUnix)}
        />,
        <KeyboardDatePicker
          clearable
          minWidth={100}
          name="finished_before"
          label="Finished at"
          placeholder="Finished at"
          onChange={handleDatePickerChange('finished_before', unixToEndOfDayUnix)}
        />
      ]}
    />
  );
};
