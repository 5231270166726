import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, MenuList, MenuItem } from '@material-ui/core';
import { formTitlesMap } from '../../components/medical/forms';
import { formsTypes } from './formsTypes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FormsMenu = ({ onClose }) => {
  const classes = useStyles();

  return (
    <MenuList className={classes.list}>
      {formsTypes.map((type) => (
        <MenuItem
          key={type}
          component={RouterLink}
          to={'/medical-forms/create?form-type=' + formTitlesMap[type]}
          onClick={onClose}
        >
          {type}
        </MenuItem>
      ))}
    </MenuList>
  );
};
