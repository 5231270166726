import { tasksPrioritiesOptions } from '../../dataMaps';
import { api } from '../api';

const transformTask = (task) => {
  const priority = task.priority && tasksPrioritiesOptions.find(({ value }) => value === task.priority);

  task.priorityLabel = priority && priority.label;

  return task;
};

export const fetchTasks = (config = {}) => {
  return api.get('/tasks', config).then(({ data: { data, pagination } }) => {
    return { data: data.map(transformTask), pagination };
  }).catch((thrown) => {
    throw thrown;
  });
};

export const createTask = (task) => {
  return api.post('/tasks', task).then(({ data: { data } }) => transformTask(data));
};

export const fetchTask = (id) => {
  return api.get(`/tasks/${id}`).then(({ data }) => transformTask(data));
};

export const updateTask = ({ id, ...task }) => {
  return api.put(`/tasks/${id}`, task).then(({ data: { data } }) => transformTask(data));
};

export const deleteTask = (id) => {
  return api.delete(`/tasks/${id}`).then(({ data }) => data);
};

export const copyTask = (id) => {
  return api.post(`/tasks/${id}`).then(({ data: { data } }) => transformTask(data));
};

export const updateTaskPosition = (id, config) => {
  return api.put(`/tasks/position/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massAssignTasks = (data) => {
  return api.post('/tasks/parent', data).then((data) => {
    return data;
  });
};

export const massDeleteTasks= (IDs) => {
  return api.delete('/tasks', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
