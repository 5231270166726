import { useContext } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../../helpers/dom';
import { roleLabelsMap } from '../../../../../../components/users';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Contact } from '../../../../../../components/Contact';
import { UserSSN } from '../../../../../../components/users';
import { Popper } from '../../../../../../components/Popper';
import { Label } from '../../../../../../components/Label';
import { UsersContext, statusTypesColors, statusTypesNames } from '../../../UsersContext';
import { MembersMenu } from '../../MembersMenu';
import {
  minRowHeight,
  columnsWidths,
  widthBreakpointMedium,
  widthBreakpointSmall
} from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ width, user }) => {
  const classes = useStyles();
  const { selectedIDs, toggleItemSelection } = useContext(UsersContext);
  const isSelected = selectedIDs.indexOf(user.id) !== -1;
  const { openModal } = useModal();

  const handleItemSelection = () => {
    toggleItemSelection(user.id);
  };

  const openPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: user.id }
    });
  };

  return (
    <ListRow button minHeight={minRowHeight} onClick={openPreview}>
      <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />

      <ListRowCell grow flexBasis={columnsWidths.name}>
        <UserLink disableLink noWrap user={user} size="md" />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.ssn}>
        <UserSSN
          user={user}
          TypographyProps={{ variant: 'inherit' }}
        />
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.email}>
          <Contact
            noWrap
            color="primary"
            type="mailto"
            contact={user.email}
            title={user.email}
          />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.phone_number}>
        <Contact
          noWrap
          type="tel"
          contact={user.phone || user?.mobile_phone_number || user?.phone_number}
          title={get(user, 'phone')}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.role}>
        <Typography noWrap className={classes.role}>
          {roleLabelsMap[user.role]}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        <Label
          color={statusTypesColors[+user.is_active]}
          label={statusTypesNames[+user.is_active] || 'Active'}
          className={classes.label}
        />
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.attend}>
          <Typography noWrap>
            {user.visited_at ? moment.unix(user.visited_at).format('L LT') : '-'}
          </Typography>
        </ListRowCell>
      }

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <MembersMenu user={user} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
