import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { getUserNameAbbr } from '../../../../../../../helpers/users';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { Avatar } from '../../../../../../../components/Avatar';
import { IconButton } from '../../../../../../../components/IconButton';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ contact }) => {
  const classes = useStyles();
  const history = useHistory();
  const profile = useSelector(({ profile }) => profile);

  const getEditContactPage = () => {
    if (get(profile, 'contact.id') === contact.id) {
      history.push('/profile/edit');
    } else {
      history.push(`/contacts/contacts/${contact.id}/edit`);
    }
  };

  return (
    <div className={classes.root}>
      {hasRole(rolesMap.admin, rolesMap.supervisor) &&
        <IconButton className={classes.action} onClick={getEditContactPage}>
          <EditIcon />
        </IconButton>
      }

      <div className={classes.avatar}>
        <Avatar
          size="extraLarge"
          src={contact.avatar}
          alt={getUserNameAbbr(contact)}
        />
      </div>
    </div>
  );
};
