import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import { OfficesLocationSelect } from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';

export const PhysicianFormInfo = ({ disableSelect = false, md = 6, xs = 12, lg = 6, xl = 4 }) => {
  const { values } = useFormikContext();

  return (
    <>
      <Grid item xl={xl} lg={lg} md={md} xs={xs}>
        <UsersSelect
          name="referred_id"
          label="Referred by"
          params={{ role: 'doctor' }}
        />
      </Grid>

      {!disableSelect && (
        <Grid item xl={xl} lg={lg} md={md} xs={xs}>
          <OfficesLocationSelect
            name="office_id"
            label="Office"
            params={{ users: values.treating_physician_id ? [ values.treating_physician_id ] : null }}
          />
        </Grid>
      )}
    </>
  );
};
