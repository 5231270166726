import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import { OfficesLocationSelect } from '../../FormField';
import { UsersSelect } from '../../users';
import { PatientsSelect } from '../../users/PatientsSelect';
import { AppointmentsSelect } from '../AppointmentsSelect';

export const ApptInfo = ({ disableApptFiled = false }) => {
  const { values } = useFormikContext();

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item sm={6} xs={12}>
        <PatientsSelect
          disabled
          name="patient_id"
          label="Patient"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        {!disableApptFiled && (
          <AppointmentsSelect
            disabled
            name="appointment_id"
          />
        )}
      </Grid>

      <Grid item sm={6} xs={12}>
        <UsersSelect
          disabled
          name="approved_id"
          label="Approved by"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <OfficesLocationSelect
          disabled
          params={{ users: [ values.approved_id ] }}
          name="office_id"
          label="Office / Practice address"
        />
      </Grid>
    </Grid>
  );
};
