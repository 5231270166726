import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../CustomScrollbars';
import { InfiniteListLoader } from '../../../../InfiniteLoader';
import { Loader } from '../../../../Loader';
import { SavedFiltersContext } from '../../../SavedFiltersProvider';
import { Card } from './Card';

export const Grid = ({ CardContent, onApplySavedFilter, hiddenFields }) => {
  const {
    filter,
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%', flexGrow: 1 }}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No saved filters</Typography>
        ) : (
          <MuiList disablePadding>
            <InfiniteListLoader
              items={savedFilters}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={filter}
              RowComponent={Card}
              RowProps={{ onApplySavedFilter, CardContent, hiddenFields }}
              scrollElementRef={scrollElementRef}
              onNeedNextPage={loadNextPage}
            />
          </MuiList>
        )}
      />
    </CustomScrollbars>
  );
};
