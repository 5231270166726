import { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { merge } from 'lodash';
import { api } from '../../../../../api';
import * as formsApi from '../../../../../api/profile';
import { capitalizeFirstLetter } from '../../../../../helpers/capitalizeFirstLetter';
import { setProfileData } from '../../../../../store/dashboard/profile';
import { setMedicalProfileLastGlobalAction, setUsersLastGlobalAction } from '../../../../../store/globalActions';
import { ApproveConfirmationModal } from '../../../../../components/medical/forms';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { formTypesMap } from '../../../../../components/medical/forms';
import { useModal } from '../../../../../components/ModalsProvider';
import * as userTypes from '../../../Members/User/UserProvider/types';
import { UserContext } from '../../../Members/User/UserProvider';
import * as medicalTypes from '../MainInfo/MedicalProfileProvider/types';
import { getFormRoutes } from '../MedicalForms/MedicalFormsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';
import {
  getExistingFormSteps,
  getExistingInjuryFormSteps,
  getExistingInjuryOrthoFormSteps,
  injurySteps,
  steps as privateSteps
} from './steps';

export const MedicalFormItemContext = createContext(null);

export const MedicalFormItemProvider = ({
  initialState: initialStateProp = {},
  children
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const {
    form,
    isFetched,
    medicalInfoPathname
  } = state;
  const { openModal } = useModal();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();
  const [ isDirty, setIsDirty ] = useState(false);
  const [ activeStep, setActiveStep ] = useState(0);
  const [ completed, setCompleted ] = useState({});
  const [ type, setType ] = useState(location?.state?.type || null);
  const [ initialFormValue, setInitialFormValue ] = useState(location?.state?.form || {});
  const steps = type === formTypesMap.private ? privateSteps : injurySteps;
  const parentRoute = `${medicalInfoPathname}/medical-forms`;
  const cancelFetch = useRef(() => {});
  const currentUser = useSelector(({ profile }) => profile.user);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : currentUser;
  const isConfirmed = !!form?.patient_id || !!(form?.id && form?.medical?.id && form?.is_confirmed);

  const fetchForm = ({ id = null }) => {
    cancelFetch.current();
    dispatch({ type: types.FETCH_FORM_REQUEST });

    if (id) {
      formsApi.fetchMedicalForm(id, {
        cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
      }).then((data) => {
        setType(data.form_type);

        setCompleted(data.form_type === formTypesMap.private || data.form_type === formTypesMap.compensation
          ? getExistingFormSteps(data)
          : data.form_type === formTypesMap.ortho
            ? getExistingInjuryOrthoFormSteps(data)
            : getExistingInjuryFormSteps(data)
        );

        dispatch({
          type: types.FETCH_FORM_SUCCESS,
          payload: { ...location?.state?.form, ...initialFormValue, ...data }
        });

        setInitialFormValue({ ...initialFormValue, ...data });
      });
    } else {
      dispatch({
        type: types.FETCH_FORM_SUCCESS,
        payload: location?.state?.form || {}
      });
    }
  };

  const handleBack = ({ dirty, id }) => {
    if (dirty) {
      openModal(ConfirmationModal, {
        payload: {
          title: 'Do you want to leave the page without saving?'
        },
        onModalResolved: () => {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          history.push(getFormRoutes({ id, medicalInfoPathname })[activeStep - 1]);
        }
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      history.push(getFormRoutes({ id, medicalInfoPathname })[activeStep - 1]);
    }
  };

  const handleReset = () => {
    if (!form?.is_confirmed) {
      openModal(ApproveConfirmationModal, {
        payload: { form },
        onModalResolved: () => {
          setActiveStep(0);
          setCompleted({});
          history.push(parentRoute);
        }
      });

      return;
    }

    openModal(ConfirmationModal, {
      payload: {
        title: 'Submit your form?'
      },
      onModalResolved: () => {
        setActiveStep(0);
        setCompleted({});
        enqueueSnackbar('Form is completed', { variant: 'success' });
        history.push(parentRoute);
      }
    });
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === steps.length;
  };

  const handleNext = (id) => {
    const newActiveStep = isLastStep() && !allStepsCompleted()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;

    setActiveStep(newActiveStep);
    history.push(getFormRoutes({ id, medicalInfoPathname })[activeStep + 1]);
  };

  const handleComplete = (id) => {
    const newCompleted = completed;

    newCompleted[activeStep] = true;

    setCompleted(newCompleted);
    handleNext(id);
  };

  const updateUserProfile = ({ isEditableProfile, values }) => {
    if (!isEditableProfile && medicalInfoPathname?.length > 0) {
      const updatedUser = {
        ...user,

        profile: {
          ...user.profile,

          birthday: values.dob,
          phone_number: values.phone,
          mobile_phone_number: values.mobile_phone,
          ssn: values.ssn,
          home: {
            street: values.address,
            country: values.country,
            state: values.state,
            city: values.city,
            zip: values.zip
          }
        }
      };

      dispatchRedux(setProfileData(updatedUser));
    } else {
      dispatchRedux(setUsersLastGlobalAction({ type: userTypes.SET_PROFILE_DATA }));
    }
  };

  const createFirstStep = ({
    updateFirstStep,
    isEditableProfile,
    values,
    setErrors = () => {},
    setSubmitting = () => {}
  }) => {
    values.primary_insurance_id = values?.primary_insurance_id?.value || values?.primary_insurance_id;
    values.first_name = capitalizeFirstLetter(values.first_name);
    values.last_name = capitalizeFirstLetter(values.last_name);

    formsApi.createMedicalForm(values).then((data) => {
      updateUserProfile({ isEditableProfile, values });

      if (updateFirstStep) {
        dispatchRedux(setMedicalProfileLastGlobalAction({
          type: medicalTypes.UPDATE_PROFILE,
          payload: data?.medical
        }));
      }

      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleComplete(data.id);
    }).catch(({ errors }) => {
      setSubmitting(false);

      if (errors) {
        setErrors(errors);
        enqueueSnackbar('Form not created', { variant: 'error' });
      }
    });
  };

  const updateForm = ({
    updateFirstStep,
    isEditableProfile,
    values,
    setErrors = () => {},
    setSubmitting = () => {}
  }) => {
    values.primary_insurance_id = values?.primary_insurance_id?.value || values?.primary_insurance_id;
    values.first_name = capitalizeFirstLetter(values.first_name);
    values.last_name = capitalizeFirstLetter(values.last_name);

    formsApi.updateMedicalForm(values).then((data) => {
      updateUserProfile({ isEditableProfile, values });

      if (updateFirstStep) {
        dispatchRedux(setMedicalProfileLastGlobalAction({
          type: medicalTypes.UPDATE_PROFILE,
          payload: data?.medical
        }));
      }

      dispatch({ type: types.UPDATE_FORM, payload: data });
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleComplete(values.id);
    }).catch(({ errors }) => {
      setSubmitting(false);

      if (errors) {
        setErrors(errors);
        enqueueSnackbar('Form not updated', { variant: 'error' });
      }
    });
  };

  const providerValue = {
    ...state,
    form,
    user,
    type,
    isDirty,
    isFetched,
    isConfirmed,
    completed,
    activeStep,
    parentRoute,
    medicalInfoPathname,
    initialFormValue,

    // functions
    setType,
    setIsDirty,
    setCompleted,
    setInitialFormValue,
    fetchForm,
    createFirstStep,
    updateForm,
    handleBack,
    handleNext,
    handleReset,
    handleComplete,
    setActiveStep
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <MedicalFormItemContext.Provider value={providerValue}>
      {children}
    </MedicalFormItemContext.Provider>
  );
};
