import { Icon } from '@mdi/react';
import {
  mdiFileAlertOutline,
  mdiEmailMultipleOutline,
  mdiBriefcaseCheckOutline
} from '@mdi/js';
import { SvgIcon } from '@material-ui/core';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';

export const emails = {
  name: 'Emails',
  icon: MailOutlineOutlinedIcon,
  path: '/emails',
  routes: [
    {
      name: 'Email client',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiEmailMultipleOutline} />
        </SvgIcon>
      ),
      path: '/emails/client'
    },
    {
      name: 'Saved emails',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiBriefcaseCheckOutline} />
        </SvgIcon>
      ),
      path: '/emails/saved'
    },
    {
      name: 'Rules',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiFileAlertOutline} />
        </SvgIcon>
      ),
      path: '/emails/rules'
    }
  ]
};
