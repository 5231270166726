import moment from 'moment-timezone';
import { transformTimeZoneToOption } from '../../FormField/selects/TimeZoneSelect';

export const apptInitialValues = {
  authorization_id: null,
  copay_type: null,
  patient_id: null,
  patient_insurance_id: null,
  price: null,
  appointment_confirmed_by_office: false,
  eligibility_manually_verified: false,
  do_not_bill_claim: false,
  chief_complaint: null,
  note: null,
  time: null,
  _minutes: null,
  _hours: null,
  appointment_book_id: null,
  appointment_type_id: null,
  arrival_status_id: null,
  appointment_at: moment().unix(),
  visit_reason_id: null,
  timezone: transformTimeZoneToOption(moment.tz.guess(true)),
  recurring_rule: null,
  office_id: null,
  patient_appointment_details: {
    padding: null
  },
  insurance_details: {
    padding: null
  }
};
