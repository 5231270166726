import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_APPOINTMENTS_SUCCESS]: (state, { appointments, daysData }) => {
    return {
      ...state,

      isFetched: true,
      appointments,
      daysData
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_APPOINTMENTS]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.ADD_APPOINTMENT]: (state, appointment) => {
    if (!(state.filter.appointment_from <= appointment.appointment_at &&
      state.filter.appointment_to >= appointment.appointment_at)) {
      return state;
    }

    return {
      ...state,

      appointments: state.appointments.concat(appointment)
    };
  },

  [types.UPDATE_APPOINTMENT]: (state, appointment) => {
    return {
      ...state,

      appointments: state.appointments.map((item) => {
        return item.id === appointment.id ? { ...item, ...appointment } : item;
      })
    };
  },

  [types.DELETE_APPOINTMENT]: (state, appointmentID) => {
    return {
      ...state,

      appointments: state.appointments.filter(({ id }) => appointmentID !== id)
    };
  }
});
