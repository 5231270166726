export const filterFieldsMap = {
  user: 'user_id',
  company: 'company_id',
  startedFrom: 'started_from',
  startedTo: 'started_to',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.company]: 'Company name',
  [filterFieldsMap.user]: 'User',
  [filterFieldsMap.startedFrom]: 'Started From',
  [filterFieldsMap.startedTo]: 'Started To',
  [filterFieldsMap.orderBy]: 'Order by'
};
