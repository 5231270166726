import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Box, Button } from '@material-ui/core';
import * as signatureApi from '../../../../../../../../../api/emails';
import { ModalBody, ModalFooter } from '../../../../../../../../../components/Modal';
import { Editor, TextField } from '../../../../../../../../../components/FormField';
import { Loader } from '../../../../../../../../../components/Loader';
import { validationSchema } from './validationSchema';

export const EmailSignature = ({
  account,
  onResolve = () => {},
  onReject = () => {}
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const editSignature = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return signatureApi.updateEmailSignature({ id: account.id, ...values }).then((data) => {
      onResolve({ ...account,  signature_line: data });
      enqueueSnackbar('Successfully updated', { variant: 'success' });
    }).catch((errors) => {
      errors && setErrors(errors);
      enqueueSnackbar('Signature not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        html: account?.signature_line?.html || null,
        name: account?.signature_line?.name || null
      }}
      validationSchema={validationSchema}
      onSubmit={editSignature}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Box
          noValidate
          component="form"
          display="flex"
          flexDirection="column"
          overflow="hidden"
          minHeight={500}
          onSubmit={handleSubmit}
        >
          <ModalBody>
            <Box mb={4}>
              <TextField
                required
                name="name"
                label="Name your signature"
                placeholder="Enter signature name..."
              />
            </Box>

            <Editor name="html" margin="normal" />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onReject}>Cancel</Button>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              )}
            />
          </ModalFooter>
        </Box>
      )}
    </Formik>
  );
};
