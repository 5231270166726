import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { makeStyles, useTheme, Checkbox, Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { rolesMap } from '../../../../../../../dataMaps';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { Date } from '../../../../../../../components/Date';
import { FavoriteCaseIndicator } from '../../../../../../../components/cases/FavoriteCaseIndicator';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { itemsRoutesByModels } from '../../../../../../../dataMaps/apiModels';
import { Members } from '../../../../../../../components/Members';
import { Loader } from '../../../../../../../components/Loader';
import { Popper } from '../../../../../../../components/Popper';
import { Label } from '../../../../../../../components/Label';
import { CasesContext } from '../../../CasesContext';
import { CaseMenu } from '../../../CaseMenu';
import { columnsWidths } from '../index';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item: caseItem = {}, isLoaded, recalculateHeight }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { selectedCasesIDs, toggleCaseSelected } = useContext(CasesContext);
  const isSelected = caseItem && selectedCasesIDs.indexOf(caseItem.id) !== -1;
  const types = caseItem?.case_type?.map(({ name }) => name).join(', ');
  const owner = caseItem?.case_users?.find(({ case_role }) => case_role === rolesMap.heading_attorney);

  const handleItemSelection = () => {
    toggleCaseSelected(caseItem);
  };

  const getCasePage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.case(caseItem.id));
    }
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button selected={isSelected} onClick={getCasePage}>
      <ListRowCell>
        <Checkbox edge="start" checked={isSelected} onClick={stopPropagation(handleItemSelection)} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.name}>
        <div className={classes.caseFileNumber}>
          {caseItem.file_number &&
            caseItem.is_favorite ? (
              <FavoriteCaseIndicator fontSize="inherit">
                <WorkIcon className={classes.caseIcon} />
              </FavoriteCaseIndicator>
            ) : (
              <WorkIcon className={classes.caseIcon} />
            )}

          <Tooltip title={caseItem.file_number}>
            <Typography noWrap className={classes.fileNumber}>
              {caseItem.file_number}
            </Typography>
          </Tooltip>
        </div>
      </ListRowCell>

      <ListRowCell grow column flexBasis={columnsWidths.description}>
        <Typography noWrap className={classes.caseName} title={caseItem.name}>
          {caseItem.name}
        </Typography>

        <Typography noWrap color="textSecondary" title={caseItem.description}>
          {caseItem.description}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.type}>
        <Typography noWrap title={types}>
          {types?.length ? types : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell column flexBasis={columnsWidths.status}>
        {!caseItem.case_status ? '-' : (
          <Label label={caseItem.case_status.name} color={theme.palette.primary.main} />
        )}

        <Date date={caseItem.last_status_changed_at} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.members}>
        <Members
          isParent
          noWrap
          maxVisible={4}
          owner={owner}
          users={caseItem.case_users || []}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.lastChange}>
        {moment.unix(caseItem.last_activity_at).fromNow()}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updatedDate}>
        {moment.unix(caseItem.last_changed_at).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
              <MoreVertIcon />
            </IconButton>
          )}
        >
          {({ handleClose }) => (
            <CaseMenu
              caseItem={caseItem}
              onClose={handleClose}
            />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
