import { SvgIcon } from '@material-ui/core';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import HumanQueue from '../../../components/icons/human-queue.svg';
import { filtersKeysMap } from '../../../store/lastFilters';
import { Appointments } from './Appointments';
import { WaitingList } from './WaitingList';
import { Appointment as Settings } from '../CodesPage/Appointment';

export const routes = [
  {
    exact: true,
    path: '/appointments',
    icon: <InsertInvitationOutlinedIcon />,
    label: 'Appointments',
    render: () => <Appointments filterKey={filtersKeysMap.calendar_appointments} />
  },
  {
    path: '/appointments/waiting-list',
    icon: <SvgIcon>
      <HumanQueue />
    </SvgIcon>,
    label: 'Waiting List',
    component: () => <WaitingList filterKey={filtersKeysMap.appointments_waiting_list} />
  },
  {
    path: '/appointments/system-settings',
    icon: <DateRangeOutlinedIcon />,
    label: 'System settings',
    component: () => (
      <Settings baseUrl="/appointments/system-settings" />
    )
  }
];
