import { Switch, Route, Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { AppointmentCheckIn } from '../components/appointments/AppointmentCheckIn';
import { PrivateRoute } from '../components/PrivateRoute';
import { Dashboard } from './Dashboard';
import { DemoRequest } from './DemoRequest';
import { FilesSharingPage } from './FilesSharingPage';
import { NotFound } from './NotFound';
import { Login, LoginConfirmation, ResetPassword, EmptyPage } from './Auth';
import { AppointmentConfirmationPage } from './AppointmentConfirmationPage';
import { EventConfirmationPage } from './EventConfirmationPage';
import { AppointmentsScoreboardPage } from './AppointmentsScoreboardPage';
import { ChangelogPage } from './ChangelogPage';
import { Providers } from './Providers';
import { SharedFilesPage } from './SharedFilesPage';

export const App = () => {
  return (
    <Providers>
      <Box display="flex" position="relative" overflow="auto" height="100%">
        <Switch>
          <Route path="/reset" component={EmptyPage} />
          <Route path="/login" component={Login} />
          <Route path="/demo-request" component={DemoRequest} />
          <Route path="/login-confirmation" component={LoginConfirmation} />
          <Route path="/password/confirm" component={ResetPassword} />
          <Route path="/404" component={NotFound} />
          <Route path="/changelog" component={ChangelogPage} />
          <Route path="/appointment-confirmation/:token" component={AppointmentConfirmationPage} />
          <Route path="/schedule-event-confirmation/:token" component={EventConfirmationPage} />
          <Redirect exact from="/public-share" to="/404" />
          <Route exact path="/public-share/files" component={SharedFilesPage} />
          <Route exact path="/public-files" component={FilesSharingPage} />
          <PrivateRoute path="/appointment-check-in" component={AppointmentCheckIn} />
          <PrivateRoute path="/appointments-scoreboard" component={AppointmentsScoreboardPage} />
          <PrivateRoute path="/" component={Dashboard} />
        </Switch>
      </Box>
    </Providers>
  );
};
