import { useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Loader } from '../../../../components/Loader';
import { formComponentsByTypes } from '../formComponentsByTypes';
import { FormContext } from '../FormProvider';

export const FormSteps = () => {
  const params = useParams();
  const location = useLocation();
  const { isFetched, type, fetchForm } = useContext(FormContext);
  const Component = formComponentsByTypes[type];

  useEffect(() => {
    fetchForm({ id: params.medicalFormID || null });
  }, [ location.pathname ]);

  return (
    <Loader p={3} loading={!isFetched && !type} render={() => <Component />} />
  );
};
