export const tooltipHelper = {
  // eslint-disable-next-line max-len
  'Unbilled Balance': 'UNBILLED BALANCE - When clicking on this, you will see a screen with all Time and Expense cards ' +
    'that have not been invoiced. You can edit these entries from here using the Action button.',
  'Balance': 'OPERATING RETAINER - When clicking on this, you will view Operating Retainer balances for each ' +
    'of your Matters.',
  'Balance Due': 'As with Operating Retainer, you will view Trust Retainer balances for each of your Matters by ' +
    'clicking on the bar.',
  'Unpaid Balance': 'Some text'
};

export const titleHelper = {
  'Balance': 'Operating Retainer',
  'Balance Due': 'Trust retainer'
};

export const getTooltip = (filterType) => {
  return tooltipHelper[filterType] || 'When you click on one of the bar graphs, it will take you to another screen ' +
    'breaking down the data for that figure.';
};
