import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  AppBar,
  DialogContent,
  Button,
  Tabs,
  Tab,
  Typography,
  Dialog,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import * as profileApi from '../../../../../../api/profile';
import { setProfileData } from '../../../../../../store/dashboard/profile';
import { Loader } from '../../../../../../components/Loader';
import { ModalFooter } from '../../../../../../components/Modal';
import { validationSchema } from './validationSchema';
import { Choose } from './Choose';
import { Draw } from './Draw';
import { Upload } from './Upload';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const UpdateSignatureModal = ({
  DialogProps,
  payload,
  handleModalResolve,
  handleModalReject
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [ currentTabIndex, setCurrentTabIndex ] = useState(0);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isValid, setIsValid ] = useState(false);
  const [ signature, setSignature ] = useState(payload?.signature || null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const handleTabChange = (event, currentTabIndex) => {
    setCurrentTabIndex(currentTabIndex);
  };

  const handleSignatureChange = (updatedSignature) => {
    setSignature((signature) => ({ ...signature, ...updatedSignature }));
  };

  const updateSignature = () => {
    const formData = new FormData();

    for (let key in signature) if (signature.hasOwnProperty(key)) {
      formData.append(key, signature[key]);
    }

    setIsSubmitting(true);

    profileApi.updateSignature(formData).then((data) => {
      dispatch(setProfileData({ signature: data }));
      enqueueSnackbar('Signature successfully updated', { variant: 'success' });
      handleModalResolve();
    }).catch(() => {
      setIsValid(false);
      enqueueSnackbar('Something went wrong, try again later', {
        variant: 'error'
      });
    }).then(() => {
      setIsSubmitting(false);
    });
  };

  useEffect(() => {
    validationSchema.isValid(signature).then((isValid) => {
      setIsValid(isValid && !!(signature.electronic_signature_file && signature.fax_signature_file));
    });
  }, [ signature ]);

  return (
    <Dialog
      fullScreen={isMobile}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      {...DialogProps}
    >
      <AppBar color="default">
        <Tabs
          value={currentTabIndex}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          onChange={handleTabChange}
        >
          <Tab label="Choose" />
          <Tab label="Draw" />
          <Tab label="Upload" />
        </Tabs>
      </AppBar>

      <DialogContent classes={{ root: classes.content }}>
        {currentTabIndex === 0 && <Choose signature={signature} onChange={handleSignatureChange} />}
        {currentTabIndex === 1 && <Draw onChange={handleSignatureChange} />}
        {currentTabIndex === 2 && <Upload onChange={handleSignatureChange} />}

        <Typography classes={{ root: classes.agreement }}>
          By clicking Save, I agree that the signature and initials will be the electronic representation
          of my signature and initials for all purposes when I (or my agent) use them on envelopes,
          including legally binding contracts - just the same as a pen-and-paper signature or initial.
        </Typography>
      </DialogContent>

      <ModalFooter>
        <Button disabled={isSubmitting} onClick={handleModalReject}>
          Cancel
        </Button>

        <Loader
          surface
          loading={isSubmitting}
          render={
            () => (
              <Button
                autoFocus
                disabled={isSubmitting || !isValid}
                variant="contained"
                color="primary"
                onClick={updateSignature}
              >
                Save
              </Button>
            )
          }
        />
      </ModalFooter>
    </Dialog>
  );
};
