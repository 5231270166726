import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import {
  Box,
  Dialog,
  SvgIcon,
  Typography,
  FormControlLabel,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { mdiFileAlertOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as emailsRulesApi from '../../../../../../api/emails-rules';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../../../components/Modal';
import { FormStep, getRuleInitialValues, validationSchema } from '../../../../../../components/emails';
import { TextField, Checkbox, Select } from '../../../../../../components/FormField';
import { Loader } from '../../../../../../components/Loader';
import { Button } from '../../../../../../components/Button';
import {
  conditionOptions,
  actionOptions,
  transformAccountsToOptions
} from '../../../../../../components/emails/options';
import { BodyActions } from './BodyActions';
import { BodyConditions } from './BodyConditions';

export const CreateEmailsRuleFormModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { accounts, initialRule = {} }
}) => {
  const accountsOptions = transformAccountsToOptions(accounts);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isTable = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const handleRuleCreate = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return emailsRulesApi.createEmailRule({
      ...values,

      conditions: values?.conditions?.map((condition) => ({
        key: condition?.key?.value,

        ...(condition?.values && {
          values: condition?.values?.map((value) => value)
        })
      })),

      actions: values?.actions?.map((action) => ({
        key: action?.key?.value,

        ...(action?.value && { value: action?.value })
      }))
    }).then((newRule) => {
      enqueueSnackbar('Email rule created successfully', { variant: 'success' });
      handleModalResolve(newRule);
    }).catch(() => {
      enqueueSnackbar('Email rule not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={initialRule?.config_id ? getRuleInitialValues({ initialRule, accountsOptions }) : {
        config_id: accountsOptions[0],
        name: null,
        conditions: [ {
          key: conditionOptions[0],
          values: []
        } ],
        actions: !!initialRule?.actions?.length
          ? initialRule.actions
          : [ { key: actionOptions[0], value: null } ],
        is_active: 1,
        stop_processing: false
      }}
      validationSchema={validationSchema}
      onSubmit={handleRuleCreate}
    >
      {({ isSubmitting, values, errors, touched, handleSubmit }) => (
        <Dialog fullScreen={isTable} fullWidth maxWidth="lg" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiFileAlertOutline} /></SvgIcon>} onClose={handleModalReject}>
              Create Rule
            </ModalHeader>

            <ModalBody>
              <Box width={isTable ? '80%' : '50%'} ml={6.5} mb={4} pr={5.75}>
                <Select
                  formattedValue
                  name="config_id"
                  label="Choose email account"
                  options={accountsOptions}
                />
              </Box>

              <FormStep
                withoutTitle
                checked={(touched.name && !errors.name) || (initialRule && !errors.name)}
                step={1}
              >
                <Box width={isTable ? '90%' : '50%'} mb={4} pr={2.5}>
                  <TextField
                    required
                    name="name"
                    label="Name your rule"
                    placeholder="Enter rule name..."
                  />
                </Box>
              </FormStep>

              <FormStep
                checked={(touched.conditions && !errors.conditions) || (initialRule && !errors.conditions)}
                step={2}
              >
                <Typography variant="h4" gutterBottom>Add a condition</Typography>

                <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight={50}>
                  <BodyConditions />
                </Box>
              </FormStep>

              <FormStep
                withoutLine
                checked={(touched.actions && !errors.actions) || (initialRule && !errors.actions)}
                step={3}
              >
                <Typography variant="h4" gutterBottom>Add an action</Typography>

                <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight={50}>
                  <BodyActions />
                </Box>
              </FormStep>

              <Box width={isTable ? '80%' : '50%'} ml={6.5} mb={4}>
                <FormControlLabel
                  control={<Checkbox name="stop_processing" />}
                  label="Stop processing more rules"
                />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
