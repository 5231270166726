import { Fragment } from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Checkbox, TextField } from '../../../../../../../components/FormField';
import { symptomsTypesList, symptomsTypesMap, symptomChildrenMap } from './symptomsTypesMap';

export const CheckboxesContent = () => {
  const { values, setFieldValue } = useFormikContext();
  const form = values?.forms?.review_of_orthopedic_symptoms;

  const toggleCheckbox = (item, symptom) => (event) => {
    const checked = event.target.checked;

    if (symptom !== 'none' && values?.forms?.review_of_orthopedic_symptoms?.[item]?.none) {
      setFieldValue(`forms.review_of_orthopedic_symptoms.${item}.none`, false);
    }

    if (symptom === 'none' && checked) {
      const formValue = form?.[item];

      if (formValue) {
        Object.keys(formValue).map((key) => (
          setFieldValue(
            `forms.review_of_orthopedic_symptoms.${item}.${key}`,
            key === 'other' ? { value: false, text: null } : key === 'none')
        ));
      }
    }
  };

  return (
    symptomsTypesList.map((item, index) => (
      <Fragment key={index}>
        <Grid item md={3} xs={12}>
          <Box display="flex" alignItems="flex-start">
            <Box clone mr={1}>
              {symptomsTypesMap[item].icon}
            </Box>

            <Typography variant="h5">
              {symptomsTypesMap[item].name}
            </Typography>
          </Box>
        </Grid>

        <Grid item md={9} xs={12}>
          <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
            {Object.keys(symptomsTypesMap?.[item]?.children)?.map((symptom, index) => (
              <Box key={index} display="flex" alignItems="center">
                <FormControlLabel
                  label={
                    <Typography color="textSecondary">
                      {symptomChildrenMap[symptom]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      name={
                        `forms.review_of_orthopedic_symptoms.${item}.${symptom === 'other' ? 'other.value' : symptom}`
                      }
                      onChange={toggleCheckbox(item, symptom)}
                    />
                  }
                />

                {symptom === 'other' &&
                  <Box minWidth={250}>
                    <TextField
                      disabled={!form?.[item]?.other}
                      fullWidth
                      name={`forms.review_of_orthopedic_symptoms.${item}.other.text`}
                    />
                  </Box>
                }
              </Box>
            ))}
          </Box>
        </Grid>
      </Fragment>
    ))
  );
};
