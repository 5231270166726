import { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Dialog,
  Button,
  SvgIcon,
  Box,
  useTheme,
  useMediaQuery,
  IconButton
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Icon } from '@mdi/react';
import { mdiCalendarEdit } from '@mdi/js';
import * as claimsApi from '../../../api/claims';
import { useResizeObserver } from '../../../helpers/hooks';
import { SideBar } from '../../../app/Dashboard/BillingPage/ClaimsPage/SideBar';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../Modal';
import { VerticalDivider } from '../../VerticalDivider';
import { Loader } from '../../Loader';
import {
  ClaimForm,
  transformClaimForForm,
  transformClaimReq,
  validationSchema
} from '../ClaimForm';
import { claimsStatusesMap } from '../claimsStatuses';

export const EditClaimModal = ({
  payload: { id = null },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [ claim, setClaim ] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState(isDesktop);
  const rootRef = useRef(null);
  const { width = window.innerWidth } = useResizeObserver({ ref: rootRef });

  const updateClaim = (values, { isSubmitting, setErrors, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    if (!values.appointment?.procedures?.length) {
      enqueueSnackbar('The claim must have an appointment with procedures', {
        variant: 'warning'
      });

      setSubmitting(false);

      return;
    }

    if (values.status === claimsStatusesMap.void && claim.status === claimsStatusesMap.void) {
      enqueueSnackbar('Change status from "Void" to another for the update claim', {
        variant: 'warning'
      });

      setSubmitting(false);

      return;
    }

    return claimsApi.updateClaim(transformClaimReq(values)).then((data) => {
      enqueueSnackbar('Claim successfully updated', {
        variant: 'success'
      });

      handleModalResolve(claim);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Please, check the form', { variant: 'error' });
      }
    });
  };

  useEffect(() => {
    claimsApi.fetchClaim(id).then(setClaim).catch(handleModalReject);
  }, [ id ]);

  const handleDrawerOpen = () => {
    setSidebarIsOpen((isOpen) => !isOpen);
  };

  return (
    <Dialog
      // Need it to fix focus at /ClaimForm/AppointmentSection/Appointment/CorrectedTotal.js
      disableEnforceFocus
      fullScreen={isMobile}
      maxWidth="lg"

      {...DialogProps}
    >
      <Loader p={2} loading={!claim} render={
        () => (
          <Formik
            initialValues={transformClaimForForm(claim) || {}}
            validationSchema={validationSchema}
            onSubmit={updateClaim}
          >
            {({ isSubmitting, values, handleSubmit }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarEdit} /></SvgIcon>} onClose={handleModalReject}>
                  Edit claim
                </ModalHeader>

                <ModalBody disablePaddings>
                  <Box ref={rootRef} display="flex" height="100%">
                    <Box display="flex">
                      <SideBar width={width} claim={values} sidebarIsOpen={sidebarIsOpen} />
                    </Box>

                    <VerticalDivider />

                    <Box p={2} pt={isMobile ? 0 : 2}>
                      {isMobile && (
                        <IconButton
                          edge="start"
                          color="primary"
                          title={sidebarIsOpen ? 'Hide sidebar' : 'Show sidebar'}
                          onClick={handleDrawerOpen}
                        >
                          {sidebarIsOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                        </IconButton>
                      )}

                      <ClaimForm />
                    </Box>
                  </Box>
                </ModalBody>

                <ModalFooter>
                  <Button disabled={isSubmitting} onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader
                    surface
                    loading={isSubmitting}
                    render={() => (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
