import { billingActivityMap } from './billingActivityMap';
import { commentsActionsMap } from './commentsActivityMap';
import { eventActivityMap } from './eventActivityMap';

export const activitySessionTypesMap = {
  workSession: 'work_session',
  workClock: 'work_clock',
  workClockClockIn: 'work_clock_clock_in',
  workClockClockOut: 'work_clock_clock_out',
  workClockReport: 'work_clock_report',
  sipCallAttachParent: 'sip_call_attach_parent',
  sipCallDetachParent: 'sip_call_detach_parent',
  addCaseReminderGroupToCase: 'add_case_reminder_group_to_case',
  removeCaseReminderGroupFromCase: 'remove_case_reminder_group_from_case',
  makeCaseReminderAsActive: 'make_case_reminder_as_active',
  makeCaseReminderAsInactive: 'make_case_reminder_as_inactive',
  makeCaseReminderAsDone: 'mark_case_reminder_as_done',
  makeCaseReminderAsNotDone: 'mark_case_reminder_as_not_done',
  insuranceAddedToCase: 'insurance_added_to_case',
  insuranceRemovedFromCase: 'insurance_removed_from_case'
};

export const activityTypesMap = {
  appointment: 'appointment',
  scheduleEvent: 'schedule_event',
  file: 'file',
  task: 'task',
  comment: 'comment',
  todoItem: 'todo_item',
  caseUser: 'case_user',
  case: 'case',
  sharedMedia: 'shared_media',
  claim: 'claim',
  claimInsuranceInformation: 'claim_insurance_information',
  invoice: 'invoice',
  income: 'income',
  expense: 'expense',
  payment: 'payment',
  scheduleRecurringTemplate: 'schedule_recurring_template',
  userEmailMessage: 'user_email_message',
  caseDeposition: 'case_deposition',
  caseDiscovery: 'case_discovery',
  caseNegotiat: 'case_negotiat',
  profile: 'profile',
  timeTrack: 'time_track'
};

export const activityActionMap = {
  updateTask: 'UPDATED@TASK',
  createTask: 'CREATED@TASK',
  deleteTask: 'DELETED@TASK',
  createCase: 'CREATED@CASE',
  updateCase: 'UPDATED@CASE',
  createCaseUser: 'CREATED@CASE:CASE_USER',
  deleteCaseUser: 'DELETED@CASE:CASE_USER',
  createCaseTask: 'CREATED@CASE:TASK',
  updateCaseTask: 'UPDATED@CASE:TASK',
  deleteCaseTask: 'DELETED@CASE:TASK',
  createCaseFile: 'CREATED@CASE:FILE',
  deleteCaseFile: 'DELETED@CASE:FILE',
  createTaskFile: 'CREATED@TASK:FILE',
  deleteTaskFile: 'DELETED@TASK:FILE',
  createApptFile: 'CREATED@APPOINTMENT:FILE',
  deleteApptFile: 'DELETED@APPOINTMENT:FILE',
  createTaskTodoItem: 'CREATED@TASK:TODO_ITEM',
  updateTaskTodoItem: 'UPDATED@TASK:TODO_ITEM',
  deleteTaskTodoItem: 'DELETED@TASK:TODO_ITEM',
  createDeposition: 'CREATED@CASE:CASE_DEPOSITION',
  updateDeposition: 'UPDATED@CASE:CASE_DEPOSITION',
  deleteDeposition: 'DELETED@CASE:CASE_DEPOSITION',
  createDiscovery: 'CREATED@CASE:CASE_DISCOVERY',
  updateDiscovery: 'UPDATED@CASE:CASE_DISCOVERY',
  deleteDiscovery: 'DELETED@CASE:CASE_DISCOVERY',
  createNegotiation: 'CREATED@CASE:CASE_NEGOTIAT',
  updateNegotiation: 'UPDATED@CASE:CASE_NEGOTIAT',
  deleteNegotiation: 'DELETED@CASE:CASE_NEGOTIAT',
  createEmail: 'CREATED@CASE:USER_EMAIL_MASSAGE',
  createCaseClaim: 'CREATED@CASE:CLAIM',
  updateCaseClaim: 'UPDATED@CASE:CLAIM',
  deleteCaseClaim: 'DELETED@CASE:CLAIM',
  createProfileFile: 'CREATED@PROFILE:FILE',
  deleteProfileFile: 'DELETED@PROFILE:FILE',
  createSharedFile: 'CREATED@FILE:SHARED_FILE',
  deleteSharedFile: 'DELETED@FILE:SHARED_FILE',
  createWorkSession: 'CREATED@WORK_SESSION',
  updateWorkSession: 'UPDATED@WORK_SESSION',
  deleteWorkSession: 'DELETED@WORK_SESSION',
  createWorkSessionWorkClocks: 'CREATED@WORK_SESSION:WORK_CLOCKS',
  updateWorkSessionWorkClocks: 'UPDATED@WORK_SESSION:WORK_CLOCKS',
  deleteWorkSessionWorkClocks: 'DELETED@WORK_SESSION:WORK_CLOCKS',
  createWorkClockReport: 'CREATED@WORK_CLOCK_REPORT',
  updateWorkClockReport: 'UPDATED@WORK_CLOCK_REPORT',
  deleteWorkClockReport: 'DELETED@WORK_CLOCK_REPORT',
  createTimeTrack: 'CREATED@TIME_TRACK',
  updateTimeTrack: 'UPDATED@TIME_TRACK',
  deleteTimeTrack: 'DELETED@TIME_TRACK',
  createWorkClockClockIn: 'CREATED@WORK_SESSION:WORK_CLOCKS',
  updateWorkClockClockIn: 'UPDATED@WORK_SESSION:WORK_CLOCKS',
  deleteWorkClockClockIn: 'DELETED@WORK_SESSION:WORK_CLOCKS',
  createWorkClockClockOut: 'CREATED@WORK_SESSION:WORK_CLOCKS',
  updateWorkClockClockOut: 'UPDATED@WORK_SESSION:WORK_CLOCKS',
  deleteWorkClockClockOut: 'DELETED@WORK_SESSION:WORK_CLOCKS',
  updateUserSipCallReport: 'UPDATED@USER:SIP_CALL_REPORT',
  updateCaseSipCallReport: 'UPDATED@CASE:SIP_CALL_REPORT',
  updateCaseReminderGroup: 'UPDATED@CASE:CASE_REMINDER_GROUP',
  updateCaseReminder: 'UPDATED@CASE:CASE_REMINDER',

  // TODO: this is new keys from BE and now we need here to add all new log_name
  create: 'CREATED@',
  update: 'UPDATED@',
  delete: 'DELETED@',

  // TODO: new log_name fields add here for actionTypesMap only if we have type CREATED@ | UPDATED@ | DELETED@
  expense: 'expense',
  claim: 'claim',
  task: 'task',
  appointment: 'appointment',
  case: 'case',
  caseDeposition: 'case_deposition',
  caseDiscovery: 'case_discovery',
  caseNegotiat: 'case_negotiat',

  comment: 'comment', // add activity new

  default: 'default',
  income: 'income',
  invoice: 'invoice',
  payment: 'payment',
  profile: 'profile',
  scheduleEvent: 'schedule_event',
  scheduleRecurringTemplate: 'schedule_recurring_template',
  sharedMedia: 'shared_media',
  userEmailMessage: 'user_email_message',
  todoItem: 'todo_item',
  event: 'event',
  user: 'user',
  deleteUser: 'delete_user',
  deposition: 'deposition',
  discovery: 'discovery',
  negotiation: 'negotiation',
  email: 'email',
  caseClaim: 'case_claim'
};

export const subjectLabels = {
  [activityTypesMap.appointment]: 'Appointment',
  [activityTypesMap.scheduleEvent]: 'Schedule Event',
  [activityTypesMap.file]: 'File',
  [activityTypesMap.task]: 'Task',
  [activityTypesMap.comment]: 'Comment',
  [activityTypesMap.todoItem]: 'Todo Item',
  [activityTypesMap.caseUser]: 'Case User',
  [activityTypesMap.case]: 'Case',
  [activityTypesMap.sharedMedia]: 'Shared Media',
  [activityTypesMap.claim]: 'Claim',
  [activityTypesMap.claimInsuranceInformation]: 'Claim Insurance Information',
  [activityTypesMap.invoice]: 'Invoice',
  [activityTypesMap.income]: 'Income',
  [activityTypesMap.expense]: 'Expense',
  [activityTypesMap.payment]: 'Payment',
  [activityTypesMap.scheduleRecurringTemplate]: 'Schedule Recurring Template',
  [activityTypesMap.userEmailMessage]: 'User Email Message',
  [activityTypesMap.caseDeposition]: 'Case Deposition',
  [activityTypesMap.caseDiscovery]: 'Case Discovery',
  [activityTypesMap.caseNegotiat]: 'Case Negotiat',
  [activitySessionTypesMap.workSession]: 'Work Session',
  [activitySessionTypesMap.workClock]: 'Work Clock',
  [activitySessionTypesMap.workClockClockIn]: 'Work Clock ClockIn',
  [activitySessionTypesMap.workClockClockOut]: 'Work Clock ClockOut',
  [activitySessionTypesMap.workClockReport]: 'Work Clock Report',
  [activityTypesMap.timeTrack]: 'Time Track',
  [activityTypesMap.profile]: 'Profile',
  [activitySessionTypesMap.sipCallAttachParent]: 'Sip Call Attach Parent',
  [activitySessionTypesMap.sipCallDetachParent]: 'Sip Call Detach Parent',
  [activitySessionTypesMap.addCaseReminderGroupToCase]: 'Add Case Reminder Group To Case',
  [activitySessionTypesMap.removeCaseReminderGroupFromCase]: 'Remove Case Reminder Group From Case',
  [activitySessionTypesMap.makeCaseReminderAsActive]: 'Make Case Reminder As Active',
  [activitySessionTypesMap.makeCaseReminderAsInactive]: 'Make Case Reminder As Inactive',
  [activitySessionTypesMap.makeCaseReminderAsDone]: 'Make Case Reminder As Done',
  [activitySessionTypesMap.makeCaseReminderAsNotDone]: 'Make Case Reminder As Not Done',
  [activitySessionTypesMap.insuranceAddedToCase]: 'Insurance Added To Case',
  [activitySessionTypesMap.insuranceRemovedFromCase]: 'Insurance Removed From Case'
};

export const activityMap = {
  [activityActionMap.updateTask]: activityTypesMap.task,
  [activityActionMap.createTask]: activityTypesMap.task,
  [activityActionMap.createTaskFile]: activityTypesMap.task,
  [activityActionMap.createTaskTodoItem]: activityTypesMap.task,
  [activityActionMap.updateTaskTodoItem]: activityTypesMap.task,
  [activityActionMap.deleteTaskTodoItem]: activityTypesMap.task,
  [activityActionMap.createCase]: activityTypesMap.case,
  [activityActionMap.updateCase]: activityTypesMap.case,
  [activityActionMap.createCaseUser]: activityActionMap.user,
  [activityActionMap.deleteCaseUser]: activityActionMap.deleteUser,
  [activityActionMap.createCaseFile]: activityTypesMap.case,
  [activityActionMap.deleteCaseFile]: activityTypesMap.case,
  [eventActivityMap.createScheduleEvent]: activityActionMap.event,
  [eventActivityMap.updateScheduleEvent]: activityActionMap.event,
  [eventActivityMap.createTaskScheduleEvent]: activityTypesMap.appointment,
  [eventActivityMap.updateTaskScheduleEvent]: activityTypesMap.appointment,
  [eventActivityMap.deleteTaskScheduleEvent]: activityTypesMap.appointment,
  [eventActivityMap.createCaseScheduleEvent]: activityTypesMap.appointment,
  [eventActivityMap.updateCaseScheduleEvent]: activityTypesMap.appointment,
  [eventActivityMap.deleteCaseScheduleEvent]: activityTypesMap.appointment,
  [eventActivityMap.createCaseRecurringScheduleEvent]: activityActionMap.event,
  [eventActivityMap.updateCaseRecurringScheduleEvent]: activityActionMap.event,
  [eventActivityMap.deleteCaseRecurringScheduleEvent]: activityActionMap.event,
  [eventActivityMap.createTaskRecurringScheduleEvent]: activityActionMap.event,
  [eventActivityMap.updateTaskRecurringScheduleEvent]: activityActionMap.event,
  [eventActivityMap.deleteTaskRecurringScheduleEvent]: activityActionMap.event,
  [eventActivityMap.createRecurringScheduleEvent]: activityActionMap.event,
  [eventActivityMap.deleteScheduleEvent]: activityActionMap.event,
  [commentsActionsMap.createComment]: activityTypesMap.comment,
  [commentsActionsMap.updateComment]: activityTypesMap.comment,
  [commentsActionsMap.deleteComment]: activityTypesMap.comment,
  [commentsActionsMap.restoreComment]: activityTypesMap.comment,
  [billingActivityMap.createInvoice]: activityTypesMap.invoice,
  [billingActivityMap.updateInvoice]: activityTypesMap.invoice,
  [billingActivityMap.createIncome]: activityTypesMap.invoice,
  [billingActivityMap.updateIncome]: activityTypesMap.invoice,
  [billingActivityMap.deleteIncome]: activityTypesMap.invoice,
  [billingActivityMap.createExpense]: activityTypesMap.invoice,
  [billingActivityMap.updateExpense]: activityTypesMap.invoice,
  [billingActivityMap.deleteExpense]: activityTypesMap.invoice,
  [billingActivityMap.deleteInvoice]: activityTypesMap.invoice,
  [activityActionMap.createDeposition]: activityActionMap.deposition,
  [activityActionMap.updateDeposition]: activityActionMap.deposition,
  [activityActionMap.deleteDeposition]: activityActionMap.deposition,
  [activityActionMap.createDiscovery]: activityActionMap.discovery,
  [activityActionMap.updateDeposition]: activityActionMap.discovery,
  [activityActionMap.deleteDiscovery]: activityActionMap.discovery,
  [activityActionMap.createNegotiation]: activityActionMap.negotiation,
  [activityActionMap.updateNegotiation]: activityActionMap.negotiation,
  [activityActionMap.deleteNegotiation]: activityActionMap.negotiation,
  [activityActionMap.createEmail]: activityActionMap.email,
  [eventActivityMap.createAppointment]: activityTypesMap.appointment,
  [eventActivityMap.updateAppointment]: activityTypesMap.appointment,
  [eventActivityMap.deleteAppointment]: activityTypesMap.appointment,
  [activityActionMap.create]: activityActionMap.create,
  [activityActionMap.update]: activityActionMap.create,
  [activityActionMap.delete]: activityActionMap.delete,
  [activityActionMap.expense]: activityTypesMap.expense,
  [activityActionMap.claim]: activityTypesMap.claim,
  [activityActionMap.updateCaseClaim]: activityActionMap.caseClaim,
  [activityActionMap.deleteTaskFile]: activityTypesMap.task,
  [activityActionMap.deleteApptFile]: activityTypesMap.appointment
};
