import { useState, useRef, useEffect, useCallback } from 'react';
import { fabric } from 'fabric';
import { makeStyles, IconButton } from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import ClearIcon from '@material-ui/icons/Clear';
import { generateUID } from '../../helpers/generateUID';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SimpleDrawing = ({ height = 260, width, onInit = () => {}, onChange = () => {} }) => {
  const classes = useStyles();
  const [ canvasId ] = useState(generateUID());
  const [ history, setHistory ] = useState([]);
  const [ isRedoing, setIsRedoing ] = useState(false);
  const rootRef = useRef();
  const fabricCanvasRef = useRef();

  useEffect(() => {
    fabricCanvasRef.current = new fabric.Canvas(canvasId, {
      width: width || rootRef.current.clientWidth,
      height,
      isDrawingMode: true
    });

    fabricCanvasRef.current.on('object:added', handleAddObjectToCanvas);
    fabricCanvasRef.current.on('object:removed', handleRemoveObjectFromCanvas);

    onInit(fabricCanvasRef);

    return () => {
      fabricCanvasRef.current.dispose();
    };
  }, []);

  const handleAddObjectToCanvas = useCallback(() => {
    if (!isRedoing) {
      setHistory([]);
    }

    setIsRedoing(false);

    onChange(fabricCanvasRef.current.toDataURL({
      multiplier: 2
    }));
  }, [ isRedoing ]);

  const handleRemoveObjectFromCanvas = useCallback(() => {
    onChange(fabricCanvasRef.current._objects.length ? fabricCanvasRef.current.toDataURL({ multiplier: 2 }) : null);
  }, []);

  const undo = () => {
    if (fabricCanvasRef.current._objects.length > 0) {
      const object = fabricCanvasRef.current._objects[fabricCanvasRef.current._objects.length - 1];

      fabricCanvasRef.current.remove(object);

      setHistory((history) => history.concat(object));
    }
  };

  const redo = () => {
    if (history.length > 0) {
      fabricCanvasRef.current.add(history.pop());

      setIsRedoing(true);
      setHistory(history);
    }
  };

  const clear = () => {
    setHistory((history) => history.concat(fabricCanvasRef.current._objects));

    fabricCanvasRef.current.clear();
  };

  return (
    <div ref={rootRef} className={classes.root}>
      {!!fabricCanvasRef.current &&
      <div className={classes.actions}>
        <IconButton
          disabled={!fabricCanvasRef.current._objects.length}
          onClick={undo}
          title="Undo"
        >
          <UndoIcon fontSize="small" />
        </IconButton>

        <IconButton
          disabled={!history.length}
          onClick={redo}
          title="Redo"
        >
          <RedoIcon fontSize="small" />
        </IconButton>

        <IconButton
          disabled={!fabricCanvasRef.current._objects.length}
          onClick={clear}
          title="Clear"
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </div>
      }

      <div className={classes.canvasWrapper}>
        <canvas id={canvasId} height={height} />
      </div>
    </div>
  );
};
