export const styles = ({ palette }) => ({
  avatar: {
    color: palette.grey[500]
  },

  favoriteIcon: {
    color: palette.warning.main,
    stroke: palette.warning.contrastText
  }
});
