import { useEffect } from 'react';
import moment from 'moment';
import { useMediaQuery, useTheme } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../helpers/dom';
import { ReadonlyCheckbox } from '../../../../ReadonlyCheckbox';
import { AuthorizationViewModal } from '../../../AuthorizationViewModal';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { useModal } from '../../../../ModalsProvider';
import { IconButton } from '../../../../IconButton';
import { Loader } from '../../../../Loader';
import { Popper } from '../../../../Popper';
import { Label } from '../../../../Label';
import { getExpirationDateColor } from '../../utils';
import { columnsLargeWidths, columnsMobileWidths } from '../List';
import { Menu } from './Menu';

export const Row = ({
  item: authorization = {},
  isLoaded,
  recalculateHeight
}) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const columnsWidths = isMobile ? columnsMobileWidths : columnsLargeWidths;

  const openPreview = () => {
    openModal(AuthorizationViewModal, {
      payload: {
        authorization
      }
    });
  };

  useEffect(() => {
    if (isLoaded) {
      recalculateHeight();
    }
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button px={2} onClick={stopPropagation(openPreview)}>
      <ListRowCell grow flexBasis={columnsWidths.authorization}>
        {authorization.authorization}
      </ListRowCell>

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.effectiveDate}>
          {authorization.effective_date ? moment.unix(authorization.effective_date).format('L') : '-'}
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.expirationDate}>
        {!authorization.expiration_date ? '-' : (
          <Label
            label={moment.unix(authorization.expiration_date).format('L')}
            color={
              getExpirationDateColor(authorization.effective_date, authorization.expiration_date)
            }
          />
        )}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.isPending}>
        <ReadonlyCheckbox checked={authorization.is_pending}  />
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <IconButton
              buttonRef={anchorRef}
              edge="end"
              onClick={stopPropagation(handleToggle)}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        >
          {({ handleClose }) => (
            <Menu authorization={authorization} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
