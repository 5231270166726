import { api } from '../api';

export const fetchWorkSessions = (config = {}) => {
  return api.get('/work-sessions', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const exportWorkSessions = (config = {}) => {
  return api.get('/work-sessions-export', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const createWorkSession = (data) => {
  return api.post('/work-sessions', data).then(({ data: { data } }) => data);
};

export const fetchWorkSession = (id) => {
  return api.get(`/work-sessions/${id}`).then(({ data }) => data);
};

export const stopWorkSession = (id) => {
  return api.put(`/work-sessions/${id}/stop`).then(({ data }) => data);
};

export const updateWorkSession = ({ id, ...data }) => {
  return api.put(`/work-sessions/${id}`, data).then(({ data: { data } }) => data);
};

export const deleteWorkSession = (id) => {
  return api.delete(`/work-sessions/${id}`).then(({ data }) => data);
};

export const massDeleteWorkSessions = (IDs) => {
  return api.delete('/work-sessions', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
