import { debounce } from 'lodash';
import { useContext, useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { CasesSelect } from '../../../../CasesSelect';
import {
  ReminderEventTypesSelect
} from '../../../events/ReminderEventTypesSelect';
import { FiltersBar as FiltersBarComponent } from '../../../../../FiltersBar';
import { KeyboardDatePicker, TextField } from '../../../../../FormField';
import { CaseRemindersFilterContext } from '../../CaseRemindersFiltersProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FiltersBar = (props) => {
  const classes = useStyles();
  const formikRef = useRef();
  const { caseId, filter, applyFilter } = useContext(CaseRemindersFilterContext);

  const handleSearchChange = debounce((event) => {
    const value = event.target.value;

    applyFilter({ search: value });
  }, 600);

  const handleEventTypeChange = (type) => {
    applyFilter({ type: type?.value || null });
  };

  const handleDateChange = (date) => {
    applyFilter({ date_from: date });
  };

  const handleCaseChange = (caseItem) => {
    applyFilter({ case_id: caseItem?.id || null });
  };

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      initialValues={filter}
      ToolbarProps={{ className: classes.toolbar }}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.search,
          label: filterFieldsLabels[filterFieldsMap.search],
          field: (
            <TextField
              name="search"
              type="search"
              label="Search"
              placeholder="Search..."
              onChange={handleSearchChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.type,
          label: filterFieldsLabels[filterFieldsMap.type],
          field: (
            <ReminderEventTypesSelect
              name="type"
              label="Type"
              placeholder="Search..."
              onChange={handleEventTypeChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.dateFrom,
          label: filterFieldsLabels[filterFieldsMap.dateFrom],
          field: (
            <KeyboardDatePicker
              clearable
              name="date_from"
              label="Date From"
              outputFormat="YYYY-MM-DD"
              onChange={handleDateChange}
            />
          )
        },
        !caseId && {
          fieldKey: filterFieldsMap.caseItem,
          label: filterFieldsLabels[filterFieldsMap.caseItem],
          field: (
            <Box minWidth={240}>
              <CasesSelect
                name="case_id"
                label="Case"
                onChange={handleCaseChange}
              />
            </Box>
          )
        }
      ]}

      {...props}
    />
  );
};
