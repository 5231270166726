import { useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Loader } from '../../../../../components/Loader';
import { MedicalFormItemContext } from '../MedicalFormItemProvider';
import { formComponentsByTypes } from '../MedicalForms/formComponentsByTypes';

export const FormSteps = () => {
  const params = useParams();
  const location = useLocation();
  const { type, isFetched, fetchForm } = useContext(MedicalFormItemContext);
  const Component = formComponentsByTypes[type];

  useEffect(() => {
    fetchForm({ id: params.medicalFormID || null });
  }, [ location.pathname ]);

  return (
    <Loader p={3} loading={!isFetched && !type} render={() => <Component />} />
  );
};
