import { useState } from 'react';
import { fade } from '@material-ui/core/styles';
import { Icon } from '@mdi/react';
import {
  makeStyles,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { SvgIcon } from '../../../../../../../../../../components/SvgIcon';
import { TableHeader } from './TableHeader';
import { Card } from './Card';
import { Row } from './Row';

const useStyles = makeStyles(({ palette }) => ({
  header: {
    backgroundColor: fade(palette.primary.light, 0.2),
    color: palette.info.main
  }
}));

export const columnsWidths = {
  updated_at: 100,
  certificationRevision: 100,
  lastCertification: 100,
  beginTherapy: 100
};

export const ServiceLines = ({ appointment, serviceLines }) => {
  const classes = useStyles();
  const [ isCollapsed, setIsCollapsed ] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleCollapsed = () => {
    setIsCollapsed((state) => !state);
  };

  return (
    <Box border={1} borderColor="divider">
      <List component="div" disablePadding className={classes.header}>
        <ListItem button onClick={toggleCollapsed}>
          <ListItemIcon>
            <SvgIcon color="info">
              <Icon path={mdiTextBoxCheckOutline} />
            </SvgIcon>
          </ListItemIcon>

          <Box display="flex" alignItems="center" mr="auto">
            <Box mr={2}>
              <Typography variant="h4" color="inherit">
                Service Lines
              </Typography>
            </Box>
          </Box>

          {isCollapsed ? <ExpandMoreIcon color="inherit" /> : <ExpandLessIcon color="inherit" />}
        </ListItem>
      </List>

      <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
        {!serviceLines?.length ? (
          <Box p={2}>
            <Typography>No service lines</Typography>
          </Box>
        ) : (
          <>
            {!isMobile && <TableHeader />}

            {serviceLines?.map((serviceLine) => (
              isMobile
                ? <Card key={serviceLine.id} authorization={appointment.authorization} serviceLine={serviceLine} />
                : <Row key={serviceLine.id} authorization={appointment.authorization} serviceLine={serviceLine} />
            ))}
          </>
        )}
      </Collapse>
    </Box>
  );
};
