import { api } from '../api';

export const fetchFeatureFlags = (config = {}) => {
  return api.get('/admin/feature_flags', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const fetchFeatureFlag = (id, config = {}) => {
  return api.get(`/admin/feature_flags/${id}`, config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const updateFeatureFlag = ({ id, ...data }) => {
  return api.patch(`/admin/feature_flags/${id}`, data).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};
