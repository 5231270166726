import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { UserLink } from '../../../../../../components/UserLink';
import { Label } from '../../Label';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params.search &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Search
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {params?.search}
            </Typography>
          </Grid>
        </>
      }

      {params.created_from &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Create From:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {moment.unix(params?.created_from).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params.created_to &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Create To:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {moment.unix(params?.created_to).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params.due_from &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Due From:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {moment.unix(params?.due_from).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params.due_to &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Due To:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {moment.unix(params?.due_to).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {!!params.cases?.length &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Cases:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.cases?.map((caseItem) => (
                <Typography noWrap key={caseItem?.id} title={caseItem?.name}>{caseItem?.name}</Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params.types?.length &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Types:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.types?.map((type) => (
                <Typography noWrap key={type?.id} title={type?.name}>{type?.name}</Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params.statuses?.length &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Statuses:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.statuses?.map((status) => (
                <Typography key={status?.id}>{status?.name}</Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params.users?.length &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Users:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Box display="flex" flexDirection="column">
              {params?.users?.map((user) => (
                <Box display="flex" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params.priorities?.length &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Priorities:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Box display="flex" flexDirection="column">
              {params?.priorities?.map((priority) => (
                <Box key={priority?.value} py={0.25}>
                  <Label name={priority?.label} color={priority?.value} />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }
    </Grid>
  );
};
