import { useContext } from 'react';
import { CardItem } from '../../../../../components/Cards';
import { InsuranceInfo, InsurancesContext, insuranceStatusTypesMap } from '../../../ProfilePage/insurance';
import {
  CaseInsuranceInfo
} from '../../../ProfilePage/MedicalInfo/MainInfo/Widgets/InsuranceDetails/CaseInsuranceInfo';

export const Card = ({ item: insurance = {} }) => {
  const { selectedIDs } = useContext(InsurancesContext);
  const isSelected = selectedIDs.indexOf(insurance.id) !== -1;

  return (
    <CardItem py={1} px={2} selected={isSelected}>
      {insurance?.status_type === insuranceStatusTypesMap.case_insurance
        ? <CaseInsuranceInfo withMenu insurance={insurance} />
        : <InsuranceInfo withMenu showMainCheck insurance={insurance} />
      }
    </CardItem>
  );
};
