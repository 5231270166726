import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Dialog, Button, IconButton, InputAdornment } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { ProfilesSelect } from '../../../../../components/users/ProfilesSelect';
import { getFileNameWithoutExtension } from '../../../../../helpers/files';
import * as faxesApi from '../../../../../api/sip/fax';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { TextField, Select, TasksSelect } from '../../../../../components/FormField';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { filesOwnersTypesMap, filesOwnersTypesOptions } from '../../../files-common/filesOwnersTypesOptions';
import { validationSchema } from './validationSchema';

export const CopyFileModal = ({
  DialogProps,
  payload: {
    mutiple = false,
    file,
    medias
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const initialName = getFileNameWithoutExtension(file?.original_filename);

  const resetFileName = (setFieldValue) => () => {
    setFieldValue('original_filename', initialName);
  };

  const handleOwnerTypeChange = (setFieldValue) => () => {
    setFieldValue('owner_id', null);
  };

  const copyFiles = ({ owner_type, owner_id }, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const copiedFiles = medias?.map((item) => ({
      media_id: item?.id,
      original_filename: moment().format('MM-DD-YYYY h-mm') + '-COPY-' + item?.original_filename
    }));

    const owner = owner_type !== filesOwnersTypesMap.user ? {
      owner_type: owner_type,
      owner_id: owner_id
    } : {};

    return faxesApi.multipleCopy({ medias: copiedFiles, ...owner }).then(() => {
      enqueueSnackbar('Files successfully copied', { variant: 'success' });
      handleModalResolve();
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        setErrors(errors);
      }

      enqueueSnackbar('Files not copied', { variant: 'error' });
    });
  };

  const copyFile = ({ original_filename, owner_type, owner_id }, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const copiedFile = {
      media_id: file?.id,
      original_filename: original_filename + (file?.extension ? '.' + file?.extension : '')
    };

    const owner = owner_type !== filesOwnersTypesMap.user ? {
      owner_type: owner_type,
      owner_id: owner_id
    } : {};

    return faxesApi.copyFile({ ...copiedFile, ...owner }).then(() => {
      enqueueSnackbar(`File "${copiedFile.original_filename}" successfully copied`, { variant: 'success' });
      handleModalResolve();
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 409) {
        setErrors({
          original_filename: 'File with same name is now exist in selected destination'
        });
      } else if (status === 422) {
        setErrors(errors);
      }

      enqueueSnackbar('File not copied', { variant: 'error' });
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          original_filename: !mutiple ? initialName : undefined,
          owner_type: filesOwnersTypesOptions[0]
        }}
        validationSchema={!mutiple ? validationSchema : undefined}
        onSubmit={mutiple ? copyFiles : copyFile}
      >
        {({ values: { original_filename, owner_type, owner_id }, handleSubmit, setFieldValue, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader icon={<FileCopyIcon />} onClose={handleModalReject}>
              Copy file
            </ModalHeader>

            <ModalBody>
              {!mutiple && (
                <TextField
                  name="original_filename"
                  label="File name"
                  placeholder="Enter file name..."
                  margin="dense"
                  InputProps={{
                    autoFocus: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />

                        <IconButton
                          title="Reset"
                          disabled={original_filename === initialName}
                          onClick={resetFileName(setFieldValue)}
                        >
                          <SettingsBackupRestoreIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}

              <Select
                formattedValue
                name="owner_type"
                isSearchable={false}
                label="File destination"
                options={filesOwnersTypesOptions}
                onChange={handleOwnerTypeChange(setFieldValue)}
                TextFieldProps={{ margin: 'dense' }}
              />

              {owner_type === filesOwnersTypesMap.profile &&
                <ProfilesSelect
                  name="owner_id"
                  label="Select user"
                  placeholder="Search user..."
                  margin="dense"
                />
              }

              {owner_type === filesOwnersTypesMap.case &&
                <CasesSelect
                  name="owner_id"
                  label="Select case"
                  placeholder="Search case by name..."
                  margin="dense"
                />
              }

              {owner_type === filesOwnersTypesMap.task &&
                <TasksSelect
                  name="owner_id"
                  label="Select task"
                  placeholder="Search task by name..."
                  margin="dense"
                />
              }
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || (owner_type !== filesOwnersTypesMap.user && !owner_id)}
                  >
                    Copy
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
