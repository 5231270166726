import { makeStyles, Grid, Paper, Box } from '@material-ui/core';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { BillingContent } from './BillingContent';
import { OfficesContent } from './OfficesContent';
import { MainContent } from './MainContent';
import { Header } from './Header';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainInfo = ({ company, setCompany }) => {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.root}>
      <Header company={company} setCompany={setCompany} />

      <Grid
        container
        spacing={2}
        component={Box}
        flexWrap={{ lg: 'nowrap !important' }}
        className={classes.content}
      >
        <Grid item lg={4} xs={12}>
          <MainContent company={company} />
        </Grid>

        <VerticalDivider />

        <Grid item lg={4} xs={12}>
          <BillingContent company={company} />
        </Grid>

        <VerticalDivider />

        <Grid item lg={4} xs={12}>
          <OfficesContent offices={company.offices || []} setCompany={setCompany} />
        </Grid>
      </Grid>
    </Paper>
  );
};
