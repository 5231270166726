import { LayoutContextProvider } from '../../../../components/LayoutContext';
import { ScheduleTypesProvider } from './ScheduleTypesProvider';
import { PageContent } from './PageContent';

export const ScheduleEventTypes = () => {
  return (
    <ScheduleTypesProvider>
      <LayoutContextProvider>
        <PageContent />
      </LayoutContextProvider>
    </ScheduleTypesProvider>
  );
};
