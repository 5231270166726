import { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import { stopPropagation } from '../../../../../../helpers/dom';
import { UserPreviewModal } from '../../../../../../components/users/UserPreviewModal';
import { AppointmentsContext, AppointmentViewModal } from '../../../../../../components/appointments';
import { CardItem, CardHeader, CardFooter } from '../../../../../../components/Cards';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { ConsultationsMenu } from '../../ConsultationsMenu';
import { Body } from './Body';

export const Card = ({ item: appt = {} }) => {
  const { openModal } = useModal();
  const { reloadAppointments } = useContext(AppointmentsContext);

  const openPreview = () => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: appt.id,
        onAppointmentUpdate: reloadAppointments,
        onAppointmentDelete: reloadAppointments
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <CardItem py={1} px={2} onClick={stopPropagation(openPreview)}>
      <CardHeader
        disableCheckBox
        menuComponent={ConsultationsMenu}
        menuComponentProps={{ appointment: appt }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary">Ins.ID</Typography>
          <Box component="span" ml={1}>
            <Typography variant="subtitle2">{appt?.patient_insurance?.insuranceId || '-'}</Typography>
          </Box>

          <Box component="span" ml={5} mr={1}>
            <Typography variant="body2" color="textSecondary">Ins.Type</Typography>
          </Box>

          <Typography variant="subtitle2">{appt?.patient_insurance?.type_insurance || '-'}</Typography>
        </Box>
      </CardHeader>

      <Body appt={appt} />

      <CardFooter
        pb={0.5}
        disableCommentsCount
        disableMediaCounts
      >
        <UserLink
          size="lg"
          variant="h5"
          childrenProps={{ display: 'flex', alignItems: 'flex-start', color: 'text.secondary' }}
          user={appt?.appointment_book?.provider}
          onClick={stopPropagation(openUserPreview(appt?.appointment_book?.provider?.id))}
        >
          <Box clone mr={0.5}>
            <RoomIcon fontSize="small" />
          </Box>

          <Typography noWrap variant="caption">
            {appt.office?.full_address || '-'}
          </Typography>
        </UserLink>
      </CardFooter>
    </CardItem>
  );
};
