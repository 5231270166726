import { List, ListItemIcon, ListItemText, MenuItem, Badge } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { CaseViewModal } from '../../../../app/Dashboard/CasesPage/Cases/CaseViewModal';
import { stopPropagation } from '../../../../helpers/dom';
import { getUserFullName } from '../../../../helpers/users';
import { IconButton } from '../../../IconButton';
import { useModal } from '../../../ModalsProvider';
import { UserAvatar, UserPreviewModal } from '../../../users';
import { Popper } from '../../../Popper';

export const CausersList = ({ caseCausers = [], userCausers = [] }) => {
  const { openModal } = useModal();
  const isCausers = caseCausers?.length > 1 || userCausers?.length > 1;
  const cases = caseCausers.slice(1, caseCausers.length);
  const users = userCausers.slice(1, userCausers.length);
  const badgeContent = `+${[ ...cases, ...users ]?.length}`;

  const openCasePreview = (item) => () => {
    openModal(CaseViewModal, {
      payload: { id: item.id }
    });
  };

  const openUserPreview = (user) => () => {
    openModal(UserPreviewModal, {
      payload: { id: user?.id }
    });
  };

  return isCausers && (
    <Popper
      placement="left-start"
      anchorRenderer={
        ({ anchorRef, handleToggle }) => (
          <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
            <Badge badgeContent={badgeContent} color="primary">
              <ExpandMoreIcon />
            </Badge>
          </IconButton>
        )
      }
    >
      {() => (
        <List>
          {cases?.map((item) => (
            <MenuItem key={item.id} onClick={openCasePreview(item)}>
              <ListItemIcon>
                <WorkIcon color="primary" />
              </ListItemIcon>

              <ListItemText primary={item?.name || item?.file_number} />
            </MenuItem>
          ))}

          {users?.map((user) => (
            <MenuItem key={user.id} onClick={openUserPreview(user)}>
              <ListItemIcon>
                <UserAvatar size="md" user={user} />
              </ListItemIcon>

              <ListItemText primary={getUserFullName(user)} />
            </MenuItem>
          ))}
        </List>
      )}
    </Popper>
  );
};
