import { Box, Paper } from '@material-ui/core';
import { Button } from '../../../Button';
import { Tags } from '../../../Tags';

export const TagsMenu = ({
  tagsType,
  selectedTags = [],
  onTagsChange = () => {},
  onChangeAnchorEl = () => {}
}) => {
  const onClose = () => {
    onChangeAnchorEl({});
  };

  return (
    <Paper
      component={Box}
      boxShadow={2}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      borderRadius={4}
      height={240}
      minWidth={300}
    >
      <Tags
        disableTabs
        withoutAppBar
        initialSelectedTags={selectedTags}
        tagsType={tagsType}
        onTagsChange={onTagsChange}
      />

      <Box ml="auto" p={1}>
        <Button variant="contained" color="primary" size="small" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Paper>
  );
};
