import { Box, Grid, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { IconButton } from '../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { OfficeLink } from '../../../../../../../components/OfficeLink';
import { Contact } from '../../../../../../../components/Contact';
import { EditOfficeModal } from '../../../../Offices/EditOfficesModal';

export const OfficesContent = ({ offices, setCompany }) => {
  const { openModal } = useModal();

  const editOffice = (id) => () => {
    openModal(EditOfficeModal, {
      payload: {
        id
      },

      onModalResolved: (updatedOffice) => {
        setCompany((company) => {
          return {
            ...company,

            offices: company.offices.map((office) => {
              return updatedOffice?.id === office.id ? updatedOffice : office;
            })
          };
        });
      }
    });
  };

  return (
    <Box pt={3} px={2}>
      <Box color="info.main">
        <Typography variant="button" component="h3" color="inherit">
          {offices.length} Offices
        </Typography>
      </Box>

      <Box pt={3} pb={2}>
        {offices.map((item, index) => (
          <Grid container spacing={2} component={Box} py={1} key={item.id}>
            {index === 0 ? (
              <Box ml={-1.75} mt={1} pr={0.75}>
                <ReadonlyCheckbox
                  checked
                  fullWidth
                  noWrap={false}
                  variant="h5"
                  position="flex-start"
                  title={
                    <Grid item>
                      <Typography variant="h5">{index + 1}.</Typography>
                    </Grid>
                  }
                />
              </Box>
            ) : (
              <Grid item>
                <Typography variant="h5">{index + 1}.</Typography>
              </Grid>
            )}

            <Grid item xs>
              <OfficeLink
                variant="h5"
                office={item}
              />

              <AddressLink variant="caption" address={item.full_address}>
                {item.full_address}
              </AddressLink>
            </Grid>

            <Grid item xs={5}>
              <Contact
                noWrap
                needIcon
                variant="h5"
                type="tel"
                contact={item.phone}
              />
            </Grid>

            <Grid item>
              <IconButton onClick={editOffice(item.id)}>
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};
