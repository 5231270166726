import { SvgIcon } from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { mdiCircleMedium, mdiCogs } from '@mdi/js';
import { Icon } from '@mdi/react';

export const tasks = {
  name: 'Tasks',
  icon: PlaylistAddCheckIcon,
  path: '/tasks',
  routes: [
    {
      exact: true,
      name: 'My Tasks',
      icon: FormatListBulletedIcon,
      path: '/tasks'
    },
    {
      name: 'Settings',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiCogs} />
        </SvgIcon>
      ),
      path: '/tasks/system-settings',
      routes: [
        {
          name: 'Task Types',
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          path: '/tasks/system-settings/types'
        },
        {
          name: 'Task Templates',
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          path: '/tasks/system-settings/task-templates/templates'
        },
        {
          name: 'Template Groups',
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          path: '/tasks/system-settings/task-templates/groups'
        }
      ]
    }
  ]
};
