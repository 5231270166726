import { SvgIcon } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import { mdiCalendarMonthOutline, mdiCircleMedium } from '@mdi/js';
import { Icon } from '@mdi/react';
import ProcedureCodesSvg from '../../../../../../components/icons/procedure-codes.svg';

export const codesMenu = [
  {
    name: 'Codes',
    icon: SettingsIcon,
    path: '/codes',
    routes: [
      {
        path: '/codes/procedure-codes',
        icon: (props) => (
          <SvgIcon {...props}>
            <ProcedureCodesSvg />
          </SvgIcon>
        ),
        name: 'Practice Codes',
        routes: [
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            name: 'CPT',
            path: '/codes/procedure-codes/cpt'
          },
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            name: 'ICD',
            path: '/codes/procedure-codes/icd'
          },
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            name: 'POS',
            path: '/codes/procedure-codes/pos'
          },
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            name: 'NDC code',
            path: '/codes/procedure-codes/ndc'
          },
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            name: 'Modifier',
            path: '/codes/procedure-codes/modifier'
          },
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            name: 'Body Part',
            path: '/codes/procedure-codes/body-part'
          }
        ]
      },
      {
        path: '/codes/appointments',
        icon: (props) => (
          <SvgIcon {...props}>
            <Icon path={mdiCalendarMonthOutline} />
          </SvgIcon>
        ),
        name: 'Appointment',
        routes: [
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            path: '/codes/appointments/arrival-statuses',
            name: 'Arrival statuses'
          },
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            exact: true,
            path: '/codes/appointments/appointment-books',
            name: 'Appointment books'
          },
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            exact: true,
            path: '/codes/appointments/appointment-types',
            name: 'Appointment types'
          },
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            exact: true,
            path: '/codes/appointments/visit-reasons',
            name: 'Visit reasons'
          }
        ]
      }
    ]
  }
];
