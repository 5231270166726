import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  company_id: Yup.mixed().nullable(),
  name: Yup.string().nullable().required().max(255),
  practice: Yup.string().nullable().max(255),
  country: Yup.string().nullable().min(2).max(255),
  state: Yup.string().nullable().min(2).max(255),
  city: Yup.string().nullable().min(2).max(255),
  zip: Yup.string().nullable().min(1).max(255),
  street: Yup.string().nullable().min(3).max(255),
  designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
    return unitNumber ? schema.required() : schema;
  }),
  unit_number: Yup.string().nullable().max(255),
  mobile_phone: Yup.string().nullable().min(3).max(25),
  phone: Yup.string().nullable().required().min(3).max(25),
  fax: Yup.string().nullable().min(3).max(25),
  email: Yup.string().email().nullable().max(255),
  timezone: Yup.mixed().nullable().required(),
  billing: Yup.object().shape({
    country: Yup.string().nullable().min(2).max(255),
    state: Yup.string().nullable().min(2).max(255),
    city: Yup.string().nullable().min(2).max(255),
    zip: Yup.string().nullable().min(1).max(255),
    street: Yup.string().nullable().min(3).max(255),
    designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
      return unitNumber ? schema.required() : schema;
    }),
    unit_number: Yup.string().nullable().max(255),
    phone: Yup.string().nullable().min(3).max(25),
    mobile_phone: Yup.string().nullable().min(3).max(25),
    fax: Yup.string().nullable().min(3).max(25),
    email: Yup.string().email().nullable().max(255)
  }),
  venue: Yup.array().of(Yup.object().shape({
    country: Yup.string().nullable().min(2).max(255),
    state: Yup.string().nullable().min(2).max(255),
    city: Yup.string().nullable().min(2).max(255),
    zip: Yup.string().nullable().min(1).max(255),
    street: Yup.string().nullable().min(3).max(255),
    designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
      return unitNumber ? schema.required() : schema;
    }),
    unit_number: Yup.string().nullable().max(255),
    is_main: Yup.mixed().nullable()
  })),
  users: Yup.array().of(Yup.object()),
  notes: Yup.string().nullable().min(1).max(5000),
  medical_data: Yup.object().nullable().when('is_medical', (isMedical, schema) => {
    return isMedical
      ? schema.shape({
        pos_id: Yup.mixed().nullable(),
        npi: Yup.string().nullable()
      }) : schema;
  })
});
