import { gendersMap } from '../../../../../components/users';
import { rolesMap } from '../../../../../dataMaps';
import {
  caseInsuranceTypesOptions,
  insuranceTypesOptions,
  statusTypeCaseOptions,
  subscriberOptions,
  subscribersMap
} from './insuranceDataMaps';

export const getInitialValues = ({
  medicalFormID,
  medical_id,
  user,
  insuranceInfo,
  type_insurance,
  status_type,
  caseItem
}) => {
  const address = (
    insuranceInfo.relationship_to_patient && insuranceInfo.relationship_to_patient !== subscribersMap.self
      ? {
        street: null,
        state: null,
        city: null,
        country: null,

        ...user?.home
      } : insuranceInfo?.user_address || insuranceInfo?.address || user?.home
  );
  const caseUser = caseItem?.case_users?.find((user) => user.case_role === rolesMap.applicant);

  return {
    case_id: caseItem?.id || null,
    caseItem,
    medical_id: medical_id || user?.medical?.id || null,
    is_icd: true,
    status_type: status_type
      ? statusTypeCaseOptions.find(({ value }) => value === status_type).value
      : (!!caseItem?.id
        ? statusTypeCaseOptions.find(({ value }) => value === 'case_insurance')?.value
        : statusTypeCaseOptions[0].value
      ),
    financial_class_id: null,
    insurance_company_id: insuranceInfo?.insurance_company_id || null,
    office_id: null,
    attorney_id: null,
    tpl_id: null,
    provider_id: null,
    spc: null,
    pcp: null,
    type_insurance: status_type === 'law_office'
      ? insuranceTypesOptions.find(({ value }) => value === type_insurance)?.value
      : !caseItem?.id
        ? insuranceTypesOptions[1].value
        : caseInsuranceTypesOptions[0].value,
    insuranceId: insuranceInfo?.primary_insurance_id_number || null,
    groupId: insuranceInfo?.group_id_number || null,
    effective_day: null,
    expiration_day: null,
    subscriber_info: !caseItem?.id ? {
      address_same_as_patient: false,
      phone_number: user?.phone_number || user?.mobile_phone_number || null,
      subscriber:
        subscriberOptions?.find(({ value }) => (value === insuranceInfo?.relationship_to_patient))?.value
        || subscriberOptions[0].value,
      first_name: user?.profile?.first_name || user?.first_name
        || insuranceInfo?.first_name || null,
      last_name: user?.profile?.last_name || user?.last_name
        || insuranceInfo?.last_name || null,
      dob: user?.profile?.birthday || user?.birthday ||
        insuranceInfo?.dob || null,
      gender: gendersMap.find(({ value }) => value === user?.gender) || null,
      address
    } : {},
    ...insuranceInfo,
    attorney_first_name: insuranceInfo?.attorney_first_name || null,
    attorney_last_name: insuranceInfo?.attorney_last_name || null,
    attorney_email: insuranceInfo?.attorney_email || null,
    attorney_phone: insuranceInfo?.attorney_phone || null,
    medicalFormID,
    case_user_id: caseUser || user || null,
    phone_number: null,
    provider_adj_id: null,
    attorney_adj_id: null,
    policy_number: null,
    claim_number: null,
    insured: null,
    prop_two_one_three: false,
    vehicle: {
      license_number: null,
      year: null,
      model: null,
      color: null
    },
    liability_limit: null,
    um_uim: null,
    medpay: null,
    medpay_requested: false,
    medpay_received_date: null,
    deductible: null,
    rental: null,
    tow: null,
    driver: null,
    refused_dislosure: false,
    requested_limit_date: null,
    policy_limit: null,
    umbrella_insurance: null,
    dl_number: null
  };
};
