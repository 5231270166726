import { useContext, useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';
import { useResizeObserver } from '../../../helpers/hooks';
import { LayoutContext, viewVariantsMap } from '../../LayoutContext';
import { FiltersBar } from './FiltersBar';
import { List } from './List';
import { Grid } from './Grid';

export const largeScreenWidth = 1250;
export const smallScreenWidth = 1080;

export const WorkSessions = ({
  filterKey,
  hiddenColumns,
  hiddenFilterFields,
  FilterBarWrapper = () => {},
  filterBarWrapperProps = {},
  ScrollWrapper = () => {},
  scrollWrapperProps = {}
}) => {
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });

  useEffect(() => {
    if (width < smallScreenWidth) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width ]);

  return (
    <Box ref={rootRef} display="flex" flexDirection="column" flexGrow={1} width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgcolor="grey.50"
      >
        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <FiltersBar filterKey={filterKey} hiddenFields={hiddenFilterFields} />
          </FilterBarWrapper>
        </ScrollWrapper>
      </Box>

      <Box flexGrow={1} bgcolor="white">
        {(viewVariant === viewVariantsMap.list) && <List hiddenColumns={hiddenColumns} width={width} />}
        {(viewVariant === viewVariantsMap.grid) && <Grid />}
      </Box>
    </Box>
  );
};
