import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, List, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { invoicesTypesMap } from '../../../../../../../components/billing';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { EditInvoiceModal } from '../../../../../CasesPage/Cases/CaseItem/TabsContent/Billing/EditInvoiceModal';
import { PreviewInvoiceModal } from '../../../../../CasesPage/Cases/CaseItem/TabsContent/Billing/PreviewInvoiceModal';
import { InvoicesContext } from '../../../InvoicesContext';

const styles = () => ({
  root: {
    minWidth: 172
  }
});

const useStyles = makeStyles(styles);

export const InvoiceMenu = ({ paymentForm, invoice, caseItem }) => {
  const classes = useStyles();
  const { updateInvoice, confirmInvoice, cancelInvoice,  ...invoiceContext } = useContext(InvoicesContext);
  const { openModal } = useModal();

  const deleteInvoice = () => {
    invoiceContext.deleteInvoices(invoice.id);
  };

  const openEditInvoiceModal = () => {
    openModal(EditInvoiceModal, {
      payload: {
        initialValues: {
          model_type: caseItem?.id ? invoicesTypesMap.case : null,
          model: caseItem,
          id: invoice.id,
          updateInvoice
        }
      }
    });
  };

  const openInvoiceViewModal = () => {
    openModal(PreviewInvoiceModal, {
      payload: {
        invoiceID: invoice.id,
        confirmInvoice,
        cancelInvoice
      }
    });
  };

  return (
    <List className={classes.root}>
      <MenuItem onClick={openInvoiceViewModal}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {invoice?.model_type === invoicesTypesMap.case && (paymentForm || !!caseItem) ?
        <MenuItem onClick={openEditInvoiceModal}>
          <ListItemIcon>
            <EditIcon fontSize="inherit" />
          </ListItemIcon>

          <ListItemText primary="Edit" />
        </MenuItem>
        :
        <MenuItem
          component={Link}
          to={`/billing/invoices/edit/${invoice.id}`}
        >
          <ListItemIcon>
            <EditIcon fontSize="inherit" />
          </ListItemIcon>

          <ListItemText primary="Edit" />
        </MenuItem>
      }

      <Tooltip
        isExistTooltip={!!invoice.paid}
        title="You cannot delete an invoice while it has payments. Delete them first."
      >
        <div>
          <MenuItem
            disabled={!!invoice.paid}
            onClick={deleteInvoice}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="inherit"/>
            </ListItemIcon>

            <ListItemText primary="Delete invoice" />
          </MenuItem>
        </div>
      </Tooltip>
    </List>
  );
};
