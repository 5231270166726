import { useContext, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { InfiniteGridLoader } from '../../../../../../components/InfiniteLoader';
import { AppointmentsContext } from '../../../../../../components/appointments';
import { Loader } from '../../../../../../components/Loader';
import { Card } from './Card';

export const Grid = () => {
  const {
    isFetched,
    isFetching,
    appointments,
    meta,
    loadNextPage,
    reloadAppointments
  } = useContext(AppointmentsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    reloadAppointments();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box py={2} pl={2}>
        <Loader loading={!isFetched} render={
          () => !meta.total ? (
            <Box p={3}>
              <Typography align="center">No appointments found</Typography>
            </Box>
          ) : (
            <InfiniteGridLoader
              items={appointments}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={{ last_page: meta.last_page, page: meta.page }}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={370}
              cellHeight={240}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
