import { useContext } from 'react';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AuthorizationFormModal } from '../AuthorizationFormModal';
import { useModal } from '../../ModalsProvider';
import { AuthorizationsContext } from './AuthorizationsProvider';

export const Header = ({ userID }) => {
  const { openModal } = useModal();
  const { resetAuthorizations } = useContext(AuthorizationsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const addAuthorization = () => {
    openModal(AuthorizationFormModal, {
      payload: {
        authorization: {
          user_id: userID
        }
      },

      onModalResolved: () => {
        resetAuthorizations();
      }
    });
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size={isMobile ? 'small' : 'medium'}
        startIcon={<AddIcon />}
        onClick={addAuthorization}
      >
        {isMobile ? 'Add #' : 'Add Authorization #'}
      </Button>
    </div>
  );
};
