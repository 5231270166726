import { FormControlLabel, Grid, Typography, FormGroup, Box } from '@material-ui/core';
import { Autocomplete, Checkbox, TextField } from '../../../FormField';
import { epsdtConditionCodesOptions } from './data';

export const Medicaid = () => {
  return (
    <>
      <Box mb={1}>
        <Typography variant="h3">
          Medicaid
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            name="resubmission_code"
            label="Resubmission Code"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            name="original_reference"
            label="Original Reference #"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            name="service_auth_exception_code"
            label="Service Auth Exception Code"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            name="medical_referral_access"
            label="Medical Referral Access #"
          />
        </Grid>

        <Grid item md={12}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name="is_family_planning" />}
              label="Family Planning"
            />

            <FormControlLabel
              control={<Checkbox name="is_epsdt" />}
              label="EPSDT"
            />

            <FormControlLabel
              control={<Checkbox name="is_copay_exempt" />}
              label="Copay Exempt"
            />
          </FormGroup>
        </Grid>

        <Grid item md={6} xs={12}>
          <Autocomplete
            name="epsdt_condition_code"
            label="EPSDT Condition Code"
            options={epsdtConditionCodesOptions}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            name="epsdt_referral_code"
            label="EPSDT Referral Code"
          />
        </Grid>
      </Grid>
    </>
  );
};
