import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import { feedMessages } from '../../../../../../components/feeds/feedMessages';

export const Row = ({ item: feed, onClose }) => {
  const message = feedMessages[feed?.type];

  return !!message && (
    <Box key={feed.id} p={1}>
      <Typography variant="body2" color="textSecondary">
        {moment.unix(feed.created_at).format('L LT')}
      </Typography>

      {message({ data: feed.data, onClose })}
    </Box>
  );
};
