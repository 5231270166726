import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  Dialog,
  Grid,
  Typography,
  Box,
  useMediaQuery
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DomainIcon from '@material-ui/icons/Domain';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { ColorPreview } from '../../../ColorPreview';
import { IconButton } from '../../../IconButton';
import { Loader } from '../../../Loader';
import { Tooltip } from '../../../Tooltip';
import * as templatesApi from '../../../../api/schedule-events/schedule-event-templates';
import { AddressLink } from '../../../AddressLink';
import { UserLink } from '../../../UserLink';
import { useModal } from '../../../ModalsProvider';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { ModalHeader, ModalHeaderActions, ModalBody, ModalContainer } from '../../../Modal';
import { EditEventTemplateModal } from '../EditEventTemplateModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ViewEventTemplateModal = ({
  DialogProps,
  payload: {
    disableActions = false,
    templateId,
    template: templatePayload,
    onUpdate = () => {},
    onDelete = () => {}
  },
  handleModalReject
}) => {
  const [ template, setTemplate ] = useState(templatePayload);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileSm));
  const currentUser = useSelector(({ profile }) => profile.user);
  const isCreator = currentUser.id === template?.user?.id;

  const editTemplate = () => {
    openModal(EditEventTemplateModal, {
      payload: {
        initialValues: template
      },

      onModalResolved: (template) => {
        onUpdate(template);
        setTemplate(template);
      }
    });
  };

  const deleteTemplate = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        handleModalReject();

        templatesApi.deleteEventTemplate(template.id).then(() => {
          onDelete(template);
          enqueueSnackbar(`Template "${template.title}" successfully deleted`, { variant: 'success' });
        });
      }
    });
  };

  useEffect(() => {
    if (!templateId) {
      return;
    }

    templatesApi.fetchEventTemplates(templateId).then((template) => {
      setTemplate(template);
    }).catch(() => {
      enqueueSnackbar('Something wrong with opening that event', { variant: 'error' });
      handleModalReject();
    });
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Template details

          {!disableActions && !!template && (
            <ModalHeaderActions disableGutters>
              {(isCreator || hasRole(rolesMap.admin, rolesMap.supervisor)) &&
                <Tooltip title="Edit template">
                  <IconButton color="info" onClick={editTemplate}>
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              }

              {(isCreator || hasRole(rolesMap.admin, rolesMap.supervisor)) &&
                <Tooltip title="Delete template">
                  <IconButton color="error" onClick={deleteTemplate}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              }
            </ModalHeaderActions>
          )}
        </ModalHeader>

        <ModalBody disableScrollbars>
          <Loader loading={!template} render={
            () => (
              <>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Template name
                        </Typography>
                      </th>

                      <td>
                        <Typography variant="subtitle2">
                          {template.name}
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Title
                        </Typography>
                      </th>

                      <td>
                        <Typography variant="subtitle2">
                          {template.title}
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Description
                        </Typography>
                      </th>

                      <td>
                        <Typography
                          variant="subtitle2"
                          dangerouslySetInnerHTML={{ __html: template.description || '-' }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Type
                        </Typography>
                      </th>

                      <td>
                        {template.type ? (
                          <Grid container spacing={1} alignItems="center">
                            <Grid item component={Box} display="flex">
                              <ColorPreview color={template.type?.color}/>
                            </Grid>

                            <Grid item>
                              <Typography variant="subtitle2">
                                {template.type?.name || '-'}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          <Typography variant="subtitle2">
                            -
                          </Typography>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Participants
                        </Typography>
                      </th>

                      <td>
                        <div className={classes.participants}>
                          {template?.users?.length ? template?.users?.map((user) => (
                            <div key={user.id} className={classes.participant}>
                              <UserLink disableName user={user}/>
                            </div>
                          )) : (
                            <Typography variant="subtitle2">-</Typography>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Office
                        </Typography>
                      </th>

                      <td>
                        {template.office ?
                          <>
                            <Grid container spacing={1}>
                              <Grid item>
                                <DomainIcon fontSize="small"/>
                              </Grid>

                              <Grid item>
                                <Typography variant="subtitle2">
                                  {template.office?.name}
                                </Typography>
                              </Grid>
                            </Grid>

                            {!!template.office?.full_address &&
                              <Grid container spacing={1}>
                                <Grid item>
                                  <LocationOnOutlinedIcon fontSize="small"/>
                                </Grid>

                                <Grid item>
                                  <AddressLink address={template.office?.full_address} variant="subtitle2">
                                    {template.office?.full_address}
                                  </AddressLink>
                                </Grid>
                              </Grid>
                            }
                          </>
                          :
                          <Typography variant="subtitle2">
                            -
                          </Typography>
                        }
                      </td>
                    </tr>

                    <tr>
                      <th className={classes.th}>
                        <Typography variant="body1" color="textSecondary">
                          Creator
                        </Typography>
                      </th>

                      <td>
                        <UserLink user={template?.user} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          />
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
