import { api } from '../api';

export const fetchUsers = (config = {}) => {
  return api.get('/users', config)
    .then((({ data }) => data))
    .catch((error) => {
      throw error;
    });
};

export const createUser = (user) => {
  return api.post('/users', user)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const uploadAvatar = (data) => {
  return api.post('/user/avatars', data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const fetchUser = (id, config) => {
  return api.get(`/users/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const updateUser = (user) => {
  return api.put(`/users/${user.id}`, user)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const deleteUser = (id) => {
  return api.delete(`/users/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const massDeleteUsers = (IDs) => {
  return api.delete('/users', { params: { users_ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createBaseUser = (user) => {
  return api.post('/users/base', user)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const uploadDriverLicense = (id, data) => {
  return api.post(`/driver-license/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const deleteDriverLicense = ({ id, tags }) => {
  return api.delete(`/driver-license/${id}`, { params: { tags } })
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
