import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { Fab } from '../../../../../components/Fab';
import { ArrivalStatusesContextProvider } from './ArrivalStatusesContext';
import { List } from './List';

export const ArrivalStatuses = ({ baseUrl }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>
          Arrival statuses
        </PageHeaderTitle>

        <PageHeaderActions>
          <Fab
            size={isMobile ? 'small' : 'medium'}
            component={Link}
            to={`${pathname}/create`}
            variant="extended"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add arrival status
          </Fab>
        </PageHeaderActions>
      </PageHeader>

      <PageBody fullHeight disablePaddings>
        <ArrivalStatusesContextProvider>
          <List baseUrl={baseUrl} />
        </ArrivalStatusesContextProvider>
      </PageBody>
    </Page>
  );
};
