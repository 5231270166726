import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { NestedList } from '../../../../../NestedList';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../helpers/isEmptyDeep';

export const PhysicalExamination = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(report) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Physical examination"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Grid container spacing={0} component={Box} py={2} px={1} m="0 !important">
        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" alignItems="center" mb={2}>
            {report?.bp &&
              <Box display="flex" alignItems="center" pr={4} mt={2} width="20%">
                <Box mr={1}>
                  <Typography variant="h5">BP:</Typography>
                </Box>

                <Typography color="textSecondary">{report?.bp}</Typography>
              </Box>
            }

            {report?.hr &&
              <Box display="flex" alignItems="center" pr={4} mt={2} width="20%">
                <Box mr={1}>
                  <Typography variant="h5">HR:</Typography>
                </Box>

                <Typography color="textSecondary">{report?.hr}</Typography>
              </Box>
            }

            {report?.rr &&
              <Box display="flex" alignItems="center" pr={4} mt={2} width="20%">
                <Box mr={1}>
                  <Typography variant="h5">RR:</Typography>
                </Box>

                <Typography color="textSecondary">{report?.rr}</Typography>
              </Box>
            }

            {report?.temp &&
              <Box display="flex" alignItems="center" pr={4} mt={2} width="20%">
                <Box mr={1}>
                  <Typography variant="h5">Temp:</Typography>
                </Box>

                <Typography color="textSecondary">{report?.temp}</Typography>
              </Box>
            }

            {report?.vas &&
              <Box display="flex" alignItems="center" pr={4} mt={2} width="20%">
                <Box mr={1}>
                  <Typography variant="h5">VAS:</Typography>
                </Box>

                <Typography color="textSecondary">{report?.vas}</Typography>
              </Box>
            }
          </Box>
        </Grid>

        <Grid item xs={12}>
          {report?.alert &&
            <ReadonlyCheckbox
              checked={report?.alert}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="Alert"
            />
          }

          {report?.no_distress &&
            <ReadonlyCheckbox
              checked={report?.no_distress}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="No distress"
            />
          }

          {report?.normal_speech &&
            <ReadonlyCheckbox
              checked={report?.normal_speech}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="Normal Speech"
            />
          }

          {report?.normal_mood_affect &&
            <ReadonlyCheckbox
              checked={report?.normal_mood_affect}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="Normal Mood/affect"
            />
          }

          {report?.perrla &&
            <ReadonlyCheckbox
              checked={report?.perrla}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="PERRLA"
            />
          }

          {report?.chest_clear &&
            <ReadonlyCheckbox
              checked={report?.chest_clear}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="Chest clear"
            />
          }

          {report?.heart_reg &&
            <ReadonlyCheckbox
              checked={report?.heart_reg}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="Heart Reg."
            />
          }
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {report?.motor_strength &&
              <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={320} width="30%">
                <Box minWidth={100} mr={1}>
                  <Typography variant="h5">Motor Strength:</Typography>
                </Box>

                <Typography color="textSecondary">{report?.motor_strength}</Typography>
              </Box>
            }

            {report?.reflexes &&
              <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={320} width="30%">
                <Box minWidth={100} mr={1}>
                  <Typography variant="h5">Reflexes:</Typography>
                </Box>

                <Typography color="textSecondary">{report?.reflexes}</Typography>
              </Box>
            }

            {report?.sensory &&
              <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={320} width="30%">
                <Box minWidth={100} mr={1}>
                  <Typography variant="h5">Sensory:</Typography>
                </Box>

                <Typography color="textSecondary">{report?.sensory}</Typography>
              </Box>
            }

            {report?.back_flexion &&
              <Box display="flex" alignItems="center" pr={4} mt={2} width="25%">
                <Box minWidth={100} mr={1}>
                  <Typography variant="h5">
                    <Box component="span" color="info.main">Back-</Box>
                    Flexion:
                  </Typography>
                </Box>

                <Typography color="textSecondary">{report?.back_flexion}</Typography>
              </Box>
            }

            {report?.back_extension &&
              <Box display="flex" alignItems="center" pr={4} mt={2} width="25%">
                <Box mr={1}>
                  <Typography variant="h5">Extension:</Typography>
                </Box>

                <Typography color="textSecondary">{report?.back_extension}</Typography>
              </Box>
            }

            {report?.neck_flexion &&
              <Box display="flex" alignItems="center" pr={4} mt={2} width="25%">
                <Box minWidth={100} mr={1}>
                  <Typography variant="h5">
                    <Box component="span" color="info.main">Neck-</Box>
                    Flexion:
                  </Typography>
                </Box>

                <Typography color="textSecondary">{report?.neck_flexion}</Typography>
              </Box>
            }

            {report?.neck_extension &&
              <Box display="flex" alignItems="center" pr={4} mt={2} width="25%">
                <Box mr={1}>
                  <Typography variant="h5">Extension:</Typography>
                </Box>

                <Typography color="textSecondary">{report?.neck_extension}</Typography>
              </Box>
            }
          </Box>
        </Grid>

        {(report?.slr_r || report?.slr_l) &&
          <Grid item xs={6}>
            <Box display="flex" flexWrap="wrap" alignItems="flex-end">
              <Box pr={4} mt={2} minWidth={40} width="10%">
                <Typography variant="h5">SLR:</Typography>
              </Box>

              {report?.slr_r &&
                <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={175} width="45%">
                  <Box mr={1}>
                    <Typography variant="h5">(R)</Typography>
                  </Box>

                  <Typography color="textSecondary">{report?.slr_r || '-'}</Typography>
                </Box>
              }

              {report?.slr_l &&
                <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={175} width="45%">
                  <Box mr={1}>
                    <Typography variant="h5">(L)</Typography>
                  </Box>

                  <Typography color="textSecondary">{report?.slr_l || '-'}</Typography>
                </Box>
              }
            </Box>
          </Grid>
        }

        {report?.check &&
          <Grid item xs={12}>
            <ReadonlyCheckbox
              checked={report?.check}
              BoxProps={{ px: 1, mt: 4, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="Paravertebral Tenderness"
            />
          </Grid>
        }

        {report?.text &&
          <Grid item xs={12}>
            <Box display="flex" alignItems="flex-start" mb={2}>
              <Box display="flex" alignItems="center">
                <NotesIcon fontSize="small" />

                <Box mr={1.5} ml={0.5}>
                  <Typography variant="subtitle2">
                    Note
                  </Typography>
                </Box>
              </Box>

              <Typography>
                {report?.text}
              </Typography>
            </Box>
          </Grid>
        }

        {report?.other &&
          <Grid item xs={12}>
            <Typography variant="h5">Other:</Typography>

            <Box display="flex" alignItems="flex-start" mt={1}>
              <Box display="flex" alignItems="center">
                <NotesIcon fontSize="small" />

                <Box mr={1.5} ml={0.5}>
                  <Typography variant="subtitle2">
                    Note
                  </Typography>
                </Box>
              </Box>

              <Typography>
                {report?.other}
              </Typography>
            </Box>
          </Grid>
        }
      </Grid>
    </NestedList>
  );
};
