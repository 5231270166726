import { useParams } from 'react-router-dom';
import { Loader } from '../../../../components/Loader';
import { FormStepper } from '../../ProfilePage/MedicalInfo/MedicalForms/FormStepper';
import { StepContext, StepProvider, steps } from '../Diagnosis/steps';

export const StepsComponent = () => {
  const params = useParams();

  return (
    <StepProvider id={params.reportID}>
      <StepContext.Consumer>
        {({ isFetched, isDirty, activeStep, completed, setActiveStep, pathname, Component }) => (
          <FormStepper
            isDirty={isDirty}
            reportID={params.reportID}
            pathname={pathname}
            steps={steps}
            activeStep={activeStep}
            completed={completed}
            onActiveStepChange={setActiveStep}
            component={<Loader loading={!isFetched} render={() => <Component />} />}
          />
        )}
      </StepContext.Consumer>
    </StepProvider>
  );
};
