import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Dialog, Button, Grid, SvgIcon, useTheme, useMediaQuery } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiCertificateOutline } from '@mdi/js';
import { getUserFullName } from '../../../../../../helpers/users';
import { TextField } from '../../../../../../components/FormField';
import { Loader } from '../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../components/Modal';
import { validationSchema } from './validationSchema';

export const CertificateSignatureModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { certificatesContext }
}) => {
  const { user } = useSelector(({ profile }) => profile);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));


  const createSignature = (values, { isSubmitting, setErrors, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    certificatesContext.addCertificate({ values, setErrors, setSubmitting, handleModalResolve });
  };

  return (
    <Dialog fullScreen={isMobile} {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={{
          name: getUserFullName(user),
          certificate_data: {
            commonName: getUserFullName(user),
            emailAddress: user.email || null,
            localityName: user?.home?.city || null,
            countryName: user?.home?.country ? user?.home?.country.slice(0, -1) : null,
            organizationalUnitName: user?.work?.department || null,
            stateOrProvinceName: user?.home?.state || null,
            organizationName: user?.work?.company?.name || null
          }
        }}
        validationSchema={validationSchema}
        onSubmit={createSignature}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiCertificateOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Create a certificate for signature
            </ModalHeader>

            <ModalBody>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    margin="dense"
                    name="name"
                    label="Certificate Name"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    margin="dense"
                    name="certificate_data.commonName"
                    label="Common Name"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="certificate_data.organizationName"
                    label="Organization/Company"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="certificate_data.organizationalUnitName"
                    label="Department"
                  />
                </Grid>

                <Grid item xs={12} container spacing={3} alignItems="center" justify="space-between">
                  <Grid item sm={4} xs={6}>
                    <TextField
                      margin="dense"
                      name="certificate_data.localityName"
                      label="City"
                    />
                  </Grid>

                  <Grid item sm={4} xs={6}>
                    <TextField
                      margin="dense"
                      name="certificate_data.stateOrProvinceName"
                      label="State"
                    />
                  </Grid>

                  <Grid item sm={4} xs={6}>
                    <TextField
                      margin="dense"
                      name="certificate_data.countryName"
                      label="Country"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    margin="dense"
                    name="certificate_data.emailAddress"
                    label="Email"
                  />
                </Grid>
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button color="primary" onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
