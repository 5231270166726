import { forwardRef } from 'react';
import { Link, Typography } from '@material-ui/core';
import { Tooltip } from '../Tooltip';
import { useModal } from '../ModalsProvider';
import { MapModal } from '../MapModal';

export const AddressLink = forwardRef(({
  children,
  address,
  lat,
  lng,
  name,
  addresses,
  stopPropagation = false,
  initialAddressKey,

  ...props
}, ref) => {
  const { openModal } = useModal();
  const Component = address ? Link : Typography;

  const openAddress = (event) => {
    if (!address) {
      return;
    }

    if (stopPropagation) {
      event.stopPropagation();
    }

    openModal(MapModal, {
      payload: {
        address,
        lat,
        lng,
        locationName: name,
        addresses,
        initialAddressKey
      }
    });
  };

  return (
    <Tooltip isExistTooltip={!!address} title={address}>
      <Component {...props} ref={ref} onClick={openAddress}>
        {children || address || 'No Info.'}
      </Component>
    </Tooltip>
  );
});
