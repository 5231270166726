export const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 70,
    border: `1px dashed ${palette.grey[200]}`,
    backgroundColor: palette.grey[25]
  },

  content: {
    padding: spacing(),
    margin: 'auto'
  }
});
