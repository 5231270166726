import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../components/UserLink';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.users_id?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Users:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            {params?.users_id?.map((user) => (
              <Box display="flex" key={user.id}>
                <UserLink
                  noWrap
                  size="sm"
                  user={user}
                />
              </Box>
            ))}
          </Grid>
        </>
      }

      {params?.case_id &&
        <>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                Cases:
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <Typography noWrap>
                {params?.case_id?.name || '-'}
              </Typography>
            </Box>
          </Grid>
        </>
      }

      {params.company_id &&
        <>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                Company:
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <CompanyLink
                noWrap
                variant="h5"
                company={params?.company_id}
              />
            </Box>
          </Grid>
        </>
      }

      {params.from &&
        <>
          <Grid item xs={4}>
            <Typography>
              Date From:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {moment.unix(params?.from).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params.to &&
        <>
          <Grid item xs={4}>
            <Typography>
              Date To:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {moment.unix(params?.to).format('L')}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
