import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import * as depositionsApi from '../../../../../../../../api/cases/depositions';
import EditIcon from '../../../../../../../../components/icons/square-edit-icone.svg';
import { Loader } from '../../../../../../../../components/Loader';
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContainer
} from '../../../../../../../../components/Modal';
import { DepositionForm, validationSchema } from '../DepositionForm';

export const EditDepositionModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    caseID,
    deposition
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateCase = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return depositionsApi.updateCaseDepositions(caseID, values).then((data) => {
      enqueueSnackbar('Case deposition successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case deposition not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={deposition}
      validationSchema={validationSchema}
      onSubmit={handleUpdateCase}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Edit Deposition
            </ModalHeader>

            <ModalBody>
              <DepositionForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
