import moment from 'moment';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarEdit } from '@mdi/js';
import { Icon } from '@mdi/react';
import { hasRole } from '../../../utils/hasRole';
import { Yup } from '../../../utils/validation';
import { apiModelsMap } from '../../../dataMaps/apiModels';
import * as appointmentsApi from '../../../api/appointments';
import {
  transformLevelToOption,
  transformModifiersCodesToOption,
  transformTimeZoneToOption
} from '../../FormField';
import { Loader } from '../../Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { AppointmentForm, Procedures, validationSchema } from '../AppointmentForm';
import { AttachmentsContainer } from '../../../app/Dashboard/TasksPage/AttachmentsContainer';
import { sidesOptions } from '../procedures';

export const AppointmentEditModal = ({
  payload: {
    disableApptBook = false,
    disableAttachments = false,
    appointmentID,
    appointmentType = null,
    insurance = {},
    medical_forms_id = null
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ appointment, setAppointment ] = useState(null);
  const validationApptBook = !disableApptBook
    ? validationSchema.shape({ appointment_book_id: Yup.mixed().required() })
    : validationSchema;
  const duration = moment.duration(appointment?.time || 0, 'seconds');

  const updateAppointment = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (disableApptBook) {
      delete values?.appointment_book_id;
    }

    return appointmentsApi.updateAppointment({
      ...values,

      patient_id: values.patient_id?.id || values.patient_id,
      timezone: values.timezone?.value || values.timezone,
      time: moment.duration(`${values._hours || 0}:${values._minutes || 0}`).asSeconds() || null
    }).then((appointment) => {
      enqueueSnackbar('Appointment successfully updated', { variant: 'success' });
      handleModalResolve(appointment);
    }).catch(({ status, data: { errors, code } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      } else if (status === 403 && code === 'appointments.cannot_edit_appt_claim') {
        enqueueSnackbar('Appointment attached to the claim cannot be changed', { variant: 'error' });
      }
    });
  };

  useEffect(() => {
    appointmentsApi.fetchAppointment(appointmentID).then((appointment) => {
      setAppointment(appointment);
    });
  }, []);

  return (
    <Dialog
      fullScreen={isMobile}
      PaperProps={!isMobile ? { style: { maxWidth: 1100 } } : {}}

      {...DialogProps}
    >
      <Loader p={3} loading={!appointment} render={
        () => (
          <Formik
            initialValues={{
              ...appointment,

              _hours: duration.hours() || null,
              _minutes: duration.minutes() || null,
              authorization_id: appointment.authorization,
              medical_forms_id: medical_forms_id || null,
              timezone: transformTimeZoneToOption(appointment.timezone),
              patient_id: appointment.patient || appointment?.patient_appointment_details?.patient,
              appointment_type_id: appointmentType || appointment.appointment_type,
              appointment_book_id: appointment.appointment_book,
              arrival_status_id: appointment.arrival_status,
              visit_reason_id: appointment.visit_reason,
              patient_insurance_id: appointment.patient_insurance || insurance?.insurance_details,
              office_id: hasRole('client', 'patient')
                ? appointment?.appointment_book?.office || appointment?.office
                : appointment.office,
              patient_insurance_pcp: appointment?.patient_insurance?.pcp || insurance?.patient_insurance_pcp || 0,
              patient_insurance_spc: appointment?.patient_insurance?.spc || insurance?.patient_insurance_spc || 0,
              insurance_details: {
                company: insurance?.insurance_company
              },
              procedures: appointment.procedures?.map((procedure) => ({
                ...procedure,

                side: sidesOptions?.find(({ value }) => value === procedure.side),
                levels: procedure?.levels?.map(transformLevelToOption),
                cpt_id: procedure?.cpt,
                cpt_description: procedure?.cpt?.description,
                pos_id: procedure?.pos,
                appointment_id: procedure?.appointment?.id,
                modifiers: procedure?.modifiers?.map(transformModifiersCodesToOption) || [],
                icd: procedure?.icd || [],
                unit: procedure.unit || 1,
                price: procedure.price || 0
              }))
            }}
            validationSchema={validationApptBook}
            onSubmit={updateAppointment}
          >
            {({ isSubmitting, handleSubmit }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarEdit} /></SvgIcon>} onClose={handleModalReject}>
                  Edit appointment
                </ModalHeader>

                <ModalBody>
                  <AppointmentForm disableApptBook={disableApptBook} appointmentType={appointmentType} />
                  <Procedures />

                  {!disableAttachments && !hasRole('client') &&
                    <Box mt={3}>
                      <AttachmentsContainer
                        disableVirtual
                        disableFetching
                        files={appointment.media}
                        filter={{ appointments: [ appointment.id ] }}
                        ownerType={apiModelsMap.appointment}
                        ownerID={appointment.id}
                      />
                    </Box>
                  }
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject} disabled={isSubmitting}>
                    Cancel
                  </Button>

                  <Loader
                    surface
                    loading={isSubmitting}
                    render={() => (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
