export const getUserFullName = (user) => {
  if (!user) {
    return;
  }

  const last_name = user?.user?.profile?.last_name
    || user?.profile?.last_name
    || user?.user?.last_name
    || user?.last_name
    || '';
  const first_name = user?.user?.profile?.first_name
    || user?.profile?.first_name
    || user?.user?.first_name
    || user?.first_name
    || '';

  return (first_name + (first_name && last_name ? ' ' : '') + last_name || '') || '';
};
