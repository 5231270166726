import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiDomain } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as officeApi from '../../../../../api/companies';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContainer
} from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { OfficeForm, validationSchema } from '../OfficeForm';
import { initialValues } from './initialValues';

export const CreateOfficeModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    disableCompany = false,
    attorneyInfo = {},
    company = null
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const addOffice = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if(!!values?.venue?.length) {
      const isMainVenue = values.venue.find((item) => item.is_main > 0);

      if (!isMainVenue) {
        values.venue[0] = { ...values.venue[0], is_main: 1 };
      }
    }

    return officeApi.createOffice({
      ...values,

      company_id: values?.company_id?.id || values?.company_id || null,
      users: values?.users?.map((user) => ({ id: user?.id?.id, position: user.position }))
    }).then((data) => {
      enqueueSnackbar('Office successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Office not created', { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,

        disableUserRemove: !!attorneyInfo?.id,
        company_id: company?.id ? company : null,
        phone: attorneyInfo.attorney_phone || null,
        email: attorneyInfo.attorney_email || null,
        users: attorneyInfo?.id ? [ {
          id: attorneyInfo,
          position: attorneyInfo.position,
          phone: attorneyInfo.phone || attorneyInfo.phone_number
        } ] : []
      }}
      validationSchema={validationSchema}
      onSubmit={addOffice}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiDomain} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Add Office
            </ModalHeader>

            <ModalBody>
              <OfficeForm disableCompany={disableCompany} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
