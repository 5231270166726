export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    flexGrow: 1,
    padding: spacing()
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(),
    flex: '0 0 300px'
  }
});
