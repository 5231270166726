import { debounce } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconComponent } from '../../../../../components/saved-filters';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../../helpers/dates';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { DatePicker, TextField } from '../../../../../components/FormField';
import { saveFilter } from '../../../../../store/lastFilters';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { HistoriesContext } from '../HistoriesProvider';
import { initialValues } from './initialValues';
import { List } from './List';

const MODAL_WIDTH = 810;

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const { applyFilter } = useContext(HistoriesContext);
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleDatePickerChange = (name, transformer) => (date) => {
    applyFilter({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);
    applyFilter({
      ...filter,

      date_from: filter?.date_from,
      date_to: filter?.date_to,
      age: filter?.age,
      weight: filter?.weight,
      bmi: filter?.bmi,
      temperature: filter?.temperature,
      pressure: filter?.pressure
    });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    applySavedFilter({
      ...relationsForFilter,
      ...lastFilters
    });
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      hiddenFields={hiddenFields}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      onReset={() => {
        applySavedFilter(initialValues);
      }}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.dateFrom,
          label: filterFieldsLabels[filterFieldsMap.dateFrom],
          field: (
            <DatePicker
              clearable
              minWidth={140}
              name="date_from"
              label="From"
              onChange={handleDatePickerChange('date_from', unixToStartOfDayUnix)}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.dateTo,
          label: filterFieldsLabels[filterFieldsMap.dateTo],
          field: (
            <DatePicker
              clearable
              minWidth={140}
              name="date_to"
              label="To"
              onChange={handleDatePickerChange('date_to', unixToEndOfDayUnix)}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.age,
          label: filterFieldsLabels[filterFieldsMap.age],
          field: (
            <TextField
              name="age"
              label="Age"
              onChange={handleFieldChange('age')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.pressure,
          label: filterFieldsLabels[filterFieldsMap.pressure],
          field: (
            <TextField
              name="pressure"
              label="Pressure"
              onChange={handleFieldChange('pressure')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.temperature,
          label: filterFieldsLabels[filterFieldsMap.temperature],
          field: (
            <TextField
              name="temperature"
              label="Body temperature"
              onChange={handleFieldChange('temperature')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.weight,
          label: filterFieldsLabels[filterFieldsMap.weight],
          field: (
            <TextField
              name="weight"
              label="Weight"
              onChange={handleFieldChange('weight')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.bmi,
          label: filterFieldsLabels[filterFieldsMap.bmi],
          field: (
            <TextField
              name="bmi"
              label="BMI"
              onChange={handleFieldChange('bmi')}
            />
          )
        }
      ]}
    />
  );
};
