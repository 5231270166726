import { useContext } from 'react';
import { List } from '@material-ui/core';
import { BodyPartsContext } from '../../BodyPartsContext';
import { Row } from './Row';

export const SelectedBodyParts = () => {
  const { painfulParts } = useContext(BodyPartsContext);

  return (
    <List disablePadding>
      {Object.keys(painfulParts).map((part) => (
        <Row key={part} part={part} />
      ))}
    </List>
  );
};
