import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_FAXES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_FAXES_SUCCESS]: (
    { filter, faxes, ...state },
    { data: newFaxes, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      faxes: (page > 1 ?
        faxes.filter(({ id }) => !newFaxes.find((loadedEmail) => id === loadedEmail.id)).concat(newFaxes)
        :
        newFaxes
      )
    };
  },

  [types.FETCH_FAXES_ERROR]: (state, payload) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      error: payload
    };
  },

  [types.UPDATE_STATUS]: ({ faxes, ...state }, { id, status, data }) => {
    return {
      ...state,

      faxes: faxes.map((item) => {
        return item.id === id ? { ...item, system_status: status, ...data } : item;
      })
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_FAXES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.DELETE_FAXES]: ({ pagination, filter, faxes, ...state }, id) => {
    const deletedFaxes = faxes.filter((file) => id === file.id);
    const total = pagination.total - deletedFaxes.length;
    const page = Math.ceil((faxes.length - deletedFaxes.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      faxes: faxes.filter((file) => !deletedFaxes.find(({ id }) => id === file.id))
    };
  },

  [types.ADD_FAXES_TO_SELECTED]: ({
    selectedFaxes,
    selectedFaxesIDs,

    ...state
  }, faxes) => {
    return {
      ...state,

      selectedFaxes: selectedFaxes.concat(
        faxes.filter(({ id }) => !selectedFaxesIDs.includes(id))
      ),
      selectedFaxesIDs: [ ...new Set(selectedFaxesIDs.concat(faxes.map(({ id }) => id))) ]
    };
  },

  [types.DELETE_FAXES_FROM_SELECTED]: ({ selectedFaxes, selectedFaxesIDs, ...state }, faxesIDs) => {
    return {
      ...state,

      selectedFaxesIDs: selectedFaxesIDs.filter((id) => faxesIDs.indexOf(id) === -1),
      selectedFaxes: selectedFaxes.filter(({ id }) => faxesIDs.indexOf(id) === -1)
    };
  },

  [types.RESET_FAXES_SELECTED]: ({ selectedFaxesIDs, ...state }) => {
    return {
      ...state,

      selectedFaxes: [],
      selectedFaxesIDs: []
    };
  }
});
