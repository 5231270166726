import { useContext } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { mdiAlarm } from '@mdi/js';
import Icon from '@mdi/react';
import { useSelector } from 'react-redux';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { IconButton } from '../../../../../../components/IconButton';
import { MeasurementsPreviewModal } from '../../../../../../components/medical/forms';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Popper } from '../../../../../../components/Popper';
import { SvgIcon } from '../../../../../../components/SvgIcon';
import { stopPropagation } from '../../../../../../helpers/dom';
import { hasRole } from '../../../../../../utils/hasRole';
// eslint-disable-next-line max-len
import { getStatus } from '../../../../../../components/medical/forms/CreateMeasurementsModal/statusMap';
import { UserContext } from '../../../../Members/User/UserProvider';
import { Label } from '../../../../TasksPage/MainWindow/Label';
import { HistoriesContext } from '../../HistoriesProvider';
import { MeasurementsMenu } from '../../MeasurementsMenu';
import {
  columnsWidths,
  minRowHeight,
  widthBreakpointLarge,
  widthBreakpointMedium,
  widthBreakpointSmall,
  widthBreakpointXS,
  widthBreakpointXXS
} from '../List';

export const Row = ({ width, history }) => {
  const { openModal } = useModal();
  const profile = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : profile.user;
  const { selectedIDs, toggleItemSelection } = useContext(HistoriesContext);
  const isSelected = selectedIDs.indexOf(history.id) !== -1;
  const status = getStatus(history.systolic_blood_pressure || 0);

  const handleOpenPreview = () => {
    openModal(MeasurementsPreviewModal, {
      payload: {
        measurements: history,
        user
      }
    });
  };

  const handleItemSelection = () => {
    toggleItemSelection(history.id);
  };

  return (
    <ListRow button minHeight={minRowHeight} onClick={stopPropagation(handleOpenPreview)}>
      {!hasRole('client', 'patient') &&
        <ListRowCheckbox checked={isSelected} onClick={handleItemSelection}/>
      }

      <ListRowCell grow flexBasis={columnsWidths.date}>
        <Typography noWrap title={history.date && moment.unix(history.date).format('L, LT')}>
          <Box component="span" mr={1}>
            <InsertInvitationOutlinedIcon fontSize="small" />
          </Box>

          {history.date ? moment.unix(history.date).format('L') : '-'}

          <Box component="span" mx={1}>
            <SvgIcon fontSize="small"><Icon path={mdiAlarm} /></SvgIcon>
          </Box>

          {history.date ? moment.unix(history.date).format('LT') : '-'}
        </Typography>
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.age}>
          <Typography noWrap>{history.age || '-'}</Typography>
        </ListRowCell>
      }

      {width > widthBreakpointXXS &&
        <ListRowCell flexBasis={columnsWidths.temperature}>
          <Typography noWrap>{history.temperature || '-'}</Typography>
        </ListRowCell>
      }

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.blood_type}>
          <Typography noWrap>{history.blood_type || '-'}</Typography>
        </ListRowCell>
      }

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={columnsWidths.heart_rate}>
          <Typography noWrap>{history.heart_rate || '-'}</Typography>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.pressure}>
        <Typography noWrap>
          {`${history.systolic_blood_pressure || 0}/${history.diastolic_blood_pressure || 0}`}

          {history.systolic_blood_pressure > 0 &&
            <Box component="span" ml={2}>
              <Label name={status.label} color={status.value} />
            </Box>
          }
        </Typography>
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.weight}>
          <Typography noWrap>{history.weight || '-'}</Typography>
        </ListRowCell>
      }

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.bmi}>
          <Typography noWrap>{history.bmi}</Typography>
        </ListRowCell>
      }

      {!hasRole('client', 'patient') &&
        <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                  <MoreVertIcon/>
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <MeasurementsMenu history={history} onClose={handleClose} />
            )}
          </Popper>
        </ListRowCell>
      }
    </ListRow>
  );
};
