import { useEffect, useRef, useState } from 'react';
import { CustomScrollbars } from '../CustomScrollbars';

export const ScrollbarsLoader = ({
  reverse = false,
  rowsCount,
  total,
  isFetching,
  isFetched,
  onNeedNextPage,
  threshold,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  scrollerRef = useRef(),

  ...props
}) => {
  const [ lastScrollHeight, setLastScrollHeight ] = useState();
  const [ scrollBottom, setScrollBottom ] = useState(0);
  const hasNextPage = rowsCount < total;

  const handleScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
    if (isFetched && !isFetching) {
      const scrollBottom = scrollHeight - clientHeight - scrollTop;
      const scrollDistance = reverse ? scrollTop : scrollBottom;
      const needNextPage = scrollDistance < (threshold || clientHeight);

      setScrollBottom(scrollBottom);

      if (needNextPage && hasNextPage) {
        onNeedNextPage();
      }
    }
  };

  useEffect(() => {
    if (reverse && isFetched && scrollBottom === 0) {
      scrollerRef.current.scrollToBottom();
    }
  }, [ isFetched, total ]);

  useEffect(() => {
    if (reverse && scrollerRef?.current) {
      const { scrollHeight, scrollTop } = scrollerRef.current;

      if (isFetching) {
        setLastScrollHeight(scrollHeight);
      } else if (isFetched && !isFetching) {
        if (scrollTop < scrollHeight / 2) {
          scrollerRef.current.scrollTop += scrollHeight - lastScrollHeight;
        }

        handleScroll(scrollerRef.current);
      }
    }
  }, [ isFetched, isFetching ]);

  useEffect(() => {
    if (scrollBottom === 0 && hasNextPage) {
      onNeedNextPage();
    }
  }, [ scrollBottom, hasNextPage ]);

  return (
    <CustomScrollbars
      ref={scrollerRef}
      style={{ width: '100%', height: '100%' }}
      onScroll={handleScroll}

      {...props}
    />
  );
};
