export const userTypesMap = {
  defendant: 'defendant',
  plaintiff: 'plaintiff'
};

export const userTypeLabelsMap = {
  [userTypesMap.defendant]: 'Defendant',
  [userTypesMap.plaintiff]: 'Plaintiff'
};

export const userTypeOptions = [
  {
    value: userTypesMap.defendant,
    label: userTypeLabelsMap[userTypesMap.defendant]
  },
  {
    value: userTypesMap.plaintiff,
    label: userTypeLabelsMap[userTypesMap.plaintiff]
  }
];
