import { useContext } from 'react';
import {
  makeStyles,
  SvgIcon,
  IconButton
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { downloadFile as download } from '../../../../../helpers/files';
import { ScreenshotsContext } from '../ScreenshotsContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Menu = ({ screenshot }) => {
  const classes = useStyles();

  const { deleteScreenshot, renameScreenshot } = useContext(ScreenshotsContext);

  const downloadScreenshot = () => {
    download({ src: screenshot.url, name: screenshot.original_filename });
  };

  const handleDeleteScreenshot = () => {
    deleteScreenshot(screenshot);
  };

  const handleRenameScreenshot = () => {
    renameScreenshot(screenshot);
  };

  return (
    <div className={classes.root}>
      <IconButton>
        <EyeIcon fontSize="inherit"/>
      </IconButton>

      <IconButton onClick={handleRenameScreenshot}>
        <EditIcon fontSize="inherit"/>
      </IconButton>

      <IconButton onClick={handleDeleteScreenshot}>
        <SvgIcon fontSize="inherit">
          <Icon path={mdiTrashCanOutline} />
        </SvgIcon>
      </IconButton>

      <IconButton onClick={downloadScreenshot}>
        <DownloadIcon fontSize="inherit"/>
      </IconButton>
    </div>
  );
};
