import { api } from '../api';

export const fetchThreads = (config = {}) => {
  return api.get('/chat/threads', config)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const fetchCurrentThread = (threadId, config = {}) => {
  return api.get(`/chat/threads/${threadId}`, config)
    .then(({ data }) =>  data)
    .catch((error) => { throw error.data; });
};

export const fetchThreadFiles = (threadId, config = {}) => {
  return api.get(`/chat/threads/${threadId}/files`, config)
    .then(({ data }) =>  data)
    .catch((error) => { throw error.data; });
};

export const fetchUsers = (config = {}) => {
  return api.get('/chat/users', config)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const createThread = (chatData) => {
  return api.post('/chat/threads', chatData)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const updateThread = (chatData) => {
  return api.put(`/chat/threads/${chatData.id}`, chatData)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const changeThreadOwner = (threadId, data, config) => {
  return api.put(`/chat/threads/${threadId}/author`, data, config)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const leaveThread = (threadId) => {
  return api.delete(`/chat/threads/${threadId}/leave`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const deleteThread = (threadId) => {
  return api.delete(`/chat/threads/${threadId}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const unarchiveThread = (threadId) => {
  return api.post(`/chat/threads/archived/${threadId}/unzip`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const threadSeen = (threadId) => {
  return api.post(`/chat/threads/${threadId}/seen`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const toggleChatAsFavorite = (threadId) => {
  return api.put(`/chat/threads/${threadId}/favorite`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};
