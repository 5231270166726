import moment from 'moment-timezone';
// Cyclic dependency, dont shortening path
import { transformTimeZoneToOption } from '../../../../../components/FormField/selects/TimeZoneSelect';

export const initialValues = {
  company_id: null,
  name: null,
  practice: null,
  street: null,
  city: null,
  state: null,
  zip: null,
  country: null,
  designator: null,
  unit_number: null,
  mobile_phone: null,
  phone: null,
  fax: null,
  email: null,
  billing_contact_id: null,
  timezone: transformTimeZoneToOption(moment.tz.guess(true)),
  billing: {
    country: null,
    state: null,
    city: null,
    zip: null,
    street: null,
    designator: null,
    unit_number: null,
    mobile_phone: null,
    phone: null,
    fax: null,
    email: null
  },
  venue: [],
  notes: null,
  users: [],
  is_medical: false,
  medical_data: {
    pos_id: null,
    npi: null,
    clia: null,
    medicare: null,
    medicaid: null,
    mammog: null,
    hide_on_claim: false,
    default_location: false,
    show_location_in_box: false
  }
};
