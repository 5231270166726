import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, omit } from 'lodash';
import { api } from '../../../../api';
import * as sessionsApi from '../../../../api/login-queues/login-sessions';
import { useMemoEffect, usePrevious } from '../../../../helpers/hooks';
import { Echo } from '../../../../utils/echo';
import { LoginSessionsFiltersContext } from '../LoginSessionsFiltersProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const LoginSessionsContext = createContext(null);

export const LoginSessionsProvider = ({ children, account }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    loginSessions,
    pagination,
    isFetching,
    isFetched,
    filter
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});
  const { filter: commonFilter = {} } = useContext(LoginSessionsFiltersContext);

  const fetchLoginSessions = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.LOGIN_SESSIONS_FETCH_REQUEST });

    sessionsApi.fetchSessions({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.LOGIN_SESSIONS_FETCH_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const addLoginSession = (loginSession) => {
    dispatch({ type: types.ADD_LOGIN_SESSION, payload: loginSession });
  };

  const deleteLoginSession = (loginSession) => {
    dispatch({ type: types.DELETE_LOGIN_SESSION, payload: loginSession });
  };

  const updateLoginSession = (loginSession) => {
    dispatch({ type: types.UPDATE_LOGIN_SESSION, payload: loginSession });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchLoginSessions({ page: filter.page + 1 });
    }
  };

  const resetLoginSessions = (newFilter) => {
    dispatch({ type: types.RESET_LOGIN_SESSIONS, payload: newFilter });

    fetchLoginSessions({ page: 1, ...newFilter });
  };

  const providerValue = {
    loginSessions,
    isFetched,
    isFetching,
    meta: {
      ...filter,
      ...pagination
    },
    fetchLoginSessions,
    addLoginSession,
    updateLoginSession,
    deleteLoginSession,
    resetLoginSessions,
    loadNextPage
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      fetchLoginSessions(newFilter);
    }
  }, [ prevFilter, filter ]);

  useMemoEffect(() => {
    const newFilter =  omit({ ...filter, ...commonFilter }, [ 'page' ]);

    if (!isEqual(omit(filter, [ 'page' ]), newFilter)) {
      applyFilter(newFilter);
    }
  }, [ filter, commonFilter ]);

  useEffect(() => {
    const accountChanel = Echo.private(`login_queue.account.${account.id}`);

    const handleSessionStarted = ({ session }) => {
      addLoginSession(session);
    };

    const handleSessionStopped = ({ session }) => {
      updateLoginSession(session);
    };

    const handleSessionDeleted = ({ session }) => {
      deleteLoginSession(session);
    };

    accountChanel.listen('.login_queue.session.started', handleSessionStarted);
    accountChanel.listen('.login_queue.session.stopped', handleSessionStopped);
    accountChanel.listen('.login_queue.session.deleted', handleSessionDeleted);

    return () => {
      cancelFetch.current();

      accountChanel.stopListening('.login_queue.session.started', handleSessionStarted);
      accountChanel.stopListening('.login_queue.session.stopped', handleSessionStopped);
      accountChanel.stopListening('.login_queue.session.deleted', handleSessionDeleted);
    };
  }, []);

  return (
    <LoginSessionsContext.Provider value={providerValue}>
      {children}
    </LoginSessionsContext.Provider>
  );
};
