export const fieldsKeysMap = {
  search: 'search',
  createdFrom: 'created_from',
  createdTo: 'created_to',
  dueFrom: 'due_from',
  dueTo: 'due_to',
  cases: 'cases',
  types: 'types',
  statuses: 'statuses',
  priority: 'priority',
  users: 'users'
};

export const filterLabels = {
  [fieldsKeysMap.search]: 'Search',
  [fieldsKeysMap.createdFrom]: 'Created From',
  [fieldsKeysMap.createdTo]: 'Created To',
  [fieldsKeysMap.dueFrom]: 'Due From',
  [fieldsKeysMap.dueTo]: 'Due To',
  [fieldsKeysMap.cases]: 'Cases',
  [fieldsKeysMap.types]: 'Types',
  [fieldsKeysMap.statuses]: 'Statuses',
  [fieldsKeysMap.priority]: 'Priority',
  [fieldsKeysMap.users]: 'Users'
};
