import { useContext, useRef } from 'react';
import { Box, Typography, List as MuiList } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
import { Sticky } from '../../../../../components/Sticky';
import { FilesFromPrintersContext } from '../FilesFromPrintersProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 45;
export const widthBreakpointLarge = 765;

export const columnsMap = {
  name: 'name',
  size: 'size',
  date: 'date',
  action: 'action'
};

export const columnsWidths = {
  [columnsMap.name]: 300,
  [columnsMap.size]: 150,
  [columnsMap.date]: 160,
  [columnsMap.action]: 60
};

export const List = () => {
  const { isFetched, files } = useContext(FilesFromPrintersContext);
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader loading={!isFetched} p={2} render={
        () => (!files?.length ? (
          <Box p={2}>
            <Typography align="center">Files not found</Typography>
          </Box>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader />
            </Sticky>

            <MuiList>
              {files?.map((file) => (
                <Row file={file} key={file.name} />
              ))}
            </MuiList>
          </>
        ))}
      />
    </CustomScrollbars>
  );
};
