import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import {
  AppointmentsFilterProvider,
  AppointmentsProvider
} from '../index';
import { Appointments } from './Appointments';
import { Scanner } from './Scanner';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AppointmentCheckIn = () => {
  const classes = useStyles();
  const selectedDate = moment();

  return (
    <div className={classes.root}>
      <AppointmentsFilterProvider
        filter={{
          appointment_from: selectedDate.unix(),
          appointment_to: selectedDate.endOf('day').unix()
        }}
      >
        <AppointmentsProvider makeFetchOnMount>
          <Scanner />
          <Appointments />
        </AppointmentsProvider>
      </AppointmentsFilterProvider>
    </div>
  );
};
