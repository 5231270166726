import { ButtonGroup, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import { Tooltip } from '../../../components/Tooltip';

export const GeneralAndMedicalSwitcher = ({ isMedical = false, generalLink, medicalLink }) => {
  return (
    <ButtonGroup color="primary" size="large">
      <Tooltip title="General info">
        <Button
          variant={isMedical ? 'outlined' : 'contained'}
          component={RouterLink}
          to={generalLink}
        >
          <PersonOutlineOutlinedIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Medical info">
        <Button
          variant={isMedical ? 'contained' : 'outlined'}
          component={RouterLink}
          to={medicalLink}
        >
          <LocalHospitalOutlinedIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};
