import { createReduxReducer } from '../../../helpers/createReduxReducer';

const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const TOGGLE_USER_INFO = 'TOGGLE_USER_INFO';

export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR });
export const toggleUserInfo = () => ({ type: TOGGLE_USER_INFO });

const initialState = {
  sidebarIsOpen: true,
  userInfoIsOpen: true
};

export const reducer = createReduxReducer(initialState, {
  [TOGGLE_SIDEBAR]: ({ sidebarIsOpen, ...state }) => {
    return { ...state, sidebarIsOpen: !sidebarIsOpen };
  },
  [TOGGLE_USER_INFO]: ({ userInfoIsOpen, ...state }) => {
    return { ...state, userInfoIsOpen: !userInfoIsOpen };
  }
});
