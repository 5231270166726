import { filtersKeysMap } from '../../../../../../store/lastFilters';
import { filterFieldsMap } from '../../../../../../components/call-reports/CallReports/filterFieldsMap';
import { CallReportsProvider } from '../../../../../../components/call-reports/CallReportsProvider';
import { parentTypesMap } from '../../../../../../components/call-reports/CopyToModal/parentTypeOptions';
import { LayoutContextProvider } from '../../../../../../components/LayoutContext';
import { CallReportsWidget } from '../Widgets';
import { getFiltersValues } from '../Widgets/CallReportsWidget/getFilterFields';

export const CallReports = ({ isTabPage, caseItem, ...props }) => {
  return (
    <LayoutContextProvider>
      <CallReportsProvider
        disableCauser
        filter={{
          parent_id: caseItem?.id,
          parent_case_ids: [ caseItem?.id ],
          parent_type: parentTypesMap.case,
          ...getFiltersValues(props?.widgetFilters?.callReports)
        }}
      >
        <CallReportsWidget
          isTabPage={isTabPage}
          payload={{
            parent_id: caseItem?.id,
            parent_type: parentTypesMap.case
          }}
          filterKey={filtersKeysMap.call_reports_cases}
          hiddenFields={[ filterFieldsMap.parentCase, filterFieldsMap.parentUser ]}
          {...props}
        />
      </CallReportsProvider>
    </LayoutContextProvider>
  );
};
