import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Box } from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import * as printersApi from '../../../../../../api/printers';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { IconButton } from '../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { Members } from '../../../../../../components/Members';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { preventDefault } from '../../../../../../helpers/dom';
import { EditConfigurationModal } from '../../EditConfigurationModal';
import { PrintersConfigurationContext } from '../../PrintersConfigurationProvider';
import { columnsWidths } from '../PrintersConfigurationsList';

export const Row = ({ printer }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { deletePrinters, updatePrinter } = useContext(PrintersConfigurationContext);

  const editPrinterConfiguration = () => {
    openModal(EditConfigurationModal, {
      payload: {
        printer
      },
      onModalResolved: (printer) => {
        updatePrinter(printer);
      }
    });
  };

  const deletePrinterConfiguration = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        printersApi.deletePrinter(printer.id).then(() => {
          enqueueSnackbar('Printer deleted', { variant: 'success' });
          deletePrinters([ printer.id ]);
        }).catch(() => {
          enqueueSnackbar('Printer not deleted', { variant: 'error' });
        });
      },
      onModalRejected: () => {
        enqueueSnackbar('Printer not deleted', { variant: 'warning' });
      }
    });
  };

  return (
    <ListRow button component={Link} to={`/files/from-printers/${printer.id}`}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Box pr={1}>
          <PrintOutlinedIcon />
        </Box>

        {printer?.name || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.desc}>
        {printer?.description || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.directory}>
        {printer.directory}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {moment.unix(printer.created_at).format('MM/DD/YYYY, HH:mm')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.staff}>
        <Members
          isLink
          isParent
          noWrap
          maxVisible={4}
          owner={printer.owner}
          users={printer.users}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.manager}>
        <UserLink user={printer.user} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} justifyContent="flex-end">
        <IconButton color="info" onClick={preventDefault(editPrinterConfiguration)}>
          <EditOutlinedIcon />
        </IconButton>

        <IconButton edge="end" color="error" onClick={preventDefault(deletePrinterConfiguration)}>
          <DeleteOutlineIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
