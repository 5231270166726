export const styles = ({ palette: { grey }, spacing }) => ({
  mediumBoxBorder: {
    borderRight: `1px solid ${grey[500]}`,
    padding: spacing(1.25, 3.75)
  },

  smallBoxBorder: {
    borderRight: `1px solid ${grey[500]}`,
    padding: spacing(1.25)
  },

  mediumNoBorderBox: {
    padding: spacing(1.25, 3.75)
  },

  smallNoBorderBox: {
    padding: spacing(1.25)
  }
});
