import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Fab, Button, Tooltip } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { participantsUserRoles } from '../../../../../../../dataMaps';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { MembersModal } from '../../../MembersModal';
import { CaseUsersModal } from './CaseUsersModal';
import { List } from './List';

export const Participants = ({
  caseItem = {},
  page,
  height = null,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},
  onClose,
  onOpen,
  onPutItem,
  onMinimize,
  onMaximized,
  ...props
}) => {
  const userGlobalStore = useSelector(({ globalUser }) => globalUser);
  const { openModal } = useModal();
  const participants = userGlobalStore?.data?.participants;
  const roles = useMemo(() => (
    participants?.length ? participantsUserRoles.filter(({ value }) => {
      const existedParticipant = participants?.find(({ option }) => value === option.value);

      return existedParticipant?.value ?? true;
    }) : participantsUserRoles
  ), [ participants ]);

  const openMembersModal = () => {
    openModal(MembersModal, {
      payload: {
        caseItem,
        onCaseItemUpdate
      }
    });
  };

  const openRolesModal = () => {
    openModal(CaseUsersModal);
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      disableContentPaddings
      title="Participants"
      icon={!hasRole(rolesMap.client, rolesMap.patient) &&
        <>
          <Fab
            color="primary"
            size="small"
            onClick={openMembersModal}
          >
            <AddIcon fontSize="small" />
          </Fab>

          <Button
            color="primary"
            size="small"
            variant="contained"
            component={Link}
            to={`/cases/${caseItem.id}/contacts`}
          >
            Full info
          </Button>
        </>
      }
      endIcon={(
        <Tooltip title="Reset Filter">
          <IconButton
            color="primary"
            onClick={openRolesModal}
          >
            <SettingsIcon fontSize="small"/>
          </IconButton>
        </Tooltip>
      )}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <List
        caseRoles={roles}
        caseItem={caseItem}
        onCaseItemUpdate={onCaseItemUpdate}
      />
    </ContentCard>
  );
};
