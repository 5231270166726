import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, useMediaQuery } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { hasRole } from '../../../../utils/hasRole';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { TimeReportCreationModal } from '../../../../components/time-reports/TimeReportCreationModal';
import { TimeReportsContext } from '../../../../components/time-reports/TimeReportsProvider';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../components/Page';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { useModal } from '../../../../components/ModalsProvider';
import { IconButton } from '../../../../components/IconButton';
import { Tooltip } from '../../../../components/Tooltip';
import { Button } from '../../../../components/Button';

export const Header = ({ filterOpenButton }) => {
  const { openModal } = useModal();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { resetTimeReports } = useContext(TimeReportsContext);
  const currentUser = useSelector(({ profile }) => profile.user);
  const initialValues = {
    company_id: hasRole(rolesMap.admin) ? null : currentUser.work?.company_id,
    user_id: hasRole(rolesMap.admin, rolesMap.supervisor) ? null : currentUser.id,
    supervisor_id: hasRole(rolesMap.supervisor) ? currentUser.id : null
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const createReport = () => {
    openModal(TimeReportCreationModal, {
      payload: {
        initialValues
      },

      onModalResolved: () => {
        resetTimeReports();
      }
    });
  };

  return (
    <PageHeader>
      <PageHeaderTitle>
        Time Reports
      </PageHeaderTitle>

      <PageHeaderActions>
        {hasRole(rolesMap.admin, rolesMap.supervisor) && (
          <Button
            size={isMobile ? 'small' : 'medium'}
            variant="contained"
            color="primary"
            onClick={createReport}
          >
            Create report
          </Button>
        )}

        {isMobile &&
          <Box px={1}>
            {filterOpenButton}
          </Box>
        }

        {!(isMobile || isTablet) &&
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      </PageHeaderActions>
    </PageHeader>
  );
};
