import { useContext, useEffect, useState } from 'react';
import { Box, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { FinancialClassesSelect, CompaniesAutocomplete, TextField } from '../../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { useDebounce } from '../../../../../../helpers/hooks';
import { CodesContext } from '../CodesContext';

const initialValues = {
  code: null,
  company_id: null,
  address_1: null,
  main_phone: null,
  financial_class_id: [],
  professional_edi: null
};

export const FiltersBar = () => {
  const { applyFilter } = useContext(CodesContext);
  const [ fields, setFields ] = useState(null);
  const debouncedFields = useDebounce(fields, 600);

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
  };

  const handleMultiSelectChange = (name) => (options) => {
    setFields({ ...fields, [name]: !!options.length ? options.map(({ value }) => value) : null });
  };

  const handleCompanyChange = (company) => {
    setFields({ ...fields, company_id: company?.id || null });
  };

  useEffect(() => {
    applyFilter(debouncedFields);
  }, [ debouncedFields ]);

  return (
    <FiltersBarComponent
      disableTopPaddings
      isResetForm
      initialValues={initialValues}
      fields={[
        <TextField
          label="Code"
          margin="dense"
          name="code"
          placeholder="Search..."
          onChange={handleFieldChange('code')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />,
        <Box minWidth={200}>
          <CompaniesAutocomplete
            label="Payer Name"
            name="company_id"
            margin="dense"
            onChange={handleCompanyChange}
          />
        </Box>,
        <TextField
          label="Address"
          name="address_1"
          placeholder="Search..."
          onChange={handleFieldChange('address_1')}
          margin="dense"
        />,
        <TextField
          label="Phone"
          name="main_phone"
          placeholder="Search..."
          onChange={handleFieldChange('main_phone')}
          margin="dense"
        />,
        <FinancialClassesSelect
          isMulti
          label="Financial class"
          name="financial_class_id"
          maxWidth={250}
          onChange={handleMultiSelectChange('financial_class_id')}
          TextFieldProps={{ margin: 'dense' }}
        />,
        <TextField
          label="Claim EDI No.Prof"
          name="professional_edi"
          placeholder="Search..."
          onChange={handleFieldChange('professional_edi')}
          margin="dense"
        />
      ]}
    />
  );
};
