import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Typography } from '@material-ui/core';
import * as eventsApi from '../../../../../../api/schedule-events';
import * as emailsApi from '../../../../../../api/emails';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Contact } from '../../../../../../components/Contact';
import { Date } from '../../../../../../components/Date';
import { selectOptions } from '../../../../../../components/RecurrenceSelect';
import {
  CreateScheduleEventModal,
  eventConfirmationMap
} from '../../../../../../components/schedule-events';
import { EmailEventConfirmation } from './EmailEventConfirmation';
import { ScheduleDate } from './ScheduleDate';
import { statusMap } from './statusMap';

export const ScheduleContent = ({ messageItem = {}, schedule = [] }) => {
  const { openModal } = useModal();
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const confirmEvent = ({ value, scheduleItem }) => () => {
    setIsSubmitting(false);

    const data = {
      id: +scheduleItem?.id,
      recurring_rule: scheduleItem?.recurrence_rule,
      confirmation: value
    };

    eventsApi.confirmEvent(data).then(() => {
      setIsSubmitting(false);
      enqueueSnackbar('Successfully confirmed', { variant: 'success' });
    });
  };

  const createEvent = ({ value, scheduleItem }) => () => {
    setIsSubmitting(true);

    if (value !== eventConfirmationMap.no) {
      openModal(CreateScheduleEventModal, {
        payload: {
          initialValues: {
            started_at: scheduleItem?.start || null,
            finished_at: scheduleItem?.end || null,
            title: scheduleItem?.name || null,
            description: scheduleItem?.description || null,
            recurring_rule: selectOptions?.find(({ value }) => (
              value?.includes(scheduleItem.recurring_rule))
            )?.value || null
          }
        },
        onModalResolved: () => {
          replyEvent({ value, scheduleItem });
        },
        onModalRejected: () => {
          setIsSubmitting(false);
        }
      });
    } else {
      replyEvent({ value, scheduleItem });
    }
  };

  const replyEvent = ({ value, scheduleItem }) => {
    setIsSubmitting(false);

    const reply = {
      config_id: messageItem?.config_id,
      subject: scheduleItem?.name,
      to: [ scheduleItem?.organizer?.email ],
      status: statusMap?.[value],
      event_uid: scheduleItem?.id,
      event_start_date: scheduleItem.start
    };

    emailsApi.replyEvent(reply).then(() => {
      setIsSubmitting(false);
      enqueueSnackbar('Successfully reply', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Not reply', { variant: 'error' });
    });
  };

  return !!schedule?.length && schedule?.map((scheduleItem, index) => (
    <Box
      key={index}
      display="flex"
      alignItems="stretch"
      my={3}
      border={1}
      borderColor="grey.400"
    >
      <ScheduleDate date={scheduleItem?.start} />

      <Box flexGrow={1} p={2} pb={1}>
        <Typography gutterBottom variant="h6">{scheduleItem?.name || '-'}</Typography>

        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box component="span" mr={2}>
            <Typography color="textSecondary">When:</Typography>
          </Box>

          <Date disableIcon variant="h5" date={scheduleItem.start} format="MM/DD/YYYY" />

          <Date
            disableDateIcon
            iconColor="info"
            variant="h5"
            format="LT"
            iconProps={{ ml: 1, mr: 0.5 }}
            date={scheduleItem.start}
          />

          <Box component="span" mx={1}>-</Box>

          <Date disableIcon variant="h5" date={scheduleItem.end} format="MM/DD/YYYY" />

          <Date
            disableDateIcon
            iconColor="info"
            variant="h5"
            format="LT"
            iconProps={{ ml: 1, mr: 0.5 }}
            date={scheduleItem.end}
          />
        </Box>

        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box component="span" mr={2}>
            <Typography color="textSecondary">Who:</Typography>
          </Box>

          <Contact variant="h5" type="mailto" contact={scheduleItem?.organizer?.email}/>

          {scheduleItem?.organizer?.name &&
            <Typography>({scheduleItem?.organizer?.name})</Typography>
          }
        </Box>

        <Box display="flex" alignItems="center" mb={1}>
          <Box component="span" mr={2}>
            <Typography color="textSecondary">Recurrence:</Typography>
          </Box>

          <Typography variant="h5">
            {selectOptions?.find(({ value }) => (value?.includes(scheduleItem.recurring_rule)))?.title ||
              'No repeat'
            }
          </Typography>
        </Box>

        <EmailEventConfirmation
          scheduleItem={scheduleItem}
          isSubmitting={isSubmitting}
          onConfirmEvent={scheduleItem?.is_system ? confirmEvent : createEvent}
        />
      </Box>
    </Box>
  ));
};
