import { makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { mdiDomain } from '@mdi/js';
import { Icon } from '@mdi/react';
import EditIcon from '@material-ui/icons/EditOutlined';
import { IconButton } from '../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { EditOfficeModal } from '../../../EditOfficesModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ office, setOffice }) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const handleUpdateOffice = () => {
    openModal(EditOfficeModal, {
      payload: {
        id: office.id
      },
      onModalResolved: (data) => {
        setOffice(data);
      }
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.logoIcon}>
          <SvgIcon className={classes.icon}>
            <Icon path={mdiDomain} />
          </SvgIcon>
        </div>

        <Typography noWrap color="inherit" variant="h2" title={office.name}>
          {office.name}
        </Typography>
      </div>

      <IconButton className={classes.action} onClick={handleUpdateOffice}>
        <EditIcon />
      </IconButton>
    </div>
  );
};
