import { makeStyles, Paper } from '@material-ui/core';
import { Body } from './Body';
import { Header } from './Header';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const UserInfo = ({ contact }) => {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.root}>
      <Header contact={contact} />
      <Body contact={contact} />
    </Paper>
  );
};
