export const styles = ({
  spacing,
  palette: { grey },
  typography: { pxToRem },
  shape: { borderRadius }
}) => ({
  description: {
    marginTop: spacing(2)
  },

  input: {
    color: grey[600],
    fontSize: pxToRem(14),
    lineHeight: pxToRem(21),
    flexGrow: 1,
    borderRadius
  }
});
