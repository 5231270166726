import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';
import filesize from 'filesize';
import {
  Dialog,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  useTheme,
  ListItemText,
  useMediaQuery,
  ListItemSecondaryAction
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { CasesSelect } from '../../../../../../components/cases/CasesSelect';
import { CaseUsersSelect } from '../../../../../../components/cases/CaseUsersSelect';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { setExpensesLastGlobalAction, setFilesLastGlobalAction } from '../../../../../../store/globalActions';
import { transformToOption } from '../../../../../../helpers/fetchDataForAsyncSelect';
import { getAggregateTypeFromMIME } from '../../../../../../helpers/files';
import * as expensesApi from '../../../../../../api/billing';
import * as filesApi from '../../../../../../api/files';
import { hasRole } from '../../../../../../utils/hasRole';
import { FileTypeIcon, FileValidationConflictModal } from '../../../../files-common';
import { ExpensesCategoriesSelect } from '../../../../../../components/billing';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { IconButton } from '../../../../../../components/IconButton';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Loader } from '../../../../../../components/Loader';
import { FileDropZone } from '../../../../../../components/FileDropZone';
import {
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalContainer
} from '../../../../../../components/Modal';
import {
  CurrencyField,
  KeyboardDateTimePicker,
  TextField
} from '../../../../../../components/FormField';
import * as filesTypes from '../../../FilesContext/types';
import * as types from '../ExpensesContext/types';
import { validationSchema } from './validationSchema';

export const ExpensesModal = ({
  DialogProps,
  payload: {
    initialValues = {}
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { openModal } = useModal();
  const [ user, setUser ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const [ selectedFiles, setSelectedFiles ] = useState([]);
  const [ deletedFiles, setDeletedFiles ] = useState([]);

  const handleDeleteFile = (file, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        if (selectedFiles[index]) {
          setSelectedFiles(selectedFiles.filter((item, i) =>i !== index));
          setDeletedFiles([ ...deletedFiles, file ]);
        }
      }
    });
  };

  const uploadFiles = ({
    owner_id,
    owner_type,
    selectedFiles,
    setSubmitting,
    setErrors
  }  = {} ) => {
    selectedFiles.forEach((file) => {
      const formData = new FormData();

      formData.append('file', file, file.original_filename);

      file.requestData = formData;

      expensesApi.uploadFile(formData, { params: { owner_id, owner_type } }).then((data) => {
        dispatch(setFilesLastGlobalAction({
          type: filesTypes.FILE_UPLOAD_SUCCESS,
          payload: {
            expenseID: initialValues.expense?.id,
            file: data
          }
        }));
        enqueueSnackbar('File successfully uploaded to expenses', { variant: 'success' });
      }).catch(({ status, data: { errors } = {} }) => {
        setSubmitting(false);

        if (status === 422 && errors) {
          setErrors(errors);
          enqueueSnackbar('File didn\'t upload to expenses', { variant: 'error' });
        }
      });
    });
  };

  const validateFile = (file) => {
    file.original_filename = file.original_filename || file.name;

    if (selectedFiles.find(({ original_filename }) => original_filename === file.original_filename)) {
      openModal(FileValidationConflictModal, {
        payload: { file },
        onModalResolved: (file) => {
          validateFile(file);
        }
      });
    } else {
      filesApi.validateFile(file).then(() => {
        file.aggregate_type = getAggregateTypeFromMIME(file.type);

        setSelectedFiles((state) => [ file ].concat(state));
      }).catch(({ status }) => {
        if (status === 409) {
          openModal(FileValidationConflictModal, {
            payload: { file },
            onModalResolved: (file) => {
              validateFile(file);
            }
          });
        } else {
          enqueueSnackbar(`File "${file.original_filename}" is invalid`, {
            variant: 'error'
          });
        }
      });
    }
  };

  const createExpense = (expenseData, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    expenseData.user = get(expenseData, 'user_id.label', user);
    expenseData.category_id = get(expenseData, 'category_id.value', expenseData.category_id);
    expenseData.category = get(expenseData, 'category_id.label');
    expenseData.files = get(expenseData, 'files', []);
    expenseData.budget = get(expenseData, 'budget', 0);
    expenseData.amount = get(expenseData, 'amount', 0);

    if (initialValues.parentPage) {
      expensesApi.createExpense(expenseData).then((data) => {
        if (selectedFiles.length > 0) {
          uploadFiles({
            owner_id: data.id,
            owner_type: 'expense',
            selectedFiles,
            setSubmitting,
            setErrors
          });
        }

        enqueueSnackbar('Expense successfully created', { variant: 'success' });
        handleModalResolve(data);
      }).catch(({ errors }) => {
        setErrors(errors);
        setSubmitting(false);
        enqueueSnackbar('Expense not updated', { variant: 'error' });
      });
    } else {
      handleModalResolve(expenseData);
      enqueueSnackbar('Expense successfully added', { variant: 'success' });
    }
  };

  const updateExpense = (expenseData, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    expenseData.category_id = get(expenseData, 'category_id.value', expenseData.category_id);
    expenseData.user_id = get(expenseData, 'user_id.value', expenseData.user_id) || expenseData.user.id;
    expenseData.user = get(expenseData, 'user_id.label', user);
    expenseData.category = get(expenseData, 'category_id.label');

    expensesApi.updateExpense(expenseData).then((data) => {
      if (selectedFiles.length > 0) {
        const files = selectedFiles.filter(({ id }) => !id);

        if (files.length > 0) {
          uploadFiles({
            owner_id: data.id,
            owner_type: 'expense',
            selectedFiles: files,
            setSubmitting,
            setErrors
          });
        }
      }

      dispatch(setExpensesLastGlobalAction({
        type: types.UPDATE_EXPENSE_IN_LIST,
        payload: { files: initialValues.expense, ...data }
      }));

      if (deletedFiles.length > 0) {
        const deletedFilesIDs = deletedFiles.map(({ id }) => id);

        expensesApi.deleteFiles(deletedFilesIDs).then(() => {
          dispatch(setFilesLastGlobalAction({
            type: filesTypes.DELETE_FILES,
            payload: {
              expenseID: initialValues.expense?.id,
              deletedFilesIDs
            }
          }));
          enqueueSnackbar(`${deletedFiles.length} file successfully deleted`, { variant: 'success' });
        });
      }

      enqueueSnackbar('Expense successfully updated', { variant: 'success' });
      handleModalResolve();
    }).catch(({ errors }) => {
      setErrors(errors);
      setSubmitting(false);
      enqueueSnackbar('Expense not updated', { variant: 'error' });
    }).then(() => {
      setSubmitting(false);
    });
  };

  const handleDropAccepted = (files) => {
    files.forEach((file) => {
      validateFile(file);
    });
  };

  useEffect(() => {
    setIsFetched(false);

    if (!initialValues.expense?.id) {
      setSelectedFiles(get(initialValues, 'expense.files', []));
      setIsFetched(true);
    } else {
      expensesApi.fetchExpense(initialValues.expense?.id).then((data) => {
        setSelectedFiles(data.files);
        setIsFetched(true);
      });
    }
  }, []);

  return (
    <Dialog fullScreen={isMobile} {...DialogProps}>
      <Formik
        initialValues={{
          id: initialValues.expense?.id,
          case_id: initialValues?.caseID || initialValues?.expense?.case || null,
          invoice_id: initialValues.invoice_id,
          category_id: transformToOption(initialValues?.expense?.category),
          user_id: initialValues?.expense?.user,
          notes: initialValues?.expense?.notes || null,
          budget: initialValues?.expense?.budget || null,
          amount: initialValues?.expense?.amount || null,
          date: initialValues?.expense?.date || null
        }}
        validationSchema={validationSchema}
        onSubmit={!initialValues.expense ? createExpense : updateExpense}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              {initialValues.title}
            </ModalHeader>

            <ModalBody>
              <Loader loading={!isFetched} p={2} render={
                () => (
                  <Grid container spacing={isMobile ? 1 : 2}>
                    {!initialValues?.disableCaseField && (
                      <Grid item xs={12}>
                        <CasesSelect
                          name="case_id"
                          label="Case"
                          placeholder="Select case..."
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <CaseUsersSelect
                        required
                        name="user_id"
                        label="Contact"
                        onChange={setUser}
                      />
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 6}>
                      <KeyboardDateTimePicker
                        required
                        maxDate={moment().unix()}
                        name="date"
                        label="Date"
                      />
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 6}>
                      <ExpensesCategoriesSelect
                        isClearable
                        name="category_id"
                        label="Category"
                        TextFieldProps={{ required: true }}
                      />
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 6}>
                      <CurrencyField
                        required
                        name="budget"
                        label="Budget"
                      />
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 6}>
                      <CurrencyField
                        required
                        name="amount"
                        label="Amount"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <List>
                        {selectedFiles.map((file, index) => (
                          <Fragment key={index}>
                            {index > 0 && <Divider />}

                            <ListItem disableGutters>
                              <FileTypeIcon
                                file={{
                                  aggregate_type: getAggregateTypeFromMIME(file.type),

                                  ...file
                                }}
                              />

                              <ListItemText
                                primary={file.original_filename || file.name}
                                primaryTypographyProps={{ noWrap: true }}
                              />

                              <ListItemText align="right" primary={filesize(file.size)} />

                              {!hasRole(rolesMap.advocate) && (
                                <ListItemSecondaryAction>
                                  <IconButton edge="end" color="error" onClick={handleDeleteFile(file, index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              )}
                            </ListItem>
                          </Fragment>
                        ))}
                      </List>
                    </Grid>

                    <Grid item xs={12}>
                      <FileDropZone multiple onDropAccepted={handleDropAccepted}/>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        multiline
                        rows={4}
                        rowsMax={10}
                        name="notes"
                        label="Notes"
                        variant="filled"
                        placeholder="Add notes..."
                      />
                    </Grid>
                  </Grid>
                )}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
