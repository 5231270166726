import { Yup } from '../../../utils/validation';

export const validationSchema = Yup.object().shape({
  email: Yup.string().nullable().required('Files is required'),
  password: Yup.string().nullable().required('Password is required').min(8).max(32),
  password_confirmation: Yup.string().nullable().when('password', {
    is: (val) => (!!(val && val.length > 0)),
    then: Yup.string().nullable().oneOf([ Yup.ref('password') ], 'Passwords must match')
  })
});
