import { DialogTitle, Dialog, DialogContent } from '@material-ui/core';

export const PrintConfirmationModal = ({ open }) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      open={open}
    >
      <DialogContent>
        <DialogTitle>Preparing a Document for Printing...</DialogTitle>
      </DialogContent>
    </Dialog>
  );
};
