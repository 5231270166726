import moment from 'moment';
import { useContext, useState } from 'react';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { ColorPreview } from '../../../../../../components/ColorPreview';
import { IconButton } from '../../../../../../components/IconButton';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import { Tooltip } from '../../../../../../components/Tooltip';
import { TypesContext } from '../../TypesProvider';
import { columnsWidths } from '../List';

export const Row = ({ type = {} }) => {
  const [ isBusy, setIsBusy ] = useState(false);
  const { deleteType, updateType } = useContext(TypesContext);
  const taskTypesContext = useContext(TypesContext);

  const toggleTypeActive = () => {
    const data = { ...type, is_active: !type.is_active };

    setIsBusy(true);

    taskTypesContext.toggleTypeActive(data).finally(() => {
      setIsBusy(false);
    });
  };

  const handleTypeDelete = () => {
    setIsBusy(true);

    deleteType(type).finally(() => {
      setIsBusy(false);
    });
  };

  const handleTypeUpdate = () => {
    updateType(type);
  };

  return (
    <Loader fullWidth surface loading={isBusy} render={
      () => (
        <ListRow disabled={!type.is_active}>
          <ListRowCell grow actions flexBasis={columnsWidths.name}>
            <ColorPreview color={type.color} />

            <span>
              {type.name}
            </span>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.createdDate}>
            {!type.is_system && moment.unix(type.created_at).format('L LT')}
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.status}>
            {type.is_system && 'System'}
          </ListRowCell>

          <ListRowCell noDisable flexBasis={columnsWidths.actions}>
            {!type.is_system &&
              <ListRowCellActions>
                <Tooltip title="Edit type">
                  <IconButton
                    color="info"
                    onClick={handleTypeUpdate}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={type.is_active ? 'Deactivate' : 'Activate'}>
                  <IconButton
                    variant="contained"
                    color={type.is_active ? 'warning' : 'success'}
                    onClick={toggleTypeActive}
                  >
                    {type.is_active ? <LockIcon /> : <LockOpenIcon />}
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={type?.is_used
                    ? `Type "${type?.name}" is in use, you can't remove it.`
                    : 'Delete type'
                  }
                >
                  <div>
                    <IconButton
                      disabled={type?.is_used}
                      color="error"
                      onClick={handleTypeDelete}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </ListRowCellActions>
            }
          </ListRowCell>
        </ListRow>
      )}
    />
  );
};
