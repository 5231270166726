import { Grid } from '@material-ui/core';
import * as casesApi from '../../../api/cases';
import { Avatar } from '../../Avatar';
import { Autocomplete } from '../../FormField';

const fetchCases = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return casesApi.fetchCases({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {

    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => {
  return (
    <Grid container wrap="nowrap" spacing={1} alignItems="center">
      <Grid item>
        <Avatar alt={option?.name?.[0]} src={option?.avatar} size="sm" />
      </Grid>

      <Grid item>
        {option.name}
      </Grid>
    </Grid>
  );
};

export const CasesSelect = ({ multiple = false, params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      multiple={multiple}
      placeholder="Search case..."
      onNeedFetch={fetchCases(params)}
      renderOption={renderOption}
      getInputProps={(value) => !(!multiple && value) ? null : ({
        startAdornment: (
          <Avatar alt={value?.name?.[0]} src={value?.avatar} size="sm" />
        )
      })}

      getOptionLabel={(caseItem) => caseItem?.name}
      getOptionValue={(caseItem) => caseItem?.id}
      getOptionSelected={(option, value) => option.id === value?.id}

      {...props}
    />
  );
};
