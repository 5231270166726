import { PURGE } from 'redux-persist';
import { generateUID } from '../../helpers/generateUID';
import { createReduxReducer } from '../../helpers/createReduxReducer';

// Action types
const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

// Action Creators
export const enqueueSnackbar = (message, options) => (dispatch) => {
  const key = generateUID();

  dispatch({
    type: ENQUEUE_SNACKBAR,
    payload: {
      key,
      snackbar: { message, options }
    }
  });

  return key;
};

export const removeSnackbar = (key) => ({ type: REMOVE_SNACKBAR, payload: key });

// Reducer
const initialState = {};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [ENQUEUE_SNACKBAR]: (state, { key, snackbar }) => {
    return { ...state, [key]: snackbar };
  },

  [REMOVE_SNACKBAR]: (state, payload) => {
    const oldState = { ...state };

    delete oldState[payload];

    return oldState;
  }
});
