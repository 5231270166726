export const styles = ({
  spacing,
  palette,
  shape: { borderRadius },
  typography: { fontWeightMedium, pxToRem },
  breakpoints
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    flexGrow: 1,
    padding: 0,

    [breakpoints.up('md')]: {
      padding: spacing(0, 3)
    }
  },

  datepickerWrapper: {
    minWidth: 335
  },

  datepicker: {
    maxWidth: 320,
    padding: spacing(0.5),
    border: `1px solid ${palette.divider}`,
    borderRadius
  },

  apptList: {
    border: `1px solid ${palette.divider}`,
    borderBottom: 'none'
  },

  contentText: {
    color: palette.grey[500]
  },

  timeContent: {
    paddingLeft: spacing(2),
    display: 'flex',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },

  timeInfo: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(3)
  },

  timeField: {
    minWidth: 0,
    width: 70,
    marginLeft: spacing(1.5),
    marginRight: spacing(1.5)
  },

  painContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    paddingLeft: spacing(2)
  },

  painInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: spacing(2),
    maxWidth: 120
  },

  painInfo_success: {
    color: palette.success.main
  },

  painInfo_error: {
    color: palette.error.main
  },

  label: {
    marginBottom: spacing(2),
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(14),
    color: palette.grey[800]
  }
});
