export const styles = ({
  spacing,
  palette: { primary },
  shape: { borderRadius },
  shadows
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
    flexGrow: 1,
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
    backgroundColor: primary.contrastText,
    boxShadow: shadows[2],
    borderRadius
  }
});
