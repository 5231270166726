import { useContext } from 'react';
import { Typography, List as MuiList, Box } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Loader } from '../../../../../../components/Loader';
import { Fab } from '../../../../../../components/Fab';
import { CertificateSignatureModal } from '../CertificateSignatureModal';
import { CertificateUploadModal } from '../CertificateUploadModal';
import { CertificatesContext } from '../CertificatesContext';
import { Row } from './Row';

export const minRowHeight = 54;
export const columnsWidths = {
  name: 270
};

export const Certificates = () => {
  const {
    isFetching,
    isFetched,
    certificates,
    ...certificatesContext
  } = useContext(CertificatesContext);
  const { openModal } = useModal();

  const addCertificate = () => {
    openModal(CertificateSignatureModal, {
      payload: { certificatesContext }
    });
  };

  const uploadFiles = () => {
    openModal(CertificateUploadModal, {
      payload: {
        onUpload: certificatesContext.uploadCertificate
      }
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box display="flex" alignItems="center" mt={2}>
        <Typography variant="h5">Certificate SSL</Typography>

        <Box component="span" mx={2}>
          <Fab
            size="small"
            color="primary"
            variant="extended"
            startIcon={<AddIcon/>}
            onClick={addCertificate}
          >
            Add
          </Fab>
        </Box>

        <Fab
          size="small"
          color="primary"
          variant="extended"
          startIcon={<CloudUploadIcon/>}
          onClick={uploadFiles}
        >
          Upload
        </Fab>
      </Box>

      <Box py={3} flexGrow={1}>
        <Loader p={3} loading={!isFetched} render={
          () => (!certificates.length ?
            <Box p={2}>
              <Typography align="center">No certificates found</Typography>
            </Box>
            :
            <MuiList disablePadding>
              {certificates.map((certificate) => (
                <Row key={certificate.id} certificate={certificate} />
              ))}
            </MuiList>
          )}
        />
      </Box>
    </Box>
  );
};
