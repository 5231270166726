import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, FormControl, FormControlLabel } from '@material-ui/core';
import * as eventsApi from '../../../api/schedule-events';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../Modal';
import { Loader } from '../../Loader';
import { RadioGroup, Radio } from '../../FormField';

const movingVariantsMap = {
  thisOnly: 'thisOnly',
  thisAndFollowings: 'thisAndFollowings',
  all: 'all'
};

export const DeleteScheduleEventModal = ({
  DialogProps,
  payload: { event },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteEvent = ({ variant }, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const isSingle = variant === movingVariantsMap.thisOnly;
    const method = isSingle ? 'deleteEvent' : 'deleteRecurringTemplate';

    eventsApi[method](isSingle ? event.id : event.recurring_template_id).then(() => {
      enqueueSnackbar('Event deleted', { variant: 'success' });
      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Something went wrong. Try again later...', { variant: 'error' });
      handleModalReject();
    });
  };

  return (
    <Formik
      initialValues={{
        variant: movingVariantsMap.thisOnly
      }}
      onSubmit={deleteEvent}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="xs" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Delete recurring event
            </ModalHeader>

            <ModalBody>
              <FormControl fullWidth component="fieldset" margin="dense">
                <RadioGroup name="variant">
                  <FormControlLabel
                    value={movingVariantsMap.thisOnly}
                    label="This event"
                    control={<Radio name="variant" color="primary" />}
                  />

                  <FormControlLabel
                    value={movingVariantsMap.thisAndFollowings}
                    label="This and following events"
                    control={<Radio name="variant" color="primary" />}
                  />

                  <FormControlLabel
                    value={movingVariantsMap.all}
                    label="All events"
                    control={<Radio name="variant" color="primary" />}
                  />
                </RadioGroup>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Delete
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
