import { Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths, columnsWidthsSM } from '../List';

export const TableHeader = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const rowWidth = isMobile ? columnsWidthsSM : columnsWidths;

  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell grow flexBasis={rowWidth.email}>
          Email
        </ListRowCell>

        <ListRowCell flexBasis={rowWidth.createDate}>
          Create Date
        </ListRowCell>

        <ListRowCell flexBasis={rowWidth.expireDate}>
          Expire Date
        </ListRowCell>

        {!isTablet && (
          <ListRowCell flexBasis={columnsWidths.confirmDate}>
            Confirm Date
          </ListRowCell>
        )}

        <ListRowCell flexBasis={rowWidth.actions} />
      </ListRow>
    </Paper>
  );
};
