import { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import { getReportsRoutes } from '../../app/Dashboard/MedicalReports';
import { useResizeObserver } from '../../helpers/hooks/useResizeObserver';
import { Tabs, Tab } from '../Tabs';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MedicalReportsTabs = ({ pathname }) => {
  const classes = useStyles();
  const location = useLocation();
  const routes = getReportsRoutes(pathname);
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });

  const getActiveRoute = () => {
    const route = routes.find(({ path }) => location.pathname === path);
    const path = location.pathname.includes('/diagnosis') ? pathname + '/diagnosis' : pathname;

    return route ? route.path : path;
  };

  return (
    <Box ref={rootRef} pt={1} pl={1} pr={1}>
      <Tabs
        variant={width > 1360 ? 'fullWidth' : 'scrollable'}
        value={getActiveRoute()}
        className={classes.root}
      >
        {routes.map(({ path, label }) => (
          <Tab
            key={path}
            disableFocusRipple
            component={Link}
            to={path}
            value={path}
            label={label}
            className={classes.tab}
          />
        ))}
      </Tabs>
    </Box>
  );
};
