import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { fetchConfig } from '../../store/api-config';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { renderRoutes } from '../../components/router';
import { Loader } from '../../components/Loader';
import { ArchiveDownloadWidget } from './files-common';
import { Notifications } from './Notifications';
import { Layout } from './Layout';
import { routes } from './routes';

export const Dashboard = () => {
  const apiConfigIsFetched = useSelector(({ apiConfig }) => apiConfig.isFetched);
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConfig());
  }, [ isAuthenticated ]);

  return !apiConfigIsFetched ? <Loader p={3} /> : (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        overflow="hidden"
        height="100%"
        flexGrow={1}
      >
        {renderRoutes(routes)}
      </Box>

      {isAuthenticated &&
        <ErrorBoundary>
          <ArchiveDownloadWidget />
          <Notifications />
        </ErrorBoundary>
      }
    </Layout>
  );
};
