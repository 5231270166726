export const styles = ({ spacing }) => ({
  root: {
    paddingLeft: 0,
    paddingRight: spacing(3),
    minHeight: 48,
    width: 270
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  title: {
    width: 100
  }
});
