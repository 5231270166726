import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import { useModal } from '../../../ModalsProvider';
import { IconButton } from '../../../IconButton';
import { Tooltip } from '../../../Tooltip';
import { VoiceRecognitionModal } from './VoiceRecognitionModal';

export const VoiceRecognitionButton = ({ onTextRecognized }) => {
  const { openModal } = useModal();

  const toggleSpeechRecognition = () => {
    openModal(VoiceRecognitionModal, {
      onModalResolved: (recognizedText) => {
        onTextRecognized(recognizedText);
      }
    });
  };

  return (
    <Tooltip title="Start speech recognition">
      <IconButton onClick={toggleSpeechRecognition}>
        <RecordVoiceOverOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
};
