import { api } from '../../api';

export const fetchMedications = (config) => {
  return api.get('/medical-report/prescriptions', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchMedication = (id, config) => {
  return api.get(`/medical-report/prescriptions/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createMedication = (data) => {
  return api.post('/medical-report/prescriptions', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const massCreateMedications = (data) => {
  return api.post('/medical-report/prescriptions/bulk', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMedication = ({ id, ...data }) => {
  return api.put(`/medical-report/prescriptions/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteMedication = (id) => {
  return api.delete(`/medical-report/prescriptions/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteMedications = (IDs) => {
  return api.delete('/medical-report/prescriptions', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
