import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.code}>
          Code
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.description}>
          Description
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.duration}>
          Duration
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.claimRequired}>
          Claim required
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.ordering}>
          Ordering
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
