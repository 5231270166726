import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { flow } from 'lodash';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as servicesApi from '../../../../api/login-queues/services';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { EditServiceModal } from '../EditServiceModal';
import { useModal } from '../../../ModalsProvider';
import { LoginServicesContext } from '../LoginServicesProvider';

export const Menu = ({ service, onClose = () => {} }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { updateLoginService, deleteLoginService } = useContext(LoginServicesContext);

  const editService = () => {
    openModal(EditServiceModal, {
      payload: {
        service
      },

      onModalResolved: (service) => {
        updateLoginService(service);
      }
    });
  };

  const handleServiceDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        servicesApi.deleteService(service.id).then(() => {
          enqueueSnackbar('Service successfully deleted', { variant: 'success' });
          deleteLoginService(service);
        }).catch(() => {
          enqueueSnackbar('Service not deleted', { variant: 'error' });
        });
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={flow(editService, onClose)}>
        <ListItemIcon>
          <EditIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={flow(handleServiceDelete, onClose)}>
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
