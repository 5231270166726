import { createContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isEqual, merge, omit } from 'lodash';
import { usePrevious } from '../../../../helpers/hooks';
import { updateUserStorage } from '../../../../store/globalUser/operations';
import { api } from '../../../../api';
import * as userApi from '../../../../api/users';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useModal } from '../../../../components/ModalsProvider';
import { initialState, reducer } from '../../Members';
import * as types from '../../Members/UsersContext/types';

export const PatientsContext = createContext(null);

export const PatientsProvider = ({ children, initialState: initialStateProp = {} }) => {
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();
  const storageInfo = useSelector(({ globalUser }) => globalUser?.data);
  const usersLastGlobalAction = useSelector(({ globalActions }) => globalActions.usersLastGlobalAction);
  const {
    filter,
    users,
    pagination,
    isFetching,
    isFetched,
    selectedIDs
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchPatients = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.USERS_FETCH_REQUEST });

    userApi.fetchPatients({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.USERS_FETCH_SUCCESS, payload: data });
    });
  };

  const resetUsers = (newFilter) => {
    dispatch({ type: types.RESET_USERS, payload: newFilter });

    fetchPatients({ page: 1, ...newFilter });
  };

  const deleteUser = (user) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        userApi.deleteUser(user.id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchPatients({ page: filter.page });
          } else if (users.length - 1 === 0 && filter.page !== 1) {
            fetchPatients({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_USER_SUCCESS, payload: user.id });
          }

          dispatchRedux(updateUserStorage({
            users: storageInfo?.users?.filter(({ id }) => id !== user.id)
          }));
          enqueueSnackbar('Patient successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Patient is not deleted!', { variant: 'error' });
        });
      }
    });
  };

  const updateSipUserInfo = (user) => {
    dispatch({ type: types.UPDATE_USER_IN_LIST, payload: user });
  };

  const massDeleteUsers = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        userApi.massDeleteUsers(IDs).then(() => {
          IDs.forEach((id) => toggleItemSelection(id));

          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            fetchPatients({ page: filter.page });
          } else if (users.length - selectedIDs.length === 0 && filter.page !== 1) {
            fetchPatients({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.MASS_DELETE_USERS, payload: IDs });
          }

          dispatchRedux(updateUserStorage({
            users: storageInfo?.users.filter((user) => IDs.indexOf(user.id) < 0)
          }));
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Users are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const creatBaseUser = (user) => {
    dispatch({ type: types.ADD_USER, payload: user });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const allUsersIsSelected = () => {
    return users.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_USER_SELECTION, payload: id });
  };

  const toggleAllUsersSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_USERS_SELECTION });
  };

  useEffect(() => {
    usersLastGlobalAction && dispatch(usersLastGlobalAction);
  }, [ usersLastGlobalAction ]);

  const providerValue = {
    users,
    selectedIDs,
    isFetching,
    isFetched,
    filter: {
      ...filter,
      ...pagination
    },
    pagination,

    // functions
    creatBaseUser,
    updateSipUserInfo,
    applyFilter,
    deleteUser,
    fetchPatients,
    resetUsers,
    massDeleteUsers,
    allUsersIsSelected,
    toggleAllUsersSelection,
    toggleItemSelection
  };

  useEffect(() => {
    const newFilter = { ...filter, ...initialStateProp.filter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, initialStateProp.filter ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetUsers();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <PatientsContext.Provider value={providerValue}>
      {children}
    </PatientsContext.Provider>
  );
};
