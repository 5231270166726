export const styles = ({
  spacing,
  palette,
  typography: { pxToRem }
}) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'nowrap'
  },

  icon: {
    fontSize: pxToRem(14),
    marginRight: spacing(0.5),
    color: palette.text.secondary
  },

  title: {
    fontSize: pxToRem(12),
    color: palette.text.secondary
  }
});
