export const initialValues = {
  first_name: null,
  last_name: null,
  email: null,
  phone_number: null,
  company_name: null,
  number_of_employees: null,
  industry: null,
  title: null,
  company_revenue: null
};
