export const initialValues = {
  login_queue_id: null,
  name: null,
  description: null,
  users: null,
  office_id: null,
  login: null,
  password: null,
  login_url: null
};
