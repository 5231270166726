import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as casesApi from '../../../../api/cases';
import { Autocomplete } from '../../Autocomplete';

const renderOption = (status) => {
  return (
    <Typography>{status?.name}</Typography>
  );
};

export const CasesStatusesSelect = (props) => {
  const [ statuses, setStatuses ] = useState([]);

  useEffect(() => {
    casesApi.fetchStatuses().then(({ data }) => {
      setStatuses(data);
    });
  }, []);

  return (
    <Autocomplete
      label="Case Status"
      placeholder="Search status..."
      options={statuses}
      renderOption={renderOption}
      getOptionLabel={(status) => status?.name}
      getOptionValue={(status) => status?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}

      {...props}
    />
  );
};
