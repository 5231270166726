import { makeStyles } from '@material-ui/core';
import * as pages from '../../../../../../store/dashboard/layout/pages';
import { Widgets } from '../../../../../../components/Widgets';
import { widgetsMap } from '../Widgets/widgetsMap';
import { cardsLayout, checkedWidgetsTypes, extraTypes } from './Layouts';
import { RemindersStatusBar } from './RemindersStatusBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CaseItem = ({
  caseItem,
  widgetFilters = {},
  onCaseItemUpdate,
  updateFilters = () => {}
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Widgets
        caseItem={caseItem}
        onCaseItemUpdate={onCaseItemUpdate}
        widgetFilters={widgetFilters}
        updateFilters={updateFilters}
        actionsBarLeftContent={
          <RemindersStatusBar caseId={caseItem?.id} />
        }

        // widgets
        rowHeight={25}
        page={pages.cases}
        extraTypes={extraTypes}
        checkedWidgetsTypes={checkedWidgetsTypes}
        widgetsMap={widgetsMap}
        cardsLayout={cardsLayout}
      />
    </div>
  );
};
