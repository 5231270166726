import { createContext, useEffect, useReducer, useRef } from 'react';
import { merge } from 'lodash';
import { useSnackbar } from 'notistack';
import { api } from '../../../api';
import * as filtersApi from '../../../api/filters';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const SavedFiltersContext = createContext(null);

export const SavedFiltersProvider = ({
  children,
  filter: filterProp,
  onFilterAdd = () => {},
  onFilterRemove = () => {}
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    pagination,
    savedFilters,
    isFetching,
    isFetched,
    filter
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchSavedFilters = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FILTERS_FETCH_REQUEST });

    filtersApi.fetchFilters({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FILTERS_FETCH_SUCCESS, payload: data });
    });
  };

  const deleteAction = (filter) => {
    dispatch({ type: types.DELETE_FILTER, payload: filter.id });
  };

  const deleteSavedFilter = (filter) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        filtersApi.deleteFilter(filter.id).then(() => {
          deleteAction(filter);
          onFilterRemove(filter);
          enqueueSnackbar('Filter successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Filter is not archived!', { variant: 'error' });
        });
      }
    });
  };

  const addAction = (newSavedFilter) => {
    dispatch({ type: types.ADD_FILTER, payload: { ...newSavedFilter, isNew: true } });
  };

  const addSavedFilter = (newSavedFilter) => {
    addAction(newSavedFilter);
    onFilterAdd(newSavedFilter);
  };

  const updateSavedFilter = (updatedFilter) => {
    dispatch({ type: types.UPDATE_FILTER, payload: updatedFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchSavedFilters({ page: filter.page + 1 });
    }
  };

  const resetSavedFilters = (newFilter) => {
    dispatch({ type: types.RESET_FILTERS, payload: newFilter });

    fetchSavedFilters({ page: 1, ...newFilter });
  };

  const providerValue = {
    savedFilters,
    isFetching,
    isFetched,
    filter: {
      ...pagination,
      ...filter
    },
    fetchSavedFilters,
    deleteSavedFilter,
    addSavedFilter,
    deleteAction,
    addAction,
    resetSavedFilters,
    updateSavedFilter,
    loadNextPage
  };

  useEffect(() => {
    resetSavedFilters();
  }, []);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <SavedFiltersContext.Provider value={providerValue}>
      {children}
    </SavedFiltersContext.Provider>
  );
};
