import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import * as codesApi from '../../../../../../api/codes/payer';
import { AddressLink } from '../../../../../../components/AddressLink';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { Contact } from '../../../../../../components/Contact';
import { Loader } from '../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderActions
} from '../../../../../../components/Modal';
import { IconButton } from '../../../../../../components/IconButton';
import { ReadonlyCheckbox } from '../../../../../../components/ReadonlyCheckbox';
import { useModal } from '../../../../../../components/ModalsProvider';
import { CopyPayerModal } from '../CopyPayerModal';

export const PayerModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { baseUrl, codeID, codesContext }
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const history = useHistory();
  const { openModal } = useModal();
  const [ code, setCode ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);

  const deleteCode = () => {
    codesContext.deleteCode(code);
    handleModalResolve();
  };

  const createCodeCopy = () => {
    openModal(CopyPayerModal, {
      payload: {
        codeID: code.id,
        codesContext
      }
    });
    handleModalResolve();
  };

  const toggleActiveItem = () => {
    if (code.deactivated_at) {
      codesContext.massActivePayer([ codeID ]);
    } else {
      codesContext.massInactivePayer([ codeID ]);
    }

    setCode({ ...code, deactivated_at: !code.deactivated_at });
  };

  const goToEditPayerPage = () => {
    handleModalResolve();
    history.push(`${baseUrl}/payer/${codeID}/edit`);
  };

  useEffect(() => {
    setIsFetched(false);

    codesApi.fetchPayer(codeID).then((data) => {
      setCode(data);
      setIsFetched(true);
    }).catch(() => handleModalReject());
  }, []);

  return (
    <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <Loader
          p={2}
          loading={!isFetched}
          render={() => (
            <>
              <ModalHeader onClose={handleModalReject}>
                Payer Details
                <ModalHeaderActions>
                  <IconButton color="primary" disabled={!code.deactivated_at} onClick={goToEditPayerPage}>
                    <EditOutlinedIcon/>
                  </IconButton>

                  <IconButton color="secondary" onClick={createCodeCopy}>
                    <FileCopyIcon/>
                  </IconButton>

                  <IconButton color={code.deactivated_at ? 'warning' : 'success'} onClick={toggleActiveItem}>
                    {code.deactivated_at ? <LockIcon/> : <LockOpenIcon/>}
                  </IconButton>

                  <IconButton color="error" onClick={deleteCode}>
                    <DeleteIcon/>
                  </IconButton>
                </ModalHeaderActions>
              </ModalHeader>

              <ModalBody>
                <Grid container spacing={5}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={5}>
                      <Typography>Payer Full Name</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <CompanyLink
                        variant="h5"
                        company={code?.company}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>Payer Code</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <Typography variant="h5">{code.payer_code || '-'}</Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>Claim EDI No. Professional</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <Typography variant="h5">{code.professional_edi || '-'}</Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>Eligibility EDI</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <Typography variant="h5">{code.eligibility_edi || '-'}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={5}>
                      <Typography>Code</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <Typography variant="h5" display="block">
                        {code.code || '-'}
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>Name</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <Typography variant="h5" display="block">
                        {code.name || '-'}
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>Address1</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <AddressLink
                        variant="body1"
                        address={code.address_1}
                      >
                        {code.address_1}
                      </AddressLink>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>Main Phone</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <Contact
                        type="tel"
                        title={code.main_phone || '-'}
                        contact={code.main_phone}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={5}>
                      <Typography>Financial Class</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <Typography variant="h5">
                        {code?.financial_class?.code || ''}
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>Secondary E-claims</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <ReadonlyCheckbox checked={code.secondary_e_claims}/>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>NPI Only</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <ReadonlyCheckbox checked={code.npi_only}/>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>Accept NPI</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <ReadonlyCheckbox checked={code.accept_npi}/>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={5}>
                      <Typography>Claim Filling Indicator</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <Typography variant="h5" display="block">
                        {code?.claim_filing?.name || ''}
                      </Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>Provider ID Used</Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <Typography variant="h5" display="block">
                        {code.provider_id_used || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ModalBody>
            </>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
