import { useMemo } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { AppointmentViewModal, AppointmentEditModal } from '../../../../appointments';
import { CurrencyFormat } from '../../../../CurrencyFormat';
import { Date } from '../../../../Date';
import { Tooltip } from '../../../../Tooltip';
import { VerticalDivider } from '../../../../VerticalDivider';
import { IconButton } from '../../../../IconButton';
import { useModal } from '../../../../ModalsProvider';
import { transformAppointmentForClaimForm } from '../../transformClaimForForm';
import { CorrectedTotal } from './CorrectedTotal';
import { Procedures } from './Procedures';

export const Appointment = ({ appointment }) => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const proceduresPrice = useMemo(() => {
    return appointment.procedures?.reduce((result, { price }) => result + (price || 0), 0);
  }, [ appointment.procedures ]);

  const openViewAppointmentModal = () => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: appointment.id,
        onAppointmentUpdate: (appointment) => {
          setFieldValue('appointment', transformAppointmentForClaimForm(appointment));
        },
        onAppointmentDelete: () => {
          setFieldValue('appointment', null);
          setFieldValue('appointment_id', null);
        }
      }
    });
  };

  const openEditAppointmentModal = () => {
    openModal(AppointmentEditModal, {
      payload: {
        appointmentID: appointment.id
      },

      onModalResolved: (appointment) => {
        setFieldValue('appointment', transformAppointmentForClaimForm(appointment));
      }
    });
  };

  return (
    <>
      {isMobile && (
        <Box display="flex" alignItems="center">
          <Date date={appointment.appointment_at} format="L, LT" variant={isMobile ? 'subtitle2' : 'h3'} />

          <Box ml={2}>
            <Typography variant={isMobile ? 'subtitle2' : 'h3'}>
              <Box clone mr={1}>
                <TimelapseIcon />
              </Box>

              {moment.duration(appointment.time, 'm').humanize()}
            </Typography>
          </Box>
        </Box>
      )}

      <Box display="flex" alignItems="center">
        <IconButton
          edge="start"
          color="info"
          title="View appointment"
          onClick={openViewAppointmentModal}
        >
          <EyeIcon />
        </IconButton>

        <IconButton
          color="info"
          title="Edit appointment"
          onClick={openEditAppointmentModal}
        >
          <EditIcon />
        </IconButton>

        <VerticalDivider verticalGutters={1} horizontalGutters={0.5} />

        {!isMobile && (
          <>
            <Box ml={1.25}>
              <Date date={appointment.appointment_at} format="L, LT" variant="h3" />
            </Box>

            <Box ml={2}>
              <Typography variant="h3">
                <Box clone mr={1}>
                  <TimelapseIcon />
                </Box>

                {moment.duration(appointment.time, 'm').humanize()}
              </Typography>
            </Box>
          </>
        )}

        {!!appointment.procedures?.length &&
          <>
            <Box ml="auto" mr={2}>
              <Typography variant={isMobile ? 'subtitle2' : 'h3'}>
                Summary Total:
              </Typography>
            </Box>

            <Box mr={1} color="secondary.main">
              <Typography variant={isMobile ? 'subtitle2' : 'h3'} color="inherit">
                <CurrencyFormat value={proceduresPrice} />
              </Typography>
            </Box>

            {!!values.corrected_total &&
              <Tooltip title="It is a corrected price">
                <Box color="secondary.main">
                  <Typography variant={isMobile ? 'subtitle2' : 'h3'} color="primary">
                    (<CurrencyFormat value={values.corrected_total} TypographyProps={{ component: 'span' }} />)
                  </Typography>
                </Box>
              </Tooltip>
            }

            <CorrectedTotal value={values.corrected_total} />
          </>
        }
      </Box>

      {!!appointment.procedures?.length &&
        <Procedures procedures={appointment.procedures} />
      }
    </>
  );
};
