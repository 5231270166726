import { api } from '../../api';

export const fetchCategories = (config = {}) => {
  return api.get('/billing/codes', config).then(({ data }) => data);
};

export const createCategory = (category) => {
  return api.post('/billing/codes', category)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const updateCategory = (category) => {
  return api.put(`/billing/codes/${category.id}`, category)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const activateCategory = (id) => {
  return api.post(`/billing/codes/${id}/activate`).then(({ data }) => data);
};

export const deactivateCategory = (id) => {
  return api.post(`/billing/codes/${id}/deactivate`).then(({ data }) => data);
};

export const deleteCategory = (id) => {
  return api.delete(`/billing/codes/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
