import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../../../components/LayoutContext';
import { Page, PageBody, PageHeader } from '../../../../components/Page';
import { apptTypesMap } from '../../../../components/appointments';
import { useModal } from '../../../../components/ModalsProvider';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { UserContext } from '../../Members/User/UserProvider';
import { MRIsContext, MRIsProvider } from './MRIsProvider';
import { CreateMRIModal } from './CreateMRIModal';
import { ActionsBar } from '../ActionsBar';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

export const MRI = () => {
  const { openModal } = useModal();
  const params = useParams();
  const { user } = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const patient = userContext ? userContext?.user : user;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filterOpenButton,
    ScrollWrapper,
    FilterBarWrapper,
    scrollWrapperProps,
    filterBarWrapperProps
  } = useMobileFilterCollapse();

  const openCreateModal = (helpers) => () => {
    openModal(CreateMRIModal, {
      payload: {
        initialValues: {
          patient,
          patient_id: patient,
          patient_appointment_details: { patient, ...patient },
          patient_insurance_id: patient?.profile?.medical?.main_insurance || patient?.medical?.main_insurance
        },
        insurance: patient?.profile?.medical?.main_insurance ||
          patient?.medical?.main_insurance,
        appointmentTypeID: apptTypesMap.mri,
        onUpdate: helpers
      }
    });
  };

  const initialState = {
    filter: {
      patient_id: params?.userID || params.patientID || user?.id
    }
  };

  return (
    <LayoutContextProvider>
      <LayoutContext.Consumer>
        {({ viewVariant }) => (
          <MRIsProvider initialState={initialState}>
            <Page>
              <PageHeader disableBorderBottom>
                <MRIsContext.Consumer>
                  {({ selectedIDs, pagination, massDeleteMRI, fetchMRIs }) => (
                    <ActionsBar
                      disableViewVariant={isTablet}
                      disabled={!patient.id}
                      actionTitle="Add new"
                      selectedIDs={selectedIDs}
                      pagination={pagination}
                      onCreate={openCreateModal(fetchMRIs)}
                      onDelete={massDeleteMRI(selectedIDs)}
                      filterOpenButton={filterOpenButton}
                    />
                  )}
                </MRIsContext.Consumer>
              </PageHeader>

              <PageBody fullHeight disablePaddings disableScrollbars>
                <Box height="100%" display="flex" flexDirection="column">
                  <ScrollWrapper {...scrollWrapperProps}>
                    <FilterBarWrapper {...filterBarWrapperProps}>
                      <FiltersBar filterKey={filtersKeysMap.mri} />
                    </FilterBarWrapper>
                  </ScrollWrapper>

                  <Box flexGrow={1}>
                    {!isTablet ? (
                      (viewVariant === viewVariantsMap.list)
                        ? <List />
                        : (viewVariant === viewVariantsMap.grid)
                          ? <Grid /> : null
                    ) : (
                      <Grid />
                    )}
                  </Box>
                </Box>
              </PageBody>
            </Page>
          </MRIsProvider>
        )}
      </LayoutContext.Consumer>
    </LayoutContextProvider>
  );
};
