import cn from 'classnames';
import moment from 'moment';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Badge } from '../../../../../../components/Badge';
import { UserAvatar, UserPreviewModal } from '../../../../../../components/users';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../helpers/dom';
import { getUserFullName } from '../../../../../../helpers/users';
import { hasRole } from '../../../../../../utils/hasRole';
import { fieldsKeysMap } from '../../../../../../components/users/MedicalInfo';
import { CardItem, CardHeader } from '../../../../../../components/Cards';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { Date } from '../../../../../../components/Date';
import { styles } from '../../../../ProfilePage/MedicalInfo/MedicalForms/Documents/SignedUsers/UserAvatar/styles';
import { PrescriptionPreview } from '../../PrescriptionPreview';
import { PrescriptionsMenu } from '../../PrescriptionsMenu';
import { Body } from './Body';

const useStyles = makeStyles(styles);

export const Card = ({ item: prescription, hiddenColumns = [] }) => {
  const { openModal } = useModal();
  const classes = useStyles();

  const openPreview = () => {
    openModal(PrescriptionPreview, {
      payload: { id: prescription.id }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <CardItem py={1} px={2} onClick={stopPropagation(openPreview)}>
      <CardHeader
        disableCheckBox
        disableCardMenu={hasRole(rolesMap.client, rolesMap.patient)}
        menuComponent={PrescriptionsMenu}
        menuComponentProps={{ prescription }}
      >
        {!hiddenColumns.includes(fieldsKeysMap.patient) ? (
          <UserLink
            disablePreview={!prescription?.patient}
            disableLink={!prescription?.patient}
            size="sm"
            variant="inherit"
            user={prescription.patient}
          >
            <Date
              disableMoment
              variant="subtitle2"
              iconFontSize="small"
              date={moment(prescription.date).format('L')}
            />
          </UserLink>
        ) : (
          <Date
            disableMoment
            variant="subtitle2"
            iconFontSize="small"
            date={moment(prescription.date).format('L')}
          />
        )}
      </CardHeader>

      <Body item={prescription} />

      <Box display="flex" alignItems="center" width="100%" pt={2} pb={0.5}>
        <Box mr={1}>
          <Badge
            overlap="circle"
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            badgeContent={
              prescription?.signature?.id
                ? <CheckCircleIcon className={classes.badgeIcon}/>
                : <RemoveCircleIcon className={cn(classes.badgeIcon, classes.badgeIcon__red)}/>
            }
          >
            <UserAvatar size="lg" user={prescription.provider} />
          </Badge>
        </Box>

        <Box display="flex" flexDirection="column">
          <Link
            noWrap
            variant="h5"
            onClick={stopPropagation(openUserPreview(prescription?.provider?.id))}
          >
            {getUserFullName(prescription.provider)}
          </Link>

          <Typography color="textSecondary" variant="caption">Provider</Typography>
        </Box>
      </Box>
    </CardItem>
  );
};
