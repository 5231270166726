export const styles = ({ spacing }) => ({
  controlLabel: {
    marginRight: 0
  },

  field: {
    display: 'flex',
    alignItems: 'center'
  },

  field__label: {
    width: 60,
    marginRight: spacing(2)
  },

  field__input: {
    minWidth: 0
  }
});
