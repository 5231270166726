import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import * as formsApi from '../../../../api/profile';
import { Label } from '../OfficesLocationSelect';
import { Select } from '../Select';

export const transformGuestOfficeLocationToOption = (office) => office && ({
  value: office.id,
  label: <Label office={office} />,
  data: office
});

const fetchOffices = (params) => fetchDataForAsyncSelect((search, { page }) => {
  return formsApi.fetchGuestOffices({ params: { search, page, ...params } });
}, {
  transformDataToOption: transformGuestOfficeLocationToOption
});

export const OfficesLocationGuestSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      label="Office"
      placeholder="Select office..."
      promiseWithOptions={fetchOffices(params)}
      {...props}
    />
  );
};
