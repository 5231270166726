import { useFormikContext } from 'formik';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { IconButton } from '../../../../../../components/IconButton';
import { TimePicker } from '../../../../../../components/FormField';
import { columnsWidthsLg, columnsWidthsSm } from './WorkSchedule';

export const Breaks = ({
  parentIndex,
  childIndex,
  onBreakAdd = () => {},
  onBreakRemove = () => {}
}) => {
  const { values } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <ListRow disableBorder>
      <ListRowCell flexBasis={columnsWidths.day}>
        <Box color="info.main">
          <Typography>Breaks</Typography>
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dayOff} />

      <ListRowCell grow flexBasis={columnsWidths.start}>
        <Box width="100%">
          <TimePicker
            disabled={!!values?.work_schedules?.[parentIndex]?.day_off}
            fullWidth
            required
            name={`work_schedules.${parentIndex}.breaks.${childIndex}.from`}
            placeholder="Start time..."
          />
        </Box>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.end}>
        <Box width="100%">
          <TimePicker
            required
            fullWidth
            disabled={!!values?.work_schedules?.[parentIndex]?.day_off}
            name={`work_schedules.${parentIndex}.breaks.${childIndex}.to`}
            placeholder="End time..."
          />
        </Box>
      </ListRowCell>

      <ListRowCell justifyContent="space-between" flexBasis={columnsWidths.action}>
        {childIndex === values?.work_schedules?.[parentIndex]?.breaks?.length - 1 &&
          <IconButton
            size="small"
            color="primary"
            onClick={onBreakAdd}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        }

        {childIndex > 0 && childIndex === values?.work_schedules?.[parentIndex]?.breaks?.length - 1 &&
          <IconButton
            size="small"
            color="error"
            onClick={onBreakRemove(childIndex)}
          >
            <ClearIcon fontSize="small"/>
          </IconButton>
        }
      </ListRowCell>
    </ListRow>
  );
};
