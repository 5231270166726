import cn from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ transitions }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },

  color: {
    flexGrow: 1,
    cursor: 'pointer',
    borderRadius: 3,
    background: ({ value: color }) => color,
    opacity: ({ value }) => value ? 1 : 0,
    transition: transitions.create()
  }
}));

export const Input = ({ inputRef, className, ...props }) => {
  const classes = useStyles(props);

  return (
    <div ref={inputRef} className={cn(classes.root, className)} {...props}>
      <div className={classes.color} />
    </div>
  );
};
