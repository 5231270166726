import { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import cn from 'classnames';
import { makeStyles, Typography, useTheme, useMediaQuery, Box, FormControlLabel } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import * as htmlDocsApi from '../../../../../api/files/html-documents';
import { Yup } from '../../../../../utils/validation';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Checkbox, TextField } from '../../../../../components/FormField';
import { Button } from '../../../../../components/Button';
import { Loader } from '../../../../../components/Loader';
import { Label } from '../../../../../components/Label';
import { CreateDocumentModal, DocumentEditor } from '../../../files-common';
import {
  addReplaceableDataTypePlugin,
  initReplaceableDataTypeList
} from '../../../files-common/DocumentEditor/replaceableDataTypePlugin';
import { ReplaceableDataFieldsList } from '../ReplaceableDataFieldsList';
import { templatesTypesNames, templatesTypesColors } from '../templatesTypes';
import { styles } from '../TemplateCreationPage/styles';

const useStyles = makeStyles(styles);

export const TemplateEditPage = ({ isOpenSidebar = false, toggleSideBar = () => {} }) => {
  const { openModal } = useModal();
  const availablePaperSizes = useSelector(({ apiConfig }) => apiConfig.config.html_documents.page_sizes);
  const [ template, setDocument ] = useState(null);
  const [ isOpenList, setIsOpenList ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const editorRef = useRef();
  const listRef = useRef();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleEditorInit = (CKEDITOR) => {
    addReplaceableDataTypePlugin({ CKEDITOR });
    initReplaceableDataTypeList({ CKEDITOR, listRef });
  };

  const fetchDocument = () => {
    htmlDocsApi.getTemplate(params.id).then((template) => {
      setDocument(template);
    }).catch(({ status }) => {
      if (status === 404) {
        history.push('/files/templates');
      }
    });
  };

  const updateDocument = (template, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const html = editorRef.current.getData();

    if (!html) {
      setSubmitting(false);

      return enqueueSnackbar('The template cannot be empty', {
        variant: 'error'
      });
    }

    return htmlDocsApi.updateTemplate({
      ...template, html
    }).then(() => {
      enqueueSnackbar('Template successfully updated', { variant: 'success' });
      history.push('/files/templates');
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  const generateDocument = () => {
    openModal(CreateDocumentModal, {
      payload: {
        initialHTML: editorRef.current.getData()
      }
    });
  };

  const handleToggleFieldsList = () => {
    setIsOpenList((state) => !state);
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  return (
    <div className={classes.root}>
      {!template ? <Loader /> : (
        <Formik
          initialValues={template}
          validationSchema={Yup.object().shape({ name: Yup.string().required().min(2).max(255) })}
          onSubmit={updateDocument}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form noValidate className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.top}>
                {isTablet && (
                  <IconButton color="primary" onClick={toggleSideBar}>
                    {isOpenSidebar ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
                )}

                <TextField
                  required
                  name="name"
                  label="Template name"
                  placeholder="Enter template name..."
                  margin="dense"
                  className={classes.grow}
                />

                <FormControlLabel
                  label="Is system"
                  control={
                    <Checkbox name="is_system" />
                  }
                />

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      size={isTablet ? 'small' : 'medium'}
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />

                <Button
                  variant="contained"
                  color="secondary"
                  size={isTablet ? 'small' : 'medium'}
                  onClick={generateDocument}
                >
                  Apply to
                </Button>

                <div className={classes.templateType}>
                  <Typography className={classes.templateType__title}>
                    {isMobile ? 'Type' : 'Template type'}
                  </Typography>

                  <Label label={templatesTypesNames[template.type]} color={templatesTypesColors[template.type]} />
                </div>
              </div>

              {isMobile && (
                <Box display="flex" justifyContent="flex-end" pb={0.5}>
                  <Button color="primary" onClick={handleToggleFieldsList}>
                    {isOpenList ? 'Close List' : 'Open List'}
                  </Button>
                </Box>
              )}

              <div className={classes.bottom}>
                <div className={classes.bottom__container}>
                  <div className={classes.editor}>
                    <DocumentEditor
                      ref={editorRef}
                      initData={template.html}
                      paperSize={availablePaperSizes[template.page_size]}
                      paperMargins={template.page_fields}
                      onBeforeLoad={handleEditorInit}
                    />
                  </div>

                  <div className={cn(classes.list, { [classes.listClose]: isMobile && !isOpenList })}>
                    <ReplaceableDataFieldsList
                      listRef={listRef}
                      selectedTemplateType={template.type}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};
