import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import * as insuranceApi from '../../../../../api/profile/insurances';
import { Loader } from '../../../../../components/Loader';
import { getFormData } from '../AddInsuranceInfoModal';
import { EditInsuranceForm } from './EditInsuranceForm';
import { getInitialValues } from './getInitialValues';

export const EditInsuranceInfoModal = ({
  DialogProps,
  payload: {
    user,
    insurance: initialInsurance = {},
    onEdit = () => {},
    onDelete = () => {},
    onCaseItemUpdate = () => {}
  },
  handleModalReject,
  handleModalResolve
}) => {
  const [ insurance, setInsurance ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const mainPage = pathname.includes('insurances');

  const handleDelete = () => {
    onDelete(insurance.id, handleModalReject);
  };

  const editInsurance = ({ caseItem = null, ...values }, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    values.tpl_id = values.tpl_id?.value || values.tpl_id;
    values.case_id = values.case_id?.value || values.case_id?.id || values.case_id;
    values.provider_id = values.provider_id?.id || values.provider_id;
    values.financial_class_id = values.financial_class_id?.value || values.financial_class_id;
    values.insurance_company_id = values.insurance_company_id?.id || values.insurance_company_id;
    values.subscriber_info.gender = values?.subscriber_info?.gender?.value || values?.subscriber_info?.gender;

    if (values.scan_back === insurance.url_scan_back) {
      values.scan_back = undefined;
    }

    if (values.scan_front === insurance.url_scan_front) {
      values.scan_front = undefined;
    }

    if (values?.medical_id) {
      values.medical_id = values?.medical_id?.id || null;
    }

    if (values?.case_user_id) {
      values.case_user_id = values?.case_user_id?.user?.id || values?.case_user_id?.id || null;
    }

    const formData = getFormData(values);

    if (values?.scan_front === null) formData.append('scan_front', '');
    if (values?.scan_back === null) formData.append('scan_back', '');
    if (values?.attorney_id === null) formData.append('attorney_id', '');
    if (values?.attorney_adj_id === null) formData.append('attorney_adj_id', '');
    if (values?.provider_id === null) formData.append('provider_id', '');
    if (values?.provider_adj_id === null) formData.append('provider_adj_id', '');

    formData.append('_method', 'put');

    return insuranceApi.editInsurance(insurance.id, formData).then((data) => {
      enqueueSnackbar('Insurance info updated successfully', { variant: 'success' });
      onEdit(data);
      handleModalResolve(data);
    }).catch(() => {
      enqueueSnackbar('Insurance info not updated', { variant: 'error' });
    });
  };

  const updateInsurance = useCallback((data) => {
    return setInsurance({ ...initialInsurance, ...data, caseItem: data?.case || null });
  }, []);

  useEffect(() => {
    insuranceApi.fetchInsurance(initialInsurance?.id)
      .then(updateInsurance).catch(handleModalReject);
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <Loader p={3} loading={!insurance?.id} render={
        () => (
          <EditInsuranceForm
            initialValues={getInitialValues(insurance)}
            insurance={insurance}
            user={mainPage ? null : user}
            mainPage={mainPage}
            editInsurance={editInsurance}
            onClose={handleModalReject}
            onCaseItemUpdate={onCaseItemUpdate}
            onDelete={handleDelete}
          />
        )}
      />
    </Dialog>
  );
};
