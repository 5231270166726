import { useContext } from 'react';
import { get } from 'lodash';
import { useField, FormikContext } from 'formik';

export const useFormikField = (nameOrOptions) => {
  const formik = useContext(FormikContext);
  const isFormikField = !!formik;
  const name = typeof nameOrOptions === 'object' ? nameOrOptions.name : nameOrOptions;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fieldProps = isFormikField ? useField(nameOrOptions) : [ {}, {}, {} ];

  return {
    isFormikField,
    fieldProps,
    error: get(formik?.errors, name, false)
  };
};
