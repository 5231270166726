import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  CPTCodesSelect,
  IcdCodesSelect,
  ModifiersSelect,
  PosCodesSelect,
  TextField,
  Autocomplete,
  CurrencyField,
  transformModifiersCodesToOption
} from '../../../../../../components/FormField';
import { sidesOptions } from '../../../../../../components/appointments/procedures';
import { LevelsSelect } from '../../../../../../components/FormField/selects/LevelsSelect';

export const ProceduresBody = ({ companyID }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleCptChange = (cpt) => {
    if (cpt) {
      setFieldValue(
        'modifiers',
        cpt?.fee_schedule?.modifier
          ? [ transformModifiersCodesToOption(cpt?.fee_schedule?.modifier) ]
          : null
      );
      setFieldValue('price', cpt?.fee_schedule?.charge_amount || 0);
      setFieldValue('unit', cpt?.fee_schedule?.default_units || 0);
      setFieldValue('cpt_description', cpt?.description);
    }
  };

  return (
    <Box flex={1}>
      <Box mt={4} color="info.main">
        <Typography variant="h3" color="inherit">
          Select Surgeries/Procedures
        </Typography>
      </Box>

      <Grid container spacing={2} alignItems="flex-end">
        <Grid item sm={3} xs={12}>
          <Autocomplete
            disableClearable
            name="side"
            label="Side"
            options={sidesOptions}
            margin="dense"
          />
        </Grid>

        <Grid item sm={9} xs={12}>
          <LevelsSelect isMulti TextFieldProps={{ margin: 'dense', required: true }} />
        </Grid>

        <Grid item sm={3} xs={12}>
          <CPTCodesSelect
            required
            name="cpt_id"
            label="Procedure Code #"
            margin="dense"
            params={{ schedule_payer_id: companyID }}
            onChange={handleCptChange}
          />
        </Grid>

        <Grid item sm={9} xs={12}>
          {values.cpt_id &&
            <Box mt={1}>
              <Typography variant="body2" color="textSecondary">
                Procedure description
              </Typography>
            </Box>
          }

          <Typography style={{ textTransform: 'uppercase' }}>
            {values.cpt_description}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            rows={2}
            rowsMax={8}
            disableUnderline
            variant="filled"
            name="surgery"
            label="Surgery"
            margin="dense"
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <PosCodesSelect
            required
            name="pos_id"
            label="POS"
            margin="dense"
          />
        </Grid>

        <Grid item sm={9} xs={12}>
          <IcdCodesSelect
            multiple
            name="icd"
            label="DX Sequence/Diagnosis"
            margin="dense"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <ModifiersSelect
            isMulti
            formattedValue
            name="modifiers"
            label="Modifiers"
            TextFieldProps={{ margin: 'dense' }}
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <TextField
            required
            type="number"
            name="unit"
            label="Unit"
            placeholder="Enter unit..."
            margin="dense"
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <CurrencyField
            required
            name="price"
            label="Price"
            margin="dense"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
