import { api } from '../api';

export const transformResponse = (data) => {
  return {
    data: Object.values(data),
    labels: Object.keys(data)
  };
};

export const fetchExpenseStatistics = (config = {}) => {
  return api.get('/statistics/expense-analytics', config)
    .then(({ data }) => ({
      data: Object.keys(data).map((key) => {
        return { ...data[key], title: key };
      }),
      transformResponse: transformResponse(data)
    }))
    .catch((error) => { throw error.data; });
};

export const fetchBillingAndPaymentStatistics = (config = {}) => {
  return api.get('/statistics/billing-payments', config)
    .then(({ data }) => ({
      ...data,

      transformedData: transformResponse(data)
    }))
    .catch((error) => { throw error.data; });
};

export const fetchIncomesAndExpenseStatistics = (config = {}) => {
  return api.get('/statistics/incomes-expenses', config)
    .then(({ data }) => transformResponse(data))
    .catch((error) => { throw error.data; });
};

export const fetchTimeTracksStatistics = (config = {}) => {
  return api.get('/statistics/time-tracking', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchBalancesStatistics = (config = {}) => {
  return api.get('/statistics/balances', config)
    .then(({ data }) => transformResponse(data))
    .catch((error) => { throw error.data; });
};

export const fetchPaymentHistoryStatistics = (config = {}) => {
  return api.get('/statistics/payment-history', config)
    .then(({ data }) => transformResponse(data))
    .catch((error) => { throw error.data; });
};

export const fetchUpcomingPaymentStatistics = (config = {}) => {
  return api.get('/statistics/upcoming-payment', config)
    .then(({ data }) => transformResponse(data))
    .catch((error) => { throw error.data; });
};
