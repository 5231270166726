import { List as MuiList, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';

export const columnsWidths = {
  name: 180,
  dosage: 180,
  frequency: 180
};

export const Medications = ({ medications }) => {
  return !!medications.length && (
    <>
      <ListRow header>
        <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
          Medication Name
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.dosage}>
          Dosage
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.frequency}>
          Frequency
        </ListRowCell>
      </ListRow>

      {!medications.length ?
        <Typography align="center" color="textSecondary">No info.</Typography>
        :
        <MuiList disablePadding>
          {medications.map((medication, index) => (
            <ListRow key={index}>
              <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                <Typography title={medication.name}>
                  {medication.medications}
                </Typography>
              </ListRowCell>

              <ListRowCell noWrap grow flexBasis={columnsWidths.dosage}>
                <Typography title={medication.dosage}>
                  {medication.dosage}
                </Typography>
              </ListRowCell>

              <ListRowCell flexBasis={columnsWidths.frequency}>
                <Typography title={medication.frequency}>
                  {medication.frequency}
                </Typography>
              </ListRowCell>
            </ListRow>
          ))}
        </MuiList>
      }
    </>
  );
};
