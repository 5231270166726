import { Yup } from '../../../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  court_id: Yup.string().nullable(),
  address: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  case_number: Yup.string().nullable(),
  contact_id: Yup.string().nullable(),
  contact_name: Yup.string().nullable(),
  dep: Yup.string().nullable(),
  jury_trial: Yup.string().nullable(),
  trial_date: Yup.date().format('YYYY-MM-DD').nullable(),
  fsc: Yup.date().format('YYYY-MM-DD').nullable(),
  cmc: Yup.date().format('YYYY-MM-DD').nullable(),
  cmc_future_use: Yup.date().format('YYYY-MM-DD').nullable(),
  osc: Yup.date().format('YYYY-MM-DD').nullable(),
  mcs: Yup.date().format('YYYY-MM-DD').nullable(),
  mcs_future_use: Yup.date().format('YYYY-MM-DD').nullable(),
  mediation: Yup.date().format('YYYY-MM-DD').nullable(),
  arb: Yup.date().format('YYYY-MM-DD').nullable(),
  arb_future_use: Yup.date().format('YYYY-MM-DD').nullable(),
  complaint_filled: Yup.date().format('YYYY-MM-DD').nullable(),
  def: Yup.string().nullable(),
  received: Yup.date().format('YYYY-MM-DD').nullable(),
  plf_sent: Yup.date().format('YYYY-MM-DD').nullable()
});
