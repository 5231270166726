import moment from 'moment';

export const getTimeSteps = ({
  startTime = moment().startOf('day'),
  endTime = moment().endOf('day'),
  interval = 60,
  unit = 'minutes',
  outputFormat = 'hh:mm A'
}) => {
  const steps = endTime.diff(startTime, unit);
  const timeLabels = [];

  for (let i = 0; i < steps; i += interval) {
    startTime.add(i === 0 ? 0 : interval, unit);
    timeLabels.push(startTime.format(outputFormat));
  }

  return timeLabels;
};
