import { api } from '../../api';

export const fetchBodyPartCodes = (config) => {
  return api.get('/procedure-codes/body-part', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchBodyPartCode = (id, config) => {
  return api.get(`/procedure-codes/body-part/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createBodyPartCode = (data) => {
  return api.post('/procedure-codes/body-part', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateBodyPartCode = ({ id, ...data }) => {
  return api.put(`/procedure-codes/body-part/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteBodyPartCode = (id) => {
  return api.delete(`/procedure-codes/body-part/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
