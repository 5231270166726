import { useContext } from 'react';
import { Checkbox, Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../ListRow';
import { ClaimsContext } from '../../index';
import { columnsMap, columnsWidths, widthBreakpointLarge } from '../ClaimsList';

export const TableHeader = ({ width, hiddenColumns = [], ListRowProps = {} }) => {
  const { selectedClaimsIDs, allClaimsIsSelected, toggleAllClaimsSelected } = useContext(ClaimsContext);

  return (
    <Paper elevation={0}>
      <ListRow header px={0} {...ListRowProps}>
        {!hiddenColumns.includes(columnsMap.checkbox) &&
          <ListRowCell>
            <Checkbox
              edge="start"
              indeterminate={!!selectedClaimsIDs.length && !allClaimsIsSelected()}
              checked={allClaimsIsSelected()}
              onClick={toggleAllClaimsSelected}
            />
          </ListRowCell>
        }

        <ListRowCell grow flexBasis={columnsWidths.claimNo}>
          Claim # / DOS
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.patient) &&
          <ListRowCell grow flexBasis={columnsWidths.patient}>
            Patient
          </ListRowCell>
        }

        {!hiddenColumns.includes(columnsMap.caseNo) &&
          <ListRowCell flexBasis={columnsWidths.caseNo}>
            Case #
          </ListRowCell>
        }

        <ListRowCell grow={width < widthBreakpointLarge} flexBasis={columnsWidths.payer}>
          Payer
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.provider}>
          Provider / Office
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.chargeAmount}>
          Charge Amount
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.totalAmount) &&
          <ListRowCell flexBasis={columnsWidths.totalAmount}>
            Total Amount
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.claimBalance}>
          Claim Balance
        </ListRowCell>

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.payoutStatus}>
            Payout Status
          </ListRowCell>
        }

        {!hiddenColumns.includes(columnsMap.claimStatus) && (
          <ListRowCell flexBasis={columnsWidths.claimStatus}>
            Claim Status
          </ListRowCell>
        )}

        {!hiddenColumns.includes(columnsMap.actions) &&
          <ListRowCell flexBasis={columnsWidths.actions} />
        }
      </ListRow>
    </Paper>
  );
};
