import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../components/Loader';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { ListHeader } from './ListHeader';
import { Row } from './Row';

export const columnsMap = {
  users: 'users',
  cases: 'cases',
  company: 'company',
  action: 'action'
};

export const columnsWidths = {
  [columnsMap.users]: 200,
  [columnsMap.cases]: 150,
  [columnsMap.company]: 200,
  [columnsMap.action]: 52
};

export const List = ({ hiddenColumns, onApplySavedFilter }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No saved filters</Typography>
        ) : (
          <>
            <ListHeader hiddenColumns={hiddenColumns} />

            <MuiList disablePadding>
              <InfiniteListLoader
                items={savedFilters}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ hiddenColumns, onApplySavedFilter }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
