import { useContext, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../../components/TablePagination';
import { Loader } from '../../../../../../components/Loader';
import { Cards } from '../../../../../../components/Cards';
import { PatientsContext } from '../../../PatientsProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    users,
    isFetched,
    filter,
    resetUsers
  } = useContext(PatientsContext);
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    resetUsers();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%', flexGrow: 1 }}>
      <Loader p={3} loading={!isFetched} render={
        () => !users.length ? (
          <Typography align="center">No users found</Typography>
        ) : (
          <Box mr={isMobile ? 1 : 0}>
            <Cards size={isMobile ? 320 : 370} list={users} component={Card} />
            <TablePagination pagination={filter} onChange={resetUsers} />
          </Box>
        )}
      />
    </CustomScrollbars>
  );
};
