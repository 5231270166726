import * as billingApi from '../../../../../api/billing';

export const transformProfileToOption = (user) => user && ({
  value: user.id,
  label: user
});

export const transformCaseNumberToOption = (item) => item && ({
  value: item.id,
  label: item.file_number || item.name
});

export const transformTermsToOption = (item) => item && ({
  value: item,
  label: item
});

export const fetchTerms = () => {
  return billingApi.fetchTerms().then((data) => {
    return data.map(transformTermsToOption);
  });
};
