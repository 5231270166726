import { useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, SvgIcon } from '@material-ui/core';
import { mdiAccountArrowLeftOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as emailsApi from '../../../../../api/emails';
import { SimpleSelect } from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '../../../../../components/Modal';
import { UsersSelect } from '../../../../../components/users';
import { validationSchema } from './validationSchema.js';
import { actionsMap } from '../actionsMap';

const actions = [
  { value: actionsMap.add, label: 'Add current to selected patient' },
  { value: actionsMap.replace, label: 'Replace current to selected patient' },
  { value: actionsMap.delete, label: 'Delete selected patient' }
];

export const EmailsAttachToPatientModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { emailIDs, onPatientAdd, onPatientDelete }
}) => {
  const [ selectedPatient, setSelectedPatient ] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const handlePatientChange = (patient) => {
    setSelectedPatient(patient);
  };

  const emailAttachToPatient = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return emailsApi.assignEmails({
      emails_id: emailIDs,
      patient_id: values.action === actionsMap.delete ? null : values.patient_id
    }).then(() => {
      enqueueSnackbar('Patient for message changed', { variant: 'success' });

      if (values.action === actionsMap.delete) {
        onPatientDelete(emailIDs);
      } else {
        onPatientAdd(emailIDs, selectedPatient);
      }

      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Patient for message not changed', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        action: actionsMap.add,
        patient_id: null
      }}
      validationSchema={validationSchema}
      onSubmit={emailAttachToPatient}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <Icon path={mdiAccountArrowLeftOutline} />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Attach to patient
            </ModalHeader>

            <ModalBody>
              <SimpleSelect
                name="action"
                label="Select action type"
                options={actions}
              />

              <UsersSelect
                name="patient_id"
                label="Select patient"
                placeholder="Select patient"
                params={{ is_patient: 1 }}
                onChange={handlePatientChange}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
