import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import * as templatesApi from '../../../../../../api/profile';
import { Loader } from '../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../components/Modal';
import { validationSchema } from './validationSchema';
import { Body } from './Body';

export const REFILL_OPTIONS = '0-1-2-3-4-5 PAN';

export const CreateTemplateModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const currentUser = useSelector(({ profile }) => profile.user);

  const createTemplate = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return templatesApi.createPrescriptionTemplate(values).then(({ data }) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      enqueueSnackbar('Not created', { variant: 'error' });
      setErrors(errors);
    });
  };

  return (
    <Formik
      initialValues={{
        name: null,
        text: null,
        office_id: null,
        provider_id: hasRole(rolesMap.doctor) ? currentUser : null,
        options: {
          generic_equivalent_acceptable: 'no',
          refill: REFILL_OPTIONS
        },
        icd_code_ids: []
      }}
      validationSchema={validationSchema}
      onSubmit={createTemplate}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>} onClose={handleModalReject}>
              Create Prescription Template
            </ModalHeader>

            <ModalBody>
              <Body />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
