import moment from 'moment';

const invalidDate = new Date('');

export function dateFormat(format = 'X') {
  return this.transform(function (value, originalValue) {
    const date = moment(originalValue, format, true);

    return originalValue && (date.isValid() ? date.toDate() : invalidDate);
  });
}
