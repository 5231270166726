// Cyclic dependency, dont shortening path
import {
  validationSchema as apptValidationSchema
} from '../../../../../components/appointments/AppointmentForm/validationSchema';
import { Yup } from '../../../../../utils/validation';
import { validationSchema as PrescriptionValidationSchema } from '../validationSchema';

export const validationSchema = apptValidationSchema.shape({
  prescriptions: Yup.array().of(PrescriptionValidationSchema).required()
});
