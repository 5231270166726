export const styles = ({
  spacing,
  typography: { pxToRem }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: spacing(2),
    paddingBottom: spacing(2)
  },

  header: {
    marginBottom: spacing(2),
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },

  listWrapper: {
    flexGrow: 1,
    overflow: 'hidden'
  },

  list: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },

  listItem: {
    marginBottom: spacing(),
    fontSize: pxToRem(12),
    textTransform: 'none',
    cursor: 'grab'
  },

  listItem__label: {
    justifyContent: 'space-between'
  }
});
