import { Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ParentAssignments } from '../../../../components/Members/ParentAssignments';
import { Popper } from '../../../../components/Popper';
import { Fab } from '../../../../components/Fab';
import { EditTaskUsers } from '../EditTaskModal/EditTaskUsers';
import { PreviewTaskMember } from '../PreviewTaskMember';

export const Members = ({ task, setInitialData }) => {
  const owner = task?.users?.find(({ id }) => id === task?.owner?.id);
  const sortedUsers = owner ? task?.users?.filter(({ id }) => id !== owner.id) : (task.users || []);

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Box mr={2}>
          <Typography variant="h5">
            MEMBERS
          </Typography>
        </Box>

        <Popper
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <Fab
                size="small"
                color="primary"
                buttonRef={anchorRef}
                onClick={handleToggle}
              >
                <AddIcon fontSize="small" />
              </Fab>
            )
          }
        >
          {({ handleClose }) => (
            <EditTaskUsers task={task} onUpdate={setInitialData} onClose={handleClose} />
          )}
        </Popper>
      </Box>

      <ParentAssignments
        mb={1}
        users={sortedUsers}
        MenuComponent={PreviewTaskMember}
        menuProps={{
          parent: task,
          onUpdate: setInitialData
        }}
      />
    </>
  );
};
