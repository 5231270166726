import { createContext, useEffect, useReducer, useRef } from 'react';
import { merge } from 'lodash';
import { api } from '../../../../../../../api';
import * as documentsApi from '../../../../../../../api/profile';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const DocumentsContext = createContext();

export const DocumentsProvider = ({ initialState: initialStateProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const {
    isFetched,
    filter,
    pagination,
    documents,
    selectedIDs
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchDocuments = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_DOCUMENTS_REQUEST });

    documentsApi.fetchMedicalDocuments({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_DOCUMENTS_SUCCESS, payload: data });
    });
  };

  const resetDocuments = (newFilter) => {
    dispatch({ type: types.RESET_DOCUMENTS, payload: newFilter });

    fetchDocuments({ page: 1, ...newFilter });
  };

  const allDocumentsIsSelected = () => {
    return documents.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_DOCUMENT_SELECTION, payload: id });
  };

  const toggleAllDocumentsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_DOCUMENTS_SELECTION });
  };

  const providerValue = {
    isFetched,
    documents,
    filter: {
      ...filter,
      ...pagination
    },
    pagination,
    selectedIDs,

    // functions
    fetchDocuments,
    resetDocuments,
    allDocumentsIsSelected,
    toggleItemSelection,
    toggleAllDocumentsSelection
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <DocumentsContext.Provider value={providerValue}>
      {children}
    </DocumentsContext.Provider>
  );
};
