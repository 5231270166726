import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isEqual, merge, omit } from 'lodash';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { api } from '../../../../../../api';
import * as codesApi from '../../../../../../api/codes/procedures';
import { usePrevious } from '../../../../../../helpers/hooks';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CodesContext = createContext();

export const CodesContextProvider = ({ initialState: initialStateProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const cptCodesLastGlobalAction = useSelector(({ globalActions }) => globalActions.cptCodesLastGlobalAction);
  const { openModal } = useModal();
  const {
    isFetched,
    isFetching,
    filter,
    pagination,
    codes,
    selectedCodesIDs
  } = state;
  const cancelFetch = useRef(() => {});
  const prevFilter = usePrevious(filter);

  const fetchCodes = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_CODES_REQUEST });

    codesApi.fetchBodyPartCodes({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_CODES_SUCCESS, payload: data });
    });
  };

  const deleteCode = (code) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        codesApi.deleteBodyPartCode(code.id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchCodes({ page: filter.page });
          } else if (codes.length - 1 === 0 && filter.page !== 1) {
            fetchCodes({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_CODE_SUCCESS, payload: code });
          }
        });
      }
    });
  };

  const resetCodes = (newFilter) => {
    dispatch({ type: types.RESET_CODES, payload: newFilter });

    fetchCodes({ page: 1, ...newFilter });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  useEffect(() => {
    cptCodesLastGlobalAction && dispatch(cptCodesLastGlobalAction);
  }, [ cptCodesLastGlobalAction ]);

  const providerValue = {
    codes,
    isFetched,
    isFetching,
    filter,
    pagination,
    selectedCodesIDs,

    // functions
    deleteCode,
    resetCodes,
    applyFilter,
    fetchCodes
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetCodes(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CodesContext.Provider value={providerValue}>
      {children}
    </CodesContext.Provider>
  );
};
