import { useEffect, useContext, useRef } from 'react';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../components/Loader';
import { Scrollbars } from '../../../../../../components/Scrollbars';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Sticky } from '../../../../../../components/Sticky';
import { ArrivalStatusesContext } from '../ArrivalStatusesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

export const minRowHeight = 54;
export const columnsWidthsLg = {
  code: 200,
  description: 180,
  arrivalType: 160,
  ordering: 100,
  actions: 264
};
export const columnsWidthsSm = {
  code: 115,
  arrivalType: 100,
  ordering: 45,
  actions: 55
};

const useStyles = makeStyles(styles);

export const List = ({ baseUrl }) => {
  const {
    arrivalStatuses,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    resetArrivalStatuses
  } = useContext(ArrivalStatusesContext);
  const scrollElementRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    resetArrivalStatuses();
  }, []);

  return (
    <Scrollbars viewRef={scrollElementRef}>
      <div className={classes.root}>
        <Loader loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No arrival statuses found</Typography>
          ) : (
            <div className={classes.list}>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={arrivalStatuses}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  RowProps={{ baseUrl }}
                  minRowHeight={minRowHeight}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </div>
          )}
        />
      </div>
    </Scrollbars>
  );
};
