import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  List,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { setTracksLastGlobalAction } from '../../../../store/globalActions';
import { TimeTrackingContext } from '../../../TimeTracking';
import { EditLogTimeModal } from '../../../LogTime';
import { useModal } from '../../../ModalsProvider';
import * as types from '../../../TimeTracking/timeTrackerHelpers/types';
import { Tooltip } from '../../../Tooltip';

export const Menu = ({ caseItem = null, track = {}, onClose = () => {} }) => {
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { deleteTrack } = useContext(TimeTrackingContext);
  const isEditDisabled = !track.is_manual || track?.is_has_income;

  const onUpdate = () => {
    openModal(EditLogTimeModal, {
      payload: {
        caseItem,
        initialValues: track
      },
      onModalResolved: (track) => {
        onClose();
        dispatch(setTracksLastGlobalAction({ type: types.UPDATE_TRACK, payload: track }));
      }
    });
  };

  const onDelete = () => {
    if (!track?.finished_at) {
      enqueueSnackbar('You cannot delete track until it is finished', { variant: 'error' });

      return;
    }

    deleteTrack(track.id, onClose);
  };

  return (
    <List>
      <Tooltip
        isExistTooltip={isEditDisabled}
        title={!track?.is_manual
          ? 'Only tracks created manually can be edited'
          : 'The track is already used in income'
        }
      >
        <div>
          <MenuItem disabled={isEditDisabled} onClick={onUpdate}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit" />
          </MenuItem>
        </div>
      </Tooltip>

      <Tooltip isExistTooltip={!!track.is_has_income} title="You cannot delete a track if it is used in income">
        <div>
          <MenuItem disabled={!!track.is_has_income} onClick={onDelete}>
            <ListItemIcon>
              <DeleteIcon color="error" fontSize="inherit"/>
            </ListItemIcon>

            <ListItemText primary="Delete" />
          </MenuItem>
        </div>
      </Tooltip>
    </List>
  );
};
