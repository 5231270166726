export const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const POST_FETCH_MESSAGES_SUCCESS = 'POST_FETCH_MESSAGES_SUCCESS';
export const MASS_DELETE_ITEMS = 'MASS_DELETE_ITEMS';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
export const APPLY_FILTER = 'APPLY_FILTER';
export const RESET_MESSAGES = 'RESET_MESSAGES';
export const TOGGLE_MESSAGE_FLAGGED = 'TOGGLE_MESSAGE_FLAGGED';
export const SET_ONE_MESSAGE = 'SET_ONE_MESSAGE';
export const TOGGLE_MESSAGE_SEEN = 'TOGGLE_MESSAGE_SEEN';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
