import { useContext, useEffect, useState } from 'react';
import { List } from '@material-ui/core';
import { invoicesTypesMap } from '../../../../../../components/billing';
import { NestedList } from '../../../../../../components/NestedList';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Loader } from '../../../../../../components/Loader';
import { IncomesContext, Incomes } from '../../Incomes';
import { CreateIncomeModal } from '../../Incomes/CreateIncomeModal';

export const IncomesContent = ({ width, invoice, setTotal }) => {
  const { openModal } = useModal();
  const [ totalTime, setTotalTime ] = useState(0);
  const { incomes, isFetched, resetIncomes } = useContext(IncomesContext);
  const [ openListItem, setOpenListItem ] = useState(false);

  const handleOpenListItem = () => {
    setOpenListItem((state) => !state);
  };

  useEffect(() => {
    resetIncomes();
  }, []);

  const addIncome = () => {
    if (invoice?.model_type === invoicesTypesMap.case) {
      openModal(CreateIncomeModal, {
        payload: {
          invoiceID: invoice.id,
          model: invoice.model
        },
        onModalResolved: () => {
          resetIncomes();
        }
      });
    }
  };

  useEffect(() => {
    incomes.length > 0
      ? setTotalTime(incomes.reduce((prev, next) => prev + +next.total, 0).toFixed(2))
      : setTotalTime(0);
  }, [ incomes ]);

  return (
    <NestedList
      disablePadding
      disableDivider
      disableBackground
      isOpen={openListItem}
      actionTitle={invoice?.model_type === invoicesTypesMap.case && 'Add'}
      buttonSize="small"
      title={`${incomes?.length || 0} Incomes`}
      TypographyProps={{ fontSize: 'medium' }}
      onCreate={addIncome}
      onOpen={handleOpenListItem}
    >
      <Loader loading={!isFetched} p={2} render={
        () => (
          <List disablePadding>
            <Incomes
              parentPage
              width={width}
              invoice={invoice}
              incomes={incomes}
              setTotal={setTotal}
              totalTime={totalTime}
              onTotalTimeUpdate={setTotalTime}
            />
          </List>
        )}
      />
    </NestedList>
  );
};
