import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { TracksList } from '../TracksList';
import { MainForm } from './MainForm';

export const IncomeForm = ({ parentPage, model }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { values } = useFormikContext();

  return (
    <>
      <MainForm model={model} />

      <Box display="flex" flexDirection="column" height="100%" mt={2}>
        <Box px={isMobile ? 2 : 0}>
          <Typography variant="h6">Worklogs</Typography>
        </Box>

        <Box bgcolor={isMobile ? 'inherit' : 'grey.50'} minHeight={500}>
          <TracksList
            initialModelID={model?.id}
            parentPage={parentPage}
            model={values?.model}
            model_id={values?.model_id}
          />
        </Box>
      </Box>
    </>
  );
};
