import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import printJS from 'print-js';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { getUserFullName } from '../../../../../helpers/users';
import { api } from '../../../../../api';
import * as prescriptionsApi from '../../../../../api/profile';
import { IconButton } from '../../../../../components/IconButton';
import { Tooltip } from '../../../../../components/Tooltip';
import { Loader } from '../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderActions
} from '../../../../../components/Modal';
import { Body } from './Body';

const actionsMap = {
  print: 'print',
  download: 'download'
};

export const PrescriptionPreview = ({
  DialogProps,
  handleModalReject,
  payload: { id }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const cancelFetch = useRef(() => {});
  const [ prescription, setPrescription ] = useState(null);
  const [ activeAction, setActiveAction ] = useState(null);

  const handleFetchTemplate = () => {
    cancelFetch.current();

    prescriptionsApi.fetchPrescription(id, {
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then(setPrescription).catch(() => {
      enqueueSnackbar('Something goes wrong', { variant: 'error' });
    });
  };

  const downloadPDF = () => {
    setActiveAction(actionsMap.download);

    prescriptionsApi.generatePDF(prescription.id).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      saveAs(url, `${prescription.name}-${getUserFullName(prescription.patient)}.pdf`);
    }).finally(() => {
      setActiveAction(null);
    });
  };

  const printPDF = () => {
    setActiveAction(actionsMap.print);

    prescriptionsApi.generatePDF(prescription.id).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      printJS({ printable: url, type: 'pdf' });
    }).finally(() => {
      setActiveAction(null);
    });
  };

  useEffect(() => {
    handleFetchTemplate();
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <Loader p={3} loading={!prescription} render={
        () => (
          <ModalContainer>
            <ModalHeader onClose={handleModalReject}>
              {prescription.name}

              {!isMobile && (
                <ModalHeaderActions disableGutters>
                  <Loader surface loading={activeAction === actionsMap.print} render={
                    () => (
                      <Tooltip title="Print prescription">
                        <IconButton onClick={printPDF}>
                          <PrintOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  />

                  <Loader surface loading={activeAction === actionsMap.download} render={
                    () => (
                      <Tooltip title="Download prescription as PDF">
                        <IconButton onClick={downloadPDF}>
                          <DownloadIcon/>
                        </IconButton>
                      </Tooltip>
                    )}
                  />
                </ModalHeaderActions>
              )}
            </ModalHeader>

            <ModalBody>
              <Body prescription={prescription} />
            </ModalBody>
          </ModalContainer>
        )}
      />
    </Dialog>
  );
};
