export const styles = ({ spacing, palette: { grey } }) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    border: '1px solid',
    borderColor: grey[200]
  },

  loader: {
    padding: spacing(2)
  }
});
