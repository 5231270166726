export const styles = ({ spacing, palette }) => ({
  root: {
    border: `1px solid ${palette.divider}`,
    borderBottomWidth: 0,
    marginTop: spacing(2)
  },

  header: {
    paddingLeft: spacing(),
    paddingRight: spacing()
  },

  textField: {
    minWidth: 0
  },

  cardWrapper: {
    display: 'grid',
    gridTemplateColumns: ({ size = 290 }) => `repeat(auto-fill, minmax(${size}px, 1fr))`,
    gridGap: spacing(2),
    marginBottom: spacing(2),
    padding: spacing(2),
    paddingBottom: 0
  }
});
