import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  isMulti: Yup.bool(),
  users_id: Yup.array().of(Yup.number().required()).nullable().when('isMulti', (isMulti, schema) => {
    return isMulti ? schema.required() : schema;
  }),
  user_id: Yup.number().nullable().when('isMulti', (isMulti, schema) => {
    return isMulti ? schema : schema.required();
  })
});
