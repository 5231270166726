export const styles = () => ({
  tab: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    maxWidth: 'max-content'
  }
});
