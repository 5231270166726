import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { CardItem } from '../../../../../../../components/Cards';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { CurrencyField, DatePicker } from '../../../../../../../components/FormField';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const PaymentCard = ({ paidDate, isOverdraftPage, invoice, total, balance }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <CardItem py={1} px={2}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>
            Invoice Paid Date
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography>
            {paidDate || '-'}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" alignItems="center" height="100%">
            <Typography>
              Due On
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={7}>
          {isOverdraftPage ?
            <Typography color="secondary">
              {invoice.due_date && moment(invoice.due_date).format('L')}
            </Typography>
            :
            <DatePicker
              outputFormat="YYYY-MM-DD"
              name="due_date"
              label="Due on"
              className={cn(classes.invoiceInfoField, classes.invoiceInfoField_date)}
            />
          }
        </Grid>

        <Grid item xs={5}>
          <Typography>
            Total
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <CurrencyFormat
            value={total}
            className={classes.balance}
          />
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" alignItems="center" height="100%">
            <Typography>
              Payment Amount
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center" height="100%">
            {isOverdraftPage ?
              <CurrencyFormat value={values.amount} className={classes.balance} />
              :
              <CurrencyField
                name="amount"
                label="Amount"
                className={classes.invoiceInfoField}
              />
            }
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Typography>
            Balance
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center">
            <CurrencyFormat value={balance} className={classes.balance} />
          </Box>
        </Grid>
      </Grid>
    </CardItem>
  );
};
