export const filterFieldsMap = {
  appointmentFrom: 'appointment_from',
  appointmentTo: 'appointment_to',
  appointmentBooks: 'appointment_books',
  providers: 'providers',
  offices: 'offices'
};

export const filterFieldsLabels = {
  [filterFieldsMap.appointmentFrom]: 'Appt.from',
  [filterFieldsMap.appointmentTo]: 'Appt.to',
  [filterFieldsMap.appointmentBooks]: 'Appt.books',
  [filterFieldsMap.providers]: 'Providers',
  [filterFieldsMap.offices]: 'Offices'
};
