export const ALL_SCHEDULES = 'ALL_SCHEDULES';
export const WORK_INFO = 'WORK_INFO';
export const MEDICAL_INFO = 'MEDICAL_INFO';
export const TASKS = 'TASKS';
export const ACTIVITY = 'ACTIVITY';
export const EMAIL = 'EMAIL';
export const FILES = 'FILES';
export const ANALYTICS_DATA = 'ANALYTICS_DATA';
export const TIME_TRACKING_BILLING = 'TIME_TRACKING_BILLING';
export const ARCHIVE = 'ARCHIVE';
