import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { CompanyLink } from '../../../../CompanyLink';
import { UserLink } from '../../../../UserLink';
import { orderByLabelsMap } from '../orderByOptions';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {!!params?.companies?.length &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Companies:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.companies?.map((company) => (
                <Box display="flex" width="100%" alignItems="center" key={company?.id}>
                  <CompanyLink
                    noWrap
                    size="sm"
                    key={company.id}
                    company={company}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.users?.length &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Users:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.users?.map((user) => (
                <Box display="flex" width="100%" alignItems="center" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    key={user.id}
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.started_from &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Date From:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment(params.started_from).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.started_to &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Date To:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment(params.started_to).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.order_by &&
        <>
          <Grid item xs={4}>
            <Typography noWrap>
              Order by:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {orderByLabelsMap[params?.order_by]}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
