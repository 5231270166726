import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

export const PhoneContact = ({ value, format = '(###) ###-####', ...props }) => {
  return value ? (
    <NumberFormat
      mask="_"
      isNumericString
      value={value}
      format={format}
      displayType="text"

      {...props}
    />
  ) : (
    <Typography color="textSecondary">No info.</Typography>
  );
};
