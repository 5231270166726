export const painLevelColors = [
  '#00BCD4',
  '#7BF900',
  '#A5F900',
  '#CEF900',
  '#F8F900',
  '#F9D000',
  '#F9A701',
  '#F97D01',
  '#F95401',
  '#F92A01',
  '#fb0000',
  '#ff0000'
];
