import { mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { flow } from 'lodash';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { SavedFiltersProvider } from '../SavedFiltersProvider';
import { ModalContent } from './ModalContent';

export const SavedFiltersModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    filterKey,
    hiddenFields = [],
    relationsForFilter,
    onApplySavedFilter,
    CardContent,
    onFilterAdd,
    onFilterRemove
  }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <ModalHeader
          icon={<SvgIcon color="primary"><Icon path={mdiFilter} /></SvgIcon>}
          onClose={handleModalReject}
        >
          Saved Filters Modal
        </ModalHeader>

        <SavedFiltersProvider filter={{ entity: filterKey }}>
          <ModalContent
            hiddenFields={hiddenFields}
            relationsForFilter={relationsForFilter}
            onApplySavedFilter={flow(onApplySavedFilter, handleModalResolve)}
            CardContent={CardContent}
            onFilterAdd={onFilterAdd}
            onFilterRemove={onFilterRemove}
          />
        </SavedFiltersProvider>

        <ModalFooter>
          <Button onClick={handleModalReject}>Cancel</Button>
        </ModalFooter>
      </ModalContainer>
    </Dialog>
  );
};
