import { BillingSettingsContextProvider } from './BillingSettingsContext';
import { MainContent } from './MainContent';

export const BillingSettings = () => {
  return (
    <BillingSettingsContextProvider>
      <MainContent />
    </BillingSettingsContextProvider>
  );
};
