import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Dialog,
  Button,
  SvgIcon,
  IconButton,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { mdiEmailEditOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as emailsApi from '../../../../../api/emails';
import {
  TextField,
  Editor,
  transformAccountToOption
} from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import {
  ModalContainer,
  ModalHeader,
  ModalHeaderActions,
  ModalBody,
  ModalFooter
} from '../../../../../components/Modal';
import { FilePicker } from '../../../../../components/FilePicker';
import { useModal } from '../../../../../components/ModalsProvider';
import { SaveEmailToDraftsModal } from '../SaveEmailToDraftsModal';
import { sendValidationSchema } from '../sendValidationSchema.js';
import { getSignature } from '../signatureHelpers';
import { EmailsUsers } from './EmailsUsers';
import { Rules } from './Rules';

export const EmailsSendModal = (({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    message,
    file = null,
    to = [],
    caseItem = null,
    patient = null
  }
}) => {
  const [ fullScreen, setFullScreen ] = useState(false);
  const [ attachments, setAttachments ] = useState([]);
  const [ availableFilesIDs, setAvailableFilesIDs ] = useState([]);
  const [ usersTo, setUsersTo ] = useState(to || []);
  const currentUser = useSelector(({ profile }) => profile.user);
  const accounts = currentUser?.email_accounts;
  const accountsOptions = accounts?.map(transformAccountToOption);
  const selectedConfigId = accountsOptions?.[0]?.value;
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const signature = getSignature(accountsOptions?.[0]?.data?.signature_line?.html);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const handleAttachments = (files) => {
    setAttachments(files);
  };

  const handleAvailableFilesIDs = (filesIDs) => {
    setAvailableFilesIDs(filesIDs);
  };

  const getFormData = (config) => {
    const formData = new FormData();

    config.patient_id && formData.append('patient_id', config.patient_id);
    config.case_id && formData.append('case_id', config.case_id);

    formData.append('config_id', config.config_id);
    formData.append('subject', config.subject);
    formData.append('body', config.body);

    config.to.forEach((to) => {
      formData.append('to[]', to);
    });

    if (config.cc) {
      config.cc.forEach((cc) => {
        formData.append('cc[]', cc);
      });
    }

    if (config.bcc) {
      config.bcc.forEach((bcc) => {
        formData.append('bcc[]', bcc);
      });
    }

    attachments.forEach((attachment) => {
      formData.append('files[]', attachment, attachment.name);
    });

    availableFilesIDs.forEach((fileID) => {
      formData.append('attach_media_id[]', fileID);
    });

    return formData;
  };

  const handleCancel = (config) => () => {
    if (!config.subject || !config.to || !config.body) {
      handleModalReject();
      return;
    }

    openModal(SaveEmailToDraftsModal, {
      payload: { config }
    });

    handleModalReject();
  };

  const sendEmail = (config, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const formData = getFormData(config);

    return emailsApi.sendEmail(formData).then(() => {
      handleModalResolve();
      enqueueSnackbar('Email sent successfully', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Email not sent', { variant: 'error' });
    });
  };

  return (
    <Dialog
      disableEnforceFocus
      fullWidth={!fullScreen}
      fullScreen={fullScreen || isMobile}
      PaperProps={{
        style: {
          maxWidth: fullScreen ? null : 700
        }
      }}
      {...DialogProps}
    >
      <Formik
        enableReinitialize
        initialValues={{
          config_id: selectedConfigId,
          to: message?.to || to || [],
          subject: message?.subject || null,
          body: !signature ? message?.body : (message?.body || '') + signature,
          case_id: caseItem?.id || null,
          patient_id: patient?.id || null,
          all_case_users: false
        }}
        validationSchema={sendValidationSchema}
        onSubmit={sendEmail}
      >
        {({ isSubmitting, values, handleSubmit }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiEmailEditOutline} /></SvgIcon>} onClose={handleCancel(values)}>
              Compose mail

              {!isMobile && (
                <ModalHeaderActions disableGutters>
                  <IconButton onClick={handleFullScreen}>
                    {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </IconButton>
                </ModalHeaderActions>
              )}
            </ModalHeader>

            <ModalBody>
              <EmailsUsers
                message={message}
                caseItem={caseItem}
                accountsOptions={accountsOptions}
                setUsersTo={setUsersTo}
              />

              <TextField
                required
                name="subject"
                label="Subject"
                margin="dense"
              />

              {(values.case_id || values.patient_id) &&
                <Rules usersTo={usersTo} accounts={accounts} caseItem={caseItem} patient={patient} />
              }

              <Editor name="body" margin="normal" />

              <FilePicker
                initialFile={file}
                onChangeFiles={handleAttachments}
                onChangeAvailableFilesIDs={handleAvailableFilesIDs}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleCancel(values)} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Send
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
});
