export const arrivalTypesMap = {
  available: 'Available',
  arrived: 'Arrived',
  cancelled: 'Cancelled',
  checkOut: 'Check-Out',
  closed: 'Closed',
  noShow: 'No Show',
  open: 'Open',
  preArrival: 'Pre-Arrival',
  rescheduled: 'Rescheduled',
  scheduled: 'Scheduled',
  waitingList: 'Waiting List',
  followUp: 'Follow Up'
};

export const arrivalTypes = {
  [arrivalTypesMap.available]: {
    type: arrivalTypesMap.available,
    name: 'Available',
    color: '#80DEEA'
  },
  [arrivalTypesMap.arrived]: {
    type: arrivalTypesMap.arrived,
    name: 'Arrived',
    color: '#00BCD4'
  },
  [arrivalTypesMap.cancelled]: {
    type: arrivalTypesMap.cancelled,
    name: 'Cancelled',
    color: '#F44336'
  },
  [arrivalTypesMap.checkOut]: {
    type: arrivalTypesMap.checkOut,
    name: 'Check-Out',
    color: '#8BC34A'
  },
  [arrivalTypesMap.closed]: {
    type: arrivalTypesMap.closed,
    name: 'Closed',
    color: '#C4C4C4'
  },
  [arrivalTypesMap.noShow]: {
    type: arrivalTypesMap.noShow,
    name: 'No Show',
    color: '#FDBC00'
  },
  [arrivalTypesMap.open]: {
    type: arrivalTypesMap.open,
    name: 'Open',
    color: '#48D2A0'
  },
  [arrivalTypesMap.preArrival]: {
    type: arrivalTypesMap.preArrival,
    name: 'Pre-Arrival',
    color: '#03A9F4'
  },
  [arrivalTypesMap.rescheduled]: {
    type: arrivalTypesMap.rescheduled,
    name: 'Rescheduled',
    color: '#9C27B0'
  },
  [arrivalTypesMap.scheduled]: {
    type: arrivalTypesMap.scheduled,
    name: 'Scheduled',
    color: '#28A44A'
  },
  [arrivalTypesMap.followUp]: {
    type: arrivalTypesMap.followUp,
    name: 'Follow Up',
    color: '#b21609'
  },
  [arrivalTypesMap.waitingList]: {
    type: arrivalTypesMap.waitingList,
    name: 'Waiting List',
    color: '#898989'
  },
  default: {
    type: 'default',
    name: 'Default',
    color: '#898989'
  }
};

export const arrivalStatusMap = {
  followUp: 51
};

export const arrivalTypesOptions = Object.values(arrivalTypes);
