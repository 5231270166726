import { useContext } from 'react';
import cn from 'classnames';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { TaskPreviewContext } from '../../../../../components/tasks/TasksProvider';
import { AttachmentsContainer } from '../../AttachmentsContainer';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PreviewTaskBody = ({ isStaticPage }) => {
  const classes = useStyles();
  const { selectedTask } = useContext(TaskPreviewContext);

  return (
    <div className={!isStaticPage ? classes.root : classes.static}>
      <div className={classes.description}>
        <Typography className={classes.title}>
          Description
        </Typography>
      </div>

      <div className={cn(classes.content, isStaticPage && classes.content_static)}>
        <Typography color="textSecondary">
          {selectedTask.description || 'No description'}
        </Typography>
      </div>

      <Box height={240}>
        <AttachmentsContainer
          disableFetching
          filter={{ tasks: [ selectedTask.id ] }}
          files={selectedTask.files}
          ownerType="task"
          ownerID={selectedTask.id}
        />
      </Box>
    </div>
  );
};
