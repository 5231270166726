import { actionOptions, conditionOptions } from '../../options';

export const getRuleInitialValues = ({ initialRule }) => {
  return {
    ...initialRule,

    name: initialRule.name,
    conditions: initialRule?.conditions?.map((condition) => ({
      key: conditionOptions.find((conditionOption) => conditionOption.value === condition.key),
      values: condition?.values || []
    })).filter((item) => !!item.values?.length) || [ { key: conditionOptions[0], values: [] } ],
    actions: initialRule?.actions?.map((action) => ({
      key: actionOptions.find((actionOption) => actionOption.value === action.key),
      value: action.value || null
    })).filter((item) => !!item.key) || [ { key: actionOptions[0] } ]
  };
};
