import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { UserContext } from '../../../app/Dashboard/Members/User/UserProvider';
import { LayoutContextProvider } from '../../LayoutContext';
import { TasksProvider, TasksFilterContextProvider } from '../TasksProvider';
import { Card, getFiltersValuesFromOptions } from './Card';

export const TasksWidget = ({ caseItem, hello, ...props }) => {
  const userContext = useContext(UserContext);
  const profile = useSelector(({ profile }) => profile);
  const user = !caseItem && (userContext ? userContext.user : profile.user);
  const initialState = {
    filter: {
      users: !caseItem ? (user ? [ user.id ] : []) : null,
      cases: caseItem ? [ caseItem.id ] : null,

      ...getFiltersValuesFromOptions(props?.widgetFilters?.tasks)
    }
  };

  return (
    <LayoutContextProvider>
      <TasksFilterContextProvider filter={initialState.filter}>
        <TasksProvider>
          <Card caseItem={caseItem} {...props} />
        </TasksProvider>
      </TasksFilterContextProvider>
    </LayoutContextProvider>
  );
};
