export const styles = ({ spacing }) => ({
  endAdornment: {
    position: 'absolute',
    bottom: 5,
    right: 0
  },

  clearIndicator: {
    padding: spacing(0.5),
    marginRight: spacing(-0.25)
  },

  input_multiple: {
    flexWrap: 'wrap',

    '& > input': {
      minWidth: 0
    }
  },

  input_open: {
    '&$input_multiple > input': {
      minWidth: '100%'
    }
  },

  input_WithLoadingIndicator: {
    paddingRight: spacing(3)
  },

  input_WithClearButton: {
    paddingRight: spacing(3)
  },

  input_WithDropdownButton: {
    paddingRight: spacing(3)
  },

  input: {
    [[
      '&$input_WithLoadingIndicator&$input_WithClearButton,',
      '&$input_WithLoadingIndicator&$input_WithDropdownButton,',
      '&$input_WithClearButton&$input_WithDropdownButton'
    ].join('')]: {
      paddingRight: spacing(6)
    }
  },

  '$input_WithLoadingIndicator$input_WithClearButton$input_WithDropdownButton': {
    paddingRight: spacing(9)
  }
});
