import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { MobileWidgets } from '../../../components/Widgets/MobileWidgets';
import * as pages from '../../../store/dashboard/layout/pages';
import { Widgets } from '../../../components/Widgets';
import {
  cardsLayout,
  typesOfWidgets,
  checkedWidgetsTypes
} from './Layout';
import { widgetsMap } from './Widgets/widgetsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Analytics = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <div className={classes.root}>
      {isTablet ? (
        <MobileWidgets
          page={pages.statistics}
          widgetsMap={widgetsMap}
          checkedWidgetsTypes={checkedWidgetsTypes}
        />
      ) : (
        <Widgets
          rowHeight={30}
          page={pages.statistics}
          cardsLayout={cardsLayout}
          extraTypes={typesOfWidgets}
          widgetsMap={widgetsMap}
          checkedWidgetsTypes={checkedWidgetsTypes}
        />
      )}
    </div>
  );
};
