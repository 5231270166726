import { mdiCameraPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import { makeStyles, SvgIcon } from '@material-ui/core';
import { Badge } from '../../../../../../components/Badge';
import { IconButton } from '../../../../../../components/IconButton';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Avatar = ({ size, align, croppedImageUrl, onUploadAvatar }) => {
  const classes = useStyles({ size, align });

  return (
    <Badge
      anchorOrigin={{ horizontal: align || 'right', vertical: 'bottom' }}
      badgeContent={
        <IconButton
          color="primary"
          onClick={onUploadAvatar}
          className={classes.badgeButton}
        >
          <SvgIcon>
            <Icon path={mdiCameraPlus} />
          </SvgIcon>
        </IconButton>
      }
      classes={{ badge: classes.badge }}
    >
      <div className={classes.imageCroppedContent}>
        <img alt="Crop" src={croppedImageUrl} className={classes.image}/>
      </div>
    </Badge>
  );
};
