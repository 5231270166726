import { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { amFormatDuration } from '../../../../../helpers/dates';
import {
  activityTimerStart,
  activityTimerStop
} from '../../../../../store/dashboard/activityTimer';
import { getRouterLinkByActivityType } from './getRouterLinkByActivityType';

class ActivityItem extends Component {
  render() {
    const {
      activeTimer,
      activityTimerStart,
      activityTimerStop,
      isPlaying,
      activityItem
    } = this.props;

    return (
      <ListItem
        button
        onClick={this.handleClose}
        component={Link}
        to={getRouterLinkByActivityType(activityItem)}
      >
        <ListItemText
          primary={activityItem?.time_trackingable?.name}
          secondary={
            isPlaying ?
              amFormatDuration(+activityItem?.full_time + activeTimer?.time)
              :
              amFormatDuration(+activityItem?.full_time)
          }
        />

        <ListItemSecondaryAction>
          {isPlaying ?
            <IconButton
              title="Stop work"
              onClick={activityTimerStop}
            >
              <StopIcon />
            </IconButton>
            :
            <IconButton
              title="Start work"
              onClick={() => activityTimerStart(activityItem)}
            >
              <PlayIcon />
            </IconButton>
          }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

const mapStateToProps = ({ activityTimer }) => ({
  activeTimer: activityTimer.activeTimer,
  isTimerFetching: activityTimer.isFetching
});

ActivityItem = connect(mapStateToProps, {
  activityTimerStart,
  activityTimerStop
})(ActivityItem);

export { ActivityItem };
