import { Yup } from '../../utils/validation';

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required().min(2).max(36),
  last_name: Yup.string().nullable().required().min(2).max(36),
  email: Yup.string().email().nullable().required(),
  phone_number: Yup.string().nullable().min(2).max(20),
  title: Yup.string().nullable(),
  industry: Yup.string().nullable(),
  company_revenue: Yup.string().nullable(),
  number_of_employees: Yup.string().nullable()
});
