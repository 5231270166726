import * as codesApi from '../../../../api/codes/procedures';
import { Autocomplete } from '../../Autocomplete';

const fetchNdcCodes = ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return codesApi.fetchNDCCodes({
    params: {
      code: search,
      is_active: 1,
      page: page + 1
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

export const NdcCodesSelect = ({ formattedValue = true, ...props }) => {
  return (
    <Autocomplete
      isAsync
      placeholder="Select code..."
      onNeedFetch={fetchNdcCodes}
      getOptionLabel={(option) => option && `${option?.code} - ${option.description || option.name}`}
      getOptionValue={(option) => !formattedValue ? option : option?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}

      {...props}
    />
  );
};
