import { useFormikContext } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '../../IconButton';
import { useModal } from '../../ModalsProvider';
import { ReadonlyCheckbox } from '../../ReadonlyCheckbox';
import { Autocomplete } from '../Autocomplete';
import { InvoicesListModal } from './InvoicesListModal';

export const InvoicesSelect = ({ name, params = {}, onChange = () => {}, ...props }) => {
  const { openModal } = useModal();
  const { setFieldValue } = useFormikContext();

  const handleOpenInvoicesListModal = () => {
    openModal(InvoicesListModal, {
      onModalResolved: (invoice) => {
        setFieldValue(name, invoice);
        onChange(invoice);
      }
    });
  };

  return (
    <Autocomplete
      open={false}
      name={name}
      getOptionLabel={(option) => option && option?.number}
      getOptionValue={(invoice) => invoice?.id}
      TextFieldProps={{
        onClick: handleOpenInvoicesListModal
      }}
      getInputProps={(value) => !value ? null : ({
        startAdornment: (
          <ReadonlyCheckbox disableTitle checked={value?.is_confirmed} />
        )
      })}
      openButton={
        <IconButton size="small">
          <SearchIcon />
        </IconButton>
      }

      {...props}
    />
  );
};
