import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { itemsRoutesByModels } from '../../../../../../../dataMaps/apiModels';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { Body } from './Body';
import { Footer } from './Footer';
import { Header } from './Header';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ item: caseItem }) => {
  const classes = useStyles();
  const history = useHistory();

  const getCasePage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.case(caseItem.id));
    }
  };

  return (
    <div className={classes.root} onClick={getCasePage}>
      <Header caseItem={caseItem} />

      <div className={classes.grow}>
        <Scrollbars>
          <Body caseItem={caseItem} />
        </Scrollbars>
      </div>

      <Footer caseItem={caseItem} />
    </div>
  );
};
