import * as timeTracksApi from '../../../../api/time-tracks';
import { Autocomplete } from '../../Autocomplete';

const fetchCategories = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return timeTracksApi.fetchCategories({
    params: {
      name: search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const getOptionValue = (option) => option?.id;

const getOptionSelected = (option, value) => option.id === value?.id;

const getOptionLabel = (option) => {
  return option?.name;
};

export const TimeTracksCategoriesSelect = ({ params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      name="category"
      label="Category"
      placeholder="Select category..."
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      getOptionSelected={getOptionSelected}
      onNeedFetch={fetchCategories(params)}

      {...props}
    />
  );
};
