import { MenuItem } from '@material-ui/core';
import { Tag } from '../../../Tag';

export const Option = ({ children, ...props }) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isSelected}

      {...props.innerProps}
    >
      <Tag tag={children} />
    </MenuItem>
  );
};
