import moment from 'moment';
import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  is_main: Yup.bool().nullable(),
  is_icd: Yup.bool().nullable(),
  status_type: Yup.string().nullable(),
  financial_class_id: Yup.mixed().nullable(),
  insurance_company_id: Yup.mixed().nullable().required(),
  office_id: Yup.mixed().nullable().required(),
  tpl_id : Yup.mixed().nullable(),
  provider_id: Yup.mixed().nullable(),
  spc: Yup.number().nullable(),
  pcp: Yup.number().nullable(),
  insuranceId: Yup.string().nullable().max(20),
  groupId: Yup.string().nullable().max(20),
  type_insurance: Yup.string().nullable(),
  effective_day: Yup.date().format('X').nullable()
    .max(moment().add(1, 'minute'), 'Must be more than today'),
  expiration_day: Yup.date().format('X').nullable()
    .test(
      'minDate',
      'Should not be less than effective day date',
      (value) => !value || moment(value).isSameOrAfter(moment())
    )
    .when('effective_day', (effectiveDay, schema) => {
      const dateMomentDate = moment(effectiveDay);

      return dateMomentDate.isValid()
        ? schema.min(dateMomentDate.add(1, 'minute'), 'Must be after the effective day date')
        : schema;
    }),
  subscriber_info: Yup.object().when('status_type', (statusType, schema) => {
    return statusType !== 'case_insurance' ? schema.shape({
      address_same_as_patient: Yup.bool().nullable(),
      subscriber: Yup.string().nullable(),
      first_name: Yup.string().nullable().max(255),
      last_name: Yup.string().nullable().max(255),
      dob: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment(), 'Birthday must be at earlier than now'),
      gender: Yup.string().nullable().max(255),
      phone_number: Yup.string().nullable().max(255),
      designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
        return unitNumber ? schema.required() : schema;
      }),
      unit_number: Yup.string().nullable().min(1).max(255)
    }) : schema;
  }),
  case_id: Yup.mixed().nullable().when('status_type', (statusType, schema) => {
    return statusType === 'case_insurance' ? schema.required() : schema;
  }),
  case_user_id: Yup.mixed().nullable().when([ 'case_id', 'mainPage' ], (caseID, mainPage, schema) => {
    return !!caseID || mainPage ? schema.required() : schema;
  }),
  phone_number: Yup.string().nullable().max(254),
  attorney_id: Yup.mixed().nullable(),
  provider_adj_id: Yup.mixed().nullable(),
  attorney_adj_id: Yup.mixed().nullable(),
  policy_number: Yup.string().nullable().max(254),
  claim_number: Yup.string().nullable().max(254),
  insured: Yup.string().nullable().max(254),
  prop_two_one_three: Yup.bool().nullable(),
  vehicle: Yup.object().when('status_type', (statusType, schema) => {
    return statusType === 'case_insurance' ? schema.shape({
      license_number: Yup.string().nullable().max(254),
      year: Yup.date().format('X').nullable(),
      model: Yup.string().nullable().max(254),
      color: Yup.string().nullable().max(254)
    }) : schema;
  }),
  liability_limit: Yup.string().nullable().max(254),
  um_uim: Yup.string().nullable().max(254),
  medpay: Yup.string().nullable().max(254),
  medpay_requested: Yup.bool().nullable(),
  refused_dislosure: Yup.bool().nullable(),
  medpay_received_date: Yup.date().format('YYYY-MM-DD').nullable(),
  requested_limit_date: Yup.date().format('YYYY-MM-DD').nullable(),
  deductible: Yup.string().nullable().max(254),
  driver: Yup.string().nullable().max(254),
  rental: Yup.number().nullable(),
  tow: Yup.string().nullable().max(254),
  dl_number: Yup.string().nullable().max(254),
  umbrella_insurance: Yup.string().nullable().max(254),
  policy_limit: Yup.string().nullable().max(254)
});
