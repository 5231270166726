const painTypes = {
  weakness: 'weakness',
  muscle_spasms: 'muscle_spasms',
  stiffness: 'stiffness',
  limping: 'limping',
  numbness: 'numbness',
  tingling: 'tingling'
};

const painResultsTypes = {
  headaches: 'headaches',
  dizziness: 'dizziness',
  loss_of_memory: 'loss_of_memory',
  difficulty_thinking: 'difficulty_thinking'
};

export const painMap = {
  [painTypes.weakness]: 'Weakness',
  [painTypes.muscle_spasms]: 'Muscle spasms',
  [painTypes.stiffness]: 'Stiffness',
  [painTypes.limping]: 'Limping',
  [painTypes.numbness]: 'Numbness',
  [painTypes.tingling]: 'Tingling'
};

export const painResultMap = {
  [painResultsTypes.headaches]: 'Headaches',
  [painResultsTypes.dizziness]: 'Dizziness',
  [painResultsTypes.loss_of_memory]: 'Loss of memory',
  [painResultsTypes.difficulty_thinking]: 'Difficulty thinking'
};
