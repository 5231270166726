export const styles = ({ palette, shadows }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxShadow: shadows[2],
    backgroundColor: palette.background.default,
    overflowX: 'hidden'
  }
});
