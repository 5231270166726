export const styles = ({ spacing }) => ({
  root: {
    padding: spacing(0, 3, 1, 3),
    minHeight: 50
  },

  actionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    marginLeft: 'auto'
  }
});
