import { get } from 'lodash';
import { List, makeStyles } from '@material-ui/core';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const BodyParts = ({ content }) => {
  const classes = useStyles();
  const symptoms = Object.keys(get(content, 'where_is_pain', []));

  return !!symptoms?.length && (
    <div className={classes.content}>
      <List disablePadding className={classes.list}>
        {symptoms.map((item, i) => (
          <Row key={i} item={item} content={content} />
        ))}
      </List>
    </div>
  );
};
