import { makeStyles, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const styles = ({ spacing, palette }) => ({
  root: {
    flexShrink: 0,
    color: palette.text.secondary,
    marginLeft: spacing(2.5)
  }
});

const useStyles = makeStyles(styles);

export const TablePaginationActions = ({ count, page, rowsPerPage, onChangePage }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileSM = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));
  const paginationProps = isMobileSM ? { size: 'small' } : {};

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(
      event,
      Math.max(0, Math.ceil(count / rowsPerPage) - 1)
    );
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
        {...paginationProps}
      >
        <FirstPageIcon />
      </IconButton>

      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
        {...paginationProps}
      >
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
        {...paginationProps}
      >
        <KeyboardArrowRight />
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
        {...paginationProps}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};
