import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import * as pages from '../../../../../store/dashboard/layout/pages';
import { Widgets } from '../../../../../components/Widgets';
import { Loader } from '../../../../../components/Loader';
import { UserContext } from '../../../Members/User/UserProvider';
import { cardsLayout, checkedWidgetsTypes, extraTypes } from './Layouts';
import { widgetsMap } from './Widgets';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const ProfileMainInfo = () => {
  const classes = useStyles();
  const currentUser = useSelector(({ profile }) => profile.user);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : currentUser;

  return (
    <div className={classes.root}>
      <Loader loading={!user?.medical?.id} render={
        () => (
          <Widgets
            rowHeight={28}
            page={pages.medicalProfile}
            cardsLayout={cardsLayout}
            extraTypes={extraTypes}
            widgetsMap={widgetsMap}
            checkedWidgetsTypes={checkedWidgetsTypes}
          />
        )}
      />
    </div>
  );
};
