import { SvgIcon } from '@material-ui/core';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import { Icon } from '@mdi/react';
import { mdiClipboardTextClockOutline } from '@mdi/js';

export const workSessions = {
  name: 'Work Sessions',
  icon: AlarmOnOutlinedIcon,
  path: '/work-sessions',
  routes: [
    {
      exact: true,
      name: 'Work Sessions',
      icon: AlarmOnOutlinedIcon,
      path: '/work-sessions'
    },
    {
      name: 'Time Reports',
      icon: () => <SvgIcon><Icon path={mdiClipboardTextClockOutline} /></SvgIcon>,
      path: '/work-sessions/time-reports'
    }
  ]
};
