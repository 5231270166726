import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { CaseUsersSelect } from '../../../../../../components/cases/CaseUsersSelect';
import { saveFilter } from '../../../../../../store/lastFilters';
import { participantsUserRoles } from '../../../../../../dataMaps';
import { IconComponent } from '../../../../../../components/saved-filters';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import {
  Select,
  TextField,
  KeyboardDatePicker,
  CaseTypesSelect,
  CasesStatusesSelect,
  orderDirectionsMap,
  OrderByAutocomplete
} from '../../../../../../components/FormField';
import { fieldsKeysMap, filterLabels } from '../../../../ProfilePage/MedicalInfo/MedicalForms/FormFiltersBar';
import { CasesFilterContext } from '../../CasesContext';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { orderByOptions } from './orderByOptions';
import { CardContent } from './CardContent';
import { List, columnsMap } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const MODAL_WIDTH = 1120;

const initialValues = {
  created_from: null,
  created_to: null,
  order_by: orderByOptions[0],
  order_direction: orderDirectionsMap.desc
};

export const FiltersBar = ({ filterKey }) => {
  const classes = useStyles();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { filter, setFilter } = useContext(CasesFilterContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState(initialValues);

  const handleFieldChange = (name) => debounce((event) => {
    const value = event?.target?.value;

    setFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleDatePickerChange = (name) => (date) => {
    setFilter({ [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleSelectChange = (name) => (option) => {
    setFilter({ [name]: option?.value });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data || option }));
  };

  const handleTypesSelectChange = (types) => {
    setFilter({ types: types?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, types }));
  };

  const handleStatusesSelectChange = (statuses) => {
    setFilter({ statuses: statuses?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, statuses }));
  };

  const handleUsersMultiSelect = (name) => (options) => {
    const users = options?.length ? options.map(({ id }) => id) : null;

    setFilter({ [name]: users });
    setRelationsForFilter((state) => ({ ...state, [name]: options }));
  };

  const handleCaseRolesSelectChange = (name) => (options) => {
    const types = options?.map(({ value }) => value) || null;

    setFilter({ [name]: types });
    setRelationsForFilter((state) => ({ ...state, [name]: options || null }));
  };

  const toggleOrderDirection = (orderDirection) => {
    setFilter({ order_direction: orderDirection });
    setRelationsForFilter((state) => ({ ...state, order_direction: orderDirection }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);

    setFilter({
      ...filter,

      case_roles: filter?.case_roles?.map(({ value }) => value),
      users: filter?.users?.map(({ id }) => id),
      types: filter?.types?.map(({ id }) => id),
      statuses: filter?.statuses?.map(({ id }) => id),
      order_by: filter?.order_by?.value
    });
  };

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableTopPaddings
      formikRef={formikRef}
      className={classes.root}
      ToolbarProps={{ className: classes.toolbar }}
      initialValues={initialValues}
      filterKey={filterKey}
      onReset={() => applySavedFilter(initialValues)}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.fileNumber,
          label: filterFieldsLabels[filterFieldsMap.fileNumber],
          field: <Box minWidth={155}>
            <TextField
              name="file_number"
              label="Search by case number"
              placeholder="Search..."
              onChange={handleFieldChange('file_number')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.name,
          label: filterFieldsLabels[filterFieldsMap.name],
          field: <TextField
            name="name"
            label="Search by name"
            placeholder="Search..."
            onChange={handleFieldChange('name')}
          />
        },
        {
          fieldKey: filterFieldsMap.createdFrom,
          label: filterFieldsLabels[filterFieldsMap.createdFrom],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              outputFormat="YYYY-MM-DD"
              name="created_from"
              label="From"
              onChange={handleDatePickerChange('created_from')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.createdTo,
          label: filterFieldsLabels[filterFieldsMap.createdTo],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              outputFormat="YYYY-MM-DD"
              name="created_to"
              label="To"
              onChange={handleDatePickerChange('created_to')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.statuses,
          label: filterFieldsLabels[filterFieldsMap.statuses],
          field: <CasesStatusesSelect
            multiple
            name="statuses"
            label="Statuses"
            onChange={handleStatusesSelectChange}
          />
        },
        {
          fieldKey: filterFieldsMap.caseRoles,
          label: filterFieldsLabels[filterFieldsMap.caseRoles],
          field: <Select
            isMulti
            isClearable
            formattedValue
            name="case_roles"
            label="Filter by case role"
            options={participantsUserRoles}
            onChange={handleCaseRolesSelectChange('case_roles')}
          />
        },
        {
          fieldKey: filterFieldsMap.users,
          label: filterFieldsLabels[filterFieldsMap.users],
          field: <CaseUsersSelect
            multiple
            name="users"
            label="Case Participants"
            params={{ case_roles: filter.case_roles }}
            onChange={handleUsersMultiSelect('users')}
          />
        },
        {
          fieldKey: columnsMap.types,
          label: filterFieldsLabels[filterFieldsMap.types],
          field: <CaseTypesSelect
            multiple
            label="Case Types"
            name="types"
            onChange={handleTypesSelectChange}
          />
        },
        {
          fieldKey: fieldsKeysMap.orderBy,
          label: filterLabels[fieldsKeysMap.orderBy],
          field: (
            <Box minWidth={isMobile ? '100%' : 160}>
              <OrderByAutocomplete
                label="Order By"
                name="order_by"
                options={orderByOptions}
                orderDirection={relationsForFilter.order_direction}
                onOrderDirectionChange={toggleOrderDirection}
                onChange={handleSelectChange('order_by')}
              />
            </Box>
          )
        }
      ]}
    />
  );
};
