export const orderByMap = {
  due_at: 'due_at',
  updated_at: 'updated_at',
  created_at: 'created_at',
  priority: 'priority',
  status_id: 'status_id',
  name: 'name',
  position: 'position'
};

export const orderByLabelsMap = {
  due_at: 'Due date',
  created_at: 'Creation date',
  updated_at: 'Updated date',
  priority: 'Priority',
  status_id: 'Status',
  name:'Name'
};

export const orderByOptions = [
  { label: orderByLabelsMap.due_at, value: orderByMap.due_at },
  { label: orderByLabelsMap.updated_at, value: orderByMap.updated_at },
  { label: orderByLabelsMap.created_at, value: orderByMap.created_at },
  { label: orderByLabelsMap.priority, value: orderByMap.priority },
  { label: orderByLabelsMap.status_id, value: orderByMap.status_id },
  { label: orderByLabelsMap.name, value: orderByMap.name }
];

export const orderCaseByOptions = [
  { label: orderByLabelsMap.due_at, value: orderByMap.due_at },
  { label: orderByLabelsMap.updated_at, value: orderByMap.updated_at },
  { label: orderByLabelsMap.created_at, value: orderByMap.created_at },
  { label: orderByLabelsMap.status_id, value: orderByMap.status_id },
  { label: orderByLabelsMap.name, value: orderByMap.name }
];
