const sidebarWidth = 410;

export const styles = ({ spacing, palette, transitions, breakpoints }) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'stretch',
    padding: spacing(2),
    background: palette.grey[200],

    [breakpoints.down(breakpoints.values.md)]: {
      padding: 0
    }
  },

  body: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100%',
    background: palette.primary.contrastText
  },

  chatOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    background: 'rgba(0,0,0,.3)'
  },

  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    maxWidth: sidebarWidth,
    width: '100%',
    height: '100%',
    borderRight: `1px solid ${palette.divider}`,
    overflow: 'hidden',

    transition: transitions.create([ 'margin' ], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    })
  },

  mobileSidebar: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    maxWidth: sidebarWidth,
    width: '85%',
    height: '100%',
    borderRight: `1px solid ${palette.divider}`,
    background: 'white',
    transition: transitions.create([ 'left' ], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    })
  },

  mobileSidebarClosed: {
    left: -sidebarWidth
  },

  thread: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  }
});
