import { useContext } from 'react';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { TemplatesContext } from '../../../TemplatesProvider';
import { columnsWidths } from '../List';

export const Header = () => {
  const { selectedIDs, allItemsIsSelected, toggleAllItemsSelection } = useContext(TemplatesContext);

  return (
    <ListRow header>
      <ListRowCheckbox
        indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
        checked={allItemsIsSelected()}
        onClick={toggleAllItemsSelection}
      />

      <ListRowCell grow flexBasis={columnsWidths.name}>
        Name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.age}>
        Age group
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.reason}>
        Visit Reason
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        Provider/Office
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions} />
    </ListRow>
  );
};
