import { useEffect, useContext } from 'react';
import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Loader } from '../../../../../../components/Loader';
import { Tag } from '../../../../../../components/Tag';
import { FilesFilterContext, FilesFilterContextProvider, FilesContextProvider } from '../../../../files-common';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Group = ({
  item: tag = null,
  hiddenColumns,
  isLoaded,
  expandedTagID,
  onSelectedPanelChange,
  recalculateHeight = () => {}
}) => {
  const classes = useStyles();
  const { filter } = useContext(FilesFilterContext);
  const isExpanded = isLoaded && (tag ? expandedTagID === tag?.id : tag === expandedTagID);

  const handleSelectedPanelChange = () => {
    onSelectedPanelChange(tag?.id, !isExpanded);
  };

  useEffect(() => {
    recalculateHeight();
  }, [ expandedTagID ]);

  return (
    <div className={cn(classes.root, isExpanded && classes.expanded)}>
      {!isLoaded ? <Loader p={1} /> :
        <>
          <div className={classes.header} onClick={handleSelectedPanelChange}>
            {tag ? (
              <Tag cutTagLabel tag={tag} />
            ) : (
              <Typography variant="h5">
                Files without tags
              </Typography>
            )}

            {!!tag &&
              <div className={classes.filesCount}>
                <AttachFileIcon className={classes.filesCount__icon}/>

                {tag?.media_count}
              </div>
            }

            <div className={classes.expandIcon}>
              {isExpanded ? <ExpandLessIcon color="inherit" /> : <ExpandMoreIcon color="inherit" /> }
            </div>
          </div>

          {isExpanded &&
            <div className={classes.body}>
              <FilesFilterContextProvider filter={{ ...filter, tags: tag ? [ tag.id ] : '' }}>
                <FilesContextProvider>
                  <List hiddenColumns={hiddenColumns} />
                </FilesContextProvider>
              </FilesFilterContextProvider>
            </div>
          }
        </>
      }
    </div>
  );
};
