import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray, isEmpty } from 'lodash';
import {
  generateMinimizeLayout,
  generateIncreaseLayout,
  handleResetLayout,
  handlePutItem,
  handleTakeItem
} from '../../components/Widgets/widgetHelpers';
import { updateCardsLayout, updatePrevCardsLayout } from '../../store/globalUser/actions';
import { updateUserStorage } from '../../store/globalUser/operations';
import { eventBus, eventBusEvents } from '../../utils/eventBus';
import { usePrevious } from './usePrevious';

const minimizedHeight = 1.68;

export const useWidgets = ({ page, extraTypes, cardsLayout, widgetsMap }) => {
  const dispatch = useDispatch();
  const userGlobalStore = useSelector(({ globalUser }) => globalUser);
  const { [page]: { cardsLayoutOptions, prevLayoutsOptions } } = userGlobalStore?.data?.layout;
  const isMinimize = useMemo(() => (
    isArray(prevLayoutsOptions?.isMinimize) || isEmpty(prevLayoutsOptions?.isMinimize)
      ? {} : prevLayoutsOptions?.isMinimize || {}
  ), [ prevLayoutsOptions?.isMinimize ]);
  const [ breakpoint, setBreakpoint ] = useState('lg');
  const [ preventCollision, setPreventCollision ] = useState(true);
  const currentStore = userGlobalStore?.data?.layout;
  const { isFetched } = userGlobalStore;
  const { layouts, toolbox } = cardsLayoutOptions;
  const previous = usePrevious({ layouts, toolbox });

  const onBreakpointChange = (breakpointValue) => {
    setBreakpoint(breakpointValue);
    onUpdatePrevValue({ ...prevLayoutsOptions, ...previous });
  };

  const handleUpdateUserStorage = ({ newToolbox = toolbox, newLayouts, newIsMinimize }) => {
    dispatch(updateUserStorage({
      layout: {
        ...currentStore,

        [page]: {
          ...currentStore[page],

          prevLayoutsOptions: {
            ...prevLayoutsOptions,
            isMinimize: newIsMinimize || isMinimize
          },
          currentLayoutsOptions: null,
          cardsLayoutOptions: {
            breakpoints: cardsLayoutOptions.breakpoints,
            cols: cardsLayoutOptions.cols,
            layouts: newLayouts,
            toolbox: newToolbox
          }
        }
      }
    }));
  };

  const onUpdateCards = (newLayouts = layouts, newToolbox = toolbox) => {
    dispatch(updateCardsLayout({ page, toolbox: newToolbox, layouts: newLayouts }));
  };

  const onUpdatePrevValue = (newPrev = {}) => {
    dispatch(updatePrevCardsLayout({ page, prevLayoutsOptions: newPrev }));
  };

  const generateNewLayouts = (newLayouts) => {
    onUpdateCards(newLayouts.layouts, newLayouts.toolbox);
    handleUpdateUserStorage({
      newLayouts: newLayouts.layouts,
      newToolbox: newLayouts.toolbox
    });
  };

  const handleClose = useCallback(() => {
    setPreventCollision(true);

    eventBus.dispatch(eventBusEvents.preventCollision, true);

    onUpdateCards(prevLayoutsOptions.layouts, toolbox);
    onUpdatePrevValue({ ...prevLayoutsOptions, isOpen: {} });
  }, [ toolbox, prevLayoutsOptions, onUpdatePrevValue, onUpdateCards ]);

  const handleOpen = useCallback((type) => {
    const newLayouts = generateIncreaseLayout(type, cardsLayout);

    setPreventCollision(false);
    onUpdateCards(newLayouts, toolbox);
    onUpdatePrevValue({ ...cardsLayoutOptions, isOpen: { [type]: true } });

    eventBus.dispatch(eventBusEvents.preventCollision, false);
  }, [ toolbox, cardsLayout, cardsLayoutOptions, onUpdatePrevValue, onUpdateCards ]);

  const generateMinimizedSettings = (newLayouts, newIsMinimize) => {
    onUpdateCards(newLayouts, toolbox);
    onUpdatePrevValue({ ...prevLayoutsOptions, layouts: newLayouts, isMinimize: newIsMinimize });
    handleUpdateUserStorage({ newLayouts, newToolbox: toolbox, newIsMinimize });
  };

  const onReset = () => {
    const newLayouts = handleResetLayout({
      layout: cardsLayout,
      state: { layouts: cardsLayout.layouts, toolbox: cardsLayout.toolbox },
      extraTypes
    });

    onUpdatePrevValue({ ...newLayouts, isOpen: {}, isMinimize: {} });
    generateNewLayouts(newLayouts);
    handleUpdateUserStorage({
      newLayouts: newLayouts.layouts,
      newToolbox: newLayouts.toolbox,
      newIsMinimize: {}
    });
  };

  const onTakeItem = (type) => () => {
    const newLayouts = handleTakeItem({
      type,
      layout: cardsLayout,
      state: { layouts, toolbox }
    });

    generateNewLayouts(newLayouts);
  };

  const onPutItem = (type) => () => {
    const newLayouts = handlePutItem({
      type,
      layout: cardsLayout,
      state: { layouts, toolbox }
    });

    generateNewLayouts(newLayouts);
  };

  const handleMinimize = (type) => {
    const prevData = layouts[breakpoint].find(({ i }) => i === type);

    if (prevData) {
      const newLayouts = generateMinimizeLayout(type, minimizedHeight, prevData.w, prevData.x, prevData.y, layouts);

      generateMinimizedSettings(newLayouts, { ...isMinimize, [type]: true });
    }
  };

  const handleMaximized = (type) => {
    const prevData = layouts[breakpoint].find(({ i }) => i === type);
    const prevLayData = previous?.layouts[breakpoint].find(({ i }) => i === type);
    const prevHeight = prevLayData?.h > 2 ? prevLayData?.h : 7;
    const h = extraTypes && extraTypes.indexOf(type) < 0 ? 10 : prevHeight;
    const newLayouts = generateMinimizeLayout(type, h, prevData.w, prevData.x, prevData.y, layouts);

    generateMinimizedSettings(newLayouts, { ...isMinimize, [type]: false });
  };

  const onLayoutChange = (layout, allLayouts) => {
    if (isEmpty(prevLayoutsOptions?.isOpen) && !preventCollision) {
      handleUpdateUserStorage({
        newLayouts: allLayouts,
        newToolbox: toolbox
      });
    }
  };

  useEffect(() => {
    const storeCurrentLayouts = layouts?.[breakpoint];

    if (isFetched) {
      const defaultWidgetsLength = Object.entries(widgetsMap)?.length;
      const widgetsLength = layouts?.lg?.length + toolbox?.lg?.length;

      if (!storeCurrentLayouts && defaultWidgetsLength !== widgetsLength) {
        const newLayout = {
          toolbox: cardsLayout.toolbox,
          layouts: cardsLayout.layouts
        };

        generateNewLayouts(newLayout);
      } else {
        Object.keys(widgetsMap).forEach((type) => {
          const prevData = storeCurrentLayouts.find(({ i }) => i === type);

          if (prevData && prevData.h === minimizedHeight && !isMinimize?.[type]) {
            onUpdatePrevValue({ ...prevLayoutsOptions, isMinimize: { ...isMinimize, [type]: true } });
          }
        });
      }
    }
  }, [ isFetched ]);

  useEffect(() => {
    const type = Object.keys(prevLayoutsOptions.isOpen)?.[0];

    if (type) {
      handleClose();
    }
  }, []);

  return {
    isFetched,
    layouts,
    toolbox,
    preventCollision,
    breakpoint,
    prevLayoutsOptions,

    // functions
    onReset,
    onPutItem,
    onTakeItem,
    handleOpen,
    handleClose,
    onLayoutChange,
    handleMinimize,
    handleMaximized,
    onBreakpointChange,
    setPreventCollision
  };
};
