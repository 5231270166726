import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { itemsRoutesByModels } from '../../../dataMaps/apiModels';

export const getProps = (track, classes) => (!!itemsRoutesByModels?.[track.model] ? {
  component: Link,
  underline: 'hover',
  target: '_blank',
  color: 'primary',
  variant: 'h5',
  to: itemsRoutesByModels[track.model](get(track, 'time_trackingable.id')),
  className: classes.parentTitleLink
} : {
  variant: !track?.time_trackingable?.name?.length ? 'body1' : 'h5',
  color: track.name ? 'primary' : 'textPrimary',
  className: track.name && classes.parentTitleLink
});
