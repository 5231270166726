import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import * as formsApi from '../../../../../api/profile';
import {
  PainForm,
  ActionButtons,
  painValidationSchema,
  generateInitialPainValues
} from '../../../../../components/medical/forms';
import { Divider } from '../../../../../components/Divider';
import { Loader } from '../../../../../components/Loader';
import { FormContext } from '../../FormProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PainContent = () => {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    form,
    isFetched,
    handleComplete,
    handleBack
  } = useContext(FormContext);

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (values?.forms?.pain_in_neck?.is_neck
      || values?.forms?.pain_in_low_back?.is_low_back
      || values?.forms?.middle_back?.is_middle_back
    ) {
      values.forms.pain_in_neck = values?.forms?.pain_in_neck?.is_neck
        ? values.forms.pain_in_neck : null;
      values.forms.pain_in_low_back = values?.forms?.pain_in_low_back?.is_low_back
        ? values.forms.pain_in_low_back : null;
      values.forms.middle_back = values?.forms?.middle_back?.is_middle_back
        ? values?.forms?.middle_back : null;

      return formsApi.updateGuestPainContent(values).then(({ data }) => {
        enqueueSnackbar('Form successfully updated', { variant: 'success' });
        handleComplete(data);
      }).catch(({ errors }) => {
        setErrors(errors);
        enqueueSnackbar('Form not updated', { variant: 'error' });
      });
    } else {
      return handleComplete(form);
    }
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            token: form?.token || null,
            form_id: form.id,
            ...generateInitialPainValues(form)
          }}
          validationSchema={painValidationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <PainForm />
              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
