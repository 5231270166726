export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { fontWeightMedium, pxToRem },
  shape: { borderRadius }
}) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexGrow: 1
  },

  videoItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '51%',
    paddingLeft: spacing(3),
    paddingTop: spacing(1.5),

    '&:last-child': {
      paddingRight: spacing(),
      paddingTop: spacing(5),
      width: '48%'
    }
  },

  videoContent: {
    position: 'relative',
    paddingTop: '56.25%'
  },

  videoPlayer: {
    position: 'absolute',
    top: 0,
    left: 0
  },

  videoPlayer_active: {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: '50%',
    animation: 'blink 0.8s ease-out infinite',
    zIndex: '-1'
  },

  '@keyframes blink': {
    '0%': {
      opacity: '1',
      transform: 'scale(1)'
    },
    '50%': {
      opacity: '0.2',
      transform: 'scale(1.1)',
      backgroundColor: palette.common.black
    },
    '100%': {
      opacity: '1',
      transform: 'scale(1)'
    }
  },

  videoTitle: {
    flexGrow: 1,
    overflow: 'hidden',
    marginBottom: spacing(3)
  },

  videoTitle__name: {
    color: grey[600],
    fontSize: pxToRem(18),
    fontWeight: fontWeightMedium
  },

  videoTitle__subName: {
    color: grey[400],
    fontSize: pxToRem(14)
  },

  videoItemContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '460px',
    paddingLeft: spacing(3),
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingRight: spacing(2),
    backgroundColor: grey[50],
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',

    '&:last-child': {
      marginTop: spacing(2.5),
      marginBottom: spacing()
    }
  },

  videoItemTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  videoItemTitle__title: {
    fontSize: pxToRem(16),
    color: grey[600]
  },

  videoItemTitle__icon: {
    fontSize: pxToRem(22),
    color: grey[500],
    marginRight: spacing()
  },

  videoScreen: {
    height: '100%',
    marginTop: spacing(3),
    overflowY: 'auto',
    textAlign: 'center'
  },

  fragmentIcon: {
    fontSize: pxToRem(16)
  },

  tabs: {
    marginTop: spacing(),
    border: `1px solid ${grey[500]}`,
    borderRadius,
    backgroundColor: grey[50]
  },

  tabs__tab: {
    borderRight: `1px solid ${grey[500]}`
  },

  loader: {
    marginTop: spacing(2)
  },

  button: {
    margin: spacing(),
    fontSize: pxToRem(12)
  },

  icon: {
    marginRight: spacing()
  }
});
