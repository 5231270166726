import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { get } from 'lodash';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as profileApi from '../../../../../../../../api/profile';
import { Loader } from '../../../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../../../components/Modal';
import { MainContent } from '../MainContent';
import { NotesContent } from './NotesContent';
import { validationSchema } from './validationSchema';

export const PatientInfoModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { initialValues }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const updatePatientInfo = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.referring_physician_id = get(values, 'referring_physician_id.value', values.referring_physician_id);
    values.treating_physician_id = get(values, 'treating_physician_id.value', values.treating_physician_id);
    values.preferred_id = get(values, 'preferred_id.value', values.preferred_id);

    return profileApi.updateMedicalProfile(values).then(({ data }) => {
      enqueueSnackbar('Profile successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors, message }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        errors && setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: initialValues.id,
        preferred_id: initialValues.preferred,
        referring_physician_id: initialValues.referring_physician,
        treating_physician_id: initialValues.treating_physician,
        ext_med_rec: initialValues.ext_med_rec,
        doi: initialValues.doi,
        notes: initialValues.notes || []
      } || {}}
      validationSchema={validationSchema}
      onSubmit={updatePatientInfo}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Update Patient Info
            </ModalHeader>

            <ModalBody>
              <MainContent />
              <NotesContent />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
