export const styles = ({
  spacing,
  palette,
  typography: { fontWeightMedium },
  shape: { borderRadius },
  breakpoints
}) => ({
  adjRoot: {
    marginTop: spacing(2),
    padding: spacing(2),
    border: `1px dashed ${palette.info.main}`,
    borderRadius,

    [breakpoints.down('md')]: {
      padding: spacing(1)
    }
  },

  doiRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: spacing(2),
    padding: spacing(1, 1, 2, 2),
    backgroundColor: palette.grey[50],
    borderRadius,

    [breakpoints.down('md')]: {
      padding: spacing(1)
    }
  },

  notesIcon: {
    display: 'flex',
    alignItems:'center',
    marginTop: spacing(),
    color: palette.grey[600],
    fontWeight: fontWeightMedium
  },

  notesInput: {
    color: palette.grey[500]
  },

  contentRowDoi: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: spacing(1, 0)
  }
});
