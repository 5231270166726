import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { rolesMap } from '../../../../../../../../../../../dataMaps/rolesMap';
import { preventDefault } from '../../../../../../../../../../../helpers/dom';
import * as casesApi from '../../../../../../../../../../../api/cases';
import { ConfirmationModal } from '../../../../../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../../../../../components/ModalsProvider';
import { hasRole } from '../../../../../../../../../../../utils/hasRole';

export const CaseUserMenu = ({ caseItem, user, onCaseItemUpdate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const currentUser = useSelector(({ profile }) => profile.user);
  const history = useHistory();
  const userID = user?.user?.id || user?.id;
  const isPatient = hasRole(rolesMap.patient);

  const onUpdate = () => {
    if (currentUser?.id === userID) {
      history.push('/profile/edit');
    } else {
      history.push(`/members/users/${userID}/edit`);
    }
  };

  const handleDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        casesApi.deleteUserFromCase(user.id).then(() => {
          const newCase = {
            ...caseItem,
            case_users: caseItem.case_users.filter(({ id }) => id !== user.id)
          };

          onCaseItemUpdate(newCase);
          enqueueSnackbar('User successfully deleted from case', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('User is not deleted!', { variant: 'error' });
        });
      }
    });
  };

  return (
    <List>
      <MenuItem
        component={Link}
        to={`/members/users/${userID}`}
      >
        <ListItemIcon>
          <EyeIcon />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {!isPatient &&
        <MenuItem onClick={onUpdate}>
          <ListItemIcon>
            <EditIcon color="primary"/>
          </ListItemIcon>

          <ListItemText primary="Edit user profile"/>
        </MenuItem>
      }

      {!isPatient &&
        <MenuItem disabled={user.case_role === 'creator'} onClick={preventDefault(handleDelete)}>
          <ListItemIcon>
            <DeleteIcon color="error"/>
          </ListItemIcon>

          <ListItemText primary="Remove"/>
        </MenuItem>
      }
    </List>
  );
};
