import { api } from '../../../../api';
import * as filesApi from '../../../../api/files';
import * as actions from './actions';
import { generateUID } from '../../../../helpers/generateUID';
import { enqueueSnackbar } from '../../../snackbars';

export const uploadFiles = ({
  files,
  tags = [],
  owner_type,
  owner_id,
  isPersonalShared = false
}) => (dispatch) => {
  files.forEach(( file ) => {
    if (file.requestData) {
      dispatch(actions.refreshUpload(file));
    } else {
      const formData = new FormData();

      formData.append('file', file, file.original_filename);

      owner_type && formData.append('owner_type', owner_type);
      owner_id && formData.append('owner_id', owner_id);

      tags.forEach((id) => formData.append('tags[]', id));

      file.localID = generateUID();
      file.tags = tags;
      file.requestData = formData;

      dispatch(actions.addFile(file));
    }

    file.progress = 0;

    filesApi[isPersonalShared ? 'uploadPersonalSharedFile' : 'uploadFile'](file.requestData, {
      cancelToken: new api.CancelToken((cancel) => {
        file.cancel = () => {
          cancel();
          dispatch(actions.cancelUpload(file));
        };
      }),
      onUploadProgress: (event) => {
        file.progress = Math.round( (event.loaded * 100) / event.total );
        dispatch(actions.updateFile(file));
      }
    }).then((uploadedFile) => {
      dispatch(actions.fileUploadSuccess({ ...file, ...uploadedFile }));
      dispatch(enqueueSnackbar('Files successfully uploaded', {
        variant: 'success'
      }));
    }).catch(() => {
      dispatch(actions.fileUploadFail(file));
      dispatch(enqueueSnackbar(`File "${file.original_filename}" is invalid`, {
        variant: 'error'
      }));
    });
  });
};
