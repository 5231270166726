import { isEqual, omit } from 'lodash';
import { createContext, useEffect, useReducer, useRef } from 'react';
import { api } from '../../../../api';
import * as accessUsersApi from '../../../../api/access-users';
import { usePrevious } from '../../../../helpers/hooks';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const MembersContext = createContext();

export const additionalParamTypesMap = {
  workers: 'workers',
  patients: 'patients'
};

export const MembersProvider = ({ user, children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    workersList
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});
  const type = (user?.is_patient) ? additionalParamTypesMap.workers : additionalParamTypesMap.patients;

  const fetchMembers = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_MEMBERS_REQUEST });

    accessUsersApi.fetchUsersWithAccess({
      type,
      params: { ...filter, ...newFilter, patient_id: user.id },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_MEMBERS_SUCCESS, payload: data });
    });
  };

  const addMember = (user) => {
    dispatch({ type: types.ADD_MEMBER, payload: user });
  };

  const removeMembers = (membersIDs) => {
    dispatch({ type: types.DELETE_MEMBER, payload: membersIDs });
  };

  const updateMember = (updatedMember) => {
    dispatch({ type: types.UPDATE_MEMBER, payload: updatedMember });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchMembers({ page: filter.page + 1 });
    }
  };

  const resetMembers = (newFilter) => {
    dispatch({ type: types.RESET_MEMBERS, payload: newFilter });

    fetchMembers({ ...newFilter, page: 1 });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const providerValue = {
    isFetched,
    isFetching,
    workersList,
    filter: {
      ...filter,
      ...pagination
    },

    resetMembers,
    fetchMembers,
    loadNextPage,
    addMember,
    updateMember,
    removeMembers,
    applyFilter
  };

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetMembers(filter);
    }
  }, [ filter, prevFilter ]);

  useEffect(() => {
    resetMembers(filter);
  }, []);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <MembersContext.Provider value={providerValue}>
      {children}
    </MembersContext.Provider>
  );
};
