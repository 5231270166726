import { useContext, useRef } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { CreateEventTemplateModal } from '../../../../../components/schedule-events/templates';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { useModal } from '../../../../../components/ModalsProvider';
import { Fab } from '../../../../../components/Fab';
import {
  ScheduleEventTemplatesContext
} from '../../../../../components/schedule-events/templates';
import { List } from '../List';

export const PageContent = () => {
  const theme = useTheme();
  const rootRef = useRef();
  const { openModal } = useModal();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { resetEventTemplates } = useContext(ScheduleEventTemplatesContext);

  const addEventTemplate = (resetEventTemplates) => () => {
    openModal(CreateEventTemplateModal, {
      onModalResolved: () => {
        resetEventTemplates();
      }
    });
  };

  return (
    <Page ref={rootRef}>
      <PageHeader>
        <PageHeaderTitle>
          Schedule event templates
        </PageHeaderTitle>

        <PageHeaderActions>
          <Fab
            variant="extended"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addEventTemplate(resetEventTemplates)}
          >
            Add {!isMobile && 'event template'}
          </Fab>
        </PageHeaderActions>
      </PageHeader>

      <PageBody disableScrollbars fullHeight disablePaddings={isMobile || isTablet}>
        <List />
      </PageBody>
    </Page>
  );
};
