import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, AppBar, Toolbar, Button, Box, Typography, Grid } from '@material-ui/core';
import { stopPropagation } from '../../helpers/dom';
import { Popper } from '../../components/Popper';
import { lang } from '../../lang';
import { LogoLink } from '../Dashboard/Layout/Sidebar/SidebarContent/LogoLink';
import { FormsMenu } from './FormsMenu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const LandingWrapper = ({ disableBackground = false, children }) => {
  const classes = useStyles({ disableBackground });
  const companyName = lang[import.meta.env.VITE_COMPANY_ID]?.name;

  return (
    <main className={classes.main}>
      <AppBar className={classes.root}>
        <Toolbar>
          <Grid
            container
            component={Box}
            display="flex"
            alignItems="center"
            flexGrow={1}
            color="common.white"
            spacing={2}
          >
            <Grid item xs md={4}>
              <Grid
                container
                spacing={2}
                wrap="nowrap"
                component={Box}
                display="flex"
                alignItems="center"
              >
                <Grid item>
                  <Box width={50} height={50}>
                    <LogoLink sidebarIsOpen={false} />
                  </Box>
                </Grid>

                <Grid item>
                  {!!companyName &&
                    <Typography variant="h2" color="inherit">
                      {companyName}
                    </Typography>
                  }
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              md={4}
              component={Box}
              display="flex"
              justifyContent="center"
            >
              <Button variant="contained" color="primary" component={RouterLink} to="demo-request">
                Request demo
              </Button>
            </Grid>

            <Grid item md={4}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Popper
                  placement="bottom-start"
                  anchorRenderer={
                    ({ anchorRef, handleToggle }) => (
                      <Button color="inherit" buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                        New Patient forms
                      </Button>
                    )
                  }
                >
                  {({ handleClose }) => (
                    <FormsMenu onClose={handleClose} />
                  )}
                </Popper>

                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/login"
                >
                  Login
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Box flexGrow={1} overflow="auto">
        {children}
      </Box>
    </main>
  );
};
