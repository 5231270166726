import { Box, Grid, Typography } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { VerticalDivider } from '../../VerticalDivider';
import { PaymentsModal } from '../../billing/payments';
import { useModal } from '../../ModalsProvider';
import { IconButton } from '../../IconButton';
import { Fab } from '../../Fab';
import {
  billingParticipantsTypesMap,
  billingParticipantsTypesOptions,
  CreateCopayModal
} from '../../billing';
import { arrivalTypesMap } from '../arrivalTypes';
import { PaymentContent } from './PaymentContent';

export const PaymentInfo = ({ appointment, onUpdate = () => {} }) => {
  const billingInfo = appointment.billing_info;
  const { openModal } = useModal();
  const isArrived = appointment.arrival_status?.arrival_type === arrivalTypesMap.arrived;

  const showHistory = () => {
    openModal(PaymentsModal, {
      payload: {
        filter: {
          appointment_id: appointment.id
        }
      }
    });
  };

  const createPaymentInfo = () => {
    openModal(CreateCopayModal, {
      payload: {
        model_type: 'appointment',
        model_id: appointment.id,
        billingInfo,
        patient: { ...appointment.patient, med_rec: appointment?.patient_appointment_details?.med_rec },
        bill_from: appointment.appointment_book?.office?.company?.id ? {
          type: billingParticipantsTypesOptions.find(({ value }) => {
            return value === billingParticipantsTypesMap.company;
          }),
          id: appointment.appointment_book?.office?.company
        } : null,
        bill_to: appointment?.patient?.id ? {
          type: billingParticipantsTypesOptions.find(({ value }) => {
            return value === billingParticipantsTypesMap.user;
          }),
          id: appointment.patient
        } : null
      },
      onModalResolved: () => {
        onUpdate();
      }
    });
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" color="info.main">
        <Box display="flex" alignItems="center">
          <Typography variant="h4" color="inherit">Payment Info</Typography>

          <VerticalDivider verticalGutters={1} horizontalGutters={3} />

          <IconButton
            edge="start"
            color="primary"
            title="Payments history"
            onClick={showHistory}
          >
            <EyeIcon />
          </IconButton>
        </Box>

        {isArrived && !hasRole(rolesMap.patient) &&
          <Fab
            variant="extended"
            color="primary"
            onClick={createPaymentInfo}
          >
            Add payment
          </Fab>
        }
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2}>
        <PaymentContent billingInfo={billingInfo} />
      </Grid>
    </>
  );
};
