import { useContext, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../../components/Loader';
import { IconButton } from '../../../../../../../../components/IconButton';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap>
          {filter?.params?.name || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.email}>
        <Typography noWrap>
          {filter?.params?.email || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.role}>
        <Typography noWrap>
          {filter?.params?.role || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

