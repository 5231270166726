import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PreviewContent = ({ className, fullSize, children, ...props }) => {
  const classes = useStyles();

  return (
    <div
      className={cn(
        classes.root,
        className,
        fullSize && classes.fullSize
      )}
      {...props}
    >
      {children}
    </div>
  );
};
