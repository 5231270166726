import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext, ErrorMessage } from 'formik';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import * as calendarApi from '../../../../../api/appointments';
import * as formsApi from '../../../../../api/profile';
import { DatePicker } from '../../../../../components/FormField';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { Loader } from '../../../../../components/Loader';

export const CalendarInfo = ({ appointmentDate = null }) => {
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const { enqueueSnackbar } = useSnackbar();
  const { values, setFieldValue } = useFormikContext();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ times, setTimes ] = useState([]);
  const fetchFreeDates = isAuthenticated ? calendarApi.fetchFreeDates : formsApi.fetchGuestFreeDatesList;

  const handleTimeChange = (time) => () => {
    if (time) {
      setFieldValue('appointment_time', time);
    }
  };

  useEffect(() => {
    if (!values?.appointment_book_id || !values.appointment_date) {
      return;
    }

    setIsLoading(true);

    const duration = moment.duration(`${values._hours || 0}:${values._minutes || 0}`).asMinutes();

    fetchFreeDates({
      params: {
        date: values.appointment_date,
        appointment_book_id: values?.appointment_book_id?.id || values?.appointment_book_id,
        interval: duration > 60 ? 60 : duration < 15 ? 15 : duration
      }
    }).then((data) => {
      setTimes(data);
    }).catch((errors) => {
      if (errors) {
        setTimes([]);
        enqueueSnackbar('The given duration was invalid', { variant: 'warning' });
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }, [ values.appointment_book_id, values._hours, values._minutes, values.appointment_date ]);

  return (
    <>
      <Grid item xs={12} md={7}>
        <DatePicker
          autoOk
          disablePast
          disableToolbar
          disabled={!values.appointment_book_id}
          outputFormat="YYYY-MM-DD"
          name="appointment_date"
          variant="static"
        />

        <Typography color="error"><ErrorMessage name="appointment_date" /></Typography>
      </Grid>

      <Grid item xs={12} md={5}>
        <Box pl={1} pt={1} maxHight={300}>
          {isLoading ? <Loader p={2} /> : (
            !times?.length ? (
              <Typography paragraph color="textSecondary" align="center">
                No time available
              </Typography>
            ) : (
              <>
                <Scrollbars autoHeight autoHeightMax="100%">
                  <Box display="flex" alignItems="center" flexWrap="wrap" pb={1}>
                    {times?.map((time) => time && (
                      <Box key={time.start} m={0.25} width={100}>
                        <Button
                          fullWidth
                          color="primary"
                          key={time.start}
                          disabled={!!appointmentDate && time.start === values.appointment_time ? false : time.busy}
                          variant={time.start === values.appointment_time ? 'contained' : 'outlined'}
                          onClick={handleTimeChange(time.start)}
                        >
                          {moment(time.start).format('LT')}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </Scrollbars>
              </>
            )
          )}

          <Typography color="error" align="center">
            <ErrorMessage name="appointment_time" />
          </Typography>
        </Box>
      </Grid>
    </>
  );
};
