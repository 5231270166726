import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, makeStyles, IconButton, Box, useTheme, useMediaQuery } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { Loader } from '../../../../../components/Loader';
import { validationSchema } from '../validationSchema';
import { InvoicesContext } from '../InvoicesContext';
import { FormContent } from './FormContent';
import { initialValues } from './initialValues';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CreateInvoice = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const invoiceContext = useContext(InvoicesContext);
  const [ expenses, setExpenses ] = useState([]);
  const [ totalTime, setTotalTime ] = useState(0);

  const createInvoice = (invoiceData, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    invoiceData.expenses = expenses;

    invoiceContext.createInvoice({ invoiceData, setSubmitting, setErrors });
  };

  return (
    <Box flexGrow={1} px={isMobile ? 0 : 2} py={isMobile ? 0 : 1}>
      <Formik
        initialValues={{
          ...initialValues,

          incomes: [],
          total: 0
        }}
        validationSchema={validationSchema}
        onSubmit={createInvoice}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form noValidate className={classes.form} onSubmit={handleSubmit}>
            <Page>
              <PageHeader>
                <IconButton
                  color="primary"
                  edge="start"
                  component={Link}
                  to="/billing/invoices"
                >
                  <KeyboardBackspaceOutlinedIcon />
                </IconButton>

                <PageHeaderTitle>
                  New Invoice
                </PageHeaderTitle>

                <PageHeaderActions>
                  <Button
                    color="primary"
                    component={Link}
                    to="/billing/invoices"
                  >
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                  />
                </PageHeaderActions>
              </PageHeader>

              <PageBody>
                <FormContent
                  totalTime={totalTime}
                  expenses={expenses}
                  onTotalTimeUpdate={setTotalTime}
                  onExpensesUpdate={setExpenses}
                />
              </PageBody>
            </Page>
          </form>
        )}
      </Formik>
    </Box>
  );
};
