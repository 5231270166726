import { useContext } from 'react';
import { Paper, Box, useTheme } from '@material-ui/core';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { InvoicesContextProvider } from '../../InvoicesContext';
import { FiltersBar } from './FiltersBar';
import { Header } from './Header';
import { List } from './List';
import { Grid } from './Grid';

export const InvoicesContent = ({ width, caseItem, filter = {}, hiddenFilterFields, filterKey }) => {
  const theme = useTheme();
  const isMobile = width <= theme.breakpoints.values.mobileLg;
  const isTablet = width <= theme.breakpoints.values.md;
  const { viewVariant } = useContext(LayoutContext);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper
  } = useMobileFilterCollapse('md', width);
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  return (
    <InvoicesContextProvider initialState={{ filter }}>
      <Header
        isMobile={isMobile}
        isTablet={isTablet}
        caseItem={caseItem}
        filterOpenButton={filterOpenButton}
      />

      <ScrollWrapper {...scrollWrapperProps}>
        <FilterBarWrapper {...filterBarWrapperProps}>
          <FiltersBar
            caseItem={caseItem}
            hiddenFields={hiddenFilterFields}
            filterKey={filterKey}
          />
        </FilterBarWrapper>
      </ScrollWrapper>

      <Paper component={Box} height="100%" display="flex" flexDirection="column" minHeight={500}>
        {isMobile || viewVariant === viewVariantsMap.grid ? (
          <Grid caseItem={caseItem} />
        ) : viewVariant === viewVariantsMap.list ? <List caseItem={caseItem} /> : null}
      </Paper>
    </InvoicesContextProvider>
  );
};
