export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const RESET_TAGS = 'RESET_TAGS';
export const ADD_TAG = 'ADD_TAG';
export const ADD_CHILDREN_TAG = 'ADD_CHILDREN_TAG';
export const DELETE_CHILDREN_TAG = 'DELETE_CHILDREN_TAG';
export const UPDATE_TAGS_POSITIONS = 'UPDATE_TAGS_POSITIONS';
export const UPDATE_TAG = 'UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
