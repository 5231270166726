import { Yup } from '../../../utils/validation';
import { validationSchema as procedureValidationSchema } from '../procedures';

export const validationSchema = Yup.object().shape({
  patient_id: Yup.mixed().nullable().required(),
  patient_insurance_id: Yup.mixed().nullable(),
  copay_type: Yup.string().nullable().when('patient_insurance_id', {
    is: (value) => value,
    then: Yup.string().required()
  }),
  price: Yup.number().nullable().positive().min(0).required(),
  patient_appointment_details: Yup.array().nullable(),
  insurance_details: Yup.array().nullable(),
  appointment_confirmed_by_office: Yup.boolean(),
  eligibility_manually_verified: Yup.boolean(),
  do_not_bill_claim: Yup.boolean(),
  chief_complaint: Yup.string().nullable(),
  note: Yup.string().nullable(),
  _hours: Yup.number().integer().positive().nullable().typeError('Must be a number'),
  _minutes: Yup
    .number()
    .integer()
    .nullable()
    .typeError('Must be a number')
    .when('_hours', (hours, schema) => {
      return hours ? schema : schema.required().min(5);
    }),
  appointment_book_id: Yup.mixed().nullable().required(),
  appointment_type_id: Yup.mixed().nullable().required(),
  arrival_status_id: Yup.mixed().nullable().required(),
  appointment_at: Yup.date().format('X').nullable().required(),
  visit_reason_id: Yup.mixed().nullable(),
  office_id: Yup.mixed().nullable().required(),
  recurring_rule: Yup.string().nullable().max(254),
  timezone: Yup.string().nullable(),
  procedures: Yup.array().of(procedureValidationSchema).nullable()
}, [ 'follow_up_count', 'follow_up_type' ]);
