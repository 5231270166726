import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { Page } from '../../../components/Page';
import { LandingWrapper } from '../../LandingPage';
import { FormItemComponent } from './FormItemComponent';
import { FormProvider } from './FormProvider';

export const GuestMedicalProfile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <FormProvider>
      <LandingWrapper>
        <Box display="flex" width="100%" height="100%" p={isMobile ? 0 : 2} pb={0}>
          <Page>
            <FormItemComponent />
          </Page>
        </Box>
      </LandingWrapper>
    </FormProvider>
  );
};
