import { Fragment, useState } from 'react';
import { Grid, Dialog, Typography, Box, Hidden } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Divider } from '../../../../../../../../components/Divider';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { Date } from '../../../../../../../../components/Date';
import {
  ModalBody,
  ModalHeader,
  ModalContainer,
  ModalHeaderActions
} from '../../../../../../../../components/Modal';
import { EditNegotiationModal } from '../EditNegotiationModal';

export const PreviewNegotiationModal = ({
  DialogProps,
  handleModalReject,
  payload: {
    caseItem,
    initialValues,
    deleteNegotiation = () => {},
    updateNegotiation = () => {},
    onCaseItemUpdate = () => {}
  }
}) => {
  const { openModal } = useModal();
  const [ negotiation, setNegotiations ] = useState(initialValues || {});

  const onUpdate = () => {
    openModal(EditNegotiationModal, {
      payload: {
        caseItem,
        negotiation,
        onCaseItemUpdate
      },
      onModalResolved: (negotiation) => {
        updateNegotiation(negotiation);
        setNegotiations(negotiation);
      }
    });
  };

  const onDelete = () => {
    deleteNegotiation(caseItem?.id, negotiation);
    handleModalReject();
  };

  return (
    <Dialog maxWidth="md" {...DialogProps}>
      <ModalContainer noValidate>
        <ModalHeader onClose={handleModalReject}>
          Negotiation* Details

          <ModalHeaderActions disableGutters>
            <Tooltip title="Edit negotiation">
              <IconButton color="primary" onClick={onUpdate}>
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete negotiation">
              <IconButton color="error" onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </ModalHeaderActions>
        </ModalHeader>

        <ModalBody>
          <Grid container spacing={2} component={Box} pb={3}>
            <Grid item sm={3} xs={12}>
              <Typography color="textSecondary">Plaintiff</Typography>
            </Grid>

            <Grid item sm={9} xs={12}>
              <UserLink
                size="sm"
                variant="h5"
                user={negotiation?.user}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <Typography color="textSecondary">Meds</Typography>
            </Grid>

            <Grid item sm={9} xs={12}>
              <CurrencyFormat
                value={negotiation?.total_to_providers || 0}
                TypographyProps={{ noWrap: true, variant: 'h5' }}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography component={Box} pt={1} variant="h4">Demand</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">Sent</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Date variant="h5" date={negotiation.demand_sent_date} />
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">Due</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Date variant="h5" date={negotiation.demand_due_date} />
            </Grid>

            <Hidden only={[ 'sm', 'xs' ]}>
              <Grid item sm={6} />
            </Hidden>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">DM Amt</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <CurrencyFormat
                value={negotiation.demand_amount}
                TypographyProps={{ noWrap: true, variant: 'h5' }}
              />
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">UM</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Date variant="h5" date={negotiation.demand_um_date} />
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">Due</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Date variant="h5" date={negotiation.demand_um_due_date} />
            </Grid>

            <Hidden only={[ 'sm', 'xs' ]}>
              <Grid item sm={6} />
            </Hidden>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">DM Amt</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <CurrencyFormat
                value={negotiation.demand_um_amount}
                TypographyProps={{ noWrap: true, variant: 'h5' }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component={Box} pt={1} variant="h4">Counter/Offers</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">Date</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Date variant="h5" date={negotiation.counter_date} />
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">Offer</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <CurrencyFormat
                value={negotiation.counter_amount}
                TypographyProps={{ noWrap: true, variant: 'h5' }}
              />
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">Date</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Date variant="h5" date={negotiation.offer_date} />
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">Counter</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <CurrencyFormat
                value={negotiation.offer_amount}
                TypographyProps={{ noWrap: true, variant: 'h5' }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component={Box} pt={1} variant="h4">Settled</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">Date</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Date variant="h5" date={negotiation.settled_date} />
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">3rdP</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <CurrencyFormat
                value={negotiation.settled_third_party_amount}
                TypographyProps={{ noWrap: true, variant: 'h5' }}
              />
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">UM</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Date variant="h5" date={negotiation.settled_um_date} />
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography color="textSecondary">UM</Typography>
            </Grid>

            <Grid item sm={3} xs={6}>
              <CurrencyFormat
                value={negotiation.settled_um_amount}
                TypographyProps={{ noWrap: true, variant: 'h5' }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component={Box} pt={1} variant="h4">Funds Received</Typography>
            </Grid>

            {negotiation?.received_founds?.map((item) => (
              <Fragment key={item.name}>
                <Grid item sm={3} xs={6}>
                  <Typography color="textSecondary">{item.name}</Typography>
                </Grid>

                <Grid item sm={3} xs={6}>
                  <Date variant="h5" date={item.date} />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <CurrencyFormat
                    value={item.amount}
                    TypographyProps={{ noWrap: true, variant: 'h5' }}
                  />
                </Grid>
              </Fragment>
            ))}

            <Grid item xs={12}>
              <Divider gutter={2} />
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography variant="h4">
                Total Funds Received
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <CurrencyFormat
                value={negotiation?.total_founds_received || 0}
                TypographyProps={{ noWrap: true, variant: 'h5' }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box ml={4}>
                <Typography variant="body1" color="textSecondary">
                  Atty Fees ({negotiation?.atty_costs_custom || 0}%)
                </Typography>
              </Box>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Box ml={4}>
                <Typography variant="body1" color="textSecondary">
                  Atty’s Costs
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box mt={0.25}>
                <CurrencyFormat
                  value={negotiation?.atty_costs || 0}
                  TypographyProps={{ noWrap: true, variant: 'h5' }}
                />
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="body1" color="textSecondary">
                Legal Costs
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Box mt={0.25}>
                <CurrencyFormat
                  value={negotiation?.atty_fee_legal_costs || 0}
                  TypographyProps={{ noWrap: true, variant: 'h5' }}
                />
              </Box>
            </Grid>

            <Grid item sm={3} xs={6}>
              <Typography variant="h4">
                Net Atty Fees
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <CurrencyFormat
                value={negotiation?.net_atty_fees || 0}
                TypographyProps={{ noWrap: true, variant: 'h5' }}
              />
            </Grid>

            <Grid item sm={6} />

            <Grid item sm={3}>
              <Typography gutterBottom variant="subtitle1">
                Total to Providers (Medical Bills)
              </Typography>
            </Grid>

            <Grid item sm={3}>
              <CurrencyFormat
                value={negotiation.total_to_providers || 0}
                TypographyProps={{ gutterBottom: true, noWrap: true, variant: 'h5' }}
              />
            </Grid>

            <Grid item sm={6} />

            <Grid item xs={3} spacing={1}>
              <Typography gutterBottom variant="subtitle1">
                Total to Adjuster
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <CurrencyFormat
                value={negotiation.total_to_client || 0}
                TypographyProps={{ gutterBottom: true, noWrap: true, variant: 'h5' }}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
