export const styles = () => ({
  appBar: {
    border: 0
  },

  toolBar: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  }
});
