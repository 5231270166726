import { useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, SvgIcon } from '@material-ui/core';
import { mdiTagPlusOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as emailsApi from '../../../../../api/emails';
import { SimpleSelect } from '../../../../../components/FormField';
import { TagsSelect } from '../../../../../components/Tags/TagsSelect';
import { Loader } from '../../../../../components/Loader';
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '../../../../../components/Modal';
import { tagsActionsMap } from '../../../../../api/files/tags';

const actions = [
  { value: tagsActionsMap.add, label: 'Add selected tags to current' },
  { value: tagsActionsMap.delete, label: 'Delete selected tags from all selected messages' },
  { value: tagsActionsMap.replace, label: 'Replace all current tags to selected' }
];

export const EmailsAttachToTagsModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { emailIDs, onTagsAdd, onTagsDelete, onTagsReplace }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ myTags, setMyTags ] = useState([]);
  const [ globalTags, setGlobalTags ] = useState([]);

  const handleTagsChange = (setTags) => (tags) => {
    setTags(tags || []);
  };

  const emailAttachToTags = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const tags = myTags.concat(globalTags);
    const tagsIDs = tags.map(({ id }) => id);

    return emailsApi.changeTags(values.action, {
      emails_id: emailIDs,
      tags_id: tagsIDs
    }).then(() => {
      enqueueSnackbar('Tags for message changed', { variant: 'success' });

      if (values.action === tagsActionsMap.add) {
        onTagsAdd(emailIDs, tags);
      } else if (values.action === tagsActionsMap.delete) {
        onTagsDelete(emailIDs, tagsIDs);
      } else if (values.action === tagsActionsMap.replace) {
        onTagsReplace(emailIDs, tags);
      }

      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Tags for message not changed', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        action: tagsActionsMap.add
      }}
      onSubmit={emailAttachToTags}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <Icon path={mdiTagPlusOutline} />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Change tags
            </ModalHeader>

            <ModalBody>
              <SimpleSelect
                name="action"
                label="Select action type"
                options={actions}
              />

              <TagsSelect
                multiple
                filterSelectedOptions
                name="myTags"
                label="Select my tags"
                placeholder="Select my tags..."
                margin="dense"
                value={myTags}
                params={{ is_system: 0 }}
                onChange={handleTagsChange(setMyTags)}
              />

              <TagsSelect
                multiple
                filterSelectedOptions
                name="globalTags"
                label="Select global tags"
                placeholder="Select global tags..."
                margin="dense"
                value={globalTags}
                params={{ is_system: 1 }}
                onChange={handleTagsChange(setGlobalTags)}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
