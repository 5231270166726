export const filterFieldsMap = {
  patients: 'patients',
  providers: 'providers',
  apptBooks: 'appointment_books',
  apptTypes: 'appointment_types',
  visitReasons: 'visit_reasons',
  appointmentDayFrom: 'appointment_day_from',
  appointmentDayTo: 'appointment_day_to',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.patients]: 'Patients',
  [filterFieldsMap.providers]: 'Providers',
  [filterFieldsMap.apptBooks]: 'Appointment Books',
  [filterFieldsMap.apptTypes]: 'Appointment Types',
  [filterFieldsMap.visitReasons]: 'Visit Reasons',
  [filterFieldsMap.appointmentDayFrom]: 'Appointment Day From',
  [filterFieldsMap.appointmentDayTo]: 'Appointment Day To',
  [filterFieldsMap.orderBy]: 'Order By'
};
