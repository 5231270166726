export const profile = 'profile';
export const clientProfile = 'clientProfile';
export const userProfile = 'userProfile';
export const cases = 'cases';
export const clientCases = 'clientCases';
export const billing = 'billing';
export const statistics = 'statistics';
export const medicalProfile = 'medicalProfile';
export const medicalUserProfile = 'medicalUserProfile';
export const medicalClientProfile = 'medicalClientProfile';
