import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Grid, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { mdiDraw } from '@mdi/js';
import { Icon } from '@mdi/react';
import { UserLink } from '../../../../../../components/UserLink';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import * as reportsApi from '../../../../../../api/profile';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Button } from '../../../../../../components/Button';
import { Loader } from '../../../../../../components/Loader';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const StaffSignature = () => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ isFetched, setIsFetched ] = useState(false);
  const { values } = useFormikContext();
  const currentUser = useSelector(({ profile }) => profile.user);
  const attorneySignature = values?.signatures?.find(({ type }) => type === 'attorney')?.signature;
  const [ signature, setSignature ] = useState(attorneySignature || null);
  const isSignature = !!attorneySignature || !!signature?.electronic_signature_file;
  const isTreatmentsExist = !!values?.forms?.treatment_plan?.filter(({ text }) => !!text)?.length;

  const handleSignForm = () => {
    if (!currentUser.signature) {
      enqueueSnackbar('You don\'t have a signature yet', { variant: 'warning' });

      return;
    }

    openModal(ConfirmationModal, {
      onModalResolved: () => {
        if (values.id) {
          setIsFetched(true);

          const signValue = {
            model_type: 'diagnosis_report',
            model_id: values.id,
            type: 'attorney',
            sign_type: 'electronic_signature'
          };

          reportsApi.signReport(signValue).then(({ data }) => {
            setSignature(data.signature);
            setIsFetched(false);
          }).catch(() => {
            enqueueSnackbar('Not signed', { variant: 'error' });
          });
        }
      }
    });
  };

  return (
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          Attorney signature
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">
            Attorney's name
          </Typography>

          <Box ml={2}>
            <UserLink user={values?.attorney} />
          </Box>
        </Box>
      </Grid>

      <Loader loading={isFetched} className={classes.loader} render={
        () => (
          <>
            <Grid item md={6} xs={12}>
              <Box className={classes.signature}>
                {!!isSignature ? (
                  <img
                    src={signature?.electronic_signature_file}
                    alt="signature"
                  />
                ) : (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h5">Not signed yet</Typography>
                  </Box>
                )}
              </Box>
            </Grid>

            {hasRole(rolesMap.advocate) && (
              <Grid item md={6} xs={12}>
                {!isSignature &&
                  <Button
                    disabled={!isTreatmentsExist}
                    size="small"
                    color="primary"
                    startIcon={<SvgIcon><Icon path={mdiDraw} /></SvgIcon>}
                    onClick={handleSignForm}
                  >
                    Sign
                  </Button>
                }
              </Grid>
            )}
          </>
        )}
      />
    </Grid>
  );
};
