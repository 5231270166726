import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
  Hidden
} from '@material-ui/core';
import { getHeightFormat } from '../../../../helpers/generateHeight';
import { useMeasurements } from '../../../../utils/useMeasurements';
import {
  bmiTypes,
  bmiTypesColors,
  bmiTypesNames,
  getCategoryBMI,
  heightMap,
  measureTypes,
  weightMap
} from '../../../../helpers/measureDataMap';
import {
  NumberMaskField,
  PrioritySelect,
  SimpleSelect,
  TextField
} from '../../../FormField';
import { styles } from '../CreateMeasurementsModal/Body/styles';
import {
  getStatus,
  statusMap
} from '../CreateMeasurementsModal/statusMap';

const useStyles = makeStyles(styles);

export const Measurements = () => {
  const { values, setFieldValue } = useFormikContext();
  const { bmi, onFeetHeightChange } = useMeasurements(values?.measurements);
  const classes = useStyles({
    color: bmi > 0
      ? bmiTypesColors[getCategoryBMI(bmi)]
      : bmiTypesColors[bmiTypes.underweight]
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleUnitChange = (name) => (event) => {
    onFeetHeightChange(0);
    setFieldValue('measurements.weight', 0);
    setFieldValue('measurements.height', 0);
    setFieldValue('measurements.bmi', 0);

    if (name === 'measurements.height_unit') {
      if (event.target.value === measureTypes.m) {
        setFieldValue('measurements.weight_unit', measureTypes.kg);
      } else {
        setFieldValue('measurements.weight_unit', measureTypes.lbs);
      }
    }

    if (name === 'measurements.weight_unit') {
      if (event.target.value === measureTypes.kg) {
        setFieldValue('measurements.height_unit', measureTypes.m);
      } else {
        setFieldValue('measurements.height_unit', measureTypes.feet);
      }
    }
  };

  useEffect(() => {
    if (bmi) {
      setFieldValue('measurements.bmi', +bmi);
      bmi > 0 && setFieldValue('measurements.category', bmi > 0 ?
        bmiTypesNames[getCategoryBMI(bmi)] : bmiTypesNames[bmiTypes.underweight]
      );
    }
  }, [ bmi ]);

  useEffect(() => {
    if (values?.measurements?.systolic_blood_pressure && values?.measurements?.diastolic_blood_pressure) {
      setFieldValue('measurements.status', getStatus(values?.measurements?.systolic_blood_pressure));
    } else {
      setFieldValue('measurements.status', null);
    }
  }, [ values?.measurements?.systolic_blood_pressure, values?.measurements?.diastolic_blood_pressure ]);

  return (
    <Grid
      container
      spacing={3}
      component={Box}
      py={isMobile ? 2 : 4}
      width="100% !important"
    >
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          Measurements
        </Typography>
      </Grid>

      <Grid item sm={4} xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">Temperature</Typography>
        </Box>
      </Grid>

      <Hidden only="xs">
        <Grid item sm={4} xs={12}>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">Blood type</Typography>
          </Box>
        </Grid>

        <Grid item xs={4} />
      </Hidden>

      <Grid item sm={4} xs={12}>
        <TextField
          type="number"
          label="Enter value"
          name="measurements.temperature"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                &deg;F
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Hidden smUp>
        <Grid item sm={6} xs={12}>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">Blood type</Typography>
          </Box>
        </Grid>
      </Hidden>

      <Grid item sm={4} xs={12}>
        <TextField
          name="measurements.blood_type"
          label="Enter value"
        />
      </Grid>

      <Hidden mdDown><Grid item xs={4} /></Hidden>

      <Grid item xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">Cardiovascular</Typography>
        </Box>
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField
          type="number"
          label="Heart rate"
          name="measurements.heart_rate"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                bpm
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Hidden mdDown><Grid item xs={8} /></Hidden>

      <Grid item sm={4} xs={12}>
        <TextField
          type="number"
          required={!!values?.measurements?.diastolic_blood_pressure}
          label="Systolic Blood Pressure"
          name="measurements.systolic_blood_pressure"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                mmHg
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField
          required={!!values?.measurements?.systolic_blood_pressure}
          type="number"
          label="Diastolic Blood Pressure"
          name="measurements.diastolic_blood_pressure"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                mmHg
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <PrioritySelect
          isDisabled
          name="measurements.status"
          label="Status"
          options={statusMap}
        />
      </Grid>

      <Grid item xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">BMI</Typography>
        </Box>
      </Grid>

      <Hidden smUp>
        <Grid item sm={4} xs={12}>
          <SimpleSelect
            name="measurements.height_unit"
            options={heightMap}
            onChange={handleUnitChange('measurements.height_unit')}
          />
        </Grid>
      </Hidden>

      <Grid item sm={4} xs={12}>
        <NumberMaskField
          required
          name="measurements.height"
          label="Height"
          margin="dense"
          format={getHeightFormat({
            isFeet: values?.measurements?.height_unit === measureTypes.feet
          })}
        />
      </Grid>

      <Hidden only="xs">
        <Grid item sm={4} xs={12}>
          <SimpleSelect
            name="measurements.height_unit"
            options={heightMap}
            onChange={handleUnitChange('measurements.height_unit')}
          />
        </Grid>
      </Hidden>

      <Hidden mdDown><Grid item xs={4} /></Hidden>

      <Hidden smUp>
        <Grid item sm={4} xs={12}>
          <SimpleSelect
            name="measurements.weight_unit"
            options={weightMap}
            onChange={handleUnitChange('weight_unit')}
          />
        </Grid>
      </Hidden>

      <Grid item sm={4} xs={12}>
        <TextField
          required
          type="number"
          label="Weight"
          name="measurements.weight"
          margin="dense"
        />
      </Grid>

      <Hidden only="xs">
        <Grid item sm={4} xs={12}>
          <SimpleSelect
            name="measurements.weight_unit"
            options={weightMap}
            onChange={handleUnitChange('weight_unit')}
          />
        </Grid>
      </Hidden>

      <Hidden mdDown><Grid item xs={4} /></Hidden>

      <Grid item sm={4} xs={12}>
        <TextField
          isEditable={false}
          color="secondary"
          type="number"
          name="measurements.bmi"
          label="Your BMI"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField
          disabled
          color="secondary"
          name="measurements.category"
          label="Categoty BMI"
          inputProps={{
            className: classes.input
          }}
          InputProps={{
            className: classes.textFieldInput
          }}
        />
      </Grid>
    </Grid>
  );
};
