import { useContext, useEffect, useRef } from 'react';
import { makeStyles, Paper, useTheme } from '@material-ui/core';
import { useResizeObserver } from '../../../helpers/hooks';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../LayoutContext';
import { EventsList } from '../EventsList';
import { FiltersBar } from '../FiltersBar';
import { ScheduleEventRow, ScheduleEventsList } from '../ScheduleEventsList';
import { Calendar } from './Calendar';
import { Header } from './Header';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ScheduleBody = ({
  hideApplyFields,
  filterKey,
  hiddenFilterFields,
  tasksFilterParams,
  createScheduleEventModalProps = {}
}) => {
  const theme = useTheme();
  const rootRef = useRef(null);
  const classes = useStyles();
  const { width } = useResizeObserver({ ref: rootRef });
  const isTablet = width <= theme.breakpoints.values.sm;
  const isMobileFilterActive = width <= theme.breakpoints.values.tablet;
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse(undefined, undefined, isMobileFilterActive);
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  useEffect(() => {
    if (isTablet) {
      setViewVariant(viewVariantsMap.list);
    }
  }, [ isTablet ]);

  return (
    <Paper ref={rootRef} elevation={2} className={classes.root}>
      <Header
        isTablet={isTablet}
        isMobileFilterActive={isMobileFilterActive}
        filterOpenButton={filterOpenButton}
        createScheduleEventModalProps={createScheduleEventModalProps}
      />

      <ScrollWrapper {...scrollWrapperProps}>
        <FilterBarWrapper {...filterBarWrapperProps}>
          <FiltersBar
            fullWidthFields={width <= theme.breakpoints.values.sm}
            filterKey={filterKey}
            hiddenFields={hiddenFilterFields}
            tasksFilterParams={tasksFilterParams}
          />
        </FilterBarWrapper>
      </ScrollWrapper>

      {viewVariant === viewVariantsMap.calendar ? (
        <div className={classes.main}>
          <div className={classes.calendar}>
            <Calendar
              hideApplyFields={hideApplyFields}
              createScheduleEventModalProps={createScheduleEventModalProps}
            />
          </div>
        </div>
      ) : viewVariant === viewVariantsMap.list ? isTablet ? <EventsList /> : (
        <ScheduleEventsList
          autoHeight
          Row={ScheduleEventRow}
          RowProps={{ viewOnClick: true }}
        />
      ) : null}
    </Paper>
  );
};
