export const styles = ({ palette, spacing, shape: { borderRadius } }) => ({
  bar: {
    display: 'flex',
    flexGrow: 1,
    height: 8,
    marginBottom: spacing(0.5),
    borderRadius,
    overflow: 'hidden'
  },

  expired: {
    backgroundColor: palette.error.main
  },

  done: {
    backgroundColor: palette.success.main
  },

  upcoming: {
    backgroundColor: palette.warning.main
  },

  undone: {
    backgroundColor: palette.grey[800]
  },

  divider: {
    height: 8,
    border: `1px solid ${palette.grey[500]}`,
    borderBottom: 'none'
  },

  dates: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: spacing(),
    paddingRight: spacing(),
    marginTop: spacing(-0.5)
  }
});
