import { forwardRef, useRef, useCallback, useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import ReactFullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { makeStyles } from '@material-ui/core';
import { Loader } from '../Loader';
import { DatePicker } from '../FormField';
import { styles } from './styles';

const calendarPlugins = [ interactionPlugin, dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin ];

export const viewTypes = {
  dayGridMonth: 'dayGridMonth',
  timeGridWeek: 'timeGridWeek',
  timeGridDay: 'timeGridDay',
  resourceTimeGridDay: 'resourceTimeGridDay'
};

const calendarHeader = {
  left: 'title',
  center: `${viewTypes.dayGridMonth},${viewTypes.timeGridWeek},${viewTypes.timeGridDay}`,
  right:  'goToButton today prev,next'
};

const useStyles = makeStyles(styles);

const dayMaxEvents = 2;

export const FullCalendar = forwardRef(({
  isLoading = false,
  selectedDate = moment(),
  viewDidMount = () => {},
  dateClick = () => {},
  moreLinkClick = () => {},
  dayCellClassNames = () => {},
  eventClassNames = () => {},
  onSelectedDateChange = () => {},
  onDayDblClick = () => {},

  ...props
}, ref) => {
  const [ currentViewType, setCurrentViewType ] = useState(viewTypes.dayGridMonth);
  const [ goToPickerIsOpen, setGoToPickerIsOpen ] = useState(false);
  const isDayGridMonth = currentViewType === viewTypes.dayGridMonth;
  const calendarRef = useRef();
  const classes = useStyles();

  const setCalendarRefs = (value) => {
    if (ref) {
      ref.current = value;
    }

    calendarRef.current = value;
  };

  const closeGoToPicker = () => {
    setGoToPickerIsOpen(false);
  };

  const goToDate = (date) => {
    const calendarApi = calendarRef.current.getApi();
    const selectedDate = moment.unix(date);

    calendarApi.gotoDate(selectedDate.toDate());
    onSelectedDateChange(selectedDate);
  };

  const handleDayDblClick = useCallback((info) => (event) => {
    onDayDblClick(info, event);
  }, []);

  const handleDayCellDidMount = (info) => {
    info.el.addEventListener('dblclick', handleDayDblClick(info));
  };

  const handleDayCellWillUnmount = (info) => {
    info.el.removeEventListener('dblclick', handleDayDblClick(info));
  };

  const handleViewDidMount = (info) => {
    const { view: { type } } = info;

    setCurrentViewType(type);
    viewDidMount(info);
  };

  const getEventClassNames = (info) => {
    return cn(eventClassNames(info));
  };

  const getDayCellClassNames = (info) => {
    return cn(classes.day, dayCellClassNames(info), {
      [classes.day_selected]: isDayGridMonth && moment(info.date).isSame(selectedDate, 'day')
    });
  };

  const handleDateClick = (info) => {
    if (!selectedDate.isSame(info.date, 'day')) {
      onSelectedDateChange(moment(info.date));
    }

    dateClick(info);
  };

  const handleMoreLinkClick = (info) => {
    onSelectedDateChange(moment(info.date));
    moreLinkClick(info);
  };

  return (
    <div className={classes.root}>
      <div className={cn(classes.content, isLoading && classes.content_loading)}>
        <ReactFullCalendar
          selectable
          ref={setCalendarRefs}
          schedulerLicenseKey={'GPL-My-Project-Is-Open-Source'}
          selectMinDistance={2}
          slotDuration="00:05"
          eventMinHeight={46}
          height="parent"
          eventDisplay="block"
          plugins={calendarPlugins}
          customButtons={{
            goToButton: {
              text: 'Go to',
              click: () => {
                setGoToPickerIsOpen(true);
              }
            }
          }}
          headerToolbar={calendarHeader}
          dayMaxEvents={dayMaxEvents}
          dayCellClassNames={getDayCellClassNames}
          eventClassNames={getEventClassNames}
          dateClick={handleDateClick}
          moreLinkClick={handleMoreLinkClick}
          viewDidMount={handleViewDidMount}
          dayCellDidMount={handleDayCellDidMount}
          dayCellWillUnmount={handleDayCellWillUnmount}
          {...props}
        />

        <DatePicker
          withoutFormik
          open={goToPickerIsOpen}
          TextFieldComponent={() => null}
          name="date"
          openTo="date"
          onChange={goToDate}
          onClose={closeGoToPicker}
        />

        <Loader loading={isLoading} className={classes.loader} />
      </div>
    </div>
  );
});
