import { useState } from 'react';
import filesize from 'filesize';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Dialog
} from '@material-ui/core';
import * as videoApi from '../../../../api/videos';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../components/Modal';
import { Loader } from '../../../../components/Loader';
import { FileDropZone } from '../../../../components/FileDropZone';
import { FileTypeIcon } from '../../files-common';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const UploadVideoModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [ selectedFile, setSelectedFile ] = useState();

  const uploadFiles = (values, { setErrors, setSubmitting }) => {
    if (!selectedFile) {
      setSubmitting(false);

      return;
    }

    const formData = new FormData();

    formData.append('file', selectedFile, selectedFile.name);

    videoApi.uploadVideo(formData).then(() => {
      enqueueSnackbar(`Video "${selectedFile.name}" uploaded`, {
        variant: 'success'
      });

      handleModalResolve();
    }).catch(({ status, data: { errors } = {} }) => {
      setSubmitting(false);

      if (status === 422 && errors) {
        setErrors(errors);
      }
    });
  };

  const handleDropAccepted = (files) => {
    setSelectedFile(files[0]);
  };

  return (
    <Dialog {...DialogProps}>
      <Formik initialValues={{}} onSubmit={uploadFiles}>
        {({ errors, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Upload videos
            </ModalHeader>

            <ModalBody>
              <FileDropZone onDropAccepted={handleDropAccepted}/>

              {errors.file &&
                <>
                  <Typography paragraph color="error">
                    {errors.file}
                  </Typography>

                  <Divider/>
                </>
              }

              {!!selectedFile &&
                <div className={classes.filesList}>
                  <Typography variant="h2" gutterBottom>
                    File selected for upload
                  </Typography>

                  <List>
                    <ListItem>
                      <FileTypeIcon file={selectedFile}/>

                      <ListItemText
                        primary={selectedFile.name}
                        primaryTypographyProps={{ noWrap: true }}
                      />

                      <ListItemText className={classes.fileSize} primary={filesize(selectedFile.size)}/>
                    </ListItem>
                  </List>
                </div>
              }
            </ModalBody>

            <ModalFooter>
              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Upload
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
