export const typesMap = {
  car_accident: 'car_accident',
  slip_and_fall: 'slip_and_fall',
  other: 'other'
};

export const typesNamesMap = {
  [typesMap.car_accident]: 'Car accident',
  [typesMap.slip_and_fall]: 'Slip and fall',
  [typesMap.other]: 'Other'
};
