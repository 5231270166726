export const filterFieldsMap = {
  type: 'type',
  users: 'users',
  companies: 'companies',
  dueDate: 'due_date',
  sendDate: 'send_date',
  receivedDate: 'received_date'
};

export const filterFieldsLabels = {
  [filterFieldsMap.type]: 'Discovery',
  [filterFieldsMap.users]: 'Users',
  [filterFieldsMap.companies]: 'Companies',
  [filterFieldsMap.dueDate]: 'Due Date',
  [filterFieldsMap.sendDate]: 'Send Date',
  [filterFieldsMap.receivedDate]: 'Received Date'
};
