import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../../../../components/Modal';
import { UsersSelect } from '../../../../../../../components/users';
import { Yup } from '../../../../../../../utils/validation';

const initialValues = { users: [] };
const validationSchema = Yup.object().shape({
  users: Yup.array().of(Yup.object().required()).required()
});

export const AddUsersModal = ({ handleModalReject, handleModalResolve, DialogProps, onClose }) => {
  const handleSubmit = ({ users }) => {
    handleModalResolve(users);
  };

  return (
    <Dialog maxWidth="xs" {...DialogProps}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={onClose}>
              Select users to assign
            </ModalHeader>

            <ModalBody>
              <UsersSelect
                disableValueTransform
                multiple
                name="users"
                label="Select to assign"
                placeholder="Search users..."
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Assign
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
