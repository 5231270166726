import { useSelector } from 'react-redux';
import { columnsMap } from '../../../../components/claims';
import { ClaimsPage } from '../../BillingPage/ClaimsPage';
import { filterFieldsMap } from '../../BillingPage/ClaimsPage/Claims/FiltersBar/filterFieldsMap';

export const Claims = () => {
  const currentUser = useSelector(({ profile }) => profile.user);

  return (
    <ClaimsPage
      initialFilter={{
        patients: [ currentUser?.id ]
      }}
      hiddenColumns={[
        columnsMap.patient,
        columnsMap.caseNo,
        columnsMap.totalAmount
      ]}
      hiddenFilterFields={[
        filterFieldsMap.patients
      ]}
    />
  );
};
