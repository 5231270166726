export const styles = ({ spacing, palette: { grey }, typography: { fontWeightMedium, pxToRem } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },

  videoContent: {
    position: 'relative',
    paddingTop: '56.25%'
  },

  videoPlayer: {
    position: 'absolute',
    top: 0,
    left: 0
  },

  videoInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
    paddingRight: 0,
    maxHeight: '3rem'
  },

  videoTitle: {
    flexGrow: 1,
    overflow: 'hidden'
  },

  videoTitle__name: {
    color: grey[600],
    fontSize: pxToRem(14),
    fontWeight: fontWeightMedium
  },

  videoTitle__subName: {
    color: grey[400],
    fontSize: pxToRem(13)
  }
});
