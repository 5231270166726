import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useReminderEvent } from '../../../../../../../components/cases/reminders/events';
import { SvgIcon } from '../../../../../../../components/SvgIcon';

export const Menu = ({ reminderEvent, onClose = () => {} }) => {
  const {
    editEvent,
    deleteEvent
  } = useReminderEvent(reminderEvent);

  return (
    <List onClick={onClose}>
      <MenuItem onClick={editEvent}>
        <ListItemIcon>
          <SvgIcon color="info" fontSize="inherit">
            <EditIcon />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={deleteEvent}>
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
