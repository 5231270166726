import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { TextField, AddressAutocomplete, UnitDesignator } from '../../../../../components/FormField';

export const AddressContent = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddressChange = (option) => {
    if (option) {
      setFieldValue('home.street', (option.street_number ? option.street_number + ' ' : '') + option.street);
      setFieldValue('home.state', option.state);
      setFieldValue('home.city', option.city);
      setFieldValue('home.zip', option.zip);
      setFieldValue('home.country', option.country);
      setFieldValue('home.lat', option.lat);
      setFieldValue('home.lng', option.lng);
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Box pt={3}>
          <Typography variant="h6">Home info</Typography>
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <AddressAutocomplete
          name="home.street"
          label="Street"
          onChange={handleAddressChange}
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        <UnitDesignator
          required={!!values.unit_number}
          name="home.designator"
          label="Unit Designator"
          placeholder="Select designator..."
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        <TextField
          zeroMinWidth
          name="home.unit_number"
          label="Apt./Suite/Room"
          placeholder="Enter apt/suite/room..."
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField
          zeroMinWidth
          name="home.city"
          label="City"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField
          zeroMinWidth
          name="home.state"
          label="State"
        />
      </Grid>

      <Grid item sm={4} xs={6}>
        <TextField
          zeroMinWidth
          name="home.zip"
          label="ZIP Code"
        />
      </Grid>

      <Grid item sm={4} xs={6}>
        <TextField
          zeroMinWidth
          name="home.country"
          label="Country"
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" fontWeight={500} mt={2}>
          <NotesIcon fontSize="small" />

          <Box ml={1}>
            <Typography variant="subtitle2">Note</Typography>
          </Box>
        </Box>

        <TextField
          fullWidth
          multiline
          disableUnderline
          rows={3}
          variant="filled"
          name="home.note"
        />
      </Grid>
    </Grid>
  );
};
