import moment from 'moment';
import { makeStyles, Box, Typography, SvgIcon } from '@material-ui/core';
import { mdiChatProcessingOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ appt }) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" mb={1.5}>
        <Box mr={1}>
          <SvgIcon><Icon path={mdiChatProcessingOutline} /></SvgIcon>
        </Box>

        <Typography noWrap variant="h6">Consultation</Typography>
      </Box>

      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="h3">
            {appt.appointment_at ? moment.unix(appt.appointment_at).format('D MMM, YYYY') : '-'}
          </Typography>

          <Typography variant="caption" color="textSecondary">
            Appt. Date
          </Typography>
        </div>

        <div className={classes.content}>
          <Typography variant="h3">
            {appt.appointment_at ? moment.unix(appt.appointment_at).format('LT') : '-'}
          </Typography>

          <Typography variant="caption" color="textSecondary">
            Appt. Time
          </Typography>
        </div>
      </div>
    </>
  );
};
