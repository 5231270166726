import { Redirect, Link, Switch, useLocation } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { renderRoutes, Route } from '../../../components/router';
import { Tabs, Tab } from '../../../components/Tabs';
import { Payer } from '../CodesPage/Payer';
import { TimeTrackCategory } from '../SystemSettings';
import { BillingSettings } from './BillingSettings';
import { routes } from './routes';

export const BillingPage = ({ location }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { pathname } = useLocation();
  const mainSettingsPath = '/billing/system-settings/expense-preference';
  const mainSettingsTracksPath = '/billing/system-settings/timetrack-category';
  const isSettingsPage = location.pathname.includes('codes');

  const getActiveRoute = () => {
    const route = routes.find(({ path }) => location.pathname.indexOf(path) === 0);

    return route ? route.path : '/billing/dashboard';
  };

  if (pathname === mainSettingsPath) {
    return (
      <Switch>
        <Route exact path={mainSettingsPath} component={BillingSettings} />
      </Switch>
    );
  }

  if (pathname === mainSettingsTracksPath) {
    return (
      <Switch>
        <Route exact path={mainSettingsTracksPath} component={TimeTrackCategory} />
      </Switch>
    );
  }

  return isSettingsPage ? (
    <Box height="100%" bgcolor="grey.100">
      <Box height="100%" py={isMobile ? 0 : 2} px={isMobile ? 0 : 3}>
        <Payer baseUrl="/billing/system-settings/codes" />
      </Box>
    </Box>
  ) : (
    <>
      <Route exact path="/billing">
        <Redirect to="/billing/dashboard" />
      </Route>

      <Route exact path="/billing/system-settings">
        <Redirect to={mainSettingsPath} />
      </Route>

      <Box display="flex" flexDirection="column" height="100%" bgcolor="grey.100" overflow="hidden">
        {!isMobile && (
          <Tabs value={getActiveRoute()}>
            {routes.map(({ path, icon, label }) => (
              <Tab key={path} component={Link} to={path} value={path} icon={icon} label={label} />
            ))}
          </Tabs>
        )}

        {renderRoutes(routes)}
      </Box>
    </>
  );
};
