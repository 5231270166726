import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { Loader } from '../../../Loader';
import { InfiniteListLoader } from '../../../InfiniteLoader';
import { Sticky } from '../../../Sticky';
import { TableHeader } from './TableHeader';
import { AuthorizationsContext } from '../AuthorizationsProvider';
import { Row } from './Row';

export const columnsLargeWidths = {
  authorization: 250,
  effectiveDate: 180,
  expirationDate: 180,
  isPending: 100,
  actions: 50
};

export const columnsMobileWidths = {
  authorization: 100,
  effectiveDate: 110,
  expirationDate: 110,
  isPending: 100,
  actions: 50
};

export const List = () => {
  const {
    isFetched,
    isFetching,
    authorizations,
    meta,
    loadNextPage,
    resetAuthorizations
  } = useContext(AuthorizationsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetAuthorizations();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
      <Loader p={3} loading={!isFetched} render={
        () => !authorizations.length ? (
          <Box p={3}>
            <Typography align="center">No authorizations found</Typography>
          </Box>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader  />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={authorizations}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                RowComponent={Row}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
              />
            </MuiList>
          </>
        )}
      />
    </CustomScrollbars>
  );
};
