export const styles = ({
  spacing,
  breakpoints,
  palette: {
    grey,
    darkgreen,
    divider
  },
  typography: { fontWeightMedium }
}) => ({
  root: {
    margin: spacing(3, 6, 0, 4),

    [breakpoints.down(breakpoints.values.md)]: {
      margin: spacing(1)
    }
  },

  rootForm: {
    border: `1px solid ${divider}`,
    borderBottom: 0
  },

  row: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    alignItems: 'center'
  },

  noteIcon: {
    display: 'flex',
    alignItems:'center',
    fontWeight: fontWeightMedium
  },

  balance: {
    color: grey[600],
    fontWeight: fontWeightMedium
  },

  noteField: {
    marginTop: spacing(2)
  },

  contentField: {
    minWidth: 0,
    marginTop: spacing(0.5)
  },

  contentFieldInput: {
    color: `${grey[800]} !important`,

    '&:before': {
      borderBottomStyle: 'solid !important'
    }
  },

  invoiceInfoField: {
    minWidth: 0,
    marginBottom: spacing(),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      marginBottom: 0
    }
  },

  invoiceInfoField_date: {
    color: darkgreen
  },

  totalRoot: {
    display: 'flex',
    width: 330,
    padding: spacing(1, 0),
    marginLeft: 'auto'
  },

  totalContent: {
    fontWeight: fontWeightMedium,
    minWidth: 170,
    textAlign: 'left',
    paddingLeft: spacing(4),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      paddingLeft: 0
    }
  },

  totalContent_budget: {
    display: 'flex',
    alignItems: 'center',
    color: darkgreen
  }
});
