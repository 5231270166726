import { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import cn from 'classnames';
import T from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import AsyncReactSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { makeStyles, useTheme } from '@material-ui/core';
import { usePrevious } from '../../../../helpers/hooks';
import { useFormikField } from '../../useFormikField';
import { AsyncPaginateSelect } from '../AsyncPaginateSelect';
import * as components from './components';
import { styles, getOverriderStyles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  name: T.string.isRequired,
  formattedValue: T.bool,
  maxWidth: T.number,
  isAsync: T.bool,
  isCreatable: T.bool
};

export const Select = ({
  name,
  withoutFormik,
  isAsync = false,
  isCreatable = false,
  formattedValue,
  promiseWithOptions,
  options = [],
  onChange = () => {},
  onChangeField = () => {},
  isMulti,
  className,
  components: componentsProp = {},

  ...props
}) => {
  const classes = useStyles(props);
  const theme = useTheme();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField(name);
  const {
    isFormikField,
    fieldProps: [ field = {}, { initialValue, touched } = {}, formikHelpers ] = [],
    error
  } = formikFieldProps;
  const [ value, setValue ] = useState(props.value || field.value);
  const prevValue = usePrevious(value);
  const formikInitialValueRef = useRef(initialValue);
  const formikValueRef = useRef(null);

  // React Hook Form
  const formContext = useFormContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { fieldState } = (formContext && useController({
    name, control: formContext?.control
  })) || {};
  const errorMessage = fieldState?.error?.message;

  const handleChange = (value = null) => {
    const formValue = isMulti ? (value || []).map(({ value }) => value) : value ? value.value : value;

    setValue(value);
    formContext?.setValue(name, formValue, { shouldValidate: true });

    if (!isFormikField) {
      return;
    }

    if (formattedValue) {
      formikValueRef.current = formValue;
      formikHelpers.setValue(formValue);
      onChangeField?.(formValue);
    } else {
      const formikValue = isMulti ? value || [] : value ? value : value;

      formikValueRef.current = formikValue;
      formikHelpers.setValue(formikValue);
      onChangeField?.(formikValue);
    }
  };

  const Select = isAsync
    ? AsyncPaginateSelect
    : isCreatable
      ? CreatableSelect
      : promiseWithOptions
        ? AsyncReactSelect
        : ReactSelect;

  const AsyncSelectProps = promiseWithOptions ? {
    defaultOptions: !isAsync,
    loadOptions: promiseWithOptions,
    additional: {
      page: 1
    },
    SelectComponent: isCreatable ? CreatableSelect : ReactSelect
  } : {};

  useEffect(() => {
    if (isFormikField && !isEqual(initialValue, formikInitialValueRef.current)) {
      formikInitialValueRef.current = initialValue;
      handleChange(initialValue);
    }
  }, [ initialValue ]);

  useEffect(() => {
    if (!isEqual(field.value, formikValueRef.current)) {
      handleChange(field.value);
    }
  }, [ field.value ]);

  useEffect(() => {
    if (props.value !== undefined) {
      handleChange(props.value);
    }
  }, [ props.value ]);

  useEffect(() => {
    if (!isEqual(prevValue, value)) {
      onChange(isMulti ? value || [] : value);
    }
  }, [ prevValue, value ]);

  return (
    <Select
      name={name}
      isMulti={isMulti}
      menuPosition="fixed"
      menuPlacement="bottom"
      menuPortalTarget={document.body}
      options={options}
      styles={getOverriderStyles(theme)}
      onChange={handleChange}
      className={cn(classes.root, className)}
      components={{
        ...components,
        ...componentsProp
      }}

      {...AsyncSelectProps}
      {...props}

      value={isFormikField ? value : props.value}
      error={(touched || fieldState?.invalid) && (error || errorMessage)}
    />
  );
};

Select.propTypes = propTypes;
