import { useContext } from 'react';
import { Box } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import { useEmailsModal } from '../../../../../../../../utils/hooks';
import { WidgetsActionButtons } from '../../../../../../../../components/WidgetsActionButtons';
import { ContentCard } from '../../../../../../../../components/ContentCard';
import { EmailsContent } from '../../../../../../../../components/Widgets';
import { Fab } from '../../../../../../../../components/Fab';
import {
  AccountsContext,
  SavedEmailsContext,
  SavedEmailsContextProvider
} from '../../../../../../EmailsPage';
import { UserContext } from '../../../../UserProvider';

export const Email = ({
  isOpen,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { isFetched, user } = useContext(UserContext);
  const { onSendEmail } = useEmailsModal({ patient: user });
  const { accounts } = useContext(AccountsContext);

  const filter = {
    patient_id: user.id
  };

  return isFetched && (
    <SavedEmailsContextProvider filter={filter} patientItem={user}>
      <SavedEmailsContext.Consumer>
        {({ fetchSavedMessages }) => (
          <ContentCard
            fullHeight
            isMovable
            disableContentPaddings
            isResizable={isOpen}
            title="Emails"
            icon={
              <Fab
                size="small"
                color="primary"
                variant="extended"
                onClick={onSendEmail({ fetchSavedMessages, accounts })}
              >
                Compose message
              </Fab>
            }
            leftActions={[
              <Box mr={1}>
                <EmailIcon color="inherit" fontSize="small" />
              </Box>
            ]}
            rightActions={[
              <WidgetsActionButtons
                isMinimizable
                isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
                onMinimize={onMinimize}
                onMaximized={onMaximized}
                onClose={onClose}
                onOpen={onOpen}
                onPutItem={onPutItem}
              />
            ]}
          >
            <EmailsContent />
          </ContentCard>
        )}
      </SavedEmailsContext.Consumer>
    </SavedEmailsContextProvider>
  );
};
