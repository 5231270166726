export const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  list: {
    flexGrow: 1,
    overflow: 'hidden'
  }
});
