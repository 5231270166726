import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  List,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { hasRole } from '../../../../../utils/hasRole';
import { itemsRoutesByModels } from '../../../../../dataMaps/apiModels';
import { useModal } from '../../../../../components/ModalsProvider';
import { EditOfficeModal } from '../EditOfficesModal';
import { OfficesContext } from '../OfficesContext';

export const OfficesMenu = ({ office, onClose }) => {
  const { openModal } = useModal();
  const history = useHistory();
  const { updateOffice, deleteOffice } = useContext(OfficesContext);

  const handleUpdateOffice = () => {
    openModal(EditOfficeModal, {
      payload: {
        id: office.id
      },
      onModalResolved: (data) => {
        const venue = data.venue.find((venue) => venue?.is_main) || {};

        updateOffice({ ...data, venue_full_address: venue.full_address || null });
      }
    });
  };

  const handleDeleteOffice = () => {
    deleteOffice(office.id);
  };

  const getOfficePage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.office(office.id));
    }
  };

  return (
    <List onClick={onClose}>
      <MenuItem onClick={getOfficePage}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      <MenuItem onClick={handleUpdateOffice}>
        <ListItemIcon>
          <EditIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      {!hasRole('client', 'patient', 'doctor', 'medic') &&
        <MenuItem onClick={handleDeleteOffice}>
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="inherit"/>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      }
    </List>
  );
};
