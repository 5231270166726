import _ from 'lodash';

export const isEmptyDeep = (value) => {
  if (_.isObject(value)) {
    if (Object.keys(value).length === 0) {
      return true;
    }

    return _.every(_.map(value, isEmptyDeep));
  }

  return !value && value !== 0 && value !== false;
};
