import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Formik } from 'formik';
import { Icon } from '@mdi/react';
import { mdiFileMoveOutline } from '@mdi/js';
import { Box, Button, Dialog, SvgIcon } from '@material-ui/core';
import { MessageItem } from '../../../app/Dashboard/MessengerPage/ChatThread/MessageList';
import { Yup } from '../../../utils/validation';
import * as chatsApi from '../../../api/chat';
import { TextField } from '../../FormField';
import { Loader } from '../../Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { Scrollbars } from '../../Scrollbars';
import { MessageContextProvider } from '../MessageProvider';
import { MessengerContextProvider } from '../MessengerProvider';
import { ThreadsContextProvider } from '../ThreadsContext';
import { ChatsList } from './ChatsList';
import { ChatsNavigation } from './ChatsNavigation';

const validationSchema = Yup.object().shape({
  message: Yup.string().trim()
});

export const ForwardModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { selectedMessages = [] }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ message, setMessage ] = useState('');
  const [ selectedThreadsIDs, setSelectedThreadsIDs ] = useState([]);

  const addSelectedThreadID = (id) => {
    setSelectedThreadsIDs((selectedThreadsIDs) => selectedThreadsIDs.concat(id));
  };

  const removeSelectedThreadID = (id) => {
    const updatedSelectedThreadIDs = selectedThreadsIDs.filter((threadId) => threadId !== id);

    setSelectedThreadsIDs(updatedSelectedThreadIDs);
  };

  const toggleThreadIDSelection = (id) => {
    if (selectedThreadsIDs.includes(id)) {
      removeSelectedThreadID(id);
    } else {
      addSelectedThreadID(id);
    }
  };

  const sendMessagesToChats = () => {
    const data = {
      body: message,
      messages: selectedMessages.map((message) => ({
        id: message?.body || message?.files?.length ? message.id : message.parent?.id
      }))
    };

    return Promise.all(selectedThreadsIDs.map((id) => {
      return chatsApi.createMessage({ ...data, thread: { id } });
    })).then(() => {
      enqueueSnackbar('Message forwarded', { variant: 'success' });
      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Message not forwarded', { variant: 'error' });
    });
  };

  const handleFieldChange = (event) => {
    setMessage(event.target.value);
  };

  const handleTextRecognized = (recognizedText) => {
    setMessage((message) => message + recognizedText);
  };

  return (
    <Dialog PaperProps={{ style: { maxWidth: 475 } }} {...DialogProps}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          message: '',
          thread_id: null,
          message_description: null
        }}
        onSubmit={sendMessagesToChats}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiFileMoveOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Forward to
            </ModalHeader>

            <ModalBody>
              <MessengerContextProvider>
                <Box vh="20vh" my={1}>
                  {selectedMessages.map((message, index) => (
                    <MessageContextProvider
                      dense
                      preview
                      message={message}
                      messages={selectedMessages}
                      index={index}
                      key={message.id}
                    >
                      <Scrollbars autoHeight autoHeightMax={160}>
                        <MessageItem disableMenu />
                      </Scrollbars>
                    </MessageContextProvider>
                  ))}
                </Box>

                <ThreadsContextProvider>
                  <Box my={1}>
                    <TextField
                      fullWidth
                      multiline
                      withoutFormik
                      margin="dense"
                      value={message}
                      variant="outlined"
                      placeholder="Write a message..."
                      onChange={handleFieldChange}
                      onTextRecognized={handleTextRecognized}
                    />
                  </Box>

                  <ChatsNavigation />

                  <Box height="20vh" mt={1}>
                    <ChatsList
                      selectedThreadsIDs={selectedThreadsIDs}
                      onToggleThreadIDSelection={toggleThreadIDSelection}
                    />
                  </Box>
                </ThreadsContextProvider>
              </MessengerContextProvider>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={!selectedThreadsIDs.length || isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
