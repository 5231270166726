import cn from 'classnames';
import T from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  gutter: T.number,
  disableTopGutter: T.bool,
  disableBottomGutter: T.bool,
  type: T.oneOf([ 'solid', 'dotted', 'dashed' ]),
  hrProps: T.object
};

export const Divider = ({ className, hrProps, ...props }) => {
  const classes = useStyles(props);

  return (
    <hr className={cn(classes.root, className)} {...hrProps} />
  );
};

Divider.propTypes = propTypes;
