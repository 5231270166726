import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Dialog, SvgIcon, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { mdiCommentMultipleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import * as mrisApi from '../../../../../api/profile';
import { ModalBody, ModalContainer, ModalHeader, ModalHeaderActions } from '../../../../../components/Modal';
import { Comments, CommentsContextProvider } from '../../../../../components/Comments';
import { MedicalAttachments } from '../../../../../components/MedicalAttachments';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Loader } from '../../../../../components/Loader';
import { ReportsPreviewContext, ReportsPreviewProvider } from '../../ReportsPreviewProvider';
import { EditMRIModal } from '../EditMRIModal';
import { MainContent } from './MainContent';

export const PreviewMRIModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { mri, onUpdate, onProcedureDelete }
}) => {
  const { openModal } = useModal();
  const commentsLastGlobalAction = useSelector(({ globalActions }) => globalActions.commentsLastGlobalAction);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const commentsInitialState = {
    filter: {
      parent_type: 'mri',
      parent_id: mri?.id
    }
  };

  const onDelete = () => {
    onProcedureDelete(mri.id);
    handleModalResolve();
  };

  const fetchMRI = () => {
    return mrisApi.fetchMRI(mri.id);
  };

  const handleUpdateMRI = (value, callback) => () => {
    openModal(EditMRIModal, {
      payload: {
        mri,
        appointment_id: mri.appointment_id,
        onListUpdate: onUpdate
      },
      onModalResolved: (data) => {
        callback({ ...value, ...data });
      }
    });
  };

  useEffect(() => {
    if (commentsLastGlobalAction) {
      onUpdate();
    }
  }, [ commentsLastGlobalAction ]);

  return (
    <ReportsPreviewProvider
      appointment_id={mri.appointment_id}
      fetchItem={fetchMRI}
      onUpdate={onUpdate}
    >
      <ReportsPreviewContext.Consumer>
        {({ isFetched, selectedItem, setSelectedItem }) => (
          <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
            <Loader p={3} loading={!isFetched} render={
              () => (
                <ModalContainer>
                  <ModalHeader onClose={handleModalReject}>
                    View MRI Scan

                    <ModalHeaderActions disableGutters>
                      {!hasRole(rolesMap.client, rolesMap.patient) &&
                        <>
                          <Tooltip arrow title="Edit MRI">
                            <IconButton
                              color="primary"
                              onClick={handleUpdateMRI(selectedItem, setSelectedItem)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip arrow title="Delete MRI">
                            <IconButton
                              color="error"
                              onClick={onDelete}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    </ModalHeaderActions>
                  </ModalHeader>

                  <ModalBody>
                    <MainContent />

                    <MedicalAttachments
                      owner_type="mri"
                      owner_id={mri.id}
                      files={selectedItem.media || []}
                    />

                    <Box display="flex" alignItems="center" mt={1} mb={2}>
                      <SvgIcon><Icon path={mdiCommentMultipleOutline} /></SvgIcon>

                      <Box ml={1}>
                        <Typography variant="h3">
                          Comments
                        </Typography>
                      </Box>
                    </Box>

                    <Box minHeight={300}>
                      <CommentsContextProvider initialState={commentsInitialState}>
                        <Comments
                          parentType="mri"
                          parentID={mri.id}
                        />
                      </CommentsContextProvider>
                    </Box>
                  </ModalBody>
                </ModalContainer>
              )}
            />
          </Dialog>
        )}
      </ReportsPreviewContext.Consumer>
    </ReportsPreviewProvider>
  );
};
