import { useState } from 'react';
import { List as MuiList, Box } from '@material-ui/core';
import { apiModelsMap } from '../../../../../../dataMaps/apiModels';
import { NestedList } from '../../../../../../components/NestedList';
import { useModal } from '../../../../../../components/ModalsProvider';
import { InvoiceFileUploadModal } from '../../InvoiceFileUploadModal';
import { List } from './List';

export const Attachments = ({ invoice }) => {
  const { openModal } = useModal();
  const [ isOpen, setIsOpen ] = useState(false);
  const [ filesCount, setFilesCount ] = useState(invoice?.files?.length || 0);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  const uploadFile = () => {
    openModal(InvoiceFileUploadModal, {
      payload: {
        owner_type: apiModelsMap.invoice,
        owner_id: invoice.id
      }
    });
  };

  return (
    <Box mt={2} mb={1} mx={2}>
      <NestedList
        disablePadding
        disableDivider
        disableBackground
        isOpen={isOpen}
        actionTitle="Add"
        buttonSize="small"
        title={`${filesCount} Attachments`}
        TypographyProps={{ fontSize: 'medium' }}
        onCreate={uploadFile}
        onOpen={handleOpenListItem}
      >
        <MuiList disablePadding>
          <Box flexGrow={1} height={200}>
            <List isOpen={isOpen} setFilesCount={setFilesCount} />
          </Box>
        </MuiList>
      </NestedList>
    </Box>
  );
};
