import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import LockIcon from '@material-ui/icons/LockOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../components/Loader';
import { CodesMenu } from '../../../CodesMenu';
import { VisitReasonsContext } from '../../VisitReasonsContext';
import { columnsWidthsLg, columnsWidthsSm } from '../List';

export const Row = ({ item: visitReason = {}, baseUrl, isLoaded, recalculateHeight }) => {
  const visitReasonsContext = useContext(VisitReasonsContext);
  const [ isBusy, setIsBusy ] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  const toggleActiveVisitReason = () => {
    const data = { ...visitReason, is_active: !visitReason.is_active };

    setIsBusy(true);

    visitReasonsContext.toggleActiveVisitReason(data).then(() => {
      setIsBusy(false);
    });
  };

  const deleteVisitReason = () => {
    setIsBusy(true);

    visitReasonsContext.deleteVisitReason(visitReason).then(() => {
      setIsBusy(false);
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return (
    <Loader fullWidth p={isBusy ? 0 : 2} surface={isBusy} loading={!isLoaded || isBusy} render={
      () => (
        <ListRow disabled={!visitReason.is_active}>
          <ListRowCell
            variant={isMobile ? 'h5' : 'inherit'}
            color={isMobile ? 'primary' : 'textPrimary'}
            noWrap
            grow
            column={isMobile}
            flexBasis={columnsWidths.code}
          >
            {visitReason.code}

            {isMobile && (
              <Typography noWrap color="textPrimary">
                {visitReason.description}
              </Typography>
            )}
          </ListRowCell>

          {!isMobile && (
            <ListRowCell flexBasis={columnsWidths.description}>
              {visitReason.description}
            </ListRowCell>
          )}

          <ListRowCell actions noDisable justifyContent="flex-end" flexBasis={columnsWidths.actions}>
            {isMobile ? (
              <CodesMenu
                isActive={visitReason.is_active}
                url={`${baseUrl}/visit-reasons/${visitReason.id}/edit`}
                onToggle={toggleActiveVisitReason}
                onDelete={deleteVisitReason}
              />
            ) : (
              <>
                <IconButton
                  title="Edit"
                  component={Link}
                  to={`${baseUrl}/visit-reasons/${visitReason.id}/edit`}
                  disabled={!visitReason.is_active}
                  variant="contained"
                  color="info"
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  title={visitReason.is_active ? 'Deactivate' : 'Activate'}
                  variant="contained"
                  color={visitReason.is_active ? 'warning' : 'success'}
                  onClick={toggleActiveVisitReason}
                >
                  {visitReason.is_active ? <LockIcon /> : <LockOpenIcon />}
                </IconButton>

                <IconButton
                  title="Delete"
                  variant="contained"
                  color="error"
                  onClick={deleteVisitReason}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </ListRowCell>
        </ListRow>
      )}
    />
  );
};
