import { Yup } from '../../../../../utils/validation';
import { validationSchema as firstStepValidationSchema } from './FirstStep/validationSchema';
import { validationSchema as secondStepValidationSchema } from './SecondStep/validationSchema';

export const validationSchema = Yup.object().shape({
  forms: Yup.array().nullable().of(Yup.object().shape({
    discussion: Yup.array().nullable().of(Yup.object().shape({
      text: Yup.string().nullable()
    })),
    medical_causation: Yup.array().nullable().of(Yup.object().shape({
      text: Yup.string().nullable()
    })),
    treatment_plan: Yup.array().nullable().of(Yup.object().shape({
      text: Yup.string().nullable(),
      approve: Yup.boolean().nullable(),
      physical_therapy: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      acupuncture: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      renew_current_medications: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      without_modification: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      with_modification: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      urine_test: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      potential_risks_re_reviewed: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      investigation_planned: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      request_authorization: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      without_modification_auth: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      procedure_to_be_performed: Yup.array().nullable().of(Yup.object().shape({
        check: Yup.boolean(),
        text: Yup.string().nullable()
      })),
      time_spent: Yup.string().nullable(),
      next_follow_up: Yup.string().nullable()
    })),
    assessment: Yup.array().nullable().of(Yup.object().shape({
      text: Yup.string().nullable()
    })),
    diagnostic_studies: Yup.array().nullable().of(Yup.object().shape({
      text: Yup.string().nullable(),
      icd: Yup.array().nullable().of(Yup.object().shape({
        id: Yup.mixed().nullable(),
        code: Yup.string().nullable(),
        description: Yup.string().nullable()
      })),
      return_work_indicator: Yup.mixed().nullable(),
      disability: Yup.string().nullable()
    })),
    current_history: Yup.array().nullable().of(Yup.object().shape({
      no_interval_change: Yup.boolean(),
      flare_up_of_pain: Yup.boolean(),
      persistent_pain: Yup.boolean(),
      questionnaire_reviewed_discussed: Yup.boolean(),
      current_complaints_discussed: Yup.boolean(),
      note: Yup.string().nullable(),
      current_medications_use_their_side_effects_discussed: Yup.boolean(),
      alternative_treatments_discussed: Yup.boolean()
    })),
    review_of_symptoms: Yup.array().nullable().of(Yup.object().shape({
      no_interval_change: Yup.boolean(),
      negative: Yup.boolean(),
      history_of: Yup.boolean(),
      note: Yup.string().nullable()
    })),
    current_medications: Yup.array().nullable().of(Yup.object().shape({
      see_list: Yup.boolean(),
      non_complaint_with_pain_management_agreement: Yup.boolean(),
      medications: Yup.array().nullable().of(Yup.object().shape({
        id: Yup.mixed().nullable(),
        code: Yup.string().nullable(),
        name: Yup.string().nullable()
      }))
    })),
    allergies: Yup.array().nullable().of(Yup.object().shape({
      nkda: Yup.boolean(),
      allergic_to: Yup.boolean(),
      note: Yup.string().nullable(),
      denies_history_of: Yup.array().nullable().of(Yup.object().shape({
        sedation: Yup.boolean(),
        nauseal_vomitting: Yup.boolean(),
        constipation: Yup.boolean(),
        pruritis: Yup.boolean()
      }))
    })),
    radiology_exams_lab_results: Yup.array().nullable().of(Yup.object().shape({
      no_new_data: Yup.boolean(),
      mri: Yup.boolean(),
      ct: Yup.boolean(),
      x_ray: Yup.boolean(),
      labs: Yup.boolean(),
      emg: Yup.boolean(),
      other: Yup.boolean(),
      date: Yup.string().nullable(),
      findings: Yup.string().nullable()
    })),
    diagnostic_impression: Yup.array().nullable().of(Yup.object().shape({
      icd: Yup.array().nullable().of(Yup.object().shape({
        id: Yup.mixed().nullable(),
        code: Yup.string().nullable(),
        description: Yup.string().nullable()
      })),
      note: Yup.string().nullable()
    })),
    physical_examination: Yup.array().of(secondStepValidationSchema),
    medical_form_info: Yup.array().of(firstStepValidationSchema)
  }))
});
