import { useEffect, useMemo, useState } from 'react';
import {
  getBMI,
  measureTypes
} from '../helpers/measureDataMap';
import { getHeightFormat } from '../helpers/generateHeight';

export const useMeasurements = (values = {}) => {
  const [ feetHeightMask, setFeetHeightMask ] = useState(0);
  const [ heightMask, setHeightMask ] = useState(0);
  const inchesInOneFeet = 12;
  const bmi = useMemo(() => {
    return getBMI({
      ...values,
      height: values?.height_unit === measureTypes.feet
        ? feetHeightMask
        : heightMask
    });
  }, [ values, feetHeightMask, heightMask ]);

  useEffect(() => {
    if (values?.height && values?.height_unit === measureTypes.feet) {
      const transformHeight = values?.height?.length === 1
        ? values?.height + '0'
        : values?.height;

      let height = transformHeight?.length > 0
        ? values?.height.split('', 2).map((item) => +item || 0)
        : null;

      if (height) {
        setFeetHeightMask((height[0] * inchesInOneFeet + (height[1] || 0)).toFixed(0));
      }
    }

    if (values?.height && values?.height_unit === measureTypes.m) {
      let height = getHeightFormat({
        isFeet: values.height_unit === measureTypes.feet,
        isTransform: true
      })(`${values.height}`);

      if (height) {
        setHeightMask(+height);
      }
    }
  }, [ values?.height ]);

  return { bmi, onFeetHeightChange: setFeetHeightMask };
};
