export const drawerWidth = 332;

export const styles = ({ spacing, transitions }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  appBar: {
    transition: transitions.create([ 'margin', 'width' ], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    })
  },

  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: transitions.create([ 'margin', 'width' ], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen
    })
  },

  menuButton: {
    marginRight: spacing(2)
  },

  hide: {
    display: 'none'
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0, 1),
    justifyContent: 'flex-end'
  },

  content: {
    flexGrow: 1,
    padding: spacing(3),
    transition: transitions.create('margin', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    }),

    marginLeft: -drawerWidth
  },

  contentShift: {
    transition: transitions.create('margin', {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen
    }),

    marginLeft: 0
  }
});
