import {
  CurrentSymptomsInjury,
  PastMedicalHistory,
  AccidentInfoOrtho,
  GeneralInfoInjury,
  AppointmetsInfo,
  MedicalHistory,
  PatientHistory,
  ReviewSymptoms,
  ReviewOfSymptoms,
  AccidentInfo,
  GeneralInfo,
  BackPain,
  NeckPain
} from '../../ProfilePage/MedicalInfo/MedicalForms/FormPreviewModal';
import { MiddlePain } from '../../ProfilePage/MedicalInfo/MedicalForms/FormPreviewModal/MiddlePain';

export const componentsMapPrivate = [
  GeneralInfo,
  AppointmetsInfo,
  CurrentSymptomsInjury,
  NeckPain,
  MiddlePain,
  BackPain,
  MedicalHistory,
  ReviewSymptoms
];

export const componentsMapInjury = [
  GeneralInfoInjury,
  AppointmetsInfo,
  CurrentSymptomsInjury,
  AccidentInfo,
  NeckPain,
  MiddlePain,
  BackPain,
  MedicalHistory
];

export const componentsMapInjuryOrtho = [
  GeneralInfoInjury,
  AppointmetsInfo,
  PatientHistory,
  AccidentInfoOrtho,
  PastMedicalHistory,
  ReviewOfSymptoms
];
