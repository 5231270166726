import { useContext, useEffect, useState } from 'react';
import { TaskPreviewContext } from '../../../../components/tasks/TasksProvider';
import { Loader } from '../../../../components/Loader';
import { EditTaskModal } from '../EditTaskModal';
import { PreviewTaskModal } from '../PreviewTaskModal';

export const Body = ({
  isEditable,
  isSubmitting,
  deleteTask,
  handleModalReject,
  handleModalResolve
}) => {
  const [ isEdit, setIsEdit ] = useState(isEditable);
  const { isFetched, selectedTaskId, fetchTask } = useContext(TaskPreviewContext);

  useEffect(() => {
    fetchTask(selectedTaskId);
  }, [ selectedTaskId ]);

  return (
    <Loader loading={!isFetched} p={3} render={
      () => (!isEdit
        ? (
          <PreviewTaskModal
            setIsEdit={setIsEdit}
            deleteTask={deleteTask}
            handleModalReject={handleModalReject}
          />
        ) : (
          <EditTaskModal
            isSubmitting={isSubmitting}
            handleModalReject={handleModalReject}
            handleModalResolve={handleModalResolve}
          />
        )
      )}
    />
  );
};
