export const tasksPrioritiesOptions = [
  {
    value: 'low',
    label: 'Low'
  },
  {
    value: 'medium',
    label: 'Medium'
  },
  {
    value: 'high',
    label: 'High'
  },
  {
    value: 'critical',
    label: 'Critical'
  }
];
