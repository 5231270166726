import { ListRow, ListRowCell } from '../../../../../ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell noWrap flexBasis={columnsWidths.companies}>
        Companies
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.users}>
        Users
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateFrom}>
        Date From
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateTo}>
        Date To
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.orderBy}>
        Order by
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
