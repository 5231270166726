import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { UserPreviewModal } from '../../../../../../../../components/users';
import { PaymentMenu } from '../../../../../../../../components/billing/payments';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Popper } from '../../../../../../../../components/Popper';
import { PreviewCompanyModal } from '../../../../../../Contacts/Companies/PreviewCompanyModal';
import { minRowHeight, columnsWidths, widthBreakpointXS, widthBreakpointXXS } from '../MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ disableInvoicePreview, payment, width }) => {
  const history = useHistory();
  const classes = useStyles();
  const { openModal } = useModal();

  const getPaymentPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.payment(payment.id));
    }
  };

  const openUserPreview = () => {
    if (payment.invoice.payer.id) {
      openModal(UserPreviewModal, {
        payload: { id: payment.invoice.payer.id }
      });
    }
  };

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: {
        companyID: payment?.payer?.participant_id
      }
    });
  };

  return (
    <ListRow
      minHeight={minRowHeight}
      className={classes.root}
      title="Open payment page"
      onClick={getPaymentPage}
    >
      {width > widthBreakpointXXS &&
        <ListRowCell grow flexBasis={columnsWidths.payer}>
          {payment?.payer?.participant_type === 'company' ? (
            <CompanyLink
              noWrap
              size="sm"
              company={payment?.payer?.participant}
              onClick={stopPropagation(openPreviewCompanyModal)}
            />
          ) : (
            <UserLink
              noWrap
              size="sm"
              user={payment?.payer?.participant}
              onClick={stopPropagation(openUserPreview)}
            />
          )}
        </ListRowCell>
      }

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={columnsWidths.paid_date} color="secondary">
          {payment.date ? moment(payment.date).format('L') : '-'}
        </ListRowCell>
      }

      <ListRowCell grow noWrap flexBasis={columnsWidths.number}>
        {payment?.invoice?.number || '-'}
      </ListRowCell>

      <ListRowCell component={Box} flexBasis={columnsWidths.total}>
        <CurrencyFormat value={payment?.invoice?.total} TypographyProps={{ noWrap: true }} />
      </ListRowCell>

      <ListRowCell component={Box} flexBasis={columnsWidths.amount}>
        <CurrencyFormat value={payment?.amount} TypographyProps={{ noWrap: true }} />
      </ListRowCell>

      {width > widthBreakpointXS &&
        <ListRowCell component={Box} flexBasis={columnsWidths.balance}>
          <CurrencyFormat value={payment?.invoice?.balance_due} TypographyProps={{ noWrap: true }} />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <PaymentMenu
              disableInvoicePreview={disableInvoicePreview}
              parentPage="widget"
              payment={payment}
              onClose={handleClose}
            />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
