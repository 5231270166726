import { Box, Typography } from '@material-ui/core';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { CaseCrossModal } from './CaseCrossModal';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 54;
export const columnsWidths = {
  name: 200,
  file_number: 150,
  action: 60
};

export const CaseCross = ({
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { openModal } = useModal();

  const openWidgetModal = () => {
    openModal(CaseCrossModal, {
      payload: {
        caseItem
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      disableContentPaddings
      title="Case cross-reference information"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          isEditable={!hasRole(rolesMap.patient, rolesMap.client)}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
          openWidgetModal={openWidgetModal}
        />
      ]}
      {...props}
    >
      <Box display="flex" flexDirection="column" height={height || '100%'}>
        {!caseItem?.sub_cases?.length &&
          <Box p={3}>
            <Typography align="center">No case file number found</Typography>
          </Box>
        }

        {!!caseItem?.sub_cases?.length &&
          <>
            <TableHeader/>

            <Box flexGrow={1} overflow="hidden">
              <Box height="100%" overflow="auto">
                {caseItem?.sub_cases?.map((item, i) => (
                  <Row key={i} subCase={item} caseItem={caseItem} />
                ))}
              </Box>
            </Box>
          </>
        }
      </Box>
    </ContentCard>
  );
};
