import { Autocomplete } from '../Autocomplete';

const unitDesignatorOptions = [
  'Apt',
  'Bldg',
  'Fl',
  'Ste',
  'Unit',
  'Rm',
  'Dept'
];

export const UnitDesignator = (props) => {
  return (
    <Autocomplete
      options={unitDesignatorOptions}

      {...props}
    />
  );
};
