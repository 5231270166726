import { Reminders } from './Reminders';
import { CaseRemindersSettings } from './CaseRemindersSettings';

export const routes = [
  {
    exact: true,
    path: '/case-reminders',
    component: Reminders
  },
  {
    path: '/case-reminders/settings',
    component: CaseRemindersSettings
  }
];
