import { SvgIcon } from '@material-ui/core';
import ClockInIcon from '../../../../../components/icons/workClockIcons/clock-in-icon.svg';
import ClockOutIcon from '../../../../../components/icons/workClockIcons/clock-out-icon.svg';
import BreakIcon from '../../../../../components/icons/workClockIcons/morning-break-icon.svg';
import LunchIcon from '../../../../../components/icons/workClockIcons/lunch-break-icon.svg';
import { workClockActionsMap } from '../WorkClock/workClockActionsMap';

export const workClockContentMap = {
  [workClockActionsMap.work]: {
    content: 'Do you want to clock in?',
    icon: (props) => (
      <SvgIcon {...props}>
        <ClockInIcon />
      </SvgIcon>
    )
  },
  [workClockActionsMap.clockOut]: {
    content: 'Do you want to clock out?',
    icon: (props) => (
      <SvgIcon {...props}>
        <ClockOutIcon />
      </SvgIcon>
    )
  },
  [workClockActionsMap.break]: {
    content: 'Start your break?',
    icon: (props) => (
      <SvgIcon {...props}>
        <BreakIcon />
      </SvgIcon>
    )
  },
  [workClockActionsMap.breakOut]: {
    content: 'Finish your break?',
    icon: (props) => (
      <SvgIcon {...props}>
        <BreakIcon />
      </SvgIcon>
    )
  },
  [workClockActionsMap.lunch]: {
    content: 'Start your lunch break?',
    icon: (props) => (
      <SvgIcon {...props}>
        <LunchIcon />
      </SvgIcon>
    )
  },
  [workClockActionsMap.lunchOut]: {
    content: 'Finish your lunch break?',
    icon: (props) => (
      <SvgIcon {...props}>
        <LunchIcon />
      </SvgIcon>
    )
  }
};
