import { useState } from 'react';
import { useSelector } from 'react-redux';
import { flow } from 'lodash';
import moment from 'moment';
import { MenuItem, ListItemText, List, useMediaQuery } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getClaimPDF } from '../../../../../../api/claims';
import { Fab } from '../../../../../../components/Fab';
import { Loader } from '../../../../../../components/Loader';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Popper } from '../../../../../../components/Popper';
import { EmailsSendModal } from '../../../../EmailsPage/EmailsClient/EmailsSendModal';

export const Email = ({ claim }) => {
  const { openModal } = useModal();
  const [ loading, setLoading ] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const currentUser = useSelector(({ profile }) => profile.user);

  const sendByEmail = (isWhitePaper = false) => () => {
    setLoading(true);

    getClaimPDF(claim.id, {
      params: { is_white_paper: +isWhitePaper }
    }).then((blob) => {
      const name = `${claim.claim_number}_${isWhitePaper ? 'W' : 'F'}_${moment().format('L LT')}.pdf`;
      const file = new File([ blob ], name.replaceAll(' ', '_'), { type: blob.type });

      file.aggregate_type = 'pdf';

      openModal(EmailsSendModal, {
        payload: {
          accounts: currentUser.email_accounts,
          file,
          message: {
            subject: 'Claim form',
            body: claim.claim_number
          }
        }
      });
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Loader surface loading={loading} render={
      () => (
        <Popper
          arrow={false}
          placement="bottom-end"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <Fab
              ref={anchorRef}
              variant="extended"
              color="primary"
              startIcon={!isMobile && <MailOutlineIcon />}
              endIcon={<KeyboardArrowDownIcon />}
              onClick={handleToggle}
            >
              {!isMobile ? 'Send by Email' : <MailOutlineIcon />}
            </Fab>
          )}
        >
          {({ handleClose }) => (
            <List>
              <MenuItem onClick={flow(sendByEmail(), handleClose)}>
                <ListItemText primary="On HCFA Form" />
              </MenuItem>

              <MenuItem onClick={flow(sendByEmail(true), handleClose)}>
                <ListItemText primary="On White Paper" />
              </MenuItem>
            </List>
          )}
        </Popper>
      )}
    />
  );
};
