import { useContext, useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { ScrollbarsLoader } from '../../../../../components/ScrollbarsLoader';
import { ThreadsContext } from '../../../../../components/chat';
import { ThreadsListLink } from './ThreadsListLink';

const useStyles = makeStyles({
  root: {
    width: '100% !important'
  }
});

export const ThreadsList = ({ onHideSideBar = () => {} }) => {
  const {
    threads,
    isFetching,
    isFetched,
    loadNextPage,
    resetThreads,
    filter: { total }
  } = useContext(ThreadsContext);
  const classes = useStyles();

  useEffect(() => {
    resetThreads();
  }, []);

  return (
    <ScrollbarsLoader
      className={classes.root}
      total={total}
      rowsCount={threads.length}
      isFetching={isFetching}
      isFetched={isFetched}
      onNeedNextPage={loadNextPage}
    >
      <Loader fullWidth p={2} loading={!isFetched} render={
        () => !total ? (
          <Box p={2}>
            <Typography align="center">No threads found</Typography>
          </Box>
        ) : threads.map((thread) => (
          <ThreadsListLink
            key={thread.id}
            thread={thread}
            isLoaded={isFetched}
            handleHideSideBar={onHideSideBar}
          />
        ))}
      />
    </ScrollbarsLoader>
  );
};
