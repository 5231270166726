import { Grid } from '@material-ui/core';
import { KeyboardDateTimePicker, TextField } from '../../../../../../../../components/FormField';

export const DepositionForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <TextField
          name="name"
          label="Name"
          placeholder="Enter the name..."
        />
      </Grid>

      <Grid item sm={6} />

      <Grid item sm={12}>
        <TextField
          required
          multiline
          disableUnderline
          rows={3}
          rowsMax={8}
          variant="filled"
          name="description"
          placeholder="Description"
        />
      </Grid>

      <Grid item sm={6}>
        <KeyboardDateTimePicker
          clearable
          name="date_received"
          label="Date Received"
          outputFormat="YYYY-MM-DD H:m:s"
        />
      </Grid>

      <Grid item sm={6}>
        <KeyboardDateTimePicker
          clearable
          name="date_responded"
          label="Date Responded"
          outputFormat="YYYY-MM-DD H:m:s"
        />
      </Grid>

      <Grid item sm={6}>
        <KeyboardDateTimePicker
          clearable
          name="deposition_date"
          label="Deposition Date"
          outputFormat="YYYY-MM-DD H:m:s"
        />
      </Grid>
    </Grid>
  );
};
