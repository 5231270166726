import { forwardRef, useRef, useState } from 'react';
import moment from 'moment';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { useFormikField } from '../useFormikField';
import { DatePickerBase } from './DatePickerBase';

export const DatePicker = forwardRef(({
  withoutFormik,
  name,
  minWidth = 0,
  outputFormat = 'X',
  onChange = () => {},

  ...props
}, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField(name);
  const { isFormikField, fieldProps: [ field, , formikHelpers = {} ] = [] } = formikFieldProps;
  const [ value, setValue ] = useState(field?.value ? moment(field?.value, outputFormat) : null);
  const formikValue = useRef(field?.value);

  const handleChange = (date) => {
    const formattedDate = (outputFormat === 'X' ? date?.startOf('day')?.unix() : date?.format(outputFormat)) || null;

    if (isFormikField) {
      formikValue.current = formattedDate;
      formikHelpers.setValue(formattedDate);
    }

    setValue(date);
    onChange(formattedDate, date);
  };

  return (
    <DatePickerBase
      autoOk
      withoutFormik={withoutFormik}
      formikValue={formikValue.current}
      Component={MuiDatePicker}
      name={name}
      outputFormat={outputFormat}
      format="MM/DD/YYYY"
      placeholder="MM/DD/YYYY"
      value={value}
      TextFieldComponentProps={{
        readOnly: true,
        minWidth,
        InputProps: {
          inputComponent: ({ value, inputRef, ...props }) => {
            return <div {...props}>{value}</div>;
          }
        }
      }}
      onChange={handleChange}

      {...props}

      ref={ref}
    />
  );
});
