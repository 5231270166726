import { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { LayoutContextProvider, LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);


const middleScreenWidth = 920;
const largeScreenWidth = 1120;

export const ReminderEvents = ({ isOpenSidebar, toggleSideBar = () => {} }) => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse(undefined, undefined, width < middleScreenWidth);

  return (
    <LayoutContextProvider>
      <LayoutContext.Consumer>
        {({ viewVariant }) => (
          <div ref={rootRef} className={classes.root}>
            <ActionsBar
              isOpenSidebar={isOpenSidebar}
              toggleSideBar={toggleSideBar}
              width={width}
              middleScreenWidth={middleScreenWidth}
              largeScreenWidth={largeScreenWidth}
              filterOpenButton={filterOpenButton}
            />

            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                <FiltersBar fullWidthFields={width < middleScreenWidth} />
              </FilterBarWrapper>
            </ScrollWrapper>

            <div className={classes.main}>
              {viewVariant === viewVariantsMap.list ? <List /> : <Grid />}
            </div>
          </div>
        )}
      </LayoutContext.Consumer>
    </LayoutContextProvider>
  );
};
