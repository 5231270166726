import { api } from '../api';

export const fetchDemoRequests = (config = {}) => {
  return api.get('/request-demo', config)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const requestDemo = (contact) => {
  return api.post('/request-demo', contact)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};
