import { Fragment, useState, useEffect } from 'react';
import { Box, Grid, Typography, Collapse, Link } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Divider } from '../../../../../../../components/Divider';
import { Date } from '../../../../../../../components/Date';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';

export const InsuranceClaimRowInfo = ({ claim }) => {
  const [ expandedContent, setExpandedContent ] = useState([]);

  const handleExpandClick = (index) => () => {
    setExpandedContent((expandedContent) =>
      expandedContent.map((item, i) => i === index ? !item : item)
    );
  };

  useEffect(() => {
    if (claim?.body_parts) {
      setExpandedContent(Array(claim?.body_parts?.length).fill(false));
    }
  }, []);

  return (
    <Grid container spacing={1} component={Box} py={2} px={1}>
      <Grid item xs={12}>
        <ReadonlyCheckbox checked={!!claim?.government_claim} title="Government claim" />
      </Grid>

      <Grid item xs={12}/>

      <Grid item xs={5}>
        <Typography noWrap>Claim filed date</Typography>
      </Grid>

      <Grid item xs={6}>
        <Date date={claim?.claim_filled_at} />
      </Grid>

      <Grid item xs={12}/>
      <Grid item xs={12}/>

      {claim?.adj_numbers?.map((adj, i) => (
        <Fragment key={i}>
          <Grid item xs={12}>
            <Box color="info.main">
              <Typography paragraph variant="h4" color="inherit">
                ADJ number
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Typography variant="h5">{i + 1}.</Typography>
          </Grid>

          <Grid item xs={5}>
            <Date noWrap date={adj.date} variant="h5" />
          </Grid>

          <Grid item xs={6}>
            <Typography noWrap variant="h5">
              ADJ {adj?.number || '-'}
            </Typography>
          </Grid>
        </Fragment>
      ))}

      <Grid item xs={12} />

      <Grid item xs={12}>
        <Box color="info.main">
          <Typography paragraph variant="h4" color="inherit">DOI</Typography>
        </Box>
      </Grid>

      {!claim?.doi?.length ? (
        <Grid item xs={12}>
          <Typography align="center" color="textSecondary">No DOI found</Typography>
        </Grid>
      ) : (claim?.doi?.map((doi, i) => (
        <Fragment key={i}>
          <Grid item xs={3}>
            <Typography noWrap color="textSecondary">
              {!!doi.from ? 'Dates' : 'Date'}
            </Typography>
          </Grid>

          <Grid item xs={9}>
            <Box display="flex" alignItems="center">
              {!!doi.from ? (
                <>
                  <Date noWrap date={doi.from} variant="h5" />
                  &nbsp; - &nbsp;
                  <Date noWrap date={doi.to} variant="h5" />
                </>
              ) : (
                <Date noWrap date={doi.date} variant="h5" />
              )}
            </Box>
          </Grid>

          <Grid item xs={3} />

          <Grid item xs={9}>
            <Box display="flex" alignItems="center" fontWeight={500} mb={0.5}>
              <NotesIcon fontSize="small"/>
              <Typography variant="subtitle2">Note</Typography>
            </Box>

            <Typography color="textSecondary">{doi.note || 'No info'}</Typography>
          </Grid>

          {i !== (claim?.doi?.length - 1) &&
            <Grid item xs={12}>
              <Divider type="dashed" gutter={1} />
            </Grid>
          }
        </Fragment>
      )))}

      <Grid item xs={12} />

      <Grid item xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">Injured body parts</Typography>
        </Box>
      </Grid>

      {!claim?.body_parts?.length ? (
        <Grid item xs={12}>
          <Typography align="center" color="textSecondary">Not found</Typography>
        </Grid>
      ) : (claim?.body_parts?.map((body_part, i) => (
        <Fragment key={i}>
          <Grid item xs={1}>
            <Typography variant="h5">{i + 1}.</Typography>
          </Grid>

          <Grid item xs={2}>
            <Box color="info.main">
              <Typography variant="h5" color="inherit" align="right">{body_part.code}</Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h5">{body_part.description}</Typography>
          </Grid>

          <Grid item xs={3} />

          <Grid item xs={9}>
            <Box display="flex" alignItems="center" fontWeight={500} mb={0.5}>
              <NotesIcon fontSize="small"/>
              <Typography variant="subtitle2">Note</Typography>
            </Box>

            {body_part?.note &&
              <Collapse in={expandedContent[i]}>
                <Typography color="textSecondary">{body_part.note || 'No info'}</Typography>
              </Collapse>
            }

            {body_part?.note &&
              <Box my={1}>
                <Link
                  color="primary"
                  variant="body2"
                  onClick={handleExpandClick(i)}
                >
                  {!expandedContent[i] ? 'Show note' : 'Hide note'}
                </Link>
              </Box>
            }
          </Grid>
        </Fragment>
      )))}
    </Grid>
  );
};
