import { orderByOptions } from '../../../../BillingPage/Invoices/InvoicesContext';

export const transformRelationsForFilterToOptions = (relationsForFilter) => {
  const orderByValue = relationsForFilter?.order_by?.value ?? relationsForFilter?.order_by;

  return {
    ...relationsForFilter,

    order_by: orderByOptions.find(({ value }) => value === orderByValue)
  };
};
