import moment from 'moment';
import { Yup } from '../../../utils/validation';
import { measurementsValidationSchema } from './Measurements';

export const formValidationSchema = Yup.object().shape({
  accident_type: Yup.string().nullable(),
  auto_accident_state: Yup.string().nullable(),
  auto_accident_country: Yup.string().nullable(),
  treating_physician_id: Yup.mixed().nullable(),
  referred_id: Yup.mixed().nullable(),
  emergency_contact_id: Yup.mixed().nullable(),
  primary_insurance_id: Yup.mixed().nullable().when([ 'isConfirmed', 'is_patient' ], {
    is: (isConfirmed, is_patient) => !isConfirmed && !is_patient,
    then: Yup.mixed().nullable().required()
  }),
  secondary_insurance_id: Yup.mixed().nullable(),
  description: Yup.string().nullable().max(1000),
  ssn: Yup.string().nullable().max(255),
  primary_insuranceId: Yup.string().nullable().max(255),
  groupID: Yup.string().nullable().max(255),
  primary_insurance_holder: Yup.string().nullable().max(255),
  relationship_to_patient: Yup.string().nullable().max(255),
  secondary_insuranceId: Yup.string().nullable().max(255),
  relationship_dob: Yup.mixed(),
  measurements: measurementsValidationSchema.nullable(),
  birthday: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today').required(),
  hearing_date:  Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today'),
  vision_date:  Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today'),
  home: Yup.object().shape({
    street: Yup.string().nullable().required().min(2).max(255),
    country: Yup.string().nullable().required().min(2).max(255),
    zip: Yup.string().nullable().required().min(1).max(255),
    city: Yup.string().nullable().required().min(2).max(255),
    state: Yup.string().nullable().required().min(2).max(255),
    designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
      return unitNumber ? schema.required() : schema;
    }),
    unit_number: Yup.string().nullable().min(1).max(255)
  }),
  emergency_contact: Yup.array().of(Yup.object().shape({
    emergency_name: Yup.string().nullable().when('date', {
      is: (value) => !!value,
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable()
    }),
    phone:  Yup.string().nullable().required(),
    relationship:  Yup.string().nullable(),
    dob: Yup.date().format('YYYY-MM-DD').nullable()
      .max(moment().add(1, 'day'), 'Date must be earlier than today')
  })),
  is_range: Yup.boolean().nullable(),
  last_work_date: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today'),
  doi: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today').when('is_range', {
      is: (value) => !!value,
      then: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today'),
      otherwise: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today').required()
    }),
  doi_from: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today').when('is_range', {
      is: (value) => !!value,
      then: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today').required()
    }),
  doi_to: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today').when('is_range', {
      is: (value) => !!value,
      then: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today').required()
    })
});
