import { useContext } from 'react';
import {
  AuthorizationsPage as AuthorizationsPageCommon
} from '../../../../../components/appointments/AuthorizationsPage';
import { UserContext } from '../../../Members/User/UserProvider';

export const AuthorizationsPage = () => {
  const { user } = useContext(UserContext);

  return (
    <AuthorizationsPageCommon userID={user.id} />
  );
};
