import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { useResizeObserver } from '../../../../helpers/hooks/useResizeObserver';
import { getUserFullName } from '../../../../helpers/users';
import { usePrevious } from '../../../../helpers/hooks';
import { hasRole } from '../../../../utils/hasRole';
import { ImageDropZone } from '../../../ImageDropZone';
import {
  TextField,
  CompaniesAutocomplete,
  OfficesLocationSelect
} from '../../../FormField';
import { widthBreakpointSmall } from '../EditUserPage';
import { ProviderIDTypes } from '../ProviderIDTypes';
import { Venue } from '../Venue';
import { WorkInfo } from '../WorkInfo';

export const CompanyInfo = ({ user }) => {
  const { values, setFieldValue } = useFormikContext();
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const front = usePrevious(values?.front);
  const back = usePrevious(values?.back);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const sipNumber = user?.sipUser?.numbers?.join(', ');

  const handleCompanyChange = (company) => {
    if (values?.work?.office_id) {
      setFieldValue('work.office_id', null);
    }

    setFieldValue('work.contact_user', company?.contact_user
      ? getUserFullName(company?.contact_user)
      : null
    );
  };

  useEffect(() => {
    if (values?.front && front !== values?.front) {
      setFieldValue('driver_license.front.url', null);
    }
  }, [ values?.front ]);

  useEffect(() => {
    if (values?.back && back !== values?.back) {
      setFieldValue('driver_license.back.url', null);
    }
  }, [ values?.back ]);

  return (
    <Box
      ref={rootRef}
      width={isMobile ? '100%' : '50%'}
      py={isMobile ? 2 : 3}
      pl={isMobile ? 2 : 3}
      pr={isMobile ? 2 : 4}
    >
      <WorkInfo />

      <Grid container spacing={3} component={Box} pt={2} alignItems="flex-end">
        <Grid item xs={12}>
          <Typography variant="h6">Company Info</Typography>
        </Grid>

        <Grid item xs={12} lg={6}>
          <CompaniesAutocomplete
            name="work.company_id"
            label="Company"
            placeholder="Select company..."
            onChange={handleCompanyChange}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextField
            disabled={hasRole(rolesMap.patient)}
            name="work.taxonomy"
            label="Taxonomy"
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextField
            isEditable={false}
            name="work.contact_user"
            label="Contact Person"
          />
        </Grid>

        {sipNumber ? (
          <Grid item xs={12} lg={6}>
            {sipNumber && (
              <Box display="flex" alignItems="center">
                <Typography variant="h5" color="textSecondary">Extension:</Typography>
                &nbsp;&nbsp;
                <Typography variant="h5">
                  {sipNumber}
                </Typography>
              </Box>
            )}
          </Grid>
        ) : width > widthBreakpointSmall && <Grid item lg={6} />}

        <Grid item xs={12}>
          <Typography variant="h6">Offices</Typography>
        </Grid>

        <Grid item xs={12}>
          <OfficesLocationSelect
            multiple
            disabled={!values?.work?.company_id}
            name="work.office_id"
            label="Office"
            placeholder="Select office..."
            params={{ company_id: values?.work?.company_id }}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          {values.role === rolesMap.medic &&
            <Grid item xs={6}>
              <TextField
                name="work.location"
                label="Location#"
              />
            </Grid>
          }
        </Grid>

        <Grid item xs={12}>
          <Box clone pt={3} pb={2}>
            <Typography variant="h3">Scan Driver License</Typography>
          </Box>
        </Grid>

        <Grid item lg={4} md={10} sm={6} xs={12}>
          <ImageDropZone
            label="Front"
            name="front"
            preview={values?.front}
          />
        </Grid>

        <Grid item lg={4} md={10} sm={6} xs={12}>
          <ImageDropZone
            label="Back"
            name="back"
            preview={values?.back}
          />
        </Grid>
      </Grid>

      {values.role === rolesMap.doctor && <ProviderIDTypes />}
      {values.role === rolesMap.advocate && <Venue />}
    </Box>
  );
};
