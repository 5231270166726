import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { FiltersBar } from '../../FiltersBar';
import { UserRegistrationContext } from '../UserRegistrationContext';
import { Chart } from './Chart';
import { MainFilter } from './MainFilter';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = () => {
  const classes = useStyles();
  const { isFetched, filter, filterAnalytics } = useContext(UserRegistrationContext);
  const [ isOpenDayPicker, setIsOpenDayPicker ] = useState(false);
  const [ isOpenWeekPicker, setIsOpenWeekPicker ] = useState(false);
  const [ isOpenMonthPicker, setIsOpenMonthPicker ] = useState(false);
  const [ isOpenYearPicker, setIsOpenYearPicker ] = useState(false);
  const [ message, setMessage ] = useState(moment.unix(filter.from).format('MM/YYYY'));

  useEffect(() => {
    filterAnalytics();
  }, []);

  return (
    <div className={classes.root}>
      <MainFilter filter={filter} setFilter={filterAnalytics} />

      <FiltersBar
        message={message}
        isOpenDayPicker={isOpenDayPicker}
        isOpenWeekPicker={isOpenWeekPicker}
        isOpenMonthPicker={isOpenMonthPicker}
        isOpenYearPicker={isOpenYearPicker}
        filter={filter}
        onChange={filterAnalytics}
        setIsOpenYearPicker={setIsOpenYearPicker}
        setIsOpenMonthPicker={setIsOpenMonthPicker}
        setIsOpenWeekPicker={setIsOpenWeekPicker}
        setIsOpenDayPicker={setIsOpenDayPicker}
        setMessage={setMessage}
      />

      <Loader loading={!isFetched} className={classes.loader} render={
        () => (
          <div className={classes.mainContent}>
            <Chart/>
          </div>
        )}
      />
    </div>
  );
};
