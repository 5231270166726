import { useContext, useState } from 'react';
import { makeStyles, Button, Collapse, useTheme, useMediaQuery } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import { getUserFullName } from '../../../helpers/users';
import { useModal } from '../../ModalsProvider';
import { CommentsContext } from '../CommentsContext';
import { ReplyModal } from '../ReplyModal';
import { FormBody } from './FormBody';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ReplyForm = ({
  comment,
  disableForm,
  recipientCommentID,
  parentCommentID
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));
  const { createComment } = useContext(CommentsContext);
  const [ formIsOpen, setFormIsOpen ] = useState(false);
  const recipientName = getUserFullName(comment.user);

  const handleCancel = (callback = () => {}) => {
    setFormIsOpen(false);
    callback();
  };

  const replayComment = ({ text, ...values }, { setSubmitting, resetForm, setErrors }) => {
    values.text = `${recipientName} ${text}`;

    return createComment(values).then(() => {
      resetForm();
      setFormIsOpen(false);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        setSubmitting(false);
        errors && setErrors(errors);
      }
    });
  };

  const toggleFormIsOpen = () => {
    if (disableForm || isMobile) {
      openModal(ReplyModal, {
        payload: {
          comment,
          parentCommentID,
          recipientCommentID,
          onCancel: handleCancel,
          replayComment
        }
      });
    } else {
      setFormIsOpen(!formIsOpen);
    }
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        className={classes.replyButton}
        startIcon={<ReplyIcon />}
        onClick={toggleFormIsOpen}
      >
        Reply
      </Button>

      <Collapse in={formIsOpen} className={classes.root}>
        <FormBody
          comment={comment}
          parentCommentID={parentCommentID}
          recipientCommentID={recipientCommentID}
          onCancel={handleCancel}
          replayComment={replayComment}
        />
      </Collapse>
    </>
  );
};
