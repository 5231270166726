import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_TRACKS_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_TRACKS_SUCCESS]: (
    { filter, tracks, ...state },
    { data: newTrack, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      tracks: page > 1 ? (
        tracks.filter(({ id }) => !newTrack.find((loadedTrack) => id === loadedTrack.id)).concat(newTrack)
      ) : (
        newTrack
      )
    };
  },

  [types.FETCH_TRACKS_ERROR]: (state) => {
    return {
      ...state,
      isFetching: false,
      isFetched: true
    };
  },

  [types.ADD_TRACK]: (state, payload) => {
    return {
      ...state,

      tracks: [ payload, ...state.tracks ]
    };
  },

  [types.UPDATE_TRACK]: ({ tracks, ...state }, payload) => {
    return {
      ...state,

      tracks: tracks.map((track) => payload.id === track.id ? payload : track)
    };
  },

  [types.RESET_TRACKING]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.DELETE_TRACK]: ({ tracks, ...state }, trackID) => {
    return {
      ...state,

      tracks: tracks.filter((track) => track.id !== trackID)
    };
  }
});
