export const styles = ({
  spacing,
  typography: { fontWeightMedium }
}) => ({
  root: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },

  title: {
    fontWeight: fontWeightMedium
  }
});
