export const styles = ({ spacing, palette }) => ({
  icon: {
    marginRight: spacing(),
    marginTop: ({ position }) => position === 'flex-start' && spacing(0.25)
  },

  icon_checked: {
    color: ({ iconColor }) => iconColor ? palette[iconColor].main : palette.lightgreen
  },

  icon_unchecked: {
    color: ({ iconColor }) => iconColor ? palette[iconColor].main : palette.grey[400]
  },

  title: {
    color: ({ color }) => color ? palette[color].main : 'inherit'
  }
});
