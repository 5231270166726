import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { Date } from '../../../../../../../../components/Date';
import { columnsMap } from '../SavedFiltersList';

export const CardContent = ({ params, hiddenFields = [] }) => {
  return (
    <Grid container spacing={1}>
      {params?.started_after &&
        <>
          <Grid item xs={4}>
            <Typography>
              Started After:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Date disableIcon date={params.started_after} format="L LT" />
          </Grid>
        </>
      }

      {params?.started_before &&
        <>
          <Grid item xs={4}>
            <Typography>
              Started Before:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Date disableIcon date={params.started_before} format="L LT" />
          </Grid>
        </>
      }

      {!!params?.cases?.length && !hiddenFields.includes(columnsMap.cases) &&
        <>
          <Grid item xs={4}>
            <Typography>
              Cases:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.cases?.map((item, index) => (
                <Typography noWrap key={index} title={item.name}>
                  {item.name + ' '}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.tasks?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Tasks:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.tasks?.map((item, index) => (
                <Typography noWrap key={index} title={item.name}>
                  {item.name + ' '}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.schedule_events?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Schedule Events:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.schedule_events?.map((event) => (
                <Typography
                  noWrap
                  key={event.id}
                  title={`${event?.title} at ${moment.unix(event?.started_at).format('L LT')}`}
                >
                  {event?.title} at {moment.unix(event?.started_at).format('L LT')}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }
    </Grid>
  );
};
