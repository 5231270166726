import { InputAdornment } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import { NumberMaskField } from '../NumberMaskField';

export const PhoneField = ({ endAdornment = false, iconAdornment, ...props }) => {
  return (
    <NumberMaskField
      isNumericString
      format="(###) ###-####"
      placeholder="(123) 123-1234"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PhoneIcon fontSize="small" />
          </InputAdornment>
        ),

        endAdornment: endAdornment && iconAdornment && (
          <InputAdornment position="end">
            {iconAdornment}
          </InputAdornment>
        )
      }}

      {...props}
    />
  );
};
