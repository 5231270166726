export const factorsTypes = {
  worse: 'worse',
  better: 'better',
  no_effect: 'no_effect'
};

export const factorsMap = {
  [factorsTypes.worse]: 'Worse',
  [factorsTypes.better]: 'Better',
  [factorsTypes.no_effect]: 'No effect'
};

const factorsTitlesTypes = {
  cold: 'cold',
  heat: 'heat',
  alcohol: 'alcohol',
  climate: 'climate',
  fatigue: 'fatigue',
  sitting: 'sitting',
  walking: 'walking',
  coughing: 'coughing',
  lying_down: 'lying_down'
};

export const factorsTitlesMap = {
  [factorsTitlesTypes.cold]: 'Cold',
  [factorsTitlesTypes.heat]: 'Heat',
  [factorsTitlesTypes.alcohol]: 'Alcohol',
  [factorsTitlesTypes.climate]: 'Climate',
  [factorsTitlesTypes.fatigue]: 'Fatigue',
  [factorsTitlesTypes.sitting]: 'Sitting',
  [factorsTitlesTypes.walking]: 'Walking',
  [factorsTitlesTypes.coughing]: 'Coughing',
  [factorsTitlesTypes.lying_down]: 'Lying down'
};
