import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Button, Grid, Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as casesApi from '../../../../../api/cases';
import { Yup } from '../../../../../utils/validation';
import { setCasesLastGlobalAction } from '../../../../../store/globalActions';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { useModal } from '../../../../../components/ModalsProvider';
import { Loader } from '../../../../../components/Loader';
import { Fab } from '../../../../../components/Fab';
import { transformEamsNumberToOption } from '../CasesContext/selectFieldHelpers';
import * as types from '../CasesContext/types';
import {
  Header,
  Body,
  Footer,
  Applicant,
  InsuranceClaim,
  validationSchema as caseValidationSchema
} from '../CreateCasePage';
import { EAMSIntegrationModal } from '../CreateCasePage/EAMSIntegrationModal';
import { IncidentInformation } from '../CreateCasePage/IncidentInformation';
import { transformCaseReq } from '../CreateCasePage/transformCaseReq';
import { validationSchema as insClaimValidationSchema } from '../CreateCasePage/insClaimValidationSchema';
import { generateInitialValues } from './generateInitialValues';
import { InsuranceClaimList } from './InsuranceClaimList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const validationSchema = caseValidationSchema.shape({
  ins_claims: Yup.array().of(insClaimValidationSchema)
});

export const EditCase = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { goBack } = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [ initialValues, setInitialValues ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const [ valueFromAdj, setValueFromAdj ] = useState({});
  const heading_attorney = initialValues.case_users?.find(({ case_role }) => {
    return case_role === 'guardian';
  });
  const applicant = initialValues.case_users?.find(({ case_role }) => {
    return case_role === 'applicant';
  });

  useEffect(() => {
    setIsFetched(false);

    casesApi.fetchCase(params.caseID).then((data) => {
      data.paperless_file = +data.paperless_file;
      data.eams_number = Array.isArray(data.eams_number) ? '' : transformEamsNumberToOption(data.eams_number);
      data.case_status_id = data.case_status;

      setValueFromAdj(data.eams_number);
      setInitialValues(data);
      setIsFetched(true);
    });
  }, []);

  const openEAMSIntegrationModal = () => {
    openModal(EAMSIntegrationModal);
  };

  const updateCase = (caseData, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.updateCase(transformCaseReq({ id: initialValues.id, ...caseData }, valueFromAdj)).then((data) => {
      dispatch(setCasesLastGlobalAction({ type: types.UPDATE_CASE_IN_LIST, payload: data }));
      enqueueSnackbar('Case successfully updated', { variant: 'success' });
      goBack();
    }).catch(({ data: { errors } }) => {
      if (errors) {
        setErrors(errors);
        enqueueSnackbar('Case not updated', { variant: 'error' });
      }
    });
  };

  return (
    <Loader loading={!isFetched} className={classes.loader} render={
      () => (
        <Formik
          initialValues={generateInitialValues({ initialValues, heading_attorney, applicant })}
          validationSchema={validationSchema}
          onSubmit={updateCase}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form noValidate className={classes.root} onSubmit={handleSubmit}>
              <Page>
                <PageHeader>
                  <PageHeaderTitle>Edit Case</PageHeaderTitle>

                  <PageHeaderActions>
                    <Button onClick={goBack} color="primary">Back</Button>

                    <Loader surface loading={isSubmitting} render={
                      () => (
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      )}
                    />
                  </PageHeaderActions>
                </PageHeader>

                <PageBody className={classes.mainContent}>
                  <Alert icon={false} severity="info">
                    <Box display="flex" flexDirection={isTablet ? 'column' : 'row'} alignItems="center" wrap="wrap">
                      <Box mr={isTablet ? 0 : 2} mb={isTablet ? 1 : 0}>
                        <Typography variant="h5">
                          EAMS Integration:
                        </Typography>
                      </Box>

                      <Box mr={isTablet ? 0 : 2} mb={isTablet ? 1 : 0}>
                        <Typography>
                          {/* eslint-disable-next-line max-len */}
                          This search feature provides access to information that helps move cases through the workers' compensation court system efficiently.
                        </Typography>
                      </Box>

                      <Fab
                        variant="extended"
                        color="secondary"
                        onClick={openEAMSIntegrationModal}
                      >
                        Go
                      </Fab>
                    </Box>
                  </Alert>

                  <Grid container spacing={isTablet ? 1 : 4}>
                    <Grid item xs={isTablet ? 12 : 7}>
                      <div className={classes.formContent}>
                        <Header />
                        <Body />
                        <Footer />
                        <IncidentInformation />
                        <Applicant applicant={applicant} />
                      </div>
                    </Grid>

                    <Grid item xs={isTablet ? 12 : 5}>
                      <InsuranceClaim
                        setValueFromAdj={setValueFromAdj}
                        defaultText="ADJ"
                      />

                      <InsuranceClaimList />
                    </Grid>
                  </Grid>
                </PageBody>
              </Page>
            </form>
          )}
        </Formik>
      )}
    />
  );
};
