import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { Grid, Typography, Box } from '@material-ui/core';
import { usersRolesOptions } from '../../../../dataMaps';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { getUserOldName } from '../../../../helpers/users/getUserOldName';
import { hasRole } from '../../../../utils/hasRole';
import {
  TextField,
  Select,
  KeyboardDatePicker,
  SsnField,
  EmailsSelect
} from '../../../FormField';
import { AvatarContent } from '../../../../app/Dashboard/Members/CreateCustomerModal';
import { gendersMap, statusMap } from '../../gendersMap';

export const UserInfo = ({ onAvatarUrlChange }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('old_name', getUserOldName(values) || null);
  }, []);

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} sm={5} md={12} lg={4} xl={3}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <AvatarContent size={160} onAvatarUrlChange={onAvatarUrlChange} />
        </Box>
      </Grid>

      <Grid
        container
        spacing={3}
        item
        xs={12}
        sm={7}
        md={12}
        lg={8}
        xl={9}
        component={Box}
        m="0 !important"
        p="0 !important"
      >
        <Grid item sm={6} xs={12}>
          <TextField
            required
            name="first_name"
            label="First name"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            required
            name="last_name"
            label="Last name"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            isEditable={false}
            name="old_name"
            label="Previous Name"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Select
            formattedValue
            name="gender"
            label="Gender"
            TextFieldProps={{ required: true }}
            options={gendersMap}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <KeyboardDatePicker
            outputFormat={moment.HTML5_FMT.DATE}
            name="birthday"
            label="DOB"
            maxDate={moment().subtract(1, 'day').unix()}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <SsnField
            name="ssn"
            label="SSN"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField name="dl" label="DL" />
        </Grid>

        <Grid item sm={6} xs={12}>
          <KeyboardDatePicker
            clearable
            name="exp"
            label="EXP"
            outputFormat="MM/DD/YYYY"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box pt={3}>
          <Typography variant="h6">Select User Role</Typography>
        </Box>
      </Grid>

      <Grid item sm={7} xs={12}>
        <Select
          formattedValue
          isDisabled={
            hasRole(
              rolesMap.client,
              rolesMap.patient,
              rolesMap.doctor,
              rolesMap.medic,
              rolesMap.supervisor,
              rolesMap.secretary,
              rolesMap.advocate
            )
          }
          name="role"
          label="Select user role"
          TextFieldProps={{ required: true }}
          options={usersRolesOptions}
        />
      </Grid>

      <Grid item sm={5} xs={12}>
        <Select
          formattedValue
          name="is_active"
          label="Status"
          TextFieldProps={{ required: true }}
          isDisabled={hasRole(rolesMap.client, rolesMap.patient)}
          options={statusMap}
        />
      </Grid>

      <Grid item sm={7} xs={12}>
        <EmailsSelect
          required
          name="email"
          label="Profile email"
        />
      </Grid>

      <Grid item sm={5} xs={12}>
        <TextField
          disabled
          name="old_email"
          label="Previous email"
        />
      </Grid>
    </Grid>
  );
};
