import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    current_symptoms_pi: Yup.object().shape({
      describe_circumstances_pain: Yup.string().nullable().max(1000),
      how_often_your_pain: Yup.string().nullable(),
      where_is_pain: Yup.mixed().required(),
      how_long_until_the_pain_interferes: Yup.object().shape({
        sit: Yup.number().nullable(),
        stand: Yup.number().nullable(),
        walk: Yup.number().nullable()
      }),
      have_you_avoided_strenuous_lifting: Yup.boolean(),
      following_factors_your_pain: Yup.object().shape({
        heat: Yup.string().nullable(),
        cold: Yup.string().nullable(),
        lying_down: Yup.string().nullable(),
        sitting: Yup.string().nullable(),
        walking: Yup.string().nullable(),
        climate: Yup.string().nullable(),
        fatigue: Yup.string().nullable(),
        coughing: Yup.string().nullable(),
        alcohol: Yup.string().nullable()
      }),
      constitutional: Yup.object().shape({
        fever: Yup.boolean().nullable(),
        weight_loss: Yup.boolean().nullable(),
        fatigue: Yup.boolean().nullable()
      }),
      respiratory: Yup.object().shape({
        indigestion: Yup.boolean().nullable(),
        abdominal_pain: Yup.boolean().nullable(),
        nausea: Yup.boolean().nullable(),
        vomiting: Yup.boolean().nullable(),
        regurgitation: Yup.boolean().nullable(),
        diarrhea: Yup.boolean().nullable(),
        bloody_stools: Yup.boolean().nullable()
      }),
      eye_problems: Yup.object().shape({
        blurred_vision: Yup.boolean().nullable(),
        double_vision: Yup.boolean().nullable(),
        loss_of_vision: Yup.boolean().nullable(),
        eye_pain: Yup.boolean().nullable(),
        eye_redness: Yup.boolean().nullable(),
        eye_dryness: Yup.boolean().nullable()
      }),
      musculoskeletal: Yup.object().shape({
        muscle_pain: Yup.boolean().nullable(),
        muscle_cramp: Yup.boolean().nullable(),
        loss_of_vision: Yup.boolean().nullable(),
        muscle_twitches: Yup.boolean().nullable(),
        loss_of_muscle: Yup.boolean().nullable(),
        neck_pain: Yup.boolean().nullable(),
        back_pain: Yup.boolean().nullable(),
        joint_swelling: Yup.boolean().nullable()
      }),
      ear_nose_throat: Yup.object().shape({
        trouble_hearing: Yup.boolean().nullable(),
        ringing_in_ears: Yup.boolean().nullable(),
        dizziness: Yup.boolean().nullable(),
        loss_of_balance: Yup.boolean().nullable(),
        ear_pain: Yup.boolean().nullable(),
        ear_discharge: Yup.boolean().nullable()
      }),
      cardiovascular: Yup.object().shape({
        chest_pain: Yup.boolean().nullable(),
        irregular_heart_beat: Yup.boolean().nullable(),
        high_blood_pressure: Yup.boolean().nullable(),
        limb_pain_walking: Yup.boolean().nullable(),
        fainting: Yup.boolean().nullable()
      })
    })
  })
});
