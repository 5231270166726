export const initialValues = {
  court_id: null,
  address: null,
  phone: null,
  case_number: null,
  contact_id: null,
  contact_name: null,
  dep: null,
  jury_trial: null,
  trial_date: null,
  fsc: null,
  cmc: null,
  cmc_future_use: null,
  osc: null,
  mcs: null,
  mcs_future_use: null,
  mediation: null,
  arb: null,
  arb_future_use: null,
  complaint_filled: null,
  def: null,
  received: null,
  plf_sent: null
};
