import { useState } from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { useFormikContext, FieldArray } from 'formik';
import { CurrencyField, KeyboardDatePicker, TextField } from '../../../../../../../../components/FormField';
import { CaseMembersSelect } from '../../../../../../../../components/users/CaseMembersSelect';
import { Divider } from '../../../../../../../../components/Divider';
import { TotalFundsReceived } from '../TotalFundsReceived';

export const NegotiationForm = ({ onCaseItemUpdate }) => {
  const { values, setFieldValue } = useFormikContext();
  const [ members, setMembers ] = useState(values?.caseItem?.case_users);

  const handleAddMembers = (values) => {
    onCaseItemUpdate(values);
    setMembers(values?.case_users);
  };

  const handleChangeAtty = (name) => (event) => {
    setFieldValue(name, false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <CaseMembersSelect
            required
            isCreatable
            name="user_id"
            label="P1"
            margin="dense"
            creatablePayload={{
              caseItem: values.caseItem,
              onCaseItemUpdate: handleAddMembers
            }}
            options={members}
          />
        </Grid>

        <Hidden only={[ 'sm', 'xs' ]}>
          <Grid item sm={6} />
        </Hidden>

        <Grid item sm={4}>
          <Typography component={Box} pt={1} variant="h4">Demand</Typography>
        </Grid>

        <Grid item sm={8}>
          <Divider
            disableBottomGutter
            gutter={2}
            type="dotted"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <KeyboardDatePicker
            clearable
            name="demand_sent_date"
            label="Sent"
            outputFormat="YYYY-MM-DD"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <KeyboardDatePicker
            clearable
            name="demand_due_date"
            label="Due"
            outputFormat="YYYY-MM-DD"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <CurrencyField
            name="demand_amount"
            label="DM Amt"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <KeyboardDatePicker
            clearable
            name="demand_um_date"
            label="UM"
            outputFormat="YYYY-MM-DD"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <KeyboardDatePicker
            clearable
            name="demand_um_due_date"
            label="Due"
            outputFormat="YYYY-MM-DD"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <CurrencyField
            name="demand_um_amount"
            label="DM Amt"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4}>
          <Typography component={Box} pt={1} variant="h4">Counter/Offers</Typography>
        </Grid>

        <Grid item sm={8}>
          <Divider
            disableBottomGutter
            gutter={2}
            type="dotted"
          />
        </Grid>

        <Hidden only={[ 'sm', 'xs' ]}>
          <Grid item sm={4} xs={12} />
        </Hidden>

        <Grid item sm={4} xs={12}>
          <KeyboardDatePicker
            clearable
            name="counter_date"
            label="Date"
            outputFormat="YYYY-MM-DD"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <CurrencyField
            name="counter_amount"
            label="Offer"
            margin="dense"
          />
        </Grid>

        <Hidden only={[ 'sm', 'xs' ]}>
          <Grid item sm={4} xs={12} />
        </Hidden>

        <Grid item sm={4} xs={12}>
          <KeyboardDatePicker
            clearable
            name="offer_date"
            label="Date"
            outputFormat="YYYY-MM-DD"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <CurrencyField
            name="offer_amount"
            label="Counter"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4}>
          <Typography component={Box} pt={1} variant="h4">Settled</Typography>
        </Grid>

        <Grid item sm={8}>
          <Divider
            disableBottomGutter
            gutter={2}
            type="dotted"
          />
        </Grid>

        <Hidden only={[ 'sm', 'xs' ]}>
          <Grid item sm={4} xs={12} />
        </Hidden>

        <Grid item sm={4} xs={12}>
          <KeyboardDatePicker
            clearable
            name="settled_date"
            label="Date"
            outputFormat="YYYY-MM-DD"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <CurrencyField
            name="settled_third_party_amount"
            label="3rdP"
            margin="dense"
          />
        </Grid>

        <Hidden only={[ 'sm', 'xs' ]}>
          <Grid item sm={4} xs={12} />
        </Hidden>

        <Grid item sm={4} xs={12}>
          <KeyboardDatePicker
            clearable
            name="settled_um_date"
            label="UM"
            outputFormat="YYYY-MM-DD"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <CurrencyField
            name="settled_um_amount"
            label="UM"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4}>
          <Typography component={Box} pt={1} variant="h4">Funds Received</Typography>
        </Grid>

        <Grid item sm={8}>
          <Divider
            disableBottomGutter
            gutter={2}
            type="dotted"
          />
        </Grid>
      </Grid>

      <FieldArray
        name="received_founds"
        render={() => (
          values?.received_founds?.map((item, index) => (
            <Grid key={index} container spacing={2} component={Box} pt={1} pb={3}>
              <Grid item sm={4} xs={12}>
                <Box pt={2}>
                  {index <= 2 ? (
                    <Typography>
                      {values?.received_founds?.[index].name}
                    </Typography>
                  ) : (
                    <TextField name={`received_founds.${index}.name`} />
                  )}
                </Box>
              </Grid>

              <Grid item sm={4} xs={12}>
                <KeyboardDatePicker
                  clearable
                  name={`received_founds.${index}.date`}
                  label="Date"
                  outputFormat="YYYY-MM-DD"
                />
              </Grid>

              <Grid item sm={4} xs={12}>
                <CurrencyField
                  name={`received_founds.${index}.amount`}
                  label="Total"
                />
              </Grid>
            </Grid>
          ))
        )}
      />

      <Grid container spacing={2} alignItems="center">
        <TotalFundsReceived
          isEditable
          totalInfo={values}
          onChange={handleChangeAtty}
        />
      </Grid>
    </>
  );
};
