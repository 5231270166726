import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { SavedFiltersContext } from '../../../../../../components/saved-filters';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Scrollbars } from '../../../../../../components/Scrollbars';
import { Loader } from '../../../../../../components/Loader';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsNamesMap = {
  claimNo: 'claimNo',
  providers: 'providers',
  patients: 'patients',
  dosFrom: 'dosFrom',
  dosTo: 'dosTo',
  offices: 'offices',
  claimStatus: 'claimStatus',
  orderBy: 'orderBy',
  actions: 'actions'
};

export const columnsWidths = {
  [columnsNamesMap.claimNo]: 200,
  [columnsNamesMap.patients]: 200,
  [columnsNamesMap.providers]: 200,
  [columnsNamesMap.dosFrom]: 100,
  [columnsNamesMap.dosTo]: 100,
  [columnsNamesMap.offices]: 150,
  [columnsNamesMap.claimStatus]: 100,
  [columnsNamesMap.orderBy]: 100,
  [columnsNamesMap.actions]: 52
};

export const SavedFiltersList = ({ onApplySavedFilter, hiddenColumns }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No saved filters</Typography>
        ) : (
          <>
            <TableHeader hiddenColumns={hiddenColumns} />

            <MuiList disablePadding>
              <InfiniteListLoader
                items={savedFilters}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ onApplySavedFilter, hiddenColumns }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
