import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import { stopPropagation } from '../../../../helpers/dom';
import { useModal } from '../../../ModalsProvider';
import { TextField } from '../../TextField';
import { useFormikField } from '../../useFormikField';
import { IconButton } from '../../../IconButton';
import { ScheduleEventsFieldModal } from './ScheduleEventsFieldModal';
import { MultiValue } from './MultiValue';
import { SingleValue } from './SingleValue';

export const ScheduleEventsField = ({
  isMulti = false,
  params = {},
  hiddenFilterFields,
  tasksFilterParams,
  onChange = () => {},

  ...props
}) => {
  const { openModal } = useModal();
  const formikFieldProps = useFormikField(props.name);
  const { fieldProps: [ field, , formikHelpers = {} ] = [] } = formikFieldProps;
  const [ value, setValue ] = useState(field.value);

  const openScheduleEventsModal = () => {
    openModal(ScheduleEventsFieldModal, {
      payload: {
        tasksFilterParams,
        isMulti,
        filter: params,
        hiddenFilterFields,
        initialSelectedEventsIDs: value && (isMulti ? value?.map(({ id }) => id) : [ value ])
      },
      onModalResolved: (events) => {
        const value = isMulti ? events : events[0];

        formikHelpers.setValue(value);
        setValue(value);
        onChange(value);
      }
    });
  };

  const clear = () => {
    formikHelpers.setValue(null);
    setValue(null);
    onChange(null);
  };

  const removeElement = (id) => () => {
    const updatedEvents = isMulti ? value?.filter((event) => event.id !== id) : null;

    setValue(updatedEvents);
    formikHelpers.setValue(updatedEvents);
    onChange(updatedEvents);
  };

  useEffect(() => {
    const newValue = field.value || null;

    formikHelpers.setValue(newValue);
    setValue(newValue);
  }, []);

  return (
    <TextField
      label="Schedule Events"
      InputProps={{
        inputComponent: ({ inputRef, ...props }) => (
          <Box display="flex" flexGrow={1} { ...(!value && props) }>
            {isMulti && !!value
              ? <MultiValue events={value} onDelete={removeElement} />
              : <SingleValue event={value} />
            }
          </Box>
        ),

        endAdornment: (
          <>
            {((isMulti && !!value?.length) || (!isMulti && !!value)) &&
              <IconButton size="small" title="Clear" onClick={stopPropagation(clear)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            }

            <IconButton size="small" edge="end" title="Search events">
              <SearchOutlinedIcon fontSize="small" />
            </IconButton>
          </>
        )
      }}
      onClick={openScheduleEventsModal}

      {...props}
    />
  );
};
