import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.ADD_TODO_ITEM]: (state, payload) => {
    return {
      ...state,

      checklist: [ ...state.checklist, payload ]
    };
  },

  [types.UPDATE_LIST_TODO_ITEM]: (state, payload) => {
    return {
      ...state,

      checklist: payload
    };
  },

  [types.UPDATE_TODO_ITEM]: (
    { checklist, ...state },
    payload
  ) => {
    return {
      ...state,

      checklist: checklist.map((item) => {
        return item.id === payload.id ? payload : item;
      })
    };
  },

  [types.DELETE_TODO_ITEM]: ({ checklist, ...state }, payloadID) => {
    return {
      ...state,

      checklist: checklist.filter((item) => item.id !== payloadID)
    };
  }
});
