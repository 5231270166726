import { useMediaQuery, useTheme } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import { AddressLink } from '../../../../../../../../components/AddressLink';
import { Button } from '../../../../../../../../components/Button';
import { IconButton } from '../../../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths, columnsWidthsSm } from '../List';

export const Row = ({ isActive, appointmentBook, onToggleActive, onBookDelete }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const actionsFlexBasis = !isMobile ? columnsWidths.actions : columnsWidthsSm.actions;

  const toggleActiveAppointmentBook = () => {
    onToggleActive(appointmentBook.id);
  };

  const handleBookDelete = () => {
    onBookDelete(appointmentBook?.id);
  };

  return (
    <ListRow disabled={!isActive}>
      <ListRowCell grow actions flexBasis={columnsWidths.name}>
        {appointmentBook.appointment_book_name}
      </ListRowCell>

      {!isMobile && (
        <ListRowCell grow flexBasis={columnsWidths.location}>
          <AddressLink address={appointmentBook.office?.full_address} />
        </ListRowCell>
      )}

      <ListRowCell actions noDisable justifyContent="flex-end" flexBasis={actionsFlexBasis}>
        {isMobile ? (
          <IconButton
            color={isActive ? 'warning' : 'success'}
            onClick={toggleActiveAppointmentBook}
          >
            {isActive
              ? <LockIcon fontSize="small" color="inherit" />
              : <LockOpenIcon fontSize="small" color="inherit" />}
          </IconButton>
        ) : (
          <Button
            size={isMobile ? 'small' : 'medium'}
            variant="outlined"
            color={isActive ? 'warning' : 'success'}
            onClick={toggleActiveAppointmentBook}
          >
            {isActive ? 'Deactivate' : 'Activate'}
          </Button>
        )}

        <IconButton
          color="error"
          edge="end"
          title="Remove from this appt. type"
          onClick={handleBookDelete}
        >
          <ClearIcon fontSize="small"/>
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
