import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { ShortInsuranceInfo } from '../../../../../../../../components/users/ShortInsuranceInfo';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Loader } from '../../../../../../../../components/Loader';
import { fieldsKeysMap } from '../../filterKeysMap';
import { orderByOptions } from '../../orderByOptions';
import { columnsWidths } from '../SavedFiltersList';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter,
  hiddenColumns = []
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      {!hiddenColumns.includes(fieldsKeysMap.formType) &&
        <ListRowCell grow flexBasis={columnsWidths.medicalFormType}>
          {params?.form_type?.label || '-'}
        </ListRowCell>
      }

      {!hiddenColumns.includes(fieldsKeysMap.search) &&
        <ListRowCell grow flexBasis={columnsWidths.search}>
          {params?.search || '-'}
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.dateFrom}>
        {params?.from ? moment.unix(params.from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateTo}>
        {params?.to ? moment.unix(params.to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        <UserLink
          noWrap
          size="sm"
          user={params?.treating_physician_id}
        />
      </ListRowCell>

      {!hiddenColumns.includes(fieldsKeysMap.users) &&
        <ListRowCell flexBasis={columnsWidths.users}>
          <Box display="flex" flexDirection="column">
            {!params?.users?.length ? '-' : params?.users?.map((user) => (
              <Box display="flex" alignItems="center" key={user?.id}>
                <UserLink
                  noWrap
                  size="sm"
                  key={user.id}
                  user={user}
                />
              </Box>
            ))}
          </Box>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.insuranceId}>
        {params?.insuranceId ? <ShortInsuranceInfo insurance={params?.insuranceId} /> : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.fromDOI}>
        {params?.from_doi ? moment.unix(params.from_doi).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.toDOI}>
        {params?.to_doi ? moment.unix(params.to_doi).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.orderBy}>
        {orderByOptions.find(({ value }) => value === params?.order_by)?.label || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
