import cn from 'classnames';
import { makeStyles, Box, Typography, Tooltip } from '@material-ui/core';
import { FileTypeIcon } from '../../../../../files-common';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ mri }) => {
  const classes = useStyles();

  return (
    <>
      <Typography noWrap gutterBottom variant="h6" title={mri.name}>
        {mri.name}
      </Typography>

      {mri.note ?
        <Tooltip arrow leaveDelay={200} title={mri.note}>
          <Box
            fontWeight="normal"
            height={38}
            className={cn(classes.noteContent, { [classes.noteContent_cropped]: mri.note.length > 120 })}
          >
            <Typography color="textSecondary" component="p" variant="caption" align="justify">
              {mri.note}
            </Typography>
          </Box>
        </Tooltip>
        :
        <Typography color="textSecondary">-</Typography>
      }

      <div className={classes.root}>
        {!mri?.media ? (
          <Typography color="textSecondary">No file found</Typography>
        ) : (
          mri?.media?.previews['280'] ?
            <img
              src={mri?.media?.previews['280'] || mri?.media?.url}
              alt={`${mri?.media?.extension}`}
              className={classes.filePreview}
            />
            :
            <FileTypeIcon className={classes.filePreviewIcon} file={mri?.media} />
        )}
      </div>
    </>
  );
};
