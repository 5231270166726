export const styles = ({ spacing, palette, transitions }) => ({
  picker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    cursor: 'pointer',
    minWidth: 0,
    padding: spacing(0.25),
    transition: transitions.create(),

    '&:hover': {
      backgroundColor: 'rgba(0, 188, 212, 0.25)'
    }
  },

  picker_active: {
    backgroundColor: 'rgba(0, 188, 212, 0.2)'
  },

  picker_item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: spacing(0.25),
    width: 18,
    height: 18
  },

  picker_item__active: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    borderRadius: '50%'
  },

  arrowButton: {
    '&:focus': {
      color: palette.primary.main
    }
  }
});
