import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { api } from '../../../../../../api';
import * as certificatesApi from '../../../../../../api/profile';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CertificatesContext = createContext();

export const CertificatesProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const {
    isFetched,
    certificates
  } = state;
  const cancelFetch = useRef(() => {});
  const usersLastGlobalAction = useSelector(({ globalActions }) => globalActions.usersLastGlobalAction);

  const fetchCertificates = () => {
    cancelFetch.current();

    certificatesApi.fetchCertificates({
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(({ data }) => {
      dispatch({ type: types.FETCH_CERTIFICATES_SUCCESS, payload: data });
    });
  };

  const addCertificate = ({ values, setErrors, setSubmitting, handleModalResolve }) => {
    certificatesApi.createCertificate(values).then(({ data }) => {
      dispatch({ type: types.ADD_CERTIFICATE, payload: data });
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve();
    }).catch(({ errors, message }) => {
      setSubmitting(false);

      if (errors) {
        const errorMessage = Object.values(errors);

        setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  const uploadCertificate = ({ formData, setErrors, setSubmitting, handleModalResolve }) => {
    certificatesApi.uploadCertificate(formData).then(({ data }) => {
      dispatch({ type: types.ADD_CERTIFICATE, payload: data });
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve();
    }).catch(({ errors, message }) => {
      setSubmitting(false);

      if (errors) {
        const errorMessage = Object.values(errors);

        setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  const deleteCertificate = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        certificatesApi.deleteCertificate(id).then(() => {
          dispatch({ type: types.DELETE_CERTIFICATE, payload: id });
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  const providerValue = {
    isFetched,
    certificates,

    // functions
    uploadCertificate,
    deleteCertificate,
    addCertificate
  };

  useEffect(() => {
    if (usersLastGlobalAction && usersLastGlobalAction?.type === 'SET_PROFILE_DATA') {
      fetchCertificates();
    }
  }, [ usersLastGlobalAction ]);

  useEffect(() => {
    fetchCertificates();

    return cancelFetch.current;
  }, []);

  return (
    <CertificatesContext.Provider value={providerValue}>
      {children}
    </CertificatesContext.Provider>
  );
};
