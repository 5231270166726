export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: -spacing(1.5)
  },

  actionButton: {
    marginRight: -spacing(0.5),
    marginLeft: 0
  },

  actionButton_exit: {
    marginRight: spacing()
  }
});
