import { useEffect, useState } from 'react';
import { makeStyles, List, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Checkbox } from '../../FormField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const WidgetsMenu = ({
  checkedWidgetsTypes,
  widgets,
  widgetsMap,
  onTakeItem,
  onPutItem
}) => {
  const classes = useStyles();
  const [ checkedWidgets, setCheckedWidgets ] = useState(checkedWidgetsTypes);
  const { layouts, toolbox } = widgets;
  const toolboxList = toolbox && toolbox.xl;

  const handleItemSelection = (key) => (event) => {
    const { checked } = event.target;

    setCheckedWidgets((widgets) => ({ ...widgets, [key]: checked }));
    checked ? onTakeItem(key)() : onPutItem(key)();
  };

  const handleClickSelection = (key) => () => {
    const checked = !checkedWidgets[key];

    setCheckedWidgets((widgets) => ({ ...widgets, [key]: checked }));
    checked ? onTakeItem(key)() : onPutItem(key)();
  };

  useEffect(() => {
    if (toolboxList) {
      toolboxList?.map((item) => setCheckedWidgets((state) => ({ ...state, [item.i]: false })));
    }
  }, [ layouts, toolbox ]);

  return (
    <List className={classes.root}>
      {Object.keys(widgetsMap).map((key) => (
        <MenuItem
          key={key}
          disabled={!checkedWidgetsTypes.hasOwnProperty(key)}
          onClick={handleClickSelection(key)}
        >
          <ListItemIcon>
            <Checkbox
              withoutFormik
              edge="start"
              value={checkedWidgets[key]}
              checked={checkedWidgets[key]}
              onChange={handleItemSelection(key)}
            />
          </ListItemIcon>

          <ListItemText primary={widgetsMap[key].title} />
        </MenuItem>
      ))}
    </List>
  );
};
