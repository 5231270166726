export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: spacing(1, 2),

    '& .fc-timegrid-event': {
      minHeight: 90
    },

    '& .fc-toolbar.fc-header-toolbar': {
      flexDirection: 'row',
      minHeight: 'auto',

      [breakpoints.up(breakpoints.values.mobileLg)]: {
        flexDirection: 'row',
        minHeight: 'auto'
      },

      [breakpoints.down(breakpoints.values.mobileLg)]: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 100,
        marginBottom: spacing(1.5)
      }
    },

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      padding: 0
    },

    [breakpoints.down(breakpoints.values.tablet)]: {
      padding: spacing(1)
    }
  },

  main: {
    display: 'flex',
    flexGrow: 1,

    [breakpoints.down(breakpoints.values.tablet)]: {
      flexDirection: 'column'
    }
  },

  calendar: {
    flexGrow: 1,
    height: '100%',
    padding: spacing(),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      padding: 0
    }
  },

  dayView: {
    display: 'flex',
    flexDirection: 'column',
    width: 380,
    padding: spacing()
  }
});
