import { Box, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { NotificationsList } from '../NotificationsList';
import { UserNotificationsProvider } from '../UserNotificationsProvider';

export const NotificationsContent = ({ userID }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <UserNotificationsProvider userID={userID}>
      <Paper style={{ height: '100%' }}>
        {isMobile && (
          <Box py={1} px={2}>
            <Typography variant="h4" color="primary">Notifications</Typography>
          </Box>
        )}

        <NotificationsList />
      </Paper>
    </UserNotificationsProvider>
  );
};
