import { useContext } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
// import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import AddIcon from '@material-ui/icons/Add';
import { mdiBriefcaseCheckOutline, mdiAccountArrowLeftOutline, mdiAccountGroupOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from '../../../../components/Button';
import { CallReportsContext } from '../../../../components/call-reports/CallReportsProvider';
import { CopyToModal } from '../../../../components/call-reports/CopyToModal';
import { parentTypesMap } from '../../../../components/call-reports/CopyToModal/parentTypeOptions';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { useModal } from '../../../../components/ModalsProvider';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../components/Page';
import { IconButton } from '../../../../components/IconButton';
import { Tooltip } from '../../../../components/Tooltip';
import { Fab } from '../../../../components/Fab';
import { SelectedItemsCount } from '../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../components/VerticalDivider';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { CallReportsModal } from '../CallReportsModal';

export const ActionsBar = ({ isTablet, payload = {}, disableAdd = false, filterOpenButton }) => {
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const {
    meta,
    isFetchingReport,
    resetCallReports,
    selectedCallReportsIDs,
    generateReport,
    deleteParent,
    generateGeneralReport,
    deleteCallReportsFromSelected
  } = useContext(CallReportsContext);
  const isAdmin = hasRole(rolesMap.admin, rolesMap.supervisor);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const openAttachModal = () => {
    openModal(CallReportsModal, {
      payload,
      onModalResolved: resetCallReports
    });
  };

  const onDelete = () => {
    deleteParent({
      ...payload,
      ids: selectedCallReportsIDs
    });
  };

  const copyReports = (parentType) => () => {
    openModal(CopyToModal, {
      payload: { ids: selectedCallReportsIDs, parent_type: parentType },
      onModalResolved: () => {
        deleteCallReportsFromSelected(selectedCallReportsIDs);
        resetCallReports();
      }
    });
  };

  return (
    <PageHeader>
      {disableAdd ? isMobile || (
        <PageHeaderTitle>
          Call Reports
        </PageHeaderTitle>
      ) : (
        <PageHeaderActions disablePosition>
          <Fab
            color="primary"
            variant="extended"
            startIcon={<AddIcon/>}
            onClick={openAttachModal}
          >
            Add Call report
          </Fab>
        </PageHeaderActions>
      )}

      <PageHeaderActions disableSpacing endPosition>
        {!!selectedCallReportsIDs?.length && (
          <>
            <SelectedItemsCount length={selectedCallReportsIDs.length} total={meta.total} />
            <VerticalDivider verticalGutters={1} horizontalGutters={1} />

            <Tooltip
              arrow
              title={<Typography>Attach selected call reports to case</Typography>}
            >
              <IconButton onClick={copyReports(parentTypesMap.case)}>
                <Icon path={mdiBriefcaseCheckOutline} size={1} />
              </IconButton>
            </Tooltip>

            <Tooltip
              arrow
              title={<Typography>Attach selected call reports to user</Typography>}
            >
              <IconButton onClick={copyReports(parentTypesMap.user)}>
                <Icon path={mdiAccountArrowLeftOutline} size={1} />
              </IconButton>
            </Tooltip>

            {!disableAdd && (
              <Tooltip
                arrow
                title={<Typography>Delete selected call reports</Typography>}
              >
                <IconButton onClick={onDelete}>
                  <DeleteIcon color="error" fontSize="inherit"/>
                </IconButton>
              </Tooltip>
            )}

            {isMobile ? (
              <Box width="100%" flexShrink={0} />
            ) : (
              <VerticalDivider verticalGutters={1} horizontalGutters={1} />
            )}

            {/* TODO: add after BE updates */}
            {/*<Tooltip*/}
            {/*  arrow*/}
            {/*  title={<Typography>Download files from selected call reports</Typography>}*/}
            {/*>*/}
            {/*  <IconButton*/}
            {/*    color="primary"*/}
            {/*    onClick={() => {}}*/}
            {/*  >*/}
            {/*    <DownloadIcon />*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
          </>
        )}

        {disableAdd && (
          <>
            <Tooltip title="Please, fill users or companies and dates filters">
              <Button
                size="small"
                disabled={isFetchingReport}
                color="primary"
                variant="contained"
                onClick={generateReport}
              >
                Export to file
              </Button>
            </Tooltip>

            <Box mx={0.25} />

            {isAdmin && (
              <Tooltip title="Generates a general report for all employees">
                <Button
                  size="small"
                  disabled={isFetchingReport}
                  color="primary"
                  variant="contained"
                  onClick={generateGeneralReport}
                  startIcon={<Icon path={mdiAccountGroupOutline} size={1} />}
                >
                  Generate report
                </Button>
              </Tooltip>
            )}

            <VerticalDivider verticalGutters={1} horizontalGutters={1} />
          </>
        )}

        {!isTablet ? (
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <PageHeaderActions>
            {filterOpenButton}
          </PageHeaderActions>
        )}
      </PageHeaderActions>
    </PageHeader>
  );
};
