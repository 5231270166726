import { Yup } from '../../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  towing_co_id: Yup.string().nullable().max(500),
  car_seats: Yup.string().nullable().max(500),
  auto_body_shop_id: Yup.string().nullable().max(500),
  pd_payer: Yup.string().nullable().max(500),
  rental_co_id: Yup.string().nullable().max(500),
  towing_paid: Yup.string().nullable().max(500),
  car_seat_reimb: Yup.string().nullable().max(500),
  pd_appraised_amount: Yup.number().nullable(),
  deductible_paid: Yup.number().nullable(),
  final_pd_amount: Yup.number().nullable(),
  total_loss: Yup.boolean().nullable(),
  deductible_reimbursed: Yup.boolean().nullable(),
  rental_paid: Yup.string().nullable().max(500),
  rental_reimbursed: Yup.boolean().nullable()
});
