import { Create } from './Create';
import { Edit } from './Edit';

export const routes = [
  {
    path: '/codes/procedure-codes/cpt/create',
    component: Create
  },
  {
    path: '/codes/procedure-codes/cpt/:id/edit',
    component: Edit
  }
];
