import { NewFileActivity } from '../NewFileActivity';
import { Text } from '../Text';

export const IntakeForm = ({ before, after, fieldLabel }) => {
  if (!before?.media && after?.media) {
    return <NewFileActivity file={after?.media} fieldLabel={fieldLabel} />;
  } else if (before?.media && !after?.media) {
    return <NewFileActivity disableAttach file={before?.media} fieldLabel={fieldLabel} />;
  } else {
    return <Text fieldLabel={fieldLabel} />;
  }
};
