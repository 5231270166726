import * as patientsApi from '../../../api/users/patients';
import { CreateBaseCustomerModal } from '../../../app/Dashboard/Members/CreateBaseCustomerModal';
import { useModal } from '../../ModalsProvider';
import { UsersSelect } from '../UsersSelect';

const fetchUsers = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return patientsApi.fetchPatients({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

export const PatientsSelect = ({ params = {}, creatablePayload, ...props }) => {
  const { openModal } = useModal();

  const addUser = () => {
    return new Promise((resolve, reject) => {
      openModal(CreateBaseCustomerModal, {
        payload: {
          isPatient: true,

          ...creatablePayload
        },
        onModalResolved: resolve,
        onModalRejected: reject
      });
    });
  };

  return (
    <UsersSelect
      label="Patient"
      onNeedFetch={fetchUsers(params)}
      onCreate={addUser}

      {...props}
    />
  );
};
