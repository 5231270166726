import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { get } from 'lodash';
import moment from 'moment';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Date } from '../../../../../../../../../components/Date';
import { itemsRoutesByModels } from '../../../../../../../../../dataMaps/apiModels';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { Popper } from '../../../../../../../../../components/Popper';
import { PaymentMenu } from '../../../../../../../../../components/billing/payments';
import { minRowHeight, columnsWidths } from '../Payments';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ payment }) => {
  const classes = useStyles();
  const history = useHistory();

  const openPaymentMenu = (callback) => (event) => {
    event.preventDefault();

    callback(event);
  };

  const getPaymentsPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.payment(payment.id));
    }
  };

  return (
    <ListRow
      minHeight={minRowHeight}
      title="Open payments page"
      className={classes.root}
      onClick={getPaymentsPage}
    >
      <ListRowCell grow flexBasis={columnsWidths.number}>
        <Typography noWrap title={payment.number}>
          {payment.number}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.due_on}>
        <Date
          disableIcon
          disableMoment
          color="secondary"
          date={payment?.date && moment(payment?.date).format('L')}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount}>
        <Typography noWrap title={get(payment, 'amount', 0).toFixed(2)}>
          <NumberFormat
            value={get(payment, 'amount', 0).toFixed(2)}
            displayType="text"
            thousandSeparator={true}
            prefix={'$ '}
          />
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        <Typography noWrap title={get(payment, 'invoice.total', 0).toFixed(2)}>
          <NumberFormat
            value={get(payment, 'invoice.total', 0).toFixed(2)}
            displayType="text"
            thousandSeparator={true}
            prefix={'$ '}
          />
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={openPaymentMenu(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <PaymentMenu
              parentPage="widget"
              payment={payment}
              onClose={handleClose}
            />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
