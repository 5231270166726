import { Icon } from '@mdi/react';
import {
  mdiClipboardAccountOutline,
  mdiAccountHeartOutline,
  mdiCellphoneMessage,
  mdiChartBellCurve,
  mdiZipBoxOutline,
  mdiFileMultipleOutline,
  mdiPlaylistCheck,
  mdiAccountMultipleOutline
} from '@mdi/js';
import { SvgIcon } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { isCurrentUserID } from '../../../../../utils/isCurrentUserID';
import { hasRole } from '../../../../../utils/hasRole';
import { filtersKeysMap } from '../../../../../store/lastFilters';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import ActivityIcon from '../../../../../components/icons/activity.svg';
import TimerIcon from '../../../../../components/icons/chronometer.svg';
import { filterFieldsMap } from '../../../../../components/schedule-events';
import { WorkInfo } from '../../../../../components/users/Tabs';
import { TasksWidget } from '../../../../../components/tasks';
import { Patients } from '../../../ProfilePage/Patients';
import { CallReports } from './CallReports';
import { Notifications } from './Notifications';
import { Appointments } from './Appointments';
import { GeneralInfo } from './GeneralInfo';
import { Schedule } from './Schedule';
import { Emails } from './Emails';
import { Files } from './Files';
import { TimeTracking } from './TimeTracking';

export const routes = [
  {
    exact: true,
    path: '/members/users/:userID',
    icon: <SvgIcon><Icon path={mdiClipboardAccountOutline} /></SvgIcon>,
    label: 'General info',
    component: GeneralInfo
  },
  {
    exact: true,
    path: '/members/users/:userID/work-info',
    icon: <HomeWorkOutlinedIcon />,
    label: 'Work Info',
    component: WorkInfo
  },
  {
    exact: true,
    hide: ({ params = {}, payload }) => {
      const isAdminOrSupervisor = hasRole(rolesMap.admin, rolesMap.supervisor);
      const isPatient = payload?.user?.role === rolesMap.patient;

      return isPatient || (!isAdminOrSupervisor && !isCurrentUserID(+params.userID));
    },
    path: '/members/users/:userID/time-tracking',
    icon: <SvgIcon><TimerIcon /></SvgIcon>,
    label: 'Time Tracking & Billing',
    component: TimeTracking
  },
  {
    exact: true,
    path: '/members/users/:userID/call-reports',
    icon: <PhoneOutlinedIcon />,
    label: 'Call Reports',
    component: CallReports
  },
  {
    exact: true,
    path: '/members/users/:userID/medical-info/medical-forms',
    icon: <SvgIcon><Icon path={mdiAccountHeartOutline} /></SvgIcon>,
    label: 'Medical Info',
    component: () => 'Medical Info'
  },
  {
    exact: true,
    path: '/members/users/:userID/appointments',
    icon: <InsertInvitationOutlinedIcon />,
    label: 'Appointments',
    component: Appointments
  },
  {
    exact: true,
    path: '/members/users/:userID/schedule',
    icon: <DateRangeOutlinedIcon />,
    label: 'Schedule',
    component: () => <Schedule hiddenFilterFields={[ filterFieldsMap.users ]} />
  },
  {
    exact: true,
    path: '/members/users/:userID/tasks',
    icon: <SvgIcon><Icon path={mdiPlaylistCheck} /></SvgIcon>,
    label: 'Tasks',
    component: () => <TasksWidget isTabPage />
  },
  {
    exact: true,
    path: '/members/users/:userID/activity',
    icon: <SvgIcon><ActivityIcon /></SvgIcon>,
    label: 'Activity',
    component: () => 'Activity'
  },
  {
    exact: true,
    path: '/members/users/:userID/emails',
    icon: <EmailIcon />,
    label: 'Emails',
    component: Emails
  },
  {
    path: '/members/users/:userID/files',
    icon: <SvgIcon><Icon path={mdiFileMultipleOutline} /></SvgIcon>,
    label: 'Files',
    component: () => <Files filterKey={filtersKeysMap.member_files} />
  },
  {
    exact: true,
    path: '/members/users/:userID/analytics',
    icon: <SvgIcon><Icon path={mdiChartBellCurve} /></SvgIcon>,
    label: 'Analytics',
    component: () => 'Analytics'
  },
  {
    exact: true,
    path: '/members/users/:userID/archive',
    icon: <SvgIcon><Icon path={mdiZipBoxOutline} /></SvgIcon>,
    label: 'Archive',
    component: () => 'Archive'
  },
  {
    exact: true,
    hide: ({ params = {}, payload }) => {
      const hasAccess = hasRole(rolesMap.admin, rolesMap.supervisor, rolesMap.advocate);

      return !(hasAccess || !isCurrentUserID(+params.userID));
    },
    path: '/members/users/:userID/patients',
    icon: <SvgIcon><Icon path={mdiAccountMultipleOutline} /></SvgIcon>,
    label: 'Patients',
    component: Patients
  },
  {
    exact: true,
    path: '/members/users/:userID/notifications',
    icon: <SvgIcon><Icon path={mdiCellphoneMessage} /></SvgIcon>,
    label: 'Notifications',
    component: Notifications
  }
];
