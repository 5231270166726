export const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 110,
    marginTop: spacing(2),
    border: `1px dashed ${palette.grey[200]}`,
    backgroundColor: palette.grey[25],
    padding: spacing(2)
  },

  noteContent: {
    overflow: 'hidden',
    position: 'relative',
    maxHeight: 40
  },

  noteContent_cropped: {
    '&:before': {
      content: '"..."',
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: 48,
      marginLeft: -spacing(6),
      paddingRight: spacing(),
      textAlign: 'right',
      color: palette.text.secondary,
      background: 'linear-gradient(to right, rgba(255, 255, 255, 0), white 40%, white)',
      zIndex: 1
    }
  }
});
