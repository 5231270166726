import { Yup } from '../../../../utils/validation';
import {
  validationSchema as privateValidationSchema
} from '../PrivatePacket/GeneralInfo/validationSchema';

export const validationSchema = privateValidationSchema.shape({
  attorney_first_name: Yup.string().nullable().max(20).when('attorney_is_unknown', (isUnknown, schema) => {
    return isUnknown ? schema : schema.required();
  }),
  attorney_last_name: Yup.string().nullable().max(20).when('attorney_is_unknown', (isUnknown, schema) => {
    return isUnknown ? schema : schema.required();
  }),
  attorney_phone: Yup.string().nullable(),
  attorney_email: Yup.string().email().nullable(),
  accident_type: Yup.string().nullable().required(),
  attorney_is_unknown: Yup.bool().nullable()
});
