import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  ListItemIcon,
  SvgIcon
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import * as reminderGroupsApi from '../../../../../../../api/cases/reminders/groups';
import { ColorPreview } from '../../../../../../../components/ColorPreview';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { Popper } from '../../../../../../../components/Popper';
import {
  CaseReminderGroupsContext,
  EditReminderGroupModal
} from '../../../../../../../components/cases/reminders/groups';

export const Row = ({
  item: reminderGroup,
  reminderGroupIsSelected,
  onReminderGroupToggleSelection,
  isLoaded,
  recalculateHeight
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const reminderGroupsContext = useContext(CaseReminderGroupsContext);
  const isSelected = reminderGroupIsSelected(reminderGroup);
  const [ isLoading, setIsLoading ] = useState(false);

  const handleReminderGroupToggleSelection = () => {
    onReminderGroupToggleSelection(reminderGroup);
  };

  const handleReminderGroupDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setIsLoading(true);

        reminderGroupsApi.deleteCaseReminderGroup(reminderGroup.id).then(() => {
          reminderGroupsContext.deleteReminderGroup(reminderGroup);
          enqueueSnackbar('Reminders group successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Reminders group not deleted', { variant: 'error' });
          setIsLoading(false);
        });
      }
    });
  };

  const handleReminderGroupUpdate = () => {
    openModal(EditReminderGroupModal, {
      payload: {
        reminderGroup
      },

      onModalResolved: (reminderGroup) => {
        reminderGroupsContext.updateReminderGroup(reminderGroup);
      }
    });
  };

  useEffect(() => {
    recalculateHeight();
  }, [ isLoaded, isSelected ]);

  return !isLoaded ? null : (
    <ListItem button disabled={isLoading} selected={isSelected} onClick={handleReminderGroupToggleSelection}>
      <ListItemIcon>
        <ColorPreview color={reminderGroup.color} />
      </ListItemIcon>

      <ListItemText primary={reminderGroup.name} secondary={reminderGroup.description} />

      <ListItemSecondaryAction>
        <Popper
          placement="right-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton disabled={isLoading} buttonRef={anchorRef} onClick={handleToggle}>
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <List onClick={handleClose}>
              <MenuItem onClick={handleReminderGroupUpdate}>
                <ListItemIcon>
                  <EditIcon fontSize="inherit" />
                </ListItemIcon>

                <ListItemText primary="Edit" />
              </MenuItem>

              <MenuItem onClick={handleReminderGroupDelete}>
                <ListItemIcon>
                  <SvgIcon fontSize="inherit">
                    <Icon path={mdiTrashCanOutline} />
                  </SvgIcon>
                </ListItemIcon>

                <ListItemText primary="Delete" />
              </MenuItem>
            </List>
          )}
        </Popper>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
