import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FormStep = ({ step, checked, withoutTitle, withoutLine, children }) => {
  const classes = useStyles();

  return (
    <div
      className={cn(
        classes.root, {
          [classes.rootWithoutTitle]: withoutTitle,
          [classes.rootWithoutLine]: withoutLine,
          [classes.rootChecked]: checked
        }
      )}
    >
      <div className={classes.iconWrap}>
        <div className={classes.icon}>
          {checked ? <CheckIcon /> : step}
        </div>
      </div>

      <div className={classes.main}>
        {children}
      </div>
    </div>
  );
};
