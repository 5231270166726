import { useFormikContext } from 'formik';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { CasesSelect } from '../../../../../../components/cases/CasesSelect';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { UserLink } from '../../../../../../components/UserLink';
import { CaseViewModal } from '../../../../CasesPage/Cases/CaseViewModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CaseInfo = () => {
  const { openModal } = useModal();
  const { values, setValues } = useFormikContext();
  const classes = useStyles();
  const heading_attorney = values?.caseItem?.case_users?.find(({ case_role }) => case_role === 'guardian');

  const handleSelectChange = (option) => {
    setValues({
      ...values,
      case_id: option.value || '',
      caseItem: option || values?.caseItem
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  const openCasePreview = () => {
    if (values?.caseItem?.id) {
      openModal(CaseViewModal, {
        payload: { id: values?.caseItem?.id }
      });
    }
  };

  return values.caseItem && (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <Typography variant="h4" className={classes.title}>
          Case
        </Typography>

        <div className={classes.content}>
          <div className={classes.contentInfo}>
            <Box mr={1.5} ml={0.5}>
              <Typography variant="h4" color="secondary">#</Typography>
            </Box>

            {!!values?.caseItem?.file_number ?
              <Typography component={Link} noWrap color="textPrimary" onClick={openCasePreview}>
                {values.caseItem.file_number}
              </Typography>
              :
              <Typography color="textSecondary">No info.</Typography>
            }
          </div>

          <div className={classes.contentInfo}>
            <Box mr={1}>
              <WorkIcon fontSize="small"  color="secondary"/>
            </Box>

            <Typography component={Link} noWrap color="textPrimary" onClick={openCasePreview}>
              {values.caseItem.name}
            </Typography>
          </div>

          {!!values?.caseItem?.guardian &&
            <UserLink
              size="md"
              user={values?.caseItem?.guardian?.user}
              onClick={openUserPreview(values?.caseItem?.guardian?.user?.id)}
            />
          }

          {heading_attorney &&
            <UserLink
              size="md"
              user={heading_attorney?.user}
              onClick={openUserPreview(heading_attorney?.user?.id)}
            />
          }
        </div>
      </div>

      <div className={classes.mainContent}>
        <Typography variant="h4" className={classes.title}>
          Change case
        </Typography>

        <div className={classes.selectContent}>
          <CasesSelect
            disabled
            disableClearable
            name="case_id"
            label="All cases"
            placeholder="Search case by name..."
            onChange={handleSelectChange}
          />
        </div>
      </div>
    </div>
  );
};
