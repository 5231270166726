import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as codesApi from '../../../../../../../api/codes/procedures';
import { Loader } from '../../../../../../../components/Loader';
import { setSchedulesLastGlobalAction } from '../../../../../../../store/globalActions';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../../components/Modal';
import * as types from '../SchedulesContext/types';
import { Body } from './Body';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

export const CreateScheduleModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { procedure_code_id, onUpdateCount = () => {} }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();

  const createFeeSchedule = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return codesApi.createFeeSchedule({ procedure_code_id, ...values }).then((data) => {
      enqueueSnackbar('Schedule successfully created', { variant: 'success' });
      onUpdateCount((state) => ++state);
      dispatchRedux(setSchedulesLastGlobalAction({ type: types.ADD_SCHEDULE, payload: data }));
      handleModalResolve(data);
    }).catch(({ errors } = {}) => {
      if (errors) {
        setErrors(errors);
        enqueueSnackbar('Schedule not created', { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createFeeSchedule}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog {...DialogProps} fullScreen={isMobile}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Create Fee Schedule
            </ModalHeader>

            <ModalBody>
              <Body />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
