import { useState, useEffect } from 'react';
import { Dialog, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as formsApi from '../../../../../api/profile';
import * as usersApi from '../../../../../api/users';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { ModalHeader, ModalBody, ModalContainer, ModalHeaderActions } from '../../../../Modal';
import { useModal } from '../../../../ModalsProvider';
import { CreateBaseCustomerModal } from '../../../../../app/Dashboard/Members/CreateBaseCustomerModal';
import { List } from './List';

export const ApproveAttorneyModal = ({
  payload: {
    company_id,
    medicalFormID,
    attorneyData
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const { openModal } = useModal();
  const [ attorneys, setAttorneys ] = useState();

  const handleAttorneySelect = (attorney) => {
    handleModalResolve(attorney);
  };

  const addAttorney = () => {
    openModal(CreateBaseCustomerModal, {
      payload: {
        isAttorney: true,
        initialValues: {
          ...attorneyData,

          role: rolesMap.advocate
        }
      },

      onModalResolved: handleModalResolve
    });
  };

  useEffect(() => {
    formsApi.searchUsersForMedicalForm(medicalFormID).then(({ advocates }) => {
      if (company_id) {
        usersApi.fetchUsers({ params: { company_id } }).then(({ data }) => {
          setAttorneys([ ...(advocates || []), ...(data || []) ]);
        });
      } else {
        setAttorneys(advocates);
      }
    });
  }, []);

  return (
    <Dialog maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Select Attorney

          <ModalHeaderActions>
            <Button
              color="primary"
              startIcon={<AddIcon/>}
              onClick={addAttorney}
            >
              Add Attorney
            </Button>
          </ModalHeaderActions>
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <List attorneys={attorneys} onUserSelect={handleAttorneySelect} />
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
