import { Paper, Typography } from '@material-ui/core';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import {
  columnsWidths,
  widthBreakpointLg,
  widthBreakpointMd,
  widthBreakpointSm,
  widthBreakpointXS
} from '../listConfig';

export const TableHeader = ({ disablePatient = false, isTabsPage, width, formContext = {} }) => {
  const {
    selectedIDs,
    isDeleted = false,
    isUnconfirmed = false,
    allFormsIsSelected,
    toggleAllFormsSelection
  } = formContext;

  return (
    <Paper elevation={0}>
      <ListRow header>
        {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.medic) && !isTabsPage &&
          <ListRowCheckbox
            onClick={toggleAllFormsSelection}
            checked={allFormsIsSelected()}
            indeterminate={!!selectedIDs.length && !allFormsIsSelected()}
          />
        }

        {!disablePatient && (
          <ListRowCell grow flexBasis={columnsWidths.patient}>
            Patient
          </ListRowCell>
        )}

        {width > widthBreakpointLg &&
          <ListRowCell flexBasis={columnsWidths.date}>
            Date / Update
          </ListRowCell>
        }

        <ListRowCell noWrap flexBasis={columnsWidths.type}>
          <Typography noWrap variant="h5" title="Type of Medical Form">
            Form Type / Fill
          </Typography>
        </ListRowCell>

        <ListRowCell noWrap flexBasis={columnsWidths.children}>
          Follow Ups
        </ListRowCell>

        {width > widthBreakpointSm &&
          <ListRowCell flexBasis={columnsWidths.doi}>
            Date of Injury
          </ListRowCell>
        }

        {width > widthBreakpointXS &&
          <ListRowCell grow noWrap flexBasis={columnsWidths.name}>
            Physician
          </ListRowCell>
        }

        {width > widthBreakpointMd &&
          <ListRowCell flexBasis={columnsWidths.insurance_name}>
            {isUnconfirmed ? 'Ins / Law' : 'Insurance Company'}
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.insurance_id}>
          Insurance Type/ID
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.appointment_date}>
          Appt. date
        </ListRowCell>

        {!isUnconfirmed && (
          <ListRowCell actions flexBasis={columnsWidths.users}>
            Sign info
          </ListRowCell>
        )}

        {!isDeleted && <ListRowCell flexBasis={columnsWidths.action} />}
      </ListRow>
    </Paper>
  );
};
