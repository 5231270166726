import moment from 'moment';
import printJS from 'print-js';
import { getUserFullName } from '../../helpers/users';

export const printAppointments = (appointments, date) => {
  const printable = appointments.map((appointment) => {
    const duration = moment.duration(appointment.time, 'seconds').format('h[h] m[min]');

    return {
      date: `${moment.unix(appointment.appointment_at).format('LT')} / ${duration}`,
      patient: getUserFullName(appointment.patient) || '-',
      provider: getUserFullName(appointment.appointment_book?.provider) || '-',
      book: appointment.appointment_book?.appointment_book_name || '-',
      office: appointment.office?.name || '-',
      status: appointment.arrival_status?.description || '-',
      type: appointment.appointment_type?.code || '-'
    };
  });

  printJS({
    printable,
    header: `<h3>Appointments on ${date.format('L')}</h3>`,
    properties: [
      { field: 'date', displayName: 'Start / Duration' },
      { field: 'patient', displayName: 'Patient' },
      { field: 'provider', displayName: 'Provider' },
      { field: 'book', displayName: 'Appt. Book' },
      { field: 'office', displayName: 'Office' },
      { field: 'status', displayName: 'Status' },
      { field: 'type', displayName: 'Appt. Type' }
    ],
    style: 'table { font-size: 10pt };',
    type: 'json'
  });
};
