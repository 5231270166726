import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import cn from 'classnames';
import { makeStyles, Button, Typography, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import * as tasksApi from '../../../../../api/tasks';
import { TaskPreviewContext } from '../../../../../components/tasks/TasksProvider';
import { stopPropagation } from '../../../../../helpers/dom';
import { setTasksLastGlobalAction } from '../../../../../store/globalActions';
import { Popper } from '../../../../../components/Popper';
import { IconButton } from '../../../../../components/IconButton';
import { AppBar } from '../../../../../components/AppBar';
import * as taskActionTypes from '../../../../../components/tasks/TasksProvider/types';
import { EditCase } from '../../EditCase';
import  { AddCase } from '../../AddCase';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PreviewTaskHeader = ({
  icon,
  children,
  onClose,
  onDelete,
  onUpdate,
  isUpdateCase,
  onRestore
}) => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedTask, caseFields } = useContext(TaskPreviewContext);

  const onDeleteCase = () => {
    const users = selectedTask?.users?.map(({ id }) => id);

    tasksApi.updateTask({
      id: selectedTask.id,
      name: selectedTask.name,
      ...selectedTask,
      parent_type: null,
      parent_id: null,
      users
    }).then((data) => {
      dispatchRedux(setTasksLastGlobalAction({
        type: taskActionTypes.UPDATE_TASK_IN_LIST,
        payload: data
      }));
      enqueueSnackbar('Case successfully deleted', { variant: 'success' });
      onUpdate(data);
      onClose();
    });
  };

  return (
    <AppBar>
      <div className={cn(classes.root, onRestore && classes.root_edit)}>
        <Typography variant="h6" className={classes.title}>
          {icon &&
            <div className={classes.icon}>
              {icon}
            </div>
          }

          {children}
        </Typography>

        {(selectedTask.parent || caseFields?.parent_id) &&
          <EditCase
            isUpdateCase={isUpdateCase}
            onDelete={onDelete}
            onDeleteCase={onDeleteCase}
            classes={classes}
          />
        }

        {!selectedTask.parent && !caseFields?.parent_id &&
          <div className={classes.case}>
            <Popper
              placement="left-start"
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <Button
                    size="small"
                    color="primary"
                    buttonRef={anchorRef}
                    startIcon={<AddIcon />}
                    onClick={stopPropagation(handleToggle)}
                  >
                    Add case
                  </Button>
                )
              }
            >
              {({ handleClose }) => (
                <AddCase
                  task={selectedTask}
                  withSubmit={!isUpdateCase}
                  onClose={handleClose}
                />
              )}
            </Popper>
          </div>
        }
      </div>

      <div className={classes.icons}>
        {!isUpdateCase &&
          <Tooltip title="Edit task">
            <IconButton color="primary" onClick={onUpdate}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }

        {!!onRestore &&
          <Tooltip title="Reset form to previous values">
            <IconButton color="primary" onClick={onRestore}>
              <BackupIcon />
            </IconButton>
          </Tooltip>
        }

        {!!onDelete &&
          <Tooltip title="Remove task">
            <IconButton color="error" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }

        {!!onClose &&
          <Tooltip title="Discard changes and close">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
      </div>
    </AppBar>
  );
};
