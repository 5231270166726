import { Paper } from '@material-ui/core';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell } from '../../../../../ListRow';
import { columnsWidths } from '../listConfig';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={2}>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.description}>
          Description
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.creator}>
          Creator
        </ListRowCell>

        {hasRole(rolesMap.admin, rolesMap.supervisor) &&
          <ListRowCell flexBasis={columnsWidths.actions} />
        }
      </ListRow>
    </Paper>
  );
};
