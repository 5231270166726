import { useEffect, useRef, useState } from 'react';
import mic from 'microphone-stream';
import {
  TranscribeStreamingClient,
  StartMedicalStreamTranscriptionCommand,
  Specialty,
  Type
} from '@aws-sdk/client-transcribe-streaming';

const pcmEncodeChunk = (chunk) => {
  const input = mic.toRaw(chunk);
  const buffer = new ArrayBuffer(input.length * 2);
  const view = new DataView(buffer);
  let offset = 0;

  for (let i = 0; i < input.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));

    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }

  return Buffer.from(buffer);
};

const audioStream = async function* (micStream) {
  for await (const chunk of micStream) {
    yield { AudioEvent: { AudioChunk: pcmEncodeChunk(chunk) } };
  }
};

export const useVoiceRecognition = () => {
  const [ isRecognitionInProgress, setIsRecognitionInProgress ] = useState(false);
  const [ lastRecognizedText, setLastRecognizedText ] = useState('');
  const [ recognizedText, setRecognizedText ] = useState('');
  const [ resultText, setResultText ] = useState('');
  const micStreamRef = useRef(null);
  const clientRef = useRef(null);

  const updateResultText = (text) => {
    setResultText(text);
  };

  const startRecognition = () => {
    micStreamRef.current = new mic();

    clientRef.current = new TranscribeStreamingClient({
      region: 'us-east-1',
      credentials: {
        accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
        secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY
      }
    });

    navigator.mediaDevices.getUserMedia({ audio: { noiseSuppression: true } }).then((stream) => {
      micStreamRef.current.setStream(stream);
    });

    const command = new StartMedicalStreamTranscriptionCommand({
      LanguageCode: 'en-US',
      MediaSampleRateHertz: 48000,
      MediaEncoding: 'pcm',
      Specialty: Specialty.PRIMARYCARE,
      Type: Type.DICTATION,
      AudioStream: audioStream(micStreamRef.current)
    });

    (async () => {
      try {
        const data = await clientRef.current.send(command, { sessionTimeout: 60000 });

        for await (const event of data.TranscriptResultStream) {
          if (event.TranscriptEvent) {
            const results = event.TranscriptEvent.Transcript.Results;
            const alternatives = results[0]?.Alternatives;

            if (alternatives?.length) {
              const transcript = alternatives[0].Transcript;
              const isPartial = results[0].IsPartial;

              setIsRecognitionInProgress(isPartial);

              if (isPartial) {
                setLastRecognizedText(transcript);
              } else {
                setRecognizedText((text) => text + transcript + ' ');
                setLastRecognizedText('');
              }
            }
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('ERROR: ', error);
        // eslint-disable-next-line no-console
        console.log('process: ', process);
      }
    })();
  };

  const stopRecognition = () => {
    clientRef.current?.destroy();
    micStreamRef.current?.stop();
  };

  useEffect(() => {
    if (!isRecognitionInProgress) {
      setResultText((resultText) => {
        const resultHasSpace = resultText.lastIndexOf(' ') === resultText.length;
        const lastHasSpace = lastRecognizedText.indexOf(' ') === 0;
        const isNeedSpace = resultText && lastRecognizedText && !resultHasSpace && !lastHasSpace;

        return resultText + (isNeedSpace ? ' ' : '') + lastRecognizedText;
      });
    }
  }, [ isRecognitionInProgress, lastRecognizedText ]);

  useEffect(() => {
    return () => {
      stopRecognition();
    };
  }, []);

  return {
    isRecognitionInProgress,
    recognizedText: recognizedText + lastRecognizedText,
    lastRecognizedText,
    resultText,
    updateResultText,
    startRecognition,
    stopRecognition
  };
};
