import { Box, Grid, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { Contact } from '../../../../../../../components/Contact';
import { OfficeLink } from '../../../../../../../components/OfficeLink';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.name &&
        <>
          <Grid item xs={4}>
            <Typography>
              Contact Name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.name}
            </Typography>
          </Grid>
        </>
      }

      {params?.office_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Office name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <OfficeLink noWrap variant="h5" office={params.office_id} />
          </Grid>
        </>
      }

      {params?.company_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Company name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <CompanyLink
                noWrap
                size="md"
                variant="h5"
                company={params?.company_id}
              />
            </Box>
          </Grid>
        </>
      }

      {params?.email &&
        <>
          <Grid item xs={4}>
            <Typography>
              Email:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Contact
              variant="h5"
              color="primary"
              type="mailto"
              contact={params?.email}
            />
          </Grid>
        </>
      }
    </Grid>
  );
};
