export const linksBySystemTypesMap = {
  'files_list': () => '/files',
  'settings_profile': () => '/profile',
  'cases_list': () => '/cases',
  'profile': () => '/profile',
  'patients_list': () => '/patients',
  'calendar_appointments_list': () => '/appointments',
  'calendar_schedule_list': () => '/schedule',
  'emails_list': () => '/emails',
  'emails_saved_emails': () => '/emails/saved',
  'emails_rules': () => '/emails/rules',
  'contacts_companies_list': () => '/contacts/companies',
  'contacts_offices_list': () => '/contacts/offices',
  'contacts_location_list': () => '/contacts/locations',
  'contacts_user_list': () => '/contacts/users',
  'codes_procedure_codes': () => '/codes/procedure-codes',
  'codes_financial_codes': () => '/billing/system-settings/codes/financial-class',
  'tasks_list': () => '/tasks',
  'videos_list': () => '/videos',
  'analytics': () => '/analytics',
  'billing_dashboard': () => '/analytics/dashboard',
  'billing_invoices': () => '/billing/invoices',
  'billing_payments': () => '/billing/payments',
  'claims_list': () => '/billing/claims',
  'billing_settings': () => '/billing/system-settings',
  'members_list': () => '/members',
  'system_settings_tags': () => '/files/system-settings/tags',
  'system_settings_company_types': () => '/contacts/system-settings/companies-types',
  'system_settings_time_track_categories': () => '/billing/system-settings/timetrack-category',
  'chat': () => '/messenger'
};
