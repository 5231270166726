import { useContext, useEffect } from 'react';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../helpers/dom';
import { CompanyLink } from '../../../../../CompanyLink';
import { UserLink } from '../../../../../UserLink';
import { SavedFiltersContext } from '../../../../../saved-filters';
import { ListRow, ListRowCell } from '../../../../../ListRow';
import { IconButton } from '../../../../../IconButton';
import { Loader } from '../../../../../Loader';
import { orderByLabelsMap } from '../../orderByOptions';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell grow flexBasis={columnsWidths.company}>
        <CompanyLink noWrap company={params?.company_id} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.user}>
        <UserLink
          noWrap
          size="sm"
          user={params.user_id}
        />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateFrom}>
        {params?.date_from ? moment(params.date_from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateTo}>
        {params?.date_to ? moment(params.date_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.orderBy}>
        {params?.order_by ? orderByLabelsMap[params?.order_by?.value] : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

