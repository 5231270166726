import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { hasRole } from '../../../../../utils/hasRole';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { Divider } from '../../../../Divider';
import {
  InsurancesSelect,
  PhoneField,
  TextField
} from '../../../../FormField';
import {
  transformToOption
  // eslint-disable-next-line max-len
} from '../../../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider/selectFieldHelpers';
import {
  PhysicianFormInfo
} from '../../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/PhysicianFormInfo';
import {
  insuranceFormTypesMap
} from '../../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/insuranceFormTypesMap';
import { UsersSelect } from '../../../../users';
import { EmergencyFormContact as GuestEmergencyFormContact } from '../../../guestForms';
import { DoiForm, EmergencyFormContact, formStatusesMap } from '../../index';
import { InsuranceButton } from '../../InsuranceButton';

export const InsuranceInfo = ({ isUnconfirmed = false }) => {
  const { values, setFieldValue } = useFormikContext();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const user = values?.patient?.id ? { ...values?.patient, medical: values?.medical } : null;

  const handleInsuranceChange = (insurance) => {
    setFieldValue('attorney_id', insurance?.attorney || null);
    setFieldValue('primary_insuranceId', insurance?.insuranceId || null);
    setFieldValue('groupID', insurance?.groupId || null);
    setFieldValue('primary_insurance_holder', insurance?.insurance_company?.name || null);
    setFieldValue('insuranceInfo', insurance);
  };

  useEffect(() => {
    if (values?.emergency_contact?.relationship) {
      setFieldValue('emergency_relationship',  transformToOption(values?.emergency_contact?.relationship));
    }
  }, [ values?.emergency_contact?.relationship ]);

  return (
    <>
      <Grid item xs={12}>
        <Typography color="secondary" variant="h5">
          Doctor info
        </Typography>
      </Grid>

      <PhysicianFormInfo disableSelect xl={4} />

      <Grid item xs={12}>
        <Typography color="secondary" variant="h5">
          Attorney
        </Typography>
      </Grid>

      <DoiForm />

      {isUnconfirmed && !values?.patient?.id && (
        <>
          <Grid item xs={12}>
            <Typography color="secondary" variant="h5">
              Information from the patient
            </Typography>
          </Grid>

          <Grid item xl={4} lg={4} md={8} sm={6} xs={12}>
            <TextField
              disabled
              name="primary_insurance_name"
              label="Law Office"
            />
          </Grid>

          <Hidden only={[ 'sm', 'xs' ]}>
            <Grid item xl={8} lg={8} md={4} />
          </Hidden>

          <Grid item xl={4} md={4} sm={6} xs={12}>
            <TextField
              disabled
              name="attorney_first_name"
              label="Attorney First Name"
            />
          </Grid>

          <Grid item xl={4} md={4} sm={6} xs={12}>
            <TextField
              disabled
              name="attorney_last_name"
              label="Attorney Last Name"
            />
          </Grid>

          <Grid item xl={4} md={4} sm={6} xs={12}>
            <PhoneField
              disabled
              name="attorney_phone"
              label="Phone"
            />
          </Grid>

          <Grid item xl={4} md={4} sm={6} xs={12}>
            <TextField
              disabled
              name="attorney_email"
              label="Email"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider disableBottomGutter gutter={1} type="dotted" />
          </Grid>
        </>
      )}

      {!values?.is_confirmed && (
        <InsuranceButton user={user} />
      )}

      {(!!values?.patient?.id || !!values?.primary_insurance_id || !!values?.primary_insurance?.id) && (
        <Grid item xl={4} lg={4} md={6} xs={12}>
          <InsurancesSelect
            isCreatable
            required={!hasRole(rolesMap.patient)}
            disabled={hasRole(rolesMap.patient) || (isUnconfirmed && !values?.patient?.id)}
            name="primary_insurance_id"
            label="Law Office"
            user={user}
            params={{
              only_active: 1,
              type_insurance: insuranceFormTypesMap?.[values?.form_type],
              status_type: formStatusesMap.law_office,
              medical_id: values?.medical_id || values?.patient?.medical?.id || values?.medical?.id || null
            }}
            insuranceInfo={{
              ...values?.insuranceInfo,

              address: values.home
            }}
            onChange={handleInsuranceChange}
          />
        </Grid>
      )}

      {isAuthenticated && !!values?.primary_insurance_id && (
        <Grid item xl={4} lg={4} md={6} xs={12}>
          <UsersSelect
            isCreatable
            name="attorney_id"
            label="Attorney"
            params={{
              role: 'advocate',
              office_id: values?.primary_insurance?.office_id || values?.insuranceInfo?.office?.id || null
            }}
            creatablePayload={{ role: rolesMap.advocate }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {values?.medical?.id || values?.medical_id ? <EmergencyFormContact /> : <GuestEmergencyFormContact />}
      </Grid>
    </>
  );
};
