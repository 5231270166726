import { createHookReducer } from '../../../../../helpers/createHookReducer';
import _ from 'lodash';
import { initialState } from './initialState';
import * as types from './types';

export const toggleActive = (categories, payload) => {
  categories?.map((item) => {
    if (item.id === payload.id) {
      item.is_active = !payload.is_active;
    }

    if (item.parent_id === payload.id) {
      item.is_active = payload.is_active;
    }

    return item?.children?.length ? toggleActive(item.children, payload) : item;
  });
};

export const reducer = createHookReducer({
  [types.CATEGORIES_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.CATEGORIES_FETCH_SUCCESS]: (
    { filter, categories, ...state },
    { data, pagination: { page, per_page, total, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page, total },
      categories: data
    };
  },

  [types.RESET_CATEGORIES]: ({ filter, ...state }, newFilter) => {
    const prevFilter = _.isEqual(newFilter, { ...initialState.filter }) && newFilter;

    return {
      ...state,

      isFetched: false,
      filter: prevFilter || { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.TOGGLE_ACTIVATE_CATEGORY]: ({ categories, ...state }, payload) => {
    toggleActive(categories, payload);

    return { ...state, categories };
  }
});
