import { useContext, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../components/TablePagination';
import { Loader } from '../../../../../components/Loader';
import { Cards } from '../../../../../components/Cards';
import { InsurancesContext } from '../../../ProfilePage/insurance';
import { Card } from './Card';

export const Grid = () => {
  const { isFetched, meta, insurances, resetInsurances } = useContext(InsurancesContext);
  const scrollElementRef = useRef();

  return (
    <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
        <Loader p={3} loading={!isFetched} render={
          () => !insurances.length ? (
            <Box p={3}>
              <Typography align="center">No insurances found</Typography>
            </Box>
          ) : (
            <>
              <Cards
                size={280}
                list={insurances}
                component={Card}
              />
              <TablePagination borderTop pagination={meta} onChange={resetInsurances} />
            </>
          )}
        />
      </CustomScrollbars>
    </Box>
  );
};
