import { useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { TextField } from '../../../../../../components/FormField';
import { IconButton } from '../../../../../../components/IconButton';
import { Fab } from '../../../../../../components/Fab';
import { useModal } from '../../../../../../components/ModalsProvider';
import { initialValues } from '../initialValues';
import { Medications } from '../Medications';

export const Prescription = () => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  useEffect(() => {
    if (!!values?.prescriptions?.length) {
      values.prescriptions.map((item, i) => (
        !values?.prescriptions[i]?.approved_id &&
          setFieldValue(`prescriptions[${i}].approved_id`, values?.provider?.id || null)
      ));
    }
  }, [ values?.prescriptions?.length, values?.provider ]);

  return (
    <Box my={6}>
      <FieldArray
        name="prescriptions"
        render={({ remove, push }) => (
          <>
            <Box mb={2} color="info.main" display="flex" alignItems="center">
              <Typography variant="h3" color="inherit">
                Prescriptions
              </Typography>

              <Box component="span" ml={2}>
                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon/>}
                  onClick={() => push(initialValues)}
                >
                  Add
                </Fab>
              </Box>
            </Box>

            {values?.prescriptions?.map((item, i) => (
              <Box display="flex" flexWrap="nowrap" mb={3} key={i}>
                <Box mt={3.5} mr={0.5} alignSelf="flex-start">
                  <Typography variant="h5" component="span">{i + 1}.</Typography>
                </Box>

                <Box flex={1} px={0.5}>
                  <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={6}>
                      <TextField
                        required
                        name={`prescriptions[${i}].prescription`}
                        label="Prescription #"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Medications
                        index={i + 1}
                        name={`prescriptions[${i}].medications`}
                        values={values?.prescriptions[i]?.medications}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {values.prescriptions.length > 1 &&
                  <Box mt={3} alignSelf="flex-start">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={handleClearField(remove, i)}
                    >
                      <ClearIcon fontSize="small"/>
                    </IconButton>
                  </Box>
                }
              </Box>
            ))}
          </>
        )}
      />
    </Box>
  );
};
