import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';

export const eventConfirmationMap = {
  yes: 'yes',
  no: 'no',
  maybe: 'maybe'
};

export const eventConfirmationProps = {
  [eventConfirmationMap.yes]: {
    color: 'success',
    icon: CheckCircleIcon,
    title: 'Participation status: Confirmed'
  },

  [eventConfirmationMap.no]: {
    color: 'error',
    icon: CancelIcon,
    title: 'Participation status: Denied'
  },

  [eventConfirmationMap.maybe]: {
    color: 'warning',
    icon: HelpIcon,
    title: 'Participation status: Maybe'
  }
};
