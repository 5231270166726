import { WidgetActivity } from '../fields/WidgetActivity';
import { fieldsMap } from './fieldsMap';

export const TimeTrackActivity = ({
  isDeleted = false,
  fieldLabel,
  activityLabel,
  payload
}) => {
  if (isDeleted) {
    return `Removed ${fieldLabel}`;
  }

  return (
    <WidgetActivity
      {...payload?.properties}
      fieldLabel={fieldLabel}
      before={{ text: 'text', ...(payload?.properties?.before || {}) }}
      after={{ ...(payload?.properties?.after || {}), parent: payload?.properties?.parent }}
      activityLabel={activityLabel}
      fieldsMap={fieldsMap}
    />
  );
};
