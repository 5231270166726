import moment from 'moment';
import { api } from '../../api';

export const transformReport = (data) => {
  if (!data) {
    return data;
  }

  if (data?.appointment?.time) {
    data.appointment.time = moment.duration(+data.appointment.time, 's').asMinutes();
  }

  return data;
};

export const fetchTestReports = (config) => {
  return api.get('/medical-report/lab-test-reports', config)
    .then(({ data }) => {
      data.data.forEach(transformReport);

      return data;
    })
    .catch((error) => { throw error.data; });
};

export const fetchTestReport = (id, config) => {
  return api.get(`/medical-report/lab-test-reports/${id}`, config)
    .then(({ data }) => transformReport(data))
    .catch((error) => { throw error.data; });
};

export const createTestReport = (data) => {
  return api.post('/medical-report/lab-test-reports', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massCreateTestReports = (data) => {
  return api.post('/medical-report/lab-test-reports/bulk', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateTestReport = ({ id, ...data }) => {
  return api.put(`/medical-report/lab-test-reports/${id}`, data)
    .then(({ data: { data } }) => transformReport(data))
    .catch((error) => { throw error.data; });
};

export const deleteTestReport = (id) => {
  return api.delete(`/medical-report/lab-test-reports/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteTestReports = (IDs) => {
  return api.delete('/medical-report/lab-test-reports/', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
