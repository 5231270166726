import { Box, Grid, Typography } from '@material-ui/core';

export const MedicInfo = ({ medicalInfo }) => {
  return (
    <Box pt={4}>
      <Box color="info.main">
        <Typography variant="button" component="h3" color="inherit">
          Title (medical data)
        </Typography>
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            POS
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {medicalInfo?.pos?.code} - {medicalInfo?.pos?.description}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            NPI
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {medicalInfo?.npi}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            CLIA
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {medicalInfo?.clia}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Medicare ID
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {medicalInfo?.medicare}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Medicaid ID
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {medicalInfo?.medicaid}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Mammog ID
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {medicalInfo?.mammog}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
