import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_WORK_SESSIONS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_WORK_SESSIONS_SUCCESS]: (
    { filter, workSessions, ...state },
    { data: newWorkSession, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      workSessions: (page > 1
        ? workSessions.filter(({ id }) => {
          return !newWorkSession.find((loadedClaim) => id === loadedClaim.id);
        }).concat(newWorkSession)
        : newWorkSession
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_WORK_SESSIONS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_WORK_SESSION]: (state, updatedWorkSession) => {
    return {
      ...state,

      workSessions: state.workSessions.map((workSession) => {
        return workSession.id === updatedWorkSession.id ? { ...workSession, ...updatedWorkSession } : workSession;
      })
    };
  },

  [types.ADD_WORK_SESSION]: (state, workSession) => {
    const { pagination, filter, workSessions } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((workSessions.length + 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      workSessions: [ workSession, ...workSessions ]
    };
  },

  [types.DELETE_WORK_SESSION]: (state, workSessionID) => {
    const { pagination, filter, workSessions, selectedWorkSessionsIDs } = state;
    const filteredWorkSessions = workSessions.filter(({ id }) => workSessionID !== id);

    if (filteredWorkSessions.length === workSessions.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((workSessions.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      workSessions: filteredWorkSessions,
      selectedWorkSessionsIDs: selectedWorkSessionsIDs.filter((id) => workSessionID !== id)
    };
  },

  [types.ADD_WORK_SESSIONS_TO_SELECTED]: ({
    selectedWorkSession,
    selectedWorkSessionsIDs,

    ...state
  }, workSessions) => {
    return {
      ...state,

      selectedWorkSession: selectedWorkSession.concat(
        workSessions.filter(({ id }) => !selectedWorkSessionsIDs.includes(id))
      ),
      selectedWorkSessionsIDs: [ ...new Set(selectedWorkSessionsIDs.concat(workSessions.map(({ id }) => id))) ]
    };
  },

  [types.DELETE_WORK_SESSIONS_FROM_SELECTED]: ({ selectedWorkSessionsIDs, ...state }, workSessionsIDs) => {
    return {
      ...state,

      selectedWorkSessionsIDs: selectedWorkSessionsIDs.filter((id) => workSessionsIDs.indexOf(id) === -1)
    };
  },

  [types.UPDATE_TRACK]: ({ workSessions, ...state }, updatedTrack) => {
    return {
      ...state,

      workSessions: workSessions.map((session) => {
        return session.id !== updatedTrack.session.id ? session : {
          ...session,
          ...updatedTrack.session,

          session_tracks: session.session_tracks.map((track) => {
            return updatedTrack.id === track.id ? updatedTrack : track;
          })
        };
      })
    };
  },

  [types.DELETE_TRACK]: ({ workSessions, ...state }, { workSessionId, trackId }) => {
    return {
      ...state,

      workSessions: workSessions.map((session) => {
        return session.id !== workSessionId ? session : {
          ...session,

          session_tracks: session.session_tracks.filter((track) => track.id !== trackId)
        };
      })
    };
  },

  [types.ADD_TRACK]: ({ workSessions, ...state }, { workSessionId, track }) => {
    return {
      ...state,

      workSessions: workSessions.map((session) => {
        return session.id !== workSessionId ? session : {
          ...session,

          session_tracks: [ ...(session.session_tracks || []), track ]
        };
      })
    };
  }
});
