import * as authApi from '../../api/auth';
import { setProfileData } from '../dashboard/profile';
import { fetchUserStorage } from '../globalUser/operations';
import { loginFail, loginRequest, loginSuccess, loginNeedConfirmation } from './actions';

export const login = (user) => (dispatch) => {
  dispatch(loginRequest());

  return authApi.login(user).then((data) => {
    const { token, user } = data;

    if (data.email_confirmation_required) {
      dispatch(loginNeedConfirmation({ token }));

      return data;
    }

    dispatch(loginSuccess({ token }));
    dispatch(setProfileData(user));
    dispatch(fetchUserStorage());

    return data;
  }).catch(({ data }) => {
    dispatch(loginFail());

    throw data;
  });
};

export const loginConfirmation = (data) => (dispatch) => {
  dispatch(loginRequest());

  return authApi.loginConfirmation(data).then((data) => {
    const { token, user } = data;

    dispatch(loginSuccess({ token }));
    dispatch(setProfileData(user));
    dispatch(fetchUserStorage());

    return data;
  }).catch(({ data }) => {
    dispatch(loginFail());

    throw data;
  });
};
