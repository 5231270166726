/* eslint-disable no-template-curly-in-string */

import * as Yup from 'yup';
import { dateFormat, phone, url, unique } from './methods';

Yup.addMethod(Yup.date, 'format', dateFormat);
Yup.addMethod(Yup.string, 'phone', phone);
Yup.addMethod(Yup.string, 'url', url);
Yup.addMethod(Yup.array, 'unique', unique);

Yup.setLocale({
  mixed: {
    required: 'Field is required',
    notType: function notType(_ref) {
      if (_ref.type === 'date') {
        return 'Please enter a valid date';
      }

      return 'Wrong format';
    }
  },
  string: {
    min: 'Min length is ${min}',
    max: 'Max length is ${max}',
    email: 'Invalid email address',
    url: 'Must be a valid URL'
  },
  number: {
    min: 'Min value is ${min}',
    max: 'Max value is ${max}',
    integer: 'Must be a integer number',
    positive: 'Must be a positive number'
  }
});

export { Yup };
