import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { NestedList } from '../../../../../../../components/NestedList';
import { CheckboxesComponent } from '../CheckboxesComponent';

export const ReviewSymptoms = ({ isOpenSwitches, form }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(form?.forms?.review_of_symptoms) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Review of Symptoms"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box p={isSmallScreen ? 1 : 3}>
        <CheckboxesComponent
          type={form.form_type}
          symptoms={get(form, 'forms.review_of_symptoms', {})}
        />
      </Box>
    </NestedList>
  );
};
