import { useContext, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { InfiniteGridLoader } from '../../../../../../components/InfiniteLoader';
import { ClaimsContext } from '../../../../../../components/claims';
import { Loader } from '../../../../../../components/Loader';
import { Card } from './Card';

export const Grid = ({ isEqual = false, isWidgets = false, filters = {}, view }) => {
  const {
    isFetched,
    isFetching,
    claims,
    filter,
    loadNextPage,
    resetClaims,
    applyFilter
  } = useContext(ClaimsContext);
  const scrollElementRef = useRef();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (isWidgets) {
      applyFilter(filters);
    }
  }, [ isEqual, filters ]);

  useEffect(() => {
    resetClaims();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1, height: '100%' }}>
      <Box py={isTablet ? 1 : 2} pl={isTablet ? 1 : 2} pr={isTablet ? 0 : 2}>
        <Loader loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No claims found</Typography>
          ) : (
            <InfiniteGridLoader
              items={claims}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={filter}
              CellComponent={Card}
              CellProps={{ view }}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={270}
              cellHeight={288}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
