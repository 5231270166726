import * as types from './cardsTypes';

export const extraTypes = [
  types.SUBSTITUTED_IN,
  types.DIRECT_REFERRAL,
  types.CASE_CLOSURE,
  types.CASE_CROSS,
  types.CASE_RE_OPENED
];

export const checkedWidgetsTypes = {
  [types.PARTICIPANTS]: true,
  [types.FILES]: true,
  [types.INSURANCE]: true,
  [types.SUBSTITUTED_IN]: true,
  [types.DIRECT_REFERRAL]: true,
  [types.CASE_CLOSURE]: true,
  [types.CASE_CROSS]: true,
  [types.CASE_RE_OPENED]: true
};

export const cardsLayout = {
  breakpoints: { xl: 1440, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  cols: { xl: 24, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  toolbox: { xl: [], lg: [], md: [], sm: [], xs: [], xxs: [] },
  layouts: {
    xl: [
      {
        w: 16,
        h: 10,
        x: 0,
        y: 0,
        i: types.INSURANCE,
        minW: 16,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 27,
        x: 16,
        y: 0,
        i: types.PARTICIPANTS,
        minW: 8,
        maxW: 24,
        minH: 10,
        maxH: 27,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 0,
        y: 10,
        i: types.SUBSTITUTED_IN,
        minW: 8,
        maxW: 8,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 8,
        y: 10,
        i: types.DIRECT_REFERRAL,
        minW: 8,
        maxW: 8,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 17,
        i: types.CASE_CLOSURE,
        minW: 16,
        maxW: 24,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 0,
        y: 27,
        i: types.CASE_CROSS,
        minW: 8,
        maxW: 8,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 8,
        y: 27,
        i: types.CASE_RE_OPENED,
        minW: 8,
        maxW: 8,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 34,
        i: types.FILES,
        minW: 16,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    lg: [
      {
        w: 8,
        h: 10,
        x: 0,
        y: 0,
        i: types.INSURANCE,
        minW: 8,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 27,
        x: 8,
        y: 0,
        i: types.PARTICIPANTS,
        minW: 4,
        maxW: 12,
        minH: 10,
        maxH: 31,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 10,
        i: types.SUBSTITUTED_IN,
        minW: 4,
        maxW: 4,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 4,
        y: 10,
        i: types.DIRECT_REFERRAL,
        minW: 4,
        maxW: 4,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 17,
        i: types.CASE_CLOSURE,
        minW: 8,
        maxW: 12,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 27,
        i: types.CASE_CROSS,
        minW: 4,
        maxW: 4,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 4,
        y: 27,
        i: types.CASE_RE_OPENED,
        minW: 4,
        maxW: 4,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 34,
        i: types.FILES,
        minW: 8,
        maxW: 12,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    md: [
      {
        w: 6,
        h: 10,
        x: 0,
        y: 0,
        i: types.INSURANCE,
        minW: 6,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 27,
        x: 6,
        y: 0,
        i: types.PARTICIPANTS,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 31,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 3,
        h: 7,
        x: 0,
        y: 10,
        i: types.SUBSTITUTED_IN,
        minW: 3,
        maxW: 3,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 3,
        h: 7,
        x: 3,
        y: 10,
        i: types.DIRECT_REFERRAL,
        minW: 3,
        maxW: 3,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 17,
        i: types.CASE_CLOSURE,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 3,
        h: 7,
        x: 0,
        y: 27,
        i: types.CASE_CROSS,
        minW: 3,
        maxW: 3,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 3,
        h: 7,
        x: 3,
        y: 27,
        i: types.CASE_RE_OPENED,
        minW: 3,
        maxW: 3,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 34,
        i: types.FILES,
        minW: 6,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    sm: [
      {
        w: 6,
        h: 10,
        x: 0,
        y: 0,
        i: types.INSURANCE,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 10,
        i: types.PARTICIPANTS,
        minW: 4,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 3,
        h: 7,
        x: 0,
        y: 20,
        i: types.SUBSTITUTED_IN,
        minW: 3,
        maxW: 3,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 3,
        h: 7,
        x: 3,
        y: 20,
        i: types.DIRECT_REFERRAL,
        minW: 3,
        maxW: 3,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 27,
        i: types.CASE_CLOSURE,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 3,
        h: 7,
        x: 0,
        y: 37,
        i: types.CASE_CROSS,
        minW: 3,
        maxW: 3,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 3,
        h: 7,
        x: 4,
        y: 37,
        i: types.CASE_RE_OPENED,
        minW: 3,
        maxW: 3,
        minH: 7,
        maxH: 7,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 44,
        i: types.FILES,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],

    xs: [
      {
        w: 4,
        h: 10,
        x: 0,
        y: 0,
        i: types.INSURANCE,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 10,
        i: types.PARTICIPANTS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 6,
        x: 0,
        y: 20,
        i: types.SUBSTITUTED_IN,
        minW: 2,
        maxW: 2,
        minH: 6,
        maxH: 6,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 6,
        x: 2,
        y: 20,
        i: types.DIRECT_REFERRAL,
        minW: 2,
        maxW: 2,
        minH: 6,
        maxH: 6,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 26,
        i: types.CASE_CLOSURE,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 6,
        x: 0,
        y: 36,
        i: types.CASE_CROSS,
        minW: 2,
        maxW: 2,
        minH: 6,
        maxH: 6,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 6,
        x: 2,
        y: 36,
        i: types.CASE_RE_OPENED,
        minW: 2,
        maxW: 2,
        minH: 6,
        maxH: 6,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 42,
        i: types.FILES,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],

    xxs: [
      {
        w: 2,
        h: 10,
        x: 0,
        y: 0,
        i: types.INSURANCE,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 10,
        i: types.PARTICIPANTS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 20,
        i: types.SUBSTITUTED_IN,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 30,
        i: types.DIRECT_REFERRAL,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 50,
        i: types.CASE_CLOSURE,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 60,
        i: types.CASE_CROSS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 70,
        i: types.CASE_RE_OPENED,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 80,
        i: types.FILES,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ]
  }
};
