import { Autocomplete } from '../../../../FormField';

export const callTypeLabelsMap = {
  null: 'All',
  0: 'Direct',
  1: 'Group'
};

export const callTypeOptions = [
  {
    value: null,
    label: callTypeLabelsMap[null]
  },
  {
    value: 0,
    label: callTypeLabelsMap[0]
  },
  {
    value: 1,
    label: callTypeLabelsMap[1]
  }
];

export const CallTypeSelect = (props) => {
  return (
    <Autocomplete
      disableSearch
      placeholder="Select type..."
      options={callTypeOptions}

      {...props}
    />
  );
};
