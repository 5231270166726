import { useContext, useEffect } from 'react';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { makeStyles, List as MuiList, Typography, Box } from '@material-ui/core';
import { TablePagination } from '../../../../../../components/TablePagination';
import { Loader } from '../../../../../../components/Loader';
import { PaymentsContext } from '../../../../../../components/billing/payments';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 60;
export const columnsWidths = {
  payer: 150,
  number: 110,
  due_date: 90
};

export const List = () => {
  const classes = useStyles();
  const {
    payments,
    isFetched,
    meta,
    resetPayments
  } = useContext(PaymentsContext);

  useEffect(() => {
    resetPayments();
  }, []);

  return (
    <div className={classes.root}>
      <Loader loading={!isFetched} p={3} render={
        () => (!payments?.length ?
          <Box p={3}>
            <Typography align="center">No payments found</Typography>
          </Box>
          :
          <>
            <TableHeader/>

            <CustomScrollbars style={{ flexGrow: 1 }}>
              <MuiList disablePadding>
                {payments.map((payment) => (
                  <Row
                    key={payment.id}
                    payment={payment}
                    invoice={payment.causer_invoice || payment.invoice || null}
                  />
                ))}
              </MuiList>
            </CustomScrollbars>

            <TablePagination borderTop disablePerPage pagination={meta} onChange={resetPayments} />
          </>
        )}
      />
    </div>
  );
};
