import { Box, Typography } from '@material-ui/core';
import { todoFieldsMap } from './fieldsMaps';
import { WidgetActivity } from './WidgetActivity';

export const TaskChecklistField = ({
  isDeleted = false,
  fieldLabel,
  activityLabel,
  payload
}) => {
  if (isDeleted) {
    return (
      <>
        Removed Checklist Item
        &nbsp;-&nbsp;
        <Typography variant="subtitle1">{payload?.text}</Typography>
      </>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <WidgetActivity
        {...payload?.properties}
        fieldLabel={fieldLabel}
        activityLabel={activityLabel}
        fieldsMap={todoFieldsMap}
      />
    </Box>
  );
};
