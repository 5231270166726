export const orderByMap = {
  updated_at: 'updated_at',
  created_at: 'created_at',
  dos: 'dos',
  status: 'status',
  total: 'total',
  paid: 'paid',
  balance: 'balance'
};

export const orderByLabelsMap = {
  [orderByMap.updated_at]: 'Updated Date',
  [orderByMap.created_at]: 'Created Date',
  [orderByMap.dos]: 'DOS',
  [orderByMap.status]: 'Status',
  [orderByMap.total]: 'Charge Amount',
  [orderByMap.paid]: 'Total Amount',
  [orderByMap.balance]: 'Claim Balance'
};

export const orderByOptions = [
  { label: orderByLabelsMap.updated_at, value: orderByMap.updated_at },
  { label: orderByLabelsMap.created_at, value: orderByMap.created_at },
  { label: orderByLabelsMap.dos, value: orderByMap.dos },
  { label: orderByLabelsMap.status, value: orderByMap.status },
  { label: orderByLabelsMap.total, value: orderByMap.total },
  { label: orderByLabelsMap.paid, value: orderByMap.paid },
  { label: orderByLabelsMap.balance, value: orderByMap.balance }
];
