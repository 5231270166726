import { Box, Grid, Typography } from '@material-ui/core';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { Contact } from '../../../../../../../components/Contact';
import { Divider } from '../../../../../../../components/Divider';
import { VenueContent } from '../../../../VenueContent';

export const BillingContent = ({ company }) => {
  return (
    <Box pt={3} px={2}>
      <Box color="info.main">
        <Typography variant="button" component="h3" color="inherit">
          Billing Details
        </Typography>
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Address
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <AddressLink variant="h5" address={company?.billing?.full_address}>
            {company?.billing?.full_address}
          </AddressLink>
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={1} component={Box} py={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Phone
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            noWrap
            needIcon
            variant="h5"
            type="tel"
            contact={company?.billing?.phone}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Fax
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            noWrap
            needIcon
            variant="h5"
            type="tel"
            contact={company?.billing?.fax}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Mob number
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            noWrap
            needIcon
            variant="h5"
            type="tel"
            contact={company?.billing?.mobile_phone}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={1} component={Box} py={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Email
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            noWrap
            needIcon
            variant="h5"
            type="mailto"
            contact={company?.billing?.email}
          />
        </Grid>
      </Grid>

      <VenueContent venue={company?.venue || []} />
    </Box>
  );
};
