import { SvgIcon } from '@material-ui/core';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import { mdiClipboardTextClockOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { WorkSessions } from './WorkSessions';
import { TimeReports } from './TimeReports';

export const routes = [
  {
    exact: true,
    path: '/work-sessions',
    icon: AlarmOnOutlinedIcon,
    label: 'Work Sessions',
    component: WorkSessions
  },
  {
    path: '/work-sessions/time-reports',
    icon: () => <SvgIcon><Icon path={mdiClipboardTextClockOutline} /></SvgIcon>,
    label: 'Time Reports',
    component: TimeReports
  }
];
