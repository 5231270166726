import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { FormControlLabel, Grid, Typography, Box } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { rolesMap } from '../../../../dataMaps';
import { hasRole } from '../../../../utils/hasRole';
import {
  TextField,
  Checkbox,
  OfficesLocationSelect,
  ApptBooksSelect,
  ApptTypesSelect,
  VisitReasonsSelect,
  ArrivalStatusesSelect,
  transformTimeZoneToOption
} from '../../../FormField';

export const AppointmentDetails = ({ appointmentType, disableApptBook }) => {
  const { values, setFieldValue } = useFormikContext();
  const { pathname } = useLocation();
  const [ apptTypesOptions, setApptTypesOptions ] = useState();
  const appointmentTypeID = values?.appointment_type_id?.value || values?.appointment_type_id;
  const disableTypes = pathname.includes('/medical-report');
  const currentUser = useSelector(({ profile }) => profile.user);
  const provider = disableTypes && currentUser?.role === rolesMap.doctor ? currentUser : null;

  const handleApptTypeChange = (apptType) => {
    setFieldValue('appointment_type', apptType);

    if (apptType?.time) {
      const duration = moment.duration(apptType?.time || 0, 'seconds');

      setFieldValue('_hours', duration.hours() || null);
      setFieldValue('_minutes', duration.minutes() || null);
    }
  };

  const handleApptBookChange = (apptBook) => {
    const office = apptBook?.office;
    const provider = apptBook?.provider;
    const defaultAppointmentType = appointmentType || apptBook?.default_appointment_type;
    const arrivalStatus = apptBook?.default_arrival_status;
    const timezone = apptBook?.office?.timezone;
    const types = apptBook?.appointment_types?.reduce((types, { is_current_active, type }) => {
      return is_current_active ? types?.concat(type) : types;
    }, []);

    setApptTypesOptions(types?.length ? types : undefined);
    setFieldValue('appointment_book', apptBook);

    if (apptBook && defaultAppointmentType) {
      setFieldValue('appointment_type_id', defaultAppointmentType);

      if (defaultAppointmentType?.time) {
        const duration = moment.duration(defaultAppointmentType?.time || 0, 'seconds');

        setFieldValue('_hours', duration.hours() || null);
        setFieldValue('_minutes', duration.minutes() || null);
      }
    } else if (!disableTypes && !apptBook) {
      setFieldValue('appointment_type_id', null);
    }

    if (provider) {
      setFieldValue('provider', provider);
    }

    if (timezone) {
      setFieldValue('timezone', transformTimeZoneToOption(timezone));
    }

    if (office) {
      setFieldValue('office_id', office);
      setFieldValue('office', office);
    }

    if (arrivalStatus) {
      setFieldValue('arrival_status_id', arrivalStatus);
      setFieldValue('arrival_status', arrivalStatus);
    }
  };

  const handleArrivalStatusChange = (arrivalStatus) => {
    if (arrivalStatus) {
      setFieldValue('arrival_status', arrivalStatus);
    }
  };

  useEffect(() => {
    const types = values?.appointment_book?.appointment_types?.map(({ type }) => {
      return type;
    });

    setApptTypesOptions(types?.length ? types : undefined);
  }, []);

  return (
    <>
      <Box mt={6} mb={2} color="info.main">
        <Typography variant="h3" color="inherit">
          Appointment Details
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <VisitReasonsSelect
            name="visit_reason_id"
            label="Visit Reason"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          {!disableApptBook &&
            <ApptBooksSelect
              required
              isCreatable
              creatablePayload={{
                initialValues: {
                  disableProvider: disableTypes && provider?.id,
                  showTypesSelect: disableTypes,
                  provider_id: provider || null,
                  default_appointment_type_id: appointmentType,
                  appointment_types: !appointmentType ? [] : [ {
                    appointment_type_id: appointmentType?.id,
                    is_current_active: 1
                  } ]
                }
              }}
              params={{
                appointment_types: !appointmentTypeID ? [] : [ appointmentTypeID ]
              }}
              name="appointment_book_id"
              onChange={handleApptBookChange}
            />
          }
        </Grid>

        <Grid item xs={12}>
          <OfficesLocationSelect
            required
            disabled={hasRole('client', 'patient')}
            name="office_id"
            label="Office"
            params={!values.provider?.id ? {} : { users: [ values.provider?.id ] }}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <ApptTypesSelect
            required
            options={apptTypesOptions || undefined}
            onChange={handleApptTypeChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <ArrivalStatusesSelect
            required
            disabled={hasRole('client', 'patient')}
            name="arrival_status_id"
            onChange={handleArrivalStatusChange}
          />
        </Grid>

        <Grid item container>
          <Grid item sm={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={hasRole('client', 'patient')}
                  name="appointment_confirmed_by_office"
                />
              }
              label="Appointment confirmed by office"
            />
          </Grid>

          {!hasRole('client', 'patient') &&
            <>
              <Grid item sm={6} xs={12}>
                <FormControlLabel
                  control={<Checkbox name="eligibility_manually_verified" />}
                  label="Eligibility manually verified"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox name="do_not_bill_claim" />}
                  label="Do not bill claim"
                />
              </Grid>
            </>
          }

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" mt={2} mb={0.5} color="text.secondary">
              <NotesIcon fontSize="small" color="inherit" />
              <Typography color="inherit">Notes</Typography>
            </Box>

            <TextField
              multiline
              disableUnderline
              rows={2}
              rowsMax={8}
              variant="filled"
              name="note"
            />
          </Grid>

          <Grid item xs={12}>
            <Box mt={2} mb={0.5}>
              <Typography color="textSecondary">Chief complaint</Typography>
            </Box>

            <TextField
              multiline
              disableUnderline
              rows={3}
              rowsMax={8}
              variant="filled"
              name="chief_complaint"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
