import { Autocomplete } from '../../../../FormField';
import { periodsOptions } from './periodsMap';

export const PeriodsSelect = (props) => {
  return (
    <Autocomplete
      label="Period"
      placeholder="Select period..."
      options={periodsOptions}

      {...props}
    />
  );
};
