import { api } from '../api';

export const fetchFilters = (config = {}) => {
  return api.get('/filters', config)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const fetchFilter = (id) => {
  return api.get(`/filters/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const createFilter = (filterData) => {
  return api.post('/filters', filterData)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const updateFilter = (updatedFilter) => {
  return api.put(`/filters/${updatedFilter.id}`, updatedFilter)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const deleteFilter = (id) => {
  return api.delete(`/filters/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};
