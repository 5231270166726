import { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import * as pages from '../../../../../../store/dashboard/layout/pages';
import { Widgets } from '../../../../../../components/Widgets';
import { UserContext } from '../../UserProvider';
import { cardsLayout, checkedWidgetsTypes, extraTypes } from './Layouts';
import { widgetsMap } from './Widgets';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const GeneralInfo = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  return (
    <div className={classes.root}>
      <Widgets
        rowHeight={25}
        page={pages.userProfile}
        extraTypes={extraTypes}
        checkedWidgetsTypes={checkedWidgetsTypes}
        widgetsMap={widgetsMap}
        cardsLayout={cardsLayout}
        payload={{ user }}
      />
    </div>
  );
};
