import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Button } from '../../../../../../components/Button';
import { ColorPreview } from '../../../../../../components/ColorPreview';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import { useModal } from '../../../../../../components/ModalsProvider';
import { ScheduleEventTypeFormModal } from '../../ScheduleEventTypeFormModal';
import { ScheduleTypesContext } from '../../ScheduleTypesProvider';
import { columnsWidths } from '../List';

export const Row = ({ item: eventType, isLoaded, recalculateHeight }) => {
  const { openModal } = useModal();
  const eventTypesContext = useContext(ScheduleTypesContext);
  const [ isBusy, setIsBusy ] = useState(false);

  const toggleActiveEventType = () => {
    const data = { ...eventType, is_active: !eventType.is_active };

    setIsBusy(true);

    eventTypesContext.updateActiveEventTypeReq(data).then(() => {
      setIsBusy(false);
    });
  };

  const editEventType = () => {
    openModal(ScheduleEventTypeFormModal, {
      payload: {
        initialValues: eventType
      },

      onModalResolved: (eventType) => {
        eventTypesContext.updateActiveEventType(eventType);
      }
    });
  };

  const deleteEventType = () => {
    setIsBusy(true);

    eventTypesContext.deleteEventType(eventType).then(() => {
      setIsBusy(false);
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return (
    <Loader fullWidth p={isBusy ? 0 : 2} surface={isBusy} loading={!isLoaded || isBusy} render={
      () => (
        <ListRow disabled={!eventType.is_active}>
          <ListRowCell grow actions flexBasis={columnsWidths.code}>
            <ColorPreview color={eventType.color} />

            <span>
              {eventType.name}
            </span>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.description}>
            {eventType.description || '-'}
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.duration}>
            {eventType.duration
              ? moment.duration(eventType.duration, 'seconds').format('h[h] m[min]')
              : '-'
            }
          </ListRowCell>

          <ListRowCell actions noDisable justifyContent="flex-end" flexBasis={columnsWidths.actions}>
            <Button
              variant="contained"
              color={eventType.is_active ? 'warning' : 'success'}
              size="small"
              onClick={toggleActiveEventType}
            >
              {eventType.is_active ? 'Inactive' : 'Active'}
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={editEventType}
            >
              Edit
            </Button>

            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={deleteEventType}
            >
              Delete
            </Button>
          </ListRowCell>
        </ListRow>
      )}
    />
  );
};
