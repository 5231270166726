import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import { caseInsuranceMap, caseInsuranceTypes } from '../../../../../../../dataMaps';
import { Divider } from '../../../../../../../components/Divider';
import {
  CompaniesAutocomplete,
  TextField,
  KeyboardDatePicker,
  CurrencyField,
  ClaimInsuranceTypeSelect,
  PhoneField
} from '../../../../../../../components/FormField';
import { Header } from './InsuranceContent/Header';

export const EditInsuranceContent = () => {
  const { values, setFieldValue } = useFormikContext();
  const valueOfInsurance = caseInsuranceMap.map((item) => item.value);
  const [ insuranceIndex, setInsuranceIndex ] = useState(0);

  const handleFieldChanged = (option) => {
    setInsuranceIndex(option ? valueOfInsurance.findIndex((item) => item === option.value) + 1 : 0);
  };

  const handleCompanyChange = (company) => {
    setFieldValue('claim_insurance.claim_info_address', company?.full_address || null);
    setFieldValue('claim_insurance.claim_info_phone', company?.phone || null);
  };

  useEffect(() => {
    if (values?.claim_insurance?.claim_insurance_type) {
      const index = valueOfInsurance.findIndex(
        (item) => (
          item === (values?.claim_insurance?.claim_insurance_type?.value
            || values?.claim_insurance?.claim_insurance_type)
        )
      );

      setInsuranceIndex(index < 0 ? 0 : index + 1);
    }
  }, []);

  useEffect(() => {
    if (values?.claim_insurance?.claim_insurance_type === caseInsuranceTypes.other) {
      setFieldValue('claim_insurance.insurance_company_id', null);
    }
  }, [ values.claim_insurance?.claim_insurance_type ]);

  return (
    <>
      <Header />

      <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={3}>
        <Typography variant="h4">Claim Insurance Information</Typography>

        <Box flexGrow={1} ml={4}>
          <Divider type="dashed" />
        </Box>
      </Box>

      <ClaimInsuranceTypeSelect
        fullWidth
        isClearable
        name="claim_insurance.claim_insurance_type"
        TextFieldProps={{ required: true, margin: 'dense' }}
        onChange={handleFieldChanged}
      />

      {insuranceIndex > 0 &&
        <>
          {insuranceIndex === 4 ?
            <>
              <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={1}>
                <Typography variant="h4">Explain</Typography>

                <Box flexGrow={1} ml={4}>
                  <Divider type="dashed" />
                </Box>
              </Box>

              <TextField
                fullWidth
                multiline
                rows={6}
                variant="filled"
                name="claim_insurance.insurance_carrier"
              />
            </>
            :
            <>
              <Grid container spacing={2}>
                {(insuranceIndex === 2 || insuranceIndex === 3) &&
                  <Grid item xs={3}>
                    <CurrencyField
                      required
                      label="Company deductible amount"
                      name="claim_insurance.amount"
                      margin="dense"
                    />
                  </Grid>
                }

                <Grid item xs={9}>
                  <CompaniesAutocomplete
                    required
                    name="claim_insurance.insurance_company_id"
                    label="Name of Insurance Company"
                    placeholder="Select company..."
                    margin="dense"
                    onChange={handleCompanyChange}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                {insuranceIndex !== 3 &&
                  <>
                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        clearable
                        outputFormat="YYYY-MM-DD"
                        name="claim_insurance.policy_period_start"
                        label="Policy period start"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        clearable
                        outputFormat="YYYY-MM-DD"
                        name="claim_insurance.policy_period_finish"
                        label="Policy period finish"
                        margin="dense"
                      />
                    </Grid>
                  </>
                }

                <Grid item xs={8}>
                  <TextField
                    disabled
                    fullWidth
                    label="Address"
                    name="claim_insurance.claim_info_address"
                    margin="dense"
                  />
                </Grid>

                <Grid item xs={4}>
                  <PhoneField
                    disabled
                    name="claim_insurance.claim_info_phone"
                    label="Tel number"
                    margin="dense"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={1}>
                    <Typography variant="h4">Insurance carrier/TPA Information</Typography>

                    <Box flexGrow={1} ml={4}>
                      <Divider type="dashed" />
                    </Box>
                  </Box>

                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="filled"
                    margin="dense"
                    name="claim_insurance.insurance_carrier"
                  />
                </Grid>
              </Grid>
            </>
          }
        </>
      }
    </>
  );
};
