import { useContext } from 'react';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '../../../../../components/Fab';
import { useModal } from '../../../../../components/ModalsProvider';
import { PrintersConfigurationContext } from '../PrintersConfigurationProvider';
import { AddConfigurationModal } from '../AddConfigurationModal';

export const AddConfigurationButton = () => {
  const { openModal } = useModal();
  const { addPrinter } = useContext(PrintersConfigurationContext);

  const addConfiguration = () => {
    openModal(AddConfigurationModal, {
      onModalResolved: (printer) => {
        addPrinter(printer);
      }
    });
  };

  return (
    <Box p={2}>
      <Fab
        variant="extended"
        color="primary"
        startIcon={<AddIcon />}
        onClick={addConfiguration}
      >
        Add Printer configuration
      </Fab>
    </Box>
  );
};
