import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ActionButtons } from '../../../../../../../components/medical/forms';
import * as formsApi from '../../../../../../../api/profile';
import { Divider } from '../../../../../../../components/Divider';
import { Loader } from '../../../../../../../components/Loader';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import { CheckboxesComponent } from '../../CheckboxesComponent';
import * as types from '../../MedicalFormsProvider/types';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ReviewContent = () => {
  const classes = useStyles();
  const params = useParams();
  const {
    form,
    type,
    isFetched,
    handleNext,
    handleBack
  } = useContext(MedicalFormItemContext);
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [ symptoms, setSymptoms ] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updateReviewOfSymptoms(values).then(({ data }) => {
      dispatchRedux({ type: types.UPDATE_FORM, payload: data });
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleNext(form.id);
    }).catch(({ errors }) => {
      setErrors(errors);
      enqueueSnackbar('Form not updated', { variant: 'error' });
    });
  };

  useEffect(() => {
    formsApi.getFormSymptoms().then((data) => setSymptoms(data));
  }, []);

  return (
    <Loader loading={!isFetched && !symptoms} render={
      () => (
        <Formik
          enableReinitialize
          initialValues={{
            form_id: form.id,
            forms: {
              review_of_symptoms: form?.forms?.review_of_symptoms || {}
            }
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <div className={classes.mainContent}>
                <Typography className={classes.formTitle}>
                  Review of Symptoms
                </Typography>

                <div className={classes.content}>
                  <Typography variant={isSmallScreen ? 'h5' : 'h6'} className={classes.title}>
                    1. If you currently have any of the following symptoms, please check mark any that apply:
                  </Typography>

                  <CheckboxesComponent type={type} symptoms={symptoms} />
                </div>
              </div>

              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
