import { FormControlLabel, Grid, InputAdornment } from '@material-ui/core';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import {
  Checkbox,
  CompaniesAutocomplete,
  CurrencyField,
  TextField
} from '../../../../../components/FormField';

export const Body = () => {
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item sm={8} xs={12}>
        <TextField
          required
          fullWidth
          name="name"
          label="Name"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <CurrencyField
          name="rate"
          label="Rate"
        />
      </Grid>

      <Grid item sm={12} xs={12}>
        <FormControlLabel
          control={<Checkbox name="is_active" size="small" color="primary"/>}
          label="Active"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <CasesSelect
          name="case_id"
          label="Case"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <CompaniesAutocomplete
          label="Company"
          name="company_id"
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField
          type="number"
          name="hours"
          label="Hours"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">h</InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField
          required
          type="number"
          name="minutes"
          label="Minutes"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">min</InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  );
};
