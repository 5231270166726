import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, useMediaQuery } from '@material-ui/core';
import * as workClockApi from '../../../api/work-clocks';
import { Loader } from '../../Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../Modal';
import { UsersSelect } from '../../users';
import { Body } from './Body';
import { getValidationSchema } from './getValidationSchema';

export const CreateWorkClocksModal = ({
  DialogProps,
  payload: { workSession = {} },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return workClockApi.createTimer(values).then((track) => {
      enqueueSnackbar('Track successfully created', { variant: 'success' });
      handleModalResolve(track);
    }).catch(() => {
      enqueueSnackbar('Track not created', { variant: 'error' });
    });
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <Formik
        validateOnMount
        initialValues={{
          user_id: workSession.user_id || null,
          type: null,
          note: null,
          started_at: null,
          finished_at: null,
          session_id: workSession.id || null
        }}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Create
            </ModalHeader>

            <ModalBody>
              {!workSession.user_id && (
                <UsersSelect name="user_id" label="User" />
              )}

              <Body />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
