import { useContext } from 'react';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { UsersSelect } from '../../../../../../components/users';
import { TemplatesContext } from '../TemplatesProvider';

const initialValues = {
  provider_id: null
};

export const FiltersBar = ({ disableResponsiveView }) => {
  const { applyFilter } = useContext(TemplatesContext);

  const handleUserSelect = (option) => {
    applyFilter({ 'provider_id': option?.id });
  };

  return (
    <FiltersBarComponent
      disableResponsiveView={disableResponsiveView}
      disableTopPaddings
      isResetForm
      border={0}
      initialValues={initialValues}
      fields={[
        <UsersSelect
          name="provider_id"
          label="Provider"
          params={{ role: 'doctor' }}
          onChange={handleUserSelect}
          margin="dense"
          minWidth={210}
        />
      ]}
    />
  );
};
