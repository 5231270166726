import { useContext, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as taskTemplatesApi from '../../../../../../api/cases/task-templates';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { IconButton } from '../../../../../../components/IconButton';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import { useModal } from '../../../../../../components/ModalsProvider';
import { EditTaskTemplateModal } from '../../EditTaskTemplateModal';
import { TaskTemplatesContext } from '../../TaskTemplatesProvider';
import { minRowHeight, columnsWidths } from '../List';

export const Row = ({ isLoaded, item: template, recalculateHeight }) => {
  const { updateTaskTemplate, deleteTaskTemplate } = useContext(TaskTemplatesContext);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const openPreview = () => {
    // openModal(, {
    //   payload: {}
    // });
  };

  const handleEdit = () => {
    openModal(EditTaskTemplateModal, {
      payload: {
        template
      },

      onModalResolved: (template) => {
        updateTaskTemplate(template);
      }
    });
  };

  const handleDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        taskTemplatesApi.deleteTaskTemplate(template.id).then(() => {
          deleteTaskTemplate(template);
          enqueueSnackbar('Template successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Template not deleted', { variant: 'error' });
        });
      }
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button minHeight={minRowHeight} onClick={openPreview}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        {template.name}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.createdDate}>
        {moment.unix(template.created_at).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updatedDate}>
        {moment.unix(template.updated_at).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        <ListRowCellActions>
          <IconButton color="primary" onClick={handleEdit}>
            <EditIcon />
          </IconButton>

          <IconButton edge="end" color="error" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
