import { useFormikContext } from 'formik';
import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { InvoicesContextProvider } from '../../../Invoices/InvoicesContext';
import { FiltersBar, List } from '../../../Invoices/InvoicesPage/InvoicesContent/List';
import { Header } from '../Header';
import { PaymentForm } from '../PaymentForm';
import { FilterHeader } from './FilterHeader';
import { SelectedInvoices } from './SelectedInvoices';
import { styles } from './styles';

export const widthBreakpointSmall = 1100;
export const minRowHeight = 72;
export const columnsWidths = {
  number: 300,
  invoice_date: 150,
  due_on: 170,
  total: 120,
  amount: 170,
  balance: 120
};

const useStyles = makeStyles(styles);

export const MainCreateContent = ({
  state,
  width,
  selectedInvoices,
  setSelectedInvoices
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { values } = useFormikContext();
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();
  const initialState = {
    filter: {
      is_confirmed: 1,
      is_paid: 0,
      model_to_type: values?.payer?.type || null,
      model_to_id: values?.payer?.id || null
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" flexGrow={1} pb={3} overflow="hidden">
      <FilterHeader />
      <Header amount={values.amount} />

      <PaymentForm
        parentPage="create"
        width={width}
        invoice={state}
        selectedInvoices={selectedInvoices}
      />

      {selectedInvoices?.length > 0 &&
        <div className={classes.wrapper}>
          <Typography paragraph variant="h2">
            Selected invoice
          </Typography>

          <SelectedInvoices width={width} selectedInvoices={selectedInvoices} />
        </div>
      }

      {values?.payer?.info &&
        <div className={classes.wrapper}>
          <Typography paragraph={!isMobile} variant="h2">
            Choose invoice
          </Typography>

          <InvoicesContextProvider initialState={initialState}>
            {isMobile && (
              <Box py={0.5} px={2} display="flex" justifyContent="flex-end" alignItems="center" flexGrow={1}>
                {filterOpenButton}
              </Box>
            )}

            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                <FiltersBar paymentForm setSelectedInvoices={setSelectedInvoices} />
              </FilterBarWrapper>
            </ScrollWrapper>

            <List
              paymentForm
              modelID={values?.payer?.id || null}
              modelType={values?.payer?.type || null}
              selectedInvoices={selectedInvoices}
              setSelectedInvoices={setSelectedInvoices}
            />
          </InvoicesContextProvider>
        </div>
      }
    </Box>
  );
};
