export const styles = ({
  spacing,
  breakpoints
}) => ({
  root: {
    display: 'block',
    height: '100%',
    padding: spacing(3, 3, 2),

    [breakpoints.down('md')]: {
      padding: spacing(1.5)
    }
  },

  loader: {
    padding: spacing(2),

    [breakpoints.down('md')]: {
      padding: spacing(1)
    }
  },

  mainContent: {
    [breakpoints.down('md')]: {
      padding: spacing(1)
    }
  },

  formContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%'
  },

  formContent__block: {
    marginTop: spacing(),
    marginBottom: spacing(),
    padding: spacing(2, 2, 3, 2),
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.1)',

    '&:last-child': {
      display: 'flex',
      alignItems: 'flex-start'
    },

    [breakpoints.down('md')]: {
      padding: spacing(2)
    }
  }
});
