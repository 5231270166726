import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import {
  DatePicker,
  IcdCodesSelect,
  OfficesLocationSelect,
  RadioGroup,
  TextField
} from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { UserSignature } from '../../../../../components/UserSignature';

export const Body = () => {
  const { values, setFieldValue } = useFormikContext();
  const currentUser = useSelector(({ profile }) => profile.user);

  const handleChangeProvider = () => {
    setFieldValue('office_id', null);
  };

  const handleSign = () => {
    setFieldValue('signature', currentUser?.signature || null);
    setFieldValue('signature_id', currentUser?.signature?.id || null);
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <UsersSelect
            required
            name="patient_id"
            params={{ is_patient: 1 }}
            label="Patient"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            name="name"
            label="Name"
            placeholder="Enter name..."
          />
        </Grid>

        <Grid item xs={12}>
          <UsersSelect
            required
            name="provider_id"
            params={{ role: rolesMap.doctor }}
            label="Physician"
            onChange={handleChangeProvider}
          />
        </Grid>

        <Grid item xs={12}>
          <OfficesLocationSelect
            required
            label="Office"
            name="office_id"
            params={{ users: values.provider_id ? [ values.provider_id ] : null }}
          />
        </Grid>

        <Grid item xs={12}>
          <IcdCodesSelect
            multiple
            label="Diagnosis"
            name="icd_code_ids"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography>
            Generic equivalent acceptable:
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <RadioGroup
            name="options.generic_equivalent_acceptable"
            style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
          >
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12}>
          <TextField name="options.refill" label="Refill" />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            multiline
            disableUnderline
            variant="filled"
            name="text"
            label="Describe"
            rows={4}
            rowsMax={8}
          />
        </Grid>

        <Grid item md={6} sm={12}>
          <DatePicker
            required
            outputFormat="YYYY-MM-DD"
            name="date"
            label="Date"
          />
        </Grid>
      </Grid>

      <UserSignature
        isSignature={!!values?.signature?.id}
        signature={values.signature}
        onSign={handleSign}
      />
    </>
  );
};
