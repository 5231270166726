import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { makeStyles, Tab, Tabs, AppBar, Typography, Box } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlined';
import { useLocation } from 'react-router-dom';
import { setTasksLastGlobalAction } from '../../../../../store/globalActions';
import { CommentsContextProvider, Comments } from '../../../../../components/Comments';
import * as taskActionTypes from '../../../../../components/tasks/TasksProvider/types';
import { Activities, ActivityProvider } from '../../../../../components/Activities';
import { TaskPreviewContext } from '../../../../../components/tasks/TasksProvider';
import { FilesFilterContextProvider, FilesContextProvider, Attachments } from '../../../files-common';
import { ChecklistContextProvider } from '../../Checklist/ChecklistContex';
import { Checklist } from '../../Checklist';
import { PreviewTaskTabContainer } from '../PreviewTaskTabContainer';
import { tabsMap } from '../taskMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PreviewTaskActivity = ({
  isStaticPage,
  defaultTab = tabsMap.checklist,
  count
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [ activeTab, setActiveTab ] = useState(defaultTab);
  const { selectedTask } = useContext(TaskPreviewContext);

  const handleTabIndexChange = (event, index) => {
    setActiveTab(index);
  };

  const initialStateForComments = {
    filter: {
      parent_type: 'task',
      parent_id: selectedTask.id
    }
  };

  const initialStateForChecklist = {
    checklist: selectedTask.todo_list,
    filter: {
      parent_type: 'task',
      parent_id: selectedTask.id
    }
  };

  const initialStateOfCaseFiles = {
    isFetched: true,
    filter: {
      cases: selectedTask.parent && [ selectedTask.parent.id ]
    }
  };

  const onCommentAdded = (comments) => {
    dispatch(setTasksLastGlobalAction({
      type: taskActionTypes.UPDATE_TASK_IN_LIST,
      payload: { ...selectedTask, comments_count: comments.length }
    }));
  };

  useEffect(() => {
    state && setActiveTab(state.activeTab);
  }, [ state?.activeTab ]);

  return (
    <div className={cn(classes.root, isStaticPage && classes.root_static)}>
      <AppBar position="static">
        <Tabs
          scrollButtons="off"
          variant="scrollable"
          value={activeTab}
          onChange={handleTabIndexChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            value={tabsMap.checklist}
            className={classes.actionBtn}
            label={
              <div className={classes.counterTab}>
                {selectedTask?.todo_list?.length > 0 &&
                  <div className={classes.counterTab__count}>
                    <CheckBoxIcon fontSize="small"/>
                    {count}/{selectedTask.todo_list.length}
                  </div>
                }
                Checklist
              </div>
            }
          />
          <Tab value={tabsMap.comment} className={classes.actionBtn} label="Comments" />
          <Tab value={tabsMap.activity} className={classes.actionBtn} label="Activity" />
          <Tab value={tabsMap.worklogs} className={classes.actionBtn} label="Worklogs" />
          <Tab value={tabsMap.case_files} className={classes.actionBtn} label="Case Files" />
        </Tabs>
      </AppBar>

      {activeTab === tabsMap.checklist &&
        <PreviewTaskTabContainer>
          <ChecklistContextProvider initialState={initialStateForChecklist}>
            <Checklist
              count={count}
              task={selectedTask}
              parentType="task"
              parentID={selectedTask.id}
            />
          </ChecklistContextProvider>
        </PreviewTaskTabContainer>
      }

      {activeTab === tabsMap.comment &&
        <PreviewTaskTabContainer>
          <CommentsContextProvider initialState={initialStateForComments}>
            <Comments
              maxHeight={isStaticPage ? 400 : 300}
              parentType="task"
              parentID={selectedTask.id}
              onCommentAdded={onCommentAdded}
              CreationFormProps={{ TextFieldProps: { multiline: true } }}
            />
          </CommentsContextProvider>
        </PreviewTaskTabContainer>
      }

      {activeTab === tabsMap.activity &&
        <PreviewTaskTabContainer>
          <ActivityProvider filter={{ model: 'task', model_id: selectedTask.id }}>
            <Box display="flex" height={isStaticPage ? 500 : 400} overflow="hidden">
              <Activities
                parentType="task"
                parent={selectedTask}
              />
            </Box>
          </ActivityProvider>
        </PreviewTaskTabContainer>
      }

      {activeTab === tabsMap.worklogs && <PreviewTaskTabContainer>Worklogs</PreviewTaskTabContainer>}

      {activeTab === tabsMap.case_files &&
        <PreviewTaskTabContainer>
          {selectedTask.parent ?
            <FilesFilterContextProvider>
              <FilesContextProvider initialState={initialStateOfCaseFiles}>
                <Box display="flex" height={isStaticPage ? 500 : 400}>
                  <Attachments />
                </Box>
              </FilesContextProvider>
            </FilesFilterContextProvider>
            :
            <Typography align="center">No attachments</Typography>
          }
        </PreviewTaskTabContainer>
      }
    </div>
  );
};
