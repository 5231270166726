import { useContext, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import * as statisticsApi from '../../../../../../../api/billing';
import { api } from '../../../../../../../api';
import { Loader } from '../../../../../../../components/Loader';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { StatisticsFilterContext } from '../../../StatisticsFilterProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = () => {
  const classes = useStyles();
  const [ state, setState ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const { filter: commonFilter } = useContext(StatisticsFilterContext);
  const cancelFetch = useRef(() => {});

  const fetchStatistics = (newFilter = {}) => {
    cancelFetch.current();
    setIsFetched(false);

    statisticsApi.fetchBillingAndPaymentStatistics({
      params: { ...commonFilter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    })
      .then((data) => {
        setState(data);
        setIsFetched(true);
      });
  };

  useEffect(() => {
    fetchStatistics(commonFilter);

    return () => {
      cancelFetch.current();
    };
  }, [ commonFilter ]);

  return (
    <Loader loading={!isFetched} render={
      () => (
        <div className={classes.root}>
          <div className={classes.mainContent}>
            <div className={classes.content}>
              <div className={classes.contentTitle}>
                <Typography className={classes.title}>
                  Billed
                </Typography>

                <Typography className={classes.counter}>
                  {state.billed.count}
                </Typography>
              </div>

              <CurrencyFormat value={state.billed.total} className={classes.content__text} />
            </div>

            <div className={classes.content}>
              <div className={classes.contentTitle}>
                <Typography className={classes.title}>
                  Payments
                </Typography>

                <Typography className={classes.counter}>
                  {state.payments.count}
                </Typography>
              </div>

              <CurrencyFormat value={state.payments.total} className={classes.content__text} />
            </div>
          </div>

          <div className={classes.mainInfo}>
            <div className={classes.mainInfoContent}>
              <Typography className={classes.mainInfoContent__title}>
                Overdue
              </Typography>

              <CurrencyFormat
                value={state.overdue}
                className={cn(classes.mainInfoContent__sum, classes.mainInfoContent__redSum)}
              />
            </div>

            <div className={classes.mainInfoContent}>
              <Typography className={classes.mainInfoContent__title}>
                Open
              </Typography>

              <CurrencyFormat value={state.open} className={classes.mainInfoContent__sum} />
            </div>
          </div>
        </div>
      )}
    />
  );
};
