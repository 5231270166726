import { useState, useContext } from 'react';
import { IconButton, makeStyles, Box } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ContentCard } from '../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../components/WidgetsActionButtons';
import { StatisticsFilterContext } from '../../StatisticsFilterProvider';
import { FilteredMainContent } from './FilteredMainContent';
import { MainContent } from './MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const BillableTimeCard = ({
  isOpen,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const classes = useStyles();
  const [ isFetchedData, setIsFetchedData ] = useState(false);
  const { filter: commonFilter } = useContext(StatisticsFilterContext);
  const [ filterType, setFilterType ] = useState({
    user_id: null,
    is_billed: null
  });
  const title = filterType.user_id && (
    filterType.is_billed === null ? 'Total time card' :
      filterType.is_billed ? 'Billable time card' : 'Unbillable time card'
  );

  const handleResetFilterType = () => {
    setFilterType(({
      user_id: null,
      is_billed: null
    }));
    setIsFetchedData(false);
  };

  const initialFilter = {
    filter: {
      ...filterType,
      ...commonFilter
    }
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      disableScrollbars
      isResizable={isOpen}
      title={isFetchedData ? title : 'Time tracking utilization (Hours)'}
      leftActions={[
        isFetchedData && (
          <IconButton
            color="primary"
            className={classes.actionContentIcon}
            onClick={handleResetFilterType}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        )
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box height={height || '100%'}>
        {isFetchedData ?
          <FilteredMainContent initialFilter={initialFilter}/>
          :
          <MainContent
            setIsFetchedData={setIsFetchedData}
            setFilterType={setFilterType}
          />
        }
      </Box>
    </ContentCard>
  );
};
