export const sidesOptions = [
  {
    value: 'left',
    label: 'Left'
  },
  {
    value: 'right',
    label: 'Right'
  },
  {
    value: 'bilateral',
    label: 'Bilateral'
  }
];
