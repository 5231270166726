import { useSnackbar } from 'notistack';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useState } from 'react';
import * as chatsApi from '../api/chat';
import { ChatModal } from '../components/chat/ChatWidget/Chat/ChatModal';
import { useModal } from '../components/ModalsProvider';
import { eventBus, eventBusEvents } from './eventBus';

export const useChatActions = () => {
  const theme = useTheme();
  const { openModal } = useModal();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const [ isChatOpening, setIsChatLoading ] = useState(false);

  const openChatWithUser = (userId, isModal = isMobile) => {
    const snackbarKey = enqueueSnackbar('Chat is opening...', { variant: 'info' });
    const chatData = {
      users: [ { id: userId } ]
    };

    setIsChatLoading(true);

    chatsApi.createThread(chatData).then((thread) => {
      if (isModal) {
        openModal(ChatModal, {
          payload: {
            threadID: thread.id
          }
        });
      } else {
        eventBus.dispatch(eventBusEvents.openChatWidget);
        eventBus.dispatch(eventBusEvents.openThreadInWidget, thread.id);
      }
    }).catch(() => {
      enqueueSnackbar('Something goes wrong!', { variant: 'error' });
    }).finally(() => {
      setIsChatLoading(false);
      closeSnackbar(snackbarKey);
    });
  };

  return {
    isChatOpening,
    openChatWithUser
  };
};
