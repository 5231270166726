import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core';
import { Loader } from '../../../../../../../components/Loader';
import { FilePreviewContext } from '../../../FilePreviewContext';
import { PreviewContent } from '../PreviewContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SVG = () => {
  const classes = useStyles();
  const [ svg, setSVG ] = useState('');
  const { selectedFile } = useContext(FilePreviewContext);

  useEffect(() => {
    setSVG(null);

    axios.get(selectedFile?.url).then(({ data }) => {
      setSVG(data);
    });
  }, [ selectedFile?.url ]);

  return (
    <Loader loading={!svg} render={
      () => (
        <PreviewContent
          className={classes.root}
          dangerouslySetInnerHTML={{ __html: svg }}
        />
      )
    }/>
  );
};
