import { Date, Text, Bool, Company, UserActivityLink } from '../../fields';
import { Events } from '../Events';

export const courtInfoFieldsMap = {
  arb: (before, after) => <Date before={before} after={after} fieldLabel="Arb." />,
  arb_future_use: (before, after) => <Date before={before} after={after} fieldLabel="Arb. Future Use" />,
  case_number: (before, after) => <Text before={before} after={after} fieldLabel="Court Case #" />,
  // TDOD: update Events component after BE updates
  events: (before, after) => <Events before={before} after={after} fieldLabel="Events" />,
  court_number: (before, after) => <Text before={before} after={after} fieldLabel="Court Number #" />,
  cmc: (before, after) => <Date before={before} after={after} fieldLabel="CMC" />,
  cmc_future_use: (before, after) => <Date before={before} after={after} fieldLabel="CMC Future Use" />,
  complaint_filled: (before, after) => <Date before={before} after={after} fieldLabel="Complaint Filed" />,
  contact_id: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Judge" />,
  court_id: (before, after) => <Company before={before} after={after} fieldLabel="Court" />,
  def: (before, after) => <Text before={before} after={after} fieldLabel="Def-998" />,
  dep: (before, after) => <Text before={before} after={after} fieldLabel="Dep" />,
  fsc: (before, after) => <Date before={before} after={after} fieldLabel="FSC" />,
  jury_trial: (before, after) => <Bool before={before} after={after} fieldLabel="Jury Trial" />,
  mcs: (before, after) => <Date before={before} after={after} fieldLabel="MSC" />,
  mcs_future_use: (before, after) => <Date before={before} after={after} fieldLabel="MSC Future Use" />,
  mediation: (before, after) => <Date before={before} after={after} fieldLabel="Mediation" />,
  osc: (before, after) => <Date before={before} after={after} fieldLabel="OSC" />,
  plf_sent: (before, after) => <Date before={before} after={after} fieldLabel="PLF-998 Sent" />,
  received: (before, after) => <Date before={before} after={after} fieldLabel="Received" />,
  trial_date: (before, after) => <Date before={before} after={after} fieldLabel="Trial" />
};
