import { Box, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { generateAddress } from '../../../../utils/generateAddress';

export const Label = ({ office = {} }) => {
  const fullAddress = office?.full_address || generateAddress(office);

  return (
    <Typography component="span">
      {office.name}

      {!!fullAddress && (
        <Typography
          component="span"
          variant="body2"
          color="textSecondary"
          title={office?.full_address || generateAddress(office)}
        >
          <Box component="span" ml={0.5}>
            <LocationOnIcon color="inherit" fontSize="small" />
          </Box>

          {office?.full_address || generateAddress(office) || ''}
        </Typography>
      )}
    </Typography>
  );
};
