import { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import * as pages from '../../../../../store/dashboard/layout/pages';
import { Widgets } from '../../../../../components/Widgets';
import { Loader } from '../../../../../components/Loader';
import { UserContext } from '../../../Members/User/UserProvider';
import { cardsLayout, checkedWidgetsTypes, extraTypes } from './ClientLayouts';
import { widgetsMap } from './Widgets/clientWidgetsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ClientMainInfo = () => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const currentUser = useSelector(({ profile }) => profile.user);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : currentUser;

  return (
    <div ref={rootRef} className={classes.root}>
      <Loader loading={!user?.medical?.id} render={
        () => (
          <Widgets
            rowHeight={28}
            page={pages.medicalClientProfile}
            cardsLayout={cardsLayout}
            extraTypes={extraTypes}
            widgetsMap={widgetsMap}
            checkedWidgetsTypes={checkedWidgetsTypes}
          />
        )}
      />
    </div>
  );
};
