import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Box, Button, InputAdornment, Typography } from '@material-ui/core';
import { TextField } from '../../../../../../components/FormField';
import { Loader } from '../../../../../../components/Loader';
import { ReportsContext } from '../../../../../../components/medical/reports';
import {
  MedicalProfileContext
} from '../../../../ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider';
import { validationSchema } from '../validationSchema';
import { StepContext } from '../StepProvider';
import { CurrentSymptoms } from './CurrentSymptoms';
import { Medications } from './Medications';
import { Allergies } from './Allergies';

export const FirstStep = () => {
  const { formValues, handleUpdateForm } = useContext(StepContext);
  const { pathname } = useContext(ReportsContext);
  const medicalContext = useContext(MedicalProfileContext);

  const updateForm = (values, { isSubmitting, setErrors }) => {
    handleUpdateForm({ values, isSubmitting, setErrors });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...formValues,
        doi: formValues?.doi || medicalContext?.medicalProfile ? medicalContext?.medicalProfile?.doi : null
      }}
      validationSchema={validationSchema}
      onSubmit={updateForm}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Box
          noValidate
          component="form"
          display="flex"
          flexDirection="column"
          onSubmit={handleSubmit}
          height="100%"
        >
          <Box flexGrow={1}>
            <Typography paragraph variant="h4" color="secondary">
              Medical Form info
            </Typography>

            <Typography variant="h4">
              Reason for consultation
            </Typography>

            <TextField
              fullWidth
              multiline
              disableUnderline
              rows={2}
              rowsMax={8}
              name="forms.medical_form_info.reason_for_consultation"
              variant="filled"
            />

            <Box mt={3}>
              <Typography variant="h4">
                History of present illness
              </Typography>
            </Box>

            <TextField
              fullWidth
              multiline
              disableUnderline
              rows={3}
              rowsMax={8}
              name="forms.medical_form_info.history_of_present_illness"
              variant="filled"
            />

            <Box mt={3}>
              <Typography variant="h4">
                Past medical history
              </Typography>
            </Box>

            <TextField
              fullWidth
              multiline
              disableUnderline
              rows={2}
              rowsMax={8}
              name="forms.medical_form_info.past_medical_history"
              variant="filled"
            />

            <Box mt={3}>
              <Typography variant="h4">
                Surgeries/Past surgical history
              </Typography>
            </Box>

            <TextField
              fullWidth
              multiline
              disableUnderline
              rows={2}
              rowsMax={8}
              name="forms.medical_form_info.past_surgical_history"
              variant="filled"
            />

            <Box mt={3}>
              <Typography variant="h4">
                Personal history
              </Typography>
            </Box>

            <TextField
              fullWidth
              multiline
              disableUnderline
              rows={2}
              rowsMax={8}
              name="forms.medical_form_info.personal_history"
              variant="filled"
            />

            <Medications />
            <Allergies />

            <CurrentSymptoms
              disableBackBody
              title="Neck"
              name="forms.medical_form_info.current_symptoms_neck"
            />

            <CurrentSymptoms
              title="Back"
              name="forms.medical_form_info.current_symptoms_back"
            />

            <Box display="flex" alignItems="center">
              <Box mr={1} ml={2}>
                <Typography variant="h5">Walking:</Typography>
              </Box>

              <Box width={100}>
                <TextField
                  zeroMinWidth
                  disableNumber
                  type="number"
                  name="forms.medical_form_info.walking"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        minutes
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" alignItems="center" mt={1}>
              <Box mr={1} ml={2}>
                <Typography variant="h5">Standing:</Typography>
              </Box>

              <Box width={100}>
                <TextField
                  zeroMinWidth
                  disableNumber
                  type="number"
                  name="forms.medical_form_info.standing"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        minutes
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </Box>

            <Box display="flex" alignItems="center" mt={1}>
              <Box mr={1} ml={2}>
                <Typography variant="h5">Sitting:</Typography>
              </Box>

              <Box width={100}>
                <TextField
                  zeroMinWidth
                  disableNumber
                  type="number"
                  name="forms.medical_form_info.sitting"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        minutes
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" ml="auto" py={2}>
            <Box mr={2}>
              <Button
                color="primary"
                component={Link}
                to={pathname}
              >
                Back
              </Button>
            </Box>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  disabled={isSubmitting}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              )}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
};
