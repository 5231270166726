import { useContext } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../components/ModalsProvider';
import { PageHeaderActions } from '../../../../../components/Page';
import { Fab } from '../../../../../components/Fab';
import { CreateTemplateModal } from './CreateTemplateModal';
import { TemplatesContext } from './TemplatesProvider';

export const ActionsBar = ({ filterOpenButton }) => {
  const { openModal } = useModal();
  const { createTemplate } = useContext(TemplatesContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTemplateCreate = () => {
    openModal(CreateTemplateModal,  {
      onModalResolved: (value) => {
        createTemplate(value);
      }
    });
  };

  return (
    <>
      <PageHeaderActions disablePosition>
        <Fab
          variant="extended"
          color="primary"
          size={isMobile ? 'small' : 'medium'}
          startIcon={<AddIcon />}
          onClick={handleTemplateCreate}
        >
          Add new
        </Fab>
      </PageHeaderActions>

      <PageHeaderActions endPosition>
        {isMobile && filterOpenButton}
      </PageHeaderActions>
    </>
  );
};
