export const styles = ({ spacing, palette }) => ({
  root: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    marginBottom: spacing(),
    padding: spacing(),
    border: `1px solid ${palette.divider}`,
    borderLeft: `5px solid ${palette.primary.main}`,
    borderRadius: 5,
    cursor: 'pointer'
  }
});
