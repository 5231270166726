import { useContext, useRef, useState } from 'react';
import moment from 'moment';
import { Box, Button, ButtonGroup, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  CaseRemindersContext,
  CreateCaseRemindersModal
} from '../../../../../../../components/cases/reminders/case-reminders';
import { orderDirectionsMap } from '../../../../../../../components/FormField';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { Fab } from '../../../../../../../components/Fab';
import { CaseRemindersList } from './CaseRemindersList';

export const periodsMap = {
  upcoming: orderDirectionsMap.asc,
  past: orderDirectionsMap.desc
};

export const Card = ({
  page,
  createScheduleEventModalProps,
  isCreatable = false,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  hideApplyFields,

  ...props
}) => {
  const { openModal } = useModal();
  const rootRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [ period, setPeriod ] = useState(periodsMap.upcoming);
  const { caseId, applyFilter, resetReminders } = useContext(CaseRemindersContext);

  const createReminders = () => {
    openModal(CreateCaseRemindersModal, {
      payload: {
        caseId
      },

      onModalResolved: () => {
        resetReminders();
      }
    });
  };

  const handlePeriodChange = (newPeriod) => () => {
    if (period !== newPeriod) {
      setPeriod(newPeriod);

      applyFilter({
        date_from: newPeriod === periodsMap.upcoming ? moment().format('YYYY-MM-DD') : null,
        date_to: newPeriod === periodsMap.past ? moment().format('YYYY-MM-DD') : null
      });
    }
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      ref={rootRef}
      disableContentPaddings
      disableScrollbars
      title="Reminders"
      icon={
        <>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Tooltip title="Add Reminders">
              <Fab
                color="primary"
                size="small"
                onClick={createReminders}
              >
                <AddIcon fontSize="small"/>
              </Fab>
            </Tooltip>

            <Box pl={isMobile ? 1 : 2} pr={isMobile ? 0 : 2}>
              <ButtonGroup color="primary" size="small">
                <Button
                  variant={period === periodsMap.upcoming ? 'contained' : 'outlined'}
                  onClick={handlePeriodChange(periodsMap.upcoming)}
                >
                  Upcoming
                </Button>

                <Button
                  variant={period === periodsMap.past ? 'contained' : 'outlined'}
                  onClick={handlePeriodChange(periodsMap.past)}
                >
                  Past
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </>
      }
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}

      {...props}
    >
      <Box display="flex" flexDirection="column" height={isMobile ? height : '100%'} overflow="auto">
        <CaseRemindersList />
      </Box>
    </ContentCard>
  );
};
