import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { CreateMeasurementsModal } from '../../../../../../../components/medical/forms';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { IconButton } from '../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../components/Loader';
import { Fab } from '../../../../../../../components/Fab';
import { MedicalProfileContext } from '../../MedicalProfileProvider';
import { styles } from '../PatientInfo/styles';
import { MainContent } from './MainContent';

const useStyles = makeStyles(styles);

export const Measurements = ({ user }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const [ isOpen, setIsOpen ] = useState(true);
  const { isFetched, medicalProfile } = useContext(MedicalProfileContext);
  const [ initialValues, setInitialValues ] = useState(null);

  const toggleIsOpen = () => setIsOpen((state) => !state);

  const openWidgetModal = () => {
    openModal(CreateMeasurementsModal, {
      payload: {
        medicalID: initialValues.medical_id || initialValues.id,
        user,
        setInitialValues
      }
    });
  };

  const openUpdateWidgetModal = () => {
    openModal(CreateMeasurementsModal, {
      payload: {
        medicalID: initialValues.medical_id || initialValues.id,
        initialValues,
        user,
        setInitialValues
      }
    });
  };

  useEffect(() => {
    isFetched && setInitialValues(medicalProfile);
  }, [ isFetched, medicalProfile ]);

  return (
    <ContentCard
      autoHeightMax={400}
      className={classes.root}
      disableContentPaddings
      isCollapsed={!isOpen}
      title="Measurements"
      icon={
        <Fab
          size="small"
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={openWidgetModal}
        >
          Add
        </Fab>
      }
      rightActions={[
        <IconButton color="inherit" onClick={openUpdateWidgetModal}>
          <EditIcon fontSize="small" />
        </IconButton>,

        <IconButton color="inherit" onClick={toggleIsOpen}>
          {isOpen ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        </IconButton>
      ]}
    >
      <Loader loading={!initialValues} render={() => (<MainContent item={initialValues} />)}/>
    </ContentCard>
  );
};
