import { useFormikContext } from 'formik';
import { FormControlLabel, Grid, Collapse, Box, Typography } from '@material-ui/core';
import {
  Checkbox,
  TextField,
  TOSCodesSelect,
  OfficesLocationSelect,
  RegionCodesSelect
} from '../../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../../components/users';

export const Body = () => {
  const { values, setFieldValue } = useFormikContext();

  const toggleAllItems = (name) => (event) => {
    if (event.target.checked) {
      setFieldValue(name, null);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="procedure_code"
          label="Procedure Code"
          placeholder="Enter code..."
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="description"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <FormControlLabel
          label="Add On"
          control={<Checkbox required name="add_on"/>}
        />

        <FormControlLabel
          label="Enable crosswalk"
          control={<Checkbox required name="enable_crosswalk"/>}
        />

        <Collapse in={!!values.enable_crosswalk} timeout="auto" unmountOnExit>
          <TextField
            name="crosswalk_description"
            label="Crosswalk Description"
            placeholder="Enter code..."
          />

          <TextField
            name="crosswalk_code"
            label="Crosswalk Code"
            placeholder="Enter code..."
          />
        </Collapse>
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          required
          type="number"
          name="base_unit"
          label="Base Unit"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TOSCodesSelect
          required
          name="tos_id"
          label="TOS"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <FormControlLabel
          label="HCPCS Exceptions"
          control={<Checkbox name="hcpcs_exceptions"/>}
        />

        <FormControlLabel
          label="Enable Cross Codes"
          control={<Checkbox name="enable_cross_codes"/>}
        />
      </Grid>

      <Grid item sm={4} xs={6}>
        <OfficesLocationSelect
          required
          params={{ is_medical: 1 }}
          disabled={values?.is_all_offices}
          label={values?.is_all_offices ? 'All offices selected' : 'Office'}
          name="office_id"
        />
      </Grid>

      <Grid item sm={2} xs={6}>
        <Box pt={1.5}>
          <FormControlLabel
            label={<Typography>All offices</Typography>}
            control={
              <Checkbox
                name="is_all_offices"
                color="primary"
                onChange={toggleAllItems('office_id')}
              />
            }
          />
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <RegionCodesSelect
          required
          name="region_code_id"
          label="Medicare region"
        />
      </Grid>

      <Grid item sm={4} xs={6}>
        <UsersSelect
          required
          disabled={values?.is_all_providers}
          name="provider_id"
          label={values?.is_all_providers ? 'All providers selected' : 'Provider'}
          params={{ role: 'doctor' }}
        />
      </Grid>

      <Grid item sm={2} xs={6}>
        <Box pt={1.5}>
          <FormControlLabel
            label={<Typography>All providers</Typography>}
            control={
              <Checkbox
                name="is_all_providers"
                color="primary"
                onChange={toggleAllItems('provider_id')}
              />
            }
          />
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="display_in_box_19"
          label="Display in box 19"
          placeholder="Enter region..."
        />
      </Grid>
    </Grid>
  );
};
