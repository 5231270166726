import { useContext, useState } from 'react';
import printJS from 'print-js';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import AddIcon from '@material-ui/icons/Add';
import { downloadFile } from '../../../../../../../../helpers/files';
import * as documentsApi from '../../../../../../../../api/profile';
import { Loader } from '../../../../../../../../components/Loader';
import { AppBar } from '../../../../../../../../components/AppBar';
import { Fab } from '../../../../../../../../components/Fab';
import { IconButton } from '../../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { VerticalDivider } from '../../../../../../../../components/VerticalDivider';
import { CreateDocumentModal } from '../../CreateDocumentModal';
import { DocumentsContext } from '../../DocumentsProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const actionsMap = {
  print: 'print',
  download: 'download'
};

const addActiveAction = (actions, addingAction) => {
  return actions.concat(addingAction);
};

const removeActiveAction = (actions, removingAction) => {
  return actions.filter((action) => action !== removingAction);
};

export const ActionsBar = () => {
  const classes = useStyles();
  const [ activeActions, setActiveActions ] = useState([]);
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const {
    pagination,
    documents,
    selectedIDs,
    toggleItemSelection,
    ...documentsContext
  } = useContext(DocumentsContext);

  const handleCreateDocuments = () => {
    documentsApi.createMedicalDocuments(params.medicalFormID).then(() => {
      documentsContext.resetDocuments();
      enqueueSnackbar('Successfully created', { variant: 'success' });
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      }
    });
  };

  const handleDownloadPDFArchive = () => {
    setActiveActions((actions) => addActiveAction(actions, actionsMap.download));

    documentsApi.fetchPDFArchive({ params : { document_ids: selectedIDs } }).then((data) => {
      selectedIDs.forEach((id) => {
        toggleItemSelection(id);
      });

      downloadFile({
        src: new Blob([ data ], { type: 'application/zip' }),
        name: 'document.zip'
      });
    }).finally(() => {
      setActiveActions((actions) => removeActiveAction(actions, actionsMap.download));
    });
  };

  const handlePrintDocuments = (event) => {
    event.preventDefault();

    setActiveActions((actions) => addActiveAction(actions, actionsMap.print));

    documentsApi.getPrintPDFArchive({ params : { document_ids: selectedIDs } }).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      selectedIDs.forEach((id) => {
        toggleItemSelection(id);
      });

      printJS({
        printable: url,
        type: 'pdf'
      });
    }).finally(() => {
      setActiveActions((actions) => removeActiveAction(actions, actionsMap.print));
    });
  };

  const handleCreateDocument = () => {
    openModal(CreateDocumentModal, {
      payload: {
        formID: params.medicalFormID,
        fetchDocuments: documentsContext.fetchDocuments
      }
    });
  };

  return (
    <AppBar ToolbarProps={{ className: classes.root }}>
      {documents.length === 0 ?
        <Fab
          color="primary"
          variant="extended"
          onClick={handleCreateDocuments}
        >
          Create Document Package
        </Fab>
        :
        <Fab
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={handleCreateDocument}
        >
          Add document
        </Fab>
      }

      {!!selectedIDs.length &&
        <div className={classes.actionBar}>
          <div>
            <Typography component="b" variant="subtitle2">
              {selectedIDs.length}
            </Typography>
            &nbsp;
            <Typography component="span">
              out of
            </Typography>
            &nbsp;
            <Typography component="b" variant="subtitle2">
              {pagination.total}
            </Typography>
            &nbsp;
            <Typography component="span">
              selected
            </Typography>
          </div>

          <VerticalDivider
            verticalGutters={1}
            horizontalGutters={2}
          />

          <Loader surface loading={activeActions.includes(actionsMap.print)} render={
            () => (
              <Tooltip title="Print selected documents">
                <div>
                  <IconButton
                    disabled={activeActions.includes(actionsMap.print)}
                    onClick={handlePrintDocuments}
                  >
                    <PrintIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          />

          <Loader surface loading={activeActions.includes(actionsMap.download)} render={
            () => (
              <Tooltip title="Download selected documents">
                <div>
                  <IconButton
                    disabled={activeActions.includes(actionsMap.download)}
                    onClick={handleDownloadPDFArchive}
                  >
                    <DownloadIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          />
        </div>
      }
    </AppBar>
  );
};
