import { useEffect } from 'react';
import { Dialog, Typography, Button } from '@material-ui/core';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import { TextField } from '../../../../components/FormField';
import { useVoiceRecognition } from '../../../../utils/useVoiceRecognition';
import { ModalHeader, ModalBody, ModalContainer, ModalFooter } from '../../../../components/Modal';

export const VoiceRecognitionModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve
}) => {
  const { resultText, recognizedText, lastRecognizedText, updateResultText, startRecognition } = useVoiceRecognition();

  const resolveModal = () => {
    handleModalResolve(resultText);
  };

  const handleChange = ({ target: { value } }) => {
    updateResultText(value);
  };

  useEffect(() => {
    startRecognition();
  }, []);

  return (
    <Dialog disableEnforceFocus maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <ModalHeader icon={<RecordVoiceOverOutlinedIcon />} onClose={handleModalReject}>
          Voice Recognition
        </ModalHeader>

        <ModalBody>
          {recognizedText ? (
            <TextField
              multiline
              withoutFormik
              value={resultText + lastRecognizedText}
              onChange={handleChange}
            />
          ) : (
            <Typography align="center">
              Please start your speech to transcribe it to text
            </Typography>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleModalReject}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={resolveModal}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Dialog>
  );
};
