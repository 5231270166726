import { useContext, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import * as statisticsApi from '../../../../../../../api/billing';
import { api } from '../../../../../../../api';
import { Loader } from '../../../../../../../components/Loader';
import { StatisticsFilterContext } from '../../../StatisticsFilterProvider';
import { StatisticsIncomesFilterContext } from '../StatisticsIncomesFilterContext';
import { FiltersBar } from './FiltersBar';
import { Chart } from './Chart';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = () => {
  const classes = useStyles();
  const [ state, setState ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const { filter } = useContext(StatisticsFilterContext);
  const { filter: commonFilter, setFilter } = useContext(StatisticsIncomesFilterContext);
  const [ isOpenPicker, setIsOpenPicker ] = useState(false);
  const [ isOpenYearPicker, setIsOpenYearPicker ] = useState(false);
  const [ message, setMessage ] = useState(moment.unix(commonFilter.from).format('YYYY'));
  const cancelFetch = useRef(() => {});

  const fetchStatistics = () => {
    cancelFetch.current();
    setIsFetched(false);

    statisticsApi.fetchIncomesAndExpenseStatistics({
      params: commonFilter,
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    })
      .then((data) => {
        setState(data);
        setIsFetched(true);
      });
  };

  const resetStatistics = (newFilter) => {
    fetchStatistics(newFilter);
  };

  useEffect(() => {
    setFilter((item) => ({
      ...item,
      ...filter,
      from: commonFilter.from,
      to: commonFilter.to,
      period: commonFilter.period
    }));
  }, [ filter ]);

  useEffect(() => {
    resetStatistics();

    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    if (!isEqual(filter, { ...filter, ...commonFilter })) {
      resetStatistics();
    }
  }, [ filter, commonFilter ]);

  return (
    <div className={classes.root}>
      <FiltersBar
        message={message}
        isOpenPicker={isOpenPicker}
        isOpenYearPicker={isOpenYearPicker}
        setMessage={setMessage}
        setIsOpenPicker={setIsOpenPicker}
        setIsOpenYearPicker={setIsOpenYearPicker}
      />

      <Loader loading={!isFetched} className={classes.loader} render={
        () => (
          <div className={classes.mainContent}>
            <div className={classes.chartContent}>
              <Chart data={state.data} labels={state.labels}/>
            </div>
          </div>
        )}
      />
    </div>
  );
};
