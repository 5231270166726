import { Grid, InputAdornment, useMediaQuery } from '@material-ui/core';
import {
  TextField,
  Autocomplete,
  TaskTypesSelect,
  TaskPrioritySelect
} from '../../../../../components/FormField';
import { caseUserRoles, caseUserSingleRoles } from '../../../../../dataMaps';
import { Checklist } from '../Checklist';
import { dueAtCaseDatesOptions } from './dueAtCaseDatesOptions';

export const TaskTemplateForm = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <Grid container spacing={3} justify="flex-start" alignItems="center">
      <Grid item xs={12}>
        <TextField
          required
          name="name"
          label="Task name"
          placeholder="Enter task name..."
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 6}>
        <TaskPrioritySelect
          name="priority"
          label="Priority"
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 6}>
        <TaskTypesSelect
          name="type_id"
          label="Type"
        />
      </Grid>

      <Grid item xs={isMobile ? 6 : 4}>
        <TextField
          zeroMinWidth
          type="number"
          name="hours"
          label="Hours"
          margin="dense"
          placeholder="Hours"
          InputProps={{
            endAdornment: (<InputAdornment position="end">h</InputAdornment>)
          }}
        />
      </Grid>

      <Grid item xs={isMobile ? 6 : 4}>
        <TextField
          zeroMinWidth
          type="number"
          name="minutes"
          label="Mins"
          margin="dense"
          placeholder="Minutes"
          InputProps={{
            endAdornment: (<InputAdornment position="end">m</InputAdornment>)
          }}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 6}>
        <Autocomplete
          required
          name="owner_case_user_role"
          label="Case user role for owner"
          placeholder="Select role..."
          options={caseUserSingleRoles}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 6}>
        <Autocomplete
          multiple
          name="users_case_user_roles"
          label="Case user roles for members"
          placeholder="Select roles..."
          options={caseUserRoles}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 6}>
        <Autocomplete
          required
          name="due_at_case_date"
          label="Case date as task due date"
          placeholder="Select date..."
          options={dueAtCaseDatesOptions}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 6}>
        <TextField
          zeroMinWidth
          type="number"
          name="due_at_interval"
          label="+ Days"
          margin="dense"
          placeholder="Days"
          InputProps={{
            endAdornment: (<InputAdornment position="end">days</InputAdornment>)
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          rows={8}
          name="description"
          label="Description"
          variant="filled"
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checklist />
      </Grid>
    </Grid>
  );
};
