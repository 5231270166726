export const styles = ({
  spacing,
  palette: { primary },
  shape: { borderRadius },
  breakpoints
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    flexGrow: 1,
    backgroundColor: primary.contrastText,
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    borderRadius,

    [breakpoints.down('md')]: {
      margin: 0
    }
  },

  uploadButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: spacing(2),
    marginBottom: spacing()
  },

  actionButton: {
    marginRight: spacing(2)
  },

  buttonIcon: {
    marginRight: spacing(2)
  },

  filesWindow: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden'
  },

  filesWindow__main: {
    display: 'flex',
    flexGrow: 1
  }
});
