import { Fragment } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { Page, PageBody, PageHeader } from '../../../../../components/Page';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { TemplatesProvider } from './TemplatesProvider';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { List } from './List';

export const PrescriptionsTemplates = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileXs = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileXs));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps
  } = useMobileFilterCollapse('sm');
  const ScrollWrapper = !isMobile ? Fragment : Scrollbars;
  const scrollWrapperProps = !isMobile ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  return (
    <TemplatesProvider>
      <Page>
        <PageHeader>
          <ActionsBar
            actionTitle="Add new"
            filterOpenButton={filterOpenButton}
          />
        </PageHeader>

        <PageBody fullHeight disablePaddings disableScrollbars>
          <Box height="100%" display="flex" flexDirection="column">
            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                <FiltersBar disableResponsiveView={!isMobileXs} />
              </FilterBarWrapper>
            </ScrollWrapper>

            <Box flexGrow={1}>
              <List />
            </Box>
          </Box>
        </PageBody>
      </Page>
    </TemplatesProvider>
  );
};
