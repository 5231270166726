export const styles = ({
  palette: { primary },
  spacing,
  shape: { borderRadius },
  typography: { pxToRem }
}) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'baseline'
  },

  label: {
    position: 'relative',
    top: 4,
    display: 'inline-flex',
    marginRight: spacing(),
    borderRadius
  },

  label__icon: {
    fontSize: pxToRem(18),
    color: primary.main
  }
});
