import { forwardRef } from 'react';
import { fade } from '@material-ui/core/styles';
import cn from 'classnames';
import { makeStyles, Radio as MuiRadio } from '@material-ui/core';
import { withBaseField } from '../withBaseField';

const styles = ({ palette }) => ({
  radio: ({ color }) => palette[color] ? {
    color: palette[color].main,

    '&:hover': {
      backgroundColor: fade(palette[color].main, palette.action.hoverOpacity)
    }
  } : {}
});

const useStyles = makeStyles(styles);

export const Radio = withBaseField(forwardRef(({
  field = {},
  form,
  color,
  className,
  isFormikField,
  error,
  onChange,
  ...props
}, ref) => {
  const classes = useStyles({ color });

  return (
    <MuiRadio
      {...field}
      error={error}
      checked={props.value === '' + field.value}
      className={cn(
        classes.radio,
        className
      )}
      {...props}
      ref={ref}
    />
  );
}));
