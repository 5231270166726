import { useContext } from 'react';
import moment from 'moment';
import { AppointmentViewModal } from '../../../../../components/appointments';
import { useModal } from '../../../../../components/ModalsProvider';
import { WaitingListContext } from '../WaitingListProvider';

export const WaitingListItemContainer = ({ item, WaitingListItem, width }) => {
  const { openModal } = useModal();
  const { updateWaitingListItem, deleteWaitingListItem, fetchWaitingList } = useContext(WaitingListContext);
  const time = item?.appointment_at
    ? moment.unix(item?.appointment_at).diff(moment(), 'hours')
    : 0;

  const waitingTime = time > 24 || time < -24
    ? moment.unix(item?.appointment_at).diff(moment(), 'days') + ' days'
    : time + ' hours';

  const openPreview = () => {
    openModal(AppointmentViewModal, {
      payload: {
        disableApptActions: true,
        onAppointmentUpdate: updateWaitingListItem,
        onWaitingListUpdate: fetchWaitingList,
        onAppointmentDelete: deleteWaitingListItem,
        appointmentID: item.id
      }
    });
  };

  return (
    <WaitingListItem
      item={item}
      time={time}
      width={width}
      waitingTime={waitingTime}
      onAppointmentPreviewOpen={openPreview}
    />
  );
};
