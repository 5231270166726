import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().min(3).max(255),
  description: Yup.string().nullable().max(8000),
  file_number: Yup.string().nullable().max(100),
  case_status_id: Yup.mixed(),
  case_type_id: Yup.mixed(),
  opened_at: Yup.mixed(),
  office_location: Yup.string().nullable().max(255),
  special_project: Yup.string().nullable().max(255),
  venue: Yup.string().nullable().max(255),
  venue_address: Yup.string().nullable().max(255),
  ssn: Yup.string().nullable().max(255),
  occupation_group_number: Yup.string().nullable().max(255),
  statute_date: Yup.date().format('X').nullable(),
  government_claim: Yup.boolean().nullable(),
  claim_filled_at: Yup.date().format('X').nullable(),
  court_number: Yup.string().nullable().min(1).max(50),
  accident_location: Yup.string().nullable().min(3).max(500),
  accident_time: Yup.date().format('YYYY-MM-DD H:m:s').nullable(),
  liability: Yup.string().nullable().min(3).max(1000).trim(),
  incident_type: Yup.mixed().nullable(),
  conditions: Yup.mixed().nullable()
});
