import { useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { makeStyles, Box, Grid, Typography, Link, Tooltip } from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import WorkIcon from '@material-ui/icons/WorkOutlineOutlined';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { AddressLink } from '../../../../AddressLink';
import { apiModelsMap } from '../../../../../dataMaps/apiModels';
import { stopPropagation } from '../../../../../helpers/dom';
import { pxToRem } from '../../../../../themes/typography';
import { Date } from '../../../../Date';
import { Loader } from '../../../../Loader';
import { useModal } from '../../../../ModalsProvider';
import { CaseViewModal } from '../../../../../app/Dashboard/CasesPage/Cases/CaseViewModal';
import { TaskModal } from '../../../../../app/Dashboard/TasksPage/TaskModal';
import { ViewScheduleEventModal } from '../../../ViewScheduleEventModal';
import { Menu } from './Menu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  item: event = {},
  isLoaded,
  hideApplyFields,
  recalculateHeight,
  onEventUpdate = () => {},
  onEventDelete = () => {}
}) => {
  const classes = useStyles({ event });
  const { openModal } = useModal();
  const currentUser = useSelector(({ profile }) => profile.user);
  const isCreator = currentUser.id === event.creator?.id;

  const handleEventClick = () => {
    openModal(ViewScheduleEventModal, {
      payload: {
        hideApplyFields,
        event: event,
        onUpdate: onEventUpdate,
        onDelete: onEventDelete
      }
    });
  };

  const openCasePreview = () => {
    openModal(CaseViewModal, {
      payload: { id: event?.parent_id }
    });
  };

  const openTaskPreview = () => {
    openModal(TaskModal, {
      payload: { id: event?.parent_id }
    });
  };

  const openParentPreview = () => {
    const previewMap = {
      [apiModelsMap.case]: openCasePreview,
      [apiModelsMap.task]: openTaskPreview
    };

    previewMap[event.parent_type]?.();
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? (
    <Box display="flex" alignItems="center" height={80} p={2}>
      <Loader />
    </Box>
  ) : (
    <div className={classes.root} onClick={handleEventClick}>
      <Grid container spacing={2} wrap="nowrap">
        <Box clone flexShrink={0} minWidth={pxToRem(175)}>
          <Grid item>
            <Grid container>
              <Grid item>
                <Date date={event?.started_at} format="L" />
              </Grid>
            </Grid>

            <Grid container spacing={1} wrap="nowrap" alignItems="center">
              <Grid item>
                <TimelapseIcon fontSize="small" />
              </Grid>

              <Grid item>
                <Typography noWrap>
                  {event?.started_at ? moment.unix(event?.started_at).format('LT') : '-'}
                  {' - '}
                  {event?.finished_at ? moment.unix(event?.finished_at).format('LT') : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box clone flexGrow={1} overflow="hidden">
          <Grid item zeroMinWidth>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} zeroMinWidth>
                <Tooltip title={event.title}>
                  <Typography noWrap variant="h5">
                    {event.title}
                  </Typography>
                </Tooltip>
              </Grid>

              {!hideApplyFields &&
                <Grid item>
                  {event.parent && (
                    <Grid container spacing={1} wrap="nowrap" alignItems="center">
                      <Grid item>
                        {event.parent_type === apiModelsMap.task ? (
                          <PlaylistAddCheckIcon fontSize="small"/>
                        ) : event.parent_type === apiModelsMap.case ? (
                          <WorkIcon fontSize="small"/>
                        ) : null}
                      </Grid>

                      <Grid item zeroMinWidth>
                        <Link noWrap onClick={stopPropagation(openParentPreview)}>
                          {event.parent.name}
                        </Link>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              }
            </Grid>
          </Grid>
        </Box>

        {isCreator &&
          <Box clone flexShrink={0} pl="0 !important">
            <Grid item>
              <Menu
                hideApplyFields={hideApplyFields}
                event={event}
                onEventUpdate={onEventUpdate}
                onEventDelete={onEventDelete}
              />
            </Grid>
          </Box>
        }
      </Grid>

      {!!event.office?.full_address &&
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <LocationOnOutlinedIcon fontSize="small" />
          </Grid>

          <Grid item xs zeroMinWidth>
            <AddressLink stopPropagation address={event.office?.full_address}>
              {event.office?.full_address}
            </AddressLink>
          </Grid>
        </Grid>
      }
    </div>
  );
};
