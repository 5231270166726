export const styles = ({
  spacing,
  palette: { grey, darkgreen },
  typography: { fontWeightMedium },
  shape: { borderRadius }
}) => ({
  form: {
    display: 'flex',
    height: '100%'
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: spacing(4)
  },

  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minWidth: 384,
    padding: spacing(4, 2, 1, 2),
    border: `1px dashed ${darkgreen}`,
    borderRadius
  },

  totalTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%'
  },

  totalTitle_sum: {
    flexGrow: 0
  },

  attachmentContentHeader: {
    backgroundColor: grey[100]
  },

  attachmentContentTitle: {
    textTransform: 'uppercase',
    fontWeight: fontWeightMedium,
    color: grey[600]
  },

  handleButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },

  actionAddButton: {
    marginLeft: spacing(2)
  }
});
