import { useEffect } from 'react';
import cn from 'classnames';
import {
  makeStyles,
  SvgIcon,
  Typography,
  Box
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserIcon from '@material-ui/icons/PermIdentity';
import { amFormatDuration } from '../../../../helpers/dates';
import { Label } from '../../../../app/Dashboard/TasksPage/MainWindow/Label';
import { stopPropagation } from '../../../../helpers/dom';
import { CompanyLink } from '../../../CompanyLink';
import { CurrencyFormat } from '../../../CurrencyFormat';
import { Date } from '../../../Date';
import TrackerPlusSvg from '../../../icons/chronometer.svg';
import { ListRow, ListRowCell } from '../../../ListRow';
import { IconButton } from '../../../IconButton';
import { Tooltip } from '../../../Tooltip';
import { Loader } from '../../../Loader';
import { Popper } from '../../../Popper';
import { UserLink } from '../../../UserLink';
import { getProps } from '../helper';
import { Menu } from '../Menu';
import {
  trackModelsColorsMap,
  trackModelsMap
} from '../trackModelsMap';
import {
  minRowHeight,
  widthBreakpointSmall,
  mobileColumnWidths
} from '../TimeTracking';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MobileRow = ({
  item: track = {},
  width,
  caseItem,
  isLoaded,
  disableActions = false,
  recalculateHeight
}) => {
  const classes = useStyles();
  const nameProps = getProps(track, classes);
  const total = track.billing_total || track.total;

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, width ]);

  return !isLoaded ? <Loader className={classes.loader} /> : (
    <ListRow
      className={cn(classes.root, { [classes.root_widget]: width < widthBreakpointSmall })}
      minHeight={minRowHeight}
    >
      <ListRowCell flexBasis={mobileColumnWidths.type}>
        <Box display="flex" flexGrow={1} flexDirection="column" alignItems="center">
          {track.is_manual ?
            <UserIcon className={cn(classes.trackTypeIcon, classes.trackTypeIcon_user)} />
            :
            <SvgIcon className={cn(classes.trackTypeIcon, classes.trackTypeIcon_tracker)}>
              <TrackerPlusSvg />
            </SvgIcon>
          }

          <Label name={trackModelsMap[track.model]} color={trackModelsColorsMap[track.model]} />
        </Box>
      </ListRowCell>

      <ListRowCell column flexBasis={mobileColumnWidths.parentInfo}>
        <Typography noWrap {...nameProps}>
          {track?.time_trackingable?.name || 'No info'}
        </Typography>

        <Tooltip
          isExistTooltip={!!track.description || !!track.note}
          title={track.description || track.note}
        >
          <Typography noWrap color="textSecondary" variant="caption">
            {track.description}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={mobileColumnWidths.user}>
        <UserLink noWrap user={track.user} />
      </ListRowCell>

      <ListRowCell flexBasis={mobileColumnWidths.company}>
        <CompanyLink noWrap company={track?.user?.work?.company} />
      </ListRowCell>

      <ListRowCell noWrap grow flexBasis={mobileColumnWidths.category}>
        {track.category?.name || ''}
      </ListRowCell>

      <ListRowCell column flexBasis={mobileColumnWidths.startedTime}>
        <div>
          <Date disableIcon date={track.started_at} format="L LT" />
          {' -'}
        </div>

        <Date disableIcon date={track.finished_at} format="L LT" />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={mobileColumnWidths.total}>

        <Box display="flex" flexDirection="column" alignItems="center">
          {track.finished_at ? amFormatDuration(track.finished_at - track.started_at) : '-'}

          <CurrencyFormat value={total} />
        </Box>

      </ListRowCell>

      {!disableActions && (
        <ListRowCell flexBasis={mobileColumnWidths.action}>
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                  <MoreVertIcon />
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <Menu
                disableActions={disableActions}
                caseItem={caseItem}
                track={track}
                onClose={handleClose}
              />
            )}
          </Popper>
        </ListRowCell>
      )}
    </ListRow>
  );
};
