export const PAYMENTS_FETCH_REQUEST = 'PAYMENTS_FETCH_REQUEST';
export const PAYMENTS_FETCH_SUCCESS = 'PAYMENTS_FETCH_SUCCESS';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const MASS_ADD_PAYMENT = 'MASS_ADD_PAYMENT';
export const ASSIGN_PAYMENT = 'ASSIGN_PAYMENT';
export const REFUND_PAYMENT = 'REFUND_PAYMENT';
export const CANCEL_REFUND_PAYMENT = 'CANCEL_REFUND_PAYMENT';
export const UPDATE_PAYMENT_IN_LIST = 'UPDATE_PAYMENT_IN_LIST';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const MASS_DELETE_PAYMENT = 'MASS_DELETE_PAYMENT';
export const RESET_PAYMENTS = 'RESET_PAYMENTS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const TOGGLE_PAYMENT_SELECTION = 'TOGGLE_PAYMENT_SELECTION';
export const TOGGLE_ALL_PAYMENTS_SELECTION = 'TOGGLE_ALL_PAYMENTS_SELECTION';
