import { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Box, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { TextField } from '../../../../../../components/FormField';
import { useDebounce } from '../../../../../../helpers/hooks';
import { Popper } from '../../../../../../components/Popper';
import { ThreadMessagesFilterContext } from '../../../../../../components/chat';

export const SearchMessageModal = () => {
  const [ fields, setFields ] = useState({});
  const { setFilter } = useContext(ThreadMessagesFilterContext);
  const debouncedFields = useDebounce(fields, 600);

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
  };

  useEffect(() => {
    setFilter((state) => ({ ...state, ...debouncedFields }));
  }, [ setFilter, debouncedFields ]);

  return (
    <Popper
      placement="bottom-start"
      anchorRenderer={({ anchorRef, handleToggle }) => (
        <IconButton
          color="inherit"
          buttonRef={anchorRef}
          onClick={handleToggle}
        >
          <SearchIcon />
        </IconButton>
      )}
    >
      {() => (
        <Formik initialValues={fields} onSubmit={null}>
          <Box minWidth="200">
            <TextField
              fullWidth
              name="body"
              variant="outlined"
              placeholder="Search message"
              onChange={handleFieldChange('body')}
            />
          </Box>
        </Formik>
      )}
    </Popper>
  );
};
