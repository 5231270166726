import { periodsMap } from './periodsMap';

export const periodsOptions = [
  {
    value: periodsMap.day,
    label: 'Day'
  },
  {
    value: periodsMap.week,
    label: 'Week'
  },
  {
    value: periodsMap.month,
    label: 'Month'
  },
  {
    value: periodsMap.year,
    label: 'Year'
  },
  {
    value: periodsMap.range,
    label: 'Range'
  }
];
