import { useState, createContext, useEffect } from 'react';
import { isEqual } from 'lodash';
import { initialState } from './initialState';

export const StatisticsIncomesFilterContext = createContext();

export const StatisticsIncomesFilterContextProvider = ({ children, ...props }) => {
  const [ filter, setFilter ] = useState({ ...initialState.filter, ...props.filter });

  const providerValue = {
    filter,
    setFilter
  };

  useEffect(() => {
    if (!isEqual(filter, { ...filter, ...props.filter })) {
      setFilter({ ...filter, ...props.filter });
    }
  }, [ props.filter ]);

  return (
    <StatisticsIncomesFilterContext.Provider value={providerValue}>
      {children}
    </StatisticsIncomesFilterContext.Provider>
  );
};
