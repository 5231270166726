import { api } from '../api';

export const fetchSearch = (config = {}) => {
  return api.get('/global-search', config).then(({ data: { data, pagination } }) => {
    return {
      data,
      pagination
    };
  }).catch((error) => {
    throw error.data;
  });
};
