import { Yup } from '../../../../../../../../utils/validation';

export const validationSchema= Yup.object().shape({
  subbed_out: Yup.boolean(),
  physical_file_scanned_at: Yup.mixed().nullable(),
  substituted_out_company_id: Yup.mixed().nullable().when('subbed_out', {
    is: (value) => value > 0,
    then: Yup.mixed().nullable().required()
  })
});
