import { createContext, useReducer, useEffect, useRef } from 'react';
import { merge, omit, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { usePrevious } from '../../../../helpers/hooks';
import { api } from '../../../../api';
import * as tagsApi from '../../../../api/files/tags';
import { setFilesLastGlobalAction, setTagsLastGlobalAction } from '../../../../store/globalActions';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useModal } from '../../../../components/ModalsProvider';
import { CreateTagModal } from '../../../../components/Tags/CreateTagModal';
import { UpdateTagModal } from '../../../../components/Tags/UpdateTagModal';
import * as filesActionsTypes from '../FilesContext/types';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const TagsContext = createContext(null);

export const TagsProvider = ({ children, initialFilter = {}, filter: filterProp = {} }) => {
  const reduxDispatch = useDispatch();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const tagsLastGlobalAction = useSelector(({ globalActions: { tagsLastGlobalAction } }) => tagsLastGlobalAction);
  const [ state, dispatch ] = useReducer(reducer, merge(
    {},
    initialState,
    { filter: { ...initialFilter, ...filterProp } }
  ));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    tags
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchTags = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_TAGS_REQUEST });

    tagsApi.fetchTags({
      params: omit({ ...filter, ...newFilter }, filter.name?.length ? [ 'parent_id' ] : []),
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_TAGS_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchTags({ page: filter.page + 1 });
    }
  };

  const updateTagsPositions = ({ id, oldIndex, newIndex }) => {
    dispatch({ type: types.UPDATE_TAGS_POSITIONS, payload: { oldIndex, newIndex } });

    tagsApi.updateTagsPositions(id, { index: newIndex }).catch(() => {
      fetchTags();
      enqueueSnackbar('Rule not moved', { variant: 'error' });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetTags = (newFilter) => {
    dispatch({ type: types.RESET_TAGS, payload: newFilter });
    fetchTags({ page: 1, ...newFilter });
  };

  const addTag = (tag) => {
    if (tag.parent) {
      reduxDispatch(setTagsLastGlobalAction({
        type: types.ADD_CHILDREN_TAG,
        payload: {
          id: tag.parent?.id
        }
      }));
    }

    dispatch({ type: types.ADD_TAG, payload: tag });
  };

  const createTag = () => {
    openModal(CreateTagModal, {
      payload: {
        is_system: filter.is_system
      },

      onModalResolved: (tag) => {
        addTag(tag);
        enqueueSnackbar(`Tag "${tag.name}" successfully added`);
      }
    });
  };

  const updateTag = (tag) => {
    openModal(UpdateTagModal, {
      payload: {
        tag
      },

      onModalResolved: (updatedTag) => {
        reduxDispatch(setTagsLastGlobalAction({ type: types.UPDATE_TAG, payload: updatedTag }));
        reduxDispatch(setFilesLastGlobalAction({ type: filesActionsTypes.UPDATE_TAG, payload: updatedTag }));
        enqueueSnackbar(`${tag.is_folder ? 'Folder' : 'Tag'} "${tag.name}" successfully updated`);
      }
    });
  };

  const deleteTag = ({ id, name, media_count, parent }) => {
    return new Promise((resolve, reject) => {
      openModal(ConfirmationModal, {
        payload: {
          title: !!media_count && `This tag is used in ${media_count} file(s)`,
          content: 'Are you sure you want to delete it?'
        },

        onModalResolved: () => {
          tagsApi.deleteTag(id).then(() => {
            reduxDispatch(setTagsLastGlobalAction({ type: types.DELETE_TAG, payload: id }));
            reduxDispatch(setFilesLastGlobalAction({ type: filesActionsTypes.DELETE_TAG, payload: id }));

            if (parent) {
              reduxDispatch(setTagsLastGlobalAction({
                type: types.DELETE_CHILDREN_TAG,
                payload: {
                  id: parent?.id
                }
              }));
            }

            enqueueSnackbar(`Tag "${name}" successfully deleted`);
            resolve();
          }).catch(reject);
        },

        onModalRejected: () => {
          reject();
        }
      });
    });
  };

  const providerValue = {
    isFetched,
    isFetching,
    filter: { ...filter, ...pagination },
    tags,

    // functions
    addTag,
    createTag,
    updateTag,
    deleteTag,
    applyFilter,
    resetTags,
    loadNextPage,
    updateTagsPositions
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetTags(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    const newFilter = omit({ ...filter, ...filterProp }, [ 'page' ]);

    if (!isEqual(omit(filter, [ 'page' ]), newFilter)) {
      resetTags(newFilter);
    }
  }, [ filter, filterProp ]);

  useEffect(() => {
    if (tagsLastGlobalAction) {
      dispatch(tagsLastGlobalAction);
    }
  }, [ tagsLastGlobalAction ]);

  return (
    <TagsContext.Provider value={providerValue}>
      {children}
    </TagsContext.Provider>
  );
};
