import { useState, useContext, useRef } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import AddIcon from '@material-ui/icons/Add';
import { exportWorkSessions  } from '../../../api/work-clocks';
import { IconButton } from '../../../components/IconButton';
import { LayoutContext, viewVariantsMap } from '../../../components/LayoutContext';
import { Loader } from '../../../components/Loader';
import { Button } from '../../../components/Button';
import { useModal } from '../../../components/ModalsProvider';
import { PageHeader, PageHeaderTitle, PageHeaderActions } from '../../../components/Page';
import { Tooltip } from '../../../components/Tooltip';
import { WorkSessionCreateModal } from '../../../components/work-sessions/WorkSessionCreateModal';
import { WorkSessionsContext } from '../../../components/work-sessions/WorkSessionsProvider';
import { smallScreenWidth } from '../../../components/work-sessions/WorkSesstions';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { useResizeObserver } from '../../../helpers/hooks';
import { hasRole } from '../../../utils/hasRole';
import { printWorkSessions, exportWorkSessionsAsCSV } from './printWorkSessions';

const actionsMap = {
  print: 'print',
  exportCSV: 'exportCSV'
};

export const Header = ({ filterOpenButton }) => {
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ activeAction, setActiveAction ] = useState(null);
  const { filter } = useContext(WorkSessionsContext);
  const isDisabled = !(
    (filter.users?.length || filter.companies?.length) && filter.started_from && filter.started_to
  );
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const print = () => {
    setActiveAction(actionsMap.print);

    exportWorkSessions({
      params: filter
    }).then(({ data }) => {
      printWorkSessions(data);
    }).catch(() => {
      enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
    }).finally(() => {
      setActiveAction(null);
    });
  };

  const exportToCSV = () => {
    setActiveAction(actionsMap.exportCSV);

    exportWorkSessions({
      params: filter
    }).then(({ data }) => {
      const csv = exportWorkSessionsAsCSV(data);
      const csvData = new Blob([ csv ], { type: 'text/csv;charset=utf-8;' });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      const startDate = moment(filter.started_from).format('L');
      const endDate = moment(filter.started_to).format('L');

      tempLink.href = csvURL;
      tempLink.setAttribute('download', `time_log_${startDate}_-_${endDate}.csv`);
      tempLink.click();
    }).catch(() => {
      enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
    }).finally(() => {
      setActiveAction(null);
    });
  };

  const addTrack = () => {
    openModal(WorkSessionCreateModal);
  };

  return (
    <PageHeader ref={rootRef}>
      <PageHeaderTitle>
        Work Sessions
      </PageHeaderTitle>

      <PageHeaderActions>
        {hasRole(rolesMap.admin, rolesMap.supervisor) &&
          <Button
            size={isMobile ? 'small' : 'medium'}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addTrack}
          >
            Add work session
          </Button>
        }

        <Loader surface loading={activeAction === actionsMap.print} render={
          () => (
            <Tooltip title={isDisabled ? 'Please, fill users or companies and dates filters' : 'Print form'}>
              <div>
                <IconButton disabled={isDisabled} onClick={print}>
                  <PrintOutlinedIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
        />

        <Loader surface loading={activeAction === actionsMap.exportCSV} render={
          () => (
            <Tooltip isExistTooltip={isDisabled} title="Please, fill users or companies and dates filters">
              <div>
                <Button
                  disabled={isDisabled}
                  variant="contained"
                  color="primary"
                  onClick={exportToCSV}
                >
                  Export to CSV
                </Button>
              </div>
            </Tooltip>
          )}
        />

        {isTablet && filterOpenButton}

        {(width > smallScreenWidth) &&
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      </PageHeaderActions>
    </PageHeader>
  );
};
