import Color from 'color';

export const styles = ({ spacing, palette: { grey, ...palette }, transitions }) => ({
  preview: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    height: '100%'
  },

  toggleButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: grey[600],
    color: palette.common.white,
    transition: transitions.create(),

    '&:hover': {
      color: palette.primary.main,
      backgroundColor: Color(grey[600]).alpha(0.8).string()
    }
  },

  prevButton: {
    extend: 'toggleButton',
    left: spacing(1.5)
  },

  nextButton: {
    extend: 'toggleButton',
    right: spacing(1.5)
  }
});
