import { createReduxReducer } from '../../../helpers/createReduxReducer';
import * as types from './types';

const initialState = {
  isFetching: false,
  roles: []
};

export const reducer = createReduxReducer(initialState, {
  [types.ROLES_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.ROLES_FETCH_SUCCESS]: (state, payload) => {
    return {
      ...state,
      isFetching: false,
      roles: payload
    };
  },

  [types.ROLES_FETCH_FAIL]: (state) => {
    return {
      ...state,
      isFetching: false
    };
  },

  [types.ADD_ROLE]: (state, payload) => {
    return {
      ...state,
      roles: [
        payload,
        ...state.roles
      ]
    };
  },

  [types.UPDATE_ROLE_IN_LIST]: (state, payload) => {
    return {
      ...state,
      roles: state.roles.map((item) => {
        return item.id === payload.id ? { ...item, ...payload } : item;
      })
    };
  },

  [types.DELETE_ROLE_SUCCESS]: (state, { roleId }) => {
    return {
      ...state,
      roles: state.roles.filter((item) => item.id !== roleId)
    };
  }
});
