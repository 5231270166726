import { Button, Dialog } from '@material-ui/core';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../../../../components/Modal';
import { AppointmentTypesContext, AppointmentTypesProvider } from '../../../AppointmentTypes/AppointmentTypesContext';
import { List } from './List';

export const AddTypesModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const assignTypes = (types) => () => {
    handleModalResolve(types);
  };

  return (
    <Dialog maxWidth="lg" {...DialogProps}>
      <AppointmentTypesProvider>
        <AppointmentTypesContext.Consumer>
          {({ selectedCodes }) => (
            <ModalContainer>
              <ModalHeader onClose={handleModalReject}>
                Select types to assign
              </ModalHeader>

              <ModalBody>
                <List />
              </ModalBody>

              <ModalFooter>
                <Button onClick={handleModalReject}>Cancel</Button>

                <Button
                  disabled={!selectedCodes.length}
                  variant="contained"
                  color="primary"
                  onClick={assignTypes(selectedCodes)}
                >
                  Assign
                </Button>
              </ModalFooter>
            </ModalContainer>
          )}
        </AppointmentTypesContext.Consumer>
      </AppointmentTypesProvider>
    </Dialog>
  );
};
