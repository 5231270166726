import { forwardRef } from 'react';
import cn from 'classnames';
import { Box, makeStyles } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CardItem = forwardRef(({
  disableHover = false,
  className,
  borderWidth = null,
  selectedBoxShadows = null,
  selected,
  children,

  ...props
}, ref) => {
  const classes = useStyles({ borderWidth });

  return (
    <Box
      ref={ref}
      className={cn(
        classes.root,
        { [classes.hoverable]: !disableHover },
        { [classes.root_checked]: selected },
        { [classes.root_borderWidth]: !selected && !!borderWidth },
        { [classes.root_selectedBoxShadows]: selected && !!selectedBoxShadows },
        className
      )}
      {...props}
    >
      {children}
    </Box>
  );
});
