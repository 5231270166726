import { Box, makeStyles } from '@material-ui/core';
import { ErrorBoundary } from '../ErrorBoundary';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Cards = ({ size, list, cardProps = {}, component: Component, ...props }) => {
  const classes = useStyles({ size });

  return (
    <Box className={classes.root} {...props}>
      {list.map((item) => (
        <ErrorBoundary key={item.id}>
          <Component item={item} {...cardProps} />
        </ErrorBoundary>
      ))}
    </Box>
  );
};
