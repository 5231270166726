import { Box, Chip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as taskTypesApi from '../../../../api/tasks/types';
import { ColorPreview } from '../../../ColorPreview';
import { Autocomplete } from '../../Autocomplete';

const renderOption = (option) => (
  <>
    <Box display="flex" mr={1}>
      <ColorPreview color={option.color} />
    </Box>

    {option.name}
  </>
);

export const TaskTypesSelect = ({ params = {}, ...props }) => {
  const [ types, setTypes ] = useState([]);

  useEffect(() => {
    taskTypesApi.fetchTypes({
      params: {
        is_active: 1,
        ...params
      }
    }).then((types) => {
      setTypes(types);
    });
  }, []);

  return (
    <Autocomplete
      label="Task Type"
      placeholder="Search type..."
      options={types}
      getInputProps={(value) => !(!props.multiple && value) ? null : ({
        startAdornment: (!props.multiple && value) && (
          <ColorPreview color={value.color} />
        )
      })}
      renderOption={renderOption}
      getOptionLabel={(type) => type?.name}
      getOptionValue={(type) => type?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}
      renderTags={(types, getTagProps) => (
        types.map((type, index) => (
          <Chip
            variant="outlined"
            size={props.size}
            label={type.name}
            icon={
              <Box display="flex" alignItems="center" ml={1.5}>
                <ColorPreview color={type.color} />
              </Box>
            }
            {...getTagProps({ index })}
          />
        ))
      )}

      {...props}
    />
  );
};
