import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { orderByLabelsMap } from '../../../../../../../components/claims';
import { UserLink } from '../../../../../../../components/UserLink';
import { filterFieldsMap } from '../filterFieldsMap';

export const CardContent = ({ params, hiddenFields = [] }) => {
  return (
    <Grid container spacing={1}>
      {params?.claim_number &&
        <>
          <Grid item xs={4}>
            <Typography>
              Claim #:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.claim_number}
            </Typography>
          </Grid>
        </>
      }

      {!!params.providers?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Providers:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.providers?.map((user) => (
                <Box display="flex" width="100%" alignItems="center" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    key={user.id}
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.patients?.length && !hiddenFields.includes(filterFieldsMap.patients) &&
        <>
          <Grid item xs={4}>
            <Typography>
              Patients
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.patients?.map((user) => (
                <Box display="flex" width="100%" alignItems="center" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    key={user.id}
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.adjusters?.length && !hiddenFields.includes(filterFieldsMap.adjusters) &&
        <>
          <Grid item xs={4}>
            <Typography>
              Adjusters
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.adjusters?.map((user) => (
                <Box display="flex" width="100%" alignItems="center" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    key={user.id}
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.appointment_from &&
        <>
          <Grid item xs={4}>
            <Typography>
              DOS From:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment.unix(params.appointment_from).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.appointment_to &&
        <>
          <Grid item xs={4}>
            <Typography>
              DOS To:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment.unix(params.appointment_to).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {!!params?.offices?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Offices:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.offices?.map((office) => (
                <Typography noWrap key={office?.id} title={office?.name}>
                  {office?.name}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.statuses &&
        <>
          <Grid item xs={4}>
            <Typography>
              Claim Statuses:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.statuses?.map(({ label }) => label).join(', ')}
            </Typography>
          </Grid>
        </>
      }

      {params?.order_by &&
        <>
          <Grid item xs={4}>
            <Typography>
              Order By:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {orderByLabelsMap[params?.order_by] || '-'}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
