import { useContext } from 'react';
import { makeStyles, Toolbar, IconButton } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { DocumentVersionsContext } from '../DocumentVersionsContext';
import { VersionPreviewContext } from '../VersionPreviewContext';
import { ActionsBar } from './ActionsBar';
import { Preview } from './Preview';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PreviewWindow = ({ onModalClose, onVersionChange }) => {
  const classes = useStyles();
  const { versions } = useContext(DocumentVersionsContext);
  const { selectedVersion, handleVersionSelect } = useContext(VersionPreviewContext);
  const versionIndex = versions.findIndex(({ id }) => id === selectedVersion.id);
  const hasPreviousVersion = versionIndex > 0;
  const hasNextVersion = versionIndex + 1 < versions.length;

  const toggleToPreviousVersion = () => {
    if (hasPreviousVersion) {
      handleVersionSelect(versions[versionIndex - 1].id);
    }
  };

  const toggleToNextVersion = () => {
    if (hasNextVersion) {
      handleVersionSelect(versions[versionIndex + 1].id);
    }
  };

  return (
    <>
      <ActionsBar onClickModalClose={onModalClose} onVersionChange={onVersionChange} />

      <div className={classes.preview}>
        <Toolbar />

        <Preview version={selectedVersion} />
      </div>

      {hasPreviousVersion &&
        <IconButton className={classes.prevButton} onClick={toggleToPreviousVersion}>
          <KeyboardArrowLeftIcon/>
        </IconButton>
      }

      {hasNextVersion &&
        <IconButton className={classes.nextButton} onClick={toggleToNextVersion}>
          <KeyboardArrowRightIcon/>
        </IconButton>
      }
    </>
  );
};
