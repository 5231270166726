import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { renderRoutes, getActiveRoute } from '../../../components/router';
import { Tab, Tabs } from '../../../components/Tabs';
import { CompaniesTypesPage } from './CompaniesTypesPage';
import { routes } from './routes';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.grey[100],
    height: '100%'
  }
}));

export const Contacts = () => {
  const classes = useStyles();
  const location = useLocation();
  const activeRoute = routes.find(({ path }) => getActiveRoute(routes).includes(path))?.path || routes[0];

  if (location.pathname === '/contacts/system-settings/companies-types') {
    return (
      <Switch>
        <Route exact path="/contacts/system-settings/companies-types" component={CompaniesTypesPage} />
      </Switch>
    );
  }

  return (
    <>
      <Route exact path="/contacts/system-settings">
        <Redirect to="/contacts/system-settings/companies-types" />
      </Route>

      <LayoutContextProvider>
        <div className={classes.root}>
          <Tabs value={activeRoute}>
            {routes.map(({ path, icon, label }) => icon && (
              <Tab
                key={path}
                component={Link}
                to={path}
                value={path}
                icon={icon}
                label={label}
              />
            ))}
          </Tabs>

          <Route exact path="/contacts">
            <Redirect to="/contacts/companies" />
          </Route>

          {renderRoutes(routes)}
        </div>
      </LayoutContextProvider>
    </>
  );
};
