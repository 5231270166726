import { useContext, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Loader } from '../../../Loader';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { InfiniteGridLoader } from '../../../InfiniteLoader';
import { TimeReportsContext } from '../../TimeReportsProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    isFetching,
    isFetched,
    meta,
    timeReports,
    loadNextPage,
    resetTimeReports
  } = useContext(TimeReportsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetTimeReports();
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%" pt={1} pl={2} pr={0} pb={0}>
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
        <Loader p={2} loading={!isFetched} render={
          () => !timeReports.length ? (
            <Box p={1}>
              <Typography align="center">No sessions found</Typography>
            </Box>
          ) : (
            <InfiniteGridLoader
              items={timeReports}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={meta}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={300}
              cellHeight={280}
            />
          )}
        />
      </CustomScrollbars>
    </Box>
  );
};
