export const getUserOldName = (user) => {
  if (!user) {
    return;
  }

  const { old_first_name = null, old_last_name = null } = user.profile || user;
  const old_name = `${old_first_name || ''} ${old_last_name || ''}`;

  return !!old_name.trim().length && old_name;
};
