export const filterFieldsMap = {
  apptFrom: 'from',
  apptTo: 'to',
  cptId: 'cpt_id',
  icdId: 'icd_id',
  providerId: 'provider_id',
  office: 'office_id'
};

export const filterFieldsLabels = {
  [filterFieldsMap.apptFrom]: 'Appt.From',
  [filterFieldsMap.apptTo]: 'Appt.To',
  [filterFieldsMap.cptId]: 'CPT',
  [filterFieldsMap.icdId]: 'ICD',
  [filterFieldsMap.providerId]: 'Provider',
  [filterFieldsMap.office]: 'Office'
};
