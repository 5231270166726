import { Button, Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as callReportsApi from '../../../../api/call-reports';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { CallReportsContext, CallReportsProvider } from '../../../../components/call-reports/CallReportsProvider';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../components/Modal';
import { FiltersBar } from '../../../../components/call-reports/CallReports/FiltersBar';
import { List } from '../../../../components/call-reports/CallReports/List';
import { LayoutContextProvider } from '../../../../components/LayoutContext';
import { Loader } from '../../../../components/Loader';

export const CallReportsModal = ({
  payload: {
    parent_id = null,
    parent_type = null
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (ids) => () => {
    setIsSubmitting(true);

    const values = {
      ids,
      parent_id,
      parent_type
    };

    return callReportsApi.multipleAttachParent(values).then(() => {
      setIsSubmitting(false);
      enqueueSnackbar('Successfully assigned', { variant: 'success' });
      handleModalResolve();
    });
  };

  return (
    <CallReportsProvider>
      <CallReportsContext.Consumer>
        {({ selectedCallReportsIDs }) => (
          <Dialog maxWidth="lg" {...DialogProps}>
            <LayoutContextProvider>
              <ModalContainer>
                <ModalHeader onClose={handleModalReject}>
                  Choose Call Report
                </ModalHeader>

                <ModalBody disablePaddings disableScrollbars>
                  <FiltersBar filterKey={filtersKeysMap.call_reports_modal}/>
                  <List autoHeight />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>Cancel</Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit(selectedCallReportsIDs)}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            </LayoutContextProvider>
          </Dialog>
        )}
      </CallReportsContext.Consumer>
    </CallReportsProvider>
  );
};
