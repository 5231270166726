import { useContext, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { filtersKeysMap, saveFilter } from '../../../../../store/lastFilters';
import { IconComponent } from '../../../../../components/saved-filters';
import { TextField, CompaniesAutocomplete } from '../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { UsersSelect } from '../../../../../components/users';
import { OfficesContext } from '../OfficesContext';
import { filterFieldsMap, filterFieldsLabels } from './filterFieldsMap';
import { initialValues } from './initialValues';
import { CardContent } from './CardContent';
import { List } from './List';

const MODAL_WIDTH = 970;

export const FiltersBar = ({ filterKey }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { applyFilter } = useContext(OfficesContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleCompanyChange = (company) => {
    applyFilter({ company_id: company?.id });
    setRelationsForFilter((state) => ({ ...state, company_id: company }));
  };

  const handleUsersMultiSelect = (name) => (options) => {
    const users = options?.length ? options.map(({ id }) => id) : null;

    applyFilter({ [name]: users });
    setRelationsForFilter((state) => ({ ...state, [name]: options }));
  };

  const applySavedFilter = (filter) => {
    applyFilter({
      ...filter,

      company_id: filter?.company_id?.id,
      users: filter?.users?.map((user) => user.id)
    });

    formikRef.current.setValues(filter);
  };

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  useEffect(() => {
    dispatch(saveFilter({
      key: filtersKeysMap.contacts_offices,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      border={0}
      initialValues={initialValues}
      onReset={() => applyFilter(initialValues)}
      hiddenFields={[]}
      filterKey={filterKey}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      fieldsList={[
        {
          fieldKey: filterFieldsMap.name,
          label: filterFieldsLabels[filterFieldsMap.name],
          field: <TextField
            name="name"
            label="Filter by office name"
            placeholder="Office name"
            onChange={handleFieldChange('name')}
          />
        },
        {
          fieldKey: filterFieldsMap.companyId,
          label: filterFieldsLabels[filterFieldsMap.companyId],
          field: <Box minWidth={210}>
            <CompaniesAutocomplete
              name="company_id"
              label="Company name"
              onChange={handleCompanyChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.users,
          label: filterFieldsLabels[filterFieldsMap.users],
          field: (
            <UsersSelect
              multiple
              name="users"
              label="Users"
              onChange={handleUsersMultiSelect('users')}
              minWidth={210}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.address,
          label: filterFieldsLabels[filterFieldsMap.address],
          field: <Box minWidth={250}>
            <TextField
              name="address"
              label="Address"
              placeholder="Enter address..."
              onChange={handleFieldChange('address')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.email,
          label: filterFieldsLabels[filterFieldsMap.email],
          field: <TextField
            name="email"
            label="Office email"
            placeholder="Enter email..."
            onChange={handleFieldChange('email')}
          />
        }
      ]}
    />
  );
};
