export const styles = ({
  spacing,
  palette: { divider, grey, ...palette },
  breakpoints,
  transitions
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: grey[100],
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },

  wrapper: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: spacing(2, 3, 0),

    [breakpoints.down('md')]: {
      padding: spacing()
    }
  },

  content: {
    display: 'flex',
    height: '100%',
    border: `1px solid ${divider}`,
    backgroundColor: palette.background.paper
  },

  sidebar: {
    flexShrink: 0,
    height: '100%',
    transition: transitions.create([ 'width' ]),

    [breakpoints.up('lg')]: {
      width: 320
    },

    [breakpoints.down('md')]: {
      width: 0,
      overflow: 'hidden'
    }
  },

  sidebarOpen: {
    width: 320,
    transition: transitions.create([ 'width' ]),

    [breakpoints.down(breakpoints.values.sm)]: {
      width: '100%'
    }
  },

  main: {
    flexGrow: 1,
    height: '100%'
  },

  tabsWrapper: {
    [breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      width: '100%'
    }
  },

  navigation: {
    backgroundColor: palette.primary.contrastText
  }
});
