export const styles = ({
  spacing,
  palette,
  typography: { pxToRem, fontWeightMedium },
  breakpoints
}) => ({
  header: {
    display: 'flex',
    borderWidth: 2,
    borderColor: palette.grey[100],
    padding: spacing(),

    [breakpoints.up('md')]: {
      borderWidth: 4,
      padding: spacing(1, 2),
      alignItems: 'stretch',
      minHeight: 114
    }
  },

  mainIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 50,
    border: `1px solid ${palette.primary.main}`,
    width: 50,
    minWidth: 50,
    height: 50,
    flexGrow: 0,

    [breakpoints.up('sm')]: {
      width: 64,
      height: 64,
      maxHeight: 64,
      minWidth: 64
    }
  },

  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    paddingLeft: spacing(2)
  },

  mainInfo__title: {
    fontSize: pxToRem(18),
    fontWeight: fontWeightMedium,

    [breakpoints.up('sm')]: {
      fontSize: pxToRem(30),
      fontWeight: 'normal'
    }
  },

  userInfo: {
    display: 'none',

    [breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      alignSelf: 'flex-start',
      flexGrow: 0
    }
  }
});
