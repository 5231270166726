import { Fragment, useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import filesize from 'filesize';
import {
  Dialog,
  useMediaQuery,
  useTheme,
  Grid,
  Typography,
  Link,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import * as expensesApi from '../../../../../api/billing';
import { expensesStatusesMap } from '../../../../../components/billing';
import { CaseLink } from '../../../../../components/cases/CaseLink';
import { CurrencyFormat } from '../../../../../components/CurrencyFormat';
import { Date } from '../../../../../components/Date';
import { Label } from '../../../../../components/Label';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { setFilesLastGlobalAction } from '../../../../../store/globalActions';
import { stopPropagation } from '../../../../../helpers/dom';
import { ModalContainer, ModalHeader, ModalBody } from '../../../../../components/Modal';
import { useModal as ModalsProvider } from '../../../../../components/ModalsProvider';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { IconButton } from '../../../../../components/IconButton';
import { Loader } from '../../../../../components/Loader';
import { UserLink } from '../../../../../components/UserLink';
import { hasRole } from '../../../../../utils/hasRole';
import { PreviewInvoiceModal } from '../../../CasesPage/Cases/CaseItem/TabsContent/Billing/PreviewInvoiceModal';
import { FilePreviewIcon, FilePreviewModal } from '../../../files-common';
import { Category } from '../../BillingSettings/Category';
import * as filesTypes from '../../FilesContext/types';
import { getFileMenuOptions } from '../Expenses/List/getFileMenuOptions';

export const ExpensesPreviewModal = ({
  DialogProps,
  payload: {
    id = null
  },
  handleModalReject
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const [ isFetched, setIsFetched ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [ expense, setExpense ] = useState(null);
  const [ files, setFiles ] = useState([]);
  const { openModal } = ModalsProvider();
  const isAbleToDeleteFile = !hasRole(rolesMap.advocate);
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      getFileMenuOptions={getFileMenuOptions(handleDeleteFile, isAbleToDeleteFile)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file: files?.[0],
        files: files || []
      }}
    />
  ), [ files && files.length ]);

  const openInvoiceViewModal = () => {
    if (expense?.invoice?.id) {
      openModal(PreviewInvoiceModal, {
        payload: {
          disableAction: true,
          invoiceID: expense?.invoice?.id
        }
      });
    }
  };

  const handleDeleteFile = (fileId) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        expensesApi.deleteFiles([ fileId ]).then(() => {
          dispatch(setFilesLastGlobalAction({
            type: filesTypes.DELETE_FILES,
            payload: [ fileId ]
          }));

          setFiles(files?.filter((item) => item.id !== fileId));
          enqueueSnackbar('File successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  useEffect(() => {
    setIsFetched(false);

    expensesApi.fetchExpense(id).then((data) => {
      setFiles(data.files);
      setExpense(data);
    }).finally(() => setIsFetched(true));
  }, []);

  return (
    <Dialog fullScreen={isMobile} {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Expense
        </ModalHeader>

        <ModalBody>
          <Loader loading={!isFetched} p={2} render={
            () => (
              <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12}>
                  <UserLink variant="h5" user={expense.user} />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Typography>Case name</Typography>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <CaseLink caseItem={expense?.case} />
                </Grid>

                {expense?.invoice?.number && (
                  <>
                    <Grid item sm={6} xs={12}>
                      <Typography>Invoice</Typography>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <Link variant="h5" onClick={stopPropagation(openInvoiceViewModal)}>
                        {expense?.invoice?.number || '-'}
                      </Link>
                    </Grid>
                  </>
                )}

                <Grid item sm={6} xs={12}>
                  <Typography>Date:</Typography>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Date format="L, LT" date={expense?.date} />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Typography>Category</Typography>
                </Grid>

                <Grid item sm={6} xs={12}>
                  {!expense?.category ? '-' : <Category category={expense?.category} />}
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Typography>Budget:</Typography>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Typography>
                    <CurrencyFormat value={expense.budget}/>
                  </Typography>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Typography>Amount:</Typography>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Typography>
                    <CurrencyFormat value={expense.amount}/>
                  </Typography>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Typography>Status:</Typography>
                </Grid>

                <Grid item sm={6} xs={12}>
                  {!expensesStatusesMap?.[expense?.status?.name.toLowerCase()] ? '-' :
                    <Label
                      label={expensesStatusesMap?.[expense?.status?.name.toLowerCase()]?.name}
                      color={expensesStatusesMap?.[expense?.status?.name.toLowerCase()]?.color}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <Typography color="textSecondary">Notes:</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography noWrap>
                    {expense?.notes || '-'}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <List disablePadding>
                    {files.map((file, index) => (
                      <Fragment key={index}>
                        {index > 0 && <Divider />}

                        <ListItem disableGutters onClick={openFilesPreview}>
                          <FilePreviewIcon file={file} onClick={openFilesPreview} />

                          <ListItemText
                            primary={file.original_filename || file.name}
                            primaryTypographyProps={{ noWrap: true }}
                          />

                          <ListItemText align="right" primary={filesize(file.size)} />

                          {isAbleToDeleteFile && (
                            <ListItemSecondaryAction>
                              <IconButton
                                color="error"
                                edge="end"
                                onClick={stopPropagation(handleDeleteFile(file.id))}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                      </Fragment>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}
          />
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
