import { api } from '../../api';

export const fetchCertificates = (config) => {
  return api.get('/profile/certificates', config)
    .then((data) => data)
    .catch((error) => { throw error.data; });
};

export const createCertificate = (data) => {
  return api.post('/profile/certificates', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteCertificate = (id) => {
  return api.delete(`/profile/certificates/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const uploadCertificate = (data, options) => {
  return api.post('/profile/certificates/upload', data, options)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
