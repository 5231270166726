import { memo, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useMediaQuery } from '@material-ui/core';
import { useWidgets } from '../../helpers/hooks/useWidgets';
import { ResponsiveGridLayout } from '../ResponsiveGridLayout';
import { Scrollbars } from '../Scrollbars';
import { Loader } from '../Loader';
import { ActionsBar } from './ActionsBar';

export const Widgets = memo(({
  rowHeight,
  extraTypes,
  widgetsMap,
  cardsLayout,
  checkedWidgetsTypes,
  actionsBarLeftContent,

  ...props
}) => {
  const {
    layouts,
    toolbox,
    isFetched,
    breakpoint,
    onReset,
    onTakeItem,
    onPutItem,
    handleOpen,
    handleClose,
    onLayoutChange,
    handleMinimize,
    handleMaximized,
    onBreakpointChange
  } = useWidgets({ page: props?.page, extraTypes, cardsLayout, widgetsMap });
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const children = useMemo(() => {
    if (!isFetched) return;

    return (
      layouts?.[breakpoint]?.map((item) => {
        const Component = widgetsMap?.[item?.i]?.component;

        return checkedWidgetsTypes[item?.i] && widgetsMap?.[item?.i] && (
          <div
            key={item.i}
            data-grid={item}
            onMouseDown={(event) => event.stopPropagation()}
          >
            <Component
              item={item}
              onClose={handleClose}
              onOpen={handleOpen}
              onMaximized={handleMaximized}
              onMinimize={handleMinimize}
              onPutItem={onPutItem(item.i)}

              {...props}
            />
          </div>
        );
      })
    );
  }, [ layouts?.[breakpoint], isFetched ]);

  return (
    <Loader
      loading={!isFetched}
      render={() => (
        <>
          <ActionsBar
            breakpoint={breakpoint}
            widgetsMap={widgetsMap}
            widgets={{ layouts, toolbox }}
            checkedWidgetsTypes={checkedWidgetsTypes}
            onReset={onReset}
            onPutItem={onPutItem}
            onTakeItem={onTakeItem}
            actionsBarLeftContent={actionsBarLeftContent}
          />

          <Scrollbars>
            <ResponsiveGridLayout
              compactType="vertical"
              mounted={!isFetched}
              measureBeforeMount={false}
              useCSSTransforms={isFetched}
              preventCollision={false}
              layouts={layouts}
              rowHeight={rowHeight}
              cols={cardsLayout.cols}
              breakpoints={cardsLayout.breakpoints}
              draggableCancel=".non-draggable"
              margin={isTablet ? [ 1, 10 ] : [ 10, 10 ]}
              onLayoutChange={onLayoutChange}
              onBreakpointChange={onBreakpointChange}
            >
              {children}
            </ResponsiveGridLayout>
          </Scrollbars>
        </>
      )}
    />
  );
}, isEqual);
