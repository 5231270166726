import { useEffect, useContext, useRef } from 'react';
import { Box } from '@material-ui/core';
import { CustomScrollbars } from '../../../components/CustomScrollbars';
import { FilesList } from '../../Dashboard/files-common/FilesList';
import { SharedFilesContext } from '../SharedFilesProvider';
import { Row } from './Row';

export const List = ({ hiddenColumns }) => {
  const sharedFilesContext = useContext(SharedFilesContext);
  const { resetFiles } = sharedFilesContext;
  const scrollElementRef = useRef();

  useEffect(() => {
    resetFiles();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
      <Box py={2} pl={4} pr={3}>
        <FilesList
          scrollElementRef={scrollElementRef}
          hiddenColumns={hiddenColumns}
          filesContext={sharedFilesContext}
          Row={Row}
        />
      </Box>
    </CustomScrollbars>
  );
};
