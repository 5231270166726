import { Fragment, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, generatePath, useParams } from 'react-router-dom';
import cn from 'classnames';
import { makeStyles, Box, useTheme, useMediaQuery, IconButton } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { renderRoutes, getActiveRoute } from '../../../../../components/router';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { Tab, Tabs } from '../../../../../components/Tabs';
import { Loader } from '../../../../../components/Loader';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { styles } from '../../../PatientsPage/MedicalInfo/styles';
import { GeneralAndMedicalSwitcher } from '../../../ProfilePage/GeneralAndMedicalSwitcher';
import { LeftSidebar } from '../../../ProfilePage/LeftSidebar';
import { UserContext } from '../UserProvider';
import { MedicalInfo } from './MedicalInfo';
import { routes } from './routes';

const useStyles = makeStyles(styles);

export const MainContent = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams();
  const userSidebarIsOpen = useSelector(({ layout: { userInfoIsOpen = true } }) => userInfoIsOpen);
  const { isFetched, medicalInfoPathname, user } = useContext(UserContext);
  const [ isUserInfoOpen, setIsUserInfoOpen ] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    FilterBarWrapper,
    filterBarWrapperProps,
    toggleFiltersBar
  } = useMobileFilterCollapse();
  const ScrollWrapper = !isTablet ? Fragment : Scrollbars;
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%',
    width: '100%'
  };

  const toggleInfoOpen = () => {
    setIsUserInfoOpen((state) => !state);
  };

  return !isFetched ? <Loader p={2} /> : (
    location.pathname.includes(medicalInfoPathname) ?
      <MedicalInfo />
      :
      <div className={classes.root}>
        {isTablet &&
          <div className={classes.navigation}>
            <Box mx={2.25} mt={1}>
              <IconButton size="medium" onClick={toggleFiltersBar}>
                <ListIcon />
              </IconButton>
            </Box>
          </div>
        }

        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <Box
              display="flex"
              flexWrap="nowrap"
              flexDirection={isTablet ? 'column' : 'row'}
              alignItems={isTablet ? 'stretch' : 'center'}
              pl={1.25}
            >
              <Box display="flex" pr={2}>
                <GeneralAndMedicalSwitcher
                  generalLink={`/members/users/${params.userID}`}
                  medicalLink={`/members/users/${params.userID}/medical-info/medical-forms`}
                />
              </Box>

              <Box flexGrow={1} overflow="hidden">
                <Tabs
                  orientation={isTablet ? 'vertical' : 'horizontal'}
                  value={generatePath(getActiveRoute(routes), { userID: user.id })}
                  classes={{ flexContainer: classes.tabsWrapper }}
                >
                  {routes.map(({ hide, path, icon, label }) => !hide?.({ params, payload: { user } }) && (
                    <Tab
                      key={path}
                      value={generatePath(path, { userID: user.id })}
                      icon={icon}
                      label={label}
                      component={Link}
                      to={generatePath(path, { userID: user.id })}
                      onClick={isTablet ? toggleFiltersBar : undefined}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </FilterBarWrapper>
        </ScrollWrapper>

        <div className={classes.content}>
          {userSidebarIsOpen && (
            <div className={cn(classes.leftSidebar, { [classes.leftSidebarFullHeight]: isUserInfoOpen })}>
              <LeftSidebar user={user} openUserInfo={isUserInfoOpen} toggleInfoOpen={toggleInfoOpen} />
            </div>
          )}

          <div className={cn(classes.main, { [classes.mainFullHeight]: !isUserInfoOpen })}>
            {renderRoutes(routes, { user })}
          </div>
        </div>
      </div>
  );
};
