import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import cn from 'classnames';
import {
  makeStyles,
  Button,
  Typography,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  Box
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Yup } from '../../../../../utils/validation';
import * as templatesApi from '../../../../../api/files/html-documents';
import { Checkbox, TextField } from '../../../../../components/FormField';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Loader } from '../../../../../components/Loader';
import { Label } from '../../../../../components/Label';
import { DocumentEditor } from '../../../files-common';
import {
  addReplaceableDataTypePlugin,
  initReplaceableDataTypeList
} from '../../../files-common/DocumentEditor/replaceableDataTypePlugin';
import { TemplateSettingsModal } from '../TemplateSettingsModal';
import { ReplaceableDataFieldsList } from '../ReplaceableDataFieldsList';
import { templatesTypesColors, templatesTypesNames } from '../templatesTypes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TemplateCreationPage = ({ isOpenSidebar = false, toggleSideBar = () => {} }) => {
  const { openModal } = useModal();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const editorRef = useRef();
  const listRef = useRef();
  const [ isOpenList, setIsOpenList ] = useState(false);
  const [ templateSettings, setTemplateSettings ] = useState();
  const availablePaperSizes = useSelector(({ apiConfig }) => apiConfig.config.html_documents.page_sizes);
  const replaceableDataFieldsTypes = useSelector(({ apiConfig }) => {
    return Object.keys(apiConfig.config.html_documents.fields);
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleEditorInit = (CKEDITOR) => {
    addReplaceableDataTypePlugin({ CKEDITOR });

    if (replaceableDataFieldsTypes.includes(templateSettings.type)) {
      initReplaceableDataTypeList({ CKEDITOR, listRef });
    }
  };

  const addTemplate = ({ name, ...values }, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const html = editorRef.current.getData();

    if (!html) {
      setSubmitting(false);

      return enqueueSnackbar('The template cannot be empty', {
        variant: 'error'
      });
    }

    templatesApi.createTemplate({
      ...templateSettings,
      ...values,
      name,
      html
    }).then(() => {
      enqueueSnackbar('Template successfully created', { variant: 'success' });
      history.push('/files/templates');
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        setSubmitting(false);
        errors && setErrors(errors);
      }
    });
  };

  const handleToggleFieldsList = () => {
    setIsOpenList((state) => !state);
  };

  useEffect(() => {
    openModal(TemplateSettingsModal, {
      onModalResolved: setTemplateSettings,
      onModalRejected: () => {
        history.push('/files/templates');
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      <Loader
        loading={!templateSettings}
        render={() => (
          <Formik
            initialValues={{}}
            validationSchema={Yup.object().shape({ name: Yup.string().required().min(2).max(255) })}
            onSubmit={addTemplate}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form noValidate className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.top}>
                  {isTablet && !isMobile && (
                    <IconButton color="primary" onClick={toggleSideBar}>
                      {isOpenSidebar ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                  )}

                  <TextField
                    required
                    name="name"
                    label="Template name"
                    placeholder="Enter template name..."
                    margin="dense"
                    className={classes.grow}
                  />

                  <FormControlLabel
                    label="Is system"
                    control={
                      <Checkbox name="is_system" />
                    }
                  />

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        size={isTablet ? 'small' : 'medium'}
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                  />

                  <div className={classes.templateType}>
                    <Typography className={classes.templateType__title}>
                      {isTablet ? 'Type' : 'Template type'}
                    </Typography>

                    <Label
                      label={templatesTypesNames[templateSettings.type]}
                      color={templatesTypesColors[templateSettings.type]}
                    />
                  </div>
                </div>

                {isMobile && replaceableDataFieldsTypes.includes(templateSettings.type) && (
                  <Box display="flex" justifyContent="flex-end" pb={0.5}>
                    <Button color="primary" onClick={handleToggleFieldsList}>
                      {isOpenList ? 'Close List' : 'Open List'}
                    </Button>
                  </Box>
                )}

                <div className={classes.bottom}>
                  <div className={classes.bottom__container}>
                    <div className={classes.editor}>
                      <DocumentEditor
                        ref={editorRef}
                        paperSize={availablePaperSizes[templateSettings.page_size]}
                        paperMargins={templateSettings.page_fields}
                        onBeforeLoad={handleEditorInit}
                      />
                    </div>

                    {replaceableDataFieldsTypes.includes(templateSettings.type) &&
                    <div className={cn(classes.list, { [classes.listClose]: isMobile && !isOpenList })}>
                      <ReplaceableDataFieldsList
                        listRef={listRef}
                        selectedTemplateType={templateSettings.type}
                      />
                    </div>
                    }
                  </div>
                </div>
              </form>
            )}
          </Formik>
        )}
      />
    </div>
  );
};
