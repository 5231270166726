import { api } from '../../api';

export const fetchCaseReminders = (config = {}) => {
  return api.get('/case-reminders/reminders', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createCaseReminders = (data) => {
  return api.post('/case-reminders/reminders/group', data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateCaseReminder = (reminder) => {
  return api.put(`/case-reminders/reminders/${reminder.id}`, reminder)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateCaseReminderSettings = (reminder, data) => {
  return api.put(`/case-reminders/reminders/${reminder.id}/settings`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const doneCaseReminder = (reminder) => {
  return api.put(`/case-reminders/reminders/${reminder.id}/done`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const undoneCaseReminder = (reminder) => {
  return api.delete(`/case-reminders/reminders/${reminder.id}/done`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const toggleCaseReminderActive = (reminder, data) => {
  return api.put(`/case-reminders/reminders/${reminder.id}/active`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchCaseRemindersStatistics = (config = {}) => {
  return api.get('/case-reminders/reminders/statistics', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
