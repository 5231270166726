import { useContext } from 'react';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import AddIcon from '@material-ui/icons/Add';
import { EmailsSendModal } from '../../../../app/Dashboard/EmailsPage/EmailsClient/EmailsSendModal';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { Fab } from '../../../Fab';
import { IconButton } from '../../../IconButton';
import { useModal } from '../../../ModalsProvider';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../Page';
import { Tooltip } from '../../../Tooltip';
import { VerticalDivider } from '../../../VerticalDivider';
import { CreateAccountModal } from '../CreateAccountModal';
import { LoginAccountsContext } from '../LoginAccountsProvider';

export const Header = ({ service }) => {
  const { openModal } = useModal();
  const { addLoginAccount } = useContext(LoginAccountsContext);

  const sendEmail = () => {
    openModal(EmailsSendModal, {
      payload: {
        to: service.email ? [ service.email ] : []
      }
    });
  };

  const addAccount = () => {
    openModal(CreateAccountModal, {
      payload: {
        service
      },
      onModalResolved: (account) => {
        addLoginAccount(account);
      }
    });
  };

  return (
    <PageHeader>
      <PageHeaderTitle secondary={service.description}>
        {service.name}
      </PageHeaderTitle>

      <PageHeaderActions disableSpacing>
        <Tooltip title="Send email to the service contact center">
          <IconButton edge="end" onClick={sendEmail}>
            <EmailIcon />
          </IconButton>
        </Tooltip>

        {hasRole(rolesMap.admin, rolesMap.supervisor) &&
          <>
            <VerticalDivider horizontalGutters={2} verticalGutters={1} />

            <Fab
              variant="extended"
              color="primary"
              startIcon={<AddIcon />}
              onClick={addAccount}
            >
              Add account
            </Fab>
          </>
        }
      </PageHeaderActions>
    </PageHeader>
  );
};
