import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Button, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { getUserNameAbbr } from '../../helpers/users';
import { Avatar } from '../Avatar';
import { TextField } from '../FormField';
import { Loader } from '../Loader';
import { CommentsContext } from './CommentsContext';
import * as types from './CommentsContext/types';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointSmall = 480;
export const widthBreakpointLarge = 830;

export const Comments = ({
  disableForm = false,
  maxHeight = '100%',
  width,
  parentType,
  parentID,
  parentPage,
  CreationFormProps: { TextFieldProps = {}, ...CreationFormProps } = {},
  onCommentAdded = () => {}
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { comments, ...commentsContext } = useContext(CommentsContext);
  const currentUser = useSelector(({ profile }) => profile.user);
  const commentsLastGlobalAction = useSelector(({ globalActions }) => globalActions.commentsLastGlobalAction);

  useEffect(() => {
    commentsContext.fetchComments();
  }, []);

  const createComment = (values, { resetForm, setErrors }) => {
    return commentsContext.createComment(values).then(() => {
      resetForm();
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  useEffect(() => {
    onCommentAdded(comments);
  }, [ comments.length ]);

  useEffect(() => {
    if (commentsLastGlobalAction) {
      if (
        commentsLastGlobalAction.type === types.ADD_COMMENT ||
        commentsLastGlobalAction.type === types.UPDATE_COMMENT
      ) {
        commentsContext.fetchComments();
      }
    }
  }, [ commentsLastGlobalAction ]);

  return (
    <>
      {!disableForm && (!parentPage || width > widthBreakpointSmall) &&
        <Formik
          initialValues={{
            text: '',
            parent_type: parentType,
            parent_id: parentID
          }}
          onSubmit={createComment}
        >
          {({ handleSubmit, isSubmitting, dirty, resetForm }) => (
            <form onSubmit={handleSubmit} className={classes.formWrapper}>
              <div className={classes.form} {...CreationFormProps}>
                <Avatar
                  src={currentUser?.profile?.avatar || currentUser?.avatar}
                  alt={getUserNameAbbr(currentUser)}
                  className={classes.userImage}
                />

                <TextField
                  multiline
                  name="text"
                  placeholder="Write a comment"
                  margin="dense"
                  {...TextFieldProps}
                  InputProps={{
                    className: classes.input,
                    ...(TextFieldProps.InputProps || {})
                  }}
                />
              </div>

              <div className={dirty ? classes.formButton : classes.formHiddenButton}>
                <Button
                  disabled={!dirty}
                  size={isTablet ? 'small' : 'medium'}
                  className={classes.cancelBtn}
                  onClick={resetForm}
                >
                  Cancel
                </Button>

                <Loader  surface loading={isSubmitting} render={
                  () => (
                    <Button
                      size={isTablet ? 'small' : 'medium'}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!dirty || isSubmitting}
                    >
                      Comment
                    </Button>
                  )}
                />
              </div>
            </form>
          )}
        </Formik>
      }

      <List
        disableForm
        width={width}
        maxHeight={maxHeight}
        parentPage={parentPage}
        parentType={parentType}
        parentID={parentID}
      />
    </>
  );
};
