import body from './body.png';

export const styles = ({ spacing, palette, transitions, breakpoints }) => ({
  root: {
    paddingLeft: spacing(2),

    [breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  },

  title: {
    color: palette.grey[500]
  },

  content: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: spacing(2, 0),

    [breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },

  contentInfo: {
    position: 'relative',
    marginRight: spacing(4),
    minWidth: 75,
    width: 72,
    height: 72,
    overflow: 'hidden',

    '&:hover': {
      '& $image': {
        filter: 'blur(1px)'
      },

      '& $actions': {
        opacity: 1
      }
    }
  },

  image: {
    width: '100%',
    height: '100%',
    background: `${palette.grey[500]} url(${body}) center / cover`,
    border: `1px solid ${palette.grey[500]}`,
    transition: transitions.create()
  },

  actions: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 188, 212, 0.2)',
    border: `1px solid ${palette.primary.main}`,
    transition: transitions.create()
  },

  list: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: spacing(3),
    marginLeft: spacing(4),

    [breakpoints.down('md')]: {
      marginLeft: 0,
      paddingRight: 0
    }
  }
});
