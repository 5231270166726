export const styles = ({ palette: { grey, ...palette }, breakpoints, spacing }) => ({
  headerFirst: {
    color: palette.secondary.light,

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      marginTop: spacing()
    }
  },

  headerSecond: {
    color: palette.lightgreen,

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      marginTop: spacing(1.5)
    }
  },

  headerThird: {
    color: grey[500],

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      marginTop: spacing(1.5)
    }
  }
});
