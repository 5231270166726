import { useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiHomeCityOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { dataURItoBlob } from '../../../../../helpers/files';
import * as usersApi from '../../../../../api/users';
import * as companyApi from '../../../../../api/companies';
import { Loader } from '../../../../../components/Loader';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContainer
} from '../../../../../components/Modal';
import { CompanyForm } from '../CompanyForm';
import { validationSchema } from '../validationSchema';
import { initialValues } from './initialValues';

export const CreateCompanyModal = ({
  DialogProps,
  payload: {
    insuranceInfo = {},
    type_insurance = null,
    status_type = null,
    company_type_id = null,
    disableCourtField = false
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ avatarDataURL, setAvatarDataURL ] = useState(null);

  const createCompany = ({ values, setErrors }) => {
    return companyApi.createCompany({
      ...values,

      rate: values?.rate ? +values?.rate * 100 : null,
      offices: values?.offices?.map((office) => ({ id: office?.id?.id })),
      venue:  values.venue.map((venue) => {
        return venue.is_main ? ({ ...venue, is_main: !!+venue.is_main }) : venue;
      })
    }).then((data) => {
      const venue = data?.venue?.find(({ is_main }) => is_main > 0);

      enqueueSnackbar('Company successfully created', { variant: 'success' });
      handleModalResolve({
        billing_full_address: data?.billing?.full_address,
        venue_full_address: venue?.full_address,
        ...data
      });
    }).catch(({ status, data: { errors } }) => {
      if (status === 422) {
        setErrors(errors);
      }
    });
  };

  const addCompany = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (!!values?.venue?.length) {
      const isMainVenue = values.venue.find((item) => item.is_main > 0);

      if (!isMainVenue) {
        values.venue[0] = { ...values.venue[0], is_main: 1 };
      }
    }

    if (avatarDataURL) {
      const formData = new FormData();

      formData.append('file', dataURItoBlob(avatarDataURL));

      return usersApi.uploadAvatar(formData).then((avatar) => {
        values.avatar_id = avatar.id;

        return createCompany({ values, setErrors });
      }).catch(({ status, data: { errors } }) => {
        if (status === 422) {
          setErrors(errors);
        }
      });
    } else {
      return createCompany({ values, setErrors });
    }
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,

        name: insuranceInfo.name,
        email: insuranceInfo.attorney_email,
        phone: insuranceInfo.attorney_phone,
        type_insurance,
        status_type,
        company_type_id,
        disableCourtField
      }}
      validationSchema={validationSchema}
      onSubmit={addCompany}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiHomeCityOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Add New Company
            </ModalHeader>

            <ModalBody>
              <CompanyForm insuranceInfo={insuranceInfo} onAvatarUrlChange={setAvatarDataURL} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
