export const styles = ({ spacing, palette, shape: { borderRadius } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    flexGrow: 1
  },

  mainContent: {
    flexGrow: 1,
    overflow: 'auto'
  },

  actionButton: {
    flexGrow: 0,
    marginLeft: 'auto',
    paddingBottom: spacing(2)
  },

  backButton: {
    marginRight: spacing()
  },

  datepickerWrapper: {
    minWidth: 335
  },

  datepicker: {
    maxWidth: 320,
    padding: spacing(0.5),
    border: `1px solid ${palette.divider}`,
    borderRadius
  },

  apptList: {
    border: `1px solid ${palette.divider}`,
    borderBottom: 'none'
  }
});
