import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { api } from '../../../api';
import * as proceduresApi from '../../../api/profile';
import { UserContext } from '../../../app/Dashboard/Members/User/UserProvider';
import { CustomScrollbars } from '../../CustomScrollbars';
import { Loader } from '../../Loader';
import { CurrentMedications } from './CurrentMedications';
import { ComplaintSymptoms } from './ComplaintSymptoms';
import { ReferralNotes } from './ReferralNotes';
import { Prescribe } from './Prescribe';
import { Diagnosis } from './Diagnosis';

export const MedicalReportContent = ({ isOpenSwitches, userID, toggleSwitches }) => {
  const cancelFetch = useRef(() => {});
  const [ reports, setReports ] = useState({});
  const [ error, setError ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);
  const currentUser = useSelector(({ profile }) => profile.user);
  const userContext = useContext(UserContext);
  const user = userContext?.user || currentUser;
  const scrollElementRef = useRef();

  const fetchReports = () => {
    cancelFetch.current();
    setIsFetched(false);

    proceduresApi.fetchMedicalReports({
      params: { patient_id: userID },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      setIsFetched(true);
      setReports(data);
    }).catch((error) => {
      if (error) {
        setError('Something went wrong, try again later');
        setIsFetched(true);
      }
    });
  };

  useEffect(() => {
    fetchReports();
  }, [ user?.id ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <Box flexGrow={1}>
      <Loader p={3} loading={!isFetched} render={
        () => !error ? (
          <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
            <ComplaintSymptoms
              fetchReports={fetchReports}
              isOpenSwitches={isOpenSwitches}
              report={reports?.complaint_symptoms}
              toggleSwitches={toggleSwitches}
            />

            <Diagnosis
              isOpenSwitches={isOpenSwitches}
              report={reports?.diagnosis}
              toggleSwitches={toggleSwitches}
            />

            <ReferralNotes
              isOpenSwitches={isOpenSwitches}
              report={reports?.referral_notes}
              toggleSwitches={toggleSwitches}
            />

            <CurrentMedications
              fetchReports={fetchReports}
              isOpenSwitches={isOpenSwitches}
              report={reports?.current_medications}
              toggleSwitches={toggleSwitches}
            />

            <Prescribe
              isOpenSwitches={isOpenSwitches}
              report={reports?.prescribe}
              toggleSwitches={toggleSwitches}
            />
          </CustomScrollbars>
        ) : (
          <Typography align="center">{error}</Typography>
        )}
      />
    </Box>
  );
};
