import { InputAdornment, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { TextField } from '../../../../../components/FormField';

export const Input = ({ anchorRef, value, onChange = () => {}, ...props }) => {
  const handleSearchChange = ({ target }) => {
    onChange(target.value);
  };

  const clearInput = () => {
    onChange('');
  };

  return (
    <TextField
      ref={anchorRef}
      placeholder="Search..."
      value={value}
      InputProps={{
        endAdornment: !!value && (
          <InputAdornment position="end">
            <IconButton edge="end" title="Clear" onClick={clearInput}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }}
      onChange={handleSearchChange}

      {...props}
    />
  );
};
