import { transformInvoiceStatusToOption } from '../../../../../../../components/FormField';
import { orderByOptions } from '../../../InvoicesContext';

export const transformRelationsForFilterToOptions = (relationsForFilter) => {
  return {
    ...relationsForFilter,

    appointment_id: relationsForFilter?.appointment_id || null,
    claim_id: relationsForFilter?.claim_id || null,
    status_id: transformInvoiceStatusToOption(relationsForFilter?.status_id),
    order_by: orderByOptions.find(({ value }) => value === relationsForFilter.order_by)
  };
};
