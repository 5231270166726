import { reminderEventTypeLabelsMap, reminderEventTypesMap } from './reminderEventTypesMap';

export const reminderEventTypesOptions = [
  {
    value: reminderEventTypesMap.REJECT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.REJECT]
  },
  {
    value: reminderEventTypesMap.APPOINTMENT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.APPOINTMENT]
  },
  {
    value: reminderEventTypesMap.NOTICE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.NOTICE]
  },
  {
    value: reminderEventTypesMap.NOTIFY,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.NOTIFY]
  },
  {
    value: reminderEventTypesMap.ARBITRATION,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.ARBITRATION]
  },
  {
    value: reminderEventTypesMap.REQUEST,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.REQUEST]
  },
  {
    value: reminderEventTypesMap.ARBHRG,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.ARBHRG]
  },
  {
    value: reminderEventTypesMap.DEADLINE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.DEADLINE]
  },
  {
    value: reminderEventTypesMap.REMINDER,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.REMINDER]
  },
  {
    value: reminderEventTypesMap.REQ_AMB,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.REQ_AMB]
  },
  {
    value: reminderEventTypesMap.FOLLOW,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.FOLLOW]
  },
  {
    value: reminderEventTypesMap.CONFIRM,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.CONFIRM]
  },
  {
    value: reminderEventTypesMap.F_ANSWER,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_ANSWER]
  },
  {
    value: reminderEventTypesMap.E_SERVE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.E_SERVE]
  },
  {
    value: reminderEventTypesMap.S_COMPLA,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_COMPLA]
  },
  {
    value: reminderEventTypesMap.F_PROOF,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_PROOF]
  },
  {
    value: reminderEventTypesMap.N_ARBIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.N_ARBIT]
  },
  {
    value: reminderEventTypesMap.D_LIST,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.D_LIST]
  },
  {
    value: reminderEventTypesMap.S_LISTS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_LISTS]
  },
  {
    value: reminderEventTypesMap.S_SUBPEN,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_SUBPEN]
  },
  {
    value: reminderEventTypesMap.AWARD,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.AWARD]
  },
  {
    value: reminderEventTypesMap.S_AWARD,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_AWARD]
  },
  {
    value: reminderEventTypesMap.F_APPLIC,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_APPLIC]
  },
  {
    value: reminderEventTypesMap.CORRECT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.CORRECT]
  },
  {
    value: reminderEventTypesMap.F_OBJECT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_OBJECT]
  },
  {
    value: reminderEventTypesMap.PETITION,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.PETITION]
  },
  {
    value: reminderEventTypesMap.F_PETIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_PETIT]
  },
  {
    value: reminderEventTypesMap.F_RESPON,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_RESPON]
  },
  {
    value: reminderEventTypesMap.H_PETIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_PETIT]
  },
  {
    value: reminderEventTypesMap.N_HEAR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.N_HEAR]
  },
  {
    value: reminderEventTypesMap.REMOTE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.REMOTE]
  },
  {
    value: reminderEventTypesMap.APPOINT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.APPOINT]
  },
  {
    value: reminderEventTypesMap.H_ARBIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_ARBIT]
  },
  {
    value: reminderEventTypesMap.SELECT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.SELECT]
  },
  {
    value: reminderEventTypesMap.ORDER,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.ORDER]
  },
  {
    value: reminderEventTypesMap.PLEAD,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.PLEAD]
  },
  {
    value: reminderEventTypesMap.DELIVER,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.DELIVER]
  },
  {
    value: reminderEventTypesMap.S_DEMAND,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_DEMAND]
  },
  {
    value: reminderEventTypesMap.F_SETTLE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_SETTLE]
  },
  {
    value: reminderEventTypesMap.S_998,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_998]
  },
  {
    value: reminderEventTypesMap.BEGDISC,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.BEGDISC]
  },
  {
    value: reminderEventTypesMap.DISMISS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.DISMISS]
  },
  {
    value: reminderEventTypesMap.JUDGMENT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.JUDGMENT]
  },
  {
    value: reminderEventTypesMap.S_WRIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_WRIT]
  },
  {
    value: reminderEventTypesMap.F_OPPO,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_OPPO]
  },
  {
    value: reminderEventTypesMap.F_EXEMPT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_EXEMPT]
  },
  {
    value: reminderEventTypesMap.F_WRIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_WRIT]
  },
  {
    value: reminderEventTypesMap.H_WRIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_WRIT]
  },
  {
    value: reminderEventTypesMap.REPLY,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.REPLY]
  },
  {
    value: reminderEventTypesMap.MEET,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.MEET]
  },
  {
    value: reminderEventTypesMap.F_STMT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_STMT]
  },
  {
    value: reminderEventTypesMap.C_SETTLE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.C_SETTLE]
  },
  {
    value: reminderEventTypesMap.TRIAL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.TRIAL]
  },
  {
    value: reminderEventTypesMap.F_ARBSTP,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_ARBSTP]
  },
  {
    value: reminderEventTypesMap.F_STIP,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_STIP]
  },
  {
    value: reminderEventTypesMap.$ARBLIST,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.$ARBLIST]
  },
  {
    value: reminderEventTypesMap.D_FEES,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.D_FEES]
  },
  {
    value: reminderEventTypesMap.DISCOVER,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.DISCOVER]
  },
  {
    value: reminderEventTypesMap.N_CMC,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.N_CMC]
  },
  {
    value: reminderEventTypesMap.DEFAULT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.DEFAULT]
  },
  {
    value: reminderEventTypesMap.F_DEMUR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_DEMUR]
  },
  {
    value: reminderEventTypesMap.DEMUR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.DEMUR]
  },
  {
    value: reminderEventTypesMap.R_STMNT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.R_STMNT]
  },
  {
    value: reminderEventTypesMap.S_RESPON,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_RESPON]
  },
  {
    value: reminderEventTypesMap.S_STMT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_STMT]
  },
  {
    value: reminderEventTypesMap.F_SJUDG,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_SJUDG]
  },
  {
    value: reminderEventTypesMap.AMEND,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.AMEND]
  },
  {
    value: reminderEventTypesMap.TRLSET,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.TRLSET]
  },
  {
    value: reminderEventTypesMap.PET_ARB,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.PET_ARB]
  },
  {
    value: reminderEventTypesMap.N_VEXLIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.N_VEXLIT]
  },
  {
    value: reminderEventTypesMap.N_DEPO_P,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.N_DEPO_P]
  },
  {
    value: reminderEventTypesMap.N_DEPO_D,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.N_DEPO_D]
  },
  {
    value: reminderEventTypesMap.S_PRODOC,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_PRODOC]
  },
  {
    value: reminderEventTypesMap.S_INTROG,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_INTROG]
  },
  {
    value: reminderEventTypesMap.D_EXAM,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.D_EXAM]
  },
  {
    value: reminderEventTypesMap.C_CMC,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.C_CMC]
  },
  {
    value: reminderEventTypesMap.S_RQAD,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_RQAD]
  },
  {
    value: reminderEventTypesMap.F_CCCS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_CCCS]
  },
  {
    value: reminderEventTypesMap.M_STAY,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_STAY]
  },
  {
    value: reminderEventTypesMap.RULING,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.RULING]
  },
  {
    value: reminderEventTypesMap.F_REMOVE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_REMOVE]
  },
  {
    value: reminderEventTypesMap.P_CHALL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.P_CHALL]
  },
  {
    value: reminderEventTypesMap.D_CHALL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.D_CHALL]
  },
  {
    value: reminderEventTypesMap.H_DEMUR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_DEMUR]
  },
  {
    value: reminderEventTypesMap.D_ENTER,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.D_ENTER]
  },
  {
    value: reminderEventTypesMap.HEARING,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.HEARING]
  },
  {
    value: reminderEventTypesMap.F_MOTION,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_MOTION]
  },
  {
    value: reminderEventTypesMap.ACKNLDGE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.ACKNLDGE]
  },
  {
    value: reminderEventTypesMap.F_QUASH,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_QUASH]
  },
  {
    value: reminderEventTypesMap.M_FRESP,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_FRESP]
  },
  {
    value: reminderEventTypesMap.S_DEMUR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_DEMUR]
  },
  {
    value: reminderEventTypesMap.ARGUE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.ARGUE]
  },
  {
    value: reminderEventTypesMap.S_OBJECT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_OBJECT]
  },
  {
    value: reminderEventTypesMap.F_COMPEL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_COMPEL]
  },
  {
    value: reminderEventTypesMap.AUDIOVID,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.AUDIOVID]
  },
  {
    value: reminderEventTypesMap.COMPLY,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.COMPLY]
  },
  {
    value: reminderEventTypesMap.C_TRNS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.C_TRNS]
  },
  {
    value: reminderEventTypesMap.R_TRNS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.R_TRNS]
  },
  {
    value: reminderEventTypesMap.P_ORDER,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.P_ORDER]
  },
  {
    value: reminderEventTypesMap.MAILCOMP,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.MAILCOMP]
  },
  {
    value: reminderEventTypesMap.S_COMP_M,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_COMP_M]
  },
  {
    value: reminderEventTypesMap.RESPONSE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.RESPONSE]
  },
  {
    value: reminderEventTypesMap.M_DISMIS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_DISMIS]
  },
  {
    value: reminderEventTypesMap.M_GRANT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_GRANT]
  },
  {
    value: reminderEventTypesMap.S_DOCS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_DOCS]
  },
  {
    value: reminderEventTypesMap.S_WITLST,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_WITLST]
  },
  {
    value: reminderEventTypesMap.S_COPIES,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_COPIES]
  },
  {
    value: reminderEventTypesMap.S_QUEST,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_QUEST]
  },
  {
    value: reminderEventTypesMap.S_INSTR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_INSTR]
  },
  {
    value: reminderEventTypesMap.VERDICT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.VERDICT]
  },
  {
    value: reminderEventTypesMap.S_GLOSS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_GLOSS]
  },
  {
    value: reminderEventTypesMap.S_LIMINE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_LIMINE]
  },
  {
    value: reminderEventTypesMap.F_LIMINE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_LIMINE]
  },
  {
    value: reminderEventTypesMap.LODGE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.LODGE]
  },
  {
    value: reminderEventTypesMap.C_PRETRL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.C_PRETRL]
  },
  {
    value: reminderEventTypesMap.EXCHANGE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.EXCHANGE]
  },
  {
    value: reminderEventTypesMap.EXAM,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.EXAM]
  },
  {
    value: reminderEventTypesMap.D_REPORT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.D_REPORT]
  },
  {
    value: reminderEventTypesMap.LATERPT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.LATERPT]
  },
  {
    value: reminderEventTypesMap.F_I_COMP,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_I_COMP]
  },
  {
    value: reminderEventTypesMap.S_I_COMP,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_I_COMP]
  },
  {
    value: reminderEventTypesMap.N_JDGMNT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.N_JDGMNT]
  },
  {
    value: reminderEventTypesMap.F_BRIEF,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_BRIEF]
  },
  {
    value: reminderEventTypesMap.H_MOTION,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_MOTION]
  },
  {
    value: reminderEventTypesMap.S_MEMO,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_MEMO]
  },
  {
    value: reminderEventTypesMap.M_STRIKE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_STRIKE]
  },
  {
    value: reminderEventTypesMap.F_APPEAL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_APPEAL]
  },
  {
    value: reminderEventTypesMap.ATTYFEES,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.ATTYFEES]
  },
  {
    value: reminderEventTypesMap.MEDIATOR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.MEDIATOR]
  },
  {
    value: reminderEventTypesMap.MEDIATE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.MEDIATE]
  },
  {
    value: reminderEventTypesMap.ENDMEDT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.ENDMEDT]
  },
  {
    value: reminderEventTypesMap.S_SJUDG,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_SJUDG]
  },
  {
    value: reminderEventTypesMap.S_JTSTIP,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_JTSTIP]
  },
  {
    value: reminderEventTypesMap.H_NEWTRL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_NEWTRL]
  },
  {
    value: reminderEventTypesMap.M_NEWTRL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_NEWTRL]
  },
  {
    value: reminderEventTypesMap.DEPO,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.DEPO]
  },
  {
    value: reminderEventTypesMap.M_PORDER,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_PORDER]
  },
  {
    value: reminderEventTypesMap.F_TRNS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_TRNS]
  },
  {
    value: reminderEventTypesMap.S_1987,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_1987]
  },
  {
    value: reminderEventTypesMap.H_QUASH,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_QUASH]
  },
  {
    value: reminderEventTypesMap.S_QUASH,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_QUASH]
  },
  {
    value: reminderEventTypesMap.DEFDPLEA,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.DEFDPLEA]
  },
  {
    value: reminderEventTypesMap.D_JURY,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.D_JURY]
  },
  {
    value: reminderEventTypesMap.INSPECT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.INSPECT]
  },
  {
    value: reminderEventTypesMap.EXCHLST,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.EXCHLST]
  },
  {
    value: reminderEventTypesMap.C_MSC,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.C_MSC]
  },
  {
    value: reminderEventTypesMap.ADR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.ADR]
  },
  {
    value: reminderEventTypesMap.S_CUST,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_CUST]
  },
  {
    value: reminderEventTypesMap.ENFORCE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.ENFORCE]
  },
  {
    value: reminderEventTypesMap.OSC,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.OSC]
  },
  {
    value: reminderEventTypesMap.PET_WRIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.PET_WRIT]
  },
  {
    value: reminderEventTypesMap.S_STAY,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_STAY]
  },
  {
    value: reminderEventTypesMap.ASSIGN,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.ASSIGN]
  },
  {
    value: reminderEventTypesMap.S_JDGMNT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_JDGMNT]
  },
  {
    value: reminderEventTypesMap.N_TRIAL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.N_TRIAL]
  },
  {
    value: reminderEventTypesMap.F_INSTR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_INSTR]
  },
  {
    value: reminderEventTypesMap.F_EXPWIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_EXPWIT]
  },
  {
    value: reminderEventTypesMap.F_WITLST,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_WITLST]
  },
  {
    value: reminderEventTypesMap.S_BRIEF,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_BRIEF]
  },
  {
    value: reminderEventTypesMap.F_EXHLST,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_EXHLST]
  },
  {
    value: reminderEventTypesMap.D_EXPWIT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.D_EXPWIT]
  },
  {
    value: reminderEventTypesMap.H_EXPDIS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_EXPDIS]
  },
  {
    value: reminderEventTypesMap.EXPDISC,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.EXPDISC]
  },
  {
    value: reminderEventTypesMap.S_EXPDIS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_EXPDIS]
  },
  {
    value: reminderEventTypesMap.EXAMRPT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.EXAMRPT]
  },
  {
    value: reminderEventTypesMap.H_NONEXP,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_NONEXP]
  },
  {
    value: reminderEventTypesMap.H_SJUDG,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_SJUDG]
  },
  {
    value: reminderEventTypesMap.S_NONEXP,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_NONEXP]
  },
  {
    value: reminderEventTypesMap.N_EXDEPO,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.N_EXDEPO]
  },
  {
    value: reminderEventTypesMap.SUPPXPRT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.SUPPXPRT]
  },
  {
    value: reminderEventTypesMap.S_DISC_H,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_DISC_H]
  },
  {
    value: reminderEventTypesMap.S_DISC_M,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_DISC_M]
  },
  {
    value: reminderEventTypesMap.S_WRTDIS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_WRTDIS]
  },
  {
    value: reminderEventTypesMap.J_WAIVED,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.J_WAIVED]
  },
  {
    value: reminderEventTypesMap.REFUND,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.REFUND]
  },
  {
    value: reminderEventTypesMap.M_PLEAD,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_PLEAD]
  },
  {
    value: reminderEventTypesMap.M_ASIDE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_ASIDE]
  },
  {
    value: reminderEventTypesMap.S_DISC_E,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_DISC_E]
  },
  {
    value: reminderEventTypesMap.SUBPOENA,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.SUBPOENA]
  },
  {
    value: reminderEventTypesMap.S_DEPO,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_DEPO]
  },
  {
    value: reminderEventTypesMap.F_998,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_998]
  },
  {
    value: reminderEventTypesMap.OBJECT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.OBJECT]
  },
  {
    value: reminderEventTypesMap.H_BIFUR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.H_BIFUR]
  },
  {
    value: reminderEventTypesMap.S_BIFUR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_BIFUR]
  },
  {
    value: reminderEventTypesMap.CHALLENG,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.CHALLENG]
  },
  {
    value: reminderEventTypesMap.PRESENT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.PRESENT]
  },
  {
    value: reminderEventTypesMap.N_SETTLE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.N_SETTLE]
  },
  {
    value: reminderEventTypesMap.F_STMNT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_STMNT]
  },
  {
    value: reminderEventTypesMap.DEPO_EXP,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.DEPO_EXP]
  },
  {
    value: reminderEventTypesMap.EXP_PROD,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.EXP_PROD]
  },
  {
    value: reminderEventTypesMap.F_MEMO,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_MEMO]
  },
  {
    value: reminderEventTypesMap.M_SJUDG,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_SJUDG]
  },
  {
    value: reminderEventTypesMap.SECURITY,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.SECURITY]
  },
  {
    value: reminderEventTypesMap.F_EXTENS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_EXTENS]
  },
  {
    value: reminderEventTypesMap.S_XQUES,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_XQUES]
  },
  {
    value: reminderEventTypesMap.S_REDRCT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_REDRCT]
  },
  {
    value: reminderEventTypesMap.S_RECRSS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.S_RECRSS]
  },
  {
    value: reminderEventTypesMap.OVERRULE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.OVERRULE]
  },
  {
    value: reminderEventTypesMap.F_COMPE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_COMPE]
  },
  {
    value: reminderEventTypesMap.F_S_ANSW,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.F_S_ANSW]
  },
  {
    value: reminderEventTypesMap.REQCHIRO,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.REQCHIRO]
  },
  {
    value: reminderEventTypesMap.FILE_CMS,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.FILE_CMS]
  },
  {
    value: reminderEventTypesMap.M_AND_C,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.M_AND_C]
  },
  {
    value: reminderEventTypesMap.JURY_FEE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.JURY_FEE]
  },
  {
    value: reminderEventTypesMap.LD,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.LD]
  },
  {
    value: reminderEventTypesMap.COMPEL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.COMPEL]
  },
  {
    value: reminderEventTypesMap.E_FILING,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.E_FILING]
  },
  {
    value: reminderEventTypesMap.STIP_ARB,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.STIP_ARB]
  },
  {
    value: reminderEventTypesMap.STATUTE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.STATUTE]
  },
  {
    value: reminderEventTypesMap.REQ_REC,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.REQ_REC]
  },
  {
    value: reminderEventTypesMap.CLCLIENT,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.CLCLIENT]
  },
  {
    value: reminderEventTypesMap.MEDI_CAL,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.MEDI_CAL]
  },
  {
    value: reminderEventTypesMap.MEDICARE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.MEDICARE]
  },
  {
    value: reminderEventTypesMap.REP_LTR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.REP_LTR]
  },
  {
    value: reminderEventTypesMap.EVIDENCE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.EVIDENCE]
  },
  {
    value: reminderEventTypesMap.MEDPAY,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.MEDPAY]
  },
  {
    value: reminderEventTypesMap.SCHEDULE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.SCHEDULE]
  },
  {
    value: reminderEventTypesMap.TELEAPPR,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.TELEAPPR]
  },
  {
    value: reminderEventTypesMap.CONTINUE,
    label: reminderEventTypeLabelsMap[reminderEventTypesMap.CONTINUE]
  }
];
