import { useState, createContext, useEffect } from 'react';
import { isEqual } from 'lodash';
import { orderDirectionsMap } from '../../../../../components/FormField';
import { orderByOptions } from '../MainWindow/FiltersBar/orderByOptions';

export const CasesFilterContext = createContext(null);

export const CasesFilterContextProvider = ({ children, filter: filterProp = {} }) => {
  const [ selectedCasesIDs, setSelectedCasesIDs ] = useState([]);
  const [ filter, setFilter ] = useState({
    ...filterProp,

    order_direction: orderDirectionsMap.desc,
    order_by: orderByOptions[0].value
  });

  const providerValue = {
    filter,
    selectedCasesIDs,

    setFilter,
    setSelectedCasesIDs
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(filter, newFilter)) {
      setFilter(newFilter);
    }
  }, [ filter, filterProp ]);

  return (
    <CasesFilterContext.Provider value={providerValue}>
      {children}
    </CasesFilterContext.Provider>
  );
};
