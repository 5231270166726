import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { flow } from 'lodash';
import { List, ListItemIcon, ListItemText, MenuItem, SvgIcon } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import {
  mdiAccountArrowLeftOutline,
  mdiBriefcaseCheckOutline,
  mdiCloudDownloadOutline,
  mdiFilePlusOutline
} from '@mdi/js';
import { Icon } from '@mdi/react';
import * as emailsApi from '../../../../../../api/emails';
import { downloadFile as download, isPrintable, printFile } from '../../../../../../helpers/files';
import { useModal } from '../../../../../../components/ModalsProvider';
import { MessagesContext } from '../../../MessagesContext';
import { AddAttachmentToCase } from './AddAttachmentToCase';
import { AddAttachmentToUser } from './AddAttachmentToUser';

export const Menu = ({
  selectedFiles = [],
  isDisabledView,
  attachment,
  openFilesPreview,
  isSavedAttachments = false,
  setSelectedFiles = () => {},
  onClose
}) => {
  const messagesContext = useContext(MessagesContext);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const addAttachmentToFiles = () => {
    emailsApi.addAttachmentToUser({
      folder_id: messagesContext.filter.folder_id,
      message_uid: messagesContext.messageItem.uid,
      attachments: !attachment ? selectedFiles : [ attachment.original_filename ]
    }).then(() => {
      enqueueSnackbar('Attachment added to files', { variant: 'success' });
      setSelectedFiles([]);
    }).catch(() => {
      enqueueSnackbar('Attachment not added to files', { variant: 'error' });
    });

    onClose();
  };

  const addAttachmentToCase = () => {
    openModal(AddAttachmentToCase, {
      payload: {
        folderID: messagesContext.filter.folder_id,
        messageUIDs: messagesContext.messageItem.uid,
        attachmentName: attachment ? attachment.original_filename : null,
        selectedFiles
      },
      onModalResolved: () => {
        setSelectedFiles([]);
      }
    });

    onClose();
  };

  const addAttachmentToUser = () => {
    openModal(AddAttachmentToUser, {
      payload: {
        folderID: messagesContext.filter.folder_id,
        messageUIDs: messagesContext.messageItem.uid,
        attachmentName: attachment ? attachment.original_filename : null,
        selectedFiles
      },
      onModalResolved: () => {
        setSelectedFiles([]);
      }
    });

    onClose();
  };

  const downloadFile = () => {
    download({ src: attachment.url, name: attachment.original_filename });
  };

  const handlePrintFile = () => {
    printFile(attachment);
  };

  return (
    <List>
      {!!openFilesPreview &&
        <MenuItem disabled={isDisabledView} onClick={flow(openFilesPreview, onClose)}>
          <ListItemIcon>
            <EyeIcon fontSize="inherit" />
          </ListItemIcon>

          <ListItemText primary="Preview" />
        </MenuItem>
      }

      {!isSavedAttachments &&
        <>
          <MenuItem onClick={addAttachmentToFiles}>
            <ListItemIcon>
              <SvgIcon><Icon path={mdiFilePlusOutline} /></SvgIcon>
            </ListItemIcon>

            <ListItemText primary="Add to my files" />
          </MenuItem>

          <MenuItem onClick={addAttachmentToCase}>
            <ListItemIcon>
              <SvgIcon><Icon path={mdiBriefcaseCheckOutline} /></SvgIcon>
            </ListItemIcon>

            <ListItemText primary="Attach to case" />
          </MenuItem>

          <MenuItem onClick={addAttachmentToUser}>
            <ListItemIcon>
              <SvgIcon><Icon path={mdiAccountArrowLeftOutline} /></SvgIcon>
            </ListItemIcon>

            <ListItemText primary="Attach to patient" />
          </MenuItem>
        </>
      }

      {!!attachment?.url && isPrintable(attachment) &&
        <MenuItem onClick={handlePrintFile}>
          <ListItemIcon>
            <PrintIcon />
          </ListItemIcon>

          <ListItemText primary="Print file" />
        </MenuItem>
      }

      {!!attachment?.url &&
        <MenuItem onClick={downloadFile}>
          <ListItemIcon>
            <SvgIcon><Icon path={mdiCloudDownloadOutline} /></SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Download file" />
        </MenuItem>
      }
    </List>
  );
};
