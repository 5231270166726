import { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../components/TablePagination';
import { Loader } from '../../../../../components/Loader';
import { InsurancesContext } from '../../../ProfilePage/insurance';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const List = ({ width }) => {
  const { isFetched, meta, insurances, resetInsurances } = useContext(InsurancesContext);
  const scrollElementRef = useRef();

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => !insurances.length ? (
          <Box p={3}>
            <Typography align="center">No insurances found</Typography>
          </Box>
        ) : (
          <>
            <TableHeader />

            <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
              <MuiList disablePadding>
                {insurances.map((insurance) => (
                  <Row
                    key={insurance.id}
                    width={width}
                    insurance={insurance}
                  />
                ))}
              </MuiList>

              <TablePagination pagination={meta} onChange={resetInsurances} />
            </CustomScrollbars>
          </>
        )}
      />
    </Box>
  );
};
