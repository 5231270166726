import { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, Typography } from '@material-ui/core';
import { TextField, Editor } from '../../../../../../components/FormField';
import { Signature } from '../../../../../../components/medical/reports/PreviewReportModal/Signature';
import { StepContext } from '../StepProvider';

export const Body = () => {
  const { dirty, values } = useFormikContext();
  const { setIsDirty, setIsSignUpdate } = useContext(StepContext);

  const onUpdate = () => {
    setIsSignUpdate(true);
  };

  useEffect(() => {
    setIsDirty(dirty);
  }, [ dirty ]);

  return (
    <Box flexGrow={1}>
      <Box px={1} mb={3}>
        <Typography paragraph variant="h4" color="secondary">
          Medical Causation
        </Typography>

        <Editor name="forms.medical_causation.text" margin="normal" />

        <Box mt={2}>
          <Typography paragraph variant="h4" color="secondary">
            Disclosure Notice
          </Typography>
        </Box>

        <TextField
          fullWidth
          multiline
          disableUnderline
          rows={4}
          name="forms.disclosure_notice.text"
          variant="filled"
        />
      </Box>

      <Signature report={values} onUpdate={onUpdate} />
    </Box>
  );
};
