import { useState } from 'react';
import { useSnackbar } from 'notistack';
import * as filesApi from '../../api/files';
import { useModal } from '../../components/ModalsProvider';
import { FileValidationConflictModal } from '../../app/Dashboard/files-common';
import { generateAggregateFileType } from '../generateAggregateFileType';

export const useFileValidation = ({
  isPersonalShared = false,
  onFileValidated = () => {},
  owner_type,
  owner_id
}) => {
  const [ validatedFiles, setValidatedFiles ] = useState([]);
  const [ filesOnValidationCount, setFilesOnValidationCount ] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const validateFile = (file) => {
    file.original_filename = file.original_filename || file.name;
    file.owner_type = owner_type;
    file.owner_id = owner_id;

    if (file?.original_filename?.length >= 120) {
      openModal(FileValidationConflictModal, {
        payload: {
          title: 'The original filename may not be greater than 120 characters',
          file
        },
        onModalResolved: (file) => {
          validateFile(file);
        }
      });
    } else {
      if (validatedFiles.find(({ original_filename }) => original_filename === file.original_filename)) {
        openModal(FileValidationConflictModal, {
          payload: { file, isPersonalShared },
          onModalResolved: (file) => {
            validateFile(file);
          }
        });
      } else {
        setFilesOnValidationCount((state) => state + 1);

        filesApi[isPersonalShared ? 'validatePersonalSharedFile' : 'validateFile'](file).then(() => {
          if (!isPersonalShared) {
            file.aggregate_type = generateAggregateFileType(file.type);
          }

          setValidatedFiles((state) => [ file ].concat(state));
          onFileValidated(file);
        }).catch(({ status }) => {
          if (status === 409 ) {
            openModal(FileValidationConflictModal, {
              payload: { file, isPersonalShared },
              onModalResolved: (file) => {
                validateFile(file);
              }
            });
          } else {
            enqueueSnackbar(`File "${file.original_filename}" is invalid`, {
              variant: 'error'
            });
          }
        }).then(() => {
          setFilesOnValidationCount((state) => state - 1);
        });
      }
    }
  };

  const validateFiles = (files) => {
    files.forEach((file) => {
      validateFile(file);
    });
  };

  return {
    filesOnValidationCount,
    validatedFiles,
    validateFile,
    validateFiles
  };
};
