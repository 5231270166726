import moment from 'moment';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, SvgIcon, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import { mdiTimerOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as categoriesApi from '../../../../../api/time-tracks';
import { Loader } from '../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../components/Modal';
import { Body, validationSchema } from '../CreateTimeTrackCategoryModal';

export const UpdateTimeTrackCategoryModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { initialValues = {} }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const duration = initialValues.time && moment.duration(initialValues.time, 'seconds');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const updateCategory = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.time = moment.duration(`${values.hours || 0}:${values.minutes || 0}`).asSeconds();
    values.rate = values.rate ? +values.rate * 100 : initialValues?.rate;

    return categoriesApi.updateCategory(values).then((data) => {
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }

      enqueueSnackbar('Not updated', { variant: 'error' });
    });
  };

  return (
    <Dialog fullScreen={isMobile} {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,

          company_id: initialValues.company,
          case_id: initialValues.case,
          hours: duration?.hours() || 0,
          minutes: duration?.minutes() || 0,
          rate: initialValues?.rate ? initialValues?.rate / 100 : null
        }}
        validationSchema={validationSchema}
        onSubmit={updateCategory}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiTimerOutline}/></SvgIcon>}
              onClose={handleModalReject}
            >
              Update Category
            </ModalHeader>

            <ModalBody>
              <Body />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
