export const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.background.paper
  },

  toolbar: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between'
    }
  }
});
