import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import * as testCodesApi from '../../../../api/codes/reports';
import { Select } from '../Select';
import { Option } from './Option';

export const transformTestToOption = (item) => item && ({
  value: item.id,
  label: item.name,
  data: item
});

const fetchTests = (params) => fetchDataForAsyncSelect((name) => {
  return testCodesApi.fetchTests({ params: { name, ...params } });
}, {
  withoutPagination: true,
  transformDataToOption: transformTestToOption
});

const components = {
  Option
};

export const TestCodesSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      components={components}
      name="test_id"
      label="Name lab test"
      promiseWithOptions={fetchTests(params)}
      {...props}
    />
  );
};
