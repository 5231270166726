import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths, widthBreakpointXS, widthBreakpointXXS } from '../MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = ({ width, parentPage }) => {
  const classes = useStyles();

  return (
    <ListRow header className={cn(classes.mainContent, { [classes.mainContent_transparent]: !!parentPage })}>
      {width > widthBreakpointXXS &&
        <ListRowCell grow flexBasis={columnsWidths.payer}>
          Payer
        </ListRowCell>
      }

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={columnsWidths.paid_date}>
          Paid Date
        </ListRowCell>
      }

      <ListRowCell grow flexBasis={columnsWidths.number}>
        Inv. #
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        Total
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount}>
        Amount Pay
      </ListRowCell>

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={columnsWidths.balance}>
          Balance
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
