import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Typography, Grid, SvgIcon, useTheme, useMediaQuery } from '@material-ui/core';
import { mdiShieldCheckOutline } from '@mdi/js';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@mdi/react';
import { ShortInsuranceInfo } from '../../../../../../../components/users/ShortInsuranceInfo';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { CardItem } from '../../../../../../../components/Cards';
import { Loader } from '../../../../../../../components/Loader';
import { Fab } from '../../../../../../../components/Fab';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { InsurancesContext, InsurancesProvider, InsuranceInfo, insuranceStatusTypesMap } from '../../../../insurance';
import { UserContext } from '../../../../../Members/User/UserProvider';
import { CaseInsuranceInfo } from './CaseInsuranceInfo';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const InsuranceDetails = ({
  caseItem = {},
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  onCaseItemUpdate = () => {},
  ...props
}) => {
  const userContext = useContext(UserContext);
  const currentUser = useSelector(({ profile }) => profile);
  const { user } = userContext || currentUser;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const filterProps = !!caseItem?.id
    ? { case_id: caseItem?.id }
    : { medical_id: user?.medical?.id || user?.profile?.medical?.id };

  const addInsurance = (callback) => () => {
    callback(!caseItem?.id ? user : {}, { ...caseItem, onCaseItemUpdate });
  };

  return (
    <InsurancesProvider filterProp={filterProps}>
      <InsurancesContext.Consumer>
        {({ isFetched, mainInsurance, insurances, createCaseInsurance, createInsurance }) => (
          <ContentCard
            fullHeight={!props.autoHeightMax}
            disableContentPaddings
            minWidth={250}
            title="Insurance Details"
            icon={
              <Fab
                size="small"
                color="primary"
                variant="extended"
                startIcon={<AddIcon />}
                onClick={
                  addInsurance(!caseItem?.id ? createInsurance : createCaseInsurance)
                }
              >
                insur. card
              </Fab>
            }
            leftActions={[
              <SvgIcon fontSize="small"><Icon path={mdiShieldCheckOutline} /></SvgIcon>
            ]}
            endIcon={
              <Box minWidth={65}>
                <Typography variant="h5"  color="inherit">
                  Total: {insurances.length}
                </Typography>
              </Box>
            }
            rightActions={isMobile || !!caseItem?.id ? [
              <WidgetsActionButtons
                isMinimizable
                page={page}
                type={props?.item?.i}
                isDisabledOpen={isDisabledOpen}
                onMinimize={onMinimize}
                onMaximized={onMaximized}
                onClose={onClose}
                onOpen={onOpen}
                onPutItem={onPutItem}
              />
            ] : [
              <ShortInsuranceInfo
                noWrap
                component={Box}
                textAlign="right"
                insurance={mainInsurance}
                className={classes.mainInsuranceInfo}
              />
            ]}
            {...props}
          >
            <Box p={2} pb={1} minHeight={height || 'auto'}>
              <Loader loading={!isFetched} render={
                () => !insurances.length ? (
                  <Typography align="center">No insurances</Typography>
                ) : (
                  <Grid container spacing={2} alignItems="stretch">
                    {insurances.map((insurance) => (
                      <Grid item key={insurance.id}>
                        <Box width={280} minHeight={385} height="100%">
                          <CardItem
                            borderWidth={3}
                            selectedBoxShadows
                            selected={!!mainInsurance && mainInsurance?.id === insurance?.id}
                          >
                            {insurance?.status_type === insuranceStatusTypesMap.case_insurance
                              ? (
                                <CaseInsuranceInfo
                                  withMenu
                                  insurance={{ ...insurance, caseItem }}
                                  onCaseItemUpdate={onCaseItemUpdate}
                                />
                              ) : (
                                <InsuranceInfo
                                  withMenu
                                  showMainCheck
                                  insurance={{ ...insurance, caseItem }}
                                  onCaseItemUpdate={onCaseItemUpdate}
                                />
                              )
                            }
                          </CardItem>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                )}
              />
            </Box>
          </ContentCard>
        )}
      </InsurancesContext.Consumer>
    </InsurancesProvider>
  );
};
