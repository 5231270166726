// TODO: uncomment after backend updating
import { EmailsWidget } from '../../../../../components/EmailsWidget';
import { TasksWidget } from '../../../../../components/tasks';
import * as cardsTypes from '../Layouts';
import { AllSchedules } from './AllSchedules';
// import { Archive } from './Archive';
import { MedicalInfo } from './MedicalInfo';
// import { Activity } from './Activity';
import { Files } from './Files';
// import { AnalyticsData } from './AnalyticsData';
// import { TimeTrackingBilling } from './TimeTrackingBilling';
import { WorkInfo } from './WorkInfo';

export const widgetsMap = {
  [cardsTypes.WORK_INFO]: {
    title: 'Work Info',
    component: WorkInfo
  },

  [cardsTypes.ALL_SCHEDULES]: {
    title: 'All Schedules',
    component: AllSchedules
  },

  [cardsTypes.MEDICAL_INFO]: {
    title: 'Medical Info',
    component: MedicalInfo
  },

  [cardsTypes.TASKS]: {
    title: 'Tasks',
    component: TasksWidget
  },

  // [cardsTypes.ACTIVITY]: {
  //   title: 'Activity',
  //   component: Activity
  // },

  [cardsTypes.EMAIL]: {
    title: 'Emails',
    component: EmailsWidget
  },

  [cardsTypes.FILES]: {
    title: 'Files',
    component: Files
  }

  // [cardsTypes.ANALYTICS_DATA]: {
  //   title: 'Analytics Data',
  //   component: AnalyticsData
  // },
  //
  // [cardsTypes.TIME_TRACKING_BILLING]: {
  //   title: 'Time Tracking and Billing',
  //   component: TimeTrackingBilling
  // },
  //
  // [cardsTypes.ARCHIVE]: {
  //   title: 'Archive',
  //   component: Archive
  // }
};
