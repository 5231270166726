import { useRef, useContext, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { InfiniteGridLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { ScreenshotsContext } from '../ScreenshotsContext';
import { ScreenshotsCard } from './ScreenshotsCard';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ScreenshotsGrid = () => {
  const {
    screenshots,
    isFetched,
    isFetching,
    loadNextPage,
    filter: { last_page, page },
    ...screenshotsContext
  } = useContext(ScreenshotsContext);
  const classes = useStyles();
  const scrollElementRef = useRef();

  useEffect(() => {
    screenshotsContext.fetchScreenshots();
  }, []);

  return (
    <div ref={scrollElementRef} className={classes.root}>
      <Loader loading={!isFetched} render={
        () => (
          <>
            {!screenshots.length &&
              <Typography align="center">
                Here will be some information from video screenshots
              </Typography>
            }

            {!!screenshots.length &&
              <InfiniteGridLoader
                items={screenshots}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={{ last_page, page }}
                CellComponent={ScreenshotsCard}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
                cellWidth={200}
                cellHeight={230}
              />
            }
          </>
        )}
      />
    </div>
  );
};
