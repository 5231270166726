import { transformToOption } from '../../../../../helpers/fetchDataForAsyncSelect';
import { transformTaskStatusToOption } from '../../../../../components/FormField';

export const transformRelationsForFilterToOptions = (relationsForFilter) => {
  return {
    ...relationsForFilter,

    search: relationsForFilter?.search,
    created_from: relationsForFilter?.created_from,
    created_to: relationsForFilter?.created_to,
    due_from: relationsForFilter?.due_from,
    due_to: relationsForFilter?.due_to,
    types: relationsForFilter?.types,
    statuses: relationsForFilter?.statuses?.map(transformTaskStatusToOption),
    priorities: relationsForFilter?.priorities?.map(transformToOption)
  };
};
