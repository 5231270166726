import { useState } from 'react';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import { Loader } from '../../../../../../components/Loader';
import { hasRole } from '../../../../../../utils/hasRole';
import { useModal } from '../../../../../../components/ModalsProvider';
import { SvgIcon } from '../../../../../../components/SvgIcon';
import { ScheduleEventTypeFormModal } from '../../ScheduleEventTypeFormModal';

export const CardMenu = ({ eventTypesContext, eventType }) => {
  const { openModal } = useModal();
  const [ isBusy, setIsBusy ] = useState(false);

  const toggleActiveEventType = () => {
    const data = { ...eventType, is_active: !eventType.is_active };

    setIsBusy(true);

    eventTypesContext.updateActiveEventTypeReq(data).then(() => {
      setIsBusy(false);
    });
  };

  const editEventType = () => {
    openModal(ScheduleEventTypeFormModal, {
      payload: {
        initialValues: eventType
      },

      onModalResolved: (eventType) => {
        eventTypesContext.updateActiveEventType(eventType);
      }
    });
  };

  const deleteEventType = () => {
    eventTypesContext.deleteEventType(eventType);
  };

  return (
    <List>
      <MenuItem onClick={toggleActiveEventType}>
        <ListItemIcon>
          <Loader
            surface
            loading={isBusy}
            render={() => (
              <SvgIcon color={eventType.is_active ? 'warning' : 'success'}>
                {eventType.is_active ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
              </SvgIcon>
            )
            }
          />
        </ListItemIcon>

        <ListItemText primary={eventType.is_active ? 'Inactive' : 'Active'} />
      </MenuItem>

      <MenuItem onClick={editEventType}>
        <ListItemIcon>
          <EditIcon color="primary" />
        </ListItemIcon>

        <ListItemText primary="Edit Event Type" />
      </MenuItem>

      {!hasRole('client', 'patient', 'secretary', 'advocate') &&
        <MenuItem onClick={deleteEventType}>
          <ListItemIcon>
            <SvgIcon color="error"><DeleteIcon /></SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      }
    </List>
  );
};
