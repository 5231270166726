export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  loader: {
    padding: spacing(2)
  },

  mainContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: spacing(2)
  },

  chartInfo: {
    display: 'flex',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  },

  content: {
    padding: spacing(2, 2, 0, 2),
    width: '50%',

    '&:last-child': {
      paddingRight: 0
    }
  }
});
