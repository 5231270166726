import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cn from 'classnames';
import { fade } from '@material-ui/core/styles';
import { makeStyles, Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../../../components/users';
import { Date } from '../../../../../../../../components/Date';
import { Loader } from '../../../../../../../../components/Loader';
import { UserLink } from '../../../../../../../../components/UserLink';
import { ClaimsContext } from '../../../../../../../../components/claims';
import { ClaimPreviewModal } from '../../../../ClaimPreviewModal';
import { columnsWidths } from '../../../SideBar';

const useStyles = makeStyles(({ spacing, palette, transitions }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1.5, 2),
    cursor: 'pointer',
    transition: transitions.create([ 'backgroundColor' ]),

    '&:hover': {
      backgroundColor: palette.grey[50]
    }
  },

  selected: {
    backgroundColor: fade(palette.primary.light, 0.2)
  }
}));

export const Row = ({ item: claim = {}, activeClaim, isLoaded }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const history = useHistory();
  const { filter } = useContext(ClaimsContext);
  const params = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const openUserPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id }
    });
  };

  const goClaimPage = () => {
    const isEditPage = history.location.pathname.includes('/edit');
    const pathEnding = isEditPage ? '/edit' : '';

    if (isMobile) {
      openModal(ClaimPreviewModal, {
        payload: { id: claim.id }
      });
    } else {
      if (hasRole(rolesMap.doctor, rolesMap.patient, rolesMap.client, rolesMap.medic)) {
        history.push(`/patients/${params.patientID}/medical-info/claims/${claim.id}` + pathEnding);
      } else {
        history.push(`/billing/claims/${claim.id}` + pathEnding);
      }
    }
  };

  return !isLoaded ? <Loader p={2} /> : (
    <div
      className={cn(classes.root, (activeClaim?.id === claim.id) && classes.selected)}
      onClick={goClaimPage}
    >
      <Box display="flex" flexBasis={columnsWidths.date} pr={2}>
        <Date
          color="secondary"
          variant="subtitle2"
          iconFontSize="small"
          format="L"
          date={claim.appointment?.appointment_at}
        />
      </Box>

      <Box display="flex" flexGrow={1} flexBasis={columnsWidths.claimNo}>
        {!filter?.patients?.length ? (
          <UserLink
            noWrap
            size="sm"
            user={claim.patient}
            onClick={stopPropagation(openUserPreview(claim.patient_id))}
          />
        ) : (
          <Typography>{claim.claim_number}</Typography>
        )}
      </Box>
    </div>
  );
};
