import { useEffect } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../components/InfiniteLoader';
import { Loader } from '../../../../components/Loader';
import { Sticky } from '../../../../components/Sticky';
import { MobileHeader } from './MobileHeader';
import { TableHeader } from './TableHeader';

export const FilesList = ({
  scrollElementRef,
  hiddenColumns,
  filesContext,
  Row,
  RowProps = {},
  isMobile = false
}) => {
  const {
    files,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    resetFiles
  } = filesContext;

  useEffect(() => {
    resetFiles();
  }, []);

  return (
    <Loader loading={!isFetched} render={
      () => !filter.total ? (
        <Typography align="center">No files found</Typography>
      ) : (
        <>
          <Sticky containerElement={scrollElementRef.current}>
            {isMobile ? (
              <MobileHeader filesContext={filesContext} hiddenColumns={hiddenColumns} />
            ) : (
              <TableHeader filesContext={filesContext} hiddenColumns={hiddenColumns} />
            )}
          </Sticky>

          <MuiList disablePadding>
            <InfiniteListLoader
              items={files}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={filter}
              RowProps={{ hiddenColumns, filesContext, ...RowProps }}
              RowComponent={Row}
              scrollElementRef={scrollElementRef}
              onNeedNextPage={loadNextPage}
            />
          </MuiList>
        </>
      )}
    />
  );
};
