import { useContext, useEffect, useRef, useState } from 'react';
import { omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { unixToEndOfDayUnix, unixToStartOfDayUnix } from '../../../../helpers/dates';
import { saveFilter } from '../../../../store/lastFilters';
import { hasRole } from '../../../../utils/hasRole';
import { CompaniesAutocomplete, KeyboardDatePicker, OrderByAutocomplete, orderDirectionsMap } from '../../../FormField';
import { FiltersBar as FiltersBarComponent } from '../../../FiltersBar';
import { IconComponent } from '../../../saved-filters';
import { UsersSelect } from '../../../users';
import { WorkSessionsContext } from '../../WorkSessionsProvider';
import { CardContent } from './CardContent';
import { List } from './List';
import { orderByOptions } from './orderByOptions';
import { transformRelationsForFilterToOptions } from './utils';

export const availableRolesForUsersSelect = [
  rolesMap.admin,
  rolesMap.medic,
  rolesMap.doctor,
  rolesMap.advocate,
  rolesMap.secretary,
  rolesMap.supervisor
];

const MODAL_WIDTH = 910;

export const filterFieldsMap = {
  companies: 'companies',
  users: 'users',
  startedFrom: 'started_from',
  startedTo: 'started_to',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.companies]: 'Companies',
  [filterFieldsMap.users]: 'Users',
  [filterFieldsMap.startedFrom]: 'From',
  [filterFieldsMap.startedTo]: 'To',
  [filterFieldsMap.orderBy]: 'Order By'
};

const initialValues = {
  companies: null,
  users: null,
  started_from: null,
  started_to: null,
  order_by: orderByOptions[0],
  order_direction: orderDirectionsMap.desc
};

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const theme = useTheme();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { initialFilter, filter, applyFilter } = useContext(WorkSessionsContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState(initialValues);

  const handleOrderByChange = (option) => {
    applyFilter({ order_by: option?.value });
    setRelationsForFilter((state) => ({ ...state, order_by: option?.data || option }));
  };

  const handleUsersSelectChange = (users) => {
    applyFilter({ users: users?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, users }));
  };

  const handleCompaniesSelectChange = (companies) => {
    applyFilter({ companies: companies?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, companies }));
  };

  const handleDatePickerChange = (name, transformer) => (date) => {
    applyFilter({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ order_direction: orderDirection });
    setRelationsForFilter((state) => ({ ...state, order_direction: orderDirection }));
  };

  const applySavedFilter = (filter) => {
    const data = {
      ...filter,

      started_from: filter?.started_from,
      started_to: filter?.started_to,
      companies: filter?.companies?.map(({ id }) => id),
      users: filter?.users?.map(({ id }) => id),
      order_by: filter?.order_by?.value
    };

    formikRef?.current?.setValues(filter);

    applyFilter(hasRole(rolesMap.admin) ? data : omit(data, 'companies'));
  };

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableTopPaddings
      formikRef={formikRef}
      filterKey={filterKey}
      disableIcon={!filterKey}
      initialValues={transformRelationsForFilterToOptions(initialFilter)}
      onReset={() => applySavedFilter(transformRelationsForFilterToOptions(initialFilter))}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      hiddenFields={hiddenFields}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.companies,
          label: filterFieldsLabels[filterFieldsMap.companies],
          field: (
            <CompaniesAutocomplete
              multiple
              name="companies"
              label="Companies"
              minWidth={isMobile ? '100%' : 210}
              onChange={handleCompaniesSelectChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.users,
          label: filterFieldsLabels[filterFieldsMap.users],
          field: (
            <UsersSelect
              multiple
              name="users"
              label="Users"
              minWidth={isMobile ? '100%' : 210}
              params={{
                company_id: filter.companies?.[0],
                roles: availableRolesForUsersSelect
              }}
              onChange={handleUsersSelectChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.startedFrom,
          label: filterFieldsLabels[filterFieldsMap.startedFrom],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                disableFuture
                clearable
                name="started_from"
                label="From"
                outputFormat="YYYY-MM-DD"
                onChange={handleDatePickerChange('started_from', unixToStartOfDayUnix)}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.startedTo,
          label: filterFieldsLabels[filterFieldsMap.startedTo],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                clearable
                name="started_to"
                label="To"
                outputFormat="YYYY-MM-DD"
                onChange={handleDatePickerChange('started_to', unixToEndOfDayUnix)}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.orderBy,
          label: filterFieldsLabels[filterFieldsMap.orderBy],
          field: (
            <Box minWidth={isMobile ? '100%' : 160}>
              <OrderByAutocomplete
                label="Order By"
                name="order_by"
                options={orderByOptions}
                orderDirection={relationsForFilter.order_direction}
                onOrderDirectionChange={toggleOrderDirection}
                onChange={handleOrderByChange}
              />
            </Box>
          )
        }
      ]}
    />
  );
};
