import { Link } from 'react-router-dom';
import { makeStyles, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { renderRoutes, getActiveRoute } from '../../../components/router';
import { Tab, Tabs } from '../../../components/Tabs';
import { routes } from './routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SchedulePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <div className={classes.root}>
      <Tabs value={getActiveRoute(routes)}>
        {routes.map(({ path, icon, label }) => label && (
          <Tab
            key={path}
            component={Link}
            to={path}
            value={path}
            icon={icon}
            label={label}
          />
        ))}
      </Tabs>

      <Box display="flex" flexDirection="column" flexGrow={1} py={isMobile ? 0 : 2} px={isMobile ? 0 : 3}>
        {renderRoutes(routes)}
      </Box>
    </div>
  );
};
