import { useContext, useEffect, useMemo, useRef } from 'react';
import { isEqual } from 'lodash';
import moment from 'moment';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core';
import { FullCalendar, viewTypes } from '../../../../FullCalendar';
import { AppointmentsContext } from '../../../index';
import { transformAppointmentToCalendarEvent, renderAppointmentContent } from '../../../index';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const transformApptToCalendarEvent = (currentApptID) => (appointment) => {
  const event = transformAppointmentToCalendarEvent(appointment);

  event.editable = currentApptID === appointment.id;

  return event;
};

export const Calendar = ({ date, workSchedule }) => {
  const calendarRef = useRef();
  const appointmentsContext = useContext(AppointmentsContext);
  const {
    appointments,
    isFetched,
    filter: { appointment_from, appointment_to }
  } = appointmentsContext;
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const currentAppointment = {
    id: values.appointment_at,
    ...values,
    timezone: values.timezone?.value || values.timezone
  };
  const events = useMemo(() => {
    return appointments.filter(({ id }) => id !== currentAppointment.id);
  }, [ appointments, currentAppointment.id ]);

  const handleDateClick = ({ date }) => {
    setFieldValue('appointment_at', moment(date).unix());
  };

  const handleDatesSet = ({ view: { activeStart, activeEnd } }) => {
    const datesRange = {
      appointment_from: moment(activeStart).unix(),
      appointment_to: moment(activeEnd).unix()
    };

    if (!isEqual({ appointment_from, appointment_to }, datesRange)) {
      appointmentsContext.applyFilter(datesRange);
    }
  };

  const handleAppointmentMove = ({ event: { start, end } }) => {
    setFieldValue('appointment_at', moment(start).unix());
    setFieldValue('time', end && start && moment(end).diff(moment(start), 'minutes'));
  };

  const scrollCalendarToTime = (time) => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.scrollToTime(time);
  };

  useEffect(() => {
    const momentDate = moment.unix(date);

    if (momentDate.isValid()) {
      const calendarApi = calendarRef.current.getApi();

      calendarApi.gotoDate(momentDate.toDate());

      appointmentsContext.applyFilter({
        appointment_from: momentDate.startOf('day').unix(),
        appointment_to: momentDate.endOf('day').unix()
      });
    }
  }, [ date ]);

  useEffect(() => {
    if (typeof values.appointment_book_id === 'number') {
      appointmentsContext.applyFilter({
        appointment_books: [ values.appointment_book_id ]
      });
    }
  }, [ values.appointment_book_id ]);

  useEffect(() => {
    if (isFetched && currentAppointment.appointment_at) {
      scrollCalendarToTime(moment.unix(currentAppointment.appointment_at).format('HH:mmf'));
    }
  }, [ isFetched, currentAppointment.appointment_at ]);

  useEffect(() => {
    const apptBookStartTime = values.appointment_book?.appointments_hours_start;

    if (!values.appointment_at && apptBookStartTime) {
      scrollCalendarToTime(apptBookStartTime);
    }
  }, [ values.appointment_book ]);

  return (
    <div className={classes.root}>
      <div className={cn(classes.calendar)}>
        <FullCalendar
          ref={calendarRef}
          dayHeaders={false}
          allDaySlot={false}
          businessHours={{
            startTime: workSchedule?.start,
            endTime: workSchedule?.finish
          }}
          isLoading={!isFetched}
          headerToolbar={null}
          initialView={viewTypes.timeGridDay}
          eventDataTransform={transformApptToCalendarEvent(currentAppointment.id)}
          events={events.concat(currentAppointment)}
          datesSet={handleDatesSet}
          eventDrop={handleAppointmentMove}
          eventResize={handleAppointmentMove}
          eventContent={renderAppointmentContent}
          dateClick={handleDateClick}
        />
      </div>
    </div>
  );
};
