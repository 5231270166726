import { useContext, useEffect } from 'react';
import filesize from 'filesize';
import moment from 'moment';
import { useModal } from 'react-modal-hook';
import { Checkbox, IconButton, Typography, SvgIcon, Badge, Box, Radio } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Icon } from '@mdi/react';
import { mdiAccountMultiplePlusOutline } from '@mdi/js';
import { Tooltip } from '../../../../../components/Tooltip';
import { ListRow, ListRowCell } from '../../../../../components/ListRow';
import { Popper } from '../../../../../components/Popper';
import { Tag } from '../../../../../components/Tag';
import { Loader } from '../../../../../components/Loader';
import { UserLink } from '../../../../../components/UserLink';
import { FilesFilterContext } from '../../FilesFilterContext';
import { FileMenu } from '../../FileMenu';
import { FilePreviewModal } from '../../FilePreviewModal';
import { FileOwner } from '../../FileOwner';
import { getFileMenuOptions as getDefaultFileMenuOptions } from '../../getFileMenuOptions';
import { FilePreviewIcon } from '../../FilePreviewIcon';
import { columnsMap, columnsWidths } from '../listConfig';

export const FileRow = ({
  hideTags = false,
  file = {},
  isLoaded,
  hiddenColumns = [],
  getFileMenuOptions = getDefaultFileMenuOptions,
  filesContext,
  recalculateHeight
}) => {
  const { isSingleSelection, selectedFilesIDs, toggleFileSelection } = useContext(FilesFilterContext);
  const isSelected = selectedFilesIDs.includes(file.id);
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      getFileMenuOptions={getFileMenuOptions(filesContext)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        files: filesContext.files,
        isFetched: filesContext.isFetched,
        isFetching: filesContext.isFetching,
        filter: filesContext.filter,
        onNeedNextPage: filesContext.loadNextPage
      }}
    />
  ), [ file, filesContext ]);

  const handlePreventShareByEmail = (tag) => () => {
    filesContext.preventShareByEmail({ file, tag });
  };

  const handleItemSelection = () => {
    toggleFileSelection(file.id);
  };

  const shareFile = () => {
    filesContext.shareFiles({ filesIDs: [ file.id ], users: file.shared_users });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, file.tags ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow selected={isSelected}>
      {!hiddenColumns.includes(columnsMap.checkbox) &&
        <ListRowCell>
          {isSingleSelection ? (
            <Radio edge="start" checked={isSelected} onClick={handleItemSelection} />
          ) : (
            <Checkbox edge="start" checked={isSelected} onClick={handleItemSelection} />
          )}
        </ListRowCell>
      }

      <ListRowCell grow actions flexBasis={columnsWidths.name}>
        <FilePreviewIcon file={file} onClick={openFilesPreview} />

        <div>
          <Typography style={{ wordBreak: 'break-all' }}>
            {file.original_filename}
          </Typography>

          {!hideTags &&
            <Box display="flex" flexWrap="wrap">
              {file.tags?.map((tag) => (
                <Box key={tag.id} m={0.25}>
                  <Tag
                    cutTagLabel
                    tag={tag}
                    deleteIcon={
                      <div>
                        <Tooltip title="Prevent access by email">
                          <CancelIcon/>
                        </Tooltip>
                      </div>
                    }
                    onDelete={tag.type === 'public_sharing' ? handlePreventShareByEmail(tag) : undefined}
                  />
                </Box>
              ))}
            </Box>
          }
        </div>
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.sharedUsers) && !filesContext.isSharedFiles &&
        <ListRowCell flexBasis={columnsWidths.sharedUsers}>
          <Badge badgeContent={file?.shared_users?.length} color="primary" overlap="circle">
            <IconButton
              title="Share"
              onClick={shareFile}
            >
              <SvgIcon>
                <Icon path={mdiAccountMultiplePlusOutline} />
              </SvgIcon>
            </IconButton>
          </Badge>
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.owner) &&
        <ListRowCell flexBasis={columnsWidths.owner}>
          <FileOwner owner_type={file.owner_type} owner={file.owner} />
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.creator) &&
        <ListRowCell flexBasis={columnsWidths.creator}>
          {!file.creator ? 'System' : (
            <UserLink
              noWrap
              size="md"
              variant="body1"
              user={file.creator}
            />
          )}
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.size}>
        {filesize(file.size)}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.date}>
        {moment.unix(file.created_at).format('L LT')}
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.action) && (
        <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
          <Popper
            placement="left-start"
            anchorRenderer={({ anchorRef, handleToggle }) => (
              <IconButton edge="end" buttonRef={anchorRef} onClick={handleToggle}>
                <MoreVertIcon />
              </IconButton>
            )}
          >
            {({ handleClose }) => (
              <FileMenu
                file={file}
                filesContext={filesContext}
                openFilesPreview={openFilesPreview}
                menuList={getFileMenuOptions(filesContext)(file)}
                onClose={handleClose}
              />
            )}
          </Popper>
        </ListRowCell>
      )}
    </ListRow>
  );
};
