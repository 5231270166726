import { FormControlLabel } from '@material-ui/core';
import { TextField, ColorPicker, Checkbox } from '../../../../../components/FormField';

export const TaskTypeForm = () => {
  return (
    <>
      <TextField
        required
        name="name"
        label="Name"
        placeholder="Enter name for type..."
        margin="dense"
      />

      <ColorPicker
        name="color"
        label="Color"
        placeholder="Select color..."
        margin="dense"
      />

      <FormControlLabel
        control={<Checkbox name="is_active" />}
        label="Is active"
        margin="dense"
      />
    </>
  );
};
