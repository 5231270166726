import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiDraw } from '@mdi/js';
import { hasRole } from '../../../../utils/hasRole';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../helpers/dom';
import { SignatureModal } from '../../../../app/Dashboard/MedicalReports';
import { styles } from '../../../UserSignature/styles';
import { useModal } from '../../../ModalsProvider';
import { UserPreviewModal } from '../../../users';
import { UserLink } from '../../../UserLink';
import { Button } from '../../../Button';

const useStyles = makeStyles(styles);

export const Signature = ({ report, setReport, onUpdate }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(({ profile }) => profile);
  const doctorSignature = report?.signatures?.find(({ type }) => type === rolesMap.doctor)?.signature;
  const [ signature, setSignature ] = useState(doctorSignature || null);
  const isShowSign = user.id === report?.provider_id && !signature?.electronic_signature_file
    && !hasRole(rolesMap.client, rolesMap.patient);

  const handleSignReport = () => {
    if (user.id === report?.provider_id) {
      openModal(SignatureModal, {
        payload: {
          formID: report.id,
          providerID: report?.provider_id
        },
        onModalResolved: (value) => {
          setReport?.({ ...report, signatures: [ ...report.signatures, value ] });
          setSignature(value);
          onUpdate?.();
        }
      });
    } else {
      enqueueSnackbar('You don\'t have permission', { variant: 'warning' });
    }
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <Grid container spacing={2} component={Box} pt={2} pb={6} px={2} alignItems="flex-start">
      <Grid item sm={6} xs={9}>
        <Typography color="textSecondary">
          Signature
        </Typography>

        <div className={classes.signature}>
          {!!signature ?
            <img
              src={signature?.electronic_signature_file}
              alt="signature"
            />
            :
            <Box display="flex" alignItems="center" justifyContent="center" height="165px">
              <Typography variant="h5">Not signed yet</Typography>
            </Box>
          }
        </div>
      </Grid>

      <Grid item xs={3} className={classes.signButton}>
        {isShowSign &&
          <Button
            size="small"
            color="primary"
            startIcon={<SvgIcon><Icon path={mdiDraw} /></SvgIcon>}
            onClick={handleSignReport}
          >
            sign
          </Button>
        }
      </Grid>

      <Hidden only="xs">
        <Grid item xs={3} />
      </Hidden>

      <Grid item sm={2} xs={6}>
        <Box minWidth={110}>
          <Typography variant="h5">
            Doctor’s Name:
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={4} xs={6}>
        <UserLink
          user={report.provider}
          onClick={stopPropagation(openUserPreview(report.provider?.id))}
        />
      </Grid>

      <Hidden only="xs">
        <Grid item xs={5} />
      </Hidden>

      <Grid item sm={2} xs={6}>
        <Box minWidth={110}>
          <Typography variant="h5">
            License No:
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={4} xs={6}>
        {report?.general_data?.license_number
          ? <Typography>{report?.general_data?.license_number}</Typography>
          : <Typography color="textSecondary">No info.</Typography>
        }
      </Grid>
    </Grid>
  );
};

