export const styles = ({
  spacing,
  palette: { divider },
  breakpoints
}) => ({
  header: {
    display: 'flex',
    minHeight: 90,
    marginBottom: spacing(2),
    borderBottom: `1px solid ${divider}`
  },

  grid: {
    paddingLeft: spacing(2),

    [breakpoints.down('md')]: {
      paddingLeft: spacing(0),
      paddingTop: spacing(2),
      paddingBottom: spacing(2)
    }
  }
});
