import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as templatesApi from '../../../../../../api/profile';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../components/Modal';
import { Loader } from '../../../../../../components/Loader';
import { reportTypesOptions } from '../../../../../../components/medical/reports/diagnosis/SelectReportTypeModal';
import { validationSchema } from './validationSchema';
import { sidesOptions } from '../../../../../../components/appointments/procedures/sidesOptions';
import { agesOptions } from './agesOptions';
import { Body } from './Body';

export const CreateTemplateReport = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { age = null }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const createReport = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return templatesApi.createTemplate(values).then(({ data }) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.push(pathname + `/${data.id}/edit`);
      handleModalResolve();
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        report_type: reportTypesOptions[0],
        age: !!age ? (age <= 30 ? agesOptions[0] : age <= 60 ? agesOptions[1] : agesOptions[2]) : null,
        side: sidesOptions[0],
        provider_id: null,
        name: null,
        office_id: null,
        visit_reason_id: null
      }}
      validationSchema={validationSchema}
      onSubmit={createReport}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>} onClose={handleModalReject}>
              Create Initial Report
            </ModalHeader>

            <ModalBody>
              <Body />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
