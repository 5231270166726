import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setMedicalProfileLastGlobalAction } from '../../../../../../../../store/globalActions';
import * as profileApi from '../../../../../../../../api/profile';
import { Loader } from '../../../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../../../components/Modal';
import { transformToOption } from '../../../MedicalProfileProvider/selectFieldHelpers';
import * as types from '../../../MedicalProfileProvider/types';
import { contactComponentsByTypes, contactTypesMap, validationSchema } from '../ContactsModal';

export const EditContactsModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { initialValues }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();

  const updateProfileInfo = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    initialValues.users[initialValues.index] = values;

    const contacts = {
      ...initialValues.contacts,
      [values?.contact_type]: initialValues.users
    };

    return profileApi.updateMedicalProfileContacts({ id: initialValues.contacts.id, contacts })
      .then((data) => {
        dispatchRedux(setMedicalProfileLastGlobalAction({ type: types.UPDATE_PROFILE, payload: data }));
        enqueueSnackbar('Contacts successfully updated', { variant: 'success' });
        handleModalResolve(data);
      })
      .catch(({ errors, message }) => {
        if (errors) {
          const errorMessage = Object.values(errors);

          errors && setErrors(errors);
          message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
        }
      });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues.user,
          contact_type: initialValues?.user?.relationship ? contactTypesMap.contact : contactTypesMap.employer,
          relationship: initialValues?.user?.relationship && transformToOption(initialValues.user.relationship),
          user_id: initialValues?.user?.user_id ? { id: initialValues?.user?.user_id, ...initialValues.user } : null,
          is_checked: !initialValues?.user?.user_id
        }}
        validationSchema={validationSchema}
        onSubmit={updateProfileInfo}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader onClose={handleModalReject}>
              Update contact
            </ModalHeader>

            <ModalBody>
              {!!values.contact_type && contactComponentsByTypes[values?.contact_type]}
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
