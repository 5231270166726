import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { eventBus, eventBusEvents } from '../../../utils/eventBus';
import { ChatWidgetComponent } from './ChatWidgetComponent';
import { Sidebar } from './Sidebar';
import { Header } from './Header';

export const Layout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const [ chatWidgetIsShown, setChatWidgetIsShown ] = useState(!isMobile);
  const { enqueueSnackbar } = useSnackbar();

  const toggleChatWidget = () => {
    chatWidgetIsShown && enqueueSnackbar(
      'Do Not Disturb mode activated. You won\'t receive messages', { variant: 'warning' }
    );

    setChatWidgetIsShown((chatWidgetIsShown) => !chatWidgetIsShown);
  };

  const closeChatWidget = useCallback(() => {
    setChatWidgetIsShown((chatWidgetIsShown) => {
      if (chatWidgetIsShown) {
        enqueueSnackbar('Do Not Disturb mode activated. You won\'t receive messages', {
          variant: 'warning'
        });
      }

      return false;
    });
  }, []);

  const openChatWidget = () => {
    setChatWidgetIsShown(true);
  };

  useEffect(() => {
    setChatWidgetIsShown(!isMobile);
  }, [ isMobile ]);

  useEffect(() => {
    eventBus.on(eventBusEvents.openChatWidget, openChatWidget);

    return () => {
      eventBus.remove(eventBusEvents.openChatWidget, openChatWidget);
    };
  }, []);

  return (
    <Box display="flex" height="100%" width="100%">
      <Sidebar />

      <Box
        component="main"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        height="100%"
      >
        <Header toggleChatWidget={toggleChatWidget} />

        <Box overflow="hidden" display="flex" flexGrow={1}>
          <Box overflow="hidden" display="flex" flexDirection="column" flexGrow={1}>
            {children}
          </Box>

          {chatWidgetIsShown &&
            <ChatWidgetComponent onChatWidgetClose={closeChatWidget} />
          }
        </Box>
      </Box>
    </Box>
  );
};
