import cn from 'classnames';
import { makeStyles, DialogContent } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: spacing(2, 3)
  }
}));

export const PopperBody = ({ className, ...props }) => {
  const classes = useStyles({ className });

  return (
    <DialogContent className={cn(classes.root, className)} {...props} />
  );
};
