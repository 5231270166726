import { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { isEqual } from 'lodash';
import { makeStyles } from '@material-ui/core';
import * as statisticsApi from '../../../../../../api/billing';
import { api } from '../../../../../../api';
import { Loader } from '../../../../../../components/Loader';
import { usePrevious } from '../../../../../../helpers/hooks';
import { HistoryPaymentsFilterContext } from '../HistoryPaymentsFilterContext';
import { StatisticsPaymentsFilterContext } from '../StatisticsPaymentsFilterContext';
import { FiltersBar } from './FiltersBar';
import { Chart } from './Chart';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = () => {
  const classes = useStyles();
  const [ statistics, setStatistics ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const { filter: historyCommonFilter } = useContext(HistoryPaymentsFilterContext);
  const { filter: commonFilter } = useContext(StatisticsPaymentsFilterContext);
  const filter = { ...historyCommonFilter, ...commonFilter };
  const prevFilter = usePrevious(filter);
  const [ isOpenDayPicker, setIsOpenDayPicker ] = useState(false);
  const [ isOpenWeekPicker, setIsOpenWeekPicker ] = useState(false);
  const [ isOpenMonthPicker, setIsOpenMonthPicker ] = useState(false);
  const [ message, setMessage ] = useState(moment.unix(commonFilter.from).format('MMMM, YYYY'));
  const cancelFetch = useRef(() => {});

  const fetchStatistics = () => {
    cancelFetch.current();
    setIsFetched(false);

    statisticsApi.fetchUpcomingPaymentStatistics({
      params: filter,
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      setStatistics(data);
      setIsFetched(true);
    });
  };

  const resetStatistics = (newFilter) => {
    fetchStatistics(newFilter);
  };

  useEffect(() => {
    resetStatistics();

    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    if (!isEqual(prevFilter, filter)) {
      resetStatistics();
    }
  }, [ prevFilter, filter ]);

  return (
    <div className={classes.root}>
      <FiltersBar
        message={message}
        isOpenDayPicker={isOpenDayPicker}
        isOpenWeekPicker={isOpenWeekPicker}
        isOpenMonthPicker={isOpenMonthPicker}
        setIsOpenMonthPicker={setIsOpenMonthPicker}
        setIsOpenWeekPicker={setIsOpenWeekPicker}
        setIsOpenDayPicker={setIsOpenDayPicker}
        setMessage={setMessage}
      />

      <Loader loading={!isFetched} className={classes.loader} render={
        () => (
          <div className={classes.mainContent}>
            <div className={classes.chartInfo}>
              <div className={classes.content}>
                <Chart data={statistics.data} labels={statistics.labels} />
              </div>

              <div className={classes.content}>
                <List data={statistics.data} labels={statistics.labels} />
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};
