import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  FormControlLabel,
  Grid,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiDraw } from '@mdi/js';
import * as reportsApi from '../../../../../../api/profile';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { ConfirmationModal } from '../../../../../ConfirmationModal';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { SignatureImage } from '../../../../../UserSignature';
import { useModal } from '../../../../../ModalsProvider';
import { NestedList } from '../../../../../NestedList';
import { UserLink } from '../../../../../UserLink';
import { Switch } from '../../../../../FormField';
import { Divider } from '../../../../../Divider';
import { Button } from '../../../../../Button';
import { Date } from '../../../../../Date';

export const TreatmentPlan = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ planInfo, setPlanInfo ] = useState(report?.forms?.treatment_plan);
  const { enqueueSnackbar } = useSnackbar();
  const attorneySignatureInfo = report?.signatures?.find(({ type }) => type === 'attorney');
  const [ attorneySignature, setAttorneySignature ] = useState(attorneySignatureInfo || null);
  const { openModal } = useModal();
  const currentUser = useSelector(({ profile }) => profile.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  const handleApprove = (index) => (event) => {
    planInfo[index].approve = event.target.checked;

    return reportsApi.updateDiagnosisReport({
      ...report,
      forms: {
        ...report?.forms,
        treatment_plan: planInfo
      }
    }).then(({ data }) => {
      enqueueSnackbar('Successfully update info', { variant: 'success' });
      setPlanInfo(data?.forms?.treatment_plan);
    }).catch(() => {
      enqueueSnackbar('Not updated', { variant: 'error' });
    });
  };

  const handleSignForm = () => {
    if (!currentUser.signature) {
      enqueueSnackbar('You don\'t have a signature yet', { variant: 'warning' });

      return;
    }

    openModal(ConfirmationModal, {
      onModalResolved: () => {
        const signValue = {
          model_type: 'diagnosis_report',
          model_id: report.id,
          type: 'attorney',
          sign_type: 'electronic_signature'
        };

        reportsApi.signReport(signValue).then(({ data }) => {
          setAttorneySignature(data);
        }).catch(() => {
          enqueueSnackbar('Not signed', { variant: 'error' });
        });
      }
    });
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Treatment & Plan"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Grid
        container
        spacing={!isMobile ? 3 : 2}
        component={Box}
        p={3}
        alignItems="center"
      >
        {planInfo?.map((item, index) => (
          <Fragment key={index}>
            <Grid item sm={10} xs={12}>
              <Typography>
                {item?.text || 'No info'}
              </Typography>
            </Grid>

            <Grid item sm={2} xs={12}>
              {!hasRole(rolesMap.advocate) || (hasRole(rolesMap.advocate) && attorneySignature?.signature) ? (
                <ReadonlyCheckbox
                  checked={item?.approve}
                  iconColor={item?.approve ? 'success' : 'error'}
                  title="Approve"
                />
              ) : (
                <FormControlLabel
                  label="Approve"
                  control={
                    <Switch
                      withoutFormik
                      disabled={!hasRole(rolesMap.advocate)}
                      color="primary"
                      checked={item.approve}
                      onChange={handleApprove(index)}
                    />
                  }
                />
              )}
            </Grid>

            {index !== planInfo?.length - 1 && (
              <Grid item xs={12}>
                <Divider type="dotted" />
              </Grid>
            )}
          </Fragment>
        ))}

        <Grid item md={2} sm={12} xs={12}>
          <Typography variant="h5" color="secondary">Attorney Signature:</Typography>
        </Grid>

        {attorneySignature?.signature ? (
          <>
            <Grid item sm={3}>
              <SignatureImage url={attorneySignature?.signature?.electronic_signature_file} />
            </Grid>

            <Grid item md={6} sm={9}>
              <UserLink
                variant="h5"
                user={attorneySignature?.user}
              >
                <Date color="textSecondary" variant="caption" date={attorneySignature?.date} />
              </UserLink>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <Button
                disabled={!hasRole(rolesMap.advocate)}
                size="small"
                color="primary"
                startIcon={<SvgIcon><Icon path={mdiDraw} /></SvgIcon>}
                onClick={handleSignForm}
              >
                Sign
              </Button>
            </Grid>

            <Grid item mb={0} md={6} sm={9}>
              {report?.attorney ?
                <UserLink
                  variant="h5"
                  user={report?.attorney}
                />
                :
                <Typography variant="h5">
                  Attorney is not added to diagnostic list
                </Typography>
              }
            </Grid>
          </>
        )}
      </Grid>
    </NestedList>
  );
};
