export const styles = ({
  spacing,
  palette,
  shape: { borderRadius },
  shadows,
  breakpoints,
  transitions
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: palette.grey[100]
  },

  loader: {
    padding: spacing(3)
  },

  mainContent: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    width: '100%',
    padding: spacing(1, 1, 0, 1),

    [breakpoints.down(breakpoints.values.md)]: {
      flexDirection: 'column',
      padding: spacing()
    }
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
    flexGrow: 1,
    marginLeft: spacing(),
    padding: spacing(2),
    backgroundColor: palette.primary.contrastText,
    boxShadow: shadows[2],
    borderRadius,

    [breakpoints.down(breakpoints.values.md)]: {
      padding: spacing(1)
    }
  },

  content__info: {
    padding: 0
  },

  activityInfo: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexGrow: 1,
    marginLeft: spacing()
  },

  filesContent: {
    width: '100%'
  },

  caseInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: 370,
    minWidth: 370,
    height: '100%',
    marginTop: 0,
    borderRadius,
    transition: transitions.create([ 'margin', 'height', 'maxHeight' ], {
      duration: transitions.duration.shortest
    }),

    [breakpoints.down(breakpoints.values.md)]: {
      width: '100%',
      maxHeight: 900,
      minWidth: 'auto'
    }
  },

  caseInfoClosed: {
    maxHeight: 'none',

    [breakpoints.down(breakpoints.values.md)]: {
      maxHeight: 90
    }
  },

  expand: {
    transform: 'rotate(0deg)',
    paddingBottom: '50%',
    marginLeft: 'auto',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest
    })
  },

  navigation: {
    backgroundColor: palette.primary.contrastText
  }
});
