export const styles = ({ palette }) => ({
  badge: ({ color, isStatic }) => {
    const colorStyles = palette[color] ? {
      color: palette[color].contrastText,
      backgroundColor: palette[color].main
    } : color === 'default' ? {
      backgroundColor: palette.grey[100]
    } : {};

    const staticStyles = !isStatic ? {} : {
      position: 'static',
      transform: 'none'
    };

    return {
      ...colorStyles,
      ...staticStyles
    };
  }
});
