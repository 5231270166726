import { useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {  LayoutContextProvider, viewVariantsMap } from '../../../../../../components/LayoutContext';
import {
  CalendarProvider
} from '../../../../../../components/schedule-events/Schedule/CalendarProvider';
import { ScheduleProvider } from '../../../../../../components/schedule-events';
import { ScheduleEventsFilterProvider } from '../../../../../../components/schedule-events';
import { UserContext } from '../../../../Members/User/UserProvider';
import { Card } from './Card';

export const AllSchedules = ({
  filter: filterProp = {},

  ...props
}) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : currentUser;

  return (
    <ScheduleEventsFilterProvider
      filter={{ users: [ user.id ], timezone: moment.tz.guess(true), ...filterProp }}
      filterKey={props?.filterKey}
    >
      <CalendarProvider disableReloadCalendar>
        <ScheduleProvider>
          <LayoutContextProvider initialViewVariant={viewVariantsMap.list}>
            <Card {...props} />
          </LayoutContextProvider>
        </ScheduleProvider>
      </CalendarProvider>
    </ScheduleEventsFilterProvider>
  );
};
