import { makeStyles, Box, Typography, SvgIcon } from '@material-ui/core';
import { mdiClipboardPulseOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tooltip, TooltipTitle } from '../../../../../../../../components/Tooltip';
import { medicationsListToString } from '../../../../../../../../utils/medicationsListToString';
import { SignedUsers } from '../../../../../../ProfilePage/MedicalInfo/MedicalForms/Documents/SignedUsers';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ report }) => {
  const classes = useStyles();
  const diagnosis = report?.diagnosis ? medicationsListToString(report.diagnosis) : null;

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <Box mr={1}>
          <SvgIcon><Icon path={mdiClipboardPulseOutline} /></SvgIcon>
        </Box>

        <Typography noWrap variant="h6">{report?.type}</Typography>
      </Box>

      <div className={classes.root}>
        <Box>
          <Tooltip
            isExistTooltip={!!report?.diagnosis?.length}
            title={report?.diagnosis?.map((item) => (
              <TooltipTitle key={item.id} itemTitle={item.code}>
                {item.description &&
                <Typography>- {item.description}</Typography>
                }
              </TooltipTitle>
            ))}
          >
            <Typography noWrap variant="h2" color="error">
              {diagnosis || '-'}
            </Typography>
          </Tooltip>

          <Typography variant="caption" color="textSecondary">
            DX Sequence/Diagnosis
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Sign info</Typography>
          <SignedUsers document={report} />
        </Box>

        <Typography variant="h5">
          DOI
          <Box component="span" ml={2}>
            {report?.general_data?.doi_string || '-'}
          </Box>
        </Typography>
      </div>
    </>
  );
};
