import { useState } from 'react';
import { Box } from '@material-ui/core';
import { ContentCard } from '../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../components/WidgetsActionButtons';
import { StatisticsExpensesFilterContextProvider } from './StatisticsExpensesFilterContext';
import { MainContent } from './MainContent';

export const ExpensesAnalysis = ({
  isOpen,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const [ isFetchedData, setIsFetchedData ] = useState(false);
  const [ filterType, setFilterType ] = useState(null);

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      isResizable={isOpen}
      title="Expenses Analysis"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box minHeight={height || '100%'}>
        <StatisticsExpensesFilterContextProvider>
          <MainContent
            isFetchedData={isFetchedData}
            filterType={filterType}
            setIsFetchedData={setIsFetchedData}
            setFilterType={setFilterType}
          />
        </StatisticsExpensesFilterContextProvider>
      </Box>
    </ContentCard>
  );
};
