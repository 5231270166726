export const styles = ({ spacing, palette, breakpoints }) => ({
  root: {
    height: '100%',
    padding: spacing(3, 3, 2),
    backgroundColor: palette.grey[100],

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      padding: 0
    }
  }
});
