import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Box, makeStyles, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import LocalPhoneIcon from '@material-ui/icons/LocalPhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { stopPropagation } from '../../../../../../helpers/dom';
import { AddressLink } from '../../../../../../components/AddressLink';
import { Contact } from '../../../../../../components/Contact';
import { billingParticipantsTypesMap } from '../../../../../../components/billing';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { UserPreviewModal } from '../../../../../../components/users';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { Label } from '../../../../../../components/Label';
import { PreviewCompanyModal } from '../../../../Contacts/Companies/PreviewCompanyModal';
import { statusTypesColors, statusTypesNames } from '../../InvoicesContext/statusTypes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ invoice }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const billToAddress = (
    invoice?.bill_to?.participant?.billing_full_address
    || invoice?.bill_to?.participant?.info?.full_address
    || invoice?.bill_to?.participant?.info?.billing_address
    || null
  );

  const openPreviewCompanyModal = (companyID) => () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID }
    });
  };

  const openUserPreview = (userID) => () => {
    openModal(UserPreviewModal, {
      payload: { id: userID }
    });
  };

  return (
    <div className={classes.root}>
      <div className={cn(classes.content, classes.leftContent)}>
        <div className={classes.mainContent}>
          <div className={classes.mainContentContainer}>
            <div className={classes.header}>
              {invoice.status &&
                <Label
                  label={statusTypesNames[invoice.status.name.toLowerCase()]}
                  color={statusTypesColors[invoice.status.name.toLowerCase()]}
                  className={classes.statusLabel}
                />
              }

              <div className={classes.balance}>
                <CurrencyFormat value={invoice.balance} />
              </div>
            </div>

            <div className={classes.titleContent}>
              <Typography variant="h6" className={classes.subTitle}>Terms</Typography>

              <Typography variant="h6" className={cn(classes.subTitle, classes.subTitle__content)}>
                {invoice.terms}
              </Typography>
            </div>

            <div className={classes.titleContent}>
              <Typography variant="h6" className={classes.subTitle}>Issue Date</Typography>

              <Typography variant="h6" className={cn(classes.subTitle, classes.subTitle__content)}>
                {moment(invoice.date).format('L')}
              </Typography>
            </div>

            <div className={classes.titleContent}>
              <Typography variant="h6" className={classes.subTitle}>Due Date</Typography>

              <Typography variant="h6" className={cn(classes.subTitle, classes.subTitle__content)}>
                {!!invoice.due_date && moment(invoice.due_date).format('L')}
              </Typography>
            </div>
          </div>

          <div className={cn(classes.mainContentContainer, classes.mainContentContainer_amount)}>
            <div className={classes.contentInfo}>
              <Typography
                variant="h3"
                className={cn(classes.title, classes.title_amount)}
              >
                Total Amount
              </Typography>

              <CurrencyFormat
                value={invoice.total}
                TypographyProps={{
                  color: 'textSecondary',
                  className: classes.contactTotal
                }}
              />
            </div>

            <div className={classes.contentInfo}>
              <Typography
                variant="h3"
                className={cn(classes.title, classes.title_amount)}
              >
                Amount Paid
              </Typography>

              <CurrencyFormat
                value={invoice.total_paid || invoice?.paid}
                TypographyProps={{
                  color: 'textSecondary',
                  className: classes.contactTotal
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={cn(classes.content, classes.rightContent)}>
        <Typography variant="h3" className={classes.title}>Bill To</Typography>

        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <PersonIcon color="secondary" />
          </Box>

          {invoice.bill_to?.participant_type === billingParticipantsTypesMap.user ? (
            <UserLink
              noWrap
              size="sm"
              variant="h5"
              user={invoice.bill_to?.participant}
              onClick={stopPropagation(openUserPreview(invoice.bill_to?.participant_id))}
            />
          ) : (
            <CompanyLink
              noWrap
              size="sm"
              variant="h5"
              company={invoice.bill_to?.participant}
              onClick={stopPropagation(openPreviewCompanyModal(invoice.bill_to?.participant_id))}
            />
          )}
        </Box>

        <Box display="flex" alignItems="center" my={1}>
          <Box mr={1}>
            <RoomOutlinedIcon color="secondary" />
          </Box>

          <AddressLink address={billToAddress} />
        </Box>

        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <LocalPhoneIcon color="secondary" />
          </Box>

          <Contact
            type="tel"
            contact={invoice?.bill_to?.participant?.phone}
          />
        </Box>

        <Box display="flex" alignItems="center" my={1}>
          <Box mr={1}>
            <EmailOutlinedIcon color="secondary" />
          </Box>

          <Contact
            color="primary"
            type="mailto"
            contact={invoice?.bill_to?.participant?.email}
          />
        </Box>
      </div>
    </div>
  );
};
