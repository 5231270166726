import { useRef, useEffect } from 'react';
import { isEqual } from 'lodash';

const useCompareAndMemo = (value) => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export const useMemoEffect = (callback, dependencies) => {
  useEffect(
    callback,
    dependencies.map(useCompareAndMemo)
  );
};
