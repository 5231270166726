import {
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { Popper } from '../../Popper';

export const AddAttachmentsButton = ({ fileAttachments }) => {
  const {
    getFileInputProps,
    openAvailableFilesModal,
    openUploadFileDialog
  } = fileAttachments;

  return (
    <Popper
      anchorRenderer={({ anchorRef, handleToggle }) => (
        <>
          <input {...getFileInputProps()} />

          <IconButton
            buttonRef={anchorRef}
            onClick={handleToggle}
          >
            <AttachFileOutlinedIcon />
          </IconButton>
        </>
      )}
    >
      {({ handleClose }) => (
        <List onClick={handleClose}>
          <MenuItem onClick={openUploadFileDialog}>
            <ListItemIcon>
              <CloudUploadIcon fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Upload new file" />
          </MenuItem>

          <MenuItem onClick={openAvailableFilesModal}>
            <ListItemIcon>
              <FileCopyIcon fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Select from available" />
          </MenuItem>
        </List>
      )}
    </Popper>
  );
};
