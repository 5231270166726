export const styles = ({ spacing, palette }) => ({
  body: {
    display: 'flex',
    flexDirection: 'column'
  },

  bodySmall: {
    overflow: 'auto'
  },

  messageText: {
    padding: spacing(1.25, 2),
    borderRadius: 12,
    backgroundColor: palette.grey[100],
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap'
  },

  messageTextSmall: {
    padding: spacing(1, 2),
    borderRadius: 16
  },

  myMessageText: {
    marginLeft: 'auto',
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main
  },

  forwardWrapper: {
    borderLeft: `2px solid ${palette.divider}`,
    marginLeft: spacing(0.25),
    padding: spacing(0.5)
  }
});
