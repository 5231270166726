export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { pxToRem, fontWeightMedium }
}) => ({
  root: {
    position: 'relative',
    paddingTop: spacing(1),
    paddingBottom: spacing(1)
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    transform: 'translateY(-100%)',
    fontSize: pxToRem(12),
    fontWeight: fontWeightMedium
  },

  title_initial: {
    color: '#48D2A0'
  },

  title_medium: {
    color: '#FDBC00'
  },

  title_full: {
    color: palette.primary.main
  },

  title_over: {
    color: palette.error.main
  },

  progress: {
    height: 8,
    borderRadius: 4,
    backgroundColor: grey[300]
  },

  bar_initial: {
    backgroundColor: '#48D2A0'
  },

  bar_medium: {
    backgroundColor: '#FDBC00'
  },

  bar_full: {
    backgroundColor: palette.primary.main
  },

  bar_over: {
    backgroundColor: palette.error.main
  },

  title__gridProgress: {
    position: 'absolute',
    left: 0,
    transform: 'translateY(-86%)'
  },

  attachmentIndicator: {
    marginRight: spacing(),

    '&:last-of-type': {
      marginRight: spacing(2)
    }
  },

  attachmentIndicator__taskIcon: {
    marginRight: 0
  },

  iconTimer: {
    fontSize: pxToRem(14),
    marginRight: spacing(0.5),
    color: grey[500]
  }
});
