import { useContext } from 'react';
import { Box, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { SelectedItemsCount } from '../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../components/VerticalDivider';
import { PageHeaderActions } from '../../../../components/Page';
import { IconButton } from '../../../../components/IconButton';
import { Fab } from '../../../../components/Fab';

export const ActionsBar = ({
  disabled,
  additionalButton = null,
  disableAddIcon = false,
  actionTitle,
  selectedIDs,
  pagination,
  filterOpenButton,
  disableViewVariant = false,
  onCreate = () => {},
  onDelete = () => {}
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const layout = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    layout.setViewVariant(viewVariant);
  };

  return (
    <>
      {actionTitle && !hasRole(rolesMap.client, rolesMap.patient, rolesMap.advocate) &&
        <PageHeaderActions disablePosition>
          <Fab
            disabled={disabled}
            size={isTablet ? 'small' : 'medium'}
            variant="extended"
            color="primary"
            startIcon={!disableAddIcon && <AddIcon />}
            onClick={onCreate}
          >
            {actionTitle}
          </Fab>

          {!!additionalButton && <Box ml={2}>{additionalButton}</Box>}
        </PageHeaderActions>
      }

      <PageHeaderActions disableSpacing endPosition>
        {!!selectedIDs?.length &&
          <>
            <SelectedItemsCount length={selectedIDs.length} total={pagination.total} />
            <VerticalDivider verticalGutters={1} horizontalGutters={1}/>

            <Tooltip
              arrow
              title={<Typography>Delete selected items</Typography>}
            >
              <IconButton
                color="error"
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            {!disableViewVariant &&
              <VerticalDivider verticalGutters={1} horizontalGutters={1} />
            }
          </>
        }

        {isTablet && filterOpenButton}

        {!disableViewVariant &&
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={layout.viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={layout.viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      </PageHeaderActions>
    </>
  );
};
