import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import { Box } from '@material-ui/core';
import { CasesSelect } from '../../../../../../../components/cases/CasesSelect';
import { filterFieldsMap } from '../../../../../../../components/schedule-events';
import { saveFilter } from '../../../../../../../store/lastFilters';
import { unixToEndOfDayUnix, unixToStartOfDayUnix } from '../../../../../../../helpers/dates';
import { FiltersBar as FiltersBarComponent } from '../../../../../../../components/FiltersBar';
import { TimeTrackingContext } from '../../../../../../../components/TimeTracking';
import { IconComponent } from '../../../../../../../components/saved-filters';
import {
  KeyboardDatePicker,
  TasksSelect,
  ScheduleEventsField
} from '../../../../../../../components/FormField';
import { CardContent } from './CardContent';
import { columnsMap, columnsWidths, SavedFiltersList } from './SavedFiltersList';
import { filterLabels, fieldsKeysMap } from './filterKeysMap';

const initialValues = {
  started_after: null,
  started_before: null,
  cases: [],
  tasks: [],
  schedule_events: null
};

const MODAL_WIDTH = 950;

export const FiltersBar = ({ caseItem = null, filterKey, hiddenFilterFields = [] }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});
  const { applyFilter, resetTimeTracking } = useContext(TimeTrackingContext);
  const modalWidth = hiddenFilterFields.includes(fieldsKeysMap.cases) ?
    MODAL_WIDTH - columnsWidths[columnsMap.cases] : MODAL_WIDTH;
  const scheduleFilterHiddenFields = [
    filterFieldsMap.cases
  ];
  const tasksFilterParams = useMemo(() => ({
    cases: caseItem?.id ? [ caseItem.id ] : null
  }), [ caseItem?.id ]);

  const handleDatePickerChange = (name, transformer) => (date) => {
    applyFilter({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleTasksChange = (tasks) => {
    applyFilter({ tasks: tasks?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, tasks }));
  };

  const handleCasesChange = (cases) => {
    applyFilter({ cases: cases?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, cases }));
  };

  const handleScheduleEventsChange = (events) => {
    applyFilter({ 'schedule_events': events?.length ? events.map(({ id }) => id) : null });
    setRelationsForFilter((state) => ({ ...state, 'schedule_events': events }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(filter);

    resetTimeTracking(omit({
      ...filter,

      schedule_events: filter?.schedule_events?.map((event) => event?.id),
      cases: filter?.cases?.map((caseItem) => caseItem?.id),
      tasks: filter?.tasks?.map((task) => task?.id)
    }, hiddenFilterFields));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      formikRef.current.setValues({
        ...relationsForFilter,
        ...lastFilters
      });
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      initialValues={initialValues}
      hiddenFields={hiddenFilterFields}
      filterKey={filterKey}
      iconComponent={
        <IconComponent
          filterKey={filterKey}
          modalWidth={modalWidth}
          hiddenFields={hiddenFilterFields}
          CardContent={CardContent}
          ListComponent={SavedFiltersList}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      onReset={() => applySavedFilter(initialValues)}
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.startedAfter,
          label: filterLabels[fieldsKeysMap.startedAfter],
          field: (
            <KeyboardDatePicker
              clearable
              name="started_after"
              label="From"
              onChange={handleDatePickerChange('started_after', unixToStartOfDayUnix)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.startedBefore,
          label: filterLabels[fieldsKeysMap.startedBefore],
          field: (
            <KeyboardDatePicker
              clearable
              name="started_before"
              label="To"
              onChange={handleDatePickerChange('started_before', unixToEndOfDayUnix)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.cases,
          label: filterLabels[fieldsKeysMap.cases],
          field: (
            <Box minWidth={210}>
              <CasesSelect
                multiple
                name="cases"
                label="Cases"
                onChange={handleCasesChange}
              />
            </Box>
          )
        },
        {
          fieldKey: fieldsKeysMap.tasks,
          label: filterLabels[fieldsKeysMap.tasks],
          field: (
            <TasksSelect
              multiple
              name="tasks"
              label="Tasks"
              params={tasksFilterParams}
              onChange={handleTasksChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.scheduleEvents,
          label: filterLabels[fieldsKeysMap.scheduleEvents],
          field: (
            <ScheduleEventsField
              isMulti
              name="schedule_events"
              label="Schedule Events"
              placeholder="Search Schedule Events.."
              params={{
                cases: caseItem?.id ? [ caseItem?.id ] : []
              }}
              tasksFilterParams={tasksFilterParams}
              hiddenFilterFields={scheduleFilterHiddenFields}
              onChange={handleScheduleEventsChange}
            />
          )
        }
      ]}
    />
  );
};
