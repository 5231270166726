export const styles = ({ spacing, palette: { grey, divider } }) => ({
  checkbox: {
    color: grey[500],
    marginLeft: spacing()
  },

  root_form: {
    border: `1px solid ${divider}`
  }
});
