import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { IconButton } from '../../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../components/Loader';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { UserLink } from '../../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.from}>
        {params.appointment_from ? moment.unix(params?.appointment_from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.to}>
        {params.appointment_to ? moment.unix(params?.appointment_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.appointmentBooks}>
        <Box display="flex" flexDirection="column">
          {!params?.appointment_books?.length ? '-' : params?.appointment_books?.map((appointment) => (
            <Typography key={appointment.id} noWrap>{appointment?.appointment_book_name|| '-'}</Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.providers}>
        <Box display="flex" flexDirection="column">
          {!params?.providers?.length ? '-' : params?.providers?.map((provider) => (
            <Box display="flex" key={provider.id}>
              <UserLink
                noWrap
                key={provider.id}
                size="sm"
                user={provider}
              />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.offices}>
        <Box display="flex" flexDirection="column">
          {!params?.offices?.length ? '-' : params?.offices?.map((office) => (
            <Typography noWrap key={office?.id} title={office?.name}>
              {office?.name || '-'}
            </Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
