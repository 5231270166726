import { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { NestedList } from '../../../../../NestedList';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../helpers/isEmptyDeep';

export const ReviewSystems = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(report) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Review of Symptoms"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box py={2}>
        {report?.no_interval_change &&
          <ReadonlyCheckbox
            checked={report?.no_interval_change}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="No Interval Change"
          />
        }

        {report?.negative &&
          <ReadonlyCheckbox
            checked={report?.negative}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="Negative"
          />
        }

        {report?.history_of &&
          <ReadonlyCheckbox
            checked={report?.history_of}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="History of"
          />
        }

        {report?.note &&
          <Box my={2} display="flex" alignItems="flex-start">
            <Box pl={3.5} display="flex" alignItems="center">
              <NotesIcon fontSize="small" />

              <Box mr={1.5} ml={0.5}>
                <Typography variant="subtitle2">
                  Note
                </Typography>
              </Box>
            </Box>

            <Typography>
              {report?.note}
            </Typography>
          </Box>
        }
      </Box>
    </NestedList>
  );
};
