import { useEffect, useState } from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { getUserFullName } from '../../../../../../../helpers/users';
import { ShortInsuranceInfo } from '../../../../../../../components/users/ShortInsuranceInfo';
import { formTypesMap } from '../../../../../../../components/medical/forms';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { NestedList } from '../../../../../../../components/NestedList';
import { UserLink } from '../../../../../../../components/UserLink';
import { Contact } from '../../../../../../../components/Contact';
import { UserSSN } from '../../../../../../../components/users';
import { Date } from '../../../../../../../components/Date';
import { PhysicianInfo } from '../PhysicianInfo';

export const GeneralInfo = ({ isOpenSwitches, form }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const formType = form?.parent?.form_type;
  const isInjury = formType !== formTypesMap.private && formType !== formTypesMap.compensation;

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="General info"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Grid container spacing={4} alignItems="center" component={Box} p={3}>
        <Grid container item sm={6} xs={12} spacing={1}>
          <Grid item xs={5}>
            <Typography color="textSecondary">Name</Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography variant="h5">{getUserFullName(form?.patient)}</Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">DOB</Typography>
          </Grid>

          <Grid item xs={7}>
            <Date
              disableIcon
              variant="h5"
              date={form?.patient?.birthday}
            />
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">SSN</Typography>
          </Grid>

          <Grid item xs={7}>
            <UserSSN user={form} />
          </Grid>
        </Grid>

        <Grid container item sm={6} xs={12} spacing={1}>
          <Grid item xs={5}>
            <Typography color="textSecondary">Phone</Typography>
          </Grid>

          <Grid item xs={7}>
            <Contact
              noWrap
              needIcon
              variant="h5"
              title={form.phone || form?.patient?.phone_number}
              type="tel"
              contact={form.phone || form?.patient?.phone_number}
            />
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">Mobile Phone</Typography>
          </Grid>

          <Grid item xs={7}>
            <Contact
              noWrap
              needIcon
              variant="h5"
              title={form.mobile_phone || form?.patient?.mobile_phone_number}
              type="tel"
              contact={form.mobile_phone || form?.patient?.mobile_phone_number}
            />
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">Address</Typography>
          </Grid>

          <Grid item xs={7}>
            <AddressLink variant="h5" address={form.full_address || form.address}>
              {form.full_address || form.address}
            </AddressLink>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} alignItems="center">
          <PhysicianInfo form={form} />
        </Grid>

        <Grid container item xs={12} spacing={1}>
          <Grid item sm={3} xs={12}>
            <Typography color="textSecondary">
              {isInjury ? 'Law Office' : 'Primary Insurance'}
            </Typography>
          </Grid>

          <Grid item sm={9} xs={12}>
            <ShortInsuranceInfo showPreview insurance={form?.primary_insurance} />
          </Grid>

          {form?.parent?.form_type === formTypesMap.private && (
            <>
              <Grid item sm={3} xs={12}>
                <Typography color="textSecondary">Insurance ID #</Typography>
              </Grid>

              <Grid item sm={9} xs={12}>
                <Typography variant="h5">
                  {form?.primary_insurance?.insuranceId || '-'}
                </Typography>
              </Grid>

              <Grid item sm={3} xs={12}>
                <Typography color="textSecondary">Group ID #</Typography>
              </Grid>

              <Grid item sm={9} xs={12}>
                <Typography variant="h5">
                  {form?.primary_insurance?.groupId || '-'}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>

        <Grid container item xs={12} spacing={1} alignItems="center">
          <Grid item sm={3} xs={12}>
            <Typography color="textSecondary">Primary Insurance Holder</Typography>
          </Grid>

          <Grid item sm={9} xs={12}>
            {form?.primary_insurance?.subscriber_info?.first_name ? (
              <UserLink
                size="md"
                variant="h5"
                disableLink
                disablePreview
                user={form?.primary_insurance?.subscriber_info}
              />
            ) : (
              form?.primary_insurance_holder_first_name || form?.primary_insurance_holder_last_name ? (
                <Typography variant="h5">
                  {getUserFullName({
                    first_name: form?.primary_insurance_holder_first_name,
                    last_name: form?.primary_insurance_holder_last_name
                  })}
                </Typography>
              ) : (
                <Typography color="textSecondary">
                  No info.
                </Typography>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </NestedList>
  );
};
