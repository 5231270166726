import { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { removeSnackbar } from '../../store/snackbars';

class SnackbarStack extends Component {
  componentDidUpdate() {
    const { snackbars } = this.props;

    for ( let key in snackbars ) if (snackbars.hasOwnProperty(key)) {
      const snackbar = snackbars[key];

      this.props.enqueueSnackbar(snackbar.message, snackbar.options);
      this.props.removeSnackbar(key);
    }
  }

  render() {
    return null;
  }
}

SnackbarStack = withSnackbar(SnackbarStack);

SnackbarStack = connect(({ snackbars }) => ({
  snackbars
}), {
  removeSnackbar
})(SnackbarStack);

export { SnackbarStack };
