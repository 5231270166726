import { Box, Grid, Typography } from '@material-ui/core';
import { Divider } from '../../../../../../../components/Divider';

export const Body = ({ company }) => {
  return (
    <>
      <Grid container spacing={2} component={Box} height={190} py={2} px={3} justify="space-between">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Address
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {company.full_address || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Billing Address
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {company.billing_full_address || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Venue
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {company.venue_full_address || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Divider disableBottomGutter type="dashed" />
    </>
  );
};
