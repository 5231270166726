import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import cn from 'classnames';
import { Link, makeStyles } from '@material-ui/core';
import { stopPropagation } from '../../helpers/dom';
import { hasRole } from '../../utils/hasRole';
import { EmailsSendModal } from '../../app/Dashboard/EmailsPage/EmailsClient/EmailsSendModal';
import { useModal } from '../ModalsProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmailContact = ({ contact, color, className, ...props }) => {
  const classes = useStyles({ color });
  const currentUser = useSelector(({ profile }) => profile.user);
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const openSendEmailModal = () => {
    if (!hasRole('client', 'patient') && currentUser?.email_accounts?.length) {
      openModal(EmailsSendModal, {
        payload: { accounts: currentUser?.email_accounts, to: [ contact ] }
      });
    } else if (!hasRole('client') && !currentUser?.email_accounts?.length) {
      enqueueSnackbar('Please add an account' , { variant: 'warning' });
    }
  };

  return (
    <Link
      color={color}
      onClick={stopPropagation(openSendEmailModal)}
      className={cn(
        classes.title,
        { [classes.textColor]: !color },
        className
      )}

      {...props}
    >
      {contact}
    </Link>
  );
};
