import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import { SimpleSelect } from '../../../../../components/FormField';
import {
  insuranceStatusTypesMap,
  medicInsuranceTypesOptions,
  lawOfficeInsuranceTypesOptions,
  statusTypeCaseOptions,
  caseInsuranceTypesOptions, caseInsuranceValuesTypesMap
} from './insuranceDataMaps';

export const Header = ({ handleToggleStatus }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleTypeChange = (event) => {
    const option = event.target.value;

    if (option && Object.values(caseInsuranceValuesTypesMap).includes(option)) {
      setFieldValue('provider_adj_id', null);
      setFieldValue('provider_id', null);
      setFieldValue('attorney_adj_id', null);
      setFieldValue('attorney_id', null);
      setFieldValue('case_user_id', null);
    }
  };

  return (
    <>
      <Grid item sm={6} xs={12}>
        <SimpleSelect
          name="status_type"
          label="Insurance"
          margin="dense"
          options={statusTypeCaseOptions}
          onChange={handleToggleStatus}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <SimpleSelect
          name="type_insurance"
          label="Insurance type"
          margin="dense"
          options={values.status_type === insuranceStatusTypesMap.law_office
            ? lawOfficeInsuranceTypesOptions
            : values.status_type === insuranceStatusTypesMap.case_insurance
              ? caseInsuranceTypesOptions
              : medicInsuranceTypesOptions
          }
          onChange={handleTypeChange}
        />
      </Grid>
    </>
  );
};
