import { Fragment } from 'react';
import { flow } from 'lodash';
import { makeStyles, List, ListItemIcon, ListItemText, MenuItem, Divider } from '@material-ui/core';
import { menuOptionsTypesMap } from '../menuOptionsTypes';

const styles = () => ({
  root: {
    minWidth: 200
  }
});

const useStyles = makeStyles(styles);

export const FileMenu = ({ menuList, onClose }) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {menuList?.map(({ isHidden, type, label, Icon, onClick }, index) => (
        <Fragment key={index}>
          {(type === menuOptionsTypesMap.divider) && !isHidden && <Divider />}

          {(type === menuOptionsTypesMap.menuItem) && !isHidden &&
            <MenuItem onClick={flow(onClick, onClose)}>
              <ListItemIcon>
                <Icon fontSize="inherit" />
              </ListItemIcon>

              <ListItemText primary={label} />
            </MenuItem>
          }
        </Fragment>
      ))}
    </List>
  );
};
