import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { validationSchema } from './validationSchema';

export const AttachCaseModal = ({
  DialogProps,
  payload: { insuranceID },
  handleModalResolve,
  handleModalReject
}) => {
  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    handleModalResolve(values);
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ case_id: null }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<FileCopyIcon />}
              onClose={handleModalReject}
            >
              Add Case
            </ModalHeader>

            <ModalBody>
              <CasesSelect
                name="case_id"
                label="Select case"
                placeholder="Select case..."
                params={{ excluded_insurance_id: insuranceID }}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
