import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { hasRole } from '../../../../../../../utils/hasRole';
import * as waitingListApi from '../../../../../../../api/appointments';
import {
  AppointmentEditModal,
  AppointmentViewModal,
  arrivalTypes,
  arrivalTypesMap
} from '../../../../../../../components/appointments';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { OfficeLink } from '../../../../../../../components/OfficeLink';
import { IconButton } from '../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { Label } from '../../../../../../../components/Label';
import { Date } from '../../../../../../../components/Date';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import { columnsWidths, minRowHeight } from '../ApptList';

export const Row = ({ isFollowUp = false, isUpdatable = false, appointment, onUpdateAppointment = () => {} }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ isDeletable, setIsDeletable ] = useState(false);
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const medicalFormItemContext= useContext(MedicalFormItemContext);
  const hasRoleForEdit = hasRole(rolesMap.doctor, rolesMap.medic, rolesMap.admin, rolesMap.supervisor);

  const handleUpdateAppt = (data) => {
    onUpdateAppointment(data);
  };

  const handleDeleteAppt = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        waitingListApi.deleteWaitingListItem(appointment.id).then(() => {
          onUpdateAppointment({});
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Item is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const openAppointmentEditModal = () => {
    openModal(AppointmentEditModal, {
      payload: {
        insurance: !!medicalFormItemContext?.form ? {
          insurance_details: medicalFormItemContext?.form?.primary_insurance ? {
            ...medicalFormItemContext?.form?.primary_insurance,
            company: medicalFormItemContext?.form?.primary_insurance?.insurance_company || null
          } : null,
          patient_insurance_pcp: medicalFormItemContext?.form?.primary_insurance?.pcp,
          patient_insurance_spc: medicalFormItemContext?.form?.primary_insurance?.spc
        } : null,
        form_type: medicalFormItemContext?.form?.form_type || null,
        medical_forms_id: medicalFormItemContext?.form?.id
          ? [ medicalFormItemContext?.form?.id ] : null,
        appointmentID: appointment.id
      },

      onModalResolved: (appointment) => {
        handleUpdateAppt(appointment);
      }
    });
  };

  const openPreviewModal = () => {
    if (appointment.arrival_status?.arrival_type === arrivalTypesMap.waitingList) {
      openModal(AppointmentViewModal, {
        payload: {
          insurance_info: !!medicalFormItemContext?.form ? {
            insurance_details: medicalFormItemContext?.form?.primary_insurance ? {
              ...medicalFormItemContext?.form?.primary_insurance,
              company: medicalFormItemContext?.form?.primary_insurance?.insurance_company || null
            } : null,
            patient_insurance_pcp: medicalFormItemContext?.form?.primary_insurance?.pcp,
            patient_insurance_spc: medicalFormItemContext?.form?.primary_insurance?.spc
          } : null,
          form_type: medicalFormItemContext?.form?.form_type || null,
          medical_forms_id: medicalFormItemContext?.form?.id
            ? [ medicalFormItemContext?.form?.id ] : null,
          disableApptActions: true,
          onAppointmentUpdate: handleUpdateAppt,
          onAppointmentDelete: handleDeleteAppt,
          appointmentID: appointment.id
        }
      });
    } else {
      openModal(AppointmentViewModal, {
        payload: {
          medical_form_id: medicalFormItemContext?.form?.id || null,
          appointmentID: appointment.id,
          disableApptBook: true,
          onAppointmentUpdate: handleUpdateAppt,
          onAppointmentDelete: handleDeleteAppt
        }
      });
    }
  };

  useEffect(() => {
    const start = moment(moment.unix(appointment.appointment_at));
    const end = moment().startOf('day');
    const coundOfDays = start.diff(end, 'days');

    setIsDeletable(!appointment?.appointment_confirmed_by_office && coundOfDays < 15);
  }, []);

  return (
    <ListRow
      button={isAuthenticated && isUpdatable}
      minHeight={minRowHeight}
      onClick={
        (isAuthenticated && isUpdatable) ? stopPropagation(openPreviewModal) : undefined
      }
    >
      <ListRowCell column flexBasis={columnsWidths.date}>
        <Date noWrap gutterBottom date={appointment?.appointment_at} format="L, LT"/>

        <Box display="flex" alignItems="center">
          <Box clone mr={1}>
            <TimelapseIcon fontSize="small" />
          </Box>

          {moment.duration(appointment?.time, 'seconds').format('h[h] m[min]')}
        </Box>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.apptBook}>
        <Typography noWrap>
          {appointment?.appointment_book?.appointment_book_name || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.office}>
        <OfficeLink
          noWrap
          office={appointment?.office}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        <UserLink
          noWrap
          size="md"
          user={appointment?.appointment_book?.provider}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.confirm}>
        <ReadonlyCheckbox checked={!!appointment?.appointment_confirmed_by_office} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        {!appointment?.arrival_status ? '-' :
          <Label
            label={`${appointment?.arrival_status?.code} - ${appointment?.arrival_status?.description}`}
            color={arrivalTypes[appointment.arrival_status?.arrival_type]?.color}
          />
        }
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        {isUpdatable && hasRoleForEdit && (
          <Box clone mr={1}>
            <Tooltip isExistTooltip title="Edit an appointment">
              <IconButton
                color="info"
                size="small"
                onClick={stopPropagation(openAppointmentEditModal)}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {!isFollowUp && isAuthenticated && !hasRole(rolesMap.patient) && !!appointment?.id &&
          <Tooltip
            isExistTooltip={!!appointment?.appointment_confirmed_by_office}
            title="You cannot delete an appointment, it's already confirmed"
          >
            <IconButton
              disabled={isDeletable}
              color="error"
              size="small"
              onClick={stopPropagation(handleDeleteAppt)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </ListRowCell>
    </ListRow>
  );
};
