export const NOTES_FETCH_REQUEST = 'NOTES_FETCH_REQUEST';
export const NOTES_FETCH_SUCCESS = 'NOTES_FETCH_SUCCESS';
export const ADD_NOTE = 'ADD_NOTE';
export const APPLY_FILTER = 'APPLY_FILTER';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const RESET_NOTES = 'RESET_NOTES';
export const MASS_DELETE = 'MASS_DELETE';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
