import { forwardRef, useState } from 'react';
import T from 'prop-types';
import { Box, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../CustomScrollbars';
import { SideBar } from './SideBar';

const propTypes = {
  children: T.node,
  ActionsBarComponent: T.func,
  SideBarComponent: T.func,
  title: T.string
};

export const PageSideBar = forwardRef(({
  children,
  title,
  SideBarComponent,
  ActionsBarComponent,

  ...props
}, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [ openDrawer, setOpenDrawer ] = useState(true);

  return (
    <Box
      ref={ref}
      flexGrow={1}
      pl={isMobile ? 0 : 2}
      pr={isMobile ? 0 : 1.5}
      py={isMobile ? 0 : 1}
      overflow="hidden"
    >
      <Box
        display="flex"
        position="relative"
        height="100%"
        overflow="hidden"
      >
        <SideBar
          title={title}
          openDrawer={openDrawer}
          onDrawerUpdate={setOpenDrawer}
          SideBarComponent={SideBarComponent}
        />

        <Box display="flex" flexDirection="column" height="100%" width="100%">
          <ActionsBarComponent openDrawer={openDrawer} onDrawerUpdate={setOpenDrawer} {...props} />

          <Paper
            display="flex"
            square={false}
            component={Box}
            flexGrow={1}
            mb={isMobile ? 0 : 0.5}
            mx={isMobile ? 0 : 1}
          >
            <CustomScrollbars style={{ flexGrow: 1 }}>
              {children}
            </CustomScrollbars>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
});

PageSideBar.propTypes = propTypes;
