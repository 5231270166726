import { useContext } from 'react';
import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { apiModelsMap } from '../../../../../dataMaps/apiModels';
import { TaskPreviewContext } from '../../../../../components/tasks/TasksProvider';
import { TextField } from '../../../../../components/FormField';
import { AttachmentsContainer } from '../../AttachmentsContainer';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ isStaticPage }) => {
  const classes = useStyles();
  const { selectedTask } = useContext(TaskPreviewContext);

  return (
    <div className={cn(classes.body, !isStaticPage && classes.body_inModal)}>
      <div className={classes.body__item}>
        <Typography className={classes.body__title}>
          Description
        </Typography>

        <TextField
          multiline
          name="description"
          margin="normal"
          variant="filled"
          rows={4}
          InputProps={{
            className: classes.body__input,
            disableUnderline: true
          }}
        />
      </div>

      <AttachmentsContainer
        disableFetching
        filter={{ tasks: [ selectedTask.id ] }}
        files={selectedTask.files}
        ownerType={apiModelsMap.task}
        ownerID={selectedTask.id}
      />
    </div>
  );
};
