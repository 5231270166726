import { Redirect, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { Route } from '../../../components/router';
import { FeatureFlags } from './FeatureFlags';
import { Users } from './Users';
import { Roles } from './Roles';
import { User, EditUser } from './User';

const useStyles = makeStyles(({ palette: { grey } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: grey[100]
  }
}));

export const Members = () => {
  const classes = useStyles();

  return (
    <LayoutContextProvider>
      <div className={classes.root}>
        <Switch>
          <Route exact path="/members">
            <Redirect to="/members/users" />
          </Route>

          <Route exact path="/members/users" component={Users} />
          <Route path="/members/users/:userID/edit" component={EditUser} />
          <Route path="/members/users/:userID" component={User} />
          <Route path="/members/roles" component={Roles} />
          <Route path="/members/feature-flags" component={FeatureFlags} />
        </Switch>
      </div>
    </LayoutContextProvider>
  );
};
