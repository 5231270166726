export const rolesMap = {
  heading_attorney: 'guardian',
  applicant: 'applicant',
  attorney: 'attorney',
  defence_attorney: 'defence_attorney',
  defence_staff: 'defence_staff',
  assistant: 'assistant',
  client: 'client',
  doctor: 'doctor',
  creator: 'creator',
  paralegal: 'paralegal',
  defendant: 'defendant',
  passenger: 'passenger',
  adjuster: 'adjuster',
  referred_by: 'referred_by'
};

export const caseUserRolesTypesMap = {
  [rolesMap.heading_attorney]: 'Heading Attorney',
  [rolesMap.applicant]: 'Plaintiff',
  [rolesMap.attorney]: 'Attorney',
  [rolesMap.defence_attorney]: 'Defense Attorney',
  [rolesMap.defence_staff]: 'Defense Staff',
  [rolesMap.assistant]: 'Assistant',
  [rolesMap.client]: 'Adjuster',
  [rolesMap.doctor]: 'Doctors',
  [rolesMap.creator]: 'Creator',
  [rolesMap.paralegal]: 'Paralegal',
  [rolesMap.defendant]: 'Defendants',
  [rolesMap.passenger]: 'Passengers',
  [rolesMap.adjuster]: 'Adjuster',
  [rolesMap.referred_by]: 'Referrer'
};

export const caseUserRolesMap = {
  [rolesMap.applicant]: 'Plaintiff',
  [rolesMap.defendant]: 'Defendant'
};

export const caseUserRolesAvailableToReplace = [
  {
    value: rolesMap.heading_attorney,
    label: caseUserRolesTypesMap[rolesMap.heading_attorney]
  },
  {
    value: rolesMap.doctor,
    label: caseUserRolesTypesMap[rolesMap.doctor]
  },
  {
    value: rolesMap.assistant,
    label: caseUserRolesTypesMap[rolesMap.assistant]
  }
];

export const caseUserSingleRoles = [
  {
    value: rolesMap.creator,
    label: caseUserRolesTypesMap[rolesMap.creator]
  },
  {
    value: rolesMap.client,
    label: caseUserRolesTypesMap[rolesMap.client]
  },
  {
    value: rolesMap.applicant,
    label: caseUserRolesTypesMap[rolesMap.applicant]
  },
  {
    value: rolesMap.heading_attorney,
    label: caseUserRolesTypesMap[rolesMap.heading_attorney]
  }
];

export const caseUserRoles = [
  {
    value: rolesMap.passenger,
    label: caseUserRolesTypesMap[rolesMap.passenger]
  },
  {
    value: rolesMap.applicant,
    label: caseUserRolesTypesMap[rolesMap.applicant]
  },
  {
    value: rolesMap.defendant,
    label: caseUserRolesTypesMap[rolesMap.defendant]
  },
  {
    value: rolesMap.doctor,
    label: caseUserRolesTypesMap[rolesMap.doctor]
  },
  {
    value: rolesMap.heading_attorney,
    label: caseUserRolesTypesMap[rolesMap.heading_attorney]
  },
  {
    value: rolesMap.attorney,
    label: caseUserRolesTypesMap[rolesMap.attorney]
  },
  {
    value: rolesMap.paralegal,
    label: caseUserRolesTypesMap[rolesMap.paralegal]
  },
  {
    value: rolesMap.assistant,
    label: caseUserRolesTypesMap[rolesMap.assistant]
  },
  {
    value: rolesMap.defence_attorney,
    label: caseUserRolesTypesMap[rolesMap.defence_attorney]
  },
  {
    value: rolesMap.defence_staff,
    label: caseUserRolesTypesMap[rolesMap.defence_staff]
  },
  {
    value: rolesMap.referred_by,
    label: caseUserRolesTypesMap[rolesMap.referred_by]
  }
];

export const participantsUserRoles = [
  ...caseUserRoles,

  {
    value: rolesMap.creator,
    label: caseUserRolesTypesMap[rolesMap.creator]
  }
];
