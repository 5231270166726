import { useContext } from 'react';
import { debounce } from 'lodash';
import { TextField } from '../../../../FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../FiltersBar';
import { RuleTemplatesContext } from '../../RuleTemplatesProvider';

export const FiltersBar = () => {
  const { filter, applyFilter } = useContext(RuleTemplatesContext);

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ ...filter, [name]: value });
  }, 600);

  return (
    <FiltersBarComponent
      disableTopPaddings
      isResetForm
      initialValues={{ name: '' }}
      fields={[
        <TextField
          name="name"
          label="Name"
          onChange={handleFieldChange('name')}
        />
      ]}
    />
  );
};
