import { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { IconButton } from '../../IconButton';
import { Popper } from '../../Popper';
import { MapProviderContext } from '../MapProvider';
import { popperStyles, markerStyles } from './styles';

const usePopperStyles = makeStyles(popperStyles);
const useMarkerStyles = makeStyles(markerStyles);

export const Marker = ({ lat, lng, title, subtitle, ...props }) => {
  const popperClasses = usePopperStyles();
  const markerClasses = useMarkerStyles();
  const { googleMapRef } = useContext(MapProviderContext);

  return (
    <div>
      {!!googleMapRef.current?.googleMapDom_ && lat && lng &&
        <Popper
          isOpen
          classes={popperClasses}
          modifiers={{
            preventOverflow: {
              boundariesElement: googleMapRef.current?.googleMapDom_
            }
          }}
          anchorRenderer={({ anchorRef }) => (
            <IconButton color="error" className={markerClasses.marker} {...props}>
              <LocationOnIcon
                ref={anchorRef}
                fontSize="large"
              />
            </IconButton>

          )}
        >
          {title && <Typography variant="subtitle1">{title}</Typography>}
          {subtitle && <Typography>{subtitle}</Typography>}
        </Popper>
      }
    </div>
  );
};
