export const styles = ({
  shape: { borderRadius },
  palette,
  spacing
}) => ({
  root: {
    backgroundColor: palette.background.default,
    border: `1px solid ${palette.grey[200]}`,
    marginBottom: spacing(1.25),
    borderRadius
  },

  header: {
    color: palette.grey[500]
  },

  mainContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },

  userAvatar: {
    marginRight: spacing()
  },

  actionMenu: {
    marginLeft: 'auto',
    marginRight: -spacing()
  }
});
