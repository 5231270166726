export const styles = `
  @media (max-width: 900px) {
    .header, .box-center, .container { 
      width: 100% !important;
    }
    
    .header { 
      padding: 0 10px; 
    }
    
    .container h1 { 
      font-size: 20px; 
    }
    
    .text-field {
      width: 250px;
      font-size: 14px;
    }
    
    .container {
      padding: 0 10px 20px;
    }
    
    .locations, .company-phone {
      font-size: 12px;
    }
    
    .locations {
      padding-right: 10px;
    }
    
    .company-data {
      padding: 5px;
      font-size: 16px;
      font-weight: bold;
    }
`;
