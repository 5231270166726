import { api } from '../../api';

export const fetchDeletedMedicalForms = (config) => {
  return api.get('/deleted-medical-forms', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchDeletedMedicalForm = (id, config) => {
  return api.get(`/deleted-medical-forms/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const restoreDeletedMedicalForms = (IDs) => {
  return api.put('/deleted-medical-forms', { medical_forms: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteDeletedMedicalForms = (IDs) => {
  return api.post('/deleted-medical-forms', { medical_forms: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
