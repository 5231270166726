export const styles = ({ spacing, palette, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.grey[100],
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    padding: spacing(2, 3, 0),

    [breakpoints.down('md')]: {
      padding: spacing()
    }
  }
});
