import { columnsMap } from '../../../../../components/claims';

export const claimsHiddenColumns = [
  columnsMap.patient,
  columnsMap.caseNo,
  columnsMap.claimStatus,
  columnsMap.checkbox
];

export const claimsMobileHiddenColumns = [
  columnsMap.patient,
  columnsMap.caseNo,
  columnsMap.checkbox
];
