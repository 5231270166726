export const styles = ({ spacing, palette: { background } }) => ({
  toolbar: {
    display: 'flex',
    paddingTop: spacing(),
    paddingBottom: spacing(),
    paddingLeft: spacing(2),
    background: background.primary,
    alignItems: 'center'
  }
});
