import { useState, forwardRef, useRef } from 'react';
import { makeStyles, InputAdornment, Paper, TextField as MuiTextField, IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { SwatchesPicker } from 'react-color';
import { Popper } from '../../Popper';
import { Scrollbars } from '../../Scrollbars';
import { useFormikField } from '../useFormikField';
import { Input } from './Input';
import { colors } from './colors';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ColorPicker = forwardRef(({
  withoutFormik,
  popupMinWidth,
  fullWidth = true,
  type = 'text',
  helperText,
  name,
  value,
  onChange,
  InputProps = {},
  InputLabelProps = {},
  ...props
}, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField(name);
  const { isFormikField,
    fieldProps: [ field = {}, { touched } = {}, { setValue } = {} ] = [],
    error
  } = formikFieldProps;
  const textFieldRef = useRef();
  const [ popupWidth, setPopupWidth ] = useState();
  const classes = useStyles({ popupWidth });

  const togglePopup = (isOpen, handleToggle) => () => {
    if (!isOpen) {
      setPopupWidth(textFieldRef.current.getBoundingClientRect().width);
    }

    handleToggle();
  };

  const handleColorChangeComplete = (handleClose) => (color) => {
    isFormikField && setValue(color.hex);

    handleClose();
  };

  return (
    <Popper
      arrow={false}
      placement="bottom"
      anchorRenderer={({ isOpen, anchorRef, handleToggle }) => (
        <MuiTextField
          {...field}

          autoComplete="off"
          fullWidth={fullWidth}
          error={!!(touched && error)}
          type={type}
          name={name || field.name}
          value={value || field.value || ''}
          helperText={(touched && error) || helperText}
          onClick={togglePopup(isOpen, handleToggle)}
          InputLabelProps={{
            shrink: !!(value || field.value)
          }}
          InputProps={{
            ...InputProps,

            ref: anchorRef,
            inputComponent: Input,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small">
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          {...props}
          ref={(nodeRef) => {
            textFieldRef.current = nodeRef;

            if (ref) {
              ref.current = nodeRef;
            }
          }}
        />
      )}
    >
      {({ handleClose }) => (
        <Paper className={classes.popup}>
          <Scrollbars>
            <SwatchesPicker
              color={field.value || undefined}
              colors={colors}
              width="100%"
              height="auto"
              onChangeComplete={handleColorChangeComplete(handleClose)}
            />
          </Scrollbars>
        </Paper>
      )}
    </Popper>
  );
});
