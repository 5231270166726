import moment from 'moment';
import { MenuItem, ListItemText, Box, Typography } from '@material-ui/core';
import { VerticalDivider } from '../../../VerticalDivider';
import { UserAvatar } from '../../../users';
import { Date } from '../../../Date';

export const Option = ({ children: form, ...props }) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isSelected}
      component="div"

      {...props.innerProps}
    >
      <ListItemText
        primary={
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Box clone minWidth={23}>
              <Typography noWrap>{form.abbreviated_name || form.form_type}</Typography>
            </Box>

            {!!form.abbreviated_name && (
              <>
                <VerticalDivider horizontalGutters={1} />
                <Typography variant="caption" color="textSecondary">Follow Up:</Typography>
                &nbsp;
                <Box color="info.main">
                  <Typography color="inherit">{form?.children?.length}</Typography>
                </Box>
              </>
            )}

            <VerticalDivider horizontalGutters={1} />
            <Typography variant="caption" color="textSecondary">Date:</Typography>
            <Date noWrap iconFontSize="small" date={form.created_at} format="L, LT" />

            {form.doi && (
              <>
                <VerticalDivider horizontalGutters={1} />
                <Typography variant="caption" color="textSecondary">DOI:</Typography>
                <Date noWrap disableMoment iconFontSize="small" date={moment(form.doi).format('L')} />
              </>
            )}

            {!!form?.treating_physician?.id && (
              <>
                <VerticalDivider horizontalGutters={1} />
                <UserAvatar size="xs" user={form?.treating_physician} />
              </>
            )}
          </Box>
        }
        primaryTypographyProps={{ noWrap: true, component: 'div' }}
      />
    </MenuItem>
  );
};
