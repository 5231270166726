import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_SCHEDULE_SUCCESS]: (state, { daysData, events }) => {
    return {
      ...state,

      isFetched: true,
      daysData,
      events
    };
  },

  [types.FETCH_CALENDAR_SUCCESS]: (state, events) => {
    return {
      ...state,

      calendarEvents: events
    };
  },

  [types.RESET_SCHEDULE]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.ADD_SCHEDULE_EVENT]: (state, event) => {
    if (!(state.filter.started_from <= event.started_at && state.filter.started_to >= event.started_at)) {
      return state;
    }

    return {
      ...state,

      events: state.events.concat(event)
    };
  },

  [types.UPDATE_SCHEDULE_EVENT]: (state, event) => {
    return {
      ...state,

      events: state.events.map((item) => {
        return item.id === event.id ? { ...item, ...event } : item;
      })
    };
  },

  [types.DELETE_SCHEDULE_EVENT]: (state, eventID) => {
    return {
      ...state,

      events: state.events.filter(({ id }) => eventID !== id)
    };
  }
});
