import moment from 'moment';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiCalendarPlus } from '@mdi/js';
import * as appointmentTypesApi from '../../../api/codes/appointments/appointment-types';
import * as appointmentsApi from '../../../api/appointments';
import { setAppointmentsLastGlobalAction } from '../../../store/globalActions';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../Modal';
import { Loader } from '../../Loader';
import * as apptTypes from '../AppointmentsProvider/types';
import {
  AppointmentForm,
  Procedures,
  apptInitialValues,
  validationSchema
} from '../index';

export const AppointmentCreateModal = ({
  payload: {
    title = null,
    initialValues = {},
    appointmentTypeID = null
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const [ appointmentType, setAppointmentType ] = useState(null);

  const createAppointment = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.timezone = values?.timezone?.value || values?.timezone;

    return appointmentsApi.createAppointment({
      ...values,

      time: moment.duration(`${values._hours || 0}:${values._minutes || 0}`).asSeconds() || null
    }).then((appointment) => {
      enqueueSnackbar('Appointment successfully created', { variant: 'success' });
      handleModalResolve(appointment);

      if (!initialValues?.form_type) {
        dispatch(setAppointmentsLastGlobalAction({ type: apptTypes.FETCH_APPOINTMENTS_REQUEST }));
      }
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  useEffect(() => {
    if (appointmentTypeID) {
      appointmentTypesApi.fetchAppointmentType(appointmentTypeID).then(setAppointmentType);
    }
  }, []);

  return (
    <Dialog
      fullScreen={isMobile}
      PaperProps={!isMobile ? { style: { maxWidth: 1100 } } : {}}
      {...DialogProps}
    >
      <Loader p={2} loading={appointmentTypeID && !appointmentType} render={
        () => (
          <Formik
            initialValues={{
              ...apptInitialValues,
              ...initialValues,

              form_type: initialValues?.form_type || null,
              medical_id: initialValues?.medical_id || null,
              medical_forms_id: initialValues?.medical_forms_id || null,
              ...initialValues?.insurance_info,
              patient_insurance_id: initialValues?.insurance_info?.insurance_details,
              appointment_type_id: appointmentType,
              appointment_type: appointmentType,
              time: appointmentType?.time || 0
            }}
            validationSchema={validationSchema}
            onSubmit={createAppointment}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarPlus} /></SvgIcon>} onClose={handleModalReject}>
                  {title || 'Add new appointment'}
                </ModalHeader>

                <ModalBody>
                  <AppointmentForm appointmentType={appointmentType} />
                  <Procedures />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject} disabled={isSubmitting}>
                    Cancel
                  </Button>

                  <Loader
                    surface
                    loading={isSubmitting}
                    render={() => (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Add
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
