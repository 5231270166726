import { KeyboardDateTimePicker, TextField, TrackTypesSelect } from '../../FormField';

export const Body = () => {
  return (
    <>
      <TrackTypesSelect required name="type" margin="dense" />

      <KeyboardDateTimePicker
        required
        name="started_at"
        label="Started date"
        margin="dense"
      />

      <KeyboardDateTimePicker
        disableFuture
        name="finished_at"
        label="Finished date"
        margin="dense"
      />

      <TextField
        multiline
        rows={3}
        name="note"
        variant="filled"
        label="Notes"
        placeholder="Enter file name..."
        margin="dense"
      />
    </>
  );
};
