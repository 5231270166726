import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, useTheme, useMediaQuery } from '@material-ui/core';
import * as templatesApi from '../../../../api/schedule-events/schedule-event-templates';
import { errorsMap } from '../../../../utils/validation';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../Modal';
import { Loader } from '../../../Loader';
import { EventTemplateForm, validationSchema, initialValues } from '../EventTemplateForm';

export const CreateEventTemplateModal = ({
  DialogProps,
  payload: {
    initialValues: initialValuesProp
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const createEvent = (event, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    templatesApi.createEventTemplate(event).then((event) => {
      enqueueSnackbar(`Template "${event.name}" successfully created`, { variant: 'success' });
      handleModalResolve(event);
    }).catch(({ data: { errors } = {} }) => {
      if (errors?.name?.includes(errorsMap.unique)) {
        setErrors({ name: 'Template already exists' });
      } else if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Template not created', { variant: 'error' });

      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...initialValuesProp
      }}
      validationSchema={validationSchema}
      onSubmit={createEvent}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog disableEnforceFocus fullScreen={isMobile} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Create event template
            </ModalHeader>

            <ModalBody>
              <EventTemplateForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
