import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Grid, makeStyles, Typography, ListItem } from '@material-ui/core';
import { Badge } from '../../../../../../components/Badge';
import { useMessageContent } from '../../../../../../helpers/hooks';
import { getUserFullName } from '../../../../../../helpers/users';
import {
  MessengerContext,
  ThreadAvatar,
  formatDateForChat,
  getMessageAuthorName,
  MessageContent
} from '../../../../../../components/chat';
import { Loader } from '../../../../../../components/Loader';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ThreadsListLink = ({ thread = {}, handleHideSideBar = () => {}, isLoaded }) => {
  const classes = useStyles();
  const history = useHistory();
  const [ isSelected, setIsSelected ] = useState(false);
  const currentUser = useSelector(({ profile }) => profile.user);
  const { users = [], last_message } = thread;
  const { currentThreadId, setCurrentThreadId } = useContext(MessengerContext);
  const messageContent = useMessageContent(last_message);
  const lastMessage = !last_message
    ? null
    : last_message?.type === 'system'
      ? messageContent
      : `${getMessageAuthorName(last_message)}: ${messageContent}`;

  const chatName = useMemo(() => thread.name || users
    ?.filter((user) => user.id !== currentUser.id)
    .map(getUserFullName).join(', '), [ thread.name, users ]);

  const lastMessageTime = last_message
    ? formatDateForChat(last_message.created_at)
    : formatDateForChat(thread.updated_at);

  const handleLinkClick = () => {
    history.push(`/messenger/${thread.id}`);
    setCurrentThreadId(thread.id);
    handleHideSideBar();
  };

  useEffect(() => {
    setIsSelected(+currentThreadId === thread.id);
  }, [ currentThreadId ]);

  return (
    <ListItem
      button
      className={classes.root}
      selected={isSelected}
      onClick={handleLinkClick}
    >
      {!isLoaded ? <Loader p={1} /> : (
        <Grid
          container
          wrap="nowrap"
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <ThreadAvatar thread={thread} />
          </Grid>

          <Grid item xs style={{ overflow: 'hidden' }}>
            <Box mb={0.25}>
              <Typography noWrap variant="h5" color="inherit">{chatName}</Typography>
            </Box>

            {lastMessage &&
              <Typography noWrap component="div">
                <MessageContent message={lastMessage} />
              </Typography>
            }
          </Grid>

          <Grid item>
            <Box py={0.5}>
              <Typography align="center">{lastMessageTime}</Typography>
            </Box>

            <Box textAlign="right">
              <Badge
                isStatic
                color="primary"
                max={99}
                badgeContent={thread.count_unread_messages}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </ListItem>
  );
};
