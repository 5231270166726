import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Typography, useTheme } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { ExpirationDate } from '../../../../../../components/ExpirationDate';
import { Label } from '../../../../../../components/Label';
import { UserLink } from '../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../helpers/dom';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { Popper } from '../../../../../../components/Popper';
import { CaseModel } from '../../../../BillingPage/Invoices/InvoicesPage/InvoicesContent/CaseModel';
import { InsurancesContext, insuranceStatusMap, isPrimary } from '../../../../ProfilePage/insurance';
import { Menu } from '../../../../ProfilePage/insurance/InsuranceInfo/Menu';
import { ViewInsuranceInfoModal } from '../../../../ProfilePage/insurance/ViewInsuranceInfoModal';
import { columnsWidths } from '../listConfig';

export const Row = ({ insurance }) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const {
    selectedIDs,
    updateInsurance,
    resetInsurances,
    deleteInsurance
  } = useContext(InsurancesContext);
  const isSelected = selectedIDs?.indexOf(insurance.id) !== -1;
  const currentUser = useSelector(({ profile }) => profile);
  const user = insurance?.case_user || currentUser?.user;

  const openInsurancePreviewModal = () => {
    openModal(ViewInsuranceInfoModal, {
      payload: {
        user,
        insurance,
        onEdit: updateInsurance,
        onDelete: deleteInsurance
      },
      onModalResolved: resetInsurances
    });
  };

  return (
    <ListRow button selected={isSelected} onClick={stopPropagation(openInsurancePreviewModal)}>
      {/* TODO: open for future settings */}
      {/*<ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />*/}

      <ListRowCell flexBasis={columnsWidths.statusType}>
        <Label
          size="small"
          typographyProps={{
            variant: 'subtitle2'
          }}
          color={isPrimary(insurance.status_type) ? theme.palette.primary.main : theme.palette.slategray}
          label={insuranceStatusMap[insurance.status_type] }
        />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.type}>
        {!!insurance?.case_user ? (
          <UserLink
            parentProps={{ mb: 1 }}
            size="sm"
            variant="h5"
            user={insurance?.case_user}
          />
        ) : insurance.type_insurance}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.insuranceId}>
        <Typography noWrap variant="h5" title={insurance.insuranceId}>
          {insurance.insuranceId}
        </Typography>
      </ListRowCell>

      <ListRowCell grow noWrap flexBasis={columnsWidths.company}>
        <CompanyLink company={insurance?.insurance_company} />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.caseInfo}>
        {insurance?.case && (
          <CaseModel model={insurance?.case} />
        )}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.groupId}>
        {insurance.groupId}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.pcp}>
        <CurrencyFormat value={insurance.pcp} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.spc}>
        <CurrencyFormat value={insurance.spc} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.financialClass}>
        {insurance?.financial_class?.code || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.expirationDate}>
        {!insurance.expiration_day
          ? <Typography color="textSecondary">No info.</Typography>
          : <ExpirationDate date={insurance.expiration_day}/>
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <Menu insurance={insurance} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
