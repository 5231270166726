import { ErrorMessage, useFormikContext } from 'formik';
import moment from 'moment';
import { Box, FormControlLabel, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Checkbox, TimePicker } from '../../../../../../components/FormField';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { IconButton } from '../../../../../../components/IconButton';
import { columnsWidthsLg, columnsWidthsSm } from './WorkSchedule';
import { Breaks } from './Breaks';
import { daysMap } from './daysMap';

export const Row = ({ item, parentIndex }) => {
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  const removeDaySchedule = () => {
    setFieldValue('work_schedules', values.work_schedules.filter((_, index) => index !== parentIndex));
  };

  const addBreakTime = () => {
    setFieldValue(
      `work_schedules.${parentIndex}.breaks`,
      values?.work_schedules?.[parentIndex]?.breaks?.concat({ from: null, to: null })
    );
  };

  const removeBreakTime = (childIndex) => () => {
    setFieldValue(
      `work_schedules.${parentIndex}.breaks`,
      values?.work_schedules?.[parentIndex]?.breaks?.filter((_, i) => i !== childIndex)
    );
  };

  return (
    <div>
      <ListRow disableBorder>
        <ListRowCell flexBasis={columnsWidths.day}>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">
              {daysMap?.[item.day] || (item.date && moment(item.date).format('L'))}
            </Typography>

            <Typography color="error">
              <ErrorMessage name={`work_schedules.${parentIndex}.day`} />
            </Typography>
          </Box>
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dayOff}>
          <Box maxWidth={columnsWidths.dayOff} overflow="hidden">
            <FormControlLabel
              label={false}
              control={
                <Checkbox
                  color="primary"
                  name={`work_schedules.${parentIndex}.day_off`}
                />
              }
            />
          </Box>
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.start}>
          <Box width="100%">
            <TimePicker
              disabled={!!values?.work_schedules?.[parentIndex]?.day_off}
              fullWidth
              required
              name={`work_schedules.${parentIndex}.start`}
              placeholder="Start time..."
            />
          </Box>
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.end}>
          <Box width="100%">
            <TimePicker
              required
              fullWidth
              disabled={!!values?.work_schedules?.[parentIndex]?.day_off}
              name={`work_schedules.${parentIndex}.finish`}
              placeholder="End time..."
            />
          </Box>
        </ListRowCell>

        <ListRowCell justifyContent="space-between" flexBasis={columnsWidths.action}>
          {parentIndex > 6 &&
            <IconButton
              size="small"
              color="error"
              onClick={removeDaySchedule}
            >
              <ClearIcon fontSize="small"/>
            </IconButton>
          }
        </ListRowCell>
      </ListRow>

      {item?.breaks?.map((child, index) => (
        <Breaks
          key={index}
          parentIndex={parentIndex}
          childIndex={index}
          item={child}
          onBreakAdd={addBreakTime}
          onBreakRemove={removeBreakTime}
        />
      ))}
    </div>
  );
};
