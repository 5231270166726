import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, breakpoints, typography: { pxToRem } }) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },

  indicator: {
    width: pxToRem(12),
    minWidth: pxToRem(12),
    height: pxToRem(12),
    marginRight: spacing(),
    borderRadius: '50%',
    backgroundColor: ({ color }) => color
  },

  label: {
    lineHeight: 1,

    [breakpoints.down(breakpoints.values.md)]: {
      padding: spacing(0.25, 0)
    }
  }
}));

export const ArrivalType = ({ type }) => {
  const classes = useStyles(type);

  return (
    <div className={classes.root}>
      <div className={classes.indicator} />

      <Typography noWrap variant="body2" className={classes.label}>
        {type.name}
      </Typography>
    </div>
  );
};
