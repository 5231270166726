export const roleLabelsMap = {
  admin: 'admin',
  supervisor: 'supervisor',
  patient: 'patient',
  client: 'adjuster',
  advocate: 'advocate',
  medic: 'medic',
  doctor: 'doctor',
  secretary: 'secretary'
};
