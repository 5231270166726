import { useRef } from 'react';
import { List as MuiList, Typography, Box } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { Loader } from '../../../../../../../components/Loader';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const List = ({ isTabsPage, width, resetForms, formContext = {} }) => {
  const { isFetched, forms, meta, medicalInfoPathname = '' } = formContext;
  const scrollElementRef = useRef();

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => !forms.length ? (
          <Box p={3}>
            <Typography align="center">No forms found</Typography>
          </Box>
        ) : (
          <>
            <TableHeader
              formContext={formContext}
              disablePatient={!!medicalInfoPathname.length}
              isTabsPage={isTabsPage}
              width={width}
            />

            <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
              <MuiList disablePadding>
                {forms.map((form) => (
                  <Row
                    formContext={formContext}
                    disablePatient={!!medicalInfoPathname.length}
                    isTabsPage={isTabsPage}
                    key={form.id}
                    width={width}
                    form={form}
                  />
                ))}
              </MuiList>

              <TablePagination pagination={meta} onChange={resetForms} />
            </CustomScrollbars>
          </>
        )}
      />
    </Box>
  );
};
