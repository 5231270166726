import moment from 'moment';

export const recurrenceVariantsMap = {
  noRepeat: 'No repeat',
  daily: 'RRULE:FREQ=DAILY;WKST=SU',
  weeklyOnTheOwnWeekday: `RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=${moment().format('dd').toUpperCase()}`,
  monthlyOfTheOwnWeekday: `RRULE:FREQ=MONTHLY;WKST=SU;BYDAY=${moment().format('dd').toUpperCase()};BYSETPOS=1`,
  yearly: 'RRULE:FREQ=YEARLY;WKST=SU',
  everyWorkday: 'RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=MO,TU,WE,TH,FR,SU',
  customRecurrence: 'Custom recurrence'
};

export const selectOptions = [
  {
    title: 'Does not repeat',
    value: recurrenceVariantsMap.noRepeat
  },
  {
    title: 'Daily',
    value: recurrenceVariantsMap.daily
  },
  {
    title: `Weekly on ${moment().format('dddd')}`,
    value: recurrenceVariantsMap.weeklyOnTheOwnWeekday
  },
  {
    title: `Monthly of the first ${moment().format('dddd')}`,
    value: recurrenceVariantsMap.monthlyOfTheOwnWeekday
  },
  {
    title: `Annually on ${moment().format('MMMM D')}`,
    value: recurrenceVariantsMap.yearly
  },
  {
    title: 'Every weekday (Sunday to Friday)',
    value: recurrenceVariantsMap.everyWorkday
  }
];
