import { createContext, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { api } from '../../../../api';
import * as deletedFilesApi from '../../../../api/files/deleted-files';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useModal } from '../../../../components/ModalsProvider';

export const TrashContext = createContext(null);

export const TrashContextProvider = ({ children, ownerType, ownerID }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ isFetched, setIsFetched ] = useState(false);
  const [ isFetching, setIsFetching ] = useState(false);
  const [ files, setFiles ] = useState([]);
  const [ pagination, setPagination ] = useState({});
  const [ selectedFilesIDs, setSelectedFilesIDs ] = useState([]);
  const [ filter, setFilter ] = useState({
    page: 1,
    per_page: 8,
    owner_type: ownerType,
    owner_id: ownerID
  });
  const cancelFetch = useRef(() => {});

  const fetchFiles = (newFilter = {}) => {
    const currentFilter = { ...filter, ...newFilter };

    cancelFetch.current();

    setIsFetching(true);
    setFilter(currentFilter);

    deletedFilesApi.fetchFiles({
      params: currentFilter,
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    })
      .then(({ data, pagination: { page, per_page, ...pagination } }) => {
        setIsFetched(true);
        setFilter((state) => ({ ...state, page, per_page }));
        setPagination(pagination);
        setIsFetching(false);
        setFiles((state) => {
          if (page > 1) {
            return [ ...state.filter(({ id }) => !data.find((loadedFile) => id === loadedFile.id)), ...data ];
          } else {
            return data;
          }
        });
      });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFiles({ page: filter.page + 1 });
    }
  };

  const resetFiles = (newFilter) => {
    setIsFetched(false);
    setFiles([]);
    fetchFiles({ page: 1, ...newFilter });
  };

  const deleteFiles = (filesIDs) => {
    return new Promise(((resolve) => {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          const total = pagination.total - filesIDs.length;
          const page = Math.ceil((files.length - filesIDs.length) / filter.per_page) - 1;
          const last_page = Math.ceil(total / filter.per_page);

          deletedFilesApi.deleteFiles(filesIDs).then(() => {
            setPagination(({ ...state }) => ({ ...state, total, last_page }));
            setFilter((state) => ({ ...state, page }));
            setSelectedFilesIDs((state) => state.filter((id) => !filesIDs.find((deletedID) => deletedID === id)));
            setFiles((state) => state.filter((file) => !filesIDs.find((id) => id === file.id)));

            enqueueSnackbar(`${filesIDs.length} file successfully deleted`);

            resolve(filesIDs);
          });
        }
      });
    }));
  };

  const emptyTrash = ({ owner_type, owner_id }) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        deletedFilesApi.emptyTrash({ owner_type, owner_id }).then(() => {
          setFilter((state) => ({ ...state, page: 1 }));
          setPagination(({ total: 0, last_page: 1 }));
          setSelectedFilesIDs([]);
          setFiles([]);

          enqueueSnackbar('All files successfully deleted');
        });
      }
    });
  };

  const restoreFiles = (filesIDs) => {
    return new Promise(((resolve) => {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          const total = pagination.total - filesIDs.length;
          const page = Math.ceil((files.length - filesIDs.length) / filter.per_page) - 1;
          const last_page = Math.ceil(total / filter.per_page);

          deletedFilesApi.restoreFiles(filesIDs).then(() => {
            setPagination(({ ...state }) => ({ ...state, total, last_page }));
            setFilter((state) => ({ ...state, page }));
            setSelectedFilesIDs((state) => state.filter((id) => !filesIDs.find((deletedID) => deletedID === id)));
            setFiles((state) => state.filter((file) => !filesIDs.find((id) => id === file.id)));

            enqueueSnackbar(`${filesIDs.length} file successfully restored`);

            resolve(filesIDs);
          });
        }
      });
    }));
  };

  const toggleItemSelection = (id) => {
    setSelectedFilesIDs((state) => {
      return selectedFilesIDs.indexOf(id) !== -1 ? state.filter((item) => item !== id) : state.concat(id);
    });
  };

  const allFilesIsSelected = () => {
    return files.length === selectedFilesIDs.length;
  };

  const toggleAllFilesSelection = () => {
    setSelectedFilesIDs(allFilesIsSelected() ? [] : files.map((item) => item.id));
  };

  const providerValue = {
    isFetched,
    isFetching,
    files,
    selectedFilesIDs,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    setFiles,
    resetFiles,
    loadNextPage,
    deleteFiles,
    restoreFiles,
    emptyTrash,
    toggleItemSelection,
    allFilesIsSelected,
    toggleAllFilesSelection,
    setSelectedFilesIDs
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    if (isFetched && files.length === 0 && pagination.total > 0) {
      resetFiles();
    }
  }, [ files.length ]);

  return (
    <TrashContext.Provider value={providerValue}>
      {children}
    </TrashContext.Provider>
  );
};
