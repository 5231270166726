export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { pxToRem }
}) => ({
  root: {
    paddingTop: spacing(2)
  },

  billFromTitle: {
    color: palette.darkgreen,
    marginBottom: spacing(3)
  },

  syncedInfo: {
    paddingLeft: spacing(2.5)
  },

  rootInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    paddingTop: spacing(1),
    borderBottom: `1px dashed ${grey[300]}`
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(0, 2.5, 1, 2.5)
  },

  leftContent: {
    flexGrow: 1
  },

  rightContent: {
    width: 480
  },

  titleContent: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: spacing()
  },

  titleContent_leftSide: {
    alignItems: 'center'
  },

  titleContent_contactInfo: {
    alignItems: 'flex-start'
  },

  titleContent_userInfo: {
    alignItems: 'center',
    marginTop: spacing(-0.5)
  },

  titleContent_status: {
    marginTop: spacing(0.25)
  },

  title: {
    fontSize: pxToRem(24),

    '&:first-child': {
      marginRight: spacing(2)
    }
  },

  mainContent: {
    display: 'flex',
    alignItems: 'flex-start'
  }
});
