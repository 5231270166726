import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import * as scheduleEventsApi from '../../../../api/schedule-events';
import { Select } from '../Select';
import { MultiValue } from './MultiValue';
import { Option } from './Option';
import { SingleValue } from './SingleValue';

export const transformScheduleEventTypeToOption = (type) => type && ({
  value: type.id,
  label: type,
  data: type
});

const components = {
  SingleValue,
  MultiValue,
  Option
};

const fetchScheduleEventTypes = (params) => fetchDataForAsyncSelect((name, { page }) => {
  return scheduleEventsApi.fetchEventTypes({
    params: {
      name,
      page,
      is_active: 1,

      ...params
    }
  });
}, {
  transformDataToOption: transformScheduleEventTypeToOption
});

export const ScheduleEventTypesSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      label="Type"
      placeholder="Search type..."
      promiseWithOptions={fetchScheduleEventTypes(params)}
      components={components}

      {...props}
    />
  );
};
