import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { SavedFiltersContext } from '../../../../../components/saved-filters';
import { ListHeader } from './ListHeader';
import { Row } from './Row';

export const columnsWidths = {
  date: 160,
  recipient: 150,
  action: 52
};

export const List = ({ onApplySavedFilter }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Box p={2}>
            <Typography align="center">No saved filters</Typography>
          </Box>
        ) : (
          <>
            <ListHeader />

            <MuiList disablePadding>
              <InfiniteListLoader
                items={savedFilters}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ onApplySavedFilter }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
