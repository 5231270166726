import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Box,
  FormControlLabel,
  Grid,
  Hidden,
  InputAdornment,
  Typography
} from '@material-ui/core';
import { Checkbox, KeyboardDatePicker, TextField } from '../../../../../../components/FormField';

export const TreatmentPlan = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!values?.forms?.treatment_plan?.physical_therapy?.check) {
      setFieldValue('forms.treatment_plan.physical_therapy.text', null);
    }
  }, [ values?.forms?.treatment_plan?.physical_therapy?.check ]);

  useEffect(() => {
    if (!values?.forms?.treatment_plan?.acupuncture?.check) {
      setFieldValue('forms.treatment_plan.acupuncture.text', null);
    }
  }, [ values?.forms?.treatment_plan?.acupuncture?.check ]);

  useEffect(() => {
    if (!values?.forms?.treatment_plan?.without_modification?.check) {
      setFieldValue('forms.treatment_plan.without_modification.text', null);
    }
  }, [ values?.forms?.treatment_plan?.without_modification?.check ]);

  useEffect(() => {
    if (!values?.forms?.treatment_plan?.with_modification?.check) {
      setFieldValue('forms.treatment_plan.with_modification.text', null);
    }
  }, [ values?.forms?.treatment_plan?.with_modification?.check ]);

  useEffect(() => {
    if (!values?.forms?.treatment_plan?.investigation_planned?.check) {
      setFieldValue('forms.treatment_plan.investigation_planned.text', null);
    }
  }, [ values?.forms?.treatment_plan?.investigation_planned?.check ]);

  useEffect(() => {
    if (!values?.forms?.treatment_plan?.procedure_to_be_performed?.check) {
      setFieldValue('forms.treatment_plan.procedure_to_be_performed.text', null);
    }
  }, [ values?.forms?.treatment_plan?.procedure_to_be_performed?.check ]);

  return (
    <Grid container spacing={0} component={Box} px={2}>
      <Grid item sm={4} xs={12}>
        <Box display="flex" alignItems="center" pr={4}>
          <Box minWidth={200}>
            <FormControlLabel
              label="Physical Therapy"
              control={
                <Checkbox
                  color="primary"
                  name="forms.treatment_plan.physical_therapy.check"
                />
              }
            />
          </Box>

          <TextField
            zeroMinWidth
            disabled={!values?.forms?.treatment_plan?.physical_therapy?.check}
            name="forms.treatment_plan.physical_therapy.text"
          />
        </Box>
      </Grid>

      <Grid item sm={4} xs={12}>
        <Box display="flex" alignItems="center" pr={4}>
          <Box minWidth={200}>
            <FormControlLabel
              label="Acupuncture"
              control={
                <Checkbox
                  color="primary"
                  name="forms.treatment_plan.acupuncture.check"
                />
              }
            />
          </Box>

          <TextField
            zeroMinWidth
            disabled={!values?.forms?.treatment_plan?.acupuncture?.check}
            name="forms.treatment_plan.acupuncture.text"
          />
        </Box>
      </Grid>

      <Hidden only="xs">
        <Grid item xs={4} />
      </Hidden>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Renew current medications"
          control={
            <Checkbox
              color="primary"
              name="forms.treatment_plan.renew_current_medications.check"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <Box display="flex" alignItems="center" pr={4}>
          <Box minWidth={200}>
            <FormControlLabel
              label="Without modification"
              control={
                <Checkbox
                  color="primary"
                  name="forms.treatment_plan.without_modification.check"
                />
              }
            />
          </Box>

          <TextField
            zeroMinWidth
            disabled={!values?.forms?.treatment_plan?.without_modification?.check}
            name="forms.treatment_plan.without_modification.text"
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" pr={4}>
          <Box minWidth={200}>
            <FormControlLabel
              label="With modification"
              control={
                <Checkbox
                  color="primary"
                  name="forms.treatment_plan.with_modification.check"
                />
              }
            />
          </Box>

          <TextField
            zeroMinWidth
            disabled={!values?.forms?.treatment_plan?.with_modification?.check}
            name="forms.treatment_plan.with_modification.text"
          />
        </Box>
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Urine test"
          control={
            <Checkbox
              color="primary"
              name="forms.treatment_plan.urine_test.check"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Potential risks re/reviewed"
          control={
            <Checkbox
              color="primary"
              name="forms.treatment_plan.potential_risks_re_reviewed.check"
            />
          }
        />
      </Grid>

      <Hidden only="xs">
        <Grid item xs={4} />
      </Hidden>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" pr={4}>
          <Box minWidth={200}>
            <FormControlLabel
              label="Investigation Planned:"
              control={
                <Checkbox
                  color="primary"
                  name="forms.treatment_plan.investigation_planned.check"
                />
              }
            />
          </Box>

          <TextField
            zeroMinWidth
            disabled={!values?.forms?.treatment_plan?.investigation_planned?.check}
            name="forms.treatment_plan.investigation_planned.text"
          />
        </Box>
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Request Authorization"
          control={
            <Checkbox
              color="primary"
              name="forms.treatment_plan.request_authorization.check"
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" pr={4}>
          <Box minWidth={250}>
            <FormControlLabel
              label="Procedure to be performed:"
              control={
                <Checkbox
                  color="primary"
                  name="forms.treatment_plan.procedure_to_be_performed.check"
                />
              }
            />
          </Box>

          <TextField
            zeroMinWidth
            disabled={!values?.forms?.treatment_plan?.procedure_to_be_performed?.check}
            name="forms.treatment_plan.procedure_to_be_performed.text"
          />
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <Box display="flex" alignItems="center" pr={4} my={3}>
          <Box mr={1}>
            <Typography variant="h5">Time Spent:</Typography>
          </Box>

          <Box width={100}>
            <TextField
              zeroMinWidth
              type="number"
              name="forms.treatment_plan.time_spent"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    minutes
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <Box display="flex" alignItems="center" pr={4} my={3}>
          <Box mr={1} minWidth={150}>
            <Typography variant="h5">Next Follow-Up:</Typography>
          </Box>

          <KeyboardDatePicker
            clearable
            outputFormat="YYYY-MM-DD"
            placeholder="MM/DD/YYYY"
            name="forms.treatment_plan.next_follow_up"
          />
        </Box>
      </Grid>
    </Grid>
  );
};
