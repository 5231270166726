import { Dialog } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ModalHeader, ModalBody } from '../../../../../../components/Modal';
import { FileDropZone } from '../../../../../../components/FileDropZone';
import { Divider } from '../../../../../../components/Divider';

export const FilesUploadModal = ({
  DialogProps,
  payload: {
    multiple
  },
  handleModalResolve,
  handleModalReject
}) => {
  return (
    <Dialog {...DialogProps}>
      <ModalHeader onClose={handleModalReject}>
        Upload files
      </ModalHeader>

      <ModalBody>
        <Alert severity="info">
          Only audio files up to 100MB are allowed
        </Alert>

        <Divider gutter={1} />

        <FileDropZone
          multiple={multiple}
          // 100MB
          maxSize={104857600}
          accept={[ '.wav', '.mp3', '.ogg', '.aac' ]}
          onDropAccepted={handleModalResolve}
        />
      </ModalBody>
    </Dialog>
  );
};
