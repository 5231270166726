import { useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, SvgIcon, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { mdiEmailEditOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useSelector } from 'react-redux';
import * as emailsApi from '../../../../../api/emails';
import { Editor, transformAccountToOption } from '../../../../../components/FormField';
import { useModal } from '../../../../../components/ModalsProvider';
import { FilePicker } from '../../../../../components/FilePicker';
import { Loader } from '../../../../../components/Loader';
import {
  ModalContainer,
  ModalHeader,
  ModalHeaderActions,
  ModalBody,
  ModalFooter
} from '../../../../../components/Modal';
import { SaveEmailToDraftsModal } from '../SaveEmailToDraftsModal';
import { sendValidationSchema } from '../sendValidationSchema.js';
import { getSignature, getOriginalMessageSignature } from '../signatureHelpers';
import { EmailsConfigSettings } from './EmailsConfigSettings';

export const EmailsReplyModal = (({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { disableReplayAll = false, replyTo }
}) => {
  const [ fullScreen, setFullScreen ] = useState(false);
  const [ attachments, setAttachments ] = useState([]);
  const [ availableFilesIDs, setAvailableFilesIDs ] = useState([]);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(({ profile }) => profile.user);
  const accounts = currentUser?.email_accounts;
  const cc = replyTo?.cc || [];
  const to = replyTo?.to || [];
  const bcc = replyTo?.bcc || [];
  const [ ccFieldVisible, setCcFieldVisible ] = useState(
    !disableReplayAll ? (!!cc.length || !!to.length): false
  );
  const [ bccFieldVisible, setBccFieldVisible ] = useState(!disableReplayAll ? !!bcc.length : false);
  const isReplySubject = replyTo.subject.split(' ').includes('Re:');
  const accountsOptions = accounts?.map(transformAccountToOption) || [];
  const from = accounts.find(({ id }) => id === replyTo?.config_id) ||
    accounts.find(({ email }) => email === replyTo?.to[0].email);
  const selectedConfigId = transformAccountToOption(from)?.value || accountsOptions[0]?.value;
  const originalSignature = getOriginalMessageSignature(replyTo);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const handleFullScreen = () => {
    setFullScreen((fullScreen) => !fullScreen);
  };

  const handleCcFieldVisible = () => {
    setCcFieldVisible(true);
  };

  const handleBccFieldVisible = () => {
    setBccFieldVisible(true);
  };

  const handleAttachments = (files) => {
    setAttachments(files);
  };

  const handleAvailableFilesIDs = (filesIDs) => {
    setAvailableFilesIDs(filesIDs);
  };

  const getFormData = (config) => {
    const formData = new FormData();

    formData.append('config_id', config.config_id);
    formData.append('message_id', config.message_id);
    formData.append('subject', config.subject);
    formData.append('body', config.body);

    config.to.forEach((to) => {
      formData.append('to[]', to);
    });

    if (config.cc) {
      config.cc.forEach((cc) => {
        formData.append('cc[]', cc);
      });
    }

    if (config.bcc) {
      config.bcc.forEach((bcc) => {
        formData.append('bcc[]', bcc);
      });
    }

    attachments.forEach((attachment) => {
      formData.append('attachments[]', attachment, attachment.name);
    });

    availableFilesIDs.forEach((fileID) => {
      formData.append('attach_media_id[]', fileID);
    });

    return formData;
  };

  const handleCancel = (config) => () => {
    if (!config.subject || !config.to || !config.body) {
      handleModalReject();
      return;
    }

    openModal(SaveEmailToDraftsModal, {
      payload: { config }
    });

    handleModalReject();
  };

  const sendEmail = (config, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const formData = getFormData(config);

    return emailsApi.answerEmail(formData).then(() => {
      enqueueSnackbar('Email sent successfully', { variant: 'success' });
      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Email not sent', { variant: 'error' });
    });
  };

  return (
    <Dialog
      disableEnforceFocus
      fullWidth={!fullScreen}
      fullScreen={fullScreen || isMobile}
      PaperProps={{
        style: {
          maxWidth: fullScreen ? null : 700
        }
      }}
      {...DialogProps}
    >
      <Formik
        initialValues={{
          config_id: !!accounts?.length
            ? selectedConfigId
            : replyTo.config_id || replyTo.folder.config_id,
          message_id: replyTo?.messageId || replyTo?.message_id,
          to: replyTo?.from,
          subject: isReplySubject ? replyTo.subject : `Re: ${replyTo.subject}`,
          cc: !disableReplayAll ? [ ...to, ...cc ] : null,
          bcc: !disableReplayAll ? bcc : null,
          body: getSignature(from?.signature_line?.html) + originalSignature
        }}
        validationSchema={sendValidationSchema}
        onSubmit={sendEmail}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiEmailEditOutline} /></SvgIcon>} onClose={handleModalReject}>
              Reply

              {!isMobile && (
                <ModalHeaderActions disableGutters>
                  <IconButton onClick={handleFullScreen}>
                    {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </IconButton>
                </ModalHeaderActions>
              )}
            </ModalHeader>

            <ModalBody>
              <EmailsConfigSettings
                accounts={accounts}
                originalSignature={originalSignature}
                accountsOptions={accountsOptions}
                ccFieldVisible={ccFieldVisible}
                bccFieldVisible={bccFieldVisible}
                onCcFieldVisibleUpdate={handleCcFieldVisible}
                onBccFieldVisibleUpdate={handleBccFieldVisible}
              />

              <Editor name="body" margin="normal" />

              <FilePicker onChangeFiles={handleAttachments} onChangeAvailableFilesIDs={handleAvailableFilesIDs} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleCancel(values)} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Send
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
});
