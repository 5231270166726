import { AppointmentsFilterProvider, AppointmentsProvider } from '../index';
import { AppointmentsWidget } from '../../Widgets/AppointmentsWidget';

export const AllAppointments = ({ patient, ...props }) => {
  return (
    <AppointmentsFilterProvider filter={{ patients: patient?.id ? [ patient?.id ] : [] }}>
      <AppointmentsProvider>
        <AppointmentsWidget patient={patient} {...props} />
      </AppointmentsProvider>
    </AppointmentsFilterProvider>
  );
};
