import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_EVENT_TEMPLATES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_EVENT_TEMPLATES_SUCCESS]: (
    { filter, eventTemplates, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      eventTemplates: (page > 1
        ? eventTemplates.filter(({ id }) => {
          return !data.find((eventTemplate) => id === eventTemplate.id);
        }).concat(data)
        : data
      )
    };
  },

  [types.RESET_EVENT_TEMPLATES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      eventTemplates: [],
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_EVENT_TEMPLATE]: (state, eventTemplate) => {
    return {
      ...state,

      eventTemplates: state.eventTemplates.map((item) => {
        return item.id === eventTemplate.id ? { ...item, ...eventTemplate } : item;
      })
    };
  },

  [types.DELETE_EVENT_TEMPLATE]: (state, eventTemplateID) => {
    const { pagination, filter, eventTemplates } = state;
    const filteredEventTemplates = eventTemplates.filter(({ id }) => eventTemplateID !== id);

    if (filteredEventTemplates.length === eventTemplates.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((eventTemplates.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      eventTemplates: filteredEventTemplates
    };
  }
});
