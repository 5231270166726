import { createContext, useRef, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../../api';
import * as arrivalStatusesApi from '../../../../../../api/codes/appointments/arrival-statuses';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const ArrivalStatusesContext = createContext();

export const ArrivalStatusesContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { isFetched, isFetching, pagination, filter, arrivalStatuses } = state;
  const cancelFetch = useRef(() => {});

  const fetchArrivalStatuses = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_ARRIVAL_STATUSES_REQUEST });

    arrivalStatusesApi.fetchArrivalStatuses({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_ARRIVAL_STATUSES_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchArrivalStatuses({ page: filter.page + 1 });
    }
  };

  const resetArrivalStatuses = (newFilter) => {
    dispatch({ type: types.RESET_ARRIVAL_STATUSES, payload: newFilter });

    fetchArrivalStatuses({ page: 1, ...newFilter });
  };

  const toggleActiveArrivalStatus = (arrivalStatus) => {
    return arrivalStatusesApi.updateArrivalStatus(arrivalStatus).then((arrivalStatus) => {
      dispatch({ type: types.UPDATE_ARRIVAL_STATUS, payload: arrivalStatus });
    });
  };

  const deleteArrivalStatus = (arrivalStatus) => {
    return new Promise((resolve) => {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          arrivalStatusesApi.deleteArrivalStatus(arrivalStatus.id).then(() => {
            dispatch({ type: types.DELETE_ARRIVAL_STATUS, payload: arrivalStatus.id });
            enqueueSnackbar(`Arrival status "${arrivalStatus.code}" successfully deleted`, { variant: 'success' });
            resolve();
          });
        },

        onModalRejected: resolve
      });
    });
  };

  const providerValue = {
    isFetched,
    isFetching,
    arrivalStatuses,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetArrivalStatuses,
    fetchArrivalStatuses,
    loadNextPage,
    toggleActiveArrivalStatus,
    deleteArrivalStatus
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ArrivalStatusesContext.Provider value={providerValue}>
      {children}
    </ArrivalStatusesContext.Provider>
  );
};
