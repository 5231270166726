import { Typography } from '@material-ui/core';
import { PhysicalExamination } from './PhysicalExamination';
import { DiagnosticStudies } from './DiagnosticStudies';
import { MedicalCausation } from './MedicalCausation';
import { MedicalFormInfo } from './MedicalFormInfo';
import { TreatmentPlan } from './TreatmentPlan';
import { Discussion } from './Discussion';

export const InitialReport = ({ report, ...props }) => {
  return !!report?.forms ? (
    <>
      {report?.forms?.medical_form_info &&
        <MedicalFormInfo report={report?.forms?.medical_form_info} {...props} />
      }
      {report?.forms?.physical_examination &&
        <PhysicalExamination report={report?.forms?.physical_examination} {...props} />
      }
      {report?.forms?.diagnostic_studies &&
        <DiagnosticStudies report={report?.forms?.diagnostic_studies} {...props} />
      }
      {!!report?.forms?.treatment_plan?.length &&
        <TreatmentPlan report={report} {...props} />
      }
      {report?.forms?.discussion?.text &&
        <Discussion report={report?.forms?.discussion} {...props} />
      }
      {report?.forms?.medical_causation?.text &&
        <MedicalCausation report={report?.forms?.medical_causation} {...props} />
      }
    </>
  ) : (
    <Typography color="textSecondary">Form is not filled</Typography>
  );
};
