export const styles = ({ spacing, palette: { grey, divider } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRight: `1px solid ${divider}`
  },

  top: {
    padding: spacing(2, 3)
  },

  navItemIcon: {
    color: grey[500]
  },

  bottom: {
    flexGrow: 1,
    overflow: 'hidden'
  },

  uploadButton: {
    flexShrink: 0,
    marginBottom: spacing(2)
  },

  uploadButton__icon: {
    marginRight: spacing(2)
  }
});
