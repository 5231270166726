import { api } from '../api';

export const fetchPayments = (config = {}) => {
  return api.get('/payments', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createPayment = (paymentData) => {
  return api.post('/payments', paymentData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const massCreatePayment = (paymentData) => {
  return api.post('/payments/multiple', paymentData)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createPaymentCopay = (paymentData) => {
  return api.post('/payments/copay', paymentData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const fetchPayment = (id) => {
  return api.get(`/payments/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => { throw error.data; });
};

export const updatePayment = (paymentData) => {
  return api.put(`/payments/${paymentData.id}`, paymentData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const deletePayment = (id) => {
  return api.delete(`/payments/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeletePayments = (IDs) => {
  return api.delete('/payments/delete', { params: { payments_id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchPaymentFiles = (id) => {
  return api.get(`/payments/payment_files/${id}`)
    .then(({ data: { data, pagination } }) => ({ data, pagination }))
    .catch((error) => { throw error.data; });
};

export const assignPayment = (paymentData) => {
  return api.post(`/payments/${paymentData.id}/assign`, paymentData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const refundPayment = (paymentData) => {
  return api.post(`/payments/${paymentData.id}/refund`, paymentData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const cancelRefundPayment = (paymentData) => {
  return api.post(`/payments/${paymentData.id}/cancel-refund`, paymentData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};
