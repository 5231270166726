import { useContext, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Cards } from '../../../../../../components/Cards';
import { Loader } from '../../../../../../components/Loader';
import { PageBody } from '../../../../../../components/Page';
import { TablePagination } from '../../../../../../components/TablePagination';
import { ContactsContext } from '../../ContactsProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    contacts,
    isFetched,
    meta,
    resetContacts
  } = useContext(ContactsContext);

  useEffect(() => {
    resetContacts();
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => !contacts.length ? (
          <Box p={3}>
            <Typography align="center">No contacts found</Typography>
          </Box>
        ) : (
          <PageBody disablePaddings>
            <Cards size={300} list={contacts} component={Card} />
            <TablePagination borderTop pagination={meta} onChange={resetContacts} />
          </PageBody>
        )}
      />
    </Box>
  );
};
