import { api } from '../api';

export const fetchCompanyTypes = (config = {}) => {
  return api.get('/companies/types', config).then(({ data }) => data);
};

export const fetchCompanyType = (id) => {
  return api.get(`/companies/types/${id}`).then(({ data }) => data);
};

export const createCompanyType = (data) => {
  return api.post('/companies/types', data).then(({ data: { data } }) => data);
};

export const updateCompanyType = ({ id, ...data }) => {
  return api.put(`/companies/types/${id}`, data).then(({ data: { data } }) => data);
};

export const deleteCompanyType = (id) => {
  return api.delete(`/companies/types/${id}`);
};
