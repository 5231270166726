export const styles = ({
  spacing,
  palette: { grey },
  shape,
  transitions
}) => ({
  uploadTrigger: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 90,
    padding: spacing(2),
    border: '1px dashed',
    cursor: 'pointer',
    color: grey[500],
    borderRadius: shape.borderRadius,
    transition: transitions.create(),

    '&:hover': {
      extend: 'uploadTrigger_hover'
    }
  },

  uploadTrigger_hover: {
    background: grey[100]
  },

  uploadIcon: {
    marginRight: spacing()
  }
});
