import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { Checkbox, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { CodesContext } from '../../../CodesContext';
import { columnsWidths, minRowHeight } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ code, baseUrl }) => {
  const classes = useStyles();
  const {
    deleteCode,
    selectedCodesIDs,
    toggleItemSelection,
    massActiveFinancialClass,
    massInactiveFinancialClass
  } = useContext(CodesContext);
  const isSelected = selectedCodesIDs.indexOf(code.id) !== -1;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const handleDeleteCode = () => deleteCode(code);

  const toggleActiveItem = () => {
    code.deactivated_at ? massActiveFinancialClass([ code.id ]) : massInactiveFinancialClass([ code.id ]);
  };

  const handleItemSelection = (event) => {
    event.preventDefault();

    toggleItemSelection(code.id);
  };

  return (
    <ListRow minHeight={minRowHeight} selected={isSelected}>
      <Checkbox className={classes.checkbox} checked={isSelected} onClick={handleItemSelection} />

      <ListRowCell grow={isMobile} noWrap column={isMobile} flexBasis={columnsWidths.code}>
        <Typography variant="h5" noWrap title={code.code}>
          {code.code}
        </Typography>

        {isMobile && (
          <Typography noWrap title={get(code, 'claim_filing_indicator.name', '-')}>
            {get(code, 'claim_filing_indicator.name', '-')}
          </Typography>
        )}
      </ListRowCell>

      {!isMobile && (
        <>
          <ListRowCell grow flexBasis={columnsWidths.description}>
            <Typography noWrap title={code.description}>
              {code.description}
            </Typography>
          </ListRowCell>

          <ListRowCell grow flexBasis={columnsWidths.indicator}>
            <Typography noWrap title={get(code, 'claim_filing_indicator.name', '-')}>
              {get(code, 'claim_filing_indicator.name', '-')}
            </Typography>
          </ListRowCell>
        </>
      )}

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
        {code.deactivated_at && (
          <Tooltip title="Edit code">
            <IconButton
              color="primary"
              component={Link}
              to={`${baseUrl}/financial-class/${code.id}/edit`}
              variant="contained"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={code.deactivated_at ? 'Activate code' : 'Deactivate code'}>
          <IconButton
            variant="contained"
            color={code.deactivated_at ? 'warning' : 'success'}
            onClick={toggleActiveItem}
          >
            {code.deactivated_at ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete code">
          <IconButton variant="contained" color="error" onClick={handleDeleteCode}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListRowCell>
    </ListRow>
  );
};
