import { api } from '../../index';

export const fetchTemplates = (config = {}) => {
  return api.get('/html-document-templates', config).then(({ data }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const getTemplate = (id) => {
  return api.get(`/html-document-templates/${id}`).then(({ data }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const createTemplate = (data, options) => {
  return api.post('/html-document-templates', data, options).then(({ data: { data } }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const updateTemplate = ({ id, ...data }, options) => {
  return api.put(`/html-document-templates/${id}`, data, options).then(({ data: { data } }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const deleteTemplate = (id) => {
  return api.delete(`/html-document-templates/${id}`).then((response) => {
    return response;
  }).catch((data) => {
    throw data;
  });
};
