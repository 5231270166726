import { makeStyles, Box, Typography } from '@material-ui/core';
import { mdiClipboardPulseOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { SvgIcon } from '../../../../../../../components/SvgIcon';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '& + &': {
      marginTop: spacing(2)
    }
  }
}));

export const DiagnosisInfo = ({ claim: { icd_codes } }) => {
  const classes = useStyles();

  return (
    <ContentCard
      disableScrollbars
      variant="outlined"
      color="info"
      title="Diagnosis"
      leftActions={[
        <Box mr={1}>
          <SvgIcon color="info"><Icon path={mdiClipboardPulseOutline} /></SvgIcon>
        </Box>
      ]}
    >
      {!icd_codes?.length ? (
        <Typography>Diagnoses is not defined</Typography>
      ) : icd_codes?.map((diagnose) => (
        <div key={diagnose.id} className={classes.root}>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">{diagnose.code}</Typography>
          </Box>

          <Typography variant="h5">{diagnose.description}</Typography>
        </div>
      ))}
    </ContentCard>
  );
};
