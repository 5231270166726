import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { ColorPreview } from '../../../../../../../components/ColorPreview';
import { IconButton } from '../../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../components/Loader';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { UserLink } from '../../../../../../../components/UserLink';
import { columnsWidths } from '../SavedFiltersList';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1}/> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.patients}>
        <Box display="flex" flexDirection="column" width="100%" alignItems="center">
          {!params.patients?.length ? '-' : params?.patients?.map((user) => (
            <Box display="flex" width="100%" alignItems="center" key={user?.id}>
              <UserLink
                noWrap
                size="sm"
                key={user.id}
                user={user}
              />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.providers}>
        <Box display="flex" flexDirection="column" width="100%" alignItems="center">
          {!params.providers?.length ? '-' : params?.providers?.map((user) => (
            <Box display="flex" width="100%" alignItems="center" key={user?.id}>
              <UserLink
                noWrap
                size="sm"
                key={user.id}
                user={user}
              />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell column flexBasis={columnsWidths.apptBooks}>
        {!params?.appointment_books?.length ? '-' : params?.appointment_books?.map((appointmentBook) => (
          <Tooltip key={appointmentBook.id} title={appointmentBook?.appointment_book_name}>
            <Typography noWrap>
              {appointmentBook?.appointment_book_name}
            </Typography>
          </Tooltip>
        ))}
      </ListRowCell>

      <ListRowCell column flexBasis={columnsWidths.apptTypes}>
        {!params?.appointment_types?.length ? '-' : params?.appointment_types.map((appointmentType) => (
          <Box key={appointmentType.id} display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mr={0.25}>
              <ColorPreview color={appointmentType.color} />
            </Box>

            <Typography noWrap>
              {appointmentType.code}
            </Typography>
          </Box>
        ))}
      </ListRowCell>

      <ListRowCell column flexBasis={columnsWidths.visitReasons}>
        {!params?.visit_reasons?.length ? '-' : params?.visit_reasons?.map((reason) => (
          <Typography noWrap key={reason.id}>
            {reason.code + (reason.description ? ` - ${reason.description}` : '')}
          </Typography>
        ))}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.appointmentDayFrom}>
        {params?.appointment_day_from ? moment.unix(params.appointment_day_from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.appointmentDayTo}>
        {params?.appointment_day_to ? moment.unix(params.appointment_day_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon/>
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
