import { SvgIcon } from '@material-ui/core';
import NextWeekOutlinedIcon from '@material-ui/icons/NextWeekOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { mdiFileAlertOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { AccountsProvider } from './AccountsContext';
import { EmailsClient } from './EmailsClient';
import { SavedEmails } from './SavedEmails';
import { Rules } from './Rules';

export const routes = [
  {
    path: '/emails/client',
    icon: <EmailOutlinedIcon />,
    label: 'Email client',
    component: EmailsClient
  },
  {
    path: '/emails/saved',
    icon: <NextWeekOutlinedIcon />,
    label: 'Saved emails',
    component: () => (
      <AccountsProvider disableCounters>
        <SavedEmails />
      </AccountsProvider>
    )
  },
  {
    path: '/emails/rules',
    icon: <SvgIcon><Icon path={mdiFileAlertOutline} /></SvgIcon>,
    label: 'Rules',
    component: () => (
      <AccountsProvider disableCounters>
        <Rules />
      </AccountsProvider>
    )
  }
];
