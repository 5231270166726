import { palette } from '../../../../../themes/palette';

export const statusesMap = {
  'all_invoices': {
    name: 'All invoices',
    color: palette().slategray,
    filterValue: null
  },
  'overdue': {
    name: 'Overdue',
    color: palette().pink,
    filterValue: 'overdue'
  },
  'paid': {
    name: 'Paid',
    color: palette().lightgreen,
    filterValue: 'paid'
  },
  'unpaid': {
    name: 'Unpaid',
    color: palette().darkgreen,
    filterValue: 'unpaid'
  },
  'balance_due': {
    name: 'Balance Due',
    color: palette().lightorange,
    filterValue: 'balance_due'
  },
  'balance': {
    name: 'Balance',
    color: palette().secondary.light,
    filterValue: null
  }
};

export const statusesList = Object.keys(statusesMap);
