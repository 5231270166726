import { Transition } from '../components/Modal/Transition';

export const props = {
  MuiButtonBase: {
    disableRipple: true
  },

  MuiAppBar: {
    position: 'static',
    color: 'secondary',
    elevation: 0
  },

  MuiToolbar: {
    variant: 'dense'
  },

  MuiPaper: {
    square: true
  },

  MuiFab: {
    size: 'medium'
  },

  MuiRadio: {
    color: 'primary'
  },

  MuiCheckbox: {
    color: 'primary'
  },

  MuiCircularProgress: {
    color: 'inherit'
  },

  MuiDialog: {
    TransitionComponent: Transition
  },

  MuiTypography: {
    variantMapping: {
      subtitle1: 'b',
      subtitle2: 'b'
    }
  },

  MuiTabs: {
    indicatorColor: 'primary',
    textColor: 'primary'
  }
};
