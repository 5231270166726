import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreVert from '@material-ui/icons/MoreVert';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import * as chatsApi from '../../../api/chat';
import { Echo } from '../../../utils/echo';
import { ChangeChatOwnerModal } from '../ChangeChatOwnerModal';
import { Popper } from '../../Popper';
import { useModal } from '../../ModalsProvider';
import { ConfirmationModal } from '../../ConfirmationModal';
import { MessengerContext } from '../MessengerProvider';
import { UpdateChatModal } from '../UpdateChatModal';
import { actionTypesMap, ChatThreadContext } from '../ChatThreadContext';

export const ChatDropDownMenu = ({ thread }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const {
    currentAction,
    setCurrentAction,
    updateCurrentThread,
    resetSelectedMessages
  } = useContext(ChatThreadContext);
  const currentUser = useSelector(({ profile }) => profile.user);
  const isOwner = currentUser.id === thread?.user?.id;
  const history = useHistory();
  const { setCurrentThreadId } = useContext(MessengerContext);

  const deleteChat = () => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Archive chat',
        content: 'Are you sure you want to archive this chat?'
      },

      onModalResolved: () => {
        chatsApi.deleteThread(thread.id).then(() => {
          enqueueSnackbar('Chat archived', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Chat not archived', { variant: 'error' });
        });
      }
    });
  };

  const unarchiveChat = () => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Unarchive chat',
        content: 'Are you sure you want to unarchive this chat?'
      },

      onModalResolved: () => {
        chatsApi.unarchiveThread(thread.id).then(() => {
          enqueueSnackbar('Chat unarchived', { variant: 'success' });
          setCurrentThreadId(null);
          history.push('/messenger');
        }).catch(() => {
          enqueueSnackbar('Chat not unarchived', { variant: 'error' });
        });
      }
    });
  };

  const leaveChat = () => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Leave chat',
        content: 'Are you sure you want to leave this chat ? '
      },

      onModalResolved: () => {
        chatsApi.leaveThread(thread.id).then(() => {
          enqueueSnackbar('You left chat', { variant: 'success' });
          setCurrentThreadId(null);
          history.push('/messenger');
        }).catch(() => {
          enqueueSnackbar('You haven`t left chat', { variant: 'error' });
        });
      }
    });
  };

  const onMessageSelect = () => {
    if (currentAction === actionTypesMap.select) {
      setCurrentAction('');
    } else {
      setCurrentAction(actionTypesMap.select);
      resetSelectedMessages();
    }
  };

  const openUpdateChatModal = () => {
    openModal(UpdateChatModal, {
      payload: { thread },
      onModalResolved: (data) => {
        updateCurrentThread(data);
      }
    });
  };

  const openChangeChatOwnerModal = () => {
    openModal(ChangeChatOwnerModal, {
      payload: { thread }
    });
  };

  const toggleFavorite = () => {
    chatsApi.toggleChatAsFavorite(thread?.id);
  };

  useEffect(() => {
    const usersChanel = Echo.private(`users.${currentUser.id}`);

    const handleThreadArchived = () => {
      setCurrentThreadId(null);
      history.push('/messenger');
    };

    usersChanel.listen('.thread.archived', handleThreadArchived);

    return () => {
      usersChanel.stopListening('.thread.archived', handleThreadArchived);
    };
  }, []);

  return (
    <Popper anchorRenderer={
      ({ anchorRef, handleToggle }) => (
        <IconButton
          color="inherit"
          edge="end"
          buttonRef={anchorRef}
          onClick={handleToggle}
        >
          <MoreVert />
        </IconButton>
      )}
    >
      {({ handleClose }) => (
        <List
          gutters={2}
          onClick={handleClose}
        >
          <ListItem
            button
            onClick={onMessageSelect}
          >
            <ListItemIcon>
              {currentAction === actionTypesMap.select
                ? <RemoveCircleOutlineIcon />
                : <CheckCircleOutlineIcon />
              }
            </ListItemIcon>

            <ListItemText
              primary={currentAction === actionTypesMap.select
                ? 'Deselect messages'
                : 'Select messages'
              }
            />
          </ListItem>

          <Divider/>

          {isOwner &&
            <ListItem
              button
              onClick={openUpdateChatModal}
            >
              <ListItemIcon>
                <CreateOutlinedIcon />
              </ListItemIcon>

              <ListItemText primary="Edit chat" />
            </ListItem>
          }

          {isOwner &&
            <ListItem
              button
              onClick={openChangeChatOwnerModal}
            >
              <ListItemIcon>
                <PersonOutlinedIcon />
              </ListItemIcon>

              <ListItemText primary="Change chat owner" />
            </ListItem>
          }

          <ListItem
            button
            onClick={toggleFavorite}
          >
            <ListItemIcon>
              <StarBorderIcon/>
            </ListItemIcon>

            <ListItemText primary={thread?.has_favorites ? 'Remove from favorites' : 'Add to favorites'} />
          </ListItem>

          {isOwner &&
            <ListItem
              button
              onClick={thread?.is_archive ? unarchiveChat : deleteChat}
            >
              <ListItemIcon>
                <DeleteOutlineIcon/>
              </ListItemIcon>

              <ListItemText primary={thread?.is_archive ? 'Unarchive chat' : 'Archive chat'} />
            </ListItem>
          }

          {!isOwner &&
            <ListItem
              button
              onClick={leaveChat}
            >
              <ListItemIcon>
                <ExitToAppIcon/>
              </ListItemIcon>

              <ListItemText primary="Leave chat"/>
            </ListItem>
          }
        </List>
      )}
    </Popper>
  );
};
