import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [ types.FETCH_CODES_REQUEST ]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_CODES_SUCCESS]: (
    { filter, codes, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      codes: data
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.RESET_CODES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_CUSTOM_CODE]: (state, payload) => {
    const { pagination, filter, codes, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((codes.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, total, page },
      codes: [ ...codes, payload ]
    };
  },

  [ types.UPDATE_CODE_IN_LIST ]: (state, payload) => {
    return {
      ...state,
      codes: state.codes.map((item) => {
        return item.id === payload.id ? { ...item, ...payload } : item;
      })
    };
  },

  [types.DELETE_CODE_SUCCESS]: ({ codes, pagination, filter, ...state }, { id }) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter,
      codes: codes.filter((item) => item.id !== id)
    };
  }
});
