import { useContext } from 'react';
import { Box } from '@material-ui/core';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import { CommentsContextProvider, Comments as CommentsContent } from '../../../../../../../../../components/Comments';
import { WidgetsActionButtons } from '../../../../../../../../../components/WidgetsActionButtons';
import { ContentCard } from '../../../../../../../../../components/ContentCard';
import { UserContext } from '../../../../../UserProvider';

export const Comments = ({
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { user } = useContext(UserContext);

  const initialStateOfComments = {
    filter: {
      parent_type: 'user_profile',
      parent_id: user.profile_id
    }
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      title="Comments"
      leftActions={[
        <Box mr={1}>
          <CommentOutlinedIcon fontSize="small" />
        </Box>
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <CommentsContextProvider initialState={initialStateOfComments}>
        <CommentsContent
          maxHeight={height}
          parentType="user_profile"
          parentID={user.profile_id}
        />
      </CommentsContextProvider>
    </ContentCard>
  );
};
