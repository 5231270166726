import { useEffect, useState } from 'react';
import { Typography, Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { getUserFullName } from '../../../../../../../helpers/users';
import { formTypesMap } from '../../../../../../../components/medical/forms';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { NestedList } from '../../../../../../../components/NestedList';
import { UserLink } from '../../../../../../../components/UserLink';
import { Contact } from '../../../../../../../components/Contact';
import { UserSSN } from '../../../../../../../components/users';
import { Date } from '../../../../../../../components/Date';
import { EmergencyFormContact } from '../EmergencyFormContact';
import { Measurements } from '../Measurements';
import { PhysicianInfo } from '../PhysicianInfo';

export const GeneralInfo = ({ isOpenSwitches, form }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const isWCType = form?.form_type === formTypesMap.compensation;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const user = {
    first_name: form?.patient?.first_name || form.first_name,
    last_name: form?.patient?.last_name || form.last_name
  };

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="General info"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Grid container spacing={4} alignItems="center" component={Box} p={isSmallScreen ? 1 : 3}>
        <Grid container item sm={6} xs={12} spacing={1}>
          <Grid item xs={5}>
            <Typography color="textSecondary">Name</Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography variant="h5">{getUserFullName(user)}</Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">DOB</Typography>
          </Grid>

          <Grid item xs={7}>
            <Date
              variant="h5"
              date={form?.birthday || form?.patient?.birthday}
            />
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">SSN</Typography>
          </Grid>

          <Grid item xs={7}>
            <UserSSN isShowable={false} user={form} />
          </Grid>
        </Grid>

        <Grid container item sm={6} xs={12} spacing={1}>
          <Grid item xs={5}>
            <Typography color="textSecondary">Phone</Typography>
          </Grid>

          <Grid item xs={7}>
            <Contact
              noWrap
              needIcon
              variant="h5"
              title={form.phone || form?.patient?.phone_number || form?.phone_number}
              type="tel"
              contact={form.phone || form?.patient?.phone_number || form?.phone_number}
            />
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">Mobile Phone</Typography>
          </Grid>

          <Grid item xs={7}>
            <Contact
              noWrap
              needIcon
              variant="h5"
              title={form.mobile_phone}
              type="tel"
              contact={form.mobile_phone}
            />
          </Grid>

          <Grid item xs={5}>
            <Typography color="textSecondary">Address</Typography>
          </Grid>

          <Grid item xs={7}>
            <AddressLink variant="h5" address={form.full_address || form.address}>
              {form.full_address || form.address}
            </AddressLink>
          </Grid>
        </Grid>

        <Grid container item sm={6} xs={12} spacing={1}>
          <PhysicianInfo form={form} />
        </Grid>

        <Grid container item sm={6} xs={12} spacing={1}>
          <Grid item xs={5}>
            <Typography color="textSecondary">
              {isWCType ? 'Law office' : 'Primary Insurance'}
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {form?.primary_insurance?.insurance_company ? (
              <CompanyLink
                variant="h5"
                company={form?.primary_insurance?.insurance_company}
              />
            ) : (
              form?.primary_insurance_name ? (
                <Typography variant="h5">
                  {form?.primary_insurance_name}
                </Typography>
              ) : (
                <Typography color="textSecondary">
                  No info.
                </Typography>
              )
            )}
          </Grid>

          {!isWCType && (
            <>
              <Grid item xs={5}>
                <Typography color="textSecondary">Insurance ID #</Typography>
              </Grid>

              <Grid item xs={7}>
                <Typography variant="h5">
                  {form?.primary_insurance?.insuranceId || form?.primary_insurance_id_number || '-'}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography color="textSecondary">Group ID #</Typography>
              </Grid>

              <Grid item xs={7}>
                <Typography variant="h5">
                  {form?.primary_insurance?.groupId || form?.group_id_number || '-'}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>

        <Grid container item sm={6} xs={12} spacing={1} alignItems="center">
          {isWCType ? (
            <>
              <Grid item xs={5}>
                <Typography color="textSecondary">Attorney Name</Typography>
              </Grid>

              <Grid item xs={7}>
                {form.attorney?.id ? (
                  <UserLink
                    size="md"
                    user={form.attorney}
                    variant="h5"
                  />
                ) : (
                  <Typography variant="h5">
                    {form?.attorney_first_name} {form?.attorney_last_name}
                  </Typography>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={5}>
                <Typography color="textSecondary">Primary Insurance Holder</Typography>
              </Grid>

              <Grid item xs={7}>
                {form?.primary_insurance?.subscriber_info?.first_name ? (
                  <UserLink
                    disableLink
                    disablePreview
                    size="md"
                    variant="h5"
                    underline="none"
                    user={form?.primary_insurance?.subscriber_info}
                  />
                ) : (
                  form?.primary_insurance_holder_first_name || form?.primary_insurance_holder_last_name ? (
                    <Typography variant="h5">
                      {getUserFullName({
                        first_name: form?.primary_insurance_holder_first_name,
                        last_name: form?.primary_insurance_holder_last_name
                      })}
                    </Typography>
                  ) : (
                    <Typography color="textSecondary">
                      No info.
                    </Typography>
                  )
                )}
              </Grid>

              <Grid item xs={5}>
                <Typography color="textSecondary">Relationship to Patient</Typography>
              </Grid>

              <Grid item xs={7}>
                <Typography variant="h5">
                  {form.relationship_to_patient || '-'}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>

        <EmergencyFormContact contacts={form?.emergency_contact || []} />

        {!!form.description && (
          <Grid item xs={12}>
            <Typography variant="h5">
              What is the reason for your visit today?
            </Typography>

            <Typography color="textSecondary">{form.description}</Typography>
          </Grid>
        )}

        <Measurements birthday={form.birthday} measurements={form.measurements} />

        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary">
            Hearing Date
          </Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Date variant="h5" date={form?.hearing_date} />
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary">
            Vision Rx Date
          </Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Date variant="h5" date={form?.vision_date} />
        </Grid>
      </Grid>
    </NestedList>
  );
};
