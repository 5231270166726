import { useContext, useRef } from 'react';
import { Typography, Box, List as MuiList } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { MessagesContext } from '../../MessagesContext';
import { EmailsListItem } from './EmailsListItem';

export const EmailsList = ({ addEmailView = false }) => {
  const { isFetching, isFetched, meta, activeAccountID, messages, loadNextPage } = useContext(MessagesContext);
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%', flexGrow: 1 }}>
      {!!(meta.folder_id && activeAccountID) &&
        <Loader loading={!isFetched} p={2} render={
          () => !messages.length ? (
            <Box p={2}>
              <Typography align="center">There is no messages</Typography>
            </Box>
          ) : (
            <MuiList disablePadding component="nav">
              <InfiniteListLoader
                items={messages}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                RowComponent={EmailsListItem}
                RowProps={{ addEmailView }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      }
    </CustomScrollbars>
  );
};
