export const styles = ({
  palette: { grey, ...palette },
  shape: { borderRadius },
  typography: { pxToRem },
  transitions
}) => ({
  root: {
    position: 'relative',
    flexShrink: 0,
    width: 46,
    height: 46,
    overflow: 'hidden',
    textAlign: 'center',
    border: ({ hasPreviewImage }) => hasPreviewImage ? `1px solid ${grey[100]}` : null,

    '&:hover': {
      borderRadius
    },

    '&:hover $previewBackdrop': {
      opacity: 1
    }
  },

  badge: {
    borderRadius: 4
  },

  previewBackdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.primary.contrastText,
    background: palette.primary.main,
    opacity: 0,
    cursor: 'pointer',
    transition: transitions.create()
  },

  previewImage: {
    width: '100%'
  },

  previewIcon: {
    fontSize: pxToRem(46)
  },

  indicator: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: pxToRem(20),
    height: pxToRem(20),
    borderRadius
  },

  indicator_view: {
    top: 0,
    right: 0,
    background: palette.primary.main
  },

  indicator_ocr: {
    bottom: 0,
    right: 0,
    background: grey[500],

    '& $indicator__icon': {
      fontSize: pxToRem(18)
    }
  },

  indicator_ocr_active: {
    background: palette.primary.main
  },

  indicator__icon: {
    fontSize: pxToRem(14),
    color: palette.primary.contrastText
  }
});
