import moment from 'moment';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { makeStyles, Box, Grid, Typography, InputAdornment } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { KeyboardDateTimePicker, NumberMaskField, TimeZoneSelect } from '../../../FormField';
import { RecurrenceSelect } from '../../../RecurrenceSelect';
import { arrivalTypesMap } from '../../arrivalTypes';
import { useBookWorkSchedule } from '../../useBookWorkSchedule';
import { CalendarContainer } from './Calendar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AppointmentTimeSection = ({ disableApptBook }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const workSchedule = useBookWorkSchedule({
    workSchedules: values.appointment_book?.work_schedules,
    date: values.appointment_at
  });
  const timeSectionIsDisabled = !disableApptBook && (!values.appointment_book_id || !values.appointment_type_id);

  return (
    <>
      <Box mt={6} mb={2} color="info.main">
        <Typography variant="h3" color="inherit">
          Appointment time
        </Typography>
      </Box>

      <div className={classes.disabledTimeSection}>
        <div className={cn({ [classes.disabledTimeSection__content]: timeSectionIsDisabled })}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <KeyboardDateTimePicker
                required
                disablePast={values?.arrival_status?.arrival_type === arrivalTypesMap.waitingList}
                name="appointment_at"
                label="Appointment date"
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <NumberMaskField
                zeroMinWidth
                name="_hours"
                label="Hours"
                placeholder="Enter hours..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">h</InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid xs={6} item sm={3}>
              <NumberMaskField
                required
                zeroMinWidth
                name="_minutes"
                label="Minutes"
                placeholder="Enter minutes..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">min</InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item lg={6}>
              <RecurrenceSelect
                name="recurring_rule"
                label="Recurrence / Follow Up"
              />
            </Grid>

            <Grid item lg={6}>
              <TimeZoneSelect
                name="timezone"
                disabled={!!values.id}
                title={values.id ? 'Time zone setting available only on appt. creation' : null}
              />
            </Grid>
          </Grid>

          <div className={classes.disabledTimeSection}>
            <div className={cn({ [classes.disabledTimeSection__content]: workSchedule?.day_off })}>
              <Box display="flex" alignItems="center" justifyContent="space-between" mt={5} mb={3}>
                <Typography variant="h3">
                  Calendar
                </Typography>

                <Typography align="center" variant="h5" color="secondary">
                  {values.appointment_at
                    ? moment.unix(values.appointment_at).format('MMM DD, YYYY')
                    : moment().format('MMM DD, YYYY')
                  }
                </Typography>
              </Box>

              <Box height={400}>
                <CalendarContainer
                  date={values.appointment_at || moment().unix()}
                  workSchedule={workSchedule}
                />
              </Box>
            </div>

            {!!workSchedule?.day_off &&
              <div className={classes.disabledTimeSection__vail}>
                <Grid container spacing={2} justify="center">
                  <Grid item>
                    <InfoOutlinedIcon color="inherit" />
                  </Grid>

                  <Grid item xs={10} sm={8}>
                    <Box component="h3" m={0} fontSize="h3.fontSize" fontWeight="fontWeightRegular" color="inherit">
                      The selected day is not available for this appt. book
                    </Box>
                  </Grid>
                </Grid>
              </div>
            }
          </div>
        </div>

        {timeSectionIsDisabled &&
          <div className={classes.disabledTimeSection__vail}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <InfoOutlinedIcon color="inherit" />
              </Grid>

              <Grid item xs={10} sm={8}>
                <Box component="h3" m={0} fontSize="h3.fontSize" fontWeight="fontWeightRegular" color="inherit">
                  Choose a appt. book and appt. type to indicate the time of visit
                </Box>
              </Grid>
            </Grid>
          </div>
        }
      </div>
    </>
  );
};
