import { EmptyFormAppointment } from '../../../../components/medical/forms';
import { AccidentInfo } from '../../../../components/medical/forms/personalInjury';
import { MedicalHistory } from '../PrivatePacket/MedicalHistory';
import { PainContent } from '../PrivatePacket/PainContent';
// import { ApptFormContent } from '../ApptFormContent';
import { PreviewContent } from '../PreviewContent';
import { CurrentSymptoms } from './CurrentSymptoms';
import { GeneralInfo } from './GeneralInfo';

export const formStepComponents = [
  GeneralInfo,
  EmptyFormAppointment,
  // ApptFormContent,
  CurrentSymptoms,
  AccidentInfo,
  PainContent,
  MedicalHistory,
  PreviewContent
];
