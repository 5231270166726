import { makeStyles, Typography, Grid, Box } from '@material-ui/core';
// eslint-disable-next-line max-len
import { getStatus } from '../../../../../../../components/medical/forms/CreateMeasurementsModal/statusMap';
import { Label } from '../../../../../TasksPage/MainWindow/Label';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ history }) => {
  const classes = useStyles();
  const status = getStatus(history.systolic_blood_pressure || 0);

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={6}>
        <Typography variant="subtitle2">
          Body temperature {history.temperature || '0'} °F
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="subtitle2">
          Heart rate {history.heart_rate || '0'} bmp
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h6" color="secondary">
          {history.weight || '0'}
        </Typography>

        <Typography variant="caption" color="textSecondary">
          {`Weight (${history.weight_unit || 'lbs'})`}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h6">
          {history.bmi || '0'}
        </Typography>

        <Typography variant="caption" color="textSecondary">
          Body Mass Index (BMI)
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <Typography variant="subtitle2">
          Pressure sys/dia {`${history.systolic_blood_pressure || 0}/${history.diastolic_blood_pressure || 0}`} mmHg
        </Typography>
      </Grid>

      <Grid item xs={5}>
        {history.systolic_blood_pressure > 0 &&
          <Box display="flex" justifyContent="flex-end">
            <Label name={status.label} color={status.value} />
          </Box>
        }
      </Grid>
    </Grid>
  );
};
