import { createContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../../../api';
import * as historiesApi from '../../../../../api/profile';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { usePrevious } from '../../../../../helpers/hooks';
import { setMedicalProfileLastGlobalAction } from '../../../../../store/globalActions';
import * as medicalProfileTypes from '../../../ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider';
import * as types from './types';
import { initialState } from './initialState';
import { reducer } from './reducer';

export const HistoriesContext = createContext();

export const HistoriesProvider = ({ children, filter: filterProp }) => {
  const [ state, dispatch ] = useReducer(reducer, {
    ...initialState,
    filter: merge({}, initialState.filter, filterProp)
  });
  const historyLastGlobalAction = useSelector(({ globalActions }) => globalActions.historyLastGlobalAction);
  const { histories, selectedIDs, pagination, filter } = state;
  const dispatchRedux = useDispatch();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchHistories = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.HISTORIES_FETCH_REQUEST });

    historiesApi.fetchHistories({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.HISTORIES_FETCH_SUCCESS, payload: data });
    });
  };

  const deleteHistory = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        historiesApi.deleteHistory(id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchHistories({ page: filter.page });
          } else if (histories.length - 1 === 0 && filter.page !== 1) {
            fetchHistories({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_HISTORY_SUCCESS, payload: id });
          }

          dispatchRedux(setMedicalProfileLastGlobalAction({ type: medicalProfileTypes.FETCH_PROFILE_REQUEST }));
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Item is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const massDeleteItems = (IDs) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        historiesApi.massDeleteWeightHistories(IDs).then(() => {
          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            fetchHistories({ page: filter.page });
          } else if (histories.length - selectedIDs.length === 0 && filter.page !== 1) {
            fetchHistories({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.MASS_DELETE_HISTORIES, payload: IDs });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Items are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const allItemsIsSelected = () => {
    return histories.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_HISTORY_SELECTION, payload: id });
  };

  const toggleAllItemsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_HISTORIES_SELECTION });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetHistories = (newFilter = {}) => {
    dispatch({ type: types.RESET_HISTORIES, payload: newFilter });

    fetchHistories({ page: 1, ...newFilter });
  };

  const providerValue = {
    ...state,
    histories,
    pagination,
    selectedIDs,
    meta: {
      ...pagination,
      ...filter
    },

    resetHistories,
    applyFilter,
    deleteHistory,
    massDeleteItems,
    allItemsIsSelected,
    toggleItemSelection,
    toggleAllItemsSelection
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, filterProp ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetHistories();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    if (historyLastGlobalAction?.type === types.HISTORIES_FETCH_REQUEST) {
      fetchHistories();
    }
  }, [ historyLastGlobalAction ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <HistoriesContext.Provider value={providerValue}>
      {children}
    </HistoriesContext.Provider>
  );
};
