export const styles = ({ palette, spacing, shape: { borderRadius }, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    margin: spacing(1, 0),

    [breakpoints.down('sm')]: {
      margin: 0
    }
  },

  body: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: spacing(1, 2),
    padding: spacing(0.5),
    border: `1px solid ${palette.grey[200]}`,
    borderRadius: borderRadius
  },

  editMessageContent: {
    whiteSpace: 'pre-wrap'
  },

  textFieldWrapper: {
    display: 'flex',
    flexGrow: 1
  }
});
