import { api } from '../../api';

export const fetchTemplates = (config) => {
  return api.get('/diagnosis-templates', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchTemplate = (id, config) => {
  return api.get(`/diagnosis-templates/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createTemplate = (data) => {
  return api.post('/diagnosis-templates', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateTemplate = ({ id, ...data }) => {
  return api.put(`/diagnosis-templates/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteTemplate = (id) => {
  return api.delete(`/diagnosis-templates/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteTemplates = (IDs) => {
  return api.delete('/diagnosis-templates', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
