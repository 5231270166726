import { Link } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';
import { makeStyles, Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { InsuranceClaimsTooltipCounter } from '../../../../../../components/claims/InsuranceClaimsTooltipCounter';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { Tooltip } from '../../../../../../components/Tooltip';
import { IconButton } from '../../../../../../components/IconButton';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CaseCard = ({ caseItem, isCaseInfoOpen, handleToggleCaseInfo = () => {} }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <div className={classes.header}>
          <Tooltip title={caseItem.file_number}>
            <Typography noWrap variant="h5" color="inherit">
              Case#
              <span className={classes.header__subTitle}>{caseItem.file_number}</span>
            </Typography>
          </Tooltip>

          <Box display="flex" alignItems="center">
            {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.doctor, rolesMap.medic) &&
              <Box px={1}>
                <Tooltip title="Edit case">
                  <IconButton
                    className={classes.header__icon}
                    component={Link}
                    to={`/cases/edit/${caseItem.id}`}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }

            {isMobile &&
              <Box px={1}>
                <Tooltip title="Toggle case info">
                  <IconButton
                    className={classes.header__icon}
                    onClick={handleToggleCaseInfo}
                  >
                    {isCaseInfoOpen ? <ArrowUp /> : <ArrowDown />}
                  </IconButton>
                </Tooltip>
              </Box>
            }
          </Box>
        </div>

        <Typography
          variant="h6"
          color="primary"
          className={classes.header__name}
        >
          {caseItem.name}
        </Typography>

        <div className={classes.claimContent}>
          <Typography variant="h5" color="inherit">
            Claims#
          </Typography>

          <InsuranceClaimsTooltipCounter caseItem={caseItem} />
        </div>

        <div className={classes.content}>
          <div className={classes.contentInfo}>
            <Typography className={classes.contentInfo__title}>
              Case Open
            </Typography>

            <Typography className={classes.contentInfo__date}>
              {moment.unix(caseItem.opened_at).format('L') || 'Not open'}
            </Typography>

            <Typography className={classes.contentInfo__title}>
              Case status
            </Typography>

            {caseItem.case_status ? (
              <Typography className={cn(classes.contentLabel, classes.contentLabel_status)}>
                {caseItem.case_status.name}
              </Typography>
            ) : (
              <Typography>-</Typography>
            )}
          </div>

          <div className={cn(classes.contentInfo, classes.contentInfo_type)}>
            <Typography className={classes.contentInfo__title}>
              Date of status
            </Typography>

            {caseItem.last_status_changed_at ? (
              <Typography className={classes.contentInfo__date}>
                {moment.unix(caseItem.last_status_changed_at).format('L')}
              </Typography>
            ) : (
              <Typography>-</Typography>
            )}
          </div>

          <div className={cn(classes.contentInfo, classes.contentInfo_type)}>
            <Typography className={classes.contentInfo__title}>
              Case type
            </Typography>

            <div className={classes.contentType}>
              {caseItem.case_type?.length ? caseItem.case_type.map((item, i) => (
                <Typography key={i} title={item.name} className={classes.contentType__title}>
                  {item.name}

                  {caseItem.case_type.length > 1 && caseItem.case_type[caseItem.case_type.length - 1] !== item && ', '}
                </Typography>
              )) : (
                <Typography>-</Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
