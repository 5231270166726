import { Box, Checkbox, Grid } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../helpers/dom';
import { IconButton } from '../../IconButton';
import { Popper } from '../../Popper';

export const CardHeader = ({
  isSelected,
  childrenWidth = 9,
  icon = null,
  size = 'medium',
  disableCheckBox = false,
  disablePopper = false,
  disableCardMenu = false,
  alignItems = 'center',
  children,
  onItemSelect = () => {},
  menuComponent: MenuComponent,
  menuComponentProps,
  ...props
}) => {
  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      alignItems={alignItems}
      justify="space-between"
      component={Box}
      pt={1}

      {...props}
    >
      <Grid item xs={childrenWidth}>
        {children}
      </Grid>

      <Grid item>
        <Box display="flex" alignItems="center" mr={-0.5}>
          {!disableCheckBox && <Checkbox size={size} checked={isSelected} onClick={stopPropagation(onItemSelect)} />}

          {!disableCardMenu && (
            !disablePopper ? (
              <Popper
                placement="left-start"
                anchorRenderer={
                  ({ anchorRef, handleToggle }) => (
                    <IconButton size={size} buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                      <MoreVertIcon />
                    </IconButton>
                  )
                }
              >
                {({ handleClose }) => (
                  <MenuComponent {...menuComponentProps} onClose={handleClose} />
                )}
              </Popper>
            ) : (
              <MenuComponent {...menuComponentProps} />
            )
          )}

          {icon}
        </Box>
      </Grid>
    </Grid>
  );
};
