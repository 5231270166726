import { useContext, useEffect } from 'react';
import {
  makeStyles,
  List as MuiList,
  Typography
} from '@material-ui/core';
import { Loader } from '../../../../../../../components/Loader';
import { PageBody } from '../../../../../../../components/Page/PageBody';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { CodesContext } from '../../CodesContext';
import { TableHeader } from '../TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const List = () => {
  const classes = useStyles();
  const { codes, isFetched, meta, resetCodes } = useContext(CodesContext);

  useEffect(() => {
    resetCodes();
  }, []);

  return (
    <Loader className={classes.loader} loading={!isFetched} render={
      () => (!codes.length ?
        <Typography align="center" className={classes.title}>No codes found</Typography>
        :
        <>
          <TableHeader/>

          <PageBody fullHeight disablePaddings>
            <MuiList disablePadding>
              {codes.map((code) => (
                <Row key={code.id} code={code}/>
              ))}
            </MuiList>

            <TablePagination pagination={meta} onChange={resetCodes} />
          </PageBody>
        </>
      )}
    />
  );
};
