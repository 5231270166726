import { accidentTypesTitles } from '../../accidentTypes';

export const carAccident = {
  type: accidentTypesTitles.car_accident,
  you_driver_description: null,
  you_driver_passenger: null,
  car_police_report_filed: null,
  car_where_accident_occur: null,
  car_how_accident_occurred: null
};

export const slipAndFall = {
  type: accidentTypesTitles.slip_and_fall,
  slip_where_accident_occur: null,
  slip_police_report_filed: null,
  slip_how_accident_occurred: null
};

export const other = {
  type: accidentTypesTitles.other,
  explain: null
};

const getIntialValues = (form) => {
  const initialValues = {
    [accidentTypesTitles.car_accident]: {
      ...slipAndFall,
      ...other,
      type: accidentTypesTitles.car_accident,
      you_driver_passenger: form?.forms?.accident_information?.you_driver_passenger || null,
      you_driver_description: form?.forms?.accident_information?.you_driver_description || null,
      car_police_report_filed: form?.forms?.accident_information?.car_police_report_filed || null,
      car_where_accident_occur: form?.forms?.accident_information?.car_where_accident_occur || null,
      car_how_accident_occurred: form?.forms?.accident_information?.car_how_accident_occurred || null
    },
    [accidentTypesTitles.slip_and_fall]: {
      ...carAccident,
      ...other,
      type: accidentTypesTitles.slip_and_fall,
      slip_where_accident_occur: form?.forms?.accident_information?.slip_where_accident_occur || null,
      slip_police_report_filed: form?.forms?.accident_information?.slip_police_report_filed || null,
      slip_how_accident_occurred: form?.forms?.accident_information?.slip_how_accident_occurred || null
    },
    [accidentTypesTitles.other]: {
      ...carAccident,
      ...slipAndFall,
      explain: form?.forms?.accident_information?.explain || null,
      type: accidentTypesTitles.other
    }
  };

  return { accident_information: initialValues?.[form?.accident_type] };
};

export const generateAccidentInitialValues = (form) => ({
  forms: getIntialValues(form)
});
