import { uniqBy } from 'lodash';
import { apiModelsMap } from '../../../../../../dataMaps/apiModels';
import { TasksWidget } from '../../../../../../components/tasks';
import { FilesCardWidget } from '../../../../files-common';
import { AccountsProvider } from '../../../../EmailsPage';
import { AllSchedules } from '../../../../ProfilePage/GeneralInfo/Widgets/AllSchedules';
import { InsuranceDetails } from '../../../../ProfilePage/MedicalInfo/MainInfo/Widgets/InsuranceDetails';
import { CallReports } from '../CallReports';
import * as cardsTypes from '../Item/Layouts/cardsTypes';
import { CourtInformation } from './CourtInformation';
import { CaseDepositionsProvider } from './Depositions/CaseDepositionsProvider';
import { Depositions } from './Depositions';
import { CaseDiscoveriesProvider } from './Discovery/CaseDiscoveriesProvider';
import { columnsMap } from './Discovery/List';
import { DirectReferral } from './DirectReferral';
import { NegotiationsProvider } from './Negotiations';
import { Reminders } from './Reminders';
import { SubstitutedIn } from './SubstitutedIn';
import { MedicalClaims } from './MedicalClaims';
import { CaseReOpened } from './CaseReOpened';
import { Participants } from './Participants';
import { TimeTracking } from './TimeTracking';
import { CaseClosure } from './CaseClosure';
import { PlaintiffsPD } from './PlaintiffsPD';
import { Appointments } from './Appointments';
import { Negotiations } from './Negotiations';
import { Discovery } from './Discovery';
import { Checklist } from './Checklist';
import { Insurance } from './Insurance';
import { CaseCross } from './CaseCross';
import { Comments } from './Comments';
import { Activity } from './Activity';
import { Billing } from './Billing';
import { Emails } from './Emails';

const discoveryHiddenColumns = [
  columnsMap.sentDate,
  columnsMap.extension,
  columnsMap.receivedDate
];

export const widgetsMap = {
  [cardsTypes.CHECKLIST]: {
    title: 'Initial Checklist',
    component: Checklist
  },

  [cardsTypes.ACTIVITY]: {
    title: 'Activity',
    component: Activity
  },

  [cardsTypes.DISCOVERY]: {
    title: 'Discovery',
    component: ({ caseItem, onCaseItemUpdate, ...props }) => (
      <CaseDiscoveriesProvider caseItem={caseItem} onCaseItemUpdate={onCaseItemUpdate}>
        <Discovery
          hiddenColumns={discoveryHiddenColumns}

          {...props}
        />
      </CaseDiscoveriesProvider>
    )
  },

  [cardsTypes.COURT_INFORMATION]: {
    title: 'Court Information',
    component: CourtInformation
  },

  [cardsTypes.SCHEDULE]: {
    title: 'Schedule',
    component: ({ caseItem, onCaseItemUpdate, ...props }) => {
      const createScheduleEventModalProps = caseItem?.id ? {
        hideApplyFields: true,

        initialValues: {
          parent_type: apiModelsMap.case,
          parent_id: caseItem?.id,
          users: uniqBy(
            caseItem?.case_users
              ?.filter(({ user }) => user)
              ?.map(({ user }) => user),
            'id'
          )
        }
      } : {};

      return (
        <AllSchedules
          isCreatable
          hideApplyFields
          createScheduleEventModalProps={createScheduleEventModalProps}
          title="Schedule"
          filter={{ parent_type: 'case', parent_id: caseItem.id }}
          {...props}
        />
      );
    }
  },

  [cardsTypes.APPOINTMENTS]: {
    title: 'Appointments',
    component: Appointments
  },

  [cardsTypes.PLAINTIFFS_PD]: {
    title: 'Plaintiff\'s PD',
    component: PlaintiffsPD
  },

  [cardsTypes.NEGOTIATIONS]: {
    title: 'Negotiations',
    component: ({ caseItem, onCaseItemUpdate, ...props }) => (
      <NegotiationsProvider caseItem={caseItem} onCaseItemUpdate={onCaseItemUpdate}>
        <Negotiations {...props} />
      </NegotiationsProvider>
    )
  },

  [cardsTypes.INSURANCE_DETAILS]: {
    title: 'Insurance Details',
    component: InsuranceDetails
  },

  [cardsTypes.CALL_REPORTS]: {
    title: 'Call Reports',
    component: CallReports
  },

  [cardsTypes.MEDICAL_CLAIMS]: {
    title: 'Medical Claims',
    component: MedicalClaims
  },

  [cardsTypes.PARTICIPANTS]: {
    title: 'Participants',
    component: Participants
  },

  [cardsTypes.FILES]: {
    title: 'Files',
    component: ({ caseItem, ...props }) => (
      <FilesCardWidget
        {...props}

        payload={{
          files: caseItem.files,
          filter: { cases: [ caseItem.id ] },
          ownerType: 'case',
          ownerID: caseItem.id
        }}
      />
    )
  },

  [cardsTypes.EMAIL]: {
    title: 'Emails',
    component: (props) => (
      <AccountsProvider disableCounters>
        <Emails {...props} />
      </AccountsProvider>
    )
  },

  [cardsTypes.BILLING]: {
    title: 'Liens and Bills',
    component: Billing
  },

  [cardsTypes.TASKS]: {
    title: 'Tasks',
    component: TasksWidget
  },

  [cardsTypes.TIME_TRACKING]: {
    title: 'Time Tracking',
    component: ({ caseItem, ...props }) => (
      <TimeTracking
        caseItem={caseItem}
        {...props}

        initialLogValues={{
          time_track_model: 'case',
          time_track_model_id: caseItem.id
        }}
      />
    )
  },

  [cardsTypes.DEPOSITIONS] : {
    title: 'Depositions',
    component: ({ caseItem, ...props }) => (
      <CaseDepositionsProvider caseID={caseItem.id}>
        <Depositions {...props} />
      </CaseDepositionsProvider>
    )
  },

  [cardsTypes.INSURANCE]: {
    title: 'Claim Insurance Information',
    component: Insurance
  },

  [cardsTypes.SUBSTITUTED_IN]: {
    title: 'Case Substituted in form',
    component: SubstitutedIn
  },

  [cardsTypes.DIRECT_REFERRAL]: {
    title: 'Direct Referral to attorney',
    component: DirectReferral
  },

  [cardsTypes.REMINDERS]: {
    title: 'Reminders',
    component: Reminders
  },

  [cardsTypes.CASE_CLOSURE]: {
    title: 'Case Closure information',
    component: CaseClosure
  },

  [cardsTypes.CASE_CROSS]: {
    title: 'Case cross-reference information',
    component: CaseCross
  },

  [cardsTypes.CASE_RE_OPENED]: {
    title: 'Case re-opened/re-closed',
    component: CaseReOpened
  },

  [cardsTypes.COMMENTS]: {
    title: 'Case Notes',
    component: Comments
  }
};
