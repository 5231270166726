import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { isObject } from 'lodash';
import { Dialog, Button, SvgIcon } from '@material-ui/core';
import { mdiEmailArrowRightOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FilePicker } from '../../../../../components/FilePicker';
import {
  Editor,
  TextField,
  SimpleSelect,
  EmailsSelect,
  transformAccountToOption
} from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '../../../../../components/Modal';
import * as emailsApi from '../../../../../api/emails';
import { getForwardedMessageTemplate, getSignature } from '../signatureHelpers';
import { validationSchema } from './validationSchema';

export const EmailsForwardModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    message: {
      attachments: initialAttachments,

      ...message
    }
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ attachments, setAttachments ] = useState([]);
  const [ files, setFiles ] = useState([]);
  const [ availableFilesIDs, setAvailableFilesIDs ] = useState([]);
  const currentUser = useSelector(({ profile }) => profile.user);
  const accounts = currentUser?.email_accounts;
  const cc = message?.cc?.map((item) => item?.email || item) || [];
  const bcc = message?.bcc?.map((item) => item?.email || item) || [];
  const accountsOptions = accounts?.map(transformAccountToOption);

  const getFormData = (values) => {
    const formData = new FormData();

    Object.entries(values).forEach(([ name, value ]) => {
      if (Array.isArray(value)) {
        value.forEach((value) => {
          formData.append(`${name}[]`, isObject(value) ? JSON.stringify(value) : value);
        });
      } else {
        formData.append(name, isObject(value) ? JSON.stringify(value) : value);
      }
    });

    values.to.forEach((to) => {
      formData.append('to[]', to);
    });

    if (values.cc) {
      values.cc.forEach((cc) => {
        formData.append('cc[]', cc);
      });
    }

    if (values.bcc) {
      values.bcc.forEach((bcc) => {
        formData.append('bcc[]', bcc);
      });
    }

    attachments?.forEach((attachment, index) => {
      Object.entries(attachment).forEach(([ name, value ]) => {
        formData.append(`attachments[${index}][${name}]`, value);
      });
    });

    files?.forEach((file) => {
      formData.append('files[]', file, file.name);
    });

    availableFilesIDs?.forEach((fileID) => {
      formData.append('attach_media_id[]', fileID);
    });

    return formData;
  };

  const forwardEmail = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const formData = getFormData(values);

    return emailsApi.sendEmail(formData).then(() => {
      enqueueSnackbar('Email forwarded', { variant: 'success' });
      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Emails not forwarded', { variant: 'error' });
    });
  };

  const handleChangeConfig = (setFieldValue) => (event) => {
    const option = accountsOptions?.find(({ value }) => value === event.target.value);

    setFieldValue('body', getSignature(option?.data?.signature_line?.html));
  };

  const handleAttachmentsChange = (attachments) => {
    setAttachments(attachments);
  };

  const handleFilesChange = (files) => {
    setFiles(files);
  };

  const handleAvailableFilesIDs = (filesIDs) => {
    setAvailableFilesIDs(filesIDs);
  };

  return (
    <Formik
      initialValues={{
        ...message,

        cc,
        bcc,
        to: null,
        subject: message.subject?.includes('Fwd:') ? message.subject : 'Fwd: ' + message.subject,
        body: getForwardedMessageTemplate(message)
      }}
      onSubmit={forwardEmail}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit, setFieldValue }) => (
        <Dialog {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiEmailArrowRightOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Forward
            </ModalHeader>

            <ModalBody>
              <SimpleSelect
                name="config_id"
                label="From"
                margin="dense"
                options={accountsOptions}
                onChange={handleChangeConfig(setFieldValue)}
              />

              <EmailsSelect
                isCreatable
                multiple
                name="to"
                label="To"
                TextFieldProps={{ required: true, margin: 'dense' }}
              />

              <TextField
                required
                name="subject"
                label="Subject"
                margin="dense"
              />

              <Editor name="body" margin="normal" />

              <FilePicker
                initialAttachments={initialAttachments}
                onChangeFiles={handleFilesChange}
                onChangeAvailableFilesIDs={handleAvailableFilesIDs}
                onAttachmentsChange={handleAttachmentsChange}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Send
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
