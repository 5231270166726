import { Switch } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { Route } from '../../../components/router';
import {
  Edit,
  PrescriptionsTemplates,
  Prescriptions as MedicalPrescriptions
} from '../MedicalReports';

export const Prescriptions = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box width="100%" height="100%" bgcolor="grey.100" p={isTablet ? 1 : 2}>
      <Switch>
        <Route exact path="/prescriptions" component={MedicalPrescriptions} />
        <Route exact path="/prescriptions/templates" component={PrescriptionsTemplates} />
        <Route path="/prescriptions/templates/:id/edit" component={Edit} />
      </Switch>
    </Box>
  );
};
