import * as billingApi from '../../../../api/billing';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';

export const transformInvoiceStatusToOption = (status) => status && ({
  label: status.name,
  value: status.id,
  data: status
});

export const fetchInvoicesStatuses = (params) => fetchDataForAsyncSelect((search, { page }) => {
  return billingApi.getInvoiceStatus({ params: { search, page, ...params } });
}, {
  transformDataToOption: transformInvoiceStatusToOption
});

export const InvoicesStatusesSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      label="Status"
      placeholder="Search invoice status"
      promiseWithOptions={fetchInvoicesStatuses(params)}

      {...props}
    />
  );
};
