import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { ErrorBoundary } from '../../../../../../components/ErrorBoundary';
import { Loader } from '../../../../../../components/Loader';
import { TablePagination } from '../../../../../../components/TablePagination';
import { PatientsContext } from '../../../PatientsProvider';
import { Row } from './Row';
import { Header } from './Header';

export const minRowHeight = 54;
export const columnsWidths = {
  medRecNo: 140,
  name: 180,
  appointmentDate: 150,
  phone: 160,
  email: 180,
  typeIns: 100,
  expirationDay: 120,
  actions: 80
};

export const List = () => {
  const {
    users,
    isFetched,
    filter: outerFilter,
    resetUsers
  } = useContext(PatientsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetUsers();
  }, []);

  return (
    <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
      <Header />

      <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
        <Loader p={3} loading={!isFetched} render={
          () => !users.length ? (
            <Box p={3}>
              <Typography align="center">No users found</Typography>
            </Box>
          ) : (
            <>
              <MuiList disablePadding>
                {users.map((user) => (
                  <ErrorBoundary key={user.id}>
                    <Row user={user} />
                  </ErrorBoundary>
                ))}
              </MuiList>

              <TablePagination pagination={outerFilter} onChange={resetUsers} />
            </>
          )}
        />
      </CustomScrollbars>
    </Box>
  );
};
