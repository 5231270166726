import { useContext, useState } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DatePicker } from '../../../FormField';
import { IconButton } from '../../../IconButton';
import { CalendarContext } from '../../../schedule-events/Schedule/CalendarProvider';

export const Header = ({ selectedDate, startDate, endDate, onDateUpdate, onStartUpdate, onEndUpdate }) => {
  const [ isOpenPicker, setIsOpenPicker ] = useState(false);
  const { reloadCalendarEvents } = useContext(CalendarContext);

  const openPicker = () => {
    setIsOpenPicker(true);
  };

  const closePicker = () => {
    setIsOpenPicker(false);
  };

  const handleChangeDate = (value) => {
    const date = moment.unix(value);

    onStartUpdate(date.clone().startOf('week'));
    onEndUpdate(date.clone().endOf('week'));
    onDateUpdate(date);

    reloadCalendarEvents({
      started_from: date.clone().startOf('week').unix(),
      started_to: date.clone().endOf('week').unix()
    });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" px={1}>
      <Typography variant="h5" color="primary">
        {selectedDate.format('L')}
      </Typography>

      <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
        <Typography variant="h5">
          {startDate.format('MMMM')}
        </Typography>

        {!startDate.isSame(endDate, 'month') && (
          <>
            &nbsp;/&nbsp;
            <Typography variant="h5">
              {endDate.format('MMMM')}
            </Typography>
          </>
        )}
      </Box>

      <IconButton color="inherit" onClick={openPicker}>
        <DateRangeIcon color="inherit" />
      </IconButton>

      <DatePicker
        withoutFormik
        open={isOpenPicker}
        TextFieldComponent={() => null}
        name="date"
        openTo="date"
        onClose={closePicker}
        onChange={handleChangeDate}
      />
    </Box>
  );
};
