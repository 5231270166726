export const styles = ({ spacing, palette, typography }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  loader: {
    padding: spacing(2)
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: spacing(0, 2, 1, 1)
  },

  chartCounter: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 1, 0, 1)
  },

  chart: {
    flexGrow: 1
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: spacing(3)
  },

  name: {
    fontSize: typography.pxToRem(13),
    color: palette.grey[400]
  },

  count: {
    color: palette.primary.contrastText,
    fontWeight: typography.fontWeightMedium,
    fontSize: typography.pxToRem(12),
    marginLeft: spacing(),
    padding: spacing(0, 1),
    borderRadius: 50
  },

  amount: {
    fontSize: typography.pxToRem(20)
  }
});
