import { useContext } from 'react';
import { Box, List, ListItemIcon, ListItemText, MenuItem, SvgIcon } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import TemplateIcon from '../../../../../../../components/icons/medical/edit-template-icon.svg';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { EditTemplateModal } from '../../EditTemplateModal';
import { TemplatesContext } from '../../TemplatesProvider';

export const TemplatesMenu = ({ template, onClose }) => {
  const { openModal } = useModal();
  const { updateTemplate, deleteTemplate } = useContext(TemplatesContext);

  const handleDeleteTemplate = () => {
    deleteTemplate(template.id);
    onClose();
  };

  const onUpdate = () => {
    openModal(EditTemplateModal, {
      payload: { id: template.id },
      onModalResolved: (value) => {
        updateTemplate(value);
        onClose();
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={onUpdate}>
        <ListItemIcon>
          <Box color="info.main">
            <SvgIcon fontSize="inherit" color="inherit">
              <TemplateIcon />
            </SvgIcon>
          </Box>
        </ListItemIcon>

        <ListItemText primary="Edit template" />
      </MenuItem>

      <MenuItem onClick={handleDeleteTemplate}>
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete template" />
      </MenuItem>
    </List>
  );
};
