import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';

export const Chart = ({
  dataCase,
  dataBilling,
  dataTotal
}) => {
  const { palette } = useTheme();
  const chartCaseData = dataCase.map((item) => +item);
  const chartBillingData = dataBilling.map((item) => +item);

  const getChartData = () => ({
    datasets: [
      {
        label: 'On cases',
        backgroundColor: [ palette.primary.main, palette.background.default ],
        hoverBackgroundColor: [ palette.primary.main, palette.background.default ],
        hoverBorderColor: [ palette.background.default, palette.background.default ],
        data: chartCaseData
      },
      {
        label: 'On billing',
        backgroundColor: [ palette.purple, palette.background.default ],
        hoverBackgroundColor: [ palette.purple, palette.background.default ],
        hoverBorderColor: [ palette.background.default, palette.background.default ],
        data: chartBillingData
      },
      {
        label: 'Uploaded files',
        backgroundColor: [ palette.secondary.light ],
        hoverBackgroundColor: [ palette.secondary.light ],
        hoverBorderColor: [ palette.background.default, palette.background.default ],
        data: dataTotal
      }
    ]
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend:{
      display:false
    },
    tooltips: {
      callbacks: {
        label: (item, data) => {
          let label;

          if (data.datasets[item.datasetIndex].backgroundColor[item.index] === palette.background.default) {
            label = 'difference';
          } else {
            label = data.datasets[item.datasetIndex].label + ': ' + data.datasets[item.datasetIndex].data[item.index];
          }

          return label;
        }
      }
    }
  };

  return (
    <Doughnut options={chartOptions} data={getChartData()} />
  );
};
