import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import moment from 'moment';
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { apptTypesMap, arrivalStatusMap } from '../../../../../../components/appointments';
import { useResizeObserver } from '../../../../../../helpers/hooks/useResizeObserver';
import { getUserFullName } from '../../../../../../helpers/users';
import * as apptApi from '../../../../../../api/appointments';
import * as formsApi from '../../../../../../api/profile';
import { api } from '../../../../../../api';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { Divider } from '../../../../../../components/Divider';
import { Loader } from '../../../../../../components/Loader';
import { Date } from '../../../../../../components/Date';
import { transformMedicalFormsToOption } from '../../../../../../components/FormField';
import { UserMRI } from '../../MainInfo/UserMRI';
import { MedicalFormItemContext } from '../../MedicalFormItemProvider';
import { ApptBody } from '../ApptFormContent/ApptBody';
import { ApptList } from '../ApptList';
import { UserIcon } from '../PrivatePacket/UserIcon';
import { reliefSliderMarks, sliderMarks } from './sliderMarks';
import { validationSchema } from './validationSchema';
import { MedicalFormInfo } from './MedicalFormInfo';
import { InsurancesInfo } from './InsurancesInfo';
import { PainContent } from './PainContent';
import { MainContent } from './MainContent';
import { Footer } from './Footer';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FollowUp = () => {
  const classes = useStyles();
  const { medicalInfoPathname, type, setType } = useContext(MedicalFormItemContext);
  const [ form, setForm ] = useState(null);
  const history = useHistory();
  const params = useParams();
  const scrollElementRef = useRef();
  const cancelFetch = useRef(() => {});
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isEditable = !!form?.appointment?.id;

  const updateForm = ({ values, setSubmitting, setErrors }) => {
    values.treating_physician_id = values?.treating_physician_id?.value || values?.treating_physician_id;

    formsApi.updateFollowUpMedicalForm({ id: form.id, ...values })
      .then(() => {
        enqueueSnackbar('Form successfully updated', { variant: 'success' });
        history.push(`${medicalInfoPathname}/medical-forms`);
      })
      .catch(({ errors }) => {
        if (errors) {
          setErrors(errors);
          setSubmitting(false);
          enqueueSnackbar('Form not updated', { variant: 'error' });
        }
      });
  };

  const handleUpdateForm = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (!isEditable && values?.appointment_time) {
      const {
        appointment_book_id,
        treating_physician_id,
        appointment_date,
        appointment_time,
        time,
        follow_up_appointment,
        ...data
      } = values;

      const appt = {
        arrival_status_id: arrivalStatusMap.followUp,
        appointment_type_id: values?.follow_up_appointment?.appointment_type_id
          || values?.follow_up_appointment?.appointment_book?.default_appointment_type_id
          || apptTypesMap.consultation,
        appointment_book_id,
        treating_physician_id,
        appointment_at: moment(values?.appointment_time)?.unix(),
        office_id: values?.follow_up_appointment?.office_id || values?.office_id,
        patient_id: values?.follow_up_appointment?.patient_id || values?.patient?.id,
        price: values?.follow_up_appointment?.price,
        time: values?.follow_up_appointment?.time,
        appointment_time: values?.appointment_time,
        copay_type: values?.follow_up_appointment?.copay_type,
        do_not_bill_claim: values?.follow_up_appointment?.do_not_bill_claim,
        eligibility_manually_verified: values?.follow_up_appointment?.eligibility_manually_verified,
        has_claim: values?.follow_up_appointment?.has_claim,
        note: values?.follow_up_appointment?.note,
        patient_insurance_id: values?.follow_up_appointment?.patient_insurance_id,
        timezone: values?.follow_up_appointment?.timezone,
        medical: form?.medical?.id,
        authorization_id: values?.follow_up_appointment?.authorization_id || null,
        appointment_confirmed_by_office: false,
        medical_forms_id: [ form?.id ]
      };

      return apptApi.createAppointment(appt).then(() => {
        enqueueSnackbar(
          'Appointment successfully created',
          { variant: 'success' }
        );
        return updateForm({ values: { ...data, treating_physician_id }, setSubmitting, setErrors });
      }).catch(({ errors }) => {
        if (errors) {
          setErrors(errors);
          enqueueSnackbar('Appointment not created', { variant: 'error' });
        }
      });
    } else {
      return updateForm({ values, setSubmitting, setErrors });
    }
  };

  const fetchMedicalForm = () => {
    cancelFetch.current();

    formsApi.fetchMedicalForm(params.medicalFormID, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      setType(data.form_type);
      setForm(data);
    });
  };

  useEffect(() => {
    fetchMedicalForm();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <Box height="100%" ref={rootRef}>
      <Loader p={3} loading={!form} render={
        () => (
          <Formik
            initialValues={{
              ...form,
              medical_id: form?.medical?.id,
              follow_up_appointment: form?.parent?.appointment,
              treating_physician_id: (
                form?.treating_physician || form?.appointment?.appointment_book?.provider
              ),
              primary_insurance_id: form?.primary_insurance,
              primary_insuranceId: form?.primary_insurance?.insuranceId,
              groupID: form?.primary_insurance?.groupId,
              secondary_insurance_id: form?.secondary_insurance,
              secondary_insuranceId: form?.secondary_insurance?.insuranceId,
              forms: {
                use_of_laxative: form?.forms?.use_of_laxative || 'no',
                awaken_with_pain: form?.forms?.awaken_with_pain || 'no',
                do_you_have_any_new_areas_of_pain: form?.forms?.do_you_have_any_new_areas_of_pain || 'no',
                how_has_your_sleep_been: form?.forms?.how_has_your_sleep_been || 'not_disturbed',
                describes_value: sliderMarks?.find(({ label }) => (
                  label === form?.forms?.describes_your_pain_on_average_in_the_week_with_medication_use)
                )?.value || 0,
                best_describes_value: sliderMarks?.find(({ label }) => (
                  label === form?.forms?.best_describes_your_pain_at_its_worst)
                )?.value || 0,
                pain_relief_value:
                  reliefSliderMarks?.find(({ label }) => (
                    label === form?.forms?.pain_relief_with_the_treatment_or_medicine_we_provided_you)
                  )?.value || 0,
                ...form?.forms
              },
              parent: form?.parent || null,
              medical_form_id: transformMedicalFormsToOption(form?.parent),
              time: form?.appointment?.time || 15,
              appointment_book_id: form.appointment?.appointment_book || form?.parent?.appointment?.appointment_book,
              appointment_date: form?.appointment?.appointment_at
                ? moment.unix(form?.appointment?.appointment_at).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
              appointment_time: form?.appointment?.appointment_time || null
            }}
            validationSchema={validationSchema}
            onSubmit={handleUpdateForm}
          >
            {({ values, isSubmitting, handleSubmit }) => (
              <Box
                noValidate
                component="form"
                display="flex"
                flexDirection="column"
                flexGrow={1}
                pt={isSmallScreen ? 0 : 2}
                pb={2}
                overflow="hidden"
                height="100%"
                onSubmit={handleSubmit}
              >
                <Box px={isSmallScreen ? 2 : 3} pt={isSmallScreen ? 2 : 3}>
                  <Paper variant="outlined" square className={classes.header}>
                    <UserIcon type={form.form_type} />

                    <div className={classes.mainInfo}>
                      <div className={classes.mainInfo__date}>
                        <Date
                          disableMoment={!form?.created_at}
                          date={form?.created_at || moment.unix(moment().unix()).format('L')}
                        />
                      </div>

                      <Typography className={classes.mainInfo__title}>
                        {type}
                      </Typography>
                    </div>

                    <div className={classes.userInfo}>
                      <Box textAlign="right" mt={2}>
                        <UserMRI form={form} />

                        <Typography
                          color="textSecondary"
                          variant={isMobile ? 'h5' : 'h6'}
                          className={classes.userName}
                        >
                          {form?.name || getUserFullName(form?.patient)}
                        </Typography>
                      </Box>
                    </div>
                  </Paper>
                </Box>

                <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
                  <MedicalFormInfo />
                  <InsurancesInfo />

                  <Box display="flex" flexDirection="column" p={isSmallScreen ? 1 : 2} py={2} overflow="hidden">
                    <Box pl={isSmallScreen ? 1 : 1.5}>
                      <Typography variant="h4" color="secondary">
                        {isEditable ? 'Appointment' : 'Previous appointment'}
                      </Typography>
                    </Box>

                    <Box px={isSmallScreen ? 1 : 2}>
                      <ApptList
                        isFollowUp
                        isUpdatable={isEditable}
                        initialValues={isEditable ? form : form?.parent}
                      />
                    </Box>
                  </Box>

                  {!isEditable && (
                    <Box display="flex" flexDirection="column" p={isSmallScreen ? 1 : 2} pt={2} overflow="hidden">
                      <ApptBody
                        appointmentDate={
                          form?.appointment?.appointment_at
                            ? moment.unix(form?.appointment?.appointment_at).format('YYYY-MM-DD')
                            : moment().format('YYYY-MM-DD')
                        }
                        width={width}
                        initialValues={form}
                      />
                    </Box>
                  )}

                  <MainContent />
                  <PainContent
                    name="forms.describes_your_pain_on_average_in_the_week_with_medication_use"
                    nameValue="forms.describes_value"
                    title={
                      <Typography variant="h5">
                        8. Choose a number that describes your pain on <u>average in the week</u> with medication use
                      </Typography>
                    }
                    sliderValue={values?.forms?.describes_value}
                    marks={sliderMarks}
                  />

                  <PainContent
                    name="forms.best_describes_your_pain_at_its_worst"
                    nameValue="forms.best_describes_value"
                    sliderValue={values?.forms?.best_describes_value}
                    title={
                      <Typography variant="h5">
                        9. Rate your pain by circling the number that best describes your <u>pain at its worst</u>
                      </Typography>
                    }
                    marks={sliderMarks}
                  />

                  <PainContent
                    name="forms.pain_relief_with_the_treatment_or_medicine_we_provided_you"
                    nameValue="forms.pain_relief_value"
                    sliderValue={values?.forms?.pain_relief_value}
                    title={
                      <Typography variant="h5">
                        10. Rate your pain relief with the treatment or medicine we provided you
                      </Typography>
                    }
                    marks={reliefSliderMarks}
                  />

                  <Footer />
                </CustomScrollbars>

                <Divider disableTopGutter gutter={2} />

                <Box flexGrow={0} ml="auto" px={2}>
                  <Button
                    className={classes.backButton}
                    color="primary"
                    onClick={history.goBack}
                  >
                    Back
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        disabled={isSubmitting}
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </Box>
              </Box>
            )}
          </Formik>
        )}
      />
    </Box>
  );
};
