import { EmptyFormAppointment } from '../../../../components/medical/forms';
// import { ApptFormContent } from '../ApptFormContent';
import { PreviewContent } from '../PreviewContent';
import { CurrentSymptoms } from './CurrentSymptoms';
import { MedicalHistory } from './MedicalHistory';
import { ReviewContent } from './ReviewContent';
import { GeneralInfo } from './GeneralInfo';
import { PainContent } from './PainContent';

export const formStepComponents = [
  GeneralInfo,
  EmptyFormAppointment,
  // ApptFormContent,
  CurrentSymptoms,
  PainContent,
  MedicalHistory,
  ReviewContent,
  PreviewContent
];
