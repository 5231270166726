import { useEffect, useRef, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { List as MuiList, makeStyles, Typography } from '@material-ui/core';
import * as statisticsApi from '../../../../../../../api/billing';
import { api } from '../../../../../../../api';
import { Loader } from '../../../../../../../components/Loader';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { initialState } from '../../../StatisticsFilterProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointXXS = 440;
export const widthBreakpointXS = 560;
export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1150;
export const minRowHeight = 54;
export const columnsWidths = {
  client: 150,
  date: 100,
  hours: 80,
  parent: 100,
  total: 110
};

export const FilteredMainContent = ({ initialFilter }) => {
  const classes = useStyles();
  const [ tracks, setTracks ] = useState(null);
  const [ filter ] = useState(initialState.filter);
  const cancelFetch = useRef(() => {});

  const fetchTracks = () => {
    cancelFetch.current();

    statisticsApi.fetchTimeTracks({
      params: { ...filter, ...initialFilter.filter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(setTracks);
  };

  useEffect(() => {
    fetchTracks();

    return () => {
      cancelFetch.current();
    };
  }, [ initialFilter.filter ]);

  return (
    <AutoSizer disableHeight className={classes.autoSizer}>
      {({ width }) => (
        <Loader loading={!tracks} className={classes.loader} render={
          () => (
            !tracks.length ?
              <Typography align="center" className={classes.contentMessage}>
                No tracks found
              </Typography>
              :
              <>
                <TableHeader width={width} />

                <Scrollbars>
                  <MuiList disablePadding>
                    {tracks.map((item) => (
                      <Row key={item.id} width={width} track={item} />
                    ))}
                  </MuiList>
                </Scrollbars>
              </>
          )}
        />
      )}
    </AutoSizer>
  );
};
