export const styles = ({ palette: { primary, darkgreen } }) => ({
  expensesFiles: {
    display: 'flex',
    alignItems: 'center',
    color: primary.main,
    cursor: 'pointer'
  },

  budget: {
    color: darkgreen
  }
});
