import { api } from '../api';

export const fetchPatients = (config = {}) => {
  return api.get('/users/patients', config)
    .then((({ data }) => data))
    .catch((error) => {
      throw error;
    });
};

export const getPatientsCounters = (user) => {
  return api.get('/users/counters', user)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const fetchLastDiagnosis = (id) => {
  return api.get(`/users/${id}/last-diagnosis`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};
