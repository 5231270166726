import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { Loader } from '../../../../../components/Loader';
import { PageBody } from '../../../../../components/Page/PageBody';
import { TablePagination } from '../../../../../components/TablePagination';
import { MRIsContext } from '../MRIsProvider';
import { Header } from './Header';
import { Row } from './Row';

export const widthBreakpointSmall = 785;
export const widthBreakpointMedium = 850;
export const widthBreakpointLarge = 1150;
export const minRowHeight = 61;
export const columnsWidths = {
  name: 150,
  date: 190,
  provider: 200,
  office: 200,
  actions: 50
};

export const List = () => {
  const { isFetched, meta, mris, resetMRIs } = useContext(MRIsContext);
  const ref = useRef();
  const { width } = useResizeObserver({ ref });

  useEffect(() => {
    resetMRIs();
  }, []);

  return (
    <Box ref={ref} height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => (!mris.length ? (
          <Box p={3}>
            <Typography align="center">No MRIs found</Typography>
          </Box>
        ) : (
          <>
            <Header width={width} />

            <PageBody disablePaddings>
              <MuiList disablePadding>
                {mris.map((mri) => (
                  <Row key={mri.id} width={width} mri={mri} />
                ))}
              </MuiList>
            </PageBody>

            <TablePagination pagination={meta} onChange={resetMRIs} />
          </>
        ))}
      />
    </Box>
  );
};
