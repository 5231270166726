import { api } from '../api';

export const fetchTaskTemplateGroups = (config = {}) => {
  return api.get('/case-task-template-groups', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createTaskTemplateGroup = (template) => {
  return api.post('/case-task-template-groups', template).then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const fetchTaskTemplateGroup = (id) => {
  return api.get(`/case-task-template-groups/${id}`).then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateTaskTemplateGroup = (template) => {
  return api.put(`/case-task-template-groups/${template.id}`, template).then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteTaskTemplateGroup = (id) => {
  return api.delete(`/case-task-template-groups/${id}`).then(({ data }) => data)
    .catch((error) => { throw error; });
};
