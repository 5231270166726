import { Formik } from 'formik';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { createTag } from '../../../api/files/tags';
import LabelAddSvg from '../../icons/label-add.svg';
import { ModalFooter, ModalHeader, ModalBody } from '../../Modal';
import { TextField, ColorPicker } from '../../FormField';
import { Loader } from '../../Loader';
import { validationSchema } from '../validationSchema';

export const CreateTagModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { is_system }
}) => {
  const addTag = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    createTag({ ...values, is_system }).then((tag) => {
      handleModalResolve(tag);
    }).catch((errors) => {
      setSubmitting(false);
      setErrors(errors);
    });
  };

  return (
    <Formik
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={addTag}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject} icon={<SvgIcon><LabelAddSvg /></SvgIcon>}>
              Add tag
            </ModalHeader>

            <ModalBody>
              <TextField
                required
                name="name"
                label="Tag name"
                placeholder="Enter name for tag..."
                margin="dense"
              />

              <ColorPicker
                required
                name="color"
                label="Color"
                placeholder="Select color..."
              />
            </ModalBody>

            <ModalFooter>
              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    autoFocus
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
