import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Box,
  Grid,
  Hidden,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import {
  stepsTitle as reportStepsTitle,
  getReportFormRoutes
} from '../../../../MedicalReports/Diagnosis/steps/StepProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FormStepper = ({
  reportID = null,
  steps,
  stepsTitle = {},
  form,
  completed,
  component,
  activeStep,
  handleReset,
  onActiveStepChange,
  isDirty = false,
  getFormRoutes = () => {},
  ...props
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const params = useParams();
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const routeArray = pathname.split('/');
    const step = routeArray[routeArray.length - 1];

    if (reportID) {
      reportStepsTitle[step] !== activeStep && onActiveStepChange(reportStepsTitle[step]);
    } else {
      stepsTitle[step] !== activeStep && onActiveStepChange(stepsTitle[step]);
    }
  }, []);

  const handleStep = (step) => () => {
    if (isDirty && (activeStep === 0 && step !== 0)) {
      openModal(ConfirmationModal, {
        DialogProps: {
          PaperProps: { style: { maxWidth: 650 } }
        },
        payload: {
          title: 'Warning',
          content: 'You have unsaved changes. If you leave before saving, ' +
            'your changes will be lost. Do you want to continue?'
        },
        onModalResolved: () => {
          onActiveStepChange(step);

          if (reportID) {
            history.push(getReportFormRoutes({ id: reportID, pathname: props.pathname })[step]);
          } else {
            history.push(
              getFormRoutes({
                id: params.medicalFormID,
                medicalInfoPathname: props.medicalInfoPathname || ''
              })[step]
            );
          }
        }
      });
    } else {
      onActiveStepChange(step);

      if (reportID) {
        history.push(getReportFormRoutes({ id: reportID, pathname: props.pathname })[step]);
      } else {
        history.push(
          getFormRoutes({
            id: params.medicalFormID,
            medicalInfoPathname: props.medicalInfoPathname || ''
          })[step]
        );
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" className={classes.logoWrapper}>
        <Grid item md={1} xs={3}>
          <div className={classes.logo} />
        </Grid>

        <Grid item md={5} xs={9}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h5">
              California Back and Pain Specialists
            </Typography>

            <Typography variant="caption" color="textSecondary">Phone: (818) 884-5480</Typography>
            <Typography variant="caption" color="textSecondary">Fax: (818) 884-5490</Typography>
          </Box>
        </Grid>

        <Hidden mdDown>
          <Grid item lg={6} md={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography variant="subtitle2" color="textSecondary">
                6640 Van Nuys Blvd., Suite 101 Van Nuys, CA 91405
              </Typography>

              <Typography variant="caption" color="textSecondary">
                13851 East 14th Street, Suite 102 A,B, San Leandro, CA 94578
              </Typography>

              <Typography variant="caption" color="textSecondary">
                7120 Indiana Avenue, Suite H&I, Riverside, CA 92504
              </Typography>

              <Typography variant="caption" color="textSecondary">
                10610 Lower Azusa Rd, El Monte, CA 91731
              </Typography>

              <Typography variant="caption" color="textSecondary">
                1919 Truxtun Ave, Bakersfield, CA 93301
              </Typography>
            </Box>
          </Grid>
        </Hidden>
      </Grid>

      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                completed={completed[index] && activeStep !== index}
                onClick={(params.medicalFormID || !!reportID) ? handleStep(index) : undefined}
                className={(params.medicalFormID || !!reportID) ? classes.stepper__item : classes.stepper__item_disable}
              >
                {!isMobile && label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className={classes.mainContent}>
          {component}
        </div>
      </div>
    </>
  );
};
