import cn from 'classnames';
import { makeStyles, IconButton, Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { Avatar } from '../../../../../../../components/Avatar';
import * as videoApi from '../../../../../../../api/videos';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item, onDeleteNote, onClickSecond }) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const deleteNote = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        videoApi.deleteNote(item.id).then((data) => {
          onDeleteNote(data);
        });
      }
    });
  };

  const handlePause = () => {
    onClickSecond(item);
  };

  return (
    <div className={classes.notes}>
      <Avatar
        src={item.user.profile.avatar}
        alt={item.user.profile.first_name[0] + item.user.profile.last_name[0]}
      />

      <div>
        <div>
          <Typography
            className={classes.note}
            variant="h4"
          >
            {item.user.profile.first_name} {item.user.profile.last_name}
          </Typography>

          <Typography
            className={
              cn(
                classes.notesDate,
                classes.note
              )
            }
            variant="h4"
          >
              7/30/2019
          </Typography>

          <div className={classes.noteInline}>
            <IconButton className={classes.buttonInline} >
              <CreateIcon fontSize="small"/>
            </IconButton>

            <IconButton className={classes.buttonInline} onClick={deleteNote}>
              <DeleteIcon fontSize="small"/>
            </IconButton>
          </div>
        </div>

        <div>
          <div className={classes.notesText}>
            <div onClick={handlePause} className={classes.notesTime}>{item.time}</div>

            <div className={classes.noteInline}>{item.comment}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
