import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, omit } from 'lodash';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { CasesSelect } from '../../../../../../../../components/cases/CasesSelect';
import { UsersSelect } from '../../../../../../../../components/users';
import { saveFilter } from '../../../../../../../../store/lastFilters';
import { FiltersBar as FiltersBarComponent } from '../../../../../../../../components/FiltersBar';
import { ExpensesCategoriesSelect } from '../../../../../../../../components/billing';
import { KeyboardDatePicker, TextField } from '../../../../../../../../components/FormField';
import { IconComponent } from '../../../../../../../../components/saved-filters';
import { ExpensesContext } from '../../../../Expenses';
import { CardContent } from './CardContent';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { transformRelationsForFilterToOptions } from './utils';
import { initialValues } from './initialValues';
import { List } from './List';

const MODAL_WIDTH = 1030;

export const FiltersBar = ({ disableInitialFilters = false, hiddenFields = [], filterKey }) => {
  const theme = useTheme();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { applyFilter } = useContext(ExpensesContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleUsersSelectChange = (user) => {
    applyFilter({ user_id: user?.id || null });
    setRelationsForFilter((state) => ({ ...state, user_id: user }));
  };

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.value });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data }));
  };

  const handleCaseChange = (caseItem) => {
    applyFilter({ case_id: caseItem?.id });
    setRelationsForFilter((state) => ({ ...state, case_id: caseItem }));
  };

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(transformRelationsForFilterToOptions(filter));

    applyFilter(omit({
      ...filter,

      invoice_number: filter?.invoice_number,
      user_id: filter?.user_id?.id,
      users_id: filter?.users_id?.map(({ id }) => id),
      case_id: filter?.case_id?.id,
      from: filter?.from,
      to: filter?.to,
      category_id: filter?.category_id?.id
    }, hiddenFields));
  };

  useEffect(() => {
    const { case_id, ...filter } = relationsForFilter;

    dispatch(saveFilter({
      key: filterKey,
      filter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (!disableInitialFilters && lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={transformRelationsForFilterToOptions(initialValues)}
      hiddenFields={hiddenFields}
      onReset={() => applySavedFilter(initialValues)}
      filterKey={filterKey}
      iconComponent={!disableInitialFilters && (
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.invoiceNumber,
          label: filterFieldsLabels[filterFieldsMap.invoiceNumber],
          field: (
            <Box minWidth={155}>
              <TextField
                name="invoice_number"
                label="Inv number"
                placeholder="Search..."
                onChange={handleFieldChange('invoice_number')}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.userID,
          label: filterFieldsLabels[filterFieldsMap.usersId],
          field: (
            <Box minWidth={isMobile ? '100%' : 210}>
              <UsersSelect
                name="user_id"
                label={filterFieldsLabels[filterFieldsMap.usersId]}
                onChange={handleUsersSelectChange}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.caseId,
          label: filterFieldsLabels[filterFieldsMap.caseId],
          field: (
            <CasesSelect
              name="case_id"
              label="All cases"
              placeholder="Search case by name..."
              onChange={handleCaseChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.dateFrom,
          label: filterFieldsLabels[filterFieldsMap.dateFrom],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                clearable
                name="from"
                label="From"
                onChange={handleDatePickerChange('from')}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.dateTo,
          label: filterFieldsLabels[filterFieldsMap.dateTo],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                clearable
                name="to"
                label="To"
                onChange={handleDatePickerChange('to')}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.categoryId,
          label: filterFieldsLabels[filterFieldsMap.categoryId],
          field: (
            <Box minWidth={180}>
              <ExpensesCategoriesSelect
                isClearable
                name="category_id"
                label="Expense category"
                onChange={handleSelectChange('category_id')}
              />
            </Box>
          )
        }
      ]}
    />
  );
};
