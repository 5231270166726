import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Typography, Collapse, Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import * as userApi from '../../../../../../api/users';
import { TextField } from '../../../../../../components/FormField';
import { Fab } from '../../../../../../components/Fab';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UsersSelect } from '../../../../../../components/users';
import { CreateBaseCustomerModal } from '../../../../Members/CreateBaseCustomerModal';
import { ApplicantInfo } from '../ApplicantInfo';

export const Applicant = ({ applicant = null }) => {
  const theme = useTheme();
  const { openModal } = useModal();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { values, setFieldValue } = useFormikContext();
  const [ user, setUser ] = useState(null);

  const fetchUser = (id) => {
    userApi.fetchUser(id).then((data) => {
      setUser(data);

      if (!values.occupation_group_number) {
        setFieldValue('occupation_group_number', data?.work?.position);
      }
    });
  };

  const handleApplicantChange = (user) => {
    if (user) {
      fetchUser(user?.id);
    } else {
      setFieldValue('applicant_id', null);
      setFieldValue('occupation_group_number', null);
    }
  };

  const openCreateCustomerModal = () => {
    openModal(CreateBaseCustomerModal, {
      onModalResolved: (user) => {
        setFieldValue('occupation_group_number', user?.work?.position);
        setFieldValue('applicant_id', user);
        setUser(user);
      }
    });
  };

  useEffect(() => {
    if (applicant) {
      fetchUser(applicant?.user?.id);
    }
  }, []);

  return (
    <Grid
      container
      spacing={2}
      component={Box}
      py={isMobile ? 2 : 5}
      px={isMobile ? 1 : 2}
      minHeight={200}
    >
      <Grid item xs={isMobile ? 6 : 2}>
        <Box color="info.main">
          <Typography variant="h6" color="inherit">
            Plaintiff
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={isMobile ? 6 : 8}>
        {openCreateCustomerModal &&
          <Fab
            variant="extended"
            color="primary"
            onClick={openCreateCustomerModal}
          >
            Create customer
          </Fab>
        }
      </Grid>

      <Grid item xs={isMobile ? 12 : 7}>
        <UsersSelect
          name="applicant_id"
          label="Name"
          placeholder="Select name..."
          onChange={handleApplicantChange}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <TextField
          name="occupation_group_number"
          label="Occupation & Group number"
          placeholder="Enter occupation & group number..."
        />
      </Grid>

      <Grid item xs={12}>
        <Collapse in={!!values.applicant_id}>
          {values.applicant_id && <ApplicantInfo user={user} />}
        </Collapse>
      </Grid>
    </Grid>
  );
};
