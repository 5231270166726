import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as codesApi from '../../../../../../api/codes/procedures';
import { TextField } from '../../../../../../components/FormField';
import {
  ModalBody,
  ModalFooter,
  ModalHeader
} from '../../../../../../components/Modal';
import { Loader } from '../../../../../../components/Loader';
import { setCPTCodesLastGlobalAction } from '../../../../../../store/globalActions';
import * as types from '../CodesContext/types';
import { validationSchema } from './validationSchema';

export const CreateCodeCPTModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    codesContext
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();

  const createProcedureCode = (code, { isSubmitting, setErrors, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    codesApi.createCustomCode(code).then((code) => {
      handleModalResolve(code);

      if (codesContext.codes.length + 1 > codesContext.filter.per_page) {
        codesContext.fetchCodes({ page: codesContext.filter.page });
      } else {
        dispatchRedux(setCPTCodesLastGlobalAction({ type: types.ADD_CUSTOM_CODE, payload: code }));
      }

      enqueueSnackbar('Code successfully created', { variant: 'success' });
    }).catch(({ errors, message }) => {
      setSubmitting(false);

      if (errors) {
        errors && setErrors(errors);
        message && enqueueSnackbar(message, { variant: 'error' });
      }
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          procedure_code: null,
          description: null
        }}
        validationSchema={validationSchema}
        onSubmit={createProcedureCode}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Add Custom CPT
            </ModalHeader>

            <ModalBody>
              <TextField
                required
                autoFocus
                autoComplete="procedure_code"
                name="procedure_code"
                label="Procedure Code"
                placeholder="Enter code..."
                margin="dense"
              />

              <TextField
                required
                multiline
                name="description"
                label="Description"
                placeholder="Enter description..."
                variant="filled"
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
