import { Box, Link, Typography } from '@material-ui/core';
import {
  PreviewInvoiceModal
} from '../../../app/Dashboard/CasesPage/Cases/CaseItem/TabsContent/Billing/PreviewInvoiceModal';
import { useModal } from '../../ModalsProvider';
import { billingActivityLabelsMap } from '../activitiesHelpers/billingActivityMap';
import { WidgetActivity } from './WidgetActivity';
import { invoiceFieldsMap } from './fieldsMaps';

export const Invoice = ({
  isDeleted = false,
  isNew = false,
  payload
}) => {
  const { openModal } = useModal();
  const label = billingActivityLabelsMap?.[payload?.invoice?.model_type]?.label || 'Invoice';

  const openInvoiceViewModal = (id) => () => {
    openModal(PreviewInvoiceModal, {
      payload: {
        disableAction: true,
        invoiceID: id
      }
    });
  };

  if (isDeleted) {
    return (
      <Box component="span" display="flex" alignItems="center">
        Removed invoice
        &nbsp;
        {label}
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box my={0.5} display="flex" alignItems="center">
        {!!payload?.invoice?.id ? (
          <Link variant="h5" onClick={openInvoiceViewModal(payload?.invoice?.id)}>
            {label}
          </Link>
        ) : (
          <Typography variant="h5" color="secondary">{label}</Typography>
        )}
      </Box>

      {isNew && (
        <Box component="span" display="flex" alignItems="center">
          Added new invoice
        </Box>
      )}

      <WidgetActivity
        {...payload?.properties}
        fieldLabel={label}
        activityLabel={label}
        fieldsMap={invoiceFieldsMap}
      />
    </Box>
  );
};
