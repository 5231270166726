import { useSelector } from 'react-redux';
import { ContentCard } from '../../../../../../../components/ContentCard';
import {
  MedicalHistoryContent,
  MedicalHistoryProvider
} from '../../../../../../../components/Widgets';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';

export const MedicalHistory = ({
  isOpen,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { user } = useSelector(({ profile }) => profile);

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      isResizable={isOpen}
      title="Medical History"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <MedicalHistoryProvider userID={user?.id}>
        <MedicalHistoryContent height={height} />
      </MedicalHistoryProvider>
    </ContentCard>
  );
};
