import { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { FilesFilterContext, TagsProvider } from '../../../files-common';
import { Group } from './Group';
import { TagsList } from './TagsList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Groups = ({ hiddenColumns }) => {
  const classes = useStyles();
  const { filter, relationsForFilter } = useContext(FilesFilterContext);
  const [ expandedTagID, setExpandedTagID ] = useState();

  const handleChange = (tagID = null, isExpanded) => {
    setExpandedTagID(isExpanded ? tagID : false);
  };

  useEffect(() => {
    if (filter.tags?.indexOf(expandedTagID) === -1) {
      setExpandedTagID(filter.tags[0]);
    }
  }, [ filter.tags ]);

  return (
    !relationsForFilter.tags?.length ? (
      <TagsProvider filter={{ ...filter, only_with_files: 1 }}>
        <TagsList
          GroupProps={{
            hiddenColumns,
            expandedTagID: expandedTagID,
            onSelectedPanelChange: handleChange
          }}
        />
      </TagsProvider>
    ) : (
      <div className={classes.root}>
        {relationsForFilter.tags.map((tag) => (
          <Group
            isLoaded
            key={tag.id}
            item={tag}
            hiddenColumns={hiddenColumns}
            expandedTagID={expandedTagID}
            onSelectedPanelChange={handleChange}
          />
        ))}
      </div>
    )
  );
};
