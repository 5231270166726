import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { formTypesMap } from '../../../../../../components/medical/forms';
import { InsurancesSelect, TextField } from '../../../../../../components/FormField';
import { UserContext } from '../../../../Members/User/UserProvider';

export const InsurancesInfo = () => {
  const { values, setFieldValue } = useFormikContext();
  const currentUser = useSelector(({ profile }) => profile.user);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : currentUser;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleInsuranceChange = (name) => (insurance) => {
    if (insurance) {
      if (name === 'primary_insurance_id') {
        setFieldValue('primary_insuranceId', insurance?.insuranceId || null);
        setFieldValue('groupID', insurance?.groupId || null);
      } else {
        setFieldValue('secondary_insuranceId', insurance?.insuranceId || null);
      }
    } else {
      if (name === 'primary_insurance_id') {
        setFieldValue('primary_insuranceId', null);
        setFieldValue('groupID', null);
      } else {
        setFieldValue('secondary_insuranceId', null);
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" p={isSmallScreen ? 1 : 2} overflow="hidden">
      <Grid
        container
        spacing={isSmallScreen ? 2 : 3}
        component={Box}
        alignItems="center"
        justify="flex-start"
        pt={2}
        m="0 !important"
      >
        <Grid item xs={12}>
          <Typography variant="h4" color="secondary">
            Insurances Info
          </Typography>
        </Grid>

        {values?.parent?.form_type === formTypesMap.private ? (
          <>
            <Grid item md={4} xs={11}>
              <InsurancesSelect
                isCreatable
                name="primary_insurance_id"
                label="Primary Insurance"
                user={user}
                params={{
                  status_type: 'primary',
                  only_active: 1,
                  medical_id: user?.medical?.id || null
                }}
                onChange={handleInsuranceChange('primary_insurance_id')}
              />
            </Grid>

            <Grid item md={4} xs={5}>
              <TextField
                isEditable={false}
                name="primary_insuranceId"
                label="Insurance ID #"
              />
            </Grid>

            <Grid item md={4} xs={5}>
              <TextField
                isEditable={false}
                name="groupID"
                label="Group ID #"
              />
            </Grid>

            <Grid item md={4} xs={11}>
              <InsurancesSelect
                isCreatable
                name="secondary_insurance_id"
                label="Secondary Insurance"
                user={user}
                params={{
                  status_type: 'secondary',
                  medical_id: user?.medical?.id || null
                }}
                onChange={handleInsuranceChange('secondary_insurance_id')}
              />
            </Grid>

            <Grid item md={4} xs={5}>
              <TextField
                isEditable={false}
                name="secondary_insuranceId"
                label="Insurance ID #"
              />
            </Grid>
          </>
        ) : (
          <Grid item md={4} xs={11}>
            <InsurancesSelect
              isCreatable
              name="primary_insurance_id"
              label="Law Office"
              user={user}
              params={{
                status_type: 'primary',
                only_active: 1,
                medical_id: user?.medical?.id || null
              }}
              onChange={handleInsuranceChange('primary_insurance_id')}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
