export const providerTypeMap = [
  {
    value: 'Physician',
    label: 'Physician'
  },
  {
    value: 'RN',
    label: 'RN'
  },
  {
    value: 'PA-C',
    label: 'PA-C'
  }
];
