import { SvgIcon } from '@material-ui/core';
// TODO: for future settings
// import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import { mdiCalendarMonthOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import ProcedureCodesSvg from '../../../components/icons/procedure-codes.svg';
import { CodesProcedure } from './CodesProcedure';
import { Appointment } from './Appointment';

export const routes = [
  // TODO: for future settings
  // {
  //   path: '/codes/dashboard',
  //   icon: <DashboardIcon />,
  //   label: 'Dashboard',
  //   component: () => 'Dashboard'
  // },
  {
    path: '/codes/procedure-codes',
    icon: (
      <SvgIcon>
        <ProcedureCodesSvg />
      </SvgIcon>
    ),
    label: 'Practice Codes',
    component: CodesProcedure
  },
  {
    path: '/codes/appointments',
    icon: (
      <SvgIcon>
        <Icon path={mdiCalendarMonthOutline} />
      </SvgIcon>
    ),
    label: 'Appointment',
    component: () => (
      <Appointment baseUrl="/codes/appointments" />
    )
  }
];
