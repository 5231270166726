export const styles = ({
  spacing,
  palette: { grey, divider, ...palette },
  typography: { pxToRem, fontWeightMedium },
  transitions
}) => ({
  widgetArrow: {},

  popper: {
    '& $widgetArrow:before': {
      background: grey[600]
    }
  },

  root: {
    width: 380,
    maxHeight: 400,
    overflow: 'auto'
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 52,
    padding: spacing(1, 2),
    background: grey[600]
  },

  header__title: {
    flexGrow: 1,
    fontSize: pxToRem(16),
    marginRight: spacing(),
    color: palette.common.white
  },

  header__plusButton: {
    background: 'none',
    color: grey[300],
    border: '2px solid',

    '&:hover': {
      backgroundColor: grey[500]
    }
  },

  list: {
    overflow: 'auto'
  },

  file: {
    minHeight: 48,
    padding: spacing(0.5, 2, 0.5, 1),

    '& + &': {
      borderTop: `1px solid ${divider}`
    }
  },

  fileIcon: {
    fontSize: pxToRem(32)
  },

  fileName: {
    flexGrow: 1
  },

  fileStatus: {
    position: 'relative',
    display: 'flex',
    flexGrow: 0
  },

  fileStatus__container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: pxToRem(33),
    minHeight: pxToRem(33)
  },

  fileStatus__toggleUploadButton: {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 0,
    transition: transitions.create()
  },

  fileStatus__cancelUploadButton: {
    opacity: 0,

    '&:hover': {
      opacity: 1,

      '& + $fileStatus__progress': {
        opacity: 0
      }
    }
  },

  fileStatus__resultIcon: {
    fontSize: 36
  },

  fileStatus__progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 13,
    fontWeight: fontWeightMedium,
    transition: transitions.create()
  }
});
