export const INVOICES_FETCH_REQUEST = 'INVOICES_FETCH_REQUEST';
export const INVOICES_FETCH_SUCCESS = 'INVOICES_FETCH_SUCCESS';
export const ADD_INVOICE = 'ADD_INVOICE';
export const UPDATE_INVOICE_IN_LIST = 'UPDATE_INVOICE_IN_LIST';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const MASS_DELETE_INVOICES = 'MASS_DELETE_INVOICES';
export const APPLY_FILTER = 'APPLY_FILTER';
export const RESET_INVOICES = 'RESET_INVOICES';
export const TOGGLE_INVOICE_SELECTION = 'TOGGLE_INVOICE_SELECTION';
export const TOGGLE_ALL_INVOICES_SELECTION = 'TOGGLE_ALL_INVOICES_SELECTION';
export const CONFIRM_INVOICE = 'CONFIRM_INVOICE';
export const MASS_CONFIRM_INVOICE = 'MASS_CONFIRM_INVOICE';
export const CANCEL_INVOICE = 'CANCEL_INVOICE';
export const MASS_CANCEL_INVOICE = 'MASS_CANCEL_INVOICE';
