import { Yup } from '../../../utils/validation';

export const validationSchema = Yup.object().shape({
  bill_from: Yup.object().nullable().shape({
    type: Yup.string().nullable().required(),
    id: Yup.mixed().nullable()
  }),
  bill_to: Yup.object().nullable().shape({
    type: Yup.string().nullable().required(),
    id: Yup.mixed().nullable()
  }),
  note: Yup.string().nullable().max(1000),
  date: Yup.mixed().nullable().required(),
  due_date: Yup.mixed().nullable(),
  method: Yup.mixed().when('amount', {
    is: (value) => value,
    then: Yup.mixed().required()
  }),
  amount: Yup.mixed().when('method', {
    is: (value) => value,
    then: Yup.mixed().required()
  }),
  number: Yup.string().nullable()
}, [ 'amount', 'method' ]);
