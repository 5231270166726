import { Paper } from '@material-ui/core';
import { useContext } from 'react';
import { ListRow, ListRowCell } from '../../../../../ListRow';
import { CaseRemindersContext } from '../../CaseRemindersProvider';
import { columnsMap, columnsWidths } from '../CaseRemindersList';

export const TableHeader = ({ ListRowProps = {}, hiddenColumns = [] }) => {
  const isGroupHidden = hiddenColumns.includes(columnsMap.group);
  const { caseId } = useContext(CaseRemindersContext);

  return (
    <Paper elevation={0}>
      <ListRow header {...ListRowProps}>
        <ListRowCell flexBasis={columnsWidths.eventNumber}>
          Event #
        </ListRowCell>

        {!isGroupHidden &&
          <ListRowCell flexBasis={columnsWidths.group}>
            Group
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.type}>
          Type
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.note}>
          Description
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.date}>
          Date
        </ListRowCell>

        {!caseId &&
          <ListRowCell flexBasis={columnsWidths.caseItem}>
            Case
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.relative}>
          Relative
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.settings}>
          Sent
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.active}>
          Active
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.done}>
          Done
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.edit} />
      </ListRow>
    </Paper>
  );
};
