import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  InfiniteLoader,
  List,
  WindowScroller,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer
} from 'react-virtualized';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { usePrevious } from '../../helpers/hooks';
import { ErrorBoundary } from '../ErrorBoundary';

const Row = ({ RowComponent, RowComponentProps, itemKey, style, ...props }) => (
  <div key={itemKey} style={style}>
    <ErrorBoundary>
      <RowComponent {...props} {...RowComponentProps} />
    </ErrorBoundary>
  </div>
);

const ListContainer = ({ listRef, ...rest }) => {
  return <List ref={listRef} {...rest} />;
};

const SortableList = SortableContainer(ListContainer);
const SortableRow = SortableElement(Row);

export const InfiniteListLoader = ({
  isSortable = false,
  items,
  isFetching,
  isFetched,
  pagination: { per_page, last_page, page },
  RowComponent,
  RowProps = {},
  onNeedNextPage,
  scrollElementRef,
  virtualListRef,
  minRowHeight,
  getItemKey = (item) => item?.id,

  ...props
}) => {
  const VirtualList = isSortable ? SortableList : List;
  const ListRow = isSortable ? SortableRow : Row;
  const [ listWidth, setListWidth ] = useState();
  const prevListWidth = usePrevious(listWidth);
  const hasNextPage = page < last_page;
  const rowCount = hasNextPage ? items.length + 1 : items.length;
  const isRowLoaded = ({ index }) => !hasNextPage || index < items.length;
  const cache = useMemo(() => {
    return new CellMeasurerCache({
      defaultHeight: minRowHeight,
      fixedWidth: true
    });
  }, [ minRowHeight, CellMeasurerCache ]);

  const loadMoreRows = ({ stopIndex }) => {
    if (isFetched && !isFetching) {
      const awayFromBottom = items.length - stopIndex;

      if (awayFromBottom < per_page) {
        onNeedNextPage();
      }
    }
  };

  const handleResize = ({ width }) => {
    setListWidth(width);
  };

  const rowRenderer = useCallback(({ index, key, style, parent, isScrolling }) => {
    const item = items[index];

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => (
          <ListRow
            itemKey={getItemKey(item) || key}
            index={index}
            style={{ ...style, overflow: 'hidden' }}
            RowComponent={RowComponent}
            item={item}
            isLoaded={!!item}
            isScrolling={isScrolling}
            recalculateHeight={measure}
            cache={cache}
            virtualListRef={virtualListRef}

            {...RowProps}
          />
        )}
      </CellMeasurer>
    );
  }, [ items, RowProps ]);

  useEffect(() => {
    if (prevListWidth && prevListWidth !== listWidth) {
      cache.clearAll();
    }
  }, [ listWidth ]);

  return (
    <InfiniteLoader
      rowCount={rowCount}
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
    >
      {({ onRowsRendered }) => (
        <WindowScroller scrollElement={scrollElementRef.current}>
          {({ height = 0, isScrolling, scrollTop, onChildScroll }) => (
            <AutoSizer disableHeight onResize={handleResize}>
              {({ width }) => (
                <VirtualList
                  autoHeight
                  ref={virtualListRef}
                  isScrolling={isScrolling}
                  scrollTop={scrollTop}
                  scrollToAlignment="start"
                  onScroll={onChildScroll}
                  height={height}
                  width={width}
                  rowCount={rowCount}
                  onRowsRendered={onRowsRendered}
                  rowRenderer={rowRenderer}
                  deferredMeasurementCache={cache}
                  rowHeight={cache.rowHeight}
                  {...props}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
  );
};
