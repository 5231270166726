import { useEffect, useState } from 'react';
import cn from 'classnames';
import { get } from 'lodash';
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { NestedList } from '../../../../../../../components/NestedList';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { painMap, painResultMap } from '../painMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const NeckPain = ({ isOpenSwitches, form }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [ isOpen, setIsOpen ] = useState(false);
  const experiences = Object.keys(get(form, 'forms.pain_in_neck.experience_following', {}));
  const resultsOfInjury = Object.keys(get(form, 'forms.pain_in_neck.experience_result_injury', {}));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(form?.forms?.pain_in_neck) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="If pain in neck"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box p={isSmallScreen ? 1 : 3}>
        {form?.forms?.pain_in_neck?.your_dominant_hand && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>Which is your dominant hand?</Typography>

            <ReadonlyCheckbox
              title={form?.forms?.pain_in_neck?.your_dominant_hand}
              checked={!!form?.forms?.pain_in_neck?.your_dominant_hand}
              className={classes.contentInfoTitle}
            />
          </div>
        )}

        <div className={classes.content}>
          <Typography className={classes.formContent}>Where is your pain?</Typography>

          <Box display="flex" alignItems="center" my={1}>
            {form?.forms?.pain_in_neck?.where_your_pain?.parts?.neck && (
              <ReadonlyCheckbox
                title="Neck"
                checked={form?.forms?.pain_in_neck?.where_your_pain?.parts?.neck}
                className={classes.contentInfoTitle}
              />
            )}

            {form?.forms?.pain_in_neck?.where_your_pain?.parts?.hand && (
              <ReadonlyCheckbox
                title="Hand"
                checked={form?.forms?.pain_in_neck?.where_your_pain?.parts?.hand}
                className={classes.contentInfoTitle}
              />
            )}

            {form?.forms?.pain_in_neck?.where_your_pain?.parts?.other && (
              <ReadonlyCheckbox
                title="Other"
                checked={form?.forms?.pain_in_neck?.where_your_pain?.parts?.other}
                className={classes.contentInfoTitle}
              />
            )}

            {form?.forms?.pain_in_neck?.where_your_pain?.parts?.shoulder && (
              <ReadonlyCheckbox
                title="Shoulder"
                checked={form?.forms?.pain_in_neck?.where_your_pain?.parts?.shoulder}
                className={classes.contentInfoTitle}
              />
            )}

            {form?.forms?.pain_in_neck?.where_your_pain?.parts?.arms && (
              <ReadonlyCheckbox
                title="Arms"
                checked={form?.forms?.pain_in_neck?.where_your_pain?.parts?.arms}
                className={classes.contentInfoTitle}
              />
            )}
          </Box>

          {form?.forms?.pain_in_neck?.where_your_pain?.description &&
            <Typography color="textSecondary">
              {form?.forms?.pain_in_neck?.where_your_pain?.description}
            </Typography>
          }
        </div>

        <div className={cn(classes.content, classes.content_row)}>
          <Typography className={classes.formContent}>
            How often is your pain?
          </Typography>

          {!!form?.forms?.pain_in_neck?.how_often_your_pain && (
            <ReadonlyCheckbox
              checked
              title={form?.forms?.pain_in_neck?.how_often_your_pain}
              className={classes.contentInfoTitle}
            />
          )}
        </div>

        <div className={cn(classes.content, classes.content_row)}>
          <Typography className={classes.formContent}>
            What is the severity of your pain?
          </Typography>

          {!!form?.forms?.pain_in_neck?.what_severity_your_pain && (
            <ReadonlyCheckbox
              checked
              title={form?.forms?.pain_in_neck?.what_severity_your_pain}
              className={classes.contentInfoTitle}
            />
          )}
        </div>

        {form?.forms?.pain_in_neck?.your_pain_radiate_other_parts && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Does your pain radiate to other parts of your body?
            </Typography>

            <ReadonlyCheckbox
              checked
              title={form?.forms?.pain_in_neck?.your_pain_radiate_other_parts > 0 ? 'Yes' : 'No'}
            />
          </div>
        )}

        <div className={cn(classes.content, classes.content_row)}>
          <Typography className={classes.formContent}>
            If yes, how often?
          </Typography>

          {!!form?.forms?.pain_in_neck?.if_yes_how_often && (
            <ReadonlyCheckbox
              checked
              title={form?.forms?.pain_in_neck?.if_yes_how_often}
              className={classes.contentInfoTitle}
            />
          )}
        </div>

        <div className={cn(classes.content, classes.content_row)}>
          <Typography className={classes.formContent}>
            Where does your pain radiate to?
          </Typography>

          <div className={classes.contentInfo}>
            {!!form?.forms?.pain_in_neck?.where_pain_radiate_too?.forearm && (
              <ReadonlyCheckbox
                checked
                title="Forearm"
                className={classes.contentInfoTitle}
                rootClassName={classes.checkboxContent}
              />
            )}

            {!!form?.forms?.pain_in_neck?.where_pain_radiate_too?.hand && (
              <ReadonlyCheckbox
                checked
                title="Hand"
                className={classes.contentInfoTitle}
                rootClassName={classes.checkboxContent}
              />
            )}

            {!!form?.forms?.pain_in_neck?.where_pain_radiate_too?.fingers && (
              <ReadonlyCheckbox
                checked
                title="Fingers"
                className={classes.contentInfoTitle}
                rootClassName={classes.checkboxContent}
              />
            )}
          </div>
        </div>

        {!!form?.forms?.pain_in_neck?.which_side_body_pain_radiate &&
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Which side of body does the pain radiate to?
            </Typography>

            <ReadonlyCheckbox
              checked
              title={form?.forms?.pain_in_neck?.which_side_body_pain_radiate}
              className={classes.contentInfoTitle}
            />
          </div>
        }

        {!!experiences.length && (
          <div className={classes.content}>
            <Typography className={cn(classes.formContent, classes.formContent__title)}>
              Do you experience any of the following?
            </Typography>

            {experiences.map((item, index) => (
              <Grid key={index} container spacing={2}>
                <Grid item xs={3}>
                  <ReadonlyCheckbox
                    checked={form?.forms?.pain_in_neck?.experience_following?.[item]?.value}
                    title={painMap[item]}
                  />
                </Grid>

                {!!form?.forms?.pain_in_neck?.experience_following?.[item]?.description &&
                  <Grid container item sm={6} xs={12} alignItems="flex-start">
                    <Grid item>
                      <div className={classes.noteIcon}>
                        <NotesIcon fontSize="small" color="inherit" /> Note
                      </div>
                    </Grid>

                    <Grid item xs={10}>
                      <Typography color="textSecondary">
                        {form?.forms?.pain_in_neck?.experience_following?.[item]?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              </Grid>
            ))}
          </div>
        )}

        {!!form?.forms?.pain_in_neck?.symptoms_increase_while_twisting &&
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Do your symptoms increase while twisting, turning,
              extending or flexing the neck or keeping it in an awkward or prolonged position?
            </Typography>

            <ReadonlyCheckbox
              checked
              title={form?.forms?.pain_in_neck?.symptoms_increase_while_twisting}
              className={classes.contentInfoTitle}
            />
          </div>
        }

        {!!resultsOfInjury.length && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Do you experience any of the following as a result of your injury?
            </Typography>

            {resultsOfInjury.map((item, index) => (
              form?.forms?.pain_in_neck?.experience_result_injury?.[item] > 0 && (
                <Box key={index} mr={2}>
                  <ReadonlyCheckbox
                    checked
                    title={painResultMap[item]}
                    rootClassName={classes.checkboxContent}
                  />
                </Box>
              )))}
          </div>
        )}

        {!!form?.forms?.pain_in_neck?.symptoms_increase_with_activity && (
          <div className={cn(classes.content, classes.content_row)}>
            <Typography className={classes.formContent}>
              Do your symptoms increase with activity involving the use of muscles in the neck,
              vigorous activity, bending over or by the cold environment?
            </Typography>

            <ReadonlyCheckbox
              checked
              title={form?.forms?.pain_in_neck?.symptoms_increase_with_activity}
              className={classes.contentInfoTitle}
            />
          </div>
        )}
      </Box>
    </NestedList>
  );
};
