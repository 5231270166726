import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { flow } from 'lodash';
import { makeStyles, List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { mdiEmailArrowRightOutline } from '@mdi/js';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { Icon } from '@mdi/react';
import * as resetApi from '../../../../../../api/auth';
import { itemsRoutesByModels } from '../../../../../../dataMaps/apiModels';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { useChatActions } from '../../../../../../utils/useChatActions';
import { hasRole } from '../../../../../../utils/hasRole';
import ChatSquaredSvg from '../../../../../../components/icons/chat-squared.svg';
import { UpdateSipUserModal } from '../../../../../../components/users';
import { useModal } from '../../../../../../components/ModalsProvider';
import { SvgIcon } from '../../../../../../components/SvgIcon';
import { PatientsContext } from '../../../PatientsProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PatientsMenu = ({ user, onClose = () => {} }) => {
  const classes = useStyles();
  const history = useHistory();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { openChatWithUser } = useChatActions();
  const { updateSipUserInfo, deleteUser } = useContext(PatientsContext);

  const handleDeleteUser = () => {
    deleteUser(user);
  };

  const getUserPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.patient(user.id) + '/medical-info/medical-forms');
    }
  };

  const updateSIPUser = () => {
    openModal(UpdateSipUserModal, {
      payload: { user },
      onModalResolved: (sipUser) => {
        updateSipUserInfo({ ...user, sipUser });
      }
    });
  };

  const resendRegister = () => {
    resetApi.resendRegister(user.id).then(() => {
      enqueueSnackbar('Successfully sent', { variant: 'success' });
      onClose();
    }).catch((errors) => {
      if (errors) {
        enqueueSnackbar('Invitation not sent', { variant: 'error' });
      }
    });
  };

  const startChatWithUser = () => {
    openChatWithUser(user?.id);
  };

  return (
    <List className={classes.root}>
      <MenuItem onClick={getUserPage}>
        <ListItemIcon>
          <EyeIcon />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {hasRole(rolesMap.admin, rolesMap.supervisor) && (
        <MenuItem onClick={updateSIPUser}>
          <ListItemIcon>
            {user?.sipUser ? <EditIcon color="primary" /> : <AddIcon color="primary" />}
          </ListItemIcon>

          <ListItemText primary={user?.sipUser ? 'Update SIP User' : 'Add SIP User'} />
        </MenuItem>
      )}

      <MenuItem button onClick={flow(startChatWithUser, onClose)}>
        <ListItemIcon>
          <SvgIcon><ChatSquaredSvg /></SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Send Message" />
      </MenuItem>

      {(user.hasOwnProperty('is_confirm') && !user.is_confirm) &&
        <MenuItem onClick={resendRegister}>
          <ListItemIcon>
            <SvgIcon color="secondary">
              <Icon path={mdiEmailArrowRightOutline} />
            </SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Resend register email" />
        </MenuItem>
      }

      {!hasRole('client', 'patient', 'secretary', 'advocate') &&
        <MenuItem onClick={handleDeleteUser}>
          <ListItemIcon>
            <SvgIcon color="error"><DeleteIcon /></SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      }
    </List>
  );
};
