import { useSnackbar } from 'notistack';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as insuranceApi from '../../../../../api/profile/insurances';
import { AddInsuranceForm } from './AddInsuranceForm';
import { getInitialValues } from './getInitialValues';
import { getFormData } from './getFormData';

export const AddInsuranceInfoModal = ({
  DialogProps,
  payload: {
    mainPage = false,
    caseItem = null,
    user = null,
    insuranceInfo = {},
    status_type = '',
    disableScan = false,
    type_insurance = null,
    medicalFormID = null,
    medical_id = null,
    onCaseItemUpdate = () => {},
    onEdit = () => {}
  },
  handleModalReject,
  handleModalResolve
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const addInsurance = ({ caseItem = null, ...values }, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    if (values.subscriber_info.gender) {
      values.subscriber_info.gender = values.subscriber_info?.gender?.value || values.subscriber_info?.gender || null;
    }

    values.insuranceInfo = null;
    values.insurance_company_id = values?.insurance_company_id?.id || values?.insurance_company_id;

    if (values?.medical_id) {
      values.medical_id = values?.medical_id?.medical_id || values?.medical_id?.id || null;
    }

    if (values?.case_user_id?.user?.id || values?.case_user_id?.id) {
      values.case_user_id = values?.case_user_id?.user?.id || values?.case_user_id?.id || null;
    }

    const formData = getFormData(values);

    return insuranceApi.createInsurance(formData).then((data) => {
      enqueueSnackbar('Insurance info added successfully', { variant: 'success' });
      onEdit(data);
      handleModalResolve(data);
    }).catch(() => {
      enqueueSnackbar('Insurance info not added', { variant: 'error' });
    });
  };

  return (
    <Dialog maxWidth="md" fullScreen={isMobile} {...DialogProps}>
      <AddInsuranceForm
        mainPage={mainPage}
        disableScan={disableScan}
        initialValues={
          getInitialValues({
            user,
            medical_id,
            status_type,
            insuranceInfo,
            medicalFormID,
            type_insurance,
            caseItem
          })
        }
        user={user}
        insuranceInfo={insuranceInfo}
        addInsurance={addInsurance}
        onClose={handleModalReject}
        onCaseItemUpdate={onCaseItemUpdate}
      />
    </Dialog>
  );
};
