import { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { NestedList } from '../../../../../NestedList';

export const MedicalCausation = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Medical Causation"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box p={3}>
        <Box mb={3}>
          <Typography dangerouslySetInnerHTML={{ __html: report?.text || 'No info' }} />
        </Box>
      </Box>
    </NestedList>
  );
};
