import { useContext } from 'react';
import { Formik } from 'formik';
import { Box, Button } from '@material-ui/core';
import { Loader } from '../../../../../../components/Loader';
import { ReportsContext } from '../../../../../../components/medical/reports/diagnosis/ReportsProvider';
import { validationSchema } from '../validationSchema';
import { StepContext } from '../StepProvider';
import { Body } from './Body';

export const SecondStep = () => {
  const { formValues, handleUpdateForm, handleBack } = useContext(StepContext);
  const { doi } = useContext(ReportsContext);

  const updateForm = (values, { isSubmitting, setErrors }) => {
    handleUpdateForm({ values, isSubmitting, setErrors });
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        ...formValues,
        forms: {
          ...formValues?.forms,
          physical_examination: {
            ...formValues?.forms?.physical_examination,

            general_appearance: formValues?.forms?.physical_examination?.general_appearance || null
          }
        },
        doi
      }}
      validationSchema={validationSchema}
      onSubmit={updateForm}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Box
          noValidate
          component="form"
          display="flex"
          flexDirection="column"
          onSubmit={handleSubmit}
          height="100%"
        >
          <Body />

          <Box display="flex" alignItems="center" ml="auto" pt={2} pb={2}>
            <Box mr={2}>
              <Button
                color="primary"
                onClick={handleBack}
              >
                Back
              </Button>
            </Box>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  disabled={isSubmitting}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              )}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
};
