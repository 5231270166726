import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';
import * as userApi from '../../../../../../api/users';
import { Divider } from '../../../../../../components/Divider';
import {
  OfficesLocationSelect,
  KeyboardDatePicker
} from '../../../../../../components/FormField';
import { Tooltip } from '../../../../../../components/Tooltip';
import { UsersSelect } from '../../../../../../components/users';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';

export const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { values, setFieldValue } = useFormikContext();
  const [ officesOptions, setOfficesOptions ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ officePayload, setOfficePaylod ] = useState({});

  const fetchUser = (id) => {
    if (!id) return;

    setIsLoading(true);

    userApi.fetchUser(id).then((data) => {
      const options = data?.work?.offices;

      setFieldValue('company_id', data?.work?.company_id || null);
      setFieldValue('office_id', options?.[0] || null);
      setOfficesOptions(options);

      setOfficePaylod({
        company: data?.work?.company,
        attorneyInfo: {
          ...data,
          position: 'Guardian',
          attorney_email: data?.email || null,
          attorney_phone: data?.phone_number || data?.mobile_phone_number || null
        }
      });
      setIsLoading(false);
    });
  };

  const handleOfficeChange = (office) => {
    if (office) {
      const isExist = officesOptions?.find(({ id }) => office?.id === id);

      if (!isExist) {
        setOfficesOptions([ ...officesOptions, office ]);
      }
    }
  };

  const handleChangeGuardian = (user) => {
    if (user) {
      fetchUser(user.id);
    } else {
      setOfficesOptions([]);
      setFieldValue('office_id', null);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!values.office_id && values.heading_attorney_id?.value) {
      fetchUser(values?.heading_attorney_id?.value);
    }
  }, []);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justify="space-between"
      component={Box}
      px={isMobile ? 1 : 2}
    >
      <Grid item xs={isMobile ? 12 : 4}>
        <UsersSelect
          isCreatable
          name="heading_attorney_id"
          label="Heading Attorney"
          params={{ role: 'advocate' }}
          creatablePayload={{
            initialValues: {
              role: rolesMap.advocate
            }
          }}
          onChange={handleChangeGuardian}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 7}>
        <Tooltip isExistTooltip={!values?.heading_attorney_id} title="Choose a guardian first">
          <div>
            <OfficesLocationSelect
              isCreatable
              disabled={!values?.heading_attorney_id}
              isAsync={false}
              loading={isLoading}
              name="office_id"
              companyPayload={officePayload.company}
              attorneyInfo={officePayload.attorneyInfo}
              label="Assigned Office Location"
              options={officesOptions}
              onChange={handleOfficeChange}
            />
          </div>
        </Tooltip>
      </Grid>

      <Grid item xs={12}>
        <Divider type="dashed" />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <KeyboardDatePicker
          name="date_file_received"
          label="Date File Received"
          placeholder="Select a date"
        />
      </Grid>
    </Grid>
  );
};
