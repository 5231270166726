import { useContext, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { useResizeObserver } from '../../../../helpers/hooks';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { viewVariantsMap, LayoutContext } from '../../../../components/LayoutContext';
import { FilesContextProvider } from '../../files-common';
import { ActionsBar } from '../MyFilesPage/ActionsBar';
import { FiltersBar } from '../MyFilesPage/FiltersBar';
import { Groups } from '../MyFilesPage/Groups';
import { Grid } from '../MyFilesPage/Grid';
import { List } from '../MyFilesPage/List';
import { styles } from '../MyFilesPage/styles';

const useStyles = makeStyles(styles);

export const RecentFilesPage = ({ isOpenSidebar, filterKey, hiddenFields = [], toggleSideBar = () => {} }) => {
  const classes = useStyles();
  const { viewVariant } = useContext(LayoutContext);
  const theme = useTheme();
  const rootRef = useRef(null);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = width <= theme.breakpoints.values.mobileSm;

  return (
    <div ref={rootRef} className={classes.root}>
      <ActionsBar
        disableActions
        isOpenSidebar={isOpenSidebar}
        toggleSideBar={toggleSideBar}
        filterOpenButton={filterOpenButton}
      />

      <ScrollWrapper {...scrollWrapperProps}>
        <FilterBarWrapper {...filterBarWrapperProps}>
          <FiltersBar filterKey={filterKey} hiddenFields={hiddenFields} />
        </FilterBarWrapper>
      </ScrollWrapper>

      <div className={classes.filesWindow}>
        <div className={classes.filesWindow__main}>
          {!isMobile && (viewVariant === viewVariantsMap.groups) && <Groups />}

          {(viewVariant === viewVariantsMap.list) &&
            <FilesContextProvider
              initialState={{
                filter: {
                  only_opened: 1,
                  order_by: 'opened_at'
                }
              }}
            >
              <List />
            </FilesContextProvider>
          }

          {(viewVariant === viewVariantsMap.grid) &&
            <FilesContextProvider
              initialState={{
                filter: {
                  only_opened: 1,
                  order_by: 'opened_at'
                }
              }}
            >
              <Grid />
            </FilesContextProvider>
          }
        </div>
      </div>
    </div>
  );
};
