export const styles = ({ palette, transitions }) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    overflow: 'hidden'
  },

  avatar: {
    width: '100%',
    height: '100%',
    transition: transitions.create(),

    '&:hover': {
      filter: 'blur(3px)'
    }
  },

  actions: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    opacity: 0,
    background: palette.common.black,
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      opacity: 0.8
    }
  },

  actions__button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    opacity: 1,
    background: palette.common.black,
    color: palette.common.white,
    transition: transitions.create(),

    '&:hover': {
      background: palette.common.white,
      color: palette.primary.main
    }
  }
});
