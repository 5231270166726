import { Box, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { ReadonlyCheckbox } from '../../../ReadonlyCheckbox';
import { sufferingsMap, sufferingsLabelsMap } from './sufferingsMap';

export const Sufferings = ({ sufferings }) => {
  return isEmpty(sufferings) ? (
    <Typography color="textSecondary">No info.</Typography>
  ) : Object.entries(sufferings).map(([ suffering, { text, value } ], index) => !!sufferingsMap[suffering] && (
    <Box key={index} mb={2}>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="h5">{sufferingsLabelsMap[suffering]}</Typography>
      </Box>

      <Box display="flex" flexDirection="column">
        <ReadonlyCheckbox
          key={index}
          checked={value}
          title={text}
        />
      </Box>
    </Box>
  ));
};
