export const styles = ({ spacing, shape: { borderRadius } }) => ({
  root: {
    minWidth: '60%',
    minHeight: 200,
    margin: spacing(2),
    padding: spacing(3),
    overflow: 'auto',
    borderRadius
  }
});
