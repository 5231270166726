export const styles = ({ spacing, palette, zIndex }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    marginBottom: spacing(3),
    border: `1px dashed ${palette.divider}`
  },

  content: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2)
  },

  itemRoot: {
    backgroundColor: palette.common.white,
    zIndex: zIndex.modal
  },

  itemRoot_drag: {
    maxWidth: 35
  },

  itemRoot_dragHandle: {
    cursor: 'grabbing'
  },

  itemRoot_radioButton: {
    maxWidth: 40
  }
});
