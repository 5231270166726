import cn from 'classnames';
import { useContext, useState } from 'react';
import { Box, makeStyles, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useLocation } from 'react-router-dom';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { useEmailsModal } from '../../../../utils/hooks';
import { EmailsContent } from '../../../../components/Widgets';
import { Fab } from '../../../../components/Fab';
import { AccountsContext } from '../AccountsContext';
import { EmailsActions } from './EmailsActions';
import {
  SavedEmailsContext,
  SavedEmailsContextProvider
} from './SavedEmailsContext';
import { FiltersBar } from './FiltersBar';
import { LeftSidebar } from './LeftSidebar';
import { EmailsList } from './EmailsList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SavedEmails = ({ filter, caseItem, patientItem, hiddenFilterFields }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const showList = !!pathname.includes('emails/saved');
  const { onSendEmail } = useEmailsModal({ caseItem });
  const [ isOpenSidebar, setIsOpenSideBar ] = useState(true);
  const {
    openFiltersBar,
    FilterBarWrapper,
    filterBarWrapperProps,
    toggleFiltersBar,
    ScrollWrapper
  } = useMobileFilterCollapse();
  const { accounts } = useContext(AccountsContext);
  const scrollWrapperProps = !isMobile ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  const toggleSideBar = () => {
    setIsOpenSideBar((state) => !state);
  };

  return (
    <SavedEmailsContextProvider filter={filter} caseItem={caseItem} patientItem={patientItem}>
      <SavedEmailsContext.Consumer>
        {({ fetchSavedMessages }) => !isMobile || showList ? (
          <Paper elevation={2} className={classes.root}>
            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                <FiltersBar hiddenFilterFields={hiddenFilterFields} />
              </FilterBarWrapper>
            </ScrollWrapper>

            <div className={classes.content}>
              <div className={cn(classes.sidebar, { [classes.sidebarOpen]: isOpenSidebar })}>
                <LeftSidebar
                  isOpenSidebar={isOpenSidebar}
                  openFiltersBar={openFiltersBar}
                  onOpenFiltersBar={toggleFiltersBar}
                  onOpenSideBar={toggleSideBar}
                />
              </div>

              <div className={classes.main}>
                <EmailsActions
                  isOpenSidebar={isOpenSidebar}
                  onOpenSideBar={toggleSideBar}
                />

                <Box flexGrow={1}>
                  <EmailsList />
                </Box>
              </div>
            </div>
          </Paper>
        ) : (
          <EmailsContent>
            <Fab
              size="small"
              color="primary"
              variant="round"
              onClick={onSendEmail({ fetchSavedMessages, accounts })}
            >
              <AddIcon />
            </Fab>
          </EmailsContent>
        )}
      </SavedEmailsContext.Consumer>
    </SavedEmailsContextProvider>
  );
};
