import { useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Route } from '../../../components/router';
import { formComponentsByTypes } from './formComponentsByTypes';
import { FormSteps } from './FormSteps';
import { FormContext } from './FormProvider';

export const FormItemComponent = () => {
  const { type } = useContext(FormContext);

  return (
    <Switch>
      {type ?
        <Route
          exact
          path="/medical-forms/create"
          component={formComponentsByTypes[type]}
        />
        :
        <Route exact path="/medical-forms/create">
          <Redirect to="/" />
        </Route>
      }

      <Route
        exact
        path="/medical-forms/:medicalFormID/first-step"
        component={FormSteps}
      />

      <Route
        exact
        path="/medical-forms/:medicalFormID/second-step"
        component={FormSteps}
      />

      <Route
        exact
        path="/medical-forms/:medicalFormID/third-step"
        component={FormSteps}
      />

      <Route
        exact
        path="/medical-forms/:medicalFormID/fourth-step"
        component={FormSteps}
      />

      <Route
        exact
        path="/medical-forms/:medicalFormID/fifth-step"
        component={FormSteps}
      />

      <Route
        exact
        path="/medical-forms/:medicalFormID/six-step"
        component={FormSteps}
      />

      <Route
        exact
        path="/medical-forms/:medicalFormID/preview"
        component={FormSteps}
      />
    </Switch>
  );
};
