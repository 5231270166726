import { useContext } from 'react';
import { debounce } from 'lodash';
import { Formik } from 'formik';
import { Box, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { useModal } from '../../../../ModalsProvider';
import { AddChatModal } from '../../../AddChatModal';
import { MessengerContext } from '../../../MessengerProvider';
import { TextField } from '../../../../FormField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChatsNavigation = ({ isMinimized, toggleMinimized, onWidgetClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { openModal } = useModal();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { applyFilter } = useContext(MessengerContext);

  const handleSearchChange = debounce((event) => {
    applyFilter({ name: event.target.value });
  }, 600);

  const addChat = () => {
    openModal(AddChatModal);
  };

  return (
    <div className={classes.root}>
      {!isMobile &&
        <Box color={isMinimized ? 'primary.main' : 'grey.500'}>
          <IconButton color="inherit" size="small" onClick={toggleMinimized}>
            {isMinimized ? <ArrowBackIosOutlinedIcon /> : <ArrowForwardIosOutlinedIcon />}
          </IconButton>
        </Box>
      }

      {!isMinimized &&
        <Formik initialValues={{ name: null }} onSubmit={null}>
          <TextField
            fullWidth
            withoutFormik
            name="name"
            placeholder="Search chat..."
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <Box pr={0.5}>
                  <SearchIcon color="primary" />
                </Box>
              ),
              endAdornment: !hasRole(rolesMap.patient, rolesMap.client) && (
                <IconButton onClick={addChat}>
                  <GroupAddOutlinedIcon />
                </IconButton>
              )
            }}
            onChange={handleSearchChange}
          />
        </Formik>
      }

      <Box ml={isMobile ? 2 : 0}>
        <IconButton size="small" onClick={onWidgetClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </div>
  );
};
