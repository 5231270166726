import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAltOutlined';
import { useSelector } from 'react-redux';
import * as chatsApi from '../../../api/chat';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { Loader } from '../../Loader';
import { Form } from './Form';

export const UpdateChatMembersModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { thread }
}) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const isOwner = currentUser.id === thread.user?.id;

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return chatsApi.updateThread(values).then(() => {
      handleModalResolve(values);
    }).catch((err) => {
      handleModalReject(err);
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={thread}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<PeopleAltIcon />} onClose={handleModalReject}>
              Chat Members
            </ModalHeader>

            <ModalBody>
              <Form thread={thread} />
            </ModalBody>

            {isOwner &&
              <ModalFooter>
                <Button onClick={handleModalReject}>Cancel</Button>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  )}
                />
              </ModalFooter>
            }
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
