import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconComponent } from '../../../../../components/saved-filters';
import { UsersSelect } from '../../../../../components/users';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../../helpers/dates';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { KeyboardDatePicker, OfficesLocationSelect } from '../../../../../components/FormField';
import { saveFilter } from '../../../../../store/lastFilters';
import { List } from './List';
import { NotesContext } from '../NotesProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { initialValues } from './initialValues';

const MODAL_WIDTH = 870;

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const { applyFilter } = useContext(NotesContext);
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleDatePickerChange = (name, transformer) => (date) => {
    applyFilter({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleOfficeChange = (office) => {
    applyFilter({ office_id: office?.id });
    setRelationsForFilter((state) => ({ ...state, office_id: office }));
  };

  const handleUserSelect = (name) => (option) => {
    applyFilter({ [name]: option?.id });
    setRelationsForFilter((state) => ({ ...state, [name]: option }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);
    applyFilter({
      ...filter,

      doi_from: filter?.doi_from,
      doi_to: filter?.doi_to,
      dor_from: filter?.dor_from,
      dor_to: filter?.dor_to,
      provider_id: filter?.provider_id?.id,
      office_id: filter?.officeId?.id
    });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    applySavedFilter({
      ...relationsForFilter,
      ...lastFilters
    });
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      hiddenFields={hiddenFields}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      onReset={() => {
        applySavedFilter(initialValues);
      }}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.doiFrom,
          label: filterFieldsLabels[filterFieldsMap.doiFrom],
          field: (
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              minWidth={140}
              name="doi_from"
              label="DOI From"
              onChange={handleDatePickerChange('doi_from', unixToStartOfDayUnix)}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.doiTo,
          label: filterFieldsLabels[filterFieldsMap.doiTo],
          field: (
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              minWidth={140}
              name="doi_to"
              label="DOI To"
              onChange={handleDatePickerChange('doi_to', unixToEndOfDayUnix)}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.dorFrom,
          label: filterFieldsLabels[filterFieldsMap.dorFrom],
          field: (
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              minWidth={140}
              name="dor_from"
              label="DOR From"
              onChange={handleDatePickerChange('dor_from', unixToStartOfDayUnix)}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.dorTo,
          label: filterFieldsLabels[filterFieldsMap.dorTo],
          field: (
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              minWidth={140}
              name="dor_to"
              label="DOR To"
              onChange={handleDatePickerChange('dor_to', unixToEndOfDayUnix)}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.providerId,
          label: filterFieldsLabels[filterFieldsMap.providerId],
          field: (
            <UsersSelect
              name="provider_id"
              label="Provider"
              params={{ role: rolesMap.doctor }}
              onChange={handleUserSelect('provider_id')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.officeId,
          label: filterFieldsLabels[filterFieldsMap.officeId],
          field: (
            <OfficesLocationSelect
              name="office_id"
              onChange={handleOfficeChange}
            />
          )
        }
      ]}
    />
  );
};
