export const styles = ({ palette: { primary } }) => ({
  expensesFiles: {
    display: 'flex',
    alignItems: 'center',
    color: primary.main,
    cursor: 'pointer'
  },

  statusLabel: {
    color: primary.contrastText
  }
});
