import { Switch } from 'react-router-dom';
import { Route } from '../../../../components/router';
import { LayoutContextProvider } from '../../../../components/LayoutContext';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { CreateInvoice } from './CreateInvoice';
import { InvoicesPage } from './InvoicesPage';
import { InvoiceItem } from './InvoiceItem';
import { EditInvoice } from './EditInvoice';
import { InvoicesContextProvider } from './InvoicesContext';

export const Invoices = () => {
  return (
    <LayoutContextProvider>
      <InvoicesContextProvider>
        <Switch>
          <Route
            exact
            path="/billing/invoices"
            render={() => (
              <InvoicesPage
                invoicesFilterKey={filtersKeysMap.billing_invoices}
                expensesFilterKey={filtersKeysMap.billing_expenses}
              />
            )}
          />
          <Route exact path="/billing/invoices/create" component={CreateInvoice} />
          <Route exact path="/billing/invoices/edit/:id" component={EditInvoice} />
          <Route path="/billing/invoices/:id" component={InvoiceItem} />
        </Switch>
      </InvoicesContextProvider>
    </LayoutContextProvider>
  );
};
