import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { InfiniteListLoader } from '../../../../InfiniteLoader';
import { Loader } from '../../../../Loader';
import { Scrollbars } from '../../../../Scrollbars';
import { Sticky } from '../../../../Sticky';
import { PaymentsContext } from '../../PaymentsContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const widthBreakpointXXS = 650;
export const widthBreakpointXS = 800;
export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1400;
export const widthBreakpointLarge = 1600;
export const minRowHeight = 54;
export const columnsWidths = {
  paid_date: 100,
  payer: 170,
  number: 150,
  description: 150,
  due_on: 100,
  method: 170,
  total: 120,
  amount: 120,
  balance: 120,
  action: 70
};

export const List = () => {
  const {
    payments,
    isFetching,
    isFetched,
    meta,
    resetPayments,
    loadNextPage
  } = useContext(PaymentsContext);
  const scrollElementRef = useRef();
  const { width } = useResizeObserver({ ref: scrollElementRef });

  useEffect(() => {
    resetPayments();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !meta.total ? (
          <Box p={2}>
            <Typography align="center">No payments found</Typography>
          </Box>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader width={width} />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={payments}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                RowComponent={Row}
                RowProps={{
                  width
                }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
