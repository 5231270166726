import { api } from '../../api';

export const fetchCaseReminderEvents = (config = {}) => {
  return api.get('/case-reminders/events', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createCaseReminderEvent = (reminderEvent) => {
  return api.post('/case-reminders/events', reminderEvent)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchCaseReminderEvent = (id) => {
  return api.get(`/case-reminders/events/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateCaseReminderEvent = (reminderEvent) => {
  return api.put(`/case-reminders/events/${reminderEvent.id}`, reminderEvent)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteCaseReminderEvent = (id) => {
  return api.delete(`/case-reminders/events/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
