export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },

  scanner: {
    height: '100%',
    padding: spacing(2)
  },

  scanner__container: {
    position: 'relative',
    display: 'inline-block',
    height: '100%'
  },

  scanner__screen: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },

  appointments: {
    display: 'flex',
    flexDirection: 'column',
    width: 372,
    height: '100%',
    padding: spacing()
  }
});
