import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { ActivityContext } from '../../../../../../components/Activities/ActivityProvider';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../components/Loader';
import { Row } from '../Row';

export const minRowHeight = 54;

export const List = ({ disableReset = false, parent = null, parentType = null }) => {
  const {
    activities,
    isFetching,
    isFetched,
    filter: { per_page, last_page, page, total },
    resetActivities,
    loadNextPage
  } = useContext(ActivityContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    if (!disableReset) {
      resetActivities();
    }
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box pl={2} py={2}>
        <Loader loading={!isFetched} render={
          () => (
            <>
              {!activities.length && <Typography align="center">No activities found</Typography>}

              {(!!activities.length || total > 0) &&
                <MuiList disablePadding>
                  <InfiniteListLoader
                    items={activities}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    pagination={{ per_page, last_page, page }}
                    RowComponent={Row}
                    RowProps={{ parent, parentType }}
                    onNeedNextPage={loadNextPage}
                    scrollElementRef={scrollElementRef}
                    minRowHeight={minRowHeight}
                  />
                </MuiList>
              }
            </>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
