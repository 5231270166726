export const styles = ({
  spacing,
  palette: { grey, darkgreen, pink },
  typography: { pxToRem, fontWeightMedium }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    marginTop: spacing(),
    height: 135,
    border: `1px dashed ${grey[200]}`,
    backgroundColor: grey[25]
  },

  mainContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '50%'
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },

  balanceTotal: {
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(20),
    color: darkgreen
  },

  balanceTotal_red: {
    color: pink
  },

  balanceDue: {
    fontSize: pxToRem(12)
  },

  total: {
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(12)
  },

  dueDate: {
    color: grey[600]
  }
});
