import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { MobileWidgets } from '../../../../components/Widgets/MobileWidgets';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import * as pages from '../../../../store/dashboard/layout/pages';
import { hasRole } from '../../../../utils/hasRole';
import { checkedWidgetsTypes as clientCheckedWidgetsTypes } from '../MedicalInfo/MainInfo/Layouts';
import { widgetsMap as clientWidgetsMap } from './Widgets/clientWidgetsMap';
import { checkedMedicWidgetsTypes, checkedWidgetsTypes } from './Layouts';
import { ClientProfileGeneralInfo } from './ClientProfileGeneralInfo';
import { ProfileGeneralInfo } from './ProfileGeneralInfo';
import { widgetsMap } from './Widgets';

export const GeneralInfo = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isClient = hasRole(rolesMap.client, rolesMap.patient);
  const isMedic = hasRole(rolesMap.medic, rolesMap.doctor);
  const { user } = useSelector(({ profile }) => profile);
  const checkedWidgets = isMedic ? checkedMedicWidgetsTypes : checkedWidgetsTypes;
  const widgetsMedicMap = Object.keys(checkedMedicWidgetsTypes).reduce((object, key) => {
    if (!object[key]) {
      object[key] = widgetsMap[key];
    }

    return object;
  }, {});

  return isTablet ? (
    <MobileWidgets
      page={isClient ? pages.clientProfile : pages.profile}
      widgetsMap={isClient ? clientWidgetsMap : isMedic ? widgetsMedicMap : widgetsMap}
      payload={{ user }}
      checkedWidgetsTypes={isClient ? clientCheckedWidgetsTypes : checkedWidgets}
    />
  ) : (
    isClient ? <ClientProfileGeneralInfo /> : <ProfileGeneralInfo />
  );
};
