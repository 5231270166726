import { useFormikContext } from 'formik';
import {
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { RadioGroup, TextField } from '../../FormField';

export const SocialHistory = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { values } = useFormikContext();

  return (
    <Grid container spacing={isSmallScreen ? 2 : 3} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          SOCIAL HISTORY
        </Typography>
      </Grid>

      <Grid item md={3} xs={12}>
        <Typography variant="h5">Occupation:</Typography>
      </Grid>

      <Grid item md={9} xs={12}>
        <TextField
          fullWidth
          name="forms.past_medical_history.social_history.occupation"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <Typography variant="h5">Tobacco use:</Typography>
      </Grid>

      <Grid item md={9} xs={12}>
        <RadioGroup
          row
          name="forms.past_medical_history.social_history.tobacco_use"
        >
          <FormControlLabel
            value="everyday"
            label={<Typography>Everyday</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="some_days"
            label={<Typography>Some days</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="former_smoker"
            label={<Typography>Former smoker</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="never_smoke"
            label={<Typography>Never smoke</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="unsure_how_often"
            label={<Typography>Unsure how often</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="unknown"
            label={<Typography>Unknown</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item md={3} xs={12}>
        <Typography variant="h5">Alcohol Use (drinks per day):</Typography>
      </Grid>

      <Grid item md={9} xs={12}>
        <RadioGroup
          row
          name="forms.past_medical_history.social_history.alcohol_use"
        >
          <FormControlLabel
            value="6"
            label={<Typography>6 or more</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="4"
            label={<Typography>4-5</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="2"
            label={<Typography>2-3</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="1"
            label={<Typography>1</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="less"
            label={<Typography>Less than 1</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="not_drink"
            label={<Typography>Don’t drink</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item md={3} xs={12}>
        <Typography variant="h5">Recreational Drug Use:</Typography>
      </Grid>

      <Grid item md={9} xs={12}>
        <RadioGroup
          row
          name="forms.past_medical_history.social_history.recreational_drug_use"
        >
          <FormControlLabel
            value="yes"
            label={<Typography>Yes</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="no"
            label={<Typography>No</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      {values?.forms?.past_medical_history?.social_history?.recreational_drug_use === 'yes' && (
        <Grid item xs={12}>
          <Typography variant="h5">Frequency:</Typography>

          <TextField
            fullWidth
            multiline
            disableUnderline
            name="forms.past_medical_history.social_history.yes_frequency"
            placeholder="Please describe"
            margin="dense"
            variant="filled"
            rows={2}
          />
        </Grid>
      )}
    </Grid>
  );
};
