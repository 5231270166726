import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

const raiseThread = (state, updatedThread) => {
  const threadsWithoutUpdatedThread = state.threads.filter(({ id }) => id !== updatedThread.id);

  return {
    ...state,

    threads: updatedThread.is_archive !== !!state.filter.is_archive
      ? threadsWithoutUpdatedThread
      : [ updatedThread, ...threadsWithoutUpdatedThread ]
  };
};

export const reducer = createHookReducer({
  [types.THREADS_FETCH_REQUEST]: (state) => {
    return {
      ...state,

      isFetching: true
    };
  },

  [types.THREADS_FETCH_SUCCESS]: (
    { filter, threads, ...state },
    { data: newThreads, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      threads: page > 1 ? (
        threads.filter(({ id }) =>
          !newThreads.find((loadedThread) => id === loadedThread.id)).concat(newThreads)
      ) : (
        newThreads
      )
    };
  },

  [types.ADD_THREAD]: (state, thread) => {
    const { threads } = state;

    if (thread.is_archive && !state.filter.is_archive) {
      return state;
    }

    const existingThread = threads.find(({ id }) => id === thread.id);

    if (existingThread) {
      return raiseThread(state, thread);
    }

    return {
      ...state,

      threads: [ thread, ...threads ],
      pagination: {
        ...state.pagination,

        total: state.pagination.total + 1
      }
    };
  },

  [types.DELETE_THREAD]: ({ pagination, filter, threads, ...state }, deletedThread) => {
    const total = pagination.total - 1;
    const page = Math.ceil((threads.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      threads: threads.filter((thread) => thread.id !== deletedThread.id)
    };
  },

  [types.UPDATE_THREAD]: ({ threads, ...state }, updatedThread) => {
    return {
      ...state,

      threads: threads.map((thread) => updatedThread.id === thread.id ? updatedThread : thread)
    };
  },

  [types.MARK_THREAD_AS_SEEN]: ({ threads, ...state }, { threadId }) => {
    return {
      ...state,

      threads: threads.map((thread) => threadId === thread.id ? {
        ...thread,

        count_unread_messages: 0
      } : thread)
    };
  },

  [types.RESET_THREADS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.TOGGLE_THREAD_AS_FAVORITE]: ({ threads, ...state }, { thread_id, is_favorite }) => {
    return {
      ...state,

      threads: threads.map((thread) => {
        return thread_id === thread.id
          ? { ...thread, has_favorites: is_favorite }
          : thread;
      })
    };
  },

  [types.RAISE_THREAD]: raiseThread
});
