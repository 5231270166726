export const getHeightFormat = ({ isFeet }) => (val) => {
  const height = typeof val === 'number' ? `${val}` : val;

  if (!val || !height?.length) {
    return '0';
  }

  const newValue = height?.split(/[',".]/)?.join('');
  const feet = newValue.substring(0, 1);
  const inch = newValue.substring(1, isFeet ? 3 : height.length);

  if (isFeet) {
    return feet + '\'' + (inch.length ? inch + '"' : '');
  } else {
    return height.length > 1 ? feet + '.' + inch : feet;
  }
};
