export const styles = ({
  spacing,
  typography: { pxToRem }
}) => ({
  root: {
    marginTop: spacing(2)
  },

  loadMoreButton: {
    fontSize: pxToRem(12),
    marginLeft: spacing(-0.7),
    textTransform: 'capitalize'
  }
});
