import T from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Fab, Dialog, Typography, useMediaQuery } from '@material-ui/core';
import { ModalContainer, ModalHeader, ModalBody } from '../../../../../components/Modal';
import { workClockActionsMap } from '../WorkClock/workClockActionsMap';
import { workClockContentMap } from './workClockContentMap';

const propTypes = {
  type: T.string,
  title: T.string,
  buttonTitle: T.string
};

export const WorkClockConfirmationModal = ({
  DialogProps,
  payload: {
    buttonTitle,
    type = workClockActionsMap.work,
    title = '',
    maxWidth = 'xs'
  },
  handleModalReject,
  handleModalResolve
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const ContentIcon = workClockContentMap?.[type]?.icon;
  const content = workClockContentMap?.[type]?.content;
  const workClock = useSelector(({ workClock }) => workClock.workClock);
  const activityTypeTime = workClock?.time + workClock?.session?.time_info?.[workClock?.session?.active_track?.type];
  const isLunchLessThan30min = (type === workClockActionsMap.lunchOut) && (activityTypeTime < 30 * 60);

  return (
    <Dialog maxWidth={maxWidth} {...DialogProps}>
      <ModalContainer>
        <ModalHeader disableBorderBottom onClose={handleModalReject}>
          {title}
        </ModalHeader>

        <ModalBody>
          <Box fontSize={isMobile ? 64 : 84} color="text.secondary" textAlign="center">
            <ContentIcon fontSize="inherit" color="inherit" />
          </Box>

          <Typography paragraph={isLunchLessThan30min} variant={isMobile ? 'h5' : 'h6'} align="center">
            {content}
          </Typography>

          {isLunchLessThan30min && (
            <Typography align="center" variant="h4">
              Under California law employees must be provided
              no less than a thirty-minute meal period when the work period is more than five hours
            </Typography>
          )}

          <Box textAlign="center" py={3}>
            <Fab
              variant="extended"
              color="primary"
              onClick={handleModalResolve}
            >
              {buttonTitle || 'Start'}
            </Fab>
          </Box>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};

WorkClockConfirmationModal.propTypes = propTypes;
