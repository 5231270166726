import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow header>
      <ListRowCell flexBasis={columnsWidths.from}>
        From
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.to}>
        To
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.appointmentBooks}>
        Appt.books
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.providers}>
        Providers
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.offices}>
        Offices
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
