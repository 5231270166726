import { useFormikContext } from 'formik';
import { Box, FormControlLabel } from '@material-ui/core';
import { Checkbox } from '../../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../../components/users';
import { UsersList } from './UsersList';

export const Form = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleMemberAdd = (user) => {
    setFieldValue('users', values?.users?.concat(user));
  };

  const handleMemberDelete = (user) => {
    setFieldValue('users', values?.users?.filter(({ id }) => user?.id !== id));
  };

  return (
    <>
      <FormControlLabel
        control={<Checkbox name="is_active" />}
        label="Is active"
        margin="normal"
      />

      <Box mb={2}>
        <UsersSelect
          withoutFormik
          label="Invite members"
          value={null}
          hiddenOptions={values?.users}
          onChange={handleMemberAdd}
        />
      </Box>

      {!!values?.users?.length &&
        <UsersList
          users={values?.users}
          onUserDelete={handleMemberDelete}
        />
      }
    </>
  );
};
