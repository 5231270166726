import moment from 'moment';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Contact } from '../../../../../../components/Contact';
import { Loader } from '../../../../../../components/Loader';
import { Popper } from '../../../../../../components/Popper';
import {
  ListRow,
  ListRowCell,
  ListRowCellActions
} from '../../../../../../components/ListRow';
import { columnsWidths, columnsWidthsSM } from '../List';
import { Menu } from './Menu';

export const Row = ({ item: access = {}, isLoaded }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const rowWidth = isMobile ? columnsWidthsSM : columnsWidths;
  const isExpired = moment().isAfter(moment.unix(access?.expired_at));

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow px={0} py={0.5}>
      <ListRowCell grow flexBasis={rowWidth.email}>
        <Box
          noWrap
          component={Typography}
          display="flex"
          flexDirection={!isTablet ? 'row' : 'column'}
          alignItems={!isTablet ? 'center' : 'flex-start'}
        >
          <Contact noWrap type="mailto" contact={access.access_email} />

          {isTablet && (
            <Box display="flex" alignItems="center" flexWrap="nowrap">
              <Typography variant="caption" color="textSecondary">Confirm:</Typography>
              &nbsp;&nbsp;
              <Typography variant="caption" color="textSecondary" noWrap>
                {access.confirmed_at ? moment.unix(access.confirmed_at).format('L') : '-'}
              </Typography>
            </Box>
          )}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={rowWidth.createDate}>
        {moment.unix(access.created_at).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={rowWidth.expireDate}>
        <Box color={isExpired ? 'error.main' : null}>
          {access.expired_at ? moment.unix(access.expired_at).format('L LT') : '-'}
        </Box>
      </ListRowCell>

      {!isTablet && (
        <ListRowCell flexBasis={columnsWidths.confirmDate}>
          {access.confirmed_at ? moment.unix(access.confirmed_at).format('L LT') : '-'}
        </ListRowCell>
      )}

      <ListRowCell flexBasis={rowWidth.actions}>
        <ListRowCellActions>
          <Popper
            placement="left-start"
            anchorRenderer={({ anchorRef, handleToggle }) => (
              <IconButton
                size={isTablet ? 'small' : 'medium'}
                buttonRef={anchorRef}
                edge="end"
                onClick={handleToggle}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          >
            {({ handleClose }) => (
              <Menu isExpired={isExpired} access={access} onClose={handleClose} />
            )}
          </Popper>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
