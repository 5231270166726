export const initialValues = {
  name: null,
  description: null,
  file_number: null,
  edex: false,
  background_check: false,
  hourly: false,
  flat_free: false,
  statute_date: null,
  government_claim: null,
  claim_filled_at: null,
  court_number: null,
  accident_location: null,
  accident_time: null,
  liability: null,
  incident_type: null,
  conditions: null
};
