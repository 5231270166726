import cn from 'classnames';
import moment from 'moment';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../components/UserLink';
import { Date } from '../../../../../../components/Date';
import { styles } from '../MainContent/List/Row/styles';

const useStyles = makeStyles(styles);

export const FormInfo = ({ form }) => {
  const classes = useStyles();
  const stepsCount = 7;

  return !!form && (
    <Grid container spacing={2} component={Box} pt={3}>
      <Grid item md={2} xs={5}>
        <Typography color="textSecondary">Date</Typography>
      </Grid>

      <Grid item md={4} xs={7}>
        <Box display="flex" alignItems="center">
          <Date
            date={form.created_at || form.updated_at}
            iconFontSize="small"
            format="L, LT"
          />
        </Box>
      </Grid>

      <Grid item md={2} xs={5}>
        <Typography color="textSecondary">DOI</Typography>
      </Grid>

      <Grid item md={4} xs={7}>
        <Date disableMoment date={moment(form.doi).format('L')} iconFontSize="small" />
      </Grid>

      <Grid item md={2} xs={5}>
        <Typography color="textSecondary">Form Filling</Typography>
      </Grid>

      <Grid item md={4} xs={7}>
        <Typography
          className={
            cn(
              classes.fillContent,
              { [classes.fillContent_fullfilled]: form.filling === stepsCount }
            )
          }
        >
          {form.filling ? `${form.filling}/${stepsCount}` : `1/${stepsCount}`}
        </Typography>
      </Grid>

      <Grid item md={2} xs={5}>
        <Typography color="textSecondary">Physician</Typography>
      </Grid>

      <Grid item md={4} xs={7}>
        <UserLink
          disableLink
          size="md"
          variant="inherit"
          user={form.treating_physician}
        />
      </Grid>

      <Grid item md={2} xs={5}>
        <Typography color="textSecondary">Insurance Company</Typography>
      </Grid>

      <Grid item md={4} xs={7}>
        <CompanyLink
          company={form?.primary_insurance?.insurance_company}
        />
      </Grid>

      <Grid item md={2} xs={5}>
        <Typography color="textSecondary">Insurance Type / Id</Typography>
      </Grid>

      <Grid item md={4} xs={7}>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Typography noWrap variant="h5">
              {form?.primary_insurance?.type_insurance || ' '}
            </Typography>
          </Box>

          <Typography noWrap>
            {form?.primary_insurance?.insuranceId || ' '}
          </Typography>
        </Box>
      </Grid>

      <Grid item md={2} xs={5}>
        <Typography color="textSecondary">Appt. Date</Typography>
      </Grid>

      <Grid item md={4} xs={7}>
        <Date date={form.appointment_at} iconFontSize="small" />
      </Grid>
    </Grid>
  );
};
