import T from 'prop-types';
import { makeStyles, Typography, DialogActions } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  title: T.node
};

export const ModalFooter = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {title &&
        <Typography variant="subtitle2">
          {title}
        </Typography>
      }

      <DialogActions className={classes.actions}>
        {children}
      </DialogActions>
    </div>
  );
};

ModalFooter.propTypes = propTypes;
