import { Box, Grid, Typography } from '@material-ui/core';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { Contact } from '../../../../../../../components/Contact';
import { Divider } from '../../../../../../../components/Divider';
import { VenueContent } from '../../../../VenueContent';
import { MedicInfo } from './MedicInfo';

export const BillingContent = ({ office }) => {
  return (
    <Box pt={3} px={2}>
      <Box color="info.main">
        <Typography variant="button" component="h3" color="inherit">
          Billing Details
        </Typography>
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Address
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <AddressLink variant="h5" address={office?.billing?.full_address}>
            {office?.billing?.full_address}
          </AddressLink>
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={1} component={Box} py={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Phone
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={office?.billing?.phone}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Fax
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={office?.billing?.fax}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Mob number
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={office?.billing?.mobile_phone}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={1} component={Box} py={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Email
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="mailto"
            contact={office?.billing?.email}
          />
        </Grid>
      </Grid>

      {office?.medical_data
        ? <MedicInfo medicalInfo={office?.medical_data} />
        : <VenueContent venue={office?.venue || []} />
      }
    </Box>
  );
};
