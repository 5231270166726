import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidthsLg, columnsWidthsSm } from '../../../../CodesPOS/MainContent/List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <ListRow header className={classes.root}>
      <ListRowCell flexBasis={columnsWidths.procedure_code}>
        Code
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.description}>
        Description
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
