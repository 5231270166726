import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Divider } from '../../../../../../components/Divider';
import { CasesStatusesSelect, CaseTypesSelect, KeyboardDatePicker } from '../../../../../../components/FormField';

export const Body = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container spacing={2} component={Box} p={isMobile ? 1 : 2}>
      <Grid item xs={isMobile ? 12 : isTablet ? 6 : 4}>
        <CaseTypesSelect
          multiple
          name="case_type_id"
          margin="dense"
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : isTablet ? 6 : 4}>
        <CasesStatusesSelect
          name="case_status_id"
          label="Case Status"
          margin="dense"
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <KeyboardDatePicker
          name="last_status_changed_at"
          label="Date of Status"
          placeholder="Date of Status"
          margin="dense"
        />
      </Grid>

      {!isMobile && <Grid item xs={8} />}

      <Grid item xs={isMobile ? 12 : 4}>
        <KeyboardDatePicker
          name="opened_at"
          label="Date Open"
          placeholder="Date Open"
          margin="dense"
        />
      </Grid>

      <Grid item xs={12}>
        <Divider type="dashed" />
      </Grid>
    </Grid>
  );
};
