import { useMediaQuery } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import {
  widthBreakpointMedium,
  widthBreakpointSmall,
  widthBreakpointXS,
  columnsWidths,
  mobileColumnWidths
} from '../../../../Invoices/InvoicesPage/InvoicesContent/List/listConfig';

export const TableHeader = ({ width }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const defaultColumnWidths = isMobile ? mobileColumnWidths : columnsWidths;

  return (
    <ListRow header px={isMobile ? 0 : 2}>
      <ListRowCell grow flexBasis={defaultColumnWidths.name}>
        Inv. #
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell grow flexBasis={defaultColumnWidths.billFor}>
          Bill For
        </ListRowCell>
      }

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={defaultColumnWidths.dueDate}>
          Due Date
        </ListRowCell>
      }

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={defaultColumnWidths.amount}>
          Amount
        </ListRowCell>
      }

      <ListRowCell flexBasis={defaultColumnWidths.balance}>
        {isMobile ? 'Balance' : 'Balance Due'}
      </ListRowCell>

      <ListRowCell flexBasis={defaultColumnWidths.status}>
        Status
      </ListRowCell>

      <ListRowCell flexBasis={defaultColumnWidths.action} />
    </ListRow>
  );
};
