export const styles = ({
  spacing,
  palette: { primary, grey },
  breakpoints
}) => ({
  form: {
    paddingBottom: spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },

  formWrapper: {
    [breakpoints.down('md')]: {
      padding: spacing()
    }
  },

  input: {
    lineHeight: '21px'
  },

  formButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '50px',
    opacity: 1,
    transition: 'opacity 0.15s ease-in, height 0.3s ease-in'
  },

  formHiddenButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 0,
    opacity: 0,
    transition: 'opacity 0.15s ease-in, height 0.3s ease-in'
  },

  actionIcon: {
    color: primary.main
  },

  userImage: {
    marginRight: spacing(),
    marginTop: spacing(1)
  },

  cancelBtn: {
    color: grey[200],
    marginRight: spacing(1.5)
  }
});
