export const styles = ({ spacing, palette, shadows }) => ({
  root: {
    padding: spacing(2, 1, 2, 2),
    borderBottom: `1px solid ${palette.divider}`
  },

  logoIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: 50,
    height: 50,
    borderRadius: '50%',
    boxShadow: shadows[2],
    backgroundColor: palette.indigo
  },

  icon: {
    color: palette.common.white
  },

  header: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexGrow: 1
  }
});
