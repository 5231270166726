export const filterFieldsMap = {
  invoiceNumber: 'invoice_number',
  users: 'users',
  companies: 'companies',
  caseId: 'case_id',
  dateFrom: 'date_from',
  dateTo: 'date_to',
  method: 'method'
};

export const filterFieldsLabels = {
  [filterFieldsMap.invoiceNumber]: 'Inv. Number',
  [filterFieldsMap.users]: 'Users',
  [filterFieldsMap.companies]: 'Companies',
  [filterFieldsMap.caseId]: 'Cases',
  [filterFieldsMap.dateFrom]: 'Date From',
  [filterFieldsMap.dateTo]: 'Date To',
  [filterFieldsMap.method]: 'Payment Method'
};
