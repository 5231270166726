import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../../../components/LayoutContext';
import { apptTypesMap, SelectApptModal } from '../../../../components/appointments';
import { Page, PageBody, PageHeader } from '../../../../components/Page';
import { useModal } from '../../../../components/ModalsProvider';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { UserContext } from '../../Members/User/UserProvider';
import { ActionsBar } from '../ActionsBar';
import { ProcedureContext, ProcedureProvider } from './ProcedureProvider';
import { CreateProceduresModal } from './CreateProceduresModal';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

export const Procedures = () => {
  const { openModal } = useModal();
  const params = useParams();
  const { user } = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const patient = userContext ? userContext?.user : user;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filterOpenButton,
    scrollWrapperProps,
    ScrollWrapper,
    FilterBarWrapper,
    filterBarWrapperProps
  } = useMobileFilterCollapse();

  const initialState = {
    filter: {
      patient_id: params?.userID || params.patientID || user?.id
    }
  };

  const handleSelectAppt = (helpers) => () => {
    openModal(SelectApptModal, {
      payload: {
        appointmentType: apptTypesMap.surgeriesProcedures,
        patientID: params.patientID || user?.id
      },
      onModalResolved: (data) => {
        if (data) {
          const apptValues = {
            ...data,

            approved_id: data?.appointment_book?.provider || null
          };

          openCreateModal(apptValues, helpers);
        } else {
          openCreateModal(null, helpers);
        }
      }
    });
  };

  const openCreateModal = (apptValues, helpers) => {
    openModal(CreateProceduresModal, {
      payload: {
        initialValues: {
          patient,
          patient_id: patient,
          patient_appointment_details: { patient, ...patient },
          patient_insurance_id: patient?.profile?.medical?.main_insurance || patient?.medical?.main_insurance
        },
        apptValues,
        insurance: patient?.profile?.medical?.main_insurance ||
          patient?.medical?.main_insurance,
        appointmentTypeID: apptTypesMap.surgeriesProcedures,
        onUpdate: helpers
      }
    });
  };

  return (
    <LayoutContextProvider>
      <LayoutContext.Consumer>
        {({ viewVariant }) => (
          <ProcedureProvider initialState={initialState}>
            <Page>
              <PageHeader disableBorderBottom>
                <ProcedureContext.Consumer>
                  {({ selectedIDs, pagination, fetchProcedures, massDeleteItems }) => (
                    <ActionsBar
                      disableViewVariant={isTablet}
                      actionTitle="Add new"
                      selectedIDs={selectedIDs}
                      pagination={pagination}
                      onCreate={handleSelectAppt(fetchProcedures)}
                      onDelete={massDeleteItems(selectedIDs)}
                      filterOpenButton={filterOpenButton}
                    />
                  )}
                </ProcedureContext.Consumer>
              </PageHeader>

              <PageBody fullHeight disablePaddings disableScrollbars>
                <Box height="100%" display="flex" flexDirection="column">
                  <ScrollWrapper {...scrollWrapperProps}>
                    <FilterBarWrapper {...filterBarWrapperProps}>
                      <FiltersBar filterKey={filtersKeysMap.surgeries_procedures} />
                    </FilterBarWrapper>
                  </ScrollWrapper>

                  <Box flexGrow={1}>
                    {!isTablet ? (
                      (viewVariant === viewVariantsMap.list)
                        ? <List />
                        : (viewVariant === viewVariantsMap.grid)
                          ? <Grid /> : null
                    ) : (
                      <Grid />
                    )}
                  </Box>
                </Box>
              </PageBody>
            </Page>
          </ProcedureProvider>
        )}
      </LayoutContext.Consumer>
    </LayoutContextProvider>
  );
};
