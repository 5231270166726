import { useContext, useEffect, useState } from 'react';
import { Box, SvgIcon, Popper, useTheme, useMediaQuery } from '@material-ui/core';
import { mdiAccountHeartOutline, mdiBriefcaseOutline, mdiTagOutline } from '@mdi/js';
import Icon from '@mdi/react';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import { stopPropagation } from '../../../../helpers/dom';
import { IconButton } from '../../../IconButton';
import { Tabs, Tab } from '../../../Tabs';
import { SavedEmailsContext } from '../../../../app/Dashboard/EmailsPage';
import { TagsProvider } from '../../../../app/Dashboard/files-common';
import { CasesMenu } from './CasesMenu';
import { PatientsMenu } from './PatientsMenu';
import { TagsMenu } from './TagsMenu';

export const FiltersBar = () => {
  const {
    filter,
    caseItem,
    patientItem,
    messages,
    applyFilter
  } = useContext(SavedEmailsContext);
  const [ tabIndex, setTabIndex ] = useState(0);
  const [ anchorEl, setAnchorEl ] = useState({});
  const [ selectedTags, setSelectedTags ] = useState({ 0: [], 1: [] });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ items, setItems ] = useState({
    cases: null,
    patients: null
  });
  const zIndex = theme.zIndex.modal;

  const updateFilter = (name, value) => {
    applyFilter({
      ...filter,
      [name]: value.id
    });
    setAnchorEl({});
  };

  const getFields = () => {
    const cases = [];
    const patients = [];

    const isFieldExist = (field, array) => {
      return array.find((item) => item.id === field.id) !== undefined;
    };

    messages.forEach((message) => {
      if (message.case && !isFieldExist(message.case, cases)) {
        cases.push(message.case);
      }

      if (message.patient && !isFieldExist(message.patient, patients)) {
        patients.push(message.patient);
      }
    });

    setItems({ cases, patients });
  };

  const resetFilter = (item) => () => {
    const filterName = item === 'case' ? 'case_id' : item === 'patients' ? 'patient_id' : 'tags';

    applyFilter({ [filterName]: null });
    setAnchorEl({});
    setSelectedTags({ 0: [], 1: [] });
  };

  const handleTabIndexChange = (event, newValue) => {
    setAnchorEl(!anchorEl[newValue] ? { [newValue]: event.currentTarget } : {});
    setTabIndex(newValue);
  };

  const handleChangeTags = (types) => (selectedTagsValues = []) => {
    applyFilter({
      ...filter,
      tags: selectedTagsValues.map((tag) => tag.value)
    });
    setSelectedTags({ ...selectedTags, [types]: selectedTagsValues });
  };

  useEffect(() => {
    getFields();
  }, [ messages ]);

  return (
    <>
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        onChange={handleTabIndexChange}
      >
        {!caseItem ? (
          <Tab
            component={Box}
            cursor="pointer"
            label={
              <Box display="flex" alignItems="center" fontSize={isMobile ? 11 : 14}>
                <Box mr={0.5}>
                  <SvgIcon fontSize={isMobile ? 'small' : 'default'}><Icon path={mdiBriefcaseOutline} /></SvgIcon>
                </Box>
                Cases

                {!caseItem?.id && filter.case_id &&
                  <Box color="info.main" ml={0.5}>
                    <IconButton size="small" color="inherit" onClick={stopPropagation(resetFilter('case'))}>
                      <BackupIcon fontSize="small" />
                    </IconButton>
                  </Box>
                }
              </Box>
            }
          />
        ) : (
          <Tab
            component={Box}
            cursor="pointer"
            label={
              <Box display="flex" alignItems="center" fontSize={isMobile ? 11 : 14}>
                <Box mr={0.5}>
                  <SvgIcon fontSize={isMobile ? 'small' : 'default'}><Icon path={mdiAccountHeartOutline} /></SvgIcon>
                </Box>
                Patients

                {!patientItem?.id && filter.patient_id &&
                  <Box color="info.main" ml={0.5}>
                    <IconButton size="small" color="inherit" onClick={stopPropagation(resetFilter('patients'))}>
                      <BackupIcon fontSize="small" />
                    </IconButton>
                  </Box>
                }
              </Box>
            }
          />
        )}

        <Tab
          component={Box}
          label={
            <Box display="flex" alignItems="center" fontSize={isMobile ? 11 : 14}>
              <Box mr={0.5}>
                <SvgIcon fontSize={isMobile ? 'small' : 'default'}><Icon path={mdiTagOutline} /></SvgIcon>
              </Box>
              My Tags

              {!!selectedTags?.[0]?.length &&
                <Box color="info.main" ml={0.5}>
                  <IconButton size="small" color="inherit" onClick={stopPropagation(resetFilter('tags'))}>
                    <BackupIcon fontSize="small" />
                  </IconButton>
                </Box>
              }
            </Box>
          }
        />
        <Tab
          component={Box}
          label={
            <Box display="flex" alignItems="center" fontSize={isMobile ? 11 : 14}>
              <Box mr={0.5}>
                <SvgIcon fontSize={isMobile ? 'small' : 'default'}><Icon path={mdiTagOutline} /></SvgIcon>
              </Box>
              Global Tags

              {!!selectedTags?.[1]?.length &&
                <Box color="info.main" ml={0.5}>
                  <IconButton size="small" color="inherit" onClick={stopPropagation(resetFilter('tags'))}>
                    <BackupIcon fontSize="small" />
                  </IconButton>
                </Box>
              }
            </Box>
          }
        />
      </Tabs>

      {tabIndex === 0 && anchorEl[0] &&
        <Popper
          open={!!anchorEl[0]}
          placement="bottom-start"
          style={{ zIndex }}
          anchorEl={anchorEl[tabIndex]}
        >
          {!caseItem ? (
            <CasesMenu
              cases={items.cases || []}
              updateFilter={updateFilter}
              onChangeAnchorEl={setAnchorEl}
            />
          ) : (
            <PatientsMenu
              patients={items.patients || []}
              updateFilter={updateFilter}
              onChangeAnchorEl={setAnchorEl}
            />
          )}
        </Popper>
      }

      {tabIndex === 1 && anchorEl[1] &&
        <Popper
          open={!!anchorEl[1]}
          placement="bottom-start"
          style={{ zIndex }}
          anchorEl={anchorEl[tabIndex]}
        >
          <TagsProvider>
            <TagsMenu
              tagsType={0}
              selectedTags={selectedTags[0]}
              onTagsChange={handleChangeTags(0)}
              onChangeAnchorEl={setAnchorEl}
            />
          </TagsProvider>
        </Popper>
      }

      {tabIndex === 2 && anchorEl[2] &&
        <Popper
          open={!!anchorEl[2]}
          placement="bottom-start"
          style={{ zIndex }}
          anchorEl={anchorEl[tabIndex]}
        >
          <TagsProvider>
            <TagsMenu
              tagsType={1}
              selectedTags={selectedTags[1]}
              onTagsChange={handleChangeTags(1)}
              onChangeAnchorEl={setAnchorEl}
            />
          </TagsProvider>
        </Popper>
      }
    </>
  );
};
