export const styles = ({ spacing }) => ({
  root: {
    height: '100%'
  },

  loader: {
    padding: spacing(2)
  },

  noVersionsMessage: {
    padding: spacing(2)
  },

  list: {
    height: '100%',
    overflow: 'auto'
  }
});
