import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Dialog, SvgIcon, Button } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiMicrophoneMessage } from '@mdi/js';
import * as transcribesApi from '../../../../../../api/files/transcribes';
import { TextField } from '../../../../../../components/FormField';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../../../components/Modal';
import { Loader } from '../../../../../../components/Loader';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

export const SendTranscribeModal = ({
  DialogProps,
  payload: {
    initialValues: initialValuesProp
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const formData = new FormData();

    for (let key in values) if (values[key]) {
      formData.append(key, values[key]);
    }

    return transcribesApi.uploadTranscribe(formData).then(() => {
      enqueueSnackbar('File sent successfully!', { variant: 'success' });
      handleModalResolve();
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ ...initialValues, ...initialValuesProp }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiMicrophoneMessage} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Send To Transcribe
            </ModalHeader>

            <ModalBody>
              <TextField
                required
                name="name"
                label="Transcribe file name"
                placeholder="Enter name..."
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
