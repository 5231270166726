import { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Paper, useTheme } from '@material-ui/core';
import { useResizeObserver } from '../../../../../../helpers/hooks';
import { filtersKeysMap } from '../../../../../../store/lastFilters';
import { filterFieldsMap } from '../../../../../../components/call-reports/CallReports/filterFieldsMap';
import { parentTypesMap } from '../../../../../../components/call-reports/CopyToModal/parentTypeOptions';
import { CallReports as CallReportsList } from '../../../../../../components/call-reports/CallReports';
import { CallReportsProvider } from '../../../../../../components/call-reports/CallReportsProvider';
import { LayoutContextProvider } from '../../../../../../components/LayoutContext';
import { PageBody } from '../../../../../../components/Page';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { CallReportsWidget } from '../../../../CasesPage/Cases/CaseItem/Widgets';
import { ActionsBar } from '../../../../CallReportsPage/ActionsBar';
import { UserContext } from '../../UserProvider';

export const CallReports = () => {
  const params = useParams();
  const rootRef = useRef();
  const userContext = useContext(UserContext);
  const currentUser = useSelector(({ profile }) => profile.user);
  const user = userContext ? userContext?.user : currentUser;
  const isPatient = !!params?.patientID || !!params?.userID;
  const theme = useTheme();
  const { width } = useResizeObserver({ ref: rootRef });
  const isTablet = width <= theme.breakpoints.values.tablet;
  const filterKey = userContext
    ? filtersKeysMap.call_reports_users
    : filtersKeysMap.call_reports_profile;
  const { filterOpenButton, ...collapsProps } = useMobileFilterCollapse('tablet', width);

  return (
    <Paper ref={rootRef} component={Box} flexGrow={1} width="100%" height="100%">
      <LayoutContextProvider>
        <CallReportsProvider
          disableCauser={isPatient}
          filter={{
            parent_id: user?.id,
            parent_user_ids: [ user?.id ],
            parent_type: parentTypesMap.user
          }}
        >
          {!isPatient ? (
            <CallReportsWidget
              isTabPage
              payload={{
                parent_id: user?.id,
                parent_type: parentTypesMap.user
              }}
              filterKey={filterKey}
            />
          ) : (
            <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
              <ActionsBar
                isTablet={isTablet}
                filterOpenButton={filterOpenButton}
                payload={{
                  parent_id: user?.id,
                  parent_type: parentTypesMap.user
                }}
              />

              <PageBody disablePaddings disableScrollbars fullHeight>
                <CallReportsList
                  isTablet={isTablet}
                  filterKey={filterKey}
                  hiddenFields={[ filterFieldsMap.parentCase, filterFieldsMap.parentUser ]}
                  {...collapsProps}
                />
              </PageBody>
            </Box>
          )}
        </CallReportsProvider>
      </LayoutContextProvider>
    </Paper>
  );
};
