import { api } from '../../api';

export const fetchTypes = (config) => {
  return api.get('/tasks/types', config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error.data;
  });
};

export const createType = (type) => {
  return api.post('/tasks/types', type).then(({ data }) => {
    return data;
  }).catch(({ data: { errors } }) => {
    throw errors;
  });
};

export const updateType = (type) => {
  return api.put(`/tasks/types/${type.id}`, type).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error.data;
  });
};

export const deleteType = (id) => {
  return api.delete(`/tasks/types/${id}`).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error.data;
  });
};
