export const invoicesTypesMap = {
  case: 'case',
  appointment: 'appointment',
  claim: 'claim'
};

export const invoiceBlocksMap = {
  invoices: 'invoices',
  expenses: 'expenses'
};

export const invoicesTypesOptions = [
  { value: invoicesTypesMap.case, label: 'Case' },
  { value: invoicesTypesMap.appointment, label: 'Appointment' },
  { value: invoicesTypesMap.claim, label: 'Claim' }
];
