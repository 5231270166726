import { PasswordField, TextField } from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';
import { workerRoles } from '../../../../../dataMaps';

export const PrinterConfigurationForm = () => {
  return (
    <>
      <TextField
        required
        name="name"
        label="Printer name configuration"
        placeholder="Printer name configuration"
      />

      <TextField
        multiline
        rows={8}
        name="description"
        label="Add Description"
        margin="dense"
        variant="filled"
      />

      <TextField
        required
        name="directory"
        label="Directory"
        margin="dense"
      />

      <TextField
        required
        name="login"
        label="Login"
        margin="dense"
      />

      <PasswordField
        required
        name="password"
        label="Password"
        margin="dense"
      />

      <UsersSelect
        required
        multiple
        name="users"
        label="Staff"
        margin="dense"
        placeholder="Select staff..."
        TextFieldProps={{
          margin: 'dense'
        }}
        params={{
          roles: workerRoles
        }}
      />

      <UsersSelect
        name="user_id"
        label="Manager"
        margin="dense"
        placeholder="Select manager..."
        TextFieldProps={{
          margin: 'dense'
        }}
        params={{
          roles: workerRoles
        }}
      />
    </>
  );
};
