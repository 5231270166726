import { useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { ImmunizationsSelect, TextField } from '../../../../../../components/FormField';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { Fab } from '../../../../../../components/Fab';
import { Attachments } from '../../../ClinicalNotes/Attachments';
import { immunizationInitialValues } from '../immunizationInitialValues';

export const Immunizations = ({ onAttachmentsUpdate }) => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  const handleChangeVaccine = (index) => (option) => {
    if (option && option?.data) {
      setFieldValue(`immunizations[${index}].vaccine_description`, option?.data?.description);
    }
  };

  useEffect(() => {
    onAttachmentsUpdate(values?.immunizations?.map(({ files }) => files));
  }, [ values.immunizations ]);

  return (
    <Box my={6}>
      <FieldArray
        name="immunizations"
        render={({ remove, push }) => (
          <>
            <Box mb={2} color="info.main" display="flex" alignItems="center">
              <Typography variant="h3" color="inherit">
                Select Immunizations
              </Typography>

              <Box component="span" ml={2}>
                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon/>}
                  onClick={() => push(immunizationInitialValues)}
                >
                  Add
                </Fab>
              </Box>
            </Box>

            {values?.immunizations?.map((item, i) => (
              <Box display="flex" flexWrap="nowrap" mb={3} key={i}>
                <Box mt={3.5} mr={0.5} alignSelf="flex-start">
                  <Typography component="span">{i + 1}.</Typography>
                </Box>

                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item sm={6} xs={12}>
                    <ImmunizationsSelect
                      name={`immunizations.${i}.immunization_id`}
                      TextFieldProps={{ required: true, margin: 'dense' }}
                      onChange={handleChangeVaccine(i)}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    {values.immunizations[i].immunization_id &&
                      <Box mt={1}>
                        <Typography variant="body2" color="textSecondary">
                          Description
                        </Typography>
                      </Box>
                    }

                    <Typography>{values.immunizations[i]?.vaccine_description}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" mt={3}>
                      <Box mr={0.5} fontWeight={500}>
                        <NotesIcon fontSize="small" />
                      </Box>

                      <Typography variant="h5">Note</Typography>
                    </Box>

                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      name={`immunizations.${i}.note`}
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Attachments name={`immunizations.${i}.files`} />
                  </Grid>
                </Grid>

                {values?.immunizations?.length > 1 &&
                  <Box mt={3} alignSelf="flex-start">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={handleClearField(remove, i)}
                    >
                      <ClearIcon fontSize="small"/>
                    </IconButton>
                  </Box>
                }
              </Box>
            ))}
          </>
        )}
      />
    </Box>
  );
};
