import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell flexBasis={columnsWidths.users}>
          Users
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.cases}>
          Case
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.company}>
          Company
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dateFrom}>
          Date From
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dateTo}>
          Date To
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
