import { makeStyles } from '@material-ui/core';
import { stopPropagation } from '../../../../../../helpers/dom';
import { useModal } from '../../../../../../components/ModalsProvider';
import { OfficePreviewModal } from '../../OfficePreviewModal';
import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ item: office }) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const openPreview = () => {
    openModal(OfficePreviewModal, {
      payload: { id: office.id }
    });
  };

  return (
    <div className={classes.root} onClick={stopPropagation(openPreview)}>
      <Header office={office}/>
      <Body office={office} />
      <Footer office={office} />
    </div>
  );
};
