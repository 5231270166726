import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import ListAddIcon from '@material-ui/icons/PlaylistAdd';
import * as typesApi from '../../../../../api/tasks/types';
import { Loader } from '../../../../../components/Loader';
import { ModalFooter, ModalHeader, ModalBody } from '../../../../../components/Modal';
import { TaskTypeForm, validationSchema } from '../TaskTypeForm';

export const UpdateTypeModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { type }
}) => {
  const updateType = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return typesApi.updateType(values).then((type) => {
      handleModalResolve(type);
    }).catch(({ errors }) => {
      setSubmitting(false);
      setErrors(errors);
    });
  };

  return (
    <Formik
      initialValues={type}
      validationSchema={validationSchema}
      onSubmit={updateType}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<ListAddIcon />} onClose={handleModalReject}>
              Edit type
            </ModalHeader>

            <ModalBody>
              <TaskTypeForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
