import { makeStyles } from '@material-ui/core';
import { Page, PageBody, PageHeader, PageHeaderTitle } from '../../../../components/Page';
import { FeatureFlagsProvider } from './FeatureFlagsProvider';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FeatureFlags = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Page>
        <PageHeader>
          <PageHeaderTitle>
            Feature Flags
          </PageHeaderTitle>
        </PageHeader>

        <PageBody>
          <FeatureFlagsProvider>
            <List />
          </FeatureFlagsProvider>
        </PageBody>
      </Page>
    </div>
  );
};
