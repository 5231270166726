import { createContext, useEffect, useState } from 'react';
import { Echo } from '../../../utils/echo';

export const OnlineUsersContext = createContext();

export const OnlineUsersProvider = ({ children }) => {
  const [ onlineUsersIDs, setOnlineUsersIDs ] = useState([]);

  useEffect(() => {
    Echo.join('online').here((data) => {
      setOnlineUsersIDs(data);
    }).joining((userID) => {
      if (!onlineUsersIDs.includes(userID)) {
        setOnlineUsersIDs((onlineUsersIDs) => onlineUsersIDs.concat(userID));
      }
    }).leaving((userID) => {
      setOnlineUsersIDs((onlineUsersIDs) => onlineUsersIDs.filter((id) => id !== userID));
    });
  }, []);

  const providerValue = {
    onlineUsersIDs
  };

  return (
    <OnlineUsersContext.Provider value={providerValue}>
      {children}
    </OnlineUsersContext.Provider>
  );
};
