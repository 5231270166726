import { Box, Grid, Typography } from '@material-ui/core';
import { Autocomplete, KeyboardDatePicker, TextField } from '../../../FormField';
import { returnWorkIndicatorsOptions } from './data';

export const WorkComp = () => {
  return (
    <>
      <Box mb={1}>
        <Typography variant="h3">
          Work Comp
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            name="disability"
            label="Disability"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <KeyboardDatePicker
            clearable
            name="last_work_date"
            label="Last Work Date"
            outputFormat="YYYY-MM-DD"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Autocomplete
            name="return_work_indicator"
            label="Return To Work Indicator"
            options={returnWorkIndicatorsOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};
