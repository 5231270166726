export const styles = ({
  spacing,
  palette: { grey, darkgreen },
  typography: { fontWeightMedium },
  shape: { borderRadius }
}) => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: spacing(1, 2, 2, 2)
  },

  note: {
    marginRight: spacing(2),
    flexGrow: 1,
    height: 150,
    overflow: 'auto',
    backgroundColor: grey[50],
    padding: spacing(2),
    borderRadius
  },

  note__title: {
    color: grey[600]
  },

  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: 384,
    minWidth: 384,
    height: 150,
    padding: spacing(2),
    border: `1px dashed ${darkgreen}`,
    borderRadius
  },

  total__title: {
    color: grey[400],
    fontWeight: fontWeightMedium,
    marginBottom: spacing(1.5)
  },

  total__sum: {
    marginBottom: spacing(1.5)
  },

  totalTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%'
  },

  totalTitle_sum: {
    flexGrow: 0
  },

  total__balance: {
    color: darkgreen
  },

  actionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItem: 'center',
    padding: spacing(1, 4),
    marginBottom: spacing(3)
  },

  actionButton__cancel: {
    color: grey[400],
    marginRight: spacing(2)
  },

  attachmentContent: {
    margin: spacing(0, 2)
  }
});
