import { Fragment, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import T from 'prop-types';
import { Typography, Link as MuiLink, Box } from '@material-ui/core';
import { OfficePreviewModal } from '../../app/Dashboard/Contacts/Offices/OfficePreviewModal';
import { stopPropagation } from '../../helpers/dom';
import { useModal } from '../ModalsProvider';
import { Tooltip } from '../Tooltip';

const propTypes = {
  office: T.object,
  path: T.string,
  size: T.string,
  tooltipTitle: T.oneOfType([ T.node, T.string ]),
  noWrap: T.bool
};

export const OfficeLink = forwardRef(({
  size = 'md',
  disablePreview = false,
  isExistTooltip = false,
  tooltipTitle,
  noWrap,
  office,
  path,
  ...props
}, ref) => {
  const { openModal } = useModal();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const Wrapper = noWrap ? Fragment : Box;
  const wrapperProps =  noWrap ? {} : {
    display: 'flex',
    alignItems: 'center',
    ref
  };

  const openPreview = () => {
    if (!disablePreview) {
      openModal(OfficePreviewModal, {
        payload: { id: office?.id }
      });
    }
  };

  const officeLinkProps = !path ? {
    onClick: isAuthenticated ? stopPropagation(openPreview) : undefined,
    ...props
  } : {
    target: '_blank',
    href: path,
    onClick: stopPropagation(),
    ...props
  };

  return !office ? (
    <Typography color="textSecondary">No info.</Typography>
  ) : (
    <Wrapper {...wrapperProps}>
      <Tooltip
        isExistTooltip={isExistTooltip}
        title={tooltipTitle}
      >
        <MuiLink
          noWrap
          title={!isExistTooltip ? office?.name : undefined}
          {...officeLinkProps}
        >
          {office?.name}
        </MuiLink>
      </Tooltip>
    </Wrapper>
  );
});

OfficeLink.propTypes = propTypes;
