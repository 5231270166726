export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(0.75, 2, 2, 2)
  },

  title: {
    flexGrow: 1
  },

  titleContent: {
    textTransform: 'capitalize'
  },

  title_total: {
    flexGrow: 0
  }
});
