import moment from 'moment';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import { arrivalTypes } from '../../../../../../../../components/appointments';
import { ColorPreview } from '../../../../../../../../components/ColorPreview';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ appointment }) => {
  const classes = useStyles();

  return (
    <>
      <Box py={1}>
        <Grid container spacing={2}>
          {appointment.appointment_type &&
            <Grid item sm={6}>
              <Grid container spacing={1} align="center" wrap="nowrap">
                <Grid item>
                  <ColorPreview color={appointment.appointment_type?.color} />
                </Grid>

                <Grid item zeroMinWidth>
                  <Tooltip
                    title={
                      appointment.appointment_type?.code + ' - ' + appointment.appointment_type?.description
                    }
                  >
                    <Typography noWrap variant="h5">
                      {appointment.appointment_type?.code}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          }

          {!!appointment?.arrival_status &&
            <Grid item sm={6}>
              <Grid container spacing={1} align="center" wrap="nowrap">
                <Grid item>
                  <ColorPreview color={arrivalTypes[appointment?.arrival_status?.arrival_type]?.color} />
                </Grid>

                <Grid item zeroMinWidth>
                  <Tooltip
                    title={
                      appointment.arrival_status?.code + ' - ' + appointment.arrival_status?.description
                    }
                  >
                    <Typography noWrap variant="h5">
                      {appointment.arrival_status?.code} - {appointment?.arrival_status?.description}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </Box>

      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="h4">
            {appointment.appointment_at ? moment.unix(appointment.appointment_at).format('D MMM, YYYY') : '-'}
          </Typography>
        </div>

        <div className={classes.content}>
          <Typography variant="h4">
            {appointment.appointment_at && appointment.time
              ? moment.unix(appointment.appointment_at).format('LT')
                + ' - '
                + moment.unix(appointment.appointment_at).add(appointment.time, 'minutes').format('LT')
              : '-'
            }
          </Typography>
        </div>
      </div>
    </>
  );
};
