export const filterFieldsMap = {
  name: 'name',
  date_received: 'dateReceived',
  date_responded: 'dateResponded',
  deposition_date: 'depositionDate'
};

export const filterFieldsLabels = {
  [filterFieldsMap.name]: 'Depositions',
  [filterFieldsMap.date_received]: 'Date Received',
  [filterFieldsMap.date_responded]: 'Date Responded',
  [filterFieldsMap.deposition_date]: 'Deposition Date'
};
