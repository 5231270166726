export const orderByLabelsMap = {
  updated_at: 'Updated At',
  viewed_at: 'Viewed At',
  original_filename: 'Name',
  size: 'Size'
};

export const orderByOptions = [
  { label: orderByLabelsMap.updated_at, value: 'updated_at' },
  { label: orderByLabelsMap.viewed_at, value: 'opened_at' },
  { label: orderByLabelsMap.original_filename, value: 'original_filename' },
  { label: orderByLabelsMap.size, value: 'size' }
];
