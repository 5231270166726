import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../components/Loader';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { ListHeader } from './ListHeader';
import { Row } from './Row';

export const minRowHeight = 45;

export const columnsMap = {
  fileNumber: 'fileNumber',
  name: 'name',
  createdFrom: 'createdFrom',
  createdTo: 'createdTo',
  createdDate: 'createdDate',
  status: 'status',
  roles: 'roles',
  members: 'members',
  types: 'types',
  orderBy: 'orderBy',
  action: 'action'
};

export const columnsWidths = {
  [columnsMap.name]: 200,
  [columnsMap.createdDate]: 160,
  [columnsMap.status]: 150,
  [columnsMap.roles]: 150,
  [columnsMap.members]: 150,
  [columnsMap.types]: 150,
  [columnsMap.orderBy]: 100,
  [columnsMap.action]: 52
};

export const columnsNamesMap = {
  [columnsMap.fileNumber]: 'Case Number',
  [columnsMap.name]: 'Case Name',
  [columnsMap.createdFrom]: 'Date From',
  [columnsMap.createdTo]: 'Date To',
  [columnsMap.status]: 'Case Statuses',
  [columnsMap.roles]: 'Users Roles',
  [columnsMap.members]: 'Case Members',
  [columnsMap.types]: 'Case Types',
  [columnsMap.orderBy]: 'Order By'
};

export const columnsNamesList = Object.keys(columnsNamesMap);

export const List = ({ onApplySavedFilter }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No saved filters</Typography>
        ) : (
          <>
            <ListHeader />

            <MuiList disablePadding>
              <InfiniteListLoader
                items={savedFilters}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ onApplySavedFilter }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
                minRowHeight={minRowHeight}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
