import { useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, useMediaQuery } from '@material-ui/core';
import EmojiFoodBeverageOutlinedIcon from '@material-ui/icons/EmojiFoodBeverageOutlined';
import FastfoodOutlinedIcon from '@material-ui/icons/FastfoodOutlined';
import AlarmOffOutlinedIcon from '@material-ui/icons/AlarmOffOutlined';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import { amFormatDuration } from '../../../../../helpers/dates';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { eventBus, eventBusEvents } from '../../../../../utils/eventBus';
import { hasRole } from '../../../../../utils/hasRole';
import { workClockTypesMap } from '../../../../../store/dashboard/workClock/workClockTypesMap';
import {
  startTimer,
  workClockFetch,
  workClockStart,
  workClockStop,
  workClockStopSuccess
} from '../../../../../store/dashboard/workClock';
import { useModal } from '../../../../../components/ModalsProvider';
import { Tooltip } from '../../../../../components/Tooltip';
import { Loader } from '../../../../../components/Loader';
import { Button } from '../../../../../components/Button';
import { Echo } from '../../../../../utils/echo';
import { WorkClockConfirmationModal } from '../WorkClockConfirmationModal';
import { workClockActionsMap } from './workClockActionsMap';

export const WorkClock = () => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const workClock = useSelector(({ workClock }) => workClock.workClock);
  const isTimerFetching = useSelector(({ workClock }) => workClock.isFetching);
  const activeTrack = workClock?.session?.active_track;
  const isWork = workClockTypesMap.work === activeTrack?.type;
  const isBreak = workClockTypesMap.break === activeTrack?.type;
  const isLunch = workClockTypesMap.lunch === activeTrack?.type;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { openModal } = useModal();

  const startWork = (type = null) => () => {
    openModal(WorkClockConfirmationModal, {
      payload: {
        buttonTitle: !!type && 'Back to work',
        type: type || workClockActionsMap.work
      },
      onModalResolved: () => {
        dispatch(workClockStart({
          type: workClockTypesMap.work
        }));
      }
    });
  };

  const startBreak = () => {
    openModal(WorkClockConfirmationModal, {
      payload: {
        type: workClockActionsMap.break
      },
      onModalResolved: () => {
        dispatch(workClockStart({
          type: workClockTypesMap.break
        }));
      }
    });
  };

  const startLunch = () => {
    openModal(WorkClockConfirmationModal, {
      payload: {
        type: workClockActionsMap.lunch
      },
      onModalResolved: () => {
        dispatch(workClockStart({
          type: workClockTypesMap.lunch
        }));
      }
    });
  };

  const stopTimer = () => {
    openModal(WorkClockConfirmationModal, {
      payload: {
        buttonTitle: 'Finish',
        type: workClockActionsMap.clockOut
      },
      onModalResolved: () => {
        dispatch(workClockStop());
      }
    });
  };

  useEffect(() => {
    if (!workClock?.time) {
      return;
    }

    const duration = moment.duration(workClock.time, 'seconds');

    if (isWork && duration.asHours() === 8) {
      enqueueSnackbar('You working already 8 hours', { variant: 'info' });
    } else if (isBreak && duration.asMinutes() === 10) {
      enqueueSnackbar('You on break already 10 minutes', { variant: 'info' });
    } else if (isLunch && duration.asMinutes() === 30) {
      enqueueSnackbar('You on lunch already 30 minutes', { variant: 'info' });
    }
  }, [ isWork, isLunch, isBreak, workClock?.time ]);

  useEffect(() => {
    dispatch(workClockFetch());

    const superuserChanel = Echo.private(`superuser.${currentUser.id}`);

    const handleSessionStart = ({ session }) => {
      eventBus.dispatch(eventBusEvents.workSessionAdd, session);

      if (session?.user?.id === currentUser?.id) {
        const workClock = {
          is_active: true,
          session
        };

        startTimer(dispatch)(workClock);
      }
    };

    const handleSessionUpdate = ({ session }) => {
      eventBus.dispatch(eventBusEvents.workSessionUpdate, session);
    };

    const handleSessionStop = ({ session }) => {
      eventBus.dispatch(eventBusEvents.workSessionUpdate, session);

      if (session?.user?.id === currentUser?.id) {
        dispatch(workClockStopSuccess());
      }
    };

    superuserChanel
      .listen('.session.start', handleSessionStart)
      .listen('.session.update', handleSessionUpdate)
      .listen('.session.stop', handleSessionStop);

    return () => {
      superuserChanel
        .stopListening('.session.start', handleSessionStart)
        .stopListening('.session.update', handleSessionUpdate)
        .stopListening('.session.stop', handleSessionStop);
    };
  }, [ currentUser.id ]);

  return !hasRole(rolesMap.patient) && (
    <Loader surface loading={isTimerFetching} render={
      () => (
        <ButtonGroup variant="outlined">
          {!workClock?.is_active && (
            <Button
              variant="contained"
              size="small"
              color="success"
              startIcon={<AlarmOnOutlinedIcon />}
              onClick={startWork()}
            >
              Clock-In
            </Button>
          )}

          {isWork && (
            <Tooltip title="Start break">
              <Button
                size={isMobile ? 'small' : 'medium'}
                color="warning"
                onClick={startBreak}
              >
                <EmojiFoodBeverageOutlinedIcon />
              </Button>
            </Tooltip>
          )}

          {isBreak && (
            <Tooltip title="Stop break">
              <Button
                size={isMobile ? 'small' : 'medium'}
                color="warning"
                startIcon={<EmojiFoodBeverageOutlinedIcon />}
                onClick={startWork(workClockActionsMap.breakOut)}
              >
                {amFormatDuration(workClock.time)}
              </Button>
            </Tooltip>
          )}

          {isWork && (
            <Tooltip title="Start lunch">
              <Button
                size={isMobile ? 'small' : 'medium'}
                color="warning"
                onClick={startLunch}
              >
                <FastfoodOutlinedIcon />
              </Button>
            </Tooltip>
          )}

          {isLunch && (
            <Tooltip title="Stop lunch">
              <Button
                size={isMobile ? 'small' : 'medium'}
                color="warning"
                startIcon={<FastfoodOutlinedIcon />}
                onClick={startWork(workClockActionsMap.lunchOut)}
              >
                {amFormatDuration(workClock.time)}
              </Button>
            </Tooltip>
          )}

          {isWork && (
            <Tooltip title="Clock-Out">
              <Button
                size={isMobile ? 'small' : 'medium'}
                color="error"
                startIcon={<AlarmOffOutlinedIcon />}
                onClick={stopTimer}
              >
                {amFormatDuration(workClock.time)}
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>
      )}
    />
  );
};
