export const periodsMap = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
};

export const periodsLabelsMap = {
  [periodsMap.DAY]: 'Day',
  [periodsMap.WEEK]: 'Week',
  [periodsMap.MONTH]: 'Month',
  [periodsMap.YEAR]: 'Year'
};

export const periodsOptions = [
  {
    value: periodsMap.DAY,
    label: periodsLabelsMap[periodsMap.DAY]
  },
  {
    value:  periodsMap.WEEK,
    label: periodsLabelsMap[periodsMap.WEEK]
  },
  {
    value:  periodsMap.MONTH,
    label: periodsLabelsMap[periodsMap.MONTH]
  },
  {
    value:  periodsMap.YEAR,
    label: periodsLabelsMap[periodsMap.YEAR]
  }
];
