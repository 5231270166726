import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { Loader } from '../../../../../../../components/Loader';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsMap = {
  search: 'search',
  providers: 'providers',
  appointmentTypes: 'appointmentTypes',
  status: 'status',
  orderBy: 'orderBy',
  action: 'action'
};

export const columnsWidths = {
  [columnsMap.search]: 150,
  [columnsMap.providers]: 150,
  [columnsMap.appointmentTypes]: 200,
  [columnsMap.status]: 80,
  [columnsMap.orderBy]: 150,
  [columnsMap.action]: 52
};

export const List = ({ hiddenColumns, onApplySavedFilter }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Box maxHeight={500}>
        <Loader p={2} loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No saved filters</Typography>
          ) : (
            <>
              <TableHeader hiddenColumns={hiddenColumns} />

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={savedFilters}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  RowProps={{ onApplySavedFilter, hiddenColumns }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </Scrollbars>
  );
};
