import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  claim_id: Yup.mixed().required(),

  // Dates
  onset_date: Yup.string().nullable(),
  first_consult_date: Yup.string().nullable(),
  assumed_care_date: Yup.string().nullable(),
  hearing_date: Yup.date().format('YYYY-MM-DD').nullable(),
  vision_rx_date: Yup.date().format('YYYY-MM-DD').nullable(),
  lmp_date: Yup.string().nullable(),
  last_seen_date: Yup.string().nullable(),
  relinquished_care_date: Yup.string().nullable(),
  accident_date: Yup.string().nullable(),

  acciden_type: Yup.mixed().nullable(),
  auto_accident_state: Yup.mixed().nullable(),
  auto_accident_country: Yup.string().max(254).nullable(),
  qualifier: Yup.string().nullable(),

  // Work Comp
  disability: Yup.string().nullable(),
  return_work_indicator: Yup.mixed().nullable(),
  last_work_date: Yup.string().nullable(),

  // Unable to Work
  unable_work_from: Yup.string().nullable(),
  unable_work_to: Yup.string().nullable(),

  // Situational Number
  mammography_certification: Yup.string().max(254).nullable(),
  investigation_device_exception: Yup.string().max(254).nullable(),

  // Vision Claim
  is_certification_code_indicator: Yup.boolean().nullable(),
  certification_type: Yup.mixed().nullable(),
  condition_indicators: Yup.array().of(Yup.string().required()).nullable(),

  // Hospice
  care_plan_oversight: Yup.string().max(254).nullable(),
  hospice: Yup.string().max(254).nullable(),

  // Medicaid
  resubmission_code: Yup.string().max(254).nullable(),
  original_reference: Yup.string().max(254).nullable(),
  service_auth_exception_code: Yup.string().max(254).nullable(),
  medical_referral_access: Yup.string().max(254).nullable(),
  is_family_planning: Yup.boolean().nullable(),
  is_epsdt: Yup.boolean().nullable(),
  is_copay_exempt: Yup.boolean().nullable(),
  epsdt_condition_code: Yup.mixed().nullable(),
  epsdt_referral_code: Yup.string().max(254).nullable(),

  // Chiropractor Claims
  initial_treatment_date: Yup.string().nullable(),
  acute_manifestation_date: Yup.string().nullable(),
  last_xray_date: Yup.string().nullable(),
  nature_condition: Yup.mixed().nullable(),
  description: Yup.string().nullable(),
  xray_available: Yup.mixed().nullable(),

  // Supplemental Information
  report_type_code_id: Yup.mixed().nullable(),
  report_transmission: Yup.mixed().nullable(),
  attach_ctrl_number: Yup.string().nullable(),

  // MISC (Claim Note)
  note_reference_code: Yup.mixed().nullable(),
  note: Yup.string().nullable()
});
