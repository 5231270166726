import { Grid, Typography } from '@material-ui/core';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.name &&
        <>
          <Grid item xs={4}>
            <Typography>
              User Name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.name}
            </Typography>
          </Grid>
        </>
      }

      {params?.email &&
        <>
          <Grid item xs={4}>
            <Typography>
              Email:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.email}
            </Typography>
          </Grid>
        </>
      }

      {params?.role &&
        <>
          <Grid item xs={4}>
            <Typography>
              User role:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.role}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
