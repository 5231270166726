import { api } from '../api';

export const createChecklistItem = (todo) => {
  return api.post('/todo-items', todo).then(({ data: { data } }) => data);
};

export const updateChecklistItem = ({ id, ...todo }) => {
  return api.put(`/todo-items/${id}`, todo).then(({ data: { data } }) => data);
};

export const updateItemPosition = ({ id, index }) => {
  return api.put(`/todo-items/position/${id}`, { index })
    .then(({ data: { data } }) => data);
};

export const deleteChecklistItem = (id) => {
  return api.delete(`/todo-items/${id}`).then(({ data }) => data);
};
