import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Grid } from '@material-ui/core';
import {
  insuranceFormTypesMap
} from '../../../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/insuranceFormTypesMap';
import { Button } from '../../../../../Button';
import { CompanyLink } from '../../../../../CompanyLink';
import { CompanyListModal } from '../../../../../CompanyListModal';
import { useModal } from '../../../../../ModalsProvider';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { formStatusesMap, formTypesMap } from '../../../formTypesMap';

export const InsCompanyStep = ({ form, setFormValue }) => {
  const { openModal } = useModal();
  const [ company, setCompany ] = useState(form?.primary_insurance?.insurance_company);
  const { enqueueSnackbar } = useSnackbar();

  const addCompany = () => {
    openModal(CompanyListModal, {
      payload: {
        insuranceInfo: {
          form_type: form?.form_type,
          name: form?.primary_insurance_name,
          group_id_number: form?.group_id_number,
          primary_insurance_id_number: form?.primary_insurance_id_number,
          primary_insurance_name: form?.primary_insurance_name,
          primary_insurance_holder_first_name: form?.primary_insurance_holder_first_name,
          primary_insurance_holder_last_name: form?.primary_insurance_holder_last_name,
          primary_insurance_holder_dob: form?.primary_insurance_holder_dob,
          relationship_to_patient: form?.relationship_to_patient,
          attorney_first_name: form?.attorney_first_name || null,
          attorney_last_name: form?.attorney_last_name || null,
          attorney_email: form?.attorney_email,
          attorney_phone: form?.attorney_phone,
          address: form?.home || form?.patient?.home || null,
          dob: form.primary_insurance_holder_dob || form.birthday ||
            form?.patient?.birthday || null,
          first_name: form?.first_name || form?.patient?.first_name || null,
          last_name: form?.last_name || form?.patient?.last_name || null
        },
        type_insurance: form?.form_type !== formTypesMap.private
          ? insuranceFormTypesMap?.[form?.form_type]
          : null,
        status_type: form?.form_type === formTypesMap.private
          ? formStatusesMap.primary
          : formStatusesMap.law_office,
        medicalFormID: form?.is_editable ? form.id : null
      },

      onModalResolved: (data) => {
        const company = { insurance_company_id: data };

        setCompany(data);
        setFormValue({ ...form, company, primary_insurance: { ...form.primary_insurance, ...company } });
        enqueueSnackbar('Successfully created', { variant: 'success' });
      }
    });
  };

  return (
    <>
      <Box pb={2}>
        <Button
          disabled={company?.id}
          size="small"
          variant="contained"
          color="primary"
          onClick={addCompany}
        >
          Add Company
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReadonlyCheckbox
            checked={!!company?.id}
            title="Company created"
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <CompanyLink company={company} />
        </Grid>
      </Grid>
    </>
  );
};
