import bg from './bg.png';

export const styles = ({
  spacing,
  palette,
  typography,
  shape: { borderRadius },
  shadows
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    background: `${palette.grey[500]} url(${bg}) center / cover`,
    padding: spacing(2, 3),
    minHeight: 90
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    color: palette.common.white
  },

  action: {
    color: palette.common.white
  },

  logoIcon: {
    position: 'relative',
    width: 50,
    height: 50,
    marginRight: spacing(2),
    borderRadius: '50%',
    backgroundColor: palette.common.white,
    boxShadow: shadows[2]
  },

  icon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: typography.pxToRem(30),
    color: palette.indigo
  }
});
