import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Tab, Tooltip, useTheme } from '@material-ui/core';
import { isEmpty, isEqual } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { mdiCalendarClockOutline, mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useSelector } from 'react-redux';
import { Tabs } from '../../../../../../../components/Tabs';
import { useResizeObserver } from '../../../../../../../helpers/hooks';
import { Badge } from '../../../../../../../components/Badge';
import { SvgIcon } from '../../../../../../../components/SvgIcon';
import { FiltersBarModal } from '../../../../../../../components/FiltersBarModal';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../components/IconButton';
import { Fab } from '../../../../../../../components/Fab';
import { AddDiscoveryModal } from './AddDiscoveryModal';
import { CaseDiscoveriesContext } from './CaseDiscoveriesProvider';
import { userTypesMap } from './DiscoveryForm/userTypeOptions';
import { filterFields } from './filterFields';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

const initialFilter = {
  type: null,
  user_type: userTypesMap.defendant
};

export const getFiltersValuesFromOptions = (values) => ({
  ...values,
  type: values?.type?.value ?? null,
  user_type: values?.user_type?.value ?? values?.user_type ?? null,
  users: values?.users?.map((user) => user?.user?.id || user?.id) || null,
  companies: values?.companies?.map((company) => company?.id) || null
});

export const Discovery = ({
  isTabPage,
  page,
  disableRightActions = false,
  hiddenColumns,
  height = null,
  widgetFilters = {},
  isDisabledOpen = false,
  updateFilters = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { openModal } = useModal();
  const isOpen = useSelector(({ globalUser }) => {
    return globalUser?.data?.layout?.[page]?.prevLayoutsOptions?.isOpen?.[props?.item?.i];
  });
  const { caseItem, filter, addDiscovery, applyFilter, onCaseItemUpdate } = useContext(CaseDiscoveriesContext);
  const [ filters, setFilters ] = useState({ ...initialFilter, ...widgetFilters?.discovery });
  const [ isFilterDefault, setIsFilterDefault ] = useState(true);
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const theme = useTheme();
  const isMobile = width <= theme.breakpoints.values.tablet;

  const onFiltersUpdate = (values) => {
    setFilters(values);
    updateFilters({ discovery: values });
  };

  const handleResetFilter = (applyFilter) => () => {
    applyFilter(initialFilter);
    onFiltersUpdate(initialFilter);
  };

  const handleTabChange = (event, newValue) => {
    applyFilter({ user_type: newValue });
  };

  const createDiscovery = () => {
    openModal(AddDiscoveryModal, {
      payload: {
        caseItem,
        onCaseItemUpdate
      },
      onModalResolved: (discovery) => {
        addDiscovery(discovery);
      }
    });
  };

  const handleFiltersChange = (filter, applyFilter) => () => {
    openModal(FiltersBarModal, {
      payload: {
        title: 'Discovery Filters',
        initialValues: filters,
        fields: filterFields(caseItem),
        setFilters: onFiltersUpdate
      },
      onModalResolved: (values) => {
        applyFilter(getFiltersValuesFromOptions({ ...values, user_type: filter?.user_type }));
      }
    });
  };

  useEffect(() => {
    if (!isEmpty(widgetFilters?.discovery)) {
      applyFilter(getFiltersValuesFromOptions({
        ...widgetFilters?.discovery,
        user_type: filter?.user_type
      }));
    }
  }, [ isOpen ]);

  useEffect(() => {
    setIsFilterDefault(isEqual(initialFilter, filters));
  }, [ filter ]);

  useEffect(() => {
    if (!isEmpty(widgetFilters?.discovery)) {
      applyFilter(getFiltersValuesFromOptions({
        ...widgetFilters?.discovery,
        user_type: filter?.user_type
      }));
    }
  }, [ isOpen ]);

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      disableDivider={disableRightActions}
      title="Discovery"
      leftActions={[
        <SvgIcon color="inherit" fontSize="small">
          <Icon path={mdiCalendarClockOutline} />
        </SvgIcon>
      ]}
      icon={(
        <Tooltip title="Add Discovery">
          <Fab
            color="primary"
            size="small"
            onClick={createDiscovery}
          >
            <AddIcon fontSize="small" />
          </Fab>
        </Tooltip>
      )}
      endIcon={(
        <Box display="flex" alignItems="center">
          {(!isTabPage || width <= 1000) &&
            <>
              <Tooltip title="Set Filter">
                <IconButton
                  color="primary"
                  onClick={handleFiltersChange(filter, applyFilter)}
                >
                  <Badge
                    invisible={isFilterDefault}
                    badgeContent={
                      <Tooltip title="Filter is used">
                        <SvgIcon fontSize="small" color="success">
                          <CheckCircleIcon/>
                        </SvgIcon>
                      </Tooltip>
                    }
                  >
                    <SvgIcon fontSize="small">
                      <Icon path={mdiFilter}/>
                    </SvgIcon>
                  </Badge>
                </IconButton>
              </Tooltip>

              <Tooltip title="Reset Filter">
                <IconButton
                  color="primary"
                  onClick={handleResetFilter(applyFilter)}
                >
                  <BackupIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            </>
          }
        </Box>
      )}
      rightActions={disableRightActions ? null : [
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box
        ref={rootRef}
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="hidden"
      >
        {isTabPage && width > 1000 &&
          <FiltersBar caseItem={caseItem} />
        }

        <Tabs
          value={filter.user_type}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab value={userTypesMap.defendant} label="Defendant" />
          <Tab value={userTypesMap.plaintiff} label="Plaintiff" />
          <Tab value={null} label="All" />
        </Tabs>

        <Box flexGrow={1}>
          {isMobile ? <Grid /> : <List hiddenColumns={hiddenColumns} />}
        </Box>
      </Box>
    </ContentCard>
  );
};
