import {
  Box,
  List as MuiList,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../ListRow';
import { rangeMotionMap } from '../../../../../spinalExaminationMap';
import { columnsWidthsLg, columnsWidthsSm } from '../../columnsWidths';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const RangeMotion = ({ report }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <Box mt={4}>
      <Typography gutterBottom variant="h5">Range of motion-Servical spine</Typography>

      <Box my={2}>
        <div className={classes.root}>
          <ListRow header>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
              <Typography noWrap variant="subtitle2" color="textSecondary">
                Cervical Spine Range of Motion
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.degrees_normal}>
              <Typography noWrap variant="subtitle2">
                Degrees Normal
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.range_of_motion}>
              <Typography noWrap variant="subtitle2">
                Range of Motion
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.fraction_of_normal}>
              <Typography noWrap variant="subtitle2">
                Fraction of Normal
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.pain}>
              <Typography noWrap variant="subtitle2">
                Pain
              </Typography>
            </ListRowCell>
          </ListRow>

          <MuiList disablePadding>
            {Object.keys(rangeMotionMap)?.map((item, i) => (
              <ListRow key={i}>
                <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                  <Typography noWrap variant="h5">
                    {rangeMotionMap[item]}:
                  </Typography>
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.degrees_normal}>
                  {report[item]?.degrees_normal || '-'}
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.range_of_motion}>
                  {report[item]?.range_of_motion || '-'}
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.fraction_of_normal}>
                  {report[item]?.fraction_of_normal || '-'}
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.pain}>
                  {report[item]?.pain || '-'}
                </ListRowCell>
              </ListRow>
            ))}
          </MuiList>
        </div>
      </Box>
    </Box>
  );
};
