import { Grid, Typography, TableRow, Table, TableBody } from '@material-ui/core';
import { AppointmentLink } from '../../../../../components/appointments/AppointmentLink';
import { TableCell } from '../../../../../components/TableCell';
import { expensesStatusesMap } from '../../../../../components/billing';
import { UserLink } from '../../../../../components/UserLink';
import { Label } from '../../../../../components/Label';

export const ApptModel = ({ model }) => {
  const isConfirmed = !!model?.confirmed_at;

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Appointment Date</Typography>
              </TableCell>

              <TableCell disableBorder>
                <AppointmentLink appointment={model} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Appointment Status</Typography>
              </TableCell>

              <TableCell disableBorder>
                {!model.arrival_status ? (
                  <Typography>-</Typography>
                ) : (
                  <Label
                    label={expensesStatusesMap?.[isConfirmed ? 'approved' : 'pending']?.name}
                    color={expensesStatusesMap?.[isConfirmed ? 'approved' : 'pending']?.color}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Patient</Typography>
              </TableCell>

              <TableCell disableBorder>
                <UserLink
                  size="sm"
                  variant="inherit"
                  user={model?.patient}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
