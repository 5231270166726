import { Box, Grid, Link as MuiLink, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { Icon } from '@mdi/react';
import { mdiFileDocumentEditOutline } from '@mdi/js';
import { getProviderIdType } from '../../../../../../../utils/getProviderIdType';
import { AuthorizationViewModal } from '../../../../../../../components/appointments';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { SvgIcon } from '../../../../../../../components/SvgIcon';
import { UserLink } from '../../../../../../../components/UserLink';

export const ClaimInfo = ({ claim }) => {
  const { openModal } = useModal();

  const viewAuthorizationNumber = () => {
    openModal(AuthorizationViewModal, {
      payload: {
        authorization: claim.authorization
      }
    });
  };

  return (
    <ContentCard
      disableScrollbars
      variant="outlined"
      color="info"
      title="Claim Info"
      leftActions={[
        <Box mr={1}>
          <SvgIcon color="info"><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>
        </Box>
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={5}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography color="textSecondary">Office</Typography>
            </Grid>

            <Grid item xs={7}>
              <Box display="flex">
                <Box clone mr={0.5}>
                  <LocationOnIcon fontSize="small" />
                </Box>

                <AddressLink
                  variant="h5"
                  address={claim.office?.full_address}
                  lat={claim.office?.lat}
                  lng={claim.office?.lng}
                >
                  {claim.office?.full_address || '-'}
                </AddressLink>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5}>
              <Typography color="textSecondary">Ref. Physician</Typography>
            </Grid>

            <Grid item xs={7}>
              <UserLink
                size="md"
                variant="h5"
                user={claim.provider_referring}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography color="textSecondary">Referring NPI ID.</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="h5">
                {getProviderIdType(claim.provider_referring?.provider_id_types, 'NPI')?.identifier || '-'}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography color="textSecondary">Auth/Referral #</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="h5">
                {!claim.authorization?.authorization ? '-' : (
                  <MuiLink variant="body1" onClick={viewAuthorizationNumber}>
                    {claim.authorization?.authorization}
                  </MuiLink>
                )}

                &nbsp;/ {claim.referral_number || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5}>
              <Typography color="textSecondary">Rend. Physician</Typography>
            </Grid>

            <Grid item xs={7}>
              <UserLink
                size="md"
                variant="h5"
                user={claim.provider_rendering}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5}>
              <Typography color="textSecondary">Primary Care Phys</Typography>
            </Grid>

            <Grid item xs={7}>
              <UserLink
                size="md"
                variant="h5"
                user={claim.provider_primary_care}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography color="textSecondary">Primary Care NPI ID</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="h5">
                {getProviderIdType(claim.provider_primary_care?.provider_id_types, 'NPI')?.identifier || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography color="textSecondary">Total Balance, $</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h5">
                <CurrencyFormat value={claim.billing_info?.total_balance} />
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography color="textSecondary">Total Charged, $</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h5">
                <CurrencyFormat value={claim.billing_info?.total_charged} />
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography color="textSecondary">Total Paid, $</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h5">
                <CurrencyFormat value={claim.billing_info?.total_paid} />
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography color="textSecondary">Ins. Balance, $</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h5">
                <CurrencyFormat value={claim.billing_info?.total_balance} />
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography color="textSecondary">Patient Balance, $</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h5">
                <CurrencyFormat value={claim.billing_info?.patient_balance} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContentCard>
  );
};
