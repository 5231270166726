import { Box, FormControlLabel, Grid } from '@material-ui/core';
import { Checkbox, NdcCodesSelect } from '../../../../../../components/FormField';

export const CurrentMedications = () => {
  return (
    <Grid container spacing={0} component={Box} px={2}>
      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="See List"
          control={
            <Checkbox
              color="primary"
              name="forms.current_medications.see_list"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Non-Complaint with Pain Management Agreement"
          control={
            <Checkbox
              color="primary"
              name="forms.current_medications.non_complaint_with_pain_management_agreement"
            />
          }
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <Box my={2}>
          <NdcCodesSelect
            multiple
            formattedValue={false}
            name="forms.current_medications.medications"
          />
        </Box>
      </Grid>
    </Grid>
  );
};
