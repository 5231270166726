import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, Collapse, Grid, Typography } from '@material-ui/core';
import { TestCodesSelect, TextField } from '../../../../../../components/FormField';
import { ChildrenContent, testChildInitialValues } from '../TestsBody';

export const TestsBodySingle = () => {
  const { values, setFieldValue } = useFormikContext();
  const [ children, setChildren ] = useState([]);

  const handleChangeTest = (option) => {
    if (option && option?.data) {
      setChildren( option?.data?.children || []);
      setFieldValue('test_description', option?.data?.description);

      if (!option?.data?.children.length) {
        setFieldValue('items', []);
        setFieldValue('value', null);
        setFieldValue('standard_range', null);
      } else {
        setFieldValue('items', [ testChildInitialValues ]);
      }
    }
  };

  useEffect(() => {
    if (values?.items?.length && values?.test?.children?.length) {
      const childrenList = values?.test?.children.filter((item) => (
        !values.items.find((child) => child.test_id === item.id))
      );

      setChildren([ ...values.items, ...childrenList ]);
    }
  }, []);

  useEffect(() => {
    if (values?.items?.length && !values?.items?.filter((child) => !!child?.is_checked)?.length) {
      setFieldValue(`items.${0}.is_checked`, true);
    }
  }, [ values.items ]);

  return (
    <Box my={6} flex={1}>
      <Box mt={4} color="info.main">
        <Typography variant="h3" color="inherit">
          Select Test
        </Typography>
      </Box>

      <Grid container spacing={2} alignItems="flex-end">
        <Grid item sm={3} xs={12}>
          <TestCodesSelect
            name="test_id"
            TextFieldProps={{ required: true, margin: 'dense' }}
            onChange={handleChangeTest}
          />
        </Grid>

        <Grid item sm={9} xs={12}>
          {values?.test_id &&
            <Box mt={1}>
              <Typography variant="body2" color="textSecondary">
                Description
              </Typography>
            </Box>
          }

          <Typography>{values?.test_description}</Typography>
        </Grid>

        {(children && !!children.length) ?
          <Collapse
            unmountOnExit
            in={!!children.length}
            timeout="auto"
            component={Box}
            flexGrow={1}
            py={2}
          >
            <Box ml={1}>
              <Typography variant="h5">Component:</Typography>
            </Box>

            {children?.map((item, index) => (
              <Grid
                key={index}
                container
                spacing={2}
                component={Box}
                alignItems="center"
                pb={3}
              >
                <ChildrenContent
                  index={index}
                  item={item}
                  label={item.name}
                  name={`items.${index}`}
                />
              </Grid>
            ))}
          </Collapse>
          :
          <Collapse
            in={!!values?.test_id && !children?.length}
            unmountOnExit
            timeout="auto"
            component={Box}
            flexGrow={1}
          >
            <Grid container spacing={2} component={Box} m="0 !important">
              <Grid item sm={3} xs={6}>
                <TextField
                  required
                  name="value"
                  label="Your Value"
                  placeholder="Enter value..."
                />
              </Grid>

              <Grid item sm={3} xs={6}>
                <TextField
                  required
                  name="standard_range"
                  label="Standart Range"
                  placeholder="Enter range..."
                />
              </Grid>
            </Grid>
          </Collapse>
        }
      </Grid>
    </Box>
  );
};
