export const sufferingsMap = {
  high_blood_pressure: 'high_blood_pressure',
  deep_vein_thrombosis: 'deep_vein_thrombosis',
  liver_disease: 'liver_disease',
  heart_disease_or_attach: 'heart_disease_or_attach',
  stroke: 'stroke',
  cancer_where: 'cancer_where',
  elevated_cholesterol: 'elevated_cholesterol',
  ulcer_disease: 'ulcer_disease',
  gastritis: 'gastritis',
  reflux_disease: 'reflux_disease',
  asthma: 'asthma',
  osteoporosis: 'osteoporosis',
  kidney_disease_problem: 'kidney_disease_problem',
  seizures: 'seizures',
  arthritis: 'arthritis',
  thyroid_hyper_or_hypo: 'thyroid_hyper_or_hypo',
  tuberculosis: 'tuberculosis',
  pulmonary_embolism: 'pulmonary_embolism',
  polio: 'polio',
  rheumatic_fever: 'rheumatic_fever',
  gout: 'gout',
  depression: 'depression',
  diabetes: 'diabetes',
  other_please_list: 'other_please_list'
};

export const sufferingsLabelsMap = {
  [sufferingsMap.high_blood_pressure]: 'High blood pressure',
  [sufferingsMap.deep_vein_thrombosis]: 'Deep vein thrombosis',
  [sufferingsMap.liver_disease]: 'Liver disease',
  [sufferingsMap.heart_disease_or_attach]: 'Heart disease or attach',
  [sufferingsMap.stroke]: 'Stroke',
  [sufferingsMap.cancer_where]: 'Cancer',
  [sufferingsMap.elevated_cholesterol]: 'Elevated cholesterol',
  [sufferingsMap.ulcer_disease]: 'Ulcer disease',
  [sufferingsMap.gastritis]: 'Gastritis',
  [sufferingsMap.reflux_disease]: 'Reflux disease',
  [sufferingsMap.asthma]: 'Asthma',
  [sufferingsMap.osteoporosis]: 'Osteoporosis',
  [sufferingsMap.kidney_disease_problem]: 'Kidney disease/ problem',
  [sufferingsMap.seizures]: 'Seizures',
  [sufferingsMap.arthritis]: 'Arthritis',
  [sufferingsMap.thyroid_hyper_or_hypo]: 'Thyroid (hyper or hypo)',
  [sufferingsMap.tuberculosis]: 'Tuberculosis',
  [sufferingsMap.pulmonary_embolism]: 'Pulmonary embolism',
  [sufferingsMap.polio]: 'Polio',
  [sufferingsMap.rheumatic_fever]: 'Rheumatic fever',
  [sufferingsMap.gout]: 'Gout',
  [sufferingsMap.depression]: 'Depression',
  [sufferingsMap.diabetes]: 'Diabetes',
  [sufferingsMap.other_please_list]: 'Other'
};
