export const styles = ({ palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&::before, &::after': {
      content: '""',
      height: 1,
      flexGrow: 1,
      backgroundColor: palette.grey[200]
    }
  }
});
