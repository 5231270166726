import { Box, Grid, Typography } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../ReadonlyCheckbox';

export const Indicators = ({ authorization, serviceLine }) => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">
          Indicators
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Emergency Indicator
          </Typography>
        </Grid>

        <Grid item md={6}>
          <ReadonlyCheckbox
            title={(serviceLine.emergency_indicator === 'n/a') && 'N/A'}
            iconColor="success"
            checked={[ 'yes', 'n/a' ].includes(serviceLine.emergency_indicator)}
          />
        </Grid>

        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            EPSDT
          </Typography>
        </Grid>

        <Grid item md={6}>
          <ReadonlyCheckbox
            title={(serviceLine.epsdt === 'n/a') && 'N/A'}
            iconColor="success"
            checked={[ 'yes', 'n/a' ].includes(serviceLine.epsdt)}
          />
        </Grid>

        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Family Planning
          </Typography>
        </Grid>

        <Grid item md={6}>
          <ReadonlyCheckbox
            title={(serviceLine.family_planning === 'n/a') && 'N/A'}
            iconColor="success"
            checked={[ 'yes', 'n/a' ].includes(serviceLine.family_planning)}
          />
        </Grid>

        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Copay Exempt
          </Typography>
        </Grid>

        <Grid item md={6}>
          <ReadonlyCheckbox
            title={(serviceLine.copay_exempt === 'n/a') && 'N/A'}
            iconColor="success"
            checked={[ 'yes', 'n/a' ].includes(serviceLine.copay_exempt)}
          />
        </Grid>

        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Line Note Reference Code
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="subtitle2">
            {serviceLine.reference_code?.label || '-'}
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Line Note
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="subtitle2">
            {serviceLine.line_note || '-'}
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Authorization #
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="subtitle2">
            {authorization?.authorization || '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
