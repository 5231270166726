export const styles = ({ spacing }) => ({
  intervalGroup: {
    flexWrap: 'nowrap'
  },

  intervalField: {
    minWidth: 60,
    width: 60,
    marginRight: spacing(2)
  },

  ends: {
    display: 'flex'
  },

  ends__label: {
    width: 60,
    marginRight: spacing(2)
  }
});
