import { useRef } from 'react';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Formik } from 'formik';
import { mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import {
  makeStyles,
  Grid,
  SvgIcon,
  Box,
  useTheme
} from '@material-ui/core';
import { useResizeObserver } from '../../helpers/hooks';
import { AppBar } from '../AppBar';
import { ActionButtons } from './ActionButtons';

const styles = ({ spacing, palette }) => ({
  toolbar: {
    display: 'flex',
    paddingTop: ({ disableTopPaddings }) => disableTopPaddings ? null : spacing(),
    paddingBottom: spacing(),
    backgroundColor: ({ color }) => color || palette.grey[50]
  },

  rootBorder: {
    borderBottom: 0
  }
});

const DefaultIconComponent = () => {
  return (
    <SvgIcon color="primary">
      <Icon path={mdiFilter} />
    </SvgIcon>
  );
};

const useStyles = makeStyles(styles);

export const FiltersBar = ({
  formikRef = {},
  enableSettings = false,
  disableResponsiveView = false,
  disableIcon,
  iconComponent,
  color,
  isResetForm = false,
  className,
  toolbarClassName,
  disableTopPaddings,
  disableBorder = false,
  fields = [],
  fieldsList = [],
  fullWidthFields,
  hiddenFields = [],
  form = null,
  initialValues = {},
  onReset = () => {},
  onFieldHide = () => {},
  filterKey = null,
  ToolbarProps,

  ...props
}) => {
  const rootRef = useRef();
  const theme = useTheme();
  const { width } = useResizeObserver({ ref: rootRef });
  const hiddenFilterFields = useSelector(({ globalUser }) => globalUser?.data?.hiddenFilterFields?.[filterKey]);
  const visibleFields = fieldsList.filter(({ fieldKey }) => !hiddenFields.includes(fieldKey));
  const classes = useStyles({ color, disableTopPaddings });
  const isMobile = width <= theme.breakpoints.values.md;
  const fullWidth = isUndefined(fullWidthFields) ? isMobile : fullWidthFields;
  const fieldProps = fullWidth ? { xs: 12 } : {};
  const isMobileView = !disableResponsiveView && fullWidth;

  const handleFieldRemoved = (field) => {
    formikRef.current?.setFieldValue(field, null);
    onFieldHide(field);
  };

  return (
    <AppBar
      ref={rootRef}
      className={cn(classes.root, className, { [classes.rootBorder]: disableBorder })}
      ToolbarProps={{ className: cn(classes.toolbar, toolbarClassName), ...ToolbarProps }}

      {...props}
    >
      <Formik
        initialValues={initialValues}
        onReset={onReset}
        onSubmit={null}
      >
        {(props) => {
          formikRef.current = props;

          return (
            <Grid
              container
              spacing={2}
              justify={isMobileView ? 'space-between' : 'flex-start'}
              wrap="nowrap"
              component={Box}
              m="0 !important"
              p={0}
              pb={isMobileView ? 2 : 0}
            >
              <Grid item container spacing={isMobileView ? 1 : 3} alignItems="flex-end">
                {(!disableIcon || !isMobile) &&
                  <Grid item>
                    <Box mt={isMobileView ? 0 : 1}>
                      {filterKey ? iconComponent : <DefaultIconComponent />}
                    </Box>
                  </Grid>
                }

                {isMobileView && (
                  <Grid item xs container spacing={2} justify="flex-end">
                    <ActionButtons
                      isResetForm={isResetForm}
                      enableSettings={enableSettings}
                      filterKey={filterKey}
                      visibleFields={visibleFields}
                      onFieldRemoved={handleFieldRemoved}

                      {...props}
                    />
                  </Grid>
                )}

                {visibleFields?.map(({ field, fieldKey }) => !hiddenFilterFields?.includes(fieldKey) && field && (
                  <Grid key={fieldKey} item {...fieldProps}>
                    {field}
                  </Grid>
                ))}

                {fields?.map((item, i) => (
                  <Grid key={i} item {...fieldProps}>
                    {item}
                  </Grid>
                ))}
              </Grid>

              {!isMobileView && (
                <ActionButtons
                  isResetForm={isResetForm}
                  enableSettings={enableSettings}
                  filterKey={filterKey}
                  visibleFields={visibleFields}
                  onFieldRemoved={handleFieldRemoved}
                  {...props}
                />
              )}

              {form}
            </Grid>
          );
        }}
      </Formik>
    </AppBar>
  );
};
