import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../../api';
import * as paymentsApi from '../../../../api/billing';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { useModal } from '../../../ModalsProvider';
import { usePrevious } from '../../../../helpers/hooks';
import * as filesTypes from '../../../../app/Dashboard/BillingPage/FilesContext/types';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const PaymentsContext = createContext(null);

export const PaymentsContextProvider = ({
  children,
  filter: filterProp = {},
  setFilesLastGlobalAction
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    payments,
    pagination,
    isFetching,
    isFetched,
    filter,
    selectedPaymentsIDs
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchPayments = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.PAYMENTS_FETCH_REQUEST });

    paymentsApi.fetchPayments({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then((data) => {
      dispatch({ type: types.PAYMENTS_FETCH_SUCCESS, payload: data });
    });
  };

  const uploadFiles = ({
    owner_id,
    owner_type,
    selectedFiles,
    setSubmitting,
    setErrors
  }  = {}) => {
    selectedFiles.forEach((file) => {
      const formData = new FormData();

      formData.append('file', file, file.original_filename);

      file.requestData = formData;

      paymentsApi.uploadFile(formData, { params: { owner_id, owner_type } }).then((data) => {
        setFilesLastGlobalAction({ type: filesTypes.FILE_UPLOAD_SUCCESS, payload: data });
        enqueueSnackbar('Files successfully uploaded to payment', { variant: 'success' });
      }).catch(({ status, data: { errors } = {} }) => {
        setSubmitting(false);

        if (status === 422 && errors) {
          setErrors(errors);
        }
      });
    });
  };

  const massCreatePayment = ({
    paymentData,
    setSubmitting,
    setErrors,
    goBack
  } = {}) => {
    paymentsApi.massCreatePayment(paymentData).then((data) => {
      dispatch({ type: types.MASS_ADD_PAYMENT, payload: data });
      enqueueSnackbar('Payment successfully created', { variant: 'success' });
      goBack();
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        setErrors(errors);
        setSubmitting(false);
        enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  const updatePayment = ({
    paymentData,
    setSubmitting,
    setErrors,
    goBack,
    selectedFiles
  } = {}) => {
    paymentsApi.updatePayment(paymentData).then((data) => {
      if (selectedFiles.length > 0) {
        uploadFiles({
          owner_id: data.id,
          owner_type: 'payment',
          selectedFiles,
          setSubmitting,
          setErrors
        });
      }

      dispatch({ type: types.UPDATE_PAYMENT_IN_LIST, payload: data });
      enqueueSnackbar('Payment successfully updated', { variant: 'success' });
      goBack();
    }).catch(({ errors }) => {
      enqueueSnackbar('Payment not updated', { variant: 'error' });

      if (errors) {
        setErrors(errors);
        setSubmitting(false);
      }
    });
  };

  const assignPayment = ({
    invoice_id,
    setSubmitting,
    setErrors,
    goBack
  }) => {
    paymentsApi.assignPayment(invoice_id)
      .then((data) => {
        dispatch({ type: types.ASSIGN_PAYMENT, payload: data });
        enqueueSnackbar('Payment successfully updated', { variant: 'success' });
        goBack();
      })
      .catch(({ message, errors }) => {
        if (errors) {
          const errorMessage = Object.values(errors);

          setErrors(errors);
          setSubmitting(false);
          enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
        }
      });
  };

  const deletePayment = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        paymentsApi.deletePayment(id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchPayments({ page: filter.page });
          } else if (payments.length - 1 === 0 && filter.page !== 1) {
            fetchPayments({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_PAYMENT, payload: id });
          }

          enqueueSnackbar('Payment successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Payment is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const massDeletePayments = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        paymentsApi.massDeletePayments(IDs).then(() => {
          if (pagination.last_page > filter.page) {
            fetchPayments({ page: filter.page });
          } else if (payments.length - 1 === 0 && filter.page !== 1) {
            fetchPayments({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.MASS_DELETE_PAYMENT, payload: IDs });
          }

          enqueueSnackbar('Payments successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Payments are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetPayments = (newFilter) => {
    dispatch({ type: types.RESET_PAYMENTS, payload: newFilter });

    fetchPayments({ page: 1, ...newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchPayments({ page: filter.page + 1 });
    }
  };

  const allPaymentsIsSelected = () => {
    return payments.length === selectedPaymentsIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_PAYMENT_SELECTION, payload: id });
  };

  const toggleAllPaymentsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_PAYMENTS_SELECTION });
  };

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetPayments(filter);
    }
  }, [ filter, prevFilter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  const providerValue = {
    payments,
    isFetching,
    isFetched,
    pagination,
    filter,
    meta: {
      ...filter,
      ...pagination
    },
    selectedPaymentsIDs,

    updatePayment,
    deletePayment,
    loadNextPage,
    resetPayments,
    allPaymentsIsSelected,
    toggleItemSelection,
    toggleAllPaymentsSelection,
    massCreatePayment,
    massDeletePayments,
    assignPayment,
    applyFilter
  };

  return (
    <PaymentsContext.Provider value={providerValue}>
      {children}
    </PaymentsContext.Provider>
  );
};
