import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Box, Typography } from '@material-ui/core';

export const Allergies = ({ allergies }) => {
  return (
    allergies.map((item, i) => (
      <Box key={i} display="flex" alignItems="center">
        <Typography>{i + 1}.</Typography>
        <Box px={1} color="success.main">
          <CheckCircleIcon fontSize="small" color="inherit"/>
        </Box>

        <Typography>{item.name}</Typography>
      </Box>
    ))
  );
};
