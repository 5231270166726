import moment from 'moment';
import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  patient_id: Yup.mixed().nullable().required(),
  provider_id: Yup.mixed().nullable().required(),
  office_id: Yup.mixed().nullable(),
  name: Yup.string().nullable().required().min(1).max(50),
  note: Yup.string().nullable().required().min(1).max(1000),
  dor: Yup.mixed().nullable().required(),
  doi: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today')
    .when('is_range', (isRange, schema) => isRange ? schema : schema.required()),
  doi_from: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today')
    .when('is_range', (isRange, schema) => isRange ? schema.required() : schema),
  doi_to: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today')
    .when('is_range', (isRange, schema) => isRange ? schema.required() : schema)
});
