import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../InfiniteLoader';
import { Loader } from '../../../../Loader';
import { ScrollbarsCustom } from '../../../../ScrollbarsCustom';
import { Sticky } from '../../../../Sticky';
import { LoginServicesContext } from '../../LoginServicesProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const List = ({ width, autoHeight, hiddenColumns, RowProps }) => {
  const {
    isFetching,
    isFetched,
    meta,
    loginServices,
    loadNextPage,
    resetLoginServices
  } = useContext(LoginServicesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetLoginServices();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} autoHeight={autoHeight}>
      <Loader p={2} loading={!isFetched} render={
        () => !meta.total ? (
          <Box p={2}>
            <Typography align="center">No services found</Typography>
          </Box>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader hiddenColumns={hiddenColumns} width={width} ListRowProps={RowProps?.ListRowProps} />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={loginServices}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                RowComponent={Row}
                RowProps={{ hiddenColumns, width, ...RowProps }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </ScrollbarsCustom>
  );
};
