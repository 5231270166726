export const styles = ({
  spacing,
  palette: { grey, primary },
  typography: { pxToRem, fontWeightMedium },
  shape: { borderRadius },
  shadows,
  transitions
}) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: grey[50],
    border: '2px solid transparent',
    borderRadius,
    color: ({ color = grey[500] }) => color,
    transition: transitions.create([ 'border-color', 'box-shadow' ]),
    margin: spacing(1, 0.5)
  },

  card_hovered: {
    cursor: 'pointer',

    '&:hover': {
      boxShadow: shadows[4]
    }
  },

  card_active: {
    borderColor: 'inherit'
  },

  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: pxToRem(10)
  },

  name: {
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(14),
    color: grey[600]
  },

  count: {
    color: primary.contrastText,
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(13),
    marginLeft: spacing(),
    padding: spacing(0, 1),
    backgroundColor: ({ color = grey[500] }) => color,
    borderRadius: 50
  },

  amount: {
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(26)
  }
});
