import {
  Box,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Checkbox, PosCodesSelect, TextField } from '../../../../../components/FormField';
import { IconButton } from '../../../../../components/IconButton';
import { useModal } from '../../../../../components/ModalsProvider';
import { npiEnumerationTypes, SearchNPIRecordsModal } from '../../../../../components/SearchNPIRecordsModal';

export const MedicInfo = () => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const setFetchedFields = (office) => {
    setFieldValue('medical_data.npi', office.number);
  };

  const openFiltersModal = () => {
    const initialValues = {
      organization_name: values?.company?.name,
      enumeration_type: npiEnumerationTypes.office
    };

    openModal(SearchNPIRecordsModal, {
      payload: { initialValues },
      onModalResolved: (office) => {
        setFetchedFields(office);
      }
    });
  };

  return (
    <Box mb={7}>
      <Typography gutterBottom variant="h3">Title (medical data)</Typography>

      <Grid container spacing={isMobile ? 2 : 3}>
        <Grid item sm={8} xs={12}>
          <PosCodesSelect
            name="medical_data.pos_id"
            label="POS"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            zeroMinWidth
            name="medical_data.npi"
            label="NPI"
            InputProps={{
              endAdornment: (
                <IconButton color="primary" size="small" onClick={openFiltersModal}>
                  <EditOutlinedIcon />
                </IconButton>
              )
            }}
          />
        </Grid>

        <Grid item sm={8} xs={12} container spacing={isMobile ? 2 : 3}>
          <Grid item sm={6} xs={12}>
            <TextField
              zeroMinWidth
              name="medical_data.clia"
              label="CLIA"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              zeroMinWidth
              name="medical_data.medicare"
              label="Medicare ID"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              zeroMinWidth
              name="medical_data.medicaid"
              label="Medicaid ID"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              zeroMinWidth
              name="medical_data.mammog"
              label="Mammog ID"
            />
          </Grid>
        </Grid>

        <Grid item sm={4} xs={8}>
          <FormControlLabel
            label={<Typography>Hide on Claim</Typography>}
            control={<Checkbox name="medical_data.hide_on_claim" />}
          />

          <FormControlLabel
            label={<Typography>Default Location</Typography>}
            control={<Checkbox name="medical_data.default_location" />}
          />

          <FormControlLabel
            label={<Typography>Show Location in Box 32</Typography>}
            control={<Checkbox name="medical_data.show_location_in_box" />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
