import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Grid, Dialog } from '@material-ui/core';
import * as codesApi from '../../../../../../api/codes/procedures';
import { setCPTCodesLastGlobalAction } from '../../../../../../store/globalActions';
import { DatePicker, TextField } from '../../../../../../components/FormField';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../../../components/Modal';
import { Loader } from '../../../../../../components/Loader';
import { errorsMap } from '../../../../../../utils/validation';
import * as types from '../../CodesPOS/CodesContext/types';
import { validationSchema } from './validationSchema';

const initialValues = {
  code: null,
  date: null,
  description: null
};

export const PosCodeFormModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    initialValues: initialValuesProp = null,
    codesContext
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();
  const isNewCode = !initialValuesProp?.id;

  const createProcedureCode = (code, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return codesApi.createPOSCode(code).then((code) => {
      handleModalResolve(code);

      if (codesContext.codes.length + 1 > codesContext.filter.per_page) {
        codesContext.fetchCodes({ page: codesContext.filter.page });
      } else {
        dispatchRedux(setCPTCodesLastGlobalAction({ type: types.ADD_CUSTOM_CODE, payload: code }));
      }

      enqueueSnackbar('Code successfully created', { variant: 'success' });
    }).catch(({ errors }) => {
      if (errors?.code?.includes(errorsMap.unique)) {
        setErrors({ code: 'Code already exists' });
      } else if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Code not created', { variant: 'error' });
    });
  };

  const updateCode = (code, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return codesApi.updatePOSCode(code).then((code) => {
      enqueueSnackbar('Code successfully updated', { variant: 'success' });
      dispatchRedux(setCPTCodesLastGlobalAction({ type: types.UPDATE_CODE_IN_LIST, payload: code }));
      handleModalResolve(code);
    }).catch(({ errors }) => {
      if (errors?.code?.includes(errorsMap.unique)) {
        setErrors({ code: 'Code already exists' });
      } else if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Code not updated', { variant: 'error' });
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ ...initialValues, ...initialValuesProp }}
        validationSchema={validationSchema}
        onSubmit={isNewCode ? createProcedureCode : updateCode}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              {isNewCode ? 'Add POS Code' : 'Update POS Code'}
            </ModalHeader>

            <ModalBody>
              <Grid container spacing={2}>
                <Grid item sm={7} xs={12}>
                  <TextField
                    required
                    name="code"
                    label="Code"
                    placeholder="Enter code..."
                  />
                </Grid>

                <Grid item sm={5} xs={12}>
                  <DatePicker
                    clearable
                    name="date"
                    label="Date"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    multiline
                    rows={5}
                    variant="filled"
                    name="description"
                    label="Description"
                  />
                </Grid>
              </Grid>

            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
