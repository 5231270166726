import { useContext, useEffect, useRef } from 'react';
import {
  Typography,
  List as MuiList,
  Box
} from '@material-ui/core';
import { Scrollbars } from '../../Scrollbars';
import { TimeTrackingContext } from '../../TimeTracking';
import { InfiniteListLoader } from '../../InfiniteLoader';
import { Loader } from '../../Loader';
import { Header } from './Header';
import { MobileHeader } from './MobileHeader';
import { MobileRow } from './MobileRow';
import { Row } from './Row';

export const minRowHeight = 64;
export const widthBreakpointSmall = 480;
export const widthBreakpointMedium = 800;
export const widthBreakpointLarge = 830;
export const columnsWidths = {
  parent: 65,
  parent_title: 150,
  parent_note: 300,
  user: 210,
  company: 210,
  category: 155,
  actionIcon: 50,
  startedTime: 165,
  duration: 90,
  total: 90,
  note: 200,
  action: 60
};

export const mobileColumnWidths = {
  type: 65,
  parentInfo: 140,
  user: 150,
  company: 150,
  category: 95,
  startedTime: 165,
  total: 110,
  action: 50
};

export const TimeTracking = ({ disableActions = false, width, event, caseItem }) => {
  const {
    isFetching,
    isFetched,
    tracks,
    meta,
    loadNextPage,
    resetTimeTracking
  } = useContext(TimeTrackingContext);
  const scrollElementRef = useRef();
  const isSmallScreenView = width < 1050;

  useEffect(() => {
    resetTimeTracking();
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Loader loading={!isFetched} p={3} render={
        () => (!tracks.length ?
          <Box p={3}>
            <Typography align="center">No tracks found</Typography>
          </Box>
          :
          <>
            {isSmallScreenView
              ? <MobileHeader isEditable={!disableActions} width={width} />
              : <Header isEditable={!disableActions} width={width} />
            }

            <Scrollbars viewRef={scrollElementRef}>
              <MuiList disablePadding>
                <InfiniteListLoader
                  items={tracks}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  RowComponent={isSmallScreenView ? MobileRow : Row}
                  RowProps={{ disableActions, event, tracks, width, caseItem }}
                  pagination={meta}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                  minRowHeight={minRowHeight}
                />
              </MuiList>
            </Scrollbars>
          </>
        )}
      />
    </Box>
  );
};
