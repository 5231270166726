import { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { UpdateSipUserModal } from '../../../../../components/users';
import { useModal } from '../../../../../components/ModalsProvider';
import { Divider } from '../../../../../components/Divider';
import { Tooltip } from '../../../../../components/Tooltip';

export const SipInfo = ({ disableUpdate = false, user }) => {
  const { openModal } = useModal();
  const [ sipUser, setSipUser ] = useState(user?.sipUser);
  const sipNumber = user?.sipUser?.numbers?.join(', ');

  const updateSIPUser = () => {
    openModal(UpdateSipUserModal, {
      payload: { user: { ...user, sipUser } },
      onModalResolved: setSipUser
    });
  };

  useEffect(() => {
    setSipUser(user?.sipUser);
  }, [ user?.sipUser ]);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">
            SIP User
          </Typography>

          {!disableUpdate && hasRole(rolesMap.admin, rolesMap.supervisor) && (
            <>
              &nbsp;&nbsp;

              <Tooltip title={sipUser ? 'Update SIP User' : 'Add SIP User'}>
                <IconButton edge="end" color="primary" onClick={updateSIPUser}>
                  {sipUser ? <EditIcon /> : <AddIcon />}
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">
          FN
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography variant="h5">
          {sipUser?.first_name || '-'}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">
          LN
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography variant="h5">
          {sipUser?.last_name || '-'}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">
          Username
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography variant="h5">
          {sipUser?.username || '-'}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">
          Email
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography variant="h5">
          {sipUser?.email || '-'}
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Extension:</Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography variant="h5">
          {sipNumber || '-'}
        </Typography>
      </Grid>
    </>
  );
};
