export const styles = ({
  transitions,
  spacing,
  palette: { grey },
  zIndex
}) => ({
  root: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    zIndex: zIndex.appBar,
    transition: transitions.create()
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),
    backgroundColor: grey[100],
    justifyContent: 'space-between'
  }
});
