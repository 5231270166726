export const styles = ({ palette: { grey, ...palette } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },

  header: {
    backgroundColor: grey[600],
    color: palette.common.white
  },

  list: {
    flexGrow: 1,
    overflow: 'hidden'
  }
});
