import moment from 'moment';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, FormControlLabel, Radio, SvgIcon, Grid, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import ListAddIcon from '@material-ui/icons/PlaylistAdd';
import * as usersApi from '../../../../api/users';
import {
  TextField,
  RadioGroup,
  PhoneField,
  Checkbox,
  KeyboardDatePicker,
  SsnField,
  EmailsSelect
} from '../../../../components/FormField';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../components/Modal';
import { Loader } from '../../../../components/Loader';
import { validationSchema } from './validationSchema';

const initialValues = {
  first_name: null,
  last_name: null,
  phone_number: null,
  birthday: null,
  gender: null,
  email: null,
  ssn: null,
  is_notify: false
};

const birthdayMaxDate = moment().subtract(1, 'day').unix();

export const CreateBaseCustomerModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    isPatient = false,
    initialValues: initialValuesProp = {}
  }
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  const createUser = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return usersApi.createBaseUser(values).then((data) => {
      enqueueSnackbar('User successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ data: { errors } }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('User not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,

        is_patient: isPatient,

        ...initialValuesProp,
        mobile_phone_number: initialValuesProp.mobile_phone || initialValuesProp.mobile_phone_number || null
      }}
      validationSchema={validationSchema}
      onSubmit={createUser}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><ListAddIcon /></SvgIcon>}
              onClose={handleModalReject}
            >
              {isPatient ? 'Create New Patient' : 'Create New Customer'}
            </ModalHeader>

            <ModalBody>
              <Grid container spacing={isMobile ? 1 : 3}>
                <Grid item xs={isMobile ? 12 : 6}>
                  <TextField
                    required
                    name="first_name"
                    label="First name"
                    margin="dense"
                  />
                </Grid>

                <Grid item xs={isMobile ? 12 : 6}>
                  <TextField
                    required
                    name="last_name"
                    label="Last name"
                    margin="dense"
                  />
                </Grid>

                <Grid item xs={isMobile ? 12 : 6}>
                  <KeyboardDatePicker
                    name="birthday"
                    label="DOB"
                    maxDate={birthdayMaxDate}
                    outputFormat={moment.HTML5_FMT.DATE}
                    placeholder="MM/DD/YYYY"
                    margin="dense"
                  />
                </Grid>

                <Grid item xs={isMobile ? 12 : 6}>
                  <RadioGroup
                    row
                    name="gender"
                    label="Gender"
                    FormControlProps={{ margin: 'dense' }}
                  >
                    <FormControlLabel
                      label="Male"
                      value="male"
                      control={<Radio color="primary" />}
                    />

                    <FormControlLabel
                      label="Female"
                      value="female"
                      control={<Radio color="primary" />}
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs={isMobile ? 12 : 6}>
                  <PhoneField
                    zeroMinWidth
                    name="phone_number"
                    label="Phone number"
                    margin="dense"
                  />
                </Grid>

                <Grid item xs={isMobile ? 12 : 6}>
                  <PhoneField
                    zeroMinWidth
                    name="mobile_phone_number"
                    label="Mobile phone number"
                    margin="dense"
                  />
                </Grid>

                <Grid item xs={isMobile ? 12 : 6}>
                  <SsnField
                    name="ssn"
                    label="SSN"
                    margin="dense"
                  />
                </Grid>

                <Grid item xs={isMobile ? 12 : 6}>
                  <EmailsSelect
                    name="email"
                    label="Email"
                    margin="dense"
                  />
                </Grid>
              </Grid>

              <Grid item xs={isMobile ? 12 : 6}>
                <FormControlLabel
                  label="Send register email"
                  control={
                    <Checkbox
                      color="primary"
                      name="is_notify"
                    />
                  }
                />
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
