import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';

export const schedule = {
  name: 'Schedule',
  icon: DateRangeOutlinedIcon,
  path: '/schedule',
  routes: [
    {
      exact: true,
      name: 'My Schedule',
      icon: DateRangeOutlinedIcon,
      path: '/schedule'
    },
    {
      name: 'Schedule event types',
      icon: DateRangeOutlinedIcon,
      path: '/schedule/schedule-event-types'
    },
    {
      name: 'Event templates',
      icon: DateRangeOutlinedIcon,
      path: '/schedule/schedule-event-templates'
    }
  ]
};
