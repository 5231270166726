import { useEffect, useContext, useRef } from 'react';
import { List as MuiList, Box, Typography } from '@material-ui/core';
import { objectKeysToMap } from '../../../../../helpers/objectKeysToMap';
import { ScrollbarsCustom } from '../../../../../components/ScrollbarsCustom';
import { Sticky } from '../../../../../components/Sticky';
import { Loader } from '../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { TranscribesContext } from '../TranscribesProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidths = {
  name: 160,
  date: 160,
  status: 140,
  actions: 48
};

export const columnsMap = objectKeysToMap(columnsWidths);

export const List = () => {
  const {
    transcribes,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    resetTranscribes
  } = useContext(TranscribesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetTranscribes();
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ScrollbarsCustom autoHeight scrollElementRef={scrollElementRef}>
        <Box px={3} py={2}>
          <Loader loading={!isFetched} p={2} render={
            () => !filter.total ? (
              <Box p={3}>
                <Typography align="center">No transcribes found</Typography>
              </Box>
            ) : (
              <>
                <Sticky containerElement={scrollElementRef.current}>
                  <TableHeader />
                </Sticky>

                <MuiList disablePadding>
                  <InfiniteListLoader
                    items={transcribes}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    pagination={filter}
                    RowComponent={Row}
                    scrollElementRef={scrollElementRef}
                    onNeedNextPage={loadNextPage}
                  />
                </MuiList>
              </>
            )}
          />
        </Box>
      </ScrollbarsCustom>
    </Box>
  );
};
