import { useEffect } from 'react';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths, minRowHeight } from '../MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  item: statistic = {},
  isLoaded,
  recalculateHeight
}) => {
  const classes = useStyles();

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return (
    <ListRow
      className={classes.root}
      minHeight={minRowHeight}
    >
      <ListRowCell flexBasis={columnsWidths.time}>
        {moment.duration(statistic.time, 'seconds').format('h[h] m[min]')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.parent}>
        <Typography className={classes.label}>
          Case
        </Typography>
      </ListRowCell>

      <ListRowCell grow noWrap flexBasis={columnsWidths.title}>
        <Typography noWrap>
          {statistic.file_number || statistic.name}
        </Typography>
      </ListRowCell>
    </ListRow>
  );
};
