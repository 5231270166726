import { useContext } from 'react';
import {
  Typography,
  Tooltip,
  Checkbox,
  FormControlLabel,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { SelectedItemsCount } from '../../../../../../components/SelectedItemsCount';
import { PageHeader, PageHeaderActions } from '../../../../../../components/Page';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { Fab } from '../../../../../../components/Fab';
import { CreateCustomerModal } from '../../../CreateCustomerModal';
import { UsersContext } from '../../../UsersContext';

export const ActionsBar = ({ filterOpenButton }) => {
  const { openModal } = useModal();
  const { selectedIDs, pagination, creatBaseUser, ...usersContext } = useContext(UsersContext);
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const openCreateCustomerModal = () => {
    openModal(CreateCustomerModal, {
      onModalResolved: (user) => {
        creatBaseUser(user);
      }
    });
  };

  const massDeleteUsers = () => {
    usersContext.massDeleteUsers(selectedIDs);
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  return (
    <PageHeader>
      <PageHeaderActions startPosition>
        <Fab
          size="large"
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={openCreateCustomerModal}
        >
          Add user
        </Fab>
      </PageHeaderActions>

      <PageHeaderActions disableSpacing endPosition>
        {!!selectedIDs.length &&
          <>
            {(viewVariant === viewVariantsMap.grid) &&
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usersContext.allUsersIsSelected()}
                      onClick={usersContext.toggleAllUsersSelection}
                      indeterminate={!!selectedIDs.length && !usersContext.allUsersIsSelected()}
                    />
                  }
                  label="Select all users"
                />

                <VerticalDivider verticalGutters={1} horizontalGutters={1}/>
              </>
            }

            <SelectedItemsCount length={selectedIDs.length} total={pagination.total} />
            <VerticalDivider verticalGutters={1} horizontalGutters={1}/>

            <Tooltip
              arrow
              title={<Typography>Delete selected users</Typography>}
            >
              <IconButton
                color="error"
                onClick={massDeleteUsers}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <VerticalDivider verticalGutters={1} horizontalGutters={1}/>
          </>
        }

        {!isDownMd && (
          <>
            <Tooltip title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Grid view">
              <IconButton
                edge={!isDownMd && 'end'}
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        )}

        {isDownMd && filterOpenButton}
      </PageHeaderActions>
    </PageHeader>
  );
};
