export const styles = ({ spacing, breakpoints }) => ({
  root: {
    padding: spacing(2),
    minHeight: 370,
    borderWidth: 4,

    [breakpoints.down('md')]: {
      padding: spacing()
    }
  }
});
