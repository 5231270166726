import bg from './bg.png';

export const styles = ({ spacing, palette, shape: { borderRadius } }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    background: `${palette.grey[500]} url(${bg}) center / cover`,
    padding: spacing(2),
    minHeight: 125
  },

  action: {
    color: palette.common.white,
    marginLeft: 'auto'
  },

  avatar: {
    position: 'absolute',
    bottom: -spacing(6)
  }
});
