import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.THREAD_FETCH_REQUEST]: (state) => {
    return {
      ...state,

      isFetching: true
    };
  },

  [types.THREAD_FETCH_SUCCESS]: (
    { currentThread, ...state },
    { data }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      currentThread: data
    };
  },

  [types.UPDATE_CURRENT_THREAD]: (state, updatedThread) => {
    return {
      ...state,

      currentThread: updatedThread
    };
  },

  [types.ADD_MESSAGES_TO_SELECTED]: ({ selectedMessages, ...state }, newMessages) => {
    return {
      ...state,

      selectedMessages: [ ...new Set(selectedMessages.concat(newMessages)) ]
    };
  },

  [types.DELETE_MESSAGES_FROM_SELECTED]: ({ selectedMessages, ...state }, newMessages) => {
    return {
      ...state,

      selectedMessages: selectedMessages.filter(
        (message) => !newMessages.filter( (newMessage) => newMessage.id === message.id)
          .length)
    };
  },

  [types.SET_CURRENT_ACTION]: ({ currentAction, ...state }, { newAction }) => {
    return {
      ...state,

      currentAction: newAction
    };
  },

  [types.SET_MESSAGE_TO_EDIT]: ({ messageToEdit, ...state }, { selectedMessage }) => {
    return {
      ...state,

      messageToEdit: selectedMessage
    };
  },

  [types.SET_FORWARDED_MESSAGE]: ({ forwardedMessage, ...state }, { message }) => {
    return {
      ...state,

      forwardedMessage: message
    };
  },

  [types.TOGGLE_THREAD_AS_FAVORITE]: ({ currentThread, ...state }, { is_favorite }) => {
    return {
      ...state,

      currentThread: { ...currentThread, has_favorites: is_favorite }
    };
  }
});
