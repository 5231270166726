import { useState, useContext, useEffect } from 'react';
import { Box, AppBar, Tab, Tabs, useTheme, useMediaQuery } from '@material-ui/core';
import { Loader } from '../../../../../../components/Loader';
import { TaskPreviewContext, TasksProvider } from '../../../../../../components/tasks/TasksProvider';
import { Column } from './Column';

export const Tasks = ({ statuses }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { isFetched, selectedTask } = useContext(TaskPreviewContext);
  const [ selectedStatusID, setSelectedStatusID ] = useState(statuses?.[0]?.id);

  const handleTabChange = (event, newValue) => {
    setSelectedStatusID(newValue);
  };

  useEffect(() => {
    if (isFetched) {
      setSelectedStatusID(selectedTask?.status?.id || statuses?.[0]?.id);
    }
  }, [ isFetched, statuses, selectedTask?.status ]);

  return (
    <Loader p={2} loading={!statuses?.length || !selectedStatusID} render={
      () => (
        <Box
          display="flex"
          flexDirection="column"
          overflow="hidden"
          height="100%"
        >
          <AppBar position="static">
            <Tabs
              value={selectedStatusID}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={isMobile ? 'on' : 'auto'}
              onChange={handleTabChange}
            >
              {statuses.map((status) => (
                <Tab
                  key={status.id}
                  value={status.id}
                  label={status.name}
                />
              ))}
            </Tabs>
          </AppBar>

          {statuses.map((status) => selectedStatusID === status.id && (
            <TasksProvider key={status.id} fixedFilter={{ statuses: [ status.id ] }}>
              <Column status={status} />
            </TasksProvider>
          ))}
        </Box>
      )}
    />
  );
};
