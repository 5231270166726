import { useRef } from 'react';
import { Box } from '@material-ui/core';
import { List } from './List';

export const Services = () => {
  const rootRef = useRef(null);

  return (
    <Box ref={rootRef} display="flex" flexDirection="column" flexGrow={1} width="100%">
      <List />
    </Box>
  );
};
