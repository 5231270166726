import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useQueryParams } from '../../../../../helpers/hooks/useQueryParams';
import { filtersKeysMap } from '../../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../../../../components/LayoutContext';
import { Page, PageBody, PageHeader } from '../../../../../components/Page';
import { fieldsKeysMap } from '../../../../../components/users/MedicalInfo';
import { useModal } from '../../../../../components/ModalsProvider';
import {
  ReportsContext,
  PreviewReportModal,
  SelectReportTypeModal
} from '../../../../../components/medical/reports';
import { UserContext } from '../../../Members/User/UserProvider';
import { ActionsBar } from '../../ActionsBar';
import { FiltersBar } from '../FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

export const MainContent = () => {
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { diagnosId } = useQueryParams();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    pathname,
    pagination,
    selectedIDs,
    updateReport,
    resetReports,
    massDeleteItems
  } = useContext(ReportsContext);
  const { openModal } = useModal();
  const userContext = useContext(UserContext);
  const { user } = useSelector(({ profile }) => profile);
  const patient = userContext ? userContext?.user : user;
  const hiddenFilterFields = pathname === '/diagnosis' ? [] : [ fieldsKeysMap.patients ];
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  const handleToggleTypeOfReport = () => {
    openModal(SelectReportTypeModal, {
      payload: {
        patient: hiddenFilterFields.length ? patient : null
      },
      onModalResolved: (data) => {
        updateReport(data);
      }
    });
  };

  const openPreview = (diagnosId) => {
    if (!diagnosId) {
      return;
    }

    enqueueSnackbar('Please add your signature', { variant: 'warning' });
    history.replace(pathname);

    openModal(PreviewReportModal, {
      payload: {
        pathname,
        reportID: diagnosId,
        onUpdate: resetReports
      }
    });
  };

  useEffect(() => {
    if (diagnosId) {
      openPreview(diagnosId);
    }
  }, [ diagnosId ]);

  return (
    <LayoutContextProvider>
      <LayoutContext.Consumer>
        {({ viewVariant }) => (
          <Page>
            <PageHeader disableBorderBottom>
              <ActionsBar
                disableViewVariant={isTablet}
                actionTitle="Add new"
                selectedIDs={selectedIDs}
                pagination={pagination}
                filterOpenButton={filterOpenButton}
                onCreate={handleToggleTypeOfReport}
                onDelete={massDeleteItems(selectedIDs)}
              />
            </PageHeader>

            <PageBody fullHeight disablePaddings disableScrollbars>
              <Box height="100%" display="flex" flexDirection="column">
                <ScrollWrapper {...scrollWrapperProps}>
                  <FilterBarWrapper {...filterBarWrapperProps}>
                    <FiltersBar
                      hiddenFilterFields={hiddenFilterFields}
                      filterKey={filtersKeysMap.diagnosis}
                    />
                  </FilterBarWrapper>
                </ScrollWrapper>

                {!isTablet ? (
                  (viewVariant === viewVariantsMap.list)
                    ? <List />
                    : (viewVariant === viewVariantsMap.grid) ? <Grid /> : null
                ) : (
                  <Grid />
                )}
              </Box>
            </PageBody>
          </Page>
        )}
      </LayoutContext.Consumer>
    </LayoutContextProvider>
  );
};
