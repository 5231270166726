import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.COMPANIES_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.COMPANIES_FETCH_SUCCESS]: (
    { filter, companies, ...state },
    { data: newCompanies, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      companies: page > 1 ?
        companies.filter(({ id }) => !newCompanies.find((loadCompany) => id === loadCompany.id)).concat(newCompanies)
        :
        newCompanies
    };
  },

  [types.ADD_COMPANY]: (state, payload) => {
    const { pagination, filter, companies, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((companies.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      companies: [ ...companies,  payload ]
    };
  },

  [types.UPDATE_COMPANY]: (
    { filter, companies, ...state },
    company
  ) => {
    return {
      ...state,

      filter,
      companies: companies.map((item) => {
        return item.id === company.id ? company : item;
      })
    };
  },

  [types.DELETE_COMPANY]: ({ pagination, filter, companies, ...state }, companyId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      companies: companies.filter((company) => company.id !== companyId)
    };
  },

  [types.RESET_COMPANIES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.TOGGLE_ALL_ITEMS_SELECTION]: ({ companies, selectedIDs, ...state }) => {
    const allItemsIsSelected = companies.length === selectedIDs.length;

    return {
      ...state,

      companies,
      selectedIDs: allItemsIsSelected ? [] : companies.map((item) => item.id)
    };
  },

  [types.TOGGLE_ITEM_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1
        ? selectedIDs.filter((item) => item !== id)
        : selectedIDs.concat(id)
    };
  },

  [types.MASS_DELETE]: ({ companies, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      companies: companies.filter((company) => IDs.indexOf(company.id) < 0)
    };
  }
});
