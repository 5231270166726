import { Button, Dialog } from '@material-ui/core';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as chatApi from '../../../api/chat';
import { Loader } from '../../Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { UsersSelect } from '../../users';

export const ChangeChatOwnerModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { thread }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const changeChatOwner = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    chatApi.changeThreadOwner(thread.id, values).then(() => {
      enqueueSnackbar('Chat got new owner', { variant: 'success' });
      handleModalResolve();
    }).catch(({ status, data: { errors } = {} }) => {
      enqueueSnackbar('Something went wrong!', { variant: 'error' });

      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Dialog PaperProps={{ style: { maxWidth: 555 } }} {...DialogProps}>
      <Formik
        initialValues={{
          author_id: thread.user
        }}
        onSubmit={changeChatOwner}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader icon={<PersonOutlinedIcon />} onClose={handleModalReject}>
              Change chat owner
            </ModalHeader>

            <ModalBody>
              <UsersSelect
                required
                name="author_id"
                label="Choose new owner"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
