import { Grid, Typography } from '@material-ui/core';
import { autoAccidentStatesOptions } from '../../claims/SituationalNumberFormModal/SituationalNumberForm/data';
import { Autocomplete, KeyboardDatePicker } from '../../FormField';
import { autoAccidentCountryOptions } from '../../../dataMaps';
import { typeOfAccidentOptions } from '../accidentTypes';
import { formTypesMap } from './formTypesMap';

export const InitialFormContent = ({ type }) => {
  return (
    <>
      <Grid item md={4} xs={6}>
        <Autocomplete
          required
          name="accident_type"
          label="Type of accident"
          margin="dense"
          options={typeOfAccidentOptions}
        />
      </Grid>

      {type !== formTypesMap.compensation &&
        <>
          <Grid item md={4} xs={6}>
            <Autocomplete
              name="auto_accident_state"
              label="Accident State"
              margin="dense"
              options={autoAccidentStatesOptions}
            />
          </Grid>

          <Grid item md={4} xs={6}>
            <Autocomplete
              name="auto_accident_country"
              label="Accident Country"
              margin="dense"
              options={autoAccidentCountryOptions}
            />
          </Grid>
        </>
      }

      {type === formTypesMap.compensation &&
        <>
          <Grid item xs={12}>
            <Typography variant="h5" color="secondary">
              Unable to Work
            </Typography>
          </Grid>

          <Grid item md={4} xs={6}>
            <KeyboardDatePicker
              clearable
              name="unable_work_date_from"
              label="From"
              outputFormat="YYYY-MM-DD"
              margin="dense"
            />
          </Grid>

          <Grid item md={4} xs={6}>
            <KeyboardDatePicker
              clearable
              name="unable_work_date_to"
              label="To"
              outputFormat="YYYY-MM-DD"
              margin="dense"
            />
          </Grid>

          <Grid item md={4} xs={6}>
            <KeyboardDatePicker
              clearable
              name="last_work_date"
              label="Last Work Date"
              outputFormat="YYYY-MM-DD"
              margin="dense"
            />
          </Grid>
        </>
      }
    </>
  );
};
