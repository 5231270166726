import { Formik } from 'formik';
import { Icon } from '@mdi/react';
import { mdiFilter } from '@mdi/js';
import { makeStyles, SvgIcon } from '@material-ui/core';
import { AppBar } from '../../../../../../components/AppBar';
import { CaseUsersSelect } from '../../../../../../components/cases/CaseUsersSelect';
import { styles } from './styles';

const initialValues = {
  users_id: null
};

const useStyles = makeStyles(styles);

export const MainFilter = ({ filter, setFilter }) => {
  const classes = useStyles();

  const handleCaseUsersChange = (options) => {
    setFilter({ ...filter, users_id: options?.map(({ id }) => id) || null });
  };

  return (
    <AppBar className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <Formik
        initialValues={initialValues}
        onSubmit={null}
      >
        {() => (
          <div className={classes.filterRoot}>
            <SvgIcon color="primary" className={classes.filterIcon}>
              <Icon path={mdiFilter} />
            </SvgIcon>

            <CaseUsersSelect
              multiple
              name="users_id"
              label="Select by user name"
              margin="dense"
              className={classes.selectField}
              onChange={handleCaseUsersChange}
            />
          </div>
        )}
      </Formik>
    </AppBar>
  );
};
