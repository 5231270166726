import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { makeStyles, Box, Paper } from '@material-ui/core';
import * as claimsApi from '../../../../../api/claims';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { claimsStatusesMap } from '../../../../../components/claims';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
import {
  ClaimForm,
  validationSchema,
  transformClaimForForm,
  transformClaimReq
} from '../../../../../components/claims/ClaimForm';
import { SideBar } from '../SideBar';
import { styles } from '../ClaimPreview/styles';
import { ActionsBar } from './ActionsBar';

const useStyles = makeStyles(styles);

export const Edit = () => {
  const params = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [ claim, setClaim ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState(true);
  const rootRef = useRef(null);
  const { width = window.innerWidth } = useResizeObserver({ ref: rootRef });
  const scrollerRef = useRef();
  const classes = useStyles();

  const updateClaim = (values, { isSubmitting, setErrors, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    if (!values.appointment?.procedures?.length) {
      enqueueSnackbar('The claim must have an appointment with procedures', {
        variant: 'warning'
      });

      setSubmitting(false);

      return;
    }

    if (values.status === claimsStatusesMap.void && claim.status === claimsStatusesMap.void) {
      enqueueSnackbar('Change status from "Void" to another for the update claim', {
        variant: 'warning'
      });

      setSubmitting(false);

      return;
    }

    return claimsApi.updateClaim(transformClaimReq(values)).then((data) => {
      enqueueSnackbar('Claim successfully updated', {
        variant: 'success'
      });

      setClaim(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  useEffect(() => {
    const fetchClaim = () => {
      setIsLoading(true);

      claimsApi.fetchClaim(params.id).then((data) => {
        setIsLoading(false);
        setClaim(data);
      }).catch(() => {
        history.push('/billing/claims');
      });
    };

    fetchClaim();
  }, [ params.id ]);

  return (
    <Loader p={3} loading={!claim} render={
      () => (
        <Formik
          initialValues={transformClaimForForm(claim)}
          validationSchema={validationSchema}
          onSubmit={updateClaim}
        >
          {({ isSubmitting, values, handleSubmit }) => (
            <form ref={rootRef} noValidate className={classes.root} onSubmit={handleSubmit}>
              <Paper
                elevation={2}
                component={Box}
                display="flex"
                position="relative"
                width="100%"
                height="100%"
                overflow="hidden"
              >
                <SideBar width={width} claim={values} sidebarIsOpen={sidebarIsOpen} />

                <div className={classes.main}>
                  <Loader loading={isLoading} p={3} render={
                    () => (
                      <>
                        <ActionsBar
                          isSubmitting={isSubmitting}
                          claim={values}
                          sidebarIsOpen={sidebarIsOpen}
                          setSidebarIsOpen={setSidebarIsOpen}
                        />

                        <Box flexGrow={1}>
                          <CustomScrollbars scrollerRef={scrollerRef} style={{ height: '100%' }}>
                            <Box p={2}>
                              <ClaimForm />
                            </Box>
                          </CustomScrollbars>
                        </Box>
                      </>
                    )}
                  />
                </div>
              </Paper>
            </form>
          )}
        </Formik>
      )}
    />
  );
};
