import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { flow } from 'lodash';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { hasRole } from '../../../../../utils/hasRole';
import { MeasurementsPreviewModal, UpdateMeasurementsModal } from '../../../../../components/medical/forms';
import { useModal } from '../../../../../components/ModalsProvider';
import { UserContext } from '../../../Members/User/UserProvider';
import { HistoriesContext } from '../HistoriesProvider';

export const MeasurementsMenu = ({ history, onClose }) => {
  const { openModal } = useModal();
  const { deleteHistory } = useContext(HistoriesContext);
  const profile = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : profile.user;

  const handleDeleteHistory = () => {
    deleteHistory(history.id);
  };

  const handleOpenPreview = () => {
    openModal(MeasurementsPreviewModal, {
      payload: {
        measurements: history,
        user
      }
    });
  };

  const handleUpdateHistory = () => {
    openModal(UpdateMeasurementsModal, {
      payload: {
        initialValues: history,
        user
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={flow(handleOpenPreview, onClose)}>
        <ListItemIcon>
          <Box color="info.main">
            <EyeIcon color="inherit" fontSize="inherit" />
          </Box>
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      <MenuItem onClick={flow(handleUpdateHistory, onClose)}>
        <ListItemIcon>
          <EditIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      {!hasRole('client') &&
        <MenuItem onClick={flow(handleDeleteHistory, onClose)}>
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="inherit"/>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      }
    </List>
  );
};
