import { Grid } from '@material-ui/core';
import { TaskTemplatesSelect } from '../../../../../components/cases/TaskTemplatesSelect';
import { TextField } from '../../../../../components/FormField';

export const Form = () => {
  return (
    <Grid container spacing={3} justify="space-between" alignItems="center">
      <Grid item xs={12}>
        <TextField
          required
          name="name"
          label="Group name"
          placeholder="Enter name..."
        />
      </Grid>

      <Grid item xs={12}>
        <TaskTemplatesSelect
          multiple
          name="case_task_templates"
          margin="dense"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          rows={8}
          name="description"
          label="Description"
          variant="filled"
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
    </Grid>
  );
};
