import { Grid } from '@material-ui/core';
import { TextField } from '../../../FormField';

export const ServiceForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          required
          name="name"
          label="Name"
          placeholder="Enter number..."
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="login_url"
          label="URL Address"
          placeholder="Enter link..."
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          type="email"
          name="email"
          label="Email"
          placeholder="Enter email..."
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          required
          rowsMax={5}
          variant="filled"
          name="description"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>
    </Grid>
  );
};
