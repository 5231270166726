export const filterFieldsMap = {
  testId: 'test_id',
  from: 'from',
  to: 'to',
  appointmentBookId: 'appointment_book_id',
  insuranceType: 'insurance_type',
  insuranceId: 'insurance_id'
};

export const filterFieldsLabels = {
  [filterFieldsMap.testId]: 'Name Component',
  [filterFieldsMap.from]: 'From',
  [filterFieldsMap.to]: 'To',
  [filterFieldsMap.appointmentBookId]: 'Appointment Book',
  [filterFieldsMap.insuranceType]: 'Insur. type',
  [filterFieldsMap.insuranceId]: 'Insurance'
};
