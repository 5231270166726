import { Icon } from '@mdi/react';
import {
  mdiCogs,
  mdiDomain,
  mdiCircleMedium,
  mdiHomeCityOutline,
  mdiCardAccountPhoneOutline,
  mdiClipboardAccountOutline
} from '@mdi/js';
import { SvgIcon } from '@material-ui/core';

export const contacts = {
  name: 'Contacts',
  icon: (props) => (
    <SvgIcon {...props}>
      <Icon path={mdiCardAccountPhoneOutline} />
    </SvgIcon>
  ),
  path: '/contacts',
  routes: [
    {
      name: 'Companies',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiHomeCityOutline} />
        </SvgIcon>
      ),
      path: '/contacts/companies'
    },
    {
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiCogs} />
        </SvgIcon>
      ),
      name: 'Settings',
      path: '/contacts/system-settings',
      routes: [
        {
          icon: (props) => (
            <SvgIcon {...props}>
              <Icon path={mdiCircleMedium} />
            </SvgIcon>
          ),
          name: 'Companies types',
          path: '/contacts/system-settings/companies-types'
        }
      ]
    },
    {
      name: 'Offices',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiDomain} />
        </SvgIcon>
      ),
      path: '/contacts/offices'
    },
    // TODO: add settings in the future
    // {
    //   name: 'Locations',
    //   icon: (props) => (
    //     <SvgIcon {...props}>
    //       <Icon path={mdiMapMarkerRadiusOutline} />
    //     </SvgIcon>
    //   ),
    //   path: '/contacts/locations'
    // },
    {
      name: 'Users',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiClipboardAccountOutline} />
        </SvgIcon>
      ),
      path: '/contacts/users'
    }
  ]
};
