import { makeStyles, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../../components/Loader';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = ({ isFetched, eamsData }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Loader loading={!isFetched} className={classes.loader} render={
        () => (
          !eamsData ?
            <Typography align="center" className={classes.title}>Not found</Typography>
            :
            <pre>
              {JSON.stringify(eamsData, null, 4)}
            </pre>
        )}
      />
    </div>
  );
};
