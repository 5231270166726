import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.NOTIFICATIONS_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.NOTIFICATIONS_FETCH_SUCCESS]: (
    { filter, userNotifications, ...state },
    { data: newUserNotifications, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      userNotifications: page === 1 ?
        newUserNotifications :
        newUserNotifications.concat(userNotifications.filter(({ id }) => {
          return !newUserNotifications.find((loadedNotification) => id === loadedNotification.id);
        }))
    };
  }
});
