import { useContext, useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { filtersKeysMap, saveFilter } from '../../../../../store/lastFilters';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { TextField } from '../../../../../components/FormField';
import { MessagesContext } from '../../MessagesContext';

export const FiltersBar = () => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { filter, applyFilter } = useContext(MessagesContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filtersKeysMap.emails_main]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleFieldChange = (name) => debounce(({ target: { value } }) => {
    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues({
      ...filter,

      from: filter?.sender
    });
  };

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  useEffect(() => {
    dispatch(saveFilter({
      key: filtersKeysMap.emails_main,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      formikRef={formikRef}
      initialValues={filter}
      fields={[
        <TextField
          minWidth={260}
          name="text"
          label="Search"
          placeholder="Search..."
          onChange={handleFieldChange('text')}
        />
      ]}
    />
  );
};
