import * as tasksApi from '../../../../api/tasks';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';

export const transformTaskStatusToOption = (status) => status && ({
  value: status.id,
  label: status.name,
  data: status
});

const fetchStatuses = fetchDataForAsyncSelect(() => {
  return tasksApi.fetchStatuses();
}, {
  transformDataToOption: transformTaskStatusToOption,
  withoutPagination: true
});

export const TaskStatusesSelect = (props) => {
  return (
    <Select
      isAsync
      formattedValue
      name="status_id"
      label="Status"
      placeholder="Select status..."
      promiseWithOptions={fetchStatuses}
      {...props}
    />
  );
};
