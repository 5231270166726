import { useContext } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { CardHeader, CardItem } from '../../../../../../../../components/Cards';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import DocumentSvg from '../../../../../../../../components/icons/mimetypes/document.svg';
import { Divider } from '../../../../../../../../components/Divider';
import { SvgIcon } from '../../../../../../../../components/SvgIcon';
import { Badge } from '../../../../../../../../components/Badge';
import { Date } from '../../../../../../../../components/Date';
import { DocumentsContext } from '../../DocumentsProvider';
import { DocumentsModal } from '../../DocumentsModal';
import { SignedUsers } from '../../SignedUsers';
import { DocumentsMenu } from '../DocumentsMenu';
import { styles } from '../Row/styles';

const useStyles = makeStyles(styles);

export const Card = ({ item: document = {} }) => {
  const classes = useStyles();
  const { selectedIDs, fetchDocuments, toggleItemSelection } = useContext(DocumentsContext);
  const isSelected = selectedIDs.indexOf(document.id) !== -1;
  const { openModal } = useModal();

  const handleItemSelection = () => {
    toggleItemSelection(document.id);
  };

  const openDocumentModal = () => {
    openModal(DocumentsModal, {
      payload: {
        documentID: document.id,
        fetchDocuments
      }
    });
  };

  return (
    <CardItem selected={isSelected} py={1} px={2} onClick={stopPropagation(openDocumentModal)}>
      <CardHeader
        isSelected={isSelected}
        onItemSelect={handleItemSelection}
        menuComponent={DocumentsMenu}
        menuComponentProps={{ document }}
      >
        <Badge
          invisible={!document.is_signed}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          badgeContent={<CheckCircleIcon className={classes.badgeIcon} />}
          classes={{ badge: classes.badge }}
        >
          <SvgIcon fontSize="large">
            <DocumentSvg />
          </SvgIcon>
        </Badge>
      </CardHeader>

      <Divider type="dotted" gutter={1} />

      <Box flexGrow={0} minHeight={75}>
        <Typography paragraph variant="h4" title={document.name}>
          {document.name}
        </Typography>
      </Box>

      <Grid container spacing={1} component={Box} flexGrow={1}>
        <Grid item xs={4}>
          <Typography variant="h5">Created at</Typography>
        </Grid>

        <Grid item xs={8}>
          <Date date={document.created_at} format="L, LT"/>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h5">Updated at</Typography>
        </Grid>

        <Grid item xs={8}>
          <Date date={document.updated_at} format="L, LT"/>
        </Grid>
      </Grid>

      <Box flexGrow={0}>
        <Divider type="dotted" gutter={1} />
        <SignedUsers document={document} />
      </Box>
    </CardItem>
  );
};
