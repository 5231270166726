import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as profileApi from '../../../../api/profile';
import { setProfileData } from '../../../../store/dashboard/profile';
import { Loader } from '../../../../components/Loader';
import { EditUserPage } from '../../../../components/users/EditUserPage';
import { errorsMap } from '../../../../utils/validation';
import { getFormData } from '../insurance';

export const EditUser = () => {
  const [ user, setUser ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(({ profile }) => profile.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const uploadLicense = (values, setSubmitting, setErrors) => {
    const front = (!values?.driver_license?.front?.url && values?.front);
    const back = (!values?.driver_license?.back?.url && values?.back);

    if (front || back) {
      const formData = getFormData({ front: front || '', back: back || '' });

      return profileApi.uploadDriverLicense(formData).then(() => {
        return onUpdateProfile(values, setSubmitting, setErrors);
      }).catch(({ data: { errors } }) => {
        errors && setErrors(errors);
        setSubmitting(false);
        enqueueSnackbar('Profile not updated', { variant: 'error' });
      });
    } else {
      return onUpdateProfile(values, setSubmitting, setErrors);
    }
  };

  const onUpdateProfile = (userData, setSubmitting, setErrors) => {
    if (userData?.tags?.length) {
      return profileApi.deleteDriverLicense(userData?.tags).then(() => {
        return updateProfile(userData, setSubmitting, setErrors);
      });
    } else {
      return updateProfile(userData, setSubmitting, setErrors);
    }
  };

  const updateProfile = (userData, setSubmitting, setErrors) => {
    profileApi.updateProfile({ ...currentUser, profile: userData, ...userData }).then(({ data }) => {
      dispatch(setProfileData(data));
      history.goBack();
    }).catch(({ data: { errors } }) => {
      if (errors?.email?.includes(errorsMap.unique)) {
        setErrors({ email: 'User with the same email already exists' });
      } else if (errors) {
        setErrors(errors);
      }

      setSubmitting(false);
      enqueueSnackbar('Profile not updated', { variant: 'error' });
    });
  };

  useEffect(() => {
    setUser({
      id: currentUser?.id,
      role: currentUser?.role || null,
      is_active: currentUser?.is_active,
      ...currentUser
    });
  }, []);

  return (
    <Loader loading={!user} p={3} render={
      () => (
        <EditUserPage
          user={user}
          onUserUpdate={uploadLicense}
        />
      )}
    />
  );
};
