import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../../helpers/createReduxReducer';

export const SET_TOKEN_DATA = 'SET_TOKEN_DATA';

export const setTokenData = (payload) => ({ type: SET_TOKEN_DATA, payload });

const initialState = { token: null };

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [SET_TOKEN_DATA]: (state, payload) => {
    return {
      ...state,
      token: payload
    };
  }
});
