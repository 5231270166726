import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.PAYMENTS_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.PAYMENTS_FETCH_SUCCESS]: (
    { filter, payments, selectedPaymentsIDs, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      selectedPaymentsIDs: [],
      filter: { ...filter, page, per_page },
      payments: data
    };
  },

  [types.ADD_PAYMENT]: (state, payload) => {
    const { filter, payments, ...otherState } = state;

    return {
      ...otherState,

      filter,
      payments: [ ...payments,  payload ]
    };
  },

  [types.MASS_ADD_PAYMENT]: (state, payload) => {
    const { filter, payments, ...otherState } = state;

    return {
      ...otherState,

      filter,
      payments: payments.concat(payload)
    };
  },

  [types.UPDATE_PAYMENT_IN_LIST]: ({ filter, payments, ...state }, payment) => {
    return {
      ...state,

      filter,
      payments: payments.map((item) => {
        return item.id === payment.id ? payment : item;
      })
    };
  },

  [types.DELETE_PAYMENT]: ({ pagination, filter, payments, ...state }, paymentId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      payments: payments.filter((payment) => payment.id !== paymentId)
    };
  },

  [types.MASS_DELETE_PAYMENT]: ({ payments, pagination, filter, selectedPaymentsIDs, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      selectedPaymentsIDs: [],
      pagination: { ...pagination, total, last_page },
      payments: payments.filter((payment) => IDs.indexOf(payment.id) < 0)
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_PAYMENTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.TOGGLE_ALL_PAYMENTS_SELECTION]: ({ payments, selectedPaymentsIDs, ...state }) => {
    const allPaymentsIsSelected = payments.length === selectedPaymentsIDs.length;

    return {
      ...state,

      payments,
      selectedPaymentsIDs: allPaymentsIsSelected ? [] : payments.map((item) => item.id)
    };
  },

  [types.TOGGLE_PAYMENT_SELECTION]: ({ selectedPaymentsIDs, ...state }, id) => {
    return {
      ...state,

      selectedPaymentsIDs: selectedPaymentsIDs.indexOf(id) !== -1
        ? selectedPaymentsIDs.filter((item) => item !== id)
        : selectedPaymentsIDs.concat(id)
    };
  }
});
