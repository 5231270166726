import { height } from './ViewControls';

export const styles = ({ spacing, palette, shape: { borderRadius } }) => ({
  root: {
    position: 'absolute',
    bottom: spacing(),
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    height: height - spacing(2),
    borderRadius,
    background: 'rgba(0, 0, 0, 0.75)',
    padding: 2
  },

  pagination: {
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid rgba(255, 255, 255, 0.2)'
  },

  pagination__info: {
    paddingLeft: spacing(1.5),
    color: palette.common.white,
    fontSize: 13.5,
    whiteSpace: 'nowrap'
  },

  pagination__infoPage: {
    display: 'inline-flex',
    justifyContent: 'center',
    minWidth: 40,
    paddingLeft: spacing(1.25),
    paddingRight: spacing(1.25)
  },

  button: {
    borderRadius: 0,
    color: palette.common.white,

    '&:first-child': {
      marginLeft: 0
    },

    '&:last-child': {
      marginRight: 0
    },

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)'
    },

    '&[disabled]': {
      color: 'rgba(255, 255, 255, 0.3)'
    }
  },

  zoom: {
    display: 'flex'
  }
});
