import { useContext, useEffect } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Label } from '../../../../Label';
import { OfficeLink } from '../../../../OfficeLink';
import { UserLink } from '../../../../UserLink';
import { stopPropagation } from '../../../../../helpers/dom';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { Loader } from '../../../../Loader';
import { IconButton } from '../../../../IconButton';
import { SavedFiltersContext } from '../../../../saved-filters';
import { columnsWidths } from '../columnsWidths';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const theme = useTheme();
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell grow column flexBasis={columnsWidths.providers}>
        <Box display="flex" flexDirection="column" width="100%">
          {!params?.providers?.length ? '-' : params?.providers?.map((provider) => (
            <Box key={provider.id} display="flex" py={0.25}>
              <UserLink
                noWrap
                size="sm"
                user={provider}
              />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell grow column shrink={0} flexBasis={columnsWidths.offices}>
        {!params?.offices?.length ? '-' : params?.offices?.map((office) => (
          <OfficeLink
            noWrap
            key={office.id}
            tooltipTitle={office?.full_address}
            isExistTooltip={!!office?.full_address}
            office={office}
          />
        ))}
      </ListRowCell>

      <ListRowCell grow column flexBasis={columnsWidths.apptBooks}>
        {!params?.appointment_books?.length ? '-' : params?.appointment_books?.map((book) => (
          <Typography noWrap key={book.id} title={book.appointment_book_name || null}>
            {book.appointment_book_name || '-'}
          </Typography>
        ))}
      </ListRowCell>

      <ListRowCell grow column flexBasis={columnsWidths.patients}>
        {!params?.patients?.length ? '-' : params?.patients?.map((patient) => (
          <Box key={patient.id} display="flex" alignItems="center" py={0.25}>
            <UserLink
              noWrap
              size="sm"
              user={patient}
            />
          </Box>
        ))}
      </ListRowCell>

      <ListRowCell grow column flexBasis={columnsWidths.arrivalStatuses}>
        {!params?.arrival_statuses?.length ? '-' : params.arrival_statuses?.map((status) => (
          <Box key={status.id} py={0.5}>
            <Label label={status.description} color={theme.palette.primary.main} />
          </Box>
        ))}
      </ListRowCell>

      <ListRowCell grow column flexBasis={columnsWidths.appointmentTypes}>
        {!params?.appointment_types?.length ? '-' : params.appointment_types?.map((type) => (
          <Typography noWrap key={type.code} title={type.description}>
            {type.description + ' '}
          </Typography>
        ))}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.visitReasons}>
        <Box display="flex" flexDirection="column" width="100%">
          {!params?.visit_reasons?.length ? '-' : params.visit_reasons?.map((reason) => (
            <Typography noWrap key={reason.id} title={reason?.description}>
              {reason.code + (reason.description ? ' - ' + reason.description : '')}
            </Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="start"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

