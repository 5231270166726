const listWidth = 300;

export const styles = ({
  spacing,
  palette: { grey },
  typography: { fontWeightMedium },
  breakpoints
}) => ({
  root: {
    height: '100%',
    padding: spacing(2, 2, 0, 2),

    [breakpoints.down('sm')]: {
      padding: spacing()
    }
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  },

  top: {
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      flexShrink: 0,
      marginLeft: spacing(2)
    },

    [breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },

  grow: {
    flexGrow: 1,

    [breakpoints.down('md')]: {
      maxWidth: '55%'
    },

    [breakpoints.down('sm')]: {
      maxWidth: '80%'
    }
  },

  templateType: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: listWidth,
    flexShrink: 0,
    padding: spacing(1, 2),

    [breakpoints.down('md')]: {
      width: 'auto',
      padding: spacing()
    },

    '& p': {
      paddingRight: spacing(0.5)
    }
  },

  templateType__title: {
    fontWeight: fontWeightMedium
  },

  bottom: {
    flexGrow: 1,
    position: 'relative',
    border: `1px solid ${grey[400]}`,
    overflow: 'hidden'
  },

  bottom__container: {
    display: 'flex',
    height: '100%'
  },

  editor: {
    flexGrow: 1
  },

  list: {
    flexShrink: 0,
    height: '100%',
    width: listWidth,
    background: grey[100],

    [breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: 0,
      width: '50%'
    }
  },

  listClose: {
    width: 0,
    flexGrow: 0
  }
});
