import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_AUTHORIZATIONS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_AUTHORIZATIONS_SUCCESS]: (
    { filter, authorizations, ...state },
    { data: newAuthorization, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      authorizations: (page > 1
        ? authorizations.filter(({ id }) => {
          return !newAuthorization.find((loadedClaim) => id === loadedClaim.id);
        }).concat(newAuthorization)
        : newAuthorization
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_AUTHORIZATIONS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_AUTHORIZATION]: (state, authorization) => {
    return {
      ...state,

      authorizations: state.authorizations.map((item) => {
        return item.id === authorization.id ? { ...item, ...authorization } : item;
      })
    };
  },

  [types.DELETE_AUTHORIZATION]: (state, authorizationID) => {
    const { pagination, filter, authorizations, selectedAuthorizationsIDs } = state;
    const filteredAuthorizations = authorizations.filter(({ id }) => authorizationID !== id);

    if (filteredAuthorizations.length === authorizations.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((authorizations.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      authorizations: filteredAuthorizations,
      selectedAuthorizationsIDs: selectedAuthorizationsIDs.filter((id) => authorizationID !== id)
    };
  },

  [types.ADD_AUTHORIZATIONS_TO_SELECTED]: ({
    selectedAuthorization,
    selectedAuthorizationsIDs,

    ...state
  }, authorizations) => {
    return {
      ...state,

      selectedAuthorization: selectedAuthorization.concat(
        authorizations.filter(({ id }) => !selectedAuthorizationsIDs.includes(id))
      ),
      selectedAuthorizationsIDs: [ ...new Set(selectedAuthorizationsIDs.concat(authorizations.map(({ id }) => id))) ]
    };
  },

  [types.DELETE_AUTHORIZATIONS_FROM_SELECTED]: ({ selectedAuthorizationsIDs, ...state }, authorizationsIDs) => {
    return {
      ...state,

      selectedAuthorizationsIDs: selectedAuthorizationsIDs.filter((id) => authorizationsIDs.indexOf(id) === -1)
    };
  }
});
