export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',

    [breakpoints.down('md')]: {
      paddingBottom: spacing(5)
    }
  }
});
