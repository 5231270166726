import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { mdiVirusOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as formsApi from '../../../../../../../../api/profile';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { SvgIcon } from '../../../../../../../../components/SvgIcon';
import { Loader } from '../../../../../../../../components/Loader';
import {
  CovidFormModal,
  formTypesMap
} from '../../../../../../../../components/medical/forms';
import { getFormRoutes, MedicalFormsContext } from '../../../MedicalFormsProvider';
import { CovidPreviewFormModal } from '../../../CovidPreviewFormModal';
import { FormPreviewModal } from '../../../FormPreviewModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const actionsMap = {
  followUp: 'follow_up',
  covidForm: 'covid_form'
};

export const getCovidForm = (form) => (
  form?.children?.find(({ form_type }) => form_type === formTypesMap.covid_form) || null
);

export const FormsMenu = ({ disableCreatable = false, form = {}, onClose = () => {} }) => {
  const classes = useStyles();
  const history = useHistory();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const medicalFormsContext = useContext(MedicalFormsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isCovidForm = !!getCovidForm(form);
  const [ activeAction, setActiveAction ] = useState(null);

  const handleDeleteForm = () => {
    medicalFormsContext.deleteForm(form);
  };

  const openFormPreviewModal = () => {
    if (isCovidForm) {
      openModal(CovidPreviewFormModal, {
        payload: { formID: form.id, onUpdateForm: medicalFormsContext.fetchForms }
      });
    } else {
      openModal(FormPreviewModal, {
        payload: {
          formID: form.id,
          approveForm: medicalFormsContext?.approveForm
        }
      });
    }
  };

  const handleOpenEditPage = () => {
    if (form?.form_type === formTypesMap.follow_up) {
      history.push(`${medicalFormsContext.medicalInfoPathname}/medical-forms/${form.id}/edit`);
    } else if (form?.form_type === formTypesMap.covid_form) {
      openModal(CovidFormModal, {
        payload: { initialValues: form },
        onModalResolved: () => {
          medicalFormsContext.fetchForm();
        }
      });
    } else {
      history.push(getFormRoutes({
        id: form.id,
        medicalInfoPathname: medicalFormsContext.medicalInfoPathname
      })[form.filling ? form.filling - 1 : 0], {
        type: form.form_type,
        form
      });
    }
  };

  const handleOpenCovidModal = () => {
    setActiveAction(actionsMap.covidForm);

    openModal(ConfirmationModal, {
      payload: {
        title: 'Add COVID form?'
      },
      onModalResolved: () => {
        openModal(CovidFormModal, {
          payload: {
            isEditable: false,
            initialValues: form
          },
          onModalResolved: () => {
            medicalFormsContext.fetchForms();
            setActiveAction(null);
          }
        });
      },
      onModalRejected: () => {
        setActiveAction(null);
      }
    });
  };

  const handleCreateFollowUpForm = () => {
    setActiveAction(actionsMap.followUp);

    openModal(ConfirmationModal, {
      payload: {
        title: 'Add a new follow up form?'
      },
      onModalResolved: () => {
        return formsApi.createFollowUpMedicalForm({
          medical_id: form?.medical_id || form?.medical?.id,
          medical_form_id: form.id
        }).then((data) => {
          setActiveAction(null);
          history.push(`${medicalFormsContext?.medicalInfoPathname}/medical-forms/${data.id}/edit`);
        }).catch((errors) => {
          if (errors) {
            enqueueSnackbar('Form not created', { variant: 'error' });
            setActiveAction(null);
            onClose();
          }
        });
      },
      onModalRejected: () => {
        setActiveAction(null);
      }
    });
  };

  return (
    <List className={classes.root}>
      {!medicalFormsContext?.isUnconfirmed && !!form?.appointment?.id && (disableCreatable &&
        <Loader fullWidth surface loading={activeAction === actionsMap.followUp} render={
          () => (
            <MenuItem onClick={handleCreateFollowUpForm}>
              <ListItemIcon>
                <Box color="success.main">
                  <AddIcon color="inherit" />
                </Box>
              </ListItemIcon>

              <ListItemText primary="Add follow up form" />
            </MenuItem>
          )}
        />
      )}

      {!medicalFormsContext?.isUnconfirmed && !isCovidForm && disableCreatable && (
        <Loader fullWidth surface loading={activeAction === actionsMap.covidForm} render={
          () => (
            <MenuItem onClick={handleOpenCovidModal}>
              <ListItemIcon>
                <SvgIcon color="secondary">
                  <Icon path={mdiVirusOutline} />
                </SvgIcon>
              </ListItemIcon>

              <ListItemText primary="COVID-19 form" />
            </MenuItem>
          )}
        />
      )}

      {!isMobile &&
        <MenuItem onClick={openFormPreviewModal}>
          <ListItemIcon>
            <Box color="info.main">
              <EyeIcon />
            </Box>
          </ListItemIcon>

          <ListItemText primary="View" />
        </MenuItem>
      }

      {!(hasRole(rolesMap.advocate) && form?.is_confirmed) && (
        <MenuItem onClick={handleOpenEditPage}>
          <ListItemIcon>
            <SvgIcon color="primary"><EditIcon /></SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Edit" />
        </MenuItem>
      )}

      {!hasRole(rolesMap.client,  rolesMap.patient, rolesMap.medic, rolesMap.doctor, rolesMap.advocate) &&
        <MenuItem onClick={handleDeleteForm}>
          <ListItemIcon>
            <SvgIcon color="error"><DeleteIcon /></SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      }
    </List>
  );
};
