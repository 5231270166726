import { useState, forwardRef, useEffect } from 'react';
import T from 'prop-types';
import { get } from 'lodash';
import cn from 'classnames';
import { makeStyles, TextField as MuiTextField } from '@material-ui/core';
import { withBaseField } from '../withBaseField';
import { styles } from '../TextField/styles';

const useStyles = makeStyles(styles);

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const propTypes = {
  max: T.number,
  isEditable: T.bool
};

export const FormattedNumberField = withBaseField(forwardRef(({
  isEditable,
  disabled,
  field = {},
  fullWidth = true,
  type = 'text',
  defaultValue = 0,
  isFormikField,
  max = Number.MAX_SAFE_INTEGER,
  form,
  helperText,
  error,
  name,
  onChange,
  InputProps = {},
  InputLabelProps = {},
  ...props
}, ref) => {
  const [ value, setValue ] = useState(0);
  const hasIsEditable = typeof isEditable === 'boolean';
  const classes = useStyles();

  useEffect(() => {
    const number = defaultValue && (defaultValue * 100).toFixed(0);

    setValue(number && number > 0 ? number : 0);
  }, [ defaultValue ]);

  const handleValueChange = (value) => {
    setValue(value);
    isFormikField && form.setFieldValue(field.name, (value / 100).toFixed(2));
  };

  const handleKeyDown = (event) => {
    const { key, keyCode } = event;

    if (
      (value === 0 && !VALID_FIRST.test(key)) ||
      (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
    ) {
      return;
    }

    const valueString = value.toString();
    let nextValue;

    if (keyCode !== DELETE_KEY_CODE) {
      const nextValueString = value === 0 ? key : `${valueString}${key}`;

      nextValue = Number.parseInt(nextValueString, 10);
    } else {
      const nextValueString = valueString.slice(0, -1);

      nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
    }

    if (nextValue > max) {
      return;
    }

    handleValueChange(nextValue);
  };

  return (
    <MuiTextField
      {...field}
      fullWidth={fullWidth}
      disabled={(hasIsEditable && !isEditable) || disabled}
      error={!!error}
      type={type}
      name={name || field.name}
      value={(value / 100).toLocaleString('en-US', { minimumFractionDigits: 2 })}
      helperText={error || helperText}
      onKeyDown={handleKeyDown}
      onChange={onChange}
      InputProps={{
        ...InputProps,

        classes: {
          ...get(InputProps, 'classes', {}),
          disabled: cn(hasIsEditable && classes.notEditableInput, get(InputProps, 'classes.disabled'))
        }
      }}
      InputLabelProps={{
        ...InputLabelProps,

        classes: {
          ...get(InputLabelProps, 'classes', {}),
          disabled: cn(hasIsEditable && classes.notEditableInputLabel, get(InputLabelProps, 'classes.disabled'))
        }
      }}
      {...props}
      ref={ref}
    />
  );
}));

FormattedNumberField.propTypes = propTypes;
