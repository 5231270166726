export const transformResponse = (data) => {
  return {
    data: Object.values(data),
    labels: Object.keys(data)
  };
};

export const transformTasksTypesStatisticsResponse = (data) => {
  return {
    data: Object.values(data),
    labels: data.map((item) => item?.name)
  };
};

export const transformFilesResponse = (data) => {
  const casePercent = {
    case_percent: data.case_files.count,
    rest_cases: (data.total_files - data.case_files.count).toFixed(0)
  };

  const billingPercent = {
    billing_percent: data.billing_files.count,
    rest_billing: (data.total_files - data.billing_files.count).toFixed(0)
  };

  const totalFilesPercent = {
    total_files: data.total_files
  };

  const count = {
    'Uploaded files': data.total_files,
    'On billing': data.billing_files.count,
    'On cases': data.case_files.count
  };

  return {
    count: transformResponse(count),
    transformTotal: transformResponse(totalFilesPercent),
    transformCase: transformResponse(casePercent),
    transformBilling: transformResponse(billingPercent),
    ...data
  };
};
