import { Box } from '@material-ui/core';
import { discoveryFieldsMap } from './fieldsMaps/discoveryFieldsMap';
import { WidgetActivity } from './WidgetActivity';

export const Discovery = ({
  isDeleted = false,
  isNew = false,
  fieldLabel,
  activityLabel,
  payload
}) => {
  if (isDeleted) {
    return 'Removed Discovery';
  }

  return (
    <>
      {isNew && (
        <Box component="span" display="flex" alignItems="center">
          Added new discovery
        </Box>
      )}

      <WidgetActivity
        {...payload?.properties}
        showInfo
        fieldLabel={fieldLabel}
        activityLabel={activityLabel}
        fieldsMap={discoveryFieldsMap}
      />
    </>
  );
};
