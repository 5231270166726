import { Fragment } from 'react';
import T from 'prop-types';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import { Scrollbars } from '../../Scrollbars';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: ({ fullHeight }) => fullHeight && '100%',
    padding: ({ disablePaddings }) => disablePaddings ? null : spacing(2, 3),

    [breakpoints.down('md')]: {
      padding: ({ disablePaddings }) => disablePaddings ? null : spacing()
    }
  }
}));

const propTypes = {
  fullHeight: T.bool,
  disablePaddings: T.bool,
  disableScrollbars: T.bool,
  className: T.string
};

export const PageBody = ({
  children,
  fullHeight,
  disablePaddings,
  disableScrollbars,
  className,

  ...props
}) => {
  const classes = useStyles({ fullHeight, disablePaddings });
  const Wrapper = disableScrollbars ? Fragment : Scrollbars;

  return (
    <Wrapper>
      <div className={cn(classes.root, className)} {...props}>
        {children}
      </div>
    </Wrapper>
  );
};

PageBody.propTypes = propTypes;
