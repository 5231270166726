import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import { Icon } from '@mdi/react';
import {
  mdiClipboardAccountOutline,
  // mdiChartBellCurve,
  // mdiZipBoxOutline,
  mdiAccountGroupOutline,
  mdiFileMultipleOutline,
  mdiPlaylistCheck,
  // mdiTimelineOutline,
  mdiCellphoneMessage
} from '@mdi/js';
import { SvgIcon } from '@material-ui/core';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { filtersKeysMap } from '../../../store/lastFilters';
import TimerSvg from '../../../components/icons/chronometer.svg';
import { WorkInfo } from '../../../components/users/Tabs';
import { TasksWidget } from '../../../components/tasks';
import { CallReports } from '../Members/User/MainContent/CallReports';
import { GeneralInfo } from './GeneralInfo';
import { Appointments } from './Appointments';
import { NotificationsPage } from './NotificationsPage';
import { Schedule } from './Schedule';
import { EditUser } from './EditUser';
import { Patients } from './Patients';
import { Files } from './Files';
import { Emails } from './Emails';
import { TimeTrackingPage } from './TimeTrackingPage';
import { WorkSessionsPage } from './WorkSessionsPage';
import { FeedPage } from './FeedPage';

export const emailsRoutes = [
  {
    path: '/profile/emails',
    icon: <EmailIcon />,
    label: 'Emails',
    component: Emails
  }
];

export const routes = [
  {
    exact: true,
    path: '/profile',
    icon: <SvgIcon><Icon path={mdiClipboardAccountOutline} /></SvgIcon>,
    label: 'General info',
    component: GeneralInfo
  },
  {
    path: '/profile/feed',
    icon: <NotificationImportantOutlinedIcon />,
    label: 'Feed',
    component: FeedPage
  },
  {
    exact: true,
    path: '/profile/edit',
    icon: null,
    label: null,
    component: EditUser
  },
  {
    path: '/profile/work-info',
    icon: <HomeWorkOutlinedIcon />,
    label: 'Work Info',
    component: WorkInfo
  },
  {
    path: '/profile/time-tracking',
    hide: ({ payload }) => {
      return [ rolesMap.patient, rolesMap.medic, rolesMap.doctor ].includes(payload?.currentUser?.role);
    },
    icon: <SvgIcon><TimerSvg /></SvgIcon>,
    label: 'Time Tracking',
    component: TimeTrackingPage
  },
  {
    exact: true,
    path: '/profile/call-reports',
    icon: <PhoneOutlinedIcon />,
    label: 'Call Reports',
    component: CallReports
  },
  {
    exact: true,
    path: '/profile/appointments',
    icon: <InsertInvitationOutlinedIcon />,
    label: 'Appointments',
    component: Appointments
  },
  {
    exact: true,
    path: '/profile/schedule',
    icon: <DateRangeOutlinedIcon />,
    label: 'Schedule',
    component: Schedule
  },
  {
    path: '/profile/tasks',
    icon: <SvgIcon><Icon path={mdiPlaylistCheck} /></SvgIcon>,
    label: 'Tasks',
    hide: ({ payload }) => {
      return [ rolesMap.patient, rolesMap.medic, rolesMap.doctor ].includes(payload?.currentUser?.role);
    },
    component: () => <TasksWidget isTabPage />
  },
  // {
  //   path: '/profile/activity',
  //   icon: <SvgIcon><Icon path={mdiTimelineOutline} /></SvgIcon>,
  //   label: 'Activity',
  //   component: () => 'Activity'
  // },
  ...emailsRoutes,
  {
    path: '/profile/files',
    icon: <SvgIcon><Icon path={mdiFileMultipleOutline} /></SvgIcon>,
    label: 'Files',
    component: () => <Files filterKey={filtersKeysMap.profile_files} />
  },
  // {
  //   path: '/profile/analytics',
  //   icon: <SvgIcon><Icon path={mdiChartBellCurve} /></SvgIcon>,
  //   label: 'Analytics',
  //   component: () => 'Analytics'
  // },
  {
    path: '/profile/work-sessions',
    icon: <AlarmOnOutlinedIcon />,
    label: 'Work Sessions',
    component: WorkSessionsPage
  },
  // {
  //   path: '/profile/archive',
  //   icon: <SvgIcon><Icon path={mdiZipBoxOutline} /></SvgIcon>,
  //   label: 'Archive',
  //   component: () => 'Archive'
  // },
  {
    path: '/profile/patients',
    icon: <SvgIcon><Icon path={mdiAccountGroupOutline} /></SvgIcon>,
    label: 'Patients',
    render: () => <Patients filterKey={filtersKeysMap.profile_patients} />
  },
  {
    path: '/profile/notifications',
    icon: <SvgIcon><Icon path={mdiCellphoneMessage} /></SvgIcon>,
    label: 'Notifications',
    component: NotificationsPage
  }
];
