export const orderByMap = {
  created_at: 'created_at',
  updated_at: 'updated_at',
  number: 'number',
  date: 'date',
  is_paid: 'is_paid',
  balance: 'balance',
  status_id: 'status_id'
};

export const orderByLabelsMap = {
  [orderByMap.created_at]: 'Created Date',
  [orderByMap.updated_at]: 'Updated Date',
  [orderByMap.number]: 'Number',
  [orderByMap.date]: 'Date',
  [orderByMap.is_paid]: 'Paid',
  [orderByMap.balance]: 'Balance',
  [orderByMap.status_id]: 'Status'
};

export const orderByOptions = [
  { label: orderByLabelsMap.created_at, value: orderByMap.created_at },
  { label: orderByLabelsMap.updated_at, value: orderByMap.updated_at },
  { label: orderByLabelsMap.number, value: orderByMap.number },
  { label: orderByLabelsMap.date, value: orderByMap.date },
  { label: orderByLabelsMap.is_paid, value: orderByMap.is_paid },
  { label: orderByLabelsMap.balance, value: orderByMap.balance },
  { label: orderByLabelsMap.status_id, value: orderByMap.status_id }
];
