import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as profileApi from '../../../../../../../../api/profile';
import * as userApi from '../../../../../../../../api/users';
import { Loader } from '../../../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../../../components/Modal';
import { setMedicalProfileLastGlobalAction } from '../../../../../../../../store/globalActions';
import * as types from '../../../MedicalProfileProvider/types';
import { Contact } from '../Contact';
import { validationSchema } from '../validationSchema';

export const FamilyContactsModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { initialValues }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();

  const updateMedicalFamilyTree = (values, setErrors) => {
    const family_tree = initialValues.family_tree ? [ ...initialValues.family_tree, values ] : [ values ];

    profileApi.updateMedicalFamilyTree({ id: initialValues.id, family_tree })
      .then((data) => {
        dispatchRedux(setMedicalProfileLastGlobalAction({ type: types.UPDATE_PROFILE, payload: data }));
        enqueueSnackbar('Contacts successfully updated', { variant: 'success' });
        handleModalResolve(values);
      })
      .catch(({ errors, message }) => {
        if (errors) {
          const errorMessage = Object.values(errors);

          errors && setErrors(errors);
          message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
        }
      });
  };

  const updateProfileInfo = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (values.is_checked) {
      const user = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email
      };

      userApi.createBaseUser(user).then((data) => {
        const user = {
          user_id: data.id,
          relationship: values.relationship,
          note: values.note
        };

        updateMedicalFamilyTree(user, setErrors);
      });
    } else {
      updateMedicalFamilyTree(values, setErrors);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        is_checked: false,
        relationship: null,
        note: null,
        user_id: null,
        first_name: null,
        last_name: null,
        email: null,
        is_notify: false
      }}
      validationSchema={validationSchema}
      onSubmit={updateProfileInfo}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog {...DialogProps}>
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader onClose={handleModalReject}>
              Add Family Unit
            </ModalHeader>

            <ModalBody>
              <Contact/>
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
