import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import * as localUseCodesApi from '../../../../api/codes/local-use-codes';
import { Select } from '../Select';

export const transformLocalUseCodeToOption = (code) => code && ({
  value: code.id,
  label: `${code.abbreviation} - ${code.name}`,
  data: code
});

const fetchLocalUseCodes = (params) => fetchDataForAsyncSelect((search, { page }) => {
  return localUseCodesApi.fetchLocalUseCodes({ params: { search, page, ...params } });
}, {
  transformDataToOption: transformLocalUseCodeToOption
});

export const LocalUseCodesSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      name="local_use_id"
      label="Local Use Code"
      promiseWithOptions={fetchLocalUseCodes(params)}
      {...props}
    />
  );
};
