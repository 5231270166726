import { useContext } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useModal } from 'react-modal-hook';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { applyFilesFilter } from '../../../../../../store/dashboard/files/recentlyOpenedFiles';
import { Popper } from '../../../../../../components/Popper';
import { FileMenu } from '../../../../files-common/FileMenu';
import { FileTypeIcon } from '../../../../files-common/FileTypeIcon';
import { FilePreviewModal } from '../../../../files-common/FilePreviewModal';
import { FilesContext } from '../../../../files-common/FilesContext';
import { getFileMenuOptions } from '../../../../files-common/getFileMenuOptions';
import { styles } from './styles';

const mapStateToProps = ({
  recentlyOpenedFiles: {
    files,
    filter,
    isFetching
  }
}) => ({
  files,
  filter,
  isFetching
});

const enhancer = compose(
  connect(mapStateToProps, {
    applyFilesFilter
  })
);

const useStyles = makeStyles(styles);

const periodsMap = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year'
};

const getNearestPeriod = (date) => {
  return date.isSame(new Date(), periodsMap.day) ? periodsMap.day :
    date.isSame(new Date(), periodsMap.week) ? periodsMap.week :
      date.isSame(new Date(), periodsMap.month) ? periodsMap.month :
        date.isSame(new Date(), periodsMap.year) ? periodsMap.year : null;
};

export const Card = enhancer(({ file, files, filter, isFetching, applyFilesFilter }) => {
  const filesContext = useContext(FilesContext);
  const classes = useStyles();
  const nearestOpenPeriod = getNearestPeriod(moment.unix(file.opened_at));
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      getFileMenuOptions={getFileMenuOptions(filesContext)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        files,
        isFetched: true,
        isFetching,
        filter,
        onNeedNextPage: () => {
          applyFilesFilter({ page: filter.page + 1 });
        }
      }}
    />
  ), [ file, files ]);

  return (
    <div className={classes.root}>
      <div className={classes.filePreview} onClick={openFilesPreview}>
        {file.previews['280'] || file.aggregate_type === 'vector' ?
          <img
            src={file.previews['280'] || file.url}
            alt={file.extension}
            className={classes.filePreview__image}
          />
          :
          <FileTypeIcon className={classes.filePreview__icon} file={file} />
        }
      </div>

      <div className={classes.fileInfo}>
        <FileTypeIcon className={classes.fileInfo__icon} file={file} />

        <div className={classes.fileInfo__main}>
          <Typography noWrap className={classes.fileInfo__name} title={file.original_filename}>
            {file.original_filename}
          </Typography>

          <Typography noWrap className={classes.fileInfo__date}>
            Open {nearestOpenPeriod ? nearestOpenPeriod === periodsMap.day ?
              'today' :
              'in this ' + nearestOpenPeriod :
              'a long time ago'
            }
          </Typography>
        </div>

        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton
                buttonRef={anchorRef}
                className={classes.menuButton}
                onClick={handleToggle}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <FileMenu
              menuList={getFileMenuOptions(filesContext)(file)}
              onClose={handleClose}
            />
          )}
        </Popper>
      </div>
    </div>
  );
});
