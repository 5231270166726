import { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Box, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AddIcon from '@material-ui/icons/Add';
import { UserContext } from '../../../app/Dashboard/Members/User/UserProvider';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { useResizeObserver } from '../../../helpers/hooks/useResizeObserver';
import { isEmptyDeep } from '../../../helpers/isEmptyDeep';
import { hasRole } from '../../../utils/hasRole';
import { CreateTaskModal } from '../../../app/Dashboard/TasksPage/CreateTaskModal';
import { Badge } from '../../Badge';
import { ApplyTaskTemplateToCaseModal } from '../../cases/ApplyTaskTemplateToCaseModal';
import { FiltersBarModal } from '../../FiltersBarModal';
import { IconButton } from '../../IconButton';
import TemplateSvg from '../../icons/template.svg';
import { LayoutContext, viewVariantsMap } from '../../LayoutContext';
import { SvgIcon } from '../../SvgIcon';
import { WidgetsActionButtons } from '../../WidgetsActionButtons';
import { useModal } from '../../ModalsProvider';
import { ContentCard } from '../../ContentCard';
import { Fab } from '../../Fab';
import { TasksContext, TasksFilterContext } from '../TasksProvider';
import { ActionsBar } from './ActionsBar';
import { AddTasksModal } from './AddTasksModal';
import { FiltersBar } from './FiltersBar';
import { fieldsKeysMap } from './FiltersBar/filterKeysMap';
import { filterFields } from './filterFields';
import { Grid } from './Grid';
import { List } from './List';

export const BREAKPOINT_LARGE = 1000;

const initialFilter = {
  search: null,
  types: null,
  statuses: null,
  created_from: null,
  created_to: null,
  due_from: null,
  due_to: null,
  priorities: null
};

export const getFiltersValuesFromOptions = (values) => ({
  ...values,

  search: values?.search ?? null,
  types: values?.types?.map((type) => type?.id) || null,
  statuses: values?.statuses?.map(({ value }) => value) || null,
  priorities: values?.priorities?.map(({ value }) => value || null)
});

export const Card = ({
  isTabPage,
  page,
  caseItem,
  filterKey,
  height = null,
  isDisabledOpen = false,
  caseUsers = [],
  widgetFilters = {},
  updateFilters = () => {},
  setCaseUsers = () => {},
  onCaseItemUpdate = () => {},
  onCaseUsersChange = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,

  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { openModal } = useModal();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const { fetchTasks } = useContext(TasksContext);
  const { applyFilter, filter } = useContext(TasksFilterContext);
  const [ filters, setFilters ] = useState({ ...initialFilter, ...widgetFilters?.tasks });
  const { viewVariant } = useContext(LayoutContext);
  const userContext = useContext(UserContext);
  const profile = useSelector(({ profile }) => profile);
  const user = userContext ? userContext.user : profile.user;
  const hiddenFilterFields = [ fieldsKeysMap.cases, user.role !== rolesMap.admin && fieldsKeysMap.tasks ];
  const twoColumnsFilter = true;

  const onFiltersUpdate = (values) => {
    setFilters(values);
    updateFilters({ tasks: values });
  };

  const handleResetFilter = (applyFilter) => () => {
    applyFilter(initialFilter);
    onFiltersUpdate(initialFilter);
  };

  const handleFilterTasks = (filter, applyFilter) => () => {
    openModal(FiltersBarModal, {
      payload: {
        title: 'Tasks Filters',
        initialValues: filters,
        fields: filterFields,
        twoColumnsFilter,
        setFilters: onFiltersUpdate
      },
      onModalResolved: (values) => {
        applyFilter(getFiltersValuesFromOptions(values));
      }
    });
  };

  const addTask = (fetchTasks) => () => {
    openModal(CreateTaskModal, {
      payload: {
        initialValues: {
          parent: caseItem || null
        },
        onListUpdate: fetchTasks
      }
    });
  };

  const assignTasksModal = (fetchTasks) => () => {
    openModal(AddTasksModal, {
      payload: {
        parent_id: caseItem?.id
      },
      onModalResolved: () => {
        fetchTasks();
      }
    });
  };

  const applyTemplate = () => {
    openModal(ApplyTaskTemplateToCaseModal, {
      payload: {
        initialValues: {
          case_id: caseItem?.id
        }
      },
      onModalResolved: () => {
        fetchTasks();
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableDivider={isTabPage}
      disableContentPaddings
      disableScrollbars
      elevation={isTabPage && 3}
      title="Tasks"
      icon={!hasRole('client', 'patient') &&
        <>
          <Tooltip title="Add Task">
            <Fab
              color="primary"
              size="small"
              onClick={addTask(fetchTasks)}
            >
              <AddIcon fontSize="small" />
            </Fab>
          </Tooltip>

          {caseItem &&
            <Tooltip title="Assign Tasks">
              <Fab
                size="small"
                color="primary"
                variant="extended"
                startIcon={<AddIcon/>}
                onClick={assignTasksModal(fetchTasks)}
              >
                {isMobile ? 'Task' : 'Assign tasks'}
              </Fab>
            </Tooltip>
          }

          {caseItem &&
            <Fab
              size="small"
              color="primary"
              variant="extended"
              startIcon={<SvgIcon><TemplateSvg /></SvgIcon>}
              onClick={applyTemplate}
            >
              Apply template
            </Fab>
          }
        </>
      }
      endIcon={(!isTabPage || width < BREAKPOINT_LARGE) &&
        <Box display="flex" alignItems="center">
          <Tooltip title="Set Filter">
            <IconButton
              color="primary"
              onClick={handleFilterTasks(filter, applyFilter)}
            >
              <Badge
                invisible={isEmptyDeep(filters)}
                badgeContent={
                  <Tooltip title="Filter is used">
                    <SvgIcon fontSize="small" color="success">
                      <CheckCircleIcon/>
                    </SvgIcon>
                  </Tooltip>
                }
              >
                <SvgIcon fontSize="small">
                  <Icon path={mdiFilter}/>
                </SvgIcon>
              </Badge>
            </IconButton>
          </Tooltip>

          <Tooltip title="Reset Filter">
            <IconButton
              color="primary"
              onClick={handleResetFilter(applyFilter)}
            >
              <BackupIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
        </Box>
      }
      leftActions={[
        <PlaylistAddCheckIcon fontSize="small"/>
      ]}
      rightActions={[
        !isTabPage ?
          <WidgetsActionButtons
            isMinimizable
            page={page}
            type={props?.item?.i}
            isDisabledOpen={isDisabledOpen}
            onMinimize={onMinimize}
            onMaximized={onMaximized}
            onClose={onClose}
            onOpen={onOpen}
            onPutItem={onPutItem}
          />
          :
          <ActionsBar isMobile={isMobile}/>
      ]}
      {...props}
    >
      <Box
        ref={rootRef}
        display="flex"
        flexDirection="column"
        height={isMobile && !isTabPage ? height : '100%'}
        overflow="hidden"
      >
        {isTabPage && width > BREAKPOINT_LARGE &&
          <FiltersBar filterKey={filterKey} hiddenFilterFields={hiddenFilterFields} />
        }

        <Box flexGrow={1}>
          {isTabPage ? (
            isMobile || viewVariant === viewVariantsMap.grid ? (
              <Grid />
            ) : viewVariant === viewVariantsMap.list ? (
              <List width={width} />
            ) : null
          ) : (
            isMobile ? <Grid /> : <List width={width} />
          )}
        </Box>
      </Box>
    </ContentCard>
  );
};
