import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_VERSIONS_REQUEST]: (state) => {
    return {
      ...state,

      isFetching: true
    };
  },

  [types.FETCH_VERSIONS_SUCCESS]: (
    { filter, versions, ...state },
    { data: newVersion, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      versions: page > 1 ? (
        versions.filter(({ id }) => !newVersion.find((loadedVersion) => id === loadedVersion.id)).concat(newVersion)
      ) : (
        newVersion
      )
    };
  },

  [types.ADD_VERSION]: (state, payload) => {
    return {
      ...state,

      versions: [ payload, ...state.versions ]
    };
  }
});
