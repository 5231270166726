import moment from 'moment';

export const initialState = {
  filter: {
    from: moment().subtract(1, 'year').startOf('year').unix(),
    to: moment().unix(),
    users_id: [],
    case_id: null,
    company_id: null
  }
};
