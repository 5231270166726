import { FieldArray } from 'formik';
import { Box, FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '../../Fab';
import { RadioGroup, TextField } from '../../FormField';
import { SurgeriesList } from './SurgeriesList';

export const SurgeryHistory = () => {
  const handleAddFields = (arrayHelpers, fields) => (event) => {
    if ((event.charCode || event.keyCode) === 13) {
      arrayHelpers.push(fields);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          PAST SURGICAL HISTORY
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FieldArray
          name="forms.past_medical_history.past_surgical_history"
          render={(arrayHelpers) => (
            <>
              <Box display="flex" alignItems="center">
                <Box component="span" mr={2}>
                  <Typography>
                    Please list all surgeries you have had in the past
                  </Typography>
                </Box>

                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon/>}
                  onClick={() => arrayHelpers.push({
                    surgery: '',
                    date_from: null,
                    date_to: null,
                    surgeon: ''
                  })}
                >
                  Add
                </Fab>
              </Box>

              <SurgeriesList arrayHelpers={arrayHelpers} onAddFields={handleAddFields} />
            </>
          )}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <Typography variant="h5">
          Have you had any problems with anesthesia?
        </Typography>
      </Grid>

      <Grid item md={6} xs={12}>
        <RadioGroup
          row
          name="forms.past_medical_history.have_you_had_any_problems_with_anesthesia"
        >
          <FormControlLabel
            value="yes"
            label={<Typography>Yes</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="no"
            label={<Typography>No</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item md={3} xs={12}>
        <Box pl={2}>
          <Typography>Please explain if yes:</Typography>
        </Box>
      </Grid>

      <Grid item md={9} xs={12}>
        <TextField
          name="forms.past_medical_history.please_explain_if_yes"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>
    </>
  );
};
