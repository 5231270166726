import { useContext } from 'react';
import { Box, Typography, Tooltip, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { mdiEmailEditOutline, mdiReplyOutline, mdiReplyAllOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { stopPropagation } from '../../../../../../helpers/dom';
import { IconButton } from '../../../../../../components/IconButton';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Checkbox } from '../../../../../../components/FormField';
import { Popper } from '../../../../../../components/Popper';
import { Date } from '../../../../../../components/Date';
import { MessagesContext } from '../../../MessagesContext';
import { AccountsContext, folderNamesMap } from '../../../AccountsContext';
import { EmailsReplyModal } from '../../EmailsReplyModal';
import { EmailsSendDraftModal } from '../../EmailsSendDraftModal';
import { DownloadMenu } from './DownloadMenu';
import { Menu } from './Menu';

export const ActionsBar = () => {
  const {
    filter,
    media,
    isOpenView,
    toggleEmailView,
    messageItem,
    toggleMessageFlagged,
    deleteMessage
  } = useContext(MessagesContext);
  const { accounts } = useContext(AccountsContext);
  const { openModal } = useModal();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fromName = messageItem?.from?.map(({ name }) => name).join(', ');
  const fromEmail = messageItem?.from?.map(({ email }) => email).join(', ');

  const openSendEmailModal = () => {
    openModal(EmailsSendDraftModal, {
      payload: {
        accounts,
        folderID: filter.folder_id,
        message: messageItem
      },
      onModalResolved: () => {
        deleteMessage([ messageItem.uid ]);
      }
    });
  };

  const openReplyEmailModal = () => {
    openModal(EmailsReplyModal, {
      payload: {
        disableReplayAll: true,
        replyTo: { ...messageItem, attachments: !!media?.length ? media : messageItem.attachments }
      }
    });
  };

  const openReplyAllEmailModal = () => {
    openModal(EmailsReplyModal, {
      payload: {
        replyTo: { ...messageItem, attachments: !!media?.length ? media : messageItem.attachments }
      }
    });
  };

  const handleFlagged = (event) => {
    toggleMessageFlagged(messageItem.uid, event.target.checked ? 'flagged' : 'unflagged');
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      py={1.5}
      pl={isTablet ? 1 : 3}
      pr={1}
    >
      {isTablet && (
        <Box mr={1}>
          <IconButton color="primary" onClick={toggleEmailView}>
            <SvgIcon color="inherit">
              {isOpenView ? <ChevronLeftIcon color="primary" /> : <ChevronRightIcon color="primary" />}
            </SvgIcon>
          </IconButton>
        </Box>
      )}

      {!isMobile && (
        <div>
          <Typography variant="h5" color="textPrimary">{fromName}</Typography>
          <Typography color="textSecondary">{fromEmail}</Typography>
        </div>
      )}

      <Box display="flex" alignItems="center">
        {!isMobile && (
          <Box mr={4}>
            <Date disableIcon date={messageItem?.date} format="L, LT" />
          </Box>
        )}

        <Tooltip arrow title={messageItem?.flags?.flagged ? 'Remove from favourites' : 'Add to favourites'}>
          <Checkbox
            withoutFormik
            name="flagged"
            color="warning"
            checked={messageItem?.flags?.flagged}
            icon={<StarBorderIcon />}
            checkedIcon={<StarIcon />}
            onClick={stopPropagation(handleFlagged)}
          />
        </Tooltip>

        {filter.folder_name === folderNamesMap.drafts ?
          <Tooltip arrow title="Send message">
            <IconButton color="primary" onClick={openSendEmailModal}>
              <SvgIcon><Icon path={mdiEmailEditOutline} /></SvgIcon>
            </IconButton>
          </Tooltip>
          :
          <>
            <Tooltip arrow title="Reply all">
              <IconButton color="primary" onClick={openReplyAllEmailModal}>
                <SvgIcon><Icon path={mdiReplyAllOutline} /></SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Reply">
              <IconButton color="primary" onClick={openReplyEmailModal}>
                <SvgIcon><Icon path={mdiReplyOutline} /></SvgIcon>
              </IconButton>
            </Tooltip>
          </>
        }

        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <Tooltip arrow title="Download message">
                <Box color="info.main">
                  <IconButton
                    buttonRef={anchorRef}
                    color="inherit"
                    onClick={handleToggle}
                  >
                    <CloudDownloadOutlinedIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            )
          }
        >
          <DownloadMenu />
        </Popper>

        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} color="inherit" onClick={handleToggle}>
                <MoreIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <Menu onClose={handleClose} />
          )}
        </Popper>
      </Box>
    </Box>
  );
};
