import { useContext, useRef } from 'react';
import { List as MuiList, Typography, Box } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { ScrollbarsCustom } from '../../../../../components/ScrollbarsCustom';
import { Sticky } from '../../../../../components/Sticky';
import { Loader } from '../../../../../components/Loader';
import { TaskTemplatesGroupsContext } from '../TaskTemplatesGroupsProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const widthBreakpointSmall = 1300;
export const widthBreakpointMedium = 1400;
export const widthBreakpointLarge = 1600;
export const minRowHeight = 54;
export const columnsWidths = {
  name: 200,
  description: 250,
  createdDate: 160,
  updatedDate: 160,
  actions: 80
};

export const List = () => {
  const {
    groups,
    isFetched,
    isFetching,
    meta,
    loadNextPage
  } = useContext(TaskTemplatesGroupsContext);
  const scrollElementRef = useRef();

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !meta.total ? (
          <Box p={2}>
            <Typography align="center">No templates found</Typography>
          </Box>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={groups}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                RowComponent={Row}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </ScrollbarsCustom>
  );
};
