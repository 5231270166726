export const commentsActionsMap = {
  createCaseComment: 'CREATED@CASE:COMMENT',
  updateCaseComment: 'UPDATED@CASE:COMMENT',
  deleteCaseComment: 'DELETED@CASE:COMMENT',
  restoreCaseComment: 'RESTORED@CASE:COMMENT',
  createTaskComment: 'CREATED@TASK:COMMENT',
  updateTaskComment: 'UPDATED@TASK:COMMENT',
  deleteTaskComment: 'DELETED@TASK:COMMENT',
  restoreTaskComment: 'RESTORED@TASK:COMMENT',
  createScheduleTemplateComment: 'CREATED@SCHEDULE_RECURRING_TEMPLATE:COMMENT',
  updateScheduleTemplateComment: 'UPDATED@SCHEDULE_RECURRING_TEMPLATE:COMMENT',
  deleteScheduleTemplateComment: 'DELETED@SCHEDULE_RECURRING_TEMPLATE:COMMENT',
  restoreScheduleTemplateComment: 'RESTORED@SCHEDULE_RECURRING_TEMPLATE:COMMENT',
  createPrescriptionComment: 'CREATED@PRESCRIPTION:COMMENT',
  updatePrescriptionComment: 'UPDATED@PRESCRIPTION:COMMENT',
  deletePrescriptionComment: 'DELETED@PRESCRIPTION:COMMENT',
  restorePrescriptionComment: 'RESTORED@PRESCRIPTION:COMMENT',
  createEventComment: 'CREATED@SCHEDULE_EVENT:COMMENT',
  updateEventComment: 'UPDATED@SCHEDULE_EVENT:COMMENT',
  deleteEventComment: 'DELETED@SCHEDULE_EVENT:COMMENT',
  restoreEventComment: 'RESTORED@SCHEDULE_EVENT:COMMENT',
  createProfileComment: 'CREATED@PROFILE:COMMENT',
  updateProfileComment: 'UPDATED@PROFILE:COMMENT',
  deleteProfileComment: 'DELETED@PROFILE:COMMENT',
  restoreProfileComment: 'RESTORED@PROFILE:COMMENT',
  createComment: 'CREATED@COMMENT',
  updateComment: 'UPDATED@COMMENT',
  deleteComment: 'DELETED@COMMENT',
  restoreComment: 'RESTORED@COMMENT'
};

const activityTypesMap = {
  task: 'task',
  case: 'case',
  profile: 'profile',
  scheduleEvent: 'schedule_event',
  scheduleRecurringTemplate: 'schedule_recurring_template',
  prescription: 'prescription'
};

export const commentsActivityMap = {
  [activityTypesMap.task]: {
    label: 'Task Comment'
  },
  [activityTypesMap.case]: {
    label: 'Case Comment'
  },
  [activityTypesMap.profile]: {
    label: 'Profile Comment'
  },
  [activityTypesMap.scheduleEvent]: {
    label: 'Schedule Event Comment'
  },
  [activityTypesMap.scheduleRecurringTemplate]: {
    label: 'Schedule Recurring Template Comment'
  },
  [activityTypesMap.prescription]: {
    label: 'Prescription Comment'
  }
};
