import { Typography } from '@material-ui/core';
import moment from 'moment';
import { ColorPreview } from '../../../ColorPreview';
import { CurrencyFormat } from '../../../CurrencyFormat';
import { Date } from '../../../Date';
import { Loader } from '../../../Loader';
import { Tooltip } from '../../../Tooltip';
import { UserLink } from '../../../UserLink';
import { ListRow, ListRowCell } from '../../../ListRow';
import { arrivalTypes } from '../../arrivalTypes';
import { Menu } from '../../Menu';
import { columnsMap, columnsWidths } from '../AppointmentsList';

export const AppointmentRow = ({
  appointment,
  isLoaded,
  hiddenColumns = [],
  ListRowProps = {},
  onClick = () => {},
  onAppointmentUpdate,
  onAppointmentDelete
}) => {
  return !isLoaded ? <Loader p={2} /> : (
    <ListRow px={1} onClick={onClick} {...ListRowProps}>
      <ListRowCell column flexBasis={columnsWidths.date}>
        <Date date={appointment?.appointment_at} format="L LT" variant="body2" color="primary" />

        {moment.duration(appointment.time, 'seconds').format('h[h] m[min]')}
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.patient) &&
        <ListRowCell flexBasis={columnsWidths.patient}>
          <UserLink
            size="sm"
            noWrap
            variant="inherit"
            user={appointment?.patient}
          />
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.provider) &&
        <ListRowCell flexBasis={columnsWidths.provider}>
          <UserLink
            size="sm"
            noWrap
            variant="inherit"
            user={appointment?.appointment_book?.provider}
          >
            <Typography variant="body1">
              {appointment.appointment_book?.appointment_book_name || '-'}
            </Typography>
          </UserLink>
        </ListRowCell>
      }

      <ListRowCell actions flexBasis={columnsWidths.apptType}>
        {!appointment.appointment_type ? '-' : (
          <>
            <ColorPreview color={appointment.appointment_type?.color} />
            <span>{appointment.appointment_type?.code}</span>
          </>
        )}
      </ListRowCell>

      <ListRowCell actions flexBasis={columnsWidths.status}>
        {!appointment.arrival_status ? '-' : (
          <>
            <ColorPreview color={arrivalTypes[appointment.arrival_status?.arrival_type]?.color} />
            <span>{appointment.arrival_status?.code} - {appointment.arrival_status?.description}</span>
          </>
        )}
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.balance) &&
        <ListRowCell flexBasis={columnsWidths.balance}>
          <CurrencyFormat value={appointment?.billing_info?.total_balance} />
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.note) &&
        <ListRowCell grow noWrap flexBasis={columnsWidths.note}>
          <Tooltip title={appointment?.note}>
            <Typography noWrap>
              {appointment?.note || '-'}
            </Typography>
          </Tooltip>
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.actions) &&
        <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
          <Menu
            appointment={appointment}
            ButtonProps={{ edge: 'end' }}
            onAppointmentUpdate={onAppointmentUpdate}
            onAppointmentDelete={onAppointmentDelete}
          />
        </ListRowCell>
      }
    </ListRow>
  );
};
