import { useContext } from 'react';
import { FilePreviewContext } from '../../../FilePreviewContext';

export const Sharepoint = () => {
  const { selectedFile } = useContext(FilePreviewContext);

  return (
    <iframe
      title="Sharepoint file preview"
      src={selectedFile?.url}
      width="100%"
      height="100%"
    />
  );
};
