import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as visitReasonsApi from '../../../../../../api/codes/appointments/visit-reasons';
import { Loader } from '../../../../../../components/Loader';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { validationSchema } from '../validationSchema';
import { VisitReasonForm } from '../VisitReasonForm';

export const Edit = ({ baseUrl }) => {
  const history = useHistory();
  const params = useParams();
  const [ visitReason, setVisitReason ] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const updateVisitReason = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return visitReasonsApi.updateVisitReason(values).then(() => {
      history.push(`${baseUrl}/visit-reasons`);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  useEffect(() => {
    const fetchVisitReason = () => {
      visitReasonsApi.fetchVisitReason(params.id)
        .then(setVisitReason)
        .catch(() => {
          history.push(`${baseUrl}/visit-reasons`);
        });
    };

    fetchVisitReason();
  }, []);

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={visitReason}
      onSubmit={updateVisitReason}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Page noValidate component="form" onSubmit={handleSubmit}>
          <PageHeader>
            <IconButton
              component={Link}
              size={isMobile ? 'small' : 'medium'}
              to={`${baseUrl}/visit-reasons`}
              color="primary"
              edge="start"
            >
              <KeyboardBackspaceOutlinedIcon />
            </IconButton>

            <PageHeaderTitle>
              Edit visit reason
            </PageHeaderTitle>

            <PageHeaderActions>
              <Button
                component={Link}
                size={isMobile ? 'small' : 'medium'}
                to={`${baseUrl}/visit-reasons`}
                color="primary"
              >
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </PageHeaderActions>
          </PageHeader>

          <PageBody>
            <Loader loading={!visitReason} render={
              () => (
                <VisitReasonForm />
              )}
            />
          </PageBody>
        </Page>
      )}
    </Formik>
  );
};
