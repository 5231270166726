import { makeStyles } from '@material-ui/core';
import { AccountsProvider, SavedEmails } from '../../EmailsPage';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Emails = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AccountsProvider disableCounters>
        <SavedEmails />
      </AccountsProvider>
    </div>
  );
};
