import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog } from '@material-ui/core';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import * as accessPatientsApi from '../../../../../api/profile/access-patients';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { UsersSelect } from '../../../../../components/users';

export const AddPatientModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    accessibleUserID
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const createAccessPatient = (values) => {
    return accessPatientsApi.createAccessPatient({
      patient_id: values.user_id,
      user_id: accessibleUserID
    }).then(({ data }) => {
      enqueueSnackbar('Patient successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(() => {
      enqueueSnackbar('Something goes wrong!', { variant: 'error' });
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          user_id: null
        }}
        onSubmit={createAccessPatient}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader
              icon={<PersonAddOutlinedIcon />}
              onClose={handleModalReject}
            >
              ADD New Patient
            </ModalHeader>

            <ModalBody>
              <UsersSelect
                name="user_id"
                label="User Name"
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Box mr={1}>
                <Button onClick={handleModalReject} color="primary">Cancel</Button>
              </Box>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
