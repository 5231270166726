export const styles = ({
  spacing,
  palette: { grey, primary, ...palette },
  typography: { pxToRem, fontWeightMedium }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  loader: {
    padding: spacing(2)
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },

  chartCounter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexGrow: 0,
    padding: spacing(1, 2)
  },

  chart: {
    flexGrow: 1
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: pxToRem(10)
  },

  name: {
    fontSize: pxToRem(13)
  },

  count: {
    color: primary.contrastText,
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(12),
    marginLeft: spacing(),
    padding: spacing(0, 1),
    borderRadius: 50
  },

  billed: {
    backgroundColor: palette.darkgreen
  },

  payments: {
    backgroundColor: palette.purple
  },

  overdue: {
    backgroundColor: palette.error.main
  },

  amount: {
    fontSize: pxToRem(20)
  }
});
