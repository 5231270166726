import { mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { SvgIcon } from '@material-ui/core';
import { IconButton } from '../../IconButton';

export const FilterBarToggler = ({ handleToggleFiltersBar }) => {
  return (
    <IconButton color="primary" onClick={handleToggleFiltersBar}>
      <SvgIcon color="inherit">
        <Icon path={mdiFilter} />
      </SvgIcon>
    </IconButton>
  );
};
