import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Echo } from '../../../utils/echo';
import { Comment } from './Comment';

export const Notifications = () => {
  const currentUser = useSelector(({ profile: { user } }) => user);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const commentsChanel = Echo.private(`comments.${currentUser.id}`);

    const handleCommentCreated = ({ activity }) => {
      enqueueSnackbar(<Comment activity={activity} />);
    };

    commentsChanel
      .listen('.created', handleCommentCreated);

    return () => {
      commentsChanel
        .stopListening('.created', handleCommentCreated);
    };
  }, []);

  return null;
};
