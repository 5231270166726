import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../index';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={2}>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.type}>
          Type
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.isSystem}>
          Is system
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.date}>
          Created at
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.date}>
          Updated at
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
