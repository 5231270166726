export const filterFieldsMap = {
  patientName: 'patient_name',
  patientEmail: 'patient_email',
  patientPhone: 'patient_phone',
  patientAddress: 'patient_address',
  doi: 'doi'
};

export const filterFieldsLabels = {
  [filterFieldsMap.patientName]: 'User Name',
  [filterFieldsMap.patientEmail]: 'User Email',
  [filterFieldsMap.patientPhone]: 'User Phone',
  [filterFieldsMap.patientAddress]: 'User Address',
  [filterFieldsMap.doi]: 'Date Of Injury'
};
