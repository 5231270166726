import { Typography,  Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import {
  Autocomplete,
  KeyboardDateTimePicker,
  TextField
} from '../../../../../../components/FormField';
import { conditionsOptions } from './conditionsOptions';
import { incidentTypesOptions } from './incidentTypesOptions';

export const IncidentInformation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <Grid container spacing={2} component={Box} px={isMobile ? 1 : 2} pt={4}>
      <Grid item xs={12}>
        <Box color="info.main">
          <Typography variant="h6" color="inherit">
            Incident Information
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <TextField
          name="accident_location"
          label="Accident location"
          placeholder="Enter accident location..."
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <TextField
          name="liability"
          label="Liability"
          placeholder="Enter liability..."
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <KeyboardDateTimePicker
          disableFuture
          name="accident_time"
          outputFormat="YYYY-MM-DD H:m:s"
          label="Accident time"
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <Autocomplete
          name="incident_type"
          label="Incident type"
          options={incidentTypesOptions}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <Autocomplete
          name="conditions"
          label="Conditions"
          options={conditionsOptions}
        />
      </Grid>
    </Grid>
  );
};
