import { FieldArray, useFormikContext } from 'formik';
import {
  Box,
  Collapse,
  Grid,
  Typography,
  Hidden,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { Fab } from '../../../../../../components/Fab';
import {
  PhoneField,
  TextField
} from '../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../components/users';

export const Contacts = () => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  const handledUserChange = (index) => (user) => {
    if (user || user === null) {
      setFieldValue(`users.${index}.phone`, user?.phone || null);
      setFieldValue(`users.${index}.position`, user?.position || null);
    }
  };

  return (
    <Box mb={3}>
      <FieldArray
        name="users"
        render={(arrayHelpers) => (
          <>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Typography variant="h3">Office Staff</Typography>
              </Box>

              <Fab
                size="small"
                color="primary"
                variant="extended"
                startIcon={<AddIcon/>}
                onClick={() => arrayHelpers.push({
                  id: '',
                  position: '',
                  phone: ''
                })}
              >
                Add
              </Fab>
            </Box>

            <Collapse
              unmountOnExit
              in={!!values?.users?.length}
              timeout="auto"
            >
              {values?.users?.map((item, index) => (
                <Box key={index} my={2}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="flex-end"
                    justify="space-between"
                    wrap={isMobile ? 'wrap' : 'nowrap'}
                  >
                    <Grid item xs={1}>
                      <Typography>{index + 1}.</Typography>
                    </Grid>

                    <Grid item sm={4} xs={10}>
                      <UsersSelect
                        disabled={values?.disableUserRemove && index === 0}
                        formattedValue={false}
                        name={`users.${index}.id`}
                        label="Staff"
                        onChange={handledUserChange(index)}
                      />
                    </Grid>

                    <Hidden mdUp>
                      <Grid item xs={1}>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={handleClearField(arrayHelpers, index)}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Hidden>

                    <Grid item sm={4} xs={12}>
                      <TextField
                        zeroMinWidth
                        name={`users.${index}.position`}
                        label="Position"
                        placeholder="Enter position..."
                      />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <PhoneField
                        zeroMinWidth
                        isEditable={false}
                        name={`users.${index}.phone`}
                        label="Phone"
                      />
                    </Grid>

                    <Hidden smDown>
                      <Grid item>
                        <IconButton
                          disabled={values?.disableUserRemove && index === 0}
                          size="small"
                          color="error"
                          onClick={handleClearField(arrayHelpers, index)}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Box>
              ))}
            </Collapse>
          </>
        )}
      />
    </Box>
  );
};
