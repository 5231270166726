import { useContext } from 'react';
import { omit, values } from 'lodash';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as filtersApi from '../../../../api/filters';
import { isEmptyDeep } from '../../../../helpers/isEmptyDeep';
import { Fab } from '../../../Fab';
import { ModalBody } from '../../../Modal';
import { SavedFiltersContext } from '../../SavedFiltersProvider';
import { Grid } from './Grid';

export const ModalContent = ({
  hiddenFields,
  relationsForFilter,
  onApplySavedFilter,
  CardContent,
  onFilterAdd
}) => {
  const { filter, addSavedFilter } = useContext(SavedFiltersContext);
  const isDisabled = isEmptyDeep(values(omit(relationsForFilter, [ 'order_by' ])));

  const saveCurrentFilter = () => {
    filtersApi.createFilter({
      entity: filter.entity,
      route: 'route',
      params: relationsForFilter
    }).then((data) => {
      addSavedFilter(data);
      onFilterAdd(data);
    });
  };

  return (
    <ModalBody disablePaddings>
      <Box display="flex" flexDirection="column" p={1} pr={2} flexGrow={1}>
        <Box pb={1}>
          <Fab
            disabled={isDisabled}
            variant="extended"
            color="primary"
            startIcon={<AddIcon />}
            onClick={saveCurrentFilter}
          >
            Save current filter
          </Fab>
        </Box>

        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Grid CardContent={CardContent} onApplySavedFilter={onApplySavedFilter} hiddenFields={hiddenFields} />
        </Box>
      </Box>
    </ModalBody>
  );
};
