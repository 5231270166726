export const styles = ({
  spacing,
  palette: { primary, darkgreen },
  transitions
}) => ({
  root: {
    cursor: 'pointer',
    transition: transitions.create(),
    paddingLeft: spacing(),

    '&:hover': {
      backgroundColor: 'rgba(0, 188, 212, 0.05)',
      borderBottom: `1px solid ${primary.main}`
    }
  },

  statusLabel: {
    color: primary.contrastText
  },

  balance: {
    color: darkgreen
  }
});
