import { Box, List as MuiList, Typography } from '@material-ui/core';
import { Row } from './Row';

export const List = ({
  filteredUsers,
  width,
  caseItem,
  onCaseItemUpdate
}) => {
  return (
    !filteredUsers.length ? (
      <Box p={2}>
        <Typography align="center">No users found</Typography>
      </Box>
    ) : (
      <MuiList disablePadding>
        {filteredUsers.map((user) => (
          <Row
            width={width}
            key={user.id}
            caseUser={user}
            caseItem={caseItem}
            onCaseItemUpdate={onCaseItemUpdate}
          />
        ))}
      </MuiList>
    )
  );
};
