import { useContext, useEffect, useRef } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useResizeObserver } from '../../../../../../helpers/hooks/useResizeObserver';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { Page, PageBody, PageHeader } from '../../../../../../components/Page';
import { MedicalFormsContext } from '../MedicalFormsProvider';
import { FormFiltersBar } from '../FormFiltersBar';
import { ActionsBar } from './ActionsBar';
import { Grid } from './Grid';
import { List } from './List';

export const MainContent = ({ filterKey, hiddenFilterFields = [] }) => {
  const { viewVariant } = useContext(LayoutContext);
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const { resetForms, ...formContext } = useContext(MedicalFormsContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  useEffect(() => {
    resetForms();
  }, []);

  return (
    <Page
      ref={rootRef}
      square={false}
      elevation={0}
      component={Box}
      pt={0.5}
    >
      <PageHeader disableBorderBottom>
        <ActionsBar filterOpenButton={filterOpenButton} />
      </PageHeader>

      <PageBody fullHeight disablePaddings disableScrollbars>
        <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
          <ScrollWrapper {...scrollWrapperProps}>
            <FilterBarWrapper {...filterBarWrapperProps}>
              <FormFiltersBar
                filterKey={filterKey}
                hiddenFilterFields={hiddenFilterFields}
                resetForms={resetForms}
              />
            </FilterBarWrapper>
          </ScrollWrapper>

          <Box clone flexGrow={1}>
            {!isTablet ? (
              (viewVariant === viewVariantsMap.list)
                ? <List width={width} resetForms={resetForms} formContext={formContext} />
                : (viewVariant === viewVariantsMap.grid)
                  ? <Grid formContext={formContext} /> : null
            ) : (
              <Grid formContext={formContext} />
            )}
          </Box>
        </Box>
      </PageBody>
    </Page>
  );
};
