export const styles = ({
  spacing,
  palette,
  typography: { fontWeightMedium, pxToRem }
}) => ({
  caseFileNumber: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  caseIcon: {
    fontSize: pxToRem(18),
    color: palette.darkgreen,
    marginTop: spacing(-0.25)
  },

  caseName: {
    fontWeight: fontWeightMedium,
    marginBottom: spacing(0.25)
  },

  mainInfoCell: {
    display: 'flex',
    alignItems: 'center'
  },

  fileNumber: {
    marginLeft: spacing(1)
  }
});
