import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ErrorMessage, useFormikContext } from 'formik';
import { Checkbox, RadioGroup, TextField } from '../../../FormField';
import { HistoryInjury } from './HistoryInjury';

const attendedInitialValues = [ 'physical_therapy', 'chiropractor', 'pool_therapy', 'acupuncture' ];
const usedInitialValues = [ 'bone_stimulator', 'tens_unit', 'cane', 'walker', 'other' ];

export const PatientHistoryForm = () => {
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const toggleNoneHistory = (name) => (event) => {
    const checked = event.target.checked;

    if (checked) {
      if (name.includes('attended')) {
        attendedInitialValues.forEach((item) => {
          setFieldValue(`${name}.${item}`, false);
        });
      } else {
        usedInitialValues.forEach((item) => {
          setFieldValue(`${name}.${item}`, false);
        });
      }
    }
  };

  const toggleHistoryPain = (name) => (event) => {
    const checked = event.target.checked;

    if (checked) {
      setFieldValue(`${name}.none`, false);
    }
  };

  return (
    <Grid
      container
      spacing={isSmallScreen ? 2 : 3}
      alignItems="flex-end"
      component={Box}
      pb={3}
    >
      <Grid item xs={12}>
        <Typography variant={isSmallScreen ? 'h4' : 'h6'} color="secondary">
          Patient History
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          1. Have you attended any of the following?*
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-around" flexWrap="wrap">
          <FormControlLabel
            label={<Typography>Physical Therapy</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.have_you_attended_any_of_the_following.physical_therapy"
                onChange={toggleHistoryPain('forms.patient_history.have_you_attended_any_of_the_following')}
              />
            }
          />

          <FormControlLabel
            label={<Typography>Chiropractor</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.have_you_attended_any_of_the_following.chiropractor"
                onChange={toggleHistoryPain('forms.patient_history.have_you_attended_any_of_the_following')}
              />
            }
          />

          <FormControlLabel
            label={<Typography>Pool Therapy</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.have_you_attended_any_of_the_following.pool_therapy"
                onChange={toggleHistoryPain('forms.patient_history.have_you_attended_any_of_the_following')}
              />
            }
          />

          <FormControlLabel
            label={<Typography>Acupuncture</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.have_you_attended_any_of_the_following.acupuncture"
                onChange={toggleHistoryPain('forms.patient_history.have_you_attended_any_of_the_following')}
              />
            }
          />

          <FormControlLabel
            label={<Typography>None</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.have_you_attended_any_of_the_following.none"
                onChange={toggleNoneHistory('forms.patient_history.have_you_attended_any_of_the_following')}
              />
            }
          />
        </Box>

        <Typography color="error">
          <ErrorMessage name="forms.patient_history.have_you_attended_any_of_the_following" />
        </Typography>
      </Grid>

      <Grid item md={3} xs={12}>
        <Box ml={2}>
          <Typography>
            How Often and When?
          </Typography>
        </Box>
      </Grid>

      <Grid item md={9} xs={12}>
        <TextField
          fullWidth
          required={!values?.forms?.patient_history?.have_you_attended_any_of_the_following?.none}
          label="Description"
          placeholder="Enter description..."
          name="forms.patient_history.how_often_and_when"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <Box ml={2}>
          <Typography>
            Is this injury related to the accident?
          </Typography>
        </Box>
      </Grid>

      <Grid item md={9} xs={12}>
        <RadioGroup row name="forms.patient_history.is_this_injury_related_to_the_accident">
          <FormControlLabel
            value="yes"
            label={<Typography>Yes</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="no"
            label={<Typography>No</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item md={3} xs={12}>
        <Typography gutterBottom variant="h5">
          2. Have you undergone any diagnostic testing?*
        </Typography>
      </Grid>

      <Grid item md={9} xs={12}>
        <RadioGroup
          row
          name="forms.patient_history.have_you_undergone_any_diagnostic_testing_mri_ct_emg_labs"
        >
          <FormControlLabel
            value="MRI"
            label={<Typography>MRI</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="CT"
            label={<Typography>CT</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="EMG"
            label={<Typography>EMG</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="LABS"
            label={<Typography>LABS</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="No"
            label={<Typography>No</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom variant="h5">
          3. Are you using any of the following:*
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-around" flexWrap="wrap">
          <FormControlLabel
            label={<Typography>Bone Stimulator</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.are_you_using_any_of_the_follwing.bone_stimulator"
                onChange={toggleHistoryPain('forms.patient_history.are_you_using_any_of_the_follwing')}
              />
            }
          />

          <FormControlLabel
            label={<Typography>Tens Unit</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.are_you_using_any_of_the_follwing.tens_unit"
                onChange={toggleHistoryPain('forms.patient_history.are_you_using_any_of_the_follwing')}
              />
            }
          />

          <FormControlLabel
            label={<Typography>Cane</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.are_you_using_any_of_the_follwing.cane"
                onChange={toggleHistoryPain('forms.patient_history.are_you_using_any_of_the_follwing')}
              />
            }
          />

          <FormControlLabel
            label={<Typography>Walker</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.are_you_using_any_of_the_follwing.walker"
                onChange={toggleHistoryPain('forms.patient_history.are_you_using_any_of_the_follwing')}
              />
            }
          />

          <FormControlLabel
            label={<Typography>Other</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.are_you_using_any_of_the_follwing.other"
                onChange={toggleHistoryPain('forms.patient_history.are_you_using_any_of_the_follwing')}
              />
            }
          />

          <FormControlLabel
            label={<Typography>None</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.are_you_using_any_of_the_follwing.none"
                onChange={toggleNoneHistory('forms.patient_history.are_you_using_any_of_the_follwing')}
              />
            }
          />
        </Box>

        <Typography color="error">
          <ErrorMessage name="forms.patient_history.are_you_using_any_of_the_follwing" />
        </Typography>
      </Grid>

      <Grid item md={3} xs={12}>
        <Typography gutterBottom variant="h5">
          4. Dominant hand*
        </Typography>
      </Grid>

      <Grid item md={9} xs={12}>
        <RadioGroup
          row
          name="forms.patient_history.dominant_hand"
        >
          <FormControlLabel
            value="right"
            label={<Typography>Right</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="left"
            label={<Typography>Left</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item md={3} xs={12}>
        <Box ml={2}>
          <Typography>
            What body parts are injured?*
          </Typography>
        </Box>
      </Grid>

      <Grid item md={9} xs={12}>
        <TextField
          fullWidth
          required
          label="Description"
          placeholder="Enter description..."
          name="forms.patient_history.what_body_parts_are_injured"
        />
      </Grid>

      <HistoryInjury />
    </Grid>
  );
};
