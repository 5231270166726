export const styles = ({ spacing, typography, breakpoints }) => ({
  container: {
    marginBottom: spacing(2),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2)
    }
  },

  notesFieldInput: {
    lineHeight: typography.pxToRem(21)
  }
});
