import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Box, Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as formsApi from '../../../../../api/profile';
import { capitalizeFirstLetter } from '../../../../../helpers/capitalizeFirstLetter';
import { getUserFullName } from '../../../../../helpers/users';
import { KeyboardDatePicker, TextField } from '../../../../../components/FormField';
import { Body } from '../../../../../components/medical/forms/privatePacket';
import { Divider } from '../../../../../components/Divider';
import { Loader } from '../../../../../components/Loader';
import {
  EmergencyFormContact,
  InsuranceInfo,
  InsuranceInfoPI
} from '../../../../../components/medical/guestForms';
import {
  InitialFormContent,
  formTypesMap,
  Measurements,
  generatePrivateInitialValues,
  generateInitialValues,
  ActionButtons
} from '../../../../../components/medical/forms';
import { subscriberOptions } from '../../../ProfilePage/insurance';
import { validationSchema as WCValidation } from '../../PersonalInjury/validationSchema';
import { FormContext } from '../../FormProvider';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const GeneralInfo = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    form,
    isFetched,
    type,
    handleComplete
  } = useContext(FormContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleBack = () => () => {
    history.push('/');
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.first_name = capitalizeFirstLetter(values.first_name);
    values.last_name = capitalizeFirstLetter(values.last_name);
    values.attorney_is_unknown = Number(values.attorney_is_unknown);

    return formsApi.createGuestMedicalForm(values).then((data) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleComplete(data);
    }).catch(({ errors }) => {
      setErrors(errors);
      enqueueSnackbar('Form not created', { variant: 'error' });
    });
  };

  const handleUpdateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.first_name = capitalizeFirstLetter(values.first_name);
    values.last_name = capitalizeFirstLetter(values.last_name);

    return formsApi.updateGuestMedicalForm(values).then((data) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleComplete(data);
    }).catch(({ errors }) => {
      setErrors(errors);
      enqueueSnackbar('Form not created', { variant: 'error' });
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            ...generateInitialValues({ type, initialValues: form }),
            ...generatePrivateInitialValues(form),
            auto_accident_country: null,
            hearing_date: null,
            vision_date: null,
            primary_insurance_holder_dob: form?.primary_insurance_holder_dob || null,
            primary_insurance_id_number: form?.primary_insurance_id_number || null,
            primary_insurance_name: form?.primary_insurance_name || null,
            primary_treating_physician: form?.primary_treating_physician ||
              (form?.treating_physician?.id && getUserFullName(form?.treating_physician)) || null ,
            relationship_to_patient: subscriberOptions?.find(({ value }) => (
              value === form?.relationship_to_patient)) || null,
            secondary_insurance: form?.secondary_insurance || null,
            secondary_insurance_id_number: form?.secondary_insurance_id_number || null,
            secondary_insurance_name: form?.secondary_insurance_name || null,
            office_id: form?.office?.id || null,
            treating_physician_id: form?.treating_physician?.id || null,
            accident_type: form?.accident_type || null,
            attorney_email: form?.attorney_email || null,
            attorney_first_name: form?.attorney_first_name || null,
            attorney_last_name: form?.attorney_last_name || null,
            attorney_phone: form?.attorney_phone || null
          }}
          validationSchema={form?.type === formTypesMap.compensation
            ? WCValidation.concat(validationSchema)
            : validationSchema
          }
          onSubmit={form?.token ? handleUpdateStep : handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form noValidate className={classes.root} onSubmit={handleSubmit}>
              <div className={classes.mainContent}>
                <Box px={2} pb={2}>
                  <Alert severity="error">
                    <Typography>
                      All fields marked with an asterisk are required
                    </Typography>
                  </Alert>
                </Box>

                {type !== formTypesMap.private &&
                  <Grid
                    container
                    spacing={isMobile ? 1 : 3}
                    width="100% !important"
                    m="0 !important"
                  >
                    <InitialFormContent type={type} />
                  </Grid>
                }

                <Box py={2}>
                  <Grid
                    container
                    spacing={isMobile ? 1 : 3}
                    width="100% !important"
                    m="0 !important"
                  >
                    <Body />
                  </Grid>
                </Box>

                <Box py={2}>
                  {type === formTypesMap.private ? (
                    <Grid
                      container
                      spacing={isMobile ? 1 : 3}
                      width="100% !important"
                      m="0 !important"
                    >
                      <InsuranceInfo />
                    </Grid>
                  ) : (
                    <Grid container spacing={isMobile ? 1 : 3}>
                      <InsuranceInfoPI />
                    </Grid>
                  )}

                </Box>

                <Box py={2}>
                  <Grid
                    container
                    spacing={isMobile ? 1 : 3}
                    width="100% !important"
                    m="0 !important"
                  >
                    <EmergencyFormContact />
                  </Grid>
                </Box>

                <Typography className={classes.questionTitle}>
                  What is the reason for your visit today?
                </Typography>

                <TextField
                  fullWidth
                  multiline
                  disableUnderline
                  rows={5}
                  variant="filled"
                  name="description"
                />

                <Measurements />

                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={isMobile ? 12 : 4}>
                    <KeyboardDatePicker
                      clearable
                      name="hearing_date"
                      label="Hearing Date"
                      outputFormat="YYYY-MM-DD"
                      margin="dense"
                    />
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 4}>
                    <KeyboardDatePicker
                      clearable
                      name="vision_date"
                      label="Vision Rx Date"
                      outputFormat="YYYY-MM-DD"
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </div>

              <Divider gutter={2} />
              <ActionButtons buttonTilte="Close" onBack={handleBack} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
