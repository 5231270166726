export const bodyPartsMap = {
  chest: 'Chest',
  breast_l: 'Breast left',
  breast_r: 'Breast right',
  belly: 'Belly',
  genitals: 'Genitals',
  groin: 'Groin',
  waist: 'Waist',
  lower_back_l: 'Lower back left',
  lower_back_r: 'Lower back right',
  lumbar: 'Lumbar',
  buttock: 'Buttock',
  buttock_l: 'Buttock left',
  buttock_r: 'Buttock right',
  back_m: 'Back middle',
  back_l: 'Back left',
  back_r: 'Back right',
  forearm_l: 'Forearm left',
  forearm_r: 'Forearm right',
  shoulder_l: 'Shoulder left',
  shoulder_r: 'Shoulder right',
  armpit_l: 'Armpit left',
  armpit_r: 'Armpit right',
  arm_l: 'Arm left',
  arm_r: 'Arm right',
  elbow_l: 'Elbow left',
  elbow_r: 'Elbow right',
  hand_l: 'Hand left',
  hand_r: 'Hand right',
  wrist_l: 'Wrist left',
  wrist_r: 'Wrist right',
  finger_l: 'Finger left',
  finger_r: 'Finger right',
  palm_l: 'Palm left',
  palm_r: 'Palm right',
  neck: 'Neck',
  head: 'Head',
  nape: 'Nape',
  face: 'Face',
  ear_l: 'Ear left',
  ear_r: 'Ear right',
  nose: 'Nose',
  chin: 'Chin',
  forehead: 'Forehead',
  temple_l: 'Temple left',
  temple_r: 'Temple right',
  cheek_l: 'Cheek left',
  cheek_r: 'Cheek right',
  mouth: 'Mouth',
  eye_l: 'Eye left',
  eye_r: 'Eye right',
  eyelid_l: 'Eyelid left',
  eyelid_r: 'Eyelid right',
  jaw: 'Jaw',
  lip: 'Lip',
  tooth: 'Tooth',
  ball: 'Ball',
  sole_l: 'Sole left',
  sole_r: 'Sole right',
  instep_l: 'Instep left',
  instep_r: 'Instep right',
  toe_l: 'Toe left',
  toe_r: 'Toe right',
  foot_l: 'Foot left',
  foot_r: 'Foot right',
  ankle_l: 'Ankle left',
  ankle_r: 'Ankle right',
  leg_l: 'Leg left',
  leg_r: 'Leg right',
  hip_l: 'Hip left',
  hip_r: 'Hip right',
  shin_l: 'Shin left',
  shin_r: 'Shin right',
  knee_l: 'Knee left',
  knee_r: 'Knee right',
  cal_l: 'Calf left',
  cal_r: 'Calf right',
  heel_l: 'Heel left',
  heel_r: 'Heel right',
  groin_l: 'Groin left',
  groin_r: 'Groin right',
  clavicle_l: 'Clavicle left',
  clavicle_r: 'Clavicle right',
  waist_l: 'Waist left',
  waist_r: 'Waist right'
};
