import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { largeScreenWidth } from '../../WorkSessions';
import { columnsMap, columnsWidths } from '../listConfig';

export const TableHeader = ({ width, hiddenColumns = [] }) => {
  return (
    <Paper elevation={0}>
      <ListRow header px={2}>
        {!hiddenColumns.includes(columnsMap.user) &&
          <ListRowCell pl={width > largeScreenWidth ? 6: 0} grow flexBasis={columnsWidths.user}>
            User
          </ListRowCell>
        }

        <ListRowCell
          grow={width > largeScreenWidth || hiddenColumns.includes(columnsMap.user)}
          flexBasis={columnsWidths.start}
        >
          Start
        </ListRowCell>

        <ListRowCell grow={width > largeScreenWidth} flexBasis={columnsWidths.end}>
          End
        </ListRowCell>

        <ListRowCell grow={width > largeScreenWidth} flexBasis={columnsWidths.duration}>
          Duration
        </ListRowCell>

        <ListRowCell grow={width > largeScreenWidth} flexBasis={columnsWidths.breaks}>
          Breaks
        </ListRowCell>

        <ListRowCell grow={width > largeScreenWidth} flexBasis={columnsWidths.lunches}>
          Lunches
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
