import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, useTheme, useMediaQuery } from '@material-ui/core';
import * as eventsApi from '../../../api/schedule-events';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../Modal';
import { Loader } from '../../Loader';
import { UsersSelect } from '../../users';
import { validationSchema } from './validationSchema';

export const ChangeEventOrganizerModal = ({
  DialogProps,
  payload: {
    eventId,
    initialValues
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileSm));

  const changeEventOrganiser = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.parent_type = values?.parent_type?.value || values?.parent_type || null;
    values.parent_id = values?.parent_id?.value || values?.parent_id || null;

    return eventsApi.changeEventOrganiser(eventId, values).then((event) => {
      enqueueSnackbar(`Event "${event.title}" successfully updated`, { variant: 'success' });
      handleModalResolve(event);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={changeEventOrganiser}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog disableEnforceFocus fullScreen={isMobile} {...DialogProps}>
          <ModalContainer component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Change organizer
            </ModalHeader>

            <ModalBody>
              <UsersSelect
                required
                label="Select organizer"
                name="owner_id"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
