import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, Grid, InputAdornment, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import * as userApi from '../../../../../api/users';
import { Loader } from '../../../../../components/Loader';
import {
  ApptBooksSelect,
  ApptTypesSelect,
  InsurancesSelect,
  NumberMaskField,
  TextField
} from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';
import { CalendarInfo } from './CalendarInfo';
import { PatientInfo } from './PatientInfo';

export const FormBody = ({ appointmentDate }) => {
  const { values, setFieldValue } = useFormikContext();
  const [ isLoading, setIsLoading ] = useState(false);

  const handleChangePatientInfo = (user) => {
    setIsLoading(true);

    if (user?.id) {
      userApi.fetchUser(user?.id).then((data) => {
        setFieldValue('patient', data);
        setIsLoading(false);
      });
    } else {
      setFieldValue('patient', null);
      setIsLoading(false);
    }
  };

  const handleChangePhysician = () => {
    setFieldValue('appointment_book_id', null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UsersSelect
          required
          name="patient_id"
          label="Select patient"
          params={{ is_patient: 1 }}
          onChange={handleChangePatientInfo}
        />
      </Grid>

      <Grid item xs={12}>
        <Box color="info.main" pt={2}>
          <Typography color="inherit" variant="h4">
            Patient appointment details
          </Typography>
        </Box>
      </Grid>

      {isLoading ? <Loader p={2} /> : <PatientInfo />}

      <Grid item xs={12}>
        <Box color="info.main" pt={2}>
          <Typography color="inherit" variant="h4">
            Details
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <UsersSelect
          disabled={!values?.patient_id}
          name="treating_physician_id"
          label="Physician"
          params={{ role: 'doctor' }}
          onChange={handleChangePhysician}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ApptBooksSelect
          allBooks
          required
          disabled={!values?.patient_id || !values?.treating_physician_id}
          name="appointment_book_id"
          params={{ providers: values?.treating_physician_id && [ values?.treating_physician_id ] }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ApptTypesSelect
          required
          disabled={!values?.patient_id}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <NumberMaskField
          zeroMinWidth
          name="_hours"
          label="Hours"
          placeholder="Enter hours..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">h</InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <NumberMaskField
          required={!values?._hours}
          zeroMinWidth
          name="_minutes"
          label="Minutes"
          placeholder="Enter minutes..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">min</InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <InsurancesSelect
          isCreatable
          disabled={!values?.patient_id}
          name="patient_insurance_id"
          label="Insurance"
          user={values.patient}
          params={{
            only_active: 1,
            medical_id: values?.patient?.medical?.id || null
          }}
        />
      </Grid>

      {appointmentDate && !!values.timezone &&
        <>
          <Grid item xs={2}>
            <Typography color="textSecondary" component={Box} pt={2}>timezone:</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography noWrap color="textPrimary" variant="h5" component={Box} pt={2}>
              {values.timezone}
            </Typography>
          </Grid>
        </>
      }

      {values?.appointment_book_id &&
        <CalendarInfo appointmentDate={appointmentDate} />
      }

      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={4}
          rowsMax={8}
          variant="filled"
          margin="dense"
          name="note"
          label={
            <Box display="flex" alignItems="center" fontWeight="fontWeightMedium">
              <NotesIcon color="primary" fontSize="small"/>

              <Typography color="primary">Add Note</Typography>
            </Box>
          }
        />
      </Grid>
    </Grid>
  );
};
