import { Paper, List as MuiList, Typography, useMediaQuery } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../ListRow';
import { columnsWidths } from './Body';

export const Row = ({
  totalHours = null,
  child = false,
  day = {},
  week = {}
}) => {
  const total = child && totalHours ? totalHours : (child ? '' : '0.00');
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Paper elevation={0}>
      <ListRow px={2}>
        <ListRowCell
          grow
          column={isTablet}
          flexBasis={columnsWidths.name}
          justifyContent="space-between"
        >
          {!child && (
            <>
              <Typography variant="h5">{day.day_name}</Typography>
              <Typography>{day.date}</Typography>
            </>
          )}
        </ListRowCell>

        <ListRowCell column={isTablet} flexBasis={columnsWidths.type}>
          {child && day.type}
        </ListRowCell>

        <ListRowCell column={isTablet} grow={isTablet && !child} flexBasis={columnsWidths.inOut}>
          {!child ? (
            <Typography>{!!day?.tracks?.length ? '' : '-'}</Typography>
          ) : (
            <>
              <Typography>{day.in}</Typography>

              {!isTablet && (
                <>
                  &nbsp;&nbsp;
                  <Typography>-</Typography>
                  &nbsp;&nbsp;
                </>
              )}

              <Typography>{day.out}</Typography>
            </>
          )}
        </ListRowCell>

        <ListRowCell grow={isTablet} flexBasis={columnsWidths.payCode}>
          {!!day?.tracks?.length || child ? '' : '-'}
        </ListRowCell>

        <ListRowCell grow={isTablet} flexBasis={columnsWidths.hours}>
          {child ? (
            <Typography>{day.hours || '0.00'}</Typography>
          ) : !day?.tracks?.length && (
            <Typography>0.00</Typography>
          )}
        </ListRowCell>

        <ListRowCell grow={isTablet} flexBasis={columnsWidths.total}>
          {!!day?.tracks?.length ? '' : total}
        </ListRowCell>
      </ListRow>

      {!!day?.tracks?.length && (
        <MuiList disablePadding>
          {day?.tracks?.map((track, index) => (
            <Row
              child
              totalHours={index === day?.tracks.length - 1 ? (day.total_hours).toFixed(2) : null}
              key={track.day_name + index}
              day={track}
            />
          ))}
        </MuiList>
      )}
    </Paper>
  );
};
