import { useContext, useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { UserLink } from '../../../../../../../components/UserLink';
import { Loader } from '../../../../../../../components/Loader';
import { apiModelsMap, itemsRoutesByModels } from '../../../../../../../dataMaps/apiModels';
import { CodesMenu } from '../../../CodesMenu';
import { AppointmentBooksContext } from '../../AppointmentBooksContext';
import { columnsWidthsLg, columnsWidthsSm } from '../List';

export const Row = ({ item: appointmentBook = {}, isLoaded, recalculateHeight }) => {
  const appointmentBooksContext = useContext(AppointmentBooksContext);
  const [ isBusy, setIsBusy ] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;
  const match = useRouteMatch();

  const toggleActiveAppointmentBook = () => {
    const data = {
      ...appointmentBook,
      is_active: !appointmentBook.is_active,
      assign_users: appointmentBook?.assign_users?.map((item) => item?.id)
    };

    setIsBusy(true);

    appointmentBooksContext.toggleActiveAppointmentBook(data).then(() => {
      setIsBusy(false);
    });
  };

  const deleteAppointmentBook = () => {
    setIsBusy(true);

    appointmentBooksContext.deleteAppointmentBook(appointmentBook).then(() => {
      setIsBusy(false);
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return (
    <Loader p={isBusy ? 0 : 2} surface={isBusy} fullWidth loading={!isLoaded || isBusy} render={
      () => (
        <ListRow disabled={!appointmentBook.is_active}>
          <ListRowCell grow noWrap actions flexBasis={columnsWidths.name}>
            {appointmentBook.appointment_book_name}
          </ListRowCell>

          <ListRowCell noWrap flexBasis={columnsWidths.provider}>
            {!appointmentBook.provider ? '-' : (
              <UserLink
                size="sm"
                user={appointmentBook.provider}
                path={itemsRoutesByModels[apiModelsMap.user](appointmentBook.provider.id)}
              />
            )}
          </ListRowCell>


          <ListRowCell actions noDisable justifyContent="flex-end" flexBasis={columnsWidths.actions}>
            <CodesMenu
              isActive={appointmentBook.is_active}
              url={`${match.url}/${appointmentBook.id}/edit`}
              onToggle={toggleActiveAppointmentBook}
              onDelete={deleteAppointmentBook}
            />
          </ListRowCell>
        </ListRow>
      )}
    />
  );
};
