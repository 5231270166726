import {
  makeStyles,
  Box,
  Link,
  SvgIcon
} from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { mdiForumOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { useChatActions } from '../../../../../../../utils/useChatActions';
import { Loader } from '../../../../../../../components/Loader';
import { CardHeader, CardItem } from '../../../../../../../components/Cards';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../../components/users';
import { Divider } from '../../../../../../../components/Divider';
import { ContactMenu } from '../../ContactMenu';
import { Body } from './Body';
import { Header } from './Header';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ item: contact = {} }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { isChatOpening, openChatWithUser } = useChatActions();
  // const { selectedIDs, toggleItemSelection } = useContext(ContactsContext);
  // const isSelected = selectedIDs.includes(contact.id);
  const { contact: user } = contact;

  // const handleItemSelection = () => {
  //   toggleItemSelection(contact.id);
  // };

  const openPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: user.id }
    });
  };

  const startChatWithContact = () => {
    openChatWithUser(user?.id);
  };

  return (
    <CardItem onClick={openPreview}>
      <CardHeader
        disableCheckBox
        // isSelected={isSelected}
        px={2}
        py={1}
        alignItems="center"
        menuComponent={ContactMenu}
        menuComponentProps={{ contact }}
      />

      <Header contact={contact} />
      <Body contact={contact} />
      <Divider disableBottomGutter gutter={2} />

      <Box display="flex" alignItems="center">
        <Box width="50%" textAlign="center" py={2}>
          <Loader surface loading={isChatOpening} size={24} render={
            () => (
              <Link
                component="button"
                underline="none"
                color="textPrimary"
                className={classes.actionLink}
                onClick={stopPropagation(startChatWithContact)}
              >
                <SvgIcon className={classes.actionIcon}>
                  <Icon path={mdiForumOutline} />
                </SvgIcon>

                Message
              </Link>
            )}
          />
        </Box>

        <VerticalDivider />

        <Box width="50%" textAlign="center" py={2}>
          <Link
            underline="none"
            color="textPrimary"
            onClick={stopPropagation(openPreview)}
            className={classes.actionLink}
          >
            <EyeIcon className={classes.actionIcon} />

            View
          </Link>
        </Box>
      </Box>
    </CardItem>
  );
};
