import { Grid } from '@material-ui/core';
import { Autocomplete, KeyboardDatePicker, TextField } from '../../../FormField';
import { typeOfAccidentOptions } from '../../../medical/accidentTypes';
import { autoAccidentStatesOptions, qualifiersOptions } from './data';

export const MainFields = () => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          clearable
          name="onset_date"
          label="Onset Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          clearable
          name="first_consult_date"
          label="First Consult Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          clearable
          name="assumed_care_date"
          label="Assumed Care Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          clearable
          name="hearing_date"
          label="Hearing Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          clearable
          name="vision_rx_date"
          label="Vision Rx Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          clearable
          name="lmp_date"
          label="LMP Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          clearable
          name="last_seen_date"
          label="Last Seen Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          clearable
          name="relinquished_care_date"
          label="Relinquished Care Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          clearable
          name="accident_date"
          label="Accident Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <Autocomplete
          name="accident_type"
          label="Accident Type"
          options={typeOfAccidentOptions}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <Autocomplete
          name="auto_accident_state"
          label="Accident State"
          options={autoAccidentStatesOptions}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <TextField
          name="auto_accident_country"
          label="Accident Country"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <Autocomplete
          name="qualifier"
          label="Qualifier"
          options={qualifiersOptions}
        />
      </Grid>
    </Grid>
  );
};
