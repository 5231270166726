import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as appointmentTypesApi from '../../../../../api/codes/appointments/appointment-types';
import * as prescriptionsApi from '../../../../../api/profile';
import { AppointmentForm, apptInitialValues, ApptInfo } from '../../../../../components/appointments';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { validationSchema as prescriptionValidationSchema } from '../validationSchema';
import { initialValues as prescriptionsInitialValues } from './initialValues';
import { SinglePrescription } from './SinglePrescription';
import { validationSchema } from './validationSchema';
import { Prescription } from './Prescription';

export const CreatePrescriptionModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    appointmentTypeID,
    apptValues = null,
    initialValues = {},
    insurance = {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ appointmentType, setAppointmentType ] = useState(null);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const defaultInitialValues = apptValues ? {
    ...prescriptionsInitialValues,

    patient_id: initialValues?.patient_id,
    appointment_id: { id: apptValues?.appointment_id, ...apptValues },
    approved_id: apptValues?.approved_id,
    office_id: apptValues?.office,
    prescribed_at: apptValues.appointment_at
  } : {
    ...apptInitialValues,
    ...initialValues,

    patient_insurance_pcp: insurance?.pcp,
    patient_insurance_spc: insurance?.spc,
    insurance_details: {
      company: insurance?.insurance_company
    },
    appointment_type_id: appointmentType,
    appointment_type: appointmentType,
    time: appointmentType?.time || 0,
    prescriptions: [ prescriptionsInitialValues ]
  };

  const massCreatePrescription = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return prescriptionsApi.massCreateMedications(values).then((data) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Prescription not created', { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  const createPrescription = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return prescriptionsApi.createMedication(values).then(({ data }) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  useEffect(() => {
    appointmentTypeID && appointmentTypesApi.fetchAppointmentType(appointmentTypeID).then(setAppointmentType);
  }, []);

  return (
    <Dialog fullScreen={isTablet} PaperProps={{ style: { maxWidth: 1100 } }} {...DialogProps}>
      <Loader p={3} loading={!appointmentType} render={
        () => (
          <Formik
            enableReinitialize
            initialValues={defaultInitialValues}
            validationSchema={!apptValues ? validationSchema : prescriptionValidationSchema}
            onSubmit={!apptValues ? massCreatePrescription : createPrescription}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader
                  icon={<SvgIcon><Icon path={mdiCalendarPlus} /></SvgIcon>}
                  onClose={handleModalReject}
                >
                  Add New Prescription
                </ModalHeader>

                <ModalBody>
                  {apptValues ?
                    <>
                      <ApptInfo />
                      <SinglePrescription />
                    </>
                    :
                    <>
                      <AppointmentForm appointmentType={appointmentType} />
                      <Prescription />
                    </>
                  }
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
