import { Switch, Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { filtersKeysMap } from '../../../store/lastFilters';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { Route } from '../../../components/router';
import { Settings } from '../MedicalReports';
import { MainContent } from './MainContent';
import { MedicalInfo } from './MedicalInfo';
import { EditUser } from './EditUser';

export const PatientsPage = () => {
  return (
    <LayoutContextProvider>
      <Box width="100%" height="100%" bgcolor="grey.100">
        <Switch>
          <Route exact path="/patients" render={
            () => (
              <MainContent filterKey={filtersKeysMap.patients_main} />
            )}
          />
          <Redirect exact from="/patients/:patientID" to="/patients/:patientID/medical-info/medical-forms" />
          <Route path="/patients/:patientID/settings" component={Settings} />
          <Route path="/patients/:patientID/medical-info" component={MedicalInfo} />
          <Route path="/patients/:patientID/edit" component={EditUser} />
        </Switch>
      </Box>
    </LayoutContextProvider>
  );
};
