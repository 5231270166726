export const styles = ({ spacing, palette, zIndex, transitions }) => ({
  root: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: ({ width }) => width - 60,
    zIndex: zIndex.appBar,
    transition: transitions.create(),
    borderRight: `1px solid ${palette.divider}`
  },

  collapsed: {
    marginLeft: ({ width }) => -width + 60
  },

  header: {
    padding: spacing(1, 2),
    borderBottom: `1px solid ${palette.divider}`
  }
});
