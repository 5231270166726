export const billingActivityMap = {
  createInvoice: 'CREATED@CASE:INVOICE',
  updateInvoice: 'UPDATED@CASE:INVOICE',
  deleteInvoice: 'DELETED@CASE:INVOICE',
  createIncome: 'CREATED@INVOICE:INCOME',
  updateIncome: 'UPDATED@INVOICE:INCOME',
  deleteIncome: 'DELETED@INVOICE:INCOME',
  createExpense: 'CREATED@INVOICE:EXPENSE',
  updateExpense: 'UPDATED@INVOICE:EXPENSE',
  deleteExpense: 'DELETED@INVOICE:EXPENSE',
  createClaimInvoice: 'CREATED@CLAIM:INVOICE',
  updateClaimInvoice: 'UPDATED@CLAIM:INVOICE',
  deleteClaimInvoice: 'DELETED@CLAIM:INVOICE',
  createAppointmentInvoice: 'CREATED@APPOINTMENT:INVOICE',
  updateAppointmentInvoice: 'UPDATED@APPOINTMENT:INVOICE',
  deleteAppointmentInvoice: 'DELETED@APPOINTMENT:INVOICE',
  createInvoicePayment: 'CREATED@INVOICE:PAYMENT',
  updateInvoicePayment: 'UPDATED@INVOICE:PAYMENT',
  deleteInvoicePayment: 'DELETED@INVOICE:PAYMENT',
  expense: 'expense'
};

const activityTypesMap = {
  claim: 'claim',
  case: 'case',
  appointment: 'appointment'
};

export const billingActivityLabelsMap = {
  [activityTypesMap.claim]: {
    label: 'Claim Invoice'
  },
  [activityTypesMap.appointment]: {
    label: 'Appointment Invoice'
  },
  [activityTypesMap.case]: {
    label: 'Case Invoice'
  }
};
