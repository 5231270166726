import { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Box, Button, Dialog, Grid, List, Typography } from '@material-ui/core';
import * as npiRegistryApi from '../../api/npi-registry';
import { api } from '../../api';
import { ModalBody, ModalContainer, ModalHeader } from '../Modal';
import { Select, TextField } from '../FormField';
import { Loader } from '../Loader';
import { addressPurposeOptions } from './addressPurposeOptions';
import { npiEnumerationTypes } from './npiEnumerationTypes';
import { ListHeader } from './ListHeader';
import { Row } from './Row';

export const SearchNPIRecordsModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    initialValues = {}
  }
}) => {
  const [ npiRecords, setNpiRecords ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const cancelFetch = useRef();

  const getData = (values) => {
    setIsLoading(true);

    return npiRegistryApi.fetchNpiRecords({
      params: values,
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then((data) => {
      setNpiRecords(data.results);
    }).catch((error) => {
      setError(error);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (initialValues) {
      getData(initialValues);
    }
  }, []);

  return (
    <Dialog PaperProps={{ style: { maxWidth: 1000 } }} {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={null}
      >
        {({ handleSubmit, values }) => (
          <ModalContainer
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader onClose={handleModalReject}>
              Search NPI Records
            </ModalHeader>

            <ModalBody>
              <Box display="flex" flexDirection="column">
                <Grid container component={Box} spacing={3} alignItems="flex-end">
                  {(values.enumeration_type === npiEnumerationTypes.user) &&
                    <>
                      <Grid item xs={4}>
                        <TextField
                          name="first_name"
                          label="First Name"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          name="last_name"
                          label="Last Name"
                        />
                      </Grid>
                    </>
                  }

                  <Grid item xs={4}>
                    <TextField
                      name="number"
                      label="Number"
                    />
                  </Grid>

                  {(values.enumeration_type === npiEnumerationTypes.office) &&
                    <Grid item xs={4}>
                      <TextField
                        name="taxonomy_description"
                        label="Taxonomy description"
                      />
                    </Grid>
                  }

                  {(values.enumeration_type === npiEnumerationTypes.office) &&
                    <Grid item xs={4}>
                      <TextField
                        name="organization_name"
                        label="Organization Name"
                      />
                    </Grid>
                  }

                  <Grid item xs={4}>
                    <Select
                      formattedValue
                      name="address_purpose"
                      label="Address purpose"
                      options={addressPurposeOptions}
                      defaultValue={addressPurposeOptions[0]}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      name="city"
                      label="City"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextField
                      name="state"
                      label="State"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextField
                      name="country_code"
                      label="Country code"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      name="postal_code"
                      label="Postal code"
                    />
                  </Grid>
                </Grid>

                <Box py={1} mt={2} display="flex" justifyContent="center">
                  <Loader surface loading={isLoading} render={() => (
                    <Button
                      variant="contained"
                      disabled={isLoading}
                      color="primary"
                      onClick={() => getData(values)}
                    >
                      Get List
                    </Button>
                  )} />
                </Box>

                {error &&
                  <Box color="error.main">
                    <Typography variant="body1" color="inherit">
                      {error.message}
                    </Typography>
                  </Box>
                }

                {!npiRecords?.length ? (
                  <Box py={2}>
                    <Typography align="center">
                      NPI records not found, try changing your search options
                    </Typography>
                  </Box>
                ) : (
                  <List>
                    <ListHeader />

                    {npiRecords.map((npiRecord) => (
                      <Row
                        key={npiRecord.number}
                        npiRecord={npiRecord}
                        onNpiRecordSelected={handleModalResolve}
                      />
                    ))}
                  </List>
                )}
              </Box>
            </ModalBody>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
