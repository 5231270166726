import { useSelector } from 'react-redux';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { filtersKeysMap } from '../../../store/lastFilters';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { WorkSessionsProvider } from '../../../components/work-sessions/WorkSessionsProvider';
import { WorkSessions as WorkSessionsCommon } from '../../../components/work-sessions/WorkSesstions';
import { columnsMap } from '../../../components/work-sessions/WorkSesstions/List/listConfig';
import { filterFieldsMap } from '../../../components/work-sessions/WorkSesstions/FiltersBar/filterFieldsMap';
import { Page, PageBody } from '../../../components/Page';
import { Header } from './Header';

export const WorkSessions = () => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('md');
  const initialFilter = {
    companies: hasRole(rolesMap.admin) ? null : [ currentUser.work?.company_id ],
    users: hasRole(rolesMap.admin, rolesMap.supervisor) ? null : [ currentUser.id ]
  };
  const hiddenFilterFields = hasRole(rolesMap.admin)
    ? []
    : hasRole(rolesMap.supervisor)
      ? [ filterFieldsMap.companies ]
      : [ filterFieldsMap.companies, filterFieldsMap.users ];
  const hiddenColumns = hasRole(rolesMap.admin, rolesMap.supervisor) ? [] : [ columnsMap.companies, columnsMap.user ];

  return (
    <WorkSessionsProvider filter={initialFilter}>
      <LayoutContextProvider>
        <Page>
          <Header filterOpenButton={filterOpenButton} />

          <PageBody disablePaddings disableScrollbars fullHeight>
            <WorkSessionsCommon
              filterKey={filtersKeysMap.work_sessions}
              hiddenColumns={hiddenColumns}
              hiddenFilterFields={hiddenFilterFields}
              FilterBarWrapper={FilterBarWrapper}
              filterBarWrapperProps={filterBarWrapperProps}
              ScrollWrapper={ScrollWrapper}
              scrollWrapperProps={scrollWrapperProps}
            />
          </PageBody>
        </Page>
      </LayoutContextProvider>
    </WorkSessionsProvider>
  );
};
