import { useEffect, useState } from 'react';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as companyApi from '../../../../../api/companies';
import { Loader } from '../../../../../components/Loader';
import { ModalBody, ModalContainer } from '../../../../../components/Modal';
import { Header } from './Header';
import { Body } from './Body';

export const PreviewCompanyModal = ({
  DialogProps,
  handleModalReject,
  payload: { companyID }
}) => {
  const [ company, setCompany ] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    companyApi.fetchCompany(companyID).then((company) => {
      setCompany(company);
    });
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <ModalContainer>
        <Loader p={3} loading={!company} render={
          () => (
            <ModalBody disablePaddings>
              <Header onClose={handleModalReject} company={company} />
              <Body company={company} />
            </ModalBody>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
