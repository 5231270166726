import { useState } from 'react';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import * as usersApi from '../../../../api/users';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { useFormikField } from '../../useFormikField';
import { Select } from '../Select';
import { SingleValue } from './SingleValue';
import { MultiValue } from './MultiValue';
import { Option } from './Option';

const components = {
  SingleValue,
  MultiValue,
  Option
};

export const transformEmailToOption = (user) => user && ({
  value: user?.email || user,
  label: user
});

export const transformAccountToOption = (account) => account && ({
  value: account.id,
  label: account.email,
  data: account
});

const fetchUsers = (params) => fetchDataForAsyncSelect((search, { page }) => {
  return usersApi.fetchUsers({ params: { search, page, ...params } });
}, {
  transformDataToOption: transformEmailToOption
});

const emailSchema = yup.string().email().required();

export const CreatableEmailsSelect = ({
  isCreatable,
  isMulti = true,
  multiple = true,
  formattedValue = true,
  name,
  params = {},
  creatablePayload = {},
  onChange = () => {},

  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ inputValue, setInputValue ] = useState('');
  const { fieldProps: [ , , { setValue } ] } = useFormikField(name);

  const handleChange = (newValues) => {
    const filteredValues = isMulti
      ? newValues?.filter((item) => !!emailSchema.isValidSync(item.value))
      : !!emailSchema.isValidSync(newValues?.value) && formattedValue ? newValues?.value : newValues;

    setValue(filteredValues || null);
    onChange(filteredValues || null);
  };

  const handleInputChange = (value, reason) => {
    if (reason.action === 'input-change') {
      setInputValue(value);
    } else if (reason.action === 'set-value') {
      value && !emailSchema.isValidSync(inputValue) &&
        enqueueSnackbar(`Invalid email '${inputValue}'`, { variant: 'warning' });
    } else if (reason.action === 'menu-close') {
      setInputValue('');
    }
  };

  return (
    <Select
      isAsync
      formattedValue={formattedValue}
      isCreatable={isCreatable}
      isMulti={isMulti}
      name={name}
      inputValue={inputValue}
      promiseWithOptions={fetchUsers(params)}
      components={components}
      onChange={handleChange}
      onInputChange={handleInputChange}

      {...props}
    />
  );
};
