export const styles = ({ spacing, transitions }) => ({
  sortIcon: {
    transition: transitions.create()
  },

  sortIcon_asc: {
    transform: 'scaleY(-1)'
  },

  sortIcon_desc: {
    transform: 'scaleY(1)'
  },

  verticalDivider: {
    alignSelf: 'stretch',
    width: 1,
    height: 'auto'
  }
});
