import { useFormikContext } from 'formik';
import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import {
  CodesModifierSelect,
  TextField,
  CompaniesAutocomplete,
  Checkbox,
  CurrencyField
} from '../../../../../../../../components/FormField';

export const Body = () => {
  const { values, setFieldValue } = useFormikContext();

  const toggleAllPayers = (event) => {
    if (event.target.checked) {
      setFieldValue('payer_id', null);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <CompaniesAutocomplete
          required={!values?.is_all_payers}
          disabled={values?.is_all_payers}
          name="payer_id"
          label={values?.is_all_payers ? 'All payers selected' : 'Payer'}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <Box pt={1.5}>
          <FormControlLabel
            label={<Typography>All payers</Typography>}
            control={
              <Checkbox
                name="is_all_payers"
                color="primary"
                onChange={toggleAllPayers}
              />
            }
          />
        </Box>
      </Grid>

      <Grid item xs={6}>
        <CurrencyField
          name="charge_amount"
          label="Charge amount"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField required type="number" name="default_units" label="Default units" />
      </Grid>

      <Grid item xs={6}>
        <CurrencyField
          name="expected_amount"
          label="Expected amount"
        />
      </Grid>

      <Grid item xs={6}>
        <CodesModifierSelect
          formattedValue
          name="modifier_id"
          TextFieldProps={{ required: true, margin: 'dense' }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField required name="work_rvu" label="Work RVU" />
      </Grid>

      <Grid item xs={6}>
        <TextField required name="moldx_code" label="MolDX Code" />
      </Grid>

      <Grid item xs={6}>
        <TextField required name="dx_code" label="DX Code" />
      </Grid>

      <Grid item xs={6}>
        <TextField required name="total_rvu" label="Total RVU" />
      </Grid>
    </Grid>
  );
};
