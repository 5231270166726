import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { AuthorizationsSelect } from '../../../appointments';
import { RadioGroup, Radio, Autocomplete, TextField } from '../../../FormField';
import { Tooltip } from '../../../Tooltip';
import { referenceCodesOptions } from './data';

export const Indicators = ({ claim }) => {
  const patientID = claim.appointment?.patient_id;

  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">
          Indicators
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography>Emergency Indicator</Typography>

          <RadioGroup row name="emergency_indicator">
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={<Radio name="emergency_indicator" color="primary" />}
            />

            <FormControlLabel
              value="no"
              label={<Typography>No</Typography>}
              control={<Radio name="emergency_indicator" color="primary" />}
            />

            <FormControlLabel
              value="n/a"
              label={<Typography>N/A</Typography>}
              control={<Radio name="emergency_indicator" color="primary" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6}>
          <Typography>EPSDT</Typography>

          <RadioGroup row name="epsdt">
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={<Radio name="epsdt" color="primary" />}
            />

            <FormControlLabel
              value="n/a"
              label={<Typography>N/A</Typography>}
              control={<Radio name="epsdt" color="primary" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6}>
          <Typography>Family Planning</Typography>

          <RadioGroup row name="family_planning">
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={<Radio name="family_planning" color="primary" />}
            />

            <FormControlLabel
              value="n/a"
              label={<Typography>N/A</Typography>}
              control={<Radio name="family_planning" color="primary" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6}>
          <Typography>Copay Exempt</Typography>

          <RadioGroup row name="copay_exempt">
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={<Radio name="copay_exempt" color="primary" />}
            />

            <FormControlLabel
              value="n/a"
              label={<Typography>N/A</Typography>}
              control={<Radio name="copay_exempt" color="primary" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6}>
          <Autocomplete
            name="reference_code"
            label="Line Note Reference Code"
            placeholder="Select line note reference code..."
            options={referenceCodesOptions}
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            name="line_note"
            label="Line Note"
          />
        </Grid>

        <Grid item md={6}>
          <Tooltip isExistTooltip={!claim.appointment?.patient_id} title="Select patient firstly">
            <div>
              <AuthorizationsSelect
                patientID={patientID}
                appointmentID={claim.appointment?.id}
                disabled={!claim.appointment?.patient_id}
                name="authorization_id"
                label="Authorization #"
                params={{
                  users: patientID && [ patientID ]
                }}
              />
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};
