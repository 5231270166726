import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { omit } from 'lodash';
import { saveFilter } from '../../../../../store/lastFilters';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import {
  Select,
  OfficesLocationSelect,
  IcdCodesSelect,
  KeyboardDatePicker
} from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';
import { IconComponent } from '../../../../../components/saved-filters';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { reportTypesOptions, ReportsContext } from '../../../../../components/medical/reports';
import { filterLabels, fieldsKeysMap } from '../../../ProfilePage/MedicalInfo/MedicalForms/FormFiltersBar';
import { CardContent } from './CardContent';
import { List } from './List';

const MODAL_WIDTH = 1070;

export const FiltersBar = ({ filterKey, hiddenFilterFields = [] }) => {
  const formikRef = useRef();
  const { applyFilter } = useContext(ReportsContext);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});
  const isDoctor = pathname === '/diagnosis' && hasRole(rolesMap.doctor);
  const currentUser = useSelector(({ profile }) => profile.user);

  const handleKeyboardPickerChange = (name) => (value) => {
    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  };

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.value });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data || option }));
  };

  const handleIcdCodeChange = (code) => {
    applyFilter({ icd_id: code?.id });
    setRelationsForFilter((state) => ({ ...state, icd_id: code }));
  };

  const handleOfficeChange = (office) => {
    applyFilter({ office_id: office?.id });
    setRelationsForFilter((state) => ({ ...state, office_id: office }));
  };

  const handleUserSelect = (name) => (option) => {
    applyFilter({ [name]: option?.id });
    setRelationsForFilter((state) => ({ ...state, [name]: option }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(omit(filter, hiddenFilterFields));

    applyFilter(omit({
      ...filter,

      icd_id: filter?.icd_id?.id,
      patient_id: filter?.patient_id?.id,
      provider_id: filter?.provider_id?.id,
      office_id: filter?.office_id?.id
    }, hiddenFilterFields));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter({
        ...relationsForFilter,
        ...lastFilters,

        provider_id: isDoctor && !lastFilters?.provider_id?.id ? currentUser : lastFilters?.provider_id
      });
    } else {
      isDoctor && formikRef.current.setValues({ provider_id: currentUser });
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      border={0}
      enableSettings
      formikRef={formikRef}
      initialValues={relationsForFilter}
      hiddenFields={hiddenFilterFields}
      filterKey={filterKey}
      onReset={() => applySavedFilter(relationsForFilter)}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.patients,
          label: filterLabels[fieldsKeysMap.patients],
          field: (
            <UsersSelect
              name="patient_id"
              label="Patient"
              params={{ is_patient: 1 }}
              onChange={handleUserSelect('patient_id')}
              minWidth={210}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.dorFrom,
          label: filterLabels[fieldsKeysMap.dorFrom],
          field: (
            <KeyboardDatePicker
              disableFuture
              clearable
              name="dor_from"
              label="DOR From"
              outputFormat="MM/DD/YYYY"
              onChange={handleKeyboardPickerChange('dor_from')}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.dorTo,
          label: filterLabels[fieldsKeysMap.dorTo],
          field: (
            <KeyboardDatePicker
              disableFuture
              clearable
              name="dor_to"
              label="DOR To"
              outputFormat="MM/DD/YYYY"
              onChange={handleKeyboardPickerChange('dor_to')}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.type,
          label: filterLabels[fieldsKeysMap.type],
          field: (
            <Select
              isClearable
              name="type"
              label={filterLabels[fieldsKeysMap.type]}
              options={reportTypesOptions}
              onChange={handleSelectChange('type')}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.icdId,
          label: filterLabels[fieldsKeysMap.icdId],
          field: (
            <IcdCodesSelect
              name="icd_id"
              label={filterLabels[fieldsKeysMap.icdId]}
              onChange={handleIcdCodeChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.providerId,
          label: filterLabels[fieldsKeysMap.providerId],
          field: (
            <UsersSelect
              name="provider_id"
              label={filterLabels[fieldsKeysMap.providerId]}
              params={{ role: rolesMap.doctor }}
              onChange={handleUserSelect('provider_id')}
              minWidth={210}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.officeId,
          label: filterLabels[fieldsKeysMap.officeId],
          field: (
            <OfficesLocationSelect
              name="office_id"
              onChange={handleOfficeChange}
            />
          )
        }
      ]}
    />
  );
};
