import T from 'prop-types';
import { Box, Typography, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AppBar } from '../AppBar';
import { Tooltip } from '../Tooltip';
import { styles } from './styles';

const propTypes = {
  icon: T.oneOfType([ T.node, T.string ]),
  onClose: T.func
};

const useStyles = makeStyles(styles);

export const ModalHeader = ({
  disableTypography = false,
  icon,
  children,
  onClose,
  ...props
}) => {
  const classes = useStyles(props);
  const Wrapper = !disableTypography ? Typography : Box;
  const WrapperProps = !disableTypography ? {
    variant: 'h6',
    className: classes.title
  } : {
    flexGrow: 1,
    mr: 1
  };

  return (
    <AppBar className={classes.root}>
      <Wrapper {...WrapperProps}>
        {icon &&
          <div className={classes.icon}>
            {icon}
          </div>
        }

        {children}
      </Wrapper>

      {!!onClose &&
        <Tooltip title="Close modal">
          <IconButton
            className={classes.closeIcon}
            edge="end"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      }
    </AppBar>
  );
};

ModalHeader.propTypes = propTypes;
