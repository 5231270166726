import { createContext, useReducer, useEffect, useRef } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../api';
import * as emailsRulesApi from '../../../../../api/emails-rules';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { usePrevious } from '../../../../../helpers/hooks';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const RulesEmailsContext = createContext();

export const RulesEmailsContextProvider = ({ filter: filterProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const { filter, pagination, isRulesFetched, rules } = state;
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchRules = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_RULES_REQUEST });

    emailsRulesApi.getEmailRules({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_RULES_SUCCESS, payload: data });
    });
  };

  const resetRules = (newFilter) => {
    dispatch({ type: types.RESET_RULES, payload: newFilter });

    fetchRules({ page: 1, ...newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchRules({ page: filter.page + 1 });
    }
  };

  const updateRulesPositions = ({ id, oldIndex, newIndex }) => {
    const { page, per_page, ...commonFilter } = filter;

    dispatch({ type: types.UPDATE_RULES_POSTIONS, payload: { oldIndex, newIndex } });

    emailsRulesApi.updateEmailRulesPositions(id, { index: newIndex, ...commonFilter }).catch(() => {
      fetchRules();
      enqueueSnackbar('Rule not moved', { variant: 'error' });
    });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.SET_FILTER, payload: newFilter });
  };

  const toggleActive = (rule) => {
    dispatch({ type: types.TOGGLE_ACTIVE, payload: rule });

    emailsRulesApi.editEmailRule(rule.id, {
      is_active: !rule.is_active
    });
  };

  const createRule = (rule) => {
    dispatch({ type: types.ADD_RULES, payload: rule });
  };

  const editRule = (rule) => {
    dispatch({ type: types.EDIT_RULE, payload: rule });
  };

  const deleteRule = (ruleID) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        emailsRulesApi.deleteEmailRule(ruleID).then(() => {
          dispatch({ type: types.DELETE_RULE, payload: ruleID });
          enqueueSnackbar('Rule deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Rule not deleted', { variant: 'error' });
        });
      }
    });
  };

  const contextValue = {
    ...state,
    filter,
    pagination,
    isRulesFetched,
    rules,
    meta: {
      ...pagination,
      ...filter
    },

    // functions
    fetchRules,
    resetRules,
    applyFilter,
    toggleActive,
    updateRulesPositions,
    createRule,
    editRule,
    loadNextPage,
    deleteRule
  };

  useEffect(() => {
    const newFilter = { ...filterProp, ...filter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, filterProp ]);


  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetRules();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <RulesEmailsContext.Provider value={contextValue}>
      {children}
    </RulesEmailsContext.Provider>
  );
};
