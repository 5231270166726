import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Box,
  FormControlLabel,
  Grid,
  Hidden,
  InputAdornment,
  Typography
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Checkbox, TextField } from '../../../../../../components/FormField';

export const Allergies = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!values?.forms?.allergies?.allergic_to) {
      setFieldValue('forms.allergies.note', null);
    }
  }, [ values?.forms?.allergies?.allergic_to ]);

  return (
    <Grid container spacing={0} component={Box} px={2}>
      <Grid item xs={12}>
        <FormControlLabel
          label="NKDA"
          control={
            <Checkbox
              color="primary"
              name="forms.allergies.nkda'"
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          label="Allergic to:"
          control={
            <Checkbox
              color="primary"
              name="forms.allergies.allergic_to"
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Box mt={2} mb={3}>
          <TextField
            fullWidth
            disabled={!values?.forms?.allergies?.allergic_to}
            name="forms.allergies.note"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="subtitle2">
                    <Box component="span" mr={0.5}>
                      <NotesIcon fontSize="inherit" />
                    </Box>
                    Add Note
                  </Typography>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Denies history of:</Typography>
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Sedation"
          control={
            <Checkbox
              color="primary"
              name="forms.allergies.denies_history_of.sedation"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Nauseal Vomitting"
          control={
            <Checkbox
              color="primary"
              name="forms.allergies.denies_history_of.nauseal_vomitting"
            />
          }
        />
      </Grid>

      <Hidden only="xs">
        <Grid item xs={4} />
      </Hidden>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Constipation"
          control={
            <Checkbox
              color="primary"
              name="forms.allergies.denies_history_of.constipation"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Pruritis"
          control={
            <Checkbox
              color="primary"
              name="forms.allergies.denies_history_of.pruritis"
            />
          }
        />
      </Grid>
    </Grid>
  );
};
