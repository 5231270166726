import moment from 'moment';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { Label } from '../../../../../../../components/Label';
import { Members } from '../../../../../../../components/Members';

export const CardContent = ({ params }) => {
  const theme = useTheme();
  const owner = params?.users?.find(({ case_role }) => case_role === 'creator');

  return (
    <Grid container spacing={1}>
      {params?.file_number &&
        <>
          <Grid item xs={4}>
            <Typography noWrap variant="h5">
              Case name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>{params?.file_number}</Typography>
          </Grid>
        </>
      }

      {params?.name &&
        <>
          <Grid item xs={4}>
            <Typography noWrap variant="h5">
              Case number:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>{params?.name}</Typography>
          </Grid>
        </>
      }

      {params?.created_from &&
        <>
          <Grid item xs={4}>
            <Typography noWrap variant="h5">
              Created From:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            {moment(params?.created_from).format('L')}
          </Grid>
        </>
      }

      {params?.created_to &&
        <>
          <Grid item xs={4}>
            <Typography noWrap variant="h5">
              Created To:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            {moment(params?.created_to).format('L')}
          </Grid>
        </>
      }

      {!!params.statuses?.length &&
        <>
          <Grid item xs={4}>
            <Typography noWrap variant="h5">
              Case status:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.statuses?.map((status) => (
                <Box key={status?.id} mb={0.25}>
                  <Label
                    label={status?.name}
                    color={theme.palette.primary.main}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params.case_roles?.length &&
        <>
          <Grid item xs={4}>
            <Typography noWrap variant="h5">
              User roles:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.case_roles?.map((role) => (
                <Typography key={role?.label}>{role?.label}</Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params.users?.length &&
        <>
          <Grid item container alignItems="center" xs={4}>
            <Typography noWrap variant="h5">
              Case members:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Members
              noWrap
              isParent
              maxVisible={6}
              owner={owner}
              users={params?.users}
            />
          </Grid>
        </>
      }

      {!!params.types?.length &&
        <>
          <Grid item xs={4}>
            <Typography noWrap variant="h5">
              Case types:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.types?.map((type) => (
                <Typography noWrap key={type?.name}>{type?.name}</Typography>
              ))}
            </Box>
          </Grid>
        </>
      }
    </Grid>
  );
};
