import { combineReducers } from 'redux';
import { reducer as theme } from './theme';
import { reducer as snackbars } from './snackbars';
import { reducer as globalActions } from './globalActions';
import { reducer as socketJobs } from './socketJobs';
import { reducer as apiConfig } from './api-config';
import { reducer as auth } from './auth';
import { reducer as globalUser } from './globalUser';
import { reducer as lastFilters } from './lastFilters';
import { reducer as appointmentsScoreboard } from './appointmentsScoreboard';
import * as dashboardReducers from './dashboard/reducers';

export const rootReducer = combineReducers({
  theme,
  snackbars,
  globalActions,
  socketJobs,
  apiConfig,
  auth,
  globalUser,
  lastFilters,
  appointmentsScoreboard,

  ...dashboardReducers
});
