import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { loginSocket } from '../utils/echo';
import { persistedReducer } from './persistedReducer';

export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(thunk)
  )
);

let lastAuthToken;

store.subscribe(() => {
  const authToken = store.getState().auth.authToken;

  if (lastAuthToken !== authToken) {
    lastAuthToken = authToken;

    loginSocket(authToken);
  }
});
