import { InputAdornment } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import { stopPropagation } from '../../../../helpers/dom';
import { IconButton } from '../../../IconButton';
import { VerticalDivider } from '../../../VerticalDivider';
import { Autocomplete } from '../../Autocomplete';
import { orderDirectionsMap } from '../OrderBy';

export const OrderByAutocomplete = ({
  params = {},
  orderDirection,
  onOrderDirectionChange = () => {},
  ...props
}) => {
  const handleOrderDirectionChange = () => {
    const direction = (orderDirection === orderDirectionsMap.asc) ? (
      orderDirectionsMap.desc
    ) : (
      orderDirectionsMap.asc
    );

    onOrderDirectionChange(direction);
  };

  return (
    <Autocomplete
      disableClearable
      label="Order by"
      placeholder="Sort by..."
      getOptionLabel={(option) => option?.label}
      getOptionValue={(option) => option?.value}
      getOptionSelected={(option, selected) => option.value === selected.value}

      getInputProps={() => ({
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={stopPropagation(handleOrderDirectionChange)}>
              <SortIcon
                fontSize="small"
                style={{
                  transform: orderDirection === orderDirectionsMap.asc ? 'scaleY(-1)' : 'scaleY(1)',
                  transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                }}
              />
            </IconButton>

            <VerticalDivider />
          </InputAdornment>
        )
      })}

      {...props}
    />
  );
};
