import * as types from './types';
import { createHookReducer } from '../../../../../helpers/createHookReducer';

export const reducer = createHookReducer({
  [types.FETCH_FILES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.FETCH_FILES_SUCCESS]: ({ filter, files, ...state }, { data: newFiles, pagination }) => {
    const { page, per_page } = pagination;

    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      files: (page > 1 ?
        files.filter(({ id }) => !newFiles.find((loadedFile) => id === loadedFile.id)).concat(newFiles)
        :
        newFiles
      )
    };
  },

  [types.RESET_FILES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 },
      selectedFilesID: []
    };
  }
});
