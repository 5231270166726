import { useEffect, useContext, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { Sticky } from '../../../../../components/Sticky';
import { TemplatesContext } from '../../../FilesPage/TemplatesPage/TemplatesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidths = {
  name: 270,
  type: 100,
  date: 160,
  isSystem: 84
};

export const List = ({ selectedTemplate, setSelectedTemplate }) => {
  const {
    templates,
    isFetched,
    isFetching,
    loadNextPage,
    fetchTemplates,
    filter
  } = useContext(TemplatesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No templates found</Typography>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader/>
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={templates}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ selectedTemplate, setSelectedTemplate }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
