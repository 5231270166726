import { api } from '../api';

export const fetchCases = (config = {}) => {
  return api.get('/cases', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchCaseUsers = (config = {}) => {
  return api.get('/cases/users', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const assignUsersToCase = (userData) => {
  return api.post('/case-users', userData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteUserFromCase = (id) => {
  return api.delete(`/case-users/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createCase = (caseData) => {
  return api.post('/cases', caseData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const fetchCase = (id) => {
  return api.get(`/cases/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateCase = (caseItem) => {
  return api.put(`/cases/${caseItem.id}`, caseItem)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const updateCaseChecklist = (caseID, data) => {
  return api.patch(`/cases/${caseID}/checklist`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateCasePlaintiff = (caseID, data) => {
  return api.patch(`/cases/${caseID}/plaintiff`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateCaseCourtInformation = (caseID, data) => {
  return api.patch(`/cases/${caseID}/court`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteCase = (id) => {
  return api.delete(`/cases/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const toggleOpen = ({ id, is_opened }) => {
  return api[is_opened ? 'delete' : 'post'](`/cases/${id}/is_opened`)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const toggleFavorite = ({ id, is_favorite }) => {
  return api[is_favorite ? 'post' : 'delete'](`/cases/${id}/is_favorite`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchCodesBodyParts = (config = {}) => {
  return api.get('/procedure-codes/body-part', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const getUsersAdjNumbers = (id) => {
  return api.get(`/cases/adj/${id}`);
};

export const attachCase = (id, data) => {
  return api.post(`/cases/${id}/attach`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const detachCase = (id, parent_id) => {
  return api.delete(`/cases/${id}/attach`, { data: { parent_id } })
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createLog = (logData) => {
  return api.post('/cases/reopens', logData)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateLog = (logData) => {
  return api.put(`/cases/reopens/${logData.id}`, logData)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const massReplaceCaseUser = (data) => {
  return api.post('/cases-users/mass-change', data)
    .then(({ data }) => data)
    .catch((error) => { throw  error; });
};
