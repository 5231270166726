import { useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import QrReader from 'react-qr-reader';
import { makeStyles } from '@material-ui/core';
import * as appointmentsApi from '../../../api/appointments';
import { AppointmentsContext } from '../index';
import { Loader } from '../../Loader';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Scanner = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [ isLoading, setIsLoading ] = useState(false);
  const { reloadAppointments } = useContext(AppointmentsContext);

  const handleScan = (token) => {
    if (token) {
      setIsLoading(true);

      appointmentsApi.checkIn(token).then(() => {
        reloadAppointments();
        enqueueSnackbar('Appointment successfully check in', { variant: 'success' });
      }).catch(() => {
        enqueueSnackbar('Appointment not check in', { variant: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const handleError = (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
  };

  return (
    <div className={classes.scanner}>
      <Loader surface fullHeight loading={isLoading} render={
        () => (
          <div className={classes.scanner__container}>
            {/* Image 1x1px for adjust scanner screen to square, based on container height */}
            <img
              src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              height="100%"
              alt=""
            />

            <QrReader
              delay={1000}
              onScan={handleScan}
              onError={handleError}
              className={classes.scanner__screen}
            />
          </div>
        )}
      />
    </div>
  );
};
