import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteGridLoader } from '../../../../InfiniteLoader';
import { Loader } from '../../../../Loader';
import { ScrollbarsCustom } from '../../../../ScrollbarsCustom';
import { CaseRemindersContext } from '../CaseRemindersProvider';
import { ReminderCard } from './ReminderCard';

export const CaseRemindersGrid = ({ autoHeight, hiddenColumns, Cell = ReminderCard, CellProps = {}, ...props }) => {
  const {
    isFetched,
    isFetching,
    reminders,
    meta,
    loadNextPage,
    resetReminders
  } = useContext(CaseRemindersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetReminders();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} autoHeight={autoHeight}>
      <Box p={2}>
        <Loader loading={!isFetched} render={
          () => !meta.total ? (
            <Typography align="center">No reminders found</Typography>
          ) : (
            <MuiList disablePadding>
              <InfiniteGridLoader
                autoSize
                cellWidth={380}
                items={reminders}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                CellComponent={Cell}
                CellProps={{ hiddenColumns, ...CellProps }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
