export const relationshipMap = [
  {
    value: 'Father',
    label: 'Father'
  },
  {
    value: 'Mother',
    label: 'Mother'
  },
  {
    value: 'Brother',
    label: 'Brother'
  },
  {
    value: 'Sister',
    label: 'Sister'
  },
  {
    value: 'Spouse',
    label: 'Spouse'
  },
  {
    value: 'Daughter',
    label: 'Daughter'
  },
  {
    value: 'Guarantor',
    label: 'Guarantor'
  },
  {
    value: 'Self',
    label: 'Self'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];
