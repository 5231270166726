import cn from'classnames';
import { makeStyles, Slider as MuiSlider } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Slider = ({
  disableHeight = false,
  value = 0,
  width = 8,
  height = 8,
  colorIndex = 0,
  step = 1,
  min = 0,
  max = 11,
  levelColors = [],
  classesProps = {},
  marks = [],
  onChange = () => {},
  ...props
}) => {
  const classes = useStyles({ colorIndex, width, height, levelColors });

  return (
    <MuiSlider
      value={value}
      step={step}
      min={min}
      max={max}
      marks={marks}
      classes={{
        root: cn(
          classes.sliderRoot,
          { [classes.height]: !disableHeight },
          { [classes.width]: disableHeight }
        ),
        track: cn(
          classes.track,
          { [classes.height]: !disableHeight },
          { [classes.width]: disableHeight }
        ),
        rail: cn(
          classes.rail,
          { [classes.height]: !disableHeight },
          { [classes.width]: disableHeight }
        ),
        mark: cn(
          { [classes.height]: !disableHeight },
          { [classes.width]: disableHeight }
        ),
        markLabel: classes.markLabel,
        thumb: classes.thumb,
        ...classesProps
      }}
      onChange={onChange}
      {...props}
    />
  );
};
