export const dueAtCaseDatesOptions = [
  {
    value: 'created_at',
    label: 'Created date'
  },
  {
    value: 'started_at',
    label: 'Started date'
  },
  {
    value: 'due_dated_at',
    label: 'Due date'
  },
  {
    value: 'updated_at',
    label: 'Updated date'
  },
  {
    value: 'commenced_date',
    label: 'Commenced date'
  },
  {
    value: 'opened_at',
    label: 'Opened date'
  },
  {
    value: 'closed_at',
    label: 'Closed date'
  },
  {
    value: 'physical_file_scanned_at',
    label: 'Physical file scanned date'
  },
  {
    value: 'subbed_out_at',
    label: 'Subbed out date'
  },
  {
    value: 'date_of_injury',
    label: 'Date Of Injury'
  },
  {
    value: 'dob',
    label: 'Adjuster DOB'
  },
  {
    value: 'date_file_received',
    label: 'File received date'
  },
  {
    value: 'deleted_at',
    label: 'Deleted date'
  }
];
