import { useFormikContext } from 'formik';
import {
  Box,
  FormControlLabel,
  Radio,
  Typography
} from '@material-ui/core';
import { RadioGroup, TextField } from '../../../../FormField';
import { getTextColor } from '../../../getTextColor';
import { accidentTypesTitles } from '../../../accidentTypes';

export const AccidentBody = ({ form }) => {
  const { values, errors } = useFormikContext();

  return (
    <Box flexGrow={1}>
      <Typography variant="h5" color="secondary">
        Accident information - {form?.accident_type}
      </Typography>

      {form?.accident_type === accidentTypesTitles.car_accident && (
        <Box mb={5}>
          <Box mb={3} mt={1}>
            <Typography
              variant="h5"
              color={getTextColor(errors?.forms?.accident_information?.you_driver_passenger)}
            >
              1. Were you the driver, passenger, or in the back seat? *
            </Typography>

            <Box py={1}>
              <RadioGroup
                row
                name="forms.accident_information.you_driver_passenger"
              >
                <FormControlLabel
                  value="Driver"
                  label={<Typography>Driver</Typography>}
                  control={<Radio color="primary" />}
                />

                <FormControlLabel
                  value="Passenger"
                  label={<Typography>Passenger</Typography>}
                  control={<Radio color="primary" />}
                />
              </RadioGroup>
            </Box>

            <TextField
              fullWidth
              multiline
              disableUnderline
              required={values.forms.accident_information.you_driver_passenger === 'Passenger'}
              name="forms.accident_information.you_driver_description"
              label="Describe"
              placeholder="Please describe..."
              margin="dense"
              variant="filled"
              rows={5}
            />
          </Box>

          <Box mb={3} mt={1}>
            <Typography
              variant="h5"
              color={getTextColor(errors?.forms?.accident_information?.car_police_report_filed)}
            >
              2. Was a police report filed? *
            </Typography>

            <Box pl={2}>
              <RadioGroup row name="forms.accident_information.car_police_report_filed">
                <FormControlLabel
                  value="1"
                  label={<Typography>Yes</Typography>}
                  control={<Radio size="small" color="primary" />}
                />

                <FormControlLabel
                  value="0"
                  label={<Typography>No</Typography>}
                  control={<Radio size="small" color="primary"/>}
                />
              </RadioGroup>
            </Box>
          </Box>

          <Box mb={3} mt={1}>
            <Typography
              variant="h5"
              color={getTextColor(errors?.forms?.accident_information?.car_where_accident_occur)}
            >
              3. Where did the accident occur? *
            </Typography>

            <TextField
              fullWidth
              multiline
              disableUnderline
              name="forms.accident_information.car_where_accident_occur"
              placeholder="Please describe"
              margin="dense"
              variant="filled"
              rows={5}
            />
          </Box>

          <Box mb={3} mt={1}>
            <Typography
              variant="h5"
              color={getTextColor(errors?.forms?.accident_information?.car_how_accident_occurred)}
            >
              4. Please briefly explain how the accident occurred. *
            </Typography>

            <TextField
              fullWidth
              multiline
              disableUnderline
              name="forms.accident_information.car_how_accident_occurred"
              placeholder="Please describe"
              margin="dense"
              variant="filled"
              rows={5}
            />
          </Box>
        </Box>
      )}

      {form?.accident_type === accidentTypesTitles.slip_and_fall && (
        <Box mb={5}>
          <Box mb={3} mt={1}>
            <Typography
              variant="h5"
              color={getTextColor(errors?.forms?.accident_information?.slip_where_accident_occur)}
            >
              1. Where did the accident occur? *
            </Typography>

            <TextField
              fullWidth
              multiline
              disableUnderline
              name="forms.accident_information.slip_where_accident_occur"
              placeholder="Please describe"
              margin="dense"
              variant="filled"
              rows={5}
            />
          </Box>

          <Box mb={3} mt={1}>
            <Typography
              variant="h5"
              color={getTextColor(errors?.forms?.accident_information?.slip_police_report_filed)}
            >
              2. Was a police report filed? *
            </Typography>

            <Box pl={2}>
              <RadioGroup row name="forms.accident_information.slip_police_report_filed">
                <FormControlLabel
                  value="1"
                  label={<Typography>Yes</Typography>}
                  control={<Radio size="small" color="primary" />}
                />

                <FormControlLabel
                  value="0"
                  label={<Typography>No</Typography>}
                  control={<Radio size="small" color="primary"/>}
                />
              </RadioGroup>
            </Box>
          </Box>

          <Box mb={3} mt={1}>
            <Typography
              variant="h5"
              color={getTextColor(errors?.forms?.accident_information?.slip_how_accident_occurred)}
            >
              3. Please briefly explain how the accident occurred. *
            </Typography>

            <TextField
              fullWidth
              multiline
              disableUnderline
              name="forms.accident_information.slip_how_accident_occurred"
              placeholder="Please describe"
              margin="dense"
              variant="filled"
              rows={5}
            />
          </Box>
        </Box>
      )}

      {form?.accident_type === accidentTypesTitles.other && (
        <Box mb={5}>
          <Box mb={3} mt={2}>
            <Typography
              variant="h5"
              color={getTextColor(errors?.forms?.accident_information?.explain)}
            >
              Please briefly explain. *
            </Typography>

            <TextField
              fullWidth
              multiline
              disableUnderline
              name="forms.accident_information.explain"
              placeholder="Please describe"
              margin="dense"
              variant="filled"
              rows={5}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
