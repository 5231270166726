import { useMediaQuery, useTheme } from '@material-ui/core';
import { useContext } from 'react';
import { ScheduleContext, ScheduleEventRow, ScheduleEventsList } from '../../../../../schedule-events';
import { ModalFooter } from '../../../../../Modal';
import { Button } from '../../../../../Button';
import { Grid } from './Grid';

export const ScheduleEventsModalContent = ({
  isMulti = false,
  hiddenColumns,
  ListRowProps,
  handleModalResolve,
  handleModalReject
}) => {
  const { selectedEventsIDs, getSelectedEvents } = useContext(ScheduleContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const applyEvents = () => {
    handleModalResolve(getSelectedEvents());
  };

  return (
    <>
      {isTablet ? (
        <Grid autoHeight RowProps={{ ListRowProps, isMulti }} />
      ) : (
        <ScheduleEventsList
          height={500}
          hiddenColumns={hiddenColumns}
          Row={ScheduleEventRow}
          RowProps={{ ListRowProps, isMulti }}
        />
      )}

      <ModalFooter>
        <Button onClick={handleModalReject}>Cancel</Button>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={!selectedEventsIDs.length > 0}
          onClick={applyEvents}
        >
          Apply
        </Button>
      </ModalFooter>
    </>
  );
};
