import _ from 'lodash';
import { useContext } from 'react';
import { MenuItem, ListItemIcon, ListItemText, List } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { EditNegotiationModal } from '../EditNegotiationModal';
import { NegotiationsContext } from '../NegotiationsProvider';
import { PreviewNegotiationModal } from '../PreviewNegotiationModal';

export const MenuList = ({ negotiation = {}, onClose = () => {} }) => {
  const { caseItem, onCaseItemUpdate, updateNegotiation, deleteNegotiation } = useContext(NegotiationsContext);
  const { openModal } = useModal();

  const onUpdate = () => {
    openModal(EditNegotiationModal, {
      payload: {
        caseItem,
        negotiation,
        onCaseItemUpdate
      },
      onModalResolved: (negotiation) => {
        updateNegotiation(negotiation);
        onClose();
      }
    });
  };

  const onDelete = () => {
    deleteNegotiation(caseItem?.id, negotiation);
  };

  const openPreview = () => {
    openModal(PreviewNegotiationModal, {
      payload: {
        caseItem,
        initialValues: negotiation,
        deleteNegotiation,
        updateNegotiation,
        onCaseItemUpdate
      },
      onModalReject: () => onClose()
    });
  };

  return (
    <List>
      <MenuItem onClick={openPreview}>
        <ListItemIcon>
          <EyeIcon />
        </ListItemIcon>

        <ListItemText primary="View negotiation" />
      </MenuItem>

      <MenuItem onClick={onUpdate}>
        <ListItemIcon>
          <EditIcon color="primary" />
        </ListItemIcon>

        <ListItemText primary="Edit negotiation" />
      </MenuItem>

      <MenuItem onClick={_.flow(onDelete, onClose)}>
        <ListItemIcon>
          <DeleteIcon color="error" />
        </ListItemIcon>

        <ListItemText primary="Delete negotiation" />
      </MenuItem>
    </List>
  );
};
