import { createHookReducer } from '../../../../../helpers/createHookReducer';
import { moveArrayItem } from '../../../../../helpers/moveArrayItem';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_REMINDER_GROUPS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_REMINDER_GROUPS_SUCCESS]: (
    { reminderGroups, ...state },
    newReminderGroups
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      reminderGroups: newReminderGroups
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_REMINDER_GROUPS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.ADD_REMINDER_GROUP]: (state, reminderGroup) => {
    const { reminderGroups, ...otherState } = state;

    return {
      ...otherState,

      reminderGroups: [ reminderGroup, ...reminderGroups ]
    };
  },

  [types.UPDATE_REMINDER_GROUP]: ({ reminderGroups, ...state }, updatedReminderGroup) => {
    return {
      ...state,

      reminderGroups: reminderGroups.map((reminderGroup) => {
        return updatedReminderGroup.id === reminderGroup.id ? updatedReminderGroup : reminderGroup;
      })
    };
  },

  [types.UPDATE_REMINDER_GROUPS_POSITIONS]: ({ reminderGroups, ...state }, positions) => {
    const { oldIndex, newIndex } = positions;

    return {
      ...state,

      reminderGroups: moveArrayItem(reminderGroups, oldIndex, newIndex)
    };
  },

  [types.DELETE_REMINDER_GROUP]: (state, reminderGroupID) => {
    const { reminderGroups } = state;

    return {
      ...state,

      reminderGroups: reminderGroups.filter(({ group_id }) => reminderGroupID !== group_id)
    };
  }
});
