import { useState } from 'react';
import cn from 'classnames';
import { makeStyles, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { CaseReminderEventsProvider } from '../../../../components/cases/reminders/events';
import { LayoutContextProvider } from '../../../../components/LayoutContext';
import { ReminderEvents } from './ReminderEvents';
import { LeftSidebar } from './LeftSidebar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CaseRemindersSettings = () => {
  const [ isOpenSidebar, setIsOpenSideBar ] = useState(true);
  const classes = useStyles({ isOpenSidebar });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const toggleSideBar = () => {
    setIsOpenSideBar((state) => !state);
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <CaseReminderEventsProvider>
        {!isMobile && (
          <div className={cn(classes.sidebar, { [classes.sidebarOpen]: isOpenSidebar })}>
            <LeftSidebar
              isOpenSidebar={isOpenSidebar}
              toggleSideBar={toggleSideBar}
            />
          </div>
        )}

        <div className={classes.main}>
          <LayoutContextProvider>
            <ReminderEvents
              isOpenSidebar={isOpenSidebar}
              toggleSideBar={toggleSideBar}
            />
          </LayoutContextProvider>
        </div>
      </CaseReminderEventsProvider>
    </Paper>
  );
};
