import { Icon } from '@mdi/react';
import { mdiAccountConvert } from '@mdi/js';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog, SvgIcon } from '@material-ui/core';
import * as casesApi from '../../../../../api/cases';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { Select } from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { UsersSelect } from '../../../../../components/users';
import { caseUserRoles, caseUserRolesAvailableToReplace } from '../../../../../dataMaps';

export const ReplaceCaseUserModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    fromUser,
    selectedCases
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const userRole = caseUserRoles.find((role) => role.value === fromUser?.case_role);
  const isAvailableToChange = !!caseUserRolesAvailableToReplace.find((role) => role.value === userRole?.value);

  const replaceCaseUser = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.cases?.map((caseItem) => caseItem.id);

    return casesApi.massReplaceCaseUser(values).then(() => {
      enqueueSnackbar('Users successfully replaced', { variant: 'success' });
      handleModalResolve(values);
    }).catch(({ status, data: { errors } = {} }) => {
      enqueueSnackbar('Something goes wrong!', { variant: 'error' });

      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Dialog maxWidth="xs" {...DialogProps}>
      <Formik
        initialValues={{
          from_user_id: fromUser?.user,
          to_user_id: '',
          cases: selectedCases || null,
          roles: isAvailableToChange ? [ userRole ] : []
        }}
        onSubmit={replaceCaseUser}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader
              icon={<SvgIcon color="inherit" fontSize="inherit"><Icon path={mdiAccountConvert } /></SvgIcon>}
              onClose={handleModalReject}
            >
              Replace Case User
            </ModalHeader>

            <ModalBody>
              <Box py={1}>
                <UsersSelect
                  required
                  name="from_user_id"
                  label="User to be replaced"
                  placeholder="Select users..."
                  margin="dense"
                />
              </Box>

              <Box py={1}>
                <UsersSelect
                  required
                  name="to_user_id"
                  label="Replace with"
                  placeholder="Select users..."
                  margin="dense"
                />
              </Box>

              <Box py={1}>
                <CasesSelect
                  multiple
                  label="In Cases"
                  name="cases"
                  margin="dense"
                />
              </Box>

              <Box py={1}>
                <Select
                  isMulti
                  formattedValue
                  isClearable
                  name="roles"
                  label="Select Roles"
                  options={caseUserRolesAvailableToReplace}
                  TextFieldProps={{ required: true }}
                  margin="dense"
                />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Apply
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
