import { Box, Grid, Typography } from '@material-ui/core';
import { caseInsuranceTypes } from '../../../../../../../../dataMaps';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { OfficeLink } from '../../../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Contact } from '../../../../../../../../components/Contact';
import { Divider } from '../../../../../../../../components/Divider';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { Date } from '../../../../../../../../components/Date';

export const widthBreakpointSmall = 540;

export const InsuranceContent = ({ claimInfo }) => {
  return (
    <Grid container spacing={2} component={Box} pb={4}>
      <Grid item xs={4}>
        <Typography color="textSecondary">Adjuster name</Typography>
      </Grid>

      <Grid item xs={8}>
        <UserLink
          size="sm"
          variant="h5"
          user={claimInfo?.client}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Main Phone Number:</Typography>
      </Grid>

      <Grid item xs={8}>
        <Contact
          needIcon
          variant="h5"
          type="tel"
          contact={claimInfo?.client?.phone}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Mailing Address</Typography>
      </Grid>

      <Grid item xs={8}>
        {claimInfo?.client?.full_address ? (
          <Typography variant="h5">
            {claimInfo?.client?.work_full_address || claimInfo?.client?.full_address}
          </Typography>
        ) : (
          <Typography color="textSecondary">No info.</Typography>
        )}
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Office Location</Typography>
      </Grid>

      <Grid item xs={8}>
        <OfficeLink
          variant="h5"
          office={claimInfo?.office}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Billing Address</Typography>
      </Grid>

      <Grid item xs={8}>
        {claimInfo?.office?.billing_full_address
          ? <Typography variant="h5">{claimInfo?.office?.billing_full_address}</Typography>
          : <Typography color="textSecondary">No info.</Typography>
        }
      </Grid>

      <Grid item xs={12} />

      <Grid item xs={4}>
        <Typography color="textSecondary">Claims Examiner Name</Typography>
      </Grid>

      <Grid item xs={8}>
        <CompanyLink
          size="sm"
          variant="h5"
          company={claimInfo?.examiner}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Direct Telephone Number</Typography>
      </Grid>

      <Grid item xs={8}>
        <Contact
          needIcon
          variant="h5"
          type="tel"
          contact={claimInfo?.examiner?.phone}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Fax/e-fax Number</Typography>
      </Grid>

      <Grid item xs={8}>
        <Contact
          needIcon
          variant="h5"
          type="tel"
          contact={claimInfo?.examiner?.fax}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Email</Typography>
      </Grid>

      <Grid item xs={8}>
        <Contact
          needIcon
          variant="h5"
          type="mailto"
          contact={claimInfo?.examiner?.email}
        />
      </Grid>

      {claimInfo?.claim_insurance_type !== caseInsuranceTypes.other ? (
        <>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={3}>
              <Typography variant="h4">Claim Insurance Information</Typography>

              <Box flexGrow={1} ml={4}>
                <Divider type="dashed" />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Typography noWrap variant="h5" color="textSecondary">Claim Insurance Type</Typography>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              isExistTooltip={!!claimInfo?.claim_insurance_type}
              title={claimInfo?.claim_insurance_type}
            >
              <Typography noWrap variant="h5" color="secondary">
                {claimInfo?.claim_insurance_type || ''}
              </Typography>
            </Tooltip>
          </Grid>

          {claimInfo?.claim_insurance_type !== caseInsuranceTypes.fully_insured ? (
            <>
              <Grid item xs={3}>
                <Tooltip
                  isExistTooltip
                  title="Self-Insured Liability Amount"
                >
                  <Typography noWrap variant="h5" color="textSecondary">Self-Insured Liability Amount</Typography>
                </Tooltip>
              </Grid>

              <Grid item xs={2}>
                <Typography variant="h5" color="secondary">
                  <CurrencyFormat value={claimInfo?.amount } />
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item xs={5} />
          )}

          <Grid item>
            <Typography color="textSecondary">Name of Re-Ins or Excess Carrier</Typography>
          </Grid>

          <Grid item xs={7}>
            <CompanyLink
              size="sm"
              variant="h5"
              company={claimInfo?.insurance_company}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography color="textSecondary">Address</Typography>
          </Grid>

          <Grid item xs={8}>
            {claimInfo?.insurance_company?.full_address
              ? <Typography variant="h5">{claimInfo?.insurance_company?.full_address}</Typography>
              : <Typography color="textSecondary">No info.</Typography>
            }
          </Grid>

          <Grid item xs={4}>
            <Typography color="textSecondary">Phone Number</Typography>
          </Grid>

          <Grid item xs={8}>
            <Contact
              needIcon
              variant="h5"
              type="tel"
              contact={claimInfo?.insurance_company?.phone}
            />
          </Grid>

          {claimInfo?.claim_insurance_type !== caseInsuranceTypes.self_insured &&
            <>
              <Grid item xs={3}>
                <Typography noWrap color="textSecondary">Policy period start</Typography>
              </Grid>

              <Grid item xs={3}>
                <Date variant="h5" date={claimInfo?.policy_period_start} />
              </Grid>

              <Grid item xs={3}>
                <Typography noWrap color="textSecondary">Policy period finish</Typography>
              </Grid>

              <Grid item xs={3}>
                <Date variant="h5" date={claimInfo?.policy_period_finish} />
              </Grid>
            </>
          }

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={1}>
              <Typography variant="h4">Insurance carrier/TPA Information</Typography>

              <Box flexGrow={1} ml={4}>
                <Divider type="dashed" />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box bgcolor="grey.50" p={2}>
              <Typography>{claimInfo?.insurance_carrier}</Typography>
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={1}>
              <Typography variant="h4">Claim Insurance Information</Typography>

              <Box flexGrow={1} ml={4}>
                <Divider type="dashed" />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Typography noWrap variant="h5" color="textSecondary">Claim Insurance Type</Typography>
          </Grid>

          <Grid item xs={3}>
            <Tooltip
              isExistTooltip={!!claimInfo?.claim_insurance_type}
              title={claimInfo?.claim_insurance_type}
            >
              <Typography noWrap variant="h5" color="secondary">
                {claimInfo?.claim_insurance_type || ''}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid item xs={12} />

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
              <Typography variant="h4">Explain</Typography>

              <Box flexGrow={1} ml={4}>
                <Divider type="dashed" />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box bgcolor="grey.50" p={2}>
              <Typography>{claimInfo?.insurance_carrier}</Typography>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};
