import {
  initialValues as invoicesInitialValues
} from '../../../../../../../BillingPage/Invoices/InvoicesPage';
import {
  initialValues as paymentInitialValues
} from '../../../../../../../BillingPage/Payments/PaymentsPage';
import { getInvoicesFilterFields, getInvoicesFiltersValues } from './invoicesFilters';
import { getPaymentsFilterFields, getPaymentsFiltersValues } from './paymentsFilters';

export const tabsMap = {
  invoices: 'Invoices',
  payment: 'Payments'
};

export const initialFilters = {
  [tabsMap.invoices]: invoicesInitialValues,
  [tabsMap.payment]: paymentInitialValues
};

export const initialMedicalClaimFilters = {
  claim_number: null,
  appointment_from: null,
  appointment_to: null,
  offices: [],
  providers: [],
  statuses: null
};


export const fieldsMap = {
  [tabsMap.invoices]: {
    fields: getInvoicesFilterFields(),
    initialValues: {},
    getFiltersValues: getInvoicesFiltersValues
  },
  [tabsMap.payment]: {
    fields: getPaymentsFilterFields(),
    initialValues: {},
    getFiltersValues: getPaymentsFiltersValues
  }
};
