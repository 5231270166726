import { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { ExpensesContext } from '../../../../Expenses';
import { widthBreakpointMedium, widthBreakpointSmall } from '../../../InvoicesContent/List/listConfig';
import { columnsWidths } from '../Expenses';

export const TableHeader = ({ disableToggle = false, caseID = null, width, isFetched }) => {
  const {
    toggleAllExpensesSelection,
    allExpensesIsSelected,
    selectedExpensesIDs
  } = useContext(ExpensesContext);
  const widthBreakpoint = caseID ? widthBreakpointMedium : widthBreakpointSmall;

  return (
    <ListRow header>
      {!disableToggle && (
        <ListRowCheckbox
          checked={isFetched && allExpensesIsSelected()}
          indeterminate={!!selectedExpensesIDs.length && !allExpensesIsSelected()}
          onClick={toggleAllExpensesSelection}
        />
      )}

      <ListRowCell noWrap grow flexBasis={columnsWidths.parent}>
        Inv.#/Adjuster
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell noWrap flexBasis={columnsWidths.category}>
          Expense category
        </ListRowCell>
      }

      <ListRowCell noWrap grow flexBasis={columnsWidths.description}>
        <Typography noWrap variant="h5" title="Short description">
          Short description
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.attachments}>
        Attachments
      </ListRowCell>

      {width > widthBreakpoint &&
        <ListRowCell grow flexBasis={columnsWidths.date}>
          Date
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.budget}>
        Budgeted
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount}>
        Amount
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        Status
      </ListRowCell>

      {!disableToggle && <ListRowCell flexBasis={columnsWidths.action} />}
    </ListRow>
  );
};
