import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { Box, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiFileAccountOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import {
  MedicalInfo as MedicalInfoComponent
} from '../../../../../../../../../components/users/MedicalInfo';
import {
  WidgetsActionButtons
} from '../../../../../../../../../components/WidgetsActionButtons';
import {
  SelectMedicalFormModal
} from '../../../../../../../../../components/medical/forms';
import { ContentCard } from '../../../../../../../../../components/ContentCard';
import { Fab } from '../../../../../../../../../components/Fab';
import {
  MedicalProfileContext
} from '../../../../../../../ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider';
import {
  MedicalFormsProvider
} from '../../../../../../../ProfilePage/MedicalInfo/MedicalForms/MedicalFormsProvider';
import { UserContext } from '../../../../../UserProvider';

export const PatientPacket = ({
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const history = useHistory();
  const { openModal } = useModal();
  const { user } = useContext(UserContext);
  const { medicalInfoPathname, setInitialType, setInitialFormValue } = useContext(MedicalProfileContext);
  const initialState = {
    filter: {
      medical_id: get(user, 'medical.id')
    },
    medicalInfoPathname
  };

  const handleSelectComponent = () => {
    openModal(SelectMedicalFormModal, {
      payload: {
        patient: user,
        pathname: medicalInfoPathname,
        onTypeUpdate: setInitialType
      },
      onModalResolved: (data) => {
        data.birthday = data?.patient?.birthday || data.birthday || null;
        setInitialType(data.medical_form);
        setInitialFormValue(data);
        history.push(
          `${medicalInfoPathname}/medical-forms/create`, {
            type: data.medical_form,
            form: data
          }
        );
      }
    });
  };

  return (
    <MedicalFormsProvider initialState={initialState}>
      <ContentCard
        fullHeight={!props.autoHeightMax}
        isMovable={!props.autoHeightMax}
        disableContentPaddings
        title="Patient Packet"
        icon={
          <Fab
            size="small"
            color="primary"
            variant={isMobile ? 'round' : 'extended'}
            startIcon={!isMobile && <AddIcon />}
            onClick={handleSelectComponent}
          >
            {isMobile ? <AddIcon /> : 'Medical form'}
          </Fab>
        }
        leftActions={[
          <Box mr={1}>
            <SvgIcon fontSize="small">
              <Icon path={mdiFileAccountOutline} />
            </SvgIcon>
          </Box>
        ]}
        rightActions={[
          <WidgetsActionButtons
            isMinimizable
            page={page}
            type={props?.item?.i}
            isDisabledOpen={isDisabledOpen}
            onMinimize={onMinimize}
            onMaximized={onMaximized}
            onClose={onClose}
            onOpen={onOpen}
            onPutItem={onPutItem}
          />
        ]}
        {...props}
      >
        <MedicalInfoComponent height={height} />
      </ContentCard>
    </MedicalFormsProvider>
  );
};
