import { Box, Chip, Grid } from '@material-ui/core';
import * as arrivalStatusesApi from '../../../../api/codes/appointments/arrival-statuses';
import { arrivalTypes } from '../../../appointments';
import { ColorPreview } from '../../../ColorPreview';
import { Autocomplete } from '../../Autocomplete';

const fetchArrivalStatuses = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return arrivalStatusesApi.fetchArrivalStatuses({
    params: {
      search,
      page: page + 1,
      is_active: 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => (
  <Grid container wrap="nowrap" spacing={1} alignItems="center">
    <Grid item>
      <ColorPreview color={arrivalTypes?.[option?.arrival_type]?.color || arrivalTypes.default.color} />
    </Grid>

    <Grid item>
      {option?.description ? `${option.code} - ${option.description}` : option.code}
    </Grid>
  </Grid>
);

const getOptionValue = (option) => option?.id;

const getOptionSelected = (option, value) => option.id === value?.id;

const getOptionLabel = (option) => {
  return option?.description ? `${option.code} - ${option.description}` : option.code;
};

export const ArrivalStatusesSelect = ({ multiple = false, params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      multiple={multiple}
      label="Arrival status"
      placeholder="Select status..."
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      onNeedFetch={fetchArrivalStatuses(params)}
      getInputProps={(value) => !(!multiple && value) ? null : ({
        startAdornment: (!multiple && value) && (
          <ColorPreview color={arrivalTypes?.[value?.arrival_type]?.color || arrivalTypes.default.color} />
        )
      })}
      renderTags={(options, getTagProps) => (
        options.map((option, index) => (
          <Chip
            variant="outlined"
            size={props.size}
            label={getOptionLabel(option)}
            icon={
              <Box display="flex" alignItems="center" ml={1.5}>
                <ColorPreview color={arrivalTypes?.[option?.arrival_type]?.color || arrivalTypes.default.color} />
              </Box>
            }

            {...getTagProps({ index })}
          />
        ))
      )}

      {...props}
    />
  );
};
