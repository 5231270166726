import { makeStyles, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { formTypesMap } from '../../../../../../../components/medical/forms';
import {
  symptomsInjuryTypesList,
  symptomsMap,
  symptomsTypesMap,
  symptomsTypesList
} from '../../MedicalFormsProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CheckboxesComponent = ({ type, symptoms }) => {
  const classes = useStyles();
  const symptomsList = type === formTypesMap.injury ? symptomsInjuryTypesList : symptomsTypesList;

  return (
    !isEmpty(symptoms) ?
      <div className={classes.root}>
        {symptomsList.map((item, i) => {
          const symptomsKeysList = symptoms[item] ? Object.keys(symptoms[item]) : [];

          return (
            !!symptomsKeysList.length &&
              <div key={i} className={classes.column}>
                <div className={classes.columnTitle}>
                  {symptomsTypesMap[item].icon}

                  <Typography variant="h4" className={classes.title}>
                    {symptomsTypesMap[item].name}
                  </Typography>
                </div>

                <div className={classes.checkboxList}>
                  {symptomsKeysList.map((symptom, i) => (
                    <ReadonlyCheckbox
                      key={i}
                      title={symptomsMap[symptom]}
                      checked={symptoms[item][symptom]}
                      className={classes.checkboxTitle}
                    />
                  ))}
                </div>
              </div>
          );
        })}
      </div>
      :
      <Typography color="textSecondary">No info.</Typography>
  );
};
