export const filterFieldsMap = {
  invoiceNumber: 'invoice_number',
  userID: 'user_id',
  usersId: 'users_id',
  caseId: 'case_id',
  dateFrom: 'from',
  dateTo: 'to',
  categoryId: 'category_id',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.invoiceNumber]: 'Inv Number',
  [filterFieldsMap.usersId]: 'User',
  [filterFieldsMap.caseId]: 'Cases',
  [filterFieldsMap.dateFrom]: 'Date From',
  [filterFieldsMap.dateTo]: 'Date To',
  [filterFieldsMap.categoryId]: 'Expense category',
  [filterFieldsMap.orderBy]: 'Order direction'
};
