export const filterFieldsMap = {
  claimNumber: 'claim_number',
  patients: 'patients',
  appointmentFrom: 'appointment_from',
  appointmentTo: 'appointment_to',
  offices: 'offices',
  providers: 'providers',
  adjusters: 'adjusters',
  statuses: 'statuses',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.claimNumber]: 'Claim Number',
  [filterFieldsMap.patients]: 'Patients',
  [filterFieldsMap.appointmentFrom]: 'DOS From',
  [filterFieldsMap.appointmentTo]: 'DOS To',
  [filterFieldsMap.offices]: 'Offices',
  [filterFieldsMap.providers]: 'Providers',
  [filterFieldsMap.adjusters]: 'Adjusters',
  [filterFieldsMap.statuses]: 'Claim Statuses',
  [filterFieldsMap.orderBy]: 'Order By'
};
