import parse from 'autosuggest-highlight/parse';
import { Box, Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';

export const renderOption = (option) => {
  const matches = option.structured_formatting?.main_text_matched_substrings;
  const parts = parse(
    option.structured_formatting?.main_text,
    matches?.map((match) => [ match.offset, match.offset + match.length ]) || []
  );

  return (
    <Grid container alignItems="center">
      <Grid item>
        <Box mr={2} color="text.secondary">
          <LocationOnIcon color="inherit" />
        </Box>
      </Grid>

      <Grid item xs>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}

        <Typography variant="body2" color="textSecondary">
          {option.structured_formatting?.secondary_text}
        </Typography>
      </Grid>
    </Grid>
  );
};
