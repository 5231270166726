import { useContext, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../../../components/CustomScrollbars';
import { InfiniteGridLoader } from '../../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../../components/Loader';
import { CaseDiscoveriesContext } from '../CaseDiscoveriesProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    isFetched,
    isFetching,
    discoveries,
    meta,
    loadNextPage,
    resetDiscoveries
  } = useContext(CaseDiscoveriesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetDiscoveries();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box py={2} pl={2}>
        <Loader loading={!isFetched} render={
          () => !discoveries.length ? (
            <Box p={3}>
              <Typography align="center">No discoveries found</Typography>
            </Box>
          ) : (
            <InfiniteGridLoader
              items={discoveries}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={meta}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={370}
              cellHeight={268}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
