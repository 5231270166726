export const FETCH_FORMS_REQUEST = 'FETCH_FORMS_REQUEST';
export const FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS';
export const FETCH_UNCOMFIRMED_FORMS_REQUEST = 'FETCH_UNCOMFIRMED_FORMS_REQUEST';
export const FETCH_UNCOMFIRMED_FORMS_SUCCESS = 'FETCH_UNCOMFIRMED_FORMS_SUCCESS';
export const MASS_DELETE_FORMS = 'MASS_DELETE_FORMS';
export const TOGGLE_FORM_SELECTION = 'TOGGLE_FORM_SELECTION';
export const TOGGLE_ALL_FORMS_SELECTION = 'TOGGLE_ALL_FORMS_SELECTION';
export const RESET_FORMS = 'RESET_FORMS';
export const DELETE_FORM = 'DELETE_FORM';
export const UPDATE_FORM = 'UPDATE_FORM';
export const ADD_FORM = 'ADD_FORM';
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS';
export const FETCH_FORM_REQUEST = 'FETCH_FORM_REQUEST';
export const SET_TYPE = 'SET_TYPE';
export const SET_FORM_VALUE = 'SET_FORM_VALUE';
