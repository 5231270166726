import { objectKeysToMap } from '../../../../helpers/objectKeysToMap';

export const columnsWidths = {
  icon: 40,
  user: 330,
  start: 160,
  end: 160,
  duration: 120,
  lunches: 120,
  breaks: 150,
  actions: 120
};

export const columnsMap = objectKeysToMap(columnsWidths);
