import { createContext, useRef, useEffect, useReducer, useCallback } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import * as featureFlagsApi from '../../../../../api/feature-flags';
import { api } from '../../../../../api';
import { eventBus, eventBusEvents } from '../../../../../utils/eventBus';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const FeatureFlagsContext = createContext();

export const FeatureFlagsProvider = ({ filter: filterProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const initialFilter = useRef(state.filter);
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    featureFlags
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchFeatureFlags = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FEATURE_FLAGS_REQUEST });

    featureFlagsApi.fetchFeatureFlags({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_FEATURE_FLAGS_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFeatureFlags({ page: filter.page + 1 });
    }
  };

  const resetFeatureFlags = (newFilter) => {
    dispatch({ type: types.RESET_FEATURE_FLAGS, payload: newFilter });

    fetchFeatureFlags({ page: 1, ...newFilter });
  };

  const addFeatureFlag = useCallback((featureFlag) => {
    dispatch({ type: types.ADD_FEATURE_FLAG, payload: featureFlag });
  });

  const updateFeatureFlag = useCallback((featureFlag) => {
    dispatch({ type: types.UPDATE_FEATURE_FLAG, payload: featureFlag });
  });

  const deleteFeatureFlag = (featureFlag) => {
    dispatch({ type: types.DELETE_FEATURE_FLAG, payload: featureFlag.id });
  };

  const providerValue = {
    isFetched,
    isFetching,
    featureFlags,
    initialFilter: initialFilter.current,
    filter: filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    resetFeatureFlags,
    fetchFeatureFlags,
    updateFeatureFlag,
    loadNextPage,
    applyFilter,
    deleteFeatureFlag
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ] );

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetFeatureFlags(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    eventBus.on(eventBusEvents.featureFlagAdd, addFeatureFlag);
    eventBus.on(eventBusEvents.featureFlagUpdate, updateFeatureFlag);

    return () => {
      eventBus.remove(eventBusEvents.featureFlagAdd, addFeatureFlag);
      eventBus.remove(eventBusEvents.featureFlagUpdate, updateFeatureFlag);

      cancelFetch.current();
    };
  }, []);

  return (
    <FeatureFlagsContext.Provider value={providerValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
