import { makeStyles, Grid, Paper, Box } from '@material-ui/core';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { BillingContent } from './BillingContent';
import { Header } from './Header';
import { MainContent } from './MainContent';
import { OfficesContent } from './OfficesContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainInfo = ({ office, setOffice }) => {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.root}>
      <Header office={office} setOffice={setOffice} />

      <Grid
        container
        spacing={2}
        component={Box}
        flexWrap={{ lg: 'nowrap !important' }}
        className={classes.content}
      >
        <Grid item lg={4} xs={12}>
          <MainContent office={office} />
        </Grid>

        <VerticalDivider />

        <Grid item lg={4} xs={12}>
          <BillingContent office={office} />
        </Grid>

        <VerticalDivider />

        <Grid item lg={4} xs={12}>
          <OfficesContent users={office.users || []} />
        </Grid>
      </Grid>
    </Paper>
  );
};
