import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as servicesApi from '../../../../api/login-queues/services';
import { Loader } from '../../../Loader';
import { Page } from '../../../Page';
import { LoginSessionsFiltersProvider } from '../../sessions/LoginSessionsFiltersProvider';
import { LoginAccountsProvider } from '../LoginAccountsProvider';
import { Header } from './Header';
import { FiltersBar } from './FiltersBar';
import { Accounts } from './Accounts';

export const ServiceSessions = () => {
  const [ service, setService ] = useState(null);
  const { serviceId } = useParams();

  useEffect(() => {
    servicesApi.fetchService(serviceId).then((service) => {
      setService(service);
    });
  }, [ serviceId ]);

  return (
    <LoginAccountsProvider service={service}>
      <LoginSessionsFiltersProvider>
        <Loader loading={!service}>
          <Page>
            <Header service={service} />
            <FiltersBar />
            <Accounts />
          </Page>
        </Loader>
      </LoginSessionsFiltersProvider>
    </LoginAccountsProvider>
  );
};
