import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as taskApi from '../../../api/tasks';
import * as taskTypes from './types';

export const TaskPreviewContext = createContext();

export const caseInitialValues = {
  case_roles: [],
  users: [],
  parent_id: null,
  parent_type: 'case'
};

export const TaskPreviewContextProvider = ({ children, selectedTaskId }) => {
  const [ selectedTask, setSelectedTask ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const [ count, setCount ] = useState(0);
  const [ caseFields, setCaseFields ] = useState(caseInitialValues);
  const history = useHistory();
  const tasksLastGlobalAction = useSelector(({ globalActions }) => globalActions.tasksLastGlobalAction);

  const fetchTask = (id) => {
    setIsFetched(false);

    taskApi.fetchTask(id).then((data) => {
      setSelectedTask(data);
      setCount(data.todo_list.filter((item) => item.is_checked).length);
      setIsFetched(true);
    }).catch(({ status }) => {
      if (status === 404) {
        history.push('/tasks/not-found');
      }

      setIsFetched(true);
    });
  };

  useEffect(() => {
    if (isFetched && tasksLastGlobalAction && tasksLastGlobalAction.type === taskTypes.UPDATE_TASK_IN_LIST) {
      if (tasksLastGlobalAction.payload.todo_list && (
        tasksLastGlobalAction.payload.todo_list.length !== selectedTask.todo_list.length
          || !selectedTask.todo_list.length)
      ) {
        setSelectedTask(tasksLastGlobalAction.payload);
      }

      if (selectedTask && selectedTask.users.length !== tasksLastGlobalAction.payload.users.length) {
        setSelectedTask(tasksLastGlobalAction.payload);
      }

      if (count !== tasksLastGlobalAction.payload.todo_list.filter((item) => item.is_checked).length) {
        setCount(tasksLastGlobalAction.payload.todo_list.filter((item) => item.is_checked).length);
      }
    }
  }, [ tasksLastGlobalAction ]);

  const providerValue = {
    isFetched,
    count,
    selectedTask,
    selectedTaskId: +selectedTaskId,
    caseFields,

    setCaseFields,
    setSelectedTask,
    fetchTask
  };

  return (
    <TaskPreviewContext.Provider value={providerValue}>
      {children}
    </TaskPreviewContext.Provider>
  );
};
