import { createHookReducer } from '../../../../../helpers/createHookReducer';
import { moveArrayItem } from '../../../../../helpers/moveArrayItem';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_REMINDERS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_REMINDERS_SUCCESS]: (
    { filter, reminders, ...state },
    { data: newReminders, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      reminders: page > 1 ? (
        reminders.filter(({ id }) => {
          return !newReminders.find((loadedReminder) => id === loadedReminder.id);
        }).concat(newReminders)
      ) : (
        newReminders
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_REMINDERS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_REMINDERS]: (state, newReminders) => {
    const { pagination, filter, reminders, ...otherState } = state;
    const total = pagination.total + newReminders?.length;
    const page = Math.ceil((reminders.length + newReminders?.length) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      reminders: [ ...newReminders, ...reminders ]
    };
  },

  [types.UPDATE_REMINDER]: ({ reminders, ...state }, updatedReminder) => {
    return {
      ...state,

      reminders: reminders.map((reminder) => {
        return updatedReminder.id === reminder.id ? updatedReminder : reminder;
      })
    };
  },

  [types.UPDATE_REMINDERS_POSITIONS]: ({ reminders, ...state }, positions) => {
    const { oldIndex, newIndex } = positions;

    return {
      ...state,

      reminders: moveArrayItem(reminders, oldIndex, newIndex)
    };
  },

  [types.DELETE_REMINDER]: (state, reminderID) => {
    const { pagination, filter, reminders } = state;
    const isExistedReminder = !!reminders.find((reminder) => reminderID === reminder.id);

    if (!isExistedReminder) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((reminders.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      reminders: reminders.filter((reminder) => reminderID !== reminder.id)
    };
  }
});
