import { api } from '../../api';

export const fetchMedicalDocuments = (config) => {
  return api.get('/medical-documents', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchMedicalDocument = (id, config) => {
  return api.get(`/medical-documents/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createMedicalDocument = (data) => {
  return api.post('/medical-documents', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createMedicalDocuments = (medical_form_id) => {
  return api.post(`/medical-documents/${medical_form_id}/create`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMedicalDocument = ({ id, ...data }) => {
  return api.put(`/medical-documents/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteMedicalDocument = (id) => {
  return api.delete(`/medical-documents/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const getPDF = (id, responseType = 'blob') => {
  return api.get(`/medical-documents/${id}/pdf`, { responseType })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const signMedicalDocument = (id, data) => {
  return api.post(`/medical-documents/${id}/sign`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchMedicalDocumentTypes = (config) => {
  return api.get('/medical-documents/get-types', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchPDFArchive = (config) => {
  return api.get('/medical-documents/zip', { ...config, responseType: 'blob' })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const getPrintPDFArchive = (config) => {
  return api.get('/medical-documents/print', { ...config, responseType: 'blob' })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
