import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../helpers/dom';
import { Popper } from '../../Popper';
import { MenuList } from './MenuList';

export const Menu = ({
  appointment,
  ButtonProps = {},
  IconProps = {},
  onAppointmentUpdate = () => {},
  onAppointmentDelete = () => {}
}) => {
  return (
    <Popper
      placement="left-start"
      anchorRenderer={
        ({ anchorRef, handleToggle }) => (
          <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)} {...ButtonProps}>
            <MoreVertIcon {...IconProps} />
          </IconButton>
        )
      }
    >
      {({ handleClose }) => (
        <MenuList
          appointment={appointment}
          onClose={handleClose}
          onAppointmentUpdate={onAppointmentUpdate}
          onAppointmentDelete={onAppointmentDelete}
        />
      )}
    </Popper>
  );
};
