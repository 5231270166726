import { useContext } from 'react';
import { Checkbox, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { FilesFilterContext } from '../../../FilesFilterContext';
import { FilesContext } from '../../../FilesContext';
import { columnsWidths } from '../../../FilesList/listConfig';

export const TableHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { isSingleSelection, selectedFilesIDs } = useContext(FilesFilterContext);
  const { allFilesIsSelected, toggleAllFilesSelection } = useContext(FilesContext);

  return (
    <Paper elevation={0}>
      <ListRow header px={isMobile ? 0.5 : 1.5}>
        <ListRowCell flexBasis={columnsWidths.checkbox}>
          {!isSingleSelection &&
            <Checkbox
              edge="start"
              indeterminate={!!selectedFilesIDs.length && !allFilesIsSelected()}
              checked={allFilesIsSelected()}
              onClick={toggleAllFilesSelection}
            />
          }
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.name}>
          Name
        </ListRowCell>

        {!isMobile &&
          <ListRowCell grow flexBasis={columnsWidths.owner}>
            Owner
          </ListRowCell>
        }

        {!isMobile &&
          <ListRowCell flexBasis={columnsWidths.size}>
            Size
          </ListRowCell>
        }

        {!isMobile &&
          <ListRowCell grow flexBasis={columnsWidths.date}>
            Uploaded at
          </ListRowCell>
        }
      </ListRow>
    </Paper>
  );
};
