export const initialState = {
  isFetched: false,
  isFetching: false,
  filter: {
    page: 1,
    per_page: 15
  },
  pagination: {
    last_page: 1,
    total: 0
  },
  events: [],
  selectedEventsIDs: []
};
