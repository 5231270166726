import { useDispatch } from 'react-redux';
import { Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { setTasksLastGlobalAction } from '../../../../store/globalActions';
import TrackerPlusSvg from '../../../../components/icons/chronometer.svg';
import * as taskActionTypes from '../../../../components/tasks/TasksProvider/types';
import { TimeTrackingProvider } from '../../../../components/TimeTracking';
import { ModalHeader, ModalBody } from '../../../../components/Modal';
import { TimeTracking } from '../../../../components/TimeTracking';

export const TimeTracksModal = ({
  DialogProps,
  payload: { task },
  handleModalReject
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const dispatchRedux = useDispatch();

  const onTrackAdd = (track) => {
    const logged_time = ~~(task.logged_time + track.time);

    dispatchRedux(setTasksLastGlobalAction({
      type: taskActionTypes.UPDATE_TASK_IN_LIST,
      payload: { ...task, logged_time }
    }));
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <ModalHeader
        icon={<SvgIcon><TrackerPlusSvg/></SvgIcon>}
        onClose={handleModalReject}
      >
        Time Tracking
      </ModalHeader>

      <ModalBody disableScrollbars>
        <TimeTrackingProvider filter={{ model: 'task', model_id: task.id }}>
          <TimeTracking
            parentType="task"
            parentID={task.id}
            onTrackAdd={onTrackAdd}
          />
        </TimeTrackingProvider>
      </ModalBody>
    </Dialog>
  );
};
