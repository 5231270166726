export const getClaimPayoutStatus = (claim = {}) => {
  const { billing_info } = claim;
  const balance = billing_info?.total_paid -  billing_info?.total_charged;

  return balance === 0
    ? 'Paid'
    : balance > 0
      ? 'Overdue'
      : 'Credit';
};
