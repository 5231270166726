import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import {
  returnWorkIndicatorsOptions
} from '../../../../../claims/SituationalNumberFormModal/SituationalNumberForm/data';
import { NestedList } from '../../../../../NestedList';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../helpers/isEmptyDeep';

export const DiagnosticImpression = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const reportLabel = returnWorkIndicatorsOptions?.find(({ value }) => (
    value === report?.return_work_indicator
  ))?.label;

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(report) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Diagnostic Impression"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Grid container spacing={1} component={Box} py={2} m="0 !important">
        {report?.icd?.map((item, i) => (
          <Grid key={i} item xs={4}>
            <ReadonlyCheckbox
              checked
              noWrap={false}
              position="flex-start"
              BoxProps={{ color: 'text.secondary' }}
              iconColor="success"
              title={item?.description + ' (' + item?.code + ')'}
            />
          </Grid>
        ))}

        <Grid item xs={12} />

        <Grid item xs={3}>
          <Typography variant="h5">
            Disability
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography color="textSecondary">
            {report?.disability || '-'}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="h5">
            Return To Work Indicator
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography color="textSecondary">
            {reportLabel || '-'}
          </Typography>
        </Grid>

        {report?.note &&
          <Grid item xs={12}>
            <Box mt={2} display="flex" alignItems="flex-start">
              <Box display="flex" alignItems="center">
                <NotesIcon fontSize="small" />

                <Box mr={1.5} ml={0.5}>
                  <Typography variant="subtitle2">
                    Note
                  </Typography>
                </Box>
              </Box>

              <Typography>
                {report?.note}
              </Typography>
            </Box>
          </Grid>
        }
      </Grid>
    </NestedList>
  );
};
