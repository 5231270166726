import { memo, useContext, useLayoutEffect, useRef } from 'react';
import { FixedSizeList as List, areEqual } from 'react-window';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, List as MuiList, useMediaQuery, useTheme } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { TasksContext } from '../../../../../components/tasks/TasksProvider';
import { Card } from './Column/Card';

const Row = memo(({ data, index, style }) => {
  const item = data?.[index];

  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={`${item.id}`} index={index} key={item.id} type="item">
      {(provided) => <Card provided={provided} item={item} style={style} />}
    </Draggable>
  );
}, areEqual);

export const ColumnDraggingList = memo(({ column, status, index }) => {
  const { isFetching, pagination, filter: { per_page, ...filter }, fetchTasks } = useContext(TasksContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const rootRef = useRef();
  const listRef = useRef(null);
  const listNodeRef = useRef(null);
  const { height } = useResizeObserver({ ref: rootRef });

  const loadMoreRows = ({ scrollOffset }) => {
    if (listNodeRef && (scrollOffset + (height || 500) >= listNodeRef?.current?.scrollHeight)) {
      if (!isFetching && filter.page < pagination.last_page) {
        fetchTasks({ page: filter.page + 1 });
      }
    }
  };

  useLayoutEffect(() => {
    const list = listRef.current;

    if (list) {
      list.scrollTo(0);
    }
  }, [ index ]);

  return (
    <Box ref={rootRef} height="100%" overflow="hidden">
      <Droppable
        droppableId={`${status?.id}`}
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => (
          <Card
            provided={provided}
            isDragging={snapshot?.isDragging}
            item={column?.items?.[rubric?.source.index]}
          />
        )}
      >
        {(provided, snapshot) => {
          const itemCount = snapshot?.isUsingPlaceholder
            ? column?.items?.length + 1
            : column?.items?.length;

          return (
            <List
              height={height || 500}
              itemCount={itemCount}
              itemSize={275}
              width={isMobile ? '100%' : 300}
              innerRef={listNodeRef}
              innerElementType={MuiList}
              outerRef={provided.innerRef}
              itemData={column?.items}
              ref={listRef}
              onScroll={loadMoreRows}
            >
              {Row}
            </List>
          );
        }}
      </Droppable>
    </Box>
  );
});
