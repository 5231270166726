export const styles = ({ spacing, palette, breakpoints }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(0.8, 1, 0.8, 2),
    borderBottom: `1px solid ${palette.divider}`,

    [breakpoints.down('md')]: {
      padding: spacing(0, 1, 0, 2),
      flexWrap: 'wrap'
    }
  },

  col: {
    display: 'flex',
    alignItems: 'center'
  },

  attachToPatientButton: {
    color: palette.pink
  }
});
