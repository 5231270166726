import { matchPath } from 'react-router';
import { Route } from './Route';

export const renderRoutes = (routes, payload = {}) => {
  return routes.map(({ hide = () => false, ...props }, index) => {
    const match = matchPath(window.location.pathname, {
      path: props.path
    });

    return !hide({ params: match?.params, payload }) && (
      <Route key={index} {...props} />
    );
  });
};
