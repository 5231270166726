import { Box, List, ListItem, ListItemText, Paper } from '@material-ui/core';

export const CasesMenu = ({ cases, updateFilter, onChangeAnchorEl = () => {} }) => {
  const handleFilterChange = (caseItem) => () => {
    updateFilter('case_id', caseItem);
    onChangeAnchorEl({});
  };

  return (
    <Paper component={Box} boxShadow={1} overflow="hidden" borderRadius={4}>
      <List>
        {cases.map((caseItem) => (
          <ListItem
            button
            key={caseItem.id}
            onClick={handleFilterChange(caseItem)}
          >
            <ListItemText
              primary={caseItem.name}
              secondary={`Case # ${caseItem.file_number || '-'}`}
              primaryTypographyProps={{ noWrap: true }}
              secondaryTypographyProps={{ noWrap: true }}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
