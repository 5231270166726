import { CodesContextProvider } from './CodesContext';
import { MainContent } from './MainContent';

export const CodesICD = () => {
  return (
    <CodesContextProvider>
      <MainContent />
    </CodesContextProvider>
  );
};
