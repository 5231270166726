export const incidentTypesOptions = [
  { value: '3MVA', label: 'Three Motor Vehicle Accident' },
  { value: '2MVA', label: 'Two Motor Vehicle Accident' },
  { value: 'WD', label: 'Wrongful Death'  },
  { value: 'premise', label: 'Premise' },
  { value: 'pedestrian_auto', label: 'Pedestrian v Auto' },
  { value: 'motorcycle_auto', label: 'Motorcycle v Auto' },
  { value: 'hit_and_run', label: 'Hit-and-Run' },
  { value: 'dog_bite', label: 'Dog bite' },
  { value: 'bicycle_auto', label: 'Bicycle v Auto' },
  { value: 'bicycle_premises', label: 'Bicycle premises' }
];
