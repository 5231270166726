import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import {
  TextField,
  OfficesLocationSelect,
  CompaniesAutocomplete
} from '../../../../../../../components/FormField';

export const Header = () => {
  const { values, setValues } = useFormikContext();
  const [ selectedCompany, setSelectedCompany ] = useState(null);

  const handleCompanyChange = (company) => {
    if (company) {
      setSelectedCompany(company);
      setValues({
        ...values,

        company_id: company?.id || null,
        name: company?.abbr || values.name || null,
        address_1: company?.billing?.full_address || null,
        main_phone: company?.phone || null,
        fax_1: company?.fax || null,
        icd_10: !!company,
        zip: company.billing.zip || values.zip || null,
        state: company.billing.state || values.state || null,
        city: company?.billing?.city || values.city || null,
        office_id: null
      });
    } else {
      setSelectedCompany(null);
      setValues({
        ...values,

        office_id: null,
        company_id: null,
        name: null,
        address_1: null,
        main_phone: null,
        fax_1: null,
        icd_10: null,
        zip: null,
        state: null,
        city: null
      });
    }
  };

  const handleOfficeChange = (office) => {
    if (office) {
      setValues({
        ...values,

        address_1: office?.billing?.full_address || selectedCompany?.billing?.full_address || values.address_1 || null,
        zip: office?.billing?.zip || selectedCompany?.billing?.zip || values.zip || null,
        state: office?.billing?.state || selectedCompany?.billing?.state || values.state || null,
        city: office?.billing?.city || selectedCompany?.billing?.city || values.city || null
      });
    } else {
      setValues({
        ...values,

        address_1: selectedCompany?.billing?.full_address || values.address_1 || null,
        zip: selectedCompany?.billing?.zip || values.zip || null,
        state: selectedCompany?.billing?.state || values.state || null,
        city: selectedCompany?.billing?.city || values.city || null
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={4}>
        <CompaniesAutocomplete
          required
          name="company_id"
          margin="dense"
          label="Payer Fill Name/Company Name"
          onChange={handleCompanyChange}
        />
      </Grid>

      <Grid item sm={4} xs={4}>
        <OfficesLocationSelect
          disabled={!values?.company_id}
          name="office_id"
          label="Office"
          margin="dense"
          placeholder="Select office..."
          params={{ company_id: values.company_id?.value || values?.company_id }}
          onChange={handleOfficeChange}
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField name="payer_code" label="Payer Code" />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField name="professional_edi" label="Claim EDI No. Professional" />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField name="institutional_edi" label="Claim EDI No. Institutional" />
      </Grid>

      <Grid item sm={4} xs={12}>
        <TextField name="eligibility_edi" label="Eligibility EDI" />
      </Grid>
    </Grid>
  );
};
