import { useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import {
  makeStyles,
  Box,
  FormControlLabel,
  Radio,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  socialHistoryTypes
  // eslint-disable-next-line max-len
} from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/FormPreviewModal/PastMedicalHistory/socialHistoryTypes';
import {
  Checkbox,
  RadioGroup,
  TextField
} from '../../../FormField';
import { Fab } from '../../../Fab';
import { getTextColor } from '../../getTextColor';
import { MedicalHistorySurgeriesList } from '../MedicalHistorySurgeriesList';
import { Medications, MedicationsCards } from '../Medications';
import { Allergies } from '../Allergies';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MedicalHistoryForm = () => {
  const classes = useStyles();
  const { values, errors, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleAddFields = (arrayHelpers, fields) => (event) => {
    if ((event.charCode || event.keyCode) === 13) {
      arrayHelpers.push(fields);
    }
  };

  useEffect(() => {
    if (values?.forms?.medical_history?.is_problems) {
      setFieldValue('forms.medical_history.medical_problems', null);
    }
  }, [ values?.forms?.medical_history?.is_problems ]);

  useEffect(() => {
    if (values?.forms?.medical_history?.medical_problems) {
      setFieldValue('forms.medical_history.is_problems', false);
    }
  }, [ values?.forms?.medical_history?.medical_problems ]);

  return (
    <Box flexGrow={1}>
      <Typography variant="h5" color="secondary">
        Medical History
      </Typography>

      <Box mt={2} mb={5}>
        <Typography
          gutterBottom
          variant="h5"
          color={getTextColor(errors?.forms?.medical_history?.medical_problems)}
        >
          1. Please list all current and past MEDICAL PROBLEMS:

          <Typography
            component="span"
          >
            {!values?.forms?.medical_history?.is_problems ? ' *' : ''}
          </Typography>
        </Typography>

        <FormControlLabel
          label={<Typography>None</Typography>}
          control={
            <Checkbox
              color="primary"
              name="forms.medical_history.is_problems"
            />
          }
        />

        <TextField
          fullWidth
          multiline
          name="forms.medical_history.medical_problems"
          placeholder="Please describe"
          margin="dense"
          variant="filled"
          rows={5}
        />
      </Box>

      <Box mb={5}>
        <FieldArray
          name="forms.medical_history.current_medications"
          render={(arrayHelpers) => (
            <>
              <Box display="flex" alignItems="center">
                <Typography variant="h5">
                  2. Current Medications
                </Typography>

                <Box ml={2}>
                  <Fab
                    size="small"
                    color="primary"
                    variant="extended"
                    startIcon={<AddIcon/>}
                    onClick={() => arrayHelpers.push({
                      name: '',
                      dosage: '',
                      frequency: '',
                      take: ''
                    })}
                  >
                    Add
                  </Fab>
                </Box>
              </Box>

              {isSmallScreen
                ? <MedicationsCards arrayHelpers={arrayHelpers} onAddFields={handleAddFields} />
                : <Medications arrayHelpers={arrayHelpers} onAddFields={handleAddFields} />
              }
            </>
          )}
        />
      </Box>

      <Box mb={5}>
        <FieldArray
          name="forms.medical_history.allergies_or_reactions"
          render={(arrayHelpers) => (
            <>
              <Box display="flex" alignItems="center">
                <Typography variant="h5">
                  3. List allergies or reactions to medications
                </Typography>

                <Box ml={2}>
                  <Fab
                    size="small"
                    color="primary"
                    variant="extended"
                    startIcon={<AddIcon/>}
                    onClick={() => arrayHelpers.push({ name: '' })}
                  >
                    Add
                  </Fab>
                </Box>
              </Box>

              <Allergies arrayHelpers={arrayHelpers} onAddFields={handleAddFields} />
            </>
          )}
        />
      </Box>

      <Box mb={5}>
        <Typography variant="h5">
          4. Do you take any blood thinning medications? If yes which of the following?
        </Typography>

        <div className={classes.checkboxesContent}>
          <FormControlLabel
            label={<Typography>Aspirin</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.medical_history.blood_thinning_medications.aspirin"
              />
            }
            className={classes.checkboxesContentRow}
          />

          <FormControlLabel
            label={<Typography>Coumadin</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.medical_history.blood_thinning_medications.coumadin"
              />
            }
            className={classes.checkboxesContentRow}
          />

          <FormControlLabel
            label={<Typography>Plavix</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.medical_history.blood_thinning_medications.plavix"
              />
            }
            className={classes.checkboxesContentRow}
          />

          <FormControlLabel
            label={<Typography>Heparin</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.medical_history.blood_thinning_medications.heparin"
              />
            }
            className={classes.checkboxesContentRow}
          />

          <FormControlLabel
            label={<Typography>Pletal</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.medical_history.blood_thinning_medications.pletal"
              />
            }
            className={classes.checkboxesContentRow}
          />

          <FormControlLabel
            label={<Typography>Lovenox</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.medical_history.blood_thinning_medications.lovenox"
              />
            }
            className={classes.checkboxesContentRow}
          />

          <FormControlLabel
            label={<Typography>Ticlid</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.medical_history.blood_thinning_medications.ticlid"
              />
            }
            className={classes.checkboxesContentRow}
          />
        </div>
      </Box>

      <Box mb={5}>
        <FieldArray
          name="forms.medical_history.surgeries_dates"
          render={(arrayHelpers) => (
            <>
              <Box display="flex" alignItems="center">
                <Typography variant="h5">
                  5. Please list all surgeries and their dates
                </Typography>

                <Box ml={2}>
                  <Fab
                    size="small"
                    color="primary"
                    variant="extended"
                    startIcon={<AddIcon/>}
                    onClick={() => arrayHelpers.push({
                      date_from: null,
                      date_to: null,
                      description: ''
                    })}
                  >
                    Add
                  </Fab>
                </Box>
              </Box>

              <MedicalHistorySurgeriesList arrayHelpers={arrayHelpers} onAddFields={handleAddFields} />
            </>
          )}
        />
      </Box>

      <Box mb={5}>
        <Typography variant="h5">
          6. Family Medical History: please list any illness or disease that runs in your family.
        </Typography>

        <TextField
          fullWidth
          multiline
          disableUnderline
          name="forms.medical_history.family_medical_history"
          placeholder="Please describe"
          margin="dense"
          variant="filled"
          rows={5}
        />
      </Box>

      <Box mb={5}>
        <Typography variant="h5">
          7. Marital Status
        </Typography>

        <div className={classes.contentInfo}>
          <RadioGroup
            row
            name="forms.medical_history.marital_status"
            className={classes.radioRowGroup}
          >
            <FormControlLabel
              value="single"
              label="Single"
              control={<Radio color="primary" />}
              className={classes.radioRowGroup__content}
            />

            <FormControlLabel
              value="married"
              label="Married"
              control={<Radio color="primary"/>}
              className={classes.radioRowGroup__content}
            />

            <FormControlLabel
              value="divorced"
              label="Divorced"
              control={<Radio color="primary" />}
              className={classes.radioRowGroup__content}
            />

            <FormControlLabel
              value="domestic_partner"
              label="Domestic Partner"
              control={<Radio color="primary"/>}
              className={classes.radioRowGroup__content}
            />

            <FormControlLabel
              value="separated"
              label="Separated"
              control={<Radio color="primary" />}
              className={classes.radioRowGroup__content}
            />

            <FormControlLabel
              value="widow"
              label="Widow"
              control={<Radio color="primary" />}
              className={classes.radioRowGroup__content}
            />

            <FormControlLabel
              value="unknown"
              label="Unknown"
              control={<Radio color="primary" />}
              className={classes.radioRowGroup__content}
            />

            <FormControlLabel
              value="other"
              label="Other"
              control={<Radio color="primary" />}
              className={classes.radioRowGroup__content}
            />
          </RadioGroup>
        </div>
      </Box>

      <Box mb={5}>
        <Typography
          variant="h5"
          color={getTextColor(errors?.forms?.medical_history?.occupation_value)}
        >
          8. Occupation *
        </Typography>

        <Box px={2} py={1}>
          <RadioGroup row name="forms.medical_history.occupation">
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" color="primary" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" color="primary"/>}
            />
          </RadioGroup>
        </Box>

        <TextField
          fullWidth
          multiline
          disableUnderline
          required={values?.forms?.medical_history?.occupation === socialHistoryTypes.yes}
          name="forms.medical_history.occupation_value"
          label="Description"
          placeholder="Please describe..."
          margin="dense"
          variant="filled"
          rows={5}
        />
      </Box>

      <Box mb={5}>
        <Typography
          variant="h5"
          color={getTextColor(errors?.forms?.medical_history?.you_use_tobacco_value)}
        >
          9. Do you use tobacco? If yes please describe *
        </Typography>

        <Box px={2} py={1}>
          <RadioGroup row name="forms.medical_history.you_use_tobacco">
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" color="primary" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" color="primary"/>}
            />
          </RadioGroup>
        </Box>

        <TextField
          fullWidth
          multiline
          disableUnderline
          required={values?.forms?.medical_history?.you_use_tobacco === socialHistoryTypes.yes}
          name="forms.medical_history.you_use_tobacco_value"
          label="Description"
          placeholder="Please describe..."
          margin="dense"
          variant="filled"
          rows={5}
        />
      </Box>

      <Box mb={5}>
        <Typography
          variant="h5"
          color={getTextColor(errors?.forms?.medical_history?.you_use_alcohol_value)}
        >
          10. Do you consume alcohol? If yes please describe *
        </Typography>

        <Box px={2} py={1}>
          <RadioGroup row name="forms.medical_history.you_use_alcohol">
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" color="primary" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" color="primary"/>}
            />
          </RadioGroup>
        </Box>

        <TextField
          fullWidth
          multiline
          disableUnderline
          required={values?.forms?.medical_history?.you_use_alcohol === socialHistoryTypes.yes}
          name="forms.medical_history.you_use_alcohol_value"
          label="Description"
          placeholder="Please describe..."
          margin="dense"
          variant="filled"
          rows={5}
        />
      </Box>

      <Box mb={5}>
        <Typography
          variant="h5"
          color={getTextColor(errors?.forms?.medical_history?.used_recreational_drugs_value)}
        >
          11. Do you currently or have you ever used recreational drugs, including cannabis?
          If yes please describe *
        </Typography>

        <Box px={2} py={1}>
          <RadioGroup row name="forms.medical_history.used_recreational_drugs">
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" color="primary" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" color="primary"/>}
            />
          </RadioGroup>
        </Box>

        <TextField
          fullWidth
          multiline
          disableUnderline
          required={values?.forms?.medical_history?.used_recreational_drugs === socialHistoryTypes.yes}
          name="forms.medical_history.used_recreational_drugs_value"
          label="Description"
          placeholder="Please describe..."
          margin="dense"
          variant="filled"
          rows={5}
        />
      </Box>
    </Box>
  );
};
