import { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import filesize from 'filesize';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import {
  makeStyles,
  Dialog,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  ListItemSecondaryAction
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { generateAggregateFileType } from '../../../../utils/generateAggregateFileType';
import * as filesApi from '../../../../api/files';
import { uploadFiles } from '../../../../store/dashboard/files/uploads';
import { TagsSelect } from '../../../../components/Tags/TagsSelect';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../components/Modal';
import { useModal } from '../../../../components/ModalsProvider';
import { FileDropZone } from '../../../../components/FileDropZone';
import { IconButton } from '../../../../components/IconButton';
import { Loader } from '../../../../components/Loader';
import { FileValidationConflictModal } from '../FileValidationConflictModal';
import { FileTypeIcon } from '../FileTypeIcon';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FileUploadModal = ({
  DialogProps,
  payload: {
    isPersonalShared = false,
    files,
    owner_type,
    owner_id
  } = {},
  handleModalResolve,
  handleModalReject
}) => {
  const [ validatedFiles, setValidatedFiles ] = useState([]);
  const [ filesOnValidationCount, setFilesOnValidationCount ] = useState(0);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const dispatch = useDispatch();

  const validateFile = (file) => {
    file.original_filename = file.original_filename || file.name;
    file.owner_type = owner_type;
    file.owner_id = owner_id;

    if (file?.original_filename?.length >= 120) {
      openModal(FileValidationConflictModal, {
        payload: {
          title: 'The original filename may not be greater than 120 characters',
          file
        },
        onModalResolved: (file) => {
          validateFile(file);
        }
      });
    } else {
      if (validatedFiles.find(({ original_filename }) => original_filename === file.original_filename)) {
        openModal(FileValidationConflictModal, {
          payload: { file, isPersonalShared },
          onModalResolved: (file) => {
            validateFile(file);
          }
        });
      } else {
        setFilesOnValidationCount((state) => state + 1);

        filesApi[isPersonalShared ? 'validatePersonalSharedFile' : 'validateFile'](file).then(() => {
          if (!isPersonalShared) {
            file.aggregate_type = generateAggregateFileType(file.type);
          }

          setValidatedFiles((state) => [ file ].concat(state));
        }).catch(({ status }) => {
          if (status === 409 ) {
            openModal(FileValidationConflictModal, {
              payload: { file, isPersonalShared },
              onModalResolved: (file) => {
                validateFile(file);
              }
            });
          } else {
            enqueueSnackbar(`File "${file.original_filename}" is invalid`, {
              variant: 'error'
            });
          }
        }).then(() => {
          setFilesOnValidationCount((state) => state - 1);
        });
      }
    }
  };

  const validateFiles = (files) => {
    files.forEach((file) => {
      validateFile(file);
    });
  };

  const handleSubmit = ({ myTags, globalTags }) => {
    const tagsIDs = (myTags || []).concat(globalTags || []);

    dispatch(uploadFiles({
      isPersonalShared: isPersonalShared,
      files: validatedFiles,
      tags: tagsIDs,
      owner_type,
      owner_id
    }));

    handleModalResolve();
  };

  const handleDeleteFile = (file) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setValidatedFiles(validatedFiles.filter(({ original_filename }) => {
          return original_filename !== file.original_filename;
        }));
      }
    });
  };

  useEffect(() => {
    if (files) {
      validateFiles(files);
    }
  }, []);

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          myTags: [],
          globalTags: []
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Upload files
            </ModalHeader>

            <ModalBody>
              <FileDropZone multiple onDropAccepted={validateFiles} />

              {!isPersonalShared &&
                <>
                  <TagsSelect
                    multiple
                    name="myTags"
                    label="Select my tags"
                    placeholder="Select my tags..."
                    margin="dense"
                    params={{ is_system: 0 }}
                  />

                  <TagsSelect
                    multiple
                    name="globalTags"
                    label="Select global tags"
                    placeholder="Select global tags..."
                    margin="dense"
                    params={{ is_system: 1 }}
                  />
                </>
              }

              {!!(filesOnValidationCount || validatedFiles.length) &&
                <div className={classes.filesList}>
                  {!!validatedFiles.length &&
                    <Typography variant="h3" gutterBottom>
                      Files selected for upload
                    </Typography>
                  }

                  <List>
                    <Loader loading={filesOnValidationCount > 0} />

                    {validatedFiles.map((file, index) => (
                      <Fragment key={file.original_filename}>
                        {index > 0 && <Divider />}

                        <ListItem>
                          <FileTypeIcon file={file} />

                          <ListItemText
                            primary={file.original_filename}
                            primaryTypographyProps={{ noWrap: true }}
                          />

                          <ListItemText className={classes.fileSize} primary={filesize(file.size)} />

                          <ListItemSecondaryAction>
                            <IconButton edge="end" color="error" onClick={handleDeleteFile(file)}>
                              <CloseIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Fragment>
                    ))}
                  </List>
                </div>
              }
            </ModalBody>

            <ModalFooter>
              <Loader
                surface
                loading={!!filesOnValidationCount}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!!filesOnValidationCount || !validatedFiles.length}
                  >
                    Upload
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
