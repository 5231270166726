export function unique(filedName, message, getUniqueValue = (value) => value) {
  return this.test('unique', message, function (list) {
    const { path, createError } = this;
    const parent = this?.parent?.provider_id_types;

    if (list.length > new Set(list.map(getUniqueValue)).size) {
      return createError({
        path: `${path}.${[ parent?.length - 1 ]}.${filedName}`,
        message
      });
    }

    return true;
  });
}
