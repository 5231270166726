export const styles = ({ spacing }) => ({
  root: {
    height: '100%',
    padding: spacing(2),
    display: 'flex',
    alignItems: 'stretch',
    overflow: 'hidden'
  },

  rootSm: {
    height: '100%',
    padding: spacing(1)
  }
});
