import { Yup } from '../../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  referring_physician_id: Yup.mixed(),
  treating_physician_id: Yup.mixed(),
  preferred_id: Yup.mixed(),
  med_rec: Yup.string().nullable().max(255),
  ext_med_rec: Yup.string().nullable().max(255),
  doi: Yup.mixed(),
  notes: Yup.array().of(
    Yup.object().shape({
      date: Yup.mixed(),
      description: Yup.string().max(255)
    })
  ).notRequired()
});
