import { useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles, Button, Dialog, InputAdornment, SvgIcon } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiRestore, mdiMagnify } from '@mdi/js';
import * as eamsApi from '../../../../../../api/cases';
import { TextField } from '../../../../../../components/FormField/TextField';
import { Fab } from '../../../../../../components/Fab';
import { Loader } from '../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../components/Modal';
import { MainContent } from './MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EAMSIntegrationModal = ({
  DialogProps,
  handleModalReject,
  payload: { initialValues = {} }
}) => {
  const classes = useStyles();
  const defaultText = 'ADJ';
  const [ caseNumber, setCaseNumber ] = useState(null);
  const [ eamsData, setEamsData ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);
  const [ isFetching, setIsFetching ] = useState(false);
  const [ message, setMessage ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const fetchEAMS = () => {
    setIsFetched(false);
    setIsFetching(true);

    eamsApi.fetchEAMS(defaultText + caseNumber)
      .then((data) => {
        setEamsData(data);
        setIsFetched(true);
      })
      .catch(({ message }) => {
        setIsFetched(true);
        setMessage(message);
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const handleGenerateEAMSIntegration = (EAMSdata,  { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    // TODO: display the reseived data in console
    setSubmitting(true);
    handleModalReject();
  };

  const handleChangeCaseNumber = (event) => {
    const value = event.target.value;

    setIsFetching(false);
    setCaseNumber(value);
  };

  const handleResetCaseNumber = (callback) => () => {
    callback((values) => ({
      ...values,
      case_number: null
    }));
    setCaseNumber(null);
    setIsFetching(false);
  };

  return (
    <Formik
      initialValues={{
        case_number: caseNumber,
        ...initialValues
      }}
      onSubmit={handleGenerateEAMSIntegration}
    >
      {({ setValues, handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="md" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Case detail information
            </ModalHeader>

            <ModalBody>
              <TextField
                name="case_number"
                label="Case number/EAMS case number"
                className={classes.textField}
                onChange={handleChangeCaseNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      disableTypography
                      position="start"
                      className={classes.defaultText}
                    >
                      {defaultText}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <div className={classes.actionButtons}>
                      <InputAdornment
                        position="end"
                        size="small"
                        component={Fab}
                        onClick={handleResetCaseNumber(setValues)}
                        className={classes.adornmentActionButton}
                      >
                        <SvgIcon fontSize="small"><Icon path={mdiRestore}/></SvgIcon>
                      </InputAdornment>

                      <InputAdornment
                        position="end"
                        size="small"
                        component={Fab}
                        className={classes.adornmentActionButton}
                        onClick={fetchEAMS}
                      >
                        <SvgIcon fontSize="small"><Icon path={mdiMagnify}/></SvgIcon>
                      </InputAdornment>
                    </div>
                  )
                }}
              />

              {!!isFetching &&
                <MainContent
                  message={message}
                  eamsData={eamsData}
                  isFetched={isFetched}
                  defaultText={defaultText}
                  caseNumber={caseNumber}
                />
              }
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Generate
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
