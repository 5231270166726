import {
  SvgIcon,
  Typography,
  Link,
  Box,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { mdiDomain } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Avatar } from '../../../../../../../components/Avatar';

export const Header = ({ company }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container spacing={2} wrap="nowrap">
      <Grid item>
        <Avatar
          size={isMobile ? 'lg' : 'xxxl'}
          src={company.avatar}
          alt={company.name[0]}
        />
      </Grid>

      <Grid item container xs={10}>
        <Grid item xs={12}>
          <Typography noWrap title={company.name} variant={isMobile ? 'h5' : 'h6'}>
            {company.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography noWrap color="textSecondary" variant="caption">
            {company?.company_type?.name || company?.company_type || '-'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-end">
            <Box display="flex" mr={4}>
              <Box mr={0.5}>
                <SvgIcon><Icon path={mdiDomain} /></SvgIcon>
              </Box>

              <Typography noWrap>{`${company.offices_count || 0} offices`}</Typography>
            </Box>

            <Typography noWrap component={Link}>
              Show on the map
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
