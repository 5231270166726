import { LayoutContextProvider } from '../../../../components/LayoutContext';
import { ScheduleEventTemplatesProvider } from '../../../../components/schedule-events/templates';
import { PageContent } from './PageContent';

export const ScheduleEventTemplates = () => {
  return (
    <ScheduleEventTemplatesProvider>
      <LayoutContextProvider>
        <PageContent />
      </LayoutContextProvider>
    </ScheduleEventTemplatesProvider>
  );
};
