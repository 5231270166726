import { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  SvgIcon,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { TemplatesContext } from '../../../TemplatesContext';

const styles = () => ({
  root: {
    minWidth: 172
  }
});

const useStyles = makeStyles(styles);

export const Menu = ({ template }) => {
  const templatesContext = useContext(TemplatesContext);
  const classes = useStyles();

  const deleteTemplate = () => {
    templatesContext.deleteTemplate(template);
  };

  return (
    <List className={classes.root}>
      <MenuItem component={Link} to={`/files/templates/edit/${template.id}`}>
        <ListItemIcon>
          <EditIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={deleteTemplate}>
        <ListItemIcon>
          <SvgIcon fontSize="inherit">
            <Icon path={mdiTrashCanOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
