import { Component } from 'react';
import { connect } from 'react-redux';
import {
  withStyles,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { fetchRoles } from '../../../../store/dashboard/roles/operations';

const styles = ({ spacing }) => ({
  root: {
    padding: spacing(),
    marginBottom: spacing()
  }
});

class Roles extends Component {
  // createRole = () => {
  //   this.props.openModal('CreateRoleModal');
  // };

  componentDidMount() {
    this.props.fetchRoles();
  }

  render() {
    const { classes, isFetching, roles } = this.props;

    return (
      <Paper className={classes.root}>
        {isFetching &&
          <CircularProgress />
        }

        {!isFetching &&
          <>
            {/*<SlideBtn*/}
            {/*variant="contained"*/}
            {/*color="primary"*/}
            {/*onClick={this.createRole}*/}
            {/*>*/}
            {/*Add role*/}
            {/*</SlideBtn>*/}

            {!roles.length &&
              <Typography variant="h5" align="center">Roles list is empty</Typography>
            }

            {!!roles.length &&
              <Grid container justify="center">
                <Grid item>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/*{roles.map(roleItem => <RoleItem key={roleItem.id} roleItem={roleItem} />)}*/}
                      {
                        roles.map((roleItem) => (
                          <tr key={roleItem.id}>
                            <td>{roleItem.name}</td>
                          </tr>
                        ))
                      }
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            }
          </>
        }
      </Paper>
    );
  }
}

Roles = connect(({ roles }) => ({
  isFetching: roles.isFetching,
  roles: roles.roles
}), {
  fetchRoles
})(Roles);

Roles = withStyles(styles)(Roles);

export { Roles };
