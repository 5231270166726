import { Grid } from '@material-ui/core';
import { ArrivalTypesSelect } from '../../../../../components/appointments/ArrivalTypesSelect';
import { TextField } from '../../../../../components/FormField';

export const ArrivalStatusForm = () => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="code"
          label="Code"
          placeholder="Enter code..."
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="description"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        <ArrivalTypesSelect
          required
          disableClearable
          name="arrival_type"
          label="Arrival type"
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        <TextField
          required
          name="ordering"
          label="Ordering"
          placeholder="Enter ordering..."
        />
      </Grid>
    </Grid>
  );
};
