import { useMediaQuery, useTheme } from '@material-ui/core';
import { FileRow } from '../../../FilesList/FileRow';
import { FileRowMobile } from '../../../FilesList/FileRowMobile';

export const Row = ({ item: file = {}, isLoaded, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    isMobile ?
      <FileRowMobile isLoaded={isLoaded} file={file} {...props} /> :
      <FileRow isLoaded={isLoaded} file={file} {...props} />
  );
};
