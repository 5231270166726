import { useContext, useEffect } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { getUserFullName } from '../../../../../../../../helpers/users/getUserFullName';
import { Loader } from '../../../../../../../../components/Loader';
import { VersionPreviewContext } from '../../../VersionPreviewContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item: version = {}, isLoaded, recalculateHeight }) => {
  const classes = useStyles();
  const versionPreviewContext = useContext(VersionPreviewContext);
  const isSelected = !!version && versionPreviewContext.selectedVersion.id === version.id;

  const handleVersionSelect = () => {
    versionPreviewContext.handleVersionSelect(version.id);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return (
    <div
      className={
        cn(
          classes.root,
          {
            [classes.root_selected]: isSelected
          }
        )
      }
      onClick={handleVersionSelect}
    >
      {!isLoaded ? <Loader className={classes.loader}/> :
        <>
          <Typography gutterBottom variant="subtitle1">
            {getUserFullName(version.user)}
          </Typography>

          <Typography>
            {moment.unix(version.created_at).format('L LT')}
          </Typography>
        </>
      }
    </div>
  );
};
