export const styles = ({ palette: { grey } }) => ({
  minWidth: {
    minWidth: ({ minWidth = null }) => minWidth
  },

  notEditableInput: {
    color: 'currentColor !important',

    '&:before': {
      borderBottomStyle: 'solid !important'
    }
  },

  notEditableInputLabel: {
    color: `${grey[500]} !important`
  },

  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },

    '&&:after': {
      borderBottom: 'none'
    }
  },

  zeroMinWidth: {
    minWidth: 0
  }
});
