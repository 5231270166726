import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as appointmentTypesApi from '../../../../../../api/codes/appointments/appointment-types';
import { Loader } from '../../../../../../components/Loader';
import {
  Page,
  PageBody,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../../../components/Page';
import { ApptTypeForm } from '../ApptTypeForm';
import { initialValues } from '../initialValues';
import { validationSchema } from '../validationSchema';

export const Create = ({ baseUrl }) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const createAppointmentType = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return appointmentTypesApi.createAppointmentType({
      ...values,

      time: moment.duration(`${values._hours || 0}:${values._minutes || 0}`).asSeconds() || null
    }).then(() => {
      history.push(`${baseUrl}/appointment-types`);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createAppointmentType}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Page component="form" noValidate onSubmit={handleSubmit}>
          <PageHeader>
            <IconButton
              component={Link}
              to={`${baseUrl}/appointment-types`}
              color="primary"
              edge="start"
            >
              <KeyboardBackspaceOutlinedIcon />
            </IconButton>

            <PageHeaderTitle>
              {isMobile ? 'Create appt. type' : 'Create appointment type'}
            </PageHeaderTitle>

            <PageHeaderActions>
              <Button
                size={isMobile ? 'small' : 'medium'}
                component={Link}
                to={`${baseUrl}/appointment-types`}
                color="primary"
              >
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    size={isMobile ? 'small' : 'medium'}
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Create
                  </Button>
                )}
              />
            </PageHeaderActions>
          </PageHeader>

          <PageBody>
            <ApptTypeForm />
          </PageBody>
        </Page>
      )}
    </Formik>
  );
};
