import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import RestoreIcon from '@material-ui/icons/RestoreOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import { isPrintable, printFile } from '../../../../../../helpers/files';
import { menuOptionsTypesMap } from '../../../../files-common/menuOptionsTypes';

export const getFileMenuOptions = (methods) => (file) => [
  {
    type: menuOptionsTypesMap.menuItem,
    Icon: RestoreIcon,
    label: 'Restore',
    onClick: () => {
      methods.restoreFiles([ file.id ]);
    }
  },
  {
    type: menuOptionsTypesMap.menuItem,
    Icon: DeleteIcon,
    label: 'Delete permanently',
    onClick: () => {
      methods.deleteFiles([ file.id ]);
    }
  },
  {
    type: menuOptionsTypesMap.divider
  },
  {
    isHidden: !isPrintable(file),
    type: menuOptionsTypesMap.menuItem,
    Icon: PrintIcon,
    label: 'Print file',
    onClick: () => {
      printFile(file);
    }
  }
];
