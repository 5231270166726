export const styles = ({ spacing }) => ({
  root: {
    width: '100%',
    marginBottom: spacing()
  },

  form: {
    width: '100%'
  },

  formField: {
    paddingBottom: spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },

  userImage: {
    marginRight: spacing(1.5)
  }
});
