import { useEffect, useContext } from 'react';
import { ClaimsContext, ClaimsList } from '../../../../../../components/claims';
import { Row } from './Row';

const ListRowProps = {
  px: 1
};

export const List = ({
  isEqual = false,
  isWidgets= false,
  filters = {},
  view,

  ...props
}) => {
  const { applyFilter, resetClaims } = useContext(ClaimsContext);

  useEffect(() => {
    if (isWidgets) {
      applyFilter(filters);
    }
  }, [ isEqual, filters ]);

  useEffect(() => {
    resetClaims();
  }, []);

  return (
    <ClaimsList Row={Row} RowProps={{ ListRowProps, view }} {...props} />
  );
};
