import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../index';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = () => {
  const classes = useStyles();

  return (
    <ListRow header className={classes.root}>
      <ListRowCell grow flexBasis={columnsWidths.payer}>
        Payer
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.number}>
        Inv#
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.due_date}>
        Due date
      </ListRowCell>
    </ListRow>
  );
};
