import { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, Typography } from '@material-ui/core';
import { Editor } from '../../../../../../components/FormField';
import { StepContext } from '../StepProvider';

export const Body = () => {
  const { dirty } = useFormikContext();
  const { setIsDirty } = useContext(StepContext);

  useEffect(() => {
    setIsDirty(dirty);
  }, [ dirty ]);

  return (
    <Box flexGrow={1}>
      <Typography paragraph variant="h4" color="secondary">
        Discussion
      </Typography>

      <Editor name="forms.discussion.text" margin="normal" />
    </Box>
  );
};
