import { useContext } from 'react';
import { List, MenuItem, ListItemIcon, ListItemText, SvgIcon } from '@material-ui/core';
import {
  mdiTrashCanOutline,
  mdiEmailArrowRightOutline,
  mdiBriefcaseCheckOutline,
  mdiAccountArrowLeftOutline,
  mdiTagPlusOutline,
  mdiReplyAllOutline,
  mdiReplyOutline
} from '@mdi/js';
import { Icon } from '@mdi/react';
import { hasRole } from '../../../../../../../utils/hasRole';
import { AccountsContext } from '../../../../AccountsContext';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { EmailsForwardModal } from '../../../../EmailsClient/EmailsForwardModal';
import { EmailsReplyModal } from '../../../../EmailsClient/EmailsReplyModal';
import { EmailsAttachToPatientModal } from '../../../EmailsAttachToPatientModal';
import { EmailsAttachToCaseModal } from '../../../EmailsAttachToCaseModal';
import { EmailsAttachToTagsModal } from '../../../EmailsAttachToTagsModal';
import { SavedEmailsContext } from '../../../SavedEmailsContext';

export const Menu = ({ message, recalculateHeight = () => {}, onClose }) => {
  const {
    addCaseToMessages,
    deleteCaseFromMessages,
    addPatientToMessages,
    deletePatientFromMessages,
    addTagsToMessages,
    deleteTagsFromMessages,
    replaceTagsToMessages,
    deleteMessages
  } = useContext(SavedEmailsContext);
  const { accounts } = useContext(AccountsContext);
  const { openModal } = useModal();

  const openForwardEmailModal = () => {
    openModal(EmailsForwardModal, {
      payload: {
        folderID: message.folder_id,
        message
      },
      onModalResolved: () => {
        recalculateHeight();
      }
    });

    onClose();
  };

  const attachToCase = () => {
    openModal(EmailsAttachToCaseModal, {
      payload: {
        emailIDs: [ message.id ],
        onCaseAdd: addCaseToMessages,
        onCaseDelete: deleteCaseFromMessages
      },
      onModalResolved: () => {
        recalculateHeight();
      }
    });

    onClose();
  };

  const attachToPatient = () => {
    openModal(EmailsAttachToPatientModal, {
      payload: {
        emailIDs: [ message.id ],
        onPatientAdd: addPatientToMessages,
        onPatientDelete: deletePatientFromMessages
      },
      onModalResolved: () => {
        recalculateHeight();
      }
    });

    onClose();
  };

  const attachToTags = () => {
    openModal(EmailsAttachToTagsModal, {
      payload: {
        emailIDs: [ message.id ],
        onTagsAdd: addTagsToMessages,
        onTagsDelete: deleteTagsFromMessages,
        onTagsReplace: replaceTagsToMessages
      },
      onModalResolved: () => {
        recalculateHeight();
      }
    });

    onClose();
  };

  const openReplyEmailModal = () => {
    openModal(EmailsReplyModal, {
      payload: {
        accounts,
        disableReplayAll: true,
        replyTo: message
      },
      onModalResolved: () => {
        recalculateHeight();
      }
    });
  };

  const openReplyAllEmailModal = () => {
    openModal(EmailsReplyModal, {
      payload: {
        accounts,
        replyTo: message
      }
    });
  };

  const deleteMessage = () => {
    deleteMessages([ message.id ]);
    onClose();
  };

  return (
    <List>
      <MenuItem onClick={openForwardEmailModal}>
        <ListItemIcon>
          <SvgIcon>
            <Icon path={mdiEmailArrowRightOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Forward" />
      </MenuItem>

      <MenuItem disabled={!accounts?.length} onClick={openReplyAllEmailModal}>
        <ListItemIcon>
          <SvgIcon>
            <SvgIcon><Icon path={mdiReplyAllOutline} /></SvgIcon>
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Reply all" />
      </MenuItem>

      <MenuItem disabled={!accounts?.length} onClick={openReplyEmailModal}>
        <ListItemIcon>
          <SvgIcon>
            <SvgIcon><Icon path={mdiReplyOutline} /></SvgIcon>
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Reply" />
      </MenuItem>

      {!hasRole('client', 'patient') &&
        <>
          <MenuItem onClick={attachToCase}>
            <ListItemIcon>
              <SvgIcon>
                <Icon path={mdiBriefcaseCheckOutline} />
              </SvgIcon>
            </ListItemIcon>

            <ListItemText primary="Attache to case" />
          </MenuItem>

          <MenuItem onClick={attachToPatient}>
            <ListItemIcon>
              <SvgIcon>
                <Icon path={mdiAccountArrowLeftOutline} />
              </SvgIcon>
            </ListItemIcon>

            <ListItemText primary="Attache to patient" />
          </MenuItem>

          <MenuItem divider onClick={attachToTags}>
            <ListItemIcon>
              <SvgIcon>
                <Icon path={mdiTagPlusOutline} />
              </SvgIcon>
            </ListItemIcon>

            <ListItemText primary="Change tags" />
          </MenuItem>

          <MenuItem onClick={deleteMessage}>
            <ListItemIcon>
              <SvgIcon>
                <Icon path={mdiTrashCanOutline} />
              </SvgIcon>
            </ListItemIcon>

            <ListItemText primary="Delete" />
          </MenuItem>
        </>
      }
    </List>
  );
};
