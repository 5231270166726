import { useFormikContext } from 'formik';
import {
  Grid,
  Hidden,
  Typography,
  FormControlLabel
} from '@material-ui/core';
import { Checkbox, PhoneField, TextField } from '../../FormField';
import { DoiForm } from '../forms';

export const InsuranceInfoPI = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleToggleCheckbox = (event) => {
    const checked = event.target.checked;

    if (checked) {
      setFieldValue('attorney_first_name', null);
      setFieldValue('attorney_last_name', null);
      setFieldValue('attorney_phone', null);
      setFieldValue('attorney_email', null);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography color="secondary" variant="h5">
          Attorney
        </Typography>
      </Grid>

      <DoiForm />

      <Grid item xl={4} lg={4} md={8} sm={6} xs={12}>
        <TextField
          name="primary_insurance_name"
          label="Law Office"
        />
      </Grid>

      <Hidden only={[ 'sm', 'xs' ]}>
        <Grid item xl={8} lg={8} md={4} />
      </Hidden>

      <Grid item xs={12}>
        <FormControlLabel
          label="Unknown Attorney"
          control={
            <Checkbox
              color="primary"
              name="attorney_is_unknown"
              onChange={handleToggleCheckbox}
            />
          }
        />
      </Grid>

      {!values.attorney_is_unknown &&
        <>
          <Grid item xl={3} md={3} sm={6} xs={12}>
            <TextField
              disabled={!!values.attorney_is_unknown}
              required={!values.attorney_is_unknown}
              name="attorney_first_name"
              label="Attorney First Name"
            />
          </Grid>

          <Grid item xl={3} md={3} sm={6} xs={12}>
            <TextField
              disabled={!!values.attorney_is_unknown}
              required={!values.attorney_is_unknown}
              name="attorney_last_name"
              label="Attorney Last Name"
            />
          </Grid>

          <Grid item xl={3} md={3} sm={6} xs={12}>
            <PhoneField
              name="attorney_phone"
              label="Phone"
            />
          </Grid>

          <Grid item xl={3} md={3} sm={6} xs={12}>
            <TextField
              name="attorney_email"
              label="Email"
            />
          </Grid>
        </>
      }
    </>
  );
};
