import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.LOGIN_SESSIONS_FETCH_REQUEST]: (state) => {
    return {
      ...state,

      isFetching: true
    };
  },

  [types.LOGIN_SESSIONS_FETCH_SUCCESS]: (
    { filter, loginSessions, ...state },
    { data: newLoginSessions, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      loginSessions: page > 1 ? (
        loginSessions.filter(({ id }) =>
          !newLoginSessions.find((loadedLoginSession) => id === loadedLoginSession.id)).concat(newLoginSessions)
      ) : (
        newLoginSessions
      )
    };
  },

  [types.ADD_LOGIN_SESSION]: (state, loginSession) => {
    const { pagination, filter, loginSessions, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((loginSessions.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      loginSessions: [ loginSession, ...loginSessions ]
    };
  },

  [types.DELETE_LOGIN_SESSION]: ({ pagination, filter, loginSessions, ...state }, deletedLoginSession) => {
    const total = pagination.total - 1;
    const page = Math.ceil((loginSessions.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      loginSessions: loginSessions.filter((loginSession) => loginSession.id !== deletedLoginSession.id)
    };
  },

  [types.UPDATE_LOGIN_SESSION]: ({ loginSessions, ...state }, updatedLoginSession) => {
    return {
      ...state,

      loginSessions: loginSessions.map((loginSession) => {
        return updatedLoginSession.id === loginSession.id ? updatedLoginSession : loginSession;
      })
    };
  },

  [types.RESET_LOGIN_SESSIONS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  }
});
