import * as payerApi from '../../../../../../api/codes/payer';

export const transformToOption = (item) => item && ({
  value: item,
  label: item
});

export const fetchProviderIDUsed = (search, { page }) => {
  return payerApi.fetchProviderIDUsed({ params: { search, page } })
    .then(({ data }) => data.map(transformToOption));
};

export const fetchInsuranceType = (search, { page }) => {
  return payerApi.fetchInsuranceType({ params: { search, page } })
    .then(({ data }) => data.map(transformToOption));
};

export const transformClassToOption = (item) => item && ({
  value: item.id,
  label: `${item.code} - ${item.description}`
});

export const transformDataToOption = (item) => item && ({
  value: item.id,
  label: item.name,
  data: {
    value: item.id,
    label: item.name
  }
});

