import { api } from '../api';

export const fetchOffices = (config = {}) => {
  return api.get('/offices', config).then(({ data }) => data);
};

export const fetchOffice = (id) => {
  return api.get(`/offices/${id}`).then(({ data }) => data);
};

export const createOffice = (data) => {
  return api.post('/offices', data).then(({ data }) => data).catch((error) => {
    throw error;
  });
};

export const updateOffice = ({ id, ...data }) => {
  return api.put(`/offices/${id}`, data).then(({ data }) => data).catch((error) => {
    throw error;
  });
};

export const deleteOffice = (id) => {
  return api.delete(`/offices/${id}`)
    .then(({ data: { data } }) => data)
    .catch((error) => {
      throw error;
    });
};

export const massDeleteOffices = (IDs) => {
  return api.delete('/offices', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
