import { useContext } from 'react';
import { Checkbox, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { CodesContext } from '../../../CodesContext';
import { PosCodeFormModal } from '../../../PosCodeFormModal';
import { columnsWidthsLg, columnsWidthsSm, minRowHeight } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ code }) => {
  const classes = useStyles();
  const {
    selectedCodesIDs,
    toggleItemSelection,
    deleteCode,
    massInactiveCodes,
    massActiveCodes
  } = useContext(CodesContext);
  const { openModal } = useModal();
  const isSelected = selectedCodesIDs.indexOf(code.id) !== -1;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  const handleDeleteCode = () => deleteCode(code);

  const handleUpdateCode = () => {
    openModal(PosCodeFormModal, {
      payload: {
        initialValues: code
      }
    });
  };

  const toggleActiveItem = () => {
    if (code.deactivated_at) {
      massActiveCodes([ code.id ]);
    } else {
      massInactiveCodes([ code.id ]);
    }
  };

  const handleItemSelection = (event) => {
    event.preventDefault();

    toggleItemSelection(code.id);
  };

  return (
    <ListRow minHeight={minRowHeight} selected={isSelected}>
      <Checkbox className={classes.checkbox} checked={isSelected} onClick={handleItemSelection}/>

      <ListRowCell noWrap flexBasis={columnsWidths.procedure_code}>
        {code.code}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.description}>
        {code.description}
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
        {code.deactivated_at &&
          <Tooltip title="Edit code">
            <IconButton
              color="primary"
              variant="contained"
              onClick={handleUpdateCode}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        }

        <Tooltip title={code.deactivated_at ? 'Activate code' : 'Deactivate code'}>
          <IconButton
            variant="contained"
            color={code.deactivated_at ? 'warning' : 'success'}
            onClick={toggleActiveItem}
          >
            {code.deactivated_at ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete code">
          <IconButton
            variant="contained"
            color="error"
            onClick={handleDeleteCode}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListRowCell>
    </ListRow>
  );
};
