import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import cn from 'classnames';
import {
  makeStyles,
  IconButton,
  Typography,
  Grid,
  Box,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/EditOutlined';
import NotesIcon from '@material-ui/icons/Notes';
import { getUserFullName } from '../../../../../../helpers/users';
import { roleLabelsMap } from '../../../../../../components/users/roleLabelsMap';
import { getUserOldName } from '../../../../../../helpers/users/getUserOldName';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { fetchProfile } from '../../../../../../store/dashboard/profile';
import { setUsersLastGlobalAction } from '../../../../../../store/globalActions';
import * as userApi from '../../../../../../api/users';
import { Tooltip } from '../../../../../../components/Tooltip';
import { DriverLicensesPreview } from '../../../../../../components/users/DriverLicensesPreview';
import { ReadonlyCheckbox } from '../../../../../../components/ReadonlyCheckbox';
import { InviteButton } from '../../../../../../components/users/InviteButton';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserSSN, Avatar } from '../../../../../../components/users';
import { Contact } from '../../../../../../components/Contact';
import { Route } from '../../../../../../components/router';
import { Fab } from '../../../../../../components/Fab';
import { AddEmailModal } from '../../../../../../components/users/AddEmailModal';
import { SipInfo } from '../../../../ProfilePage/LeftSidebar';
import { UserContext } from '../../UserProvider';
import * as types from '../../UserProvider/types';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const UserInfo = ({ openUserInfo = false, toggleInfoOpen = () => {} }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const theme = useTheme();
  const { user, ...userContext } = useContext(UserContext);
  const currentUser = useSelector(({ profile }) => profile.user);
  const isExistLicense = user?.driver_license?.front?.url || user?.driver_license?.back?.url;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const sipNumber = user?.sipUser?.numbers?.join(', ');

  const goEditUserPage = () => {
    if (currentUser?.id === user.id) {
      history.push('/profile/edit');
    } else {
      history.push(`/members/users/${user.id}/edit`);
    }
  };

  const fetchUser = () => {
    dispatch(fetchProfile());
  };

  const openLicensesPreview = () => {
    openModal(DriverLicensesPreview, {
      payload: {
        user,
        front: user?.driver_license?.front?.url || null,
        back: user?.driver_license?.back?.url || null,
        onLicensesUpdate: userContext?.fetchUser || fetchUser
      }
    });
  };

  const handleUploadAvatar = (formData) => {
    return new Promise((resolve) => {
      userApi.uploadAvatar(formData).then((avatar) => {
        userApi.updateUser({ ...user, avatar_id: avatar.id }).then((data) => {
          dispatch(setUsersLastGlobalAction({ type: types.SET_PROFILE_DATA, payload: data }));
          resolve();
        }).catch(({ data: { message } }) => {
          message && enqueueSnackbar(message, { variant: 'error' });
        });
      });
    });
  };

  const addEmail = () => {
    openModal(AddEmailModal, {
      payload: {
        userID: user.id
      },

      onModalResolved: (user) => {
        userContext.updateUser(user);
      }
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.userHeader}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isMobile && openUserInfo ? 'flex-end' : 'space-between'}
          color="info.contrastText"
        >
          {!openUserInfo && isMobile && (
            <Typography variant="h4" color="inherit">{getUserFullName(user)}</Typography>
          )}

          <Box display="flex" alignItems="center">
            <Route path={[ '/profile', '/members/users/:userID' ]}>
              {hasRole(rolesMap.admin, rolesMap.supervisor) &&
                <IconButton className={classes.actionButton} onClick={goEditUserPage}>
                  <EditIcon fontSize="small" />
                </IconButton>
              }
            </Route>

            {isMobile && (
              <Box px={1}>
                <Tooltip title="Toggle user info">
                  <IconButton
                    color="primary"
                    onClick={toggleInfoOpen}
                  >
                    {openUserInfo ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>

        <div className={classes.userBirthdayDate}>
          <Box ml={1}>
            <Typography variant="h5">
              {moment(user?.birthday).format('L')}
            </Typography>

            <Typography color="primary" variant="button">
              {user.gender}
            </Typography>
          </Box>

          <Typography color="inherit" variant="h5">
            {user?.birthday
              ? moment().diff(moment(user?.birthday), 'years') + ' y.o.'
              : '-'
            }
          </Typography>
        </div>

        <div className={classes.userInfo}>
          <div className={classes.avatarWrapper}>
            <div className={classes.avatar}>
              <Avatar
                user={user}
                onSubmit={handleUploadAvatar}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.mainContent}>
        <div className={classes.activeContent}>
          <ReadonlyCheckbox
            variant="h5"
            iconColor={user.is_active ? 'success' : 'warning'}
            color={user.is_active ? 'success' : 'warning'}
            title={user.is_active ? 'Active' : 'Inactive'}
            checked={!!user.is_active}
          />
        </div>

        {isExistLicense &&
          <div className={cn(classes.activeContent, classes.activeContent_scanButton)}>
            <Fab size="small" variant="extended" color="primary" onClick={openLicensesPreview}>
              View ID
            </Fab>
          </div>
        }

        <Box mt={isExistLicense ? 1 : 3}>
          <Typography variant="h6" align="center">
            {getUserFullName(user)}
          </Typography>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" className={classes.role}>
                {roleLabelsMap[user.role]}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">SSN</Typography>
            </Grid>

            <Grid item xs={8}>
              <UserSSN isShowable={hasRole('admin', 'supervisor')} user={user} />
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">Profile Email</Typography>
            </Grid>

            <Grid item xs={8}>
              <Box display="flex" alignItems="center">
                <Contact variant="h5" type="mailto" contact={user?.email} />

                {user?.email ? (
                  <InviteButton user={user} />
                ) : (
                  <Box m={1}>
                    <Fab
                      variant="extended"
                      size="small"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={addEmail}
                    >
                      Add
                    </Fab>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">Previous Name</Typography>
            </Grid>

            <Grid item xs={8}>
              {!!getUserOldName(user)?.length
                ? <Typography variant="h5">{getUserOldName(user)}</Typography>
                : <Typography color="textSecondary">No info.</Typography>
              }
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">Previous Email</Typography>
            </Grid>

            <Grid item xs={8}>
              {user.old_email
                ? <Typography variant="h5">{user.old_email}</Typography>
                : <Typography color="textSecondary">No info.</Typography>
              }
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">Phone</Typography>
            </Grid>

            <Grid item xs={8}>
              <Contact
                variant="h5"
                type="tel"
                contact={user.phone_number}
              />
            </Grid>

            {sipNumber && (
              <>
                <Grid item xs={4}>
                  <Typography color="textSecondary">Extension:</Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography variant="h5">
                    {sipNumber}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Grid container spacing={1} component={Box} pt={3}>
            <Grid item xs={4}>
              <Typography color="textSecondary">Mob number</Typography>
            </Grid>

            <Grid item xs={8}>
              <Contact
                variant="h5"
                type="tel"
                contact={user.mobile_phone_number}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">Fax</Typography>
            </Grid>

            <Grid item xs={8}>
              <Contact
                variant="h5"
                type="tel"
                contact={user.fax}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">Address</Typography>
            </Grid>

            <Grid item xs={8}>
              {(user?.home?.full_address || user.full_address)
                ? <Typography variant="h5">{user?.home?.full_address || user.full_address}</Typography>
                : <Typography color="textSecondary">No info.</Typography>
              }
            </Grid>

            <SipInfo user={user} />
          </Grid>

          <Grid container component={Box} mt={3}>
            <Grid item>
              <Typography variant="subtitle2">
                <Box component="span" mr={0.5}>
                  <NotesIcon fontSize="small" color="inherit" />
                </Box>
                Note
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography color="textSecondary">
                {user?.home?.note || 'No info.'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
