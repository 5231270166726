import { makeStyles, Typography } from '@material-ui/core';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { CurrencyField, DatePicker } from '../../../../../../../components/FormField';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths, minRowHeight } from '../../CreatePayment';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const PaymentRow = ({ paidDate, isOverdraftPage, invoice, total, balance }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <ListRow className={classes.row} minHeight={minRowHeight}>
      <ListRowCell grow flexBasis={columnsWidths.invoice_date}>
        {paidDate || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.due_on}>
        {isOverdraftPage ?
          <Typography color="secondary">
            {invoice.due_date && moment(invoice.due_date).format('L')}
          </Typography>
          :
          <DatePicker
            outputFormat="YYYY-MM-DD"
            name="due_date"
            label="Due on"
            className={cn(classes.invoiceInfoField, classes.invoiceInfoField_date)}
          />
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        <CurrencyFormat
          value={total}
          className={classes.balance}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount}>
        {isOverdraftPage ?
          <CurrencyFormat value={values.amount} className={classes.balance}/>
          :
          <CurrencyField
            name="amount"
            label="Amount"
            className={classes.invoiceInfoField}
          />
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.balance}>
        <CurrencyFormat value={balance} className={classes.balance} />
      </ListRowCell>
    </ListRow>
  );
};
