import { activityActionMap } from '../activitiesHelpers';
import { NewFileActivity } from '../fields';
import { getActivityComponent } from './actionTypesMap';

export const taskActionTypesMap = {
  [activityActionMap.createTask]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateTask]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteTask]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createCaseTask]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateCaseTask]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteCaseTask]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createTaskFile]: ({ file }) => {
    return [ <NewFileActivity file={file} /> ];
  },

  [activityActionMap.deleteTaskFile]: ({ file }) => {
    return [ <NewFileActivity disableAttach file={file} /> ];
  },

  [activityActionMap.createTaskTodoItem]: (payload) => (
    getActivityComponent({ payload, logName: activityActionMap.todoItem })
  ),

  [activityActionMap.updateTaskTodoItem]: (payload) => (
    getActivityComponent({ payload, logName: activityActionMap.todoItem })
  ),

  [activityActionMap.deleteTaskTodoItem]: (payload) => (
    getActivityComponent({ payload, logName: activityActionMap.todoItem, isDeleted: true })
  )
};
