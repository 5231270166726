import { useState, useEffect } from 'react';
import { Dialog, Typography, Button } from '@material-ui/core';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import { TextField } from '../TextField';
import { useVoiceRecognition } from '../../../../utils/useVoiceRecognition';
import { ModalHeader, ModalBody, ModalContainer, ModalFooter } from '../../../Modal';

export const VoiceRecognitionModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve
}) => {
  const { isRecognitionInProgress, recognizedText, lastRecognizedText, startRecognition } = useVoiceRecognition();
  const [ resultText, setResultText ] = useState(recognizedText);

  const resolveModal = () => {
    handleModalResolve(resultText);
  };

  const handleChange = ({ target: { value } }) => {
    setResultText(value);
  };

  useEffect(() => {
    startRecognition();
  }, []);

  useEffect(() => {
    if (!isRecognitionInProgress) {
      setResultText((resultText) => {
        const resultHasSpace = resultText.lastIndexOf(' ') === resultText.length;
        const lastHasSpace = lastRecognizedText.indexOf(' ') === 0;
        const isNeedSpace = resultText && lastRecognizedText && !resultHasSpace && !lastHasSpace;

        return resultText + (isNeedSpace ? ' ' : '') + lastRecognizedText;
      });
    }
  }, [ isRecognitionInProgress, lastRecognizedText ]);

  return (
    <Dialog disableEnforceFocus maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <ModalHeader icon={<RecordVoiceOverOutlinedIcon />} onClose={handleModalReject}>
          Voice Recognition
        </ModalHeader>

        <ModalBody>
          {recognizedText ? (
            <TextField
              multiline
              withoutFormik
              disableVoiceRecognition
              value={resultText + lastRecognizedText}
              onChange={handleChange}
            />
          ) : (
            <Typography align="center">
              Please start your speech to transcribe it to text
            </Typography>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleModalReject}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={resolveModal}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Dialog>
  );
};
