import { makeStyles } from '@material-ui/core';
import { SearchFilesContextProvider } from './SearchFilesContext';
import { FiltersBar } from './FiltersBar';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SearchDocumentsPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SearchFilesContextProvider>
        <FiltersBar />

        <div className={classes.list}>
          <List />
        </div>
      </SearchFilesContextProvider>
    </div>
  );
};
