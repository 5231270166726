export const styles = ({ spacing, palette }) => ({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:' center',
    height: 100,
    marginTop: spacing(2),
    border: `1px dashed ${palette.grey[200]}`,
    backgroundColor: palette.grey[25],
    padding: spacing(1.5, 2)
  },

  boxIcon: {
    fontSize: 44
  },

  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1
  }
});
