import { useContext } from 'react';
import { Paper } from '@material-ui/core';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { HistoriesContext } from '../../HistoriesProvider';
import {
  columnsWidths,
  widthBreakpointLarge,
  widthBreakpointMedium,
  widthBreakpointSmall,
  widthBreakpointXS,
  widthBreakpointXXS
} from '../List';

export const TableHeader = ({ width }) => {
  const { selectedIDs, allItemsIsSelected, toggleAllItemsSelection } = useContext(HistoriesContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        {!hasRole('client', 'patient') &&
          <ListRowCheckbox
            indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
            checked={allItemsIsSelected()}
            onClick={toggleAllItemsSelection}
          />
        }

        <ListRowCell grow flexBasis={columnsWidths.date}>
          Date/Time
        </ListRowCell>

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.age}>
            Age
          </ListRowCell>
        }

        {width > widthBreakpointXXS &&
          <ListRowCell flexBasis={columnsWidths.temperature}>
            Body temperature
          </ListRowCell>
        }

        {width > widthBreakpointSmall &&
          <ListRowCell flexBasis={columnsWidths.blood_type}>
            Blood type
          </ListRowCell>
        }

        {width > widthBreakpointXS &&
          <ListRowCell flexBasis={columnsWidths.heart_rate}>
            Heart rate (bmp)
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.pressure}>
          Pressure sys/dia (mmHg)
        </ListRowCell>

        {width > widthBreakpointMedium &&
          <ListRowCell flexBasis={columnsWidths.weight}>
            Weight
          </ListRowCell>
        }

        {width > widthBreakpointSmall &&
          <ListRowCell flexBasis={columnsWidths.bmi}>
            BMI
          </ListRowCell>
        }

        {!hasRole('client', 'patient') && <ListRowCell flexBasis={columnsWidths.actions} />}
      </ListRow>
    </Paper>
  );
};
