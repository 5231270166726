export const filterFieldsMap = {
  code: 'code',
  name: 'name',
  description: 'description',
  is_active: 'is_active',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.code]: 'Code',
  [filterFieldsMap.name]: 'Drug name',
  [filterFieldsMap.is_active]: 'Status',
  [filterFieldsMap.description]: 'Description',
  [filterFieldsMap.orderBy]: 'Order By'
};
