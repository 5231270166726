import { useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { getUserFullName } from '../../../helpers/users';
import { ListRow, ListRowCell } from '../../../components/ListRow';
import { Loader } from '../../../components/Loader';
import { columnsWidths } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item: appointment, isLoaded, recalculateHeight, cache, cacheRef }) => {
  const classes = useStyles();
  const patientLastName = appointment?.patient?.last_name?.[0];

  useEffect(() => {
    cacheRef.current = cache;
  }, []);

  useEffect(() => {
    recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow px={1}>
      <ListRowCell grow flexBasis={columnsWidths.time} className={classes.responsiveRow}>
        {moment.unix(appointment.appointment_at).format('LT')}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.patient} className={classes.responsiveRow}>
        {getUserFullName({ ...appointment?.patient, last_name: patientLastName ? patientLastName + '.' : null })}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.provider} className={classes.responsiveRow}>
        {getUserFullName(appointment?.appointment_book?.provider)}
      </ListRowCell>
    </ListRow>
  );
};
