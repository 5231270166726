import moment from 'moment';
import { Yup } from '../../../../utils/validation';

const getFollowingTests = () => ({
  value: Yup.boolean().nullable(),
  what_facility: Yup.string().nullable().when('value', {
    is: (value) => !!value,
    then: Yup.string().nullable().required()
  }),
  body_parts: Yup.string().nullable().when('value', {
    is: (value) => !!value,
    then: Yup.string().nullable().required()
  }),
  date: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'month'), 'Date must be earlier than today')
});

export const patientHistoryValidationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    patient_history: Yup.object().shape({
      have_you_attended_any_of_the_following: Yup.object().shape({
        physical_therapy: Yup.boolean(),
        chiropractor: Yup.boolean(),
        pool_therapy: Yup.boolean(),
        acupuncture: Yup.boolean(),
        none: Yup.boolean()
      }).test('at-least-one', 'At least one required', (value) =>
        !!(value.physical_therapy || value.chiropractor || value.pool_therapy || value.acupuncture || value.none)
      ),
      how_often_and_when: Yup.string().nullable().when('have_you_attended_any_of_the_following', {
        is: (value) => value.none !== true,
        then: Yup.string().nullable().required()
      }),
      is_this_injury_related_to_the_accident: Yup.string().nullable(),
      have_you_undergone_any_diagnostic_testing_mri_ct_emg_labs: Yup.string().nullable()
        .oneOf([ 'MRI', 'CT', 'EMG', 'LABS', 'No' ], 'Must be one of the following values: MRI, CT, EMG, LABS, No')
        .required(),
      are_you_using_any_of_the_follwing: Yup.object().shape({
        bone_stimulator: Yup.mixed(),
        tens_unit: Yup.mixed(),
        cane: Yup.mixed(),
        walker: Yup.mixed(),
        other: Yup.mixed(),
        none: Yup.mixed()
      }).test('at-least-one', 'At least one required', (value) =>
        !!(value.bone_stimulator || value.tens_unit || value.cane || value.walker || value.other || value.none)
      ),
      dominant_hand: Yup.string().nullable().required(),
      what_body_parts_are_injured: Yup.string().nullable().required(),
      please_rate_your_pain: Yup.object().shape({
        at_rest: Yup.number().min(1).nullable().required(),
        at_worst: Yup.number().min(1).nullable().required()
      }),
      is_the_pain: Yup.object().shape({
        worsening: Yup.boolean(),
        occasional: Yup.boolean(),
        stabbing: Yup.boolean(),
        stable: Yup.boolean(),
        sharp: Yup.boolean(),
        throbbing: Yup.boolean(),
        improving: Yup.boolean(),
        dull: Yup.boolean(),
        constant: Yup.boolean(),
        aching: Yup.boolean()
      }).test('at-least-one', 'At least one required', (value) =>
        !!(value.worsening || value.occasional || value.stabbing || value.stable
          || value.sharp || value.throbbing || value.improving || value.dull || value.constant || value.aching
        )
      ),
      what_symptoms_are_you_experiencing: Yup.object().shape({
        locking: Yup.boolean(),
        catching: Yup.boolean(),
        giving_away: Yup.boolean(),
        popping: Yup.boolean(),
        grinding: Yup.boolean(),
        bruising: Yup.boolean(),
        numbness: Yup.boolean(),
        tingling: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        })
      }).test('at-least-one', 'At least one required', (value) =>
        !!(value.locking || value.catching || value.giving_away || value.popping
          || value.grinding || value.bruising || value.numbness || value.tingling || value?.other?.value
        )
      ),
      what_if_anything_makes_your_symptoms_better: Yup.object().shape({
        rest: Yup.boolean(),
        cold_therapy: Yup.boolean(),
        activity: Yup.boolean(),
        heat_therapy: Yup.boolean(),
        medication: Yup.boolean(),
        other: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        })
      }).test('at-least-one', 'At least one required', (value) =>
        !!(value.rest || value.cold_therapy || value.activity || value.heat_therapy
          || value.medication || value?.other?.value
        )
      ),
      what_if_anything_makes_your_symptoms_worse: Yup.object().shape({
        inactivity: Yup.boolean().nullable(),
        exercise: Yup.object().shape({
          value: Yup.boolean().nullable(),
          description: Yup.string().nullable()
        }),
        other: Yup.object().shape({
          value: Yup.boolean().nullable(),
          description: Yup.string().nullable()
        })
      }).test('at-least-one', 'At least one required', (value) =>
        !!(value.inactivity || value.exercise.value || value?.other?.value)
      ),
      have_you_seen_another_physician_for_this_injury: Yup.mixed().required(),
      if_yes_who: Yup.string().nullable().when('have_you_seen_another_physician_for_this_injury', {
        is: (value) => value === 'yes',
        then: Yup.string().nullable().required()
      }),
      what_treatments_have_you_tried: Yup.object().shape({
        nothing: Yup.boolean(),
        physical_therapy: Yup.boolean(),
        exercise: Yup.boolean(),
        acupuncture: Yup.boolean(),
        decreased_activity: Yup.boolean(),
        injections: Yup.boolean(),
        ice: Yup.boolean(),
        chiropractic: Yup.boolean(),
        bracing: Yup.boolean(),
        medications: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        }),
        other: Yup.object().shape({
          value: Yup.boolean(),
          description: Yup.string().nullable()
        })
      }).test('at-least-one', 'At least one required', (value) =>
        !!(value.nothing || value.physical_therapy || value.exercise || value.acupuncture
          || value.decreased_activity || value.injections || value.chiropractic || value.ice || value.bracing
          || value?.other?.value || value?.medications?.value
        )
      ),
      have_you_had_any_of_the_following_tests_studies: Yup.object().shape({
        x_rays: Yup.object().shape(getFollowingTests()),
        mri_scan: Yup.object().shape(getFollowingTests()),
        ct_scan: Yup.object().shape(getFollowingTests()),
        emgncv: Yup.object().shape(getFollowingTests()),
        other: Yup.object().shape(getFollowingTests())
      }),
      recreational_activities: Yup.string().nullable(),
      current_regular_exercise_program: Yup.string().nullable()
    })
  })
});
