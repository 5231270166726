import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, SvgIcon, Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as casesApi from '../../../../../../../../api/cases';
import { CasesSelect } from '../../../../../../../../components/cases/CasesSelect';
import { setCasesLastGlobalAction } from '../../../../../../../../store/globalActions';
import EditIcon from '../../../../../../../../components/icons/square-edit-icone.svg';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../../../../../components/Modal';
import { Loader } from '../../../../../../../../components/Loader';
import * as types from '../../../../CasesContext/types';

export const CaseCrossModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    caseItem = {}
  }
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const updateCase = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.attachCase(caseItem.id, values).then(({ data }) => {
      dispatch(setCasesLastGlobalAction({
        type: types.UPDATE_CASE_IN_LIST,
        payload: { ...caseItem, sub_cases: data }
      }));
      enqueueSnackbar('Case successfully attached', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ data: { errors } = {} }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case not attached', { variant: 'error' });
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          parent_id: null
        }}
        onSubmit={updateCase}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Edit cross-reference information
            </ModalHeader>

            <ModalBody>
              <CasesSelect
                required
                name="parent_id"
                label="Cases"
                placeholder="Select case..."
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
