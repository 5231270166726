import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { fieldsKeysMap } from '../../../../components/users/MedicalInfo';
import { UserContext } from '../../Members/User/UserProvider';
import { columnsMap } from './List';
import { Prescriptions } from './Prescriptions';

export const PrescriptionsContainer = () => {
  const { user } = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const patient = userContext ? userContext?.user : user;
  const hiddenFilterFields = patient ? [ fieldsKeysMap.patient ] : [];
  const hiddenColumns = patient ? [ columnsMap.patient ] : [];
  const filter = patient ? {
    patient_id: patient.id
  } : {};

  return (
    <Prescriptions
      patient={patient}
      filter={filter}
      hiddenFilterFields={hiddenFilterFields}
      hiddenColumns={hiddenColumns}
    />
  );
};

