const pluginName = 'replaceable-data-type';

export const replaceableElementAttrName = pluginName;

export const addReplaceableDataTypePlugin = ({ CKEDITOR, onInit = () => {} }) => {
  CKEDITOR.plugins.get(pluginName) || CKEDITOR.plugins.add(pluginName, {
    requires: 'widget',
    init: (editor) => {
      editor.widgets.add(pluginName, {
        allowedContent: `span[${replaceableElementAttrName}]`,
        requiredContent: `span[${replaceableElementAttrName}]`,
        pathName: replaceableElementAttrName,
        upcast: (element) => {
          return element.attributes.hasOwnProperty(replaceableElementAttrName);
        }
      });

      onInit(editor);

      editor.addFeature(editor.widgets.registered[pluginName]);

      editor.on('paste', (event) => {
        const replaceableDataType = event.data.dataTransfer.getData(replaceableElementAttrName);

        if (!replaceableDataType) {
          return;
        }

        event.data.dataValue = `
          <span ${replaceableElementAttrName}=${replaceableDataType.field}>
            ${replaceableDataType.label}
          </span>
        `;
      });
    }
  });
};

export const initReplaceableDataTypeList = ({ CKEDITOR, listRef }) => {
  const listElement = new CKEDITOR.dom.element(listRef.current);

  listElement.on('dragstart', (event) => {
    const target = event.data.getTarget().getAscendant((element) => {
      const isNodeElement = element.$.nodeType === Node.ELEMENT_NODE;

      return isNodeElement && element.hasAttribute(replaceableElementAttrName);
    }, true);
    const replaceableDataType = target.getAttribute(replaceableElementAttrName);

    CKEDITOR.plugins.clipboard.initDragDataTransfer(event);

    const dataTransfer = event.data.dataTransfer;

    dataTransfer.setData(replaceableElementAttrName, JSON.parse(replaceableDataType));
    dataTransfer.setData('text/html', target.getText());

    if (dataTransfer.$.setDragImage) {
      const image = target.findOne('img');

      if (image) {
        dataTransfer.$.setDragImage(image.$, 0, 0);
      }
    }
  });
};
