import { useContext } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { MessageContent, MessageContext } from '../../../../../../../components/chat';
import { UserLink } from '../../../../../../../components/UserLink';
import { Attachments } from '../../../../../../../components/chat/Attachments';
import { MessageDescription } from '../MessageDescription';
import { useMessageMenu } from './useMessageMenu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MessageBody = ({ disableMenu, onMenuToggled }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ profile }) => profile?.user);
  const { isCurrentUser, dense, message } = useContext(MessageContext);
  const isMyMessage = currentUser.id === message.user?.id;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { openMenu, Menu } = disableMenu ? {} : useMessageMenu({
    disableMenu,
    isCurrentUser,
    message,
    onMenuToggled
  });

  return (
    <div
      className={cn(classes.body, { [classes.bodySmall]: dense })}
      onClick={openMenu}
    >
      {disableMenu || <Menu />}

      {!!(message.body || message.parent?.body || message.parent?.files?.length) &&
        <div
          className={cn(classes.messageText, {
            [classes.myMessageText]: isMyMessage,
            [classes.messageTextSmall]: dense
          })}
        >
          {!!(message.parent?.body || message.parent?.files?.length) &&
            <Box mx={0.5} mb={0.5} display="flex">
              <div className={classes.forwardWrapper}>
                <UserLink
                  noAvatar
                  color={isMyMessage ? 'inherit' : 'primary'}
                  user={message.parent?.user}
                />

                {!!message.parent?.body &&
                  <MessageContent message={message.parent?.body}/>
                }

                <Attachments message={message.parent} />
              </div>
            </Box>
          }

          <Typography variant="body1">
            <MessageContent message={message.body} />
          </Typography>
        </div>
      }

      <Attachments message={message} />
      <MessageDescription />
    </div>
  );
};
