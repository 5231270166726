import { useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, SvgIcon, Typography } from '@material-ui/core';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { mdiAlarm } from '@mdi/js';
import { Icon } from '@mdi/react';
import { CardItem, CardHeader } from '../../../../../../components/Cards';
import { MeasurementsPreviewModal } from '../../../../../../components/medical/forms';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../helpers/dom';
import { hasRole } from '../../../../../../utils/hasRole';
import { UserContext } from '../../../../Members/User/UserProvider';
import { HistoriesContext } from '../../HistoriesProvider';
import { MeasurementsMenu } from '../../MeasurementsMenu';
import { Body } from './Body';

export const Card = ({ item: history = {} }) => {
  const { openModal } = useModal();
  const { selectedIDs, toggleItemSelection } = useContext(HistoriesContext);
  const isSelected = selectedIDs.indexOf(history.id) !== -1;
  const profile = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : profile.user;

  const handleItemSelection = () => {
    toggleItemSelection(history.id);
  };

  const handleOpenPreview = () => {
    openModal(MeasurementsPreviewModal, {
      payload: {
        measurements: history,
        user
      }
    });
  };

  return (
    <CardItem
      selected={!hasRole('client', 'patient') && isSelected}
      py={1}
      px={2}
      onClick={stopPropagation(handleOpenPreview)}
    >
      <CardHeader
        isSelected={isSelected}
        disableCheckBox={hasRole('client', 'patient')}
        disableCardMenu={hasRole('client', 'patient')}
        onItemSelect={handleItemSelection}
        menuComponent={MeasurementsMenu}
        menuComponentProps={{ history }}
      >
        <UserLink variant="h3" user={user}>
          <Box mt={-0.5}>
            <Typography color="textSecondary" variant="caption">
              Age {history.age} y.o.
            </Typography>
          </Box>
        </UserLink>
      </CardHeader>

      <Box px={0.75} py={2}>
        <Body history={history} />
      </Box>

      <Box display="flex" alignItems="center" pb={0.5} px={1.5}>
        <Typography noWrap variant="h5" title={history.date && moment.unix(history.date).format('L, LT')}>
          <Box component="span" mr={1}>
            <InsertInvitationOutlinedIcon fontSize="small" />
          </Box>

          {history.date ? moment.unix(history.date).format('L') : '-'}

          <Box component="span" mx={1}>
            <SvgIcon fontSize="small"><Icon path={mdiAlarm} /></SvgIcon>
          </Box>

          {history.date ? moment.unix(history.date).format('LT') : '-'}
        </Typography>
      </Box>
    </CardItem>
  );
};
