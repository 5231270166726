import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_TYPES_REQUEST]: (state) => {
    return { ...state };
  },

  [types.FETCH_TYPES_SUCCESS]: (state, types) => {
    return {
      ...state,

      isFetched: true,
      types
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_TYPES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.ADD_TYPE]: ({ types, ...state }, type) => {
    return {
      ...state,

      types: [ type, ...types ]
    };
  },

  [types.UPDATE_TYPE]: ({ types, ...state }, updatedType) => {
    return {
      ...state,

      types: types.map((type) => updatedType.id === type.id ? updatedType : type)
    };
  },

  [types.DELETE_TYPE]: (state, typeID) => {
    const { types } = state;
    const isExistedType = !!types.find((type) => typeID === type.id);

    if (!isExistedType) {
      return state;
    }

    return {
      ...state,

      types: types.filter((type) => typeID !== type.id)
    };
  }
});
