export const markerStyles = () => ({
  marker: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)'
  }
});

export const popperStyles = ({ spacing }) => ({
  popperContent: {
    padding: spacing(0.75, 1)
  }
});
