import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal as useModalHook } from 'react-modal-hook';
import { useSnackbar } from 'notistack';
import * as expensesApi from '../../../../../../../api/billing';
import { invoiceBlocksMap } from '../../../../../../../components/billing';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { setFilesLastGlobalAction } from '../../../../../../../store/globalActions';
import { hasRole } from '../../../../../../../utils/hasRole';
import { FilePreviewModal } from '../../../../../files-common';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import * as filesTypes from '../../../../FilesContext/types';
import { ExpensesModal } from '../../ExpensesModal';
import { getFileMenuOptions } from '../getFileMenuOptions';

export const ExpenseContainer = ({
  index,
  width,
  parentPage,
  expenseID,
  expense,
  expenses,
  invoice,
  onUpdateExpenses = () => {},
  onUpdateMainTotal = () => {},
  ExpenseComponent = () => {}
}) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ files, setFiles ] = useState(expense?.files || []);
  const filesLastGlobalAction = useSelector(({ globalActions }) => globalActions.filesLastGlobalAction);
  const isAbleToDeleteFile = !hasRole(rolesMap.advocate);

  const handleDeleteFile = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        expensesApi.deleteFiles([ id ]).then(() => {
          dispatch(setFilesLastGlobalAction({ type: filesTypes.DELETE_FILES, payload: [ id ] }));
          setFiles(files.filter((item) => item.id !== id));
          enqueueSnackbar('File successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  const [ openFilesPreview, closeFilesPreview ] = useModalHook(({ in: open, onExited }) => (
    <FilePreviewModal
      previewMenuOptions={getFileMenuOptions(handleDeleteFile, isAbleToDeleteFile)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file: files[0],
        files
      }}
    />
  ), [ files.length ]);

  const onDeleteExpense = (index, expense) => () => {
    if (parentPage) {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          expensesApi.massUnassignExpense({
            expense_ids: [ expense?.id ],
            invoice_id: invoice?.id
          }).then(() => {
            const filteredExpenses = expenses.filter((item) => item?.id !== expense?.id);

            onUpdateMainTotal(expense, invoiceBlocksMap.expenses);
            enqueueSnackbar('Expense successfully deleted', { variant: 'success' });
            onUpdateExpenses(filteredExpenses);
          }).catch(() => {
            enqueueSnackbar('Expense is not deleted', { variant: 'error' });
          });
        }
      });
    } else {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          onUpdateMainTotal(expense, invoiceBlocksMap.expenses);
          onUpdateExpenses(expenses.filter((item, i) => i !== index));
          enqueueSnackbar('Expense successfully deleted', { variant: 'success' });
        }
      });
    }
  };

  const onUpdateExpense = (expense) => () => {
    expense.files = files;

    openModal(ExpensesModal, {
      payload: {
        initialValues: {
          title: 'Edit expense',
          invoice_id: invoice.id,
          expense,
          index
        },
        onModalResolved: (data) => {
          data && onUpdateExpenses([ [ ...expenses, data ] ]);
        }
      }
    });
  };

  useEffect(() => {
    if (filesLastGlobalAction) {
      if (expenseID === expense.id) {
        const expenseIndex = expenses.findIndex(({ id }) => id === expenseID);

        if (filesLastGlobalAction.type === filesTypes.FILE_UPLOAD_SUCCESS) {
          const expenseFile = expense?.files?.find(({ id }) => id === filesLastGlobalAction?.payload?.file?.id);

          expenses[expenseIndex] = {
            ...expense,

            files: expenseFile ? files : [ ...files, filesLastGlobalAction?.payload?.file ]
          };
          onUpdateExpenses(expenses);
          setFiles(expenseFile ? files : [ ...files, filesLastGlobalAction?.payload?.file ]);
        }

        if (filesLastGlobalAction.type === filesTypes.DELETE_FILES) {
          if (files.length) {
            expenses[expenseIndex] = {
              ...expense,

              files: files.filter(({ id }) => !filesLastGlobalAction?.payload?.includes(id))
            };
            onUpdateExpenses(expenses);
            setFiles(files.filter(({ id }) => !filesLastGlobalAction?.payload?.includes(id)));
          }
        }
      }
    }
  }, [ filesLastGlobalAction ]);

  return (
    <ExpenseComponent
      index={index}
      files={files}
      width={width}
      expense={expense}
      parentPage={parentPage}
      onUpdateExpense={onUpdateExpense}
      onDeleteExpense={onDeleteExpense}
      openFilesPreview={openFilesPreview}
    />
  );
};
