export const styles = ({ spacing, palette: { grey }, typography: { pxToRem } }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2.5),
    background: grey[50],
    borderBottom: `1px dashed ${grey[300]}`,

    '& > * + *': {
      flexShrink: 0,
      marginLeft: spacing(2)
    }
  },

  left: {
    display: 'flex',
    flexGrow: 1
  },

  info: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 220,
    marginRight: spacing(2)
  },

  info__label: {
    marginRight: spacing(2.5)
  },

  ownerIcon: {
    fontSize: pxToRem(20)
  },

  info__editButton: {
    marginLeft: spacing(0.25)
  }
});
