import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import * as payerApi from '../../../../api/codes/payer';
import { Select } from '../Select';

export const transformClaimFillingIndicatorToOption = (indicator) => indicator && ({
  value: indicator.id,
  label: indicator.name,
  data: indicator
});

const fetchClaimFillingIndicators = (params) => fetchDataForAsyncSelect((search, { page }) => {
  return payerApi.fetchClaimFilingIndicators({ params: { search, page, ...params } });
}, {
  transformDataToOption: transformClaimFillingIndicatorToOption
});

export const ClaimFillingIndicatorsSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      name="appointment_book_id"
      label="Claim filling indicator"
      promiseWithOptions={fetchClaimFillingIndicators(params)}
      {...props}
    />
  );
};
