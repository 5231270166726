import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { ContentCard } from '../../../../../components/ContentCard';
import { StatisticsPaymentsFilterContextProvider } from './StatisticsPaymentsFilterContext';
import { HistoryPaymentsFilterContextProvider } from './HistoryPaymentsFilterContext';
import { MainContent as MainUpcomingContent } from './MainUpcomingContent';
import { FiltersBar } from './FiltersBar';
import { MainContent } from './MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PaymentsHistory = ({ filterKey }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  return (
    <HistoryPaymentsFilterContextProvider>
      {isTablet &&
        <Box
          display="flex"
          justifyContent="flex-end"
          py={1}
          px={3}
          bgcolor="common.white"
        >
          {filterOpenButton}
        </Box>
      }

      <div className={classes.root}>
        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <FiltersBar filterKey={filterKey} />
          </FilterBarWrapper>
        </ScrollWrapper>

        <div className={classes.mainContent}>
          <div className={classes.contentLeft}>
            <ContentCard
              fullHeight
              isMovable
              disableContentPaddings
              title="Upcoming payments"
            >
              <StatisticsPaymentsFilterContextProvider>
                <MainUpcomingContent />
              </StatisticsPaymentsFilterContextProvider>
            </ContentCard>
          </div>

          <div className={classes.contentRight}>
            <ContentCard
              fullHeight
              isMovable
              disableContentPaddings
              title="Payment history"
            >
              <StatisticsPaymentsFilterContextProvider>
                <MainContent />
              </StatisticsPaymentsFilterContextProvider>
            </ContentCard>
          </div>
        </div>
      </div>
    </HistoryPaymentsFilterContextProvider>
  );
};
