import { createContext, forwardRef } from 'react';
import T from 'prop-types';
import cn from 'classnames';
import { makeStyles, ListItem, Box } from '@material-ui/core';
import { styles } from './styles';

export const ListRowContext = createContext();

const useStyles = makeStyles(styles);

const propTypes = {
  disabled: T.bool,
  disableBorder: T.bool,
  header: T.bool,
  selected: T.bool,
  minHeight: T.oneOfType([ T.number, T.string ]),
  children: T.node.isRequired
};

export const ListRow = forwardRef(({
  disableBorder,
  disabled,
  button,
  selected,
  header,
  className,

  ...props
}, ref) => {
  const classes = useStyles();

  return (
    <Box
      px={1}
      ref={ref}
      className={cn(
        classes.listRow,
        className,
        {
          [classes.listRow_selected]: selected,
          [classes.listRow_header]: header,
          [classes.listRow_border]: !disableBorder,
          [classes.listRow_button]: button
        }
      )}
      {...props}
    >
      <ListRowContext.Provider value={{ header, disabled }}>
        {props.children}
      </ListRowContext.Provider>
    </Box>
  );
});

ListItem.propTypes = propTypes;
