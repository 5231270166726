import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { setUsersLastGlobalAction } from '../../../../store/globalActions';
import { api } from '../../../../api';
import * as usersApi from '../../../../api/users';
import { Loader } from '../../../../components/Loader';
import { EditUserPage } from '../../../../components/users/EditUserPage';
import * as types from '../../Members/User/UserProvider/types';
import { getFormData } from '../../ProfilePage/insurance';

export const EditUser = () => {
  const [ user, setUser ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const cancelFetch = useRef(() => {});

  const uploadLicense = (values, setSubmitting, setErrors) => {
    const front = (!values?.driver_license?.front?.url && values?.front);
    const back = (!values?.driver_license?.back?.url && values?.back);

    if (front || back) {
      const formData = getFormData({ front: front || '', back: back || '' });

      return usersApi.uploadDriverLicense(values.id, formData).then(() => {
        return updateUser(values, setSubmitting, setErrors);
      }).catch(({ data: { errors } }) => {
        errors && setErrors(errors);
        setSubmitting(false);
        enqueueSnackbar('Profile not updated', { variant: 'error' });
      });
    } else {
      return updateUser(values, setSubmitting, setErrors);
    }
  };

  const onLicenseDelete = ({ id, tags }) => {
    return usersApi.deleteDriverLicense({ id, tags });
  };

  const updateUser = (userData, setSubmitting, setErrors) => {
    usersApi.updateUser(userData).then((data) => {
      userData?.tags?.length && onLicenseDelete({ id: userData.id, tags: userData?.tags });
      dispatch(setUsersLastGlobalAction({ type: types.SET_PROFILE_DATA, payload: data }));
      enqueueSnackbar('User successfully updated', { variant: 'success' });
      history.goBack();
    }).catch(({ data: { errors } }) => {
      errors && setErrors(errors);
      setSubmitting(false);
      enqueueSnackbar('User not updated', { variant: 'error' });
    });
  };

  useEffect(() => {
    usersApi.fetchUser(params?.patientID, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(setUser).catch(({ status }) => {
      if (status === 404) {
        history.push('/404');
      }
    });

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <Loader loading={!user} p={3} render={
      () => (
        <EditUserPage
          user={user}
          onUserUpdate={uploadLicense}
        />
      )}
    />
  );
};
