import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';
import { useSnackbar } from 'notistack';
import * as remindersApi from '../../../../../api/cases/reminders/case-reminders';
import { Yup } from '../../../../../utils/validation';
import { ModalFooter, ModalHeader, ModalBody } from '../../../../Modal';
import { Loader } from '../../../../Loader';
import { CasesSelect } from '../../../CasesSelect';
import { CaseReminderGroupsSelect } from '../../groups';

const initialValues = {
  group_ids: null
};

const validationSchema = Yup.object().shape({
  case_id: Yup.mixed().nullable().required(),
  group_ids: Yup.mixed().nullable().required()
});

export const CreateCaseRemindersModal = ({
  payload: {
    caseId
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const addReminderEvent = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return remindersApi.createCaseReminders({
      ...values,

      group_ids: values.group_ids?.map(({ id }) => id)
    }).then((reminders) => {
      enqueueSnackbar('Reminders successfully created', { variant: 'success' });
      handleModalResolve(reminders);
    }).catch(({ data: { errors } }) => {
      const attachedGroupNames = Object.keys(errors).map((name) => {
        const groupIndex = name.split('group_ids.')[1];

        return values.group_ids[groupIndex]?.name;
      });

      if (attachedGroupNames?.length === 1) {
        enqueueSnackbar('Selected group already applied to the case: ' + attachedGroupNames[0], { variant: 'error' });
      } else if (attachedGroupNames?.length > 1) {
        enqueueSnackbar(
          'Selected groups already applied to the case: ' + attachedGroupNames.join(', '),
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar('Reminders not created', { variant: 'error' });
      }

      if (errors) {
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,

        case_id: caseId
      }}
      validationSchema={validationSchema}
      onSubmit={addReminderEvent}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<AddAlertOutlinedIcon />}
              onClose={handleModalReject}
            >
              Create reminders
            </ModalHeader>

            <ModalBody>
              {!caseId &&
                <CasesSelect
                  required
                  label="Case"
                  name="case_id"
                  margin="dense"
                />
              }

              <CaseReminderGroupsSelect
                required
                multiple
                disableValueTransform
                name="group_ids"
                label="Group"
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
