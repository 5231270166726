import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Box } from '@material-ui/core';
import * as reportsApi from '../../../../../../api/profile';
import { Button } from '../../../../../../components/Button';
import { Loader } from '../../../../../../components/Loader';
import { ReportsContext } from '../../../../../../components/medical/reports';
import { StepContext } from '../StepProvider';
import { validationSchema } from '../validationSchema';
import { Body } from './Body';

export const SixStep = () => {
  const { formValues, handleReset, handleBack } = useContext(StepContext);
  const { doi, updateReport } = useContext(ReportsContext);
  const { enqueueSnackbar } = useSnackbar();

  const updateForm = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return reportsApi.updateDiagnosisReport(values).then(({ data }) => {
      enqueueSnackbar('Successfully update info', { variant: 'success' });
      updateReport(data);
      handleReset();
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...formValues,
        doi
      }}
      validationSchema={validationSchema}
      onSubmit={updateForm}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Box
          noValidate
          component="form"
          display="flex"
          flexDirection="column"
          overflow="hidden"
          onSubmit={handleSubmit}
          height="100%"
        >
          <Body />

          <Box display="flex" alignItems="center" ml="auto" py={2}>
            <Box mr={2}>
              <Button
                color="primary"
                onClick={handleBack}
              >
                Back
              </Button>
            </Box>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  disabled={isSubmitting}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              )}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
};
