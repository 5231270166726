import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Echo } from '../../../utils/echo';
import { MessengerContext } from '../MessengerProvider';

export const useThread = ({
  threadId,
  onMessageCreated = () => {},
  onMessageUpdated = () => {},
  onMessageDeleted = () => {},
  onThreadUpdated = () => {},
  onThreadArchived = () => {},
  onThreadSeen = () => {},
  onThreadLikeChange = () => {}
}) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const { currentThreadId, setCurrentThreadId } = useContext(MessengerContext);
  const threadIdParam = +useParams().threadId;
  const history = useHistory();

  useEffect(() => {
    const usersChanel = Echo.private(`threads.${threadId}`);

    const handleMessageCreated = (data) => {
      onMessageCreated(data);
    };

    const handleMessageUpdated = (data) => {
      onMessageUpdated(data);
    };

    const handleMessageDeleted = (data) => {
      onMessageDeleted(data);
    };

    const handleThreadArchived = ({ thread }) => {
      onThreadArchived(thread);
    };

    const handleThreadAuthorChanged = ({ thread }) => {
      onThreadUpdated(thread);
    };

    const handleThreadLike = ({ thread_id, is_favorite }) => {
      onThreadLikeChange({ thread_id, is_favorite });
    };

    const handleThreadSeen = ({ thread_id, user_id }) => {
      onThreadSeen({ thread_id, user_id });
    };

    const handleParticipantAdded = ({ thread }) => {
      onThreadUpdated(thread);
    };

    const handleParticipantRemoved = ({ thread, participant }) => {
      if (currentUser.id !== participant.user_id) {
        onThreadUpdated(thread);

        return;
      }

      if (threadIdParam === thread?.id) {
        history.push('/messenger');
      } else if (currentThreadId === thread?.id) {
        setCurrentThreadId(null);
      }

      onThreadArchived?.(thread);
    };

    usersChanel
      .listen('.message.created', handleMessageCreated)
      .listen('.message.updated', handleMessageUpdated)
      .listen('.message.deleted', handleMessageDeleted)

      .listen('.thread.archived', handleThreadArchived)
      .listen('.thread.author-changed', handleThreadAuthorChanged)
      .listen('.thread.toggleLike', handleThreadLike)
      .listen('.thread.seen', handleThreadSeen)

      .listen('.participant.added', handleParticipantAdded)
      .listen('.participant.removed', handleParticipantRemoved);

    return () => {
      usersChanel
        .stopListening('.message.created', handleMessageCreated)
        .stopListening('.message.updated', handleMessageUpdated)
        .stopListening('.message.deleted', handleMessageDeleted)

        .stopListening('.thread.archived', handleThreadArchived)
        .stopListening('.thread.author-changed', handleThreadAuthorChanged)
        .stopListening('.thread.toggleLike', handleThreadLike)
        .stopListening('.thread.seen', handleThreadSeen)

        .stopListening('.participant.added', handleParticipantAdded)
        .stopListening('.participant.removed', handleParticipantRemoved);
    };
  }, []);
};
