import { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Sticky } from '../../../../../components/Sticky';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { AccessPatientsContext } from '../PatientsProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const List = () => {
  const {
    patientsList,
    isFetched,
    isFetching,
    loadNextPage,
    filter
  } = useContext(AccessPatientsContext);
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader loading={!isFetched} p={2} render={
        () => !filter.total ? (
          <Box p={2}>
            <Typography align="center">No users found</Typography>
          </Box>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={patientsList}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </CustomScrollbars>
  );
};
