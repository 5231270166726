export const styles = ({ spacing, palette }) => ({
  signature: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 165,
    background: palette.grey[50]
  },

  signaturePreviewIcon: {
    fontSize: 80
  },

  signButton: {
    alignSelf: 'flex-start',
    marginTop: spacing(2)
  },

  image: {
    maxHeight: 70
  }
});
