import { createContext, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

export const AppointmentsFilterContext = createContext();

export const AppointmentsFilterProvider = ({ children, filter: filterProp = {}, filterKey }) => {
  const [ filter, setFilter ] = useState(filterProp);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({
    appointment_books: [],
    patients: [],

    ...lastFilters
  });

  const changeFilter = (newFilter = {}) => {
    setFilter((filter) => ({ ...filter, ...newFilter }));
  };

  const providerValue = {
    filter,
    relationsForFilter,
    setRelationsForFilter,
    changeFilter
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(filter, newFilter)) {
      setFilter(newFilter);
    }
  }, [ filter, filterProp ]);

  return (
    <AppointmentsFilterContext.Provider value={providerValue}>
      {children}
    </AppointmentsFilterContext.Provider>
  );
};
