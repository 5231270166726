import { filtersKeysMap } from '../../../../store/lastFilters';
import { CodesBodyPart } from './CodesBodyPart';
import { CodesCPT } from './CodesCPT';
import { CodesICD } from './CodesICD';
import { CodesModifier } from './CodesModifier';
import { CodesPOS } from './CodesPOS';
import { CodesNDC } from './CodesNDC';

export const routes = [
  {
    exact: true,
    path: '/codes/procedure-codes/cpt',
    label: 'CPT',
    component: () => <CodesCPT filterKey={filtersKeysMap.codes_cpt} />
  },
  {
    exact: true,
    path: '/codes/procedure-codes/icd',
    label: 'ICD',
    component: CodesICD
  },
  {
    exact: true,
    path: '/codes/procedure-codes/pos',
    label: 'POS',
    component: () => <CodesPOS filterKey={filtersKeysMap.codes_pos} />
  },
  {
    exact: true,
    path: '/codes/procedure-codes/modifier',
    label: 'Modifier',
    component: () => <CodesModifier filterKey={filtersKeysMap.codes_modifier} />
  },
  {
    exact: true,
    path: '/codes/procedure-codes/body-part',
    label: 'Body Part',
    component: () => <CodesBodyPart filterKey={filtersKeysMap.codes_body_part} />
  },
  {
    exact: true,
    path: '/codes/procedure-codes/ndc',
    label: 'NDC code',
    component: () => <CodesNDC filterKey={filtersKeysMap.codes_ndc} />
  }
];
