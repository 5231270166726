import moment from 'moment';

export const initialState = {
  filter: {
    from: moment().utc().startOf('month').unix(),
    to: moment().utc().endOf('month').unix(),
    period: 'month',
    type: 'types'
  }
};
