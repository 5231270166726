import { Yup } from '../../../utils/validation';
import { apptTypesCreationMap } from './apptTypesCreationMap';

export const validationSchema = Yup.object().shape({
  appt_type: Yup.mixed().required(),
  appointment_id: Yup.mixed().when('appt_type', {
    is: (value) => value === apptTypesCreationMap.exist,
    then: Yup.mixed().required()
  })
}, [ 'appt_type' ]);
