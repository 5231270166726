import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell flexBasis={columnsWidths.officeName}>
          Office Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.companyName}>
          Company Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.users}>
          Users
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.address}>
          Address
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
