export const fileToObject = ({ lastModified, lastModifiedDate, name, path, size, type, ...file }) => {
  return {
    lastModified,
    lastModifiedDate,
    name,
    path,
    size,
    type,

    ...file
  };
};
