import { Typography } from '@material-ui/core';

export const ChecklistBool = ({ after, before, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Completed&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  } else if (before !== after) {
    return (
      <>Cleared&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography></>
    );
  } else {
    return (
      <>Updated&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography></>
    );
  }
};
