import { Typography } from '@material-ui/core';
import { getUserFullName } from '../../../../helpers/users';
import { ModelLink } from '../../files-common';

export const Comment = ({ activity: { log_data: { causer, payload } } }) => {
  const name = getUserFullName(causer);

  return (
    <div>
      <Typography variant="subtitle1">{name}</Typography>
      New comment in
      &nbsp;
      <ModelLink modelType={payload.main_parent.type} model={payload.main_parent} />

      <Typography>{payload.comment.text}</Typography>
    </div>
  );
};
