import React, { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { ActivityContext } from '../../../components/Activities';
import { CustomScrollbars } from '../../../components/CustomScrollbars';
import { Loader } from '../../../components/Loader';
import { TablePagination } from '../../../components/TablePagination';
import { Row } from '../CasesPage/Cases/CaseActivity/Row';

export const List = () => {
  const {
    activities,
    isFetched,
    filter,
    resetActivities
  } = useContext(ActivityContext);
  const scrollElementRef = useRef();

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden" pt={2} pl={2} pb={1}>
      <Loader p={3} loading={!isFetched} render={
        () => !activities.length ? (
          <Box p={3}>
            <Typography align="center">No activities found</Typography>
          </Box>
        ) : (
          <>
            <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
              <MuiList disablePadding>
                {activities.map((activity) => (
                  <Row
                    isLoaded={isFetched}
                    key={activity.id}
                    item={activity}
                  />
                ))}
              </MuiList>
            </CustomScrollbars>

            <TablePagination
              pagination={filter}
              rowsPerPageOptions={[ 20, 30, 40, 50 ]}
              onChange={resetActivities}
            />
          </>
        )}
      />
    </Box>
  );
};

