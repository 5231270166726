import { useContext } from 'react';
import { Box, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Divider } from '../../../../../components/Divider';
import { IconButton } from '../../../../../components/IconButton';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { AccountsContext } from '../../AccountsContext';
import { EmailContent } from '../../EmailContent';
import { MessagesContext } from '../../MessagesContext';
import { EmailSecondaryActions } from './EmailSecondaryActions';
import { ActionsBar } from './ActionsBar';

export const EmailView = () => {
  const { filter, messageItem, isOpenView, toggleEmailView } = useContext(MessagesContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { accounts } = useContext(AccountsContext);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {messageItem === null ? (
        <Box p={2}>
          {isMobile && (
            <IconButton color="primary" onClick={toggleEmailView}>
              <SvgIcon color="inherit">
                {isOpenView ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon color="primary" />}
              </SvgIcon>
            </IconButton>
          )}

          <Typography align="center">No message selected</Typography>
        </Box>
      ) : (
        <>
          <ActionsBar />
          <Divider />

          <Box flexGrow={1}>
            <Scrollbars>
              <EmailContent filter={filter} messageItem={messageItem} />
              <Divider />
              <EmailSecondaryActions
                messageItem={messageItem}
                accounts={accounts}
                filter={filter}
              />
            </Scrollbars>
          </Box>
        </>
      )}
    </Box>
  );
};
