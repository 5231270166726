import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../../components/Loader';
import { ScrollbarsCustom } from '../../../../../../../../components/ScrollbarsCustom';
import { CaseRemindersContext } from '../../../../../../../../components/cases/reminders/case-reminders';
import { ReminderRow } from './ReminderRow';

export const CaseRemindersList = ({ width, autoHeight, hiddenColumns, Row = ReminderRow, RowProps = {}, ...props }) => {
  const {
    isFetched,
    isFetching,
    reminders,
    meta,
    loadNextPage,
    resetReminders
  } = useContext(CaseRemindersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetReminders();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} autoHeight={autoHeight}>
      <Box {...props}>
        <Loader p={2} loading={!isFetched} render={
          () => !meta.total ? (
            <Box p={2}>
              <Typography align="center">No reminders found</Typography>
            </Box>
          ) : (
            <MuiList disablePadding>
              <InfiniteListLoader
                items={reminders}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                RowComponent={Row}
                RowProps={{ hiddenColumns, ...RowProps, width }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
