import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { fabric } from 'fabric';
import { loadFonts } from '../../../../../../../../store/dashboard/profile/signatureFonts';
import { Loader } from '../../../../../../../../components/Loader';

const useStyles = makeStyles(({ typography: { pxToRem } }) => ({
  listItemText: {
    padding: 0
  },

  text: {
    fontSize: pxToRem(30),
    lineHeight: 1
  }
}));

export const CustomFont = ({ text, onChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fonts, isFetching, isFetched } = useSelector(({ signatureFonts }) => signatureFonts);
  const [ selectedFontIndex, setSelectedFontIndex ] = useState(0);
  const canvas = useRef();

  useEffect(() => {
    if (isFetched) {
      canvas.current = new fabric.StaticCanvas();
      onChange(exportTextToPNGDataURL());
    } else {
      dispatch(loadFonts());
    }
  }, []);

  useEffect(() => {
    if (!isFetched) return;

    if (!canvas.current) {
      canvas.current = canvas.current || new fabric.StaticCanvas();
    }

    onChange(exportTextToPNGDataURL());
  }, [ isFetched, text ]);

  const exportTextToPNGDataURL = (fontIndex = selectedFontIndex) => {
    // This method added text to canvas, and generate dataURL from result canvas.
    // After generation, remove text from canvas.

    const textObject = new fabric.Text(text, {
      fontSize: 58,
      fontFamily: fonts[fontIndex]
    });

    canvas.current.add(textObject);

    const dataURL = canvas.current.toDataURL({
      left: -5,
      width: textObject.width + 25,
      height: textObject.height + 15,
      multiplier: 2
    });

    canvas.current.remove(textObject);

    return dataURL;
  };

  const handleFontToggle = (index) => () => {
    setSelectedFontIndex(index);
    onChange(exportTextToPNGDataURL(index));
  };

  return (
    <Loader loading={isFetching} render={
      () => (
        <List disablePadding>
          {
            fonts.map((font, index) => (
              <ListItem
                key={font}
                dense
                button
                selected={index === selectedFontIndex}
                onClick={handleFontToggle(index)}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={
                    <Typography className={classes.text} style={{ fontFamily: font }}>{text}</Typography>
                  }
                />
              </ListItem>
            ))
          }
        </List>
      )}
    />
  );
};
