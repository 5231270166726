import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Grid, Box, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { AppointmentsSelect } from '../../../../../../components/appointments/AppointmentsSelect';
import {
  KeyboardDatePicker,
  OfficesLocationSelect,
  TextField
} from '../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../components/users';
import { Attachments } from '../../../ClinicalNotes/Attachments';

export const MRIBody = ({
  isApptExist = false,
  isEditable = false,
  onAttachmentsUpdate = () => {}
}) => {
  const { values, setFieldValue } = useFormikContext();

  const handlePhysicianChange = () => {
    setFieldValue('office_id', null);
  };

  useEffect(() => {
    onAttachmentsUpdate(values.media);
  }, [ values.media ]);

  return (
    <Grid container spacing={2}>
      {!isEditable &&
        <Grid item sm={8} xs={12}>
          <TextField
            required
            name="name"
            label="Name MRI scan"
          />
        </Grid>
      }

      {isApptExist &&
        <Grid item sm={4} xs={12}>
          <AppointmentsSelect disabled />
        </Grid>
      }

      <Grid item sm={6} xs={12}>
        <UsersSelect
          required
          disabled
          name="patient_id"
          label="Select patient"
          params={{ is_patient: 1 }}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <UsersSelect
          required
          disabled={isApptExist}
          name="physician_id"
          label="Primary Care Physician"
          params={{ role: 'doctor' }}
          onChange={handlePhysicianChange}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <KeyboardDatePicker
          clearable
          required
          name="date"
          label="Date of MRI"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <OfficesLocationSelect
          name="office_id"
          disabled={isApptExist}
          params={{ users: values.physician_id ? [ values.physician_id ] : null }}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={3}>
          <Box mr={0.5} fontWeight={500}>
            <NotesIcon fontSize="small" />
          </Box>

          <Typography variant="h5">Note</Typography>
        </Box>

        <TextField
          fullWidth
          multiline
          rows={4}
          name="note"
          variant="filled"
        />
      </Grid>

      {onAttachmentsUpdate &&
        <Grid item xs={12}>
          <Attachments name="media" />
        </Grid>
      }
    </Grid>
  );
};
