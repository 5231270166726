import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as casesApi from '../../../../../../../../api/cases';
import { transformConfirmationToOption } from '../../../../../../../../components/FormField';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../../../components/Modal';
import EditIcon from '../../../../../../../../components/icons/square-edit-icone.svg';
import { Loader } from '../../../../../../../../components/Loader';
import { ChecklistForm } from './ChecklistForm';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

export const EditChecklistModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    caseID,
    checklist = {},
    onChecklistUpdate = () => {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateCase = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.updateCaseChecklist(caseID, values).then((caseItem) => {
      enqueueSnackbar('Checklist successfully updated', { variant: 'success' });
      handleModalResolve(caseItem);
    }).catch(({ status, data: { errors } }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }

      enqueueSnackbar('Checklist not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...checklist,

        scene_photos: transformConfirmationToOption(checklist.scene_photos),
        police_report: transformConfirmationToOption(checklist.police_report),
        pd_requested: transformConfirmationToOption(checklist.pd_requested),
        pd_photos: transformConfirmationToOption(checklist.pd_photos),
        police_department_id: checklist.police_department
      }}
      validationSchema={validationSchema}
      onSubmit={handleUpdateCase}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><EditIcon /></SvgIcon>}
              onClose={handleModalReject}
            >
              Edit Initial Checklist
            </ModalHeader>

            <ModalBody>
              <ChecklistForm caseID={caseID} onChecklistUpdate={onChecklistUpdate} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
