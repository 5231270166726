import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, SvgIcon } from '@material-ui/core';
import { mdiCancel } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as resetApi from '../../../api/auth';
import { Loader } from '../../Loader';
import { Tooltip } from '../../Tooltip';
import { Fab } from '../../Fab';

export const InviteButton = ({ user = {} }) => {
  const hasIsConfirmField = user.hasOwnProperty('is_confirm');
  const { enqueueSnackbar } = useSnackbar();
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const resendRegister = () => {
    setIsSubmitting(true);

    resetApi.resendRegister(user.id).then(() => {
      enqueueSnackbar('Successfully sent', { variant: 'success' });
    }).catch((errors) => {
      if (errors) {
        enqueueSnackbar('Invitation not sent', { variant: 'error' });
      }
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return hasIsConfirmField && (
    <>
      <Box component="span" mx={1}>
        <Tooltip
          isExistTooltip={!user.is_confirm}
          title="Not Verified"
        >
          {!user.is_confirm &&
            <SvgIcon fontSize="small" color="error">
              <Icon path={mdiCancel} />
            </SvgIcon>
          }
        </Tooltip>
      </Box>

      {!user.is_confirm &&
        <Box component="span" minWidth={60}>
          <Tooltip
            isExistTooltip
            title="An invitation to register with CaseArk"
          >
            <div>
              <Loader surface loading={isSubmitting} render={
                () => (
                  <Fab
                    disabled={isSubmitting}
                    variant="extended"
                    color="primary"
                    size="small"
                    onClick={resendRegister}
                  >
                    Invite
                  </Fab>
                )}
              />
            </div>
          </Tooltip>
        </Box>
      }
    </>
  );
};
