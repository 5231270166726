import { makeStyles, Typography, IconButton } from '@material-ui/core';
import { preventDefault, stopPropagation } from '../../helpers/dom';
import { Popper } from '../Popper';
import { Assignments } from './Assignments';
import { ParentUsersList } from './ParentUsersList';
import { UsersList } from './UsersList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Members = ({
  isLink,
  isParent,
  noWrap,
  space,
  owner,
  maxVisible = 5,
  placement = 'left',
  users = [],
  MenuComponent,
  menuProps
}) => {
  const classes = useStyles();
  const sortedUsers = owner?.id ? users?.filter((item) => item?.id !== owner?.id) : users;
  const visibleUsers = sortedUsers.slice(0, maxVisible);
  const hiddenUsers = sortedUsers.length > maxVisible ? sortedUsers.slice(visibleUsers.length, sortedUsers.length) : [];

  return !users?.length ? (
    <Typography color="textSecondary">No info</Typography>
  ) : (
    <>
      <Assignments
        isParent={isParent}
        owner={owner}
        noWrap={noWrap}
        space={space}
        users={visibleUsers}
        MenuComponent={MenuComponent}
        menuProps={menuProps}
      />

      {!!hiddenUsers.length && (
        <Popper
          placement={placement}
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton
                size="small"
                color="primary"
                buttonRef={anchorRef}
                className={classes.actionButton}
                onClick={isLink ? preventDefault(handleToggle) : stopPropagation(handleToggle)}
              >
                +{hiddenUsers.length}
              </IconButton>
            )
          }
        >
          {({ handleClose }) => isParent ? (
            <ParentUsersList
              users={hiddenUsers}
              MenuComponent={MenuComponent}
              menuProps={menuProps}
            />
          ) : (
            <UsersList users={hiddenUsers} onClose={handleClose}/>
          )}
        </Popper>
      )}
    </>
  );
};
