import { Typography } from '@material-ui/core';
import React from 'react';
import { typesMap } from './typesMap';

export const TypesActivity = ({ after, before, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Set&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;to&nbsp;
        <Typography variant="subtitle1">{typesMap[after]}</Typography>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Typography variant="subtitle1">{typesMap[before]}</Typography>
      </>
    );
  } else {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Typography variant="subtitle1">{typesMap[before]}</Typography>
        &nbsp;to&nbsp;
        <Typography variant="subtitle1">{typesMap[after]}</Typography>
      </>
    );
  }
};
