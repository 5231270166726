import { useContext } from 'react';
import { Checkbox, Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { CasesContext } from '../../CasesContext';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  const { toggleAllCasesSelection, allCasesIsSelected, selectedCasesIDs } = useContext(CasesContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell>
          <Checkbox
            edge="start"
            checked={allCasesIsSelected()}
            indeterminate={!!selectedCasesIDs.length && !allCasesIsSelected()}
            onClick={toggleAllCasesSelection}
          />
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.name}>
          Case #
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.description}>
          Name / Description
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.type}>
          Type
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.status}>
          Status / Date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.members}>
          Members
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.lastChange}>
          Activity
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.updatedDate}>
          Last Change
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
