import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Grid, Typography, FormControlLabel } from '@material-ui/core';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { Checkbox } from '../../../../../components/FormField';
import { rolesMap } from '../../../../../dataMaps';
import { hasRole } from '../../../../../utils/hasRole';
import { isPrimary } from '../InsurancesProvider';
import { caseInsuranceValuesTypesMap, insuranceTypes } from './insuranceDataMaps';
import { SubscriberInfo } from './SubscriberInfo';
import { ImageForm } from './ImageForm';
import { MainInfo } from './MainInfo';

export const InsuranceForm = ({
  mainPage = false,
  disableScan = false,
  user = null,
  insurance = null,
  onCaseItemUpdate = () => {}
}) => {
  const { pathname } = useLocation();
  const { values, setFieldValue } = useFormikContext();
  const isWcOrPi = [
    insuranceTypes.wc,
    insuranceTypes.pi,
    ...Object.values(caseInsuranceValuesTypesMap)
  ].includes(values?.type_insurance);
  const showCaseSelect = !pathname.includes('cases') && !pathname.includes('medical-forms');

  const handleCaseChange = (option) => {
    if (!mainPage) {
      return;
    }

    setFieldValue('medical_id', null);
    setFieldValue('caseItem', option);
  };

  useEffect(() => {
    if (values.status_type) {
      setFieldValue('is_main', isPrimary(values.status_type));
    }
  }, []);

  return (
    <>
      {showCaseSelect && !hasRole(rolesMap.doctor) && (
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <CasesSelect
              name="case_id"
              label="Assign to case"
              placeholder="Select case..."
              params={{
                excluded_insurance_id: values?.id,
                users: user?.id ? [ user.id ] : null
              }}
              onChange={handleCaseChange}
            />
          </Grid>
        </Grid>
      )}

      <MainInfo mainPage={mainPage} user={user} onCaseItemUpdate={onCaseItemUpdate} />

      {!isWcOrPi && (
        <>
          <SubscriberInfo user={user} insurance={insurance} />

          <Grid container spacing={2}>
            {!disableScan && <ImageForm />}

            <Grid item sm={6} xs={12}>
              <FormControlLabel
                label={<Typography>ICD - 10</Typography>}
                control={<Checkbox name="is_icd" />}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
