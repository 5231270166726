import { useContext } from 'react';
import { Checkbox, IconButton, makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Popper } from '../../../../../../../../../components/Popper';
import { ReadonlyCheckbox } from '../../../../../../../../../components/ReadonlyCheckbox';
import { InvoicesContext } from '../../../../../InvoicesContext';
import { InvoiceMenu } from '../../../InvoiceMenu';
import { columnsMap as invoicesColumnsMap } from '../../../List/listConfig';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ disableToggle = false, invoice, hiddenColumns = [] }) => {
  const classes = useStyles();
  const { selectedInvoicesIDs, ...invoicesContext } = useContext(InvoicesContext);
  const isSelected = selectedInvoicesIDs.indexOf(invoice.id) !== -1;

  const handleItemSelection = (event) => {
    event.stopPropagation();

    invoicesContext.toggleItemSelection(invoice.id);
  };

  const openInvoiceMenu = (callback) => (event) => {
    event.stopPropagation();

    callback(event);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <ReadonlyCheckbox
          title={`Inv. # ${invoice.number}`}
          checked={invoice.is_confirmed}
          className={classes.invoiceTitle}
        />
      </div>

      <div className={classes.menu}>
        {!disableToggle && !hiddenColumns.includes(invoicesColumnsMap.checkbox) && (
          <Checkbox className={classes.checkbox} checked={isSelected} onClick={handleItemSelection}/>
        )}

        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={openInvoiceMenu(handleToggle)}>
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <InvoiceMenu
              invoice={invoice}
              onClose={handleClose}
            />
          )}
        </Popper>
      </div>
    </div>
  );
};
