import { formTypesMap } from '../../../../../components/medical/forms';

export const formTypesOptions = [
  {
    value: formTypesMap.private,
    label: formTypesMap.private,
    data: {
      value: formTypesMap.private,
      label: formTypesMap.private
    }
  },
  {
    value: formTypesMap.injury,
    label: formTypesMap.injury,
    data: {
      value: formTypesMap.injury,
      label: formTypesMap.injury
    }
  },
  {
    value: formTypesMap.ortho,
    label: formTypesMap.ortho,
    data: {
      value: formTypesMap.ortho,
      label: formTypesMap.ortho
    }
  },
  {
    value: formTypesMap.compensation,
    label:  formTypesMap.compensation,
    data: {
      value: formTypesMap.compensation,
      label:  formTypesMap.compensation
    }
  },
  {
    value: formTypesMap.follow_up,
    label: formTypesMap.follow_up,
    data: {
      value: formTypesMap.follow_up,
      label: formTypesMap.follow_up
    }
  }
];
