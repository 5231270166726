import { api } from '../api';

export const fetchSituationalNumbers = (config) => {
  return api.get('/situational-numbers', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchSituationalNumber = (id, config) => {
  return api.get(`/situational-numbers/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createSituationalNumber = (data) => {
  return api.post('/situational-numbers', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const updateSituationalNumber = ({ id, ...data }) => {
  return api.put(`/situational-numbers/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteSituationalNumber = (id) => {
  return api.delete(`/situational-numbers/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
