import { useContext, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { FileDropZoneWindow } from '../../../../components/FileDropZoneWindow';
import { useModal } from '../../../../components/ModalsProvider';
import { useResizeObserver } from '../../../../helpers/hooks';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { FilesContextProvider, FileUploadModal } from '../../files-common';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { Groups } from './Groups';
import { Grid } from './Grid';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MyFilesPage = ({ isOpenSidebar, filterKey, hiddenFields = [], toggleSideBar = () => {} }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { openModal } = useModal();
  const rootRef = useRef(null);
  const { viewVariant } = useContext(LayoutContext);
  const { width } = useResizeObserver({ ref: rootRef });
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('lg');
  const isMobile = width <= theme.breakpoints.values.mobileSm;

  const uploadFiles = (files) => {
    openModal(FileUploadModal, {
      payload: { files }
    });
  };

  return (
    <FileDropZoneWindow onDropAccepted={uploadFiles}>
      <div ref={rootRef} className={classes.root}>
        <ActionsBar
          isOpenSidebar={isOpenSidebar}
          toggleSideBar={toggleSideBar}
          filterOpenButton={filterOpenButton}
        />

        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <FiltersBar filterKey={filterKey} hiddenFields={hiddenFields} />
          </FilterBarWrapper>
        </ScrollWrapper>

        <div className={classes.filesWindow}>
          <div className={classes.filesWindow__main}>
            {!isMobile && (viewVariant === viewVariantsMap.groups) && <Groups />}

            {(viewVariant === viewVariantsMap.list) &&
              <FilesContextProvider>
                <List />
              </FilesContextProvider>
            }

            {(viewVariant === viewVariantsMap.grid) &&
              <FilesContextProvider>
                <Grid />
              </FilesContextProvider>
            }
          </div>

          {/* TODO: For future usage */}
          {/*<div*/}
          {/*  className={cn(*/}
          {/*    classes.filesWindow__sidebar,*/}
          {/*    { [classes.filesWindow__sidebar_active]: sidebarIsOpen }*/}
          {/*  )}*/}
          {/*>*/}
          {/*  <RightSidebar />*/}
          {/*</div>*/}
        </div>
      </div>
    </FileDropZoneWindow>
  );
};
