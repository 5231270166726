import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as authApi from '../../../../../api/auth';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { UsersSelect } from '../../../../../components/users';
import { Yup } from '../../../../../utils/validation';

const initialValues = {
  userId: null
};

const validationSchema = Yup.object().shape({
  userId: Yup.mixed().nullable().required()
});

export const LoginAsModal = ({ handleModalReject, handleModalResolve, DialogProps, onClose }) => {
  const handleSubmit = ({ userId }) => {
    authApi.loginAs(userId).then((data) => {
      handleModalResolve(data);
    });
  };

  return (
    <Dialog maxWidth="sm" {...DialogProps}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={onClose}>
              Select user to login
            </ModalHeader>

            <ModalBody>
              <Alert severity="info">
                When switching accounts, the current session will be ended. Use the login form to resume your session
              </Alert>

              <UsersSelect
                name="userId"
                label="Select user to login as"
                placeholder="Search user..."
                margin="normal"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
