import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { isEqual, omit } from 'lodash';
import { Box, List as MuiList, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { amFormatDuration } from '../../../../../../../helpers/dates';
import { usePrevious } from '../../../../../../../helpers/hooks';
import * as trackApi from '../../../../../../../api/billing';
import { api } from '../../../../../../../api';
import { billingParticipantsTypesMap } from '../../../../../../../components/billing';
import { ListRowCell, ListRow } from '../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../components/Loader';
import { TimeTrackContainer } from './TimeTrackContainer';
import { TableHeader } from './TableHeader';
import { FiltersBar } from './FiltersBar';
import { Card } from './Card';
import { Row } from './Row';

export const minRowHeight = 64;
export const columnsWidths = {
  action: 60,
  type: 50,
  range_date: 150,
  caseName: 150,
  category: 100,
  notes: 180,
  finished_at: 100,
  total: 110,
  billing_rate: 110,
  rate: 100
};

export const TracksList = ({ initialModelID, parentPage, model, model_id }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { values, setValues } = useFormikContext();
  const [ tracksList, setTracksList ] = useState([]);
  const [ isFetched, setIsFetched ] = useState(false);
  const [ newFilter, setNewFilter ] = useState({});
  const [ selectedTracks, setSelectedTracks ] = useState([]);
  const [ totalTime, setTotalTime ] = useState(0);
  const totalHours = amFormatDuration(totalTime > 0 ? totalTime : values.hours, 'seconds', 'h[h] m[min]');
  const cancelFetch = useRef(() => {});
  const prevFilter = usePrevious(newFilter);
  const TimeTrackComponent = isTablet ? Card : Row;

  useEffect(() => {
    if ((values?.participant_type?.value || values?.participant_type) === billingParticipantsTypesMap.user) {
      if (!values?.flat_fee && values?.rate) {
        const totalRate = (totalTime / 3600 * +values.rate).toFixed(2);

        setValues({ ...values, hours: totalHours, total: +totalRate });
      }
    }

    if (!values?.flat_fee && !values?.rate) {
      setValues({ ...values, hours: 0, total: 0 });
    }
  }, [ totalTime, values?.rate, values?.flat_fee, values?.participant_type ]);

  useEffect(() => {
    if ((values?.participant_type?.value ||
        values?.participant_type) === billingParticipantsTypesMap.company
    ) {
      if (!values?.flat_fee) {
        const totalRate = selectedTracks.reduce((prev, next) => (
          !next.billing_total
            ? prev + +(next.billing_time / 3600 * values.rate).toFixed(2)
            : prev + +next.billing_total
        ), 0);

        setValues({ ...values, hours: totalHours, total: +totalRate.toFixed(2) });
      }
    }
  }, [ totalHours, values?.rate, values?.flat_fee ]);

  const fetchTracks = () => {
    cancelFetch.current();

    if (model) {
      setIsFetched(false);

      trackApi.fetchTimeTracks({
        params: {
          model,
          model_id,
          is_billed: !values?.income?.id ? 0 : null,
          income_id: values?.income?.id || null,

          ...newFilter
        },
        cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
      }).then((data) => {
        if (!parentPage && values.incomes && values.incomes.length > 0) {
          const tracksIDs = values.incomes.reduce((tracksIDs, { time_tracks_id }) => {
            return time_tracks_id ? tracksIDs.concat(time_tracks_id) : tracksIDs;
          }, []);

          if (tracksIDs.length > 0) {
            const newTrackList = data.filter((item) => tracksIDs.indexOf(item.id) < 0);

            setTracksList(newTrackList);
          } else {
            setTracksList(data);
          }
        } else {
          if (values?.income?.time_tracks_id?.length) {
            setSelectedTracks(data.filter(({ id }) => values?.income?.time_tracks_id.includes(id)));
          }

          setTracksList(data);
        }

        setIsFetched(true);
      });
    } else {
      setIsFetched(true);
    }
  };

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      fetchTracks();
    }
  }, [ prevFilter, newFilter ]);

  useEffect(() => {
    if (values.task) {
      setNewFilter((filter) => ({ ...filter, model: 'task', model_id: values.task }));
    } else {
      setNewFilter((filter) => ({ ...filter, model: 'case', model_id: initialModelID }));
    }
  }, [ values.task ]);

  useEffect(() => {
    selectedTracks.length > 0
      ? setTotalTime(selectedTracks.reduce((prev, next) => prev + next.time, 0))
      : setTotalTime(0);

    setValues({
      ...values,

      model,
      model_id,
      time_tracks_id: selectedTracks.map(({ id }) => id ),
      time_tracks: selectedTracks
    });
  }, [ selectedTracks ]);

  useEffect(() => {
    fetchTracks();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <>
      <FiltersBar filter={newFilter} setFilter={setNewFilter} />

      <Loader loading={!isFetched} p={3} render={
        () => !tracksList.length ? (
          <Box p={2}>
            <Typography align="center">No tracks found</Typography>
          </Box>
        ) : (
          <>
            {!isTablet && <TableHeader />}

            <Box px={2} pt={1}>
              <MuiList disablePadding>
                {tracksList.map((item) =>
                  <TimeTrackContainer
                    key={item.id}
                    track={item}
                    selectedTracks={selectedTracks}
                    setSelectedTracks={setSelectedTracks}
                    TimeTrackComponent={TimeTrackComponent}
                  />
                )}
              </MuiList>
            </Box>

            <ListRow minHeight={minRowHeight} justifyContent="flex-end" px={2}>
              <ListRowCell flexBasis={columnsWidths.total}>
                <Typography variant="h5">Summary total</Typography>
              </ListRowCell>

              <ListRowCell flexBasis={columnsWidths.rate}>
                <Typography variant="h5">{totalHours}</Typography>
              </ListRowCell>
            </ListRow>
          </>
        )}
      />
    </>
  );
};
