export const styles = ({ spacing, palette }) => ({
  textField: {
    marginBottom: spacing(2)
  },

  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: spacing(0.25)
  },

  adornmentActionButton: {
    backgroundColor: 'transparent',

    '&:first-child': {
      color: palette.grey[500]
    }
  }
});
