import { Fragment } from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { preventDefault, stopPropagation } from '../../../helpers/dom';
import { LinkableText } from '../../LinkableText';
import { useModal } from '../../ModalsProvider';
import { UserPreviewModal } from '../../users';

// Related with `markup` prop of `react-mentions/Mention`
const regex = /@\[.+?]\(.+?\)/gm;
const displayRegex = /@\[.+?]/g;
const idRegex = /\(.+?\)/g;

export const MessageContent = ({ message }) => {
  const { openModal } = useModal();
  const messagePartsBetweenMentions = message?.split?.(regex);
  const matchedMentions = message?.match?.(regex);
  const mentions = matchedMentions?.map((match) => ({
    id: match?.match(idRegex)[0].replace('(', '').replace(')', ''),
    display: match?.match(displayRegex)[0].replace('@[', '').replace(']', '')
  }));

  const openUserViewModal = (userID) => () => {
    openModal(UserPreviewModal, {
      payload: { id: userID }
    });
  };

  return !!message && (typeof message !== 'string' ? message :
    <LinkableText target="_blank" color="inherit">
      {messagePartsBetweenMentions?.map((messagePart, index) => (
        <Fragment key={index}>
          {messagePart}

          {index !== messagePartsBetweenMentions.length - 1 &&
            <MuiLink
              component="strong"
              color="inherit"
              underline="always"
              onClick={stopPropagation(preventDefault(openUserViewModal(mentions[index].id)))}
            >
              @{mentions[index].display}
            </MuiLink>
          }
        </Fragment>
      ))}
    </LinkableText>
  );
};
