const getFollowingTests = (form, key) => ({
  value: form?.forms?.patient_history?.have_you_had_any_of_the_following_tests_studies?.[key]?.value || false,
  date: form?.forms?.patient_history?.have_you_had_any_of_the_following_tests_studies?.[key]?.date || null,
  body_parts:
    form?.forms?.patient_history?.have_you_had_any_of_the_following_tests_studies?.[key]?.body_parts || null,
  what_facility:
    form?.forms?.patient_history?.have_you_had_any_of_the_following_tests_studies?.[key]?.what_facility || null
});

export const generatePatientHistoryValues = (form) => ({
  forms: {
    patient_history: {
      please_rate_your_pain: {
        at_rest: 0,
        at_worst: 0,
        ...form?.forms?.patient_history?.please_rate_your_pain
      },
      ...form?.forms?.patient_history,
      have_you_attended_any_of_the_following: {
        ...form?.forms?.patient_history?.have_you_attended_any_of_the_following,
        physical_therapy:
          form?.forms?.patient_history?.have_you_attended_any_of_the_following?.physical_therapy || false,
        chiropractor:
          form?.forms?.patient_history?.have_you_attended_any_of_the_following?.chiropractor || false,
        pool_therapy:
          form?.forms?.patient_history?.have_you_attended_any_of_the_following?.pool_therapy || false,
        acupuncture:
          form?.forms?.patient_history?.have_you_attended_any_of_the_following?.acupuncture || false,
        none:
          form?.forms?.patient_history?.have_you_attended_any_of_the_following?.none || false
      },
      is_this_injury_related_to_the_accident:
        form?.forms?.patient_history?.is_this_injury_related_to_the_accident || null,
      how_often_and_when: form?.forms?.patient_history?.how_often_and_when || null,
      have_you_undergone_any_diagnostic_testing_mri_ct_emg_labs: (
        form?.forms?.patient_history?.have_you_undergone_any_diagnostic_testing_mri_ct_emg_labs || null
      ),
      are_you_using_any_of_the_follwing: {
        bone_stimulator: (
          form?.forms?.patient_history?.are_you_using_any_of_the_follwing?.bone_stimulator || false
        ),
        tens_unit: form?.forms?.patient_history?.are_you_using_any_of_the_follwing?.tens_unit || false,
        cane: form?.forms?.patient_history?.are_you_using_any_of_the_follwing?.cane || false,
        walker: form?.forms?.patient_history?.are_you_using_any_of_the_follwing?.walker || false,
        other: form?.forms?.patient_history?.are_you_using_any_of_the_follwing?.other || false,
        none: form?.forms?.patient_history?.are_you_using_any_of_the_follwing?.none || false
      },
      dominant_hand: form?.forms?.patient_history?.dominant_hand || null,
      what_body_parts_are_injured: form?.forms?.patient_history?.what_body_parts_are_injured || null,
      is_the_pain: {
        worsening: form?.forms?.patient_history?.is_the_pain?.worsening || false,
        occasional: form?.forms?.patient_history?.is_the_pain?.occasional || false,
        stabbing: form?.forms?.patient_history?.is_the_pain?.stabbing || false,
        stable: form?.forms?.patient_history?.is_the_pain?.stable || false,
        sharp: form?.forms?.patient_history?.is_the_pain?.sharp || false,
        throbbing: form?.forms?.patient_history?.is_the_pain?.throbbing || false,
        improving: form?.forms?.patient_history?.is_the_pain?.improving || false,
        dull: form?.forms?.patient_history?.is_the_pain?.dull || false,
        constant: form?.forms?.patient_history?.is_the_pain?.constant || false,
        aching: form?.forms?.patient_history?.is_the_pain?.aching || false
      },
      what_symptoms_are_you_experiencing: {
        locking: form?.forms?.patient_history?.what_symptoms_are_you_experiencing?.locking || false,
        catching: form?.forms?.patient_history?.what_symptoms_are_you_experiencing?.catching || false,
        giving_away:
          form?.forms?.patient_history?.what_symptoms_are_you_experiencing?.giving_away || false,
        popping: form?.forms?.patient_history?.what_symptoms_are_you_experiencing?.popping || false,
        grinding: form?.forms?.patient_history?.what_symptoms_are_you_experiencing?.grinding || false,
        bruising: form?.forms?.patient_history?.what_symptoms_are_you_experiencing?.bruising || false,
        numbness: form?.forms?.patient_history?.what_symptoms_are_you_experiencing?.numbness || false,
        tingling: form?.forms?.patient_history?.what_symptoms_are_you_experiencing?.tingling || false,
        other: {
          value: form?.forms?.patient_history?.what_symptoms_are_you_experiencing?.other?.value || false,
          description:
            form?.forms?.patient_history?.what_symptoms_are_you_experiencing?.other?.description || null
        }
      },
      what_if_anything_makes_your_symptoms_better: {
        rest:
          form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_better?.rest || false,
        cold_therapy:
          form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_better?.cold_therapy || false,
        activity:
          form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_better?.activity || false,
        heat_therapy:
          form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_better?.heat_therapy || false,
        medication:
          form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_better?.medication || false,
        other: {
          value:
            form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_better?.other?.value || false,
          description:
            form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_better?.other?.description
            || null
        }
      },
      what_if_anything_makes_your_symptoms_worse: {
        inactivity:
          form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_worse?.inactivity || false,
        exercise: {
          value:
            form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_worse?.exercise?.value
            || false,
          description:
            form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_worse?.exercise?.description
            || null
        },
        other: {
          value:
            form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_worse?.other?.value || false,
          description:
            form?.forms?.patient_history?.what_if_anything_makes_your_symptoms_worse?.other?.description || null
        }
      },
      have_you_seen_another_physician_for_this_injury: (
        form?.forms?.patient_history?.have_you_seen_another_physician_for_this_injury || null
      ),
      if_yes_who: form?.forms?.patient_history?.if_yes_who || null,
      what_treatments_have_you_tried: {
        nothing: form?.forms?.patient_history?.what_treatments_have_you_tried?.nothing || false,
        physical_therapy:
          form?.forms?.patient_history?.what_treatments_have_you_tried?.physical_therapy || false,
        exercise: form?.forms?.patient_history?.what_treatments_have_you_tried?.exercise || false,
        acupuncture: form?.forms?.patient_history?.what_treatments_have_you_tried?.acupuncture || false,
        decreased_activity:
          form?.forms?.patient_history?.what_treatments_have_you_tried?.decreased_activity || false,
        injections:
          form?.forms?.patient_history?.what_treatments_have_you_tried?.injections || false,
        ice: form?.forms?.patient_history?.what_treatments_have_you_tried?.ice || false,
        bracing: form?.forms?.patient_history?.what_treatments_have_you_tried?.bracing || false,
        medications: {
          value:
            form?.forms?.patient_history?.what_treatments_have_you_tried?.medications?.value || false,
          description:
            form?.forms?.patient_history?.what_treatments_have_you_tried?.medications?.description || null
        },
        other: {
          value:
            form?.forms?.patient_history?.what_treatments_have_you_tried?.other?.value || false,
          description:
            form?.forms?.patient_history?.what_treatments_have_you_tried?.other?.description || null
        }
      },
      have_you_had_any_of_the_following_tests_studies: {
        x_rays: getFollowingTests(form, 'x_rays'),
        mri_scan: getFollowingTests(form, 'mri_scan'),
        ct_scan: getFollowingTests(form, 'ct_scan'),
        emgncv: getFollowingTests(form, 'emgncv'),
        other: getFollowingTests(form, 'other')
      },
      recreational_activities: form?.forms?.patient_history?.recreational_activities || null,
      current_regular_exercise_program:
        form?.forms?.patient_history?.current_regular_exercise_program || null
    }
  }
});
