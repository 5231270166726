import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import * as officeApi from '../../../../../api/companies';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
// TODO: for future settings
// import { Activity } from '../../Activity';
import { Cases } from '../../Cases';
import { Tasks } from '../../Tasks';
import { MainInfo } from './MainInfo';

export const OfficeItem = () => {
  const params = useParams();
  const history = useHistory();
  const [ office, setOffice ] = useState(null);
  const scrollElementRef = useRef();

  useEffect(() => {
    officeApi.fetchOffice(params.id)
      .then(setOffice)
      .catch(() => history.push('/contacts/offices'));
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader loading={!office} p={3} render={
        () => (
          <Grid
            container
            spacing={2}
            component={Box}
            pl={1}
            py={1}
            m="0 !important"
            width="100% !important"
          >
            <Grid item xs={12}>
              <MainInfo office={office} setOffice={setOffice} />
            </Grid>

            <Grid item xs={12}>
              <Cases filter={{ office_id: office.id }} />
            </Grid>

            {/*TODO: for future settings*/}
            {/*<Grid item lg={width > widthBreakpointLarge ? 4 : 12} xs={12}>*/}
            {/*  <Activity />*/}
            {/*</Grid>*/}

            <Grid item xs={12}>
              <Tasks filter={{ office_id: [ office.id ] }} />
            </Grid>
          </Grid>
        )}
      />
    </CustomScrollbars>
  );
};
