import { Fragment } from 'react';
import moment from 'moment';
import { ErrorMessage, useFormikContext } from 'formik';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { AddressLink } from '../../../AddressLink';
import { UserLink } from '../../../UserLink';

export const List = ({ times = [], appointmentDate }) => {
  const { values, setFieldValue } = useFormikContext();
  const apptBookID = values.appointment_book_id?.id || values.appointment_book_id;

  const handleChangeTime = (time, apptBookId) => () => {
    if (time) {
      setFieldValue('appointment_time', time);
      setFieldValue('appointment_book_id', apptBookId);
    }
  };

  return !times?.length ? (
    <Grid item xs={12}>
      <Typography paragraph color="textSecondary" align="center">No times found</Typography>
    </Grid>
  ) : times?.map((timeItem) => (
    <Fragment key={timeItem?.appointment_book_id}>
      <Grid item lg={4} md={6} sm={12} xs={12}>
        <UserLink
          disableLink
          disablePreview
          variant="h5"
          color="textPrimary"
          user={timeItem?.provider}
        >
          <Box display="flex" flexDirection="column" flexWrap="wrap">
            <Typography variant="caption" color="textSecondary">
              Appt.book: {timeItem?.appointment_book_name}
            </Typography>

            <AddressLink address={timeItem?.office?.full_address}>
              {timeItem?.office?.full_address || '-'}
            </AddressLink>
          </Box>
        </UserLink>
      </Grid>

      <Grid item lg={8} md={6} sm={12} xs={12}>
        {!timeItem?.dates?.length ? (
          <Typography paragraph color="textSecondary" align="center">No times found</Typography>
        ) : (
          <Box display="flex" alignItems="center" flexWrap="wrap" pb={1}>
            {timeItem?.dates?.map((time) => time && (
              <Box key={time.start} m={0.25} width={100}>
                <Button
                  fullWidth
                  color="primary"
                  key={time.start}
                  disabled={
                    !!appointmentDate && timeItem?.appointment_book_id === apptBookID
                      && time.start === values.appointment_time ? false : time.busy
                  }
                  variant={
                    timeItem?.appointment_book_id === apptBookID
                      && time.start === values.appointment_time ? 'contained' : 'outlined'
                  }
                  onClick={handleChangeTime(time.start, timeItem?.appointment_book_id)}
                >
                  {moment(time.start).format('LT')}
                </Button>
              </Box>
            ))}
          </Box>
        )}

        <Typography color="error"><ErrorMessage name="appointment_time" /></Typography>
      </Grid>
    </Fragment>
  ));
};
