import { useContext, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Box, IconButton, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Icon } from '@mdi/react';
import { mdiFileMoveOutline } from '@mdi/js';
import * as chatsApi from '../../../api/chat';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { ForwardModal } from '../ForwardModal';
import { actionTypesMap, ChatThreadContext } from '../ChatThreadContext';
import { ThreadMessagesContext } from '../ThreadMessagesContext';

export const MultiActions = () => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const {
    resetCurrentAction,
    selectedMessages,
    resetSelectedMessages,
    setCurrentAction
  } = useContext(ChatThreadContext);
  const { deleteMessages } = useContext(ThreadMessagesContext);
  const currentUser = useSelector(({ profile }) => profile.user);
  const isSelectedCurrentUserMessages = useMemo(() => (
    selectedMessages.find((message) => message?.user?.id !== currentUser.id)
  ), [ selectedMessages, currentUser.id ]);

  const copySelectedMessagesText = () => {
    const selectedMessagesText = selectedMessages.map((message) => message.body || message.parent.body).join('\n');

    window.navigator.clipboard.writeText(selectedMessagesText);
    resetSelectedMessages();
    resetCurrentAction();
    enqueueSnackbar('Text copied!', { variant: 'success' });
  };

  const discardSelection = () => {
    resetSelectedMessages();
    resetCurrentAction();
  };

  const openForwardModal = () => {
    openModal(ForwardModal, {
      payload: { selectedMessages },
      onModalResolved: () => {
        resetSelectedMessages();
        resetCurrentAction();
      },
      onModalRejected: () => {
        resetCurrentAction();
      }
    });
  };

  const handleSelectedMessagesDelete = () => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Delete message',
        content: 'Are you sure you want to delete this message? This cannot be undone.'
      },

      onModalResolved: () => {
        const selectedMessagesIds = selectedMessages.map((message) => message.id);

        return chatsApi.deleteSelectedMessages({ params: { message_ids: selectedMessagesIds } }).then(() => {
          deleteMessages(selectedMessagesIds);
          resetSelectedMessages();
          enqueueSnackbar('Messages deleted!', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Messages not deleted', { variant: 'error' });
        });
      }
    });

    setCurrentAction(actionTypesMap.default);
  };

  return (
    <Box p={0.75} display="flex" alignItems="center" justifyContent="space-between" flexGrow={1}>
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <IconButton onClick={discardSelection}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography variant="body1">{selectedMessages.length} selected</Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Box ml="auto">
          <Tooltip
            title={isSelectedCurrentUserMessages
              ? 'You can`t delete other people`s messages'
              : 'Delete'
            }
          >
            <div>
              <IconButton
                disabled={!!isSelectedCurrentUserMessages || !selectedMessages.length}
                onClick={handleSelectedMessagesDelete}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Box>

        <Tooltip title="Copy">
          <div>
            <IconButton disabled={!selectedMessages.length} onClick={copySelectedMessagesText}>
              <FileCopyOutlinedIcon />
            </IconButton>
          </div>
        </Tooltip>

        <Tooltip title="Forward to">
          <div>
            <IconButton disabled={!selectedMessages.length} onClick={openForwardModal}>
              <SvgIcon>
                <Icon path={mdiFileMoveOutline} />
              </SvgIcon>
            </IconButton>
          </div>
        </Tooltip>
      </Box>
    </Box>
  );
};
