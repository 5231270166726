import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import {
  AuthorizationsPage as AuthorizationsPageCommon
} from '../../../../../components/appointments/AuthorizationsPage';

export const AuthorizationsPage = () => {
  const currentUser = useSelector(({ profile }) => profile.user);

  return (
    <Box height="100%" minHeight="100%">
      <AuthorizationsPageCommon userID={currentUser.id} />
    </Box>
  );
};
