import { useContext } from 'react';
import { FilePreviewContext } from '../../../FilePreviewContext';
import { PreviewContent } from '../PreviewContent';

export const AudioPlayer = () => {
  const { selectedFile } = useContext(FilePreviewContext);

  return (
    <PreviewContent>
      <audio controls src={selectedFile?.url}>
        Your browser does not support the <code>audio</code> element.
      </audio>
    </PreviewContent>
  );
};
