import { Box, Grid, Typography } from '@material-ui/core';

export const Hospice = ({ claim }) => {
  return (
    <Grid container spacing={2} component={Box} px={2} py={3}>
      <Grid item xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">
            Hospice #
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Care Plan Oversight #
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {claim?.situational_number?.care_plan_oversight || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Hospice #
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {claim?.situational_number?.hospice || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};
