import { Box } from '@material-ui/core';
import { Date } from '../../../../../../components/Date';
import { Divider } from '../../../../../../components/Divider';

export const ScheduleDate = ({ date }) => {
  return (
    <Box flexGrow={0} minWidth={110} p={2}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        borderRadius="borderRadius"
        overflow="hidden"
        boxShadow={1}
      >
        <Box px={2} py={1} bgcolor="primary.main" color="common.white" textAlign="center">
          <Date variant="h5" disableIcon date={date} format="MMM." />
        </Box>

        <Box px={2} py={1} bgcolor="common.white" textAlign="center">
          <Date variant="h6" disableIcon date={date} format="D" />
          <Divider type="dotted" />
          <Date variant="h6" disableIcon date={date} format="ddd" />
        </Box>
      </Box>
    </Box>
  );
};
