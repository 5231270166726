import { Fragment } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../ReadonlyCheckbox';

export const VisionClaims = ({ claim }) => {
  return (
    <Grid container spacing={2} component={Box} px={2} py={3}>
      <Grid item xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">
            Vision Claims
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Certification Code Indicator
        </Typography>
      </Grid>

      <Grid item sm={9} xs={6}>
        <ReadonlyCheckbox
          disableTitle
          iconColor="success"
          checked={!!claim?.situational_number?.is_certification_code_indicator}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Certification Type
        </Typography>
      </Grid>

      <Grid item sm={9} xs={6}>
        <Typography variant="subtitle2">
          {claim?.situational_number?.certification_type?.label || '-'}
        </Typography>
      </Grid>

      {claim?.situational_number?.condition_indicators?.map((item, i) => (
        <Fragment key={i}>
          <Grid item sm={3} xs={6}>
            <Typography variant="body2" color="textSecondary">
              Condition Indicator {i + 1}
            </Typography>
          </Grid>

          <Grid item sm={9} xs={6}>
            <Typography variant="subtitle2">
              {item?.value} - {item?.label}
            </Typography>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};
