import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles, Button, IconButton } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as codesApi from '../../../../../../api/codes/payer';
import { Loader } from '../../../../../../components/Loader';
import {
  Page,
  PageBody,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../../../components/Page';
import {
  transformClassToOption,
  transformDataToOption,
  transformToOption
} from '../CodesContext/selectFieldHelpers';
import { Header, Body, validationSchema } from '../Create';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Edit = ({ baseUrl }) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [ initialValues, setInitialValues ] = useState();

  const updateCode = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const editedPayer = {
      ...values,

      company_id: values?.company_id?.value || values?.company_id,
      office_id: values?.office_id?.value || values?.office_id
    };

    return codesApi.updatePayer(editedPayer).then(() => {
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      history.push(`${baseUrl}/payer`);
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
      }
    });
  };

  useEffect(() => {
    const fetchCode = () => {
      codesApi.fetchPayer(params.id).then((data) => {
        data.company_id = data.company;
        data.office_id = data.office;
        data.financial_class_id = transformClassToOption(data.financial_class);
        data.claim_filing_id = transformDataToOption(data.claim_filing);
        data.claim_processing = transformToOption(data.claim_processing);
        data.box_17 = transformToOption(data.box_17);
        data.box_31 = transformToOption(data.box_31);
        data.box_33 = transformToOption(data.box_33);
        data.box_24 = transformToOption(data.box_24);
        data.box_32 = transformToOption(data.box_32);
        data.timely_filling = transformToOption(data.timely_filling);
        data.insurance_type_code = transformToOption(data.insurance_type_code);
        data.provider_id_used = transformToOption(data.provider_id_used);
        data.secondary_e_claims = !!data.secondary_e_claims;
        data.npi_only = !!data.npi_only;
        data.accept_npi = !!data.accept_npi;

        setInitialValues(data);
      }).catch(() => {
        history.push(`${baseUrl}/payer`);
      });
    };

    fetchCode();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues || {}}
      validationSchema={validationSchema}
      onSubmit={updateCode}
    >
      {({ isSubmitting, handleSubmit }) => (
        <form noValidate className={classes.root} onSubmit={handleSubmit}>
          <Page>
            <PageHeader>
              <IconButton component={Link} to={`${baseUrl}/payer`} color="primary" edge="start">
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>

              <PageHeaderTitle>Edit Payer</PageHeaderTitle>

              <PageHeaderActions>
                <Button component={Link} to={`${baseUrl}/payer`} color="primary">
                  Cancel
                </Button>

                <Loader
                  surface
                  loading={isSubmitting}
                  render={() => (
                    <Button autoFocus disabled={isSubmitting} type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  )}
                />
              </PageHeaderActions>
            </PageHeader>

            <PageBody>
              <Loader
                loading={!initialValues}
                render={() => (
                  <>
                    <Header />
                    <Body />
                  </>
                )}
              />
            </PageBody>
          </Page>
        </form>
      )}
    </Formik>
  );
};
