import moment from 'moment';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useContext, useState } from 'react';
import * as remindersApi from '../../../../../../api/cases/reminders/case-reminders';
import { ColorPreview } from '../../../../../ColorPreview';
import { IconButton } from '../../../../../IconButton';
import { Loader } from '../../../../../Loader';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../ListRow';
import { useModal } from '../../../../../ModalsProvider';
import { Tooltip } from '../../../../../Tooltip';
import { CaseLink } from '../../../../CaseLink';
import { CaseRemindersContext } from '../../CaseRemindersProvider';
import { EditCaseReminderModal } from '../../EditCaseReminderModal';
import { getReminderGroupTitle } from '../../getReminderGroupTitle';
import { columnsMap, columnsWidths } from '../CaseRemindersList';

const actionsMap = {
  changeSettings: 'changeSettings',
  toggleActive: 'toggleActive',
  toggleDone: 'toggleDone'
};

export const ReminderRow = ({
  item: reminder,
  isLoaded,
  hiddenColumns = [],
  ListRowProps = {},
  onClick = () => {}
}) => {
  const { openModal } = useModal();
  const isGroupHidden = hiddenColumns.includes(columnsMap.group);
  const { caseId, updateReminder, resetReminders } = useContext(CaseRemindersContext);
  const [ activeActions, setActiveActions ] = useState([]);
  const { groupTitle, shortGroupTitle } = getReminderGroupTitle(reminder?.group);

  const addActionToActive = (action) => {
    setActiveActions((activeActions) => activeActions.concat(action));
  };

  const removeActionFromActive = (removableAction) => {
    setActiveActions((activeActions) => activeActions.filter((action) => action !== removableAction));
  };

  const changeSettings = (name) => () => {
    addActionToActive(actionsMap.changeSettings);

    remindersApi.updateCaseReminderSettings(reminder, {
      [name]: !reminder.settings[name]
    }).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.changeSettings);
    });
  };

  const toggleActive = () => {
    addActionToActive(actionsMap.toggleActive);

    remindersApi.toggleCaseReminderActive(reminder, {
      is_active: !reminder.is_active
    }).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.toggleActive);
    });
  };

  const doneReminder = () => {
    addActionToActive(actionsMap.toggleDone);

    remindersApi.doneCaseReminder(reminder).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.toggleDone);
    });
  };

  const undoneReminder = () => {
    addActionToActive(actionsMap.toggleDone);

    remindersApi.undoneCaseReminder(reminder).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.toggleDone);
    });
  };

  const editReminder = () => {
    openModal(EditCaseReminderModal, {
      payload: {
        caseReminder: reminder
      },
      onModalResolved: () => {
        resetReminders();
      }
    });
  };

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow px={1} onClick={onClick} {...ListRowProps}>
      <ListRowCell flexBasis={columnsWidths.eventNumber}>
        {reminder.position || '-'}
      </ListRowCell>

      {!isGroupHidden &&
        <ListRowCell column flexBasis={columnsWidths.group}>
          <ListRowCellActions start withSpacings>
            <ColorPreview color={reminder?.group?.color}/>

            <Tooltip title={groupTitle}>
              <span>
                {shortGroupTitle}
              </span>
            </Tooltip>
          </ListRowCellActions>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.type}>
        {reminder?.type || '-'}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.note}>
        {reminder?.description || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {reminder?.date ? moment(reminder?.date).format('L') : '-'}
      </ListRowCell>

      {!caseId &&
        <ListRowCell noWrap flexBasis={columnsWidths.caseItem}>
          <CaseLink noWrap caseItem={reminder?.case} />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.relative}>
        {reminder?.previousReminder?.position || 'No'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.settings}>
        <Loader
          surface
          loading={activeActions.includes(actionsMap.changeSettings)}
          render={() => (
            <ListRowCellActions start>
              <IconButton
                edge="start"
                color={reminder?.settings?.by_sms ? 'primary' : 'default'}
                onClick={changeSettings('by_sms')}
              >
                <CallIcon />
              </IconButton>

              <IconButton
                color={reminder?.settings?.by_email ? 'primary' : 'default'}
                onClick={changeSettings('by_email')}
              >
                <MailOutlineOutlinedIcon />
              </IconButton>
            </ListRowCellActions>
          )}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.active}>
        <Loader
          surface
          loading={activeActions.includes(actionsMap.toggleActive)}
          render={() => (
            <IconButton
              edge="start"
              color={reminder?.is_active ? 'success' : 'warning'}
              onClick={toggleActive}
            >
              {reminder?.is_active ? (
                <NotificationsActiveOutlinedIcon />
              ) : (
                <NotificationsOffOutlinedIcon />
              )}
            </IconButton>
          )}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.done}>
        <Loader
          surface
          loading={activeActions.includes(actionsMap.toggleDone)}
          render={() => (
            <IconButton
              edge="start"
              color={reminder?.done_at ? 'success' : 'warning'}
              onClick={reminder?.done_at ? undoneReminder : doneReminder}
            >
              <CheckCircleOutlinedIcon />
            </IconButton>
          )}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.edit}>
        <IconButton color="info" onClick={editReminder}>
          <EditIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
