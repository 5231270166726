import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as appointmentBooksApi from '../../../../../../api/codes/appointments/appointment-books';
import { Loader } from '../../../../../../components/Loader';
import {
  Page,
  PageBody,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../../../components/Page';
import { initialValues } from '../initialValues';
import { validationSchema } from '../validationSchema';
import { ApptBookForm } from '../ApptBookForm';

export const Edit = ({ baseUrl }) => {
  const history = useHistory();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ appointmentBook, setAppointmentBook ] = useState(null);

  const updateAppointmentBook = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return appointmentBooksApi.updateAppointmentBook(values).then(() => {
      enqueueSnackbar(
        'Appointment book successfully updated',
        { variant: 'success' }
      );
      history.push(`${baseUrl}/appointment-books`);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422 && errors) {
        setErrors(errors);
      }
    });
  };

  useEffect(() => {
    const fetchAppointmentBook = () => {
      appointmentBooksApi.fetchAppointmentBook(params.id).then((data) => {
        data.provider_id = data.provider;
        data.office_id = data.office;
        data.default_arrival_status_id = data.default_arrival_status;
        data.default_appointment_type_id = data.default_appointment_type;
        data.work_schedules = !data?.work_schedules?.length
          ? initialValues.work_schedules
          : data?.work_schedules?.map((item) => {
            if (!item.breaks?.length) {
              item.breaks = [ { from: null, to: null } ];
            }

            if (item.day === null && item.date === null) {
              return null;
            }

            return item;
          }).filter((item) => !!item);

        setAppointmentBook(data);
      }).catch(() => {
        history.push(`${baseUrl}/appointment-books`);
      });
    };

    fetchAppointmentBook();
  }, []);

  return (
    <Loader p={3} loading={!appointmentBook} render={
      () => (
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          validateOnTouched={false}
          validationSchema={validationSchema}
          initialValues={appointmentBook || {}}
          onSubmit={updateAppointmentBook}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Page noValidate component="form" onSubmit={handleSubmit}>
              <PageHeader>
                <IconButton
                  component={Link}
                  to={`${baseUrl}/appointment-books`}
                  color="primary"
                  edge="start"
                >
                  <KeyboardBackspaceOutlinedIcon />
                </IconButton>

                <PageHeaderTitle>
                  {isMobile ? 'Edit appt. book' : 'Edit appointment book'}
                </PageHeaderTitle>

                <PageHeaderActions>
                  <Button
                    size={isMobile ? 'small' : 'medium'}
                    component={Link}
                    to={`${baseUrl}/appointment-books`}
                    color="primary"
                  >
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        autoFocus
                        size={isMobile ? 'small' : 'medium'}
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                  />
                </PageHeaderActions>
              </PageHeader>

              <PageBody>
                <ApptBookForm appointmentBook={appointmentBook} />
              </PageBody>
            </Page>
          )}
        </Formik>
      )}
    />
  );
};
