import { useContext } from 'react';
import moment from 'moment';
import { Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ColorPreview } from '../../../../../components/ColorPreview';
import { TaskPreviewContext } from '../../../../../components/tasks/TasksProvider';
import { ProgressBar } from '../../MainWindow/ProgressBar';
import { Label } from '../../MainWindow/Label';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const StatusBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const current_day = moment().unix();
  const { selectedTask } = useContext(TaskPreviewContext);

  return (
    <Grid container className={classes.header}>
      <Grid
        container
        item
        xs={isMobile ? 12 : 4}
        spacing={isMobile ? 2 : 0}
        alignItems="center"
        className={classes.grid}
      >
        <Grid item xs={selectedTask.type ? 4 : 12}>
          <Typography variant="subtitle1">
            Type
          </Typography>
        </Grid>

        {selectedTask.type &&
          <Grid item container xs={8} spacing={1}>
            <Grid item>
              <ColorPreview color={selectedTask.type?.color} />
            </Grid>

            <Grid item>
              <Typography color="textSecondary">
                {selectedTask.type?.name}
              </Typography>
            </Grid>
          </Grid>
        }

        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Estimated
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <ProgressBar logged={selectedTask.logged_time} estimate={selectedTask.estimated_time} />
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={isMobile ? 12 : 4}
        spacing={isMobile ? 2 : 0}
        alignItems="center"
        className={classes.grid}
      >
        <Grid item xs={selectedTask.priority ? 4 : 12}>
          <Typography variant="subtitle1">
            Priority
          </Typography>
        </Grid>

        {selectedTask.priorityLabel &&
          <Grid item xs={8}>
            <Label name={selectedTask.priorityLabel} color={selectedTask.priority} />
          </Grid>
        }

        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Due date
          </Typography>
        </Grid>

        <Grid item xs={7}>
          {selectedTask.due_at &&
            <ProgressBar
              isDueDateProgress
              logged={current_day - selectedTask.created_at}
              estimate={selectedTask.due_at - selectedTask.created_at}
            />
          }
        </Grid>
      </Grid>

      <Grid
        container
        item
        spacing={isMobile ? 2 : 0}
        xs={isMobile ? 12 : 4}
        alignItems="center"
        className={classes.grid}
      >
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            Status
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography color="textSecondary">
            {selectedTask?.status?.name}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1">
            My work status
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography color="textSecondary">
            {selectedTask?.work_status}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
