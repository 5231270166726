import moment from 'moment';
import { Box, Link, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import { AppointmentViewModal } from '../../appointments';
import { fieldsMap } from '../../appointments/Activity';
import { useModal } from '../../ModalsProvider';
import { WidgetActivity } from './WidgetActivity';

export const AppointmentsActivity = ({
  isDeleted = false,
  isNew = false,
  payload,
  fieldLabel,
  activityLabel
}) => {
  const { openModal } = useModal();

  const openPreviewModal = () => {
    if (!payload?.appointment?.id) return;

    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: payload?.appointment?.id
      }
    });
  };

  if (isDeleted) {
    return 'Removed Appointment';
  }

  return (
    <>
      {isNew && (
        <Box component="span" display="flex" alignItems="center">
          Added new Appointment
          &nbsp;
          <Link variant="subtitle1" onClick={openPreviewModal}>
            <Box mr={1} component="span">
              <CalendarIcon fontSize="small" />
            </Box>

            {moment.unix(payload?.appointment?.appointment_at).format('L LT')}
          </Link>

          {payload?.appointment_book?.appointment_book_name && (
            <>
              &nbsp;
              with Appt. Book:
              &nbsp;
              <Typography variant="subtitle1">
                {payload?.appointment_book?.appointment_book_name}
              </Typography>
            </>
          )}
        </Box>
      )}

      <WidgetActivity
        {...payload?.properties}
        fieldLabel={fieldLabel}
        activityLabel={activityLabel}
        fieldsMap={fieldsMap}
      />
    </>
  );
};
