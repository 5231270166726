import { Button, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { TextField } from '../../../../components/FormField';
import { Loader } from '../../../../components/Loader';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChecklistForm = ({
  disableSubmit = false,
  isChecked = false,
  count = 0,
  buttonSize = 'medium',
  checklist = [],
  onClick = () => {},
  onReset = () => {},
  onAddItem = () => {}
}) => {
  const classes = useStyles();
  const { values, dirty, isSubmitting, resetForm } = useFormikContext();

  return (
    <>
      <div className={classes.form}>
        <TextField
          name="text"
          placeholder="Add item"
          variant="outlined"
          margin="dense"
          InputProps={{
            className: classes.input
          }}
        />
      </div>

      <div className={classes.actions}>
        {!disableSubmit && (
          <Button
            size={buttonSize}
            disabled={(!isChecked && !checklist?.length) || count === 0}
            variant="contained"
            className={classes.toggleCheckedVisibilityButton}
            onClick={onClick}
          >
            {isChecked ? `Show checked (${count})` : 'Hide checked' }
          </Button>
        )}

        {!!values?.text?.trim() && (
          <Button
            size={buttonSize}
            disabled={!dirty}
            className={classes.cancelButton}
            onClick={disableSubmit ? onReset : resetForm}
          >
            Cancel
          </Button>
        )}

        {!disableSubmit ? (
          <Loader surface loading={isSubmitting} render={
            () => (
              <Button
                size={buttonSize}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting}
              >
                Add item
              </Button>
            )}
          />
        ) : (
          <Button
            size={buttonSize}
            variant="contained"
            color="primary"
            disabled={!values?.text?.trim()}
            onClick={onAddItem(values.text)}
          >
            Add item
          </Button>
        )}
      </div>
    </>
  );
};
