import { makeStyles } from '@material-ui/core';
import OwnerIcon from '../../icons/owner-label.svg';
import { Badge } from '../../Badge';
import { SvgIcon } from '../../SvgIcon';
import { Tooltip } from '../../Tooltip';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MemberIndicator = (props) => {
  const classes = useStyles();

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      badgeContent={
        <Tooltip title="Owner">
          <SvgIcon className={classes.badgeIcon}>
            <OwnerIcon />
          </SvgIcon>
        </Tooltip>
      }

      {...props}
    />
  );
};
