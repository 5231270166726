import { useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { makeStyles, Box, Grid, Typography, useTheme } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '../../../../../components/FormField';
import { IconButton } from '../../../../../components/IconButton';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../components/ListRow';
import { Tag } from '../../../../../components/Tag';
import { Tooltip } from '../../../../../components/Tooltip';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { FilePreviewIcon, FilePreviewModal } from '../../../files-common';
import { columnsWidths } from '../listConfig';
import { pagesSelectingTypesMap, pagesSelectingTypes } from '../pagesSelectingTypes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  document = {},
  documents,
  index,
  dragHandleProps = {},
  onDocumentRemove = () => {},
  onDocumentChange = () => {}
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = width <= theme.breakpoints.values.mobileSm;
  const [ pagesSelectingType, setPagesSelectingType ] = useState(pagesSelectingTypes[0]);
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file: document,
        files: documents.map(({ file }) => file)
      }}
    />
  ), [ document, documents ]);

  const handlePagesChange = (pages) => {
    onDocumentChange(index, {
      ...document,

      pages: pages?.map(({ value }) => value)
    });
  };

  const handlePagesSelectingTypeChange = (option) => {
    setPagesSelectingType(option);

    onDocumentChange(index, {
      ...document,

      pagesSelectingType: option?.value
    });
  };

  const removeFile = () => {
    onDocumentRemove(index);
  };

  return (
    <ListRow ref={rootRef} px={0}>
      <ListRowCell px={0} flexBasis={columnsWidths.dragIcon} {...dragHandleProps}>
        <div className={classes.dragHandleContent}>
          <DragIndicatorIcon />
        </div>
      </ListRowCell>

      <ListRowCell grow actions flexBasis={columnsWidths.file}>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="flex-start"
          alignItems={isMobile ? 'flex-start' : 'center'}
          width="100%"
        >
          <FilePreviewIcon file={document.file} onClick={openFilesPreview} />

          <div>
            <Typography style={{ wordBreak: 'break-all' }}>
              {document.file?.original_filename}
            </Typography>

            <Box display="flex" flexWrap="wrap">
              {document.file?.tags?.map((tag) => (
                <Box key={tag.id} m={0.25}>
                  <Tag tag={tag} />
                </Box>
              ))}
            </Box>
          </div>
        </Box>
      </ListRowCell>

      <ListRowCell grow={isMobile} flexBasis={columnsWidths.selectPages}>
        <Grid container spacing={isMobile ? 0 : 2} wrap={isMobile ? 'wrap' : 'nowrap'}>
          <Grid item>
            <Autocomplete
              disableSearch
              disableClearable
              withoutFormik
              value={pagesSelectingType}
              label="Select variant"
              options={pagesSelectingTypes}
              onChange={handlePagesSelectingTypeChange}
            />
          </Grid>

          {(pagesSelectingTypesMap.all !== document.pagesSelectingType) &&
            <Grid item>
              <Autocomplete
                multiple
                withoutFormik
                label="Pages"
                placeholder="Select pages..."
                options={document.pagesOptions}
                onChange={handlePagesChange}
              />
            </Grid>
          }
        </Grid>
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <ListRowCellActions>
          <Tooltip title="Remove file">
            <IconButton edge="end" color="error" onClick={removeFile}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
