import { useFormikContext } from 'formik';
import { Box, Grid } from '@material-ui/core';
import { DatePicker, OfficesLocationSelect } from '../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../components/users';

export const Header = () => {
  const { values } = useFormikContext();

  return (
    <Grid container spacing={2} justify="space-between" component={Box} p={2}>
      <Grid item sm={5} xs={12}>
        <UsersSelect
          disabled
          params={{ is_patient: 1 }}
          name="patient_id"
          label="Patient"
        />
      </Grid>

      <Grid item sm={5} xs={12}>
        <DatePicker
          isEditable={false}
          name="dob"
          label="DOB"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item xs={12}>
        <UsersSelect
          required
          params={{ role: 'doctor' }}
          name="provider_id"
          label="Provider"
        />
      </Grid>

      <Grid item xs={12}>
        <OfficesLocationSelect
          name="office_id"
          label="Office"
          params={{ users: [ values.provider_id ] }}
        />
      </Grid>
    </Grid>
  );
};
