import { Yup } from '../../../../../utils/validation';

export const getValidationSchema = (hideWorkOffice) => {
  const companySchema = Yup.mixed().nullable();
  const officeSchema = Yup.mixed().nullable();

  return Yup.object().shape({
    email: Yup.string().email().nullable().required().max(255),
    first_name: Yup.string().nullable().required().min(2).max(36),
    last_name: Yup.string().nullable().required().min(2).max(36),
    is_active: Yup.boolean(),
    work: Yup.object().shape({
      position: Yup.string().nullable().min(2),
      company_id: hideWorkOffice ? companySchema : companySchema.required(),
      office_id: hideWorkOffice ? officeSchema : companySchema.required()
    })
  });
};

