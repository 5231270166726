import { useMediaQuery, useTheme } from '@material-ui/core';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import * as pages from '../../../../../store/dashboard/layout/pages';
import { hasRole } from '../../../../../utils/hasRole';
import { MobileWidgets } from '../../../../../components/Widgets/MobileWidgets';
import { checkedWidgetsTypes as clientCheckedWidgetsTypes } from './ClientLayouts';
import { widgetsMap as clientWidgetsMap } from './Widgets/clientWidgetsMap';
import { ProfileMainInfo } from './ProfileMainInfo';
import { ClientMainInfo } from './ClientMainInfo';
import { checkedWidgetsTypes } from './Layouts';
import { widgetsMap } from './Widgets';

export const MainInfo = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isClient = hasRole(rolesMap.client, rolesMap.patient);

  return isTablet ? (
    <MobileWidgets
      page={isClient ? pages.medicalClientProfile : pages.medicalProfile}
      widgetsMap={isClient ? clientWidgetsMap : widgetsMap}
      checkedWidgetsTypes={isClient ? clientCheckedWidgetsTypes : checkedWidgetsTypes}
    />
  ) : isClient ? <ClientMainInfo /> : <ProfileMainInfo />;
};
