export const styles = ({ spacing, palette: { primary } }) => ({
  root: {
    paddingLeft: spacing()
  },

  userInfo: {
    display: 'flex',
    alignItems: 'center'
  },

  avatar: {
    marginRight: spacing()
  },

  time: {
    cursor: 'pointer',
    color: primary.main,

    '&:hover': {
      textDecoration: 'underline'
    }
  }
});
