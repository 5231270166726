import { createHookReducer } from '../../../../../helpers/createHookReducer';
import { isPrimary } from './isPrimary';
import * as types from './types';

const getMainInsurance = (insurances) => {
  return insurances.find(({ status_type }) => isPrimary(status_type)) || null;
};

const updateInsurancesList = (newInsurances) => {
  return newInsurances.sort(({ status_type }) => isPrimary(status_type) ? -1 : 0) || null;
};

export const reducer = createHookReducer({
  [types.INSURANCES_FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.INSURANCES_FETCH_SUCCESS]: ({ filter, insurances, ...state }, payload) => {
    const mainInsurance = getMainInsurance(payload?.data);
    const insurancesData = updateInsurancesList(payload?.data);

    return {
      ...state,

      isFetching: false,
      isFetched: true,
      insurances: insurancesData,
      mainInsurance
    };
  },

  [types.ALL_INSURANCES_FETCH_SUCCESS]: (
    { filter, insurances, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      insurances: data
    };
  },

  [types.ADD_INSURANCE]: ({ insurances, ...state }, payload) => {
    const newInsuranceList = isPrimary(payload.status_type) ?
      insurances.map((item) => ({
        ...item,
        status_type: isPrimary(item.status_type) ? 'secondary' : item.status_type
      })) : insurances;

    return {
      ...state,

      insurances: updateInsurancesList([ payload, ...newInsuranceList ]),
      mainInsurance: getMainInsurance([ payload, ...newInsuranceList ])
    };
  },

  [types.UPDATE_INSURANCE]: ({ insurances, ...state }, payload) => {
    const newInsuranceList = isPrimary(payload.status_type) ? (
      insurances.map((item) => item.id === payload.id
        ? payload
        : { ...item, status_type: isPrimary(item.status_type) ? 'secondary' : item.status_type })
    ) : (
      insurances.map((item) => item.id === payload.id ? payload : item)
    );
    const mainInsurance = getMainInsurance(newInsuranceList);
    const insurancesData = updateInsurancesList(newInsuranceList);

    return {
      ...state,

      insurances: insurancesData,
      mainInsurance
    };
  },

  [types.DELETE_INSURANCE_SUCCESS]: ({ insurances, ...state }, insurancenId) => {
    const newInsuranceList = insurances.filter(({ id }) => id !== insurancenId);
    const mainInsurance = insurancenId !== state?.mainInsurance?.id ? getMainInsurance(newInsuranceList) : null;
    const insurancesData = updateInsurancesList(newInsuranceList);

    return {
      ...state,
      insurances: insurancesData,
      mainInsurance
    };
  },

  [types.RESET_INSURANCES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.TOGGLE_ALL_INSURANCES_SELECTION]: ({ insurances, selectedIDs, ...state }) => {
    const allInsurancesIsSelected = insurances.length === selectedIDs.length;

    return {
      ...state,

      insurances,
      selectedIDs: allInsurancesIsSelected ? [] : insurances.map((item) => item.id)
    };
  },

  [types.TOGGLE_INSURANCE_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1
        ? selectedIDs.filter((item) => item !== id)
        : selectedIDs.concat(id)
    };
  }
});
