import { Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap variant="h5">
          File name
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updateAfter}>
        <Typography noWrap variant="h5">
          Update after
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updateBefore}>
        <Typography noWrap variant="h5">
          Update before
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.cases}>
        <Typography noWrap variant="h5">
          Cases
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.tasks}>
        <Typography noWrap variant="h5">
          Tasks
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.orderBy}>
        <Typography noWrap variant="h5">
          Order
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>

      </ListRowCell>
    </ListRow>
  );
};
