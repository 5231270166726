import { Box, Chip } from '@material-ui/core';
import { Label } from '../../../../app/Dashboard/TasksPage/MainWindow/Label';
import { colorsMap } from '../../../../app/Dashboard/TasksPage/MainWindow/Label/colorsMap';
import { tasksPrioritiesOptions } from '../../../../dataMaps';
import { palette } from '../../../../themes/palette';
import { Autocomplete } from '../../Autocomplete';

const renderOption = (option) => {
  return (
    <Box key={option.value}>
      <Label name={option?.label} color={option?.value} />
    </Box>
  );
};

export const TaskPrioritySelect = ({ multiple = false, params = {}, creatablePayload, ...props }) => {
  return (
    <Autocomplete
      multiple={multiple}
      placeholder="Select priority..."
      renderOption={renderOption}
      getOptionSelected={(option, value) => (option?.value === value?.value)}
      getOptionLabel={(option) => multiple ? option?.label : ''}
      getOptionValue={(option) => option?.value}
      options={tasksPrioritiesOptions}

      getInputProps={(value) => !(!multiple && value) ? null : ({
        startAdornment: (
          <Chip
            variant="outlined"
            label={value?.label}
            style={{ backgroundColor: colorsMap[value.value], color: palette().white.main }}
          />
        )
      })}

      renderTags={(types, getTagProps) => (
        types.map((type, index) => (
          <Chip
            variant="outlined"
            label={type?.label}
            style={{ backgroundColor: colorsMap[type.value], color: palette().white.main }}

            {...getTagProps({ index })}
          />
        ))
      )}

      {...props}
    />
  );
};
