import { core } from './core';
import { overrides } from './overrides';

const palette = {
  type: 'dark',
  // divider: 'rgba(255, 255, 255, 0.12)',

  background: {
    paper: '#303030',
    default: '#303030',
    primary: '#2b2b2b'
  },

  action: {
    active: '#fff',
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    hover: 'rgba(255, 255, 255, 0.1)',
    hoverOpacity: 0.1,
    selected: 'rgba(255, 255, 255, 0.2)'
  }
};

const theme = core({
  palette
});

theme.overrides = overrides(theme);

export { theme };
