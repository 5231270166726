import { makeStyles, Typography } from '@material-ui/core';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Indicator = ({ label, color, amount = 0 }) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <NotificationsNoneOutlinedIcon className={classes.icon} />
      </div>

      <div className={classes.info}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.amount}>{amount}</Typography>
      </div>
    </div>
  );
};
