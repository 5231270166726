import { api } from '../api';

export const fetchUserStorage = (config = {}) => {
  return api.get('/user-storage', config).then(({ data: { data } }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const updateUserStorage = (data) => {
  return api.post('/user-storage', { data })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};
