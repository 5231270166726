import { transformRtcToOption } from '../../../FormField';
import { typeOfAccidentOptions } from '../../../medical/accidentTypes';
import {
  autoAccidentStatesOptions,
  certificationTypesOptions,
  conditionIndicatorsOptions,
  epsdtConditionCodesOptions,
  natureCodesOptions,
  noteReferenceCodesOptions,
  qualifiersOptions,
  reportTransmissionsOptions,
  returnWorkIndicatorsOptions,
  xRayAvailableOptions
} from './data';

export const transformSituationalNumberForForm = (situationalNumber) => {
  if (!situationalNumber) {
    return situationalNumber;
  }

  return {
    ...situationalNumber,

    report_type_code_id: transformRtcToOption(situationalNumber.report_type),
    accident_type: typeOfAccidentOptions.find(({ value }) => value === situationalNumber.accident_type),
    xray_available: xRayAvailableOptions.find(({ value }) => value === situationalNumber.xray_available),
    return_work_indicator: returnWorkIndicatorsOptions.find(({ value }) => {
      return value === situationalNumber.return_work_indicator;
    }),
    report_transmission: reportTransmissionsOptions.find(({ value }) => {
      return value === situationalNumber.report_transmission;
    }),
    qualifier: qualifiersOptions.find(({ value }) => value === situationalNumber.qualifier),
    note_reference_code: noteReferenceCodesOptions.find(({ value }) => value === situationalNumber.note_reference_code),
    nature_condition: natureCodesOptions.find(({ value }) => value === situationalNumber.nature_condition),
    epsdt_condition_code: epsdtConditionCodesOptions.find(({ value }) => {
      return value === situationalNumber.epsdt_condition_code;
    }),
    certification_type: certificationTypesOptions.find(({ value }) => value === situationalNumber.certification_type),
    auto_accident_state: autoAccidentStatesOptions.find(({ value }) => value === situationalNumber.auto_accident_state),
    condition_indicators: situationalNumber?.condition_indicators?.map((value) => {
      return conditionIndicatorsOptions.find((option) => option.value === value);
    }),
    vision_rx_date: (
      situationalNumber?.vision_rx_date
      || situationalNumber?.vision_date
      || situationalNumber?.hearing_vision_rx_date
    )
  };
};
