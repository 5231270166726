export const styles = ({ spacing, breakpoints, palette: { background } }) => ({
  root: {
  },

  toolbar: {
    display: 'flex',
    padding: spacing(1, 3),
    background: background.primary,

    [breakpoints.down(breakpoints.values.md)]: {
      padding: spacing(1, 2)
    }
  }
});
