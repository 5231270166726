export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { fontWeightMedium },
  breakpoints
}) => ({
  root: {
    paddingLeft: spacing(5),
    paddingRight: spacing(5),
    marginBottom: spacing(3)
  },

  static: {
    paddingLeft: spacing(2),
    marginBottom: spacing(3),

    [breakpoints.down('md')]: {
      paddingLeft: 0
    }
  },

  title: {
    color: palette.text.caption,
    fontWeight: fontWeightMedium,
    marginRight: spacing(2)
  },

  description: {
    marginBottom: spacing(2)
  },

  content: {
    marginBottom: spacing(3),
    paddingRight: spacing(),
    maxHeight: 220,
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflow: 'auto'
  },

  content_static: {
    minHeight: 100
  },

  closeIcon: {
    color: grey[400]
  }
});
