import cn from 'classnames';
import { makeStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
    width: '100%'
  },

  start: {
    justifyContent: 'flex-start'
  },

  withSpacings: {
    '& > * + *': {
      marginLeft: spacing()
    }
  }
});

const useStyles = makeStyles(styles);

export const ListRowCellActions = ({
  className,
  withSpacings = false,
  start = false,

  ...props
}) => {
  const classes = useStyles();

  return (
    <div
      className={cn(
        classes.root,
        className,
        {
          [classes.start]: start,
          [classes.withSpacings]: withSpacings
        }
      )}

      {...props}
    />
  );
};
