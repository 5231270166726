import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.UPDATE_FORM]: (state, payload) => {
    return {
      ...state,

      form: payload
    };
  },

  [types.FETCH_FORM_REQUEST]: (state) => {
    return {
      ...state,
      form: {},
      isFetched: false
    };
  },

  [types.FETCH_FORM_SUCCESS]: ({ form, ...state }, payload) => {
    return {
      ...state,

      form: payload,
      isFetched: true
    };
  }
});
