export const breadcrumbs = [
  // Services
  { path: '/services', name: 'Services' },
  { path: '/services/:serviceId', name: 'Service sessions' },

  // Messenger
  { path: '/messenger', name: 'Messenger' },
  { path: '/messenger/:threadId', name: 'Thread' },

  // Call reports
  { path: '/call-reports', name: 'Call reports' },

  // Diagnosis
  { path: '/diagnosis', name: 'Diagnosis' },
  { path: '/diagnosis/templates', name: 'Templates' },
  { path: '/diagnosis/templates/:id/edit', name: 'Edit template' },

  // Prescriptions
  { path: '/prescriptions', name: 'Prescriptions' },
  { path: '/prescriptions/templates', name: 'Templates' },

  // Emails
  { path: '/emails/client', name: 'Emails client' },
  { path: '/emails/saved', name: 'Saved emails' },
  { path: '/emails/rules', name: 'Emails rules' },

  // Medical forms
  { path: '/medical-forms', name: 'Medical forms' },
  { path: '/medical-forms/:medicalFormID', name: 'Form' },
  { path: '/medical-forms/:medicalFormID/edit', name: 'Edit' },
  { path: '/medical-forms/:medicalFormID/first-step', name: 'First step' },
  { path: '/medical-forms/:medicalFormID/second-step', name: 'Second step' },
  { path: '/medical-forms/:medicalFormID/third-step', name: 'Third step' },
  { path: '/medical-forms/:medicalFormID/fourth-step', name: 'Fourth step' },
  { path: '/medical-forms/:medicalFormID/fifth-step', name: 'Fifth step' },
  { path: '/medical-forms/:medicalFormID/six-step', name: 'Six step' },
  { path: '/medical-forms/:medicalFormID/seven-step', name: 'Seven step' },

  // Profile
  { path: '/profile', name: 'Profile' },
  { path: '/profile/work-info', name: 'Work info' },
  { path: '/profile/work-sessions', name: 'Work sessions' },
  { path: '/profile/notifications', name: 'Notifications' },
  { path: '/profile/medical-info', name: 'Medical info' },
  { path: '/profile/medical-info/authorizations', name: 'Authorizations' },

  { path: '/profile/medical-info/medical-forms', name: 'Medical forms' },
  { path: '/profile/medical-info/medical-forms/create', name: 'Create form' },
  { path: '/profile/medical-info/medical-forms/:medicalFormID/first-step', name: 'First step' },
  { path: '/profile/medical-info/medical-forms/:medicalFormID/second-step', name: 'Second step' },
  { path: '/profile/medical-info/medical-forms/:medicalFormID/third-step', name: 'Third step' },
  { path: '/profile/medical-info/medical-forms/:medicalFormID/fourth-step', name: 'Fourth step' },
  { path: '/profile/medical-info/medical-forms/:medicalFormID/fifth-step', name: 'Fifth step' },
  { path: '/profile/medical-info/medical-forms/:medicalFormID/six-step', name: 'Six step' },
  { path: '/profile/medical-info/medical-forms/:medicalFormID/seven-step', name: 'Seven step' },

  { path: '/profile/medical-info/medical-report', name: 'Medical report' },
  { path: '/profile/medical-info/medical-report/consultation', name: 'Consultation' },
  { path: '/profile/medical-info/medical-report/weight-history', name: 'Weight history' },
  { path: '/profile/medical-info/medical-report/mri', name: 'MRI' },
  { path: '/profile/medical-info/medical-report/surgeries-procedures', name: 'Surgeries/Procedures' },
  { path: '/profile/medical-info/medical-report/clinical-notes', name: 'Clinical notes' },
  { path: '/profile/medical-info/medical-report/tests', name: 'Tests' },
  { path: '/profile/medical-info/medical-report/medication-list', name: 'Medication list' },
  { path: '/profile/medical-info/medical-report/vaccination', name: 'Vaccination' },
  { path: '/profile/medical-info/medical-report/diagnosis', name: 'Diagnosis' },
  { path: '/profile/medical-info/medical-report/diagnosis/templates', name: 'Templates' },
  { path: '/profile/medical-info/medical-report/diagnosis/templates/:id/edit', name: 'Edit template' },

  { path: '/work-sessions', name: 'Work sessions' },
  { path: '/work-sessions/time-reports', name: 'Time reports' },

  { path: '/profile/medical-info/claims', name: 'Claims details' },
  { path: '/profile/medical-info/claims/:id', name: 'Claim preview' },
  { path: '/profile/medical-info/files', name: 'Files' },
  { path: '/profile/medical-info/emails', name: 'Emails' },

  { path: '/profile/calendar', name: 'Calendar' },
  { path: '/profile/tasks', name: 'Tasks' },
  { path: '/profile/activity', name: 'Activity' },
  { path: '/profile/emails', name: 'Emails' },
  { path: '/profile/files', name: 'Files' },
  { path: '/profile/files/recycle-bin', name: 'Recycle Bin' },
  { path: '/profile/analytics', name: 'Analytics' },
  { path: '/profile/call-reports', name: 'Call Reports' },
  { path: '/profile/time-tracking', name: 'Time tracking & billing' },
  { path: '/profile/archive', name: 'Archive' },

  // Faxes
  { path: '/faxes/income', name: 'Received Faxes' },
  { path: '/faxes/outcome', name: 'Sent Faxes' },


  // Files
  { path: '/files', name: 'Files' },
  { path: '/files/shared', name: 'Shared with me' },
  { path: '/files/recent', name: 'Recent' },
  { path: '/files/search', name: 'Search documents' },
  { path: '/files/merge-pdf', name: 'Merge PDF' },
  { path: '/files/transcribes', name: 'Transcribes' },
  { path: '/files/recycle-bin', name: 'Recycle bin' },
  { path: '/files/templates', name: 'Templates' },
  { path: '/files/templates/new', name: 'New template' },
  { path: '/files/from-printers', name: 'Printers' },
  { path: '/files/from-printers/:id', name: 'Files from printer' },
  { path: '/files/access-control', name: 'Access control' },
  { path: '/files/forms', name: 'Forms' },
  { path: '/files/system-settings/tags', name: 'Tags' },
  { path: '/files/system-settings/configuration', name: 'Printers configurations' },

  // Cases
  { path: '/cases', name: 'Cases' },
  { path: '/cases/:id', name: 'Case profile' },
  { path: '/cases/edit/:id', name: 'Case edit' },
  { path: '/cases/:id/litigation', name: 'Litigation' },
  { path: '/cases/:id/negotiation', name: 'Negotiations' },
  { path: '/cases/:id/files', name: 'Files' },
  { path: '/cases/:id/files/recycle-bin', name: 'Recycle Bin' },
  { path: '/cases/:id/schedule', name: 'Schedule' },
  { path: '/cases/:id/reminders', name: 'Reminders' },
  { path: '/cases/:id/appointments', name: 'Appointments' },
  { path: '/cases/:id/emails', name: 'Emails' },
  { path: '/cases/:id/contacts', name: 'Contacts' },
  { path: '/cases/:id/claims', name: 'Medical Claims' },
  { path: '/cases/:id/discovery', name: 'Discovery' },
  { path: '/cases/:id/billing', name: 'Billing' },
  { path: '/cases/:id/activity', name: 'Activity' },
  { path: '/cases/:id/tasks', name: 'Tasks' },
  { path: '/cases/:id/comments', name: 'Comments' },
  { path: '/cases/:id/call-reports', name: 'Call Reports' },
  { path: '/cases/:id/time-tracking', name: 'Time tracking' },
  { path: '/cases/:id/case-transfer', name: 'Case transfer' },

  // Case reminders
  { path: '/case-reminders', name: 'Case reminders' },
  { path: '/case-reminders/settings', name: 'Settings' },

  // Appointments
  { path: '/appointments', name: 'Appointments' },
  { path: '/appointments/waiting-list', name: 'Waiting list' },

  { path: '/appointments/system-settings', name: 'System settings' },
  { path: '/appointments/system-settings/arrival-statuses', name: 'Arrival statuses' },
  { path: '/appointments/system-settings/arrival-statuses/create', name: 'Create arrival status' },
  { path: '/appointments/system-settings/arrival-statuses/:id/edit', name: 'Edit arrival status' },

  { path: '/appointments/system-settings/appointment-books', name: 'Appointment books' },
  { path: '/appointments/system-settings/appointment-books/create', name: 'Create appointment book' },
  { path: '/appointments/system-settings/appointment-books/:id/edit', name: 'Edit appointment book' },

  { path: '/appointments/system-settings/appointment-types', name: 'Appointment types' },
  { path: '/appointments/system-settings/appointment-types/create', name: 'Create appointment type' },
  { path: '/appointments/system-settings/appointment-types/:id/edit', name: 'Edit appointment type' },

  { path: '/appointments/system-settings/visit-reasons', name: 'Visit reason' },
  { path: '/appointments/system-settings/visit-reasons/create', name: 'Create visit reason' },
  { path: '/appointments/system-settings/visit-reasons/:id/edit', name: 'Edit visit reason' },

  // Schedule
  { path: '/schedule', name: 'Schedule' },
  { path: '/schedule/schedule-event-types', name: 'Schedule event types' },
  { path: '/schedule/schedule-event-templates', name: 'Templates' },

  // Tasks
  { path: '/tasks', name: 'Tasks' },
  { path: '/tasks/:id', name: 'Task info' },
  { path: '/tasks/system-settings', name: 'Settings' },
  { path: '/tasks/system-settings/types', name: 'Task types' },
  { path: '/tasks/system-settings/task-templates/templates', name: 'Task templates' },
  { path: '/tasks/system-settings/task-templates/groups', name: 'Task template groups' },

  // Billing
  { path: '/billing', name: 'Billing' },
  { path: '/billing/dashboard', name: 'Dashboard' },

  { path: '/billing/invoices', name: 'Invoices' },
  { path: '/billing/invoices/create', name: 'Create invoice' },
  { path: '/billing/invoices/:id', name: 'Invoice info' },
  { path: '/billing/invoices/edit/:id', name: 'Invoice edit' },

  { path: '/billing/payments', name: 'Payments' },
  { path: '/billing/payments/received', name: 'Received' },
  { path: '/billing/payments/overdraft', name: 'Overdraft' },
  { path: '/billing/payments/create', name: 'Create payment' },
  { path: '/billing/payments/edit/:id', name: 'Payment edit' },
  { path: '/billing/payments/assign/:id', name: 'Payment assign' },
  { path: '/billing/payments/:id', name: 'Payment info' },

  { path: '/billing/claims', name: 'Claims' },
  { path: '/billing/claims/create', name: 'Create claim' },
  { path: '/billing/claims/:id', name: 'Claim preview' },
  { path: '/billing/claims/:id/edit', name: 'Edit claim' },

  { path: '/billing/system-settings/expense-preference', name: 'Expense settings' },
  { path: '/billing/system-settings/timetrack-category', name: 'Timetracks categories' },
  { path: '/billing/system-settings/codes/financial-class', name: 'Financial Class codes' },
  { path: '/billing/system-settings/codes/payer', name: 'Payer codes' },

  // Claims
  { path: '/claims', name: 'Claims' },
  { path: '/claims/:id', name: 'Claim preview' },

  // Analytics
  { path: '/analytics', name: 'Analytics' },

  // Members
  { path: '/members', name: 'Members' },
  { path: '/members/roles', name: 'Roles' },
  { path: '/members/feature-flags', name: 'Feature Flags' },

  { path: '/members/users', name: 'Users' },
  { path: '/members/users/:userID', name: 'User info' },
  { path: '/members/users/:userID/edit', name: 'Edit' },
  { path: '/members/users/:userID/work-info', name: 'Work info' },
  { path: '/members/users/:userID/calendar', name: 'Calendar' },
  { path: '/members/users/:userID/appointments', name: 'Appointments' },
  { path: '/members/users/:userID/call-reports', name: 'Call Reports' },
  { path: '/members/users/:userID/schedule', name: 'Schedule' },
  { path: '/members/users/:userID/tasks', name: 'Tasks' },
  { path: '/members/users/:userID/activity', name: 'Activity' },
  { path: '/members/users/:userID/emails', name: 'Email' },
  { path: '/members/users/:userID/files', name: 'Files' },
  { path: '/members/users/:userID/files/recycle-bin', name: 'Recycle Bin' },
  { path: '/members/users/:userID/analytics', name: 'Analytics' },
  { path: '/members/users/:userID/time-tracking', name: 'Time tracking & billing' },
  { path: '/members/users/:userID/archive', name: 'Archive' },
  { path: '/members/users/:userID/patients', name: 'Patients' },
  { path: '/members/users/:userID/notifications', name: 'Notifications' },

  { path: '/members/users/:userID/medical-info', name: 'Medical info' },
  { path: '/members/users/:userID/medical-info/claims', name: 'Claims details' },
  { path: '/members/users/:userID/medical-info/authorizations', name: 'Authorizations' },

  { path: '/members/users/:userID/medical-info/medical-forms', name: 'Medical forms' },
  { path: '/members/users/:userID/medical-info/medical-forms/create', name: 'Create form' },
  { path: '/members/users/:userID/medical-info/medical-forms/:medicalFormID/first-step', name: 'First step' },
  // eslint-disable-next-line max-len
  { path: '/members/users/:userID/medical-info/medical-forms/:medicalFormID/second-step', name: 'Second step' },
  { path: '/members/users/:userID/medical-info/medical-forms/:medicalFormID/third-step', name: 'Third step' },
  // eslint-disable-next-line max-len
  { path: '/members/users/:userID/medical-info/medical-forms/:medicalFormID/fourth-step', name: 'Fourth step' },
  { path: '/members/users/:userID/medical-info/medical-forms/:medicalFormID/fifth-step', name: 'Fifth step' },
  { path: '/members/users/:userID/medical-info/medical-forms/:medicalFormID/six-step', name: 'Six step' },
  { path: '/members/users/:userID/medical-info/medical-forms/:medicalFormID/seven-step', name: 'Seven step' },

  { path: '/members/users/:userID/medical-info/medical-report', name: 'Medical report' },
  { path: '/members/users/:userID/medical-info/medical-report/consultation', name: 'Consultation' },
  { path: '/members/users/:userID/medical-info/medical-report/weight-history', name: 'Weight history' },
  { path: '/members/users/:userID/medical-info/medical-report/mri', name: 'MRI' },
  // eslint-disable-next-line max-len
  { path: '/members/users/:userID/medical-info/medical-report/surgeries-procedures', name: 'Surgeries/Procedures' },
  { path: '/members/users/:userID/medical-info/medical-report/clinical-notes', name: 'Clinical notes' },
  { path: '/members/users/:userID/medical-info/medical-report/tests', name: 'Tests' },
  { path: '/members/users/:userID/medical-info/medical-report/medication-list', name: 'Medication list' },
  { path: '/members/users/:userID/medical-info/medical-report/vaccination', name: 'Vaccination' },
  { path: '/members/users/:userID/medical-info/medical-report/diagnosis', name: 'Diagnosis' },
  { path: '/members/users/:userID/medical-info/medical-report/diagnosis/templates', name: 'Templates' },
  // eslint-disable-next-line max-len
  { path: '/members/users/:userID/medical-info/medical-report/diagnosis/templates/:id/edit', name: 'Edit template' },

  // Codes
  { path: '/codes', name: 'Codes' },
  { path: '/codes/dashboard', name: 'Dashboard' },

  { path: '/codes/procedure-codes', name: 'Procedure codes' },
  { path: '/codes/procedure-codes/cpt', name: 'CPT' },
  { path: '/codes/procedure-codes/cpt/create', name: 'Create CPT' },
  { path: '/codes/procedure-codes/cpt/:id/edit', name: 'Edit CPT' },
  { path: '/codes/procedure-codes/icd', name: 'ICD' },
  { path: '/codes/procedure-codes/pos', name: 'POS' },
  { path: '/codes/procedure-codes/ndc', name: 'NDC' },
  { path: '/codes/procedure-codes/modifier', name: 'Modifier' },
  { path: '/codes/procedure-codes/body-part', name: 'Body part' },

  { path: '/codes/procedure-codes/locations', name: 'Locations' },
  { path: '/codes/procedure-codes/locations/create', name: 'Create location' },
  { path: '/codes/procedure-codes/locations/:id/edit', name: 'Edit location' },

  { path: '/codes/payer-codes/payer', name: 'Payer' },
  { path: '/codes/payer-codes/payer/create', name: 'Create' },
  { path: '/codes/payer-codes/payer/:id/edit', name: 'Edit' },

  { path: '/codes/payer-codes/financial-class', name: 'Financial Class' },
  { path: '/codes/payer-codes/financial-class/create', name: 'Create' },
  { path: '/codes/payer-codes/financial-class/:id/edit', name: 'Edit' },

  { path: '/codes/appointments', name: 'Appointment' },
  { path: '/codes/appointments/arrival-statuses', name: 'Arrival statuses' },
  { path: '/codes/appointments/arrival-statuses/create', name: 'Create arrival status' },
  { path: '/codes/appointments/arrival-statuses/:id/edit', name: 'Edit arrival status' },

  { path: '/codes/appointments/appointment-books', name: 'Appointment books' },
  { path: '/codes/appointments/appointment-books/create', name: 'Create appointment book' },
  { path: '/codes/appointments/appointment-books/:id/edit', name: 'Edit appointment book' },

  { path: '/codes/appointments/appointment-types', name: 'Appointment types' },
  { path: '/codes/appointments/appointment-types/create', name: 'Create appointment type' },
  { path: '/codes/appointments/appointment-types/:id/edit', name: 'Edit appointment type' },

  { path: '/codes/appointments/visit-reasons', name: 'Visit reason' },
  { path: '/codes/appointments/visit-reasons/create', name: 'Create visit reason' },
  { path: '/codes/appointments/visit-reasons/:id/edit', name: 'Edit visit reason' },

  // Patients
  { path: '/patients', name: 'Patients' },
  { path: '/patients/:patientID', name: 'Patient info' },
  { path: '/patients/:patientID/edit', name: 'Edit' },

  { path: '/patients/:patientID/medical-info', name: 'Medical info' },
  { path: '/patients/:patientID/medical-info/appointments', name: 'Appointments' },
  { path: '/patients/:patientID/medical-info/call-reports', name: 'Call Reports' },
  { path: '/patients/:patientID/medical-info/authorizations', name: 'Authorizations' },
  { path: '/patients/:patientID/medical-info/notifications', name: 'Notifications' },
  { path: '/patients/:patientID/medical-info/comments', name: 'Comments' },
  { path: '/patients/:patientID/medical-info/medical-forms', name: 'Medical forms' },
  { path: '/patients/:patientID/medical-info/medical-forms/create', name: 'Create form' },
  { path: '/patients/:patientID/medical-info/medical-forms/:medicalFormID/first-step', name: 'First step' },
  { path: '/patients/:patientID/medical-info/medical-forms/:medicalFormID/second-step', name: 'Second step' },
  { path: '/patients/:patientID/medical-info/medical-forms/:medicalFormID/third-step', name: 'Third step' },
  { path: '/patients/:patientID/medical-info/medical-forms/:medicalFormID/fourth-step', name: 'Fourth step' },
  { path: '/patients/:patientID/medical-info/medical-forms/:medicalFormID/fifth-step', name: 'Fifth step' },
  { path: '/patients/:patientID/medical-info/medical-forms/:medicalFormID/six-step', name: 'Six step' },
  { path: '/patients/:patientID/medical-info/medical-forms/:medicalFormID/seven-step', name: 'Seven step' },

  { path: '/patients/:patientID/medical-info/medical-report', name: 'Medical report' },
  { path: '/patients/:patientID/medical-info/medical-report/consultation', name: 'Consultation' },
  { path: '/patients/:patientID/medical-info/medical-report/weight-history', name: 'Weight history' },
  { path: '/patients/:patientID/medical-info/medical-report/mri', name: 'MRI' },
  { path: '/patients/:patientID/medical-info/medical-report/surgeries-procedures', name: 'Surgeries/Procedures' },
  { path: '/patients/:patientID/medical-info/medical-report/clinical-notes', name: 'Clinical notes' },
  { path: '/patients/:patientID/medical-info/medical-report/tests', name: 'Tests' },
  { path: '/patients/:patientID/medical-info/medical-report/medication-list', name: 'Medication list' },
  { path: '/patients/:patientID/medical-info/medical-report/vaccination', name: 'Vaccination' },
  { path: '/patients/:patientID/medical-info/medical-report/diagnosis', name: 'Diagnosis' },
  { path: '/patients/:patientID/medical-info/medical-report/diagnosis/templates', name: 'Templates' },
  { path: '/patients/:patientID/medical-info/medical-report/diagnosis/templates/:id/edit', name: 'Edit template' },

  { path: '/patients/:patientID/medical-info/claims', name: 'Claims details' },
  { path: '/patients/:patientID/medical-info/claims/:id', name: 'Claim preview' },
  { path: '/patients/:patientID/medical-info/files', name: 'Files' },
  { path: '/patients/:patientID/medical-info/files/recycle-bin', name: 'Recycle Bin' },
  { path: '/patients/:patientID/medical-info/emails', name: 'Emails' },
  { path: '/patients/:patientID/medical-info/members', name: 'Members' },

  // Contacts
  { path: '/contacts', name: 'Contacts' },
  { path: '/contacts/companies', name: 'Companies' },
  { path: '/contacts/companies/:id', name: 'Company view' },

  { path: '/contacts/offices', name: 'Offices' },
  { path: '/contacts/offices/:id', name: 'Office view' },

  { path: '/contacts/locations', name: 'Locations' },

  { path: '/contacts/users', name: 'Users' },
  { path: '/contacts/users/:id', name: 'User view' },
  { path: '/contacts/users/:id/edit', name: 'User edit' },

  { path: '/contacts/system-settings/companies-types', name: 'Companies types' },

  // System settings
  { path: '/system-settings', name: 'System settings' },
  { path: '/system-settings/tags', name: 'Tags' },
  { path: '/system-settings/companies-types', name: 'Companies types' },
  { path: '/system-settings/timetrack-category', name: 'Time-track categories' }
];
