import { useMediaQuery, useTheme } from '@material-ui/core';
import { Switch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { ReportsProvider } from '../../../components/medical/reports';
import { Route } from '../../../components/router';
import { Page } from '../../../components/Page';
import {
  MainContent,
  FormWrapper,
  Consultation,
  StepsComponent,
  Edit,
  Templates
} from '../MedicalReports';

export const Diagnosis = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box width="100%" height="100%" bgcolor="grey.100" p={isTablet ? 1 : 2}>
      <Page>
        <ReportsProvider paginateByPage>
          <Switch>
            <Route exact path="/diagnosis" component={MainContent}/>
            <Route exact path="/diagnosis/templates" component={Templates} />
            <Route path="/diagnosis/templates/:id/edit" component={Edit}/>

            <FormWrapper>
              <Route exact path="/diagnosis/:reportID/edit" component={Consultation}/>
              <Route exact path="/diagnosis/:reportID/first-step" component={StepsComponent}/>
              <Route exact path="/diagnosis/:reportID/second-step" component={StepsComponent}/>
              <Route exact path="/diagnosis/:reportID/third-step" component={StepsComponent}/>
              <Route exact path="/diagnosis/:reportID/fourth-step" component={StepsComponent}/>
              <Route exact path="/diagnosis/:reportID/fifth-step" component={StepsComponent}/>
              <Route exact path="/diagnosis/:reportID/six-step" component={StepsComponent}/>
            </FormWrapper>
          </Switch>
        </ReportsProvider>
      </Page>
    </Box>
  );
};
