export const sliderMarks = [
  {
    value: 100,
    label: '100%'
  },
  {
    value: 110,
    label: '110%'
  },
  {
    value: 125,
    label: '125%'
  },
  {
    value: 150,
    label: '150%'
  },
  {
    value: 200,
    label: '200%'
  },
  {
    value: 250,
    label: '250%'
  },
  {
    value: 300,
    label: '300%'
  },
  {
    value: 400,
    label: '400%'
  }
];
