import { useContext } from 'react';
import { Box, SvgIcon, Typography } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiAttachment, mdiClipboardPulseOutline } from '@mdi/js';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../helpers/dom';
import { CardItem, CardHeader } from '../../../../../../components/Cards';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { UserPreviewModal } from '../../../../../../components/users';
import { hasRole } from '../../../../../../utils/hasRole';
import { NotesMenu } from '../../NotesMenu';
import { NotesContext } from '../../NotesProvider';
import { PreviewNote } from '../../PreviewNote';
import { Body } from './Body';

export const Card = ({ item }) => {
  const { openModal } = useModal();
  const { selectedIDs, toggleItemSelection, fetchNotes, updateNote } = useContext(NotesContext);
  const isSelected = selectedIDs.indexOf(item.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(item.id);
  };

  const openPreview = () => {
    openModal(PreviewNote, {
      payload: {
        noteId: item.id,
        onNoteUpdate: updateNote
      },
      onModalResolved: fetchNotes
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <CardItem
      selected={!hasRole(rolesMap.client, rolesMap.patient) && isSelected}
      py={1}
      px={2}
      onClick={stopPropagation(openPreview)}
    >
      <CardHeader
        isSelected={isSelected}
        disableCheckBox={hasRole(rolesMap.client, rolesMap.patient)}
        disableCardMenu={hasRole(rolesMap.client, rolesMap.patient)}
        onItemSelect={handleItemSelection}
        menuComponent={NotesMenu}
        menuComponentProps={{ note: item }}
      >
        <Box display="flex" alignItems="center" color="text.secondary">
          <Box mr={1}>
            <SvgIcon fontSize="small" color="inherit"><Icon path={mdiClipboardPulseOutline} /></SvgIcon>
          </Box>

          <Typography variant="subtitle2" color="inherit" title={item.name}>
            {item.name}
          </Typography>
        </Box>
      </CardHeader>

      <Body item={item} />

      <Box display="flex" alignItems="center" justifyContent="space-between" pt={2} pb={0.5}>
        <UserLink
          size="lg"
          variant="h5"
          user={item.provider}
          onClick={stopPropagation(openUserPreview(item?.provider?.id))}
        >
          <Box mt={-0.5}>
            <Typography color="textSecondary" variant="caption">Provider</Typography>
          </Box>
        </UserLink>

        <Box display="flex" alignItems="center" fontSize={15}>
          <SvgIcon color="inherit"><Icon path={mdiAttachment} /></SvgIcon>

          <Box pl={0.5}>
            <Typography color="inherit">{item?.media_count || 0}</Typography>
          </Box>
        </Box>
      </Box>
    </CardItem>
  );
};
