export const styles = ({
  spacing,
  shape: { borderRadius },
  breakpoints,
  shadows
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
    flexGrow: 1,
    boxShadow: shadows[2],
    borderRadius,

    [breakpoints.up('md')]: {
      marginLeft: spacing(0.5),
      marginRight: spacing(0.5)
    }
  }
});
