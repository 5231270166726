import {
  conditionIndicatorsOptions,
  certificateCodeTypesOptions,
  referenceCodesOptions,
  rentalTypesOptions,
  unitsOptions
} from './data';

export const transformServiceLineForForm = ({ appointment, serviceLine }) => {
  if (!serviceLine) {
    return serviceLine;
  }

  return {
    ...serviceLine,

    authorization_id: appointment.authorization,
    unit: unitsOptions.find(({ value }) => value === serviceLine.unit),
    rental_type: rentalTypesOptions.find(({ value }) => value === serviceLine.rental_type),
    reference_code: referenceCodesOptions.find(({ value }) => value === serviceLine.reference_code),
    certification_type_code: certificateCodeTypesOptions.find(({ value }) => {
      return value === serviceLine.certification_type_code;
    }),
    condition_indicators: serviceLine?.condition_indicators?.map((value) => {
      return conditionIndicatorsOptions.find((option) => option.value === value);
    })
  };
};
