import { Box, Grid, Typography } from '@material-ui/core';
import { Autocomplete, TextField } from '../../../FormField';
import { unitsOptions } from './data';

export const NationalDrugCode = () => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">
          National Drug Code
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextField
            name="package_number"
            label="Package Number"
            placeholder="Enter package number..."
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            name="quantity"
            label="Quantity"
            placeholder="Enter quantity..."
          />
        </Grid>

        <Grid item md={6}>
          <Autocomplete
            name="unit"
            label="Unit"
            placeholder="Select unit..."
            options={unitsOptions}
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            name="unit_price"
            label="Unit Price"
            placeholder="Enter unit price..."
          />
        </Grid>
      </Grid>
    </>
  );
};
