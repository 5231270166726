import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { makeStyles, useTheme, IconButton, Typography, Box } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { stopPropagation } from '../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../dataMaps/apiModels';
import {
  statusTypesColors,
  statusTypesNames
} from '../../../../app/Dashboard/BillingPage/Invoices/InvoicesContext/statusTypes';
import {
  PreviewInvoiceModal
} from '../../../../app/Dashboard/CasesPage/Cases/CaseItem/TabsContent/Billing/PreviewInvoiceModal';
import { ClaimModel } from '../../../../app/Dashboard/BillingPage/Invoices/InvoicesPage/InvoicesContent/ClaimModel';
import { ApptModel } from '../../../../app/Dashboard/BillingPage/Invoices/InvoicesPage/InvoicesContent/ApptModel';
import { CaseModel } from '../../../../app/Dashboard/BillingPage/Invoices/InvoicesPage/InvoicesContent/CaseModel';
import { InvoicesContext } from '../../../../app/Dashboard/BillingPage/Invoices/InvoicesContext';
import { billingParticipantsTypesMap, invoicesTypesMap } from '../../../billing';
import { ReadonlyCheckbox } from '../../../ReadonlyCheckbox';
import { CurrencyFormat } from '../../../CurrencyFormat';
import { ListRow, ListRowCell } from '../../../ListRow';
import { CompanyLink } from '../../../CompanyLink';
import { useModal } from '../../../ModalsProvider';
import { UserLink } from '../../../UserLink';
import { Label } from '../../../Label';
import {
  minRowHeight,
  columnsWidths,
  widthBreakpointSmall,
  widthBreakpointLarge,
  widthBreakpointXXS,
  BREAKPOINT_LARGE,
  widthBreakpointXS
} from '../Billing';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ invoice, width }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { selectedInvoicesIDs, confirmInvoice, cancelInvoice, ...invoicesContext } = useContext(InvoicesContext);
  const isSelected = selectedInvoicesIDs.indexOf(invoice.id) !== -1;
  const { openModal } = useModal();

  const getInvoicePage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.invoice(invoice.id), {
        filter: invoicesContext.filter
      });
    }
  };

  const openInvoiceViewModal = () => {
    openModal(PreviewInvoiceModal, {
      payload: {
        invoiceID: invoice.id,
        confirmInvoice,
        cancelInvoice
      }
    });
  };

  return (
    <ListRow
      button
      selected={isSelected}
      minHeight={minRowHeight}
      title="Open invoice page"
      onClick={stopPropagation(getInvoicePage)}
    >
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <ReadonlyCheckbox fullWidth title={invoice.number} checked={invoice.is_confirmed} />
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointXXS} flexBasis={columnsWidths.billFor}>
        {invoice?.model_type === invoicesTypesMap.case
          ? <CaseModel model={invoice?.model || {}} />
          : invoice?.model_type === invoicesTypesMap.appointment
            ? <ApptModel model={invoice?.model || {}} />
            : invoice?.model_type === invoicesTypesMap.claim
              ? <ClaimModel model={invoice?.model || {}} />
              : '-'
        }
      </ListRowCell>

      {width > widthBreakpointXXS &&
        <ListRowCell flexBasis={columnsWidths.billFrom}>
          {invoice?.bill_from?.participant_type === billingParticipantsTypesMap.company ? (
            <CompanyLink
              noWrap
              size="sm"
              company={invoice?.bill_from?.participant}
            />
          ) : (
            <UserLink
              noWrap
              size="sm"
              user={invoice?.bill_from?.participant}
            />
          )}
        </ListRowCell>
      }

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={columnsWidths.billTo}>
          {invoice?.bill_to?.participant_type === billingParticipantsTypesMap.company ? (
            <CompanyLink
              noWrap
              size="sm"
              company={invoice?.bill_to?.participant}
            />
          ) : (
            <UserLink
              noWrap
              size="sm"
              user={invoice?.bill_to?.participant}
            />
          )}
        </ListRowCell>
      }

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.dueDate}>
          {!invoice.due_date ? '-' :  moment(invoice.due_date).format('L')}
        </ListRowCell>
      }

      {width >= BREAKPOINT_LARGE &&
        <ListRowCell flexBasis={columnsWidths.amount}>
          {!invoice.total ? '-' :
            <CurrencyFormat value={invoice.total} />
          }
        </ListRowCell>
      }

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.balance}>
          <Typography noWrap color="error">
            {!invoice.balance ? '-' :
              <CurrencyFormat value={invoice.balance} />
            }
          </Typography>
        </ListRowCell>
      }

      {width > BREAKPOINT_LARGE &&
        <ListRowCell flexBasis={columnsWidths.paid}>
          <Label
            className={classes.statusLabel}
            label={invoice.is_paid ? 'Paid' : 'Unpaid'}
            color={theme.palette[invoice.is_paid ? 'success' : 'error'].main}
          />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.status}>
        {invoice.status &&
          <Label
            className={classes.statusLabel}
            label={statusTypesNames[invoice.status.name
              ? invoice.status.name.toLowerCase()
              : invoice.status.toLowerCase()
            ]}
            color={statusTypesColors[invoice.status.name
              ? invoice.status.name.toLowerCase()
              : invoice.status.toLowerCase()
            ]}
          />
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Box color="info.main">
          <IconButton color="inherit" onClick={stopPropagation(openInvoiceViewModal)}>
            <EyeIcon />
          </IconButton>
        </Box>
      </ListRowCell>
    </ListRow>
  );
};
