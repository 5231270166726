import cn from 'classnames';
import { useEffect } from 'react';
import moment from 'moment';
import { makeStyles, Paper } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { TimeReportsPreviewModal } from '../../../TimeReportsPreviewModal';
import { stopPropagation } from '../../../../../helpers/dom';
import { IconButton } from '../../../../IconButton';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { Loader } from '../../../../Loader';
import { useModal } from '../../../../ModalsProvider';
import { Popper } from '../../../../Popper';
import { SvgIcon } from '../../../../SvgIcon';
import { Tooltip } from '../../../../Tooltip';
import { UserLink } from '../../../../UserLink';
import { columnsWidths } from '../listConfig';
import { Menu } from './Menu';

const useStyles = makeStyles(({ palette }) => ({
  row: {
    background: lighten(palette.success.main, 0.85)
  }
}));

export const Row = ({
  item: timeReport = {},
  isLoaded,
  recalculateHeight
}) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const openReportPreview = () => {
    openModal(TimeReportsPreviewModal, {
      payload: {
        reportID: timeReport.id
      }
    });
  };

  useEffect(() => {
    if (isLoaded) {
      recalculateHeight();
    }
  }, [ isLoaded, recalculateHeight ]);

  return !isLoaded ? <Loader p={1} /> : (
    <Paper elevation={0} className={cn({ [classes.row]: timeReport.approved_at && timeReport.worker_approved_at })}>
      <ListRow button px={2} onClick={openReportPreview}>
        <ListRowCell flexBasis={columnsWidths.user}>
          <UserLink noWrap user={timeReport.user} />
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.supervisor}>
          <UserLink user={timeReport.supervisor} />
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.start}>
          {moment(timeReport.date_from).format('L')}
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.end}>
          {moment(timeReport.date_to).format('L')}
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.supervisorApprove}>
          <Tooltip title={timeReport.approved_at ? 'Approved by supervisor' : 'Not approved by supervisor'}>
            <SvgIcon color={timeReport.approved_at ? 'success' : 'warning'}>
              <VerifiedUserOutlinedIcon />
            </SvgIcon>
          </Tooltip>
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.workerApprove}>
          <Tooltip title={timeReport.worker_approved_at ? 'Approved by worker' : 'Not approved by worker'}>
            <SvgIcon color={timeReport.worker_approved_at ? 'success' : 'warning'}>
              <VerifiedUserOutlinedIcon />
            </SvgIcon>
          </Tooltip>
        </ListRowCell>

        <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
          <Popper
            placement="left-start"
            anchorRenderer={({ anchorRef, handleToggle }) => (
              <IconButton
                buttonRef={anchorRef}
                edge="end"
                onClick={stopPropagation(handleToggle)}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          >
            {({ handleClose }) => (
              <Menu timeReport={timeReport} onClose={handleClose} />
            )}
          </Popper>
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
