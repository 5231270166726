import { useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Fab, SvgIcon, Tooltip, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { useResizeObserver } from '../../../../../../../helpers/hooks/useResizeObserver';
import { setTracksLastGlobalAction } from '../../../../../../../store/globalActions';
import { filtersKeysMap } from '../../../../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../../../../utils/useMobileFilterCollapse';
import { hasRole } from '../../../../../../../utils/hasRole';
import { TimeTracking as WidgetTimeTracking } from '../../../../../../../components/Widgets/TimeTracking';
import TimerIcon from '../../../../../../../components/icons/chronometer.svg';
import { LayoutContext, viewVariantsMap } from '../../../../../../../components/LayoutContext';
import * as types from '../../../../../../../components/TimeTracking/timeTrackerHelpers/types';
import { ActionsBar } from '../../../../../../../components/Widgets/TimeTracking/ActionsBar';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { TimeTrackingProvider } from '../../../../../../../components/TimeTracking';
import { Grid } from '../../../../../../../components/Widgets/TimeTracking/Grid';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { LogTimeModal } from '../../../../../../../components/LogTime';
import { FiltersBar } from './FiltersBar';

export const TimeTracking = ({
  isTabPage,
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  disableActions = false,
  onCaseItemUpdate = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  initialFilter = {},
  hiddenFilterFields,
  initialLogValues = {},
  ...props
}) => {
  const { openModal } = useModal();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const dispatchRedux = useDispatch();
  const theme = useTheme();
  const isMobile = width <= theme.breakpoints.values.tablet;
  const disableButtons = disableActions ||
    hasRole(rolesMap.client, rolesMap.patient, rolesMap.doctor, rolesMap.medic);
  const { viewVariant } = useContext(LayoutContext);
  const {
    filterOpenButton,
    ScrollWrapper,
    FilterBarWrapper,
    filterBarWrapperProps
  } = useMobileFilterCollapse('tablet', width);
  const scrollWrapperProps = !isMobile ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  const openLogTimeModal = () => {
    openModal(LogTimeModal, {
      payload: {
        caseItem,
        onCaseItemUpdate,
        initialValues: initialLogValues
      },
      onModalResolved: (track) => {
        dispatchRedux(setTracksLastGlobalAction({ type: types.ADD_TRACK, payload: track }));
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      disableScrollbars
      disableDivider={isTabPage}
      elevation={isTabPage && 3}
      title="Time Tracking"
      icon={!disableButtons
        ? (
          <Tooltip title="Add Track">
            <Fab
              color="primary"
              onClick={openLogTimeModal}
            >
              <AddIcon fontSize="small" />
            </Fab>
          </Tooltip>
        ) : false
      }
      leftActions={[
        <SvgIcon color="inherit" fontSize="small">
          <TimerIcon/>
        </SvgIcon>
      ]}
      rightActions={[
        !isTabPage ?
          <WidgetsActionButtons
            isMinimizable
            page={page}
            type={props?.item?.i}
            isDisabledOpen={isDisabledOpen}
            onMinimize={onMinimize}
            onMaximized={onMaximized}
            onClose={onClose}
            onOpen={onOpen}
            onPutItem={onPutItem}
          />
          :
          <ActionsBar isMobile={isMobile} filterOpenButton={filterOpenButton} />
      ]}
      {...props}
    >
      <Box ref={rootRef} display="flex" flexDirection="column" height={height || '100%'} overflow="hidden">
        <TimeTrackingProvider filter={{
          ...initialFilter,

          model: initialLogValues.time_track_model,
          model_id: initialLogValues.time_track_model_id
        }}>
          {isTabPage && (
            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                <FiltersBar
                  filterKey={filtersKeysMap.cases_timetracks}
                  hiddenFilterFields={hiddenFilterFields}
                  caseItem={caseItem}
                />
              </FilterBarWrapper>
            </ScrollWrapper>
          )}

          <Box flexGrow={1}>
            {isTabPage ? (
              isMobile || viewVariant === viewVariantsMap.grid ? (
                <Grid caseItem={caseItem} disableActions={disableButtons} />
              ) : viewVariant === viewVariantsMap.list ? (
                <WidgetTimeTracking
                  caseItem={caseItem}
                  disableActions={disableButtons}
                  width={width}
                />
              ) : null
            ) : (
              isMobile ? <Grid caseItem={caseItem} disableActions={disableButtons} /> : (
                <WidgetTimeTracking
                  caseItem={caseItem}
                  disableActions={disableButtons}
                  width={width}
                />
              )
            )}
          </Box>
        </TimeTrackingProvider>
      </Box>
    </ContentCard>
  );
};
