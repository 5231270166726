import { createContext, useRef, useEffect, useReducer, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { merge, omit, isEqual } from 'lodash';
import { tagsActionsMap } from '../../../../api/files/tags';
import { usePrevious } from '../../../../helpers/hooks';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useModal } from '../../../../components/ModalsProvider';
import * as faxApi from '../../../../api/sip';
import { api } from '../../../../api';
import { setFilesLastGlobalAction } from '../../../../store/globalActions';
import { ShareByEmailModal } from '../../files-common';
import * as fileTypes from '../../files-common/FilesContext/types';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const FaxContext = createContext();

export const FaxProvider = ({ filter: filterProp, children }) => {
  const [
    {
      faxes,
      isFetched,
      isFetching,
      filter,
      pagination,
      selectedFaxesIDs,
      selectedFaxes
    },

    dispatch
  ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});
  const { openModal } = useModal();
  const reduxDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const medias = useMemo(() => {
    return selectedFaxes?.reduce((arr, item) => {
      if (item?.files?.length) {
        arr = [ ...arr, ...item?.files ];
      }

      return arr;
    }, []);
  }, [ selectedFaxes ]);

  const fetchFaxes = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FAXES_REQUEST });

    faxApi.fetchFaxes({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_FAXES_SUCCESS, payload: data });
    }).catch((error) => {
      if (error) {
        dispatch({ type: types.FETCH_FAXES_ERROR, payload: error?.data?.message });
      }
    });
  };

  const resendFax = (faxID) => {
    faxApi.resendFax(faxID).then(() => {
      enqueueSnackbar('Fax successfully resented', { variant: 'success' });
      resetFaxes();
    });
  };

  const addFaxesToSelected = (faxes) => {
    dispatch({ type: types.ADD_FAXES_TO_SELECTED, payload: faxes });
  };

  const deleteFaxesFromSelected = (faxesIDs) => {
    dispatch({ type: types.DELETE_FAXES_FROM_SELECTED, payload: faxesIDs });
  };

  const resetFaxesFromSelected = () => {
    dispatch({ type: types.RESET_FAXES_SELECTED });
  };

  const deleteFaxes = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        faxApi.deleteFaxes(selectedFaxesIDs).then(() => {
          deleteFaxesFromSelected(selectedFaxesIDs);
          resetFaxes();
        }).catch((error) => {
          if (error) {
            enqueueSnackbar('Not deleted', { variant: 'error' });
          }
        });
      }
    });
  };

  const toggleFaxSelected = (fax) => {
    if (selectedFaxesIDs.indexOf(fax.id) === -1) {
      addFaxesToSelected([ fax ]);
    } else {
      deleteFaxesFromSelected([ fax.id ]);
    }
  };

  const allFaxesIsSelected = () => {
    return selectedFaxesIDs
      .filter((id) => faxes.find((fax) => fax.id === id))
      .length === faxes.length;
  };

  const toggleAllFaxesSelected = () => {
    const faxesIDs = faxes.map(({ id }) => id);

    if (allFaxesIsSelected()) {
      deleteFaxesFromSelected(faxesIDs);
    } else {
      addFaxesToSelected(faxes);
    }
  };

  const shareByEmail = (filesIDs) => {
    openModal(ShareByEmailModal, {
      onModalResolved: (data) => {
        faxApi.multipleShare({
          share_tag_id: data?.data?.additional_data?.tag?.id,
          media_ids: filesIDs
        }).then(() => {
          reduxDispatch(setFilesLastGlobalAction({
            type: fileTypes.CHANGE_FILES_TAGS,
            payload: {
              filesIDs,
              tags: [ data?.data?.additional_data?.tag ],
              actionType: tagsActionsMap.add
            }
          }));

          enqueueSnackbar(`File${filesIDs.length > 1 ? 's' : ''} successfully shared`, { variant: 'success' });
          resetFaxesFromSelected();
        }).catch((error) => {
          if (error) {
            enqueueSnackbar(`File${filesIDs.length > 1 ? 's' : ''} not shared`, { variant: 'success' });
          }
        });
      }
    });
  };

  const updateStatus = ({ id, status }) => {
    faxApi.updateFaxStatus({ id, status }).then(({ data }) => {
      dispatch({ type: types.UPDATE_STATUS, payload: { id, status, fax: data || {} } });
      enqueueSnackbar('Status successfully updated', { variant: 'success' });
      fetchFaxes({ page: 1 });
    });
  };

  const deleteFax = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        faxApi.deleteFax(id).then(() => {
          dispatch({ type: types.DELETE_FAXES, payload: id });

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Fax is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFaxes({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetFaxes = (newFilter) => {
    dispatch({ type: types.RESET_FAXES, payload: newFilter });
    fetchFaxes({ page: 1, ...newFilter });
  };

  const providerValue = {
    isFetched,
    isFetching,
    medias,
    faxes,
    filter: {
      ...filter,
      ...pagination
    },
    selectedFaxesIDs,
    selectedFaxes,

    // functions
    resetFaxes,
    resendFax,
    fetchFaxes,
    shareByEmail,
    updateStatus,
    loadNextPage,
    applyFilter,
    deleteFax,
    deleteFaxes,
    addFaxesToSelected,
    allFaxesIsSelected,
    toggleFaxSelected,
    resetFaxesFromSelected,
    toggleAllFaxesSelected,
    deleteFaxesFromSelected
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetFaxes(newFilter);
    }
  }, [ prevFilter, filter ]);

  return (
    <FaxContext.Provider value={providerValue}>
      {children}
    </FaxContext.Provider>
  );
};
