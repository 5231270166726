import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { TablePagination } from '../../../../../components/TablePagination';
import { PageBody } from '../../../../../components/Page/PageBody';
import { Loader } from '../../../../../components/Loader';
import { ImmunizationContext } from '../ImmunizationProvider';
import { Header } from './Header';
import { Row } from './Row';

export const widthBreakpointSmall = 785;
export const widthBreakpointMedium = 990;
export const widthBreakpointLarge = 1150;
export const minRowHeight = 61;
export const columnsWidths = {
  date: 150,
  name: 200,
  note: 200,
  approved_by: 220,
  office: 220,
  actions: 50
};

export const List = () => {
  const { isFetched, meta, immunizations, resetImmunizationReports } = useContext(ImmunizationContext);
  const ref = useRef();
  const { width } = useResizeObserver({ ref });

  useEffect(() => {
    resetImmunizationReports();
  }, []);

  return (
    <Box ref={ref} height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => (!immunizations.length ? (
          <Box p={3}>
            <Typography align="center">No immunizations found</Typography>
          </Box>
        ) : (
          <>
            <Header width={width} />

            <PageBody disablePaddings>
              <MuiList disablePadding>
                {immunizations.map((immunization) => (
                  <Row key={immunization.id} width={width} immunization={immunization} />
                ))}
              </MuiList>
            </PageBody>

            <TablePagination pagination={meta} onChange={resetImmunizationReports} />
          </>
        ))}
      />
    </Box>
  );
};
