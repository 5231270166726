import Linkify from 'react-linkify';
import { Link as MuiLink } from '@material-ui/core';

const componentDecorator = (props) => (decoratedHref, decoratedText, key) => {
  return (
    <MuiLink
      href={decoratedHref}
      target="_blank"
      underline="always"
      key={key}

      {...props}
    >
      {decoratedText}
    </MuiLink>
  );
};

export const LinkableText = ({ children, ...props }) => {
  return (
    <Linkify componentDecorator={componentDecorator(props)}>
      {children}
    </Linkify>
  );
};
