import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Box } from '@material-ui/core';
import { UsersSelect } from '../../users';
import { ChatUsersList } from './ChatUsersList';

export const Form = ({ thread }) => {
  const { values, setFieldValue } = useFormikContext();
  const currentUser = useSelector(({ profile }) => profile.user);
  const isAuthUserOwner = currentUser?.id === thread?.user?.id;

  const handleChatMemberAdd = (user) => {
    setFieldValue('users', values?.users?.concat(user));
  };

  const handleChatMemberDelete = (user) => {
    setFieldValue('users', values?.users?.filter(({ id }) => user?.id !== id));
  };

  return (
    <>
      {isAuthUserOwner &&
        <Box mb={2}>
          <UsersSelect
            withoutFormik
            label="Invite people"
            value={null}
            hiddenOptions={values?.users}
            onChange={handleChatMemberAdd}
          />
        </Box>
      }

      {!!values?.users?.length &&
        <ChatUsersList
          isAuthUserOwner={isAuthUserOwner}
          currentThread={thread}
          users={values?.users}
          onUserDelete={handleChatMemberDelete}
        />
      }
    </>
  );
};
