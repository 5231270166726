import { useContext } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { CardHeader, CardItem } from '../../../../../../../components/Cards';
import { OfficeLink } from '../../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { TemplatesContext } from '../../TemplatesProvider';
import { TemplatesMenu } from '../TemplatesMenu';

export const Card = ({ item: template = {} }) => {
  const { selectedIDs, toggleItemSelection } = useContext(TemplatesContext);
  const isSelected = selectedIDs.indexOf(template.id) !== -1;
  const description = !!template?.visit_reason?.description
    ? ' - ' + template?.visit_reason?.description
    : '';

  const handleItemSelection = () => {
    toggleItemSelection(template.id);
  };

  return (
    <CardItem selected={isSelected} py={1} px={2}>
      <CardHeader
        isSelected={isSelected}
        onItemSelect={stopPropagation(handleItemSelection)}
        menuComponent={TemplatesMenu}
        menuComponentProps={{ template }}
      >
        <Typography gutterBottom variant="h4">{template.name}</Typography>
      </CardHeader>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>Report Type:</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {template.report_type}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography>Age Group:</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography noWrap variant="h5">
            {template.age ? template.age + 'yrs' : '-'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>Visit Reason:</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography noWrap variant="h5">
            {template?.visit_reason
              ? `${template?.visit_reason?.code}${description}`
              : '-'
            }
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>Provider / Office:</Typography>
        </Grid>

        <Grid item xs={12}>
          <UserLink
            size="md"
            variant="h5"
            color="textPrimary"
            user={template.provider}
          >
            <Box display="flex" alignItems="center" color="info.main">
              <LocationOnIcon color="inherit" />

              <OfficeLink
                variant="caption"
                color="inherit"
                office={template.office}
              />
            </Box>
          </UserLink>
        </Grid>
      </Grid>
    </CardItem>
  );
};
