import moment from 'moment';
import { Yup } from '../../../utils/validation';

export const validationSchema = Yup.object().shape({
  user_id: Yup.mixed().nullable().required(),
  started_at: Yup.lazy(() => (
    Yup.date().format('X').nullable().required().when('finished_at', (finishedAt, schema) => {
      return moment(finishedAt).isValid()
        ? schema.max(moment(finishedAt).subtract(1, 'second'), 'Start date must be earlier than end')
        : schema;
    })
  )),
  finished_at: Yup.lazy(() => (
    Yup.date().format('X').nullable().max(moment(), 'End date must be earlier than now')
  )),
  lunch: Yup.bool().nullable(),
  lunch_started_at: Yup.lazy(() => (
    Yup.date().format('X').nullable().when(
      [ 'lunch', 'started_at', 'lunch_end_at' ],
      (lunch, startedAt, lunchFinishedAt, schema) => {
        const isAllDepsExist = lunch && startedAt && lunchFinishedAt;

        return isAllDepsExist && moment(startedAt).isValid() && moment(lunchFinishedAt).isValid()
          ? schema
            .min(moment(startedAt).add(1, 'second'), 'Lunch date must be late than work start')
            .max(moment(lunchFinishedAt).subtract(1, 'second'), 'Start date must be earlier than end')
            .required()
          : schema;
      }
    )
  )),
  lunch_end_at: Yup.lazy(() => (
    Yup.date().format('X').nullable().when([ 'lunch', 'finished_at' ], (lunch, finishedAt, schema) => {
      return lunch && moment(finishedAt).isValid()
        ? schema
          .max(
            moment(finishedAt).subtract(1, 'second'),
            'Lunch must be earlier than work end'
          )
          .required()
        : schema;
    })
  ))
});
