export const initialState = {
  filter: {
    page: 1,
    per_page: 15
  },
  pagination: {
    total: 0
  },
  isRulesFetching: false,
  isRulesFetched: false,
  rules: []
};
