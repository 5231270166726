import moment from 'moment';
import { Yup } from '../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  user_id: Yup.string().nullable().required(),
  category_id: Yup.string().nullable().required(),
  notes: Yup.string().trim().nullable().required().max(1000),
  budget: Yup.number().nullable().required(),
  amount: Yup.number().nullable().required(),
  date: Yup.date().format('X').nullable().max(moment().add(1, 'day'), 'Date must be earlier than today')
});
