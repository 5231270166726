import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { flow } from 'lodash';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as discoveriesApi from '../../../../../../../../../api/cases/discovery';
import { ConfirmationModal } from '../../../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import { CaseDiscoveriesContext } from '../../CaseDiscoveriesProvider';
import { EditDiscoveryModal } from '../../EditDiscoveryModal';

export const Menu = ({ discovery, onClose = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const { caseItem, onCaseItemUpdate,updateDiscovery, deleteDiscovery } = useContext(CaseDiscoveriesContext);

  const handleUpdate = () => {
    openModal(EditDiscoveryModal, {
      payload: {
        caseItem,
        discovery,
        onCaseItemUpdate
      },
      onModalResolved: (data) => {
        updateDiscovery(data);
        onClose();
      }
    });
  };

  const handleDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        discoveriesApi.deleteCaseDiscovery(caseItem.id, discovery.id).then(() => {
          deleteDiscovery(discovery);
          enqueueSnackbar('Case discovery successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Case discovery not deleted', { variant: 'error' });
        });
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={handleUpdate}>
        <ListItemIcon>
          <EditIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={flow(handleDelete, onClose)}>
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
