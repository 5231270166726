import { useContext, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../../components/TablePagination';
import { Loader } from '../../../../../../components/Loader';
import { Cards } from '../../../../../../components/Cards';
import { ReportsContext } from '../../../../../../components/medical/reports';
import { Card } from './Card';

export const Grid = () => {
  const { isFetched, meta, reports, resetReports } = useContext(ReportsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetReports();
  }, []);

  return (
    <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
        <Loader p={3} loading={!isFetched} render={
          () => (!reports.length ? (
            <Box p={3}>
              <Typography align="center">No reports found</Typography>
            </Box>
          ) : (
            <>
              <Cards size={300} list={reports} component={Card} />
              <TablePagination pagination={meta} onChange={resetReports} />
            </>
          ))}
        />
      </CustomScrollbars>
    </Box>
  );
};
