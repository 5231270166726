export const styles = ({ spacing, palette, typography: { pxToRem, fontWeightMedium }, breakpoints }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(2),
    borderTop: `1px dashed ${palette.divider}`,

    [breakpoints.down('md')]: {
      flexDirection: 'column',
      alignContent: 'center'
    }
  },

  group: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '33.3333%',
    padding: spacing(0, 1, 1),
    textAlign: 'center',

    [breakpoints.down('md')]: {
      flexBasis: '100%',
      flexDirection: 'row',
      padding: 0,
      margin: spacing(0.5, 0)
    }
  },

  group__header: {
    marginBottom: spacing(2),
    textTransform: 'uppercase',
    fontSize: pxToRem(12),
    fontWeight: fontWeightMedium,

    [breakpoints.down('md')]: {
      margin: 0,
      display: 'flex',
      flexBasis: '40%',
      flexGrow: 1,
      flexDirection: 'row',
      alignItems: 'center'
    }
  },

  group__content: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: pxToRem(18),
    fontWeight: fontWeightMedium,

    [breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'start'
    }
  }
});
