import moment from 'moment';
import { Yup } from '../../../../../../../utils/validation';
import { socialHistoryTypes } from '../../FormPreviewModal/PastMedicalHistory/socialHistoryTypes';

export const validationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    medical_history: Yup.object().shape({
      is_problems: Yup.boolean().nullable(),
      medical_problems: Yup.string().nullable().when('is_problems', {
        is: (value) => !value,
        then: Yup.string().nullable().required()
      }),
      current_medications: Yup.array().of(Yup.string().nullable()),
      allergies_or_reactions: Yup.array().of(Yup.object()),
      blood_thinning_medications: Yup.object().shape({
        aspirin: Yup.boolean().nullable(),
        coumadin: Yup.boolean().nullable(),
        plavix: Yup.boolean().nullable(),
        heparin: Yup.boolean().nullable(),
        pletal: Yup.boolean().nullable(),
        lovenox: Yup.boolean().nullable(),
        ticlid: Yup.boolean().nullable()
      }),
      surgeries_dates: Yup.array().of(Yup.object().shape({
        description: Yup.string().nullable().when([ 'date_from', 'date_to' ], {
          is: (date_from, date_to) => !!date_from || !!date_to,
          then: Yup.string().nullable().required(),
          otherwise: Yup.string().nullable()
        }),
        date_from: Yup.date().format('YYYY-MM-DD').nullable()
          .max(moment().add(1, 'minute'), 'Date must be earlier than today'),
        date_to: Yup.date().format('YYYY-MM-DD').nullable()
          .max(moment().add(1, 'minute'), 'Date must be earlier than today')
          .test(
            'minDate',
            'Should not be less than date from',
            (value) => !value || moment(value).isSameOrBefore(moment())
          )
          .when('date_from', (dateFrom, schema) => {
            const dateMomentDate = moment(dateFrom);

            return dateMomentDate.isValid()
              ? schema.min(dateMomentDate.add(1, 'minute'), 'Must be after the date from date')
              : schema;
          })
      })),
      family_medical_history: Yup.string().nullable(),
      marital_status: Yup.string().nullable(),
      occupation: Yup.string().nullable().required(),
      occupation_value: Yup.string().nullable().when('occupation', {
        is: (value) => value === socialHistoryTypes.yes,
        then: Yup.string().nullable().required()
      }),
      you_use_tobacco: Yup.string().nullable().required(),
      you_use_tobacco_value: Yup.string().nullable().when('you_use_tobacco', {
        is: (value) => value === socialHistoryTypes.yes,
        then: Yup.string().nullable().required()
      }),
      you_use_alcohol: Yup.string().nullable().required(),
      you_use_alcohol_value: Yup.string().nullable().when('you_use_alcohol', {
        is: (value) => value === socialHistoryTypes.yes,
        then: Yup.string().nullable().required()
      }),
      used_recreational_drugs: Yup.string().nullable().required(),
      used_recreational_drugs_value: Yup.string().nullable().when('used_recreational_drugs', {
        is: (value) => value === socialHistoryTypes.yes,
        then: Yup.string().nullable().required()
      })
    }).notRequired()
  })
});
