import { Bool, ChecklistBool, CurrencyFormat, Date, IncomeTime, TaskField, Text, UserActivityLink } from '../fields';

export const fieldsMap = {
  is_manual: (before, after) => <Bool before={before} after={after} fieldLabel="Manual" />,
  is_checked: (before, after) => <ChecklistBool before={before} after={after} fieldLabel="Checkbox Item" />,
  is_approved_paid: (before, after) => <ChecklistBool before={before} after={after} fieldLabel="Is Approved Paid" />,
  time_trackingable_id: (before, after) => <Text before={before} after={after} fieldLabel="Time Trackingable ID" />,
  time_trackingable_type: (before, after) => <Text before={before} after={after} fieldLabel="Time Trackingable Type" />,
  description: (before, after) => <Text before={before} after={after} fieldLabel="Description" />,
  income: (before, after) => <Text before={before?.id} after={after?.id} fieldLabel="Income" />,
  approvedPaidBy: (before, after) => <Text before={before} after={after} fieldLabel="Approved Paid By" />,
  category_id: (before, after) => <Text before={before} after={after} fieldLabel="Category Id" />,
  approved_paid_at: (before, after) => <Date before={before} after={after} fieldLabel="Approved Paid At" />,
  finished_at: (before, after) => <Date before={before} after={after} fieldLabel="Finished At" />,
  started_at: (before, after) => <Date before={before} after={after} fieldLabel="Started At" />,
  updated_at: (before, after) => <Date before={before} after={after} fieldLabel="Date of updating" />,
  created_at: (before, after) => <Date before={before} after={after} fieldLabel="Date of creation" />,
  deleted_at: (before, after) => <Date before={before} after={after} fieldLabel="Date of deleting" />,
  parent: (before, after) => <TaskField before={before} after={after} fieldLabel="Parent Task" />,
  time: (before, after) => <IncomeTime before={before} after={after} fieldLabel="Time" />,
  billing_time: (before, after) => <IncomeTime before={before} after={after} fieldLabel="Billing Time" />,
  billing_rate: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Billing Rate" />,
  current_rate: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Current Rate" />,
  user: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="User" />
};
