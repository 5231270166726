import { Box, Grid, Typography } from '@material-ui/core';
import { AddressLink } from '../../../../components/AddressLink';
import { ReadonlyCheckbox } from '../../../../components/ReadonlyCheckbox';

export const VenueContent = ({ venue }) => {
  return (
    <Box pt={4}>
      <Box color="info.main">
        <Typography variant="button" component="h3" color="inherit">
          Venue
        </Typography>
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Address
          </Typography>
        </Grid>

        <Grid item xs={7}>
          {venue.map((item, i) => (
            <Box key={i} mb={1.5}>
              {+item.is_main > 0 ?
                <Box ml={-3}>
                  <ReadonlyCheckbox
                    checked
                    fullWidth
                    noWrap={false}
                    variant="h5"
                    position="flex-start"
                    component="div"
                    title={
                      <AddressLink variant="h5" address={item.full_address}>
                        {`${i + 1}. ${item.full_address}`}
                      </AddressLink>
                    }
                  />
                </Box>
                :
                <AddressLink variant="h5" address={item.full_address}>
                  {`${i + 1}. ${item.full_address}`}
                </AddressLink>
              }
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
