import { useContext, useEffect, useRef } from 'react';
import { Box, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import * as accountsApi from '../../../../../../api/login-queues/accounts';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { useResizeObserver } from '../../../../../../helpers/hooks';
import { hasRole } from '../../../../../../utils/hasRole';
import ChatSvg from '../../../../../../components/icons/chat-squared.svg';
import { ChatModal } from '../../../../../chat/ChatWidget/Chat/ChatModal';
import { ConfirmationModal } from '../../../../../ConfirmationModal';
import { ContentCard } from '../../../../../ContentCard';
import { Fab } from '../../../../../Fab';
import { IconButton } from '../../../../../IconButton';
import { InfiniteListLoader } from '../../../../../InfiniteLoader';
import { Loader } from '../../../../../Loader';
import { useModal } from '../../../../../ModalsProvider';
import { Sticky } from '../../../../../Sticky';
import { LoginSessionsContext } from '../../../../sessions';
import { AddSessionModal } from '../../../../sessions/AddSessionModal';
import { EditAccountModal } from '../../../EditAccountModal';
import { LoginAccountsContext } from '../../../LoginAccountsProvider';
import { styles } from '../styles';
import { TableHeader } from './TableHeader';
import { SessionRow } from './SessionRow';
import { SessionCard } from './SessionCard';

const useStyles = makeStyles(styles);

const listViewWidth = 720;

export const Account = ({ account }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const {
    service,
    deleteLoginAccount,
    updateLoginAccount
  } = useContext(LoginAccountsContext);
  const {
    isFetched,
    isFetching,
    meta,
    loginSessions,
    resetLoginSessions,
    loadNextPage
  } = useContext(LoginSessionsContext);
  const scrollElementRef = useRef();
  const { width } = useResizeObserver({ ref: scrollElementRef });

  const addSession = () => {
    openModal(AddSessionModal, {
      payload: {
        account,
        service
      }
    });
  };

  const openChatModal = () => {
    openModal(ChatModal, {
      payload: {
        threadID: account?.thread?.id
      }
    });
  };

  const editAccount = () => {
    openModal(EditAccountModal, {
      payload: {
        accountId: account.id
      },
      onModalResolved: (account) => {
        updateLoginAccount(account);
      }
    });
  };

  const handleAccountDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        accountsApi.deleteAccount(account.id).then(() => {
          deleteLoginAccount(account);
        });
      }
    });
  };

  useEffect(() => {
    resetLoginSessions({ login_queue_account_id: account.id });
  }, []);

  return (
    <ContentCard
      fullHeight
      disableContentPaddings
      ref={scrollElementRef}
      title={account.name}
      className={classes.column}
      leftActions={[
        <Fab color="primary" onClick={addSession}>
          <AddIcon />
        </Fab>
      ]}
      rightActions={[
        <IconButton color="inherit" onClick={openChatModal}>
          <SvgIcon>
            <ChatSvg />
          </SvgIcon>
        </IconButton>,

        hasRole(rolesMap.admin, rolesMap.supervisor) && (
          <>
            <IconButton color="inherit" onClick={editAccount}>
              <EditIcon />
            </IconButton>

            <IconButton color="inherit" onClick={handleAccountDelete}>
              <DeleteIcon />
            </IconButton>
          </>
        )
      ]}
    >
      <Loader p={2} loading={isFetching}>
        {width > listViewWidth &&
          <Sticky containerElement={scrollElementRef.current}>
            <TableHeader />
          </Sticky>
        }

        {meta.total ? (
          <InfiniteListLoader
            items={loginSessions}
            isFetching={isFetching}
            isFetched={isFetched}
            pagination={meta}
            RowComponent={width > listViewWidth ? SessionRow : SessionCard}
            scrollElementRef={scrollElementRef}
            onNeedNextPage={loadNextPage}
            getItemKey={(item) => item?.id}
          />
        ) : (
          <Box p={2}>
            <Typography align="center">No sessions found</Typography>
          </Box>
        )}
      </Loader>
    </ContentCard>
  );
};
