import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import T from 'prop-types';
import cn from 'classnames';
import { makeStyles, Typography, Link, Box } from '@material-ui/core';
import { preventDefault, stopPropagation } from '../../helpers/dom';
import { getUserFullName } from '../../helpers/users';
import { useModal } from '../ModalsProvider';
import { Tooltip } from '../Tooltip';
import { UserAvatar, UserPreviewModal } from '../users';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  children: T.node,
  user: T.object,
  path: T.string,
  noWrap: T.bool
};

export const UserLink = ({
  size = 'md',
  disablePreview = false,
  disableName = false,
  disableLink = false,
  noAvatar = false,
  noWrap,
  user = null,
  caseUser = null,
  path,
  children,
  childrenProps,
  parentProps = {},
  className,
  tooltipTitle,

  ...props
}) => {
  const classes = useStyles({ noAvatar });
  const { openModal } = useModal();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const Wrapper = noWrap ? Fragment : Box;
  const wrapperProps =  noWrap ? {} : {
    display: 'flex',
    alignItems: !children ? 'center' : 'flex-start',

    ...parentProps
  };

  const openUserPreview = () => {
    if (!disablePreview && user?.id) {
      openModal(UserPreviewModal, {
        payload: { id: user?.id, caseUser }
      });
    }
  };

  const userLinkProps = !path ? {
    component: 'div',
    className: classes.linkWrapper,
    onClick: isAuthenticated ? stopPropagation(preventDefault(openUserPreview)) : undefined,

    ...props
  } : {
    variant: 'h5',
    target: '_blank',
    href: path,
    component: Link,
    onClick: stopPropagation(),

    ...props
  };

  const UserLinkWrapper = disableLink ? Typography : Link;

  return !user ? (
    <Typography color="textSecondary">No info.</Typography>
  ) : (
    <Wrapper {...wrapperProps}>
      {!noAvatar &&
        <UserLinkWrapper underline={disableLink ? null : 'none'} {...userLinkProps}>
          <Tooltip isExistTooltip={disableName} title={tooltipTitle || getUserFullName(user)}>
            <UserAvatar size={size} user={user} />
          </Tooltip>
        </UserLinkWrapper>
      }

      {!disableName && (
        <Typography noWrap className={cn(classes.content, className)} component="div">
          <Tooltip
            isExistTooltip={!disableLink}
            title={tooltipTitle || getUserFullName(user)}
          >
            <UserLinkWrapper noWrap {...userLinkProps}>
              {getUserFullName(user)}
            </UserLinkWrapper>
          </Tooltip>

          {children &&
            <Box overflow="hidden" textOverflow="ellipsis" {...childrenProps}>
              {children}
            </Box>
          }
        </Typography>
      )}
    </Wrapper>
  );
};

UserLink.propTypes = propTypes;
