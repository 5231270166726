import { makeStyles } from '@material-ui/core';
import { Route } from '../../../components/router/Route';
import { Trash } from '../FilesPage/Trash';
import { VideoContextProvider } from './VideosContext';
import { MainWindow } from './MainWindow/MainWindow';
import { LeftSidebar } from './LeftSidebar';
import { VideosItem } from './VideosItem';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const VideosPage = () => {
  const classes = useStyles();

  return (
    <VideoContextProvider>
      <div className={classes.root}>
        <div className={classes.sidebar}>
          <LeftSidebar />
        </div>

        <div className={classes.main}>
          <Route exact path="/videos" component={MainWindow} />
          <Route exact path="/videos/:id" component={VideosItem} />
          <Route path="/videos/recycle-bin" component={Trash} />
        </div>
      </div>
    </VideoContextProvider>
  );
};
