import { Box, Grid, Typography } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { socialHistoryTypes } from '../PastMedicalHistory/socialHistoryTypes';

export const MainContent = ({ form }) => {
  return (
    <Box display="flex" flexDirection="column" overflow="hidden">
      <Grid
        container
        spacing={3}
        component={Box}
        alignItems="center"
        justify="flex-start"
        py={2}
        m="0 !important"
      >
        {form?.where_is_your_primary_pain_location  && (
          <>
            <Grid item>
              <Typography variant="h5">
                Where is your primary pain location?
              </Typography>
            </Grid>

            <Grid item lg={6} xs={11}>
              <Typography color="textSecondary">
                {form?.where_is_your_primary_pain_location}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Typography variant="h5">
            Check <u>two words</u> that best describe your pain
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" justifyContent="flex-start" px={2}>
            {!!form?.circle_two_words_that_best_describe_your_pain?.aching && (
              <Box component="span" mr={2}>
                <ReadonlyCheckbox checked title="Aching" />
              </Box>
            )}

            {!!form?.circle_two_words_that_best_describe_your_pain?.sharp && (
              <Box component="span" mr={2}>
                <ReadonlyCheckbox checked title="Sharp" />
              </Box>
            )}

            {!!form?.circle_two_words_that_best_describe_your_pain?.dull && (
              <Box component="span" mr={2}>
                <ReadonlyCheckbox checked title="Dull" />
              </Box>
            )}

            {!!form?.circle_two_words_that_best_describe_your_pain?.nagging && (
              <Box component="span" mr={2}>
                <ReadonlyCheckbox checked title="Nagging" />
              </Box>
            )}

            {!!form?.circle_two_words_that_best_describe_your_pain?.shooting && (
              <Box component="span" mr={2}>
                <ReadonlyCheckbox checked title="Shooting" />
              </Box>
            )}

            {!!form?.circle_two_words_that_best_describe_your_pain?.burning && (
              <Box component="span" mr={2}>
                <ReadonlyCheckbox checked title="Burning" />
              </Box>
            )}

            {!!form?.circle_two_words_that_best_describe_your_pain?.stabbing && (
              <Box component="span" mr={2}>
                <ReadonlyCheckbox checked title="Stabbing" />
              </Box>
            )}
          </Box>
        </Grid>

        {!!form?.what_makes_the_pain_worse && (
          <>
            <Grid item>
              <Typography variant="h5">
                What makes the pain <u>worse</u>?
              </Typography>
            </Grid>

            <Grid item lg={9} xs={11}>
              <Typography color="textSecondary">
                {form?.what_makes_the_pain_worse}
              </Typography>
            </Grid>
          </>
        )}

        {!!form?.how_has_your_sleep_been && (
          <>
            <Grid item lg={4} md={6} xs={10}>
              <Typography variant="h5">
                How has your sleep been?
              </Typography>
            </Grid>

            <Grid item lg={3} md={6} xs={12}>
              <ReadonlyCheckbox
                checked
                title={(form?.how_has_your_sleep_been === 'disturbed' ? 'Disturbed' : 'Not Disturbed')}
              />
            </Grid>
          </>
        )}

        {!!form?.how_has_your_sleep_been && (
          <>
            <Grid item lg={3} xs={6}>
              <Box pl={2}>
                <Typography variant="h5">
                  Awaken with pain?
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={2} xs={6}>
              <ReadonlyCheckbox
                checked
                title={
                  form?.how_has_your_sleep_been === socialHistoryTypes.yes ? 'Yes' : 'No'
                }
              />
            </Grid>
          </>
        )}

        {!!form?.what_makes_your_pain_better && (
          <>
            <Grid item>
              <Typography variant="h5">
                What makes your pain better?
              </Typography>
            </Grid>

            <Grid item sm={8} xs={11}>
              <Box pl={2}>
                <Typography color="textSecondary">
                  {form?.what_makes_your_pain_better}
                </Typography>
              </Box>
            </Grid>
          </>
        )}

        {!!form?.do_you_have_any_new_areas_of_pain && (
          <>
            <Grid item md={3} xs={6}>
              <Typography variant="h5">
                Do you have any <u>new</u> areas of pain?
              </Typography>
            </Grid>

            <Grid item md={3} xs={6}>
              <ReadonlyCheckbox
                checked
                title={
                  form?.do_you_have_any_new_areas_of_pain === socialHistoryTypes.yes ? 'Yes' : 'No'
                }
              />
            </Grid>
          </>
        )}

        {!!form?.where && (
          <>
            <Grid item>
              <Box pl={2}>
                <Typography variant="h5">
                  Where?
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={3} xs={11}>
              <Box pl={2}>
                <Typography color="textSecondary">
                  {form?.where}
                </Typography>
              </Box>
            </Grid>
          </>
        )}

        {!!form?.how_long_have_you_had_the_new_pain && (
          <>
            <Grid item>
              <Typography variant="h5">
                How long have you had the new pain?
              </Typography>
            </Grid>

            <Grid item md={3} xs={12}>
              <Box pl={2}>
                <Typography color="textSecondary">
                  {form?.how_long_have_you_had_the_new_pain}
                </Typography>
              </Box>
            </Grid>
          </>
        )}

        <Grid item md={2} xs={6}>
          <Typography variant="h5">
            Constipation?
          </Typography>
        </Grid>

        <Grid item md={4} xs={6}>
          <Box display="flex" alignItems="center">
            {form?.constipation === 'Mild' && (
              <Box component="span" mr={2}>
                <ReadonlyCheckbox checked title="Mild" />
              </Box>
            )}

            {form?.constipation === 'Moderate' && (
              <Box component="span" mr={2}>
                <ReadonlyCheckbox checked title="Moderate" />
              </Box>
            )}

            {form?.constipation === 'Severe' && (
              <Box component="span" mr={2}>
                <ReadonlyCheckbox checked title="Severe" />
              </Box>
            )}
          </Box>
        </Grid>


        {!!form?.use_of_laxative && (
          <>
            <Grid item md={3} xs={6}>
              <Typography variant="h5">
                Use of laxative?
              </Typography>
            </Grid>

            <Grid md={3} xs={6}>
              <ReadonlyCheckbox
                checked
                title={
                  form?.use_of_laxative === socialHistoryTypes.yes ? 'Yes' : 'No'
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
