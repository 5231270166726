export const orderByMap = {
  created_at: 'created_at',
  user_id: 'user_id',
  updated_at: 'updated_at'
};

export const orderByLabelsMap = {
  [orderByMap.created_at]: 'Created At',
  [orderByMap.user_id]: 'Users'
};

export const orderByOptions = [
  { label: orderByLabelsMap.created_at, value: orderByMap.created_at },
  { label: orderByLabelsMap.user_id, value: orderByMap.user_id }
];
