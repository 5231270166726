import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import * as companyApi from '../../../../../api/companies';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
// TODO: for future settings
// import { Activity } from '../../Activity';
import { Cases } from '../../Cases';
import { Tasks } from '../../Tasks';
import { MainInfo } from './MainInfo';

export const CompanyItem = () => {
  const scrollElementRef = useRef();
  const params = useParams();
  const history = useHistory();
  const [ company, setCompany ] = useState(null);

  useEffect(() => {
    companyApi.fetchCompany(params.id)
      .then(setCompany)
      .catch(() => history.push('/contacts/companies'));
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader loading={!company} p={3} render={
        () => (
          <Grid
            container
            spacing={2}
            component={Box}
            pl={1}
            py={1}
            m="0 !important"
            width="100% !important"
          >
            <Grid item xs={12}>
              <MainInfo company={company} setCompany={setCompany} />
            </Grid>

            <Grid item xs={12}>
              <Cases filter={{ company_id: company.id }} />
            </Grid>

            {/*TODO: for future settings*/}
            {/*<Grid item lg={width > widthBreakpointLarge ? 4 : 12} xs={12}>*/}
            {/*  <Activity />*/}
            {/*</Grid>*/}

            <Grid item xs={12}>
              <Tasks filter={{ company_id: company.id }} />
            </Grid>
          </Grid>
        )}
      />
    </CustomScrollbars>
  );
};
