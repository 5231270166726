import { makeStyles, Typography } from '@material-ui/core';

const styles = () => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    font: 'inherit',
    lineHeight: 1.5
  }
});

const useStyles = makeStyles(styles);

export const Placeholder = ({
  selectProps: { label, error },
  innerProps,
  children,
  isFocused
}) => {
  const classes = useStyles();
  const placeholderIsAccepted = !!(isFocused || !label);

  return (
    <Typography
      noWrap
      color={error ? 'error' : 'textSecondary'}
      className={classes.root}
      {...innerProps}
    >
      {placeholderIsAccepted && children}
    </Typography>
  );
};
