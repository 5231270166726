import { useContext, useState } from 'react';
import moment from 'moment';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as featureFlagsApi from '../../../../../../api/feature-flags';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { FeatureFlagsContext } from '../../FeatureFlagsProvider';
import { columnsWidths } from '../List';
import { UpdateFeatureFlagModal } from './UpdateFeatureFlagModal';

export const Row = ({ isLoaded, item: featureFlag }) => {
  const { openModal } = useModal();
  const { updateFeatureFlag } = useContext(FeatureFlagsContext);
  const [ isBusy, setIsBusy ] = useState(false);

  const openEditModal = () => {
    openModal(UpdateFeatureFlagModal, {
      payload: {
        featureFlagId: featureFlag.id
      },

      onModalResolved: (featureFlag) => {
        updateFeatureFlag(featureFlag);
      }
    });
  };

  const toggleActive = () => {
    const data = { ...featureFlag, is_active: !featureFlag.is_active };

    setIsBusy(true);

    featureFlagsApi.updateFeatureFlag(data).then((featureFlag) => {
      updateFeatureFlag(featureFlag);
    }).finally(() => {
      setIsBusy(false);
    });
  };

  return (
    <Loader
      fullWidth
      p={isBusy ? 0 : 2}
      surface={isBusy}
      loading={!isLoaded || isBusy}
      render={() => (
        <ListRow disabled={!featureFlag.is_active}>
          <ListRowCell grow flexBasis={columnsWidths.name}>
            {featureFlag?.type}
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.date}>
            {moment(featureFlag?.created_at).format('L LT')}
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.date}>
            {moment(featureFlag?.activated_at).format('L LT')}
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.users}>
            No info
          </ListRowCell>

          <ListRowCell noDisable justifyContent="flex-end" flexBasis={columnsWidths.actions}>
            <ListRowCellActions>
              <IconButton
                title={featureFlag.is_active ? 'Deactivate' : 'Activate'}
                color={featureFlag.is_active ? 'warning' : 'success'}
                onClick={toggleActive}
              >
                {featureFlag.is_active ? <LockIcon /> : <LockOpenIcon />}
              </IconButton>

              <IconButton color="info" onClick={openEditModal}>
                <EditIcon />
              </IconButton>
            </ListRowCellActions>
          </ListRowCell>
        </ListRow>
      )}
    />
  );
};
