import moment from 'moment';
import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  appointment_book_name: Yup.string().nullable().max(255).required(),
  provider_id: Yup.mixed().required(),
  default_appointment_type_id: Yup.mixed(),
  default_arrival_status_id: Yup.mixed(),
  office_id: Yup.mixed().nullable().required(),
  house_call: Yup.boolean(),
  eligibility_auto_check: Yup.boolean(),
  referring_physician_required: Yup.boolean(),
  ssn_required: Yup.boolean(),
  address_required: Yup.boolean(),
  is_active: Yup.boolean(),
  is_private: Yup.boolean(),
  assign_users: Yup.array().of(Yup.number().required()),
  appointment_types: Yup.array().of(Yup.object().shape({
    appointment_type_id: Yup.number().required(),
    is_current_active: Yup.boolean().nullable()
  })),
  work_schedules: Yup.array().of(Yup.object().shape({
    day: Yup.number().when('date', {
      is: (value) => !value,
      then: Yup.number().nullable().required(),
      otherwise: Yup.number().nullable().notRequired()
    }),
    start: Yup.string().nullable().when('day_off', {
      is: (value) => !value,
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable().notRequired()
    }),
    finish: Yup.string().nullable().when('day_off', {
      is: (value) => !value,
      then: Yup.string().nullable().required().test({
        test: function (value) {
          return 0 < moment(value, 'HH:mm').diff(moment(this.parent.start, 'HH:mm'));
        },
        message: 'Must be greater than start time'
      }),
      otherwise: Yup.string().nullable().notRequired()
    }),
    date: Yup.mixed().nullable(),
    day_off: Yup.boolean().nullable(),
    breaks: Yup.array().of(Yup.object().shape({
      from: Yup.string().nullable().when('to', (value, schema) => {
        return value ? schema.required() : schema;
      }),
      to: Yup.string().nullable().when('from', (value, schema) => {
        return value ? schema.required() : schema;
      })
    }, [ 'from', 'to' ])).nullable()
  })).nullable()
});
