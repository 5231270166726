import { Date, Text, Bool, Company, CurrencyFormat } from '../../fields';

export const plaintiffFieldsMap = {
  arb: (before, after) => <Date before={before} after={after} fieldLabel="Arb." />,
  auto_body_shop_id: (before, after) => <Company before={before} after={after} fieldLabel="Auto Body Shop" />,
  car_seats: (before, after) => <Text before={before} after={after} fieldLabel="Car Seats" />,
  car_seat_reimb: (before, after) => <Text before={before} after={after} fieldLabel="Car Seat Reimb." />,
  deductible_paid: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Deductible Paid" />,
  deductible_reimbursed: (before, after) => (
    <Bool before={before} after={after} fieldLabel="Deductible Reimbursed" />
  ),
  final_pd_amount: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="Final PD Amount" />
  ),
  pd_appraised_amount: (before, after) => (
    <CurrencyFormat before={before} after={after} fieldLabel="PD Appraised Amt." />
  ),
  pd_payer: (before, after) => <Text before={before} after={after} fieldLabel="PD Payer" />,
  rental_co_id: (before, after) => <Company before={before} after={after} fieldLabel="Rental Co" />,
  rental_paid: (before, after) => <Text before={before} after={after} fieldLabel="Rental Paid" />,
  rental_reimbursed: (before, after) => <Bool before={before} after={after} fieldLabel="Rental Reimbursed" />,
  total_loss: (before, after) => <Bool before={before} after={after} fieldLabel="Total Loss" />,
  towing_co_id: (before, after) => <Company before={before} after={after} fieldLabel="Towing Co" />,
  towing_paid: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Towing Paid" />
};
