import createSpacing from '@material-ui/core/styles/createSpacing';
import { props } from './props';
import { palette as corePalette } from './palette';
import { typography as coreTypography } from './typography';

export const core = (theme) => {
  const palette = corePalette(theme.palette);
  const typography = coreTypography({ palette });

  return {
    typography,
    palette,
    props,
    breakpoints: {
      values: {
        xs: 0,
        mobileXs: 321,
        mobileSm: 480,
        sm: 600,
        mobileLg: 768,
        tablet: 920,
        md: 960,
        tabletMd: 1100,
        tabletLg: 1200,
        lg: 1280,
        xl: 1920
      }
    },

    spacing: createSpacing(8),

    shape: {
      borderRadius: 5
    }
  };
};
