import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../ListRow';
import { columnsWidths } from './ServiceLines';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.updated_at}>
          Updated date
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.certificationRevision}>
          Certification revision
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.lastCertification}>
          Last certification
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.beginTherapy}>
          Begin therapy
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
