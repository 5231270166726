import { Box } from '@material-ui/core';
import { ContentCard } from '../../../../../../components/ContentCard';

export const ReputationInfo = () => {
  return (
    <ContentCard
      fullHeight
      title="Reputation Scale"
      component={Box}
      minHeight={630}
    >
        Reputation Scale
    </ContentCard>
  );
};
