import { createContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../../../api';
import * as accessPatientsApi from '../../../../../api/profile/access-patients';
import { usePrevious } from '../../../../../helpers/hooks';
import { initialState as initialStateConfig } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const AccessPatientsContext = createContext(null);

export const AccessPatientsContextProvider = ({ children, initialState = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialStateConfig, initialState));
  const cancelFetch = useRef(() => {});
  const {
    patientsList,
    pagination,
    isFetching,
    isFetched,
    selectedPatientsIDs,
    filter
  } = state;
  const prevFilter = usePrevious(filter);

  const fetchAccessPatients = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.PATIENTS_FETCH_REQUEST });

    accessPatientsApi.fetchAccessPatients({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.PATIENTS_FETCH_SUCCESS, payload: data });
    });
  };

  const addPatient = (patient) => {
    dispatch({ type: types.ADD_PATIENT, payload: patient });
  };

  const deletePatients = (patientsIDs) => {
    dispatch({ type: types.DELETE_PATIENT, payload: patientsIDs });
  };

  const updatePatient = (updatedPatient) => {
    dispatch({ type: types.UPDATE_PATIENT, payload: updatedPatient });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchAccessPatients({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetAccessPatients = (newFilter) => {
    dispatch({ type: types.RESET_PATIENTS, payload: newFilter });

    fetchAccessPatients({ ...newFilter, page: 1 });
  };

  const addItemsToSelected = ((itemIDs) => {
    dispatch({ type: types.ADD_PATIENTS_TO_SELECTED, payload: itemIDs });
  });

  const deleteItemsFromSelected = ((itemIDs) => {
    dispatch({ type: types.DELETE_PATIENTS_FROM_SELECTED, payload: itemIDs });
  });

  const togglePatientSelection = (fileID) => {
    if (selectedPatientsIDs.indexOf(fileID) === -1) addItemsToSelected([ fileID ]);
    else deleteItemsFromSelected([ fileID ]);
  };

  const allItemsIsSelected = () => {
    return selectedPatientsIDs.filter((id) =>
      state.patientsList.find((item) => item.id === id)).length === state.patientsList.length;
  };

  const toggleAllItemsSelection = () => {
    const itemsIDs = state.patientsList.map(({ id }) => id);

    allItemsIsSelected() ? deleteItemsFromSelected(itemsIDs) : addItemsToSelected(itemsIDs);
  };

  const providerValue = {
    patientsList,
    pagination,
    isFetching,
    isFetched,
    filter: {
      ...filter,
      ...pagination
    },
    selectedPatientsIDs,
    fetchAccessPatients,
    addPatient,
    deletePatients,
    updatePatient,
    loadNextPage,
    applyFilter,
    resetAccessPatients,
    allItemsIsSelected,
    togglePatientSelection,
    toggleAllItemsSelection
  };

  useEffect(() => {
    resetAccessPatients(filter);
  }, []);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetAccessPatients(newFilter);
    }
  }, [ filter, prevFilter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);


  return (
    <AccessPatientsContext.Provider value={providerValue}>
      {children}
    </AccessPatientsContext.Provider>
  );
};
