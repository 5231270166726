export const reminderEventTypesMap = {
  REJECT: 'REJECT',
  APPOINTMENT: 'APPOINTMENT',
  NOTICE: 'NOTICE',
  NOTIFY: 'NOTIFY',
  ARBITRATION: 'ARBITRATION',
  REQUEST: 'REQUEST',
  ARBHRG: 'ARBHRG',
  DEADLINE: 'DEADLINE',
  REMINDER: 'REMINDER',
  REQ_AMB: 'REQ_AMB',
  FOLLOW: 'FOLLOW',
  CONFIRM: 'CONFIRM',
  F_ANSWER: 'F_ANSWER',
  E_SERVE: 'E_SERVE',
  S_COMPLA: 'S_COMPLA',
  F_PROOF: 'F_PROOF',
  N_ARBIT: 'N_ARBIT',
  D_LIST: 'D_LIST',
  S_LISTS: 'S_LISTS',
  S_SUBPEN: 'S_SUBPEN',
  AWARD: 'AWARD',
  S_AWARD: 'S_AWARD',
  F_APPLIC: 'F_APPLIC',
  CORRECT: 'CORRECT',
  F_OBJECT: 'F_OBJECT',
  PETITION: 'PETITION',
  F_PETIT: 'F_PETIT',
  F_RESPON: 'F_RESPON',
  H_PETIT: 'H_PETIT',
  N_HEAR: 'N_HEAR',
  REMOTE: 'REMOTE',
  APPOINT: 'APPOINT',
  H_ARBIT: 'H_ARBIT',
  SELECT: 'SELECT',
  ORDER: 'ORDER',
  PLEAD: 'PLEAD',
  DELIVER: 'DELIVER',
  S_DEMAND: 'S_DEMAND',
  F_SETTLE: 'F_SETTLE',
  S_998: 'S_998',
  BEGDISC: 'BEGDISC',
  DISMISS: 'DISMISS',
  JUDGMENT: 'JUDGMENT',
  S_WRIT: 'S_WRIT',
  F_OPPO: 'F_OPPO',
  F_EXEMPT: 'F_EXEMPT',
  F_WRIT: 'F_WRIT',
  H_WRIT: 'H_WRIT',
  REPLY: 'REPLY',
  MEET: 'MEET',
  F_STMT: 'F_STMT',
  C_SETTLE: 'C_SETTLE',
  TRIAL: 'TRIAL',
  F_ARBSTP: 'F_ARBSTP',
  F_STIP: 'F_STIP',
  $ARBLIST: '$ARBLIST',
  D_FEES: 'D_FEES',
  DISCOVER: 'DISCOVER',
  N_CMC: 'N_CMC',
  DEFAULT: 'DEFAULT',
  F_DEMUR: 'F_DEMUR',
  DEMUR: 'DEMUR',
  R_STMNT: 'R_STMNT',
  S_RESPON: 'S_RESPON',
  S_STMT: 'S_STMT',
  F_SJUDG: 'F_SJUDG',
  AMEND: 'AMEND',
  TRLSET: 'TRLSET',
  PET_ARB: 'PET_ARB',
  N_VEXLIT: 'N_VEXLIT',
  N_DEPO_P: 'N_DEPO_P',
  N_DEPO_D: 'N_DEPO_D',
  S_PRODOC: 'S_PRODOC',
  S_INTROG: 'S_INTROG',
  D_EXAM: 'D_EXAM',
  C_CMC: 'C_CMC',
  S_RQAD: 'S_RQAD',
  F_CCCS: 'F_CCCS',
  M_STAY: 'M_STAY',
  RULING: 'RULING',
  F_REMOVE: 'F_REMOVE',
  P_CHALL: 'P_CHALL',
  D_CHALL: 'D_CHALL',
  H_DEMUR: 'H_DEMUR',
  D_ENTER: 'D_ENTER',
  HEARING: 'HEARING',
  F_MOTION: 'F_MOTION',
  ACKNLDGE: 'ACKNLDGE',
  F_QUASH: 'F_QUASH',
  M_FRESP: 'M_FRESP',
  S_DEMUR: 'S_DEMUR',
  ARGUE: 'ARGUE',
  S_OBJECT: 'S_OBJECT',
  F_COMPEL: 'F_COMPEL',
  AUDIOVID: 'AUDIOVID',
  COMPLY: 'COMPLY',
  C_TRNS: 'C_TRNS',
  R_TRNS: 'R_TRNS',
  P_ORDER: 'P_ORDER',
  MAILCOMP: 'MAILCOMP',
  S_COMP_M: 'S_COMP_M',
  RESPONSE: 'RESPONSE',
  M_DISMIS: 'M_DISMIS',
  M_GRANT: 'M_GRANT',
  S_DOCS: 'S_DOCS',
  S_WITLST: 'S_WITLST',
  S_COPIES: 'S_COPIES',
  S_QUEST: 'S_QUEST',
  S_INSTR: 'S_INSTR',
  VERDICT: 'VERDICT',
  S_GLOSS: 'S_GLOSS',
  S_LIMINE: 'S_LIMINE',
  F_LIMINE: 'F_LIMINE',
  LODGE: 'LODGE',
  C_PRETRL: 'C_PRETRL',
  EXCHANGE: 'EXCHANGE',
  EXAM: 'EXAM',
  D_REPORT: 'D_REPORT',
  LATERPT: 'LATERPT',
  F_I_COMP: 'F_I_COMP',
  S_I_COMP: 'S_I_COMP',
  N_JDGMNT: 'N_JDGMNT',
  F_BRIEF: 'F_BRIEF',
  H_MOTION: 'H_MOTION',
  S_MEMO: 'S_MEMO',
  M_STRIKE: 'M_STRIKE',
  F_APPEAL: 'F_APPEAL',
  ATTYFEES: 'ATTYFEES',
  MEDIATOR: 'MEDIATOR',
  MEDIATE: 'MEDIATE',
  ENDMEDT: 'ENDMEDT',
  S_SJUDG: 'S_SJUDG',
  S_JTSTIP: 'S_JTSTIP',
  H_NEWTRL: 'H_NEWTRL',
  M_NEWTRL: 'M_NEWTRL',
  DEPO: 'DEPO',
  M_PORDER: 'M_PORDER',
  F_TRNS: 'F_TRNS',
  S_1987: 'S_1987',
  H_QUASH: 'H_QUASH',
  S_QUASH: 'S_QUASH',
  DEFDPLEA: 'DEFDPLEA',
  D_JURY: 'D_JURY',
  INSPECT: 'INSPECT',
  EXCHLST: 'EXCHLST',
  C_MSC: 'C_MSC',
  ADR: 'ADR',
  S_CUST: 'S_CUST',
  ENFORCE: 'ENFORCE',
  OSC: 'OSC',
  PET_WRIT: 'PET_WRIT',
  S_STAY: 'S_STAY',
  ASSIGN: 'ASSIGN',
  S_JDGMNT: 'S_JDGMNT',
  N_TRIAL: 'N_TRIAL',
  F_INSTR: 'F_INSTR',
  F_EXPWIT: 'F_EXPWIT',
  F_WITLST: 'F_WITLST',
  S_BRIEF: 'S_BRIEF',
  F_EXHLST: 'F_EXHLST',
  D_EXPWIT: 'D_EXPWIT',
  H_EXPDIS: 'H_EXPDIS',
  EXPDISC: 'EXPDISC',
  S_EXPDIS: 'S_EXPDIS',
  EXAMRPT: 'EXAMRPT',
  H_NONEXP: 'H_NONEXP',
  H_SJUDG: 'H_SJUDG',
  S_NONEXP: 'S_NONEXP',
  N_EXDEPO: 'N_EXDEPO',
  SUPPXPRT: 'SUPPXPRT',
  S_DISC_H: 'S_DISC_H',
  S_DISC_M: 'S_DISC_M',
  S_WRTDIS: 'S_WRTDIS',
  J_WAIVED: 'J_WAIVED',
  REFUND: 'REFUND',
  M_PLEAD: 'M_PLEAD',
  M_ASIDE: 'M_ASIDE',
  S_DISC_E: 'S_DISC_E',
  SUBPOENA: 'SUBPOENA',
  S_DEPO: 'S_DEPO',
  F_998: 'F_998',
  OBJECT: 'OBJECT',
  H_BIFUR: 'H_BIFUR',
  S_BIFUR: 'S_BIFUR',
  CHALLENG: 'CHALLENG',
  PRESENT: 'PRESENT',
  N_SETTLE: 'N_SETTLE',
  F_STMNT: 'F_STMNT',
  DEPO_EXP: 'DEPO_EXP',
  EXP_PROD: 'EXP_PROD',
  F_MEMO: 'F_MEMO',
  M_SJUDG: 'M_SJUDG',
  SECURITY: 'SECURITY',
  F_EXTENS: 'F_EXTENS',
  S_XQUES: 'S_XQUES',
  S_REDRCT: 'S_REDRCT',
  S_RECRSS: 'S_RECRSS',
  OVERRULE: 'OVERRULE',
  F_COMPE: 'F_COMPE',
  F_S_ANSW: 'F_S_ANSW',
  REQCHIRO: 'REQCHIRO',
  FILE_CMS: 'FILE_CMS',
  M_AND_C: 'M_AND_C',
  JURY_FEE: 'JURY_FEE',
  LD: 'LD',
  COMPEL: 'COMPEL',
  E_FILING: 'E_FILING',
  STIP_ARB: 'STIP_ARB',
  STATUTE: 'STATUTE',
  REQ_REC: 'REQ_REC',
  CLCLIENT: 'CLCLIENT',
  MEDI_CAL: 'MEDI_CAL',
  MEDICARE: 'MEDICARE',
  REP_LTR: 'REP_LTR',
  EVIDENCE: 'EVIDENCE',
  MEDPAY: 'MEDPAY',
  SCHEDULE: 'SCHEDULE',
  TELEAPPR: 'TELEAPPR',
  CONTINUE: 'CONTINUE'
};

export const reminderEventTypeLabelsMap = {
  [reminderEventTypesMap.REJECT]: 'REJECT',
  [reminderEventTypesMap.APPOINTMENT]: 'APPOINTMENT',
  [reminderEventTypesMap.NOTICE]: 'NOTICE',
  [reminderEventTypesMap.NOTIFY]: 'NOTIFY',
  [reminderEventTypesMap.ARBITRATION]: 'ARBITRATION',
  [reminderEventTypesMap.REQUEST]: 'REQUEST',
  [reminderEventTypesMap.ARBHRG]: 'ARBHRG',
  [reminderEventTypesMap.DEADLINE]: 'DEADLINE',
  [reminderEventTypesMap.REMINDER]: 'REMINDER',
  [reminderEventTypesMap.REQ_AMB]: 'REQ-AMB',
  [reminderEventTypesMap.FOLLOW]: 'FOLLOW',
  [reminderEventTypesMap.CONFIRM]: 'CONFIRM',
  [reminderEventTypesMap.F_ANSWER]: 'F-ANSWER',
  [reminderEventTypesMap.E_SERVE]: 'E-SERVE',
  [reminderEventTypesMap.S_COMPLA]: 'S-COMPLA',
  [reminderEventTypesMap.F_PROOF]: 'F-PROOF',
  [reminderEventTypesMap.N_ARBIT]: 'N-ARBIT',
  [reminderEventTypesMap.D_LIST]: 'D-LIST',
  [reminderEventTypesMap.S_LISTS]: 'S-LISTS',
  [reminderEventTypesMap.S_SUBPEN]: 'S-SUBPEN',
  [reminderEventTypesMap.AWARD]: 'AWARD',
  [reminderEventTypesMap.S_AWARD]: 'S-AWARD',
  [reminderEventTypesMap.F_APPLIC]: 'F-APPLIC',
  [reminderEventTypesMap.CORRECT]: 'CORRECT',
  [reminderEventTypesMap.F_OBJECT]: 'F-OBJECT',
  [reminderEventTypesMap.PETITION]: 'PETITION',
  [reminderEventTypesMap.F_PETIT]: 'F-PETIT',
  [reminderEventTypesMap.F_RESPON]: 'F-RESPON',
  [reminderEventTypesMap.H_PETIT]: 'H-PETIT',
  [reminderEventTypesMap.N_HEAR]: 'N-HEAR',
  [reminderEventTypesMap.REMOTE]: 'REMOTE',
  [reminderEventTypesMap.APPOINT]: 'APPOINT',
  [reminderEventTypesMap.H_ARBIT]: 'H-ARBIT',
  [reminderEventTypesMap.SELECT]: 'SELECT',
  [reminderEventTypesMap.ORDER]: 'ORDER',
  [reminderEventTypesMap.PLEAD]: 'PLEAD',
  [reminderEventTypesMap.DELIVER]: 'DELIVER',
  [reminderEventTypesMap.S_DEMAND]: 'S-DEMAND',
  [reminderEventTypesMap.F_SETTLE]: 'F-SETTLE',
  [reminderEventTypesMap.S_998]: 'S-998',
  [reminderEventTypesMap.BEGDISC]: 'BEGDISC',
  [reminderEventTypesMap.DISMISS]: 'DISMISS',
  [reminderEventTypesMap.JUDGMENT]: 'JUDGMENT',
  [reminderEventTypesMap.S_WRIT]: 'S-WRIT',
  [reminderEventTypesMap.F_OPPO]: 'F-OPPO',
  [reminderEventTypesMap.F_EXEMPT]: 'F-EXEMPT',
  [reminderEventTypesMap.F_WRIT]: 'F-WRIT',
  [reminderEventTypesMap.H_WRIT]: 'H-WRIT',
  [reminderEventTypesMap.REPLY]: 'REPLY',
  [reminderEventTypesMap.MEET]: 'MEET',
  [reminderEventTypesMap.F_STMT]: 'F-STMT',
  [reminderEventTypesMap.C_SETTLE]: 'C-SETTLE',
  [reminderEventTypesMap.TRIAL]: 'TRIAL',
  [reminderEventTypesMap.F_ARBSTP]: 'F-ARBSTP',
  [reminderEventTypesMap.F_STIP]: 'F-STIP',
  [reminderEventTypesMap.$ARBLIST]: '$ARBLIST',
  [reminderEventTypesMap.D_FEES]: 'D-FEES',
  [reminderEventTypesMap.DISCOVER]: 'DISCOVER',
  [reminderEventTypesMap.N_CMC]: 'N-CMC',
  [reminderEventTypesMap.DEFAULT]: 'DEFAULT',
  [reminderEventTypesMap.F_DEMUR]: 'F-DEMUR',
  [reminderEventTypesMap.DEMUR]: 'DEMUR',
  [reminderEventTypesMap.R_STMNT]: 'R-STMNT',
  [reminderEventTypesMap.S_RESPON]: 'S-RESPON',
  [reminderEventTypesMap.S_STMT]: 'S-STMT',
  [reminderEventTypesMap.F_SJUDG]: 'F-SJUDG',
  [reminderEventTypesMap.AMEND]: 'AMEND',
  [reminderEventTypesMap.TRLSET]: 'TRLSET',
  [reminderEventTypesMap.PET_ARB]: 'PET-ARB',
  [reminderEventTypesMap.N_VEXLIT]: 'N-VEXLIT',
  [reminderEventTypesMap.N_DEPO_P]: 'N-DEPO-P',
  [reminderEventTypesMap.N_DEPO_D]: 'N-DEPO-D',
  [reminderEventTypesMap.S_PRODOC]: 'S-PRODOC',
  [reminderEventTypesMap.S_INTROG]: 'S-INTROG',
  [reminderEventTypesMap.D_EXAM]: 'D-EXAM',
  [reminderEventTypesMap.C_CMC]: 'C-CMC',
  [reminderEventTypesMap.S_RQAD]: 'S-RQAD',
  [reminderEventTypesMap.F_CCCS]: 'F-CCCS',
  [reminderEventTypesMap.M_STAY]: 'M-STAY',
  [reminderEventTypesMap.RULING]: 'RULING',
  [reminderEventTypesMap.F_REMOVE]: 'F-REMOVE',
  [reminderEventTypesMap.P_CHALL]: 'P-CHALL',
  [reminderEventTypesMap.D_CHALL]: 'D-CHALL',
  [reminderEventTypesMap.H_DEMUR]: 'H-DEMUR',
  [reminderEventTypesMap.D_ENTER]: 'D-ENTER',
  [reminderEventTypesMap.HEARING]: 'HEARING',
  [reminderEventTypesMap.F_MOTION]: 'F-MOTION',
  [reminderEventTypesMap.ACKNLDGE]: 'ACKNLDGE',
  [reminderEventTypesMap.F_QUASH]: 'F-QUASH',
  [reminderEventTypesMap.M_FRESP]: 'M-FRESP',
  [reminderEventTypesMap.S_DEMUR]: 'S-DEMUR',
  [reminderEventTypesMap.ARGUE]: 'ARGUE',
  [reminderEventTypesMap.S_OBJECT]: 'S-OBJECT',
  [reminderEventTypesMap.F_COMPEL]: 'F-COMPEL',
  [reminderEventTypesMap.AUDIOVID]: 'AUDIOVID',
  [reminderEventTypesMap.COMPLY]: 'COMPLY',
  [reminderEventTypesMap.C_TRNS]: 'C-TRNS',
  [reminderEventTypesMap.R_TRNS]: 'R-TRNS',
  [reminderEventTypesMap.P_ORDER]: 'P-ORDER',
  [reminderEventTypesMap.MAILCOMP]: 'MAILCOMP',
  [reminderEventTypesMap.S_COMP_M]: 'S-COMP-M',
  [reminderEventTypesMap.RESPONSE]: 'RESPONSE',
  [reminderEventTypesMap.M_DISMIS]: 'M-DISMIS',
  [reminderEventTypesMap.M_GRANT]: 'M-GRANT',
  [reminderEventTypesMap.S_DOCS]: 'S-DOCS',
  [reminderEventTypesMap.S_WITLST]: 'S-WITLST',
  [reminderEventTypesMap.S_COPIES]: 'S-COPIES',
  [reminderEventTypesMap.S_QUEST]: 'S-QUEST',
  [reminderEventTypesMap.S_INSTR]: 'S-INSTR',
  [reminderEventTypesMap.VERDICT]: 'VERDICT',
  [reminderEventTypesMap.S_GLOSS]: 'S-GLOSS',
  [reminderEventTypesMap.S_LIMINE]: 'S-LIMINE',
  [reminderEventTypesMap.F_LIMINE]: 'F-LIMINE',
  [reminderEventTypesMap.LODGE]: 'LODGE',
  [reminderEventTypesMap.C_PRETRL]: 'C-PRETRL',
  [reminderEventTypesMap.EXCHANGE]: 'EXCHANGE',
  [reminderEventTypesMap.EXAM]: 'EXAM',
  [reminderEventTypesMap.D_REPORT]: 'D-REPORT',
  [reminderEventTypesMap.LATERPT]: 'LATERPT',
  [reminderEventTypesMap.F_I_COMP]: 'F-I-COMP',
  [reminderEventTypesMap.S_I_COMP]: 'S-I-COMP',
  [reminderEventTypesMap.N_JDGMNT]: 'N-JDGMNT',
  [reminderEventTypesMap.F_BRIEF]: 'F-BRIEF',
  [reminderEventTypesMap.H_MOTION]: 'H-MOTION',
  [reminderEventTypesMap.S_MEMO]: 'S-MEMO',
  [reminderEventTypesMap.M_STRIKE]: 'M-STRIKE',
  [reminderEventTypesMap.F_APPEAL]: 'F-APPEAL',
  [reminderEventTypesMap.ATTYFEES]: 'ATTYFEES',
  [reminderEventTypesMap.MEDIATOR]: 'MEDIATOR',
  [reminderEventTypesMap.MEDIATE]: 'MEDIATE',
  [reminderEventTypesMap.ENDMEDT]: 'ENDMEDT',
  [reminderEventTypesMap.S_SJUDG]: 'S-SJUDG',
  [reminderEventTypesMap.S_JTSTIP]: 'S-JTSTIP',
  [reminderEventTypesMap.H_NEWTRL]: 'H-NEWTRL',
  [reminderEventTypesMap.M_NEWTRL]: 'M-NEWTRL',
  [reminderEventTypesMap.DEPO]: 'DEPO',
  [reminderEventTypesMap.M_PORDER]: 'M-PORDER',
  [reminderEventTypesMap.F_TRNS]: 'F-TRNS',
  [reminderEventTypesMap.S_1987]: 'S-1987',
  [reminderEventTypesMap.H_QUASH]: 'H-QUASH',
  [reminderEventTypesMap.S_QUASH]: 'S-QUASH',
  [reminderEventTypesMap.DEFDPLEA]: 'DEFDPLEA',
  [reminderEventTypesMap.D_JURY]: 'D-JURY',
  [reminderEventTypesMap.INSPECT]: 'INSPECT',
  [reminderEventTypesMap.EXCHLST]: 'EXCHLST',
  [reminderEventTypesMap.C_MSC]: 'C-MSC',
  [reminderEventTypesMap.ADR]: 'ADR',
  [reminderEventTypesMap.S_CUST]: 'S-CUST',
  [reminderEventTypesMap.ENFORCE]: 'ENFORCE',
  [reminderEventTypesMap.OSC]: 'OSC',
  [reminderEventTypesMap.PET_WRIT]: 'PET-WRIT',
  [reminderEventTypesMap.S_STAY]: 'S-STAY',
  [reminderEventTypesMap.ASSIGN]: 'ASSIGN',
  [reminderEventTypesMap.S_JDGMNT]: 'S-JDGMNT',
  [reminderEventTypesMap.N_TRIAL]: 'N-TRIAL',
  [reminderEventTypesMap.F_INSTR]: 'F-INSTR',
  [reminderEventTypesMap.F_EXPWIT]: 'F-EXPWIT',
  [reminderEventTypesMap.F_WITLST]: 'F-WITLST',
  [reminderEventTypesMap.S_BRIEF]: 'S-BRIEF',
  [reminderEventTypesMap.F_EXHLST]: 'F-EXHLST',
  [reminderEventTypesMap.D_EXPWIT]: 'D-EXPWIT',
  [reminderEventTypesMap.H_EXPDIS]: 'H-EXPDIS',
  [reminderEventTypesMap.EXPDISC]: 'EXPDISC',
  [reminderEventTypesMap.S_EXPDIS]: 'S-EXPDIS',
  [reminderEventTypesMap.EXAMRPT]: 'EXAMRPT',
  [reminderEventTypesMap.H_NONEXP]: 'H-NONEXP',
  [reminderEventTypesMap.H_SJUDG]: 'H-SJUDG',
  [reminderEventTypesMap.S_NONEXP]: 'S-NONEXP',
  [reminderEventTypesMap.N_EXDEPO]: 'N-EXDEPO',
  [reminderEventTypesMap.SUPPXPRT]: 'SUPPXPRT',
  [reminderEventTypesMap.S_DISC_H]: 'S-DISC-H',
  [reminderEventTypesMap.S_DISC_M]: 'S-DISC-M',
  [reminderEventTypesMap.S_WRTDIS]: 'S-WRTDIS',
  [reminderEventTypesMap.J_WAIVED]: 'J-WAIVED',
  [reminderEventTypesMap.REFUND]: 'REFUND',
  [reminderEventTypesMap.M_PLEAD]: 'M-PLEAD',
  [reminderEventTypesMap.M_ASIDE]: 'M-ASIDE',
  [reminderEventTypesMap.S_DISC_E]: 'S-DISC-E',
  [reminderEventTypesMap.SUBPOENA]: 'SUBPOENA',
  [reminderEventTypesMap.S_DEPO]: 'S-DEPO',
  [reminderEventTypesMap.F_998]: 'F-998',
  [reminderEventTypesMap.OBJECT]: 'OBJECT',
  [reminderEventTypesMap.H_BIFUR]: 'H-BIFUR',
  [reminderEventTypesMap.S_BIFUR]: 'S-BIFUR',
  [reminderEventTypesMap.CHALLENG]: 'CHALLENG',
  [reminderEventTypesMap.PRESENT]: 'PRESENT',
  [reminderEventTypesMap.N_SETTLE]: 'N-SETTLE',
  [reminderEventTypesMap.F_STMNT]: 'F-STMNT',
  [reminderEventTypesMap.DEPO_EXP]: 'DEPO-EXP',
  [reminderEventTypesMap.EXP_PROD]: 'EXP-PROD',
  [reminderEventTypesMap.F_MEMO]: 'F-MEMO',
  [reminderEventTypesMap.M_SJUDG]: 'M-SJUDG',
  [reminderEventTypesMap.SECURITY]: 'SECURITY',
  [reminderEventTypesMap.F_EXTENS]: 'F-EXTENS',
  [reminderEventTypesMap.S_XQUES]: 'S-XQUES',
  [reminderEventTypesMap.S_REDRCT]: 'S-REDRCT',
  [reminderEventTypesMap.S_RECRSS]: 'S-RECRSS',
  [reminderEventTypesMap.OVERRULE]: 'OVERRULE',
  [reminderEventTypesMap.F_COMPE]: 'F-COMPE',
  [reminderEventTypesMap.F_S_ANSW]: 'F-S-ANSW',
  [reminderEventTypesMap.REQCHIRO]: 'REQCHIRO',
  [reminderEventTypesMap.FILE_CMS]: 'FILE-CMS',
  [reminderEventTypesMap.M_AND_C]: 'M-AND-C',
  [reminderEventTypesMap.JURY_FEE]: 'JURY-FEE',
  [reminderEventTypesMap.LD]: 'LD',
  [reminderEventTypesMap.COMPEL]: 'COMPEL',
  [reminderEventTypesMap.E_FILING]: 'E-FILING',
  [reminderEventTypesMap.STIP_ARB]: 'STIP-ARB',
  [reminderEventTypesMap.STATUTE]: 'STATUTE',
  [reminderEventTypesMap.REQ_REC]: 'REQ-REC',
  [reminderEventTypesMap.CLCLIENT]: 'CLCLIENT',
  [reminderEventTypesMap.MEDI_CAL]: 'MEDI-CAL',
  [reminderEventTypesMap.MEDICARE]: 'MEDICARE',
  [reminderEventTypesMap.REP_LTR]: 'REP-LTR',
  [reminderEventTypesMap.EVIDENCE]: 'EVIDENCE',
  [reminderEventTypesMap.MEDPAY]: 'MEDPAY',
  [reminderEventTypesMap.SCHEDULE]: 'SCHEDULE',
  [reminderEventTypesMap.TELEAPPR]: 'TELEAPPR',
  [reminderEventTypesMap.CONTINUE]: 'CONTINUE'
};
