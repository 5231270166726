export const onAllImagesReady = (parent) => {
  return new Promise((resolve) => {
    const images = parent.querySelectorAll('img');
    const imagesCount = images.length;
    let count = 0;

    if (!imagesCount) {
      resolve();
      return;
    }

    const incrementCount = () => {
      count++;

      if (count === imagesCount) {
        resolve();
      }
    };

    Array.from(images).forEach((img) => {
      if (img.complete) {
        incrementCount();
      } else {
        img.addEventListener('load', incrementCount, false);
        img.addEventListener('error', incrementCount, false);
      }
    });
  });
};
