import { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { CodesContext } from '../../../CodesContext';
import { columnsWidths } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = () => {
  const classes = useStyles();
  const { selectedCodesIDs, allCodesIsSelected, toggleAllCodesSelection } = useContext(CodesContext);

  return (
    <ListRow header className={classes.root}>
      <ListRowCheckbox
        onClick={toggleAllCodesSelection}
        checked={allCodesIsSelected()}
        indeterminate={!!selectedCodesIDs.length && !allCodesIsSelected()}
      />

      <ListRowCell flexBasis={columnsWidths.code}>
        Code
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.name}>
        Payer Name
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.address}>
        Address1
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.state}>
        State
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.city}>
        City
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.phone}>
        Phone
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.class}>
        Financial Class
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.edi}>
        Claim EDI No. Prof
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
