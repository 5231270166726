export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    paddingTop: spacing(),
    paddingBottom: spacing(),
    paddingLeft: spacing(4),

    [breakpoints.down('md')]: {
      paddingLeft: spacing()
    }
  },

  grow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },

  multiActions: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  }
});
