import { useFormikContext } from 'formik';
import { Grid, FormControlLabel, Hidden } from '@material-ui/core';
import {
  Checkbox,
  TextField,
  OfficesLocationSelect,
  ArrivalStatusesSelect,
  ApptTypesSelect
} from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';
import { AvailableApptTypes } from './AvailableApptTypes';
import { AssignedUsers } from './AssignedUsers';
import { WorkSchedule } from './WorkSchedule';

export const ApptBookForm = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleDefaultApptTypeChange = (apptType) => {
    if (apptType && !values?.appointment_types?.find(({ type }) => type.id === apptType.id)) {
      const apptTypes = (values?.appointment_types || []).concat({
        type: apptType,
        appointment_type_id: apptType.id,
        is_current_active: true
      });

      setFieldValue('appointment_types', apptTypes);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <TextField
            required
            name="appointment_book_name"
            label="Appointment book name"
            placeholder="Enter book name..."
          />
        </Grid>

        <Grid item lg={3} md={6} sm={6} xs={12}>
          <UsersSelect
            required
            name="provider_id"
            label="Provider"
            params={{ role: 'doctor' }}
          />
        </Grid>

        <Hidden mdDown>
          <Grid item lg={6} md={4} />
        </Hidden>

        <Grid item lg={3} md={6} sm={6} xs={12}>
          <OfficesLocationSelect
            required
            name="office_id"
            label="Office"
            params={{ users: values?.provider_id ? [ values?.provider_id ] : null }}
          />
        </Grid>

        <Grid item lg={3} md={6} sm={6} xs={12}>
          <ArrivalStatusesSelect
            name="default_arrival_status_id"
            label="Default arrival status"
          />
        </Grid>

        <Grid item lg={3} md={6} sm={6} xs={12}>
          <ApptTypesSelect
            name="default_appointment_type_id"
            label="Default appointment type"
            onChange={handleDefaultApptTypeChange}
          />
        </Grid>

        <Hidden xsDown>
          <Grid item lg={3} md={6} sm={6} />
        </Hidden>

        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FormControlLabel
            control={<Checkbox name="ssn_required" />}
            label="SSN required for US address"
          />
        </Grid>

        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FormControlLabel
            control={<Checkbox name="address_required" />}
            label="Address required upon arrival"
          />
        </Grid>

        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FormControlLabel
            control={<Checkbox name="referring_physician_required" />}
            label="Referring physician required"
          />
        </Grid>

        <Grid item lg={3} md={6} sm={6} xs={12}>
          <FormControlLabel
            control={<Checkbox name="eligibility_auto_check" />}
            label="Eligibility auto check"
          />
        </Grid>

        <Grid item md={2} sm={3} xs={12}>
          <FormControlLabel
            control={<Checkbox name="house_call" />}
            label="House call"
          />
        </Grid>

        <Grid item md={2} sm={3} xs={6}>
          <FormControlLabel
            control={<Checkbox name="is_active" />}
            label="Is active"
          />
        </Grid>

        <Grid item md={2} sm={3} xs={6}>
          <FormControlLabel
            control={<Checkbox name="is_private" />}
            label="Is private"
          />
        </Grid>

        <Grid item xl={7} md={7} xs={12}>
          <WorkSchedule />
        </Grid>
      </Grid>

      <AvailableApptTypes formFieldName="appointment_types" />
      <AssignedUsers formFieldName="assign_users" />
    </>
  );
};
