import { makeStyles } from '@material-ui/core';
import { Route } from 'react-router-dom';
import { routes } from './routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CaseReminders = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {routes.map(({ exact, path, component: Component }) => (
        <Route
          key={path}
          exact={exact}
          path={path}
        >
          <Component />
        </Route>
      ))}
    </div>
  );
};
