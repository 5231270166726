import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_APPOINTMENTS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_APPOINTMENTS_SUCCESS]: (
    { appointments, filter, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      appointments: page > 1
        ? appointments.filter(({ id }) => !data.find((appointment) => id === appointment.id)).concat(data)
        : data
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_APPOINTMENTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.UPDATE_APPOINTMENT]: (state, appointment) => {
    return {
      ...state,

      appointments: state.appointments.map((item) => {
        return item.id === appointment.id ? { ...item, ...appointment } : item;
      })
    };
  },

  [types.DELETE_APPOINTMENTS]: ({ pagination, filter, appointments, ...state }, appointmentsIDs) => {
    const deletedAppointments = appointments.filter((appt) => appointmentsIDs.find((id) => id === appt.id));
    const total = pagination.total - deletedAppointments.length;
    const page = Math.ceil((appointments.length - deletedAppointments.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      appointments: appointments.filter((appointment) => !deletedAppointments.find(({ id }) => id === appointment.id))
    };
  },

  [types.DELETE_APPOINTMENT]: ({ filter, ...state }, appointmentID) => {
    return {
      ...state,

      filter,
      appointments: state.appointments.filter(({ id }) => appointmentID !== id)
    };
  }
});
