import { Grid, Typography } from '@material-ui/core';
import {
  relationshipMap
} from '../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/LeftSidebar/Contacts/ContactsModal';
import { KeyboardDatePicker, Select, TextField } from '../../FormField';
import { DoiForm } from '../forms';

export const InsuranceInfo = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography color="secondary" variant="h5">
          Doctor info
        </Typography>
      </Grid>

      <Grid item xl={4} md={4} sm={6} xs={12}>
        <TextField
          disabled
          name="primary_treating_physician"
          label="Physician"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography color="secondary" variant="h5">
          Insurance Info
        </Typography>
      </Grid>

      <DoiForm />

      <Grid item xl={4} md={4} sm={6} xs={12}>
        <TextField
          name="primary_insurance_name"
          label="Primary Insurance"
        />
      </Grid>

      <Grid item xl={4} md={4} sm={6} xs={12}>
        <TextField
          name="primary_insurance_id_number"
          label="Insurance ID"
        />
      </Grid>

      <Grid item xl={4} md={4} sm={6} xs={12}>
        <TextField
          name="group_id_number"
          label="Group ID"
        />
      </Grid>

      <Grid item xl={2} md={4} sm={6} xs={12}>
        <TextField
          name="primary_insurance_holder_first_name"
          label="Pr. Ins. Holder First name"
        />
      </Grid>

      <Grid item xl={2} md={4} sm={6} xs={12}>
        <TextField
          name="primary_insurance_holder_last_name"
          label="Pr. Ins. Holder Last name"
        />
      </Grid>

      <Grid item xl={4} md={4} sm={6} xs={12}>
        <Select
          isClearable
          formattedValue
          name="relationship_to_patient"
          label="Relationship to Patient"
          options={relationshipMap}
        />
      </Grid>

      <Grid item xl={4} md={4} sm={6} xs={12}>
        <KeyboardDatePicker
          name="primary_insurance_holder_dob"
          label="DOB"
          outputFormat="YYYY-MM-DD"
          placeholder="MM/DD/YYYY"
        />
      </Grid>
    </>
  );
};
