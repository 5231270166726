import { useContext } from 'react';
import cn from 'classnames';
import filesize from 'filesize';
import { useModal } from 'react-modal-hook';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Checkbox } from '../../../../../components/FormField';
import { Popper } from '../../../../../components/Popper';
import { stopPropagation } from '../../../../../helpers/dom';
import {
  FilePreviewModal,
  FileTypeIcon,
  fileTypesIconsVariants
} from '../../../files-common';
import { MessagesContext } from '../../MessagesContext';
import { Menu } from './Menu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const aggregateType = [ 'spreadsheet', 'document', 'pdf' ];

export const AttachmentItem = ({
  isSavedAttachments = false,
  selectedFiles,
  attachment,
  attachments,
  toggleItemSelection
}) => {
  const classes = useStyles();
  const messagesContext = useContext(MessagesContext);
  const isSelected = selectedFiles.indexOf(attachment.original_filename || attachment.file_name) !== -1;
  const variant = attachment && fileTypesIconsVariants.find(({ condition }) => condition(attachment));

  const handleItemSelection = () => {
    toggleItemSelection(attachment.original_filename || attachment.file_name);
  };

  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        disableFetch: true,
        file: attachment,
        files: attachments,
        isFetched: true,
        isFetching: false
      }}
    />
  ), [ attachment, attachments.length ]);

  return (
    <div
      className={cn(classes.root, { [classes.root_preview]: !messagesContext })}
      onClick={openFilesPreview}
    >
      {variant && attachment.preview && (
        <div className={classes.main}>
          {aggregateType.includes(attachment?.aggregate_type) ? (
            <FileTypeIcon file={attachment} className={classes.pdfIcon} />
          ) : (
            <img
              src={attachment?.pdf || attachment?.preview}
              className={classes.previewImg}
              alt={attachment.extension}
            />
          )}
        </div>
      )}

      <div className={classes.info}>
        <FileTypeIcon file={attachment} className={classes.icon} />

        <div className={classes.infoMain}>
          <Typography noWrap className={classes.name} title={attachment.original_filename}>
            {attachment.original_filename}
          </Typography>

          {attachment.size > 0 &&
            <Typography className={classes.size}>{filesize(attachment.size)}</Typography>
          }
        </div>

        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton
                buttonRef={anchorRef}
                color="inherit"
                size="small"
                onClick={stopPropagation(handleToggle)}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <Menu
              isSavedAttachments={isSavedAttachments}
              isDisabledView={messagesContext && !messagesContext?.isFetchedMedia}
              attachment={attachment}
              openFilesPreview={openFilesPreview}
              onClose={handleClose}
            />
          )}
        </Popper>

        {!isSavedAttachments &&
          <Box component="span" mr={-0.5}>
            <Checkbox
              withoutFormik
              checked={isSelected}
              size="small"
              color={isSelected ? 'primary' : 'secondary'}
              onClick={stopPropagation(handleItemSelection)}
            />
          </Box>
        }
      </div>
    </div>
  );
};
