import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import printJS from 'print-js';
import moment from 'moment';
import { Box, Dialog, Typography, useMediaQuery } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import * as workClocksApi from '../../../api/work-clocks';
import * as timeReportsApi from '../../../api/work-clocks';
import { ConfirmationModal } from '../../ConfirmationModal';
import { IconButton } from '../../IconButton';
import { useModal } from '../../ModalsProvider';
import { Tooltip } from '../../Tooltip';
import { Loader } from '../../Loader';
import { Date } from '../../Date';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderActions
} from '../../Modal';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { eventBus, eventBusEvents } from '../../../utils/eventBus';
import { hasRole } from '../../../utils/hasRole';
import { PayCodeSummary } from './PayCodeSummary';
import { PayPeriodTotal } from './PayPeriodTotal';
import { Header } from './Header';
import { Body } from './Body';

export const TimeReportsPreviewModal = ({
  DialogProps,
  handleModalReject,
  payload: { reportID }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ report, setReport ] = useState(null);
  const { openModal } = useModal();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const currentUser = useSelector(({ profile }) => profile.user);
  const isWorkerApprove = currentUser.id === report?.user?.id;

  const approveReportAsWorker = () => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Are you agree?',
        // eslint-disable-next-line max-len
        content: `I acknowledge that I have taken all the legally authorized rest & meal break periods as required by ${report.company.name} policies and procedures.`
      },

      onModalResolved: () => {
        workClocksApi.workerApproveTimeReport(reportID).then((report) => {
          setReport(report);
          eventBus.dispatch(eventBusEvents.timeReportUpdate, report);
          enqueueSnackbar('Report approved', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Report not approved', { variant: 'error' });
        });
      }
    });
  };

  const approveReportAsSupervisor = () => {
    workClocksApi.approveTimeReport(reportID).then((report) => {
      setReport(report);
      eventBus.dispatch(eventBusEvents.timeReportUpdate, report);
      enqueueSnackbar('Report approved', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Report not approved', { variant: 'error' });
    });
  };

  const disapproveReportAsSupervisor = () => {
    workClocksApi.disapproveTimeReport(reportID).then((report) => {
      setReport(report);
      eventBus.dispatch(eventBusEvents.timeReportUpdate, report);
      enqueueSnackbar('Report disapproved', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Report not disapproved', { variant: 'error' });
    });
  };

  const printReport = () => {
    workClocksApi.fetchTimeReportPDF(reportID).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      printJS({ printable: url, type: 'pdf' });
    });
  };

  const downLoadReport = () => {
    workClocksApi.fetchTimeReportPDF(reportID).then((data) => {
      const blob = new Blob([ data ]);
      const fromDate = moment(report.date_from).format('L');
      const toDate = moment(report.date_to).format('L');

      saveAs(blob, `${fromDate}-${toDate}.pdf`);
    });
  };

  useEffect(() => {
    timeReportsApi.fetchTimeReport(reportID).then(setReport);
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <Loader p={3} loading={!report} render={
          () => (
            <>
              <ModalHeader disableTypography onClose={handleModalReject}>
                <Box display="flex" alignItems="center" flexGrow={1}>
                  <Box display="flex" alignItems="center" flexWrap="wrap" color="text.primary">
                    <Typography variant="h6" color="inherit">Timecard</Typography>
                    &nbsp;&nbsp;
                    <Date disableIcon color="inherit" variant="h6" date={report.date_from} />
                    &nbsp;
                    <Typography variant="h6" color="inherit">-</Typography>
                    &nbsp;
                    <Date disableIcon color="inherit" variant="h6" date={report.date_to} />
                    &nbsp;&nbsp;
                    <Typography variant="h6" color="inherit">(Historical Pay Period)</Typography>
                  </Box>

                  <ModalHeaderActions disableGutters>
                    <Tooltip title={report.worker_approved_at ? 'Approved by worker' : 'Approve as worker'}>
                      <div>
                        <IconButton
                          disabled={!isWorkerApprove || !!report?.worker_approved_at}
                          color="success"
                          onClick={approveReportAsWorker}
                        >
                          <VerifiedUserOutlinedIcon />
                        </IconButton>
                      </div>
                    </Tooltip>

                    <Tooltip title={report.approved_at ? 'Disapprove by supervisor' : 'Approve as supervisor'}>
                      <div>
                        <IconButton
                          disabled={!hasRole(rolesMap.admin, rolesMap.supervisor)}
                          color={report.approved_at ? 'success' : 'warning'}
                          onClick={report.approved_at ? disapproveReportAsSupervisor : approveReportAsSupervisor}
                        >
                          <VerifiedUserOutlinedIcon />
                        </IconButton>
                      </div>
                    </Tooltip>

                    <Tooltip title="Print">
                      <div>
                        <IconButton color="info" onClick={printReport}>
                          <PrintOutlinedIcon />
                        </IconButton>
                      </div>
                    </Tooltip>

                    <Tooltip title="Download">
                      <div>
                        <IconButton color="primary" onClick={downLoadReport}>
                          <DownloadIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </ModalHeaderActions>
                </Box>
              </ModalHeader>

              <ModalBody>
                <Header report={report} />
                <Body report={report} />
                <PayPeriodTotal report={report} />
                <PayCodeSummary report={report} />
              </ModalBody>
            </>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
