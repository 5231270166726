import { useFormikContext } from 'formik';
import { Box, FormControlLabel } from '@material-ui/core';
import { Checkbox, transformAccountToOption } from '../../../../../components/FormField';
import { transformAccountsToOptions } from '../../../../../components/emails/options';
import { useModal } from '../../../../../components/ModalsProvider';
import { CreateEmailsRuleFormModal } from '../../Rules/MainContent/CreateEmailsRuleFormModal';

export const Rules = ({ usersTo, accounts, caseItem, patient }) => {
  const { openModal } = useModal();
  const { values, errors, setFieldValue } = useFormikContext();
  const accountsOptions = transformAccountsToOptions(accounts);
  const account = accounts.find(({ id }) => id === values.config_id);
  const selectedConfigId = account?.id ? transformAccountToOption(account) : accountsOptions[0];

  const saveRule = (event) => {
    if (event.target.checked) {
      openModal(CreateEmailsRuleFormModal, {
        payload: {
          accounts,
          initialRule: {
            config_id: selectedConfigId,
            conditions: [
              { key: 'from', values: usersTo },
              { key: 'subject', values: values.subject ? [ values.subject ] : null }
            ],
            actions: [
              { key: values.patient_id && 'attach_patient', value: patient },
              { key: values.case_id && 'attach_case', value: caseItem }
            ],
            stop_processing: false,
            is_active: 1
          }
        },
        onModalRejected: () => {
          setFieldValue('isRule', false);
        }
      });
    }
  };

  return (
    <Box my={2}>
      <FormControlLabel
        label="Create rule"
        control={
          <Checkbox
            disabled={!values.to || !!errors?.to}
            color="primary"
            name="isRule"
            onChange={saveRule}
          />
        }
      />
    </Box>
  );
};
