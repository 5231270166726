import { useContext, useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import { IconButton, Typography, Box, Link, Button } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FaxNumber, parseFaxContact } from '../../../../../components/faxes/FaxNumber';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { Contact } from '../../../../../components/Contact';
import { Date } from '../../../../../components/Date';
import { preventDefault, stopPropagation } from '../../../../../helpers/dom';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../components/ListRow';
import { UserLink } from '../../../../../components/UserLink';
import { Loader } from '../../../../../components/Loader';
import { Popper } from '../../../../../components/Popper';
import { useModal as useDefaultModal } from '../../../../../components/ModalsProvider';
import { FilePreviewModal, FileTypeIcon } from '../../../files-common';
import { faxStatusColorsMap, faxStatusesMap } from '../../helpers';
import { StatusConfirmationPopup } from '../../StatusConfirmationPopup';
import { SendFaxModal } from '../../ActionsBar/SendFaxModal';
import { ReplyFaxModal } from '../../FaxMenu/ReplyFaxModal';
import { FaxContext } from '../../FaxProvider';
import { tabsMap } from '../../PageContent';
import { FaxMenu } from '../../FaxMenu';
import { statusLabelsMap, statusesMap } from '../statusMap';
import { columnsWidths } from '../List';

export const Row = ({ item: fax = {}, isLoaded }) => {
  const { openModal } = useDefaultModal();
  const { filter, selectedFaxesIDs, updateStatus, toggleFaxSelected } = useContext(FaxContext);
  const { name, phone } = useMemo(() => {
    return parseFaxContact(fax.from);
  }, [ fax.from ]);
  const isReceived = filter?.destination === tabsMap.income;
  const isSelected = selectedFaxesIDs?.indexOf(fax.id) !== -1;
  const isNew = !fax?.system_status || fax?.system_status === statusesMap.new;
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        disableFetch: true,
        file: fax.files?.[0],
        singleView: true
      }}
    />
  ), [ fax.media?.[0] ]);

  const sendByFax = () => {
    openModal(SendFaxModal, {
      payload: {
        initialValues: {
          file_id: fax?.files?.[0]?.id,
          to: fax.to
        }
      }
    });
  };

  const handleFaxReply = () => {
    openModal(ReplyFaxModal, {
      payload: {
        initialValues: {
          to: phone,
          name: name
        }
      }
    });
  };

  const onUpdate = () => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Are you about to update the fax status?',
        maxWidth: 'sm',
        content: <StatusConfirmationPopup fax={fax} />
      },
      onModalResolved: () => {
        const newStatus = fax?.system_status === statusesMap.processed
          ? statusesMap.new : statusesMap.processed;

        updateStatus({ id: fax.id, status: newStatus });
      }
    });
  };

  const handleItemSelection = () => {
    toggleFaxSelected(fax);
  };

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow px={0}>
      <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />

      <ListRowCell flexBasis={columnsWidths.sentDate}>
        <Date disableIcon date={fax.sent_at} format="L LT" />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.user}>
        {fax.user
          ? (
            <UserLink noWrap size="xs" user={fax.user}>
              <Contact
                color="textPrimary"
                type="mailto"
                variant="caption"
                contact={fax?.user?.email}
              />
            </UserLink>
          ) : !!fax?.email ? (
            <Contact
              noWrap
              color="primary"
              type="mailto"
              contact={fax?.email}
            />
          ) : name}
      </ListRowCell>

      <ListRowCell
        grow
        cursor={isReceived ? 'pointer' : undefined}
        flexBasis={columnsWidths.from}
        onClick={isReceived ? handleFaxReply : undefined}
      >
        {fax?.fromContact?.id ? (
          <UserLink noWrap size="xs" user={fax?.fromContact}>
            <Typography noWrap color="textSecondary" variant="caption">
              {fax?.fromContact?.mobile_phone_number || fax?.fromContact?.email}
            </Typography>
          </UserLink>
        ) : (
          <FaxNumber contact={fax.from} />
        )}
      </ListRowCell>

      <ListRowCell
        flexBasis={columnsWidths.to}
        cursor={!isReceived ? 'pointer' : undefined}
        onClick={!isReceived ? sendByFax : undefined}
      >
        {fax?.toContact?.id ? (
          <UserLink noWrap size="sm" user={fax?.toContact}>
            <Typography noWrap color="textSecondary" variant="caption">
              {fax?.toContact?.mobile_phone_number || fax?.toContact?.email}
            </Typography>
          </UserLink>
        ) : (
          <FaxNumber contact={fax.to} />
        )}
      </ListRowCell>

      <ListRowCell noWrap grow flexBasis={columnsWidths.file}>
        <Box display="flex" alignItems="center">
          {!!fax?.files && (
            <Box mr={1}>
              <FileTypeIcon file={fax.files[0]} />
            </Box>
          )}

          <Link
            noWrap
            component={Box}
            sx={{ cursor: 'pointer' }}
            onClick={openFilesPreview}
          >
            {fax.files?.[0]?.original_filename}
          </Link>
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.pages}>
        <Typography>
          {fax?.pages || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.statusInfo}>
        {!!fax?.statusChangedBy?.id ? (
          <UserLink noWrap size="xs" user={fax?.statusChangedBy}>
            <Date
              color="textSecondary"
              format="L LT"
              variant="caption"
              date={fax?.system_status_changed_at}
            />
          </UserLink>
        ) : (
          <Typography noWrap color="textSecondary">No info</Typography>
        )}
      </ListRowCell>

      <ListRowCell color={isNew ? 'error' : 'primary'} flexBasis={columnsWidths.status}>
        <Button size="small" color="inherit" onClick={onUpdate}>
          {statusLabelsMap[fax?.system_status] || statusLabelsMap.new}
        </Button>
      </ListRowCell>

      <ListRowCell column flexBasis={columnsWidths.view}>
        <Box color={faxStatusColorsMap?.[fax?.status] || 'text.secondary'}>
          <Typography color="inherit">
            {faxStatusesMap[fax?.status]}
          </Typography>
        </Box>

        <Date disableIcon date={fax.received_at || fax.sent_at} format="L LT" />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={preventDefault(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <FaxMenu
              fax={fax}
              openFilesPreview={openFilesPreview}
              onClose={handleClose}
            />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
