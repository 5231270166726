import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as invoicesApi from '../../../../../../api/billing';
import { Loader } from '../../../../../../components/Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../components/Modal';
import { generateAggregateFileType } from '../../../../../../utils/generateAggregateFileType';
import { Form } from './Form';

export const SendByModal = ({
  isModalEntered,
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    invoice
  }
}) => {
  const currentUser = useSelector(({ profile }) => profile.user);

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    invoicesApi.downloadInvoicePDF(invoice.id).then((blob) => {
      const name = `${invoice.number}.pdf`;
      const file = new File([ blob ], name, { type: blob.type });

      file.aggregate_type = generateAggregateFileType(file.type);

      handleModalResolve({ ...values, file });
    });
  };

  return (
    <Dialog maxWidth="sm" {...DialogProps}>
      <Formik
        initialValues={{
          user: currentUser,
          config_id: currentUser.id,
          to: currentUser?.email ? [ currentUser ] : null,
          fax: currentUser?.fax,
          faxDomain: null
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader onClose={handleModalReject}>
              Send by
            </ModalHeader>

            <ModalBody disablePaddings>
              <Form isModalEntered={isModalEntered} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Send
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
