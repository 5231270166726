import { SvgIcon } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiFileAccountOutline } from '@mdi/js';

export const clientMenuListSm = [
  {
    path: '/profile/medical-info/medical-forms',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileAccountOutline} /></SvgIcon>,
    name: 'Medical Forms'
  }
];
