import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_SUCCESS]: (
    { filter, reports, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      selectedIDs: [],
      filter: { ...filter, page, per_page },
      reports: page > 1
        ? reports.filter(({ id }) => !data.find((report) => id === report.id)).concat(data)
        : data
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_REPORTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_REPORT]: (state, payload) => {
    const { pagination, filter, reports, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((reports.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page: page === 0 ? 1 : page },
      reports: [ payload, ...reports ]
    };
  },

  [types.UPDATE_REPORT_IN_LIST]: ({ reports, ...state }, payload) => {
    return {
      ...state,

      reports: reports.map((item) => item.id === payload.id ? payload : item)
    };
  },

  [types.DELETE_REPORT_SUCCESS]: ({ reports, pagination, filter, ...state }, reportId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      reports: reports.filter(({ id }) => id !== reportId)
    };
  },

  [types.TOGGLE_ALL_ITEMS_SELECTION]: ({ reports, selectedIDs, ...state }) => {
    const allItemIsSelected = reports.length === selectedIDs.length;

    return {
      ...state,

      reports,
      selectedIDs: allItemIsSelected ? [] : reports.map((item) => item.id)
    };
  },

  [types.TOGGLE_ITEM_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1 ?
        selectedIDs.filter((item) => item !== id)
        :
        selectedIDs.concat(id)
    };
  },

  [types.MASS_DELETE_REPORTS]: ({ reports, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      reports: reports.filter((report) => IDs.indexOf(report.id) < 0)
    };
  }
});
