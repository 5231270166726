import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  group_id: Yup.mixed().nullable().required(),
  type: Yup.mixed().nullable().required(),
  period: Yup.mixed().nullable().required(),
  interval: Yup.number().nullable().required().integer().typeError('Must be a number'),
  description: Yup.string().nullable().required(),
  case_roles: Yup.mixed().nullable().required(),
  previous_event_id: Yup.mixed().nullable(),
  position: Yup.number().nullable().integer().positive().typeError('Must be a number')
});
