import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Dialog, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { Loader } from '../../Loader';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../Modal';
import * as appointmentsApi from '../../../api/appointments';
import { AuthorizationForm, procedureInitialValues } from './AuthorizationForm';
import { validationSchema } from './validationSchema.js';

export const AuthorizationFormModal = (({
  DialogProps,
  payload: {
    authorization
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const submitAuthorization = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (!authorization?.id) {
      return appointmentsApi.createAuthorization(values).then((authorization) => {
        enqueueSnackbar('Authorization successfully created', { variant: 'success' });
        handleModalResolve(authorization);
      }).catch(({ status, data: { errors } = {} }) => {
        if (status === 422) {
          errors && setErrors(errors);
        }

        enqueueSnackbar('Authorization not created', { variant: 'error' });
      });
    }

    return appointmentsApi.updateAuthorization(values).then((authorization) => {
      enqueueSnackbar('Authorization successfully updated', { variant: 'success' });
      handleModalResolve(authorization);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }

      enqueueSnackbar('Authorization not updated', { variant: 'error' });
    });
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <Formik
        initialValues={{
          authorization: null,
          is_pending: false,

          ...authorization,

          referring_physician_id: authorization?.referring_physician,
          primary_care_physician_id: authorization?.primary_care_physician,
          procedures: authorization?.procedures?.map((procedure) => ({
            ...procedure,

            procedure_id: procedure.procedure
          })) || [ procedureInitialValues ]
        }}
        validationSchema={validationSchema}
        onSubmit={submitAuthorization}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Authorization
            </ModalHeader>

            <ModalBody>
              <AuthorizationForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
});
