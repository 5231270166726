export const TASKS_FETCH_REQUEST = 'TASKS_FETCH_REQUEST';
export const RESET_TASKS = 'RESET_TASKS';
export const TASKS_FETCH_SUCCESS = 'TASKS_FETCH_SUCCESS';
export const ADD_TASKS = 'ADD_TASK';
export const APPLY_FILTER = 'APPLY_FILTER';
export const UPDATE_TASK_IN_LIST = 'UPDATE_TASK_IN_LIST';
export const UPDATE_TASK_POSITION_SUCCESS = 'UPDATE_TASK_POSITION_SUCCESS';
export const UPDATE_TASK_POSITION_FAILD = 'UPDATE_TASK_POSITION_FAILD';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const MASS_DELETE_TASK = 'MASS_DELETE_TASK';
export const TOGGLE_TASK_SELECTION = 'TOGGLE_TASK_SELECTION';
export const TOGGLE_ALL_TASKS_SELECTION = 'TOGGLE_ALL_TASKS_SELECTION';
