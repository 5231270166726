import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, omit } from 'lodash';
import { Box } from '@material-ui/core';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { saveFilter } from '../../../../../../store/lastFilters';
import { ApptTypesSelect, OrderByAutocomplete, TextField } from '../../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { IconComponent } from '../../../../../../components/saved-filters';
import { UsersSelect } from '../../../../../../components/users';
import { StatusesSelect } from '../../../StatusesSelect';
import { AppointmentBooksContext } from '../AppointmentBooksContext';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { transformRelationsForFilterToOptions } from './utils';
import { orderByOptions } from './orderByOptions';
import { initialValues } from './initialValues';
import { CardContent } from './CardContent';
import { List } from './List';

const MODAL_WIDTH = 830;

export const FiltersBar = ({ hiddenFields = [], filterKey, ...props }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { relationsForFilter, setRelationsForFilter, applyFilter } = useContext(AppointmentBooksContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleUsersMultiSelect = (name) => (users) => {
    applyFilter({ [name]: users?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, [name]: users }));
  };

  const handleApptTypeChange = (apptTypes) => {
    applyFilter({ appointment_types: apptTypes?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, appointment_types: apptTypes }));
  };

  const handleSelectChange = (name) => (option) => {
    applyFilter(({ [name]: option?.value }));
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data || option }));
  };

  const handleStatusChange = (option) => {
    applyFilter({ is_active: option?.value });
    setRelationsForFilter((state) => ({ ...state, is_active: option }));
  };

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ order_direction: orderDirection });
    setRelationsForFilter((state) => ({ ...state, order_direction: orderDirection }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(transformRelationsForFilterToOptions(omit(filter, hiddenFields)));

    applyFilter(omit({
      ...filter,

      is_active: filter?.is_active?.value,
      providers: filter?.providers?.map(({ id }) => id),
      appointment_types: filter.appointment_types?.map(({ id }) => id),
      order_by: filter?.order_by?.value
    }, hiddenFields));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      border={0}
      initialValues={transformRelationsForFilterToOptions(initialValues)}
      onReset={() => applySavedFilter(initialValues)}
      filterKey={filterKey}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      hiddenFields={hiddenFields}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.search,
          label: filterFieldsLabels[filterFieldsMap.search],
          field: <TextField
            name="search"
            label="Search"
            placeholder="Search..."
            onChange={handleFieldChange('search')}
          />
        },
        {
          fieldKey: filterFieldsMap.providers,
          label: filterFieldsLabels[filterFieldsMap.providers],
          field: <UsersSelect
            multiple
            name="providers"
            label="Providers"
            params={{ role: rolesMap.doctor }}
            onChange={handleUsersMultiSelect('providers')}
            minWidth={210}
          />
        },
        {
          fieldKey: filterFieldsMap.appointmentTypes,
          label: filterFieldsLabels[filterFieldsMap.appointmentTypes],
          field: <Box minWidth={210}>
            <ApptTypesSelect
              multiple
              name="appointment_types"
              label="Appointment types"
              onChange={handleApptTypeChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.status,
          label: filterFieldsLabels[filterFieldsMap.status],
          field: (
            <StatusesSelect
              name="is_active"
              label="Status"
              onChange={handleStatusChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.orderBy,
          label: filterFieldsLabels[filterFieldsMap.orderBy],
          field: <Box minWidth={160}>
            <OrderByAutocomplete
              label="Order By"
              name="order_by"
              options={orderByOptions}
              orderDirection={relationsForFilter.order_direction}
              onOrderDirectionChange={toggleOrderDirection}
              onChange={handleSelectChange('order_by')}
            />
          </Box>
        }
      ]}

      {...props}
    />
  );
};
