import { useContext } from 'react';
import { AppointmentsContext, AppointmentRow } from '../../../../../../../components/appointments';

export const Row = ({ item: appointment, ...props }) => {
  const { reloadAppointments } = useContext(AppointmentsContext);

  return (
    <AppointmentRow
      appointment={appointment}
      onAppointmentUpdate={reloadAppointments}
      onAppointmentDelete={reloadAppointments}

      {...props}
    />
  );
};
