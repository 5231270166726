import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { DragDropContext } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core';
import { stopPropagation } from '../../../../helpers/dom';
import { moveArrayItem } from '../../../../helpers/moveArrayItem';
import { setTasksLastGlobalAction } from '../../../../store/globalActions';
import * as taskTypes from '../../../../components/tasks/TasksProvider/types';
import { ChecklistContext } from './ChecklistContex';
import { ChecklistForm } from './ChecklistForm';
import { EditForm } from './EditForm';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Checklist = ({ task, count, parentType, parentID }) => {
  const {
    filter,
    checklist,
    isFetching,
    isFetched,

    ...checklistContext
  } = useContext(ChecklistContext);
  const [ isCheckedHidden, setIsCheckedHidden ] = useState(false);
  const dispatchRedux = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const toggleCheckedVisibility = () => {
    setIsCheckedHidden((state) => !state);
  };

  const createChecklistItem = (listItem, { isSubmitting, setSubmitting, setErrors, resetForm }) => {
    if (isSubmitting) {
      return;
    }

    if (listItem.text.trim().length === 0) {
      enqueueSnackbar('You can\'t add empty item', { variant: 'warning' });
      setSubmitting(false);
      resetForm();
    } else {
      checklistContext.createChecklistItem(listItem).then(() => {
        resetForm();
      }).catch(({ status, data: { errors } = {} }) => {
        if (status === 422) {
          errors && setErrors(errors);
        }
      });
    }
  };

  const handleListItemDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    const newChecklist = moveArrayItem(
      checklist,
      source.index,
      destination.index
    );

    checklistContext.updateChecklist(newChecklist, destination.index);
  };

  useEffect(() => {
    dispatchRedux(setTasksLastGlobalAction({
      type: taskTypes.UPDATE_TASK_IN_LIST,
      payload: {
        ...task,

        todo_list: checklist
      }
    }));
  }, [ checklist ]);

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          text: '',
          is_checked: 0,
          parent: parentType,
          parent_id: parentID
        }}
        onSubmit={createChecklistItem}
      >
        {
          ({ handleSubmit }) => (
            <form onSubmit={stopPropagation(handleSubmit)}>
              <ChecklistForm
                isChecked={isCheckedHidden}
                count={count}
                checklist={checklist}
                onClick={toggleCheckedVisibility}
              />
            </form>
          )
        }
      </Formik>

      <DragDropContext onDragEnd={handleListItemDragEnd}>
        <List
          id={task.id}
          isCheckedHidden={isCheckedHidden}
          checklist={checklist}
          EditForm={EditForm}
          onUpdate={checklistContext.updateChecklistItem}
          onDelete={checklistContext.deleteChecklistItem}
        />
      </DragDropContext>
    </div>
  );
};
