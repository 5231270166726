export const styles = ({
  spacing,
  palette: { text, error, divider },
  typography: { fontWeightMedium, pxToRem }
}) => ({
  root: {
    width: '100%'
  },

  mainTitle: {
    color: text.caption,
    fontWeight: fontWeightMedium,
    minWidth: 70,
    marginBottom: spacing(2)
  },

  title: {
    color: text.caption,
    fontWeight: fontWeightMedium,
    minWidth: 70
  },

  date: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${divider}`,
    marginBottom: spacing(1),
    paddingBottom: spacing(1),
    marginTop: spacing(0.5),

    '&:last-child': {
      borderBottom: 0
    }
  },

  date__content: {
    fontSize: pxToRem(12)
  }
});
