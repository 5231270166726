import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { logout } from '../../../../../store/auth/actions';
import * as resetApi from '../../../../../api/auth';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../components/ContentCard';
import { Contact } from '../../../../../components/Contact';
import { Fab } from '../../../../../components/Fab';

export const Account = ({ user }) => {
  const { openModal } = useModal();
  const [ isCollapsed, setIsCollapsed ] = useState(false);
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const profile = useSelector(({ profile }) => profile);
  const history = useHistory();

  const toggleCollapsed = () => {
    setIsCollapsed((state) => !state);
  };

  const resetPassword = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        resetApi.resetPassword({ email: user.email }).then(() => {
          dispatchRedux(logout());
          history.push('/reset');
        }).catch(({ data: { message, errors } }) => {
          if (errors) {
            const errorMessage = Object.values(errors);

            message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
          }
        });
      }
    });
  };

  return (
    <ContentCard
      autoHeightMax={800}
      isCollapsed={isCollapsed}
      title="Account"
      rightActions={[
        <IconButton onClick={toggleCollapsed} color="inherit">
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      ]}
      component={Box}
      mb={1}
    >
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={4}>
          <Typography color="textSecondary">
            Email
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            variant="h5"
            type="mailto"
            contact={user.email}
          />
        </Grid>

        {profile.user.id === user.id &&
          <Grid item xs={6}>
            <Fab
              color="primary"
              variant="extended"
              onClick={resetPassword}
            >
              Reset password
            </Fab>
          </Grid>
        }
      </Grid>
    </ContentCard>
  );
};
