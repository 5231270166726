import { Yup } from '../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  financial_class_id: Yup.mixed().nullable().required(),
  claim_filing_id: Yup.mixed().nullable().required(),
  claim_processing: Yup.mixed().nullable(),
  timely_filling: Yup.mixed().nullable(),
  company_id: Yup.mixed().nullable().required(),
  office_id: Yup.mixed().nullable(),
  professional_edi: Yup.string().nullable().max(255),
  institutional_edi: Yup.string().nullable().max(255),
  name: Yup.string().nullable().required().max(255),
  address_1: Yup.string().nullable().required().max(255),
  zip: Yup.string().required().max(255),
  state: Yup.string().required().max(255),
  city: Yup.string().required().max(255),
  insurance_type_code: Yup.string().nullable().max(255),
  box_17: Yup.string().nullable().max(255),
  box_24: Yup.string().nullable().max(255),
  box_31: Yup.string().nullable().max(255),
  box_32: Yup.string().nullable().max(255),
  box_33: Yup.string().nullable().max(255),
  payer_code: Yup.string().nullable().max(255),
  eligibility_edi: Yup.string().nullable().max(255),
  code: Yup.string().nullable().max(255),
  main_phone: Yup.string().nullable().max(255),
  claim_phone: Yup.string().nullable().max(255),
  eligibility_phone: Yup.string().nullable().max(255),
  auth_cert_phone: Yup.string().nullable().max(255),
  fax_1: Yup.string().nullable().max(255),
  fax_2: Yup.string().nullable().max(255),
  provider_id_used: Yup.string().nullable().required().max(255),
  secondary_e_claims: Yup.boolean(),
  npi_only: Yup.boolean(),
  accept_npi: Yup.boolean(),
  icd_10: Yup.boolean()
});
