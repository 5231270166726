import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useContext, useRef } from 'react';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { InfiniteListLoader } from '../../../InfiniteLoader';
import { Loader } from '../../../Loader';
import { Sticky } from '../../../Sticky';
import { MembersContext } from '../MembersContext';
import { ListHeader } from './ListHeader';
import { Row } from './Row';

export const columnsWidths = {
  userName: 150,
  role: 100,
  office: 200,
  phone: 150,
  email: 150,
  accessDate: 100,
  closeDate: 100,
  action: 52
};

export const List = () => {
  const scrollElementRef = useRef();
  const {
    filter,
    workersList,
    loadNextPage,
    isFetched,
    isFetching
  } = useContext(MembersContext);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box>
        <Loader loading={!isFetched} p={2} render={
          () => !filter.total ? (
            <Box p={3}>
              <Typography align="center">No members found</Typography>
            </Box>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <ListHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={workersList}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
