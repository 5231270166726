import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Fab, Dialog } from '@material-ui/core';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import { uploadFiles } from '../../../../../store/dashboard/files/uploads';
import { ModalHeader, ModalBody, ModalContainer } from '../../../../../components/Modal';
import { Recorder } from './recorder';

export const RecordVoiceModal = ({ DialogProps, handleModalReject }) => {
  const [ isRecording, setIsRecording ] = useState(false);
  const dispatch = useDispatch();
  const mediaRecorder = useRef();
  const streamRef = useRef();

  const stop = () => {
    mediaRecorder.current.stop();
    streamRef.current.getAudioTracks().forEach((track) => track.stop());

    mediaRecorder.current.exportWAV(function (blob) {
      blob.original_filename = `audio-${moment().format()}.wav`;

      dispatch(uploadFiles({ files: [ blob ] }));
    });
  };

  const toggleRecording = () => {
    setIsRecording((isRecording) => {
      if (isRecording) {
        stop();
      }

      mediaRecorder.current.record();

      return !isRecording;
    });
  };

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      streamRef.current = stream;
      mediaRecorder.current = new Recorder(new AudioContext().createMediaStreamSource(stream));
    });
  }, []);

  return (
    <Dialog {...DialogProps}>
      <ModalContainer>
        <ModalHeader icon={<RecordVoiceOverIcon />} onClose={handleModalReject}>
          Record voice file
        </ModalHeader>

        <ModalBody>
          <Fab variant="extended" onClick={toggleRecording}>
            {isRecording ? 'Stop record' : 'Start record'}
          </Fab>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
