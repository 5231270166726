import { connect } from 'react-redux';
import { makeStyles, Tooltip } from '@material-ui/core';
import { PlayArrow, PauseCircleOutline } from '@material-ui/icons';
import { activityTimerStart, activityTimerStop } from '../../../../../store/dashboard/activityTimer';
import { Fab } from '../../../../../components/Fab';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const enhancer = connect(({ activityTimer }) => ({
  activeTimer: activityTimer.activeTimer
}), {
  activityTimerStart,
  activityTimerStop
});

export const PlayButton = enhancer(({
  task,
  activeTimer,
  activityTimerStart,
  activityTimerStop
}) => {
  const classes = useStyles();

  const isPlaying = activeTimer && (activeTimer.model === 'task' && activeTimer.model_id === task.id);

  return (
    <>
      {!isPlaying &&
        <Tooltip title="Start work">
          <Fab
            color="secondary"
            variant="extended"
            className={classes.icon}
            onClick={() => activityTimerStart({ model: 'task', model_id: task.id })}
            startIcon={<PlayArrow className={classes.icon__play} />}
          >
            Start
          </Fab>
        </Tooltip>
      }

      {isPlaying &&
        <Tooltip title="Stop work">
          <Fab
            color="secondary"
            variant="extended"
            className={classes.icon}
            startIcon={<PauseCircleOutline className={classes.icon__pause} />}
            onClick={() => activityTimerStop()}
          >
            Stop
          </Fab>
        </Tooltip>
      }
    </>
  );
});
