import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {
  returnWorkIndicatorsOptions
} from '../../../../../claims/SituationalNumberFormModal/SituationalNumberForm/data';
import { NestedList } from '../../../../../NestedList';

export const DiagnosticStudies = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const reportLabel = returnWorkIndicatorsOptions?.find(({ value }) => (
    value === report?.return_work_indicator
  ))?.label;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Diagnostic studies"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box p={3}>
        {!!report?.text &&
          <Box mb={3}>
            <Typography>
              {report?.text || 'No info'}
            </Typography>
          </Box>
        }

        {!!report?.icd?.length && report?.icd?.map((item, i) => (
          <Box
            key={i}
            display="flex"
            alignItems="center"
            mb={1}
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
          >
            <Typography>{i + 1}.</Typography>

            <Box color="info.main" mx={1}>
              <Typography>{item.code}</Typography>
            </Box>

            <Typography>- {item.description}</Typography>
          </Box>
        ))}

        <Grid container spacing={2} component={Box} py={2}>
          <Grid item sm={3} xs={12}>
            <Typography variant="h5">
              Disability
            </Typography>
          </Grid>

          <Grid item sm={9} xs={12}>
            <Typography color="textSecondary">
              {report?.disability || '-'}
            </Typography>
          </Grid>

          <Grid item sm={3} xs={12}>
            <Typography variant="h5">
              Return To Work Indicator
            </Typography>
          </Grid>

          <Grid item sm={9} xs={12}>
            <Typography color="textSecondary">
              {reportLabel || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </NestedList>
  );
};
