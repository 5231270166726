import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import * as discoveriesApi from '../../../../../../../../api/cases/discovery';
import EditIcon from '../../../../../../../../components/icons/square-edit-icone.svg';
import { Loader } from '../../../../../../../../components/Loader';
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContainer
} from '../../../../../../../../components/Modal';
import { DiscoveryForm, discoveryTypeOptions, validationSchema } from '../DiscoveryForm';
import { discoveryMemberTypesOptions } from '../DiscoveryForm/discoveryMemberTypes';
import { userTypeOptions } from '../DiscoveryForm/userTypeOptions';

export const EditDiscoveryModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    discovery,
    caseItem,
    onCaseItemUpdate
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateCase = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.from_type = values?.from_type?.value || values?.from_type;
    values.to_type = values?.to_type?.value || values?.to_type;

    return discoveriesApi.updateCaseDiscovery(caseItem.id, values).then((data) => {
      enqueueSnackbar('Case discovery successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case discovery not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...discovery,

        type: discoveryTypeOptions.find(({ value }) => value === discovery.type),
        user_type: userTypeOptions.find(({ value }) => value === discovery.user_type),
        to_type: discoveryMemberTypesOptions.find(({ value }) => value === discovery.to_type),
        from_type: discoveryMemberTypesOptions.find(({ value }) => value === discovery.from_type),
        to_id: discovery.to,
        from_id: discovery.from
      }}
      validationSchema={validationSchema}
      onSubmit={handleUpdateCase}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Edit Discovery
            </ModalHeader>

            <ModalBody>
              <DiscoveryForm caseItem={caseItem} onCaseItemUpdate={onCaseItemUpdate} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
