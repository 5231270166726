import { api } from '../api';

export const fetchExpensesCategories = (config = {}) => {
  return api.get('/expense-categories', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createExpensesCategory = (data) => {
  return api.post('/expense-categories', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateExpensesCategory = (data) => {
  return api.put(`/expense-categories/${data.id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteExpensesCategory = (id) => {
  return api.delete(`/expense-categories/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const activateExpensesCategory = (id) => {
  return api.post(`/expense-categories/${id}/activate`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deactivateExpensesCategory = (id) => {
  return api.delete(`/expense-categories/${id}/activate`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteExpensesCategories = (IDs) => {
  return api.delete('/expense-categories/delete', { params: { id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
