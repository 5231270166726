import { createContext, useEffect, useReducer, useRef } from 'react';
import { api } from '../../../../../api';
import * as usersApi from '../../../../../api/users';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const UserNotificationsContext = createContext();

export const UserNotificationsProvider = ({ children, userID }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const cancelFetch = useRef(() => {});
  const {
    userNotifications,
    pagination,
    isFetching,
    isFetched,
    filter
  } = state;

  const fetchUserNotifications = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.NOTIFICATIONS_FETCH_REQUEST });

    usersApi.fetchUserNotifications(userID, {
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.NOTIFICATIONS_FETCH_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchUserNotifications({ page: filter.page + 1 });
    }
  };

  const resetUserNotifications = (newFilter) => {
    dispatch({ type: types.RESET_NOTIFICATIONS, payload: newFilter });

    fetchUserNotifications({ page: 1, ...newFilter });
  };

  const providerValue = {
    userNotifications,
    pagination,
    isFetching,
    isFetched,
    filter: {
      ...filter,
      ...pagination
    },

    loadNextPage,
    fetchUserNotifications,
    resetUserNotifications
  };

  useEffect(() => {
    resetUserNotifications();
  }, [ userID ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <UserNotificationsContext.Provider value={providerValue}>
      {children}
    </UserNotificationsContext.Provider>
  );
};
