import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_VISIT_REASONS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_VISIT_REASONS_SUCCESS]: (
    { filter, visitReasons, ...state },
    { data: newArrivalStatuses, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      visitReasons: (page > 1 ?
        visitReasons.filter(({ id }) => {
          return !newArrivalStatuses.find((loadedFile) => id === loadedFile.id);
        }).concat(newArrivalStatuses)
        :
        newArrivalStatuses
      )
    };
  },

  [types.RESET_VISIT_REASONS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_VISIT_REASON]: (state, visitReason) => {
    return {
      ...state,

      visitReasons: state.visitReasons.map((item) => {
        return item.id === visitReason.id ? { ...item, ...visitReason } : item;
      })
    };
  },

  [types.DELETE_VISIT_REASON]: (state, visitReasonID) => {
    const { pagination, filter, visitReasons } = state;
    const filteredArrivalStatuses = visitReasons.filter(({ id }) => visitReasonID !== id);

    if (filteredArrivalStatuses.length === visitReasons.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((visitReasons.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      visitReasons: filteredArrivalStatuses
    };
  }
});
