import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import {
  VisitReasonsSelect,
  TextField,
  Autocomplete,
  OfficesLocationSelect
} from '../../../../../../components/FormField';
import { sidesOptions } from '../../../../../../components/appointments/procedures';
import { reportTypesOptions } from '../../../../../../components/medical/reports';
import { UsersSelect } from '../../../../../../components/users';
import { agesOptions } from './agesOptions';

export const Body = () => {
  const { values } = useFormikContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          disabled
          disableClearable
          name="report_type"
          label="Report form"
          options={reportTypesOptions}
          getOptionDisabled={(option) => option === reportTypesOptions[1]}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="name"
          label="Enter a name for the template"
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          required
          disableClearable
          name="age"
          label="Select age group"
          options={agesOptions}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <Autocomplete
          disableClearable
          name="side"
          label="Select a side"
          options={sidesOptions}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <VisitReasonsSelect name="visit_reason_id" label="Visit Reason"/>
      </Grid>

      <Grid item xs={12}>
        <UsersSelect
          required
          name="provider_id"
          label="Select a provider"
          params={{ role: 'doctor' }}
        />
      </Grid>

      <Grid item xs={12}>
        <OfficesLocationSelect
          required
          name="office_id"
          label="Select a office"
          params={{ user_id: values.provider_id }}
        />
      </Grid>
    </Grid>
  );
};
