import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { filterFieldsMap } from '../../filterFieldsMap';
import { columnsWidths } from '../List';

export const TableHeader = ({ hiddenColumns = [] }) => {
  return (
    <ListRow header>
      <ListRowCell noWrap flexBasis={columnsWidths.number}>
        Inv. number
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.users}>
        Users
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.companies}>
        Companies
      </ListRowCell>

      {!hiddenColumns.includes(filterFieldsMap.caseId) &&
        <ListRowCell noWrap flexBasis={columnsWidths.cases}>
          Case
        </ListRowCell>
      }

      <ListRowCell noWrap flexBasis={columnsWidths.appointments}>
        Appointment
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.claims}>
        Claim
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateFrom}>
        Date From
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateTo}>
        Date To
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.statuses}>
        Statuses
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.orderBy}>
        Order By
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
