export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },

  card: {
    width: '15%',
    minWidth: 160,
    minHeight: 100,

    '& + &': {
      marginLeft: spacing()
    }
  }
});
