import { CodesContextProvider } from './CodesContext';
import { MainContent } from './MainContent';

export const FinancialClass = ({ baseUrl }) => {
  return (
    <CodesContextProvider>
      <MainContent baseUrl={baseUrl} />
    </CodesContextProvider>
  );
};
