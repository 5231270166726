export const styles = ({ spacing, palette }) => ({
  content: {
    paddingTop: spacing(3),
    paddingBottom: spacing(2)
  },
  agreement: {
    marginTop: spacing(2),
    paddingTop: spacing(2),
    borderTop: `1px solid ${palette.divider}`
  }
});
