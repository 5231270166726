import { forwardRef } from 'react';
import T from 'prop-types';
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';
import { useFormikField } from '../../useFormikField';

const propTypes = {
  name: T.string.isRequired,
  options: T.arrayOf(T.shape({
    value: T.oneOfType([ T.string, T.number, T.bool ]),
    label: T.node
  })),
  InputLabelProps: T.object,
  SelectProps: T.object,
  FormHelperTextProps: T.object
};

export const SimpleSelect = forwardRef(({
  withoutFormik,
  isEditable,
  fullWidth = true,
  options = [],
  label,
  name,
  error: errorProp,
  InputLabelProps = {},
  value,
  onChange = () => {},
  SelectProps = {},
  FormHelperTextProps: {
    children: helperTextProp,
    ...FormHelperTextProps
  } = {},
  ...FormControlProps
}, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? { fieldProps: [] } : useFormikField(name);
  const { isFormikField, fieldProps: [ field = {}, { touched } = {} ], error } = formikFieldProps;
  const helperText = (touched && error) || errorProp || helperTextProp;

  const handleChange = (event) => {
    onChange(event);
    isFormikField && field.onChange(event);
  };

  return (
    <FormControl
      ref={ref}
      error={!!(touched && error) || errorProp}
      fullWidth={fullWidth}
      {...FormControlProps}
    >
      <InputLabel {...InputLabelProps}>{label}</InputLabel>

      <MuiSelect
        {...field}
        name={name}
        value={value || field.value || ''}
        onChange={handleChange}
        {...SelectProps}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </MuiSelect>

      {helperText &&
        <FormHelperText {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      }
    </FormControl>
  );
});

SimpleSelect.propTypes = propTypes;
