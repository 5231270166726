import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { Date } from '../../../../Date';
import { Divider } from '../../../../Divider';

export const WorkInfo = ({ claim }) => {
  const data = claim?.situational_number;

  return (
    <>
      <Grid container spacing={2} component={Box} px={2} pt={3}>
        <Grid item xs={12}>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">
              Work Comp
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="body2" color="textSecondary">
            Disability
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="subtitle2">
            {data?.disability || '-'}
          </Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography variant="body2" color="textSecondary">
            Last Work Date
          </Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Date
            disableMoment
            variant="subtitle2"
            iconFontSize="small"
            date={data?.last_work_date && moment(data?.last_work_date).format('L')}
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography variant="body2" color="textSecondary">
            Return To Work Indicator
          </Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography variant="subtitle2">
            {data?.return_work_indicator?.label || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Divider gutter={3} />

      <Grid container spacing={2} component={Box} px={2}>
        <Grid item xs={12}>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">
              Unable to Work
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography variant="body2" color="textSecondary">From</Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Date
            disableMoment
            variant="subtitle2"
            iconFontSize="small"
            date={data?.unable_work_from && moment(data?.unable_work_from).format('L')}
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography variant="body2" color="textSecondary">To</Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Date
            disableMoment
            variant="subtitle2"
            iconFontSize="small"
            date={data?.unable_work_to && moment(data?.unable_work_to).format('L')}
          />
        </Grid>
      </Grid>

      <Divider gutter={3} />

      <Grid container spacing={2} component={Box} px={2} pb={3}>
        <Grid item xs={12}>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">
              Situational Number
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">Mammography Certification</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle2">
            {data?.mammography_certification || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">Investigational Device Exception</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle2">
            {data?.investigation_device_exception || '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
