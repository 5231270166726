import { memo } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { ChatWidget } from '../../../../components/chat';
import { chatWidgetMinWidth } from '../../../../components/chat/ChatWidget/Chat/styles';

export const ChatWidgetComponent = memo(({ onChatWidgetClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  const MobileChatWidgetWrapper = ({ children }) => !isMobile ? children : (
    <Box position="fixed" right={0} top={0} bottom={0} width={chatWidgetMinWidth} zIndex={100000}>
      {children}
    </Box>
  );

  const MobileOverlayWrapper = ({ children }) => {
    const theme = useTheme();

    return !isMobile ? children : (
      <Box
        position="fixed"
        left="0"
        right="0"
        top="0"
        zIndex={theme.zIndex.drawer}
        width="100vw"
        height="100vh"
        bgcolor="rgba(0,0,0,0.2)"
      >
        {children}
      </Box>
    );
  };

  return (
    <MobileOverlayWrapper>
      <MobileChatWidgetWrapper>
        <Box height="100%" position="relative" flexShrink={0} width={chatWidgetMinWidth}>
          <ChatWidget onChatWidgetClose={onChatWidgetClose} />
        </Box>
      </MobileChatWidgetWrapper>
    </MobileOverlayWrapper>
  );
});
