import { useContext, useEffect, useRef } from 'react';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { Page, PageBody, PageHeader } from '../../../../../components/Page';
import { MainFiltersBar } from '../MainFiltersBar';
import { ActionsBar } from '../ActionsBar';
import { Grid } from '../Grid';
import { List } from '../List';

export const BREAKPOINT_WIDTH = 1100;

export const PageContent = ({ filterKey }) => {
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('lg');

  useEffect(() => {
    if (width < BREAKPOINT_WIDTH) {
      setViewVariant(viewVariantsMap.grid);
    } else {
      setViewVariant(viewVariantsMap.list);
    }
  }, [ width ]);

  return (
    <Page ref={rootRef}>
      <PageHeader>
        <ActionsBar width={width} filterOpenButton={filterOpenButton} />
      </PageHeader>

      <ScrollWrapper {...scrollWrapperProps}>
        <FilterBarWrapper {...filterBarWrapperProps}>
          <MainFiltersBar filterKey={filterKey} />
        </FilterBarWrapper>
      </ScrollWrapper>

      <PageBody fullHeight disablePaddings disableScrollbars>
        {viewVariant === viewVariantsMap.list && <List />}
        {viewVariant === viewVariantsMap.grid && <Grid />}
      </PageBody>
    </Page>
  );
};
