export const styles = ({ spacing, palette, typography: { fontWeightMedium } }) => ({
  experienceControl: {
    width: 320
  },

  checkboxSpacing: {
    marginTop: spacing(2)
  },

  noteIcon: {
    display: 'flex',
    alignItems:'center',
    fontWeight: fontWeightMedium,
    color: palette.grey[600]
  }
});
