import { useContext } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { formatDateForChat, MessageContext } from '../../../../../../../components/chat';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChatDivider = () => {
  const classes = useStyles();
  const {
    dense,
    message,
    isDividerShown
  } = useContext(MessageContext);

  return isDividerShown && (
    <div className={classes.root}>
      <Box p={dense ? 1.5 : 3}>
        <Typography variant="body1">
          {formatDateForChat(message?.created_at, true)}
        </Typography>
      </Box>
    </div>
  );
};
