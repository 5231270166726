import { Box } from '@material-ui/core';
import { unixToEndOfDayUnix, unixToStartOfDayUnix } from '../../../../../../../../../helpers/dates';
import { ApptTypesSelect } from '../../../../../../../../../components/FormField';
import { DatePicker } from '../../../../../../../../../components/FormField';

export const Filters = ({ resetHistory, setWidgetFilters }) => {
  const handleDatePickerChange = (name, transformer) => (date) => {
    resetHistory({ [name]: transformer?.(date) || date });
    setWidgetFilters((state) => ({ ...state, [name]: date }));
  };

  const handleApptTypeChange = (apptType) => {
    resetHistory({ appointment_type_id: apptType?.id });
    setWidgetFilters((state) => ({ ...state, appointment_type_id: apptType }));
  };

  return ([
    <Box minWidth={160}>
      <DatePicker
        clearable
        name="appointment_from"
        label="Appt. From"
        onChange={handleDatePickerChange('appointment_from', unixToStartOfDayUnix)}
      />
    </Box>,

    <Box minWidth={160}>
      <DatePicker
        clearable
        name="appointment_to"
        label="Appt. To"
        onChange={handleDatePickerChange('appointment_to', unixToEndOfDayUnix)}
      />
    </Box>,

    <ApptTypesSelect
      name="appointment_type_id"
      onChange={handleApptTypeChange}
    />
  ]);
};
