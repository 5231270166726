import { api } from '../../api';

export const fetchICDCodes = (config) => {
  return api.get('/procedure-codes/icd-codes', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteICDCode = (id) => {
  return api.delete(`/procedure-codes/icd-codes/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchICDTypes = (config) => {
  return api.get('/procedure-codes/icd-types', config)
    .then((data) => data)
    .catch((error) => { throw error.data; });
};

export const importICDCode = (data) => {
  return api.post('/procedure-codes/icd-codes/import', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
