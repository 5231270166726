export const filterFieldsMap = {
  name: 'name',
  date: 'date',
  status: 'status'
};

export const filterFieldsLabels = {
  [filterFieldsMap.name]: 'File name',
  [filterFieldsMap.date]: 'Date',
  [filterFieldsMap.status]: 'Status'
};
