import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SvgIcon
} from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { mdiTrashCanOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as types from '../../../app/Dashboard/files-common/FilesContext/types';
import * as filesApi from '../../../api/files';
import { setFilesLastGlobalAction } from '../../../store/globalActions';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';

export const AttachmentsMenu = ({ file, openFilesPreview, onClose }) => {
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const handleDeleteFile = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        filesApi.deleteFiles([ file.id ]).then(() => {
          dispatchRedux(setFilesLastGlobalAction({ type: types.DELETE_FILES, payload: [ file.id ] }));
          enqueueSnackbar('File successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={flow(openFilesPreview, onClose)}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Preview" />
      </MenuItem>

      <Divider />

      <MenuItem onClick={handleDeleteFile}>
        <ListItemIcon>
          <SvgIcon color="error" fontSize="inherit">
            <Icon path={mdiTrashCanOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
