import { useContext } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { hasRole } from '../../../../../../utils/hasRole';
import { columnsWidths } from '../List';
import { NotesContext } from '../../NotesProvider';

export const Header = () => {
  const { selectedIDs, toggleAllItemsSelection, allItemsIsSelected } = useContext(NotesContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        {!hasRole('client', 'patient') &&
          <ListRowCheckbox
            checked={allItemsIsSelected()}
            indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
            onClick={toggleAllItemsSelection}
          />
        }

        <ListRowCell grow flexBasis={columnsWidths.name}>
          Clinical Notes
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.doi}>
          DOI
        </ListRowCell>

        <ListRowCell column flexBasis={columnsWidths.dor}>
          <Typography variant="h5">DOR</Typography>
          <Typography variant="h5">(Date of Report)</Typography>
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.provider}>
          Provider
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.office}>
          Office
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
