import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Contact } from '../../../../../../../components/Contact';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../components/Loader';
import { columnsWidths } from '../SavedFiltersList';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap>
          {params?.patient_name || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.email}>
        <Contact
          noWrap
          variant="h5"
          type="mailto"
          contact={params?.patient_email}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.phone}>
        <Contact
          noWrap
          variant="h5"
          type="tel"
          contact={params?.patient_phone}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.address}>
        <Typography noWrap>
          {params?.patient_address || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.doi}>
        {params?.doi ? moment(params?.doi).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
