import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          File name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.date}>
          Date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.status}>
          Status
        </ListRowCell>

        {/*<ListRowCell grow flexBasis={columnsWidths.actions} />*/}
      </ListRow>
    </Paper>
  );
};
