import { useRef, useContext, useEffect } from 'react';
import { makeStyles, Typography, List as MuiList, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../components/Loader';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { TasksContext, TasksFilterContext } from '../../../../../../../components/tasks/TasksProvider';
import * as types from '../../../../../../../components/tasks/TasksProvider/types';
import { setTasksLastGlobalAction } from '../../../../../../../store/globalActions';
import { CreateTaskModal } from '../../../../CreateTaskModal';
import { Card } from './Card';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 280;

export const Column = ({ status }) => {
  const {
    tasks,
    isFetching,
    isFetched,
    loadNextPage,
    filter,
    resetTasks,
    fetchTasks
  } = useContext(TasksContext);
  const { openModal } = useModal();
  const classes = useStyles();
  const scrollElementRef = useRef();
  const dispatchRedux = useDispatch();
  const { updateTaskPosition } = useContext(TasksFilterContext);

  const addTask = () => {
    openModal(CreateTaskModal, {
      payload: {
        initialValues: { status },
        onUpdatePosition: updateTaskPosition
      },
      onModalResolved: () => {
        fetchTasks();
      }
    });
  };

  useEffect(() => {
    resetTasks();
  }, []);

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const item = tasks[oldIndex];

      dispatchRedux(setTasksLastGlobalAction({
        type: types.UPDATE_TASK_POSITION_SUCCESS,
        payload: { oldIndex, newIndex }
      }));
      updateTaskPosition({ id: item.id, newIndex, status: status.id });
    }
  };

  return (
    <>
      <div ref={scrollElementRef} className={classes.content}>
        <Loader loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No tasks found</Typography>
          ) : (
            <MuiList disablePadding>
              <InfiniteListLoader
                isSortable
                lockAxis="y"
                items={tasks}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Card}
                onSortEnd={handleSortEnd}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
                minRowHeight={minRowHeight}
              />
            </MuiList>
          )}
        />
      </div>

      <div className={classes.content__action}>
        <div className={classes.content__shadow} />

        <Button
          fullWidth
          className={classes.creationButton}
          onClick={addTask}
        >
          <AddIcon className={classes.creationButton__icon}/> Add new
        </Button>
      </div>
    </>
  );
};
