import { CasesSelect } from '../cases/CasesSelect';
import { CreatableSelect, EmailsSelect } from '../FormField';
import { UsersSelect } from '../users';

export const transformAccountsToOptions = (accounts) => {
  return accounts?.map((account) => ({
    value: account.id,
    label: account.username
  }));
};

export const conditionOptions = [
  { value: 'from', label: 'From' },
  { value: 'to', label: 'To' },
  { value: 'to_line', label: 'I\'m on the To line' },
  { value: 'cc_line', label: 'I\'m on the Cc line' },
  { value: 'to_cc_line', label: 'I\'m on the To or Cc line' },
  { value: 'not_to_line', label: 'I\'m not on the To line' },
  { value: 'recipient', label: 'I\'m the only recipient' },
  { value: 'subject', label: 'Subject includes' },
  { value: 'subject_body', label: 'Subject or body includes' },
  { value: 'flag', label: 'Flag' },
  { value: 'has_attachment', label: 'Has attachment' },
  { value: 'all', label: 'Apply to all messages' }
];

export const actionOptions = [
  { label: 'Attach to case', value: 'attach_case' },
  { label: 'Attach to patient', value: 'attach_patient' },
  { label: 'Delete', value: 'delete' },
  { label: 'Mark as read', value: 'mark_read' },
  { label: 'Mark with flag', value: 'mark_flag' }
];

export const conditionToFieldMap = {
  from: (props) => (
    <EmailsSelect
      isCreatable
      multiple
      label="Suggested contacts"
      placeholder="Enter suggested contacts..."
      TextFieldProps={{ required: true }}
      {...props}
    />
  ),
  to: (props) => (
    <EmailsSelect
      isCreatable
      multiple
      label="Suggested contacts"
      placeholder="Enter suggested contacts..."
      TextFieldProps={{ required: true }}
      {...props}
    />
  ),
  subject: (props) => (
    <CreatableSelect
      placeholder="Enter words to look for..."
      label="Enter words to look for"
      {...props}
    />
  ),
  subject_body: (props) => (
    <CreatableSelect
      placeholder="Enter words to look for..."
      label="Enter words to look for"
      {...props}
    />
  )
};

export const actionToFieldMap = {
  attach_case: (props) => (
    <CasesSelect
      required
      label="Select cases"
      placeholder="Select cases..."

      {...props}
    />
  ),
  attach_patient: (props) => (
    <UsersSelect
      required
      label="Select patient"
      placeholder="Select patient..."
      params={{ is_patient: 1 }}

      {...props}
    />
  )
};

const descriptionMap = {
  condition: {
    from: 'If the message was received from',
    to: 'If the message was sent to',
    to_line: 'If my name is in the To box',
    cc_line: 'If my name is in the Cc box',
    to_cc_line: 'If my name is in the To or Cc box',
    not_to_line: 'If my name is not in the To box',
    recipient: 'If the message was sent only to me',
    subject: 'If the message includes specific words in the subject',
    subject_body: 'If the message includes specific words in the subject or body',
    flag: 'If the message is flagged',
    has_attachment: 'If the message has an attachment',
    all: 'If a message arrives in my inbox'
  },

  action: {
    attach_case: 'attach the message to case',
    attach_patient: 'attach the message to patient',
    delete: 'delete the message',
    mark_read: 'mark the message as read',
    mark_trash: 'move the message to "Trash" folder',
    mark_flag: 'mark the message as flagged'
  }
};

export const transformOptions = (options = [], multiple = false) => options.map((option) => ({
  key: option.key.key,
  ...(multiple && option.values && option.values.length
    ? { values: [].concat(option.values) }
    : option.value ? { value: option.value?.id || option.value } : {}
  )
}));

export const renderOptionField = (map, key, fieldProps) => {
  const field = map[key];

  return field ? field(fieldProps) : null;
};

const arrayToString = (array, separator = ', ', wrapper = '\'') => {
  if (!Array.isArray(array)) {
    return '';
  }

  return array.reduce((accumulator, currentValue, index) => {
    const value = currentValue.label || currentValue;
    const end = index + 1 !== array.length ? separator : '';

    return `${accumulator}${wrapper}${value}${wrapper}${end}`;
  }, '');
};

export const generateDescription = (options = [], type, separator = ', ', useAndSeparator = true) => {
  let description = '';

  options.forEach((option, index, array) => {
    const values = option.label || (option.values ? arrayToString(option.values) : null);
    const valueResult = values ? ` ${values}` : '';
    let end = separator;

    if (index === array.length - 2 && useAndSeparator) {
      end = ' and ';
    } else if (index + 1 === array.length) {
      end = '';
    }

    description += `${descriptionMap[type][option.key]}${valueResult}${end}`;
  });

  return description;
};
