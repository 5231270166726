import { Box, makeStyles, Typography } from '@material-ui/core';
import { VerticalDivider } from '../../../VerticalDivider';
import { UserAvatar } from '../../../users';
import { Date } from '../../../Date';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    font: 'inherit',
    lineHeight: 1.5,
    flexWrap: ({ wrap }) => wrap ? 'wrap' : null
  }
});

const useStyles = makeStyles(styles);

export const SingleValue = ({ wrap = false, children: form }) => {
  const classes = useStyles({ wrap });

  return (
    <div className={classes.root}>
      <Typography noWrap>{form.abbreviated_name || form.form_type}</Typography>

      {!!form.abbreviated_name && (
        <>
          <VerticalDivider horizontalGutters={1} />
          <Typography variant="caption" color="textSecondary">Follow Up:</Typography>
          &nbsp;
          <Box color="info.main">
            <Typography color="inherit">{form?.children?.length}</Typography>
          </Box>
        </>
      )}

      <VerticalDivider horizontalGutters={1} />

      <Box mr={0.5}>
        <Typography variant="caption" color="textSecondary">Date:</Typography>
      </Box>

      <Date noWrap iconFontSize="small" date={form.created_at} format="L, LT" />

      {form?.treating_physician?.id && (
        <>
          <VerticalDivider horizontalGutters={1} />

          <Box mr={0.5}>
            <Typography variant="caption" color="textSecondary">Phys.:</Typography>
          </Box>

          <UserAvatar size="xs" user={form?.treating_physician} />
        </>
      )}
    </div>
  );
};
