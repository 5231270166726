import { useContext } from 'react';
import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../components/ListRow';
import { FaxContext } from '../../FaxProvider';
import { columnsWidths } from '../List';

const nameMap = {
  income: 'Received Date',
  outcome: 'Sent Date'
};

export const TableHeader = () => {
  const {
    filter,
    selectedFaxesIDs,
    allFaxesIsSelected,
    toggleAllFaxesSelected
  } = useContext(FaxContext);

  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCheckbox
          checked={allFaxesIsSelected()}
          indeterminate={!!selectedFaxesIDs.length && !allFaxesIsSelected()}
          onClick={toggleAllFaxesSelected}
        />

        <ListRowCell flexBasis={columnsWidths.sentDate}>
          {nameMap[filter.destination] || nameMap.income}
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.user}>
          User
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.from}>
          From
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.to}>
          To
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.file}>
          File Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.pages}>
          Pages
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.statusInfo}>
          Last Changes
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.status}>
          Status
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.view}>
          Result
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
