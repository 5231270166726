import { useContext, useEffect, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../components/IconButton';
import { Fab } from '../../../../../../../components/Fab';
import { MedicalProfileContext } from '../../MedicalProfileProvider';
import { styles } from '../PatientInfo/styles';
import { UpdateAllergiesModal } from './UpdateAllergiesModal';

const useStyles = makeStyles(styles);

export const Allergies = () => {
  const classes = useStyles();
  const { isFetched, medicalProfile } = useContext(MedicalProfileContext);
  const [ initialValues, setInitialValues ] = useState([]);
  const [ isOpen, setIsOpen ] = useState(true);
  const { openModal } = useModal();

  const toggleIsOpen = () => {
    setIsOpen((state) => !state);
  };

  const openWidgetModal = () => {
    openModal(UpdateAllergiesModal, {
      payload: {
        allergies: initialValues,
        medicalID: medicalProfile.id
      },
      onModalResolved: (data) => {
        setInitialValues(data);
      }
    });
  };

  useEffect(() => {
    isFetched && setInitialValues(medicalProfile.allergies || []);
  }, [ medicalProfile ]);

  return (
    <ContentCard
      className={classes.root}
      autoHeightMax={800}
      isCollapsed={!isOpen}
      title="Allergies"
      icon={
        <Fab
          size="small"
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={openWidgetModal}
        >
          Add
        </Fab>
      }
      rightActions={[
        <IconButton color="inherit" onClick={openWidgetModal}>
          <EditIcon fontSize="small" />
        </IconButton>,

        <IconButton color="inherit" onClick={toggleIsOpen}>
          {isOpen ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        </IconButton>
      ]}
    >
      {!!initialValues.length ?
        initialValues?.map((item, i) => (
          <Box key={i} mb={1}>
            <Typography>{i + 1}. {item}</Typography>
          </Box>
        ))
        :
        <Typography>No allergies found</Typography>
      }
    </ContentCard>
  );
};
