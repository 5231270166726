export const styles = ({
  spacing,
  palette,
  typography: { pxToRem, fontWeightMedium },
  breakpoints
}) => ({
  header: {
    display: 'flex',
    alignItems: 'stretch',
    borderWidth: 2,
    borderColor: palette.grey[100],
    padding: spacing(),
    minHeight: 116,

    [breakpoints.up('md')]: {
      borderWidth: 4,
      padding: spacing(1, 2)
    }
  },

  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    paddingLeft: spacing(2)
  },

  mainInfo__date: {
    display: 'flex',
    alignItems: 'flex-start'
  },

  mainInfo__title: {
    fontSize: pxToRem(18),
    fontWeight: fontWeightMedium,

    [breakpoints.up('sm')]: {
      fontSize: pxToRem(30),
      fontWeight: 'normal'
    }
  },

  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    flexGrow: 0
  },

  userName: {
    minHeight: 32
  },

  doiDate: {
    minWidth: 0,
    width: 120,
    marginLeft: spacing(2)
  }
});
