import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormControlLabel, useMediaQuery, Dialog } from '@material-ui/core';
import * as insuranceApi from '../../../../../api/profile/insurances';
import { Radio, RadioGroup } from '../../../../../components/FormField';
import { ModalBody, ModalContainer, ModalHeader } from '../../../../../components/Modal';
import { getFormData } from '../AddInsuranceInfoModal';
import { AddInsuranceForm } from '../AddInsuranceInfoModal/AddInsuranceForm';
import { getInitialValues } from '../AddInsuranceInfoModal/getInitialValues';
import { ExistingInsurance } from './ExistingInsurance';

export const insuranceTypesCreationMap = {
  new: 'new',
  exist: 'exist'
};

export const CaseInsuranceModal = ({
  DialogProps,
  payload: {
    caseItem = null,
    user = null,
    insuranceInfo = {},
    status_type = '',
    disableScan = false,
    type_insurance = null,
    medicalFormID = null,
    medical_id = null,
    onCaseItemUpdate = () => {},
    onEdit = () => {}
  },
  handleModalReject,
  handleModalResolve
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ insuranceType, setInsuranceType ] = useState(insuranceTypesCreationMap.exist);
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleChange = (event) => {
    setInsuranceType(event.target.value);
  };

  const addInsurance = ({ caseItem = null, ...values }, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    if (values.subscriber_info.gender) {
      values.subscriber_info.gender = values.subscriber_info?.gender?.value || values.subscriber_info?.gender || null;
    }

    values.insuranceInfo = null;
    values.insurance_company_id = values?.insurance_company_id?.id || values?.insurance_company_id;

    if (values?.case_user_id?.user?.id || values?.case_user_id?.id) {
      values.case_user_id = values?.case_user_id?.user?.id || values?.case_user_id?.id || null;
    }

    const formData = getFormData(values);

    return insuranceApi.createInsurance(formData).then((data) => {
      enqueueSnackbar('Insurance info added successfully', { variant: 'success' });
      onEdit(data);
      handleModalResolve(data);
    }).catch(() => {
      enqueueSnackbar('Insurance info not added', { variant: 'error' });
    });
  };

  return (
    <Dialog maxWidth="md" fullScreen={fullScreen} {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          {insuranceType === insuranceTypesCreationMap.exist ? 'Existing Insurance' : 'New Insurance'}
        </ModalHeader>

        <ModalBody>
          <RadioGroup withoutFormik name="insurance_type" value={insuranceType} onChange={handleChange}>
            <FormControlLabel
              value={insuranceTypesCreationMap.exist}
              label="Choose from existing insurances"
              control={
                <Radio
                  checked={insuranceType === insuranceTypesCreationMap.exist}
                  name="appt_type"
                  color="primary"
                />
              }
            />

            <FormControlLabel
              value={insuranceTypesCreationMap.new}
              label="Create a new insurance"
              control={
                <Radio
                  checked={insuranceType === insuranceTypesCreationMap.new}
                  name="appt_type"
                  color="primary"
                />
              }
            />
          </RadioGroup>

          {insuranceType === insuranceTypesCreationMap.exist && (
            <ExistingInsurance
              caseItem={caseItem}
              onCaseItemUpdate={onCaseItemUpdate}
              onModalResolve={handleModalResolve}
              onClose={handleModalReject}
            />
          )}

          {insuranceType === insuranceTypesCreationMap.new && (
            <AddInsuranceForm
              disableScrollbars
              disableScan={disableScan}
              initialValues={
                getInitialValues({
                  user,
                  medical_id,
                  status_type,
                  insuranceInfo,
                  medicalFormID,
                  type_insurance,
                  caseItem
                })
              }
              user={user}
              insuranceInfo={insuranceInfo}
              addInsurance={addInsurance}
              onClose={handleModalReject}
              onCaseItemUpdate={onCaseItemUpdate}
            />
          )}
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
