import moment from 'moment';
import { useContext } from 'react';
import { ColorPreview } from '../../../../../../../../../components/ColorPreview';
import { Checkbox } from '../../../../../../../../../components/FormField';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { AppointmentTypesContext } from '../../../../../AppointmentTypes/AppointmentTypesContext';
import { minRowHeight, columnsWidths } from '../List';

export const Row = ({ appointmentType }) => {
  const appointmentTypesContext = useContext(AppointmentTypesContext);

  const toggleCodeSelected = () => {
    appointmentTypesContext.toggleCodeSelected(appointmentType);
  };

  return (
    <ListRow disabled={!appointmentType.is_active} minHeight={minRowHeight}>
      <ListRowCell flexBasis={columnsWidths.checkbox}>
        <Checkbox
          withoutFormik
          checked={appointmentTypesContext.selectedCodesIDs.includes(appointmentType.id)}
          onChange={toggleCodeSelected}
        />
      </ListRowCell>

      <ListRowCell grow actions flexBasis={columnsWidths.code}>
        <ColorPreview color={appointmentType.color} />

        <span>
          {appointmentType.code}
        </span>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.description}>
        {appointmentType.description}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.duration}>
        {moment.duration(appointmentType.time, 'seconds').format('h[h] m[min]')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.claimRequired}>
        {appointmentType.claim_required ? 'Yes' : 'No'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.ordering}>
        {appointmentType.ordering}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.apptBooks}>
        -
      </ListRowCell>
    </ListRow>
  );
};
