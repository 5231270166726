import { useContext } from 'react';
import { invoiceBlocksMap } from '../../../../../../../components/billing';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { CaseViewModal } from '../../../../../CasesPage/Cases/CaseViewModal';
import { EditIncomeModal } from '../../EditIncomeModal';
import { IncomesContext } from '../../IncomesContext';

export const IncomeContainer = ({
  parentPage,
  income,
  index,
  width,
  invoice,
  incomes,
  onIncomesUpdate,
  onUpdateMainTotal = () => {},
  IncomeComponent
}) => {
  const { openModal } = useModal();
  const incomesContext = useContext(IncomesContext);

  const onDeleteIncome = () => {
    if (incomesContext?.deleteIncome) {
      incomesContext.deleteIncome(income.id);
    } else {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          onIncomesUpdate(incomes.filter((item, i) => i !== index));
          onUpdateMainTotal(income, invoiceBlocksMap.invoices);
        }
      });
    }
  };

  const openCasePreview = () => {
    if (income?.model_id) {
      openModal(CaseViewModal, {
        payload: { id: income?.model_id }
      });
    }
  };

  const onUpdateIncome = () => {
    openModal(EditIncomeModal, {
      payload: {
        parentPage,
        invoiceID: invoice?.id,
        model: invoice?.model,
        income,
        incomes
      },
      onModalResolved: (data) => {
        onIncomesUpdate(incomes.map((item) => item.id === data?.id ? data : item));
        incomesContext?.fetchIncomes(incomesContext.filter);
      }
    });
  };

  return (
    <IncomeComponent
      parentPage={parentPage}
      width={width}
      income={income}
      index={index}
      openCasePreview={openCasePreview}
      handleUpdateIncome={onUpdateIncome}
      handleDeleteIncome={onDeleteIncome}
    />
  );
};
