import { Icon } from '@mdi/react';
import { mdiAccountEditOutline } from '@mdi/js';
import { MenuItem, ListItemText, SvgIcon } from '@material-ui/core';
import { expensesCategoryMap } from '../expensesTypes';
import { IconLabel } from '../../IconLabel';

export const Option = ({ children, data, ...props }) => {
  const categoryName = children?.toLowerCase();

  return (
    <MenuItem
      buttonRef={props.innerRef}

      {...props.innerProps}
    >
      <ListItemText
        primary={
          expensesCategoryMap[categoryName] ?
            <IconLabel
              label={expensesCategoryMap[categoryName].name}
              color={expensesCategoryMap[categoryName].color}
              icon={expensesCategoryMap[categoryName].icon}
              variant="outlined"
            />
            :
            <IconLabel
              label={children}
              icon={
                expensesCategoryMap[data.icon] ?
                  expensesCategoryMap[data.icon].icon
                  :
                  <SvgIcon><Icon path={mdiAccountEditOutline} /></SvgIcon>}
              color={data.icon && expensesCategoryMap[data.icon] && expensesCategoryMap[data.icon].color}
              variant="outlined"
            />
        }
        primaryTypographyProps={{ noWrap: true, component: 'div' }}
      />
    </MenuItem>
  );
};
