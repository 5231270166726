import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiFilePlusOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as situationalNumbersApi from '../../../api/claims/situational-numbers';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { Loader } from '../../Loader';
import { SituationalNumberForm, validationSchema, initialValues } from './SituationalNumberForm';

export const SituationalNumberFormModal = ({
  payload: {
    initialValues: initialValuesProp
  },
  DialogProps,
  handleModalReject,
  handleModalResolve
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const createSituationalNumber = (values, { setErrors }) => {
    return situationalNumbersApi.createSituationalNumber(values).then((data) => {
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }

      enqueueSnackbar('Situational number not created', { variant: 'error' });
    });
  };

  const updateSituationalNumber = (values, { setErrors }) => {
    return situationalNumbersApi.updateSituationalNumber(values).then((data) => {
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }

      enqueueSnackbar('Situational number not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{ ...initialValues, ...initialValuesProp }}
      validationSchema={validationSchema}
      onSubmit={initialValuesProp?.id ? updateSituationalNumber : createSituationalNumber}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiFilePlusOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              {initialValuesProp?.id ? 'Edit' : 'Add'} Situational Number
            </ModalHeader>

            <ModalBody disablePaddings>
              <SituationalNumberForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {initialValuesProp?.id ? 'Save' : 'Create'}
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
