import { useContext } from 'react';
import { Switch, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { makeStyles, Box, useMediaQuery, useTheme, IconButton, Paper } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { LayoutContextProvider, viewVariantsMap } from '../../../../../components/LayoutContext';
import { rolesMap as caseRolesMap } from '../../../../../dataMaps';
import { setCasesLastGlobalAction } from '../../../../../store/globalActions';
import { filtersKeysMap } from '../../../../../store/lastFilters';
// TODO: hide for future settings
// import TransferIcon } from '../../../../components/icons/transfer.svg';
import { hasRole } from '../../../../../utils/hasRole';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { CommentsContextProvider, Comments } from '../../../../../components/Comments';
import * as taskActionTypes from '../../../../../components/tasks/TasksProvider/types';
import { ActivityProvider } from '../../../../../components/Activities';
import { TasksWidget } from '../../../../../components/tasks';
import { Loader } from '../../../../../components/Loader';
import { Route } from '../../../../../components/router';
import { CaseReminders } from '../../../../../components/cases/reminders/case-reminders';
import { Claims } from '../../../BillingPage/ClaimsPage/Claims';
import { filterFieldsMap as expensesFilterFieldsMap } from '../../../BillingPage/Invoices/InvoicesPage/ExpensesContent';
import { filterFieldsMap as invoicesFilterFieldsMap } from '../../../BillingPage/Invoices/InvoicesPage/ExpensesContent';
import { CaseActivity } from '../CaseActivity';
import { Appointments } from './Appointments';
import { CallReports } from './CallReports';
import { CaseItemContext } from './CaseItemProvider';
import { CaseNavigation } from './CaseNavigation';
import { claimsMobileHiddenColumns } from './claimsHiddenColumns';
import { Discovery } from './Widgets/Discovery';
import { CaseDiscoveriesProvider } from './Widgets/Discovery/CaseDiscoveriesProvider';
import { Litigation } from './Widgets/Litigation';
import { Negotiations, NegotiationsProvider } from './Widgets/Negotiations';
import { fieldsKeysMap } from './Widgets/TimeTracking/filterKeysMap';
import { Item } from './Item';
import { Schedule } from './Schedule';
import { TimeTracking } from './Widgets';
import { Contacts } from './Contacts';
import { CaseInfo } from './CaseInfo';
import { Billing } from './TabsContent';
import { Files } from './Files';
import { Emails } from './Emails';
import { routes } from './routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const hiddenInvoicesFilterFields = [ invoicesFilterFieldsMap.caseId ];
const hiddenExpensesFilterFields = [ expensesFilterFieldsMap.caseId ];

export const CaseItem = () => {
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams();
  const {
    isFetched,
    isCaseInfoOpen,
    disableTimeTracks,
    caseItem,
    updateCase,
    toggleCaseInfoOpen
  } = useContext(CaseItemContext);
  const dispatchRedux = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const {
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps,
    toggleFiltersBar
  } = useMobileFilterCollapse();

  const initialStateOfComments = {
    filter: {
      parent_type: 'case',
      parent_id: +params.caseID
    }
  };

  const initialTimeTrackingFilter = {
    model: 'case',
    model_id: caseItem.id
  };

  const initialModalValues = {
    time_track_model: 'case',
    time_track_model_id: caseItem.id
  };

  const hiddenFilterFields = [ fieldsKeysMap.cases ];

  const onCommentAdded = (comments) => {
    dispatchRedux(setCasesLastGlobalAction({
      type: taskActionTypes.UPDATE_TASK_IN_LIST,
      payload: { ...caseItem, comments_count: comments.length }
    }));
  };

  return (
    <div className={classes.root}>
      <Loader loading={!isFetched} className={classes.loader} render={
        () => (
          <>
            {isMobile &&
              <div className={classes.navigation}>
                <Box mx={2.25} mt={1}>
                  <IconButton size="medium" onClick={toggleFiltersBar}>
                    <ListIcon />
                  </IconButton>
                </Box>
              </div>
            }

            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                <CaseNavigation handleMenuClose={toggleFiltersBar} />
              </FilterBarWrapper>
            </ScrollWrapper>

            <div className={classes.mainContent}>
              <div className={cn(classes.caseInfo, { [classes.caseInfoClosed]: !isCaseInfoOpen })}>
                <CaseInfo caseItem={caseItem} isCaseInfoOpen={isCaseInfoOpen} toggleCaseInfoOpen={toggleCaseInfoOpen} />
              </div>

              <Box display="flex" flexDirection="column" ml={isMobile ? 0 : 1} height="100%" width="100%" minWidth={0}>
                <Switch>
                  <Route exact path={routes.general}>
                    <Item
                      isFetched={isFetched}
                      caseItem={caseItem}
                      setCaseItem={updateCase}
                    />
                  </Route>

                  <Route exact path={routes.litigation}>
                    <Litigation caseItem={caseItem} onCaseItemUpdate={updateCase} />
                  </Route>

                  <Route
                    exact
                    path={routes.negotiation}
                    render={() => (
                      <NegotiationsProvider caseItem={caseItem}>
                        <Negotiations />
                      </NegotiationsProvider>
                    )}
                  />

                  <Route path={routes.files}>
                    <Box ml={isMobile ? 0 : 0.5} width="100%" height="100%">
                      <Files caseItem={caseItem} />
                    </Box>
                  </Route>

                  <Route exact path={routes.schedule}>
                    <LayoutContextProvider initialViewVariant={viewVariantsMap.calendar}>
                      <Schedule caseItem={caseItem} />
                    </LayoutContextProvider>
                  </Route>

                  <Route exact path={routes.reminders}>
                    <CaseReminders caseId={caseItem?.id} />
                  </Route>

                  <Route exact path={routes.appointments}>
                    <Appointments caseItem={caseItem} />
                  </Route>

                  <Route exact path={routes.callReports}>
                    <Paper component={Box} flexGrow={1} width="100%" height="100%">
                      <CallReports isTabPage caseItem={caseItem} />
                    </Paper>
                  </Route>

                  <Route exact path={routes.emails}>
                    <Emails caseItem={caseItem} />
                  </Route>

                  <Route
                    exact
                    path={routes.contacts}
                    render={() => (
                      <Contacts
                        isFetched={isFetched}
                        caseItem={caseItem}
                        onCaseItemUpdate={updateCase}
                      />
                    )}
                  />

                  <Route
                    exact
                    path={routes.claims}
                    render={() => {
                      const applicant = caseItem.case_users.find(({ case_role }) => {
                        return case_role === caseRolesMap.applicant;
                      })?.user;

                      return (
                        <Claims
                          isFixedCase
                          view="modal"
                          hiddenFilterFields={hiddenFilterFields}
                          hiddenColumns={claimsMobileHiddenColumns}
                          initialFilter={{
                            cases: [ caseItem.id ]
                          }}
                          creationInitialValues={{
                            case_id: caseItem.id,
                            patient: applicant,
                            patient_id: applicant?.id
                          }}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path={routes.discovery}
                    render={() => (
                      <CaseDiscoveriesProvider caseItem={caseItem} onCaseItemUpdate={updateCase}>
                        <Discovery isTabPage disableRightActions />
                      </CaseDiscoveriesProvider>
                    )}
                  />

                  {!hasRole('medic', 'doctor') &&
                    <Route
                      exact
                      path={routes.billing}
                      render={() => (
                        <Billing
                          caseItem={caseItem}
                          hiddenInvoicesFilterFields={hiddenInvoicesFilterFields}
                          hiddenExpensesFilterFields={hiddenExpensesFilterFields}
                        />
                      )}
                    />
                  }

                  <Route
                    exact
                    path={routes.activity}
                    render={() => (
                      <ActivityProvider filter={{ model: 'case', model_id: caseItem.id }}>
                        <CaseActivity parentType="case" parent={caseItem} />
                      </ActivityProvider>
                    )}
                  />

                  <Route
                    exact
                    path={routes.tasks}
                    render={() => (
                      <TasksWidget
                        isTabPage
                        filterKey={filtersKeysMap.cases_tasks}
                        caseItem={caseItem}
                      />
                    )}
                  />

                  <Route
                    exact
                    path={routes.comments}
                    render={() => (
                      <div className={classes.content}>
                        <CommentsContextProvider initialState={initialStateOfComments}>
                          <Comments
                            parentType="case"
                            parentID={caseItem.id}
                            onCommentAdded={onCommentAdded}
                          />
                        </CommentsContextProvider>
                      </div>
                    )}
                  />

                  {!disableTimeTracks && (
                    <Route
                      exact
                      path={routes.timeTracking}
                      render={() => (
                        <LayoutContextProvider>
                          <TimeTracking
                            isTabPage
                            caseItem={caseItem}
                            initialLogValues={initialModalValues}
                            hiddenFilterFields={hiddenFilterFields}
                            initialFilter={initialTimeTrackingFilter}
                          />
                        </LayoutContextProvider>
                      )}
                    />
                  )}

                  {/* TODO: hide for future settings */}
                  {/*<Route*/}
                  {/*  exact*/}
                  {/*  path={routes.caseTransfer}*/}
                  {/*  render={() => (*/}
                  {/*    <div className={classes.content}>*/}
                  {/*      Case Transfer Information*/}
                  {/*    </div>*/}
                  {/*  )}*/}
                  {/*/>*/}
                </Switch>
              </Box>
            </div>
          </>
        )}
      />
    </div>
  );
};
