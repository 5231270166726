export const styles = ({ spacing, palette: { grey, text }, typography: { pxToRem, fontWeightMedium } }) => ({
  root: {
    borderBottom: ({ disableBorderBottom }) => disableBorderBottom && 0
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(),
    color: text.primary,

    '& svg': {
      fontSize: pxToRem(30)
    }
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    marginRight: spacing(),
    color: text.primary,
    fontSize: pxToRem(18),
    fontWeight: fontWeightMedium
  },

  closeIcon: {
    color: grey[400]
  }
});
