import { ListRow, ListRowCell } from '../../../../../ListRow';
import { filterFieldsMap } from '../../../filterFieldsMap';
import { columnsWidths } from '../List';

export const ListHeader = ({ hiddenFields }) => {
  return (
    <ListRow>
      <ListRowCell noWrap flexBasis={columnsWidths.users}>
        User
      </ListRowCell>

      {!hiddenFields.includes(filterFieldsMap.parentUser) && (
        <ListRowCell noWrap grow flexBasis={columnsWidths.patient}>
          Patient
        </ListRowCell>
      )}

      {!hiddenFields.includes(filterFieldsMap.parentCase) && (
        <ListRowCell noWrap grow flexBasis={columnsWidths.case}>
          Case
        </ListRowCell>
      )}

      <ListRowCell noWrap flexBasis={columnsWidths.phone}>
        Number
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.disposition}>
        Disposition
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.is_group_call}>
        Call Type
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.is_callback}>
        Callback
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateFrom}>
        Date From
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateTo}>
        Date To
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
