import { Typography } from '@material-ui/core';
import { Label } from '../../../app/Dashboard/TasksPage/MainWindow/Label';
import { tasksPrioritiesOptions } from '../../../dataMaps';

const getLabel = (title) => tasksPrioritiesOptions.find(({ value }) => value === title)?.label;

export const TaskPriority = ({ before = null, after = null, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Set&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;to&nbsp;
        <Label name={getLabel(after)} color={after} />
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Label name={getLabel(before)} color={before} />
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Label name={getLabel(before)} color={before} />
        &nbsp;to&nbsp;
        <Label name={getLabel(after)} color={after} />
      </>
    );
  } else {
    return (
      <>Updated&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>&nbsp;option</>
    );
  }
};
