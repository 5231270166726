import { useContext, useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { CardItem, CardHeader } from '../../../../../components/Cards';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { Contact } from '../../../../../components/Contact';
import { FaxNumber, parseFaxContact } from '../../../../../components/faxes/FaxNumber';
import { useModal as useDefaultModal } from '../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../components/UserLink';
import { Tooltip } from '../../../../../components/Tooltip';
import { Button } from '../../../../../components/Button';
import { Date } from '../../../../../components/Date';
import { FilePreviewModal, FileTypeIcon } from '../../../files-common';
import { FaxMenu } from '../../FaxMenu';
import { FaxContext } from '../../FaxProvider';
import { faxStatusColorsMap, faxStatusesMap } from '../../helpers';
import { statusesMap, statusLabelsMap } from '../../List/statusMap';
import { tabsMap } from '../../PageContent';
import { StatusConfirmationPopup } from '../../StatusConfirmationPopup';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ item: fax = {} }) => {
  const classes = useStyles();
  const { openModal } = useDefaultModal();
  const { filter, selectedFaxesIDs, updateStatus, toggleFaxSelected } = useContext(FaxContext);
  const { name } = useMemo(() => {
    return parseFaxContact(fax.from);
  }, [ fax.from ]);
  const isSelected = selectedFaxesIDs?.indexOf(fax.id) !== -1;
  const isNew = !fax?.system_status || fax?.system_status === statusesMap.new;
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        disableFetch: true,
        file: fax.files?.[0],
        singleView: true
      }}
    />
  ), [ fax.media?.[0] ]);

  const handleItemSelection = () => {
    toggleFaxSelected(fax);
  };

  const onUpdate = () => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Are you about to update the fax status?',
        maxWidth: 'sm',
        content: <StatusConfirmationPopup fax={fax} />
      },
      onModalResolved: () => {
        const newStatus = fax?.system_status === statusesMap.processed
          ? statusesMap.new : statusesMap.processed;

        updateStatus({ id: fax.id, status: newStatus });
      }
    });
  };

  return (
    <CardItem disableHover selected={isSelected} py={1} px={2}>
      <CardHeader
        size="sm"
        isSelected={isSelected}
        childrenWidth={10}
        menuComponent={FaxMenu}
        onItemSelect={handleItemSelection}
        menuComponentProps={{ fax, openFilesPreview }}

      >
        <Grid container spacing={1} wrap="nowrap" justify="space-between">
          <Grid item xs={8}>
            {fax.user
              ? (
                <UserLink size="xs" user={fax.user}>
                  <Contact
                    color="textPrimary"
                    type="mailto"
                    variant="caption"
                    contact={fax?.user?.email}
                  />
                </UserLink>
              ) : !!fax?.email ? (
                <Contact
                  noWrap
                  color="primary"
                  type="mailto"
                  contact={fax?.email}
                />
              ) : (
                <Typography variant="h5">{name}</Typography>
              )}
          </Grid>

          <Grid item xs={4}>
            {filter.destination === tabsMap.income ? (
              <Box display="flex" justifyContent="flex-end">
                <Button size="small" color={isNew ? 'error' : 'primary'} onClick={onUpdate}>
                  {statusLabelsMap[fax?.system_status] || statusLabelsMap.new}
                </Button>
              </Box>
            ) : (
              <Box color={faxStatusColorsMap?.[fax?.status] || 'text.secondary'}>
                <Typography align="right" color="inherit">
                  {faxStatusesMap[fax?.status]}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardHeader>

      <div className={classes.bodyWrapper}>
        <div className={classes.box}>
          <Grid
            container
            spacing={2}
            wrap="nowrap"
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={10}>
              <Box display="flex" alignItems="center" height="100%">
                {!!fax?.files && (
                  <FileTypeIcon file={fax.files[0]} className={classes.boxIcon} />
                )}

                <Tooltip title={fax.name}>
                  <Link noWrap variant="body1" onClick={openFilesPreview}>
                    {fax.name}
                  </Link>
                </Tooltip>
              </Box>

              <Box pl={1}>
                <Typography color="textSecondary" variant="caption">
                  Files
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-end"
              >
                <Typography>
                  {fax?.pages || '0'}
                </Typography>

                <Typography color="textSecondary" variant="caption">
                  Pages
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {fax?.toContact?.id ? (
                <UserLink size="sm" user={fax?.toContact} childrenProps={{ mt: -0.5 }}>
                  <Typography noWrap>
                    {fax?.toContact?.mobile_phone_number || fax?.toContact?.email}
                  </Typography>
                </UserLink>
              ) : (
                <FaxNumber contact={fax.to} />
              )}

              <Box pl={4}>
                <Typography variant="caption" color="textSecondary">
                  To
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Date noWrap disableIcon date={fax.received_at || fax.sent_at} format="L LT" />

              <Typography variant="caption" color="textSecondary">
                {filter.destination === tabsMap.income ? 'Received' : 'Sent'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    </CardItem>
  );
};
