export const stringToRGBColor = (string, opacity = 1) => {
  let hash = 0;
  let color = '';

  for (let i = 0; i < string?.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    color = Math.floor(Math.abs((Math.sin(hash) * 10000) % 1 * 16777216)).toString(16);
  }

  const result = '#' + Array(6 - color.length + 1).join('0') + color;
  const hex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(result);

  const r = parseInt(hex[1], 16);
  const g = parseInt(hex[2], 16);
  const b = parseInt(hex[3], 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
