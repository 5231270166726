import { Box, Link } from '@material-ui/core';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { useModal } from '../../ModalsProvider';
import { Date } from '../../Date';
import { AppointmentViewModal } from '../AppointmentViewModal';

export const AppointmentLink = ({ appointment }) => {
  const { openModal } = useModal();

  const openViewAppointmentModal = () => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: appointment?.id
      }
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <InsertInvitationOutlinedIcon color={appointment?.appointment_at && 'primary'} />

      <Box ml={1}>
        <Date
          disableIcon
          disableWrapper
          component={appointment?.appointment_at && Link}
          date={appointment?.appointment_at}
          onClick={appointment?.id && openViewAppointmentModal}
        />
      </Box>
    </Box>
  );
};
