import { truncate } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import { Tooltip } from '../../../../../../../components/Tooltip';

export const Body = ({ immunization }) => {
  return (
    <>
      <Tooltip
        isExistTooltip={!!immunization?.immunization?.name}
        leaveDelay={100}
        placement="bottom-start"
        title={immunization?.immunization?.name}
      >
        <Typography noWrap gutterBottom variant="h6">{immunization?.immunization?.name || '-'}</Typography>
      </Tooltip>

      <Box fontWeight="normal" height={38}>
        <Tooltip
          isExistTooltip={!!immunization?.note}
          leaveDelay={200}
          placement="bottom-start"
          title={immunization?.note}
        >
          <Typography color="textSecondary" component="p" variant="caption" align="justify">
            {truncate(immunization?.note, { length: 110 })}
          </Typography>
        </Tooltip>
      </Box>
    </>
  );
};
