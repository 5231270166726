export const generateIncreaseLayout = (type, cardsLayout) => {
  const newObjXl = {
    i: type,
    w: 24,
    h: 28,
    x: 0,
    y: 0,
    maxW: 24,
    minW: 24,
    moved: false,
    isDraggable: true,
    isResizable: true
  };

  const newObjLg = {
    i: type,
    w: 12,
    h: 28,
    x: 0,
    y: 0,
    maxW: 12,
    minW: 12,
    moved: false,
    isDraggable: true,
    isResizable: true
  };

  const newObjMd = {
    i: type,
    w: 10,
    h: 28,
    x: 0,
    y: 0,
    maxW: 10,
    minW: 10,
    moved: false,
    isDraggable: true,
    isResizable: true
  };

  const newObjSm = {
    i: type,
    w: 6,
    h: 26,
    x: 0,
    y: 0,
    maxW: 10,
    minW: 6,
    moved: false,
    isDraggable: true,
    isResizable: true
  };

  const newObjXs = {
    i: type,
    w: 4,
    h: 26,
    x: 0,
    y: 0,
    maxW: 4,
    minW: 4,
    moved: false,
    isDraggable: true,
    isResizable: true
  };

  const newObjXxs = {
    i: type,
    w: 2,
    h: 16,
    x: 0,
    y: 0,
    maxW: 2,
    minW: 2,
    moved: false,
    isDraggable: true,
    isResizable: true
  };

  return {
    xl: [ ...cardsLayout?.layouts?.xl?.filter(({ i }) => i !== type), newObjXl ],
    lg: [ ...cardsLayout?.layouts?.lg?.filter(({ i }) => i !== type), newObjLg ],
    md: [ ...cardsLayout?.layouts?.md?.filter(({ i }) => i !== type), newObjMd ],
    sm: [ ...cardsLayout?.layouts?.sm?.filter(({ i }) => i !== type), newObjSm ],
    xs: [ ...cardsLayout?.layouts?.xs?.filter(({ i }) => i !== type), newObjXs ],
    xxs: [ ...cardsLayout?.layouts?.xxs?.filter(({ i }) => i !== type), newObjXxs ]
  };
};

export const generateMinimizeLayout = (type, h, w, x, y, layouts) => {
  const newObj = {
    i: type,
    w: w,
    h: h,
    x: x,
    y: y,
    maxH: h,
    maxW: w,
    minW: w,
    minH: h,
    moved: false,
    isDraggable: true,
    isResizable: true
  };

  return {
    xl: [ ...layouts.xl.filter(({ i }) => i !== type), newObj ],
    lg: [ ...layouts.lg.filter(({ i }) => i !== type), newObj ],
    md: [ ...layouts.md.filter(({ i }) => i !== type), newObj ],
    sm: [ ...layouts.sm.filter(({ i }) => i !== type), newObj ],
    xs: [ ...layouts.xs.filter(({ i }) => i !== type), newObj ],
    xxs: [ ...layouts.xxs.filter(({ i }) => i !== type), newObj ]
  };
};

const onDeleteItem = ({ breakpoints, layouts, type }) => (
  breakpoints.reduce((layoutsObj, point) => {
    const widgetOptions = layouts?.[point]?.find(({ i }) => i === type);

    return ({
      ...layoutsObj,

      [point]: layouts[point].filter(({ i }) => i !== widgetOptions.i)
    });
  }, {})
);

export const handlePutItem = ({ type, layout, state }) => {
  const breakpoints = Object.keys(layout.layouts);
  const layouts = onDeleteItem({ breakpoints, layouts: state.layouts, type });
  const toolbox = breakpoints.reduce((toolbox, point) => {
    const widgetOptions = state.layouts?.[point]?.find(({ i }) => i === type);

    return ({
      ...toolbox,

      [point]: (state.toolbox[point] || []).concat(widgetOptions ? [ widgetOptions ] : [])
    });
  }, {});

  return { toolbox, layouts };
};

export const handleTakeItem = ({ type, layout, state }) => {
  const breakpoints = Object.keys(layout.layouts);
  const toolbox = onDeleteItem({ breakpoints, layouts: state.toolbox, type });
  const layouts = breakpoints.reduce((obj, point) => {
    const toolboxOptions = state.toolbox?.[point]?.find(({ i }) => i === type);
    const isExist = !!state.layouts?.[point]?.find(({ i }) => i === toolboxOptions?.i);

    return ({
      ...obj,

      [point]: isExist ? state.layouts[point] : [ ...state.layouts[point], toolboxOptions ]
    });
  }, {});

  return { toolbox, layouts };
};

export const handleResetLayout = ({ layout, state }) => {
  const breakpoints = Object.keys(layout.layouts);
  const layouts = Object.assign({}, ...(breakpoints.map((point) => {
    const newLayouts = [ ...state.layouts[point], ...(state.toolbox?.[point] || []) ];
    const transformedLayout = newLayouts?.map((item) => {
      if (item.h < 2) {
        const newItem = layout.layouts?.[point]?.find((layoutItem) => layoutItem.i === item.i);

        if (newItem.i === item.i) {
          item = newItem;
        }
      }

      return item;
    });

    return ({ [point]: transformedLayout });
  })));

  return {
    toolbox: { xl: [], lg: [], md: [], sm: [], xs: [], xxs: [] },
    layouts
  };
};
