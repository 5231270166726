import { useContext } from 'react';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import AddIcon from '@material-ui/icons/Add';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { useModal } from '../../../../components/ModalsProvider';
import {
  PageHeader, PageHeaderActions, PageHeaderTitle
} from '../../../../components/Page';
import { IconButton } from '../../../../components/IconButton';
import { Fab } from '../../../../components/Fab';
import { SelectedItemsCount } from '../../../../components/SelectedItemsCount';
import { Tooltip } from '../../../../components/Tooltip';
import { VerticalDivider } from '../../../../components/VerticalDivider';
import { AddInsuranceInfoModal, InsurancesContext } from '../../ProfilePage/insurance';

export const ActionsBar = ({ isTablet, filterOpenButton }) => {
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { selectedIDs, meta, fetchInsurances } = useContext(InsurancesContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const createInsurance = () => {
    openModal(AddInsuranceInfoModal, {
      payload: { mainPage: true },
      onModalResolved: () => {
        fetchInsurances();
      }
    });
  };

  return (
    <PageHeader>
      <PageHeaderTitle>
        Insurances
      </PageHeaderTitle>

      <PageHeaderActions disablePosition>
        <Fab
          color="primary"
          variant="extended"
          startIcon={<AddIcon />}
          onClick={createInsurance}
        >
          Add Insurance
        </Fab>
      </PageHeaderActions>

      <PageHeaderActions disableSpacing endPosition>
        {!!selectedIDs?.length && (
          <>
            <SelectedItemsCount length={selectedIDs.length} total={meta.total} />
            <VerticalDivider verticalGutters={1} horizontalGutters={1} />

            {/*<Tooltip*/}
            {/*  arrow*/}
            {/*  title={<Typography>Delete selected insurances</Typography>}*/}
            {/*>*/}
            {/*  <IconButton onClick={onDelete}>*/}
            {/*    <DeleteIcon color="error" fontSize="inherit"/>*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}

            <VerticalDivider verticalGutters={1} horizontalGutters={1} />
          </>
        )}

        {!isTablet ? (
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <PageHeaderActions>
            {filterOpenButton}
          </PageHeaderActions>
        )}
      </PageHeaderActions>
    </PageHeader>
  );
};
