export const paymentMethodsMap = [
  {
    value: 'Cash',
    label: 'Cash'
  },
  {
    value: 'Cashier\'s check',
    label: 'Cashier\'s check'
  },
  {
    value: 'Check',
    label: 'Check'
  },
  {
    value: 'Credit card',
    label: 'Credit card'
  },
  {
    value: 'Direct deposit',
    label: 'Direct deposit'
  },
  {
    value: 'Electronic payment',
    label: 'Electronic payment'
  },
  {
    value: 'Money order',
    label: 'Money order'
  },
  {
    value: 'EFT',
    label: 'EFT'
  },
  {
    value: 'ACH',
    label: 'ACH'
  },
  {
    value: 'Apply Overdraft',
    label: 'Apply Overdraft'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];
