export const filterFieldsMap = {
  search: 'search',
  type: 'type',
  dateFrom: 'dateFrom',
  caseItem: 'caseItem'
};

export const filterFieldsLabels = {
  [filterFieldsMap.search]: 'Search',
  [filterFieldsMap.type]: 'Type',
  [filterFieldsMap.dateFrom]: 'Date from',
  [filterFieldsMap.caseItem]: 'Case'
};
