import { createReduxReducer } from '../../helpers/createReduxReducer';
import { getConfig } from '../../api/config';
import { tasksPrioritiesOptions, replaceableDataFieldsMap, participantsUserRoles } from '../../dataMaps';

const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';

export const fetchConfig = () => (dispatch) => {
  getConfig().then(({ data }) => {
    dispatch({ type: FETCH_CONFIG_SUCCESS, payload: data });
  });
};

const initialState = {
  isFetched: false,
  config: {}
};

export const reducer = createReduxReducer(initialState, {
  [FETCH_CONFIG_SUCCESS]: (state, { tasks, html_documents, ...payload }) => {
    return {
      ...state,

      isFetched: true,
      config: {
        ...payload,

        tasks: {
          ...tasks,

          roles: participantsUserRoles.filter(({ value }) => payload?.cases?.case_all_roles?.includes(value)),
          priorities: tasksPrioritiesOptions.filter(({ value }) => tasks?.priorities?.includes(value))
        },

        html_documents: {
          ...html_documents,

          fields: {
            case: replaceableDataFieldsMap.case.filter((field) => html_documents?.fields.case?.includes(field.field)),
            task: replaceableDataFieldsMap.task.filter((field) => html_documents?.fields.task?.includes(field.field))
          }
        }
      }
    };
  }
});
