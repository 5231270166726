import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.filename &&
        <>
          <Grid item xs={4}>
            <Typography>
              Name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.filename}
            </Typography>
          </Grid>
        </>
      }

      {params?.date &&
        <>
          <Grid item xs={4}>
            <Typography>
              Date:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment(params.date).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.status &&
        <>
          <Grid item xs={4}>
            <Typography>
              Status:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.status?.label}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
