import { Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import { ModalContainer, ModalHeader, ModalBody } from '../../Modal';
import { List } from './List';

export const CallReportsModal = ({
  DialogProps,
  handleModalReject,
  payload: {
    reports
  }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog fullWidth fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Call reports
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <List reports={reports} />
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
