export const styles = ({
  palette: { grey, ...palette },
  shape: { borderRadius },
  spacing,
  shadows,
  typography: { pxToRem },
  zIndex
}) => ({
  root: {
    position: 'fixed',
    right: spacing(2),
    bottom: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius,
    boxShadow: shadows[4],
    zIndex: zIndex.appBar + 1
  },

  autoHeight: {
    maxHeight: '100%'
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 52,
    padding: spacing(1, 0.5, 1, 2),
    background: grey[600]
  },

  header__title: {
    flexGrow: 1,
    fontSize: pxToRem(16),
    marginRight: spacing(),
    color: palette.common.white
  },

  header__button: {
    color: palette.common.white
  },

  bodyWrapper: {
    overflow: 'auto'
  },

  body: {
    padding: spacing(2)
  }
});
