export const styles = ({
  palette: { primary, divider },
  transitions
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    border: `1px solid ${divider}`,
    overflow: 'hidden',
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      border: `1px solid ${primary.main}`,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)'
    }
  }
});
