import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, IconButton, Typography, Link as MuiLink, Checkbox } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WorkOutlineIcon from '@material-ui/icons/WorkOutlineOutlined';
import { FavoriteCaseIndicator } from '../../../../../../../../components/cases/FavoriteCaseIndicator';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { Popper } from '../../../../../../../../components/Popper';
import { CaseMenu } from '../../../../CaseMenu';
import { CasesContext } from '../../../../CasesContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ caseItem }) => {
  const classes = useStyles();
  const casesContext = useContext(CasesContext);
  const isSelected = caseItem && casesContext.selectedCasesIDs.indexOf(caseItem.id) !== -1;

  const handleItemSelection = (event) => {
    event.stopPropagation();

    casesContext.toggleItemSelection(caseItem.id);
  };

  return (
    <div className={classes.root}>
      {caseItem.is_favorite ? (
        <FavoriteCaseIndicator fontSize="large">
          <div className={classes.icon}>
            <WorkOutlineIcon fontSize="inherit" />
          </div>
        </FavoriteCaseIndicator>
      ) : (
        <div className={classes.icon}>
          <WorkOutlineIcon fontSize="inherit" />
        </div>
      )}

      <div className={classes.info}>
        <MuiLink
          to={`/cases/${caseItem.id}`}
          component={Link}
          variant="h3"
          className={classes.name}
          title={caseItem.name}
        >
          {caseItem.name}
        </MuiLink>

        <Typography className={classes.number}>
          Case # {caseItem.file_number}
        </Typography>
      </div>

      <Checkbox className={classes.checkbox} checked={isSelected} onClick={handleItemSelection}/>

      <Popper
        placement="left-start"
        anchorRenderer={
          ({ anchorRef, handleToggle }) => (
            <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
              <MoreVertIcon/>
            </IconButton>
          )
        }
      >
        {({ handleClose }) => (
          <CaseMenu
            caseItem={caseItem}
            onClose={handleClose}
          />
        )}
      </Popper>
    </div>
  );
};
