import { useContext } from 'react';
import { Box } from '@material-ui/core';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../helpers/dates';
import { FiltersBar as FiltersBarComponent } from '../../../FiltersBar';
import { ApptTypesSelect, DatePicker } from '../../../FormField';
import { MedicalHistoryContext } from '../MedicalHistoryProvider';

const initialValues = {
  appointment_type_id: null,
  appointment_from: null,
  appointment_to: null
};

export const FiltersBar = () => {
  const { resetHistory } = useContext(MedicalHistoryContext);

  const handleDatePickerChange = (name, transformer) => (date) => {
    resetHistory({ [name]: transformer?.(date) || date });
  };

  const handleApptTypeChange = (apptType) => {
    resetHistory({ appointment_type_id: apptType?.id });
  };

  return (
    <FiltersBarComponent
      border={0}
      initialValues={initialValues}
      fields={[
        <Box minWidth={160}>
          <DatePicker
            clearable
            name="appointment_from"
            label="Appt. From"
            onChange={handleDatePickerChange('appointment_from', unixToStartOfDayUnix)}
          />
        </Box>,
        <Box minWidth={160}>
          <DatePicker
            clearable
            name="appointment_to"
            label="Appt. To"
            onChange={handleDatePickerChange('appointment_to', unixToEndOfDayUnix)}
          />
        </Box>,
        <ApptTypesSelect
          onChange={handleApptTypeChange}
        />
      ]}
    />
  );
};
