import { forwardRef } from 'react';
import cn from 'classnames';
import { makeStyles, Button as MuiButton } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Button = forwardRef(({ className, color, ...props }, ref) => {
  const classes = useStyles({ ...props, color });

  return (
    <MuiButton ref={ref} className={cn(classes.root, className)} {...props} />
  );
});
