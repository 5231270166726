import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Button, DialogContent, Dialog, Box, Typography } from '@material-ui/core';
import { ModalFooter } from '../Modal';
import { Loader } from '../Loader';
import { Slider } from '../Slider';
import { getCroppedImg } from './getCroppedImg';

export const ImageTransformCropModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  isModalEntered,
  payload: {
    restrictPosition = false,
    minWidth,
    minHeight,
    cropShape = 'square',
    ...payload
  }
}) => {
  const [ crop, setCrop ] = useState({ x: 0, y: 0 });
  const [ zoom, setZoom ] = useState(1);
  const [ rotation, setRotation ] = useState(0);
  const [ croppedAreaPixels, setCroppedAreaPixels ] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropChange = (crop) => {
    setCrop((state) => ({ ...state, ...crop }));
  };

  const handleCrop = () => {
    if (payload.parentPageUser) {
      handleModalResolve({
        imageSrc: payload.src,
        fileType: payload.fileType,
        pixelCrop: croppedAreaPixels,
        rotation,
        crop
      });
    } else {
      handleModalResolve({
        dataURL: getCroppedImg({
          imageSrc: payload.src,
          fileType: payload.fileType,
          pixelCrop: croppedAreaPixels,
          rotation
        })
      });
    }
  };

  const handleZoomChange = (event, zoom) => {
    setZoom(zoom);
  };

  const handleRotationChange = (event, rotation) => {
    setRotation(rotation);
  };

  return (
    <Dialog maxWidth="md" {...DialogProps}>
      <DialogContent>
        <Loader loading={!isModalEntered} render={
          () => (
            <>
              <Box position="relative" width="100%" height={500}>
                <Cropper
                  minZoom={0.8}
                  restrictPosition={restrictPosition}
                  image={payload.src}
                  crop={crop}
                  zoom={zoom}
                  rotation={rotation}
                  aspect={cropShape === 'round' ? 3 / 3 :  4 / 2.5}
                  cropShape={cropShape}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  onCropChange={handleCropChange}
                  onRotationChange={setRotation}
                />
              </Box>

              <Box display="flex" flexDirection="column" mt={3}>
                <Typography variant="overline">Zoom</Typography>

                <Slider
                  value={zoom}
                  min={0.8}
                  max={5}
                  step={0.1}
                  onChange={handleZoomChange}
                />

                <Typography variant="overline">Rotation</Typography>

                <Slider
                  value={rotation}
                  min={-180}
                  max={180}
                  step={10}
                  onChange={handleRotationChange}
                />
              </Box>
            </>
          )}
        />
      </DialogContent>

      <ModalFooter>
        <Button onClick={handleModalReject}>
          Cancel
        </Button>

        <Button
          autoFocus
          disabled={!crop}
          variant="contained"
          color="primary"
          onClick={handleCrop}
        >
          Crop
        </Button>
      </ModalFooter>
    </Dialog>
  );
};
