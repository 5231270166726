import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Box, Button } from '@material-ui/core';
import * as reportsApi from '../../../../../../api/profile';
import {
  returnWorkIndicatorsOptions
} from '../../../../../../components/claims/SituationalNumberFormModal/SituationalNumberForm/data';
import { Loader } from '../../../../../../components/Loader';
import { ReportsContext } from '../../../../../../components/medical/reports/diagnosis/ReportsProvider';
import { validationSchema } from '../validationSchema';
import { StepContext } from '../StepProvider';
import { Body } from './Body';

export const ThirdStep = () => {
  const { formValues, handleComplete, handleBack } = useContext(StepContext);
  const { doi, updateReport } = useContext(ReportsContext);
  const { enqueueSnackbar } = useSnackbar();

  const updateForm = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return reportsApi.updateDiagnosisReport(values).then(({ data }) => {
      handleComplete();
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      updateReport(data);
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Form is not valid', { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...formValues,

        forms: {
          ...formValues.forms,

          diagnostic_studies: {
            ...formValues?.forms?.diagnostic_studies,

            return_work_indicator: returnWorkIndicatorsOptions.find(({ value }) => (
              value === formValues?.forms?.diagnostic_studies?.return_work_indicator
            )) || null
          }
        },
        doi
      }}
      validationSchema={validationSchema}
      onSubmit={updateForm}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Box
          noValidate
          component="form"
          display="flex"
          flexDirection="column"
          onSubmit={handleSubmit}
          height="100%"
        >
          <Body />

          <Box display="flex" alignItems="center" ml="auto" py={2}>
            <Box mr={2}>
              <Button
                color="primary"
                onClick={handleBack}
              >
                Back
              </Button>
            </Box>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  disabled={isSubmitting}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              )}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
};
