import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../InfiniteLoader';
import { Loader } from '../../../Loader';
import { ScrollbarsCustom } from '../../../ScrollbarsCustom';
import { Sticky } from '../../../Sticky';
import { CallReportsContext } from '../../CallReportsProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const List = ({ autoHeight, hiddenColumns, RowProps }) => {
  const {
    isFetching,
    isFetched,
    meta,
    callReports,
    loadNextPage,
    resetCallReports
  } = useContext(CallReportsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetCallReports();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} autoHeight={autoHeight}>
      <Loader p={2} loading={!isFetched} render={
        () => !meta.total ? (
          <Box p={2}>
            <Typography align="center">No reports found</Typography>
          </Box>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader hiddenColumns={hiddenColumns} ListRowProps={RowProps?.ListRowProps} />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={callReports}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                RowComponent={Row}
                RowProps={{ hiddenColumns, ...RowProps }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </ScrollbarsCustom>
  );
};
