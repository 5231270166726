import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Grid } from '@material-ui/core';
import * as formsApi from '../../../../../../api/profile';
import { Button } from '../../../../../Button';
import { useModal } from '../../../../../ModalsProvider';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { UserLink } from '../../../../../UserLink';
import { ApprovePatientModal } from '../../ApprovePatientModal';

export const PatientStep = ({ form, setFormValue }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ patient, setPatient ] = useState(form?.patient);

  const addPatient = () => {
    openModal(ApprovePatientModal, {
      payload: {
        medicalFormID: form.id,
        patientData: {
          birthday: form.birthday,
          first_name: form.first_name,
          last_name: form.last_name,
          email: form.email,
          gender: form.gender,
          phone_number: form.phone_number,
          mobile_phone: form.mobile_phone,
          ssn: form.ssn
        }
      },

      onModalResolved: ({ isUpdatablePatient, patient: patientVal }) => {
        setPatient(patientVal);

        formsApi.updateMedicalForm({
          id: form.id,
          medical_id: patientVal?.medical?.id,
          patient: patientVal,
          is_updatable_patient: +isUpdatablePatient
        }).then((data) => {
          setFormValue({ ...data, patient: patientVal });
          enqueueSnackbar('Successfully created', { variant: 'success' });
        }).catch((error) => {
          if (error) {
            enqueueSnackbar('Not created', { variant: 'error' });
          }
        });
      }
    });
  };

  return (
    <>
      <Box pb={2}>
        <Button
          disabled={form?.medical?.id}
          size="small"
          variant="contained"
          color="primary"
          onClick={addPatient}
        >
          Add Patient
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReadonlyCheckbox
            checked={form?.medical?.id}
            title="Patient created"
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <UserLink user={patient} />
        </Grid>
      </Grid>
    </>
  );
};
