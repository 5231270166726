import { createContext, useRef, useEffect, useReducer } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { usePrevious } from '../../../helpers/hooks';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { api } from '../../../api';
import * as claimsApi from '../../../api/claims';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const ClaimsContext = createContext();

export const ClaimsProvider = ({ filter: filterProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    claims,
    selectedClaimsIDs,
    selectedClaims
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchClaims = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_CLAIMS_REQUEST });

    claimsApi.fetchClaims({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_CLAIMS_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchClaims({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetClaims = (newFilter) => {
    dispatch({ type: types.RESET_CLAIMS, payload: newFilter });

    fetchClaims({ page: 1, ...newFilter });
  };

  const addClaimsToSelected = (claims) => {
    dispatch({ type: types.ADD_CLAIMS_TO_SELECTED, payload: claims });
  };

  const deleteClaimsFromSelected = (claimsIDs) => {
    dispatch({ type: types.DELETE_CLAIMS_FROM_SELECTED, payload: claimsIDs });
  };

  const toggleClaimSelected = (claim) => {
    if (selectedClaimsIDs.indexOf(claim.id) === -1) addClaimsToSelected([ claim ]);
    else deleteClaimsFromSelected([ claim.id ]);
  };

  const allClaimsIsSelected = () => {
    return selectedClaimsIDs
      .filter((id) => claims.find((claim) => claim.id === id))
      .length === claims.length;
  };

  const toggleAllClaimsSelected = () => {
    const claimsIDs = claims.map(({ id }) => id);

    allClaimsIsSelected() ? deleteClaimsFromSelected(claimsIDs) : addClaimsToSelected(claims);
  };

  const deleteClaim = (claim) => {
    return new Promise((resolve) => {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          claimsApi.deleteClaim(claim.id).then(() => {
            dispatch({ type: types.DELETE_CLAIM, payload: claim.id });
            enqueueSnackbar('Claim successfully deleted', { variant: 'success' });
            resolve();
          });
        },

        onModalRejected: resolve
      });
    });
  };

  const providerValue = {
    isFetched,
    isFetching,
    claims,
    selectedClaimsIDs,
    selectedClaims,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetClaims,
    applyFilter,
    fetchClaims,
    loadNextPage,
    deleteClaim,
    toggleClaimSelected,
    deleteClaimsFromSelected,
    allClaimsIsSelected,
    toggleAllClaimsSelected
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetClaims(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ClaimsContext.Provider value={providerValue}>
      {children}
    </ClaimsContext.Provider>
  );
};
