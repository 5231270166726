export const styles = ({
  palette: { grey },
  shape: { borderRadius }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexGrow: 1,
    overflow: 'auto'
  },

  gridLayout: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },

  actionBar: {
    backgroundColor: grey[50],
    borderRadius
  }
});
