import { Redirect, Switch } from 'react-router-dom';
import { Route } from '../../components/router';

export const FilesSettingsPage = () => {
  return (
    <Switch>
      <Route exact path="/files/system-settings">
        <Redirect to="/files/system-settings/tags" />
      </Route>
    </Switch>
  );
};
