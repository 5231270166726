export const styles = ({
  spacing,
  palette: { primary, grey },
  typography: { fontWeightMedium },
  shape: { borderRadius }
}) => ({
  autoSizer: {
    position: 'relative',
    height: '100%',
    width: '100% !important'
  },

  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: primary.contrastText,
    height: '100%',
    overflow: 'auto',
    borderRadius
  },

  mainInfo: {
    marginBottom: spacing(10)
  },

  content: {
    marginBottom: spacing(4)
  },

  attachmentContentHeader: {
    backgroundColor: grey[100]
  },

  attachmentContentTitle: {
    textTransform: 'uppercase',
    fontWeight: fontWeightMedium,
    color: grey[600]
  },

  handleButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },

  actionAddButton: {
    marginLeft: spacing(2)
  },

  actions: {
    marginLeft: 'auto'
  },

  actions__backButton: {
    color: grey[400],
    marginRight: spacing(1.5)
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: spacing(4)
  }
});
