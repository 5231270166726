import { Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export const AddOption = ({ option }) => {
  return (
    <Box display="flex" alignItems="center">
      {option?.inputValue && (
        <AddIcon color="primary" />
      )}

      {option?.inputValue ? (
        <Typography variant="body2" color="textSecondary">
          {`Create "${option?.inputValue}"`}
        </Typography>
      ) : (
        <Typography variant="body2">
          Enter email
        </Typography>
      )}
    </Box>
  );
};
