import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { makeStyles, Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { getHeightFormat } from '../../../../../../../helpers/generateHeight';
import { measureTypes } from '../../../../../../../helpers/measureDataMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { InsuranceInfo } from '../../../../../../../components/medical/forms/personalInjury';
import { KeyboardDatePicker } from '../../../../../../../components/FormField';
import { Divider } from '../../../../../../../components/Divider';
import { Loader } from '../../../../../../../components/Loader';
import {
  InitialFormContent,
  generateInitialValues,
  Measurements,
  userValidationSchema,
  ActionButtons,
  formTypesMap
} from '../../../../../../../components/medical/forms';
import { Body } from '../../../../../../../components/medical/forms/privatePacket';
import { UserContext } from '../../../../../Members/User/UserProvider';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import { validationSchema } from '../../PersonalInjury/GeneralInfo/validationSchema';
import { styles } from '../../styles';

const useStyles = makeStyles(styles);

export const GeneralInfo = () => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const params = useParams();
  const {
    form,
    isFetched,
    type,
    createFirstStep,
    parentRoute,
    updateForm
  } = useContext(MedicalFormItemContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const hasRoleForEdit = hasRole(rolesMap.doctor, rolesMap.medic, rolesMap.admin, rolesMap.supervisor);
  const hasRoleToConfirm = !hasRole(rolesMap.advocate, rolesMap.patient);

  const handleBack = () => () => {
    history.push(parentRoute);
  };

  const handleCreateStep = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.attorney_id = values?.attorney_id?.value || values?.attorney_id || null;
    values.measurements.height = getHeightFormat({
      isFeet: values.measurements.height_unit === measureTypes.feet,
      isTransform: true
    })(`${values.measurements.height}`);

    createFirstStep({
      updateFirstStep: true,
      isEditableProfile: !!userContext,
      values,
      setErrors,
      setSubmitting
    });
  };

  const handleUpdateStep = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.id = form.id || params.medicalFormID;
    values.attorney_id = values?.attorney_id?.value || values?.attorney_id || null;
    values.auto_accident_state = values?.auto_accident_state?.value || values?.auto_accident_state || null;
    values.auto_accident_country = values?.auto_accident_country?.value || values?.auto_accident_country || null;
    values.measurements.height = getHeightFormat({
      isFeet: values.measurements.height_unit === measureTypes.feet,
      isTransform: true
    })(`${values.measurements.height}`);

    updateForm({
      updateFirstStep: true,
      isEditableProfile: !!userContext,
      values,
      setErrors,
      setSubmitting
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            ...generateInitialValues({
              type: formTypesMap.ortho,
              initialValues: form
            }),
            attorney_id: form.attorney || null,
            birthday: form?.birthday || form?.patient?.birthday,
            is_editable: !!form?.medical?.id || !!form.form_type,
            is_patient: hasRole(rolesMap.patient),
            is_confirmed: hasRoleToConfirm && (form?.is_confirmed || !!form?.medical?.id || !!form?.patient?.id),
            isConfirmed: hasRoleToConfirm && (form?.is_confirmed || !form?.medical?.id) && !hasRoleForEdit
          }}
          validationSchema={userValidationSchema.concat(validationSchema)}
          onSubmit={form.form_type ? handleUpdateStep : handleCreateStep}
        >
          {({ values, handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <Box flexGrow={1}>
                <Box py={2}>
                  <Grid container spacing={isMobile ? 1 : 3}>
                    <InitialFormContent type={type} />
                  </Grid>
                </Box>

                <Box py={2}>
                  <Grid container spacing={isMobile ? 1 : 3}>
                    <Body isUnconfirmed={!form?.medical?.id} />
                  </Grid>
                </Box>

                <Box py={2}>
                  <Grid container spacing={isMobile ? 1 : 3}>
                    <InsuranceInfo isUnconfirmed={!form?.medical?.id && !values?.patient?.id} />
                  </Grid>
                </Box>

                <Measurements />

                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={isMobile ? 12 : 4}>
                    <KeyboardDatePicker
                      clearable
                      name="hearing_date"
                      label="Hearing Date"
                      outputFormat="YYYY-MM-DD"
                      margin="dense"
                    />
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 4}>
                    <KeyboardDatePicker
                      clearable
                      name="vision_date"
                      label="Vision Rx Date"
                      outputFormat="YYYY-MM-DD"
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Divider gutter={2} />
              <ActionButtons buttonTilte="Close" onBack={handleBack} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
