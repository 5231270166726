import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { renderRoutes } from '../../../components/router';
import { routes } from './routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AppointmentsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="column" flexGrow={1} py={isTablet ? 0 : 2} px={isTablet ? 0 : 3}>
        {renderRoutes(routes)}
      </Box>
    </div>
  );
};
