import { useContext } from 'react';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { CaseReminderEventsContext } from '../../../../../components/cases/reminders/events';
import { CaseReminderGroupsProvider } from '../../../../../components/cases/reminders/groups';
import { ReminderGroups } from './ReminderGroups';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const LeftSidebar = ({ isOpenSidebar = false, toggleSideBar = () => {} }) => {
  const { applyFilter, applyRelationsForFilter } = useContext(CaseReminderEventsContext);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const handleGroupsChange = (groups) => {
    // applyFilter({ groups: groups?.map(({ id }) => id) || null });
    // applyRelationsForFilter({
    //   groups: groups?.map(({ label: tag }) => tag) || null
    // });
    applyFilter({ group_id: groups?.id });
    applyRelationsForFilter({
      group_id: groups
    });
  };

  return (
    <div className={classes.root}>
      {!isMobile && (
        <div className={classes.groups}>
          <CaseReminderGroupsProvider>
            <ReminderGroups
              isOpenSidebar={isOpenSidebar}
              toggleSideBar={toggleSideBar}
              onReminderGroupsChange={handleGroupsChange}
            />
          </CaseReminderGroupsProvider>
        </div>
      )}
    </div>
  );
};
