export const filterFieldsMap = {
  dateFrom: 'date_from',
  dateTo: 'date_to',
  age: 'age',
  bmi: 'bmi',
  weight: 'weight',
  temperature: 'temperature',
  pressure: 'pressure'
};

export const filterFieldsLabels = {
  [filterFieldsMap.dateFrom]: 'Date From',
  [filterFieldsMap.dateTo]: 'Date To',
  [filterFieldsMap.age]: 'Age',
  [filterFieldsMap.bmi]: 'BMI',
  [filterFieldsMap.weight]: 'Weight',
  [filterFieldsMap.temperature]: 'Temperature',
  [filterFieldsMap.pressure]: 'Pressure'
};
