import { Loader } from '../../../../../../../../components/Loader';
import { FileRow } from '../../../../../../files-common/FilesList/FileRow';
import { FileRowMobile } from '../../../../../../files-common/FilesList/FileRowMobile';

export const Row = ({ item: file = {}, isMobile, isLoaded, ...props }) => {
  return !isLoaded ? <Loader p={2} /> :
    isMobile ? (
      <FileRowMobile isLoaded={isLoaded} file={file} {...props} />
    ) : (
      <FileRow isLoaded={isLoaded} file={file} {...props} />
    );
};
