import React, { useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Box, IconButton, Link, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { billingParticipantsTypesMap } from '../../../../../../../components/billing';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../../dataMaps/apiModels';
import { ListRow, ListRowCell, ListRowCellActions, ListRowCheckbox } from '../../../../../../../components/ListRow';
import { UserPreviewModal } from '../../../../../../../components/users/UserPreviewModal';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../components/UserLink';
import { Popper } from '../../../../../../../components/Popper';
import { PreviewCompanyModal } from '../../../../../Contacts/Companies/PreviewCompanyModal';
import { PaymentsContext, PaymentMenu } from '../../../../../../../components/billing/payments';
import {
  columnsMap,
  columnsWidths,
  widthBreakpointMedium,
  widthBreakpointLarge
} from '../List';
import { OverdraftActions } from './OverdraftActions';

export const Row = ({ payment, width, hiddenColumns = [] }) => {
  const paymentsContext = useContext(PaymentsContext);
  const isSelected = paymentsContext.selectedPaymentsIDs.indexOf(payment.id) !== -1;
  const { openModal } = useModal();
  const history = useHistory();

  const handleItemSelection = (event) => {
    event.stopPropagation();

    paymentsContext.toggleItemSelection(payment.id);
  };

  const openPaymentMenu = (callback) => (event) => {
    event.stopPropagation();

    callback(event);
  };

  const getPaymentPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.payment(payment.id));
    }
  };

  const openUserPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: payment?.payer?.participant?.id }
    });
  };

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: {
        companyID: payment?.payer?.participant_id
      }
    });
  };

  return (
    <ListRow
      button
      selected={isSelected}
      title="Open payment page"
      onClick={getPaymentPage}
    >
      {!hiddenColumns.includes(columnsMap.checkbox) &&
        <ListRowCheckbox checked={isSelected} onClick={handleItemSelection} />
      }

      <ListRowCell grow={width < widthBreakpointMedium} flexBasis={columnsWidths.number}>
        <Typography noWrap title={payment.number}>
          {payment.number}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.payer}>
        {payment?.payer?.participant_type === billingParticipantsTypesMap.company ?
          <CompanyLink
            noWrap
            size="sm"
            company={payment?.payer?.participant}
            onClick={stopPropagation(openPreviewCompanyModal)}
          />
          : payment?.payer?.participant_type === billingParticipantsTypesMap.user ?
            <UserLink
              noWrap
              size="sm"
              user={payment?.payer?.participant}
              onClick={stopPropagation(openUserPreview)}
            />
            : '-'}
      </ListRowCell>

      <ListRowCell noWrap grow={width < widthBreakpointMedium} flexBasis={columnsWidths.number}>
        {!payment.invoice ? '-' :
          <Link
            noWrap
            target="_blank"
            component={RouterLink}
            color="textSecondary"
            to={itemsRoutesByModels.invoice(payment?.invoice?.id)}
            title={payment?.invoice?.number}
          >
            {payment?.invoice?.number}
          </Link>
        }
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell grow flexBasis={columnsWidths.description}>
          <Typography noWrap title={payment.note}>
            {payment.note}
          </Typography>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.paidDate}>
        {moment(payment.date).format('L')}
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.dueOn) &&
        <ListRowCell flexBasis={columnsWidths.dueOn}>
          {!payment?.invoice?.date ? '-' :
            <Box clone color="secondary.main">
              <Typography>
                {moment(payment?.invoice?.date).format('L')}
              </Typography>
            </Box>
          }
        </ListRowCell>
      }

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.method}>
          <Typography noWrap title={payment.method}>
            {payment.method}
          </Typography>
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.total) &&
        <ListRowCell flexBasis={columnsWidths.total}>
          <CurrencyFormat value={payment?.invoice?.total} />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.amount}>
        <CurrencyFormat value={payment?.amount} />
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.balance) &&
        <ListRowCell flexBasis={columnsWidths.balance}>
          <Box clone color="secondary.main">
            <CurrencyFormat value={payment?.invoice?.balance} />
          </Box>
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.actions) &&
        <ListRowCell flexBasis={columnsWidths.actions} justifyContent="flex-end">
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton edge="end" buttonRef={anchorRef} onClick={openPaymentMenu(handleToggle)}>
                  <MoreVertIcon />
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <PaymentMenu
                payment={payment}
                onClose={handleClose}
              />
            )}
          </Popper>
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.overdraftActions) &&
        <ListRowCell flexBasis={columnsWidths.overdraftActions}>
          <ListRowCellActions withSpacings>
            <OverdraftActions payment={payment} />
          </ListRowCellActions>
        </ListRowCell>
      }
    </ListRow>
  );
};
