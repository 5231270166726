import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../../../components/LayoutContext';
import { Page, PageBody, PageHeader } from '../../../../components/Page';
import { useModal } from '../../../../components/ModalsProvider';
import { Loader } from '../../../../components/Loader';
import { UserContext } from '../../Members/User/UserProvider';
import { MedicalProfileContext } from '../../ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider';
import { ActionsBar } from '../ActionsBar';
import { NotesContext, NotesProvider } from './NotesProvider';
import { CreateNoteModal } from './CreateNoteModal';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

export const ClinicalNotes = () => {
  const params = useParams();
  const profile = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const [ user, setUser ] = useState(null);
  const isFetched = userContext ? userContext.isFetched : profile.isFetched;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const context = useContext(MedicalProfileContext);
  const { openModal } = useModal();
  const {
    filterOpenButton,
    scrollWrapperProps,
    ScrollWrapper,
    FilterBarWrapper,
    filterBarWrapperProps
  } = useMobileFilterCollapse();

  const initialState = {
    filter: {
      patient_id: params?.userID || params.patientID || profile?.user?.id
    }
  };

  const openCreateModal = (addNote) => () => {
    openModal(CreateNoteModal, {
      payload: { user, medicalProfile: context.medicalProfile },
      onModalResolved: (data) => {
        addNote(data);
      }
    });
  };

  useEffect(() => {
    setUser(userContext ? userContext.user : profile.user);
  }, [ isFetched ] );

  return (
    <LayoutContextProvider>
      <LayoutContext.Consumer>
        {({ viewVariant }) => (
          <NotesProvider initialState={initialState}>
            <Page>
              <Loader p={3} loading={!user} render={
                () => (
                  <>
                    <PageHeader disableBorderBottom>
                      <NotesContext.Consumer>
                        {({ selectedIDs, pagination, addNote, massDeleteItems }) => (
                          <ActionsBar
                            disableViewVariant={isTablet}
                            actionTitle="Add new"
                            selectedIDs={selectedIDs}
                            pagination={pagination}
                            filterOpenButton={filterOpenButton}
                            onCreate={openCreateModal(addNote)}
                            onDelete={massDeleteItems(selectedIDs)}
                          />
                        )}
                      </NotesContext.Consumer>
                    </PageHeader>

                    <PageBody fullHeight disablePaddings disableScrollbars>
                      <Box height="100%" display="flex" flexDirection="column">
                        <ScrollWrapper {...scrollWrapperProps}>
                          <FilterBarWrapper {...filterBarWrapperProps}>
                            <FiltersBar filterKey={filtersKeysMap.clinical_notes} />
                          </FilterBarWrapper>
                        </ScrollWrapper>

                        <Box flexGrow={1}>
                          {!isTablet ? (
                            (viewVariant === viewVariantsMap.list)
                              ? <List />
                              : (viewVariant === viewVariantsMap.grid)
                                ? <Grid /> : null
                          ) : (
                            <Grid />
                          )}
                        </Box>
                      </Box>
                    </PageBody>
                  </>
                )}
              />
            </Page>
          </NotesProvider>
        )}
      </LayoutContext.Consumer>
    </LayoutContextProvider>
  );
};
