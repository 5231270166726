import { Chip, Typography } from '@material-ui/core';
import { getUserFullName } from '../../../../helpers/users';
import { UserAvatar } from '../../../users/UserAvatar';

export const SingleValue = ({ children: user }) => {
  const isUser = typeof user !== 'string';

  return isUser && (
    <Chip
      tabIndex={-1}
      size="small"
      variant="outlined"
      label={
        <Typography noWrap title={getUserFullName(user)}>
          {getUserFullName(user)}
        </Typography>
      }
      avatar={<UserAvatar user={user} size="xs" />}
    />
  );
};
