export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    flexGrow: 1,
    padding: 0,

    [breakpoints.up('md')]: {
      padding: spacing(0, 3)
    }
  },

  mainContent: {
    flexGrow: 1,
    overflow: 'auto'
  },

  checkboxesContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    maxWidth: 800,
    paddingLeft: spacing(2)
  },

  checkboxesContentRow: {
    width: 180
  },

  radioRowGroup: {
    maxWidth: 800
  },

  radioRowGroup__content: {
    width: 180
  }
});
