export const initialValues = {
  is_active: true,
  is_private: false,
  appointment_book_name: null,
  provider_id: null,
  default_appointment_type_id: null,
  default_arrival_status_id: null,
  appointment_types: [],
  office_id: null,
  work_schedules: [
    {
      day: 0,
      start: null,
      finish: null,
      day_off: true,
      breaks: [
        {
          from: null,
          to: null
        }
      ]
    },
    {
      day: 1,
      start: '09:00:00',
      finish: '17:00:00',
      day_off: false,
      breaks: [
        {
          from: '12:00:00',
          to: '13:00:00'
        }
      ]
    },
    {
      day: 2,
      start: '09:00:00',
      finish: '17:00:00',
      day_off: false,
      breaks: [
        {
          from: '12:00:00',
          to: '13:00:00'
        }
      ]
    },
    {
      day: 3,
      start: '09:00:00',
      finish: '17:00:00',
      day_off: false,
      breaks: [
        {
          from: '12:00:00',
          to: '13:00:00'
        }
      ]
    },
    {
      day: 4,
      start: '09:00:00',
      finish: '17:00:00',
      day_off: false,
      breaks: [
        {
          from: '12:00:00',
          to: '13:00:00'
        }
      ]
    },
    {
      day: 5,
      start: '09:00:00',
      finish: '17:00:00',
      day_off: false,
      breaks: [
        {
          from: '12:00:00',
          to: '13:00:00'
        }
      ]
    },
    {
      day: 6,
      start: null,
      finish: null,
      day_off: true,
      breaks: [
        {
          from: null,
          to: null
        }
      ]
    }
  ]
};
