import { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { CardHeader, CardItem } from '../../../../../../../components/Cards';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { Contact } from '../../../../../../../components/Contact';
import { CodesContext } from '../../CodesContext';
import { PayerModal } from '../../PayerModal';
import { PayerMenu } from '../List/Row/PayerMenu';

export const Card = ({ item: code = {}, baseUrl }) => {
  const {
    selectedCodesIDs,
    toggleItemSelection,
    massActivePayer,
    massInactivePayer,
    ...codesContext
  } = useContext(CodesContext);
  const isSelected = selectedCodesIDs.indexOf(code.id) !== -1;
  const { openModal } = useModal();

  const toggleActiveItem = () => {
    code.deactivated_at ? massActivePayer([ code.id ]) : massInactivePayer([ code.id ]);
  };

  const handleItemSelection = () => {
    toggleItemSelection(code.id);
  };

  const openPayerModal = () => {
    openModal(PayerModal, {
      payload: {
        baseUrl,
        codeID: code.id,
        codesContext: { ...codesContext, massActivePayer, massInactivePayer }
      }
    });
  };

  return (
    <CardItem
      selected={isSelected}
      py={1}
      px={2}
      onClick={stopPropagation(openPayerModal)}
    >
      <CardHeader
        isSelected={isSelected}
        onItemSelect={handleItemSelection}
        menuComponent={PayerMenu}
        menuComponentProps={{
          code,
          baseUrl,
          onChange: toggleActiveItem
        }}
      >
        <Typography noWrap variant="h5" title={code.code || '-'}>
          {code.code || '-'}
        </Typography>
      </CardHeader>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Typography variant="caption">Payer Name</Typography>
        </Grid>

        <Grid item xs={8}>
          <CompanyLink
            variant="h5"
            company={code?.company}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography variant="caption">Address1</Typography>
        </Grid>

        <Grid item xs={8}>
          <AddressLink
            variant="body1"
            address={code.address_1}
          >
            {code.address_1}
          </AddressLink>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="caption">State</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography noWrap variant="h5" title={code.state || '-'}>
            {code.state || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="caption">City</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography noWrap variant="h5" title={code.city || '-'}>
            {code.city || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="caption">Phone</Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            type="tel"
            title={code.main_phone || '-'}
            contact={code.main_phone}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography variant="caption">Financial Class</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5" noWrap title={code?.financial_class?.code || ''}>
            {code?.financial_class?.code || ''}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="caption">Claim EDI No. Prof</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography noWrap variant="h5" title={code?.professional_edi || ''}>
            {code?.professional_edi || ''}
          </Typography>
        </Grid>
      </Grid>
    </CardItem>
  );
};
