import { SvgIcon } from '@material-ui/core';
import { Icon } from '@mdi/react';
import {
  mdiFileAccountOutline,
  mdiFileDocumentOutline,
  mdiAccountHeartOutline,
  mdiFileMultipleOutline,
  mdiClipboardAccountOutline,
  mdiBriefcaseCheckOutline,
  mdiFileDocumentEditOutline
} from '@mdi/js';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { chat } from './chat';

export const clientMenuList = [
  {
    exact: true,
    path: '/profile',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiClipboardAccountOutline} /></SvgIcon>,
    name: 'General info'
  },
  {
    exact: true,
    path: '/profile/medical-info/medical-forms',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileAccountOutline} /></SvgIcon>,
    name: 'Medical Forms'
  },
  {
    exact: true,
    path: '/profile/medical-info',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiAccountHeartOutline} /></SvgIcon>,
    name: 'Medical Info'
  },
  {
    exact: true,
    path: '/profile/medical-info/medical-report',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileDocumentOutline} /></SvgIcon>,
    name: 'Medical Report'
  },
  {
    path: '/profile/appointments',
    icon: InsertInvitationOutlinedIcon,
    name: 'Appointments'
  },
  {
    path: '/claims',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>,
    name: 'Claims'
  },
  {
    path: '/profile/files',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileMultipleOutline} /></SvgIcon>,
    name: 'Files'
  },

  chat
];

export const emailsRoute = [
  {
    name: 'Emails',
    icon: (props) => (
      <SvgIcon {...props}>
        <Icon path={mdiBriefcaseCheckOutline} />
      </SvgIcon>
    ),
    path: '/profile/emails'
  }
];
