import { activity } from './activity';
import { faxes } from './faxes';
import { callReports } from './callReports';
import { appointments } from './appointments';
import { insurances } from './insurances';
import { schedule } from './schedule';
import { emails } from './emails';
import { contacts } from './contacts';
import { billing } from './billing';
import { tasks } from './tasks';
// TODO: for future settings
// import { videos } from './videos';
import { analytics } from './analitics';
import { chat } from './chat';
import { workSessions } from './workSessions';

export const menuList = [
  faxes,
  callReports,
  insurances,
  appointments,
  schedule,
  emails,
  contacts,
  billing,
  tasks,
  activity,
  // TODO: for future settings
  // videos,
  analytics,
  chat,
  workSessions
];
