import { Formik } from 'formik';
import { Button, Dialog, Grid, SvgIcon } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import { mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { IconButton } from '../IconButton';
import { Loader } from '../Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalHeaderActions
} from '../Modal';

export const FiltersBarModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    twoColumnsFilter,
    title,
    initialValues = {},
    fields = [],
    setFilters = () => {}
  }
}) => {
  const handleReset = (setValues) => () => {
    setValues({});
  };

  const onSubmit = (values) => {
    handleModalResolve(values);
    setFilters(values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setValues, handleSubmit }) => (
        <Dialog PaperProps={{ style: { maxWidth: !twoColumnsFilter ? 350 : 500 } }} {...DialogProps}>
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader
              icon={
                <SvgIcon color="primary">
                  <Icon path={mdiFilter} />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              {title}

              <ModalHeaderActions>
                <IconButton
                  edge="end"
                  color="primary"
                  onClick={handleReset(setValues)}
                >
                  <BackupIcon />
                </IconButton>
              </ModalHeaderActions>
            </ModalHeader>

            <ModalBody>
              {!twoColumnsFilter ?
                <Grid container spacing={2}>
                  {fields?.map((item, i) => (
                    <Grid key={i} item xs={12}>
                      {item}
                    </Grid>
                  ))}
                </Grid>
                :
                <Grid container spacing={2}>
                  {fields?.map((item, i) => (
                    <Grid key={i} item xs={fields.length % 2 !== 0 && i === fields.length - 1 ? 12 : 6}>
                      {item}
                    </Grid>
                  ))}
                </Grid>
              }
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
