import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, omit } from 'lodash';
import { Box } from '@material-ui/core';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { saveFilter } from '../../../../../../store/lastFilters';
import { allClaimsStatuses, ClaimsContext, orderByOptions } from '../../../../../../components/claims';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import {
  Autocomplete,
  KeyboardDatePicker,
  OfficesLocationSelect,
  OrderBy,
  TextField
} from '../../../../../../components/FormField';
import { IconComponent } from '../../../../../../components/saved-filters';
import { UsersSelect } from '../../../../../../components/users';
import { columnsNamesMap, columnsWidths } from '../SavedFiltersList';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { transformRelationsForFilterToOptions } from './utils';
import { CardContent } from './CardContent';
import { List } from './List';

const MODAL_WIDTH = 1410;

export const initialValues = {
  appointment_from: null,
  appointment_to: null,
  order_by: orderByOptions[0]
};

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { filter, applyFilter } = useContext(ClaimsContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState({
    order_by: orderByOptions[0]
  });
  const filtersModalWidth = hiddenFields.includes(filterFieldsMap.patients)
    ? MODAL_WIDTH - columnsWidths[columnsNamesMap.patients]
    : MODAL_WIDTH;

  const handleTextFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleStatusesChange = (options) => {
    applyFilter({ statuses: options?.map(({ value }) => value) });
    setRelationsForFilter((state) => ({ ...state, statuses: options }));
  };

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleSelectChange = (name) => (option) => {
    applyFilter(({ [name]: option?.value }));
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data || option }));
  };

  const handleOfficesChange = (offices) => {
    applyFilter({ offices: offices?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, offices }));
  };

  const handleUsersMultiSelect = (name) => (options) => {
    const users = options?.length ? options.map(({ id }) => id) : null;

    applyFilter({ [name]: users });
    setRelationsForFilter((state) => ({ ...state, [name]: options }));
  };

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ order_direction: orderDirection });
    setRelationsForFilter((state) => ({ ...state, order_direction: orderDirection }));
  };

  const applySavedFilter = (filter) => {
    const preparedFilter = {
      ...filter,

      adjusters: filter?.adjusters?.map(({ id }) => id),
      offices: filter?.offices?.map(({ id }) => id),
      patients: filter?.patients?.map(({ id }) => id),
      providers: filter?.providers?.map(({ id }) => id),
      statuses: filter?.statuses?.map(({ value }) => value),
      order_by: filter?.order_by?.value
    };

    if (filter?.cases !== undefined) {
      preparedFilter.cases = filter?.cases?.map(({ id }) => id);
    }

    applyFilter(omit( preparedFilter, hiddenFields));
    formikRef.current.setValues(transformRelationsForFilterToOptions(filter));
  };

  useEffect(() => {
    if (filterKey && lastFilters) {
      dispatch(saveFilter({
        key: filterKey,
        filter: relationsForFilter
      }));
    }
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (filterKey && lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      initialValues={transformRelationsForFilterToOptions(initialValues)}
      hiddenFields={hiddenFields}
      filterKey={filterKey}
      iconComponent={
        <IconComponent
          modalWidth={filtersModalWidth}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      onReset={() => applySavedFilter(initialValues)}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.claimNumber,
          label: filterFieldsLabels[filterFieldsMap.claimNumber],
          field: <TextField
            label="Claim #"
            name="claim_number"
            onChange={handleTextFieldChange('claim_number')}
          />
        },
        {
          fieldKey: filterFieldsMap.patients,
          label: filterFieldsLabels[filterFieldsMap.patients],
          field: <UsersSelect
            multiple
            name="patients"
            label="Patients"
            params={{ is_patient: 1 }}
            onChange={handleUsersMultiSelect('patients')}
            minWidth={210}
          />
        },
        {
          fieldKey: filterFieldsMap.appointmentFrom,
          label: filterFieldsLabels[filterFieldsMap.appointmentFrom],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              name="appointment_from"
              label="DOS From"
              onChange={handleDatePickerChange('appointment_from')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.appointmentTo,
          label: filterFieldsLabels[filterFieldsMap.appointmentTo],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              name="appointment_to"
              label="DOS To"
              onChange={handleDatePickerChange('appointment_to')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.offices,
          label: filterFieldsLabels[filterFieldsMap.offices],
          field: <OfficesLocationSelect
            multiple
            name="offices"
            label="Offices"
            onChange={handleOfficesChange}
          />
        },
        {
          fieldKey: filterFieldsMap.providers,
          label: filterFieldsLabels[filterFieldsMap.providers],
          field: <UsersSelect
            multiple
            name="providers"
            label="Providers"
            params={{ role: rolesMap.doctor }}
            onChange={handleUsersMultiSelect('providers')}
            minWidth={210}
          />
        },
        {
          fieldKey: filterFieldsMap.adjusters,
          label: filterFieldsLabels[filterFieldsMap.adjusters],
          field: <UsersSelect
            multiple
            name="adjusters"
            label="Adjusters"
            params={{ role: rolesMap.client }}
            onChange={handleUsersMultiSelect('adjusters')}
            minWidth={210}
          />
        },
        {
          fieldKey: filterFieldsMap.statuses,
          label: filterFieldsLabels[filterFieldsMap.statuses],
          field: <Box minWidth={120}>
            <Autocomplete
              multiple
              name="statuses"
              label="Claim Status"
              options={allClaimsStatuses}
              onChange={handleStatusesChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.orderBy,
          label: filterFieldsLabels[filterFieldsMap.orderBy],
          field: <Box minWidth={180}>
            <OrderBy
              isSearchable={false}
              name="order_by"
              value={orderByOptions.find(({ value }) => value === filter.order_by)}
              options={orderByOptions}
              orderDirection={filter.order_direction}
              onOptionChange={handleSelectChange('order_by')}
              onOrderDirectionChange={toggleOrderDirection}
            />
          </Box>
        }
      ]}
    />
  );
};
