import { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IconComponent } from '../../../../../components/saved-filters';
import { UsersSelect } from '../../../../../components/users';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../../helpers/dates';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import {
  DatePicker,
  IcdCodesSelect,
  CPTCodesSelect,
  OfficesLocationSelect
} from '../../../../../components/FormField';
import { saveFilter } from '../../../../../store/lastFilters';
import { List } from './List';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { ProcedureContext } from '../ProcedureProvider';
import { initialValues } from './initialValues';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const MODAL_WIDTH = 980;

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const classes = useStyles();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { applyFilter } = useContext(ProcedureContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleDatePickerChange = (name, transformer) => (date) => {
    applyFilter({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleIcdCodeChange = (code) => {
    applyFilter({ icd_id: code?.id });
    setRelationsForFilter((state) => ({ ...state, icd_id: code }));
  };

  const handleCptChange = (cpt) => {
    applyFilter({ cpt_id: cpt?.id });
    setRelationsForFilter((state) => ({ ...state, cpt_id: cpt }));
  };

  const handleOfficeChange = (office) => {
    applyFilter({ office_id: office?.id });
    setRelationsForFilter((state) => ({ ...state, office_id: office }));
  };

  const handleUserSelect = (name) => (option) => {
    applyFilter({ [name]: option?.id });
    setRelationsForFilter((state) => ({ ...state, [name]: option }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);
    applyFilter({
      ...filter,

      from: filter?.from,
      to: filter?.to,
      cpt_id: filter?.cpt_id,
      icd_id: filter?.icd_id,
      provider_id: filter?.provider_id?.id,
      office_id: filter?.office_id?.id
    });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    applySavedFilter({
      ...relationsForFilter,
      ...lastFilters
    });
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      hiddenFields={hiddenFields}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      onReset={() => {
        applySavedFilter(initialValues);
      }}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.apptFrom,
          label: filterFieldsLabels[filterFieldsMap.apptFrom],
          field: (
            <DatePicker
              clearable
              name="from"
              label="Appt.From"
              onChange={handleDatePickerChange('from', unixToStartOfDayUnix)}
              className={classes.controlFields}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.apptTo,
          label: filterFieldsLabels[filterFieldsMap.apptTo],
          field: (
            <DatePicker
              clearable
              name="to"
              label="Appt.To"
              onChange={handleDatePickerChange('to', unixToEndOfDayUnix)}
              className={classes.controlFields}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.cptId,
          label: filterFieldsLabels[filterFieldsMap.cptId],
          field: (
            <CPTCodesSelect
              name="cpt_id"
              label="Procedure Code#"
              onChange={handleCptChange}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.icdId,
          label: filterFieldsLabels[filterFieldsMap.icdId],
          field: (
            <IcdCodesSelect
              name="icd_id"
              label="Diagnosis"
              onChange={handleIcdCodeChange}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.providerId,
          label: filterFieldsLabels[filterFieldsMap.providerId],
          field: (
            <UsersSelect
              name="provider_id"
              label="Provider"
              params={{ role: rolesMap.doctor }}
              onChange={handleUserSelect('provider_id')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.office,
          label: filterFieldsLabels[filterFieldsMap.office],
          field: (
            <OfficesLocationSelect
              name="office_id"
              onChange={handleOfficeChange}
            />
          )
        }
      ]}
    />
  );
};
