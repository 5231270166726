import { useContext, useEffect } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { PageBody } from '../../../../../components/Page';
import { TablePagination } from '../../../../../components/TablePagination';
import { objectKeysToMap } from '../../../../../helpers/objectKeysToMap';
import { PrescriptionsContext } from '../PrescriptionsProvider';
import { Header } from './Header';
import { Row } from './Row';

export const minRowHeight = 52;
export const columnsWidths = {
  name: 200,
  patient: 170,
  office: 200,
  provider: 200,
  date: 120,
  actions: 50
};

export const columnsMap = objectKeysToMap(columnsWidths);

export const List = ({ hiddenColumns = [] }) => {
  const { isFetched, meta, prescriptions, resetPrescriptions } = useContext(PrescriptionsContext);

  useEffect(() => {
    resetPrescriptions();
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => (!prescriptions.length ? (
          <Box p={3}>
            <Typography align="center">No prescriptions found</Typography>
          </Box>
        ) : (
          <>
            <Header hiddenColumns={hiddenColumns} />

            <PageBody disablePaddings>
              <MuiList disablePadding>
                {prescriptions.map((prescription) => (
                  <Row
                    key={prescription.id}
                    prescription={prescription}
                    hiddenColumns={hiddenColumns}
                  />
                ))}
              </MuiList>
            </PageBody>

            <TablePagination borderTop pagination={meta} onChange={resetPrescriptions} />
          </>
        ))}
      />
    </Box>
  );
};
