export const styles = ({ spacing, breakpoints }) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    padding: spacing(2),

    [breakpoints.down(breakpoints.values.md)]: {
      padding: spacing(1)
    }
  },

  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))',
    gridGap: spacing(2),
    marginBottom: spacing(2)
  }
});
