export const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: spacing(1, 2),
    height: 100,
    border: `1px dashed ${palette.grey[200]}`,
    backgroundColor: palette.grey[25]
  },

  mainContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  balanceTotal_darkgreen: {
    color: palette.darkgreen
  },

  balanceTotal_red: {
    color: palette.pink
  }
});
