import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { columnsWidths } from '../Payments';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = () => {
  const classes = useStyles();

  return (
    <ListRow header className={classes.mainContent}>
      <ListRowCell grow flexBasis={columnsWidths.number}>
        Inv. #
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.due_on}>
        Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount}>
        Amount
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        Total
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
