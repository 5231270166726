import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { makeStyles, Typography, Tooltip, Box, IconButton, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TimerIcon from '@material-ui/icons/Timer';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { TaskPreviewContext } from '../../../../../components/tasks/TasksProvider';
import { stopPropagation } from '../../../../../helpers/dom';
import { getUserFullName, getUserNameAbbr } from '../../../../../helpers/users';
import { setTasksLastGlobalAction } from '../../../../../store/globalActions';
import * as taskActionTypes from '../../../../../components/tasks/TasksProvider/types';
import { useModal } from '../../../../../components/ModalsProvider';
import { LogTimeModal } from '../../../../../components/LogTime';
import { Avatar } from '../../../../../components/Avatar';
import { Popper } from '../../../../../components/Popper';
import { Fab } from '../../../../../components/Fab';
import { PlayButton } from '../../MainWindow/PlayButton';
import { PreviewTaskMember } from '../../PreviewTaskMember';
import { TimeTracksModal } from '../../TimeTracksModal';
import { Members } from '../../Members';
import { PreviewTaskDate } from '../PreviewTaskDate';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PreviewRightSidebar = ({ isStaticPage, handleToggleRightSidebar = () => {} }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { selectedTask, setSelectedTask } = useContext(TaskPreviewContext);

  const onTimeTrackAdd = (track) => {
    const logged_time = ~~(selectedTask.logged_time + track.time);
    dispatch(setTasksLastGlobalAction({
      type: taskActionTypes.UPDATE_TASK_IN_LIST,
      payload: { ...selectedTask, logged_time }
    }));
  };

  const openTimeTracksModal = () => {
    openModal(TimeTracksModal, {
      payload: { task: selectedTask },
      onModalResolved: (track) => {
        onTimeTrackAdd(track);
      }
    });
  };

  const openLogTimeModal = () => {
    openModal(LogTimeModal, {
      payload: {
        initialValues: {
          time_track_model: 'task',
          time_track_model_id: selectedTask.id
        }
      },
      onModalResolved: (track) => {
        onTimeTrackAdd(track);
      }
    });
  };

  return (
    <>
      <div className={cn(classes.preview, isStaticPage && classes.preview_static)}>
        {isMobile &&
          <Box py={1}>
            <IconButton color="primary" onClick={handleToggleRightSidebar}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        }

        <div className={classes.preview__owner}>
          <Typography className={classes.preview__title}>
            Creator
          </Typography>

          {!!selectedTask?.created_by ? (
            <Popper
              placement="bottom-end"
              arrow={false}
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <Fab
                    size="small"
                    buttonRef={anchorRef}
                    onClick={stopPropagation(handleToggle)}
                    className={classes.assignments__button}
                  >
                    <Avatar
                      src={selectedTask?.created_by?.profile?.avatar || selectedTask?.created_by?.avatar}
                      alt={selectedTask?.created_by && getUserNameAbbr(selectedTask?.created_by)}
                    />
                  </Fab>
                )
              }
            >
              {({ handleClose }) => (
                <PreviewTaskMember
                  isDeletable
                  onClose={handleClose}
                  user={selectedTask.created_by}
                  task={selectedTask}
                />
              )}
            </Popper>
          ) : (
            <Avatar />
          )}

          <Typography className={classes.userName}>
            {selectedTask.created_by && getUserFullName(selectedTask.created_by)}
          </Typography>
        </div>

        <div className={classes.preview__owner}>
          <Typography className={classes.preview__title}>
            Owner
          </Typography>

          {selectedTask.owner ?
            <Popper
              placement="bottom-end"
              arrow={false}
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <Fab
                    size="small"
                    buttonRef={anchorRef}
                    onClick={stopPropagation(handleToggle)}
                    className={classes.assignments__button}
                  >
                    <Avatar
                      src={selectedTask?.owner?.profile?.avatar || selectedTask?.owner?.avatar}
                      alt={selectedTask?.owner && getUserNameAbbr(selectedTask?.owner)}
                    />
                  </Fab>
                )
              }
            >
              {({ handleClose }) => (
                <PreviewTaskMember
                  isDeletable
                  onClose={handleClose}
                  user={selectedTask?.owner}
                  task={selectedTask}
                />
              )}
            </Popper>
            :
            <Avatar
              src={selectedTask?.owner?.profile?.avatar || selectedTask?.owner?.avatar}
              alt={selectedTask.owner && getUserNameAbbr(selectedTask.owner)}
            />
          }

          <Typography className={classes.userName}>
            {getUserFullName(selectedTask.owner)}
          </Typography>
        </div>
      </div>

      <div className={cn(classes.preview, isStaticPage && classes.preview_static)}>
        <Members task={selectedTask} users={selectedTask.users} setInitialData={setSelectedTask} />
      </div>

      <div className={cn(classes.preview, isStaticPage && classes.preview_static)}>
        <PreviewTaskDate task={selectedTask} />
      </div>

      <div className={cn(classes.preview, isStaticPage && classes.preview_static)}>
        <div className={classes.preview__trackerContent}>
          <Typography className={classes.preview__tracker}>
            TRACKERS
          </Typography>

          <Tooltip title="Add Timetrack">
            <Fab
              color="primary"
              onClick={openLogTimeModal}
            >
              <AddIcon fontSize="small" />
            </Fab>
          </Tooltip>


          <PlayButton task={selectedTask} />
        </div>

        <Tooltip title="View Timetracks">
          <Fab
            className={classes.preview__icon}
            color="primary"
            onClick={openTimeTracksModal}
          >
            <TimerIcon />
          </Fab>
        </Tooltip>
      </div>
    </>
  );
};
