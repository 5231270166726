import { MenuItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { getUserFullName, getUserNameAbbr } from '../../../../helpers/users';
import { Avatar } from '../../../Avatar';

export const Option = ({ children, ...props }) => {
  const user = children.profile || children;
  const isUser = typeof children !== 'string';

  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isSelected}
      component="div"

      {...props.innerProps}
    >
      {isUser &&
        <ListItemAvatar>
          <Avatar src={user?.avatar} alt={getUserNameAbbr(user)} />
        </ListItemAvatar>
      }

      <ListItemText
        primary={isUser ? getUserFullName(user) : children}
        primaryTypographyProps={{ noWrap: true, component: 'div' }}
      />
    </MenuItem>
  );
};
