import { api } from '../api';

export const fetchTimer = () => {
  return api.get('/work-clocks/active');
};

export const fetchTrack = (id) => {
  return api.get(`/work-clocks/${id}`).then(({ data }) => data);
};

export const startTimer = (payload) => {
  return api.post('/work-clocks/start', payload).then(({ data }) => data.data);
};

export const createTimer = (payload) => {
  return api.post('/work-clocks', payload).then(({ data }) => data.data);
};

export const updateTimer = (payload) => {
  return api.put(`/work-clocks/${payload.id}`, payload).then(({ data }) => data.data);
};

export const stopTimer = (payload) => {
  return api.post('/work-clocks/stop', payload).then(({ data }) => data.data);
};

export const deleteTimer = (id) => {
  return api.delete(`/work-clocks/${id}`).then(({ data }) => data);
};
