import { useContext, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../../helpers/dom';
import { AddressLink } from '../../../../../../components/AddressLink';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Contact } from '../../../../../../components/Contact';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { Popper } from '../../../../../../components/Popper';
import { Loader } from '../../../../../../components/Loader';
import { IconButton } from '../../../../../../components/IconButton';
import { Members } from '../../../../../../components/Members';
import { PreviewCompanyModal } from '../../../Companies/PreviewCompanyModal';
import { OfficePreviewModal } from '../../OfficePreviewModal';
import { OfficesContext } from '../../OfficesContext';
import { OfficesMenu } from '../../OfficesMenu';
import { columnsWidths, widthBreakpointLarge, widthBreakpointMedium, widthBreakpointSmall } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  item: office = {},
  offices,
  width,
  isLoaded,
  recalculateHeight
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { selectedIDs, toggleItemSelection } = useContext(OfficesContext);
  const isSelected = selectedIDs.indexOf(office.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(office.id);
  };

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: office?.company?.id }
    });
  };

  const openPreviewOfficeModal = () => {
    openModal(OfficePreviewModal, {
      payload: { id: office.id }
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, offices.length ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow className={classes.root} onClick={stopPropagation(openPreviewOfficeModal)}>
      <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />

      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap title={office.name}>
          {office.name}
        </Typography>
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.name_company}>
        <CompanyLink
          company={office?.company}
          onClick={stopPropagation(openPreviewCompanyModal)}
        />
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.address}>
          <AddressLink
            noWrap
            stopPropagation
            name={office.name}
            address={office.full_address}
            lat={office.lat}
            lng={office.lng}
          >
            {office.full_address || '-'}
          </AddressLink>
        </ListRowCell>
      }

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.address}>
          <AddressLink
            noWrap
            stopPropagation
            address={office.billing_full_address}
            lat={office.billing?.lat}
            lng={office.billing?.lng}
          >
            {office.billing_full_address || '-'}
          </AddressLink>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.email}>
        <Contact
          noWrap
          color="primary"
          type="mailto"
          contact={office.email}
        />
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.phone}>
          <Contact
            type="tel"
            contact={office.phone}
          />
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.office_contacts}>
        <Members users={office.users || []} />
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <OfficesMenu office={office} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
