import { useSnackbar } from 'notistack';
import { Dialog, Typography, Button } from '@material-ui/core';
import { ModalBody, ModalFooter } from '../../../../../components/Modal';
import * as emailsApi from '../../../../../api/emails';

export const SaveEmailToDraftsModal = ({
  DialogProps,
  payload: { config },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = () => {
    emailsApi.saveEmailToDrafts(config).then(() => {
      enqueueSnackbar('Messages saved to drafts', { variant: 'success' });
      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Messages not saved to drafts', { variant: 'error' });
      handleModalReject();
    });
  };

  return (
    <Dialog maxWidth="xs" {...DialogProps}>
      <ModalBody>
        <Typography>Save this message to drafts?</Typography>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleModalReject}>
          Cancel
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </ModalFooter>
    </Dialog>
  );
};
