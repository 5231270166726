import { api } from '../../api';

export const fetchPrescriptions = (config) => {
  return api.get('/patient-prescriptions', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchPrescription = (id, config) => {
  return api.get(`/patient-prescriptions/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createPrescription = (data) => {
  return api.post('/patient-prescriptions', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updatePrescription = ({ id, ...data }) => {
  return api.put(`/patient-prescriptions/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deletePrescription = (id) => {
  return api.delete(`/patient-prescriptions/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const generatePDF = (id) => {
  return api.get(`/patient-prescriptions/pdf/${id}`, { responseType: 'blob' })
    .then((data) => data)
    .catch((error) => { throw error; });
};
