import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { InputAdornment } from '@material-ui/core';
import * as appointmentsApi from '../../../../api/appointments';
import { ArrivalStatusesSelect } from '../../../FormField/selects/ArrivalStatusesSelect';
import { Loader } from '../../../Loader';

export const ArrivalStatus = ({ disableUpdate = false, appointment, onUpdate = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ arrivalStatus, setArrivalStatus ] = useState(appointment.arrival_status);
  const [ isLoading, setIsLoading ] = useState(false);

  const handleChange = (option) => {
    setArrivalStatus(option);

    if (option && option?.id !== arrivalStatus?.id) {
      setIsLoading(true);

      appointmentsApi.updateAppointment({
        id: appointment.id,
        arrival_status_id: option?.id
      }).then((appointment) => {
        enqueueSnackbar('Appointment successfully updated', { variant: 'success' });
        onUpdate(appointment);
      }).catch(() => {
        setArrivalStatus(appointment.arrival_status);
        enqueueSnackbar('Appointment not updated', { variant: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    setArrivalStatus(appointment.arrival_status);
  }, [ appointment.arrival_status ]);

  return (
    <ArrivalStatusesSelect
      withoutFormik
      disabled={disableUpdate || isLoading}
      label={false}
      name="arrival_status_id"
      value={arrivalStatus}
      TextFieldProps={{
        InputProps: {
          endAdornment: isLoading && (
            <InputAdornment position="end">
              <Loader inset={false} size={16} thickness={2} />
            </InputAdornment>
          )
        }
      }}
      onChange={handleChange}
    />
  );
};
