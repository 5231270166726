import { createContext, useEffect, useReducer, useRef } from 'react';
import { api } from '../../../../../api';
import * as printerFilesApi from '../../../../../api/printers';
import * as types from './types';
import { initialState } from './initialState';
import { reducer } from './reducer';

export const PrintersConfigurationContext = createContext(null);

export const PrintersConfigurationProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    filter,
    isFetched,
    isFetching,
    pagination,
    printers
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchPrinters = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_PRINTERS_REQUEST });

    printerFilesApi.fetchPrinters({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_PRINTERS_SUCCESS, payload: data });
    });
  };

  const addPrinter = (printer) => {
    dispatch({ type: types.ADD_PRINTER, payload: printer });
  };

  const deletePrinters = (printersIDs) => {
    dispatch({ type: types.DELETE_PRINTERS, payload: printersIDs });
  };

  const updatePrinter = (printer) => {
    dispatch({ type: types.UPDATE_PRINTER, payload: printer });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchPrinters({ page: filter.page + 1 });
    }
  };

  const resetPrinters = (newFilter) => {
    dispatch({ type: types.RESET_PRINTERS, payload: newFilter });

    fetchPrinters({ page: 1, ...newFilter });
  };

  const providerValue = {
    isFetched,
    isFetching,
    printers,
    filter,
    pagination,

    resetPrinters,
    fetchPrinters,
    addPrinter,
    updatePrinter,
    deletePrinters,
    loadNextPage
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    resetPrinters();
  }, []);

  return (
    <PrintersConfigurationContext.Provider value={providerValue}>
      {children}
    </PrintersConfigurationContext.Provider>
  );
};
