import moment from 'moment';
import { Yup } from '../../../utils/validation';

export const getValidationSchema = () => Yup.object().shape({
  user_id: Yup.mixed().nullable().required(),
  type: Yup.string().nullable().required(),
  note: Yup.string().nullable().max(1000),
  started_at: Yup.date().format('X').nullable().required()
    .when('finished_at', (finishedAt, schema) => {
      return moment(finishedAt).isValid()
        ? schema.max(finishedAt, 'Start date must be earlier than end')
        : schema;
    }),
  finished_at: Yup.date().format('X').nullable()
    .max(moment(), 'End date must be earlier than now')
});
