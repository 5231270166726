const accidentTypes = {
  car_accident: 'car_accident',
  slip_and_fall: 'slip_and_fall',
  other: 'other'
};

export const accidentTypesTitles = {
  [accidentTypes.car_accident]: 'Car accident',
  [accidentTypes.slip_and_fall]: 'Slip and fall',
  [accidentTypes.other]: 'Other'
};

export const accidentTypesTitlesMap = {
  [accidentTypesTitles.car_accident]: 'Car accident',
  [accidentTypesTitles.slip_and_fall]: 'Slip and fall',
  [accidentTypesTitles.other]: 'Other'
};

export const typeOfAccidentOptions = [
  { value: accidentTypesTitles.car_accident, label: accidentTypesTitles.car_accident },
  { value: accidentTypesTitles.slip_and_fall, label: accidentTypesTitles.slip_and_fall },
  { value: accidentTypesTitles.other, label: accidentTypesTitles.other }
];
