import { Box, Grid, Typography } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiShieldAccountOutline } from '@mdi/js';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { Date } from '../../../../../../../components/Date';
import { SvgIcon } from '../../../../../../../components/SvgIcon';
import { UserLink } from '../../../../../../../components/UserLink';
import { getUserFullName } from '../../../../../../../helpers/users';

export const InsuranceInfo = ({ claim: { adjuster, insurance } }) => {
  return (
    <ContentCard
      fullHeight
      disableScrollbars
      variant="outlined"
      color="info"
      title="Insurance Info"
      leftActions={[
        <Box mr={1}>
          <SvgIcon color="info"><Icon path={mdiShieldAccountOutline} /></SvgIcon>
        </Box>
      ]}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={5}>
          <Typography color="textSecondary">Primary Insurance</Typography>
        </Grid>

        <Grid item xs={7}>
          <CompanyLink
            size="md"
            variant="h5"
            company={insurance?.insurance_company}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Typography color="textSecondary">Insurance ID</Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">{insurance?.insuranceId || '-'}</Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography color="textSecondary">Phone</Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">{insurance?.insurance_company?.phone || '-'}</Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography color="textSecondary">Insured Name</Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">{getUserFullName(insurance?.subscriber_info) || '-'}</Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography color="textSecondary">Financial Class</Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {insurance?.financial_class?.code} - {insurance?.financial_class?.description}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography color="textSecondary">Effective Date</Typography>
        </Grid>

        <Grid item xs={7}>
          <Date variant="h5" iconFontSize="small" date={insurance?.effective_day} />
        </Grid>

        <Grid item xs={5}>
          <Typography color="textSecondary">Expiration Date</Typography>
        </Grid>

        <Grid item xs={7}>
          <Date variant="h5" iconFontSize="small" date={insurance?.expiration_day} />
        </Grid>

        <Grid item xs={5}>
          <Typography color="textSecondary">Adjuster</Typography>
        </Grid>

        <Grid item xs={7}>
          <UserLink size="md" variant="h5" user={adjuster}/>
        </Grid>
      </Grid>
    </ContentCard>
  );
};
