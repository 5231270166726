import moment from 'moment';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormControl, FormLabel, FormControlLabel, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { Divider } from '../../../../../components/Divider';
import { usersRolesOptions } from '../../../../../dataMaps';
import {
  RadioGroup,
  Radio,
  TextField,
  KeyboardDatePicker,
  SsnField
} from '../../../../../components/FormField';

export const MainContent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    usersRolesOptions.forEach((item) => (
      item.value === values.role && setFieldValue('role', item)
    ));
  }, []);

  return (
    <>
      <Grid item xs={isMobile ? 12 : 6}>
        <TextField
          required
          name="first_name"
          label="First name"
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 6}>
        <TextField
          required
          name="last_name"
          label="Last name"
        />
      </Grid>

      <Grid item xs={6}>
        <KeyboardDatePicker
          required
          name="birthday"
          label="DOB"
          maxDate={moment().unix()}
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item xs={6}>
        <SsnField
          name="ssn"
          label="SSN"
        />
      </Grid>

      {isMobile && <Divider type="dashed" gutter={2} />}

      <Grid item xs={isMobile ? 12 : 6}>
        <FormControl required>
          <FormLabel>Gender</FormLabel>

          <RadioGroup row name="gender">
            <FormControlLabel
              label="Male"
              value="male"
              control={<Radio color="primary" name="gender" />}
            />

            <FormControlLabel
              label="Female"
              value="female"
              control={<Radio color="primary" name="gender" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};
