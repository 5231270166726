import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormControlLabel, Grid, Hidden } from '@material-ui/core';
import { Checkbox, KeyboardDatePicker } from '../../../FormField';

export const DoiForm = ({ disableHidden = false }) => {
  const { values, setFieldValue } = useFormikContext();
  const [ doi, setDoi ] = useState(values.doi);

  useEffect(() => {
    if (values.is_range) {
      setFieldValue('doi_string', null);
      setFieldValue('doi', null);
    } else {
      setFieldValue('doi', doi || values.doi_from);
    }
  }, [ values.is_range ]);

  useEffect(() => {
    setDoi(values.doi);
  }, []);

  return (
    <>
      {values.is_range ? (
        <>
          <Grid item xl={4} md={4}>
            <KeyboardDatePicker
              required
              name="doi_from"
              label="Date of Injury From"
              outputFormat="YYYY-MM-DD"
              placeholder="MM/DD/YYYY"
            />
          </Grid>

          <Grid item xl={4} md={4}>
            <KeyboardDatePicker
              required
              name="doi_to"
              label="Date of Injury"
              outputFormat="YYYY-MM-DD"
              placeholder="MM/DD/YYYY"
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              label="Range"
              control={
                <Checkbox
                  color="primary"
                  name="is_range"
                />
              }
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xl={4} md={4}>
            <KeyboardDatePicker
              required
              name="doi"
              label="Date of Injury"
              outputFormat="YYYY-MM-DD"
              placeholder="MM/DD/YYYY"
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              label="Range"
              control={
                <Checkbox
                  color="primary"
                  name="is_range"
                />
              }
            />
          </Grid>

          {!disableHidden && (
            <Hidden only={[ 'sm', 'xs' ]}>
              <Grid item xl={6} md={6} />
            </Hidden>
          )}
        </>
      )}
    </>
  );
};
