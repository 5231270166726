export const styles = ({
  spacing,
  shape: { borderRadius },
  palette,
  shadows
}) => ({
  root: {
    height: '100%',
    marginBottom: spacing(1),
    backgroundColor: palette.common.white,
    borderRadius,
    boxShadow: shadows[2],
    overflow: 'hidden'
  }
});
