export const styles = ({ spacing, palette: { grey, ...palette }, typography: { pxToRem } }) => ({
  root: {
    marginBottom: spacing(2)
  },

  head: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(0.5, 0),
    borderBottom: `1px solid ${palette.divider}`,
    color: grey[500],
    minHeight: 45
  },

  col: {
    display: 'flex',
    alignItems: 'center'
  },

  title: {
    fontSize: pxToRem(14),
    color: palette.success.main
  },

  filesIcon: {
    marginRight: spacing(1),
    fontSize: pxToRem(16)
  },

  button: {
    margin: spacing(0, 1)
  },

  divider: {
    height: 15
  },

  attachments: {
    padding: spacing(1.25, 0),
    display: 'flex'
  },

  attachmentsRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'stretch',
    flexWrap: 'wrap',
    overflow: 'hidden'
  }
});
