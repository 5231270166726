import * as faxApi from '../../../api/sip/fax';
import { Autocomplete } from '../../FormField';

const fetchFaxNumbers = (params) => ({ loadedOptions = [] }) => {
  return faxApi.fetchFaxNumbers({
    params
  }).then((data) => {
    return {
      options: loadedOptions.concat(data)
    };
  });
};

export const FaxNumbersSelect = ({ params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      label="Fax number"
      placeholder="Search fax number..."
      onNeedFetch={fetchFaxNumbers(params)}
      getOptionLabel={(faxNumber) => faxNumber?.callerid}
      getOptionValue={(faxNumber) => faxNumber?.faxinfo_id}
      getOptionSelected={(option, value) => option?.faxinfo_id === value?.faxinfo_id}

      {...props}
    />
  );
};
