import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import * as companiesApi from '../../../../api/companies';
import { Autocomplete } from '../../Autocomplete';

const renderOption = (type) => {
  return (
    <Typography>{type?.name}</Typography>
  );
};

export const CompanyTypesSelect = (props) => {
  const [ types, setTypes ] = useState([]);

  useEffect(() => {
    companiesApi.fetchCompanyTypes().then(setTypes);
  }, []);

  return (
    <Autocomplete
      options={types}
      renderOption={renderOption}
      name="company_type_id"
      label="Practice / Company Type"
      placeholder="Select company type..."
      getOptionLabel={(option) => option?.name}
      getOptionValue={(option) => option?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}

      {...props}
    />
  );
};
