import { useFormikContext } from 'formik';
import {
  Box,
  FormControlLabel,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Grid
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Checkbox, KeyboardDatePicker, TextField } from '../../../../../../components/FormField';
import { styles } from '../GeneralInfo/styles';

const useStyles = makeStyles(styles);

export const DoiForm = ({
  parentIndex = 0,
  name,
  toggleRange = () => {}
}) => {
  const { values } = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <>
      <Box display="flex" alignItems="center" mx={0} mt={2} mb={1}>
        <Typography variant="h3">DOI</Typography>
      </Box>

      <div className={classes.painContent__list}>
        <div className={classes.contentRow}>
          <Box flexGrow={1}>
            <FormControlLabel
              label="Range"
              control={
                <Checkbox
                  color="primary"
                  name={`[${name}][0].is_range`}
                  onChange={toggleRange(parentIndex, 0)}
                />
              }
            />

            <Box display="flex" justifyContent="space-between" alignItems="flex-start" pb={2}>
              {values?.[name][0]?.is_range ? (
                <Grid container spacing={isMobile ? 1 : 2} justify="space-between" alignItems="flex-end">
                  <Grid item xs={isMobile ? 12 : 3}>
                    <KeyboardDatePicker
                      required
                      zeroMinWidth
                      label="From"
                      outputFormat="YYYY-MM-DD"
                      name={`[${name}][0].from`}
                      placeholder="Select a date"
                    />
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 3}>
                    <KeyboardDatePicker
                      required
                      label="To"
                      zeroMinWidth
                      outputFormat="YYYY-MM-DD"
                      name={`[${name}][0].to`}
                      placeholder="Select a date"
                    />
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 3}>
                    <KeyboardDatePicker
                      required
                      label="Statute of Limitations"
                      outputFormat="YYYY-MM-DD"
                      name={`[${name}][0].start_of_limitation`}
                      placeholder="Select a date"
                    />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Box width={isMobile ? '100%' : '45%'}>
                    <KeyboardDatePicker
                      required
                      label="Date"
                      outputFormat="YYYY-MM-DD"
                      name={`[${name}][0].date`}
                      placeholder="Select a date"
                    />
                  </Box>

                  <Box width={isMobile ? '100%' : '45%'}>
                    <KeyboardDatePicker
                      required
                      label="Statute of Limitations"
                      outputFormat="YYYY-MM-DD"
                      name={`[${name}][0].start_of_limitation`}
                      placeholder="Select a date"
                    />
                  </Box>
                </>
              )}
            </Box>

            <TextField
              multiline
              rowsMax={5}
              label={
                <div className={classes.notesIcon}>
                  <NotesIcon fontSize="small"/>

                  {values?.[name][0]?.note ? 'Note' : 'Add note'}
                </div>
              }
              InputProps={{
                className: classes.notesInput
              }}
              name={`[${name}][0].note`}
            />
          </Box>
        </div>
      </div>
    </>
  );
};
