import { useRef, useState } from 'react';
import { Box, Paper, Tabs, Tab, useTheme, useMediaQuery } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { ExpensesContent } from './ExpensesContent';
import { InvoicesContent } from './InvoicesContent';
import { PaymentsContent } from './PaymentsContent';

export const tabsMap = {
  invoices: 'invoices',
  expenses: 'expenses',
  payments: 'payments'
};

export const InvoicesPage = ({
  caseItem,
  invoicesFilterKey,
  expensesFilterKey,
  hiddenExpensesFilterFields,
  hiddenInvoicesFilterFields,
  PaymentsProps
}) => {
  const theme = useTheme();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const [ activeTab, setActiveTab ] = useState(tabsMap.invoices);
  const currentCaseFilter = {
    case_id: caseItem?.id
  };

  const handleTabIndexChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <CustomScrollbars style={{ flexGrow: 1 }}>
      <Box
        ref={rootRef}
        display="flex"
        flexDirection="column"
        height="100%"
        p={isMobile ? 0 : caseItem ? 0 : 2}
      >
        <Paper elevation={0} variant="outlined">
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabIndexChange}
          >
            <Tab value={tabsMap.invoices} label="Medical Bills" />
            <Tab value={tabsMap.expenses} label="Legal Cost" />
            <Tab value={tabsMap.payments} label="Payments" />
          </Tabs>
        </Paper>

        {(activeTab === tabsMap.invoices) &&
          <InvoicesContent
            width={width}
            caseItem={caseItem}
            filter={currentCaseFilter}
            filterKey={invoicesFilterKey}
            hiddenFilterFields={hiddenInvoicesFilterFields}
          />
        }

        {(activeTab === tabsMap.expenses) && (
          <ExpensesContent
            width={width}
            caseID={caseItem?.id || null}
            filter={currentCaseFilter}
            filterKey={expensesFilterKey}
            hiddenFilterFields={hiddenExpensesFilterFields}
          />
        )}

        {(activeTab === tabsMap.payments) && (
          <PaymentsContent
            filter={currentCaseFilter}

            {...PaymentsProps}
          />
        )}
      </Box>
    </CustomScrollbars>
  );
};
