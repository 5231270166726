import moment from 'moment';
import { useFormikContext } from 'formik';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useModal } from '../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../components/users';
import { UserLink } from '../../../../../components/UserLink';
import { Date } from '../../../../../components/Date';

export const PatientInfo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { openModal } = useModal();
  const { values } = useFormikContext();

  const openUserPreview = () => {
    if (values?.patient?.id) {
      openModal(UserPreviewModal, {
        payload: { id: values?.patient?.id }
      });
    }
  };

  return !values?.patient?.id ? (
    <Grid item xs={12}>
      <Typography color="textSecondary" align="center">No patient found</Typography>
    </Grid>
  ) : (
    <>
      <Grid item xs={isMobile ? 4 : 3}>
        <Typography color="textSecondary">Patient name</Typography>
      </Grid>

      <Grid item xs={isMobile ? 8 : 4}>
        <UserLink
          size="md"
          variant="h5"
          user={values.patient}
          onClick={openUserPreview}
        />
      </Grid>

      <Grid item xs={isMobile ? 4 : 2}>
        <Typography color="textSecondary">MRN</Typography>
      </Grid>

      <Grid item xs={isMobile ? 8 : 3}>
        {values?.patient?.medical?.med_rec
          ? <Typography variant="h5">{values?.patient?.medical?.med_rec}</Typography>
          : <Typography color="textSecondary">No info</Typography>
        }
      </Grid>

      <Grid item xs={isMobile ? 4 : 3}>
        <Typography color="textSecondary">DOB</Typography>
      </Grid>

      <Grid item xs={isMobile ? 8 : 4}>
        <Date
          disableMoment
          variant="h5"
          date={moment(values?.patient?.birthday).format('L')}
        />
      </Grid>

      <Grid item xs={isMobile ? 4 : 2}>
        <Typography color="textSecondary">Ext MRN</Typography>
      </Grid>

      <Grid item xs={isMobile ? 8 : 3}>
        {values?.patient?.medical?.ext_med_rec
          ? <Typography variant="h5">{values?.patient?.medical?.ext_med_rec}</Typography>
          : <Typography color="textSecondary">No info</Typography>
        }
      </Grid>

      <Grid item xs={isMobile ? 4 : 3}>
        <Typography color="textSecondary">Address</Typography>
      </Grid>

      <Grid item xs={isMobile ? 8 : 9}>
        {values?.patient?.home?.full_address
          ? <Typography variant="h5">{values?.patient?.home?.full_address}</Typography>
          : <Typography color="textSecondary">No info</Typography>
        }
      </Grid>
    </>
  );
};
