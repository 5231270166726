import { orderDirectionsMap } from '../../FormField/selects/OrderBy';
import { orderByMap } from '../TimeReports/FiltersBar/orderByOptions';

export const initialState = {
  isFetched: false,
  isFetching: false,
  filter: {
    page: 1,
    per_page: 15,
    order_direction: orderDirectionsMap.desc,
    order_by: orderByMap.started_at
  },
  pagination: {
    total: 0,
    last_page: 1
  },
  timeReports: [],
  selectedTimeReportsIDs: [],
  selectedTimeReports: []
};
