import { Typography } from '@material-ui/core';
import * as templatesApi from '../../../api/cases/task-templates';
import { Autocomplete } from '../../FormField';

const fetchCompanies = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return templatesApi.fetchTaskTemplates({
    params: {
      name: search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (template) => {
  return (
    <Typography>{template?.name}</Typography>
  );
};

export const TaskTemplatesSelect = ({ multiple = false, params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      isCreatable={false}
      multiple={multiple}
      placeholder="Search template..."
      onNeedFetch={fetchCompanies(params)}
      renderOption={renderOption}
      getOptionLabel={(template) => template?.name}
      getOptionValue={(template) => template?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}

      {...props}
    />
  );
};
