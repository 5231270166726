import { useFormikContext } from 'formik';
import { FormControl, FormControlLabel, Grid } from '@material-ui/core';
import {
  Editor,
  TextField,
  TimeZoneSelect,
  OfficesLocationSelect,
  ScheduleEventTypesSelect, Switch
} from '../../../FormField';
import { RecurrenceSelect } from '../../../RecurrenceSelect';
import { UsersSelect } from '../../../users';

export const EventTemplateForm = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleEventTypeChange = (option) => {
    setFieldValue('type', option?.data);
  };

  return (
    <>
      <TextField
        required
        name="name"
        label="Template name"
        placeholder="Enter name..."
        margin="dense"
      />

      <FormControl margin="dense">
        <FormControlLabel
          label="Is public"
          control={<Switch name="is_public" color="primary" />}
        />
      </FormControl>

      <TextField
        required
        name="title"
        label="Event title"
        placeholder="Enter title..."
        margin="dense"
      />

      <Editor
        name="description"
        label="Description"
        placeholder="Enter description..."
        margin="dense"
      />

      <ScheduleEventTypesSelect
        isClearable
        name="type_id"
        label="Event type"
        TextFieldProps={{ margin: 'dense' }}
        onChange={handleEventTypeChange}
      />

      {!!values.type?.duration &&
        <FormControl margin="dense">
          <FormControlLabel
            label="Bind event duration to event type"
            control={
              <Switch
                name="is_duration_corresponds_to_type"
                color="primary"
              />
            }
          />
        </FormControl>
      }

      <Grid container spacing={2}>
        <Grid item sm={6}>
          <RecurrenceSelect
            name="recurring_rule"
            label="Recurrence"
            margin="dense"
          />
        </Grid>

        <Grid item sm={6}>
          <TimeZoneSelect name="timezone" margin="dense" />
        </Grid>
      </Grid>

      <UsersSelect
        multiple
        name="users"
        label="Select users"
        margin="dense"
      />

      <OfficesLocationSelect
        name="office_id"
        label="Office"
        margin="dense"
      />

      <FormControl margin="dense">
        <FormControlLabel
          label="Send them e-mail with notification"
          control={
            <Switch
              name="email_notification"
              color="primary"
            />
          }
        />
      </FormControl>
    </>
  );
};
