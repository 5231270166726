import { useContext, useRef } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../../../components/CustomScrollbars';
import { ErrorBoundary } from '../../../../../../../../components/ErrorBoundary';
import { Loader } from '../../../../../../../../components/Loader';
import { NegotiationsContext } from '../NegotiationsProvider';
import { styles } from './styles';
import { Card } from './Card';

const useStyles = makeStyles(styles);

export const List = () => {
  const classes = useStyles();
  const { isFetched, negotiates } = useContext(NegotiationsContext);
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={2}>
        <Loader p={2} loading={!isFetched} render={
          () => !negotiates.length ? (
            <Box p={2}>
              <Typography align="center">No found</Typography>
            </Box>
          ) : (
            <div className={classes.cards}>
              {negotiates.map((negotiation) => (
                <ErrorBoundary key={negotiation.id}>
                  <Card negotiation={negotiation}/>
                </ErrorBoundary>
              ))}
            </div>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
