import { useContext, useEffect, useRef, useState } from 'react';
import { groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  Box,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography
} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseIcon from '@material-ui/icons/Close';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import SendIcon from '@material-ui/icons/Send';
import { api } from '../../../api';
import * as chatsApi from '../../../api/chat';
import { useFileAttachments } from '../../../app/Dashboard/files-common/useFileAttachments';
import { jsonToFormData } from '../../../helpers/jsonToFormData';
import { enqueueSnackbar } from '../../../store/snackbars';
import { Echo } from '../../../utils/echo';
import { Divider } from '../../Divider';
import { TextField } from '../../FormField';
import { Loader } from '../../Loader';
import { ChatThreadContext, actionTypesMap } from '../ChatThreadContext';
import { ThreadMessagesContext } from '../ThreadMessagesContext';
import { MessageContent } from '../MessageContent';
import { AddAttachmentsButton } from './AddAttachmentsButton';
import { Attachments } from './Attachments';
import { InputComponent } from './InputComponent';
import { MultiActions } from './MultiActions';
import { Reply } from './Reply';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const visibleActions = [ actionTypesMap.default, actionTypesMap.edit, actionTypesMap.select, actionTypesMap.reply ];

export const MessageInput = ({ threadId }) => {
  const classes = useStyles();
  const [ message, setMessage ] = useState('');
  const currentUser = useSelector(({ profile }) => profile.user);
  const [ isMessageSending, setIsMessageSending ] = useState(false);
  const { addMessage, updateMessage } = useContext(ThreadMessagesContext);
  const {
    forwardedMessage,
    currentAction,
    resetCurrentAction,
    messageToEdit,
    resetMessageToEdit,
    setForwardedMessage
  } = useContext(ChatThreadContext);
  const cancelRequest = useRef(() => {});
  const mentionSuggestionsPortalHostRef = useRef();
  const fileAttachments = useFileAttachments({
    multiple: true
  });

  const sendMessage = () => {
    const { file_ids, files } = groupBy(fileAttachments.media, ({ id }) => id ? 'file_ids' : 'files');

    if (isMessageSending) {
      return;
    }

    const data = {
      body: message,
      thread: {
        id: threadId
      },
      parent: forwardedMessage && {
        id: forwardedMessage.body || forwardedMessage.files?.length
          ? forwardedMessage.id
          : forwardedMessage.parent?.id
      },
      file_ids: file_ids?.map(({ id }) => id) || null,
      files: files || null
    };

    cancelRequest.current();
    setIsMessageSending(true);

    chatsApi.createMessage(files?.length ? jsonToFormData(data) : data, {
      cancelToken: new api.CancelToken((cancel) => cancelRequest.current = cancel)
    }).then((data) => {
      addMessage(data);
      setMessage('');
      resetCurrentAction();

      if (fileAttachments.media) {
        fileAttachments.clear();
      }

      if (forwardedMessage) {
        setForwardedMessage(null);
      }
    }).catch(() => {
      enqueueSnackbar('Something went wrong, message not sent', { variant: 'error' });
    }).finally(() => {
      setIsMessageSending(false);
    });
  };

  const submitEditing = () => {
    const data = {
      ...messageToEdit,

      body: message,
      parent: forwardedMessage && {
        id: forwardedMessage.body ? forwardedMessage.id : forwardedMessage.parent?.id
      }
    };

    if (isMessageSending) {
      return;
    }

    cancelRequest.current();
    setIsMessageSending(true);

    chatsApi.updateMessage(data, {
      cancelToken: new api.CancelToken((cancel) => cancelRequest.current = cancel)
    }).then((data) => {
      enqueueSnackbar('Message edited!', { variant: 'success' });
      updateMessage(data);
      setMessage('');
    }).catch(() => {
      enqueueSnackbar('You cannot do that!', { variant: 'error' });
    }).finally(() => {
      resetCurrentAction();
      resetMessageToEdit();
      setIsMessageSending(false);
    });
  };

  const discardEditing = () => {
    setMessage('');
    resetCurrentAction();
  };

  const handleFormSubmit = () => {
    if ([ actionTypesMap.default, actionTypesMap.reply ].includes(currentAction)) {
      sendMessage();
    } else if (currentAction === actionTypesMap.edit) {
      submitEditing();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();

    if (event.shiftKey && message?.trim()) {
      setMessage(message + '\n');
    } else {
      handleFormSubmit();
    }
  };

  const handleTextRecognized = (recognizedText) => {
    setMessage((message) => message + recognizedText);
  };

  const handleFieldChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    const threadsChannel = Echo.private(`threads.${threadId}`);

    threadsChannel.whisper('typing', {
      user: currentUser.id,
      typing: !!message
    });

    return () => {
      threadsChannel.whisper('typing', {
        user: currentUser.id,
        typing: false
      });
    };
  }, [ threadId, message ]);

  useEffect(() => {
    if (currentAction === actionTypesMap.edit) {
      setMessage(messageToEdit.body);
    }
  }, [ messageToEdit ]);

  useEffect(() => {
    return () => {
      cancelRequest.current();
    };
  }, []);

  return visibleActions.includes(currentAction) && (
    <div className={classes.root} ref={mentionSuggestionsPortalHostRef}>
      <div className={classes.body}>
        {(currentAction === actionTypesMap.edit) &&
          <>
            <Box display="flex" alignItems="center" flexGrow={1} mb={1}>
              <Box p={1}>
                <CreateOutlinedIcon className={classes.actionIcon} />
              </Box>

              <Box pl={2}>
                <Typography color="primary">
                  Edit Message
                </Typography>

                {!!messageToEdit.body &&
                  <Typography variant="body2" className={classes.editMessageContent}>
                    <MessageContent message={messageToEdit.body} />
                  </Typography>
                }
              </Box>

              <Box ml="auto">
                <IconButton onClick={discardEditing}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>

            <Divider/>
          </>
        }

        {(currentAction === actionTypesMap.select) ? (
          <MultiActions />
        ) : (
          <>
            {(currentAction === actionTypesMap.reply) &&
              <Reply />
            }

            {!!fileAttachments.media?.length &&
              <Attachments
                attachments={fileAttachments.media}
                onFileRemove={fileAttachments.removeFile}
              />
            }

            <Prompt
              when={!!fileAttachments.media?.length}
              message={() => 'Attachments will not be uploaded. Are you sure you want to leave the chat?'}
            />

            <TextField
              fullWidth
              multiline
              withoutFormik
              rowsMax={5}
              value={message}
              style={{ wordBreak: 'break-word' }}
              placeholder="Write a message ..."
              InputProps={{
                disableUnderline: true,
                inputComponent: InputComponent,
                inputProps: {
                  mentionSuggestionsPortalHostRef
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <AddAttachmentsButton fileAttachments={fileAttachments} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {(currentAction === actionTypesMap.edit) &&
                      <IconButton onClick={submitEditing}>
                        <CheckOutlinedIcon />
                      </IconButton>
                    }

                    {[ actionTypesMap.default, actionTypesMap.reply ].includes(currentAction) &&
                      <Loader
                        surface
                        loading={isMessageSending}
                        render={() => (
                          <IconButton
                            disabled={isMessageSending || (!fileAttachments.media?.length && !message.trim().length)}
                            onClick={handleFormSubmit}
                          >
                            <SendIcon />
                          </IconButton>
                        )}
                      />
                    }
                  </InputAdornment>
                )
              }}
              onChange={handleFieldChange}
              onKeyPress={handleKeyPress}
              onTextRecognized={handleTextRecognized}
            />
          </>
        )}
      </div>
    </div>
  );
};
