import { apiModelsMap } from '../../dataMaps/apiModels';

export const eventsParentsTypesOptions = [
  {
    value: apiModelsMap.task,
    label: 'Task'
  },
  {
    value: apiModelsMap.case,
    label: 'Case'
  }
];
