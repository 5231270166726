import { useContext, useEffect } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { ErrorBoundary } from '../../../../../../../components/ErrorBoundary';
import { Loader } from '../../../../../../../components/Loader';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { InvoicesContext } from '../../../InvoicesContext';
import { Card } from './Card';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Grid = ({ hiddenColumns = [], onInvoiceSelect }) => {
  const classes = useStyles();
  const {
    isFetched,
    invoices,
    meta,
    resetInvoices
  } = useContext(InvoicesContext);

  useEffect(() => {
    resetInvoices();
  }, []);

  return (
    <Box p={!!hiddenColumns?.length ? 0 : 2}>
      <Loader loading={!isFetched} render={
        () => !invoices?.length ? (
          <Typography align="center">No invoices found</Typography>
        ) : (
          <>
            <div className={classes.cards}>
              {invoices.map((invoice) => (
                <ErrorBoundary key={invoice.id}>
                  <Card
                    hiddenColumns={hiddenColumns}
                    invoice={invoice}
                    onInvoiceSelect={onInvoiceSelect}
                  />
                </ErrorBoundary>
              ))}
            </div>

            <TablePagination pagination={meta} onChange={resetInvoices} />
          </>
        )}
      />
    </Box>
  );
};
