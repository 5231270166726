import { useEffect, useContext, useRef } from 'react';
import { List as MuiList, Box, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../helpers/hooks/useResizeObserver';
import { objectKeysToMap } from '../../../helpers/objectKeysToMap';
import { InfiniteListLoader } from '../../InfiniteLoader';
import { Scrollbars } from '../../Scrollbars';
import { ClaimsContext } from '../../claims';
import { Loader } from '../../Loader';
import { TableHeader } from './TableHeader';

export const widthBreakpointLarge = 1240;
export const columnsWidths = {
  checkbox: 'auto',
  claimNo: 100,
  patient: 180,
  caseNo: 100,
  payer: 180,
  provider: 180,
  chargeAmount: 150,
  totalAmount: 110,
  claimBalance: 110,
  payoutStatus: 140,
  claimStatus: 100,
  actions: 64
};

export const columnsMap = objectKeysToMap(columnsWidths);

export const ClaimsList = ({ scrollProps = {}, hiddenColumns, Row, RowProps = {} }) => {
  const {
    claims,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    resetClaims
  } = useContext(ClaimsContext);
  const scrollElementRef = useRef();
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });

  useEffect(() => {
    resetClaims();
  }, []);

  return (
    <Box ref={rootRef} display="flex" flexDirection="column" height="100%" overflow="hidden">
      <Loader loading={!isFetched} p={2} render={
        () => !filter.total ? (
          <Box p={3}>
            <Typography align="center">No claims found</Typography>
          </Box>
        ) : (
          <>
            <TableHeader width={width} hiddenColumns={hiddenColumns} ListRowProps={RowProps?.ListRowProps} />

            <Scrollbars viewRef={scrollElementRef} {...scrollProps}>
              <MuiList disablePadding>
                <InfiniteListLoader
                  items={claims}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowProps={{ hiddenColumns, width, ...RowProps }}
                  RowComponent={Row}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </Scrollbars>
          </>
        )}
      />
    </Box>
  );
};
