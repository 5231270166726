import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_FRAGMENTS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_FRAGMENTS_SUCCESS]: (
    { filter, fragments, ...state },
    { data: newFragments, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      fragments: page > 1 ?
        fragments.filter(({ id }) => !newFragments.find((video) => id === video.id)).concat(newFragments) : newFragments
    };
  },

  [types.RESET_FRAGMENTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.DELETE_FRAGMENT]: (state, { id }) => {
    return {
      ...state,
      fragments: state.fragments.filter((item) => item.id !== id)
    };
  },

  [types.ADD_FRAGMENT]: (state, payload) => {
    const { pagination, filter, fragments, ...otherState } = state;

    const total = pagination.total + 1;
    const page = Math.ceil((fragments.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      fragments: fragments.concat(payload)
    };
  }
});
