import { api } from '../api';

export const fetchIncomes = (config = {}) => {
  return api.get('/incomes', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createIncome = (incomesData) => {
  return api.post('/incomes', incomesData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchIncome = (id) => {
  return api.get(`/incomes/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateIncome = (incomesData) => {
  return api.put(`/incomes/${incomesData.id}`, incomesData)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteIncome = (id) => {
  return api.delete(`/incomes/${id}`).then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchTimeTracks = (config = {}) => {
  return api.get('/incomes/time-tracks', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
