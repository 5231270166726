import {
  initialValues as baseInitialValues
} from '../../../../app/Dashboard/CodesPage/Appointment/AppointmentBooks/initialValues';

export const initialValues = {
  appointment_book_name: null,
  provider_id: null,
  office_id: null,
  work_schedules: baseInitialValues.work_schedules
};
