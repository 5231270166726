import moment from 'moment';
import { useContext } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { CardItem, CardHeader } from '../../../../../../components/Cards';
import { ColorPreview } from '../../../../../../components/ColorPreview';
import { CodesMenu } from '../../CodesMenu';
import { AppointmentTypesContext } from '../AppointmentTypesContext';

export const Card = ({ item: appointmentType = {}, baseUrl = null }) => {
  const appointmentTypesContext = useContext(AppointmentTypesContext);
  const isSelected = appointmentTypesContext.selectedCodesIDs.includes(appointmentType.id);

  const toggleCodeSelected = () => {
    appointmentTypesContext.toggleCodeSelected(appointmentType);
  };

  const toggleActiveAppointmentType = () => {
    const data = { ...appointmentType, is_active: !appointmentType.is_active };

    appointmentTypesContext.toggleActiveAppointmentType(data);
  };

  const deleteAppointmentType = () => {
    appointmentTypesContext.deleteAppointmentType(appointmentType);
  };

  return (
    <CardItem selected={isSelected} py={1} px={2}>
      <CardHeader
        disablePopper
        disableCheckBox={!!baseUrl}
        disableCardMenu={!baseUrl}
        isSelected={isSelected}
        onItemSelect={toggleCodeSelected}
        alignItems="center"
        menuComponent={CodesMenu}
        menuComponentProps={{
          isActive: appointmentType.is_active,
          url: `${baseUrl}/appointment-types/${appointmentType.id}/edit`,
          onToggle: toggleActiveAppointmentType,
          onDelete: deleteAppointmentType
        }}
      >
        <Box display="flex" alignItems="center">
          <Box width="20" display="flex" alignContent="center" mr={1}>
            <ColorPreview color={appointmentType.color} />
          </Box>

          <Typography noWrap>
            {appointmentType.code}
          </Typography>
        </Box>
      </CardHeader>

      <Grid container spacing={1} component={Box} pt={2}>
        <Grid item xs={12}>
          <Typography>
            {appointmentType.description}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>Duration</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {moment.duration(appointmentType.time, 'seconds').format('h[h] m[min]')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>Claim required</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {appointmentType.claim_required ? 'Yes' : 'No'}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>Ordering</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {appointmentType.ordering}
          </Typography>
        </Grid>
      </Grid>
    </CardItem>
  );
};
