import { Yup } from '../../../../../../../../utils/validation';
import { contactTypesMap } from './contactTypesOptions';

export const validationSchema = Yup.object().shape({
  contact_type: Yup.string().nullable().required(),
  is_checked: Yup.boolean(),
  phone: Yup.string().nullable().required(),
  relationship: Yup.string().nullable().when('contact_type', {
    is: (contact_type) => contact_type === contactTypesMap.contact,
    then: Yup.string().nullable().required()
  }),
  position: Yup.string().nullable().when('contact_type', {
    is: (contact_type) => contact_type === contactTypesMap.employer,
    then: Yup.string().nullable().required()
  }),
  user_id: Yup.mixed().when('is_checked', {
    is: (val) => !val,
    then: Yup.mixed().nullable().required()
  }),
  first_name: Yup.string().nullable().when('is_checked', {
    is: (val) => val,
    then: Yup.string().nullable().required()
  }),
  last_name: Yup.string().nullable().when('is_checked', {
    is: (val) => val,
    then: Yup.string().nullable().required()
  })
});
