import { useContext } from 'react';
import { isEmpty } from 'lodash';
import {
  List,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiAccountArrowLeftOutline, mdiBriefcaseCheckOutline } from '@mdi/js';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { downloadFile as download } from '../../../../helpers/files';
import { useModal } from '../../../ModalsProvider';
import { CallReportsContext } from '../../CallReportsProvider';
import { parentTypesMap } from '../../CopyToModal/parentTypeOptions';
import { CopyToModal } from '../../CopyToModal';

export const CallReportsMenu = ({ callReport, onClose = () => {} }) => {
  const { openModal } = useModal();
  const { defaultFilter, deleteParent, resetCallReports } = useContext(CallReportsContext);

  const downloadFile = () => {
    download({
      src: callReport?.files[0]?.url,
      name: callReport?.original_filename || callReport?.name
    });
  };

  const copyReport = (parentType) => () => {
    openModal(CopyToModal, {
      payload: { ids: [ callReport?.id ], parent_type: parentType },
      onModalResolved: resetCallReports,
      onModalReject: onClose
    });
  };

  const onDelete = () => {
    deleteParent({ ids: [ callReport.id ] });
  };

  return (
    <List>
      <MenuItem onClick={copyReport(parentTypesMap.case)}>
        <ListItemIcon>
          <Icon path={mdiBriefcaseCheckOutline} size={1} />
        </ListItemIcon>

        <ListItemText primary="Attach to case" />
      </MenuItem>

      <MenuItem onClick={copyReport(parentTypesMap.user)}>
        <ListItemIcon>
          <Icon path={mdiAccountArrowLeftOutline} size={1} />
        </ListItemIcon>

        <ListItemText primary="Attach to user" />
      </MenuItem>

      <MenuItem disabled={!callReport?.files?.length} onClick={downloadFile}>
        <ListItemIcon>
          <DownloadIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Download" />
      </MenuItem>

      {!isEmpty(defaultFilter) && (
        <MenuItem onClick={onDelete}>
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="inherit"/>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      )}
    </List>
  );
};
