import { useContext, useEffect } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { ScrollbarsLoader } from '../../../../../components/ScrollbarsLoader';
import { UserNotificationsContext } from '../UserNotificationsProvider';
import { NotificationItem } from './NotificationItem';

export const NotificationsList = ({ userID }) => {
  const {
    userNotifications,
    pagination,
    isFetching,
    isFetched,
    loadNextPage,
    resetUserNotifications
  } = useContext(UserNotificationsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  useEffect(() => {
    if (userID) {
      resetUserNotifications();
    }
  }, [ userID ]);

  return (
    <Box height="100%" p={isMobile ? 0 : 2}>
      <Loader loading={!isFetched} p={2} render={
        () => (!userNotifications.length ? (
          <Box p={2}>
            <Typography variant="h4" align="center">There is no notifications yet.</Typography>
          </Box>
        ) : (
          <ScrollbarsLoader
            isFetching={isFetching}
            isFetched={isFetched}
            total={pagination.total}
            rowsCount={userNotifications.length}
            onNeedNextPage={loadNextPage}
          >
            {userNotifications.map((notification, index) => (
              <NotificationItem
                key={notification.id}
                index={index}
                notification={notification}
                notifications={userNotifications}
              />
            ))}
          </ScrollbarsLoader>
        ))}
      />
    </Box>
  );
};
