import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormControlLabel, Grid } from '@material-ui/core';
import { TextField, Checkbox } from '../../../../../../../components/FormField';

export const ChildrenContent = ({ index, parentIndex = null, label, item, name = `items[${index}]` }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setFieldValue(`${name}.name`, label);
      setFieldValue(`${name}.test_id`, item.id || item.test_id);
    } else {
      setFieldValue(`${name}.name`, null);
      setFieldValue(`${name}.test_id`, null);
      setFieldValue(`${name}.value`, null);
      setFieldValue(`${name}.standard_range`, null);
    }
  };

  const handleChangeValueField = (event) => {
    if (event.target.value) {
      setFieldValue(`${name}.is_checked`, true);
      setFieldValue(`${name}.name`, label);
      setFieldValue(`${name}.test_id`, item.id || item.test_id);
    }
  };

  useEffect(() => {
    if ((
      parentIndex && values?.lab_tests?.length && !values?.lab_tests[parentIndex].items[index]?.test_id
    ) || (
      !parentIndex && values?.items?.length && !values?.items[index]?.test_id
    )) {
      setFieldValue(`${name}.is_checked`, false);
      setFieldValue(`${name}.value`, null);
      setFieldValue(`${name}.standard_range`, null);
    }
  }, []);

  return (
    <>
      <Grid item sm={3} xs={12}>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              color="primary"
              name={`${name}.is_checked`}
              onChange={handleChange}
            />
          }
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <TextField
          name={`${name}.value`}
          label="Your Value"
          onChange={handleChangeValueField}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <TextField
          name={`${name}.standard_range`}
          label="Standart Range"
          onChange={handleChangeValueField}
        />
      </Grid>
    </>
  );
};
