import { useContext, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce, omit } from 'lodash';
import { Box } from '@material-ui/core';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { UsersSelect } from '../../../../../components/users';
import { saveFilter } from '../../../../../store/lastFilters';
import {
  TextField,
  KeyboardDatePicker,
  TaskStatusesSelect,
  TaskTypesSelect,
  TaskPrioritySelect
} from '../../../../../components/FormField';
import { IconComponent } from '../../../../../components/saved-filters';
import { orderByMap } from '../../../../../components/tasks/TasksProvider/orderByOptions';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { TasksFilterContext } from '../../../../../components/tasks/TasksProvider';
import { initialValues } from '../../../CasesPage/Cases/MainWindow/FiltersBar/initialValues';
import { transformRelationsForFilterToOptions } from './utils';
import { fieldsKeysMap, filterLabels } from './filterKeysMap';
import { CardContent } from './CardContent';
import { List, columnsWidths } from './List';

const MODAL_WIDTH = 1350;

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { viewVariant } = useContext(LayoutContext);
  const { applyFilter, relationsForFilter, setRelationsForFilter } = useContext(TasksFilterContext);
  const hiddenFilterFields = useMemo(() => (
    viewVariant === viewVariantsMap.columns ? hiddenFields.concat(fieldsKeysMap.statuses) : hiddenFields
  ), [ hiddenFields, viewVariant ]);
  const modalWidth = hiddenFilterFields.includes(fieldsKeysMap.statuses) ?
    MODAL_WIDTH - columnsWidths.statuses : MODAL_WIDTH;

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleMultiSelectChange = (name) => (options) => {
    applyFilter({ [name]: options?.map(({ value }) => value) });
    setRelationsForFilter((state) => ({ ...state, [name]: options?.map((option) => option?.data || option) }));
  };

  const handleCasesChange = (cases) => {
    applyFilter({ cases: cases?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, cases }));
  };

  const handleUsersMultiSelect = (name) => (options) => {
    const users = options?.length ? options.map(({ id }) => id) : null;

    applyFilter({ [name]: users });
    setRelationsForFilter((state) => ({ ...state, [name]: options }));
  };

  const handleTaskTypesSelectChange = (types) => {
    const typeIDs = types?.map(({ id }) => id) || null;

    applyFilter({ types: typeIDs });
    setRelationsForFilter((state) => ({ ...state, types: types || null }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(transformRelationsForFilterToOptions(filter));
  };

  useEffect(() => {
    if (viewVariant === viewVariantsMap.list ) {
      applyFilter({ order_by: lastFilters?.order_by || orderByMap.updated_at });
    } else {
      applyFilter({ order_by: orderByMap.position });
    }

    applyFilter(omit({
      order_by: viewVariant === viewVariantsMap.list
        ? lastFilters?.order_by || orderByMap.updated_at
        : orderByMap.position
    }, hiddenFilterFields));

    if (lastFilters) {
      applySavedFilter(omit(lastFilters, hiddenFilterFields));
    }
  }, [ viewVariant ]);

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: { ...relationsForFilter }
    }));
  }, [ relationsForFilter ]);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      onReset={() => applySavedFilter(initialValues)}
      iconComponent={(
        <IconComponent
          modalWidth={modalWidth}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          hiddenFields={hiddenFilterFields}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      hiddenFields={hiddenFilterFields}
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.search,
          label: filterLabels[fieldsKeysMap.search],
          field: <TextField
            name="search"
            label="Search"
            placeholder="Search..."
            onChange={handleFieldChange('search')}
          />
        },
        {
          fieldKey: fieldsKeysMap.dueFrom,
          label: filterLabels[fieldsKeysMap.dueFrom],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              name="due_from"
              label="Due date from"
              placeholder="Date from"
              onChange={handleDatePickerChange('due_from')}
            />
          </Box>
        },
        {
          fieldKey: fieldsKeysMap.dueTo,
          label: filterLabels[fieldsKeysMap.dueTo],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              name="due_to"
              label="Due date to"
              onChange={handleDatePickerChange('due_to')}
            />
          </Box>
        },
        {
          fieldKey: fieldsKeysMap.cases,
          label: filterLabels[fieldsKeysMap.cases],
          field: <CasesSelect
            multiple
            name="cases"
            label="Cases"
            onChange={handleCasesChange}
          />
        },
        {
          fieldKey: fieldsKeysMap.types,
          label: filterLabels[fieldsKeysMap.types],
          field: <TaskTypesSelect
            multiple
            name="types"
            label="Types"
            params={{
              is_active: null
            }}
            onChange={handleTaskTypesSelectChange}
          />
        },
        {
          fieldKey: fieldsKeysMap.createdFrom,
          label: filterLabels[fieldsKeysMap.createdFrom],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              name="created_from"
              label="Create date from"
              onChange={handleDatePickerChange('created_from')}
            />
          </Box>
        },
        {
          fieldKey: fieldsKeysMap.createdTo,
          label: filterLabels[fieldsKeysMap.createdTo],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              name="created_to"
              label="Create date to"
              onChange={handleDatePickerChange('created_to')}
            />
          </Box>
        },
        {
          fieldKey: fieldsKeysMap.statuses,
          label: filterLabels[fieldsKeysMap.statuses],
          field: <TaskStatusesSelect
            isMulti
            name="statuses"
            label="Statuses"
            onChange={handleMultiSelectChange('statuses')}
          />
        },
        {
          fieldKey: fieldsKeysMap.users,
          label: filterLabels[fieldsKeysMap.users],
          field: (
            <UsersSelect
              multiple
              name="users"
              label="Users"
              onChange={handleUsersMultiSelect('users')}
              minWidth={210}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.priority,
          label: filterLabels[fieldsKeysMap.priority],
          field: <TaskPrioritySelect
            multiple
            name="priorities"
            label="Priority"
            onChange={handleMultiSelectChange('priorities')}
          />
        }
      ]}
    />
  );
};
