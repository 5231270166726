import { Text, UserActivityLink, Date, Case } from '../../fields';
import { EmailField } from '../EmailField';

export const emailsFieldsMap = {
  subject: (before, after) => <Text before={before} after={after} fieldLabel="Subject" />,
  patient: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Patient" />,
  date: (before, after) => <Date before={before} after={after} fieldLabel="Sent Date" />,
  case: (before, after) => <Case before={before} after={after} fieldLabel="Case" />,
  to: (before, after) => <EmailField before={before} after={after} fieldLabel="To" />,
  from: (before, after) => <EmailField before={before} after={after} fieldLabel="From" />,
  bcc: (before, after) => <EmailField before={before} after={after} fieldLabel="BCC" />,
  cc: (before, after) => <EmailField before={before} after={after} fieldLabel="CC" />
};
