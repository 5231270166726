export const styles = () => ({
  popup: {
    width: ({ popupWidth }) => popupWidth,
    height: 292,

    '& .swatches-picker > div': {
      display: 'block !important',

      '& > div:nth-child(2) > div': {
        overflow: 'auto !important'
      }
    }
  }
});
