import { useState } from 'react';
import { Box, Dialog, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ImagePreview } from '../../../app/Dashboard/ProfilePage/insurance/ViewInsuranceInfoModal/ImagePreview';
import { ModalBody, ModalContainer, ModalHeader } from '../../Modal';
import { DriverLicensesForm } from './DriverLicensesForm';

export const DriverLicensesPreview = ({
  DialogProps,
  handleModalReject,
  payload: { user = {}, front = null, back = null, onLicensesUpdate = () => {} }
}) => {
  const [ frontUrl, setFrontUrl ] = useState(front);
  const [ backUrl, setBackUrl ] = useState(back);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onUrlUpdate = (data) => {
    setFrontUrl(data?.front ? data?.croppedFrontImageUrl : null);
    setBackUrl(data?.back ? data?.croppedBackImageUrl : null);
    onLicensesUpdate();
  };

  return (
    <Dialog fullScreen={isMobile} {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          View Driver Licenses
        </ModalHeader>

        <ModalBody disablePaddings>
          <Grid container spacing={2} component={Box} p={2}>
            <Grid item sm={6} xs={12}>
              {frontUrl
                ? <ImagePreview imageUrl={frontUrl} name="Front" />
                : <Typography color="textSecondary">No Front License found</Typography>
              }
            </Grid>

            <Grid item sm={6} xs={12}>
              {backUrl
                ? <ImagePreview imageUrl={backUrl} name="Back" />
                : <Typography color="textSecondary">No Back License found</Typography>
              }
            </Grid>
          </Grid>

          <DriverLicensesForm
            user={user}
            front={front}
            back={back}
            onLicensesUpdate={onLicensesUpdate}
            onUpdate={onUrlUpdate}
            onClose={handleModalReject}
          />
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
