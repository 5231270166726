import { makeStyles, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const styles = ({ spacing }) => ({
  root: {
    marginLeft: spacing(0.5)
  }
});

const useStyles = makeStyles(styles);

export const ClearIndicator = ({ getStyles, innerProps: { ref, ...restInnerProps }, ...props }) => {
  const style = getStyles('clearIndicator', props);
  const classes = useStyles();

  delete style.padding;
  delete style.color;

  return (
    <IconButton
      {...restInnerProps}
      ref={ref}
      title="Clear all"
      style={style}
      size="small"
      className={classes.root}
    >
      <ClearIcon fontSize="small" />
    </IconButton>
  );
};
