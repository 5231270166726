import { Box, Grid, Typography } from '@material-ui/core';
import { Autocomplete, KeyboardDatePicker, TextField } from '../../../FormField';
import { natureCodesOptions, xRayAvailableOptions } from './data';

export const ChiropractorClaims = () => {
  return (
    <>
      <Box mb={1}>
        <Typography variant="h3">
          Chiropractor Claims
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <KeyboardDatePicker
            clearable
            name="initial_treatment_date"
            label="Initial Treatment Date"
            outputFormat="YYYY-MM-DD"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <KeyboardDatePicker
            clearable
            name="acute_manifestation_date"
            label="Acute Manifestation Date"
            outputFormat="YYYY-MM-DD"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <KeyboardDatePicker
            clearable
            name="last_xray_date"
            label="Last X-Ray Date"
            outputFormat="YYYY-MM-DD"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Autocomplete
            name="nature_condition"
            label="Nature of Condition"
            options={natureCodesOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            disableUnderline
            rows={3}
            rowsMax={8}
            variant="filled"
            name="description"
            label="Description"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Autocomplete
            name="xray_available"
            label="X-Ray Available"
            options={xRayAvailableOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};
