import { api } from '../../api';
import { transformReport } from './medical-reports';

export const fetchImmunizationReports = (config) => {
  return api.get('/medical-report/immunization-reports', config)
    .then(({ data }) => {
      data.data.forEach(transformReport);

      return data;
    })
    .catch((error) => { throw error.data; });
};

export const fetchImmunizationReport = (id, config) => {
  return api.get(`/medical-report/immunization-reports/${id}`, config)
    .then(({ data }) => transformReport(data))
    .catch((error) => { throw error.data; });
};

export const createImmunizationReport = (data) => {
  return api.post('/medical-report/immunization-reports', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massCreateImmunizationReports = (data) => {
  return api.post('/medical-report/immunization-reports/bulk', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateImmunizationReport = ({ id, ...data }) => {
  return api.put(`/medical-report/immunization-reports/${id}`, data)
    .then(({ data: { data } }) => transformReport(data))
    .catch((error) => { throw error.data; });
};

export const deleteImmunizationReport = (id) => {
  return api.delete(`/medical-report/immunization-reports/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteImmunizationReports = (IDs) => {
  return api.delete('/medical-report/immunization-reports/', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
