import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Radio } from '../../../FormField/Radio';
import { RadioGroup } from '../../../FormField/RadioGroup';
import { Autocomplete, KeyboardDatePicker, TextField } from '../../../FormField';
import { certificateCodeTypesOptions, conditionIndicatorsOptions, rentalTypesOptions } from './data';

export const DurableMedicalEquipment = () => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">
          Durable Medical Equipment
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextField
            name="length_medical_necessity"
            label="Length of Medical Necessity"
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            name="rental_price"
            label="Rental Price"
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            name="purchase_price"
            label="Purchase Price"
          />
        </Grid>

        <Grid item md={6}>
          <Autocomplete
            name="rental_type"
            label="Rental Type"
            placeholder="Select rental type..."
            options={rentalTypesOptions}
          />
        </Grid>

        <Grid item md={6}>
          <Typography>DMERC Condition Indicator</Typography>

          <RadioGroup row name="dmerc_condition_indicator">
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={<Radio name="dmerc_condition_indicator" color="primary" />}
            />

            <FormControlLabel
              value="no"
              label={<Typography>No</Typography>}
              control={<Radio name="dmerc_condition_indicator" color="primary" />}
            />

            <FormControlLabel
              value="n/a"
              label={<Typography>N/A</Typography>}
              control={<Radio name="dmerc_condition_indicator" color="primary" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6}>
          <Autocomplete
            multiple
            name="condition_indicators"
            label="Condition Indicators"
            placeholder="Select condition indicators..."
            options={conditionIndicatorsOptions}
          />
        </Grid>

        <Grid item md={6}>
          <Autocomplete
            name="certification_type_code"
            label="Certification Type Code"
            placeholder="Select certification type code..."
            options={certificateCodeTypesOptions}
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            name="dme_duration"
            label="DME Duration"
          />
        </Grid>

        <Grid item md={6}>
          <KeyboardDatePicker
            clearable
            name="certification_revision_date"
            label="Certification Revision Date"
            outputFormat="YYYY-MM-DD"
          />
        </Grid>

        <Grid item md={6}>
          <KeyboardDatePicker
            clearable
            name="begin_therapy_date"
            label="Begin Therapy Date"
            outputFormat="YYYY-MM-DD"
          />
        </Grid>

        <Grid item md={6}>
          <KeyboardDatePicker
            clearable
            name="last_certification_date"
            label="Last Certification Date"
            outputFormat="YYYY-MM-DD"
          />
        </Grid>
      </Grid>
    </>
  );
};
