export const styles = ({ spacing }) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing()
  },

  title: {
    paddingTop: spacing(2)
  },

  index: {
    marginRight: spacing()
  }
});
