import { useFormikContext } from 'formik';
import { Box, Grid } from '@material-ui/core';
import {
  TextField,
  PhoneField,
  CompaniesAutocomplete,
  OfficesLocationSelect
} from '../../../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../../../components/users';

export const Header = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleOfficeChange = (office) => {
    setFieldValue('claim_insurance.billing_address', office?.billing_full_address);
    setFieldValue('claim_insurance.office_phone', office?.phone);
  };

  const handleCompanyChange = (company) => {
    setFieldValue('claim_insurance.office_id', null);
    setFieldValue('claim_insurance.client_id', null);
    setFieldValue('claim_insurance.phone', company?.phone);
    setFieldValue('claim_insurance.email', company?.email);
  };

  const handleAdjusterChange = (user) => {
    setFieldValue('claim_insurance.mail_address', user?.work_full_address);
    setFieldValue('claim_insurance.direct_phone', user?.phone);
    setFieldValue('claim_insurance.client_email', user?.email);
  };

  return (
    <Grid container spacing={2} component={Box} pb={2}>
      <Grid item xs={6}>
        <CompaniesAutocomplete
          name="claim_insurance.examiner_id"
          label="Claims Examiner Name"
          margin="dense"
          onChange={handleCompanyChange}
        />
      </Grid>

      <Grid item xs={3}>
        <PhoneField
          disabled
          name="claim_insurance.phone"
          label="Phone"
          margin="dense"
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          disabled
          name="claim_insurance.email"
          label="Email"
          margin="dense"
        />
      </Grid>

      <Grid item xs={12}>
        <OfficesLocationSelect
          name="claim_insurance.office_id"
          label="Office Location"
          margin="dense"
          params={{ company_id: values?.claim_insurance?.examiner_id }}
          onChange={handleOfficeChange}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          name="claim_insurance.billing_address"
          label="Billing Address"
          margin="dense"
        />
      </Grid>

      <Grid item xs={6}>
        <PhoneField
          disabled
          name="claim_insurance.office_phone"
          label="Main Office Telephone Number"
          margin="dense"
        />
      </Grid>

      <Grid item xs={6}>
        <UsersSelect
          name="claim_insurance.client_id"
          label="Adjuster name"
          margin="dense"
          params={{ company_id: values?.claim_insurance?.examiner_id }}
          onChange={handleAdjusterChange}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          name="claim_insurance.mail_address"
          label="Mailing Address"
          margin="dense"
        />
      </Grid>

      <Grid item xs={6}>
        <PhoneField
          disabled
          name="claim_insurance.direct_phone"
          label="Direct Telephone Number"
          margin="dense"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          name="claim_insurance.client_email"
          label="Email"
          margin="dense"
        />
      </Grid>
    </Grid>
  );
};
