import { pick, omit } from 'lodash';
import NumberFormat from 'react-number-format';
import { TextField } from '../TextField';

const inputComponent = ({ inputRef, onChange, ...props }) => {
  return (
    <NumberFormat
      mask="_"
      allowNegative={false}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}

      {...props}
    />
  );
};

const allowableTextFieldProps = [
  'required',
  'className',
  'name',
  'InputProps',
  'withoutFormik',
  'label',
  'placeholder',
  'margin',
  'zeroMinWidth',
  'isEditable'
];

export const NumberMaskField = (props) => {
  const { InputProps, ...TextFieldProps } = pick(props, allowableTextFieldProps);
  const NumberFormatProps = omit(props, allowableTextFieldProps);

  return (
    <TextField
      InputProps={{
        ...InputProps,

        inputComponent,
        inputProps: {
          ...InputProps?.inputProps,
          ...NumberFormatProps
        }
      }}

      {...TextFieldProps}
    />
  );
};
