import { api } from '../api';

export const fetchTimeReports = (config = {}) => {
  return api.get('/work-clocks-reports', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const exportTimeReports = (config = {}) => {
  return api.get('/work-clocks-reports-export', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const createTimeReport = (data) => {
  return api.post('/work-clocks-reports', data).then(({ data: { data } }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const fetchTimeReport = (id) => {
  return api.get(`/work-clocks-reports/${id}`).then(({ data }) => data);
};

export const fetchTimeReportPDF = (id) => {
  return api.get(`/work-clocks-reports/${id}/pdf`, { responseType: 'blob' }).then(({ data }) => data);
};

export const updateTimeReport = ({ id, ...data }) => {
  return api.put(`/work-clocks-reports/${id}`, data).then(({ data: { data } }) => data);
};

export const approveTimeReport = (id) => {
  return api.post(`/work-clocks-reports/${id}/approve`).then(({ data: { data } }) => data);
};

export const disapproveTimeReport = (id) => {
  return api.delete(`/work-clocks-reports/${id}/approve`).then(({ data: { data } }) => data);
};

export const workerApproveTimeReport = (id) => {
  return api.post(`/work-clocks-reports/${id}/worker-approve`).then(({ data: { data } }) => data);
};

export const deleteTimeReport = (id) => {
  return api.delete(`/work-clocks-reports/${id}`).then(({ data }) => data);
};
