import { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { MessageItem } from '../../../../../../app/Dashboard/MessengerPage/ChatThread/MessageList';
import { MessageContextProvider } from '../../../../MessageProvider';
import { ThreadMessagesContext } from '../../../../ThreadMessagesContext';
import { Loader } from '../../../../../Loader';
import { ScrollbarsLoader } from '../../../../../ScrollbarsLoader';

export const MessageList = ({ threadId }) => {
  const {
    threadMessages,
    isFetching,
    isFetched,
    loadNextPage,
    resetMessages,
    pagination,
    scrollerRef
  } = useContext(ThreadMessagesContext);
  const [ isScrollDisabled, setIsScrollDisabled ] = useState(false);

  const handleMenuToggled = (isOpened) => {
    setIsScrollDisabled(isOpened);
  };

  useEffect(() => {
    if (threadId) {
      resetMessages();
    }
  }, [ threadId ]);

  return (
    <Box height="100%">
      <Loader loading={!isFetched} p={2}>
        {!threadMessages.length ? (
          <Box p={2}>
            <Typography variant="body1" align="center">Enter first message to chat</Typography>
          </Box>
        ) : (
          <ScrollbarsLoader
            reverse
            noScroll={isScrollDisabled}
            scrollerRef={scrollerRef}
            isFetching={isFetching}
            isFetched={isFetched}
            total={pagination?.total}
            rowsCount={threadMessages.length}
            threshold={400}
            onNeedNextPage={loadNextPage}
          >
            {threadMessages.map((message, index) => (
              <MessageContextProvider
                dense
                key={message.id}
                message={message}
                messages={threadMessages}
                index={index}
              >
                <MessageItem onMenuToggled={handleMenuToggled} />
              </MessageContextProvider>
            ))}
          </ScrollbarsLoader>
        )}
      </Loader>
    </Box>
  );
};
