export const styles = ({
  palette: { grey, divider, text },
  typography: { pxToRem, fontWeightMedium },
  spacing
}) => ({
  root: {
    borderBottom: `1px solid ${divider}`
  },

  input: {
    color: grey[500],
    fontSize: pxToRem(21),
    flexGrow: 1
  },

  preview__item: {
    position: 'relative',
    paddingTop: spacing(1),
    overflow: 'hidden',
    flexGrow: 1,
    paddingBottom: spacing(3)
  },

  preview__tempo: {
    backgroundColor: grey[50]
  },

  preview__header: {
    borderBottom: `1px solid ${divider}`,
    paddingBottom: spacing(1),
    paddingLeft: spacing(5),

    '&:nth-child(4)': {
      borderBottom: 0
    }
  },

  preview__title: {
    color: text.caption,
    fontWeight: fontWeightMedium,
    paddingBottom: spacing(1)
  },

  preview__submit: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: spacing(2.5),
    paddingRight: spacing(4)
  },

  preview__cancelBtn: {
    color: grey[200],
    marginRight: spacing(1.5)
  }
});
