import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { LayoutContext, viewVariantsMap } from '../../../../../../../components/LayoutContext';
import { SelectedItemsCount } from '../../../../../../../components/SelectedItemsCount';
import { SelectMedicalFormModal } from '../../../../../../../components/medical/forms';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { PageHeaderActions } from '../../../../../../../components/Page';
import { IconButton } from '../../../../../../../components/IconButton';
import { Fab } from '../../../../../../../components/Fab';
import { UserContext } from '../../../../../Members/User/UserProvider';
import { MedicalFormsContext } from '../../MedicalFormsProvider';

export const ActionsBar = ({ filterOpenButton = () => {} }) => {
  const history = useHistory();
  const { openModal } = useModal();
  const {
    selectedIDs,
    pagination,
    setInitialType,
    setInitialFormValue,
    ...medicalFormsContext
  } = useContext(MedicalFormsContext);
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const currentUser = useSelector(({ profile }) => profile.user);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : currentUser;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleSelectComponent = () => {
    openModal(SelectMedicalFormModal, {
      payload: {
        patient: !!medicalFormsContext.medicalInfoPathname.length ? user : null,
        pathname: medicalFormsContext?.medicalInfoPathname || ''
      },
      onModalResolved: (data) => {
        history.push(
          `${medicalFormsContext.medicalInfoPathname}/medical-forms/create`, {
            type: data.medical_form,
            form: data
          }
        );
      }
    });
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const massDeleteForms = () => {
    medicalFormsContext.massDeleteForms(selectedIDs);
  };

  return (
    <>
      <PageHeaderActions disablePosition>
        <Fab
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={handleSelectComponent}
        >
          Add medical form
        </Fab>
      </PageHeaderActions>

      <PageHeaderActions disableSpacing endPosition>
        {!!selectedIDs.length &&
          <>
            <SelectedItemsCount length={selectedIDs.length} total={pagination.total} />
            <VerticalDivider verticalGutters={1} horizontalGutters={1} />

            <Tooltip
              arrow
              title={<Typography>Delete selected forms</Typography>}
            >
              <IconButton
                color="error"
                onClick={massDeleteForms}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <VerticalDivider verticalGutters={1} horizontalGutters={1}/>
          </>
        }

        {!isTablet ? (
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <PageHeaderActions>
            {filterOpenButton}
          </PageHeaderActions>
        )}
      </PageHeaderActions>
    </>
  );
};
