import { useState } from 'react';
import { Formik } from 'formik';
import { Dialog, Button } from '@material-ui/core';
import NextWeekOutlinedIcon from '@material-ui/icons/NextWeekOutlined';
import { useSnackbar } from 'notistack';
import * as emailsApi from '../../../../../api/emails';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { SimpleSelect } from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '../../../../../components/Modal';
import { validationSchema } from './validationSchema.js';
import { actionsMap } from '../actionsMap';

const actions = [
  { value: actionsMap.add, label: 'Add current to selected case' },
  { value: actionsMap.replace, label: 'Replace current to selected case' },
  { value: actionsMap.delete, label: 'Delete selected case' }
];

export const EmailsAttachToCaseModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { emailIDs, onCaseAdd, onCaseDelete }
}) => {
  const [ selectedCase, setSelectedCase ] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const handleCaseChange = (caseItem) => {
    setSelectedCase(caseItem);
  };

  const emailAttachToCase = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return emailsApi.assignEmails({
      emails_id: emailIDs,
      case_id: values.action === actionsMap.delete ? null : values.case_id
    }).then(() => {
      enqueueSnackbar('Case for message changed', { variant: 'success' });

      if (values.action === actionsMap.delete) {
        onCaseDelete(emailIDs);
      } else {
        onCaseAdd(emailIDs, selectedCase);
      }

      handleModalResolve();
    }).catch(() => {
      enqueueSnackbar('Case for message not changed', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        action: actionsMap.add,
        case_id: null
      }}
      validationSchema={validationSchema}
      onSubmit={emailAttachToCase}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<NextWeekOutlinedIcon />} onClose={handleModalReject}>
              Attach to case
            </ModalHeader>

            <ModalBody>
              <SimpleSelect
                name="action"
                label="Select action type"
                options={actions}
              />

              <CasesSelect
                label="Select case"
                placeholder="Search case by name..."
                onChange={handleCaseChange}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
