import { Typography } from '@material-ui/core';
import { Date as MuiDate } from '../../Date';

export const Date = ({ before = null, after = null, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Set&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;-&nbsp;
        <MuiDate disableIcon format="L, LT" variant="subtitle1" date={after} />
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <MuiDate disableIcon format="L, LT" variant="subtitle1" date={before} />
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <MuiDate disableIcon format="L, LT" variant="subtitle1" date={before} />
        &nbsp;to&nbsp;
        <MuiDate disableIcon format="L, LT" variant="subtitle1" date={after} />
      </>
    );
  } else {
    return (
      <>
        Empty&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  }
};
