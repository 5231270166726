export const faxTypesMap = {
  faxQueued: 'fax-queued',
  faxRequestError: 'fax-request-error',
  faxFailed: 'fax-failed',
  faxSent: 'fax-sent',
  faxReceived: 'fax-received',
  faxUnknownError: 'fax-unknown-error',
  faxBadAttachments: 'fax-bad-attachments',
  faxNoAttachments: 'fax-no-attachments',
  faxBadRecipient: 'fax-bad-recipient',
  faxNotAllowed: 'fax-not-allowed',
  faxUnreachableRecipient: 'fax-unreachable-recipient',
  faxQueuedCaveats: 'fax-queued-w-caveats',
  faxQueuedSuccessfully: 'fax-queued-successfully'
};

export const faxStatusesMap = {
  [faxTypesMap.faxQueued]: 'Queued',
  [faxTypesMap.faxRequestError]: 'Request Error',
  [faxTypesMap.faxFailed]: 'Failed',
  [faxTypesMap.faxSent]: 'Sent',
  [faxTypesMap.faxReceived]: 'Received',
  [faxTypesMap.faxUnknownError]: 'Unknown Error',
  [faxTypesMap.faxBadAttachments]: 'Bad Attachments',
  [faxTypesMap.faxNoAttachments]: 'No Attachments',
  [faxTypesMap.faxBadRecipient]: 'Bad Recipient',
  [faxTypesMap.faxNotAllowed]: 'Not Allowed',
  [faxTypesMap.faxUnreachableRecipient]: 'Unreachable Recipient',
  [faxTypesMap.faxQueuedCaveats]: 'Queued W Caveats',
  [faxTypesMap.faxQueuedSuccessfully]: 'Queued Successfully'
};

export const faxStatusColorsMap = {
  [faxTypesMap.faxQueued]: 'warning.main',
  [faxTypesMap.faxRequestError]: 'error.main',
  [faxTypesMap.faxFailed]: 'error.main',
  [faxTypesMap.faxSent]: 'success.main',
  [faxTypesMap.faxReceived]: 'success.main',
  [faxTypesMap.faxUnknownError]: 'error.main',
  [faxTypesMap.faxBadAttachments]: 'error.main',
  [faxTypesMap.faxNoAttachments]: 'warning.main',
  [faxTypesMap.faxBadRecipient]: 'error.main',
  [faxTypesMap.faxNotAllowed]: 'error.main',
  [faxTypesMap.faxUnreachableRecipient]: 'error.main',
  [faxTypesMap.faxQueuedCaveats]: 'warning.main',
  [faxTypesMap.faxQueuedSuccessfully]: 'warning.main'
};
