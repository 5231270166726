import Box from '@material-ui/core/Box';
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ColorPreview } from '../../../ColorPreview';

export const Option = ({ children: eventType, ...props }) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isSelected}

      {...props.innerProps}
    >
      <ListItemIcon>
        <Box mr={-1}>
          <ColorPreview color={eventType.color} />
        </Box>
      </ListItemIcon>

      <ListItemText
        primary={eventType?.name}
        primaryTypographyProps={{ noWrap: true, title: eventType.name }}
      />
    </MenuItem>
  );
};
