import { useContext } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { CompaniesContext } from '../../CompaniesContext';
import {
  columnsWidths,
  companiesMap,
  widthBreakpointLarge,
  widthBreakpointMedium,
  widthBreakpointSmall
} from '../List';

const styles = ({ spacing }) => ({
  root: {
    paddingLeft: spacing(),
    paddingRight: spacing()
  }
});

const useStyles = makeStyles(styles);

export const TableHeader = ({ disableToggle = false, hiddenColumns = [], width }) => {
  const classes = useStyles();
  const { selectedIDs, toggleAllItemsSelection, allItemsIsSelected } = useContext(CompaniesContext);

  return (
    <Paper elevation={0}>
      <ListRow header className={classes.root}>
        <ListRowCheckbox
          disabled={disableToggle}
          onClick={toggleAllItemsSelection}
          checked={allItemsIsSelected()}
          indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
        />

        <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
          Name Company
        </ListRowCell>

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.address}>
            Address
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.address}>
          Billing Address
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.venue}>
          {hiddenColumns?.length ? 'Phone' : 'Venue'}
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>

        {width > widthBreakpointSmall &&
          <ListRowCell flexBasis={columnsWidths.phone}>
            Phone
          </ListRowCell>
        }

        {width > widthBreakpointMedium &&
          <ListRowCell flexBasis={columnsWidths.offices}>
            Offices
          </ListRowCell>
        }

        {!hiddenColumns.includes(companiesMap.actions) &&
          <ListRowCell flexBasis={columnsWidths.actions} />
        }
      </ListRow>
    </Paper>
  );
};
