import { Switch, useRouteMatch } from 'react-router-dom';
import { Box, useMediaQuery } from '@material-ui/core';
import { Route } from '../../../../components/router';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { hasRole } from '../../../../utils/hasRole';
import { ClaimPreview } from './ClaimPreview';
import { Claims } from './Claims';
import { Create } from './Create';
import { Edit } from './Edit';

export const ClaimsPage = ({
  view,
  filterKey = filtersKeysMap.billing_claims_main,
  hiddenFilterFields,
  hiddenColumns,
  initialFilter
}) => {
  const match = useRouteMatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <Box p={isMobile ? 0 : 2} height="100%" bgcolor="grey.50">
      <Switch>
        <Route exact path={match.path}>
          <Claims
            view={view}
            filterKey={filterKey}
            hiddenFilterFields={hiddenFilterFields}
            hiddenColumns={hiddenColumns}
            initialFilter={initialFilter}
          />
        </Route>

        <Route path={`${match.path}/create`}>
          {!hasRole(rolesMap.patient, rolesMap.client) &&
            <Create />
          }
        </Route>

        <Route exact path={`${match.path}/:id`} component={ClaimPreview} />

        <Route path={`${match.path}/:id/edit`}>
          {!hasRole(rolesMap.patient, rolesMap.client) &&
            <Edit />
          }
        </Route>
      </Switch>
    </Box>
  );
};
