import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Dialog, SvgIcon, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { mdiCommentMultipleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import * as prescriptionsApi from '../../../../../api/profile';
import { Comments, CommentsContextProvider } from '../../../../../components/Comments';
import { ModalBody, ModalContainer, ModalHeader, ModalHeaderActions } from '../../../../../components/Modal';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Loader } from '../../../../../components/Loader';
import { ReportsPreviewContext, ReportsPreviewProvider } from '../../ReportsPreviewProvider';
import { EditPrescriptionModal } from '../EditPrescriptionModal';
import { MainContent } from './MainContent';

export const PreviewPrescriptionModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    prescription,
    updatePrescription,
    onUpdate,
    onDelete
  }
}) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const commentsLastGlobalAction = useSelector(({ globalActions }) => globalActions.commentsLastGlobalAction);

  const commentsInitialState = {
    filter: {
      parent_type: 'prescription',
      parent_id: prescription?.id
    }
  };

  const handleUpdatePrescription = (value, callback) => () => {
    openModal(EditPrescriptionModal, {
      payload: { prescriptionID: prescription.id },
      onModalResolved: (data) => {
        onUpdate(data);
        callback({ ...value, ...data });
      }
    });
  };

  const handleDeletePrescription = () => {
    onDelete(prescription.id, handleModalResolve);
  };

  const fetchPrescription = () => {
    return prescriptionsApi.fetchMedication(prescription.id);
  };

  useEffect(() => {
    if (commentsLastGlobalAction) {
      onUpdate(prescription.id);
    }
  }, [ commentsLastGlobalAction ]);

  return (
    <ReportsPreviewProvider
      appointment_id={prescription.appointment_id}
      fetchItem={fetchPrescription}
      onUpdate={onUpdate}
    >
      <ReportsPreviewContext.Consumer>
        {({ isFetched, selectedItem, setSelectedItem }) => (
          <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
            <Loader p={3} loading={!isFetched} render={
              () => (
                <ModalContainer>
                  <ModalHeader onClose={handleModalReject}>
                    View Prescription #{selectedItem.prescription}

                    <ModalHeaderActions disableGutters>
                      {!hasRole(rolesMap.client, rolesMap.patient) &&
                        <>
                          <Tooltip arrow title="Edit prescription">
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={handleUpdatePrescription(selectedItem, setSelectedItem)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip arrow title="Delete prescription">
                            <IconButton
                              color="error"
                              onClick={handleDeletePrescription}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    </ModalHeaderActions>
                  </ModalHeader>

                  <ModalBody>
                    <MainContent />

                    <Box display="flex" alignItems="center" mt={1} mb={2}>
                      <SvgIcon><Icon path={mdiCommentMultipleOutline} /></SvgIcon>

                      <Box ml={1}>
                        <Typography variant="h3">
                          Comments
                        </Typography>
                      </Box>
                    </Box>

                    <Box minHeight={300}>
                      <CommentsContextProvider
                        initialState={commentsInitialState}
                        onCommentsCountUpdate={updatePrescription}
                      >
                        <Comments
                          parentType="prescription"
                          parentID={prescription.id}
                        />
                      </CommentsContextProvider>
                    </Box>
                  </ModalBody>
                </ModalContainer>
              )}
            />
          </Dialog>
        )}
      </ReportsPreviewContext.Consumer>
    </ReportsPreviewProvider>
  );
};
