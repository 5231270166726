import { Box, ListItemText, MenuItem, Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import { preventDefault } from '../../../../../../../../helpers/dom';
import { generateAddress } from '../../../../../../../../utils/generateAddress';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { OfficePreviewModal } from '../../../../../../Contacts/Offices/OfficePreviewModal';

export const OfficePopper = ({ office }) => {
  const { openModal } = useModal();

  const openPreviewOfficeModal = () => {
    openModal(OfficePreviewModal, {
      payload: {
        id: office.id
      }
    });
  };

  return (
    <MenuItem onClick={preventDefault(openPreviewOfficeModal)}>
      <ListItemText
        disableTypography
        primary={<Typography variant="h5">{office?.name}</Typography>}
        secondary={
          <Box display="flex" alignItems="center" color="text.secondary">
            <Box mr={0.5}>
              <RoomIcon fontSize="small" color="inherit" />
            </Box>

            <Typography variant="body2" color="textSecondary">
              {office?.full_address || generateAddress(office)}
            </Typography>
          </Box>
        }
      />
    </MenuItem>
  );
};
