import React, { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { InfiniteListLoader } from '../../../InfiniteLoader';
import { Loader } from '../../../Loader';
import { MedicalHistoryContext } from '../MedicalHistoryProvider';
import { Row } from './Row';

export const minRowHeight = 72;

export const List = () => {
  const { isFetched, isFetching, meta, medicalHistory, loadNextPage } = useContext(MedicalHistoryContext);
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader p={3} loading={!isFetched} render={
        () => !medicalHistory?.length ? (
          <Box p={3}>
            <Typography align="center">No history found</Typography>
          </Box>
        ) : (
          <MuiList disablePadding>
            <InfiniteListLoader
              items={medicalHistory}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={meta}
              RowComponent={Row}
              minRowHeight={minRowHeight}
              scrollElementRef={scrollElementRef}
              onNeedNextPage={loadNextPage}
            />
          </MuiList>
        )}
      />
    </CustomScrollbars>
  );
};
