import { Box, Grid, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TimeReportsPreviewModal } from '../../../TimeReportsPreviewModal';
import { stopPropagation } from '../../../../../helpers/dom';
import { CompanyLink } from '../../../../CompanyLink';
import { IconButton } from '../../../../IconButton';
import { useModal } from '../../../../ModalsProvider';
import { Popper } from '../../../../Popper';
import { UserLink } from '../../../../UserLink';
import { CardItem } from '../../../../Cards';
import { Date } from '../../../../Date';
import { Menu } from '../../List/Row/Menu';

export const Card = ({ item: timeReport = {} }) => {
  const { openModal } = useModal();

  const openReportPreview = () => {
    openModal(TimeReportsPreviewModal, {
      payload: {
        reportID: timeReport.id
      }
    });
  };

  return (
    <CardItem py={1} px={2} onClick={openReportPreview}>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Box height="100%" display="flex" alignItems="center">
            <CompanyLink noWrap variant="h5" company={timeReport.company} />
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Popper
            placement="left-start"
            anchorRenderer={({ anchorRef, handleToggle }) => (
              <IconButton
                buttonRef={anchorRef}
                edge="end"
                onClick={stopPropagation(handleToggle)}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          >
            {({ handleClose }) => (
              <Menu timeReport={timeReport} onClose={handleClose} />
            )}
          </Popper>
        </Grid>

        <Grid item xs={4}>
          <Typography>User:</Typography>
        </Grid>

        <Grid item xs={8}>
          <UserLink
            size="sm"
            variant="h5"
            user={timeReport.user}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>Supervisor:</Typography>
        </Grid>

        <Grid item xs={8}>
          <UserLink
            size="sm"
            variant="h5"
            user={timeReport.supervisor}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>Start:</Typography>
        </Grid>

        <Grid item xs={8}>
          <Date disableIcon date={timeReport.date_from} format="L LT" />
        </Grid>

        <Grid item xs={4}>
          <Typography>End:</Typography>
        </Grid>

        <Grid item xs={8}>
          <Date disableIcon date={timeReport.date_to} format="L LT" />
        </Grid>
      </Grid>
    </CardItem>
  );
};
