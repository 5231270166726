export const styles = ({ spacing, typography: { fontWeightMedium } }) => ({
  root: {
    marginTop: spacing(3)
  },

  notesContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  noteIcon: {
    display: 'flex',
    alignItems:'center',
    fontWeight: fontWeightMedium
  },

  notesContentDatepicker: {
    minWidth: 0,
    maxWidth: 100
  },

  notesContentInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },

  actionNoteButton: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },

  collapse: {
    marginBottom: spacing(3)
  }
});
