import { forwardRef, useEffect, useRef } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { get } from 'lodash';
import cn from 'classnames';
import { makeStyles, TextField as MuiTextField } from '@material-ui/core';
import { useFormikField } from '../useFormikField';
import { VoiceRecognitionButton } from './VoiceRecognitionButton';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TextField = forwardRef(({
  showRendersCount = false,
  disableVoiceRecognition = false,
  unbindForm = false,
  minWidth,
  isEditable,
  disabled,
  zeroMinWidth,
  disableNumber = false,
  disableUnderline,
  withoutFormik,
  fullWidth = true,
  type = 'text',
  helperText,
  error: errorProp,
  name,
  className,
  InputProps = {},
  InputLabelProps = {},
  onChange = () => {},
  onTextRecognized = () => {},

  ...props
}, ref) => {
  const rendersCountRef = useRef(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik || !name ? {} : useFormikField(name);
  const {
    isFormikField,
    fieldProps: [ field = {}, { touched } = {}, { setValue } = {} ] = [],
    error
  } = formikFieldProps;
  const classes = useStyles({ minWidth });
  const hasIsEditable = typeof isEditable === 'boolean';
  const isInitialized = useRef(false);
  const isControlled = props.hasOwnProperty('value') || field.hasOwnProperty('value');
  const conditionalProps = {};

  // React Hook Form
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formContext = name && useFormContext();
  const formRegister = formContext?.register?.(name);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { fieldState } = (formContext && useController({
    name, control: formContext?.control
  })) || {};
  const errorMessage = fieldState?.error?.message;

  if (isControlled) {
    conditionalProps.value =
      props.value ||
      (type === 'number' ? `${field.value}` : field.value) ||
      (type !== 'number' && '');
  }

  const handleTextRecognized = (recognizedText) => {
    setValue?.((field.value || '') + recognizedText);
    formContext?.setValue(name, (fieldState.value || '') + recognizedText, { shouldValidate: true });
    onTextRecognized(recognizedText);
  };

  const handleChange = (event) => {
    isInitialized.current = true;

    if (isFormikField) {
      setValue(
        ((type === 'number' && !disableNumber && event.target.value > 0)
          ? +event.target.value
          : event.target.value
        ) || null
      );
    } else {
      onChange(event);
      formRegister?.onChange(event);
    }
  };

  useEffect(() => {
    if (isFormikField) {
      if (isInitialized.current) {
        onChange({ target: { value: field.value } });
      } else {
        isInitialized.current = true;
      }
    }
  }, [ isFormikField, field.value ]);

  return (
    <>
      {showRendersCount &&
        <div>Renders count: {++rendersCountRef.current}</div>
      }

      <MuiTextField
        {...field}

        autoComplete="off"
        fullWidth={fullWidth}
        disabled={(hasIsEditable && !isEditable) || disabled}
        error={!!(touched && error) || fieldState?.invalid || errorProp}
        type={type}
        name={name}
        onChange={handleChange}
        helperText={(touched && error) || errorMessage || helperText}
        InputProps={{
          ...InputProps,

          ...(props.multiline && !disableVoiceRecognition && {
            endAdornment: (
              <>
                {InputProps?.endAdornment}

                <VoiceRecognitionButton onTextRecognized={handleTextRecognized} />
              </>
            )
          }),

          classes: {
            ...get(InputProps, 'classes', {}),
            disabled: cn(hasIsEditable && classes.notEditableInput, get(InputProps, 'classes.disabled')),
            underline: disableUnderline && classes.underline
          }
        }}
        InputLabelProps={{
          ...InputLabelProps,

          classes: {
            ...get(InputLabelProps, 'classes', {}),
            disabled: cn(hasIsEditable && classes.notEditableInputLabel, get(InputLabelProps, 'classes.disabled'))
          }
        }}
        className={cn(className, {
          [classes.minWidth]: minWidth,
          [classes.zeroMinWidth]: zeroMinWidth
        })}

        {...props}
        {...conditionalProps}

        ref={(!unbindForm && formRegister?.ref) || ref}
      />
    </>
  );
});
