import { Link, Typography } from '@material-ui/core';
import { getUserFullName } from '../../../helpers/users';
import { useModal } from '../../ModalsProvider';
import { UserPreviewModal } from '../../users';

export const Provider = ({ before, after, fieldLabel }) => {
  const { openModal } = useModal();

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  if (!before && after) {
    return (
      <>
        Added <Typography variant="subtitle1">{fieldLabel} provider</Typography>
        &nbsp;
        <Link onClick={openUserPreview(after?.id)}>
          {getUserFullName(after)}
        </Link>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed <Typography variant="subtitle1">{fieldLabel} provider</Typography>
        &nbsp;
        <Link onClick={openUserPreview(before?.id)}>
          {getUserFullName(before)}
        </Link>
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed <Typography variant="subtitle1">{fieldLabel} provider</Typography>
        &nbsp;from&nbsp;
        <Link onClick={openUserPreview(before?.id)}>
          {getUserFullName(before)}
        </Link>
        &nbsp;to&nbsp;
        <Link onClick={openUserPreview(after?.id)}>
          {getUserFullName(after)}
        </Link>
      </>
    );
  } else {
    return (
      <>
        Empty&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  }
};
