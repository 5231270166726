import { createContext, useEffect, useReducer, useRef } from 'react';
import { api } from '../../../../../../api';
import * as documentsApi from '../../../../../../api/files/html-documents';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const DocumentVersionsContext = createContext();

export const DocumentVersionsContextProvider = ({ children, documentID }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { isFetching, isFetched, filter, pagination, versions } = state;
  const cancelFetch = useRef(() => {});

  const fetchVersions = () => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_VERSIONS_REQUEST });

    return documentsApi.fetchDocumentsVersions({
      params: { ...filter, html_document_id: documentID },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    })
      .then((data) => {
        dispatch({ type: types.FETCH_VERSIONS_SUCCESS, payload: data });

        return data;
      });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchVersions({ page: pagination.page + 1 });
    }
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);


  const providerValue = {
    isFetched,
    isFetching,
    versions,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    fetchVersions,
    loadNextPage
  };

  return (
    <DocumentVersionsContext.Provider value={providerValue}>
      {children}
    </DocumentVersionsContext.Provider>
  );
};
