import { PURGE } from 'redux-persist';
import * as trackingablesApi from '../../../api/time-tracks/time-trackingables';
import { createReduxReducer } from '../../../helpers/createReduxReducer';

const SET_TIME_TRACKS_FILTER = 'SET_TIME_TRACKS_FILTER';
const FETCH_TIME_TRACKS_REQUEST = 'FETCH_TIME_TRACKS_REQUEST';
const FETCH_TIME_TRACKS_SUCCESS = 'FETCH_TIME_TRACKS_SUCCESS';

// export const setTracksFilter = (payload) => ({ type: SET_TIME_TRACKS_FILTER, payload });
export const fetchTracksRequest = () => ({ type: FETCH_TIME_TRACKS_REQUEST });
export const fetchTracksSuccess = (payload) => ({ type: FETCH_TIME_TRACKS_SUCCESS, payload });

export const fetchTracks = () => (dispatch) => {
  dispatch(fetchTracksRequest());

  trackingablesApi.fetchTracks().then((data) => {
    dispatch(fetchTracksSuccess(data));
  });
};

const initialState = {
  isFetching: true,
  tracks: []
};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [SET_TIME_TRACKS_FILTER]: (state, payload) => {
    return {
      ...state,
      filter: {
        ...state.filter,
        ...payload
      }
    };
  },

  [FETCH_TIME_TRACKS_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [FETCH_TIME_TRACKS_SUCCESS]: (state, { data, pagination }) => {
    return {
      ...state,
      isFetching: false,
      tracks: data,
      pagination,
      filter: {
        ...state.filter,
        ...pagination
      }
    };
  }
});
