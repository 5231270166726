import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';
import { grey } from '../../../../../../themes/palette';

export const Chart = ({ data, labels  }) => {
  const isEmpty = data.filter((item) => !!item)?.length === 0;
  const { palette } = useTheme();
  const labelsInfo = labels.map((item) => {
    if (item.indexOf('_') !== -1) {
      return item.split('_').map((i) => i.charAt(0).toUpperCase() + i.slice(1)).join(' ');
    } else if (item.indexOf('>') !== -1) {
      const newItem = item.split('>');
      newItem[0] = 'More than';

      return newItem.join(' ');
    } else {
      return item;
    }
  });

  const getChartData = () => ({
    labels: isEmpty ? [ 'No values' ] : labelsInfo,
    toolbox: false,
    datasets: [
      {
        label: isEmpty ? '' : 'Task Statistics',
        backgroundColor: isEmpty ? [ grey[300] ] : [
          palette.primary.main,
          palette.purple,
          palette.pink,
          palette.amberyellow
        ],
        data: isEmpty ? [ 1 ] : data
      }
    ]
  });

  const chartOptions = {
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 12
      }
    }
  };

  return (
    <Doughnut options={chartOptions} data={getChartData()} />
  );
};
