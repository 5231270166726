import { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import printJS from 'print-js';
import cn from 'classnames';
import {
  Button,
  makeStyles,
  SvgIcon,
  Box,
  Typography,
  FormControlLabel
} from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiSend } from '@mdi/js';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import * as invoicesApi from '../../../../../../api/billing';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Switch } from '../../../../../../components/FormField';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Fab } from '../../../../../../components/Fab';
import { useModal } from '../../../../../../components/ModalsProvider';
import { EmailsSendModal } from '../../../../EmailsPage/EmailsClient/EmailsSendModal';
import { widthBreakpointSmall, widthBreakpointXS } from '../InvoiceItem';
import { SendByModal } from '../SendByModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({
  width,
  openDrawer,
  disableAction = false,
  disableBorder = false,
  onDrawerUpdate = () => {},
  onInvoiceUpdate = () => {},
  invoice,

  ...props
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ toggleCancelInvoice, setToggleCancelInvoice ] = useState(!!invoice?.confirmed_by?.id);

  const handleDownloadPDF = () => {
    invoicesApi.downloadInvoicePDF(invoice.id).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ]));

      saveAs(url, `${invoice.number}.pdf`);
    });
  };

  const handlePrintInvoice = () => {
    invoicesApi.downloadInvoicePDF(invoice.id).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      printJS({
        printable: url,
        type: 'pdf'
      });
    });
  };

  const handleConfirmInvoice = () => {
    invoicesApi.confirmInvoice(invoice.id).then(({ data }) => {
      onInvoiceUpdate(data);
      enqueueSnackbar('Invoice successfully confirmed', { variant: 'success' });
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      }
    });
  };

  const handleCancelInvoice = () => {
    invoicesApi.cancelInvoice(invoice.id).then(({ data }) => {
      onInvoiceUpdate(data);
      enqueueSnackbar('Invoice successfully canceled', { variant: 'success' });
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      }
    });
  };

  const handleDrawerOpen = () => {
    onDrawerUpdate(true);
  };

  const handleToggleCancelInvoice = (event) => {
    const checked = event.target.checked;

    checked ? handleConfirmInvoice(checked) : handleCancelInvoice(checked);
    setToggleCancelInvoice(checked);
  };

  const handleSendByModalOpen = () => {
    openModal(SendByModal, {
      payload: { invoice },
      onModalResolved: (data) => {
        openModal(EmailsSendModal, {
          payload: data
        });
      }
    });
  };

  return (
    <div className={cn(classes.root, { [classes.root_border]: !disableBorder })}>
      <div className={classes.actionGroup}>
        {!disableBorder &&
          <>
            <IconButton
              color="primary"
              onClick={handleDrawerOpen}
              className={cn(classes.menuButton, openDrawer && classes.hide)}
            >
              <MenuIcon />
            </IconButton>

            <IconButton color="primary" onClick={!disableBorder ? history.goBack : props.handleModalReject}>
              <KeyboardBackspaceIcon />
            </IconButton>
          </>
        }

        {width > widthBreakpointSmall ? (
          <>
            <Fab
              color="primary"
              variant="extended"
              className={classes.actionButton}
              onClick={handlePrintInvoice}
              startIcon={<PrintIcon/>}
            >
              Print
            </Fab>

            <Fab
              color="primary"
              variant="extended"
              onClick={handleDownloadPDF}
              className={classes.actionButton}
              startIcon={<DownloadIcon/>}
            >
              Download
            </Fab>

            <Fab
              color="secondary"
              variant="extended"
              className={classes.actionButton}
              startIcon={
                <SvgIcon>
                  <Icon path={mdiSend} />
                </SvgIcon>
              }
              onClick={handleSendByModalOpen}
            >
              Send by email
            </Fab>
          </>
        ) : (
          <>
            <IconButton color="primary" onClick={handlePrintInvoice}>
              <PrintIcon/>
            </IconButton>

            <IconButton color="info" onClick={handleDownloadPDF}>
              <DownloadIcon/>
            </IconButton>

            <IconButton color="secondary" onClick={handleSendByModalOpen}>
              <SvgIcon>
                <Icon path={mdiSend} />
              </SvgIcon>
            </IconButton>
          </>
        )}
      </div>

      <div className={classes.actionCancel}>
        <Box display="flex" alignItems="center">
          {toggleCancelInvoice && invoice.confirmed_by &&
            <UserLink
              variant="h5"
              size="md"
              user={invoice.confirmed_by}
              childrenProps={{ mt: -0.5 }}
            >
              <Typography color="textSecondary" variant="body2">Confirmed by</Typography>
            </UserLink>
          }

          {!disableAction && (
            <Box mx={1}>
              <Tooltip title={toggleCancelInvoice ? 'Cancel Invoice' : 'Confirm Invoice'}>
                <FormControlLabel
                  control={
                    <Switch
                      withoutFormik
                      checked={toggleCancelInvoice}
                      color="primary"
                      onChange={handleToggleCancelInvoice}
                    />
                  }
                  label={
                    <Typography color="textSecondary" variant="body2">
                      {toggleCancelInvoice ? 'Cancel' : 'Confirm'}
                    </Typography>
                  }
                />
              </Tooltip>
            </Box>
          )}
        </Box>

        {width > widthBreakpointXS && (
          <Button
            component={RouterLink}
            size="small"
            variant="contained"
            color="primary"
            to={`/billing/invoices/edit/${invoice.id}`}
          >
            Edit
          </Button>
        )}
      </div>
    </div>
  );
};
