import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isEqual, merge, omit } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import { setCasesLastGlobalAction } from '../../../../../store/globalActions';
import { api } from '../../../../../api';
import * as casesApi from '../../../../../api/cases';
import { useModal } from '../../../../../components/ModalsProvider';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { CaseModal } from '../CaseModal';
import { CasesFilterContext } from './CasesFilterContext';
import { initialState as initialStateConfig } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CasesContext = createContext();

export const CasesContextProvider = ({ children, initialState = {} }) => {
  const { openModal } = useModal();
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const casesLastGlobalAction = useSelector(({ globalActions }) => globalActions.casesLastGlobalAction);
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialStateConfig, initialState));
  const {
    cases,
    pagination,
    isFetching,
    isFetched,
    selectedCasesIDs,
    selectedCases,
    filter
  } = state;
  const { filter: commonFilter } = useContext(CasesFilterContext);
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const fetchCases = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.CASES_FETCH_REQUEST });

    casesApi.fetchCases({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.CASES_FETCH_SUCCESS, payload: data });
    });
  };

  const deleteCase = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        casesApi.deleteCase(id).then(() => {
          dispatch({ type: types.DELETE_CASE_SUCCESS, payload: id });
          enqueueSnackbar('Case successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Case is not archived!', { variant: 'error' });
        });
      }
    });
  };

  const createCase = () => {
    openModal(CaseModal);
  };

  const updateCase = (caseItem) => {
    openModal(CaseModal, {
      payload: {
        initialValues: { caseItem }
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchCases({ page: filter.page + 1 });
    }
  };

  const resetCases = (newFilter) => {
    dispatch({ type: types.RESET_CASES, payload: newFilter });

    fetchCases({ page: 1, ...newFilter });
  };

  const addCasesToSelected = (cases) => {
    dispatch({ type: types.ADD_CASES_TO_SELECTED, payload: cases });
  };

  const deleteCasesFromSelected = (casesIDs) => {
    dispatch({ type: types.DELETE_CASES_FROM_SELECTED, payload: casesIDs });
  };

  const toggleCaseSelected = (caseItem) => {
    if (selectedCasesIDs.indexOf(caseItem.id) === -1) addCasesToSelected([ caseItem ]);
    else deleteCasesFromSelected([ caseItem.id ]);
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_CASE_SELECTION, payload: id });
  };

  const allCasesIsSelected = () => {
    return cases.length === selectedCasesIDs.length;
  };

  const toggleAllCasesSelection = () => {
    const casesIDs = cases.map(({ id }) => id);

    allCasesIsSelected() ? deleteCasesFromSelected(casesIDs) : addCasesToSelected(cases);
  };

  const toggleOpen = (caseItem) => {
    casesApi.toggleOpen({ id: caseItem.id, is_opened: !caseItem.is_opened, ...caseItem })
      .then((data) => dispatch({ type: types.UPDATE_CASE_IN_LIST, payload: data }));
  };

  const toggleFavorite = (caseItem) => {
    const isFavorite = !caseItem.is_favorite;

    casesApi.toggleFavorite({ id: caseItem.id, is_favorite: isFavorite }).then(() => {
      dispatchRedux(setCasesLastGlobalAction({
        type: types.UPDATE_CASE_IN_LIST,
        payload: { ...caseItem, is_favorite: isFavorite }
      }));

      enqueueSnackbar((isFavorite ? 'Case marked as favorite' : 'Case removed from favorite'), {
        variant: 'success'
      });
    });
  };

  const providerValue = {
    cases,
    isFetching,
    isFetched,
    filter: {
      ...filter,
      ...pagination
    },
    selectedCasesIDs,
    selectedCases,
    loadNextPage,
    resetCases,
    updateCase,
    deleteCase,
    toggleItemSelection,
    allCasesIsSelected,
    toggleAllCasesSelection,
    toggleCaseSelected,
    deleteCasesFromSelected,
    createCase,
    toggleOpen,
    toggleFavorite,
    fetchCases,
    applyFilter
  };

  useEffect(() => {
    casesLastGlobalAction && dispatch(casesLastGlobalAction);
  }, [ casesLastGlobalAction ]);

  useEffect(() => {
    const newFilter = omit({ ...filter, ...commonFilter }, [ 'page' ]);

    if (!isEqual(omit(filter, [ 'page' ]), newFilter)) {
      applyFilter(newFilter);
    }
  }, [ filter, commonFilter ]);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetCases(newFilter);
    }
  }, [ filter, prevFilter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CasesContext.Provider value={providerValue}>
      {children}
    </CasesContext.Provider>
  );
};
