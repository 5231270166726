import { objectKeysToMap } from '../../../../helpers/objectKeysToMap';

export const columnsWidths = {
  from: 160,
  to: 160,
  type: 160,
  disposition: 160,
  startEndDate: 160,
  duration: 120,
  file: 80,
  causer: 200,
  actions: 60
};

export const columnsMap = objectKeysToMap(columnsWidths);
