export const transformToOption = (item) => item && ({
  value: item.value || item.id || item,
  label: item.name || item.label || item,
  data: item.id ? {
    value: item.id,
    label: item.name || item.label
  } : {}
});

export const fetchDataForAsyncSelect = (getPromise, options = {}) => {
  const { transformDataToOption = transformToOption, withoutPagination = false } = options;

  return (search, loadedOptions, { page }) => getPromise(search, { page }).then(({ data, pagination }) => ({
    hasMore: !withoutPagination && (page < pagination.last_page),

    options: data.map((option) => transformDataToOption(option)),

    additional: {
      page: page + 1
    }
  }));
};
