export const styles = ({
  spacing,
  palette: { primary, grey, darkgreen },
  typography: { pxToRem, fontWeightMedium },
  shape: { borderRadius },
  breakpoints
}) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingTop: spacing(2),
    borderBottom: `3px solid ${grey[100]}`
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    padding: spacing(0, 2.5, 2, 2.5)
  },

  leftContent: {
    flexGrow: 1
  },

  rightContent: {
    width: 480
  },

  title: {
    color: darkgreen,
    marginBottom: spacing(3)
  },

  title_amount: {
    marginBottom: spacing()
  },

  titleBalance: {
    marginBottom: spacing()
  },

  mainContent: {
    display: 'flex',
    alignItems: 'stretch',

    [breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },

  mainContentContainer: {
    display: 'flex',
    flexDirection: 'column',

    [breakpoints.up('md')]: {
      minWidth: 300
    }
  },

  mainContentContainer_amount: {
    justifyContent: 'space-between',

    [breakpoints.down('md')]: {
      paddingTop: spacing()
    }
  },

  titleContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(),

    '&:last-child': {
      marginBottom: 0
    }
  },

  subTitle: {
    fontSize: pxToRem(14),
    fontWeight: fontWeightMedium,
    marginRight: spacing(2),
    color: grey[600],
    minWidth: 100
  },

  subTitle__content: {
    color: grey[500],
    fontWeight: 'normal'
  },

  contentInfo: {
    display: 'flex',
    flexDirection: 'column'
  },

  contactTotal: {
    fontWeight: fontWeightMedium,
    color: grey[600]
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: grey[50],
    marginBottom: spacing(3),
    marginRight: 'auto',
    borderRadius
  },

  statusLabel: {
    height: 43,
    fontSize: pxToRem(21),
    color: primary.contrastText
  },

  balance: {
    flexGrow: 1,
    textAlign: 'center',
    fontSize: pxToRem(24),
    fontWeight: fontWeightMedium,
    minWidth: 50,
    paddingLeft: spacing(2),
    paddingRight: spacing(3)
  }
});
