import { makeStyles, List as MuiList, Typography, Box } from '@material-ui/core';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { Header } from './Header';
import { Row } from './Row';
import { styles } from './styles';

export const columnsWidths = {
  name: 230,
  role: 150,
  mobile_phone_number: 150,
  email: 200,
  action: 52
};

const useStyles = makeStyles(styles);

export const List = ({ users, onUserDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        {!users.length ?
          <Box mx={3} my={2}>
            <Typography align="center">No users found</Typography>
          </Box>
          :
          <>
            <Header />

            <Scrollbars autoHeight autoHeightMax={400}>
              <MuiList disablePadding className={classes.list}>
                {users.map((user) => (
                  <Row key={user.id} user={user} onUserDelete={onUserDelete} />
                ))}
              </MuiList>
            </Scrollbars>
          </>
        }
      </div>
    </div>
  );
};
