import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as casesApi from '../../../../../../../../api/cases';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../../../components/Modal';
import EditIcon from '../../../../../../../../components/icons/square-edit-icone.svg';
import { Loader } from '../../../../../../../../components/Loader';
import { CourtInformationForm } from './CourtInformationForm';
import { initialValues, validationSchema } from './CourtInformationForm';

export const EditCourtInformationModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    caseID,
    courtInformation = {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleUpdateCase = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return casesApi.updateCaseCourtInformation(caseID, values).then((caseItem) => {
      enqueueSnackbar('Case successfully updated', { variant: 'success' });
      handleModalResolve(caseItem);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Case not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...courtInformation,

        court_id: courtInformation?.court,
        contact_id: courtInformation?.contact,
        court_number: courtInformation?.court?.court_number,
        address: courtInformation?.court?.full_address || null,
        phone: courtInformation?.court?.phone || null
      }}
      validationSchema={validationSchema}
      onSubmit={handleUpdateCase}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="md" fullScreen={isMobile} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><EditIcon /></SvgIcon>}
              onClose={handleModalReject}
            >
              Edit Court Information
            </ModalHeader>

            <ModalBody>
              <CourtInformationForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
