import { Grid, Typography } from '@material-ui/core';
import { Contact } from '../../../../../../components/Contact';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1} alignItems="center">
      {params?.name &&
        <>
          <Grid item xs={4}>
            <Typography>
              Company Name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.name}
            </Typography>
          </Grid>
        </>
      }

      {params?.company_type_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Company Type:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.company_type_id?.name || '-'}
            </Typography>
          </Grid>
        </>
      }

      {params?.address &&
        <>
          <Grid item xs={4}>
            <Typography>
              Location:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.address}
            </Typography>
          </Grid>
        </>
      }

      {params?.email &&
        <>
          <Grid item xs={4}>
            <Typography>
              Email:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Contact
              variant="h5"
              color="primary"
              type="mailto"
              contact={params?.email}
            />
          </Grid>
        </>
      }
    </Grid>
  );
};
