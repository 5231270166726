import { makeStyles, Typography } from '@material-ui/core';

const styles = ({ spacing }) => ({
  root: {
    padding: spacing(1, 2)
  }
});

const useStyles = makeStyles(styles);

export const NoOptionsMessage = ({ innerProps, children }) => {
  const classes = useStyles();

  return (
    <Typography
      color="textSecondary"
      className={classes.root}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};
