export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },

  main: {
    flexGrow: 1,
    overflow: 'hidden',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),

    [breakpoints.down(breakpoints.values.sm)]: {
      paddingTop: 0
    }
  },

  calendar: {
    height: '100%'
  }
});
