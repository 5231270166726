import { useFormikContext } from 'formik';
import { Grid, Hidden } from '@material-ui/core';
import {
  CompaniesAutocomplete,
  OfficesLocationSelect,
  PhoneField
} from '../../../../../../components/FormField';
import { insuranceTypes, insuranceTypesMap } from '../insuranceDataMaps';
import { ImageForm } from '../ImageForm';
import { UserForm } from '../UserForm';
import { DefendantForm } from './DefendantForm';
import { PlaintiffForm } from './PlaintiffForm';

export const CaseInsurance = ({ mainPage, user, onCaseItemUpdate }) => {
  const { isSubmitting, values, setFieldValue } = useFormikContext();

  const handleCompanyChange = () => {
    setFieldValue('office_id', null);
    setFieldValue('phone_number', null);
  };

  const handleChangeOffice = (option) => {
    const phone = option?.data?.phone || option?.phone || option?.mobile_phone || null;

    setFieldValue('phone_number', phone);
  };

  return (
    <>
      <UserForm mainPage={mainPage} user={user} onCaseItemUpdate={onCaseItemUpdate} />

      <Grid item sm={6} xs={12}>
        <CompaniesAutocomplete
          required
          isCreatable={!isSubmitting}
          name="insurance_company_id"
          label="Insurance company / Law Office"
          margin="dense"
          insuranceInfo={{
            ...values?.insuranceInfo,

            type_insurance: values?.type_insurance,
            status_type: values.status_type
          }}
          params={{ company_type_id: insuranceTypesMap?.[values?.type_insurance] }}
          onChange={handleCompanyChange}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <OfficesLocationSelect
          isClearable
          name="office_id"
          label="Office Location"
          params={{ company_id: values?.insurance_company_id }}
          TextFieldProps={{ required: true, margin: 'dense' }}
          onChange={handleChangeOffice}
        />
      </Grid>

      <Hidden only={[ 'xs' ]}>
        <Grid item sm={6} />
      </Hidden>

      <Grid item sm={6} xs={12}>
        <PhoneField
          isNumericString={false}
          name="phone_number"
          label="Phone Number"
          margin="dense"
        />
      </Grid>

      {values.type_insurance === insuranceTypes.plaintiff ? (
        <PlaintiffForm />
      ) : (
        <DefendantForm />
      )}

      <ImageForm />
    </>
  );
};
