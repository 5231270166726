export const fieldsKeysMap = {
  providers: 'providers',
  offices: 'offices',
  apptBooks: 'appointment_books',
  patients: 'patients',
  arrivalStatuses: 'arrival_statuses',
  appointmentTypes: 'appointment_types',
  visitReasons: 'visit_reasons',
  period: 'period',
  week: 'week',
  action: 'action'
};
