import { useContext } from 'react';
import { Typography, List } from '@material-ui/core';
import { Loader } from '../../../../../../components/Loader';
import { AccountsContext } from '../../../AccountsContext';
import { AccountItem } from './AccountItem';

export const MailboxNav = () => {
  const { isFetched, accounts } = useContext(AccountsContext);

  return (
    <Loader loading={!isFetched} render={
      () => !accounts.length ? (
        <Typography align="center">There is no accounts</Typography>
      ) : (
        <List disablePadding component="nav">
          {accounts.map((account, i) => (
            <AccountItem selected={!i} account={account} key={account.id} />
          ))}
        </List>
      )}
    />
  );
};
