import { Box, makeStyles } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import { IconButton } from '../../IconButton';
import { WidgetsPopper } from '../ActionsBar/WidgetsPopper';
import { styles } from '../ActionsBar/styles';
import { WidgetsMenu } from './WidgetsMenu';

const useStyles = makeStyles(styles);

export const ActionsBar = ({
  checkedWidgetsTypes,
  widgetsMap,
  onReset = () => {},
  onToggleItem = () => {}
}) => {
  const classes = useStyles();
  const checked = Object.entries(checkedWidgetsTypes).filter(([ , value ]) => value).length || 0;
  const total = Object.keys(widgetsMap).length || 0;

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" width="100%" justifyContent="space-between" px={1}>
        <WidgetsPopper
          checked={checked}
          total={total}
          checkedWidgetsTypes={checkedWidgetsTypes}
        >
          <WidgetsMenu
            widgetsMap={widgetsMap}
            checkedWidgetsTypes={checkedWidgetsTypes}
            onReset={onReset}
            onToggleItem={onToggleItem}
          />
        </WidgetsPopper>

        <IconButton
          edge="end"
          color="primary"
          onClick={onReset}
        >
          <BackupIcon />
        </IconButton>
      </Box>
    </div>
  );
};
