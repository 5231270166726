import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { KeyboardDatePicker, TextField } from '../../../../../../components/FormField';
import { DoiForm } from '../../../../../../components/medical/forms';

export const Body = () => {
  return (
    <Grid container spacing={2} justify="space-between" component={Box} px={2} py={3}>
      <Grid item sm={5} xs={12}>
        <KeyboardDatePicker
          name="dor"
          label="Date of Report"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Hidden only="xs">
        <Grid item sm={7} />
      </Hidden>

      <DoiForm />

      <Grid item xs={12}>
        <TextField
          required
          name="name"
          label="Name"
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" fontWeight={500} mt={2} mb={0.5}>
          <NotesIcon fontSize="small"/>

          <Box ml={1}>
            <Typography variant="h5">Note*</Typography>
          </Box>
        </Box>

        <TextField
          required
          fullWidth
          multiline
          rows={5}
          name="note"
          variant="filled"
        />
      </Grid>
    </Grid>
  );
};
