import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles, Button, IconButton } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as codesApi from '../../../../../../api/codes/payer';
import { Loader } from '../../../../../../components/Loader';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { Body } from './Body';
import { Header } from './Header';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Create = ({ baseUrl }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const createCode = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return codesApi.createPayer(values).then(() => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.push(`${baseUrl}/payer`);
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createCode}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form noValidate className={classes.root} onSubmit={handleSubmit}>
          <Page>
            <PageHeader>
              <IconButton component={Link} to={`${baseUrl}/payer`} color="primary" edge="start">
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>

              <PageHeaderTitle>Add Payer</PageHeaderTitle>

              <PageHeaderActions>
                <Button component={Link} to={`${baseUrl}/payer`} color="primary">
                  Cancel
                </Button>

                <Loader
                  surface
                  loading={isSubmitting}
                  render={() => (
                    <Button
                      autoFocus
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />
              </PageHeaderActions>
            </PageHeader>

            <PageBody>
              <Header />
              <Body />
            </PageBody>
          </Page>
        </form>
      )}
    </Formik>
  );
};
