import { useSnackbar } from 'notistack';
import { createContext, useEffect, useReducer, useRef } from 'react';
import { api } from '../../../../../api';
import * as templatesApi from '../../../../../api/files/html-documents';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { initialState as initialStateConfig } from './initialState';
import * as types from './types';
import { reducer } from './reducer';

export const TemplatesContext = createContext(null);

export const TemplatesContextProvider = ({
  children,
  initialState = {},
  templatesLastGlobalAction
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer, { ...initialStateConfig, ...initialState });
  const {
    isFetched,
    isFetching,
    filter,
    pagination,
    templates,
    selectedTemplates
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchTemplates = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_TEMPLATES_REQUEST });

    return templatesApi.fetchTemplates({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_TEMPLATES_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchTemplates({ page: filter.page + 1 });
    }
  };

  const resetTemplates = (newFilter) => {
    dispatch({ type: types.RESET_TEMPLATES, payload: newFilter });

    fetchTemplates({ page: 1, ...newFilter });
  };

  const deleteTemplate = ({ id }) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        templatesApi.deleteTemplate(id).then(() => {
          dispatch({ type: types.DELETE_TEMPLATE, payload: id });
          enqueueSnackbar('Template successfully deleted');
        });
      }
    });
  };

  const addTemplates = (templates) => {
    dispatch({ type: types.ADD_TEMPLATES, payload: templates });
  };

  const providerValue = {
    isFetched,
    isFetching,
    templates,
    selectedTemplates,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetTemplates,
    fetchTemplates,
    loadNextPage,
    deleteTemplate,
    addTemplates
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    templatesLastGlobalAction && dispatch(templatesLastGlobalAction);
  }, [ templatesLastGlobalAction ]);

  return (
    <TemplatesContext.Provider value={providerValue}>
      {children}
    </TemplatesContext.Provider>
  );
};
