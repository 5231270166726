export const EXPENSES_FETCH_REQUEST = 'EXPENSES_FETCH_REQUEST';
export const EXPENSES_FETCH_SUCCESS = 'EXPENSES_FETCH_SUCCESS';
export const ADD_EXPENSE = 'ADD_EXPENSE';
export const UPDATE_EXPENSE_IN_LIST = 'UPDATE_EXPENSE_IN_LIST';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const MASS_DELETE_EXPENSE = 'MASS_DELETE_EXPENSE';
export const RESET_EXPENSES = 'RESET_EXPENSES';
export const TOGGLE_EXPENSE_SELECTION = 'TOGGLE_EXPENSE_SELECTION';
export const TOGGLE_ALL_EXPENSE_SELECTION = 'TOGGLE_ALL_EXPENSE_SELECTION';
export const APPROVE_EXPENSE = 'APPROVE_EXPENSE';
export const DISAPPROVE_EXPENSE = 'DISAPPROVE_EXPENSE';
export const MASS_EXPENSES_APPROVE = 'MASS_EXPENSES_APPROVE';
export const MASS_EXPENSES_DISAPPROVE = 'MASS_EXPENSES_DISAPPROVE';
export const APPLY_FILTER = 'APPLY_FILTER';
