import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../../components/Loader';
import { Scrollbars } from '../../../../../../../../components/Scrollbars';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsMap = {
  number: 'number',
  users: 'users',
  companies: 'companies',
  cases: 'cases',
  appointments: 'appointments',
  claims: 'claims',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  statuses: 'statuses',
  orderBy: 'orderBy',
  action: 'action'
};

export const columnsWidths = {
  [columnsMap.number]: 150,
  [columnsMap.users]: 200,
  [columnsMap.companies]: 200,
  [columnsMap.cases]: 150,
  [columnsMap.appointments]: 165,
  [columnsMap.claims]: 150,
  [columnsMap.dateFrom]: 125,
  [columnsMap.dateTo]: 125,
  [columnsMap.statuses]: 150,
  [columnsMap.orderBy]: 130,
  [columnsMap.action]: 52
};

export const List = ({ hiddenColumns, onApplySavedFilter }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Box maxHeight={500} minWidth={1610}>
        <Loader p={2} loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No saved filters</Typography>
          ) : (
            <>
              <TableHeader hiddenColumns={hiddenColumns} />

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={savedFilters}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  RowProps={{ onApplySavedFilter, hiddenColumns }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </Scrollbars>
  );
};
