export const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },

  tasksWindow: {
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: palette.common.white
  },

  columnRoot: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    flexGrow: 1,
    padding: spacing()
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: spacing(),
    flex: '0 0 300px'
  }
});
