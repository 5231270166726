import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Dialog, Button } from '@material-ui/core';
import ListAddIcon from '@material-ui/icons/PlaylistAdd';
import { setCompaniesTypesLastGlobalAction } from '../../../../../store/globalActions';
import * as typeApi from '../../../../../api/companies';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { TextField } from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import * as companyTypes from '../TypesContext/types';
import { validationSchema } from './validationSchema';

export const CreateTypesModal = ({ DialogProps, handleModalResolve, handleModalReject }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const addType = (value, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return typeApi.createCompanyType(value).then((data) => {
      dispatch(setCompaniesTypesLastGlobalAction({ type: companyTypes.ADD_TYPE, payload: data }));
      enqueueSnackbar('Type successfully created', { variant: 'success' });
      handleModalResolve(value);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Formik initialValues={{ name: '' }} validationSchema={validationSchema} onSubmit={addType}>
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<ListAddIcon />} onClose={handleModalReject}>
              Add New Type
            </ModalHeader>

            <ModalBody>
              <TextField name="name" label="Type name" required margin="dense" placeholder="Enter type name..." />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
