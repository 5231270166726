import { LayoutContextProvider } from '../../../../../components/LayoutContext';
import { ClaimsLayout } from './ClaimsLayout';

export const Claims = (props) => {
  return (
    <LayoutContextProvider>
      <ClaimsLayout {...props} />
    </LayoutContextProvider>
  );
};
