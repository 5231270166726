import moment from 'moment';
import { Grid, Typography, Link as MuiLink } from '@material-ui/core';
import {
  FormPreviewModal
} from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/FormPreviewModal';
import { SigningMembers } from '../../../medical/SigningMembers';
import { useModal } from '../../../ModalsProvider';
import { Divider } from '../../../Divider';
import { Date } from '../../../Date';

export const MedicalFormInfo = ({ medicalForms }) => {
  const { openModal } = useModal();

  const openFormPreviewModal = (formID) => () => {
    openModal(FormPreviewModal, {
      payload: { formID }
    });
  };

  return !medicalForms?.length ? (
    <Typography align="center">No forms found</Typography>
  ) : medicalForms?.map((form, index) => (
    <Grid container key={form.id} spacing={1}>
      <Grid item xs={4}>
        <Typography>Form</Typography>
      </Grid>

      <Grid item xs={8}>
        <MuiLink variant="h5" onClick={openFormPreviewModal(form.id)}>
          {form?.form_type}
        </MuiLink>
      </Grid>

      <Grid item xs={4}>
        <Typography>Created Date</Typography>
      </Grid>

      <Grid item xs={8}>
        <Date variant="h5" date={form.created_at} />
      </Grid>

      <Grid item xs={4}>
        <Typography>Date of Injury</Typography>
      </Grid>

      <Grid item xs={8}>
        <Date disableMoment variant="h5" date={form?.doi_string || moment(form.doi).format('L')} />
      </Grid>

      <Grid item xs={4}>
        <Typography>Sign Info</Typography>
      </Grid>

      <Grid item xs={8}>
        <SigningMembers signInfo={form?.sign_info} />
      </Grid>

      {index > 0 && (
        <Grid item xs={12}>
          <Divider type="dotted" gutter={1} />
        </Grid>
      )}
    </Grid>
  ));
};
