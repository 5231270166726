import { useContext } from 'react';
import {
  List,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SvgIcon
} from '@material-ui/core';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { mdiFileOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { saveAs } from 'file-saver';
import * as emailsApi from '../../../../../../../api/emails';
import { MessagesContext } from '../../../../MessagesContext';

export const DownloadMenu = () => {
  const { filter, messageItem } = useContext(MessagesContext);

  const downloadPdf = () => {
    emailsApi.downloadPdf({
      params: {
        folder_id: filter.folder_id,
        message_uid: messageItem.uid
      }
    }).then(({ data }) => {
      const blob = new Blob([ data ]);

      saveAs(blob, `${messageItem.subject}.pdf`);
    });
  };

  const downloadHtml = () => {
    emailsApi.downloadHTml({
      params: {
        folder_id: filter.folder_id,
        message_uid: messageItem.uid
      }
    }).then(({ data }) => {
      const blob = new Blob([ data ]);

      saveAs(blob, `${messageItem.subject}.html`);
    });
  };

  return (
    <List>
      <MenuItem onClick={downloadPdf}>
        <ListItemIcon>
          <PictureAsPdfOutlinedIcon />
        </ListItemIcon>

        <ListItemText primary="Pdf" />
      </MenuItem>

      <MenuItem onClick={downloadHtml}>
        <ListItemIcon>
          <SvgIcon>
            <Icon path={mdiFileOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Html" />
      </MenuItem>
    </List>
  );
};
