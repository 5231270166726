import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../ListRow';
import { BREAKPOINT_LARGE, widthBreakpointXS, widthBreakpointXXS } from '../Billing';
import {
  columnsWidths,
  widthBreakpointSmall,
  widthBreakpointLarge
} from '../Billing';

export const TableHeader = ({ width }) => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          Inv. #
        </ListRowCell>

        <ListRowCell grow={width < widthBreakpointXXS} flexBasis={columnsWidths.billFor}>
          Bill For
        </ListRowCell>

        {width > widthBreakpointXXS &&
          <ListRowCell flexBasis={columnsWidths.billFrom}>
            Bill From
          </ListRowCell>
        }

        {width > widthBreakpointXS &&
          <ListRowCell flexBasis={columnsWidths.billTo}>
            Bill To
          </ListRowCell>
        }

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.dueDate}>
            Due Date
          </ListRowCell>
        }

        {width > BREAKPOINT_LARGE &&
          <ListRowCell flexBasis={columnsWidths.amount}>
            Amount
          </ListRowCell>
        }

        {width > widthBreakpointSmall &&
          <ListRowCell flexBasis={columnsWidths.balance}>
            Balance Due
          </ListRowCell>
        }

        {width > BREAKPOINT_LARGE &&
          <ListRowCell flexBasis={columnsWidths.paid}>
            Paid
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.status}>
          Status
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
