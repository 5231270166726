import { Children, forwardRef } from 'react';
import cn from 'classnames';
import { makeStyles, AppBar as MuiAppBar, Toolbar } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AppBar = forwardRef(({
  ToolbarProps: {
    className,
    ...ToolbarProps
  } = {},
  ...props
}, ref) => {
  const classes = useStyles();

  return (
    <MuiAppBar ref={ref} {...props}>
      <Toolbar className={cn(classes.toolbar, className)} {...ToolbarProps}>
        {Children.toArray(props.children)}
      </Toolbar>
    </MuiAppBar>
  );
});
