import { makeStyles, Box, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import { getUserFullName, getUserNameAbbr } from '../../../helpers/users';
import { stopPropagation } from '../../../helpers/dom';
import OwnerIcon from '../../icons/owner-label.svg';
import { useModal } from '../../ModalsProvider';
import { UserPreviewModal } from '../../users';
import { Avatar } from '../../Avatar';
import { Badge } from '../../Badge';
import { Fab } from '../../Fab';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Owner = ({ space, role, user = null }) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const openPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id }
    });
  };

  return !!user && (
    <Box mr={2}>
      <Tooltip
        arrow
        title={
          <Box textAlign="center" minWidth={100}>
            <Typography variant="h5" color="inherit">{getUserFullName(user)}</Typography>
            <Typography variant="body2">{role}</Typography>
          </Box>
        }
      >
        <Fab
          size="small"
          onClick={stopPropagation(openPreview(user.id))}
        >
          <Badge
            overlap="circle"
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            badgeContent={
              <SvgIcon className={classes.badgeIcon}>
                <OwnerIcon />
              </SvgIcon>
            }
          >
            <Avatar
              size={space > 0 ? 'lg' : 'md'}
              src={user?.profile?.avatar || user.avatar}
              alt={getUserNameAbbr(user)}
            />
          </Badge>
        </Fab>
      </Tooltip>
    </Box>
  );
};
