import { useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../components/ListRow';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { EditTypesModal } from '../../EditTypesModal';
import { TypesContext } from '../../TypesContext';
import { columnsWidths, minRowHeight } from '../List';

export const Row = ({ item: type = {}, isLoaded, recalculateHeight }) => {
  const { types, deleteType } = useContext(TypesContext);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const handleTypeDelete = () => {
    if (type.is_system) {
      enqueueSnackbar('This type of company is used, you can\'t delete it', { variant: 'warning' });
    } else {
      deleteType(type.id);
    }
  };

  const handleTypeUpdate = () => {
    openModal(EditTypesModal, {
      payload: {
        initialValues: { type }
      }
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, types.length ]);

  return (
    <ListRow minHeight={minRowHeight}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap title={type.name}>
          {type.name}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        {!type.is_system &&
          <ListRowCellActions>
            <IconButton color="primary" onClick={handleTypeUpdate}>
              <EditIcon />
            </IconButton>

            <IconButton color="error" onClick={handleTypeDelete}>
              <DeleteIcon />
            </IconButton>
          </ListRowCellActions>
        }
      </ListRowCell>
    </ListRow>
  );
};
