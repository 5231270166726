import { api } from '../../api';

export const fetchPOSCodes = (config) => {
  return api.get('/procedure-codes/pos-codes', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchPOSCode = (id, config) => {
  return api.get(`/procedure-codes/pos-codes/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createPOSCode = (data) => {
  return api.post('/procedure-codes/pos-codes', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updatePOSCode = ({ id, ...data }) => {
  return api.put(`/procedure-codes/pos-codes/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deletePOSCode = (id) => {
  return api.delete(`/procedure-codes/pos-codes/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massActivePOSCodes = (IDs) => {
  return api.post('/procedure-codes/pos-codes/active', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massInactivePOSCodes = (IDs) => {
  return api.post('/procedure-codes/pos-codes/inactive', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeletePOSCodes = (IDs) => {
  return api.delete('procedure-codes/pos-codes', { params: { id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
