import { useEffect, useState } from 'react';
import { Dialog, useMediaQuery, Grid, Typography } from '@material-ui/core';
import * as templatesApi from '../../../../../api/cases/task-template-groups';
import { Loader } from '../../../../../components/Loader';
import {
  ModalContainer,
  ModalBody,
  ModalHeader
} from '../../../../../components/Modal';

export const PreviewModal = ({
  DialogProps,
  payload: {
    id
  },
  handleModalReject
}) => {
  const [ group, setGroup ] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  useEffect(() => {
    if (!id) return;

    templatesApi.fetchTaskTemplateGroup(id)
      .then(setGroup)
      .catch((error) => error && handleModalReject());
  }, [ id ]);

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      {!group ? <Loader p={2} /> : (
        <ModalContainer>
          <ModalHeader onClose={handleModalReject}>
            {group.name}
          </ModalHeader>

          <ModalBody>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary">
                  Templates:
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {group?.templates?.map((template) => (
                  <Grid key={template.id} container spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">{template.name}</Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Typography color={!!template.description ? 'textPrimary' : 'textSecondary'}>
                        {template.description || 'No info.'}
                      </Typography>
                    </Grid>
                  </Grid>
                )) || (
                  <Typography color="textSecondary">No info.</Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4" color="primary">
                  Description:
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  {group.description}
                </Typography>
              </Grid>
            </Grid>
          </ModalBody>
        </ModalContainer>
      )}
    </Dialog>
  );
};
