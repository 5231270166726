import { FieldArray } from 'formik';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import NotesIcon from '@material-ui/icons/Notes';
import AddIcon from '@material-ui/icons/Add';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { NdcCodesSelect, TextField } from '../../../../../../components/FormField';
import { IconButton } from '../../../../../../components/IconButton';
import { Fab } from '../../../../../../components/Fab';
import { useModal } from '../../../../../../components/ModalsProvider';

export const medicationsInitialValues = {
  ndc_code_id: null,
  dose: null,
  qty: null,
  note: null
};

export const Medications = ({ name = 'medications', values, index }) => {
  const { openModal } = useModal();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  return (
    <FieldArray
      name={name}
      render={({ remove, push }) => (
        <>
          <Box mt={1} mb={2} color="info.main" display="flex" alignItems="center">
            <Typography variant="h5" color="inherit">
              Medications
            </Typography>

            <Box component="span" ml={2}>
              <Fab
                size="small"
                color="primary"
                onClick={() => push(medicationsInitialValues)}
              >
                <AddIcon/>
              </Fab>
            </Box>
          </Box>

          {values?.map((item, i) => (
            <Box display="flex" flexWrap="nowrap" mb={3} key={i}>
              <Box mr={0.5} mt={3.5} alignSelf="flex-start">
                <Typography variant="h5" component="span">
                  {index ? `${index} - ${i + 1}.` : `${i + 1}.`}
                </Typography>
              </Box>

              <Box flex={1} px={0.5}>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item sm={6} xs={12}>
                    <NdcCodesSelect
                      name={`${name}.${i}.ndc_code_id`}
                      label="Medication Name / Drag Name"
                      TextFieldProps={{ required: true }}
                    />
                  </Grid>

                  <Hidden only="xs">
                    <Grid item xs={6} />
                  </Hidden>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      required
                      name={`${name}.${i}.qty`}
                      label="Package Quantity"
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      required
                      name={`${name}.${i}.dose`}
                      label="Dose"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" mt={1}>
                      <Box mr={0.5} fontWeight={500}>
                        <NotesIcon fontSize="small" />
                      </Box>

                      <Typography variant="h5">Note</Typography>
                    </Box>

                    <TextField
                      fullWidth
                      multiline
                      disableUnderline
                      rows={2}
                      rowsMax={4}
                      name={`${name}.${i}.note`}
                      variant="filled"
                    />
                  </Grid>
                </Grid>
              </Box>

              {values?.length > 1 &&
                <Box mt={3} alignSelf="flex-start">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={handleClearField(remove, i)}
                  >
                    <ClearIcon fontSize="small"/>
                  </IconButton>
                </Box>
              }
            </Box>
          ))}
        </>
      )}
    />
  );
};
