import { Box, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { formTypesMap } from '../../medical/forms';
import {
  symptomsInjuryTypesList,
  symptomsMap,
  symptomsTypesList,
  symptomsTypesMap
} from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/MedicalFormsProvider';
import { ReadonlyCheckbox } from '../../ReadonlyCheckbox';

export const Symptoms = ({ type, symptoms }) => {
  const symptomsList = type === formTypesMap.injury ? symptomsInjuryTypesList : symptomsTypesList;

  return isEmpty(symptoms) ? (
    <Typography color="textSecondary">No info.</Typography>
  ) : (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      {symptomsList.map((item, index) => !!symptoms?.[item]?.length && (
        <Box key={index} pr={2} minWidth={220} mb={1}>
          <Box display="flex" alignItems="center" mb={1}>
            <Box component="span" mr={1}>{symptomsTypesMap[item].icon}</Box>
            <Typography variant="h5">{symptomsTypesMap[item].name}</Typography>
          </Box>

          <Box display="flex" flexDirection="column">
            {symptoms?.[item].map((symptom, index) => (
              <ReadonlyCheckbox
                key={index}
                checked
                title={symptomsMap[symptom]}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
