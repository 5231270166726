import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { NotificationsContent } from '../../../../PatientsPage/NotificationsPage/NotificationsContent';

export const Notifications = () => {
  const params = useParams();

  return (
    <Box width="100%" height="100%">
      <NotificationsContent userID={params.userID} />
    </Box>
  );
};
