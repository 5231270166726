const WORKER_PATH = './recorderWorker.js';

export const Recorder = function(source, cfg) {
  const worker = new Worker(WORKER_PATH);
  const config = cfg || {};
  const bufferLen = config.bufferLen || 4096;

  this.context = source.context;
  this.node = (
    this.context.createScriptProcessor ||
    this.context.createJavaScriptNode
  ).call(this.context, bufferLen, 2, 2);

  worker.onmessage = function(e) {
    const blob = e.data;
    currCallback(blob);
  };

  worker.postMessage({
    command: 'init',
    config: {
      sampleRate: this.context.sampleRate
    }
  });

  let recording = false;
  let currCallback = null;

  this.node.onaudioprocess = function(e) {
    if (!recording) return;
    worker.postMessage({
      command: 'record',
      buffer: [
        e.inputBuffer.getChannelData(0),
        e.inputBuffer.getChannelData(1)
      ]
    });
  };

  this.configure = function(cfg) {
    for (const prop in cfg){
      if (cfg.hasOwnProperty(prop)){
        config[prop] = cfg[prop];
      }
    }
  };

  this.record = function() {
    recording = true;
  };

  this.stop = function() {
    recording = false;
  };

  this.clear = function() {
    worker.postMessage({ command: 'clear' });
  };

  this.getBuffer = function(cb) {
    currCallback = cb || config.callback;
    worker.postMessage({ command: 'getBuffer' });
  };

  this.exportWAV = function(cb, type) {
    currCallback = cb || config.callback;
    type = type || config.type || 'audio/wav';
    if (!currCallback) throw new Error('Callback not set');
    worker.postMessage({
      command: 'exportWAV',
      type: type
    });
  };

  source.connect(this.node);
  this.node.connect(this.context.destination);    //this should not be necessary
};

Recorder.forceDownload = function(blob, filename) {
  const url = (window.URL || window.webkitURL).createObjectURL(blob);
  const link = window.document.createElement('a');

  link.href = url;
  link.download = filename || 'output.wav';

  const click = document.createEvent('Event');

  click.initEvent('click', true, true);
  link.dispatchEvent(click);
};
