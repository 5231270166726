import { useContext, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { FormControlLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { saveFilter } from '../../../../../../store/lastFilters';
import { TextField, Switch } from '../../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { IconComponent } from '../../../../../../components/saved-filters';
import { ContactsContext } from '../../ContactsProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { initialValues } from './initialValues';
import { CardContent } from './CardContent';
import { List } from './List';

const MODAL_WIDTH = 520;

export const FiltersBar = ({ filterKey }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { resetContacts } = useContext(ContactsContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleFieldChange = debounce((event) => {
    const value = event.target.value || null;

    resetContacts({ [filterFieldsMap.search]: value });
    setRelationsForFilter((state) => ({ ...state, [filterFieldsMap.search]: value }));
  }, 600);

  const toggleIsFavorite = ({ target: { checked } }) => {
    const value = +checked || null;

    resetContacts({ [filterFieldsMap.isFavorite]: value });
    setRelationsForFilter((state) => ({ ...state, [filterFieldsMap.isFavorite]: value }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(initialValues);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={initialValues}
      onReset={() => resetContacts(initialValues)}
      filterKey={filterKey}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.search,
          label: filterFieldsLabels[filterFieldsMap.search],
          field: <TextField
            name="search"
            label="Search"
            placeholder="Search contact..."
            onChange={handleFieldChange}
          />
        },
        {
          fieldKey: filterFieldsMap.isFavorite,
          label: filterFieldsLabels[filterFieldsMap.isFavorite],
          field: <FormControlLabel
            label={relationsForFilter.is_favorite ? 'Show all' : 'Show favorite'}
            control={
              <Switch
                name="correctionValue"
                color="primary"
                value={1}
                onChange={toggleIsFavorite}
              />
            }
          />
        }
      ]}
    />
  );
};
