import { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { columnsMap } from '../../../../../claims';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../ListRow';
import { ReportsContext } from '../../ReportsProvider';
import {
  columnsWidths,
  widthBreakpointXS,
  widthBreakpointSmall,
  widthBreakpointMedium,
  widthBreakpointLarge
} from '../DiagnosisList';

export const Header = ({
  width,
  disablePatient = false,
  hiddenColumns = [],
  ListRowProps = {}
}) => {
  const { selectedIDs, allItemsIsSelected, toggleAllItemsSelection } = useContext(ReportsContext);

  return (
    <ListRow header {...ListRowProps}>
      {!hasRole(rolesMap.client, rolesMap.patient) && !hiddenColumns.includes(columnsMap.checkbox) &&
        <ListRowCheckbox
          indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
          checked={allItemsIsSelected()}
          onClick={toggleAllItemsSelection}
        />
      }

      {!disablePatient && (
        <ListRowCell grow flexBasis={columnsWidths.patient}>
          Patient
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.date}>
        Date/Update of Report
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointLarge} flexBasis={columnsWidths.type}>
        <Typography noWrap variant="h5">
          Type of Medical Report
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.diagnosis}>
        Diagnosis(DX)
      </ListRowCell>

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.doi_date}>
          Date of Injury
        </ListRowCell>
      }

      <ListRowCell grow={width < widthBreakpointMedium} flexBasis={columnsWidths.provider}>
        <Typography noWrap variant="h5">
          Primary Care Physician/Location
        </Typography>
      </ListRowCell>

      {width > widthBreakpointXS &&
        <ListRowCell flexBasis={columnsWidths.signed}>
          Signed
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.actions) &&
        <ListRowCell flexBasis={columnsWidths.actions} />
      }
    </ListRow>
  );
};
