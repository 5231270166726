import { Dialog } from '@material-ui/core';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import { PaymentsContextProvider } from '../PaymentsContext';
import { ModalContainer, ModalHeader, ModalBody } from '../../../Modal';
import { List } from './List';

export const PaymentsModal = ({
  payload: {
    filter = {}
  },
  DialogProps,
  handleModalReject
}) => {
  return (
    <Dialog maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader icon={<LocalAtmOutlinedIcon />} onClose={handleModalReject}>
          Payments
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <PaymentsContextProvider filter={filter}>
            <List />
          </PaymentsContextProvider>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
