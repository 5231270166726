import { isEqual, omit } from 'lodash';
import { createContext, useRef, useEffect, useReducer } from 'react';
import { api } from '../../../../../api';
import * as filesApi from '../../../../../api/files';
import { usePrevious } from '../../../../../helpers/hooks';
import { initialState } from './initialState';
import * as types from './types';
import { reducer } from './reducer';

export const SearchFilesContext = createContext(null);

export const SearchFilesContextProvider = ({
  children,
  filesLastGlobalAction
}) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    isFetched,
    isFetching,
    filter,
    pagination,
    files
  } = state;
  const cancelFetch = useRef(() => {});
  const prevFilter = usePrevious(filter);

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const fetchFiles = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FILES_REQUEST });

    filesApi.searchFiles({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_FILES_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFiles({ page: filter.page + 1 });
    }
  };

  const resetFiles = (newFilter) => {
    dispatch({ type: types.RESET_FILES, payload: newFilter });

    fetchFiles({ page: 1, ...newFilter });
  };

  const providerValue = {
    isFetched,
    isFetching,
    files,
    filter,
    pagination,

    // functions
    applyFilter,
    resetFiles,
    fetchFiles,
    loadNextPage
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetFiles(newFilter);
    }
  }, [ filter, prevFilter ]);

  useEffect(() => {
    filesLastGlobalAction && dispatch(filesLastGlobalAction);
  }, [ filesLastGlobalAction ]);

  return (
    <SearchFilesContext.Provider value={providerValue}>
      {children}
    </SearchFilesContext.Provider>
  );
};
