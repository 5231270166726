import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { makeStyles, Collapse, Grid, Typography, Box } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { useModal } from '../../../ModalsProvider';
import { IconButton } from '../../../IconButton';
import { TextField } from '../../../FormField';
import { CardItem } from '../../../Cards';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MedicationsCards = ({ arrayHelpers, onAddFields }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { values } = useFormikContext();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  return (
    <Collapse
      unmountOnExit
      in={!!get(values, 'forms.medical_history.current_medications.length')}
      timeout="auto"
    >
      <div className={classes.cardWrapper}>
        {!!get(values, 'forms.medical_history.current_medications.length') &&
          values.forms.medical_history.current_medications.map((medication, index) => (
            <CardItem key={index}>
              <Grid container spacing={2} component={Box} m="0 !important" pr={2}>
                <Grid item xs={10} />

                <Grid item xs={2}>
                  <Box textAlign="right">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={handleClearField(arrayHelpers, index)}
                    >
                      <ClearIcon fontSize="small"/>
                    </IconButton>
                  </Box>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="h5">Medication Name</Typography>
                </Grid>

                <Grid item xs={7}>
                  <TextField
                    autoFocus
                    placeholder="Add..."
                    name={`forms.medical_history.current_medications.${index}.name`}
                    className={classes.textField}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="h5">Dosage</Typography>
                </Grid>

                <Grid item xs={7}>
                  <TextField
                    placeholder="Add..."
                    name={`forms.medical_history.current_medications.${index}.dosage`}
                    className={classes.textField}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="h5">Frequency</Typography>
                </Grid>

                <Grid item xs={7}>
                  <TextField
                    placeholder="Add..."
                    name={`forms.medical_history.current_medications.${index}.frequency`}
                    className={classes.textField}
                    onKeyDown={onAddFields(arrayHelpers, {
                      name: '',
                      dosage: '',
                      frequency: '',
                      take: ''
                    })}
                  />
                </Grid>
              </Grid>
            </CardItem>
          ))}
      </div>
    </Collapse>
  );
};
