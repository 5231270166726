import { createHookReducer } from '../../../../../helpers/createHookReducer';
import { toggleActive } from '../../../BillingPage/BillingSettings/BillingSettingsContext/reducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.CATEGORIES_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.CATEGORIES_FETCH_SUCCESS]: (
    { filter, categories, ...state },
    { data: newCategory, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      categories: (page > 1 ?
      // eslint-disable-next-line max-len
        categories.filter(({ id }) => !newCategory.find((loadedCategory) => id === loadedCategory.id)).concat(newCategory)
        :
        newCategory
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.ADD_CATEGORY]: (state, payload) => {
    const { pagination, filter, categories, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((categories.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, total, page },
      categories: [ ...categories, payload ]
    };
  },

  [types.UPDATE_CATEGORY_IN_LIST]: (state, payload) => {
    return {
      ...state,

      categories: state.categories.map((item) => {
        return item.id === payload.id ? payload : item;
      })
    };
  },

  [types.DELETE_CATEGORY]: ({ categories, ...state }, categoryId) => {
    return {
      ...state,

      categories: categories.filter(({ id }) => id !== categoryId)
    };
  },

  [types.RESET_CATEGORIES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.TOGGLE_ACTIVATE_CATEGORY]: ({ categories, ...state }, payload) => {
    toggleActive(categories, payload);

    return { ...state, categories };
  }
});
