import { LayoutContextProvider } from '../../../../components/LayoutContext';
import { PageContent } from './PageContent';

export const TranscribesPage = () => {
  return (
    <LayoutContextProvider>
      <PageContent />
    </LayoutContextProvider>
  );
};
