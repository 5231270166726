import moment from 'moment';
import { useContext } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import NotesIcon from '@material-ui/icons/Notes';
import { stopPropagation } from '../../../../../../helpers/dom';
import { generateAddress } from '../../../../../../utils/generateAddress';
import { UserPreviewModal } from '../../../../../../components/users';
import { useModal } from '../../../../../../components/ModalsProvider';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Date } from '../../../../../../components/Date';
import { ReportsPreviewContext } from '../../../ReportsPreviewProvider';

export const MainContent = () => {
  const { openModal } = useModal();
  const { selectedItem: immunization, appointment } = useContext(ReportsPreviewContext);

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" component={Box} pt={3}>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary">Patient</Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <UserLink
            variant="h5"
            user={immunization.patient}
            onClick={stopPropagation(openUserPreview(immunization?.patient?.id))}
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography noWrap color="textSecondary">Provider/Approved by</Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <UserLink
            variant="h5"
            user={appointment?.appointment_book?.provider}
            onClick={stopPropagation(openUserPreview(appointment?.appointment_book?.provider?.id))}
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary">Date</Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Box display="flex" alignItems="center">
            <Date format="L" iconFontSize="small" variant="h5" date={immunization?.appointment?.appointment_at} />

            <Box clone ml={1.5} mr={0.5}>
              <TimelapseIcon fontSize="small" />
            </Box>

            <Typography variant="h5">
              {moment.duration(immunization?.appointment?.time, 'seconds').format('h[h] m[min]')}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary">Office</Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Tooltip
            isExistTooltip={!!immunization?.office}
            title={immunization?.office?.full_address || generateAddress(immunization?.office)}
          >
            <OfficeLink
              variant={immunization?.office ? 'h5' : 'caption'}
              office={immunization?.office}
            />
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="flex-start" component={Box} py={5}>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary">Type Insurance</Typography>
        </Grid>

        <Grid item sm={9} xs={6}>
          <Typography variant="h5">
            {appointment?.patient_insurance?.type_insurance || '-'}
          </Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary">Insurance ID</Typography>
        </Grid>

        <Grid item sm={9} xs={6}>
          <Typography variant="h5">
            {appointment?.patient_insurance?.insuranceId || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} component={Box} pb={5}>
        <Grid item sm={3} xs={6}>
          <Typography noWrap variant="h5">Immunization name</Typography>
        </Grid>

        <Grid item sm={3} xs={6}>
          <Box color="info.main">
            <Typography variant="h5" color="inherit">
              {immunization?.immunization?.name}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography>
            {immunization?.immunization?.description}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center" fontWeight={500} mb={0.5}>
            <NotesIcon fontSize="small"/>
            <Typography variant="subtitle2">Notes</Typography>
          </Box>

          <Typography>{immunization.note || 'No info'}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
