export const initialValues = {
  code: null,
  description: null,
  time: null,
  _minutes: null,
  _hours: null,
  ordering: null,
  color: null,
  claim_required: false,
  out_of_office_visit: false,
  new_visit: false,
  is_active: true
};
