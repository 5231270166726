import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Dialog, Button, SvgIcon } from '@material-ui/core';
import { mdiPlaylistEdit } from '@mdi/js';
import { Icon } from '@mdi/react';
import { setCompaniesTypesLastGlobalAction } from '../../../../../store/globalActions';
import * as typeApi from '../../../../../api/companies';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { TextField } from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import * as companyTypes from '../TypesContext/types';
import { validationSchema } from '../CreateTypesModal';

export const EditTypesModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { initialValues = {} }
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const updateType = (value, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return typeApi.updateCompanyType(value).then((data) => {
      dispatch(setCompaniesTypesLastGlobalAction({ type: companyTypes.UPDATE_TYPE, payload: data }));
      enqueueSnackbar('Type successfully updated', { variant: 'success' });
      handleModalResolve(value);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Formik initialValues={initialValues?.type} validationSchema={validationSchema} onSubmit={updateType}>
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <Icon path={mdiPlaylistEdit} />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Edit Type
            </ModalHeader>

            <ModalBody>
              <TextField
                name="name"
                label="Type name"
                required
                margin="dense"
                placeholder="Enter type name..."
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
