import { createContext, useRef, useEffect, useReducer } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSelector } from 'react-redux';
import { usePrevious } from '../../../helpers/hooks';
import { api } from '../../../api';
import * as feedsApi from '../../../api/feeds';
import { Echo } from '../../../utils/echo';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const FeedsContext = createContext();

export const FeedsProvider = ({ filter: filterProp = {}, children }) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    feeds,
    selectedFeedsIDs,
    selectedFeeds
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchFeeds = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FEEDS_REQUEST });

    feedsApi.fetchFeeds({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_FEEDS_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const addFeed = (feed) => {
    dispatch({ type: types.ADD_FEED, payload: feed });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFeeds({ page: filter.page + 1 });
    }
  };

  const resetFeeds = (newFilter) => {
    dispatch({ type: types.RESET_FEEDS, payload: newFilter });

    fetchFeeds({ page: 1, ...newFilter });
  };

  const providerValue = {
    isFetched,
    isFetching,
    feeds,
    selectedFeedsIDs,
    selectedFeeds,
    filter: filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    resetFeeds,
    fetchFeeds,
    loadNextPage,
    applyFilter
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      resetFeeds(newFilter);
    }
  }, [ filter, filterProp ]);


  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ] );

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetFeeds(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    const usersChanel = Echo.private(`users.${currentUser.id}`);

    const handleFeedCreated = ({ feed }) => {
      addFeed(feed);
    };

    usersChanel
      .listen('.feed.created', handleFeedCreated);

    return () => {
      usersChanel
        .stopListening('.feed.created', handleFeedCreated);

      cancelFetch.current();
    };
  }, []);

  return (
    <FeedsContext.Provider value={providerValue}>
      {children}
    </FeedsContext.Provider>
  );
};
