import { useEffect } from 'react';
import { Box, Dialog, useMediaQuery } from '@material-ui/core';
import { ExpensesContextProvider } from '../../../app/Dashboard/BillingPage/Invoices/Expenses';
import { Expenses as List, FiltersBar } from '../../../app/Dashboard/BillingPage/Invoices/InvoicesPage/ExpensesContent';
import { Grid } from '../../../app/Dashboard/BillingPage/Invoices/InvoicesPage/ExpensesContent/Grid';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { filtersKeysMap } from '../../../store/lastFilters';
import { ModalBody, ModalContainer, ModalHeader, ModalHeaderActions } from '../../Modal';
import { expensesStatusIDsMap } from '../../billing';

export const ExpensesListModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    toggleFiltersBar
  } = useMobileFilterCollapse();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  const handleExpenseSelect = (invoice) => {
    handleModalResolve(invoice);
  };

  useEffect(() => {
    toggleFiltersBar();
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Choose Expense

          <ModalHeaderActions>
            {isTablet && filterOpenButton}
          </ModalHeaderActions>
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <ExpensesContextProvider
            initialState={{
              filter: {
                status_id: expensesStatusIDsMap.approved,
                has_invoice: 0
              }
            }}
          >
            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                <FiltersBar
                  disableInitialFilters
                  filterKey={filtersKeysMap.expenses_modal}
                />
              </FilterBarWrapper>
            </ScrollWrapper>

            <Box height="100%" display="flex" flexDirection="column" minHeight={500}>
              {!isMobile
                ? <List onExpenseSelect={handleExpenseSelect} />
                : <Grid onExpenseSelect={handleExpenseSelect} />
              }
            </Box>
          </ExpensesContextProvider>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
