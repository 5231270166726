import { flow } from 'lodash';
import { useSnackbar } from 'notistack';
import { MenuItem, ListItemIcon, ListItemText, List } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import * as publicAccessApi from '../../../../../../../api/public-access';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { hasRole } from '../../../../../../../utils/hasRole';
import { EmailsSendModal } from '../../../../../EmailsPage/EmailsClient/EmailsSendModal';
import { AccessControlContext } from '../../../AccessControlProvider';
import { ExtendAccessModal } from './ExtendAccessModal';

export const Menu = ({ isExpired, access, onClose = {} }) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const { resetEmails } = useContext(AccessControlContext);

  const sendEmail = () => {
    if (!hasRole('client', 'patient') && currentUser?.email_accounts?.length) {
      openModal(EmailsSendModal, {
        payload: {
          accounts: currentUser?.email_accounts,
          to: [ access.access_email ]
        }
      });
    } else if (!hasRole('client') && !currentUser?.email_accounts?.length) {
      enqueueSnackbar('Please add an account' , { variant: 'warning' });
    }
  };

  const copyLink = () => {
    const link = `${window.location.origin}/public-share/files?token=${access.access_token}`;

    navigator.clipboard.writeText(link).then(() => {
      enqueueSnackbar('Link copied to clipboard', { variant: 'success' });
    }, () => {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    });
  };

  const extendAccess = () => {
    openModal(ExtendAccessModal, {
      payload: {
        access
      },

      onModalResolved: () => {
        enqueueSnackbar('Access successfully extended', { variant: 'success' });
        resetEmails();
      }
    });
  };

  const deleteAccess = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        publicAccessApi.deleteAccess(access.id).then(() => {
          enqueueSnackbar('Access successfully deleted', { variant: 'success' });
          resetEmails();
        });
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={flow(sendEmail, onClose)}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>

        <ListItemText primary="Send email" />
      </MenuItem>

      <Tooltip isExistTooltip={isExpired} title="Access is expired">
        <div>
          <MenuItem disabled={isExpired} onClick={flow(copyLink, onClose)}>
            <ListItemIcon>
              <LinkOutlinedIcon />
            </ListItemIcon>

            <ListItemText primary="Copy access link" />
          </MenuItem>
        </div>
      </Tooltip>

      <MenuItem onClick={flow(extendAccess, onClose)}>
        <ListItemIcon>
          <AutorenewIcon />
        </ListItemIcon>

        <ListItemText primary="Extend access" />
      </MenuItem>

      <MenuItem onClick={flow(deleteAccess, onClose)}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>

        <ListItemText primary="Delete access" />
      </MenuItem>
    </List>
  );
};
