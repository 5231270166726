import { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControlLabel, Grid, InputAdornment, useMediaQuery, useTheme } from '@material-ui/core';
import { CasesSelect } from '../../../../components/cases/CasesSelect';
import {
  TextField,
  TaskStatusesSelect,
  TaskTypesSelect,
  Checkbox,
  TaskPrioritySelect,
  CompaniesAutocomplete,
  KeyboardDatePicker
} from '../../../../components/FormField';
import { UsersSelect } from '../../../../components/users';

const filterUsers = (users) => {
  return users?.filter((user, index) => (
    users.findIndex(({ id }) => (id === user.id)) === index
  )) || [];
};

const getTaskUsers = (users, filterUsers) => (
  users.filter((user) => !filterUsers.find((filterUser) => user.value === filterUser.value))
);

export const Body = ({ task }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { values, setFieldValue } = useFormikContext();
  const [ caseUsers, setCaseUsers ] = useState(task?.parent?.case_users || []);
  const transformCaseUser = useMemo(() => (
    filterUsers(caseUsers?.reduce((users, { user }) => {
      return users.concat(user || []);
    }, []))
  ), [ caseUsers ]);

  const handleUsersChange = (users) => {
    if (!users?.length) {
      setFieldValue('is_checked', false);
    }
  };

  const handleCaseChange = (caseItem) => {
    setCaseUsers(caseItem?.case_users || []);
    setFieldValue('is_checked', false);
    setFieldValue('users', []);
  };

  const toggleCaseUsers = (event) => {
    const { checked } = event.target;

    if (checked) {
      const filteredUsers = getTaskUsers(transformCaseUser, values.users);
      setFieldValue('users', [ ...(values.users || []), ...filteredUsers ]);
    } else {
      const filteredUsers = getTaskUsers(values.users, transformCaseUser);
      setFieldValue('users', filteredUsers);
    }
  };

  return (
    <Grid container spacing={3} justify="space-between" alignItems="center">
      <Grid item xs={isMobile ? 12 : 7}>
        <TextField
          required
          name="name"
          label="Task name"
          placeholder="Enter task name..."
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <TaskStatusesSelect />
      </Grid>

      <Grid item xs={isMobile ? 12 : 7}>
        <CasesSelect
          name="parent_id"
          label="Case"
          placeholder="Select case..."
          onChange={handleCaseChange}
        />
      </Grid>

      {!isMobile && <Grid item xs={4} />}

      <Grid item xs={isMobile ? 12 : 7} container justify="space-between">
        <Grid item xs={isMobile ? 12 : 4}>
          <Box mb={isMobile ? 1 : 0}>
            <TaskPrioritySelect
              name="priority"
              label="Priority"
            />
          </Box>
        </Grid>

        <Grid item xs={isMobile ? 12 : 6}>
          <TaskTypesSelect
            name="type_id"
            label="Type"
          />
        </Grid>
      </Grid>

      <Grid item xs={isMobile ? 12 : 7}>
        <CompaniesAutocomplete
          name="company_id"
          label="Users from company"
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <KeyboardDatePicker
          name="due_at"
          label="Deadline"
          placeholder="Select due date..."
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 7}>
        <UsersSelect
          multiple
          name="users"
          label="Select users"
          params={{ company_id: values?.company_id }}
          onChange={handleUsersChange}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4} container justify="space-between">
        <Grid item xs={5}>
          <TextField
            zeroMinWidth
            type="number"
            name="hours"
            label="Hours"
            margin="dense"
            placeholder="Hours"
            InputProps={{
              endAdornment: (<InputAdornment position="end">h</InputAdornment>)
            }}
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            zeroMinWidth
            type="number"
            name="minutes"
            label="Minutes"
            margin="dense"
            placeholder="Minutes"
            InputProps={{
              endAdornment: (<InputAdornment position="end">m</InputAdornment>)
            }}
          />
        </Grid>
      </Grid>

      {!!caseUsers?.length &&
        <Grid item xs={isMobile ? 12 : 3}>
          <FormControlLabel
            control={
              <Checkbox
                name="is_checked"
                size="small"
                color="primary"
                onChange={toggleCaseUsers}
              />
            }
            label="Add users from case"
          />
        </Grid>
      }

      <Grid item xs={12}>
        <TextField
          multiline
          rows={8}
          name="description"
          label="Description"
          margin="dense"
          variant="filled"
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
    </Grid>
  );
};
