import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { CurrencyFormat } from '../../../../components/CurrencyFormat';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PaymentStatusCard = ({
  isActive,
  isHovered,
  name,
  count,
  amount,
  color,
  className,
  ...props
}) => {
  const classes = useStyles({ color });

  return (
    <div
      className={
        cn(
          classes.card,
          { [classes.card_hovered]: isHovered },
          { [classes.card_active]: isActive },
          className
        )
      }
      {...props}
    >
      <div className={classes.top}>
        <Typography className={classes.name}>
          {name}
        </Typography>

        <Typography className={cn(classes.count)}>
          {count || 0}
        </Typography>
      </div>

      <CurrencyFormat value={amount} className={classes.amount} />
    </div>
  );
};
