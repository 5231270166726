import { Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { columnsLargeWidths, columnsMobileWidths } from '../List';

export const TableHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const columnsWidths = isMobile ? columnsMobileWidths : columnsLargeWidths;

  return (
    <Paper elevation={0}>
      <ListRow header px={2}>
        <ListRowCell grow noWrap flexBasis={columnsWidths.authorization}>
          Authorization #
        </ListRowCell>

        {!isMobile && (
          <ListRowCell flexBasis={columnsWidths.effectiveDate}>
            Effective Date
          </ListRowCell>
        )}

        <ListRowCell flexBasis={columnsWidths.expirationDate}>
          Expiration Date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.isPending}>
          Is Pending
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
