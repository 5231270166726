import { Fragment } from 'react';
import { Grid, Typography, Tooltip, Box, SvgIcon } from '@material-ui/core';
import { mdiMapMarker } from '@mdi/js';
import { Icon } from '@mdi/react';
import { generateAddress } from '../../../utils/generateAddress';
import { CompanyLink } from '../../CompanyLink';
import { OfficeLink } from '../../OfficeLink';
import { UserLink } from '../../UserLink';
import { Contact } from '../../Contact';
import { Divider } from '../../Divider';
import { MedicUserInfo } from './MedicUserInfo';
import { UserInfo } from './UserInfo';

export const WorkInfo = ({ tabPage = false, user }) => {
  const sipNumber = user?.sipUser?.numbers?.join(', ');

  return (
    <>
      {user?.role === 'medic' || user?.role === 'doctor'
        ? <MedicUserInfo user={user} />
        : <UserInfo user={user} />
      }

      <Box mt={2} pb={1}>
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">Company Name</Typography>
          </Grid>

          <Grid item sm={7} xs={6}>
            {!!user?.work?.company?.id ?
              <Tooltip title="View company" placement="bottom-start">
                <CompanyLink variant="h5" company={user?.work?.company} />
              </Tooltip>
              :
              <Typography variant="h5">-</Typography>
            }
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">Company Address</Typography>
          </Grid>

          <Grid item sm={7} xs={6}>
            <Typography variant="h5">
              {user?.work?.company?.full_address || '-'}
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">Contact Person</Typography>
          </Grid>

          <Grid item sm={tabPage ? 3 : 4} xs={6}>
            <UserLink
              size="md"
              user={user?.work?.company?.contact_user}
            />
          </Grid>

          <Grid item sm={tabPage ? 3 : 2} xs={6}>
            <Typography color="textSecondary">Phone</Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Contact
              type="tel"
              variant="h5"
              contact={user?.work?.company?.phone || '-'}
            />
          </Grid>

          {sipNumber && (
            <>
              <Grid item sm={3} xs={6}>
                <Typography color="textSecondary">Extension:</Typography>
              </Grid>

              <Grid item sm={7} xs={6}>
                <Typography variant="h5">
                  {sipNumber}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" my={1}>
              <Typography variant="h5">Offices: </Typography>

              <Box
                py={0.25}
                px={1.25}
                ml={2}
                borderRadius="50%"
                bgcolor="secondary.main"
                color="common.white"
              >
                {user?.work?.offices?.length || 0}
              </Box>
            </Box>
          </Grid>

          {user?.work?.offices?.length ? user?.work?.offices?.map((office, index) => (
            <Fragment key={office?.id}>
              <Grid item sm={3} xs={6}>
                <Typography color="textSecondary">Office Name</Typography>
              </Grid>

              <Grid item sm={7} xs={6}>
                <Tooltip title="View office" placement="bottom-start">
                  <OfficeLink variant="h5" office={office} />
                </Tooltip>
              </Grid>

              <Grid item sm={3} xs={6} />

              <Grid item sm={7} xs={6}>
                {(office?.full_address || generateAddress(office)) && (
                  <Box display="flex" alignItems="center">
                    <SvgIcon>
                      <Icon path={mdiMapMarker} />
                    </SvgIcon>

                    <Typography variant="h5">{office?.full_address || generateAddress(office)}</Typography>
                  </Box>
                )}
              </Grid>

              <Grid item sm={3} xs={6}>
                <Typography color="textSecondary">Phone</Typography>
              </Grid>

              <Grid item sm={7} xs={6}>
                <Contact
                  type="tel"
                  variant="h5"
                  contact={office?.phone || '-'}
                />
              </Grid>

              <Grid item sm={3} xs={6}>
                <Typography color="textSecondary">Fax</Typography>
              </Grid>

              <Grid item sm={7} xs={6}>
                <Contact
                  type="tel"
                  variant="h5"
                  contact={office?.fax || '-'}
                />
              </Grid>

              <Grid item sm={3} xs={6}>
                <Typography color="textSecondary">Email</Typography>
              </Grid>

              <Grid item sm={7} xs={6}>
                <Contact
                  type="mailto"
                  variant="h5"
                  contact={office?.email || '-'}
                />
              </Grid>

              {index !== user?.work?.offices?.length - 1 &&
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              }
            </Fragment>
          )) :
            <>
              <Grid item sm={3} xs={6}>
                <Typography variant="h5">
                  Office
                </Typography>
              </Grid>

              <Grid item sm={9} xs={6}>
                <Typography color="textSecondary">No info</Typography>
              </Grid>
            </>
          }
        </Grid>
      </Box>
    </>
  );
};
