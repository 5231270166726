import { Grid, FormControlLabel, InputAdornment } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Checkbox, ColorPicker, NumberMaskField, TextField } from '../../../../../components/FormField';

export const ApptTypeForm = () => {
  const { values } = useFormikContext();

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="code"
          label="Code"
          placeholder="Enter code..."
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="description"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <NumberMaskField
          name="_hours"
          label="Hours"
          placeholder="Enter hours..."
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">h</InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid xs={6} item sm={4}>
        <NumberMaskField
          required={!values._hours}
          name="_minutes"
          label="Minutes"
          placeholder="Enter minutes..."
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">min</InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <TextField
          required
          name="ordering"
          label="Ordering"
          placeholder="Enter ordering..."
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <ColorPicker
          required
          name="color"
          label="Color"
          placeholder="Select color..."
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <FormControlLabel
          control={<Checkbox name="claim_required" />}
          label="Claim required"
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <FormControlLabel
          control={<Checkbox name="out_of_office_visit" />}
          label="Out of office visit"
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <FormControlLabel
          control={<Checkbox name="new_visit" />}
          label="New visit"
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <FormControlLabel
          control={<Checkbox name="is_active" />}
          label="Is active"
        />
      </Grid>
    </Grid>
  );
};
