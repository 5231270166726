import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Dialog,
  Grid,
  SvgIcon,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { mdiFileAccountOutline, mdiLink } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as formsApi from '../../../../../../api/profile';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderActions
} from '../../../../../../components/Modal';
import { FormHeader, formTypesMap } from '../../../../../../components/medical/forms';
import { accidentTypesTitlesMap } from '../../../../../../components/medical/accidentTypes';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Loader } from '../../../../../../components/Loader';
import { Date } from '../../../../../../components/Date';
import { getFormRoutes } from '../MedicalFormsProvider';
import { componentsMapInjury, componentsMapInjuryOrtho, componentsMapPrivate } from './componentsMap';
import { FollowUpFormPreview, GeneralInfo } from './FollowUpFormPreview';
import { PreviewActionButtons } from './PreviewActionButtons';

export const actionsMap = {
  save: 'save',
  print: 'print',
  download: 'download',
  approve: 'approve'
};

export const FormPreviewModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    isDeleted = false,
    formID,
    onApprove = () => {},
    onRestore = () => {}
  }
}) => {
  const { openModal } = useModal();
  const [ activeAction, setActiveAction ] = useState(null);
  const [ form, setForm ] = useState(null);
  const [ isOpenSwitches, setIsOpenSwitches ] = useState(true);
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const location = useLocation();
  const pathname = location.pathname === '/profile'
    ? location.pathname
    : location.pathname.substring(0, location.pathname.indexOf('/medical-info'));
  const isMainList = location.pathname === '/medical-forms';
  const isAppointment = location.pathname === '/appointments';
  const formPreviewApi = isDeleted ? formsApi.fetchDeletedMedicalForm : formsApi.fetchMedicalForm;

  const handleSwitchChange = (event) => {
    setIsOpenSwitches(event.target.checked);
  };

  const openFormPreviewModal = () => {
    openModal(FormPreviewModal, {
      payload: {
        formID: form?.parent?.id
      }
    });
  };

  const fetchForm = () => {
    return formPreviewApi(formID).then(setForm);
  };

  const handleApprove = () => {
    fetchForm();
    onApprove();
  };

  const handleOpenEditPage = () => {
    let path;

    if (form?.form_type === formTypesMap.follow_up) {
      path = isMainList || isAppointment
        ? `/medical-forms/${form.id}/edit`
        : `${pathname}/medical-info/medical-forms/${form.id}/edit`;
    } else {
      path = getFormRoutes({
        id: form.id,
        medicalInfoPathname: isMainList || isAppointment ? '' : pathname + '/medical-info'
      })[0];
    }

    window.open(path, '_blank');
    window.focus();
  };

  const handleRestoreForm = () => {
    onRestore([ formID ]);
    handleModalResolve();
  };

  useEffect(() => {
    fetchForm();
  }, []);

  return (
    <Dialog fullScreen={isTablet} maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader
          icon={<SvgIcon><Icon path={mdiFileAccountOutline} /></SvgIcon>}
          onClose={handleModalReject}
        >
          View Medical Form

          {!!form && !isTablet &&
            <ModalHeaderActions disableGutters>
              <PreviewActionButtons
                isDeleted={isDeleted}
                isOpenSwitches={isOpenSwitches}
                form={form}
                onRestore={handleRestoreForm}
                activeAction={activeAction}
                setActiveAction={setActiveAction}
                onSwitchChange={handleSwitchChange}
                onOpenEditPage={handleOpenEditPage}
                onApprove={handleApprove}
              />
            </ModalHeaderActions>
          }
        </ModalHeader>

        <Loader loading={!form} p={2} render={
          () => (
            <ModalBody disablePaddings={isTablet}>
              <Box mb={3}>
                {isTablet && (
                  <Box display="flex" alignItems="center" justifyContent="space-between" px={1}>
                    <PreviewActionButtons
                      isOpenSwitches={isOpenSwitches}
                      form={form}
                      isDeleted={isDeleted}
                      activeAction={activeAction}
                      setActiveAction={setActiveAction}
                      onSwitchChange={handleSwitchChange}
                      onOpenEditPage={handleOpenEditPage}
                      onApprove={onApprove}
                    />
                  </Box>
                )}

                <Box px={isTablet ? 1 : 0}>
                  <FormHeader
                    form={form}
                    type={form.form_type}
                    activeStep={0}
                  />
                </Box>

                <Grid
                  container
                  spacing={2}
                  component={Box}
                  px={1}
                  py={3}
                  width="100% !important"
                  mx="0 !important"
                >
                  {!!form.parent && (
                    <>
                      <Grid item sm={3} xs={12}>
                        <Typography color="textSecondary">
                          Parent Form
                        </Typography>
                      </Grid>

                      <Grid item sm={9} xs={12}>
                        <Box display="flex" alignItems="center" flexWrap="nowrap">
                          <Date
                            variant="h5"
                            date={form?.created_at || form?.date}
                          />

                          {form?.parent?.id &&
                            <Box ml={2}>
                              <Tooltip isExistTooltip={!!form?.parent?.id} title="Show form">
                                <IconButton color="primary" size="small" onClick={openFormPreviewModal}>
                                  <SvgIcon><Icon path={mdiLink} /></SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </Box>
                          }
                        </Box>
                      </Grid>
                    </>
                  )}

                  <Grid item sm={3} xs={6}>
                    <Typography color="textSecondary">
                      Hearing Date
                    </Typography>
                  </Grid>

                  <Grid item sm={3} xs={6}>
                    <Date variant="h5" date={form?.hearing_date} />
                  </Grid>

                  <Grid item sm={3} xs={6}>
                    <Typography color="textSecondary">
                      Vision Rx Date
                    </Typography>
                  </Grid>

                  <Grid item sm={3} xs={6}>
                    <Date variant="h5" date={form?.vision_date} />
                  </Grid>

                  {form?.form_type !== formTypesMap.private && (
                    <>
                      <Grid item sm={3} xs={6}>
                        <Typography color="textSecondary">
                          Accident Type
                        </Typography>
                      </Grid>

                      <Grid item sm={3} xs={6}>
                        <Typography variant="h5">
                          {accidentTypesTitlesMap[form?.accident_type] || '-'}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {form?.form_type !== formTypesMap.compensation && form?.form_type !== formTypesMap.private &&
                    <>
                      <Grid item sm={3} xs={6}>
                        <Typography color="textSecondary">
                          Accident State
                        </Typography>
                      </Grid>

                      <Grid item sm={3} xs={6}>
                        <Typography variant="h5">
                          {form?.auto_accident_state || '-'}
                        </Typography>
                      </Grid>

                      <Grid item sm={3} xs={6}>
                        <Typography color="textSecondary">
                          Accident Country
                        </Typography>
                      </Grid>

                      <Grid item sm={3} xs={6}>
                        <Typography variant="h5">
                          {form?.auto_accident_country || '-'}
                        </Typography>
                      </Grid>
                    </>
                  }

                  {form?.form_type === formTypesMap.compensation &&
                    <>
                      <Grid item xs={12}>
                        <Box color="info.main">
                          <Typography variant="h5" color="inherit">
                            Unable to Work
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography color="textSecondary">From</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Date
                          disableMoment
                          variant="h5"
                          iconFontSize="small"
                          date={form?.unable_work_date_from && moment(form?.unable_work_date_from).format('L')}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Typography color="textSecondary">To</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Date
                          disableMoment
                          variant="h5"
                          iconFontSize="small"
                          date={form?.unable_work_date_to && moment(form?.unable_work_date_to).format('L')}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Typography color="textSecondary">Last Work Date</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Date
                          disableMoment
                          variant="h5"
                          iconFontSize="small"
                          date={form?.last_work_date && moment(form?.last_work_date).format('L')}
                        />
                      </Grid>
                    </>
                  }
                </Grid>

                {form.form_type === formTypesMap.injury ?
                  componentsMapInjury.map((Component, i) => (
                    <Component
                      key={i}
                      isOpenSwitches={isOpenSwitches}
                      form={form}
                      toggleSwitches={setIsOpenSwitches}
                    />
                  ))
                  : form.form_type === formTypesMap.ortho ?
                    componentsMapInjuryOrtho.map((Component, i) => (
                      <Component
                        key={i}
                        isOpenSwitches={isOpenSwitches}
                        form={form}
                        toggleSwitches={setIsOpenSwitches}
                      />
                    )) : form.form_type === formTypesMap.follow_up ?
                      <>
                        <GeneralInfo
                          isOpenSwitches={isOpenSwitches}
                          form={form}
                          toggleSwitches={setIsOpenSwitches}
                        />

                        <FollowUpFormPreview
                          isOpenSwitches={isOpenSwitches}
                          form={form}
                          toggleSwitches={setIsOpenSwitches}
                        />
                      </>
                      :
                      componentsMapPrivate.map((Component, i) => (
                        <Component
                          key={i}
                          isOpenSwitches={isOpenSwitches}
                          form={form}
                          toggleSwitches={setIsOpenSwitches}
                        />
                      ))
                }
              </Box>
            </ModalBody>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
