import { Box, Grid, Typography } from '@material-ui/core';
import { TextField } from '../../../FormField';

export const Hospice = () => {
  return (
    <>
      <Box mb={1}>
        <Typography variant="h3">
          Hospice #
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            name="care_plan_oversight"
            label="Care Plan Oversight #"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            name="hospice"
            label="Hospice #"
          />
        </Grid>
      </Grid>
    </>
  );
};
