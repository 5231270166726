import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_CASE_TIME_STATISTICS_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_CASE_TIME_STATISTICS_SUCCESS]: (
    { filter, statistics, ...state },
    { data: newStatistics, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      statistics: page > 1 ? (
        statistics.filter(({ id }) =>
          !newStatistics.find((loadedStatistic) => id === loadedStatistic.id)).concat(newStatistics)
      ) : (
        newStatistics
      )
    };
  },

  [types.RESET_CASE_TIME_STATISTICS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  }
});
