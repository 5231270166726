import { useEffect, useContext, useRef } from 'react';
import { List as MuiList, Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { objectKeysToMap } from '../../../../../helpers/objectKeysToMap';
import { ScrollbarsCustom } from '../../../../../components/ScrollbarsCustom';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Sticky } from '../../../../../components/Sticky';
import { Loader } from '../../../../../components/Loader';
import { AccessControlContext } from '../AccessControlProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidths = {
  email: 180,
  createDate: 160,
  expireDate: 160,
  confirmDate: 160,
  actions: 64
};

export const columnsWidthsSM = {
  email: 120,
  createDate: 90,
  expireDate: 90,
  actions: 40
};

export const columnsMap = objectKeysToMap(columnsWidths);

export const List = () => {
  const {
    emails,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    resetEmails
  } = useContext(AccessControlContext);
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    resetEmails();
  }, []);

  return (
    <ScrollbarsCustom autoHeight scrollElementRef={scrollElementRef}>
      <Box px={isTablet ? 0 : 3} py={2}>
        <Loader loading={!isFetched} p={2} render={
          () => !filter.total ? (
            <Box p={3}>
              <Typography align="center">No emails found</Typography>
            </Box>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={emails}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
