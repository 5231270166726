import {
  KeyboardDatePicker,
  TaskPrioritySelect,
  TaskStatusesSelect,
  TaskTypesSelect,
  TextField
} from '../../FormField';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';

export const filterFields = [
  <TextField
    name="search"
    type="search"
    placeholder="Search..."
    label={filterFieldsLabels[filterFieldsMap.search]}
  />,
  <KeyboardDatePicker
    clearable
    name="created_from"
    placeholder="Date from"
    label={filterFieldsLabels[filterFieldsMap.created_from]}
  />,
  <TaskTypesSelect
    disableValueTransform
    multiple
    name="types"
    label={filterFieldsLabels[filterFieldsMap.types]}
  />,
  <KeyboardDatePicker
    clearable
    name="created_to"
    placeholder="to"
    label={filterFieldsLabels[filterFieldsMap.created_to]}
  />,
  <TaskStatusesSelect
    isMulti
    formattedValue={false}
    name="statuses"
    label={filterFieldsLabels[filterFieldsMap.statuses]}
  />,
  <KeyboardDatePicker
    clearable
    name="due_from"
    placeholder="Date from"
    label={filterFieldsLabels[filterFieldsMap.due_from]}
  />,
  <TaskPrioritySelect
    disableValueTransform
    multiple
    name="priorities"
    label={filterFieldsLabels[filterFieldsMap.priority]}
  />,
  <KeyboardDatePicker
    clearable
    name="due_to"
    placeholder="to"
    label={filterFieldsLabels[filterFieldsMap.due_to]}
  />
];
