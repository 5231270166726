import { Box, makeStyles } from '@material-ui/core';
import { Divider } from '../../../../components/Divider';
import { AddConfigurationButton } from './AddConfigurationButton';
import { PrintersConfigurationProvider } from './PrintersConfigurationProvider';
import { PrintersConfigurationsList } from './PrintersConfigurationsList';
import { FiltersBar } from './FiltersBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PrinterConfiguration = () => {
  const classes = useStyles();

  return (
    <PrintersConfigurationProvider>
      <div className={classes.root}>
        <div className={classes.body}>
          <Box display="flex" alignItems="center">
            <AddConfigurationButton />
          </Box>

          <Divider />
          <FiltersBar />
          <PrintersConfigurationsList />
        </div>
      </div>
    </PrintersConfigurationProvider>
  );
};
