import { makeStyles, Typography } from '@material-ui/core';
import { billingParticipantsTypesMap } from '../../../../../../../../../components/billing';
import { CompanyLink } from '../../../../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../../../../components/UserLink';
import { Label } from '../../../../../../../../../components/Label';
import { statusTypesColors, statusTypesNames } from '../../../../../InvoicesContext/statusTypes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Footer = ({ invoice }) => {
  const classes = useStyles();
  const status = (invoice?.status?.name || invoice?.status).toLowerCase();

  return (
    <div className={classes.root}>
      <div className={classes.contact}>
        {invoice.bill_to?.participant_type === billingParticipantsTypesMap.user ? (
          <UserLink
            size="sm"
            variant="h5"
            user={invoice?.bill_to?.participant}
            parentProps={{ alignItems: 'center' }}
            childrenProps={{ mt: -0.5 }}
          >
            <Typography color="textSecondary" variant="body2" component="div">
              Adjuster
            </Typography>
          </UserLink>
        ) : (
          <CompanyLink
            size="sm"
            variant="h5"
            company={invoice?.bill_to?.participant}
            parentProps={{ alignItems: 'center' }}
            childrenProps={{ mt: -0.5 }}
          >
            <Typography color="textSecondary" variant="body2" component="div">
              Adjuster
            </Typography>
          </CompanyLink>
        )}
      </div>

      {invoice.status &&
        <Label
          label={statusTypesNames[status]}
          color={statusTypesColors[status]}
          className={classes.statusLabel}
        />
      }
    </div>
  );
};
