import { flow } from 'lodash';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import printJS from 'print-js';
import { saveAs } from 'file-saver';
import {
  Box,
  List,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import * as workClocksApi from '../../../../../api/work-clocks';
import { TimeReportsPreviewModal } from '../../../TimeReportsPreviewModal';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { SvgIcon } from '../../../../SvgIcon';
import { ConfirmationModal } from '../../../../ConfirmationModal';
import { useModal } from '../../../../ModalsProvider';
import { TimeReportsContext } from '../../../TimeReportsProvider';

export const Menu = ({ timeReport, onClose }) => {
  const { openModal } = useModal();
  const {
    deleteTimeReport,
    resetTimeReports,
    toggleApproveReport,
    workerApproveTimeReport
  } = useContext(TimeReportsContext);
  const currentUser = useSelector(({ profile }) => profile.user);
  const isWorker = timeReport.user_id === currentUser.id;

  const openReportPreview = () => {
    openModal(TimeReportsPreviewModal, {
      payload: {
        reportID: timeReport.id
      }
    });
  };

  const approveOrDisapproveReport = () => {
    toggleApproveReport(timeReport);
  };

  const workerApprove = () => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Are you agree?',
        // eslint-disable-next-line max-len
        content: `I acknowledge that I have taken all the legally authorized rest & meal break periods as required by ${timeReport.company.name} policies and procedures.`
      },

      onModalResolved: () => {
        workerApproveTimeReport(timeReport);
      }
    });
  };

  const printReport = () => {
    workClocksApi.fetchTimeReportPDF(timeReport.id).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      printJS({ printable: url, type: 'pdf' });
    });
  };

  const downLoadReport = () => {
    workClocksApi.fetchTimeReportPDF(timeReport.id).then((data) => {
      const blob = new Blob([ data ]);
      const fromDate = moment(timeReport.date_from).format('L');
      const toDate = moment(timeReport.date_to).format('L');

      saveAs(blob, `${fromDate}-${toDate}.pdf`);
    });
  };

  const deleteReport = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        workClocksApi.deleteTimeReport(timeReport.id).then(() => {
          deleteTimeReport(timeReport);
          resetTimeReports();
        });
      }
    });

    onClose();
  };

  return (
    <List>
      <MenuItem onClick={flow(openReportPreview, onClose)}>
        <ListItemIcon>
          <EyeIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {hasRole(rolesMap.admin, rolesMap.supervisor) && (
        <>
          <MenuItem onClick={flow(approveOrDisapproveReport, onClose)}>
            <ListItemIcon>
              <SvgIcon color={timeReport.approved_at ? 'warning' : 'success'}>
                <VerifiedUserOutlinedIcon />
              </SvgIcon>
            </ListItemIcon>

            <ListItemText primary={timeReport.approved_at ? 'Disapprove' : 'Approve'} />
          </MenuItem>
        </>
      )}

      {isWorker && (
        <MenuItem
          disabled={!!timeReport?.worker_approved_at}
          onClick={flow(workerApprove, onClose)}
        >
          <ListItemIcon>
            <SvgIcon color={timeReport.worker_approved_at ? 'success' : 'warning'}>
              <VerifiedUserOutlinedIcon />
            </SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Agree" />
        </MenuItem>
      )}

      <MenuItem onClick={flow(printReport, onClose)}>
        <ListItemIcon>
          <Box color="info.main" component="span">
            <PrintOutlinedIcon />
          </Box>
        </ListItemIcon>

        <ListItemText primary="Print" />
      </MenuItem>

      <MenuItem onClick={flow(downLoadReport, onClose)}>
        <ListItemIcon>
          <DownloadIcon color="primary" />
        </ListItemIcon>

        <ListItemText primary="Download" />
      </MenuItem>

      {hasRole(rolesMap.admin, rolesMap.supervisor) && (
        <MenuItem onClick={flow(deleteReport, onClose)}>
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="inherit"/>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      )}
    </List>
  );
};
