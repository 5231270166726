import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import { Loader } from '../../../../../../components/Loader';
import { Tooltip } from '../../../../../../components/Tooltip';
import { stopPropagation } from '../../../../../../helpers/dom';
import { CardHeader, CardItem } from '../../../../../../components/Cards';
import { ColorPreview } from '../../../../../../components/ColorPreview';
import { IconButton } from '../../../../../../components/IconButton';
import { Scrollbars } from '../../../../../../components/Scrollbars';
import { SvgIcon } from '../../../../../../components/SvgIcon';
import { Popper } from '../../../../../../components/Popper';
import { ScheduleTypesContext } from '../../ScheduleTypesProvider';
import { CardMenu } from '../CardMenu';

export const Card = ({ item: eventType, isLoaded, recalculateHeight }) => {
  const eventTypesContext = useContext(ScheduleTypesContext);
  const [ isBusy, setIsBusy ] = useState(false);

  const toggleActiveEventType = () => {
    const data = { ...eventType, is_active: !eventType.is_active };

    setIsBusy(true);

    eventTypesContext.updateActiveEventTypeReq(data).then(() => {
      setIsBusy(false);
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return (
    <CardItem disableHover py={1} px={2}>
      <CardHeader
        disableCheckBox
        disableCardMenu
        menuComponent={CardMenu}
        menuComponentProps={{ eventType, eventTypesContext, handleToggleActiveEventType: toggleActiveEventType }}
        icon={
          <>
            <Loader
              surface
              loading={isBusy}
              render={() => (
                <Tooltip title={eventType.is_active ? 'Deactivate type' : 'Activate type'}>
                  <IconButton edge="end" onClick={stopPropagation(toggleActiveEventType)}>
                    <SvgIcon color={eventType.is_active ? 'warning' : 'success'}>
                      {eventType.is_active ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              )}
            />

            <Popper
              placement="left-start"
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <IconButton edge="end"  buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                    <MoreVertIcon />
                  </IconButton>
                )
              }
            >
              {({ handleClose }) => (
                <CardMenu eventType={eventType} eventTypesContext={eventTypesContext} onClose={handleClose} />
              )}
            </Popper>
          </>
        }
      >
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="baseline" mr={1}>
            <ColorPreview color={eventType.color} />
          </Box>

          <Box flexGrow={1}>
            <Typography noWrap title={eventType.name}>
              {eventType.name}
            </Typography>
          </Box>
        </Box>
      </CardHeader>

      <Box pl={3}>
        <Typography>
          {eventType.duration
            ? moment.duration(eventType.duration, 'seconds').format('h[h] m[min]')
            : '-'
          }
        </Typography>
      </Box>

      <Scrollbars>
        <Box maxHeight="200">
          <Typography>
            <span style={{ fontWeight: 'bold' }}>Description: </span>
            {eventType.description || '-'}
          </Typography>
        </Box>
      </Scrollbars>
    </CardItem>
  );
};
