import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { Page, PageHeader } from '../../../../components/Page';
import {
  LayoutContextProvider,
  viewVariantsMap
} from '../../../../components/LayoutContext';
import { AccessPatientsContextProvider } from './PatientsProvider';
import { PatientsPage } from './PatientsPage';
import { ActionsBar } from './ActionsBar';

export const Patients = ({ filterKey }) => {
  const rootRef = useRef(null);
  const { userID } = useParams();
  const currentUser = useSelector(({ profile }) => profile.user);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  return (
    <AccessPatientsContextProvider initialState={{ filter: { user_id: userID || currentUser.id } }}>
      <LayoutContextProvider initialViewVariant={viewVariantsMap.list}>
        <Page
          ref={rootRef}
          square={false}
          elevation={0}
          component={Box}
          pt={0.5}
        >
          <PageHeader disableBorderBottom>
            <ActionsBar
              filterOpenButton={filterOpenButton}
              accessibleUserID={userID || currentUser.id}
            />
          </PageHeader>

          <PatientsPage
            filterKey={filterKey}
            FilterBarWrapper={FilterBarWrapper}
            filterBarWrapperProps={filterBarWrapperProps}
            ScrollWrapper={ScrollWrapper}
            scrollWrapperProps={scrollWrapperProps}
          />
        </Page>
      </LayoutContextProvider>
    </AccessPatientsContextProvider>
  );
};
