export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%'
  },

  loader: {
    padding: spacing(2)
  },

  mainContent: {
    display: 'flex',
    alignItems: 'stretch',
    flexGrow: 1,
    overflow: 'auto',
    padding: spacing(2)
  },

  chartContent: {
    flexGrow: 1
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 0,
    width: '20%',
    maxWidth: 110,
    textTransform: 'capitalize',
    padding: spacing(1, 2)
  }
});
