import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Slider } from '../../../../../../../components/Slider';
import { painLevelColors } from '../../BodyPartsModal/painLevelColors';

export const PainContent = ({ title, sliderValue, marks }) => {
  const [ colorIndex, setColorIndex ] = useState(null);

  useEffect(() => {
    setColorIndex(sliderValue || 0);
  }, []);

  return (
    <Box display="flex" flexDirection="column" p={1} pl={1.5}>
      {title}

      <Box display="flex" m={`${16}px auto`} pt={3} width="70%">
        <Slider
          value={sliderValue || 0}
          marks={marks}
          levelColors={painLevelColors}
          colorIndex={colorIndex}
        />
      </Box>
    </Box>
  );
};
