import { Create } from './Create';
import { Edit } from './Edit';

export const routes = [
  {
    pathName: '/arrival-statuses/create',
    component: Create
  },
  {
    pathName: '/arrival-statuses/:id/edit',
    component: Edit
  }
];
