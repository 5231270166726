import { useContext } from 'react';
import { Tooltip } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../ModalsProvider';
import { LayoutContext, viewVariantsMap } from '../../../LayoutContext';
import { PageHeaderActions } from '../../../Page';
import { IconButton } from '../../../IconButton';
import { Fab } from '../../../Fab';
import { MembersContext } from '../MembersContext';
import { AddMembersModal } from '../AddMemberModal';
import { BREAKPOINT_WIDTH } from '../Members';

export const ActionsBar = ({
  width,
  accessibleUserID,
  filterOpenButton
}) => {
  const { openModal } = useModal();
  const { addMember } = useContext(MembersContext);
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const isDesktop = width > BREAKPOINT_WIDTH;

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const openAddWorkerModal = () => {
    openModal(AddMembersModal, {
      payload: {
        accessibleUserID
      },
      onModalResolved: (data) => {
        addMember(data);
      }
    });
  };

  return (
    <>
      <PageHeaderActions disablePosition>
        <Fab
          size={!isDesktop ? 'small' : 'medium'}
          variant="extended"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openAddWorkerModal}
        >
          Add member
        </Fab>
      </PageHeaderActions>

      {!isDesktop ? (
        <PageHeaderActions>
          {filterOpenButton}
        </PageHeaderActions>
      ) : (
        <PageHeaderActions disableSpacing endPosition>
          <Tooltip arrow title="List view">
            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Grid view">
            <IconButton
              edge="end"
              color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.grid)}
            >
              <ViewModuleIcon />
            </IconButton>
          </Tooltip>
        </PageHeaderActions>
      )}
    </>
  );
};
