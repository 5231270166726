import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Dialog, Button, Typography } from '@material-ui/core';
import * as profileApi from '../../../../../api/profile';
import * as formsApi from '../../../../../api/profile';
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContainer
} from '../../../../Modal';
import { FormContent } from './FormContent';

export const ApproveConfirmationModal = ({
  DialogProps,
  payload: {
    form
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ disabled, setDisabled ] = useState(false);
  const [ formValue, setFormValue ] = useState(form);

  const resolveModal = () => {
    formsApi.approveMedicalForm(formValue.id).then(() => {
      const medicalID = formValue?.medical?.id || formValue?.patient?.medical?.id;

      if (formValue?.emergency_contact?.length && medicalID) {
        const contacts = { contact: form?.emergency_contact?.filter((contact) => contact?.phone) };

        profileApi.updateMedicalProfileContacts({ id: medicalID, contacts })
          .then(() => {
            enqueueSnackbar(
              'Contacts from the form have been successfully added to the user profile',
              { variant: 'success' }
            );
          })
          .catch(() => {
            enqueueSnackbar('Contacts not added', { variant: 'error' });
          });
      }

      enqueueSnackbar('Successfully approved', { variant: 'success' });
      handleModalResolve();
    }).catch((error) => {
      if (error) {
        enqueueSnackbar('Not approved', { variant: 'error' });
      }
    });
  };

  return (
    <Dialog maxWidth="sm" {...DialogProps}>
      <ModalContainer>
        <ModalHeader>
          Approve
        </ModalHeader>

        <ModalBody>
          <Typography gutterBottom>
            In the next steps, you will be asked to fill in the missing data, if required
          </Typography>

          <FormContent
            form={formValue}
            setFormValue={setFormValue}
            setDisabled={setDisabled}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleModalReject}>
            Cancel
          </Button>

          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={resolveModal}
          >
            Approve
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Dialog>
  );
};
