import { makeStyles, Typography } from '@material-ui/core';
import { Scrollbars } from '../../../../../../components/Scrollbars';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const List = ({ statistics }) => {
  const classes = useStyles();
  const { data, labels } = statistics;

  return (
    <div className={classes.root}>
      <Scrollbars>
        {labels.map((item, i) => (
          <div key={i} className={classes.row}>
            <div className={classes.title}>
              <Typography noWrap variant="h3" className={classes.titleContent}>
                {item}
              </Typography>
            </div>

            <div className={classes.title_total}>
              <Typography noWrap variant="h3">
                {data[i]}
              </Typography>
            </div>
          </div>
        ))}
      </Scrollbars>
    </div>
  );
};
