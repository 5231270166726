export const styles = () => ({
  dragHandle: {
    cursor: 'move'
  },

  dragHandleContent: {
    display: 'flex',
    alignItems: 'center'
  }
});
