import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths, widthBreakpointMedium } from '../List';

export const TableHeader = ({ width }) => {
  return (
    <ListRow header px={2}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        Name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.category}>
        Category
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.notes}>
          Notes
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.attachments}>
        Attachments
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.budgeted}>
        Budgeted
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount}>
        Amount
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}/>
    </ListRow>
  );
};
