import React, { useContext } from 'react';
import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../components/ListRow';
import { PaymentsContext } from '../../../../../../../components/billing/payments';
import { columnsMap, columnsWidths, widthBreakpointMedium, widthBreakpointLarge } from '../List';

export const TableHeader = ({ width, hiddenColumns = [] }) => {
  const {
    selectedPaymentsIDs,
    allPaymentsIsSelected,
    toggleAllPaymentsSelection
  } = useContext(PaymentsContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        {!hiddenColumns.includes(columnsMap.checkbox) &&
          <ListRowCheckbox
            onClick={toggleAllPaymentsSelection}
            checked={allPaymentsIsSelected()}
            indeterminate={!!selectedPaymentsIDs.length && !allPaymentsIsSelected()}
          />
        }

        <ListRowCell noWrap grow={width < widthBreakpointMedium} flexBasis={columnsWidths.number}>
          Payment #
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.payer}>
          Payer
        </ListRowCell>

        <ListRowCell grow={width < widthBreakpointMedium} flexBasis={columnsWidths.number}>
          Inv #
        </ListRowCell>

        {width > widthBreakpointMedium &&
          <ListRowCell grow flexBasis={columnsWidths.description}>
            Description
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.paidDate}>
          Paid Date
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.dueOn) &&
          <ListRowCell flexBasis={columnsWidths.dueOn}>
            Due On
          </ListRowCell>
        }

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.method}>
            Payment Method
          </ListRowCell>
        }

        {!hiddenColumns.includes(columnsMap.total) &&
          <ListRowCell flexBasis={columnsWidths.total}>
            Total
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.amount}>
          Amount
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.balance) &&
          <ListRowCell flexBasis={columnsWidths.balance}>
            Balance
          </ListRowCell>
        }

        {!hiddenColumns.includes(columnsMap.actions) &&
          <ListRowCell flexBasis={columnsWidths.actions} />
        }

        {!hiddenColumns.includes(columnsMap.overdraftActions) &&
          <ListRowCell flexBasis={columnsWidths.overdraftActions} />
        }
      </ListRow>
    </Paper>
  );
};
