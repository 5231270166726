import { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ClaimsContext } from '../../../../../../components/claims/ClaimsProvider';
import { Divider } from '../../../../../../components/Divider';
import { columnsWidths } from '../SideBar';
import { FiltersBar } from './FiltersBar';
import { List } from './List';

export const Claims = ({ claim }) => {
  const { filter } = useContext(ClaimsContext);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box py={1} px={2} bgcolor="grey.50">
        <FiltersBar claim={claim} />
      </Box>

      <Divider />

      {!!filter.total &&
        <Box display="flex" py={1.5} px={2}>
          <Box flexBasis={columnsWidths.date}>
            <Typography variant="subtitle1">DOS</Typography>
          </Box>

          <Box flexBasis={columnsWidths.claimNo}>
            <Typography variant="subtitle1">
              {filter?.patients?.length ? 'Claim #' : 'Patient'}
            </Typography>
          </Box>
        </Box>
      }

      <Divider />

      <Box flexGrow={1}>
        <List claim={claim} />
      </Box>
    </Box>
  );
};
