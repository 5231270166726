import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

const addCase = (state, payload) => {
  const { pagination, filter: { statuses, ...filter }, cases, ...otherState } = state;
  let suitableCases = payload;

  if (statuses && statuses.length) {
    suitableCases = payload.filter(({ status }) => statuses.find((id) => id === status.id));
  }

  if (!suitableCases.length) {
    return state;
  }

  const total = pagination.total + suitableCases.length;
  const page = Math.ceil((cases.length + suitableCases.length) / filter.per_page) - 1;
  const last_page = Math.ceil(total / filter.per_page);

  return {
    ...otherState,

    pagination: { ...pagination, total, last_page },
    filter: { ...filter, statuses, page },
    cases: suitableCases.concat(cases)
  };
};

const deleteCase = ({ pagination, filter, cases, selectedCasesIDs, ...state }, caseId) => {
  const remainingCases = cases.filter((task) => task.id !== caseId);
  const deletedCasesCount = cases.length - remainingCases.length;
  const total = pagination.total - deletedCasesCount;
  const page = Math.ceil(deletedCasesCount / filter.per_page);
  const last_page = Math.ceil(total / filter.per_page);

  return {
    ...state,

    pagination: { ...pagination, total, last_page },
    filter: { ...filter, page },
    selectedCasesIDs: selectedCasesIDs.filter((id) => caseId !== id),
    cases: remainingCases
  };
};

export const reducer = createHookReducer( {
  [types.CASES_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.CASES_FETCH_SUCCESS]: (
    { filter, cases, ...state },
    { data: newCases, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      cases: page > 1 ? (
        cases.filter(({ id }) => !newCases.find((loadedCase) => id === loadedCase.id)).concat(newCases)
      ) : (
        newCases
      )
    };
  },

  [types.ADD_CASE]: addCase,

  [types.UPDATE_CASE_IN_LIST]: (state, caseItem) => {
    const { filter, cases } = state;
    const getStateWithUpdatedCases = () => {
      return {
        ...state,

        cases: cases.map((item) => {
          return item.id === caseItem.id ? { ...item, ...caseItem } : item;
        })
      };
    };

    if (!(filter.statuses && filter.statuses.length)) {
      return getStateWithUpdatedCases();
    }

    if (filter.statuses.find((status) => status === caseItem.status.id)) {
      if (!cases.find(({ id }) => id === caseItem.id)) {
        return addCase(state, [ caseItem ]);
      }

      return getStateWithUpdatedCases();
    }

    return deleteCase(state, caseItem.id);
  },

  [types.DELETE_CASE_SUCCESS]: deleteCase,

  [types.TOGGLE_CASE_SELECTION]: ({ selectedCasesIDs, ...state }, id) => {
    return {
      ...state,

      selectedCasesIDs: selectedCasesIDs.indexOf(id) !== -1 ?
        selectedCasesIDs.filter((item) => item !== id)
        :
        selectedCasesIDs.concat(id)
    };
  },

  [types.ADD_CASES_TO_SELECTED]: ({ selectedCases, selectedCasesIDs, ...state }, cases) => {
    return {
      ...state,

      selectedCases: selectedCases.concat(cases.filter(({ id }) => !selectedCasesIDs.includes(id))),
      selectedCasesIDs: [ ...new Set(selectedCasesIDs.concat(cases.map(({ id }) => id))) ]
    };
  },

  [types.DELETE_CASES_FROM_SELECTED]: ({ selectedCases, selectedCasesIDs, ...state }, casesIDs) => {
    return {
      ...state,

      selectedCasesIDs: selectedCasesIDs.filter((id) => casesIDs.indexOf(id) === -1),
      selectedCases: selectedCases.filter(({ id }) => casesIDs.indexOf(id) === -1)
    };
  },

  [types.RESET_CASES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  }
});
