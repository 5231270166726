import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as notesApi from '../../../../../api/profile';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { Body, Header } from '../CreateNoteModal';
import { validationSchema } from '../CreateNoteModal';

export const EditNoteModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { initialValues }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const updateNote = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return notesApi.updateNote(values).then((data) => {
      enqueueSnackbar('Note successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,

        provider_id: initialValues.provider,
        patient_id: initialValues.patient,
        office_id: initialValues.office,
        dob: initialValues?.patient?.birthday,
        is_range: !initialValues?.doi && !!initialValues?.doi_from && !!initialValues.doi_to
      }}
      validationSchema={validationSchema}
      onSubmit={updateNote}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Update Clinical Notes
            </ModalHeader>

            <ModalBody>
              <Header />
              <Body />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
