import { createContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { merge } from 'lodash';
import { api } from '../../../../../api';
import * as insuranceApi from '../../../../../api/profile/insurances';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { setProfileData } from '../../../../../store/dashboard/profile';
import { setUsersLastGlobalAction } from '../../../../../store/globalActions';
import * as userTypes from '../../../Members/User/UserProvider/types';
import { AddInsuranceInfoModal } from '../AddInsuranceInfoModal';
import { AttachCaseModal } from '../AttachCaseModal';
import { CaseInsuranceModal } from '../CaseInsuranceModal';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const InsurancesContext = createContext(null);

export const InsurancesProvider = ({
  allInsurances = false,
  children,
  filterProp,
  initialState: initialStateProp = {}
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { pagination } = state;
  const filter = { ...filterProp, ...state.filter };
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const reduxDispatch = useDispatch();
  const { openModal } = useModal();
  const cancelFetch = useRef(() => {});
  const isProfile = pathname.includes('profile');
  const { user } = useSelector(({ profile }) => profile);

  const fetchInsurances = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.INSURANCES_FETCH_REQUEST });

    insuranceApi.fetchInsurances({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({
        type: allInsurances ? types.ALL_INSURANCES_FETCH_SUCCESS : types.INSURANCES_FETCH_SUCCESS,
        payload: data
      });
    });
  };

  const createInsurance = (user, { onCaseItemUpdate = () => {}, ...caseItem } = {}) => {
    openModal(AddInsuranceInfoModal, {
      payload: {
        user,
        caseItem,
        onCaseItemUpdate
      },
      onModalResolved: (data) => {
        dispatch({ type: types.ADD_INSURANCE, payload: data });
      }
    });
  };

  const createCaseInsurance = (user, { onCaseItemUpdate, ...caseItem } = {}) => {
    openModal(CaseInsuranceModal, {
      payload: {
        user,
        caseItem,
        onCaseItemUpdate
      },
      onModalResolved: (data) => {
        dispatch({ type: types.ADD_INSURANCE, payload: data });
      }
    });
  };

  const handleToggleMain = (insurance) => () => {
    insuranceApi.updateInsurance(insurance.id, { status_type: 'primary' }).then((data) => {
      if (isProfile) {
        reduxDispatch(setProfileData({
          medical: {
            ...user?.medical,
            main_insurance: data
          }
        }));
      } else {
        reduxDispatch(setUsersLastGlobalAction({ type: userTypes.SET_PROFILE_DATA }));
      }

      dispatch({ type: types.UPDATE_INSURANCE, payload: data });
      enqueueSnackbar('Successfully updated', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Insurance not updated', { variant: 'error' });
    });
  };

  const updateInsurance = (value) => {
    dispatch({ type: types.UPDATE_INSURANCE, payload: value });
  };

  const detachInsuranceCase = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        insuranceApi.updateInsurance(id, { case_id: null, case: null }).then(() => {
          fetchInsurances();
          enqueueSnackbar('Insurance deleted from case', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Insurance not deleted from case', { variant: 'error' });
        });
      }
    });
  };

  const attachInsuranceCase = (insurance) => {
    openModal(AttachCaseModal, {
      payload: {
        insuranceID: insurance?.id
      },
      onModalResolved: (values) => {
        if (!values) {
          return;
        }

        insuranceApi.updateInsurance(insurance?.id, values).then(() => {
          fetchInsurances();
          enqueueSnackbar('Case added to insurance', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Case not added to insurance', { variant: 'error' });
        });
      }
    });
  };

  const deleteInsurance = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        insuranceApi.deleteInsurance(id).then(() => {
          dispatch({ type: types.DELETE_INSURANCE_SUCCESS, payload: id });
          enqueueSnackbar('Insurance deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Insurance not deleted', { variant: 'error' });
        });
      }
    });
  };

  const resetInsurances = (newFilter) => {
    dispatch({ type: types.RESET_INSURANCES, payload: newFilter });

    fetchInsurances({ page: 1, ...newFilter });
  };

  const allInsurancesIsSelected = () => {
    return state?.insurances?.length === state.selectedIDs.length;
  };

  const toggleInsuranceSelection = (id) => {
    dispatch({ type: types.TOGGLE_INSURANCE_SELECTION, payload: id });
  };

  const toggleAllInsurancesSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_INSURANCES_SELECTION });
  };

  const providerValue = {
    ...state,
    pagination,
    filter,
    meta: {
      ...pagination,
      ...filter
    },

    // functions
    attachInsuranceCase,
    createCaseInsurance,
    detachInsuranceCase,
    fetchInsurances,
    createInsurance,
    updateInsurance,
    handleToggleMain,
    deleteInsurance,
    resetInsurances,
    allInsurancesIsSelected,
    toggleInsuranceSelection,
    toggleAllInsurancesSelection
  };

  useEffect(() => {
    if (allInsurances) {
      resetInsurances();
    } else {
      fetchInsurances();
    }
  }, [ filterProp?.medical_id ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <InsurancesContext.Provider value={providerValue}>
      {children}
    </InsurancesContext.Provider>
  );
};
