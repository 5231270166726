import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_VIDEOS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_VIDEOS_SUCCESS]: (
    { filter, videos, ...state },
    { data: newVideos, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      videos: page > 1 ?
        videos.filter(({ id }) => !newVideos.find((video) => id === video.id)).concat(newVideos) : newVideos
    };
  },

  [types.RESET_VIDEOS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [ types.UPDATE_VIDEO_IN_LIST ]: (state, payload) => {
    return {
      ...state,
      videos: state.videos.map((item) => {
        return item.id === payload.id ? { ...item, ...payload } : item;
      })
    };
  },

  [types.DELETE_VIDEOS]: (state, { id }) => {
    return {
      ...state,
      videos: state.videos.filter((item) => item.id !== id)
    };
  }
});
