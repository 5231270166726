export const styles = ({ spacing, breakpoints }) => ({
  root: {
    paddingTop: spacing(),
    paddingBottom: spacing(),
    paddingLeft: spacing(4),
    alignItems: 'center',

    [breakpoints.down('md')]: {
      paddingLeft: spacing()
    },

    [breakpoints.down(breakpoints.values.sm)]: {
      flexWrap: 'wrap'
    }
  },

  rootSM: {
    flexWrap: 'wrap'
  },

  rootMergePdf: {
    flexWrap: 'nowrap'
  },

  rootNoWrap: {
    flexWrap: 'nowrap'
  },

  rightSide: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    marginLeft: 'auto',

    [breakpoints.down(breakpoints.values.sm)]: {
      width: '100%'
    },

    [breakpoints.up(breakpoints.values.md)]: {
      height: '100%'
    }
  },

  rightSideSM: {
    flexGrow: 0
  },

  multiActions: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    [breakpoints.down(breakpoints.values.sm)]: {
      paddingTop: spacing(0.5)
    }
  }
});
