import { useContext, useEffect } from 'react';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../helpers/dom';
import { Contact } from '../../../../../Contact';
import { UserLink } from '../../../../../UserLink';
import { SavedFiltersContext } from '../../../../../saved-filters';
import { ListRow, ListRowCell } from '../../../../../ListRow';
import { IconButton } from '../../../../../IconButton';
import { Loader } from '../../../../../Loader';
import { parentTypesMap } from '../../../../CopyToModal/parentTypeOptions';
import { dispositionsLabelsMap } from '../../../../dispositionsMap';
import { Causer } from '../../../Causer/Causer';
import { filterFieldsMap } from '../../../filterFieldsMap';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  hiddenFields = [],
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell noWrap flexBasis={columnsWidths.users}>
        {!params.user_id ? '-' : (
          <UserLink
            size="sm"
            user={params.user_id}
          />
        )}
      </ListRowCell>

      {!hiddenFields.includes(filterFieldsMap.parentUser) && (
        <ListRowCell noWrap grow flexBasis={columnsWidths.patient}>
          {!params.parent_user_ids?.id ? '-' : (
            <Causer parent={params.parent_user_ids} parentType={parentTypesMap.user} />
          )}
        </ListRowCell>
      )}

      {!hiddenFields.includes(filterFieldsMap.parentCase) && (
        <ListRowCell noWrap grow flexBasis={columnsWidths.case}>
          {!params.parent_case_ids?.id ? '-' : (
            <Causer parent={params.parent_case_ids} parentType={parentTypesMap.case} />
          )}
        </ListRowCell>
      )}

      <ListRowCell noWrap flexBasis={columnsWidths.phone}>
        <Contact
          noWrap
          disableTransform={params?.phone?.length <= 3}
          color="textPrimary"
          type="tel"
          contact={params?.phone}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.disposition}>
        {dispositionsLabelsMap[params.disposition?.value] || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.is_group_call}>
        {params.is_group_call?.label || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.is_callback}>
        {params.is_callback?.label || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateFrom}>
        {params?.date_from ? moment(params.date_from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateTo}>
        {params?.date_to ? moment(params.date_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

