import { makeStyles, List as MuiList, Box, Typography } from '@material-ui/core';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { Header } from './Header';
import { Row } from './Row';
import { styles } from './styles';

export const columnsWidths = {
  name: 200,
  location: 200,
  actions: 264
};

export const columnsWidthsSm = {
  actions: 75
};

const useStyles = makeStyles(styles);

export const List = ({ books, onToggleActive, onBookDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        {!books.length ?
          <Box mx={3} my={2}>
            <Typography align="center">No books found</Typography>
          </Box>
          :
          <>
            <Header />

            <Scrollbars autoHeight autoHeightMax={400}>
              <MuiList disablePadding className={classes.list}>
                {books.map(({ appointment_book, is_current_active }) => (
                  <Row
                    key={appointment_book.id}
                    isActive={is_current_active}
                    appointmentBook={appointment_book}
                    onToggleActive={onToggleActive}
                    onBookDelete={onBookDelete}
                  />
                ))}
              </MuiList>
            </Scrollbars>
          </>
        }
      </div>
    </div>
  );
};
