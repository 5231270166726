import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';
import { makeStyles, Checkbox, IconButton, Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ColorPreview } from '../../../../../../components/ColorPreview';
import { stopPropagation } from '../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../dataMaps/apiModels';
import { Members } from '../../../../../../components/Members';
import ChatSvg from '../../../../../../components/icons/chat.svg';
import { Popper } from '../../../../../../components/Popper';
import { Loader } from '../../../../../../components/Loader';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { TasksContext } from '../../../../../../components/tasks/TasksProvider';
import { PreviewTaskMember } from '../../../PreviewTaskMember';
import { TaskMenu } from '../../../TaskMenu';
import { DueDate } from '../../../DueDate';
import { ProgressBar } from '../../ProgressBar';
import { Label } from '../../Label';
import { AttachmentIndicator } from '../../AttachmentIndicator';
import { columnsWidths, minRowHeight } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  item: task = {},
  isTabPage,
  isLoaded,
  recalculateHeight
}) => {
  const tasksContext = useContext(TasksContext);
  const history = useHistory();
  const isSelected = tasksContext.selectedTasksIDs.indexOf(task.id) !== -1;
  const classes = useStyles();
  const owner = task?.users?.find(({ id }) => id === task.owner_id);
  const [ parent, setParent ] = useState(task);

  const handleItemSelection = () => {
    tasksContext.toggleItemSelection(task.id);
  };

  const getTaskPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.task(task.id));
    }
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, task.users ]);

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow
      button
      selected={isSelected}
      minHeight={minRowHeight}
      title="Open task page"
      className={cn(classes.root, { [classes.root_casePage]: !!isTabPage })}
      onClick={getTaskPage}
    >
      {!isTabPage &&
        <Checkbox
          className={classes.checkbox}
          checked={isSelected}
          onClick={stopPropagation(handleItemSelection)}
        />
      }

      <ListRowCell grow flexBasis={columnsWidths.name}>
        <div className={classes.taskCard}>
          <Typography noWrap className={classes.taskName} title={task.name}>
            {task.name}
          </Typography>

          <div className={classes.taskInfo}>
            {task.comments_count > 0 &&
              <AttachmentIndicator
                icon={<ChatSvg />}
                title={task.comments_count}
                className={classes.attachmentIndicator}
              />
            }

            {task.files_count > 0 &&
              <AttachmentIndicator
                icon={<AttachFileIcon />}
                title={task.files_count}
                className={classes.attachmentIndicator}
                classes={{ icon: classes.attachmentIndicator__taskIcon }}
              />
            }

            <Typography noWrap color="textSecondary" title={task.description}>
              {task.description}
            </Typography>
          </div>
        </div>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.parent}>
        {task.parent?.name}
      </ListRowCell>

      <ListRowCell grow actions flexBasis={columnsWidths.type}>
        <ColorPreview color={task.type?.color} />

        <span>
          {task.type?.name || '-'}
        </span>
      </ListRowCell>

      {!isTabPage &&
        <ListRowCell flexBasis={columnsWidths.status}>
          {task.status?.name}
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.progress}>
        <ProgressBar
          logged={task.logged_time}
          estimate={task.estimated_time}
        />
      </ListRowCell>

      {!isTabPage &&
        <ListRowCell flexBasis={ columnsWidths.activity }>
          {moment.unix(task.created_at).format('L')}
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.activity}>
        {task.due_at && <DueDate task={task} />}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.assigned}>
        <Members
          isParent
          noWrap
          maxVisible={4}
          owner={owner}
          users={task.users || []}
          MenuComponent={PreviewTaskMember}
          menuProps={{
            disablePreview: true,
            parent: parent,
            onUpdate: setParent
          }}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.priority}>
        {task.priorityLabel && <Label name={task.priorityLabel} color={task.priority} />}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton
                buttonRef={anchorRef}
                onClick={stopPropagation(handleToggle)}
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <TaskMenu task={task} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
