import { useContext, useState } from 'react';
import { Formik } from 'formik';
import { mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Box, Collapse, FormControlLabel, Grid, SvgIcon } from '@material-ui/core';
import { AppointmentsContext, arrivalTypesMap, arrivalTypesOptions } from '../../../../../../components/appointments';
import { ArrivalTypesSelect } from '../../../../../../components/appointments/ArrivalTypesSelect';
import {
  DatePicker,
  OfficesLocationSelect,
  Switch
} from '../../../../../../components/FormField';
import { IconButton } from '../../../../../../components/IconButton';
import { UsersSelect } from '../../../../../../components/users';

export const FiltersBar = ({ appointment }) => {
  const [ filterIsOpen, setFilterIsOpen ] = useState(false);
  const { filter, applyFilter } = useContext(AppointmentsContext);

  const toggleFilterIsOpen = () => {
    setFilterIsOpen((filterIsOpen) => !filterIsOpen);
  };

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date });
  };

  const handleOfficesChange = (offices) => {
    applyFilter({ offices: offices.map(({ id }) => id) });
  };

  const handleUsersMultiSelect = (name) => (options) => {
    const users = options?.length ? options.map(({ id }) => id) : null;

    applyFilter({ [name]: users });
  };

  const handleArrivalTypesChange = (options) => {
    applyFilter({ arrival_types: options?.map(({ type }) => type) });
  };

  const handleFollowUpChange = ({ target }) => {
    applyFilter({ 'follow_up': target.checked ? target.value : null });
  };

  return (
    <Formik
      initialValues={{
        ...filter,

        arrival_types: [ arrivalTypesOptions.find(({ type }) => type === arrivalTypesMap.arrived) ]
      }}
      onSubmit={null}
    >
      <>
        <Grid container spacing={1} alignItems="center">
          <Box clone alignSelf="flex-end">
            <Grid item>
              <IconButton
                edge="start"
                color="primary"
                title="Collapse filters"
                onClick={toggleFilterIsOpen}
              >
                <SvgIcon>
                  <Icon path={mdiFilter} />
                </SvgIcon>
              </IconButton>
            </Grid>
          </Box>

          <Grid item xs>
            <ArrivalTypesSelect
              multiple
              name="arrival_types"
              label="Arrival types"
              onChange={handleArrivalTypesChange}
            />
          </Grid>

          {!!appointment?.id &&
            <Grid item>
              <FormControlLabel
                labelPlacement="top"
                label="Follow Up"
                control={
                  <Switch
                    name="follow_up"
                    value={appointment?.id}
                    color="primary"
                    onChange={handleFollowUpChange}
                  />
                }
              />
            </Grid>
          }
        </Grid>

        <Collapse in={filterIsOpen}>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePicker
                  clearable
                  name="appointment_from"
                  label="DOS From"
                  onChange={handleDatePickerChange('appointment_from')}
                />
              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  clearable
                  name="appointment_to"
                  label="DOS To"
                  onChange={handleDatePickerChange('appointment_to')}
                />
              </Grid>

              <Grid item xs={12}>
                <OfficesLocationSelect
                  multiple
                  name="offices"
                  label="Offices"
                  onChange={handleOfficesChange}
                />
              </Grid>

              <Grid item xs={12}>
                <UsersSelect
                  multiple
                  name="providers"
                  label="Providers"
                  params={{ role: 'doctor' }}
                  onChange={handleUsersMultiSelect('providers')}
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </>
    </Formik>
  );
};
