import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarEdit } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as prescriptionsApi from '../../../../../api/profile';
import { ApptInfo } from '../../../../../components/appointments';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { medicationsInitialValues } from '../CreatePrescriptionModal/Medications';
import { SinglePrescription } from '../CreatePrescriptionModal/SinglePrescription';
import { validationSchema } from '../validationSchema';

export const EditPrescriptionModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { prescriptionID }
}) => {
  const [ initialValues, setInitialValues ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const updatePrescription = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return prescriptionsApi.updateMedication(values).then((data) => {
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Prescription not updated', { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  useEffect(() => {
    prescriptionsApi.fetchMedication(prescriptionID).then(setInitialValues);
  }, []);

  return (
    <Dialog fullScreen={isTablet} PaperProps={{ style: { maxWidth: 1100 } }} {...DialogProps}>
      <Loader p={3} loading={!initialValues} render={
        () => (
          <Formik
            enableReinitialize
            initialValues={{
              ...initialValues,

              patient_id: initialValues?.patient,
              approved_id: initialValues?.approved,
              appointment_id: initialValues?.appointment_id,
              office_id: initialValues?.office,
              medications: initialValues?.medications?.length
                ? initialValues?.medications?.map((medication) => ({
                  ...medication,

                  ndc_code_id: medication?.ndc_code
                }))
                : [ medicationsInitialValues ]
            }}
            validationSchema={validationSchema}
            onSubmit={updatePrescription}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader
                  icon={<SvgIcon><Icon path={mdiCalendarEdit} /></SvgIcon>}
                  onClose={handleModalReject}
                >
                  Edit Prescription
                </ModalHeader>

                <ModalBody>
                  <ApptInfo disableApptFiled />
                  <SinglePrescription />
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
