export const initialValues = {
  towing_co_id: null,
  car_seats: null,
  auto_body_shop_id: null,
  pd_payer: null,
  rental_co_id: null,
  towing_paid: null,
  car_seat_reimb: null,
  pd_appraised_amount: null,
  deductible_paid: null,
  final_pd_amount: null,
  total_loss: null,
  deductible_reimbursed: null,
  rental_paid: null,
  rental_reimbursed: null
};
