export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  loader: {
    padding: spacing(2)
  },

  mainContent: {
    flexGrow: 1,
    overflow: 'hidden'
  },

  chartContent: {
    height: '100%',
    padding: spacing(1, 2),
    overflowY: 'auto',
    overflowX: 'hidden'
  }
});
