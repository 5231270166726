export const styles = ({ palette: { grey, getContrastText }, typography: { pxToRem, fontWeightMedium } }) => ({
  root: {
    minHeight: pxToRem(22),
    backgroundColor: ({ color }) => color || grey[100],
    color: ({ color }) => getContrastText(color || grey[100])
  },

  icon: {
    fontSize: pxToRem(16),
    color: 'inherit !important'
  },

  label: {
    paddingBottom: pxToRem(1.5),
    fontSize: pxToRem(12),
    fontWeight: fontWeightMedium,
    whiteSpace: 'nowrap'
  },

  deleteIcon: {
    color: 'inherit !important'
  },

  tooltip: {
    maxWidth: pxToRem(190)
  }
});
