import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow header>
      <ListRowCell flexBasis={columnsWidths.testId}>
        Name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.from}>
        From
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.to}>
        To
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.appointmentBookId}>
        Appointment Book
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.insuranceType}>
        Insurance Type
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.insuranceId}>
        Insurance
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
