export const immunizationInitialValues = {
  immunization_id: null,
  note: null,
  files: []
};

export const immunizationsInitialValues = {
  appointment_id: null,
  patient_id: null,
  office_id: null,
  immunization_id: null,
  note: null,
  files: []
};
