import { Autocomplete } from '../../../../FormField';

export const callbackLabelsMap = {
  null: 'All',
  1: 'Callbacks',
  0: 'Not callbacks'
};

export const callbackOptions = [
  {
    value: null,
    label: callbackLabelsMap[null]
  },
  {
    value: 0,
    label: callbackLabelsMap[0]
  },
  {
    value: 1,
    label: callbackLabelsMap[1]
  }
];

export const CallbackSelect = (props) => {
  return (
    <Autocomplete
      disableSearch
      placeholder="Select type..."
      options={callbackOptions}

      {...props}
    />
  );
};
