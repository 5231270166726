import { Typography } from '@material-ui/core';
import {
  activityActionTypesMap as apptActivityActionTypesMap
} from '../../../appointments/Activity/activityActionTypesMap';
import { getPropertiesMap } from '../../../Activities/helpers/getPropertiesMap';
import { fieldsMap } from './fieldsMap';

export const activityActionTypesMap = {
  'CREATED@CLAIM': () => [
    <Typography variant="subtitle1">Created claim</Typography>
  ],

  'UPDATED@CLAIM': (payload) => {
    const properties = payload?.properties;
    const propertiesMap = getPropertiesMap(fieldsMap);

    return Object.keys(properties)?.reduce((messages, key) => {
      return propertiesMap[key] ? messages.concat(propertiesMap[key](properties)) : messages;
    }, []);
  },

  ...apptActivityActionTypesMap
};

export const getActionMessages = ({ log_data }) => {
  return activityActionTypesMap[log_data?.type]?.(log_data?.payload);
};
