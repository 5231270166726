import { mdiSquareEditOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import * as claimsApi from '../../../../../api/cases';
import { transformToOption } from '../../../../../helpers/selectHelpers';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import {
  ModalBody, ModalContainer, ModalFooter, ModalHeader, ModalHeaderActions
} from '../../../../../components/Modal';
import { IconButton } from '../../../../../components/IconButton';
import { useModal } from '../../../../../components/ModalsProvider';
import { Tooltip } from '../../../../../components/Tooltip';
import { Loader } from '../../../../../components/Loader';
import { Body } from './Body';
import { validationSchema } from '../CreateCasePage/insClaimValidationSchema';

export const UpdateInsuranceClaimModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { initialValues = {}, companyID = null }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const handleDeleteInsClaim = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        claimsApi.deleteInsClaim(initialValues.id).then(() => {
          enqueueSnackbar('Successfully deleted', {
            variant: 'success'
          });
          handleModalResolve();
        }).catch(({ data: { errors } = {} }) => {
          if (errors) {
            enqueueSnackbar('Not deleted', {
              variant: 'error'
            });
          }
        });
      }
    });
  };

  const updateInsuranceClaim = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return claimsApi.updateInsClaim(values).then((data) => {
      enqueueSnackbar('Successfully updated', {
        variant: 'success'
      });
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        adj: initialValues?.adj_numbers || [],
        doi: initialValues?.doi?.map((item) => ({ is_range: !item?.date, ...item })) || [],
        status: transformToOption(initialValues.status),
        body_parts: initialValues?.body_parts?.map((item) => ({ ...item, id: item }))
      }}
      validationSchema={validationSchema}
      onSubmit={updateInsuranceClaim}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} PaperProps={{ style: { maxWidth: 900 } }} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiSquareEditOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Edit Insurance Claim

              <ModalHeaderActions disableGutters>
                <Tooltip arrow title="Delete note">
                  <IconButton
                    color="error"
                    onClick={handleDeleteInsClaim}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ModalHeaderActions>
            </ModalHeader>

            <ModalBody>
              <Body companyID={companyID} />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
