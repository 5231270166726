import { useContext } from 'react';
import { makeStyles, useMediaQuery, Fab, Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AppBar } from '../../../../../../components/AppBar';
import { Popper } from '../../../../../../components/Popper';
import { Tooltip } from '../../../../../../components/Tooltip';
import { FileMenu } from '../../../FileMenu';
import { FileTypeIcon } from '../../../FileTypeIcon';
import { menuOptionsTypesMap } from '../../../menuOptionsTypes';
import { FilePreviewContext } from '../../FilePreviewContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({ actionsBarPortalRef, singleView, menuList, onClickModalClose }) => {
  const { selectedFile, sidebarIsOpen, toggleSidebarIsOpen } = useContext(FilePreviewContext);
  const classes = useStyles();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <AppBar position="absolute" className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <div className={classes.fileName}>
        <IconButton
          title="Close window"
          className={classes.fileName__arrow}
          onClick={onClickModalClose}
        >
          <ArrowBackIcon color="inherit" />
        </IconButton>

        {selectedFile?.id && <FileTypeIcon file={selectedFile} className={classes.fileName__icon} />}

        <Typography noWrap className={classes.fileName__name}>
          {selectedFile?.original_filename}
        </Typography>

        <div ref={actionsBarPortalRef} />
      </div>

      {!isTablet && selectedFile?.id && menuList?.map((
        { isHidden, type, Icon, label, onClick },
        index
      ) => !isHidden && type === menuOptionsTypesMap.menuItem && (
        <Tooltip key={index} title={label}>
          <IconButton
            className={classes.button}
            onClick={onClick}
          >
            <Icon />
          </IconButton>
        </Tooltip>
      ))}

      {isTablet &&
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton
                edge="end"
                className={classes.button}
                buttonRef={anchorRef}
                onClick={handleToggle}
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <FileMenu menuList={menuList} onClose={handleClose} />
          )}
        </Popper>
      }

      {singleView ||
        <Fab
          size="small"
          title={sidebarIsOpen ? 'Hide files sidebar' : 'Show files sidebar'}
          className={classes.sidebarToggleButton}
          onClick={toggleSidebarIsOpen}
        >
          {sidebarIsOpen
            ? <FastForwardIcon fontSize="small" />
            : <FastRewindIcon fontSize="small" />
          }
        </Fab>
      }
    </AppBar>
  );
};
