import { Box, makeStyles, Link, Typography, SvgIcon, List } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { mdiForumOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import {
  caseUserRoles,
  caseUserRolesTypesMap,
  rolesMap as caseRolesMap
} from '../../../../../../../../dataMaps';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { getUserFullName } from '../../../../../../../../helpers/users';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { useChatActions } from '../../../../../../../../utils/useChatActions';
import { Loader } from '../../../../../../../../components/Loader';
import { UserAvatar, UserPreviewModal } from '../../../../../../../../components/users';
import { VerticalDivider } from '../../../../../../../../components/VerticalDivider';
import { CardHeader } from '../../../../../../../../components/Cards';
import { CardItem } from '../../../../../../../../components/Cards';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { Contact } from '../../../../../../../../components/Contact';
import { Divider } from '../../../../../../../../components/Divider';
import { Label } from '../../../../../../../../components/Label';
import { Popper } from '../../../../../../../../components/Popper';
import { Fab } from '../../../../../../../../components/Fab';
import { statusTypesColors, statusTypesNames } from '../../../../../../Members';
import { ContactsMenu } from '../../List/Row/ContactsMenu';
import { OfficePopper } from '../../List/Row/OfficePopper';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ item: caseUser = {}, onCaseItemUpdate, caseItem }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { isChatOpening, openChatWithUser } = useChatActions();
  const { user, case_role = '' } = caseUser;
  const userRole = caseUserRoles.find((role) => role.value === case_role)?.label
    || caseUserRolesTypesMap[caseRolesMap.creator];

  const openPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: user.id }
    });
  };

  const getUserPage = () => {
    if (user.id) {
      window.open(itemsRoutesByModels.user(user.id));
    }
  };

  const startChatWithUser = () => {
    openChatWithUser(user?.id);
  };

  return (
    <CardItem onClick={openPreview}>
      <CardHeader
        disableCheckBox
        disableCardMenu={hasRole(rolesMap.client, rolesMap.patient)}
        alignItems="center"
        menuComponent={ContactsMenu}
        menuComponentProps={{ caseUser, onCaseItemUpdate, caseItem }}
        px={2}
        py={1}
      >
        <Label
          color={statusTypesColors[user.is_active]}
          label={statusTypesNames[user.is_active] || 'Active'}
        />
      </CardHeader>

      <div className={classes.content}>
        <UserAvatar size="xxxl" user={user} />

        <Typography gutterBottom variant="h6" align="center">
          {getUserFullName(user)}
        </Typography>

        <Typography
          gutterBottom
          variant="subtitle2"
          align="center"
          color="error"
        >
          {userRole}
        </Typography>

        <Contact
          gutterBottom
          type="mailto"
          color="primary"
          contact={user.email}
        />

        <Contact gutterBottom type="tel" contact={user.mobile_phone_number} />

        {caseUser?.user?.work?.offices?.length ? (
          <Popper
            anchorRenderer={
              ({ isOpen, anchorRef, handleToggle }) => (
                <Fab
                  size="small"
                  color="secondary"
                  variant="extended"
                  buttonRef={anchorRef}
                  endIcon={!isOpen
                    ? <ExpandMore />
                    : <ExpandLess />
                  }
                  onClick={stopPropagation(handleToggle)}
                >
                  {caseUser?.user?.work?.offices?.length || 0}
                </Fab>
              )
            }
          >
            <List>
              {caseUser?.user?.work?.offices?.map((office) => (
                <OfficePopper key={office.id} office={office} />
              ))}
            </List>
          </Popper>
        ) : (
          <Typography color="textSecondary">No info.</Typography>
        )}
      </div>

      {!hasRole(rolesMap.client, rolesMap.patient) && (
        <Box height={47}>
          <Divider disableBottomGutter />

          <Box display="flex" alignItems="center" height="100%">
            <Box width="50%" textAlign="center">
              <Loader surface loading={isChatOpening} size={24} render={
                () => (
                  <Link
                    component="button"
                    underline="none"
                    color="textPrimary"
                    onClick={stopPropagation(startChatWithUser)}
                    className={classes.actionLink}
                  >
                    <SvgIcon className={classes.actionIcon}>
                      <Icon path={mdiForumOutline} />
                    </SvgIcon>

                    Message
                  </Link>
                )}
              />
            </Box>

            <VerticalDivider />

            <Box width="50%" textAlign="center">
              <Link
                underline="none"
                color="textPrimary"
                onClick={stopPropagation(getUserPage)}
                className={classes.actionLink}
              >
                <EyeIcon className={classes.actionIcon} />

                View
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </CardItem>
  );
};
