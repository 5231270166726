import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, Box, Paper } from '@material-ui/core';
import * as claimsApi from '../../../../../api/claims';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { transformServiceLineForForm } from '../../../../../components/claims/ServiceLineFormModal/ServiceLineForm';
import { transformSituationalNumberForForm } from '../../../../../components/claims/SituationalNumberFormModal';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
import { ActionsBar } from './ActionsBar';
import { MainContent } from './MainContent';
import { SideBar } from '../SideBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ClaimPreview = () => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const [ claim, setClaim ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const scrollerRef = useRef();
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState(true);
  const rootRef = useRef(null);
  const { width = window.innerWidth } = useResizeObserver({ ref: rootRef });

  const changeClaim = (updatedClaim) => {
    setClaim((claim) => ({ ...claim, ...updatedClaim }));
  };

  useEffect(() => {
    setIsLoading(true);

    claimsApi.fetchClaim(params.id).then((claim) => {
      setIsLoading(false);

      setClaim({
        ...claim,

        situational_number: transformSituationalNumberForForm(claim.situational_number),
        appointment: claim.appointment && {
          ...claim.appointment,

          procedures: claim.appointment?.procedures?.map((procedure) => ({
            ...procedure,

            service_line: procedure.service_line?.map((serviceLine) => {
              return transformServiceLineForForm({ appointment: claim.appointment, serviceLine });
            })
          }))
        }
      });
    }).catch(() => {
      history.push('/billing/claims');
    });
  }, [ params.id ]);

  return (
    <div ref={rootRef} className={classes.root}>
      <Paper
        elevation={0}
        component={Box}
        display="flex"
        position="relative"
        width="100%"
        height="100%"
        overflow="hidden"
      >
        <Loader loading={!claim} p={3} render={
          () => (
            <>
              <SideBar width={width} disableAppointments claim={claim} sidebarIsOpen={sidebarIsOpen} />

              <div className={classes.main}>
                <Loader loading={isLoading} p={3} render={
                  () => (
                    <>
                      <ActionsBar
                        claim={claim}
                        sidebarIsOpen={sidebarIsOpen}
                        setSidebarIsOpen={setSidebarIsOpen}
                      />

                      <Box flexGrow={1}>
                        <CustomScrollbars scrollerRef={scrollerRef} style={{ height: '100%' }}>
                          <MainContent claim={claim} onClaimChange={changeClaim} />
                        </CustomScrollbars>
                      </Box>
                    </>
                  )}
                />
              </div>
            </>
          )}
        />
      </Paper>
    </div>
  );
};
