import { Typography } from '@material-ui/core';
import { CurrencyFormat } from '../../CurrencyFormat';

export const Money = ({ before, after, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Set <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;to&nbsp;

        <Typography variant="subtitle1">
          <CurrencyFormat value={after} />
        </Typography>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Typography variant="subtitle1">
          <CurrencyFormat value={before} />
        </Typography>
      </>
    );
  } else {
    return (
      <>
        Changed <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;

        <Typography variant="subtitle1">
          <CurrencyFormat value={before} />
        </Typography>

        &nbsp;to&nbsp;

        <Typography variant="subtitle1">
          <CurrencyFormat value={after} />
        </Typography>
      </>
    );
  }
};
