import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import { CardHeader, CardItem } from '../../../../../../../../components/Cards';
import { TaskPreviewContext } from '../../../../../../../../components/tasks/TasksProvider';
import { Body } from '../../../../../MainWindow/ColumnsList/Column/Card/Body';
import { TaskMenu } from '../../../../../TaskMenu';

export const Card = ({ item: task = {}, isLoaded, recalculateHeight }) => {
  const history = useHistory();
  const taskPreviewContext = useContext(TaskPreviewContext);
  const isSelected = !!task && taskPreviewContext && taskPreviewContext.selectedTaskId === task.id;

  const getTaskPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.task(task.id));
    }
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, task.users ]);

  return (
    <Box p={0.5}>
      <CardItem
        py={0.5}
        pr={1}
        pl={1.5}
        selected={isSelected}
        bgcolor="common.white"
        borderRadius="borderRadius"
        onClick={stopPropagation(getTaskPage)}
      >
        <CardHeader
          disableCheckBox
          menuComponent={TaskMenu}
          menuComponentProps={!taskPreviewContext ? { task } : { task, taskPreviewContext }}
        >
          <Typography noWrap title={task.name} variant="h5">
            {task.name}
          </Typography>
        </CardHeader>

        <Body task={task} />
      </CardItem>
    </Box>
  );
};
