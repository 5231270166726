import { Box, Grid, SvgIcon, Typography } from '@material-ui/core';
import { mdiAttachment, mdiCommentMultipleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { VerticalDivider } from '../../VerticalDivider';

export const CardFooter = ({
  disableCommentsCount = false,
  disableMediaCounts = false,
  countComments = 0,
  countMedia = 0,
  children,
  ...props
}) => {
  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      alignItems="center"
      justify="space-between"
      component={Box}
      pt={1.5}
      {...props}
    >
      <Grid item xs={(disableMediaCounts || disableCommentsCount) ? 9 : 8}>
        {children}
      </Grid>

      <Grid item>
        <Box display="flex" alignItems="center">
          {!disableMediaCounts &&
            <Box display="flex" alignItems="center" fontSize={15}>
              <SvgIcon color="inherit"><Icon path={mdiAttachment} /></SvgIcon>

              <Box ml={1}>
                <Typography color="inherit">{countMedia}</Typography>
              </Box>
            </Box>
          }

          {!disableCommentsCount &&
            <>
              <VerticalDivider verticalGutters={0.25} horizontalGutters={2} />

              <Box display="flex" alignItems="center" color="info.main" fontSize={15}>
                <SvgIcon color="inherit" fontSize="inherit"><Icon path={mdiCommentMultipleOutline} /></SvgIcon>

                <Box ml={1}>
                  <Typography color="inherit">{countComments}</Typography>
                </Box>
              </Box>
            </>
          }
        </Box>
      </Grid>
    </Grid>
  );
};
