export const styles = ({
  spacing,
  palette,
  typography: { pxToRem, fontWeightMedium }
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 1, 2, 2),
    borderBottom: `1px solid ${palette.divider}`
  },

  icon: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 74,
    height: 74,
    borderRadius: '50%',
    background: palette.grey[600],
    color: palette.common.white,
    fontSize: pxToRem(26)
  },

  info: {
    flexGrow: 1,
    paddingLeft: spacing(2.5),
    paddingRight: spacing(2)
  },

  name: {
    display: 'inline-flex',
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(21),
    maxHeight: pxToRem(48),
    overflow: 'hidden',
    marginBottom: spacing(0.5),
    textTransform: 'none'
  },

  number: {
    fontWeight: fontWeightMedium,
    color: palette.grey[600]
  }
});
