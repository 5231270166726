import _ from 'lodash';
import { createHookReducer } from '../../../../../helpers/createHookReducer';
import { initialState } from './initialState';
import * as types from './types';

export const reducer = createHookReducer({
  [types.CONTACTS_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.CONTACTS_FETCH_SUCCESS]: (
    { filter, contacts, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      contacts: data
    };
  },

  [types.ADD_CONTACT]: (state, payload) => {
    const { pagination, filter, contacts, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((contacts.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, total, page: page === 0 ? 1 : page },
      contacts: [ payload, ...contacts ]
    };
  },

  [types.UPDATE_CONTACT]: (state, payload) => {
    return {
      ...state,

      contacts: state.contacts.map((item) => {
        return item.id === payload.id ? payload : item;
      })
    };
  },

  [types.DELETE_CONTACT]: ({ contacts, pagination, filter, ...state }, contactId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, total },
      contacts: contacts.filter(({ id }) => id !== contactId)
    };
  },

  [types.RESET_CONTACTS]: ({ filter, ...state }, newFilter) => {
    const prevFilter = _.isEqual(newFilter, { ...initialState.filter }) && newFilter;

    return {
      ...state,

      isFetched: false,
      filter: prevFilter || { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.TOGGLE_ALL_CONTACTS_SELECTION]: ({ contacts, selectedIDs, ...state }) => {
    const allContactsIsSelected = contacts.length === selectedIDs.length;

    return {
      ...state,

      contacts,
      selectedIDs: allContactsIsSelected ? [] : contacts.map((item) => item.id)
    };
  },

  [types.TOGGLE_CONTACT_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.includes(id)
        ? selectedIDs.filter((item) => item !== id)
        : selectedIDs.concat(id)
    };
  }
});
