import { useState, useEffect } from 'react';
import { Dialog, Button, FormControlLabel, useTheme, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as formsApi from '../../../../../api/profile';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import {
  CreateBaseCustomerModal
} from '../../../../../app/Dashboard/Members/CreateBaseCustomerModal';
import { useModal } from '../../../../ModalsProvider';
import { Checkbox } from '../../../../FormField';
import { Fab } from '../../../../Fab';
import {
  ModalHeader,
  ModalBody,
  ModalContainer,
  ModalHeaderActions
} from '../../../../Modal';
import { Grid } from './Grid';
import { List } from './List';

export const ApprovePatientModal = ({
  payload: {
    medicalFormID,
    patientData
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const { openModal } = useModal();
  const [ patients, setPatients ] = useState();
  const [ isUpdatablePatient, setIsUpdatablePatient ] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const toggleIsUpdatablePatient = () => {
    setIsUpdatablePatient((isUpdatablePatient) => !isUpdatablePatient);
  };

  const handlePatientSelect = (patient) => {
    handleModalResolve({ isUpdatablePatient, patient });
  };

  const addPatient = () => {
    openModal(CreateBaseCustomerModal, {
      payload: {
        isPatient: true,
        initialValues: {
          ...patientData,

          role: rolesMap.patient
        }
      },

      onModalResolved: (patient) => {
        handleModalResolve({ isUpdatablePatient, patient });
      }
    });
  };

  useEffect(() => {
    formsApi.searchUsersForMedicalForm(medicalFormID).then(({ patients }) => {
      setPatients(patients);
    });
  }, []);

  return (
    <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Select Patient

          <ModalHeaderActions>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isUpdatablePatient}
                  name="is_updatable_patient"
                  onChange={toggleIsUpdatablePatient}
                />
              )}
              label={isTablet ? 'Update patient profile' : 'Update patient profile data from this form'}
            />

            {isTablet ? (
              <Fab
                size="small"
                color="primary"
                variant="round"
                onClick={addPatient}
              >
                <AddIcon />
              </Fab>
            ) : (
              <Button
                color="primary"
                startIcon={<AddIcon/>}
                onClick={addPatient}
              >
                Add Patient
              </Button>
            )}
          </ModalHeaderActions>
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          {!isTablet
            ? <List patients={patients} onUserSelect={handlePatientSelect} />
            : <Grid patients={patients} onUserSelect={handlePatientSelect} />
          }
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
