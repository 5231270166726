export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  loader: {
    padding: spacing(2)
  },

  mainContent: {
    flexGrow: 1,
    overflow: 'auto',
    padding: spacing(2),
    display: 'flex',
    alignItems: 'center'
  }
});
