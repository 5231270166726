import { api } from '../../api';

export const fetchCases = (config = {}) => {
  return api.get('/case-reminders/cases', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchCaseRemindersStatistics = (caseId, config = {}) => {
  return api.get(`/case-reminders/case/${caseId}/stats`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
