import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog } from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import * as printersApi from '../../../../../api/printers';
import { errorsMap } from '../../../../../utils/validation';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { PrinterConfigurationForm } from '../PrinterConfigurationForm';

export const EditConfigurationModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    printer = []
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const editConfiguration = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return printersApi.updatePrinter(values).then(({ data }) => {
      enqueueSnackbar('Editing saved!', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors } = {}) => {
      if (errors?.name?.includes(errorsMap.unique)) {
        setErrors({ name: 'Printer with the same name already exists' });
      }

      if (errors?.directory?.includes(errorsMap.unique)) {
        setErrors({ directory: 'Printer with this directory already exists' });
      }

      enqueueSnackbar('Something goes wrong!', { variant: 'error' });
    });
  };

  return (
    <Dialog maxWidth="sm" {...DialogProps}>
      <Formik
        initialValues={{
          ...printer,

          user_id: printer.user
        }}
        onSubmit={editConfiguration}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader icon={<PrintOutlinedIcon />} onClose={handleModalReject}>
              Edit Printer Configuration
            </ModalHeader>

            <ModalBody>
              <PrinterConfigurationForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
