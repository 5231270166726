import { Box, Chip, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import { Category } from '../../../app/Dashboard/BillingPage/BillingSettings/Category';
import { getUserFullName, getUserNameAbbr } from '../../../helpers/users';
import { Avatar } from '../../Avatar';
import { IconButton } from '../../IconButton';
import { useModal } from '../../ModalsProvider';
import { Autocomplete } from '../Autocomplete';
import { ExpensesListModal } from './ExpensesListModal';

export const ExpensesSelect = ({ name, params = {}, onChange = () => {}, ...props }) => {
  const { openModal } = useModal();
  const { setFieldValue } = useFormikContext();

  const handleOpenExpensesListModal = () => {
    openModal(ExpensesListModal, {
      onModalResolved: (expense) => {
        setFieldValue(name, expense);
        onChange(expense);
      }
    });
  };

  return (
    <Autocomplete
      open={false}
      name={name}
      getOptionLabel={(option) => option && ''}
      getOptionValue={(expense) => expense}
      TextFieldProps={{
        onClick: handleOpenExpensesListModal
      }}
      getInputProps={(value) => !value ? null : ({
        startAdornment: (
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box maxWidth={180}>
              <Chip
                label={(
                  <Typography noWrap>{getUserFullName(value?.user)}</Typography>
                )}
                avatar={<Avatar alt={getUserNameAbbr(value?.user)} src={value?.user?.avatar} />}
              />
            </Box>

            <Box ml={1} maxWidth={180}>
              <Category category={value?.category} />
            </Box>
          </Box>
        )
      })}
      openButton={
        <IconButton size="small">
          <SearchIcon />
        </IconButton>
      }

      {...props}
    />
  );
};
