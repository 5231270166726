import { useContext } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { IconButton } from '../../../../../../components/IconButton';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { Tooltip } from '../../../../../../components/Tooltip';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { CreateReportButton } from '../CreateReportButton';

export const Header = ({ filterOpenButton }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  return (
    <PageHeader>
      <PageHeaderTitle>
        Time Reports
      </PageHeaderTitle>

      <PageHeaderActions>
        {hasRole(rolesMap.admin, rolesMap.supervisor) && (
          <CreateReportButton />
        )}

        {isTablet && filterOpenButton}

        {!(isMobile || isTablet) &&
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      </PageHeaderActions>
    </PageHeader>
  );
};
