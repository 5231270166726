import { IconButton, Grid, Typography, Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Divider } from '../../../../../../../../components/Divider';
import { Date } from '../../../../../../../../components/Date';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { CaseReOpenModal } from '../CaseReOpenModal';

export const widthBreakpointSmall = 430;

export const CaseReOpenContent = ({ width, caseItem }) => {
  const { openModal } = useModal();
  const isPatient = hasRole(rolesMap.patient);

  const openCaseReOpenModal = (parent) => () => {
    openModal(CaseReOpenModal, {
      payload: {
        caseItem,
        parent
      }
    });
  };

  return (
    <Grid container spacing={1} alignItems="center" component={Box} pb={2}>
      <Grid item xs={width > widthBreakpointSmall ? 5 : 12}>
        <Typography variant="caption" color="textSecondary">
          Date Case Re-Opened
        </Typography>
      </Grid>

      <Grid item xs={width > widthBreakpointSmall ? 6 : 11}>
        <Date variant="h5" date={caseItem?.reopen_log?.reopened_at} />
      </Grid>

      {!isPatient &&
        <Grid item xs={1}>
          <IconButton color="secondary" onClick={openCaseReOpenModal('re_open')}>
            <EditIcon fontSize="small"/>
          </IconButton>
        </Grid>
      }

      <Grid item xs={width > widthBreakpointSmall ? 5 : 12}>
        <Typography variant="caption" color="textSecondary">
          Authorized By (Adjuster)
        </Typography>
      </Grid>

      <Grid item xs={width > widthBreakpointSmall ? 7 : 12}>
        <UserLink
          size="sm"
          variant="h5"
          user={caseItem?.reopen_log?.authorized_user}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider gutter={1} type="dashed" />
      </Grid>

      <Grid item xs={width > widthBreakpointSmall ? 5 : 12}>
        <Typography variant="caption" color="textSecondary">
          Date Case Re-Closed
        </Typography>
      </Grid>

      <Grid item xs={width > widthBreakpointSmall ? 6 : 11}>
        <Date variant="h5" date={caseItem?.reopen_log?.reclosed_at} />
      </Grid>

      {!isPatient &&
        <Grid item xs={1}>
          <IconButton color="secondary" onClick={openCaseReOpenModal('re_close')}>
            <EditIcon fontSize="small"/>
          </IconButton>
        </Grid>
      }

      <Grid item xs={width > widthBreakpointSmall ? 5 : 12}>
        <Typography variant="caption" color="textSecondary">
          Case Re-Closed By (Employee)
        </Typography>
      </Grid>

      <Grid item xs={width > widthBreakpointSmall ? 7 : 12}>
        <UserLink
          size="sm"
          variant="h5"
          user={caseItem?.reopen_log?.reclosed_user}
        />
      </Grid>
    </Grid>
  );
};
