import { useModal } from 'react-modal-hook';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { FilePreviewModal } from '../../app/Dashboard/files-common';
import { SingleValue as FormPreview } from '../FormField/selects/MedicalFormsSelect/SingleValue';
import { feedsTypesMap } from './feedsTypesMap';

export const feedMessages = {
  [feedsTypesMap.needSign]: ({ data, onClose = () => {} }) => (
    <>
      Need sign:&nbsp;
      {!data.model_data ? '-' :(
        <Link
          component={RouterLink}
          to={!data.model_data?.patient_id
            ? `/profile/medical-info/medical-forms/${data?.model_id}/seven-step`
            : `/patients/${data.model_data?.patient_id}/medical-info/medical-forms/${data?.model_id}/seven-step`
          }
          onClick={onClose}
        >
          <FormPreview wrap children={data.model_data} />
        </Link>
      )}
    </>
  ),

  [feedsTypesMap.formIsSigned]: ({ data, onClose = () => {} }) => (
    <>
      Form is signed:&nbsp;
      {!data.model_data ? '-' : (
        <Link
          component={RouterLink}
          to={!data.model_data?.patient_id
            ? `/profile/medical-info/medical-forms/${data?.model_id}/seven-step`
            : `/patients/${data.model_data?.patient_id}/medical-info/medical-forms/${data?.model_id}/seven-step`
          }
          onClick={onClose}
        >
          <FormPreview wrap children={data.model_data} />
        </Link>
      )}
    </>
  ),

  [feedsTypesMap.mergedPdf]: ({ data }) => {
    const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
      <FilePreviewModal
        DialogProps={{
          open,
          onExited,
          onCancel: closeFilesPreview
        }}
        payload={{
          disableFetch: true,
          file: data.model_data,
          singleView: true
        }}
      />
    ), []);

    return (
      <>
        Merge PDF is complete:&nbsp;

        <Link onClick={openFilesPreview}>
          {data.model_data.original_filename}
        </Link>
      </>
    );
  }
};
