import { Fragment, useContext, useRef } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import { UserPreviewModal } from '../../../users/UserPreviewModal';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { useModal } from '../../../ModalsProvider';
import { UserLink } from '../../../UserLink';
import { Date } from '../../../Date';
import { MedicalHistoryContext } from '../MedicalHistoryProvider';

const styles = ({ spacing, transitions, palette }) => ({
  root: {
    width: ({ width = 0 }) => width,
    transition: transitions.create(),
    padding: ({ width = 0 }) => width > 0 ? spacing(1, 0, 1, 1) : 0,
    borderLeft: ({ width = 0 }) => width > 0 && `1px solid ${palette.divider}`,
    height: '100%'
  }
});

const useStyles = makeStyles(styles);

export const HistoryPreview = () => {
  const { openModal } = useModal();
  const scrollElementRef = useRef();
  const { selectedItem } = useContext(MedicalHistoryContext);
  const classes = useStyles({ width: selectedItem?.id ? 450 : 0 });
  const ScrollWrapper = selectedItem?.id ? CustomScrollbars : Fragment;
  const scrollProps = selectedItem?.id ? {
    scrollerRef: scrollElementRef,
    style: {
      height: '100%'
    }
  } : {};

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <div className={classes.root}>
      <ScrollWrapper {...scrollProps}>
        <Typography paragraph variant="h5" color="secondary">
          {selectedItem?.sub_data?.title || '-'}
        </Typography>

        <Typography gutterBottom variant="h5">Referring Physician</Typography>

        <UserLink
          user={selectedItem?.provider}
          onClick={openUserPreview(selectedItem?.provider?.id)}
        />

        <Box display="flex" alignItems="center" pt={0.5}>
          <Box clone mr={0.5} color="grey.500">
            <RoomIcon color="inherit" />
          </Box>

          <Typography color="textSecondary">
            {selectedItem?.office || 'No info.'}
          </Typography>
        </Box>

        <Box pt={2} color="text.secondary">
          <Typography variant="h5">Date & Time</Typography>
          <Date color="inherit" format="L, LT" date={selectedItem?.appointment_at} />
        </Box>

        <Box pt={2} pr={1}>
          <Typography gutterBottom variant="h5">Recipe</Typography>

          <Typography color="textSecondary">
            {selectedItem?.sub_data?.text || 'No info.'}
          </Typography>
        </Box>
      </ScrollWrapper>
    </div>
  );
};
