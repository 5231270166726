import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography, Box } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { FeatureFlagsContext } from '../FeatureFlagsProvider';
import { Header } from './Header';
import { Row } from './Row';

export const columnsWidths = {
  name: 250,
  date: 200,
  users: 200,
  actions: 90
};

export const List = () => {
  const {
    featureFlags,
    isFetching,
    isFetched,
    meta,
    loadNextPage,
    resetFeatureFlags
  } = useContext(FeatureFlagsContext);
  const scrollElementRef = useRef();
  const rootRef = useRef(null);

  useEffect(() => {
    resetFeatureFlags();
  }, []);

  return (
    <Box ref={rootRef} display="flex" flexDirection="column" height="100%">
      <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
        <Loader loading={!isFetched} p={3} render={
          () => (
            <>
              {!featureFlags.length ? (
                <Box p={3}>
                  <Typography align="center">No feature flags found</Typography>
                </Box>
              ) : (
                <>
                  <Header />

                  <MuiList disablePadding>
                    <InfiniteListLoader
                      items={featureFlags}
                      isFetching={isFetching}
                      isFetched={isFetched}
                      pagination={meta}
                      RowComponent={Row}
                      scrollElementRef={scrollElementRef}
                      onNeedNextPage={loadNextPage}
                    />
                  </MuiList>
                </>
              )}
            </>
          )}
        />
      </Scrollbars>
    </Box>
  );
};
