import { CurrencyFormat } from '../CurrencyFormat';
import { Date } from '../Date';
import { Text } from '../Text';

export const claimInsInfoFieldsMap = {
  amount: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Amount" />,
  client_id: (before, after) => <Text before={before} after={after} fieldLabel="Client Id" />,
  case_id: (before, after) => <Text before={before} after={after} fieldLabel="Case Id" />,
  office_id: (before, after) => <Text before={before} after={after} fieldLabel="Office Id" />,
  claim_insurance_type: (before, after) => <Text before={before} after={after} fieldLabel="Claim Insurance Type" />,
  insurance_company_id: (before, after) => <Text before={before} after={after} fieldLabel="Insurance Company Id" />,
  examiner_id: (before, after) => <Text before={before} after={after} fieldLabel="Examiner Id" />,
  updated_at: (before, after) => <Date before={before} after={after} fieldLabel="Updated At" />,
  created_at: (before, after) => <Date before={before} after={after} fieldLabel="Created At" />,
  policy_period_start: (before, after) => <Date before={before} after={after} fieldLabel="Policy Period Start" />,
  policy_period_finish: (before, after) => <Date before={before} after={after} fieldLabel="Policy Period Finish" />,
  insurance_carrier: (before, after) => <Text before={before} after={after} fieldLabel="Insurance Carrier" />
};
