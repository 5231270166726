import { Box, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { ReadonlyCheckbox } from '../../../../../../ReadonlyCheckbox';

export const TreatmentItem = ({ item, title, text }) => {
  return item && (
    <>
      <ReadonlyCheckbox
        checked={item}
        variant="h5"
        BoxProps={{ px: 1, mb: 1 }}
        iconColor="success"
        title={title}
      />

      <Box pl={3.5} display="flex" alignItems="flex-start" mb={2}>
        <Box display="flex" alignItems="center">
          <NotesIcon fontSize="small" />

          <Box mr={1.5} ml={0.5}>
            <Typography variant="subtitle2">
              Note
            </Typography>
          </Box>
        </Box>

        <Typography color="textSecondary">
          {text || 'No info'}
        </Typography>
      </Box>
    </>
  );
};
