import * as tasksApi from '../../../../api/tasks';
import { Autocomplete } from '../../Autocomplete';

const fetchTasks = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return tasksApi.fetchTasks({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

export const TasksSelect = ({ params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      name="task_id"
      label="Task"
      placeholder="Search task..."
      onNeedFetch={fetchTasks(params)}
      getOptionLabel={(option) => option?.name}
      getOptionValue={(option) => option?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}

      {...props}
    />
  );
};
