export const styles = ({ spacing }) => ({
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: spacing(2, 0)
  },

  list: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: spacing(3)
  }
});
