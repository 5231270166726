import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../../api';
import * as reportsApi from '../../../../../../api/profile';
import { ReportsContext } from '../../../../../../components/medical/reports/diagnosis/ReportsProvider';
import { getReportFormRoutes } from './getReportFormRoutes';
import { getExistingFormSteps, steps } from './steps';
import { stepComponents } from './stepComponents';

export const StepContext = createContext();

export const StepProvider = ({ id, children }) => {
  const history = useHistory();
  const [ formValues, setFormValues ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);
  const [ isSignUpdate, setIsSignUpdate ] = useState(false);
  const [ activeStep, setActiveStep ] = useState(0);
  const [ completed, setCompleted ] = useState({});
  const [ isDirty, setIsDirty ] = useState(false);
  const { pathname, updateReport, setDoi, setFormItem } = useContext(ReportsContext);
  const { enqueueSnackbar } = useSnackbar();
  const Component = stepComponents[activeStep];
  const cancelFetch = useRef(() => {});

  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;

    setActiveStep(newActiveStep);
    history.push(getReportFormRoutes({ id, pathname })[activeStep + 1]);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    history.push(getReportFormRoutes({ id, pathname })[activeStep - 1]);
  };

  const handleComplete = () => {
    const newCompleted = completed;

    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext(id);
  };

  const handleUpdateForm = ({ values, isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return reportsApi.updateDiagnosisReport(values).then(({ data }) => {
      enqueueSnackbar('Successfully update info', { variant: 'success' });
      updateReport(data);
      handleComplete();
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === steps.length;
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    history.push(pathname);
  };

  useEffect(() => {
    cancelFetch.current();
    setIsFetched(false);
    setIsSignUpdate(false);

    reportsApi.fetchDiagnosisReport(id, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      setFormValues(data);
      setCompleted(getExistingFormSteps(data));
      data?.general_data && setDoi(data?.general_data?.doi);
      setFormItem(data);
      setIsFetched(true);
    }).catch(() => {
      setFormValues(null);
      setIsFetched(true);
    });

    return () => {
      cancelFetch.current();
    };
  }, [ isSignUpdate ]);

  const providerValue = {
    isFetched,
    formValues,
    pathname,
    completed,
    setFormValues,
    Component,
    activeStep,
    isDirty,

    // functions
    setActiveStep,
    setCompleted,
    setIsDirty,
    setIsSignUpdate,
    handleUpdateForm,
    handleNext,
    handleBack,
    handleComplete,
    handleReset
  };

  return (
    <StepContext.Provider value={providerValue}>
      {children}
    </StepContext.Provider>
  );
};
