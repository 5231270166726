import { Select } from '../Select';
import { levelsOptions } from './levelsOptions';

export const transformLevelToOption = (level) => level && ({
  value: level,
  label: level
});

export const LevelsSelect = (props) => {
  return (
    <Select
      formattedValue
      isAsync={false}
      name="levels"
      label="Levels"
      promiseWithOptions={false}
      options={levelsOptions}

      {...props}
    />
  );
};
