import moment from 'moment';
import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  number: Yup.string().nullable().max(255),
  status: Yup.string().nullable().max(255),
  date: Yup.date().format('YYYY-MM-DD').nullable(),
  claim_filled_at: Yup.date().format('YYYY-MM-DD').max(moment(), 'Date must be earlier than today').nullable(),
  adj: Yup.array().of(
    Yup.object().shape({
      date: Yup.date().format('YYYY-MM-DD').nullable().required(),
      number: Yup.string().nullable().required().max(20)
    })
  ).notRequired(),
  doi: Yup.array().of(
    Yup.object().shape({
      from: Yup.date().format('YYYY-MM-DD').nullable().when('is_range', (isRange, schema) => {
        return !isRange ? schema : schema.required();
      }),
      to: Yup.date().format('YYYY-MM-DD').nullable().when('is_range', (isRange, schema) => {
        return !isRange ? schema : schema.required();
      }),
      date: Yup.date().format('YYYY-MM-DD').nullable().when('is_range', (isRange, schema) => {
        return isRange ? schema : schema.required();
      }),
      start_of_limitation: Yup.date().format('YYYY-MM-DD').nullable().required(),
      note: Yup.string().nullable().max(1000)
    })
  ).notRequired(),
  body_parts: Yup.array().of(
    Yup.object().shape({
      id: Yup.mixed().nullable().required(),
      note: Yup.string().nullable().max(1000)
    })
  ).notRequired()
});
