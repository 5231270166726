import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Button, Box, SvgIcon, Dialog } from '@material-ui/core';
import { mdiCertificateOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as certificatesApi from '../../../../../../../api/profile';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../../components/Modal';
import { Loader } from '../../../../../../../components/Loader';
import { CertificatesProvider } from '../../../../LeftSidebar/Signature/CertificatesContext';
import { Body } from './Body';
import { UserSignature } from './UserSignature';
import { validationSchema } from './validationSchema';

export const SignDocumentModal =  ({
  DialogProps,
  handleModalReject,
  handleModalResolve
}) => {
  const [ certificates, setCertificates ] = useState([]);
  const [ isFetched, setIsFetched ] = useState(false);
  const [ isAddCertificate, setIsAddCertificate ] = useState(false);
  const { signature } = useSelector(({ profile }) => profile.user);

  const signDocument = (values, { isSubmitting, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    handleModalResolve(values);
    setSubmitting(true);
  };

  const fetchCertificates = () => {
    setIsFetched(false);

    certificatesApi.fetchCertificates().then(({ data }) => {
      setIsFetched(true);
      setCertificates(data || []);
    });
  };

  useEffect(() => {
    fetchCertificates();
  }, [ isAddCertificate ]);

  return (
    <Formik
      enableReinitialize
      initialValues={!!certificates.length ? { certificate_id: null } : {}}
      validationSchema={!!certificates.length && validationSchema}
      onSubmit={signDocument}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          {...DialogProps}
        >
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiCertificateOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Sign document
            </ModalHeader>

            <ModalBody>
              <Box component="div" px={2} py={3}>
                <CertificatesProvider>
                  <Loader loading={!isFetched} render={
                    () => (!certificates.length || !signature ?
                      <UserSignature
                        disableCertificate={!certificates.length}
                        disableSignature={!signature}
                        updateCertificate={setIsAddCertificate}
                      />
                      :
                      <Body certificates={certificates} />
                    )}
                  />
                </CertificatesProvider>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button color="primary" onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    save & sign
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
