import { Formik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery } from '@material-ui/core';
import ListAddIcon from '@material-ui/icons/PlaylistAdd';
import * as taskTemplatesApi from '../../../../../api/cases/task-templates';
import { Loader } from '../../../../../components/Loader';
import { ModalContainer, ModalBody, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { caseUserRoles, caseUserSingleRoles, tasksPrioritiesOptions } from '../../../../../dataMaps';
import { TaskTemplateForm, initialValues, validationSchema, dueAtCaseDatesOptions } from '../TaskTemplateForm';

export const EditTaskTemplateModal = ({
  DialogProps,
  payload: {
    template
  },
  handleModalResolve,
  handleModalReject
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { enqueueSnackbar } = useSnackbar();
  const duration = moment.duration(template.estimated_time, 'seconds');
  const due_at_interval = moment.duration(template.due_at_interval, 'seconds').asDays();

  const addTask = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const estimated_time = moment.duration(`${values.hours || 0}:${values.minutes || 0}`).asSeconds();
    const due_at_interval = moment.duration(values.due_at_interval, 'days').asSeconds();

    return taskTemplatesApi.updateTaskTemplate({
      ...values,

      estimated_time: estimated_time || null,
      due_at_interval: due_at_interval || null
    }).then((template) => {
      enqueueSnackbar('Template successfully updated', { variant: 'success' });
      handleModalResolve(template);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Template not updated', { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...template,

        hours: duration.hours(),
        minutes: duration.minutes(),
        owner_case_user_role: caseUserSingleRoles.find(({ value }) => template.owner_case_user_role === value),
        users_case_user_roles: template.users_case_user_roles?.map((role) => {
          return caseUserRoles.find(({ value }) => role === value);
        }),
        due_at_case_date: dueAtCaseDatesOptions.find(({ value }) => template.due_at_case_date === value),
        priority: tasksPrioritiesOptions.find(({ value }) => template.priority === value),
        due_at_interval
      }}
      validationSchema={validationSchema}
      onSubmit={addTask}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<ListAddIcon />} onClose={handleModalReject}>
              Update Task Template
            </ModalHeader>

            <ModalBody>
              <TaskTemplateForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
