export const styles = ({ spacing, palette: { divider } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },

  header: {
    background: 'none',
    borderTop: `1px solid ${divider}`
  },

  header__title: {
    flexGrow: 1
  },

  tagsTabsHeader: {
    background: 'transparent'
  },

  tab: {
    minHeight: 0
  },

  search: {
    minHeight: 48,
    padding: spacing(0.5, 1.75, 0.5, 2)
  },

  search_hasValue: {
    paddingLeft: spacing()
  },

  folders: {
    paddingLeft: spacing(),
    paddingRight: spacing()
  }
});
