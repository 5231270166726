import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { merge } from 'lodash';
import * as formsApi from '../../../../../api/profile';
import { api } from '../../../../../api';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const DeletedMedicalFormsContext = createContext(null);

export const DeletedMedicalFormsProvider = ({ initialState: initialStateProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const {
    form,
    isFetched,
    filter,
    pagination,
    forms,
    selectedIDs
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchForms = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FORMS_REQUEST });

    formsApi.fetchDeletedMedicalForms({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_FORMS_SUCCESS, payload: data });
    }).catch(() => {
      dispatch({ type: types.FETCH_FORMS_ERROR });
      enqueueSnackbar('Forms are not found', { variant: 'error' });
    });
  };

  const allFormsIsSelected = () => {
    return forms.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_FORM_SELECTION, payload: id });
  };

  const toggleAllFormsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_FORMS_SELECTION });
  };

  const massDeleteForms = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        formsApi.massDeleteDeletedMedicalForms(IDs).then(() => {
          IDs.forEach((id) => toggleItemSelection(id));

          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            fetchForms({ page: filter.page });
          } else if (forms.length - selectedIDs.length === 0 && filter.page !== 1) {
            fetchForms({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.MASS_DELETE_FORMS, payload: IDs });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Forms are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const restoreDeletedMedicalForms = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        formsApi.restoreDeletedMedicalForms(IDs).then(() => {
          IDs.forEach((id) => toggleItemSelection(id));
          enqueueSnackbar('Successfully restored', { variant: 'success' });

          if (forms.length - selectedIDs.length === 0 && filter.page !== 1) {
            return fetchForms({ page: filter.page - 1 });
          }

          return fetchForms({ page: filter.page });
        }).catch((error) => {
          if (error) {
            enqueueSnackbar('Not restored', { variant: 'error' });
          }
        });
      }
    });
  };

  const resetForms = (newFilter) => {
    dispatch({ type: types.RESET_FORMS, payload: newFilter });

    fetchForms({ page: 1, ...newFilter });
  };

  const providerValue = {
    ...state,
    form,
    isDeleted: true,
    isFetched,
    forms,
    filter,
    pagination,
    selectedIDs,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    fetchForms,
    resetForms,
    massDeleteForms,
    allFormsIsSelected,
    toggleItemSelection,
    toggleAllFormsSelection,
    restoreDeletedMedicalForms
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <DeletedMedicalFormsContext.Provider value={providerValue}>
      {children}
    </DeletedMedicalFormsContext.Provider>
  );
};
