import { useContext } from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { stopPropagation } from '../../../../../../helpers/dom';
import { AppointmentViewModal } from '../../../../../../components/appointments';
import { CardHeader, CardItem } from '../../../../../../components/Cards';
import { ColorPreview } from '../../../../../../components/ColorPreview';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { Divider } from '../../../../../../components/Divider';
import { Contact } from '../../../../../../components/Contact';
import { Date } from '../../../../../../components/Date';
import { WaitingListContext } from '../../WaitingListProvider';
import { Menu } from '../../Menu';

export const Card = ({ item }) => {
  const { openModal } = useModal();
  const { updateWaitingListItem, deleteWaitingListItem, fetchWaitingList } = useContext(WaitingListContext);
  const time = item?.appointment_at
    ? moment.unix(item?.appointment_at).diff(moment(), 'hours')
    : 0;

  const waitingTime = time > 24 || time < -24
    ? moment.unix(item?.appointment_at).diff(moment(), 'days') + ' days'
    : time + ' hours';

  const openPreview = () => {
    openModal(AppointmentViewModal, {
      payload: {
        disableApptActions: true,
        onAppointmentUpdate: updateWaitingListItem,
        onWaitingListUpdate: fetchWaitingList,
        onAppointmentDelete: deleteWaitingListItem,
        appointmentID: item.id
      }
    });
  };

  return (
    <CardItem px={2} py={1} onClick={stopPropagation(openPreview)}>
      <CardHeader
        disableCheckBox
        alignItems="flex-start"
        menuComponent={Menu}
        menuComponentProps={{ appointment: item }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Date date={item?.appointment_at} format="L, LT" />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <TimelapseIcon />
              </Box>

              <Typography noWrap>
                {moment.duration(item?.time, 'seconds').format('h[h] m[min]')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardHeader>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Divider gutter={1} />
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" alignItems="center" height="100%" mb={1} mt={-1}>
            <Typography variant="h5">
              Patient
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center" height="100%" mb={1} mt={-1}>
            <UserLink
              noWrap
              size="md"
              variant="h5"
              user={item.patient}
            >
              <Box mt={-0.5} display="flex" alignItems="center" wrap="nowrap">
                <Typography color="textSecondary" variant="caption">
                  {item?.patient?.birthday
                    ? moment().diff(moment(item?.patient?.birthday), 'years') + ' y.o.'
                    : '0 y.o.'
                  }
                </Typography>
              </Box>
            </UserLink>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Typography variant="h5">
            Phone
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center">
            <Contact
              needIcon
              type="tel"
              contact={item?.patient?.phone}
            />
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Typography variant="h5">
            Appt. Book / Location
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center">
            <UserLink
              noWrap
              size="md"
              variant="h5"
              user={item.appointment_book?.provider}
            >
              <Typography noWrap color="textSecondary" variant="caption">
                {item?.appointment_book?.office?.name || 'No info'}
              </Typography>
            </UserLink>
          </Box>
        </Grid>


        <Grid item xs={5}>
          <Box display="flex" alignItems="center" height="100%">
            <Typography variant="h5">
              Appt. Type
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={7}>
          {!item?.appointment_type ? '-' : (
            <Box display="flex" alignItems="center">
              <ColorPreview color={item?.appointment_type?.color} />

              <Box component="span" ml={0.5}>
                <Typography noWrap variant="h5">
                  {item?.appointment_type?.code}
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" alignItems="center" height="100%">
            <Typography variant="h5">
              Notes
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Typography>
            {item?.note || 'No info'}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Box display="flex" alignItems="center" height="100%">
            <Typography variant="h5">
              Waiting
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Typography noWrap color={time < 0 ? 'error' : 'textPrimary'}>
            {waitingTime}
          </Typography>
        </Grid>
      </Grid>
    </CardItem>
  );
};
