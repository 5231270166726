import { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Sticky } from '../../../../../components/Sticky';
import { TypesContext } from '../TypesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 54;
export const columnsWidths = {
  name: 200,
  actions: 100
};

export const List = () => {
  const {
    isFetched,
    isFetching,
    types,
    filter: { per_page, last_page, page },
    loadNextPage
  } = useContext(TypesContext);
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={2}>
        <Loader
          loading={!isFetched}
          render={() =>
            !types.length ? (
              <Typography align="center">No types found</Typography>
            ) : (
              <>
                <Sticky containerElement={scrollElementRef.current}>
                  <TableHeader />
                </Sticky>

                <MuiList disablePadding>
                  <InfiniteListLoader
                    items={types}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    pagination={{ per_page, last_page, page }}
                    RowComponent={Row}
                    onNeedNextPage={loadNextPage}
                    scrollElementRef={scrollElementRef}
                    minRowHeight={minRowHeight}
                  />
                </MuiList>
              </>
            )
          }
        />
      </Box>
    </CustomScrollbars>
  );
};
