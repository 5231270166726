import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { stopPropagation } from '../../../../../../helpers/dom';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { UserLink } from '../../../../../../components/UserLink';
import { Divider } from '../../../../../../components/Divider';
import { Contact } from '../../../../../../components/Contact';

export const EmergencyFormContact = ({ contacts }) => {
  const { openModal } = useModal();

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return !!contacts?.length && contacts?.map((contact, index) => (
    <Grid key={index} container item xs={12} spacing={1} alignItems="center">
      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Emergency Contact</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <UserLink
          disableLink={!contact?.user_id}
          size="md"
          user={contact}
          variant="h5"
          onClick={stopPropagation(openUserPreview(contact?.user_id))}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Phone #</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Contact
          noWrap
          needIcon
          variant="h5"
          title={contact?.phone}
          type="tel"
          contact={contact?.phone}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">DOB</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        {!!contact.dob
          ? <Typography>{moment(contact.dob).format('L')}</Typography>
          : <Typography color="textSecondary">No info.</Typography>
        }
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Relationship</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="h5">
          {contact?.relationship || '-'}
        </Typography>
      </Grid>

      {contacts?.length > 1 &&
        <Grid item xs={12}>
          <Divider />
        </Grid>
      }
    </Grid>
  ));
};
