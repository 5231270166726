import { createContext, useState } from 'react';

export const viewVariantsMap = {
  list: 'list',
  grid: 'grid',
  groups: 'groups',
  columns: 'columns',
  calendar: 'calendar'
};

export const LayoutContext = createContext();

export const LayoutContextProvider = ({ children, initialViewVariant = viewVariantsMap.list }) => {
  const [ viewVariant, setViewVariant ] = useState(initialViewVariant);

  const contextValue = {
    viewVariant,
    setViewVariant
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};
