import { useContext } from 'react';
import moment from 'moment';
import { Box, SvgIcon, Typography } from '@material-ui/core';
import { mdiAttachment, mdiCommentMultipleOutline } from '@mdi/js';
import CalendarIcon from '@material-ui/icons/Event';
import { Icon } from '@mdi/react';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../helpers/dom';
import { hasRole } from '../../../../../../utils/hasRole';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { CardItem, CardHeader } from '../../../../../../components/Cards';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { UserLink } from '../../../../../../components/UserLink';
import { Divider } from '../../../../../../components/Divider';
import { PreviewMRIModal } from '../../PreviewMRIModal';
import { MRIsContext } from '../../MRIsProvider';
import { MRIsMenu } from '../../MRIsMenu';
import { Body } from './Body';

export const Card = ({ item: mri = {} }) => {
  const { openModal } = useModal();
  const { selectedIDs, toggleItemSelection, fetchMRIs, deleteMRI } = useContext(MRIsContext);
  const isSelected = selectedIDs.indexOf(mri.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(mri.id);
  };

  const openPreview = () => {
    openModal(PreviewMRIModal, {
      payload: {
        mri,
        onUpdate: fetchMRIs,
        onProcedureDelete: deleteMRI
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <CardItem
      selected={!hasRole(rolesMap.client, rolesMap.patient) && isSelected}
      py={1}
      px={2}
      onClick={stopPropagation(openPreview)}
    >
      <CardHeader
        isSelected={isSelected}
        disableCheckBox={hasRole(rolesMap.client, rolesMap.patient)}
        disableCardMenu={hasRole(rolesMap.client, rolesMap.patient)}
        onItemSelect={handleItemSelection}
        menuComponent={MRIsMenu}
        menuComponentProps={{ mri }}
      >
        <Box display="flex" alignItems="center" color="text.secondary">
          <Box mr={1}>
            <CalendarIcon fontSize="small" />
          </Box>

          <Typography noWrap variant="h5">
            {moment.unix(mri.date).format('L, LT')}
          </Typography>
        </Box>
      </CardHeader>

      <Body mri={mri} />

      <Box mx={-2}>
        <Divider disableBottomGutter gutter={2} />
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" pt={1.5}>
        <UserLink
          size="md"
          variant="h5"
          user={mri.physician}
          onClick={stopPropagation(openUserPreview(mri?.physician?.id))}
        >
          <Box mt={-0.5}>
            <Typography color="textSecondary" variant="caption">Primary care physician</Typography>
          </Box>
        </UserLink>

        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" fontSize={15}>
            <SvgIcon color="inherit"><Icon path={mdiAttachment} /></SvgIcon>

            <Box pl={0.5}>
              <Typography color="inherit">{mri?.media_count || 0}</Typography>
            </Box>
          </Box>

          <VerticalDivider verticalGutters={0.25} horizontalGutters={2} />

          <Box display="flex" alignItems="center" color="info.main" fontSize={15}>
            <SvgIcon color="inherit" fontSize="inherit"><Icon path={mdiCommentMultipleOutline} /></SvgIcon>

            <Box pl={0.5}>
              <Typography color="inherit">{mri?.count_comments || 0}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </CardItem>
  );
};
