import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { Button, Tooltip, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { preventDefault } from '../../../../../../../helpers/dom';
import { hasRole } from '../../../../../../../utils/hasRole';
import { PageHeader, PageHeaderActions } from '../../../../../../../components/Page';
import { LayoutContext, viewVariantsMap } from '../../../../../../../components/LayoutContext';
import { SelectedItemsCount } from '../../../../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';
import { expensesTypesMap } from '../../../../../../../components/billing';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../components/IconButton';
import { Fab } from '../../../../../../../components/Fab';
import { ExpensesContext } from '../../../Expenses';
import { ExpensesModal } from '../../../Expenses/ExpensesModal';

export const ActionsBar = ({ isTablet, caseID, disableExpenseCreation = false, filterOpenButton }) => {
  const {
    expenses,
    selectedExpenses,
    selectedExpensesIDs,
    toggleItemSelection,
    pagination,
    fetchExpenses,
    ...expensesContext
  } = useContext(ExpensesContext);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const addExpense = () => {
    openModal(ExpensesModal, {
      payload: {
        initialValues: {
          caseID,
          disableCaseField: !!caseID,
          title: 'Create Expense',
          parentPage: 'expense'
        }
      },
      onModalResolved: () => {
        fetchExpenses();
      }
    });
  };

  const massExpensesApprove = () => {
    const filteredIDs = selectedExpenses.reduce((result, expense) => {
      return expense?.status?.name === expensesTypesMap.submit ? result.concat(expense.id) : result;
    }, []);

    if (filteredIDs.length) {
      expensesContext.massExpensesApprove(filteredIDs);
    } else {
      enqueueSnackbar('Expense status must be "Submit"', { variant: 'warning' });
    }
  };

  const massExpensesDisapprove = () => {
    expensesContext.massExpensesDisapprove(selectedExpensesIDs);
  };

  const massDeleteExpenses = () => {
    expensesContext.massDeleteExpenses(selectedExpensesIDs);
  };

  return (
    <PageHeader>
      {!disableExpenseCreation &&
        <Fab
          color="primary"
          variant="extended"
          startIcon={<AddIcon />}
          onClick={addExpense}
        >
          Create expense
        </Fab>
      }

      <PageHeaderActions disableSpacing>
        {!!selectedExpensesIDs.length &&
          <>
            <SelectedItemsCount length={selectedExpensesIDs.length} total={pagination.total} />
            <VerticalDivider verticalGutters={1} horizontalGutters={1}/>

            {hasRole('admin') &&
              <>
                <Box clone mx={1}>
                  <Fab
                    color="primary"
                    variant="extended"
                    onClick={preventDefault(massExpensesApprove)}
                  >
                    Approve
                  </Fab>
                </Box>

                <Button
                  color="primary"
                  onClick={preventDefault(massExpensesDisapprove)}
                >
                  Disapprove
                </Button>
              </>
            }

            <VerticalDivider verticalGutters={1} horizontalGutters={2} />

            <Tooltip title="Print selected expense">
              <IconButton>
                <PrintIcon/>
              </IconButton>
            </Tooltip>

            <Tooltip title="Download selected expenses">
              <IconButton>
                <DownloadIcon/>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete selected expenses">
              <IconButton
                color="error"
                onClick={preventDefault(massDeleteExpenses)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <VerticalDivider verticalGutters={1} horizontalGutters={2} />
          </>
        }

        {isTablet && filterOpenButton}

        {!isTablet &&
          <>
            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon />
            </IconButton>

            <IconButton
              edge="end"
              color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.grid)}
            >
              <ViewModuleIcon />
            </IconButton>
          </>
        }
      </PageHeaderActions>
    </PageHeader>
  );
};
