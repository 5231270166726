import { useContext, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core';
import * as statisticsApi from '../../../../../../../api/billing';
import { api } from '../../../../../../../api';
import { Loader } from '../../../../../../../components/Loader';
import { initialState, StatisticsFilterContext } from '../../../StatisticsFilterProvider';
import { InvoicesContextProvider } from '../../../../Invoices/InvoicesContext';
import { PaymentsContextProvider } from '../../../../../../../components/billing/payments/PaymentsContext';
import { Invoices } from './Invoices';
import { Payments } from './Payments';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = ({
  isFetchedData,
  filterType,
  setFilterType,
  setIsFetchedData
}) => {
  const classes = useStyles();
  const [ state, setState ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const [ filter ] = useState(initialState.filter);
  const { filter: commonFilter } = useContext(StatisticsFilterContext);
  const labels = [ 'Billed Amount', 'Paid Amount' ];
  const cancelFetch = useRef(() => {});

  const initialInvoicesState = {
    filter: {
      filter_type: filterType,

      ...commonFilter
    }
  };

  const getChartData = () => ({
    labels,
    datasets: [
      {
        backgroundColor: [
          'rgba(0, 150, 136, 1)',
          'rgba(41, 73, 122, 1)'
        ],
        hoverBackgroundColor: [
          'rgba(0, 150, 136, 0.8)',
          'rgba(41, 73, 122, 0.8)'
        ],
        fill: false,
        data: state.transformedData.data.map((item) => item.total)
      }
    ]
  });

  const chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      yAxes: [ {
        ticks: {
          beginAtZero: true,
          callback: (label) => {
            label = new Intl.NumberFormat('en-US').format(label);

            return label;
          }
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0.2)',
          borderDash: [ 2, 3 ]
        }
      } ],
      xAxes: [ {
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(0, 0, 0, 0.2)',
          borderDash: [ 2, 3 ]
        },
        ticks: {
          beginAtZero: true,
          fontStyle: 'bold'
        }
      } ]
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem) => ' $ ' + new Intl.NumberFormat('en-US').format(tooltipItem.value)
      }
    }
  };

  const handleClickOnChart = (event) => {
    const label = !!event.length && event[0]._view.label;

    if (label) {
      setFilterType(label === labels[0] ? null : 'paid');
      setIsFetchedData(true);
    }
  };

  const fetchStatistics = (newFilter = {}) => {
    cancelFetch.current();
    setIsFetched(false);

    statisticsApi.fetchBillingAndPaymentStatistics({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    })
      .then((data) => {
        setState(data);
        setIsFetched(true);
      });
  };

  const resetStatistics = (newFilter) => {
    fetchStatistics({ ...newFilter });
  };

  useEffect(() => {
    if (!isEqual(filter, { ...filter, ...commonFilter })) {
      resetStatistics({ ...filter, ...commonFilter });
    }
  }, [ commonFilter ]);

  useEffect(() => {
    resetStatistics();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return !isFetchedData ? (
    <Loader loading={!isFetched} className={classes.loader} render={
      () => (
        <Bar
          data={getChartData()}
          options={chartOptions}
          getElementsAtEvent={handleClickOnChart}
        />
      )}
    />
  ) : filterType !== 'paid' ? (
    <InvoicesContextProvider initialState={initialInvoicesState}>
      <Invoices />
    </InvoicesContextProvider>
  ) : (
    <PaymentsContextProvider filter={initialInvoicesState.filter}>
      <Payments commonFilter={commonFilter} />
    </PaymentsContextProvider>
  );
};
