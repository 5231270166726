import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_COMMENTS_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_COMMENTS_SUCCESS]: (
    { filter: { parent_type, ...filter }, comments, ...state },
    { data: newComments, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { parent_type, ...filter, page, per_page },
      comments: page > 1 ? (
        comments.filter(({ id }) => !newComments.find((loadedComment) => id === loadedComment.id)).concat(newComments)
      ) : (
        newComments
      )
    };
  },

  [types.ADD_COMMENT]: (state, payload) => {
    const { pagination, filter: { parent_type, parent_id, ...filter }, comments, ...otherState } = state;

    if (filter.parent_type !== payload.parent_type && parent_id !== payload.parent_id) {
      return state;
    }

    const total = pagination.total + 1;
    const page = Math.ceil((comments.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, parent_type, parent_id, page },
      comments: [ payload, ...comments ]
    };
  },

  [types.UPDATE_COMMENT]: (
    { filter: { parent_type, ...filter }, comments, ...state },
    comment
  ) => {
    return {
      ...state,

      filter: { ...filter, parent_type },
      comments: comments.map((item) => {
        return item.id === comment.id ? comment : item;
      })
    };
  },

  [types.DELETE_COMMENT_SUCCESS]: ({ pagination, filter, comments, ...state }, commentId) => {
    return {
      ...state,

      comments: comments.filter((comment) => comment.id !== commentId)
    };
  }
});
