import { useContext, useEffect } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useModal } from '../../../../components/ModalsProvider';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { Page, PageBody, PageHeader } from '../../../../components/Page';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { ActionsBar } from '../ActionsBar';
import { CreateOfficeModal } from './CreateOfficeModal';
import { OfficesContext, OfficesProvider } from './OfficesContext';
import { FiltersBar } from './FiltersBar';
import { List } from './List';
import { Grid } from './Grid';

export const Offices = ({ filterKey }) => {
  const theme = useTheme();
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('md');

  const toggleViewVariant = (viewVariant) => {
    setViewVariant(viewVariant);
  };

  const openCreateModal = (addOffice) => () => {
    openModal(CreateOfficeModal, {
      onModalResolved: (data) => {
        addOffice(data);
      }
    });
  };

  useEffect(() => {
    if (isMobile) {
      toggleViewVariant(viewVariantsMap.grid);
    }
  }, [ isMobile ]);

  return (
    <Box display="flex" flexDirection="column" py={isMobile ? 0 : 2} px={isMobile ? 0 : 3} height="100%">
      <OfficesProvider>
        <Page>
          <OfficesContext.Consumer>
            {({ selectedIDs, pagination, addOffice, massDeleteItems }) => (
              <PageHeader>
                <ActionsBar
                  title={isMobile ? 'Add' : 'Add office'}
                  pagination={pagination}
                  selectedIDs={selectedIDs}
                  addNewContact={openCreateModal(addOffice)}
                  massDelete={massDeleteItems(selectedIDs)}
                  filterOpenButton={filterOpenButton}
                />
              </PageHeader>
            )}
          </OfficesContext.Consumer>


          <PageBody fullHeight disablePaddings disableScrollbars>
            <Box height="100%" display="flex" flexDirection="column">
              <ScrollWrapper {...scrollWrapperProps}>
                <FilterBarWrapper {...filterBarWrapperProps}>
                  <FiltersBar filterKey={filterKey} />
                </FilterBarWrapper>
              </ScrollWrapper>

              <Box flexGrow={1}>
                {(viewVariant === viewVariantsMap.list) && <List />}
                {(viewVariant === viewVariantsMap.grid) && <Grid />}
              </Box>
            </Box>
          </PageBody>
        </Page>
      </OfficesProvider>
    </Box>
  );
};
