import { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { stopPropagation } from '../../../helpers/dom';
import { CovidFormModal } from '../../medical/forms';
import { formTypesMap } from '../../medical/forms/formTypesMap';
import {
  getFormRoutes,
  MedicalFormsContext
} from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/MedicalFormsProvider';
import { UserPreviewModal } from '../../users';
import { useModal } from '../../ModalsProvider';
import { NestedList } from '../../NestedList';
import { UserLink } from '../../UserLink';
import { Fab } from '../../Fab';
import { Symptoms } from './Symptoms';

export const ComplaintSymptoms = ({ isOpenSwitches = false, report, fetchReports = () => {} }) => {
  const { openModal } = useModal();
  const history = useHistory();
  const [ isOpen, setIsOpen ] = useState(false);
  const { medicalInfoPathname } = useContext(MedicalFormsContext);
  const step = report?.medical_form?.form_type === formTypesMap.injury ? 1 : 4;

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  const handleOpenEditPage = () => {
    if (report?.medical_form?.form_type === formTypesMap.follow_up) {
      history.push(`${medicalInfoPathname}/medical-forms/${report?.medical_form?.id}/edit`);
    } else if (report?.medical_form?.form_type === formTypesMap.covid_form) {
      openModal(CovidFormModal, {
        payload: { initialValues: report?.medical_form },
        onModalResolved: fetchReports
      });
    } else {
      history.push(getFormRoutes({ id: report?.medical_form?.id, medicalInfoPathname })[step]);
    }
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Complaint/Symptoms"
      actionIcon={!!report &&
        <Fab
          size="small"
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={handleOpenEditPage}
        >
          Add
        </Fab>
      }
      onOpen={handleOpenListItem}
    >
      <Box p={3}>
        {!report ?
          <Typography align="center" color="textSecondary">No info found</Typography>
          :
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Typography color="textSecondary">Medical form type</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="h5">
                {report?.medical_form?.form_type || '-'}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography color="textSecondary">Treating physician</Typography>
            </Grid>

            <Grid item xs={7}>
              <UserLink
                variant="h5"
                user={report?.medical_form?.treating_physician}
                onClick={stopPropagation(openUserPreview(report?.medical_form?.treating_physician?.id))}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography color="textSecondary">Review of symptoms</Typography>
            </Grid>

            <Grid item xs={12}>
              <Symptoms
                type={report?.medical_form?.form_type}
                symptoms={report?.review_of_symptoms || {}}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom color="textSecondary">Description:</Typography>
              <Typography>{report?.description || 'No info'}</Typography>
            </Grid>
          </Grid>
        }
      </Box>
    </NestedList>
  );
};
