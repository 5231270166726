import { useContext } from 'react';
import {
  List,
  ListItemText,
  IconButton,
  MenuItem,
  ListItemIcon
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { stopPropagation } from '../../../../helpers/dom';
import { TagsContext } from '../../../../app/Dashboard/files-common';
import { useModal } from '../../../ModalsProvider';
import { CreateFolderModal } from '../../CreateFolderModal';
import { Popper } from '../../../Popper';

export const Menu = ({ folder, setExpanded }) => {
  const { openModal } = useModal();
  const tagsContext = useContext(TagsContext);

  const createFolder = () => {
    openModal(CreateFolderModal, {
      payload: {
        initialValues: {
          parent_id: folder.id
        }
      },
      onModalResolved: (newFolder) => {
        tagsContext.addTag(newFolder);

        const nodeId = folder.id?.toString();

        setExpanded((nodeIds) => {
          if (nodeIds.includes(nodeId)) {
            return nodeIds;
          } else {
            return nodeIds.concat(nodeId);
          }
        });
      }
    });
  };

  const handleFolderUpdate = () => {
    tagsContext.updateTag(folder);
  };

  const handleFolderDelete = () => {
    tagsContext.deleteTag(folder);
  };

  return (
    <Popper
      placement="right-start"
      anchorRenderer={
        ({ anchorRef, handleToggle }) => (
          <IconButton
            buttonRef={anchorRef}
            color="inherit"
            onClick={stopPropagation(handleToggle)}
          >
            <MoreVertIcon/>
          </IconButton>
        )
      }
    >
      {({ handleClose }) => (
        <List onClick={handleClose}>
          <MenuItem onClick={createFolder}>
            <ListItemIcon>
              <AddIcon fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Add subfolder" />
          </MenuItem>

          <MenuItem onClick={handleFolderUpdate}>
            <ListItemIcon>
              <EditIcon fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit" />
          </MenuItem>

          <MenuItem onClick={handleFolderDelete}>
            <ListItemIcon>
              <DeleteOutlinedIcon fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Delete" />
          </MenuItem>
        </List>
      )}
    </Popper>
  );
};
