import { useRef } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box/Box';
import { CallReports } from '../../../components/call-reports/CallReports';
import { CallReportsProvider } from '../../../components/call-reports/CallReportsProvider';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { useResizeObserver } from '../../../helpers/hooks';
import { filtersKeysMap } from '../../../store/lastFilters';
import { Page, PageBody } from '../../../components/Page';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { ActionsBar } from './ActionsBar';

export const CallReportsPage = () => {
  const rootRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { width } = useResizeObserver({ ref: rootRef });
  const isTablet = width <= theme.breakpoints.values.tabletLg;
  const { filterOpenButton, ...collapsProps } = useMobileFilterCollapse('tabletLg', width);

  return (
    <LayoutContextProvider>
      <CallReportsProvider>
        <Box
          ref={rootRef}
          bgcolor="grey.100"
          overflow="hidden"
          display="flex"
          flexDirection="column"
          flexGrow={1}
          height="100%"
          px={isMobile ? 1 : 3}
          py={isMobile ? 1 : 2}
        >
          <Page>
            <ActionsBar
              disableAdd
              isTablet={isTablet}
              filterOpenButton={filterOpenButton}
            />

            <PageBody disablePaddings disableScrollbars fullHeight>
              <CallReports isTablet={isTablet} filterKey={filtersKeysMap.call_reports} {...collapsProps} />
            </PageBody>
          </Page>
        </Box>
      </CallReportsProvider>
    </LayoutContextProvider>
  );
};
