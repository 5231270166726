export const styles = ({ spacing, palette: { grey, primary, ...palette }, typography: { pxToRem } }) => ({
  root: {
    display: 'flex'
  },

  rootWithoutTitle: {
    '& $iconWrap': {
      marginTop: spacing(2)
    }
  },

  rootWithoutLine: {
    '& $iconWrap': {
      '&:after': {
        content: 'none'
      }
    }
  },

  rootChecked: {
    '& $iconWrap': {
      '&:after': {
        borderColor: primary.main
      }
    },

    '& $icon': {
      backgroundColor: primary.main,
      color: palette.common.white
    }
  },

  iconWrap: {
    position: 'relative',
    display: 'flex',
    marginTop: spacing(-1),
    alignItems: 'center',
    flexDirection: 'column',

    '&:after': {
      content: '""',
      margin: spacing(1, 0, 2),
      borderLeft: `1px dashed ${palette.divider}`,
      flex: 1
    }
  },

  icon: {
    display: 'flex',
    width: 32,
    height: 32,
    backgroundColor: grey[200],
    color: grey[600],
    borderRadius: '50%',
    fontSize: pxToRem(14),
    alignItems: 'center',
    justifyContent: 'center'
  },

  main: {
    flex: 1,
    padding: spacing(0, 0, 2.5, 2.5)
  }
});
