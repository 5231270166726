import { api } from '../api';

export const fetchTaskTemplates = (config = {}) => {
  return api.get('/case-task-templates', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createTaskTemplate = (template) => {
  return api.post('/case-task-templates', template).then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const fetchTaskTemplate = (id) => {
  return api.get(`/case-task-templates/${id}`).then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateTaskTemplate = (template) => {
  return api.put(`/case-task-templates/${template.id}`, template).then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteTaskTemplate = (id) => {
  return api.delete(`/case-task-templates/${id}`).then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const applyTaskTemplate = (data) => {
  return api.post('/case-tasks-generator', data).then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};
