export const getPropertiesMap = (fieldsMap) => ({
  before: (properties) => {
    return Object.keys(properties?.before || {})?.reduce((messages, key) => {
      const before = properties?.before?.[key];
      const after = properties?.after?.[key];

      return fieldsMap?.[key] ? messages.concat(fieldsMap?.[key](before, after)) : messages;
    }, []);
  }
});
