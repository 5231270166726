import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../components/LayoutContext';
import { useQueryParams } from '../../helpers/hooks/useQueryParams';
import { setToken } from '../../store/auth/actions';
import { FilesFilterContextProvider } from '../Dashboard/files-common';
import { styles } from '../Dashboard/FilesPage/MyFilesPage/styles';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { SharedFilesProvider } from './SharedFilesProvider';
import { columnsMap } from '../Dashboard/files-common/FilesList/listConfig';
import { List } from './List';

const hiddenColumns = [
  columnsMap.owner,
  columnsMap.sharedUsers,
  columnsMap.creator
];

const useStyles = makeStyles(styles);

export const SharedFilesPage = () => {
  const dispatch = useDispatch();
  const { token } = useQueryParams();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setToken({ token }));
  }, [ token ]);

  return (
    <FilesFilterContextProvider>
      <LayoutContextProvider>
        <LayoutContext.Consumer>
          {({ viewVariant }) => (
            <div className={classes.root}>
              <ActionsBar />
              <FiltersBar />

              <div className={classes.filesWindow}>
                <div className={classes.filesWindow__main}>
                  {(viewVariant === viewVariantsMap.list) &&
                    <SharedFilesProvider>
                      <List hiddenColumns={hiddenColumns} />
                    </SharedFilesProvider>
                  }
                </div>
              </div>
            </div>
          )}
        </LayoutContext.Consumer>
      </LayoutContextProvider>
    </FilesFilterContextProvider>
  );
};
