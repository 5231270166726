import { useContext, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { saveFilter } from '../../../../../../store/lastFilters';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../../../helpers/dates';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { IconComponent } from '../../../../../../components/saved-filters';
import { UsersSelect } from '../../../../../../components/users';
import {
  InsurancesTypesSelect,
  KeyboardDatePicker,
  TextField
} from '../../../../../../components/FormField';
import { initialState } from '../../../../Members';
import { PatientsContext } from '../../../PatientsProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { initialValues } from './initialValues';
import { CardContent } from './CardContent';
import { List } from './List';

const MODAL_WIDTH = 1380;

export const FiltersBar = ({
  filterKey,
  hiddenFields = [],
  relationsForFilter,
  handleSetRelationsForFilter,
  onSavedFilterUpdate
}) => {
  const theme = useTheme();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [ fields, setFields ] = useState(initialState.filter);
  const { applyFilter } = useContext(PatientsContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
    handleSetRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleInsuranceTypeChange = (type) => {
    applyFilter({ insurance_type: type });
    handleSetRelationsForFilter((state) => ({ ...state, insurance_type: type }));
  };

  const handleUserSelect = (name) => (option) => {
    applyFilter({ [name]: option?.id });
    handleSetRelationsForFilter((state) => ({ ...state, [name]: option }));
  };

  const handleDatePickerChange = (name, transformer) => (date) => {
    const transformedDate = transformer?.(date) || date;

    applyFilter({ [name]: transformedDate });
    handleSetRelationsForFilter((state) => ({ ...state, [name]: transformedDate }));
  };

  const handleKeyboardPickerChange = (name) => (value) => {
    applyFilter({ [name]: value });
    handleSetRelationsForFilter((state) => ({ ...state, [name]: value }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(filter);

    onSavedFilterUpdate({
      search: filter?.search,
      med_rec: filter?.med_rec
    });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableTopPaddings
      formikRef={formikRef}
      border={0}
      initialValues={initialValues}
      filterKey={filterKey}
      onReset={() => {
        onSavedFilterUpdate({
          search: '',
          med_rec: null
        });
        applySavedFilter(initialValues);
      }}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      hiddenFields={hiddenFields}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.dob,
          label: filterFieldsLabels[filterFieldsMap.dob],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              disableFuture
              clearable
              name="dob"
              label="DOB"
              margin="dense"
              outputFormat={moment.HTML5_FMT.DATE}
              onChange={handleKeyboardPickerChange('dob')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.ssn,
          label: filterFieldsLabels[filterFieldsMap.ssn],
          field: <Box width={isMobile ? '100%' : 120}>
            <TextField
              zeroMinWidth
              name="ssn"
              label="SSN"
              placeholder="Enter last 4 chars..."
              margin="dense"
              onChange={handleFieldChange('ssn')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.appointmentFrom,
          label: filterFieldsLabels[filterFieldsMap.appointmentFrom],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              name="appointment_from"
              label="Appt. From"
              margin="dense"
              onChange={handleDatePickerChange('appointment_from', unixToStartOfDayUnix)}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.appointmentTo,
          label: filterFieldsLabels[filterFieldsMap.appointmentTo],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              name="appointment_to"
              label="Appt. To"
              margin="dense"
              onChange={handleDatePickerChange('appointment_to', unixToEndOfDayUnix)}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.providerId,
          label: filterFieldsLabels[filterFieldsMap.providerId],
          field: <UsersSelect
            name="provider_id"
            label="Provider"
            params={{ role: 'doctor' }}
            onChange={handleUserSelect('provider_id')}
            margin="dense"
            minWidth={210}
          />
        },
        {
          fieldKey: filterFieldsMap.insuranceType,
          label: filterFieldsLabels[filterFieldsMap.insuranceType],
          field: <Box minWidth={140}>
            <InsurancesTypesSelect
              name="insurance_type"
              label="Insurance Type"
              margin="dense"
              params={{
                groups: [ 'patient' ]
              }}
              onChange={handleInsuranceTypeChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.expirationDay,
          label: filterFieldsLabels[filterFieldsMap.expirationDay],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              zeroMinWidth
              name="expiration_day"
              label="Expiration day"
              outputFormat="YYYY-MM-DD"
              margin="dense"
              onChange={handleDatePickerChange('expiration_day')}
            />
          </Box>
        }
      ]}
    />
  );
};
