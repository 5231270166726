export const styles = ({ spacing }) => ({
  notes: {
    padding: spacing()
  },

  notesInfo: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  countNotes: {
    marginLeft: spacing()
  },

  loader: {
    marginTop: spacing(2)
  }
});
