import { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as sessionsApi from '../../../../../../../api/login-queues/login-sessions';
import { amFormatDuration } from '../../../../../../../helpers/dates';
import { enqueueSnackbar } from '../../../../../../../store/snackbars';
import { ConfirmationModal } from '../../../../../../ConfirmationModal';
import { Fab } from '../../../../../../Fab';
import { IconButton } from '../../../../../../IconButton';
import { ListRow, ListRowCell } from '../../../../../../ListRow';
import { Loader } from '../../../../../../Loader';
import { useModal } from '../../../../../../ModalsProvider';
import { UserLink } from '../../../../../../UserLink';
import { LoginSessionsContext } from '../../../../../sessions';
import { EditSessionModal } from '../../../../../sessions/EditSessionModal';
import { columnsWidths } from '../columnsWidths';
import { styles } from '../../styles';

const useStyles = makeStyles(styles);

export const SessionRow = ({ isLoaded, item: session, recalculateHeight }) => {
  const { openModal } = useModal();
  const classes = useStyles();
  const { updateLoginSession } = useContext(LoginSessionsContext);
  const [ isStopping, setIsStopping ] = useState(false);
  const [ duration, setDuration ] = useState();

  const editSession = () => {
    openModal(EditSessionModal, {
      payload: {
        session
      },

      onModalResolved: (session) => {
        updateLoginSession(session);
      }
    });
  };

  const closeSession = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setIsStopping(true);

        sessionsApi.stopSession(session.id).then((session) => {
          updateLoginSession(session);
          enqueueSnackbar('Session stopped', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Session not stopped', { variant: 'error' });
        }).finally(() => {
          setIsStopping(false);
        });
      }
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  useEffect(() => {
    const finishedAt = session?.finished_at;

    if (finishedAt) {
      return;
    }

    const initialDuration = moment().diff(moment.utc(session?.started_at).local(), 'seconds');

    const interval = setInterval(() => {
      setDuration((duration) => (duration || initialDuration) + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [ session?.started_at, session?.finished_at ]);

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow className={cn({ [classes.activeSession]: !session?.finished_at })}>
      <ListRowCell grow flexBasis={columnsWidths.user}>
        <UserLink noWrap size="sm" user={session.user} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.note}>
        {session.note}

        <IconButton size="small" onClick={editSession}>
          <EditIcon />
        </IconButton>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.startedAt}>
        {moment.utc(session.started_at).local().format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.finishedAt}>
        {session.finished_at ? (
          <Typography variant="subtitle1">{moment.utc(session.finished_at).local().format('L LT')}</Typography>
        ) : (
          <Loader surface loading={isStopping}>
            <Fab
              disabled={isStopping}
              color="error"
              size="small"
              variant="extended"
              onClick={closeSession}
            >
              {amFormatDuration(duration)} Close
            </Fab>
          </Loader>
        )}
      </ListRowCell>
    </ListRow>
  );
};
