import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Box, Button, Dialog, Grid, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarEdit } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as proceduresApi from '../../../../../api/profile';
import {
  ModifiersSelect,
  transformModifiersCodesToOption,
  CPTCodesSelect,
  IcdCodesSelect,
  PosCodesSelect,
  TextField,
  Autocomplete,
  CurrencyField
} from '../../../../../components/FormField';
import { LevelsSelect, transformLevelToOption } from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { validationSchema, sidesOptions } from '../../../../../components/appointments/procedures';

export const EditProceduresModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { id, onUpdate = () => {} }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ initialValues, setInitialValues ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const updateProcedure = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }
    values.time = ((values.time || 0) * 60).toFixed(0);

    return proceduresApi.updateMedicalReport(values).then((data) => {
      onUpdate();
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors = {}, message = '' }) => {
      if (errors) {
        setErrors(errors);
        message && enqueueSnackbar(message, { variant: 'error' });
      }
    });
  };

  const handleCptChange = (values, setFieldValue) => (cpt) => {
    if (cpt) {
      setFieldValue('cpt_description', cpt?.description);
    }
  };

  useEffect(() => {
    setIsFetched(false);

    proceduresApi.fetchMedicalReport(id).then((data) => {
      setInitialValues(data);
      setIsFetched(true);
    });
  }, []);

  return (
    <Dialog fullScreen={isTablet} PaperProps={{ style: { maxWidth: 1100 } }} {...DialogProps}>
      <Loader p={3} loading={!isFetched} render={
        () => (
          <Formik
            initialValues={{
              ...initialValues,

              id,
              cpt_id: initialValues?.cpt,
              cpt_description: initialValues?.cpt?.description,
              pos_id: initialValues?.pos,
              appointment_id: initialValues?.appointment?.id,
              modifiers: initialValues?.modifiers?.map(transformModifiersCodesToOption) || [],
              icd: initialValues?.icd || [],
              unit: initialValues.unit || 1,
              price: initialValues.price || 0,
              side: sidesOptions?.find(({ value }) => value === initialValues.side),
              levels: initialValues?.levels?.map(transformLevelToOption),
              surgery: initialValues.surgery || null
            }}
            validationSchema={validationSchema}
            onSubmit={updateProcedure}
          >
            {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
              <ModalContainer component="form" onSubmit={handleSubmit}>
                <ModalHeader
                  icon={<SvgIcon><Icon path={mdiCalendarEdit} /></SvgIcon>}
                  onClose={handleModalReject}
                >
                  Edit Surgeries/Procedures
                </ModalHeader>

                <ModalBody>
                  <Box flex={1}>
                    <Grid container spacing={2} alignItems="flex-end">
                      <Grid item sm={3} xs={12}>
                        <Autocomplete
                          disableClearable
                          name="side"
                          label="Side"
                          options={sidesOptions}
                          margin="dense"
                        />
                      </Grid>

                      <Grid item sm={9} xs={12}>
                        <LevelsSelect isMulti TextFieldProps={{ margin: 'dense' }} />
                      </Grid>

                      <Grid item sm={3} xs={12}>
                        <CPTCodesSelect
                          required
                          name="cpt_id"
                          label="Procedure Code #"
                          margin="dense"
                          onChange={handleCptChange(values, setFieldValue)}
                        />
                      </Grid>

                      <Grid item sm={9} xs={12}>
                        {values.cpt_id &&
                          <Box mt={1}>
                            <Typography variant="body2" color="textSecondary">
                              Procedure description
                            </Typography>
                          </Box>
                        }

                        <Typography style={{ textTransform: 'uppercase' }}>
                          {values.cpt_description}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          multiline
                          rows={2}
                          rowsMax={8}
                          disableUnderline
                          variant="filled"
                          name="surgery"
                          label="Surgery"
                          margin="dense"
                        />
                      </Grid>

                      <Grid item sm={3} xs={12}>
                        <PosCodesSelect
                          name="pos_id"
                          label="POS"
                          margin="dense"
                        />
                      </Grid>

                      <Grid item sm={9} xs={12}>
                        <IcdCodesSelect
                          multiple
                          name="icd"
                          label="DX Sequence/Diagnosis"
                          margin="dense"
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <ModifiersSelect
                          isMulti
                          formattedValue
                          name="modifiers"
                          label="Modifiers"
                          TextFieldProps={{ margin: 'dense' }}
                        />
                      </Grid>

                      <Grid item sm={3} xs={12}>
                        <TextField
                          type="number"
                          name="unit"
                          label="Unit"
                          margin="dense"
                        />
                      </Grid>

                      <Grid item sm={3} xs={12}>
                        <CurrencyField
                          name="price"
                          label="Price"
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
