import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Typography, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import * as formsApi from '../../../../api/profile';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { Loader } from '../../../Loader';
import {
  KeyboardDatePicker,
  OfficesLocationGuestSelect,
  OfficesLocationSelect
} from '../../../FormField';
import { List } from './List';

export const FormsCalendarInfo = ({ isBefore }) => {
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const { values } = useFormikContext();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [ times, setTimes ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const fetchMultipleFreeDates = isAuthenticated
    ? formsApi.fetchMultipleFreeDates
    : formsApi.fetchGuestMultipleFreeDates;
  const disabled = values?.confirmed_at && hasRole(rolesMap.patient) && isBefore;

  const handleChangeDate = (date) => {
    if (values?.offices?.length && date) {
      const offices = values?.offices?.map((office) => office?.id || office);

      setIsLoading(true);

      fetchMultipleFreeDates({
        params: {
          date: date,
          offices,
          token: values?.token
        }
      }).then(setTimes).catch((error) => {
        if (error) {
          setTimes([]);
          enqueueSnackbar('Times not found', { variant: 'warning' });
        }
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    setTimes([]);
  }, [ values.time ]);

  useEffect(() => {
    if (values.offices?.length) {
      handleChangeDate(values.appointment_date);
    }
  }, [ values.offices ]);

  return (
    <>
      <Box pl={isSmallScreen ? 1 : 1.5}>
        <Typography variant="h4" color="secondary">
          Request an appointment
        </Typography>
      </Box>

      <Grid
        container
        spacing={isSmallScreen ? 2 : 3}
        component={Box}
        alignItems="flex-start"
        justify="space-between"
        py={2}
        width="100% !important"
        m="0 !important"
      >
        <Grid item md={6} xs={12}>
          {isAuthenticated ? (
            <OfficesLocationSelect
              required
              multiple
              disabled={disabled}
              name="offices"
              label="Offices"
              params={{
                has_book: 1
              }}
            />
          ) : (
            <OfficesLocationGuestSelect
              required
              multiple
              name="offices"
              label="Offices"
              params={{
                token: values?.token || null,
                has_book: 1
              }}
            />
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <KeyboardDatePicker
            autoOk
            required
            disablePast
            disabled={disabled}
            label="Date"
            outputFormat="YYYY-MM-DD"
            placeholder="MM/DD/YYYY"
            name="appointment_date"
            onChange={handleChangeDate}
          />
        </Grid>

        {isLoading ? <Loader p={2} /> : (
          <List times={times} />
        )}
      </Grid>
    </>
  );
};
