import { Fragment, useState } from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, Box, useTheme, useMediaQuery, IconButton } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { renderRoutes, getActiveRoute, Route } from '../../../components/router';
import { Scrollbars } from '../../../components/Scrollbars';
import { Tabs, Tab } from '../../../components/Tabs';
import { styles } from '../PatientsPage/MedicalInfo/styles';
import { GeneralAndMedicalSwitcher } from './GeneralAndMedicalSwitcher';
import { LeftSidebar } from './LeftSidebar';
import { MedicalInfo } from './MedicalInfo';
import { EditUser } from './EditUser';
import { routes } from './routes';

const useStyles = makeStyles(styles);

export const MainProfilePage = () => {
  const classes = useStyles();
  const location = useLocation();
  const [ isUserInfoOpen, setIsUserInfoOpen ] = useState(false);
  const currentUser = useSelector(({ profile }) => profile.user);
  const medicalInfoPathname = '/profile/medical-info';
  const theme = useTheme();
  const userSidebarIsOpen = useSelector(({ layout: { userInfoIsOpen = true } }) => userInfoIsOpen);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    FilterBarWrapper,
    filterBarWrapperProps,
    toggleFiltersBar
  } = useMobileFilterCollapse();
  const ScrollWrapper = !isTablet ? Fragment : Scrollbars;
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%',
    width: '100%'
  };

  const toggleInfoOpen = () => {
    setIsUserInfoOpen((state) => !state);
  };

  if (location.pathname.includes(medicalInfoPathname)) {
    return <Route path={location.pathname} component={MedicalInfo} />;
  }

  if (location.pathname.includes('edit')) {
    return <Route path={location.pathname} component={EditUser} />;
  }

  return (
    <div className={classes.root}>
      {isTablet &&
        <div className={classes.navigation}>
          <Box mx={2.25} mt={1}>
            <IconButton size="medium" onClick={toggleFiltersBar}>
              <ListIcon />
            </IconButton>
          </Box>
        </div>
      }

      <ScrollWrapper {...scrollWrapperProps}>
        <FilterBarWrapper {...filterBarWrapperProps}>
          <Box
            display="flex"
            flexWrap="nowrap"
            flexDirection={isTablet ? 'column' : 'row'}
            alignItems={isTablet ? 'stretch' : 'center'}
            pl={1.25}
          >
            <Box display="flex" pr={2}>
              <GeneralAndMedicalSwitcher
                generalLink="/profile"
                medicalLink="/profile/medical-info/medical-forms"
              />
            </Box>

            <Box flexGrow={1} overflow="hidden">
              <Tabs
                value={getActiveRoute(routes)}
                orientation={isTablet ? 'vertical' : 'horizontal'}
                classes={{ flexContainer: classes.tabsWrapper }}
              >
                {routes.map(({ hide, path, icon, label }) => icon && !hide?.({ payload: { currentUser } }) && (
                  <Tab
                    key={path}
                    component={Link}
                    to={path}
                    value={path}
                    icon={icon}
                    label={label}
                    onClick={isTablet ? toggleFiltersBar : undefined}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
        </FilterBarWrapper>
      </ScrollWrapper>

      <div className={classes.content}>
        {userSidebarIsOpen && (
          <div className={cn(classes.leftSidebar, { [classes.leftSidebarFullHeight]: isUserInfoOpen })}>
            <LeftSidebar
              user={currentUser}
              openUserInfo={isUserInfoOpen}
              toggleInfoOpen={toggleInfoOpen}
            />
          </div>
        )}

        <div className={cn(classes.main, { [classes.mainFullHeight]: !isUserInfoOpen })}>
          {renderRoutes(routes, { currentUser })}
        </div>
      </div>
    </div>
  );
};
