import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { UserLink } from '../../../../../../../components/UserLink';
import { ShortInsuranceInfo } from '../../../../../../../components/users/ShortInsuranceInfo';
import { fieldsKeysMap } from '../filterKeysMap';

export const CardContent = ({ params, hiddenFields = [] }) => {
  return (
    <Grid container spacing={1}>
      {!hiddenFields.includes(fieldsKeysMap.formType) && params?.form_type &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Type of Medical Form
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {params?.form_type?.label || '-'}
            </Typography>
          </Grid>
        </>
      }

      {!hiddenFields.includes(fieldsKeysMap.search) && params?.search &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Patient Name:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {params?.search || '-'}
            </Typography>
          </Grid>
        </>
      }

      {params?.from &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              From:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {moment.unix(params.from).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.to &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              To:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {moment.unix(params.to).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.treating_physician_id?.id &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Provider:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Box display="flex" alignItems="center">
              <UserLink
                noWrap
                size="sm"
                user={params?.treating_physician_id}
              />
            </Box>
          </Grid>
        </>
      }

      {!hiddenFields.includes(fieldsKeysMap.patients) && params?.users?.length &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Patients:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Box display="flex" flexDirection="column">
              {!params?.users?.length ? '-' : params?.users?.map((user) => (
                <Box display="flex" alignItems="center" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    key={user.id}
                    user={user.data}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.insuranceId &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Insurance:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Box display="flex" alignItems="center">
              <ShortInsuranceInfo insurance={params?.insuranceId} />
            </Box>
          </Grid>
        </>
      }

      {params?.from_doi &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              From DOI:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {moment.unix(params.from_doi).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.to_doi &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              To DOI:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>
              {moment.unix(params.to_doi).format('L')}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
