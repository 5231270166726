import { Icon } from '@mdi/react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Button, makeStyles, SvgIcon } from '@material-ui/core';
import * as taskApi from '../../../../api/tasks';
import { UsersSelect } from '../../../../components/users';
import { setTasksLastGlobalAction } from '../../../../store/globalActions';
import { mdiAccountMultiplePlusOutline } from '@mdi/js';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../components/Modal';
import { Loader } from '../../../../components/Loader';
import * as taskActionTypes from '../../../../components/tasks/TasksProvider/types';

const styles = () => ({
  root: {
    width: 420
  }
});

const useStyles = makeStyles(styles);

const enhancer = compose(
  connect( null, { setTasksLastGlobalAction })
);

export const EditTaskUsers = enhancer(({
  task,
  onClose,
  onUpdate = () => {},
  setTasksLastGlobalAction
}) => {
  const classes = useStyles();

  const updateTask = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return taskApi.updateTask({ id: task.id, name: task.name, ...values })
      .then((data) => {
        setTasksLastGlobalAction({ type: taskActionTypes.UPDATE_TASK_IN_LIST, payload: data });
        onUpdate(data);
        onClose();
      })
      .catch(({ status, data: { errors } = {} }) => {
        if (status === 422) {
          setErrors(errors);
        }
      });
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          users: task?.users
        }}
        onSubmit={updateTask}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiAccountMultiplePlusOutline} /></SvgIcon>}
              onClose={onClose}
            >
              Members
            </ModalHeader>

            <ModalBody>
              <UsersSelect
                multiple
                name="users"
                label="Select by name user"
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Ok
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
});
