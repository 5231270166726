import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../../components/Loader';
import { FilePreviewContext } from '../../../FilePreviewContext';
import { PreviewContent } from '../PreviewContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Text = () => {
  const classes = useStyles('');
  const [ text, setText ] = useState();
  const { selectedFile } = useContext(FilePreviewContext);

  useEffect(() => {
    setText('');

    axios.get(selectedFile?.url).then(({ data }) => {
      setText(data);
    });
  }, [ selectedFile?.url ]);

  return (
    <PreviewContent>
      <Paper className={classes.root}>
        <Loader loading={!text} render={
          () => (
            text.split('\n').map((line, index) => {
              return <Typography key={index}>{line}</Typography>;
            })
          )}
        />
      </Paper>
    </PreviewContent>
  );
};
