import cn from 'classnames';
import { IconButton, makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { actionTypesMap } from '../../../../../../../components/chat';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const MessageSelection = ({ dense, currentAction, isSelected, handleSelectionClick }) => {
  const classes = useStyles();

  return (currentAction === actionTypesMap.select) &&
    (<div className={cn(classes.selection, { [classes.selectionSmall]: dense })}>
      <IconButton onClick={handleSelectionClick}>
        {isSelected ? (
          <CheckCircleOutlineIcon color="primary" />
        ) : (
          <RadioButtonUncheckedIcon color="primary" />
        )}
      </IconButton>
    </div>);
};
