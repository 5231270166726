import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import {
  makeStyles,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { mdiTrashCanOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { fetchProfile } from '../../../../../../../../store/dashboard/profile';
import * as emailsApi from '../../../../../../../../api/emails';
import { eventBus, eventBusEvents } from '../../../../../../../../utils/eventBus';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { SvgIcon } from '../../../../../../../../components/SvgIcon';
import { EmailsEditAccountModal } from './EmailsEditAccountModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmailAccountItem = ({ account, onDelete }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const openEditAccountModal = () => {
    openModal(EmailsEditAccountModal, {
      payload: {
        accountID: account.id
      },

      onModalResolved: () => {
        dispatch(fetchProfile());
      }
    });
  };

  const openDeleteAccountModal = () => {
    openModal(ConfirmationModal, {
      payload: {
        content: 'Are you sure, you want to delete your account?'
      },

      onModalResolved: () => {
        emailsApi.deleteEmailConfig(account.id).then(() => {
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
          onDelete(account.id);
          eventBus.dispatch(eventBusEvents.emailAccountDeleted, account);
        }).catch(() => {
          enqueueSnackbar('Account is not deleted', { variant: 'error' });
        });
      }
    });
  };

  return (
    <ListItem button className={classes.root}>
      <ListItemText
        primary={account.username}
        secondary={account.name}
        classes={{
          multiline: classes.itemText,
          primary: classes.itemPrimary,
          secondary: classes.itemSecondary
        }}
      />

      <ListItemSecondaryAction className={classes.itemActions}>
        <IconButton onClick={openEditAccountModal}>
          <EditOutlinedIcon color="primary" />
        </IconButton>

        <IconButton onClick={openDeleteAccountModal}>
          <SvgIcon color="error"><Icon path={mdiTrashCanOutline} /></SvgIcon>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
