import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  // Indicators
  emergency_indicator: Yup.string().nullable(),
  family_planning: Yup.string().nullable(),
  epsdt: Yup.string().nullable(),
  copay_exempt: Yup.string().nullable(),
  reference_code: Yup.string().nullable(),
  line_note: Yup.string().nullable().max(254),
  authorization_id: Yup.mixed().nullable(),

  // National Drug Code
  package_number: Yup.string().nullable().max(254),
  quantity: Yup.string().nullable().max(254),
  unit: Yup.string().nullable(),
  unit_price: Yup.string().nullable().max(254),

  // Lab
  hemoglobin: Yup.string().nullable().max(254),
  hematocrit: Yup.string().nullable().max(254),

  // Durable Medical Equipment
  length_medical_necessity: Yup.string().nullable().max(254),
  rental_price: Yup.string().nullable().max(254),
  purchase_price: Yup.string().nullable().max(254),
  rental_type: Yup.string().nullable(),
  dmerc_condition_indicator: Yup.string().nullable(),
  condition_indicators: Yup.array().of(Yup.string().required()).nullable(),
  certification_type_code: Yup.string().nullable(),
  dme_duration: Yup.string().nullable().max(254),
  certification_revision_date: Yup.string().nullable(),
  begin_therapy_date: Yup.string().nullable(),
  last_certification_date: Yup.string().nullable()
});
