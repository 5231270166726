import T from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ typography: { pxToRem } }) => ({
  root: {
    display: 'inline-flex',
    width: ({ size = 18 }) => pxToRem(size),
    height: ({ size = 18 }) => pxToRem(size),
    borderRadius: ({ borderRadius = '50%' }) => borderRadius,
    background: ({ color }) => color,
    flexShrink: 0
  }
}));

const propTypes = {
  color: T.string
};

export const ColorPreview = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root} title={props.color} />
  );
};

ColorPreview.propTypes = propTypes;
