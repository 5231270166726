import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as tasksApi from '../../../../api/tasks';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { Loader } from '../../../Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../Modal';
import { TasksContext, TasksFilterContextProvider, TasksProvider } from '../../TasksProvider';
import { FiltersBar } from '../FiltersBar';
import { List } from './List';

export const AddTasksModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { parent_id }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { enqueueSnackbar } = useSnackbar();
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const assignTasks = (tasks) => () => {
    setIsSubmitting(true);

    tasksApi.massAssignTasks({
      tasks,
      parent_id,
      parent_type: 'case'
    }).then(() => {
      enqueueSnackbar('Tasks successfully assigned', { variant: 'success' });
      handleModalResolve();
      setIsSubmitting(false);
    }).catch(({ status }) => {
      if (status === 422) {
        enqueueSnackbar('Tasks not assigned', { variant: 'error' });
        setIsSubmitting(false);
      }
    });
  };

  const initialState = {
    filter: {
      parent_type: ''
    }
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <TasksFilterContextProvider>
        <TasksProvider initialState={initialState}>
          <TasksContext.Consumer>
            {({ selectedTasksIDs }) => (
              <ModalContainer>
                <ModalHeader onClose={handleModalReject}>
                  Select task to assign
                </ModalHeader>

                <ModalBody disablePaddings>
                  <FiltersBar filterKey={filtersKeysMap.tasks_assign_modal} />

                  <List onClose={handleModalReject} />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>Cancel</Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        disabled={!selectedTasksIDs.length}
                        variant="contained"
                        color="primary"
                        onClick={assignTasks(selectedTasksIDs)}
                      >
                        Assign
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </TasksContext.Consumer>
        </TasksProvider>
      </TasksFilterContextProvider>
    </Dialog>
  );
};
