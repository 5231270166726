import { createContext, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { rolesMap as caseRolesMap } from '../../../../../../dataMaps';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { updateUserStorage } from '../../../../../../store/globalUser/operations';
import * as casesApi from '../../../../../../api/cases';
import * as casesTypes from '../../CasesContext/types';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CaseItemContext = createContext(null);

export const CaseItemProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { caseItem, isFetched } = state;
  const params = useParams();
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const storageInfo = useSelector(({ globalUser }) => globalUser?.data);
  const currentUser = useSelector(({ profile }) => profile.user);
  const [ isCaseInfoOpen, setIsCaseInfoOpen ] = useState(false);
  const casesLastGlobalAction = useSelector(({ globalActions }) => globalActions.casesLastGlobalAction);
  const disableTimeTracks = currentUser?.role === rolesMap.patient
    || currentUser?.role === rolesMap.medic
    || currentUser?.role === rolesMap.doctor;

  const fetchCase = () => {
    dispatch({ type: types.FETCH_CASE_REQUEST });

    casesApi.fetchCase(params.caseID).then((data) => {
      const applicant = data.case_users?.find(({ case_role }) => case_role === caseRolesMap.applicant);

      data.ssn = data.ssn || applicant?.user?.ssn;

      data.dob = data.dob
        ? moment.unix(data.dob).format('L')
        : applicant?.user?.birthday
          ? moment(applicant?.user?.birthday).format('L')
          : null;

      dispatch({ type: types.FETCH_CASE_SUCCESS, payload: data });
    }).catch(({ status }) => {
      if (status === 404) {
        if (params.caseID) {
          enqueueSnackbar('Case not found', { variant: 'error' });

          dispatchRedux(updateUserStorage({
            cases: storageInfo.cases?.filter(({ id }) => id !== +params.caseID)
          }));
        }

        history.push('/cases');
      }
    });
  };

  const updateCase = (caseItem) => {
    dispatch({ type: types.UPDATE_CASE, payload: caseItem });
  };

  const toggleCaseInfoOpen = () => {
    setIsCaseInfoOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    if (casesLastGlobalAction?.type === casesTypes.UPDATE_CASE_IN_LIST) {
      updateCase(casesLastGlobalAction?.payload);
    }
  }, [ casesLastGlobalAction ]);

  useEffect(() => {
    fetchCase(params.caseID);
  }, [ params.caseID ]);

  const providerValue = {
    isFetched,
    isCaseInfoOpen,
    disableTimeTracks,
    caseItem,
    setIsCaseInfoOpen,
    fetchCase,
    updateCase,
    toggleCaseInfoOpen
  };

  return (
    <CaseItemContext.Provider value={providerValue}>
      {children}
    </CaseItemContext.Provider>
  );
};
