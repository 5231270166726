import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ListRow header className={classes.root}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        User
      </ListRowCell>

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.role}>
          Role
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.mobile_phone_number}>
        {isMobile ? 'Mobile phone / Email' : 'Mobile phone'}
      </ListRowCell>

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.action}/>
    </ListRow>
  );
};
