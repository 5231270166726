import { useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListRow header>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        Name
      </ListRowCell>

      <ListRowCell grow={isMobile} flexBasis={columnsWidths.provider}>
        {isMobile ? 'Provider / Office' : 'Provider'}
      </ListRowCell>

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.office}>
          Office
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.actions} />
    </ListRow>
  );
};
