import { createContext, useRef, useEffect, useReducer, useContext } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSelector } from 'react-redux';
import { api } from '../../../api';
import * as appointmentsApi from '../../../api/appointments';
import { usePrevious } from '../../../helpers/hooks';
import { AppointmentsFilterContext } from '../AppointmentsFilterProvider';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const AppointmentsContext = createContext();

export const AppointmentsProvider = ({
  children,
  makeFetchOnMount = false,
  filter: filterProp,
  initialFilter
}) => {
  const appointmentsLastGlobalAction = useSelector(({ globalActions }) => globalActions.appointmentsLastGlobalAction);
  const { filter: commonFilter } = useContext(AppointmentsFilterContext);
  const [ state, dispatch ] = useReducer(reducer, {
    ...initialState,
    filter: merge({}, initialState.filter, commonFilter, initialFilter, filterProp)
  });
  const { filter, pagination } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchAppointments = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_APPOINTMENTS_REQUEST });

    appointmentsApi.fetchAppointments({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_APPOINTMENTS_SUCCESS, payload: data });

      return data;
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchAppointments({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const reloadAppointments = () => {
    dispatch({ type: types.RESET_APPOINTMENTS, payload: { page: 1 } });

    fetchAppointments({ page: 1 });
  };

  const resetAppointments = (filter) => {
    dispatch({ type: types.RESET_APPOINTMENTS, payload: filter });

    fetchAppointments({ ...filter, page: 1 });
  };

  const addAppointment = (appointment) => {
    dispatch({ type: types.ADD_APPOINTMENT, payload: appointment });
  };

  const updateAppointment = (appointment) => {
    dispatch({ type: types.UPDATE_APPOINTMENT, payload: appointment });
  };

  const deleteAppointments = (IDs) => {
    dispatch({ type: types.DELETE_APPOINTMENTS, payload: IDs });
  };

  const deleteAppointment = (id) => {
    dispatch({ type: types.DELETE_APPOINTMENT, payload: id });
  };

  const providerValue = {
    ...state,

    meta: {
      ...pagination,
      ...filter
    },

    // functions
    applyFilter,
    reloadAppointments,
    resetAppointments,
    loadNextPage,
    addAppointment,
    updateAppointment,
    deleteAppointment,
    deleteAppointments
  };

  useEffect(() => {
    const newFilter = { ...filter, ...commonFilter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, commonFilter ]);

  useEffect(() => {
    const newFilter = omit({ ...filter, ...filterProp }, [ 'page' ]);

    if (!isEqual(omit(filter, [ 'page' ]), newFilter)) {
      applyFilter(newFilter);
    }
  }, [ filter, filterProp ]);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetAppointments(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    if (appointmentsLastGlobalAction?.type === types.FETCH_APPOINTMENTS_REQUEST) {
      fetchAppointments();
    }
  }, [ appointmentsLastGlobalAction ]);

  useEffect(() => {
    if (makeFetchOnMount) {
      fetchAppointments();
    }

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <AppointmentsContext.Provider value={providerValue}>
      {children}
    </AppointmentsContext.Provider>
  );
};
