import { useEffect } from 'react';
import moment from 'moment';
import { makeStyles, Box, Grid, Typography, Link } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import { usePrevious } from '../../../../../helpers/hooks';
import { getUserFullName } from '../../../../../helpers/users';
import { stopPropagation } from '../../../../../helpers/dom';
import { Label } from '../../../../Label';
import { Loader } from '../../../../Loader';
import { useModal } from '../../../../ModalsProvider';
import { SvgIcon } from '../../../../SvgIcon';
import { UserPreviewModal } from '../../../../users/UserPreviewModal';
import { arrivalTypes } from '../../../arrivalTypes';
import { AppointmentViewModal, Menu } from '../../../index';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  item: appointment = {},
  isLoaded,
  recalculateHeight,
  hidePatient,
  hideProvider,
  onAppointmentUpdate = () => {},
  onAppointmentDelete = () => {}
}) => {
  const classes = useStyles({ isLoaded, appointment });
  const { openModal } = useModal();
  const prevIsLoaded = usePrevious(isLoaded);

  const handleAppointmentClick = () => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: appointment.id,
        onUpdate: onAppointmentUpdate,
        onDelete: onAppointmentDelete
      }
    });
  };

  const openPatientView = () => {
    openModal(UserPreviewModal, {
      payload: {
        id: appointment.patient_id
      }
    });
  };

  const openProviderView = () => {
    openModal(UserPreviewModal, {
      payload: {
        id: appointment?.appointment_book?.provider_id
      }
    });
  };

  useEffect(() => {
    if (!prevIsLoaded && isLoaded) {
      recalculateHeight();
    }
  }, [ isLoaded, prevIsLoaded ]);

  return !isLoaded ? (
    <Box display="flex" alignItems="center" height={80} p={2}>
      <Loader />
    </Box>
  ) : (
    <div className={classes.root} onClick={handleAppointmentClick}>
      <Grid container spacing={2} wrap="nowrap">
        <Box clone flexShrink={0} mt={0.25}>
          <Grid item>
            <Typography noWrap component="div" variant="subtitle2">
              {moment.unix(appointment.appointment_at).format('L')}
            </Typography>

            <Grid container wrap="nowrap" alignItems="center" spacing={1}>
              <Grid item>
                <Typography noWrap component="div" variant="subtitle2" color="textPrimary">
                  {moment.unix(appointment.appointment_at).format('LT')}
                </Typography>

                <Typography noWrap component="div" variant="subtitle2" color="textPrimary">
                  {!!appointment.time && moment.unix(
                    appointment.appointment_at + moment.duration(appointment.time, 's').asSeconds()
                  ).format('LT')}
                </Typography>
              </Grid>

              {!!appointment.group_id &&
                <Grid item>
                  <LoopOutlinedIcon fontSize="small" titleAccess="This appt is recurring" />
                </Grid>
              }
            </Grid>
          </Grid>
        </Box>

        <Box clone flexGrow={1} overflow="hidden">
          <Grid item>
            <Grid container wrap="nowrap" alignItems="center" spacing={1}>
              <Grid item xs zeroMinWidth>
                <div>
                  {!hidePatient && (!appointment.patient ? (
                    <Typography noWrap variant="h5">Appointment without patient</Typography>
                  ) : (
                    <Link noWrap variant="h5" color="textPrimary" onClick={stopPropagation(openPatientView)}>
                      {getUserFullName(appointment.patient)}
                    </Link>
                  ))}
                </div>

                <div>
                  {!hideProvider && !!appointment.appointment_book?.provider &&
                    <Link noWrap variant="h5" color="textPrimary" onClick={stopPropagation(openProviderView)}>
                      Dr.: {getUserFullName(appointment.appointment_book?.provider)}
                    </Link>
                  }
                </div>
              </Grid>

              <Grid item>
                <Menu
                  appointment={appointment}
                  ButtonProps={{ size: 'small' }}
                  IconProps={{ fontSize: 'small' }}
                  onAppointmentUpdate={onAppointmentUpdate}
                  onAppointmentDelete={onAppointmentDelete}
                />
              </Grid>
            </Grid>

            <Grid container wrap="nowrap" spacing={1}>
              {!!appointment.appointment_confirmed_by_office &&
                <Grid item>
                  <Box bgcolor="success.contrastText" borderRadius="50%" title="Appt is confirmed">
                    <SvgIcon color="success">
                      <CheckCircleIcon />
                    </SvgIcon>
                  </Box>
                </Grid>
              }

              {!!appointment?.arrival_status &&
                <Grid item zeroMinWidth>
                  <Label
                    color={arrivalTypes[appointment?.arrival_status?.arrival_type]?.color}
                    title={appointment?.arrival_status?.description}
                    label={`${appointment?.arrival_status?.code} - ${appointment?.arrival_status?.description}`}
                  />
                </Grid>
              }

              {!!appointment?.appointment_type &&
                <Grid item xs={6} zeroMinWidth>
                  <Typography noWrap variant="body1" title={appointment?.appointment_type?.description}>
                    {appointment?.appointment_type?.code}
                  </Typography>
                </Grid>
              }
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};
