export const socialHistoryTypes = {
  everyday: 'everyday',
  some_days: 'some_days',
  former_smoker: 'former_smoker',
  never_smoke: 'never_smoke',
  unsure_how_often: 'unsure_how_often',
  unknown: 'unknown',
  less: 'less',
  yes: 'yes',
  Yes: 'Yes'
};
