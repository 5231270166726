export const styles = ({ spacing, palette, breakpoints, transitions }) => ({
  loader: {
    padding: spacing(3)
  },

  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.grey[100],
    height: '100%',
    width: '100%',
    minHeight: '100%'
  },

  content: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: spacing(1, 1, 0, 1),
    flexGrow: 1,

    [breakpoints.up('md')]: {
      overflow: 'hidden',
      height: '100%'
    },

    [breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },

  leftSidebar: {
    transition: transitions.create([ 'margin', 'height', 'maxHeight' ], {
      duration: transitions.duration.shortest
    }),

    [breakpoints.up('lg')]: {
      height: '100%',
      marginRight: spacing(0.5),
      width: 410,
      flexShrink: 0
    },

    [breakpoints.down('md')]: {
      marginRight: spacing(0.5),
      width: '100%'
    }
  },

  leftSidebarFullHeight: {
    height: '100%'
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: 0,

    [breakpoints.up('md')]: {
      height: '100%'
    },

    [breakpoints.down('md')]: {
      marginRight: spacing(0.5)
    }
  },

  mainFullHeight: {
    [breakpoints.down('md')]: {
      height: '100%'
    }
  },

  navigation: {
    backgroundColor: palette.primary.contrastText
  },

  tabsWrapper: {
    [breakpoints.down('md')]: {
      alignItems: 'flex-start',
      width: '100%'
    }
  }
});
