import {
  Fab,
  Typography,
  List,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { Popper } from '../../../../components/Popper';
import { useModal } from '../../../../components/ModalsProvider';
import {
  Attachments,
  SelectAvailableFilesModal,
  FileUploadModal,
  FilesContextProvider,
  FilesFilterContextProvider
} from '../../files-common';

export const AttachmentsContainer = ({
  disableFetching,
  disableVirtual,
  files = [],
  filter,
  ownerType,
  ownerID
}) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const initialState = {
    files,
    filter,
    isFetched: true
  };

  const openAvailableFilesModal = () => {
    openModal(SelectAvailableFilesModal, {
      payload: {
        owner_type: ownerType,
        owner_id: ownerID
      }
    });
  };

  const uploadFiles = () => {
    openModal(FileUploadModal, {
      payload: {
        owner_type: ownerType,
        owner_id: ownerID
      }
    });
  };

  return (
    <FilesFilterContextProvider>
      <FilesContextProvider initialState={initialState}>
        <Box display="flex" flexDirection="column" height="100%">
          <Grid container spacing={1} alignItems="center" component={Box} pb={isMobile ? 0 : 1}>
            <Grid item>
              <Typography variant="h3">Attachments</Typography>
            </Grid>

            <Grid item>
              <Popper
                anchorRenderer={({ anchorRef, handleToggle }) => (
                  <Fab
                    buttonRef={anchorRef}
                    color="primary"
                    onClick={handleToggle}
                  >
                    <AddIcon fontSize="small" />
                  </Fab>
                )}
              >
                <List>
                  <MenuItem onClick={uploadFiles}>
                    <ListItemIcon>
                      <CloudUploadIcon fontSize="inherit" />
                    </ListItemIcon>

                    <ListItemText primary="Upload new file" />
                  </MenuItem>

                  <MenuItem onClick={openAvailableFilesModal}>
                    <ListItemIcon>
                      <FileCopyIcon fontSize="inherit" />
                    </ListItemIcon>

                    <ListItemText primary="Select from available" />
                  </MenuItem>
                </List>
              </Popper>
            </Grid>
          </Grid>

          <Box display="flex" flexGrow={1} height={400}>
            <Attachments disableFetching={disableFetching} disableVirtual={disableVirtual} />
          </Box>
        </Box>
      </FilesContextProvider>
    </FilesFilterContextProvider>
  );
};
