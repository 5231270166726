import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import { useModal as useModalHook } from '../../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../../components/UserLink';
import { UserPreviewModal } from '../../../../../../../../components/users';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths, minRowHeight } from '../MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item: track, isLoaded, setFilterType, setIsFetchedData }) => {
  const classes = useStyles();
  const { openModal } = useModalHook();

  const onSetFilterType = (is_billed) => () => {
    setIsFetchedData(true);
    setFilterType({
      user_id: track.user.id,
      is_billed
    });
  };

  const openUserPreview = () => {
    if (track?.user?.id) {
      openModal(UserPreviewModal, {
        payload: { id: track?.user?.id }
      });
    }
  };

  return isLoaded && (
    <ListRow minHeight={minRowHeight} className={classes.root}>
      <ListRowCell grow flexBasis={columnsWidths.client}>
        <UserLink
          noWrap
          size="sm"
          variant="h5"
          user={track.user}
          onClick={stopPropagation(openUserPreview)}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.billable} onClick={onSetFilterType(1)}>
        <Typography className={classes.time}>
          {moment.unix(track.billable_time).format('HH:mm')}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.unbillable} onClick={onSetFilterType(0)}>
        <Typography className={classes.time}>
          {moment.unix(track.unbillable_time).format('HH:mm')}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total} onClick={onSetFilterType(null)}>
        <Typography className={classes.time}>
          {moment.unix(track.total).format('HH:mm')}
        </Typography>
      </ListRowCell>
    </ListRow>
  );
};
