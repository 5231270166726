import { useContext, useRef, useState } from 'react';
import { Box, Fab, Tooltip, useTheme } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/SettingsBackupRestore';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import AddIcon from '@material-ui/icons/Add';
import { mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Badge } from '../../../../../../../components/Badge';
import { useResizeObserver } from '../../../../../../../helpers/hooks';
import { SvgIcon } from '../../../../../../../components/SvgIcon';
import { CallReportsContext } from '../../../../../../../components/call-reports/CallReportsProvider';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { CallReports } from '../../../../../../../components/call-reports/CallReports';
import { FiltersBarModal } from '../../../../../../../components/FiltersBarModal';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { IconButton } from '../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { useMobileFilterCollapse } from '../../../../../../../utils/useMobileFilterCollapse';
import { CallReportsModal } from '../../../../../CallReportsPage/CallReportsModal';
import { getFilterFields, getFiltersValues } from './getFilterFields';
import { initialCallReportsFilters } from './filters';
import { ActionsBar } from './ActionsBar';

export const CallReportsWidget = ({
  isTabPage = false,
  payload,
  filterKey,
  page,
  height = null,
  disableButtons = false,
  isDisabledOpen = false,
  hiddenFields = [],
  widgetFilters = {},
  updateFilters = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  onCaseItemUpdate = () => {},
  ...props
}) => {
  const theme = useTheme();
  const { openModal } = useModal();
  const rootRef = useRef(null);
  const [ filters, setFilters ] = useState({
    ...initialCallReportsFilters,
    ...widgetFilters?.callReports
  });
  const { resetCallReports, applyFilter } = useContext(CallReportsContext);
  const { width } = useResizeObserver({ ref: rootRef });
  const isTablet = width <= theme.breakpoints.values.tablet;
  const { filterOpenButton, ...collapsProps } = useMobileFilterCollapse('tablet', width);

  const onFiltersUpdate = (values) => {
    setFilters(values);
    updateFilters({ callReports: values });
  };

  const openAttachModal = () => {
    openModal(CallReportsModal, {
      payload,
      onModalResolved: resetCallReports
    });
  };

  const handleFiltersChange = () => {
    openModal(FiltersBarModal, {
      payload: {
        title: 'Call Reports Filters',
        initialValues: filters,
        fields: getFilterFields({ applyFilter }),
        setFilters: onFiltersUpdate
      },
      onModalResolved: ({ title, ...values }) => {
        const filterValues = getFiltersValues(values);

        applyFilter(filterValues);
      }
    });
  };

  const handleResetFilter = () => {
    applyFilter(initialCallReportsFilters);
    onFiltersUpdate(initialCallReportsFilters);
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      disableScrollbars
      disableDivider={isTabPage}
      elevation={isTabPage ? 3 : 0}
      title="Call Reports"
      icon={!disableButtons
        ? (
          <Tooltip title="Add Track">
            <Fab
              color="primary"
              onClick={openAttachModal}
            >
              <AddIcon fontSize="small" />
            </Fab>
          </Tooltip>
        ) : false
      }
      rightActions={[
        !isTabPage && (
          <WidgetsActionButtons
            isMinimizable
            page={page}
            type={props?.item?.i}
            isDisabledOpen={isDisabledOpen}
            onMinimize={onMinimize}
            onMaximized={onMaximized}
            onClose={onClose}
            onOpen={onOpen}
            onPutItem={onPutItem}
          />
        )
      ]}
      endIcon={(
        <Box display="flex" alignItems="center">
          <ActionsBar
            isTablet={isTablet}
            isTabPage={isTabPage}
            payload={payload}
            filterOpenButton={filterOpenButton}
          />

          {!isTabPage && (
            <>
              <VerticalDivider horizontalGutters={1} />

              <Tooltip title="Set Filter">
                <IconButton
                  color="primary"
                  onClick={handleFiltersChange}
                >
                  <Badge
                    invisible={isEmptyDeep(filters)}
                    badgeContent={
                      <Tooltip title="Filter is used">
                        <SvgIcon fontSize="small" color="success">
                          <CheckCircleIcon/>
                        </SvgIcon>
                      </Tooltip>
                    }
                  >
                    <SvgIcon fontSize="small">
                      <Icon path={mdiFilter}/>
                    </SvgIcon>
                  </Badge>
                </IconButton>
              </Tooltip>

              <Tooltip title="Reset Filter">
                <IconButton
                  color="primary"
                  onClick={handleResetFilter}
                >
                  <BackupIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      )}
      leftActions={[
        <PhoneOutlinedIcon color="inherit" fontSize="small" />
      ]}
      {...props}
    >
      <Box
        ref={rootRef}
        display="flex"
        flexDirection="column"
        height={height || '100%'}
        overflow="hidden"
      >
        <CallReports
          payload={payload}
          isTablet={isTablet}
          disableFiltersBar={!isTabPage}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          {...collapsProps}
        />
      </Box>
    </ContentCard>
  );
};

