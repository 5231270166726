export const styles = ({ spacing, palette, breakpoints }) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: spacing(2),
    borderBottom: `1px solid ${palette.divider}`,

    [breakpoints.down('md')]: {
      padding: 0
    }
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    marginRight: spacing(),
    color: palette.text.primary
  }
});
