import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import { CurrencyFormat } from '../../../../../components/CurrencyFormat';
import { CurrencyField, TextField } from '../../../../../components/FormField';

export const Body = () => {
  const { values } = useFormikContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <TextField
          multiline
          rows={6}
          name="notes"
          variant="filled"
          placeholder="Add notes..."
        />
      </Grid>

      <Grid item>
        <Box minWidth={320}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Typography variant="subtitle1" color="textSecondary">Total</Typography>
            </Grid>

            <Grid item xs={7}>
              <CurrencyField name="total" />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Typography variant="subtitle1" color="textSecondary">Payment</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="subtitle1">
                <CurrencyFormat value={values.paid} />
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Typography variant="h6">Balance due</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="h6" color="secondary">
                <CurrencyFormat value={values.balance} />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
