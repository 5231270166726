import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.PROCEDURES_FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.PROCEDURES_FETCH_SUCCESS]: (
    { filter, procedures, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      selectedIDs: [],
      filter: { ...filter, page, per_page },
      procedures: data
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_PROCEDURES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.ADD_PROCEDURE]: (state, payload) => {
    const { pagination, filter, procedures, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((procedures.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page: page === 0 ? 1 : page },
      procedures: [ payload, ...procedures ]
    };
  },

  [types.UPDATE_PROCEDURE_IN_LIST]: ({ procedures, ...state }, payload) => {
    return {
      ...state,

      procedures: procedures.map((item) => item.id === payload.id ? payload : item)
    };
  },

  [types.DELETE_PROCEDURE_SUCCESS]: ({ procedures, pagination, filter, ...state }, procedureId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      procedures: procedures.filter(({ id }) => id !== procedureId)
    };
  },

  [types.TOGGLE_ALL_PROCEDURES_SELECTION]: ({ procedures, selectedIDs, ...state }) => {
    const allItemIsSelected = procedures.length === selectedIDs.length;

    return {
      ...state,

      procedures,
      selectedIDs: allItemIsSelected ? [] : procedures.map((item) => item.id)
    };
  },

  [types.TOGGLE_PROCEDURE_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1 ?
        selectedIDs.filter((item) => item !== id)
        :
        selectedIDs.concat(id)
    };
  },

  [types.MASS_DELETE_PROCEDURES]: ({ procedures, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      procedures: procedures.filter((procedure) => IDs.indexOf(procedure.id) < 0)
    };
  }
});
