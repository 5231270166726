import * as certificatesApi from '../../../../api/profile';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';

export const transformCertificateToOption = (item) => item && ({
  value: item.id,
  label: item.name
});

const fetchCertificates = fetchDataForAsyncSelect(() => {
  return certificatesApi.fetchCertificates();
}, {
  withoutPagination: true,
  transformDataToOption: transformCertificateToOption
});

export const CertificatesSelect = (props) => {
  return (
    <Select
      isAsync
      formattedValue
      name="certificate_id"
      label="Select certificate"
      promiseWithOptions={fetchCertificates}
      {...props}
    />
  );
};
