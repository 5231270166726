export const formTypesMap = {
  private: 'Private Patient Consultation',
  newPrivate: 'New Private Patient Packet',
  oldPrivate: 'Private New Packet',
  injury: 'Personal Injury - Pain Management Consultation',
  oldInjury: 'Personal Injury New Packet',
  oldOrtho: 'Initial Orthopedic Consultation',
  ortho: 'Personal Injury Orthopedic Consultation',
  newOrtho: 'Personal Injury Patient Questionnaire (ORTHO)',
  compensation: 'Workers Compensation Consultation',
  oldCompensation: 'Workers Compensation New Packet',
  follow_up: 'Follow Up',
  covid_form: 'Covid Form'
};

export const formAbbrTypesMap = {
  [formTypesMap.private]: 'PPC',
  [formTypesMap.oldPrivate]: 'PPC',
  [formTypesMap.newPrivate]: 'PPC',
  [formTypesMap.injury]: 'PI-PMC',
  [formTypesMap.oldInjury]: 'PI-PMC',
  [formTypesMap.oldOrtho]: 'PIOC',
  [formTypesMap.ortho]: 'PIOC',
  [formTypesMap.newOrtho]: 'PIOC',
  [formTypesMap.compensation]: 'WCC',
  [formTypesMap.oldCompensation]: 'WCC',
  [formTypesMap.follow_up]: formTypesMap.follow_up,
  [formTypesMap.covid_form]: formTypesMap.covid_form
};

export const formTitlesMap = {
  [formTypesMap.private]: 'private',
  [formTypesMap.injury]: 'injury',
  [formTypesMap.ortho]: 'ortho',
  [formTypesMap.compensation]: 'compensation',
  [formTypesMap.follow_up]: 'follow_up',
  [formTypesMap.covid_form]: 'covid_form'
};

export const formStatusesMap = {
  primary: 'primary',
  law_office: 'law_office'
};
