import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Button, Typography, Box } from '@material-ui/core';
import { Divider } from '../../../../../../components/Divider';
import { MedicalFormItemContext } from '../../MedicalFormItemProvider';
import { DocumentsProvider } from './DocumentsProvider';
import { MainContent } from './MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Documents = () => {
  const classes = useStyles();
  const params = useParams();
  const { activeStep, handleBack, handleReset } = useContext(MedicalFormItemContext);

  const initialState = {
    filter: {
      medical_form_id: [ params.medicalFormID ]
    }
  };

  const handleBackStep = () => {
    handleBack({ dirty: false, id: params.medicalFormID });
  };

  return (
    <div className={classes.root}>
      <Box flexGrow={1}>
        <Box pl={5}>
          <Typography variant="h5" color="secondary">
            Documents
          </Typography>
        </Box>

        <DocumentsProvider initialState={initialState}>
          <MainContent/>
        </DocumentsProvider>
      </Box>

      <Divider gutter={2} />

      <div className={classes.actionButton}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBackStep}
          className={classes.backButton}
          color="primary"
        >
          Back
        </Button>

        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleReset}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
