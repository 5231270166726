import { useContext, useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';
import { LayoutContext, viewVariantsMap } from '../../../LayoutContext';
import { List } from '../List';
import { Grid } from '../Grid';
import { BREAKPOINT_WIDTH } from '../Members';

export const MembersPage = ({ width }) => {
  const rootRef = useRef();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  useEffect(() => {
    if (width < BREAKPOINT_WIDTH) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width ]);

  return (
    <Box flexGrow={1} ref={rootRef}>
      {(viewVariant === viewVariantsMap.list) && <List />}
      {(viewVariant === viewVariantsMap.grid) && <Grid />}
    </Box>
  );
};
