import { api } from '../../api';

export const fetchDiagnosisReports = (config) => {
  return api.get('/medical-report/diagnosis-reports', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchDiagnosisReport = (id, config) => {
  return api.get(`/medical-report/diagnosis-reports/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createDiagnosisReport = (data) => {
  return api.post('/medical-report/diagnosis-reports', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateDiagnosisReport = ({ id, ...data }) => {
  return api.put(`/medical-report/diagnosis-reports/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const generatePDFDiagnosisReport = (id) => {
  return api.get(`/medical-report/diagnosis-reports/${id}/pdf`, { responseType: 'blob' })
    .then((data) => data)
    .catch((error) => { throw error; });
};

export const deleteDiagnosisReport = (id) => {
  return api.delete(`/medical-report/diagnosis-reports/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteDiagnosisReports= (IDs) => {
  return api.delete('/medical-report/diagnosis-reports', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const signReport = (data) => {
  return api.post('/document-signatures', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
