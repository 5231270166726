import { createElement } from 'react';
import { CasesSelect } from '../../../../components/cases/CasesSelect';
import { TasksSelect } from '../../../../components/FormField';
import { AppointmentsField } from '../../../../components/appointments';
import { ClaimsField } from '../../../../components/claims';
import { ApptModel } from '../../BillingPage/Invoices/InvoicesPage/InvoicesContent/ApptModel';
import { CaseModel } from '../../BillingPage/Invoices/InvoicesPage/InvoicesContent/CaseModel';
import { ClaimModel } from '../../BillingPage/Invoices/InvoicesPage/InvoicesContent/ClaimModel';
import { TaskModel } from '../../BillingPage/Invoices/InvoicesPage/InvoicesContent/TaskModel';

export const fieldsMapTypes = {
  case: 'case',
  task: 'task',
  claim: 'claim',
  appointment: 'appointment'
};

export const modelTypeOptions = [
  { value: fieldsMapTypes.case, label: 'Case' },
  { value: fieldsMapTypes.task, label: 'Task' },
  { value: fieldsMapTypes.claim, label: 'Claim' },
  { value: fieldsMapTypes.appointment, label: 'Appointment' }
];

export const modelField = {
  [fieldsMapTypes.case]: {
    field: CasesSelect,
    name: 'Cases',
    ui: CaseModel
  },
  [fieldsMapTypes.task]: {
    field: TasksSelect,
    name: 'Tasks',
    ui: TaskModel
  },
  [fieldsMapTypes.claim]: {
    field: ClaimsField,
    name: 'Claims',
    ui: ClaimModel
  },
  [fieldsMapTypes.appointment]: {
    field: AppointmentsField,
    name: 'Appointments',
    ui: ApptModel
  }
};

export const ActivityModelField = ({ modelType, ...props }) => {
  return modelType
    ? createElement(
      modelField[modelType]?.field, {
        label: modelField[modelType]?.name,
        ...props
      })
    : null;
};

export const ActivityModelUI = ({ modelType, ...props }) => (
  modelType ? createElement(modelField[modelType]?.ui, props) : null
);
