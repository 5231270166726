import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Grid } from '@material-ui/core';
import * as formsApi from '../../../../../../api/profile';
import { Button } from '../../../../../Button';
import { useModal } from '../../../../../ModalsProvider';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { UserLink } from '../../../../../UserLink';
import { ApproveAttorneyModal } from '../../ApproveAttorneyModal';

export const AttorneyStep = ({ form, setFormValue }) => {
  const { openModal } = useModal();
  const [ attorney, setAttorney ] = useState(form?.attorney);
  const { enqueueSnackbar } = useSnackbar();

  const addAttorney = () => {
    openModal(ApproveAttorneyModal, {
      payload: {
        company_id: form?.primary_insurance?.insurance_company?.id,
        medicalFormID: form.id,
        attorneyData: {
          first_name: form.attorney_first_name,
          last_name: form.attorney_last_name,
          phone_number: form.attorney_phone,
          email: form.attorney_email
        }
      },

      onModalResolved: (attorneyValue) => {
        setAttorney(attorneyValue);

        formsApi.updateMedicalForm({ id: form.id, attorney_id: attorneyValue.id }).then((data) => {
          setFormValue(data);
          enqueueSnackbar('Successfully created', { variant: 'success' });
        }).catch((error) => {
          if (error) {
            enqueueSnackbar('Not created', { variant: 'error' });
          }
        });
      }
    });
  };

  return (
    <>
      <Box pb={2}>
        <Button
          disabled={attorney?.id}
          size="small"
          variant="contained"
          color="primary"
          onClick={addAttorney}
        >
          Add Attorney
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReadonlyCheckbox
            checked={!!attorney?.id}
            title="Attorney created"
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <UserLink user={attorney} />
        </Grid>
      </Grid>
    </>
  );
};
