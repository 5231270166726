import { chatWidgetMinWidth, chatWidgetMaxWidth } from '../Chat/styles';

export const styles = ({ zIndex, transitions }) => ({
  root: {
    position: 'fixed',
    right: ({ widgetIsMinimized }) => widgetIsMinimized ? chatWidgetMinWidth : chatWidgetMaxWidth,
    bottom: 26,
    zIndex: zIndex.appBar + 1,
    transition: ({ widgetIsMinimized }) => transitions.create([ 'right' ], {
      duration: widgetIsMinimized ? transitions.duration.enteringScreen : transitions.duration.leavingScreen
    })
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },

  body: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-end'
  }
});
