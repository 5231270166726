import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <ListRow header className={classes.root}>
      <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
        {isMobile ? 'NDC Code /  Quantity type' : 'NDC Code'}
      </ListRowCell>

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.type}>
          Quantity type
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.quantity}>
        Quantity
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.price}>
        Price
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
