import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow header>
      <ListRowCell grow flexBasis={columnsWidths.search}>
        Search
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.isFavorite}>
        Is favorite
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
