export const styles = ({ spacing, palette: { grey }, typography: { pxToRem } }) => ({
  header: {
    backgroundColor: grey[600]
  },

  titleIcon: {
    marginRight: spacing()
  },

  form: {
    marginTop: spacing(8),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 400,
    padding: spacing(2, 3)
  },

  submit: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(5)
  }
});
