import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, Grid, Paper, Typography, SvgIcon, useTheme, Collapse, useMediaQuery } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { mdiFileDocumentEditOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useModal } from '../../../../../components/ModalsProvider';
import { ReadonlyCheckbox } from '../../../../../components/ReadonlyCheckbox';
import { Tooltip } from '../../../../../components/Tooltip';
import { Divider } from '../../../../../components/Divider';
import { Date } from '../../../../../components/Date';
import { IconButton } from '../../../../../components/IconButton';
import { Label } from '../../../../../components/Label';
import { UpdateInsuranceClaimModal } from '../UpdateInsuranceClaimModal';
import { InsuranceClaimRowInfo } from './InsuranceClaimRowInfo';

export const InsuranceClaimList = () => {
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { openModal } = useModal();
  const [ openClaim, setOpenClaim ] = useState({});

  const handleOpenClaim = (id) => () => {
    setOpenClaim({ [id]: !openClaim[id] });
  };

  const handleUpdateClaim = (claim, index) => () => {
    openModal(UpdateInsuranceClaimModal, {
      payload: {
        initialValues: claim,
        companyID: values?.company_id
      },
      onModalResolved: (data = null) => {
        if (data) {
          setFieldValue(`ins_claims_info[${index}]`, data);
        } else {
          setFieldValue('ins_claims_info', values?.ins_claims_info?.filter((item, i) => i !== index));
        }
      }
    });
  };

  return (
    <Box mt={isMobile ? 1 : 3} mb={1} px={2}>
      <Box color="info.main">
        <Typography variant="h6" color="inherit">
          List of Insurance Claim
        </Typography>
      </Box>

      {values?.ins_claims_info?.length ?
        values?.ins_claims_info?.map((item, i) => (
          <Box key={i} my={2} px={1}>
            <Grid
              container
              spacing={2}
              component={Paper}
              square={false}
              variant="outlined"
              alignItems="center"
            >
              <Grid item xs={isMobile ? 2 : 1}>
                <Box color="info.main">
                  <SvgIcon color="inherit" fontSize="large"><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>
                </Box>
              </Grid>

              <Grid item xs={isMobile ? 4 : 2}>
                <Typography noWrap gutterBottom variant="h5">Claim #</Typography>
                <Typography noWrap variant="body2" color="textSecondary">DOS</Typography>
              </Grid>

              <Grid item xs={isMobile ? 6 : 3}>
                <Typography noWrap gutterBottom variant="h5">{item?.number || '-'}</Typography>
                <Date noWrap date={item.date} color="textSecondary" variant="body2" />
              </Grid>

              <Grid item xs={isMobile ? 6 : 4}>
                <Box mb={0.5}>
                  <Label
                    color={theme.palette.primary.main}
                    label={<Typography noWrap>{item?.status}</Typography>}
                  />
                </Box>

                {!isMobile &&
                  <Typography variant="body2" color="textSecondary">
                    Claim Status
                  </Typography>
                }
              </Grid>

              <Grid item xs={isMobile ? 6 : 2}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={isMobile ? 'center' : 'flex-end'}
                  color="info.main"
                >
                  <Tooltip isExistTooltip title="Show claim">
                    <IconButton size="small" color="inherit" onClick={handleOpenClaim(item.id)}>
                      <EyeIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip isExistTooltip title="Update claim">
                    <IconButton size="small" color="primary" onClick={handleUpdateClaim(item, i)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item>
                <ReadonlyCheckbox checked={item?.government_claim} title="Government claim" />
              </Grid>

              <Grid item>
                <Typography noWrap variant="h5">Claim filed date</Typography>
              </Grid>

              <Grid item>
                <Date date={item?.claim_filled_at} />
              </Grid>

              <Grid item xs={12}>
                <Collapse in={!!openClaim[item.id]}>
                  <Divider type="dashed" color={theme.palette.info.main} />
                  <InsuranceClaimRowInfo claim={item} />
                </Collapse>
              </Grid>
            </Grid>
          </Box>
        ))
        :
        <Typography align="center" color="textSecondary">No claims found</Typography>
      }
    </Box>
  );
};
