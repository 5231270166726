import { Box, Grid, Typography } from '@material-ui/core';

export const NationalDrugCode = ({ serviceLine }) => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">
          National Drug Code
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Package Number
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="subtitle2">
            {serviceLine.package_number || '-'}
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Quantity
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="subtitle2">
            {serviceLine.quantity || '-'}
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Unit
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="subtitle2">
            {serviceLine.unit?.label || '-'}
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Unit Price
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="subtitle2">
            {serviceLine.unit_price || '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
