import { useContext } from 'react';
import { AddressLink } from '../../../../../../../../../../components/AddressLink';
import { Checkbox } from '../../../../../../../../../../components/FormField';
import { ListRow, ListRowCell } from '../../../../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../../../../components/Loader';
import { AppointmentBooksContext } from '../../../../../../AppointmentBooks/AppointmentBooksContext';
import { columnsWidths } from '../List';

export const Row = ({ item: appointmentBook, isLoaded }) => {
  const appointmentBooksContext = useContext(AppointmentBooksContext);

  const toggleBookSelected = () => {
    appointmentBooksContext.toggleBookSelected(appointmentBook);
  };

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow disabled={!appointmentBook.is_active}>
      <ListRowCell noDisable>
        <Checkbox
          withoutFormik
          checked={appointmentBooksContext.selectedBooksIDs.includes(appointmentBook.id)}
          onChange={toggleBookSelected}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.name}>
        {appointmentBook.appointment_book_name}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.location}>
        <AddressLink address={appointmentBook.office?.full_address} />
      </ListRowCell>
    </ListRow>
  );
};
