import { useContext, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell grow noWrap flexBasis={columnsWidths.name}>
        {filter?.params?.name || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.type}>
        {filter?.params?.company_type_id?.name || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.address}>
        {filter?.params?.address || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.email}>
        {filter?.params?.email || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} justifyContent="flex-end">
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

