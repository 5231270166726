import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { mdiDomain } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as officeApi from '../../../../../api/companies';
import {
  transformTimeZoneToOption,
  transformPayPeriodToOption
} from '../../../../../components/FormField';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { OfficeForm, validationSchema } from '../OfficeForm';

export const EditOfficeModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { id }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ office, setOffice ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);

  const updateOffice = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (!values.is_medical) {
      values.medical_data = null;
    }

    if (!!values?.venue?.length) {
      const isMainVenue = values.venue.find((item) => item.is_main > 0);

      if (!isMainVenue) {
        values.venue[0] = { ...values.venue[0], is_main: 1 };
      }
    }

    return officeApi.updateOffice({
      ...values,

      company_id: values?.company_id?.id || values?.company_id || null,
      users: values?.users?.map((user) => ({ id: user?.id?.id, position: user.position }))
    }).then((data) => {
      enqueueSnackbar('Office successfully updated', { variant: 'success' });
      handleModalResolve({ billing_full_address: data?.billing?.full_address, ...data });
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Office not updated', { variant: 'error' });
      }
    });
  };

  useEffect(() => {
    setIsFetched(false);

    officeApi.fetchOffice(id).then((data) => {
      if (data?.medical_data) {
        data.medical_data = {
          ...data.medical_data,
          pos_id: data?.medical_data?.pos
        };
      }
      setOffice(data);
      setIsFetched(true);
    });
  }, []);

  return (
    <Dialog maxWidth="md" {...DialogProps}>
      <Loader p={3} loading={!isFetched} render={
        () => (
          <Formik
            initialValues={{
              ...office,

              id,
              timezone: transformTimeZoneToOption(office.timezone) || null,
              company_id: office.company,
              billing_contact_id: office.billing_contact,
              users: office?.users?.map((user) => user && ({
                id: user,
                position: user.position,
                phone: user.phone
              })),
              is_medical: !!office?.is_medical,
              payroll: {
                ...office.payroll,

                pay_period: transformPayPeriodToOption(office?.payroll?.pay_period),
                pay_period_range: {
                  start: null,
                  finish: null,

                  ...office.payroll?.pay_period_range
                }
              }
            }}
            validationSchema={validationSchema}
            onSubmit={updateOffice}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader
                  icon={<SvgIcon><Icon path={mdiDomain} /></SvgIcon>}
                  onClose={handleModalReject}
                >
                  Edit Office
                </ModalHeader>

                <ModalBody>
                  <OfficeForm />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>Cancel</Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
