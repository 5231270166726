import { createContext, useRef, useEffect, useReducer, useState, useContext } from 'react';
import { isEqual, omit } from 'lodash';
import moment from 'moment';
import { usePrevious } from '../../../../helpers/hooks';
import { api } from '../../../../api';
import * as eventsApi from '../../../../api/schedule-events';
import { ScheduleEventsFilterContext } from '../../index';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const CalendarContext = createContext();

export const CalendarProvider = ({ disableReloadCalendar = false, children }) => {
  const { filter: commonFilter } = useContext(ScheduleEventsFilterContext);
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { filter } = state;
  const prevFilter = usePrevious(filter);
  const [ selectedDate, setSelectedDate ] = useState(moment());
  const cancelFetch = useRef(() => {});
  const cancelEventsFetch = useRef(() => {});

  const fetchCalendar = (newFilter = {}) => {
    cancelFetch.current();

    eventsApi.fetchCalendar({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_SCHEDULE_SUCCESS, payload: data });
    });
  };

  const fetchCalendarEvents = (newFilter = {}) => {
    cancelEventsFetch.current();

    eventsApi.fetchCalendarEvents({
      params: { ...filter, ...commonFilter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelEventsFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_CALENDAR_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const reloadCalendar = (newFilter) => {
    dispatch({ type: types.RESET_SCHEDULE, payload: newFilter });
    fetchCalendar(newFilter);
  };

  const reloadCalendarEvents = (newFilter) => {
    dispatch({ type: types.RESET_SCHEDULE, payload: newFilter });
    fetchCalendarEvents(newFilter);
  };

  const addEvent = (event) => {
    dispatch({ type: types.ADD_SCHEDULE_EVENT, payload: event });
  };

  const updateEvent = (event) => {
    dispatch({ type: types.UPDATE_SCHEDULE_EVENT, payload: event });
  };

  const deleteEvent = (id) => {
    dispatch({ type: types.DELETE_SCHEDULE_EVENT, payload: id });
  };

  const providerValue = {
    ...state,
    selectedDate,

    // functions
    setSelectedDate,
    fetchCalendar,
    reloadCalendarEvents,
    fetchCalendarEvents,
    reloadCalendar,
    applyFilter,
    addEvent,
    updateEvent,
    deleteEvent
  };

  useEffect(() => {
    const newFilter = { ...filter, ...omit(commonFilter, [ 'started_from', 'started_to' ]) };

    if (!isEqual(filter, newFilter)) {
      reloadCalendar(newFilter);
    }
  }, [ commonFilter ]);

  useEffect(() => {
    if (!disableReloadCalendar && !isEqual(prevFilter, filter)) {
      reloadCalendar(filter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
      cancelEventsFetch.current();
    };
  }, []);

  return (
    <CalendarContext.Provider value={providerValue}>
      {children}
    </CalendarContext.Provider>
  );
};
