import { Link, Typography } from '@material-ui/core';
import { PreviewCompanyModal } from '../../../app/Dashboard/Contacts/Companies/PreviewCompanyModal';
import { useModal } from '../../ModalsProvider';

export const Company = ({ before, after, fieldLabel }) => {
  const { openModal } = useModal();

  const openCompanyPreview = (id) => () => {
    if (id) {
      openModal(PreviewCompanyModal, {
        payload: { companyID: id }
      });
    }
  };

  if (!before && after) {
    return (
      <>
        Added&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Link onClick={openCompanyPreview(after?.id)}>
          {after?.name || ''}
        </Link>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Link onClick={openCompanyPreview(before?.id)}>
          {before?.name || ''}
        </Link>
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Link onClick={openCompanyPreview(before?.id)}>
          {before?.name || ''}
        </Link>
        &nbsp;to&nbsp;
        <Link onClick={openCompanyPreview(after?.id)}>
          {after?.name || ''}
        </Link>
      </>
    );
  } else {
    return (
      <>
        Empty&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  }
};
