import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../ListRow';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const columnsWidths = {
  name: 200,
  dosage: 100,
  frequency: 100
};

export const Medications = ({ medications }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ListRow header>
        <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
          <Typography variant="subtitle2">
            Medication Name
          </Typography>
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dosage}>
          <Typography variant="subtitle2">
            Dosage
          </Typography>
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.frequency}>
          <Typography variant="subtitle2">
            Frequency
          </Typography>
        </ListRowCell>
      </ListRow>

      <MuiList disablePadding>
        {medications?.map((item, i) => (
          <ListRow key={i}>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
              <Typography noWrap>{item.medication_name}</Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.dosage}>
              <Typography noWrap>{item.dosage}</Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.frequency}>
              <Typography noWrap>{item.frequency}</Typography>
            </ListRowCell>
          </ListRow>
        ))}
      </MuiList>
    </div>
  );
};
