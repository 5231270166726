import moment from 'moment';
import {
  Bool,
  Date,
  Insurance,
  Text,
  Authorization,
  Office,
  CurrencyFormat,
  UserActivityLink
} from '../../Activities/fields';
import { AppointmentType, ApptBook, ArrivalStatus } from '../../Activities/fields/appointmentFields';

export const getVisitReasonLabel = (reason) => {
  return reason && (reason.code + (reason.description ? ' - ' + reason.description : ''));
};

export const fieldsMap = {
  authorization_id: (before, after) => <Authorization before={before} after={after} />,
  time: (before, after) => (
    <Text
      before={`${moment.duration(before, 's').asMinutes()} min`}
      after={`${moment.duration(after, 's').asMinutes()} min`}
      fieldLabel="Appt. Duration"
    />
  ),
  appointment_at: (before, after) => <Date before={before} after={after} fieldLabel="appointment start date" />,
  note: (before, after) => <Text before={before} after={after} fieldLabel="Note" />,
  group_id: (before, after) => <Text before={before} after={after} fieldLabel="Group ID" />,
  copay_type: (before, after) => <Text before={before} after={after} fieldLabel="Copay Type" />,
  office: (before, after) => <Office before={before} after={after} fieldLabel="Office" />,
  price: (before, after) => <CurrencyFormat before={before} after={after} fieldLabel="Price" />,
  patient: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Patient" />,
  do_not_bill_claim: (before, after) => <Bool before={before} after={after} fieldLabel="Do Not Bill Claim" />,
  appointment_confirmed_by_office: (before, after) => (
    <Bool before={before} after={after} fieldLabel="Appointment Confirmed By Office" />
  ),
  eligibility_manually_verified: (before, after) => (
    <Bool before={before} after={after} fieldLabel="Eligibility Manually Verified" />
  ),
  appointment_type: (before, after) => (
    <AppointmentType before={before} after={after} fieldLabel="Appointment Type" />
  ),
  arrival_status: (before, after) => (
    <ArrivalStatus before={before} after={after} fieldLabel="Arrival Status" />
  ),
  patient_insurance: (before, after) => <Insurance before={before} after={after} fieldLabel="Patient Insurance" />,
  appointment_book: (before, after) => <ApptBook before={before} after={after} fieldLabel="Appt. Book" />,
  follow_up_count: (before, after) => <Text before={before} after={after} fieldLabel="Follow Up Count" />,
  follow_up_type: (before, after) => <Text before={before} after={after} fieldLabel="Follow Up Type" />,
  chief_complaint: (before, after) => <Text before={before} after={after} fieldLabel="Chief Complaint" />,
  visit_reason: (before, after) => (
    <Text before={getVisitReasonLabel(before)} after={getVisitReasonLabel(after)} fieldLabel="Visit Reason" />
  )
};
