import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog } from '@material-ui/core';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import * as accessUsersApi from '../../../../api/access-users';
import { validationSchema } from './validationSchema';
import { workersRolesOptions } from '../../../../dataMaps';
import { CompaniesAutocomplete, OfficesLocationSelect, Select } from '../../../FormField';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../Modal';
import { Loader } from '../../../Loader';
import { UsersSelect } from '../../UsersSelect';

export const AddMembersModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    accessibleUserID
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const addUserWithAccess = (values) => {
    accessUsersApi.addUserWithAccess({ user_id: values.user_id, patient_id: accessibleUserID }).then(({ data }) => {
      enqueueSnackbar('Member successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(() => {
      enqueueSnackbar('Something goes wrong!', { variant: 'error' });
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          role: null,
          office_id: null,
          company_id: null,
          user_id: null
        }}
        validationSchema={validationSchema}
        onSubmit={addUserWithAccess}
      >
        {({ isSubmitting, values, handleSubmit }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader
              icon={<PersonAddOutlinedIcon />}
              onClose={handleModalReject}
            >
              Add Member
            </ModalHeader>

            <ModalBody>
              <Select
                isClearable
                formattedValue
                name="role"
                label="Position (Role)"
                options={workersRolesOptions}
                TextFieldProps={{ margin: 'dense' }}
              />

              <CompaniesAutocomplete
                label="Name Company"
                name="company_id"
                margin="dense"
              />

              <OfficesLocationSelect
                label="Name Office"
                name="office_id"
                margin="dense"
                params={{ company_id: values?.company_id || null }}
              />

              <UsersSelect
                required
                name="user_id"
                label="Staff"
                margin="dense"
                params={{
                  role: values?.role || null,
                  company_id: values?.company_id || null,
                  office_id: values?.office_id || null
                }}
              />
            </ModalBody>

            <ModalFooter>
              <Box mr={1}>
                <Button onClick={handleModalReject} color="primary">Cancel</Button>
              </Box>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
