import { useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import {
  PreviewInvoiceModal
} from '../../../../../../CasesPage/Cases/CaseItem/TabsContent/Billing/PreviewInvoiceModal';
import { InvoicesContext } from '../../../../InvoicesContext';
import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = withRouter(({ invoice, hiddenColumns = [], onInvoiceSelect }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { openModal } = useModal();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { filter, confirmInvoice, cancelInvoice } = useContext(InvoicesContext);

  const handleInvoiceSelect = () => {
    onInvoiceSelect(invoice);
  };

  const goToInvoicePage = () => {
    if (onInvoiceSelect) {
      return handleInvoiceSelect();
    }

    if (!isMobile) {
      history.push(itemsRoutesByModels.invoice(invoice.id), { filter });
    } else {
      openModal(PreviewInvoiceModal, {
        payload: {
          invoiceID: invoice.id,
          confirmInvoice,
          cancelInvoice
        }
      });
    }
  };

  return (
    <div className={classes.root} onClick={goToInvoicePage}>
      <Header
        disableToggle={!!onInvoiceSelect}
        hiddenColumns={hiddenColumns}
        invoice={invoice}
      />
      <Body invoice={invoice} />
      <Footer invoice={invoice} />
    </div>
  );
});
