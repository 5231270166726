import { makeStyles } from '@material-ui/core';
import { Row } from '../MainCreateContent/SelectedInvoices/Row';
import { TableHeader } from './TableHeader';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const InvoiceContent = ({ invoice, width }) => {
  const classes = useStyles();

  return (
    <div className={classes.rootContent}>
      <TableHeader width={width} />
      <Row invoice={invoice} width={width}/>
    </div>
  );
};
