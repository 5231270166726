import { ITEM_WIDTH, ITEM_HEIGHT } from './RecentlyOpened';

export const styles = ({ spacing }) => ({
  root: {
    marginBottom: spacing(3)
  },

  filesRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: ITEM_HEIGHT,
    overflow: 'hidden',
    marginLeft: -spacing(),
    marginRight: -spacing()
  },

  loader: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  },

  noFilesMessage: {
    width: '100%'
  },

  file: {
    flex: `1 1 ${ITEM_WIDTH}px`,
    height: ITEM_HEIGHT,
    minWidth: ITEM_WIDTH,
    maxWidth: ITEM_WIDTH * 1.25,
    padding: spacing()
  }
});
