export const styles = ({ spacing, palette }) => ({
  root: {
    border: `1px solid ${palette.divider}`,
    marginTop: spacing(2)
  },

  root_notEmpty: {
    borderBottomWidth: 0
  },

  header: {
    paddingLeft: spacing(),
    paddingRight: spacing()
  },

  title: {
    padding: spacing(3)
  }
});
