import { fetchDataForAsyncSelect } from '../../../helpers/fetchDataForAsyncSelect';
import * as expensesApi from '../../../api/billing';
import { Select } from '../../FormField/selects/Select';
import { Option } from './Option';
import { SingleValue } from './SingleValue';

const components = {
  SingleValue,
  Option
};

export const transformExpensesCategoryToOption = (expensesCategory) => expensesCategory && ({
  label: expensesCategory.name,
  value: expensesCategory.id,
  data: expensesCategory
});

const fetchCategory = fetchDataForAsyncSelect((search, { page }) => {
  // TODO: remove after backend fixes
  const paramsProps = search.length ? { search } : {};

  return expensesApi.fetchExpensesCategories({ params: { ...paramsProps, is_active: 1, page } });
}, {
  transformDataToOption: transformExpensesCategoryToOption
});

export const ExpensesCategoriesSelect = (props) => {
  return (
    <Select
      isAsync
      formattedValue
      placeholder="Search category..."
      components={components}
      promiseWithOptions={fetchCategory}

      {...props}
    />
  );
};
