import { Typography, useTheme, useMediaQuery, Box } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Contact } from '../../../../../../../../components/Contact';
import { IconButton } from '../../../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const Row = ({ user, onUserDelete }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDeleteUser = () => {
    onUserDelete(user);
  };

  return (
    <ListRow>
      <ListRowCell grow column={isMobile} noWrap flexBasis={columnsWidths.name}>
        <UserLink
          disableLink
          size={isMobile ? 'sm' : 'md'}
          user={user}
        />

        {isMobile && (
          <Box display="flex" alignItems="center">
            <Typography>Role:</Typography>
            &nbsp;
            <Typography noWrap>{user.role}</Typography>
          </Box>
        )}
      </ListRowCell>

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.role}>
          <Typography noWrap>
            {user.role}
          </Typography>
        </ListRowCell>
      )}

      <ListRowCell column={isMobile} flexBasis={columnsWidths.mobile_phone_number}>
        <Contact
          noWrap
          needIcon={isMobile}
          type="tel"
          contact={user?.profile?.mobile_phone_number || user?.phone || user?.mobile_phone_number}
        />

        {isMobile && (
          <Contact
            noWrap
            needIcon
            color="primary"
            type="mailto"
            contact={user.email}
          />
        )}
      </ListRowCell>

      {!isMobile && (
        <ListRowCell flexBasis={columnsWidths.email}>
          <Contact
            noWrap
            color="primary"
            type="mailto"
            contact={user.email}
          />
        </ListRowCell>
      )}

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          edge="end"
          title="Remove from this appt. book"
          onClick={handleDeleteUser}
        >
          <ClearIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
