import cn from 'classnames';
import { makeStyles, Tab as MuiTab } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Tab = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <MuiTab
      classes={{
        root: cn(classes.root, className)
      }}
      {...props}
    />
  );
};
