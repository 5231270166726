import { useFormikContext } from 'formik';
import {
  makeStyles,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  Grid
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Checkbox, RadioGroup, TextField } from '../../../FormField';
import { getTextColor } from '../../getTextColor';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MiddlePain = () => {
  const classes = useStyles();
  const { errors } = useFormikContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          IF PAIN IN MIDDLE BACK
        </Typography>
      </Grid>

      <Grid item xs={12} />

      <Grid item xs={12}>
        <Typography variant="h5">
          1. Where is your pain?
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-around" flexWrap="wrap">
          <FormControlLabel
            label={<Typography>Shoulder Blades</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.middle_back.does_the_pain_radiate_to.parts.shoulder_blades"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Chest</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.middle_back.does_the_pain_radiate_to.parts.chest"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Other</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.middle_back.does_the_pain_radiate_to.parts.other"
              />
            }
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          disableUnderline
          name="forms.middle_back.does_the_pain_radiate_to.description"
          margin="dense"
          variant="filled"
          placeholder="Please describe"
          rows={5}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h5"
          color={getTextColor(errors?.forms?.middle_back?.how_often_do_you_experience_the_pain)}
        >
          2. How often do you experience the pain? *
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box pl={2} mt={1}>
          <RadioGroup row name="forms.middle_back.how_often_do_you_experience_the_pain">
            <FormControlLabel
              value="occasional"
              label={<Typography>Occasional</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="frequent"
              label={<Typography>Frequent</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="constant"
              label={<Typography>Constant</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h5"
          color={getTextColor(errors?.forms?.middle_back?.what_severity_your_pain)}
        >
          3. What is the severity of your pain? *
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box pl={2}>
          <RadioGroup
            row
            name="forms.middle_back.what_severity_your_pain"
          >
            <FormControlLabel
              value="mild"
              label={<Typography>Mild</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="moderate"
              label={<Typography>Moderate</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="severe"
              label={<Typography>Severe</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          4. Do you experience any of the following? If yes, please explain.
        </Typography>
      </Grid>

      <Grid item md={4} xs={12}>
        <FormControlLabel
          label={<Typography>Weakness</Typography>}
          className={classes.checkboxSpacing}
          control={
            <Checkbox
              color="primary"
              name="forms.middle_back.experience_following.weakness.value"
            />
          }
        />
      </Grid>

      <Grid item md={8} xs={12}>
        <TextField
          name="forms.middle_back.experience_following.weakness.description"
          label={
            <div className={classes.noteIcon}>
              <NotesIcon fontSize="small" color="inherit" /> Note
            </div>
          }
          margin="normal"
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <FormControlLabel
          className={classes.checkboxSpacing}
          label={<Typography>Muscle Spasms/Tightness/Tension</Typography>}
          control={
            <Checkbox
              color="primary"
              name="forms.middle_back.experience_following.muscle_spasms.value"
            />
          }
        />
      </Grid>

      <Grid item md={8} xs={12}>
        <TextField
          name="forms.middle_back.experience_following.muscle_spasms.description"
          label={
            <div className={classes.noteIcon}>
              <NotesIcon fontSize="small" color="inherit"/> Note
            </div>
          }
          margin="normal"
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <FormControlLabel
          className={classes.checkboxSpacing}
          label={<Typography>Stiffness</Typography>}
          control={
            <Checkbox
              color="primary"
              name="forms.middle_back.experience_following.stiffness.value"
            />
          }
        />
      </Grid>

      <Grid item md={8} xs={12}>
        <TextField
          name="forms.middle_back.experience_following.stiffness.description"
          label={
            <div className={classes.noteIcon}>
              <NotesIcon fontSize="small" color="inherit" /> Note
            </div>
          }
          margin="normal"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h5"
          color={getTextColor(errors?.forms?.middle_back?.symptoms_increase_while_twisting)}
        >
          5. Do your symptoms increase while twisting, turning, extending or flexing the spine? *
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box pl={2}>
          <RadioGroup row name="forms.middle_back.symptoms_increase_while_twisting">
            <FormControlLabel
              value="Yes"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="No"
              label={<Typography>No</Typography>}
              control={<Radio color="primary"/>}
            />
          </RadioGroup>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h5"
          color={getTextColor(errors?.forms?.middle_back?.symptoms_increase_with_activity)}
        >
          6. Do your symptoms increase with activity involving the use of muscles
          in the middle back, vigorous activity, bending over or with change in temperature? *
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box pl={2}>
          <RadioGroup row name="forms.middle_back.symptoms_increase_with_activity">
            <FormControlLabel
              value="Yes"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="No"
              label={<Typography>No</Typography>}
              control={<Radio color="primary"/>}
            />
          </RadioGroup>
        </Box>
      </Grid>
    </Grid>
  );
};
