import { useState } from 'react';
import cn from 'classnames';
import { makeStyles, Box, AppBar, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { IconButton } from '../../../../../components/IconButton';
import { widthBreakpointLg } from '../TaskItem';
import { Tasks } from './Tasks';
import { Events } from './Events';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const tabsMap = {
  schedule: 'schedule',
  tasks: 'tasks'
};

export const LeftSideBar = ({ statuses, width, sidebarIsOpen, handleToggleLeftSidebar = () => {} }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const classes = useStyles({ width: width < widthBreakpointLg ? 300 : 330 });
  const [ selectedTab, setSelectedTab ] = useState(tabsMap.tasks);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const MobileWrapper = ({ children }) => !isMobile ? (
    children
  ) : (
    <Box display="flex">{children}</Box>
  );

  return (
    <div className={cn(classes.root, { [classes.rootMobile]: isMobile, [classes.root_collapsed]: !sidebarIsOpen })}>
      <AppBar position="static">
        <MobileWrapper>
          <Tabs
            variant="fullWidth"
            value={selectedTab}
            onChange={handleTabChange}
          >
            <Tab
              value={tabsMap.tasks}
              label="Tasks"
            />

            <Tab
              value={tabsMap.schedule}
              label="Schedule"
            />
          </Tabs>

          {isMobile &&
            <Box display="flex" alignItems="center" ml="auto">
              <IconButton onClick={handleToggleLeftSidebar}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            </Box>
          }
        </MobileWrapper>
      </AppBar>

      {(selectedTab === tabsMap.schedule) &&
        <Box flexGrow={1} p={1}>
          <Events />
        </Box>
      }

      {(selectedTab === tabsMap.tasks) &&
        <Tasks statuses={statuses} />
      }
    </div>
  );
};
