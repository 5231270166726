export const styles = ({ spacing, palette: { background } }) => ({
  root: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)'
  },

  toolbar: {
    display: 'flex',
    padding: spacing(1, 3),
    background: background.primary
  }
});
