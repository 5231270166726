import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton, Typography, Grid } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useModal } from '../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../components/ContentCard';
import { CertificatesProvider } from './CertificatesContext';
import { Certificates } from './Certificates';
import { UpdateSignatureModal } from './UpdateSignatureModal';

export const Signature = () => {
  const { openModal } = useModal();
  const signature = useSelector(({ profile }) => profile?.user?.signature) || null;
  const { user } = useSelector(({ profile }) => profile);
  const [ isCollapsed, setIsCollapsed ] = useState(false);

  const toggleCollapsed = () => {
    setIsCollapsed((state) => !state);
  };

  const updateSignature = () => {
    const { first_name, middle_name, last_name } = user;
    const defaultSignature = {
      electronic_signature: `${first_name} ${(middle_name ? middle_name[0] + '. ' : '') + last_name}`,
      fax_signature: first_name?.[0] + last_name?.[0]
    };

    openModal(UpdateSignatureModal, {
      payload: {
        signature: signature ? {
          electronic_signature: signature?.electronic_signature || defaultSignature?.electronic_signature,
          fax_signature: signature?.fax_signature || defaultSignature?.fax_signature
        } : defaultSignature
      }
    });
  };

  return (
    <ContentCard
      disableAutoHeight
      autoHeightMax={800}
      isCollapsed={isCollapsed}
      title="Signature"
      rightActions={[
        <IconButton color="inherit" onClick={updateSignature}>
          <EditIcon />
        </IconButton>,

        <IconButton onClick={toggleCollapsed} color="inherit">
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      ]}
      component={Box}
      mb={1}
    >
      {!signature ?
        <Typography align="center">You don't have a signature yet</Typography>
        :
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Signature</Typography>

            <Box bgcolor="grey.50" p={1}>
              <img
                height="64"
                src={signature.electronic_signature_file}
                alt={signature.electronic_signature}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4">Initials</Typography>

            <Box bgcolor="grey.50" p={1}>
              <img
                height="64"
                src={signature.fax_signature_file}
                alt={signature.fax_signature}
              />
            </Box>
          </Grid>
        </Grid>
      }

      <CertificatesProvider>
        <Certificates />
      </CertificatesProvider>
    </ContentCard>
  );
};
