import { useContext, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import * as statisticsApi from '../../../../../../../api/billing';
import { api } from '../../../../../../../api';
import { Loader } from '../../../../../../../components/Loader';
import { StatisticsFilterContext } from '../../../StatisticsFilterProvider';
import { StatisticsExpensesFilterContext } from '../StatisticsExpensesFilterContext';
import { FiltersBar } from './FiltersBar';
import { Chart } from './Chart';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = () => {
  const classes = useStyles();
  const [ statistics, setStatistics ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const { filter } = useContext(StatisticsFilterContext);
  const { filter: commonFilter, setFilter } = useContext(StatisticsExpensesFilterContext);
  const [ isOpenDayPicker, setIsOpenDayPicker ] = useState(false);
  const [ isOpenWeekPicker, setIsOpenWeekPicker ] = useState(false);
  const [ isOpenMonthPicker, setIsOpenMonthPicker ] = useState(false);
  const [ isOpenYearPicker, setIsOpenYearPicker ] = useState(false);
  const [ message, setMessage ] = useState(null);
  const cancelFetch = useRef(() => {});

  useEffect(() => {
    setFilter((item) => ({ ...item, ...filter, period: null }));
    setMessage(`${moment.unix(filter.from).format('L')} - ${moment.unix(filter.to).format('L')}`);
  }, [ filter ]);

  const fetchStatistics = () => {
    cancelFetch.current();
    setIsFetched(false);

    statisticsApi.fetchExpenseStatistics({
      params: commonFilter,
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then((data) => {
      setStatistics(data);
      setIsFetched(true);
    });
  };

  const resetStatistics = (newFilter) => {
    fetchStatistics(newFilter);
  };

  useEffect(() => {
    resetStatistics();

    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    if (!isEqual(filter, { ...filter, ...commonFilter })) {
      resetStatistics();
    }
  }, [ filter, commonFilter ]);

  return (
    <div className={classes.root}>
      <FiltersBar
        message={message}
        isOpenDayPicker={isOpenDayPicker}
        isOpenWeekPicker={isOpenWeekPicker}
        isOpenMonthPicker={isOpenMonthPicker}
        isOpenYearPicker={isOpenYearPicker}
        setIsOpenYearPicker={setIsOpenYearPicker}
        setIsOpenMonthPicker={setIsOpenMonthPicker}
        setIsOpenWeekPicker={setIsOpenWeekPicker}
        setIsOpenDayPicker={setIsOpenDayPicker}
        setMessage={setMessage}
      />

      <Loader loading={!isFetched} className={classes.loader} render={
        () => (
          <div className={classes.mainContent}>
            <div className={classes.chartInfo}>
              <div className={classes.content}>
                <Chart data={statistics.transformResponse.data} labels={statistics.transformResponse.labels}/>
              </div>

              <div className={classes.content}>
                <List statistics={statistics.data} />
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};
