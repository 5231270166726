export const styles = ({
  spacing,
  palette: { grey, ...palette }
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 0.5, 1, 1.5),
    borderBottom: `1px solid ${grey[200]}`,
    background: palette.common.white
  },

  preview: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    marginRight: spacing(1.5)
  },

  preview__picture: {
    position: 'relative',
    width: 62,
    height: 42,
    overflow: 'hidden',
    marginBottom: spacing(0.25),
    textAlign: 'center'
  },

  preview__fileSize: {
    color: grey[400]
  },

  mainInfo: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'hidden'
  },

  mainInfo__name: {
    color: grey[600]
  },

  mainInfo__date: {
    color: grey[400]
  }
});
