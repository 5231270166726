import { useFormikContext } from 'formik';
import { Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Checkbox } from '../../../../../components/FormField';
import { SvgIcon } from '../../../../../components/SvgIcon';
import { insuranceStatusTypesMap, statusTypeOptions } from './insuranceDataMaps';

export const IsMainInsuranceToggler = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleToggleMain = (event) => {
    const { checked } = event.target;

    setFieldValue('is_main', checked);
    setFieldValue('status_type',
      statusTypeOptions.find(({ value }) => checked ? value === 'primary' : value === 'secondary').value);
  };

  return values.status_type !== insuranceStatusTypesMap.law_office && (
    <Tooltip title={values.is_main ? 'Do not use as main' : 'Set as main'}>
      <Checkbox
        name="is_main"
        icon={<CheckCircleIcon/>}
        checkedIcon={<SvgIcon color="success"><CheckCircleIcon/></SvgIcon>}
        onChange={handleToggleMain}
      />
    </Tooltip>
  );
};
