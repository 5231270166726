import moment from 'moment';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import { getUserFullName } from '../../../helpers/users';
import { Label } from '../../Label';
import { SvgIcon } from '../../SvgIcon';
import { arrivalTypes } from '../arrivalTypes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Appointment = ({ appointment }) => {
  const classes = useStyles({ appointment });

  return !!appointment && (
    <div className={classes.root}>
      <Box display="flex">
        <Typography variant="subtitle2" color="textPrimary">
          {getUserFullName(appointment?.patient_appointment_details?.patient)}
        </Typography>

        {!!appointment?.appointment_type &&
          <Typography noWrap variant="body2" title={appointment?.appointment_type?.description} color="textPrimary">
            , {appointment?.appointment_type?.code}
          </Typography>
        }
      </Box>

      <Grid container spacing={1} alignItems="center" wrap="nowrap">
        {appointment?.arrival_status &&
          <Grid item zeroMinWidth xs>
            <Label
              color={arrivalTypes[appointment?.arrival_status?.arrival_type]?.color}
              label={
                <Typography noWrap component="span">
                  {`${appointment?.arrival_status?.code} - ${appointment?.arrival_status?.description}`}
                </Typography>
              }
            />
          </Grid>
        }

        <Grid item>
          {!!appointment.appointment_confirmed_by_office &&
            <Box bgcolor="success.contrastText" borderRadius="50%" title="Appt is confirmed">
              <SvgIcon color="success">
                <CheckCircleIcon />
              </SvgIcon>
            </Box>
          }
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {!!appointment.group_id &&
          <Grid item>
            <LoopOutlinedIcon fontSize="small" titleAccess="This appt is recurring" className={classes.loopIcon} />
          </Grid>
        }

        <Grid item>
          <Typography variant="body2" color="textPrimary">
            {moment.unix(appointment.appointment_at).format('LT')}

            {!!appointment.time && ' - ' + moment.unix(
              appointment.appointment_at + moment.duration(appointment.time, 's').asSeconds()
            ).format('LT')}
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="subtitle2" color="textPrimary">
        {appointment?.appointment_book?.provider &&
          <>
            Dr.: {getUserFullName(appointment?.appointment_book?.provider)}
          </>
        }
      </Typography>
    </div>
  );
};
