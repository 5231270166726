import { createContext, useRef, useEffect, useReducer } from 'react';
import { merge, omit, isEqual } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import { api } from '../../../../../api';
import * as publicAccessApi from '../../../../../api/public-access';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const AccessControlContext = createContext();

export const AccessControlProvider = ({ filter: filterProp, children }) => {
  const [
    {
      emails,
      isFetched,
      isFetching,
      filter,
      pagination
    },

    dispatch
  ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchEmails = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_EMAILS_REQUEST });

    publicAccessApi.fetchEmails({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_EMAILS_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchEmails({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetEmails = (newFilter) => {
    dispatch({ type: types.RESET_EMAILS, payload: newFilter });
    fetchEmails({ page: 1, ...newFilter });
  };

  const providerValue = {
    isFetched,
    isFetching,
    emails,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetEmails,
    fetchEmails,
    loadNextPage,
    applyFilter
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetEmails(newFilter);
    }
  }, [ prevFilter, filter ]);

  return (
    <AccessControlContext.Provider value={providerValue}>
      {children}
    </AccessControlContext.Provider>
  );
};
