export const generateInitialPainValues = (form) => ({
  forms: {
    pain_in_neck: {
      ...form?.forms?.pain_in_neck,
      your_dominant_hand: form?.forms?.pain_in_neck?.your_dominant_hand || null,
      where_your_pain: {
        ...form?.forms?.pain_in_neck?.where_your_pain,
        parts: {
          neck: form?.forms?.pain_in_neck?.where_your_pain?.parts?.neck || false,
          hand: form?.forms?.pain_in_neck?.where_your_pain?.parts?.hand || false,
          other: form?.forms?.pain_in_neck?.where_your_pain?.parts?.other || false,
          shoulder: form?.forms?.pain_in_neck?.where_your_pain?.parts?.shoulder || false,
          arms: form?.forms?.pain_in_neck?.where_your_pain?.parts?.arms || false
        },
        description: form?.forms?.pain_in_neck?.where_your_pain?.description || null
      },
      how_often_your_pain: form?.forms?.pain_in_neck?.how_often_your_pain || null,
      what_severity_your_pain: form?.forms?.pain_in_neck?.what_severity_your_pain || null,
      your_pain_radiate_other_parts: form?.forms?.pain_in_neck?.your_pain_radiate_other_parts || null,
      where_pain_radiate_too: {
        forearm: form?.forms?.pain_in_neck?.where_pain_radiate_too?.forearm,
        hand: form?.forms?.pain_in_neck?.where_pain_radiate_too?.hand,
        fingers: form?.forms?.pain_in_neck?.where_pain_radiate_too?.fingers
      },
      which_side_body_pain_radiate: form?.forms?.pain_in_neck?.which_side_body_pain_radiate || null,
      symptoms_increase_while_twisting: form?.forms?.pain_in_neck?.symptoms_increase_while_twisting || null,
      is_neck: !!form?.forms?.pain_in_neck || null
    },
    pain_in_low_back: {
      ...form?.forms?.pain_in_low_back,
      how_often_your_pain: form?.forms?.pain_in_low_back?.how_often_your_pain || null,
      avoided_strenuous_lifting: form?.forms?.pain_in_low_back?.avoided_strenuous_lifting || null,
      what_severity_your_pain: form?.forms?.pain_in_low_back?.what_severity_your_pain || null,
      pain_radiate_other_parts_body: form?.forms?.pain_in_low_back?.pain_radiate_other_parts_body || null,
      which_side_pain_radiate_too: form?.forms?.pain_in_low_back?.which_side_pain_radiate_too || null,
      following_until_pain_interferes: {
        ...form?.forms?.pain_in_low_back?.following_until_pain_interferes,
        sit: form?.forms?.pain_in_low_back?.following_until_pain_interferes?.sit || null,
        stand: form?.forms?.pain_in_low_back?.following_until_pain_interferes?.stand || null,
        walk: form?.forms?.pain_in_low_back?.following_until_pain_interferes?.walk || null
      },
      is_low_back: !!form?.forms?.pain_in_low_back || false
    },
    middle_back: {
      ...form?.forms?.middle_back,
      does_the_pain_radiate_to: {
        ...form?.forms?.middle_back?.does_the_pain_radiate_to,
        parts: {
          shoulder_blades: form?.forms?.middle_back?.does_the_pain_radiate_to?.parts?.shoulder_blades || false,
          chest: form?.forms?.middle_back?.does_the_pain_radiate_to?.parts?.chest || false,
          other: form?.forms?.middle_back?.does_the_pain_radiate_to?.parts?.other || false
        },
        description: form?.forms?.middle_back?.does_the_pain_radiate_to?.description || null
      },
      what_severity_your_pain: form?.forms?.middle_back?.what_severity_your_pain || null,
      symptoms_increase_with_activity: form?.forms?.middle_back?.symptoms_increase_with_activity || null,
      symptoms_increase_while_twisting: form?.forms?.middle_back?.symptoms_increase_while_twisting || null,
      how_often_do_you_experience_the_pain: form?.forms?.middle_back?.how_often_do_you_experience_the_pain
        || null,
      is_middle_back: !!form?.forms?.middle_back || false
    }
  }
});
