import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Paper } from '@material-ui/core';
import { api } from '../../../../../../../api';
import * as invoicesApi from '../../../../../../../api/billing';
import * as types from '../../../InvoicesContext/types';
import { InvoicesHeaderContent } from '../../../../InvoicesHeaderContent';
import { InvoiceActionBar } from './InvoiceActionBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ isMobile, isTablet, caseItem, filterOpenButton }) => {
  const classes = useStyles();
  const [ counters, setCounters ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const invoicesLastGlobalAction = useSelector(({ globalActions }) => globalActions.invoicesLastGlobalAction);
  const cancelFetch = useRef(() => {});

  const getInvoiceCounter = () => {
    cancelFetch.current();
    setIsFetched(false);

    invoicesApi.getInvoicesCounters({
      params: { case_id: caseItem ? caseItem.id : null },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      setCounters(data);
      setIsFetched(true);
    });
  };

  useEffect(() => {
    getInvoiceCounter();

    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    if (invoicesLastGlobalAction) {
      if (
        invoicesLastGlobalAction.type === types.ADD_INVOICE ||
        invoicesLastGlobalAction.type === types.MASS_DELETE_INVOICES
      ) {
        getInvoiceCounter();
      }
    }
  }, [ invoicesLastGlobalAction ]);

  return (
    <Paper elevation={0} variant="outlined">
      {!isMobile &&
        <div className={classes.tabPanel}>
          <InvoicesHeaderContent isHovered isFetched={isFetched} counters={counters} />
        </div>
      }

      <div className={classes.tabPanelActions}>
        <InvoiceActionBar
          isMobile={isMobile}
          isTablet={isTablet}
          caseItem={caseItem}
          filterOpenButton={filterOpenButton}
        />
      </div>
    </Paper>
  );
};
