import { useContext, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { ModalBody, ModalHeader } from '../../../Modal';
import { useModal } from '../../../ModalsProvider';
import { Fab } from '../../../Fab';
import { RuleTemplatesContext } from '../RuleTemplatesProvider';
import { CreateRuleFormModal } from '../CreateRuleFormModal';
import { FiltersBar } from './FiltersBar';
import { Rules } from './Rules';

export const Body = ({ model_id, model_type, action = {}, onClose = () => {} }) => {
  const { resetRules, fetchRules } = useContext(RuleTemplatesContext);
  const { openModal } = useModal();

  const handleNewRule = () => {
    openModal(CreateRuleFormModal, {
      payload: {
        initialRule: {
          actions: [ action ],
          model_type,
          model_id
        }
      },
      onModalResolved: () => {
        fetchRules();
      }
    });
  };

  useEffect(() => {
    resetRules();
  }, []);

  return (
    <>
      <ModalHeader onClose={onClose}>
        <Box display="flex" alignItems="center">
          Rules

          {!hasRole(rolesMap.client, rolesMap.patient) && (
            <Box ml={3}>
              <Fab
                color="primary"
                variant="extended"
                onClick={handleNewRule}
                startIcon={<AddIcon />}
              >
                New rule
              </Fab>
            </Box>
          )}
        </Box>
      </ModalHeader>

      <ModalBody disablePaddings>
        <FiltersBar />

        <Box flexGrow={1}>
          <Rules />
        </Box>
      </ModalBody>
    </>
  );
};
