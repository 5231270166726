import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiCalendarEdit } from '@mdi/js';
import * as waitingListApi from '../../../../../api/appointments';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { FormBody } from '../CreateModal/FormBody';
import { validationSchema } from '../validationSchema';

export const EditModal = ({
  DialogProps,
  payload: {
    appointment = {}
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const duration = moment.duration(appointment?.time || 0, 'seconds');

  const updateWaitingListItem = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return waitingListApi.updateWaitingListItem({
      ...values,

      time: moment.duration(`${values._hours || 0}:${values._minutes || 0}`).asSeconds() || null
    }).then((data) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
        enqueueSnackbar('Item not created', { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      initialValues={{
        id: appointment.id,
        name: appointment.name,
        email: appointment.email,
        phone: appointment.phone,
        patient_id: appointment.patient || appointment?.patient_appointment_details?.patient,
        patient: appointment.patient || null,
        appointment_book_id: appointment.appointment_book,
        appointment_type_id: appointment.appointment_type,
        treating_physician_id: appointment?.patient_appointment_details?.treating_physician
          || appointment?.appointment_book?.provider,
        patient_insurance_id: appointment.patient_insurance,
        appointment_date: appointment.appointment_at
          ? moment.unix(appointment.appointment_at).format('YYYY-MM-DD')
          : null,
        appointment_time: appointment.appointment_at
          ? moment.unix(appointment.appointment_at).format('YYYY-MM-DD HH:mm:ss')
          : null,
        note: appointment.note || null,
        timezone: appointment.timezone,
        _hours: duration.hours() || null,
        _minutes: duration.minutes() || null
      }}
      validationSchema={validationSchema}
      onSubmit={updateWaitingListItem}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarEdit} /></SvgIcon>} onClose={handleModalReject}>
              Edit appointment
            </ModalHeader>

            <ModalBody>
              <FormBody
                appointmentDate={appointment?.appointment_at
                  ? moment.unix(appointment.appointment_at).format('YYYY-MM-DD')
                  : null
                }
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} disabled={isSubmitting}>
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
