import { Box, Grid, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { Contact } from '../../../../../../components/Contact';
import { Members } from '../../../../../../components/Members';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.name &&
        <>
          <Grid item xs={4}>
            <Typography>
              Office Name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.name}
            </Typography>
          </Grid>
        </>
      }

      {params?.company_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Company:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <CompanyLink
                noWrap
                variant="h5"
                company={params?.company_id}
              />
            </Box>
          </Grid>
        </>
      }

      {params?.users &&
        <>
          <Grid item xs={4}>
            <Typography>
              Users:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Members
              noWrap
              maxVisible={4}
              users={params?.users || []}
            />
          </Grid>
        </>
      }

      {params?.address &&
        <>
          <Grid item xs={4}>
            <Typography>
              Address:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.address}
            </Typography>
          </Grid>
        </>
      }

      {params?.email &&
        <>
          <Grid item xs={4}>
            <Typography>
              Email:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Contact
              variant="h5"
              color="primary"
              type="mailto"
              contact={params?.email}
            />
          </Grid>
        </>
      }
    </Grid>
  );
};
