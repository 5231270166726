import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { TextField } from '../../../../../../../../components/FormField';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import {
  motorStrengthMap,
  lumbarMotorStrengthMap,
  columnsWidthsLg,
  columnsWidthsSm
} from '../../../../../../../../components/medical/reports';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const MotorStrength = ({ isLumbarSpine = false, name }) => {
  const classes = useStyles();
  const keys = isLumbarSpine ? lumbarMotorStrengthMap : motorStrengthMap;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <Box mt={4}>
      <Typography variant="h4">
        Motor strenght-upper extremities
      </Typography>

      <div className={classes.root}>
        <ListRow header>
          <ListRowCell noWrap grow flexBasis={columnsWidths.name} />

          <ListRowCell flexBasis={columnsWidths.right}>
            <Typography variant="subtitle2" color="textSecondary">
              Right
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.left}>
            <Typography variant="subtitle2" color="textSecondary">
              Left
            </Typography>
          </ListRowCell>
        </ListRow>

        {Object.keys(keys)?.map((item, i) => (
          <ListRow key={i}>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
              <Typography noWrap variant="h5">
                {keys[item]}:
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.right}>
              <TextField
                fullWidth
                zeroMinWidth
                disableUnderline
                placeholder="Add..."
                name={`${name}.${item}.right`}
              />
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.left}>
              <TextField
                fullWidth
                zeroMinWidth
                disableUnderline
                placeholder="Add..."
                name={`${name}.${item}.left`}
              />
            </ListRowCell>
          </ListRow>
        ))}
      </div>
    </Box>
  );
};
