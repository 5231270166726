import { Box, Typography } from '@material-ui/core';
import { TextField } from '../../../../../../../components/FormField';

export const PastMedicalHistory = () => {
  return (
    <>
      <Box mt={3}>
        <Typography variant="h4">
          Past medical history
        </Typography>
      </Box>

      <TextField
        fullWidth
        multiline
        disableUnderline
        rows={2}
        name="forms.medical_form_info.past_medical_history"
        variant="filled"
      />
    </>
  );
};
