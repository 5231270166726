export const styles = ({ spacing, palette: { text }, typography: { pxToRem } }) => ({
  item: {
    padding: spacing(0.5, 1),
    color: text.primary
  },

  icon: {
    width: 30,
    height: 30
  },

  nameText: {
    fontSize: pxToRem(12),
    wordBreak: 'break-all'
  },

  time: {
    flex: 'none'
  },

  timeText: {
    fontSize: pxToRem(12)
  },

  size: {
    flex: 'none'
  },

  sizeText: {
    fontSize: pxToRem(12),
    textAlign: 'right'
  }
});
