import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as templatesApi from '../../api/cases/task-templates';
import { Loader } from '../Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../Modal';
import { TaskTemplateGroupsSelect } from './TaskTemplateGroupsSelect';
import { TaskTemplatesSelect } from './TaskTemplatesSelect';
import { validationSchema } from './validationSchema';

const initialValues = {
  case_id: null,
  templates: [],
  template_groups: []
};

export const ApplyTaskTemplateToCaseModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { initialValues: initialValuesProp  }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { enqueueSnackbar } = useSnackbar();

  const applyTemplate = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return templatesApi.applyTaskTemplate(values).then(() => {
      enqueueSnackbar('Templates successfully applied', { variant: 'success' });
      handleModalResolve();
    }).catch(({ data: { errors } }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Templates not applied', { variant: 'error' });
    });
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="xs" {...DialogProps}>
      <Formik
        initialValues={{ ...initialValues, ...initialValuesProp }}
        validationSchema={validationSchema}
        onSubmit={applyTemplate}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Apply task templates
            </ModalHeader>

            <ModalBody>
              <TaskTemplatesSelect
                multiple
                name="templates"
                margin="dense"
              />

              <TaskTemplateGroupsSelect
                multiple
                margin="dense"
                name="template_groups"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Apply
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
