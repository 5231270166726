import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { Date } from '../../../../../../../components/Date';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { orthoSliderMarks } from '../../FollowUp/sliderMarks';
import { PainContent } from '../FollowUpFormPreview/PainContent';
import { socialHistoryTypes } from '../PastMedicalHistory/socialHistoryTypes';

export const HistoryInjury = ({ history = {} }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography paragraph variant="h4" color="secondary">
          History of Injury
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          1. Please rate your pain on a scale of 1 to 10 (10 being the worst pain)
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <PainContent
          title={<Typography variant="h5">At rest:</Typography>}
          sliderValue={
            orthoSliderMarks?.find(({ label }) => (
              label === history?.please_rate_your_pain?.at_rest)
            )?.value || 0
          }
          marks={orthoSliderMarks}
        />
      </Grid>

      <Grid item xs={12}>
        <PainContent
          title={<Typography variant="h5">At worst:</Typography>}
          sliderValue={
            orthoSliderMarks?.find(({ label }) => (
              label === history?.please_rate_your_pain?.at_worst)
            )?.value || 0
          }
          marks={orthoSliderMarks}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography variant="h5">
          2. Is the pain:
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
          {!!history?.is_the_pain?.worsening && (
            <>
              <ReadonlyCheckbox checked title="Worsening"/>&nbsp;
            </>
          )}

          {!!history?.is_the_pain?.occasional && (
            <>
              <ReadonlyCheckbox checked title="Occasional"/>&nbsp;
            </>
          )}

          {!!history?.is_the_pain?.stabbing && (
            <>
              <ReadonlyCheckbox checked title="Stabbing" />&nbsp;
            </>
          )}

          {!!history?.is_the_pain?.stable && (
            <>
              <ReadonlyCheckbox checked title="Stable" />&nbsp;
            </>
          )}

          {!!history?.is_the_pain?.sharp && (
            <>
              <ReadonlyCheckbox checked title="Sharp" />&nbsp;
            </>
          )}

          {!!history?.is_the_pain?.throbbing && (
            <>
              <ReadonlyCheckbox checked title="Throbbing" />&nbsp;
            </>
          )}

          {!!history?.is_the_pain?.improving && (
            <>
              <ReadonlyCheckbox checked title="Improving" />&nbsp;
            </>
          )}

          {!!history?.is_the_pain?.dull && (
            <>
              <ReadonlyCheckbox checked title="Dull" />&nbsp;
            </>
          )}

          {!!history?.is_the_pain?.constant && (
            <>
              <ReadonlyCheckbox checked title="Constant" />&nbsp;
            </>
          )}

          {!!history?.is_the_pain?.aching && (
            <>
              <ReadonlyCheckbox checked title="Aching" />&nbsp;
            </>
          )}
        </Box>
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography variant="h5">
          3. What symptoms are you experiencing?
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
          {!!history?.what_symptoms_are_you_experiencing?.locking && (
            <>
              <ReadonlyCheckbox checked title="Locking" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_symptoms_are_you_experiencing?.catching && (
            <>
              <ReadonlyCheckbox checked title="Catching" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_symptoms_are_you_experiencing?.giving_away && (
            <>
              <ReadonlyCheckbox checked title="Giving away" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_symptoms_are_you_experiencing?.popping && (
            <>
              <ReadonlyCheckbox checked title="Popping" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_symptoms_are_you_experiencing?.grinding && (
            <>
              <ReadonlyCheckbox checked title="Grinding" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_symptoms_are_you_experiencing?.bruising && (
            <>
              <ReadonlyCheckbox checked title="Bruising" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_symptoms_are_you_experiencing?.numbness && (
            <>
              <ReadonlyCheckbox checked title="Numbness" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_symptoms_are_you_experiencing?.tingling && (
            <>
              <ReadonlyCheckbox checked title="Tingling" />&nbsp;&nbsp;
            </>
          )}


          {!!history?.what_symptoms_are_you_experiencing?.other?.value &&
            <Box display="flex" alignItems="flex-end" flexWrap="wrap" minWidth={300}>
              <Box minWidth={146}>
                <ReadonlyCheckbox checked title="Other" />
              </Box>

              <Box minWidth={300}>
                <Typography>
                  {history?.what_symptoms_are_you_experiencing?.other?.description}
                </Typography>
              </Box>
            </Box>
          }
        </Box>
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography variant="h5">
          4. What, if anything, makes your symptoms <u>better?</u>
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
          {!!history?.what_if_anything_makes_your_symptoms_better?.rest && (
            <>
              <ReadonlyCheckbox checked title="Rest" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_if_anything_makes_your_symptoms_better?.cold_therapy && (
            <>
              <ReadonlyCheckbox checked title="Cold Therapy" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_if_anything_makes_your_symptoms_better?.activity && (
            <>
              <ReadonlyCheckbox checked title="Activity" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_if_anything_makes_your_symptoms_better?.heat_therapy && (
            <>
              <ReadonlyCheckbox checked title="Heat Therapy" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_if_anything_makes_your_symptoms_better?.medication && (
            <>
              <ReadonlyCheckbox checked title="Medication" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_if_anything_makes_your_symptoms_better?.other?.value &&
            <Box display="flex" alignItems="flex-start" minWidth={300}>
              <Box minWidth={146}>
                <ReadonlyCheckbox checked title="Other" />
              </Box>

              <Box minWidth={300}>
                <Typography>
                  {history?.what_if_anything_makes_your_symptoms_better?.other?.description}
                </Typography>
              </Box>
            </Box>
          }
        </Box>
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography variant="h5">
          5. What, if anything, makes your symptoms <u>worse?</u>
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
          {!!history?.what_if_anything_makes_your_symptoms_worse?.inactivity && (
            <>
              <ReadonlyCheckbox checked title="Inactivity" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_if_anything_makes_your_symptoms_worse?.exercise?.value &&
            <Box display="flex" alignItems="flex-start" minWidth={300}>
              <Box minWidth={146}>
                <ReadonlyCheckbox checked title="Exercise (describe)" />
              </Box>

              <Box minWidth={300}>
                <Typography>
                  {history?.what_if_anything_makes_your_symptoms_worse?.exercise?.description}
                </Typography>
              </Box>
            </Box>
          }

          {!!history?.what_if_anything_makes_your_symptoms_worse?.other?.value &&
            <Box display="flex" alignItems="flex-start" flexWrap="wrap" minWidth={300}>
              <Box minWidth={146}>
                <ReadonlyCheckbox checked title="Other" />
              </Box>

              <Box minWidth={300}>
                <Typography>
                  {history?.what_if_anything_makes_your_symptoms_worse?.other?.description}
                </Typography>
              </Box>
            </Box>
          }
        </Box>
      </Grid>

      {history?.have_you_seen_another_physician_for_this_injury && (
        <>
          <Grid item md={4} sm={10} xs={12}>
            <Typography gutterBottom variant="h5">
              6. Have you seen another physician for this injury?
            </Typography>
          </Grid>

          <Grid item md={8} sm={2} xs={12}>
            <ReadonlyCheckbox
              checked
              title={
                history?.have_you_seen_another_physician_for_this_injury === socialHistoryTypes.yes
                  ? 'Yes' : 'No'
              }
            />
          </Grid>
        </>
      )}

      {!!history?.if_yes_who && (
        <>
          <Grid item md={4} xs={12}>
            <Box ml={2}>
              <Typography variant="h5">
                If yes, who?
              </Typography>
            </Box>
          </Grid>

          <Grid item md={8} xs={12}>
            <Typography>{history?.if_yes_who}</Typography>
          </Grid>
        </>
      )}

      <Grid item md={4} xs={12}>
        <Typography variant="h5">
          7. What treatments have you tried?
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          {!!history?.what_treatments_have_you_tried?.nothing && (
            <>
              <ReadonlyCheckbox checked title="Nothing" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_treatments_have_you_tried?.physical_therapy && (
            <>
              <ReadonlyCheckbox checked title="Physical therapy" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_treatments_have_you_tried?.exercise && (
            <>
              <ReadonlyCheckbox checked title="Exercise" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_treatments_have_you_tried?.acupuncture && (
            <>
              <ReadonlyCheckbox checked title="Acupuncture" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_treatments_have_you_tried?.decreased_activity && (
            <>
              <ReadonlyCheckbox checked title="Decreased activity" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_treatments_have_you_tried?.injections && (
            <>
              <ReadonlyCheckbox checked title="Injections" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_treatments_have_you_tried?.bracing && (
            <>
              <ReadonlyCheckbox checked title="Bracing" />&nbsp;&nbsp;
            </>
          )}

          {!!history?.what_treatments_have_you_tried?.medications?.value &&
            <Box display="flex" alignItems="flex-start" minWidth={300}>
              <Box minWidth={146}>
                <ReadonlyCheckbox checked title="Medications" />
              </Box>

              <Box minWidth={300}>
                <Typography>
                  {history?.what_treatments_have_you_tried?.medications?.description}
                </Typography>
              </Box>
            </Box>
          }

          {!!history?.what_treatments_have_you_tried?.chiropractic && (
            <>
              &nbsp;&nbsp;
              <ReadonlyCheckbox checked title="Chiropractic" />
              &nbsp;&nbsp;
            </>
          )}

          {!!history?.what_treatments_have_you_tried?.ice && (
            <>
              <ReadonlyCheckbox checked title="Ice" />&nbsp;&nbsp;
            </>
          )}


          {!!history?.what_treatments_have_you_tried?.other?.value &&
            <Box display="flex" alignItems="flex-start" minWidth={300}>
              <Box minWidth={146}>
                <ReadonlyCheckbox checked title="Other" />
              </Box>

              <Box minWidth={300}>
                <Typography>
                  {history?.what_treatments_have_you_tried?.other?.description}
                </Typography>
              </Box>
            </Box>
          }
        </Box>
      </Grid>


      {!!history?.have_you_had_any_of_the_following_tests_studies?.x_rays?.value && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">
              8. Have you had any of the following tests/studies?
            </Typography>
          </Grid>

          {!!history?.have_you_had_any_of_the_following_tests_studies?.x_rays?.value && (
            <Grid item md={2} xs={4}>
              <ReadonlyCheckbox
                title="X-rays"
                checked={!!history?.have_you_had_any_of_the_following_tests_studies?.x_rays?.value}
              />
            </Grid>
          )}

          <Grid item md={2} xs={8}>
            <Date
              disableMoment={!!history?.have_you_had_any_of_the_following_tests_studies?.x_rays?.date}
              date={history?.have_you_had_any_of_the_following_tests_studies?.x_rays?.date &&
                moment(history?.have_you_had_any_of_the_following_tests_studies?.x_rays?.date).format('L')
              }
            />
          </Grid>

          <Grid item md={7} xs={12}>
            <Typography>
              {history?.have_you_had_any_of_the_following_tests_studies?.x_rays?.what_facility}
            </Typography>
          </Grid>
        </>
      )}

      {!!history?.have_you_had_any_of_the_following_tests_studies?.mri_scan?.value && (
        <>
          <Grid item md={2} xs={4}>
            <ReadonlyCheckbox
              title="MRI scan"
              checked={!!history?.have_you_had_any_of_the_following_tests_studies?.mri_scan?.value}
            />
          </Grid>

          <Grid item md={2} xs={8}>
            <Date
              disableMoment={!!history?.have_you_had_any_of_the_following_tests_studies?.mri_scan?.date}
              date={history?.have_you_had_any_of_the_following_tests_studies?.mri_scan?.date &&
                moment(history?.have_you_had_any_of_the_following_tests_studies?.mri_scan?.date).format('L')
              }
            />
          </Grid>

          <Grid item md={7} xs={12}>
            <Typography>
              {history?.have_you_had_any_of_the_following_tests_studies?.mri_scan?.what_facility}
            </Typography>
          </Grid>
        </>
      )}

      {!!history?.have_you_had_any_of_the_following_tests_studies?.ct_scan?.value && (
        <>
          <Grid item md={2} xs={4}>
            <ReadonlyCheckbox
              title="CT scan"
              checked={!!history?.have_you_had_any_of_the_following_tests_studies?.ct_scan?.value}
            />
          </Grid>

          <Grid item md={2} xs={8}>
            <Date
              disableMoment={!!history?.have_you_had_any_of_the_following_tests_studies?.ct_scan?.date}
              date={history?.have_you_had_any_of_the_following_tests_studies?.ct_scan?.date &&
                moment(history?.have_you_had_any_of_the_following_tests_studies?.ct_scan?.date).format('L')
              }
            />
          </Grid>

          <Grid item md={7} xs={12}>
            <Typography>
              {history?.have_you_had_any_of_the_following_tests_studies?.ct_scan?.what_facility}
            </Typography>
          </Grid>
        </>
      )}

      {!!history?.have_you_had_any_of_the_following_tests_studies?.emgncv?.value && (
        <>
          <Grid item md={2} xs={4}>
            <ReadonlyCheckbox
              title="EMG/NCV"
              checked={!!history?.have_you_had_any_of_the_following_tests_studies?.emgncv?.value}
            />
          </Grid>

          <Grid item md={2} xs={8}>
            <Date
              disableMoment={!!history?.have_you_had_any_of_the_following_tests_studies?.emgncv?.date}
              date={history?.have_you_had_any_of_the_following_tests_studies?.emgncv?.date &&
                moment(history?.have_you_had_any_of_the_following_tests_studies?.emgncv?.date).format('L')
              }
            />
          </Grid>

          <Grid item md={7} xs={12}>
            <Typography>
              {history?.have_you_had_any_of_the_following_tests_studies?.emgncv?.what_facility}
            </Typography>
          </Grid>
        </>
      )}

      {!!history?.have_you_had_any_of_the_following_tests_studies?.other?.value && (
        <>
          <Grid item md={2} xs={4}>
            <ReadonlyCheckbox
              title="Other"
              checked={!!history?.have_you_had_any_of_the_following_tests_studies?.other?.value}
            />
          </Grid>

          <Grid item md={2} xs={8}>
            <Date
              disableMoment={!!history?.have_you_had_any_of_the_following_tests_studies?.other?.date}
              date={history?.have_you_had_any_of_the_following_tests_studies?.other?.date &&
              moment(history?.have_you_had_any_of_the_following_tests_studies?.other?.date).format('L')
              }
            />
          </Grid>

          <Grid item md={7} xs={12}>
            <Typography>
              {history?.have_you_had_any_of_the_following_tests_studies?.other?.what_facility}
            </Typography>
          </Grid>
        </>
      )}

      {!!history?.recreational_activities && (
        <>
          <Grid item md={4} xs={12}>
            <Typography variant="h5">Recreational activities:</Typography>
          </Grid>

          <Grid item md={8} xs={12}>
            <Typography>
              {history?.recreational_activities}
            </Typography>
          </Grid>
        </>
      )}

      {!!history?.current_regular_exercise_program && (
        <>
          <Grid item md={4} xs={12}>
            <Typography variant="h5">Current, regular exercise program (if any)</Typography>
          </Grid>

          <Grid item md={8} xs={12}>
            <Typography>
              {history?.current_regular_exercise_program}
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
};
