import { FieldArray, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import * as userApi from '../../../../../../../../api/profile';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../../../../components/Modal';
import { TextField } from '../../../../../../../../components/FormField';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../../components/Loader';
import { Fab } from '../../../../../../../../components/Fab';
import { validationSchema } from './validationSchema';

export const UpdateAllergiesModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { medicalID, allergies = [] }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const updateAllergies = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return userApi.addAllergies({ id: medicalID, ...values }).then(({ data }) => {
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      handleModalResolve(data?.allergies || []);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  return (
    <Formik
      initialValues={{ allergies }}
      validationSchema={validationSchema}
      onSubmit={updateAllergies}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Dialog {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <FieldArray
              name="allergies"
              render={({ remove, push }) => (
                <>
                  <ModalHeader onClose={handleModalReject}>
                    <Box color="info.main" display="flex" alignItems="center">
                      <Typography variant="h4" color="inherit">
                        Allergies
                      </Typography>

                      <Box component="span" ml={2}>
                        <Fab
                          size="small"
                          color="primary"
                          variant="extended"
                          startIcon={<AddIcon/>}
                          onClick={() => push()}
                        >
                          Add
                        </Fab>
                      </Box>
                    </Box>
                  </ModalHeader>

                  <ModalBody>
                    {values?.allergies?.map((allergy, index) => (
                      <Box key={index} display="flex" alignItems="center">
                        <Typography>{index + 1}.</Typography>

                        <Box flexGrow={1} ml={1}>
                          <TextField
                            fullWidth
                            autoFocus
                            placeholder="Allergy..."
                            name={`allergies.${index}`}
                            margin="dense"
                          />
                        </Box>

                        <IconButton
                          size="small"
                          color="error"
                          onClick={handleClearField(remove, index)}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                  </ModalBody>
                </>
              )}
            />

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
