export const styles = ({ spacing, typography: { fontWeightMedium } }) => ({
  formContent: {
    fontWeight: fontWeightMedium,
    marginRight: spacing(2)
  },

  content: {
    marginBottom: spacing(3)
  },

  content_row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },

  contentInfoTitle: {
    marginRight: spacing(3)
  }
});
