import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles, Typography } from '@material-ui/core';
import { getUserFullName, getUserNameAbbr } from '../../../../../../../../helpers/users';
import { preventDefault } from '../../../../../../../../helpers/dom';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Avatar } from '../../../../../../../../components/Avatar';
import { Popper } from '../../../../../../../../components/Popper';
import { styles } from '../Contact/styles';
import { UserInfo } from '../Contact/UserInfo';
import { ContactMenu } from '../ContactMenu';

const useStyles = makeStyles(styles);

export const Employer = ({ users, contacts, setInitialValues }) => {
  const classes = useStyles();

  return (users?.map((user, index) => (
    <div key={index} className={classes.root}>
      <div className={classes.content}>
        <Avatar
          size="xl"
          src={user?.avatar}
          alt={getUserNameAbbr(user)}
        />

        <div className={classes.contentInfo}>
          <div className={classes.content}>
            <Typography variant="h4">{getUserFullName(user)},</Typography>

            <Typography color="textSecondary" className={classes.contentUserInfo}>
              Employer
            </Typography>
          </div>

          <UserInfo user={user} />
        </div>
      </div>

      {setInitialValues && (
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={preventDefault(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <ContactMenu
              index={index}
              user={user}
              users={users}
              contacts={contacts}
              setInitialValues={setInitialValues}
              onClose={handleClose}
            />
          )}
        </Popper>
      )}
    </div>
  )));
};
