export const styles = ({ palette: { grey }, spacing, shadows, breakpoints }) => ({
  root: {
    height: '100%',
    padding: spacing(2),

    [breakpoints.down('sm')]: {
      padding: 0
    }
  },

  loader: {
    padding: spacing(2)
  },

  list: {
    background: grey[100]
  },

  draggableRow: {
    boxShadow: shadows[4]
  }
});
