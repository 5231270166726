import { useContext } from 'react';
import { Box } from '@material-ui/core';
import { FilePreviewContext } from '../../../FilePreviewContext';

// TODO: temporary solution until BE adds pdf url for .docs files
export const DOC = () => {
  const { selectedFile } = useContext(FilePreviewContext);

  return (
    <Box
      component="iframe"
      id="msdoc-iframe"
      title="msdoc-iframe"
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        selectedFile?.url
      )}`}
      width="100%"
      height="100%"
      frameBorder={0}
      border={0}
    />
  );
};
