import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { paymentMethodsMap } from '../../../dataMaps/paymentMethodsMap';
import { PaymentContent } from '../../appointments/AppointmentViewModal/PaymentContent';
import {
  DatePicker,
  Select,
  TextField,
  Autocomplete,
  CompaniesAutocomplete,
  CurrencyField
} from '../../FormField';
import { Contact } from '../../Contact';
import { Divider } from '../../Divider';
import { UserLink } from '../../UserLink';
import { Date } from '../../Date';
import { UsersSelect } from '../../users';
import { billingParticipantsTypesMap, billingParticipantsTypesOptions } from '../billingParticipantsTypesOptions';

export const Body = ({ patient, billingInfo }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleParticipantTypeChange = (participantFieldName) => () => {
    setFieldValue(participantFieldName, null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box color="info.main">
          <Typography paragraph variant="h5" color="inherit">Patient Info</Typography>
        </Box>
      </Grid>

      <Grid item sm={6} xs={12} container spacing={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Patient Name
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <UserLink
            disableLink
            size="sm"
            user={patient}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            DOB
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex">
            <Date date={patient?.birthday} />
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Pri. Phone
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            needIcon
            type="tel"
            contact={patient?.phone}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            MRN
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography>
            {patient?.medical?.med_rec || patient?.med_rec || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid item sm={6} xs={12} container spacing={2}>
        <PaymentContent billingInfo={billingInfo} />
      </Grid>

      <Grid item xs={12}>
        <Divider gutter={2} type="dashed" />
      </Grid>

      <Grid item sm={6} xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">Bill From</Typography>
        </Box>

        <Autocomplete
          required
          name="bill_from.type"
          label="Select participant type"
          options={billingParticipantsTypesOptions}
          onChange={handleParticipantTypeChange('bill_from.type')}
        />

        {values?.bill_from?.type === billingParticipantsTypesMap.user &&
          <UsersSelect
            required
            name="bill_from.id"
            label="Select user"
            margin="dense"
          />
        }

        {values?.bill_from?.type === billingParticipantsTypesMap.company &&
          <CompaniesAutocomplete
            required
            name="bill_from.id"
            label="Select company"
            margin="dense"
          />
        }
      </Grid>

      <Grid item sm={6} xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">Bill To</Typography>
        </Box>

        <Autocomplete
          required
          name="bill_to.type"
          label="Select participant type"
          options={billingParticipantsTypesOptions}
          onChange={handleParticipantTypeChange('bill_to.type')}
        />

        {values?.bill_to?.type === billingParticipantsTypesMap.user &&
          <UsersSelect
            required
            name="bill_to.id"
            label="Select user"
            margin="dense"
          />
        }

        {values?.bill_to?.type === billingParticipantsTypesMap.company &&
          <CompaniesAutocomplete
            name="bill_to.id"
            label="Select company"
            TextFieldProps={{ required: true, margin: 'dense' }}
          />
        }
      </Grid>

      <Grid item xs={12}>
        <Divider gutter={2} type="dashed" />
      </Grid>

      <Grid item sm={3} xs={12}>
        <Box pr={2}>
          <DatePicker
            required
            outputFormat="YYYY-MM-DD"
            name="date"
            label="Payment Date"
            margin="dense"
          />
        </Box>
      </Grid>

      <Grid item sm={3} xs={12}>
        <Box pr={2}>
          <DatePicker
            outputFormat="YYYY-MM-DD"
            name="due_date"
            label="Due On"
            margin="dense"
          />
        </Box>
      </Grid>

      <Grid item sm={4} xs={12}>
        <Select
          formattedValue
          name="method"
          label="Payment Method"
          options={paymentMethodsMap}
          TextFieldProps={{ required: !!values.amount, margin: 'dense' }}
        />
      </Grid>

      <Grid item sm={2} xs={12}>
        <CurrencyField
          required={!!values.method}
          name="amount"
          label="Amount"
          margin="dense"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={5}
          name="note"
          placeholder="Add notes"
          variant="filled"
          margin="dense"
          label={
            <Box display="flex" alignItems="center">
              <NotesIcon fontSize="small"/> Note
            </Box>
          }
        />
      </Grid>
    </Grid>
  );
};
