export const measureTypes = {
  m: 'm',
  feet: 'feet',
  kg: 'kg',
  lbs: 'lbs'
};

export const heightMap = [
  {
    value: measureTypes.m,
    label: measureTypes.m
  },
  {
    value: measureTypes.feet,
    label: measureTypes.feet
  }
];

export const weightMap = [
  {
    value: measureTypes.kg,
    label: measureTypes.kg
  },
  {
    value: measureTypes.lbs,
    label: measureTypes.lbs
  }
];
