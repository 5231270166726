import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DatePicker,
  ApptBooksSelect,
  NdcCodesSelect,
  InsurancesTypesSelect,
  OfficesLocationSelect,
  InsurancesSelect
} from '../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { IconComponent } from '../../../../../components/saved-filters';
import { saveFilter } from '../../../../../store/lastFilters';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { PrescriptionsContext } from '../PrescriptionsProvider';
import { initialValues } from './initialValues';
import { List } from './List';

const MODAL_WIDTH = 990;

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const { applyFilter } = useContext(PrescriptionsContext);
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleDatePickerChange = (name, transformer) => (date) => {
    const transformedDate = transformer?.(date) || date;

    applyFilter({ [name]: transformedDate });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleNdcChange = (code) => {
    applyFilter({ ndc_code_id: code?.id });
    setRelationsForFilter((state) => ({ ...state, ndc_code_id: code }));
  };

  const handleInsuranceTypeChange = (type) => {
    applyFilter({ insurance_type: type });
    setRelationsForFilter((state) => ({ ...state, insurance_type: type }));
  };

  const handleOfficeChange = (office) => {
    applyFilter({ office_id: office?.id });
    setRelationsForFilter((state) => ({ ...state, office_id: office }));
  };

  const handleApptBookChange = (option) => {
    applyFilter({ appointment_book_id: option?.id || null });
    setRelationsForFilter((state) => ({ ...state, appointment_book_id: option }));
  };

  const handleInsuranceSelect = (option) => {
    applyFilter({ 'insuranceId': option?.insuranceId });
    setRelationsForFilter((state) => ({ ...state, 'insuranceId': option }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);
    applyFilter({
      ...filter,

      ndc_code_id: filter?.ndc_code_id?.id,
      appointment_book_id: filter?.appointment_book_id?.id,
      office_id: filter?.office_id?.id,
      insurance_type: filter?.insurance_type?.value || filter?.insurance_type,
      insuranceId: filter?.insuranceId?.id
    });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    applySavedFilter({
      ...relationsForFilter,
      ...lastFilters
    });
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      hiddenFields={hiddenFields}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      onReset={() => {
        applySavedFilter(initialValues);
      }}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.ndcCodeId,
          label: filterFieldsLabels[filterFieldsMap.ndcCodeId],
          field: (
            <NdcCodesSelect
              name="ndc_code_id"
              label="Drug Name"
              onChange={handleNdcChange}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.prescribedFrom,
          label: filterFieldsLabels[filterFieldsMap.prescribedFrom],
          field: (
            <DatePicker
              clearable
              zeroMinWidth
              minWidth={140}
              name="prescribed_from"
              label="From"
              onChange={handleDatePickerChange('prescribed_from')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.prescribedTo,
          label: filterFieldsLabels[filterFieldsMap.prescribedTo],
          field: (
            <DatePicker
              clearable
              zeroMinWidth
              minWidth={140}
              name="prescribed_to"
              label="To"
              onChange={handleDatePickerChange('prescribed_to')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.appointmentBookId,
          label: filterFieldsLabels[filterFieldsMap.appointmentBookId],
          field: (
            <ApptBooksSelect
              name="appointment_book_id"
              onChange={handleApptBookChange}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.office,
          label: filterFieldsLabels[filterFieldsMap.office],
          field: (
            <OfficesLocationSelect
              name="office_id"
              onChange={handleOfficeChange}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.insuranceType,
          label: filterFieldsLabels[filterFieldsMap.insuranceType],
          field: (
            <InsurancesTypesSelect
              name="insurance_type"
              label="Insurance Type"
              margin="dense"
              params={{
                groups: [ 'patient' ]
              }}
              onChange={handleInsuranceTypeChange}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.insuranceId,
          label: filterFieldsLabels[filterFieldsMap.insuranceId],
          field: (
            <InsurancesSelect
              name="insuranceId"
              label="Insurance"
              onChange={handleInsuranceSelect}
            />
          )
        }
      ]}
    />
  );
};
