import { useContext, useState } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { ScheduleEventsFilterContext } from '../../../schedule-events';
import { Body } from './Body';
import { Header } from './Header';

export const HorizontalDatepickerFilter = () => {
  const { changeFilter } = useContext(ScheduleEventsFilterContext);
  const [ date, setDate ] = useState(moment());
  const [ start, setStart ] = useState(date.clone().startOf('week'));
  const [ end, setEnd ] = useState(date.clone().endOf('week'));

  const handleDateChange = (value) => {
    setDate(value);
    const started_from = value.startOf('day').unix();
    const started_to = value.endOf('day').unix();

    changeFilter({ started_from, started_to });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Header
        selectedDate={date}
        startDate={start}
        endDate={end}
        onStartUpdate={setStart}
        onEndUpdate={setEnd}
        onDateUpdate={handleDateChange}
      />

      <Body
        selectedDate={date}
        startDate={start}
        endDate={end}
        onStartUpdate={setStart}
        onEndUpdate={setEnd}
        onDateUpdate={handleDateChange}
      />
    </Box>
  );
};
