import { FieldArray, useFormikContext } from 'formik';
import { Typography, Box } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { IconButton } from '../../../../../../../components/IconButton';
import { TextField } from '../../../../../../../components/FormField';
import { Fab } from '../../../../../../../components/Fab';

export const Allergies = () => {
  const { values } = useFormikContext();
  const { openModal } = useModal();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  return (
    <Box my={3}>
      <FieldArray
        name="forms.medical_form_info.allergies"
        render={({ remove, push }) => (
          <>
            <Box mb={2} color="info.main" display="flex" alignItems="center">
              <Typography variant="h4" color="inherit">
                Allergies
              </Typography>

              <Box component="span" ml={2}>
                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon/>}
                  onClick={() => push({ name: '' })}
                >
                  Add
                </Fab>
              </Box>
            </Box>

            {values?.forms?.medical_form_info?.allergies?.map((contact, index) => (
              <Box key={index} display="flex" alignItems="center">
                <Typography>{index + 1}.</Typography>

                <Box maxWidth={300} ml={1}>
                  <TextField
                    fullWidth
                    placeholder="Allergy..."
                    name={`forms.medical_form_info.allergies.${index}.name`}
                    margin="dense"
                  />
                </Box>

                <IconButton
                  size="small"
                  color="error"
                  onClick={handleClearField(remove, index)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </>
        )}
      />
    </Box>
  );
};
