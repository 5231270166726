import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../../../../../helpers/hooks';
import { api } from '../../../../../../../api';
import * as caseRemindersApi from '../../../../../../../api/cases/reminders/cases';
import { Loader } from '../../../../../../../components/Loader';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export function RemindersStatusBar({ caseId }) {
  const classes = useStyles();
  const cancelFetch = useRef(() => {});
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const isVisible = !width || (width > 320);
  const [ statistics, setStatistics ] = useState();

  useEffect(() => {
    if (!caseId || !isVisible) {
      return;
    }

    caseRemindersApi.fetchCaseRemindersStatistics(caseId, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((statistics) => {
      const total = (
        statistics.expired_reminders
        + statistics.done_reminders
        + statistics.upcoming_reminders
        + statistics.undone_reminders
      );
      const expiredPercent = statistics.expired_reminders * 100 / total;
      const donePercent = statistics.done_reminders * 100 / total;
      const upcomingPercent = statistics.upcoming_reminders * 100 / total;
      const undonePercent = statistics.undone_reminders * 100 / total;

      setStatistics({
        ...statistics,

        expiredPercent,
        donePercent,
        upcomingPercent,
        undonePercent
      });
    });

    return () => {
      cancelFetch.current();
    };
  }, [ width, caseId ]);

  return (
    <div ref={rootRef}>
      {isVisible && (
        !statistics ? <Loader size={24} /> : !statistics.min_date ? (
          <Typography color="textSecondary">No reminders for that case</Typography>
        ) : (
          <>
            <div className={classes.bar}>
              <Tooltip
                title={
                  `Expired ${statistics.expired_reminders} (${statistics.expiredPercent.toFixed(2)}%)`
                }
              >
                <div className={classes.expired} style={{ width: `${statistics.expiredPercent}%` }}/>
              </Tooltip>

              <Tooltip
                title={
                  `Done ${statistics.done_reminders} (${statistics.donePercent.toFixed(2)}%)`
                }
              >
                <div className={classes.done} style={{ width: `${statistics.donePercent}%` }}/>
              </Tooltip>

              <Tooltip
                title={
                  `Upcoming ${statistics.upcoming_reminders} (${statistics.upcomingPercent.toFixed(2)}%)`
                }
              >
                <div className={classes.upcoming} style={{ width: `${statistics.upcomingPercent}%` }}/>
              </Tooltip>

              <Tooltip
                title={
                  `Undone ${statistics.undone_reminders} (${statistics.undonePercent.toFixed(2)}%)`
                }
              >
                <div className={classes.undone} style={{ width: `${statistics.undonePercent}%` }}/>
              </Tooltip>
            </div>

            <div className={classes.divider} />

            <div className={classes.dates}>
              <Tooltip title="First reminder">
                <Typography color="textSecondary">
                  {moment(statistics.min_date).format('L')}
                </Typography>
              </Tooltip>

              <Tooltip title="Today">
                <Typography color="textSecondary">
                  {moment().format('L')}
                </Typography>
              </Tooltip>

              <Tooltip title="Last reminder">
                <Typography color="textSecondary">
                  {moment(statistics.max_date).format('L')}
                </Typography>
              </Tooltip>
            </div>
          </>
        )
      )}
    </div>
  );
}
