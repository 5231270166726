import { ErrorMessage, useFormikContext } from 'formik';
import { get } from 'lodash';
import { List, makeStyles, Typography } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { useModal } from '../../../../../../components/ModalsProvider';
import { BodyPartsModal } from '../BodyPartsModal';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const BodyParts = () => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { values, setFieldValue } = useFormikContext();
  const stepTitle = get(values, 'tab_name', 'current_symptoms_pi');

  const handleOpenBodyPartsModal = () => {
    openModal(BodyPartsModal, {
      payload: {
        where_is_pain: get(values, `forms.${stepTitle}.where_is_pain`, {})
      },
      onModalResolved: (data) => {
        setFieldValue(`forms.${stepTitle}.where_is_pain`, data);
      }
    });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        Please mark the place in the image below where your pain begins and show where it is going, using the arrow:
      </Typography>

      <div className={classes.content}>
        <div className={classes.contentInfo} onClick={handleOpenBodyPartsModal}>
          <div className={classes.image}/>

          <div className={classes.actions}>
            <ZoomInIcon color="primary" fontSize="large" />
          </div>
        </div>

        <Typography color="error">
          <ErrorMessage name="forms.current_symptoms.where_is_pain" />
        </Typography>

        {!!Object.keys(get(values, `forms.${stepTitle}.where_is_pain`, [])).length &&
          <List disablePadding className={classes.list}>
            {Object.keys(values.forms[stepTitle].where_is_pain).map((item, i) => (
              <Row key={i} item={item} stepTitle={stepTitle} />
            ))}
          </List>
        }
      </div>
    </div>
  );
};
