import { useEffect, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { NestedList } from '../../../../../../../components/NestedList';
import { isEmptyDeep } from '../../../../../../../helpers/isEmptyDeep';
import { socialHistoryTypes } from '../PastMedicalHistory/socialHistoryTypes';
import { HistoryInjury } from './HistoryInjury';

export const PatientHistory = ({ isOpenSwitches, form }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const history = form?.forms?.patient_history || {};
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(history) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Patient History"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Grid container component={Box} p={isSmallScreen ? 1 : 3} spacing={3} alignItems="flex-start">
        <Grid item md={3} xs={12}>
          <Typography variant="h5">
            1. Have you attended any of the following?
          </Typography>
        </Grid>

        <Grid item md={9} xs={12}>
          <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="space-between">
            {!!history?.have_you_attended_any_of_the_following?.physical_therapy && (
              <ReadonlyCheckbox checked title="Physical Therapy" />
            )}

            {!!history?.have_you_attended_any_of_the_following?.chiropractor && (
              <ReadonlyCheckbox checked title="Chiropractor" />
            )}

            {!!history?.have_you_attended_any_of_the_following?.pool_therapy && (
              <ReadonlyCheckbox checked title="Pool Therapy" />
            )}

            {!!history?.have_you_attended_any_of_the_following?.acupuncture && (
              <ReadonlyCheckbox checked title="Acupuncture" />
            )}

            {!!history?.have_you_attended_any_of_the_following?.none && (
              <ReadonlyCheckbox checked title="None" />
            )}
          </Box>
        </Grid>

        {history?.how_often_and_when && (
          <>
            <Grid item md={3} xs={12}>
              <Box ml={2}>
                <Typography variant="h5">How Often and When?</Typography>
              </Box>
            </Grid>

            <Grid item md={9} xs={12}>
              <Typography>{history?.how_often_and_when}</Typography>
            </Grid>
          </>
        )}

        {history?.is_this_injury_related_to_the_accident && (
          <>
            <Grid item md={3} xs={12}>
              <Box ml={2}>
                <Typography variant="h5">Is this injury related to the accident?</Typography>
              </Box>
            </Grid>

            <Grid item md={9} xs={12}>
              <ReadonlyCheckbox
                checked
                title={history?.is_this_injury_related_to_the_accident === socialHistoryTypes.yes ? 'Yes' : 'No'}
              />
            </Grid>
          </>
        )}

        <Grid item md={6} xs={12}>
          <Typography variant="h5">
            2. Have you undergone any diagnostic testing (MRI, CT, EMG, LABS)?
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <ReadonlyCheckbox
            checked={!!history?.have_you_undergone_any_diagnostic_testing_mri_ct_emg_labs}
            title={history?.have_you_undergone_any_diagnostic_testing_mri_ct_emg_labs}
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <Typography variant="h5">
            3. Are you using any of the following:
          </Typography>
        </Grid>

        <Grid item md={9} xs={12}>
          <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="space-between">
            {!!history?.are_you_using_any_of_the_follwing?.bone_stimulator && (
              <ReadonlyCheckbox checked title="Bone Stimulator" />
            )}

            {!!history?.are_you_using_any_of_the_follwing?.tens_unit && (
              <ReadonlyCheckbox checked title="Tens Unit" />
            )}

            {!!history?.are_you_using_any_of_the_follwing?.cane && (
              <ReadonlyCheckbox checked title="Cane" />
            )}

            {!!history?.are_you_using_any_of_the_follwing?.walker && (
              <ReadonlyCheckbox checked title="Walker" />
            )}

            {!!history?.are_you_using_any_of_the_follwing?.other && (
              <ReadonlyCheckbox checked title="Other" />
            )}

            {!!history?.are_you_using_any_of_the_follwing?.none && (
              <ReadonlyCheckbox checked title="None" />
            )}
          </Box>
        </Grid>

        {!!history?.dominant_hand && (
          <>
            <Grid item md={3} xs={12}>
              <Typography variant="h5">
                4. Dominant hand
              </Typography>
            </Grid>

            <Grid item md={9} xs={12}>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <ReadonlyCheckbox
                  checked
                  title={history?.dominant_hand === 'right' ? 'Right' : 'Left'}
                />
              </Box>
            </Grid>
          </>
        )}

        {history?.what_body_parts_are_injured && (
          <>
            <Grid item md={3} xs={12}>
              <Box ml={2}>
                <Typography variant="h5">What body parts are injured?</Typography>
              </Box>
            </Grid>

            <Grid item md={9} xs={12}>
              <Typography>{history?.what_body_parts_are_injured}</Typography>
            </Grid>
          </>
        )}

        <HistoryInjury history={history} />
      </Grid>
    </NestedList>
  );
};
