import { useSnackbar } from 'notistack';
import { Dialog, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import * as notesApi from '../../../../../api/profile';
import { ModalBody, ModalContainer, ModalHeader, ModalHeaderActions } from '../../../../../components/Modal';
import { MedicalAttachments } from '../../../../../components/MedicalAttachments';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Loader } from '../../../../../components/Loader';
import { ReportsPreviewContext, ReportsPreviewProvider } from '../../ReportsPreviewProvider';
import { EditNoteModal } from '../EditNoteModal';
import { MainInfo } from './MainInfo';

export const PreviewNote = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { noteId, onNoteUpdate, onListUpdate }
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleUpdateNote = (initialValues, setInitialValues) => () => {
    openModal(EditNoteModal, {
      payload: { initialValues },
      onModalResolved: (data) => {
        setInitialValues(data);
        onNoteUpdate(data);
      }
    });
  };

  const handleDeleteNote = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        notesApi.deleteNote(noteId).then(() => {
          enqueueSnackbar('Item successfully deleted', { variant: 'success' });
          handleModalResolve();
        });
      }
    });
  };

  const fetchNote = () => {
    return notesApi.fetchNote(noteId);
  };

  return (
    <ReportsPreviewProvider fetchItem={fetchNote} onUpdate={onListUpdate}>
      <ReportsPreviewContext.Consumer>
        {({ isFetched, selectedItem, setSelectedItem }) => (
          <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
            <ModalContainer>
              <ModalHeader onClose={handleModalReject}>
                View Clinical Note

                <ModalHeaderActions disableGutters>
                  {!hasRole(rolesMap.client, rolesMap.patient) &&
                    <>
                      <Tooltip arrow title="Edit note">
                        <IconButton
                          color="primary"
                          onClick={handleUpdateNote(selectedItem, setSelectedItem)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip arrow title="Delete note">
                        <IconButton
                          color="error"
                          onClick={handleDeleteNote}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                </ModalHeaderActions>
              </ModalHeader>

              <Loader p={3} loading={!isFetched} render={
                () => (
                  <ModalBody>
                    <MainInfo />

                    <MedicalAttachments
                      owner_type="clinical_note"
                      owner_id={noteId}
                      files={selectedItem.media || []}
                    />
                  </ModalBody>
                )}
              />
            </ModalContainer>
          </Dialog>
        )}
      </ReportsPreviewContext.Consumer>
    </ReportsPreviewProvider>
  );
};
