import { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { IconButton } from '../../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Loader } from '../../../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell column flexBasis={columnsWidths.users}>
        {!params?.users_id?.length ? '-' : params?.users_id?.map((user) => (
          <Box display="flex" alignItems="center" alignContent="center" width="100%" key={user.id}>
            <UserLink
              noWrap
              size="sm"
              user={user}
            />
          </Box>
        ))}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.cases}>
        {params?.case_id?.name || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.company}>
        <CompanyLink
          noWrap
          variant="h5"
          company={params?.company_id}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

