import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { FormHeader } from '../../../../../../components/medical/forms';
import { injuryOrthoSteps as unconfirmedSteps } from '../../../../GuestMedicalProfile/FormProvider';
import {
  MedicalFormItemContext,
  injuryOrthoSteps,
  stepsTitle
} from '../../MedicalFormItemProvider';
import { getFormRoutes } from '../MedicalFormsProvider';
import { FormStepper } from '../FormStepper';
import { styles } from '../styles';
import {
  formStepComponents,
  formStepUnconfirmedComponents
} from './formStepComponents';

const useStyles = makeStyles(styles);

export const PersonalInjuryOrtho = () => {
  const classes = useStyles();
  const {
    fetchForm,
    form,
    type,
    isConfirmed,
    initialFormValue,
    completed,
    activeStep,
    setActiveStep,
    ...props
  } = useContext(MedicalFormItemContext);
  const params = useParams();
  const Component = isConfirmed
    ? formStepComponents[activeStep]
    : formStepUnconfirmedComponents[activeStep];

  useEffect(() => {
    if (!params.medicalFormID) {
      fetchForm({ id: null });
    }
  }, []);

  return (
    <div className={classes.formRoot}>
      <FormHeader
        form={form}
        type={type}
        activeStep={activeStep}
        initialFormValue={initialFormValue}
      />

      <FormStepper
        steps={isConfirmed ? injuryOrthoSteps : unconfirmedSteps}
        form={form}
        stepsTitle={stepsTitle}
        completed={completed}
        activeStep={activeStep}
        onActiveStepChange={setActiveStep}
        getFormRoutes={getFormRoutes}
        component={<Component /> }

        { ...props }
      />
    </div>
  );
};
