import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormControlLabel, Tooltip, useTheme } from '@material-ui/core';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import CropFreeIcon from '@material-ui/icons/CropFree';
import AddIcon from '@material-ui/icons/Add';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { Switch } from '../../../../../components/FormField';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import { AppointmentCreateModal } from '../../../../../components/appointments';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Fab } from '../../../../../components/Fab';
import { minWidthForListView } from '../Appointments';

export const Header = ({
  isFilterVisible = true,
  width,
  patient,
  filterOpenButton,
  onFilterVisibilityChange = () => {}
}) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const isTablet = width <= theme.breakpoints.values.tablet;
  const isTabletLg = width <= theme.breakpoints.values.tabletLg;

  const addAppointment = () => {
    openModal(AppointmentCreateModal, {
      payload: {
        initialValues: {
          patient,
          patient_id: patient,
          patient_appointment_details: { patient, ...patient },
          patient_insurance_id: patient?.medical?.main_insurance
        }
      }
    });
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const toggleFilterVisibility = () => {
    onFilterVisibilityChange();
  };

  return (
    <>
      {!isTabletLg &&
        <PageHeaderTitle>
          Appointments
        </PageHeaderTitle>
      }

      <PageHeaderActions startPosition>
        {!hasRole(rolesMap.client, rolesMap.patient) &&
          <>
            <Fab
              variant="extended"
              color="primary"
              size={isTabletLg ? 'small' : 'medium'}
              startIcon={<AddIcon/>}
              onClick={addAppointment}
            >
              {isTabletLg ? 'Add' : 'Add appointment'}
            </Fab>

            <Fab
              target="_blank"
              variant="extended"
              color="primary"
              size={isTabletLg ? 'small' : 'medium'}
              startIcon={<CropFreeIcon/>}
              component={RouterLink}
              to="/appointment-check-in"
            >
              {isTabletLg ? 'Check In' : 'Check In appointment'}
            </Fab>

            <Fab
              target="_blank"
              variant="extended"
              color="primary"
              size={isTabletLg ? 'small' : 'medium'}
              startIcon={<FormatListBulletedIcon/>}
              component={RouterLink}
              to="/appointments-scoreboard"
            >
              Scoreboard
            </Fab>
          </>
        }

        {!isTabletLg &&
          <FormControlLabel
            label={isFilterVisible ? 'Hide filters' : 'Show filters'}
            control={
              <Switch
                checked={isFilterVisible}
                color="primary"
                onChange={toggleFilterVisibility}
              />
            }
          />
        }
      </PageHeaderActions>

      <PageHeaderActions disableSpacing>
        {!isTablet &&
          <>
            {width >= minWidthForListView &&
              <Tooltip title="List view">
                <IconButton
                  color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                  onClick={toggleViewVariant(viewVariantsMap.list)}
                >
                  <ViewListIcon />
                </IconButton>
              </Tooltip>
            }

            <Tooltip title="Grid view">
              <IconButton
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Calendar view">
              <IconButton
                edge={!isTabletLg && 'end'}
                color={viewVariant === viewVariantsMap.calendar ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.calendar)}
              >
                <EventNoteOutlinedIcon/>
              </IconButton>
            </Tooltip>
          </>
        }

        {isTabletLg && filterOpenButton}
      </PageHeaderActions>
    </>
  );
};
