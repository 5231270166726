import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../helpers/dom';
import { hasRole } from '../../../../../utils/hasRole';
import { CurrencyFormat } from '../../../../../components/CurrencyFormat';
import { CardHeader, CardItem } from '../../../../../components/Cards';
import { CompanyLink } from '../../../../../components/CompanyLink';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Tooltip } from '../../../../../components/Tooltip';
import { Label } from '../../../../../components/Label';
import { CaseViewModal } from '../../../CasesPage/Cases/CaseViewModal';
import { statusTypesColors, statusTypesNames } from '../../../Members';
import { CategoriesContext } from '../CategoriesProvider';

export const Card = ({ item: category = {} }) => {
  const {
    updateCategory,
    deleteCategory,
    activateCategory,
    deactivateCategory
  } = useContext(CategoriesContext);
  const { openModal } = useModal();
  const [ time, setTime ] = useState('');
  const categoryRate = ~~(category.rate / 100);

  const openPreview = () => {
    openModal(CaseViewModal, {
      payload: { id: category?.case?.id }
    });
  };

  const handleCategoryDelete = () => {
    deleteCategory(category.id);
  };

  const handleCategoryDeactivate = () => {
    deactivateCategory(category);
  };

  const handleCategoryActivate = () => {
    activateCategory(category);
  };

  const caseProps = !category?.case?.file_number ? {
    component: Link,
    onClick: stopPropagation(openPreview)
  } : {};

  useEffect(() => {
    setTime(`${moment.duration(category.time, 'seconds').format('h[h] m[min]')}`);
  }, [ category ]);

  return (
    <CardItem px={2} py={1}>
      <CardHeader
        pb={1}
        disableCheckBox
        disableCardMenu
        alignItems="flex-start"
        icon={hasRole(rolesMap.admin, rolesMap.supervisor) && (
          <>
            <IconButton edge="end" color="primary" onClick={updateCategory(category)}>
              <EditIcon />
            </IconButton>

            <Tooltip title={category.is_active ? 'Deactivate category' : 'Activate category'}>
              <IconButton
                variant="contained"
                color={category.is_active ? 'success' : 'warning'}
                onClick={category.is_active ? handleCategoryDeactivate : handleCategoryActivate}
              >
                {category.is_active ? <LockOpenIcon /> : <LockIcon />}
              </IconButton>
            </Tooltip>

            <IconButton color="error" onClick={handleCategoryDelete}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      >
        <Typography noWrap variant="h5">
          {category.name}
        </Typography>
      </CardHeader>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Label
            color={statusTypesColors[+category.is_active]}
            label={statusTypesNames[+category.is_active]}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap>Case</Typography>
        </Grid>

        <Grid item xs={8}>
          {category?.case?.id ?
            <>
              <Box display="flex" alignItems="center">
                <WorkIcon color="secondary" />

                <Box ml={1} overflow="hidden" textOverflow="ellipsis">
                  {category?.case?.name && (
                    <Typography
                      noWrap
                      variant="body2"
                      color="textSecondary"
                      {...caseProps}
                    >
                      {category?.case?.name}
                    </Typography>
                  )}

                  {category?.case?.file_number && (
                    <Typography
                      noWrap
                      color="textPrimary"
                      component={Link}
                      onClick={stopPropagation(openPreview)}
                    >
                      {category?.case?.file_number}
                    </Typography>
                  )}
                </Box>
              </Box>
            </>
            :
            <Typography color="textSecondary">No info.</Typography>
          }
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap>Company</Typography>
        </Grid>

        <Grid item xs={8}>
          <CompanyLink
            variant="h5"
            company={category?.company}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap>Time</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">{time}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap>Rate</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {categoryRate
              ? <CurrencyFormat value={categoryRate} />
              : 'n/a'
            }
          </Typography>
        </Grid>
      </Grid>
    </CardItem>
  );
};
