export const styles = ({
  spacing,
  palette: { grey },
  typography: { pxToRem },
  shadows
}) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: spacing(2),
    borderBottom: `1px solid ${grey[300]}`
  },

  logoAvatar: {
    width: pxToRem(80),
    height: pxToRem(80),
    fontSize: pxToRem(30),
    boxShadow: shadows[2]
  },

  header: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    paddingRight: spacing(2),
    paddingLeft: spacing(2)
  },

  actionIcon: {
    marginLeft: 'auto',
    marginRight: -spacing(),
    marginTop: -spacing(),
    color: grey[400]
  },

  actionButton: {
    minWidth: 64,
    marginLeft: 'auto'
  }
});
