import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_OFFICES_REQUEST]: (state) => {
    return {
      ...state,

      isFetching: true
    };
  },

  [types.FETCH_OFFICES_SUCCESS]: (
    { filter, offices, ...state },
    { data: newOffice, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      offices: page > 1
        ? offices.filter(({ id }) => !newOffice.find((loadOffice) => id === loadOffice.id)).concat(newOffice)
        : newOffice
    };
  },

  [types.ADD_OFFICE]: ({ offices, ...state }, payload) => {
    return {
      ...state,

      offices: [ payload, ...offices ]
    };
  },

  [types.UPDATE_OFFICE]: (state, payload) => {
    return {
      ...state,

      offices: state.offices.map((item) => {
        return item.id === payload.id ? payload : item;
      })
    };
  },

  [types.DELETE_OFFICE]: ({ filter, pagination, offices, ...state }, officeId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      offices: offices.filter((office) => office.id !== officeId)
    };
  },

  [types.RESET_OFFICES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter },
      selectedIDs: []
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.TOGGLE_ALL_ITEMS_SELECTION]: ({ offices, selectedIDs, ...state }) => {
    const allItemsIsSelected = offices.length === selectedIDs.length;

    return {
      ...state,

      offices,
      selectedIDs: allItemsIsSelected ? [] : offices.map((item) => item.id)
    };
  },

  [types.TOGGLE_ITEM_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1
        ? selectedIDs.filter((item) => item !== id)
        : selectedIDs.concat(id)
    };
  },

  [types.MASS_DELETE]: ({ offices, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      offices: offices.filter((office) => IDs.indexOf(office.id) < 0)
    };
  }
});
