import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiAccountConvert } from '@mdi/js';
import { Box, Button, SvgIcon, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import AddIcon from '@material-ui/icons/Add';
import * as usersApi from '../../../../../../api/users';
import { hasRole } from '../../../../../../utils/hasRole';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { setCasesLastGlobalAction } from '../../../../../../store/globalActions';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { IconButton } from '../../../../../../components/IconButton';
import { useModal } from '../../../../../../components/ModalsProvider';
import { PageHeaderActions } from '../../../../../../components/Page';
import { ReplaceCaseUserModal } from '../../ReplaceCaseUserModal';
import { CasesContext } from '../../CasesContext';
import * as types from '../../CasesContext/types';

export const ActionsBar = ({ filterOpenButton }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const {
    filter,
    selectedCases,
    selectedCasesIDs,
    deleteCasesFromSelected
  } = useContext(CasesContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const updateCases = (data) => {
    usersApi.fetchUser(data.to_user_id).then((updatedUser) => {
      selectedCases.forEach((selectedCase) => {
        selectedCase.case_users.map((caseUserItem) => {
          if (caseUserItem?.user?.id === data.from_user_id) {
            caseUserItem.user = updatedUser;
            return caseUserItem;
          } else {
            return caseUserItem;
          }
        });

        dispatch(setCasesLastGlobalAction({ type: types.UPDATE_CASE_IN_LIST, payload: selectedCase }));
      });
    });
  };

  const replaceUser = () => {
    openModal(ReplaceCaseUserModal, {
      payload: {
        selectedCases
      },
      onModalResolved: (data) => {
        updateCases(data);
        deleteCasesFromSelected(selectedCasesIDs);
      }
    });
  };

  return (
    <>
      {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.doctor, rolesMap.medic) &&
        <PageHeaderActions disablePosition>
          <Button
            size={isDownMd ? 'small' : 'medium'}
            component={Link}
            variant="contained"
            color="primary"
            to="/cases/create"
            startIcon={<AddIcon />}
          >
            Add new case
          </Button>
        </PageHeaderActions>
      }

      <PageHeaderActions disableSpacing endPosition>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignContent="center"
          alignItems="center"
          mt={(isDownMd && (selectedCasesIDs.length > 0)) ? 1 : 0}
        >
          {(selectedCasesIDs.length > 0) &&
            <>
              <Typography>
                {selectedCasesIDs.length} out of {filter.total} selected
              </Typography>

              <VerticalDivider verticalGutters={1} horizontalGutters={2} />

              <Tooltip arrow title="Replace case users">
                <IconButton edge="start" onClick={replaceUser}>
                  <SvgIcon>
                    <Icon path={mdiAccountConvert} />
                  </SvgIcon>
                </IconButton>
              </Tooltip>

              <VerticalDivider verticalGutters={1} horizontalGutters={1} />
            </>
          }

          {isDownMd && filterOpenButton}

          {!isDownMd &&
            <>
              <Tooltip arrow title="List view">
                <IconButton
                  color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                  onClick={toggleViewVariant(viewVariantsMap.list)}
                >
                  <ViewListIcon />
                </IconButton>
              </Tooltip>

              <Tooltip arrow title="Grid view">
                <IconButton
                  edge="end"
                  color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                  onClick={toggleViewVariant(viewVariantsMap.grid)}
                >
                  <ViewModuleIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        </Box>
      </PageHeaderActions>
    </>
  );
};
