import { useContext } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import cn from 'classnames';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { DatePicker } from '../../../../../../../../components/FormField';
import { StatisticsIncomesFilterContext } from '../../StatisticsIncomesFilterContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FiltersBar = ({
  message,
  setMessage,
  isOpenPicker,
  isOpenYearPicker,
  setIsOpenPicker,
  setIsOpenYearPicker
}) => {
  const classes = useStyles();
  const { filter, setFilter } = useContext(StatisticsIncomesFilterContext);

  const getMessageTitle = (date, name) => (
    name === 'isoWeek' ? `Week of ${moment.unix(date).format('LL')}` :
      moment.unix(date).format('YYYY')
  );

  const handleChange = (name) => (value) => {
    if (value) {
      const from = moment.unix(value).startOf(name).unix();
      const to = moment.unix(value).endOf(name).unix();

      setMessage(getMessageTitle(value, name));
      setFilter((filter) => ({ ...filter, from, to, period: name === 'isoWeek' ? 'week' : name }));
    }
  };

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const start = moment(selectedDate).clone().startOf('isoWeek');
    const end = moment(selectedDate).clone().endOf('isoWeek');

    const dayIsBetween = date.isBetween(start, end, 'day', '[]');
    const isFirstDay = date.isSame(start, 'day');
    const isLastDay = date.isSame(end, 'day');

    const wrapperClassName = cn({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });

    const dayClassName = cn(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {date.format('D')} </span>
        </IconButton>
      </div>
    );
  };

  const formatWeekSelectLabel = (date, invalidLabel) => {
    if (date === null) {
      return '';
    }

    return date ? `Week of ${moment(date).format('LL')}` : invalidLabel;
  };

  const handleOpenPicker = (callback) => () => callback(true);

  const handleClosePicker = (callback) => () => callback(false);

  return (
    <div className={classes.filtersBar}>
      <Formik
        onSubmit={null}
        initialValues={{
          week: filter.from,
          year: moment().unix()
        }}
      >
        {() => (
          <>
            <DatePicker
              open={isOpenPicker}
              name="week"
              label="Select Week"
              renderDay={renderWrappedWeekDay}
              labelFunc={formatWeekSelectLabel}
              onChange={handleChange('isoWeek')}
              onClose={handleClosePicker(setIsOpenPicker)}
              className={classes.datePicker}
            />

            <DatePicker
              open={isOpenYearPicker}
              name="year"
              views={[ 'year' ]}
              minDate={moment().subtract(10, 'years').unix()}
              onChange={handleChange('year')}
              onClose={handleClosePicker(setIsOpenYearPicker)}
              className={classes.datePicker}
            />

            <div className={classes.actionButton}>
              <Typography className={classes.actionButton__title}>
                {message}
              </Typography>

              <ToggleButtonGroup
                exclusive
                size="small"
                value={filter.period}
              >
                <ToggleButton
                  value="week"
                  onClick={handleOpenPicker(setIsOpenPicker)}
                >
                  Week
                </ToggleButton>

                <ToggleButton
                  value="year"
                  onClick={handleOpenPicker(setIsOpenYearPicker)}
                >
                  Year
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
