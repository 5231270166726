import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { fieldsKeysMap, filterLabels } from '../../filterKeysMap';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell noWrap flexBasis={columnsWidths.company}>
        {filterLabels[fieldsKeysMap.insuranceCompanyId]}
      </ListRowCell>

      <ListRowCell grow noWrap flexBasis={columnsWidths.insuranceId}>
        {filterLabels[fieldsKeysMap.insuranceId]}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.groupId}>
        {filterLabels[fieldsKeysMap.groupId]}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.statusType}>
        {filterLabels[fieldsKeysMap.statusType]}
      </ListRowCell>

      <ListRowCell grow noWrap flexBasis={columnsWidths.typeInsurance}>
        {filterLabels[fieldsKeysMap.typeInsurance]}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.expirationDay}>
        {filterLabels[fieldsKeysMap.expirationDay]}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
