export const styles = ({ spacing, palette }) => ({
  root: {
    padding: spacing(0.5, 1, 0.5, 0.5)
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  rootError: {
    border: `1px solid ${palette.error.main}`
  },

  title: {
    flexGrow: 1
  }
});
