import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Dialog, Box, useTheme, useMediaQuery } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import * as paymentApi from '../../../../api/billing';
import { InvoicesList } from '../../../../app/Dashboard/BillingPage/Payments/PaymentItem/InvoicesList';
import { TableHeader } from '../../../../app/Dashboard/BillingPage/Payments/PaymentItem/TableHeader';
import { Header } from '../../../../app/Dashboard/BillingPage/Payments/PaymentItem/Header';
import { ModalBody, ModalContainer, ModalHeader } from '../../../Modal';
import { Loader } from '../../../Loader';
import { InvoiceCard } from './InvoiceCard';

export const PaymentModal = ({
  DialogProps,
  handleModalReject,
  payload: {
    initialValues = {}
  }
}) => {
  const [ payment, setPayment ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setIsFetched(false);

    paymentApi.fetchPayment(initialValues.id).then((data) => {
      setPayment(data);
      setIsFetched(true);
    });
  }, []);

  return(
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <Loader loading={!isFetched} p={3} render={
        () => (
          <ModalContainer>
            <ModalHeader disableTypography onClose={handleModalReject}>
              <Typography variant="h2">{payment.number}</Typography>
            </ModalHeader>

            <ModalBody>
              <Box display="flex" alignItems="center">
                <Box component="span" mr={2}>
                  <Typography variant="h5">Inv. #</Typography>
                </Box>

                {payment?.invoice?.number ?
                  <Typography
                    variant="h5"
                    target="_blank"
                    color="textSecondary"
                    component={Link}
                    to={`/billing/invoices/${payment?.invoice?.id}`}
                  >
                    {payment?.invoice?.number}
                  </Typography>
                  :
                  <Typography color="textSecondary">No info.</Typography>
                }
              </Box>

              <Header
                disableSpacing
                amount={payment.amount}
                method={payment.method}
                payment={payment}
                invoice={payment.invoice || payment.causer_invoice || null}
              />

              {isMobile ? (
                <InvoiceCard
                  payment={payment}
                  disableInvoicePreview={initialValues?.disableInvoicePreview}
                />
              ) : (
                <>
                  <TableHeader disableInvoicePreview={initialValues?.disableInvoicePreview} />
                  <InvoicesList
                    payment={payment}
                    disableInvoicePreview={initialValues?.disableInvoicePreview}
                  />
                </>
              )}

              <Box my={2}>
                <Box display="flex" alignItems="center" mt={1}>
                  <Box mr={0.5} fontWeight={500}>
                    <NotesIcon fontSize="small" />
                  </Box>

                  <Typography variant="h5">Note: </Typography>
                </Box>

                {payment.note
                  ? <Typography>{payment.note}</Typography>
                  : <Typography color="textSecondary">No info.</Typography>
                }
              </Box>
            </ModalBody>
          </ModalContainer>
        )}
      />
    </Dialog>
  );
};
