import { useContext } from 'react';
import { Checkbox, Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { TasksContext } from '../../../../../../components/tasks/TasksProvider';
import { columnsWidths } from '../List';

export const TableHeader = ({ isTabPage, tasks }) => {
  const { selectedTasksIDs, allTasksIsSelected, toggleAllTasksSelection } = useContext(TasksContext);

  return (
    <Paper elevation={0}>
      {!!tasks.length &&
        <ListRow header>
          <ListRowCell>
            <Checkbox
              edge="start"
              checked={allTasksIsSelected()}
              indeterminate={!!selectedTasksIDs.length && !allTasksIsSelected()}
              onClick={toggleAllTasksSelection}
            />
          </ListRowCell>

          <ListRowCell grow flexBasis={columnsWidths.name}>
            Name/Description
          </ListRowCell>

          <ListRowCell grow flexBasis={columnsWidths.parent}>
            Case
          </ListRowCell>

          <ListRowCell grow flexBasis={columnsWidths.type}>
            Type
          </ListRowCell>

          {!isTabPage &&
            <ListRowCell flexBasis={columnsWidths.status}>
              Status
            </ListRowCell>
          }

          <ListRowCell flexBasis={columnsWidths.progress}>
            Progress
          </ListRowCell>

          {!isTabPage &&
            <ListRowCell flexBasis={ columnsWidths.activity }>
              Activity date
            </ListRowCell>
          }

          <ListRowCell flexBasis={columnsWidths.activity}>
            Due date
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.assigned}>
            Members
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.priority}>
            Priority
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.action}/>
        </ListRow>
      }
    </Paper>
  );
};
