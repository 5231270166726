export const columnsMap = {
  checkbox: 'checkbox',
  name: 'name',
  sharedUsers: 'sharedUsers',
  owner: 'owner',
  creator: 'creator',
  pages: 'pages',
  size: 'size',
  date: 'date',
  action: 'action'
};

export const columnsWidths = {
  [columnsMap.checkbox]: 48,
  [columnsMap.name]: 270,
  [columnsMap.sharedUsers]: 80,
  [columnsMap.owner]: 130,
  [columnsMap.creator]: 180,
  [columnsMap.size]: 90,
  [columnsMap.date]: 160,
  [columnsMap.action]: 40
};

export const mobileColumnWidths = {
  [columnsMap.name]: 80,
  [columnsMap.creator]: 100,
  [columnsMap.date]: 100,
  [columnsMap.action]: 40
};
