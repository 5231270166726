import { useParams } from 'react-router-dom';
import { columnsMap } from '../../../../../../components/claims';
import { ClaimsPage } from '../../../../BillingPage/ClaimsPage';
import { filterFieldsMap } from '../../../../BillingPage/ClaimsPage/Claims/FiltersBar/filterFieldsMap';

export const Claims = () => {
  const { userID } = useParams();

  return (
    <ClaimsPage
      hiddenColumns={[
        columnsMap.patient,
        columnsMap.caseNo,
        columnsMap.totalAmount
      ]}
      hiddenFilterFields={[
        filterFieldsMap.patients
      ]}
      initialFilter={{
        patients: [ userID ]
      }}
    />
  );
};
