import { api } from '../../api';

export const fetchFinancialClasses = (config) => {
  return api.get('/procedure-codes/financial-class', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchFinancialClass = (id, config) => {
  return api.get(`/procedure-codes/financial-class/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createFinancialClass = (data) => {
  return api.post('/procedure-codes/financial-class/', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateFinancialClass = ({ id, ...data }) => {
  return api.put(`/procedure-codes/financial-class/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteFinancialClass = (id) => {
  return api.delete(`/procedure-codes/financial-class/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchClaimFilingIndicators = (config) => {
  return api.get('/procedure-codes/claim-filing-indicator', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massActiveFinancialClass = (IDs) => {
  return api.post('/procedure-codes/financial-class/active', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massInactiveFinancialClass = (IDs) => {
  return api.post('/procedure-codes/financial-class/inactive', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteFinancialClasses = (IDs) => {
  return api.delete('/procedure-codes/financial-class', { params: { id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
