import { Typography } from '@material-ui/core';

export const Text = ({ before = null, after = null, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Added&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;-&nbsp;
        <Typography variant="subtitle1">{after}</Typography>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Typography variant="subtitle1">{before}</Typography>
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Typography variant="subtitle1">{before}</Typography>
        &nbsp;to&nbsp;
        <Typography variant="subtitle1">{after}</Typography>
      </>
    );
  } else {
    return (
      <>Empty&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>&nbsp;option</>
    );
  }
};
