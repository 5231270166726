import { useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../MainContent';

export const TableHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ListRow header px={isMobile ? 1 : 2}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        Code
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.description}>
        Description
      </ListRowCell>
    </ListRow>
  );
};
