import { useContext, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core';
import * as statisticsApi from '../../../../../../../api/billing';
import { api } from '../../../../../../../api';
import { Loader } from '../../../../../../../components/Loader';
import { initialState, StatisticsFilterContext } from '../../../StatisticsFilterProvider';
import { InvoicesContextProvider } from '../../../../Invoices/InvoicesContext';
import { FilteredMainContent } from '../../BillableTimeCard/FilteredMainContent';
import { Invoices } from '../../OverdueBills/MainContent/Invoices';
import { filterTitle } from '../filterTitleMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = ({
  isFetchedData,
  filterType,
  setFilterType,
  setIsFetchedData
}) => {
  const classes = useStyles();
  const [ state, setState ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const [ filter ] = useState(initialState.filter);
  const { filter: commonFilter } = useContext(StatisticsFilterContext);
  const unbilledFilter = 'Unbilled Balance';
  const cancelFetch = useRef(() => {});

  const initialInvoicesState = {
    filter: {
      filter_type: filterTitle[filterType],

      ...commonFilter
    }
  };

  const initialUnbilledFilter = {
    filter: {
      ...commonFilter
    }
  };

  const getChartData = () => ({
    labels: state.labels.map((item) => (
      item.split('_').map((i) => i.charAt(0).toUpperCase() + i.slice(1)).join(' ')
    )),
    datasets: [
      {
        backgroundColor: [
          'rgba(29, 215, 126, 1)',
          'rgba(0, 188, 212, 1)',
          'rgba(218, 73, 91, 1)',
          'rgba(253, 188, 0, 1)'
        ],
        hoverBackgroundColor: [
          'rgba(29, 215, 126, 0.8)',
          'rgba(0, 188, 212, 0.8)',
          'rgba(218, 73, 91, 0.8)',
          'rgba(253, 188, 0, 0.8)'
        ],
        fill: false,
        data: state.data
      }
    ]
  });

  const chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      xAxes: [ {
        ticks: {
          beginAtZero: true,
          callback: (label) => {
            label = new Intl.NumberFormat('en-US').format(label);

            return label;
          }
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0.2)',
          drawOnChartArea: false
        }
      } ],
      yAxes: [ {
        ticks: {
          mirror: true,
          padding: -10,
          z: 1,
          callback: (label, index, labels) => {
            labels[2] = 'Operating Retainer';
            labels[3] = 'Trust retainer / Balance Due';
            return label;
          }
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0.2)',
          borderDash: [ 2, 3 ]
        }
      } ]
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem) => ' $ ' + new Intl.NumberFormat('en-US').format(tooltipItem.value),
        title: (tooltipItem) => {
          if (tooltipItem[0].index === 3) {
            return tooltipItem[0].yLabel = 'Trust retainer / Balance Due';
          }

          if (tooltipItem[0].index === 2) {
            return tooltipItem[0].yLabel = 'Operating Retainer';
          }

          return tooltipItem[0].yLabel;
        }
      }
    }
  };

  const handleClickOnChart = (event) => {
    const label = !!event.length && event[0]._view.label;

    if (label) {
      setFilterType(label);
      setIsFetchedData(true);
    }
  };

  const fetchStatistics = (newFilter = {}) => {
    cancelFetch.current();
    setIsFetched(false);

    statisticsApi.fetchBalancesStatistics({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    })
      .then((data) => {
        setState(data);
        setIsFetched(true);
      });
  };

  const resetStatistics = (newFilter) => {
    fetchStatistics({ ...newFilter });
  };

  useEffect(() => {
    if (!isEqual(filter, { ...filter, ...commonFilter })) {
      resetStatistics({ ...filter, ...commonFilter });
    }
  }, [ filter, commonFilter ]);

  useEffect(() => {
    resetStatistics();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    isFetchedData ? (
      filterType === unbilledFilter ?
        <FilteredMainContent initialFilter={initialUnbilledFilter}/>
        :
        <InvoicesContextProvider initialState={initialInvoicesState}>
          <Invoices/>
        </InvoicesContextProvider>
    )
      :
      <Loader loading={!isFetched} className={classes.loader} render={
        () => (
          <HorizontalBar
            data={getChartData()}
            options={chartOptions}
            getElementsAtEvent={handleClickOnChart}
          />
        )}
      />
  );
};
