export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const UPDATE_TEMPLATE_IN_LIST = 'UPDATE_TEMPLATE_IN_LIST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const RESET_TEMPLATES = 'RESET_TEMPLATES';
export const APPLY_FILTER = 'APPLY_FILTER';
export const MASS_DELETE_TEMPLATES = 'MASS_DELETE_TEMPLATES';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
