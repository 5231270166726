import { useRef, useContext, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { InfiniteGridLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { FragmentsContext } from '../FragmentsContext';
import { FragmentsCard } from './FragmentsCard';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FragmentsGrid = () => {
  const {
    fragments,
    isFetched,
    isFetching,
    loadNextPage,
    filter: { last_page, page },
    ...fragmentsContext
  } = useContext(FragmentsContext);
  const classes = useStyles();

  const scrollElementRef = useRef();

  useEffect(() => {
    fragmentsContext.fetchFragments();
  }, []);

  return (
    <div ref={scrollElementRef} className={classes.root}>
      <Loader loading={!isFetched} render={
        () => (
          <>
            {!fragments.length &&
              <Typography align="center">
                Here will be some information from video fragments
              </Typography>
            }

            {!!fragments.length &&
              <InfiniteGridLoader
                items={fragments}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={{ last_page, page }}
                CellComponent={FragmentsCard}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
                cellWidth={200}
                cellHeight={200}
              />
            }
          </>
        )}
      />
    </div>
  );
};
