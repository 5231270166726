import { stringToRGBColor } from '../../helpers/stringToRGBColor';

export const styles = ({ palette, typography: { pxToRem, fontWeightMedium } }) => ({
  root: {
    fontWeight: fontWeightMedium,
    backgroundColor: ({ string }) => stringToRGBColor(string),
    color: palette.common.white,
    textDecoration: 'none',
    textAlign: 'center',

    '&.MuiChip-avatar': {
      color: palette.common.white
    }
  },

  avatar: {
    backgroundColor: palette.grey[400]
  },

  xs: {
    width: 22,
    height: 22,
    fontSize: pxToRem(10)
  },

  sm: {
    width: 26,
    height: 26,
    fontSize: pxToRem(14)
  },

  md: {
    width: 30,
    height: 30,
    fontSize: pxToRem(14)
  },

  lg: {
    width: 34,
    height: 34,
    fontSize: pxToRem(17)
  },

  xl: {
    width: 48,
    height: 48,
    fontSize: pxToRem(24)
  },

  xxl: {
    width: 65,
    height: 65,
    fontSize: pxToRem(32)
  },

  xxxl: {
    width: 90,
    height: 90,
    fontSize: pxToRem(48)
  },

  extraLarge: {
    width: 110,
    height: 110,
    fontSize: pxToRem(55)
  }
});
