import { forwardRef } from 'react';
import cn from 'classnames';
import T from 'prop-types';
import { makeStyles, Avatar as MuiAvatar } from '@material-ui/core';
import { mdiAccountOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { SvgIcon } from '../SvgIcon';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const sizesMap = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  xxxl: 'xxxl',
  extraLarge: 'extraLarge'
};

const propTypes = {
  children: T.node,
  src: T.string,
  alt: T.string,
  size: T.oneOf(Object.values(sizesMap))
};

export const Avatar = forwardRef(({
  children,
  src,
  size = 'md',
  alt,
  className,
  ...props
}, ref) => {
  const classes = useStyles({ string: children || alt });

  return alt ? (
    <MuiAvatar
      ref={ref}
      src={src}
      alt={alt}
      className={cn(classes.root, classes[size], className)}
      {...props}
    >
      {children || (src ? children : alt)}
    </MuiAvatar>
  ) : (
    <MuiAvatar
      ref={ref}
      className={cn(classes.avatar, classes[size], className)}
      {...props}
    >
      <SvgIcon><Icon path={mdiAccountOutline} /></SvgIcon>
    </MuiAvatar>
  );
});

Avatar.propTypes = propTypes;
