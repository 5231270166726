import { useSelector } from 'react-redux';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { UserLink } from '../../UserLink';
import {
  ModalContainer,
  ModalHeader,
  ModalBody
} from '../../Modal';
import { FormBody } from '../ReplyForm/FormBody';

export const ReplyModal = ({
  payload: {
    comment,
    parentType = null,
    parentCommentID,
    recipientCommentID,
    onCancel,
    replayComment
  },
  DialogProps,
  handleModalReject,
  handleModalResolve
}) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const onReply = async (values, actions) => {
    await replayComment(values, actions);
    await handleModalResolve();
  };

  const handleReplyCancel = (callback = () => {}) => {
    onCancel(callback);
    handleModalReject();
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          <UserLink variant="h5" color="textPrimary" user={currentUser} />
        </ModalHeader>

        <ModalBody disablePaddings={isMobile}>
          <FormBody
            parentType={parentType}
            comment={comment}
            parentCommentID={parentCommentID}
            recipientCommentID={recipientCommentID}
            onCancel={handleReplyCancel}
            replayComment={onReply}
          />
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
