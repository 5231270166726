import { forwardRef, useEffect } from 'react';
import { CKEditor } from 'ckeditor4-react';
import { useTheme, makeStyles } from '@material-ui/core';
import { getEditorStyles } from './getEditorStyles';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const DocumentEditor = forwardRef(({
  paperSize,
  paperMargins,
  onStylesReady = () => {},
  onBeforeLoad,

  ...props
}, ref) => {
  const theme = useTheme();
  const classes = useStyles();
  const css = getEditorStyles({ theme, paperSize, paperMargins });

  const handleBeforeLoad = (CKEDITOR) => {
    CKEDITOR.disableAutoInline = true;

    CKEDITOR.on('instanceCreated', () => {
      CKEDITOR.addCss(css);
    });

    onBeforeLoad(CKEDITOR);
  };

  const handleInstanceReady = ({ editor }) => {
    if (ref) {
      ref.current = editor;
    }
  };

  useEffect(() => {
    onStylesReady(css);
  }, []);

  return (
    <div className={classes.root}>
      <CKEditor
        editor={ref}
        editorUrl="https://cdn.ckeditor.com/4.22.1/full-all/ckeditor.js"
        config={{
          versionCheck: false,
          startupFocus: true,
          toolbar: [
            { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
            { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
            // eslint-disable-next-line max-len
            { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting' ] },
            { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
            { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
            { name: 'links', items: [ 'Link', 'Unlink' ] },
            // eslint-disable-next-line max-len
            { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ] },
            { name: 'insert', items: [ 'Image', 'Table' ] },
            { name: 'tools', items: [ 'Maximize' ] },
            { name: 'editing', items: [ 'Scayt' ] },
            { name: 'pagebreak  ', items: [ 'PageBreak' ] }
          ],
          extraPlugins: 'replaceable-data-type, tableresize, pagebreak',
          contentsCss: []
        }}
        onBeforeLoad={handleBeforeLoad}
        onInstanceReady={handleInstanceReady}

        {...props}
      />
    </div>
  );
});
