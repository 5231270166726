import { memo } from 'react';
import { isEqual } from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';

const RGL = WidthProvider(Responsive);

export const ResponsiveGridLayout = memo((props) => {
  return (
    <RGL draggableHandle="[rgl-handle]" {...props} />
  );
}, isEqual);
