import { createContext, useRef, useEffect, useReducer, useState } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { api } from '../../../api';
import { downloadFile as download } from '../../../helpers/files';
import { usePrevious } from '../../../helpers/hooks';
import * as callReportsApi from '../../../api/call-reports';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CallReportsContext = createContext();

export const CallReportsProvider = ({
  filter: filterProp = {},
  disableCauser = false,
  children
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    callReports,
    selectedCallReportsIDs,
    selectedCallReports
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});
  const [ isFetchingReport, setIsFetchingReport ] = useState(false);

  const fetchCallReports = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_CALL_REPORTS_REQUEST });

    callReportsApi.fetchCallReports({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_CALL_REPORTS_SUCCESS, payload: data });
    });
  };

  const generateReport = () => {
    const { page, per_page, ...params } = filter;

    if (!filter?.date_from || !filter?.date_to) {
      return enqueueSnackbar('Please select date filters', { variant: 'error' });
    }

    const diff = moment(filter?.date_to).diff(moment(filter?.date_from), 'months', true);

    if (diff > 1) {
      return enqueueSnackbar('Dates range should be not more than a month', { variant: 'error' });
    }

    setIsFetchingReport(true);

    callReportsApi.generateCallReport({ params }).then((data) => {
      download({
        src: 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data),
        name: `report ${moment().format('L LT')}.csv`
      });
    }).finally(() => setIsFetchingReport(false));
  };

  const generateGeneralReport = () => {
    setIsFetchingReport(true);

    const { page, per_page, ...params } = filter;

    callReportsApi.generateGeneralCallReport({ params }).then((data) => {
      download({
        src: 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data),
        name: `general report ${moment().format('L LT')}.csv`
      });
    }).finally(() => setIsFetchingReport(false));
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchCallReports({ page: filter.page + 1 });
    }
  };

  const resetCallReports = (newFilter) => {
    dispatch({ type: types.RESET_CALL_REPORTS, payload: newFilter });

    fetchCallReports({ page: 1, ...newFilter });
  };

  const deleteParent = (values) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        callReportsApi.dettachParent({ ...filterProp, ...values }).then(() => {
          deleteCallReportsFromSelected(selectedCallReportsIDs);
          resetCallReports();
        }).catch((error) => {
          if (error) {
            enqueueSnackbar('Not deleted', { variant: 'error' });
          }
        });
      }
    });
  };

  const addCallReportsToSelected = (callReports) => {
    dispatch({ type: types.ADD_CALL_REPORTS_TO_SELECTED, payload: callReports });
  };

  const deleteCallReportsFromSelected = (callReportsIDs) => {
    dispatch({ type: types.DELETE_CALL_REPORTS_FROM_SELECTED, payload: callReportsIDs });
  };

  const toggleCallReportSelected = (callReport) => {
    if (selectedCallReportsIDs.indexOf(callReport.id) === -1) addCallReportsToSelected([ callReport ]);
    else deleteCallReportsFromSelected([ callReport.id ]);
  };

  const allCallReportsIsSelected = () => {
    return selectedCallReportsIDs
      .filter((id) => callReports.find((callReport) => callReport.id === id))
      .length === callReports.length;
  };

  const toggleAllCallReportsSelected = () => {
    const callReportsIDs = callReports.map(({ id }) => id);

    if (allCallReportsIsSelected()) {
      deleteCallReportsFromSelected(callReportsIDs);
    } else {
      addCallReportsToSelected(callReports);
    }
  };

  const deleteCallReport = (callReport) => {
    dispatch({ type: types.DELETE_CALL_REPORT, payload: callReport.id });
  };

  const providerValue = {
    isFetched,
    isFetchingReport,
    defaultFilter: filterProp,
    isFetching,
    callReports,
    disableCauser,
    selectedCallReportsIDs,
    selectedCallReports,
    filter: filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    resetCallReports,
    fetchCallReports,
    loadNextPage,
    applyFilter,
    deleteParent,
    generateReport,
    deleteCallReport,
    generateGeneralReport,
    toggleCallReportSelected,
    deleteCallReportsFromSelected,
    allCallReportsIsSelected,
    toggleAllCallReportsSelected
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetCallReports(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CallReportsContext.Provider value={providerValue}>
      {children}
    </CallReportsContext.Provider>
  );
};
