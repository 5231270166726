import { useContext, useEffect } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../Loader';
import { TasksContext } from '../../../TasksProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 54;
export const columnsWidths = {
  name: 280,
  parent: 120,
  status: 100,
  type: 140,
  progress: 160,
  activity: 120,
  assigned: 200,
  priority: 100
};

export const List = ({ onClose }) => {
  const { tasks, isFetched, resetTasks } = useContext(TasksContext);

  useEffect(() => {
    resetTasks();
  }, []);

  return (
    <Box p={!tasks.length ? 3 : 0}>
      <Loader loading={!isFetched} render={
        () => (!tasks.length ?
          <Typography align="center">No tasks found</Typography>
          :
          <>
            <TableHeader tasks={tasks} />

            <MuiList disablePadding>
              {tasks.map((task) => (
                <Row key={task.id} task={task} onClose={onClose} />
              ))}
            </MuiList>
          </>
        )}
      />
    </Box>
  );
};
