import { FinancialClass } from './FinancialClass';
import { PayerContent } from './PayerContent';

export const routes = [
  {
    exact: true,
    pathName: '/financial-class',
    label: 'Financial Class',
    component: FinancialClass
  },
  {
    exact: true,
    pathName: '/payer',
    label: 'Payer',
    component: PayerContent
  }
];
