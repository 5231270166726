import { Formik } from 'formik';
import { Dialog, Button } from '@material-ui/core';
import { Yup } from '../../../utils/validation';
import { OfficesLocationSelect } from '../../../components/FormField';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../components/Modal';

export const SelectOfficeModal = ({
  DialogProps,
  handleModalResolve
}) => {
  const handleSubmit = ({ office }) => {
    handleModalResolve(office);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"

      {...DialogProps}
    >
      <Formik
        initialValues={{
          office: null
        }}
        validationSchema={Yup.object().shape({
          office: Yup.mixed().nullable().required()
        })}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader>
              Select office
            </ModalHeader>

            <ModalBody>
              <OfficesLocationSelect
                formattedValue={false}
                name="office"
                params={{ is_medical: 1 }}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Apply
              </Button>
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
