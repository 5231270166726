import { useContext, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { ColorPreview } from '../../../../../../../../components/ColorPreview';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../../components/UserLink';
import { Loader } from '../../../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell noWrap flexBasis={columnsWidths.search}>
        {params?.search || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.providers}>
        <Box display="flex" flexDirection="column" width="100%" alignItems="center">
          {!params?.providers?.length ? '-' : params?.providers?.map((provider) => (
            <Box key={provider.id} display="flex" width="100%" alignItems="center">
              <UserLink
                noWrap
                size="sm"
                user={provider}
              />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.appointmentTypes}>
        <Box display="flex" flexDirection="column" width="100%">
          {!params?.appointment_types?.length ? '-' : params?.appointment_types.map((appointmentType) => (
            <Box key={appointmentType.id} display="flex" alignItems="center">
              <Box display="flex" alignItems="center" mr={0.25}>
                <ColorPreview color={appointmentType.color} />
              </Box>

              <Typography noWrap>
                {appointmentType.code}
              </Typography>
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        {params?.is_active?.label || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.orderBy}>
        {params?.order_by.label || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
