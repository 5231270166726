export const styles = ({ spacing }) => ({
  root: {
    width: '100%'
  },

  form: {
    paddingBottom: spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    opacity: 1
  },

  cancelButton: {
    marginRight: spacing(1.5)
  }
});
