import { useFormikContext } from 'formik';
import {
  Box,
  FormControlLabel,
  Grid,
  Hidden,
  Typography
} from '@material-ui/core';
import {
  AddressAutocomplete,
  Checkbox,
  PhoneField,
  TextField,
  UnitDesignator
} from '../../../../components/FormField';
import { UsersSelect } from '../../../../components/users';
import { getAddress } from '../../../../utils/generateAddress';

export const BillingAddress = ({ disableBillingCheckbox = false, disableContactPerson = false }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddressChange = (option) => {
    if (option) {
      setFieldValue('billing.street', (option.street_number ? option.street_number + ' ' : '') + option.street);
      setFieldValue('billing.state', option.state);
      setFieldValue('billing.city', option.city);
      setFieldValue('billing.zip', option.zip);
      setFieldValue('billing.country', option.country);
      setFieldValue('billing.lat', option.lat);
      setFieldValue('billing.lng', option.lng);
    }
  };

  const handleAddressIsSameChange = (isMainAddress = false) => (event) => {
    const checked = event.target.checked;
    const address = isMainAddress ? values?.company_id?.data?.billing : values;

    if (!isMainAddress && checked) {
      setFieldValue('is_same_address', false);
    }

    if (isMainAddress && checked) {
      setFieldValue('is_same', false);
    }

    setFieldValue('billing', checked ? getAddress(address) : getAddress());
  };

  return (
    <Box mb={7}>
      <Typography gutterBottom variant="h3">Billing Address</Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            label={<Typography>Billing Address Same as Address</Typography>}
            control={<Checkbox name="is_same" onChange={handleAddressIsSameChange()} />}
          />

          {!disableBillingCheckbox && (
            <FormControlLabel
              label={<Typography>Billing Address Same as Main Office Billing Address</Typography>}
              control={<Checkbox name="is_same_address" onChange={handleAddressIsSameChange(true)} />}
            />
          )}
        </Grid>


        <Grid item sm={6} xs={12}>
          <AddressAutocomplete
            name="billing.street"
            label="Street"
            onChange={handleAddressChange}
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <UnitDesignator
            required={!!values.billing?.unit_number}
            name="billing.designator"
            label="Unit Designator"
            placeholder="Select designator..."
          />
        </Grid>

        <Grid item sm={3} xs={6}>
          <TextField
            zeroMinWidth
            name="billing.unit_number"
            label="Apt./Suite/Room"
          />
        </Grid>

        <Grid item sm={4} xs={8}>
          <TextField
            zeroMinWidth
            name="billing.city"
            label="City"
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <TextField
            zeroMinWidth
            name="billing.state"
            label="State/Region"
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <TextField
            zeroMinWidth
            name="billing.zip"
            label="ZIP Code"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            zeroMinWidth
            name="billing.country"
            label="Country"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} component={Box} pt={5}>
        <Grid item sm={4} xs={12}>
          <PhoneField
            zeroMinWidth
            name="billing.phone"
            label="Phone"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <PhoneField
            zeroMinWidth
            name="billing.fax"
            label="Fax"
          />
        </Grid>

        <Hidden only={[ 'sm', 'xs' ]}>
          <Grid item sm={4} />
        </Hidden>

        <Grid item sm={4} xs={12}>
          <PhoneField
            zeroMinWidth
            name="billing.mobile_phone"
            label="Mobile Phone"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            zeroMinWidth
            name="billing.email"
            label="Email"
          />
        </Grid>

        {!disableContactPerson &&
          <Grid item sm={4} xs={12}>
            <UsersSelect
              params={{
                company_id: values?.company_id
              }}
              name="billing_contact_id"
              label="Billing Contact Person"
            />
          </Grid>
        }
      </Grid>
    </Box>
  );
};
