import { Route, Switch, Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink, Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { breadcrumbs } from './breadcrumbsMap';

const Crumbs = ({ match }) => {
  const replaceParamsToValues = (path) => {
    return Object.keys(match.params).reduce((path, param) => {
      return path.replace(`:${param}`, match.params[param]);
    }, path);
  };

  const crumbs = breadcrumbs
    .filter(({ path }) => match.path.indexOf(path) === 0)
    .map(({ path, ...rest }) => ({
      path: Object.keys(match.params).length ? replaceParamsToValues(path) : path,
      ...rest
    }));

  return (
    <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {crumbs.map(({ path, name }) => (
        <MuiLink component={RouterLink} color="inherit" to={path} key={path}>
          {name}
        </MuiLink>
      ))}
    </MuiBreadcrumbs>
  );
};

export const Breadcrumbs = () => {
  return (
    <Switch>
      {breadcrumbs.map(({ path }) => (
        <Route key={path} exact path={path} component={Crumbs} />
      ))}
    </Switch>
  );
};
