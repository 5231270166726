import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { Contact } from '../../../../../components/Contact';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.date &&
        <>
          <Grid item xs={4}>
            <Typography>
              Date:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {moment(params.date).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.to &&
        <>
          <Grid item xs={4}>
            <Typography>
              Recipient:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Contact noWrap variant="h5" type="tel" contact={params?.to} />
          </Grid>
        </>
      }
    </Grid>
  );
};
