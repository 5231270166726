import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import { filtersKeysMap } from '../../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { LayoutContextProvider, viewVariantsMap } from '../../../../LayoutContext';
import { ScheduleProvider } from '../../../../schedule-events';
import {
  FiltersBar,
  ScheduleEventsFilterProvider
} from '../../../../schedule-events';
import { columnsMap } from '../../../../schedule-events/ScheduleEventsList/columns';
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalHeaderActions
} from '../../../../Modal';
import { ScheduleEventsModalContent } from './ScheduleEventsModalContent';

const hiddenColumns = [
  columnsMap?.action
];

const ListRowProps = {
  button: true,
  px: 2
};

export const ScheduleEventsFieldModal = ({
  payload: {
    isMulti = false,
    filter = {},
    initialSelectedEventsIDs = [],
    hiddenFilterFields,
    tasksFilterParams
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filterOpenButton,
    openFiltersBar,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps: initialScrollWrapperProps
  } = useMobileFilterCollapse();
  const scrollWrapperProps = !isTablet ? {} : {
    ...initialScrollWrapperProps,
    autoHeight: true,
    style: !isMobile && isTablet && openFiltersBar ? {
      minHeight: 200
    } : isMobile && openFiltersBar ? {
      minHeight: 300
    } : {}
  };

  return (
    <Dialog fullScreen={isTablet} maxWidth="lg" PaperProps={{ style: { maxWidth: 1420 } }} {...DialogProps}>
      <ModalContainer>
        <ModalHeader
          icon={<CalendarIcon />}
          onClose={handleModalReject}
        >
          Schedule Events

          {isTablet && (
            <ModalHeaderActions disableGutters>
              {filterOpenButton}
            </ModalHeaderActions>
          )}
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <LayoutContextProvider initialViewVariant={viewVariantsMap.list}>
            <ScheduleEventsFilterProvider filter={filter} filterKey={filtersKeysMap.schedule_events_field}>
              <ScheduleProvider initialSelectedEventsIDs={initialSelectedEventsIDs}>
                <ScrollWrapper {...scrollWrapperProps}>
                  <FilterBarWrapper {...filterBarWrapperProps}>
                    <FiltersBar
                      filterKey={filtersKeysMap.schedule_events_field}
                      hiddenFields={hiddenFilterFields}
                      tasksFilterParams={tasksFilterParams}
                    />
                  </FilterBarWrapper>
                </ScrollWrapper>

                <ScheduleEventsModalContent
                  isMulti={isMulti}
                  handleModalResolve={handleModalResolve}
                  handleModalReject={handleModalReject}
                  hiddenColumns={hiddenColumns}
                  ListRowProps={ListRowProps}
                />
              </ScheduleProvider>
            </ScheduleEventsFilterProvider>
          </LayoutContextProvider>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
