import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';
import { labelsMap } from './labelsMap';

export const Chart = ({ data, labels }) => {
  const { palette } = useTheme();

  const getChartData = () => ({
    labels,
    datasets: [
      {
        backgroundColor: [
          palette.grey[300],
          palette.purple,
          palette.pink,
          palette.lightorange,
          palette.amberyellow
        ],
        hoverBackgroundColor: [
          palette.grey[300],
          palette.purple,
          palette.pink,
          palette.lightorange,
          palette.amberyellow
        ],
        data
      }
    ]
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend:{
      display: false
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem, data) => {
          const dataSets = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const labels = data.labels[tooltipItem.index];

          return labelsMap[labels] + ' $ ' + new Intl.NumberFormat('en-US').format(dataSets);
        }
      }
    }
  };

  return (
    <Doughnut options={chartOptions} data={getChartData()} />
  );
};
