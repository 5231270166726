import { useSelector } from 'react-redux';
import { Autocomplete } from '../../Autocomplete';

export const transformTimeZoneToOption = (tz) => tz && ({
  value: tz,
  label: tz
});

export const TimeZoneSelect = (props) => {
  const timezones = useSelector((store) => store.apiConfig.config.timezones).map(transformTimeZoneToOption);

  return (
    <Autocomplete
      label="Time zone"
      placeholder="Search time zone..."
      options={timezones}

      {...props}
    />
  );
};
