import { Box, Typography, SvgIcon } from '@material-ui/core';
import CalendarSvg from './next-appt-calendar.svg';

export const NextAppointmentEmptyContent = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      m="0 auto"
      textAlign="center"
      p={2}
    >
      <Box maxWidth={450}>
        <Typography gutterBottom variant="h4">
          Here you will see information and status of the last event
        </Typography>

        <Box component="span" color="info.main" width={390}>
          <Typography variant="h4" color="inherit">
            To Add a New Appt., in the Medical info, click on the
            ‘+ Add’ button in this widget.
          </Typography>
        </Box>
      </Box>

      <Box color="text.secondary" fontSize={95}>
        <SvgIcon color="inherit" fontSize="inherit"><CalendarSvg /></SvgIcon>
      </Box>

      <Typography color="textSecondary">
        You can see here the date and time of the event, what type, location, doctor,
        payer and whether the patient has any debt from the last visit
      </Typography>
    </Box>
  );
};
