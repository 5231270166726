import moment from 'moment';
import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { columnsWidths } from '../List';
import { CaseDepositionsContext } from '../../CaseDepositionsProvider';
import { IconButton } from '../../../../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../../../../components/Tooltip';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import * as depositionsApi from '../../../../../../../../../api/cases/depositions';
import { EditDepositionModal } from '../../EditDespositionModal/EditDespositionModal';
import { ConfirmationModal } from '../../../../../../../../../components/ConfirmationModal';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../../../../components/ListRow';

export const Row = ({ deposition, width } ) => {
  const { caseID, updateDeposition, deleteDeposition } = useContext(CaseDepositionsContext);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const handleDepositionUpdate = () => {
    openModal(EditDepositionModal, {
      payload: {
        caseID,
        deposition
      },

      onModalResolved: (deposition) => {
        updateDeposition(deposition);
      }
    });
  };

  const handleDepositionDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        depositionsApi.deleteCaseDepositions(caseID, deposition.id).then(() => {
          deleteDeposition(deposition);
          enqueueSnackbar('Case deposition successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Case deposition not deleted', { variant: 'error' });
        });
      }
    });
  };

  return (
    <ListRow>
      <ListRowCell grow noWrap flexBasis={columnsWidths.name}>
        <Tooltip title={width < 1000 ? deposition.name : ''}>
          <Typography noWrap>
            {deposition.name}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.description}>
        <Tooltip title={deposition.description}>
          <Typography noWrap>
            {deposition.description}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateReceived}>
        {deposition.date_received ? moment(deposition.date_received).format('L LT') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateResponded}>
        {deposition.date_responded ? moment(deposition.date_responded).format('L LT') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.depositionDate}>
        {deposition.deposition_date ? moment(deposition.deposition_date).format('L LT') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        <ListRowCellActions>
          <IconButton
            color="info"
            onClick={handleDepositionUpdate}
          >
            <EditIcon />
          </IconButton>

          <IconButton edge="end" color="error" onClick={handleDepositionDelete}>
            <DeleteIcon />
          </IconButton>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
