import { flow } from 'lodash';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { useModal } from '../../../../ModalsProvider';
import { PreviewReportModal } from '../../PreviewReportModal';
import { ReportsContext } from '../ReportsProvider';
import { reportTypesMap } from '../SelectReportTypeModal';

export const ReportsMenu = ({ report, onClose }) => {
  const { openModal } = useModal();
  const { pathname, deleteReport, fetchReports } = useContext(ReportsContext);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleDeleteReport = () => {
    deleteReport(report.id);
  };

  const openPreview = () => {
    openModal(PreviewReportModal, {
      payload: {
        pathname,
        reportID: report.id,
        onDelete: deleteReport,
        onUpdate: fetchReports
      }
    });
  };

  const handleUpdateReport = () => {
    if (report.type === reportTypesMap.new) {
      history.push(pathname + `/${report.id}/first-step`);
    } else {
      history.push(pathname + `/${report.id}/edit`);
    }
  };

  return (
    <List>
      {!isMobile && (
        <MenuItem onClick={flow(openPreview, onClose)}>
          <ListItemIcon>
            <EyeIcon fontSize="inherit" />
          </ListItemIcon>

          <ListItemText primary="View" />
        </MenuItem>
      )}

      {hasRole(rolesMap.doctor, rolesMap.admin, rolesMap.medic, rolesMap.supervisor) &&
        <MenuItem onClick={flow(handleUpdateReport, onClose)}>
          <ListItemIcon>
            <EditIcon color="primary" fontSize="inherit" />
          </ListItemIcon>

          <ListItemText primary="Edit" />
        </MenuItem>
      }

      {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.medic, rolesMap.advocate) &&
        <MenuItem onClick={flow(handleDeleteReport, onClose)}>
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="inherit"/>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      }
    </List>
  );
};
