import { useContext } from 'react';
import { debounce } from 'lodash';
import { Box } from '@material-ui/core';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../helpers/dates';
import { InvoicesContext, orderByOptions } from '../../../../app/Dashboard/BillingPage/Invoices/InvoicesContext';
import { FiltersBar as FiltersBarComponent } from '../../../FiltersBar';
import { AppointmentsField } from '../../../appointments';
import { ClaimsField } from '../../../claims';
import {
  DatePicker,
  TextField,
  BillingParticipantsSelect,
  InvoicesStatusesSelect,
  OrderBy
} from '../../../FormField';

const initialValues = {
  number: null,
  billing_participant_id: null,
  case_id: null,
  appointment_id: null,
  claim_id: null,
  due_date_from: null,
  due_date_to: null,
  status_id: null,
  order_by: null
};

export const FiltersBar = () => {
  const { filter, applyFilter } = useContext(InvoicesContext);

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
  }, 600);

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.value });
  };

  const handleBillingParticipantChange = (participant) => {
    applyFilter({ billing_participant_id: participant?.id });
  };

  const handleDatePickerChange = (name, transformer) => (date) => {
    const transformedDate = transformer?.(date) || date;

    applyFilter(({ [name]: transformedDate }));
  };

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ order_direction: orderDirection });
  };

  return (
    <FiltersBarComponent
      isResetForm
      border={0}
      initialValues={initialValues}
      fields={[
        <TextField
          name="number"
          label="Inv number"
          placeholder="Search..."
          onChange={handleFieldChange('number')}
        />,

        <BillingParticipantsSelect
          name="billing_participant_id"
          label="Participants"
          onChange={handleBillingParticipantChange}
        />,

        <AppointmentsField
          name="appointment_id"
          label="Appointment"
          placeholder="Search appt..."
          onChange={handleSelectChange('appointment_id')}
        />,

        <ClaimsField
          name="claim_id"
          label="Claim"
          placeholder="Search claim..."
          onChange={handleSelectChange('claim_id')}
        />,

        <Box minWidth={140}>
          <DatePicker
            clearable
            outputFormat="YYYY-MM-DD"
            name="due_date_from"
            label="From"
            onChange={handleDatePickerChange('due_date_from', unixToStartOfDayUnix)}
          />
        </Box>,

        <Box minWidth={140}>
          <DatePicker
            clearable
            outputFormat="YYYY-MM-DD"
            name="due_date_to"
            label="To"
            onChange={handleDatePickerChange('due_date_to', unixToEndOfDayUnix)}
          />
        </Box>,

        <InvoicesStatusesSelect
          name="status_id"
          label="Filter by statuses"
          onChange={handleSelectChange('status_id')}
        />,

        <Box minWidth={160}>
          <OrderBy
            name="order_by"
            options={orderByOptions}
            orderDirection={filter.order_direction}
            onOptionChange={handleSelectChange('order_by')}
            onOrderDirectionChange={toggleOrderDirection}
          />
        </Box>
      ]}
    />
  );
};
