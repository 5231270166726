import { useContext } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { OfficesContext } from '../../OfficesContext';
import { columnsWidths, widthBreakpointLarge, widthBreakpointSmall, widthBreakpointMedium } from '../List';

const styles = ({ spacing }) => ({
  root: {
    paddingLeft: spacing(),
    paddingRight: spacing()
  }
});

const useStyles = makeStyles(styles);

export const TableHeader = ({ width }) => {
  const classes = useStyles();
  const { selectedIDs, toggleAllItemsSelection, allItemsIsSelected } = useContext(OfficesContext);

  return (
    <Paper elevation={0}>
      <ListRow header className={classes.root}>
        <ListRowCheckbox
          onClick={toggleAllItemsSelection}
          checked={allItemsIsSelected()}
          indeterminate={!!selectedIDs.length && !allItemsIsSelected()}
        />

        <ListRowCell grow noWrap flexBasis={columnsWidths.name}>
          Office Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.name_company}>
          Company Name
        </ListRowCell>

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.address}>
            Address
          </ListRowCell>
        }

        {width > widthBreakpointMedium &&
          <ListRowCell flexBasis={columnsWidths.address}>
            Billing Address
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>

        {width > widthBreakpointSmall &&
          <ListRowCell flexBasis={columnsWidths.phone}>
            Phone
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.office_contacts}>
          Office contacts
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
