import { useFormikContext } from 'formik';
import { Box, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { TextField } from '../../../../../components/FormField';
import { BillingAddress } from '../../BillingAddress';
import { Address } from '../../Address';
import { Venue } from '../../Venue';
import { Contacts } from './Contacts';
import { MainInfo } from './MainInfo';
import { MedicInfo } from './MedicInfo';

export const OfficeForm = ({ disableCompany }) => {
  const { values } = useFormikContext();

  return (
    <>
      <MainInfo disableCompany={disableCompany} />

      <Address title="Office Address/Location" />

      <BillingAddress />

      {values?.is_medical ? <MedicInfo /> : <Venue />}

      <Contacts />

      <Box display="flex" alignItems="center" fontWeight={500} mb={2}>
        <NotesIcon fontSize="small"/>

        <Box ml={1}>
          <Typography variant="h5">Note</Typography>
        </Box>
      </Box>

      <TextField
        fullWidth
        multiline
        rows={5}
        name="notes"
        variant="filled"
      />
    </>
  );
};
