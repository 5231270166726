import { Box, Link } from '@material-ui/core';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { AppointmentViewModal } from '../../../../../../components/appointments/AppointmentViewModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { Date } from '../../../../../../components/Date';

export const ApptModel = ({ model }) => {
  const { openModal } = useModal();

  const openViewAppointmentModal = () => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: model?.id
      }
    });
  };

  return (
    <Box display="flex" alignItems="center" color="info.main">
      <InsertInvitationOutlinedIcon color="inherit" />

      <Box ml={1}>
        <UserLink
          disableLink
          size="sm"
          variant="body2"
          user={model?.patient}
        />

        <Date
          disableIcon
          disableWrapper
          component={Link}
          color="textPrimary"
          date={model.appointment_at}
          onClick={openViewAppointmentModal}
        />
      </Box>
    </Box>
  );
};
