import { Typography } from '@material-ui/core';

export const Bool = ({ before, after, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Enabled&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  } else if (before !== after) {
    return (
      <>Disabled&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography></>
    );
  } else {
    return (
      <>Updated&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography></>
    );
  }
};
