import moment from 'moment';

export const scrollCalendarToFirstAppt = ({ appointments, calendarRef }) => {
  const firstApptTime = appointments[0]?.appointment_at;

  if (firstApptTime) {
    const scrollTime = moment.unix(firstApptTime).format('HH:mm');
    const calendarApi = calendarRef.current.getApi();

    calendarApi.scrollToTime(scrollTime);
  }
};
