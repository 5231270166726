import { Date, Text, Bool, Company } from '../../fields';
import { WidgetActivity } from '../WidgetActivity';
import { mediasFieldsMap } from './mediasFieldsMap';

export const checklistFieldsMap = {
  other: (before, after) => <Text before={before} after={after} fieldLabel="Notes" />,
  media_id: (before, after) => <Text before={before} after={after} fieldLabel="Attached file" />,
  medias: (before, after) => (
    <WidgetActivity
      before={before}
      after={after}
      fieldLabel="Checklist Widget"
      activityLabel="Checklist Widget"
      fieldsMap={mediasFieldsMap}
    />
  ),
  insurance_card_number: (before, after) => (
    <Text before={before} after={after} fieldLabel="Insurance Card #" />
  ),
  loe: (before, after) => <Bool before={before} after={after} fieldLabel="LOE" />,
  medical: (before, after) => <Bool before={before} after={after} fieldLabel="Medi-Cal" />,
  medicare: (before, after) => <Bool before={before} after={after} fieldLabel="Medicare" />,
  um_case: (before, after) => <Bool before={before} after={after} fieldLabel="UM Case" />,
  pd_photos: (before, after) => <Bool before={before} after={after} fieldLabel="PD Photos" />,
  witnesses: (before, after) => <Bool before={before} after={after} fieldLabel="Witnesses" />,
  officer_id: (before, after) => <Text before={before} after={after} fieldLabel="Officer ID" />,
  passengers: (before, after) => <Bool before={before} after={after} fieldLabel="Passengers" />,
  tcr_number: (before, after) => <Text before={before} after={after} fieldLabel="TCR Number" />,
  pd_received: (before, after) => <Bool before={before} after={after} fieldLabel="PD Received" />,
  pd_requested: (before, after) => <Bool before={before} after={after} fieldLabel="PD Requested" />,
  police_report: (before, after) => <Bool before={before} after={after} fieldLabel="Police Report" />,
  sr_one_sent: (before, after) => <Date before={before} after={after} fieldLabel="SR-1 Sent" />,
  sr_nineteen_sent: (before, after) => <Date before={before} after={after} fieldLabel="SR-19 Sent" />,
  scene_photos: (before, after) => <Bool before={before} after={after} fieldLabel="Scene Photos" />,
  no_health_ins: (before, after) => <Bool before={before} after={after} fieldLabel="No Health Ins" />,
  plf_dec_sheet: (before, after) => <Bool before={before} after={after} fieldLabel="PLF Dec Sheet" />,
  rep_ltr_own_ins: (before, after) => <Bool before={before} after={after} fieldLabel="Rep Ltr Own Ins" />,
  medical_filled_at: (before, after) => <Date before={before} after={after} fieldLabel="Medical Filled At" />,
  rep_ltr_def_three: (before, after) => <Bool before={before} after={after} fieldLabel="Rep Ltr (Def-3)" />,
  witness_statement: (before, after) => <Bool before={before} after={after} fieldLabel="Witness Statement" />,
  medicare_filled_at: (before, after) => <Date before={before} after={after} fieldLabel="Medicare Filled At" />,
  medical_final_lien: (before, after) => <Bool before={before} after={after} fieldLabel="Medical Final Lien" />,
  medicare_final_lien: (before, after) => <Bool before={before} after={after} fieldLabel="Medicare Final Lien" />,
  primary_health_ins: (before, after) => <Bool before={before} after={after} fieldLabel="Pri Health Ins" />,
  intake_form_completed: (before, after) => <Bool before={before} after={after} fieldLabel="Intake Form Completed" />,
  rep_ltr_to_def_one_ins: (before, after) => <Bool before={before} after={after} fieldLabel="Rep Ltr to Def-1 Ins" />,
  rep_ltr_to_def_two_ins: (before, after) => <Bool before={before} after={after} fieldLabel="Rep Ltr to Def-2 Ins" />,
  primary_health_ins_final_lien: (before, after) => (
    <Bool before={before} after={after} fieldLabel="Pri Health Ins Final Lien" />
  ),
  police_department_id: (before, after) => <Company before={before} after={after} fieldLabel="Police Department" />
};
