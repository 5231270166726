import { activityActionMap } from '../activitiesHelpers';
import { getActivityComponent } from './actionTypesMap';

export const workSessionActionTypesMap = {
  [activityActionMap.createWorkSession]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateWorkSession]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteWorkSession]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createWorkSessionWorkClocks]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateWorkSessionWorkClocks]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteWorkSessionWorkClocks]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createWorkClockReport]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateWorkClockReport]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteWorkClockReport]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createTimeTrack]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateTimeTrack]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteTimeTrack]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createWorkClockClockIn]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateWorkClockClockIn]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteWorkClockClockIn]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createWorkClockClockOut]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateWorkClockClockOut]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteWorkClockClockOut]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  )
};
