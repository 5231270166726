import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, Dialog, SvgIcon, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { mdiCommentMultipleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import * as immunizationApi from '../../../../../api/profile';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { Comments, CommentsContextProvider } from '../../../../../components/Comments';
import { MedicalAttachments } from '../../../../../components/MedicalAttachments';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Loader } from '../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderActions
} from '../../../../../components/Modal';
import { ReportsPreviewContext, ReportsPreviewProvider } from '../../ReportsPreviewProvider';
import { EditImmunizationModal } from '../EditImmunizationModal';
import { MainContent } from './MainContent';

export const PreviewImmunizationModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { immunization, onUpdate }
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const commentsLastGlobalAction = useSelector(({ globalActions }) => globalActions.commentsLastGlobalAction);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const commentsInitialState = {
    filter: {
      parent_type: 'immunization_report',
      parent_id: immunization?.id
    }
  };

  const handleImmunizationDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        immunizationApi.deleteImmunizationReport(immunization?.id).then(() => {
          onUpdate();
          enqueueSnackbar('Item successfully deleted', { variant: 'success' });
          handleModalResolve();
        });
      }
    });
  };

  const fetchImmunizationReport = () => {
    return immunizationApi.fetchImmunizationReport(immunization.id);
  };

  const handleImmunizationUpdate = (initialValues, setInitialValues) => () => {
    openModal(EditImmunizationModal, {
      payload: { ID: initialValues.id },
      onModalResolved: (data) => {
        setInitialValues({ ...initialValues, ...data });
        onUpdate();
      }
    });
  };

  useEffect(() => {
    if (commentsLastGlobalAction) {
      onUpdate();
    }
  }, [ commentsLastGlobalAction ]);

  return (
    <ReportsPreviewProvider
      appointment_id={immunization.appointment_id}
      fetchItem={fetchImmunizationReport}
      onUpdate={onUpdate}
    >
      <ReportsPreviewContext.Consumer>
        {({ isFetched, selectedItem, setSelectedItem }) => (
          <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
            <Loader p={3} loading={!isFetched} render={
              () => (
                <ModalContainer>
                  <ModalHeader onClose={handleModalReject}>
                    View Immunization

                    <ModalHeaderActions disableGutters>
                      {!hasRole(rolesMap.client, rolesMap.patient) &&
                        <>
                          <Tooltip arrow title="Edit Immunization">
                            <IconButton
                              color="primary"
                              onClick={handleImmunizationUpdate(selectedItem, setSelectedItem)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip arrow title="Delete Immunization">
                            <IconButton
                              color="error"
                              onClick={handleImmunizationDelete}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    </ModalHeaderActions>
                  </ModalHeader>

                  <ModalBody>
                    <MainContent />

                    <MedicalAttachments
                      owner_type="immunization_report"
                      owner_id={immunization.id}
                      files={selectedItem.media || []}
                    />

                    <Box display="flex" alignItems="center" mt={1} mb={2}>
                      <SvgIcon><Icon path={mdiCommentMultipleOutline} /></SvgIcon>

                      <Box ml={1}>
                        <Typography variant="h3">
                          Comments
                        </Typography>
                      </Box>
                    </Box>

                    <Box minHeight={300}>
                      <CommentsContextProvider initialState={commentsInitialState}>
                        <Comments
                          parentType="immunization_report"
                          parentID={immunization.id}
                        />
                      </CommentsContextProvider>
                    </Box>
                  </ModalBody>
                </ModalContainer>
              )}
            />
          </Dialog>
        )}
      </ReportsPreviewContext.Consumer>
    </ReportsPreviewProvider>
  );
};
