export const FETCH_OFFICES_REQUEST = 'FETCH_OFFICES_REQUEST';
export const FETCH_OFFICES_SUCCESS = 'FETCH_OFFICES_SUCCESS';
export const ADD_OFFICE = 'ADD_OFFICE';
export const APPLY_FILTER = 'APPLY_FILTER';
export const UPDATE_OFFICE = 'UPDATE_OFFICE';
export const DELETE_OFFICE = 'DELETE_OFFICE';
export const RESET_OFFICES = 'RESET_OFFICES';
export const MASS_DELETE = 'MASS_DELETE';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
