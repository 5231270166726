import { api } from '../api';
import * as transformHelpers from './transformHelpers';

const transformTaskUserResponse = ({ status, time, ...data }) => {
  return {
    ...data,

    status: transformHelpers.transformResponse(status),
    time: transformHelpers.transformResponse(time)
  };

};

export const fetchTasksStatistics = (config) => {
  return api.get('/task-statistics', config)
    .then(({ data }) => {
      const type = config?.params?.type === 'types' ? 'types' : 'statuses';
      const transformedResp = config?.params?.type === 'types'
        ? transformHelpers.transformTasksTypesStatisticsResponse(data[type])
        : transformHelpers.transformResponse(data[type]);

      return {
        total_tasks: data.total_tasks,
        ...transformedResp
      };
    });
};

export const fetchTimeTaskStatistics = (config) => {
  return api.get('/task-time-statistics', config).then(({ data }) => transformHelpers.transformResponse(data));
};

export const fetchTaskPriorityStatistics = (config) => {
  return api.get('/task-priority-statistics', config).then(({ data }) => ({
    total_tasks: data.total_tasks,
    ...transformHelpers.transformResponse(data.priorities)
  }));
};

export const fetchTasksUserStatistics = (id) => {
  return api.get(`/task-user-statistics/${id}`).then(({ data }) => transformTaskUserResponse(data));
};

export const fetchTasksTimeSpentStatistics = (config) => {
  return api.get('/task-closed-time-statistics', config).then(({ data }) => data);
};
