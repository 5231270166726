import { api } from '../../api';

export const fetchProviderIDs = (config) => {
  return api.get('/profile/provider-id-types', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchProviderIdentifiers = (config) => {
  return api.get('/profile/provider-identifiers', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
