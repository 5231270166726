import { Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { UserLink } from '../../../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.invoiceNumber}>
        <Tooltip title={params?.invoice_number || ''}>
          <Typography noWrap>{params?.invoice_number || ''}</Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.users}>
        {params?._users?.length ? params?._users.map(({ id, participant }) => (
          <UserLink
            noWrap
            key={id}
            user={participant}
          />
        )) : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.companies}>
        {params?._companies?.length ? params?._companies.map(({ id, participant }) => (
          <CompanyLink
            noWrap
            key={id}
            company={participant}
          />
        )) : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.case}>
        {params?.case_id?.name || '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateFrom}>
        {params.date_from ? moment(params.date_from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateTo}>
        {params.date_to ? moment(params.date_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.method}>
        {params.method?.label || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

