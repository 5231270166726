import { useRef, useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { api } from '../../../../../api';
import { fetchStatuses } from '../../../../../api/tasks';
import { Loader } from '../../../../../components/Loader';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { TasksProvider, TasksFilterContext } from '../../../../../components/tasks/TasksProvider';
import { Column } from './Column';
import { handleSortEnd } from './handleSortEnd';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ColumnsList = () => {
  const classes = useStyles();
  const [ isFetched, setIsFetched ] = useState(false);
  const [ statuses, setStatuses ] = useState({});
  const cancelStatusesFetch = useRef(() => {});
  const [ statusTasks, setStatusTasks ] = useState({
    columns: {},
    columnOrder: []
  });
  const { updateTaskPosition } = useContext(TasksFilterContext);

  useEffect(() => {
    setIsFetched(false);

    fetchStatuses({
      cancelToken: new api.CancelToken((cancel) => cancelStatusesFetch.current = cancel)
    }).then(({ data }) => {

      setStatuses(data);
      setStatusTasks((state) => ({
        ...state,
        columnOrder: data?.map((status) => status.id)
      }));
      setIsFetched(true);
    });

    return () => {
      cancelStatusesFetch.current();
    };
  }, []);

  return (
    <Loader p={3} loading={!isFetched} render={
      () => (
        <DragDropContext
          onDragEnd={handleSortEnd({
            state: statusTasks,
            setState: setStatusTasks,
            onUpdate: updateTaskPosition
          })}
        >
          <Droppable
            droppableId="all-droppables"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <Scrollbars>
                <div
                  className={classes.root}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {statusTasks?.columnOrder.map((status, index) => (
                    <TasksProvider key={status} fixedFilter={{ statuses: [ statuses?.[index]?.id ] }}>
                      <div className={classes.column}>
                        <Column
                          status={statuses?.[index]}
                          index={index}
                          column={statusTasks?.columns?.[status]}
                          setStatusTasks={setStatusTasks}
                        />
                      </div>
                    </TasksProvider>
                  ))}

                  {provided.placeholder}
                </div>
              </Scrollbars>

            )}
          </Droppable>

        </DragDropContext>
      )}
    />
  );
};
