import { useEffect, useContext, useRef } from 'react';
import { makeStyles, Box, List as MuiList,Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../components/Loader';
import { Sticky } from '../../../../../../components/Sticky';
import { VisitReasonsContext } from '../VisitReasonsContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

export const columnsWidthsLg = {
  code: 200,
  description: 180,
  actions: 264
};

export const columnsWidthsSm = {
  code: 200,
  actions: 60
};

const useStyles = makeStyles(styles);

export const List = ({ baseUrl }) => {
  const {
    visitReasons,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    resetVisitReasons
  } = useContext(VisitReasonsContext);
  const scrollElementRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    resetVisitReasons();
  }, []);

  return (
    <div className={classes.root}>
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
        <Loader p={2} loading={!isFetched} render={
          () => (!filter.total ?
            <Box p={2}>
              <Typography align="center">No visit reasons found</Typography>
            </Box>
            :
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={visitReasons}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  RowProps={{ baseUrl }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </CustomScrollbars>
    </div>
  );
};
