import { createContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setCommentsLastGlobalAction } from '../../../store/globalActions';
import * as commentsApi from '../../../api/comments';
import { api } from '../../../api';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { initialState as initialStateConfig } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CommentsContext = createContext(null);

export const CommentsContextProvider = ({
  children,
  onCommentsCountUpdate = () => {},
  initialState = {}
}) => {
  const [ state, dispatch ] = useReducer(reducer, { ...initialStateConfig, ...initialState });
  const commentsLastGlobalAction = useSelector(({ globalActions }) => globalActions.commentsLastGlobalAction);
  const reduxDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const {
    filter,
    comments,
    pagination,
    isFetching,
    isFetched
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchComments = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_COMMENTS_REQUEST });

    return commentsApi.fetchComments({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      onCommentsCountUpdate?.();
      dispatch({ type: types.FETCH_COMMENTS_SUCCESS, payload: data });
    });
  };

  const createComment = (value) => {
    return commentsApi.createComment(value).then((data) => {
      reduxDispatch(setCommentsLastGlobalAction({ type: types.ADD_COMMENT, payload: data }));
    }).catch(({ status }) => {
      if (status === 422) {
        enqueueSnackbar('Comment not added', { variant: 'error' });
      }
    });
  };

  const updateComment = (comment) => {
    return commentsApi.updateComment(comment).then((data) => {
      reduxDispatch(setCommentsLastGlobalAction({ type: types.UPDATE_COMMENT, payload: data }));
    });
  };

  const deleteComment = (comment) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        commentsApi.deleteComment(comment.id).then((payload) => {
          reduxDispatch(setCommentsLastGlobalAction({ type: types.UPDATE_COMMENT, payload }));
          enqueueSnackbar('Comment successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  const restoreComment = (comment) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        commentsApi.restoreComment(comment.id).then((data) => {
          reduxDispatch(setCommentsLastGlobalAction({ type: types.UPDATE_COMMENT, payload: data }));
          enqueueSnackbar('Comment successfully restored', { variant: 'success' });
        });
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchComments({ page: filter.page + 1 }).catch(() => {});
    }
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    commentsLastGlobalAction && dispatch(commentsLastGlobalAction);
  }, [ commentsLastGlobalAction ]);

  const providerValue = {
    isFetched,
    isFetching,
    comments,
    filter: {
      ...filter,
      ...pagination
    },

    fetchComments,
    createComment,
    deleteComment,
    updateComment,
    restoreComment,
    loadNextPage
  };

  return (
    <CommentsContext.Provider value={providerValue}>
      {children}
    </CommentsContext.Provider>
  );
};
