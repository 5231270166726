import { useEffect } from 'react';
import { Link } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useReminderEvent } from '../../../../../../../components/cases/reminders/events';
import { periodDirectionsMap } from '../../../../../../../components/cases/reminders/events/ReminderEventForm';
import { ColorPreview } from '../../../../../../../components/ColorPreview';
import { IconButton } from '../../../../../../../components/IconButton';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../components/Loader';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { columnsWidths } from '../List';

export const Row = ({ item: reminderEvent = {}, isLoaded, recalculateHeight }) => {
  const {
    groupTitle,
    shortGroupTitle,
    openRelativeReminderEventPreview,
    editEvent,
    deleteEvent
  } = useReminderEvent(reminderEvent);

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow px={0}>
      <ListRowCell flexBasis={columnsWidths.eventNumber}>
        {reminderEvent.position || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.type}>
        {reminderEvent.type}
      </ListRowCell>

      <ListRowCell column flexBasis={columnsWidths.group}>
        <ListRowCellActions start withSpacings>
          <ColorPreview color={reminderEvent?.group?.color}/>

          <Tooltip title={groupTitle}>
            <span>
              {shortGroupTitle}
            </span>
          </Tooltip>
        </ListRowCellActions>
      </ListRowCell>

      <ListRowCell grow noWrap flexBasis={columnsWidths.description}>
        {reminderEvent.description}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.interval}>
        {reminderEvent.period_direction === periodDirectionsMap.back && '-'}
        {reminderEvent.interval} {reminderEvent.period}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.relative}>
        {!reminderEvent.previousEvent ? 'No' : (
          <Link onClick={openRelativeReminderEventPreview}>
            {reminderEvent.previousEvent?.position} {reminderEvent.previousEvent?.type}
          </Link>
        )}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        <ListRowCellActions>
          <IconButton color="info" onClick={editEvent}>
            <EditIcon />
          </IconButton>

          <IconButton color="error" onClick={deleteEvent}>
            <DeleteIcon />
          </IconButton>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
