import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { NestedList } from '../../../../../NestedList';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../helpers/isEmptyDeep';

export const CurrentMedications = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(report) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Current Medications"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box py={2}>
        {report?.see_list &&
          <ReadonlyCheckbox
            checked={report?.see_list}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="See List"
          />
        }

        {report?.see_list &&
          <ReadonlyCheckbox
            checked={report?.see_list}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="Non-Complaint with Pain Management Agreement"
          />
        }

        <Box pl={3} py={1}>
          {report?.medications?.map((item, i) => (
            <Grid key={i} container spacing={1} component={Box} m="0 !important">
              <Grid item>
                <Typography variant="h5">{i + 1}.</Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="h5">{item.name}</Typography>
              </Grid>

              <Grid item xs={7}>
                <Typography variant="h5">(# {item.code})</Typography>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Box>
    </NestedList>
  );
};
