import { Box } from '@material-ui/core';
import { CurrencyFormat as MuiCurrencyFormat } from '../../CurrencyFormat';
import { WidgetActivity } from './WidgetActivity';
import { incomeFieldsMap } from './fieldsMaps';

export const Income = ({
  isDeleted = false,
  isNew = false,
  payload
}) => {
  if (isDeleted) {
    return (
      <Box component="span" display="flex" alignItems="center">
        Removed Income for the amount
        &nbsp;
        <MuiCurrencyFormat
          value={payload?.properties?.after?.total}
          TypographyProps={{ variant: 'subtitle1' }}
        />
      </Box>
    );
  }

  return (
    <>
      {isNew && (
        <Box component="span" display="flex" alignItems="center">
          Added new Income for the amount
          &nbsp;
          <MuiCurrencyFormat
            value={payload?.properties?.after?.total}
            TypographyProps={{ variant: 'subtitle1' }}
          />
        </Box>
      )}

      <WidgetActivity
        {...payload?.properties}
        fieldLabel="Schedule Event"
        activityLabel="Schedule Event"
        fieldsMap={incomeFieldsMap}
      />
    </>
  );
};
