import { useFormikContext } from 'formik';
import { CompaniesAutocomplete, OfficesLocationSelect, PhoneField } from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';
import { FaxNumbersSelect } from '../../../../../components/faxes/FaxNumbersSelect';

export const Form = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleUserChange = (user) => {
    if (user) {
      setFieldValue('to', user?.fax || null);
    }
  };

  return (
    <>
      <CompaniesAutocomplete
        name="company"
        label="Company"
        margin="dense"
      />

      <OfficesLocationSelect
        name="office"
        label="Office"
        margin="dense"
        params={{
          company_id: values?.company
        }}
      />

      <UsersSelect
        name="user"
        label="User"
        margin="dense"
        params={{
          company_id: values?.company,
          office_id: values?.office
        }}
        onChange={handleUserChange}
      />

      <PhoneField
        required
        name="to"
        label="Fax"
        margin="dense"
        placeholder="Enter fax number..."
      />

      <FaxNumbersSelect
        name="sip_fax_id"
        label="Fax number"
        margin="dense"
        placeholder="Search fax number..."
      />
    </>
  );
};
