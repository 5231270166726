import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as codesApi from '../../../../../../../api/codes/procedures';
import { setSchedulesLastGlobalAction } from '../../../../../../../store/globalActions';
import { transformCodeModifierToOption } from '../../../../../../../components/FormField';
import { Loader } from '../../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../../components/Modal';
import * as types from '../SchedulesContext/types';
import { Body, validationSchema } from '../CreateScheduleModal';

export const EditScheduleModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { parentID }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const [ initialValues, setInitialValues ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();

  const updateFeeSchedule = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return codesApi.updateFeeSchedule(values).then((data) => {
      enqueueSnackbar('Schedule successfully updated', { variant: 'success' });
      dispatchRedux(setSchedulesLastGlobalAction({ type: types.UPDATE_SCHEDULE_IN_LIST, payload: data }));
      handleModalResolve(data);
    }).catch(({ errors, message }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  useEffect(() => {
    parentID && codesApi.fetchFeeSchedule(parentID).then(setInitialValues);
  }, []);

  return (
    <Dialog {...DialogProps} fullScreen={isMobile}>
      <Loader p={3} loading={!initialValues} render={
        () => (
          <Formik
            enableReinitialize
            initialValues={{
              id: parentID,
              procedure_code_id: initialValues.procedure_code_id,
              payer_id: initialValues?.payer,
              is_all_payers: !initialValues?.payer,
              modifier_id: transformCodeModifierToOption(initialValues?.modifier) || '',
              default_units: initialValues?.default_units || '',
              charge_amount: initialValues?.charge_amount || 0,
              expected_amount: initialValues?.expected_amount || 0,
              work_rvu: initialValues?.work_rvu || '',
              moldx_code: initialValues?.moldx_code || '',
              dx_code: initialValues?.dx_code || '',
              total_rvu: initialValues?.total_rvu || ''
            }}
            validationSchema={validationSchema}
            onSubmit={updateFeeSchedule}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
                <ModalHeader onClose={handleModalReject}>
                  Edit Fee Schedule
                </ModalHeader>

                <ModalBody>
                  <Body />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>Cancel</Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
