import * as payerApi from '../../../../api/codes/payer';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';

export const transformFinancialClassToOption = (item) => item && ({
  value: item.id,
  label: item.code
});

export const fetchFinancialClass = fetchDataForAsyncSelect((search, { page }) => {
  return payerApi.fetchFinancialClasses({
    params: {
      search,
      page,
      is_active: 1
    }
  });
}, {
  transformDataToOption: transformFinancialClassToOption
});

export const FinancialClassesSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      name="financial_class_id"
      label="Financial class"
      promiseWithOptions={fetchFinancialClass}

      {...props}
    />
  );
};
