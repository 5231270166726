import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../../../../components/Loader';
import { Scrollbars } from '../../../../../../../../../components/Scrollbars';
import { SavedFiltersContext } from '../../../../../../../../../components/saved-filters';
import { InfiniteListLoader } from '../../../../../../../../../components/InfiniteLoader';
import { ListHeader } from './ListHeader';
import { Row } from './Row';

export const columnsMap = {
  invoiceNumber: 'invoiceNumber',
  user: 'user',
  cases: 'cases',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  expenseCategory: 'expenseCategory'
};

export const columnsWidths = {
  [columnsMap.invoiceNumber]: 200,
  [columnsMap.user]: 160,
  [columnsMap.cases]: 160,
  [columnsMap.dateFrom]: 150,
  [columnsMap.dateTo]: 150,
  [columnsMap.expenseCategory]: 150
};

export const columnsNamesMap = {
  [columnsMap.invoiceNumber]: 'Invoice Number',
  [columnsMap.user]: 'User',
  [columnsMap.cases]: 'Cases',
  [columnsMap.dateFrom]: 'Date From',
  [columnsMap.dateTo]: 'Date To',
  [columnsMap.expenseCategory]: 'Expense category'
};

export const List = ({ onApplySavedFilter }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Box maxHeight={500} minWidth={1030}>
        <Loader p={2} loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No saved filters</Typography>
          ) : (
            <>
              <ListHeader />

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={savedFilters}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  RowProps={{ onApplySavedFilter }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </Scrollbars>
  );
};
