import { Box } from '@material-ui/core';
import { UserAvatar } from './UserAvatar';

export const SignedUsers = ({ document }) => {
  return !!document?.need_users_sign?.length && (
    <Box display="flex" alignItems="center">
      {document?.need_users_sign?.map((userItem) => (
        <Box key={userItem?.user?.id} mr={0.5}>
          <UserAvatar item={userItem}/>
        </Box>
      ))}
    </Box>
  );
};
