export const filterFieldsMap = {
  cases: 'cases',
  tasks: 'tasks',
  users: 'users',
  offices: 'offices',
  period: 'period',
  types: 'types'
};

export const filterFieldsLabels = {
  [filterFieldsMap.cases]: 'Cases',
  [filterFieldsMap.tasks]: 'Tasks',
  [filterFieldsMap.users]: 'Users',
  [filterFieldsMap.offices]: 'Offices',
  [filterFieldsMap.period]: 'Period',
  [filterFieldsMap.types]: 'Types'
};
