export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { pxToRem, fontWeightRegular, fontWeightMedium }
}) => ({
  root: {
    padding: spacing(4, 2, 0)
  },

  list: {
    listStyle: 'none',
    margin: 0,
    paddingLeft: spacing(),
    paddingRight: spacing(),

    '& + &': {
      marginTop: spacing(2)
    }
  },

  field: {
    display: 'flex',

    '& + &': {
      marginTop: spacing()
    }
  },

  field_center: {
    justifyContent: 'center'
  },

  field__label: {
    flexShrink: 0,
    width: 130,
    paddingRight: spacing(2),
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(14)
  },

  field__label_secondary: {
    fontWeight: fontWeightRegular,
    color: grey[500]
  },

  field__label_flex: {
    width: 'auto'
  },

  field__value: {
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(14)
  },

  field__value_marked: {
    color: palette.darkgreen
  },

  fieldsGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
    gridGap: spacing(),

    '& $field': {
      marginTop: 0
    },

    '& + $field': {
      marginTop: spacing(2)
    }
  }
});
