import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, makeStyles, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import * as billingApi from '../../../../../../../../api/billing';
import {
  invoicesTypesOptions,
  billingParticipantsTypesOptions
} from '../../../../../../../../components/billing';
import { ModalHeader, ModalFooter, ModalBody } from '../../../../../../../../components/Modal';
import { Loader } from '../../../../../../../../components/Loader';
import { FormContent } from '../../../../../../BillingPage/Invoices/CreateInvoice/FormContent';
import { transformTermsToOption } from '../../../../../../BillingPage/Invoices/helpers';
import { transformToOption } from '../../../../../../../../helpers/fetchDataForAsyncSelect';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EditInvoiceModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    initialValues = {}
  }
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const [ initialInvoiceValues, setInitialValues ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const [ expenses, setExpenses ] = useState([]);
  const [ totalTime, setTotalTime ] = useState(0);

  useEffect(() => {
    setIsFetched(false);

    billingApi.fetchInvoice(initialValues.id).then((data) => {
      setInitialValues(data);
      setExpenses(data.expenses);
      setIsFetched(true);

      setIsFetched(true);
    });
  }, []);

  const updateInvoice = (invoiceData, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    invoiceData.expenses = expenses;
    invoiceData.total = +invoiceData.total;

    initialValues.updateInvoice({ invoiceData, setSubmitting, setErrors, handleModalResolve });
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <Loader loading={!isFetched} p={3} render={
        () => (
          <Formik
            initialValues={{
              ...initialInvoiceValues,

              model_type: invoicesTypesOptions.find(({ value }) => value === initialInvoiceValues.model_type),
              model_id: initialValues.model || null,
              bill_from: {
                type: billingParticipantsTypesOptions.find(({ value }) => {
                  return value === initialInvoiceValues.bill_from?.participant_type;
                }),
                id: initialInvoiceValues.bill_from?.participant
              },
              bill_to: {
                type: billingParticipantsTypesOptions.find(({ value }) => {
                  return value === initialInvoiceValues.bill_to?.participant_type;
                }),
                id: initialInvoiceValues.bill_to?.participant
              },
              incomes: initialInvoiceValues.incomes || [],
              status_id: transformToOption(initialInvoiceValues.status),
              terms: transformTermsToOption(initialInvoiceValues.terms)
            }}
            validationSchema={validationSchema}
            onSubmit={updateInvoice}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form noValidate className={classes.root} onSubmit={handleSubmit}>
                <ModalHeader onClose={handleModalReject}>
                  Edit Invoice
                </ModalHeader>

                <ModalBody>
                  <FormContent
                    isEditable
                    totalTime={totalTime}
                    expenses={expenses}
                    onTotalTimeUpdate={setTotalTime}
                    onExpensesUpdate={setExpenses}
                  />
                </ModalBody>

                <ModalFooter>
                  <div className={classes.actions}>
                    <Button className={classes.actions__backButton} onClick={handleModalResolve}>Back</Button>

                    <Loader surface loading={isSubmitting} render={
                      () => (
                        <Button
                          autoFocus
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      )}
                    />
                  </div>
                </ModalFooter>
              </form>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
