import { useState } from 'react';
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import { filtersKeysMap } from '../../../../../../store/lastFilters';
import { filterFieldsMap } from '../../../Payments/PaymentsPage/FiltersBar/filterFieldsMap';
import { Overdraft } from '../../../Payments/PaymentsPage/Overdraft';
import { Received } from '../../../Payments/PaymentsPage/Received';

const tabsMap = {
  received: 'received',
  overdraft: 'overdraft'
};

export const PaymentsContent = ({ filter, ReceivedProps, OverdraftProps }) => {
  const [ activeTab, setActiveTab ] = useState(tabsMap.received);

  const handleTabIndexChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Paper component={Box} height="100%" display="flex" flexDirection="column">
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabIndexChange}
      >
        <Tab value={tabsMap.received} label="Received" />
        <Tab value={tabsMap.overdraft} label="Overdraft" />
      </Tabs>

      {(activeTab === tabsMap.received) && (
        <Received
          filter={filter}
          filterKey={filtersKeysMap.billing_payments_received}

          {...ReceivedProps}
        />
      )}

      {(activeTab === tabsMap.overdraft) && (
        <Overdraft
          filter={filter}
          filterKey={filtersKeysMap.billing_payments_overdraft}
          hiddenFields={[ filterFieldsMap.caseId ]}

          {...OverdraftProps}
        />
      )}
    </Paper>
  );
};
