export const styles = ({ spacing, typography }) => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    overflow: 'auto',
    cursor: ({ cursor = 'inherit' }) => cursor
  },

  cell_header: {
    '&$cell': {
      fontWeight: typography.fontWeightMedium
    }
  },

  cell_actions: {
    '& > * + *': {
      marginLeft: spacing()
    }
  },

  cell_grow: {
    flex: '1 1'
  },

  cell_column: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },

  cell_disabled: {
    opacity: 0.4
  },

  cell_noWrap: {
    display: 'block',
    overflow: 'hidden'
  }
});
