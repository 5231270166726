import { Box, Typography } from '@material-ui/core';
import { ColorPreview } from '../../ColorPreview';

export const EventType = ({ before = null, after = null, fieldLabel }) => {
  const renderType = (type) => (
    <Box display="flex" alignItems="center">
      <ColorPreview color={type?.color} />
      &nbsp;
      <Typography variant="subtitle1">{type?.name}</Typography>
    </Box>
  );

  if (!before && after) {
    return (
      <Box display="flex" alignItems="center">
        Set&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;to&nbsp;
        {renderType(after)}
      </Box>
    );
  } else if (before && !after) {
    return (
      <Box display="flex" alignItems="center">
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        {renderType(before)}
      </Box>
    );
  } else {
    return (
      <Box display="flex" alignItems="center">
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        {renderType(before)}
        &nbsp;to&nbsp;
        {renderType(after)}
      </Box>
    );
  }
};
