import { useRef, useContext, useEffect } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../../../components/CustomScrollbars';
import { useResizeObserver } from '../../../../../../../../helpers/hooks/useResizeObserver';
import { Loader } from '../../../../../../../../components/Loader';
import { TablePagination } from '../../../../../../../../components/TablePagination';
import { StatisticsFilterContext } from '../../../../StatisticsFilterProvider';
import { InvoicesContext } from '../../../../../Invoices/InvoicesContext';
import { columnsMap } from '../../../../../Invoices/InvoicesPage/InvoicesContent/List/listConfig';
import { TableHeader } from '../../../../../Invoices/InvoicesPage/InvoicesContent/List/TableHeader';
import { Row } from '../../../../../Invoices/InvoicesPage/InvoicesContent/List/Row';

const hiddenColumns = [ columnsMap.checkbox ];

export const widthBreakpointXXS = 440;
export const widthBreakpointXS = 560;
export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1150;

export const Invoices = () => {
  const {
    invoices,
    isFetched,
    meta,
    resetInvoices
  } = useContext(InvoicesContext);
  const statisticsFilterContext = useContext(StatisticsFilterContext);
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });

  useEffect(() => {
    if (statisticsFilterContext) {
      resetInvoices();
    }
  }, [ statisticsFilterContext?.filter ]);

  return (
    <Box ref={rootRef} height="100%">
      <Loader loading={!isFetched} p={2} render={
        () => !meta.total ? (
          <Box p={2}>
            <Typography align="center">No invoices found</Typography>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" height="100%">
            <TableHeader width={width} hiddenColumns={hiddenColumns} />

            <CustomScrollbars style={{ flexGrow: 1 }}>
              <MuiList disablePadding>
                {invoices.map((invoice) => (
                  <Row
                    key={invoice.id}
                    invoices={invoices}
                    invoice={invoice}
                    width={width}
                    hiddenColumns={hiddenColumns}
                  />
                ))}
              </MuiList>
            </CustomScrollbars>

            <TablePagination borderTop disablePerPage pagination={meta} onChange={resetInvoices} />
          </Box>
        )}
      />
    </Box>
  );
};
