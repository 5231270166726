import * as types from './types';
import { createReduxReducer } from '../../../../helpers/createReduxReducer';

const initialState = {
  isFetching: false,
  statuses: []
};

export const reducer = createReduxReducer(initialState, {
  [types.FETCH_STATUSES]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_STATUSES_SUCCESS]: (state, payload) => {
    return {
      ...state,

      isFetching: true,
      statuses: payload
    };
  },

  [types.CREATE_STATUSES_SUCCESS]: (state, payload) => {
    return {
      ...state,

      statuses: [
        payload,
        ...state.statuses
      ]
    };
  },

  [types.UPDATE_STATUSES_SUCCESS]: (state, payload) => {
    return {
      ...state,
      [payload.id]: payload
    };
  },

  [types.DELETE_STATUSES_SUCCESS]: (state, payload) => {
    return {
      ...state,
      statuses: state.statuses.filter((status) => status.id !== payload.id)
    };
  }
});
