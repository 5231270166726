import { useFormikContext } from 'formik';
import { Box, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../components/UserLink';
import { paymentMethodsMap } from '../../../../../../dataMaps/paymentMethodsMap';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { DatePicker, Select } from '../../../../../../components/FormField';
import { Contact } from '../../../../../../components/Contact';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ isOverdraftPage }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { values } = useFormikContext();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const payerAddress = values?.payer?.info?.billing_full_address || values?.payer?.info?.full_address
    || values?.payer?.info?.billing_address || null;
  const payerPhone = values?.payer?.info?.phone || values?.payer?.info?.billing?.phone || null;
  const payerEmail = values?.payer?.info?.email || values?.payer?.info?.billing?.email || null;

  return (
    <Grid
      container
      spacing={isMobile ? 1 : 2}
      alignItems="stretch"
      component={Box}
      py={isMobile ? 2 : 3}
      px={isMobile ? 1 : 4}
      width="100% !important"
      mx="0 !important"
    >
      <Grid item xs={isMobile ? 12 : 4}>
        <Typography paragraph variant={isMobile ? 'h4' : 'h5'}>Payer</Typography>

        {!!values?.parentPage &&
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <PersonIcon />
            </Box>

            {values?.payer?.type?.value === 'user'
              ? <UserLink user={values?.payer?.info} />
              : <CompanyLink company={values?.payer?.info} />
            }
          </Box>
        }

        <Box display="flex" alignItems="center" mt={1}>
          <Box component="span" mr={1}>
            <RoomOutlinedIcon fontSize="small" />
          </Box>

          {payerAddress
            ? <Typography>{payerAddress}</Typography>
            : <Typography color="textSecondary">No info.</Typography>
          }
        </Box>

        <Box display="flex" alignItems="center" mt={1}>
          <Contact
            needIcon
            type="tel"
            variant="h5"
            contact={payerPhone}
          />
        </Box>

        <Box display="flex" alignItems="center" mt={1} mb={isMobile ? 1 : 0}>
          <Box component="span" mr={1}>
            <EmailOutlinedIcon fontSize="small" />
          </Box>

          <Contact
            color="primary"
            type="mailto"
            variant="h5"
            contact={payerEmail}
          />
        </Box>
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <Typography paragraph variant={isMobile ? 'h4' : 'h5'}>Payment info</Typography>

        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <Select
            formattedValue
            isDisabled={!!isOverdraftPage}
            name="method"
            label="Payment Method"
            options={paymentMethodsMap}
            TextFieldProps={{ required: true }}
          />

          <Box component="span" mt={2}>
            <DatePicker
              disabled={!!isOverdraftPage}
              outputFormat="YYYY-MM-DD"
              name="date"
              label="Payment Date"
            />
          </Box>
        </Box>
      </Grid>

      {(!!values?.parentPage || isOverdraftPage) &&
        <Grid item xs={isMobile ? 12 : 4}>
          <Box height="100%" pb={3}>
            <Typography gutterBottom align="right" variant={isMobile ? 'h4' : 'h5'}>Amount received</Typography>

            <div className={classes.contentAmount}>
              <CurrencyFormat value={values?.amount} className={classes.currencyFormat} />
            </div>
          </Box>
        </Grid>
      }
    </Grid>
  );
};
