import { Box, List as MuiList, Paper, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../ListRow';

const columnsWidths = {
  name: 120,
  count: 200
};

export const PayPeriodTotal = ({ report = {} }) => {
  const weeks = report?.report_data?.weeks || [];

  return (
    <Box py={2}>
      <Typography gutterBottom variant="h6">Pay Period Totals</Typography>

      <Paper elevation={0}>
        <ListRow header px={2}>
          <ListRowCell flexBasis={columnsWidths.name}>
            Pay Period:
          </ListRowCell>

          <ListRowCell grow flexBasis={columnsWidths.count}>
            {report?.report_data?.gross_totals}
          </ListRowCell>
        </ListRow>
      </Paper>

      <MuiList disablePadding>
        {weeks.map((weekItem, index) => (
          <ListRow px={2} key={weekItem.week + index}>
            <ListRowCell flexBasis={columnsWidths.name}>
              {`Week ${weekItem.week}`}
            </ListRowCell>

            <ListRowCell grow flexBasis={columnsWidths.count}>
              {weekItem?.week_total_hours}
            </ListRowCell>
          </ListRow>
        ))}
      </MuiList>
    </Box>
  );
};
