import { useContext, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { OfficeLink } from '../../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.patient}>
        <UserLink noWrap user={params.patient_id} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dorFrom}>
        {params?.dor_from || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dorTo}>
        {params?.dor_to || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.reportType}>
        {params?.type?.label || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.diagnosisDX}>
        {params?.icd_id ? `${params?.icd_id?.code} - ${params?.icd_id?.description}` : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        <UserLink noWrap user={params.provider_id} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        <OfficeLink noWrap variant="h5" office={params?.office_id} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
