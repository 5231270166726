import { useContext, useEffect } from 'react';
import { useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { ExpirationDate } from '../../../../../../components/ExpirationDate';
import { Label } from '../../../../../../components/Label';
import { stopPropagation } from '../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { IconButton } from '../../../../../../components/IconButton';
import { Loader } from '../../../../../../components/Loader';
import { insuranceStatusMap, isPrimary } from '../../../../ProfilePage/insurance';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const theme = useTheme();
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell noWrap flexBasis={columnsWidths.company}>
        <CompanyLink company={params?.insurance_company_id} />
      </ListRowCell>

      <ListRowCell grow noWrap flexBasis={columnsWidths.insuranceId}>
        {params.insuranceId}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.groupId}>
        {params.groupId}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.statusType}>
        <Label
          size="small"
          typographyProps={{
            variant: 'subtitle2'
          }}
          color={isPrimary(params?.status_type?.value) ? theme.palette.primary.main : theme.palette.slategray}
          label={insuranceStatusMap[params?.status_type?.value] }
        />
      </ListRowCell>

      <ListRowCell grow noWrap flexBasis={columnsWidths.typeInsurance}>
        {params?.type_insurance?.label}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.expirationDay}>
        {params.expiration_day
          ? <ExpirationDate date={params.expiration_day}/>
          : '-'
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

