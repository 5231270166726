import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../../../components/UserLink';
import { orderByLabelsMap } from '../../../../InvoicesContext';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.number &&
        <>
          <Grid item xs={4}>
            <Typography>
              Inv. number:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.number}
            </Typography>
          </Grid>
        </>
      }

      {params?._users?.length &&
        <>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                Users:
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              {params?._users?.map(({ participant, id }) => (
                <UserLink
                  noWrap
                  key={id}
                  user={participant}
                />
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?._companies?.length &&
        <>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                Companies:
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              {params?._companies?.map(({ participant, id }) => (
                <CompanyLink
                  noWrap
                  key={id}
                  company={participant}
                />
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.case_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Case:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.case_id?.name}
            </Typography>
          </Grid>
        </>
      }

      {params?.appointment_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Appointment:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {moment.unix(params?.appointment_id?.appointment_at).format('MM/DD/YYYY, LT')}
            </Typography>
          </Grid>
        </>
      }

      {params?.claim_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Claim:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.claim_id?.claim_number}
            </Typography>
          </Grid>
        </>
      }

      {params?.due_date_from &&
        <>
          <Grid item xs={4}>
            <Typography>
              Date From:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {moment(params.due_date_from).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.due_date_to &&
        <>
          <Grid item xs={4}>
            <Typography>
              Date To:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {moment(params.due_date_to).format('L')}
            </Typography>
          </Grid>
        </>
      }

      {params?.status_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Statuses:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.status_id?.name}
            </Typography>
          </Grid>
        </>
      }

      {params?.order_by &&
        <>
          <Grid item xs={4}>
            <Typography>
              Order by:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {orderByLabelsMap[params?.order_by]}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
