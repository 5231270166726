export const styles = ({ spacing, palette, typography: { fontWeightMedium } }) => ({
  rowBackground: {
    borderColor: palette.primary.main,
    paddingLeft: spacing(5.75)
  },

  fillContent: {
    maxWidth: 45,
    backgroundColor: palette.grey[200],
    borderRadius: 20,
    textAlign: 'center',
    paddingLeft: spacing(0.75),
    paddingRight: spacing(0.75),
    marginLeft: spacing(0.5),
    fontWeight: fontWeightMedium
  },

  fillContent_fullfilled: {
    color: palette.primary.contrastText,
    backgroundColor: palette.darkgreen
  },

  count: {
    color: palette.common.white,
    marginRight: spacing(0.5),
    padding: spacing(0, 1),
    backgroundColor: palette.info.main,
    borderRadius: 50
  }
});
