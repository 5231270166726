import moment from 'moment';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Date } from '../../../../../../../../components/Date';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SurgeriesList = ({ surgeries }) => {
  const classes = useStyles();

  return !!surgeries.length && (
    surgeries.map((item, index) => (
      <Grid key={index} container spacing={1} alignItems="flex-start" className={classes.root}>
        <Grid item>
          <Typography>{index + 1}.</Typography>
        </Grid>

        <Grid item md={3} sm={4} xs={10}>
          <Box display="flex" alignItems="center">
            {item.date_from && (
              <>
                <Date disableMoment date={item.date_from ? moment(item.date_from).format('L') : ''} />
                &nbsp;
                &nbsp;
              </>
            )}

            {item.date_to && (
              <Date disableMoment date={item.date_to ? moment(item.date_to).format('L') : ''} />
            )}
          </Box>
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <Box display="flex" alignItems="flex-start">
            <div className={classes.noteIcon}>
              <NotesIcon fontSize="small" color="inherit" /> Note
            </div>

            <Typography color="textSecondary">
              {item.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    ))
  );
};
