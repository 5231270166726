export const styles = ({ spacing, palette, typography: { pxToRem, fontWeightMedium } }) => ({
  root: {
    display: 'flex',
    background: palette.background.primary,
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(2)
  },

  icon: {
    display: 'flex',
    fontSize: pxToRem(48),
    color: ({ color }) => palette[color]?.main,
    marginRight: spacing(2)
  },

  info: {

  },

  label: {
    fontSize: pxToRem(18),
    fontWeight: fontWeightMedium,
    lineHeight: 1,
    marginBottom: spacing(0.5)
  },

  amount: {
    fontSize: pxToRem(26),
    color: ({ color }) => palette[color]?.main,
    fontWeight: fontWeightMedium,
    lineHeight: 1
  }
});
