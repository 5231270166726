import { MenuItem, ListItemText } from '@material-ui/core';

export const Option = ({ children, ...props }) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"

      {...props.innerProps}
    >
      <ListItemText
        primary={children}
        primaryTypographyProps={{ noWrap: true, component: 'div' }}
      />
    </MenuItem>
  );
};
