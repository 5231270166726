import { palette } from '../../../../../themes/palette';

export const statusTypes = {
  unproved: 'unproved',
  pending: 'pending',
  submit: 'submit',
  approved: 'approved',
  partial: 'partial',
  paid: 'paid',
  declined: 'declined',
  reimbursement: 'reimbursement'
};

export const statusTypesNames = {
  [statusTypes.unproved]: 'Unproved',
  [statusTypes.pending]: 'Pending',
  [statusTypes.approved]: 'Approved',
  [statusTypes.partial]: 'Partial',
  [statusTypes.paid]: 'Paid',
  [statusTypes.declined]: 'Declined',
  [statusTypes.reimbursement]: 'Reimbursement',
  [statusTypes.submit]: 'Submit'
};

export const statusTypesColors = {
  [statusTypes.unproved]: palette().slategray,
  [statusTypes.pending]: palette().amberyellow,
  [statusTypes.approved]: palette().primary.main,
  [statusTypes.submit]: palette().success.main,
  [statusTypes.partial]: palette().secondary.light,
  [statusTypes.reimbursement]: palette().info.main,
  [statusTypes.paid]: palette().lightgreen,
  [statusTypes.declined]: palette().pink
};
