import moment from 'moment';
import { Yup } from '../../../../../../../utils/validation';
import { formValidationSchema, measurementsValidationSchema } from '../../../../../../../components/medical/forms';

export const validationSchema = formValidationSchema.shape({
  accident_type: Yup.string().nullable().required(),
  auto_accident_state: Yup.string().nullable(),
  auto_accident_country: Yup.string().nullable(),
  emergency_phone: Yup.string().nullable().max(255),
  emergency_relationship: Yup.string().nullable().max(255),
  emergency_contact_id: Yup.mixed().nullable(),
  measurements: measurementsValidationSchema,
  primary_insurance_id: Yup.mixed().nullable().when([ 'isConfirmed', 'is_patient' ], {
    is: (isConfirmed, is_patient) => !isConfirmed && !is_patient,
    then: Yup.mixed().nullable().required()
  }),
  treating_physician_id: Yup.mixed().nullable(),
  unable_work_date_from: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today'),
  unable_work_date_to: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'month'), 'Date must be earlier than today'),
  last_work_date: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'month'), 'Date must be earlier than today'),
  hearing_date:  Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today'),
  vision_date:  Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today')
});
