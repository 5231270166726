import { Typography } from '@material-ui/core';
import { activityActionMap } from '../activitiesHelpers';
import { claimInsInfoActivityMap } from '../activitiesHelpers/claimInsInfoActivityMap';
import { NewFileActivity, UserActivity, UsersActivity } from '../fields';
import { fieldsMap } from '../fields/fieldsMaps';
import { getPropertiesMap } from '../helpers';
import { getActivityComponent } from './actionTypesMap';

export const caseActionTypesMap = {
  [activityActionMap.createCase]: () =>  [
    <Typography variant="subtitle1">Case created</Typography>
  ],

  [activityActionMap.updateCase]: (payload) => {
    const properties = payload?.case?.properties || payload?.properties;
    const propertiesMap = getPropertiesMap(fieldsMap);

    if (properties?.users) {
      return [ <UsersActivity users={properties?.users} /> ];
    }

    return Object.keys(properties)?.reduce((messages, key) => {
      return propertiesMap[key] ? messages.concat(propertiesMap[key](properties)) : messages;
    }, []);
  },

  [activityActionMap.createCaseUser]: (payload) => {
    return [ <UserActivity payload={payload} /> ];
  },

  [activityActionMap.deleteCaseUser]: (payload) => {
    return [ <UserActivity deletedUser payload={payload} /> ];
  },

  [activityActionMap.createCaseFile]: ({ file }) => {
    return [ <NewFileActivity file={file} /> ];
  },

  [activityActionMap.deleteCaseFile]: ({ file }) => {
    return [ <NewFileActivity disableAttach file={file} /> ];
  },

  [activityActionMap.createDeposition]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateDeposition]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteDeposition]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createDiscovery]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateDiscovery]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteDiscovery]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createNegotiation]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [activityActionMap.updateNegotiation]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [activityActionMap.deleteNegotiation]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createCaseClaim]: (payload, logName = activityActionMap.claim) => {
    return getActivityComponent({ payload, logName, isNew: true });
  },

  [activityActionMap.updateCaseClaim]: (payload, logName = activityActionMap.claim) => {
    return getActivityComponent({ payload, logName });
  },

  [activityActionMap.deleteCaseClaim]: (payload, logName = activityActionMap.claim) => {
    return getActivityComponent({ payload, logName, isDeleted: true });
  },

  [claimInsInfoActivityMap.createCaseClaimInsInfo]: (payload) => {
    return getActivityComponent({
      payload,
      logName: claimInsInfoActivityMap.claimCaseInsInfo,
      isNew: true
    });
  },

  [claimInsInfoActivityMap.updateCaseClaimInsInfo]: (payload) => {
    return getActivityComponent({ payload, logName: claimInsInfoActivityMap.claimCaseInsInfo });
  },

  [claimInsInfoActivityMap.deleteCaseClaimInsInfo]: (payload) => {
    return getActivityComponent({
      payload,
      logName: claimInsInfoActivityMap.claimCaseInsInfo,
      isDeleted: true
    });
  }
};
