import { useContext,useState } from 'react';
import { useSnackbar } from 'notistack';
import { mdiFileMoveOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { makeStyles, Collapse, SvgIcon } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import * as printersApi from '../../../../../api/printers';
import { Loader } from '../../../../../components/Loader';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { SelectedItemsCount } from '../../../../../components/SelectedItemsCount';
import { IconButton } from '../../../../../components/IconButton';
import { AppBar } from '../../../../../components/AppBar';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { FilesFromPrintersContext } from '../FilesFromPrintersProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ActionsBar = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { files, printerId, selectedFiles, deleteFiles } = useContext(FilesFromPrintersContext);
  const classes = useStyles();

  const assignFileToUser = ({ isDeletable = false }) => () => {
    setIsLoading(true);

    printersApi.assignFilesToUser(printerId, {
      files: selectedFiles,
      delete_after_assign: isDeletable
    }).then(() => {
      enqueueSnackbar('File(s) assigned', { variant: 'success' });

      if (isDeletable) {
        deleteFiles(selectedFiles);
      }
    }).catch(() => {
      enqueueSnackbar('File(s) is not assigned', { variant: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteFilesFromPrinter = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setIsLoading(true);

        printersApi.deleteFilesFromPrinter(printerId, {
          params: {
            files: selectedFiles
          }
        }).then(() => {
          enqueueSnackbar('File(s) deleted', { variant: 'success' });
          deleteFiles(selectedFiles);
        }).catch(() => {
          enqueueSnackbar('File(s) is not deleted', { variant: 'error' });
        }).finally(() => {
          setIsLoading(false);
        });
      }
    });
  };

  return (
    <Collapse in={!!selectedFiles.length}>
      <AppBar ToolbarProps={{ className: classes.root }}>
        <div className={classes.rightSide}>
          <div className={classes.multiActions}>
            <SelectedItemsCount total={files.length} length={selectedFiles.length} />

            <VerticalDivider verticalGutters={1} horizontalGutters={2} />

            <Loader surface loading={isLoading} render={
              () => (
                <>
                  <IconButton
                    edge="start"
                    title="Copy to Files"
                    onClick={assignFileToUser({ isDeletable: false })}
                  >
                    <FileCopyIcon />
                  </IconButton>

                  <IconButton
                    title="Move to Files"
                    onClick={assignFileToUser({ isDeletable: true })}
                  >
                    <SvgIcon><Icon path={mdiFileMoveOutline} /></SvgIcon>
                  </IconButton>

                  <IconButton
                    edge="end"
                    title="Delete selected files"
                    onClick={deleteFilesFromPrinter}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            />
          </div>
        </div>
      </AppBar>
    </Collapse>
  );
};
