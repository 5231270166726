import { store } from '../../store';

export const getAggregateTypeFromExtension = (extension) => {
  const { aggregate_types } = store.getState().apiConfig.config.files;

  for (let type in aggregate_types) if (aggregate_types.hasOwnProperty(type)) {
    if (aggregate_types[type].extensions.find((type) => type === extension)) {
      return type;
    }
  }
};
