import { useContext, useRef } from 'react';
import { Box, List as MuiList, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
import { Sticky } from '../../../../../components/Sticky';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { TemplatesContext } from '../TemplatesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 54;
export const columnsWidths = {
  name: 270,
  type: 100,
  isSystem: 90,
  date: 160,
  action: 60
};

export const List = () => {
  const {
    templates,
    isFetched,
    isFetching,
    loadNextPage,
    filter
  } = useContext(TemplatesContext);
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
      <Box p={isTablet ? 0 : 2}>
        <Loader loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No templates found</Typography>
          ) : (
            <Paper elevation={0}>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={templates}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  scrollElementRef={scrollElementRef}
                  minRowHeight={minRowHeight}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </Paper>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
