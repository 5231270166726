import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import AddIcon from '@material-ui/icons/Add';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { Page, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { IconButton } from '../../../../../../components/IconButton';
import { Fab } from '../../../../../../components/Fab';
import { styles } from '../../FinancialClass/MainContent/styles';
import { CodesContext } from '../CodesContext';
import { FiltersBar } from '../FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

const useStyles = makeStyles(styles);

export const MainContent = ({ baseUrl }) => {
  const classes = useStyles();
  const { selectedCodesIDs, massActivePayer, massInactivePayer, massDeleteCodes } = useContext(CodesContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper
  } = useMobileFilterCollapse();
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  const toggleMassActivatedItems = () => {
    massActivePayer(selectedCodesIDs);
  };

  const toggleMassInactivatedItems = () => {
    massInactivePayer(selectedCodesIDs);
  };

  const handleMassDelete = () => {
    massDeleteCodes(selectedCodesIDs);
  };

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>Payer</PageHeaderTitle>
      </PageHeader>

      <PageHeader>
        <PageHeaderActions startPosition>
          <Fab
            size={isTablet ? 'small' : 'medium'}
            component={Link}
            to={`${baseUrl}/payer/create`}
            color="primary"
            variant="extended"
            startIcon={<AddIcon />}
          >
            Add payer
          </Fab>
        </PageHeaderActions>

        {!!selectedCodesIDs.length && (
          <PageHeaderActions>
            <IconButton variant="contained" color="warning" onClick={toggleMassInactivatedItems}>
              <Tooltip title="Mass Deactivate Payers">
                <LockIcon />
              </Tooltip>
            </IconButton>

            <IconButton variant="contained" color="success" onClick={toggleMassActivatedItems}>
              <Tooltip title="Mass Activate Payers">
                <LockOpenIcon />
              </Tooltip>
            </IconButton>

            <IconButton variant="contained" color="error" onClick={handleMassDelete}>
              <Tooltip title="Mass Delete Payers">
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </PageHeaderActions>
        )}

        {isTablet && (
          <PageHeaderActions disablePosition>
            {filterOpenButton}
          </PageHeaderActions>
        )}
      </PageHeader>

      <Page variant="outlined" square className={classes.mainContent}>
        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <FiltersBar />
          </FilterBarWrapper>
        </ScrollWrapper>

        {isTablet ? <Grid baseUrl={baseUrl} /> : <List baseUrl={baseUrl} />}
      </Page>
    </Page>
  );
};
