import { useContext, useEffect } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { TablePagination } from '../../../../../../components/TablePagination';
import { Loader } from '../../../../../../components/Loader';
import { PageBody } from '../../../../../../components/Page';
import { TemplatesContext } from '../TemplatesProvider';
import { Header } from './Header';
import { Row } from './Row';

export const minRowHeight = 52;
export const columnsWidths = {
  name: 200,
  office: 200,
  provider: 200,
  actions: 50
};

export const List = () => {
  const { isFetched, meta, templates, resetTemplates } = useContext(TemplatesContext);

  useEffect(() => {
    resetTemplates();
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => (!templates.length ? (
          <Box p={3}>
            <Typography align="center">No templates found</Typography>
          </Box>
        ) : (
          <>
            <Header />

            <PageBody disablePaddings>
              <MuiList disablePadding>
                {templates.map((template) => (
                  <Row key={template.id} template={template} />
                ))}
              </MuiList>
            </PageBody>

            <TablePagination borderTop pagination={meta} onChange={resetTemplates} />
          </>
        ))}
      />
    </Box>
  );
};
