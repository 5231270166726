export const styles = ({ spacing, palette: { divider } }) => ({
  mainInfoCell: {
    display: 'flex',
    alignItems: 'center'
  },

  filesListItemText: {
    textAlign: 'right',
    paddingRight: spacing(2)
  }
});
