import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, Grid, Hidden, InputAdornment, Typography } from '@material-ui/core';
import { PrioritySelect, TextField } from '../../../../FormField';
import { statusMap, getStatus } from '../statusMap';

export const MainContent = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.systolic_blood_pressure && values.diastolic_blood_pressure) {
      setFieldValue('status', getStatus(values.systolic_blood_pressure));
    } else {
      setFieldValue('status', null);
    }
  }, [ values.systolic_blood_pressure, values.diastolic_blood_pressure ]);

  return (
    <Grid container spacing={2} component={Box} pb={4}>
      <Grid item sm={6} xs={12}>
        <Box color="info.main">
          <Typography variant="h4" color="inherit">Temperature</Typography>
        </Box>
      </Grid>

      <Hidden xsDown>
        <Grid item sm={6} xs={12}>
          <Box color="info.main">
            <Typography variant="h4" color="inherit">Blood type</Typography>
          </Box>
        </Grid>
      </Hidden>

      <Grid item sm={6} xs={12}>
        <TextField
          type="number"
          label="Enter value"
          name="temperature"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                &deg;F
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Hidden smUp>
        <Grid item sm={6} xs={12}>
          <Box color="info.main">
            <Typography variant="h4" color="inherit">Blood type</Typography>
          </Box>
        </Grid>
      </Hidden>

      <Grid item sm={6} xs={12}>
        <TextField
          name="blood_type"
          label="Enter value"
        />
      </Grid>

      <Grid item xs={12}>
        <Box color="info.main">
          <Typography variant="h4" color="inherit">Cardiovascular</Typography>
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          type="number"
          label="Heart rate"
          name="heart_rate"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                bpm
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Hidden lgDown>
        <Grid item xs={6} />
      </Hidden>

      <Grid item sm={6} xs={12}>
        <TextField
          type="number"
          label="Systolic Blood Pressure"
          name="systolic_blood_pressure"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                mmHg
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          type="number"
          label="Diastolic Blood Pressure"
          name="diastolic_blood_pressure"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                mmHg
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <PrioritySelect
          isDisabled
          name="status"
          label="Status"
          options={statusMap}
        />
      </Grid>
    </Grid>
  );
};
