import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { Grid, Hidden, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { transformToOption } from '../../../../../helpers/selectHelpers';
import { getUserFullName } from '../../../../../helpers/users';
import { hasRole } from '../../../../../utils/hasRole';
import {
  insuranceFormTypesMap
  // eslint-disable-next-line max-len
} from '../../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/insuranceFormTypesMap';
import {
  PhysicianFormInfo
} from '../../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/PhysicianFormInfo';

import { Divider } from '../../../../Divider';
import {
  DatePicker,
  TextField,
  InsurancesSelect,
  KeyboardDatePicker
} from '../../../../FormField';
import { EmergencyFormContact as GuestEmergencyFormContact } from '../../../guestForms';
import { EmergencyFormContact } from '../../EmergencyFormContact';
import { InsuranceButton } from '../../InsuranceButton';
import { formStatusesMap, formTypesMap } from '../../formTypesMap';
import { DoiForm } from '../../DoiForm';

export const InsuranceInfo = ({ isUnconfirmed = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { values, setFieldValue } = useFormikContext();
  const user = !isUnconfirmed
    ? values?.patient?.id ? { ...values?.patient, medical: values?.medical } : null
    : null;
  const secondaryInsuranceInfo = useMemo(() => {
    return {
      ...values?.insuranceInfo,

      primary_insurance_id_number: null,
      group_id_number: null
    };
  }, [ values?.insuranceInfo ]);

  const handleInsuranceChange = (name) => (insurance) => {
    if (insurance) {
      if (name === 'primary_insurance_id') {
        setFieldValue('primary_insuranceId', insurance?.insuranceId || null);
        setFieldValue('groupID', insurance?.groupId || null);
        setFieldValue('primary_insurance_holder', getUserFullName(insurance?.subscriber_info) || null);
        setFieldValue('relationship_to_patient', insurance?.subscriber_info?.subscriber || null);
        setFieldValue(
          'relationship_dob',
          insurance?.subscriber_info?.dob
            ? moment(insurance?.subscriber_info?.dob).unix()
            : null
        );
      } else {
        setFieldValue('secondary_insuranceId', insurance?.insuranceId || null);
      }
    } else {
      if (name === 'primary_insurance_id') {
        setFieldValue('primary_insuranceId', null);
        setFieldValue('groupID', null);
        setFieldValue('primary_insurance_holder', null);
        setFieldValue('relationship_to_patient', null);
        setFieldValue('relationship_dob', null);
      } else {
        setFieldValue('secondary_insuranceId', null);
      }
    }
  };

  useEffect(() => {
    if (values?.emergency_contact?.relationship) {
      setFieldValue('emergency_relationship',  transformToOption(values?.emergency_contact?.relationship));
    }
  }, [ values?.emergency_contact?.relationship ]);

  return (
    <>
      <Grid item xs={12}>
        <Typography color="secondary" variant="h5">
          Doctor info
        </Typography>
      </Grid>

      <PhysicianFormInfo disableSelect xl={4} lg={4} md={4} xs={6} />

      <Grid item xs={12}>
        <Typography color="secondary" variant="h5">
          Insurance Info
        </Typography>
      </Grid>

      <DoiForm />

      {isUnconfirmed && !values?.patient?.id && (
        <>
          <Grid item xs={12}>
            <Typography color="secondary" variant="h5">
              Information from the patient
            </Typography>
          </Grid>

          <Grid item xl={4} md={4} sm={6} xs={12}>
            <TextField
              disabled
              name="primary_insurance_name"
              label="Primary Insurance"
            />
          </Grid>

          <Grid item xl={4} md={4} sm={6} xs={12}>
            <TextField
              disabled
              name="primary_insurance_id_number"
              label="Insurance ID"
            />
          </Grid>

          <Grid item xl={4} md={4} sm={6} xs={12}>
            <TextField
              disabled
              name="group_id_number"
              label="Group ID"
            />
          </Grid>

          <Grid item xl={2} md={4} sm={6} xs={12}>
            <TextField
              disabled
              name="primary_insurance_holder_first_name"
              label="Pr. Ins. Holder First name"
            />
          </Grid>

          <Grid item xl={2} md={4} sm={6} xs={12}>
            <TextField
              disabled
              name="primary_insurance_holder_last_name"
              label="Pr. Ins. Holder Last name"
            />
          </Grid>

          <Grid item xl={4} md={4} sm={6} xs={12}>
            <TextField
              disabled
              name="relationship_to_patient"
              label="Relationship to Patient"
            />
          </Grid>

          <Grid item xl={4} md={4} sm={6} xs={12}>
            <KeyboardDatePicker
              disabled
              name="primary_insurance_holder_dob"
              label="DOB"
              outputFormat="YYYY-MM-DD"
              placeholder="MM/DD/YYYY"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider disableBottomGutter gutter={1} type="dotted" />
          </Grid>
        </>
      )}

      {!values?.is_confirmed && (
        <InsuranceButton user={user} />
      )}

      {(!!values?.patient?.id || values?.primary_insurance_id || values?.primary_insurance?.id) && (
        <>
          <Grid item lg={4} xs={isMobile ? 12 : 8}>
            <InsurancesSelect
              isCreatable
              required={!hasRole(rolesMap.patient)}
              disabled={hasRole(rolesMap.patient) || (isUnconfirmed && !values?.patient?.id)}
              name="primary_insurance_id"
              label={values?.form_type === formTypesMap.private ? 'Primary Insurance' : 'Law Office'}
              user={user}
              params={{
                only_active: 1,
                disableScan: true,
                type_insurance: values?.form_type !== formTypesMap.private
                  ? insuranceFormTypesMap?.[values?.form_type]
                  : null,
                status_type: values?.form_type === formTypesMap.private
                  ? formStatusesMap.primary
                  : formStatusesMap.law_office,
                medical_id: values?.medical_id || values?.patient?.medical?.id || values?.medical?.id || null
              }}
              insuranceInfo={{
                ...values?.insuranceInfo,

                address: values.home
              }}
              onChange={handleInsuranceChange('primary_insurance_id')}
            />
          </Grid>

          {values?.form_type === formTypesMap.private ?
            <>
              <Grid item lg={2} md={6} xs={isMobile ? 12 : 6}>
                <TextField
                  disabled
                  zeroMinWidth
                  name="primary_insuranceId"
                  label="Insurance ID #"
                />
              </Grid>

              <Grid item lg={2} md={6} xs={isMobile ? 12 : 6}>
                <TextField
                  disabled
                  zeroMinWidth
                  name="groupID"
                  label="Group ID #"
                />
              </Grid>

              <Hidden only={[ 'sm', 'xs', 'md' ]}>
                <Grid item lg={4} />
              </Hidden>
            </>
            :
            <Grid item lg={2} md={6} />
          }

          <Grid item xs={isMobile ? 12 : 4}>
            <TextField
              disabled
              zeroMinWidth
              name="primary_insurance_holder"
              label="Primary Insurance Holder"
            />
          </Grid>

          <Grid item xs={isMobile ? 6 : 4}>
            <TextField
              disabled
              zeroMinWidth
              name="relationship_to_patient"
              label="Relationship to Patient"
            />
          </Grid>

          <Grid item xs={isMobile ? 6 : 4}>
            <DatePicker
              disabled
              zeroMinWidth
              name="relationship_dob"
              label="DOB"
            />
          </Grid>
        </>
      )}

      {!values?.patient?.id && (
        <Hidden only={[ 'sm', 'xs' ]}>
          <Grid item xl={4} md={4} />
        </Hidden>
      )}

      {!!values?.patient?.id && (
        <>
          <Grid item xs={isMobile ? 12 : 4}>
            <InsurancesSelect
              isCreatable
              disabled={hasRole(rolesMap.patient)}
              name="secondary_insurance_id"
              label="Secondary Insurance"
              user={user}
              params={{
                status_type: 'secondary',
                medical_id: values?.medical_id || values?.patient?.medical?.id ||
                  values?.medical?.id || null
              }}
              insuranceInfo={secondaryInsuranceInfo}
              onChange={handleInsuranceChange('secondary_insurance_id')}
            />
          </Grid>

          <Grid item xs={isMobile ? 6 : 4}>
            <TextField
              disabled
              name="secondary_insuranceId"
              label="Insurance ID #"
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        {values?.medical?.id || values?.medical_id ? <EmergencyFormContact /> : <GuestEmergencyFormContact />}
      </Grid>
    </>
  );
};
