import { useRef } from 'react';
import moment from 'moment';
import filesize from 'filesize';
import { IconButton, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useResizeObserver } from '../../../../helpers/hooks';
import { FileTypeIcon } from '../../../../app/Dashboard/files-common';
import { SvgIcon } from '../../../SvgIcon';
import { Scrollbars } from '../../../Scrollbars';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Attachments = ({ attachments, onFileRemove }) => {
  const classes = useStyles();
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const isSmallScreen = width < 480;

  return (
    <Scrollbars autoHeight autoHeightMax={200}>
      <List disablePadding className={classes.root} ref={rootRef}>
        {attachments?.map((file, i) => (
          <ListItem divider className={classes.item} key={i}>
            <FileTypeIcon file={file} className={classes.icon} />

            <ListItemText
              primary={file.name || file.original_filename}
              classes={{ primary: classes.nameText }}
            />

            {!isSmallScreen &&
              <ListItemText
                primary={moment(file.lastModified || file.updated_at).format('L LT')}
                primaryTypographyProps={{ noWrap: true }}
                classes={{ root: classes.time, primary: classes.timeText }}
              />
            }

            {!isSmallScreen &&
              <ListItemText
                primary={file?.size && filesize(file.size)}
                primaryTypographyProps={{ noWrap: true }}
                classes={{ root: classes.size, primary: classes.sizeText }}
              />
            }

            <IconButton onClick={() => onFileRemove(file, i)}>
              <SvgIcon color="error">
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Scrollbars>
  );
};
