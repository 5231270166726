import { useEffect, useState } from 'react';
import { Box, Chip, SvgIcon } from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import { mdiFileDocumentEditOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import moment from 'moment';
import { stopPropagation } from '../../../helpers/dom';
import { useModal } from '../../ModalsProvider';
import { IconButton } from '../../IconButton';
import { useFormikField, TextField } from '../../FormField';
import { ClaimsSelectModal } from './ClaimsSelectModal';

export const ClaimsField = ({ filter, formattedValue = true, params = {}, onChange = () => {}, ...props }) => {
  const { openModal } = useModal();
  const formikFieldProps = useFormikField(props.name);
  const { fieldProps: [ field, , formikHelpers = {} ] = [] } = formikFieldProps;
  const [ claim, setClaim ] = useState(field.value);
  const DOS = claim?.appointment?.appointment_at && moment.unix(claim.appointment?.appointment_at).format('L');

  const openClaimsModal = () => {
    openModal(ClaimsSelectModal, {
      payload: {
        filter
      },

      onModalResolved: (claim) => {
        formikHelpers.setValue(formattedValue ? claim.id : claim);
        setClaim(claim);
        onChange(claim);
      }
    });
  };

  const clear = () => {
    formikHelpers.setValue(null);
    setClaim(null);
    onChange(null);
  };

  useEffect(() => {
    if (typeof(field.value) === 'object') {
      formikHelpers.setValue(formattedValue ? field.value?.id : field?.value);
      setClaim(field.value);
    }
  }, [ field.value ]);

  return (
    <TextField
      label="Claim"
      InputProps={{
        inputComponent: ({ inputRef, ...props }) => (
          <Box flexGrow={1} {...(!claim && props)}>
            {!!claim &&
              <Chip
                tabIndex={-1}
                icon={<SvgIcon><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>}
                label={(DOS ? DOS + ' - ' : '') + claim?.claim_number}
              />
            }
          </Box>
        ),

        endAdornment: (
          <>
            {!!claim &&
              <IconButton size="small" title="Clear" onClick={stopPropagation(clear)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            }

            <IconButton size="small" edge="end" title="Search claims">
              <SearchOutlinedIcon fontSize="small" />
            </IconButton>
          </>
        )
      }}
      onClick={openClaimsModal}

      {...props}
    />
  );
};
