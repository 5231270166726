import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { useContext, useState } from 'react';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as remindersApi from '../../../../../../../../../api/cases/reminders/case-reminders';
import { ColorPreview } from '../../../../../../../../../components/ColorPreview';
import { Date } from '../../../../../../../../../components/Date';
import { IconButton } from '../../../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../../../components/Loader';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import {
  CaseRemindersContext,
  EditCaseReminderModal
} from '../../../../../../../../../components/cases/reminders/case-reminders';

const actionsMap = {
  changeSettings: 'changeSettings',
  toggleActive: 'toggleActive',
  toggleDone: 'toggleDone'
};

export const ReminderRow = ({ item: reminder, isLoaded }) => {
  const { openModal } = useModal();
  const { updateReminder } = useContext(CaseRemindersContext);
  const [ activeActions, setActiveActions ] = useState([]);

  const addActionToActive = (action) => {
    setActiveActions((activeActions) => activeActions.concat(action));
  };

  const removeActionFromActive = (removableAction) => {
    setActiveActions((activeActions) => activeActions.filter((action) => action !== removableAction));
  };

  const changeSettings = (name) => () => {
    addActionToActive(actionsMap.changeSettings);

    remindersApi.updateCaseReminderSettings(reminder, {
      [name]: !reminder.settings[name]
    }).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.changeSettings);
    });
  };

  const toggleActive = () => {
    addActionToActive(actionsMap.toggleActive);

    remindersApi.toggleCaseReminderActive(reminder, {
      is_active: !reminder.is_active
    }).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.toggleActive);
    });
  };

  const doneReminder = () => {
    addActionToActive(actionsMap.toggleDone);

    remindersApi.doneCaseReminder(reminder).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.toggleDone);
    });
  };

  const undoneReminder = () => {
    addActionToActive(actionsMap.toggleDone);

    remindersApi.undoneCaseReminder(reminder).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.toggleDone);
    });
  };

  const editReminder = () => {
    openModal(EditCaseReminderModal, {
      payload: {
        caseReminder: reminder
      },
      onModalResolved: (reminder) => {
        updateReminder(reminder);
      }
    });
  };

  return !isLoaded ? <Loader p={2} /> : (
    <Box px={1} py={0.5}>
      <Paper variant="outlined" component={Box} p={1}>
        <Grid container spacing={1} justify="space-between">
          <Grid item>
            <Date date={reminder?.date} />

            <Box display="flex" alignItems="center">
              <Box display="flex" mr={1}>
                <ColorPreview color={reminder?.group?.color}/>
              </Box>

              <Typography>
                {reminder?.group?.name || '-'}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Loader
                  surface
                  loading={activeActions.includes(actionsMap.changeSettings)}
                  render={() => (
                    <Box display="flex">
                      <IconButton
                        color={reminder?.settings?.by_sms ? 'primary' : 'default'}
                        onClick={changeSettings('by_sms')}
                      >
                        <CallIcon />
                      </IconButton>

                      <IconButton
                        color={reminder?.settings?.by_email ? 'primary' : 'default'}
                        onClick={changeSettings('by_email')}
                      >
                        <MailOutlineOutlinedIcon />
                      </IconButton>
                    </Box>
                  )}
                />
              </Grid>

              <Grid item>
                <Loader
                  surface
                  loading={activeActions.includes(actionsMap.toggleActive)}
                  render={() => (
                    <IconButton
                      color={reminder?.is_active ? 'success' : 'warning'}
                      onClick={toggleActive}
                    >
                      {reminder?.is_active ? (
                        <NotificationsActiveOutlinedIcon />
                      ) : (
                        <NotificationsOffOutlinedIcon />
                      )}
                    </IconButton>
                  )}
                />
              </Grid>

              <Grid item>
                <Loader
                  surface
                  loading={activeActions.includes(actionsMap.toggleDone)}
                  render={() => (
                    <IconButton
                      color={reminder?.done_at ? 'success' : 'warning'}
                      onClick={reminder?.done_at ? undoneReminder : doneReminder}
                    >
                      <CheckCircleOutlinedIcon />
                    </IconButton>
                  )}
                />
              </Grid>

              <Grid item>
                <IconButton color="info" onClick={editReminder}>
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} wrap="nowrap" justify="space-between">
          <Grid item>
            <Typography variant="subtitle1">
              {reminder?.type || '-'}
            </Typography>
          </Grid>

          <Grid item xs component={Box} overflow="auto">
            <Typography noWrap>
              {reminder?.description || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
