import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../helpers/dom';
import { IconButton } from '../../../../../IconButton';
import { SavedFiltersContext } from '../../../../../saved-filters';
import { ListRow, ListRowCell } from '../../../../../ListRow';
import { Loader } from '../../../../../Loader';
import { UserLink } from '../../../../../UserLink';
import { fieldsKeysMap } from '../../filterKeysMap';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  hiddenColumns = [],
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.search}>
        {params?.search || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.createDate}>
        {params?.created_from ? moment.unix(params?.created_from).format('L') : '-'}
        <br />
        {params?.created_to ? moment.unix(params?.created_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dueDate}>
        {params?.due_from ? moment.unix(params?.due_from).format('L') : '-'}
        <br />
        {params?.due_to ? moment.unix(params?.due_to).format('L') : '-' }
      </ListRowCell>

      {!hiddenColumns.includes(fieldsKeysMap.cases) && (
        <ListRowCell flexBasis={columnsWidths.cases}>
          <Box display="flex" flexDirection="column" width="100%">
            {!params?.cases ? '-' : params?.cases?.map((caseItem) => (
              <Typography noWrap key={caseItem?.id} title={caseItem?.name}>{caseItem?.name}</Typography>
            ))}
          </Box>
        </ListRowCell>
      )}

      <ListRowCell flexBasis={columnsWidths.types}>
        <Box display="flex" flexDirection="column" width="100%">
          {!params?.types?.length ? '-' : params?.types?.map((type) => (
            <Typography noWrap key={type?.value} title={type?.label}>{type?.label}</Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.priorities}>
        <Box display="flex" flexDirection="column" width="100%">
          {!params?.priorities?.length ? '-' : params?.priorities?.map((priority) => (
            <Typography noWrap key={priority?.value} title={priority?.label}>{priority?.label}</Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.statuses}>
        <Box display="flex" flexDirection="column" width="100%">
          {!params?.statuses?.length ? '-' : params?.statuses?.map((status) => (
            <Typography key={status?.id}>{status?.name}</Typography>
          )) || '-'}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.users}>
        <Box display="flex" flexDirection="column">
          {!params?.users?.length ? '-' : params?.users?.map((user) => (
            <Box display="flex" key={user?.id}>
              <UserLink
                noWrap
                size="sm"
                user={user}
              />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
