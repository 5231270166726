import { useContext, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { InfiniteGridLoader } from '../../../InfiniteLoader';
import { TimeTrackingContext } from '../../../TimeTracking';
import { Loader } from '../../../Loader';
import { Card } from './Card';

export const Grid = ({ disableActions = false, caseItem }) => {
  const {
    isFetching,
    isFetched,
    tracks,
    meta,
    loadNextPage,
    resetTimeTracking
  } = useContext(TimeTrackingContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetTimeTracking();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={2} pr={0}>
        <Loader p={3} loading={!isFetched} render={
          () => (!tracks.length ? (
            <Box p={3}>
              <Typography align="center">No reports found</Typography>
            </Box>
          ) : (
            <InfiniteGridLoader
              autoSize
              cellWidth={300}
              items={tracks}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={{ last_page: meta.last_page, page: meta.page }}
              CellComponent={Card}
              CellProps={{ disableActions, caseItem }}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
            />
          ))}
        />
      </Box>
    </CustomScrollbars>
  );
};
