export const transformToOption = (item) => item && ({
  value: item,
  label: item
});

export const transformContactsToOption = (item) => item && ({
  value: item.first_name + ' ' + item.last_name,
  label: item,
  extendedProps: { item }
});
