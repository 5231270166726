import { api } from '../api';

export const fetchComments = (config = {}) => {
  return api.get('/comments', config).then(({ data }) => data);
};

export const createComment = (data) => {
  return api.post('/comments', data).then(({ data: { data } }) => data);
};

export const updateComment = ({ id, ...comment }) => {
  return api.put(`/comments/${id}`, comment).then(({ data: { data } }) => data);
};

export const deleteComment = (id) => {
  return api.delete(`/comments/${id}`).then(({ data: { data } }) => data);
};

export const restoreComment = (id) => {
  return api.post(`/comments/${id}/restore`).then(({ data: { data } }) => data);
};
