import { truncate } from 'lodash';
import { Box, Typography, Tooltip } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { OfficeLink } from '../../../../../../../components/OfficeLink';

export const Body = ({ item }) => {
  return (
    <>
      <Box display="flex" alignItems="center" fontWeight={500} mb={0.5}>
        <NotesIcon />

        <Box ml={1} overflow="hidden" textOverflow="ellipsis">
          <Typography noWrap variant="h6">{item.name}</Typography>
        </Box>
      </Box>

      {item.text ?
        <Tooltip arrow leaveDelay={200} title={item.text}>
          <Box
            fontWeight="normal"
            height={38}
            position="relative"
            overflow="hidden"
          >
            <Typography color="textSecondary" component="p" variant="caption" align="justify">
              {item.text && truncate(item.text, { length: 120 })}
            </Typography>
          </Box>
        </Tooltip>
        :
        <Typography color="textSecondary">-</Typography>
      }

      <Box display="flex" alignItems="center" mt={2}>
        <Box clone pr={2}>
          <Typography variant="caption">Office:</Typography>
        </Box>

        <OfficeLink noWrap variant="h5" office={item.office} />
      </Box>
    </>
  );
};
