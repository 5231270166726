import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as appointmentsApi from '../../../../api/appointments';
import * as types from '../../files-common/FilesContext/types';

export const ReportsPreviewContext = createContext();

export const ReportsPreviewProvider = ({ appointment_id, children, fetchItem, onUpdate }) => {
  const [ selectedItem, setSelectedItem ] = useState({});
  const [ appointment, setAppointment ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);
  const filesLastGlobalAction = useSelector(({ globalActions }) => globalActions.filesLastGlobalAction);
  const lastUploadedFile = useSelector(({ uploads }) => uploads.lastUploadedFile);

  useEffect(() => {
    setIsFetched(false);

    fetchItem().then((data) => {
      setSelectedItem(data);

      if (appointment_id || data?.appointment?.id) {
        appointmentsApi.fetchAppointment(appointment_id || data?.appointment?.id).then((appointment) => {
          setAppointment(appointment);
          setIsFetched(true);
        }).catch(() => {
          setAppointment(null);
          setIsFetched(true);
        });
      } else {
        setIsFetched(true);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedItem?.media && lastUploadedFile) {
      setSelectedItem(({ media, ...state }) => ({
        ...state,
        media: [ ...media, lastUploadedFile ]
      }));
      onUpdate && onUpdate();
    }
  }, [ lastUploadedFile ]);

  useEffect(() => {
    if (selectedItem?.media && filesLastGlobalAction) {
      if (filesLastGlobalAction.type === types.DELETE_FILES) {
        setSelectedItem(({ media, ...state }) => ({
          ...state,
          media: media.filter((file) => filesLastGlobalAction.payload.indexOf(file.id) < 0)
        }));
      }

      if (filesLastGlobalAction.type === types.ADD_FILES) {
        setSelectedItem(({ media, ...state }) => ({
          ...state,
          media: [ ...media, ...filesLastGlobalAction.payload ]
        }));
      }

      onUpdate && onUpdate();
    }
  }, [ filesLastGlobalAction ]);

  const providerValue = {
    isFetched,
    selectedItem,
    appointment,
    setSelectedItem,
    setAppointment
  };

  return (
    <ReportsPreviewContext.Provider value={providerValue}>
      {children}
    </ReportsPreviewContext.Provider>
  );
};
