import { makeStyles, Box, useTheme, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { hasRole } from '../../../../utils/hasRole';
import { Button } from '../../../../components/Button';
import {
  Page,
  PageBody,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../components/Page';
import { CategoriesContext, CategoriesProvider } from './CategoriesProvider';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

const useStyles = makeStyles(({ palette: { grey } }) => ({
  root: {
    height: '100%',
    backgroundColor: grey[100]
  }
}));

export const TimeTrackCategory = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper
  } = useMobileFilterCollapse();
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  return (
    <div className={classes.root}>
      <CategoriesProvider>
        <Box display="flex" py={isMobile ? 0 : 2} px={isMobile ? 0 : 3} height="100%">
          <Page>
            <PageHeader>
              <PageHeaderTitle>Timetrack Categories</PageHeaderTitle>

              {hasRole(rolesMap.admin, rolesMap.supervisor) &&
                <PageHeaderActions>
                  <CategoriesContext.Consumer>
                    {({ createCategory }) => (
                      <Button
                        size={isTablet ? 'small' : 'medium'}
                        color="primary"
                        variant="contained"
                        startIcon={<AddIcon/>}
                        onClick={createCategory}
                      >
                        Add category
                      </Button>
                    )}
                  </CategoriesContext.Consumer>
                </PageHeaderActions>
              }

              {isTablet && (
                <PageHeaderActions disablePosition>
                  {filterOpenButton}
                </PageHeaderActions>
              )}
            </PageHeader>

            <PageBody disablePaddings disableScrollbars fullHeight>
              <Box display="flex" flexDirection="column" height="100%">
                <ScrollWrapper {...scrollWrapperProps}>
                  <FilterBarWrapper {...filterBarWrapperProps}>
                    <FiltersBar />
                  </FilterBarWrapper>
                </ScrollWrapper>

                <Box flexGrow={1}>
                  {!isTablet ? <List /> : <Grid />}
                </Box>
              </Box>
            </PageBody>
          </Page>
        </Box>
      </CategoriesProvider>
    </div>
  );
};
