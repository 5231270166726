import { UsersSelect } from '../UsersSelect';

export const ProfilesSelect = ({ params = {}, ...props }) => {
  return (
    <UsersSelect
      getOptionValue={(user) => user?.profile_id}
      getOptionSelected={(option, value) => option?.profile_id === value?.profile_id}

      {...props}
    />
  );
};
