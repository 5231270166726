export const COMPANIES_FETCH_REQUEST = 'COMPANIES_FETCH_REQUEST';
export const COMPANIES_FETCH_SUCCESS = 'COMPANIES_FETCH_SUCCESS';
export const ADD_COMPANY = 'ADD_COMPANY';
export const APPLY_FILTER = 'APPLY_FILTER';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const RESET_COMPANIES = 'RESET_COMPANIES';
export const MASS_DELETE = 'MASS_DELETE';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
