import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../PaymentItem';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = ({ disableInvoicePreview }) => {
  const classes = useStyles();

  return (
    <ListRow header className={classes.root}>
      <ListRowCell grow flexBasis={columnsWidths.number} className={classes.content}>
        Number
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.invoice_date} className={classes.content}>
        Invoice paid date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.due_on} className={classes.content}>
        Due on
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total} className={classes.content}>
        Total
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount} className={classes.content}>
        Payment Amount
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.balance} className={classes.content}>
        Balance
      </ListRowCell>

      {!disableInvoicePreview && (
        <ListRowCell flexBasis={columnsWidths.action} />
      )}
    </ListRow>
  );
};
