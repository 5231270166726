import { Box, Grid, Typography } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../ReadonlyCheckbox';

export const Medicaid = ({ claim }) => {
  return (
    <Grid container spacing={2} component={Box} px={2} py={3}>
      <Grid item xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">
            Medicaid
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Resubmission Code
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {claim?.situational_number?.resubmission_code || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Original Reference #
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {claim?.situational_number?.original_reference || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Service Auth Exception Code
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {claim?.situational_number?.service_auth_exception_code || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Medical Referral Access #
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {claim?.situational_number?.medical_referral_access || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Family Planning
        </Typography>
      </Grid>

      <Grid item sm={9} xs={6}>
        <ReadonlyCheckbox
          disableTitle
          iconColor="success"
          checked={!!claim?.situational_number?.is_family_planning}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          EPSDT
        </Typography>
      </Grid>

      <Grid item sm={9} xs={6}>
        <ReadonlyCheckbox
          disableTitle
          iconColor="success"
          checked={!!claim?.situational_number?.is_epsdt}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Copay Exempt
        </Typography>
      </Grid>

      <Grid item sm={9} xs={6}>
        <ReadonlyCheckbox
          disableTitle
          iconColor="success"
          checked={!!claim?.situational_number?.is_copay_exempt}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          EPSDT Condition Code
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {claim?.situational_number?.epsdt_condition_code?.label || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          EPSDT Referral Code
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {claim?.situational_number?.epsdt_referral_code || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};
