import { lighten } from '@material-ui/core/styles';

export const styles = ({ spacing, palette, transitions }) => ({
  root: ({ event }) => {
    const typeColor = event?.type?.color;
    const bgColor = typeColor ? lighten(typeColor, 0.8) : palette.grey[100];
    const borderColor = typeColor ? lighten(typeColor, 0.8) : palette.grey[500];
    const borderLeftColor = typeColor || palette.grey[500];

    return {
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      padding: spacing(0.5, 0.75),
      border: `1px solid ${borderColor}`,
      borderLeft: `5px solid ${borderLeftColor}`,
      borderRadius: 5,
      cursor: 'pointer',
      backgroundColor: bgColor,
      transition: transitions.create([ 'border-color' ]),

      '&:hover': {
        borderColor: typeColor
      }
    };
  },

  loopIcon: {
    color: palette.text.primary
  }
});
