import _ from 'lodash';
import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_PROFILE_SUCCESS]: (state) => {
    return {
      ...state,

      isFetched: false
    };
  },

  [types.SET_PROFILE_DATA]: (state, payload) => {
    return {
      ...state,

      user: _.merge({}, state.user, payload)
    };
  },

  [types.FETCH_PROFILE_SUCCESS]: (state, payload) => {
    return {
      ...state,

      isFetched: true,
      user: payload
    };
  }
});
