import { api } from '../api';

export const fetchServiceLines = (config) => {
  return api.get('/service-lines', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchServiceLine = (id, config) => {
  return api.get(`/service-lines/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createServiceLine = (data) => {
  return api.post('/service-lines', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const updateServiceLine = ({ id, ...data }) => {
  return api.put(`/service-lines/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteServiceLine = (id) => {
  return api.delete(`/service-lines/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
