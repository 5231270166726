import { mdiBellCogOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as reminderEventsApi from '../../../../../api/cases/reminders/events';
import { ModalFooter, ModalHeader, ModalBody, ModalContainer } from '../../../../Modal';
import { Loader } from '../../../../Loader';
import { ReminderEventForm, initialValues, validationSchema } from '../ReminderEventForm';

export const CreateReminderEventModal = ({
  DialogProps,
  payload: {
    initialValues: initialValuesProp= {}
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const addReminderEvent = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return reminderEventsApi.createCaseReminderEvent(values).then((event) => {
      enqueueSnackbar('Reminders event successfully created', { variant: 'success' });
      handleModalResolve(event);
    }).catch(({ data: { errors } }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Reminders event not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{ ...initialValues, ...initialValuesProp }}
      validationSchema={validationSchema}
      onSubmit={addReminderEvent}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiBellCogOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Add event
            </ModalHeader>

            <ModalBody>
              <ReminderEventForm />
            </ModalBody>

            <ModalFooter>
              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
