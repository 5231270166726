import { flow } from 'lodash';
import { useContext } from 'react';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { AppointmentEditModal } from '../../../../../components/appointments';
import { useModal } from '../../../../../components/ModalsProvider';
import { EditProceduresModal } from '../EditProceduresModal';
import { PreviewProceduresModal } from '../PreviewProceduresModal';
import { ProcedureContext } from '../ProcedureProvider';

export const ProceduresMenu = ({ procedure, onClose }) => {
  const { openModal } = useModal();
  const { appointmentType, fetchProcedures, deleteProcedure } = useContext(ProcedureContext);

  const handleUpdateProcedure = () => {
    openModal(EditProceduresModal, {
      payload: {
        id: procedure.id,
        onUpdate: fetchProcedures
      }
    });
  };

  const handleUpdateAppointment = () => {
    openModal(AppointmentEditModal, {
      payload: {
        appointmentType,
        disableAttachments: true,
        appointmentID: procedure.appointment_id
      },
      onModalResolved: () => {
        fetchProcedures();
      }
    });
  };

  const handleDeleteProcedure = () => {
    deleteProcedure(procedure.id);
  };

  const openPreview = () => {
    openModal(PreviewProceduresModal, {
      payload: {
        appointmentType,
        procedure: procedure,
        onUpdate: fetchProcedures
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={flow(openPreview, onClose)}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <>
          <MenuItem onClick={flow(handleUpdateProcedure, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit procedure" />
          </MenuItem>

          <MenuItem onClick={flow(handleUpdateAppointment, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit appointment" />
          </MenuItem>

          <MenuItem onClick={flow(handleDeleteProcedure, onClose)}>
            <ListItemIcon>
              <DeleteIcon color="error" fontSize="inherit"/>
            </ListItemIcon>

            <ListItemText primary="Delete" />
          </MenuItem>
        </>
      }
    </List>
  );
};
