import { useContext } from 'react';
import T from 'prop-types';
import cn from 'classnames';
import { makeStyles, Checkbox } from '@material-ui/core';
import { ListRowContext } from '../ListRow';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  checked: T.bool,
  onClick: T.func
};

export const ListRowCheckbox = ({
  checked,
  onClick,
  className,
  ...props
}) => {
  const { header } = useContext(ListRowContext);
  const classes = useStyles();

  return (
    <Checkbox
      className={cn(
        className,
        classes.checkbox,
        { [classes.checkbox_header]: header }
      )}
      checked={checked}
      onClick={onClick}
      {...props}
    />
  );
};

ListRowCheckbox.propTypes = propTypes;
