import { Box, Grid, Typography } from '@material-ui/core';

export const Lab = ({ serviceLine }) => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">
          Lab
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Hemoglobin
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="subtitle2">
            {serviceLine.hemoglobin || '-'}
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            Hematocrit
          </Typography>
        </Grid>

        <Grid item md={6}>
          <Typography variant="subtitle2">
            {serviceLine.hematocrit || '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
