import { useEffect, useState } from 'react';
import { makeStyles, Button, Paper, TextField } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { amFormatDuration } from '../../../../../../helpers/dates';
import * as videoApi from '../../../../../../api/videos';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CreateForm = ({ video, seconds, onAddNote, setIsFetched }) => {
  const classes = useStyles();
  const [ fields, setFields ] = useState({});

  const addNotes = () => {
    setIsFetched(false);

    videoApi.addNote(video.id, fields).then((data) => {
      onAddNote(data);
    });
  };

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
  };

  useEffect(() => {
    setFields((fields) => ({ ...fields, time: amFormatDuration(seconds || 0) }));
  }, [ seconds ]);

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.avatarIcon}>
          <AccountCircle/>
        </div>

        <TextField
          fullWidth
          spacing={1}
          name="comment"
          onChange={handleFieldChange('comment')}
        />
      </Paper>

      <div className={classes.notes}>
        <Button type="reset">
          Cancel
        </Button>

        <Button
          autoFocus
          type="submit"
          variant="contained"
          color="primary"
          onClick={addNotes}
        >
          ADD
        </Button>
      </div>
    </>
  );
};
