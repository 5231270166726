export const styles = ({ spacing, typography: { pxToRem } }) => ({
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(248px, 1fr))',
    gridGap: spacing(2),
    marginBottom: spacing(2)
  },

  text: {
    fontSize: pxToRem(10)
  }
});
