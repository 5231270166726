import { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import * as documentsApi from '../../../../../../../api/profile';
import { Button } from '../../../../../../../components/Button';
import { Loader } from '../../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalHeaderActions
} from '../../../../../../../components/Modal';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { SignDocumentModal } from '../SignDocumentModal';
import { styles } from './styles';

export const DocumentsModal = ({
  DialogProps,
  handleModalReject,
  payload: { documentID, fetchDocuments }
}) => {
  const { openModal } = useModal();
  const [ document, setDocument ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const fetchDocument = (id) => {
    setIsFetched(false);

    documentsApi.fetchMedicalDocument(id).then((data) => {
      setDocument(data);
      setIsFetched(true);
    });
  };

  const handleSignDocument = () => {
    openModal(SignDocumentModal, {
      onModalResolved: (data) => {
        setIsFetched(false);

        documentsApi.signMedicalDocument(documentID, data).then(() => {
          fetchDocuments();
          fetchDocument(documentID);
          enqueueSnackbar('Successfully signed', { variant: 'success' });
        }).catch(({ errors, message }) => {
          if (errors && !message) {
            const errorMessage = Object.values(errors);

            enqueueSnackbar(errorMessage, { variant: 'error' });
          } else {
            enqueueSnackbar(message, { variant: 'error' });
          }

          fetchDocuments();
          fetchDocument(documentID);
        });
      }
    });
  };

  const handleDownloadPDF = () => {
    documentsApi.getPDF(documentID).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ]));

      saveAs(url, `${document.name}.pdf`);
    });
  };

  useEffect(() => {
    fetchDocument(documentID);
  }, []);

  return (
    <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
      <ModalContainer>
        <Loader loading={!isFetched} p={3} render={
          () => (
            <>
              <ModalHeader onClose={handleModalReject}>
                <ModalHeaderActions disableGutters>
                  <Button
                    color="primary"
                    onClick={handleDownloadPDF}
                  >
                    Generate pdf
                  </Button>
                </ModalHeaderActions>
              </ModalHeader>

              <ModalBody disablePaddings={isTablet}>
                <div dangerouslySetInnerHTML={{ __html: document.content }} />
                <style dangerouslySetInnerHTML={{ __html: styles }} />
              </ModalBody>

              <ModalFooter>
                <Button color="primary" type="reset" onClick={handleModalReject}>
                  Cancel
                </Button>

                {document?.need_you_sign &&
                  <Loader surface loading={!isFetched} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleSignDocument}
                      >
                        Sign document
                      </Button>
                    )}
                  />
                }
              </ModalFooter>
            </>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
