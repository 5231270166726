import { Grid } from '@material-ui/core';
import { dataURItoBlob } from '../../../../../../../helpers/files';
import { Preview } from './Preview';

export const Upload = ({ onChange }) => {
  const handleInit = (key) => () => {
    onChange({ [key]: null });
  };

  const handleSignatureChange = (key) => (dataURI) => {
    onChange({ [key]: dataURItoBlob(dataURI) });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7}>
        <Preview
          label="Full Name"
          onInit={handleInit('electronic_signature_file')}
          onChange={handleSignatureChange('electronic_signature_file')}
        />
      </Grid>

      <Grid item xs={12} md={5}>
        <Preview
          label="Initials"
          onInit={handleInit('fax_signature_file')}
          onChange={handleSignatureChange('fax_signature_file')}
        />
      </Grid>
    </Grid>
  );
};
