import { activitySessionTypesMap, activityTypesMap, subjectLabels } from '../activitiesHelpers';
import { ClaimActivity, ClaimCaseInsInfoActivity } from '../ClaimActivity';
import { Comment, Expense, Income, Invoice, ScheduleEvent } from '../fields';
import { AppointmentsActivity } from '../fields/AppointmentsActivity';
import { Deposition } from '../fields/Deposition';
import { Discovery } from '../fields/Discovery';
import { Emails } from '../fields/Emails';
import { Negotiations } from '../fields/Negotiations';
import { Payment } from '../fields/Payment';
import { TaskChecklistField } from '../fields/TaskChecklistField';
import { ReminderActivity } from '../ReminderActivity';
import { SipCallReportActivity } from '../SipCallReportActivity';
import { TaskActivity } from '../TaskActivity';
import { TimeTrackActivity } from '../TimeTrackActivity';
import { WorkSessionActivity } from '../WorkSessionActivity';

export const activitiesActionsMap = {
  [activityTypesMap.expense]: {
    label: subjectLabels[activityTypesMap.expense],
    component: Expense
  },
  [activityTypesMap.task]: {
    label: subjectLabels[activityTypesMap.task],
    component: TaskActivity
  },
  [activityTypesMap.claim]: {
    label: subjectLabels[activityTypesMap.claim],
    component: ClaimActivity
  },
  [activityTypesMap.caseDeposition]: {
    label: subjectLabels[activityTypesMap.caseDeposition],
    component: Deposition
  },
  [activityTypesMap.caseDiscovery]: {
    label: subjectLabels[activityTypesMap.caseDiscovery],
    component: Discovery
  },
  [activityTypesMap.caseNegotiat]: {
    label: subjectLabels[activityTypesMap.caseNegotiat],
    component: Negotiations
  },
  [activityTypesMap.income]: {
    label: subjectLabels[activityTypesMap.income],
    component: Income
  },
  [activityTypesMap.invoice]: {
    label: subjectLabels[activityTypesMap.invoice],
    component: Invoice
  },
  [activityTypesMap.payment]: {
    label: subjectLabels[activityTypesMap.payment],
    component: Payment
  },
  [activityTypesMap.profile]: {
    label: subjectLabels[activityTypesMap.profile],
    component: () => <></> // add component
  },
  [activityTypesMap.scheduleEvent]: {
    label: subjectLabels[activityTypesMap.scheduleEvent],
    component: ScheduleEvent
  },
  [activityTypesMap.scheduleRecurringTemplate]: {
    label: subjectLabels[activityTypesMap.scheduleRecurringTemplate],
    component: ScheduleEvent
  },
  [activityTypesMap.sharedMedia]: {
    label: subjectLabels[activityTypesMap.sharedMedia],
    component: () => <></> // add component
  },
  [activityTypesMap.appointment]: {
    label: subjectLabels[activityTypesMap.appointment],
    component: AppointmentsActivity
  },
  [activityTypesMap.userEmailMessage]: {
    label: subjectLabels[activityTypesMap.userEmailMessage],
    component: Emails
  },
  [activityTypesMap.todoItem]: {
    label: subjectLabels[activityTypesMap.todoItem],
    component: TaskChecklistField
  },
  [activityTypesMap.comment]: {
    label: subjectLabels[activityTypesMap.comment],
    component: Comment
  },
  [activityTypesMap.claimInsuranceInformation]: {
    label: subjectLabels[activityTypesMap.claimInsuranceInformation],
    component: ClaimCaseInsInfoActivity
  },
  [activitySessionTypesMap.workSession]: {
    label: subjectLabels[activitySessionTypesMap.workSession],
    component: WorkSessionActivity
  },
  [activitySessionTypesMap.workClock]: {
    label: subjectLabels[activitySessionTypesMap.workClock],
    component: WorkSessionActivity
  },
  [activityTypesMap.timeTrack]: {
    label: subjectLabels[activityTypesMap.timeTrack],
    component: TimeTrackActivity
  },
  [activitySessionTypesMap.workClockClockIn]: {
    label: subjectLabels[activitySessionTypesMap.workClockClockIn],
    component: WorkSessionActivity
  },
  [activitySessionTypesMap.workClockClockOut]: {
    label: subjectLabels[activitySessionTypesMap.workClockClockOut],
    component: WorkSessionActivity
  },
  [activitySessionTypesMap.workClockReport]: {
    label: subjectLabels[activitySessionTypesMap.workClockReport],
    component: WorkSessionActivity
  },
  [activitySessionTypesMap.sipCallAttachParent]: {
    label: subjectLabels[activitySessionTypesMap.sipCallAttachParent],
    component: SipCallReportActivity
  },
  [activitySessionTypesMap.sipCallDetachParent]: {
    label: subjectLabels[activitySessionTypesMap.sipCallDetachParent],
    component: SipCallReportActivity
  },
  [activitySessionTypesMap.addCaseReminderGroupToCase]: {
    label: subjectLabels[activitySessionTypesMap.addCaseReminderGroupToCase],
    component: ReminderActivity
  },
  [activitySessionTypesMap.removeCaseReminderGroupFromCase]: {
    label: subjectLabels[activitySessionTypesMap.removeCaseReminderGroupFromCase],
    component: ReminderActivity
  },
  [activitySessionTypesMap.makeCaseReminderAsActive]: {
    label: subjectLabels[activitySessionTypesMap.makeCaseReminderAsActive],
    component: ReminderActivity
  },
  [activitySessionTypesMap.makeCaseReminderAsInactive]: {
    label: subjectLabels[activitySessionTypesMap.makeCaseReminderAsInactive],
    component: ReminderActivity
  },
  [activitySessionTypesMap.makeCaseReminderAsDone]: {
    label: subjectLabels[activitySessionTypesMap.makeCaseReminderAsDone],
    component: ReminderActivity
  },
  [activitySessionTypesMap.makeCaseReminderAsNotDone]: {
    label: subjectLabels[activitySessionTypesMap.makeCaseReminderAsNotDone],
    component: ReminderActivity
  }
};
