import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import { filtersKeysMap } from '../../../store/lastFilters';
import { Schedule } from '../../../components/schedule-events';
import { ScheduleEventTypes } from './ScheduleEventTypes';
import { ScheduleEventTemplates } from './ScheduleEventTemplates';

export const routes = [
  {
    exact: true,
    path: '/schedule',
    icon: <DateRangeOutlinedIcon />,
    label: 'Schedule',
    component: () => <Schedule filterKey={filtersKeysMap.calendar_schedule_main} />
  },
  {
    path: '/schedule/schedule-event-types',
    icon: <DateRangeOutlinedIcon />,
    label: 'Schedule event types',
    component: ScheduleEventTypes
  },
  {
    path: '/schedule/schedule-event-templates',
    icon: <DateRangeOutlinedIcon />,
    label: 'Schedule event templates',
    component: ScheduleEventTemplates
  }
];
