import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import { createTag } from '../../../api/files/tags';
import { ModalFooter, ModalHeader, ModalBody } from '../../Modal';
import { TextField, ColorPicker } from '../../FormField';
import { Loader } from '../../Loader';
import { validationSchema } from '../validationSchema';

export const CreateFolderModal = ({
  DialogProps,
  payload: {
    initialValues = {}
  },
  handleModalResolve,
  handleModalReject
}) => {
  const createFolder = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return createTag(values).then((folder) => {
      handleModalResolve(folder);
    }).catch((errors) => {
      setErrors(errors);
    });
  };

  return (
    <Formik
      initialValues={{
        is_folder: 1,
        name: null,
        color: null,

        ...initialValues
      }}
      validationSchema={validationSchema}
      onSubmit={createFolder}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject} icon={<FolderOutlinedIcon />}>
              Add folder
            </ModalHeader>

            <ModalBody>
              <TextField
                required
                name="name"
                label="Folder name"
                placeholder="Enter folder name..."
                margin="dense"
              />

              <ColorPicker
                required
                name="color"
                label="Color"
                placeholder="Select color..."
              />
            </ModalBody>

            <ModalFooter>
              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    autoFocus
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
