import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  Button,
  Paper,
  Typography,
  Divider,
  Box,
  Link
} from '@material-ui/core';
import * as resetApi from '../../../api/auth';
import { Loader } from '../../../components/Loader';
import { useModal } from '../../../components/ModalsProvider';
import { Scrollbars } from '../../../components/Scrollbars';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { login } from '../../../store/auth/operations';
import { fetchProfile } from '../../../store/dashboard/profile';
import { hasRole } from '../../../utils/hasRole';
import { Yup } from '../../../utils/validation';
import { lang } from '../../../lang';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { TextField, PasswordField } from '../../../components/FormField';
import FullLogoSVG from '../../Dashboard/Layout/Sidebar/SidebarContent/logo-full.svg';
import { LandingWrapper } from '../../LandingPage';
import { styles } from './styles';

const initialValues = {
  email: null,
  password: null
};

const validationSchema = Yup.object().shape({
  email: Yup.string().nullable().required(),
  password: Yup.string().nullable().required('Password is required')
});

const useStyles = makeStyles(styles);

export const Login = ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const companyName = lang[import.meta.env.VITE_COMPANY_ID]?.name;

  const makeLogin = (user, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return dispatch(login(user)).then((data) => {
      if (data.email_confirmation_required) {
        history.push('/login-confirmation', location.state);

        return;
      }

      dispatch(fetchProfile());

      if (hasRole(rolesMap.client, rolesMap.patient) && !data?.user?.visited_at) {
        history.push('/profile/medical-info/medical-forms');
      } else if (hasRole(rolesMap.client, rolesMap.patient)) {
        history.push('/profile');
      } else {
        history.push(location.state?.from || '/');
      }
    }).catch(() => {
      setErrors({ login: 'true' });
    });
  };

  const resetPassword = (email) => () => {
    if (!email) {
      enqueueSnackbar('Please enter email address to reset password', { variant: 'warning' });
    } else {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          resetApi.resetPassword({ email: email }).then(() => {
            enqueueSnackbar('Reset requested!', { variant: 'success' });
            history.push('/reset');
          }).catch(() => {
            enqueueSnackbar('Please, enter valid email address', { variant: 'error' });
          });
        }
      });
    }
  };

  return (
    <LandingWrapper>
      <Scrollbars>
        <Paper className={classes.paper}>
          <div className={classes.paper__header}>
            <FullLogoSVG className={classes.paper__icon} />

            {!!companyName &&
              <Typography align="center" variant="h1" color="inherit">
                {companyName}
              </Typography>
            }

            <Typography variant="h2" color="inherit" className={classes.paper__title}>
              Welcome back, please login to your account
            </Typography>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={makeLogin}
          >
            {({ errors, handleSubmit, isSubmitting, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.login &&
                  <Box color="warning.main">
                    <Typography paragraph color="inherit">
                      You have entered an invalid pair of email and password
                    </Typography>

                    <Divider />
                  </Box>
                }

                <TextField
                  autoFocus
                  required
                  type="email"
                  name="email"
                  className={classes.input}
                  label="Email Address"
                  autoComplete="email"
                  margin="dense"
                />

                <PasswordField
                  required
                  className={classes.input}
                  name="password"
                  label="Password"
                  margin="dense"
                  visibilityButtonProps={{
                    color: 'primary'
                  }}
                />

                <Box
                  display="flex"
                  alignItems="baseline"
                  justifyContent="flex-end"
                  mt={3}
                  mb={1}
                  color="common.white"
                >
                  <Typography variant="body1">
                    <Link
                      color="inherit"
                      underline="always"
                      onClick={resetPassword(values.email)}
                    >
                      Forgot password
                    </Link>
                  </Typography>
                </Box>

                <Loader surface fullWidth loading={isSubmitting} render={
                  () => (
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Sign in
                    </Button>
                  )}
                />
              </form>
            )}
          </Formik>
        </Paper>
      </Scrollbars>
    </LandingWrapper>
  );
};
