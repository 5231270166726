import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IconButton, MenuItem, ListItemIcon, ListItemText, List } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { ConfirmationModal } from '../../../../../ConfirmationModal';
import { stopPropagation } from '../../../../../../helpers/dom';
import * as eventsApi from '../../../../../../api/schedule-events';
import { useModal } from '../../../../../ModalsProvider';
import { Popper } from '../../../../../Popper';
import { Tooltip } from '../../../../../Tooltip';
import { DeleteScheduleEventModal } from '../../../../DeleteScheduleEventModal';
import { EditScheduleEventModal } from '../../../../EditScheduleEventModal';

export const Menu = ({ hideApplyFields, event, onEventUpdate, onEventDelete }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const currentUser = useSelector(({ profile }) => profile.user);
  const isAbleToEditAndDelete = hasRole(rolesMap.admin, rolesMap.supervisor) || event?.users?.some((user) => {
    return user?.id === currentUser?.id && user?.schedule_event.is_creator;
  });

  const editEvent = () => {
    openModal(EditScheduleEventModal, {
      payload: {
        hideApplyFields,
        event
      },

      onModalResolved: (event) => {
        onEventUpdate(event);
      }
    });
  };

  const deleteEvent = () => {
    if (!event.is_recurring_dummy) {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          eventsApi.deleteEvent(event.id).then(() => {
            onEventDelete(event);
            enqueueSnackbar(`Event "${event.title}" successfully deleted`, { variant: 'success' });
          });
        }
      });
    } else {
      openModal(DeleteScheduleEventModal, {
        payload: { event },
        onModalResolved: () => {
          onEventDelete(event);
        }
      });
    }
  };

  return (
    <Popper
      placement="left-start"
      anchorRenderer={
        ({ anchorRef, handleToggle }) => (
          <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
            <MoreVertIcon />
          </IconButton>
        )
      }
    >
      {({ handleClose }) => (
        <List>
          <Tooltip isExistTooltip={!isAbleToEditAndDelete} title="Don't have permissions to edit">
            <div>
              <MenuItem disabled={!isAbleToEditAndDelete} onClick={_.flow(editEvent, handleClose)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>

                <ListItemText primary="Edit event" />
              </MenuItem>
            </div>
          </Tooltip>

          <Tooltip isExistTooltip={!isAbleToEditAndDelete} title="Don't have permissions to delete">
            <div>
              <MenuItem disabled={!isAbleToEditAndDelete} onClick={_.flow(deleteEvent, handleClose)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>

                <ListItemText primary="Delete event" />
              </MenuItem>
            </div>
          </Tooltip>
        </List>
      )}
    </Popper>
  );
};
