import { useState } from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { IconButton } from '../../IconButton';

export const UserSSN = ({
  user,
  isShowable = false,
  title = '',
  TypographyProps = {},
  ...props
}) => {
  const [ isOpenSSN, setIsOpenSSN ] = useState(false);
  //TODO: fix after Back End add to members ssn if exist ssn_last
  const ssn = user.ssn || user?.profile?.ssn || user?.patient?.ssn ||
    (user?.ssn_last || user?.profile?.ssn_last || user?.patient?.ssn_last);
  const ssn_last = ssn && (user?.ssn_last || user?.profile?.ssn_last ||
    user?.patient?.ssn_last || ssn.substring(ssn.length - 4));

  const handleOpenSSN = () => {
    setIsOpenSSN((state) => !state);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
      {...props}
    >
      {ssn_last ? (
        <Typography noWrap variant="h5" {...TypographyProps}>
          {isOpenSSN && ssn ? ssn : `${title} ***-**-${ssn_last}`}
        </Typography>
      ) : (
        <Typography noWrap variant="caption" color="textSecondary">No info</Typography>
      )}

      {isShowable && ssn &&
        <Tooltip arrow title={isOpenSSN ? 'Hide ssn' : 'Show ssn'}>
          <IconButton color="primary" onClick={handleOpenSSN}>
            <EyeIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      }
    </Box>
  );
};
