export const columnsMap = {
  dragIcon: 'dragIcon',
  file: 'file',
  selectPages: 'selectPages',
  actions: 'actions'
};

export const columnsWidths = {
  [columnsMap.dragIcon]: 32,
  [columnsMap.file]: 200,
  [columnsMap.selectPages]: 420,
  [columnsMap.actions]: 46
};
