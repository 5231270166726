import moment from 'moment';
import { Box, makeStyles, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const NotesContent = ({ notes }) => {
  const classes = useStyles();

  return (
    <>
      {notes && !!notes.length ? (
        <>
          <Box display="flex" alignItems="center" mb={0.5} fontSize={14}>
            <NotesIcon fontSize="inherit" />

            <Typography variant="caption" className={classes.contentInfo}>Previous Notes</Typography>
          </Box>

          {notes.map((note, i) => (
            <div key={i}>
              <Typography variant="h5">
                {moment.unix(note.date).format('L LT')}:
              </Typography>

              <Box mb={2}>
                <Typography color="textSecondary">
                  {note.description}
                </Typography>
              </Box>
            </div>
          ))}
        </>
      )
        :
        <Typography align="center">Notes not found</Typography>
      }
    </>
  );
};
