import * as themes from '../../themes';
import { createReduxReducer } from '../../helpers/createReduxReducer';

const defaultThemeName = Object.keys(themes)[1];

// Actions
const TOGGLE_THEME = 'TOGGLE_THEME';

// Action Creators
export const toggleTheme = (themeName) => {
  const theme = themes[themeName];

  if (theme) {
    return {
      type: TOGGLE_THEME,
      payload: themeName
    };
  } else {
    throw new Error(`Theme with name "${themeName}" is not defined!`);
  }
};

// Reducer
const initialState = {
  currentTheme: defaultThemeName
};

export const reducer = createReduxReducer(initialState, {
  [TOGGLE_THEME]: (state, payload) => {
    return {
      ...state,
      currentTheme: payload
    };
  }
});
