import * as types from './types';
import { createHookReducer } from '../../../../../helpers/createHookReducer';

export const reducer = createHookReducer({
  [types.FETCH_TEMPLATES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_TEMPLATES_SUCCESS]: (
    { filter, templates, ...state },
    { data: newFiles, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      templates: (page > 1 ?
        templates.filter(({ id }) => !newFiles.find((loadedFile) => id === loadedFile.id)).concat(newFiles)
        :
        newFiles
      )
    };
  },

  [types.RESET_TEMPLATES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 },
      selectedFilesID: []
    };
  },

  [types.ADD_TEMPLATES]: (state, payload) => {
    const { pagination, filter: { tasks, cases, ...filter }, templates, ...otherState } = state;
    let suitableFiles = payload;

    if (tasks && tasks.length) {
      suitableFiles = suitableFiles.filter(({ owner_type, owner }) => {
        return tasks.find((id) => {
          return (owner_type === 'task') && (id === owner.id);
        });
      });
    }

    if (cases && cases.length) {
      suitableFiles = suitableFiles.filter(({ owner_type, owner }) => {
        return cases.find((id) => {
          return (owner_type === 'case') && (id === owner.id);
        });
      });
    }

    if (!suitableFiles.length) {
      return state;
    }

    const total = pagination.total + suitableFiles.length;
    const page = Math.ceil((templates.length + suitableFiles.length) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, tasks, cases, page },
      templates: suitableFiles.concat(templates)
    };
  },

  [types.DELETE_TEMPLATE]: ({ pagination, filter, templates, selectedFilesID, ...state }, templatesID) => {
    const deletedFiles = templates.filter((template) => templatesID === template.id);
    const total = pagination.total - deletedFiles.length;
    const page = Math.ceil((templates.length - deletedFiles.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      selectedFilesID: selectedFilesID.filter((id) => !deletedFiles.find(({ id: deletedID }) => deletedID !== id)),
      templates: templates.filter((template) => !deletedFiles.find(({ id }) => id === template.id))
    };
  },

  [types.RENAME_TEMPLATE]: ({ templates, ...state }, renamedFile) => {
    return {
      ...state,

      templates: templates.map((template) => renamedFile.id === template.id ? renamedFile : template)
    };
  }
});
