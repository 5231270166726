import { useContext, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { Loader } from '../../../../../../../components/Loader';
import { PageBody } from '../../../../../../../components/Page';
import { Cards } from '../../../../../../../components/Cards';
import { TemplatesContext } from '../../TemplatesProvider';
import { Card } from './Card';

export const Grid = () => {
  const { isFetched, meta, templates, resetTemplates } = useContext(TemplatesContext);

  useEffect(() => {
    resetTemplates();
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden" p={1}>
      <Loader p={3} loading={!isFetched} render={
        () => (!templates.length ? (
          <Box p={3}>
            <Typography align="center">No templates found</Typography>
          </Box>
        ) : (
          <>
            <PageBody disablePaddings>
              <Cards size={280} list={templates} component={Card} />
            </PageBody>

            <TablePagination borderTop pagination={meta} onChange={resetTemplates} />
          </>
        ))}
      />
    </Box>
  );
};
