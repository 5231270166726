import * as types from './cardsTypes';

export const extraTypes = [
  types.SUBSTITUTED_IN,
  types.DIRECT_REFERRAL,
  types.CASE_CROSS,
  types.CASE_RE_OPENED
];

export const checkedWidgetsTypes = {
  [types.CHECKLIST]: true,
  [types.ACTIVITY]: true,
  [types.DISCOVERY]: true,
  [types.COURT_INFORMATION]: true,
  [types.CALL_REPORTS]: true,
  [types.SCHEDULE]: true,
  [types.PLAINTIFFS_PD]: true,
  [types.NEGOTIATIONS]: true,
  [types.INSURANCE_DETAILS]: true,
  [types.MEDICAL_CLAIMS]: true,
  [types.PARTICIPANTS]: true,
  [types.FILES]: true,
  [types.EMAIL]: true,
  [types.BILLING]: true,
  [types.TASKS]: true,
  [types.TIME_TRACKING]: true,
  [types.INSURANCE]: true,
  [types.SUBSTITUTED_IN]: true,
  [types.DIRECT_REFERRAL]: true,
  [types.REMINDERS]: true,
  [types.CASE_CLOSURE]: true,
  [types.CASE_CROSS]: true,
  [types.CASE_RE_OPENED]: true,
  [types.COMMENTS]: true,
  [types.DEPOSITIONS]: true,
  [types.APPOINTMENTS]: true
};

export const cardsLayout = {
  breakpoints: { xl: 1440, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  cols: { xl: 24, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  toolbox: { xl: [], lg: [], md: [], sm: [], xs: [], xxs: [] },
  layouts: {
    xl: [
      {
        w: 16,
        h: 10,
        x: 0,
        y: 0,
        i: types.CHECKLIST,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 0,
        i: types.ACTIVITY,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 10,
        i: types.DISCOVERY,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 10,
        i: types.DEPOSITIONS,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 30,
        x: 16,
        y: 10,
        i: types.PARTICIPANTS,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 30,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 20,
        i: types.COURT_INFORMATION,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 30,
        i: types.SCHEDULE,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 40,
        i: types.PLAINTIFFS_PD,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 40,
        i: types.COMMENTS,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 50,
        i: types.INSURANCE_DETAILS,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 50,
        i: types.NEGOTIATIONS,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 50,
        i: types.APPOINTMENTS,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 24,
        h: 10,
        x: 0,
        y: 60,
        i: types.CALL_REPORTS,
        minW: 10,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 24,
        h: 10,
        x: 0,
        y: 70,
        i: types.MEDICAL_CLAIMS,
        minW: 10,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 80,
        i: types.FILES,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 80,
        i: types.EMAIL,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 90,
        i: types.TASKS,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 16,
        y: 90,
        i: types.TIME_TRACKING,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 100,
        i: types.BILLING,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 110,
        i: types.INSURANCE,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 16,
        y: 110,
        i: types.SUBSTITUTED_IN,
        minW: 6,
        maxW: 24,
        minH: 7,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 16,
        y: 117,
        i: types.DIRECT_REFERRAL,
        minW: 6,
        maxW: 24,
        minH: 7,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 9,
        x: 16,
        y: 117,
        i: types.REMINDERS,
        minW: 6,
        maxW: 24,
        minH: 7,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 16,
        h: 10,
        x: 0,
        y: 120,
        i: types.CASE_CLOSURE,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 0,
        y: 130,
        i: types.CASE_CROSS,
        minW: 6,
        maxW: 24,
        minH: 7,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 7,
        x: 8,
        y: 130,
        i: types.CASE_RE_OPENED,
        minW: 6,
        maxW: 24,
        minH: 7,
        maxH: 10,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    lg: [
      {
        w: 8,
        h: 10,
        x: 0,
        y: 0,
        i: types.CHECKLIST,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 0,
        i: types.ACTIVITY,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 10,
        i: types.DISCOVERY,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 10,
        i: types.DEPOSITIONS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 30,
        x: 8,
        y: 10,
        i: types.PARTICIPANTS,
        minW: 4,
        maxW: 8,
        minH: 6,
        maxH: 30,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 20,
        i: types.COURT_INFORMATION,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 30,
        i: types.SCHEDULE,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 40,
        i: types.PLAINTIFFS_PD,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 40,
        i: types.COMMENTS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 50,
        i: types.INSURANCE_DETAILS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 50,
        i: types.NEGOTIATIONS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 50,
        i: types.APPOINTMENTS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 12,
        h: 10,
        x: 0,
        y: 60,
        i: types.CALL_REPORTS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 12,
        h: 10,
        x: 0,
        y: 70,
        i: types.MEDICAL_CLAIMS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 80,
        i: types.FILES,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 80,
        i: types.EMAIL,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 90,
        i: types.TASKS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 8,
        y: 90,
        i: types.TIME_TRACKING,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 100,
        i: types.BILLING,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 110,
        i: types.INSURANCE,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 8,
        y: 110,
        i: types.SUBSTITUTED_IN,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 8,
        y: 117,
        i: types.DIRECT_REFERRAL,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 9,
        x: 8,
        y: 117,
        i: types.REMINDERS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 8,
        h: 10,
        x: 0,
        y: 120,
        i: types.CASE_CLOSURE,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 130,
        i: types.CASE_CROSS,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 4,
        y: 130,
        i: types.CASE_RE_OPENED,
        minW: 4,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    md: [
      {
        w: 6,
        h: 10,
        x: 0,
        y: 0,
        i: types.CHECKLIST,
        minW: 6,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 6,
        y: 0,
        i: types.ACTIVITY,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 10,
        i: types.DISCOVERY,
        minW: 6,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 10,
        i: types.DEPOSITIONS,
        minW: 6,
        maxW: 12,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 30,
        x: 6,
        y: 10,
        i: types.PARTICIPANTS,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 30,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 20,
        i: types.COURT_INFORMATION,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 30,
        i: types.SCHEDULE,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 40,
        i: types.PLAINTIFFS_PD,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 6,
        y: 40,
        i: types.COMMENTS,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 50,
        i: types.INSURANCE_DETAILS,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 6,
        y: 50,
        i: types.NEGOTIATIONS,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 6,
        y: 50,
        i: types.APPOINTMENTS,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 60,
        i: types.CALL_REPORTS,
        minW: 10,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 70,
        i: types.MEDICAL_CLAIMS,
        minW: 10,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 80,
        i: types.FILES,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 6,
        y: 80,
        i: types.EMAIL,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 90,
        i: types.TASKS,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 6,
        y: 90,
        i: types.TIME_TRACKING,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 100,
        i: types.BILLING,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 110,
        i: types.INSURANCE,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 6,
        y: 110,
        i: types.SUBSTITUTED_IN,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 7,
        x: 6,
        y: 117,
        i: types.DIRECT_REFERRAL,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 9,
        x: 6,
        y: 117,
        i: types.REMINDERS,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 120,
        i: types.CASE_CLOSURE,
        minW: 4,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 3,
        h: 7,
        x: 0,
        y: 130,
        i: types.CASE_CROSS,
        minW: 3,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 3,
        h: 7,
        x: 3,
        y: 130,
        i: types.CASE_RE_OPENED,
        minW: 3,
        maxW: 10,
        minH: 6,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    sm: [
      {
        w: 6,
        h: 10,
        x: 0,
        y: 0,
        i: types.CHECKLIST,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 10,
        i: types.ACTIVITY,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 20,
        i: types.DISCOVERY,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 20,
        i: types.DEPOSITIONS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 30,
        i: types.PARTICIPANTS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 40,
        i: types.COURT_INFORMATION,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 50,
        i: types.SCHEDULE,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 60,
        i: types.PLAINTIFFS_PD,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 70,
        i: types.COMMENTS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 80,
        i: types.INSURANCE_DETAILS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 90,
        i: types.NEGOTIATIONS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 90,
        i: types.APPOINTMENTS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 100,
        i: types.CALL_REPORTS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 110,
        i: types.MEDICAL_CLAIMS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 120,
        i: types.FILES,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 130,
        i: types.EMAIL,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 140,
        i: types.TASKS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 150,
        i: types.TIME_TRACKING,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 160,
        i: types.BILLING,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 170,
        i: types.INSURANCE,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 180,
        i: types.SUBSTITUTED_IN,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 190,
        i: types.DIRECT_REFERRAL,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 190,
        i: types.REMINDERS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 200,
        i: types.CASE_CLOSURE,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 210,
        i: types.CASE_CROSS,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 220,
        i: types.CASE_RE_OPENED,
        minW: 6,
        maxW: 6,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    xs: [
      {
        w: 4,
        h: 10,
        x: 0,
        y: 0,
        i: types.CHECKLIST,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 10,
        i: types.ACTIVITY,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 20,
        i: types.DISCOVERY,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 20,
        i: types.DEPOSITIONS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 30,
        i: types.PARTICIPANTS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 40,
        i: types.COURT_INFORMATION,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 50,
        i: types.SCHEDULE,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 60,
        i: types.PLAINTIFFS_PD,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 70,
        i: types.COMMENTS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 80,
        i: types.INSURANCE_DETAILS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 90,
        i: types.NEGOTIATIONS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 90,
        i: types.APPOINTMENTS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 100,
        i: types.CALL_REPORTS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 110,
        i: types.MEDICAL_CLAIMS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 120,
        i: types.FILES,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 130,
        i: types.EMAIL,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 140,
        i: types.TASKS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 150,
        i: types.TIME_TRACKING,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 160,
        i: types.BILLING,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 170,
        i: types.INSURANCE,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 180,
        i: types.SUBSTITUTED_IN,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 190,
        i: types.DIRECT_REFERRAL,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 190,
        i: types.REMINDERS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 200,
        i: types.CASE_CLOSURE,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 210,
        i: types.CASE_CROSS,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 220,
        i: types.CASE_RE_OPENED,
        minW: 4,
        maxW: 4,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    xxs: [
      {
        w: 2,
        h: 10,
        x: 0,
        y: 0,
        i: types.CHECKLIST,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 10,
        i: types.ACTIVITY,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 20,
        i: types.DISCOVERY,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 20,
        i: types.DEPOSITIONS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 30,
        i: types.PARTICIPANTS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 40,
        i: types.COURT_INFORMATION,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 50,
        i: types.SCHEDULE,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 60,
        i: types.PLAINTIFFS_PD,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 70,
        i: types.COMMENTS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 80,
        i: types.INSURANCE_DETAILS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 90,
        i: types.NEGOTIATIONS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 90,
        i: types.APPOINTMENTS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 100,
        i: types.CALL_REPORTS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 110,
        i: types.MEDICAL_CLAIMS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 120,
        i: types.FILES,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 130,
        i: types.EMAIL,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 140,
        i: types.TASKS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 150,
        i: types.TIME_TRACKING,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 160,
        i: types.BILLING,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 170,
        i: types.INSURANCE,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 180,
        i: types.SUBSTITUTED_IN,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 190,
        i: types.DIRECT_REFERRAL,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 190,
        i: types.REMINDERS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 200,
        i: types.CASE_CLOSURE,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 210,
        i: types.CASE_CROSS,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 220,
        i: types.CASE_RE_OPENED,
        minW: 2,
        maxW: 2,
        minH: 10,
        maxH: 20,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ]
  }
};
