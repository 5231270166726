import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import * as callReportsApi from '../../../api/call-reports';
import { Loader } from '../../Loader';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { Form } from './Form';
import { parentTypesLabelsMap } from './parentTypeOptions';
import { validationSchema } from './validationSchema';

export const CopyToModal = ({
  DialogProps,
  payload: {
    ids = [],
    parent_id = null,
    parent_type = null
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return callReportsApi.multipleAttachParent(values).then(() => {
      enqueueSnackbar('Successfully copied', { variant: 'success' });
      handleModalResolve();
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ ids, parent_id, parent_type }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<FileCopyIcon />}
              onClose={handleModalReject}
            >
              Attach to {parentTypesLabelsMap[parent_type]}
            </ModalHeader>

            <ModalBody>
              <Form />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
