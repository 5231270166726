import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().max(255),
  provider_id: Yup.mixed().nullable().required(),
  patient_id: Yup.mixed().nullable().required(),
  office_id: Yup.mixed().nullable().required(),
  signature_id: Yup.mixed().nullable(),
  text: Yup.string().nullable().required().max(5000),
  options: Yup.object().shape({
    generic_equivalent_acceptable: Yup.string().nullable().max(255),
    icd_code_ids: Yup.array().of(Yup.mixed().nullable()).nullable()
  }),
  date: Yup.string().nullable().required()
});
