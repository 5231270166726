export const filterFieldsMap = {
  company: 'company',
  office: 'office',
  position: 'position',
  caseRole: 'case_role'
};

export const filterFieldsLabels = {
  [filterFieldsMap.company]: 'Company',
  [filterFieldsMap.office]: 'Office',
  [filterFieldsMap.position]: 'Position',
  [filterFieldsMap.caseRole]: 'User role'
};
