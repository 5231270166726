import { api } from '../api';

export const fetchCaseDepositions = (caseID, config = {}) => {
  return api.get(`/cases/${caseID}/depositions`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createCaseDepositions = (caseID, data) => {
  return api.post(`/cases/${caseID}/depositions`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateCaseDepositions = (caseID, { id, ...data }) => {
  return api.put(`/cases/${caseID}/depositions/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteCaseDepositions = (caseID, depositionID) => {
  return api.delete(`/cases/${caseID}/depositions/${depositionID}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
