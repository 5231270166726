import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { ImmunizationsSelect, TextField } from '../../../../../../components/FormField';
import { Attachments } from '../../../ClinicalNotes/Attachments';

export const ApptImmunization = ({ onAttachmentsUpdate }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleChangeVaccine = (option) => {
    if (option && option?.data) {
      setFieldValue('immunization.description', option?.data?.description);
    }
  };

  useEffect(() => {
    onAttachmentsUpdate && onAttachmentsUpdate(values.media);
  }, [ values.media ]);

  return (
    <Box my={6} flex={1}>
      <Box mt={4} color="info.main">
        <Typography variant="h3" color="inherit">
          Select Immunization
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <ImmunizationsSelect
            TextFieldProps={{ required: true, margin: 'dense' }}
            onChange={handleChangeVaccine}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          {values?.immunization_id &&
            <Box mt={1}>
              <Typography variant="body2" color="textSecondary">
                Description
              </Typography>
            </Box>
          }

          <Typography>{values?.immunization?.description || '-'}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center" mt={3}>
            <Box mr={0.5} fontWeight={500}>
              <NotesIcon fontSize="small" />
            </Box>

            <Typography variant="h5">Note</Typography>
          </Box>

          <TextField
            fullWidth
            multiline
            rows={4}
            name="note"
            variant="filled"
          />
        </Grid>

        {onAttachmentsUpdate &&
          <Grid item xs={12}>
            <Attachments name="media" />
          </Grid>
        }
      </Grid>
    </Box>
  );
};
