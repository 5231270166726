import { Bar } from 'react-chartjs-2';
import { get } from 'lodash';
import { palette } from '../../../../../../../../themes/palette';

export const Chart = ({ data, labels }) => {
  const incomesData = data.map((item) => get(item, 'incomes.sum', 0));
  const expensesData = data.map((item) => get(item, 'expenses.sum', 0));
  const labelsData = labels.length === 7 ?
    [ 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN' ]
    :
    labels;

  const getChartData = () => ({
    labels: labelsData,
    datasets: [
      {
        label: 'income',
        backgroundColor: palette().lightgreen,
        hoverBackgroundColor: palette().lightgreen,
        fill: false,
        data: incomesData
      },
      {
        label: 'expense',
        backgroundColor: palette().primary.light,
        hoverBackgroundColor: palette().primary.light,
        fill: false,
        data: expensesData
      }
    ]
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [ {
        ticks: {
          beginAtZero: true,
          callback: (label) => {
            label = new Intl.NumberFormat('en-US').format(label);

            return label;
          }
        },
        gridLines: {
          color: palette().grey[200],
          borderDash: [ 2, 3 ]
        }
      } ],
      xAxes: [ {
        gridLines: {
          drawOnChartArea: false,
          color: palette().grey[200],
          borderDash: [ 2, 3 ]
        },
        ticks: {
          beginAtZero: true
        }
      } ]
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem) => ' $ ' + new Intl.NumberFormat('en-US').format(tooltipItem.value)
      }
    }
  };

  return (
    <Bar
      data={getChartData()}
      options={chartOptions}
    />
  );
};
