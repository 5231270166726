import { createContext, useRef, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { api } from '../../../../api';
import * as eventTemplatesApi from '../../../../api/schedule-events/schedule-event-templates';
import { useModal } from '../../../ModalsProvider';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const ScheduleEventTemplatesContext = createContext();

export const ScheduleEventTemplatesProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    eventTemplates,
    selectedCodesIDs,
    selectedCodes
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchEventTemplates = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_EVENT_TEMPLATES_REQUEST });

    eventTemplatesApi.fetchEventTemplates({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_EVENT_TEMPLATES_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchEventTemplates({ page: filter.page + 1 });
    }
  };

  const resetEventTemplates = (newFilter) => {
    dispatch({ type: types.RESET_EVENT_TEMPLATES, payload: newFilter });

    fetchEventTemplates({ page: 1, ...newFilter });
  };

  const updateActiveEventTemplate = (eventTemplate) => {
    dispatch({ type: types.UPDATE_EVENT_TEMPLATE, payload: eventTemplate });
  };

  const updateActiveEventTemplateReq = (eventTemplate) => {
    return eventTemplatesApi.updateEventTemplate(eventTemplate).then((eventTemplate) => {
      updateActiveEventTemplate(eventTemplate);
    });
  };

  const deleteEventTemplate = (eventTemplate) => {
    return new Promise((resolve) => {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          eventTemplatesApi.deleteEventTemplate(eventTemplate.id).then(() => {
            dispatch({ type: types.DELETE_EVENT_TEMPLATE, payload: eventTemplate.id });
            enqueueSnackbar(`Appt. template "${eventTemplate.name}" successfully deleted`, { variant: 'success' });
            resolve();
          });
        },

        onModalRejected: resolve
      });
    });
  };

  const providerValue = {
    isFetched,
    isFetching,
    eventTemplates,
    selectedCodesIDs,
    selectedCodes,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetEventTemplates,
    fetchEventTemplates,
    loadNextPage,
    updateActiveEventTemplate,
    updateActiveEventTemplateReq,
    deleteEventTemplate
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ScheduleEventTemplatesContext.Provider value={providerValue}>
      {children}
    </ScheduleEventTemplatesContext.Provider>
  );
};
