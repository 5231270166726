import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { extendMoment } from 'moment-range';

moment.locale('en-US');

// Set thresholds to be at their "max" value
moment.relativeTimeThreshold('s', 59);
moment.relativeTimeThreshold('m', 59);
moment.relativeTimeThreshold('h', 23);
moment.relativeTimeThreshold('d', 28);
moment.relativeTimeThreshold('M', 12);

momentDurationFormatSetup(moment);
extendMoment(moment);

// Adding static helpers
moment.sod = (date) => {
  return date?.startOf('day');
};

moment.eod = (date) => {
  return date?.endOf('day');
};
