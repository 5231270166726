import * as types from './types';

export const fetchUserStorageRequest = () => ({ type: types.FETCH_STORAGE_DATA });
export const fetchUserStorageSuccess = (payload) => ({ type: types.FETCH_STORAGE_SUCCESS, payload });
export const fetchUserStorageError = () => ({ type: types.FETCH_STORAGE_ERROR });
export const updateUserStorageSuccess = (payload) => ({ type: types.UPDATE_STORAGE_DATA, payload });
export const updateCardsLayout = (payload) => ({ type: types.SET_CARDS_LAYOUTS, payload });
export const updatePrevCardsLayout = (payload) => ({ type: types.SET_PREV_LAYOUTS, payload });
export const setWidgets = (payload) => ({ type: types.SET_CHECKED_WIDGETS, payload });
export const toggleWidgets = (payload) => ({ type: types.SET_OPEN_WIDGETS, payload });
