import { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../helpers/dom';
import { generateAddress } from '../../../../../../utils/generateAddress';
import { CardItem, CardHeader, CardFooter } from '../../../../../../components/Cards';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { Tooltip } from '../../../../../../components/Tooltip';
import { UserLink } from '../../../../../../components/UserLink';
import { hasRole } from '../../../../../../utils/hasRole';
import { PrescriptionsMenu } from '../../PrescriptionsMenu';
import { PrescriptionsContext } from '../../PrescriptionsProvider';
import { PreviewPrescriptionModal } from '../../PreviewPrescriptionModal';
import { Body } from './Body';

export const Card = ({ item: prescription = {} }) => {
  const { openModal } = useModal();
  const {
    selectedIDs,
    toggleItemSelection,
    updatePrescription,
    deletePrescription,
    fetchPrescriptions
  } = useContext(PrescriptionsContext);
  const isSelected = selectedIDs.indexOf(prescription.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(prescription.id);
  };

  const openPreview = () => {
    openModal(PreviewPrescriptionModal, {
      payload: {
        prescription,
        onUpdate: updatePrescription,
        onDelete: deletePrescription,
        updatePrescription: fetchPrescriptions
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <CardItem selected={isSelected} py={1} px={2} onClick={stopPropagation(openPreview)}>
      <CardHeader
        isSelected={isSelected}
        disableCheckBox={hasRole('client', 'patient')}
        disableCardMenu={hasRole('client', 'patient')}
        onItemSelect={handleItemSelection}
        menuComponent={PrescriptionsMenu}
        menuComponentProps={{ prescription }}
      >
        <Box display="flex" alignItems="center" color="text.secondary">
          <Typography noWrap variant="subtitle2" color="inherit">
            Prescription # {prescription.prescription}
          </Typography>
        </Box>
      </CardHeader>

      <Body prescription={prescription} />

      <CardFooter disableMediaCounts countComments={prescription?.count_comments}>
        <UserLink
          size="md"
          variant="h5"
          user={prescription.approved}
          childrenProps={{ mt: -0.5 }}
          onClick={stopPropagation(openUserPreview(prescription?.approved?.id))}
        >
          <Tooltip
            isExistTooltip={!!prescription?.office}
            title={prescription?.office?.full_address || generateAddress(prescription?.office)}
          >
            <OfficeLink
              color="textSecondary"
              variant="caption"
              office={prescription?.office}
            />
          </Tooltip>
        </UserLink>
      </CardFooter>
    </CardItem>
  );
};
