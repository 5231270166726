import { useContext } from 'react';
import { flow } from 'lodash';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { AppointmentEditModal, AppointmentViewModal } from '../../../../../components/appointments';
import { useModal } from '../../../../../components/ModalsProvider';
import { WaitingListContext } from '../WaitingListProvider';
import { EditModal } from '../EditModal';

export const Menu = ({ appointment, onClose = () => {} }) => {
  const { openModal } = useModal();
  const { updateWaitingListItem, deleteWaitingListItem, fetchWaitingList } = useContext(WaitingListContext);

  const handleWaitingListItemUpdate = () => {
    openModal(EditModal, {
      payload: { appointment },
      onModalResolved: (data) => {
        updateWaitingListItem(data);
        onClose();
      }
    });
  };

  const editAppointment = () => {
    openModal(AppointmentEditModal, {
      payload: {
        appointmentID: appointment.id
      },

      onModalResolved: (data) => {
        updateWaitingListItem(data);
        onClose();
      }
    });
  };

  const handleWaitingListItemDelete = () => {
    deleteWaitingListItem(appointment.id);
  };

  const openPreviewModal = () => {
    openModal(AppointmentViewModal, {
      payload: {
        disableApptActions: true,
        onAppointmentUpdate: updateWaitingListItem,
        onWaitingListUpdate: fetchWaitingList,
        onAppointmentDelete: deleteWaitingListItem,
        appointmentID: appointment.id
      },
      onModalReject: () => {
        onClose();
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={openPreviewModal}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {!hasRole(rolesMap.client) &&
        <>
          <MenuItem onClick={!hasRole(rolesMap.patient) ? editAppointment : handleWaitingListItemUpdate}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit appointment" />
          </MenuItem>

          {!hasRole(rolesMap.patient) &&
            <MenuItem onClick={flow(handleWaitingListItemDelete, onClose)}>
              <ListItemIcon>
                <DeleteIcon color="error" fontSize="inherit"/>
              </ListItemIcon>

              <ListItemText primary="Delete" />
            </MenuItem>
          }
        </>
      }
    </List>
  );
};
