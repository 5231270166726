import { useMemo } from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { CurrencyField, NumberMaskField } from '../../../../../../../../components/FormField';

export const TotalFundsReceived = ({ isEditable = false, totalInfo = {}, onChange = () => {} }) => {
  const totalFoundsReceived = useMemo(() => (
    totalInfo?.received_founds?.reduce((result, { amount }) => result + (+amount || 0), 0)
  ), [ totalInfo?.received_founds ]);

  const attyCosts = useMemo(() => {
    let result = 0;

    if (totalInfo?.atty_costs_custom < 10) {
      result = (+totalFoundsReceived * +`0.0${totalInfo?.atty_costs_custom}`).toFixed(2);
    } else {
      result = (+totalFoundsReceived * +`0.${totalInfo?.atty_costs_custom}`).toFixed(2);
    }

    return result;
  }, [ totalFoundsReceived, totalInfo?.atty_costs_custom ]);

  const netAttyFees = useMemo(() => (
    +attyCosts + +totalInfo?.atty_fee_legal_costs
  ), [ attyCosts, totalInfo?.atty_fee_legal_costs ]);

  const totalToClient = useMemo(() => (
    +totalFoundsReceived - (+totalInfo?.total_to_providers || 0) - netAttyFees
  ), [ totalFoundsReceived, netAttyFees, totalInfo?.total_to_providers ]);

  return (
    <>
      {isEditable &&  <Grid item xs={12} />}

      <Grid item xs={4}>
        <Typography variant={isEditable ? 'h4' : 'h5'}>
          Total Funds Received
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <CurrencyFormat
          value={totalFoundsReceived}
          TypographyProps={{ noWrap: true, variant: isEditable ? 'h4' : 'h5' }}
        />
      </Grid>

      {isEditable && (
        <>
          <Grid item sm={2} xs={3}>
            <Box ml={2}>
              <Typography color="textSecondary">
                Atty’s Costs
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={2} xs={3}>
            <NumberMaskField
              zeroMinWidth
              name="atty_costs_custom"
              label="Rate"
              placeholder="Write %"
              margin="dense"
            />
          </Grid>

          <Grid item sm={8} xs={6}>
            <CurrencyFormat
              value={attyCosts}
              TypographyProps={{ noWrap: true, variant: isEditable ? 'h4' : 'h5' }}
            />
          </Grid>

          <Grid item sm={4} xs={6}>
            <Box ml={2}>
              <Typography color="textSecondary">
                Legal Costs
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={4} xs={6}>
            <CurrencyField
              name="atty_fee_legal_costs"
              label="Total"
            />
          </Grid>

          <Hidden only={[ 'sm', 'xs' ]}>
            <Grid item sm={4} />
          </Hidden>
        </>
      )}

      <Grid item sm={isEditable ? 4 : 3} xs={6}>
        <Typography variant={isEditable ? 'h4' : 'h5'}>
          Net Atty Fees
        </Typography>
      </Grid>

      <Grid item sm={isEditable ? 4 : 3} xs={6}>
        <CurrencyFormat
          value={-netAttyFees}
          TypographyProps={{ noWrap: true, variant: isEditable ? 'h4' : 'h5' }}
        />
      </Grid>

      {!isEditable && (
        <>
          <Hidden only={[ 'sm', 'xs' ]}>
            <Grid item sm={4} />
          </Hidden>

          <Hidden only={[ 'sm', 'xs' ]}>
            <Grid item sm={6} />
          </Hidden>
        </>
      )}

      <Grid item sm={isEditable ? 8 : 3} xs={6}>
        <Typography variant={isEditable ? 'h4' : 'h5'}>
          Total to Providers (Medical Bills)
        </Typography>
      </Grid>

      <Grid item sm={isEditable ? 4 : 3} xs={6}>
        <CurrencyFormat
          value={totalInfo?.total_to_providers || 0}
          TypographyProps={{ noWrap: true, variant: isEditable ? 'h4' : 'h5' }}
        />
      </Grid>

      {!isEditable && (
        <Hidden only={[ 'sm', 'xs' ]}>
          <Grid item sm={6} />
        </Hidden>
      )}

      <Grid item sm={isEditable ? 8 : 3} xs={6}>
        <Typography variant={isEditable ? 'h4' : 'h5'}>
          Total to Adjuster
        </Typography>
      </Grid>

      <Grid item sm={isEditable ? 4 : 3} xs={6}>
        <CurrencyFormat
          value={totalToClient || 0}
          TypographyProps={{ noWrap: true, variant: isEditable ? 'h4' : 'h5' }}
        />
      </Grid>
    </>
  );
};
