import { makeStyles, Typography, IconButton, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Loader } from '../../../../../components/Loader';
import { FileOwner } from '../../FileOwner';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const InfoBar = ({
  isSubmitting,
  document: { file },
  onFileOwnerChange
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <div className={classes.info}>
          <Typography className={classes.info__label}>Paper size</Typography>
          <Typography component="b" variant="subtitle2">Letter 8.5 x 11</Typography>
        </div>

        <div className={classes.info}>
          <Typography className={classes.info__label}>File owner</Typography>

          <Typography component="b" variant="subtitle2">
            <FileOwner owner_type={file.owner_type} owner={file.owner} classes={{ label__icon: classes.ownerIcon }} />
          </Typography>

          <IconButton className={classes.info__editButton} onClick={onFileOwnerChange}>
            <EditIcon fontSize="small" />
          </IconButton>
        </div>
      </div>

      <Loader surface loading={isSubmitting} render={
        () => (
          <Button
            type="submit"
            disabled={isSubmitting}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        )}
      />
    </div>
  );
};
