import { Box, List as MuiList, Typography } from '@material-ui/core';
import { ErrorBoundary } from '../../../../../ErrorBoundary';
import { Loader } from '../../../../../Loader';
import { Scrollbars } from '../../../../../Scrollbars';
import { Row } from './Row';
import { Header } from './Header';

export const columnsWidths = {
  medRecNo: 120,
  name: 180,
  phone: 140,
  email: 200,
  coincidence: 240
};

export const List = ({ attorneys, onUserSelect }) => {
  return (
    <Loader p={3} loading={!attorneys} render={
      () => !attorneys.length ? (
        <Box p={3}>
          <Typography align="center">No users found</Typography>
        </Box>
      ) : (
        <>
          <Header />

          <Scrollbars autoHeight>
            <MuiList disablePadding>
              {attorneys.map((user) => (
                <ErrorBoundary key={user.id}>
                  <Row user={user} onUserSelect={onUserSelect} />
                </ErrorBoundary>
              ))}
            </MuiList>
          </Scrollbars>
        </>
      )}
    />
  );
};
