import { useContext, useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import {
  makeStyles,
  Box,
  Typography,
  List as MuiList,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../components/IconButton';
import { TextField } from '../../../../../../../components/FormField';
import { Fab } from '../../../../../../../components/Fab';
import { StepContext } from '../../StepProvider';
import { MedicationsCards } from './MedicationsCards';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const columnsWidths = {
  name: 180,
  dosage: 180,
  frequency: 180,
  take: 180,
  action: 45
};

export const medicationInitialValues = {
  medication_name: null,
  dosage: null,
  frequency: null,
  how_i_take_it: null
};

export const Medications = () => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { values, dirty } = useFormikContext();
  const stepContext = useContext(StepContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  const handleAddFields = (arrayHelpers, fields) => (event) => {
    if ((event.charCode || event.keyCode) === 13) {
      arrayHelpers.push(fields);
    }
  };

  useEffect(() => {
    if (stepContext) {
      stepContext.setIsDirty(dirty);
    }
  }, [ !!stepContext && dirty ]);

  return (
    <Box my={3}>
      <FieldArray
        name="forms.medical_form_info.medications"
        render={({ remove, push }) => (
          <>
            <Box mb={2} color="info.main" display="flex" alignItems="center">
              <Typography variant="h4" color="inherit">
                Medications
              </Typography>

              <Box component="span" ml={2}>
                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon/>}
                  onClick={() => push(medicationInitialValues)}
                >
                  Add
                </Fab>
              </Box>
            </Box>

            {isSmallScreen
              ? (
                <MedicationsCards
                  arrayHelpers={{ remove, push }}
                  onAddFields={handleAddFields(medicationInitialValues)}
                />
              ) : (!!values?.forms?.medical_form_info?.medications?.length &&
                <div className={classes.root}>
                  <ListRow header>
                    <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Medication Name
                      </Typography>
                    </ListRowCell>

                    <ListRowCell grow flexBasis={columnsWidths.dosage}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Dosage
                      </Typography>
                    </ListRowCell>

                    <ListRowCell flexBasis={columnsWidths.frequency}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Frequency
                      </Typography>
                    </ListRowCell>

                    <ListRowCell flexBasis={columnsWidths.action}/>
                  </ListRow>

                  <MuiList disablePadding>
                    {values?.forms?.medical_form_info?.medications?.map((medication, index) => (
                      <ListRow key={index}>
                        <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                          <TextField
                            fullWidth
                            zeroMinWidth
                            disableUnderline
                            placeholder="Add..."
                            name={`forms.medical_form_info.medications.${index}.medication_name`}
                          />
                        </ListRowCell>

                        <ListRowCell noWrap grow flexBasis={columnsWidths.dosage}>
                          <TextField
                            fullWidth
                            zeroMinWidth
                            disableUnderline
                            placeholder="Add..."
                            name={`forms.medical_form_info.medications.${index}.dosage`}
                          />
                        </ListRowCell>

                        <ListRowCell flexBasis={columnsWidths.frequency}>
                          <TextField
                            fullWidth
                            zeroMinWidth
                            disableUnderline
                            placeholder="Add..."
                            name={`forms.medical_form_info.medications.${index}.frequency`}
                          />
                        </ListRowCell>

                        <ListRowCell noWrap flexBasis={columnsWidths.action}>
                          <IconButton
                            size="small"
                            color="error"
                            onClick={handleClearField(remove, index)}
                          >
                            <ClearIcon fontSize="small"/>
                          </IconButton>
                        </ListRowCell>
                      </ListRow>
                    ))}
                  </MuiList>
                </div>
              )}
          </>
        )}
      />
    </Box>
  );
};
