import { useContext } from 'react';
import { Box, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiClipboardAccountOutline, mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { FiltersBarModal } from '../../../../../../../../../components/FiltersBarModal/FiltersBarModal';
import { WidgetsActionButtons } from '../../../../../../../../../components/WidgetsActionButtons';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../../../components/ContentCard';
import { IconButton } from '../../../../../../../../../components/IconButton';
import {
  MedicalHistoryContent,
  MedicalHistoryContext,
  MedicalHistoryProvider
} from '../../../../../../../../../components/Widgets';
import { UserContext } from '../../../../../UserProvider';
import { Filters } from './Filters';

export const MedicalHistory = ({
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const theme = useTheme();
  const { openModal } = useModal();
  const userContext = useContext(UserContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleFilterTasks = ({
    filter,
    widgetFilters,
    setWidgetFilters,
    resetHistory
  }) => () => {
    openModal(FiltersBarModal, {
      payload: {
        title: 'Medical History Filter',
        initialValues: {
          ...filter,
          ...widgetFilters
        },
        fields: Filters({ resetHistory, setWidgetFilters })
      }
    });
  };

  return (
    <MedicalHistoryProvider userID={userContext?.user?.id}>
      <MedicalHistoryContext.Consumer>
        {({ widgetFilters, filter, resetHistory, setWidgetFilters }) => (
          <ContentCard
            fullHeight={!props.autoHeightMax}
            isMovable={!props.autoHeightMax}
            disableContentPaddings
            title="Medical History"
            leftActions={[
              <Box mr={1}>
                <SvgIcon fontSize="small">
                  <Icon path={mdiClipboardAccountOutline} />
                </SvgIcon>
              </Box>
            ]}
            endIcon={isMobile && (
              <IconButton
                color="primary"
                onClick={handleFilterTasks({
                  filter,
                  widgetFilters,
                  setWidgetFilters,
                  resetHistory
                })}
              >
                <SvgIcon>
                  <Icon path={mdiFilter} />
                </SvgIcon>
              </IconButton>
            )}
            rightActions={[
              <WidgetsActionButtons
                isMinimizable
                page={page}
                type={props?.item?.i}
                isDisabledOpen={isDisabledOpen}
                onMinimize={onMinimize}
                onMaximized={onMaximized}
                onClose={onClose}
                onOpen={onOpen}
                onPutItem={onPutItem}
              />
            ]}
            {...props}
          >
            <MedicalHistoryContent height={height} />
          </ContentCard>
        )}
      </MedicalHistoryContext.Consumer>
    </MedicalHistoryProvider>
  );
};
