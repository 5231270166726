import { useEffect } from 'react';
import { get } from 'lodash';
import { useFormikContext } from 'formik';
import { Collapse, FormControlLabel, makeStyles, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { TextField, Checkbox, Select } from '../../../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../../../components/users';
import { relationshipMap } from '../relationshipMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Contact = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (get(values, 'is_checked')) {
      setFieldValue('user_id', null);
    } else {
      setFieldValue('first_name', null);
      setFieldValue('last_name', null);
    }
  }, [ values ]);

  return (
    <>
      <Select
        formattedValue
        name="relationship"
        label="Relationship"
        options={relationshipMap}
        TextFieldProps={{ margin: 'dense' }}
      />

      <UsersSelect
        disabled={!!values?.is_checked}
        name="user_id"
        label="Name2"
        margin="dense"
        getOptionValue={(option) => option?.id || option?.user_id}
        getOptionSelected={(option, value) => option?.id === (value?.id || value?.user_id)}
      />

      <FormControlLabel
        control={
          <Checkbox
            name="is_checked"
            size="small"
            color="primary"
          />
        }
        label="Did not find the user?"
      />

      <Collapse
        unmountOnExit
        in={get(values, 'is_checked', false)}
        timeout="auto"
      >
        <TextField
          margin="dense"
          name="first_name"
          label="First name"
        />

        <TextField
          margin="dense"
          name="last_name"
          label="Last name"
        />

        <TextField
          margin="dense"
          name="email"
          label="Email"
        />

        <FormControlLabel
          label={<Typography>Send register email</Typography>}
          control={
            <Checkbox
              color="primary"
              name="is_notify"
            />
          }
        />
      </Collapse>

      <TextField
        fullWidth
        multiline
        rows={5}
        variant="filled"
        margin="dense"
        name="note"
        label={
          <div className={classes.noteIcon}>
            <NotesIcon color="inherit" fontSize="small"/>

            <Typography color="inherit" variant="h4">Note</Typography>
          </div>
        }
      />
    </>
  );
};
