export const filterFieldsMap = {
  patient: 'patient_id',
  name: 'name',
  dateFrom: 'date_from',
  dateTo: 'date_to',
  provider: 'provider_id',
  office: 'office_id'
};

export const filterFieldsLabels = {
  [filterFieldsMap.patient]: 'Patient',
  [filterFieldsMap.name]: 'Name',
  [filterFieldsMap.dateFrom]: 'Date From',
  [filterFieldsMap.dateTo]: 'Date To',
  [filterFieldsMap.provider]: 'Physician',
  [filterFieldsMap.office]: 'Office'
};
