import { createContext, useRef, useEffect, useReducer, useCallback } from 'react';
import { isEqual, merge } from 'lodash';
import { usePrevious } from '../../../../../../../../helpers/hooks';
import { api } from '../../../../../../../../api';
import * as depositionsApi from '../../../../../../../../api/cases/depositions';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const CaseDepositionsContext = createContext();

export const CaseDepositionsProvider = ({ caseID, filter: filterProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetching,
    filter,
    depositions
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchDepositions = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_DEPOSITIONS_REQUEST });

    depositionsApi.fetchCaseDepositions(caseID, {
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_DEPOSITIONS_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetDepositions = (newFilter) => {
    dispatch({ type: types.RESET_DEPOSITIONS, payload: newFilter });

    fetchDepositions(newFilter);
  };

  const addDeposition = useCallback((deposition) => {
    dispatch({ type: types.ADD_DEPOSITION, payload: deposition });
  });

  const updateDeposition = useCallback((deposition) => {
    dispatch({ type: types.UPDATE_DEPOSITION, payload: deposition });
  });

  const deleteDeposition = (deposition) => {
    dispatch({ type: types.DELETE_DEPOSITION, payload: deposition.id });
  };

  const providerValue = {
    caseID,
    isFetching,
    depositions,
    filter,

    // functions
    addDeposition,
    resetDepositions,
    fetchDepositions,
    updateDeposition,
    applyFilter,
    deleteDeposition
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(filter, newFilter)) {
      resetDepositions(newFilter);
    }
  }, [ filter, filterProp ]);

  useEffect(() => {
    if (!isEqual(prevFilter, filter)) {
      resetDepositions(filter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    resetDepositions();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CaseDepositionsContext.Provider value={providerValue}>
      {children}
    </CaseDepositionsContext.Provider>
  );
};
