import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { objectKeysToMap } from '../../../helpers/objectKeysToMap';
import { AppointmentsContext } from '../../../components/appointments';
import { InfiniteListLoader } from '../../../components/InfiniteLoader';
import { ScrollbarsCustom } from '../../../components/ScrollbarsCustom';
import { Loader } from '../../../components/Loader';
import { Sticky } from '../../../components/Sticky';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidths = {
  time: 200,
  patient: 200,
  provider: 200
};

export const columnsMap = objectKeysToMap(columnsWidths);

export const List = ({ fontSize }) => {
  const {
    isFetched,
    isFetching,
    appointments,
    meta,
    loadNextPage,
    reloadAppointments
  } = useContext(AppointmentsContext);
  const scrollElementRef = useRef();
  const cacheRef = useRef();

  useEffect(() => {
    cacheRef?.current?.clearAll();
  }, [ fontSize ]);

  useEffect(() => {
    reloadAppointments({
      appointment_from: null,
      appointment_to: null
    });
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} style={{ flexGrow: 1 }}>
      <Loader p={2} loading={!isFetched} render={
        () => !meta.total ? (
          <Typography align="center">No appointments found</Typography>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={appointments}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={meta}
                RowComponent={Row}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
                RowProps={{ fontSize, cacheRef }}
              />
            </MuiList>
          </>
        )}
      />
    </ScrollbarsCustom>
  );
};
