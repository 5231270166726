import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { TextField } from '../../../../../../../components/FormField';
import { validationSchema } from '../validationSchema';

export const TextVariantsForm = ({
  signature,
  onChange
}) => {
  const handleFieldChange = (name, formikHandler) => (event) => {
    formikHandler(event);

    onChange({
      [name]: event.target.value
    });
  };

  return (
    <Formik
      initialValues={signature}
      validationSchema={validationSchema}
      onSubmit={null}
    >
      {({ handleChange }) => (
        <form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <TextField
                name="electronic_signature"
                label="Full Name"
                autoComplete="off"
                onChange={handleFieldChange('electronic_signature', handleChange)}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <TextField
                name="fax_signature"
                label="Initials"
                autoComplete="off"
                onChange={handleFieldChange('fax_signature', handleChange)}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};
