import { useContext } from 'react';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { LayoutContext, viewVariantsMap } from '../../LayoutContext';
import { IconButton } from '../../IconButton';

export const ActionsBar = ({ isMobile }) => {
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  return (
    <>
      {!isMobile && (
        <>
          <IconButton
            color={viewVariant === viewVariantsMap.list ? 'primary' : 'white'}
            onClick={toggleViewVariant(viewVariantsMap.list)}
          >
            <ViewListIcon />
          </IconButton>

          <IconButton
            edge="end"
            color={viewVariant === viewVariantsMap.grid ? 'primary' : 'white'}
            onClick={toggleViewVariant(viewVariantsMap.grid)}
          >
            <ViewModuleIcon />
          </IconButton>
        </>
      )}
    </>
  );
};
