import { Typography } from '@material-ui/core';
import { ColorPreview } from '../../../ColorPreview';

export const AppointmentType = ({ before, after, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Added&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        {after?.color && (
          <>
            &nbsp;
            -
            &nbsp;
            <ColorPreview color={after?.color} />
          </>
        )}

        &nbsp;
        {after?.code} - {after?.description}
        &nbsp;
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        {before?.color && (
          <>
            &nbsp;
            -
            &nbsp;
            <ColorPreview color={before?.color} />
          </>
        )}
        &nbsp;
        {before?.code} - {before?.description}
        &nbsp;
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        {before?.color && (
          <>
            -
            &nbsp;
            <ColorPreview color={before?.color} />
          </>
        )}
        &nbsp;
        {before?.code} - {before?.description}
        &nbsp;to&nbsp;
        {after?.color && (
          <>
            -
            &nbsp;
            <ColorPreview color={after?.color} />
          </>
        )}
        &nbsp;
        {after?.code} - {after?.description}
      </>
    );
  } else {
    return (
      <>
        Empty&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  }
};
