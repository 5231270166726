import { Box, Grid, Typography } from '@material-ui/core';
import { CardItem, CardHeader } from '../../../../../../../../../components/Cards';
import { Tooltip } from '../../../../../../../../../components/Tooltip';
import { Menu } from './Menu';
import { Body } from './Body';

export const Card = ({ item: deposition = {} }) => {
  return (
    <CardItem disableHover py={2} px={2}>
      <CardHeader
        disableCheckBox
        childrenWidth={11}
        menuComponent={Menu}
        menuComponentProps={{
          deposition
        }}
      >
        <Grid container spacing={1}>
          <Grid item sm={9}>
            <Typography noWrap variant="h4" color="primary">
              {deposition.name}
            </Typography>
          </Grid>

          <Grid item sm={12}>
            <Tooltip title={deposition.description}>
              <Typography noWrap>
                {deposition.description}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </CardHeader>

      <Box py={2}>
        <Body deposition={deposition} />
      </Box>
    </CardItem>
  );
};
