import { api } from '../api';
import * as transformHelpers from './transformHelpers';

export const fetchBillsStatistics = (config) => {
  return api.get('/statistics/bills-graph', config)
    .then(({ data }) => {
      return {
        transformResponse: transformHelpers.transformResponse(data.data),
        transformCounters: transformHelpers.transformResponse(data),
        ...data
      };
    });
};

export const fetchInvoicesStatistics = (config) => {
  return api.get('/statistics/invoices-graph', config)
    .then(({ data }) => {
      return {
        total_open: data.total_open,
        total_paid: data.total_paid,
        transformResponse: transformHelpers.transformResponse(data.data)
      };
    });
};
