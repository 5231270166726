export const orderByMap = {
  started_at: 'started_at',
  finished_at: 'finished_at'
};

export const orderByLabelsMap = {
  [orderByMap.started_at]: 'Start Date',
  [orderByMap.finished_at]: 'End Date'
};

export const orderByOptions = [
  { label: orderByLabelsMap.started_at, value: orderByMap.started_at },
  { label: orderByLabelsMap.finished_at, value: orderByMap.finished_at }
];
