import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell grow flexBasis={columnsWidths.date}>
        Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.recipient}>
        Recipient
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
