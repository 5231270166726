import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { AppBar, Box, IconButton, makeStyles, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import { getThreadName } from '../../../../../components/chat/utils';
import { useOnlineUsers } from '../../../../../components/users/OnlineUsersProvider';
import {
  ActivityIndicator,
  ChatDropDownMenu,
  ChatThreadContext,
  InfoDropDownMenu,
  ThreadAvatar,
  threadTypesMap,
  UpdateChatMembersModal
} from '../../../../../components/chat';
import { useModal } from '../../../../../components/ModalsProvider';
import { chatsTypesMap } from '../chatsTypesMap';
import { SearchMessageModal } from '../MessageList';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const ThreadHeader = ({ aboutTab, handleSetAboutTab, handleShowSidebar }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ isActive, setIsActive ] = useState(false);
  const { onlineUsersIDs } = useOnlineUsers();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const currentUser = useSelector(({ profile }) => profile.user);
  const { currentThread, updateCurrentThread } = useContext(ChatThreadContext);

  const openUpdateChatModal = () => {
    openModal(UpdateChatMembersModal, {
      payload: { thread: currentThread },
      onModalResolved: (data) => {
        enqueueSnackbar('Chat members successfully updated!', { variant: 'success' });
        updateCurrentThread(data);
      }
    });
  };

  useEffect(() => {
    if (currentThread.type === threadTypesMap.direct) {
      const companion = currentThread.users.find((item) => item.id !== currentUser.id);

      setIsActive(onlineUsersIDs.includes(companion?.id));
    }
  }, [ onlineUsersIDs, currentThread.users ]);

  return (
    <AppBar style={{ zIndex: 1 }}>
      <Toolbar className={classes.toolBarWrapper}>
        <div className={classes.titleWrapper}>
          {isMobile &&
            <Box p={0.25} mr={1}>
              <IconButton onClick={handleShowSidebar}>
                <FormatListBulletedOutlinedIcon />
              </IconButton>
            </Box>
          }

          {isMobile &&
            <Box mr={1}>
              <ThreadAvatar thread={currentThread} />
            </Box>
          }

          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Typography variant="h4" style={{ wordBreak: 'break-all' }}>
              {getThreadName({ thread: currentThread, currentUser })}
            </Typography>

            {!isMobile && (
              <div className={classes.subtitle}>
                {(currentThread?.type === chatsTypesMap.direct) ? (
                  <Box display="flex" alignItems="center">
                    {isActive ? (
                      <>
                        <Box pb={0.2} mr={0.5}>
                          <ActivityIndicator />
                        </Box>

                        <Typography>Active</Typography>
                      </>
                    ) : (
                      <Typography>Not Active</Typography>
                    )}
                  </Box>
                ) : (
                  <>
                    <IconButton edge="start" size="small" onClick={openUpdateChatModal}>
                      <PeopleOutlineOutlinedIcon />
                    </IconButton>

                    <Typography>{currentThread?.users?.length}</Typography>
                  </>
                )}
              </div>
            )}
          </Box>
        </div>

        <InfoDropDownMenu aboutTab={aboutTab} onAboutTabChange={handleSetAboutTab} />

        <SearchMessageModal thread={currentThread} />

        <ChatDropDownMenu thread={currentThread} />
      </Toolbar>
    </AppBar>
  );
};
