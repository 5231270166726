import { FormControl, FormControlLabel, FormLabel, Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { participantsUserRoles } from '../../../../../dataMaps';
import { Autocomplete, RadioGroup, Radio, TextField } from '../../../../FormField';
import { Tooltip } from '../../../../Tooltip';
import { CaseReminderGroupsSelect } from '../../groups';
import { CaseReminderEventsSelect } from '../CaseReminderEventsSelect';
import { PeriodsSelect } from '../PeriodsSelect';
import { ReminderEventTypesSelect } from '../ReminderEventTypesSelect';
import { Card } from './Card';

export const ReminderEventForm = () => {
  const { values, setFieldValue } = useFormikContext();

  const handlePreviousEventChange = (event) => {
    setFieldValue('previousEvent', event);
  };

  const handleGroupChange = () => {
    setFieldValue('previous_event_id', null);
    setFieldValue('previousEvent', null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          name="position"
          label="Event #"
          placeholder="Enter number..."
        />
      </Grid>

      <Grid item xs={6}>
        <Tooltip isExistTooltip={!!values.id} title="A group cannot be changed once created">
          <div>
            <CaseReminderGroupsSelect
              required
              disabled={!!values.id}
              name="group_id"
              label="Group"
              onChange={handleGroupChange}
            />
          </div>
        </Tooltip>
      </Grid>

      <Grid item xs={12} sm={6}>
        <ReminderEventTypesSelect
          required
          name="type"
          label="Type"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl>
          <FormLabel>Time Direction</FormLabel>

          <RadioGroup row name="period_direction">
            <FormControlLabel
              value="forward"
              label="Positive"
              control={<Radio color="primary" name="period_direction" />}
            />

            <FormControlLabel
              value="back"
              label="Negative"
              control={<Radio color="primary" name="period_direction" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <PeriodsSelect
          required
          name="period"
          label="Period"
          placeholder="Enter interval..."
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          name="interval"
          label="Interval"
          placeholder="Enter interval..."
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          multiple
          required
          name="case_roles"
          label="Case user roles who receive reminders"
          placeholder="Select roles..."
          options={participantsUserRoles}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          multiline
          rowsMax={5}
          variant="filled"
          name="description"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item xs={12}>
        <Tooltip isExistTooltip={!values.group_id} title="Select group first">
          <div>
            <CaseReminderEventsSelect
              disabled={!values.group_id}
              name="previous_event_id"
              label="Relative"
              hiddenEvent={values}
              params={{ group_id: values.group_id }}
              onChange={handlePreviousEventChange}
            />
          </div>
        </Tooltip>
      </Grid>

      {values.previousEvent &&
        <Grid item xs={12}>
          <Card event={values.previousEvent} />
        </Grid>
      }
    </Grid>
  );
};
