import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { Tag } from '../../../../../../components/Tag';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.original_filename &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              File Name:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap>{params?.original_filename}</Typography>
          </Grid>
        </>
      }

      {!!params.tags?.length &&
        <>
          <Grid item container alignItems="center" xs={5}>
            <Typography noWrap variant="h5">
              Tags:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Box width="100%" display="flex" flexDirection="column">
              {params?.tags?.map((tag) => (
                <Box key={tag?.id} component="span" py={0.5} mr={1}>
                  <Tag tag={tag} />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.updated_after &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Updated After:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {moment.unix(params.updated_after).format('L')}
          </Grid>
        </>
      }

      {params?.updated_before &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Updated Before:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {moment.unix(params.updated_before).format('L')}
          </Grid>
        </>
      }
    </Grid>
  );
};
