import { useContext, useRef } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../CustomScrollbars';
import { Loader } from '../../Loader';
import { CommentsContext } from '../CommentsContext';
import { Row } from '../Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const List = ({
  width,
  disableForm,
  maxHeight,
  parentPage,
  parentType,
  parentID
}) => {
  const { comments, isFetched } = useContext(CommentsContext);
  const classes = useStyles({ maxHeight });
  const scrollElementRef = useRef();

  const newCommentsList = comments.filter((comment) => {
    return (comment.parent_type === parentType && comment.parent_id === parentID);
  });

  return (
    <div className={classes.root}>
      <Loader loading={!isFetched} render={
        () => (
          <>
            {!newCommentsList.length && <Typography align="center">No comments found</Typography>}

            <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
              {newCommentsList.map((comment) => (
                <Row
                  disableForm={disableForm}
                  key={comment.id}
                  width={width}
                  parentPage={parentPage}
                  comment={comment}
                />
              ))}
            </CustomScrollbars>
          </>
        )}
      />
    </div>
  );
};
