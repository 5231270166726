import { TasksWidget } from '../../../../../../../components/tasks';
import { AccountsProvider } from '../../../../../EmailsPage/AccountsContext';
import { FilesCardWidget } from '../../../../../files-common/FilesCardWidget';
import { AllSchedules } from '../../../../../ProfilePage/GeneralInfo/Widgets/AllSchedules';
import * as cardsTypes from '../Layouts';
import { Email } from './Email';
import { WorkInfo } from './WorkInfo';
import { MedicalInfo } from './MedicalInfo';
import { Activity } from './Activity';
import { AnalyticsData } from './AnalyticsData';
import { Archive } from './Archive';
import { TimeTrackingBilling } from './TimeTrackingBilling';

export const widgetsMap = {
  [cardsTypes.WORK_INFO]: {
    title: 'Work Info',
    component: WorkInfo
  },

  [cardsTypes.ALL_SCHEDULES]: {
    title: 'All Schedules',
    component: AllSchedules
  },

  [cardsTypes.MEDICAL_INFO]: {
    title: 'Medical Info',
    component: MedicalInfo
  },

  [cardsTypes.TASKS]: {
    title: 'Tasks',
    component: TasksWidget
  },

  [cardsTypes.ACTIVITY]: {
    title: 'Activity',
    component: Activity
  },

  [cardsTypes.EMAIL]: {
    title: 'Emails',
    component: (props) => (
      <AccountsProvider disableCounters>
        <Email {...props} />
      </AccountsProvider>
    )
  },

  [cardsTypes.FILES]: {
    title: 'Files',
    component: ({ payload: { user }, ...props }) => (
      <FilesCardWidget
        {...props}

        payload={{
          filter: { user_profile_id: user.profile_id },
          ownerType: 'profile',
          ownerID: user.profile_id
        }}
      />
    )
  },

  [cardsTypes.ANALYTICS_DATA]: {
    title: 'Analytics Data',
    component: AnalyticsData
  },

  [cardsTypes.TIME_TRACKING_BILLING]: {
    title: 'Time Tracking and Billing',
    component: TimeTrackingBilling
  },

  [cardsTypes.ARCHIVE]: {
    title: 'Archive',
    component: Archive
  }
};
