import { createContext, useReducer } from 'react';
import * as checklistApi from '../../../../../api/tasks';
import { reducer } from './reducer';
import { initialState as initialStateConfig } from './initialState';
import * as types from './types';

export const ChecklistContext = createContext();

export const ChecklistContextProvider = ({ children, initialState = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, { ...initialStateConfig, ...initialState });
  const {
    filter,
    checklist,
    isFetching,
    isFetched
  } = state;

  const createChecklistItem = (value) => {
    return checklistApi.createChecklistItem(value).then((data) => {
      dispatch({ type: types.ADD_TODO_ITEM, payload: data });
    });
  };

  const updateChecklistItem = (item) => {
    return checklistApi.updateChecklistItem(item).then((data) => {
      dispatch({ type: types.UPDATE_TODO_ITEM, payload: data });
    });
  };

  const updateChecklist = async (list, index) => {
    await dispatch({ type: types.UPDATE_LIST_TODO_ITEM, payload: list });

    return !!list?.[index]?.id && checklistApi.updateItemPosition({
      id: list?.[index]?.id,
      index
    });
  };

  const deleteChecklistItem = (item) => {
    return checklistApi.deleteChecklistItem(item.id).then(() => {
      dispatch({ type: types.DELETE_TODO_ITEM, payload: item.id });
    });
  };

  const providerValue = {
    isFetched,
    isFetching,
    checklist,
    filter,

    // functions
    createChecklistItem,
    deleteChecklistItem,
    updateChecklistItem,
    updateChecklist
  };

  return (
    <ChecklistContext.Provider value={providerValue}>
      {children}
    </ChecklistContext.Provider>
  );
};
