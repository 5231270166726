import { get } from 'lodash';
import {
  makeStyles,
  Typography,
  ListItem,
  ListItemText
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/TrendingFlat';
import ResetIcon from '@material-ui/icons/Autorenew';
import { VerticalDivider } from '../../../../../../../../components/VerticalDivider';
import { bodyPartsMap } from '../../../BodyPartsModal/Body/bodyPartsMap';
import { CountLabel } from '../../../../CountLabel';
import { painLevelColors } from '../../../BodyPartsModal/painLevelColors';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ content, item }) => {
  const classes = useStyles();
  const painLevel = 10;

  return (
    <ListItem className={classes.root}>
      <ListItemText
        primary={
          <div className={classes.row}>
            <Typography noWrap className={classes.title}>{bodyPartsMap[item]}</Typography>

            <CountLabel
              color={painLevelColors[get(content, `where_is_pain.${item}.level`)]}
              count={get(content, `where_is_pain.${item}.level`) + '/' + painLevel}
            />

            {(!!get(content, `where_is_pain.${item}.direction`) ||
              get(content, `where_is_pain.${item}.direction`) === 0) &&
                <>
                  <VerticalDivider horizontalGutters={1}/>

                  {get(content, `where_is_pain.${item}.direction`) !== 360 ?
                    <ArrowIcon
                      style={{
                        transform: `rotate(${get(content, `where_is_pain.${item}.direction`)}deg)`
                      }}
                    />
                    :
                    <ResetIcon/>
                  }
                </>
            }
          </div>
        }
      />
    </ListItem>
  );
};
