import { useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../CustomScrollbars';
import { Loader } from '../../../../../Loader';
import { Cards } from '../../../../../Cards';
import { Card } from './Card';

export const Grid = ({ patients, onUserSelect }) => {
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={2}>
        <Loader p={3} loading={!patients} render={
          () => !patients.length ? (
            <Box p={3}>
              <Typography align="center">No users found</Typography>
            </Box>
          ) : (
            <Cards
              size={290}
              list={patients}
              component={Card}
              cardProps={{ onUserSelect }}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
