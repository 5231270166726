import { forwardRef } from 'react';
import { makeStyles, Badge } from '@material-ui/core';
import { mdiCancel } from '@mdi/js';
import { Icon } from '@mdi/react';
import { SvgIcon } from '../../SvgIcon';
import { Tooltip } from '../../Tooltip';

const styles = ({ palette, typography: { pxToRem } }) => ({
  badgeIcon: {
    color: palette.common.white,
    backgroundColor: palette.error.main,
    borderRadius: '50%',
    fontSize: pxToRem(12) + ' !important'
  }
});

const useStyles = makeStyles(styles);

export const RegisterIndicator = forwardRef(({ user, ...props }, ref) => {
  const classes = useStyles();
  const invisible = !user?.hasOwnProperty('is_confirm') || user.is_confirm;

  return (
    <Badge
      ref={ref}
      invisible={invisible}
      overlap="circle"
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      badgeContent={
        <Tooltip
          isExistTooltip
          title="Not registered"
        >
          <SvgIcon className={classes.badgeIcon}>
            <Icon path={mdiCancel} />
          </SvgIcon>
        </Tooltip>
      }

      {...props}
    />
  );
});
