import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell flexBasis={columnsWidths.patient}>
          Patient
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dorFrom}>
          DOR From
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dorTo}>
          DOR To
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.reportType}>
          Type of med.report
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.diagnosisDX}>
          Diagnosis(DX)
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.provider}>
          Provider
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.office}>
          Office
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
