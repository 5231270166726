import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import {
  makeStyles,
  Button,
  Typography,
  Divider,
  Slider,
  Dialog
} from '@material-ui/core';
import { amFormatDuration } from '../../../../../helpers/dates';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import * as videoApi from '../../../../../api/videos';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CreateFragmentModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { video, endDuration }
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [ sliderValue, setSliderValue ] = useState([ 0, endDuration ]);
  const [ fragment, setFragment ] = useState({});

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);

    setFragment((fragment) => ({
      ...fragment,
      end: amFormatDuration(newValue[1]),
      start: amFormatDuration(newValue[0]),
      format: video.extension
    }));
  };

  const getSliderAriaValueText = (value) => {
    return `${value} seconds`;
  };

  const createFragment = (values, { setErrors, setSubmitting }) => {
    if (!fragment) {
      setSubmitting(false);

      return;
    }

    videoApi.createFragment(video.id, fragment).then((data) => {
      enqueueSnackbar('Fragment successful created', {
        variant: 'success'
      });

      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      setSubmitting(false);

      if (status === 422 && errors) {
        setErrors(errors);
      }
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{}}
        onSubmit={createFragment}
      >
        {({ errors, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Create fragment
            </ModalHeader>

            <ModalBody>
              <div className={classes.sliderCustom}>
                <Slider
                  value={sliderValue}
                  onChange={handleSliderChange}
                  min={0}
                  max={endDuration}
                  aria-labelledby="range-slider"
                  valueLabelDisplay="on"
                  getAriaValueText={getSliderAriaValueText}
                />
              </div>

              {errors.file &&
                <>
                  <Typography paragraph color="error">
                    {errors.file}
                  </Typography>

                  <Divider/>
                </>
              }
            </ModalBody>

            <ModalFooter>
              <Button
                type="reset"
                className={classes.cancelButton}
                onClick={handleModalReject}
              >
                Cancel
              </Button>

              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
