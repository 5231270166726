import { MainContent } from './MainContent';
import { CodesContextProvider } from './CodesContext';

export const CodesBodyPart = ({ filterKey }) => {
  return (
    <CodesContextProvider>
      <MainContent filterKey={filterKey} />
    </CodesContextProvider>
  );
};
