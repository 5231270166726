import { useEffect } from 'react';
import {
  ListItem,
  ListItemSecondaryAction
} from '@material-ui/core';
import { Loader } from '../../Loader';
import { Tag } from '../../Tag';
import { Menu } from './Menu';

export const Row = ({
  item: tag,
  tagIsSelected,
  onTagToggleSelection,
  isLoaded,
  recalculateHeight
}) => {
  const handleTagToggleSelection = () => {
    onTagToggleSelection(tag);
  };

  useEffect(() => {
    recalculateHeight();
  }, [ tagIsSelected(tag) ]);

  return !isLoaded ? <Loader p={2} /> : tagIsSelected(tag) ? null : (
    <ListItem button onClick={handleTagToggleSelection}>
      <Tag tag={tag} />

      <ListItemSecondaryAction>
        {tag.id !== 1 &&
          <Menu tag={tag} />
        }
      </ListItemSecondaryAction>
    </ListItem>
  );
};
