import { colorsMap } from './colorsMap';

export const styles = ({
  spacing,
  palette: { primary, grey, error, slategray, ...palette },
  shape: { borderRadius },
  typography: { fontWeightMedium, pxToRem }
}) => ({
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 20,
    paddingLeft: spacing(),
    paddingRight: spacing(),
    borderRadius,
    fontSize: pxToRem(12),
    fontWeight: fontWeightMedium,
    color: error.contrastText,
    backgroundColor: grey[500]
  },

  primary: {
    backgroundColor: primary.main
  },

  info: {
    backgroundColor: palette.info.main
  },

  low: {
    backgroundColor: colorsMap.low
  },

  medium: {
    backgroundColor: colorsMap.medium
  },

  high: {
    backgroundColor: colorsMap.high
  },

  critical: {
    backgroundColor: colorsMap.critical
  },

  slategray: {
    backgroundColor: slategray
  },

  grey: {
    backgroundColor: grey[500]
  },

  lightGrey: {
    backgroundColor: grey[200],
    color: grey[500]
  },

  normal: {
    backgroundColor: ({ color }) => color || palette.success.main
  },

  prehypertension: {
    backgroundColor: palette.warning.main
  },

  red: {
    backgroundColor: error.main
  },

  hypertension: {
    backgroundColor: error.main
  },

  hypotension: {
    backgroundColor: primary.main
  },

  underweight: {
    backgroundColor: ({ color }) => color
  },

  overweight: {
    backgroundColor: ({ color }) => color
  },

  obese: {
    backgroundColor: ({ color }) => color
  }
});
