import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../components/Modal';
import { EmailSecondaryActions } from '../EmailsClient/EmailView/EmailSecondaryActions';
import { EmailContent } from '../EmailContent';

export const EmailPreviewModal = ({
  DialogProps,
  handleModalReject,
  payload: {
    disableActionBar = false,
    message = {},
    filter = {},
    accounts = [],
    deleteMessage = () => {}
  }
}) => {
  const theme = useTheme();
  const isTable = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <Dialog fullScreen={isTable} fullWidth maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader icon={<EmailOutlinedIcon />} onClose={handleModalReject}>
          Email View
        </ModalHeader>

        <ModalBody disablePaddings>
          <EmailContent filter={filter} messageItem={message} />
        </ModalBody>
      </ModalContainer>

      {!disableActionBar && (
        <ModalFooter>
          <EmailSecondaryActions
            filter={filter}
            accounts={accounts}
            messageItem={message}
            deleteMessage={deleteMessage}
          />
        </ModalFooter>
      )}
    </Dialog>
  );
};
