import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  PreviewInvoiceModal
} from '../../../../../app/Dashboard/CasesPage/Cases/CaseItem/TabsContent/Billing/PreviewInvoiceModal';
import { stopPropagation } from '../../../../../helpers/dom';
import { CurrencyFormat } from '../../../../CurrencyFormat';
import { CardHeader, CardItem } from '../../../../Cards';
import { useModal } from '../../../../ModalsProvider';

export const InvoiceCard = ({ disableInvoicePreview, payment }) => {
  const { openModal } = useModal();

  const openInvoiceViewModal = () => {
    if (payment?.invoice?.id) {
      openModal(PreviewInvoiceModal, {
        payload: {
          invoiceID: payment?.invoice?.id,
          disableAction: true,
          disablePayment: true
        }
      });
    }
  };

  return (
    <Box width="100%">
      <CardItem
        py={1}
        px={2}
        disableHover={disableInvoicePreview}
        onClick={disableInvoicePreview ? undefined : stopPropagation(openInvoiceViewModal)}
      >
        <CardHeader disableCardMenu disableCheckBox>
          <Box display="flex" alignItems="center" pb={2}>
            <Typography noWrap variant="h5">
              Number
            </Typography>
            &nbsp;&nbsp;
            <Typography noWrap color="secondary">
              {payment.number}
            </Typography>
          </Box>
        </CardHeader>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography color="textSecondary">
              Invoice paid date
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography>
              {(!!payment?.invoice?.date && !payment?.causer_invoice?.date) &&
                moment(payment.invoice.date).format('L')
              }

              {(!!payment?.causer_invoice?.date && !payment?.invoice?.date) &&
                moment(payment?.causer_invoice?.date).format('L')
              }
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">
              Due on
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography>
              {(!!payment?.invoice?.due_date && !payment?.causer_invoice?.due_date) &&
                moment(payment?.invoice?.due_date).format('L')
              }

              {(!!payment?.causer_invoice?.date && !payment?.invoice?.due_date) &&
                moment(payment?.causer_invoice?.due_date).format('L')
              }
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">
              Total
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">
              {payment.invoice
                ? <CurrencyFormat value={payment?.invoice?.total}/>
                : <CurrencyFormat value={payment?.causer_invoice?.total}/>
              }
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">
              Payment Amount
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">
              <CurrencyFormat value={payment?.amount}/>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">
              Balance
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">
              {payment.invoice
                ? <CurrencyFormat value={payment?.invoice?.balance}/>
                : <CurrencyFormat value={payment?.causer_invoice?.balance}/>
              }
            </Typography>
          </Grid>
        </Grid>
      </CardItem>
    </Box>
  );
};
