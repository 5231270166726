export const styles = ({ spacing, palette, transitions }) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.background.paper,
    borderLeft: `1px solid ${palette.divider}`
  },

  mobileRoot: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
    width: '100%',
    height: '100%',
    maxWidth: '80%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.background.paper,
    borderLeft: `1px solid ${palette.divider}`,
    overflow: 'hidden',

    transition: transitions.create([ 'right' ], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    })
  },

  sidebarHidden: {
    right: '-100%'
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    padding: spacing(1.5),
    borderBottom: `1px solid ${palette.grey[300]}`
  },

  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2),
    borderTop: `1px solid ${palette.divider}`,
    borderBottom: `1px solid ${palette.divider}`
  },

  nested: {
    paddingLeft: spacing(4)
  },

  withoutBorderBottom: {
    borderBottom: 'none'
  },

  membersHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: `${spacing(0.25)}px`
  }
});
