import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import {
  billingParticipantsTypesMap,
  billingParticipantsTypesOptions,
  invoicesTypesMap,
  invoicesTypesOptions
} from '../../../../../../components/billing';
import { AppointmentsField } from '../../../../../../components/appointments';
import { CasesSelect } from '../../../../../../components/cases/CasesSelect';
import { claimPayersMap, ClaimsField } from '../../../../../../components/claims';
import { Autocomplete } from '../../../../../../components/FormField';

export const MainInfo = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { enqueueSnackbar } = useSnackbar();
  const { values, setFieldValue, setValues } = useFormikContext();

  const handleInvoiceTypeChange = () => {
    setFieldValue('model_id', null);
  };

  const handleCaseChange = (caseItem) => {
    setFieldValue('model', caseItem);
  };

  const handleAppointmentChange = (appointment) => {
    if (!appointment.patient) {
      enqueueSnackbar('This appointment has no patient, please check it before proceeding', { variant: 'warning' });

      return;
    }

    setValues({
      ...values,

      model: appointment,
      model_id: appointment.id,
      total: appointment.price,
      bill_from: appointment.appointment_book?.office?.company?.id ? {
        type: billingParticipantsTypesOptions.find(({ value }) => {
          return value === billingParticipantsTypesMap.company;
        }),
        id: appointment.appointment_book?.office?.company
      } : null,

      bill_to: appointment?.patient?.id ? {
        type: billingParticipantsTypesOptions.find(({ value }) => {
          return value === billingParticipantsTypesMap.user;
        }),
        id: appointment.patient
      } : null
    });
  };

  const handleClaimChange = (claim) => {
    const invoiceID = claim?.billing_info?.invoice_id;

    // Only when creating an invoice (not when editing)
    if (!values.id && invoiceID) {
      enqueueSnackbar('The claim already has an invoice, you were redirected to edit it', { variant: 'info' });
      history.push(`/billing/invoices/edit/${invoiceID}`);
    } else {
      setValues({
        ...values,

        model: claim,
        model_id: claim.id,
        total: claim.billing_info?.total_balance || null,
        bill_from: claim.appointment?.appointment_book?.office?.company?.id ? {
          type: billingParticipantsTypesOptions.find(({ value }) => {
            return value === billingParticipantsTypesMap.company;
          }),
          id: claim.appointment?.appointment_book?.office?.company
        } : null,
        bill_to: {
          type: billingParticipantsTypesOptions.find(({ value }) => value === (
            claim?.payer_type === claimPayersMap.patient
              ? billingParticipantsTypesMap.user
              : billingParticipantsTypesMap.company
          )),
          id: claim?.payer_type === claimPayersMap.patient ? claim.patient : claim.insurance?.insurance_company
        }
      });
    }
  };

  return (
    <Grid container spacing={isMobile ? 1 : 2} direction="column">
      <Grid item sm={5} xs={isMobile ? 12 : 3}>
        <Autocomplete
          required
          preventManualChangeNotification
          name="model_type"
          label="Invoice for"
          options={invoicesTypesOptions}
          onChange={handleInvoiceTypeChange}
        />
      </Grid>

      <Grid item sm={5} xs={isMobile ? 12 : 3}>
        {values.model_type === invoicesTypesMap.case &&
          <CasesSelect
            required
            name="model_id"
            label="Select case"
            placeholder="Search case by name..."
            margin="dense"
            onChange={handleCaseChange}
          />
        }

        {values.model_type === invoicesTypesMap.appointment &&
          <AppointmentsField
            required
            name="model_id"
            label="Select appointment"
            placeholder="Search appt..."
            margin="dense"
            onChange={handleAppointmentChange}
          />
        }

        {values.model_type === invoicesTypesMap.claim &&
          <ClaimsField
            required
            name="model_id"
            label="Select claim"
            placeholder="Search claim..."
            margin="dense"
            onChange={handleClaimChange}
          />
        }
      </Grid>
    </Grid>
  );
};
