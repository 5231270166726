import { Box, Grid, Link, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { AppointmentViewModal } from '../../../../../../../components/appointments';
import { formTypesMap } from '../../../../../../../components/medical/forms';
import { CardHeader, CardItem } from '../../../../../../../components/Cards';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../../components/users';
import { UserLink } from '../../../../../../../components/UserLink';
import { Date } from '../../../../../../../components/Date';
import { PreviewCompanyModal } from '../../../../../Contacts/Companies/PreviewCompanyModal';
import { CovidPreviewFormModal } from '../../CovidPreviewFormModal';
import { FormPreviewModal } from '../../FormPreviewModal';
import { FormsMenu } from '../List/FormsMenu';
import { Body } from './Body';

export const Card = ({ disablePatient = false, item: form = {}, formContext }) => {
  const { openModal } = useModal();
  const {
    selectedIDs,
    fetchForms,
    isDeleted = false,
    isUnconfirmed = false,
    toggleItemSelection,
    fetchUnconfirmedForms = () => {}
  } = formContext;
  const isSelected = selectedIDs.indexOf(form.id) !== -1;
  const isLawOffice = form?.primary_insurance?.status_type === 'law_office';
  const isCovidForm = form.form_type === formTypesMap.covid_form;
  const onAppointmentUpdate = isUnconfirmed ? fetchUnconfirmedForms : fetchForms;

  const handleItemSelection = () => {
    toggleItemSelection(form.id);
  };

  const openAppointmentPreview = () => {
    if (isDeleted) return;

    if (form.appointment_id) {
      openModal(AppointmentViewModal, {
        payload: {
          appointmentID: form.appointment_id,
          onWaitingListUpdate: onAppointmentUpdate,
          onAppointmentUpdate
        }
      });
    }
  };

  const openPreviewCompany = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: form?.primary_insurance?.insurance_company?.id }
    });
  };

  const openUserPreview = () => {
    if (form?.treating_physician?.id) {
      openModal(UserPreviewModal, {
        payload: { id: form?.treating_physician?.id }
      });
    }
  };

  const openFormPreviewModal = () => {
    if (isCovidForm) {
      openModal(CovidPreviewFormModal, {
        payload: { formID: form.id, onUpdateForm: fetchForms }
      });
    } else {
      openModal(FormPreviewModal, {
        payload: {
          isDeleted,
          formID: form.id,
          onApprove: fetchUnconfirmedForms
        }
      });
    }
  };

  return (
    <CardItem
      selected={isSelected}
      py={1}
      px={2}
      onClick={stopPropagation(openFormPreviewModal)}
    >
      <CardHeader
        disableCardMenu={isDeleted}
        isSelected={isSelected}
        disableCheckBox={hasRole(rolesMap.client,  rolesMap.patient)}
        onItemSelect={handleItemSelection}
        menuComponent={FormsMenu}
        menuComponentProps={{ form, disableCreatable: true }}
      >
        {!disablePatient ? (
          <UserLink
            disablePreview={!form?.patient}
            disableLink={!form?.patient}
            size="sm"
            variant="inherit"
            user={form?.patient || form}
          >
            {form.appointment_at && (
              <Link onClick={stopPropagation(openAppointmentPreview)}>
                <Date
                  disableIcon
                  title="Appt. Date"
                  variant="subtitle2"
                  date={form.appointment_at}
                  iconFontSize="small"
                  format="L LT"
                />
              </Link>
            )}
          </UserLink>
        ) : (
          <Date
            title="Appt. Date"
            variant="subtitle2"
            date={form.appointment_at}
            iconFontSize="small"
            format="L LT"
          />
        )}
      </CardHeader>

      <Body form={form} />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <UserLink
            variant="h5"
            size="sm"
            user={form.treating_physician}
            onClick={stopPropagation(openUserPreview)}
          >
            <Box mt={-0.5}>
              <Typography color="textSecondary" variant="caption">
                Physician
              </Typography>
            </Box>
          </UserLink>
        </Grid>

        {isLawOffice &&
          <Grid item xs={6}>
            <CompanyLink
              variant="h5"
              size="sm"
              company={form?.primary_insurance?.insurance_company}
              onClick={stopPropagation(openPreviewCompany)}
            />
          </Grid>
        }
      </Grid>
    </CardItem>
  );
};
