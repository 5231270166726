import { useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, List as MuiList, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { FilesContext } from '../../../../../files-common';
import { Loader } from '../../../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { TableHeader } from '../../../../../files-common/FilesList/TableHeader';
import { Row } from './Row';
import { styles } from './styles';

export const minRowHeight = 54;
export const columnsWidths = {
  name: 270,
  owner: 170,
  size: 90,
  date: 160,
  action: 64
};

const useStyles = makeStyles(styles);

export const List = ({ hiddenColumns }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const filesContext = useContext(FilesContext);
  const { pathname } = useLocation();
  const {
    files,
    isFetched,
    isFetching,
    loadNextPage,
    filter: { per_page, last_page, page, total },
    resetFiles
  } = filesContext;
  const classes = useStyles();
  const scrollElementRef = useRef();

  useEffect(() => {
    resetFiles({
      only_opened: +(pathname === '/files/recent'),
      order_by: pathname === '/files/recent' ? 'opened_at' : 'updated_at'
    });
  }, [ pathname ]);

  return (
    <div ref={scrollElementRef} className={classes.root}>
      <Loader loading={!isFetched} render={
        () => (
          <>
            {(!files.length || total === 0) &&
              <Typography align="center">No files found</Typography>
            }

            {(!!files.length || total > 0) &&
              <Paper elevation={0}>
                {!isMobile && (
                  <TableHeader
                    hiddenColumns={hiddenColumns}
                    filesContext={filesContext}
                  />
                )}

                <MuiList disablePadding>
                  <InfiniteListLoader
                    items={files}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    pagination={{ per_page, last_page, page }}
                    RowComponent={Row}
                    RowProps={{ isMobile, filesContext, hiddenColumns }}
                    scrollElementRef={scrollElementRef}
                    minRowHeight={minRowHeight}
                    onNeedNextPage={loadNextPage}
                  />
                </MuiList>
              </Paper>
            }
          </>
        )}
      />
    </div>
  );
};
