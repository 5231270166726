import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog, Grid, useMediaQuery } from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhoneOutlined';
import * as api from '../../../api/sip';
import { ModalBody, ModalFooter, ModalHeader } from '../../Modal';
import { Loader } from '../../Loader';
import { Form } from './Form';
import { validationSchema } from './validationSchema';

export const UpdateSipUserModal = ({
  DialogProps,
  payload: { user },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isCreatable = !user?.sipUser?.id;
  const apiReq = isCreatable ? api.createSipUser : api.updateSipUser;

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    apiReq(values).then((data) => {
      enqueueSnackbar('SIP User successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(() => {
      enqueueSnackbar('SIP User not created', { variant: 'error' });
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          user_id: user?.id,
          first_name: user?.sipUser?.first_name || user?.first_name,
          last_name: user?.sipUser?.last_name || user?.last_name,
          username: user?.sipUser?.username || null,
          email: user?.sipUser?.email || user?.email || null,
          numbers: user?.sipUser?.numbers || null,
          ...(user?.sipUser || {})
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject} icon={<ContactPhoneIcon />}>
              {user?.sipUser?.id ? 'Update SIP User' : 'Add SIP User'}
            </ModalHeader>

            <ModalBody>
              <Grid
                container
                spacing={isMobile ? 1 : 2}
                justify="space-between"
                component={Box}
                pb={isMobile ? 1 : 3}
                alignItems="flex-start"
              >
                <Form />
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
