import { createContext, useState } from 'react';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';

export const BodyPartsContext = createContext(null);

export const BodyPartsContextProvider = ({ initialPainfulParts, children }) => {
  const { openModal } = useModal();
  const [ painfulParts, setPainfulParts ] = useState(initialPainfulParts);
  const [ selectedPart, setSelectedPart ] = useState(null);

  const addPainfulPart = (part) => {
    if (painfulParts[part]) {
      return;
    }

    setPainfulParts((painfulParts) => ({
      ...painfulParts,

      [part]: {
        level: 0,
        direction: null
      }
    }));
  };

  const updatePainfulPart = (part, data) => {
    setPainfulParts((painfulParts) => ({
      ...painfulParts,

      [part]: data
    }));
  };

  const deletePainfulPart = (deletionPart) => {
    openModal(ConfirmationModal, {
      payload: {
        title: 'Delete part from list of pains?'
      },
      onModalResolved: () => {
        deletionPart === selectedPart && setSelectedPart(null);
        setPainfulParts((painfulParts) => {
          return Object.keys(painfulParts).reduce((result, part) => {
            if (part !== deletionPart) {
              result[part] = painfulParts[part];
            }

            return result;
          }, {});
        });
      }
    });
  };

  const providerValue = {
    painfulParts,
    selectedPart,
    addPainfulPart,
    updatePainfulPart,
    deletePainfulPart,
    setSelectedPart
  };

  return (
    <BodyPartsContext.Provider value={providerValue}>
      {children}
    </BodyPartsContext.Provider>
  );
};
