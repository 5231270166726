import { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as sessionsApi from '../../../../../../../api/login-queues/login-sessions';
import { amFormatDuration } from '../../../../../../../helpers/dates';
import { enqueueSnackbar } from '../../../../../../../store/snackbars';
import { ConfirmationModal } from '../../../../../../ConfirmationModal';
import { Fab } from '../../../../../../Fab';
import { IconButton } from '../../../../../../IconButton';
import { Loader } from '../../../../../../Loader';
import { useModal } from '../../../../../../ModalsProvider';
import { OfficeLink } from '../../../../../../OfficeLink';
import { UserLink } from '../../../../../../UserLink';
import { LoginSessionsContext } from '../../../../../sessions';
import { EditSessionModal } from '../../../../../sessions/EditSessionModal';
import { styles as commonStyles } from '../../styles';
import { styles } from './styles';

const useCommonStyles = makeStyles(commonStyles);
const useStyles = makeStyles(styles);

export const SessionCard = ({ isLoaded, item: session, recalculateHeight }) => {
  const { openModal } = useModal();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { updateLoginSession } = useContext(LoginSessionsContext);
  const [ isStopping, setIsStopping ] = useState(false);
  const [ duration, setDuration ] = useState();

  const editSession = () => {
    openModal(EditSessionModal, {
      payload: {
        session
      },

      onModalResolved: (session) => {
        updateLoginSession(session);
      }
    });
  };

  const closeSession = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setIsStopping(true);

        sessionsApi.stopSession(session.id).then((session) => {
          updateLoginSession(session);
          enqueueSnackbar('Session stopped', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Session not stopped', { variant: 'error' });
        }).finally(() => {
          setIsStopping(false);
        });
      }
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  useEffect(() => {
    const finishedAt = session?.finished_at;

    if (finishedAt) {
      return;
    }

    const initialDuration = moment().diff(moment.utc(session?.started_at).local(), 'seconds');

    const interval = setInterval(() => {
      setDuration((duration) => (duration || initialDuration) + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [ session?.started_at, session?.finished_at ]);

  return !isLoaded ? <Loader p={2} /> : (
    <Paper elevation={2} className={cn(classes.root, { [commonClasses.activeSession]: !session?.finished_at })}>
      <Grid container alignItems="center" className={classes.info}>
        <Grid item className={classes.label}>
          <Typography variant="body2" color="textSecondary">Started At</Typography>
        </Grid>

        <Grid item xs>
          <Typography variant="subtitle1">{moment.utc(session.started_at).local().format('L LT')}</Typography>
        </Grid>
      </Grid>

      <Grid container alignItems="center" className={classes.info}>
        <Grid item className={classes.label}>
          <Typography variant="body2" color="textSecondary">Finished At</Typography>
        </Grid>

        <Grid item xs>
          {session.finished_at ? (
            <Typography variant="subtitle1">{moment.utc(session.finished_at).local().format('L LT')}</Typography>
          ) : (
            <Loader surface loading={isStopping}>
              <Fab
                disabled={isStopping}
                color="error"
                size="small"
                variant="extended"
                onClick={closeSession}
              >
                {amFormatDuration(duration)} Close
              </Fab>
            </Loader>
          )}
        </Grid>
      </Grid>

      <Grid container alignItems="center" className={classes.info}>
        <Grid item className={classes.label}>
          <Typography variant="body2" color="textSecondary">User</Typography>
        </Grid>

        <Grid item xs>
          <UserLink size="sm" user={session.user} />
        </Grid>
      </Grid>

      <Grid container alignItems="center" className={classes.info}>
        <Grid item className={classes.label}>
          <Typography variant="body2" color="textSecondary">Notes</Typography>
        </Grid>

        <Grid item xs>
          <Typography>{session.note}
            <IconButton size="small" onClick={editSession}>
              <EditIcon />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>

      <Grid container alignItems="center" className={classes.info}>
        <Grid item className={classes.label}>
          <Typography variant="body2" color="textSecondary">Office</Typography>
        </Grid>

        <Grid item xs>
          <OfficeLink size="sm" office={session.office} />
        </Grid>
      </Grid>
    </Paper>
  );
};
