import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as profileApi from '../../../../../../../../api/profile';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { SvgIcon } from '../../../../../../../../components/SvgIcon';
import { setMedicalProfileLastGlobalAction } from '../../../../../../../../store/globalActions';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import * as types from '../../../MedicalProfileProvider/types';
import { contactTypesMap } from '../ContactsModal';
import { EditContactsModal } from '../EditContactsModal';

export const ContactMenu = ({ index, user, users, contacts, setInitialValues, onClose }) => {
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const contact_type = get(user, 'relationship') ? contactTypesMap.contact : contactTypesMap.employer;

  const handleDeleteContact = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        profileApi.updateMedicalProfileContacts({
          id: contacts.id,
          contacts: {
            ...contacts,
            [contact_type]: users?.filter((item, i) => i !== index)
          }
        }).then((data) => {
          setInitialValues(data);
          dispatchRedux(setMedicalProfileLastGlobalAction({ type: types.UPDATE_PROFILE, payload: data }));
          enqueueSnackbar('Contacts successfully updated', { variant: 'success' });
          onClose();
        }).catch(({ errors }) => {
          errors && enqueueSnackbar('Contacts not updated', { variant: 'error' });
        });
      }
    });
  };

  const handleOpenEditModal = () => {
    openModal(EditContactsModal, {
      payload: {
        initialValues: {
          index,
          user,
          users,
          contacts
        }
      },
      onModalResolved: (data) => {
        setInitialValues(data);
        onClose();
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={handleOpenEditModal}>
        <ListItemIcon>
          <SvgIcon color="primary"><EditIcon /></SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={handleDeleteContact}>
        <ListItemIcon>
          <SvgIcon color="error"><DeleteIcon /></SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
