import { PreviewContent } from '../../../../GuestMedicalProfile/PreviewContent';
import { ApptFormContent } from '../ApptFormContent';
import { AccidentInfo } from './AccidentInfo';
import { PatientHistory } from './PatientHistory';
import { GeneralInfo } from './GeneralInfo';
import { ReviewSystems } from './ReviewSystems';
import { PastMedicalHistory } from './PastMedicalHistory';
import { Documents } from '../Documents';

export const formStepComponents = [
  GeneralInfo,
  ApptFormContent,
  PatientHistory,
  AccidentInfo,
  PastMedicalHistory,
  ReviewSystems,
  Documents
];

export const formStepUnconfirmedComponents = [
  GeneralInfo,
  ApptFormContent,
  PatientHistory,
  AccidentInfo,
  PastMedicalHistory,
  ReviewSystems,
  PreviewContent
];
