import { useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import * as eventsApi from '../../../../api/schedule-events';
import { Loader } from '../../../Loader';
import { Button } from '../../../Button';
import { ModalFooter } from '../../../Modal';
import { eventConfirmationMap } from '../../eventConfirmationMap';

export const ConfirmationParticipation = ({ event, onChange }) => {
  const { user: currentUser } = useSelector(({ profile }) => profile);
  const user = event?.users?.find(({ id }) => id === currentUser.id);
  const confirmation = get(user, 'schedule_event.confirmation');
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const confirmEvent = (value) => () => {
    setIsSubmitting(true);

    const data = {
      ...event,
      confirmation: value
    };

    eventsApi.confirmEvent(data).then((data) => {
      setIsSubmitting(false);
      onChange({ ...event, ...data, id: event?.id });
    });
  };

  return (!!user &&
    <Loader
      surface
      loading={isSubmitting}
      render={() => (
        <ModalFooter title="Going?">
          <Button
            disabled={confirmation === eventConfirmationMap.yes}
            size="medium"
            color="success"
            onClick={confirmEvent(eventConfirmationMap.yes)}
          >
            Yes
          </Button>

          <Button
            disabled={confirmation === eventConfirmationMap.no}
            size="medium"
            color="error"
            onClick={confirmEvent(eventConfirmationMap.no)}
          >
            No
          </Button>

          <Button
            disabled={confirmation === eventConfirmationMap.maybe}
            size="medium"
            color="warning"
            onClick={confirmEvent(eventConfirmationMap.maybe)}
          >
            Maybe
          </Button>
        </ModalFooter>
      )}
    />
  );
};
