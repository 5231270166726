export const styles = ({ palette, shape: { borderRadius }, transitions }) => ({
  root: {
    borderRadius,

    '& > div > div > span': {
      backgroundColor: 'transparent'
    }
  },

  tab: {
    minHeight: 36,
    height: 36,
    borderLeft: '0 !important',
    whiteSpace: 'nowrap',
    transition: transitions.create(),

    '&:hover': {
      backgroundColor: palette.grey[100]
    }
  }
});
