export const widthBreakpointXXS = 650;
export const widthBreakpointXS = 800;
export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1350;
export const widthBreakpointLarge = 1500;
export const minRowHeight = 54;

export const columnsMap = {
  checkbox: 'checkbox',
  name: 'name',
  billFor: 'billFor',
  billFrom: 'billFrom',
  billTo: 'billTo',
  date: 'date',
  dueDate: 'dueDate',
  amount: 'amount',
  balance: 'balance',
  status: 'status',
  paid: 'paid',
  action: 'action'
};

export const columnsWidths = {
  [columnsMap.name]: 150,
  [columnsMap.billFor]: 200,
  [columnsMap.billFrom]: 200,
  [columnsMap.billTo]: 200,
  [columnsMap.date]: 140,
  [columnsMap.dueDate]: 140,
  [columnsMap.amount]: 140,
  [columnsMap.balance]: 140,
  [columnsMap.status]: 100,
  [columnsMap.paid]: 100,
  [columnsMap.action]: 54
};

export const mobileColumnWidths = {
  [columnsMap.name]: 150,
  [columnsMap.balance]: 80,
  [columnsMap.status]: 80,
  [columnsMap.action]: 54
};
