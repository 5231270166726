import { useCallback, useEffect, useRef, useState } from 'react';
import AES from 'crypto-js/aes';
import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import * as sessionsApi from '../../../../api/login-queues/login-sessions';
import { ModalFooter, ModalHeader, ModalBody } from '../../../Modal';
import { Loader } from '../../../Loader';
import { SessionForm, initialValues, validationSchema } from '../SessionForm';

export const AddSessionModal = ({
  DialogProps,
  payload: {
    account,
    service
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ isExtensionChecking, setIsExtensionChecking ] = useState(true);
  const [ isExtensionNotAvailable, setIsExtensionNotAvailable ] = useState(false);
  const [ isExisted, setIsExisted ] = useState();
  const checkExtensionTimeoutRef = useRef(null);
  const loginTimeoutRef = useRef(null);

  const checkExtension = () => {
    return new Promise((resolve, reject) => {
      const successTimeout = setTimeout(() => {
        resolve();
      }, 1000);

      checkExtensionTimeoutRef.current = setTimeout(() => {
        setIsExtensionNotAvailable(true);
        setIsExtensionChecking(false);

        clearTimeout(successTimeout);
        reject();
      }, 500);

      window.postMessage({ type: 'EXTENSION_CHECK' }, '*');
    });
  };

  const handleExtensionLoginResponse = useCallback((event) => {
    if (event.data?.type === 'EXTENSION_LOGIN_RESPONSE') {
      clearTimeout(loginTimeoutRef.current);

      if (event.data.payload?.isSuccess) {
        enqueueSnackbar('Session successfully started', { variant: 'success' });
        window.open(service.login_url);
        handleModalResolve();
      } else {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
        handleModalReject();
      }
    }
  }, []);

  const startSession = async (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    await checkExtension();

    return sessionsApi.createSession({
      ...values,

      force: isExisted,
      login_queue_account_id: account.id
    }).then(() => {
      window.postMessage({
        type: 'EXTENSION_LOGIN',
        payload: [
          AES.encrypt(service.login_url, import.meta.env.VITE_LOGIN_EXTENSION_SECRET).toString(),
          AES.encrypt(account.login, import.meta.env.VITE_LOGIN_EXTENSION_SECRET).toString(),
          AES.encrypt(account.password, import.meta.env.VITE_LOGIN_EXTENSION_SECRET).toString()
        ]
      }, '*');

      window.addEventListener('message', handleExtensionLoginResponse);

      loginTimeoutRef.current = setTimeout(() => {
        window.removeEventListener('message', handleExtensionLoginResponse);
        enqueueSnackbar('Something went wrong', { variant: 'error' });
        handleModalReject();
      }, 1000);
    }).catch((error) => {
      const { data: { errors } } = error;

      if (errors?.code?.includes('LOGIN_QUEUE_ANOTHER_ACTIVE_SESSION_EXISTED')) {
        setIsExisted(true);
      } else {
        enqueueSnackbar('Session not started', { variant: 'error' });
      }

      if (errors) {
        setErrors(errors);
      }
    });
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.type === 'EXTENSION_ACTIVATED') {
        setIsExtensionNotAvailable(false);
      } else if (event.data?.type === 'EXTENSION_CHECK_RESPONSE') {
        if (event.data.payload?.isSuccess) {
          clearTimeout(checkExtensionTimeoutRef.current);
          setIsExtensionChecking(false);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    checkExtension();

    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('message', handleExtensionLoginResponse);

      clearTimeout(checkExtensionTimeoutRef.current);
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={startSession}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Start login session
            </ModalHeader>

            <ModalBody>
              <Loader loading={isExtensionChecking}>
                {isExtensionNotAvailable ? (
                  <Alert severity="warning">
                    The login extension is not installed or not active.
                    Please check your installation, and try again.
                  </Alert>
                ) : (
                  <>
                    {isExisted &&
                      <Alert severity="warning">
                        You are already logged in. Do you want to close your old session and create a new one?
                      </Alert>
                    }

                    <SessionForm />
                  </>
                )}
              </Loader>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isExtensionChecking || isExtensionNotAvailable || isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {isExisted ? 'Close and Login' : 'Login'}
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
