import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useFormikContext } from 'formik';
import cn from 'classnames';
import {
  makeStyles,
  Box,
  Grid,
  FormControlLabel,
  Radio,
  Hidden,
  Typography
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Tooltip } from '../../../../../../components/Tooltip';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { OfficesLocationSelect } from '../../../../../../components/FormField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const RowDragHandle = SortableHandle(() => {
  return (
    <DragIndicatorIcon />
  );
});

export const SortableItem = SortableElement(({
  isDragging,
  itemIndex,
  arrayHelpers
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  const handleMainOfficeChange = ({ target: { value } }) => {
    setFieldValue('main_office_id', values?.offices.find((office) => {
      return office?.id?.id === +value;
    })?.id || null);
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-end"
      className={classes.itemRoot}
    >
      <Grid
        item
        className={cn(
          classes.itemRoot_drag,
          { [classes.itemRoot_dragHandle]: isDragging }
        )}
      >
        {isDragging && <RowDragHandle />}
      </Grid>

      <Grid item className={classes.itemRoot_radioButton}>
        <Box mb={-1}>
          <FormControlLabel
            label={false}
            value={values?.offices?.[itemIndex]?.id?.id || ''}
            control={
              <Tooltip title="Set as main">
                <Radio
                  checked={values?.offices?.[itemIndex]?.id?.id === values?.main_office_id}
                  color="primary"
                  onChange={handleMainOfficeChange}
                />
              </Tooltip>
            }
          />
        </Box>
      </Grid>

      <Grid item sm={4} xs={9}>
        <OfficesLocationSelect
          isCreatable
          formattedValue={false}
          label="Office name"
          name={`offices.${itemIndex}.id`}
          params={{ without_company: 1 }}
          hiddenOptions={values.offices.map(({ id }) => id)}
        />
      </Grid>

      <Hidden mdUp>
        <Grid item xs={1}>
          <IconButton
            size="small"
            color="error"
            onClick={handleClearField(arrayHelpers, itemIndex)}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Hidden>

      <Grid item xs>
        <Typography noWrap color="textSecondary">
          {values.offices?.[itemIndex]?.id?.full_address || '-'}
        </Typography>
      </Grid>

      <Hidden smDown>
        <Grid item>
          <IconButton
            edge="end"
            color="error"
            onClick={handleClearField(arrayHelpers, itemIndex)}
          >
            <ClearIcon />
          </IconButton>
        </Grid>
      </Hidden>
    </Grid>
  );
});
