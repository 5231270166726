import { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Collapse, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { TestCodesSelect, TextField } from '../../../../../../components/FormField';
import { IconButton } from '../../../../../../components/IconButton';
import { Fab } from '../../../../../../components/Fab';
import { useModal } from '../../../../../../components/ModalsProvider';
import { ApptInfo } from '../ApptInfo';
import { ChildrenContent } from './ChildrenContent';

export const testChildInitialValues = {
  is_checked: true,
  value: null,
  standard_range: null
};

export const testInitialValues = {
  test_id: null,
  standard_range: null,
  date_collected: null,
  date_resulted: null,
  ordered_id: null,
  value: null,
  items: [ testChildInitialValues ]
};

export const TestsBody = () => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();
  const [ children, setChildren ] = useState({});

  const handleChangeTest = (index) => (option) => {
    if (option && option?.data) {
      setChildren({ ...children, [index]: option?.data?.children || [] });
      setFieldValue(`lab_tests[${index}].test_description`, option?.data?.description);

      if (!option?.data?.children.length) {
        setFieldValue(`lab_tests[${index}].items`, []);
        setFieldValue(`lab_tests[${index}].value`, null);
        setFieldValue(`lab_tests[${index}].standard_range`, null);
      } else {
        setFieldValue(`lab_tests[${index}].items`, [ testChildInitialValues ]);
      }
    }
  };

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  return (
    <Box my={6}>
      <FieldArray
        name="lab_tests"
        render={({ remove, push }) => (
          <>
            <Box mb={2} color="info.main" display="flex" alignItems="center">
              <Typography variant="h3" color="inherit">
                Select Test
              </Typography>

              <Box component="span" ml={2}>
                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon/>}
                  onClick={() => push(testInitialValues)}
                >
                  Add
                </Fab>
              </Box>
            </Box>

            {values?.lab_tests?.map((test, i) => (
              <Box display="flex" flexWrap="nowrap" mb={3} key={i}>
                <Box mt={3.5} mr={0.5} alignSelf="flex-start">
                  <Typography variant="h5" component="span">{i + 1}.</Typography>
                </Box>

                <Box flex={1}>
                  <Grid container spacing={2} alignItems="flex-end">
                    <Grid item sm={3} xs={12}>
                      <TestCodesSelect
                        name={`lab_tests.${i}.test_id`}
                        TextFieldProps={{ required: true, margin: 'dense' }}
                        onChange={handleChangeTest(i)}
                      />
                    </Grid>

                    <Grid item sm={9} xs={12}>
                      {values.lab_tests[i].test_id &&
                        <Box mt={1}>
                          <Typography variant="body2" color="textSecondary">
                            Description
                          </Typography>
                        </Box>
                      }

                      <Typography>{values.lab_tests[i]?.test_description}</Typography>
                    </Grid>

                    <ApptInfo disableApptFiled name={`lab_tests.${i}.`}/>

                    {children[i]?.length ? (
                      <Collapse
                        unmountOnExit
                        in={!!children[i].length}
                        timeout="auto"
                        component={Box}
                        flexGrow={1}
                        py={2}
                      >
                        <Typography variant="h5">Component:</Typography>

                        {children[i]?.map((item, index) => (
                          <Grid
                            key={index}
                            container
                            spacing={2}
                            alignItems="center"
                          >
                            <ChildrenContent
                              index={index}
                              parentIndex={i}
                              item={item}
                              label={item.name}
                              name={`lab_tests.${i}.items.${index}`}
                            />
                          </Grid>
                        ))}
                      </Collapse>
                    ) : (
                      <Collapse
                        in={!!values?.lab_tests[i]?.test_id && !children[i]?.length}
                        unmountOnExit
                        timeout="auto"
                        component={Box}
                        flexGrow={1}
                        ml={-1}
                      >
                        <Grid container spacing={2} component={Box} m="0 !important">
                          <Grid item sm={3} xs={12}>
                            <TextField
                              name={`lab_tests.${i}.value`}
                              label="Your Value"
                            />
                          </Grid>

                          <Grid item sm={3} xs={12}>
                            <TextField
                              name={`lab_tests.${i}.standard_range`}
                              label="Standart Range"
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    )}
                  </Grid>
                </Box>

                {values.lab_tests.length > 1 &&
                  <Box mt={3} alignSelf="flex-start">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={handleClearField(remove, i)}
                    >
                      <ClearIcon fontSize="small"/>
                    </IconButton>
                  </Box>
                }
              </Box>
            ))}
          </>
        )}
      />
    </Box>
  );
};
