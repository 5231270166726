import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import T from 'prop-types';
import IMask from 'imask';
import { IMaskInput } from 'react-imask';
import { makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getTimeSteps } from '../../../../helpers/dates';
import { usePrevious } from '../../../../helpers/hooks';
import { useFormikField } from '../../useFormikField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  name: T.string.isRequired,
  startTime: T.string,
  endTime: T.string,
  interval: T.number
};

const outerFormat = 'HH:mm:ss';
const innerFormat = 'hh:mm A';
const transformOuterValueToInner = (value = null) => {
  return value && moment(value, outerFormat).format(innerFormat);
};

export const TimePicker = ({
  required = false,
  name,
  label,
  disabled = false,
  placeholder = 'hh:mm AM',
  startTime,
  endTime,
  interval
}) => {
  const classes = useStyles();
  const { fieldProps, error } = useFormikField(name);
  const { initialValue, touched } = fieldProps[1];
  const formikHelpers = fieldProps[2];
  const [ value, setValue ] = useState(transformOuterValueToInner(initialValue));
  const inputRef = useRef();
  const options = useMemo(() => getTimeSteps({ startTime, endTime, interval }), []);
  const previousValue = usePrevious(value);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const renderInput = ({ inputProps, InputLabelProps, ...params }) => {
    if (value) {
      InputLabelProps.shrink = true;
    }

    const handleAccept = (value, mask) => {
      if (previousValue !== value) {
        setValue(mask._unmaskedValue ? value : null);
      }

      formikHelpers.setValue(null);
    };

    const handleComplete = (value) => {
      formikHelpers.setValue(moment(value, innerFormat).format(outerFormat));
    };

    const props = !disabled ? {
      ...inputProps,
      mask: Date,
      pattern: innerFormat,
      lazy: !value,
      format: (date) => moment(date).format(innerFormat),
      parse: (str) => moment(str, innerFormat),
      blocks: {
        hh: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },

        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59
        },

        A: {
          mask: IMask.MaskedEnum,
          enum: [ 'AM', 'PM' ],
          prepare: (str) => str.toUpperCase(),
          placeholderChar: '-'
        }
      },
      onComplete: handleComplete,
      onAccept: handleAccept
    } : {};

    return (
      <TextField
        {...params}
        disabled={disabled}
        className={classes.textField}
        required={required}
        error={touched && !!error}
        value={value}
        label={label}
        placeholder={placeholder}
        helperText={touched && error}
        InputLabelProps={InputLabelProps}
        InputProps={{
          ...params.InputProps,
          inputComponent: IMaskInput
        }}
        inputProps={{
          value,
          ref: (ref) => inputRef.current = inputProps.ref.current = ref,
          ...props
        }}
      />
    );
  };

  useEffect(() => {
    setValue(transformOuterValueToInner(initialValue));
  }, [ initialValue ]);

  useEffect(() => {
    if (!value && inputRef.current) {
      inputRef.current.value = null;
    }
  }, [ value ]);

  return (
    <Autocomplete
      freeSolo
      disableClearable={!value}
      options={options}
      value={value}
      renderInput={renderInput}
      onChange={handleChange}
    />
  );
};

TimePicker.propTypes = propTypes;
