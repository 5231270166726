import { api } from '../api';

export const fetchEmails = (config) => {
  return api.get('/public-access', config).then(({ data }) => {
    return data;
  });
};

export const shareByEmail = (data, config) => {
  return api.post('/public-access', data, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const extendAccess = (id, data, config) => {
  return api.post(`/public-access/${id}/extend`, data, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const deleteAccess = (id, config) => {
  return api.delete(`/public-access/${id}`, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};
