import { api } from '../../api';

export const fetchArrivalStatuses = (config) => {
  return api.get('/arrival-statuses', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchArrivalStatus = (id, config) => {
  return api.get(`/arrival-statuses/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createArrivalStatus = (data) => {
  return api.post('/arrival-statuses', data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateArrivalStatus = ({ id, ...data }) => {
  return api.put(`/arrival-statuses/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteArrivalStatus = (id) => {
  return api.delete(`/arrival-statuses/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
