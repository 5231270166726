import { Bar } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';

export const Chart = ({ data, labels }) => {
  const { palette } = useTheme();
  const billed = data.map((item) => item.billed);
  const payment = data.map((item) => item.payment);
  const overdue = data.map((item) => item.overdue);

  const getChartData = () => ({
    labels,
    datasets: [
      {
        label: 'Billed',
        backgroundColor: palette.darkgreen,
        hoverBackgroundColor: palette.darkgreen,
        data: billed
      },
      {
        label: 'Payment',
        backgroundColor: palette.purple,
        hoverBackgroundColor: palette.purple,
        data: payment
      },
      {
        label: 'Overdue',
        backgroundColor: palette.error.main,
        hoverBackgroundColor: palette.error.main,
        data: overdue
      }
    ]
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend:{
      display:false
    },
    scales: {
      yAxes: [ {
        ticks: {
          beginAtZero: true,
          callback: (label) => {
            label = new Intl.NumberFormat('en-US').format(label);

            return label;
          }
        },
        gridLines: {
          color: palette.grey[200],
          borderDash: [ 2, 3 ]
        }
      } ],
      xAxes: [ {
        gridLines: {
          drawOnChartArea: false,
          color: palette.grey[200],
          borderDash: [ 2, 3 ]
        },
        ticks: {
          beginAtZero: true
        }
      } ]
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem) => ' $ ' + new Intl.NumberFormat('en-US').format(tooltipItem.value)
      }
    }
  };

  return (
    <Bar data={getChartData()} options={chartOptions} />
  );
};
