import { Box, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import cn from 'classnames';
import { getUserFullName } from '../../../helpers/users';
import {
  styles
} from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/Documents/SignedUsers/UserAvatar/styles';
import { Tooltip, TooltipTitle } from '../../Tooltip';
import { Badge } from '../../Badge';
import { UserAvatar } from '../../users';

const useStyles = makeStyles(styles);

export const SigningMembers = ({ signInfo = {} }) => {
  const classes = useStyles();
  const users = signInfo ? Object.entries(signInfo).map(([ , value ]) => value && ({ ...value })) : [];

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      {users?.map((userInfo, index) => userInfo && (
        <Box key={userInfo?.user?.id + index} mr={1}>
          <Tooltip
            arrow
            title={
              <TooltipTitle itemTitle={getUserFullName(userInfo?.user)} flexDirection="column">
                <Box pt={2}>
                  <Typography>Role: {userInfo?.role}</Typography>
                  <Typography>
                    Documents: {userInfo?.total_docs_for_sign || 0}
                  </Typography>

                  <Typography>
                    Signed: {userInfo?.count_signed || 0}
                  </Typography>
                </Box>
              </TooltipTitle>
            }
          >
            <Badge
              overlap="circle"
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              badgeContent={
                userInfo?.is_signed
                  ? <CheckCircleIcon className={classes.badgeIcon}/>
                  : <RemoveCircleIcon className={cn(classes.badgeIcon, classes.badgeIcon__red)}/>
              }
            >
              <UserAvatar size="md" user={userInfo?.user} />
            </Badge>
          </Tooltip>
        </Box>
      ))}
    </Box>
  );
};
