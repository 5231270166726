export const styles = ({ palette: { divider } }) => ({
  root: {
    display: 'flex',
    height: '100%',
    border: `1px solid ${divider}`
  },

  sidebar: {
    flexShrink: 0,
    width: 290,
    height: '100%'
  },

  main: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  }
});
