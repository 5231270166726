import {
  makeStyles,
  Box,
  Paper,
  SvgIcon,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { mdiClipboardTextPlayOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Date } from '../../../../Date';
import { previewTitleMap } from '../../diagnosis';
import { HeaderInfo } from './HeaderInfo';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ report }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Paper variant="outlined" square className={classes.header}>
        <div className={classes.mainIcon}>
          <SvgIcon fontSize="large" color="primary">
            <Icon path={mdiClipboardTextPlayOutline} />
          </SvgIcon>
        </div>

        <div className={classes.mainInfo}>
          <Box display="flex" alignItems="flex-start" mb={1}>
            {!isTablet && (
              <Date
                color="textSecondary"
                date={report?.created_at}
                format="L LT"
              />
            )}

            <Box display="flex" alignItems="flex-start" color="success.main" ml={isTablet ? 0 : 7}>
              <Box mr={2}>
                <Typography variant={isTablet ? 'body2' : 'h5'} color="inherit">Last Visit</Typography>
              </Box>

              <Date
                variant={isTablet ? 'body2' : 'caption'}
                color="textSecondary"
                date={report?.updated_at}
                format="L LT"
              />
            </Box>
          </Box>

          <Typography className={classes.mainInfo__title}>
            {previewTitleMap[report.type]}
          </Typography>
        </div>

        {!isTablet && <HeaderInfo report={report} />}
      </Paper>

      {isTablet && <HeaderInfo report={report} />}
    </>
  );
};
