import { Link as RouterLink } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import { Link, makeStyles, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ payment }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <div className={classes.content}>
          {payment?.invoice ?
            <Link
              noWrap
              target="_blank"
              component={RouterLink}
              color="textSecondary"
              to={itemsRoutesByModels.invoice(payment?.invoice?.id)}
              title={payment?.invoice?.number}
            >
              {payment?.invoice?.number}
            </Link>
            :
            '-'
          }

          <div className={classes.header}>
            <Typography
              className={cn(
                classes.balanceTotal,
                { [classes.balanceTotal_red]: payment?.balance_due < 0 }
              )}
            >
              <CurrencyFormat value={payment?.invoice?.balance} />
            </Typography>

            <Typography color="textSecondary" className={classes.balanceDue}>
              Balance Due
            </Typography>
          </div>

          <Typography className={classes.total}>
            Total <CurrencyFormat value={payment?.invoice?.total} />
          </Typography>
        </div>

        <div className={classes.content}>
          <Typography color="textSecondary" component="div" className={classes.contentDate}>
            <CalendarIcon fontSize="small" className={classes.contentDateIcon}/>

            {moment(payment.date).format('L')}
          </Typography>

          <div className={classes.header}>
            <Typography className={cn(classes.balanceTotal, classes.dueDate)}>
              {!payment?.invoice?.due_date ? '-' : moment(payment?.invoice?.due_date).format('DD MMM, YY')}
            </Typography>

            <Typography color="textSecondary" className={classes.balanceDue}>
              Due Date
            </Typography>
          </div>

          <Typography className={classes.total}>
            Amount <CurrencyFormat value={payment.amount} />
          </Typography>
        </div>
      </div>
    </div>
  );
};
