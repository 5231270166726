import { Chip, makeStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: spacing(-0.25),
    font: 'inherit',
    lineHeight: 1.5
  }
});

const useStyles = makeStyles(styles);

export const SingleValue = ({ children: user }) => {
  const classes = useStyles();
  const isUser = typeof user !== 'string';

  return isUser && (
    <div className={classes.root}>
      <Chip
        tabIndex={-1}
        label={user.email}
      />
    </div>
  );
};
