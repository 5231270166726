import { createContext, useRef, useEffect, useReducer, useContext } from 'react';
import { isEqual, omit } from 'lodash';
import { api } from '../../../api';
import * as eventsApi from '../../../api/schedule-events';
import { ScheduleEventsFilterContext } from '../index';
import { usePrevious } from '../../../helpers/hooks';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const ScheduleContext = createContext();

export const ScheduleProvider = ({ children, initialSelectedEventsIDs = [] }) => {
  const { filter: commonFilter } = useContext(ScheduleEventsFilterContext);
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { pagination, filter } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchEvents = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_SCHEDULE_REQUEST });

    eventsApi.fetchEvents({
      params: {
        ...filter,
        ...newFilter
      },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_SCHEDULE_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const reloadEvents = (newFilter = {}) => {
    dispatch({ type: types.RESET_SCHEDULE, payload: newFilter });
    fetchEvents({ page: 1, ...newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchEvents({ page: filter.page + 1 });
    }
  };

  const addEvent = (event) => {
    dispatch({ type: types.ADD_SCHEDULE_EVENT, payload: event });
  };

  const updateEvent = (event) => {
    dispatch({ type: types.UPDATE_SCHEDULE_EVENT, payload: event });
  };

  const deleteEvent = (event) => {
    dispatch({ type: types.DELETE_SCHEDULE_EVENT, payload: event.id || event });
  };

  const toggleEventSelection = (id) => {
    dispatch({ type: types.TOGGLE_EVENT_SELECTION, payload: id });
  };

  const toggleSingleEventSelection = (id) => {
    dispatch({ type: types.TOGGLE_SINGLE_EVENT_SELECTION, payload: id });
  };

  const toggleAllEventsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_EVENTS_SELECTION });
  };

  const allEventsIsSelected = () => {
    return state.events.length === state.selectedEventsIDs.length;
  };

  const getSelectedEvents = () => {
    return state.events.filter((event) => {
      return state.selectedEventsIDs.includes(event.id);
    });
  };

  const providerValue = {
    ...state,
    filter: { ...filter, ...pagination },

    // functions
    fetchEvents,
    applyFilter,
    reloadEvents,
    addEvent,
    updateEvent,
    deleteEvent,
    loadNextPage,
    allEventsIsSelected,
    toggleEventSelection,
    toggleSingleEventSelection,
    toggleAllEventsSelection,
    getSelectedEvents
  };

  useEffect(() => {
    initialSelectedEventsIDs?.forEach((eventID) => {
      toggleEventSelection(eventID);
    });
  }, [ initialSelectedEventsIDs ]);

  useEffect(() => {
    const newFilter = omit({ ...filter, ...commonFilter }, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      reloadEvents(newFilter);
    }
  }, [ prevFilter, filter, commonFilter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ScheduleContext.Provider value={providerValue}>
      {children}
    </ScheduleContext.Provider>
  );
};
