import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import _ from 'lodash';
import { initialState } from './initialState';
import * as types from './types';

export const reducer = createHookReducer({
  [types.INCOMES_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.INCOMES_FETCH_SUCCESS]: (
    { filter, incomes, ...state },
    { data: newIncomes, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      incomes: page > 1 ?
        incomes.filter(({ id }) => !newIncomes.find((loadIncome) => id === loadIncome.id)).concat(newIncomes)
        :
        newIncomes
    };
  },

  [types.ADD_INCOMES]: (state, payload) => {
    const { filter, incomes, ...otherState } = state;

    return {
      ...otherState,

      filter,
      incomes: [ ...incomes,  payload ]
    };
  },

  [types.UPDATE_INCOMES_IN_LIST]: (
    { filter, incomes, ...state },
    income
  ) => {
    return {
      ...state,

      filter,
      incomes: incomes.map((item) => {
        return item.id === income.id ? income : item;
      })
    };
  },

  [types.DELETE_INCOMES]: ({ pagination, filter, incomes, ...state }, incomeId) => {
    return {
      ...state,

      incomes: incomes.filter((income) => income.id !== incomeId)
    };
  },

  [types.RESET_INCOMES]: ({ filter, ...state }, newFilter) => {
    const prevFilter = _.isEqual(newFilter, { ...initialState.filter }) && newFilter;

    return {
      ...state,

      isFetched: false,
      filter: prevFilter || { ...filter, ...newFilter, page: 1 }
    };
  }
});
