import { useRef } from 'react';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { FilesList } from '../FilesList';

export const DownloadsTab = () => {
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars
      style={{ height: '100%' }}
      scrollerRef={scrollElementRef}
    >
      <FilesList />
    </CustomScrollbars>
  );
};
