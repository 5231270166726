import { useState } from 'react';

export const useStepper = ({ steps }) => {
  const [ activeStep, setActiveStep ] = useState(0);
  const [ completed, setCompleted ] = useState(new Set());

  const totalSteps = () => {
    return steps.length;
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const completedSteps = () => {
    return completed.size;
  };

  const isAllStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !isAllStepsCompleted()
        ? steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);

    newCompleted.add(activeStep);
    setCompleted(newCompleted);

    if (completed.size !== totalSteps()) {
      handleNext();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted(new Set());
  };

  const isStepCompleted = (step) => {
    return completed.has(step);
  };

  return {
    completed,
    activeStep,
    totalSteps,
    completedSteps,
    isLastStep,
    isStepCompleted,
    isAllStepsCompleted,
    handleNext,
    handleBack,
    handleStep,
    handleSkip,
    handleComplete,
    handleReset
  };
};
