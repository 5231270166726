import { useContext, useEffect } from 'react';
import { List as MuiList, Typography, Box } from '@material-ui/core';
import { Loader } from '../../../../../../components/Loader';
import { PageBody } from '../../../../../../components/Page';
import { TablePagination } from '../../../../../../components/TablePagination';
import { ContactsContext } from '../../ContactsProvider';
import { Row } from './Row';
import { Header } from './Header';

export const widthBreakpointSmall = 1080;
export const widthBreakpointMedium = 1280;
export const widthBreakpointLarge = 1530;
export const widthBreakpointExtraLarge = 1680;
export const columnsWidths = {
  name: 240,
  position: 150,
  role: 150,
  office_name: 200,
  address: 250,
  venue: 250,
  email: 200,
  phone: 150,
  actions: 60
};

export const List = ({ width }) => {
  const {
    contacts,
    isFetched,
    meta,
    resetContacts
  } = useContext(ContactsContext);

  useEffect(() => {
    resetContacts();
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => !contacts.length ? (
          <Box p={3}>
            <Typography align="center">No contacts found</Typography>
          </Box>
        ) : (
          <>
            <Header width={width} />

            <PageBody disablePaddings>
              <MuiList disablePadding>
                {contacts.map((contact) => (
                  <Row
                    key={contact.id}
                    width={width}
                    contact={contact}
                  />
                ))}
              </MuiList>
            </PageBody>

            <TablePagination borderTop pagination={meta} onChange={resetContacts} />
          </>
        )}
      />
    </Box>
  );
};
