import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../listConfig';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        {/* TODO: open for future settings */}
        {/*<ListRowCheckbox*/}
        {/*  onClick={toggleAllInsurancesSelection}*/}
        {/*  checked={allInsurancesIsSelected()}*/}
        {/*  indeterminate={!!selectedIDs.length && !allInsurancesIsSelected()}*/}
        {/*/>*/}

        <ListRowCell flexBasis={columnsWidths.statusType}>
          Status Type
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.type}>
          Type Insurance
        </ListRowCell>

        <ListRowCell grow noWrap flexBasis={columnsWidths.insuranceId}>
          Insurance ID
        </ListRowCell>

        <ListRowCell grow noWrap flexBasis={columnsWidths.company}>
          Insur. Company
        </ListRowCell>

        <ListRowCell grow noWrap flexBasis={columnsWidths.caseInfo}>
          Case
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.groupId}>
          Group ID
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.pcp}>
          PCP,$
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.spc}>
          SPC,$
        </ListRowCell>

        <ListRowCell noWrap flexBasis={columnsWidths.financialClass}>
          Financial Class
        </ListRowCell>

        <ListRowCell noWrap flexBasis={columnsWidths.expirationDate}>
          Expiration day
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
