import { createContext, useEffect, useReducer, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { api } from '../../../api';
import * as tasksApi from '../../../api/tasks';
import { TaskModal } from '../../../app/Dashboard/TasksPage/TaskModal';
import { usePrevious } from '../../../helpers/hooks';
import { setTasksLastGlobalAction } from '../../../store/globalActions';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { reducer } from './reducer';
import { initialState } from './initialState';
import { TasksFilterContext  } from './TasksFilterContext';
import * as types from './types';

export const TasksContext = createContext();

export const TasksProvider = ({
  children,
  initialState: initialStateProp = {},
  fixedFilter = {}
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const dispatchRedux = useDispatch();
  const {
    tasks,
    pagination,
    isFetching,
    isFetched,
    selectedTasksIDs
  } = state;
  const { filter: commonFilter, setSelectedTasksIDs, setTotalTasks } = useContext(TasksFilterContext);
  const tasksLastGlobalAction = useSelector(({ globalActions }) => globalActions.tasksLastGlobalAction);
  const lastUploadedFile = useSelector(({ uploads }) => uploads.lastUploadedFile);
  const filter = { ...state.filter, ...(fixedFilter?.filter || fixedFilter) };
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});
  const { pathname } = useLocation();
  const isCasePage = pathname.includes('/cases/');

  const fetchTasks = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.TASKS_FETCH_REQUEST });

    tasksApi.fetchTasks({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.TASKS_FETCH_SUCCESS, payload: data });
      setTotalTasks(pagination?.total);
    });
  };

  const deleteTask = (id, history) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        history && history.location.pathname === `/tasks/${id}` && history.goBack();

        tasksApi.deleteTask(id).then(() => {
          dispatchRedux(setTasksLastGlobalAction({ type: types.DELETE_TASK_SUCCESS, payload: id }));
          enqueueSnackbar('Task successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  const updateTask = (task) => {
    openModal(TaskModal, {
      payload: {
        taskData: task,
        isEditable: true
      }
    });
  };

  const copyTask = (id) => {
    openModal(ConfirmationModal, {
      payload: {
        content: 'Make a copy?'
      },
      onModalResolved: () => {
        tasksApi.copyTask(id).then((data) => {
          dispatch({ type: types.ADD_TASKS, payload: [ data ] });
          enqueueSnackbar('Task successfully copied', { variant: 'success' });
          updateTask(data);
        });
      }
    });
  };

  const openPreview = (task) => {
    openModal(TaskModal, {
      payload: {
        id: task.id,
        deleteTask
      }
    });
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_TASK_SELECTION, payload: id });
  };

  const allTasksIsSelected = () => {
    return tasks.length === selectedTasksIDs.length;
  };

  const toggleAllTasksSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_TASKS_SELECTION });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchTasks({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetTasks = (newFilter) => {
    dispatch({ type: types.RESET_TASKS, payload: newFilter });

    fetchTasks({ page: 1, ...newFilter });
  };

  const providerValue = {
    tasks,
    pagination,
    filter: {
      ...filter,
      ...pagination
    },
    isFetching,
    isFetched,
    selectedTasksIDs,

    // functions
    deleteTask,
    updateTask,
    openPreview,
    loadNextPage,
    fetchTasks,
    resetTasks,
    copyTask,
    allTasksIsSelected,
    toggleItemSelection,
    toggleAllTasksSelection
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    setSelectedTasksIDs(selectedTasksIDs);
  }, [ selectedTasksIDs ]);

  useEffect(() => {
    if (tasksLastGlobalAction) {
      dispatch(tasksLastGlobalAction);

      if (
        (tasksLastGlobalAction.type === types.UPDATE_TASK_POSITION_FAILD) ||
          (isCasePage && tasksLastGlobalAction.type === types.UPDATE_TASK_IN_LIST)
      ) {
        fetchTasks();
      }
    }
  }, [ tasksLastGlobalAction ]);

  useEffect(() => {
    const newFilter = { ...filter, ...omit(commonFilter, Object.keys(fixedFilter?.filter || fixedFilter)) };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, commonFilter ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetTasks(filter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    if (lastUploadedFile && lastUploadedFile.owner_type === 'task') {
      const task = tasks.find(({ id }) => id === lastUploadedFile.owner_id);

      if (task) {
        dispatch({ type: types.UPDATE_TASK_IN_LIST, payload: { ...task, files_count: task.files_count + 1 } });
      }
    }
  }, [ lastUploadedFile ]);

  return (
    <TasksContext.Provider value={providerValue}>
      {children}
    </TasksContext.Provider>
  );
};
