import { FieldArray, useFormikContext } from 'formik';
import { Grid, Box, FormControlLabel, Typography, Fab, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import NotesIcon from '@material-ui/icons/Notes';
import CloseIcon from '@material-ui/icons/Close';
import {
  TextField,
  SimpleSelect,
  Checkbox,
  CPTCodesSelect,
  KeyboardDatePicker
} from '../../FormField';
import { SvgIcon } from '../../SvgIcon';
import { UsersSelect } from '../../users';

const lateralityOptions = [
  { value: 'LT', label: 'LT' },
  { value: 'RT', label: 'RT' },
  { value: 'OU', label: 'OU' }
];

export const procedureInitialValues = {
  from_id: null,
  to_id: null,
  from_description: '',
  to_description: '',
  laterality: lateralityOptions[0].value,
  visits: null,
  min: null
};

export const AuthorizationForm = () => {
  const { errors, values, setFieldValue } = useFormikContext();

  const handleCptChange = (index) => (cpt) => {
    setFieldValue(`procedures[${index}].procedure`, cpt);
  };

  return (
    <>
      <Grid container spacing={4} alignItems="flex-end">
        <Grid item sm={4} xs={12}>
          <UsersSelect
            required
            name="referring_physician_id"
            label="Referring Physician"
            margin="dense"
            params={{ role: 'doctor' }}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            required
            disabled={values.is_pending && !errors.authorization}
            name="authorization"
            label="Authorization #"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <FormControlLabel
            label="Pending"
            control={<Checkbox name="is_pending" />}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} alignItems="flex-end">
        <Grid item sm={4} xs={12}>
          <KeyboardDatePicker
            name="effective_date"
            label="Effective day"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <KeyboardDatePicker
            name="expiration_date"
            label="Expiration day"
            margin="dense"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <TextField
            name="referral"
            label="Referral #"
            margin="dense"
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item sm={4} xs={12}>
          <UsersSelect
            name="primary_care_physician_id"
            label="Primary Care Physician"
            margin="dense"
            params={{ role: 'doctor' }}
          />
        </Grid>
      </Grid>

      <Box mb={6}>
        <FieldArray
          name="procedures"
          render={({ remove, push }) => (
            <>
              <Box mt={4}>
                <Typography variant="h4">
                  Procedure <Typography component="span" color="error">*</Typography>

                  <Box component="span" ml={1}>
                    <Fab
                      color="primary"
                      onClick={() => push(procedureInitialValues)}
                    >
                      <AddIcon fontSize="small" />
                    </Fab>
                  </Box>
                </Typography>
              </Box>

              {values.procedures.map((procedure, index) => (
                <Box key={index} display="flex" flexWrap="nowrap" mb={3}>
                  <Box mt={3.5} mr={0.5} alignSelf="flex-start">
                    <Typography component="span">{index + 1}.</Typography>
                  </Box>

                  <Box flex={1}>
                    <Grid container spacing={2}>
                      <Grid item sm={3} xs={12}>
                        <CPTCodesSelect
                          required
                          name={`procedures.${index}.procedure_id`}
                          label="Procedure"
                          margin="dense"
                          onChange={handleCptChange(index)}
                        />
                      </Grid>

                      {!!values.procedures[index]?.procedure?.description &&
                        <Grid item sm={9} xs={12}>
                          <Box mt={1}>
                            <Typography variant="body2" color="textSecondary">
                              Procedure description
                            </Typography>
                          </Box>

                          <Typography style={{ wordWrap: 'break-word' }}>
                            {values.procedures[index]?.procedure?.description}
                          </Typography>
                        </Grid>
                      }
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item sm={3} xs={12}>
                        <SimpleSelect
                          required
                          name={`procedures.${index}.laterality`}
                          label="Laterality"
                          margin="dense"
                          options={lateralityOptions}
                        />
                      </Grid>

                      <Grid item sm={3} xs={12}>
                        <TextField
                          required
                          zeroMinWidth
                          type="number"
                          name={`procedures.${index}.visits`}
                          label="Visits"
                          margin="dense"
                        />
                      </Grid>

                      <Grid item sm={3} xs={12}>
                        <TextField
                          required
                          zeroMinWidth
                          name={`procedures.${index}.min`}
                          label="Minutes"
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {values.procedures.length > 1 &&
                    <Box mt={3} alignSelf="flex-start">
                      <IconButton
                        size="small"
                        onClick={() => remove(index)}
                      >
                        <SvgIcon color="error">
                          <CloseIcon/>
                        </SvgIcon>
                      </IconButton>
                    </Box>
                  }
                </Box>
              ))}
            </>
          )}
        />
      </Box>

      <Box mb={3}>
        <Typography gutterBottom variant="h5" color="textSecondary">
          <NotesIcon fontSize="inherit" />

          <Box component="span" ml={1}>Notes</Box>
        </Typography>

        <TextField
          fullWidth
          multiline
          rows={2}
          rowsMax={5}
          name="note"
          placeholder="Add notes"
          variant="filled"
        />
      </Box>

      {/*<FilePicker onChangeFiles={handleAttachments} onChangeAvailableFilesIDs={handleAvailableFilesIDs} />*/}
    </>
  );
};
