import { Box } from '@material-ui/core';
import { Select } from '../../../../../../../../../components/FormField';
import { UserInfo } from '../UserInfo';
import { relationshipMap } from '../relationshipMap';

export const Contact = () => {
  return (
    <Box my={2}>
      <Select
        formattedValue
        name="relationship"
        label="Relationship"
        options={relationshipMap}
        TextFieldProps={{ required: true, margin: 'dense' }}
      />

      <UserInfo />
    </Box>
  );
};
