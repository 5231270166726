import { transformImmunizationToOption } from '../../../../../components/FormField';

export const transformRelationsForFilterToOptions = (relationsForFilter) => {

  return {
    ...relationsForFilter,

    immunization_id: transformImmunizationToOption(relationsForFilter?.immunization_id)
  };
};
