import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import { Causer } from '../../call-reports/CallReports/Causer/Causer';
import { UserContact } from '../../call-reports/CallReports/UserContact';
import { callerTypesMap, parentTypesMap } from '../../call-reports/CopyToModal/parentTypeOptions';
import { activitySessionTypesMap } from '../activitiesHelpers';

export const SipCallReportActivity = ({
  isDeleted = false,
  fieldLabel,
  isUser = false,
  isCase = false,
  logName,
  payload
}) => {
  const callee = payload?.sip_call_report?.callee
    || payload?.sip_call_report?.toContact
    || payload?.sip_call_report?.defaultView;
  const caller = payload?.sip_call_report?.caller
    || payload?.sip_call_report?.fromContact
    || payload?.sip_call_report?.defaultView;
  const title = logName === activitySessionTypesMap.sipCallDetachParent ? 'Detach From' : 'Attach To';

  if (isDeleted) {
    return `Removed ${fieldLabel}`;
  }

  return (
    <>
      <Box fontSize={14} display="flex" alignItems="center" flexWrap="wrap">
        <Box display="flex" alignItems="center" mr={1} flexWrap="wrap">
          <Typography variant="subtitle1">From:&nbsp;</Typography>

          <UserContact
            disableTransform={
              payload?.sip_call_report?.caller_phone?.length <= 3
              || payload?.sip_call_report?.caller_phone === callerTypesMap.anonymous
            }
            user={caller}
            phone={payload?.sip_call_report?.caller_phone}
          />;
        </Box>

        <Box display="flex" alignItems="center" mr={1} flexWrap="wrap">
          <Typography variant="subtitle1">To:&nbsp;</Typography>

          <UserContact
            disableTransform={payload?.sip_call_report?.callee_phone?.length <= 3
            }
            user={callee}
            phone={payload?.sip_call_report?.callee_phone}
          />;
        </Box>

        <Box display="flex" alignItems="center" mr={1} flexWrap="wrap">
          <Typography variant="subtitle1">Started:&nbsp;</Typography>

          <Typography>
            {payload?.sip_call_report.started_at
              ? moment(payload?.sip_call_report.started_at).format('L LT')
              : '-'};
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mr={1} flexWrap="wrap">
          <Typography variant="subtitle1">Finished:&nbsp;</Typography>

          <Typography>
            {payload?.sip_call_report?.finished_at
              ? moment(payload?.sip_call_report?.finished_at).format('L LT')
              : '-'};
          </Typography>
        </Box>
      </Box>

      <Box fontSize={14} display="flex" alignItems="center" flexWrap="wrap" width="100%">
        {isUser && (
          <>
            <Typography variant="subtitle1">{title} User:&nbsp;</Typography>
            <Causer parent={payload?.properties?.parent} parentType={parentTypesMap.user} />
          </>
        )}

        {isCase && (
          <>
            <Typography variant="subtitle1">{title} Case:&nbsp;</Typography>
            <Causer parent={payload?.properties?.parent} parentType={parentTypesMap.case} />
          </>
        )}
      </Box>
    </>
  );
};
