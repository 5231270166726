import { Typography } from '@material-ui/core';

export const EventText = ({ before = null, after = null, fieldLabel }) => {
  if (!before?.id && after?.id) {
    return (
      <>
        Added&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  } else if (before?.id && !after?.id) {
    return (
      <>
        Removed&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  } else if (before?.id && after?.id && before?.id !== after?.id) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  } else {
    return (
      <>Updated&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>&nbsp;</>
    );
  }
};
