import { useContext } from 'react';
import moment from 'moment';
import { Box, makeStyles, Typography, SvgIcon } from '@material-ui/core';
import { mdiAttachment, mdiCommentMultipleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CalendarIcon from '@material-ui/icons/Event';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../helpers/dom';
import { generateAddress } from '../../../../../../utils/generateAddress';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { Tooltip, TooltipTitle } from '../../../../../../components/Tooltip';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Popper } from '../../../../../../components/Popper';
import { PreviewProceduresModal } from '../../PreviewProceduresModal';
import { ProcedureContext } from '../../ProcedureProvider';
import { ProceduresMenu } from '../../ProceduresMenu';
import {
  minRowHeight,
  columnsWidths,
  widthBreakpointSmall,
  widthBreakpointMedium,
  widthBreakpointLarge
} from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ width, procedure }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { selectedIDs, appointmentType, toggleItemSelection, fetchProcedures } = useContext(ProcedureContext);
  const isSelected = selectedIDs.indexOf(procedure.id) !== -1;
  const diagnosis = procedure?.icd?.map(({ code }, i) => `DX${i + 1}/${code}`).join(', ');

  const handleItemSelection = () => {
    toggleItemSelection(procedure.id);
  };

  const openPreview = () => {
    openModal(PreviewProceduresModal, {
      payload: {
        appointmentType,
        procedure,
        onUpdate: fetchProcedures
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <ListRow
      button
      minHeight={minRowHeight}
      onClick={stopPropagation(openPreview)}
    >
      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />
      }

      <ListRowCell grow column flexBasis={columnsWidths.name}>
        <Typography gutterBottom variant="h5">{procedure?.cpt_code?.procedure_code}</Typography>

        <Typography noWrap component="div" className={classes.content}>
          <Box display="flex" alignItems="center" fontSize={15}>
            <SvgIcon color="inherit"><Icon path={mdiAttachment} /></SvgIcon>

            <Box pl={0.5}>
              <Typography color="inherit">{procedure?.count_media || 0}</Typography>
            </Box>
          </Box>
          <VerticalDivider verticalGutters={0.25} horizontalGutters={1} />

          <Box display="flex" alignItems="center" color="info.main" fontSize={15}>
            <SvgIcon color="inherit" fontSize="inherit"><Icon path={mdiCommentMultipleOutline} /></SvgIcon>

            <Box pl={0.5}>
              <Typography color="inherit">{procedure?.count_comments || 0}</Typography>
            </Box>
          </Box>

          <VerticalDivider verticalGutters={0.25} horizontalGutters={1} />

          <Typography noWrap color="textSecondary" title={procedure?.cpt_code?.description}>
            {procedure?.cpt_code?.description}
          </Typography>
        </Typography>
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.diagnosis}>
        <Tooltip
          isExistTooltip={!!procedure?.icd?.length}
          title={procedure?.icd?.map((item) => (
            <TooltipTitle key={item.id} itemTitle={item.code}>
              {item.description &&
                <Typography>- {item.description}</Typography>
              }
            </TooltipTitle>
          ))}
        >
          <Typography noWrap>{diagnosis || '-'}</Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.date}>
        <Box mr={1}>
          <CalendarIcon fontSize="small" />
        </Box>

        <Typography noWrap title={procedure.from ? moment.unix(procedure.from).format('L, LT') : '-'}>
          {procedure.from ? moment.unix(procedure.from).format('L, LT') : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.date}>
        <Box mr={1}>
          <CalendarIcon fontSize="small" />
        </Box>

        <Typography noWrap title={procedure.to ? moment.unix(procedure.to).format('L, LT') : '-'}>
          {procedure.to ? moment.unix(procedure.to).format('L, LT') : '-'}
        </Typography>
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.provider}>
          <UserLink
            noWrap
            size="md"
            variant="inherit"
            user={procedure.provider}
            onClick={stopPropagation(openUserPreview(procedure?.provider?.id))}
          />
        </ListRowCell>
      }

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.office}>
          <Tooltip
            isExistTooltip={!!procedure?.office}
            title={procedure?.office?.full_address || generateAddress(procedure?.office)}
          >
            <OfficeLink office={procedure?.office} />
          </Tooltip>
        </ListRowCell>
      }

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <ProceduresMenu procedure={procedure} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
