import cn from 'classnames';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import { CurrencyFormat } from '../../../../../../../../../components/CurrencyFormat';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ invoice }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography gutterBottom color="textSecondary">
        P.O. # {invoice.po || '-'}
      </Typography>

      <div className={classes.mainContent}>
        <div className={classes.content}>
          <div className={classes.header}>
            <CurrencyFormat
              value={invoice.balance}
              color="textSecondary"
              TypographyProps={{
                className: cn(
                  classes.balanceTotal,
                  {
                    [classes.balanceTotal_red]: invoice.balance < 0
                  }
                )
              }}
            />

            <Typography color="textSecondary" className={classes.balanceDue}>
              Balance Due
            </Typography>
          </div>

          <Typography className={classes.total}>
            Total <CurrencyFormat value={invoice.total} TypographyProps={{ component: 'span' }} />
          </Typography>
        </div>

        <div className={classes.content}>
          <div className={classes.header}>
            <Typography className={cn(classes.balanceTotal, classes.dueDate)}>
              {invoice.due_date ? moment(invoice.due_date).format('DD MMM, YY') : '-'}
            </Typography>

            <Typography color="textSecondary" className={classes.balanceDue}>
              Due Date
            </Typography>
          </div>

          <Typography className={classes.total}>
            Paid <CurrencyFormat value={invoice.paid} TypographyProps={{ component: 'span' }} />
          </Typography>
        </div>
      </div>
    </div>
  );
};
