import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { TablePagination } from '../../../../../components/TablePagination';
import { PageBody } from '../../../../../components/Page/PageBody';
import { Loader } from '../../../../../components/Loader';
import { TestsContext } from '../TestsProvider';
import { Header } from './Header';
import { Row } from './Row';

export const widthBreakpointXS = 700;
export const widthBreakpointSmall = 800;
export const widthBreakpointMedium = 900;
export const widthBreakpointLarge = 1150;
export const minRowHeight = 61;
export const columnsWidths = {
  name: 200,
  value: 150,
  range: 150,
  date: 200,
  provider: 200,
  actions: 50
};

export const List = () => {
  const { isFetched, meta, tests, resetTestReports } = useContext(TestsContext);
  const ref = useRef();
  const { width } = useResizeObserver({ ref });

  useEffect(() => {
    resetTestReports();
  }, []);

  return (
    <Box ref={ref} height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => (!tests.length ? (
          <Box p={3}>
            <Typography align="center">No Tests found</Typography>
          </Box>
        ) : (
          <>
            <Header width={width} />

            <PageBody disablePaddings>
              <MuiList disablePadding>
                {tests.map((test) => (
                  <Row key={test.id} width={width} test={test} />
                ))}
              </MuiList>
            </PageBody>

            <TablePagination pagination={meta} onChange={resetTestReports} />
          </>
        ))}
      />
    </Box>
  );
};
