import { useEffect, useRef } from 'react';
import { makeStyles, Typography, Box, List } from '@material-ui/core';
import { useResizeObserver } from '../../../../../../helpers/hooks/useResizeObserver';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { IncomeContainer } from './IncomeContainer';
import { TableHeader } from './TableHeader';
import { Card } from './Card';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointMedium = 1200;

export const minRowHeight = 52;
export const columnsWidths = {
  name: 200,
  parent: 250,
  notes: 300,
  hours: 120,
  total: 120,
  rate: 100,
  action: 100
};

export const Incomes = ({
  parentPage,
  incomes,
  invoice,
  totalTime,
  setTotal,
  onIncomesUpdate = () => {},
  onUpdateMainTotal = () => {}
}) => {
  const classes = useStyles();
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const IncomeComponent = width < widthBreakpointMedium ? Card : Row;

  useEffect(() => {
    if (parentPage) {
      const totalIncomes = incomes.reduce((prev, next) => prev + +next.total, 0);
      const totalExpenses = invoice.expenses.reduce((prev, next) => prev + +next.amount, 0);

      setTotal(totalIncomes + totalExpenses);
    }
  }, [ incomes ]);

  return (
    <div ref={rootRef} className={classes.root}>
      <div className={classes.mainContent}>
        {!incomes.length ?
          <Box p={3}>
            <Typography align="center">Incomes not found</Typography>
          </Box>
          :
          <>
            {(width > widthBreakpointMedium) && <TableHeader width={width} incomes={incomes} />}

            <div className={classes.listContent}>
              <List disablePadding className={classes.list}>
                {incomes.map((item, index) => (
                  <IncomeContainer
                    key={index}
                    parentPage={parentPage}
                    income={item}
                    index={index}
                    width={width}
                    invoice={invoice}
                    incomes={incomes}
                    onIncomesUpdate={onIncomesUpdate}
                    onUpdateMainTotal={onUpdateMainTotal}
                    IncomeComponent={IncomeComponent}
                  />
                ))}
              </List>
            </div>

            <ListRow minHeight={minRowHeight} className={classes.totalRoot}>
              <ListRowCell flexBasis={columnsWidths.total}>
                <Typography className={classes.totalContent}>Total</Typography>
              </ListRowCell>

              <ListRowCell flexBasis={columnsWidths.rate}>
                <Typography className={classes.totalContent}>
                  <CurrencyFormat value={totalTime} />
                </Typography>
              </ListRowCell>

              <ListRowCell flexBasis={columnsWidths.action} />
            </ListRow>
          </>
        }
      </div>
    </div>
  );
};
