export const styles = ({ palette, spacing, breakpoints }) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      backgroundColor: palette.background.primary,
      padding: spacing(2, 3, 0),

      [breakpoints.down('md')]: {
        padding: spacing(0)
      }
    }
  };
};
