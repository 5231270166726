import { FilesCardWidget } from '../../../../files-common/FilesCardWidget';

export const Files = ({ payload: { user }, ...props }) => (
  <FilesCardWidget
    {...props}

    payload={{
      filter: { user_profile_id: user.profile_id },
      ownerType: 'profile',
      ownerID: user.profile_id
    }}
  />
);
