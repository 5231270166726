import { EmptyFormAppointment } from '../../../../components/medical/forms';
// import { ApptFormContent } from '../ApptFormContent';
import { GeneralInfo } from '../PersonalInjury/GeneralInfo';
import { PreviewContent } from '../PreviewContent';
import { PastMedicalHistory } from './PastMedicalHistory';
import { PatientHistory } from './PatientHistory';
import { ReviewSystems } from './ReviewSystems';
import { AccidentInfo } from './AccidentInfo';

export const formStepComponents = [
  GeneralInfo,
  EmptyFormAppointment,
  // ApptFormContent,
  PatientHistory,
  AccidentInfo,
  PastMedicalHistory,
  ReviewSystems,
  PreviewContent
];
