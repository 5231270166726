import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { makeStyles, Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { useCalendarEventsCount } from '../../../../../../utils/useCalendarEventsCount';
import { hasRole } from '../../../../../../utils/hasRole';
import * as appointmentsApi from '../../../../../../api/appointments';
import { Badge } from '../../../../../../components/Badge';
import { Divider } from '../../../../../../components/Divider';
import { IconButton } from '../../../../../../components/IconButton';
import { useModal } from '../../../../../../components/ModalsProvider';
import { FullCalendar, viewTypes } from '../../../../../../components/FullCalendar';
import {
  AppointmentsFilterContext,
  AppointmentsContext,
  AppointmentCreateModal,
  AppointmentViewModal,
  renderAppointmentContent,
  renderResourceLabel,
  scrollCalendarToFirstAppt,
  transformAppointmentToCalendarEvent,
  AppointmentsProvider,
  printAppointments
} from '../../../../../../components/appointments';
import { CalendarContext } from '../../CalendarProvider';
import { ArrivalTypesIndicators } from './ArrivalTypesIndicators';
import { DayView } from './DayView';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Calendar = ({ width, patient }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const calendarRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const [ currentViewType, setCurrentViewType ] = useState(viewTypes.dayGridMonth);
  const isDayGridMonth = currentViewType === viewTypes.dayGridMonth;
  const { relationsForFilter } = useContext(AppointmentsFilterContext);
  const { appointments, daysData, ...calendarContext } = useContext(CalendarContext);
  const {
    isFetched,
    filter: { appointment_from, appointment_to },
    selectedDate,
    setSelectedDate,
    reloadCalendar
  } = calendarContext;
  const classes = useStyles();
  const resources = useMemo(() => (relationsForFilter.appointment_books || []).map((book) => {
    return {
      id: book.id,
      title: book.appointment_book_name,
      extendedProps: {
        book
      }
    };
  }), [ relationsForFilter.appointment_books ]);

  useCalendarEventsCount({
    isDayGridMonth,
    daysData,
    calendarEl: document.querySelector('.' + classes.calendar)
  });

  const handlePrint = (appointments, date) => () => {
    printAppointments(appointments, date);
  };

  const handleDatesSet = ({ view: { activeStart, activeEnd, type } }) => {
    const datesRange = {
      appointment_from: moment(activeStart).unix(),
      appointment_to: moment(activeEnd).unix()
    };

    setCurrentViewType(type);

    if (!isEqual({ appointment_from, appointment_to }, datesRange)) {
      calendarContext.applyFilter(datesRange);
    }
  };

  const createAppointment = (initialValues) => {
    if (hasRole(rolesMap.client, rolesMap.patient)) {
      return;
    }

    openModal(AppointmentCreateModal, {
      payload: {
        initialValues: {
          patient,
          patient_id: patient,
          patient_appointment_details: { patient, ...patient },
          patient_insurance_id: patient?.medical?.main_insurance,

          ...initialValues
        }
      },

      onModalResolved: (appointment) => {
        if (appointment.recurring_rule) {
          reloadCalendar();
        } else {
          calendarContext.addAppointment(appointment);
        }
      }
    });
  };

  const handleDateClick = ({ date }) => {
    if (selectedDate.isSame(moment(date), 'day')) {
      createAppointment({
        appointment_at: moment(date).unix()
      });
    }
  };

  const handleDayDblClick = ({ date }) => {
    createAppointment({
      appointment_at: moment(date).unix()
    });
  };

  const handleDatesSelect = (info) => {
    createAppointment({
      appointment_at: moment(info.start).unix(),
      time: moment(info.start).diff(moment(info.end), 'minutes')
    });
  };

  const handleAppointmentUpdate = () => {
    reloadCalendar();
  };

  const handleAppointmentDelete = () => {
    reloadCalendar();
  };

  const handleAppointmentClick = ({ event }) => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: event.extendedProps?.appointment?.id,

        onAppointmentUpdate: () => {
          handleAppointmentUpdate();
        },

        onAppointmentDelete: () => {
          handleAppointmentDelete();
        }
      }
    });
  };

  const handleAppointmentMove = ({ event: { start, end, extendedProps: { appointment } }, revert, newResource }) => {
    const transformedAppointment = {
      id: appointment.id,
      appointment_at: moment(start).unix(),
      time: moment(end).diff(moment(start), 'minutes')
    };

    if (newResource) {
      const { book } = newResource.extendedProps;
      const availableTypes = book.appointment_types.filter((type) => type.is_current_active);
      const apptEndDate = appointment.appointment_at + moment.duration(appointment.time, 's').asSeconds();
      const apptStartTime = moment.unix(appointment.appointment_at).format(moment.HTML5_FMT.TIME_SECONDS);
      const apptEndTime = moment.unix(apptEndDate).format(moment.HTML5_FMT.TIME_SECONDS);
      const apptStartTimeMoment = moment(apptStartTime, moment.HTML5_FMT.TIME_SECONDS);
      const apptEndTimeMoment = moment(apptEndTime, moment.HTML5_FMT.TIME_SECONDS);
      const bookStartTimeMoment = moment(book.appointments_hours_start, moment.HTML5_FMT.TIME_SECONDS);
      const bookEndTimeMoment = moment(book.appointments_hours_finish, moment.HTML5_FMT.TIME_SECONDS);

      if (!book.provider) {
        enqueueSnackbar('Appt. don\'t moved - this book without provider', { variant: 'warning' });
        revert();

        return;
      }

      if (!availableTypes?.length) {
        enqueueSnackbar('Appt. don\'t moved - this book without available appt. types', { variant: 'warning' });
      }

      if (apptStartTimeMoment.isBefore(bookStartTimeMoment) || apptEndTimeMoment.isAfter(bookEndTimeMoment)) {
        enqueueSnackbar(
          'Recording time does not coincide with the working time of the book',
          { variant: 'warning' }
        );
      }

      transformedAppointment.appointment_book_id = book.id;
      transformedAppointment.office_id = book.office_id || appointment.office_id;
    }

    appointmentsApi.updateAppointment(transformedAppointment).then(() => {
      reloadCalendar();
    }).catch(revert);
  };

  useEffect(() => {
    calendarContext.applyFilter({
      count_per_day: 2,
      timezone: moment.tz.guess(true)
    });
  }, []);

  useEffect(() => {
    scrollCalendarToFirstAppt({ appointments, calendarRef });
  }, [ appointments ]);

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();

    if (calendarApi.view.type !== currentViewType) {
      setCurrentViewType(calendarApi.view.type);
    }

    if (isEmpty(relationsForFilter.appointment_books) && currentViewType === viewTypes.resourceTimeGridDay) {
      calendarApi.changeView(viewTypes.timeGridDay);
      setCurrentViewType(viewTypes.timeGridDay);
    } else if (!isEmpty(relationsForFilter.appointment_books) && currentViewType === viewTypes.timeGridDay) {
      calendarApi.changeView(viewTypes.resourceTimeGridDay);
      setCurrentViewType(viewTypes.resourceTimeGridDay);
    }
  }, [ currentViewType, relationsForFilter.appointment_books ]);

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.calendar}>
          <FullCalendar
            editable
            isLoading={!isFetched}
            selectedDate={selectedDate}
            onSelectedDateChange={setSelectedDate}
            eventDataTransform={transformAppointmentToCalendarEvent}
            resources={resources}
            events={appointments}
            selectMinDistance={2}
            select={handleDatesSelect}
            dateClick={handleDateClick}
            onDayDblClick={handleDayDblClick}
            eventClick={handleAppointmentClick}
            datesSet={handleDatesSet}
            eventDrop={handleAppointmentMove}
            eventResize={handleAppointmentMove}
            ref={calendarRef}
            eventContent={renderAppointmentContent}
            resourceLabelContent={renderResourceLabel}
          />
        </div>

        {!isMobile && isDayGridMonth &&
          <AppointmentsProvider>
            <AppointmentsContext.Consumer>
              {({ appointments, meta }) => (
                <div className={classes.dayView}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Box mr={2}>
                      <Typography variant="h3">
                        {selectedDate.format('ddd, DD MMMM')}
                      </Typography>
                    </Box>

                    <Badge showZero badgeContent={meta.total} color="info" title="Appointments count" />

                    <Box ml="auto">
                      <IconButton edge="end" onClick={handlePrint(appointments, selectedDate)}>
                        <PrintOutlinedIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box mt={1.75} mb={1.5}>
                    <Divider />
                  </Box>

                  <Box flexGrow={1}>
                    <DayView
                      selectedDate={selectedDate}
                      onAppointmentUpdate={handleAppointmentUpdate}
                      onAppointmentDelete={handleAppointmentDelete}
                    />
                  </Box>
                </div>
              )}
            </AppointmentsContext.Consumer>
          </AppointmentsProvider>
        }
      </div>

      {!isMobile && (
        <ArrivalTypesIndicators />
      )}
    </div>
  );
};
