import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Tooltip, useTheme } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import { useResizeObserver } from '../../../../../../helpers/hooks/useResizeObserver';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { hasRole } from '../../../../../../utils/hasRole';
import { Page, PageBody, PageHeader, PageHeaderActions } from '../../../../../../components/Page';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { IconButton } from '../../../../../../components/IconButton';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Button } from '../../../../../../components/Button';
import { MembersModal } from '../../MembersModal';
import { FiltersBar } from './FiltersBar';
import { Header } from './Header';
import { List } from './List';
import { Grid } from './Grid';

export const widthBreakpointXS = 900;
export const widthBreakpointLarge = 1300;

export const minRowHeight = 54;
export const columnsWidths = {
  name: 200,
  case_role: 176,
  mobile_phone_number: 120,
  email: 150,
  company: 176,
  office: 80,
  position: 150,
  action: 52
};

export const Contacts = ({
  caseItem,
  onCaseItemUpdate
}) => {
  const { openModal } = useModal();
  const [ filter, setFilter ] = useState([]);
  const rootRef = useRef();
  const theme = useTheme();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { width } = useResizeObserver({ ref: rootRef });
  const isTablet = width <= theme.breakpoints.values.tablet;
  const [ filteredUsers, setFilteredUsers ] = useState(caseItem.case_users);
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper
  } = useMobileFilterCollapse('tablet', width);

  const positions = useMemo(() => {
    const positions = caseItem.case_users.reduce((positions, { user }) => {
      const position = user?.work?.position;

      return position ? positions.concat(position) : positions;
    }, []);

    return [ ...new Set(positions) ];
  }, [ caseItem.case_users ]);

  const openMembersModal = () => {
    openModal(MembersModal, {
      payload: {
        caseItem,
        onCaseItemUpdate
      }
    });
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const getFilteredUsers = (users, filter) => {
    return users.filter(({ user, case_role }) => {
      return Object.keys(filter).every((filterKey) => {
        if (!filter[filterKey].length) {
          return true;
        }

        switch (filterKey) {
          case 'company':
            return filter[filterKey].includes(user?.work?.company?.id);

          case 'office':
            return user?.work?.offices?.some((office) => filter[filterKey].includes(office?.id));

          case 'position':
            return filter[filterKey].includes(user?.work?.position);

          case 'case_role':
            return filter[filterKey].includes(case_role);

          default:
            return true;
        }
      });
    });
  };

  useEffect(() => {
    setFilteredUsers(getFilteredUsers(caseItem.case_users, filter));
  }, [ caseItem.case_users, filter ]);

  useEffect(() => {
    setFilter(filter);
  }, [ filter ]);

  return (
    <Page ref={rootRef} component={Box} ml={isTablet ? 0 : 1} pb={1}>
      {!hasRole('client', 'patient') &&
        <PageHeader disableBorderBottom>
          <PageHeaderActions disableSpacing endPosition>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={openMembersModal}
            >
              Add members
            </Button>

            {isTablet &&
              <Box ml={1.5} py={0.25} display="flex" justifyContent="flex-end" alignItems="center">
                {filterOpenButton}
              </Box>
            }
          </PageHeaderActions>

          {!isTablet &&
            <>
              <Tooltip title="List view">
                <IconButton
                  color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                  onClick={toggleViewVariant(viewVariantsMap.list)}
                >
                  <ViewListIcon/>
                </IconButton>
              </Tooltip>

              <Tooltip title="Grid view">
                <IconButton
                  color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                  onClick={toggleViewVariant(viewVariantsMap.grid)}
                >
                  <ViewModuleIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        </PageHeader>
      }

      <PageBody fullHeight disablePaddings disableScrollbars>
        <Box height="100%" display="flex" flexDirection="column">
          <ScrollWrapper {...scrollWrapperProps}>
            <FilterBarWrapper {...filterBarWrapperProps}>
              <FiltersBar
                caseUsers={caseItem.case_users}
                handleSetFilter={setFilter}
              />
            </FilterBarWrapper>
          </ScrollWrapper>

          {!isTablet && !!caseItem.case_users.length && (viewVariant === viewVariantsMap.list) &&
            <Header
              width={width}
              filter={filter}
              onFilterChange={setFilter}
              positions={positions}
            />
          }

          <Box flexGrow={1}>
            <PageBody disablePaddings fullHeight>
              {isTablet || viewVariant === viewVariantsMap.grid ? (
                <Grid
                  caseItem={caseItem}
                  filteredUsers={filteredUsers}
                  onCaseItemUpdate={onCaseItemUpdate}
                />
              ) : viewVariant === viewVariantsMap.list ? (
                <List
                  caseItem={caseItem}
                  filteredUsers={filteredUsers}
                  onCaseItemUpdate={onCaseItemUpdate}
                  width={width}
                />
              ) : null
              }
            </PageBody>
          </Box>
        </Box>
      </PageBody>
    </Page>
  );
};
