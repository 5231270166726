import { useContext } from 'react';
import { get } from 'lodash';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Typography } from '@material-ui/core';
import { preventDefault } from '../../../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { Contact } from '../../../../../../../../components/Contact';
import { IconButton } from '../../../../../../../../components/IconButton';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { Popper } from '../../../../../../../../components/Popper';
import { CodesContext } from '../../../CodesContext';
import { PayerMenu } from './PayerMenu';
import { columnsWidths, minRowHeight } from '../List';

export const Row = ({ baseUrl, code }) => {
  const { selectedCodesIDs, toggleItemSelection, massActivePayer, massInactivePayer } = useContext(CodesContext);
  const isSelected = selectedCodesIDs.indexOf(code.id) !== -1;

  const toggleActiveItem = () => {
    code.deactivated_at ? massActivePayer([ code.id ]) : massInactivePayer([ code.id ]);
  };

  const handleItemSelection = () => {
    toggleItemSelection(code.id);
  };

  return (
    <ListRow minHeight={minRowHeight} selected={isSelected}>
      <ListRowCheckbox checked={isSelected} onClick={preventDefault(handleItemSelection)} />

      <ListRowCell noWrap flexBasis={columnsWidths.code}>
        <Typography noWrap title={code.code || '-'}>
          {code.code || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.name}>
        <CompanyLink noWrap company={code?.company} path={itemsRoutesByModels.company(code?.company?.id)} />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.address}>
        <Typography noWrap title={code.address_1 || '-'}>
          {code.address_1 || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.state}>
        <Typography noWrap title={code.state || '-'}>
          {code.state || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.city}>
        <Typography noWrap title={code.city || '-'}>
          {code.city || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.phone}>
        <Contact type="tel" title={code.main_phone || '-'} contact={code.main_phone} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.class}>
        <Typography noWrap title={get(code, 'financial_class.code', '-')}>
          {get(code, 'financial_class.code', '-')}
        </Typography>
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.edi}>
        <Typography noWrap title={code.professional_edi || '-'}>
          {code.professional_edi || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <Popper
          placement="left-start"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <IconButton buttonRef={anchorRef} onClick={preventDefault(handleToggle)}>
              <MoreVertIcon />
            </IconButton>
          )}
        >
          {({ handleClose }) => (
            <PayerMenu code={code} baseUrl={baseUrl} onClose={handleClose} onChange={toggleActiveItem} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
