import { Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { IconButton } from '../../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../../components/UserLink';
import { roleLabelsMap, UserAvatar } from '../../../../../../../../components/users';

export const UsersList = ({ users, onUserDelete }) => {
  const { openModal } = useModal();

  const handleUserDelete = (user) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        onUserDelete(user);
      }
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      {users.map((user) => {
        return (
          <Box mb={1} display="flex" alignItems="center" key={user.id}>
            <UserAvatar user={user} />

            <Box mx={1} flexGrow={1}>
              <Box clone lineHeight="1 !important">
                <UserLink noAvatar user={user} />
              </Box>

              <Typography variant="body2" color="textSecondary">
                {roleLabelsMap[user.role]}
              </Typography>
            </Box>

            <IconButton
              edge="end"
              color="error"
              title="Remove access"
              onClick={handleUserDelete(user)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};
