import { useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import { PageHeaderActions, PageHeaderTitle } from '../../../../components/Page';
import { useModal } from '../../../../components/ModalsProvider';
import { TaskTemplatesGroupsContext } from './TaskTemplatesGroupsProvider';
import { CreateGroupModal } from './CreateGroupModal';

export const Header = () => {
  const { openModal } = useModal();
  const { addGroup } = useContext(TaskTemplatesGroupsContext);

  const createGroup = () => {
    openModal(CreateGroupModal, {
      onModalResolved: (group) => {
        addGroup(group);
      }
    });
  };

  return (
    <>
      <PageHeaderTitle>Task Templates Groups</PageHeaderTitle>

      <PageHeaderActions>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={createGroup}
        >
          Add group
        </Button>
      </PageHeaderActions>
    </>
  );
};
