import { useContext } from 'react';
import ReactPlayer from 'react-player';
import { Box } from '@material-ui/core';
import { FilePreviewContext } from '../../../FilePreviewContext';
import { PreviewContent } from '../PreviewContent';

export const VideoPlayer = () => {
  const { selectedFile } = useContext(FilePreviewContext);

  return (
    <PreviewContent fullSize>
      <Box clone maxWidth="100%" height="auto !important">
        <ReactPlayer controls url={selectedFile?.url} />
      </Box>
    </PreviewContent>
  );
};
