import * as templatesApi from '../../../../api/profile';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';

export const transformTemplatesToOption = (item) => item && ({
  value: item.id,
  label: item.name,
  data: item
});

const fetchTemplates = (params) => fetchDataForAsyncSelect((name, { page }) => {
  return templatesApi.fetchTemplates({ params: { name, page, ...params } });
}, {
  transformDataToOption: transformTemplatesToOption
});

export const TemplatesSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      name="template_id"
      label="Select template"
      placeholder="Search templates..."
      promiseWithOptions={fetchTemplates(params)}
      {...props}
    />
  );
};
