import { api } from '../../api';

export const createFeeSchedule = (data) => {
  return api.post('/procedure-codes/fee-schedule', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const getFeeSchedules = (config) => {
  return api.get('/procedure-codes/fee-schedule/', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchFeeSchedule = (id) => {
  return api.get(`/procedure-codes/fee-schedule/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateFeeSchedule = ({ id, ...data }) => {
  return api.put(`/procedure-codes/fee-schedule/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteFeeSchedule = (id) => {
  return api.delete(`/procedure-codes/fee-schedule/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteFeeSchedules = (IDs) => {
  return api.delete('/procedure-codes/fee-schedule', { params: { id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massActiveFeeSchedules = (IDs) => {
  return api.post('/procedure-codes/fee-schedule/active', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massInactiveFeeSchedules = (IDs) => {
  return api.post('/procedure-codes/fee-schedule/inactive', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};