import { createContext, useRef, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../api';
import * as eventTypesApi from '../../../../../api/schedule-events/schedule-event-types';
import { useModal } from '../../../../../components/ModalsProvider';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const ScheduleTypesContext = createContext();

export const ScheduleTypesProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    eventTypes,
    selectedCodesIDs,
    selectedCodes
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchEventTypes = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_EVENT_TYPES_REQUEST });

    eventTypesApi.fetchEventTypes({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_EVENT_TYPES_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchEventTypes({ page: filter.page + 1 });
    }
  };

  const resetEventTypes = (newFilter) => {
    dispatch({ type: types.RESET_EVENT_TYPES, payload: newFilter });

    fetchEventTypes({ page: 1, ...newFilter });
  };

  const updateActiveEventType = (eventType) => {
    dispatch({ type: types.UPDATE_EVENT_TYPE, payload: eventType });
  };

  const updateActiveEventTypeReq = (eventType) => {
    return eventTypesApi.updateEventType(eventType).then((eventType) => {
      updateActiveEventType(eventType);
    });
  };

  const deleteEventType = (eventType) => {
    return new Promise((resolve) => {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          eventTypesApi.deleteEventType(eventType.id).then(() => {
            dispatch({ type: types.DELETE_EVENT_TYPE, payload: eventType.id });
            enqueueSnackbar(`Appt. type "${eventType.name}" successfully deleted`, { variant: 'success' });
            resolve();
          });
        },

        onModalRejected: resolve
      });
    });
  };

  const providerValue = {
    isFetched,
    isFetching,
    eventTypes,
    selectedCodesIDs,
    selectedCodes,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetEventTypes,
    fetchEventTypes,
    loadNextPage,
    updateActiveEventType,
    updateActiveEventTypeReq,
    deleteEventType
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ScheduleTypesContext.Provider value={providerValue}>
      {children}
    </ScheduleTypesContext.Provider>
  );
};
