import { Typography } from '@material-ui/core';
import { Label } from '../../Label';
import { allClaimsStatuses } from '../../claims';

export const Status = ({ before, after }) => {
  const oldStatus = allClaimsStatuses.find(({ value }) => value === before)?.label;
  const newStatus = allClaimsStatuses.find(({ value }) => value === after)?.label;

  if (!before && after) {
    return (
      <>
        <>
          Set
          &nbsp;
          <Label label={newStatus} />
          &nbsp;
          <Typography variant="subtitle1">status</Typography>
        </>
      </>
    );
  } else {
    return (
      <>
        Changed <Typography variant="subtitle1">status</Typography>
        &nbsp;from&nbsp;
        <Label label={oldStatus} />
        &nbsp;to&nbsp;
        <Label label={newStatus} />
      </>
    );
  }
};
