import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_TIME_REPORTS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_TIME_REPORTS_SUCCESS]: (
    { filter, timeReports, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      timeReports: page > 1
        ? timeReports.filter(({ id }) => !data.find((report) => id === report.id)).concat(data)
        : data
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_TIME_REPORTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_TIME_REPORT]: (state, timeReport) => {
    return {
      ...state,

      timeReports: state.timeReports.map((item) => {
        return item.id === timeReport.id ? { ...item, ...timeReport } : item;
      })
    };
  },

  [types.DELETE_TIME_REPORT]: (state, timeReportID) => {
    const { pagination, filter, timeReports, selectedTimeReportsIDs } = state;
    const filteredTimeReports = timeReports.filter(({ id }) => timeReportID !== id);

    if (filteredTimeReports.length === timeReports.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((timeReports.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      timeReports: filteredTimeReports,
      selectedTimeReportsIDs: selectedTimeReportsIDs.filter((id) => timeReportID !== id)
    };
  },

  [types.ADD_TIME_REPORTS_TO_SELECTED]: ({
    selectedTimeReport,
    selectedTimeReportsIDs,

    ...state
  }, timeReports) => {
    return {
      ...state,

      selectedTimeReport: selectedTimeReport.concat(
        timeReports.filter(({ id }) => !selectedTimeReportsIDs.includes(id))
      ),
      selectedTimeReportsIDs: [ ...new Set(selectedTimeReportsIDs.concat(timeReports.map(({ id }) => id))) ]
    };
  },

  [types.DELETE_TIME_REPORTS_FROM_SELECTED]: ({ selectedTimeReportsIDs, ...state }, timeReportsIDs) => {
    return {
      ...state,

      selectedTimeReportsIDs: selectedTimeReportsIDs.filter((id) => timeReportsIDs.indexOf(id) === -1)
    };
  }
});
