export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexBasis: '100%',
    padding: spacing(1)
  },

  rightAlign: {
    justifyContent: 'flex-end'
  },

  author: {
    padding: spacing(0, 1)
  },

  time: {
    display: 'flex'
  },

  edited: {
    marginRight: spacing(1)
  }
});
