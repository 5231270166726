import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutlineOutlined';
import { apiModelsMap } from '../../../../dataMaps/apiModels';
import { getUserFullName } from '../../../../helpers/users';

export const modelTypesMap = {
  [apiModelsMap.user]: {
    title: 'Personal',
    icon: AccountCircleIcon,
    getName: getUserFullName
  },
  [apiModelsMap.case]: {
    title: 'Case',
    icon: WorkOutlineIcon,
    getName: (caseItem) => caseItem.name
  },
  [apiModelsMap.task]: {
    title: 'Task',
    icon: PlaylistAddCheckIcon,
    getName: (task) => task.name
  }
};
