import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Box, Button, Typography } from '@material-ui/core';
import * as profileApi from '../../../api/profile';
import * as userApi from '../../../api/users';
import { getFormData } from '../../../app/Dashboard/ProfilePage/insurance';
import { ImageDropZone } from '../../ImageDropZone';
import { ModalFooter } from '../../Modal';
import { Loader } from '../../Loader';

export const DriverLicensesForm = ({
  user = {},
  front,
  back,
  onUpdate = () => {},
  onClose = () => {}
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(({ profile }) => profile.user);
  const uploadDriverLicense = (formData) => {
    return currentUser?.id === user?.id
      ? profileApi.uploadDriverLicense(formData)
      : userApi.uploadDriverLicense(user.id, formData);
  };

  const deleteDriverLicense = (tags) => {
    return currentUser?.id === user?.id
      ? profileApi.deleteDriverLicense(tags)
      : userApi.deleteDriverLicense({ id: user.id, tags });
  };

  const updateLicense = ({ values, formData, setErrors }) => {
    return uploadDriverLicense(formData).then(() => {
      onUpdate(values);
      enqueueSnackbar('Successfully updated', { variant: 'success' });
    }).catch(({ data: { errors } }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Not updated', { variant: 'error' });
    });
  };

  const onLicensesUpdate = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const front = typeof values?.front !== 'string' ? values?.front : '';
    const back = typeof values?.back !== 'string' ? values?.back : '';
    const formData = getFormData({ front, back });
    const tags = [];

    if (values?.driver_license?.front && !values?.front) {
      tags?.push('driver_license_front');
    }

    if (values?.driver_license?.back && !values?.back) {
      tags?.push('driver_license_back');
    }

    values.tags = tags;

    if (values?.tags?.length) {
      return deleteDriverLicense(values?.tags).then(() => {
        if (front || back) {
          return updateLicense({ values, formData, setErrors });
        } else {
          return onUpdate(values);
        }
      });
    } else {
      return updateLicense({ values, formData, setErrors });
    }
  };

  return (
    <Formik
      initialValues={{
        driver_license: {
          back: user?.driver_license?.back?.url || null,
          front: user?.driver_license?.front?.url || null
        },
        back: back || null,
        front: front || null,
        croppedFrontImageUrl: user?.driver_license?.front?.url || null,
        croppedBackImageUrl: user?.driver_license?.back?.url || null
      }}
      onSubmit={onLicensesUpdate}
    >
      {({ values, dirty, isSubmitting, handleSubmit }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Box p={2} pb={3}>
            <Box clone pb={1}>
              <Typography variant="h3">Update Scan Driver License</Typography>
            </Box>

            <Box clone pb={1}>
              <Typography color="primary">
                Please upload one of these images file types: PNG or JPG.
                The maximum size is 15 MB.
              </Typography>
            </Box>

            <Box clone pb={1}>
              <Typography variant="h5">
                Note: Blurring or obscuring portions of the image will prevent us from verifying the validity of your
                ID.
              </Typography>
            </Box>

            <ImageDropZone
              label="Front"
              name="front"
              croppedName="croppedFrontImageUrl"
              preview={values?.front}
            />

            <ImageDropZone
              label="Back"
              name="back"
              croppedName="croppedBackImageUrl"
              preview={values?.back}
            />
          </Box>

          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
              )}
            />
          </ModalFooter>
        </Box>
      )}
    </Formik>
  );
};
