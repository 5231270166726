import { useContext, useMemo } from 'react';
import cn from 'classnames';
import { MentionsInput, Mention } from 'react-mentions';
import { makeStyles, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { getUserFullName } from '../../../../helpers/users';
import { Scrollbars } from '../../../Scrollbars';
import { UserAvatar } from '../../../users';
import { ChatThreadContext } from '../../ChatThreadContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const displayTransform = (id, display) => {
  return `@${display}`;
};

export const InputComponent = ({ mentionSuggestionsPortalHostRef, ...props }) => {
  const classes = useStyles();
  const { currentThread } = useContext(ChatThreadContext);
  const users = useMemo(() => currentThread?.users?.map((user) => ({
    id: user.id,
    display: getUserFullName(user),
    data: user
  })), [ currentThread?.users ]);

  const renderSuggestion = ({ data: user }, search, highlightedDisplay, index, focused) => {
    return (
      <ListItem button component="div" selected={focused}>
        <ListItemAvatar>
          <UserAvatar user={user} />
        </ListItemAvatar>

        <ListItemText primary={getUserFullName(user)} secondary={user.role} />
      </ListItem>
    );
  };

  return (
    <Scrollbars invisible autoHeight autoHeightMax={200}>
      <MentionsInput
        {...props}

        allowSuggestionsAboveCursor
        allowSpaceInQuery
        suggestionsPortalHost={mentionSuggestionsPortalHostRef?.current}
        className={cn(classes.mentionsInput, props.className)}
      >
        <Mention
          className={classes.mention}
          trigger="@"
          data={users}
          markup="@[__display__](__id__)"
          displayTransform={displayTransform}
          renderSuggestion={renderSuggestion}
        />
      </MentionsInput>
    </Scrollbars>
  );
};
