export const styles = ({ spacing, typography: { pxToRem, fontWeightMedium } }) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: spacing(2)
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '80%'
  },

  userContent: {
    textTransform: 'uppercase',
    fontSize: pxToRem(12)
  },

  noteContent: {
    margin: spacing(2, 0),
    maxWidth: '80%'
  },

  noteIcon: {
    display: 'flex',
    alignItems:'center',
    fontWeight: fontWeightMedium
  },

  userIcon: {
    marginRight: spacing(),
    marginLeft: spacing()
  }
});
