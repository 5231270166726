import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { EventsList, ScheduleEventRow, ScheduleEventsList } from '../../schedule-events';
import { columnsMap } from '../../schedule-events/ScheduleEventsList/columns';

const hiddenColumns = [
  columnsMap?.type
];

export const ScheduleEventsWidget = ({ isMobile, period, height = '100%', applyFilter }) => {
  useEffect(() => {
    applyFilter({ newPeriod: period });
  }, []);

  return (
    <Box display="flex" flexDirection="column" height={height} overflow="hidden">
      <Box flexGrow={1}>
        <Box display="flex" flexDirection="column" height="100%" p={1}>
          {isMobile ? <EventsList /> : (
            <ScheduleEventsList
              hiddenColumns={hiddenColumns}
              Row={ScheduleEventRow}
              RowProps={{ viewOnClick: true }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
