import { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import { AppBar as MuiAppBar, Box, Chip, Tab, Tabs } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { DatePicker } from '@material-ui/pickers';
import * as usersApi from '../../../../../api/users';
import {
  AppointmentsProvider,
  AppointmentsPreviewsList,
  AppointmentCreateModal,
  AppointmentsContext,
  AppointmentsFilterContext
} from '../../../../../components/appointments';
import { Avatar } from '../../../../../components/Avatar';
import { Divider } from '../../../../../components/Divider';
import { orderDirectionsMap } from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import { useModal } from '../../../../../components/ModalsProvider';
import { getUserFullName, getUserNameAbbr } from '../../../../../helpers/users';
import { stopPropagation } from '../../../../../helpers/dom';

const periodsMap = {
  upcoming: orderDirectionsMap.asc,
  past: orderDirectionsMap.desc
};

export const Appointments = () => {
  const appointmentsContext = useContext(AppointmentsContext);
  const {
    relationsForFilter: { patients },
    changeFilter,
    setRelationsForFilter
  } = useContext(AppointmentsFilterContext);
  const { openModal } = useModal();
  const [ period, setPeriod ] = useState(periodsMap.upcoming);
  const [ patientIsFetching, setPatientIsFetching ] = useState(false);
  const [ selectedDate, setSelectedDate ] = useState(moment());

  const applyFilter = ({ newPeriod, date } = {}) => {
    const fromDate = date ? date.startOf('day').unix() : null;
    const toDate = date ? date.endOf('day').unix() : null;

    changeFilter({
      order_by: 'appointment_from',
      order_direction: newPeriod || period,
      appointment_from: newPeriod === periodsMap.upcoming ? moment().unix() : fromDate,
      appointment_to: newPeriod === periodsMap.past ? moment().unix() : toDate
    });
  };

  const handlePeriodChange = (event, newPeriod) => {
    if (period !== newPeriod) {
      setPeriod(newPeriod);
      applyFilter({ newPeriod });
    }
  };

  const createAppointment = (initialValues) => {
    openModal(AppointmentCreateModal, {
      payload: {
        initialValues
      },

      onModalResolved: () => {
        appointmentsContext.reloadAppointments();
      }
    });
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    applyFilter({ date });
  };

  const handleDateDoubleClick = (date) => () => {
    if (patients?.length) {
      setPatientIsFetching(true);

      usersApi.fetchUser(patients[0].id).then((user) => {
        setPatientIsFetching(false);

        createAppointment({
          appointment_at: date.unix(),
          patient_id: user,
          patient: user,
          patient_appointment_details: user,
          patient_insurance_id: user?.medical?.main_insurance
        });
      }).catch(() => {
        setPatientIsFetching(false);
      });
    } else {
      createAppointment({
        appointment_at: date.unix()
      });
    }
  };

  const renderCalendarDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => (
    <div onDoubleClick={stopPropagation(handleDateDoubleClick(day))}>
      {dayComponent}
    </div>
  );

  const handleDayFilterClear = () => {
    setSelectedDate(null);
    applyFilter({ newPeriod: periodsMap.upcoming });
  };

  const handlePatientFilterClear = () => {
    changeFilter({ patients: null });
    setRelationsForFilter({ patients: null });
  };

  useEffect(() => {
    applyFilter({ date: moment() });
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box>
        <Loader surface loading={patientIsFetching} render={
          () => (
            <DatePicker
              autoOk
              disableToolbar
              variant="static"
              value={selectedDate}
              onChange={handleDateClick}
              renderDay={renderCalendarDay}
            />
          )}
        />
      </Box>

      <Divider gutter={2} />

      {(!!selectedDate || !!patients?.length) &&
        <>
          <Box display="flex" justifyContent="center" alignItems="center" my={-1}>
            {!!selectedDate &&
              <Box p={0.5}>
                <Chip
                  label={selectedDate.format('ddd, DD MMMM')}
                  icon={<InsertInvitationOutlinedIcon />}
                  onDelete={handleDayFilterClear}
                  deleteIcon={<CancelIcon />}
                />
              </Box>
            }

            {!!patients?.length &&
              <Box p={0.5}>
                <Chip
                  label={getUserFullName(patients[0])}
                  avatar={<Avatar alt={getUserNameAbbr(patients[0])} src={patients[0]?.avatar} />}
                  deleteIcon={<CancelIcon />}
                  onDelete={handlePatientFilterClear}
                />
              </Box>
            }
          </Box>

          <Divider gutter={2} />
        </>
      }

      {!selectedDate &&
        <Box mb={1}>
          <MuiAppBar position="static">
            <Tabs
              value={period}
              onChange={handlePeriodChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab value={periodsMap.upcoming} label="Upcoming" />
              <Tab value={periodsMap.past} label="Past" />
            </Tabs>
          </MuiAppBar>
        </Box>
      }

      <Box flexGrow={1}>
        <AppointmentsProvider makeFetchOnMount>
          <AppointmentsPreviewsList hidePatient={patients?.length} />
        </AppointmentsProvider>
      </Box>
    </Box>
  );
};
