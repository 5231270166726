import { useContext, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import { api } from '../../../../../api';
import * as statisticsApi from '../../../../../api/statistics';
import { usePrevious } from '../../../../../helpers/hooks';
import { Loader } from '../../../../../components/Loader';
import { StatisticsFilterContext } from '../StatisticsFilterContext';
import { FiltersBar } from '../../FiltersBar';
import { Chart } from './Chart';
import { MainFilter } from './MainFilter';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = ({ isOpen }) => {
  const classes = useStyles();
  const [ statistics, setStatistics ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const { filter, setFilter } = useContext(StatisticsFilterContext);
  const prevFilter = usePrevious(filter);
  const [ isOpenDayPicker, setIsOpenDayPicker ] = useState(false);
  const [ isOpenWeekPicker, setIsOpenWeekPicker ] = useState(false);
  const [ isOpenMonthPicker, setIsOpenMonthPicker ] = useState(false);
  const [ isOpenYearPicker, setIsOpenYearPicker ] = useState(false);
  const [ message, setMessage ] = useState(moment.unix(filter.from).format('MM/YYYY'));
  const cancelFetch = useRef(() => {});

  const fetchStatistics = () => {
    cancelFetch.current();
    setIsFetched(false);

    statisticsApi.fetchTasksStatistics({
      params: filter,
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then((data) => {
      setStatistics(data);
      setIsFetched(true);
    });
  };

  useEffect(() => {
    fetchStatistics();

    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    if (!isEqual(prevFilter, filter)) {
      fetchStatistics();
    }
  }, [ prevFilter, filter ]);

  return (
    <div className={classes.root}>
      <MainFilter setFilter={setFilter}/>

      <FiltersBar
        message={message}
        isOpenDayPicker={isOpenDayPicker}
        isOpenWeekPicker={isOpenWeekPicker}
        isOpenMonthPicker={isOpenMonthPicker}
        isOpenYearPicker={isOpenYearPicker}
        filter={filter}
        onChange={setFilter}
        setIsOpenYearPicker={setIsOpenYearPicker}
        setIsOpenMonthPicker={setIsOpenMonthPicker}
        setIsOpenWeekPicker={setIsOpenWeekPicker}
        setIsOpenDayPicker={setIsOpenDayPicker}
        setMessage={setMessage}
      />

      <Loader loading={!isFetched} className={classes.loader} render={
        () => (
          <div className={classes.mainContent}>
            <Typography variant="h2">Total: {statistics.total_tasks}</Typography>

            <div className={classes.chart}>
              <Chart
                data={statistics.data}
                labels={statistics.labels}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};
