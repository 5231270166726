import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { ContactsProvider } from './ContactsProvider';
import { MainContent } from './MainContent';

export const Users = ({ filterKey }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <Box display="flex" flexDirection="column" py={isMobile ? 0 : 2} px={isMobile ? 0 : 3} height="100%">
      <ContactsProvider>
        <MainContent filterKey={filterKey} />
      </ContactsProvider>
    </Box>
  );
};
