import { useEffect, useState } from 'react';
import {
  Dialog,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import * as insuranceApi from '../../../../../api/profile/insurances';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderActions
} from '../../../../../components/Modal';
import { useModal } from '../../../../../components/ModalsProvider';
import { Loader } from '../../../../../components/Loader';
import { EditInsuranceInfoModal } from '../EditInsuranceInfoModal';
import { ViewInsuranceBody } from './ViewInsuranceBody';

export const ViewInsuranceInfoModal = ({
  payload: {
    user = {},
    insuranceId,
    insurance: initialInsurance = {},
    onEdit = () => {},
    onDelete = () => {},
    onCaseItemUpdate = () => {}
  },
  DialogProps,
  handleModalReject
}) => {
  const [ insurance, setInsurance ] = useState(null);
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasRoleForEdit = hasRole(
    rolesMap.client,
    rolesMap.patient,
    rolesMap.doctor,
    rolesMap.medic,
    rolesMap.supervisor,
    rolesMap.admin
  );

  const handleEdit = () => {
    openModal(EditInsuranceInfoModal, {
      payload: {
        user,
        insurance: { ...initialInsurance, ...insurance },
        onEdit,
        onDelete,
        onCaseItemUpdate
      },
      onModalResolved: (editedInsurance) => {
        setInsurance(editedInsurance);
      }
    });
  };

  const handleDelete = () => {
    onDelete(insurance.id, handleModalReject);
  };

  useEffect(() => {
    insuranceApi.fetchInsurance(initialInsurance?.id || insuranceId).then(setInsurance).catch(handleModalReject);
  }, []);

  return (
    <Dialog fullScreen={isMobile} PaperProps={{ style: { maxWidth: !isMobile ? 690 : '100%' } }} {...DialogProps}>
      <ModalContainer>
        <Loader p={3} loading={!insurance} render={
          () => (
            <>
              <ModalHeader onClose={handleModalReject}>
                Insurance Card Details

                <ModalHeaderActions disableGutters>
                  {!insurance?.isExpired && hasRoleForEdit && !insurance?.is_used &&
                    <Tooltip title="Edit">
                      <IconButton onClick={handleEdit}>
                        <EditIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  }

                  {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.medic) && !insurance?.is_used && (
                    <Tooltip title="Delete">
                      <IconButton onClick={handleDelete}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  )}
                </ModalHeaderActions>
              </ModalHeader>

              <ModalBody>
                <ViewInsuranceBody insurance={insurance} />
              </ModalBody>
            </>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
