import { api } from '../../api';

export const fetchMedicalForms = (config) => {
  return api.get('/profile/medical-forms', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchUnconfirmedMedicalForms = (config) => {
  return api.get('/profile/medical-forms/not-confirmed-list', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchMedicalForm = (id, config) => {
  return api.get(`/profile/medical-forms/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createMedicalForm = (data) => {
  return api.post('/profile/medical-forms', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const createFollowUpMedicalForm = (data) => {
  return api.post('/profile/medical-forms/follow-up', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const approveMedicalForm = (id, data) => {
  return api.post(`/profile/medical-forms/${id}/approve`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const searchUsersForMedicalForm = (id) => {
  return api.get(`/profile/medical-forms/${id}/search-users`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMedicalForm = ({ id, ...data }) => {
  return api.put(`/profile/medical-forms/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateFollowUpMedicalForm = ({ id, ...data }) => {
  return api.put(`/profile/medical-forms/follow-up/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const createCovidMedicalForm = (data) => {
  return api.post('/profile/medical-forms/covid', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateCovidMedicalForm = ({ id, ...data }) => {
  return api.put(`/profile/medical-forms/covid/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteMedicalForm = (id) => {
  return api.delete(`/profile/medical-forms/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const getBodyParts = (config) => {
  return api.get('/profile/medical-forms/body-parts', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const getFormSymptoms = () => {
  return api.get('/profile/medical-forms/get-symptoms')
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateCurrentSymptoms = (data) => {
  return api.post('/profile/medical-forms/current-symptoms', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateCurrentSymptomsPI = (data) => {
  return api.post('/profile/medical-forms/current-symptoms-pi', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
export const updatePatientHistory = (data) => {
  return api.post('/profile/medical-forms/patient-history', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updatePainContent = (data) => {
  return api.post('/profile/medical-forms/pain-in', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updatePastMedicalHistoryContent = (data) => {
  return api.post('/profile/medical-forms/past-medical-history', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMedicalHistory = (data) => {
  return api.post('/profile/medical-forms/medical-history', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateReviewOfSystems = (data) => {
  return api.post('/profile/medical-forms/review-of-orthopedic-symptoms', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateReviewOfSymptoms = (data) => {
  return api.post('/profile/medical-forms/review-of-symptoms', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateAccidentInformation = (data) => {
  return api.post('/profile/medical-forms/accident-information', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateAccidentInformationOrtho = (data) => {
  return api.post('/profile/medical-forms/accident-information-ortho', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteForms = (IDs) => {
  return api.delete('/profile/medical-forms/', { params: { form_ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createMedicalAppt = ({ id, ...data }) => {
  return api.post(`/profile/medical-forms/${id}/appointment`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const downloadPdf = (id) => {
  return api.get(`/profile/medical-forms/pdf/${id}`, { responseType: 'blob' })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const savePdfToProfileFiles = (id) => {
  return api.get(`/profile/medical-forms/save-pdf/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchMedicalFormsTypes = (config) => {
  return api.get('/medical-forms/types', config)
    .then((data) => data)
    .catch((error) => { throw error.data; });
};
