import { objectKeysToMap } from '../../../helpers/objectKeysToMap';

export const columnsWidths = {
  date: 130,
  duration: 160,
  type: 150,
  description: 190,
  office: 170,
  participants: 215,
  action: 54
};

export const columnsMap = objectKeysToMap(columnsWidths);
