import { useContext, useEffect, useRef } from 'react';
import { Box, Paper, Tab, Tabs, useTheme } from '@material-ui/core';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { getActiveRoute, Route } from '../../../components/router';
import { useResizeObserver } from '../../../helpers/hooks';
import { filtersKeysMap } from '../../../store/lastFilters';
import { LayoutContext, viewVariantsMap } from '../../../components/LayoutContext';
import { Page } from '../../../components/Page';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { ActionsBar } from './ActionsBar';
import { FaxContext } from './FaxProvider';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';
import { routes } from './routes';

export const tabsMap = {
  income: 'income',
  outcome: 'outcome'
};

export const PageContent = () => {
  const rootRef = useRef();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { viewVariant } = useContext(LayoutContext);
  const { resetFaxesFromSelected } = useContext(FaxContext);
  const { width } = useResizeObserver({ ref: rootRef });
  const isTablet = width <= theme.breakpoints.values.tabletLg;
  const {
    ScrollWrapper,
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps
  } = useMobileFilterCollapse('tabletLg', width);
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  useEffect(() => {
    resetFaxesFromSelected();
  }, [ pathname ]);

  return (
    <Box
      ref={rootRef}
      bgcolor="grey.100"
      overflow="hidden"
      display="flex"
      flexDirection="column"
      height="100%"
      py={2}
      px={3}
    >
      <Page>
        <Route exact path="/faxes">
          <Redirect to="/faxes/income" />
        </Route>

        <ActionsBar isTablet={isTablet}  filterOpenButton={filterOpenButton} />

        <Paper
          component={Box}
          elevation={0}
          variant="outlined"
          borderTop={0}
          borderLeft={0}
          borderRight={0}
        >
          <Tabs
            textColor="primary"
            indicatorColor="primary"
            value={getActiveRoute(routes)}
          >
            {routes.map(({ path, label }) => label && (
              <Tab
                key={path}
                component={Link}
                to={path}
                value={path}
                label={label}
              />
            ))}
          </Tabs>
        </Paper>

        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <FiltersBar filterKey={filtersKeysMap.fax_history} />
          </FilterBarWrapper>
        </ScrollWrapper>

        <Box display="flex" flexDirection="column" flexGrow={1}>
          {routes.map(({ path, component: Component }) => pathname === path && (
            <Route
              key={path}
              path={path}
              render={() => (
                <Component>
                  {isTablet || viewVariant === viewVariantsMap.grid
                    ? <Grid />
                    : viewVariant === viewVariantsMap.list
                      ? <List />
                      : null
                  }
                </Component>
              )}
            />
          ))}
        </Box>
      </Page>
    </Box>
  );
};
