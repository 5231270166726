import { useContext, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { Box, InputAdornment, makeStyles, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { TextField } from '../../../../../../components/FormField';
import { AppBar } from '../../../../../../components/AppBar';
import { Fab } from '../../../../../../components/Fab';
import { CreateBaseCustomerModal } from '../../../../Members/CreateBaseCustomerModal';
import { PatientsContext } from '../../../PatientsProvider';
import { styles } from './styles';

export const BREAKPOINT_WIDTH = 1000;

const useStyles = makeStyles(styles);

const initialValues = {
  search: null,
  med_rec: null
};

export const ActionsBar = ({
  width,
  savedFilter,
  handleSetRelationsForFilter,
  filterOpenButton
}) => {
  const theme = useTheme();
  const formikRef = useRef();
  const { openModal } = useModal();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { filter, applyFilter, creatBaseUser } = useContext(PatientsContext);
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    handleSetRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 200);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const openCreateCustomerModal = () => {
    openModal(CreateBaseCustomerModal, {
      payload: {
        isPatient: true
      },
      onModalResolved: (user) => {
        creatBaseUser(user);
      }
    });
  };

  useEffect(() => {
    formikRef.current.setValues(savedFilter);
  }, [ savedFilter ]);

  return (
    <AppBar className={classes.wrapper} ToolbarProps={{ className: classes.root }}>
      <Box ml={isMobile ? 2 : 0} order={-1}>
        <Fab
          variant="extended"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={openCreateCustomerModal}
        >
          {isMobile ? 'Add' : 'Add new patient'}
        </Fab>
      </Box>

      <Box minWidth={isMobile ? '100%' : 400} flexGrow={1} ml={isMobile ? 0 : 2} mt={isMobile ? -1 : 0}>
        <FiltersBarComponent
          disableIcon
          disableBorder
          disableTopPaddings
          formikRef={formikRef}
          initialValues={initialValues}
          color="transparent"
          fields={[
            <TextField
              fullWidth
              name="search"
              color="primary"
              placeholder="Search by patients name"
              margin="dense"
              onChange={handleFieldChange('search')}
              value={filter.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                )
              }}
            />,
            <Box minWidth={140} ml={isMobile ? 0 : 3}>
              <TextField
                name="med_rec"
                color="primary"
                placeholder="MRN"
                margin="dense"
                onChange={handleFieldChange('med_rec')}
              />
            </Box>
          ]}
        />
      </Box>

      {isMobile &&
        <Box
          px={1}
          py={0.25}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          ml={isMobile ? 'auto' : 0}
          mr={isMobile ? 2 : 0}
          order={isMobile ? -1 : 0}
        >
          {filterOpenButton}
        </Box>
      }

      {(width > BREAKPOINT_WIDTH) &&
        <Box mr={2} ml="auto">
          <Tooltip title="List view">
            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Grid view">
            <IconButton
              edge="end"
              color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.grid)}
            >
              <ViewModuleIcon />
            </IconButton>
          </Tooltip>
        </Box>
      }
    </AppBar>
  );
};
