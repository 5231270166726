export const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 585,
    borderRadius: 5
  },

  content: {
    margin: '0 !important',
    width: '100% !important',
    flexGrow: 1
  }
});
