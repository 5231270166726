import { api } from '../../api';

export const fetchVisitReasons = (config) => {
  return api.get('/visit-reasons', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchVisitReason = (id, config) => {
  return api.get(`/visit-reasons/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createVisitReason = (data) => {
  return api.post('/visit-reasons', data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateVisitReason = ({ id, ...data }) => {
  return api.put(`/visit-reasons/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteVisitReason = (id) => {
  return api.delete(`/visit-reasons/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
