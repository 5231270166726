export const styles = ({ palette, typography: { pxToRem } }) => ({
  badgeIcon: {
    color: palette.success.main,
    backgroundColor: palette.background.default,
    fontSize: pxToRem(12),
    borderRadius: 50
  },

  badgeIcon__red: {
    color: palette.error.main
  }
});
