import { periodDirectionsMap } from './periodDirectionsMap';

export const initialValues = {
  position: null,
  group_id: null,
  type: null,
  period: null,
  interval: null,
  case_roles: null,
  description: null,
  previous_event_id: null,
  period_direction: periodDirectionsMap.forward
};
