import { Grid } from '@material-ui/core';
import { OfficesLocationSelect, PasswordField, TextField } from '../../../FormField';
import { UsersSelect } from '../../../users';

export const AccountForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          required
          name="name"
          label="Name"
          placeholder="Enter number..."
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          required
          rowsMax={5}
          variant="filled"
          name="description"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="login"
          label="Login"
          placeholder="Enter login..."
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <PasswordField
          name="password"
          label="Password"
          placeholder="Enter password..."
          autoComplete="new-password"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <OfficesLocationSelect
          name="office_id"
          label="Office"
          placeholder="Enter password..."
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <UsersSelect
          multiple
          name="users"
          label="Users"
          placeholder="Select user..."
        />
      </Grid>
    </Grid>
  );
};
