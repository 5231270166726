import { useEffect, useState } from 'react';
import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import * as remindersApi from '../../../../../../api/cases/reminders/case-reminders';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ProgressBar = ({ caseId }) => {
  const classes = useStyles();
  const [ statistics, setStatistics ] = useState(null);

  useEffect(() => {
    remindersApi.fetchCaseRemindersStatistics({
      params: {
        case_ids: caseId ? [ caseId ] : null
      }
    }).then(({ done, undone }) => {
      const total = done + undone;
      const donePercent = Math.round(done * 100 / total);
      const undonePercent = 100 - donePercent;

      setStatistics({
        done,
        undone,
        donePercent,
        undonePercent
      });
    });
  }, []);

  return !!statistics && (
    <div className={classes.root}>
      <div className={cn(classes.label, classes.done)}>
        <Typography variant="subtitle1">
          Done: {statistics.done}
        </Typography>
      </div>

      <div className={classes.bar}>
        <div className={classes.done} style={{ width: `${statistics.donePercent}%` }}/>
        <div className={classes.notDone} style={{ width: `${statistics.undonePercent}%` }}/>
      </div>

      <div className={cn(classes.label, classes.notDone)}>
        <Typography variant="subtitle1">
          Not Done: {statistics.undone}
        </Typography>
      </div>
    </div>
  );
};
