import { ListRow, ListRowCell } from '../../ListRow';
import { columnWidth } from '../columnWidthMap';

export const ListHeader = () => {
  return (
    <ListRow header display="flex" flexGrow={1}>
      <ListRowCell grow flexBasis={columnWidth.name}>
        Name
      </ListRowCell>

      <ListRowCell flexBasis={columnWidth.number}>
        Number
      </ListRowCell>

      <ListRowCell flexBasis={columnWidth.enumeration_date}>
        Enum. date
      </ListRowCell>

      <ListRowCell flexBasis={columnWidth.status}>
        Status
      </ListRowCell>

      <ListRowCell flexBasis={columnWidth.last_updated}>
        Date
      </ListRowCell>
    </ListRow>
  );
};
