import filesize from 'filesize';
import { useModal } from 'react-modal-hook';
import { Grid, Link, makeStyles, Paper, Typography } from '@material-ui/core';
import { FilePreviewModal, FileTypeIcon } from '../../../../app/Dashboard/files-common';
import { stopPropagation } from '../../../../helpers/dom';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Attachment = ({ file, files }) => {
  const classes = useStyles();
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        disableFetch: true,
        file,
        files
      }}
    />
  ), [ files ]);

  return (
    <Paper elevation={0} className={classes.root}>
      <Grid container spacing={1} alignItems="center" wrap="nowrap">
        <Grid item>
          <FileTypeIcon file={file} fontSize="large" />
        </Grid>

        <Grid item className={classes.fileInfo}>
          <Link className={classes.fileName} onClick={stopPropagation(openFilesPreview)}>
            {file?.original_filename}
          </Link>

          <Typography variant="body2" color="textSecondary">
            {filesize(file.size)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
