import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.HISTORIES_FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.HISTORIES_FETCH_SUCCESS]: (
    { filter, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      selectedIDs: [],
      filter: { ...filter, page, per_page },
      histories: data
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_HISTORIES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.DELETE_HISTORY_SUCCESS]: ({ histories, pagination, filter, ...state }, historyId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      histories: histories.filter(({ id }) => id !== historyId)
    };
  },

  [types.TOGGLE_ALL_HISTORIES_SELECTION]: ({ histories, selectedIDs, ...state }) => {
    const allItemIsSelected = histories.length === selectedIDs.length;

    return {
      ...state,

      histories,
      selectedIDs: allItemIsSelected ? [] : histories.map((item) => item.id)
    };
  },

  [types.TOGGLE_HISTORY_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1
        ? selectedIDs.filter((item) => item !== id)
        : selectedIDs.concat(id)
    };
  },

  [types.MASS_DELETE_HISTORIES]: ({ histories, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      histories: histories.filter((history) => IDs.indexOf(history.id) < 0)
    };
  }
});
