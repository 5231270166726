import { ActivityProvider } from '../../Activities';
import { List } from './List';

export const Activity = ({ claim }) => {
  return (
    <ActivityProvider filter={{ model: 'claim', model_id: claim.id }}>
      <List claim={claim} />
    </ActivityProvider>
  );
};
