import { Box, Grid, Typography } from '@material-ui/core';
import { AddressLink } from '../../components/AddressLink';
import { CompanyLink } from '../../components/CompanyLink';
import { Date } from '../../components/Date';
import { UserLink } from '../../components/UserLink';

export const AppointmentInfo = ({ appointment }) => {
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={4}>
        <Typography noWrap variant="h4">
          When:
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Box display="flex">
          <Date variant="h4" date={appointment.started_at} format="L, LT" />
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Typography noWrap variant="h4">
          Where:
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <AddressLink variant="h4" address={appointment.location}>
          {appointment.location}
        </AddressLink>
      </Grid>

      <Grid item xs={4}>
        <Typography noWrap variant="h4">
          Insurance Company:
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Box display="flex">
          <CompanyLink
            disableLink
            variant="h4"
            company={appointment.insurance_company}
          />
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Typography noWrap variant="h4">
          Doctor:
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Box display="flex">
          <UserLink
            disableLink
            variant="h4"
            user={appointment.provider}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
