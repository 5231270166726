import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, SvgIcon, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import { mdiFileAccountOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as formsApi from '../../../../api/profile';
import * as userApi from '../../../../api/users';
import { Loader } from '../../../Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../Modal';
import { formTypesOptions } from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/formTypesOptions';
import { formTypesMap } from '../formTypesMap';
import { Form } from './Form';
import { validationSchema } from './validationSchema';

export const SelectMedicalFormModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    patient = null,
    pathname = null
  }
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSetType = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (values.medical_form === formTypesMap.follow_up) {
      return formsApi.createFollowUpMedicalForm(values).then((data) => {
        history.push(`${pathname}/medical-forms/${data.id}/edit`, {
          state: {
            type: values.medical_form
          }
        });
      }).catch((errors) => {
        if (errors) {
          setErrors(errors);
          enqueueSnackbar('Form not created', { variant: 'error' });
        }
      });
    } else {
      if (values.patient_id?.id) {
        handleModalResolve({ ...values, patient: values.patient_id?.data });
      } else {
        userApi.fetchUser(values.patient_id).then((data) => {
          handleModalResolve({ ...values, patient: data });
        });
      }
    }
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <Formik
        initialValues={{
          ...patient,

          patient_id: patient,
          medical_form: formTypesOptions[0],
          medical_form_id: null,
          doi: null,
          doi_from: null,
          doi_to: null,
          isLoading: false,
          is_range: false,
          last_work_date: null
        }}
        validationSchema={validationSchema}
        onSubmit={handleSetType}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={
                <SvgIcon>
                  <Icon path={mdiFileAccountOutline} />
                </SvgIcon>
              }
              onClose={handleModalReject}
            >
              Create Medical Form
            </ModalHeader>

            <ModalBody>
              <Form isPatientExist={!!patient?.id} />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting || values.isLoading} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
