import GmailSvg from './gmail.svg';
import OutlookSvg from './outlook.svg';

export const emailConfigType = {
  gmail: 'gmail',
  outlook: 'outlook',
  other: 'other'
};

export const emailConfigs = {
  [emailConfigType.gmail]: {
    name: 'Gmail',
    icon: GmailSvg,
    config: {
      name: null,
      username: null,
      password: null,
      incoming: {
        host: 'imap.gmail.com',
        port: 993,
        encryption: 'ssl'
      },
      outgoing: {
        host: 'smtp.gmail.com',
        port: 587,
        encryption: 'tls'
      }
    }
  },
  [emailConfigType.outlook]: {
    name: 'Outlook',
    icon: OutlookSvg,
    config: {
      name: null,
      username: null,
      password: null,
      incoming: {
        host: 'imap-mail.outlook.com',
        port: 993,
        encryption: 'ssl'
      },
      outgoing: {
        host: 'smtp-mail.outlook.com',
        port: 587,
        encryption: 'tls'
      }
    }
  },
  [emailConfigType.other]: {
    name: 'Other',
    config: {
      name: null,
      username: null,
      password: null,
      incoming: {
        host: null,
        encryption: 'ssl',
        port: 993
      },
      outgoing: {
        host: null,
        port: 587,
        encryption: 'tls'
      }
    }
  }
};
