import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../TracksList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = () => {
  const classes = useStyles();

  return (
    <ListRow header className={classes.root}>
      <ListRowCell flexBasis={columnsWidths.action}/>

      <ListRowCell flexBasis={columnsWidths.type}>
        Type
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.range_date}>
        Employee name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.caseName}>
        Parent
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.category}>
        Category
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.notes}>
        Notes
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.finished_at}>
        Started at
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.finished_at}>
        Finished at
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        Total, h
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.rate}>
        Rate
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.billing_rate}>
        Total, $
      </ListRowCell>
    </ListRow>
  );
};
