import { billingParticipantsTypesOptions, invoicesTypesOptions } from '../../../../../components/billing';

export const initialValues = {
  model_type: invoicesTypesOptions[0],
  model_id: null,
  bill_from: {
    type: billingParticipantsTypesOptions[0],
    id: null
  },
  bill_to: {
    type: billingParticipantsTypesOptions[0],
    id: null
  },
  terms: null,
  date: null,
  due_date: null,
  number: null,
  po: null,
  notes: null
};
