import { useContext, useEffect, useRef } from 'react';
import { makeStyles, List as MuiList } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../../components/Loader';
import { DocumentVersionsContext } from '../../DocumentVersionsContext';
import { VersionPreviewContext } from '../../VersionPreviewContext';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const List = () => {
  const classes = useStyles();
  const {
    isFetching,
    isFetched,
    versions,
    filter: { page, per_page, last_page, total },
    fetchVersions,
    loadNextPage
  } = useContext(DocumentVersionsContext);
  const versionPreviewContext = useContext(VersionPreviewContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    fetchVersions().then(({ data }) => {
      versionPreviewContext.handleVersionSelect(data.length && data[0].id);
    });
  }, []);

  return (
    <div className={classes.root}>
      <Loader
        loading={!isFetched}
        className={classes.loader}
        render={
          () => (!!versions.length || total > 0) && (
            <MuiList disablePadding ref={scrollElementRef} className={classes.list}>
              <InfiniteListLoader
                items={versions}
                isFetching={isFetching}
                isFetched={isFetched}
                RowComponent={Row}
                pagination={{ per_page, last_page, page }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
                minRowHeight={54}
              />
            </MuiList>
          )
        }
      />
    </div>
  );
};
