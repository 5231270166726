export const styles = ({
  palette: { grey, divider, text },
  typography: { pxToRem, fontWeightMedium },
  spacing,
  breakpoints
}) => ({
  preview: {
    width: '100%',
    borderBottom: `1px solid ${divider}`,
    padding: spacing(1.5, 5, 2),

    '&:nth-child(3)': {
      borderBottom: 0
    },

    [breakpoints.down('md')]: {
      padding: spacing(1, 2)
    }
  },

  preview_static: {
    padding: spacing(1.5, 4, 2)
  },

  preview__title: {
    color: text.caption,
    fontWeight: fontWeightMedium,
    minWidth: 70
  },

  preview__dateTitle: {
    color: text.caption,
    fontWeight: fontWeightMedium,
    marginBottom: spacing(2)
  },

  preview__owner: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '&:first-child': {
      marginBottom: spacing(1)
    }
  },

  preview__avatar: {
    marginRight: spacing(1.5)
  },

  preview__dataBorder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${divider}`,
    marginBottom: spacing(1),
    paddingBottom: spacing(1),
    marginTop: spacing(0.5),

    '&:last-child': {
      borderBottom: 0
    }
  },

  preview__dataContent: {
    fontSize: pxToRem(12)
  },

  preview__userName: {
    color: grey[500],
    fontSize: pxToRem(14)
  },

  preview__submit: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: spacing(2.5),
    paddingRight: spacing(4)
  },

  preview__cancelBtn: {
    color: grey[200],
    marginRight: spacing(1.5)
  }
});
