import CloseIcon from '@material-ui/icons/Close';
import { Box, Link } from '@material-ui/core';
import { stopPropagation } from '../../../helpers/dom';
import { UserPreviewModal } from '../../users';
import { IconButton } from '../../IconButton';
import { useModal } from '../../ModalsProvider';
import { UserLink } from '../../UserLink';

export const UsersList = ({ users, onClose }) => {
  const { openModal } = useModal();

  const openPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id },
      onModalRejected: () => {
        onClose();
      }
    });
  };

  return (
    <Box minWidth={170} p={2} pt={0}>
      <Box display="flex" justifyContent="flex-end" py={1}>
        <IconButton color="error" size="small" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      {users?.map((user) => user && (
        <UserLink
          key={user?.id}
          user={user}
          color="textPrimary"
          component={Link}
          onClick={stopPropagation(openPreview(user?.id))}
        />
      ))}
    </Box>
  );
};
