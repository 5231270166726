import { Box } from '@material-ui/core';
import { useContext } from 'react';
import { CompaniesAutocomplete } from '../../../../../../components/FormField';
import { StatisticsFilterContext } from '../../StatisticsFilterContext';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { CaseUsersSelect } from '../../../../../../components/cases/CaseUsersSelect';
import { CasesSelect } from '../../../../../../components/cases/CasesSelect';

const initialValues = {
  users_id: null,
  case_id: null,
  company_id: null
};

export const MainFilter = () => {
  const { setFilter } = useContext(StatisticsFilterContext);

  const handleCaseChange = (caseItem) => {
    setFilter((filter) => ({ ...filter, case_id: caseItem?.id || null }));
  };

  const handleCompanyChange = (company) => {
    setFilter((filter) => ({ ...filter, company_id: company?.id || null }));
  };

  const handleCaseUsersChange = (users) => {
    setFilter((filter) => ({ ...filter, users_id: users?.map(({ id }) => id) || null }));
  };

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      initialValues={initialValues}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.companyID,
          label: filterFieldsLabels[filterFieldsMap.companyID],
          field: (
            <Box minWidth={170}>
              <CompaniesAutocomplete
                name="company_id"
                label={filterFieldsLabels[filterFieldsMap.companyID]}
                onChange={handleCompanyChange}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.usersID,
          label: filterFieldsLabels[filterFieldsMap.caseID],
          field: (
            <CasesSelect
              name="case_id"
              label="All cases"
              placeholder="Search case by name..."
              minWidth={170}
              onChange={handleCaseChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.usersID,
          label: filterFieldsLabels[filterFieldsMap.usersID],
          field: (
            <CaseUsersSelect
              multiple
              name="users_id"
              label="Select by user name"
              minWidth={170}
              onChange={handleCaseUsersChange}
            />
          )
        }
      ]}
    />
  );
};
