import { Formik } from 'formik';
import { Icon } from '@mdi/react';
import { mdiFilter } from '@mdi/js';
import { Box, makeStyles, SvgIcon } from '@material-ui/core';
import { AppBar } from '../../../../../../components/AppBar';
import { CaseUsersSelect } from '../../../../../../components/cases/CaseUsersSelect';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const initialValues = {
  users_id: null
};

export const MainFilter = ({ setFilter }) => {
  const classes = useStyles();

  const handleUsersChange = (users) => {
    setFilter((filter) => ({ ...filter, users_id: users?.map(({ id }) => id) || null }));
  };

  return (
    <AppBar className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <Formik
        initialValues={initialValues}
        onSubmit={null}
      >
        {() => (
          <div className={classes.filterRoot}>
            <SvgIcon color="primary" className={classes.filterIcon}>
              <Icon path={mdiFilter} />
            </SvgIcon>

            <Box minWidth={200}>
              <CaseUsersSelect
                multiple
                name="users_id"
                label="Select by user name"
                margin="dense"
                onChange={handleUsersChange}
              />
            </Box>
          </div>
        )}
      </Formik>
    </AppBar>
  );
};
