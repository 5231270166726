import { useModal } from 'react-modal-hook';
import { Link, Typography } from '@material-ui/core';
import { FilePreviewModal } from '../../../app/Dashboard/files-common';

export const NewFileActivity = ({ showPreview = true, disableAttach = false, file, fieldLabel = null }) => {
  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        singleView: true
      }}
    />
  ), [ file ]);

  return (
    !disableAttach ? (
      <>
        Attached file
        {!!fieldLabel && (
          <>
            &nbsp;to&nbsp;
            <Typography variant="subtitle1">{fieldLabel}</Typography>
          </>
        )}
        &nbsp;-&nbsp;
        <Link variant="subtitle1" onClick={openFilesPreview}>
          {file?.original_filename}
        </Link>
      </>
    ) : (
      <>
        Deleted file
        {!!fieldLabel && (
          <>
            &nbsp;from&nbsp;
            <Typography variant="subtitle1">{fieldLabel}</Typography>
          </>
        )}
        &nbsp;-&nbsp;
        {showPreview ? (
          <Link variant="subtitle1" onClick={openFilesPreview}>
            {file?.original_filename}
          </Link>
        ) : (
          <Typography variant="subtitle1">{file?.original_filename}</Typography>
        )}
      </>
    )
  );
};
