import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../../../components/Loader';
import { Date } from '../../../../../../../../../components/Date';
import { columnsMap, columnsWidths } from '../SavedFiltersList';

export const Row = ({
  item: filter = {},
  isLoaded,
  hiddenColumns = [],
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.startedAfter}>
        <Date disableIcon date={params.started_after} format="L LT" />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.startedBefore}>
        <Date disableIcon date={params.started_before} format="L LT" />
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.cases) &&
        <ListRowCell flexBasis={columnsWidths.cases}>
          <Box display="flex" flexDirection="column" width="100%">
            {!params.cases?.length ? '-' : params?.cases?.map((item, index) => (
              <Typography noWrap key={index} title={item.name}>
                {item.name + ' '}
              </Typography>
            ))}
          </Box>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.tasks}>
        <Box display="flex" flexDirection="column" width="100%">
          {!params.tasks?.length ? '-' : params?.tasks?.map((item, index) => (
            <Typography noWrap key={index} title={item.name}>
              {item.name + ' '}
            </Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.scheduleEvents}>
        <Box display="flex" flexDirection="column" width="100%">
          {!params.schedule_events?.length ? '-' : params?.schedule_events.map((event) => (
            <Typography noWrap key={event.id} title={`${event?.title}
             at ${moment.unix(event?.started_at).format('L LT')}`}>
              {event?.title} at {moment.unix(event?.started_at).format('L LT')}
            </Typography>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
