import { useParams } from 'react-router-dom';
import { SvgIcon, Typography, Box, Link } from '@material-ui/core';
import { mdiFileDocumentEditOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { hasRole } from '../../../../../../utils/hasRole';
import { Date } from '../../../../../../components/Date';

export const ClaimModel = ({ model }) => {
  const params = useParams();

  const goClaimPage = () => {
    if (hasRole('doctor', 'patient', 'client', 'medic')) {
      window.open(`/patients/${params.patientID}/medical-info/claims/${model.id}`);
    } else {
      window.open(`/billing/claims/${model.id}`);
    }
  };

  return (
    <Box display="flex" alignItems="center" color="info.main">
      <SvgIcon color="inherit"><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>

      <Box ml={1}>
        {model?.number &&
          <Typography color="textPrimary" variant="body2">
            {model?.number || '-'}
          </Typography>
        }

        <Date
          disableIcon
          component={Link}
          target="_blank"
          color="textSecondary"
          date={model.appointment?.appointment_at}
          onClick={goClaimPage}
        />
      </Box>
    </Box>
  );
};
