import { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  SvgIcon,
  ClickAwayListener
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { mdiFilter, mdiFilterMinus } from '@mdi/js';
import AddIcon from '@material-ui/icons/Add';
import { Icon } from '@mdi/react';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { Divider } from '../../../../../components/Divider';
import { IconButton } from '../../../../../components/IconButton';
import {
  Page,
  PageBody,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../../components/Page';
import { useModal } from '../../../../../components/ModalsProvider';
import { Select } from '../../../../../components/FormField';
import { Fab } from '../../../../../components/Fab';
import { Tooltip } from '../../../../../components/Tooltip';
import { AccountsContext } from '../../AccountsContext';
import { RulesEmailsContext } from '../RulesEmailsContext';
import { CreateEmailsRuleFormModal } from './CreateEmailsRuleFormModal';
import { FiltersBar } from './FiltersBar';
import { Rules } from './Rules';

export const MainContent = () => {
  const { createRule, applyFilter, resetRules } = useContext(RulesEmailsContext);
  const { accounts } = useContext(AccountsContext);
  const [ isOpenTooltip, setIsOpenTooltip ] = useState(false);
  const { openModal } = useModal();
  const configID = accounts.map(({ id }) => id);
  const accountsOptions = accounts.map((account) => ({
    value: account.id,
    label: account.username
  }));
  const theme = useTheme();
  const isTable = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  // eslint-disable-next-line max-len
  const ruleTooltip = 'You can create rules that tell CaseArk how to handle incoming email messages. You choose both the conditions that trigger a rule and the actions the rule will take. Rules will run in the order shown in the list below, starting with the rule at the top.';
  const {
    openFiltersBar,
    FilterBarWrapper,
    filterBarWrapperProps,
    toggleFiltersBar,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  const handleNewRule = () => {
    openModal(CreateEmailsRuleFormModal, {
      payload: { accounts },
      onModalResolved: (rule) => {
        createRule(rule);
      }
    });
  };

  const handleChangeAccount = (name) => (option) => {
    applyFilter({ [name]: option ? [ option.value ] : configID });
  };

  const handleToggleTooltip = () => {
    setIsOpenTooltip((state) => !state);
  };

  const handleTooltipClose = () => {
    setIsOpenTooltip(false);
  };

  useEffect(() => {
    resetRules();
  }, []);

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>Rules</PageHeaderTitle>

        {isTable && (
          <PageHeaderActions startPosition>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  placement="bottom-start"
                  PopperProps={{
                    disablePortal: true
                  }}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  isExistTooltip={isOpenTooltip}
                  open={isOpenTooltip}
                  onClose={handleTooltipClose}
                  title={ruleTooltip}
                >
                  <IconButton color="primary" onClick={handleToggleTooltip}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </PageHeaderActions>
        )}
      </PageHeader>

      <PageBody fullHeight disablePaddings disableScrollbars>
        <Box height="100%" display="flex" flexDirection="column">
          {!isTable && (
            <Box maxWidth={975} px={isMobile ? 1 : 3} pt={isMobile ? 1 : 3}>
              <Typography paragraph color="textSecondary">
                {ruleTooltip}
              </Typography>
            </Box>
          )}

          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            component={Box}
            px={isTable ? 1 : 3}
            pb={isTable ? 1 : 2}
          >
            <Grid item>
              <Fab
                color="primary"
                variant="extended"
                onClick={handleNewRule}
                startIcon={<AddIcon />}
              >
                New rule
              </Fab>
            </Grid>

            <Grid item md={4} sm={8} xs={6}>
              <Select
                withoutFormik
                isClearable
                name="config_id"
                label="Accounts"
                options={accountsOptions}
                onChange={handleChangeAccount('config_id')}
              />
            </Grid>

            <Grid item>
              <IconButton color="primary" onClick={toggleFiltersBar}>
                <SvgIcon color="inherit">
                  {!openFiltersBar ? <Icon path={mdiFilter} /> : <Icon path={mdiFilterMinus} />}
                </SvgIcon>
              </IconButton>
            </Grid>
          </Grid>

          <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
            <ScrollWrapper {...scrollWrapperProps}>
              <FilterBarWrapper {...filterBarWrapperProps}>
                <FiltersBar/>
              </FilterBarWrapper>
            </ScrollWrapper>

            {isTable && !openFiltersBar && <Divider gutter={1} disableBottomGutter />}

            <PageBody disablePaddings>
              <Rules/>
            </PageBody>
          </Box>
        </Box>
      </PageBody>
    </Page>
  );
};
