import { api } from '../api';

export const fetchAccounts = (config = {}) => {
  return api.get('/loginQueues/accounts', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const createAccount = (data) => {
  return api.post('/loginQueues/accounts', data).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const fetchAccount = (id) => {
  return api.get(`/loginQueues/accounts/${id}`).then(({ data }) => data);
};

export const updateAccount = ({ id, ...data }) => {
  return api.put(`/loginQueues/accounts/${id}`, data).then(({ data }) => data);
};

export const deleteAccount = (id) => {
  return api.delete(`/loginQueues/accounts/${id}`).then(({ data }) => data);
};
