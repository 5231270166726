import { LayoutContextProvider } from '../../../components/LayoutContext';
import { Services as ServicesCommon } from '../../../components/login-queues/services/Services';
import { LoginServicesProvider } from '../../../components/login-queues/services';
import { Page, PageBody } from '../../../components/Page';
import { Header } from './Header';

export const Services = () => {
  return (
    <LoginServicesProvider>
      <LayoutContextProvider>
        <Page>
          <Header />

          <PageBody disablePaddings disableScrollbars fullHeight>
            <ServicesCommon />
          </PageBody>
        </Page>
      </LayoutContextProvider>
    </LoginServicesProvider>
  );
};
