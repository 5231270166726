export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    height: '100%',
    paddingBottom: spacing()
  },

  list: {
    flexGrow: 1,
    overflow: 'auto'
  },

  title: {
    padding: spacing(2)
  }
});
