export const styles = ({
  spacing,
  palette: { grey },
  typography: { pxToRem },
  shape: { borderRadius }
}) => ({
  root: {
    display: 'inline-block',
    width: 240,
    margin: spacing(0, 1.25, 1.25, 0),
    verticalAlign: 'top',
    borderRadius: 7,
    overflow: 'hidden'
  },

  root_preview: {
    cursor: 'pointer'
  },

  main: {
    height: 88,
    border: `1px solid ${grey[100]}`,
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius
  },

  previewImg: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },

  info: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0.5, 1),
    background: grey[100]
  },

  infoMain: {
    flexGrow: 1,
    overflow: 'hidden'
  },

  icon: {
    width: 26,
    height: 26,
    marginRight: spacing(0.75)
  },

  name: {
    fontWeight: 500,
    fontSize: pxToRem(12),
    color: grey[600]
  },

  size: {
    fontSize: pxToRem(11),
    color: grey[500]
  },

  pdfIcon: {
    fontSize: pxToRem(50)
  }
});
