export const styles = ({
  spacing,
  palette: { primary, grey },
  typography: { fontWeightMedium }
}) => ({
  root: {
    paddingLeft: spacing()
  },

  userInfoLink: {
    fontWeight: fontWeightMedium,
    color: grey[600]
  },

  avatar: {
    marginRight: spacing()
  },

  time: {
    cursor: 'pointer',
    color: primary.main,

    '&:hover': {
      textDecoration: 'underline'
    }
  }
});
