import { useContext } from 'react';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { CaseUsersSelect } from '../../../../../../components/cases/CaseUsersSelect';
import { CasesSelect } from '../../../../../../components/cases/CasesSelect';
import { StatisticsFilterContext } from '../../StatisticsFilterContext';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';

const initialValues = {
  users_id: null,
  case_id: null
};

export const MainFilter = () => {
  const { setFilter } = useContext(StatisticsFilterContext);

  const handleCaseChange = (caseItem) => {
    setFilter((filter) => ({ ...filter, case_id: caseItem?.id || null }));
  };

  const handleCaseUsersChange = (users) => {
    setFilter((filter) => ({ ...filter, users_id: users?.map(({ id }) => id) || null }));
  };

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      initialValues={initialValues}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.usersID,
          label: filterFieldsLabels[filterFieldsMap.usersID],
          field: (
            <CaseUsersSelect
              multiple
              name="users_id"
              label="Select by user name"
              minWidth={170}
              onChange={handleCaseUsersChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.usersID,
          label: filterFieldsLabels[filterFieldsMap.caseID],
          field: (
            <CasesSelect
              name="case_id"
              label="All cases"
              placeholder="Search case by name..."
              minWidth={170}
              onChange={handleCaseChange}
            />
          )
        }
      ]}
    />

  );
};
