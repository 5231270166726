import { useContext } from 'react';
import { generatePath, Link, useLocation, useParams } from 'react-router-dom';
import { makeStyles, Paper, Tab, Tabs } from '@material-ui/core';
import { Route } from '../../../../../components/router';
import { apiModelsMap } from '../../../../../dataMaps/apiModels';
import { FilesPage } from '../../../files-common/FilesPage';
import { columnsMap } from '../../../files-common/FilesList/listConfig';
import { Trash } from '../../../FilesPage/Trash';
import { UserContext } from '../../../Members/User/UserProvider';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
});

const tabsRoutes = {
  files: '/patients/:patientID/medical-info/files',
  recycleBin: '/patients/:patientID/medical-info/files/recycle-bin'
};

export const Files = ({ filterKey }) => {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams();
  const { user } = useContext(UserContext);

  return (
    <Paper elevation={0} variant="outlined" className={classes.root}>
      <Tabs value={location.pathname}>
        <Tab
          component={Link}
          value={generatePath(tabsRoutes.files, params)}
          to={generatePath(tabsRoutes.files, params)}
          label="Files"
        />

        <Tab
          component={Link}
          value={generatePath(tabsRoutes.recycleBin, params)}
          to={generatePath(tabsRoutes.recycleBin, params)}
          label="Recycle Bin"
        />
      </Tabs>

      <Route exact path={tabsRoutes.files}>
        <FilesPage
          owner={user}
          ownerType={apiModelsMap.profile}
          ownerID={user.profile_id}
          filter={{
            user_profile_id: user.profile_id
          }}
          filterKey={filterKey}
          hiddenColumns={[ columnsMap.owner ]}
        />
      </Route>

      <Route path={tabsRoutes.recycleBin}>
        <Trash
          ownerType={apiModelsMap.profile}
          ownerID={user.profile_id}
        />
      </Route>
    </Paper>
  );
};
