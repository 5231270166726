import { useRef, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, flow } from 'lodash';
import { SavedFiltersProvider, SavedFiltersWidget } from '../../../saved-filters';
import { saveFilter } from '../../../../store/lastFilters';
import { CasesSelect } from '../../../cases/CasesSelect';
import { Autocomplete, KeyboardDatePicker, NumberMaskField } from '../../../FormField';
import { FiltersBar as FiltersBarComponent } from '../../../FiltersBar';
import { UsersSelect } from '../../../users';
import { CallReportsContext } from '../../CallReportsProvider';
import { dispositionOptions } from '../../dispositionOptions';
import { filterFieldsMap, filterFieldsLabels } from '../filterFieldsMap';
import { CallbackSelect } from './CallbackSelect';
import { CallTypeSelect } from './CallTypeSelect';
import { List } from './List';

const initialValues = {
  user_id: null,
  phone: null,
  disposition: null,
  is_group_call: null,
  is_callback: null,
  date_from: null,
  date_to: null,
  parent_user_ids: null,
  parent_case_ids: null
};

const transformRelationsToFilters = (relationsForFilter, defaultFilter) => {
  return {
    ...relationsForFilter,

    user_id: relationsForFilter.user_id?.id,
    parent_user_ids: relationsForFilter.parent_user_ids?.id
      ? [ relationsForFilter.parent_user_ids?.id, ...(defaultFilter?.parent_user_ids || []) ]
      : defaultFilter?.parent_user_ids || [],
    parent_case_ids: relationsForFilter.parent_case_ids?.id
      ? [ relationsForFilter.parent_case_ids?.id, ...(defaultFilter?.parent_case_ids || []) ]
      : defaultFilter?.parent_case_ids || [],
    disposition: relationsForFilter.disposition?.value,
    is_callback: relationsForFilter.is_callback?.value,
    is_group_call: relationsForFilter.is_group_call?.value
  };
};

const IconComponent = ({ relationsForFilter, onApplySavedFilter, filterKey, hiddenFields }) => {
  return (
    <SavedFiltersProvider filter={{ entity: filterKey }}>
      <SavedFiltersWidget
        relationsForFilter={relationsForFilter}
        modalWidth={hiddenFields?.length ? 1100 : 1400}
      >
        {({ handleClose }) => (
          <List hiddenFields={hiddenFields} onApplySavedFilter={flow(onApplySavedFilter, handleClose)} />
        )}
      </SavedFiltersWidget>
    </SavedFiltersProvider>
  );
};

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { defaultFilter, filter, resetCallReports, applyFilter } = useContext(CallReportsContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState(initialValues);

  const handleDispositionChange = (option) => {
    const disposition = option?.value || null;

    resetCallReports({ disposition });
    setRelationsForFilter((state) => ({ ...state, disposition: option }));
  };

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;
    const phone = value?.replace(/[(|)\s,-]/g, '');

    resetCallReports({ [name]: phone });
    setRelationsForFilter((state) => ({ ...state, [name]: phone }));
  }, 600);

  const handleUserChange = (name) => (option) => {
    resetCallReports({ [name]: option?.id });
    setRelationsForFilter((state) => ({ ...state, [name]: option }));
  };

  const handleMultiSelectChange = (name) => (option) => {
    const prevFilter = [].concat(filter?.[name] || []);
    const newFilter = option
      ? [ ...new Set([ ...prevFilter, option?.id, ...(defaultFilter?.[name] || []) ]) ]
      : defaultFilter?.[name];

    resetCallReports({ [name]: newFilter });
    setRelationsForFilter((state) => ({ ...state, [name]: option }));
  };

  const handleDatePickerChange = (name) => (date) => {
    resetCallReports(({ [name]: date }));
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleCallTypeChange = (option) => {
    applyFilter({ is_group_call: option?.value });
    setRelationsForFilter((state) => ({ ...state, is_group_call: option }));
  };

  const handleCallbackChange = (option) => {
    applyFilter({ is_callback: option?.value });
    setRelationsForFilter((state) => ({ ...state, is_callback: option }));
  };

  const applySavedFilter = (values) => {
    applyFilter(transformRelationsToFilters(values, defaultFilter));
    formikRef?.current?.setValues(values);
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableResponsiveView
      formikRef={formikRef}
      border={0}
      hiddenFields={hiddenFields}
      filterKey={filterKey}
      initialValues={relationsForFilter}
      onReset={() => {
        resetCallReports({ ...initialValues, ...defaultFilter });
      }}
      iconComponent={(
        <IconComponent
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.user,
          label: filterFieldsLabels[filterFieldsMap.user],
          field: (
            <UsersSelect
              minWidth={240}
              name="user_id"
              label="User"
              onChange={handleUserChange('user_id')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.parentUser,
          label: filterFieldsLabels[filterFieldsMap.parentUser],
          field: (
            <UsersSelect
              minWidth={240}
              name="parent_user_ids"
              label="Patient"
              onChange={handleMultiSelectChange('parent_user_ids')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.parentCase,
          label: filterFieldsLabels[filterFieldsMap.parentCase],
          field: (
            <CasesSelect
              minWidth={240}
              name="parent_case_ids"
              label="Case"
              onChange={handleMultiSelectChange('parent_case_ids')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.phone,
          label: filterFieldsLabels[filterFieldsMap.phone],
          field: (
            <NumberMaskField
              name="phone"
              label="Number"
              placeholder="Search by phone..."
              onChange={handleFieldChange('phone')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.disposition,
          label: filterFieldsLabels[filterFieldsMap.disposition],
          field: (
            <Autocomplete
              name="disposition"
              label="Disposition"
              placeholder="Select disposition..."
              options={dispositionOptions}
              onChange={handleDispositionChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.is_group_call,
          label: filterFieldsLabels[filterFieldsMap.is_group_call],
          field: (
            <CallTypeSelect
              name="is_group_call"
              label={filterFieldsLabels[filterFieldsMap.is_group_call]}
              onChange={handleCallTypeChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.is_callback,
          label: filterFieldsLabels[filterFieldsMap.is_callback],
          field: (
            <CallbackSelect
              name="is_callback"
              label={filterFieldsLabels[filterFieldsMap.is_callback]}
              onChange={handleCallbackChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.dateFrom,
          label: filterFieldsLabels[filterFieldsMap.dateFrom],
          field: (
            <KeyboardDatePicker
              clearable
              outputFormat="YYYY-MM-DD"
              name="date_from"
              label="From"
              onChange={handleDatePickerChange('date_from')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.dateTo,
          label: filterFieldsLabels[filterFieldsMap.dateTo],
          field: (
            <KeyboardDatePicker
              clearable
              disableFuture
              outputFormat="YYYY-MM-DD"
              name="date_to"
              label="To"
              onChange={handleDatePickerChange('date_to')}
            />
          )
        }
      ]}
    />
  );
};
