import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../ListRow';
import { columnsWidths, widthBreakpointSmall } from '../TimeTracking';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ isEditable = true, width }) => {
  const classes = useStyles();

  return (
    <ListRow header className={cn(classes.root, { [classes.root_widget]: width < widthBreakpointSmall })}>
      <ListRowCell flexBasis={columnsWidths.actionIcon}>
        Type
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.parent}>
        Parent
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.parent_note}>
        Parent name / Note
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.user}>
        User
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.company}>
        Company
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.category}>
        Category
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.startedTime}>
        Started at/Finished at
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.duration}>
        Duration
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        Total
      </ListRowCell>

      {isEditable && <ListRowCell flexBasis={columnsWidths.action}/>}
    </ListRow>
  );
};
