import { conditionsOptions } from '../CreateCasePage/IncidentInformation/conditionsOptions';
import { incidentTypesOptions } from '../CreateCasePage/IncidentInformation/incidentTypesOptions';

export const generateInitialValues = ({ initialValues, heading_attorney, applicant }) => ({
  ...initialValues,

  name: initialValues.name,
  case_type_id: initialValues.case_type,
  case_status_id: initialValues.case_status,
  file_number: initialValues.file_number,
  description: initialValues.description,
  office_location: initialValues.office_location,
  special_project: initialValues.special_project,
  opened_at: initialValues.opened_at,
  office_id: initialValues.office,
  commenced_date: initialValues.commenced_date,
  heading_attorney_id: heading_attorney?.user || null,
  applicant_id: applicant?.user || null,
  paperless_file: initialValues.paperless_file,
  edex: initialValues.edex,
  background_check: initialValues.background_check,
  hourly: initialValues.hourly,
  flat_free: initialValues.flat_free,
  eams_number: initialValues.eams_number,
  company_id: heading_attorney?.user?.work?.company_id,
  ins_claims: [],
  ins_claims_info: initialValues.ins_claims,
  incident_type: incidentTypesOptions.find(({ value }) => value === initialValues.incident_type),
  conditions: conditionsOptions.find(({ value }) => value === initialValues.conditions)
});
