import { get } from 'lodash';
import { api } from '../api';
import * as transformHelpers from './transformHelpers';

export const fetchCasesStatistics = (config) => {
  return api.get('/case-statistics-status', config)
    .then(({ data }) => {
      const type = get(config, 'params.type') === 'types' ? 'types' : 'statuses';

      return {
        total_cases: data.total_cases,

        ...transformHelpers.transformResponse(data[type])
      };
    });
};

export const fetchCasesUsersStatistics = (config) => {
  return api.get('/case-users-statistics', config)
    .then(({ data }) => transformHelpers.transformResponse(data));
};

export const fetchTimeCaseStatistics = (config) => {
  return api.get('/case-time-statistics', config).then(({ data }) => data);
};

export const fetchCaseGraphStatistics = (config) => {
  return api.get('/case-statistics-graph', config).then(({ data }) => {
    const fetchedData = transformHelpers.transformResponse(data.data);

    return {
      total_cases: data.total_cases,
      created: fetchedData.data.map((item) => item.created),
      closed: fetchedData.data.map((item) => item.closed),

      ...transformHelpers.transformResponse(data.data)
    };
  });
};
