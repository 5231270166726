export const styles = ({ spacing, palette, breakpoints }) => ({
  root: {
    display: 'flex',
    height: '100%',

    [breakpoints.down('md')]: {
      overflow: 'auto'
    }
  },

  bodyPartsList: {
    flexGrow: 0,
    minWidth: 320
  },

  bodyImage: {
    flexGrow: 1,
    padding: spacing(2, 3),
    borderLeft: `1px solid ${palette.divider}`,
    borderRight: `1px solid ${palette.divider}`
  },

  painActions: {
    flexGrow: 0,
    minWidth: 260
  }
});
