import { Box, Grid } from '@material-ui/core';
import { Contact } from '../../../../../../../components/Contact';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';

export const Footer = ({ company }) => {
  return (
    <Grid container spacing={2} component={Box} pt={2.5} px={2} wrap="nowrap">
      <Grid item xs={6}>
        <Contact
          noWrap
          needIcon
          variant="h5"
          type="mailto"
          contact={company.email}
        />
      </Grid>

      <VerticalDivider />

      <Grid item xs={6}>
        <Contact
          noWrap
          needIcon
          variant="h5"
          type="tel"
          contact={company.phone}
        />
      </Grid>
    </Grid>
  );
};
