import { makeStyles } from '@material-ui/core';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { Badge } from '../../Badge';
import { threadTypesMap } from '../ThreadsContext';

const styles = ({ palette }) => ({
  badge: {
    left: 2,
    bottom: 2,
    padding: 0,
    backgroundColor: palette.common.white
  }
});

const useStyles = makeStyles(styles);

export const ThreadTypeIndicator = ({ children, thread, ...props }) => {
  const classes = useStyles();
  const Icon = thread.type === threadTypesMap.group
    ? PeopleOutlineIcon
    : thread.type === threadTypesMap.case
      ? WorkOutlineIcon
      : null;

  if (!Icon) {
    return children;
  }

  return (
    <Badge
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      classes={{ badge: classes.badge }}
      badgeContent={<Icon color="inherit" fontSize="small" />}

      {...props}
    >
      {children}
    </Badge>
  );
};
