export const styles = ({ palette, shadows, shape }) => ({
  mentionsInput: {
    height: '100%',

    '&__input': {
      padding: 0,
      border: 0,
      outline: 'none',
      lineHeight: 'inherit'
    },

    '&__highlighter': {
      border: '0 !important'
    },

    '&__suggestions': {
      borderRadius: shape.borderRadius,
      boxShadow: shadows[2]
    }
  },

  mention: {
    position: 'relative',
    zIndex: 1,
    color: palette.primary.main
  }
});
