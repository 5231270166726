import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { ReadonlyCheckbox } from '../../../../../../../../components/ReadonlyCheckbox';

export const widthBreakpointSmall = 540;

export const PlaintiffsPDContent = ({ plaintiff }) => {
  return (
    <Grid container spacing={3} component={Box} pb={4}>
      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Towing Co</Typography>
          </Grid>

          <Grid item>
            <CompanyLink company={plaintiff?.towing_co}  />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Towing Paid</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {plaintiff?.towing_paid || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Car Seats</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {plaintiff?.car_seats || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Car Seat Reimb.</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {plaintiff?.car_seat_reimb || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Auto Body Shop</Typography>
          </Grid>

          <Grid item>
            <CompanyLink company={plaintiff?.auto_body_shop}  />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">PD Appraised Amt.</Typography>
          </Grid>

          <Grid item>
            <CurrencyFormat value={plaintiff?.pd_appraised_amount} TypographyProps={{ variant: 'h5' }} />
          </Grid>
        </Grid>
      </Grid>

      <Hidden xsDown>
        <Grid item md={6} />
      </Hidden>

      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Deductible Paid</Typography>
          </Grid>

          <Grid item>
            <CurrencyFormat value={plaintiff?.deductible_paid} TypographyProps={{ variant: 'h5' }} />
          </Grid>
        </Grid>
      </Grid>

      <Hidden xsDown>
        <Grid item md={6} />
      </Hidden>

      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Final PD Amount</Typography>
          </Grid>

          <Grid item>
            <CurrencyFormat value={plaintiff?.final_pd_amount} TypographyProps={{ variant: 'h5' }} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">PD Payer</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              {plaintiff?.pd_payer || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <ReadonlyCheckbox variant="h5" checked={plaintiff?.total_loss} title="Total Loss" />
      </Grid>

      <Grid item md={6} />

      <Grid item md={6}>
        <ReadonlyCheckbox variant="h5" checked={plaintiff?.deductible_reimbursed} title="Deductible Reimbursed" />
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Rental Co</Typography>
          </Grid>

          <Grid item>
            <CompanyLink company={plaintiff?.rental_co}  />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography color="textSecondary">Rental Paid</Typography>
          </Grid>

          <Grid item>
            {!!plaintiff?.rental_paid ? (
              <Typography variant="h5">
                {plaintiff?.rental_paid}
              </Typography>
            ) : (
              <Typography color="textSecondary">No info.</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} />

      <Grid item md={6}>
        <ReadonlyCheckbox
          variant="h5"
          checked={plaintiff?.rental_reimbursed}
          title="Rental Reimbursed"
        />
      </Grid>
    </Grid>
  );
};
