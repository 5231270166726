export const CHECKLIST = 'CHECKLIST';
export const INSURANCE_DETAILS = 'INSURANCE_DETAILS';
export const MEDICAL_CLAIMS = 'MEDICAL_CLAIMS';
export const SCHEDULE = 'SCHEDULE';
export const COURT_INFORMATION = 'COURT_INFORMATION';
export const DISCOVERY = 'DISCOVERY';
export const PARTICIPANTS = 'PARTICIPANTS';
export const SUBSTITUTED_IN = 'SUBSTITUTED_IN';
export const FILES = 'FILES';
export const EMAIL = 'EMAIL';
export const ACTIVITY = 'ACTIVITY';
export const BILLING = 'BILLING';
export const TASKS = 'TASKS';
export const TIME_TRACKING = 'TIME_TRACKING';
export const CASE_CLOSURE = 'CASE_CLOSURE';
export const CASE_CROSS = 'CASE_CROSS';
export const CASE_RE_OPENED = 'CASE_RE_OPENED';
export const COMMENTS = 'COMMENTS';
export const DIRECT_REFERRAL = 'DIRECT_REFERRAL';
export const REMINDERS = 'REMINDERS';
export const PLAINTIFFS_PD = 'PLAINTIFF';
export const NEGOTIATIONS = 'NEGOTIATIONS';
export const INSURANCE = 'INSURANCE';
export const DEPOSITIONS = 'DEPOSITIONS';
export const CALL_REPORTS = 'CALL_REPORTS';
export const APPOINTMENTS = 'APPOINTMENTS';
