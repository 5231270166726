import { useContext } from 'react';
import { Link } from 'react-router-dom';
import printJS from 'print-js';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import { Box, Button, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import * as invoicesApi from '../../../../../../../../api/billing';
import { LayoutContext, viewVariantsMap } from '../../../../../../../../components/LayoutContext';
import { SelectedItemsCount } from '../../../../../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../../../../../components/VerticalDivider';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Fab } from '../../../../../../../../components/Fab';
import { InvoiceModal } from '../../../../../../CasesPage/Cases/CaseItem/TabsContent/Billing/InvoiceModal';
import { InvoicesContext } from '../../../../InvoicesContext';

export const InvoiceActionBar = ({ isMobile, isTablet, caseItem, filterOpenButton }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const {
    invoices,
    selectedInvoicesIDs,
    createInvoice,
    toggleItemSelection,
    pagination,
    ...invoicesContext
  } = useContext(InvoicesContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const massConfirmInvoices = () => {
    invoicesContext.massConfirmInvoices(selectedInvoicesIDs);
  };

  const massCancelInvoices = () => {
    invoicesContext.massCancelInvoices(selectedInvoicesIDs);
  };

  const massDeleteInvoices = () => {
    invoicesContext.massDeleteInvoices(selectedInvoicesIDs);
  };

  const openCreateInvoiceModal = () => {
    openModal(InvoiceModal, {
      payload: {
        model: caseItem,
        createInvoice
      }
    });
  };

  const handleDownloadPDF = () => {
    if (selectedInvoicesIDs.length > 0) {
      selectedInvoicesIDs.forEach((id) => {
        const name = invoices.find((item) => item.id === id);

        invoicesApi.downloadInvoicePDF(id).then((data) => {
          const url = window.URL.createObjectURL(new Blob([ data ]));

          saveAs(url, `${name.number}.pdf`);
          toggleItemSelection(id);
        });
      });
    }
  };

  const handlePrintInvoice = (event) => {
    event.preventDefault();

    if (selectedInvoicesIDs.length > 0) {
      if (selectedInvoicesIDs.length === 1) {
        invoicesApi.downloadInvoicePDF(selectedInvoicesIDs[0]).then((data) => {
          const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

          printJS({ printable: url, type: 'pdf' });
        });
      } else {
        enqueueSnackbar('You can print only one invoice, sorry', { variant: 'warning' });
      }
    }
  };

  return (
    <>
      {caseItem ?
        <Fab
          color="primary"
          variant="extended"
          startIcon={<AddIcon />}
          onClick={openCreateInvoiceModal}
        >
          Create invoice
        </Fab>
        :
        <Fab
          color="primary"
          variant="extended"
          startIcon={<AddIcon />}
          component={Link}
          to={{
            pathname: '/billing/invoices/create',
            state: {
              caseItem: caseItem || null
            }
          }}
        >
          Create invoice
        </Fab>
      }

      <Box display="flex" alignItems="center" flexWrap={isMobile ? 'wrap' : 'no-wrap'}>
        {!!selectedInvoicesIDs.length &&
          <>
            <SelectedItemsCount length={selectedInvoicesIDs.length} total={pagination.total} />

            {!isMobile && <VerticalDivider verticalGutters={1} horizontalGutters={1} />}

            <Box clone mx={1}>
              <Fab
                color="primary"
                variant="extended"
                onClick={massConfirmInvoices}
              >
                Confirm
              </Fab>
            </Box>

            <Button
              color="primary"
              onClick={massCancelInvoices}
            >
              Cancel
            </Button>

            {!isMobile && <VerticalDivider verticalGutters={1} horizontalGutters={2} />}

            <Tooltip title="Print selected invoice">
              <IconButton onClick={handlePrintInvoice}>
                <PrintIcon/>
              </IconButton>
            </Tooltip>

            <Tooltip title="Download selected invoices">
              <IconButton onClick={handleDownloadPDF}>
                <DownloadIcon/>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete selected invoices">
              <IconButton onClick={massDeleteInvoices}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            {!isMobile && <VerticalDivider verticalGutters={1} horizontalGutters={2} />}
          </>
        }

        {isTablet && filterOpenButton }

        {!isMobile &&
          <>
            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              title="List view"
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon/>
            </IconButton>

            <IconButton
              color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
              title="Grid view"
              onClick={toggleViewVariant(viewVariantsMap.grid)}
            >
              <ViewModuleIcon/>
            </IconButton>
          </>
        }
      </Box>
    </>
  );
};
