import { useContext, useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import {
  List as MuiList,
  makeStyles,
  Typography
} from '@material-ui/core';
import { getElementCoords } from '../../../../../../helpers/dom/getElementCoords';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../components/Loader';
import { minRowHeight } from '../../../Invoices/InvoiceItem/Attachments/List';
import { FilesContext } from '../../../FilesContext';
import * as types from '../../../FilesContext/types';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FilesList = ({ payment }) => {
  const classes = useStyles();
  const {
    isFetched,
    isFetching,
    loadNextPage,
    filter: { per_page, last_page, page },
    resetFiles,

    ...filesContext
  } = useContext(FilesContext);
  const filesLastGlobalAction = useSelector(({ globalActions }) => globalActions.filesLastGlobalAction);
  const [ files, setFiles ] = useState([]);
  const scrollElementRef = useRef();
  const virtualListRef = useRef();
  const [ scrollTop, setScrollTop ] = useState(0);

  const handleScroll = ({ target: { scrollTop } }) => {
    if (virtualListRef.current) {
      const scrollerTopOffset = getElementCoords(scrollElementRef.current).top;
      const listTopOffset = getElementCoords(virtualListRef.current.Grid._scrollingContainer).top;
      const listToScrollerOffset = listTopOffset - scrollerTopOffset;

      setScrollTop(scrollTop - listToScrollerOffset);
    }
  };

  useEffect(() => {
    resetFiles();
  }, []);

  useEffect(() => {
    isFetched && setFiles(filesContext.files);
  }, [ isFetched, filesContext.files.length ]);

  useEffect(() => {
    if (filesLastGlobalAction) {
      if (filesLastGlobalAction.type === types.FILE_UPLOAD_SUCCESS) {
        setFiles([ ...files, filesLastGlobalAction.payload ]);
      }

      if (filesLastGlobalAction.type === types.DELETE_FILES) {
        setFiles(files.filter(({ id }) => !filesLastGlobalAction.payload.includes(id)));
      }
    }
  }, [ filesLastGlobalAction ]);

  return (
    <Scrollbars onScroll={handleScroll}>
      <div ref={scrollElementRef} className={classes.root}>
        <Loader loading={!isFetched && files.length === 0} className={classes.loader} render={
          () => (
            <>
              {!files.length &&
                <Typography align="center" className={classes.title}>Files not found</Typography>
              }

              {!!files.length &&
                <MuiList disablePadding className={classes.list}>
                  <InfiniteListLoader
                    items={files}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    pagination={{ per_page, last_page, page }}
                    RowComponent={Row}
                    RowProps={{ payment }}
                    minRowHeight={minRowHeight}
                    scrollElementRef={scrollElementRef}
                    virtualListRef={virtualListRef}
                    scrollTop={scrollTop}
                    onNeedNextPage={loadNextPage}
                  />
                </MuiList>
              }
            </>
          )}
        />
      </div>
    </Scrollbars>
  );
};
