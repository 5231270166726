import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import {
  downloadFile as download,
  isPrintable,
  printFile,
  isConvertable,
  convertFile
} from '../../helpers/files';
import { menuOptionsTypesMap } from '../Dashboard/files-common/menuOptionsTypes';

export const getFileMenuOptions = () => (file) => [
  {
    type: menuOptionsTypesMap.menuItem,
    Icon: DownloadIcon,
    label: 'Download',
    onClick: () => {
      download({ src: file.upload_url, name: file.original_filename });
    }
  },
  {
    isHidden: !isConvertable(file),
    type: menuOptionsTypesMap.menuItem,
    Icon: PictureAsPdfIcon,
    label: 'Download as PDF',
    onClick: () => {
      convertFile(file);
    }
  },
  {
    isHidden: !isPrintable(file),
    type: menuOptionsTypesMap.menuItem,
    Icon: PrintIcon,
    label: 'Print file',
    onClick: () => {
      printFile(file);
    }
  }
];
