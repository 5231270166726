import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { HistoryPreview } from './HistoryPreview';
import { FiltersBar } from './FiltersBar';
import { List } from './List';

export const MedicalHistoryContent = ({ height = '100%' }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <Box display="flex" flexDirection="column" height={isMobile ? height : '100%'} overflow="hidden">
      {!isMobile && <FiltersBar />}

      <Box flexGrow={1} display="flex" alignItems="flex-start">
        <List />
        <HistoryPreview />
      </Box>
    </Box>
  );
};
