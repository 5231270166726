import { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { EmailsActions } from '../../../../app/Dashboard/EmailsPage/EmailsClient/EmailsActions';
import { EmailsList } from '../../../../app/Dashboard/EmailsPage/EmailsClient/EmailsList';
import { MessagesContext } from '../../../../app/Dashboard/EmailsPage/MessagesContext';

export const FolderItem = ({ folder }) => {
  const { applyFilter } = useContext(MessagesContext);

  useEffect(() => {
    applyFilter({ folder_id: folder.id });
  }, [ folder ]);

  return (
    <>
      <EmailsActions disablePaddings disableMessages isOpenSidebar />

      <Box display="flex" flexDirection="column" flexGrow={1} height="100%">
        <EmailsList addEmailView />
      </Box>
    </>
  );
};
