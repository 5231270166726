const previewHeight = 100;

export const styles = ({
  spacing,
  palette: { grey, ...palette },
  shape: { borderRadius },
  typography: { pxToRem, fontWeightMedium },
  transitions
}) => ({
  root: {
    display: 'flex',
    paddingTop: spacing(2.5),

    '& .elastic-highlight': {
      color: palette.darkgreen,
      fontWeight: fontWeightMedium
    }
  },

  preview: {
    position: 'relative',
    flexShrink: 0,
    width: 82,
    height: previewHeight,
    overflow: 'hidden',
    textAlign: 'center',

    '&:hover': {
      borderRadius
    }
  },

  preview__backdrop: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.primary.contrastText,
    background: palette.primary.main,
    opacity: 0,
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      opacity: 1
    }
  },

  preview__image: {
    width: '100%'
  },

  preview__icon: {
    fontSize: pxToRem(48)
  },

  viewingIndicator: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: pxToRem(24),
    height: pxToRem(24),
    background: palette.primary.main,
    borderRadius
  },

  viewingIndicator__icon: {
    fontSize: pxToRem(14),
    color: palette.primary.contrastText
  },

  mainInfo: {
    flexGrow: 1,
    minHeight: previewHeight + spacing(2.5),
    paddingLeft: spacing(2),
    paddingBottom: spacing(2),
    borderBottom: `1px dashed ${grey[300]}`
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing()
  },

  header__icon: {
    fontSize: pxToRem(26),
    marginRight: spacing(0.5)
  },

  header__title: {
    fontWeight: fontWeightMedium
  },

  documentContent: {
    color: grey[500]
  }
});
