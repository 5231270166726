import { Box, Grid, Link, Typography } from '@material-ui/core';
import moment from 'moment';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useContext, useEffect, useState } from 'react';
import * as remindersApi from '../../../../../../api/cases/reminders/case-reminders';
import { CardHeader, CardItem } from '../../../../../Cards';
import { ColorPreview } from '../../../../../ColorPreview';
import { IconButton } from '../../../../../IconButton';
import { Loader } from '../../../../../Loader';
import { useModal } from '../../../../../ModalsProvider';
import { Tooltip } from '../../../../../Tooltip';
import { CaseLink } from '../../../../CaseLink';
import { CaseRemindersContext } from '../../CaseRemindersProvider';
import { EditCaseReminderModal } from '../../EditCaseReminderModal';
import { getReminderGroupTitle } from '../../getReminderGroupTitle';
import { Body } from './Body';

const actionsMap = {
  changeSettings: 'changeSettings',
  toggleActive: 'toggleActive',
  toggleDone: 'toggleDone'
};

export const ReminderCard = ({ item: reminder, recalculateHeight }) => {
  const { openModal } = useModal();
  const { caseId, updateReminder, resetReminders } = useContext(CaseRemindersContext);
  const [ activeActions, setActiveActions ] = useState([]);
  const { groupTitle, shortGroupTitle } = getReminderGroupTitle(reminder?.group);

  const addActionToActive = (action) => {
    setActiveActions((activeActions) => activeActions.concat(action));
  };

  const removeActionFromActive = (removableAction) => {
    setActiveActions((activeActions) => activeActions.filter((action) => action !== removableAction));
  };

  const changeSettings = (name) => () => {
    addActionToActive(actionsMap.changeSettings);

    remindersApi.updateCaseReminderSettings(reminder, {
      [name]: !reminder.settings[name]
    }).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.changeSettings);
    });
  };

  const toggleActive = () => {
    addActionToActive(actionsMap.toggleActive);

    remindersApi.toggleCaseReminderActive(reminder, {
      is_active: !reminder.is_active
    }).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.toggleActive);
    });
  };

  const doneReminder = () => {
    addActionToActive(actionsMap.toggleDone);

    remindersApi.doneCaseReminder(reminder).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.toggleDone);
    });
  };

  const undoneReminder = () => {
    addActionToActive(actionsMap.toggleDone);

    remindersApi.undoneCaseReminder(reminder).then((reminder) => {
      updateReminder(reminder);
    }).finally(() => {
      removeActionFromActive(actionsMap.toggleDone);
    });
  };

  const openReminderPreview = () => {
    openModal(EditCaseReminderModal, {
      payload: {
        caseReminder: reminder
      },
      onModalResolved: () => {
        resetReminders();
      }
    });
  };

  const editReminder = () => {
    openModal(EditCaseReminderModal, {
      payload: {
        caseReminder: reminder
      },
      onModalResolved: () => {
        resetReminders();
      }
    });
  };

  useEffect(() => {
    recalculateHeight();
  }, []);

  return (
    <CardItem disableHover py={1} px={2}>
      <Grid container justify="space-around">
        <IconButton color="info" onClick={editReminder}>
          <EditIcon />
        </IconButton>

        <Loader
          surface
          loading={activeActions.includes(actionsMap.changeSettings)}
          render={() => (
            <IconButton
              color={reminder?.settings?.by_sms ? 'primary' : 'default'}
              onClick={changeSettings('by_sms')}
            >
              <CallIcon />
            </IconButton>
          )}
        />

        <Loader
          surface
          loading={activeActions.includes(actionsMap.changeSettings)}
          render={() => (
            <IconButton
              color={reminder?.settings?.by_email ? 'primary' : 'default'}
              onClick={changeSettings('by_email')}
            >
              <MailOutlineOutlinedIcon />
            </IconButton>
          )}
        />

        <Loader
          surface
          loading={activeActions.includes(actionsMap.toggleActive)}
          render={() => (
            <IconButton
              color={reminder?.is_active ? 'success' : 'warning'}
              onClick={toggleActive}
            >
              {reminder?.is_active ? (
                <NotificationsActiveOutlinedIcon />
              ) : (
                <NotificationsOffOutlinedIcon />
              )}
            </IconButton>
          )}
        />

        <Loader
          surface
          loading={activeActions.includes(actionsMap.toggleDone)}
          render={() => (
            <IconButton
              color={reminder?.done_at ? 'success' : 'warning'}
              onClick={reminder?.done_at ? undoneReminder : doneReminder}
            >
              <CheckCircleOutlinedIcon />
            </IconButton>
          )}
        />
      </Grid>

      <CardHeader
        disableCheckBox
        disableCardMenu
        childrenWidth={12}
      >
        <Grid container spacing={2}>
          {!caseId &&
            <Grid item xs={12}>
              <CaseLink caseItem={reminder.case} />
            </Grid>
          }

          <Grid item xs>
            <Box display="flex" alignItems="center">
              {!!reminder?.group &&
                <Box display="flex" mr={1}>
                  <ColorPreview color={reminder?.group?.color}/>
                </Box>
              }

              <Tooltip title={groupTitle}>
                <Typography>
                  {shortGroupTitle}
                </Typography>
              </Tooltip>
            </Box>
          </Grid>

          <Grid item>
            <Typography variant="subtitle1">
              {reminder?.date ? moment(reminder?.date).format('L') : '-'}
            </Typography>
          </Grid>
        </Grid>
      </CardHeader>

      <Box py={2}>
        <Body reminder={reminder} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="subtitle1">
                {reminder.position || '-'} / {reminder.type}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Position / Event Type
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              {reminder.previousReminder ? (
                <Link variant="subtitle1" onClick={openReminderPreview}>
                  {reminder.previousReminder?.position} {reminder.previousReminder?.type || '-'}
                </Link>
              ) : (
                <Typography variant="subtitle1">
                  No
                </Typography>
              )}
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Relative
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardItem>
  );
};
