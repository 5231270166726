import { useContext, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Cards } from '../../../../../../../../components/Cards';
import { CustomScrollbars } from '../../../../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../../../../components/Loader';
import { CaseDepositionsContext } from '../CaseDepositionsProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    isFetching,
    depositions,
    resetDepositions
  } = useContext(CaseDepositionsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetDepositions();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader loading={isFetching} render={
        () => !depositions.length ? (
          <Box p={3}>
            <Typography align="center">No depositions found</Typography>
          </Box>
        ) : (
          <Cards size={370} list={depositions} component={Card} />
        )}
      />
    </CustomScrollbars>
  );
};
