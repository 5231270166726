import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, Grid, List, makeStyles, Typography } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import NotesIcon from '@material-ui/icons/Notes';
import { TextField } from '../../../../../../../components/FormField';
import { useResizeObserver } from '../../../../../../../helpers/hooks/useResizeObserver';
import { Back, Front } from '../../../../../ProfilePage/MedicalInfo/MedicalForms/BodyPartsModal';
import {
  BodyPartsContextProvider
} from '../../../../../ProfilePage/MedicalInfo/MedicalForms/BodyPartsModal/BodyPartsContext';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointSmall = 750;

export const CurrentSymptoms = ({ disableBodyParts = false, disableBackBody, title, name }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const ref = useRef();
  const { width } = useResizeObserver({ ref });
  const [ isOpen, setIsOpen ] = useState(true);
  const current_symptoms = values?.medical_form?.forms?.current_symptoms ||
    values?.medical_form?.forms?.current_symptoms_pi || {};

  const handleOpenBodyPartsModal = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(width > widthBreakpointSmall);
  }, [ width ]);

  return (
    <Box ref={ref} my={4} overflow="hidden">
      <Typography paragraph variant="h4">
        Current symptoms: {title}
      </Typography>

      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justify={width < widthBreakpointSmall ? 'space-around' : 'space-between'}
      >
        {!disableBodyParts &&
          <>
            <Grid item xs={width < widthBreakpointSmall ? 12 : 'auto'}>
              <div className={classes.contentInfo} onClick={handleOpenBodyPartsModal}>
                <div className={classes.image}/>

                <div className={classes.actions}>
                  <ZoomInIcon color="primary" fontSize="large" />
                </div>
              </div>
            </Grid>

            {isOpen &&
              <Grid item xs={width < widthBreakpointSmall ? 10 : 4}>
                <BodyPartsContextProvider initialPainfulParts={current_symptoms?.where_is_pain}>
                  {!disableBackBody ? <Back disableHover /> : <Front disableHover />}
                </BodyPartsContextProvider>
              </Grid>
            }
          </>
        }

        <Grid item md={!disableBodyParts ? (width < widthBreakpointSmall ? 10 : (isOpen ? 6 : 10)) : 12} xs={12}>
          {!disableBodyParts && !!current_symptoms?.where_is_pain &&
            !!Object.keys(current_symptoms?.where_is_pain).length &&
            <List disablePadding component={Box} display="flex" flexWrap="wrap">
              {Object.keys(current_symptoms?.where_is_pain).map((item, i) => (
                <Row key={i} item={item} stepTitle={current_symptoms?.where_is_pain} />
              ))}
            </List>
          }

          <TextField
            fullWidth
            multiline
            disableUnderline
            rows={2}
            name={`${name}.text`}
            variant="filled"
          />

          <Box mt={3} ml={2}>
            <Typography variant="h5">
              Location:
            </Typography>

            <Box display="flex" alignItems="center">
              <NotesIcon fontSize="small" />

              <Box mx={0.5}>
                <Typography variant="subtitle2" color="textSecondary">
                  Note
                </Typography>
              </Box>

              <TextField
                fullWidth
                name={`${name}.location`}
              />
            </Box>
          </Box>

          <Box mt={3} ml={2}>
            <Typography variant="h5">
              Character of Pain:
            </Typography>

            <Box display="flex" alignItems="center">
              <NotesIcon fontSize="small" />

              <Box mx={0.5}>
                <Typography variant="subtitle2" color="textSecondary">
                  Note
                </Typography>
              </Box>

              <TextField
                fullWidth
                name={`${name}.character_of_pain`}
              />
            </Box>
          </Box>

          <Box mt={3} ml={2}>
            <Typography variant="h5">
              Radiation:
            </Typography>

            <Box display="flex" alignItems="center">
              <NotesIcon fontSize="small" />

              <Box mx={0.5}>
                <Typography variant="subtitle2" color="textSecondary">
                  Note
                </Typography>
              </Box>

              <TextField
                fullWidth
                name={`${name}.radiation`}
              />
            </Box>
          </Box>

          <Box mt={3} ml={2}>
            <Typography variant="h5">
              Weakness:
            </Typography>

            <Box display="flex" alignItems="center">
              <NotesIcon fontSize="small" />

              <Box mx={0.5}>
                <Typography variant="subtitle2" color="textSecondary">
                  Note
                </Typography>
              </Box>

              <TextField
                fullWidth
                name={`${name}.weakness`}
              />
            </Box>
          </Box>

          <Box mt={3} ml={2}>
            <Typography variant="h5">
              Stiffness:
            </Typography>

            <Box display="flex" alignItems="center">
              <NotesIcon fontSize="small" />

              <Box mx={0.5}>
                <Typography variant="subtitle2" color="textSecondary">
                  Note
                </Typography>
              </Box>

              <TextField
                fullWidth
                name={`${name}.stiffness`}
              />
            </Box>
          </Box>

          <Box mt={3} ml={2}>
            <Typography variant="h5">
              Tingling and Numbness:
            </Typography>

            <Box display="flex" alignItems="center">
              <NotesIcon fontSize="small" />

              <Box mx={0.5}>
                <Typography variant="subtitle2" color="textSecondary">
                  Note
                </Typography>
              </Box>

              <TextField
                fullWidth
                name={`${name}.tingling_and_numbness`}
              />
            </Box>
          </Box>

          <Box mt={3} ml={2}>
            <Typography variant="h5">
              <Box component="span" mr={0.5}>
                <NotesIcon fontSize="small" />
              </Box>
              Note
            </Typography>

            <TextField
              fullWidth
              multiline
              rows={2}
              rowsMax={4}
              name={`${name}.note`}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
