import { flow } from 'lodash';
import { useContext } from 'react';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { AppointmentEditModal } from '../../../../../components/appointments';
import { useModal } from '../../../../../components/ModalsProvider';
import { EditTestModal } from '../EditTestModal';
import { TestPreviewModal } from '../TestPreviewModal';
import { TestsContext } from '../TestsProvider';

export const TestsMenu = ({ test, onClose }) => {
  const { openModal } = useModal();
  const { fetchTestReports, updateTestReport, deleteTestReport } = useContext(TestsContext);

  const handleDeleteMRI = () => {
    deleteTestReport(test.id);
  };

  const openPreview = () => {
    openModal(TestPreviewModal, {
      payload: {
        testId: test.id,
        onTestUpdate: updateTestReport,
        onListUpdate: fetchTestReports
      }
    });
  };

  const handleUpdateTest = () => {
    openModal(EditTestModal, {
      payload: {
        id: test.id,
        onUpdate: updateTestReport
      },
      onModalResolved: (data) => {
        updateTestReport(data);
      }
    });
  };

  const handleUpdateAppointment = () => {
    openModal(AppointmentEditModal, {
      payload: {
        disableAttachments: true,
        appointmentID: test.appointment_id
      },
      onModalResolved: () => {
        fetchTestReports();
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={flow(openPreview, onClose)}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <>
          <MenuItem onClick={flow(handleUpdateTest, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit Test" />
          </MenuItem>

          <MenuItem disabled={!test.appointment_id} onClick={flow(handleUpdateAppointment, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit appointment" />
          </MenuItem>

          <MenuItem onClick={flow(handleDeleteMRI, onClose)}>
            <ListItemIcon>
              <DeleteIcon color="error" fontSize="inherit"/>
            </ListItemIcon>

            <ListItemText primary="Delete" />
          </MenuItem>
        </>
      }
    </List>
  );
};
