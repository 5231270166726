export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%'
  },

  loader: {
    padding: spacing(2)
  },

  title: {
    paddingTop: spacing(3)
  }
});
