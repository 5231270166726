export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: spacing()
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },

  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center'
  },

  header__content: {
    marginLeft: spacing(0.5),

    '&:first-child': {
      marginLeft: 0
    }
  }
});
