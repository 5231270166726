import moment from 'moment';
import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required().min(2).max(36),
  last_name: Yup.string().nullable().required().min(2).max(36),
  birthday: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment(), 'Birthday must be at earlier than now')
    .required(),
  phone_number: Yup.string().nullable().notRequired().min(2).max(20),
  fax: Yup.string().nullable().notRequired().min(2).max(20),
  mobile_phone_number: Yup.string().nullable().notRequired().min(2).max(20),
  gender: Yup.string().nullable().required(),
  email: Yup.string().nullable().email().required(),
  role: Yup.string().nullable().required(),
  is_active: Yup.bool().nullable().required(),
  ssn: Yup.string().nullable().min(1).max(255),
  home: Yup.object().shape({
    street: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    zip: Yup.string().nullable(),
    country: Yup.string().nullable(),
    note: Yup.string().nullable().min(3).max(5000),
    designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
      return unitNumber ? schema.required() : schema;
    }),
    unit_number: Yup.string().nullable().min(1).max(255)
  }),
  work: Yup.object().shape({
    company_id: Yup.mixed().nullable(),
    office_id: Yup.mixed().nullable(),
    position: Yup.string().nullable(),
    department: Yup.string().nullable()
  })
});
