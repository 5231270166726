import { Line } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';

export const Chart = ({ statistics }) => {
  const { palette } = useTheme();
  const { labels, data } = statistics;
  const total = data.map((item) => item.total);
  const paid = data.map((item) => item.paid);

  const getChartData = () => ({
    labels,
    datasets: [
      {
        label: 'open',
        data: total,
        lineTension: 0.1,
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        backgroundColor: 'rgba(0, 188, 212, 0.3)',
        borderColor: '#00BCD4',
        pointBorderColor: '#00BCD4',
        pointHoverBackgroundColor: '#00BCD4',
        pointHoverBorderColor: '#00BCD4',
        pointRadius: 1,
        pointHitRadius: 10
      },
      {
        label: 'paid',
        data: paid,
        lineTension: 0.1,
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        backgroundColor: 'rgba(72, 210, 160, 0.3)',
        borderColor: palette.lightgreen,
        pointBorderColor: palette.lightgreen,
        pointHoverBackgroundColor: palette.lightgreen,
        pointHoverBorderColor: palette.lightgreen,
        pointRadius: 1,
        pointHitRadius: 10
      }
    ]
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [ {
        ticks: {
          beginAtZero: true,
          callback: (label) => ' $ ' + new Intl.NumberFormat('en-US').format(label)
        },
        gridLines: {
          borderDash: [ 2, 3 ]
        }
      } ],
      xAxes: [ {
        gridLines: {
          drawOnChartArea: false,
          borderDash: [ 2, 3 ]
        },
        ticks: {
          beginAtZero: true
        }
      } ]
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem) => ' $ ' + new Intl.NumberFormat('en-US').format(tooltipItem.value)
      }
    }
  };

  return (
    <Line data={getChartData} options={chartOptions} />
  );
};
