import { makeStyles, MenuItem } from '@material-ui/core';

const styles = () => ({
  root: {
    whiteSpace: 'normal'
  }
});

const useStyles = makeStyles(styles);

export const Option = (props) => {
  const classes = useStyles();

  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isSelected}
      className={classes.root}

      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};
