import { api } from '../api';

export const fetchCallReports = (config = {}) => {
  return api.get('/sip/reports', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const generateCallReport = (config = {}) => {
  return api.get('/sip/reports/export', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const generateGeneralCallReport = (config = {}) => {
  return api.get('sip/reports/export/general', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const attachParent = ({ id, ...data }, config) => {
  return api.post(`/sip/reports/${id}/parent`, data, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const multipleAttachParent = (data, config) => {
  return api.post('/sip/reports/parent/attach', data, config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const dettachParent = (data, config) => {
  return api.post('/sip/reports/parent/detach', data, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
