import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { TaskPreviewContextProvider } from '../../../../components/tasks/TasksProvider';
import { Body } from './Body';

export const TaskModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  isSubmitting,
  payload: {
    id,
    deleteTask,
    taskData,
    isEditable = false
  }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  return (
    <Dialog disableEnforceFocus fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <TaskPreviewContextProvider selectedTaskId={id || taskData.id}>
        <Body
          isEditable={isEditable}
          isSubmitting={isSubmitting}
          handleModalReject={handleModalReject}
          handleModalResolve={handleModalResolve}
          deleteTask={deleteTask}
        />
      </TaskPreviewContextProvider>
    </Dialog>
  );
};
