import { fade } from '@material-ui/core/styles';

export const styles = ({ palette }) => ({
  switchBase: ({ color }) => !palette[color] ? {} : ({
    '&.Mui-checked':{
      color: palette[color].main,

      '&:hover': {
        backgroundColor: fade(palette[color].main, palette.action.hoverOpacity)
      }
    },

    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: palette[color].main
    }
  })
});
