import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { get } from 'lodash';
import { makeStyles, Button, IconButton, SvgIcon, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiAccountEditOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { dataURItoBlob } from '../../../helpers/files';
import { getUserFullName } from '../../../helpers/users';
import { usersRolesOptions } from '../../../dataMaps';
import * as usersApi from '../../../api/users';
import { transformProviderIdentifierToOption } from '../../FormField';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../Page';
import { VerticalDivider } from '../../VerticalDivider';
import { Loader } from '../../Loader';
import { gendersMap, statusMap } from '../gendersMap';
import { validationSchema } from './validationSchema';
import { providerTypeMap } from './providerTypeMap';
import { CompanyInfo } from './CompanyInfo';
import { Contacts } from './Contacts';
import { UserInfo } from './UserInfo';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointSmall = 562;

export const EditUserPage = ({ user, onUserUpdate }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [ avatarDataURL, setAvatarDataURL ] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onUserInfoUpdate = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (![ rolesMap.medic, rolesMap.doctor ].includes(values.role)) {
      values.provider_id_types = null;
      values.work.pqrs_category = null;
      values.work.provider_type = null;
    }

    const tags = [];

    if (values?.driver_license?.front?.url && !values?.front) {
      tags?.push('driver_license_front');
    }

    if (values?.driver_license?.back?.url && !values?.back) {
      tags?.push('driver_license_back');
    }

    values.tags = tags;

    if (avatarDataURL) {
      const formData = new FormData();

      formData.append('file', dataURItoBlob(avatarDataURL));

      usersApi.uploadAvatar(formData).then((avatar) => {
        values.avatar_id = avatar.id;

        onUserUpdate(values, setSubmitting, setErrors);
      }).catch(({ data: { errors } }) => {
        if (errors) {
          enqueueSnackbar('Avatar not uploaded', { variant: 'error' });
          setSubmitting(false);
        }
      });
    } else {
      onUserUpdate(values, setSubmitting, setErrors);
    }
  };

  return (
    <Formik
      initialValues={{
        ...user,
        rate: user.rate || 1,
        gender: user.gender && gendersMap.find(({ value }) => value === user.gender),
        is_active: statusMap.find(({ value }) => value === user.is_active),
        role: user.role && usersRolesOptions.find(({ value }) => value === user.role),
        home: {
          ...user.home,
          mailbox: null
        },
        work: {
          ...user.work,
          ...get(user, 'work.company'),

          company_id: user?.work?.company,
          locations: user?.work?.locations?.map(({ id }) => id),
          office_id: user?.work?.company?.id ? user?.work?.offices : null,
          provider_type: providerTypeMap.find(({ value }) => value === user?.work?.provider_type),
          contact_user: getUserFullName(user?.work?.contact_user || user?.work?.company?.contact_user)
        },
        provider_id_types: (user.role === rolesMap.doctor && user?.provider_id_types?.map((item) => ({
          ...item,
          provider_type_id: transformProviderIdentifierToOption(item?.provider_type)
        }))) || [],
        back: user?.driver_license?.back?.url || null,
        front: user?.driver_license?.front?.url || null
      }}
      validationSchema={validationSchema}
      onSubmit={onUserInfoUpdate}
    >
      {({ isSubmitting, handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit} className={classes.root}>
          <Page variant={isMobile ? 'elevation' : 'outlined'}>
            <PageHeader>
              <IconButton
                color="primary"
                edge="start"
                size={isMobile ? 'small' : 'medium'}
                onClick={history.goBack}
              >
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>

              <PageHeaderTitle>
                <Box clone mr={1}>
                  <SvgIcon><Icon path={mdiAccountEditOutline} /></SvgIcon>
                </Box>

                Edit User
              </PageHeaderTitle>

              <PageHeaderActions>
                <Button
                  color="primary"
                  size={isMobile ? 'small' : 'medium'}
                  onClick={history.goBack}
                >
                  Cancel
                </Button>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      size={isMobile ? 'small' : 'medium'}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />
              </PageHeaderActions>
            </PageHeader>

            <PageBody fullHeight disablePaddings className={classes.mainContent}>
              <Box display="flex" flexDirection={!isMobile ? 'row' : 'column'} flexGrow={1}>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  flexDirection={!isMobile ? 'row' : 'column'}
                  width={isMobile ? '100%' : '50%'}
                  py={isMobile ? 2 : 3}
                  pl={isMobile ? 2 : 4}
                  pr={isMobile ? 2.5 : 3}
                >
                  <UserInfo onAvatarUrlChange={setAvatarDataURL} />
                  <Contacts />
                </Box>

                <VerticalDivider />
                <CompanyInfo user={user} />
              </Box>
            </PageBody>
          </Page>
        </form>
      )}
    </Formik>
  );
};
