import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Page, PageBody, PageHeader } from '../../../../../../components/Page';
import { useModal } from '../../../../../../components/ModalsProvider';
import { ActionsBar } from '../../../ActionsBar';
import { CreateTemplateReport } from '../CreateTemplateReport';
import { TemplatesContext } from '../TemplatesProvider';
import { Grid } from './Grid';
import { List } from './List';

export const MainContent = () => {
  const { selectedIDs, pagination, massDeleteItems } = useContext(TemplatesContext);
  const { openModal } = useModal();
  const params = useParams();
  const profile = useSelector(({ profile }) => profile);
  const usersInfo = useSelector(({ globalUser }) => globalUser?.data?.users) || [];
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const userInfo = !!params?.patientID ? usersInfo.find(({ id }) => id === +params?.patientID) : null;
  const birthdayDate = params?.patientID ? userInfo?.birthday || profile?.user?.birthday : null;

  const handleCreateTemplateReport = () => {
    openModal(CreateTemplateReport, {
      payload: {
        age: !!birthdayDate ? moment().diff(birthdayDate, 'years') : null
      }
    });
  };

  const handleDeleteTemplates = () => {
    massDeleteItems(selectedIDs);
  };

  return (
    <Page>
      <PageHeader>
        <ActionsBar
          disableViewVariant
          actionTitle="Create new template"
          selectedIDs={selectedIDs}
          pagination={pagination}
          onCreate={handleCreateTemplateReport}
          onDelete={handleDeleteTemplates}
        />
      </PageHeader>

      <PageBody fullHeight disablePaddings disableScrollbars>
        {isTablet ? <Grid /> : <List />}
      </PageBody>
    </Page>
  );
};
