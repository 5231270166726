import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { ReadonlyCheckbox } from '../../../../../../components/ReadonlyCheckbox';
import { AddressLink } from '../../../../../../components/AddressLink';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { Contact } from '../../../../../../components/Contact';
import { Divider } from '../../../../../../components/Divider';
import { Members } from '../../../../../../components/Members';

export const Body = ({ office }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box px={isMobile ? 0 : 6}>
      <Grid container spacing={2} component={Box} py={2} justify="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Company Name
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <CompanyLink
            variant="h5"
            company={office?.company}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Country
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {office.country}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Address
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <AddressLink variant="h5" address={office.full_address}>
            <Box display="flex" alignItems="flex-start">
              <Box clone mr={1}>
                <LocationOnIcon />
              </Box>

              {office.full_address || '-'}
            </Box>
          </AddressLink>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Billing Address
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <AddressLink variant="h5" address={office?.billing?.full_address}>
            <Box display="flex" alignItems="flex-start">
              <Box clone mr={1}>
                <LocationOnIcon />
              </Box>

              {office?.billing?.full_address || '-'}
            </Box>
          </AddressLink>
        </Grid>
      </Grid>

      <Grid container spacing={2} justify="space-between" alignItems="flex-start">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Venue
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {!office.venue?.length ? (
            <Typography color="textSecondary">No info</Typography>
          ) : office.venue.map((item, i) => (
            <Box key={i} mb={1.5}>
              <Grid container spacing={1} wrap="nowrap">
                {(+item.is_main > 0) &&
                  <Grid item>
                    <ReadonlyCheckbox checked disableTitle />
                  </Grid>
                }

                <Grid item>
                  <Typography variant="h5">
                    {i + 1}.
                  </Typography>
                </Grid>

                <Grid item>
                  <AddressLink variant="h5" address={item.full_address}>
                    {item.full_address || '-'}
                  </AddressLink>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Office contacts
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Members
            space={0.5}
            placement="bottom"
            users={office.users || []}
          />
        </Grid>
      </Grid>

      <Divider disableBottomGutter gutter={2} />

      <Grid container spacing={2} component={Box} py={2} justify="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Phone
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={office.phone}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Fax
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={office.fax}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Mob number
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="tel"
            contact={office.mobile_phone}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Timezone
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {office.timezone || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={2} component={Box} pt={2} pb={4} justify="space-between" alignItems="center">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Email
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            needIcon
            noWrap
            variant="h5"
            type="mailto"
            contact={office.email}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
