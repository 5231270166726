import { useContext, useEffect } from 'react';
import filesize from 'filesize';
import cn from 'classnames';
import { makeStyles, IconButton, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Date } from '../../../../../../components/Date';
import { Popper } from '../../../../../../components/Popper';
import { Loader } from '../../../../../../components/Loader';
import { Tag } from '../../../../../../components/Tag';
import { FilePreviewIcon } from '../../../FilePreviewIcon';
import { FilesContext } from '../../../FilesContext';
import { FileOwner } from '../../../FileOwner';
import { FileMenu } from '../../../FileMenu';
import { FilePreviewContext } from '../../FilePreviewContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item: file = {}, isLoaded, recalculateHeight, listItemMenuOptions, getFileMenuOptions }) => {
  const { selectedFile = {}, handleFileSelect } = useContext(FilePreviewContext);
  const isSelected = !!file && selectedFile?.id === file.id;
  const classes = useStyles();
  const menuOptions = getFileMenuOptions(file) || listItemMenuOptions;
  const menuList = menuOptions?.filter(({ isHidden }) => !isHidden);

  useContext(FilesContext);

  const openFileMenu = (callback) => (event) => {
    event.stopPropagation();

    callback(event);
  };

  const selectFile = () => {
    handleFileSelect(file);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, file.tags ]);

  return (
    <div className={cn(classes.root, { [classes.root_selected]: isSelected })} onClick={selectFile}>
      <div className={classes.main}>
        {!isLoaded ? <Loader /> :
          <>
            <div className={classes.preview}>
              <FilePreviewIcon file={file} className={classes.preview__picture} />

              <Typography className={classes.preview__fileSize}>
                {filesize(file.size)}
              </Typography>
            </div>

            <div className={classes.mainInfo}>
              <Typography noWrap className={classes.mainInfo__name} title={file.original_filename}>
                {file.original_filename || file.file_name}
              </Typography>

              <Date disableIcon component="span" date={file.created_at} color="textSecondary" />

              {file.owner_type && file.owner &&
                <FileOwner owner_type={file.owner_type} owner={file.owner}/>
              }
            </div>

            {!!menuList?.length &&
              <Popper
                placement="left-start"
                anchorRenderer={
                  ({ anchorRef, handleToggle }) => (
                    <IconButton buttonRef={anchorRef} onClick={openFileMenu(handleToggle)}>
                      <MoreVertIcon/>
                    </IconButton>
                  )
                }
              >
                {({ handleClose }) => (
                  <FileMenu
                    file={file}
                    menuList={menuList}
                    onClose={handleClose}
                  />
                )}
              </Popper>
            }
          </>
        }
      </div>

      {file.tags &&
        <div className={classes.tags}>
          {file.tags && file.tags.map((tag) => (
            <Tag key={tag.id} tag={tag} className={classes.tags__tag} />
          ))}
        </div>
      }
    </div>
  );
};
