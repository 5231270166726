import { Grid, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { CompanyLink } from '../../../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { Contact } from '../../../../../../../../components/Contact';
import { PreviewCompanyModal } from '../../../../../../Contacts/Companies/PreviewCompanyModal';

export const SubstitutedInContent = ({ substitutedInfo }) => {
  const { openModal } = useModal();

  const openCompanyPreview = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: substitutedInfo?.id }
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Typography color="textSecondary">Firm name</Typography>
      </Grid>

      <Grid item xs={8}>
        <CompanyLink
          size="sm"
          variant="h5"
          company={substitutedInfo}
          onClick={stopPropagation(openCompanyPreview)}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Address</Typography>
      </Grid>

      <Grid item xs={8}>
        {substitutedInfo?.full_address
          ? <Typography variant="h5">{substitutedInfo?.full_address}</Typography>
          : <Typography color="textSecondary">No info.</Typography>
        }
      </Grid>

      <Grid item xs={4}>
        <Typography color="textSecondary">Phone Number</Typography>
      </Grid>

      <Grid item xs={8}>
        <Contact
          needIcon
          variant="h5"
          type="tel"
          contact={substitutedInfo?.phone}
        />
      </Grid>
    </Grid>
  );
};
