import { Box, Fab, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import { rolesMap } from '../../../../../../../../../dataMaps/rolesMap';
import { rolesMap as caseRolesMap } from '../../../../../../../../../dataMaps';
import { hasRole } from '../../../../../../../../../utils/hasRole';
import { MembersModal } from '../../../../../MembersModal';
import { Item } from './Item';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ item, caseItem, onCaseItemUpdate }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const usersList = caseItem?.case_users?.filter(({ case_role }) => case_role === item.value);

  const openMembersModal = () => {
    openModal(MembersModal, {
      payload: {
        role: item,
        caseItem,
        onCaseItemUpdate
      }
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Box display="flex" flexGrow={1} alignItems="center">
          <Typography variant="h3" color="primary">{item?.label}</Typography>

          {usersList?.length > 1 &&
            <Typography className={classes.header__counter}>
              {usersList.length}
            </Typography>
          }
        </Box>

        {item?.value !== caseRolesMap.creator && !hasRole(rolesMap.patient, rolesMap.client) && (
          <Box pr={2}>
            <Fab
              color="primary"
              size="small"
              onClick={openMembersModal}
            >
              <AddIcon fontSize="small" />
            </Fab>
          </Box>
        )}
      </div>

      <div className={classes.mainContent}>
        {usersList?.map((item) => (
          <Item
            key={item.id}
            user={item}
            caseItem={caseItem}
            onCaseItemUpdate={onCaseItemUpdate}
          />
        ))}
      </div>
    </div>
  );
};
