import { Fragment } from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { stopPropagation } from '../../helpers/dom';

export const Tooltip = ({ isExistTooltip = true, title, children, onClick = () => {}, ...props }) => {
  const isVisible = isExistTooltip && title;
  const Wrapper = isVisible ? MuiTooltip : Fragment;
  const wrapperProps = isVisible ? {
    interactive: true,
    arrow: true,
    title,
    PopperProps: {
      onClick: stopPropagation(onClick)
    },

    ...props
  } : {};

  return (
    <Wrapper {...wrapperProps}>
      {children}
    </Wrapper>
  );
};
