import { fade } from '@material-ui/core/styles';

export const styles = ({ palette, transitions }) => ({
  root: {
    display: 'flex',
    height: '100%',

    '& .fc': {
      height: '100%'
    },

    '& .fc .fc-col-header-cell.fc-resource': {
      verticalAlign: 'middle'
    },

    '& .fc .fc-col-header-cell-cushion': {
      display: 'block',
      width: '100%'
    },

    '& .fc-day, & .fc-day.fc-day-today': {
      background: 'none'
    },

    '& .fc-day-today .fc-daygrid-day-frame': {
      borderTop: `4px solid ${palette.primary.main}`
    },

    '& .fc-day-past': {
      background: fade(palette.grey[200], 0.25)
    },

    '& .fc-event-main': {
      display: 'flex'
    }
  },

  day: {
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      boxShadow: `inset 0 0 0 1px ${palette.primary.main}`
    }
  },

  day_selected: {
    '$root &.fc-day': {
      background: fade(palette.primary.light, 0.2),
      boxShadow: `inset 0 0 0 1px ${palette.primary.main}`
    }
  },

  content: {
    position: 'relative',
    flexGrow: 1,
    height: '100%',
    transition: transitions.create()
  },

  content_loading: {
    opacity: 0.6,
    filter: 'blur(3px)'
  },

  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center'
  }
});
