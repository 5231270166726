export const styles = ({ palette }) => ({
  signature: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 165,
    background: palette.grey[50]
  },

  signaturePreviewIcon: {
    fontSize: 80
  },

  loader: {
    minHeight: 165
  }
});
