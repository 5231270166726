import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@mdi/react';
import { useSnackbar } from 'notistack';
import { flow } from 'lodash';
import { mdiAccountRemoveOutline, mdiFileReplaceOutline } from '@mdi/js';
import { List, ListItem, ListItemIcon, ListItemText, SvgIcon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import * as accessPatientsApi from '../../../../../api/profile/access-patients';
import { useChatActions } from '../../../../../utils/useChatActions';
import { useModal } from '../../../../../components/ModalsProvider';
import ChatSquaredSvg from '../../../../../components/icons/chat-squared.svg';
import { AccessPatientsContext } from '../PatientsProvider';
import { ReplacePatientModal } from '../ReplacePatientModal';

export const PatientsMenu = ({ item, onClose }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { openChatWithUser } = useChatActions();
  const { updatePatient, deletePatients } = useContext(AccessPatientsContext);

  const handleUpdateUser = (data) => {
    accessPatientsApi.updateAccessPatient(data).then(({ data }) => {
      updatePatient(data);
      enqueueSnackbar('Patient successfully updated!', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Something goes wrong!', { variant: 'error' });
    });
  };

  const changeUserAccess = () => {
    handleUpdateUser({ ...item, is_active: !item?.is_active });
  };

  const handleUserReplace = () => {
    openModal(ReplacePatientModal, {
      payload: {
        user: item?.patient
      },
      onModalResolved: ({ user_id }) => {
        handleUpdateUser({ ...item, user_id });
        deletePatients([ item?.id ]);
      }
    });
  };

  const removeUserWithAccess = () => {
    accessPatientsApi.removeAccessPatient(item?.id).then(() => {
      deletePatients([ item?.id ]);
      enqueueSnackbar('Lawyer removed!', { variant: 'success' });
    });
  };

  const startChatWithUser = () => {
    openChatWithUser(item?.patient_id);
  };

  return (
    <List>
      <ListItem button component={Link} to={`/patients/${item.patient.id}`}>
        <ListItemIcon>
          <VisibilityOutlinedIcon />
        </ListItemIcon>

        <ListItemText primary="View" />
      </ListItem>

      <ListItem button onClick={flow(startChatWithUser, onClose)}>
        <ListItemIcon>
          <SvgIcon><ChatSquaredSvg /></SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Send Message" />
      </ListItem>

      <ListItem button onClick={flow(handleUserReplace, onClose)}>
        <ListItemIcon>
          <SvgIcon>
            <Icon path={mdiFileReplaceOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Forward patient" />
      </ListItem>

      <ListItem button onClick={flow(changeUserAccess, onClose)}>
        <ListItemIcon>
          <SvgIcon>
            <Icon path={mdiAccountRemoveOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary={(item?.is_active ? 'Disconnect' : 'Connect') + ' patient'} />
      </ListItem>

      <ListItem button onClick={flow(removeUserWithAccess, onClose)}>
        <ListItemIcon>
          <CloseIcon />
        </ListItemIcon>

        <ListItemText primary="Remove patient" />
      </ListItem>
    </List>
  );
};
