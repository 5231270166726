import { useContext } from 'react';
import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { Checkbox } from '../../../../../../../../components/FormField/Checkbox';
import { SchedulesContext } from '../../SchedulesContext';
import { columnsWidths } from '../MainContent';

export const TableHeader = () => {
  const { selectedSchedulesIDs, allSchedulesIsSelected, toggleAllSchedulesSelection } = useContext(SchedulesContext);

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell flexBasis={columnsWidths.checkbox}>
          <Checkbox
            onClick={toggleAllSchedulesSelection}
            checked={allSchedulesIsSelected()}
            indeterminate={!!selectedSchedulesIDs.length && !allSchedulesIsSelected()}
          />
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.payer}>
          Payer
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.charge_amount}>
          Charge amount
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.expected_amount}>
          Expected amount
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
