import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getElementCoords } from '../../helpers/dom';

export const StickyFallback = ({ children, containerElement = document.body }) => {
  const [ isSticky, setIsSticky ] = useState(false);
  const ref = useRef();
  const wrapperStyle = useMemo(() => isSticky ? ({ height: ref.current.offsetHeight }) : {}, [ isSticky ]);
  const lastNormalPosition = useRef(0);
  const getStickyStyle = useCallback(() => isSticky ? ({
    position: 'fixed',
    // To overlap elements such as MuiBadge
    zIndex: 2,
    top: getElementCoords(containerElement).top,
    left: getElementCoords(ref.current).left,
    width: ref.current.offsetWidth,
    height: ref.current.offsetHeight
  }) : {}, [ isSticky, containerElement.width ]);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky((isSticky) => {
        if (isSticky) {
          return containerElement.scrollTop >= lastNormalPosition.current;
        }

        lastNormalPosition.current = containerElement.scrollTop;

        return (getElementCoords(ref.current).top) <= getElementCoords(containerElement).top;
      });
    };

    containerElement.addEventListener('scroll', handleScroll);

    return () => {
      containerElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={wrapperStyle}>
      <div ref={ref} style={getStickyStyle()}>
        {children}
      </div>
    </div>
  );
};
