export const styles = ({ palette: { grey } }) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${grey[400]}`,

    '& .cke': {
      height: '100%',
      border: 0
    },

    '& .cke_top': {
      background: 'none',
      paddingLeft: 14,
      paddingRight: 14
    },

    '& .cke_inner': {
      height: '100%',
      display: 'flex !important',
      flexDirection: 'column'
    },

    '& .cke_contents': {
      flexGrow: 1,
      height: 'auto !important'
    },

    '& .cke_bottom': {
      display: 'none'
    }
  }
});
