import { makeStyles, Box, Grid } from '@material-ui/core';
import { DurableMedicalEquipment } from './DurableMedicalEquipment';
import { Indicators } from './Indicators';
import { Lab } from './Lab';
import { NationalDrugCode } from './NationalDrugCode';

const useStyles = makeStyles(({ palette }) => ({
  borderBottom: {
    borderBottom: `1px solid ${palette.divider}`
  },

  borderRight: {
    borderRight: `1px solid ${palette.divider}`
  }
}));

export const ServiceLineForm = ({ claim }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={6} className={classes.borderRight}>
        <Grid container>
          <Grid item xs={12} className={classes.borderBottom} component={Box} p={4}>
            <Indicators claim={claim} />
          </Grid>

          <Grid item xs={12} component={Box} p={4}>
            <NationalDrugCode />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container>
          <Grid item xs={12} className={classes.borderBottom} component={Box} p={4}>
            <DurableMedicalEquipment />
          </Grid>

          <Grid item xs={12} component={Box} p={4}>
            <Lab />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
