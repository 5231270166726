import { Box, Chip } from '@material-ui/core';
import { ColorPreview } from '../../ColorPreview';
import { Autocomplete } from '../../FormField';
import { arrivalTypesOptions } from '../arrivalTypes';

export const ArrivalTypesSelect = (props) => {
  return (
    <Autocomplete
      placeholder="Search type..."
      options={arrivalTypesOptions}
      getInputProps={(value) => !(!props.multiple && value) ? null : ({
        startAdornment: (!props.multiple && value) && (
          <ColorPreview color={value.color} />
        )
      })}
      getOptionLabel={(type) => type?.name}
      getOptionValue={(type) => type?.type}
      getOptionSelected={(option, value) => option?.type === value?.type}
      renderOption={(option) => (
        <>
          <Box display="flex" mr={1}>
            <ColorPreview color={option.color} />
          </Box>

          {option.name}
        </>
      )}
      renderTags={(types, getTagProps) => (
        types.map((type, index) => (
          <Chip
            variant="outlined"
            size={props.size}
            label={type.name}
            icon={
              <Box display="flex" alignItems="center" ml={1.5}>
                <ColorPreview color={type.color} />
              </Box>
            }
            {...getTagProps({ index })}
          />
        ))
      )}

      {...props}
    />
  );
};
