import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { columnsMap, columnsWidths } from '../listConfig';

export const TableHeader = ({ hiddenColumns = [] }) => {
  return (
    <Paper elevation={0}>
      <ListRow header px={2}>
        {!hiddenColumns.includes(columnsMap.user) && (
          <ListRowCell flexBasis={columnsWidths.user}>
            User
          </ListRowCell>
        )}

        <ListRowCell grow flexBasis={columnsWidths.supervisor}>
          Supervisor
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.start}>
          Start
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.end}>
          End
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.supervisorApprove}>
          Approve
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.workerApprove}>
          Agree
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
