import { Typography } from '@material-ui/core';
import { Category } from '../../../app/Dashboard/BillingPage/BillingSettings/Category';

export const CategoryActivity = ({ before = null, after = null, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Set&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;to&nbsp;
        <Category category={after} />
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Category category={before} />
      </>
    );
  } else if (before !== after) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Category category={before} />
        &nbsp;to&nbsp;
        <Category category={after} />
      </>
    );
  } else {
    return (
      <>Updated <Typography variant="subtitle1">{fieldLabel}</Typography></>
    );
  }
};

