import { useContext } from 'react';
import { makeStyles, Typography, Paper, Box } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { columnsWidths, widthBreakpointLarge } from '../List';
import { TasksContext } from '../../../TasksProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = ({ width }) => {
  const classes = useStyles();
  const { filter: { per_page, total } } = useContext(TasksContext);

  return (
    <Paper elevation={0} component={Box} pr={total > per_page ? 1 : 0}>
      <ListRow header className={classes.mainContent}>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          <Typography
            noWrap
            component="div"
            className={classes.headerCell}
          >
            Name/Description
          </Typography>
        </ListRowCell>

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.type}>
            Type
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.status}>
          Status
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.progress}>
          Progress
        </ListRowCell>

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.activity}>
            Activity date
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.activity}>
          Due date
        </ListRowCell>

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.assigned}>
            Members
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.priority}>
          Priority
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action}/>
      </ListRow>
    </Paper>
  );
};
