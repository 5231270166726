import moment from 'moment';
import { useContext, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { SavedFiltersContext } from '../../../../../../../../../../components/saved-filters';
import { Tooltip } from '../../../../../../../../../../components/Tooltip';
import { UserLink } from '../../../../../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../../../../../helpers/dom';
import { ListRow, ListRowCell } from '../../../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.invoiceNumber}>
        <Tooltip title={params?.invoice_number || '-'}>
          <Typography noWrap>{params?.invoice_number || '-'}</Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.user}>
        <UserLink
          noWrap
          size="sm"
          user={params?.user_id}
        />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.cases}>
        {params?.case_id?.name || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateFrom}>
        {params?.from ? moment.unix(params?.from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateTo}>
        {params?.to ? moment.unix(params?.to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.expenseCategory}>
        {params?.category_id?.name || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

