export const styles = ({ spacing, palette: { grey } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  notFoundText: {
    paddingTop: spacing(),
    paddingBottom: spacing()
  },

  list: {
    paddingTop: spacing(),
    paddingBottom: spacing()
  }
});
