import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { Box, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import AddIcon from '@material-ui/icons/Add';
import * as accessPatientsApi from '../../../../../api/profile/access-patients';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { useModal } from '../../../../../components/ModalsProvider';
import { PageHeaderActions } from '../../../../../components/Page';
import { IconButton } from '../../../../../components/IconButton';
import { Fab } from '../../../../../components/Fab';
import { AccessPatientsContext } from '../PatientsProvider';
import { AddPatientModal } from '../AddPatientModal';
import { ReplacePatientModal } from '../ReplacePatientModal';

export const ActionsBar = ({ accessibleUserID, filterOpenButton }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { deletePatients } = useContext(AccessPatientsContext);
  const { addPatient, selectedPatientsIDs } = useContext(AccessPatientsContext);
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const handleUpdateUser = (data) => {
    accessPatientsApi.massReplacePatient(data).then(() => {
      deletePatients(selectedPatientsIDs);
      enqueueSnackbar('Patient successfully updated!', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Something goes wrong!', { variant: 'error' });
    });
  };

  const handleUserReplace = () => {
    openModal(ReplacePatientModal, {
      payload: {
        isMulti: true
      },
      onModalResolved: (values) => {
        handleUpdateUser({
          from_users: [ accessibleUserID ],
          to_users: values?.users_id,
          patients: selectedPatientsIDs
        });
      }
    });
  };

  const handleAddNewPatient = () => {
    openModal(AddPatientModal, {
      payload: {
        accessibleUserID: accessibleUserID
      },
      onModalResolved: (data) => {
        addPatient(data);
      }
    });
  };

  return (
    <>
      <PageHeaderActions disablePosition>
        <Fab
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={handleAddNewPatient}
        >
          Add Patient
        </Fab>
      </PageHeaderActions>

      <PageHeaderActions disableSpacing endPosition>
        {!!selectedPatientsIDs?.length &&
          <>
            <Box px={1}>
              <Fab color="secondary" variant="extended" onClick={handleUserReplace}>
                Forward patient{(selectedPatientsIDs.length > 1) ? 's' : ''}
              </Fab>
            </Box>

            <VerticalDivider horizontalGutters={1} verticalGutters={1} />
          </>
        }

        {!isTablet ? (
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <PageHeaderActions>
            {filterOpenButton}
          </PageHeaderActions>
        )}
      </PageHeaderActions>
    </>
  );
};
