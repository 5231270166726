import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { CompanyLink } from '../../../../CompanyLink';
import { UserLink } from '../../../../UserLink';
import { orderByLabelsMap } from '../orderByOptions';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.company_id &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Company:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <CompanyLink noWrap company={params?.company_id} />
          </Grid>
        </>
      }

      {params?.user_id &&
        <>
          <Grid item container alignItems="center" xs={5}>
            <Typography noWrap variant="h5">
              User:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <UserLink
              noWrap
              size="sm"
              user={params.user_id}
            />
          </Grid>
        </>
      }

      {params?.started_from &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Started From:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {params?.started_from ? moment(params.started_from).format('L') : '-'}
          </Grid>
        </>
      }

      {params?.started_to &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Started To:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {params?.started_to ? moment(params.started_to).format('L') : '-'}
          </Grid>
        </>
      }

      {params?.order_by?.value &&
        <>
          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Order By:
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {params?.order_by ? orderByLabelsMap[params?.order_by?.value] : '-'}
          </Grid>
        </>
      }
    </Grid>
  );
};
