import { useHistory } from 'react-router-dom';
import { Typography, ListItem, ListItemText, ListItemIcon, SvgIcon } from '@material-ui/core';
import { searchResultsMap } from '../searchResultMaps';

export const Row = ({ item, onResultClick }) => {
  const history = useHistory();
  const result = searchResultsMap[item.type]?.(item);
  const Icon = result?.Icon || SvgIcon;

  const openResult = () => {
    history.push(result.link);
    onResultClick(item);
  };

  return !!result?.link && (
    <ListItem button key={item.id} onClick={openResult}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>

      <ListItemText
        primary={
          <Typography
            dangerouslySetInnerHTML={{
              __html: (
                item?.highlight?.main_search?.[0] ||
                item?.highlight?.additional_search?.[0] ||
                item?.main_search ||
                item?.additional_search
              )
            }}
          />
        }
        secondary={!!item?.highlight?.main_search?.[0] && item?.highlight?.additional_search?.[0] &&
          <Typography dangerouslySetInnerHTML={{ __html: item?.highlight?.additional_search?.[0] }} />
        }
      />
    </ListItem>
  );
};
