export const replaceableDataFieldsMap = {
  case: [
    {
      field: 'case_name',
      label: 'Case name'
    },
    {
      field: 'case_file_number',
      label: 'Case number'
    },
    {
      field: 'case_adj',
      label: 'Case ADJ NO'
    },
    {
      field: 'case_venue',
      label: 'Case venue'
    },
    {
      field: 'case_description',
      label: 'Case description'
    },
    {
      field: 'case_address',
      label: 'Case address'
    },
    {
      field: 'case_claim_no',
      label: 'Case claim number'
    },
    {
      field: 'case_doi',
      label: 'Case DOI'
    },
    {
      field: 'case_ssn',
      label: 'Case SSN'
    },
    {
      field: 'case_explain',
      label: 'Case explain'
    },
    {
      field: 'case_notice_of_representation',
      label: 'Case notice of representation'
    },
    {
      field: 'case_third_party_information',
      label: 'Case third party information'
    },
    {
      field: 'case_parent_name',
      label: 'Case parent name'
    },
    {
      field: 'case_parent_number',
      label: 'Case parent number'
    },
    {
      field: 'case_lats_changed_user_name',
      label: 'Case lats changed user name'
    },
    {
      field: 'case_lats_changed_user_role',
      label: 'Case lats changed user role'
    },
    {
      field: 'case_opened_by_user_name',
      label: 'Case opened by user name'
    },
    {
      field: 'case_opened_by_user_role',
      label: 'Case opened by user role'
    },
    {
      field: 'case_closed_by_user_name',
      label: 'Case closed by user name'
    },
    {
      field: 'case_closed_by_user_role',
      label: 'Case closed by user role'
    },
    {
      field: 'case_user_creator_name',
      label: 'Case user creator name'
    },
    {
      field: 'case_user_client_name',
      label: 'Case user client name'
    },
    {
      field: 'case_user_guardian_name',
      label: 'Case user guardian name'
    },
    {
      field: 'case_user_applicant_name',
      label: 'Case user applicant name'
    },
    {
      field: 'case_user_attorney_name',
      label: 'Case user attorney name'
    },
    {
      field: 'case_user_assistant_name',
      label: 'Case user assistant name'
    },
    {
      field: 'case_user_doctor_name',
      label: 'Case user doctor name'
    },
    {
      field: 'case_language',
      label: 'Case language'
    },
    {
      field: 'case_updated_at',
      label: 'Case updated at'
    },
    {
      field: 'case_commenced_date',
      label: 'Case commenced date'
    },
    {
      field: 'case_started_at',
      label: 'Case started at'
    },
    {
      field: 'case_due_date_at',
      label: 'Case due date at'
    },
    {
      field: 'case_policy_period_start',
      label: 'Case policy period start'
    },
    {
      field: 'case_policy_period_finish',
      label: 'Case policy period finish'
    },
    {
      field: 'case_opened_at',
      label: 'Case opened at'
    },
    {
      field: 'case_closed_at',
      label: 'Case closed at'
    },
    {
      field: 'case_physical_file_scanned_at',
      label: 'Case physical file scanned at'
    },
    {
      field: 'case_subbed_out_at',
      label: 'Case subbed out at'
    },
    {
      field: 'case_last_status_changed_at',
      label: 'Case last status changed at'
    },
    {
      field: 'case_age_on_date_of_injury',
      label: 'Case age on date of injury'
    },
    {
      field: 'case_company_deductible_amount',
      label: 'Case company deductible amount'
    },
    {
      field: 'case_selfinsured_liability_amount',
      label: 'Case self-insured liability amount'
    },
    {
      field: 'case_dirreff_attorney',
      label: 'Case direct referral to attorney'
    },
    {
      field: 'case_is_opened',
      label: 'Case is opened'
    },
    {
      field: 'case_subbed_out',
      label: 'Case subbed out'
    },
    {
      field: 'case_status',
      label: 'Case status'
    },
    {
      field: 'case_type',
      label: 'Case type'
    }
  ],
  task: [
    {
      field: 'task_name',
      label: 'Task name'
    },
    {
      field: 'task_description',
      label: 'Task description'
    },
    {
      field: 'task_created_by_name',
      label: 'Task created by name'
    },
    {
      field: 'task_created_by_role',
      label: 'Task created by role'
    },
    {
      field: 'task_opened_by_name',
      label: 'Task opened by name'
    },
    {
      field: 'task_opened_by_role',
      label: 'Task opened by role'
    },
    {
      field: 'task_closed_by_name',
      label: 'Task closed by name'
    },
    {
      field: 'task_closed_by_role',
      label: 'Task closed by role'
    },
    {
      field: 'task_archived_by_name',
      label: 'Task archived by name'
    },
    {
      field: 'task_archived_by_role',
      label: 'Task archived by role'
    },
    {
      field: 'task_reopened_by_name',
      label: 'Task reopened by name'
    },
    {
      field: 'task_reopened_by_role',
      label: 'Task reopened by role'
    },
    {
      field: 'task_status_changed_by_name',
      label: 'Task status changed by name'
    },
    {
      field: 'task_status_changed_by_role',
      label: 'Task status changed by role'
    },
    {
      field: 'task_due_at',
      label: 'Task due at'
    },
    {
      field: 'task_opened_at',
      label: 'Task opened at'
    },
    {
      field: 'task_closed_at',
      label: 'Task closed at'
    },
    {
      field: 'task_archived_at',
      label: 'Task archived at'
    },
    {
      field: 'task_reopened_at',
      label: 'Task reopened at'
    },
    {
      field: 'task_status_changed_at',
      label: 'Task status changed at'
    },
    {
      field: 'task_updated_at',
      label: 'Task updated at'
    },
    {
      field: 'task_estimate_time',
      label: 'Task estimate time'
    },
    {
      field: 'task_status',
      label: 'Task status'
    },
    {
      field: 'task_priority',
      label: 'Task priority'
    },
    {
      field: 'is_guarded',
      label: 'Is guarded'
    }
  ]
};
