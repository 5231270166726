import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import * as spcApi from '../../../../api/codes/special-program-codes';
import { Select } from '../Select';

export const transformSpcToOption = (code) => code && ({
  value: code.id,
  label: `${code.code} - ${code.name}`,
  data: code
});

const fetchSpecialProgramCodes = (params) => fetchDataForAsyncSelect((search, { page }) => {
  return spcApi.fetchSpecialProgramCodes({ params: { search, page, ...params } });
}, {
  transformDataToOption: transformSpcToOption
});

export const SpecialProgramCodesSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      name="special_program_code_id"
      label="Special Program Code"
      promiseWithOptions={fetchSpecialProgramCodes(params)}
      {...props}
    />
  );
};
