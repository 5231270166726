import { createContext, useCallback, useEffect, useReducer, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { isEqual, merge } from 'lodash';
import { api } from '../../../../../../../../api';
import * as negotiateApi from '../../../../../../../../api/cases/negotiations';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { usePrevious } from '../../../../../../../../helpers/hooks';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const NegotiationsContext = createContext();

export const NegotiationsProvider = ({ caseItem, onCaseItemUpdate, filter: filterProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    filter,
    negotiates
  } = state;
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchNegotiations = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_REQUEST });

    negotiateApi.fetchNegotiations(caseItem?.id, {
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetNegotiations = (newFilter) => {
    dispatch({ type: types.RESET_FILTERS, payload: newFilter });

    fetchNegotiations(newFilter);
  };

  const addNegotiation = useCallback((negotiation) => {
    dispatch({ type: types.ADD_ITEM, payload: negotiation });
  });

  const updateNegotiation = useCallback((negotiation) => {
    dispatch({ type: types.UPDATE_ITEM, payload: negotiation });
  });

  const deleteNegotiation = (parentID, negotiation) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        negotiateApi.deleteNegotiation(parentID, negotiation.id).then(() => {
          dispatch({ type: types.DELETE_ITEM, payload: negotiation.id });
          enqueueSnackbar('Case negotiation successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Case negotiation not deleted', { variant: 'error' });
        });
      }
    });
  };

  const providerValue = {
    caseItem,
    isFetched,
    isFetching,
    negotiates,
    filter,
    onCaseItemUpdate,

    // functions
    addNegotiation,
    resetNegotiations,
    fetchNegotiations,
    updateNegotiation,
    applyFilter,
    deleteNegotiation
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(filter, newFilter)) {
      resetNegotiations(newFilter);
    }
  }, [ filter, filterProp ]);


  useEffect(() => {
    if (!isEqual(prevFilter, filter)) {
      resetNegotiations(filter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    resetNegotiations();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <NegotiationsContext.Provider value={providerValue}>
      {children}
    </NegotiationsContext.Provider>
  );
};
