import { activityActionMap } from '../activitiesHelpers';
import { eventActivityMap } from '../activitiesHelpers/eventActivityMap';
import { NewFileActivity } from '../fields';
import { getActivityComponent } from './actionTypesMap';

export const eventActionTypesMap = {
  [eventActivityMap.createCaseScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [eventActivityMap.updateCaseScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [eventActivityMap.deleteCaseScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [eventActivityMap.createTaskScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [eventActivityMap.updateTaskScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [eventActivityMap.deleteTaskScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [eventActivityMap.createScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [eventActivityMap.updateScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [eventActivityMap.deleteScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [eventActivityMap.createRecurringScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [eventActivityMap.updateRecurringScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [eventActivityMap.deleteRecurringScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [eventActivityMap.createCaseRecurringScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [eventActivityMap.updateCaseRecurringScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [eventActivityMap.deleteCaseRecurringScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [eventActivityMap.createAppointment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [eventActivityMap.updateAppointment]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [eventActivityMap.deleteAppointment]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  ),

  [activityActionMap.createApptFile]: ({ file }) => {
    return [ <NewFileActivity file={file} /> ];
  },

  [activityActionMap.deleteApptFile]: ({ file }) => {
    return [ <NewFileActivity disableAttach file={file} /> ];
  },

  [eventActivityMap.createTaskRecurringScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isNew: true })
  ),

  [eventActivityMap.updateTaskRecurringScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName })
  ),

  [eventActivityMap.deleteTaskRecurringScheduleEvent]: (payload, logName) => (
    getActivityComponent({ payload, logName, isDeleted: true })
  )
};
