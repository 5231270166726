const triangleLeg = 16;
const triangleHypotenuse = triangleLeg * Math.sqrt(2);

export const styles = ({ palette: { background }, shape, shadows, zIndex }) => ({
  popper: {
    zIndex: zIndex.modal,

    '&[x-placement*="bottom"]': {
      paddingTop: triangleHypotenuse / 2,

      '& $triangle': {
        top: 0
      }
    },

    '&[x-placement*="top"]': {
      paddingBottom: triangleHypotenuse / 2,

      '& $triangle': {
        bottom: 0,
        transform: 'rotate(180deg)'
      }
    },

    '&[x-placement*="left"]': {
      paddingRight: triangleHypotenuse / 2,

      '& $triangle': {
        right: ~~(-triangleHypotenuse / 4),
        transform: 'rotate(90deg)'
      }
    },

    '&[x-placement*="right"]': {
      paddingLeft: triangleHypotenuse / 2,

      '& $triangle': {
        left: ~~(-triangleHypotenuse / 4),
        transform: 'rotate(270deg)'
      }
    }
  },

  triangle: {
    position: 'absolute',
    display: 'block',
    width: triangleHypotenuse,
    height: triangleHypotenuse / 2,
    overflow: 'hidden',

    '&:before': {
      content: '""',
      display: 'block',
      position: 'relative',
      width: triangleLeg,
      height: triangleLeg,
      background: background.paper,
      top: '50%',
      left: '50%',
      boxShadow: '0px 0px 8px -1px rgba(0, 0, 0, 0.5)',
      transform: 'translateX(-50%) rotate(45deg)'
    }
  },

  popperContent: {
    overflow: 'hidden',
    borderRadius: shape.borderRadius,
    boxShadow: shadows[4]
  }
});
