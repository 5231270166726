import { useState } from 'react';
import { Box, Dialog, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { IconButton } from '../../../IconButton';
import { ModalBody, ModalContainer, ModalHeader, ModalHeaderActions } from '../../../Modal';
import { useModal } from '../../../ModalsProvider';
import { Tooltip } from '../../../Tooltip';
import { UserLink } from '../../../UserLink';
import { CreateWorkClocksModal } from '../../CreateWorkClocksModal';
import { MobileTracks } from '../List/Row/MobileTracks';
import { Tracks } from '../List/Row/Tracks';
import { TableHeader } from './TableHeader';

export const WorkingSessionInfoModal = ({
  DialogProps,
  handleModalReject,
  payload: {
    workSession,
    onTrackAdd = () => {},
    onTrackUpdate = () => {},
    onTrackDelete = () => {}
  }
}) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ tracks, setTracks ] = useState(workSession.session_tracks);
  const hasAccess = hasRole(rolesMap.admin, rolesMap.supervisor);

  const addTrack = () => {
    openModal(CreateWorkClocksModal, {
      payload: { workSession },
      onModalResolved: (track) => {
        setTracks((tracks) => tracks.concat(track));
        onTrackAdd(workSession.id, track);      }
    });
  };

  const handleTrackUpdate = (updatedTrack) => {
    setTracks((tracks) => {
      return tracks.map((track) => track.id === updatedTrack.id ? updatedTrack : track);
    });

    onTrackUpdate(updatedTrack);
  };

  const handleTrackDelete = (trackId) => () => {
    const cb = () => {
      setTracks(tracks.filter((track) => track.id !== trackId));
    };

    onTrackDelete(workSession.id, trackId, cb);
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <ModalContainer p={0} >
        <ModalHeader onClose={handleModalReject}>
          <Box display="flex" alignItems="baseline">
            <Typography variant="h4">Work Session</Typography>

            &nbsp;

            <UserLink
              noAvatar
              size="md"
              variant="h5"
              user={workSession.user}
            />
          </Box>

          {hasAccess &&
            <ModalHeaderActions>
              <Tooltip title="Add track">
                <IconButton
                  color="primary"
                  onClick={addTrack}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </ModalHeaderActions>
          }
        </ModalHeader>

        <ModalBody disablePaddings={!isMobile}>
          {!isMobile && <TableHeader />}

          {isMobile ? (
            <MobileTracks tracks={tracks} onTrackDelete={handleTrackDelete} />
          ) : (
            <Tracks tracks={tracks} onTrackUpdate={handleTrackUpdate} onTrackDelete={handleTrackDelete} />
          )}
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
