import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Checkbox, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { AddressLink } from '../../../AddressLink';
import { ColorPreview } from '../../../ColorPreview';
import { Date } from '../../../Date';
import { Members } from '../../../Members';
import { useModal } from '../../../ModalsProvider';
import { Menu } from '../../EventsList/List/Row/Menu';
import { ScheduleContext } from '../../ScheduleProvider';
import { ListRow, ListRowCell } from '../../../ListRow';
import { Loader } from '../../../Loader';
import { OfficeLink } from '../../../OfficeLink';
import { Tooltip } from '../../../Tooltip';
import { ViewScheduleEventModal } from '../../ViewScheduleEventModal';
import { columnsWidths, columnsMap } from '../columns';

export const BREAKPOINT_LARGE = 800;
export const BREAKPOINT_HIDE_OFFICE = 920;
export const BREAKPOINT_LARGE_GROW = 1000;

export const ScheduleEventRow = ({
  width,
  viewOnClick = false,
  isMulti = false,
  isLoaded,
  item: event,
  ListRowProps,
  recalculateHeight,
  hiddenColumns = []
}) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    selectedEventsIDs,
    updateEvent,
    deleteEvent,
    toggleSingleEventSelection,
    toggleEventSelection
  } = useContext(ScheduleContext);
  const start = moment.unix(event?.started_at);
  const end = moment.unix(event?.finished_at);
  const time = event?.finished_at
    ? moment.duration(end.diff(start)).asMinutes()
    : 0;

  const openViewModal = () => {
    openModal(ViewScheduleEventModal, {
      payload: {
        hideApplyFields: true,
        event: event,
        onUpdate: updateEvent,
        onDelete: deleteEvent
      }
    });
  };

  const handleSelect = () => {
    toggleEventSelection(event.id);
  };

  const handleSingleSelect = () => {
    toggleSingleEventSelection(event.id);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow
      button
      px={1}
      onClick={viewOnClick ? openViewModal : isMulti ? handleSelect : handleSingleSelect}
      selected={selectedEventsIDs?.includes(event?.id)}

      {...ListRowProps}
    >
      {isMulti && (
        <ListRowCell>
          <Checkbox
            edge="start"
            checked={selectedEventsIDs?.includes(event.id)}
          />
        </ListRowCell>
      )}

      <ListRowCell flexGrow={1} color="primary" flexBasis={columnsWidths.date}>
        <Date date={event?.started_at} format="L" />
      </ListRowCell>

      <ListRowCell flexGrow={1} column flexBasis={columnsWidths.duration}>
        {event?.started_at ? moment.unix(event?.started_at).format('LT') : '-'}
        {' - '}
        {event?.finished_at ? moment.unix(event?.finished_at).format('LT') : '-'}

        <br/>
        {moment.duration(time, 'minutes').format('h[h] m[min]')}
      </ListRowCell>

      <ListRowCell flexGrow={1} column flexBasis={columnsWidths.description}>
        <Tooltip title={event?.title}>
          <Typography color="primary" noWrap>{event?.title}</Typography>
        </Tooltip>

        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: event?.description || '-' }} />}>
          <Typography
            noWrap
            component="div"
            dangerouslySetInnerHTML={{ __html: event?.description || '-' }}
          />
        </Tooltip>
      </ListRowCell>

      {!hiddenColumns.includes(columnsMap.type) && !isTablet &&
        <ListRowCell flexGrow={width > BREAKPOINT_LARGE_GROW ? 1 : null} actions flexBasis={columnsWidths.type}>
          {!event.type ? '-' : (
            <>
              <ColorPreview color={event.type?.color}/>

              <span>{event.type?.name}</span>
            </>
          )}
        </ListRowCell>
      }

      {BREAKPOINT_HIDE_OFFICE < width &&
        <ListRowCell flexGrow={width > BREAKPOINT_LARGE_GROW ? 1 : null} column flexBasis={columnsWidths.office}>
          <OfficeLink
            noWrap
            variant="h5"
            office={event?.office}
          />

          <AddressLink noWrap address={event.office?.full_address} variant="subtitle2"/>
        </ListRowCell>
      }

      {BREAKPOINT_LARGE < width &&
        <ListRowCell flexGrow={width > BREAKPOINT_LARGE_GROW ? 1 : null} flexBasis={columnsWidths.participants}>
          <Members
            noWrap
            maxVisible={4}
            owner={event.creator}
            users={event.users || []}
          />
        </ListRowCell>
      }

      {!hiddenColumns.includes(columnsMap.action) &&
        <ListRowCell flexBasis={columnsWidths.action}>
          <Menu
            hideApplyFields
            event={event}
            onEventUpdate={updateEvent}
            onEventDelete={deleteEvent}
          />
        </ListRowCell>
      }
    </ListRow>
  );
};
