import { useFormikContext } from 'formik';
import {
  CompaniesAutocomplete,
  PhoneField,
  TextField
} from '../../../../../../../../components/FormField';

export const Body = () => {
  const { setFieldValue } = useFormikContext();

  const handleCompanyChange = (company) => {
    setFieldValue('substituted_in_company_phone', company?.phone);
    setFieldValue('substituted_in_company_address', company?.full_address);
  };

  return (
    <>
      <CompaniesAutocomplete
        name="substituted_in_company_id"
        label="Firm name"
        margin="dense"
        onChange={handleCompanyChange}
      />

      <TextField
        name="substituted_in_company_address"
        label="Address"
        margin="dense"
      />

      <PhoneField
        disabled
        name="substituted_in_company_phone"
        label="Phone"
        margin="dense"
      />
    </>
  );
};
