import { useContext } from 'react';
import { Box, SvgIcon, Typography } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiDraw } from '@mdi/js';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { hasRole } from '../../../../../../../utils/hasRole';
import { UserPreviewModal } from '../../../../../../../components/users';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';
import { CardItem, CardHeader } from '../../../../../../../components/Cards';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { Date } from '../../../../../../../components/Date';
import {
  ReportsContext,
  PreviewReportModal,
  ReportsMenu
} from '../../../../../../../components/medical/reports';
import { Body } from './Body';

export const Card = ({ item: report = {} }) => {
  const { openModal } = useModal();
  const { selectedIDs, toggleItemSelection, pathname, fetchReports, deleteReport } = useContext(ReportsContext);
  const isSelected = selectedIDs.indexOf(report.id) !== -1;
  const disablePatient = pathname !== '/diagnosis';

  const handleItemSelection = () => {
    toggleItemSelection(report.id);
  };

  const openPreview = () => {
    openModal(PreviewReportModal, {
      payload: {
        pathname,
        reportID: report.id,
        onDelete: deleteReport,
        onUpdate: fetchReports
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <CardItem
      selected={isSelected}
      py={1}
      px={2}
      onClick={stopPropagation(openPreview)}
    >
      <CardHeader
        isSelected={isSelected}
        disableCheckBox={hasRole('client', 'patient')}
        disableCardMenu={hasRole('client', 'patient')}
        onItemSelect={handleItemSelection}
        menuComponent={ReportsMenu}
        menuComponentProps={{ report }}
      >
        <Box display="flex" alignItems="center" color="text.secondary">
          {!disablePatient ? (
            <UserLink
              disablePreview={!report?.patient}
              disableLink={!report?.patient}
              size="sm"
              variant="inherit"
              user={report?.patient}
            >
              {(report.updated_at || report.created_at) && (
                <Date
                  disableIcon
                  title="DOR"
                  color="textSecondary"
                  variant="subtitle2"
                  date={report.updated_at || report.created_at}
                  iconFontSize="small"
                  format="L LT"
                />
              )}
            </UserLink>
          ) : (
            <Date
              title="DOR"
              variant="subtitle2"
              date={report.updated_at || report.created_at}
              iconFontSize="small"
              format="L LT"
            />
          )}
        </Box>
      </CardHeader>

      <Body report={report} />

      <Box display="flex" alignItems="center" justifyContent="space-between" pt={2} pb={0.5} pr={2}>
        <UserLink
          size="lg"
          variant="h5"
          user={report.provider}
          onClick={stopPropagation(openUserPreview(report?.provider?.id))}
        >
          <Box mt={-0.5}>
            <Typography color="textSecondary" variant="caption">Provider</Typography>
          </Box>
        </UserLink>

        <Box color={report.is_signed ? 'success.main' : 'inherit'} display="flex" alignItems="center">
          <VerticalDivider verticalGutters={0.25} horizontalGutters={2} />

          <Tooltip isExistTooltip title={report?.is_signed ? 'Signed' : 'Needs to sign'}>
            <SvgIcon color="inherit"><Icon path={mdiDraw} /></SvgIcon>
          </Tooltip>
        </Box>
      </Box>
    </CardItem>
  );
};
