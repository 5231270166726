import { useFormikContext } from 'formik';
import { CasesSelect } from '../../cases/CasesSelect';
import { UsersSelect } from '../../users';
import { parentTypesMap } from './parentTypeOptions';

export const Form = () => {
  const { values } = useFormikContext();

  return values?.parent_type === parentTypesMap.user ? (
    <UsersSelect
      required
      isClearable
      name="parent_id"
      label="Select user"
      placeholder="Select user..."
    />
  ) : (
    <CasesSelect
      required
      name="parent_id"
      label="Select case"
      placeholder="Select case..."
    />
  );
};
