export const styles = ({ spacing, palette }) => ({
  root: {
    position: 'relative',
    width: 190,
    margin: spacing()
  },

  media: {
    height: 100
  },

  icon: {
    fontSize: 26
  },

  fileSize: {
    fontSize: 11
  },

  cardActions: {
    alignItems: 'flex-start',
    paddingBottom: spacing(0.5),
    backgroundColor: palette.grey[100]
  }
});
