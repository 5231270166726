import { useRef } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { useMobileFilterCollapse } from '../../../../../utils/useMobileFilterCollapse';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../../../LayoutContext';
import { CaseRemindersFiltersProvider } from '../CaseRemindersFiltersProvider';
import { CaseRemindersGrid } from '../CaseRemindersGrid';
import { CaseRemindersProvider } from '../CaseRemindersProvider';
import { CaseRemindersList } from '../CaseRemindersList';
import { CasesGroups } from './CasesGroups/CasesGroups';
import { FiltersBar } from './FiltersBar';
import { Groups } from './Groups';
import { Header } from './Header';
import { ProgressBar } from './ProgressBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const middleScreenWidth = 920;
const largeScreenWidth = 1380;

export const CaseReminders = ({ caseId }) => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse(undefined, undefined, width < middleScreenWidth);

  return (
    <Paper ref={rootRef} elevation={2} className={classes.root}>
      <LayoutContextProvider>
        <LayoutContext.Consumer>
          {({ viewVariant }) => (
            <CaseRemindersFiltersProvider caseId={caseId}>
              <Header
                width={width}
                middleScreenWidth={middleScreenWidth}
                largeScreenWidth={largeScreenWidth}
                filterOpenButton={filterOpenButton}
              />

              <ScrollWrapper {...scrollWrapperProps}>
                <FilterBarWrapper {...filterBarWrapperProps}>
                  <FiltersBar fullWidthFields={width < middleScreenWidth}/>
                </FilterBarWrapper>
              </ScrollWrapper>

              <ProgressBar caseId={caseId}/>

              <div className={classes.main}>
                {viewVariant === viewVariantsMap.groups ? caseId ? (
                  <Groups/>
                ) : (
                  <CasesGroups/>
                ) : (
                  <CaseRemindersProvider caseId={caseId}>
                    {viewVariant === viewVariantsMap.grid
                      ? <CaseRemindersGrid/>
                      : <CaseRemindersList/>
                    }
                  </CaseRemindersProvider>
                )}
              </div>
            </CaseRemindersFiltersProvider>
          )}
        </LayoutContext.Consumer>
      </LayoutContextProvider>
    </Paper>
  );
};
