import moment from 'moment';

export const DELETED_USER = 'DELETED USER';

export const formatDateForChat = (unixDate, isLong = false) => {
  const date = moment.unix(unixDate);
  const isToday = date.isSame(moment(), 'day');
  const isThisWeek = date.isSame(moment(), 'week');
  
  return isToday
    ? 'Today'
    : isThisWeek
      ? date.format(isLong ? 'dddd' : 'ddd')
      : date.format('L');
};

export const getMessageAuthorName = (message) => {
  return message?.user?.first_name || message?.parent?.user?.first_name || DELETED_USER;
};
