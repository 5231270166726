export const quantityMap = [
  {
    value: 'F2',
    label: 'F2'
  },
  {
    value: 'GR',
    label: 'GR'
  },
  {
    value: 'UN',
    label: 'UN'
  },
  {
    value: 'ML',
    label: 'ML'
  }
];
