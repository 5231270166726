import { Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell flexBasis={columnsWidths.patientName}>
        <Typography noWrap variant="h5">
          Patient name
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.medRec}>
        <Typography noWrap variant="h5">
          MRN
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dob}>
        <Typography noWrap variant="h5">
          DOB
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.ssn}>
        <Typography noWrap variant="h5">
          SSN
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.apptFrom}>
        <Typography noWrap variant="h5">
          Appt. from
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.apptTo}>
        <Typography noWrap variant="h5">
          Appt. to
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        <Typography noWrap variant="h5">
          Provider
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.insurType}>
        <Typography noWrap variant="h5">
          Insur. type
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.expirationDay}>
        <Typography noWrap variant="h5">
          Expiration day
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
