import { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { TablePagination } from '../../../../../components/TablePagination';
import { Loader } from '../../../../../components/Loader';
import { PageBody } from '../../../../../components/Page';
import { WaitingListItemContainer } from '../WaitingListItemContainer';
import { WaitingListContext } from '../WaitingListProvider';
import { Header } from './Header';
import { Row } from './Row';

export const widthBreakpointSmall = 785;
export const widthBreakpointMedium = 1000;
export const widthBreakpointLarge = 1150;
export const minRowHeight = 61;
export const columnsWidths = {
  name: 250,
  phone: 150,
  location: 250,
  appt: 200,
  duration: 176,
  notes: 180,
  waitingTime: 140,
  actions: 52
};

export const List = () => {
  const { isFetched, meta, waiting_list, resetWaitingList } = useContext(WaitingListContext);
  const rooRef = useRef();
  const { width } = useResizeObserver({ ref: rooRef });

  return (
    <Box ref={rooRef} height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => !waiting_list.length ? (
          <Box p={3}>
            <Typography align="center">No info found</Typography>
          </Box>
        ) : (
          <>
            <Header width={width} />

            <PageBody disablePaddings>
              <MuiList disablePadding>
                {waiting_list?.map((listItem) => (
                  <WaitingListItemContainer key={listItem.id} width={width} item={listItem} WaitingListItem={Row} />
                ))}
              </MuiList>
            </PageBody>

            <TablePagination borderTop pagination={meta} onChange={resetWaitingList} />
          </>
        )}
      />
    </Box>
  );
};
