import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { IconButton, Link, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../dataMaps/apiModels';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../ListRow';
import { Loader } from '../../../../../Loader';
import { UserPreviewModal } from '../../../../../users';
import { CurrencyFormat } from '../../../../../CurrencyFormat';
import { CompanyLink } from '../../../../../CompanyLink';
import { useModal } from '../../../../../ModalsProvider';
import { UserLink } from '../../../../../UserLink';
import { Popper } from '../../../../../Popper';
import { PreviewCompanyModal } from '../../../../../../app/Dashboard/Contacts/Companies/PreviewCompanyModal';
import { PaymentsContext } from '../../../PaymentsContext';
import {
  minRowHeight,
  columnsWidths,
  widthBreakpointMedium,
  widthBreakpointLarge
} from '../List';
import { PaymentMenu } from '../../../PaymentMenu';

export const Row = ({ item: payment, width, isLoaded, recalculateHeight }) => {
  const paymentsContext = useContext(PaymentsContext);
  const isSelected = isLoaded && paymentsContext.selectedPaymentsIDs.indexOf(payment.id) !== -1;
  const { openModal } = useModal();
  const history = useHistory();

  const handleItemSelection = () => {
    paymentsContext.toggleItemSelection(payment.id);
  };

  const getPaymentPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.payment(payment.id));
    }
  };

  const openUserPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: payment?.payer?.participant?.id }
    });
  };

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: {
        companyID: payment?.payer?.participant_id
      }
    });
  };

  useEffect(() => {
    if (isLoaded) {
      recalculateHeight();
    }
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow
      button
      px={2}
      selected={isSelected}
      minHeight={minRowHeight}
      title="Open payment page"
      onClick={getPaymentPage}
    >
      <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)}/>

      <ListRowCell grow flexBasis={columnsWidths.number}>
        <Typography noWrap title={payment.number}>
          {payment.number}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.payer}>
        {payment?.payer?.participant_type === 'company' ?
          <CompanyLink
            noWrap
            company={payment?.payer?.participant}
            onClick={stopPropagation(openPreviewCompanyModal)}
          />
          :
          <UserLink
            noWrap
            user={payment?.payer?.participant}
            onClick={stopPropagation(openUserPreview)}
          />
        }
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.number}>
        {!payment.invoice ? '-' :
          <Link
            noWrap
            target="_blank"
            component={RouterLink}
            to={itemsRoutesByModels.invoice(payment?.invoice?.id)}
            title={payment?.invoice?.number}
          >
            {payment?.invoice?.number}
          </Link>
        }
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.description}>
          <Typography noWrap title={payment.note}>
            {payment.note}
          </Typography>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.paid_date}>
        {moment(payment.date).format('L')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.due_on}>
        {payment?.invoice?.date ? moment(payment?.invoice?.date).format('L') : '-'}
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.method}>
          <Typography noWrap title={payment.method}>
            {payment.method}
          </Typography>
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.total}>
        <CurrencyFormat value={payment?.invoice?.total} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount}>
        <CurrencyFormat value={payment?.amount} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.balance}>
        <CurrencyFormat value={payment?.invoice?.balance} />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} justifyContent="flex-end">
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton edge="end" buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <PaymentMenu
              payment={payment}
              onClose={handleClose}
            />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
