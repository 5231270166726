import { getUserFullName } from '../../helpers/users';

export const transformAppointmentToCalendarEvent = (appointment) => {
  const baseEvent = {
    start: appointment.appointment_at * 1000
  };

  return !appointment.id ? baseEvent : {
    ...baseEvent,

    id: appointment.id,
    end: (appointment.appointment_at + (appointment.time || 1)) * 1000,
    title: getUserFullName(appointment.patient) || 'Appointment without patient',
    editable: !appointment.is_system,
    color: 'transparent',
    resourceId: appointment.appointment_book_id,
    extendedProps: {
      appointment
    }
  };
};
