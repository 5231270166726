import CloseIcon from '@material-ui/icons/Close';
import { useState, useContext, useEffect, useRef } from 'react';
import {
  makeStyles,
  List,
  Fab,
  Typography,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import { useModal } from '../../../../../../components/ModalsProvider';
import { useDebounce } from '../../../../../../helpers/hooks';
import {
  CaseReminderGroupsContext,
  CaseReminderGroupsSelect,
  CreateReminderGroupModal
} from '../../../../../../components/cases/reminders/groups';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { IconButton } from '../../../../../../components/IconButton';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../components/Loader';
import { AppBar } from '../../../../../../components/AppBar';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ReminderGroups = ({
  isOpenSidebar = false,
  initialSelectedReminderGroups = null,
  toggleSideBar = () => {},
  onReminderGroupsChange = () => {}
}) => {
  const {
    isFetched,
    isFetching,
    reminderGroups,
    meta,
    addReminderGroup,
    applyFilter,
    loadNextPage,
    resetReminderGroups
  } = useContext(CaseReminderGroupsContext);
  const [ searchField, setSearchField ] = useState(meta.name);
  const [ selectedReminderGroups, setSelectedReminderGroups ] = useState(initialSelectedReminderGroups);
  const debouncedSearchField = useDebounce(searchField, 600);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const scrollElementRef = useRef();
  const classes = useStyles();
  const { openModal } = useModal();

  const createReminderGroup = () => {
    openModal(CreateReminderGroupModal, {
      onModalResolved: (reminderGroup) => {
        addReminderGroup(reminderGroup);
        setSelectedReminderGroups(reminderGroup);
      }
    });
  };

  const handleReminderGroupsSearch = (event, value) => {
    setSearchField(value);
  };

  const reminderGroupIsSelected = (reminderGroup) => {
    // return !!selectedReminderGroups?.find(({ id }) => id === reminderGroup?.id);
    return selectedReminderGroups?.id === reminderGroup?.id;
  };

  const toggleReminderGroupSelection = (reminderGroup) => {
    // setSelectedReminderGroups((options) => {
    //   if (reminderGroupIsSelected(reminderGroup)) {
    //     return options.filter(({ id }) => id !== reminderGroup?.id);
    //   }
    //
    //   return [ reminderGroup ].concat(options || []);
    // });
    setSelectedReminderGroups(reminderGroup);
  };

  const handleSearchFilterClear = () => {
    setSelectedReminderGroups(null);
    setSearchField(null);
  };

  const handleSelectedReminderGroupsChange = (options) => {
    setSelectedReminderGroups(options);
  };

  useEffect(() => {
    if (debouncedSearchField !== meta.search) {
      applyFilter({ search: debouncedSearchField });
    }
  }, [ debouncedSearchField ]);

  useEffect(() => {
    onReminderGroupsChange(selectedReminderGroups);
  }, [ selectedReminderGroups ]);

  useEffect(() => {
    resetReminderGroups();
  }, []);

  return (
    <div className={classes.root}>
      <AppBar className={classes.header} ToolbarProps={{ className: classes.headerToolbar }}>
        <Typography variant="h3" className={classes.header__title}>Reminder groups</Typography>

        <Fab color="primary" onClick={createReminderGroup}>
          <AddIcon />
        </Fab>

        {isTablet && (
          <Box mr={-2} ml={1}>
            <IconButton onClick={toggleSideBar}>
              {isOpenSidebar ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon color="primary" />}
            </IconButton>
          </Box>
        )}
      </AppBar>

      <CaseReminderGroupsSelect
        withoutFormik
        disableDropdown
        disableClearable
        isAsync={false}
        open={false}
        clearOnBlur={false}
        clearOnEscape={false}
        name="name"
        label={null}
        placeholder="Search group..."
        value={selectedReminderGroups}
        inputValue={searchField}
        TextFieldProps={{
          InputProps: {
            className: classes.search,
            endAdornment: (selectedReminderGroups || searchField) && (
              <IconButton className={classes.searchButton} onClick={handleSearchFilterClear}>
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          }
        }}
        onChange={handleSelectedReminderGroupsChange}
        onInputChange={handleReminderGroupsSearch}
      />

      <Box display="flex" flexGrow={1} py={1}>
        <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
          <Loader loading={!isFetched} render={
            () => !meta.total ? (
              <Typography align="center">No reminder groups found</Typography>
            ) : (
              <List disablePadding>
                <InfiniteListLoader
                  items={reminderGroups}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={meta}
                  RowComponent={Row}
                  RowProps={{
                    reminderGroupIsSelected,
                    onReminderGroupToggleSelection: toggleReminderGroupSelection
                  }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </List>
            )}
          />
        </CustomScrollbars>
      </Box>
    </div>
  );
};
