import { createContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, omit } from 'lodash';
import { api } from '../../../../api';
import * as servicesApi from '../../../../api/login-queues/services';
import { usePrevious } from '../../../../helpers/hooks';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const LoginServicesContext = createContext(null);

export const LoginServicesProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    loginServices,
    pagination,
    isFetching,
    isFetched,
    filter
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchLoginServices = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.LOGIN_SERVICES_FETCH_REQUEST });

    servicesApi.fetchServices({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.LOGIN_SERVICES_FETCH_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const addLoginService = (loginService) => {
    dispatch({ type: types.ADD_LOGIN_SERVICE, payload: loginService });
  };

  const deleteLoginService = (loginService) => {
    dispatch({ type: types.DELETE_LOGIN_SERVICE, payload: loginService });
  };

  const updateLoginService = (loginService) => {
    dispatch({ type: types.UPDATE_LOGIN_SERVICE, payload: loginService });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchLoginServices({ page: filter.page + 1 });
    }
  };

  const resetLoginServices = (newFilter) => {
    dispatch({ type: types.RESET_LOGIN_SERVICES, payload: newFilter });

    fetchLoginServices({ page: 1, ...newFilter });
  };

  const providerValue = {
    loginServices,
    isFetched,
    isFetching,
    filter,
    meta: {
      ...filter,
      ...pagination
    },
    fetchLoginServices,
    applyFilter,
    addLoginService,
    updateLoginService,
    deleteLoginService,
    resetLoginServices,
    loadNextPage
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      fetchLoginServices(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <LoginServicesContext.Provider value={providerValue}>
      {children}
    </LoginServicesContext.Provider>
  );
};
