import { useContext, useState } from 'react';
import { Box, Grid, makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import DirectionsTransitOutlinedIcon from '@material-ui/icons/DirectionsTransitOutlined';
import DirectionsBikeOutlinedIcon from '@material-ui/icons/DirectionsBikeOutlined';
import DirectionsWalkOutlinedIcon from '@material-ui/icons/DirectionsWalkOutlined';
import { MapProviderContext } from '../MapProvider';
import { travelModesMap } from '../travelModesMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const RouteInfo = () => {
  const {
    isRouteNotFound,
    distance,
    duration,
    changeRoute
  } = useContext(MapProviderContext);
  const classes = useStyles();
  const [ activeTab, setActiveTab ] = useState(travelModesMap.driving);

  const handleActiveTabChange = (event, newValue) => {
    setActiveTab(newValue);
    changeRoute({ travelMode: newValue });
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
        onChange={handleActiveTabChange}
      >
        <Box clone minHeight="0 !important" value={travelModesMap.driving}>
          <Tab icon={<DirectionsCarOutlinedIcon />} />
        </Box>

        <Box clone minHeight="0 !important" value={travelModesMap.transit}>
          <Tab icon={<DirectionsTransitOutlinedIcon />} />
        </Box>

        <Box clone minHeight="0 !important" value={travelModesMap.bicycling}>
          <Tab icon={<DirectionsBikeOutlinedIcon />} />
        </Box>

        <Box clone minHeight="0 !important" value={travelModesMap.walking}>
          <Tab icon={<DirectionsWalkOutlinedIcon />} />
        </Box>
      </Tabs>

      <div className={classes.content}>
        {isRouteNotFound ? (
          <Typography variant="h3">No route found for this option</Typography>
        ) : (
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Typography variant="h3">{distance?.text}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="h3">{duration?.text}</Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </Paper>
  );
};
