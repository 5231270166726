import moment from 'moment';
import { Yup } from '../../../../utils/validation';
import { formTypesMap } from '../formTypesMap';

export const validationSchema = Yup.object().shape({
  medical_form: Yup.string().required(),
  patient_id: Yup.mixed().required(),
  medical_form_id: Yup.mixed().nullable().when('medical_form', {
    is: (type) => type === formTypesMap.follow_up,
    then: Yup.mixed().nullable().required()
  }),
  treating_physician_id: Yup.mixed().nullable().when('medical_form', {
    is: (type) => type === formTypesMap.follow_up,
    then: Yup.mixed().nullable().required()
  }),
  unable_work_date_from: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today'),
  unable_work_date_to: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(10, 'days'), 'Date must be earlier than today'),
  last_work_date: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'days'), 'Date must be earlier than today'),
  doi: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today').when('is_range', {
      is: (value) => !!value,
      then: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today'),
      otherwise: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today').required()
    }),
  doi_from: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today').when('is_range', {
      is: (value) => !!value,
      then: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today').required()
    }),
  doi_to: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today').when('is_range', {
      is: (value) => !!value,
      then: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today').required()
    })
});
