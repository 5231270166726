export const styles = ({ palette: { grey, error }, typography: { pxToRem } }) => ({
  notEditableInput: {
    color: 'currentColor !important',

    '& button': {
      color: `${grey[500]} !important`
    },

    '&:before': {
      borderBottomStyle: 'solid !important'
    }
  },

  notEditableInputLabel: {
    color: `${grey[500]} !important`
  },

  notEditableErrorInputLabel: {
    color: `${error.main} !important`
  },

  dropDownIcon: {
    fontSize: pxToRem(17)
  }
});
