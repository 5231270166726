import { makeStyles } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CountLabel = ({ color, count }) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.root}>
      {count}
    </div>
  );
};
