import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  amount: Yup.mixed().required(),
  method: Yup.mixed().required(),
  note: Yup.string().nullable().max(1000),
  date: Yup.mixed().required(),
  payer: Yup.object().nullable().shape({
    type: Yup.mixed().nullable().required(),
    id: Yup.mixed().nullable().required()
  })
});
