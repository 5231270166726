import { useContext, useEffect } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Loader } from '../../Loader';
import { CommentsContext } from '../CommentsContext';
import { Row } from '../Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SubComments = ({
  width,
  parentPage,
  isRepliesOpened,
  parentCommentID
}) => {
  const classes = useStyles();
  const {
    isFetched,
    comments,
    filter,
    deleteComment,
    loadNextPage,
    ...commentsContext
  } = useContext(CommentsContext);

  useEffect(() => {
    !isFetched && isRepliesOpened && commentsContext.fetchComments();
  }, [ isRepliesOpened ]);

  return (
    <div className={classes.root}>
      {isRepliesOpened &&
        <>
          <Loader loading={!isFetched} render={
            () => (
              comments.map((comment) => (
                <Row
                  isSubComment
                  key={comment.id}
                  width={width}
                  parentPage={parentPage}
                  parentCommentID={parentCommentID}
                  comment={comment}
                />
              ))
            )}
          />

          {filter.total > comments.length &&
            <Button
              className={classes.loadMoreButton}
              onClick={loadNextPage}
            >
              Show more replies
            </Button>
          }
        </>
      }
    </div>
  );
};
