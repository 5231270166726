import { Redirect } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { renderRoutes, Route } from '../../../components/router';
import { routes } from './routes';

export const EmailsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box height="100%" bgcolor="grey.100">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        py={isMobile? 1 : 2}
        px={isMobile ? 1 : 3}
      >
        <Route exact path="/emails">
          <Redirect to="/emails/client" />
        </Route>

        {renderRoutes(routes)}
      </Box>
    </Box>
  );
};
