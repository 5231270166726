import { useContext } from 'react';
import { PreviewNotFound } from './PreviewNotFound';
import { FilePreviewContext } from '../../FilePreviewContext';
import { previewVariants } from './previewVariants';

export const Preview = ({ actionsBarPortalElement, topLayerPortalElement }) => {
  const { selectedFile } = useContext(FilePreviewContext);

  const variant = selectedFile && previewVariants.find((variant) => variant.condition(selectedFile));
  const PreviewComponent = variant ? variant.component : PreviewNotFound;

  return (
    <PreviewComponent
      actionsBarPortalElement={actionsBarPortalElement}
      topLayerPortalElement={topLayerPortalElement}
    />
  );
};
