import { useContext } from 'react';
import { makeStyles, Box, useTheme, useMediaQuery } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { mdiTrashCanOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { SelectedItemsCount } from '../../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { IconButton } from '../../../../../components/IconButton';
import { Checkbox } from '../../../../../components/FormField';
import { SvgIcon } from '../../../../../components/SvgIcon';
import { useModal } from '../../../../../components/ModalsProvider';
import { AccountsContext } from '../../AccountsContext';
import { MessagesContext } from '../../MessagesContext';
import { EmailSaveModal } from '../EmailSaveModal';
import { Messages } from './Messages';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmailsActions = ({
  disablePaddings = false,
  disableMessages = false,
  isOpenSidebar = false,
  onOpenSideBar = () => {}
}) => {
  const {
    filter,
    pagination,
    selectedMessages,
    deleteMessages,
    allItemsIsSelected,
    toggleAllItemsSelection
  } = useContext(MessagesContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { accounts } = useContext(AccountsContext);
  const { openModal } = useModal();
  const classes = useStyles({ disablePaddings });

  const handleDeleteMessages = () => {
    deleteMessages(filter.folder_id, selectedMessages.map(({ uid }) => uid));
  };

  const saveEmail = () => {
    openModal(EmailSaveModal, {
      payload: {
        disableCheckbox: true,
        folderID: filter.folder_id,
        messageUIDs: selectedMessages.map(({ uid }) => uid),
        accounts
      }
    });
  };

  return (
    !!pagination.total &&
      <>
        <Box className={classes.actions}>
          <Box mr={1}>
            <Checkbox
              withoutFormik
              size="small"
              edge="start"
              checked={allItemsIsSelected()}
              indeterminate={!!selectedMessages.length && !allItemsIsSelected()}
              onClick={toggleAllItemsSelection}
            />
          </Box>

          {isMobile && !isOpenSidebar && (
            <Box mr={1}>
              <IconButton color="primary" onClick={onOpenSideBar}>
                <SvgIcon color="inherit">
                  <ChevronLeftIcon color="primary" />
                </SvgIcon>
              </IconButton>
            </Box>
          )}

          {!!selectedMessages.length &&
            <>
              <SelectedItemsCount length={selectedMessages.length} total={pagination.total}/>
              <VerticalDivider verticalGutters={1} horizontalGutters={1}/>

              <IconButton color="error" onClick={handleDeleteMessages}>
                <SvgIcon><Icon path={mdiTrashCanOutline} /></SvgIcon>
              </IconButton>

              <IconButton color="success" onClick={saveEmail}>
                <SaveOutlinedIcon />
              </IconButton>
            </>
          }
        </Box>

        {!disableMessages && !!selectedMessages.length &&
          <Messages messages={selectedMessages} />
        }
      </>
  );
};
