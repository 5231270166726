import { forwardRef } from 'react';
import cn from 'classnames';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%'
  }
}));

export const Page = forwardRef(({ children, className, ...props }, ref) => {
  const classes = useStyles();

  return (
    <Paper ref={ref} elevation={2} className={cn(classes.root, className)} {...props}>
      {children}
    </Paper>
  );
});
