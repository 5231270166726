export const TESTS_FETCH_REQUEST = 'TESTS_FETCH_REQUEST';
export const TESTS_FETCH_SUCCESS = 'TESTS_FETCH_SUCCESS';
export const ADD_TEST = 'ADD_TEST';
export const UPDATE_TEST_IN_LIST = 'UPDATE_TEST_IN_LIST';
export const DELETE_TEST_SUCCESS = 'DELETE_TEST_SUCCESS';
export const RESET_TESTS = 'RESET_TESTS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const MASS_DELETE_TESTS = 'MASS_DELETE_TESTS';
export const TOGGLE_TEST_SELECTION = 'TOGGLE_TEST_SELECTION';
export const TOGGLE_ALL_TESTS_SELECTION = 'TOGGLE_ALL_TESTS_SELECTION';
