import { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IconComponent } from '../../../../../components/saved-filters';
import { UsersSelect } from '../../../../../components/users';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../../helpers/dates';
import { AppointmentsContext } from '../../../../../components/appointments';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { DatePicker, ApptBooksSelect, OfficesLocationSelect } from '../../../../../components/FormField';
import { saveFilter } from '../../../../../store/lastFilters';
import { filterFieldsMap, filterFieldsLabels } from './filterFieldsMap';
import { initialValues } from './initialValues';
import { List } from './List';

const MODAL_WIDTH = 840;

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { applyFilter } = useContext(AppointmentsContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleOfficesChange = (offices) => {
    applyFilter({ offices: offices.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, offices }));
  };

  const handleUsersMultiSelect = (name) => (options) => {
    const users = options?.length ? options.map(({ id }) => id) : null;

    applyFilter({ [name]: users });
    setRelationsForFilter((state) => ({ ...state, [name]: options }));
  };

  const handleApptBooksChange = (options) => {
    applyFilter(({ appointment_books: options?.map(({ id }) => id) }));
    setRelationsForFilter((state) => ({ ...state, appointment_books: options }));
  };

  const handleDatePickerChange = (name, transformer) => (date) => {
    applyFilter({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);
    applyFilter({
      ...filter,

      appointment_from: filter?.appointment_from,
      appointment_to: filter?.appointment_to,
      appointment_books: filter?.appointment_books?.map(({ id }) => id),
      providers: filter?.providers?.map(({ id }) => id),
      offices: filter?.offices?.map(({ id }) => id)
    });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    applySavedFilter({
      ...relationsForFilter,
      ...lastFilters
    });
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      hiddenFields={hiddenFields}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      onReset={() => {
        applySavedFilter(initialValues);
      }}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.appointmentFrom,
          label: filterFieldsLabels[filterFieldsMap.appointmentFrom],
          field: (
            <Box minWidth={140}>
              <DatePicker
                clearable
                zeroMinWidth
                name="appointment_from"
                label="From"
                onChange={handleDatePickerChange('appointment_from', unixToStartOfDayUnix)}
              />
            </Box>
          )
        },

        {
          fieldKey: filterFieldsMap.appointmentTo,
          label: filterFieldsLabels[filterFieldsMap.appointmentTo],
          field: (
            <Box minWidth={140}>
              <DatePicker
                clearable
                zeroMinWidth
                name="appointment_to"
                label="To"
                onChange={handleDatePickerChange('appointment_to', unixToEndOfDayUnix)}
              />
            </Box>
          )
        },

        {
          fieldKey: filterFieldsMap.appointmentBooks,
          label: filterFieldsLabels[filterFieldsMap.appointmentBooks],
          field: (
            <ApptBooksSelect
              multiple
              name="appointment_books"
              label="Appt. books"
              onChange={handleApptBooksChange}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.providers,
          label: filterFieldsLabels[filterFieldsMap.providers],
          field: (
            <UsersSelect
              multiple
              name="providers"
              label="Providers"
              minWidth={210}
              params={{ role: rolesMap.doctor }}
              onChange={handleUsersMultiSelect('providers')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.offices,
          label: filterFieldsLabels[filterFieldsMap.offices],
          field: (
            <Box minWidth={210}>
              <OfficesLocationSelect
                multiple
                name="offices"
                label="Offices"
                onChange={handleOfficesChange}
              />
            </Box>
          )
        }
      ]}
    />
  );
};
