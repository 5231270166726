import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import * as authorizationsApi from '../../../api/appointments/authorization';
import { Autocomplete } from '../../FormField';
import { useModal } from '../../ModalsProvider';
import { Label } from '../../Label';
import { AuthorizationFormModal } from '../AuthorizationFormModal';
import { getExpirationDateColor } from '../AuthorizationsPage/utils';

const fetchAuthorizations = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return authorizationsApi.fetchAuthorizations({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

export const AuthorizationsSelect = ({ patientID, appointmentID = null, params = {}, ...props }) => {
  const { openModal } = useModal();

  const addAuthorization = (authorization) => {
    return new Promise((resolve, reject) => {
      openModal(AuthorizationFormModal, {
        payload: {
          authorization: {
            appointment_id: appointmentID,
            authorization,
            user_id: patientID
          }
        },

        onModalResolved: resolve,
        onModalRejected: reject
      });
    });
  };


  const renderOption = (option) => {
    return !option.isCreatableOption ? (
      <Box display="flex" alignItems="center">
        <Typography>
          {option.authorization || '-'}
        </Typography>

        {!!option.expiration_date &&
          <Box display="flex" alignItems="center" ml={1}>
            <Label
              label={moment.unix(option.expiration_date).format('L')}
              color={
                getExpirationDateColor(option.effective_date, option.expiration_date)
              }
            />
          </Box>
        }
      </Box>
    ) : (
      <Box display="flex" alignItems="center">
        <Box display="flex" mr={1}>
          <AddIcon color="primary" />
        </Box>

        <Typography>Add</Typography>
      </Box>
    );
  };

  return (
    <Autocomplete
      isAsync
      isCreatable
      label="Authorizations"
      placeholder="Search authorization..."
      onNeedFetch={fetchAuthorizations(params)}
      getOptionLabel={(authorization) => authorization?.authorization || '-'}
      getOptionValue={(option) => option?.id}
      getOptionSelected={(option, value) => option.id === value?.id}
      renderOption={renderOption}
      onCreate={addAuthorization}

      {...props}
    />
  );
};
