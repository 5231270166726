export const MRIS_FETCH_REQUEST = 'MRIS_FETCH_REQUEST';
export const MRIS_FETCH_SUCCESS = 'MRIS_FETCH_SUCCESS';
export const ADD_MRI = 'ADD_MRI';
export const UPDATE_MRI = 'UPDATE_MRI';
export const DELETE_MRI_SUCCESS = 'DELETE_MRI_SUCCESS';
export const RESET_MRIS = 'RESET_MRIS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const MASS_DELETE_MRIS = 'MASS_DELETE_MRIS';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
