import { Link, withRouter } from 'react-router-dom';
import { AppBar, Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core';

export const Header = withRouter(({ tabsRoutes, location }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <AppBar>
      <Tabs
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          component={Link}
          value={tabsRoutes.payments}
          to={tabsRoutes.payments}
          label="Received"
        />

        <Tab
          component={Link}
          value={tabsRoutes.overdraft}
          to={tabsRoutes.overdraft}
          label="Overdraft"
        />

        {isTablet &&
          <Tab
            component={Link}
            value={tabsRoutes.paymentsHistory}
            to={tabsRoutes.paymentsHistory}
            label="Charts"
          />
        }
      </Tabs>
    </AppBar>
  );
});
