import { api } from '../api';

export const getProfile = (config) => {
  return api.get('/profile', config).then(({ data }) => data).catch((error) => {
    throw error;
  });
};

export const updateProfile = (data) => {
  return api.put('/profile', data).then(({ data }) => data).catch((error) => {
    throw error;
  });
};

export const updateAvatar = (data) => {
  return api.post('/profile/avatar', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const updateSignature = (data) => {
  return api.post('/profile/signatures', data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const uploadDriverLicense = (data) => {
  return api.post('/profile/driver-license', data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const deleteDriverLicense = (tags) => {
  return api.delete('/profile/driver-license', { params: { tags } })
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
