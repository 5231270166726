import { createContext, useRef, useContext, useEffect, useReducer } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useModal } from '../../../../components/ModalsProvider';
import { usePrevious } from '../../../../helpers/hooks';
import { api } from '../../../../api';
import * as filesApi from '../../../../api/billing';
import { FilesFilterContext } from '../../files-common';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const FilesContext = createContext(null);

export const FilesContextProvider = ({
  children,
  initialState: initialStateProp = {}
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const filesLastGlobalAction = useSelector(({ globalActions }) => globalActions.filesLastGlobalAction);
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { isFetched, isFetching, pagination } = state;
  const {
    filter: commonFilter,
    selectedFilesIDs,
    addFilesToSelected,
    deleteFilesFromSelected
  } = useContext(FilesFilterContext);
  const filter = { ...commonFilter, ...state.filter };
  const prevFilter = usePrevious(filter);
  const files = useRef(state.files);
  const cancelFetch = useRef(() => {});

  const fetchFiles = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FILES_REQUEST });

    filesApi.fetchFiles({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_FILES_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFiles({ page: filter.page + 1 });
    }
  };

  const resetFiles = (newFilter) => {
    dispatch({ type: types.RESET_FILES, payload: newFilter });
    deleteFilesFromSelected(files.current.map(({ id }) => id));
    fetchFiles({ page: 1, ...newFilter });
  };

  const deleteFiles = (filesIDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        filesApi.deleteFiles(filesIDs).then(() => {
          dispatch({ type: types.DELETE_FILES, payload: filesIDs });
          enqueueSnackbar('File successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  const allFilesIsSelected = () => {
    return selectedFilesIDs.filter((id) => state.files.find((file) => file.id === id)).length === state.files.length;
  };

  const toggleAllFilesSelection = () => {
    const filesIDs = state.files.map(({ id }) => id);

    allFilesIsSelected() ? deleteFilesFromSelected(filesIDs) : addFilesToSelected(filesIDs);
  };

  const addFiles = (files) => {
    dispatch({ type: types.ADD_FILES, payload: files });
  };

  const providerValue = {
    isFetched,
    isFetching,
    isBillingFile: true,
    files: state.files,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetFiles,
    fetchFiles,
    loadNextPage,
    deleteFiles,
    addFiles,
    allFilesIsSelected,
    toggleAllFilesSelection
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
      deleteFilesFromSelected(files.current.map(({ id }) => id));
    };
  }, []);

  useEffect(() => {
    if (filesLastGlobalAction) {
      if (filesLastGlobalAction.type === types.FILE_UPLOAD_SUCCESS) {
        addFiles([ filesLastGlobalAction.payload ]);
      }

      dispatch(filesLastGlobalAction);
    }
  }, [ filesLastGlobalAction ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetFiles(filter);
    }
  }, [ filter, prevFilter ]);

  return (
    <FilesContext.Provider value={providerValue}>
      {children}
    </FilesContext.Provider>
  );
};
