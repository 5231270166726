export const styles = ({ spacing, typography: { pxToRem }, palette, breakpoints }) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    color: palette.info.main,

    [breakpoints.down('md')]: {
      justifyContent: 'center',
      padding: spacing(1, 0)
    }
  },

  listItemIcon: {
    fontSize: pxToRem(20),
    color: palette.info.main,
    marginRight: spacing()
  },

  workInfoTitle: {
    color: palette.info.main
  }
});
