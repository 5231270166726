import { useContext, useEffect, useRef } from 'react';
import {
  List as MuiList,
  Paper,
  Typography,
  Box
} from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Sticky } from '../../../../../../components/Sticky';
import { CasesContext } from '../../CasesContext';
import { TableHeader } from '../TableHeader';
import { Row } from './Row';

export const minRowHeight = 54;
export const columnsWidths = {
  name: 150,
  description: 300,
  type: 150,
  status: 150,
  members: 210,
  updatedDate: 100,
  lastChange: 160,
  action: 64
};

export const List = () => {
  const {
    cases,
    isFetching,
    isFetched,
    loadNextPage,
    resetCases,
    filter: { per_page, last_page, page }
  } = useContext(CasesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetCases();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={!isFetched ? 2 : 0}>
        <Loader loading={!isFetched} render={
          () => (!cases.length ? (
            <Box p={3}>
              <Typography align="center">No cases found</Typography>
            </Box>
          ) : (
            <>
              <Paper elevation={0}>
                <Sticky containerElement={scrollElementRef.current}>
                  <TableHeader />
                </Sticky>

                <MuiList disablePadding>
                  <InfiniteListLoader
                    items={cases}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    pagination={{ per_page, last_page, page }}
                    RowComponent={Row}
                    onNeedNextPage={loadNextPage}
                    scrollElementRef={scrollElementRef}
                    minRowHeight={minRowHeight}
                  />
                </MuiList>
              </Paper>
            </>
          ))}
        />
      </Box>
    </CustomScrollbars>
  );
};
