import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles({
  disableBorder: {
    borderBottom: 'none'
  }
});

export const TableCell = ({ disableBorder = false, ...props }) => {
  const classes = useStyles();

  return (
    <MuiTableCell
      className={cn({
        [classes.disableBorder]: disableBorder
      })}

      {...props} />
  );
};
