import { TextField, ColorPicker } from '../../../../FormField';

export const ReminderGroupForm = () => {
  return (
    <>
      <TextField
        required
        name="name"
        label="Group name"
        placeholder="Enter name for group..."
        margin="dense"
      />

      <ColorPicker
        required
        name="color"
        label="Color"
        placeholder="Select color..."
        margin="dense"
      />

      <TextField
        required
        multiline
        rowsMax={5}
        name="description"
        label="Group description"
        placeholder="Enter description..."
        margin="dense"
      />
    </>
  );
};
