export const filesOwnersTypesMap = {
  user: 'user',
  profile: 'profile',
  case: 'case',
  task: 'task'
};

export const filesOwnersTypesOptions = [
  {
    value: filesOwnersTypesMap.user,
    label: 'Personal'
  },
  {
    value: filesOwnersTypesMap.profile,
    label: 'Profile'
  },
  {
    value: filesOwnersTypesMap.task,
    label: 'Task'
  },
  {
    value: filesOwnersTypesMap.case,
    label: 'Case'
  }
];
