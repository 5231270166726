import { Yup } from '../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required().max(255),
  username: Yup.string().email().required(),
  password: Yup.string().nullable().required()
    .min(8, 'Use 8 or more characters with a mix of letters, numbers & symbols').max(255),
  incoming: Yup.object().shape({
    host: Yup.string().nullable().required().max(255),
    port: Yup.number().nullable().required(),
    encryption: Yup.string().nullable().oneOf([ 'false', 'ssl', 'tls', 'starttls', 'notls' ]).required()
  }),
  outgoing: Yup.object().shape({
    host: Yup.string().nullable().required().max(255),
    port: Yup.number().nullable().required(),
    encryption: Yup.string().nullable().oneOf([ 'false', 'ssl', 'tls', 'starttls', 'notls' ]).required()
  })
});
