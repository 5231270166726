import { useContext, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../../../components/Loader';
import { orderByLabelsMap } from '../../orderByOptions';
import { columnsWidths } from '../SavedFiltersList';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1}/> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.code}>
        {params?.code || '-'}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.description}>
        {params?.description || '-'}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.status}>
        {params?.is_active?.label || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.orderBy}>
        {!params?.order_by ? '-' : orderByLabelsMap[params?.order_by]}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon/>
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
