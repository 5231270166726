import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { UserLink } from '../../../../../../../components/UserLink';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params.search &&
        <>
          <Grid item xs={4}>
            <Typography>
              Search:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params.search || '-'}
            </Typography>
          </Grid>
        </>
      }

      {params.med_rec &&
        <>
          <Grid item xs={4}>
            <Typography>
              MRN:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params.med_rec || '-'}
            </Typography>
          </Grid>
        </>
      }

      {params.dob &&
        <>
          <Grid item xs={4}>
            <Typography>
              DOB:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params.dob ? moment(params.dob).format('L') : '-'}
            </Typography>
          </Grid>
        </>
      }

      {params.ssn &&
        <>
          <Grid item xs={4}>
            <Typography>
              SSN:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params.ssn || '-'}
            </Typography>
          </Grid>
        </>
      }

      {params.appointment_from &&
        <>
          <Grid item xs={4}>
            <Typography>
              Appt. From:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params.appointment_from ? moment.unix(params.appointment_from).format('L') : '-'}
            </Typography>
          </Grid>
        </>
      }

      {params.appointment_to &&
        <>
          <Grid item xs={4}>
            <Typography>
              Appt. To:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params.appointment_to ? moment.unix(params.appointment_to).format('L') : '-'}
            </Typography>
          </Grid>
        </>
      }

      {params.provider_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Provider:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <UserLink
                noWrap
                size="sm"
                user={params?.provider_id}
              />
            </Box>
          </Grid>
        </>
      }

      {params.insurType &&
        <>
          <Grid item xs={4}>
            <Typography>
              Insur. type:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.insurance_type?.label || params?.insurance_type || '-'}
            </Typography>
          </Grid>
        </>
      }

      {params.expirationDay &&
        <>
          <Grid item xs={4}>
            <Typography>
              Expiration day:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params.expiration_day ? moment(params.expiration_day).format('L') : '-'}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
