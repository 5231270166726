import { estimateTimeHelpers } from '../estimateTimeHelpers';
import { TaskPriority } from '../TaskPriority';
import { UserActivityLink } from '../UserActivityLink';
import { TypesActivity } from '../TypesActivity';
import { Case } from '../Case';
import { Date } from '../Date';
import { Text } from '../Text';

export const taskFieldsMap = {
  type: (before, after) => <TypesActivity before={before} after={after} fieldLabel="Type" />,
  parent: (before, after) => <Case before={before} after={after} fieldLabel="Parent" />,
  owner: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="Owner" />,
  status: (before, after) => <Text before={before?.name || null} after={after?.name || null} fieldLabel="Status" />,
  work_status: (before, after) => <Text before={before} after={after} fieldLabel="Work Status" />,
  name: (before, after) => <Text before={before} after={after} fieldLabel="Task Name" />,
  priority: (before, after) => <TaskPriority before={before} after={after} fieldLabel="Priority" />,
  updated_at: (before, after) => <Date before={before} after={after} fieldLabel="Updated At" />,
  extended_at: (before, after) => <Date before={before} after={after} fieldLabel="Extended At" />,
  description: (before, after) => (
    <Text
      disableSemicolon
      before={before}
      after={after}
      fieldLabel="Description"
    />
  ),
  estimated_time: (before, after) => (
    <Text
      before={estimateTimeHelpers(before)}
      after={estimateTimeHelpers(after)}
      fieldLabel="Estimated Time"
    />
  ),
  text: (before, after) => <Text before={before} after={after} fieldLabel="Create Checklist Item" />,
  case: (before, after) => <Case before={before} after={after} fieldLabel="Case" />,
  due_at: (before, after) => <Date before={before} after={after} fieldLabel="Due Date" />
};
