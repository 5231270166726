import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as sessionsApi from '../../../../api/login-queues/login-sessions';
import { ModalFooter, ModalHeader, ModalBody } from '../../../Modal';
import { Loader } from '../../../Loader';
import { SessionForm, initialValues, validationSchema } from '../SessionForm';

export const EditSessionModal = ({
  DialogProps,
  payload: {
    session
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const addAccount = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return sessionsApi.updateSession(values).then((account) => {
      enqueueSnackbar('Session successfully updated', { variant: 'success' });
      handleModalResolve(account);
    }).catch(({ data: { errors } }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Session not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...session
      }}
      validationSchema={validationSchema}
      onSubmit={addAccount}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Edit note
            </ModalHeader>

            <ModalBody>
              <SessionForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
