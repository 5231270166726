import { useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { getHeightFormat } from '../../../../../helpers/generateHeight';
import {
  bmiTypes,
  bmiTypesColors,
  bmiTypesNames,
  getCategoryBMI,
  heightMap,
  measureTypes,
  weightMap
} from '../../../../../helpers/measureDataMap';
import { useMeasurements } from '../../../../../utils/useMeasurements';
import { NumberMaskField, SimpleSelect, TextField } from '../../../../FormField';
import { ColorPreview } from '../../../../ColorPreview';
import {
  CategorySlider
  // eslint-disable-next-line max-len
} from '../../../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/LeftSidebar/Measurements/CategorySlider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ user }) => {
  const { values, setFieldValue } = useFormikContext();
  const { bmi, onFeetHeightChange } = useMeasurements(values);
  const classes = useStyles({
    color: bmi > 0
      ? bmiTypesColors[getCategoryBMI(bmi)]
      : bmiTypesColors[bmiTypes.underweight]
  });
  const color = bmi > 0 ? bmiTypesColors[getCategoryBMI(bmi)] : bmiTypesColors[bmiTypes.underweight];

  useEffect(() => {
    if (bmi) {
      setFieldValue('bmi', +bmi);
      bmi > 0 && setFieldValue('category', bmi > 0 ?
        bmiTypesNames[getCategoryBMI(bmi)] : bmiTypesNames[bmiTypes.underweight]
      );
    }
  }, [ bmi ]);

  const handleUnitChange = (name) => (event) => {
    onFeetHeightChange(0);
    setFieldValue('height_mask', 0);
    setFieldValue('weight', 0);
    setFieldValue('height', 0);
    setFieldValue('bmi', 0);

    if (name === 'height_unit') {
      if (event.target.value === measureTypes.m) {
        setFieldValue('weight_unit', measureTypes.kg);
      } else {
        setFieldValue('weight_unit', measureTypes.lbs);
      }
    }

    if (name === 'weight_unit') {
      if (event.target.value === measureTypes.kg) {
        setFieldValue('height_unit', measureTypes.m);
      } else {
        setFieldValue('height_unit', measureTypes.feet);
      }
    }
  };

  return (
    <Grid container spacing={2} alignItems="flex-end" justify="space-between">
      <Grid item sm={3} xs={12} container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h4">Age:</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {!get(user, 'profile.birthday', user.birthday) ? '-' :
              moment().diff(moment(get(user, 'profile.birthday', user.birthday)), 'years') + ' y.o.'
            }
          </Typography>
        </Grid>
      </Grid>

      <Grid item sm={4} xs={6}>
        <NumberMaskField
          required
          name="height"
          label="Height"
          margin="dense"
          format={getHeightFormat({
            isFeet: values?.height_unit === measureTypes.feet
          })}
        />
      </Grid>

      <Grid item sm={4} xs={6}>
        <SimpleSelect
          name="height_unit"
          options={heightMap}
          margin="dense"
          onChange={handleUnitChange('height_unit')}
        />
      </Grid>

      <Grid item sm={3} xs={12} container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h4">Gender:</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            {get(user, 'profile.gender', user.gender || null)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item sm={4} xs={6}>
        <TextField
          required
          type="number"
          name="weight"
          label="Weight"
          margin="dense"
        />
      </Grid>

      <Grid item sm={4} xs={6}>
        <SimpleSelect
          name="weight_unit"
          options={weightMap}
          margin="dense"
          onChange={handleUnitChange('weight_unit')}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          disabled
          color="secondary"
          type="number"
          name="bmi"
          label="Your BMI"
          margin="dense"
          InputProps={{
            className: classes.textFieldInput
          }}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          disabled
          color="secondary"
          name="category"
          label="Categoty BMI"
          margin="dense"
          inputProps={{
            className: classes.input
          }}
          InputProps={{
            className: classes.textFieldInput
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <CategorySlider
          color={color}
          categoryValue={bmi > 0 ? +bmi : 15}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Box display="flex" alignItems="center">
          <ColorPreview size={11} borderRadius={4} color={bmiTypesColors[bmiTypes.underweight]} />

          <Box component="span" ml={1}>
            <Typography color="textSecondary" variant="caption">
              {bmiTypesNames[bmiTypes.underweight]}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Box display="flex" alignItems="center">
          <ColorPreview size={11} borderRadius={4} color={bmiTypesColors[bmiTypes.normal]} />

          <Box component="span" ml={1}>
            <Typography color="textSecondary" variant="caption">
              {bmiTypesNames[bmiTypes.normal]}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Box display="flex" alignItems="center">
          <ColorPreview size={11} borderRadius={4} color={bmiTypesColors[bmiTypes.overweight]} />

          <Box component="span" ml={1}>
            <Typography color="textSecondary" variant="caption">
              {bmiTypesNames[bmiTypes.overweight]}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Box display="flex" alignItems="center">
          <ColorPreview size={11} borderRadius={4} color={bmiTypesColors[bmiTypes.obese]} />

          <Box component="span" ml={1}>
            <Typography color="textSecondary" variant="caption">
              {bmiTypesNames[bmiTypes.obese]}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
