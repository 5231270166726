import { useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { amFormatDuration } from '../../../helpers/dates';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TimeCounter = ({ setIsEditExpired, comment }) => {
  const classes = useStyles();
  const [ message, setMessage ] = useState('');

  let interval = null;

  const isEditExpired = () => {
    return moment().unix() >= comment.edit_expired_at;
  };

  const getEditCountdown = () => {
    const duration = amFormatDuration(comment.edit_expired_at - moment().unix());
    if (isEditExpired()) {
      setIsEditExpired(false);
      return '';
    }
    return `edit expired after : ${duration}`;
  };

  const startEdit = () => {

    if (isEditExpired()) {
      setIsEditExpired(false);
    }

    setMessage(getEditCountdown());

    interval = setInterval(() => {

      if (isEditExpired()) {
        setMessage('');
        setIsEditExpired(false);
        clearInterval(interval);
      } else {
        setIsEditExpired(true);
        setMessage(getEditCountdown());
      }
    }, 1000);
  };

  useEffect(() => {
    startEdit();
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.root}>
      {message}
    </div>
  );
};
