import { useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { ProviderIdentifiersSelect, TextField, transformProviderIdentifierToOption } from '../../../FormField';
import { IconButton } from '../../../IconButton';
import { Fab } from '../../../Fab';
import { useModal } from '../../../ModalsProvider';
import { npiEnumerationTypes, SearchNPIRecordsModal } from '../../../SearchNPIRecordsModal';
import { gendersMap } from '../../gendersMap';

export const providerIdentifierTypes = {
  NPI: 'NPI',
  EIN: 'EIN',
  LIC: 'LIC'
};

export const providerIDInitialValues = {
  provider_type_id: null,
  identifier: null
};

export const providerIdentifiers = [
  {
    provider_type_id: transformProviderIdentifierToOption({ id: 1, name: 'National Provider Identifier(NPI)' }),
    identifier: null
  },
  {
    provider_type_id: transformProviderIdentifierToOption({ id: 2, name: 'Employers Identification Number(EIN)' }),
    identifier: null
  },
  {
    provider_type_id: transformProviderIdentifierToOption({ id: 3, name: 'State License(LIC)' }),
    identifier: null
  }
];

export const ProviderIDTypes = () => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  const setFetchedFields = (user, index) => {
    setFieldValue('first_name', user.basic.first_name || values.first_name);
    setFieldValue('last_name', user.basic.last_name || values.last_name);
    setFieldValue('gender', (user.basic.gender === 'M') ? gendersMap[0] : gendersMap[1]);
    setFieldValue(`provider_id_types[${index}].identifier`, user.number);
  };

  const openFiltersModal = (index) => () => {
    const initialValues = {
      enumeration_type: npiEnumerationTypes.user,
      first_name: values?.first_name,
      last_name: values?.last_name
    };

    openModal(SearchNPIRecordsModal, {
      payload: { initialValues },
      onModalResolved: (user) => {
        setFetchedFields(user, index);
      }
    });
  };

  useEffect(() => {
    if (values.role === 'doctor' && !values?.provider_id_types?.length) {
      setFieldValue('provider_id_types', providerIdentifiers);
    }
  }, []);

  useEffect(() => {
    if (values.role === 'doctor' && values?.provider_id_types?.length) {
      setFieldValue('provider_id_types', values.provider_id_types?.map((item, index) => ({
        ...item,
        identifier: !!values?.provider_id_types?.[index]?.provider_type ? item.identifier : null,
        provider_type_id: transformProviderIdentifierToOption(values?.provider_id_types?.[index]?.provider_type) ||
          providerIdentifiers?.[index]?.provider_type_id
      })));
    }
  }, [ values.role ]);

  return (
    <Box my={3}>
      <FieldArray
        name="provider_id_types"
        render={({ remove, push }) => (
          <>
            <Box mb={2} color="info.main" display="flex" alignItems="center">
              <Typography variant="h6" color="inherit">
                Provider ID Type
              </Typography>

              <Box component="span" ml={2}>
                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon />}
                  onClick={() => push(providerIDInitialValues)}
                >
                  Add
                </Fab>
              </Box>
            </Box>

            {values?.provider_id_types?.map((type, i) => (
              <Box display="flex" flexWrap="nowrap" mb={3} key={i}>
                {i > 2 &&
                  <Box mt={3.5} mr={0.5} alignSelf="flex-start">
                    <Typography variant="h5" component="span">{i - 2}.</Typography>
                  </Box>
                }

                <Box flex={1}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} lg={6}>
                      <ProviderIdentifiersSelect
                        isDisabled={i < 3}
                        name={`provider_id_types[${i}].provider_type_id`}
                      />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <TextField
                        required={values.role === rolesMap.doctor}
                        name={`provider_id_types[${i}].identifier`}
                        label="Provider ID"
                        placeholder="Enter Provider ID..."
                        InputProps={{
                          endAdornment: (
                            <>
                              {type.provider_type?.code === providerIdentifierTypes.NPI &&
                                <IconButton color="primary" size="small" onClick={openFiltersModal(i)}>
                                  <EditOutlinedIcon />
                                </IconButton>
                              }
                            </>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {i > 2 &&
                  <Box mt={3} alignSelf="flex-start">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={handleClearField(remove, i)}
                    >
                      <ClearIcon fontSize="small"/>
                    </IconButton>
                  </Box>
                }
              </Box>
            ))}
          </>
        )}
      />
    </Box>
  );
};
