import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../../components/Loader';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { useResizeObserver } from '../../../../../../../helpers/hooks/useResizeObserver';
import { InvoicesContext } from '../../../InvoicesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const List = ({
  hiddenColumns,
  paymentForm,
  isEditable,
  parentPage,
  modelID,
  modelType,
  payment,
  caseItem,
  selectedInvoices,
  setSelectedInvoices,
  onInvoiceSelect
}) => {
  const {
    isFetched,
    invoices,
    meta,
    applyFilter,
    resetInvoices
  } = useContext(InvoicesContext);
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });

  useEffect(() => {
    resetInvoices();
  }, []);

  useEffect(() => {
    if (paymentForm) {
      applyFilter(({ model_to_id: modelID, model_to_type: modelType }));
    }
  }, [ paymentForm, modelID, modelType ]);

  return (
    <Box ref={rootRef} height="100%" display="flex" flexDirection="column">
      <Loader loading={!isFetched} p={2} render={
        () => !invoices.length ? (
          <Box p={2}>
            <Typography align="center">No invoices found</Typography>
          </Box>
        ) : (
          <>
            <TableHeader
              disableToggle={!!onInvoiceSelect}
              hiddenColumns={hiddenColumns}
              paymentForm={paymentForm}
              parentPage={parentPage}
              width={width}
              selectedInvoices={selectedInvoices}
              setSelectedInvoices={setSelectedInvoices}
            />

            <MuiList disablePadding>
              {invoices.map((invoice) => (
                <Row
                  key={invoice.id}
                  isEditable={isEditable}
                  invoice={invoice}
                  caseItem={caseItem}
                  parentPage={parentPage}
                  payment={payment}
                  invoices={invoices}
                  width={width}
                  onInvoiceSelect={onInvoiceSelect}
                  paymentForm={paymentForm}
                  selectedInvoices={selectedInvoices}
                  setSelectedInvoices={setSelectedInvoices}
                  hiddenColumns={hiddenColumns}
                />
              ))}
            </MuiList>

            <TablePagination pagination={meta} onChange={resetInvoices} />
          </>
        )}
      />
    </Box>
  );
};
