import { Yup } from '../../../utils/validation';

export const validationSchema = Yup.object().shape({
  referring_physician_id: Yup.number().nullable().required(),
  is_pending: Yup.boolean(),
  authorization: Yup.string().nullable().when('is_pending', {
    is: false,
    then: Yup.string().required().max(25, 'Max 25 symbols')
  }),
  procedures: Yup.array().of(Yup.object().shape({
    procedure_id: Yup.mixed().nullable().required(),
    laterality: Yup.string().nullable().required(),
    visits: Yup.number().nullable().required(),
    min: Yup.number().nullable().required().max(9999, 'Max 9999')
  }))
});
