import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import {
  Dialog,
  SvgIcon,
  Button,
  Typography
} from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiFax } from '@mdi/js';
import { useState } from 'react';
import * as faxesApi from '../../../../../api/sip';
import { FilePicker } from '../../../../../components/FilePicker';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { initialValues } from '../../ActionsBar/SendFaxModal/initialValues';
import { validationSchema } from '../../ActionsBar/SendFaxModal/validationSchema';

export const ReplyFaxModal = ({
  DialogProps,
  payload: {
    initialValues: initialValuesProp
  },
  handleModalResolve,
  handleModalReject
}) => {
  const [ attachments, setAttachments ] = useState([]);
  const [ availableFilesIDs, setAvailableFilesIDs ] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleAttachments = (files) => {
    setAttachments(files);
  };

  const handleAvailableFilesIDs = (filesIDs) => {
    setAvailableFilesIDs(filesIDs);
  };

  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    const formData = new FormData();

    for (let key in values) {
      formData.append(key, values[key]);
    }

    if (attachments[0]) {
      formData.append('file', attachments[0], attachments[0]?.name);
    }

    if (availableFilesIDs[0]) {
      formData.append('file_id', availableFilesIDs[0]);
    }

    return faxesApi.sendFax(formData).then(() => {
      enqueueSnackbar('Document sent successfully!', { variant: 'success' });
      handleModalResolve();
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ ...initialValues, ...initialValuesProp }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiFax} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Reply
              &nbsp;
              <Typography color="textSecondary">
                to
                &nbsp;
                {initialValuesProp.to}
                &nbsp;
                {initialValuesProp.name}
              </Typography>
            </ModalHeader>

            <ModalBody>
              <FilePicker
                onChangeFiles={handleAttachments}
                onChangeAvailableFilesIDs={handleAvailableFilesIDs}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
