import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { IconButton } from '../../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../components/Loader';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.dateFrom}>
        {params?.date_from ? moment.unix(params?.date_from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateTo}>
        {params?.date_to ? moment.unix(params?.date_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.name}>
        <Typography noWrap>{params?.name|| '-'}</Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.physicianId}>
        <Typography noWrap>
          {params?.physician_id ? `${params?.physician_id?.first_name} ${params?.physician_id?.last_name}` : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        <Typography noWrap>
          {params?.office_id?.name || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
