import { useContext, useEffect } from 'react';
import { Button, Box, useTheme, useMediaQuery } from '@material-ui/core';
import {
  Page,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../../../components/Page';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { CodesContext } from '../CodesContext';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { ExportCPTCodes } from './ExportCPTCodes';
import { List } from './List';

export const minRowHeight = 52;
export const columnsWidths = {
  checkbox: 58,
  procedure_code: 130,
  description: 240,
  base_unit: 80,
  add_on: 100,
  add_icon: 60,
  actionButton: 184,
  action: 100,
  create_icon: 60,
  delete_icon: 60
};

export const MainContent = ({ filterKey }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { importCodes, resetCodes } = useContext(CodesContext);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  useEffect(() => {
    resetCodes();
  }, []);

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>
          CPT
        </PageHeaderTitle>

        <PageHeaderActions>
          <Button
            size={isMobile ? 'small' : 'medium'}
            variant="contained"
            color="primary"
            onClick={importCodes}
          >
            import
          </Button>

          <ExportCPTCodes/>
        </PageHeaderActions>
      </PageHeader>

      <PageHeader>
        <ActionsBar filterOpenButton={filterOpenButton}  />
      </PageHeader>

      <Box clone mt={isMobile ? 0 : 2} mx={isMobile ? 0 : 3}>
        <Page variant={isMobile ? 'elevation' : 'outlined'} square>
          <ScrollWrapper {...scrollWrapperProps}>
            <FilterBarWrapper {...filterBarWrapperProps}>
              <PageHeader disablePaddings disableBorderBottom>
                <FiltersBar filterKey={filterKey} />
              </PageHeader>
            </FilterBarWrapper>
          </ScrollWrapper>

          <List/>
        </Page>
      </Box>
    </Page>
  );
};
