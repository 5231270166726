import { Box, Grid, Typography } from '@material-ui/core';
import { TextField } from '../../../FormField';

export const Lab = () => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">
          Lab
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextField
            name="hemoglobin"
            label="Hemoglobin"
            placeholder="Enter hemoglobin..."
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            name="hematocrit"
            label="Hematocrit"
            placeholder="Enter hematocrit..."
          />
        </Grid>
      </Grid>
    </>
  );
};
