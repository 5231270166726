import { makeStyles } from '@material-ui/core/styles';
import { AccountsProvider, SavedEmails } from '../../../../EmailsPage';
import { filterFieldsMap } from '../../../../EmailsPage/SavedEmails/FiltersBar/filterFieldsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const hiddenFilterFields = [
  filterFieldsMap.caseID
];

export const Emails = ({ caseItem }) => {
  const classes = useStyles();
  const filter = {
    case_id: caseItem.id
  };

  return (
    <div className={classes.root}>
      <AccountsProvider disableCounters>
        <SavedEmails
          filter={filter}
          caseItem={caseItem}
          hiddenFilterFields={hiddenFilterFields}
        />
      </AccountsProvider>
    </div>
  );
};
