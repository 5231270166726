import cn from 'classnames';
import { makeStyles, AppBar, Tabs as MuiTabs } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Tabs = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <AppBar className={cn(classes.root, className)}>
      <MuiTabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"

        {...props}
      />
    </AppBar>
  );
};
