export const orderByMap = {
  created_at: 'created_at',
  description: 'description',
  code: 'code'
};

export const orderByLabelsMap = {
  [orderByMap.created_at]: 'Created Date',
  [orderByMap.description]: 'Description',
  [orderByMap.code]: 'Code'
};

export const orderByOptions = [
  { label: orderByLabelsMap.created_at, value: orderByMap.created_at },
  { label: orderByLabelsMap.description, value: orderByMap.description },
  { label: orderByLabelsMap.code, value: orderByMap.code }
];
