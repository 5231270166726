import { useState } from 'react';
import { Box } from '@material-ui/core';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import { SubstitutedInContent, EditSubstitutedInModal } from './SubstitutedInContent';

export const SubstitutedIn = ({
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  onCaseItemUpdate = () => {},
  ...props
}) => {
  const { openModal } = useModal();
  const [ substitutedInfo, setSubstitutedInfo ] = useState(caseItem.substituted_in);

  const openWidgetModal = () => {
    openModal(EditSubstitutedInModal, {
      payload: { substitutedInfo: { caseItemID: caseItem.id, ...substitutedInfo } },
      onModalResolved: (value) => {
        setSubstitutedInfo(value.substituted_in);
        onCaseItemUpdate(value);
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      title="Case Substituted in form"
      rightActions={[
        <WidgetsActionButtons
          isEditable={!hasRole(rolesMap.patient, rolesMap.client)}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          page={page}
          isMinimizable
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
          openWidgetModal={openWidgetModal}
        />
      ]}
      {...props}
    >
      <Box height={height || '100%'}>
        <SubstitutedInContent substitutedInfo={substitutedInfo} />
      </Box>
    </ContentCard>
  );
};
