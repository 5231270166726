export const styles = ({
  spacing,
  palette: { grey },
  typography: { pxToRem }
}) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: spacing(3, 6, 2, 4)
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '30%'
  },

  mainContentEmpty: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    width: 'auto'
  },

  title: {
    fontSize: pxToRem(14),
    marginBottom: spacing(1.5)
  },

  titleAmount: {
    textAlign: 'right',
    marginRight: spacing(4)
  },

  contentAmount: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: spacing(1, 2),
    backgroundColor: grey[50],
    height: '100%'
  },

  currencyFormat: {
    fontSize: pxToRem(48)
  },

  contentInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: spacing()
  },

  contentInfo__title: {
    color: grey[600]
  },

  contentInfo__titleSecondary: {
    color: grey[400]
  },

  contentInfo__address: {
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  },

  contentInfo__icon: {
    marginRight: spacing()
  },

  contentInfoLocation: {
    marginLeft: spacing(-0.25)
  },

  userLink: {
    color: grey[600],
    fontSize: pxToRem(14)
  },

  paymentContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  paymentContentEmpty: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '50%'
  },

  content__selectEmpty: {
    width: '50%',
    paddingRight: spacing(2)
  }
});
