import moment from 'moment';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ deposition }) => {
  const classes = useStyles();
  const daysLeft = deposition.due_date && moment(deposition.due_date).diff(moment(), 'days');

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>
            Date Received
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {deposition.date_received ? moment(deposition.date_received).format('L LT') : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>
            Date Responded
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {deposition.date_responded ? moment(deposition.date_responded).format('L LT') : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>
            Deposition Date
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography color={daysLeft <= 5 ? 'error' : 'textPrimary'}>
            {deposition.deposition_date ? moment(deposition.deposition_date).fromNow() : '-'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
