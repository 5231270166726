import { FeedsProvider } from '../../../../components/feeds/FeedsProvider';
import { Feeds } from '../../../../components/feeds/Feeds';

export const FeedPage = () => {
  return (
    <FeedsProvider>
      <Feeds />
    </FeedsProvider>
  );
};
