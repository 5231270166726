import { useRef } from 'react';
import { Typography } from '@material-ui/core';
import { Cards } from '../../../../../../../components/Cards';
import { CustomScrollbars } from '../../../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../../../components/Loader';
import { Card } from './Card';

export const Grid = ({
  isFetched = true,
  filteredUsers,
  caseItem,
  onCaseItemUpdate
}) => {
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader loading={!isFetched} p={3} render={
        () => !filteredUsers.length ? (
          <Typography align="center">No users found</Typography>
        ) : (
          <Cards size={270} list={filteredUsers} cardProps={{ caseItem, onCaseItemUpdate }} component={Card} />
        )}
      />
    </CustomScrollbars>
  );
};
