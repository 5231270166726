export const styles = ({ spacing, palette: { grey } }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: spacing(1, 2),
    zIndex: 1
  },

  root_border: {
    borderBottom: `1px solid ${grey[100]}`
  },

  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },

  actionButton: {
    marginLeft: spacing(2)
  },

  actionButton__btn: {
    paddingRight: spacing(),
    paddingLeft: spacing()
  },

  actionButton__icon: {
    marginRight: spacing()
  },

  actionCancel: {
    display: 'flex',
    alignItems: 'center'
  },

  actionCancel__btn: {
    color: grey[400]
  },

  actionUserLink: {
    display: 'flex',
    alignItems: 'center'
  },

  actionEditButton: {
    marginLeft: spacing(3)
  },

  hide: {
    display: 'none'
  }
});
