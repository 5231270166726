import { FieldArray, useFormikContext } from 'formik';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {
  relationshipMap
  // eslint-disable-next-line max-len
} from '../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/LeftSidebar/Contacts/ContactsModal';
import { SvgIcon } from '../../SvgIcon';
import { Fab } from '../../Fab';
import {
  KeyboardDatePicker,
  PhoneField,
  Select,
  TextField
} from '../../FormField';

const initialValues = {
  user_id: null,
  first_name: null,
  last_name: null,
  relationship: null,
  phone: null,
  dob: null
};

export const EmergencyFormContact = () => {
  const { values } = useFormikContext();

  return (
    <Grid item xs={12}>
      <FieldArray
        name="emergency_contact"
        render={({ remove, push }) => (
          <Box mt={2}>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" color="secondary">Emergency Contact</Typography>

              <Box ml={2}>
                <Fab
                  variant="extended"
                  color="primary"
                  size="small"
                  onClick={() => push(initialValues)}
                  startIcon={<AddIcon />}
                >
                  Add
                </Fab>
              </Box>
            </Box>

            {values?.emergency_contact?.map((item, index) => (
              <Grid key={index} container spacing={2} component={Box} py={2}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton
                      size="small"
                      onClick={() => remove(index)}
                    >
                      <SvgIcon color="error">
                        <CloseIcon />
                      </SvgIcon>
                    </IconButton>
                  </Box>
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    name={`emergency_contact.${index}.first_name`}
                    label="First Name"
                  />
                </Grid>

                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    name={`emergency_contact.${index}.last_name`}
                    label="Last Name"
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <PhoneField
                    required
                    name={`emergency_contact.${index}.phone`}
                    label="Phone"
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <KeyboardDatePicker
                    name={`emergency_contact.${index}.dob`}
                    label="DOB"
                    outputFormat="YYYY-MM-DD"
                    placeholder="MM/DD/YYYY"
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Select
                    isClearable
                    formattedValue
                    name={`emergency_contact.${index}.relationship`}
                    label="Relationship"
                    options={relationshipMap}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
      />
    </Grid>
  );
};
