import { useContext } from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { stopPropagation } from '../../../../../../helpers/dom';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../components/UserLink';
import { Date } from '../../../../../../components/Date';
import { ReportsPreviewContext } from '../../../ReportsPreviewProvider';

export const MainInfo = () => {
  const { openModal } = useModal();
  const { selectedItem: note } = useContext(ReportsPreviewContext);

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">
            Patient
          </Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <UserLink
            variant="h5"
            user={note.patient}
            onClick={stopPropagation(openUserPreview(note?.patient?.id))}
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">
            Provider
          </Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <UserLink
            variant="h5"
            user={note.provider}
            onClick={stopPropagation(openUserPreview(note?.provider?.id))}
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">
            DOB
          </Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Date
            variant="h5"
            date={note?.patient?.birthday}
            iconFontSize="small"
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">
            Office Name
          </Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <OfficeLink
            variant="h5"
            office={note?.office}
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">
            DOI
          </Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Date
            variant="h5"
            disableMoment
            date={note?.doi_string || moment(note.doi).format('L')}
            iconFontSize="small"
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">
            Office Address
          </Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Typography variant="h5">
            {note?.office?.full_address || '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} component={Box} py={4}>
        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">
            Date of Report
          </Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Date
            variant="h5"
            date={note.dor}
            iconFontSize="small"
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">
            Source Name
          </Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Typography variant="h5">
            {note.name || '-'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center" fontWeight={500} mb={0.5}>
            <NotesIcon fontSize="small"/>

            <Box ml={1}>
              <Typography variant="h5">Note</Typography>
            </Box>
          </Box>

          <Typography>
            {note.note || 'No info'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
