import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { fieldsKeysMap, filterLabels } from '../../filterKeysMap';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell grow noWrap flexBasis={columnsWidths.users}>
        {filterLabels[fieldsKeysMap.userIDs]}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.logName}>
        {filterLabels[fieldsKeysMap.logName]}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.subjectType}>
        {filterLabels[fieldsKeysMap.subjectType]}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.model}>
        {filterLabels[fieldsKeysMap.model]}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.modelID}>
        {filterLabels[fieldsKeysMap.modelID]}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.date}>
        {filterLabels[fieldsKeysMap.date]}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
