export const fieldsKeysMap = {
  patients: 'patients',
  patient: 'patient_id',
  fromDate: 'from_date',
  toDate: 'to_date',
  action: 'action',
  fromType: 'from_type',
  formType: 'form_type',
  physician: 'physician',
  insurance: 'insurance',
  fromDoi: 'from_doi',
  toDoi: 'to_doi',
  users: 'users',
  insuranceId: 'insuranceId',
  providerId: 'provider_id',
  name: 'name',
  search: 'search'
};
