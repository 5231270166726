import { isNumber } from 'lodash';
import { createHookReducer } from '../../../../helpers/createHookReducer';
import { moveArrayItem } from '../../../../helpers/moveArrayItem';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_TAGS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_TAGS_SUCCESS]: (
    { filter, tags, ...state },
    { data: newTags, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      tags: (page > 1 ?
        tags.filter(({ id }) => !newTags.find((loadedTag) => id === loadedTag.id)).concat(newTags)
        :
        newTags
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_TAGS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_TAG]: (state, tag) => {
    const { pagination, filter, tags, ...otherState } = state;
    const isNotEqualToIsSystemFilter = isNumber(filter.is_system) && filter.is_system !== tag.is_system;

    if (isNotEqualToIsSystemFilter) {
      return state;
    }

    const total = pagination.total + 1;
    const page = Math.ceil((tags.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      tags: [ tag, ...tags ]
    };
  },

  [types.ADD_CHILDREN_TAG]: ({ tags, ...state }, { id }) => {
    return {
      ...state,

      tags: tags.map((tag) => {
        return id === tag.id ? {
          ...tag,

          children_count: (tag.children_count ?? 0) + 1
        } : tag;
      })
    };
  },

  [types.DELETE_CHILDREN_TAG]: ({ tags, ...state }, { id }) => {
    return {
      ...state,

      tags: tags.map((tag) => {
        return id === tag.id ? {
          ...tag,

          children_count: tag.children_count - 1
        } : tag;
      })
    };
  },

  [types.UPDATE_TAG]: ({ tags, ...state }, updatedTag) => {
    return {
      ...state,

      tags: tags.map((tag) => updatedTag.id === tag.id ? updatedTag : tag)
    };
  },

  [types.UPDATE_TAGS_POSITIONS]: ({ tags, ...state }, positions) => {
    const { oldIndex, newIndex } = positions;

    return {
      ...state,

      tags: moveArrayItem(tags, oldIndex, newIndex)
    };
  },

  [types.DELETE_TAG]: (state, tagID) => {
    const { pagination, filter, tags } = state;
    const isExistedTag = !!tags.find((tag) => tagID === tag.id);

    if (!isExistedTag) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((tags.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      tags: tags.filter((tag) => tagID !== tag.id)
    };
  }
});
