import { expensesStatusesMap } from '../../../../../../components/billing';
import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import _ from 'lodash';
import { initialState } from './initialState';
import * as types from './types';

export const reducer = createHookReducer({
  [types.EXPENSES_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.EXPENSES_FETCH_SUCCESS]: (
    { filter, expenses, selectedExpensesIDs, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      selectedExpensesIDs: [],
      selectedExpenses: [],
      filter: { ...filter, page, per_page },
      expenses: data
    };
  },

  [types.ADD_EXPENSE]: (state, payload) => {
    const { pagination, filter, expenses, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((expenses.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page: page === 0 ? 1 : page },
      expenses: [ payload, ...expenses ]
    };
  },

  [types.UPDATE_EXPENSE_IN_LIST]: ({ expenses, ...state }, updatedExpense) => {
    return {
      ...state,

      expenses: expenses.map((expense) => {
        return expense.id === updatedExpense.id ? updatedExpense : expense;
      })
    };
  },

  [types.APPROVE_EXPENSE]: (state, expenseID) => {
    return {
      ...state,

      expenses: state.expenses.map((expense) => {
        return expenseID !== expense.id ? expense : {
          ...expense,

          status: expensesStatusesMap.approved
        };
      }),

      selectedExpenses: state.selectedExpenses.map((expense) => {
        return expenseID !== expense.id ? expense : {
          ...expense,

          status: expensesStatusesMap.approved
        };
      })
    };
  },

  [types.DISAPPROVE_EXPENSE]: (state, expenseID) => {
    return {
      ...state,

      expenses: state.expenses.map((expense) => {
        return expenseID !== expense.id ? expense : {
          ...expense,

          status: expensesStatusesMap.submit
        };
      }),

      selectedExpenses: state.selectedExpenses.map((expense) => {
        return expenseID !== expense.id ? expense : {
          ...expense,

          status: expensesStatusesMap.submit
        };
      })
    };
  },

  [types.MASS_EXPENSES_APPROVE]: ({ expenses, ...state }, IDs) => {
    return {
      ...state,

      selectedExpensesIDs: [],
      selectedExpenses: [],
      expenses: expenses.map((expense) => {
        return !IDs.includes(expense.id) ? expense : {
          ...expense,

          status: expensesStatusesMap.approved
        };
      })
    };
  },

  [types.MASS_EXPENSES_DISAPPROVE]: ({ expenses, ...state }, IDs) => {
    return {
      ...state,

      selectedExpensesIDs: [],
      selectedExpenses: [],
      expenses: expenses.map((expense) => {
        return !IDs.includes(expense.id) ? expense : {
          ...expense,

          status: expensesStatusesMap.submit
        };
      })
    };
  },

  [types.DELETE_EXPENSE]: ({ expenses, pagination, filter, ...state }, expenseId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      expenses: expenses.filter((expense) => expense.id !== expenseId),
      selectedExpensesIDs: state.selectedExpensesIDs.filter((id) => id !== expenseId),
      selectedExpenses: state.selectedExpenses.filter(({ id }) => id !== expenseId)
    };
  },

  [types.MASS_DELETE_EXPENSE]: ({ expenses, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedExpensesIDs: [],
      selectedExpenses: [],
      pagination: { ...pagination, total, last_page },
      expenses: expenses.filter((expense) => IDs.indexOf(expense.id) < 0)
    };
  },

  [types.RESET_EXPENSES]: ({ filter, ...state }, newFilter) => {
    const prevFilter = _.isEqual(newFilter, { ...initialState.filter }) && newFilter;

    return {
      ...state,

      isFetched: false,
      filter: prevFilter || { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.TOGGLE_ALL_EXPENSE_SELECTION]: ({ expenses, selectedExpensesIDs, ...state }) => {
    const allExpensesIsSelected = expenses.length === selectedExpensesIDs.length;

    return {
      ...state,

      expenses,
      selectedExpensesIDs: allExpensesIsSelected ? [] : expenses.map((item) => item.id),
      selectedExpenses: allExpensesIsSelected ? [] : expenses
    };
  },

  [types.TOGGLE_EXPENSE_SELECTION]: ({ selectedExpensesIDs, selectedExpenses, ...state }, expense) => {
    return {
      ...state,

      selectedExpensesIDs: selectedExpensesIDs.indexOf(expense.id) !== -1
        ? selectedExpensesIDs.filter((item) => item !== expense.id)
        : selectedExpensesIDs.concat(expense.id),

      selectedExpenses: selectedExpensesIDs.indexOf(expense.id) !== -1
        ? selectedExpenses.filter((item) => item.id !== expense.id)
        : selectedExpenses.concat(expense)
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  }
});
