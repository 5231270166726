import { useModal } from 'react-modal-hook';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { CardItem } from '../../../../../../components/Cards';
import { Date } from '../../../../../../components/Date';
import { Tooltip } from '../../../../../../components/Tooltip';
import { getFileNameWithoutExtension } from '../../../../../../helpers/files';
import { FilePreviewModal, FileTypeIcon } from '../../../../files-common';
import { getFileMenuOptions } from '../../List';
import { transcribeStatusLabelsMap } from '../../transcribeStatusesMap';

export const Card = ({ item: transcribe = {} }) => {
  const [ openAudioFilePreview, closeAudioFilePreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeAudioFilePreview
      }}
      payload={{
        disableFetch: true,
        file: {
          id: {},
          original_filename: transcribe.filename,
          url: transcribe.audio_url,
          aggregate_type: 'audio'
        },
        singleView: true
      }}
      getFileMenuOptions={getFileMenuOptions}
    />
  ), [ transcribe.audio_url ]);

  const [ openTextFilePreview, closeTextFilePreview ] = useModal(({ in: open, onExited }) => {
    const blob = new Blob([ transcribe.text ], {
      type : 'text/plain;charset=utf-8'
    });

    return (
      <FilePreviewModal
        DialogProps={{
          open,
          onExited,
          onCancel: closeTextFilePreview
        }}
        payload={{
          disableFetch: true,
          file: {
            id: {},
            original_filename: getFileNameWithoutExtension(transcribe.filename) + '.txt',
            extension: 'txt',
            url: URL.createObjectURL(blob),
            aggregate_type: 'text'
          },
          singleView: true
        }}
        getFileMenuOptions={getFileMenuOptions}
      />
    );
  }, [ transcribe.text ]);

  return (
    <CardItem disableHover py={1} px={2}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Date:
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Date disableIcon date={transcribe.created_at} format="L LT" />
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Status:
          </Typography>
        </Grid>

        <Grid item xs={8} variant="subtitle1">
          <Typography variant="body1">{transcribeStatusLabelsMap[transcribe.status]}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle1">
            Text:
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {!transcribe.text ? '-' : <Link noWrap onClick={openTextFilePreview}>{transcribe.text}</Link>}
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle1">
            File:
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Box display="flex" alignItems="center">
            {!!transcribe?.files && (
              <FileTypeIcon file={transcribe.files[0]} />
            )}

            <Tooltip title={transcribe.filename}>
              <Link noWrap variant="body1" onClick={openAudioFilePreview}>{transcribe.filename}</Link>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </CardItem>
  );
};
