export const initialValues = {
  financial_class_id: null,
  claim_filing_id: null,
  claim_processing: null,
  timely_filling: null,
  company_id: null,
  office_id: null,
  professional_edi: null,
  institutional_edi: null,
  name: null,
  address_1: null,
  zip: null,
  state: null,
  city: null,
  insurance_type_code: null,
  box_17: null,
  box_24: null,
  box_31: null,
  box_32: null,
  box_33: null,
  payer_code: null,
  eligibility_edi: null,
  code: null,
  main_phone: null,
  claim_phone: null,
  eligibility_phone: null,
  auth_cert_phone: null,
  fax_1: null,
  fax_2: null,
  provider_id_used: null,
  secondary_e_claims: false,
  npi_only: false,
  accept_npi: false,
  icd_10: false
};
