import { Box, Typography } from '@material-ui/core';

export const TooltipTitle = ({ itemTitle = null, children, ...props }) => {
  return (
    <Box display="flex" alignItems="flex-start" width="auto" {...props}>
      {itemTitle &&
        <Box mr={0.5} color="common.white">
          <Typography variant="h5" color="inherit">
            {itemTitle}
          </Typography>
        </Box>
      }

      {children}
    </Box>
  );
};
