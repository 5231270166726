import { ListItemAvatar, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import StarIcon from '@material-ui/icons/Star';
import { getUserFullName } from '../../../helpers/users';
import { Avatar } from '../../Avatar';
import { Badge } from '../../Badge';
import { Tooltip } from '../../Tooltip';
import { UserAvatar } from '../../users';
import { threadTypesMap } from '../ThreadsContext';
import { ThreadTypeIndicator } from './ThreadTypeIndicator';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ThreadAvatar = ({ showUnreadMessagesIndicator = false, thread, ...props }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ profile }) => profile.user);
  const { chatPartner, chatName } = useMemo(() => {
    return {
      chatPartner: thread?.users?.find((user) => user.id !== currentUser.id),
      chatName: thread.name || thread.users
        ?.filter((user) => user.id !== currentUser.id)
        .map(getUserFullName).join(', ')
    };
  }, [ thread.name, thread.users ]);

  return (
    <ThreadTypeIndicator thread={thread} {...props}>
      <Badge
        invisible={!showUnreadMessagesIndicator || !thread.count_unread_messages}
        badgeContent={thread.count_unread_messages}
        color="primary"
        max={999}
      >
        <Badge
          invisible={!thread.has_favorites}
          overlap="circle"
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'top'
          }}
          badgeContent={
            <Tooltip title="Is favorite">
              <StarIcon className={classes.favoriteIcon} />
            </Tooltip>
          }
        >
          <ListItemAvatar className={classes.avatar}>
            {thread.type === threadTypesMap.direct ? (
              <UserAvatar user={chatPartner} size="lg" />
            ) : (
              <Avatar size="lg" alt={chatName?.[0]} />
            )}
          </ListItemAvatar>
        </Badge>
      </Badge>
    </ThreadTypeIndicator>
  );
};
