import { useFormikContext } from 'formik';
import { FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Checkbox, TextField } from '../../FormField';

export const CheckboxesContent = () => {
  const { values, setFieldValue } = useFormikContext();
  const name = 'forms.past_medical_history.check_if_you_currently_suffer_or_have_previously_suffered_from';
  const form = values?.forms?.past_medical_history?.check_if_you_currently_suffer_or_have_previously_suffered_from;

  const handleToggleCheckbox = (name) => (event) => {
    const checked = event.target.checked;

    if (!checked) {
      setFieldValue(`${name}.text`, null);
    }
  };

  return (
    <>
      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>High blood pressure</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.high_blood_pressure.value`}
              onChange={handleToggleCheckbox(`${name}.high_blood_pressure`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.high_blood_pressure?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.high_blood_pressure.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Deep vein thrombosis</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.deep_vein_thrombosis.value`}
              onChange={handleToggleCheckbox(`${name}.deep_vein_thrombosis`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.deep_vein_thrombosis?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.deep_vein_thrombosis.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Liver disease</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.liver_disease.value`}
              onChange={handleToggleCheckbox(`${name}.liver_disease`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.liver_disease?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.liver_disease.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Heart disease or attach</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.heart_disease_or_attach.value`}
              onChange={handleToggleCheckbox(`${name}.heart_disease_or_attach`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.heart_disease_or_attach?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.heart_disease_or_attach.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Stroke</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.stroke.value`}
              onChange={handleToggleCheckbox(`${name}.stroke`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.stroke?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.stroke.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Cancer (where)</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.cancer_where.value`}
              onChange={handleToggleCheckbox(`${name}.cancer_where`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.cancer_where?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.cancer_where.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Elevated cholesterol</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.elevated_cholesterol.value`}
              onChange={handleToggleCheckbox(`${name}.cancer_where`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.elevated_cholesterol?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.elevated_cholesterol.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Ulcer disease</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.ulcer_disease.value`}
              onChange={handleToggleCheckbox(`${name}.ulcer_disease`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.ulcer_disease?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.ulcer_disease.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Gastritis</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.gastritis.value`}
              onChange={handleToggleCheckbox(`${name}.gastritis`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.gastritis?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.gastritis.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Reflux disease</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.reflux_disease.value`}
              onChange={handleToggleCheckbox(`${name}.reflux_disease`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.reflux_disease?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.reflux_disease.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Asthma</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.asthma.value`}
              onChange={handleToggleCheckbox(`${name}.asthma`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.asthma?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.asthma.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Osteoporosis</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.osteoporosis.value`}
              onChange={handleToggleCheckbox(`${name}.osteoporosis`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.osteoporosis?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.osteoporosis.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Kidney disease/ problem</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.kidney_disease_problem.value`}
              onChange={handleToggleCheckbox(`${name}.kidney_disease_problem`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.kidney_disease_problem?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.kidney_disease_problem.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Seizures</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.seizures.value`}
              onChange={handleToggleCheckbox(`${name}.seizures`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.seizures?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.seizures.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Arthritis</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.arthritis.value`}
              onChange={handleToggleCheckbox(`${name}.arthritis`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.arthritis?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.arthritis.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Thyroid (hyper or hypo)</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.thyroid_hyper_or_hypo.value`}
              onChange={handleToggleCheckbox(`${name}.thyroid_hyper_or_hypo`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.thyroid_hyper_or_hypo?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.thyroid_hyper_or_hypo.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Tuberculosis</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.tuberculosis.value`}
              onChange={handleToggleCheckbox(`${name}.tuberculosis`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.tuberculosis?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.tuberculosis.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Pulmonary embolism</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.pulmonary_embolism.value`}
              onChange={handleToggleCheckbox(`${name}.pulmonary_embolism`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.pulmonary_embolism?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.pulmonary_embolism.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Polio</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.polio.value`}
              onChange={handleToggleCheckbox(`${name}.polio`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.polio?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.polio.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Rheumatic fever</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.rheumatic_fever.value`}
              onChange={handleToggleCheckbox(`${name}.rheumatic_fever`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.rheumatic_fever?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.rheumatic_fever.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Gout</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.gout.value`}
              onChange={handleToggleCheckbox(`${name}.gout`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.gout?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.gout.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Depression</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.depression.value`}
              onChange={handleToggleCheckbox(`${name}.depression`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.depression?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.depression.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Diabetes</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.diabetes.value`}
              onChange={handleToggleCheckbox(`${name}.diabetes`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.diabetes?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.diabetes.text`}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <FormControlLabel
          label={<Typography>Other, please list:</Typography>}
          control={
            <Checkbox
              color="primary"
              name={`${name}.other_please_list.value`}
              onChange={handleToggleCheckbox(`${name}.other_please_list`)}
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          disabled={!form?.other_please_list?.value}
          label="Description"
          placeholder="Enter description..."
          name={`${name}.other_please_list.text`}
        />
      </Grid>
    </>
  );
};
