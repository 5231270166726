import { Box } from '@material-ui/core';
import { List } from './List';

export const Feeds = () => {
  return (
    <Box display="flex" flexDirection="column" flexGrow={1} width="100%">
      <List />
    </Box>
  );
};
