import { Fragment, useContext } from 'react';
import cn from 'classnames';
import { Box, Collapse, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Scrollbars } from '../../../../../../components/Scrollbars';
import { UserInfo as MembersUserInfo } from '../../../../Members/User/MainContent/UserInfo';
import { UserContext } from '../../../../Members/User/UserProvider';
import { UserInfo } from '../../../LeftSidebar/UserInfo';
import { Allergies } from './Allergies';
import { Contacts } from './Contacts';
import { FamilyTree } from './FamilyTree';
import { Measurements } from './Measurements';
import { PatientInfo } from './PatientInfo';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const LeftSidebar = ({ openUserInfo = false, toggleInfoOpen = () => {} }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const profile = useSelector(({ profile }) => profile);
  const location = useLocation();
  const isPatient = location.pathname.includes('patients');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const Wrapper = isMobile ? Collapse : Fragment;
  const wrapperProps = isMobile ? {
    in: openUserInfo,
    component: Box,
    height: 55,
    flexShrink: 0,
    collapsedHeight: 55
  } : {};

  return (
    <div
      className={
        cn(
          classes.root,
          { [classes.rootClose]: !openUserInfo }
        )
      }
    >
      <Scrollbars>
        <Wrapper {...wrapperProps}>
          {userContext && !isPatient
            ? <MembersUserInfo openUserInfo={openUserInfo} toggleInfoOpen={toggleInfoOpen} />
            : <UserInfo openUserInfo={openUserInfo} toggleInfoOpen={toggleInfoOpen} />
          }

          <PatientInfo />
          <Contacts />
          <FamilyTree />
          <Measurements user={userContext ? userContext.user : profile.user} />
          <Allergies />
        </Wrapper>
      </Scrollbars>
    </div>
  );
};
