import moment from 'moment';
import {
  measurementsValidationSchema
} from '../../../../../components/medical/forms';
import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required().max(255),
  last_name: Yup.string().nullable().required().max(255),
  ssn: Yup.string().nullable().min(1).max(255),
  phone_number: Yup.string().nullable().max(20).required(),
  mobile_phone: Yup.string().nullable().max(20),
  email: Yup.string().email().nullable().max(255),
  secondary_insurance_id: Yup.mixed().nullable(),
  description: Yup.string().nullable().max(1000),
  measurements: measurementsValidationSchema,
  home: Yup.object().shape({
    street: Yup.string().nullable().required().min(2).max(255),
    country: Yup.string().nullable().required().min(2).max(255),
    zip: Yup.string().nullable().required().min(1).max(255),
    city: Yup.string().nullable().required().min(2).max(255),
    state: Yup.string().nullable().required().min(2).max(255),
    designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
      return unitNumber ? schema.required() : schema;
    }),
    unit_number: Yup.string().nullable().min(1).max(255)
  }),
  referred_by: Yup.string().nullable().max(255),
  primary_treating_physician: Yup.string().nullable().max(255),
  primary_insurance_name: Yup.string().nullable().max(255),
  primary_insurance_id_number: Yup.string().nullable().max(255),
  group_id_number: Yup.string().nullable().max(255),
  primary_insurance_holder: Yup.string().nullable().max(255),
  relationship_to_patient: Yup.string().nullable().max(255),
  primary_insurance_holder_dob: Yup.string().nullable().max(255),
  secondary_insurance_name: Yup.string().nullable().max(255),
  secondary_insurance_id_number: Yup.string().nullable().max(255),
  emergency_contact: Yup.array().of(Yup.object().shape({
    first_name: Yup.string().nullable().max(255),
    last_name: Yup.string().nullable().max(255),
    relationship: Yup.string().nullable().max(20),
    phone: Yup.string().nullable().max(20).required(),
    dob: Yup.date().format('YYYY-MM-DD').nullable()
      .max(moment(), 'Date must be earlier than today')
  })),
  birthday: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment(), 'Date must be earlier than today').required(),
  last_work_date: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'days'), 'Date must be earlier than today'),
  doi: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today').when('is_range', {
      is: (value) => !!value,
      then: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today'),
      otherwise: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today').required()
    }),
  doi_from: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today').when('is_range', {
      is: (value) => !!value,
      then: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today').required()
    }),
  doi_to: Yup.date().format('YYYY-MM-DD').nullable()
    .max(moment().add(1, 'day'), 'Date must be earlier than today').when('is_range', {
      is: (value) => !!value,
      then: Yup.date().format('YYYY-MM-DD').nullable()
        .max(moment().add(1, 'day'), 'Date must be earlier than today').required()
    })
});
