import { useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';
import {
  makeStyles,
  Box,
  Typography,
  Link as MuiLink,
  Button,
  Paper,
  Divider
} from '@material-ui/core';
import { Loader } from '../../../components/Loader';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { loginConfirmation } from '../../../store/auth/operations';
import { fetchProfile } from '../../../store/dashboard/profile';
import { hasRole } from '../../../utils/hasRole';
import { Yup } from '../../../utils/validation';
import { Scrollbars } from '../../../components/Scrollbars';
import { TextField } from '../../../components/FormField';
import FullLogoSVG from '../../Dashboard/Layout/Sidebar/SidebarContent/logo-full.svg';
import { LandingWrapper } from '../../LandingPage';
import { styles } from './styles';

const initialValues = {
  code: null
};

const validationSchema = Yup.object().shape({
  code: Yup.string().nullable().required()
});

const useStyles = makeStyles(styles);

export const LoginConfirmation = ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const makeLogin = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return dispatch(loginConfirmation(values)).then((data) => {
      dispatch(fetchProfile());

      if (hasRole(rolesMap.client, rolesMap.patient) && !data?.user?.visited_at) {
        history.push('/profile/medical-info/medical-forms');
      } else if (hasRole(rolesMap.client, rolesMap.patient)) {
        history.push('/profile');
      } else {
        history.push(location.state?.from || '/');
      }
    }).catch(() => {
      setErrors({ login: 'true' });
    });
  };

  return (
    <LandingWrapper>
      <Scrollbars>
        <Paper className={classes.paper}>
          <div className={classes.paper__header}>
            <FullLogoSVG className={classes.paper__icon} />

            <Typography variant="h2" color="inherit" className={classes.paper__title}>
              Check email, and enter confirmation code
            </Typography>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={makeLogin}
          >
            {({ handleSubmit, isSubmitting, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.login &&
                  <Box color="warning.main">
                    <Typography paragraph color="inherit">
                      Code is incorrect or expired,
                      &nbsp;<MuiLink component={RouterLink} to="/login">login</MuiLink>&nbsp;
                      again please
                    </Typography>

                    <Divider />
                  </Box>
                }

                <TextField
                  required
                  className={classes.input}
                  name="code"
                  label="Code"
                  placeholder="Enter code..."
                  margin="dense"
                />

                <Loader fullWidth surface loading={isSubmitting} render={
                  () => (
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Sign in
                    </Button>
                  )}
                />
              </form>
            )}
          </Formik>
        </Paper>
      </Scrollbars>
    </LandingWrapper>
  );
};
