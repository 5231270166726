import { useContext, useState } from 'react';
import { AppBar, Box, Tab, Tabs } from '@material-ui/core';
import { MessengerContext, ThreadsContextProvider } from '../../../../components/chat';
import { ChatsNavigation } from './ChatsNavigation';
import { ThreadsList } from './ThreadsList';

const tabsMap = {
  myChats: 'myChats',
  archived: 'archived'
};

export const MessengerSidebar = ({ onHideSideBar }) => {
  const [ currentTab, setCurrentTab ] = useState(tabsMap.myChats);
  const { applyFilter } = useContext(MessengerContext);

  const handleChange = (event, tab) => {
    setCurrentTab(tab);
    applyFilter({ is_archive: tab === tabsMap.myChats ? 0 : 1 });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ChatsNavigation />

      <AppBar position="static">
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          value={currentTab}
          onChange={handleChange}
        >
          <Tab value={tabsMap.myChats} label="My Chats" />
          <Tab value={tabsMap.archived} label="Archived" />
        </Tabs>
      </AppBar>

      <Box flexGrow={1}>
        <ThreadsContextProvider>
          <ThreadsList onHideSideBar={onHideSideBar} />
        </ThreadsContextProvider>
      </Box>
    </Box>
  );
};
