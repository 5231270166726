import { api } from '../api';

export const fetchVideos = (config) => {
  return api.get('/videos', config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchVideo = (id, config) => {
  return api.get(`/videos/${id}`, config)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadVideo = (video) => {
  return api.post('/files', video)
    .then(({ data: { data } }) => data)
    .catch((error) => {
      throw error;
    });
};

export const convertVideo = ({ id, ...video }, filter) => {
  return api.post(`/videos/${id}/convert`, video, filter)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const deleteVideo = (id) => {
  return api.delete(`/files/${id}`)
    .then(({ data: { data } }) => data);
};

export const renameVideo = ({ id, original_filename }) => {
  return api.put(`/videos/${id}`, { original_filename });
};

export const fetchFragments = (videoID, config) => {
  return api.get(`/videos/${videoID}/fragments/videos`, config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteFragment = (videoID, fragmentID) => {
  return api.delete(`/videos/${videoID}/fragments/${fragmentID}/video`)
    .then(({ data: { data } }) => data);
};

export const fetchNotes = (id) => {
  return api.get(`/videos/${id}/comments`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error.data;
    });
};

export const addNote = (id, comment) => {
  return api.post(`/videos/${id}/comments`, comment).then(({ data }) => data);
};

export const createFragment = (id, fragment) => {
  return api.post(`/videos/${id}/split`, fragment)
    .then(({ data }) => data.video);
};

export const createScreenshot = (id, screen) => {
  return api.post(`/videos/${id}/screens`, screen)
    .then(({ data }) => data);
};

export const createAudio = (id, audio) => {
  return api.post(`/videos/${id}/audios`, audio)
    .then(({ data }) => data);
};

export const deleteNote = (id) => {
  return api.delete(`/videos/comments/${id}`).then(({ data }) => data);
};

export const fetchScreenshots = (videoID, config) => {
  return api.get(`/videos/${videoID}/fragments/screens`, config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteScreenshot = (id) => {
  return api.delete(`/videos/screens/${id}/`)
    .then(({ data: { data } }) => data);
};
