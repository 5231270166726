export const claimProcessingMap = [
  { value: '15', label: '15' },
  { value: '21', label: '21' },
  { value: '30', label: '30' },
  { value: '45', label: '45' },
  { value: '60', label: '60' },
  { value: '75', label: '75' },
  { value: '90', label: '90' }
];

export const timelyFilingMap = [
  { value: '30', label: '30' },
  { value: '45', label: '45' },
  { value: '60', label: '60' },
  { value: '90', label: '90' },
  { value: '120', label: '120' },
  { value: '150', label: '150' },
  { value: '180', label: '180' },
  { value: '360', label: '360' }
];

export const box17Map = [
  { value: 'DN - Refering Provider', label: 'DN - Refering Provider' },
  { value: 'DK - Ordering Provider ', label: 'DK - Ordering Provider ' },
  { value: 'DQ - Supervising Provider', label: 'DQ - Supervising Provider' }
];

export const box31Map = [
  { value: '', label: 'Select' },
  { value: 'Rendering Provider', label: 'Rendering Provider' }
];

export const box24Map = [
  { value: '0B - State Licence Number', label: '0B - State Licence Number' },
  { value: '1G - Provider Upin Number ', label: '1G - Provider Upin Number ' },
  { value: 'G2 - Provider Commercial Number', label: 'G2 - Provider Commercial Number' },
  { value: 'LU - Location Number', label: 'LU - Location Number' },
  { value: 'ZZ - Provider Taxonomy', label: 'ZZ - Provider Taxonomy' }
];

export const box32Map = [
  { value: '0B - State Licence Number', label: '0B - State Licence Number' },
  { value: 'G2 - Provider Commercial Number', label: 'G2 - Provider Commercial Number' },
  { value: 'LU - Location Number', label: 'LU - Location Number' }
];
