import _ from 'lodash';
import { makeStyles, Box, Typography, List, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import AddIcon from '@material-ui/icons/Add';
import { SelectAvailableFilesModal, FileUploadModal } from '../../app/Dashboard/files-common';
import { Fab } from '../Fab';
import { useModal } from '../ModalsProvider';
import { Popper } from '../Popper';
import { Card } from './Card';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MedicalAttachments = ({ owner_type, owner_id, files }) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const uploadFiles = (files = []) => {
    openModal(FileUploadModal, {
      payload: { files, owner_type, owner_id }
    });
  };

  const handleUploadFilesButtonClicked = () => {
    uploadFiles();
  };

  const openAvailableFilesModal = () => {
    openModal(SelectAvailableFilesModal, {
      payload: { owner_type, owner_id }
    });
  };

  return (
    <Box pb={2}>
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Typography variant="h3">
            {files.length} Attachments
          </Typography>
        </Box>

        <Popper
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <Fab
              size="small"
              color="primary"
              variant="extended"
              buttonRef={anchorRef}
              startIcon={<AddIcon/>}
              onClick={handleToggle}
            >
              Add
            </Fab>
          )}
        >
          {({ handleClose }) => (
            <List>
              <MenuItem onClick={_.flow(handleUploadFilesButtonClicked, handleClose)}>
                <ListItemIcon>
                  <CloudUploadIcon fontSize="inherit" />
                </ListItemIcon>

                <ListItemText primary="Upload new file" />
              </MenuItem>

              <MenuItem onClick={_.flow(openAvailableFilesModal, handleClose)}>
                <ListItemIcon>
                  <FileCopyIcon fontSize="inherit" />
                </ListItemIcon>

                <ListItemText primary="Select from available" />
              </MenuItem>
            </List>
          )}
        </Popper>
      </Box>

      {!files.length ?
        <Box py={2}>
          <Typography>No attachments found</Typography>
        </Box>
        :
        <Box my={2} mx={-1}>
          <div className={classes.filesRow}>
            {files.map((file) => (
              <Card key={file.id} file={file} files={files}/>
            ))}
          </div>
        </Box>
      }
    </Box>
  );
};
