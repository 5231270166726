export const styles = ({ spacing, palette }) => ({
  root: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: spacing(1, 2)
  },

  message: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1
  },

  myMessage: {
    flexDirection: 'row-reverse'
  },

  messageNoAvatar: {
    paddingLeft: spacing(5.5)
  },

  avatar: {
    paddingRight: spacing(1)
  },

  selection: {
    display: 'flex',
    flex: '0 0 50px',
    marginTop: spacing(1),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },

  selectionSmall: {
    flex: '0 0 25px',
    marginTop: spacing(0.5)
  }
});
