import { useFormikContext } from 'formik';
import {
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {
  billingParticipantsTypesMap,
  billingParticipantsTypesOptions
} from '../../../../../../../../components/billing';
import { CaseUsersSelect } from '../../../../../../../../components/cases/CaseUsersSelect';
import {
  Autocomplete,
  Checkbox,
  CompaniesAutocomplete,
  CurrencyField,
  TasksSelect,
  TextField
} from '../../../../../../../../components/FormField';
import { styles } from '../../styles';

const useStyles = makeStyles(styles);

export const MainForm = ({ model }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { values, setFieldValue, setValues } = useFormikContext();

  const handleTaskChange = (task) => {
    setValues({ ...values, model: 'task', model_id: task?.id || model?.id });
  };

  const handleUserChange = (user) => {
    setFieldValue('participant', user || null);
    setFieldValue('rate', user?.rate ? user?.rate / 100 : null);
  };

  const handleCompanyChange = (company) => {
    setFieldValue('participant', company || null);
    setFieldValue('rate', company?.rate ? company?.rate / 100 : null);
  };

  const handleParticipantTypeChange = () => {
    setFieldValue('payer', null);
    setFieldValue('participant_id', null);
    setFieldValue('rate', null);
    setFieldValue('total', null);
    setFieldValue('hours', null);
  };

  const toggleFlatFee = () => {
    setFieldValue('total', null);
  };

  return (
    <>
      <Grid
        container
        spacing={isMobile ? 1 : 2}
        justify={values.flat_fee ? 'flex-start' : 'space-between'}
        alignItems="flex-end"
        className={classes.container}
      >
        <Grid item xs={isMobile ? 12 : 6}>
          <Autocomplete
            required
            name="participant_type"
            label="Select participant type"
            options={billingParticipantsTypesOptions}
            onChange={handleParticipantTypeChange}
          />
        </Grid>

        {!isMobile && <Grid item xs={6} />}

        <Grid item xs={isMobile ? 12 : 6}>
          {(values?.participant_type?.value || values?.participant_type) === billingParticipantsTypesMap.user ? (
            <CaseUsersSelect
              required
              name="participant_id"
              label="User"
              onChange={handleUserChange}
            />
          ) : (
            <CompaniesAutocomplete
              name="participant_id"
              label="Company"
              onChange={handleCompanyChange}
            />
          )}
        </Grid>

        <Grid item xs={isMobile ? 12 : 2}>
          <FormControlLabel
            label={<Typography color="textSecondary">Flat fee</Typography>}
            control={
              <Checkbox
                name="flat_fee"
                color="primary"
                onClick={toggleFlatFee}
              />
            }
          />
        </Grid>

        {!values.flat_fee &&
          <>
            <Grid item xs={isMobile ? 6 : 2}>
              <CurrencyField
                required
                name="rate"
                label="Rate"
              />
            </Grid>

            <Grid item xs={isMobile ? 6 : 2}>
              <TextField
                readOnly
                disabled
                zeroMinWidth
                name="hours"
                label="Hours"
                placeholder="hh/mm"
              />
            </Grid>
          </>
        }

        <Grid item xs={isMobile ? 6 : 2}>
          <CurrencyField
            required
            name="total"
            label="Total"
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={isMobile ? 1 : 3}
        alignItems="flex-end"
        className={classes.container}
      >
        <Grid item xs={isMobile ? 12 : 4}>
          <TextField
            readOnly
            disabled
            name="caseInfo.file_number"
            label="Case#"
            InputProps={{
              className: classes.contentFieldInput
            }}
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <TasksSelect
            name="task"
            label="Select Task"
            params={{ cases: [ model?.id ] }}
            onChange={handleTaskChange}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={4}
          required
          name="notes"
          label="Notes"
          placeholder="Add notes"
          variant="filled"
          InputProps={{
            className: classes.notesFieldInput
          }}
        />
      </Grid>
    </>
  );
};
