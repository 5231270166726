import { HorizontalBar } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';

export const Chart = ({ data, labels }) => {
  const { palette } = useTheme();

  const getChartData = () => ({
    labels,
    datasets: [
      {
        backgroundColor: [
          palette.pink,
          palette.deeporange,
          palette.amberyellow,
          palette.lightgreen
        ],
        hoverBackgroundColor: [
          palette.pink,
          palette.deeporange,
          palette.amberyellow,
          palette.lightgreen
        ],
        data
      }
    ]
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      yAxes: [ {
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          beginAtZero: true
        },
        display: false
      } ],
      xAxes: [ {
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          beginAtZero: true
        },
        display: false
      } ]
    }
  };

  return (
    <HorizontalBar
      redraw
      data={getChartData()}
      options={chartOptions}
    />
  );
};
