import { Typography } from '@material-ui/core';
import { TaskMenu } from '../../../../app/Dashboard/TasksPage';
import { Body } from '../../../../app/Dashboard/TasksPage/MainWindow/ColumnsList/Column/Card/Body';
import { CardHeader, CardItem } from '../../../Cards';

export const Card = ({ item: task = {} }) => {
  return (
    <CardItem
      disableHover
      py={0.5}
      pr={1}
      pl={1.5}
      bgcolor="common.white"
      borderRadius="borderRadius"
    >
      <CardHeader
        disableCheckBox
        menuComponent={TaskMenu}
        menuComponentProps={{ task, disablePreview: true }}
      >
        <Typography noWrap title={task.name} variant="h5">
          {task.name}
        </Typography>
      </CardHeader>

      <Body disableProvider task={task} />
    </CardItem>
  );
};
