import { useContext, useEffect, useRef, useState } from 'react';
import { useDebounce } from '../../../../../helpers/hooks';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { TextField } from '../../../../../components/FormField';
import { PrintersConfigurationContext } from '../PrintersConfigurationProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';

export const FiltersBar = () => {
  const formikRef = useRef();
  const { resetPrinters } = useContext(PrintersConfigurationContext);
  const [ fields, setFields ] = useState({});
  const debouncedFields = useDebounce(fields, 600);

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
  };

  useEffect(() => {
    resetPrinters(debouncedFields);
  }, [ debouncedFields ]);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      formikRef={formikRef}
      border={0}
      onReset={() => resetPrinters({})}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.search,
          label: filterFieldsLabels[filterFieldsMap.search],
          field: (
            <TextField
              name={filterFieldsMap.search}
              label={filterFieldsLabels[filterFieldsMap.search]}
              onChange={handleFieldChange(filterFieldsMap.search)}
            />
          )
        }
      ]}
    />
  );
};
