export const styles = ({ zIndex }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },

  mobileOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: zIndex.appBar + 1,
    cursor: 'pointer'
  }
});
