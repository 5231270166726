import { Fragment, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { generatePath, Link, Redirect, Switch, useLocation, useParams } from 'react-router-dom';
import ListIcon from '@material-ui/icons/List';
import { Box, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { getActiveRoute, renderRoutes, Route } from '../../../../components/router';
import { MedicalReportsTabs } from '../../../../components/MedicalReportsTabs';
import { Scrollbars } from '../../../../components/Scrollbars';
import { Tabs, Tab } from '../../../../components/Tabs';
import { Loader } from '../../../../components/Loader';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { ClaimPreview } from '../../BillingPage/ClaimsPage/ClaimPreview';
import { getReportsRoutes } from '../../MedicalReports';
import { UserContext, UserProvider } from '../../Members/User/UserProvider';
import { MedicalProfileProvider } from '../../ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider';
import { LeftSidebar } from '../../ProfilePage/MedicalInfo/MainInfo/LeftSidebar';
import { routes } from './routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MedicalInfo = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams();
  const userSidebarIsOpen = useSelector(({ layout: { userInfoIsOpen = true } }) => userInfoIsOpen);
  const [ isUserInfoOpen, setIsUserInfoOpen ] = useState(false);
  const medicalInfoPathname = `/patients/${params.patientID}/medical-info`;
  const reportRoutes = getReportsRoutes('/patients/:patientID/medical-info/medical-report');
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    FilterBarWrapper,
    filterBarWrapperProps,
    toggleFiltersBar
  } = useMobileFilterCollapse();
  const ScrollWrapper = !isTablet ? Fragment : Scrollbars;
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%',
    width: '100%'
  };

  const toggleInfoOpen = () => {
    setIsUserInfoOpen((state) => !state);
  };

  if (location.pathname === `/patients/${params.patientID}/medical-info/medical-report`) {
    return (
      <Switch>
        <Route exact path={location.pathname}>
          <Redirect to={location.pathname + '/consultation'} />
        </Route>
      </Switch>
    );
  }

  return (
    <UserProvider userID={params.patientID}>
      <MedicalProfileProvider userID={params.patientID} medicalInfoPathname={medicalInfoPathname}>
        <div className={classes.root}>
          {isTablet &&
            <div className={classes.navigation}>
              <Box mx={2.25} mt={1}>
                <IconButton size="medium" onClick={toggleFiltersBar}>
                  <ListIcon />
                </IconButton>
              </Box>
            </div>
          }

          <ScrollWrapper {...scrollWrapperProps}>
            <FilterBarWrapper {...filterBarWrapperProps}>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                orientation={isTablet ? 'vertical' : 'horizontal'}
                value={generatePath(getActiveRoute(routes), { patientID: params.patientID })}
                classes={{ flexContainer: classes.tabsWrapper }}
              >
                {routes.map(({ path, icon, label }) => label && (
                  <Tab
                    key={path}
                    value={generatePath(path, { patientID: params.patientID })}
                    icon={icon}
                    label={label}
                    component={Link}
                    to={generatePath(path, { patientID: params.patientID })}
                    onClick={isTablet ? toggleFiltersBar : undefined}
                  />
                ))}
              </Tabs>
            </FilterBarWrapper>
          </ScrollWrapper>

          <Route path="/patients/:patientID/medical-info/medical-report">
            <MedicalReportsTabs pathname={`/patients/${params.patientID}/medical-info/medical-report`}/>
          </Route>

          {location.pathname.includes('claims/') ?
            <Route exact path="/patients/:patientID/medical-info/claims/:id">
              <Box p={1} pb={0} height="100%">
                <ClaimPreview />
              </Box>
            </Route>
            :
            <UserContext.Consumer>
              {({ isFetched }) => (
                !isFetched ? <Loader p={3} /> : (
                  <div className={classes.content}>
                    {userSidebarIsOpen && (
                      <div className={cn(classes.leftSidebar, { [classes.leftSidebarFullHeight]: isUserInfoOpen })}>
                        <LeftSidebar openUserInfo={isUserInfoOpen} toggleInfoOpen={toggleInfoOpen}/>
                      </div>
                    )}

                    <div className={cn(classes.main, { [classes.mainFullHeight]: !isUserInfoOpen })}>
                      {renderRoutes(routes)}

                      <Route path="/patients/:patientID/medical-info/medical-report">
                        <Box display="flex" flexDirection="column" px={isTablet ? 0 : 0.5} height="100%">
                          {renderRoutes(reportRoutes)}
                        </Box>
                      </Route>
                    </div>
                  </div>
                )
              )}
            </UserContext.Consumer>
          }
        </div>
      </MedicalProfileProvider>
    </UserProvider>
  );
};
