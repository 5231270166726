export const styles = ({
  palette: { primary, divider },
  transitions,
  shadows
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    border: `1px solid ${divider}`,
    overflow: 'hidden'
  },

  hoverable: {
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)'
    }
  },

  root_checked: {
    border: `1px solid ${primary.light}`
  },

  root_borderWidth: {
    borderWidth: ({ borderWidth = 1 }) => borderWidth
  },

  root_selectedBoxShadows: {
    boxShadow: shadows[4]
  }
});
