import { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../components/Loader';
import { RulesEmailsContext } from '../../RulesEmailsContext';
import { RuleItem } from './RuleItem';

export const columnsWidths = {
  sortAction: 36,
  lock: 74,
  description: 200,
  date: 120,
  account: 200,
  action: 88
};

export const Rules = ({ disableDragHandle = false }) => {
  const {
    rules,
    isRulesFetched,
    meta,
    isRulesFetching,
    updateRulesPositions,
    loadNextPage
  } = useContext(RulesEmailsContext);
  const scrollElementRef = useRef();

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const item = rules[oldIndex];

      updateRulesPositions({ id: item.id, oldIndex, newIndex });
    }
  };

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isRulesFetched} render={
        () => !rules.length ? (
          <Box p={3}>
            <Typography color="textSecondary">No rules found</Typography>
          </Box>
        ) : (
          <>
            <MuiList disablePadding>
              <InfiniteListLoader
                isSortable
                useDragHandle
                items={rules}
                isFetching={isRulesFetching}
                isFetched={isRulesFetched}
                pagination={meta}
                lockAxis="y"
                RowComponent={RuleItem}
                RowProps={{ disableDragHandle }}
                onSortEnd={handleSortEnd}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Box>
  );
};
