import * as providersApi from '../../../../api/profile';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';

export const transformProviderIdentifierToOption = (item) => item && ({
  value: item.id,
  label: item.name,
  data: item
});

const fetchProviderIdentifiers = (params) => fetchDataForAsyncSelect((search, { page }) => {
  return providersApi.fetchProviderIdentifiers({ params: { page, ...params } });
}, {
  transformDataToOption: transformProviderIdentifierToOption
});

export const ProviderIdentifiersSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      label="Provider ID Type"
      promiseWithOptions={fetchProviderIdentifiers(params)}
      {...props}
    />
  );
};
