import { useContext } from 'react';
import { Checkbox, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../components/ListRow';
import { FilesFilterContext } from '../../FilesFilterContext';
import { columnsMap, columnsWidths, mobileColumnWidths } from '../listConfig';

export const TableHeader = ({ hiddenColumns = [], filesContext }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
  const { selectedFilesIDs } = useContext(FilesFilterContext);
  const { allFilesIsSelected, toggleAllFilesSelection } = filesContext;
  const defaultColumnsWidths = isMobile ? mobileColumnWidths : columnsWidths;

  return (
    <Paper elevation={0}>
      <ListRow header>
        {!hiddenColumns.includes(columnsMap.checkbox) &&
          <ListRowCell>
            <Checkbox
              edge="start"
              indeterminate={!!selectedFilesIDs.length && !allFilesIsSelected()}
              checked={allFilesIsSelected()}
              onClick={toggleAllFilesSelection}
            />
          </ListRowCell>
        }

        <ListRowCell grow flexBasis={defaultColumnsWidths.name}>
          Name
        </ListRowCell>

        {!isMobile && !hiddenColumns.includes(columnsMap.sharedUsers) && !filesContext.isSharedFiles &&
          <ListRowCell flexBasis={defaultColumnsWidths.sharedUsers}>
            Shared
          </ListRowCell>
        }

        {!isMobile && !hiddenColumns.includes(columnsMap.owner) &&
          <ListRowCell flexBasis={defaultColumnsWidths.owner}>
            Owner
          </ListRowCell>
        }

        {!hiddenColumns.includes(columnsMap.creator) &&
          <ListRowCell flexBasis={defaultColumnsWidths.creator}>
            Creator
          </ListRowCell>
        }

        {!isMobile &&
          <ListRowCell flexBasis={defaultColumnsWidths.size}>
            Size
          </ListRowCell>
        }

        <ListRowCell noWrap flexBasis={defaultColumnsWidths.date}>
          Uploaded at
        </ListRowCell>

        <ListRowCell flexBasis={defaultColumnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
