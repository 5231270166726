import { useState } from 'react';
import { AsyncPaginateBase } from 'react-select-async-paginate';

export const AsyncPaginateSelect = ({
  menuIsOpen: menuIsOpenProp = false,
  onMenuOpen: onMenuOpenProp,
  onMenuClose: onMenuCloseProp,
  ...props
}) => {
  const [ inputValue, onInputChange ] = useState('');
  const [ menuIsOpen, setMenuIsOpen ] = useState(menuIsOpenProp);

  const onMenuOpen = () => {
    setMenuIsOpen(true);
    onMenuOpenProp && onMenuOpenProp();
  };

  const onMenuClose = () => {
    setMenuIsOpen(false);
    onMenuCloseProp && onMenuCloseProp();
  };

  return (
    <AsyncPaginateBase
      inputValue={inputValue}
      onInputChange={onInputChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      menuIsOpen={menuIsOpen}
      {...props}
    />
  );
};
