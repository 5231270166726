import { FormControlLabel, Grid } from '@material-ui/core';
import { useState } from 'react';
import {
  Autocomplete,
  KeyboardDatePicker,
  Checkbox
} from '../../../../../../../../components/FormField';
import { CaseMembersSelect } from '../../../../../../../../components/users/CaseMembersSelect';
import { discoveryTypeOptions } from './discoveryTypeOptions';
import { userTypeOptions } from './userTypeOptions';

export const DiscoveryForm = ({ caseItem, onCaseItemUpdate }) => {
  const [ members, setMembers ] = useState(caseItem?.case_users);

  const handleAddMembers = (values) => {
    onCaseItemUpdate(values);
    setMembers(values?.case_users);
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item sm={6}>
        <Autocomplete
          name="user_type"
          label="User Type"
          placeholder="Select user type..."
          options={userTypeOptions}
        />
      </Grid>

      <Grid item sm={6} />

      <Grid item sm={6}>
        <CaseMembersSelect
          isCreatable
          name="to_id"
          label="To"
          margin="dense"
          creatablePayload={{
            caseItem,
            onCaseItemUpdate: handleAddMembers
          }}
          options={members}
        />
      </Grid>

      <Grid item sm={6}>
        <CaseMembersSelect
          isCreatable
          name="from_id"
          label="From"
          margin="dense"
          creatablePayload={{
            caseItem,
            onCaseItemUpdate: handleAddMembers
          }}
          options={members}
        />
      </Grid>

      <Grid item sm={6}>
        <Autocomplete
          name="type"
          label="Discovery Type"
          placeholder="Select discovery type..."
          options={discoveryTypeOptions}
        />
      </Grid>

      <Grid item sm={6} />

      <Grid item sm={6}>
        <KeyboardDatePicker
          clearable
          name="send_date"
          label="Date Sent"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={6}>
        <KeyboardDatePicker
          clearable
          name="received_date"
          label="Date Received"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={6}>
        <KeyboardDatePicker
          clearable
          name="due_date"
          label="Due Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={6}>
        <FormControlLabel
          control={<Checkbox name="extension" />}
          label="Extension"
        />
      </Grid>
    </Grid>
  );
};
