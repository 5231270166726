import { useEffect } from 'react';
import { Box, makeStyles, useMediaQuery, useTheme, IconButton } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import cn from 'classnames';
import { Loader } from '../../../../../components/Loader';
import { List } from '../List';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const FilePreviewContent = ({
  sidebarIsOpen,
  handleToggleSidebarIsOpen,
  isModalEntered,
  files,
  isFetched,
  isFetching,
  filter,
  listItemMenuOptions,
  getFileMenuOptions,
  onNeedNextPage
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  useEffect(() => {
    if (isMobile) {
      handleToggleSidebarIsOpen(false);
    }
  }, [ isMobile ]);

  return (
    <div className={cn(classes.list, !sidebarIsOpen && classes.list_collapsed)}>
      {!isModalEntered ? (
        <Loader className={classes.loader} />
      ) : (
        <Box height="100%" width="100%">
          {isMobile &&
            <IconButton color="primary" onClick={handleToggleSidebarIsOpen}>
              <KeyboardArrowRight />
            </IconButton>
          }

          <List
            files={files}
            isFetched={isFetched}
            isFetching={isFetching}
            filter={filter}
            listItemMenuOptions={listItemMenuOptions}
            getFileMenuOptions={getFileMenuOptions}
            onNeedNextPage={onNeedNextPage}
            handleToggleSidebarIsOpen={handleToggleSidebarIsOpen}
          />
        </Box>
      )}
    </div>
  );
};
