export const filterFieldsMap = {
  ndcCodeId: 'ndc_code_id',
  prescribedFrom: 'prescribed_from',
  prescribedTo: 'prescribed_to',
  insuranceType: 'insurance_type',
  appointmentBookId: 'appointment_book_id',
  office: 'office_id',
  insuranceId: 'insuranceId'
};

export const filterFieldsLabels = {
  [filterFieldsMap.ndcCodeId]: 'Drug Name',
  [filterFieldsMap.prescribedFrom]: 'From',
  [filterFieldsMap.prescribedTo]: 'To',
  [filterFieldsMap.insuranceType]: 'Insur. type',
  [filterFieldsMap.appointmentBookId]: 'Appointment Book',
  [filterFieldsMap.office]: 'Office',
  [filterFieldsMap.insuranceId]: 'insuranceId'
};
