import { useContext } from 'react';
import {
  List,
  ListItemText,
  IconButton,
  MenuItem,
  ListItemIcon,
  SvgIcon
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { TagsContext } from '../../../app/Dashboard/files-common';
import { Popper } from '../../Popper';

export const Menu = ({ tag }) => {
  const tagsContext = useContext(TagsContext);

  const handleTagDelete = () => {
    tagsContext.deleteTag(tag);
  };

  const handleTagUpdate = () => {
    tagsContext.updateTag(tag);
  };

  return (
    <Popper
      placement="right-start"
      anchorRenderer={
        ({ anchorRef, handleToggle }) => (
          <IconButton buttonRef={anchorRef} onClick={handleToggle}>
            <MoreVertIcon/>
          </IconButton>
        )
      }
    >
      <List>
        <MenuItem onClick={handleTagUpdate}>
          <ListItemIcon>
            <EditIcon fontSize="inherit" />
          </ListItemIcon>

          <ListItemText primary="Edit" />
        </MenuItem>

        <MenuItem onClick={handleTagDelete}>
          <ListItemIcon>
            <SvgIcon fontSize="inherit">
              <Icon path={mdiTrashCanOutline} />
            </SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      </List>
    </Popper>
  );
};
