import { useMemo } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { Date } from '../../../../../../../../components/Date';
import { Label } from '../../../../../../../../components/Label';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../../components/UserLink';
import { UserPreviewModal } from '../../../../../../../../components/users/UserPreviewModal';
import { allClaimsStatuses } from '../../../../../../../../components/claims';

export const Body = ({ claim }) => {
  const { openModal } = useModal();
  const status = useMemo(() => allClaimsStatuses.find(({ value }) => value === claim.status), [ claim.status ]);

  const openUserPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id }
    });
  };

  return (
    <Box px={1.75} py={1} border="1px dashed" borderColor="grey.200" bgcolor="grey.25">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Case # {claim.case?.file_number || '-'}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            DOS
          </Typography>

          <Date
            date={claim.appointment?.appointment_at}
            iconFontSize="small"
          />
        </Grid>

        <Grid item xs={6}>
          <Box display="flex" alignItems="center">
            <UserLink
              noWrap
              size="lg"
              variant="h5"
              user={claim?.patient}
              onClick={stopPropagation(openUserPreview(claim?.patient_id))}
            >
              <Typography noWrap variant="body2" color="textSecondary">
                MRN: {claim.patient?.medical?.med_rec || '-'}
              </Typography>
            </UserLink>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Claim status
          </Typography>

          <Label label={status?.label} />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Charge Amount
          </Typography>

          <Typography variant="h5">
            <CurrencyFormat value={claim.billing_info?.total_charged} />
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Total Amount
          </Typography>

          <Typography variant="h5">
            <CurrencyFormat value={claim.billing_info?.total_balance} />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
