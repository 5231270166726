import { useRef, createContext, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isEqual, merge, omit } from 'lodash';
import { usePrevious } from '../../../../../../helpers/hooks';
import { setExpensesLastGlobalAction } from '../../../../../../store/globalActions';
import * as expensesApi from '../../../../../../api/billing';
import { api } from '../../../../../../api';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { initialState as initialStateConfig } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const ExpensesContext = createContext();

export const ExpensesContextProvider = ({ children, initialState = {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const reduxDispatch = useDispatch();
  const expensesLastGlobalAction = useSelector(({ globalActions }) => globalActions.expensesLastGlobalAction);
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialStateConfig, initialState));
  const {
    expenses,
    pagination,
    isFetching,
    isFetched,
    filter,
    selectedExpensesIDs,
    selectedExpenses
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchExpenses = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.EXPENSES_FETCH_REQUEST });

    expensesApi.fetchExpenses({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.EXPENSES_FETCH_SUCCESS, payload: data });
    });
  };

  const updateExpense = ({ expenseData, setSubmitting, setErrors, handleModalResolve } = {}) => {
    expensesApi.updateExpense(expenseData).then((data) => {
      reduxDispatch(setExpensesLastGlobalAction({ type: types.UPDATE_EXPENSE_IN_LIST, payload: data }));
      enqueueSnackbar('Expense successfully updated', { variant: 'success' });
      handleModalResolve();
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Expense is not updated', { variant: 'error' });
      }
    }).then(() => {
      setSubmitting(false);
    });
  };

  const deleteExpense = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        expensesApi.deleteExpense(id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchExpenses({ page: filter.page });
          } else if (expenses.length - 1 === 0 && filter.page !== 1) {
            fetchExpenses({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_EXPENSE, payload: id });
          }

          enqueueSnackbar('Expense successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Expense is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const massDeleteExpenses = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        expensesApi.massDeleteExpenses(IDs).then(() => {
          if (pagination.last_page > filter.page && selectedExpensesIDs.length < filter.per_page) {
            fetchExpenses({ page: filter.page });
          } else if (expenses.length - selectedExpensesIDs.length === 0 && filter.page !== 1) {
            fetchExpenses({ page: filter.page - 1 });
          } else {
            reduxDispatch(setExpensesLastGlobalAction({ type: types.MASS_DELETE_EXPENSE, payload: IDs }));
          }

          enqueueSnackbar('Expenses successfully deleted', { variant: 'success' });
        }).catch(({ message }) => {
          enqueueSnackbar(message, { variant: 'error' });
        });
      }
    });
  };

  const approveExpense = (expenseID) => {
    expensesApi.massExpensesApprove([ expenseID ]).then(() => {
      reduxDispatch(setExpensesLastGlobalAction({ type: types.APPROVE_EXPENSE, payload: expenseID }));
      enqueueSnackbar('Expense successfully approved', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Expense not approved', { variant: 'error' });
    });
  };

  const disapproveExpense = (expenseID) => {
    expensesApi.massExpensesDisapprove([ expenseID ]).then(() => {
      reduxDispatch(setExpensesLastGlobalAction({ type: types.DISAPPROVE_EXPENSE, payload: expenseID }));
      enqueueSnackbar('Expense disapproved', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Expense not disapproved', { variant: 'error' });
    });
  };

  const massExpensesApprove = (IDs) => {
    expensesApi.massExpensesApprove(IDs).then(() => {
      reduxDispatch(setExpensesLastGlobalAction({ type: types.MASS_EXPENSES_APPROVE, payload: IDs }));
      enqueueSnackbar('Expenses successfully approved', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Expenses not approved', { variant: 'error' });
    });
  };

  const massExpensesDisapprove = (IDs) => {
    expensesApi.massExpensesDisapprove(IDs).then(() => {
      reduxDispatch(setExpensesLastGlobalAction({ type: types.MASS_EXPENSES_DISAPPROVE, payload: IDs }));
      enqueueSnackbar('Expenses disapproved', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Expenses not disapproved', { variant: 'error' });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchExpenses({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const toggleItemSelection = (expense) => {
    dispatch({ type: types.TOGGLE_EXPENSE_SELECTION, payload: expense });
  };

  const toggleAllExpensesSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_EXPENSE_SELECTION });
  };

  const allExpensesIsSelected = () => {
    return expenses.length === selectedExpensesIDs.length;
  };

  const resetExpenses = (newFilter) => {
    dispatch({ type: types.RESET_EXPENSES, payload: newFilter });

    fetchExpenses({ page: 1, ...newFilter });
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetExpenses(newFilter);
    }
  }, [ prevFilter, filter ]);

  const providerValue = {
    expenses,
    isFetching,
    isFetched,
    selectedExpensesIDs,
    selectedExpenses,
    pagination,
    filter,
    meta: {
      ...filter,
      ...pagination
    },

    applyFilter,
    loadNextPage,
    resetExpenses,
    deleteExpense,
    fetchExpenses,
    updateExpense,
    toggleItemSelection,
    toggleAllExpensesSelection,
    allExpensesIsSelected,
    approveExpense,
    disapproveExpense,
    massExpensesApprove,
    massExpensesDisapprove,
    massDeleteExpenses
  };

  useEffect(() => {
    expensesLastGlobalAction && dispatch(expensesLastGlobalAction);
  }, [ expensesLastGlobalAction ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ExpensesContext.Provider value={providerValue}>
      {children}
    </ExpensesContext.Provider>
  );
};
