import { PaymentsContextProvider } from '../../../../../../../components/billing/payments';
import { InvoicesContextProvider } from '../../../../Invoices/InvoicesContext';
import { BillingSectionContent } from './BillingSectionContent';

export const BillingSection = ({ claim }) => {
  return (
    <PaymentsContextProvider filter={{ model_type: 'claim', model_id: claim.id }}>
      <InvoicesContextProvider initialState={{ filter: { model_type: 'claim', model_id: claim.id } }}>
        <BillingSectionContent claim={claim} />
      </InvoicesContextProvider>
    </PaymentsContextProvider>
  );
};
