import { Box, Dialog, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Measurements } from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms';
import { ModalBody, ModalContainer, ModalHeader } from '../../../Modal';

export const MeasurementsPreviewModal = ({
  DialogProps,
  handleModalReject,
  payload: { user = {}, measurements = {} }
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog fullScreen={isTablet} {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          <Box component="span" color="info.main">
            Measurements
          </Box>
        </ModalHeader>

        <ModalBody>
          <Grid container spacing={2}>
            <Measurements disableTitle birthday={user.birthday} measurements={measurements} />
          </Grid>
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};

