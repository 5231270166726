import { SvgIcon } from '@material-ui/core';
import ChatSquaredSvg from '../../../../../../components/icons/chat-squared.svg';

export const chat = {
  name: 'Chat/Messenger',
  icon: (props) => (
    <SvgIcon {...props}>
      <ChatSquaredSvg />
    </SvgIcon>
  ),
  path: '/messenger'
};
