export const initialValues = {
  payer_id: null,
  modifier_id: null,
  default_units: null,
  charge_amount: 1,
  expected_amount: 1,
  work_rvu: null,
  moldx_code: null,
  dx_code: null,
  total_rvu: null,
  is_all_payers: false
};
