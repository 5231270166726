import { Component } from 'react';
import { withStyles, List, Paper, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Loader } from '../../../../../components/Loader';
import { fetchTracks } from '../../../../../store/dashboard/timeTracks';
import { getActivityGroupKey } from '../getActivityGroupKey';
import { ActivityItem } from './ActivityItem';
import { styles } from './styles';

class ActivityList extends Component {
  componentDidMount() {
    this.props.fetchTracks();
  }

  render() {
    const {
      activeTimer,
      tracks: groupedTracks,
      isTracksFetching,
      classes
    } = this.props;

    return (
      <Paper className={classes.root} elevation={4}>
        <Loader loading={isTracksFetching} render={
          () => !groupedTracks.length ? (
            <Typography align="center">No activity for this day</Typography>
          ) : (
            <List disablePadding>
              {groupedTracks.map((item) => {
                const isPlaying = !!activeTimer && getActivityGroupKey(activeTimer) === getActivityGroupKey(item);

                return (
                  <ActivityItem
                    key={getActivityGroupKey(item)}
                    activityItem={item}
                    isPlaying={isPlaying}
                  />
                );
              })}
            </List>
          )}
        />
      </Paper>
    );
  }
}

const mapStateToProps = ({ activityTimer, timeTracks }) => ({
  activeTimer: activityTimer.activeTimer,
  isTracksFetching: timeTracks.isFetching,
  tracks: timeTracks.tracks
});

ActivityList = withStyles(styles)(ActivityList);

ActivityList = connect(mapStateToProps, {
  fetchTracks
})(ActivityList);

export { ActivityList };
