import { Icon } from '@mdi/react';
import { mdiForumOutline } from '@mdi/js';
import { Box, makeStyles, Link, Typography, SvgIcon } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { statusTypesColors, statusTypesNames } from '../../../../../app/Dashboard/Members';
import { stopPropagation } from '../../../../../helpers/dom';
import { getUserFullName } from '../../../../../helpers/users';
import { itemsRoutesByModels } from '../../../../../dataMaps/apiModels';
import { useChatActions } from '../../../../../utils/useChatActions';
import { Loader } from '../../../../Loader';
import { UserPreviewModal } from '../../../UserPreviewModal';
import { VerticalDivider } from '../../../../VerticalDivider';
import { CardItem, CardHeader } from '../../../../Cards';
import { UserAvatar } from '../../../UserAvatar';
import { useModal } from '../../../../ModalsProvider';
import { Contact } from '../../../../Contact';
import { Divider } from '../../../../Divider';
import { Label } from '../../../../Label';
import { MemberMenu } from '../../MemberMenu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ item }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { isChatOpening, openChatWithUser } = useChatActions();

  const openPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: item?.user?.id }
    });
  };

  const getUserPage = () => {
    window.open(itemsRoutesByModels.user(item?.user?.id));
  };

  const startChatWithUser = () => {
    openChatWithUser(item?.user?.id);
  };

  return (
    <CardItem onClick={openPreview}>
      <CardHeader
        disableCheckBox
        alignItems="center"
        menuComponent={MemberMenu}
        menuComponentProps={{ item }}
        px={2}
        py={1}
      >
        <Label
          color={statusTypesColors[+item?.is_active]}
          label={statusTypesNames[+item?.is_active] || 'Active'}
        />
      </CardHeader>

      <div className={classes.content}>
        <UserAvatar size="xxxl" user={item?.user} />

        <Typography gutterBottom variant="h6" align="center">
          {getUserFullName(item?.user)}
        </Typography>

        <Typography
          gutterBottom
          variant="subtitle2"
          align="center"
          color="error"
        >
          {item?.user.role ? item?.user.role.toUpperCase() : '-'}
        </Typography>

        <Contact
          gutterBottom
          type="mailto"
          color="primary"
          contact={item?.user.email}
        />

        <Contact type="tel" contact={item?.user.phone} />
      </div>

      <Divider disableBottomGutter />

      <Box display="flex" alignItems="center" height={46}>
        <Box width="50%" textAlign="center" py={2}>
          <Loader surface loading={isChatOpening} size={24} render={
            () => (
              <Link
                underline="none"
                color="textPrimary"
                onClick={stopPropagation(startChatWithUser)}
                className={classes.actionLink}
              >
                <SvgIcon className={classes.actionIcon}>
                  <Icon path={mdiForumOutline} />
                </SvgIcon>

                Message
              </Link>
            )}
          />
        </Box>

        <VerticalDivider />

        <Box width="50%" textAlign="center">
          <Link
            underline="none"
            color="textPrimary"
            onClick={stopPropagation(getUserPage)}
            className={classes.actionLink}
          >
            <EyeIcon className={classes.actionIcon} />

            View
          </Link>
        </Box>
      </Box>
    </CardItem>
  );
};
