import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, List as MuiList, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { DatePicker } from '../../../../../../components/FormField';
import { Fab } from '../../../../../../components/Fab';
import { Header } from './Header';
import { Row } from './Row';

const initialValues = {
  day: null,
  start: null,
  finish: null,
  date: null,
  day_off: false,
  breaks: [ {
    from: null,
    to: null
  } ]
};

export const columnsWidthsLg = {
  day: 100,
  dayOff: 65,
  start: 140,
  end: 140,
  action: 100
};

export const columnsWidthsSm = {
  day: 70,
  dayOff: 40,
  start: 140,
  end: 140,
  action: 40
};

export const WorkSchedule = () => {
  const { values, setFieldValue } = useFormikContext();
  const [ isOpenPicker, setIsOpenPicker ] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClosePicker = () => {
    setIsOpenPicker(false);
  };

  const handleOpenPicker = () => {
    setIsOpenPicker(true);
  };

  const addDate = (date) => {
    if (date) {
      setFieldValue('work_schedules', values.work_schedules.concat({ ...initialValues, date }));
    }
  };

  return (
    <Box my={2}>
      <Box color="info.main" mb={isMobile ? 2 : 4}>
        <Typography color="inherit" variant="h4">Work Schedules</Typography>
      </Box>

      <Header />

      <CustomScrollbars style={{ height: 400 }}>
        <MuiList disablePadding>
          {values?.work_schedules?.map((item, index) => (
            <Row key={index} parentIndex={index} item={item} />
          ))}
        </MuiList>
      </CustomScrollbars>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Box display="none">
          <DatePicker
            open={isOpenPicker}
            name="date"
            outputFormat="YYYY-MM-DD"
            onClose={handleClosePicker}
            onChange={addDate}
          />
        </Box>

        <Fab
          size={isMobile ? 'small' : 'medium'}
          color="primary"
          variant="extended"
          startIcon={<AddIcon />}
          onClick={handleOpenPicker}
        >
          Add date
        </Fab>
      </Box>
    </Box>
  );
};
