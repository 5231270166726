import moment from 'moment';
import { ListRow, ListRowCell } from '../../../../../../../../../../components/ListRow';
import { useModal } from '../../../../../../../../../../components/ModalsProvider';
import { ServiceLineViewModal } from '../../../../../../../../../../components/claims';
import { columnsWidths } from './ServiceLines';

export const Row = ({ authorization, serviceLine }) => {
  const { openModal } = useModal();

  const viewServiceLine = () => {
    openModal(ServiceLineViewModal, {
      payload: {
        authorization,
        serviceLine
      }
    });
  };

  return (
    <ListRow button onClick={viewServiceLine}>
      <ListRowCell grow column flexBasis={columnsWidths.updated_at}>
        {moment.unix(serviceLine.updated_at).format('L LT')}
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.certificationRevision}>
        {serviceLine.certification_revision_date ?
          moment(serviceLine.certification_revision_date, 'YYYY-MM-DD').format('L') : '-'
        }
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.lastCertification}>
        {serviceLine.last_certification_date ?
          moment(serviceLine.last_certification_date, 'YYYY-MM-DD').format('L') : '-'
        }
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.beginTherapy}>
        {serviceLine.begin_therapy_date ?
          moment(serviceLine.begin_therapy_date, 'YYYY-MM-DD').format('L') : '-'
        }
      </ListRowCell>
    </ListRow>
  );
};
