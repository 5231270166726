import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { styles as defaultStyles } from '../Select/components/MultiValue';
import { Tag } from '../../../Tag';

const styles = () => ({
  deleteIcon: {
    color: 'inherit'
  }
});

const useStyles = makeStyles(styles);
const useDefaultStyles = makeStyles(defaultStyles);

export const MultiValue = ({
  isFocused,
  removeProps,
  children
}) => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();

  return (
    <Tag
      cutTagLabel
      tabIndex={-1}
      tag={children}
      className={cn(
        defaultClasses.chip,
        { [defaultClasses.chipFocused]: isFocused },
        classes.root
      )}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} className={classes.deleteIcon} />}
    />
  );
};
