import { Pie } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';

export const Chart = ({ data, labels }) => {
  const { palette } = useTheme();
  
  const getChartData = () => ({
    labels,
    datasets: [
      {
        label: 'Task Statistics',
        backgroundColor: [
          palette.primary.main,
          palette.pink,
          palette.amberyellow,
          '#3F51B5',
          palette.purple,
          '#CDDC39'
        ],
        hoverBackgroundColor: [
          palette.primary.main,
          palette.pink,
          palette.amberyellow,
          '#3F51B5',
          palette.purple,
          '#CDDC39'
        ],
        data
      }
    ]
  });

  const chartOptions = {
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 12
      }
    }
  };

  return (
    <Pie options={chartOptions} data={getChartData()} />
  );
};
