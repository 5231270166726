import {
  Box,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import moment from 'moment';
import { getUserFullName } from '../../../helpers/users';
import { UserMRI } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/UserMRI';
import { styles } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/styles';

const useStyles = makeStyles(styles);

export const UserInfo = ({ initialFormValue, dob = null, form, activeStep }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const formValue = initialFormValue || form;

  return (
    <>
      <Box display="flex" flexDirection="column">
        {!!dob && (
          <Box display="flex" alignItems="center" my={activeStep > 0 ? 1 : 0}>
            <Typography variant={isMobile ? 'h5' : 'h4'}>DOB</Typography>

            <Box ml={1} mr={1.5} minWidth={80}>
              <Typography>{moment(dob).format('L')}</Typography>
            </Box>
          </Box>
        )}

        {(formValue?.doi || formValue.doi_string) && (
          <Box display="flex" alignItems="center" my={activeStep > 0 ? 1 : 0}>
            <Typography variant={isMobile ? 'h5' : 'h4'}>DOI</Typography>

            <Box ml={1} mr={1.5} minWidth={80}>
              {formValue?.doi && !formValue.doi_string
                ? <Typography>{moment(formValue?.doi).format('L')}</Typography>
                : <Typography>{formValue.doi_string}</Typography>
              }
            </Box>
          </Box>
        )}
      </Box>

      <Box mr={isMobile ? 0 : 2} textAlign="right">
        <UserMRI form={formValue} />

        <Typography
          color="textSecondary"
          variant={isMobile ? 'h5' : 'h6'}
          className={classes.userName}
        >
          {getUserFullName(formValue.patient)}
        </Typography>
      </Box>
    </>
  );
};
