import { Date, Text, Case, UserActivityLink } from '../../fields';
import { EventText } from '../EventText';

export const depositionFieldsMap = {
  date_received: (before, after) => <Date before={before} after={after} fieldLabel="Received Date" />,
  date_responded: (before, after) => <Date before={before} after={after} fieldLabel="Responded Date" />,
  deleted_at: (before, after) => <Date before={before} after={after} fieldLabel="Deleted At" />,
  updated_at: (before, after) => <Date before={before} after={after} fieldLabel="Updated At" />,
  deposition_date: (before, after) => <Date before={before} after={after} fieldLabel="Deposition Date" />,
  deposition_event: (before, after) => <EventText before={before} after={after} fieldLabel="Deposition Event" />,
  responded_event: (before, after) => <EventText before={before} after={after} fieldLabel="Responded Event" />,
  received_event: (before, after) => <EventText before={before} after={after} fieldLabel="Received Event" />,
  description: (before, after) => <Text before={before} after={after} fieldLabel="Description" />,
  name: (before, after) => <Text before={before} after={after} fieldLabel="Name" />,
  user: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="User" />,
  case: (before, after) => <Case before={before} after={after} fieldLabel="Case" />
};
