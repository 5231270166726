import { Yup } from '../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  participant_id: Yup.mixed().nullable().required(),
  participant_type: Yup.mixed().nullable().required(),
  notes: Yup.string().nullable().required().max(1000),
  flat_fee: Yup.bool(),
  rate: Yup.number().nullable().when('flat_fee', (flatFee, schema) => {
    return !flatFee ? schema.required() : schema;
  }),
  total: Yup.mixed().nullable().required()
});
