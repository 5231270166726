import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Typography, Grid, Box, useMediaQuery, useTheme } from '@material-ui/core';
import * as formsApi from '../../../../../../../api/profile';
import { ActionButtons } from '../../../../../../../components/medical/forms';
import { accidentTypesTitles } from '../../../../../../../components/medical/accidentTypes';
import { Divider } from '../../../../../../../components/Divider';
import { Loader } from '../../../../../../../components/Loader';
import {
  carAccidentValidationSchema,
  slipAndFallValidationSchema,
  otherAccidentValidationSchema,
  generateAccidentInitialOrthoValues
} from '../../../../../../../components/medical/forms/accidentInfoOrtho';
import { Body } from '../../../../../GuestMedicalProfile/PersonalInjuryOrtho/AccidentInfo/Body';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import * as types from '../../MedicalFormsProvider/types';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AccidentInfo = () => {
  const classes = useStyles();
  const params = useParams();
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetched, form, handleComplete, handleBack } = useContext(MedicalFormItemContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const validationSchema = form?.accident_type === accidentTypesTitles.car_accident
    ? carAccidentValidationSchema
    : form?.accident_type === accidentTypesTitles.slip_and_fall
      ? slipAndFallValidationSchema
      : otherAccidentValidationSchema;

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updateAccidentInformationOrtho(values).then(({ data }) => {
      dispatchRedux({ type: types.UPDATE_FORM, payload: data });
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleComplete(form.id);
    }).catch(({ errors }) => {
      if (errors) {
        errors && setErrors(errors);
        enqueueSnackbar('Form not updated', { variant: 'error' });
      }
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            form_id: form.id,
            ...generateAccidentInitialOrthoValues(form)
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <Box flexGrow={1}>
                <Grid
                  container
                  spacing={isSmallScreen ? 2 : 3}
                  alignItems="flex-end"
                  component={Box}
                  pb={3}
                >
                  <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                      Accident information - {form?.accident_type}
                    </Typography>
                  </Grid>

                  <Body form={form} />
                </Grid>
              </Box>

              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
