import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  makeStyles,
  Paper,
  Box,
  SvgIcon,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { mdiCalendar, mdiClipboardPulseOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { ReportsContext } from '../../../../../components/medical/reports';
import { UserInfo } from '../../../../../components/medical/forms';
import { PageBody } from '../../../../../components/Page';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FormWrapper = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { formItem, activeStep, doi } = useContext(ReportsContext);
  const title = location.pathname.includes('edit')
    ? 'History and Physical/Follow Up Consultation'
    : 'Initial Report';

  return (
    <PageBody fullHeight disablePaddings>
      <Box p={isMobile ? 1 : 3}>
        <Paper variant="outlined" square className={classes.header}>
          <div className={classes.mainIcon}>
            <SvgIcon fontSize="large" color="primary">
              <Icon path={mdiClipboardPulseOutline} />
            </SvgIcon>
          </div>

          <div className={classes.mainInfo}>
            <Box display="flex" alignItems="flex-start" mb={1}>
              <Box mr={1} color="text.secondary">
                <SvgIcon color="inherit">
                  <Icon path={mdiCalendar} />
                </SvgIcon>
              </Box>

              <Typography color="textSecondary">
                {moment.unix(moment().unix()).format('L')}
              </Typography>
            </Box>

            <Typography className={classes.mainInfo__title}>
              {title}
            </Typography>
          </div>

          {!isMobile &&
            <div className={classes.userInfo}>
              <UserInfo
                activeStep={activeStep}
                dob={formItem?.patient?.birthday}
                form={{ doi, ...formItem }}
              />
            </div>
          }
        </Paper>

        {isMobile &&
          <Box display="flex" alignItems="flex-start" justifyContent="space-between" mt={1}>
            <UserInfo
              activeStep={activeStep}
              dob={formItem?.patient?.birthday}
              form={{ doi, ...formItem }}
            />
          </Box>
        }
      </Box>

      <Box p={isMobile ? 1 : 3}>
        {children}
      </Box>
    </PageBody>
  );
};
