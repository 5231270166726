import { useContext, useRef, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import { api } from '../../../../../../../api';
import { useMemoEffect } from '../../../../../../../helpers/hooks';
import * as invoicesApi from '../../../../../../../api/billing';
import { Loader } from '../../../../../../../components/Loader';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { statusesList, statusesMap } from '../../../../Invoices/InvoicesPage/statusesMap';
import { PaymentStatusCard } from '../../../../PaymentStatusCard';
import { StatisticsFilterContext } from '../../../StatisticsFilterProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = ({ isHovered }) => {
  const classes = useStyles();
  const [ counters, setCounters ] = useState({});
  const [ isFetching, setIsFetching ] = useState(false);
  const { filter } = useContext(StatisticsFilterContext);
  const [ activeStatus, setActiveStatus ] = useState(isHovered && 'all_invoices');
  const cancelFetch = useRef(() => {});

  const handleStatusClick = (name) => (event) => {
    event.preventDefault();

    setActiveStatus(name);
  };

  useMemoEffect(() => {
    setIsFetching(true);

    invoicesApi.getInvoicesCounters({
      params: filter,
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      setCounters(data);
      setIsFetching(false);
    });

    return () => {
      cancelFetch.current();
    };
  }, [ filter ]);

  return (
    <Loader loading={isFetching} p={3} render={
      () => (
        <Box height="100%">
          <Scrollbars>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {statusesList.map((status) => (
                <PaymentStatusCard
                  key={status}
                  isHovered={isHovered}
                  isActive={activeStatus === status}
                  name={statusesMap[status].name}
                  color={statusesMap[status].color}
                  count={get(counters, `${status}.count`, 0)}
                  amount={get(counters, `${status}.sum`, 0).toFixed(2)}
                  className={classes.card}
                  onClick={isHovered && handleStatusClick(status)}
                />
              ))}
            </Box>
          </Scrollbars>
        </Box>
      )}
    />
  );
};
