export const caseInsuranceMap = [
  {
    value: 'Fully Insured',
    label: 'Fully Insured'
  },
  {
    value: 'High Retention Deductible',
    label: 'High Retention Deductible'
  },
  {
    value: 'Self-Insured',
    label: 'Self-Insured'
  },
  {
    value: 'Other Coverage',
    label: 'Other Coverage'
  }
];

export const caseInsuranceTypes = {
  fully_insured: 'Fully Insured',
  self_insured: 'Self-Insured',
  high_retention: 'High Retention',
  other: 'Other Coverage'
};
