import { Grid } from '@material-ui/core';
import { DatePicker, NumberMaskField } from '../../../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../../../components/users';
import { hasRole } from '../../../../../../../../utils/hasRole';

export const MainContent = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <NumberMaskField
            disabled={!hasRole('supervisor', 'medic', 'doctor', 'admin')}
            name="ext_med_rec"
            label="Ext. Med Rec"
            format="FP#####"
            margin="dense"
          />
        </Grid>

        <Grid item xs={12}>
          <UsersSelect
            name="referring_physician_id"
            label="Referring Physician"
            margin="dense"
            params={{ role: 'doctor' }}
          />
        </Grid>

        <Grid item xs={12}>
          <UsersSelect
            name="preferred_id"
            label="Referral Name"
            margin="dense"
            params={{ role: 'doctor' }}
          />
        </Grid>

        <Grid item xs={12}>
          <UsersSelect
            name="treating_physician_id"
            label="Primary Care Physician"
            margin="dense"
            params={{ role: 'doctor' }}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            name="doi"
            label="Date of Injury"
            InputProps={{ endAdornment: false }}
            margin="dense"
          />
        </Grid>
      </Grid>
    </>
  );
};
