import { useEffect, useContext, useRef } from 'react';
import { makeStyles, Box, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { Sticky } from '../../../../../components/Sticky';
import { ScheduleTypesContext } from '../ScheduleTypesProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

export const minRowHeight = 54;
export const columnsWidths = {
  name: 200,
  description: 300,
  duration: 80,
  actions: 264
};

const useStyles = makeStyles(styles);

export const List = () => {
  const {
    eventTypes,
    isFetching,
    isFetched,
    filter,
    resetEventTypes,
    loadNextPage
  } = useContext(ScheduleTypesContext);
  const classes = useStyles();
  const scrollElementRef = useRef();

  useEffect(() => {
    resetEventTypes();
  }, []);

  return (
    <div className={classes.root}>
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
        <Loader p={2} loading={!isFetched} render={
          () => (!filter.total ? (
            <Box p={2}>
              <Typography align="center">No event types found</Typography>
            </Box>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={eventTypes}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={filter}
                  RowComponent={Row}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          ))}
        />
      </CustomScrollbars>
    </div>
  );
};
