import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { usePrevious } from '../../../../../helpers/hooks';
import { api } from '../../../../../api';
import * as reportsApi from '../../../../../api/profile';
import { ConfirmationModal } from '../../../../ConfirmationModal';
import { useModal } from '../../../../ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const ReportsContext = createContext();

export const ReportsProvider = ({ children, paginateByPage = false, filter: filterProp = {} }) => {
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer,  merge({}, initialState, { filter: filterProp, paginateByPage }));
  const { reports, selectedIDs, pagination, filter } = state;
  const params = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [ activeStep, setActiveStep ] = useState(0);
  const [ doi, setDoi ] = useState(null);
  const [ formItem, setFormItem ] = useState(null);
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});
  const pathname =
    location.pathname.includes('/patients')
      ? `/patients/${params.patientID}/medical-info/medical-report/diagnosis`
      : location.pathname.includes('/profile')
        ? '/profile/medical-info/medical-report/diagnosis'
        : location.pathname.includes('/members')
          ? `/members/users/${params.userID}/medical-info/medical-report/diagnosis`
          : '/diagnosis';

  const fetchReports = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_REQUEST });

    reportsApi.fetchDiagnosisReports({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_SUCCESS, payload: data });
    });
  };

  const createReport = (report) => {
    dispatch({ type: types.ADD_REPORT, payload: report });
  };

  const updateReport = (report) => {
    dispatch({ type: types.UPDATE_REPORT_IN_LIST, payload: report });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetReports = (newFilter = {}) => {
    dispatch({ type: types.RESET_REPORTS, payload: newFilter });

    fetchReports({ page: 1, ...newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchReports({ page: filter.page + 1 });
    }
  };

  const allItemsIsSelected = () => {
    return reports.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_ITEM_SELECTION, payload: id });
  };

  const toggleAllItemsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_ITEMS_SELECTION });
  };

  const deleteReport = (id, onClose) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        reportsApi.deleteDiagnosisReport(id).then(() => {
          dispatch({ type: types.DELETE_REPORT_SUCCESS, payload: id });
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
          onClose && onClose();
        }).catch(() => {
          enqueueSnackbar('Item is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const massDeleteItems = (IDs) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        reportsApi.massDeleteDiagnosisReports(IDs).then(() => {
          dispatch({ type: types.MASS_DELETE_REPORTS, payload: IDs });
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Items are not deleted', { variant: 'error' });
        });
      }
    });
  };

  useEffect(() => {
    setFormItem(null);
  }, [ params ]);

  const providerValue = {
    ...state,
    doi,
    reports,
    selectedIDs,
    pagination,
    filter,
    activeStep,
    formItem,
    pathname,
    meta: {
      ...pagination,
      ...filter
    },

    // functions
    setDoi,
    setFormItem,
    setActiveStep,
    resetReports,
    loadNextPage,
    createReport,
    updateReport,
    applyFilter,
    fetchReports,
    allItemsIsSelected,
    toggleItemSelection,
    toggleAllItemsSelection,
    deleteReport,
    massDeleteItems
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, filterProp ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetReports();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    setDoi(null);
    setFormItem(null);
  }, [ params ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ReportsContext.Provider value={providerValue}>
      {children}
    </ReportsContext.Provider>
  );
};
