import { Box, Grid, Typography } from '@material-ui/core';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../../components/users';
import { UserLink } from '../../../../../../../components/UserLink';
import { Contact } from '../../../../../../../components/Contact';
import { Divider } from '../../../../../../../components/Divider';
import { ContactInfo } from './ContactInfo';
import { EmailInfo } from './EmailInfo';

export const MainContent = ({ company }) => {
  const { openModal } = useModal();

  const openUserPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: company.contact_user.id }
    });
  };

  return (
    <Box pt={3} px={2}>
      <Box color="info.main">
        <Typography variant="button" component="h3" color="inherit">
          Main info
        </Typography>
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Full Name Company
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {company.name}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Contact Person
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Box display="flex" alignItems="center">
            {company?.contact_user &&
              <UserLink
                size="md"
                variant="h5"
                parentProps={{ pr: 2 }}
                user={company?.contact_user}
                onClick={openUserPreview}
              />
            }

            {company?.contact_user?.phone &&
              <Contact
                noWrap
                needIcon
                variant="h5"
                type="tel"
                contact={company?.contact_user?.phone}
              />
            }
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Practice
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">
            {company?.company_type?.name || '-'}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Address
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <AddressLink variant="h5" address={company.full_address}>
            {company.full_address}
          </AddressLink>
        </Grid>
      </Grid>

      <Divider />
      <ContactInfo company={company} />
      <Divider />
      <EmailInfo company={company} />
    </Box>
  );
};
