import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as appointmentTypesApi from '../../../../../api/codes/appointments/appointment-types';
import * as immunizationsApi from '../../../../../api/profile';
import { uploadFiles } from '../../../../../store/dashboard/files/uploads';
import { ApptInfo } from '../../../../../components/appointments';
import { Loader } from '../../../../../components/Loader';
import { Button } from '../../../../../components/Button';
import {
  AppointmentForm,
  apptInitialValues
} from '../../../../../components/appointments';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../components/Modal';
import {
  immunizationInitialValues,
  immunizationsInitialValues
} from './immunizationInitialValues';
import {
  validationSchema as ImmunizationsValidationSchema
} from './ImmunizationValidationSchema';
import { ApptImmunization } from './ApptImmunization';
import { validationSchema } from './validationSchema';
import { Immunizations } from './Immunizations';

export const CreateImmunizationModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    appointmentTypeID,
    apptValues = null,
    initialValues = {},
    insurance = {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [ attachments, setAttachments ] = useState([]);
  const [ appointmentType, setAppointmentType ] = useState(null);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const defaultInitialValues = apptValues ? {
    ...immunizationsInitialValues,

    patient_id: initialValues?.patient_id,
    approved_id: apptValues?.approved_id,
    appointment_id: { id: apptValues.appointment_id, ...apptValues },
    office_id: apptValues?.office
  } : {
    ...apptInitialValues,
    ...initialValues,

    patient_insurance_pcp: insurance?.pcp,
    patient_insurance_spc: insurance?.spc,
    insurance_details: {
      company: insurance?.insurance_company
    },
    appointment_type_id: appointmentType,
    appointment_type: appointmentType,
    time: appointmentType?.time || 0,
    immunizations: [ immunizationInitialValues ]
  };

  const createImmunizationReport = ({ media, ...values }, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.patient_id = values?.patient_id?.id || values.patient_id;

    return immunizationsApi.createImmunizationReport(values).then(({ data }) => {
      if(attachments.length) {
        dispatch(uploadFiles({
          files: attachments,
          owner_type: 'immunization_report',
          owner_id: data?.id
        }));
      }

      enqueueSnackbar('Immunization successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Immunization not created', { variant: 'error' });
    });
  };

  const massCreateImmunizationReports = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.patient_id = values?.patient_id?.id || values.patient_id;
    values.immunizations = values.immunizations.map((item) => ({
      immunization_id: item.immunization_id,
      note: item.note
    }));

    return immunizationsApi.massCreateImmunizationReports(values).then(({ data }) => {
      if (!!attachments.length) {
        attachments.forEach((attachment, i) => {
          dispatch(uploadFiles({
            files: attachment,
            owner_type: 'immunization_report',
            owner_id: data?.immunizations[i].id
          }));
        });
      }

      handleModalResolve(data);
      enqueueSnackbar('Immunization successfully created', { variant: 'success' });
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Immunization not created', { variant: 'error' });
    });
  };

  useEffect(() => {
    if (appointmentTypeID) {
      appointmentTypesApi.fetchAppointmentType(appointmentTypeID).then(setAppointmentType);
    }
  }, []);

  return (
    <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
      <Loader p={3} loading={!appointmentType} render={
        () => (
          <Formik
            initialValues={defaultInitialValues}
            validationSchema={!apptValues ? validationSchema : ImmunizationsValidationSchema}
            onSubmit={!apptValues ? massCreateImmunizationReports : createImmunizationReport}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarPlus} /></SvgIcon>} onClose={handleModalReject}>
                  Add New Immunization
                </ModalHeader>

                <ModalBody>
                  {apptValues ?
                    <>
                      <ApptInfo />
                      <ApptImmunization
                        onAttachmentsUpdate={setAttachments}
                      />
                    </>
                    :
                    <>
                      <AppointmentForm appointmentType={appointmentType} />
                      <Immunizations
                        onAttachmentsUpdate={setAttachments}
                      />
                    </>
                  }
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
