import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../../../helpers/createReduxReducer';
import * as types from './types';

const initialState = {
  isFetched: false,
  isFetching: false,
  files: [],
  filter: {
    per_page: 15,
    page: 1
  }
};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [types.FILES_FETCH_REQUEST]: (state) => {
    return {
      ...state,

      isFetching: true
    };
  },

  [types.SET_FILES_FILTER]: (state, payload) => {
    return {
      ...state,

      filter: {
        ...state.filter,
        ...payload
      }
    };
  },

  [types.FILES_FETCH_SUCCESS]: ({ files, filter, ...state }, { data, pagination }) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      files: pagination.page === 1 ? data : files.concat(data),
      filter: {
        ...filter,
        ...pagination
      }
    };
  },

  [types.ADD_FILE]: ({ files, ...state }, file) => {
    return {
      ...state,

      files: [
        file,

        ...files.filter(({ id }) => id !== file.id)
      ]
    };
  },

  [types.UPDATE_FILE_IN_LIST]: (state, payload) => {
    return {
      ...state,

      files: state.files.map((item) => {
        return item.id === payload.id ? { ...item, ...payload } : item;
      })
    };
  },

  [types.DELETE_FILES_SUCCESS]: ({ filter, files, ...state }, deletedIDs) => {
    const total = filter.total - deletedIDs.length;
    const page = Math.ceil((files.length - deletedIDs.length) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);


    return {
      ...state,

      filter: { ...filter, total, page, last_page },
      files: files.filter((item) => !deletedIDs.find((id) => item.id === id))
    };
  }
});
