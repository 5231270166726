import { Fragment, forwardRef } from 'react';
import moment from 'moment';
import { isString } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { mdiAlarm } from '@mdi/js';
import { SvgIcon } from '../SvgIcon';

export const Date = forwardRef(({
  disableWrapper = false,
  disableMoment = false,
  disableDateIcon = false,
  disableIcon = false,
  date = null,
  color = 'inherit',
  title = '',
  format = 'L',
  noInfoTitle = 'No info.',
  iconFontSize = 'small',
  iconColor = 'inherit',
  iconProps = {},
  ...props
}, ref) => {
  const Wrapper = disableWrapper ? Fragment : Box;
  const wrapperProps = !disableWrapper ? {
    clone: true,
    display: 'inline-flex',
    alignItems: 'center',
    color
  } : {};

  return !date ? (
    <Typography color="textSecondary">{noInfoTitle}</Typography>
  ) : (
    <Wrapper {...wrapperProps}>
      <Typography ref={ref} color={color} {...props}>
        {!disableIcon &&
          <Box clone mr={1} {...iconProps}>
            {!disableDateIcon
              ? <InsertInvitationOutlinedIcon fontSize={iconFontSize} color={iconColor} />
              : <SvgIcon fontSize={iconFontSize} color={iconColor}><Icon path={mdiAlarm} /></SvgIcon>
            }
          </Box>
        }

        {!!title &&
          <Box component="span" mr={1}>
            {title}
          </Box>
        }

        {!disableMoment
          ? (!isString(date) ? moment.unix(date).format(format) : moment(date).format(format))
          : date
        }
      </Typography>
    </Wrapper>
  );
});
