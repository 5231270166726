import { forwardRef } from 'react';
import { makeStyles, Switch as MuiSwitch } from '@material-ui/core';
import { SwitchBase } from '../SwitchBase';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Switch = forwardRef((props, ref) => {
  const { color, classes: classesProp, ...clearedProps } = props;
  const classes = useStyles(props);

  return (
    <SwitchBase
      ref={ref}
      color="default"
      classes={classes}
      Component={MuiSwitch}

      {...clearedProps}
    />
  );
});
