import { useState } from 'react';
import { useSnackbar } from 'notistack';
import * as appointmentsApi from '../../../../api/appointments';
import { Switch } from '../../../FormField';
import { Loader } from '../../../Loader';

export const ConfirmedByOffice = ({ disableUpdate = false, appointment, onUpdate = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ isLoading, setIsLoading ] = useState(false);

  const handleChange = () => {
    setIsLoading(true);

    appointmentsApi.updateAppointment({
      id: appointment.id,
      appointment_confirmed_by_office: !appointment.appointment_confirmed_by_office
    }).then((appointment) => {
      enqueueSnackbar('Appointment successfully updated', { variant: 'success' });
      onUpdate(appointment);
    }).catch(() => {
      enqueueSnackbar('Appointment not updated', { variant: 'error' });
    }).then(() => {
      setIsLoading(false);
    });
  };

  return (
    <Loader surface loading={isLoading} render={
      () => (
        <Switch
          disabled={disableUpdate}
          withoutFormik
          name="appointment_confirmed_by_office"
          color="primary"
          checked={!!appointment.appointment_confirmed_by_office}
          onChange={handleChange}
        />
      )}
    />
  );
};
