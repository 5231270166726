import { useState } from 'react';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { Box, Button, FormControlLabel, makeStyles } from '@material-ui/core';
import { getUniqueValues } from '../../../../../helpers/getUniqueValues';
import {
  Checkbox,
  EmailsSelect,
  SimpleSelect
} from '../../../../../components/FormField';
import { getSignature } from '../signatureHelpers';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmailsUsers = ({ caseItem, message, accountsOptions, setUsersTo }) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();
  const [ ccFieldVisible, setCcFieldVisible ] = useState(!!values?.cc?.length || false);
  const [ bccFieldVisible, setBccFieldVisible ] = useState(!!values?.bcc?.length || false);
  const caseUsers = !!caseItem
    ? caseItem?.case_users?.map((case_user) => ({
      ...case_user?.user,
      ...case_user?.user?.profile
    }))?.filter((item) => !isEmpty(item))
    : [];

  const handleCcFieldVisible = () => {
    setCcFieldVisible(true);
  };

  const handleBccFieldVisible = () => {
    setBccFieldVisible(true);
  };

  const handleChangeTo = (options) => {
    setUsersTo(options?.length ? options?.map((item) => item.email) : []);
    !options?.length && setFieldValue('all_case_users', false);
  };

  const handleToggleCaseUsers = (event) => {
    const isChecked = event.target.checked;

    setFieldValue('to', isChecked
      ? getUniqueValues(caseUsers, 'email')?.filter((item) => !!item.email)
      : message?.to
    );
    setUsersTo(isChecked ? getUniqueValues(caseUsers, 'email') : []);
  };

  const handleChangeConfig = (event) => {
    const option = accountsOptions?.find(({ value }) => value === event.target.value);

    setFieldValue('body', getSignature(option?.data?.signature_line?.html));
  };

  return (
    <>
      <SimpleSelect
        name="config_id"
        label="From"
        options={accountsOptions}
        onChange={handleChangeConfig}
      />

      {!!caseItem &&
        <Box my={2}>
          <FormControlLabel
            label="Send to all case users"
            control={
              <Checkbox
                color="primary"
                name="all_case_users"
                onChange={handleToggleCaseUsers}
              />
            }
          />
        </Box>
      }

      <Box display="flex" alignItems="flex-end">
        <Box flexGrow={1}>
          <EmailsSelect
            multiple
            isCreatable
            name="to"
            label="To"
            onChange={handleChangeTo}
            TextFieldProps={{ required: true, margin: 'dense' }}
          />
        </Box>

        {!ccFieldVisible &&
          <Box mx={1}>
            <Button
              color="primary"
              className={classes.fieldToggleButton}
              onClick={handleCcFieldVisible}
            >
              CC
            </Button>
          </Box>
        }

        {!bccFieldVisible &&
          <Button
            color="primary"
            className={classes.fieldToggleButton}
            onClick={handleBccFieldVisible}
          >
            BCC
          </Button>
        }
      </Box>

      {ccFieldVisible &&
        <EmailsSelect
          multiple
          isCreatable
          name="cc"
          label="CC"
          TextFieldProps={{ margin: 'dense' }}
        />
      }

      {bccFieldVisible &&
        <EmailsSelect
          multiple
          isCreatable
          name="bcc"
          label="BCC"
          TextFieldProps={{ margin: 'dense' }}
        />
      }
    </>
  );
};
