import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isEqual, merge, omit } from 'lodash';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { usePrevious } from '../../../../../helpers/hooks';
import * as officesApi from '../../../../../api/companies';
import { api } from '../../../../../api';
import { OfficePreviewModal } from '../OfficePreviewModal';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const OfficesContext = createContext();

export const OfficesProvider = ({ children, initialState: initialStateProp = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const officesLastGlobalAction = useSelector(({ globalActions }) => globalActions.officesLastGlobalAction);
  const {
    filter,
    offices,
    pagination,
    isFetching,
    isFetched,
    selectedIDs
  } = state;
  const prevFilter = usePrevious(filter);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const cancelFetch = useRef(() => {});

  const fetchOffices = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_OFFICES_REQUEST });

    officesApi.fetchOffices({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_OFFICES_SUCCESS, payload: data });
    });
  };

  const openPreview = (office) => {
    openModal(OfficePreviewModal, {
      payload: {
        id: office.id
      }
    });
  };

  const deleteOffice = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        officesApi.deleteOffice(id).then(() => {
          dispatch({ type: types.DELETE_OFFICE, payload: id });
          enqueueSnackbar('Office successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  const massDeleteItems = (IDs) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        officesApi.massDeleteOffices(IDs).then(() => {
          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            resetOffices({ page: filter.page });
          } else if (offices.length - selectedIDs.length === 0 && filter.page !== 1) {
            resetOffices({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.MASS_DELETE, payload: IDs });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Items are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const addOffice = (newFilter = {}) => {
    dispatch({ type: types.ADD_OFFICE, payload: newFilter });
  };

  const updateOffice = (newFilter = {}) => {
    dispatch({ type: types.UPDATE_OFFICE, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchOffices({ page: filter.page + 1 });
    }
  };

  const resetOffices = (newFilter) => {
    dispatch({ type: types.RESET_OFFICES, payload: newFilter });

    fetchOffices({ page: 1, ...newFilter });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const allItemsIsSelected = () => {
    return offices.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_ITEM_SELECTION, payload: id });
  };

  const toggleAllItemsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_ITEMS_SELECTION });
  };

  useEffect(() => {
    officesLastGlobalAction && dispatch(officesLastGlobalAction);
  }, [ officesLastGlobalAction ]);

  const providerValue = {
    isFetched,
    isFetching,
    offices,
    selectedIDs,
    filter,
    pagination,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    addOffice,
    updateOffice,
    loadNextPage,
    deleteOffice,
    openPreview,
    resetOffices,
    allItemsIsSelected,
    massDeleteItems,
    toggleAllItemsSelection,
    toggleItemSelection,
    applyFilter
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ] );

    if(!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetOffices(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <OfficesContext.Provider value={providerValue}>
      {children}
    </OfficesContext.Provider>
  );
};
