import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import { AccountsContext, AccountsProvider } from '../../app/Dashboard/EmailsPage/AccountsContext';
import { EmailsSendModal } from '../../app/Dashboard/EmailsPage/EmailsClient/EmailsSendModal';
import { MessagesProvider } from '../../app/Dashboard/EmailsPage/MessagesContext';
import { Fab } from '../Fab';
import { ContentCard } from '../ContentCard';
import { Loader } from '../Loader';
import { useModal } from '../ModalsProvider';
import { WidgetsActionButtons } from '../WidgetsActionButtons';
import { MainContent } from './MainContent';

export const EmailsWidget = ({
  page,
  height = null,
  isDisabledOpen = false,
  title = 'Emails',
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const openSendEmailModal = (accounts) => () => {
    accounts.length && openModal(EmailsSendModal, {
      payload: { accounts }
    });
  };

  return (
    <AccountsProvider disableCounters>
      <MessagesProvider>
        <AccountsContext.Consumer>
          {({ isFetched, accounts }) => (
            <ContentCard
              fullHeight={!props.autoHeightMax}
              isMovable={!props.autoHeightMax}
              disableContentPaddings
              title={title}
              icon={
                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  onClick={openSendEmailModal(accounts)}
                >
                  Compose message
                </Fab>
              }
              rightActions={[
                <WidgetsActionButtons
                  isMinimizable
                  page={page}
                  type={props?.item?.i}
                  isDisabledOpen={isDisabledOpen}
                  onMinimize={onMinimize}
                  onMaximized={onMaximized}
                  onClose={onClose}
                  onOpen={onOpen}
                  onPutItem={onPutItem}
                />
              ]}
              leftActions={[
                <Box mr={1}>
                  <EmailIcon color="inherit" fontSize="small" />
                </Box>
              ]}
              {...props}
            >
              <Box height={isMobile ? height : '100%'} display="flex" flexDirection="column" overflow="hidden">
                <Loader p={3} loading={!isFetched} render={
                  () => (!accounts.length ?
                    <Typography align="center">There is no accounts</Typography>
                    :
                    <MainContent />
                  )}
                />
              </Box>
            </ContentCard>
          )}
        </AccountsContext.Consumer>
      </MessagesProvider>
    </AccountsProvider>
  );
};
