import cn from 'classnames';
import { Chip, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { styles } from '../Select/components/MultiValue';

const useStyles = makeStyles(styles);

export const MultiValue = ({
  isFocused,
  removeProps,
  children
}) => {
  const classes = useStyles();

  return children && (
    <Chip
      tabIndex={-1}
      label={children?.email || children || ''}
      className={cn(classes.chip, {
        [classes.chipFocused]: isFocused
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
};
