import { makeStyles, Box, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../components/Loader';
import { ScrollbarsLoader } from '../../../../../../components/ScrollbarsLoader';
import { styles } from '../styles';

const useStyles = makeStyles({
  root: {
    width: '100% !important'
  }
});

export const List = ({ row: Row, searchContext, onResultClick = () => {} }) => {
  const { searchResults, meta, isFetching, isFetched, loadNextPage } = searchContext;
  const classes = useStyles(styles);

  return (
    <ScrollbarsLoader
      translateContentSizesToHolder
      className={classes.root}
      total={meta.total}
      rowsCount={searchResults.length}
      isFetched={isFetched}
      isFetching={isFetching}
      onNeedNextPage={loadNextPage}
      style={{ maxHeight: 400 }}
    >
      <Loader p={2} fullWidth loading={!isFetched} render={
        () => !meta.total ? (
          <Box p={2}>
            <Typography align="center">No search result</Typography>
          </Box>
        ) : (
          searchResults.map((item, index) => (
            <Row
              key={index}
              item={item}
              onResultClick={onResultClick}
            />
          ))
        )}
      />
    </ScrollbarsLoader>
  );
};
