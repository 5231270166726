import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell grow flexBasis={columnsWidths.userName}>
          User name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.role}>
          Position
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.office}>
          Office
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.phone}>
          Phone
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.accessDate}>
          Access Date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.closeDate}>
          Close Date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
