import { useEffect, useState } from 'react';
import * as insurancesApi from '../../../../api/profile/insurances';
import { Autocomplete } from '../../Autocomplete';

export const InsurancesTypesSelect = ({ params, ...props }) => {
  const [ types, setTypes ] = useState([]);

  useEffect(() => {
    insurancesApi.fetchInsurancesTypes({
      params
    }).then(setTypes);
  }, [ params ]);

  return (
    <Autocomplete
      options={types}
      placeholder="Select type..."
      getOptionLabel={(option) => option}
      getOptionValue={(option) => option}
      getOptionSelected={(option, value) => option === value}

      {...props}
    />
  );
};
