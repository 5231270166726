import { Box, SvgIcon } from '@material-ui/core';
import { mdiChartBellCurve } from '@mdi/js';
import Icon from '@mdi/react';
import { ContentCard } from '../../../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../../../components/WidgetsActionButtons';

export const AnalyticsData = ({
  isOpen,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  return (
    <ContentCard
      fullHeight
      isMovable
      isResizable={isOpen}
      title="Analytics Data"
      leftActions={[
        <Box mr={1}>
          <SvgIcon fontSize="small"><Icon path={mdiChartBellCurve} /></SvgIcon>
        </Box>
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
    >
      Analytics Data Content
    </ContentCard>
  );
};
