import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Link as MuiLink, Typography } from '@material-ui/core';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import RoomIcon from '@material-ui/icons/Room';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import { apiModelsMap, itemsRoutesByModels } from '../../dataMaps/apiModels';
import { getUserFullName, getUserNameAbbr } from '../../helpers/users';
import { Avatar } from '../Avatar';

export const renderResourceLabel = ({ resource: { extendedProps: { book } } }) => {
  const { provider, location, appointment_book_name } = book;

  return (
    <Box textAlign="left" py={0.75} px={1}>
      <Grid container spacing={1} wrap="nowrap" alignItems="center">
        <Grid item>
          {provider ? (
            <Avatar
              src={provider?.profile?.avatar || provider.avatar}
              alt={getUserNameAbbr(provider?.profile || provider)}
              title={getUserFullName(provider?.profile || provider)}
              component={RouterLink}
              to={itemsRoutesByModels[apiModelsMap.user](provider.id)}
            />
          ) : (
            <Avatar />
          )}
        </Grid>

        <Grid item zeroMinWidth>
          <Box overflow="hidden">
            {provider ? (
              <MuiLink
                component={RouterLink}
                to={itemsRoutesByModels.user(provider?.id)}
                color="textPrimary"
                title={getUserFullName(provider?.profile || provider)}
              >
                <Box clone mr={1}>
                  <AssignmentIndOutlinedIcon fontSize="small" />
                </Box>

                {getUserFullName(provider?.profile || provider)}
              </MuiLink>
            ) : (
              <Typography>No provider</Typography>
            )}
          </Box>

          {!!location?.full_address &&
            <Box overflow="hidden">
              <Typography noWrap variant="body2" title={location?.full_address}>
                <Box clone mr={1}>
                  <RoomIcon fontSize="small" />
                </Box>

                {location?.full_address}
              </Typography>
            </Box>
          }

          {!!appointment_book_name &&
            <Box clone display="flex">
              <Typography noWrap variant="body2" title={appointment_book_name}>
                <Box clone mr={1}>
                  <BookOutlinedIcon fontSize="small" />
                </Box>

                {appointment_book_name}
              </Typography>
            </Box>
          }
        </Grid>
      </Grid>
    </Box>
  );
};
