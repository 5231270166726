import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { UserLink } from '../../../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../../components/Loader';
import { IconButton } from '../../../../../../../../components/IconButton';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter({
      ...filter?.params,

      insurance_type: filter?.params?.insurance_type?.value || filter?.params?.insurance_type
    });
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.patientName}>
        <Typography noWrap>
          {filter.params.search || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.medRec}>
        <Typography noWrap>
          {filter.params.med_rec || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dob}>
        <Typography noWrap>
          {filter.params.dob ? moment(filter.params.dob).format('L') : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.ssn}>
        <Typography noWrap>
          {filter.params.ssn || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.apptFrom}>
        <Typography noWrap>
          {filter.params.appointment_from ? moment.unix(filter.params.appointment_from).format('L') : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.apptTo}>
        <Typography noWrap>
          {filter.params.appointment_to ? moment.unix(filter.params.appointment_to).format('L') : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        <UserLink
          noWrap
          size="sm"
          user={filter?.params?.provider_id}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.insurType}>
        <Typography noWrap>
          {filter?.params?.insurance_type?.label || filter?.params?.insurance_type || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.expirationDay}>
        <Typography noWrap>
          {filter.params.expiration_day ? moment(filter.params.expiration_day).format('L') : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

