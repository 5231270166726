import { useContext, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import cn from 'classnames';
import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import * as eventsApi from '../../../../api/schedule-events';
import { useCalendarEventsCount } from '../../../../utils/useCalendarEventsCount';
import { Badge } from '../../../Badge';
import { Divider } from '../../../Divider';
import { useModal } from '../../../ModalsProvider';
import { FullCalendar, viewTypes } from '../../../FullCalendar';
import { CreateScheduleEventModal } from '../../CreateScheduleEventModal';
import { ScheduleContext } from '../../ScheduleProvider';
import { ViewScheduleEventModal } from '../../ViewScheduleEventModal';
import { EventsList } from '../../EventsList';
import { renderEventContent } from '../../renderEventContent';
import { CalendarContext } from '../CalendarProvider';
import { MoveScheduleEventModal } from './MoveScheduleEventModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const dayMaxEvents = 2;

const transformEvent = (event) => {
  return {
    id: event.id,
    color: 'transparent',
    title: event.title,
    start: event.started_at * 1000,
    end: event.finished_at * 1000,
    editable: !event.is_system,
    extendedProps: {
      event
    }
  };
};

export const Calendar = ({ hideApplyFields, createScheduleEventModalProps = {} }) => {
  const theme = useTheme();
  const { openModal } = useModal();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ currentViewType, setCurrentViewType ] = useState(viewTypes.dayGridMonth);
  const isDayGridMonth = currentViewType === viewTypes.dayGridMonth;
  const calendarContext = useContext(CalendarContext);
  const scheduleContext = useContext(ScheduleContext);
  const {
    isFetched,
    daysData,
    filter: { started_from, started_to },
    selectedDate,
    setSelectedDate,
    reloadCalendar
  } = calendarContext;
  const classes = useStyles();

  useCalendarEventsCount({
    isDayGridMonth,
    daysData,
    calendarEl: document.querySelector('.' + classes.calendar)
  });

  const handleDatesSet = ({ start, end, view }) => {
    const datesRange = {
      started_from: moment(start).unix(),
      started_to: moment(end).unix()
    };

    setCurrentViewType(view.type);

    if (!isEqual({ started_from, started_to }, datesRange)) {
      calendarContext.applyFilter(datesRange);
    }
  };

  const getDayCellClassNames = ({ date }) => {
    return cn(classes.day, {
      [classes.day_selected]: isDayGridMonth && moment(date).isSame(selectedDate, 'day')
    });
  };

  const createEvent = ({ start, end }) => {
    openModal(CreateScheduleEventModal, {
      payload: {
        hideApplyFields,

        ...createScheduleEventModalProps,

        initialValues: {
          ...createScheduleEventModalProps.initialValues,

          started_at: start,
          finished_at: end
        }
      },

      onModalResolved: (event) => {
        if (event.recurring_rule) {
          reloadCalendar();
        } else {
          calendarContext.addEvent(event);
        }

        if (isDayGridMonth) {
          scheduleContext.reloadEvents();
        }
      }
    });
  };

  const handleDateClick = ({ date }) => {
    if (selectedDate.isSame(moment(date), 'day')) {
      createEvent({
        start: moment(date).unix(),
        end: moment(date).endOf('day').unix()
      });
    }
  };

  const handleDatesSelect = (info) => {
    createEvent({
      start: moment(info.start).unix(),
      end: moment(info.end).unix()
    });
  };

  const handleEventUpdate = (event) => {
    if (event.recurring_rule) {
      reloadCalendar();
    } else {
      calendarContext.updateEvent(event);
    }

    if (isDayGridMonth) {
      if (event.recurring_rule) {
        scheduleContext.reloadEvents();
      } else {
        scheduleContext.updateEvent(event);
      }
    }
  };

  const handleEventDelete = (event) => {
    if (event.recurring_rule) {
      reloadCalendar();
    } else {
      calendarContext.deleteEvent(event.id);
    }

    if (isDayGridMonth) {
      if (event.recurring_rule) {
        scheduleContext.reloadEvents();
      } else {
        scheduleContext.deleteEvent(event.id);
      }
    }
  };

  const handleEventClick = ({ event }) => {
    openModal(ViewScheduleEventModal, {
      payload: {
        hideApplyFields,
        event: event.extendedProps.event,
        onUpdate: handleEventUpdate,
        onDelete: handleEventDelete
      }
    });
  };

  const handleEventMove = ({ event: { start, end, extendedProps: { event } }, revert }) => {
    const transformedEvent = {
      ...event,
      users: event.users.map(({ id }) => id),
      started_at: moment(start).unix(),
      finished_at: moment(end).unix()
    };

    if (!event.is_recurring_dummy) {
      eventsApi.updateEvent(transformedEvent).then(() => {
        scheduleContext.reloadEvents();
        reloadCalendar();
      }).catch(revert);
    } else {
      openModal(MoveScheduleEventModal, {
        payload: {
          event: transformedEvent
        },
        onModalResolved: () => {
          scheduleContext.reloadEvents();
          reloadCalendar();
        },
        onModalRejected: revert
      });
    }
  };

  useEffect(() => {
    calendarContext.applyFilter({ count_per_day: dayMaxEvents, timezone: moment.tz.guess(true) });
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.calendar}>
        <FullCalendar
          isLoading={!isFetched}
          dayMaxEvents={dayMaxEvents}
          selectedDate={selectedDate}
          onSelectedDateChange={setSelectedDate}
          dayCellClassNames={getDayCellClassNames}
          eventDataTransform={transformEvent}
          events={calendarContext.events}
          selectMinDistance={2}
          select={handleDatesSelect}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          datesSet={handleDatesSet}
          eventDrop={handleEventMove}
          eventResize={handleEventMove}
          eventContent={renderEventContent}
        />
      </div>

      {isDayGridMonth &&
        <Box display="flex" flexDirection="column" width={isMobile ? '100%' : 372} height="100%" p={1}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mr={2}>
              <Typography variant="h3">
                {selectedDate.format('ddd, DD MMMM')}
              </Typography>
            </Box>

            <Badge showZero badgeContent={scheduleContext.filter.total} color="info" title="Appointments count" />
          </Box>

          <Box mt={1.75} mb={1.5}>
            <Divider />
          </Box>

          <Box flexGrow={1}>
            <EventsList
              hideApplyFields={hideApplyFields}
              selectedDate={selectedDate}
              onEventUpdate={handleEventUpdate}
              onEventDelete={handleEventDelete}
            />
          </Box>
        </Box>
      }
    </div>
  );
};
