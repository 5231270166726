export const styles = ({ spacing, breakpoints }) => ({
  root: {
    paddingLeft: spacing(4),
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingRight: spacing(2),

    [breakpoints.down('md')]: {
      padding: spacing(0.25)
    }
  }
});
