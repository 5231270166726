export const styles = () => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },

  videoWindow: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  }
});
