import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { Loader } from '../../../../../../../components/Loader';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidths = {
  search: 150,
  dateFrom: 150,
  dateTo: 150,
  medicalFormType: 200,
  provider: 150,
  users: 170,
  insuranceId: 150,
  fromDOI: 150,
  toDOI: 150,
  orderBy: 100,
  actions: 52
};

export const SavedFiltersList = ({ onApplySavedFilter, hiddenColumns }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No saved filters</Typography>
        ) : (
          <>
            <TableHeader hiddenColumns={hiddenColumns} />

            <MuiList disablePadding>
              <InfiniteListLoader
                items={savedFilters}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ onApplySavedFilter, hiddenColumns }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
