import { useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import T from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { makeStyles, IconButton, InputAdornment, Fade } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import { stopPropagation } from '../../../helpers/dom';
import { VerticalDivider } from '../../VerticalDivider';
import { TextField } from '../TextField';
import { useFormikField } from '../useFormikField';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const TextFieldComponent = ({ TextFieldComponentProps, ...props }) => (
  <TextField
    unbindForm
    withoutFormik

    {...props}
    {...TextFieldComponentProps}
  />
);

const propTypes = {
  Component:  T.elementType.isRequired,
  name: T.string.isRequired
};

export const DatePickerBase = ({
  Component,
  withoutFormik,
  formikValue,
  name,
  outputFormat = 'X',
  value = null,
  disabled,
  clearable = false,
  zeroMinWidth = false,
  isEditable,
  helperText,
  minDate: minDateProp,
  maxDate: maxDateProp,
  onChange = () => {},
  TextFieldComponentProps = {},

  ...props
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField(name);
  const { fieldProps: [ field = {}, { touched } = {}, { setTouched } = {} ] = [], error } = formikFieldProps;
  const [ isOpen, setIsOpen ] = useState(false);
  const [ minDate, setMinDate ] = useState();
  const [ maxDate, setMaxDate ] = useState();
  const classes = useStyles();
  const hasIsEditable = typeof isEditable === 'boolean';
  // React Hook Form
  const formContext = useFormContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { fieldState } = (formContext && useController({
    name, control: formContext?.control
  })) || {};
  const errorMessage = fieldState?.error?.message;

  const handleClearClick = () => {
    onChange(null);
  };

  const handleChange = (date, value) => {
    onChange(date, value);
    setTouched?.(true);
  };

  useEffect(() => {
    if (field.value !== formikValue) {
      onChange(field.value ? moment(field.value, outputFormat) : null, field.value);
    }
  }, [ field.value ]);

  useEffect(() => {
    if (minDateProp) {
      setMinDate(moment.unix(minDateProp).toDate());
    }
  }, [ minDateProp ]);

  useEffect(() => {
    if (maxDateProp) {
      setMaxDate(moment.unix(maxDateProp).toDate());
    }
  }, [ maxDateProp ]);

  return (
    <Component
      fullWidth
      clearable={clearable}
      open={isOpen}
      name={name}
      disabled={disabled || isEditable === false}
      value={value && value.toDate()}
      minDate={minDate}
      maxDate={maxDate}
      error={!!(touched || fieldState?.invalid) && !!(error || errorMessage)}
      helperText={((touched || fieldState?.invalid) && (error || errorMessage)) || helperText}
      onChange={handleChange}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      TextFieldComponent={TextFieldComponent}
      TextFieldComponentProps={{
        ...TextFieldComponentProps,

        zeroMinWidth,

        InputProps: {
          ...TextFieldComponentProps.InputProps,

          disabled: disabled || isEditable === false,
          endAdornment: isEditable !== false && (
            <InputAdornment position="end">
              {clearable && (value || props.inputValue) &&
                <>
                  <IconButton
                    disabled={disabled}
                    size="small"
                    onClick={stopPropagation(handleClearClick)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>

                  <VerticalDivider horizontalGutters={0.5} />
                </>
              }

              <IconButton
                disabled={disabled}
                edge="end"
                size="small"
                onClick={() => setIsOpen(true)}
              >
                <EventIcon className={classes.dropDownIcon} />
              </IconButton>
            </InputAdornment>
          ),

          classes: {
            disabled: cn(hasIsEditable && classes.notEditableInput)
          }
        }
      }}

      InputLabelProps={{
        ...(value ? { shrink: true } : {}),

        classes: {
          disabled: cn(hasIsEditable && classes.notEditableInputLabel),
          error: cn(hasIsEditable && classes.notEditableErrorInputLabel)
        }
      }}

      DialogProps={{
        disableRestoreFocus: true,
        TransitionComponent: Fade
      }}

      {...props}
    />
  );
};

DatePickerBase.propTypes = propTypes;
