export const styles = ({
  spacing,
  palette,
  typography: { fontWeightMedium, pxToRem },
  breakpoints
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    flexGrow: 1,
    padding: 0,

    [breakpoints.up('md')]: {
      padding: spacing(0, 3)
    }
  },

  mainContent: {
    flexGrow: 1,
    overflow: 'auto'
  },

  actionButton: {
    flexGrow: 0,
    marginLeft: 'auto',
    paddingBottom: spacing(2)
  },

  backButton: {
    marginRight: spacing()
  },

  content: {
    marginBottom: spacing(4)
  },

  content__header: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: `1px solid ${palette.slategray}`
  },

  contentInfo: {
    paddingLeft: spacing(2)
  },

  title: {
    fontWeight: fontWeightMedium,
    marginBottom: spacing()
  },

  mainTitle: {
    minWidth: 146,
    color: palette.slategray
  },

  formLabel: {
    color: palette.slategray,
    fontSize: pxToRem(14)
  }
});
