import ReactPlayer from 'react-player';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Popper } from '../../../../../../components/Popper';
import { VideosMenu } from '../../../VideosMenu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ item: video }) => {
  const classes = useStyles();

  const openFileMenu = (callback) => (e) => {
    e.stopPropagation();

    callback(e);
  };

  return (
    <div className={classes.root}>
      <div className={classes.videoContent}>
        <ReactPlayer
          controls
          className={classes.videoPlayer}
          url={video.url}
          width="100%"
          height="100%"
        />
      </div>

      <div className={classes.videoInfo}>
        <div className={classes.videoTitle}>
          <Typography
            noWrap
            component="div"
            className={classes.videoTitle__name}
          >
            {video.original_filename}
          </Typography>

          <Typography className={classes.videoTitle__subName}>{video.video_fragments_count} fragments</Typography>
        </div>

        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton
                buttonRef={anchorRef}
                onClick={openFileMenu(handleToggle)}
              >
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          <VideosMenu video={video}/>
        </Popper>
      </div>
    </div>
  );
};
