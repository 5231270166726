import { useContext } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector } from 'react-redux';
import { Label } from '../../../../../components/Label';
import { UserLink } from '../../../../../components/UserLink';
import { stopPropagation, preventDefault } from '../../../../../helpers/dom';
import { CurrencyFormat } from '../../../../../components/CurrencyFormat';
import { ExpirationDate } from '../../../../../components/ExpirationDate';
import { CompanyLink } from '../../../../../components/CompanyLink';
import { useModal } from '../../../../../components/ModalsProvider';
import { SvgIcon } from '../../../../../components/SvgIcon';
import { Divider } from '../../../../../components/Divider';
import { Popper } from '../../../../../components/Popper';
import { UserContext } from '../../../Members/User/UserProvider';
import { insuranceStatusMap } from '../AddInsuranceInfoModal';
import { InsurancesContext, isPrimary } from '../InsurancesProvider';
import { ViewInsuranceInfoModal } from '../ViewInsuranceInfoModal';
import { Menu } from './Menu';

export const InsuranceInfo = ({ insurance, withMenu }) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const userContext = useContext(UserContext);
  const { updateInsurance, deleteInsurance, resetInsurances } = useContext(InsurancesContext);
  const currentUser = useSelector(({ profile }) => profile);
  const user = userContext?.user || insurance?.case_user || currentUser?.user;

  const openPreview = () => {
    openModal(ViewInsuranceInfoModal, {
      payload: {
        user,
        insurance,
        onEdit: updateInsurance,
        onDelete: deleteInsurance
      },
      onModalResolved: resetInsurances
    });
  };

  return (
    <Box minHeight={385} onClick={preventDefault(openPreview)}>
      <Box p={1}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={10}>
            <Label
              color={theme.palette.slategray}
              label={insuranceStatusMap[insurance.status_type] }
            />
          </Grid>

          {withMenu ?
            <Grid item xs={2}>
              <Popper
                placement="left-start"
                anchorRenderer={({ anchorRef, handleToggle }) => (
                  <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                    <MoreVertIcon />
                  </IconButton>
                )}
              >
                {({ handleClose }) => (
                  <Menu insurance={insurance} onClose={handleClose} />
                )}
              </Popper>
            </Grid>
            :
            <Grid item xs={2} />
          }

          <Grid item xs={6}>
            {!!insurance?.case_user ? (
              <Typography noWrap variant="h5" title={insurance.type_insurance}>
                {insurance.type_insurance}
              </Typography>
            ) : (
              <Typography variant="h5">
                Type Insurance
              </Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            {!!insurance?.case_user ? (
              <UserLink
                parentProps={{ mb: 1 }}
                size="sm"
                variant="h5"
                user={insurance?.case_user}
              />
            ) : (
              <Typography noWrap variant="h5" title={insurance.type_insurance}>
                {insurance.type_insurance}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5" display="inline">
              Insurance ID

              {isPrimary(insurance.status_type) &&
                <Box component="span" ml={0.5}>
                  <SvgIcon color="success" fontSize="small">
                    <CheckCircleIcon />
                  </SvgIcon>
                </Box>
              }
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography noWrap variant="h5" title={insurance.insuranceId}>
              {insurance.insuranceId}
            </Typography>
          </Grid>

          <Grid item xs={2} />
        </Grid>
      </Box>

      <Divider gutter={1} />

      <Box p={1}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={5}>
            <Typography noWrap variant="caption" color="textSecondary">
              Insurance
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <CompanyLink
              variant="subtitle2"
              company={insurance?.insurance_company}
            />
          </Grid>

          <Grid item xs={5}>
            <Typography noWrap variant="caption" color="textSecondary">
              Group ID
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap variant="subtitle2" color="textPrimary">
              {insurance.groupId}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography noWrap variant="caption" color="textSecondary">
              PCP, $
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap variant="subtitle2" color="textPrimary">
              <CurrencyFormat value={insurance.pcp} />
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography noWrap variant="caption" color="textSecondary">
              SPC, $
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap variant="subtitle2" color="textPrimary">
              <CurrencyFormat value={insurance.spc} />
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography noWrap variant="caption" color="textSecondary">
              Financial Class
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap variant="subtitle2" color="textPrimary">
              {insurance?.financial_class?.code || '-'}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider gutter={1} type="dashed" />
          </Grid>

          <Grid item xs={5}>
            <Typography noWrap variant="h5">
              Expiration day
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {!insurance.expiration_day
              ? <Typography color="textSecondary">No info.</Typography>
              : <ExpirationDate date={insurance.expiration_day}/>
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
