import { api } from '../api';

export const fetchServices = (config = {}) => {
  return api.get('/loginQueues', config).then(({ data }) => {
    return data;
  }).catch((thrown) => {
    throw thrown;
  });
};

export const createService = (data) => {
  return api.post('/loginQueues', data).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const fetchService = (id) => {
  return api.get(`/loginQueues/${id}`).then(({ data }) => data);
};

export const updateService = ({ id, ...data }) => {
  return api.put(`/loginQueues/${id}`, data).then(({ data }) => data);
};

export const deleteService = (id) => {
  return api.delete(`/loginQueues/${id}`).then(({ data }) => data);
};
