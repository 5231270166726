export const styles = ({ spacing, palette: { grey }, breakpoints }) => ({
  root: {
    marginTop: spacing(1),
    marginLeft: spacing(5),
    paddingRight: spacing(5),
    paddingBottom: spacing(3),

    [breakpoints.down('md')]: {
      marginLeft: 0
    }
  },

  root_static: {
    marginLeft: spacing(2),
    paddingRight: 0,

    [breakpoints.down('md')]: {
      marginLeft: 0
    }
  },

  actionBtn: {
    marginRight: spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
    color: grey[600]
  },

  counterTab: {
    display: 'flex',
    alignItems: 'center'
  },

  counterTab__count: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing()
  }
});
