import { FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import { RadioGroup, TextField } from '../../FormField';
import { SurgeryHistory } from './SurgeryHistory';

export const GastroHistory = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          GASTROINTESTINAL HISTORY
        </Typography>
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography gutterBottom variant="h5">
          Do you have a history of Peptic Ulcer Disease?
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <RadioGroup
          row
          // eslint-disable-next-line max-len
          name="forms.past_medical_history.gastrointestinal_history.do_you_have_a_history_of_peptic_ulcer_disease"
        >
          <FormControlLabel
            value="yes"
            label={<Typography>Yes</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="no"
            label={<Typography>No</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item md={4} xs={12}>
        <Typography gutterBottom variant="h5">
          Do you have a history of GI, Stomach bleed?
        </Typography>
      </Grid>

      <Grid item md={8} xs={12}>
        <RadioGroup
          row
          // eslint-disable-next-line max-len
          name="forms.past_medical_history.gastrointestinal_history.do_you_have_a_history_of_gi_stomach_bleed"
        >
          <FormControlLabel
            value="yes"
            label={<Typography>Yes</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="no"
            label={<Typography>No</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom variant="h5">
          {/* eslint-disable-next-line max-len */}
          Do you take any medications for your stomach? (please include over the counter medications: e.g. Pepcid, Tums, Zantac, etc, dosage and frequency)
        </Typography>

        <TextField
          fullWidth
          multiline
          disableUnderline
          // eslint-disable-next-line max-len
          name="forms.past_medical_history.gastrointestinal_history.do_you_take_any_medications_for_your_stomach"
          placeholder="Please describe"
          margin="dense"
          variant="filled"
          rows={3}
        />
      </Grid>

      <SurgeryHistory />
    </>
  );
};
