import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  appointment_id: Yup.mixed().nullable(),
  approved_id: Yup.mixed().nullable(),
  office_id: Yup.mixed().nullable(),
  prescribed_at: Yup.mixed().nullable(),
  medications: Yup.array().of(Yup.object().shape({
    ndc_code_id: Yup.mixed().nullable().required(),
    dose: Yup.string().nullable().required().max(50),
    qty: Yup.string().nullable().required().max(50),
    note: Yup.string().nullable().max(1000)
  })).required(),
  prescription: Yup.string().nullable().required().max(50)
});
