export const initialValues = {
  file_number: null,
  name: null,
  statuses: [],
  users: [],
  types: [],
  case_roles: [],
  created_from: null,
  created_to: null
};
