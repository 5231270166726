import { useEffect, useState } from 'react';
import { isEmpty, every } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { NestedList } from '../../../../../NestedList';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';

export const CurrentHistory = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !every(report, isEmpty) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Current History"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box py={2}>
        {report?.no_interval_change &&
          <ReadonlyCheckbox
            checked={report?.no_interval_change}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="No Interval Change"
          />
        }

        {report?.flare_up_of_pain &&
          <ReadonlyCheckbox
            checked={report?.flare_up_of_pain}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="Flare-up of Pain"
          />
        }

        {report?.persistent_pain &&
          <ReadonlyCheckbox
            checked={report?.persistent_pain}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="Persistent pain"
          />
        }

        {report?.questionnaire_reviewed_discussed &&
          <ReadonlyCheckbox
            checked={report?.questionnaire_reviewed_discussed}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="Questionnaire Reviewed & Discussed"
          />
        }

        {report?.current_complaints_discussed &&
          <ReadonlyCheckbox
            checked={report?.current_complaints_discussed}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="Current Complaints Discussed"
          />
        }

        {report?.current_medications_use_their_side_effects_discussed &&
          <ReadonlyCheckbox
            checked={report?.current_medications_use_their_side_effects_discussed}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="Current Medications Use & Their Side Effects Discussed"
          />
        }

        {report?.alternative_treatments_discussed &&
          <ReadonlyCheckbox
            checked={report?.alternative_treatments_discussed}
            BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
            iconColor="success"
            title="Alternative Treatments Discussed"
          />
        }

        {report?.note &&
          <Box my={2} display="flex" alignItems="flex-start">
            <Box pl={3.5} display="flex" alignItems="center">
              <NotesIcon fontSize="small" />

              <Box mr={1.5} ml={0.5}>
                <Typography variant="subtitle2">
                  Note
                </Typography>
              </Box>
            </Box>

            <Typography>
              {report?.note}
            </Typography>
          </Box>
        }
      </Box>
    </NestedList>
  );
};
