export const styles = ({ palette, spacing }) => ({
  root: {
    background: palette.grey[50],
    marginTop: spacing(0.5),
    padding: spacing(0.5, 1),
    borderRadius: 12
  },

  fileInfo: {
    overflow: 'auto'
  },

  fileName: {
    wordWrap: 'break-word'
  }
});
