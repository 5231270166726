import { createContext, useReducer, useEffect, useRef } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useMemoEffect, usePrevious } from '../../../../../helpers/hooks';
import { api } from '../../../../../api';
import * as casesApi from '../../../../../api/cases/reminders/cases';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CaseReminderCasesContext = createContext(null);

export const CaseReminderCasesProvider = ({
  children,
  filter: filterProp = {}
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    filter,
    pagination,
    cases
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchCases = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_CASES_REQUEST });

    casesApi.fetchCases({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_CASES_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchCases({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetCases = (newFilter = {}) => {
    dispatch({ type: types.RESET_CASES, payload: newFilter });
    fetchCases(newFilter);
  };

  const providerValue = {
    isFetched,
    isFetching,
    cases,
    filter,
    pagination,
    meta: { ...filter, ...pagination },

    // functions
    applyFilter,
    resetCases,
    loadNextPage
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useMemoEffect(() => {
    resetCases(filter);
  }, [ prevFilter, filter ]);

  useEffect(() => {
    const newFilter = omit({ ...filter, ...filterProp }, [ 'page' ]);

    if (!isEqual(omit(filter, [ 'page' ]), newFilter)) {
      resetCases(newFilter);
    }
  }, [ filter, filterProp ]);

  return (
    <CaseReminderCasesContext.Provider value={providerValue}>
      {children}
    </CaseReminderCasesContext.Provider>
  );
};
