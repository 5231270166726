import { useContext } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { stopPropagation } from '../../../../helpers/dom';
import { TaskPreviewContext } from '../../../../components/tasks/TasksProvider';
import { useModal } from '../../../../components/ModalsProvider';
import { Popper } from '../../../../components/Popper';
import { CaseViewModal } from '../../CasesPage/Cases/CaseViewModal';
import { AddCase } from '../AddCase';

export const EditCase = ({
  isUpdateCase,
  onDelete,
  onDeleteCase,
  classes
}) => {
  const { openModal } = useModal();
  const { selectedTask, caseFields } = useContext(TaskPreviewContext);

  const openCasePreview = () => {
    if (selectedTask?.parent?.id || caseFields?.parent_id?.id) {
      openModal(CaseViewModal, {
        payload: { id: selectedTask?.parent?.id || caseFields?.parent_id?.id }
      });
    }
  };

  return (
    <div className={classes.case}>
      <Typography className={classes.case__title}>
        Case
      </Typography>

      <Typography onClick={openCasePreview} className={classes.caseLink}>
        {!caseFields.parent_id ? selectedTask?.parent?.name : caseFields?.parent_id?.name}
      </Typography>

      {!!isUpdateCase &&
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton
                className={classes.actionIcon}
                buttonRef={anchorRef}
                onClick={stopPropagation(handleToggle)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <AddCase task={selectedTask} onClose={handleClose} />
          )}
        </Popper>
      }

      {!onDelete &&
        <IconButton
          className={classes.actionIcon}
          onClick={onDeleteCase}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      }
    </div>
  );
};
