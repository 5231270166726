import { get } from 'lodash';
import { Link, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { renderRoutes, Route } from '../../../components/router';
import { Tabs, Tab } from '../../../components/Tabs';
import { routes } from './routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CodesPage = ({ location }) => {
  const classes = useStyles();

  const getActiveRoute = () => {
    return get(routes.find(({ path }) => location.pathname.indexOf(path) === 0), 'path', '/codes/procedure-codes');
  };

  return (
    <div className={classes.root}>
      <Tabs value={getActiveRoute()}>
        {routes.map(({ path, icon, label }) => (
          <Tab
            key={path}
            component={Link}
            to={path}
            value={path}
            icon={icon}
            label={label}
          />
        ))}
      </Tabs>

      <div className={classes.body}>
        <Route exact path="/codes">
          <Redirect to="/codes/procedure-codes" />
        </Route>

        {renderRoutes(routes)}
      </div>
    </div>
  );
};
