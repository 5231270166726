import { Icon } from '@mdi/react';
import { mdiFileDownloadOutline } from '@mdi/js';
import cn from 'classnames';
import { IconButton, makeStyles, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import CloseIcon from '@material-ui/icons/Close';
import NotesIcon from '@material-ui/icons/Notes';
import { DownloadsTab } from './DownloadsTab';
import { FilesTab } from './FilesTab';
import { InfoTab } from './InfoTab';
import { sidebarNamesMap } from './sidebarNamesMap';
import { sidebarTypesMap } from './sidebarTypesMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChatAbout = ({ thread, aboutTab, onAboutTabChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChatClose = () => {
    onAboutTabChange(null);
  };

  const isCurrentTab = (tab) => {
    return aboutTab === tab;
  };

  return (
    <div className={
      cn({
        [classes.root]: !isMobile,
        [classes.mobileRoot]: isMobile,
        [classes.sidebarHidden]: isMobile && !aboutTab
      })}
    >
      <div className={classes.header}>
        {isCurrentTab(sidebarTypesMap.info) &&
          <NotesIcon />
        }

        {isCurrentTab(sidebarTypesMap.files) &&
          <FolderIcon />
        }

        {isCurrentTab(sidebarTypesMap.downloads) &&
          <SvgIcon color="inherit">
            <Icon path={mdiFileDownloadOutline} />
          </SvgIcon>
        }

        <Typography variant="h3">{sidebarNamesMap[aboutTab]}</Typography>

        <IconButton edge="end" onClick={handleChatClose}>
          <CloseIcon />
        </IconButton>
      </div>

      {isCurrentTab(sidebarTypesMap.info) &&
        <InfoTab thread={thread} />
      }

      {isCurrentTab(sidebarTypesMap.files) &&
        <FilesTab handleSetAboutTab={onAboutTabChange} />
      }

      {isCurrentTab(sidebarTypesMap.downloads) &&
        <DownloadsTab handleSetAboutTab={onAboutTabChange} />
      }
    </div>
  );
};
