export const FETCH_FAXES_REQUEST = 'FETCH_FAXES_REQUEST';
export const FETCH_FAXES_SUCCESS = 'FETCH_FAXES_SUCCESS';
export const FETCH_FAXES_ERROR = 'FETCH_FAXES_ERROR';
export const APPLY_FILTER = 'APPLY_FILTER';
export const RESET_FAXES = 'RESET_FAXES';
export const DELETE_FAXES = 'DELETE_FAXES';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const ADD_FAXES_TO_SELECTED = 'ADD_FAXES_TO_SELECTED';
export const DELETE_FAXES_FROM_SELECTED = 'DELETE_FAXES_FROM_SELECTED';
export const RESET_FAXES_SELECTED = 'RESET_FAXES_SELECTED';
