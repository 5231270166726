import moment from 'moment';
import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required().min(2).max(36),
  last_name: Yup.string().nullable().required().min(2).max(36),
  phone_number: Yup.string().nullable().min(2).max(20),
  gender: Yup.string().nullable(),
  birthday: Yup.date().format(moment.HTML5_FMT.DATE).nullable(),
  email: Yup.string().email().nullable(),
  ssn: Yup.string().nullable(),
  is_notify: Yup.boolean()
});
