import { ListRow, ListRowCell } from '../../../ListRow';
import { columnsWidths } from '../Tasks';

export const Header = () => {
  return (
    <ListRow header>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        Task Name/Type
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.case}>
        Case#
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        Office Name/Location
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        Activity date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        Due Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        Status
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.progress}>
        Progress
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.priority}>
        Priority
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.members}>
        Members
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
