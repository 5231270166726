import { useContext, useState } from 'react';
import { Box, Collapse, List as MuiList, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { preventDefault } from '../../../../../../helpers/dom';
import { useModal } from '../../../../../../components/ModalsProvider';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { IconButton } from '../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../components/Tooltip';
import { BillingSettingsContext } from '../../BillingSettingsContext';
import { Category } from '../../Category';
import { CreateCategoryModal } from '../../CreateCategoryModal';
import { columnsWidths, minRowHeight } from '../MainContent';

const noDescriptionText = 'No description';

export const Row = ({ category, categories }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { openModal } = useModal();
  const [ isOpen, setIsOpen ] = useState(false);
  const {
    deleteCategories,
    activateExpenseCategory,
    deactivateExpenseCategory,
    fetchCategories
  } = useContext(BillingSettingsContext);

  const handleDeleteCategories = () => {
    deleteCategories(category);
  };

  const handleEditCategory = () => {
    openModal(CreateCategoryModal, {
      payload: {
        initialValues: {
          category,
          categories
        }
      },
      onModalResolved: () => {
        fetchCategories();
      }
    });
  };

  const showDescriptionModal = () => {
    setIsOpen(!isOpen);
  };

  const handleActiveExpenseCategory = () => {
    activateExpenseCategory(category);
  };

  const handleDeactivateExpenseCategory = () => {
    deactivateExpenseCategory(category);
  };

  return (
    <>
      <ListRow button={isMobile} minHeight={minRowHeight} onClick={isMobile ? showDescriptionModal : null}>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          <Box pl={(!!category?.parent_id && isMobile) ? 2 : !!category?.parent_id ? 5 : 0}>
            <Category category={category} />
          </Box>
        </ListRowCell>

        {!isMobile &&
          <ListRowCell grow flexBasis={columnsWidths.description}>
            <Typography noWrap title={category.description || noDescriptionText}>
              {category.description || noDescriptionText}
            </Typography>
          </ListRowCell>
        }

        <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.action}>
          <Tooltip
            title={category?.is_system
              ? 'You cannot edit the system category'
              : !category.is_active
                ? 'You cannot edit an inactive category'
                : 'Edit category'
            }
          >
            <div>
              <IconButton
                color="primary"
                variant="contained"
                disabled={!category.is_active || !!category?.is_system}
                onClick={handleEditCategory}
              >
                <EditIcon />
              </IconButton>
            </div>
          </Tooltip>

          <Tooltip title={category.is_active ? 'Deactivate category' : 'Activate category'}>
            <IconButton
              variant="contained"
              color={category.is_active ? 'success' : 'warning'}
              onClick={category.is_active ? handleDeactivateExpenseCategory : handleActiveExpenseCategory}
            >
              {category.is_active ? <LockOpenIcon /> : <LockIcon />}
            </IconButton>
          </Tooltip>

          <Tooltip
            title={category?.is_system
              ? 'You cannot delete the system category'
              : !category.is_active
                ? 'You cannot delete an inactive category'
                : 'Delete category'
            }
          >
            <div>
              <IconButton
                variant="contained"
                color="error"
                disabled={!category.is_active || !!category?.is_system}
                onClick={preventDefault(handleDeleteCategories)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        </ListRowCell>
      </ListRow>

      {isMobile &&
        <Collapse in={isOpen}>
          <Box py={0.5} px={2} borderBottom={1} borderColor="divider">
            <Typography title={category.description || noDescriptionText}>
              {category.description || noDescriptionText}
            </Typography>
          </Box>
        </Collapse>
      }

      {!!category?.children?.length && (
        <MuiList disablePadding>
          {category?.children?.map((category) => (
            <Row key={category.id} category={category} categories={categories} />
          ))}
        </MuiList>
      )}
    </>
  );
};
