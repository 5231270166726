export const styles = ({ spacing, palette, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  body: {
    flexGrow: 1,
    overflow: 'auto',
    padding: spacing(2, 3),
    backgroundColor: palette.grey[100],

    [breakpoints.down('md')]: {
      padding: spacing()
    }
  }
});
