import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Button } from '../../../../../../../components/Button';
import { ColorPreview } from '../../../../../../../components/ColorPreview';
import { IconButton } from '../../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../components/Loader';
import { AppointmentTypesContext } from '../../AppointmentTypesContext';
import { columnsWidths } from '../List';

export const Row = ({ item: appointmentType = {}, baseUrl, isLoaded, recalculateHeight }) => {
  const appointmentTypesContext = useContext(AppointmentTypesContext);
  const [ isBusy, setIsBusy ] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleActiveAppointmentType = () => {
    const data = { ...appointmentType, is_active: !appointmentType.is_active };

    setIsBusy(true);

    appointmentTypesContext.toggleActiveAppointmentType(data).then(() => {
      setIsBusy(false);
    });
  };

  const deleteAppointmentType = () => {
    setIsBusy(true);

    appointmentTypesContext.deleteAppointmentType(appointmentType).then(() => {
      setIsBusy(false);
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return (
    <Loader fullWidth p={isBusy ? 0 : 2} surface={isBusy} loading={!isLoaded || isBusy} render={
      () => (
        <ListRow disabled={!appointmentType.is_active}>
          <ListRowCell grow actions flexBasis={columnsWidths.code} alignItems="center">
            <Box width="20" display="flex" alignContent="center">
              <ColorPreview color={appointmentType.color} />
            </Box>

            <Typography noWrap>
              <span>
                {appointmentType.code}
              </span>
            </Typography>
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.description}>
            {appointmentType.description}
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.duration}>
            {moment.duration(appointmentType.time, 'seconds').format('h[h] m[min]')}
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.claimRequired}>
            {appointmentType.claim_required ? 'Yes' : 'No'}
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.ordering}>
            {appointmentType.ordering}
          </ListRowCell>

          <ListRowCell actions noDisable justifyContent="flex-end" flexBasis={columnsWidths.actions}>
            {isMobile ? (
              <>
                <IconButton
                  title={appointmentType.is_active ? 'Deactivate' : 'Activate'}
                  color={appointmentType.is_active ? 'warning' : 'success'}
                  onClick={toggleActiveAppointmentType}
                >
                  {appointmentType.is_active ? <LockIcon /> : <LockOpenIcon />}
                </IconButton>

                <IconButton
                  component={Link}
                  to={`${baseUrl}/appointment-types/${appointmentType.id}/edit`}
                  color="info"
                  size="small"
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  color="error"
                  size="small"
                  onClick={deleteAppointmentType}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color={appointmentType.is_active ? 'warning' : 'success'}
                  size="small"
                  onClick={toggleActiveAppointmentType}
                >
                  {appointmentType.is_active ? 'Inactive' : 'Active'}
                </Button>

                <Button
                  component={Link}
                  to={`${baseUrl}/appointment-types/${appointmentType.id}/edit`}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Edit
                </Button>

                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={deleteAppointmentType}
                >
                  Delete
                </Button>
              </>
            )}
          </ListRowCell>
        </ListRow>
      )}
    />
  );
};
