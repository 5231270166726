export const styles = ({ spacing, palette: { primary, grey } }) => ({
  notes: {
    paddingTop: spacing(),
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: spacing(),
    justifyContent: 'flex-start'
  },

  notesTime: {
    color: primary.main,
    display: 'inline',
    marginLeft: spacing(),

    '&:hover': {
      cursor: 'pointer'
    }
  },

  notesText: {
    color: grey[500]
  },

  notesDate: {
    color: grey[300]
  },

  note: {
    display: 'inline-block',
    marginLeft: spacing()
  },


  noteInline: {
    display: 'inline',
    marginLeft: spacing()
  },

  buttonInline: {
    color: 'rgba(0, 0, 0, 0.4)'
  },

  noteDescription: {
    flexGrow: 1
  }
});
