import { createReduxReducer } from '../../../../helpers/createReduxReducer';

const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

export const openModal = () => ({ type: OPEN_MODAL });
export const closeModal = () => ({ type: CLOSE_MODAL });

const initialState = {
  isOpen: false
};

export const reducer = createReduxReducer(initialState, {
  [OPEN_MODAL]: (state) => {
    return { ...state, isOpen: true };
  },

  [CLOSE_MODAL]: (state) => {
    return { ...state, isOpen: false };
  }
});
