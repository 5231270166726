import { truncate } from 'lodash';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import cn from 'classnames';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import * as groupsApi from '../../../../../../../api/cases/reminders/groups';
import { Badge } from '../../../../../../Badge';
import {
  CaseRemindersList,
  CaseRemindersProvider
} from '../../../index';
import { columnsMap } from '../../../CaseRemindersList';
import {
  CaseReminderCaseGroupsContext
} from '../../../../groups';
import { ColorPreview } from '../../../../../../ColorPreview';
import { ConfirmationModal } from '../../../../../../ConfirmationModal';
import { IconButton } from '../../../../../../IconButton';
import { useModal } from '../../../../../../ModalsProvider';
import { Tooltip } from '../../../../../../Tooltip';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { styles } from './styles';

const hiddenColumns = [
  columnsMap.group
];

const useStyles = makeStyles(styles);

export const Group = ({
  caseId,
  group,
  eventsAmount,
  expandedGroupID,
  onSelectedPanelChange
}) => {
  const classes = useStyles();
  const { filter, deleteReminderGroup } = useContext(CaseReminderCaseGroupsContext);
  const isExpanded = expandedGroupID === group.id;
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const shortGroupTitle = group?.name || group?.description
    ? `
      ${truncate(group?.name, { length: 48 })}
      (${truncate(group?.description || '-', { length: 32 })})
    `
    : '-';
  const groupTitle = group?.name || group?.description
    ? `${group?.name || '-'} (${group?.description || '-'})`
    : '-';

  const handleSelectedPanelChange = () => {
    onSelectedPanelChange(group?.id, !isExpanded);
  };

  const deleteGroup = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        groupsApi.deleteCaseReminderCaseGroups(caseId, {
          params: {
            group_ids: [ group.id ]
          }
        }).then(() => {
          deleteReminderGroup(group);
          enqueueSnackbar('Group successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Group not deleted', { variant: 'error' });
        });
      }
    });
  };

  return (
    <div className={cn(classes.root, isExpanded && classes.expanded)}>
      <div className={classes.header} onClick={handleSelectedPanelChange}>
        <Box display="flex" flexGrow={1}>
          <ColorPreview color={group?.color} />

          <Tooltip title={groupTitle}>
            <Box ml={1}>
              {shortGroupTitle}
            </Box>
          </Tooltip>

        </Box>

        <div>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Badge isStatic badgeContent={eventsAmount} color="info">
                <IconButton color="inherit">
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Badge>
            </Grid>

            <Grid item>
              <Tooltip title="Delete group">
                <IconButton
                  color="error"
                  edge="end"
                  onClick={stopPropagation(deleteGroup)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      </div>

      {isExpanded &&
        <div className={classes.body}>
          <CaseRemindersProvider caseId={caseId} filter={{ ...filter, group_id: group.id }}>
            <CaseRemindersList hiddenColumns={hiddenColumns} />
          </CaseRemindersProvider>
        </div>
      }
    </div>
  );
};
