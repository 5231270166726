import { useState, forwardRef, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { isObject, isEqual, debounce, isEmpty, isNil, isUndefined } from 'lodash';
import cn from 'classnames';
import { makeStyles, Box, CircularProgress, InputAdornment, Chip } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete, createFilterOptions } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useController, useFormContext } from 'react-hook-form';
import { stopPropagation } from '../../../helpers/dom';
import { IconButton } from '../../IconButton';
import { useFormikField } from '../useFormikField';
import { TextField } from '../TextField';
import { Popper } from './Popper';
import { ListBox } from './ListBox';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const filter = createFilterOptions();

export const Autocomplete = forwardRef(({
  disableDropdown = false,
  disableValueTransform = false,
  disableClearable = false,
  preventManualChangeNotification = false,
  disableSearch = false,
  disabled = false,
  isAsync: isAsyncProp = false,
  isCreatable = false,
  multiple,
  freeSolo = false,
  minWidth,
  value: valueProp,
  inputValue: inputValueProp,
  options: optionsProp,
  hiddenOptions = [],
  getOptionValue: getOptionValueProp = (option) => option?.value || option,
  getOptionFormattedValue,
  getOptionLabel: getOptionLabelProp = (option) => option?.label || option,
  getOptionSelected = (option, value) => (option?.value || option) === (value?.value || value),
  withoutFormik,
  required,
  helperText,
  openButton,
  error: errorProp,
  name,
  label,
  placeholder,
  margin,
  TextFieldProps = {},
  onChange = () => {},
  getInputProps = () => ({}),
  onNeedFetch = (callback) => {},
  onCreate = () => {},
  onInputChange = () => {},

  ...props
}, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { validateForm } = withoutFormik ? {} : useFormikContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField(name);
  const {
    isFormikField,
    fieldProps: [ field = {}, { touched } = {}, { setValue: setFormikValue, setTouched } = {} ] = [],
    error
  } = formikFieldProps;
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);
  const [ inputValue, setInputValue ] = useState(null);
  const [ options, setOptions ] = useState([]);
  const [ additionalData, setAdditionalData ] = useState({});
  const [ hasMore, setHasMore ] = useState(true);
  const [ value, setValue ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);
  const isAsync = isAsyncProp && !optionsProp;
  const loading = isAsync && open && !isFetched;
  // React Hook Form
  const formContext = useFormContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { fieldState } = (formContext && useController({
    name, control: formContext?.control
  })) || {};
  const errorMessage = fieldState?.error?.message;
  const getOptionValue = disableValueTransform ? (option) => option : getOptionValueProp;
  const valuePropOrValue = isUndefined(valueProp) ? (value || (multiple ? [] : null)) : valueProp;
  const inputValueOrInputValueProp = isUndefined(inputValueProp) ? inputValue : inputValueProp;
  const inputValueIsString = typeof(inputValueOrInputValueProp) === 'string';
  const clearButtonIsVisible = !disableClearable && (multiple ? !isEmpty(valuePropOrValue) : !isNil(valuePropOrValue));

  const getOptionLabel = (option) => {
    return (option?.isCreatableOption ? option.name : getOptionLabelProp(option)) || '';
  };

  const transformValueToInputValue = (value) => {
    return multiple || !value ? '' : getOptionLabel(value);
  };

  const valueAsInputValue = transformValueToInputValue(valuePropOrValue);

  const handleChange = (event, selectedOption, reason) => {
    if (isCreatable) {
      if (!multiple && selectedOption?.isCreatableOption) {
        onCreate(selectedOption?.inputValue).then((option) => {
          setOpen(false);
          handleChange(null, option);
        }).catch(() => null);

        return;
      }

      if (multiple) {
        const creatableOption = selectedOption?.find((optionItem) => optionItem.isCreatableOption);
        const filteredOptions = selectedOption?.filter((optionItem) => !optionItem.isCreatableOption);

        if (creatableOption) {
          onCreate(creatableOption?.inputValue).then((option) => {
            setOpen(false);
            handleChange(null, filteredOptions.concat(option));
          }).catch(() => null);

          return;
        }
      }
    }

    const option = (multiple && !selectedOption?.length)
      ? null
      : selectedOption;
    const formValue = multiple ? option?.map(getOptionValue) || [] : getOptionValue(option) ?? null;

    setValue(option);
    formContext?.setValue(name, formValue, { shouldValidate: true });
    onChange(option);

    if (reason !== 'input') {
      setInputValue(null);
    }

    if (isFormikField) {
      setFormikValue(formValue);
      setTouched(true);

      setTimeout(() => {
        validateForm();
      });
    }
  };

  const loadOptions = ({ search, loadedOptions = [], additionalData = {} } = {}) => {
    onNeedFetch({
      search,
      loadedOptions,
      additionalData
    }).then(({ hasMore, options, additionalData }) => {
      const optionsHasValue = value && options.some((option) => {
        return multiple
          ? value.some((value) => getOptionSelected(option, value))
          : getOptionSelected(option, value);
      });
      const newOptions = (!value || optionsHasValue) || !isCreatable ? options : options.concat(value);
      const newOptionsWithoutHidden = !hiddenOptions ? newOptions : newOptions?.filter((option) => {
        return !hiddenOptions?.some((hiddenOption) => {
          const getOptionValue = getOptionFormattedValue || getOptionValueProp;

          return getOptionValue(option) === getOptionValue(hiddenOption);
        });
      });

      setOptions(newOptionsWithoutHidden);
      setHasMore(hasMore);
      setAdditionalData(additionalData);
    }).finally(() => {
      setIsFetched(true);
    });
  };

  const handleSearch = useCallback(debounce(({ search, loadedOptions, additionalData }) => {
    loadOptions({ search, loadedOptions, additionalData });
  }, 600), [ onNeedFetch ]);

  const handleInputChange = (event, value) => {
    if (!event) {
      return;
    }

    onInputChange(event, value);
    setInputValue(value);

    if (isAsync && event.type === 'change') {
      handleSearch({ search: value });
    }

    if (freeSolo) {
      handleChange(event, value, 'input');
    }
  };

  const toggleOpen = () => {
    setOpen((open) => !open);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    handleChange(null, null);
    setInputValue('');
  };

  const transformOptionToValue = (option) => {
    if (!option) {
      return;
    }

    return isObject(option) ? getOptionValue(option) : option;
  };

  useEffect(() => {
    if (!isAsync) {
      return;
    }

    if (open) {
      setIsFetched(false);
      handleSearch({ search: inputValue });
    } else {
      setOptions([]);
      setAdditionalData({});
    }
  }, [ open, inputValue ]);

  useEffect(() => {
    if (!isFormikField && !isEqual(valueProp, value)) {
      setValue(valueProp);
    }
  }, [ isFormikField, valueProp ]);

  useEffect(() => {
    if (isFormikField) {
      const formikMultiValue = multiple && (field?.value || [])?.map(transformOptionToValue);
      const innerMultiValue = multiple && (value || [])?.map(transformOptionToValue);
      const formikValue = multiple ? formikMultiValue : transformOptionToValue(field.value);
      const innerValue = multiple ? innerMultiValue : transformOptionToValue(value);

      if (!isEqual(formikValue, innerValue)) {
        setValue(field.value);

        if (!preventManualChangeNotification && !isUndefined(innerValue)) {
          onChange(field.value);
        }

        if (!multiple) {
          setInputValue(getOptionLabel(field.value));
        }

        setFormikValue(formikValue);
      } else if (!multiple && isObject(field.value)) {
        setFormikValue(formikValue);
      }
    }
  }, [ isFormikField, field.value ]);

  return (
    <MuiAutocomplete
      openOnFocus
      clearOnBlur
      clearOnEscape
      disableClearable
      selectOnFocus={!disableSearch}
      disabled={disabled}
      inputValue={inputValueIsString ? inputValueOrInputValueProp : valueAsInputValue}
      loading={loading}
      forcePopupIcon={false}
      multiple={multiple}
      name={name}
      value={valuePropOrValue}
      open={open}
      options={optionsProp || options}
      filterOptions={(options, params) => {
        const filtered = disableSearch ? options : filter(options, params).filter((option) => {
          return multiple
            ? !value?.some((value) => getOptionSelected(option, value))
            : !getOptionSelected(option, value);
        });

        if (isCreatable) {
          filtered.unshift({
            isCreatableOption: true,
            inputValue: params.inputValue,
            name: params.inputValue ? `Add "${params.inputValue}"` : 'Add'
          });
        }

        return filtered;
      }}
      renderOption={(option) => (
        <>
          {!!option.isCreatableOption &&
            <Box display="flex" mr={1}>
              <AddIcon color="primary" />
            </Box>
          }

          {getOptionLabel(option)}
        </>
      )}
      onOpen={handleOpen}
      onClose={handleClose}
      onInputChange={handleInputChange}
      onChange={handleChange}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip {...getTagProps({ index })} label={getOptionLabel(option)} />
        ));
      }}
      PopperComponent={Popper}
      ListboxComponent={ListBox}
      renderInput={({ inputProps: inputPropsProp, ...params }) => {
        const { autoComplete, ...inputProps } = inputPropsProp;

        return (
          <TextField
            {...params}

            unbindForm
            withoutFormik
            name={name}
            minWidth={minWidth}
            inputProps={inputProps}
            required={required}
            label={label}
            placeholder={placeholder}
            margin={margin}
            error={!!(touched || fieldState?.invalid) && !!(error || errorMessage)}
            helperText={((touched || fieldState?.invalid) && (error || errorMessage)) || helperText}

            {...TextFieldProps}

            InputProps={{
              ...TextFieldProps?.InputProps,
              ...params.InputProps,
              ...getInputProps(valuePropOrValue),

              readOnly: disableSearch,
              className: cn(
                TextFieldProps?.InputProps?.className,
                params.InputProps.className,
                classes.input,
                classes.input_WithDropdownButton,
                getInputProps(valuePropOrValue)?.className,
                {
                  [classes.input_open]: open,
                  [classes.input_multiple]: multiple,
                  [classes.input_WithLoadingIndicator]: loading,
                  [classes.input_WithClearButton]: clearButtonIsVisible
                }
              ),

              endAdornment: (
                <InputAdornment position="end" className={classes.endAdornment}>
                  {TextFieldProps.InputProps?.endAdornment}
                  {getInputProps(valuePropOrValue)?.endAdornment}

                  {loading &&
                    <CircularProgress color="primary" size={20} />
                  }

                  {clearButtonIsVisible &&
                    <IconButton
                      disabled={disabled}
                      className={classes.clearIndicator}
                      onClick={stopPropagation(handleClear)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }

                  {openButton || (!disableDropdown && (
                    <IconButton
                      disabled={disabled}
                      className={cn(
                        'MuiAutocomplete-popupIndicator',
                        open && 'MuiAutocomplete-popupIndicatorOpen'
                      )}
                      onClick={toggleOpen}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  ))}
                </InputAdornment>
              )
            }}
          />
        );
      }}
      ListboxProps={{
        onScroll: (event) => {
          if (!isAsync || !hasMore) {
            return;
          }

          const listBoxNode = event.currentTarget;
          const scrollThreshold = 200;
          const scrollPosition = listBoxNode.scrollTop + listBoxNode.clientHeight;
          const isScrollEnd = (scrollPosition + scrollThreshold) >= listBoxNode.scrollHeight;

          if (isScrollEnd) {
            loadOptions({
              search: inputValue,
              loadedOptions: options,
              additionalData
            });
          }
        }
      }}

      {...props}

      ref={ref}
    />
  );
});
