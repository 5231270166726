import { Formik } from 'formik';
import { Box, Button, Dialog } from '@material-ui/core';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { CompaniesAutocomplete, OfficesLocationSelect } from '../../../../../components/FormField';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { UserLink } from '../../../../../components/UserLink';
import { Loader } from '../../../../../components/Loader';
import { UsersSelect } from '../../../../../components/users';
import { validationSchema } from './validationSchema';

const initialValues = {
  office_id: null,
  company_id: null
};

export const ReplacePatientModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { user, isMulti = false }
}) => {
  const handleSubmit = (values) => {
    handleModalResolve(values);
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          isMulti,
          ...initialValues,
          ...(isMulti ? { users_id: [] } : { user_id: null })
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader
              icon={<PersonAddOutlinedIcon />}
              onClose={handleModalReject}
            >
              <Box display="flex" alignItems="baseline">
                Replace
                &nbsp;
                {isMulti ? (
                  'patients'
                ) : (
                  <UserLink
                    noAvatar
                    user={user}
                    variant="h3"
                    color="primary"
                  />
                )}
                &nbsp;
                for
              </Box>
            </ModalHeader>

            <ModalBody>
              <CompaniesAutocomplete
                label="Company"
                name="company_id"
                margin="dense"
              />

              <OfficesLocationSelect
                label="Name Office"
                name="office_id"
                margin="dense"
                params={{
                  company_id: values?.company_id || null
                }}
              />

              <UsersSelect
                required
                multiple={isMulti}
                name={isMulti ? 'users_id' : 'user_id'}
                label={isMulti ? 'Users' : 'User'}
                margin="dense"
                params={{
                  company_id: values?.company_id || null,
                  office_id: values?.office_id || null
                }}
              />
            </ModalBody>

            <ModalFooter>
              <Box mr={1}>
                <Button onClick={handleModalReject} color="primary">Cancel</Button>
              </Box>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Replace
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
