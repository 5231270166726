import { useSelector } from 'react-redux';
import { Box, Paper } from '@material-ui/core';
import { LayoutContextProvider } from '../../../../components/LayoutContext';
import { TimeTracking } from '../../CasesPage/Cases/CaseItem/Widgets';

export const TimeTrackingPage = () => {
  const currentUser = useSelector(({ profile }) => profile.user);

  const initialFilter = {
    users: [ currentUser.id ]
  };

  return (
    <Paper component={Box} flexGrow={1} width="100%">
      <LayoutContextProvider>
        <TimeTracking isTabPage initialFilter={initialFilter} />
      </LayoutContextProvider>
    </Paper>
  );
};
