import { useContext, useRef } from 'react';
import { Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { TablePagination } from '../../../TablePagination';
import { Loader } from '../../../Loader';
import { Cards } from '../../../Cards';
import { MembersContext } from '../MembersContext';
import { Card } from './Card';

export const Grid = () => {
  const {
    workersList,
    isFetched,
    filter,
    resetMembers
  } = useContext(MembersContext);
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader loading={!isFetched} p={3} render={
        () => !workersList.length ? (
          <Typography align="center">No users found</Typography>
        ) : (
          <>
            <Cards size={300} list={workersList} component={Card} />
            <TablePagination pagination={filter} onChange={resetMembers} />
          </>
        )}
      />
    </CustomScrollbars>
  );
};
