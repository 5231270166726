import moment from 'moment';
import { autoAccidentStatesOptions } from '../../claims/SituationalNumberFormModal/SituationalNumberForm/data';
import { autoAccidentCountryOptions } from '../../../dataMaps';
import { getUserFullName } from '../../../helpers/users';
import {
  bmiTypes,
  bmiTypesNames,
  getCategoryBMI,
  heightMap,
  weightMap
} from '../../../helpers/measureDataMap';
import {
  transformContactsToOption,
  transformToOption
} from '../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider/selectFieldHelpers';
import { typeOfAccidentOptions } from '../accidentTypes';
import { statusMap } from './CreateMeasurementsModal/statusMap';
import { formTypesMap } from './index';

export const generateInitialValues = ({ type, initialValues }) => {
  const measurements = initialValues.measurements || initialValues?.medical || initialValues?.patient?.medical;
  const isAttorneyUnknown = initialValues?.attorney_is_unknown === undefined
    ? false
    : !initialValues?.attorney_first_name;

  return {
    ...initialValues,

    is_updatable_patient: true,
    auto_accident_state: (type === formTypesMap.injury || type === formTypesMap.ortho)
      ? autoAccidentStatesOptions.find(({ value }) =>
        (value === initialValues?.auto_accident_state)
      ) : null,
    auto_accident_country: (type === formTypesMap.injury || type === formTypesMap.ortho)
      ? autoAccidentCountryOptions.find(({ value }) =>
        (value === initialValues?.auto_accident_country)
      ) : null,
    form_type: type,
    is_range: !initialValues?.doi && !!initialValues?.doi_from && !!initialValues.doi_to,
    home: {
      street: null,
      designator: null,
      unit_number: null,
      city: null,
      state: null,
      zip: null,
      country: null,

      ...(initialValues?.home || initialValues?.patient?.home)
    },
    email: initialValues?.email || initialValues?.patient?.email || null,
    medical: initialValues?.medical || initialValues?.patient?.medical|| null,
    medical_id: initialValues?.medical?.id || initialValues?.patient?.medical?.id || null,
    first_name: initialValues?.patient?.first_name || initialValues?.first_name || null,
    last_name: initialValues?.patient?.last_name || initialValues?.last_name || null,
    mobile_phone: initialValues?.mobile_phone || initialValues?.patient?.mobile_phone_number || null,
    phone_number: initialValues?.phone || initialValues?.patient?.phone_number
      || initialValues?.phone_number || null,
    emergency_contact: initialValues?.emergency_contact?.map((item) => ({
      ...item,
      emergency_name: transformContactsToOption(item),
      relationship: transformToOption(item?.relationship)
    })) || [],
    treating_physician_id: initialValues?.treating_physician
      || initialValues?.medical?.treating_physician,
    primary_insurance_id: initialValues?.primary_insurance,
    date: moment().unix(),
    primary_insuranceId: initialValues?.primary_insurance?.insuranceId || null,
    ssn: initialValues?.ssn || initialValues?.patient?.ssn || null,
    birthday: initialValues?.birthday || initialValues?.patient?.birthday,
    measurements: {
      status: measurements?.status
        ? statusMap.find(({ value }) => value === measurements?.status)
        : null,
      height_unit: heightMap.find(({ value }) => (
        value === measurements?.height_unit
      ))?.value || heightMap[1].value,
      weight_unit: weightMap.find(({ value }) => (
        value === measurements?.weight_unit
      ))?.value || weightMap[1].value,
      weight: measurements?.weight || null,
      height: measurements?.height || null,
      bmi: measurements?.bmi || 0,
      temperature: measurements?.temperature || null,
      blood_type: measurements?.blood_type || null,
      heart_rate: measurements?.heart_rate || null,
      systolic_blood_pressure: measurements?.systolic_blood_pressure || null,
      diastolic_blood_pressure: measurements?.diastolic_blood_pressure || null,
      category: (measurements?.bmi > 0)
        ? bmiTypesNames[getCategoryBMI(measurements?.bmi)]
        : bmiTypesNames[bmiTypes.underweight]
    },
    office_id: null,
    doi: initialValues?.doi || null,
    doi_from: initialValues?.doi_from || null,
    doi_to: initialValues?.doi_to || null,
    emergency_phone: initialValues?.emergency_contact?.phone ||
      initialValues?.medical?.contacts?.contact[0]?.phone || null,
    attorney_id: initialValues?.attorney,
    accident_type: typeOfAccidentOptions.find(({ value }) => value === initialValues?.accident_type) || null,
    referred_id: initialValues?.referred
      || initialValues?.medical?.referring_physician,
    attorney_email: initialValues?.attorney_email || null,
    attorney_is_unknown: isAttorneyUnknown,
    attorney_first_name: initialValues?.attorney_first_name || null,
    attorney_last_name: initialValues?.attorney_last_name || null,
    attorney_phone: initialValues?.attorney_phone || null,
    insuranceInfo: {
      form_type: type || initialValues.form_type || initialValues.medical_form || null,
      name: initialValues?.primary_insurance_name || null,
      group_id_number: initialValues?.group_id_number || null,
      primary_insurance_id_number: initialValues?.primary_insurance_id_number || null,
      primary_insurance_name: initialValues?.primary_insurance_name || null,
      primary_insurance_holder_first_name: initialValues?.primary_insurance_holder_first_name || null,
      first_name: initialValues?.first_name || initialValues?.patient?.first_name || null,
      last_name: initialValues?.last_name || initialValues?.patient?.last_name || null,
      address: initialValues?.home || initialValues?.patient?.home || null,
      dob: initialValues.primary_insurance_holder_dob || initialValues.birthday ||
        initialValues?.patient?.birthday || null,
      primary_insurance_holder_last_name: initialValues?.primary_insurance_holder_last_name || null,
      primary_insurance_holder_dob: initialValues?.primary_insurance_holder_dob || null,
      relationship_to_patient: initialValues?.relationship_to_patient || null,
      attorney_last_name: initialValues?.attorney_last_name || null,
      attorney_first_name: initialValues?.attorney_first_name || null,
      attorney_email: initialValues?.attorney_email || null,
      attorney_phone: initialValues?.attorney_phone || null
    }
  };
};

export const generatePrivateInitialValues = (initialValues) => ({
  primary_insurance_holder: initialValues?.primary_insurance?.subscriber_info ?
    getUserFullName(initialValues?.primary_insurance?.subscriber_info) : null,
  primary_insurance_holder_last_name: initialValues?.primary_insurance_holder_last_name,
  primary_insurance_holder_first_name: initialValues?.primary_insurance_holder_first_name,
  groupID: initialValues?.primary_insurance?.groupId || null,
  primary_insuranceId: initialValues?.primary_insurance?.insuranceId || null,
  secondary_insuranceId: initialValues?.secondary_insurance?.insuranceId || null,
  relationship_to_patient: initialValues?.primary_insurance?.subscriber_info?.subscriber ||
    initialValues?.relationship_to_patient || null,
  relationship_dob: initialValues?.primary_insurance?.subscriber_info?.dob
    ? moment(initialValues?.primary_insurance?.subscriber_info?.dob).unix()
    : null,
  secondary_insurance_id: initialValues?.secondary_insurance,
  office_id: initialValues.office
});
