import { Grid } from '@material-ui/core';
import { SipNumbersSelect, TextField } from '../../../FormField';

export const Form = () => {
  return (
    <>
      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="first_name"
          label="First name"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="last_name"
          label="Last name"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="username"
          label="Username"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          required
          name="email"
          label="Profile email"
        />
      </Grid>

      <Grid item xs={12}>
        <SipNumbersSelect name="numbers" />
      </Grid>
    </>
  );
};
