import { AppointmentRow } from '../../../index';

export const Row = ({ item: appointment = {}, onSelect, ...props }) => {
  const selectAppointment = () => {
    onSelect(appointment);
  };

  return (
    <AppointmentRow appointment={appointment} onClick={selectAppointment} {...props} />
  );
};
