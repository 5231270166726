import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsMap, columnsWidths } from '../List';

export const Header = ({ hiddenColumns = [] }) => {
  return (
    <ListRow header>
      {!hiddenColumns.includes(columnsMap.patient) && (
        <ListRowCell flexBasis={columnsWidths.patient}>
          Patients
        </ListRowCell>
      )}

      <ListRowCell grow flexBasis={columnsWidths.name}>
        Name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        Provider
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        Office
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions} />
    </ListRow>
  );
};
