import { useState } from 'react';
import { Box, Grid, FormControlLabel } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AuthorizationsSelect } from '../../appointments';
import { Checkbox, TextField } from '../../FormField';
import { IconButton } from '../../IconButton';
import { SvgIcon } from '../../SvgIcon';
import { ContentCard } from '../../ContentCard';
import DoctorSvg from '../../icons/medical/doctor.svg';
import { Tooltip } from '../../Tooltip';
import { UsersSelect } from '../../users';

export const Providers = ({ claim }) => {
  const [ isCollapsed, setIsCollapsed ] = useState(false);
  const patientID = claim.patient_id?.id || claim.patient_id;

  const toggleCollapsed = () => {
    setIsCollapsed((state) => !state);
  };

  return (
    <ContentCard
      fullHeight
      disableScrollbars
      variant="outlined"
      color="info"
      isCollapsed={isCollapsed}
      title="Providers"
      leftActions={[
        <Box mr={1}>
          <SvgIcon color="info"><DoctorSvg /></SvgIcon>
        </Box>
      ]}
      rightActions={[
        <IconButton edge="end" color="info" onClick={toggleCollapsed}>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UsersSelect
            name="provider_rendering_id"
            label="Rendering"
            params={{ role: 'doctor' }}
          />
        </Grid>

        <Grid item xs={12}>
          <UsersSelect
            name="provider_supervising_id"
            label="Supervising"
            params={{ role: 'doctor' }}
          />
        </Grid>

        <Grid item xs={12}>
          <UsersSelect
            name="provider_ordering_id"
            label="Ordering"
            params={{ role: 'doctor' }}
          />
        </Grid>

        <Grid item xs={12}>
          <UsersSelect
            name="provider_primary_care_id"
            label="Primary Care"
            params={{ role: 'doctor' }}
          />
        </Grid>

        <Grid item xs={12}>
          <UsersSelect
            name="provider_referring_id"
            label="Referring"
            params={{ role: 'doctor' }}
          />
        </Grid>

        <Grid item md={6}>
          <Tooltip
            isExistTooltip={!patientID || !claim.appointment}
            title={!patientID ? 'Select patient firstly' : !claim.appointment ? 'Select appointment firstly' : null}
          >
            <div>
              <AuthorizationsSelect
                patientID={patientID}
                appointmentID={claim.appointment?.id}
                disabled={!patientID || !claim.appointment}
                name="authorization_id"
                label="Authorization #"
                params={{
                  users: patientID && [ patientID ]
                }}
              />
            </div>
          </Tooltip>
        </Grid>

        <Grid item md={6}>
          <FormControlLabel
            control={<Checkbox name="is_auth_required" />}
            label="No Auth Required"
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            name="referral_number"
            label="Referral No."
            placeholder="Enter referral number..."
          />
        </Grid>

        <Grid item md={6}>
          <FormControlLabel
            control={<Checkbox name="is_use_clia" />}
            label="Use CLIA"
          />
        </Grid>
      </Grid>
    </ContentCard>
  );
};
