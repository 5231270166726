import { dispositionOptions } from '../../../../../../../components/call-reports/dispositionOptions';
import {
  TextField,
  Autocomplete,
  KeyboardDatePicker
} from '../../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../../components/users';

export const getFilterFields = () => {
  return [
    <UsersSelect
      disableValueTransform
      name="user_id"
      label="User"
    />,

    <TextField
      name="phone"
      label="Number"
      placeholder="Search by phone..."
    />,

    <Autocomplete
      disableValueTransform
      name="disposition"
      label="Disposition"
      options={dispositionOptions}
    />,

    <KeyboardDatePicker
      clearable
      outputFormat="YYYY-MM-DD"
      name="date_from"
      label="From"
    />,

    <KeyboardDatePicker
      clearable
      outputFormat="YYYY-MM-DD"
      name="date_to"
      label="To"
    />
  ];
};

export const getFiltersValues = (filters) => {
  return {
    ...filters,
    user_id: filters?.user_id?.id || filters?.user_id,
    disposition: filters?.disposition?.value || filters?.disposition
  };
};
