import { useContext, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '../../../components/Button';
import { useModal } from '../../../components/ModalsProvider';
import { PageHeader, PageHeaderTitle, PageHeaderActions } from '../../../components/Page';
import {
  CreateServiceModal,
  LoginServicesContext
} from '../../../components/login-queues/services';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';

export const Header = ({ filterOpenButton }) => {
  const rootRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { openModal } = useModal();
  const { addLoginService } = useContext(LoginServicesContext);

  const addService = () => {
    openModal(CreateServiceModal, {
      onModalResolved: (service) => {
        addLoginService(service);
      }
    });
  };

  return (
    <PageHeader ref={rootRef}>
      <PageHeaderTitle>
        Services
      </PageHeaderTitle>

      <PageHeaderActions>
        {hasRole(rolesMap.admin, rolesMap.supervisor) &&
          <Button
            size={isMobile ? 'small' : 'medium'}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addService}
          >
            Add service
          </Button>
        }

        {isTablet && filterOpenButton}
      </PageHeaderActions>
    </PageHeader>
  );
};
