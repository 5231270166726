import { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../helpers/dom';
import { hasRole } from '../../../../../../utils/hasRole';
import { UserPreviewModal } from '../../../../../../components/users';
import { CardItem, CardHeader } from '../../../../../../components/Cards';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { Date } from '../../../../../../components/Date';
import { TestPreviewModal } from '../../TestPreviewModal';
import { TestsContext } from '../../TestsProvider';
import { TestsMenu } from '../../TestsMenu';
import { Body } from './Body';

export const Card = ({ item: test }) => {
  const { openModal } = useModal();
  const { selectedIDs, toggleItemSelection, updateTestReport, fetchTestReports } = useContext(TestsContext);
  const isSelected = selectedIDs.indexOf(test.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(test.id);
  };

  const openPreview = () => {
    openModal(TestPreviewModal, {
      payload: {
        testId: test.id,
        onTestUpdate: updateTestReport,
        onListUpdate: fetchTestReports
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <CardItem selected={isSelected} py={1} px={2} onClick={stopPropagation(openPreview)}>
      <CardHeader
        isSelected={isSelected}
        disableCheckBox={hasRole(rolesMap.client, rolesMap.patient)}
        disableCardMenu={hasRole(rolesMap.client, rolesMap.patient)}
        onItemSelect={handleItemSelection}
        menuComponent={TestsMenu}
        menuComponentProps={{ test }}
      >
        <Date
          color="textSecondary"
          date={test.date_resulted}
          format="L, LT"
          iconFontSize="small"
        />
      </CardHeader>

      <Body test={test} />

      <Box pt={2} pb={0.5}>
        <UserLink
          size="lg"
          variant="h5"
          user={test?.ordered}
          onClick={stopPropagation(openUserPreview(test?.ordered?.id))}
        >
          <Box mt={-0.5}>
            <Typography color="textSecondary" variant="caption">Provider/Ordered by</Typography>
          </Box>
        </UserLink>
      </Box>
    </CardItem>
  );
};
