import { useContext, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TagsContext } from '../../../app/Dashboard/files-common';
import { Loader } from '../../Loader';
import { Folder } from './Folder';

const useStyles = makeStyles(({ spacing }) => ({
  notFound: {
    padding: spacing()
  }
}));

export const Folders = ({ collapseButtonUid, folders, selected, setExpanded, onFolderToggleSelection }) => {
  const classes = useStyles();
  const { isFetched, tags, resetTags } = useContext(TagsContext);

  useEffect(() => {
    if (!folders) {
      resetTags();
    }
  }, []);

  return !isFetched ? <Loader size={24} /> : tags?.length ? tags?.map((folder) => (
    <Folder
      key={folder.id}
      collapseButtonUid={collapseButtonUid}
      folder={folder}
      selected={selected}
      setExpanded={setExpanded}
      onFolderToggleSelection={onFolderToggleSelection}
    />
  )) : <Typography variant="body2" className={classes.notFound}>No folders found</Typography>;
};
