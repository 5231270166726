import { ReceivedFaxes } from './ReceivedFaxes';
import { SentFaxes } from './SentFaxes';

export const routes = [
  {
    exact: true,
    path: '/faxes/income',
    label: 'Received Faxes',
    component: ({ children }) => <ReceivedFaxes>{children}</ReceivedFaxes>
  },
  {
    path: '/faxes/outcome',
    label: 'Sent Faxes',
    component: ({ children }) => <SentFaxes>{children}</SentFaxes>
  }
];
