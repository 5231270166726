import { useContext } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { Comments, CommentsContextProvider } from '../../../../components/Comments';
import { UserContext } from '../../Members/User/UserProvider';

const styles = ({ spacing, breakpoints }) => ({
  root: {
    padding: spacing(2, 3, 10, 3),
    height: '100%',

    [breakpoints.down('md')]: {
      padding: spacing(1, 0, 10, 0)
    }
  }
});

const useStyles = makeStyles(styles);

export const UserComments = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  const initialStateOfComments = {
    filter: {
      parent_type: 'user_profile',
      parent_id: user.profile_id
    }
  };

  return (
    <CommentsContextProvider initialState={initialStateOfComments}>
      <Paper elevation={2} className={classes.root}>
        <Comments
          parentType="user_profile"
          parentID={user.profile_id}
        />
      </Paper>
    </CommentsContextProvider>
  );
};
