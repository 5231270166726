export const testsInitialValues = {
  patient_id: null,
  ordered_id: null,
  appointment_id: null,
  test_id: null,
  value: null,
  items: [],
  date_collected: null,
  date_resulted: null
};
