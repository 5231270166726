import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { makeStyles, Box, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { transformToOption } from '../../../../../helpers/fetchDataForAsyncSelect';
import * as billingApi from '../../../../../api/billing';
import { Page, PageBody, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../components/Page';
import {
  invoicesTypesOptions,
  billingParticipantsTypesOptions
} from '../../../../../components/billing';
import { Loader } from '../../../../../components/Loader';
import { FormContent } from '../CreateInvoice/FormContent';
import { transformTermsToOption } from '../helpers';
import { InvoicesContext } from '../InvoicesContext';
import { validationSchema } from '../validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EditInvoice = () => {
  const classes = useStyles();
  const params = useParams();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const invoiceContext = useContext(InvoicesContext);
  const [ initialValues, setInitialValues ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const [ expenses, setExpenses ] = useState([]);
  const [ totalTime, setTotalTime ] = useState(0);

  const updateInvoice = (invoiceData, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    invoiceData.expenses = expenses;

    invoiceContext.updateInvoice({ invoiceData, setSubmitting, setErrors });
  };

  useEffect(() => {
    setIsFetched(false);

    billingApi.fetchInvoice(params.id).then((data) => {
      setInitialValues(data);
      setExpenses(data.expenses);
      setIsFetched(true);
    });
  }, [ params.id ]);

  return (
    <Box flexGrow={1} px={isTablet ? 0 : 2} py={isTablet ? 0 : 1}>
      <Loader loading={!isFetched} p={2} render={
        () => (
          <Formik
            initialValues={{
              ...initialValues,

              model_type: invoicesTypesOptions.find(({ value }) => value === initialValues.model_type),
              model_id: initialValues.model || null,
              bill_from: {
                type: billingParticipantsTypesOptions.find(({ value }) => {
                  return value === initialValues?.bill_from?.participant_type;
                }),
                id: initialValues?.bill_from?.participant
              },
              bill_to: {
                type: billingParticipantsTypesOptions.find(({ value }) => {
                  return value === initialValues?.bill_to?.participant_type;
                }),
                id: initialValues?.bill_to?.participant
              },
              incomes: initialValues.incomes || [],
              status_id: transformToOption(initialValues.status),
              terms: transformTermsToOption(initialValues.terms)
            }}
            validationSchema={validationSchema}
            onSubmit={updateInvoice}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form noValidate className={classes.form} onSubmit={handleSubmit}>
                <Page>
                  <PageHeader>
                    <PageHeaderTitle>
                      Edit Invoice {initialValues?.number}
                    </PageHeaderTitle>

                    <PageHeaderActions>
                      <Button
                        component={Link}
                        to="/billing/invoices"
                        color="primary"
                        className={classes.actions__backButton}
                      >
                        Cancel
                      </Button>

                      <Loader surface loading={isSubmitting} render={
                        () => (
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Save
                          </Button>
                        )}
                      />
                    </PageHeaderActions>
                  </PageHeader>

                  <PageBody>
                    <FormContent
                      isEditable
                      totalTime={totalTime}
                      expenses={expenses}
                      onTotalTimeUpdate={setTotalTime}
                      onExpensesUpdate={setExpenses}
                    />
                  </PageBody>
                </Page>
              </form>
            )}
          </Formik>
        )}
      />
    </Box>
  );
};
