import { Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell flexBasis={columnsWidths.name}>
        <Typography noWrap variant="h5">
          Case name /
          Case number
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.createdDate}>
        <Typography noWrap variant="h5">
          Created From /
          Created To
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        <Typography noWrap variant="h5">
          Case status
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.roles}>
        <Typography noWrap variant="h5">
          User roles
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.members}>
        <Typography noWrap variant="h5">
          Case members
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.types}>
        <Typography noWrap variant="h5">
          Case types
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.orderBy}>
        <Typography noWrap variant="h5">
          Order By
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
