import Color from 'color';

export const styles = ({ palette }) => ({
  root: {
    position: 'relative'
  },
  actions: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
    background: Color(palette.background.default).fade(0.35).string()
  },
  canvasWrapper: {
    backgroundColor: 'rgba(220, 220, 220, 0.6)',
    backgroundImage: 'linear-gradient(#bbb, transparent 1px), linear-gradient(90deg, #bbb, transparent 1px)',
    backgroundSize: '20px 20px',
    backgroundPosition: 'center center'
  }
});
