import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarEdit } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as testsApi from '../../../../../api/profile';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { transformTestToOption } from '../../../../../components/FormField';
import { TestsBodySingle, ApptInfo, validationSchema } from '../CreateTestModal';

export const EditTestModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { id }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ initialValues, setInitialValues ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const updateTest = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const items = values?.items?.filter((child) => !!child?.is_checked);

    if (values?.items?.length && !items.length) {
      enqueueSnackbar('Components are required', { variant: 'warning' });
      setSubmitting(false);
    } else {
      return testsApi.updateTestReport({ id, ...values, items }).then((data) => {
        enqueueSnackbar('Successfully updated', { variant: 'success' });
        handleModalResolve(data);
      }).catch(({ errors }) => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });

        if (errors) {
          setErrors(errors);
        }
      });
    }
  };

  useEffect(() => {
    setIsFetched(false);

    testsApi.fetchTestReport(id).then((data) => {
      setInitialValues(data);
      setIsFetched(true);
    });
  }, []);

  return (
    <Dialog fullScreen={isTablet} PaperProps={{ style: { maxWidth: 1100 } }} {...DialogProps}>
      <Loader p={3} loading={!isFetched} render={
        () => (
          <Formik
            enableReinitialize
            initialValues={{
              ...initialValues,

              appointment_id: {
                id: initialValues?.appointment_id,

                ...initialValues?.appointment
              },
              office_id: initialValues?.appointment?.office,
              ordered_id: initialValues?.ordered,
              patient_id: initialValues?.patient,
              test_id: transformTestToOption(initialValues?.test),
              standard_range: initialValues?.test?.standard_range,
              test_description: initialValues?.test?.description
            }}
            validationSchema={validationSchema}
            onSubmit={updateTest}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer component="form" onSubmit={handleSubmit}>
                <ModalHeader
                  icon={<SvgIcon><Icon path={mdiCalendarEdit} /></SvgIcon>}
                  onClose={handleModalReject}
                >
                  Edit Test Report
                </ModalHeader>

                <ModalBody>
                  <ApptInfo />
                  <TestsBodySingle />
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
