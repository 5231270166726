import { createContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../api';
import * as vaccinationsApi from '../../../../../api/profile';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { usePrevious } from '../../../../../helpers/hooks';
import { useModal } from '../../../../../components/ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const ImmunizationContext = createContext();

export const ImmunizationProvider = ({ children, initialState: initialStateProp = {} }) => {
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { immunizations, selectedIDs, pagination, filter } = state;
  const { enqueueSnackbar } = useSnackbar();
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchImmunizationReports = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.IMMUNIZATIONS_FETCH_REQUEST });

    vaccinationsApi.fetchImmunizationReports({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.IMMUNIZATIONS_FETCH_SUCCESS, payload: data });
    });
  };

  const createImmunizationReport = (data) => {
    dispatch({ type: types.ADD_IMMUNIZATION, payload: data });
  };

  const updateImmunizationReport = (data) => {
    dispatch({ type: types.UPDATE_IMMUNIZATION_IN_LIST, payload: data });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetImmunizationReports = (newFilter = {}) => {
    dispatch({ type: types.RESET_IMMUNIZATIONS, payload: newFilter });

    fetchImmunizationReports({ page: 1, ...newFilter });
  };

  const allItemsIsSelected = () => {
    return immunizations.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_IMMUNIZATION_SELECTION, payload: id });
  };

  const toggleAllItemsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_IMMUNIZATIONS_SELECTION });
  };

  const deleteImmunizationReport = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        vaccinationsApi.deleteImmunizationReport(id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchImmunizationReports({ page: filter.page });
          } else if (immunizations.length - 1 === 0 && filter.page !== 1) {
            fetchImmunizationReports({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_IMMUNIZATION_SUCCESS, payload: id });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Item is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const massDeleteImmunizationReports = (IDs) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        vaccinationsApi.massDeleteImmunizationReports(IDs).then(() => {
          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            fetchImmunizationReports({ page: filter.page });
          } else if (immunizations.length - selectedIDs.length === 0 && filter.page !== 1) {
            fetchImmunizationReports({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.MASS_DELETE_IMMUNIZATIONS, payload: IDs });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Items is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const providerValue = {
    ...state,
    immunizations,
    selectedIDs,
    pagination,
    filter,
    meta: {
      ...pagination,
      ...filter
    },

    // functions
    applyFilter,
    fetchImmunizationReports,
    resetImmunizationReports,
    updateImmunizationReport,
    createImmunizationReport,
    allItemsIsSelected,
    toggleItemSelection,
    toggleAllItemsSelection,
    deleteImmunizationReport,
    massDeleteImmunizationReports
  };

  useEffect(() => {
    const newFilter = { ...filter, ...initialStateProp.filter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, initialStateProp.filter ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetImmunizationReports();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ImmunizationContext.Provider value={providerValue}>
      {children}
    </ImmunizationContext.Provider>
  );
};
