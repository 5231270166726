import { ListRow, ListRowCell } from '../../../../../ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell grow noWrap flexBasis={columnsWidths.company}>
        Company
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.user}>
        User
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateFrom}>
        Date From
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateTo}>
        Date To
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.orderBy}>
        Order by
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
