import { useState, useEffect, useContext } from 'react';
import Papa from 'papaparse';
import {
  makeStyles,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import { Loader } from '../../../../../../../components/Loader';
import { generateUID } from '../../../../../../../helpers/generateUID';
import { FilePreviewContext } from '../../../FilePreviewContext';
import { PreviewContent } from '../PreviewContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CSV = () => {
  const classes = useStyles();
  const [ { isParsed, headerCells, bodyRows }, setState ] = useState({
    isParsed: false,
    headerCells: [],
    bodyRows: []
  });
  const { selectedFile } = useContext(FilePreviewContext);

  useEffect(() => {
    setState((state) => ({ ...state, isParsed: false }));

    Papa.parse(selectedFile?.url, {
      download: true,
      dynamicTyping: true,
      header: true,
      skipEmptyLines: true,

      complete: ({ meta: { fields }, data }) => {
        setState((state) => ({
          ...state,

          isParsed: true,
          headerCells: fields,
          bodyRows: data.map((row) => {
            row.__id = generateUID();

            return row;
          })
        }));
      }
    });
  }, [ selectedFile?.url ]);

  return (
    <PreviewContent>
      <Paper className={classes.root}>
        <Loader loading={!isParsed} render={
          () => (
            <Table>
              <TableHead>
                <TableRow>
                  {headerCells.map((item) => (
                    <TableCell key={item}>
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {bodyRows.map((row) => (
                  <TableRow key={row.__id}>
                    {headerCells.map((item) => (
                      <TableCell key={row[item]}>
                        {row[item]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </Paper>
    </PreviewContent>
  );
};
