export const filterFieldsMap = {
  doiFrom: 'doi_from',
  doiTo: 'doi_to',
  dorFrom: 'dor_from',
  dorTo: 'dor_to',
  providerId: 'provider_id',
  officeId: 'office_id'
};

export const filterFieldsLabels = {
  [filterFieldsMap.doiFrom]: 'DOI From',
  [filterFieldsMap.doiTo]: 'DOI To',
  [filterFieldsMap.dorFrom]: 'DOR From',
  [filterFieldsMap.dorTo]: 'DOR To',
  [filterFieldsMap.providerId]: 'Provider',
  [filterFieldsMap.officeId]: 'Office'
};
