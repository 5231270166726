import { useContext } from 'react';
import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../ListRow';
import { PaymentsContext } from '../../../PaymentsContext';
import { columnsWidths, widthBreakpointMedium, widthBreakpointLarge } from '../List';

export const TableHeader = ({ width }) => {
  const {
    selectedPaymentsIDs,
    allPaymentsIsSelected,
    toggleAllPaymentsSelection
  } = useContext(PaymentsContext);

  return (
    <Paper elevation={0}>
      <ListRow header px={2}>
        <ListRowCheckbox
          onClick={toggleAllPaymentsSelection}
          checked={allPaymentsIsSelected()}
          indeterminate={!!selectedPaymentsIDs.length && !allPaymentsIsSelected()}
        />

        <ListRowCell noWrap grow flexBasis={columnsWidths.number}>
          Payment #
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.payer}>
          Payer
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.number}>
          Inv #
        </ListRowCell>

        {width > widthBreakpointMedium &&
          <ListRowCell flexBasis={columnsWidths.description}>
            Description
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.paid_date}>
          Paid Date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.due_on}>
          Due On
        </ListRowCell>

        {width > widthBreakpointLarge &&
          <ListRowCell flexBasis={columnsWidths.method}>
            Payment Method
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.total}>
          Total
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.amount}>
          Amount
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.balance}>
          Balance
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
