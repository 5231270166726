import { Create } from './Create';
import { Edit } from './Edit';

export const routes = [
  {
    pathName: '/appointment-books/create',
    component: Create
  },
  {
    pathName: '/appointment-books/:id/edit',
    component: Edit
  }
];
