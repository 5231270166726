import { forwardRef } from 'react';
import T from 'prop-types';
import cn from 'classnames';
import { makeStyles, Fab as MuiFab } from '@material-ui/core';
import capitalize from '@material-ui/core/utils/capitalize';
import { styles } from '@material-ui/core/Button/Button';
import { getFilledButtonColorStyle } from '../Button';

const useButtonStyles = makeStyles(styles);
const useStyles = makeStyles(({ palette }) => ({
  root: ({ color, fullWidth }) => ({
    width: fullWidth ? '100%' : 'auto',
    ...getFilledButtonColorStyle({ palette, color })
  })
}));

const propTypes = {
  startIcon: T.node,
  endIcon: T.node,
  fullWidth: T.bool
};

export const Fab = forwardRef(({
  children,
  className,
  fullWidth,
  color,
  size = 'medium',
  startIcon: startIconProp,
  endIcon: endIconProp,
  ...props
}, ref) => {
  const buttonClasses = useButtonStyles();
  const classes = useStyles({ fullWidth, color });

  const startIcon = startIconProp && (
    <span className={cn(buttonClasses.startIcon, buttonClasses[`iconSize${capitalize(size)}`])}>
      {startIconProp}
    </span>
  );

  const endIcon = endIconProp && (
    <span className={cn(buttonClasses.endIcon, buttonClasses[`iconSize${capitalize(size)}`])}>
      {endIconProp}
    </span>
  );

  return (
    <MuiFab ref={ref} size={size} className={cn(classes.root, className)} {...props}>
      {startIcon}
      {children}
      {endIcon}
    </MuiFab>
  );
});

Fab.propTypes = propTypes;
