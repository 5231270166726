import { CommentsContextProvider } from '../../../../../../../components/Comments';
import { CommentsBody } from './CommentsBody';

export const Comments = ({
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  isMinimizableType,
  onCaseItemUpdate = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  setCasesLastGlobalAction,
  ...props
}) => {
  const initialState = {
    filter: {
      parent_type: 'case',
      parent_id: caseItem.id
    }
  };

  return (
    <CommentsContextProvider initialState={initialState}>
      <CommentsBody
        caseItem={caseItem}
        props={props}
        widgetProps={{
          isDisabledOpen,
          isMinimizableType,
          onMinimize,
          onMaximized,
          onClose,
          onOpen,
          onPutItem,
          page,
          type: props?.item?.i
        }}
        {...props}
      />
    </CommentsContextProvider>
  );
};
