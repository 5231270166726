import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { menuOptionsTypesMap } from '../../../../files-common/menuOptionsTypes';

export const getFileMenuOptions = (deleteFile, isAbleToDeleteFile) => (file) => [
  {
    isHidden: !isAbleToDeleteFile,
    type: menuOptionsTypesMap.menuItem,
    Icon: DeleteIcon,
    label: 'Delete file',
    onClick: () => {
      deleteFile(file.id)();
    }
  }
];
