import { useFormikContext } from 'formik';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import NotesIcon from '@material-ui/icons/Notes';
import { BillingSection } from '../../../app/Dashboard/BillingPage/ClaimsPage/ClaimPreview/MainContent/BillingSection';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { CasesSelect } from '../../cases/CasesSelect';
import {
  Radio,
  Checkbox,
  TextField,
  RadioGroup,
  Autocomplete,
  OfficesLocationSelect,
  LocalUseCodesSelect,
  InsurancesSelect,
  SpecialProgramCodesSelect,
  KeyboardDateTimePicker
} from '../../FormField';
import { UsersSelect } from '../../users';
import { PatientsSelect } from '../../users/PatientsSelect';
import { creationClaimsStatuses, allClaimsStatuses } from '../claimsStatuses';
import { Providers } from './Providers';
import { Diagnosis } from './Diagnosis';
import { AppointmentSection } from './AppointmentSection';
import { SituationalNumber } from './SituationalNumber';
import { transformSituationalNumberForForm } from '../SituationalNumberFormModal';

export const payerTypesMap = {
  patient: 'patient',
  insurance: 'insurance'
};

export const ClaimForm = ({
  isFixedCase = false,
  isFixedPatient = false
}) => {
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handlePatientChange = (prevValues) => (user) => {
    if (values.patient && prevValues.patient_id !== user?.id) {
      setFieldValue('office_id', null);
      setFieldValue('insurance_id', null);
      setFieldValue('appointment', null);
      setFieldValue('appointment_id', null);
    }

    setFieldValue('patient', user);
  };

  const handleOfficeChange = (office) => {
    setFieldValue('office', office);
  };

  const handleInsuranceChange = (insurance) => {
    setFieldValue('insurance', insurance);
  };

  const handleSituationalNumberChange = (situationalNumber) => {
    setFieldValue('situational_number', transformSituationalNumberForForm(situationalNumber));
  };

  return (
    <Grid container spacing={isMobile ? 2 : 4}>
      <Grid item xs={12}>
        <Alert severity="info">
          <Typography variant="h4" align="center" color="inherit">
            Claim Should Contain ICD 10 Only
          </Typography>
        </Alert>
      </Grid>

      {!isFixedPatient &&
        <Grid item md={4} xs={12}>
          <PatientsSelect
            required
            name="patient_id"
            label="Select patient"
            onChange={handlePatientChange(values)}
          />
        </Grid>
      }

      <Grid item md={isFixedPatient ? 8 : 4} xs={12}>
        <FormControl required fullWidth component="fieldset">
          <FormLabel component="legend">Payer</FormLabel>

          <RadioGroup row name="payer_type">
            <FormControlLabel
              value={payerTypesMap.patient}
              label="Patient"
              control={<Radio name="payer_type" color="primary" />}
            />

            <FormControlLabel
              value={payerTypesMap.insurance}
              label="Insurance"
              control={<Radio name="payer_type" color="primary" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item sm={4} xs={12}>
        <InsurancesSelect
          isCreatable
          required={values.payer_type === payerTypesMap.insurance}
          disabled={!values.patient_id}
          name="insurance_id"
          margin="dense"
          user={values.patient}
          params={{
            case_id: values?.case_id?.id || values?.case_id,
            medical_id: values.patient?.medical?.id || values.patient?.medical_id
          }}
          onChange={handleInsuranceChange}
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <UsersSelect
          required={!!values.insurance_id}
          name="adjuster_id"
          label="Adjuster"
          margin="dense"
          noOptionsText="Selected insurance does not have an adjusters"
          params={{
            company_id: values.insurance?.insurance_company?.id,
            role: rolesMap.client
          }}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <OfficesLocationSelect
          required
          name="office_id"
          label="Visit Office / Appointment Office"
          disabled={!values.patient_id}
          onChange={handleOfficeChange}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <Autocomplete
          required
          name="status"
          label="Claim Status"
          options={values.id ? allClaimsStatuses : creationClaimsStatuses}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <SpecialProgramCodesSelect isClearable />
      </Grid>

      <Grid item md={4} xs={12}>
        <TextField
          name="local_use_19"
          label="Local Use 19"
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <LocalUseCodesSelect
          isClearable
          name="local_use_id"
          label="Local Use (10d)"
        />
      </Grid>

      {!isFixedCase &&
        <Grid item md={4} xs={12}>
          <CasesSelect
            name="case_id"
            label="Case"
            placeholder="Select case..."
            noOptionsText="Selected patient is not assigned to any case"
            params={{
              users: values?.patient_id ?
                [ values?.patient_id?.id || values?.patient_id ]
                : null
            }}
          />
        </Grid>
      }

      <Grid item md={4} xs={12}>
        <FormControlLabel
          control={<Checkbox name="is_paper_claim" />}
          label="Paper claim"
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <Box mt={2} mb={2} color="info.main">
          <Typography variant="h3" color="inherit">
            Hospitalization Admit Date
          </Typography>
        </Box>

        <Grid container spacing={isMobile ? 2 : 4}>
          <Grid item lg={6} xs={12}>
            <KeyboardDateTimePicker
              name="hospitalization_from"
              label="From"
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <KeyboardDateTimePicker
              name="hospitalization_to"
              label="To"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} xs={12}>
        <Box display="flex" alignItems="center" mt={2} mb={0.5} color="text.secondary">
          <Box display="flex" mr={1}>
            <NotesIcon fontSize="small" color="inherit" />
          </Box>

          <Typography color="inherit">Claim Notes</Typography>
        </Box>

        <TextField
          multiline
          disableUnderline
          rows={2}
          rowsMax={8}
          variant="filled"
          name="note"
        />
      </Grid>

      <Grid item xs={12}>
        <AppointmentSection />
      </Grid>

      <Grid item lg={6} xs={12}>
        <Providers claim={values} />
      </Grid>

      <Grid item lg={6} xs={12}>
        <Diagnosis />
      </Grid>

      {!!values.id &&
        <Grid item xs={12}>
          <BillingSection claim={values} />
        </Grid>
      }

      <Grid item xs={12}>
        <SituationalNumber claim={values} onChange={handleSituationalNumberChange} />
      </Grid>
    </Grid>
  );
};
