import cn from 'classnames';
import { makeStyles, Chip, Box } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { ColorPreview } from '../../../ColorPreview';
import { styles } from '../Select/components/MultiValue';

const useStyles = makeStyles(styles);

export const MultiValue = ({
  isFocused,
  removeProps,
  children: eventType
}) => {
  const classes = useStyles();

  return (
    <Chip
      tabIndex={-1}
      icon={
        <Box display="flex">
          <ColorPreview color={eventType.color} />
        </Box>
      }
      label={eventType.name}
      className={cn(classes.chip, {
        [classes.chipFocused]: isFocused
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
};
