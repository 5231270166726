import { Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../ListRow';
import {
  columnsLargeWidths,
  columnsMap,
  columnsMobileWidths
} from './Procedures';

export const TableHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsMobileWidths : columnsLargeWidths;

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths[columnsMap.code]}>
          CPT code
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.laterality}>
          Laterality
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.visits}>
          Visits
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.min}>
          Minutes
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
