import { useEffect, useState } from 'react';
import { Box, Dialog, Tabs, Tab } from '@material-ui/core';
import * as emailsApi from '../../../../../../../../../api/emails';
import { Loader } from '../../../../../../../../../components/Loader';
import { ModalContainer, ModalHeader } from '../../../../../../../../../components/Modal';
import { EmailsEditAccountSettings } from './EmailsEditAccountSettings';
import { EmailSignature } from './EmailSignature';

export const EmailsEditAccountModal = ({
  payload: {
    accountID
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const [ account, setAccount ] = useState(null);
  const [ tabIndex, setTabIndex ] = useState(0);

  const handleTabIndexChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    emailsApi.getEmailConfig(accountID).then((account) => {
      setAccount(account);
    });
  }, []);

  return (
    <Dialog disableEnforceFocus {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Edit account settings
        </ModalHeader>

        {!account ? <Loader p={2} /> : (
          <>
            <Box flexShrink={0}>
              <Tabs
                value={tabIndex}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={handleTabIndexChange}
              >
                <Tab label="Edit account" />
                <Tab label="Edit signature" />
              </Tabs>
            </Box>

            {tabIndex === 0 &&
              <EmailsEditAccountSettings
                account={account}
                onResolve={handleModalResolve}
                onReject={handleModalReject}
              />
            }

            {tabIndex === 1 &&
              <EmailSignature
                account={account}
                onResolve={handleModalResolve}
                onReject={handleModalReject}
              />
            }
          </>
        )}
      </ModalContainer>
    </Dialog>
  );
};
