import { useRef, useContext, useEffect } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { InfiniteGridLoader } from '../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../components/Loader';
import { CasesContext } from '../../CasesContext';
import { Card } from './Card';

export const Grid = () => {
  const {
    cases,
    isFetched,
    isFetching,
    filter: { last_page, page },
    resetCases,
    loadNextPage
  } = useContext(CasesContext);
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));

  useEffect(() => {
    resetCases();
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%" pt={2} pl={2} pr={0} pb={0}>
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
        <Loader p={2} loading={!isFetched} render={
          () => !cases.length ? (
            <Typography align="center">No cases found</Typography>
          ) : (
            <InfiniteGridLoader
              items={cases}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={{ last_page, page }}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={520}
              cellHeight={isMobile ? 550 : 450}
            />
          )}
        />
      </CustomScrollbars>
    </Box>
  );
};
