import { orderDirectionsMap } from '../../FormField/selects/OrderBy';

export const initialState = {
  isFetching: false,
  isFetched: false,
  tasks: [],
  pagination: {
    total: 0,
    last_page: 1
  },
  filter: {
    page: 1,
    per_page: 12,
    order_direction: orderDirectionsMap.desc,
    types: [],
    priorities: [],
    cases: [],
    statuses: []
  },
  selectedTasksIDs: []
};
