export const gendersMap = [
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'female',
    label: 'Female'
  }
];

export const statusMap = [
  {
    value: 0,
    label: 'Inactive/None'
  },
  {
    value: 1,
    label: 'Active'
  }
];
