export const styles = ({ spacing, palette, typography: { fontWeightMedium }, breakpoints }) => ({
  content: {
    marginBottom: spacing(2)
  },

  contentInfo: {
    paddingLeft: spacing(2)
  },

  contentInfoPain: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: spacing(2)
  },

  title: {
    fontWeight: fontWeightMedium,
    marginBottom: spacing()
  },

  formTitle: {
    fontWeight: fontWeightMedium,
    color: palette.darkgreen,
    marginBottom: spacing(2)
  },

  experienceControl: {
    width: 205
  },

  experienceTextField: {
    marginTop: 0
  },

  noteIcon: {
    display: 'flex',
    alignItems:'center',
    fontWeight: fontWeightMedium,
    color: palette.grey[600]
  },

  painExperience: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    maxWidth: 400,
    paddingLeft: spacing(2)
  },

  painExperienceRow: {
    width: 180
  },

  timeContent: {
    paddingLeft: spacing(2),
    display: 'flex',
    alignItems: 'center'
  },

  timeInfo: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(3),

    [breakpoints.down('sm')]: {
      marginBottom: spacing()
    }
  },

  timeField: {
    minWidth: 0,
    width: 70,
    marginLeft: spacing(1.5),
    marginRight: spacing(1.5)
  }
});
