import { useState } from 'react';
import { Icon } from '@mdi/react';
import { mdiHelpCircle } from '@mdi/js';
import { IconButton, makeStyles, SvgIcon, Tooltip, Box } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ContentCard } from '../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../components/WidgetsActionButtons';
import { MainContent } from './MainContent';
import { getTooltip, titleHelper } from './helpers';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const BillingSummery = ({
  isOpen,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const classes = useStyles();
  const [ isFetchedData, setIsFetchedData ] = useState(false);
  const [ filterType, setFilterType ] = useState(null);

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      isResizable={isOpen}
      title={isFetchedData ? (titleHelper[filterType] || filterType) : 'Balances'}
      icon={
        <Tooltip
          title={getTooltip(filterType)}
          placement="bottom-start"
        >
          <SvgIcon
            color="primary"
            className={classes.contentIcon}
          >
            <Icon path={mdiHelpCircle} />
          </SvgIcon>
        </Tooltip>
      }
      leftActions={[
        isFetchedData ?
          <IconButton
            color="primary"
            className={classes.actionContentIcon}
            onClick={() => setIsFetchedData(false)}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          :
          null
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box height={height || '100%'}>
        <MainContent
          isFetchedData={isFetchedData}
          filterType={filterType}
          setIsFetchedData={setIsFetchedData}
          setFilterType={setFilterType}
        />
      </Box>
    </ContentCard>
  );
};
