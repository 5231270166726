import { Link } from 'react-router-dom';
import { makeStyles, Typography, Link as MuiLink, Box } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotesIcon from '@material-ui/icons/Notes';
import LinkIcon from '@material-ui/icons/Link';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Popper } from '../../../../../../../../components/Popper';
import { UserLink } from '../../../../../../../../components/UserLink';
import { ContactMenu } from './ContactMenu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ index, item, medicalProfile, onUpdate }) => {
  const classes = useStyles();

  const openContactMenu = (callback) => (event) => {
    event.preventDefault();

    callback(event);
  };

  return (
    <div className={classes.root}>
      <Box width="80%">
        <UserLink
          size="xl"
          user={item}
          path={itemsRoutesByModels.user(item.user_id)}
        >
          <div className={classes.content}>
            <Typography color="primary" className={classes.userContent}>
              {item.relationship.toUpperCase()}
            </Typography>

            <LinkIcon fontSize="small" color="inherit" className={classes.userIcon}/>

            <MuiLink
              noWrap
              target="_blank"
              component={Link}
              to={itemsRoutesByModels.user(item.user_id)}
              title={window.location.origin + itemsRoutesByModels.user(item.user_id)}
            >
              {window.location.origin + itemsRoutesByModels.user(item.user_id)}
            </MuiLink>
          </div>

          <div className={classes.noteContent}>
            <div className={classes.noteIcon}>
              <NotesIcon fontSize="small" color="inherit" /> Note
            </div>

            <Typography color="textSecondary" title={item.note}>
              {item.note}
            </Typography>
          </div>
        </UserLink>
      </Box>

      <Popper
        placement="left-start"
        anchorRenderer={
          ({ anchorRef, handleToggle }) => (
            <IconButton buttonRef={anchorRef} onClick={openContactMenu(handleToggle)}>
              <MoreVertIcon/>
            </IconButton>
          )
        }
      >
        {({ handleClose }) => (
          <ContactMenu
            index={index}
            item={item}
            medicalProfile={medicalProfile}
            onUpdate={onUpdate}
            onClose={handleClose}
          />
        )}
      </Popper>
    </div>
  );
};
