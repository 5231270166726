import { useContext, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { CaseReminderEventsContext } from '../../../../../../components/cases/reminders/events';
import { ReminderEventTypesSelect } from '../../../../../../components/cases/reminders/events/ReminderEventTypesSelect';
import { saveFilter } from '../../../../../../store/lastFilters';
import { TextField } from '../../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../../components/FiltersBar';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const initialValues = {
  search: null,
  group_id: null
};

export const FiltersBar = ({ filterKey = '', hiddenFields = [], ...props }) => {
  const classes = useStyles();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { filter, relationsForFilter, applyFilter, applyRelationsForFilter } = useContext(CaseReminderEventsContext);

  const handleSearchChange = debounce((event) => {
    const value = event.target.value;

    applyFilter({ search: value });
    applyRelationsForFilter({ search: value });
  }, 600);

  const handleTypeChange = (type) => {
    applyFilter({ ...filter, type: type?.value || null });
    applyRelationsForFilter({ type });
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter({
        ...relationsForFilter,
        ...lastFilters
      });
    }
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      className={classes.root}
      ToolbarProps={{ className: classes.toolbar }}
      initialValues={initialValues}
      hiddenFields={hiddenFields}
      filterKey={filterKey}
      onReset={() => applySavedFilter(initialValues)}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.search,
          label: filterFieldsLabels[filterFieldsMap.search],
          field: <TextField
            name="search"
            type="search"
            label="Search"
            placeholder="Search..."
            onChange={handleSearchChange}
          />
        },
        {
          fieldKey: filterFieldsMap.type,
          label: filterFieldsLabels[filterFieldsMap.type],
          field: <ReminderEventTypesSelect
            name="type"
            label="Type"
            placeholder="Select type..."
            onChange={handleTypeChange}
          />
        }
      ]}

      {...props}
    />
  );
};
