import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { AddressLink } from '../../../../../../../../components/AddressLink';
import { Divider } from '../../../../../../../../components/Divider';
import {
  Checkbox,
  CompaniesAutocomplete,
  ConfirmationAutocomplete,
  KeyboardDatePicker,
  TextField
} from '../../../../../../../../components/FormField';
import { generateAddress } from '../../../../../../../../utils/generateAddress';
import { Attachment } from '../Attachment';

export const ChecklistForm = ({ caseID, onChecklistUpdate }) => {
  const { values, setFieldValue } = useFormikContext();
  const policeDepartmentAddress = (
    values.police_department?.full_address
    || generateAddress(values.police_department?.main_office?.address)
  );

  const handleChecklistUpdate = (checklist) => {
    setFieldValue('medias', checklist?.medias);
    onChecklistUpdate(checklist);
  };

  const handlePoliceDepartmentChange = (company) => {
    setFieldValue('police_department', company);
  };

  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item sm={4}>
        <FormControlLabel
          label="Intake Form Completed"
          control={<Checkbox name="intake_form_completed" />}
        />

        <Attachment
          name="intake_form_completed"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Witnesses"
          control={<Checkbox name="witnesses" />}
        />

        <Attachment
          name="witnesses"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <TextField
          name="officer_id"
          label="Officer ID"
          placeholder="Enter Officer ID..."
        />

        <Attachment
          name="officer_id"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <ConfirmationAutocomplete
          name="scene_photos"
          label="Scene Photos"
        />

        <Attachment
          name="scene_photos"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Witness Statement"
          control={<Checkbox name="witness_statement" />}
        />

        <Attachment
          name="witness_statement"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <KeyboardDatePicker
          name="sr_one_sent"
          label="SR-1 Sent"
          outputFormat="YYYY-MM-DD"
        />

        <Attachment
          name="sr_one_sent"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <ConfirmationAutocomplete
          name="pd_photos"
          label="PD Photos"
        />

        <Attachment
          name="pd_photos"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Passengers"
          control={<Checkbox name="passengers" />}
        />

        <Attachment
          name="passengers"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <KeyboardDatePicker
          name="sr_nineteen_sent"
          label="SR-19 Sent"
          outputFormat="YYYY-MM-DD"
        />

        <Attachment
          name="sr_nineteen_sent"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Rep Ltr to Def-1 Ins"
          control={<Checkbox name="rep_ltr_to_def_one_ins" />}
        />

        <Attachment
          name="rep_ltr_to_def_one_ins"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="UM Case"
          control={<Checkbox name="um_case" />}
        />

        <Attachment
          name="um_case"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="PLF Dec Sheet"
          control={<Checkbox name="plf_dec_sheet" />}
        />

        <Attachment
          name="plf_dec_sheet"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Rep Ltr to Def-2 Ins"
          control={<Checkbox name="rep_ltr_to_def_two_ins" />}
        />

        <Attachment
          name="rep_ltr_to_def_two_ins"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <ConfirmationAutocomplete
          name="police_report"
          label="Police Report"
        />

        <Attachment
          name="police_report"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <ConfirmationAutocomplete
          name="pd_requested"
          label="PD Requested"
        />

        <Attachment
          name="pd_requested"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Rep Ltr Own Ins"
          control={<Checkbox name="rep_ltr_own_ins" />}
        />

        <Attachment
          name="rep_ltr_own_ins"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <CompaniesAutocomplete
          name="police_department_id"
          label="Police Department"
          onChange={handlePoliceDepartmentChange}
        />

        {policeDepartmentAddress &&
          <Box mt={1}>
            <AddressLink address={policeDepartmentAddress} />
          </Box>
        }

        <Attachment
          name="police_department_id"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="PD Received"
          control={<Checkbox name="pd_received" />}
        />

        <Attachment
          name="pd_received"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Rep Ltr (Def-3)"
          control={<Checkbox name="rep_ltr_def_three" />}
        />

        <Attachment
          name="rep_ltr_def_three"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <TextField
          name="tcr_number"
          label="TCR Number"
          placeholder="Enter TCR Number..."
        />

        <Attachment
          name="tcr_number"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="LOE"
          control={<Checkbox name="loe" />}
        />

        <Attachment
          name="loe"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          variant="filled"
          name="other"
          label="Note"
          placeholder="Enter note..."
        />

        <Attachment
          name="other"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider gutter={2} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4">Medicals</Typography>
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Medicare"
          control={<Checkbox name="medicare" />}
        />

        <Attachment
          name="medicare"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={3}>
        <KeyboardDatePicker
          name="medicare_filled_at"
          label="Filed"
          outputFormat="YYYY-MM-DD"
        />

        <Attachment
          name="medicare_filled_at"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Final Lien"
          control={<Checkbox name="medicare_final_lien" />}
        />

        <Attachment
          name="medicare_final_lien"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Medi-Cal"
          control={<Checkbox name="medical" />}
        />

        <Attachment
          name="medical"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={3}>
        <KeyboardDatePicker
          name="medical_filled_at"
          label="Filed"
          outputFormat="YYYY-MM-DD"
        />

        <Attachment
          name="medical_filled_at"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Final Lien"
          control={<Checkbox name="medical_final_lien" />}
        />

        <Attachment
          name="medical_final_lien"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="Pri Health Ins"
          control={<Checkbox name="primary_health_ins" />}
        />

        <Attachment
          name="primary_health_ins"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={3} />

      <Grid item sm={4}>
        <FormControlLabel
          label="Final Lien"
          control={<Checkbox name="primary_health_ins_final_lien" />}
        />

        <Attachment
          name="primary_health_ins_final_lien"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={4}>
        <FormControlLabel
          label="No Health Ins"
          control={<Checkbox name="no_health_ins" />}
        />

        <Attachment
          name="no_health_ins"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>

      <Grid item sm={8} />

      <Grid item sm={4}>
        <TextField
          name="insurance_card_number"
          label="Insurance Card #"
          placeholder="Enter Insurance Card #..."
        />

        <Attachment
          name="insurance_card_number"
          caseID={caseID}
          medias={values.medias}
          onUpdate={handleChecklistUpdate}
        />
      </Grid>
    </Grid>
  );
};
