import { makeStyles, Paper, Tab, Tabs } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { LayoutContextProvider } from '../../../../components/LayoutContext';
import { Route } from '../../../../components/router';
import { apiModelsMap } from '../../../../dataMaps/apiModels';
import { FilesPage } from '../../files-common/FilesPage';
import { columnsMap } from '../../files-common/FilesList/listConfig';
import { Trash } from '../../FilesPage/Trash';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
});

const tabsRoutes = {
  files: '/profile/files',
  recycleBin: '/profile/files/recycle-bin'
};

export const Files = ({ filterKey }) => {
  const location = useLocation();
  const classes = useStyles();
  const currentUser = useSelector(({ profile }) => profile.user);

  return (
    <Paper elevation={0} variant="outlined" className={classes.root}>
      <Tabs value={location.pathname}>
        <Tab
          component={Link}
          value={tabsRoutes.files}
          to={tabsRoutes.files}
          label="Files"
        />

        <Tab
          component={Link}
          value={tabsRoutes.recycleBin}
          to={tabsRoutes.recycleBin}
          label="Recycle Bin"
        />
      </Tabs>

      <Route exact path={tabsRoutes.files}>
        <FilesPage
          owner={currentUser}
          ownerType={apiModelsMap.profile}
          ownerID={currentUser.profile_id}
          filter={{
            user_profile_id: currentUser.profile_id
          }}
          filterKey={filterKey}
          hiddenColumns={[ columnsMap.owner ]}
        />
      </Route>

      <Route path={tabsRoutes.recycleBin}>
        <LayoutContextProvider>
          <Trash
            ownerType={apiModelsMap.profile}
            ownerID={currentUser.profile_id}
          />
        </LayoutContextProvider>
      </Route>
    </Paper>
  );
};
