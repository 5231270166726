export const styles = ({ spacing, palette }) => ({
  loader: {
    padding: spacing(2)
  },

  contentMessage: {
    padding: spacing(3)
  },

  actionContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: spacing(2),
    paddingRight: spacing(2),
    borderBottom: `1px solid ${palette.divider}`
  }
});
