import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormikField } from '../useFormikField';
import { TextField } from '../TextField';
import { renderOption } from './renderOption';
import { transformPlaceDetailsToAddress } from './transformPlaceDetailsToAddress';

const autocompleteService = { current: null };
const placeService = { current: null };

const divForPlacesService = document.createElement('div');

export const AddressAutocomplete = ({
  withoutFormik,
  name,
  required,
  label,
  placeholder,
  margin,
  onChange = () => {},
  ...props
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField(name);
  const {
    isFormikField,
    fieldProps: [ field = {}, { touched, initialValue } = {}, { setValue: setFormikValue, setTouched } = {} ] = [],
    error
  } = formikFieldProps;
  const [ value, setValue ] = useState(null);
  const [ inputValue, setInputValue ] = useState('');
  const [ options, setOptions ] = useState([]);

  const fetch = useCallback(debounce((request, callback) => {
    autocompleteService.current.getPlacePredictions(request, callback);
  }, 600), []);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);

    if (isFormikField) {
      setFormikValue(newInputValue);
      setTouched(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (isFormikField) {
      setFormikValue(newValue?.description);
      setTouched(true);
    }

    if (newValue?.place_id) {
      placeService.current.getDetails({
        placeId: newValue.place_id
      }, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          onChange(transformPlaceDetailsToAddress(result), result);
        } else {
          // eslint-disable-next-line no-console
          console.log(status);
        }
      });
    } else {
      onChange(newValue);
    }
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!placeService.current && window.google) {
      placeService.current = new window.google.maps.places.PlacesService(divForPlacesService);
    }

    if (!autocompleteService.current || initialValue === inputValue) {
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = [ ...newOptions, ...results ];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [ value, inputValue, fetch ]);

  useEffect(() => {
    if (field.value !== value) {
      setValue(field.value);
    }
  }, [ field.value ]);

  return (
    <Autocomplete
      freeSolo
      // autoComplete
      // includeInputInList
      // filterSelectedOptions
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      value={value}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={({ inputProps: inputPropsProp, ...props }) => {
        const { autoComplete, ...inputProps } = inputPropsProp;

        return (
          <TextField
            {...props}
            {...inputProps}

            fullWidth
            withoutFormik
            required={required}
            label={label}
            placeholder={placeholder}
            margin={margin}
            error={!!(touched && error)}
            helperText={(touched && error)}
          />
        );
      }}
      renderOption={renderOption}

      {...props}
    />
  );
};
