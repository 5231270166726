import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  test_id: Yup.mixed().nullable().required(),
  ordered_id: Yup.mixed().nullable().required(),
  date_collected: Yup.mixed().nullable().required(),
  date_resulted: Yup.mixed().nullable().required(),
  standard_range: Yup.string().nullable().when('items', {
    is: (items) => !items.length,
    then: Yup.string().nullable().required().max(100, 'Max 100 symbols')
  }),
  value: Yup.string().nullable().when('items', {
    is: (items) => !items.length,
    then: Yup.string().nullable().required().max(100, 'Max 100 symbols')
  }),
  items: Yup.array().of(Yup.object().shape({
    is_checked: Yup.boolean(),
    value: Yup.string().nullable().when('is_checked', {
      is: true,
      then: Yup.string().nullable().required().max(100, 'Max 100 symbols')
    }),
    standard_range: Yup.string().nullable().when('is_checked', {
      is: true,
      then: Yup.string().nullable().required().max(100, 'Max 100 symbols')
    })
  }))
});
