import { useRef } from 'react';
import { Box } from '@material-ui/core';
import { useResizeObserver } from '../../../helpers/hooks/useResizeObserver';
import { EmailsActions, EmailsList } from '../../../app/Dashboard/EmailsPage';
import { FiltersBar } from './FiltersBar';

export const EmailsContent = ({ children }) => {
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });

  return (
    <Box ref={rootRef} display="flex" flexDirection="column" height="100%">
      <FiltersBar />

      <EmailsActions disableButton width={width}>
        {children}
      </EmailsActions>

      <Box flexGrow={1}>
        <EmailsList />
      </Box>
    </Box>
  );
};
