import { useFormikContext } from 'formik';
import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  CompaniesAutocomplete,
  TextField,
  OfficesLocationSelect,
  Checkbox,
  EmailsSelect
} from '../../../../../../components/FormField';
import { Tooltip } from '../../../../../../components/Tooltip';
import { AvatarContent } from '../../../../Members/CreateCustomerModal';

export const MainInfo = ({ hideWorkOffice, onAvatarUrlChange }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleCompanyChange = (company) => {
    if (company) {
      setFieldValue('work.office_id', null);
      setFieldValue('office', null);
    }
  };

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              name="first_name"
              label="First name"
              placeholder="Enter first name..."
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              name="last_name"
              label="Last name"
              placeholder="Enter last name..."
            />
          </Grid>

          <Grid item xs={12}>
            <EmailsSelect
              required
              name="email"
              label="Email"
              placeholder="Enter email..."
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="work.position"
              label="Position"
              placeholder="Enter position..."
            />
          </Grid>

          {!hideWorkOffice &&
            <>
              <Grid item xs={12}>
                <CompaniesAutocomplete
                  required
                  isCreatable
                  name="work.company_id"
                  label="Company Name"
                  onChange={handleCompanyChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Tooltip isExistTooltip={!values?.work?.company_id} title="Choose a company first">
                  <div>
                    <OfficesLocationSelect
                      required
                      multiple
                      isCreatable
                      disabled={!values?.work?.company_id}
                      name="work.office_id"
                      label="Office Name"
                      params={{ company_id: values?.work?.company_id || null }}
                    />
                  </div>
                </Tooltip>
              </Grid>
            </>
          }

          <Grid item xs={6}>
            <KeyboardDatePicker
              clearable
              name="work.start_date"
              label="Getting started work date"
              outputFormat="YYYY-MM-DD"
            />
          </Grid>

          <Grid item xs={6}>
            <KeyboardDatePicker
              clearable
              name="work.end_date"
              label="End of work date"
              outputFormat="YYYY-MM-DD"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              label={<Typography>Send register email</Typography>}
              control={
                <Checkbox
                  color="primary"
                  name="is_notify"
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={5}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <AvatarContent size={160} onAvatarUrlChange={onAvatarUrlChange} />
        </Box>
      </Grid>
    </Grid>
  );
};
