import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { mdiHomeCityOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { dataURItoBlob } from '../../../../../helpers/files';
import * as usersApi from '../../../../../api/users';
import * as companyApi from '../../../../../api/companies';
import {
  transformPayPeriodToOption,
  transformTimeZoneToOption
} from '../../../../../components/FormField';
import { Loader } from '../../../../../components/Loader';
import { ModalHeader, ModalBody, ModalFooter, ModalContainer } from '../../../../../components/Modal';
import { CompanyForm } from '../CompanyForm';
import { initialValues } from '../CreateCompanyModal/initialValues';
import { validationSchema } from '../validationSchema';

export const EditCompanyModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { company_id }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ avatarDataURL, setAvatarDataURL ] = useState(null);
  const [ company, setCompany ] = useState(null);

  const handleUpdateCompany = ({ values, setErrors }) => {
    return companyApi.updateCompany({
      ...values,

      rate: values?.rate ? +values?.rate * 100 : null,
      offices: values?.offices?.map((office) => ({ id: office?.id?.id })),
      venue:  values.venue.map((venue) => {
        return venue.is_main ? ({ ...venue, is_main: !!+venue.is_main }) : venue;
      })
    }).then((data) => {
      const venue = data?.venue?.find(({ is_main }) => is_main > 0);

      enqueueSnackbar('Company successfully updated', { variant: 'success' });
      handleModalResolve({
        billing_full_address: data?.billing?.full_address,
        venue_full_address: venue?.full_address,
        offices_count: data.offices.length,

        ...data
      });
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  const updateCompany = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (values?.venue?.length) {
      const isMainVenue = values.venue.find((item) => item.is_main > 0);

      if (!isMainVenue) {
        values.venue[0] = { ...values.venue[0], is_main: 1 };
      }
    }

    if (avatarDataURL) {
      const formData = new FormData();

      formData.append('file', dataURItoBlob(avatarDataURL));

      return usersApi.uploadAvatar(formData).then((avatar) => {
        values.avatar_id = avatar.id;

        return handleUpdateCompany({ values, setErrors });
      }).catch(({ status, data: { errors } = {} }) => {
        if (status === 422) {
          errors && setErrors(errors);
        }
      });
    } else {
      return handleUpdateCompany({ values, setErrors });
    }
  };

  useEffect(() => {
    companyApi.fetchCompany(company_id).then(setCompany);
  }, []);

  return (
    <Dialog maxWidth="md" {...DialogProps}>
      <Loader p={2} loading={!company} render={
        () => (
          <Formik
            initialValues={{
              ...company,

              payroll: merge(initialValues.payroll, {
                ...company.payroll,

                pay_period: transformPayPeriodToOption(company?.payroll?.pay_period)
              }),

              timezone: transformTimeZoneToOption(company.timezone),
              contact_user_id: company.contact_user,
              contact_person_phone: company?.contact_user?.phone,
              company_type_id: company?.company_type,
              main_office_id: company?.main_office,
              offices: company?.offices?.map((office) => ({
                id: office
              })),
              rate: company?.rate ? +company?.rate / 100 : null
            }}
            validationSchema={validationSchema}
            onSubmit={updateCompany}
          >
            {({ isSubmitting, handleSubmit }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader
                  icon={<SvgIcon><Icon path={mdiHomeCityOutline} /></SvgIcon>}
                  onClose={handleModalReject}
                >
                  Edit Company
                </ModalHeader>

                <ModalBody>
                  <CompanyForm onAvatarUrlChange={setAvatarDataURL} />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>Cancel</Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
