import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles, Button, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import * as mrisApi from '../../../../../api/profile';
import { TextField } from '../../../../../components/FormField';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { MRIBody } from '../CreateMRIModal/MRIBody';
import { validationSchema } from '../CreateMRIModal/MRIValidationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EditMRIModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    mri,
    appointment_id = null,
    onListUpdate = () => {}
  }
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [ initialValues, setInitialValues ] = useState(mri);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const updateMRI = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return mrisApi.updateMRI({ id: mri?.id, ...values }).then(({ data }) => {
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      onListUpdate();
      handleModalResolve(data);
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  useEffect(() => {
    mrisApi.fetchMRI(mri?.id).then((data) => setInitialValues({ ...data, ...mri }));
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,

        appointment_id,
        patient_id: initialValues?.patient,
        physician_id: initialValues?.physician,
        office_id: initialValues?.office
      }}
      validationSchema={validationSchema}
      onSubmit={updateMRI}
    >
      {({ handleSubmit, isSubmitting, handleBlur }) => (
        <Dialog fullScreen={isMobile} PaperProps={{ style: { maxWidth: 1100 } }} {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              <TextField
                autoFocus
                multiline
                onBlur={handleBlur}
                name="name"
                label="Cervical and lumbar spine."
                margin="dense"
                InputProps={{
                  className: classes.input,
                  disableUnderline: true
                }}
              />
            </ModalHeader>

            <ModalBody>
              <MRIBody isEditable />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
