import { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { isEmptyDeep } from '../../../../../../helpers/isEmptyDeep';
import { NestedList } from '../../../../../NestedList';
import { Medications } from './Medications';
import { Allergies } from './Allergies';
import { Symptoms } from './Symptoms';

export const MedicalFormInfo = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isOpenChild, setIsOpenChild ] = useState({
    neck_symptoms: true,
    back_symptoms: true
  });

  const handleOpenListItem = (name = null) => () => {
    name
      ? setIsOpenChild((state) => ({ ...state, [name]: !state[name] }))
      : setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(report) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Medical Form info"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem()}
    >
      <Box p={3}>
        {report?.reason_for_consultation &&
          <Box mb={3}>
            <Typography gutterBottom variant="h5">Reason for consultation</Typography>
            <Typography color="textSecondary">{report?.reason_for_consultation}</Typography>
          </Box>
        }

        {report?.history_of_present_illness &&
          <Box mb={3}>
            <Typography gutterBottom variant="h5">History of present illness</Typography>
            <Typography color="textSecondary">{report?.history_of_present_illness}</Typography>
          </Box>
        }

        {report?.past_medical_history &&
          <Box mb={3}>
            <Typography gutterBottom variant="h5">Past medical history</Typography>
            <Typography color="textSecondary">{report?.past_medical_history}</Typography>
          </Box>
        }

        {report?.personal_history &&
          <Box mb={3}>
            <Typography gutterBottom variant="h5">Personal history</Typography>
            <Typography color="textSecondary">{report?.personal_history || 'No info.'}</Typography>
          </Box>
        }

        {report?.past_surgical_history &&
          <Box mb={3}>
            <Typography gutterBottom variant="h5">Surgeries/Past surgical history</Typography>
            <Typography color="textSecondary">{report?.past_surgical_history || 'No info.'}</Typography>
          </Box>
        }

        {!!report?.medications?.length &&
          <Box mb={3}>
            <Typography gutterBottom variant="h5">Medications</Typography>
            <Medications medications={report?.medications} />
          </Box>
        }

        {!!report?.allergies?.length &&
          <Box mb={3}>
            <Typography gutterBottom variant="h5">Allergies</Typography>
            <Allergies allergies={report?.allergies} />
          </Box>
        }

        {!isEmptyDeep(report?.current_symptoms_neck) &&
          <NestedList
            disablePadding
            disableDivider
            isOpen={isOpenChild.neck_symptoms}
            title={
              <Box component="span" ml={-1}>
                Current symptoms: Neck
              </Box>
            }
            onOpen={handleOpenListItem('neck_symptoms')}
          >
            <Symptoms name="current_symptoms_neck" symptoms={report} />
          </NestedList>
        }

        {!isEmptyDeep(report?.current_symptoms_back) &&
          <Box mb={3}>
            <NestedList
              disablePadding
              disableDivider
              isOpen={isOpenChild.back_symptoms}
              title={
                <Box component="span" ml={-1}>
                  Current symptoms: Back
                </Box>
              }
              onOpen={handleOpenListItem('back_symptoms')}
            >
              <Symptoms name="current_symptoms_back" symptoms={report} />
            </NestedList>
          </Box>
        }

        {!!report?.walking &&
          <Box display="flex" alignItems="flex-end" mb={1}>
            <Typography variant="h5">Walking:</Typography>

            <Box ml={2}>
              <Typography color="textSecondary">
                {report?.walking + ' minutes'}
              </Typography>
            </Box>
          </Box>
        }

        {!!report?.standing &&
          <Box display="flex" alignItems="flex-end" mb={1}>
            <Typography variant="h5">Standing:</Typography>

            <Box ml={2}>
              <Typography color="textSecondary">
                {report?.standing + ' minutes'}
              </Typography>
            </Box>
          </Box>
        }
      </Box>
    </NestedList>
  );
};
