import { makeStyles, Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../components/ListRow';
import { columnsWidths } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = () => {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.time} className={classes.responsiveRow}>
          Time
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.patient} className={classes.responsiveRow}>
          Patient
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.provider} className={classes.responsiveRow}>
          Provider
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
