import { fade } from '@material-ui/core/styles';

export const styles = ({ palette }) => ({
  root: ({ color }) => palette[color] ? ({
    '&:hover': {
      backgroundColor: fade(palette[color].main, palette.action.hoverOpacity)
    }
  }) : {},

  checked: ({ color }) => palette[color] ? ({
    '&$root': {
      color: palette[color].main
    }
  }) : {}
});
