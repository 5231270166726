export const initialValues = {
  is_active: true,
  is_notify: false,
  first_name: null,
  last_name: null,
  birthday: null,
  phone_number: null,
  fax: null,
  mobile_phone_number: null,
  gender: null,
  email: null,
  role: null,
  ssn: null,
  home: {
    street: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    note: null
  },
  work: {
    company_id: null,
    office_id: null,
    position: null,
    department: null
  }
};
