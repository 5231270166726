import { makeStyles } from '@material-ui/core';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../../../components/LayoutContext';
import { FileDropZoneWindow } from '../../../../components/FileDropZoneWindow';
import { useModal } from '../../../../components/ModalsProvider';
import { Groups } from '../../FilesPage/MyFilesPage/Groups';
import { List } from '../../FilesPage/MyFilesPage/List';
import { Grid } from '../../FilesPage/MyFilesPage/Grid';
import { FilesContextProvider, FilesFilterContextProvider, FileUploadModal } from '../index';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const hiddenFilterFields = [ 'cases' ];

export const FilesPage = ({ filter, filterKey, owner, ownerType, ownerID, hiddenColumns }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  const handleFilesDropped = (files = []) => {
    openModal(FileUploadModal, {
      payload: {
        files,
        owner_type: ownerType,
        owner_id: ownerID
      }
    });
  };

  return (
    <FileDropZoneWindow onDropAccepted={handleFilesDropped}>
      <div className={classes.root}>
        <LayoutContextProvider>
          <LayoutContext.Consumer>
            {({ viewVariant }) => (
              <FilesFilterContextProvider filter={filter}>
                <ActionsBar
                  owner={owner}
                  ownerID={ownerID}
                  ownerType={ownerType}
                  filterOpenButton={filterOpenButton}
                />

                <ScrollWrapper {...scrollWrapperProps}>
                  <FilterBarWrapper {...filterBarWrapperProps}>
                    <FiltersBar filterKey={filterKey} hiddenFields={hiddenFilterFields} />
                  </FilterBarWrapper>
                </ScrollWrapper>

                <div className={classes.filesWindow}>
                  <div className={classes.filesWindow__main}>
                    {(viewVariant === viewVariantsMap.groups) && <Groups hiddenColumns={hiddenColumns} />}

                    {(viewVariant === viewVariantsMap.list) &&
                      <FilesContextProvider owner={owner}>
                        <List hiddenColumns={hiddenColumns} />
                      </FilesContextProvider>
                    }

                    {(viewVariant === viewVariantsMap.grid) &&
                      <FilesContextProvider owner={owner}>
                        <Grid />
                      </FilesContextProvider>
                    }
                  </div>
                </div>
              </FilesFilterContextProvider>
            )}
          </LayoutContext.Consumer>
        </LayoutContextProvider>
      </div>
    </FileDropZoneWindow>
  );
};
