import { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { SortableContainer } from 'react-sortable-hoc';
import {
  makeStyles,
  Box,
  Collapse,
  SvgIcon,
  Typography,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { mdiDomain } from '@mdi/js';
import { Icon } from '@mdi/react';
import AddIcon from '@material-ui/icons/Add';
import { moveArrayItem } from '../../../../../../helpers/moveArrayItem';
import { Fab } from '../../../../../../components/Fab';
import { SortableItem } from './SortableItem';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const SortableListContainer = SortableContainer(({ children }) => {
  return (
    <Box height="100%">
      {children}
    </Box>
  );
});

export const Offices = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const [ preventDragging, setPreventDragging ] = useState(true);

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === 0 || newIndex === 0) {
      return;
    }

    if (oldIndex !== newIndex) {
      const newOfficesList = moveArrayItem(values.offices, oldIndex, newIndex);

      setFieldValue('offices', newOfficesList?.map((item, i) => ({ ...item, index: i })));
    }
  };

  const handleChangePreventDragging = (event) => {
    setPreventDragging(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <FieldArray
        name="offices"
        render={(arrayHelpers) => (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <SvgIcon><Icon path={mdiDomain} /></SvgIcon>

                <Box mr={2} ml={1}>
                  <Typography variant="h3">Offices</Typography>
                </Box>

                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon/>}
                  onClick={() => arrayHelpers.unshift({
                    id: null
                  })}
                >
                  Add
                </Fab>
              </Box>

              {!!values?.offices?.length &&
                <FormControlLabel
                  label={preventDragging ? 'Lock' : 'Unlock'}
                  control={
                    <Switch
                      checked={preventDragging}
                      color="primary"
                      onChange={handleChangePreventDragging}
                    />
                  }
                />
              }
            </Box>

            <Collapse
              unmountOnExit
              in={!!values?.offices?.length}
              timeout="auto"
              className={classes.content}
            >
              <SortableListContainer
                useDragHandle
                onSortEnd={handleSortEnd}
              >
                {values?.offices?.map((item, i) => (
                  <SortableItem
                    isDragging={preventDragging}
                    key={i}
                    index={i}
                    itemIndex={i}
                    item={item}
                    arrayHelpers={arrayHelpers}
                  />
                ))}
              </SortableListContainer>
            </Collapse>
          </>
        )}
      />
    </div>
  );
};
