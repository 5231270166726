export const styles = ({
  spacing,
  palette: { primary },
  typography: { pxToRem }
}) => ({
  icon: {
    backgroundColor: '#29497A',

    '&:hover': {
      backgroundColor: '#29497A',
      boxShadow: '0 1px 6px rgba(0, 0, 0, .45)'
    }
  },

  icon__play: {
    marginRight: spacing(1),
    backgroundColor: primary.contrastText,
    borderRadius: '100%',
    color: '#29497A',
    fontSize: pxToRem(17)
  },

  icon__pause: {
    marginRight: spacing(1),
    backgroundColor: '#29497A',
    borderRadius: '100%',
    color: primary.contrastText
  }
});
