export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(2)
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },

  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: spacing()
  },

  contentUserInfo: {
    marginLeft: spacing()
  }
});
