import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as templatesApi from '../../../../../../api/profile';
import { Loader } from '../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../components/Modal';
import { Body, REFILL_OPTIONS, validationSchema } from '../CreateTemplateModal';

export const EditTemplateModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { id = null }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ initialValues, setInitialValues ] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const editTemplate = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return templatesApi.updatePrescriptionTemplate(values).then(({ data }) => {
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      enqueueSnackbar('Not updated', { variant: 'error' });
      setErrors(errors);
    });
  };

  useEffect(() => {
    templatesApi.fetchPrescriptionTemplate(id)
      .then(setInitialValues)
      .catch(handleModalReject);
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <Loader p={3} loading={!initialValues} render={
        () => (
          <Formik
            initialValues={{
              ...initialValues,

              office_id: initialValues.office,
              provider_id: initialValues.provider,
              icd_code_ids: initialValues?.icd_codes,
              options: {
                generic_equivalent_acceptable: (
                  initialValues?.options?.generic_equivalent_acceptable || 'no'
                ),
                refill: initialValues?.options?.refill || REFILL_OPTIONS
              }
            }}
            validationSchema={validationSchema}
            onSubmit={editTemplate}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
                <ModalHeader
                  icon={
                    <SvgIcon><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>
                  }
                  onClose={handleModalReject}
                >
                  Edit Prescription Template
                </ModalHeader>

                <ModalBody>
                  <Body />
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
