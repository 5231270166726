import { useState } from 'react';
import { flow } from 'lodash';
import printJS from 'print-js';
import { MenuItem, ListItemText, List } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getClaimPDF } from '../../../../../../api/claims';
import { Fab } from '../../../../../../components/Fab';
import { Loader } from '../../../../../../components/Loader';
import { Popper } from '../../../../../../components/Popper';

export const Print = ({ claimID }) => {
  const [ loading, setLoading ] = useState(false);

  const printClaim = (isWhitePaper = false) => () => {
    setLoading(true);

    getClaimPDF(claimID, {
      params: { is_white_paper: +isWhitePaper }
    }).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      setLoading(false);

      printJS({
        printable: url,
        type: 'pdf'
      });
    });
  };

  return (
    <Loader surface loading={loading} render={
      () => (
        <Popper
          arrow={false}
          placement="bottom-end"
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <Fab
              ref={anchorRef}
              variant="extended"
              color="primary"
              startIcon={<PrintIcon />}
              endIcon={<KeyboardArrowDownIcon />}
              onClick={handleToggle}
            >
              Print
            </Fab>
          )}
        >
          {({ handleClose }) => (
            <List>
              <MenuItem onClick={flow(printClaim(), handleClose)}>
                <ListItemText primary="On HCFA Form" />
              </MenuItem>

              <MenuItem onClick={flow(printClaim(true), handleClose)}>
                <ListItemText primary="On White Paper" />
              </MenuItem>
            </List>
          )}
        </Popper>
      )}
    />
  );
};
