import { useContext } from 'react';
import { makeStyles, Typography, Grid, Tooltip, Box, useMediaQuery, useTheme } from '@material-ui/core';
import ViewColumnIcon from '@material-ui/icons/ViewColumnOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { orderByOptions } from '../../../../../components/tasks/TasksProvider/orderByOptions';
import { LayoutContext, viewVariantsMap } from '../../../../../components/LayoutContext';
import { TasksFilterContext } from '../../../../../components/tasks/TasksProvider';
import { SelectedItemsCount } from '../../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { OrderBy } from '../../../../../components/FormField';
import { Button } from '../../../../../components/Button';
import { AppBar } from '../../../../../components/AppBar';
import { CreateTaskModal } from '../../CreateTaskModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({ filterOpenButton }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { openModal } = useModal();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    selectedTasksIDs,
    filter,
    totalTasks,
    setRelationsForFilter,
    applyFilter,
    massDeleteTasks
  } = useContext(TasksFilterContext);
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  const addTask = () => {
    openModal(CreateTaskModal);
  };

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  const handleDeleteSelectedTasks = () => {
    massDeleteTasks(selectedTasksIDs);
  };

  const handleOrderByChange = (name) => (option) => {
    applyFilter({ [name]: option?.value });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.value }));
  };

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ order_direction: orderDirection });
    setRelationsForFilter((state) => ({ ...state, order_direction: orderDirection }));
  };

  return (
    <AppBar ToolbarProps={{ className: classes.root }} border={0}>
      <Box flexGrow={0}>
        <Button
          variant="contained"
          color="primary"
          onClick={addTask}
          startIcon={<AddIcon />}
        >
          Add new task
        </Button>
      </Box>

      <Box flexGrow={1} mx={2}>
        {(viewVariant === viewVariantsMap.list) &&
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <OrderBy
                name="order_by"
                isSearchable={false}
                options={orderByOptions}
                value={orderByOptions.find(({ value }) => value === filter.order_by)}
                orderDirection={filter.order_direction}
                onOptionChange={handleOrderByChange('order_by')}
                onOrderDirectionChange={toggleOrderDirection}
              />
            </Grid>
          </Grid>
        }
      </Box>

      {!!selectedTasksIDs.length &&
        <>
          <SelectedItemsCount length={selectedTasksIDs?.length} total={totalTasks} />

          <VerticalDivider verticalGutters={1} horizontalGutters={1}/>

          <Tooltip
            arrow
            title={<Typography>Delete selected tasks</Typography>}
          >
            <IconButton
              color="error"
              onClick={handleDeleteSelectedTasks}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>

          <VerticalDivider verticalGutters={1} horizontalGutters={1} />
        </>
      }

      {isMobile && filterOpenButton}

      {!isMobile &&
        <>
          <Tooltip arrow title="List view">
            <IconButton
              color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.list)}
            >
              <ViewListIcon />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Column view">
            <IconButton
              edge="end"
              color={viewVariant === viewVariantsMap.columns ? 'primary' : 'default'}
              onClick={toggleViewVariant(viewVariantsMap.columns)}
            >
              <ViewColumnIcon />
            </IconButton>
          </Tooltip>
        </>
      }
    </AppBar>
  );
};
