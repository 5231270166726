import { createContext, useContext, useState, useEffect } from 'react';
import { DocumentVersionsContext } from './DocumentVersionsContext';
import * as documentsApi from '../../../../../api/files/html-documents';

export const VersionPreviewContext = createContext();

export const VersionPreviewContextProvider = ({ children }) => {
  const { versions } = useContext(DocumentVersionsContext);
  const [ isFetching, setIsFetching ] = useState(true);
  const [ selectedVersion, setSelectedVersion ] = useState({});
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState(true);

  const toggleSidebarIsOpen = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const handleVersionSelect = (versionID) => {
    setIsFetching(true);

    documentsApi.getDocumentVersion(versionID).then((version) => {
      setSelectedVersion(version);
      setIsFetching(false);
    });
  };

  useEffect(() => {
    if (selectedVersion && versions.length && !versions.find(({ id }) => id === selectedVersion.id)) {
      setSelectedVersion({});
    }
  }, [ versions ]);

  const providerValue = {
    isFetching,
    selectedVersion,
    sidebarIsOpen,
    setIsFetching,
    handleVersionSelect,
    toggleSidebarIsOpen
  };

  return (
    <VersionPreviewContext.Provider value={providerValue}>
      {children}
    </VersionPreviewContext.Provider>
  );
};
