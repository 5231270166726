import { useRef } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box/Box';
import { orderDirectionsMap } from '../../../components/FormField';
import { useMobileFilterCollapse } from '../../../utils/useMobileFilterCollapse';
import { useResizeObserver } from '../../../helpers/hooks';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { Page, PageBody } from '../../../components/Page';
import { InsurancesProvider } from '../ProfilePage/insurance';
import { orderByMap } from '../ProfilePage/MedicalInfo/MedicalForms/FormFiltersBar/orderByOptions';
import { ActionsBar } from './ActionsBar';
import { FiltersBar } from './FiltersBar';
import { MainContent } from './MainContent';

export const Insurances = () => {
  const rootRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { width } = useResizeObserver({ ref: rootRef });
  const isTablet = width <= theme.breakpoints.values.tabletLg;
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse('tabletLg', width);
  const initialState = {
    filter: {
      page: 1,
      per_page: 15,
      order_direction: orderDirectionsMap.desc,
      order_by: orderByMap.updated_at
    },
    pagination: {
      total: 0,
      last_page: 1
    }
  };

  return (
    <LayoutContextProvider>
      <InsurancesProvider allInsurances initialState={initialState} filterProp={initialState.filter}>
        <Box
          ref={rootRef}
          bgcolor="grey.100"
          overflow="hidden"
          display="flex"
          flexDirection="column"
          flexGrow={1}
          height="100%"
          px={isMobile ? 1 : 3}
          py={isMobile ? 1 : 2}
        >
          <Page>
            <ActionsBar
              isTablet={isTablet}
              filterOpenButton={filterOpenButton}
            />

            <PageBody disablePaddings disableScrollbars fullHeight>
              <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
                <ScrollWrapper {...scrollWrapperProps}>
                  <FilterBarWrapper {...filterBarWrapperProps}>
                    <FiltersBar filterKey="insurances" />
                  </FilterBarWrapper>
                </ScrollWrapper>

                <MainContent width={width} />
              </Box>
            </PageBody>
          </Page>
        </Box>
      </InsurancesProvider>
    </LayoutContextProvider>
  );
};
