import { gendersMap } from '../../../../../components/users';

export const getSubscriberInfo = (data = null, setFieldValue, disableName = false) => {
  setFieldValue('subscriber_info.address',
    { zip: data?.home?.zip, ...(data?.home || data?.address) } || null
  );

  if (!disableName) {
    setFieldValue('subscriber_info.first_name', data?.first_name || null);
    setFieldValue('subscriber_info.last_name', data?.last_name || null);
    setFieldValue(
      'subscriber_info.phone_number',
      data?.phone_number || data?.mobile_phone_number || null
    );
    setFieldValue('subscriber_info.dob', data?.dob || data?.birthday || null);
    setFieldValue('subscriber_info.gender',
      gendersMap.find(({ value }) => value === data?.gender) || null
    );

    if (data?.home?.city) {
      setFieldValue('subscriber_info.address', data?.home);
    }
  }
};

export const setInfo = ({ subscriberInfo, user = {}, setFieldValue, fetchUser }) => {
  const subscribeUser = user?.medical?.family_tree?.find(
    ({ relationship }) => relationship === subscriberInfo
  );
  const subscribeContact = user?.medical?.contacts?.contact?.find(
    ({ relationship }) => relationship === subscriberInfo
  );

  if (subscribeUser || subscribeContact) {
    const member = subscribeUser || subscribeContact;
    member?.user_id
      ? fetchUser(member.user_id)
      : getSubscriberInfo(member, setFieldValue);
  } else {
    getSubscriberInfo(null, setFieldValue);
  }
};
