export const styles = ({
  spacing,
  transitions
}) => ({
  form: {
    paddingBottom: spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },

  input: {
    lineHeight: '21px'
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '50px',
    opacity: 1,
    transition: transitions.create()
  },

  cancelButton: {
    marginRight: spacing(1.5)
  },

  toggleCheckedVisibilityButton: {
    marginRight: 'auto'
  }
});
