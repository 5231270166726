import { palette } from '../../../../themes/palette';

export const templatesTypes = {
  default: 'default',
  header: 'header',
  case: 'case',
  task: 'task'
};

export const templatesTypesNames = {
  [templatesTypes.default]: 'Default',
  [templatesTypes.header]: 'Header',
  [templatesTypes.case]: 'Case',
  [templatesTypes.task]: 'Task'
};

export const templatesTypesColors = {
  [templatesTypes.default]: palette().slategray,
  [templatesTypes.header]: palette().deeporange,
  [templatesTypes.case]: palette().darkgreen,
  [templatesTypes.task]: palette().lightgreen
};

export const templatesTypesOptions = [
  { value: templatesTypes.default, label: templatesTypesNames.default },
  { value: templatesTypes.case, label: templatesTypesNames.case },
  { value: templatesTypes.task, label: templatesTypesNames.task },
  { value: templatesTypes.header, label: templatesTypesNames.header }
];
