import { useContext } from 'react';
import { ModalsContext } from './ModalsProvider';

export const useModal = () => {
  const { addModal, deleteModal } = useContext(ModalsContext);

  return {
    openModal: (ModalComponent, props) => {
      return addModal({ ModalComponent, ...props });
    },

    closeModal: deleteModal
  };
};
