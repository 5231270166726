export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: ({ size = 300 }) => `repeat(auto-fill, minmax(${size}px, 1fr))`,
    gridGap: spacing(2),
    marginBottom: spacing(2),
    padding: spacing(2),
    paddingBottom: 0,

    [breakpoints.down('md')]: {
      padding: 0
    }
  }
});
