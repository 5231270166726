import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { makeStyles, IconButton, List, ListItem, ListItemText, Typography, Fab } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { useModal } from '../../../../../components/ModalsProvider';
import { openWidget, closeWidget, uploadFiles, uploadStatuses } from '../../../../../store/dashboard/files/uploads';
import { Popper } from '../../../../../components/Popper';
import { Loader } from '../../../../../components/Loader';
import { FileTypeIcon, FileUploadModal } from '../../../files-common';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const UploadsWidget = ({ isPersonalShared = false }) => {
  const classes = useStyles();
  const files = useSelector(({ uploads: { files } }) => files);
  const widgetIsOpened = useSelector(({ uploads: { widgetIsOpened } }) => widgetIsOpened);
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const totalProgress = (files.reduce((progress, file) => {
    return file.progress && file.progress < 100 ? file.progress + progress : progress;
  }, 0)) || 0;
  const inProgressCount = files.filter(({ progress }) => progress).length;
  const progress = (totalProgress / inProgressCount) || 0;

  const openUploadFilesModal = () => {
    openModal(FileUploadModal, {
      payload: {
        isPersonalShared
      }
    });
  };

  const handleRefreshUpload = (file) => () => {
    dispatch(uploadFiles({ files: [ file ], isPersonalShared }));
  };

  const handleWidgetClosed = () => () => {
    dispatch(closeWidget());
  };

  const secondaryTextRenderer = (file) => {
    const isError = file.uploadStatus === uploadStatuses.error;
    const isCanceled = file.uploadStatus === uploadStatuses.canceled;

    return (
      <Typography noWrap variant="body2" color={isError ? 'error' : 'textSecondary'}>
        {
          isError ? 'File type is not supported or the file is too large'
            : isCanceled ? 'Upload is canceled' : null
        }
      </Typography>
    );
  };

  useEffect(() => {
    if (files.length) {
      dispatch(openWidget());
    }
  }, [ files.length ]);

  return (
    <Popper
      isOpen={widgetIsOpened}
      onClosed={handleWidgetClosed}
      classes={{
        popper: classes.popper,
        triangle: classes.widgetArrow
      }}
      anchorRenderer={({ anchorRef, handleToggle }) => (
        <div ref={anchorRef}>
          <Loader
            loading={!!progress && (progress < 100)}
            surface={true} inset={false}
            variant="determinate"
            value={progress}
            render={() => (
              <IconButton
                color="inherit"
                onClick={handleToggle}
              >
                <CloudUploadIcon />
              </IconButton>
            )}
          />
        </div>
      )}
    >
      <div className={classes.root}>
        <header className={classes.header}>
          <Typography className={classes.header__title} variant="h2">Upload {files.length} files</Typography>

          <Fab className={classes.header__plusButton} onClick={openUploadFilesModal}>
            <AddIcon />
          </Fab>
        </header>

        <List className={classes.list}>
          {!files.length &&
            <Typography align="center">Upload list is empty</Typography>
          }

          {files.map((file) => (
            <ListItem key={file.localID} className={classes.file}>
              <FileTypeIcon file={file} className={classes.fileIcon}/>

              <ListItemText
                className={classes.fileName}
                primary={file.original_filename || file.name}
                secondary={secondaryTextRenderer(file)}
                primaryTypographyProps={{
                  noWrap: true,
                  title: file.original_filename,
                  component: 'p'
                }}
              />

              <div className={classes.fileStatus}>
                <Loader
                  surface
                  loading={file.uploadStatus === uploadStatuses.progress}
                  inset={false}
                  thickness={4}
                  variant="determinate"
                  value={file.progress}
                  render={() => (
                    <div className={classes.fileStatus__container}>
                      {file.uploadStatus === uploadStatuses.success ? (
                        <CheckIcon
                          color="primary"
                          className={classes.fileStatus__resultIcon}
                        />
                      ) : (file.uploadStatus === uploadStatuses.error ? (
                        <ErrorIcon
                          color="error"
                          className={classes.fileStatus__resultIcon}
                        />
                      ) : (file.uploadStatus === uploadStatuses.canceled ? (
                        <IconButton
                          size="small"
                          title="Refresh upload"
                          className={classes.fileStatus__toggleUploadButton}
                          onClick={handleRefreshUpload(file)}
                        >
                          <RefreshIcon />
                        </IconButton>
                      ) : (
                        <>
                          <IconButton
                            className={
                              cn(
                                classes.fileStatus__toggleUploadButton,
                                classes.fileStatus__cancelUploadButton
                              )
                            }
                            size="small"
                            title="Cancel upload"
                            onClick={file.cancel}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>

                          <Typography className={classes.fileStatus__progress} color="primary">
                            {file.progress}
                          </Typography>
                        </>
                      )))}
                    </div>
                  )}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    </Popper>
  );
};
