import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

export const MessageContext = createContext(null);

export const MessageContextProvider = ({
  preview = false,
  dense = false,
  index,
  children,
  message = {},
  messages = []
}) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const isCurrentUser = currentUser.id === message?.user?.id;
  const [ isDividerShown, setIsDividerShown ] = useState(true);
  const [ isAvatarShown, setIsAvatarShown ] = useState(true);
  const [ isDescriptionShown, setIsDescriptionShown ] = useState(true);
  const [ isForwarded, setIsForwarded ] = useState(false);

  useEffect(() => {
    if (_.has(message, [ 'parent', 'body' ])) {
      setIsForwarded(true);
    }
  }, [ message, messages ]);

  useEffect(() => {
    if (message?.created_at) {
      const isDividerShown = !messages[index - 1] ||
        !moment.unix(message.created_at).isSame(moment.unix(messages[index - 1].created_at), 'day');

      const isAvatarShown = !messages[index + 1] ||
        messages[index + 1]?.type === 'system' ||
        message?.user?.id !== messages[index + 1]?.user?.id ||
        isDividerShown;

      setIsDividerShown(isDividerShown);
      setIsAvatarShown(isAvatarShown);
      setIsDescriptionShown(isDividerShown && isAvatarShown);
    }
  }, [ message, messages ]);

  const providerValue = {
    dense,
    preview,
    message,
    isForwarded,
    currentUser,
    isCurrentUser,
    isDividerShown,
    isAvatarShown,
    isDescriptionShown
  };

  return (
    <MessageContext.Provider value={providerValue}>
      {children}
    </MessageContext.Provider>
  );
};
