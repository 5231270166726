import { Autocomplete } from '../../Autocomplete';

const confirmationOptions = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
];

export const transformConfirmationToOption = (value) => {
  return confirmationOptions.find((option) => value === option.value);
};

export const ConfirmationAutocomplete = (props) => {
  return (
    <Autocomplete
      disableSearch
      placeholder="Select option..."
      getOptionLabel={(option) => {
        return option?.label ?? confirmationOptions.find(({ value }) => option === value)?.label;
      }}
      getOptionValue={(option) => option?.value ?? option}
      getOptionSelected={(option, selected) => {
        return (option.value === selected?.value) || (option.value === selected);
      }}
      options={confirmationOptions}

      {...props}
    />
  );
};
