import { useContext } from 'react';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '../../../../../../components/Fab';
import { useModal } from '../../../../../../components/ModalsProvider';
import { TaskPreviewContext } from '../../../../../../components/tasks/TasksProvider';
import {
  ScheduleContext,
  CreateScheduleEventModal
} from '../../../../../../components/schedule-events';
import { apiModelsMap } from '../../../../../../dataMaps/apiModels';

export const AddEvent = () => {
  const { openModal } = useModal();
  const { selectedTask } = useContext(TaskPreviewContext);
  const { reloadEvents } = useContext(ScheduleContext);

  const addEvent = () => {
    openModal(CreateScheduleEventModal, {
      payload: {
        hideApplyFields: true,

        initialValues: {
          started_at: moment().unix(),
          finished_at: moment().unix() < selectedTask?.due_at ? selectedTask?.due_at : null,
          title: selectedTask?.name || null,
          description: selectedTask?.description || null,
          users: selectedTask?.users,
          parent_type: apiModelsMap.task,
          parent_id: selectedTask?.id
        }
      },
      onModalResolved: () => {
        reloadEvents();
      }
    });
  };

  return (
    <Fab
      variant="extended"
      color="primary"
      startIcon={<AddIcon />}
      onClick={addEvent}
    >
      Add Event
    </Fab>
  );
};
