import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../../dataMaps/apiModels';
import { UserPreviewModal } from '../../../../../../../components/users/UserPreviewModal';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../components/UserLink';
import { PreviewCompanyModal } from '../../../../../Contacts/Companies/PreviewCompanyModal';
import { minRowHeight, columnsWidths } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ payment }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { pathname } = useLocation();
  const history = useHistory();

  const getPaymentPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.payment(payment.id));
    }
  };

  const openUserPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: payment?.payer?.participant?.id }
    });
  };

  const openPreviewCompanyModal = () => {
    openModal(PreviewCompanyModal, {
      payload: {
        companyID: payment?.payer?.participant_id
      }
    });
  };

  return (
    <ListRow
      selected={pathname === itemsRoutesByModels.payment(payment.id)}
      className={classes.root}
      title="Open page"
      minHeight={minRowHeight}
      onClick={getPaymentPage}
    >
      <ListRowCell grow flexBasis={columnsWidths.payer}>
        {payment?.payer?.participant_type === 'company' ?
          <CompanyLink
            noWrap
            size="sm"
            company={payment?.payer?.participant}
            onClick={stopPropagation(openPreviewCompanyModal)}
          />
          :
          <UserLink
            noWrap
            size="sm"
            user={payment?.payer?.participant}
            onClick={stopPropagation(openUserPreview)}
          />
        }
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.number}>
        <Typography noWrap title={get(payment, 'invoice.number', get(payment, 'causer_invoice.number', '-'))}>
          {get(payment, 'invoice.number', get(payment, 'causer_invoice.number', '-'))}
        </Typography>
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.due_date}>
        {!!payment?.invoice?.date &&
          <Typography
            noWrap
            className={classes.dueDate}
            title={moment(payment.invoice.date).format('L')}
          >
            {moment(payment.invoice.date).format('L')}
          </Typography>
        }

        {!get(payment, 'invoice.date') && !!get(payment, 'causer_invoice.date') &&
          <Typography
            noWrap
            className={classes.dueDate}
            title={moment(payment.causer_invoice.date).format('L')}
          >
            {moment(payment.causer_invoice.date).format('L')}
          </Typography>
        }
      </ListRowCell>
    </ListRow>
  );
};
