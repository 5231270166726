import { isString } from 'lodash';
import { measureTypes } from '../../../../helpers/measureDataMap';
import { Yup } from '../../../../utils/validation';

export const measurementsValidationSchema = Yup.object().shape({
  weight: Yup.number().positive().nullable().max(750).required(),
  height: Yup.mixed().nullable().when('height_unit', {
    is: (value) => value === measureTypes.feet,
    then: Yup.mixed().nullable().required(),
    otherwise: Yup.number().positive().nullable().required()
  }).test('height', 'Max ft is 9 and max inches is 11', (val) => {
    if (val && isString(val) && !val.includes('\'')) {
      const inch = val.substring(1, 3);

      return +inch < 12;
    }

    return true;
  }),
  bmi: Yup.number().nullable().max(220),
  height_unit: Yup.string().nullable(),
  weight_unit: Yup.string().nullable(),
  temperature: Yup.number().positive().nullable().min(60).max(120),
  blood_type: Yup.string().nullable().max(10),
  heart_rate: Yup.number().integer().positive().nullable().min(30).max(200),
  systolic_blood_pressure: Yup.number().integer().positive().nullable().min(30).max(300)
    .when('diastolic_blood_pressure', (diastolicBloodPressure, schema) => (
      diastolicBloodPressure ? schema.required() : schema
    )),
  diastolic_blood_pressure: Yup.number().integer().positive().nullable().min(30).max(300)
    .when('systolic_blood_pressure', (systolicBloodPressure, schema) => (
      systolicBloodPressure ? schema.required() : schema
    ))
}, [ 'systolic_blood_pressure', 'diastolic_blood_pressure' ]);
