import { useRef, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { saveFilter } from '../../../../store/lastFilters';
import { IconComponent } from '../../../../components/saved-filters';
import { FiltersBar as FiltersBarComponent } from '../../../../components/FiltersBar';
import { KeyboardDatePicker, PhoneField } from '../../../../components/FormField';
import { FaxContext } from '../FaxProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { CardContent } from './CardContent';
import { List } from './List';

const initialValues = {
  date: null,
  to: null
};

const MODAL_WIDTH = 420;

export const FiltersBar = ({ filterKey }) => {
  const theme = useTheme();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { applyFilter } = useContext(FaxContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState(initialValues);

  const handleTextFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleDatePickerChange = (name) => (date) => {
    applyFilter(({ [name]: date }));
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);

    applyFilter(filter);
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    applyFilter(lastFilters);

    formikRef.current.setValues({
      ...relationsForFilter,
      ...lastFilters
    });
  }, []);

  return (
    <FiltersBarComponent
      disableTopPaddings
      isResetForm
      enableSettings
      formikRef={formikRef}
      border={0}
      initialValues={relationsForFilter}
      onReset={() => applyFilter(initialValues)}
      filterKey={filterKey}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.to,
          label: filterFieldsLabels[filterFieldsMap.to],
          field: <Box minWidth={isMobile ? '100%' : 250}>
            <PhoneField
              label="Recipient's fax"
              name="to"
              onChange={handleTextFieldChange('to')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.date,
          label: filterFieldsLabels[filterFieldsMap.date],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              outputFormat="YYYY-MM-DD"
              name="date"
              label="Date"
              onChange={handleDatePickerChange('date')}
            />
          </Box>
        }
      ]}
    />
  );
};
