import React, { useContext, useEffect, useRef, useState } from 'react';
import { isEqual, omit } from 'lodash';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import * as statisticsApi from '../../../../../../../api/billing';
import { api } from '../../../../../../../api';
import { CustomScrollbars } from '../../../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { initialState, StatisticsFilterContext } from '../../../StatisticsFilterProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 54;
export const columnsWidths = {
  client: 200,
  billable: 80,
  unbillable: 80,
  total: 80
};

export const MainContent = ({ setFilterType, setIsFetchedData }) => {
  const [ tracks, setTracks ] = useState([]);
  const [ pagination, setPagination ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);
  const [ isFetching, setIsFetching ] = useState(false);
  const [ filter, setFilter ] = useState(initialState.filter);
  const { filter: commonFilter } = useContext(StatisticsFilterContext);
  const { page, per_page } = filter;
  const { last_page, total } = pagination;
  const scrollElementRef = useRef();
  const cancelFetch = useRef(() => {});

  const fetchStatistics = (newFilter = {}) => {
    cancelFetch.current();
    setIsFetching(true);
    setIsFetched(false);

    statisticsApi.fetchTimeTracksStatistics({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(({ data, pagination }) => {
      setFilter((filter) => ({ ...filter, page: pagination.page, per_page: pagination.per_page }));
      setPagination(pagination);
      setTracks((tracks) => tracks.concat(data));
      setIsFetching(false);
      setIsFetched(true);
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page) {
      fetchStatistics({ page: filter.page + 1 });
    }
  };

  const resetStatistics = (newFilter = {}) => {
    setTracks([]);
    fetchStatistics({ page: 1, ...newFilter });
  };

  useEffect(() => {
    const newFilter = { ...filter, ...commonFilter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      resetStatistics(newFilter);
    }
  }, [ commonFilter ]);

  useEffect(() => {
    resetStatistics();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      <TableHeader />

      <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
        <Loader p={2} loading={!isFetched} render={
          () => !total ? (
            <Box p={2}>
              <Typography align="center">No tracks found</Typography>
            </Box>
          ) : (
            <MuiList disablePadding>
              <InfiniteListLoader
                items={tracks}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={{ per_page, last_page, page }}
                RowComponent={Row}
                RowProps={{ setFilterType, setIsFetchedData }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      </CustomScrollbars>
    </Box>
  );
};
