export const routes = {
  general: '/cases/:caseID',
  medical_claim: '/cases/:caseID/medical-claim',
  files: '/cases/:caseID/files',
  schedule: '/cases/:caseID/schedule',
  reminders: '/cases/:caseID/reminders',
  appointments: '/cases/:caseID/appointments',
  emails: '/cases/:caseID/emails',
  contacts: '/cases/:caseID/contacts',
  billing: '/cases/:caseID/billing',
  activity: '/cases/:caseID/activity',
  tasks: '/cases/:caseID/tasks',
  comments: '/cases/:caseID/comments',
  timeTracking: '/cases/:caseID/time-tracking',
  caseTransfer: '/cases/:caseID/case-transfer',
  claims: '/cases/:caseID/claims',
  discovery: '/cases/:caseID/discovery',
  litigation: '/cases/:caseID/litigation',
  negotiation: '/cases/:caseID/negotiation',
  callReports: '/cases/:caseID/call-reports'
};
