import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Box, FormControlLabel, useMediaQuery, useTheme } from '@material-ui/core';
import { UserContext } from '../../../../app/Dashboard/Members/User/UserProvider';
import { saveFilter } from '../../../../store/lastFilters';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import {
  TextField,
  DatePicker,
  TaskTypesSelect,
  TaskStatusesSelect,
  TaskPrioritySelect,
  Switch
} from '../../../FormField';
import { FiltersBar as FiltersBarComponent } from '../../../FiltersBar';
import { IconComponent } from '../../../saved-filters';
import { UsersSelect } from '../../../users';
import { TasksFilterContext } from '../../TasksProvider';
import { transformRelationsForFilterToOptions } from './utils';
import { fieldsKeysMap, filterLabels } from './filterKeysMap';
import { initialValues } from './initialValues';
import { columnsWidths, List } from './List';

const MODAL_WIDTH = 1380;

export const FiltersBar = ({ filterKey, hiddenFilterFields = [] }) => {
  const theme = useTheme();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const userContext = useContext(UserContext);
  const profile = useSelector(({ profile }) => profile);
  const user = userContext ? userContext.user : profile.user;
  const [ isUserTasksShown, setIsUserTasksShown ] = useState(true);
  const { applyFilter, relationsForFilter, setRelationsForFilter } = useContext(TasksFilterContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const modalWidth = hiddenFilterFields.includes(fieldsKeysMap.cases) ?
    MODAL_WIDTH - columnsWidths[fieldsKeysMap.cases] : MODAL_WIDTH;
  const isUserAdvocate = user.role === rolesMap.advocate;
  const isUserAdmin = user.role === rolesMap.admin;

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleMultiSelectChange = (name) => (options) => {
    applyFilter({ [name]: options?.map(({ value }) => value) });
    setRelationsForFilter((state) => ({ ...state, [name]: options?.map((option) => option?.data) }));
  };

  const handleUsersMultiSelect = (name) => (options) => {
    const users = options?.length ? options.map(({ id }) => id) : null;

    applyFilter({ [name]: users ? users : !isUserTasksShown || isUserAdvocate ? [ user.id ] : users });
    setRelationsForFilter((state) => ({ ...state, [name]: options }));
  };

  const handleTaskTypesSelectChange = (types) => {
    const typeIDs = types?.map(({ id }) => id) || null;

    applyFilter({ types: typeIDs });
    setRelationsForFilter((state) => ({ ...state, types: types || null }));
  };

  const handlePrioritiesChange = (priorities) => {
    applyFilter({ priorities: priorities?.map(({ value }) => value) });
    setRelationsForFilter((state) => ({ ...state, priorities }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(transformRelationsForFilterToOptions(filter));
  };

  const handleFilterTasksChange = () => {
    setIsUserTasksShown((showUserTasks) => !showUserTasks);
    applyFilter({ users: isUserTasksShown ? [ user.id ] : null });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }

    applyFilter({ users: isUserAdmin ? [] : [ user.id ] });
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      onReset={() => {
        applySavedFilter(initialValues);
      }}
      iconComponent={(
        <IconComponent
          modalWidth={modalWidth}
          filterKey={filterKey}
          ListComponent={List}
          relationsForFilter={relationsForFilter}
          hiddenFields={hiddenFilterFields}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      hiddenFields={hiddenFilterFields}
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.search,
          label: filterLabels[fieldsKeysMap.search],
          field: <TextField
            name="search"
            label="Search"
            placeholder="Search..."
            onChange={handleFieldChange('search')}
          />
        },
        {
          fieldKey: fieldsKeysMap.createdFrom,
          label: filterLabels[fieldsKeysMap.createdFrom],
          field: <Box minWidth={isMobile ? '100%' : 140}>
            <DatePicker
              clearable
              zeroMinWidth
              name="created_from"
              label="Create date from"
              placeholder="Date from"
              onChange={handleDatePickerChange('created_from')}
            />
          </Box>
        },
        {
          fieldKey: fieldsKeysMap.createdTo,
          label: filterLabels[fieldsKeysMap.createdTo],
          field: <Box minWidth={isMobile ? '100%' : 140}>
            <DatePicker
              clearable
              zeroMinWidth
              name="created_to"
              label="Create date to"
              placeholder="Date to"
              onChange={handleDatePickerChange('created_from')}
            />
          </Box>
        },
        {
          fieldKey: fieldsKeysMap.dueFrom,
          label: filterLabels[fieldsKeysMap.dueFrom],
          field: <Box minWidth={isMobile ? '100%' : 140}>
            <DatePicker
              clearable
              zeroMinWidth
              name="due_from"
              label="Due date from"
              placeholder="Date from"
              onChange={handleDatePickerChange('due_from')}
            />
          </Box>
        },
        {
          fieldKey: fieldsKeysMap.dueTo,
          label: filterLabels[fieldsKeysMap.dueTo],
          field: <Box minWidth={isMobile ? '100%' : 140}>
            <DatePicker
              clearable
              zeroMinWidth
              name="due_to"
              label="Due date to"
              placeholder="Date to"
              onChange={handleDatePickerChange('due_to')}
            />
          </Box>
        },
        {
          fieldKey: fieldsKeysMap.types,
          label: filterLabels[fieldsKeysMap.types],
          field: <TaskTypesSelect
            multiple
            name="types"
            label="Types"
            params={{
              is_active: null
            }}
            onChange={handleTaskTypesSelectChange}
          />
        },
        {
          fieldKey: fieldsKeysMap.statuses,
          label: filterLabels[fieldsKeysMap.statuses],
          field: <TaskStatusesSelect
            isMulti
            name="statuses"
            label="Statuses"
            onChange={handleMultiSelectChange('statuses')}
          />
        },
        {
          fieldKey: fieldsKeysMap.users,
          label: filterLabels[fieldsKeysMap.users],
          field: <UsersSelect
            multiple
            name="users"
            label="Users"
            onChange={handleUsersMultiSelect('users')}
          />
        },
        {
          fieldKey: fieldsKeysMap.priority,
          label: filterLabels[fieldsKeysMap.priority],
          field: <TaskPrioritySelect
            multiple
            name="priorities"
            label="Priorities"
            onChange={handlePrioritiesChange}
          />
        },
        {
          fieldKey: fieldsKeysMap.tasks,
          label: filterLabels[fieldsKeysMap.tasks],
          field: <FormControlLabel
            label="Show my tasks"
            control={
              <Switch
                name="tasks"
                checked={!isUserTasksShown}
                color="primary"
                onChange={handleFilterTasksChange}
              />
            }
          />
        }
      ]}
    />
  );
};
