import { Box, Grid, Typography } from '@material-ui/core';
import { Body } from './Body';

export const Measurements = ({ disableTitle = false, birthday, measurements }) => {
  return (
    <>
      {!disableTitle && (
        <Grid item xs={12}>
          <Box color="info.main">
            <Typography variant="h4" color="inherit">Measurements</Typography>
          </Box>
        </Grid>
      )}

      {measurements ? (
        <Body birthday={birthday} measurements={measurements} />
      ) : (
        <Grid item xs={12}>
          <Typography>No measurements</Typography>
        </Grid>
      )}
    </>
  );
};
