export const styles = ({
  spacing,
  palette: { grey, ...palette }
}) => ({
  root: {
    paddingLeft: spacing(),
    paddingRight: spacing(),
    background: palette.common.white
  },

  dragHandle: {
    cursor: 'move'
  },

  dragHandleContent: {
    display: 'flex',
    alignItems: 'center'
  },

  dragHandleIcon: {
    marginLeft: -spacing(),
    marginRight: spacing(2)
  }
});
