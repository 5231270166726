import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { CurrencyFormat } from '../../CurrencyFormat';
import { Date } from '../../Date';

export const UserInfo = ({ user }) => {
  return (
    <Grid container spacing={1} alignItems="center" component={Box} pb={2}>
      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Position</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="h5">
          {user?.work?.position || '-'}
        </Typography>
      </Grid>

      {hasRole(rolesMap.admin, rolesMap.supervisor) &&
        <>
          <Grid item sm={3} xs={6}>
            <Typography color="textSecondary">Rate</Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography variant="h5">
              <CurrencyFormat value={user?.rate} />
            </Typography>
          </Grid>
        </>
      }

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Department</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="h5">
          {user?.work?.department || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">Start of Work Date</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date variant="h5" date={user?.work?.start_date} />
      </Grid>

      {!hasRole(rolesMap.client, rolesMap.patient) && (
        <Hidden xsDown>
          <Grid item xs={6} />
        </Hidden>
      )}

      <Grid item sm={3} xs={6}>
        <Typography color="textSecondary">End of Work Date</Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date variant="h5" date={user?.work?.end_date} />
      </Grid>
    </Grid>
  );
};
