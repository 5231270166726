import { useContext, useEffect } from 'react';
import { FaxContext } from '../FaxProvider';

export const ReceivedFaxes = ({ children }) => {
  const { resetFaxes } = useContext(FaxContext);

  useEffect(() => {
    resetFaxes({ destination: 'income' });
  }, []);

  return <>{children}</>;
};
