export const styles = ({ palette }) => ({
  root: {
    border: `1px solid ${palette.divider}`,
    display: 'flex',
    flexDirection: 'column'
  },

  mainContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },

  list: {
    flexGrow: 1
  }
});
