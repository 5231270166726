import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  ModifiersSelect,
  transformModifiersCodesToOption,
  CPTCodesSelect,
  IcdCodesSelect,
  PosCodesSelect,
  TextField,
  Autocomplete,
  CurrencyField,
  LevelsSelect
} from '../../FormField';
import { sidesOptions } from './sidesOptions';

export const ProcedureForm = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleCptChange = (cpt) => {
    setFieldValue('cpt', cpt);

    if (cpt) {
      setFieldValue(
        'modifiers',
        cpt?.fee_schedule?.modifier
          ? [ transformModifiersCodesToOption(cpt?.fee_schedule?.modifier) ]
          : null
      );
      setFieldValue('price', cpt?.fee_schedule?.charge_amount || null);
      setFieldValue('unit', cpt?.fee_schedule?.default_units || 1);
    }
  };

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item sm={3} xs={12}>
        <Autocomplete
          disableClearable
          name="side"
          label="Side"
          options={sidesOptions}
          margin="dense"
        />
      </Grid>

      <Grid item sm={9} xs={12}>
        <LevelsSelect
          isMulti
          isClearable
          name="levels"
          label="Levels"
          TextFieldProps={{ margin: 'dense' }}
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        <CPTCodesSelect
          required
          name="cpt_id"
          label="Procedure Code #"
          margin="dense"
          onChange={handleCptChange}
        />
      </Grid>

      <Grid item sm={9} xs={12}>
        {!!values.cpt &&
          <Box mt={1}>
            <Typography variant="body2" color="textSecondary">
              Procedure description
            </Typography>

            <Typography style={{ textTransform: 'uppercase' }}>
              {values.cpt?.description}
            </Typography>
          </Box>
        }
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          disableUnderline
          rows={2}
          rowsMax={8}
          variant="filled"
          name="surgery"
          label="Surgery"
          margin="dense"
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        <PosCodesSelect
          required
          name="pos_id"
          label="POS"
          margin="dense"
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        <TextField
          type="number"
          name="unit"
          label="Unit"
          margin="dense"
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        <CurrencyField
          required
          name="price"
          label="Price"
          margin="dense"
        />
      </Grid>

      <Grid item xs={12}>
        <ModifiersSelect
          isMulti
          isClearable
          name="modifiers"
          label="Modifiers"
          TextFieldProps={{ margin: 'dense' }}
        />
      </Grid>

      <Grid item xs={12}>
        <IcdCodesSelect
          multiple
          name="icd"
          label="DX Sequence/Diagnosis"
          margin="dense"
        />
      </Grid>
    </Grid>
  );
};
