export const filterFieldsMap = {
  search: 'search',
  providers: 'providers',
  appointmentTypes: 'appointment_types',
  status: 'is_active',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.search]: 'Search',
  [filterFieldsMap.providers]: 'Providers',
  [filterFieldsMap.appointmentTypes]: 'Appointment types',
  [filterFieldsMap.status]: 'Status',
  [filterFieldsMap.orderBy]: 'Order by'
};
