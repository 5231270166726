import * as cardsTypes from '../Layouts';
import { AllSchedules } from './AllSchedules';
import { MedicalInfo } from './MedicalInfo';
import { Files } from './Files';
import { WorkInfo } from './WorkInfo';

export const widgetsMap = {
  [cardsTypes.WORK_INFO]: {
    title: 'Work Info',
    component: WorkInfo
  },

  [cardsTypes.ALL_SCHEDULES]: {
    title: 'All Schedules',
    component: AllSchedules
  },

  [cardsTypes.MEDICAL_INFO]: {
    title: 'Medical Info',
    component: MedicalInfo
  },

  [cardsTypes.FILES]: {
    title: 'Files',
    component: Files
  }
};
