export const styles = ({ spacing }) => ({
  marginNormal: {
    marginTop: spacing(2),
    marginBottom: spacing()
  },

  marginDense: {
    marginTop: spacing(),
    marginBottom: spacing(0.5)
  }
});
