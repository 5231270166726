import { Box, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { UserLink } from '../../../UserLink';

export const PatientsMenu = ({ patients, updateFilter, onChangeAnchorEl }) => {
  const handleFilterChange = (patientItem) => () => {
    updateFilter('patient_id', patientItem);
    onChangeAnchorEl({});
  };

  return (
    <Paper component={Box} boxShadow={1} overflow="hidden" minWidth={300} height={240} borderRadius={4}>
      <List>
        {patients.map((patientItem) => (
          <ListItem
            button
            key={patientItem.id}
            onClick={handleFilterChange(patientItem)}
          >
            <ListItemText
              primary={
                <UserLink user={patientItem} />
              }
              primaryTypographyProps={{ noWrap: true }}
              secondaryTypographyProps={{ noWrap: true }}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
