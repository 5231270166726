import cn from 'classnames';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useContext } from 'react';
import { UserLink } from '../../../../../../../components/UserLink';
import { DELETED_USER, MessageContext } from '../../../../../../../components/chat';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MessageDescription = () => {
  const classes = useStyles();
  const { isCurrentUser, message } = useContext(MessageContext);
  const isEdited = message.created_at !== message.updated_at;

  return (
    <div className={cn(classes.root, { [classes.rightAlign]: isCurrentUser })}>
      {!isCurrentUser && !message.user ? (
        <Typography variant="body2">{DELETED_USER}</Typography>
      ) : (
        <div className={classes.author}>
          <UserLink noAvatar user={message.user} variant="body2"  />
        </div>
      )}

      <div className={isCurrentUser ? classes.time : ''}>
        <div className={classes.edited}>
          <Typography variant="body2">
            {(isEdited ? 'edited ' : '') + moment.unix(message.created_at).format('LT')}
          </Typography>
        </div>

        {isCurrentUser &&
          <DoneAllIcon
            color={message.status === 'seen' ? 'primary' : 'action'}
            fontSize="small"
          />
        }
      </div>
    </div>
  );
};
