import { Fragment } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { statusTypeOptions } from '../../../app/Dashboard/ProfilePage/insurance';
import { formStatusesMap } from '../../medical/forms';
import { CompanyLink } from '../../CompanyLink';
import { SvgIcon } from '../../SvgIcon';
import { Avatar } from '../../Avatar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ShortInsuranceInfo = ({ showPreview = false, insurance, className, ...props }) => {
  const classes = useStyles();
  const statusType = statusTypeOptions.find(({ value }) => {
    return value === insurance?.status_type;
  })?.label || insurance?.status_type;
  const isPrimary = insurance?.status_type === formStatusesMap.primary;
  const expirationDay = insurance?.expiration_day
    ? moment.unix(insurance.expiration_day).add(1, 'd').startOf('day')
    : null;
  const Wrapper = showPreview ? Box : Fragment;
  const wrapperProps = showPreview ? {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap'
  } : {};

  return !!insurance && (
    <Typography noWrap component="span" className={cn(classes.root, className)} {...props}>
      <Wrapper {...wrapperProps}>
        {insurance.is_main &&
          <SvgIcon fontSize="small" color="success" className={classes.checkIcon}>
            <CheckCircleIcon />
          </SvgIcon>
        }

        {insurance?.type_insurance ? `${insurance?.type_insurance} / ` : ''}
        {statusType ? `${statusType} / ` : ''}
        {isPrimary && insurance.insuranceId ? `Ins.ID: ${insurance.insuranceId || '-'} / ` : ''}
        {insurance.financial_class ? `${insurance.financial_class.code} / ` : ''}
        {insurance.expiration_day && expirationDay ? `Exp: ${expirationDay.format('MM/DD/YYYY')} / ` : ''}

        {!showPreview ? (insurance.insurance_company &&
          <>
            <Avatar
              component="span"
              size="xs"
              alt={insurance.insurance_company.name[0]}
              className={classes.avatar}
            />

            {`${insurance?.insurance_company?.name} `}
          </>
        ) : (
          <CompanyLink
            variant="h5"
            company={insurance?.insurance_company}
          />
        )}

        {isPrimary ? `/ ${insurance?.subscriber_info?.subscriber}` : ''}
      </Wrapper>
    </Typography>
  );
};
