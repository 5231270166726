import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';

export const Chart = ({ data, labels  }) => {
  const { palette } = useTheme();
  
  const getChartData = () => ({
    labels: labels.map((item) => (
      item.split('_').map((i) => i.charAt(0).toUpperCase() + i.slice(1)).join(' ')
    )),
    datasets: [
      {
        label: 'Task Statistics',
        backgroundColor: [
          palette.primary.main,
          '#00D084',
          palette.deeporange
        ],
        hoverBackgroundColor: [
          palette.primary.main,
          '#00D084',
          palette.deeporange
        ],
        data
      }
    ]
  });

  const chartOptions = {
    maintainAspectRatio: false,
    legend: {
      position: 'left',
      labels: {
        boxWidth: 12
      }
    }
  };

  return (
    <Doughnut options={chartOptions} data={getChartData()} />
  );
};
