import { api } from '../api';

export const fetchTranscribes = (config = {}) => {
  return api.get('/transcribes', {
    cancelToken: new api.CancelToken((cancel) => fetchTranscribes.cancel = cancel),
    ...config
  }).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const uploadTranscribe = (data, config) => {
  return api.post('/transcribes', data, config).then(({ data: { data } }) => {
    return data;
  }).catch((data) => {
    throw data;
  });
};

export const deleteTranscribe = (id) => {
  return api.delete(`/transcribes/${id}`).then(({ data }) => data);
};
