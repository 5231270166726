import { Box, Chip, Grid } from '@material-ui/core';
import * as apptTypesApi from '../../../../api/codes/appointments/appointment-types';
import { ColorPreview } from '../../../ColorPreview';
import { Autocomplete } from '../../Autocomplete';

const fetchApptTypes = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return apptTypesApi.getApptTypeById({
    params: {
      search,
      page: page + 1,
      is_active: 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => (
  <Grid container wrap="nowrap" spacing={1} alignItems="center">
    <Grid item>
      <ColorPreview color={option.color} />
    </Grid>

    <Grid item>
      {option?.description ? `${option.code} - ${option.description}` : option.code}
    </Grid>
  </Grid>
);

const getOptionValue = (option) => option?.id;

const getOptionSelected = (option, value) => option.id === value?.id;

const getOptionLabel = (option) => {
  return option?.description ? `${option.code} - ${option.description}` : option?.code;
};

export const ApptTypesSelect = ({ multiple = false, params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      multiple={multiple}
      name="appointment_type_id"
      label="Appointment type"
      placeholder="Search appt. type..."
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      onNeedFetch={fetchApptTypes(params)}
      getInputProps={(value) => !(!multiple && value) ? null : ({
        startAdornment: (!multiple && value) && (
          <ColorPreview color={value.color} />
        )
      })}
      renderTags={(types, getTagProps) => (
        types.map((type, index) => (
          <Chip
            variant="outlined"
            size={props.size}
            label={getOptionLabel(type)}
            icon={
              <Box display="flex" alignItems="center" ml={1.5}>
                <ColorPreview color={type.color} />
              </Box>
            }

            {...getTagProps({ index })}
          />
        ))
      )}

      {...props}
    />
  );
};
