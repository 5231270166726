import { useFormikContext } from 'formik';
import { Box, Grid, Collapse, AppBar } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { TextField, PhoneField } from '../../../../../../components/FormField';
import { EmailsSelect } from '../../../../../../components/FormField/selects/EmailsSelect';
import { Tab, Tabs } from '../../../../../../components/Tabs';
import { UsersSelect } from '../../../../../../components/users';

const tabsMap = {
  email: 'email',
  fax: 'fax'
};

export const Form = ({ isModalEntered }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ activeTab, setActiveTab ] = useState(tabsMap.email);
  const { setFieldValue } = useFormikContext();

  const handleActiveTabChange = (event, activeTab) => {
    setActiveTab(activeTab);
  };

  const handleUserChange = (user) => {
    if (user?.email) {
      setFieldValue('to', [ user ] || null);
    } else {
      enqueueSnackbar('User does not have an email', { variant: 'error' });
    }
  };

  return isModalEntered && (
    <>
      <AppBar position="static">
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          onChange={handleActiveTabChange}
        >
          <Tab value={tabsMap.email} label="Email" />
          <Tab value={tabsMap.fax} label="Fax" />
        </Tabs>
      </AppBar>

      <Box p={2}>
        <UsersSelect
          name="user"
          label="Name User / Name Company"
          margin="dense"
          onChange={handleUserChange}
        />

        <Collapse in={activeTab === tabsMap.email}>
          <EmailsSelect
            isCreatable
            multiple
            name="to"
            label="Email"
            margin="dense"
          />
        </Collapse>

        <Collapse in={activeTab === tabsMap.fax}>
          <Grid container spacing={2}>
            <Grid item xs>
              <PhoneField
                zeroMinWidth
                name="fax"
                label="Fax"
                margin="dense"
              />
            </Grid>

            <Grid item xs>
              <TextField
                name="faxDomain"
                label="Fax domain"
                margin="dense"
              />
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    </>
  );
};
