import { SvgIcon } from '@material-ui/core';
import {
  mdiBellCogOutline,
  mdiCogs
} from '@mdi/js';
import { Icon } from '@mdi/react';

export const caseRemindersMenuList = [
  {
    name: 'Case Reminders',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiBellCogOutline} /></SvgIcon>,
    path: '/case-reminders',
    routes: [
      {
        exact: true,
        name: 'Case Reminders',
        icon: (props) => <SvgIcon {...props}><Icon path={mdiBellCogOutline}/></SvgIcon>,
        path: '/case-reminders'
      },
      {
        name: 'Settings',
        icon: (props) => (
          <SvgIcon {...props}>
            <Icon path={mdiCogs} />
          </SvgIcon>
        ),
        path: '/case-reminders/settings'
      }
    ]
  }
];
