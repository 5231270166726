import { Grid, Typography } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../../../ReadonlyCheckbox';
import { ListRow, ListRowCell } from '../../../../../../ListRow';
import { UserLink } from '../../../../../../UserLink';
import { Contact } from '../../../../../../Contact';
import { UserSSN } from '../../../../../../users';
import { columnsWidths } from '../List';

export const Row = ({ user, onUserSelect = () => {} }) => {
  const handleUserClick = () => {
    onUserSelect(user);
  };

  return (
    <ListRow button onClick={handleUserClick}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <UserLink
          noWrap
          user={user}
          size="md"
          variant="h5"
        >
          <UserSSN
            user={user}
            TypographyProps={{
              variant: 'caption',
              color: 'textSecondary'
            }}
          />
        </UserLink>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.medRecNo}>
        <Typography noWrap title={user.med_rec}>
          {user.medical?.med_rec || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.phone}>
        <Contact
          noWrap
          color="primary"
          type="tel"
          contact={user.phone || user.mobile_phone_number || user.phone_number}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.email}>
        <Contact
          noWrap
          color="primary"
          type="mailto"
          contact={user.email}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.coincidence}>
        <Grid container spacing={1} wrap="wrap">
          <Grid item>
            <ReadonlyCheckbox title="Name" checked={user.check_fields?.name} />
          </Grid>

          <Grid item>
            <ReadonlyCheckbox title="Email" checked={user.check_fields?.email} />
          </Grid>

          <Grid item>
            <ReadonlyCheckbox title="Birthday" checked={user.check_fields?.birthday} />
          </Grid>

          <Grid item>
            <ReadonlyCheckbox title="Phone" checked={user.check_fields?.phone} />
          </Grid>

          <Grid item>
            <ReadonlyCheckbox title="SSN" checked={user.check_fields?.ssn} />
          </Grid>
        </Grid>
      </ListRowCell>
    </ListRow>
  );
};
