import moment from 'moment';
import { Yup } from '../../../../utils/validation';

export const covidValidationSchema = Yup.object().shape({
  patient_sign_id: Yup.mixed().nullable(),
  staff_sign_id: Yup.mixed().nullable(),
  first_name: Yup.string().nullable().required(),
  last_name: Yup.string().nullable().required(),
  dob: Yup.string().nullable(),
  date: Yup.mixed().when('isStaff', {
    is: (value) => value,
    then: Yup.date().format('X').nullable().max(moment().add(1, 'day'), 'Date must be earlier than today')
  }).nullable(),
  is_cough: Yup.string().nullable(),
  if_yes: Yup.string().nullable(),
  is_difficulty_breathing: Yup.string().nullable(),
  is_chills: Yup.string().nullable(),
  is_fever: Yup.string().nullable(),
  is_traveled: Yup.string().nullable(),
  description: Yup.string().nullable().max(1000),
  is_airport: Yup.string().nullable(),
  temperature: Yup.number().when('isStaff', {
    is: (value) => value,
    then: Yup.number().positive().nullable().min(60).notRequired(),
    otherwise: Yup.number().nullable()
  })
});

