import moment from 'moment';
import cn from 'classnames';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { getUserFullName, getUserNameAbbr } from '../../../../../../../../helpers/users';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { Tooltip, TooltipTitle } from '../../../../../../../../components/Tooltip';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../../../components/users';
import { Avatar } from '../../../../../../../../components/Avatar';
import { Badge } from '../../../../../../../../components/Badge';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const UserAvatar = ({ item = {} }) => {
  const classes = useStyles();
  const { openModal } = useModal();

  const openUserPreview = () => {
    if (item?.user?.id) {
      openModal(UserPreviewModal, {
        payload: { id: item?.user?.id }
      });
    }
  };

  return (
    <Tooltip
      arrow
      title={
        <TooltipTitle>
          <Grid container spacing={1} component={Box} p={1} color="common.white">
            <Grid item xs={4}>
              <Typography>User: </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h5" color="inherit">
                {getUserFullName(item?.user)}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography>User role: </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h5" color="inherit">
                {item?.role}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography>Signed: </Typography>
            </Grid>

            <Grid item xs={8}>
              {item?.signed_on
                ? <Typography>Signed on {moment.unix(item?.signed_on).format('l')}</Typography>
                : <Typography>Need to sign</Typography>
              }
            </Grid>
          </Grid>
        </TooltipTitle>
      }
    >
      <Badge
        overlap="circle"
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        badgeContent={
          item?.signed_on
            ? <CheckCircleIcon className={classes.badgeIcon}/>
            : <RemoveCircleIcon className={cn(classes.badgeIcon, classes.badgeIcon__red)}/>
        }
      >
        <Avatar
          title={getUserFullName(item?.user)}
          src={item?.user?.avatar}
          alt={getUserNameAbbr(item?.user)}
          onClick={stopPropagation(openUserPreview)}
        />
      </Badge>
    </Tooltip>
  );
};
