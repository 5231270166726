import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isEqual, merge, omit } from 'lodash';
import { useHistory } from 'react-router-dom';
import { usePrevious } from '../../../../../helpers/hooks';
import { setInvoicesLastGlobalAction } from '../../../../../store/globalActions';
import { api } from '../../../../../api';
import * as invoicesApi from '../../../../../api/billing';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { orderByMap } from './orderByOptions';
import { initialState as initialStateConfig } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const InvoicesContext = createContext(null);

export const InvoicesContextProvider = ({ children, initialState = {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const invoicesLastGlobalAction = useSelector(({ globalActions }) => globalActions.invoicesLastGlobalAction);
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialStateConfig, initialState));
  const {
    invoices,
    pagination,
    isFetching,
    isFetched,
    selectedInvoicesIDs,
    filter
  } = state;
  const prevFilter = usePrevious(filter);
  const [ relationsForFilter, setRelationsForFilter ] = useState({
    order_by: orderByMap.updated_at
  });
  const cancelFetch = useRef(() => {});

  const changeRelationsForFilter = (data) => {
    setRelationsForFilter((state) => ({ ...state, ...data }));
  };

  const fetchInvoices = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.INVOICES_FETCH_REQUEST });

    invoicesApi.fetchInvoices({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.INVOICES_FETCH_SUCCESS, payload: data });
    });
  };

  const deleteInvoices = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        invoicesApi.deleteInvoice(id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchInvoices({ page: filter.page });
          } else if (invoices.length - 1 === 0 && filter.page !== 1) {
            fetchInvoices({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_INVOICE, payload: id });
          }

          enqueueSnackbar('Invoice successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Invoice is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const massDeleteInvoices = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        invoicesApi.massDeleteInvoices(IDs).then(() => {
          if (pagination.last_page > filter.page && selectedInvoicesIDs.length < filter.per_page) {
            fetchInvoices({ page: filter.page });
          } else if (invoices.length - selectedInvoicesIDs.length === 0 && filter.page !== 1) {
            fetchInvoices({ page: filter.page - 1 });
          } else {
            dispatchRedux(setInvoicesLastGlobalAction({ type: types.MASS_DELETE_INVOICES, payload: IDs }));
          }
          enqueueSnackbar('Invoices successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Invoices are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const addInvoice = (invoice) => {
    dispatchRedux(setInvoicesLastGlobalAction({ type: types.ADD_INVOICE, payload: invoice }));
  };

  const createInvoice = ({ invoiceData, setSubmitting, setErrors, handleModalResolve } = {}) => {
    invoicesApi.createInvoice(invoiceData).then((invoice) => {
      addInvoice(invoice);
      enqueueSnackbar('Invoice successfully created', { variant: 'success' });
      !handleModalResolve ? history.goBack() : handleModalResolve();
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
        setSubmitting(false);
        enqueueSnackbar('Invoice not created', { variant: 'error' });
      }
    });
  };

  const updateInvoice = ({ invoiceData, setSubmitting, setErrors, handleModalResolve } = {}) => {
    invoicesApi.updateInvoice({ id: invoiceData.id, ...invoiceData }).then((data) => {
      dispatchRedux(setInvoicesLastGlobalAction({ type: types.UPDATE_INVOICE_IN_LIST, payload: data }));
      enqueueSnackbar('Invoice successfully updated', { variant: 'success' });
      !handleModalResolve ? history.goBack() : handleModalResolve();
    }).catch(({ errors }) => {
      if (errors) {
        setErrors(errors);
        setSubmitting(false);
        enqueueSnackbar('Invoice not updated', { variant: 'error' });
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchInvoices({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetInvoices = (newFilter) => {
    dispatch({ type: types.RESET_INVOICES, payload: newFilter });

    fetchInvoices({ page: 1, ...newFilter });
  };

  const allInvoicesIsSelected = () => {
    return invoices.length === selectedInvoicesIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_INVOICE_SELECTION, payload: id });
  };

  const toggleAllInvoicesSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_INVOICES_SELECTION });
  };

  const confirmInvoice = (id) => {
    invoicesApi.confirmInvoice(id).then(({ data }) => {
      dispatchRedux(setInvoicesLastGlobalAction({ type: types.CONFIRM_INVOICE, payload: data }));
      enqueueSnackbar('Invoice successfully confirmed', { variant: 'success' });
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      }
    });
  };

  const cancelInvoice = (id) => {
    invoicesApi.cancelInvoice(id).then(({ data }) => {
      dispatchRedux(setInvoicesLastGlobalAction({ type: types.CANCEL_INVOICE, payload: data }));
      enqueueSnackbar('Invoice successfully canceled', { variant: 'success' });
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Something went wrong, try again later', { variant: 'error' });
      }
    });
  };

  const massConfirmInvoices = (IDs) => {
    invoicesApi.massConfirmInvoices(IDs).then((data) => {
      selectedInvoicesIDs.forEach((item) => toggleItemSelection(item));
      fetchInvoices();
      dispatchRedux(setInvoicesLastGlobalAction({ type: types.MASS_CONFIRM_INVOICE, payload: data }));
      enqueueSnackbar('Invoices successfully confirmed', { variant: 'success' });
    });
  };

  const massCancelInvoices = (IDs) => {
    invoicesApi.massCancelInvoices(IDs).then((data) => {
      selectedInvoicesIDs.forEach(toggleItemSelection);
      fetchInvoices();
      dispatchRedux(setInvoicesLastGlobalAction({ type: types.MASS_CANCEL_INVOICE, payload: data }));
      enqueueSnackbar('Invoices successfully canceled', { variant: 'success' });
    });
  };

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetInvoices(filter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    invoicesLastGlobalAction && dispatch(invoicesLastGlobalAction);
  }, [ invoicesLastGlobalAction ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  const providerValue = {
    invoices,
    isFetching,
    isFetched,
    pagination,
    filter,
    relationsForFilter,
    meta: {
      ...filter,
      ...pagination
    },
    selectedInvoicesIDs,

    changeRelationsForFilter,
    loadNextPage,
    addInvoice,
    applyFilter,
    resetInvoices,
    updateInvoice,
    deleteInvoices,
    toggleItemSelection,
    allInvoicesIsSelected,
    toggleAllInvoicesSelection,
    massConfirmInvoices,
    massCancelInvoices,
    createInvoice,
    fetchInvoices,
    confirmInvoice,
    cancelInvoice,
    massDeleteInvoices
  };

  return (
    <InvoicesContext.Provider value={providerValue}>
      {children}
    </InvoicesContext.Provider>
  );
};
