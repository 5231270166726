import { flow } from 'lodash';
import { useContext } from 'react';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { useModal } from '../../../../../components/ModalsProvider';
import { EditMRIModal } from '../EditMRIModal';
import { MRIsContext } from '../MRIsProvider';
import { PreviewMRIModal } from '../PreviewMRIModal';

export const MRIsMenu = ({ mri, onClose }) => {
  const { openModal } = useModal();
  const { fetchMRIs, deleteMRI } = useContext(MRIsContext);

  const handleDeleteMRI = () => {
    deleteMRI(mri.id);
  };

  const openPreview = () => {
    openModal(PreviewMRIModal, {
      payload: {
        mri,
        onUpdate: fetchMRIs,
        onProcedureDelete: deleteMRI
      }
    });
  };

  const handleUpdateMRI = () => {
    openModal(EditMRIModal, {
      payload: {
        mri,
        appointment_id: mri.appointment_id,
        onListUpdate: fetchMRIs
      }
    });
  };

  // TODO: Hide for future settings
  // const handleUpdateAppointment = () => {
  //   openModal(EditAppointmentModal, {
  //     payload: {
  //       disableAttachments: true,
  //       appointmentID: mri.appointment_id
  //     },
  //     onModalResolved: () => {
  //       fetchMRIs();
  //       onClose();
  //     }
  //   });
  // };

  return (
    <List>
      <MenuItem onClick={flow(openPreview, onClose)}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>


      {!hasRole(rolesMap.client) &&
        <>
          <MenuItem onClick={flow(handleUpdateMRI, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit MRI" />
          </MenuItem>

          {/* TODO: Hide for future settings */}
          {/*<MenuItem disabled={!mri.appointment_id} onClick={handleUpdateAppointment}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <EditIcon color="primary" fontSize="inherit" />*/}
          {/*  </ListItemIcon>*/}

          {/*  <ListItemText primary="Edit appointment" />*/}
          {/*</MenuItem>*/}

          {!hasRole(rolesMap.patient) &&
            <MenuItem onClick={flow(handleDeleteMRI, onClose)}>
              <ListItemIcon>
                <DeleteIcon color="error" fontSize="inherit"/>
              </ListItemIcon>

              <ListItemText primary="Delete" />
            </MenuItem>
          }
        </>
      }
    </List>
  );
};
