import { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';
import { UserRegistrationContext } from '../../UserRegistrationContext';

export const Chart = () => {
  const { palette } = useTheme();
  const { statistics } = useContext(UserRegistrationContext);
  const { labels, data } = statistics;

  const getChartData = () => ({
    labels,
    datasets: [
      {
        label: 'User Statistics',
        data: data.map((item) => item.created),
        backgroundColor: 'rgba(156, 39, 176, 0.1)',
        borderColor: palette.purple,
        pointBorderColor: palette.purple,
        pointHoverBackgroundColor: palette.purple,
        pointHoverBorderColor: palette.purple
      }
    ]
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend:{
      display: false
    },
    scales: {
      yAxes: [ {
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          color: palette.grey[200],
          borderDash: [ 2, 3 ]
        }
      } ],
      xAxes: [ {
        gridLines: {
          drawOnChartArea: false,
          color: palette.grey[200],
          borderDash: [ 2, 3 ]
        },
        ticks: {
          beginAtZero: true
        }
      } ]
    }
  };

  return (
    <Line data={getChartData} options={chartOptions} />
  );
};
