import { useContext } from 'react';
import { useModal as useModalHook } from 'react-modal-hook';
import moment from 'moment';
import { Box, Grid, Link, Typography, useTheme } from '@material-ui/core';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import { FilePreviewModal } from '../../../../../app/Dashboard/files-common';
import { CardItem, CardHeader } from '../../../../Cards';
import { IconButton } from '../../../../IconButton';
import { Label } from '../../../../Label';
import { useModal } from '../../../../ModalsProvider';
import { CallReportsModal } from '../../../CallReportsModal';
import { callerTypesMap, parentTypesMap } from '../../../CopyToModal/parentTypeOptions';
import { CallReportsContext } from '../../../CallReportsProvider';
import { dispositionsLabelsMap } from '../../../dispositionsMap';
import { getCallReportGroupName } from '../../../getCallReportGroupName.js';
import { CallReportsMenu } from '../../CallReportsMenu';
import { Causer } from '../../Causer/Causer';
import { CausersList } from '../../CausersList';
import { UserContact } from '../../UserContact';

export const Card = ({ item: callReport = {} }) => {
  const { openModal } = useModal();
  const theme = useTheme();
  const {
    disableCauser,
    selectedCallReportsIDs,
    toggleCallReportSelected
  } = useContext(CallReportsContext);
  const isSelected = selectedCallReportsIDs?.indexOf(callReport.id) !== -1;

  const [ openFilesPreview, closeFilesPreview ] = useModalHook(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        disableFetch: true,
        file: callReport.files?.[0],
        singleView: true
      }}
    />
  ), [ callReport.files?.[0] ]);

  const handleItemSelection = () => {
    toggleCallReportSelected(callReport);
  };

  const openGroupView = () => {
    openModal(CallReportsModal, {
      payload: {
        reports: callReport.group_calls
      }
    });
  };

  const openCallbackForView = () => {
    openModal(CallReportsModal, {
      payload: {
        reports: [ callReport.callback_for ]
      }
    });
  };

  const openCallbackView = () => {
    openModal(CallReportsModal, {
      payload: {
        reports: [ callReport.callback ]
      }
    });
  };

  return (
    <CardItem disableHover selected={isSelected} py={1} px={2}>
      <CardHeader
        isSelected={isSelected}
        menuComponent={CallReportsMenu}
        onItemSelect={handleItemSelection}
        menuComponentProps={{ callReport }}
      >
        <UserContact
          noWrap={false}
          disableTransform={
            callReport?.caller_phone?.length <= 3 || callReport?.caller_phone === callerTypesMap.anonymous
          }
          user={callReport?.caller || callReport?.fromContact || callReport?.defaultView}
          phone={callReport?.caller_phone}
          TypographyProps={{ variant: 'h4' }}
        />

        {!!callReport.callback && (
          <>
            <br/>
            <Link onClick={openCallbackView}>Has callback</Link>
          </>
        )}

        {!!callReport.callback_for && (
          <>
            <br/>
            <Link onClick={openCallbackForView}>It's callback</Link>
          </>
        )}
      </CardHeader>

      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" pt={1}>
        {!disableCauser && (
          <Grid container spacing={1}>
            <Grid item xs={10} container spacing={1}>
              <Grid item xs={4}>
                <Label color={theme.palette.error.main} label="Case" />
              </Grid>

              <Grid item xs={8}>
                {callReport?.parentCases?.[0] ? (
                  <Causer parent={callReport?.parentCases?.[0]} parentType={parentTypesMap.case} />
                ) : '-'}
              </Grid>

              <Grid item xs={4}>
                <Label color={theme.palette.primary.main} label="User" />
              </Grid>

              <Grid item xs={8}>
                {callReport?.parentUsers?.[0] ? (
                  <Causer parent={callReport?.parentUsers?.[0]} parentType={parentTypesMap.user} />
                ) : '-'}
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <CausersList caseCausers={callReport?.parentCases} userCausers={callReport?.parentUsers} />
            </Grid>
          </Grid>
        )}

        <Box px={1.75} py={1} border="1px dashed" borderColor="grey.200" bgcolor="grey.25" height={90} mt={1}>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography variant="h4">
                  {callReport.is_group_call ? callReport.group_calls?.length ? (
                    <Link onClick={openGroupView}>
                      {getCallReportGroupName(callReport.group_number)}
                    </Link>
                  ) : getCallReportGroupName(callReport.group_number) : 'Direct'}
                </Typography>

                <Typography variant="caption" color="textSecondary">Type</Typography>
              </Box>

              <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography variant="h4">
                  {!!callReport.files?.length
                    ? 'Voicemail'
                    : dispositionsLabelsMap[callReport.disposition] || '-'
                  }
                </Typography>

                <Typography variant="caption" color="textSecondary">Disposition</Typography>
              </Box>
            </Grid>

            <Grid item xs={8}>
              <Box display="flex" flexDirection="column">
                <Typography variant="h4">
                  {callReport.started_at ? moment(callReport.started_at).format('L LT') : '-'}
                </Typography>

                <Typography variant="caption" color="textSecondary">Start Time</Typography>
              </Box>

              <Box display="flex" flexDirection="column">
                <Typography variant="h4">
                  {callReport.finished_at ? moment(callReport.finished_at).format('L LT') : '-'}
                </Typography>

                <Typography variant="caption" color="textSecondary">End Time</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Box display="flex" flexDirection="column">
              <UserContact
                noWrap={false}
                disableTransform={callReport?.callee_phone?.length <= 3}
                user={callReport?.callee || callReport?.toContact || callReport?.defaultView}
                phone={callReport?.callee_phone}
                TypographyProps={{ variant: 'h4' }}
              />

              <Typography variant="caption" color="textSecondary">To</Typography>
            </Box>
          </Grid>

          <Grid item xs={3}>
            {!!callReport.files?.length && (
              <IconButton color="primary" component="span" onClick={openFilesPreview}>
                <VoicemailIcon />
              </IconButton>
            )}
          </Grid>

          <Grid item xs={4}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h4">
                {callReport.duration
                  ? moment.duration(+callReport.duration, 'seconds').format('h[h] m[min] s[s]')
                  : '-'
                }
              </Typography>

              <Typography variant="caption" color="textSecondary">Duration</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </CardItem>
  );
};
