import { debounce } from 'lodash';
import { useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { FiltersBar as FiltersBarComponent } from '../../../../FiltersBar';
import { OfficesLocationSelect, TextField } from '../../../../FormField';
import { UsersSelect } from '../../../../users';
import { LoginSessionsFiltersContext } from '../../../sessions/LoginSessionsFiltersProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FiltersBar = () => {
  const classes = useStyles();
  const formikRef = useRef();
  const { filter, applyFilter } = useContext(LoginSessionsFiltersContext);

  const handleSearchChange = debounce((event) => {
    const value = event.target.value;

    applyFilter({ note: value });
  }, 600);

  const handleUserChange = (user) => {
    applyFilter({ user_id: user?.id });
  };

  const handleOfficeChange = (office) => {
    applyFilter({ office_id: office?.id });
  };

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      initialValues={filter}
      ToolbarProps={{ className: classes.toolbar }}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.note,
          label: filterFieldsLabels[filterFieldsMap.note],
          field: (
            <TextField
              name="note"
              type="search"
              label="Note"
              placeholder="Search..."
              onChange={handleSearchChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.userId,
          label: filterFieldsLabels[filterFieldsMap.userId],
          field: (
            <UsersSelect
              minWidth={210}
              name="user_id"
              label="User"
              onChange={handleUserChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.officeId,
          label: filterFieldsLabels[filterFieldsMap.officeId],
          field: (
            <OfficesLocationSelect
              minWidth={210}
              name="office_id"
              label="Office"
              onChange={handleOfficeChange}
            />
          )
        }
      ]}
    />
  );
};
