import { useMemo } from 'react';
import { truncate } from 'lodash';
import cn from 'classnames';
import { makeStyles, Chip } from '@material-ui/core';
import LabelImportantIcon from '@material-ui/icons/LabelImportantOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import { getFolderParentName } from '../Tags/getFolderParentName';
import { Tooltip } from '../Tooltip';
import { styles } from './styles';

const useStyles = makeStyles(styles);
const maxTagNameLength = 20;

export const Tag = ({
  disableTooltip = false,
  cutTagLabel = false,
  tag,
  className,
  classes: classesProp,

  ...props
}) => {
  const classes = useStyles({ color: tag.color });
  const tagLabel = useMemo(() => {
    return cutTagLabel ? truncate(tag.name, {
      length: maxTagNameLength
    }) : tag.name;
  }, [ tag.name, cutTagLabel ]);

  return (
    <Tooltip
      isExistTooltip={!disableTooltip && tag.name?.length > maxTagNameLength}
      title={tag.is_folder ? getFolderParentName(tag) : tag.name}
      placement="right"
      classes={{ tooltip: classes.tooltip }}
    >
      <Chip
        icon={
          tag.is_system
            ? <LabelImportantIcon className={classes.icon} />
            : tag.is_folder
              ? <FolderOutlinedIcon className={classes.icon} />
              : null
        }
        label={tagLabel}
        className={cn(
          classes.root,
          className
        )}
        classes={{
          label: classes.label,
          deleteIcon: classes.deleteIcon,

          ...classesProp
        }}

        {...props}
      />
    </Tooltip>
  );
};
