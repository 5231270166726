import { Box } from '@material-ui/core';
import { Contact } from '../../../Contact';
import { UserLink } from '../../../UserLink';

export const UserContact = ({ disableTransform = false, user, phone, noWrap = true, TypographyProps = {} }) => {
  return user ? (
    <UserLink
      noWrap={noWrap}
      size="md"
      user={{ ...user, id: user?.user_id || user?.id }}
      childrenProps={{ mt: -0.5 }}

      {...TypographyProps}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Contact
          disableTransform={disableTransform}
          color="textPrimary"
          type="tel"
          contact={phone || user?.phone_number}

          {...TypographyProps}
        />
      </Box>
    </UserLink>
  ) : (
    <Contact
      noWrap
      disableTransform={disableTransform}
      color="textPrimary"
      type="tel"
      contact={phone}

      {...TypographyProps}
    />
  );
};
