import { makeStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexWrap: ({ isMulti }) => isMulti && 'wrap',
    flex: 1,
    overflow: 'hidden',
    margin: ({ isMulti, hasValue }) => isMulti && hasValue ? spacing(0.25, -0.25) : 0
  }
});

const useStyles = makeStyles(styles);

export const ValueContainer = ({ children, ...props }) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>{children}</div>
  );
};
