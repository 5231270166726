import { bmiTypes, bmiTypesColors } from '../../../../../../../../helpers/measureDataMap';

export const styles = ({
  spacing,
  palette,
  typography: { pxToRem, fontWeightMedium }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing(2),
    marginRight: 'auto',
    marginLeft: 'auto'
  },

  root_disableSpacing: {
    marginTop: 0
  },

  sliderRoot: {
    cursor: 'default',
    height: '16px !important',
    marginTop: spacing()
  },

  rail: {
    height: '16px !important',
    opacity: 1,
    color: palette.grey[200],
    backgroundImage: `linear-gradient(to right, 
      ${bmiTypesColors[bmiTypes.underweight]} 14%,
      ${bmiTypesColors[bmiTypes.normal]} 14% 40.5%, 
      ${bmiTypesColors[bmiTypes.overweight]} 40.5% 60%, 
      ${bmiTypesColors[bmiTypes.obese]} 60%)`
  },

  mark: {
    height: '20px !important',
    backgroundColor: 'transparent'
  },

  markLabel: {
    marginTop: spacing()
  },

  thumb: {
    width: 15,
    height: 15,
    color: 'transparent',
    transform: 'translate(-2%, 60%)',

    '&:hover, &.MuiSlider-active': {
      boxShadow: 'none'
    },

    '& > span > span': {
      backgroundColor: ({ color }) => color,

      '& > span': {
        fontSize: pxToRem(10),
        fontWeight: fontWeightMedium
      }
    }
  },

  title: {
    marginRight: spacing(0.5)
  }
});
