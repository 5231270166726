import { transformFinancialClassToOption } from '../../../../../components/FormField';
import { gendersMap } from '../../../../../components/users';
import {
  caseInsuranceTypesOptions,
  insuranceStatusTypesMap,
  insuranceTypesOptions,
  statusTypeCaseOptions,
  subscriberOptions
} from '../AddInsuranceInfoModal';
import { isPrimary } from '../InsurancesProvider';

export const getInitialValues = ({
  attorney = null,
  attorney_adj = null,
  provider = null,
  provider_adj = null,
  ...insurance
}) => {
  const isAddressSame = (!!insurance?.subscriber_info?.address_same_as_patient &&
      +insurance?.subscriber_info?.address_same_as_patient > 0) ||
    insurance?.subscriber_info?.subscriber === subscriberOptions[0].value;
  const typeOptions = [ ...insuranceTypesOptions, ...caseInsuranceTypesOptions ];
  const subscriber = insurance?.medical?.profile
    ? { ...insurance?.medical?.profile, id: insurance?.medical?.profile?.user_id }
    : null;

  return {
    ...insurance,
    case_id: insurance?.case,
    caseItem: insurance?.caseItem || insurance?.case || null,
    status_type: statusTypeCaseOptions.find(({ value }) => value === insurance?.status_type)?.value
      || (!!insurance?.case?.id
        ? statusTypeCaseOptions.find(({ value }) => value === 'case_insurance')?.value
        : statusTypeCaseOptions[0].value
      ),
    type_insurance: typeOptions?.find(({ value }) => (
      value === insurance?.type_insurance
    ))?.value || typeOptions[1].value,
    medical_id: insurance?.medical?.id,
    attorney_id: insurance?.status_type === insuranceStatusTypesMap.case_insurance
      && (attorney || null),
    financial_class_id: transformFinancialClassToOption(insurance?.financial_class),
    insurance_company_id: insurance?.insurance_company || null,
    office_id: insurance?.office || null,
    tpl_id: insurance?.tpl,
    provider_id: insurance?.status_type === insuranceStatusTypesMap.case_insurance
      ? provider ? {
        ...(provider || {}),
        ...(provider?.profile || {})
      } : null
      : provider || null,
    is_main: isPrimary(insurance?.status_type),
    subscriber_info: {
      ...insurance?.subscriber_info,
      address_same_as_patient: isAddressSame,
      gender: gendersMap.find(({ value }) => value === insurance?.subscriber_info?.gender) || null
    },
    case_user_id: insurance?.case_user || subscriber || null,
    adj_mp: insurance?.attorney_adj_id || null,
    adj_pd: insurance?.provider_adj_id || null,
    scan_front: insurance?.url_scan_front || null,
    scan_back: insurance?.url_scan_back || null,
    vehicle: insurance?.vehicle || {},
    provider_adj_id: provider_adj,
    attorney_adj_id: attorney_adj
  };
};
