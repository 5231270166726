import { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { Loader } from '../../../../../components/Loader';
import { CreateForm } from './CreateForm';
import { ListNotes } from './ListNotes';
import * as videoApi from '../../../../../api/videos';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Notes = ({ video, seconds, onClickSecond }) => {
  const classes = useStyles();
  const [ comments, setComments ] = useState({});
  const [ isFetched, setIsFetched ] = useState(false);

  const fetchNotes = () => {
    videoApi.fetchNotes(video.id).then((data) => {
      setComments(data);

      setIsFetched(true);
    });
  };

  const handleEventName = () => {
    fetchNotes();
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  return (
    <Loader
      className={classes.loader}
      loading={!isFetched}
      render={
        () => (
          <div className={classes.notes}>
            <div className={classes.notesInfo}>
              <QuestionAnswerOutlinedIcon/>

              <Typography
                component="h1"
                variant="h2"
                className={classes.countNotes}
              >
                {comments.length} Notes
              </Typography>
            </div>

            <CreateForm
              setIsFetched={setIsFetched}
              onAddNote={handleEventName}
              video={video}
              seconds={seconds}
            />

            <ListNotes
              onClickSecond={onClickSecond}
              onDeleteNote={handleEventName}
              comments={comments}
            />
          </div>
        )}
    />
  );
};
