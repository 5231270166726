import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.LOGIN_ACCOUNTS_FETCH_REQUEST]: (state) => {
    return {
      ...state,

      isFetching: true
    };
  },

  [types.LOGIN_ACCOUNTS_FETCH_SUCCESS]: (
    { filter, loginAccounts, ...state },
    { data: newLoginAccounts, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      loginAccounts: page > 1 ? (
        loginAccounts.filter(({ id }) =>
          !newLoginAccounts.find((loadedLoginAccount) => id === loadedLoginAccount.id)).concat(newLoginAccounts)
      ) : (
        newLoginAccounts
      )
    };
  },

  [types.ADD_LOGIN_ACCOUNT]: (state, loginAccount) => {
    return {
      ...state,

      loginAccounts: [ loginAccount, ...state.loginAccounts ],
      pagination: {
        ...state.pagination,

        total: state.pagination.total + 1
      }
    };
  },

  [types.DELETE_LOGIN_ACCOUNT]: ({ pagination, filter, loginAccounts, ...state }, deletedLoginAccount) => {
    const total = pagination.total - 1;
    const page = Math.ceil((loginAccounts.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      loginAccounts: loginAccounts.filter((loginAccount) => loginAccount.id !== deletedLoginAccount.id)
    };
  },

  [types.UPDATE_LOGIN_ACCOUNT]: ({ loginAccounts, ...state }, updatedLoginAccount) => {
    return {
      ...state,

      loginAccounts: loginAccounts.map((loginAccount) => {
        return updatedLoginAccount.id === loginAccount.id ? updatedLoginAccount : loginAccount;
      })
    };
  },

  [types.RESET_LOGIN_ACCOUNTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  }
});
