import { useContext } from 'react';
import { mdiForumOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Box, makeStyles, Link, Typography, SvgIcon } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { stopPropagation } from '../../../../../../helpers/dom';
import { getUserFullName } from '../../../../../../helpers/users';
import { itemsRoutesByModels } from '../../../../../../dataMaps/apiModels';
import { Loader } from '../../../../../../components/Loader';
import { roleLabelsMap, UserAvatar, UserPreviewModal } from '../../../../../../components/users';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { CardHeader } from '../../../../../../components/Cards';
import { CardItem } from '../../../../../../components/Cards';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Contact } from '../../../../../../components/Contact';
import { Divider } from '../../../../../../components/Divider';
import { Label } from '../../../../../../components/Label';
import { useChatActions } from '../../../../../../utils/useChatActions';
import { statusTypesColors, statusTypesNames, UsersContext } from '../../../UsersContext';
import { MembersMenu } from '../../MembersMenu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ item: user = {} }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { isChatOpening, openChatWithUser } = useChatActions();
  const { selectedIDs, toggleItemSelection } = useContext(UsersContext);
  const isSelected = selectedIDs.indexOf(user.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(user.id);
  };

  const openPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: user.id }
    });
  };

  const getUserPage = () => {
    window.open(itemsRoutesByModels.user(user.id));
  };

  const startChatWithUser = () => {
    openChatWithUser(user.id);
  };

  return (
    <CardItem selected={isSelected} onClick={openPreview}>
      <CardHeader
        isSelected={isSelected}
        onItemSelect={handleItemSelection}
        alignItems="center"
        menuComponent={MembersMenu}
        menuComponentProps={{ user }}
        px={2}
        py={1}
      >
        <Label
          color={statusTypesColors[user.is_active]}
          label={statusTypesNames[user.is_active] || 'Active'}
        />
      </CardHeader>

      <div className={classes.content}>
        <UserAvatar size="xxxl" user={user} />

        <Typography gutterBottom variant="h6" align="center">
          {getUserFullName(user)}
        </Typography>

        <Typography
          gutterBottom
          variant="subtitle2"
          align="center"
          color="error"
          className={classes.role}
        >
          {roleLabelsMap[user.role]}
        </Typography>

        <Contact
          gutterBottom
          type="mailto"
          color="primary"
          contact={user.email}
        />

        <Contact type="tel" contact={user.phone} />
      </div>

      <Divider disableBottomGutter />

      <Box display="flex" alignItems="center" height={46}>
        <Box width="50%" textAlign="center" py={2}>
          <Loader surface loading={isChatOpening} size={24} render={
            () => (
              <Link
                component="button"
                underline="none"
                color="textPrimary"
                className={classes.actionLink}
                onClick={stopPropagation(startChatWithUser)}
              >
                <SvgIcon className={classes.actionIcon}>
                  <Icon path={mdiForumOutline} />
                </SvgIcon>

                Message
              </Link>
            )}
          />
        </Box>

        <VerticalDivider />

        <Box width="50%" textAlign="center">
          <Link
            underline="none"
            color="textPrimary"
            onClick={stopPropagation(getUserPage)}
            className={classes.actionLink}
          >
            <EyeIcon className={classes.actionIcon} />

            View
          </Link>
        </Box>
      </Box>
    </CardItem>
  );
};
