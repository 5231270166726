import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import cn from 'classnames';
import { makeStyles, Button, Tooltip, Typography, Box } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import * as billingApi from '../../../../../api/billing';
import { FileDropZoneWindow } from '../../../../../components/FileDropZoneWindow';
import { CurrencyFormat } from '../../../../../components/CurrencyFormat';
import { invoicesTypesMap } from '../../../../../components/billing';
import { useModal } from '../../../../../components/ModalsProvider';
import { PageSideBar } from '../../../../../components/PageSideBar';
import { setInvoiceItem } from '../../../../../store/dashboard/billing';
import { FilesFilterContextProvider } from '../../../files-common';
import { PaymentsContextProvider } from '../../../../../components/billing/payments';
import { FilesContextProvider } from '../../FilesContext';
import { IncomesContextProvider, IncomesFilterContextProvider } from '../Incomes';
import { Expenses } from '../Expenses';
import { InvoiceFileUploadModal } from '../InvoiceFileUploadModal';
import { InvoicesContext } from '../InvoicesContext';
import { PaymentsContent } from './PaymentsContent';
import { IncomesContent } from './IncomesContent';
import { ActionsBar } from './ActionsBar';
import { Attachments } from './Attachments';
import { Header } from './Header';
import { Body } from './Body';
import { List } from './SideBar/List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointXS = 860;
export const widthBreakpointSmall = 1150;
export const widthBreakpointMedium = 1400;

export const InvoiceItem = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const rootRef = useRef();
  const [ invoice, setInvoice ] = useState(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ isFetched, setIsFetched ] = useState(false);
  const [ total, setTotal ] = useState(0);
  const invoicesContext = useContext(InvoicesContext);

  const initialState = {
    filter: {
      invoice_id: +params.id
    }
  };

  const initialStateOfFiles = {
    filter: {
      owner_type: 'invoice',
      owner_id: invoice?.id
    }
  };

  const initialStateOfInvoices = {
    filter: {
      invoice_id: invoice?.id
    }
  };

  const uploadFiles = (files = []) => () => {
    openModal(InvoiceFileUploadModal, {
      payload: {
        files,
        owner_type: 'invoice',
        owner_id: +params.id
      }
    });
  };

  const handleFilesDropped = (files) => {
    uploadFiles(files)();
  };

  const handleInvoiceStatusUpdate = (data) => {
    setInvoice(data);
    invoicesContext?.fetchInvoices();
  };

  const handleReceivePayment = () => {
    if (invoice.is_confirmed) {
      dispatch(setInvoiceItem({
        title: 'Receive Payment',
        parentPage: 'invoice',

        ...invoice
      }));

      history.push('/billing/payments/create');
    } else {
      enqueueSnackbar('You should confirm invoice');
    }
  };

  useEffect(() => {
    setIsFetched(false);

    billingApi.fetchInvoice(params.id).then((data) => {
      setInvoice(data);
      setIsFetched(true);
    });
  }, [ params.id ]);

  return !isFetched ? <Loader p={2} /> : (
    <PageSideBar
      ref={rootRef}
      title="Invoices List"
      SideBarComponent={List}
      ActionsBarComponent={(props) =>
        <ActionsBar width={width} invoice={invoice} onInvoiceUpdate={handleInvoiceStatusUpdate} {...props} />
      }
    >
      <FilesFilterContextProvider>
        <FileDropZoneWindow onDropAccepted={handleFilesDropped}>
          <Header invoice={invoice} />

          <Body width={width} invoice={invoice}/>

          <FilesContextProvider initialState={initialStateOfFiles}>
            <Attachments invoice={invoice} />
          </FilesContextProvider>

          {invoice.model_type === invoicesTypesMap.case &&
            <Box my={1} mx={2}>
              <IncomesFilterContextProvider>
                <IncomesContextProvider initialState={initialState}>
                  <IncomesContent width={width} invoice={invoice} setTotal={setTotal} />
                </IncomesContextProvider>
              </IncomesFilterContextProvider>
            </Box>
          }

          <PaymentsContextProvider filter={initialStateOfInvoices.filter}>
            <PaymentsContent invoice={invoice} />
          </PaymentsContextProvider>

          <Box my={1} mx={2}>
            <Expenses
              total={total}
              onTotalTimeUpdate={setTotal}
              parentPage="invoice"
              invoice={invoice}
            />
          </Box>

          <Box p={2} pb={0}>
            <Typography variant="subtitle2">
              Notes
            </Typography>
          </Box>

          <div className={classes.footer}>
            <div className={classes.note}>
              <Typography className={classes.note__title}>
                {invoice.notes}
              </Typography>
            </div>

            <div className={classes.total}>
              <div className={classes.totalTitle}>
                <div>
                  <Typography className={classes.total__title}>Total</Typography>
                  <Typography className={classes.total__title}>Payment</Typography>
                </div>

                <Typography variant="h6">Balance due</Typography>
              </div>

              <div className={cn(classes.totalTitle, classes.totalTitle_sum)}>
                <div>
                  <CurrencyFormat
                    value={invoice.total}
                    TypographyProps={{
                      className: classes.total__sum
                    }}
                  />

                  <CurrencyFormat
                    value={invoice.total_paid}
                    TypographyProps={{
                      className: classes.total__sum
                    }}
                  />
                </div>

                <CurrencyFormat
                  value={invoice.balance?.toFixed(2)}
                  TypographyProps={{
                    variant: 'h6',
                    className: classes.total__balance
                  }}
                />
              </div>
            </div>
          </div>

          <div className={classes.actionButton}>
            <Button
              className={classes.actionButton__cancel}
              onClick={history.goBack}
            >
              Back
            </Button>

            {!invoice.is_confirmed ?
              <Tooltip title="You should confirm invoice">
                <Button variant="contained">
                  Receive payment
                </Button>
              </Tooltip>
              :
              <Button
                color="primary"
                variant="contained"
                onClick={handleReceivePayment}
              >
                Receive payment
              </Button>
            }
          </div>
        </FileDropZoneWindow>
      </FilesFilterContextProvider>
    </PageSideBar>
  );
};
