export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: spacing(),
    paddingLeft: 0,

    [breakpoints.down('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      padding: spacing()
    },

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      display: 'flex',
      padding: 0
    }
  },

  wrapper: {
    borderBottom: 0
  },

  actionButtons: {
    flexGrow: 1,

    '& > * + *': {
      marginLeft: spacing(2)
    },

    [breakpoints.down('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',

      '& > * + *': {
        margin: spacing(0.5, 0)
      }
    }
  },

  multiActions: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    [breakpoints.down('md')]: {
      flex: '1 1 100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      marginLeft: spacing(1),
      height: 'auto'
    }
  }
});
