export const styles = ({ palette, transitions }) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    height: '100%'
  },

  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },

  bodyPart: {
    mixBlendMode: 'multiply',
    color: 'transparent',
    transition: transitions.create()
  },

  bodyPart_hover: {
    cursor: 'pointer',

    '&:hover': {
      color: palette.primary.main
    }
  },

  bodyPart_checked: {
    stroke: palette.common.black,
    strokeWidth: 2,
    animation: '2s linear $flickerAnimation infinite'
  },

  '@keyframes flickerAnimation': {
    '0%': {
      opacity: 0
    },

    '50%': {
      opacity: 1
    },

    '100%': {
      opacity: 0
    }
  }
});
