import { Typography } from '@material-ui/core';
import {
  discoveryTypeLabelsMap
} from '../../../app/Dashboard/CasesPage/Cases/CaseItem/Widgets/Discovery/DiscoveryForm';
import {
  userTypeLabelsMap
} from '../../../app/Dashboard/CasesPage/Cases/CaseItem/Widgets/Discovery/DiscoveryForm/userTypeOptions';

export const DiscoveryType = ({ isUserType = false, before = null, after = null, fieldLabel }) => {
  const typesMap = isUserType ? userTypeLabelsMap : discoveryTypeLabelsMap;

  if (!before && after) {
    return (
      <>
        Set&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;-&nbsp;
        <Typography variant="subtitle1">
          {typesMap[after] || '-'}
        </Typography>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Typography variant="subtitle1">
          {typesMap[before] || '-'}
        </Typography>
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Typography variant="subtitle1">
          {typesMap[before] || '-'}
        </Typography>
        &nbsp;to&nbsp;
        <Typography variant="subtitle1">
          {typesMap[after] || '-'}
        </Typography>
      </>
    );
  } else if (!before && !after) {
    return (
      <>
        Empty&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  } else {
    return <></>;
  }
};
