import { Box } from '@material-ui/core';
import { ContentCard } from '../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../components/WidgetsActionButtons';
import { MainContent } from './MainContent';
import { TimeCaseContextProvider } from './TimeCaseContext';

export const TimeCase = ({
  isOpen,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      isResizable={isOpen}
      title="Time spent on cases"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box height={height || '100%'}>
        <TimeCaseContextProvider>
          <MainContent/>
        </TimeCaseContextProvider>
      </Box>
    </ContentCard>
  );
};
