import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_EMAILS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_EMAILS_SUCCESS]: (
    { filter, emails, ...state },
    { data: newEmails, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      emails: (page > 1 ?
        emails.filter(({ id }) => !newEmails.find((loadedEmail) => id === loadedEmail.id)).concat(newEmails)
        :
        newEmails
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_EMAILS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  }
});
