import { Box, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as companyApi from '../../../../api/companies';
import { CreateCompanyModal } from '../../../../app/Dashboard/Contacts/Companies/CreateCompanyModal';
import { Avatar } from '../../../Avatar';
import { useModal } from '../../../ModalsProvider';
import { Autocomplete } from '../../Autocomplete';

const fetchCompanies = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return companyApi.fetchCompanies({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => {
  return option?.isCreatableOption ? (
    <>
      <Box display="flex" mr={1}>
        <AddIcon color="primary" />
      </Box>

      Add Company
    </>
  ) : (
    <Grid container wrap="nowrap" spacing={1} alignItems="center">
      <Grid item>
        <Avatar alt={option?.name?.[0]} src={option?.avatar} size="sm" />
      </Grid>

      <Grid item>
        {option.name}
      </Grid>
    </Grid>
  );
};

const insuranceCompaniesTypeId = 2;

export const CompaniesAutocomplete = ({
  multiple = false,
  params = {},
  insuranceInfo = {},
  creatablePayload = {},

  ...props
}) => {
  const { openModal } = useModal();

  const createCompany = () => {
    return new Promise((resolve, reject) => {
      openModal(CreateCompanyModal, {
        payload: {
          insuranceInfo,
          companyTypeId: insuranceCompaniesTypeId,

          ...creatablePayload
        },
        onModalResolved: resolve,
        onModalRejected: reject
      });
    });
  };

  return (
    <Autocomplete
      isAsync
      multiple={multiple}
      placeholder="Search company..."
      onNeedFetch={fetchCompanies(params)}
      renderOption={renderOption}
      getInputProps={(value) => !(!multiple && value) ? null : ({
        startAdornment: (
          <Avatar alt={value?.name?.[0]} src={value?.avatar} size="xs" />
        )
      })}
      getOptionLabel={(company) => company?.name}
      getOptionValue={(company) => company?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}
      onCreate={createCompany}

      {...props}
    />
  );
};
