import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import { generatePath, Link, useParams } from 'react-router-dom';
import { makeStyles, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendarOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import WorkIcon from '@material-ui/icons/WorkOutlineOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import { mdiCalendarClockOutline, mdiFileDocumentEditOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { getActiveRoute } from '../../../../../../components/router';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import ActivityIcon from '../../../../../../components/icons/activity.svg';
import BillingSvg from '../../../../../../components/icons/billing-icon.svg';
import TimerIcon from '../../../../../../components/icons/chronometer.svg';
import LitigationSvg from '../../../../../../components/icons/litigation.svg';
import NegotiationsSvg from '../../../../../../components/icons/negotiations.svg';
import { Tab, Tabs } from '../../../../../../components/Tabs';
import { routes } from '../routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const routesArray = Object.values(routes).map((path) => ({
  path
}));

const pathProps = (path, params) => {
  return ({
    to: generatePath(path, params),
    value: generatePath(path, params)
  });
};

export const CaseNavigation = ({ handleMenuClose }) => {
  const classes = useStyles();
  const params = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <Tabs
      value={generatePath(getActiveRoute(routesArray), params)}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      orientation={isMobile ? 'vertical' : 'horizontal'}
    >
      <Tab
        component={Link}
        className={classes.tab}
        icon={<WorkIcon />}
        label="General Case info"
        {...pathProps(routes.general, params)}
        onClick={handleMenuClose}
      />

      {!hasRole(rolesMap.client, rolesMap.patient) && (
        <Tab
          component={Link}
          className={classes.tab}
          icon={<QuestionAnswerOutlinedIcon />}
          label="Case Notes"
          {...pathProps(routes.comments, params)}
          onClick={handleMenuClose}
        />
      )}

      <Tab
        component={Link}
        className={classes.tab}
        icon={<SvgIcon><LitigationSvg /></SvgIcon>}
        label="Litigation"
        {...pathProps(routes.litigation, params)}
        onClick={handleMenuClose}
      />

      <Tab
        component={Link}
        className={classes.tab}
        icon={<SvgIcon><NegotiationsSvg /></SvgIcon>}
        label="Negotiations"
        {...pathProps(routes.negotiation, params)}
        onClick={handleMenuClose}
      />

      <Tab
        component={Link}
        className={classes.tab}
        icon={<FolderIcon/>}
        label="Files"
        {...pathProps(routes.files, params)}
        onClick={handleMenuClose}
      />

      <Tab
        component={Link}
        className={classes.tab}
        icon={<DateRangeOutlinedIcon />}
        label="Schedule"
        {...pathProps(routes.schedule, params)}
        onClick={handleMenuClose}
      />

      {!hasRole(rolesMap.client, rolesMap.patient) && (
        <Tab
          component={Link}
          className={classes.tab}
          icon={<NotificationsActiveOutlinedIcon />}
          label="Reminders"
          {...pathProps(routes.reminders, params)}
          onClick={handleMenuClose}
        />
      )}

      <Tab
        component={Link}
        className={classes.tab}
        icon={<InsertInvitationOutlinedIcon />}
        label="Appointments"
        {...pathProps(routes.appointments, params)}
        onClick={handleMenuClose}
      />

      <Tab
        component={Link}
        className={classes.tab}
        icon={<PhoneOutlinedIcon />}
        label="Call Reports"
        {...pathProps(routes.callReports, params)}
        onClick={handleMenuClose}
      />

      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <Tab
          component={Link}
          className={classes.tab}
          icon={<EmailIcon />}
          label="Emails"
          {...pathProps(routes.emails, params)}
          onClick={handleMenuClose}
        />
      }

      <Tab
        component={Link}
        className={classes.tab}
        icon={<PermContactCalendarIcon />}
        label="Contacts"
        {...pathProps(routes.contacts, params)}
        onClick={handleMenuClose}
      />

      <Tab
        component={Link}
        className={classes.tab}
        icon={<SvgIcon><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>}
        label="Medical Claims"
        {...pathProps(routes.claims, params)}
        onClick={handleMenuClose}
      />

      <Tab
        component={Link}
        className={classes.tab}
        icon={<SvgIcon><Icon path={mdiCalendarClockOutline} /></SvgIcon>}
        label="Discovery"
        {...pathProps(routes.discovery, params)}
        onClick={handleMenuClose}
      />

      {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.medic, rolesMap.doctor) &&
        <Tab
          component={Link}
          className={classes.tab}
          icon={<SvgIcon><BillingSvg /></SvgIcon>}
          label="Billing"
          {...pathProps(routes.billing, params)}
          onClick={handleMenuClose}
        />
      }

      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <Tab
          component={Link}
          className={classes.tab}
          icon={<SvgIcon><ActivityIcon /></SvgIcon>}
          label="Activity"
          {...pathProps(routes.activity, params)}
          onClick={handleMenuClose}
        />
      }

      {!hasRole(rolesMap.client, rolesMap.patient) &&
        <Tab
          component={Link}
          className={classes.tab}
          icon={<PlaylistAddCheckIcon />}
          label="Tasks"
          {...pathProps(routes.tasks, params)}
          onClick={handleMenuClose}
        />
      }

      {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.medic, rolesMap.doctor) &&
        <Tab
          component={Link}
          className={classes.tab}
          icon={<SvgIcon><TimerIcon /></SvgIcon>}
          label="Time Tracking"
          {...pathProps(routes.timeTracking, params)}
          onClick={handleMenuClose}
        />
      }

      {/* TODO: hide for future settings */}
      {/*{!hasRole('client', 'patient') &&*/}
      {/*  <Tab*/}
      {/*    component={Link}*/}
      {/*    className={classes.tab}*/}
      {/*    icon={<SvgIcon><TransferIcon /></SvgIcon>}*/}
      {/*    label="Case Transfer Information"*/}
      {/*    {...pathProps(routes.caseTransfer)}*/}
      {/*  />*/}
      {/*}*/}
    </Tabs>
  );
};
