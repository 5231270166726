import { ListRow, ListRowCell } from '../../../../ListRow';
import { columnsWidths } from '../../List/Row/Tracks';

export const TableHeader = () => {
  return (
    <ListRow header px={2}>
      <ListRowCell grow flexBasis={columnsWidths.type}>
        Type
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.start}>
        Start Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.finish}>
        End Date
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.duration}>
        Duration
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
