import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { SearchFilesContext } from '../SearchFilesContext';
import { Row } from './Row';

export const List = () => {
  const {
    files,
    isFetched,
    isFetching,
    loadNextPage,
    pagination,
    resetFiles
  } = useContext(SearchFilesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetFiles();
  }, []);

  return (
    <Scrollbars viewRef={scrollElementRef}>
      <Box px={3} py={2}>
        <Loader loading={!isFetched} render={
          () => !pagination.total ? (
            <Typography align="center">No files found</Typography>
          ) : (
            <MuiList disablePadding>
              <InfiniteListLoader
                items={files}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={pagination}
                RowComponent={Row}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      </Box>
    </Scrollbars>
  );
};
