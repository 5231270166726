import { Formik } from 'formik';
import { Button, Collapse, makeStyles } from '@material-ui/core';
import { TextField } from '../../../../../components/FormField/TextField';
import { Loader } from '../../../../../components/Loader';
import { validationSchema } from '../validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EditForm = ({
  isEditable,
  listItem,
  setIsEditable,
  onUpdate
}) => {
  const classes = useStyles();

  const handleUpdateChecklistItem = (listItem, { isSubmitting, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    onUpdate(listItem).then(() => {
      setSubmitting(false);
      setIsEditable(false);
    });
  };

  const handleFormSubmit = (callback) => (event) => {
    event.preventDefault();

    callback(event);
  };

  const handleFormReset = (callback) => (event) => {
    event.stopPropagation();

    setIsEditable(false);
    callback();
  };

  return (
    <Collapse in={isEditable} className={classes.root}>
      <Formik
        initialValues={listItem}
        onSubmit={handleUpdateChecklistItem}
        validationSchema={validationSchema}
      >
        {
          ({ handleSubmit, isSubmitting, dirty, resetForm }) => (
            <form onSubmit={handleFormSubmit(handleSubmit)}>
              <div className={classes.form}>
                <TextField
                  autoFocus
                  multiline
                  name="text"
                  placeholder="Add item"
                  variant="outlined"
                  margin="dense"
                />
              </div>

              <div className={classes.actions}>
                <Button className={classes.cancelButton} onClick={handleFormReset(resetForm)}>
                  Cancel
                </Button>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!dirty || isSubmitting}
                    >
                      Save
                    </Button>
                  )}
                />
              </div>
            </form>
          )
        }
      </Formik>
    </Collapse>
  );
};
