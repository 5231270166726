import { Row } from './Row';

export const List = ({
  caseRoles = [],
  caseItem,
  onCaseItemUpdate
}) => {
  return (
    caseRoles?.map((item, i) => (
      <Row
        key={i}
        item={item}
        caseItem={caseItem}
        onCaseItemUpdate={onCaseItemUpdate}
      />
    ))
  );
};
