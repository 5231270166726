import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { makeStyles, Collapse, Typography, Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import NotesIcon from '@material-ui/icons/Notes';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { KeyboardDatePicker, TextField } from '../../../FormField';
import { IconButton } from '../../../IconButton';
import { useModal } from '../../../ModalsProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MedicalHistorySurgeriesList = ({ arrayHelpers, onAddFields }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { values } = useFormikContext();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  return (
    <Collapse
      unmountOnExit
      timeout="auto"
      in={!!get(values, 'forms.medical_history.surgeries_dates.length')}
      className={classes.root}
    >
      {!!get(values, 'forms.medical_history.surgeries_dates.length') && (
        values.forms.medical_history.surgeries_dates.map((date, index) => (
          <Grid container spacing={2} key={index} alignItems="flex-end">
            <Grid item>
              <Typography>{index + 1}.</Typography>
            </Grid>

            <Grid item md={2} sm={3} xs={10}>
              <KeyboardDatePicker
                name={`forms.medical_history.surgeries_dates.${index}.date_from`}
                label="Add date from"
                outputFormat="YYYY-MM-DD"
                placeholder="MM/DD/YYYY"
              />
            </Grid>

            <Grid item md={2} sm={3} xs={10}>
              <KeyboardDatePicker
                name={`forms.medical_history.surgeries_dates.${index}.date_to`}
                label="Add date to"
                outputFormat="YYYY-MM-DD"
                placeholder="MM/DD/YYYY"
              />
            </Grid>

            <Grid item md={7} sm={6} xs={10}>
              <TextField
                fullWidth
                name={`forms.medical_history.surgeries_dates.${index}.description`}
                label={
                  <div className={classes.noteIcon}>
                    <NotesIcon fontSize="small" color="inherit" /> Note
                  </div>
                }
                onKeyDown={onAddFields(arrayHelpers, {
                  date_from: null,
                  date_to: null,
                  description: ''
                })}
              />
            </Grid>

            <Grid item>
              <IconButton
                size="small"
                color="error"
                onClick={handleClearField(arrayHelpers, index)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        ))
      )}
    </Collapse>
  );
};
