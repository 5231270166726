import { getTextButtonColorStyle } from '../Button';

export const styles = ({ palette }) => ({
  root: ({ color }) => getTextButtonColorStyle({ palette, color }),

  selected: {
    background: palette.action.selected,
    color: palette.action.active
  }
});
