import cn from 'classnames';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const columnsWidths = {
  name: 180,
  dosage: 180,
  frequency: 200
};

export const Medications = ({ medications }) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.root, { [classes.root_notEmpty]: medications.length > 0 })}>
      <ListRow header className={classes.header}>
        <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
          Medication Name
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.dosage}>
          Dosage
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.frequency}>
          Frequency
        </ListRowCell>
      </ListRow>

      {!medications.length ?
        <Typography align="center" color="textSecondary" className={classes.title}>No info.</Typography>
        :
        <MuiList disablePadding>
          {medications.map((medication, index) => (
            <ListRow key={index}>
              <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                <Typography title={medication.name}>
                  {medication.name}
                </Typography>
              </ListRowCell>

              <ListRowCell noWrap grow flexBasis={columnsWidths.dosage}>
                <Typography title={medication.dosage}>
                  {medication.dosage}
                </Typography>
              </ListRowCell>

              <ListRowCell grow flexBasis={columnsWidths.frequency}>
                <Typography title={medication.frequency}>
                  {medication.frequency}
                </Typography>
              </ListRowCell>
            </ListRow>
          ))}
        </MuiList>
      }
    </div>
  );
};
