import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { Loader } from '../../../../../components/Loader';
import { Sticky } from '../../../../../components/Sticky';
import { FilesContext } from '../../FilesContext';
import { columnsMap } from '../../FilesList/listConfig';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

const hiddenColumns = [ columnsMap.sharedUsers, columnsMap.creator, columnsMap.action ];

export const List = () => {
  const filesContext = useContext(FilesContext);
  const {
    files,
    filter,
    isFetched,
    isFetching,
    loadNextPage,
    resetFiles
  } = filesContext;
  const scrollElementRef = useRef();

  useEffect(() => {
    resetFiles();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No files found</Typography>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={files}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ filesContext, hiddenColumns }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
