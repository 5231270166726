import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as proceduresApi from '../../../../api/profile';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../Modal';
import { Loader } from '../../../Loader';
import { initialValues } from '../initialValues';
import { ProcedureForm } from '../ProcedureForm';
import { validationSchema } from '../validationSchema';

export const ProcedureCreateModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    initialValues: initialValuesProp = {}
  }
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const createProcedure = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return proceduresApi.createMedicalReport(values).then(({ data }) => {
      enqueueSnackbar('Procedure successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Procedure not created', { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <Formik
        initialValues={{
          ...initialValues,
          ...initialValuesProp
        }}
        validationSchema={validationSchema}
        onSubmit={createProcedure}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiCalendarPlus} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Add Procedure
            </ModalHeader>

            <ModalBody>
              <ProcedureForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Create
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
