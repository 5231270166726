export const styles = ({ spacing, breakpoints, palette: { background } }) => ({
  root: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)'
  },

  toolbar: {
    display: 'flex',
    paddingTop: spacing(),
    paddingBottom: spacing(),
    paddingLeft: spacing(4),
    background: background.primary,

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      paddingLeft: spacing()
    }
  },

  grow: {
    flexGrow: 1
  }
});
