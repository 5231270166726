import moment from 'moment';

export const initialValues = {
  patient_id: null,
  patient: null,
  appointment_book_id: null,
  appointment_type_id: null,
  appointment_date: moment().format('YYYY-MM-DD'),
  appointment_time: null,
  _hours: null,
  _minutes: 15,
  note: null
};
