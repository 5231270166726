import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../Loader';
import { InfiniteListLoader } from '../../../InfiniteLoader';
import { Scrollbars } from '../../../Scrollbars';
import { TasksContext } from '../../TasksProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 54;
export const widthBreakpointLarge = 1000;
export const columnsWidths = {
  name: 300,
  status: 100,
  progress: 160,
  activity: 120,
  type: 150,
  assigned: 210,
  priority: 80,
  action: 60
};

export const List = ({ width }) => {
  const {
    tasks,
    isFetching,
    isFetched,
    loadNextPage,
    filter: { per_page, last_page, page, total },
    resetTasks
  } = useContext(TasksContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetTasks();
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Loader loading={!isFetched} p={3} render={
        () => !total ? (
          <Box p={3}>
            <Typography align="center">No tasks found</Typography>
          </Box>
        ) : (
          <>
            <TableHeader width={width} />

            <Scrollbars viewRef={scrollElementRef}>
              <MuiList disablePadding>
                <InfiniteListLoader
                  items={tasks}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={{ per_page, last_page, page }}
                  RowComponent={Row}
                  RowProps={{ width }}
                  onNeedNextPage={loadNextPage}
                  scrollElementRef={scrollElementRef}
                  minRowHeight={minRowHeight}
                />
              </MuiList>
            </Scrollbars>
          </>
        )}
      />
    </Box>
  );
};
