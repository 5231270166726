import { useContext } from 'react';
import { debounce } from 'lodash';
import { Formik } from 'formik';
import { IconButton, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { TextField } from '../../../FormField';
import { MessengerContext } from '../../MessengerProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChatsNavigation = () => {
  const classes = useStyles();
  const { applyFilter } = useContext(MessengerContext);

  const handleSearchChange = debounce((event) => {
    applyFilter({ name: event.target.value });
  }, 600);

  return (
    <div className={classes.root}>
      <Formik initialValues={{ name: null }} onSubmit={null}>
        <TextField
          fullWidth
          name="name"
          variant="outlined"
          placeholder="Search chat"
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton
                color="inherit"
                className={classes.searchIcon}
              >
                <SearchIcon color="primary" />
              </IconButton>
            )
          }}
        />
      </Formik>
    </div>
  );
};
