import { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core';
import { useDebounce } from '../../../../../helpers/hooks';
import { AppBar } from '../../../../../components/AppBar';
import { TextField } from '../../../../../components/FormField';
import { OrderBy } from '../../../../../components/FormField/selects/OrderBy';
import { orderByOptions } from '../../VideosContext/orderByOptions';
import { VideosContext } from '../../VideosContext';
import { styles } from './styles';

const enhancer = withStyles(styles, { withTheme: true });

export const FiltersBar = enhancer(({ classes }) => {
  const { filter, resetVideos } = useContext(VideosContext);
  const [ fields, setFields ] = useState({});
  const debouncedFields = useDebounce(fields, 600);

  const toggleOrderDirection = (orderDirection) => {
    resetVideos({ order_direction: orderDirection });
  };

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
  };

  const handleSelectChange = (name) => (option) => {
    setFields({ ...fields, [ name ]: option.value });
  };

  useEffect(() => {
    resetVideos(debouncedFields);
  }, [ debouncedFields ]);

  return (
    <AppBar className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <Formik
        onSubmit={() => {}}
        initialValues={{
          order_by: orderByOptions.find(({ value }) => value === filter.order_by)
        }}
      >
        {
          () => (
            <div className={classes.form}>
              <TextField
                name="original_filename"
                label="Search"
                placeholder="Search..."
                className={classes.form__textField}
                onChange={handleFieldChange('original_filename')}
              />

              <OrderBy
                isSearchable={false}
                name="order_by"
                label="Order by"
                className={classes.form__selectField}
                value={orderByOptions.find(({ value }) => value === filter.order_by)}
                options={orderByOptions}
                orderDirection={filter.order_direction}
                onOptionChange={handleSelectChange('order_by')}
                onOrderDirectionChange={toggleOrderDirection}
              />
            </div>
          )
        }
      </Formik>
    </AppBar>
  );
});
