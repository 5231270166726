import { useFormikField } from '../../useFormikField';
import { Select } from '../Select';
import { MultiValue } from './MultiValue';

const components = {
  MultiValue
};

export const CreatableSelect = ({
  isCreatable = true,
  isMulti = true,
  name,
  params = {},
  onChange = () => {},
  ...props
}) => {
  const { fieldProps: [ , , { setValue } ] } = useFormikField(name);

  const handleChange = (newValues) => {
    setValue(newValues);
    onChange(newValues);
  };

  return (
    <Select
      isCreatable={isCreatable}
      isMulti={isMulti}
      name={name}
      components={components}
      onChange={handleChange}

      {...props}
    />
  );
};
