import { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { Box, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { TextField } from '../../../../../../components/FormField';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Fab } from '../../../../../../components/Fab';
import { StepContext } from '../StepProvider';
import { CervicalSpine } from './CervicalSpine';
import { TemplateModal } from './TemplateModal';
import { LumbarSpine } from './LumbarSpine';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = () => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { values, dirty, setFieldValue } = useFormikContext();
  const { setIsDirty } = useContext(StepContext);
  const patient = values.patient;
  const age = moment().diff(moment(patient.birthday), 'years');
  const section = values?.forms?.physical_examination;

  const addTemplate = () => {
    openModal(TemplateModal, {
      payload: { age, reportType: values.type },
      onModalResolved: ({ general_appearance, ...data }) => {
        const physicalExamination = data?.template?.forms?.physical_examination;

        if (physicalExamination) {
          setFieldValue('forms.physical_examination', { ...section, ...physicalExamination });
        }
      }
    });
  };

  const removeTemplate = (name) => () => {
    setFieldValue(
      `forms.physical_examination.${name}`,
      null
    );
  };

  useEffect(() => {
    setIsDirty(dirty);
  }, [ dirty ]);

  return (
    <Box flexGrow={1}>
      <Typography paragraph variant="h4" color="secondary">
        Physical examination
      </Typography>

      <Typography variant="h4">
        General appearance
      </Typography>

      <TextField
        fullWidth
        multiline
        disableUnderline
        rows={2}
        name="forms.physical_examination.general_appearance"
        variant="filled"
      />

      <div className={classes.content}>
        <Typography variant="h6" className={classes.title}>
          Spinal examination
        </Typography>
      </div>

      {(section?.cervical || section?.lumbar) && (
        <Box pt={2}>
          <Fab
            size="small"
            color="primary"
            variant="extended"
            startIcon={<AddIcon />}
            onClick={addTemplate}
          >
            Add
          </Fab>
        </Box>
      )}

      {section?.cervical && <CervicalSpine onDelete={removeTemplate('cervical')} />}
      {section?.lumbar && <LumbarSpine onDelete={removeTemplate('lumbar')} />}

      <Box p={2} pl={0}>
        <Fab
          size="small"
          color="primary"
          variant="extended"
          startIcon={<AddIcon />}
          onClick={addTemplate}
        >
          Add
        </Fab>
      </Box>
    </Box>
  );
};
