import { Autocomplete, CompaniesAutocomplete, KeyboardDatePicker } from '../../../../../../../components/FormField';
import { CaseMembersSelect } from '../../../../../../../components/users/CaseMembersSelect';
import { discoveryTypeOptions } from './DiscoveryForm';
import { filterFieldsLabels, filterFieldsMap } from './FiltersBar/filterFieldsMap';

export const filterFields = (caseItem) => ([
  <Autocomplete
    disableValueTransform
    name="type"
    label="Discovery Type"
    placeholder="Select discovery type..."
    options={discoveryTypeOptions}
  />,

  <CaseMembersSelect
    multiple
    formattedValue={false}
    options={caseItem?.case_users}
    name="users"
    label="Users"
  />,

  <CompaniesAutocomplete
    multiple
    formattedValue={false}
    name="companies"
    label="Companies"
  />,

  <KeyboardDatePicker
    disableFuture
    clearable
    name="due_date"
    label={filterFieldsLabels[filterFieldsMap.dueDate]}
    margin="dense"
    outputFormat="YYYY-MM-DD"
  />,

  <KeyboardDatePicker
    clearable
    name="send_date"
    label={filterFieldsLabels[filterFieldsMap.sendDate]}
    margin="dense"
    outputFormat="YYYY-MM-DD"
  />,

  <KeyboardDatePicker
    clearable
    name="received_date"
    label={filterFieldsLabels[filterFieldsMap.receivedDate]}
    margin="dense"
    outputFormat="YYYY-MM-DD"
  />
]);
