import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_MEMBERS_REQUEST]: (state) => {
    return {
      ...state,

      isFetching: true
    };
  },

  [types.FETCH_MEMBERS_SUCCESS]: (
    { filter, workersList, ...state },
    { data: newWorkersList, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      workersList: (page > 1
        ? workersList.filter(({ id }) => {
          return !newWorkersList.find((loadedWorker) => id === loadedWorker.id);
        }).concat(newWorkersList)
        : newWorkersList
      )
    };
  },

  [types.RESET_MEMBERS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.UPDATE_MEMBER]: ({ workersList, ...state }, updatedWorker) => {
    return {
      ...state,

      workersList: workersList.map((worker) => {
        return worker.id === updatedWorker.id ? { ...worker, ...updatedWorker } : worker;
      })
    };
  },

  [types.ADD_MEMBER]: ({ pagination, workersList, ...state }, worker) => {
    return {
      ...state,
      workersList: [ ...workersList, worker ],
      pagination: {
        ...pagination,
        total: pagination.total + 1
      }
    };
  },

  [types.DELETE_MEMBER]: ({ pagination, filter, workersList, ...state }, membersIDs) => {
    const deletedWorkers = workersList.filter((worker) => membersIDs.find((id) => id === worker.id));
    const total = pagination.total - deletedWorkers.length;
    const page = Math.ceil((workersList.length - deletedWorkers.length) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      workersList: workersList.filter((worker) => !deletedWorkers.find(({ id }) => id === worker.id))
    };
  }
});
