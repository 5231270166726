import { fade } from '@material-ui/core';

export const styles = ({ palette }) => ({
  disabledTimeSection: {
    position: 'relative'
  },

  disabledTimeSection__vail: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: fade(palette.info.main, 0.1),
    color: palette.info.main
  },

  disabledTimeSection__content: {
    opacity: 0.05
  },

  calendarTitle: {
    textTransform: 'uppercase',
    color: palette.darkgreen
  }
});
