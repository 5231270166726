import { Icon } from '@mdi/react';
import {
  mdiHomeCityOutline,
  // mdiMapMarkerRadiusOutline,
  mdiClipboardAccountOutline,
  mdiDomain
} from '@mdi/js';
import { SvgIcon } from '@material-ui/core';
import { filtersKeysMap } from '../../../store/lastFilters';
import { EditUser } from '../Members/User';
import { Companies, CompanyItem } from './Companies';
import { OfficeItem, Offices } from './Offices';
// import { Locations } from './Locations';
import { Users } from './Users';
import { UserItem } from './Users/UserItem';

export const routes = [
  {
    exact: true,
    path: '/contacts/companies',
    icon: <SvgIcon><Icon path={mdiHomeCityOutline} /></SvgIcon>,
    label: 'Companies',
    render: () => <Companies filterKey={filtersKeysMap.contacts_companies} />
  },
  {
    path: '/contacts/companies/:id',
    component: CompanyItem
  },
  {
    exact: true,
    path: '/contacts/offices',
    icon: <SvgIcon><Icon path={mdiDomain} /></SvgIcon>,
    label: 'Offices',
    render: () => <Offices filterKey={filtersKeysMap.contacts_offices} />
  },
  {
    path: '/contacts/offices/:id',
    component: OfficeItem
  },
  // TODO: add settings in the future
  // {
  //   exact: true,
  //   path: '/contacts/locations',
  //   icon: <SvgIcon><Icon path={mdiMapMarkerRadiusOutline} /></SvgIcon>,
  //   label: 'Locations',
  //   component: Locations
  // },
  {
    exact: true,
    path: '/contacts/users',
    icon: <SvgIcon><Icon path={mdiClipboardAccountOutline} /></SvgIcon>,
    label: 'Users',
    render: () => <Users filterKey={filtersKeysMap.contacts_users} />
  },
  {
    exact: true,
    path: '/contacts/users/:userID',
    component: UserItem
  },
  {
    exact: true,
    path: '/contacts/users/:userID/edit',
    component: EditUser
  }
];
