import { uniq, isEqual } from 'lodash';

export const getUpdatedKeys = (oldData, newData) => {
  const data = uniq([ ...Object.keys(oldData || {}), ...Object.keys(newData || {}) ]);
  const before = {};
  const after = {};

  for (const key of data) {
    if (!isEqual(oldData[key], newData[key])) {
      before[key] = oldData[key] || false;
      after[key] = newData[key];
    }
  }

  return { before, after };
};
