import { Yup } from '../../utils/validation';

export const validationSchema = Yup.object().shape({
  name: Yup.string().min(3).max(255).nullable().required(),
  conditions: Yup.array().of(Yup.object().shape({
    key: Yup.mixed().required(),
    values: Yup.array().of(Yup.mixed()).nullable().when('key', (value, schema) => {
      if (!value?.value) {
        return schema;
      }

      switch (value?.value) {
        case 'from':
          return schema.required('Please add a sender');
        case 'to':
          return schema.required('Please add a recipient');
        case 'subject':
        case 'subject_body':
          return schema.test('has-values', 'Please add text', (value) => !!value && !!value.length);
        case 'to_line':
        case 'cc_line':
        case 'to_cc_line':
        case 'not_to_line':
        case 'recipient':
        case 'flag':
        case 'has_attachment':
          return schema.nullable();
        default:
          return schema;
      }
    })
  })).min(1).required(),
  actions: Yup.array().of(Yup.object().shape({
    key: Yup.mixed().required('Please select an action'),
    value: Yup.mixed().when('key', (value, schema) => {
      if (!value?.value) {
        return schema;
      }

      switch (value?.value) {
        case 'attach_case':
          return schema.required('Please add a case');
        case 'attach_patient':
          return schema.required('Please add a patient');
        case 'delete':
        case 'mark_read':
        case 'mark_trash':
        case 'mark_flag':
          return schema.nullable();
        default:
          return schema;
      }
    })
  })).min(1).required()
});
