export const eventActivityMap = {
  createCaseScheduleEvent: 'CREATED@CASE:SCHEDULE_EVENT',
  updateCaseScheduleEvent: 'UPDATED@CASE:SCHEDULE_EVENT',
  deleteCaseScheduleEvent: 'DELETED@CASE:SCHEDULE_EVENT',
  createRecurringScheduleEvent: 'CREATED@SCHEDULE_RECURRING_TEMPLATE',
  updateRecurringScheduleEvent: 'UPDATED@SCHEDULE_RECURRING_TEMPLATE',
  deleteRecurringScheduleEvent: 'DELETED@SCHEDULE_RECURRING_TEMPLATE',
  createCaseRecurringScheduleEvent: 'CREATED@CASE:SCHEDULE_RECURRING_TEMPLATE',
  updateCaseRecurringScheduleEvent: 'UPDATED@CASE:SCHEDULE_RECURRING_TEMPLATE',
  deleteCaseRecurringScheduleEvent: 'DELETED@CASE:SCHEDULE_RECURRING_TEMPLATE',
  createScheduleEvent: 'CREATED@SCHEDULE_EVENT',
  updateScheduleEvent: 'UPDATED@SCHEDULE_EVENT',
  deleteScheduleEvent: 'DELETED@SCHEDULE_EVENT',
  createTaskScheduleEvent: 'CREATED@TASK:SCHEDULE_EVENT',
  updateTaskScheduleEvent: 'UPDATED@TASK:SCHEDULE_EVENT',
  deleteTaskScheduleEvent: 'DELETED@TASK:SCHEDULE_EVENT',
  createAppointment: 'CREATED@APPOINTMENT',
  updateAppointment: 'UPDATED@APPOINTMENT',
  deleteAppointment: 'DELETED@APPOINTMENT',
  createTaskRecurringScheduleEvent: 'CREATED@TASK:SCHEDULE_RECURRING_TEMPLATE',
  updateTaskRecurringScheduleEvent: 'UPDATED@TASK:SCHEDULE_RECURRING_TEMPLATE',
  deleteTaskRecurringScheduleEvent: 'DELETED@TASK:SCHEDULE_RECURRING_TEMPLATE'
};
