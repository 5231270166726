import cn from 'classnames';
import { makeStyles } from '@material-ui/core';

const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '0 0 auto',

    '& > * + *': {
      marginLeft: spacing()
    }
  }
});

const useStyles = makeStyles(styles);

export const CommentActions = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.root, className)} {...props} />
  );
};
