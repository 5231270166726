import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { objectKeysToMap } from '../../../../../helpers/objectKeysToMap';
import { InfiniteListLoader } from '../../../../InfiniteLoader';
import { ScrollbarsCustom } from '../../../../ScrollbarsCustom';
import { Sticky } from '../../../../Sticky';
import { Loader } from '../../../../Loader';
import { ReportsContext } from '../ReportsProvider';
import { Header } from './Header';

export const widthBreakpointXS = 760;
export const widthBreakpointSmall = 900;
export const widthBreakpointMedium = 1000;
export const widthBreakpointLarge = 1100;

export const columnsWidths = {
  checkbox: 'auto',
  date: 170,
  doi_date: 150,
  patient: 150,
  type: 190,
  diagnosis: 190,
  provider: 230,
  signed: 150,
  actions: 50
};

export const columnsMap = objectKeysToMap(columnsWidths);

export const DiagnosisList = ({ autoHeight, hiddenColumns, Row, RowProps = {} }) => {
  const { isFetching, isFetched, meta, reports, pathname, resetReports, loadNextPage } = useContext(ReportsContext);
  const scrollElementRef = useRef();
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const disablePatient = pathname !== '/diagnosis';

  useEffect(() => {
    resetReports();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} autoHeight={autoHeight}>
      <Box ref={rootRef}>
        <Loader loading={!isFetched} p={2} render={
          () => !meta.total ? (
            <Box p={3}>
              <Typography align="center">No reports found</Typography>
            </Box>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <Header
                  width={width}
                  disablePatient={disablePatient}
                  hiddenColumns={hiddenColumns}
                  ListRowProps={RowProps?.ListRowProps}
                />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={reports}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={meta}
                  RowProps={{ hiddenColumns, width, disablePatient, ...RowProps }}
                  RowComponent={Row}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
