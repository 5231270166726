import moment from 'moment';
import cn from 'classnames';
import { makeStyles, Box, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useContext } from 'react';
import { Badge } from '../../../Badge';
import { IconButton } from '../../../IconButton';
import { Divider } from '../../../Divider';
import { CalendarContext } from '../../../schedule-events/Schedule/CalendarProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({
  selectedDate,
  startDate,
  endDate,
  onEndUpdate = () => {},
  onStartUpdate = () => {},
  onDateUpdate = () => {}
}) => {
  const classes = useStyles();
  const range = moment.range(startDate, endDate);
  const { calendarEvents, reloadCalendarEvents } = useContext(CalendarContext);

  const handleNext = () => {
    const start = startDate.clone().add(1, 'week');
    const end = endDate.clone().add(1, 'week');

    onStartUpdate(start);
    onEndUpdate(end);
    reloadCalendarEvents({
      started_from: start.unix(),
      started_to: end.unix()
    });
  };

  const handleBack = () => {
    const start = startDate.clone().subtract(1, 'week');
    const end = endDate.clone().subtract(1, 'week');

    onStartUpdate(start);
    onEndUpdate(end);
    reloadCalendarEvents({
      started_from: start.unix(),
      started_to: end.unix()
    });
  };

  const handleDateChange = (value) => () => {
    onDateUpdate(value);
  };

  const getEventsCount = (value) => {
    const formattedDate = moment(value).format('YYYY-MM-DD');

    return calendarEvents?.[formattedDate]?.total || 0;
  };

  return (
    <>
      <Divider />

      <Box display="flex" alignItems="center" flex={1} minWidth={0}>
        <Box mr={1} ml={0.5}>
          <IconButton size="small" className={classes.arrowButton} onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </Box>

        {Array.from(range.by('day')).map((date) => (
          <div
            key={+date._d}
            className={cn(classes.picker, { [classes.picker_active]: selectedDate.isSame(date, 'day') })}
            onClick={handleDateChange(date)}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box mr={2}>
                <Typography variant="caption" color="textSecondary">
                  {moment(date._d).format('ddd').toUpperCase()}
                </Typography>
              </Box>

              <Badge
                showZero
                badgeContent={getEventsCount(date)}
                color={getEventsCount(date) === 0 ? 'success' : 'info'}
                title="Events count"
              />
            </Box>

            <Box component="span" mt={-0.75}>
              <Typography
                variant="subtitle2"
                className={
                  cn(
                    classes.picker_item,
                    { [classes.picker_item__active]: date.isSame(moment(), 'day') }
                  )
                }
              >
                {moment(date._d).format('DD')}
              </Typography>
            </Box>
          </div>
        ))}

        <Box ml={1} mr={0.5}>
          <IconButton size="small" className={classes.arrowButton} onClick={handleNext}>
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>

      <Divider />
    </>
  );
};
