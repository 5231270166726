import {
  Box,
  List as MuiList,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../ListRow';
import {
  lumbarMotorStrengthMap,
  motorStrengthMap
} from '../../../../../spinalExaminationMap';
import { columnsWidthsLg, columnsWidthsSm } from '../../columnsWidths';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const MotorStrength = ({ isLumbarSpine = false, report }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;
  const keys = isLumbarSpine ? lumbarMotorStrengthMap : motorStrengthMap;

  return (
    <Box mt={4}>
      <Typography gutterBottom variant="h5">Motor strenght-upper extremities</Typography>

      <Box my={2}>
        <div className={classes.root}>
          <ListRow header>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name} />

            <ListRowCell flexBasis={columnsWidths.right}>
              <Typography variant="subtitle2">
                Right
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.left}>
              <Typography variant="subtitle2">
                Left
              </Typography>
            </ListRowCell>
          </ListRow>

          <MuiList disablePadding>
            {Object.keys(keys)?.map((item, i) => (
              <ListRow key={i}>
                <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                  <Typography noWrap variant="h5">
                    {keys[item]}:
                  </Typography>
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.right}>
                  {report[item]?.right || '-'}
                </ListRowCell>

                <ListRowCell flexBasis={columnsWidths.left}>
                  {report[item]?.left || '-'}
                </ListRowCell>
              </ListRow>
            ))}
          </MuiList>
        </div>
      </Box>
    </Box>
  );
};
