import { Grid, Typography, Hidden } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../../../components/ReadonlyCheckbox';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { UserLink } from '../../../../../../components/UserLink';
import { Date } from '../../../../../../components/Date';

export const DefendantBody = ({ insurance }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={3} sm={6} xs={12}>
        <Typography color="textSecondary">
          Adj. (BI)
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <UserLink
          variant="h5"
          user={insurance?.attorney}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography color="textSecondary">
          Adj. (UM)
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <UserLink
          variant="h5"
          user={insurance?.provider}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Policy #
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.policy_number}>
          {insurance?.policy_number || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Claim #
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.claim_number}>
          {insurance?.claim_number || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Insured
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.insured}>
          {insurance?.insured || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Driver
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.driver}>
          {insurance?.driver || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Request Limits
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Date
          variant="h5"
          date={insurance?.requested_limit_date}
        />
      </Grid>

      <Grid item md={6} sm={12} xs={12}>
        <ReadonlyCheckbox
          title="Refused Dislosure"
          checked={insurance?.refused_dislosure}
        />
      </Grid>

      <Hidden only={[ 'xs', 'sm' ]}>
        <Grid item sm={12} />
      </Hidden>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Policy Limits
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <CurrencyFormat
          value={insurance?.policy_limit}
          TypographyProps={{ variant: 'h5' }}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Umbrella Ins.
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.umbrella_insurance}>
          {insurance?.umbrella_insurance || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Veh Lic #
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.vehicle?.license_number}>
          {insurance?.vehicle?.license_number || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Year/Make
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Date
          format="YYYY"
          variant="h5"
          date={+insurance?.vehicle?.year}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Model
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.vehicle?.model}>
          {insurance?.vehicle?.model || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Color
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.vehicle?.color}>
          {insurance?.vehicle?.color || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          DL #
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.dl_number}>
          {insurance?.dl_number || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};
