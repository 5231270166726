import { makeStyles, SvgIcon } from '@material-ui/core';
import { mdiCardAccountDetailsOutline, mdiCardAccountDetailsStarOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import CardCompensationSvg from '../../../../../../../components/icons/medical/card-accound-compensation.svg';
import CardAccountSvg from '../../../../../../../components/icons/medical/card-account-injury.svg';
import { formTypesMap } from '../../../../../../../components/medical/forms';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const UserIcon = ({ type }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SvgIcon fontSize="large" color="primary">
        {type === formTypesMap.private
          ? <Icon path={mdiCardAccountDetailsStarOutline} />
          : type === formTypesMap.injury
            ? <CardAccountSvg />
            : type === formTypesMap.follow_up
              ? <Icon path={mdiCardAccountDetailsOutline} />
              : type === formTypesMap.ortho
                ? <CardAccountSvg />
                : <CardCompensationSvg />
        }
      </SvgIcon>
    </div>
  );
};
