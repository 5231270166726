import { api } from '../api';

export const fetchContactsUsers = (config = {}) => {
  return api.get('/contact/users', config)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const createContactsUser = (user) => {
  return api.post('/contact/users', user)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const fetchContactsUser = (id, config) => {
  return api.get(`/contact/users/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const updateContactsUser = (user) => {
  return api.put(`/contact/users/${user.id}`, user)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const deleteContactsUser = (id) => {
  return api.delete(`/contact/users/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export const massDeleteUsers = (IDs) => {
  return api.delete('/users', { params: { users_ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
