import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_TYPES_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_TYPES_SUCCESS]: (state, payload) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      types: payload
    };
  },

  [types.ADD_TYPE]: ({ types, ...state }, payload) => {
    return {
      ...state,

      types: [ ...types, payload ]
    };
  },

  [types.UPDATE_TYPE]: ({ types, ...state }, type) => {
    return {
      ...state,

      types: types.map((item) => {
        return item.id === type.id ? type : item;
      })
    };
  },

  [types.DELETE_TYPE]: ({ pagination, filter, types, ...state }, typeId) => {
    return {
      ...state,

      types: types.filter((type) => type.id !== typeId)
    };
  }
});
