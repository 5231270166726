import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, SvgIcon } from '@material-ui/core';
import { mdiHomeCityOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { UserContext } from '../../../../app/Dashboard/Members/User/UserProvider';
import { ContentCard } from '../../../ContentCard';
import { WorkInfo as WorkInfoComponent } from '../../WorkInfo';

export const WorkInfo = () => {
  const userContext = useContext(UserContext);
  const profile = useSelector(({ profile }) => profile);
  const user = userContext ? userContext.user : profile.user;

  return (
    <ContentCard
      fullHeight
      title="Work Info"
      elevation={3}
      leftActions={[
        <Box mr={1}>
          <SvgIcon fontSize="small">
            <Icon path={mdiHomeCityOutline} />
          </SvgIcon>
        </Box>
      ]}
    >
      <WorkInfoComponent tabPage user={user || {}} />
    </ContentCard>
  );
};
