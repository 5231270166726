import { Grid, Typography, Hidden } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../../../components/ReadonlyCheckbox';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { UserLink } from '../../../../../../components/UserLink';
import { Date } from '../../../../../../components/Date';

export const PlaintiffBody = ({ insurance }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={3} sm={6} xs={12}>
        <Typography color="textSecondary">
          Adj. (BI)
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <UserLink
          variant="h5"
          user={insurance?.attorney}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography color="textSecondary">
          Adj. (PD)
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <UserLink variant="h5" user={insurance?.attorney_adj} />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography color="textSecondary">
          Adj. (UM)
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <UserLink variant="h5" user={insurance?.provider} />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography color="textSecondary">
          Adj. (MP)
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <UserLink variant="h5" user={insurance?.provider_adj} />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Policy #
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.policy_number}>
          {insurance?.policy_number || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Claim #
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.claim_number}>
          {insurance?.claim_number || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Insured
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.insured}>
          {insurance?.insured || '-'}
        </Typography>
      </Grid>

      <Grid item md={6} sm={12} xs={12}>
        <ReadonlyCheckbox
          title="Prop 213"
          checked={insurance?.prop_two_one_three}
        />
      </Grid>

      <Hidden only={[ 'xs', 'sm' ]}>
        <Grid item sm={12} />
      </Hidden>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Veh Lic #
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.vehicle?.license_number}>
          {insurance?.vehicle?.license_number || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Year/Make
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Date
          format="YYYY"
          variant="h5"
          date={+insurance?.vehicle?.year}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Model
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.vehicle?.model}>
          {insurance?.vehicle?.model || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Color
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.vehicle?.color}>
          {insurance?.vehicle?.color || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Liability/Limits
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.liability_limit}>
          {insurance?.liability_limit || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          UM/UIM
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.um_uim}>
          {insurance?.um_uim || '-'}
        </Typography>
      </Grid>

      <Hidden only={[ 'xs', 'sm' ]}>
        <Grid item sm={12} />
      </Hidden>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Medpay
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.medpay}>
          {insurance?.medpay || '-'}
        </Typography>
      </Grid>

      <Grid item md={6} sm={12} xs={12}>
        <ReadonlyCheckbox
          title="Medpay Requested"
          checked={insurance?.medpay_requested}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Deductible
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.deductible}>
          {insurance?.deductible || '-'}
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Medpay Received
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Date
          noInfoTitle="-"
          variant="h5"
          date={insurance?.medpay_received_date}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Rental
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <CurrencyFormat
          value={insurance?.rental}
          TypographyProps={{ variant: 'h5' }}
        />
      </Grid>

      <Hidden only={[ 'xs', 'sm', 'md' ]}>
        <Grid item sm={6} />
      </Hidden>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap color="textSecondary">
          Tow
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <Typography noWrap variant="h5" title={insurance?.tow}>
          {insurance?.tow || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};
