import { orderDirectionsMap } from '../../../components/FormField';
import { orderByOptions } from '../../Dashboard/files-common/FilesFilterContext/orderByOptions';

export const initialState = {
  isFetched: false,
  isFetching: false,
  filter: {
    page: 1,
    per_page: 12,
    order_by: orderByOptions[0].value,
    order_direction: orderDirectionsMap.desc,
    tags: []
  },
  pagination: {
    total: 0,
    last_page: 1
  },
  files: [],
  selectedFilesIDs: []
};
