import { useState, useEffect, useMemo } from 'react';
import { flow } from 'lodash';
import { useDropzone } from 'react-dropzone';
import filesize from 'filesize';
import moment from 'moment';
import {
  makeStyles,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuItem,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { generateAggregateFileType } from '../../utils/generateAggregateFileType';
import { useModal } from '../ModalsProvider';
import { Popper } from '../Popper';
import { SvgIcon } from '../SvgIcon';
import { FileTypeIcon, SelectAvailableFilesModal } from '../../app/Dashboard/files-common';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FilePicker = ({
  initialFile = null,
  initialAttachments = [],
  onChangeFiles,
  onChangeAvailableFilesIDs,
  onAttachmentsChange
}) => {
  const [ files, setFiles ] = useState(initialFile ? [].concat(initialFile) : []);
  const [ availableFiles, setAvailableFiles ] = useState([]);
  const [ attachments, setAttachments ] = useState(initialAttachments);
  const media = useMemo(() => (
    [ ...(files || []), ...(availableFiles || []), ...(attachments || []) ]
  ), [ files, availableFiles, attachments ]);
  const { openModal } = useModal();
  const classes = useStyles();

  const acceptFiles = (acceptFiles) => {
    const isExistFile = (file) => {
      return files.find(({ name, lastModified }) => {
        return name === file.name && lastModified === file.lastModified;
      });
    };

    acceptFiles.forEach((file) => {
      if (!files.length || !isExistFile(file)) {
        file.aggregate_type = generateAggregateFileType(file.type);
        setFiles((prevFiles) => [ ...prevFiles, file ]);
      }
    });
  };

  const acceptAvailableFiles = (files) => {
    const isExistFile = (file) => availableFiles.find(({ id }) => id === file.id);
    const acceptedAvailableFiles = files.filter((file) => !isExistFile(file));

    setAvailableFiles((prevFiles) => [ ...prevFiles, ...acceptedAvailableFiles ]);
  };

  const openAvailableFilesModal = () => {
    openModal(SelectAvailableFilesModal, {
      payload: {
        disableCoping: true
      },
      onModalResolved: acceptAvailableFiles
    });
  };

  const handleRemoveFile = (file, index) => {
    if (file.isAttachment) {
      setAttachments((prevFiles) => prevFiles.filter((currentFile) => currentFile.id !== file.id));
    } else if (file.id) {
      setAvailableFiles((prevFiles) => prevFiles.filter((currentFile) => currentFile.id !== file.id));
    } else {
      setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
    }
  };

  const { getInputProps, open } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: acceptFiles
  });

  useEffect(() => {
    onChangeFiles(files);
  }, [ files ]);

  useEffect(() => {
    const filesIDs = availableFiles.map((file) => file.id);

    onChangeAvailableFilesIDs(filesIDs);
  }, [ availableFiles ]);

  useEffect(() => {
    onAttachmentsChange?.(attachments);
  }, [ attachments ]);

  return (
    <div>
      <Typography variant="h3" className={classes.title}>Attachments</Typography>

      <input {...getInputProps()} />

      <Popper
        anchorRenderer={({ anchorRef, handleToggle }) => (
          <Fab
            buttonRef={anchorRef}
            color="primary"
            onClick={handleToggle}
          >
            <AddIcon />
          </Fab>
        )}
      >
        {({ handleClose }) => (
          <List>
            <MenuItem onClick={flow(open, handleClose)}>
              <ListItemIcon>
                <CloudUploadIcon fontSize="inherit" />
              </ListItemIcon>

              <ListItemText primary="Upload new file" />
            </MenuItem>

            <MenuItem onClick={flow(openAvailableFilesModal, handleClose)}>
              <ListItemIcon>
                <FileCopyIcon fontSize="inherit" />
              </ListItemIcon>

              <ListItemText primary="Select from available" />
            </MenuItem>
          </List>
        )}
      </Popper>

      <List>
        {media?.map((file, i) => (
          <ListItem divider className={classes.item} key={i}>
            <FileTypeIcon file={file} className={classes.icon} />

            <ListItemText
              primary={file.name || file.original_filename}
              primaryTypographyProps={{ noWrap: true }}
              classes={{ primary: classes.nameText }}
            />

            <ListItemText
              primary={moment(file.lastModified || file.updated_at).format('L LT')}
              primaryTypographyProps={{ noWrap: true }}
              classes={{ root: classes.time, primary: classes.timeText }}
            />

            <ListItemText
              primary={file?.size && filesize(file.size)}
              primaryTypographyProps={{ noWrap: true }}
              classes={{ root: classes.size, primary: classes.sizeText }}
            />

            <IconButton onClick={() => handleRemoveFile(file, i)}>
              <SvgIcon color="error">
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
