import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { columnsWidths } from '../MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = () => {
  const classes = useStyles();

  return (
    <ListRow header className={classes.root}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        Name
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.created_at}>
        Created at
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updated_at}>
        Updated at
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.signed_users}>
        Signed
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
