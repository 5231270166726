import { useState } from 'react';
import { isEmpty } from 'lodash';
import cn from 'classnames';
import Dropzone from 'react-dropzone';
import { useFormikContext } from 'formik';
import { makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '../IconButton';
import { dataURItoBlob } from '../../helpers/files';
import { useFormikField } from '../FormField/useFormikField';
import { ImageTransformCropModal } from '../ImageTransformCropModal';
import { useModal } from '../ModalsProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ImageDropZone = ({
  label,
  name,
  croppedName = null,
  preview
}) => {
  const { fieldProps: [ , , { setValue } ] } = useFormikField(name);
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [ croppedImageUrl, setCroppedImageUrl ] = useState(preview || null);
  const { openModal } = useModal();
  const classes = useStyles();

  const onDrop = ([ image ]) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      openModal(ImageTransformCropModal, {
        payload: {
          src: reader.result,
          fileType: image.type,
          minWidth: 100,
          minHeight: 100
        },
        onModalResolved: (data) => {
          const dataUrl = data.dataURL;

          if (croppedName) {
            setFieldValue(croppedName, dataUrl);
            setFieldValue(`driver_license.${name}`, dataUrl);
          }

          setCroppedImageUrl(dataUrl);
          setValue(dataURItoBlob(dataUrl));
        }
      });
    });

    reader.readAsDataURL(image);
  };

  const handleRemoveImage = () => {
    setCroppedImageUrl(null);
    setValue(null);
  };

  return (
    <Dropzone multiple={false} accept="image/*" onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div className={classes.root}>
          <IconButton
            disabled={isEmpty(croppedImageUrl) || isSubmitting}
            size="small"
            className={classes.removeButton}
            style={{ visibility: isEmpty(croppedImageUrl) && 'hidden' }}
            color="error"
            onClick={handleRemoveImage}
          >
            <CloseIcon />
          </IconButton>

          <div
            {...getRootProps()}
            className={cn(classes.uploadTrigger, { [classes.uploadTrigger_hover]: isDragActive && !isSubmitting } )}
          >
            {!isSubmitting && <input {...getInputProps()} />}

            <Typography color="inherit" className={classes.uploadTriggerText}>
              Drag & Drop <br />files to upload
            </Typography>

            {!isEmpty(croppedImageUrl) &&
              <div className={classes.uploadPreview}>
                <img src={croppedImageUrl} alt="" className={classes.uploadPreviewImg} />

                {!isSubmitting &&
                  <div className={classes.uploadPlaceholder}>
                    <Typography color="inherit" className={classes.uploadTriggerText}>
                      Replace file
                    </Typography>
                  </div>
                }
              </div>
            }
          </div>

          {label &&
            <Typography className={classes.uploadName}>{label}</Typography>
          }
        </div>
      )}
    </Dropzone>
  );
};
