import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import {
  makeStyles,
  IconButton,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import MinimizeIcon from '@material-ui/icons/Minimize';
import MaximizeIcon from '@material-ui/icons/Maximize';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const WidgetsActionButtons = ({
  page,
  isEditable,
  isDisabledOpen,
  type,
  isDisabledClose = false,
  isMinimizable,
  onMinimize,
  onMaximized,
  onPutItem,
  onClose,
  onOpen,
  openWidgetModal
}) => {
  const classes = useStyles();
  const userGlobalStore = useSelector(({ globalUser }) => globalUser);
  const prevLayoutsOptions = userGlobalStore?.data?.layout?.[page]?.prevLayoutsOptions;
  const isOpen = useMemo(() => prevLayoutsOptions?.isOpen?.[type], [ prevLayoutsOptions?.isOpen ]);
  const isMinimize = useMemo(() => prevLayoutsOptions?.isMinimize, [ prevLayoutsOptions?.isMinimize ]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const showButton = isMobile || (!isMobile && !isOpen);

  const handleOpen = () => {
    onOpen?.(type);
  };

  const handleMaximized = () => {
    onMaximized(type);
  };

  const handleMinimize = () => {
    onMinimize(type);
  };

  return (
    <div className={cn(classes.root, 'non-draggable')}>
      {isEditable &&
        <IconButton
          color="inherit"
          className={classes.actionButton}
          onClick={openWidgetModal}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      }

      {showButton && isMinimizable && isMinimize?.[type] &&
        <IconButton
          color="inherit"
          className={classes.actionButton}
          onClick={handleMaximized}
        >
          <MaximizeIcon fontSize="small" />
        </IconButton>
      }

      {showButton && isMinimizable && !isMinimize?.[type] &&
        <IconButton
          color="inherit"
          className={classes.actionButton}
          onClick={handleMinimize}
        >
          <MinimizeIcon fontSize="small" />
        </IconButton>
      }

      {!isDisabledOpen && isOpen &&
        <IconButton
          color="inherit"
          className={cn(classes.actionButton, classes.actionButton_exit)}
          onClick={onClose}
        >
          <FullScreenExitIcon fontSize="small" />
        </IconButton>
      }

      {!isDisabledOpen && !isOpen &&
        <IconButton
          color="inherit"
          className={classes.actionButton}
          onClick={handleOpen}
        >
          <FullScreenIcon fontSize="small" />
        </IconButton>
      }

      {showButton && !isDisabledClose &&
        <IconButton
          color="inherit"
          className={classes.actionButton}
          onClick={onPutItem}
        >
          <CloseIcon fontSize="small"/>
        </IconButton>
      }
    </div>
  );
};
