import { Box, Grid, Typography } from '@material-ui/core';
import { SipInfo } from '../../../../app/Dashboard/ProfilePage/LeftSidebar';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { CompanyLink } from '../../../CompanyLink';
import { Contact } from '../../../Contact';
import { Divider } from '../../../Divider';
import { OfficesContent } from './OfficesContent';
import { WorkOfficesContent } from './WorkOfficesContent';

export const Body = ({ user }) => {
  return (
    <div>
      <Grid container spacing={2} component={Box} pb={2} justify="space-between" alignItems="flex-start">
        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Company
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {user?.work?.company?.name ?
            <CompanyLink
              variant="h5"
              company={user?.work?.company}
            />
            :
            <Typography color="textSecondary">No info.</Typography>
          }
        </Grid>

        {user.role === rolesMap.doctor ? (
          <WorkOfficesContent user={user} offices={user?.work?.offices} />
        ) : (
          <OfficesContent user={user} />
        )}
      </Grid>

      {hasRole(rolesMap.admin, rolesMap.supervisor) && (
        <>
          <Divider gutter={2} />

          <Grid container spacing={2} component={Box} py={2} justify="space-between" alignItems="center">
            <Grid item xs={4}>
              <Typography noWrap color="textSecondary">
                Phone
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Contact
                needIcon
                noWrap
                variant="h5"
                type="tel"
                contact={user.phone_number}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography noWrap color="textSecondary">
                Mob number
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Contact
                needIcon
                noWrap
                variant="h5"
                type="tel"
                contact={user.mobile_phone_number}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography noWrap color="textSecondary">
                Fax
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Contact
                needIcon
                noWrap
                variant="h5"
                type="tel"
                contact={user.fax}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography noWrap color="textSecondary">
                Email
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Contact
                needIcon
                noWrap
                variant="h5"
                type="mailto"
                contact={user.email}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography noWrap color="textSecondary">
                Main Office Timezone
              </Typography>
            </Grid>

            <Grid item xs={8}>
              {user?.work?.offices?.[0]?.timezone ?
                <Typography variant="h5">
                  {user?.work?.offices?.[0]?.timezone}
                </Typography>
                :
                <Typography color="textSecondary">No info.</Typography>
              }
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={2} component={Box} py={2} justify="space-between" alignItems="center">
        <SipInfo disableUpdate user={user} />
      </Grid>
    </div>
  );
};
