import { lighten } from '@material-ui/core/styles';

export const styles = ({ spacing, palette, transitions }) => ({
  root: ({ appointment }) => {
    const apptTypeColor = appointment?.appointment_type?.color;
    const bgColor = apptTypeColor ? lighten(apptTypeColor, 0.8) : palette.grey[100];
    const borderColor = apptTypeColor ? lighten(apptTypeColor, 0.8) : palette.grey[500];
    const borderLeftColor = apptTypeColor || palette.grey[500];

    return {
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      padding: spacing(0.5, 0.75),
      border: `1px solid ${borderColor}`,
      borderLeft: `5px solid ${borderLeftColor}`,
      borderRadius: 5,
      cursor: 'pointer',
      backgroundColor: bgColor,
      transition: transitions.create([ 'border-color' ]),

      '&:hover': {
        borderColor: apptTypeColor
      }
    };
  },

  loopIcon: {
    color: palette.text.primary
  }
});
