import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { Date } from '../../../../Date';
import { ReadonlyCheckbox } from '../../../../ReadonlyCheckbox';

export const ChiropractorClaims = ({ claim }) => {
  const data = claim?.situational_number;

  return (
    <Grid container spacing={2} component={Box} px={2} py={3}>
      <Grid item xs={12}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">
            Chiropractor Claims
          </Typography>
        </Box>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Initial Treatment Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          disableMoment
          variant="subtitle2"
          iconFontSize="small"
          date={data?.initial_treatment_date && moment(data?.initial_treatment_date).format('L')}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Acute Manifestation Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          disableMoment
          variant="subtitle2"
          iconFontSize="small"
          date={data?.acute_manifestation_date && moment(data?.acute_manifestation_date).format('L')}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Last X-Ray Date
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Date
          disableMoment
          variant="subtitle2"
          iconFontSize="small"
          date={data?.last_xray_date && moment(data?.last_xray_date).format('L')}
        />
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          Nature of Condition
        </Typography>
      </Grid>

      <Grid item sm={3} xs={6}>
        <Typography variant="subtitle2">
          {data?.nature_condition?.label || '-'}
        </Typography>
      </Grid>

      <Grid item sm={3} xs={12}>
        <Typography variant="body2" color="textSecondary">
          Patient Condition Desc.
        </Typography>
      </Grid>

      <Grid item sm={9} xs={12}>
        <Typography variant="subtitle2">
          {data?.description || '-'}
        </Typography>
      </Grid>

      <Grid item xs={12} />

      <Grid item sm={3} xs={6}>
        <Typography variant="body2" color="textSecondary">
          X-Ray Available
        </Typography>
      </Grid>

      <Grid item sm={9} xs={6}>
        <ReadonlyCheckbox
          title={data?.xray_available?.label}
          iconColor="success"
          checked={!!data?.xray_available?.label}
        />
      </Grid>
    </Grid>
  );
};
