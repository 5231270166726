export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const ADD_REPORT = 'ADD_REPORT';
export const UPDATE_REPORT_IN_LIST = 'UPDATE_REPORT_IN_LIST';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const RESET_REPORTS = 'RESET_REPORTS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const MASS_DELETE_REPORTS = 'MASS_DELETE_REPORTS';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
