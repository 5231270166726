import { useSelector } from 'react-redux';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { LandingPage } from '../../app/LandingPage';
import { GuestMedicalProfile } from '../../app/Dashboard';
import { Route } from '../router';

export const PrivateRoute = ({ component: Component, ...props }) => {
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const { pathname } = useLocation();

  return (
    <Route
      {...props}

      render={(props) => isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Switch>
          <Route exact path="/" component={LandingPage} />

          <Route exact path="/medical-forms">
            <Redirect to="/" />
          </Route>

          <Route path="/medical-forms" component={GuestMedicalProfile} />

          <Redirect
            from={pathname}
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        </Switch>
      )}
    />
  );
};
