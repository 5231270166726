import { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { makeStyles, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { useDebounce } from '../../../../../helpers/hooks';
import { AppBar } from '../../../../../components/AppBar';
import {
  TextField,
  TasksSelect,
  OrderBy,
  KeyboardDatePicker
} from '../../../../../components/FormField';
import { FilesFilterContext, orderByOptions } from '../../FilesFilterContext';
import { styles } from './styles';

export const filterFieldsMap = {
  cases: 'cases'
};

const useStyles = makeStyles(styles);

export const FiltersBar = ({ hiddenFilterFields = [] }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { filter, setFilter } = useContext(FilesFilterContext);
  const [ fields, setFields ] = useState({});
  const debouncedFields = useDebounce(fields, 600);

  const toggleOrderDirection = (orderDirection) => {
    setFilter((filter) => ({ ...filter, order_direction: orderDirection }));
  };

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
  };

  const handleSelectChange = (name) => (option) => {
    setFilter((filter) => ({ ...filter, [name]: option.value }));
  };

  const handleTasksChange = (tasks) => {
    setFilter((filter) => ({ ...filter, tasks: tasks?.map(({ id }) => id) }));
  };

  const handleCasesChange = (cases) => {
    setFilter((filter) => ({ ...filter, cases: cases?.map(({ id }) => id) }));
  };

  const handleDatePickerChange = (name) => (date) => {
    setFilter((filter) => ({ ...filter, [name]: date }));
  };

  useEffect(() => {
    setFilter((filter) => ({ ...filter, ...debouncedFields }));
  }, [ debouncedFields ]);

  return (
    <AppBar className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <Formik
        initialValues={{
          original_filename: '',
          order_by: orderByOptions.find(({ value }) => value === filter.order_by)
        }}
        onSubmit={null}
      >
        {() => (
          <Grid container spacing={2}>
            <Grid item xs={isMobile ? 12 : 3}>
              <TextField
                name="original_filename"
                label="Search"
                placeholder="Search..."
                onChange={handleFieldChange('original_filename')}
              />
            </Grid>

            <Grid item xs={isMobile ? 12 : 3}>
              <KeyboardDatePicker
                name="updated_after"
                label="Updated after"
                onChange={handleDatePickerChange('updated_after')}
              />
            </Grid>

            <Grid item xs={isMobile ? 12 : 3}>
              <KeyboardDatePicker
                name="updated_before"
                label="Updated before"
                onChange={handleDatePickerChange('updated_before')}
              />
            </Grid>

            {!hiddenFilterFields.includes(filterFieldsMap.cases) &&
              <Grid item xs={isMobile ? 12 : 3}>
                <CasesSelect
                  multiple
                  name="cases"
                  label="Cases"
                  placeholder="Search case by name..."
                  onChange={handleCasesChange}
                />
              </Grid>
            }

            <Grid item xs={isMobile ? 12 : 3}>
              <TasksSelect
                multiple
                name="tasks"
                label="Tasks"
                placeholder="Search task by name..."
                params={{ cases: filter.cases }}
                onChange={handleTasksChange}
              />
            </Grid>

            <Grid item xs={isMobile ? 12 : 3}>
              <OrderBy
                isSearchable={false}
                name="order_by"
                value={orderByOptions.find(({ value }) => value === filter.order_by)}
                options={orderByOptions}
                orderDirection={filter.order_direction}
                onOptionChange={handleSelectChange('order_by')}
                onOrderDirectionChange={toggleOrderDirection}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </AppBar>
  );
};
