import { Grid, Typography } from '@material-ui/core';
import { CompanyLink } from '../../CompanyLink';
import { UserLink } from '../../UserLink';
import { Date } from '../../Date';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';

export const Header = ({ report }) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={6}>
        <Typography>Employee:</Typography>
      </Grid>

      <Grid item sm={8} xs={6}>
        <UserLink variant="h5" user={report?.user} />
      </Grid>

      <Grid item sm={4} xs={6}>
        <Typography>Company:</Typography>
      </Grid>

      <Grid item sm={8} xs={6}>
        <CompanyLink variant="h5" company={report?.company} />
      </Grid>

      {hasRole(rolesMap.admin, rolesMap.supervisor) && (
        <>
          <Grid item sm={4} xs={6}>
            <Typography>Rate:</Typography>
          </Grid>

          <Grid item sm={8} xs={6}>
            <Typography variant="h5">
              $ {report?.rate || '0'}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item sm={4} xs={12}>
        <Typography>Approved by: </Typography>
      </Grid>

      <Grid item sm={8} xs={12}>
        <UserLink variant="h5" user={report.approved_by} />
      </Grid>

      <Grid item sm={4} xs={12}>
        <Typography>Approved at: </Typography>
      </Grid>

      <Grid item sm={8} xs={12}>
        <Date variant="h5" date={report.approved_at} format="L, LT" />
      </Grid>

      <Grid item sm={4} xs={6}>
        <Typography>Supervisor:</Typography>
      </Grid>

      <Grid item sm={8} xs={6}>
        <UserLink variant="h5" user={report?.supervisor} />
      </Grid>
    </Grid>
  );
};
