import { useEffect, useState } from 'react';
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { NestedList } from '../../../../../../../components/NestedList';
import { DocumentsProvider } from '../../Documents/DocumentsProvider';
import { MainContent } from './MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Documents = ({ isOpenSwitches, form }) => {
  const classes = useStyles();
  const [ isOpen, setIsOpen ] = useState(false);
  const [ disableDivider, setDisableDivider ] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  const initialState = {
    filter: {
      medical_form_id: [ form.id ]
    }
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      disableDivider={disableDivider}
      isOpen={isOpen}
      title="Documents"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
      className={classes.root}
    >
      <Box p={!isSmallScreen ? 3 : 1}>
        <DocumentsProvider initialState={initialState}>
          <MainContent setDisableDivider={setDisableDivider} />
        </DocumentsProvider>
      </Box>
    </NestedList>
  );
};
