import { truncate } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import WorkOutlineIcon from '@material-ui/icons/WorkOutlineOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { IconButton } from '../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../components/Tooltip';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({
  caseItem,
  onClose,
  onUpdate,
  onDelete
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={isMobile ? 0 : 2}
      alignItems={isMobile ? 'center' : 'flex-start'}
      component={Box}
      pl={isMobile ? 1 : 2}
      pr={1}
      py={2}
    >
      <Grid item xs={2}>
        <div className={classes.icon}>
          <WorkOutlineIcon fontSize="inherit" />
        </div>
      </Grid>

      <Grid item xs={8}>
        <Typography
          title={caseItem.file_number}
          variant="body2"
          color="textSecondary"
        >
          Case # {caseItem.file_number}
        </Typography>

        <Typography title={caseItem.name} variant="h6">
          {caseItem.name}
        </Typography>

        <Tooltip
          isExistTooltip={!!caseItem.description}
          leaveDelay={200}
          placement="bottom-start"
          title={caseItem.description}
        >
          <Typography color="textSecondary" variant="caption" align="justify">
            {truncate(caseItem.description, { length: 110 })}
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <Tooltip title="Open page on new window">
              <IconButton component={RouterLink} to={`/cases/${caseItem.id}`} target="_blank">
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <VerticalDivider verticalGutters={1} />

          {onUpdate &&
            <Grid item>
              <IconButton color="primary" onClick={onUpdate}>
                <EditIcon />
              </IconButton>

              <IconButton color="error" onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          }

          {onClose &&
            <Grid item>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
