import { useContext, useEffect } from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { TasksFilterContextProvider, TasksProvider } from '../../../../components/tasks/TasksProvider';
import { ColumnsList } from './ColumnsList';
import { FiltersBar } from './FiltersBar';
import { ActionsBar } from './ActionsBar';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainWindow = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  useEffect(() => {
    if (isMobile) {
      setViewVariant(viewVariantsMap.columns);
    }
  }, [ isMobile ]);

  return (
    <TasksFilterContextProvider>
      <div className={classes.root}>
        <ActionsBar filterOpenButton={filterOpenButton} />

        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <FiltersBar filterKey={filtersKeysMap.tasks_main} />
          </FilterBarWrapper>
        </ScrollWrapper>

        <div className={classes.tasksWindow}>
          {(viewVariant === viewVariantsMap.list) &&
            <TasksProvider>
              <List />
            </TasksProvider>
          }

          {(viewVariant === viewVariantsMap.columns) && <ColumnsList />}
        </div>
      </div>
    </TasksFilterContextProvider>
  );
};
