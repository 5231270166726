import { useContext, useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';
import { useResizeObserver } from '../../../../helpers/hooks';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { ActionsBar } from './ActionsBar';
import { TranscribesProvider } from './TranscribesProvider';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

export const WIDTH_BREAKPOINT = 1200;

export const PageContent = () => {
  const rootRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const { viewVariant, setViewVariant } = useContext(LayoutContext);

  useEffect(() => {
    if (width < WIDTH_BREAKPOINT) {
      setViewVariant(viewVariantsMap.grid);
    }
  }, [ width ]);

  return (
    <Box ref={rootRef} display="flex" flexDirection="column" height="100%">
      <TranscribesProvider>
        <ActionsBar />
        <FiltersBar filterKey={filtersKeysMap.transcribes} />

        <Box flexGrow={1}>
          {(viewVariant === viewVariantsMap.list) && <List />}
          {(viewVariant === viewVariantsMap.grid) && <Grid />}
        </Box>
      </TranscribesProvider>
    </Box>
  );
};
