import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/EditOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { hasRole } from '../../../../../../../utils/hasRole';
import { ErrorBoundary } from '../../../../../../../components/ErrorBoundary';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { IconButton } from '../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../components/Loader';
import { MedicalProfileContext } from '../../MedicalProfileProvider';
import { PatientContent } from './PatientContent';
import { PatientInfoModal } from './PatientInfoModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PatientInfo = () => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { isFetched, medicalProfile, getMedicalProfile } = useContext(MedicalProfileContext);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ initialValues, setInitialValues ] = useState(null);

  const toggleIsOpen = () => setIsOpen((state) => !state);

  const openWidgetModal = () => {
    openModal(PatientInfoModal, {
      payload: { initialValues },
      onModalResolved: () => getMedicalProfile()
    });
  };

  useEffect(() => {
    isFetched && setInitialValues(medicalProfile);
  }, [ medicalProfile ]);

  return (
    <ContentCard
      autoHeightMax={800}
      isCollapsed={!isOpen}
      title="Patient Info"
      className={classes.root}
      rightActions={[
        !hasRole('client', 'patient') &&
          <IconButton color="inherit" onClick={openWidgetModal}>
            <EditIcon fontSize="small" />
          </IconButton>,

        <IconButton color="inherit" onClick={toggleIsOpen}>
          {isOpen ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        </IconButton>
      ]}
    >
      <Loader loading={!initialValues} render={
        () => (
          <ErrorBoundary>
            <PatientContent initialValues={initialValues}/>
          </ErrorBoundary>
        )}
      />
    </ContentCard>
  );
};
