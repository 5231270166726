import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../helpers/createReduxReducer';

// Action types
const SAVE_FILTER = 'SAVE_FILTER';

export const saveFilter = (filter) => ({ type : SAVE_FILTER, payload: filter });

// Reducer
const initialState = {};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [SAVE_FILTER]: (state, { key, filter }) => {
    return { ...state, [key]: filter };
  }
});
