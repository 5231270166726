import { Typography } from '@material-ui/core';
import { Row } from './Row';

export const ListNotes = ({ comments, onDeleteNote, onClickSecond }) => {

  return (
    <>
      {!comments.length &&
        <Typography align="center">
          Here will be some information from video messages
        </Typography>
      }

      {comments.map((item) =>
        <Row
          key={item.id}
          onDeleteNote={onDeleteNote}
          onClickSecond={onClickSecond}
          item={item}
        />
      )}
    </>
  );
};

