import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import moment from 'moment';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCashMultiple } from '@mdi/js';
import Icon from '@mdi/react';
import * as paymentsApi from '../../../api/billing';
import { SvgIcon } from '../../SvgIcon';
import { Loader } from '../../Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../Modal';
import {
  billingParticipantsTypesMap,
  billingParticipantsTypesOptions
} from '../billingParticipantsTypesOptions';
import { Body } from './Body';
import { validationSchema } from './validationSchema';

export const CreateCopayModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    billingInfo,
    model_type,
    model_id,
    bill_from,
    bill_to,
    patient
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(({ profile }) => profile.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const createPayment = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return paymentsApi.createPaymentCopay(values).then((data) => {
      enqueueSnackbar('Payment successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch((errors) => {
      if (errors) {
        if (errors?.status === 409) {
          enqueueSnackbar('Invoice already paid', { variant: 'error' });
        } else {
          setErrors(errors);
          enqueueSnackbar('Payment not created', { variant: 'error' });
        }
      }
    });
  };

  return (
    <Formik
      initialValues={{
        model_type,
        model_id,
        bill_from: {
          type: billingParticipantsTypesOptions.find(({ value }) => {
            return value === billingParticipantsTypesMap.company;
          }),
          id: currentUser.work?.company,

          ...bill_from
        },
        bill_to,
        method: null,
        number: null,
        note: null,
        date: moment().format(moment.HTML5_FMT.DATE),
        due_date: null,
        amount: billingInfo?.copay_balance || null
      }}
      validationSchema={validationSchema}
      onSubmit={createPayment}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiCashMultiple} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Patient Payment
            </ModalHeader>

            <ModalBody>
              <Body patient={patient} billingInfo={billingInfo} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
