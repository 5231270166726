import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../../../../../../helpers/dom';
import { UserLink } from '../../../../../../../../../../components/UserLink';
import { SavedFiltersContext } from '../../../../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.users}>
        <Box display="flex" flexDirection="column" width="100%" alignItems="center">
          {!params.users ? '-' : params?.users?.map((user) => (
            <Box display="flex" width="100%" alignItems="center" key={user?.id}>
              <UserLink
                noWrap
                size="sm"
                key={user.id}
                user={user}
              />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateFrom}>
        {params?.started_from ? moment(params?.started_from).format('L') : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateTo}>
        {params?.started_to ? moment(params?.started_to).format('L') : '-'}
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.orderBy}>
        {params?.order_by?.label || '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};

