import { useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Typography, useMediaQuery } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as templatesApi from '../../../../../../api/cases/task-template-groups';
import { stopPropagation } from '../../../../../../helpers/dom';
import {
  ListRow,
  ListRowCell,
  ListRowCellActions
} from '../../../../../../components/ListRow';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Loader } from '../../../../../../components/Loader';
import { PreviewModal } from '../../PreviewModal';
import { TaskTemplatesGroupsContext } from '../../TaskTemplatesGroupsProvider';
import { EditGroupModal } from '../../EditGroupModal';
import { minRowHeight, columnsWidths } from '../List';

export const Row = ({ isLoaded, item: group, recalculateHeight }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { updateGroup, deleteGroup } = useContext(TaskTemplatesGroupsContext);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const openPreview = () => {
    openModal(PreviewModal, {
      payload: { id: group.id }
    });
  };

  const handleEdit = () => {
    openModal(EditGroupModal, {
      payload: {
        id: group.id
      },

      onModalResolved: (group) => {
        updateGroup(group);
      }
    });
  };

  const handleDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        templatesApi.deleteTaskTemplateGroup(group.id).then(() => {
          deleteGroup(group);
          enqueueSnackbar('Group successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Group not deleted', { variant: 'error' });
        });
      }
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, group ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button minHeight={minRowHeight} onClick={openPreview}>
      <ListRowCell grow={isMobile} noWrap flexBasis={columnsWidths.name}>
        <Tooltip isExistTooltip={isMobile && !!group?.name} title={group?.name}>
          <Typography noWrap>
            {group?.name}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell noWrap grow flexBasis={columnsWidths.description}>
        <Tooltip isExistTooltip={!!group?.description} title={group?.description}>
          <Typography noWrap>
            {group?.description}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.createdDate}>
        {moment.unix(group.created_at).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.updatedDate}>
        {moment.unix(group.updated_at).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        <ListRowCellActions>
          <IconButton color="primary" onClick={stopPropagation(handleEdit)}>
            <EditIcon />
          </IconButton>

          <IconButton edge="end" color="error" onClick={stopPropagation(handleDelete)}>
            <DeleteIcon />
          </IconButton>
        </ListRowCellActions>
      </ListRowCell>
    </ListRow>
  );
};
