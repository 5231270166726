import { makeStyles } from '@material-ui/core';
import { renderRoutes } from '../../../components/router';
import { routes } from './routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ServicesPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {renderRoutes(routes)}
    </div>
  );
};
