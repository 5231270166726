import { useContext, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../../CustomScrollbars';
import { InfiniteGridLoader } from '../../../../../../InfiniteLoader';
import { ScheduleContext } from '../../../../../../schedule-events';
import { Loader } from '../../../../../../Loader';
import { Card } from './Card';

export const Grid = ({ RowProps }) => {
  const {
    events,
    isFetched,
    isFetching,
    filter,
    reloadEvents,
    loadNextPage
  } = useContext(ScheduleContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    reloadEvents();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%', minHeight: 500 }}>
      <Box pl={1} py={1}>
        <Loader p={2} loading={!isFetched} render={
          () => !filter.total ? (
            <Box p={2}>
              <Typography align="center">No events found</Typography>
            </Box>
          ) : (
            <InfiniteGridLoader
              items={events}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={filter}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              CellProps={RowProps}
              scrollElementRef={scrollElementRef}
              cellWidth={290}
              cellHeight={340}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
