import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalBase } from './ModalBase';

export const ModalsContext = createContext({
  addModal: () => {},
  deleteModal: () => {}
});

export const ModalsProvider = ({ children }) => {
  const history = useHistory();
  const modalsCount = useRef(0);
  const [ modals, setModals ] = useState([]);

  const addModal = useCallback((modal) => {
    const modalID = modalsCount.current++;

    setModals((modals) => modals.concat({
      ...modal,

      modalID,
      onModalExited: deleteModal
    }));

    return modalID;
  }, []);

  const deleteModal = useCallback((modalID) => {
    setModals((modals) => modals.filter((modal) => modal.modalID !== modalID ));
  }, []);

  useEffect(() => {
    const removeListener = history.listen(() => {
      setModals((modals) => modals.map((modal) => ({ ...modal, open: false })));
    });

    return () => {
      removeListener();
    };
  }, []);

  const providerValue = useMemo(() => ({
    addModal,
    deleteModal
  }), [ addModal, deleteModal ]);

  return (
    <ModalsContext.Provider value={providerValue}>
      {children}

      {modals.map((modal) => (
        <ModalBase open key={modal.modalID} {...modal} />
      ))}
    </ModalsContext.Provider>
  );
};
