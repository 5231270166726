export const styles = ({ palette, spacing, shape: { borderRadius } }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: `${spacing()}px`,
    padding: spacing(1, 2)
  },

  bar: {
    display: 'flex',
    flexGrow: 1,
    height: 12,
    borderRadius,
    overflow: 'hidden'
  },

  label: {
    paddingLeft: spacing(1.5),
    paddingRight: spacing(1.5),
    color: palette.common.white,
    borderRadius
  },

  done: {
    backgroundColor: palette.success.main
  },

  notDone: {
    backgroundColor: palette.error.main
  }
});
