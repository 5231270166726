import { FieldArray, useFormikContext } from 'formik';
import {
  Box,
  Collapse,
  FormControlLabel,
  Radio,
  Grid,
  Typography
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { ReadonlyCheckbox } from '../../../../components/ReadonlyCheckbox';
import { useModal } from '../../../../components/ModalsProvider';
import { IconButton } from '../../../../components/IconButton';
import { Fab } from '../../../../components/Fab';
import {
  AddressAutocomplete,
  RadioGroup,
  TextField,
  UnitDesignator
} from '../../../../components/FormField';

export const Venue = () => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();

  const handleAddressChange = (index) => (option) => {
    if (option) {
      setFieldValue(`venue.${index}.street`, (option.street_number ? option.street_number + ' ' : '') + option.street);
      setFieldValue(`venue.${index}.state`, option.state);
      setFieldValue(`venue.${index}.city`, option.city);
      setFieldValue(`venue.${index}.zip`, option.zip);
      setFieldValue(`venue.${index}.country`, option.country);
      setFieldValue('venue.lat', option.lat);
      setFieldValue('venue.lng', option.lng);
    }
  };

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  const toggleMainVenue = (mainIndex) => () => {
    values.venue?.map(({ is_main }, index) => (
      setFieldValue(`venue.${index}.is_main`, mainIndex !== index ? 0 : 1)
    ));
  };

  return (
    <Box mb={5}>
      <FieldArray
        name="venue"
        render={(arrayHelpers) => (
          <>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Typography variant="h3">Venue</Typography>
              </Box>

              <Fab
                size="small"
                color="primary"
                variant="extended"
                startIcon={<AddIcon/>}
                onClick={() => arrayHelpers.push({
                  country: null,
                  state: null,
                  city: null,
                  zip: null,
                  street: null,
                  designator: null,
                  unit_number: null,
                  is_main: 0
                })}
              >
                Add
              </Fab>
            </Box>

            <Collapse
              unmountOnExit
              in={!!values.venue?.length}
              timeout="auto"
            >
              {values.venue?.map((item, index) => (
                <Box key={index} my={4}>
                  <Grid container spacing={2} alignItems="center" justify="space-between">
                    <Grid item>
                      {values.venue[index].is_main ? (
                        <ReadonlyCheckbox checked fullWidth variant="h5" title={`${index + 1}. Main Venue`} />
                      ) : (
                        <Typography variant="h5" color="textSecondary">
                          {`${index + 1}. Venue`}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item>
                      <IconButton
                        edge="end"
                        size="small"
                        color="error"
                        onClick={handleClearField(arrayHelpers, index)}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="flex-end">
                    <Grid item sm={6} xs={12}>
                      <AddressAutocomplete
                        name={`venue.${index}.street`}
                        label="Street"
                        onChange={handleAddressChange(index)}
                      />
                    </Grid>

                    <Grid item sm={3} xs={6}>
                      <UnitDesignator
                        required={!!values.venue?.[index]?.unit_number}
                        name={`venue.${index}.designator`}
                        label="Unit Designator"
                        placeholder="Select designator..."
                      />
                    </Grid>

                    <Grid item sm={3} xs={6}>
                      <TextField
                        zeroMinWidth
                        name={`venue.${index}.unit_number`}
                        label="Apt./Suite/Room"
                      />
                    </Grid>

                    <Grid item sm={4} xs={7}>
                      <TextField
                        zeroMinWidth
                        name={`venue.${index}.city`}
                        label="City"
                      />
                    </Grid>

                    <Grid item sm={2} xs={6}>
                      <TextField
                        zeroMinWidth
                        name={`venue.${index}.state`}
                        label="State/Region"
                      />
                    </Grid>

                    <Grid item sm={2} xs={6}>
                      <TextField
                        zeroMinWidth
                        name={`venue.${index}.zip`}
                        label="ZIP Code"
                      />
                    </Grid>

                    <Grid item sm={3} xs={5}>
                      <TextField
                        zeroMinWidth
                        name={`venue.${index}.country`}
                        label="Country"
                      />
                    </Grid>

                    <Grid item sm={3} xs={5}>
                      <RadioGroup name={`venue.${index}.is_main`}>
                        <FormControlLabel
                          value={values?.venue[index]?.is_main || '1'}
                          label="Set as main"
                          control={<Radio color="primary"/>}
                          onChange={toggleMainVenue(index)}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Collapse>
          </>
        )}
      />
    </Box>
  );
};
