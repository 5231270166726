import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_FEEDS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_FEEDS_SUCCESS]: (
    { filter, feeds, ...state },
    { data: newFeed, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      feeds: (page > 1
        ? feeds.filter(({ id }) => {
          return !newFeed.find((loadedClaim) => id === loadedClaim.id);
        }).concat(newFeed)
        : newFeed
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_FEEDS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_FEED]: ({ feeds, pagination, filter, ...state }, feed) => {
    const total = pagination.total + 1;
    const page = Math.ceil((feeds.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      feeds: [ feed, ...feeds ]
    };
  },

  [types.UPDATE_FEED]: (state, feed) => {
    return {
      ...state,

      feeds: state.feeds.map((item) => {
        return item.id === feed.id ? { ...item, ...feed } : item;
      })
    };
  },

  [types.DELETE_FEED]: (state, feedID) => {
    const { pagination, filter, feeds, selectedFeedsIDs } = state;
    const filteredFeeds = feeds.filter(({ id }) => feedID !== id);

    if (filteredFeeds.length === feeds.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((feeds.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      feeds: filteredFeeds,
      selectedFeedsIDs: selectedFeedsIDs.filter((id) => feedID !== id)
    };
  },

  [types.ADD_FEEDS_TO_SELECTED]: ({
    selectedFeed,
    selectedFeedsIDs,

    ...state
  }, feeds) => {
    return {
      ...state,

      selectedFeed: selectedFeed.concat(
        feeds.filter(({ id }) => !selectedFeedsIDs.includes(id))
      ),
      selectedFeedsIDs: [ ...new Set(selectedFeedsIDs.concat(feeds.map(({ id }) => id))) ]
    };
  },

  [types.DELETE_FEEDS_FROM_SELECTED]: ({ selectedFeedsIDs, ...state }, feedsIDs) => {
    return {
      ...state,

      selectedFeedsIDs: selectedFeedsIDs.filter((id) => feedsIDs.indexOf(id) === -1)
    };
  }
});
