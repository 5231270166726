import moment from 'moment';
import { Yup } from '../../../utils/validation';

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().required().min(2).max(36),
  last_name: Yup.string().required().min(2).max(36),
  birthday: Yup.date().format(moment.HTML5_FMT.DATE).nullable(),
  rate: Yup.number().nullable().integer().positive().required().min(1),
  phone_number: Yup.string().nullable().notRequired().min(2).max(20),
  fax: Yup.string().nullable().notRequired().min(2).max(20),
  mobile_phone_number: Yup.string().nullable().notRequired().min(2).max(20),
  gender: Yup.string().required(),
  ssn: Yup.string().nullable(),
  role: Yup.string().nullable().required(),
  is_active: Yup.bool().required(),
  email: Yup.string().email().required(),
  home: Yup.object().shape({
    street: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable().min(2),
    zip: Yup.string().nullable(),
    country: Yup.string().nullable().min(2),
    note: Yup.string().nullable().min(3).max(5000),
    designator: Yup.mixed().nullable().when('unit_number', (unitNumber, schema) => {
      return unitNumber ? schema.required() : schema;
    }),
    unit_number: Yup.string().nullable().min(1).max(255)
  }),
  work: Yup.object().shape({
    company_id: Yup.mixed().nullable(),
    office_id: Yup.mixed().nullable(),
    position: Yup.string().nullable(),
    department: Yup.string().nullable(),
    pqrs_category: Yup.string().nullable().max(255)
  }),
  provider_id_types: Yup.array().nullable().when('role', {
    is: (value) => value === 'doctor',
    then: Yup.array().nullable().of(Yup.object().shape({
      provider_type_id: Yup.mixed().nullable().required(),
      identifier: Yup.string().nullable().required()
    })).unique(
      'provider_type_id',
      'Duplicate Provider ID Type',
      (value) => value.provider_type_id
    ),
    otherwise: Yup.array().nullable().notRequired()
  }),
  dl: Yup.string().nullable(),
  exp: Yup.string().nullable().when('dl', {
    is: (value) => !!value,
    then: Yup.string().nullable().required()
  })
});
