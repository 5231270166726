export const initialValues = {
  // Indicators
  emergency_indicator: 'n/a',
  family_planning: 'n/a',
  epsdt: 'n/a',
  copay_exempt: 'n/a',
  reference_code: null,
  line_note: null,
  authorization_id: null,

  // National Drug Code
  package_number: null,
  quantity: null,
  unit: null,
  unit_price: null,

  // Lab
  hemoglobin: null,
  hematocrit: null,

  // Durable Medical Equipment
  length_medical_necessity: null,
  rental_price: null,
  purchase_price: null,
  rental_type: null,
  dmerc_condition_indicator: 'n/a',
  condition_indicators: null,
  certification_type_code: null,
  dme_duration: null,
  certification_revision_date: null,
  begin_therapy_date: null,
  last_certification_date: null
};
