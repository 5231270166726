export const styles = ({ spacing, palette }) => ({
  root: {
    padding: spacing(0, 2)
  },

  root_widget: {
    padding: 0
  },

  loader: {
    alignSelf: 'center'
  },

  trackTypeIcon: {
    color: palette.grey[500]
  },

  trackTypeIcon_user: {
    color: palette.purple
  },

  trackTypeIcon_tracker: {
    color: palette.violet
  },

  parentTitleLink: {
    cursor: 'pointer'
  },

  cardBody: {
    minHeight: 80,
    width: '100%',
    border: `1px dashed ${palette.grey[200]}`,
    background: palette.grey[25]
  }
});
