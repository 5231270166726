import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import * as reminderEventsApi from '../../../../api/cases/reminders/events';
import {
  getReminderGroupTitle
} from '../case-reminders/getReminderGroupTitle';
import {
  CaseReminderEventsContext,
  EditReminderEventModal,
  ReminderEventViewModal
} from './index';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { useModal } from '../../../ModalsProvider';

export const useReminderEvent = (reminderEvent) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { updateReminderEvent, deleteReminderEvent } = useContext(CaseReminderEventsContext);
  const { groupTitle, shortGroupTitle } = getReminderGroupTitle(reminderEvent?.group);

  const editEvent = () => {
    openModal(EditReminderEventModal, {
      payload: {
        reminderEvent
      },

      onModalResolved: (reminderEvent) => {
        updateReminderEvent(reminderEvent);
      }
    });
  };

  const openRelativeReminderEventPreview = () => {
    openModal(ReminderEventViewModal, {
      payload: {
        reminderEventId: reminderEvent.previous_event_id
      }
    });
  };

  const deleteEvent = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        reminderEventsApi.deleteCaseReminderEvent(reminderEvent.id).then(() => {
          deleteReminderEvent(reminderEvent);
          enqueueSnackbar('Event successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Event not deleted', { variant: 'error' });
        });
      }
    });
  };

  return {
    groupTitle,
    shortGroupTitle,
    openRelativeReminderEventPreview,
    editEvent,
    deleteEvent
  };
};
