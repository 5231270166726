import { useMediaQuery, useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ThreadMessagesFilterContextProvider } from '../ThreadMessagesContext';
import { MessengerContextProvider } from '../MessengerProvider';
import { ChatNotifications } from './ChatNotifications';
import { OpenedChatThread } from './OpenedChatThread';
import { Chat } from './Chat';

export const ChatWidget = ({ onChatWidgetClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const [ isMinimized, setIsMinimized ] = useState(true);

  const toggleMinimized = () => {
    setIsMinimized((isMinimized) => !isMinimized);
  };

  useEffect(() => {
    setIsMinimized(!isMobile);
  }, [ isMobile ]);

  return (
    <MessengerContextProvider>
      <ThreadMessagesFilterContextProvider>
        <Chat
          isMinimized={isMinimized}
          toggleMinimized={toggleMinimized}
          onWidgetClose={onChatWidgetClose}
        />

        <OpenedChatThread widgetIsMinimized={isMinimized} />

        <ChatNotifications />
      </ThreadMessagesFilterContextProvider>
    </MessengerContextProvider>
  );
};
