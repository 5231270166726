import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { SvgIcon } from '../../../../../../../../../components/SvgIcon';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import { CodesContext } from '../../../../CodesContext';
import { CopyPayerModal } from '../../../../CopyPayerModal';
import { PayerModal } from '../../../../PayerModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PayerMenu = ({ baseUrl, code, onChange }) => {
  const classes = useStyles();
  const codesContext = useContext(CodesContext);
  const { openModal } = useModal();

  const handeDeleteCode = () => codesContext.deleteCode(code);

  const createCodeCopy = () => {
    openModal(CopyPayerModal, {
      payload: {
        codeID: code.id,
        codesContext
      }
    });
  };

  const openPayerModal = () => {
    openModal(PayerModal, {
      payload: {
        baseUrl,
        codeID: code.id,
        createCodeCopy,
        codesContext
      }
    });
  };

  return (
    <List className={classes.root}>
      <MenuItem onClick={openPayerModal}>
        <ListItemIcon>
          <EyeIcon />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      <MenuItem disabled={!code.deactivated_at} component={Link} to={`${baseUrl}/payer/${code.id}/edit`}>
        <ListItemIcon>
          <SvgIcon color="primary">
            <EditIcon />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={createCodeCopy}>
        <ListItemIcon>
          <SvgIcon color="secondary">
            <FileCopyIcon />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Copy" />
      </MenuItem>

      <MenuItem onClick={onChange}>
        <ListItemIcon>
          <SvgIcon color={code.deactivated_at ? 'warning' : 'success'}>
            {code.deactivated_at ? <LockIcon /> : <LockOpenIcon />}
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary={code.deactivated_at ? 'Deactivated' : 'Activated'} />
      </MenuItem>

      <MenuItem onClick={handeDeleteCode}>
        <ListItemIcon>
          <SvgIcon color="error">
            <DeleteIcon />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
