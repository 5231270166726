import { Box, Grid, Link, Typography } from '@material-ui/core';
import { ColorPreview } from '../../../../../ColorPreview';
import { CardItem, CardHeader } from '../../../../../Cards';
import { useModal } from '../../../../../ModalsProvider';
import { ReminderEventViewModal } from '../../ReminderEventViewModal';

import { Body } from './Body';

export const Card = ({ event = {} }) => {
  const { openModal } = useModal();

  const openRelativeReminderEventPreview = () => {
    openModal(ReminderEventViewModal, {
      payload: {
        reminderEventId: event.previous_event_id
      }
    });
  };

  return (
    <CardItem disableHover  py={1} px={2}>
      <CardHeader
        disableCheckBox
        disableCardMenu
        childrenWidth={12}
      >
        <Grid container spacing={2}>
          <Grid item xs>
            <Box display="flex" alignItems="center">
              {!!event?.group &&
                <Box display="flex" mr={1}>
                  <ColorPreview color={event?.group?.color}/>
                </Box>
              }

              <Typography>
                {event?.group?.name || '-'}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Typography variant="subtitle1">
              {event.interval} {event.period}
            </Typography>
          </Grid>
        </Grid>
      </CardHeader>

      <Box py={2}>
        <Body event={event} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="subtitle1">
                {event.type}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Event Type
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs>
              {event.previousEvent ? (
                <Link variant="subtitle1" onClick={openRelativeReminderEventPreview}>
                  {event.previousEvent?.position} {event.previousEvent?.type || '-'}
                </Link>
              ) : (
                <Typography variant="subtitle1">
                   No
                </Typography>
              )}
            </Grid>

            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                Relative
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardItem>
  );
};
