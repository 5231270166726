import { Fragment, useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { truncate } from 'lodash';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import Add from '@material-ui/icons/Add';
import { preventDefault, stopPropagation } from '../../../../../../../helpers/dom';
import {
  TaskPreviewContext,
  TaskPreviewContextProvider
} from '../../../../../../../components/tasks/TasksProvider';
import { Button } from '../../../../../../../components/Button';
import { Members } from '../../../../../../../components/Members';
import { Popper } from '../../../../../../../components/Popper';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { PreviewTaskMember } from '../../../../PreviewTaskMember';
import { AddCase } from '../../../../AddCase';
import { DueDate } from '../../../../DueDate';
import { ProgressBar } from '../../../ProgressBar';
import { Label } from '../../../Label';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ disableProvider = false, task }) => {
  const classes = useStyles();
  const [ parent, setParent ] = useState(task);
  const taskPreviewContext = useContext(TaskPreviewContext);
  const owner = task?.users?.find(({ id }) => id === task.owner_id);
  const Wrapper = !disableProvider ? TaskPreviewContextProvider : Fragment;
  const wrapperProps = !disableProvider ? { selectedTaskId: task.id } : {};

  return (
    <Wrapper {...wrapperProps}>
      <Box flexGrow={1}>
        <Box color="info.main">
          {task.parent ? (
            <Link
              noWrap
              variant="subtitle2"
              color="inherit"
              title={task.parent.name}
              target="_blank"
              component={RouterLink}
              to={`/cases/${task?.parent?.id}`}
              onClick={stopPropagation()}
            >
              {task.parent.name}
            </Link>
          ) : !taskPreviewContext && (
            <Popper
              placement="bottom-start"
              anchorRenderer={
                ({ anchorRef, handleToggle }) => (
                  <Button
                    color="primary"
                    size="small"
                    buttonRef={anchorRef}
                    onClick={preventDefault(handleToggle)}
                    className={classes.taskBodyButton}
                  >
                    <Add className={classes.taskBodyIcon} /> Add case
                  </Button>
                )
              }
            >
              {({ handleClose }) => (
                <AddCase withSubmit task={task} onClose={handleClose} />
              )}
            </Popper>
          )}
        </Box>

        <Box fontWeight="normal" height={42}>
          <Tooltip
            isExistTooltip={!!task.description}
            leaveDelay={200}
            placement="bottom-start"
            title={task.description}
          >
            <Typography color="textSecondary" component="p" variant="caption" align="justify">
              {task.description ? truncate(task.description, { length: 75 }) : 'No info.'}
            </Typography>
          </Tooltip>
        </Box>

        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {task.due_at &&
            <Box display="flex" alignItems="center" mr={1}>
              <InsertInvitationOutlinedIcon className={classes.iconCalendar} />
              <DueDate task={task}/>
            </Box>
          }

          {task.priorityLabel && <Label name={task.priorityLabel} color={task.priority}/>}
        </Box>

        <Box position="relative" mt={3.5} mb={1}>
          <ProgressBar
            gridProgress
            logged={task.logged_time}
            estimate={task.estimated_time}
            files_count={task.files_count}
            comments_count={task.comments_count}
          />
        </Box>

        {task.type?.name &&
          <div className={classes.badges}>
            <Label name={task.type?.name} color="grey" />
          </div>
        }
      </Box>

      <Box display="flex" pt={0.5} pb={1}>
        <Members
          isLink
          isParent
          noWrap
          maxVisible={6}
          owner={owner}
          users={task.users || []}
          MenuComponent={PreviewTaskMember}
          menuProps={{
            disablePreview: true,
            parent: parent,
            onUpdate: setParent
          }}
        />
      </Box>
    </Wrapper>
  );
};
