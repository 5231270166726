import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiAutoFix } from '@mdi/js';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { styles } from '../../../../app/Dashboard/GuestMedicalProfile/PrivatePacket/GeneralInfo/styles';
import { UserContext } from '../../../../app/Dashboard/Members/User/UserProvider';
import {
  AddressAutocomplete,
  KeyboardDatePicker,
  PhoneField,
  SsnField,
  TextField,
  UnitDesignator
} from '../../../FormField';
import { IconButton } from '../../../IconButton';
import { SvgIcon } from '../../../SvgIcon';
import { Tooltip } from '../../../Tooltip';

const useStyles = makeStyles(styles);

export const Body = ({ isUnconfirmed = false }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const profile = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext?.user : profile?.user;
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);

  const handleAddressChange = (option) => {
    if (option) {
      setFieldValue('home.street', (option.street_number ? option.street_number + ' ' : '') + option.street);
      setFieldValue('home.state', option.state);
      setFieldValue('home.city', option.city);
      setFieldValue('home.zip', option.zip);
      setFieldValue('home.country', option.country);
      setFieldValue('home.lat', option.lat);
      setFieldValue('home.lng', option.lng);
    }
  };

  const handleSetUserPhoneNumbers = (name) => () => {
    if (user) {
      setFieldValue(name, name === 'mobile_phone' ? user?.mobile_phone_number : user?.phone_number);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography className={classes.formTitle}>
          General Info
        </Typography>
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <TextField
          required
          disabled={!!values?.id && !isUnconfirmed}
          name="first_name"
          label="First name"
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <TextField
          required
          disabled={!!values?.id && !isUnconfirmed}
          name="last_name"
          label="Last name"
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <KeyboardDatePicker
          required
          name="birthday"
          label="Date of Birth"
          outputFormat="YYYY-MM-DD"
          placeholder="MM/DD/YYYY"
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        {isAuthenticated && !isUnconfirmed && !hasRole(rolesMap.doctor, rolesMap.medic) && (
          <SsnField
            disabled={!!user?.ssn}
            name="ssn"
          />
        )}

        {(!isAuthenticated || isUnconfirmed) && (
          <SsnField name="ssn" />
        )}
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <PhoneField
          required
          endAdornment={!values?.phone}
          name="phone_number"
          label="Phone"
          iconAdornment={!!user?.id && (
            <Tooltip isExistTooltip={!values?.phone} title="Set phone from user profile">
              <IconButton size="small" color="primary" onClick={handleSetUserPhoneNumbers('phone')}>
                <SvgIcon fontSize="small"><Icon path={mdiAutoFix} /></SvgIcon>
              </IconButton>
            </Tooltip>
          )}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <PhoneField
          endAdornment={!values?.mobile_phone}
          name="mobile_phone"
          label="Mobile Phone"
          iconAdornment={!!user?.id && (
            <Tooltip isExistTooltip={!values?.phone} title="Set phone from user profile">
              <IconButton size="small" color="primary" onClick={handleSetUserPhoneNumbers('phone')}>
                <SvgIcon fontSize="small"><Icon path={mdiAutoFix} /></SvgIcon>
              </IconButton>
            </Tooltip>
          )}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <TextField
          name="email"
          label="Email"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.formTitle}>
          Address
        </Typography>
      </Grid>

      <Grid item md={3} xs={12}>
        <AddressAutocomplete
          required
          name="home.street"
          label="Street"
          onChange={handleAddressChange}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <UnitDesignator
          required={!!values.home?.unit_number}
          name="home.designator"
          label="Unit Designator"
          placeholder="Select designator..."
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          disabled={!values.home?.designator}
          name="home.unit_number"
          label="Apartment/Suite/Room"
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <TextField
          required
          name="home.city"
          label="City"
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <TextField
          required
          name="home.state"
          label="State/Region"
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <TextField
          required
          name="home.zip"
          label="ZIP Code"
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <TextField
          required
          name="home.country"
          label="Country"
        />
      </Grid>
    </>
  );
};
