import { useContext } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import cn from 'classnames';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { DatePicker } from '../../../../../../components/FormField';
import { StatisticsFilterContext } from '../../StatisticsFilterContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FiltersBar = ({
  message,
  isOpenDayPicker,
  isOpenWeekPicker,
  isOpenMonthPicker,
  isOpenYearPicker,
  setMessage,
  setIsOpenDayPicker,
  setIsOpenWeekPicker,
  setIsOpenMonthPicker,
  setIsOpenYearPicker
}) => {
  const classes = useStyles();
  const { filter, setFilter } = useContext(StatisticsFilterContext);

  const getMessageTitle = (date, name) => (
    name === 'day' ? moment.unix(date).format('LL') :
      name === 'isoWeek' ? `Week of ${moment.unix(date).format('LL')}` :
        name === 'month' ? moment.unix(date).format('MM/YYYY') :
          moment.unix(date).format('YYYY')
  );

  const handleChange = (name) => (value) => {
    if (value) {
      const from = moment.unix(value).startOf(name).unix();
      const to = moment.unix(value).endOf(name).unix();

      setMessage(getMessageTitle(value, name));
      setFilter((filter) => ({ ...filter, from, to, period: name === 'isoWeek' ? 'week' : name }));
    }
  };

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const start = moment(selectedDate).utc().clone().startOf('isoWeek');
    const end = moment(selectedDate).utc().clone().endOf('isoWeek');

    const dayIsBetween = date.isBetween(start, end, 'day', '[]');
    const isFirstDay = date.isSame(start, 'day');
    const isLastDay = date.isSame(end, 'day');

    const wrapperClassName = cn({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });

    const dayClassName = cn(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });

    return (
      isOpenWeekPicker &&
        <div className={wrapperClassName}>
          <IconButton className={dayClassName}>
            <span> {date.format('D')} </span>
          </IconButton>
        </div>
    );
  };

  const formatWeekSelectLabel = (date, invalidLabel) => {
    if (date === null) {
      return '';
    }

    return date ? `Week of ${moment(date).utc().format('LL')}` : invalidLabel;
  };

  const handleOpenPicker = (callback) => () => callback(true);

  const handleClosePicker = (callback) => () => callback(false);

  return (
    <div className={classes.filtersBar}>
      <Formik
        onSubmit={null}
        initialValues={{
          week: filter.from,
          day: moment().unix(),
          month: moment().unix(),
          year: moment().unix()
        }}
      >
        {() => (
          <>
            <DatePicker
              open={isOpenDayPicker}
              name="day"
              onChange={handleChange('day')}
              onClose={handleClosePicker(setIsOpenDayPicker)}
              className={classes.datePicker}
            />

            <DatePicker
              open={isOpenWeekPicker}
              name="week"
              onChange={handleChange('isoWeek')}
              renderDay={renderWrappedWeekDay}
              labelFunc={formatWeekSelectLabel}
              onClose={handleClosePicker(setIsOpenWeekPicker)}
              className={classes.datePicker}
            />

            <DatePicker
              open={isOpenMonthPicker}
              name="month"
              openTo="month"
              views={[ 'year', 'month' ]}
              onChange={handleChange('month')}
              onClose={handleClosePicker(setIsOpenMonthPicker)}
              className={classes.datePicker}
            />

            <DatePicker
              open={isOpenYearPicker}
              name="year"
              views={[ 'year' ]}
              onChange={handleChange('year')}
              onClose={handleClosePicker(setIsOpenYearPicker)}
              className={classes.datePicker}
            />

            <div className={classes.actionButton}>
              <Typography className={classes.actionButton__title}>
                {message}
              </Typography>

              <ToggleButtonGroup
                exclusive
                size="small"
                value={filter.period}
              >
                <ToggleButton
                  value="day"
                  onClick={handleOpenPicker(setIsOpenDayPicker)}
                >
                  Day
                </ToggleButton>

                <ToggleButton
                  value="week"
                  onClick={handleOpenPicker(setIsOpenWeekPicker)}
                >
                  Week
                </ToggleButton>

                <ToggleButton
                  value="month"
                  onClick={handleOpenPicker(setIsOpenMonthPicker)}
                >
                  Month
                </ToggleButton>

                <ToggleButton
                  value="year"
                  onClick={handleOpenPicker(setIsOpenYearPicker)}
                >
                  Year
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
