import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import * as immunizationsApi from '../../../../api/codes/reports';
import { Select } from '../Select';

export const transformImmunizationToOption = (item) => item && ({
  value: item.id,
  label: item.name + (item.description && ` (${item.description})`),
  data: item
});

const fetchImmunizations = (params) => fetchDataForAsyncSelect((name) => {
  return immunizationsApi.fetchImmunizations({ params: { name, ...params } });
}, {
  withoutPagination: true,
  transformDataToOption: transformImmunizationToOption
});

export const ImmunizationsSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      isClearable
      formattedValue
      name="immunization_id"
      label="Name Immunizations"
      promiseWithOptions={fetchImmunizations(params)}
      {...props}
    />
  );
};
