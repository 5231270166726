import {
  transformLocalUseCodeToOption,
  transformSpcToOption
} from '../../FormField';
import { allClaimsStatuses } from '../claimsStatuses';
import {
  transformServiceLineForForm
} from '../ServiceLineFormModal/ServiceLineForm';
import { transformSituationalNumberForForm } from '../SituationalNumberFormModal';

export const transformAppointmentForClaimForm = (appointment) => {
  return appointment && {
    ...appointment,

    procedures: appointment.procedures?.map((procedure) => ({
      ...procedure,

      service_line: procedure.service_line?.map((serviceLine) => {
        return transformServiceLineForForm({ serviceLine, appointment });
      })
    }))
  };
};

export const transformClaimForForm = (claim) => claim && ({
  ...claim,

  patient_id: claim.patient,
  authorization_id: claim.authorization,
  office_id: claim.office,
  insurance_id: claim.insurance,
  adjuster_id: claim.adjuster,
  special_program_code_id: transformSpcToOption(claim.special_program_code),
  local_use_id: transformLocalUseCodeToOption(claim.local_use),
  provider_rendering_id: claim.provider_rendering,
  provider_supervising_id: claim.provider_supervising,
  provider_ordering_id: claim.provider_ordering,
  provider_primary_care_id: claim.provider_primary_care,
  provider_referring_id: claim.provider_referring,
  status: allClaimsStatuses.find(({ value }) => value === claim.status),
  situational_number: transformSituationalNumberForForm(claim.situational_number),
  appointment: transformAppointmentForClaimForm(claim.appointment),
  case_id: claim?.case || null
});

export const transformClaimReq = (claim) => {
  return {
    ...claim,

    appointments: claim.appointments?.map(({ id }) => id),
    icd_codes: claim.icd_codes?.map(({ id }) => id)
  };
};
