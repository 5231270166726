import { Hidden, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import LockIcon from '@material-ui/icons/LockOutlined';
import { SelectedItemsCount } from '../../../../../components/SelectedItemsCount';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { PageHeaderActions } from '../../../../../components/Page';
import { IconButton } from '../../../../../components/IconButton';

export const HeaderActions = ({
  selectedCodesIDs,
  pagination,
  toggleMassInactivatedItems,
  toggleMassActivatedItems,
  onMassDelete = () => {},
  filterOpenButton
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Hidden smUp>
        <PageHeaderActions>
          {filterOpenButton}
        </PageHeaderActions>
      </Hidden>

      {!!selectedCodesIDs.length &&
        <PageHeaderActions>
          <SelectedItemsCount length={selectedCodesIDs.length} total={pagination.total}/>
          <VerticalDivider verticalGutters={1} horizontalGutters={1}/>

          {!!toggleMassInactivatedItems && (
            <IconButton
              variant="contained"
              color="warning"
              onClick={toggleMassInactivatedItems}
            >
              <Tooltip title="Mass Deactivate Payers">
                <LockIcon />
              </Tooltip>
            </IconButton>
          )}

          {!!toggleMassActivatedItems && (
            <IconButton
              variant="contained"
              color="success"
              onClick={toggleMassActivatedItems}
            >
              <Tooltip title="Mass Activate Payers">
                <LockOpenIcon />
              </Tooltip>
            </IconButton>
          )}

          <IconButton
            variant="contained"
            color="error"
            onClick={onMassDelete}
          >
            <Tooltip title="Mass Delete Payers">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </PageHeaderActions>
      }

      {isMobile && (
        <Hidden only="xs">
          <PageHeaderActions>
            {filterOpenButton}
          </PageHeaderActions>
        </Hidden>
      )}
    </>
  );
};
