export const getPrintStyles = () => `
  html {
    padding: 0;
    background: none;
  }

  body {
    width: 100%;
    min-height: 0;
    padding: 0;
    background: none;
    border: 1px #D3D3D3 solid;
    border-radius: 0;
    background: none;
    box-shadow: none;
  }
`;
