import Color from 'color';

export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { pxToRem, fontWeightMedium },
  transitions
}) => ({
  root: {
    '& & $listItem:not($listItem_collapsed)': {
      paddingLeft: spacing(6)
    }
  },

  listItem: {
    paddingLeft: spacing(2.25),
    paddingRight: spacing(1.25),
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: pxToRem(14),
    transition: transitions.create(),

    '&:hover': {
      background: grey[800]
    }
  },

  listItem_collapsed: {
    paddingLeft: spacing(2),

    '& $listItem__icon svg': {
      fontSize: pxToRem(24)
    },

    '& $listItem__primaryText': {
      color: 'transparent',
      fontSize: 0
    }
  },

  listItem_activeParent: {
    '&, &:hover, &:active, &:focus': {
      background: Color(grey[700]).alpha(0.8).string(),
      color: palette.common.white
    }
  },

  listItem_active: {
    '&, &:hover, &:active, &:focus': {
      background: palette.primary.main,
      color: `${palette.primary.contrastText}`
    }
  },

  listItem__icon: {
    marginRight: spacing(0.75),
    color: 'inherit',

    '& svg': {
      fontSize: pxToRem(21),
      transition: transitions.create()
    }
  },

  listItem__primaryText: {
    color: 'inherit',
    fontWeight: fontWeightMedium,
    transition: transitions.create()
  }
});
