import { Link } from 'react-router-dom';
import {
  makeStyles,
  SvgIcon,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  IconButton,
  Box
} from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
import { mdiTrashCanOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Popper } from '../../../../../components/Popper';

const styles = () => ({
  root: {
    minWidth: 172
  }
});

const useStyles = makeStyles(styles);

export const CodesMenu = ({
  disabled = false,
  isActive = false,
  url = '',
  onToggle = () => {},
  onDelete = () => {}
}) => {
  const classes = useStyles();

  return (
    <Popper
      placement="left-start"
      anchorRenderer={({ anchorRef, handleToggle }) => (
        <IconButton disabled={disabled} buttonRef={anchorRef} onClick={handleToggle}>
          <MoreIcon />
        </IconButton>
      )}
    >
      <List className={classes.root}>
        <MenuItem onClick={onToggle}>
          <ListItemIcon>
            <Box color={isActive ? 'warning.main' : 'success.main'}>
              {isActive
                ? <LockIcon fontSize="inherit" color="inherit" />
                : <LockOpenIcon fontSize="inherit" color="inherit" />}
            </Box>
          </ListItemIcon>

          <ListItemText primary={isActive ? 'Deactivate' : 'Activate'} />
        </MenuItem>

        <MenuItem component={Link} to={url}>
          <ListItemIcon color="info">
            <Box color="info.main">
              <EditIcon fontSize="inherit" color="inherit" />
            </Box>
          </ListItemIcon>

          <ListItemText primary="Edit" />
        </MenuItem>

        <MenuItem onClick={onDelete}>
          <ListItemIcon>
            <SvgIcon fontSize="inherit" color="error">
              <Icon path={mdiTrashCanOutline} />
            </SvgIcon>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      </List>
    </Popper>
  );
};
