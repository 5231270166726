import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { makeStyles, Box, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import EditIcon from '@material-ui/icons/EditOutlined';
import { EditClaimModal } from '../../../../../../components/claims/EditClaimModal';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { useModal } from '../../../../../../components/ModalsProvider';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { Button } from '../../../../../../components/Button';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Email } from './Email';
import { Print } from './Print';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({
  disableSidebar = false,
  view = 'page',
  claim,
  sidebarIsOpen,
  setSidebarIsOpen,
  onUpdateClaim = () => {}
}) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const match = useRouteMatch();
  const claimsPageUrl = match.path === '/claims/:id'
    ? '/claims'
    : match.path === '/billing/claims/:id'
      ? '/billing/claims'
      : match.path === '/patients/:patientID/medical-info/claims/:id'
        ? `/patients/${match.params.patientID}/medical-info/claims`
        : '/';

  const editClaimModal = () => {
    openModal(EditClaimModal, {
      payload: { id: claim.id },
      onModalResolved: (values) => {
        onUpdateClaim(values);
      }
    });
  };

  const editButtonProps = isMobile || view === 'modal' ? {
    onClick: editClaimModal
  } : {
    component: RouterLink,
    to: `${match.url}/edit`
  };

  const handleDrawerOpen = () => {
    setSidebarIsOpen((isOpen) => !isOpen);
  };

  return (
    <div className={classes.root}>
      {!disableSidebar &&
        <IconButton
          edge="start"
          color="primary"
          title={sidebarIsOpen ? 'Hide sidebar' : 'Show sidebar'}
          onClick={handleDrawerOpen}
        >
          {sidebarIsOpen ? <ChevronLeftIcon/> : <MenuIcon/>}
        </IconButton>
      }

      <Box ml={1}>
        <Typography variant="h4">Claim # {claim.claim_number || '-'}</Typography>
      </Box>


      {!isMobile && (
        <>
          <VerticalDivider verticalGutters={1} horizontalGutters={3} />
          <Print claimID={claim.id} />
        </>
      )}

      {!hasRole(rolesMap.advocate) &&
        <>
          <VerticalDivider verticalGutters={1} horizontalGutters={isMobile ? 1 : 3}/>
          <Email claim={claim} />
        </>
      }

      <Box ml="auto" pl={1}>
        {view === 'page' && !isMobile && (
          <Tooltip title="Back to claims list">
            <Button color="primary" component={RouterLink} to={claimsPageUrl}>
              Back
            </Button>
          </Tooltip>
        )}

        {hasRole(rolesMap.admin, rolesMap.supervisor) &&
          <Tooltip title="Edit claim">
            <IconButton edge="end" color="primary" {...editButtonProps}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
      </Box>
    </div>
  );
};
