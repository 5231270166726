import { useState, createContext } from 'react';
import { initialState } from './initialState';

export const HistoryPaymentsFilterContext = createContext();

export const HistoryPaymentsFilterContextProvider = ({ children }) => {
  const [ filter, setFilter ] = useState(initialState.filter);

  const providerValue = {
    filter,
    setFilter
  };

  return (
    <HistoryPaymentsFilterContext.Provider value={providerValue}>
      {children}
    </HistoryPaymentsFilterContext.Provider>
  );
};
