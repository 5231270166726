export const styles = ({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 85,
    marginTop: spacing(2),
    padding: spacing(1.5, 2, 2),
    border: `1px dashed ${palette.grey[200]}`,
    backgroundColor: palette.grey[25]
  }
});
