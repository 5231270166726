import { useEffect } from 'react';
import moment from 'moment';
import { useModal as useModalHook } from 'react-modal-hook';
import { Link } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import {
  downloadFile as download,
  getFileNameWithoutExtension
} from '../../../../../../helpers/files';
import { FilePreviewModal } from '../../../../files-common';
import { menuOptionsTypesMap } from '../../../../files-common/menuOptionsTypes';
import { transcribeStatusLabelsMap } from '../../transcribeStatusesMap';
import { columnsWidths } from '../List';

export const getFileMenuOptions = (file) => [
  {
    type: menuOptionsTypesMap.menuItem,
    label: 'Download file',
    Icon: DownloadIcon,
    onClick: () => {
      download({ src: file.url, name: file.original_filename });
    }
  }
];

export const Row = ({ item: transcribe = {}, isLoaded, recalculateHeight }) => {
  const [ openAudioFilePreview, closeAudioFilePreview ] = useModalHook(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeAudioFilePreview
      }}
      payload={{
        disableFetch: true,
        file: {
          id: {},
          original_filename: transcribe.filename,
          url: transcribe.audio_url,
          aggregate_type: 'audio'
        },
        singleView: true
      }}
      getFileMenuOptions={getFileMenuOptions}
    />
  ), [ transcribe.audio_url ]);
  const [ openTextFilePreview, closeTextFilePreview ] = useModalHook(({ in: open, onExited }) => {
    const blob = new Blob([ transcribe.text ], {
      type : 'text/plain;charset=utf-8'
    });

    return (
      <FilePreviewModal
        DialogProps={{
          open,
          onExited,
          onCancel: closeTextFilePreview
        }}
        payload={{
          disableFetch: true,
          file: {
            id: {},
            original_filename: getFileNameWithoutExtension(transcribe.filename) + '.txt',
            extension: 'txt',
            url: URL.createObjectURL(blob),
            aggregate_type: 'text'
          },
          singleView: true
        }}
        getFileMenuOptions={getFileMenuOptions}
      />
    );
  }, [ transcribe.text ]);

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow px={0}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Link onClick={openAudioFilePreview}>{transcribe.filename}</Link>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.name}>
        {!transcribe.text ? '-' : <Link noWrap onClick={openTextFilePreview}>{transcribe.text}</Link>}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {moment.unix(transcribe.created_at).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.status}>
        {transcribeStatusLabelsMap[transcribe.status]}
      </ListRowCell>
    </ListRow>
  );
};
