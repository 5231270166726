import moment from 'moment';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { medicationsListToString } from '../../../../../../../utils/medicationsListToString';
import { TooltipInnerTitle } from '../../../TooltipInnerTitle';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ prescription }) => {
  const classes = useStyles();
  const medications = medicationsListToString(prescription?.medications);

  return (
    <>
      <Typography variant="h2">
        {prescription.prescribed_at ? moment.unix(prescription.prescribed_at).format('D MMM, YYYY') : '-'}
      </Typography>

      <Typography variant="caption" color="textSecondary">
        Prescribed
      </Typography>

      <div className={classes.root}>
        <Tooltip
          isExistTooltip={!!prescription?.medications?.length}
          title={prescription?.medications?.map((item, i) => (
            <TooltipInnerTitle
              key={item.ndc_code_id}
              index={i}
              item={item}
              prescription={prescription}
            />
          ))}
        >
          <Box color="success.main">
            <Typography noWrap variant="h6" color="inherit">{medications || '-'}</Typography>
          </Box>
        </Tooltip>

        <Typography variant="caption" color="textSecondary">
          Name Medication/Drug Name
        </Typography>
      </div>
    </>
  );
};
