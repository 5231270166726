export const styles = ({ spacing }) => ({
  sliderCustom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: spacing(3)
  },

  cancelButton: {
    marginRight: spacing()
  }
});
