import { Fragment, useContext, useRef, useState } from 'react';
import { Box, makeStyles, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { api } from '../../../../api';
import * as invoicesApi from '../../../../api/billing';
import { filtersKeysMap } from '../../../../store/lastFilters';
import * as pages from '../../../../store/dashboard/layout/pages';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { useResizeObserver } from '../../../../helpers/hooks/useResizeObserver';
import { useMemoEffect } from '../../../../helpers/hooks';
import { MobileWidgets } from '../../../../components/Widgets/MobileWidgets';
import { CustomScrollbars } from '../../../../components/CustomScrollbars';
import { Scrollbars } from '../../../../components/Scrollbars';
import { Widgets } from '../../../../components/Widgets';
import { InvoicesHeaderContent } from '../InvoicesHeaderContent';
import { StatisticsFilterContext } from './StatisticsFilterProvider';
import { checkedWidgetsTypes, extraTypes, cardsLayout } from './Widgets/cardsLayout';
import { widgetsMap } from './Widgets';
import { FiltersBar } from './FiltersBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointSmall = 1250;
export const widthBreakpointMedium = 1300;
export const widthBreakpointLarge = 1400;

export const DashboardContent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const { filter } = useContext(StatisticsFilterContext);
  const [ counters, setCounters ] = useState({});
  const [ isFetching, setIsFetching ] = useState(false);
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const {
    openFiltersBar,
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps
  } = useMobileFilterCollapse();
  const ScrollWrapper = !isTablet ? Fragment : Scrollbars;
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%',
    width: '100%',
    style: isTablet && openFiltersBar ? {
      minHeight: isMobile ? 330 : 185
    } : {}
  };
  const cancelFetch = useRef(() => {});

  useMemoEffect(() => {
    cancelFetch.current();
    setIsFetching(false);

    invoicesApi.getInvoicesCounters({
      params: filter,
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      setCounters(data);
      setIsFetching(true);
    });

    return () => {
      cancelFetch.current();
    };
  }, [ filter ]);

  return (
    <CustomScrollbars style={{ flexGrow: 1 }}>
      <div ref={rootRef} className={classes.root}>
        {width > widthBreakpointMedium &&
          <Paper square={false} className={classes.counter}>
            <InvoicesHeaderContent counters={counters} isFetched={isFetching} />
          </Paper>
        }

        {isTablet &&
          <Box
            px={1}
            display="flex"
            justifyContent="flex-end"
            mb={0.5}
            component={Paper}
            square={false}
          >
            {filterOpenButton}
          </Box>
        }

        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <Box
              ml={isTablet ? 0 : 1}
              mr={isTablet ? 0 : 0.15}
              mb={0.5}
            >
              <FiltersBar
                disableBorder
                square={false}
                elevation={1}
                filterKey={filtersKeysMap.billing_dashboard_main}
                className={classes.filterBar}
              />
            </Box>
          </FilterBarWrapper>
        </ScrollWrapper>

        {isTablet ? (
          <MobileWidgets
            page={pages.billing}
            widgetsMap={widgetsMap}
            checkedWidgetsTypes={checkedWidgetsTypes}
          />
        ) : (
          <Widgets
            rowHeight={25}
            page={pages.billing}
            extraTypes={extraTypes}
            checkedWidgetsTypes={checkedWidgetsTypes}
            widgetsMap={widgetsMap}
            cardsLayout={cardsLayout}
          />
        )}
      </div>
    </CustomScrollbars>
  );
};
