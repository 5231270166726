import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { OfficeLink } from '../../../OfficeLink';
import { UserLink } from '../../../UserLink';
import { Label } from '../../../Label';

export const CardContent = ({ params }) => {
  const theme = useTheme();

  return (
    <Grid container spacing={1}>
      {!!params?.providers?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Providers:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.providers?.map((provider) => (
                <Box key={provider.id} display="flex" py={0.25}>
                  <UserLink
                    noWrap
                    size="sm"
                    user={provider}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.offices?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Offices:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.offices?.map((office) => (
                <OfficeLink
                  noWrap
                  key={office.id}
                  tooltipTitle={office?.full_address}
                  isExistTooltip={!!office?.full_address}
                  office={office}
                />
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.appointment_books?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Appt. Books:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.appointment_books?.map((book) => (
                <Typography noWrap key={book.id} title={book.appointment_book_name || null}>
                  {book.appointment_book_name || '-'}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.patients?.length &&
        <>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                Patients:
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.patients?.map((patient) => (
                <Box key={patient.id} display="flex" alignItems="center" py={0.25}>
                  <UserLink
                    noWrap
                    size="sm"
                    user={patient}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.arrival_statuses?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Arrival Statuses:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params.arrival_statuses?.map((status) => (
                <Box key={status.id} py={0.5}>
                  <Label label={status.description} color={theme.palette.primary.main} />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params.appointment_types?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Appointment Types:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params?.appointment_types?.map((type) => (
                <Typography noWrap key={type.code} title={type.description}>
                  {type.description + ' '}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }

      {!!params?.visit_reasons?.length &&
        <>
          <Grid item xs={4}>
            <Typography>
              Visit Reasons:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%">
              {params.visit_reasons?.map((reason) => (
                <Typography
                  noWrap
                  key={reason.id}
                  title={reason.code + (reason.description ? ' - ' + reason.description : '')}
                >
                  {reason.code + (reason.description ? ' - ' + reason.description : '')}
                </Typography>
              ))}
            </Box>
          </Grid>
        </>
      }
    </Grid>
  );
};
