import { useEffect, useRef, useState } from 'react';
import { makeStyles, Box, useTheme, useMediaQuery, Dialog } from '@material-ui/core';
import * as claimsApi from '../../../../../api/claims';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { transformServiceLineForForm } from '../../../../../components/claims/ServiceLineFormModal/ServiceLineForm';
import { transformSituationalNumberForForm } from '../../../../../components/claims/SituationalNumberFormModal';
import { Loader } from '../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalHeader
} from '../../../../../components/Modal';
import { ActionsBar } from '../ClaimPreview/ActionsBar';
import { MainContent } from '../ClaimPreview/MainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ClaimPreviewModal = ({
  payload: {
    id
  },
  DialogProps,
  handleModalReject
}) => {
  const classes = useStyles();
  const [ claim, setClaim ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState(isDesktop);
  const rootRef = useRef(null);

  const changeClaim = (updatedClaim) => {
    setClaim((claim) => ({ ...claim, ...updatedClaim }));
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchClaim = () => {
      claimsApi.fetchClaim(id).then((claim) => {
        setIsLoading(false);

        setClaim({
          ...claim,
          situational_number: transformSituationalNumberForForm(claim.situational_number),
          appointment: claim.appointment && {
            ...claim.appointment,

            procedures: claim.appointment?.procedures?.map((procedure) => ({
              ...procedure,

              service_line: procedure.service_line?.map((serviceLine) => {
                return transformServiceLineForForm({ appointment: claim.appointment, serviceLine });
              })
            }))
          }
        });
      }).catch(handleModalReject);
    };

    fetchClaim();
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject}>
          Claim
        </ModalHeader>

        <ModalBody disablePaddings disableScrollbars>
          <Loader loading={!claim} p={3} render={
            () => (
              <Box ref={rootRef} display="flex" position="relative" overflow="hidden" height="100%">
                <div className={classes.main}>
                  <Loader loading={isLoading} p={3} render={
                    () => (
                      <>
                        <ActionsBar
                          disableSidebar
                          view="modal"
                          claim={claim}
                          sidebarIsOpen={sidebarIsOpen}
                          setSidebarIsOpen={setSidebarIsOpen}
                          onUpdateClaim={setClaim}
                        />

                        <Scrollbars autoHeight autoHeightMax="100%">
                          <MainContent claim={claim} onClaimChange={changeClaim}/>
                        </Scrollbars>
                      </>
                    )}
                  />
                </div>
              </Box>
            )}
          />
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
