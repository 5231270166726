import { useSelector } from 'react-redux';
import {
  makeStyles,
  Button,
  SvgIcon,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import DragSvg from '../../../../../components/icons/draggable-dots.svg';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ReplaceableDataFieldsList = ({ listRef, selectedTemplateType }) => {
  const classes = useStyles();
  const replaceableDataFields = useSelector((store) => store.apiConfig.config.html_documents.fields);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <Typography variant={isMobile ? 'h5' : 'h2'} className={classes.header}>
        Auto fill elements
      </Typography>

      <div className={classes.listWrapper}>
        <div ref={listRef} className={classes.list}>
          {replaceableDataFields[selectedTemplateType] && replaceableDataFields[selectedTemplateType].map((field) => (
            <Button
              key={field.field}
              fullWidth
              draggable
              color="primary"
              variant="contained"
              size="small"
              replaceable-data-type={JSON.stringify(field)}
              endIcon={<SvgIcon fontSize="small"><DragSvg/></SvgIcon>}
              className={classes.listItem}
              classes={{
                label: classes.listItem__label
              }}
            >
              {field.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
