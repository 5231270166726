import { useContext, useEffect, useRef } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { Grid, List } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/MainContent';
import { MedicalFormsContext } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/MedicalFormsProvider';
import { useResizeObserver } from '../../../helpers/hooks/useResizeObserver';

export const MedicalInfo = ({ height = '100%' }) => {
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const { resetForms, ...formContext } = useContext(MedicalFormsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  useEffect(() => {
    resetForms();
  }, []);

  return (
    <Box ref={rootRef} display="flex" flexDirection="column" height={isMobile ? height : '100%'} p={isMobile ? 1 : 0}>
      {isMobile
        ? <Grid formContext={formContext} />
        : <List isTabsPage width={width} formContext={formContext} />
      }
    </Box>
  );
};
