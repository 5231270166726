import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';
import { makeStyles, Button, Checkbox, FormControlLabel, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import * as categoriesApi from '../../../../../api/billing';
import { TextField } from '../../../../../components/FormField';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { ExpensesCategoriesSelect, transformExpensesCategoryToOption } from '../../../../../components/billing';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CreateCategoryModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    initialValues = {}
  }
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [ isChecked, setIsChecked ] = useState(false);
  const [ iconCategory, setIconCategory ] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const parent = initialValues?.categories?.find(({ id }) => id === initialValues.category.parent_id);

  const handleChangeCategory = (option) => {
    setIconCategory(option ? option.label : null);
  };

  const handleChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
  };

  const createCategory = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.icon = iconCategory;
    values.is_active = false;

    return categoriesApi.createExpensesCategory(values).then((data) => {
      enqueueSnackbar('Category successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch((errors) => {
      if (errors) {
        setErrors(errors);
        enqueueSnackbar('Category not created', { variant: 'error' });
      }
    });
  };

  const updateCategory = (value, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    value.icon = iconCategory;
    value.parent_id = value.parent_id ? get(value, 'parent_id.value', value.parent_id) : null;
    value.is_active = true;

    return categoriesApi.updateExpensesCategory(value).then(() => {
      enqueueSnackbar('Category successfuly deleted', { variant: 'success' });
      handleModalResolve(value);
    }).catch((errors) => {
      if (errors) {
        setErrors(errors);
        enqueueSnackbar('Category not updated', { variant: 'error' });
      }
    });
  };

  useEffect(() => {
    if (initialValues.category && initialValues.category.parent_id) {
      setIsChecked(true);
      setIconCategory(initialValues.category ? initialValues.category.icon : null);
    }
  }, []);

  return (
    <Dialog fullScreen={isMobile} {...DialogProps}>
      <ModalContainer>
        <Formik
          initialValues={{
            ...initialValues.category,

            parent_id: transformExpensesCategoryToOption(parent)
          }}
          validationSchema={validationSchema}
          onSubmit={initialValues.category ? updateCategory : createCategory}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
              <ModalHeader onClose={handleModalReject}>
                New Category
              </ModalHeader>

              <ModalBody>
                <TextField
                  name="name"
                  disabled={!!values?.is_system}
                  label="Category Name"
                  placeholder="Select category name..."
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!values.name}
                      value="secondary"
                      color="primary"
                      checked={isChecked}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label="Make as a sub-category?"
                />

                <ExpensesCategoriesSelect
                  isClearable
                  isDisabled={!isChecked}
                  name="parent_id"
                  label="Parent Category"
                  TextFieldProps={{ margin: 'dense' }}
                  onChange={handleChangeCategory}
                />

                <TextField
                  multiline
                  name="description"
                  label="Description"
                  margin="normal"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true
                  }}
                  className={classes.description}
                  rows={5}
                />
              </ModalBody>

              <ModalFooter>
                <Button onClick={handleModalReject}>Cancel</Button>

                <Button
                  autoFocus
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </ModalFooter>
            </ModalContainer>
          )}
        </Formik>
      </ModalContainer>
    </Dialog>
  );
};
