import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths, widthBreakpointXXS } from '../FilteredMainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = ({ width }) => {
  const classes = useStyles();

  return (
    <ListRow header className={classes.root}>
      <ListRowCell grow flexBasis={columnsWidths.client}>
        Case
      </ListRowCell>

      {width > widthBreakpointXXS &&
        <ListRowCell flexBasis={columnsWidths.date}>
          Date
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.hours}>
        Hours
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.parent}>
        Task
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        Total
      </ListRowCell>
    </ListRow>
  );
};

