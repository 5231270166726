import { DiscoveryType } from '../DiscoveryType';
import { Date, Case, UserActivityLink, Bool } from '../../fields';

export const discoveryFieldsMap = {
  to: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="To User" />,
  from: (before, after) => <UserActivityLink before={before} after={after} fieldLabel="From User" />,
  case: (before, after) => <Case before={before} after={after} fieldLabel="Case" />,
  created_at: (before, after) => <Date before={before} after={after} fieldLabel="Created At" />,
  deleted_at: (before, after) => <Date before={before} after={after} fieldLabel="Deleted At" />,
  due_date: (before, after) => <Date before={before} after={after} fieldLabel="Due Date" />,
  send_date: (before, after) => <Date before={before} after={after} fieldLabel="Send Date" />,
  updated_at: (before, after) => <Date before={before} after={after} fieldLabel="Updated At" />,
  received_date: (before, after) => <Date before={before} after={after} fieldLabel="Received Date" />,
  type: (before, after) => <DiscoveryType before={before} after={after} fieldLabel="Type" />,
  user_type: (before, after) => <DiscoveryType isUserType before={before} after={after} fieldLabel="User Type" />,
  extension: (before, after) => <Bool before={before} after={after} fieldLabel="Extension" />
};
