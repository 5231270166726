import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography } from '@material-ui/core';
import { SavedFiltersContext } from '../../../../../../../../components/saved-filters';
import { InfiniteListLoader } from '../../../../../../../../components/InfiniteLoader';
import { Scrollbars } from '../../../../../../../../components/Scrollbars';
import { Loader } from '../../../../../../../../components/Loader';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsNamesMap = {
  code: 'code',
  description: 'description',
  type: 'type',
  year: 'year',
  orderBy: 'orderBy',
  actions: 'actions'
};

export const columnsWidths = {
  [columnsNamesMap.code]: 150,
  [columnsNamesMap.description]: 150,
  [columnsNamesMap.type]: 150,
  [columnsNamesMap.year]: 100,
  [columnsNamesMap.orderBy]: 100,
  [columnsNamesMap.orderBy]: 100,
  [columnsNamesMap.actions]: 52
};

export const SavedFiltersList = ({ onApplySavedFilter, hiddenColumns }) => {
  const {
    savedFilters,
    isFetching,
    isFetched,
    loadNextPage,
    resetSavedFilters,
    filter
  } = useContext(SavedFiltersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetSavedFilters();
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax="100%" viewRef={scrollElementRef}>
      <Loader p={2} loading={!isFetched} render={
        () => !filter.total ? (
          <Typography align="center">No saved filters</Typography>
        ) : (
          <>
            <TableHeader hiddenColumns={hiddenColumns} />

            <MuiList disablePadding>
              <InfiniteListLoader
                items={savedFilters}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ onApplySavedFilter, hiddenColumns }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          </>
        )}
      />
    </Scrollbars>
  );
};
