import { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { NestedList } from '../../../../../../../components/NestedList';
import { ApptList } from '../../ApptList';

export const AppointmetsInfo = ({ isOpenSwitches, form }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Appointment"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Box p={isSmallScreen ? 1 : 3}>
        {!!form?.appointment?.id ?
          <ApptList initialValues={form} />
          :
          <Typography align="center">No appointments found</Typography>
        }
      </Box>
    </NestedList>
  );
};
