import { Grid, Link as MuiLink, Table, TableBody, TableRow, Typography, useTheme } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { AppointmentLink } from '../../../../../components/appointments/AppointmentLink';
import { CaseLink } from '../../../../../components/cases/CaseLink';
import { TableCell } from '../../../../../components/TableCell';
import { hasRole } from '../../../../../utils/hasRole';
import { allClaimsStatuses } from '../../../../../components/claims';
import { UserLink } from '../../../../../components/UserLink';
import { Label } from '../../../../../components/Label';

export const ClaimModel = ({ model }) => {
  const theme = useTheme();
  const params = useParams();

  const goClaimPage = () => {
    if (hasRole('doctor', 'patient', 'client', 'medic')) {
      window.open(`/patients/${params.patientID}/medical-info/claims/${model.id}`);
    } else {
      window.open(`/billing/claims/${model.id}`);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Claim</Typography>
              </TableCell>

              <TableCell disableBorder>
                <MuiLink
                  variant="body1"
                  onClick={goClaimPage}
                >
                  {model?.claim_number}
                </MuiLink>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Claim Status</Typography>
              </TableCell>

              <TableCell disableBorder>
                {!model.status ? (
                  <Typography>-</Typography>
                ) : (
                  <Label
                    label={
                      allClaimsStatuses.find((status) => status.value === model.status)?.label
                    }
                    color={theme.palette.primary.main}
                  />
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Appointment Date</Typography>
              </TableCell>

              <TableCell disableBorder>
                <AppointmentLink appointment={model?.appointment} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Patient</Typography>
              </TableCell>

              <TableCell disableBorder>
                <UserLink
                  size="sm"
                  variant="inherit"
                  user={model?.patient}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Case Number</Typography>
              </TableCell>

              <TableCell disableBorder>
                <Typography>
                  {model?.case?.file_number || '-'}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Case Name</Typography>
              </TableCell>

              <TableCell disableBorder>
                <CaseLink caseItem={model?.case} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Case Status</Typography>
              </TableCell>

              <TableCell disableBorder>
                {!model?.case?.status?.name ? (
                  <Typography>-</Typography>
                ) : (
                  <Label
                    label={model?.case?.status?.name}
                    color={theme.palette.primary.main}
                  />
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell disableBorder padding="none">
                <Typography variant="h5">Heading Attorney</Typography>
              </TableCell>

              <TableCell disableBorder>
                <UserLink
                  size="sm"
                  variant="inherit"
                  user={model?.case?.guardian?.user}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
