export const styles = ({ spacing, breakpoints }) => ({
  root: {
    padding: spacing(3.5, 3, 2),

    [breakpoints.down(breakpoints.values.md)]: {
      padding: spacing()
    }
  },

  title: {
    marginBottom: spacing(1)
  },

  body: {
    marginBottom: 100
  }
});
