import { RRule } from 'rrule';

export const frequencyOptions = [
  {
    label: 'Day',
    value: RRule.DAILY
  },
  {
    label: 'Week',
    value: RRule.WEEKLY
  },
  {
    label: 'Month',
    value: RRule.MONTHLY
  },
  {
    label: 'Year',
    value: RRule.YEARLY
  }
];
