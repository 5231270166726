import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../../../api';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { usePrevious } from '../../../../../helpers/hooks';
import * as companyApi from '../../../../../api/companies';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CompaniesContext = createContext();

export const CompaniesContextProvider = ({ children, initialState: initialStateProp = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const companiesLastGlobalAction = useSelector(({ globalActions }) => globalActions.companiesLastGlobalAction);
  const {
    filter,
    companies,
    pagination,
    isFetching,
    isFetched,
    selectedIDs
  } = state;
  const prevFilter = usePrevious(filter);
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const cancelFetch = useRef(() => {});

  const fetchCompanies = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.COMPANIES_FETCH_REQUEST });

    companyApi.fetchCompanies({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.COMPANIES_FETCH_SUCCESS, payload: data });
    });
  };

  const deleteCompany = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        companyApi.deleteCompany(id).then(() => {
          dispatch({ type: types.DELETE_COMPANY, payload: id });
          enqueueSnackbar('Company successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  const massDeleteItems = (IDs) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        companyApi.massDeleteCompanies(IDs).then(() => {
          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            fetchCompanies({ page: filter.page });
          } else if (companies.length - selectedIDs.length === 0 && filter.page !== 1) {
            fetchCompanies({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.MASS_DELETE, payload: IDs });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Items are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const addCompany = (newFilter = {}) => {
    dispatch({ type: types.ADD_COMPANY, payload: newFilter });
  };

  const updateCompany = (newFilter = {}) => {
    dispatch({ type: types.UPDATE_COMPANY, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchCompanies({ page: filter.page + 1 });
    }
  };

  const resetCompanies = (newFilter = {}) => {
    dispatch({ type: types.RESET_COMPANIES, payload: newFilter });
    fetchCompanies({ page: 1, ...newFilter });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const allItemsIsSelected = () => {
    return companies.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_ITEM_SELECTION, payload: id });
  };

  const toggleAllItemsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_ITEMS_SELECTION });
  };

  useEffect(() => {
    companiesLastGlobalAction && dispatch(companiesLastGlobalAction);
  }, [ companiesLastGlobalAction ]);

  const providerValue = {
    isFetched,
    isFetching,
    companies,
    selectedIDs,
    pagination,
    filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    addCompany,
    updateCompany,
    deleteCompany,
    loadNextPage,
    resetCompanies,
    massDeleteItems,
    allItemsIsSelected,
    toggleAllItemsSelection,
    toggleItemSelection,
    applyFilter
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetCompanies(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CompaniesContext.Provider value={providerValue}>
      {children}
    </CompaniesContext.Provider>
  );
};
