import _ from 'lodash';
import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../../helpers/createReduxReducer';
import * as profileApi from '../../../api/profile';

export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const ADD_EMAIL_ACCOUNT = 'ADD_EMAIL_ACCOUNT';
export const DELETE_EMAIL_ACCOUNT = 'DELETE_EMAIL_ACCOUNT';

export const setProfileData = (payload) => ({ type: SET_PROFILE_DATA, payload });
export const addEmailAccount = (payload) => ({ type: ADD_EMAIL_ACCOUNT, payload });
export const deleteEmailAccount = (payload) => ({ type: DELETE_EMAIL_ACCOUNT, payload });
export const fetchProfile = () => async (dispatch) => dispatch(fetchProfileSuccess(await profileApi.getProfile()));
export const fetchProfileSuccess = (payload) => ({ type: FETCH_PROFILE_SUCCESS, payload });

const initialState = {
  isFetched: false,
  user: {}
};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [SET_PROFILE_DATA]: (state, payload) => {
    return {
      ...state,

      user: _.merge({}, state.user, payload),
      token: null
    };
  },

  [FETCH_PROFILE_SUCCESS]: (state, payload) => {
    return {
      ...state,

      isFetched: true,
      user: payload,
      token: null
    };
  },

  [ADD_EMAIL_ACCOUNT]: (state, account) => {
    return {
      ...state,

      user: {
        ...state.user,

        email_accounts: (state.email_accounts || []).concat(account)
      }
    };
  },

  [DELETE_EMAIL_ACCOUNT]: (state, accountID) => {
    return {
      ...state,

      user: {
        ...state.user,

        email_accounts: state.user.email_accounts.filter(({ id }) => id !== accountID)
      }
    };
  }
});
