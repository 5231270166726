import { Typography } from '@material-ui/core';
import { CardHeader, CardItem } from '../../../../../../../components/Cards';
import { TaskMenu } from '../../../../TaskMenu';
import { Body } from './Body';
import { getStyle } from './styles';

export const Card = ({ isDragging, item: task = {}, provided, style }) => {
  return (
    <CardItem
      py={0.5}
      pr={1}
      pl={1.5}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        isDragging
      })}
      bgcolor="common.white"
      borderRadius="borderRadius"
    >
      <CardHeader
        disableCheckBox
        menuComponent={TaskMenu}
        menuComponentProps={{ task, disablePreview: true }}
      >
        <Typography noWrap title={task.name} variant="h5">
          {task.name}
        </Typography>
      </CardHeader>

      <Body task={task} />
    </CardItem>
  );
};
