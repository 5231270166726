import { useContext, useState } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { rolesMap } from '../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../utils/hasRole';
import {
  billingParticipantsTypesMap,
  billingParticipantsTypesOptions,
  CreateCopayModal
} from '../../../../../../../components/billing';
import { claimPayersMap } from '../../../../../../../components/claims';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { Fab } from '../../../../../../../components/Fab';
import { IconButton } from '../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { InvoicesContext } from '../../../../Invoices/InvoicesContext';
import { List as InvoicesList } from '../../../../Invoices/InvoicesPage/InvoicesContent/List';
import { Grid as InvoicesGrid } from '../../../../Invoices/InvoicesPage/InvoicesContent/Grid';
import { columnsMap as invoicesColumnsMap } from '../../../../Invoices/InvoicesPage/InvoicesContent/List/listConfig';
import { ReceivedPayments } from './ReceivedPayments';
import { OverdraftPayments } from './OverdraftPayments';
import { TabsContent } from './TabsContent';

const invoicesHiddenColumns = [ invoicesColumnsMap.checkbox, invoicesColumnsMap.billFor ];

export const tabsMap = {
  invoices: 'invoices',
  receivedPayments: 'receivedPayments',
  overdraftPayments: 'overdraftPayments'
};

export const BillingSectionContent = ({ claim }) => {
  const { openModal } = useModal();
  const { fetchInvoices } = useContext(InvoicesContext);
  const [ isCollapsed, setIsCollapsed ] = useState(false);
  const [ activeTab, setActiveTab ] = useState(tabsMap.invoices);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleTabIndexChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleCollapsed = () => {
    setIsCollapsed((state) => !state);
  };

  const createPaymentInfo = () => {
    openModal(CreateCopayModal, {
      payload: {
        model_type: 'claim',
        model_id: claim.id,
        patient: claim.patient,
        bill_from: claim.appointment?.appointment_book?.office?.company?.id ? {
          type: billingParticipantsTypesOptions.find(({ value }) => {
            return value === billingParticipantsTypesMap.company;
          }),
          id: claim.appointment?.appointment_book?.office?.company
        } : null,
        bill_to: {
          type: billingParticipantsTypesOptions.find(({ value }) => value === (
            claim?.payer_type === claimPayersMap.patient
              ? billingParticipantsTypesMap.user
              : billingParticipantsTypesMap.company
          )),
          id: claim?.payer_type === claimPayersMap.patient
            ? claim.patient
            : claim.insurance?.insurance_company
        }
      },
      onModalResolved: () => {
        fetchInvoices();
      }
    });
  };

  return (
    <ContentCard
      disableScrollbars
      isCollapsed={isCollapsed}
      variant="outlined"
      color="info"
      leftActions={[
        <TabsContent activeTab={activeTab} onChange={handleTabIndexChange} />
      ]}
      rightActions={!claim?.id ? [] : [
        !hasRole(rolesMap.patient) && (
          <Box mr={1}>
            <Fab
              variant="extended"
              color="primary"
              size={isMobile ? 'small' : 'medium'}
              onClick={createPaymentInfo}
            >
              {isMobile ? 'Add' : 'Add payment'}
            </Fab>
          </Box>
        ),

        <IconButton edge="end" color="info" onClick={toggleCollapsed}>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      ]}
    >
      {activeTab === tabsMap.invoices && (
        isMobile
          ? <InvoicesGrid hiddenColumns={invoicesHiddenColumns} />
          : <InvoicesList hiddenColumns={invoicesHiddenColumns} />
      )}

      {activeTab === tabsMap.receivedPayments && (
        <ReceivedPayments />
      )}

      {activeTab === tabsMap.overdraftPayments && (
        <OverdraftPayments />
      )}
    </ContentCard>
  );
};
