import { Box, Grid, Typography } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';

export const FamilyHistory = ({ history }) => {
  const form = history?.family_history || {};

  return (
    <>
      {!!form?.please_check_family_history_conditions && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" color="secondary">
              FAMILY HISTORY
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
              {!!form?.please_check_family_history_conditions?.blood_clots && (
                <>
                  <ReadonlyCheckbox checked title="Blood Clots" />&nbsp;&nbsp;
                </>
              )}

              {!!form?.please_check_family_history_conditions?.diabetes && (
                <>
                  <ReadonlyCheckbox checked title="Diabetes" />&nbsp;&nbsp;
                </>
              )}

              {!!form?.please_check_family_history_conditions?.hypertension && (
                <>
                  <ReadonlyCheckbox checked title="Hypertension" />&nbsp;&nbsp;
                </>
              )}

              {!!form?.please_check_family_history_conditions?.rheumatoid_arthritis && (
                <>
                  <ReadonlyCheckbox checked title="Rheumatoid Arthritis" />&nbsp;&nbsp;
                </>
              )}

              {!!form?.please_check_family_history_conditions?.anesthetic_problems && (
                <>
                  <ReadonlyCheckbox checked title="Anesthetic Problems" />&nbsp;&nbsp;
                </>
              )}

              {!!form?.please_check_family_history_conditions?.cancer && (
                <>
                  <ReadonlyCheckbox checked title="Cancer" />&nbsp;&nbsp;
                </>
              )}

              {!!form?.please_check_family_history_conditions?.heart_disease && (
                <>
                  <ReadonlyCheckbox checked title="Heart Disease" />&nbsp;&nbsp;
                </>
              )}

              {!!form?.please_check_family_history_conditions?.osteoporosis && (
                <>
                  <ReadonlyCheckbox checked title="Osteoporosis" />&nbsp;&nbsp;
                </>
              )}

              {!!form?.please_check_family_history_conditions?.stroke && (
                <>
                  <ReadonlyCheckbox checked title="Stroke" />&nbsp;&nbsp;
                </>
              )}

              {!!form?.please_check_family_history_conditions?.seizures && (
                <>
                  <ReadonlyCheckbox checked title="Seizures" />&nbsp;&nbsp;
                </>
              )}
            </Box>
          </Grid>
        </>
      )}

      {form?.please_describe_any_immediate_family_history_medical_problems && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">Please describe any immediate family history medical problems:</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>{form?.please_describe_any_immediate_family_history_medical_problems}</Typography>
          </Grid>
        </>
      )}
    </>
  );
};
