import { useContext, useState } from 'react';
import _ from 'lodash';
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import {
  ChatThreadContext,
  MessageInput,
  MessengerContext,
  ThreadMessagesFilterContextProvider,
  TypingIndicator
} from '../../../../components/chat';
import { Loader } from '../../../../components/Loader';
import { MessageList, ThreadMessagesContextProvider } from './MessageList';
import { ThreadHeader } from './ThreadHeader';
import { ChatAbout } from './ChatAbout';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ChatThread = ({ isModal = false, onShowSidebar }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [ aboutTab, setAboutTab ] = useState(null);
  const { currentThreadId } = useContext(MessengerContext);
  const { isFetched, currentThread } = useContext(ChatThreadContext);
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const handleCloseSidebar = () => {
    setAboutTab(null);
  };

  const handleSetAboutTab = (tab) => {
    setAboutTab(tab);
  };

  return !currentThread ? <Loader p={2} /> : (
    <div className={classes.root}>
      <div className={classes.chatWrapper}>
        <ThreadMessagesFilterContextProvider>
          {!isModal &&
            <ThreadHeader
              aboutTab={aboutTab}
              handleSetAboutTab={handleSetAboutTab}
              handleShowSidebar={onShowSidebar}
            />
          }

          <div className={classes.chatThread}>
            <ThreadMessagesContextProvider threadId={currentThreadId}>
              {_.isEmpty(currentThread) ? (
                <Box py={3}>
                  <Loader loading={!isFetched} />
                </Box>
              ) : (
                <MessageList threadId={currentThreadId} />
              )}

              {!_.isEmpty(currentThread) &&
                <Box mb={isModal ? 2 : 0}>
                  <TypingIndicator />

                  <MessageInput threadId={currentThread.id} />
                </Box>
              }
            </ThreadMessagesContextProvider>
          </div>
        </ThreadMessagesFilterContextProvider>
      </div>

      {!_.isEmpty(currentThread) && !!aboutTab &&
        <>
          <ChatAbout thread={currentThread} onAboutTabChange={setAboutTab} aboutTab={aboutTab} />

          {!!aboutTab && isMobile && <div className={classes.chatOverlay} onClick={handleCloseSidebar} />}
        </>
      }
    </div>
  );
};
