import * as codesApi from '../../../../api/codes/procedures';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';

export const transformCodeModifierToOption = (item) => item && ({
  value: item.id,
  label: `${item.code}-${item.description}`
});

export const fetchCodesModifier = fetchDataForAsyncSelect((search, { page }) => {
  return codesApi.fetchCodesModifier({ params: { search, page, is_active: 1 } });
}, {
  transformDataToOption: transformCodeModifierToOption
});

export const CodesModifierSelect = (props) => {
  return (
    <Select
      isAsync
      isClearable
      formattedValue
      label="Modifier"
      promiseWithOptions={fetchCodesModifier}

      {...props}
    />
  );
};
