export const initialValues = {
  tos_id: null,
  office_id: null,
  provider_id: null,
  base_unit: null,
  procedure_code: null,
  region_code_id: null,
  description: null,
  display_in_box_19: null,
  enable_crosswalk: false,
  effective_date: null,
  expiration_date: null,
  add_on: false,
  hcpcs_exceptions: false,
  enable_cross_codes: false,
  do_not_bill_claim: false,
  is_all_offices: false,
  is_all_providers: false
};
