import { createHookReducer } from '../../../../../helpers/createHookReducer';
import { moveArrayItem } from '../../../../../helpers/moveArrayItem';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_RULES_REQUEST]: (state) => {
    return {
      ...state,

      isRulesFetching: true
    };
  },

  [types.FETCH_RULES_SUCCESS]: (
    { filter, rules, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isRulesFetching: false,
      isRulesFetched: true,
      filter: { ...filter, page, per_page },
      pagination,
      rules: page > 1
        ? rules.filter(({ id }) => !data.find((rule) => id === rule.id)).concat(data)
        : data
    };
  },

  [types.RESET_RULES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isRulesFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_RULES_POSTIONS]: ({ rules, ...state }, positions) => {
    const { oldIndex, newIndex } = positions;

    return {
      ...state,

      rules: moveArrayItem(rules, oldIndex, newIndex)
    };
  },

  [types.SET_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.TOGGLE_ACTIVE]: (state, rule) => {
    return {
      ...state,

      rules: state.rules.map((ruleItem) => rule.id === ruleItem.id ? ({
        ...ruleItem,
        is_active: !rule.is_active
      }) : ruleItem)
    };
  },

  [types.EDIT_RULE]: (state, updatedRule) => {
    return {
      ...state,

      rules: state.rules.map((rule) => updatedRule.id === rule.id ? updatedRule : rule)
    };
  },

  [types.ADD_RULES]: ({ pagination, filter, rules, ...otherState }, payload) => {
    const total = pagination.total + 1;
    const page = Math.ceil((rules.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page: page === 0 ? 1 : page },
      rules: [ ...rules, payload ]
    };
  },

  [types.DELETE_RULE]: ({ rules, pagination, filter, ...state }, ruleID) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, total },
      rules: rules.filter(({ id }) => id !== ruleID)
    };
  }
});
