import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Box, makeStyles, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { toggleSidebar } from '../../../../../store/dashboard/layout';
import { IconButton } from '../../../../../components/IconButton';
import FullLogoSVG from './logo-full.svg';
import LogoSVG from './logo.svg';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const LogoLink = ({ isClosableByButton = false, sidebarIsOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const LogoIcon = sidebarIsOpen ? FullLogoSVG : LogoSVG;

  const handleDrawerClose = () => {
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    if (isMobile && sidebarIsOpen) {
      dispatch(toggleSidebar());
    }
  }, [ isMobile ]);

  return (
    <>
      <NavLink to="/">
        <Toolbar variant="regular" className={cn(classes.logo, sidebarIsOpen && classes.logo_full)}>
          <LogoIcon className={classes.logo__icon} />
        </Toolbar>
      </NavLink>

      {isMobile && isClosableByButton && (
        <Box position="absolute" right={10} top={10}>
          <IconButton color="primary" onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Box>
      )}
    </>
  );
};
