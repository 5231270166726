import { pxToRem } from '../../../../../themes/typography';

const rightSidebarWidth = 300;

export const styles = ({ spacing, palette, breakpoints, transitions, zIndex }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    [breakpoints.down('md')]: {
      position: 'relative',
      overflow: 'hidden'
    },

    [breakpoints.down('xs')]: {
      width: '100vw'
    }
  },

  mainContent: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexGrow: 1,
    overflow: 'auto',

    [breakpoints.down('md')]: {
      padding: spacing(1)
    }
  },

  leftSideBar: {
    height: '100%',
    flexGrow: 1,
    overflow: 'auto',
    paddingLeft: spacing(2),
    paddingRight: spacing(6),

    [breakpoints.down('md')]: {
      padding: spacing(0, 1)
    }
  },

  rightSideBar: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    minWidth: rightSidebarWidth,
    backgroundColor: palette.grey[50],

    [breakpoints.down('md')]: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: zIndex.appBar + 1,
      transition: transitions.create([ 'right' ], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen
      })
    }
  },

  rightSideBarMobileClosed: {
    right: pxToRem(-rightSidebarWidth - 10)
  },

  mobileOverlay: {
    [breakpoints.down('md')]: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.3)',
      zIndex: zIndex.appBar,
      cursor: 'pointer'
    }
  }
});
