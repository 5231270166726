import { createContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../api';
import * as userApi from '../../../../../api/users';
import { updateUserStorage } from '../../../../../store/globalUser/operations';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const UserContext = createContext();

export const UserProvider = ({ userID, children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { isFetched, user } = state;
  const history = useHistory();
  const reduxDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const storageInfo = useSelector(({ globalUser }) => globalUser?.data);
  const cancelFetch = useRef(() => {});
  const usersLastGlobalAction = useSelector(({ globalActions }) => globalActions.usersLastGlobalAction);
  const medicalInfoPathname = `/members/users/${userID}/medical-info`;

  const fetchUser = () => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_PROFILE_REQUEST });

    userApi.fetchUser(userID, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_PROFILE_SUCCESS, payload: data });
    }).catch(({ status }) => {
      if (status === 404) {
        if (userID) {
          enqueueSnackbar('User not found', { variant: 'error' });

          reduxDispatch(updateUserStorage({
            users: storageInfo.users?.filter(({ id }) => id !== +userID)
          }));
        }

        history.push('/patients');
      }
    });
  };

  const updateUser = (user) => {
    dispatch({ type: types.SET_PROFILE_DATA, payload: user });
  };

  useEffect(() => {
    if (usersLastGlobalAction) {
      dispatch(usersLastGlobalAction);

      if (usersLastGlobalAction?.type === types.SET_PROFILE_DATA) {
        fetchUser();
      }
    }
  }, [ usersLastGlobalAction ]);

  const providerValue = {
    isFetched,
    user,
    medicalInfoPathname,

    // functions
    updateUser,
    fetchUser
  };

  useEffect(() => {
    fetchUser();
  }, [ userID ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};
