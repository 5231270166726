import { MenuItem, ListItemText } from '@material-ui/core';
import { Label } from '../../../../app/Dashboard/TasksPage/MainWindow/Label';

export const Option = ({ children, ...props }) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"

      {...props.innerProps}
    >
      <ListItemText
        primary={<Label name={children} color={children.toLowerCase()}/>}
        primaryTypographyProps={{ noWrap: true, component: 'div' }}
      />
    </MenuItem>
  );
};
