import { Box, Typography } from '@material-ui/core';
import { depositionFieldsMap } from './fieldsMaps/depositionFieldsMap';
import { WidgetActivity } from './WidgetActivity';

export const Deposition = ({
  isDeleted = false,
  isNew = false,
  fieldLabel,
  activityLabel,
  payload
}) => {
  if (isDeleted) {
    return (
      <Box component="span" display="flex" alignItems="center">
        Removed Deposition
        &nbsp;
        <Typography variant="subtitle1">
          {payload?.case_deposition?.name || '-'}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {isNew && (
        <Box component="span" display="flex" alignItems="center">
          Added new deposition
          &nbsp;
          <Typography variant="subtitle1">
            {payload?.case_deposition?.name || '-'}
          </Typography>
        </Box>
      )}

      <WidgetActivity
        {...payload?.properties}
        showInfo
        fieldLabel={fieldLabel}
        activityLabel={activityLabel}
        fieldsMap={depositionFieldsMap}
      />
    </>
  );
};
