import { Fragment } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Date } from '../../../../../components/Date';
import { Divider } from '../../../../../components/Divider';

export const InsuranceClaimRowInfo = ({ claim }) => {
  return (
    <Grid container spacing={1} component={Box} py={2}>
      <Grid item xs={1} />

      {claim?.adj_numbers?.map((item, i) => (
        <>
          <Grid item xs={11}>
            <Box color="info.main">
              <Typography variant="h5" color="inherit">
                ADJ number
              </Typography>
            </Box>
          </Grid>

          <Fragment key={i}>
            <Grid item xs={1} />

            <Grid item xs={2}>
              <Typography noWrap variant="body2" color="textSecondary">Date</Typography>
            </Grid>

            <Grid item xs={3}>
              <Date noWrap date={item.date} variant="subtitle2" />
            </Grid>

            <Grid item xs={3}>
              <Typography noWrap variant="body2" color="textSecondary">EAMS #</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography noWrap variant="subtitle2">
                ADJ {item?.number || '-'}
              </Typography>
            </Grid>

            <Grid item xs={1} />
          </Fragment>
        </>
      ))}

      <Grid item xs={12} />
      <Grid item xs={1} />

      <Grid item xs={6}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">DOI</Typography>
        </Box>
      </Grid>

      <Grid item xs={5}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">Statute of Limitations</Typography>
        </Box>
      </Grid>

      {!claim?.doi?.length ? (
        <Grid item xs={12}>
          <Typography align="center" color="textSecondary">No DOI found</Typography>
        </Grid>
      ) : (claim?.doi?.map((doi, i) => (
        <Fragment key={i}>
          <Grid item xs={1} />

          <Grid item xs={1}>
            <Typography noWrap variant="body2" color="textSecondary">
              {!!doi.from ? 'Dates' : 'Date'}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              {!!doi.from ? (
                <>
                  <Date noWrap date={doi.from} variant="subtitle2" />
                  &nbsp; - &nbsp;
                  <Date noWrap date={doi.to} variant="subtitle2" />
                </>
              ) : (
                <>
                  <Date noWrap date={doi.date} variant="subtitle2" />
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={3}>
            <Box display="flex" alignItems="center">
              <Date noWrap date={doi.start_of_limitation} variant="subtitle2" />
            </Box>
          </Grid>

          <Grid item xs={2} />
          <Grid item xs={2} />

          <Grid item xs={9}>
            <Box display="flex" alignItems="center" fontWeight={500} mb={0.5}>
              <NotesIcon fontSize="small"/>
              <Typography variant="subtitle2">Notes</Typography>
            </Box>

            <Typography variant="body2" color="textSecondary">{doi.note || 'No info'}</Typography>
          </Grid>

          {i !== (claim?.doi?.length - 1) &&
            <>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Divider type="dashed" gutter={1} />
              </Grid>
            </>
          }
        </Fragment>
      )))}

      <Grid item xs={12} />
      <Grid item xs={1} />

      <Grid item xs={11}>
        <Box color="info.main">
          <Typography variant="h5" color="inherit">Injured body parts</Typography>
        </Box>
      </Grid>

      {!claim?.body_parts?.length ? (
        <Grid item xs={12}>
          <Typography align="center" color="textSecondary">No injury body parts found</Typography>
        </Grid>
      ) : (claim?.body_parts?.map((item, i) => (
        <Fragment key={i}>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <Typography variant="subtitle2">{i + 1}.</Typography>
          </Grid>

          <Grid item xs={9}>
            <Typography variant="subtitle2">
              {item.code + ' ' + item.description}
            </Typography>
          </Grid>

          <Grid item xs={3} />

          <Grid item xs={9}>
            <Box display="flex" alignItems="center" fontWeight={500} mb={0.5}>
              <NotesIcon fontSize="small"/>
              <Typography variant="subtitle2">Notes</Typography>
            </Box>

            <Typography variant="body2" color="textSecondary">{item.note || 'No info'}</Typography>
          </Grid>
        </Fragment>
      )))}
    </Grid>
  );
};
