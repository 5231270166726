import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { saveFilter } from '../../../../../store/lastFilters';
import { hasRole } from '../../../../../utils/hasRole';
import { IconComponent } from '../../../../../components/saved-filters';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import {
  KeyboardDatePicker,
  TextField,
  OfficesLocationSelect
} from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';
import { PrescriptionsContext } from '../PrescriptionsProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { initialValues } from './initialValues';
import { CardContent } from './CardContent';
import { List } from './List';

export const FiltersBar = ({ filterKey, hiddenFilterFields = [] }) => {
  const formikRef = useRef();
  const { filter, applyFilter } = useContext(PrescriptionsContext);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});
  const isDoctor = pathname === '/prescriptions' && hasRole(rolesMap.doctor);
  const currentUser = useSelector(({ profile }) => profile.user);
  const MODAL_WIDTH = !hasRole(rolesMap.client, rolesMap.patient) ? 960 : 780;

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleOfficeChange = (office) => {
    applyFilter({ office_id: office?.id });
    setRelationsForFilter((state) => ({ ...state, office_id: office }));
  };

  const handleUserSelect = (name) => (option) => {
    applyFilter({ [name]: option?.id });
    setRelationsForFilter((state) => ({ ...state, [name]: option }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);

    applyFilter({
      ...filter,
      patient_id: filter?.patient_id?.id,
      provider_id: filter?.provider_id?.id,
      office_id: filter?.office_id?.id
    });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter({
        provider_id: isDoctor ? currentUser : null,
        ...relationsForFilter,
        ...lastFilters
      });
    } else {
      isDoctor && applySavedFilter({ provider_id: currentUser });
    }
  }, []);

  return (
    <FiltersBarComponent
      disableTopPaddings
      isResetForm
      border={0}
      enableSettings
      formikRef={formikRef}
      initialValues={initialValues}
      hiddenFields={hiddenFilterFields}
      filterKey={filterKey}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      onReset={() => applySavedFilter(initialValues)}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.patient,
          label: filterFieldsLabels[filterFieldsMap.patient],
          field: (
            <UsersSelect
              name="patient_id"
              label="Patients"
              params={{ is_patient: 1 }}
              onChange={handleUserSelect('patient_id')}
              margin="dense"
              minWidth={210}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.name,
          label: filterFieldsLabels[filterFieldsMap.name],
          field: (
            <TextField
              name="name"
              label="Name"
              onChange={handleFieldChange('name')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.dateFrom,
          label: filterFieldsLabels[filterFieldsMap.dateFrom],
          field: (
            <KeyboardDatePicker
              zeroMinWidth
              clearable
              outputFormat="YYYY-MM-DD"
              name="date_from"
              label="From"
              minWidth={140}
              onChange={handleDatePickerChange('date_from')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.dateTo,
          label: filterFieldsLabels[filterFieldsMap.dateTo],
          field: (
            <KeyboardDatePicker
              zeroMinWidth
              clearable
              outputFormat="YYYY-MM-DD"
              name="date_to"
              label="To"
              minWidth={140}
              onChange={handleDatePickerChange('date_to')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.provider,
          label: filterFieldsLabels[filterFieldsMap.provider],
          field: (
            <UsersSelect
              name="provider_id"
              label="Physician"
              params={{ role: 'doctor' }}
              onChange={handleUserSelect('provider_id')}
              margin="dense"
              minWidth={210}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.office,
          label: filterFieldsLabels[filterFieldsMap.office],
          field: (
            <OfficesLocationSelect
              name="office_id"
              params={{ users: filter.provider_id ? [ filter.provider_id ] : null }}
              onChange={handleOfficeChange}
            />
          )
        }
      ]}
    />
  );
};
