import { Grid, useMediaQuery, useTheme, makeStyles } from '@material-ui/core';
import { NDCCodes } from './NDCCodes';
import { Schedule } from './Schedule';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Footer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      spacing={isMobile ? 1 : 3}
      align="flex-start"
      className={classes.root}
    >
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Schedule { ...props} />
      </Grid>

      <Grid item lg={6} md={12} sm={12} xs={12}>
        <NDCCodes {...props} />
      </Grid>
    </Grid>
  );
};
