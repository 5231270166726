export const reportTypesMap = {
  new: 'Initial Report New Patient',
  exist: 'Initial Report Follow Up'
};

export const reportTypesOptions = [
  {
    value: reportTypesMap.new,
    label: 'Initial Report New Patient'
  },
  {
    value: reportTypesMap.exist,
    label: 'Initial Report Follow Up/ History and Physical - Follow Up Consultation'
  }
];

export const previewTitleMap = {
  [reportTypesMap.new]: 'Initial Report',
  [reportTypesMap.exist]: 'History and Physical/Follow Up Consultation'
};
