import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_ACCOUNTS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_ACCOUNTS_SUCCESS]: (state, payload) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      accounts: payload
    };
  },

  [types.RESET_ACCOUNTS]: ({ filter, ...state }) => {
    return { ...state, isFetched: false };
  },

  [types.SET_ACTIVE_ACCOUNT_ID]: (state, accountId) => {
    return {
      ...state,
      activeAccountId: accountId
    };
  },

  [types.SET_COUNTERS]: (state, [ id, data ]) => {
    return {
      ...state,
      counters: {
        ...state.counters,
        [id]: data
      }
    };
  },

  [types.SET_COUNTERS_ERROR]: (state, data) => {
    return {
      ...state,
      error: data
    };
  },

  [types.UPDATE_ACCOUNT]: ({ accounts, ...state }, payload) => {
    return {
      ...state,

      accounts: accounts.map((item) => item.id === payload.id ? payload : item)
    };
  },

  [types.DELETE_ACCOUNT]: ({ accounts, ...state }, accountId) => {
    return {
      ...state,

      accounts: accounts.filter(({ id }) => id !== accountId)
    };
  }
});
