import { createContext, useRef, useEffect, useReducer, useCallback } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import { api } from '../../../../../api';
import * as templatesApi from '../../../../../api/cases/task-template-groups';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const TaskTemplatesGroupsContext = createContext();

export const TaskTemplatesGroupsProvider = ({ filter: filterProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    groups
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchGroups = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_REQUEST });

    templatesApi.fetchTaskTemplateGroups({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchGroups({ page: filter.page + 1 });
    }
  };

  const resetGroups = (newFilter) => {
    dispatch({ type: types.RESET, payload: newFilter });

    fetchGroups({ page: 1, ...newFilter });
  };

  const addGroup = useCallback((group) => {
    dispatch({ type: types.ADD, payload: group });
  });

  const updateGroup = useCallback((group) => {
    dispatch({ type: types.UPDATE, payload: group });
  });

  const deleteGroup = (group) => {
    dispatch({ type: types.DELETE, payload: group.id });
  };

  const providerValue = {
    isFetched,
    isFetching,
    groups,
    filter: filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    addGroup,
    resetGroups,
    fetchGroups,
    updateGroup,
    loadNextPage,
    applyFilter,
    deleteGroup
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      resetGroups(newFilter);
    }
  }, [ filter, filterProp ]);


  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ] );

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetGroups(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    resetGroups();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <TaskTemplatesGroupsContext.Provider value={providerValue}>
      {children}
    </TaskTemplatesGroupsContext.Provider>
  );
};
