import { painLevelColors } from '../painLevelColors';

export const styles = ({ spacing, palette, breakpoints }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',

    [breakpoints.down('md')]: {
      justifyContent: 'space-around',
      paddingBottom: spacing(3)
    }
  },

  control: {
    height: '98%',
    padding: spacing(2, 2, 0),

    '&:first-child': {
      paddingBottom: spacing(4)
    }
  },

  painDirections: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '97%',
    paddingLeft: spacing(),

    '& > *': {
      marginBottom: spacing(2)
    },

    [breakpoints.down('sm')]: {
      height: 'auto'
    }
  },

  sliderRoot: {
    width: '4px !important',
    marginTop: spacing()
  },

  track: {
    width: '4px !important',
    color: 'transparent',
    backgroundImage: ({ level }) => `linear-gradient(to top, ${painLevelColors.slice(0, level)})`
  },

  rail: {
    width: '4px !important',
    opacity: 1,
    color: palette.grey[200]
  },

  mark: {
    width: '4px !important',
    backgroundColor: palette.grey[600]
  },

  thumb: {
    width: 18,
    height: 18,
    transform: 'translate(-15%, 10%)'
  },

  title: {
    marginRight: spacing(0.5)
  }
});
