import { makeStyles, Box, Paper, useTheme, useMediaQuery } from '@material-ui/core';
import { AppointmentsFilterProvider } from '../../../../components/appointments';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';
import { PatientsProvider } from '../PatientsProvider';
import { PatientsContent } from './PatientsContent';
import { Appointments } from './Appointments';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = ({ filterKey }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PatientsProvider>
      <Box height="100%" p={isMobile ? 0 : 2}>
        {isTablet ? (
          <Paper elevation={2} className={classes.rootSm}>
            <AppointmentsFilterProvider>
              <ErrorBoundary>
                <PatientsContent filterKey={filterKey} />
              </ErrorBoundary>
            </AppointmentsFilterProvider>
          </Paper>
        ) : (
          <Paper elevation={2} className={classes.root}>
            <AppointmentsFilterProvider>
              <Box display="flex" flexGrow={1}>
                <ErrorBoundary>
                  <PatientsContent filterKey={filterKey} />
                </ErrorBoundary>
              </Box>

              <Box px={2}>
                <ErrorBoundary>
                  <Appointments />
                </ErrorBoundary>
              </Box>
            </AppointmentsFilterProvider>
          </Paper>
        )}
      </Box>
    </PatientsProvider>
  );
};
