import { useContext, useRef } from 'react';
import { Box, List, Typography } from '@material-ui/core';
import { ScheduleContext } from '../../schedule-events';
import { Scrollbars } from '../../Scrollbars';
import { Row } from '../ScheduleEventsWidget/Row';

export const minRowHeight = 64;
export const columnsWidths = {
  content: 200,
  date: 90,
  icon: 36
};

export const MainContentCalendar = ({ width }) => {
  const { events } = useContext(ScheduleContext);
  const scrollElementRef = useRef();

  return (
    <Box display="flex" flexDirection="column" height="100%" p={1}>
      {!events?.length ? (
        <Box p={2}>
          <Typography align="center">No events found</Typography>
        </Box>
      ) : (
        <Scrollbars viewRef={scrollElementRef}>
          <List disablePadding>
            {events.map((item) => (
              <Row key={item.id} event={item} width={width} />
            ))}
          </List>
        </Scrollbars>
      )}
    </Box>
  );
};
