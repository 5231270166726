import { api } from '../../api';

export const fetchTests = (config) => {
  return api.get('/procedure-codes/lab-tests/', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchTest = (id) => {
  return api.get(`/procedure-codes/lab-tests/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createTest = (data) => {
  return api.post('/procedure-codes/lab-tests', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateTest = ({ id, ...data }) => {
  return api.put(`/procedure-codes/lab-tests/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteTest = (id) => {
  return api.delete(`/procedure-codes/lab-tests/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
