import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { MedicalProfileContext } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider';
import { reportTypesMap } from '../../medical/reports/diagnosis/SelectReportTypeModal';
import { NestedList } from '../../NestedList';
import { UserLink } from '../../UserLink';
import { Fab } from '../../Fab';

export const Diagnosis = ({ isOpenSwitches = false, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const history = useHistory();
  const { medicalInfoPathname } = useContext(MedicalProfileContext);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  const handleUpdateReport = () => {
    if (report?.diagnosis_report?.type === reportTypesMap.new) {
      history.push(medicalInfoPathname + `/medical-report/diagnosis/${report?.diagnosis_report?.id}/third-step`);
    } else {
      history.push(medicalInfoPathname + `/medical-report/diagnosis/${report?.diagnosis_report?.id}/edit`);
    }
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="New Diagnosis"
      actionIcon={!!report &&
        <Fab
          size="small"
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={handleUpdateReport}
        >
          Add
        </Fab>
      }
      onOpen={handleOpenListItem}
    >
      <Box p={3}>
        {!report ?
          <Typography align="center" color="textSecondary">No info found</Typography>
          :
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Typography color="textSecondary">Diagnosis report type</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="h5">
                {report?.diagnosis_report?.type || '-'}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography color="textSecondary">Provider</Typography>
            </Grid>

            <Grid item xs={7}>
              <UserLink
                variant="h5"
                user={report?.diagnosis_report?.provider}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom color="textSecondary">Diagnosis:</Typography>

              {!report?.diagnosis?.length ?
                <Typography color="textSecondary">No info.</Typography>
                : (
                  report?.diagnosis?.map((diagnosisItem, index) => (
                    <Box key={index} display="flex" alignItems="flex-start">
                      <Typography>{index + 1}.</Typography>

                      <Box color="info.main" mx={1}>
                        <Typography>{diagnosisItem.code}</Typography>
                      </Box>

                      <Typography>- {diagnosisItem.description}</Typography>
                    </Box>
                  )
                  ))}
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom color="textSecondary">Assessment:</Typography>
              <Typography>{report?.assessment || 'No info'}</Typography>
            </Grid>
          </Grid>
        }
      </Box>
    </NestedList>
  );
};
