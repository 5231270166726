import { Grid, Typography } from '@material-ui/core';
import { dataURItoBlob } from '../../../../../../../helpers/files';
import { SimpleDrawing } from '../../../../../../../components/SimpleDrawing';

export const Draw = ({ onChange }) => {
  const handleInit = (key) => () => {
    onChange({ [key]: null });
  };

  const handleCanvasChange = (key) => (dataURI) => {
    onChange({ [key]: dataURI && dataURItoBlob(dataURI) });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7}>
        <Typography>Full Name</Typography>

        <SimpleDrawing
          height={260}
          onInit={handleInit('electronic_signature_file')}
          onChange={handleCanvasChange('electronic_signature_file')}
        />
      </Grid>

      <Grid item xs={12} md={5}>
        <Typography>Initials</Typography>

        <SimpleDrawing
          height={260}
          onInit={handleInit('fax_signature_file')}
          onChange={handleCanvasChange('fax_signature_file')}
        />
      </Grid>
    </Grid>
  );
};
