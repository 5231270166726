import cn from 'classnames';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { Tooltip, TooltipTitle } from '../../../../../../../components/Tooltip';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ procedure }) => {
  const classes = useStyles();
  const diagnosis = procedure?.icd?.map(({ code }, i) => `DX${i + 1}/${code}`).join(', ');

  return (
    <>
      <Typography gutterBottom variant="h6">{procedure?.cpt_code?.procedure_code}</Typography>

      {procedure?.cpt_code?.description ?
        <Box
          fontWeight="normal"
          height={38}
          className={
            cn(
              classes.noteContent,
              { [classes.noteContent_cropped]: procedure.cpt_code?.description?.length > 120 }
            )
          }
        >
          <Tooltip
            isExistTooltip
            leaveDelay={200}
            placement="bottom-start"
            title={procedure?.cpt_code?.description}
          >
            <Typography color="textSecondary" component="p" variant="caption" align="justify">
              {procedure?.cpt_code?.description}
            </Typography>
          </Tooltip>
        </Box>
        :
        <Typography color="textSecondary">-</Typography>
      }

      <div className={classes.root}>
        <Box>
          <Tooltip
            isExistTooltip={!!procedure?.icd?.length}
            title={procedure?.icd?.map((item) => (
              <TooltipTitle key={item.id} itemTitle={item.code}>
                {item.description &&
                  <Typography>- {item.description}</Typography>
                }
              </TooltipTitle>
            ))}
          >
            <Typography noWrap variant="h2" color="error">
              {diagnosis || '-'}
            </Typography>
          </Tooltip>

          <Typography variant="caption" color="textSecondary">
            DX Sequence/Diagnosis
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Box width="50%">
            <Typography variant="subtitle2">
              Unit {procedure?.unit}
            </Typography>
          </Box>

          <Box width="50%">
            <Typography variant="subtitle2">
              <CurrencyFormat value={procedure?.price || 0} prefix={'Price $ '}/>
            </Typography>
          </Box>
        </Box>
      </div>
    </>
  );
};
