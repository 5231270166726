import { createHookReducer } from '../../../../../helpers/createHookReducer';
import { moveArrayItem } from '../../../../../helpers/moveArrayItem';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_REMINDER_EVENTS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_REMINDER_EVENTS_SUCCESS]: (
    { filter, reminderEvents, ...state },
    { data: newReminderEvents, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      reminderEvents: page > 1 ? (
        reminderEvents.filter(({ id }) => {
          return !newReminderEvents.find((loadedReminderEvent) => id === loadedReminderEvent.id);
        }).concat(newReminderEvents)
      ) : (
        newReminderEvents
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_REMINDER_EVENTS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_REMINDER_EVENT]: (state, reminderEvent) => {
    const { pagination, filter, reminderEvents, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((reminderEvents.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      reminderEvents: [ reminderEvent, ...reminderEvents ]
    };
  },

  [types.UPDATE_REMINDER_EVENT]: ({ reminderEvents, ...state }, updatedReminderEvent) => {
    return {
      ...state,

      reminderEvents: reminderEvents.map((reminderEvent) => {
        return updatedReminderEvent.id === reminderEvent.id ? updatedReminderEvent : reminderEvent;
      })
    };
  },

  [types.UPDATE_REMINDER_EVENTS_POSITIONS]: ({ reminderEvents, ...state }, positions) => {
    const { oldIndex, newIndex } = positions;

    return {
      ...state,

      reminderEvents: moveArrayItem(reminderEvents, oldIndex, newIndex)
    };
  },

  [types.DELETE_REMINDER_EVENT]: (state, reminderEventID) => {
    const { pagination, filter, reminderEvents } = state;
    const isExistedReminderEvent = !!reminderEvents.find((reminderEvent) => reminderEventID === reminderEvent.id);

    if (!isExistedReminderEvent) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((reminderEvents.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      reminderEvents: reminderEvents.filter((reminderEvent) => reminderEventID !== reminderEvent.id)
    };
  }
});
