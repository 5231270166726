import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Formik } from 'formik';
import { Box, Button, Grid } from '@material-ui/core';
import * as insuranceApi from '../../../../../../api/profile/insurances';
import { InsurancesSelect } from '../../../../../../components/FormField';
import { Loader } from '../../../../../../components/Loader';
import { ModalFooter } from '../../../../../../components/Modal';
import { CaseMembersSelect } from '../../../../../../components/users/CaseMembersSelect';
import { rolesMap } from '../../../../../../dataMaps';
import { ViewInsuranceBody } from '../../ViewInsuranceInfoModal/ViewInsuranceBody';

const getExistInsuranceCaseOptions = (users) => {
  return users?.filter(({ case_role }) => case_role === rolesMap.applicant || case_role === rolesMap.defendant);
};

export const ExistingInsurance = ({ caseItem, onCaseItemUpdate, onModalResolve, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ members, setMembers ] = useState(caseItem?.case_users);
  const [ user, setUser ] = useState(null);
  const [ insurance, setInsurance ] = useState(null);

  const handleAddMembers = (values) => {
    onCaseItemUpdate(values);
    setMembers(values?.case_users);
  };

  const handleChangeUser = (option) => {
    setUser(option?.user || null);
  };

  const editInsurance = ({ caseItem = null, ...values }, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    return insuranceApi.updateInsurance(insurance.id, values).then((data) => {
      enqueueSnackbar('Insurance info updated successfully', { variant: 'success' });
      onModalResolve(data);
    }).catch(() => {
      enqueueSnackbar('Insurance info not updated', { variant: 'error' });
    });
  };

  return (
    <>
      <Grid container spacing={2} component={Box} pb={5}>
        <Grid item sm={6} xs={12}>
          <CaseMembersSelect
            required
            isCreatable
            withoutFormik
            name="case_user"
            formattedValue={false}
            label="Subscriber"
            creatablePayload={{
              caseItem,
              onCaseItemUpdate: handleAddMembers
            }}
            options={getExistInsuranceCaseOptions(members)}
            onChange={handleChangeUser}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <InsurancesSelect
            withoutFormik
            disabled={!user?.id}
            name="insurance"
            formattedValue={false}
            label="Insurance"
            params={{
              only_active: 1,
              user_id: user?.id || null,
              excluded_case_id: caseItem.id
            }}
            onChange={setInsurance}
          />
        </Grid>
      </Grid>

      {!!insurance?.id && (
        <Formik
          initialValues={{
            id: insurance?.id,
            case_id: caseItem?.id
          }}
          onSubmit={editInsurance}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <ViewInsuranceBody insurance={insurance} />

              <ModalFooter>
                <Button color="primary" onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />
              </ModalFooter>
            </Box>
          )}
        </Formik>
      )}
    </>
  );
};
