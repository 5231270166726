import Color from 'color';

export const styles = ({ palette: { grey, ...palette } }) => ({
  listRow: {
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    textDecoration: 'none',

    '&, &:active, &:focus': {
      background: 'none'
    }
  },

  listRow_header: {
    background: palette.common.white
  },

  listRow_border: {
    borderBottom: `1px solid ${palette.divider}`
  },

  listRow_button: {
    cursor: 'pointer',

    '&:hover, &:active, &:hover:focus': {
      background: Color(grey[300]).alpha(0.2).string()
    }
  },

  listRow_selected: {
    '&, &:focus': {
      background: Color(palette.primary.light).alpha(0.2).string(),
      borderColor: palette.primary.light
    }
  }
});
