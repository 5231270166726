export const initialValues = {
  cpt_id: null,
  pos_id: null,
  unit: 1,
  price: null,
  modifiers: [],
  icd: [],
  files: [],
  side: null,
  levels: [],
  surgery: null
};
