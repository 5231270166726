import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import * as formsApi from '../../../../../api/profile';
import { Divider } from '../../../../../components/Divider';
import { Loader } from '../../../../../components/Loader';
import {
  ActionButtons,
  MedicalHistoryForm,
  medicalHistoryInitialValues
} from '../../../../../components/medical/forms';
import {
  validationSchema
} from '../../../ProfilePage/MedicalInfo/MedicalForms/PrivatePacket/MedicalHistory/validationSchema';
import { FormContext } from '../../FormProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MedicalHistory = () => {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    form,
    isFetched,
    handleComplete,
    handleBack
  } = useContext(FormContext);

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updateGuestMedicalHistory(values).then(({ data }) => {
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleComplete(data);
    }).catch(({ errors }) => {
      setErrors(errors);
      enqueueSnackbar('Form not updated', { variant: 'error' });
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            token: form?.token || null,
            form_id: form.id,
            ...medicalHistoryInitialValues(form)
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <MedicalHistoryForm />
              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
