import { Typography } from '@material-ui/core';

export const EventDescription = ({ before = null, after = null, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Set&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;to&nbsp;
        <Typography variant="subtitle1" dangerouslySetInnerHTML={{ __html: after || '-' }} />
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <Typography variant="subtitle1" dangerouslySetInnerHTML={{ __html: before || '-' }} />
      </>
    );
  } else if (before !== after) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Typography variant="subtitle1" dangerouslySetInnerHTML={{ __html: before || '-' }} />
        &nbsp;to&nbsp;
        <Typography variant="subtitle1" dangerouslySetInnerHTML={{ __html: after || '-' }} />
      </>
    );
  } else {
    return (
      <>Updated <Typography variant="subtitle1">{fieldLabel}</Typography></>
    );
  }
};
