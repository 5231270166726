import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_CLAIMS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_CLAIMS_SUCCESS]: (
    { filter, claims, ...state },
    { data: newClaims, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      claims: (page > 1
        ? claims.filter(({ id }) => {
          return !newClaims.find((loadedClaim) => id === loadedClaim.id);
        }).concat(newClaims)
        : newClaims
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_CLAIMS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_CLAIM]: (state, claim) => {
    return {
      ...state,

      claims: state.claims.map((item) => {
        return item.id === claim.id ? { ...item, ...claim } : item;
      })
    };
  },

  [types.DELETE_CLAIM]: (state, claimID) => {
    const { pagination, filter, claims } = state;
    const filteredArrivalStatuses = claims.filter(({ id }) => claimID !== id);

    if (filteredArrivalStatuses.length === claims.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((claims.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      claims: filteredArrivalStatuses
    };
  },

  [types.ADD_CLAIMS_TO_SELECTED]: ({ selectedClaims, selectedClaimsIDs, ...state }, claims) => {
    return {
      ...state,

      selectedClaims: selectedClaims.concat(claims.filter(({ id }) => !selectedClaimsIDs.includes(id))),
      selectedClaimsIDs: [ ...new Set(selectedClaimsIDs.concat(claims.map(({ id }) => id))) ]
    };
  },

  [types.DELETE_CLAIMS_FROM_SELECTED]: ({ selectedClaimsIDs, ...state }, claimsIDs) => {
    return {
      ...state,

      selectedClaimsIDs: selectedClaimsIDs.filter((id) => claimsIDs.indexOf(id) === -1)
    };
  }
});
