import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { objectKeysToMap } from '../../../../../helpers/objectKeysToMap';
import { InfiniteListLoader } from '../../../../InfiniteLoader';
import { Loader } from '../../../../Loader';
import { ScrollbarsCustom } from '../../../../ScrollbarsCustom';
import { Sticky } from '../../../../Sticky';
import { CaseRemindersContext } from '../CaseRemindersProvider';
import { ReminderRow } from './ReminderRow';
import { TableHeader } from './TableHeader';

export const columnsWidths = {
  eventNumber: 68,
  group: 220,
  type: 120,
  note: 220,
  date: 120,
  relative: 100,
  caseItem: 160,
  settings: 100,
  active: 62,
  done: 62,
  edit: 52
};

export const columnsMap = objectKeysToMap(columnsWidths);

export const CaseRemindersList = ({ width, hiddenColumns, Row = ReminderRow, RowProps = {}, ...props }) => {
  const {
    isFetched,
    isFetching,
    reminders,
    meta,
    loadNextPage,
    resetReminders
  } = useContext(CaseRemindersContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetReminders();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef}>
      <Box p={2}>
        <Loader loading={!isFetched} render={
          () => !meta.total ? (
            <Typography align="center">No reminders found</Typography>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader width={width} hiddenColumns={hiddenColumns} ListRowProps={RowProps?.ListRowProps} />
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={reminders}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={meta}
                  RowComponent={Row}
                  RowProps={{ hiddenColumns, ...RowProps, width }}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
