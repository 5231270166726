import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getHeightFormat } from '../../../../helpers/generateHeight';
import { heightMap, measureTypes, weightMap } from '../../../../helpers/measureDataMap';
import * as profileApi from '../../../../api/profile';
import {
  setHistoryLastGlobalAction,
  setMedicalProfileLastGlobalAction
} from '../../../../store/globalActions';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../Modal';
import { Loader } from '../../../Loader';
import * as historyTypes from '../../../../app/Dashboard/MedicalReports/WeightHistory/HistoriesProvider';
import * as types from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider/types';
import { Body, MainContent, validationSchema } from '../CreateMeasurementsModal';

export const UpdateMeasurementsModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { user = {}, initialValues = {}, setInitialValues = () => {} }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatchRedux = useDispatch();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const updateWeightHistory = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.height = getHeightFormat({
      isFeet: values.height_unit === measureTypes.feet,
      isTransform: true
    })(values.height);

    return profileApi.updateWeightHistory(values).then((data) => {
      setInitialValues(data);
      dispatchRedux(setMedicalProfileLastGlobalAction({ type: types.UPDATE_PROFILE, payload: data }));
      dispatchRedux(setHistoryLastGlobalAction({ type: historyTypes.HISTORIES_FETCH_REQUEST }));
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors, message }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        errors && setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        height_unit: initialValues.height_unit || heightMap[1].value,
        weight_unit: initialValues.weight_unit || weightMap[1].value,
        weight: initialValues.weight || 0,
        height: initialValues.height || 0,
        bmi: initialValues.bmi || 0,
        temperature: initialValues.temperature || null,
        blood_type: initialValues.blood_type || null,
        heart_rate: initialValues.heart_rate || null,
        systolic_blood_pressure: initialValues.systolic_blood_pressure || null,
        diastolic_blood_pressure: initialValues.diastolic_blood_pressure || null
      }}
      validationSchema={validationSchema}
      onSubmit={updateWeightHistory}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isTablet} {...DialogProps}>
          <ModalContainer
            noValidate
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader onClose={handleModalReject}>
              Update Measurements
            </ModalHeader>

            <ModalBody>
              <MainContent />

              <Box color="info.main">
                <Typography gutterBottom variant="h4" color="inherit">BMI</Typography>
              </Box>

              <Body user={user} />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
