import { useState } from 'react';
import { useSnackbar } from 'notistack';
import filesize from 'filesize';
import { Formik } from 'formik';
import { makeStyles, Button, List, ListItem, ListItemText, Typography, Divider, Dialog } from '@material-ui/core';
import { getAggregateTypeFromMIME } from '../../../../../../helpers/files';
import * as codesApi from '../../../../../../api/codes/procedures';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../../../components/Modal';
import { Loader } from '../../../../../../components/Loader';
import { FileDropZone } from '../../../../../../components/FileDropZone';
import { Select } from '../../../../../../components/FormField/selects/Select';
import { FileTypeIcon } from '../../../../files-common';
import { typesDelimiter } from './typesDelimiter';
import { typesEnclosure } from './typesEnclosure';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ImportCPTCodesModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const [ selectedFile, setSelectedFile ] = useState();
  const [ fields, setFields ] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleFieldChange = (name) => (option) => {
    setFields({ ...fields, [name]: option?.value });
  };

  const uploadFiles = (values, { setErrors, setSubmitting }) => {
    if (!selectedFile) {
      setSubmitting(false);

      return;
    }

    const formData = new FormData();

    formData.append('file', selectedFile, selectedFile.name);

    if (selectedFile.type === 'text/csv') {
      formData.append('enclosure', fields.enclosure);
      formData.append('delimiter', fields.delimiter);
    }

    codesApi.importCode(formData).then(() => {
      enqueueSnackbar(`File "${selectedFile.name}" uploaded`, {
        variant: 'success'
      });

      handleModalResolve();
    }).catch(({ errors, message }) => {
      setSubmitting(false);

      if (errors) {
        errors && setErrors(errors);
        message && enqueueSnackbar(message, { variant: 'error' });
      }
    });
  };

  const handleDropAccepted = (files) => {
    files[0].original_filename = files[0].original_filename || files[0].name;
    files[0].aggregate_type = getAggregateTypeFromMIME(files[0].type);

    setSelectedFile(files[0]);
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{}}
        onSubmit={uploadFiles}
      >
        {({ errors, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Upload files
            </ModalHeader>

            <ModalBody>
              <FileDropZone onDropAccepted={handleDropAccepted}/>

              {errors.file &&
                <>
                  <Typography paragraph color="error">
                    {errors.file}
                  </Typography>

                  <Divider/>
                </>
              }

              {selectedFile && selectedFile.type === 'text/csv' &&
                <>
                  <Select
                    formattedValue
                    name="delimiter"
                    label="Delimiter"
                    options={typesDelimiter}
                    onChange={handleFieldChange('delimiter')}
                  />

                  <Select
                    formattedValue
                    name="enclosure"
                    label="Enclosure"
                    options={typesEnclosure}
                    onChange={handleFieldChange('enclosure')}
                  />
                </>
              }

              {!!selectedFile &&
                <div className={classes.filesList}>
                  <Typography variant="h2" gutterBottom>
                    File selected for upload
                  </Typography>

                  <List>
                    <ListItem>
                      <FileTypeIcon file={selectedFile}/>

                      <ListItemText
                        primary={selectedFile.name}
                        primaryTypographyProps={{ noWrap: true }}
                      />

                      <ListItemText className={classes.fileSize} primary={filesize(selectedFile.size)}/>
                    </ListItem>
                  </List>
                </div>
              }
            </ModalBody>

            <ModalFooter>
              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Upload
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
