import { api } from '../../api';

export const fetchMedicalProfile = (config) => {
  return api.get('/profile/medical', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchUserMedicalProfile = (id, config) => {
  return api.get(`/profile/medical/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMedicalProfile = ({ id, ...data }) => {
  return api.put(`/profile/medical/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMedicalProfileContacts = ({ id, ...data }) => {
  return api.put(`/profile/medical/${id}/contacts`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMedicalFamilyTree = ({ id, ...data }) => {
  return api.put(`/profile/medical/${id}/family-tree`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMedicalMeasurements = ({ id, ...data }) => {
  return api.put(`/profile/medical/${id}/bmi`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error.data; });
};

export const activateInsurance = (id) => {
  return api.post('/profile/medical/active-insurance', { insurance_id: id })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const addAllergies = ({ id, ...data }) => {
  return api.put(`/profile/medical/${id}/allergy`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
