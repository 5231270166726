export const styles = ({
  spacing,
  palette: { primary },
  transitions
}) => ({
  root: {
    cursor: 'pointer',
    transition: transitions.create(),
    padding: spacing(0, 1),

    '&:hover': {
      backgroundColor: 'rgba(0, 188, 212, 0.05)',
      borderBottom: `1px solid ${primary.main}`
    }
  },

  avatar: {
    marginRight: spacing()
  },

  label: {
    lineHeight: 1.3
  },

  role: {
    textTransform: 'capitalize'
  }
});
