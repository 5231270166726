import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { FilesFromPrintersContextProvider } from './FilesFromPrintersProvider';
import { ActionsBar } from './ActionsBar';
// import { FiltersBar } from './FiltersBar';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FilesFromPrintersPage = () => {
  const classes = useStyles();
  const { printerId } = useParams();

  return (
    <div className={classes.root}>
      <FilesFromPrintersContextProvider printerId={printerId}>
        <ActionsBar />
        {/*<FiltersBar />*/}
        <List />
      </FilesFromPrintersContextProvider>
    </div>
  );
};
