import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Grid } from '@material-ui/core';
import * as formsApi from '../../../../../../api/profile';
import { AddInsuranceInfoModal } from '../../../../../../app/Dashboard/ProfilePage/insurance';
import {
  insuranceFormTypesMap
} from '../../../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/insuranceFormTypesMap';
import { Button } from '../../../../../Button';
import { useModal } from '../../../../../ModalsProvider';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { ShortInsuranceInfo } from '../../../../../users/ShortInsuranceInfo';
import { formStatusesMap, formTypesMap } from '../../../formTypesMap';

export const InsuranceStep = ({ form, setFormValue }) => {
  const { openModal } = useModal();
  const [ insurance, setInsurance ] = useState(form?.primary_insurance);
  const { enqueueSnackbar } = useSnackbar();

  const addInsurance = () => {
    openModal(AddInsuranceInfoModal, {
      payload: {
        disableScan: true,
        type_insurance: form?.form_type !== formTypesMap.private
          ? insuranceFormTypesMap?.[form?.form_type]
          : null,
        status_type: form?.form_type === formTypesMap.private
          ? formStatusesMap.primary
          : formStatusesMap.law_office,
        medicalFormID: form?.is_editable ? form.id : null,
        insuranceInfo: {
          form_type: form?.form_type,
          name: form?.primary_insurance_name,
          group_id_number: form?.group_id_number,
          primary_insurance_id_number: form?.primary_insurance_id_number,
          primary_insurance_name: form?.primary_insurance_name,
          primary_insurance_holder_first_name: form?.primary_insurance_holder_first_name,
          primary_insurance_holder_last_name: form?.primary_insurance_holder_last_name,
          primary_insurance_holder_dob: form?.primary_insurance_holder_dob,
          relationship_to_patient: form?.relationship_to_patient,
          attorney_first_name: form?.attorney_first_name || null,
          attorney_last_name: form?.attorney_last_name || null,
          attorney_email: form?.attorney_email,
          attorney_phone: form?.attorney_phone,
          address: form?.home || form?.patient?.home || null,
          dob: form.primary_insurance_holder_dob || form.birthday ||
            form?.patient?.birthday || null,
          first_name: form?.primary_insurance_holder_first_name
            || form?.first_name
            || form?.patient?.first_name
            || null,
          last_name: form?.primary_insurance_holder_last_name
            || form?.last_name
            || form?.patient?.last_name
            || null,
          ...form.company
        }
      },

      onModalResolved: (insurance) => {
        setInsurance(insurance);

        formsApi.updateMedicalForm({ id: form.id, primary_insurance_id: insurance.id }).then((data) => {
          setFormValue(data);
          enqueueSnackbar('Successfully created', { variant: 'success' });
        }).catch((error) => {
          if (error) {
            enqueueSnackbar('Not created', { variant: 'error' });
          }
        });
      }
    });
  };

  return (
    <>
      <Box pb={2}>
        <Button
          disabled={insurance?.id}
          size="small"
          variant="contained"
          color="primary"
          onClick={addInsurance}
        >
          Add Insurance
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReadonlyCheckbox
            checked={!!insurance?.id}
            title="Insurance created"
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <ShortInsuranceInfo insurance={insurance} />
        </Grid>
      </Grid>
    </>
  );
};
