import { Yup } from '../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  code: Yup.string().nullable().max(255).required(),
  description: Yup.string().nullable().required(),
  _hours: Yup.number().integer().positive().nullable().typeError('Must be a number'),
  _minutes: Yup
    .number()
    .integer()
    .nullable()
    .typeError('Must be a number')
    .when('_hours', (hours, schema) => {
      return hours ? schema : schema.required().min(5);
    }),
  ordering: Yup.number().integer().positive().required().typeError('Must be a number'),
  color: Yup.string().nullable().max(255).required(),
  claim_required: Yup.boolean(),
  out_of_office_visit: Yup.boolean(),
  new_visit: Yup.boolean()
});
