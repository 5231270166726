import { objectKeysToMap } from '../../../../../helpers/objectKeysToMap';

export const columnsWidths = {
  name: 200,
  description: 200,
  email: 200,
  creator: 240,
  actions: 48
};

export const columnsMap = objectKeysToMap(columnsWidths);
