import { makeStyles } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Badge } from '../../../Badge';
import { useOnlineUsers } from '../../OnlineUsersProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const OnlineIndicator = ({ invisible, user, ...props }) => {
  const classes = useStyles();
  const { onlineUsersIDs } = useOnlineUsers();

  return (
    <Badge
      invisible={invisible || !onlineUsersIDs?.includes(user.id)}
      overlap="circle"
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      badgeContent={
        <FiberManualRecordIcon fontSize="small" className={classes.icon} />
      }

      {...props}
    />
  );
};
