export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { pxToRem, fontWeightMedium }
}) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px dashed rgba(0, 0, 0, 0.1)',
    padding: spacing(2)
  },

  contentCheckbox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: spacing(0.5),
    marginBottom: spacing()
  },

  contentInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
    paddingBottom: spacing()
  },

  contentInfo__item: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing(1.5),
    width: '40%',

    '&:last-child': {
      width: '60%',
      marginTop: 0
    }
  },

  contentInfo__title: {
    fontSize: pxToRem(11),
    paddingBottom: spacing(0.5),
    paddingTop: spacing(0.5),
    fontWeight: fontWeightMedium
  },

  contentInfo__titleInfo: {
    fontSize: pxToRem(14),
    paddingBottom: spacing(0),
    paddingTop: spacing(2)
  },

  description: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2)
  },

  descriptionTitle: {
    fontWeight: fontWeightMedium
  },

  descriptionContent: {
    color: grey[500],
    paddingTop: spacing()
  },

  actionButton: {
    textTransform: 'none',
    marginLeft: 'auto'
  },

  sectionHeader: {
    padding: spacing(1.5, 2),
    flexGrow: 1,
    fontSize: pxToRem(16),
    color: ({ color }) => palette[color]?.main || palette.common.white,
    backgroundColor: grey[600]
  }
});
