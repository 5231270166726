import { Fragment, useEffect, useState } from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import filesize from 'filesize';
import {
  Box,
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { getAggregateTypeFromMIME } from '../../../../../helpers/files';
import { Fab } from '../../../../../components/Fab';
import { IconButton } from '../../../../../components/IconButton';
import { Attachments as AttachmentsComponent } from '../../../../../components/Attachments';
import { FileTypeIcon } from '../../../files-common';

export const Attachments = ({ name = 'files' }) => {
  const { errors, setFieldValue } = useFormikContext();
  const { openModal } = useModal();
  const [ selectedFiles, setSelectedFiles ] = useState([]);

  const handleDeleteFile = (file) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setSelectedFiles(selectedFiles.filter(({ original_filename }) => original_filename !== file.original_filename));
      }
    });
  };

  useEffect(() => {
    setFieldValue(name, selectedFiles);
  }, [ selectedFiles ]);

  return (
    <Box p={2}>
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Typography variant="h3">Attachments</Typography>
        </Box>

        <AttachmentsComponent selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles}>
          <Fab
            size="small"
            color="primary"
            variant="extended"
            startIcon={<AddIcon/>}
          >
            Add
          </Fab>
        </AttachmentsComponent>

        {errors.files &&
          <Box ml={3}>
            <Typography color="error"><ErrorMessage name="files" /></Typography>
          </Box>
        }
      </Box>

      <Collapse unmountOnExit in={!!selectedFiles?.length} timeout="auto">
        <List>
          {selectedFiles.map((file, index) => (
            <Fragment key={index}>
              <ListItem>
                <FileTypeIcon
                  file={{
                    aggregate_type: getAggregateTypeFromMIME(file.type),
                    ...file
                  }}
                />

                <ListItemText
                  primary={file.original_filename || file.name}
                  primaryTypographyProps={{ noWrap: true }}
                />

                <ListItemText
                  primary={filesize(file.size)}
                  primaryTypographyProps={{ align: 'right' }}
                />

                <ListItemSecondaryAction>
                  <IconButton edge="end" color="error" onClick={handleDeleteFile(file)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Fragment>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};
