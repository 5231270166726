import moment from 'moment';
import filesize from 'filesize';
import { Box, Checkbox, makeStyles, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { getFileNameExtension } from '../../../../../../helpers/files';
import { FileTypeIcon } from '../../../../files-common';
import { FilesFromPrintersContext } from '../../FilesFromPrintersProvider';
import { columnsWidths } from '../List';
import { Menu } from './Menu';

const useStyles = makeStyles({
  fileIcon: {
    fontSize: 30
  }
});

export const Row = ({ file }) => {
  const classes = useStyles();
  const extension = getFileNameExtension(file.name);
  const { selectedFiles, toggleFileSelection } = useContext(FilesFromPrintersContext);
  const isSelected = selectedFiles.includes(file.name);

  const handleItemSelection = () => {
    toggleFileSelection(file.name);
  };

  return (
    <ListRow selected={isSelected}>
      <ListRowCell>
        <Checkbox
          edge="start"
          checked={isSelected}
          onClick={handleItemSelection}
        />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Box pr={1}>
          <FileTypeIcon file={{ extension }} className={classes.fileIcon} />
        </Box>

        <Typography noWrap variant="body1">
          {file?.name || ''}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.size}>
        {filesize(file.size) || ''}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {moment.unix(file?.last_modified).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} justifyContent="flex-end">
        <Menu file={file} />
      </ListRowCell>
    </ListRow>
  );
};
