const medicationsTypes = {
  aspirin: 'aspirin',
  coumadin: 'coumadin',
  plavix: 'plavix',
  heparin: 'heparin',
  pletal: 'pletal',
  lovenox: 'lovenox',
  ticlid: 'ticlid'
};

export const medicationsMap = {
  [medicationsTypes.aspirin]: 'Aspirin',
  [medicationsTypes.coumadin]: 'Coumadin',
  [medicationsTypes.plavix]: 'Plavix',
  [medicationsTypes.heparin]: 'Heparin',
  [medicationsTypes.pletal]: 'Pletal',
  [medicationsTypes.lovenox]: 'Lovenox',
  [medicationsTypes.ticlid]: 'Ticlid'
};
