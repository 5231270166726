export const fieldsKeysMap = {
  startedAfter: 'started_after',
  startedBefore: 'started_before',
  cases: 'cases',
  tasks: 'tasks',
  scheduleEvents: 'schedule_events'
};

export const filterLabels = {
  [fieldsKeysMap.startedAfter]: 'Date From',
  [fieldsKeysMap.startedBefore]: 'Date To',
  [fieldsKeysMap.cases]: 'Cases',
  [fieldsKeysMap.tasks]: 'Tasks',
  [fieldsKeysMap.scheduleEvents]: 'Schedule Events'
};
