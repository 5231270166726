import moment from 'moment';
import { useContext } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { stopPropagation } from '../../../../../../helpers/dom';
import { generateAddress } from '../../../../../../utils/generateAddress';
import { UserPreviewModal } from '../../../../../../components/users';
import { useModal } from '../../../../../../components/ModalsProvider';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Date } from '../../../../../../components/Date';
import { ReportsPreviewContext } from '../../../ReportsPreviewProvider';
import { ItemsList } from './ItemsList';

export const MainInfo = () => {
  const { openModal } = useModal();
  const { selectedItem: test } = useContext(ReportsPreviewContext);

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" component={Box} pt={3}>
        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Patient</Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <UserLink
            variant="h5"
            user={test.patient}
            onClick={stopPropagation(openUserPreview(test?.patient?.id))}
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Ordered by/Provider</Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <UserLink
            variant="h5"
            user={test?.ordered}
            onClick={stopPropagation(openUserPreview(test?.ordered?.id))}
          />
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Date/Duration</Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Box display="flex" alignItems="center">
            <Date date={test?.appointment.appointment_at} format="L" iconFontSize="small" variant="h5" />

            <Box clone ml={1.5} mr={0.5}>
              <TimelapseIcon fontSize="small" />
            </Box>

            <Typography variant="h5">
              {moment.duration(test?.appointment?.time, 'seconds').format('h[h] m[min]')}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Office</Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Tooltip
            isExistTooltip={!!test?.appointment?.office}
            title={test?.appointment?.office?.full_address || generateAddress(test?.appointment?.office)}
          >
            <OfficeLink
              variant="h5"
              office={test?.appointment?.office}
            />
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="flex-start" component={Box} py={5}>
        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Type Insurance</Typography>
        </Grid>

        <Grid item sm={10} xs={6}>
          <Typography variant="h5">
            {test?.appointment?.patient_insurance?.type_insurance}
          </Typography>
        </Grid>

        <Grid item sm={2} xs={6}>
          <Typography color="textSecondary">Insurance ID</Typography>
        </Grid>

        <Grid item sm={10} xs={6}>
          <Typography variant="h5">
            {test?.appointment?.patient_insurance?.insuranceId}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} component={Box} pb={5} alignItems="flex-start">
        <Grid item sm={2} xs={6}>
          <Typography variant="h5">Name lab test</Typography>
        </Grid>

        <Grid item sm={10} xs={6}>
          <Box color="info.main">
            <Typography gutterBottom variant="h5" color="inherit">
              {test?.test?.name}
            </Typography>
          </Box>

          <Typography>
            {test?.test?.description}
          </Typography>
        </Grid>
      </Grid>

      <ItemsList />
    </>
  );
};
