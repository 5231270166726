import { MedicationList } from './MedicationList';
import { ClinicalNotes } from './ClinicalNotes';
import { PrescriptionsContainer } from './Prescriptions';
import { WeightHistory } from './WeightHistory';
import { Consultation } from './Consultation';
import { Immunization } from './Immunization';
import { Procedures } from './Procedures';
import { Diagnosis } from './Diagnosis';
import { Tests } from './Tests';
import { MRI } from './MRI';

export const reportRoutes = [
  {
    pathName: '/consultation',
    label: 'Consultation',
    component: Consultation
  },
  {
    pathName: '/weight-history',
    label: 'Measurements',
    component: WeightHistory
  },
  {
    pathName: '/mri',
    label: 'MRI',
    component: MRI
  },
  {
    pathName: '/surgeries-procedures',
    label: 'Surgeries/Procedures',
    component: Procedures
  },
  {
    pathName: '/clinical-notes',
    label: 'Clinical Notes',
    component: ClinicalNotes
  },
  {
    pathName: '/tests',
    label: 'Tests',
    component: Tests
  },
  {
    pathName: '/medication-list',
    label: 'Medication List',
    component: MedicationList
  },
  {
    pathName: '/immunizations',
    label: 'Immunizations',
    component: Immunization
  },
  {
    pathName: '/prescriptions',
    label: 'Prescriptions',
    component: PrescriptionsContainer
  },
  {
    pathName: '/diagnosis',
    label: 'Diagnosis',
    component: Diagnosis
  }
];

export const getReportsRoutes = (pathname) => (
  reportRoutes.map((item) => ({ ...item, path: pathname + item.pathName }))
);
