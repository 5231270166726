import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { useEmailsModal } from '../../../../../../../utils/hooks';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { EmailsContent } from '../../../../../../../components/Widgets';
import { Fab } from '../../../../../../../components/Fab';
import {
  AccountsContext,
  SavedEmailsContext,
  SavedEmailsContextProvider
} from '../../../../../EmailsPage';

export const Emails = ({
  height = null,
  page,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},
  onClose,
  onOpen,
  caseItem,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { accounts } = useContext(AccountsContext);
  const { user } = useSelector(({ profile }) => profile);
  const { onSendEmail } = useEmailsModal({ caseItem, patient: user });
  const filter = {
    case_id: caseItem.id || null
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <SavedEmailsContextProvider filter={filter} caseItem={caseItem}>
      <SavedEmailsContext.Consumer>
        {({ fetchSavedMessages }) => (
          <ContentCard
            fullHeight={!props.autoHeightMax}
            isMovable={!props.autoHeightMax}
            disableContentPaddings
            title="Emails"
            icon={
              <Fab
                size="small"
                color="primary"
                variant="extended"
                onClick={onSendEmail({ fetchSavedMessages, accounts })}
              >
                Compose message
              </Fab>
            }
            leftActions={[
              <EmailIcon color="inherit" fontSize="small"/>
            ]}
            rightActions={[
              <WidgetsActionButtons
                isMinimizable
                page={page}
                type={props?.item?.i}
                isDisabledOpen={isDisabledOpen}
                onMinimize={onMinimize}
                onMaximized={onMaximized}
                onClose={onClose}
                onOpen={onOpen}
                onPutItem={onPutItem}
              />
            ]}
            {...props}
          >
            <Box height={isMobile ? height : '100%'}>
              <EmailsContent />
            </Box>
          </ContentCard>
        )}
      </SavedEmailsContext.Consumer>
    </SavedEmailsContextProvider>
  );
};
