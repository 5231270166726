import { useRef, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { saveFilter } from '../../../../../store/lastFilters';
import { IconComponent } from '../../../../../components/saved-filters';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { Autocomplete, KeyboardDatePicker, TextField } from '../../../../../components/FormField';
import { TranscribesContext } from '../TranscribesProvider';
import { transcribeStatusOptions } from '../transcribeStatusesMap';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { CardContent } from './CardContent';
import { List } from './List';

export const transformRelationsForFilterToOptions = (relationsForFilter = {}) => {
  return {
    ...relationsForFilter,

    status: transcribeStatusOptions.find(({ value }) => value === relationsForFilter.status)
  };
};

const initialValues = {
  name: null,
  date: null,
  status: null
};

const MODAL_WIDTH = 620;

export const FiltersBar = ({ filterKey }) => {
  const theme = useTheme();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { applyFilter } = useContext(TranscribesContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState(initialValues);

  const handleTextFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleDatePickerChange = (name) => (date) => {
    applyFilter(({ [name]: date }));
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleStatusChange = (status) => {
    applyFilter({ status: status?.value });
    setRelationsForFilter((state) => ({ ...state, status }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);
    applyFilter(filter);
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    applyFilter(lastFilters);
    formikRef?.current?.setValues(transformRelationsForFilterToOptions(lastFilters));

    formikRef.current.setValues({
      ...relationsForFilter,
      ...lastFilters
    });
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      formikRef={formikRef}
      border={0}
      initialValues={relationsForFilter}
      onReset={() => applyFilter(initialValues)}
      filterKey={filterKey}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.name,
          label: filterFieldsLabels[filterFieldsMap.name],
          field: <Box minWidth={isMobile ? '100%' : 250}>
            <TextField
              name="filename"
              label="Name"
              placeholder="Enter name..."
              onChange={handleTextFieldChange('filename')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.date,
          label: filterFieldsLabels[filterFieldsMap.date],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              outputFormat="YYYY-MM-DD"
              name="date"
              label="Date"
              onChange={handleDatePickerChange('date')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.status,
          label: filterFieldsLabels[filterFieldsMap.status],
          field: <Box minWidth={140}>
            <Autocomplete
              name="status"
              label="Status"
              placeholder="Select status..."
              options={transcribeStatusOptions}
              onChange={handleStatusChange}
            />
          </Box>
        }
      ]}
    />
  );
};
