import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import * as formsApi from '../../../../../../../api/profile';
import { ActionButtons } from '../../../../../../../components/medical/forms';
import { generateAccidentInitialValues } from '../../../../../../../components/medical/forms/accidentInfo';
import {
  carAccidentValidationSchema,
  otherAccidentValidationSchema,
  slipAndFallValidationSchema
} from '../../../../../../../components/medical/forms/accidentInfo';
import { accidentTypesTitles } from '../../../../../../../components/medical/accidentTypes';
import { AccidentBody } from '../../../../../../../components/medical/forms/personalInjury';
import { Divider } from '../../../../../../../components/Divider';
import { Loader } from '../../../../../../../components/Loader';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import * as types from '../../MedicalFormsProvider/types';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AccidentInfo = () => {
  const classes = useStyles();
  const params = useParams();
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetched, form, handleComplete, handleBack } = useContext(MedicalFormItemContext);
  const validationSchema = form?.accident_type === accidentTypesTitles.car_accident
    ? carAccidentValidationSchema
    : form?.accident_type === accidentTypesTitles.slip_and_fall
      ? slipAndFallValidationSchema
      : otherAccidentValidationSchema;

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    formsApi.updateAccidentInformation(values)
      .then(({ data }) => {
        dispatchRedux({ type: types.UPDATE_FORM, payload: data });
        enqueueSnackbar('Form successfully updated', { variant: 'success' });
        handleComplete(form.id);
      })
      .catch(({ errors, message }) => {
        setSubmitting(false);

        if (errors) {
          const errorMessage = Object.values(errors);

          errors && setErrors(errors);
          message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
        }
      });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          enableReinitialize
          initialValues={{
            form_id: form.id,
            ...generateAccidentInitialValues(form)
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <AccidentBody form={form} />
              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
