import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow header>
      <ListRowCell flexBasis={columnsWidths.doiFrom}>
        DOI From
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.doiTo}>
        DOI To
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dorFrom}>
        DOR From
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dorTo}>
        DOR To
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        Provider
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.office}>
        Office
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
