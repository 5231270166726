import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  List,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { hasRole } from '../../../../../utils/hasRole';
import { itemsRoutesByModels } from '../../../../../dataMaps/apiModels';
import { useModal } from '../../../../../components/ModalsProvider';
import { CompaniesContext } from '../CompaniesContext';
import { EditCompanyModal } from '../EditCompanyModal';

export const CompaniesMenu = ({ company, onClose }) => {
  const { openModal } = useModal();
  const history = useHistory();
  const { updateCompany, deleteCompany } = useContext(CompaniesContext);

  const handleUpdateCompany = () => {
    openModal(EditCompanyModal, {
      payload: {
        company_id: company.id
      },
      onModalResolved: (data) => {
        updateCompany(data);
        onClose();
      }
    });
  };

  const handleDeleteCompany = () => {
    deleteCompany(company.id);
  };

  const getCompanyPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.company(company.id));
    }
  };

  return (
    <List>
      <MenuItem onClick={getCompanyPage}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      <MenuItem onClick={handleUpdateCompany}>
        <ListItemIcon>
          <EditIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      {!hasRole('client', 'patient', 'doctor', 'medic') &&
        <MenuItem onClick={handleDeleteCompany}>
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="inherit"/>
          </ListItemIcon>

          <ListItemText primary="Delete" />
        </MenuItem>
      }
    </List>
  );
};
