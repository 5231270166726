import { useContext, useEffect, useState } from 'react';
import { Box, List as MuiList, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { useDebounce } from '../../../../../../../helpers/hooks';
import { ModalBody, ModalContainer, ModalHeader } from '../../../../../../../components/Modal';
import { SelectedItemsCount } from '../../../../../../../components/SelectedItemsCount';
import { TextField, CompaniesAutocomplete } from '../../../../../../../components/FormField';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../../components/IconButton';
import { FiltersBar } from '../../../../../../../components/FiltersBar';
import { AppBar } from '../../../../../../../components/AppBar';
import { Loader } from '../../../../../../../components/Loader';
import { Fab } from '../../../../../../../components/Fab';
import { CreateScheduleModal } from '../CreateScheduleModal';
import { SchedulesContext } from '../SchedulesContext';
import { Row } from './Row';
import { TableHeader } from './TableHeader';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 54;
export const columnsWidths = {
  payer: 150,
  charge_amount: 150,
  expected_amount: 150,
  action: 140,
  checkbox: 58
};

export const MainContent = ({ parent, parentID, handleModalReject }) => {
  const classes = useStyles();
  const {
    isFetched,
    filter,
    pagination,
    meta,
    schedules,
    resetSchedules,
    selectedSchedulesIDs,
    massDeleteSchedules
  } = useContext(SchedulesContext);
  const [ fields, setFields ] = useState({});
  const debouncedFields = useDebounce(fields);
  const { openModal } = useModal();

  const handleFieldChange = (name) => (event) => {
    const value = event.target.value;

    setFields({ ...fields, [name]: value });
  };

  const handleCompaniesChange = (companies) => {
    resetSchedules(({ ...filter, payers_id: companies?.map(({ id }) => id) || null }));
  };

  const openScheduleModal= () => {
    openModal(CreateScheduleModal, {
      payload: { procedure_code_id: parentID }
    });
  };

  const handleMassDelete = () => {
    massDeleteSchedules(selectedSchedulesIDs);
  };

  useEffect(() => {
    resetSchedules(debouncedFields);
  }, [ debouncedFields ]);

  return (
    <ModalContainer>
      <ModalHeader onClose={handleModalReject}>
        {parent}
      </ModalHeader>

      <ModalBody disablePaddings>
        <AppBar>
          <Fab
            color="primary"
            variant="extended"
            startIcon={<AddIcon />}
            onClick={openScheduleModal}
          >
            add fee schedule
          </Fab>

          {isFetched && selectedSchedulesIDs.length > 0 &&
            <div className={classes.actionBar}>
              <SelectedItemsCount length={selectedSchedulesIDs.length} total={pagination.total} />
              <VerticalDivider verticalGutters={1} horizontalGutters={1} />

              <IconButton
                variant="contained"
                color="error"
                onClick={handleMassDelete}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          }
        </AppBar>

        <FiltersBar
          isResetForm
          disableTopPaddings
          initialValues={{
            payers_id: [],
            amount: null
          }}
          fields={[
            <CompaniesAutocomplete
              multiple
              name="payers_id"
              label="Search payers"
              placeholder="Search"
              margin="dense"
              onChange={handleCompaniesChange}
            />,

            <TextField
              label="Amount"
              name="amount"
              margin="dense"
              onChange={handleFieldChange('amount')}
            />
          ]}
        />

        <Loader p={3} loading={!isFetched} render={
          () => !schedules.length ? (
            <Box p={3}>
              <Typography align="center">No schedules found</Typography>
            </Box>
          ) : (
            <>
              <TableHeader />

              <MuiList disablePadding>
                {schedules.map((schedule) => (
                  <Row key={schedule.id} schedule={schedule} />
                ))}
              </MuiList>

              <TablePagination pagination={meta} onChange={resetSchedules} />
            </>
          )}
        />
      </ModalBody>
    </ModalContainer>
  );
};
