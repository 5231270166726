import { Box, Link, Typography } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { stopPropagation } from '../../../../../../helpers/dom';
import { useModal } from '../../../../../../components/ModalsProvider';
import { CaseViewModal } from '../../../../CasesPage/Cases/CaseViewModal';

export const CaseModel = ({
  model,
  variant = 'body2',
  color = 'textSecondary',
  iconColor = 'secondary'
}) => {
  const { openModal } = useModal();

  const openPreview = () => {
    openModal(CaseViewModal, {
      payload: { id: model.id }
    });
  };

  const modelProps = !model?.file_number ? {
    component: Link,
    onClick: stopPropagation(openPreview)
  } : {};

  return !model ? (
    <Typography>-</Typography>
  ) : (
    <>
      <WorkIcon color={iconColor} />

      <Box ml={1} overflow="hidden" textOverflow="ellipsis">
        {model?.name &&
          <Typography
            noWrap
            variant={variant}
            color={color}
            {...modelProps}
          >
            {model?.name}
          </Typography>
        }

        {model?.file_number &&
          <Typography
            noWrap
            color="textPrimary"
            component={Link}
            onClick={stopPropagation(openPreview)}
          >
            {model?.file_number}
          </Typography>
        }
      </Box>
    </>
  );
};
