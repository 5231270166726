import { PreviewContent } from '../../../../GuestMedicalProfile/PreviewContent';
import { MedicalHistory } from '../PrivatePacket/MedicalHistory';
import { ApptFormContent } from '../ApptFormContent';
import { AccidentInfo } from './AccidentInfo';
import { CurrentSymptoms } from './CurrentSymptoms';
import { DocumentsStep } from './DocumentsStep';
import { GeneralInfo } from './GeneralInfo';
import { PainContent } from './PainContent';

export const formStepComponents = [
  GeneralInfo,
  ApptFormContent,
  CurrentSymptoms,
  AccidentInfo,
  PainContent,
  MedicalHistory,
  DocumentsStep
];

export const formStepUnconfirmedComponents = [
  GeneralInfo,
  ApptFormContent,
  CurrentSymptoms,
  AccidentInfo,
  PainContent,
  MedicalHistory,
  PreviewContent
];
