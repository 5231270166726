import { useContext, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { InfiniteGridLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { ScheduleTypesContext } from '../ScheduleTypesProvider';
import { Card } from './Card';

export const Grid = () => {
  const {
    eventTypes,
    isFetching,
    isFetched,
    filter,
    resetEventTypes,
    loadNextPage
  } = useContext(ScheduleTypesContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const scrollElementRef = useRef();

  useEffect(() => {
    resetEventTypes();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box py={2} pl={isMobile ? 1 : 2}>
        <Loader loading={!isFetched} render={
          () => !eventTypes.length ? (
            <Box p={3}>
              <Typography align="center">No event types found</Typography>
            </Box>
          ) : (
            <InfiniteGridLoader
              items={eventTypes}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={filter}
              CellComponent={Card}
              onNeedNextPage={loadNextPage}
              scrollElementRef={scrollElementRef}
              cellWidth={250}
              cellHeight={180}
            />
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
