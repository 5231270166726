export const initialValues = {
  to_type: 'user',
  from_type: 'user',
  to_id: null,
  from_id: null,
  user_type: null,
  type: null,
  due_date: null,
  send_date: null,
  received_date: null,
  extension: null
};
