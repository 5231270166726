import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { filtersKeysMap } from '../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../utils/useMobileFilterCollapse';
import { LayoutContext, LayoutContextProvider, viewVariantsMap } from '../../../../components/LayoutContext';
import { CreateMeasurementsModal } from '../../../../components/medical/forms';
import { Page, PageBody, PageHeader } from '../../../../components/Page';
import { useModal } from '../../../../components/ModalsProvider';
import { Loader } from '../../../../components/Loader';
import { UserContext } from '../../Members/User/UserProvider';
import { ActionsBar } from '../ActionsBar';
import { FiltersBar } from './FiltersBar';
import { List } from './List';
import { Grid } from './Grid';
import {
  HistoriesContext,
  HistoriesProvider
} from './HistoriesProvider';

export const WeightHistory = () => {
  const { openModal } = useModal();
  const profile = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : profile.user;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  const openWidgetModal = () => {
    openModal(CreateMeasurementsModal, {
      payload: {
        medicalID: user?.medical?.id,
        user
      }
    });
  };

  return (
    <LayoutContextProvider>
      <LayoutContext.Consumer>
        {({ viewVariant }) => (
          <Page>
            <Loader p={3} loading={!user?.medical?.id} render={
              () => (
                <HistoriesProvider filter={{ medical_id: user?.medical?.id }}>
                  <PageHeader disableBorderBottom>
                    <HistoriesContext.Consumer>
                      {({ selectedIDs, pagination, massDeleteItems }) => (
                        <ActionsBar
                          disableViewVariant={isTablet}
                          actionTitle="Add new"
                          selectedIDs={selectedIDs}
                          pagination={pagination}
                          onCreate={openWidgetModal}
                          onDelete={massDeleteItems(selectedIDs)}
                          filterOpenButton={filterOpenButton}
                        />
                      )}
                    </HistoriesContext.Consumer>
                  </PageHeader>

                  <PageBody fullHeight disablePaddings disableScrollbars>
                    <Box height="100%" display="flex" flexDirection="column">
                      <ScrollWrapper {...scrollWrapperProps}>
                        <FilterBarWrapper {...filterBarWrapperProps}>
                          <FiltersBar filterKey={filtersKeysMap.weight_history} />
                        </FilterBarWrapper>
                      </ScrollWrapper>

                      <Box flexGrow={1}>
                        {!isTablet ? (
                          (viewVariant === viewVariantsMap.list)
                            ? <List />
                            : (viewVariant === viewVariantsMap.grid)
                              ? <Grid /> : null
                        ) : (
                          <Grid />
                        )}
                      </Box>
                    </Box>
                  </PageBody>
                </HistoriesProvider>
              )}
            />
          </Page>
        )}
      </LayoutContext.Consumer>
    </LayoutContextProvider>
  );
};
