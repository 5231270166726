export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    height: '100%',

    [breakpoints.down('md')]: {
      flexDirection: 'column',
      overflow: 'auto'
    },

    '& .fc-toolbar': {
      [breakpoints.down(breakpoints.values.sm)]: {
        flexDirection: 'column'
      }
    },

    '& .fc-button-group': {
      [breakpoints.down(breakpoints.values.sm)]: {
        marginBottom: spacing(0.5)
      }
    },

    '& .fc-toolbar.fc-header-toolbar': {
      [breakpoints.down(breakpoints.values.sm)]: {
        marginBottom: spacing(0.5)
      }
    }
  },

  calendar: {
    flexGrow: 1,
    height: '100%',
    paddingLeft: spacing(3),
    paddingRight: spacing(),

    [breakpoints.down('md')]: {
      minHeight: '100%',
      paddingLeft: spacing()
    }
  },

  list: {
    width: 372,
    height: '100%',

    [breakpoints.down('md')]: {
      width: '100%'
    }
  }
});
