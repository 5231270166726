import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_CERTIFICATES_SUCCESS]: (state, payload) => {
    return {
      ...state,

      isFetched: true,
      certificates: payload
    };
  },

  [types.ADD_CERTIFICATE]: (state, payload) => {
    const { pagination, filter, certificates, ...otherState } = state;

    return {
      ...otherState,

      certificates: [ ...certificates, payload ]
    };
  },

  [types.DELETE_CERTIFICATE]: ({ certificates, ...state }, id) => {
    return {
      ...state,

      certificates: certificates.filter((item) => item.id !== id)
    };
  }
});
