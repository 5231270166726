import { Box, Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { AddressLink } from '../../../AddressLink';
import { OfficeLink } from '../../../OfficeLink';

export const OfficesContent = ({ user }) => {
  return (
    <>
      <Grid item xs={4}>
        <Typography noWrap color="textSecondary">
          Office
        </Typography>
      </Grid>

      <Grid item xs={8}>
        {!user?.work?.offices?.length ? (
          <Typography color="textSecondary">No info.</Typography>
        ) : user?.work?.offices?.map((office) => (
          <Box key={office.id} display="flex" flexDirection="column" mb={1}>
            <OfficeLink variant="h5" office={office} />

            {!!office?.full_address &&
              <Box display="flex" alignItems="flex-start" color="text.secondary">
                <Box mr={0.5}>
                  <LocationOnIcon fontSize="small" color="inherit" />
                </Box>

                <AddressLink variant="body2" address={office?.full_address}>
                  {office?.full_address}
                </AddressLink>
              </Box>
            }
          </Box>
        ))}
      </Grid>

      <Grid item xs={4}>
        <Typography noWrap color="textSecondary">
          Main Office Venue
        </Typography>
      </Grid>

      <Grid item xs={8}>
        {!user?.work?.offices?.[0]?.venue?.full_address ? (
          <Typography color="textSecondary">No info.</Typography>
        ) : (
          <Typography>
            {user?.work?.offices?.[0]?.venue?.full_address}
          </Typography>
        )}
      </Grid>
    </>
  );
};
