export const TASKS_TIME_STATISTICS = 'TASKS_TIME_STATISTICS';
export const TASKS_STATUS_STATISTICS = 'TASKS_STATUS_STATISTICS';
export const TASKS_TYPE_STATISTICS = 'TASKS_TYPE_STATISTICS';
export const TASKS_PRIORITY_STATISTICS = 'TASKS_PRIORITY_STATISTICS';
export const ANALYTICS_DATA = 'ANALYTICS_DATA';
export const USER_REGISTRATION_STATISTICS = 'USER_REGISTRATION_STATISTICS';
export const TIME_TASK = 'TIME_TASK';
export const TIME_CASE = 'TIME_CASE';
export const CASE_STATISTICS = 'CASE_STATISTICS';
export const CASES_USERS_STATISTICS = 'CASES_USERS_STATISTICS';
export const CASES_STATUS_STATISTICS = 'CASES_STATUS_STATISTICS';
export const FILES = 'FILES';
export const INVOICES = 'INVOICES';
export const EXPENSES_ANALYSIS = 'EXPENSES_ANALYSIS';
export const UPCOMING_PAYMENTS = 'UPCOMING_PAYMENTS';
export const PAYMENT_HISTORY = 'PAYMENT_HISTORY';
