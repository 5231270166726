import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  login_queue_id: Yup.mixed().nullable().required(),
  name: Yup.string().nullable().required(),
  description: Yup.string().nullable().required(),
  users: Yup.array().nullable(),
  office_id: Yup.mixed().nullable(),
  login: Yup.string().nullable(),
  password: Yup.string().nullable(),
  login_url: Yup.string().nullable()
});
