import moment from 'moment';
import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme, Typography, Link as MuiLink } from '@material-ui/core';
import { InsuranceClaimsTooltipCounter } from '../../../../../../../../components/claims/InsuranceClaimsTooltipCounter';
import { Label } from '../../../../../../../../components/Label';
import { getUserFullName } from '../../../../../../../../helpers/users';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ caseItem }) => {
  const classes = useStyles();
  const theme = useTheme();
  const types = caseItem.case_type && caseItem.case_type.map(({ name }) => name).join(', ');
  const attorney = caseItem.case_users.find(({ case_role }) => case_role === 'attorney');

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        <div className={classes.fieldsGroup}>
          <li className={classes.field}>
            <Typography className={classes.field__label}>
              Case open
            </Typography>

            <Typography className={classes.field__value}>
              {moment.unix(caseItem.created_at).format('L')}
            </Typography>
          </li>

          <li className={cn(classes.field, classes.field_center)}>
            <Typography className={cn(classes.field__label, classes.field__label_flex)}>
              Case status
            </Typography>

            {caseItem.case_status ?
              <Label label={caseItem.case_status.name} color={theme.palette.primary.main} />
              :
              <Typography className={classes.field__value}>-</Typography>
            }
          </li>
        </div>

        <li className={classes.field}>
          <Typography className={classes.field__label}>
            Case type
          </Typography>

          <Typography className={cn(classes.field__value, classes.field__value_marked)}>
            {types}
          </Typography>
        </li>
      </ul>


      <ul className={classes.list}>
        <li className={classes.field}>
          <Typography className={cn(classes.field__label, classes.field__label_secondary)}>
            Office
          </Typography>

          <Typography className={classes.field__value}>
            {caseItem.office?.full_address || '-'}
          </Typography>
        </li>

        <li className={classes.field}>
          <Typography className={cn(classes.field__label, classes.field__label_secondary)}>
            Attorney
          </Typography>

          {attorney && attorney.user ?
            <MuiLink component={RouterLink} to={`/members/users/${attorney.user.id}`} className={classes.field__value}>
              {getUserFullName(attorney.user)}
            </MuiLink>
            :
            <Typography className={classes.field__value}>
              -
            </Typography>
          }
        </li>

        <li className={classes.field}>
          <Typography className={cn(classes.field__label, classes.field__label_secondary)}>
            Claims #
          </Typography>

          <InsuranceClaimsTooltipCounter caseItem={caseItem} />
        </li>
      </ul>
    </div>
  );
};
