import logo from './logo.png';

export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },

  stepper: {
    paddingLeft: 0,
    paddingRight: 0
  },

  stepper__item: {
    cursor: 'pointer !important'
  },

  stepper__item_disable: {
    cursor: 'inherit'
  },

  backButton: {
    marginRight: spacing(1)
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: spacing(1),
    marginBottom: spacing(1)
  },

  actionButton: {
    flexGrow: 0,
    marginLeft: 'auto'
  },

  logoWrapper: {
    paddingTop: spacing(2),
    paddingLeft: spacing(0),
    paddingRight: spacing(5),
    paddingBottom: spacing(1),

    [breakpoints.up('md')]: {
      paddingLeft: spacing(3)
    }
  },

  logo: {
    height: 70,
    background: `url(${logo}) center no-repeat`
  }
});
