import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_TASK_TEMPLATES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_TASK_TEMPLATES_SUCCESS]: (
    { filter, templates, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      templates: (page > 1
        ? templates.filter(({ id }) => {
          return !data.find((template) => id === template.id);
        }).concat(data)
        : data
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_TASK_TEMPLATES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_TASK_TEMPLATE]: (state, updatedTemplate) => {
    return {
      ...state,

      templates: state.templates.map((template) => {
        return template.id === updatedTemplate.id ? { ...template, ...updatedTemplate } : template;
      })
    };
  },

  [types.ADD_TASK_TEMPLATE]: (state, template) => {
    const { pagination, filter, templates } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((templates.length + 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      templates: [ template, ...templates ]
    };
  },

  [types.DELETE_TASK_TEMPLATE]: (state, templateID) => {
    const { pagination, filter, templates, selectedTemplatesIDs } = state;
    const filteredTemplates = templates.filter(({ id }) => templateID !== id);

    if (filteredTemplates.length === templates.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((templates.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      templates: filteredTemplates,
      selectedTemplatesIDs: selectedTemplatesIDs.filter((id) => templateID !== id)
    };
  },

  [types.ADD_TASK_TEMPLATES_TO_SELECTED]: ({
    selectedTemplate,
    selectedTemplatesIDs,

    ...state
  }, templates) => {
    return {
      ...state,

      selectedTemplate: selectedTemplate.concat(
        templates.filter(({ id }) => !selectedTemplatesIDs.includes(id))
      ),
      selectedTemplatesIDs: [ ...new Set(selectedTemplatesIDs.concat(templates.map(({ id }) => id))) ]
    };
  },

  [types.DELETE_TASK_TEMPLATES_FROM_SELECTED]: ({ selectedTemplatesIDs, ...state }, templatesIDs) => {
    return {
      ...state,

      selectedTemplatesIDs: selectedTemplatesIDs.filter((id) => templatesIDs.indexOf(id) === -1)
    };
  }
});
