import { createContext, useReducer, useEffect, useRef, useContext } from 'react';
import { merge, omit, isEqual } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import { api } from '../../../../../api';
import * as remindersApi from '../../../../../api/cases/reminders/case-reminders';
import { CaseRemindersFilterContext } from '../CaseRemindersFiltersProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CaseRemindersContext = createContext(null);

export const CaseRemindersProvider = ({ children, caseId, filter: filterProp }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    reminders
  } = state;
  const prevFilter = usePrevious(filter);
  const { filter: commonFilter = {} } = useContext(CaseRemindersFilterContext) || {};
  const cancelFetch = useRef(() => {});

  const fetchReminders = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_REMINDERS_REQUEST });

    remindersApi.fetchCaseReminders({
      params: {
        ...filter,
        ...newFilter,

        case_id: caseId || filter.case_id
      },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_REMINDERS_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchReminders({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetReminders = (newFilter) => {
    dispatch({ type: types.RESET_REMINDERS, payload: newFilter });
    fetchReminders({ page: 1, ...newFilter });
  };

  const addReminders = (reminders) => {
    dispatch({ type: types.ADD_REMINDERS, payload: reminders });
  };

  const updateReminder = (reminder) => {
    dispatch({ type: types.UPDATE_REMINDER, payload: reminder });
  };

  const deleteReminder = ({ id }) => {
    dispatch({ type: types.DELETE_REMINDER, payload: id });
  };

  const providerValue = {
    isFetched,
    isFetching,
    caseId,
    filter,
    meta: {
      ...filter,
      ...pagination
    },
    reminders,

    // functions
    addReminders,
    updateReminder,
    deleteReminder,
    applyFilter,
    resetReminders,
    loadNextPage
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetReminders(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    const newFilter = { ...filter, ...commonFilter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(commonFilter);
    }
  }, [ filter, commonFilter ]);

  return (
    <CaseRemindersContext.Provider value={providerValue}>
      {children}
    </CaseRemindersContext.Provider>
  );
};
