import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_FORMS_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_FORMS_SUCCESS]: (
    { filter, forms, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      forms: data
    };
  },

  [types.FETCH_FORMS_ERROR]: (state) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      forms: []
    };
  },

  [types.RESET_FORMS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.TOGGLE_ALL_FORMS_SELECTION]: ({ forms, selectedIDs, ...state }) => {
    const allFormsIsSelected = forms.length === selectedIDs.length;

    return {
      ...state,

      forms,
      selectedIDs: allFormsIsSelected ? [] : forms.map((item) => item.id)
    };
  },

  [types.TOGGLE_FORM_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1 ?
        selectedIDs.filter((item) => item !== id)
        :
        selectedIDs.concat(id)
    };
  },

  [types.MASS_DELETE_FORMS]: ({ forms, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      forms: forms.filter((form) => IDs.indexOf(form.id) < 0)
    };
  }
});
