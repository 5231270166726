import { createContext, useRef, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../../api';
import * as visitReasonsApi from '../../../../../../api/codes/appointments/visit-reasons';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const VisitReasonsContext = createContext();

export const VisitReasonsContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { isFetched, isFetching, pagination, filter, visitReasons } = state;
  const cancelFetch = useRef(() => {});

  const fetchVisitReasons = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_VISIT_REASONS_REQUEST });

    visitReasonsApi.fetchVisitReasons({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_VISIT_REASONS_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchVisitReasons({ page: filter.page + 1 });
    }
  };

  const resetVisitReasons = (newFilter) => {
    dispatch({ type: types.RESET_VISIT_REASONS, payload: newFilter });

    fetchVisitReasons({ page: 1, ...newFilter });
  };

  const toggleActiveVisitReason = (visitReason) => {
    return visitReasonsApi.updateVisitReason(visitReason).then((visitReason) => {
      dispatch({ type: types.UPDATE_VISIT_REASON, payload: visitReason });
    });
  };

  const deleteVisitReason = (visitReason) => {
    return new Promise((resolve) => {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          visitReasonsApi.deleteVisitReason(visitReason.id).then(() => {
            dispatch({ type: types.DELETE_VISIT_REASON, payload: visitReason.id });
            enqueueSnackbar(`Visit reason "${visitReason.code}" successfully deleted`, { variant: 'success' });
            resolve();
          });
        },

        onModalRejected: resolve
      });
    });
  };

  const providerValue = {
    isFetched,
    isFetching,
    visitReasons,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    resetVisitReasons,
    fetchVisitReasons,
    loadNextPage,
    toggleActiveVisitReason,
    deleteVisitReason
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <VisitReasonsContext.Provider value={providerValue}>
      {children}
    </VisitReasonsContext.Provider>
  );
};
