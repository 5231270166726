import { useContext } from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import {
  ChatThreadContext,
  MessageContext,
  ThreadMessagesContext
} from '../../../../../../components/chat';
import { Loader } from '../../../../../../components/Loader';
import { SystemMessageItem } from '../SystemMessageItem';
import { MessageSelection } from './MessageSelection';
import { MessageAvatar } from './MessageAvatar';
import { MessageBody } from './MessageBody';
import { ChatDivider } from './ChatDivider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MessageItem = ({ disableMenu, onMenuToggled }) => {
  const classes = useStyles();
  const {
    dense,
    preview,
    message,
    isCurrentUser,
    isAvatarShown
  } = useContext(MessageContext);
  const {
    selectedMessages = [],
    toggleMessageSelect,
    currentAction
  } = useContext(ChatThreadContext) || {};
  const { isFetched = true } = useContext(ThreadMessagesContext) || {};
  const isSelected = selectedMessages?.includes(message);

  const handleSelectionClick = () => {
    toggleMessageSelect(message);
  };

  return message.type === 'system' ? <SystemMessageItem message={message} /> : (
    <Loader loading={!isFetched} className={classes.loader}>
      <>
        {!preview && <ChatDivider />}

        <div className={classes.root}>
          {!preview &&
            <MessageSelection
              isSelected={isSelected}
              dense={dense}
              currentAction={currentAction}
              handleSelectionClick={handleSelectionClick}
            />
          }

          <div
            className={cn(classes.message, {
              [classes.myMessage]: isCurrentUser,
              [classes.messageNoAvatar]: !!message.user && !isAvatarShown
            })}
          >
            <MessageAvatar />
            <MessageBody disableMenu={disableMenu} onMenuToggled={onMenuToggled} />
          </div>
        </div>
      </>
    </Loader>
  );
};
