import { MenuItem, ListItemText, Box, Typography } from '@material-ui/core';
import { Tooltip } from '../../../Tooltip';

export const Option = ({ children, data: { data }, ...props }) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"

      {...props.innerProps}
    >
      <Tooltip
        isExistTooltip
        title={
          <Box color="common.white" py={0.5} minWidth={150}>
            {!!data?.children?.length ? (
              <>
                <Typography gutterBottom variant="h4" color="inherit">Components:</Typography>

                {data?.children?.map((item) => (
                  <Box key={item.id} mb={0.5}>
                    <Typography noWrap color="inherit">
                      <b>{item?.name}</b>
                      {item.standard_range && ` - ${item.standard_range}`}
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              <Typography>No components found</Typography>
            )}
          </Box>
        }
      >
        <ListItemText
          primary={children}
          secondary={data?.description}
          primaryTypographyProps={{ noWrap: true, component: 'div' }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </Tooltip>
    </MenuItem>
  );
};
