export const styles = ({ spacing, palette: { grey, ...palette }, breakpoints }) => ({
  title: {
    display: 'inline-flex',
    alignItems: 'center',
    color: palette.pink
  },

  avatar: {
    display: 'inline-flex',
    marginRight: spacing(0.5)
  },

  main: {
    padding: spacing(1, 5, 4, 5),
    borderBottom: `1px solid ${palette.divider}`,

    [breakpoints.down('md')]: {
      padding: spacing()
    }
  }
});
