export const filterFieldsMap = {
  companies: 'companies',
  users: 'users',
  startedFrom: 'started_from',
  startedTo: 'started_to',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.companies]: 'Companies',
  [filterFieldsMap.users]: 'Users',
  [filterFieldsMap.startedFrom]: 'From',
  [filterFieldsMap.startedTo]: 'To',
  [filterFieldsMap.orderBy]: 'Order By'
};
