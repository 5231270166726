import { SvgIcon } from '@material-ui/core';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import { Icon } from '@mdi/react';
import {
  mdiCogs,
  mdiHeartPulse,
  mdiCircleMedium,
  // TODO: for future settings
  // mdiCashMultiple,
  mdiFileAccountOutline,
  mdiFileDocumentOutline,
  mdiFileMultipleOutline,
  mdiAccountGroupOutline,
  mdiFileDocumentEditOutline,
  mdiCellphoneMessage
} from '@mdi/js';

export const patientsMenuList = [
  {
    name: 'Patients',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiAccountGroupOutline} /></SvgIcon>,
    path: '/patients'
  }
];

export const patientMenuList = [
  {
    name: 'Medical forms',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileAccountOutline}/></SvgIcon>,
    path: '/patients/:patientID/medical-info/medical-forms'
  },
  {
    name: 'Medical report',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileDocumentOutline}/></SvgIcon>,
    path: '/patients/:patientID/medical-info/medical-report'
  },
  {
    exact: true,
    name: 'Medical info',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiHeartPulse}/></SvgIcon>,
    path: '/patients/:patientID/medical-info'
  },
  {
    name: 'Appointments',
    icon: InsertInvitationOutlinedIcon,
    path: '/patients/:patientID/medical-info/appointments'
  },
  {
    name: 'Call Reports',
    icon: PhoneOutlinedIcon,
    path: '/patients/:patientID/medical-info/call-reports'
  },
  {
    name: 'Authorizations',
    icon: LocalHospitalOutlinedIcon,
    path: '/patients/:patientID/medical-info/authorizations'
  },
  {
    name: 'Files',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileMultipleOutline}/></SvgIcon>,
    path: '/patients/:patientID/medical-info/files'
  },
  {
    name: 'Emails',
    icon: EmailIcon,
    path: '/patients/:patientID/medical-info/emails'
  },
  {
    name: 'Claims',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiFileDocumentEditOutline}/></SvgIcon>,
    path: '/patients/:patientID/medical-info/claims'
  },
  // TODO: for future new settings
  // {
  //   name: 'Claim Payments',
  //   icon: (props) => <SvgIcon {...props}><Icon path={mdiCashMultiple}/></SvgIcon>,
  //   path: '/medical-info/claim-payments'
  // },
  {
    name: 'Members',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiAccountGroupOutline}/></SvgIcon>,
    path: '/patients/:patientID/medical-info/members'
  },
  {
    name: 'Comments',
    icon: CommentOutlinedIcon,
    path: '/patients/:patientID/medical-info/comments'
  },
  {
    name: 'Notifications',
    icon: (props) => <SvgIcon {...props}><Icon path={mdiCellphoneMessage} /></SvgIcon>,
    path: '/patients/:patientID/medical-info/notifications'
  },
  {
    icon: (props) => <SvgIcon {...props}><Icon path={mdiCogs} /></SvgIcon>,
    name: 'Settings',
    path: '/patients/:patientID/settings',
    routes: [
      {
        icon: (props) => <SvgIcon {...props}><Icon path={mdiCircleMedium} /></SvgIcon>,
        name: 'Templ. Med Reports',
        path: '/patients/:patientID/settings/templates'
      },
      {
        icon: (props) => <SvgIcon {...props}><Icon path={mdiCircleMedium} /></SvgIcon>,
        name: 'Templ. Prescriptions',
        path: '/patients/:patientID/settings/prescriptions-templates'
      }
    ]
  }
];
