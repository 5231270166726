import { useContext } from 'react';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import {
  makeStyles,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@material-ui/core';
import VideoLibrary from '@material-ui/icons/VideoLibraryOutlined';
import HistoryIcon from '@material-ui/icons/History';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { VideosContext } from '../VideosContext';
import { styles } from './styles';

const enhancer = compose(withRouter);

const useStyles = makeStyles(styles);

export const LeftSidebar = enhancer(({ location }) => {
  const classes = useStyles();

  const { uploadVideo } = useContext(VideosContext);

  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          className={classes.uploadButton}
          onClick={uploadVideo}
        >
          <CloudUploadIcon className={classes.uploadButton__icon}/>
          Upload video
        </Button>

        <List disablePadding component="nav">
          <ListItem
            button
            selected={isActiveRoute('/files/recent')}
            component={Link}
            to={'/files/recent'}
          >
            <ListItemIcon className={classes.navItemIcon}>
              <HistoryIcon/>
            </ListItemIcon>

            <ListItemText primary="Recent"/>
          </ListItem>

          <Divider/>

          <ListItem
            button
            selected={isActiveRoute('/videos')}
            component={Link}
            to={'/videos'}
          >
            <ListItemIcon className={classes.navItemIcon}>
              <VideoLibrary/>
            </ListItemIcon>

            <ListItemText primary="All video"/>
          </ListItem>
        </List>
      </div>
    </div>
  );
});
