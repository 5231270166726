import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, SvgIcon, Fab, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import { apiModelsMap } from '../../../../dataMaps/apiModels';
import { setFilesLastGlobalAction } from '../../../../store/globalActions';
import * as documentsApi from '../../../../api/files/html-documents';
import { Yup } from '../../../../utils/validation';
import TemplateSvg from '../../../../components/icons/template.svg';
import AddFileSvg from '../../../../components/icons/file-add.svg';
import { Loader } from '../../../../components/Loader';
import { ModalContainer, ModalHeader, ModalBody } from '../../../../components/Modal';
import { useModal } from '../../../../components/ModalsProvider';
import { TextField } from '../../../../components/FormField';
import { VoiceRecognitionButton } from '../VoiceRecognitionButton';
import { addReplaceableDataTypePlugin } from '../DocumentEditor/replaceableDataTypePlugin';
import * as filesActionTypes from '../FilesContext/types';
import { SelectTemplateModal } from '../SelectTemplateModal';
import { DocumentEditor } from '../DocumentEditor';
import { getPrintStyles } from '../DocumentEditor/getPrintStyles';
import { DocumentSettingsModal } from './DocumentSettingsModal';
import { InfoBar } from './InfoBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CreateDocumentModal = ({
  DialogProps,
  payload: {
    initialHTML,
    ...payload
  },
  handleModalResolve,
  handleModalReject
}) => {
  const [ documentSettings, setDocumentSettings ] = useState(null);
  const [ editorCss, setEditorCss ] = useState('');
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const availablePaperSizes = useSelector(({ apiConfig }) => apiConfig.config.html_documents.page_sizes);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles();
  const editorRef = useRef();

  const applyTemplate = () => {
    openModal(SelectTemplateModal, {
      onModalResolved: ({ isReplaceable, template }) => {
        editorRef.current[isReplaceable ? 'setData' : 'insertHtml'](template.html);
      }
    });
  };

  const handleEditorInit = (CKEDITOR) => {
    addReplaceableDataTypePlugin({ CKEDITOR });
  };

  const addDocument = ({ name }, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const html = editorRef.current.getData();

    if (!html) {
      setSubmitting(false);

      return enqueueSnackbar('The document cannot be empty', {
        variant: 'error'
      });
    }

    if (documentSettings.model === apiModelsMap.user) {
      delete documentSettings.model;
      delete documentSettings.model_id;
    }

    return documentsApi.createDocument({
      ...documentSettings,
      name,
      html,
      css: editorCss + getPrintStyles()
    }).then((data) => {
      enqueueSnackbar('Document successfully created');
      dispatch(setFilesLastGlobalAction({ type: filesActionTypes.ADD_FILES, payload: [ data.file ] }));
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  useEffect(() => {
    openModal(DocumentSettingsModal, {
      payload,
      onModalResolved: setDocumentSettings,
      onModalRejected: handleModalReject
    });
  }, []);

  return (
    <Dialog
      fullScreen={isTablet}
      disableEnforceFocus
      maxWidth="lg"
      PaperProps={{
        style: {
          height: '100%'
        }
      }}
      {...DialogProps}
    >
      <ModalContainer>
        <ModalHeader icon={<SvgIcon><AddFileSvg /></SvgIcon>} onClose={handleModalReject}>
          Create document
        </ModalHeader>

        <ModalBody className={classes.body}>
          <Loader
            loading={!documentSettings}
            className={classes.loader}
            render={() => (
              <Formik
                initialValues={{ name: '' }}
                validationSchema={Yup.object().shape({ name: Yup.string().required().min(2).max(255) })}
                onSubmit={addDocument}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <form noValidate className={classes.form} onSubmit={handleSubmit}>
                    <InfoBar
                      isSubmitting={isSubmitting}
                      documentSettings={documentSettings}
                    />

                    <div className={classes.top}>
                      <TextField
                        required
                        name="name"
                        label="Document name"
                        placeholder="Enter file name..."
                        margin="dense"
                        className={classes.title}
                      />

                      <VoiceRecognitionButton editorRef={editorRef} />

                      <Fab
                        color="secondary"
                        title="Use template"
                        onClick={applyTemplate}
                      >
                        <SvgIcon><TemplateSvg /></SvgIcon>
                      </Fab>
                    </div>

                    <div className={classes.bottom}>
                      <DocumentEditor
                        ref={editorRef}
                        initData={initialHTML}
                        paperSize={availablePaperSizes[documentSettings.page_size]}
                        paperMargins={documentSettings.page_fields}
                        onBeforeLoad={handleEditorInit}
                        onStylesReady={setEditorCss}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            )}
          />
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
