import { useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import { ImageDropZone } from '../../../../../../components/ImageDropZone';

export const ImageForm = () => {
  const { values } = useFormikContext();

  return (
    <>
      <Grid item xs={12}>
        <Box clone pt={3}>
          <Typography variant="h3">Scan insurance card</Typography>
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <ImageDropZone
          label="Front"
          name="scan_front"
          preview={values.scan_front}
        />

        <ImageDropZone
          label="Back"
          name="scan_back"
          preview={values.scan_back}
        />
      </Grid>
    </>
  );
};
