export const styles = ({ palette }) => ({
  checkbox: {
    alignSelf: 'center',
    color: palette.grey[400]
  },

  checkbox_header: {
    color: palette.grey[500]
  }
});
