import { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { useModal as useModalHook } from '../../../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../../../components/users/UserPreviewModal';
import { CardHeader, CardItem } from '../../../../../../../../components/Cards';
import { UserLink } from '../../../../../../../../components/UserLink';
import { ExpensesContext } from '../../../../Expenses/ExpensesContext';
import { ExpensesPreviewModal } from '../../../../ExpensesPreviewModal';
import { ExpenseMenu } from '../../Expenses/ExpenseMenu';
import { Body } from './Body';
import { Footer } from './Footer';

export const Card = ({ expense, onExpenseSelect }) => {
  const { openModal } = useModalHook();
  const { selectedExpensesIDs, ...expensesContext } = useContext(ExpensesContext);
  const isSelected = selectedExpensesIDs.indexOf(expense.id) !== -1;

  const handleExpenseSelect = () => {
    onExpenseSelect(expense);
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  const openExpensePreviewModal = () => {
    if (onExpenseSelect) {
      return handleExpenseSelect();
    }

    openModal(ExpensesPreviewModal, {
      payload: { id: expense.id }
    });
  };

  const handleItemSelection = () => {
    if (onExpenseSelect) {
      return;
    }

    expensesContext.toggleItemSelection(expense);
  };

  return (
    <CardItem selected={isSelected} py={1} px={2} onClick={stopPropagation(openExpensePreviewModal)}>
      <CardHeader
        disableCheckBox={!!onExpenseSelect}
        isSelected={isSelected}
        pb={1}
        onItemSelect={stopPropagation(handleItemSelection)}
        menuComponent={ExpenseMenu}
        menuComponentProps={{
          expense,
          invoiceId: expense?.invoice?.id || null
        }}
      >
        {expense?.invoice?.number ?
          <Typography
            noWrap
            title={expense?.invoice?.number}
          >
            Invoice no. {expense?.invoice?.number}
          </Typography>
          :
          <UserLink
            size="md"
            variant="h5"
            user={expense?.user}
            onClick={stopPropagation(openUserPreview(expense?.user?.id))}
          />
        }
      </CardHeader>

      <Body expense={expense} />
      <Footer expense={expense} />
    </CardItem>
  );
};
