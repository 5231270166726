export const styles = ({
  spacing,
  palette: { grey },
  typography: { pxToRem }
}) => ({
  taskBodyButton: {
    fontSize: pxToRem(12),
    textTransform: 'capitalize',
    paddingLeft: 0,
    marginLeft: spacing(-0.5)
  },

  taskBodyIcon: {
    fontSize: pxToRem(14),
    marginTop: spacing(-0.2)
  },

  iconCalendar: {
    fontSize: pxToRem(18),
    marginRight: spacing(0.5),
    color: grey[500]
  },

  badges: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(0.5)
  }
});

export const getStyle = ({ draggableStyle, virtualStyle, isDragging }) => {
  const combined = {
    ...virtualStyle,
    ...draggableStyle
  };

  const grid = 8;

  return {
    ...combined,
    height: isDragging ? combined.height : combined.height - grid,
    left: isDragging ? combined.left : combined.left + grid,
    width: isDragging
      ? draggableStyle.width
      : `calc(${combined.width} - ${grid * 2}px)`,
    marginBottom: grid
  };
};
