import { useContext, useEffect } from 'react';
import filesize from 'filesize';
import { useModal } from 'react-modal-hook';
import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Date } from '../../../../../../../../components/Date';
import { IconButton } from '../../../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { Loader } from '../../../../../../../../components/Loader';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { FilePreviewIcon, getFileMenuOptions } from '../../../../../../files-common';
import { FilePreviewModal } from '../../../../../../files-common';
import { FilesContext } from '../../../../../FilesContext';
import { columnsWidths, minRowHeight } from '../List';

export const Row = ({ item: file = {}, isLoaded, recalculateHeight }) => {
  const filesContext = useContext(FilesContext);

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ recalculateHeight, isLoaded ]);

  const deleteFile = () => {
    filesContext.deleteFiles([ file.id ]);
  };

  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      getFileMenuOptions={getFileMenuOptions(filesContext)}
      previewMenuOptions={getFileMenuOptions(filesContext)}
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        files: filesContext.files,
        isFetched: filesContext.isFetched,
        isFetching: filesContext.isFetching,
        filter: filesContext.filter,
        onNeedNextPage: filesContext.loadNextPage
      }}
    />
  ), [ file, filesContext ]);

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow minHeight={minRowHeight}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Box component="span" mr={2}>
          <FilePreviewIcon file={file} onClick={openFilesPreview} />
        </Box>

        <Typography component="div" noWrap>
          {file.original_filename}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.size}>
        {filesize(file.size)}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        <Date disableIcon date={file.created_at} format="L LT" />
      </ListRowCell>

      {!hasRole(rolesMap.advocate) &&
        <ListRowCell flexBasis={columnsWidths.action}>
          <IconButton color="error" onClick={deleteFile}>
            <DeleteIcon />
          </IconButton>
        </ListRowCell>
      }
    </ListRow>
  );
};
