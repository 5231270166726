import { Box, Grid, Typography } from '@material-ui/core';
import { Contact } from '../../../../../../../../components/Contact';

export const ContactInfo = ({ company }) => {
  return (
    <Grid container spacing={1} component={Box} py={2}>
      <Grid item xs={5}>
        <Typography noWrap color="textSecondary">
          Phone
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <Contact
          noWrap
          needIcon
          variant="h5"
          type="tel"
          contact={company.phone}
        />
      </Grid>

      <Grid item xs={5}>
        <Typography noWrap color="textSecondary">
          Fax
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <Contact
          noWrap
          needIcon
          variant="h5"
          type="tel"
          contact={company.fax}
        />
      </Grid>

      <Grid item xs={5}>
        <Typography noWrap color="textSecondary">
          Mob number
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <Contact
          noWrap
          needIcon
          variant="h5"
          type="tel"
          contact={company.mobile_phone}
        />
      </Grid>

      <Grid item xs={5}>
        <Typography noWrap color="textSecondary">
          Timezone
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <Typography variant="h5">
          {company.timezone || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};
