export const filterFieldsMap = {
  name: 'name',
  companyId: 'company_id',
  users: 'users',
  address: 'address',
  email: 'email'
};

export const filterFieldsLabels = {
  [filterFieldsMap.name]: 'Office name',
  [filterFieldsMap.companyId]: 'Company name',
  [filterFieldsMap.users]: 'Users',
  [filterFieldsMap.address]: 'Address',
  [filterFieldsMap.email]: 'Company email'
};
