import { Fragment } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { AddressLink } from '../../../AddressLink';
import { Contact } from '../../../Contact';
import { OfficeLink } from '../../../OfficeLink';

export const OfficesContent = ({ disableOfficeVenue = false, user }) => {
  return (
    <>
      {!user?.work?.offices?.length ? (
        <>
          <Grid item xs={4}>
            <Typography noWrap color="textSecondary">
              Office
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography color="textSecondary">No info.</Typography>
          </Grid>
        </>
      ) : user?.work?.offices?.map((office) => (
        <Fragment key={office?.id}>
          <Grid item xs={4}>
            <Typography noWrap color="textSecondary">
              Office
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box key={office.id} display="flex" flexDirection="column" mb={1}>
              <OfficeLink variant="h5" office={office} />

              {!!office?.full_address &&
              <Box display="flex" alignItems="flex-start" color="text.secondary">
                <Box mr={0.5}>
                  <LocationOnIcon fontSize="small" color="inherit" />
                </Box>

                <AddressLink
                  color={disableOfficeVenue ? 'textPrimary' : 'primary'}
                  variant={disableOfficeVenue ? 'h5' : 'body2'}
                  address={office?.full_address}
                >
                  {office?.full_address}
                </AddressLink>
              </Box>
              }
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Typography noWrap color="textSecondary">
              Phone
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Contact
              type="tel"
              variant="h5"
              color="textPrimary"
              contact={office?.phone}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography color="textSecondary">
              Fax
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h5">
              {office?.fax || '-'}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography color="textSecondary">
              Email
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Contact
              noWrap
              variant="h5"
              type="mailto"
              color="textPrimary"
              contact={user?.email}
            />
          </Grid>
        </Fragment>
      ))}

      <Grid item xs={4}>
        <Typography color="textSecondary">
          Ocupation
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography variant="h5">
          {user?.work?.position || '-'}
        </Typography>
      </Grid>

      {!disableOfficeVenue && (
        <>
          <Grid item xs={4}>
            <Typography noWrap color="textSecondary">
              Main Office Venue
            </Typography>
          </Grid>

          <Grid item xs={8}>
            {!user?.work?.offices?.[0]?.venue?.full_address ? (
              <Typography color="textSecondary">No info.</Typography>
            ) : (
              <Typography>
                {user?.work?.offices?.[0]?.venue?.full_address}
              </Typography>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
