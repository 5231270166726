import { useFormikContext } from 'formik';
import * as templatesApi from '../../../../../../../api/profile';
import { TemplatesSelect } from '../../../../../../../components/FormField';
import { agesOptions } from '../../../../Settings/Templates/CreateTemplateReport';

export const Body = ({ age, report_type, onFetchingUpdate }) => {
  const { setFieldValue } = useFormikContext();

  const handleChangeTemplate = (option) => {
    if (option?.data?.id) {
      onFetchingUpdate(true);

      templatesApi.fetchTemplate(option?.data?.id).then((data) => {
        setFieldValue('template', data);
        onFetchingUpdate(false);
      });
    } else {
      setFieldValue('template', null);
    }
  };

  return (
    <TemplatesSelect
      params={{
        report_type,
        age: !!age
          ? (age <= 30 ? agesOptions[0].value : age <= 60 ? agesOptions[1].value : agesOptions[2].value)
          : null
      }}
      onChange={handleChangeTemplate}
    />
  );
};
