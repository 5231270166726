import StarIcon from '@material-ui/icons/Star';
import { Badge } from '../../Badge';
import { SvgIcon } from '../../SvgIcon';

export const FavoriteCaseIndicator = ({ children, fontSize, ...props }) => {
  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      badgeContent={
        <SvgIcon color="warning" fontSize={fontSize}>
          <StarIcon />
        </SvgIcon>
      }

      {...props}
    >
      {children}
    </Badge>
  );
};
