import { useContext } from 'react';
import cn from 'classnames';
import {
  makeStyles,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowIcon from '@material-ui/icons/TrendingFlat';
import ResetIcon from '@material-ui/icons/Autorenew';
import { stopPropagation } from '../../../../../../../../../helpers/dom';
import { IconButton } from '../../../../../../../../../components/IconButton';
import { VerticalDivider } from '../../../../../../../../../components/VerticalDivider';
import { bodyPartsMap } from '../../../Body/bodyPartsMap';
import { BodyPartsContext } from '../../../BodyPartsContext';
import { CountLabel } from '../../../../../CountLabel';
import { painLevelColors } from '../../../painLevelColors';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ part }) => {
  const maxPainLevel = 10;
  const {
    painfulParts,
    selectedPart,
    deletePainfulPart,
    setSelectedPart
  } = useContext(BodyPartsContext);
  const classes = useStyles(painfulParts[part]);

  const handleSelectedBodyPartChange = () => {
    setSelectedPart(part);
  };

  const deleteBodyPart = () => {
    deletePainfulPart(part);
  };

  return (
    <ListItem
      button
      selected={selectedPart === part}
      className={cn(classes.root, { [classes.rootError]: painfulParts?.[part]?.level === 0 })}
      onClick={handleSelectedBodyPartChange}
    >
      <ListItemIcon>
        <IconButton
          title="Delete selected part"
          color="error"
          onClick={stopPropagation(deleteBodyPart)}
        >
          <RemoveIcon />
        </IconButton>
      </ListItemIcon>

      <ListItemText
        primary={
          <div className={classes.row}>
            <Typography noWrap className={classes.title}>{bodyPartsMap[part]}</Typography>

            <CountLabel
              color={painLevelColors[painfulParts[part].level]}
              count={painfulParts[part].level + '/' + maxPainLevel}
            />

            <VerticalDivider horizontalGutters={1} />

            {typeof painfulParts[part].direction === 'number' ?
              painfulParts[part].direction !== 360 ?
                <ArrowIcon style={{ transform: `rotate(${painfulParts[part].direction}deg)` }} />
                :
                <ResetIcon/>
              :
              <Icon />
            }
          </div>
        }
      />
    </ListItem>
  );
};
