import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Box,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Checkbox, TextField } from '../../../../../../components/FormField';

export const ReviewSystems = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!values?.forms?.review_of_symptoms?.history_of) {
      setFieldValue('forms.review_of_symptoms.note', null);
    }
  }, [ values?.forms?.review_of_symptoms?.history_of ]);

  return (
    <Grid container spacing={0} component={Box} px={2}>
      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="No Interval Change"
          control={
            <Checkbox
              color="primary"
              name="forms.review_of_symptoms.no_interval_change"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Negative"
          control={
            <Checkbox
              color="primary"
              name="forms.review_of_symptoms.negative"
            />
          }
        />
      </Grid>

      <Grid item xs={4} />

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="History of:"
          control={
            <Checkbox
              color="primary"
              name="forms.review_of_symptoms.history_of"
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Box mt={2} mb={3}>
          <TextField
            fullWidth
            disabled={!values?.forms?.review_of_symptoms?.history_of}
            name="forms.review_of_symptoms.note"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="subtitle2">
                    <Box component="span" mr={0.5}>
                      <NotesIcon fontSize="inherit" />
                    </Box>
                    Add Note
                  </Typography>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
