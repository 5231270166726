export const styles = ({ spacing,  palette, typography: { pxToRem } }) => ({
  root: {
    paddingLeft: spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch'
  },

  column: {
    minWidth: 220,
    paddingRight: spacing(2),
    marginBottom: spacing(2)
  },

  columnTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing()
  },

  title: {
    fontSize: pxToRem(14),
    marginLeft: spacing(0.5)
  },

  checkboxTitle: {
    color: palette.grey[500]
  }
});
