import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { AppointmentsFilterProvider, AppointmentsProvider } from '../../components/appointments';
import { useModal } from '../../components/ModalsProvider';
import { SelectOfficeModal } from './SelectOfficeModal';
import { Header } from './Header';
import { List } from './List';

const filter = {
  is_private: 0,
  appointment_from: moment().unix(),
  appointment_to: moment().endOf('day').unix()
};

export const AppointmentsScoreboardPage = () => {
  const fontSize = useSelector(({ appointmentsScoreboard }) => appointmentsScoreboard.fontSize);
  const { openModal } = useModal();
  const [ office, setOffice ] = useState();

  useEffect(() => {
    openModal(SelectOfficeModal, {
      onModalResolved: (office) => {
        setOffice(office);
      }
    });
  }, []);

  return !!office && (
    <AppointmentsFilterProvider>
      <AppointmentsProvider filter={{ ...filter, offices: [ office.id ] }}>
        <Box width="100%" height="100%" display="flex" flexDirection="column" pt={2} px={3}>
          <Header office={office} />

          <Box
            display="flex"
            flexGrow={1}
            overflow="hidden"
            mt={2}
            bgcolor="white"
            style={{ fontSize: `${fontSize}%` }}
          >
            <List fontSize={fontSize} />
          </Box>
        </Box>
      </AppointmentsProvider>
    </AppointmentsFilterProvider>
  );
};
