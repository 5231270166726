import { useContext, useRef, useEffect } from 'react';
import {
  makeStyles,
  Typography,
  IconButton,
  SvgIcon,
  Link,
  Box
} from '@material-ui/core';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import { mdiAccountHeartOutline, mdiTagOutline } from '@mdi/js';
import AttachmentIcon from '@material-ui/icons/Attachment';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Icon } from '@mdi/react';
import { stopPropagation } from '../../../../../../helpers/dom';
import { hasRole } from '../../../../../../utils/hasRole';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../components/ListRow';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Popper } from '../../../../../../components/Popper';
import { Date } from '../../../../../../components/Date';
import { Tag } from '../../../../../../components/Tag';
import { CaseViewModal } from '../../../../CasesPage/Cases/CaseViewModal';
import { AccountsContext } from '../../../AccountsContext';
import { EmailPreviewModal } from '../../../EmailPreviewModal';
import { SavedEmailsContext } from '../../SavedEmailsContext';
import {
  minRowHeight,
  columnsWidths,
  widthBreakpointLarge,
  widthBreakpointMedium
} from '../EmailsList';
import { Menu } from './Menu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EmailsListItem = ({
  item: message,
  width,
  isLoaded,
  recalculateHeight
}) => {
  const { accounts } = useContext(AccountsContext);
  const { openModal } = useModal();
  const { filter, messageItem, selectedMessages, toggleItemSelection, fetchMessage } = useContext(SavedEmailsContext);
  const classes = useStyles();
  const itemRef = useRef();
  const isSelected = selectedMessages.indexOf(message.id) !== -1;
  const account = message.folder && accounts.find((account) => account.id === message.folder.config_id);
  const accountEmail = account ? account.username : '';
  const name = message.from?.map((item) => item?.name || item?.email).join(', ');

  const openCasePreview = () => {
    if (message.case.id) {
      openModal(CaseViewModal, {
        payload: { id: message.case.id }
      });
    }
  };

  const openEmailPreview = (message) => () => {
    fetchMessage(message);

    openModal(EmailPreviewModal, {
      payload: {
        disableActionBar: true,
        message,
        filter
      }
    });
  };

  const handleCheck = () => {
    toggleItemSelection(message.id);
  };

  useEffect(() => {
    if (isLoaded) {
      recalculateHeight();
    }
  }, [ isLoaded, messageItem ]);

  return (
    <ListRow
      button
      ref={itemRef}
      minHeight={minRowHeight}
      selected={isSelected || messageItem?.uid === message.uid}
      onClick={stopPropagation(openEmailPreview(message))}
    >
      {!hasRole(rolesMap.patient) &&
        <ListRowCheckbox size="small" checked={isSelected} onClick={stopPropagation(handleCheck)} />
      }

      <ListRowCell noWrap column grow flexBasis={columnsWidths.name}>
        <Box display="flex" alignItems="center">
          {message?.case?.id &&
            <Tooltip
              isExistTooltip
              title={width > widthBreakpointLarge
                ? 'Show case info'
                : (message?.case?.name || message?.case?.file_number)}
            >
              <Typography
                variant="subtitle2"
                color="primary"
                component={Link}
                onClick={stopPropagation(openCasePreview)}
              >
                <Box component="span" mr={width > widthBreakpointLarge ? 0.5 : 0}>
                  <WorkOutlineOutlinedIcon fontSize="small" />
                </Box>

                {width > widthBreakpointLarge && (message?.case?.name || message?.case?.file_number || '-')}
              </Typography>
            </Tooltip>
          }

          {message.case && message.patient &&
            <VerticalDivider verticalGutters={0.25} horizontalGutters={1} />
          }

          {message.patient &&
            <div className={classes.title}>
              {width > widthBreakpointLarge ?
                <>
                  <Box component="span" mr={0.5}>
                    <SvgIcon fontSize="small">
                      <Icon path={mdiAccountHeartOutline} />
                    </SvgIcon>
                  </Box>

                  <UserLink
                    noWrap
                    size="xs"
                    variant="subtitle2"
                    color="inherit"
                    user={message.patient}
                  />
                </>
                :
                <Tooltip
                  isExistTooltip
                  title={
                    <Box p={1}>
                      <UserLink
                        size="md"
                        variant="h5"
                        color="inherit"
                        user={message.patient}
                      />
                    </Box>
                  }
                >
                  <Box component="span">
                    <SvgIcon fontSize="small">
                      <Icon path={mdiAccountHeartOutline} />
                    </SvgIcon>
                  </Box>
                </Tooltip>
              }
            </div>
          }

          {width < widthBreakpointLarge && !!message?.tags?.length &&
            <>
              <VerticalDivider verticalGutters={0.25} horizontalGutters={1} />

              <Tooltip
                isExistTooltip={!!message?.tags?.length}
                title={
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    {message?.tags?.map((tag) => (
                      <Box key={tag.id} component="span" py={0.5} mr={1}>
                        <Tag cutTagLabel tag={tag}/>
                      </Box>
                    ))}
                  </Box>
                }
              >
                <Box display="flex" alignItems="center">
                  <SvgIcon fontSize="small" color="secondary">
                    <Icon path={mdiTagOutline} />
                  </SvgIcon>

                  <Typography variant="subtitle2" color="secondary">
                    {message?.tags?.length}
                  </Typography>
                </Box>
              </Tooltip>
            </>
          }
        </Box>

        <Typography noWrap component="div" color="textSecondary">
          <Box display="flex" alignItems="center" pt={0.5} pb={1} overflow="hidden" textOverflow="ellipsis">
            <Box mr={2}>
              <Typography variant="h5" color="textPrimary">{name}</Typography>
            </Box>

            {!!message.files.length &&
              <>
                <Box ml={2}>
                  <AttachmentIcon />
                </Box>
                <VerticalDivider verticalGutters={0.5} horizontalGutters={1} />
              </>
            }

            <Tooltip isExistTooltip={!!message.subject} title={`Subject: ${message.subject}`}>
              <Typography noWrap color="textSecondary">
                Subject: {message.subject}
              </Typography>
            </Tooltip>
          </Box>
        </Typography>

        {width > widthBreakpointLarge &&
          <Box display="flex" alignItems="center" flexWrap="wrap">
            {message.tags.map((tag) => (
              <Box key={tag.id} component="span" mb={1} mr={1}>
                <Tag cutTagLabel tag={tag}/>
              </Box>
            ))}
          </Box>
        }
      </ListRowCell>

      <ListRowCell
        column
        grow={width < widthBreakpointMedium}
        alignItems="flex-end !important"
        flexBasis={columnsWidths.info}
      >
        <Date disableIcon variant="subtitle2" color="textSecondary" date={message.date} />

        <Tooltip isExistTooltip={!!accountEmail} title={accountEmail}>
          <Typography noWrap variant="subtitle2">
            {message?.folder?.name}
            {accountEmail && ` - ${accountEmail}`}
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton
                buttonRef={anchorRef}
                color="inherit"
                size="small"
                onClick={stopPropagation(handleToggle)}
              >
                <MoreIcon />
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <Menu
              recalculateHeight={recalculateHeight}
              message={message}
              onClose={handleClose}
            />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
