import { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ChatThreadContext } from '../ChatThreadContext';

export const TypingIndicator = () => {
  const { typing, currentThread } = useContext(ChatThreadContext);
  const [ user, setUser ] = useState('');

  useEffect(() => {
    if (typing?.user && typing?.typing) {
      setUser(currentThread.users.find(({ id }) => id === typing.user)?.first_name);
    } else {
      setUser('');
    }
  }, [ typing ]);

  return !!user && (
    <Box height={20} px={3} display="flex" alignContent="center">
      <Typography variant="body1">
        {user} is typing...
      </Typography>
    </Box>
  );
};
