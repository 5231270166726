import { useSnackbar } from 'notistack';
import cn from 'classnames';
import {
  makeStyles,
  Button,
  Dialog,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../components/Modal';
import {
  BodyPartsContext,
  BodyPartsContextProvider
} from './BodyPartsContext';
import { TabbedBodyParts } from './TabbedBodyParts';
import { CheckboxesList } from './CheckboxesList';
import { RightSideBar } from './RightSideBar';
import { Body } from './Body';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const BodyPartsModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { where_is_pain }
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const addBodyParts = (painfulParts) => () => {
    const isExistLevel = Object.values(painfulParts).every(({ level }) => level);

    if (isExistLevel) {
      handleModalResolve(painfulParts);
    } else {
      enqueueSnackbar('Pain levels must be greater than 0', { variant: 'error' });
    }
  };

  return (
    <Dialog
      fullScreen={isTablet}
      PaperProps={!isTablet ? { style: { maxWidth: 1400, height: 840 } } : {}}
      {...DialogProps}
    >
      <BodyPartsContextProvider initialPainfulParts={where_is_pain}>
        <ModalContainer>
          <ModalHeader onClose={handleModalReject}>
            Where is your pain?
          </ModalHeader>

          <ModalBody disablePaddings disableScrollbars>
            {isMobile ? (
              <TabbedBodyParts />
            ) : (
              <div className={classes.root}>
                <div className={classes.bodyPartsList}>
                  <CheckboxesList />
                </div>

                <div className={classes.painActions}>
                  <RightSideBar />
                </div>

                <div className={cn(classes.bodyImage)}>
                  <Body />
                </div>
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={handleModalReject}>Cancel</Button>

            <BodyPartsContext.Consumer>
              {({ painfulParts }) => (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={addBodyParts(painfulParts)}
                >
                  Add
                </Button>
              )}
            </BodyPartsContext.Consumer>
          </ModalFooter>
        </ModalContainer>
      </BodyPartsContextProvider>
    </Dialog>
  );
};
