import { useFormikContext } from 'formik';
import { FormControlLabel, Grid } from '@material-ui/core';
import { CaseUsersSelect } from '../../../../../../components/cases/CaseUsersSelect';
import {
  Checkbox,
  CurrencyField,
  KeyboardDatePicker,
  TextField
} from '../../../../../../components/FormField';
import { rolesMap } from '../../../../../../dataMaps';

export const PlaintiffForm = () => {
  const { values } = useFormikContext();

  return (
    <>
      <Grid item md={3} sm={6} xs={12}>
        <CaseUsersSelect
          name="attorney_id"
          label="Adj. (BI)"
          margin="dense"
          params={{
            role: rolesMap.adjuster,
            office_id: values.office_id?.value || values.office_id,
            company_id: values.insurance_company_id?.id
          }}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <CaseUsersSelect
          name="attorney_adj_id"
          label="Adj. (PD)"
          margin="dense"
          params={{
            role: rolesMap.adjuster,
            office_id: values.office_id?.value || values.office_id,
            company_id: values.insurance_company_id?.id
          }}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <CaseUsersSelect
          name="provider_id"
          label="Adj. (UM)"
          margin="dense"
          params={{
            role: rolesMap.adjuster,
            office_id: values.office_id?.value || values.office_id,
            company_id: values.insurance_company_id?.id
          }}
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <CaseUsersSelect
          name="provider_adj_id"
          label="Adj. (MP)"
          margin="dense"
          params={{
            role: rolesMap.adjuster,
            office_id: values.office_id?.value || values.office_id,
            company_id: values.insurance_company_id?.id
          }}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="policy_number"
          label="Policy #"
          margin="dense"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="claim_number"
          label="Claim #"
          margin="dense"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="insured"
          label="Insured"
          margin="dense"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <FormControlLabel
          control={<Checkbox name="prop_two_one_three" />}
          label="Prop 213"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="vehicle.license_number"
          label="Veh Lic #"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <KeyboardDatePicker
          zeroMinWidth
          name="vehicle.year"
          label="Year/Make"
          views={[ 'year' ]}
          format="YYYY"
          mask="____"
          placeholder="YYYY"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="vehicle.model"
          label="Model"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="vehicle.color"
          label="Color"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="liability_limit"
          label="Liability/Limits"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="um_uim"
          label="UM/UIM"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="medpay"
          label="Medpay"
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <FormControlLabel
          control={<Checkbox name="medpay_requested" />}
          label="Medpay Requested"
        />
      </Grid>

      <Grid item md={3} sm={6} xs={12}>
        <KeyboardDatePicker
          name="medpay_received_date"
          label="Medpay Received"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="deductible"
          label="Deductible"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <CurrencyField
          name="rental"
          label="Rental"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          name="tow"
          label="Tow"
        />
      </Grid>
    </>
  );
};
