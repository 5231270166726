import { Date, Text, Bool, Office, Company } from '../index';
import { checklistFieldsMap, courtInfoFieldsMap, plaintiffFieldsMap } from './index';
import { WidgetActivity } from '../WidgetActivity';

export const fieldsMap = {
  employer_id: (before, after) => <Text before={before} after={after} fieldLabel="Employer" />,
  employer: (before, after) =>  <Company before={before} after={after} fieldLabel="Employer" />,
  claims_insurance_company_id: (before, after) => (
    <Text before={before} after={after} fieldLabel="Claims Insurance Company" />
  ),
  office: (before, after) => <Office before={before} after={after} />,
  status: (before, after) => (
    <Text before={before?.name || null} after={after?.name || null} fieldLabel="Status" />
  ),
  is_opened: (before, after) => <Bool before={before} after={after} fieldLabel="Is Opened" />,
  background_check: (before, after) => <Bool before={before} after={after} fieldLabel="Background Check" />,
  case_associated: (before, after) => <Bool before={before} after={after} fieldLabel="Case Associated" />,
  paperless_file: (before, after) => <Bool before={before} after={after} fieldLabel="Paperless File" />,
  edex: (before, after) => <Bool before={before} after={after} fieldLabel="edex" />,
  dirreff_attorney: (before, after) => <Bool before={before} after={after} fieldLabel="Dirreff Attorney" />,
  hourly: (before, after) => <Bool before={before} after={after} fieldLabel="Hourly" />,
  subbed_out: (before, after) => <Bool before={before} after={after} fieldLabel="Subbed Out" />,
  substituted_in_company_id: (before, after) => (
    <Text before={before} after={after} fieldLabel="Substituted in Company" />
  ),
  substituted_out_company_id: (before, after) => (
    <Text before={before} after={after} fieldLabel="Substituted out Company" />
  ),
  direct_attorney_company_id: (before, after) => (
    <Text before={before} after={after} fieldLabel="Direct Attorney Company" />
  ),
  name: (before, after) => <Text before={before} after={after} fieldLabel="Case Name" />,
  direct_ref_name: (before, after) => <Text before={before} after={after} fieldLabel="Direct Ref Name" />,
  occupation_group_number: (before, after) => (
    <Text before={before} after={after} fieldLabel="Occupation Group Number" />
  ),
  insurance_carrier: (before, after) => <Text before={before} after={after} fieldLabel="Insurance Carrier" />,
  claim_info_address: (before, after) => <Text before={before} after={after} fieldLabel="Claim Info Address" />,
  claim_info_phone: (before, after) => <Text before={before} after={after} fieldLabel="Claim Info Phone" />,
  company_deductible_amount: (before, after) => (
    <Text before={before} after={after} fieldLabel="Company Deductible Amount, $" />)
  ,
  claims_insurance_info: (before, after) => <Text before={before} after={after} fieldLabel="Claim Insurance Info" />,
  file_number: (before, after) => <Text before={before} after={after} fieldLabel="File number" />,
  ssn: (before, after) => <Text before={before} after={after} fieldLabel="SSN" />,
  description: (before, after) => <Text before={before} after={after} fieldLabel="Description" />,
  commenced_date: (before, after) => <Date before={before} after={after} fieldLabel="Commenced Date" />,
  doi: (before, after) => <Date before={before} after={after} fieldLabel="Date of Injury" />,
  dob: (before, after) =>  <Date before={before} after={after} fieldLabel="Date of Birthday" />,
  due_dated_at: (before, after) => <Date before={before} after={after} fieldLabel="Due Date" />,
  date_file_received: (before, after) => <Date before={before} after={after} fieldLabel="File Received Date" />,
  policy_period_start: (before, after) => (
    <Date before={before} after={after} fieldLabel="Start Date of the Policy Period" />
  ),
  policy_period_finish: (before, after) => (
    <Date before={before} after={after} fieldLabel="Finish Date of the Policy Period" />
  ),
  physical_file_scanned_at: (before, after) => (
    <Date before={before} after={after} fieldLabel="Physical File Scan Date" />
  ),
  subbed_out_at: (before, after) => <Date before={before} after={after} fieldLabel="Date of Subbed Out" />,
  date_of_injury: (before, after) => <Date before={before} after={after} fieldLabel="Date of Injury" />,
  claims_insurance_company: (before, after) => <Company before={before} after={after} fieldLabel="Claims Insurance" />,
  substituted_in_company: (before, after) => <Company before={before} after={after} fieldLabel="Substituted In" />,
  direct_attorney_company: (before, after) => <Company before={before} after={after} fieldLabel="Direct Attorney" />,
  substituted_out_company: (before, after) => <Company before={before} after={after} fieldLabel="Substituted Out" />,
  checklist: (before, after) => (
    <WidgetActivity
      before={before}
      after={after}
      fieldLabel="Checklist Widget"
      activityLabel="Checklist Widget"
      fieldsMap={checklistFieldsMap}
    />
  ),
  court_information: (before, after) => (
    <WidgetActivity
      before={before}
      after={after}
      fieldLabel="Court Information Widget"
      activityLabel="Court Information Widget"
      fieldsMap={courtInfoFieldsMap}
    />
  ),
  plaintiff: (before, after) => (
    <WidgetActivity
      before={before}
      after={after}
      fieldLabel="Plaintiff's PD Widget"
      activityLabel="Plaintiff's PD Widget"
      fieldsMap={plaintiffFieldsMap}
    />
  )
};
