import { useContext } from 'react';
import { useTheme } from '@material-ui/core';
import { LayoutContext, viewVariantsMap } from '../../../../components/LayoutContext';
import { Grid } from './Grid';
import { List } from './List';

export const MainContent = ({ width }) => {
  const { viewVariant } = useContext(LayoutContext);
  const theme = useTheme();
  const isTablet = width <= theme.breakpoints.values.tabletLg;

  return (
    <>
      {!isTablet ? (
        (viewVariant === viewVariantsMap.list)
          ? <List width={width} /> :
          (viewVariant === viewVariantsMap.grid) ? <Grid /> : null
      ) : (
        <Grid />
      )}
    </>
  );
};
