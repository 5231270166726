import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as reportsApi from '../../../../../api/profile';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../Modal';
import { Loader } from '../../../../Loader';
import { reportTypesMap, reportTypesOptions } from './reportTypesOptions';
import { validationSchema } from './validationSchema';
import { Body } from './Body';

export const SelectReportTypeModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { patient }
}) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSetReportType = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return reportsApi.createDiagnosisReport(values).then(({ data }) => {
      if (values.type === reportTypesMap.new) {
        history.push(location.pathname + `/${data.id}/first-step`);
      } else {
        history.push(location.pathname + `/${data.id}/edit`);
      }

      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        patient_id: patient,
        medical_id: patient?.medical?.id || null,
        type: reportTypesOptions[0],
        provider_id: null,
        attorney_id: null,
        medical_form_id: null,
        isLoading: false
      }}
      validationSchema={validationSchema}
      onSubmit={handleSetReportType}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog
          fullScreen={isMobile}
          PaperProps={!isMobile ? { style: { maxWidth: 900 } } : {}}
          {...DialogProps}
        >
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>} onClose={handleModalReject}>
              Create Initial Report
            </ModalHeader>

            <ModalBody>
              <Body isPatientExist={!!patient?.id} />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
