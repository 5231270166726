import { Box, makeStyles, Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core';
import { tabsMap } from './BillingSectionContent';

const styles = ({ spacing, breakpoints }) => ({
  tab: {
    [breakpoints.down('md')]: {
      paddingLeft: spacing(),
      paddingRight: spacing()
    }
  }
});

const useStyles = makeStyles(styles);

export const TabsContent = ({ activeTab, onChange= () => {} }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const tabsProps = isMobile ? { variant: 'scrollable' } : {};

  return (
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={onChange}
      {...tabsProps}
    >
      <Box clone minHeight="0 !important" value={tabsMap.invoices}>
        <Tab
          classes={{ root: classes.tab }}
          label={
            <Box fontSize={isMobile ? 12 : 14}>Invoices</Box>
          }
        />
      </Box>

      <Box clone minHeight="0 !important" value={tabsMap.receivedPayments}>
        <Tab
          classes={{ root: classes.tab }}
          label={
            <Box fontSize={isMobile ? 12 : 14}>
              {isMobile ? 'Received' : 'Received Payments'}
            </Box>
          }
        />
      </Box>

      <Box clone minHeight="0 !important" value={tabsMap.overdraftPayments}>
        <Tab
          classes={{ root: classes.tab }}
          label={
            <Box fontSize={isMobile ? 12 : 14}>
              {isMobile ? 'Overdraft' : 'Overdraft Payments'}
            </Box>
          }
        />
      </Box>
    </Tabs>
  );
};
