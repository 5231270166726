import { useContext } from 'react';
import moment from 'moment';
import { makeStyles, Box, Typography } from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import {
  AppointmentsContext,
  AppointmentsPreviewsList,
  printAppointments
} from '../index';
import { Badge } from '../../Badge';
import { Divider } from '../../Divider';
import { IconButton } from '../../IconButton';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Appointments = () => {
  const classes = useStyles();
  const selectedDate = moment();
  const { appointments, meta } = useContext(AppointmentsContext);

  const handlePrint = (appointments, date) => () => {
    printAppointments(appointments, date);
  };

  return (
    <div className={classes.appointments}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box mr={2}>
          <Typography variant="h3">
            {selectedDate.format('ddd, DD MMMM')}
          </Typography>
        </Box>

        <Badge showZero badgeContent={meta.total} color="info" title="Appointments count" />

        <Box ml="auto">
          <IconButton edge="end" onClick={handlePrint(appointments, selectedDate)}>
            <PrintOutlinedIcon />
          </IconButton>
        </Box>
      </Box>

      <Box mt={1.75} mb={1.5}>
        <Divider />
      </Box>

      <Box flexGrow={1}>
        <AppointmentsPreviewsList />
      </Box>
    </div>
  );
};
