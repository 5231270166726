import { useEffect, useState } from 'react';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as officeApi from '../../../../../api/companies';
import { ModalBody, ModalContainer } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { Header } from './Header';
import { Body } from './Body';

export const OfficePreviewModal = ({
  DialogProps,
  handleModalReject,
  payload: { id }
}) => {
  const [ office, setOffice ] = useState(null);
  const [ isFetched, setIsFetched ] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setIsFetched(false);

    officeApi.fetchOffice(id).then((data) => {
      setOffice(data);
      setIsFetched(true);
    });
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <ModalContainer>
        <ModalBody>
          <Loader loading={!isFetched} render={
            () => (
              <>
                <Header office={office} onClose={handleModalReject} />
                <Body office={office} />
              </>
            )}
          />
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
