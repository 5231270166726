import cn from 'classnames';
import { makeStyles, Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { ChiropractorClaims } from './ChiropractorClaims';
import { ClaimNote } from './ClaimNote';
import { Hospice } from './Hospice';
import { MainFields } from './MainFields';
import { Medicaid } from './Medicaid';
import { SituationalNumber } from './SituationalNumber';
import { SupplementalInformation } from './SupplementalInformation';
import { UnableToWork } from './UnableToWork';
import { VisionClaims } from './VisionClaims';
import { WorkComp } from './WorkComp';

const useStyles = makeStyles(({ palette }) => ({
  borderBottom: {
    borderBottom: `1px solid ${palette.divider}`
  },

  borderRight: {
    borderRight: `1px solid ${palette.divider}`
  }
}));

export const SituationalNumberForm = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 8}
      component={Box}
      m="0 !important"
      width="100% !important"
    >
      <Grid item md={6} xs={12} className={cn(classes.borderBottom, classes.borderRight)}>
        <MainFields />
      </Grid>

      <Grid item md={6} xs={12} className={classes.borderBottom}>
        <Medicaid />
      </Grid>

      <Grid item md={6} xs={12} className={cn(classes.borderBottom, classes.borderRight)}>
        <Grid container spacing={8}>
          <Grid item xs={12} className={classes.borderBottom}>
            <WorkComp />
          </Grid>

          <Grid item xs={12} className={classes.borderBottom}>
            <UnableToWork />
          </Grid>

          <Grid item xs={12}>
            <SituationalNumber />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} xs={12} className={classes.borderBottom}>
        <ChiropractorClaims />
      </Grid>

      <Grid item md={6} xs={12} className={classes.borderRight}>
        <Grid container spacing={8}>
          <Grid item xs={12} className={classes.borderBottom}>
            <VisionClaims />
          </Grid>

          <Grid item xs={12}>
            <Hospice />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} xs={12}>
        <Grid container spacing={8}>
          <Grid item xs={12} className={classes.borderBottom}>
            <SupplementalInformation />
          </Grid>

          <Grid item xs={12}>
            <ClaimNote />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
