import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell flexBasis={columnsWidths.date}>
          Appt. day
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.time}>
          Appt. time
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.apptBook}>
          Appt. book
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.provider}>
          Provider
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.office}>
          Office
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.insuranceType}>
          Insur. Type
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.insuranceID}>
          Insurance ID
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
