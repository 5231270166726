import { Box, Typography } from '@material-ui/core';
import { commentsActivityMap } from '../activitiesHelpers/commentsActivityMap';

const renderContent = ({
  isDeleted = false,
  isRestored = false,
  isNew = false,
  payload
}) => {
  if (isDeleted) {
    return (
      <>
        Removed Comment
        &nbsp;
        <Typography variant="subtitle1">{payload?.comment?.text || ''}</Typography>
      </>
    );
  } else if (isNew) {
    return (
      <>
        Added new Comment
        &nbsp;
        <Typography variant="subtitle1">{payload?.comment?.text || ''}</Typography>
      </>
    );
  } else if (isRestored) {
    return (
      <>
        Restored Comment
        &nbsp;
        <Typography variant="subtitle1">{payload?.comment?.text || ''}</Typography>
      </>
    );
  } else {
    return (
      <>
        Changed Comment
        &nbsp;
        <Typography variant="subtitle1">{payload?.comment?.text || ''}</Typography>
      </>
    );
  }
};

export const Comment = ({
  isDeleted = false,
  isRestored = false,
  isNew = false,
  payload
}) => {
  const parent = commentsActivityMap[payload?.main_parent?.type]?.label;

  return (
    <Box display="flex" flexDirection="column">
      <Box my={0.5} display="flex" alignItems="center">
        <Typography variant="h5" color="secondary">{parent || 'Comment'}</Typography>

        &nbsp;&nbsp;
        {!!payload?.main_parent?.name && (
          <Typography variant="subtitle1" color="textSecondary">
            {payload?.main_parent?.name}
          </Typography>
        )}
      </Box>

      {renderContent({ isDeleted, isRestored, isNew, payload })}
    </Box>
  );
};
