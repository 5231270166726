import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  List,
  MenuItem,
  ListItemText
} from '@material-ui/core';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import * as paymentsApi from '../../../../../../../../api/billing';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { PaymentModal } from '../../../../../../../../components/billing/payments';
import * as types from '../../../../../../../../components/billing/payments/PaymentsContext/types';

const styles = () => ({
  root: {
    minWidth: 172
  }
});

const useStyles = makeStyles(styles);

export const OverdraftPaymentMenu = ({
  parentPage,
  payment,
  history,
  onClose,
  setPaymentsLastGlobalAction
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ isRefunded, setIsRefunded ] = useState(!!payment.refunded_at);
  const paymentsLastGlobalAction = useSelector(({ globalActions }) => globalActions.paymentsLastGlobalAction);

  useEffect(() => {
    if (paymentsLastGlobalAction) {
      if (paymentsLastGlobalAction.type === types.REFUND_PAYMENT) {
        setIsRefunded(true);
      }

      if (paymentsLastGlobalAction.type === types.CANCEL_REFUND_PAYMENT) {
        setIsRefunded(false);
      }
    }
  }, [ paymentsLastGlobalAction ]);

  const handleRefund = () => {
    if (payment.causer_invoice) {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          paymentsApi.refundPayment({ id: payment.id }).then((data) => {
            setPaymentsLastGlobalAction({ type: types.REFUND_PAYMENT, payload: data });
            enqueueSnackbar('Payment successfully refunded', { variant: 'success' });
            onClose();
          }).catch(({ message, errors }) => {
            if (errors) {
              const errorMessage = Object.values(errors);

              enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
            }
          });
        }
      });
    } else {
      enqueueSnackbar('Payment doesn\'t have invoice', { variant: 'warning' });
    }
  };

  const handleCancelRefund = () => {
    if (payment.causer_invoice) {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          paymentsApi.cancelRefundPayment({ id: payment.id }).then((data) => {
            setPaymentsLastGlobalAction({ type: types.CANCEL_REFUND_PAYMENT, payload: data });
            enqueueSnackbar('Payment successfully canceled', { variant: 'success' });
            onClose();
          }).catch(({ message, errors }) => {
            if (errors) {
              const errorMessage = Object.values(errors);

              enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
            }
          });
        }
      });
    } else {
      enqueueSnackbar('Payment doesn\'t have invoice', { variant: 'warning' });
    }
  };

  const goToPaymentItem = () => {
    !parentPage ?
      history.push(itemsRoutesByModels.payment(payment.id))
      :
      openModal(PaymentModal, {
        payload: {
          initialValues: {
            title: payment.number,
            id: payment.id
          }
        }
      });
  };

  return (
    <List className={classes.root}>
      <MenuItem onClick={goToPaymentItem}>
        <ListItemText primary="See Details" />
      </MenuItem>

      {isRefunded ?
        <MenuItem onClick={handleCancelRefund}>
          <ListItemText primary="Cancel"/>
        </MenuItem>
        :
        <>
          <MenuItem
            component={Link}
            to={itemsRoutesByModels.overdraft(payment.id)}
          >
            <ListItemText primary="Apply to Inv." />
          </MenuItem>

          <MenuItem onClick={handleRefund}>
            <ListItemText primary="Refund" />
          </MenuItem>
        </>
      }
    </List>
  );
};
