import { Formik } from 'formik';
import { Dialog, Button } from '@material-ui/core';
import * as publicAccessApi from '../../../../../../../../api/public-access';
import { KeyboardDateTimePicker } from '../../../../../../../../components/FormField';
import { Loader } from '../../../../../../../../components/Loader';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../../../../../components/Modal';
import { validationSchema } from './validationSchema';

export const ExtendAccessModal = ({
  DialogProps,
  payload: {
    access
  },
  handleModalResolve,
  handleModalReject
}) => {
  const handleSubmit = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return publicAccessApi.extendAccess(access.id, values).then((data) => {
      handleModalResolve(data);
    }).catch(({ data: { errors } = {} }) => {
      if (errors) {
        setErrors(errors);
      }
    });
  };

  return (
    <Formik
      validateOnMount
      initialValues={access}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Extend Access
            </ModalHeader>

            <ModalBody>
              <KeyboardDateTimePicker
                disablePast
                label="Expire date"
                name="expired_at"
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject} color="primary">Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
