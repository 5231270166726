import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Badge } from '../Badge';
import { SvgIcon } from '../SvgIcon';
import { eventConfirmationProps } from './eventConfirmationMap';

const useStyles = makeStyles(({ palette, typography: { pxToRem } }) => ({
  badgeIcon: {
    background: palette.common.white,
    borderRadius: '50%',
    fontSize: pxToRem(16)
  }
}));

export const ParticipationBadge = forwardRef(({ user, children }, ref) => {
  const classes = useStyles();
  const confirmation = user?.schedule_event?.confirmation;
  const Icon = eventConfirmationProps?.[confirmation]?.icon || (() => null);

  return (
    <Badge
      ref={ref}
      invisible={!confirmation}
      overlap="circle"
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      badgeContent={
        <SvgIcon
          fontSize="small"
          color={eventConfirmationProps?.[confirmation]?.color}
          titleAccess={eventConfirmationProps?.[confirmation]?.title}
          className={classes.badgeIcon}
        >
          <Icon />
        </SvgIcon>
      }
    >
      {children}
    </Badge>
  );
});
