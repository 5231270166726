import { makeStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { renderRoutes, getRelatedRoutesWithCustomBase, Route } from '../../../../components/router';
import { Menu } from '../Menu';
import { routes as actionRoutes } from './FinancialClass/routes';
import { routes as actionPayerRoutes } from './PayerContent/routes';
import { routes } from './routes';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Payer = ({ baseUrl }) => {
  const classes = useStyles();
  const mainRoutes = getRelatedRoutesWithCustomBase(routes, baseUrl);

  return (
    <div className={classes.root}>
      <div className={classes.leftSide}>
        <Menu routes={mainRoutes} />
      </div>

      <div className={classes.rightSide}>
        <Route exact path={baseUrl}>
          <Redirect to={`${baseUrl}/financial-class`} />
        </Route>

        {renderRoutes(mainRoutes)}
        {renderRoutes(getRelatedRoutesWithCustomBase(actionRoutes, baseUrl))}
        {renderRoutes(getRelatedRoutesWithCustomBase(actionPayerRoutes, baseUrl))}
      </div>
    </div>
  );
};
