export const styles = ({ spacing }) => ({
  root: {
    minWidth: 172,
    overflow: 'auto',
    maxHeight: 200,
    padding: spacing(1, 2)
  },

  userLink: {
    marginTop: spacing(0.5),
    marginBottom: spacing(0.5),
    cursor: 'pointer'
  }
});
