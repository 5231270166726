import { useContext } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { Divider } from '../../../../../../components/Divider';
import { TaskPreviewContext } from '../../../../../../components/tasks/TasksProvider';
import {
  ScheduleEventsFilterProvider,
  ScheduleProvider,
  EventsList
} from '../../../../../../components/schedule-events';
import { AddEvent } from './AddEvent';

export const Events = () => {
  const { selectedTask } = useContext(TaskPreviewContext);

  return (
    <ScheduleEventsFilterProvider filter={{ parent_type: 'task', parent_id: selectedTask.id }}>
      <ScheduleProvider>
        <Box display="flex" justifyContent="center">
          <AddEvent />
        </Box>

        <Divider gutter={1} />

        <EventsList hideApplyFields selectedDate={moment()} />
      </ScheduleProvider>
    </ScheduleEventsFilterProvider>
  );
};
