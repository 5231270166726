import moment from 'moment';
import { useFormikContext } from 'formik';
import { Collapse, FormControlLabel } from '@material-ui/core';
import {
  Checkbox,
  TextField,
  PhoneField,
  KeyboardDatePicker
} from '../../../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../../../components/users';

export const UserInfo = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleUserChange = (user) => {
    setFieldValue('phone', user?.phone || null);
    setFieldValue(
      'dob',
      user?.birthday
        ? moment(user?.birthday).format('YYYY-MM-DD')
        : null
    );
  };

  const handleCheckedChanged = (event) => {
    const checked = event.target.checked;

    if (checked) {
      setFieldValue('user_id', null);
      setFieldValue('first_name', null);
      setFieldValue('last_name', null);
    } else {
      setFieldValue('first_name', null);
      setFieldValue('last_name', null);
      setFieldValue('phone', null);
      setFieldValue('dob', null);
    }
  };

  return (
    <>
      <UsersSelect
        required
        disabled={!!values?.is_checked}
        name="user_id"
        label="Name"
        margin="dense"
        onChange={handleUserChange}
      />

      <FormControlLabel
        control={
          <Checkbox
            name="is_checked"
            size="small"
            color="primary"
            onChange={handleCheckedChanged}
          />
        }
        label="Did not find the user?"
      />

      <Collapse
        unmountOnExit
        in={values?.is_checked}
        timeout="auto"
      >
        <TextField
          margin="dense"
          name="first_name"
          label="First name"
        />

        <TextField
          margin="dense"
          name="last_name"
          label="Last name"
        />

        <KeyboardDatePicker
          name="dob"
          label="DOB"
          outputFormat="YYYY-MM-DD"
          placeholder="MM/DD/YYYY"
          margin="dense"
        />
      </Collapse>

      <PhoneField
        required
        margin="dense"
        name="phone"
        label="Phone"
      />
    </>
  );
};
