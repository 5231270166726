import { Switch, Redirect, useLocation, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Route } from '../../../../components/router/Route';
import { Templates, Edit } from './Templates';
import { PrescriptionsTemplates } from './PrescriptionsTemplates';

export const Settings = () => {
  const { pathname } = useLocation();
  const params = useParams();

  return (
    <Box height="100%" bgcolor="grey.100">
      <Box height="100%" py={2} px={2}>
        <Switch>
          {!!params?.patientID ?
            <>
              <Route exact path={`/patients/${params.patientID}/settings`}>
                <Redirect to={`/patients/${params.patientID}/settings/templates`} />
              </Route>

              <Route exact path="/patients/:patientID/settings/templates" component={Templates} />
              <Route
                exact
                path="/patients/:patientID/settings/prescriptions-templates"
                component={PrescriptionsTemplates}
              />
              <Route path="/patients/:patientID/settings/templates/:id/edit" component={Edit}/>
            </>
            :
            <Route exact path={pathname}>
              <Redirect to="/patients" />
            </Route>
          }
        </Switch>
      </Box>
    </Box>
  );
};
