import { useContext, useEffect, useRef, useState } from 'react';
import { debounce, flow } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { workersRolesOptions } from '../../../../dataMaps';
import { Select, TextField } from '../../../FormField';
import { SavedFiltersWidget, SavedFiltersProvider } from '../../../saved-filters';
import { FiltersBar as FiltersBarComponent } from '../../../FiltersBar';
import { filtersKeysMap, saveFilter } from '../../../../store/lastFilters';
import { MembersContext } from '../MembersContext';
import { SavedFiltersList } from './SavedFiltersList';

const IconComponent = ({ relationsForFilter, onApplySavedFilter }) => {
  return (
    <SavedFiltersProvider filter={{ entity: filtersKeysMap.patients_workers }}>
      <SavedFiltersWidget relationsForFilter={relationsForFilter} modalWidth={450}>
        {({ handleClose }) => (
          <SavedFiltersList onApplySavedFilter={flow(onApplySavedFilter, handleClose)} />
        )}
      </SavedFiltersWidget>
    </SavedFiltersProvider>
  );
};

const initialValues = {
  worker_name: null,
  worker_role: null
};

export const FiltersBar = () => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [ relationsForFilter, setRelationsForFilter ] = useState({});
  const { applyFilter } = useContext(MembersContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filtersKeysMap.patients_workers]);

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.value });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data || option }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues({
      ...filter,

      worker_role: workersRolesOptions.find(({ value } ) => value === filter?.worker_role?.value)
    });

    applyFilter({
      ...filter,
      worker_role: filter?.worker_role?.value
    });
  };

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  useEffect(() => {
    dispatch(saveFilter({
      key: filtersKeysMap.patients_workers,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      formikRef={formikRef}
      initialValues={relationsForFilter}
      onReset={() => {
        applySavedFilter(initialValues);
      }}
      iconComponent={
        <IconComponent
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      border={0}
      fields={[
        <TextField
          name="worker_name"
          label="Search by user name"
          placeholder="Search..."
          margin="dense"
          onChange={handleFieldChange('worker_name')}
        />,

        <Select
          isClearable
          name="worker_role"
          label="Select user role"
          options={workersRolesOptions}
          onChange={handleSelectChange('worker_role')}
          TextFieldProps={{ margin: 'dense' }}
        />
      ]}
    />
  );
};
