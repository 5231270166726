import { useContext } from 'react';
import { Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Tooltip } from '../../../../../../../../components/Tooltip';
import { UserLink } from '../../../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../../../helpers/dom';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Popper } from '../../../../../../../../components/Popper';
import { TemplatesContext } from '../../../TemplatesProvider';
import { TemplatesMenu } from '../../TemplatesMenu';
import { minRowHeight, columnsWidths } from '../List';

export const Row = ({ template }) => {
  const { selectedIDs, toggleItemSelection } = useContext(TemplatesContext);
  const isSelected = selectedIDs.indexOf(template.id) !== -1;
  const description = !!template?.visit_reason?.description
    ? ' - ' + template?.visit_reason?.description
    : '';

  const handleItemSelection = () => {
    toggleItemSelection(template.id);
  };

  return (
    <ListRow minHeight={minRowHeight}>
      <ListRowCheckbox checked={isSelected} onClick={stopPropagation(handleItemSelection)} />

      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap>{template.name}</Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.age}>
        <Typography noWrap>{template.age ? template.age + 'yrs' : '-'}</Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.reason}>
        <Tooltip
          isExistTooltip={!!template?.visit_reason}
          title={`${template?.visit_reason?.code} - ${template?.visit_reason?.description}`}
        >
          <Typography noWrap>
            {template?.visit_reason
              ? `${template?.visit_reason?.code}${description}`
              : '-'
            }
          </Typography>
        </Tooltip>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.provider}>
        <UserLink
          noWrap
          size="md"
          variant="inherit"
          user={template.provider}
          childrenProps={{ mt: -0.5 }}
        >
          <Typography color="textSecondary" variant="caption">
            {template?.office?.full_address}
          </Typography>
        </UserLink>
      </ListRowCell>

      <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <TemplatesMenu template={template} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
