import moment from 'moment';
import { Box, Chip, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import * as appointmentsApi from '../../../api/appointments';
import { Autocomplete } from '../../FormField';
import { UserLink } from '../../UserLink';
import { VerticalDivider } from '../../VerticalDivider';

const fetchAppointments = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return appointmentsApi.fetchAppointments({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (appointment) => {
  return (
    <>
      <ListItemAvatar>
        <CalendarIcon fontSize="small" />
      </ListItemAvatar>

      <ListItemText
        primary={
          <Box display="flex" alignItems="center">
            <Typography>{moment.unix(appointment.appointment_at).format('L, LT')}</Typography>

            <VerticalDivider horizontalGutters={1} />

            <Box mr={0.5}>
              <Typography variant="caption" color="textSecondary">Dr.</Typography>
            </Box>

            <UserLink size="xs" user={appointment?.appointment_book?.provider} />

            {(!!appointment?.office?.name || !!appointment?.office?.full_address) &&
              <>
                <VerticalDivider horizontalGutters={1} />

                <Typography>
                  <Box clone mr={0.5}>
                    <LocationOnOutlinedIcon fontSize="small" />
                  </Box>

                  {appointment?.office?.name || appointment?.office?.full_address}
                </Typography>
              </>
            }
          </Box>
        }
        primaryTypographyProps={{ noWrap: true, component: 'div' }}
      />
    </>
  );
};

export const AppointmentsSelect = ({ multiple = false, params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      name="appointment_id"
      label="Appointments"
      placeholder="Select appointment..."
      onNeedFetch={fetchAppointments(params)}
      renderOption={renderOption}
      getOptionLabel={(option) => option?.appointment_book_name}
      getOptionValue={(appointment) => appointment?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}
      getInputProps={(value) => !(!multiple && value) ? null : ({
        startAdornment: (
          <Chip
            variant="outlined"
            label={moment.unix(value.appointment_at).format('L LT')}
            icon={<CalendarIcon />}
          />
        )
      })}

      {...props}
    />
  );
};
