import { Box, Grid, Typography } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { stopPropagation } from '../../../helpers/dom';
import { AddressLink } from '../../AddressLink';
import { Button } from '../../Button';
import { CurrencyFormat } from '../../CurrencyFormat';
import { UserPreviewModal } from '../../users';
import { useModal } from '../../ModalsProvider';
import { UserLink } from '../../UserLink';
import { Contact } from '../../Contact';
import { Date } from '../../Date';
import { VerticalDivider } from '../../VerticalDivider';
import { AppointmentsHistoryModal } from '../AppointmentsHistoryModal';

export const PatientInfo = ({ appointment }) => {
  const { openModal } = useModal();
  const billingInfo = appointment.billing_info;
  const patient = {
    ...(appointment?.patient_appointment_details || {}),
    ...(appointment?.patient_appointment_details?.patient || {}),
    ...(appointment?.patient || {})
  };

  const showHistory = () => {
    openModal(AppointmentsHistoryModal, {
      payload: {
        patientID: appointment.patient_id
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" color="info.main">
        <Typography variant="h4" color="inherit">Patient Info</Typography>

        <VerticalDivider verticalGutters={1} horizontalGutters={3} />

        <Button
          edge="start"
          color="primary"
          startIcon={<EyeIcon />}
          onClick={showHistory}
        >
          Appt. history
        </Button>
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Patient Name
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <UserLink
            size="sm"
            user={patient}
            onClick={stopPropagation(openUserPreview(patient?.id))}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            DOB
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Date date={patient?.birthday} />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Pri. Phone
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            needIcon
            type="tel"
            contact={patient?.phone_number || patient?.phone_number}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            MRN
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography>
            {appointment?.patient_appointment_details?.med_rec || '-'}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Ext. MRN
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography>
            {appointment?.patient_appointment_details?.ext_med_rec || '-'}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Address
          </Typography>
        </Grid>

        <Grid item xs={7}>
          {appointment?.patient_appointment_details?.full_address ?
            <Box clone display="flex" alignItems="flex-start">
              <AddressLink address={appointment?.patient_appointment_details?.full_address}>
                <Box clone mr={1}>
                  <LocationOnIcon />
                </Box>

                {appointment?.patient_appointment_details?.full_address}
              </AddressLink>
            </Box>
            :
            <Typography>
              -
            </Typography>
          }
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Referral Name
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <UserLink
            size="sm"
            user={
              appointment?.patient_appointment_details?.medical?.preferred ||
              appointment?.patient_appointment_details?.preferred
            }
            onClick={openUserPreview(
              appointment?.patient_appointment_details?.medical?.preferred?.id ||
              appointment?.patient_appointment_details?.preferred?.id
            )}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Patient Balance
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <CurrencyFormat value={billingInfo?.patient_balance} />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Notes
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography>
            {appointment?.note || '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
