export const typesEnclosure = [
  {
    value: '"',
    label: 'Double Quote'
  },
  {
    value: '\'',
    label: 'Quote'
  }
];
