import moment from 'moment';
import { Yup } from '../../../utils/validation';

export const validationSchema = Yup.object({
  user_id: Yup.mixed().nullable().when('is_all_users', (is_all_users, schema) => {
    return is_all_users ? schema : schema.required();
  }),
  company_id: Yup.mixed().nullable().required(),
  is_all_users: Yup.boolean().nullable(),
  supervisor_id: Yup.mixed().nullable().required(),
  date_from: Yup.date().format(moment.HTML5_FMT.DATE).nullable().required().when('date_to', (dateTo, schema) => {
    const isValid = dateTo && moment(dateTo).isValid();

    return isValid ? schema.max(dateTo, 'Start date must be earlier than end') : schema;
  }),
  date_to: Yup.date().format(moment.HTML5_FMT.DATE).nullable().required()
    .max(moment(), 'End date must be earlier than now')
});
