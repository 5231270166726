export const getReportFormRoutes = ({ id, pathname }) => {
  return {
    0: `${pathname}/${id}/first-step`,
    1: `${pathname}/${id}/second-step`,
    2: `${pathname}/${id}/third-step`,
    3: `${pathname}/${id}/fourth-step`,
    4: `${pathname}/${id}/fifth-step`,
    5: `${pathname}/${id}/six-step`,
    6: `${pathname}/${id}/seven-step`
  };
};
