import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_EVENT_TYPES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_EVENT_TYPES_SUCCESS]: (
    { filter, eventTypes, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      eventTypes: (page > 1
        ? eventTypes.filter(({ id }) => {
          return !data.find((eventType) => id === eventType.id);
        }).concat(data)
        : data
      )
    };
  },

  [types.RESET_EVENT_TYPES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_EVENT_TYPE]: (state, eventType) => {
    return {
      ...state,

      eventTypes: state.eventTypes.map((item) => {
        return item.id === eventType.id ? { ...item, ...eventType } : item;
      })
    };
  },

  [types.DELETE_EVENT_TYPE]: (state, eventTypeID) => {
    const { pagination, filter, eventTypes } = state;
    const filteredEventTypes = eventTypes.filter(({ id }) => eventTypeID !== id);

    if (filteredEventTypes.length === eventTypes.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((eventTypes.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,
      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      eventTypes: filteredEventTypes
    };
  }
});
