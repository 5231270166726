export const trackModelsMap = {
  case: 'case',
  task: 'task',
  schedule_event: 'event'
};

export const trackModelsColorsMap = {
  case: 'high',
  task: 'low',
  schedule_event: 'info'
};
