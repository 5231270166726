import { AppointmentsFilterProvider, AppointmentsProvider } from '../../../index';
import { Calendar } from './Calendar';

export const CalendarContainer = ({ date, workSchedule, filterKey }) => {
  return (
    <AppointmentsFilterProvider filterKey={filterKey}>
      <AppointmentsProvider>
        <Calendar date={date} workSchedule={workSchedule} />
      </AppointmentsProvider>
    </AppointmentsFilterProvider>
  );
};
