import { useContext } from 'react';
import { Box, Paper, useTheme } from '@material-ui/core';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { ExpensesContextProvider } from '../../Expenses';
import { ActionsBar } from './ActionsBar';
import { Expenses, FiltersBar } from './Expenses';
import { Grid } from './Grid';

export const ExpensesContent = ({ width, caseID = null, hiddenFilterFields, filter = {}, filterKey }) => {
  const { viewVariant } = useContext(LayoutContext);
  const screenSize = caseID ? 'tabletMd' : 'md';
  const theme = useTheme();
  const isTablet = width <= theme.breakpoints.values?.[screenSize];
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper
  } = useMobileFilterCollapse(screenSize, width);
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  return (
    <ExpensesContextProvider initialState={{ filter }}>
      <ActionsBar isTablet={isTablet} caseID={caseID} filterOpenButton={filterOpenButton} />

      <ScrollWrapper {...scrollWrapperProps}>
        <FilterBarWrapper {...filterBarWrapperProps}>
          <FiltersBar
            hiddenFields={hiddenFilterFields}
            filterKey={filterKey}
          />
        </FilterBarWrapper>
      </ScrollWrapper>

      <Paper component={Box} height="100%" display="flex" flexDirection="column" minHeight={500}>
        {isTablet || viewVariant === viewVariantsMap.grid
          ? <Grid />
          : viewVariant === viewVariantsMap.list ? <Expenses caseID={caseID} /> : null
        }
      </Paper>
    </ExpensesContextProvider>
  );
};
