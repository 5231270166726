import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Contact } from '../../../../../../../../components/Contact';

export const EmailInfo = ({ office }) => {
  return (
    <>
      <Grid container spacing={1} component={Box} py={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Email
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Contact
            noWrap
            needIcon
            variant="h5"
            type="mailto"
            contact={office.email}
          />
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center" mb={0.5} fontSize={14}>
        <NotesIcon fontSize="inherit" />

        <Box ml={0.5}>
          <Typography variant="subtitle2">
            Notes
          </Typography>
        </Box>
      </Box>

      <Box mb={3}>
        <Typography color="textSecondary">
          {office.notes || 'No info.'}
        </Typography>
      </Box>
    </>
  );
};
