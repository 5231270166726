import { useContext, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { PaymentsContext } from '../../../../../../../components/billing/payments';
import { columnsMap, List } from '../../../../Payments/PaymentsPage/List';
import { Grid } from '../../../../Payments/PaymentsPage/Grid';

const paymentsHiddenColumns = [ columnsMap.checkbox, columnsMap.overdraftActions ];

export const ReceivedPayments = () => {
  const paymentsContext = useContext(PaymentsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    paymentsContext.resetPayments({ overdraft: null });
  }, []);

  return !isMobile ? (
    <List hiddenColumns={paymentsHiddenColumns} />
  ) : (
    <Grid hiddenColumns={paymentsHiddenColumns} />
  );
};
