import { orderDirectionsMap } from '../../FormField/selects/OrderBy';

export const initialState = {
  isFetching: false,
  isFetched: false,
  filter: {
    page: 1,
    per_page: 15,
    order_direction: orderDirectionsMap.asc
  },
  pagination: {},
  appointments: []
};
