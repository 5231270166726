import { useContext } from 'react';
import {
  makeStyles,
  Box,
  IconButton,
  Button,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import {
  mdiAccountArrowLeftOutline,
  mdiBriefcaseCheckOutline,
  mdiTagPlusOutline,
  mdiTrashCanOutline
} from '@mdi/js';
import { Icon } from '@mdi/react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { useModal } from '../../../../../components/ModalsProvider';
import { Checkbox } from '../../../../../components/FormField';
import { SvgIcon } from '../../../../../components/SvgIcon';
import { Tooltip } from '../../../../../components/Tooltip';
import { EmailsAttachToPatientModal } from '../EmailsAttachToPatientModal';
import { EmailsAttachToCaseModal } from '../EmailsAttachToCaseModal';
import { EmailsAttachToTagsModal } from '../EmailsAttachToTagsModal';
import { SavedEmailsContext } from '../SavedEmailsContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointMedium = 445;

export const EmailsActions = ({
  disableButton = false,
  isOpenSidebar = false,
  onOpenSideBar = () => {},
  children
}) => {
  const {
    messages,
    selectedMessages,
    addCaseToMessages,
    deleteCaseFromMessages,
    addPatientToMessages,
    deletePatientFromMessages,
    addTagsToMessages,
    deleteTagsFromMessages,
    replaceTagsToMessages,
    deleteMessages,
    allItemsIsSelected,
    toggleAllItemsSelection
  } = useContext(SavedEmailsContext);
  const { openModal } = useModal();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const attachToCase = () => {
    openModal(EmailsAttachToCaseModal, {
      payload: {
        emailIDs: selectedMessages,
        onCaseAdd: addCaseToMessages,
        onCaseDelete: deleteCaseFromMessages
      }
    });
  };

  const attachToPatient = () => {
    openModal(EmailsAttachToPatientModal, {
      payload: {
        emailIDs: selectedMessages,
        onPatientAdd: addPatientToMessages,
        onPatientDelete: deletePatientFromMessages
      }
    });
  };

  const attachToTags = () => {
    openModal(EmailsAttachToTagsModal, {
      payload: {
        emailIDs: selectedMessages,
        onTagsAdd: addTagsToMessages,
        onTagsDelete: deleteTagsFromMessages,
        onTagsReplace: replaceTagsToMessages
      }
    });
  };

  const deleteMessage = () => {
    deleteMessages(selectedMessages);
  };

  return !!messages.length && (
    <Box className={classes.root}>
      <div className={classes.col}>
        {!hasRole(rolesMap.patient) &&
          <Box mr={isMobile ? 0 : !disableButton ? 3 : 1}>
            <Checkbox
              withoutFormik
              edge="start"
              checked={allItemsIsSelected()}
              indeterminate={!!selectedMessages.length && !allItemsIsSelected()}
              size="small"
              onClick={toggleAllItemsSelection}
            />
          </Box>
        }

        {!disableButton && isMobile && !isOpenSidebar && (
          <Box mr={1}>
            <IconButton color="primary" onClick={onOpenSideBar}>
              <SvgIcon color="inherit">
                <ChevronLeftIcon color="primary" />
              </SvgIcon>
            </IconButton>
          </Box>
        )}

        {!selectedMessages.length ? children : (
          <>
            {!disableButton ? (
              <Button
                size="small"
                color="primary"
                startIcon={<SvgIcon><Icon path={mdiBriefcaseCheckOutline} /></SvgIcon>}
                onClick={attachToCase}
              >
                Attache to case
              </Button>
            ) : (
              <Tooltip title="Attache to case">
                <IconButton size="small" onClick={attachToCase}>
                  <SvgIcon fontSize="small"><Icon path={mdiBriefcaseCheckOutline} /></SvgIcon>
                </IconButton>
              </Tooltip>
            )}

            <VerticalDivider horizontalGutters={1} verticalGutters={2} />

            {!disableButton ? (
              <Button
                size="small"
                startIcon={<SvgIcon><Icon path={mdiAccountArrowLeftOutline}/></SvgIcon>}
                className={classes.attachToPatientButton}
                onClick={attachToPatient}
              >
                Attache to patient
              </Button>
            ) : (
              <Tooltip title="Attache to patient">
                <IconButton size="small" className={classes.attachToPatientButton} onClick={attachToPatient}>
                  <SvgIcon fontSize="small"><Icon path={mdiAccountArrowLeftOutline} /></SvgIcon>
                </IconButton>
              </Tooltip>
            )}

            <VerticalDivider horizontalGutters={1} verticalGutters={2} />

            {!disableButton ? (
              <Button
                size="small"
                startIcon={<SvgIcon><Icon path={mdiTagPlusOutline}/></SvgIcon>}
                color="secondary"
                onClick={attachToTags}
              >
                Change tags
              </Button>
            ) : (
              <Tooltip title="Change tags">
                <IconButton size="small" color="secondary" onClick={attachToTags}>
                  <SvgIcon fontSize="small"><Icon path={mdiTagPlusOutline} /></SvgIcon>
                </IconButton>
              </Tooltip>
            )}

            <VerticalDivider horizontalGutters={1} verticalGutters={2} />

            <Tooltip title="Delete email">
              <IconButton size="small" onClick={deleteMessage}>
                <SvgIcon fontSize={!disableButton ? 'inherit' : 'small'} color="error">
                  <Icon path={mdiTrashCanOutline} />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </Box>
  );
};
