import { useEffect, useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { styles } from '../../../../../../components/Comments/Row/styles';
import { LinkableText } from '../../../../../../components/LinkableText';
import { Contact } from '../../../../../../components/Contact';
import { ChatDivider } from './ChatDivider';

const useStyles = makeStyles(styles);

export const NotificationItem = ({ notification, index, notifications }) => {
  const classes = useStyles();
  const [ isDividerShown, setIsDividerShown ] = useState(true);
  const isIncomeRoute = notification?.route !== 'income';

  useEffect(() => {
    if (notification?.created_at) {
      const timeDifferenceDay = !notifications[index - 1] ||
        !moment.unix(notification.created_at).isSame(moment.unix(notifications[index - 1].created_at), 'day');

      setIsDividerShown(!!timeDifferenceDay);
    }
  }, [ notification, notifications ]);

  return (
    <>
      {isDividerShown && <ChatDivider notification={notification} />}

      <Box display="flex" flexDirection={isIncomeRoute ? 'row' : 'row-reverse'}>
        <Box display="flex" flexDirection="column" alignItems={isIncomeRoute ? 'flex-start' : 'flex-end'} p={1}>
          <Box mx={2} display="flex">
            <Typography>
              from:&nbsp;
            </Typography>

            <Contact
              type="tel"
              variant="h5"
              contact={notification.phone}
            />
          </Box>

          <Box
            p={1.5}
            px={2}
            display="flex"
            borderRadius={4}
            color={isIncomeRoute ? 'inherit' : 'white'}
            bgcolor={isIncomeRoute ? 'grey.100' : 'primary.main'}
          >
            <Typography className={cn(classes.contentText, classes.contentTextNotification)} color="inherit">
              <LinkableText color="inherit" target="_blank">
                {notification.message}
              </LinkableText>
            </Typography>
          </Box>

          <Box py={1} mx={2}>
            <Typography>
              {moment.unix(notification.updated_at).format('LT')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
