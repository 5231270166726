import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { useModal } from '../../../../../ModalsProvider';
import { Tooltip } from '../../../../../Tooltip';
import { getThreadName } from '../../../../utils';
import { MessengerContext } from '../../../../MessengerProvider';
import { ThreadAvatar } from '../../../../ThreadAvatar';
import { formatDateForChat } from '../../../../utils';
import { ChatModal } from '../../ChatModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ThreadLink = ({ item: thread = {}, isMinimized }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { openModal } = useModal();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const currentUser = useSelector(({ profile }) => profile.user);
  const lastMessageTime = formatDateForChat(thread.updated_at);
  const chatName = useMemo(() => {
    return getThreadName({ thread, currentUser });
  }, [ thread.name, thread.users ]);
  const { currentThreadId, setCurrentThreadId } = useContext(MessengerContext);
  const chatNameLength = lastMessageTime.length > 5 ? '136px' : '155px';

  const openChatWindow = () => {
    setCurrentThreadId(currentThreadId === thread.id ? null : thread.id);
  };

  const openChatModal = () => {
    openModal(ChatModal, {
      payload: {
        threadID: thread.id
      }
    });
  };

  return (
    <ListItem
      button
      className={classes.listItem}
      onClick={isMobile ? openChatModal : openChatWindow}
    >
      <>
        <Tooltip placement="left" title={chatName}>
          <Box mx={isMinimized ? 1 : 0}>
            <ThreadAvatar showUnreadMessagesIndicator thread={thread} />
          </Box>
        </Tooltip>


        <ListItemText
          className={classes.listItemText}
          style={{ maxWidth: chatNameLength }}
          primary={
            <Tooltip placement="left" title={chatName}>
              <Typography noWrap variant="h5">{chatName}</Typography>
            </Tooltip>
          }
        />

        <ListItemSecondaryAction className={classes.secondaryAction}>
          <Typography>{lastMessageTime}</Typography>
        </ListItemSecondaryAction>
      </>
    </ListItem>
  );
};
