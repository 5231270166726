import { Box, Grid, Typography } from '@material-ui/core';
import { ColorPreview } from '../../../../../../../components/ColorPreview';
import { UserLink } from '../../../../../../../components/UserLink';
import { orderByLabelsMap } from '../../../../../CasesPage/Cases/MainWindow/FiltersBar/orderByOptions';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.search &&
        <>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                Search:
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.search}
            </Typography>
          </Grid>
        </>
      }

      {!!params?.patients?.length &&
        <>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                Patients:
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.patients?.map((user) => (
                <Box display="flex" width="100%" alignItems="center" key={user?.id}>
                  <UserLink
                    noWrap
                    size="sm"
                    key={user.id}
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.appointment_types &&
        <>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                Appt. types:
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" flexDirection="column" width="100%" alignItems="center">
              {params?.appointment_types?.map((appt) => (
                <Box display="flex" width="100%" alignItems="center" key={appt?.id}>
                  <ColorPreview color={appt.color} />

                  <span>
                    {appt.code}
                  </span>
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      }

      {params?.is_active &&
        <>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography>
                Status:
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8}>
            {params?.is_active?.label}
          </Grid>
        </>
      }

      {params?.order_by &&
        <>
          <Grid item xs={4}>
            <Typography>
              Order by:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {orderByLabelsMap[params?.order_by]}
            </Typography>
          </Grid>
        </>
      }
    </Grid>
  );
};
