import { Yup } from '../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  primary_insurance_id: Yup.mixed().nullable(),
  secondary_insurance_id: Yup.mixed().nullable(),
  treating_physician_id: Yup.mixed().nullable(),
  date: Yup.mixed(),
  forms: Yup.object().shape({
    where_is_your_primary_pain_location: Yup.string().nullable().min(1).max(250),
    circle_two_words_that_best_describe_your_pain: Yup.object().shape({
      aching: Yup.boolean().nullable(),
      sharp: Yup.boolean().nullable(),
      shooting: Yup.boolean().nullable(),
      dull: Yup.boolean().nullable(),
      burning: Yup.boolean().nullable(),
      nagging: Yup.boolean().nullable(),
      stabbing: Yup.boolean().nullable()
    }),
    what_makes_the_pain_worse: Yup.string().nullable().min(1).max(250),
    how_has_your_sleep_been: Yup.string().nullable().max(20),
    what_makes_your_pain_better: Yup.string().nullable().min(1).max(250),
    do_you_have_any_new_areas_of_pain:  Yup.string().nullable(),
    where: Yup.string().nullable().min(1).max(250),
    how_long_have_you_had_the_new_pain: Yup.string().nullable().min(1).max(250),
    constipation: Yup.string().nullable(),
    use_of_laxative: Yup.string().nullable(),
    describes_your_pain_on_average_in_the_week_with_medication_use: Yup.string().nullable().min(1).max(250),
    best_describes_your_pain_at_its_worst: Yup.string().nullable().min(1).max(250),
    pain_relief_with_the_treatment_or_medicine_we_provided_you: Yup.string().nullable().min(1).max(250),
    what_side_effects_or_symptoms_are_you_having: Yup.object().shape({
      chest_pain: Yup.boolean().nullable(),
      cough: Yup.boolean().nullable(),
      nausea: Yup.boolean().nullable(),
      stomach_pain: Yup.boolean().nullable(),
      itch: Yup.boolean().nullable(),
      insomnia: Yup.boolean().nullable(),
      depression: Yup.boolean().nullable(),
      sweating: Yup.boolean().nullable()
    }),
    please_list_any_new_medication_added_since_the_last_visit: Yup.string().nullable().min(1).max(250)
  }),
  appointment_book_id: Yup.mixed().nullable(),
  appointment_date: Yup.mixed().nullable().when('appointment_book_id', {
    is: (value) => value,
    then: Yup.mixed().nullable().required()
  }),
  appointment_time: Yup.mixed().nullable().when('appointment_book_id', {
    is: (value) => value,
    then: Yup.mixed().nullable().required()
  })
});
