import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link, Redirect, Switch, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';
import { Box, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { MedicalReportsTabs } from '../../../../../../components/MedicalReportsTabs';
import { getActiveRoute, renderRoutes, Route } from '../../../../../../components/router';
import { Tabs, Tab } from '../../../../../../components/Tabs';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { getReportsRoutes } from '../../../../MedicalReports';
import { GeneralAndMedicalSwitcher } from '../../../../ProfilePage/GeneralAndMedicalSwitcher';
import { MedicalProfileProvider } from '../../../../ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider';
import { LeftSidebar } from '../../../../ProfilePage/MedicalInfo/MainInfo/LeftSidebar';
import { styles } from '../../../../PatientsPage/MedicalInfo/styles';
import { UserContext } from '../../UserProvider';
import { routes } from './routes';

const useStyles = makeStyles(styles);

export const MedicalInfo = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams();
  const { medicalInfoPathname } = useContext(UserContext);
  const userSidebarIsOpen = useSelector(({ layout: { userInfoIsOpen = true } }) => userInfoIsOpen);
  const reportRoutes = getReportsRoutes('/members/users/:userID/medical-info/medical-report');
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [ isUserInfoOpen, setIsUserInfoOpen ] = useState(false);
  const {
    FilterBarWrapper,
    filterBarWrapperProps,
    toggleFiltersBar,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  const toggleInfoOpen = () => {
    setIsUserInfoOpen((state) => !state);
  };

  if (location.pathname === `${medicalInfoPathname}/medical-report`) {
    return (
      <Switch>
        <Route exact path={location.pathname}>
          <Redirect to={location.pathname + '/consultation'} />
        </Route>
      </Switch>
    );
  }

  return (
    <MedicalProfileProvider
      userID={params.userID}
      medicalInfoPathname={`/members/users/${params.userID}/medical-info`}
    >
      <Box display="flex" flexDirection="column" height="100%" bgcolor="grey.100" overflow="hidden" minHeight="100%">
        {isTablet &&
          <div className={classes.navigation}>
            <Box mx={2.25} mt={1}>
              <IconButton size="medium" onClick={toggleFiltersBar}>
                <ListIcon />
              </IconButton>
            </Box>
          </div>
        }

        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <Box
              display="flex"
              flexWrap="nowrap"
              flexDirection={isTablet ? 'column' : 'row'}
              alignItems={isTablet ? 'stretch' : 'center'}
              pl={1.25}
            >
              <Box display="flex" pr={2}>
                <GeneralAndMedicalSwitcher
                  isMedical
                  generalLink={`/members/users/${params.userID}`}
                  medicalLink={`/members/users/${params.userID}/medical-info/medical-forms`}
                />
              </Box>

              <Box flexGrow={1} overflow="hidden">
                <Tabs
                  value={generatePath(getActiveRoute(routes), { userID: params.userID })}
                  orientation={isTablet ? 'vertical' : 'horizontal'}
                  classes={{ flexContainer: classes.tabsWrapper }}
                >
                  {routes.map(({ path, icon, label }) => (
                    <Tab
                      key={path}
                      component={Link}
                      to={generatePath(path, { userID: params.userID })}
                      value={generatePath(path, { userID: params.userID })}
                      icon={icon}
                      label={label}
                      onClick={isTablet ? toggleFiltersBar : undefined}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </FilterBarWrapper>
        </ScrollWrapper>

        <Route path="/members/users/:userID/medical-info/medical-report">
          <MedicalReportsTabs pathname={`/members/users/${params.userID}/medical-info/medical-report`} />
        </Route>

        <div className={classes.content}>
          {userSidebarIsOpen && (
            <div className={cn(classes.leftSidebar, { [classes.leftSidebarFullHeight]: isUserInfoOpen })}>
              <LeftSidebar openUserInfo={isUserInfoOpen} toggleInfoOpen={toggleInfoOpen} />
            </div>
          )}

          <div className={cn(classes.main, { [classes.mainFullHeight]: !isUserInfoOpen })}>
            {renderRoutes(routes)}

            <Route path="/members/users/:userID/medical-info/medical-report">
              <Box display="flex" px={isTablet ? 0 : 0.5} height="100%">
                {renderRoutes(reportRoutes)}
              </Box>
            </Route>
          </div>
        </div>
      </Box>
    </MedicalProfileProvider>
  );
};
