import * as cardsTypes from './cardsTypes';
import { BillingPayments } from './BillingPayments';
import { OverdueBills } from './OverdueBills';
import { ExpensesAnalysis } from './ExpensesAnalysis';
import { BillableTimeCard } from './BillableTimeCard';
import { BillingSummery } from './BillingSummery';
import { Balances } from './Balances';
import { BillingHistory } from './BillingHistory';
import { Payment } from './Payment';
import { PaymentStatuses } from './PaymentStatuses';

export const widgetsMap = {
  [cardsTypes.BILLING_PAYMENTS]: {
    title: 'Billing & Payments',
    component: BillingPayments
  },

  [cardsTypes.OVERDUE_BILLS]: {
    title: 'Billing summary',
    component: OverdueBills
  },

  [cardsTypes.EXPENSES_ANALYSIS]: {
    title: 'Expenses Analysis',
    component: ExpensesAnalysis
  },

  [cardsTypes.BILLABLE_TIME_CARD]: {
    title: 'Time tracking utilization (Hours)',
    component: BillableTimeCard
  },

  [cardsTypes.BILLING_SUMMERY]: {
    title: 'Balances',
    component: BillingSummery
  },

  [cardsTypes.BALANCES]: {
    title: 'Income & Expenses',
    component: Balances
  },

  [cardsTypes.BILLING_HISTORY]: {
    title: 'Invoices',
    component: BillingHistory
  },

  [cardsTypes.PAYMENT]: {
    title: 'Payments',
    component: Payment
  },

  [cardsTypes.PAYMENT_STATUSES]: {
    title: 'Payment Statuses',
    component: PaymentStatuses
  }
};
