import * as casesApi from '../../../api/cases';
import { UsersSelect } from '../../users';

const fetchUsers = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return casesApi.fetchCaseUsers({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

export const CaseUsersSelect = ({ params, ...props }) => {
  return (
    <UsersSelect
      onNeedFetch={fetchUsers(params)}

      {...props}
    />
  );
};
