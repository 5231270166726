import { createContext, useReducer, useEffect, useRef } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useMemoEffect, usePrevious } from '../../../../../helpers/hooks';
import { api } from '../../../../../api';
import * as reminderGroupsApi from '../../../../../api/cases/reminders/groups';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const CaseReminderCaseGroupsContext = createContext(null);

export const CaseReminderCaseGroupsProvider = ({
  children,
  caseId,
  filter: { page, per_page, ...filterProp } = {}
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    filter,
    reminderGroups
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchReminderGroups = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_REMINDER_GROUPS_REQUEST });

    reminderGroupsApi.fetchCaseReminderGroupsForCase(caseId, {
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_REMINDER_GROUPS_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetReminderGroups = (newFilter = {}) => {
    dispatch({ type: types.RESET_REMINDER_GROUPS, payload: newFilter });
    fetchReminderGroups(newFilter);
  };

  const addReminderGroup = (reminderGroup) => {
    dispatch({ type: types.ADD_REMINDER_GROUP, payload: reminderGroup });
  };

  const updateReminderGroup = (reminderGroup) => {
    dispatch({ type: types.UPDATE_REMINDER_GROUP, payload: reminderGroup });
  };

  const deleteReminderGroup = ({ id }) => {
    dispatch({ type: types.DELETE_REMINDER_GROUP, payload: id });
  };

  const providerValue = {
    isFetched,
    isFetching,
    filter,
    reminderGroups,

    // functions
    addReminderGroup,
    updateReminderGroup,
    deleteReminderGroup,
    applyFilter,
    resetReminderGroups
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useMemoEffect(() => {
    resetReminderGroups(filter);
  }, [ prevFilter, filter ]);

  useEffect(() => {
    const newFilter = omit({ ...filter, ...filterProp }, [ 'page' ]);

    if (!isEqual(omit(filter, [ 'page' ]), newFilter)) {
      resetReminderGroups(newFilter);
    }
  }, [ filter, filterProp ]);

  return (
    <CaseReminderCaseGroupsContext.Provider value={providerValue}>
      {children}
    </CaseReminderCaseGroupsContext.Provider>
  );
};
