import { useSelector } from 'react-redux';
import { Box, SvgIcon } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiAccountHeartOutline } from '@mdi/js';
import { ContentCard } from '../../../../../../components/ContentCard';
import { WidgetsActionButtons } from '../../../../../../components/WidgetsActionButtons';
import { MedicalInfo as MedicalInfoComponent } from '../../../../../../components/users/MedicalInfo';
import { MedicalFormsProvider } from '../../../MedicalInfo/MedicalForms/MedicalFormsProvider';

export const MedicalInfo = ({
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { user } = useSelector(({ profile }) => profile);
  const medicalInfoPathname = '/profile/medical-info';
  const initialState = {
    filter: {
      medical_id: user?.medical?.id
    },
    medicalInfoPathname
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      disableContentPaddings
      title="Medical Info"
      leftActions={[
        <Box mr={1}>
          <SvgIcon fontSize="small">
            <Icon path={mdiAccountHeartOutline} />
          </SvgIcon>
        </Box>
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <MedicalFormsProvider initialState={initialState}>
        <MedicalInfoComponent height={height} />
      </MedicalFormsProvider>
    </ContentCard>
  );
};
