import { DiagnoseRow } from '../DiagnosisList/DiagnoseRow';

export const Row = ({ item: report = {}, onSelect, ...props }) => {
  const handleClick = () => {
    onSelect(report);
  };

  return (
    <DiagnoseRow report={report} onClick={handleClick} {...props} />
  );
};
