import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import * as incomesApi from '../../../../../../api/billing';
import { ModalFooter, ModalHeader, ModalBody, ModalContainer } from '../../../../../../components/Modal';
import { billingParticipantsTypesOptions } from '../../../../../../components/billing';
import { Loader } from '../../../../../../components/Loader';
import { IncomeForm, validationSchema } from './IncomeForm';

export const CreateIncomeModal = ({
  DialogProps,
  payload: {
    model = {},
    invoiceID = null,
    parentPage = null,
    incomes = []
  },
  handleModalResolve,
  handleModalReject
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { enqueueSnackbar } = useSnackbar();

  const createIncome = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.name = model?.name;
    values.rate = +values.rate;
    values.invoice_id = invoiceID;
    values.participant_type = values.participant_type?.value || values.participant_type;

    return incomesApi.createIncome(values).then(() => {
      enqueueSnackbar('Income successfully created', { variant: 'success' });
      handleModalResolve();
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Income not created', { variant: 'error' });
    });
  };

  const handleCreateIncome = (values, { isSubmitting, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    if (!values?.participant_id) {
      enqueueSnackbar('Please, choose the participant', { variant: 'warning' });
      setSubmitting(false);

      return;
    }

    if (!values?.time_tracks_id?.length) {
      enqueueSnackbar('Please, choose tracks', { variant: 'warning' });
      setSubmitting(false);

      return;
    }

    values.rate = +values?.rate;
    values.participant_type = values.participant_type?.value || values.participant_type;
    values.name = model?.name;
    values.model_name = model?.name;

    enqueueSnackbar('Income successfully created', { variant: 'success' });
    handleModalResolve(values);
  };

  return (
    <Formik
      initialValues={{
        hours: 0,
        total: null,
        user_id: null,
        rate: null,
        notes: null,
        model: 'case',
        model_id: model?.id,
        caseInfo: {
          ...model,

          file_number: model?.file_number || model?.name
        },
        participant_type: billingParticipantsTypesOptions[0],
        participant_id: null,
        flat_fee: false,
        incomes
      }}
      validationSchema={validationSchema}
      onSubmit={!invoiceID ? handleCreateIncome : createIncome}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="lg" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Create Income
            </ModalHeader>

            <ModalBody disablePaddings={isMobile}>
              <IncomeForm
                model={model}
                parentPage={parentPage}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
