import { makeStyles, List as MuiList, Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { Scrollbars } from '../../../../../../../components/Scrollbars';
import { Card } from './Card';
import { Header } from './Header';
import { Row } from './Row';
import { styles } from './styles';

export const columnsWidths = {
  code: 200,
  description: 300,
  duration: 100,
  claimRequired: 120,
  ordering: 100,
  actions: 148
};

const useStyles = makeStyles(styles);

export const List = ({ types, onToggleActive, onTypeDelete }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        {!types.length ?
          <Box mx={3} my={2}>
            <Typography align="center">No types found</Typography>
          </Box>
          :
          <>
            {!isMobile && <Header />}

            <Scrollbars autoHeight autoHeightMax={400}>
              <MuiList disablePadding className={classes.list}>
                {types.map(({ type, is_current_active }) => isMobile ? (
                  <Card
                    key={type.id}
                    isActive={is_current_active}
                    appointmentType={type}
                    onToggleActive={onToggleActive}
                    onTypeDelete={onTypeDelete}
                  />
                ) : (
                  <Row
                    key={type.id}
                    isActive={is_current_active}
                    appointmentType={type}
                    onToggleActive={onToggleActive}
                    onTypeDelete={onTypeDelete}
                  />
                ))}
              </MuiList>
            </Scrollbars>
          </>
        }
      </div>
    </div>
  );
};
