import { isEqual, omit } from 'lodash';
import { createContext, useRef, useEffect, useReducer, useState } from 'react';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../../api';
import * as appointmentBooksApi from '../../../../../../api/codes/appointments/appointment-books';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { orderDirectionsMap } from '../../../../../../components/FormField';
import { useModal } from '../../../../../../components/ModalsProvider';
import { usePrevious } from '../../../../../../helpers/hooks';
import { orderByOptions } from '../../../CodesProcedure/CodesModifier/MainContent/FiltersBar/orderByOptions';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const AppointmentBooksContext = createContext();

export const AppointmentBooksProvider = ({ filter: filterProp = {}, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { pagination, appointmentBooks, selectedBooksIDs } = state;
  const [ relationsForFilter, setRelationsForFilter ] = useState({
    order_by: orderByOptions[0],
    order_direction: orderDirectionsMap.desc
  });
  const filter = { ...state.filter, ...filterProp };
  const cancelFetch = useRef(() => {});
  const prevFilter = usePrevious(filter);

  const fetchAppointmentBooks = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_APPOINTMENT_BOOKS_REQUEST });

    appointmentBooksApi.fetchAppointmentBooks({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_APPOINTMENT_BOOKS_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchAppointmentBooks({ page: filter.page + 1 });
    }
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetAppointmentBooks = (newFilter) => {
    dispatch({ type: types.RESET_APPOINTMENT_BOOKS, payload: newFilter });

    fetchAppointmentBooks({ page: 1, ...newFilter });
  };

  const toggleActiveAppointmentBook = (appointmentBook) => {
    return appointmentBooksApi.updateAppointmentBook(appointmentBook).then((appointmentBook) => {
      dispatch({ type: types.UPDATE_APPOINTMENT_BOOK, payload: appointmentBook });
    });
  };

  const addBooksToSelected = ((books) => {
    dispatch({ type: types.ADD_BOOKS_TO_SELECTED, payload: books });
  });

  const deleteBooksFromSelected = ((booksIDs) => {
    dispatch({ type: types.DELETE_BOOKS_FROM_SELECTED, payload: booksIDs });
  });

  const toggleBookSelected = (book) => {
    if (selectedBooksIDs.includes(book.id)) {
      deleteBooksFromSelected([ book.id ]);
    } else {
      addBooksToSelected([ book ]);
    }
  };

  const allBooksIsSelected = () => {
    return selectedBooksIDs
      .filter((id) => appointmentBooks.find((book) => book.id === id))
      .length === appointmentBooks.length;
  };

  const toggleAllBooksSelected = () => {
    const booksIDs = appointmentBooks.map(({ id }) => id);

    allBooksIsSelected() ? deleteBooksFromSelected(booksIDs) : addBooksToSelected(appointmentBooks);
  };

  const deleteAppointmentBook = (appointmentBook) => {
    return new Promise((resolve) => {
      openModal(ConfirmationModal, {
        onModalResolved: () => {
          appointmentBooksApi.deleteAppointmentBook(appointmentBook.id).then(() => {
            dispatch({ type: types.DELETE_APPOINTMENT_BOOK, payload: appointmentBook.id });
            enqueueSnackbar(`Appt. book "${appointmentBook.book}" successfully deleted`, { variant: 'success' });
            resolve();
          });
        },

        onModalRejected: resolve
      });
    });
  };

  const providerValue = {
    ...state,
    relationsForFilter,
    filter: {
      ...filter,
      ...pagination
    },

    // functions
    setRelationsForFilter,
    applyFilter,
    resetAppointmentBooks,
    fetchAppointmentBooks,
    loadNextPage,
    toggleActiveAppointmentBook,
    toggleBookSelected,
    allBooksIsSelected,
    toggleAllBooksSelected,
    deleteAppointmentBook
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetAppointmentBooks(newFilter);
    }
  }, [ filter, prevFilter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <AppointmentBooksContext.Provider value={providerValue}>
      {children}
    </AppointmentBooksContext.Provider>
  );
};
