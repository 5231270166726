import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { UserSignature } from '../../../UserSignature';
import {
  KeyboardDatePicker,
  RadioGroup,
  TextField
} from '../../../FormField';

export const Body = ({ disableUserName = false, disableUserSignature = false }) => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSign = () => {
    setFieldValue('patient_signature', currentUser?.signature || null);
    setFieldValue('patient_sign_id', currentUser?.signature?.id || null);
  };

  useEffect(() => {
    if (values.is_cough === 'no') {
      setFieldValue('if_yes', null);
    }

    if (values.is_traveled === 'no') {
      setFieldValue('description', null);
    }
  }, [ values.is_traveled, values.is_cough ]);

  return (
    <>
      <Grid container spacing={isMobile ? 2 : 3}>
        <Grid item xs={12}>
          <Typography variant="h5" color="secondary">
            PLEASE COMPLETE QUESTIONS BELOW:
          </Typography>
        </Grid>

        <Grid item md={4} xs={isMobile ? 12 : 4}>
          <TextField
            required
            disabled={disableUserName}
            name="last_name"
            label="Last name"
          />
        </Grid>

        <Grid item md={4} xs={isMobile ? 12 : 4}>
          <TextField
            required
            disabled={disableUserName}
            name="first_name"
            label="First name"
          />
        </Grid>

        <Grid item md={4} xs={isMobile ? 12 : 4}>
          <KeyboardDatePicker
            disabled={disableUserName}
            outputFormat="YYYY-MM-DD"
            name="dob"
            label="DOB"
            placeholder="MM/DD/YYYY"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography variant="h5">
            Do you have a cough?
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <RadioGroup name="is_cough" style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography variant="h5">
            If yes:
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <RadioGroup name="if_yes" style={{ flexDirection: 'row', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
            <FormControlLabel
              disabled={values.is_cough === 'no'}
              value="Mild"
              label="Mild"
              control={<Radio size="small" />}
            />

            <FormControlLabel
              disabled={values.is_cough === 'no'}
              value="Moderate"
              label="Moderate"
              control={<Radio size="small" />}
            />

            <FormControlLabel
              disabled={values.is_cough === 'no'}
              value="Severe"
              label="Severe"
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography variant="h5">
            Do you have difficulty breathing? (without history of asthma)
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <RadioGroup name="is_difficulty_breathing" style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography variant="h5">
            Do you have chills, loss of taste or smell
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <RadioGroup name="is_chills" style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography variant="h5">
            Do you have or have had a fever in the past 24 hours?
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <RadioGroup name="is_fever" style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography variant="h5">
            Have you traveled in the past 3 months?
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <RadioGroup name="is_traveled" style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography variant="h5">
            If yes, please discribe when and where you traveled to?
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            disabled={values.is_traveled === 'no'}
            disableUnderline
            name="description"
            variant="filled"
            rows={2}
            rowsMax={6}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography variant="h5">
            Have you been to the airport in the past 6 months?
          </Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <RadioGroup name="is_airport" style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
            <FormControlLabel
              value="yes"
              label="Yes"
              control={<Radio size="small" />}
            />

            <FormControlLabel
              value="no"
              label="No"
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Grid>
      </Grid>

      {!disableUserSignature && (
        <UserSignature
          userRole={rolesMap.patient}
          isSignature={!!values?.patient_signature}
          signature={values.patient_signature}
          onSign={handleSign}
        />
      )}
    </>
  );
};
