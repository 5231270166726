import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_SUCCESS]: (
    { filter, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetching: false,
      isFetched: true,
      pagination,
      filter: { ...filter, page, per_page },
      templates: data
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_TEMPLATES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.ADD_TEMPLATE]: (state, payload) => {
    const { pagination, filter, templates, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((templates.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page: page === 0 ? 1 : page },
      templates: [ payload, ...templates ]
    };
  },

  [types.UPDATE_TEMPLATE_IN_LIST]: ({ templates, ...state }, payload) => {
    return {
      ...state,

      templates: templates.map((item) => item.id === payload.id ? payload : item)
    };
  },

  [types.DELETE_TEMPLATE_SUCCESS]: ({ templates, pagination, filter, ...state }, templateId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      templates: templates.filter(({ id }) => id !== templateId)
    };
  },

  [types.TOGGLE_ALL_ITEMS_SELECTION]: ({ templates, selectedIDs, ...state }) => {
    const allItemIsSelected = templates.length === selectedIDs.length;

    return {
      ...state,

      templates,
      selectedIDs: allItemIsSelected ? [] : templates.map((item) => item.id)
    };
  },

  [types.TOGGLE_ITEM_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1 ?
        selectedIDs.filter((item) => item !== id)
        :
        selectedIDs.concat(id)
    };
  },

  [types.MASS_DELETE_TEMPLATES]: ({ templates, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      templates: templates.filter((template) => IDs.indexOf(template.id) < 0)
    };
  }
});
