import { useContext, useEffect } from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { TablePagination } from '../../../../../../components/TablePagination';
import { Loader } from '../../../../../../components/Loader';
import { Cards } from '../../../../../../components/Cards';
import { PaymentsContext } from '../../../../../../components/billing/payments';
import { Card } from './Card';

export const Grid = ({ width = null, hiddenColumns = [] }) => {
  const {
    payments,
    isFetched,
    meta,
    resetPayments
  } = useContext(PaymentsContext);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const size = isMobile
    ? (width ? width || window.innerWidth || !!hiddenColumns.length : 320)
    : 370;

  useEffect(() => {
    resetPayments();
  }, []);

  return (
    <Loader loading={!isFetched} p={3} render={
      () => !meta.total ? (
        <Box p={3}>
          <Typography align="center">No payments found</Typography>
        </Box>
      ) : (
        <>
          <Cards
            size={size}
            list={payments}
            component={Card}
            cardProps={{ hiddenColumns }}
          />
          <TablePagination pagination={meta} onChange={resetPayments} />
        </>
      )}
    />
  );
};
