import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Loader } from '../../../../../components/Loader';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import * as paymentApi from '../../../../../api/billing';
import { InvoiceCard } from '../../../../../components/billing/payments/PaymentModal/InvoiceCard';
import { PaymentsContextProvider } from '../../../../../components/billing/payments';
import { FileDropZoneWindow } from '../../../../../components/FileDropZoneWindow';
import { CurrencyFormat } from '../../../../../components/CurrencyFormat';
import { PageSideBar } from '../../../../../components/PageSideBar';
import { useModal } from '../../../../../components/ModalsProvider';
import { TextField } from '../../../../../components/FormField';
import { FilesFilterContextProvider } from '../../../files-common';
import { InvoiceFileUploadModal } from '../../Invoices/InvoiceFileUploadModal';
import { FilesContextProvider } from '../../FilesContext';
import { InvoiceContent } from '../CreatePayment/InvoiceContent';
import { ActionsBar } from './ActionsBar';
import { InvoicesList } from './InvoicesList';
import { TableHeader } from './TableHeader';
import { FilesList } from './FilesList';
import { Header } from './Header';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const widthBreakpointXS = 800;
export const widthBreakpointSmall = 1150;
export const widthBreakpointMedium = 1350;
export const minRowHeight = 53;
export const columnsWidths = {
  number: 300,
  invoice_date: 150,
  due_on: 170,
  total: 120,
  amount: 170,
  balance: 120,
  action: 52
};

export const PaymentItem = () => {
  const theme = useTheme();
  const rootRef = useRef();
  const classes = useStyles();
  const params = useParams();
  const { openModal } = useModal();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [ payment, setPayment ] = useState(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const [ state, setState ] = useState({
    isLoaded: false,
    overdraft: null
  });

  const initialStateOfFiles = {
    filter: {
      owner_type: 'payment',
      owner_id: +params.id
    }
  };

  useEffect(() => {
    setState({
      isLoaded: false,
      overdraft: null
    });

    paymentApi.fetchPayment(params.id).then((data) => {
      setPayment(data);

      setState({
        isLoaded: true,
        overdraft: data.invoice ? null : 1
      });
    });
  }, [ params.id ]);

  const uploadFiles = (files = []) => () => {
    openModal(InvoiceFileUploadModal, {
      payload: {
        files,
        owner_type: 'payment',
        owner_id: +params.id
      }
    });
  };

  const handleFilesDropped = (files) => {
    uploadFiles(files)();
  };

  return (
    <PaymentsContextProvider filter={{ overdraft: state?.overdraft }}>
      {!state.isLoaded ? <Loader p={2} /> : (
        <PageSideBar
          ref={rootRef}
          title="Payments List"
          SideBarComponent={List}
          ActionsBarComponent={(props) => (
            <ActionsBar
              overdraft={state.overdraft}
              id={payment?.id}
              state={state}
              title={payment?.number}

              {...props}
            />
          )}
        >
          <FilesFilterContextProvider>
            <FileDropZoneWindow onDropAccepted={handleFilesDropped}>
              <Box height="100%" overflow="hidden">
                <Grid
                  container
                  spacing={isMobile ? 1 : 2}
                  component={Box}
                  px={isMobile ? 1 : 3}
                  py={isMobile ? 1 : 2}
                >
                  <Grid item xs={12}>
                    <Typography variant="h2">
                      {payment?.number || '-'}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Box component="span" mr={2}>
                        <Typography variant="h5">Inv. #</Typography>
                      </Box>

                      {payment?.invoice?.number ?
                        <Typography
                          variant="h5"
                          target="_blank"
                          color="textSecondary"
                          component={Link}
                          to={`/billing/invoices/${payment?.invoice?.id}`}
                          className={classes.invoiceLink}
                        >
                          {payment?.invoice?.number}
                        </Typography>
                        :
                        <Typography color="textSecondary">No info.</Typography>
                      }
                    </Box>
                  </Grid>
                </Grid>

                <Header
                  width={width}
                  amount={payment?.amount}
                  method={payment?.method}
                  payment={payment}
                  invoice={payment?.invoice || payment?.causer_invoice || null}
                />

                <Grid
                  container
                  spacing={isMobile ? 1 : 2}
                  component={Box}
                  px={isMobile ? 1 : 3}
                  py={2}
                  overflow="hidden"
                >
                  <Grid item xs={12}>
                    {!isMobile && <TableHeader />}

                    {isMobile ? (
                      <InvoiceCard payment={payment} />
                    ) : (
                      <InvoicesList payment={payment} />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" width={310} py={1} ml="auto">
                      <Typography variant="h5" align="left" className={classes.totalContent}>
                        Amount to apply
                      </Typography>

                      <Typography variant="h5" align="left" className={classes.totalContent}>
                        <CurrencyFormat value={payment?.amount}/>
                      </Typography>
                    </Box>

                    <Box display="flex" width={310} py={1} ml="auto">
                      <Typography variant="h5" align="left" className={classes.totalContent}>
                        Balance to apply
                      </Typography>

                      <Typography variant="h5" align="left" className={classes.totalContent}>
                        {payment?.invoice
                          ? <CurrencyFormat value={payment?.invoice?.balance}/>
                          : <CurrencyFormat value={payment?.causer_invoice?.balance}/>
                        }
                      </Typography>
                    </Box>
                  </Grid>


                  {width < widthBreakpointMedium &&
                    <Grid item xs={12} className={classes.noteContent}>
                      <TextField
                        readOnly
                        disabled
                        fullWidth
                        multiline
                        withoutFormik
                        disableUnderline
                        rows={5}
                        name="note"
                        value={payment.note}
                        variant="filled"
                        label={
                          <div className={classes.noteIcon}>
                            <NotesIcon fontSize="small"/> Note
                          </div>
                        }
                      />
                    </Grid>
                  }

                  <Grid item xs={12} className={classes.footer}>
                    <FilesContextProvider initialState={initialStateOfFiles}>
                      <FilesList payment={payment} />
                    </FilesContextProvider>

                    {width > widthBreakpointMedium &&
                      <div className={classes.noteFooterContent}>
                        <TextField
                          readOnly
                          disabled
                          fullWidth
                          multiline
                          withoutFormik
                          disableUnderline
                          rows={5}
                          name="note"
                          value={payment?.note}
                          variant="filled"
                          label={
                            <div className={classes.noteIcon}>
                              <NotesIcon fontSize="small"/> Note
                            </div>
                          }
                        />
                      </div>
                    }
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Selected invoice
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <InvoiceContent width={width} invoice={payment?.invoice || payment?.causer_invoice} />
                  </Grid>
                </Grid>
              </Box>
            </FileDropZoneWindow>
          </FilesFilterContextProvider>
        </PageSideBar>
      )}
    </PaymentsContextProvider>
  );
};
