import { makeStyles, Dialog, useTheme, useMediaQuery } from '@material-ui/core';
import { ModalBody, ModalContainer, ModalHeader } from '../../../../../../../components/Modal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ImageViewModal = ({
  payload: { imageUrl },
  DialogProps,
  handleModalReject
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
      <ModalContainer>
        <ModalHeader onClose={handleModalReject} />

        <ModalBody>
          <img src={imageUrl} alt=""  className={classes.image} />
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
