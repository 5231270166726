import { makeStyles } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import {
  columnsWidths,
  widthBreakpointSmall,
  widthBreakpointMedium,
  widthBreakpointLarge,
  widthBreakpointExtraLarge
} from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ width }) => {
  const classes = useStyles();
  // const { selectedIDs, allContactsIsSelected, toggleAllContactsSelection } = useContext(ContactsContext);

  return (
    <ListRow header className={classes.root}>
      {/*<ListRowCheckbox*/}
      {/*  onClick={toggleAllContactsSelection}*/}
      {/*  checked={allContactsIsSelected()}*/}
      {/*  indeterminate={!!selectedIDs.length && !allContactsIsSelected()}*/}
      {/*/>*/}

      <ListRowCell grow flexBasis={columnsWidths.name}>
        Contact name
      </ListRowCell>

      {width > widthBreakpointExtraLarge &&
        <ListRowCell flexBasis={columnsWidths.position}>
          Position
        </ListRowCell>
      }

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.role}>
          Role
        </ListRowCell>
      }

      <ListRowCell grow flexBasis={columnsWidths.office_name}>
        Office Name
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell flexBasis={columnsWidths.address}>
          Address
        </ListRowCell>
      }

      {width > widthBreakpointSmall &&
        <ListRowCell flexBasis={columnsWidths.venue}>
          Venue
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.email}>
        Email
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.phone}>
        Phone
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions} />
    </ListRow>
  );
};
