import { useEffect, useRef } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { api } from '../../../../../api';
import * as templatesApi from '../../../../../api/profile';
import { Loader } from '../../../../../components/Loader';
import { PrescriptionsTemplatesSelect } from '../../../../../components/FormField';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../components/Modal';
import { REFILL_OPTIONS } from '../../Settings/PrescriptionsTemplates/CreateTemplateModal';
import { Body } from './Body';
import { validationSchema } from './validationSchema';

export const CreateModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { patient = null }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const cancelFetch = useRef(() => {});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const fetchTemplate = (setValues, setFieldValue) => (option) => {
    cancelFetch.current();

    if (option) {
      templatesApi.fetchPrescriptionTemplate(
        option.value,
        { cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel)) }
      ).then((data) => {
        setValues((values) => ({
          ...values,
          ...data,

          provider_id: data.provider,
          icd_code_ids: data?.icd_codes,
          options: {
            generic_equivalent_acceptable: (
              data?.options?.generic_equivalent_acceptable || 'no'
            ),
            refill: data?.options?.refill || REFILL_OPTIONS
          }
        }));
        setFieldValue('office_id', data.office);
      }).catch(() => {
        enqueueSnackbar('Something goes wrong', { variant: 'error' });
      });
    }
  };

  const createPrescription = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return templatesApi.createPrescription(values).then(({ data }) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      enqueueSnackbar('Not created', { variant: 'error' });
      setErrors(errors);
    });
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <Formik
      initialValues={{
        patient_id: patient,
        date: moment().format('YYYY-MM-DD'),
        name: null,
        provider_id: null,
        office_id: null,
        signature_id: null,
        text: null,
        options: {
          generic_equivalent_acceptable: 'no',
          refill: REFILL_OPTIONS
        }
      }}
      validationSchema={validationSchema}
      onSubmit={createPrescription}
    >
      {({ handleSubmit, setValues, setFieldValue, isSubmitting }) => (
        <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Add New Prescription
            </ModalHeader>

            <ModalBody>
              <PrescriptionsTemplatesSelect onChange={fetchTemplate(setValues, setFieldValue)} />

              <Box mt={4} minHeight={300}>
                <Body />
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
