import { useContext, useRef } from 'react';
import { Paper, Box, useTheme } from '@material-ui/core';
import { useResizeObserver } from '../../../../../../helpers/hooks';
import { filtersKeysMap } from '../../../../../../store/lastFilters';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { PaymentsContextProvider } from '../../../../../../components/billing/payments';
import { ActionsBar } from '../ActionsBar';
import { FiltersBar } from '../FiltersBar';
import { List, columnsMap } from '../List';
import { Grid } from '../Grid';

const hiddenColumns = [ columnsMap.dueOn, columnsMap.total, columnsMap.balance, columnsMap.actions ];

export const Overdraft = ({
  filterKey = filtersKeysMap.billing_payments_overdraft,
  filter= {},
  hiddenFields = []
}) => {
  const rootRef = useRef();
  const theme = useTheme();
  const { width } = useResizeObserver({ ref: rootRef });
  const isTablet = width <= theme.breakpoints.values.md;
  const { viewVariant } = useContext(LayoutContext);
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper
  } = useMobileFilterCollapse('md', width);
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  return (
    <PaymentsContextProvider filter={{ overdraft: 1, ...filter }}>
      <ActionsBar isTablet={isTablet} filterOpenButton={filterOpenButton} />

      <ScrollWrapper {...scrollWrapperProps}>
        <FilterBarWrapper {...filterBarWrapperProps}>
          <FiltersBar hiddenFields={hiddenFields} filterKey={filterKey} />
        </FilterBarWrapper>
      </ScrollWrapper>

      <Paper ref={rootRef} component={Box} minHeight={500}>
        {isTablet || viewVariant === viewVariantsMap.grid ? (
          <Grid width={width} />
        ) : viewVariant === viewVariantsMap.list ? (
          <List hiddenColumns={hiddenColumns} />
        ) : null}
      </Paper>
    </PaymentsContextProvider>
  );
};
