import * as api from '../../../../api/sip';
import { Autocomplete } from '../../Autocomplete';

const fetchSIPNumbers = () => {
  return api.fetchSipNumbers().then(({ items }) => ({ options: items?.map(String) }));
};

export const SipNumbersSelect = (props) => {
  return (
    <Autocomplete
      isAsync
      multiple
      label="Extensions"
      placeholder="Search extension..."
      onNeedFetch={fetchSIPNumbers}

      {...props}
    />
  );
};
