export const filterFieldsMap = {
  caseId: 'case_id',
  number: 'number',
  users: 'users',
  companies: 'companies',
  appointmentId: 'appointment_id',
  claimId: 'claim_id',
  dueFrom: 'due_date_from',
  dueTo: 'due_date_to',
  statusId: 'status_id',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.caseId]: 'Cases',
  [filterFieldsMap.number]: 'Inv Number',
  [filterFieldsMap.users]: 'Users',
  [filterFieldsMap.companies]: 'Companies',
  [filterFieldsMap.appointmentId]: 'Appointment',
  [filterFieldsMap.claimId]: 'Claim',
  [filterFieldsMap.dueFrom]: 'Due From',
  [filterFieldsMap.dueTo]: 'Due To',
  [filterFieldsMap.statusId]: 'Statuses',
  [filterFieldsMap.orderBy]: 'Order by'
};
