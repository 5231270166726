import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  Medications
} from '../../medical/reports/PreviewReportModal/InitialReport/MedicalFormInfo/Medications';
import { stopPropagation } from '../../../helpers/dom';
import { reportTypesMap } from '../../medical/reports/diagnosis/SelectReportTypeModal';
import { MedicalProfileContext } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/MedicalProfileProvider';
import { UserPreviewModal } from '../../users/UserPreviewModal';
import { useModal } from '../../ModalsProvider';
import { NestedList } from '../../NestedList';
import { UserLink } from '../../UserLink';
import { Fab } from '../../Fab';

export const Prescribe = ({ isOpenSwitches = false, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const { openModal } = useModal();
  const history = useHistory();
  const { medicalInfoPathname } = useContext(MedicalProfileContext);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  const handleUpdateReport = () => {
    if (report?.diagnosis_report?.type === reportTypesMap.new) {
      history.push(medicalInfoPathname + `/medical-report/diagnosis/${report?.diagnosis_report?.id}/first-step`);
    } else {
      history.push(medicalInfoPathname + `/medical-report/diagnosis/${report?.diagnosis_report?.id}/edit`);
    }
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Prescribe Medications"
      actionIcon={!!report &&
        <Fab
          size="small"
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={handleUpdateReport}
        >
          Add
        </Fab>
      }
      onOpen={handleOpenListItem}
    >
      <Box p={3}>
        {!report ?
          <Typography align="center" color="textSecondary">No info found</Typography>
          :
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Typography color="textSecondary">Diagnosis report type</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="h5">
                {report?.diagnosis_report?.type || '-'}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography color="textSecondary">Provider</Typography>
            </Grid>

            <Grid item xs={7}>
              <UserLink
                variant="h5"
                user={report?.diagnosis_report?.provider}
                onClick={stopPropagation(openUserPreview(report?.diagnosis_report?.provider?.id))}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom color="textSecondary">Prescribe medications:</Typography>

              {!report?.prescribe_medications?.length ?
                <Typography color="textSecondary">No info.</Typography>
                :
                <Box overflow="hidden">
                  <Medications medications={report?.prescribe_medications} />
                </Box>
              }
            </Grid>
          </Grid>
        }
      </Box>
    </NestedList>
  );
};
