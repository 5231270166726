import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { Contact } from '../../../../../../components/Contact';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.patient_name && (
        <>
          <Grid item xs={4}>
            <Typography>
              Name:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.patient_name}
            </Typography>
          </Grid>
        </>
      )}

      {params?.patient_email && (
        <>
          <Grid item xs={4}>
            <Typography>
              Email:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <Contact
                noWrap
                variant="h5"
                type="mailto"
                contact={params?.patient_email}
              />
            </Box>
          </Grid>
        </>
      )}

      {params?.patient_phone && (
        <>
          <Grid item xs={4}>
            <Typography>
              Phone:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <Contact
                noWrap
                variant="h5"
                type="tel"
                contact={params?.patient_phone}
              />
            </Box>
          </Grid>
        </>
      )}

      {params?.patient_address && (
        <>
          <Grid item xs={4}>
            <Typography>
              Address:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {params?.patient_address}
            </Typography>
          </Grid>
        </>
      )}

      {params?.doi && (
        <>
          <Grid item xs={4}>
            <Typography>
              DOI:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {moment(params?.doi).format('L')}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
