import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { makeStyles, Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { tasksPrioritiesOptions } from '../../../../../dataMaps';
import { setTasksLastGlobalAction } from '../../../../../store/globalActions';
import * as tasksApi from '../../../../../api/tasks';
import * as taskActionTypes from '../../../../../components/tasks/TasksProvider/types';
import { caseInitialValues, TaskPreviewContext } from '../../../../../components/tasks/TasksProvider';
import { IconButton } from '../../../../../components/IconButton';
import {
  TextField,
  transformTaskStatusToOption
} from '../../../../../components/FormField';
import { validationSchema } from '../../CreateTaskModal/validationSchema';
import { Header as StatusBar, RightSidebar, Body } from '../../EditTaskModal';
import { Header } from '../Header';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EditTask = ({ sidebarIsOpen, setSidebarIsOpen, handleEditTask }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { selectedTask, setSelectedTask, caseFields, setCaseFields } = useContext(TaskPreviewContext);
  const duration = selectedTask?.estimated_time && moment.duration(selectedTask?.estimated_time, 'seconds');

  const handleCancel = () => {
    setCaseFields(caseInitialValues);
    handleEditTask();
  };

  const handleToggleSideBar = () => {
    setSidebarIsOpen((state) => !state);
  };

  const updateTask = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const estimated_time = moment.duration(`${values.hours || 0}:${values.minutes || 0}`).asSeconds();
    values.parent_type = values.parent_id && 'case';
    values.users = [ ...caseFields.users, ...selectedTask.users ]?.map(({ id }) => id);

    return tasksApi.updateTask({
      id: selectedTask.id,
      ...values,
      estimated_time: estimated_time > 0 ? estimated_time : null
    }).then((data) => {
      dispatch(setTasksLastGlobalAction({
        type: taskActionTypes.UPDATE_TASK_IN_LIST,
        payload: { ...data, owner_id: values.owner_id }
      }));

      enqueueSnackbar('Task successfully updated', { variant: 'success' });
      setSelectedTask(data);
      setCaseFields(caseInitialValues);
      handleEditTask();
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Task not updated', { variant: 'error' });
      }
    });
  };

  const resetTaskData = (setFieldValue) => () => {
    setFieldValue('name', selectedTask.name);
    setFieldValue('description', selectedTask.description);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...selectedTask,

        name: selectedTask.name,
        owner_id: selectedTask?.owner,
        description: selectedTask.description,
        status_id: transformTaskStatusToOption(selectedTask.status),
        priority: tasksPrioritiesOptions.find(({ value }) => value === selectedTask.priority),
        hours: duration?.hours() || 0,
        minutes: duration?.minutes() || 0,
        due_at: selectedTask.due_at,
        parent_type: (caseFields.parent_type || selectedTask.parent_type) && 'case',
        parent_id: caseFields.parent_id?.id || selectedTask.parent?.id
      }}
      validationSchema={validationSchema}
      onSubmit={updateTask}
    >
      {({ isSubmitting, handleSubmit, setFieldValue, handleBlur }) => (
        <form noValidate onSubmit={handleSubmit} className={classes.form}>
          <Header
            isUpdateCase
            onClose={handleCancel}
            onRestore={resetTaskData(setFieldValue)}
            icon={
              <IconButton color="primary" size="small" onClick={handleToggleSideBar}>
                {sidebarIsOpen
                  ? <ChevronLeftIcon fontSize="small" />
                  : <ChevronRightIcon fontSize="small" />
                }
              </IconButton>
            }
          >
            <TextField
              autoFocus
              name="name"
              margin="dense"
              placeholder="Enter task name..."
              onBlur={handleBlur}
              InputProps={{
                className: classes.input,
                disableUnderline: true
              }}
            />
          </Header>

          <div className={classes.root}>
            <div className={classes.leftSideBar}>
              <StatusBar />
              <Body isStaticPage />
            </div>

            <div className={classes.rightSideBar}>
              <RightSidebar isStaticPage />
            </div>
          </div>

          <div className={classes.submitButton}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Save
            </Button>

            <Button className={classes.cancelBtn} onClick={handleCancel}>Cancel</Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
