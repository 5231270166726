export const PROCEDURES_FETCH_REQUEST = 'PROCEDURES_FETCH_REQUEST';
export const PROCEDURES_FETCH_SUCCESS = 'PROCEDURES_FETCH_SUCCESS';
export const ADD_PROCEDURE = 'ADD_PROCEDURE';
export const UPDATE_PROCEDURE_IN_LIST = 'UPDATE_PROCEDURE_IN_LIST';
export const DELETE_PROCEDURE_SUCCESS = 'DELETE_PROCEDURE_SUCCESS';
export const RESET_PROCEDURES = 'RESET_PROCEDURES';
export const APPLY_FILTER = 'APPLY_FILTER';
export const MASS_DELETE_PROCEDURES = 'MASS_DELETE_PROCEDURES';
export const TOGGLE_PROCEDURE_SELECTION = 'TOGGLE_PROCEDURE_SELECTION';
export const TOGGLE_ALL_PROCEDURES_SELECTION = 'TOGGLE_ALL_PROCEDURES_SELECTION';
