export const styles = ({
  spacing,
  palette: { primary, grey, lightgreen },
  typography: { fontWeightMedium },
  shape: { borderRadius },
  breakpoints
}) => ({
  adjNumberContent: {
    marginBottom: spacing(3)
  },

  adjNumberContent__list: {
    padding: spacing(2),
    border: `1px dashed ${lightgreen}`,
    borderRadius
  },

  adjContent: {
    marginLeft: spacing(2)
  },

  contentRow: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: spacing(2),
    padding: spacing(1, 1, 2, 2),
    backgroundColor: primary.contrastText,

    [breakpoints.down('md')]: {
      padding: 0
    }
  },

  addButton: {
    marginLeft: spacing(2)
  },

  toggleAdjButton: {
    marginLeft: spacing(2)
  },

  defaultText: {
    fontWeight: fontWeightMedium,
    marginRight: spacing()
  },

  notesIcon: {
    display: 'flex',
    alignItems:'center',
    color: grey[600],
    fontWeight: fontWeightMedium
  }
});
