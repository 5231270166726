export const getFormData = (values) => {
  const formData = new FormData();

  const appendData = (values, pathPrefix = '') => {
    Object.entries(values).forEach(([ key, value ]) => {
      const path = pathPrefix ? `${pathPrefix}[${key}]` : key;

      if (value?.constructor === Object) {
        appendData(value, path);
      } else if (typeof value === 'boolean') {
        formData.append(path, +value);
      } else if (value !== null && value !== undefined) {
        formData.append(path, value);
      }
    });
  };

  appendData(values);

  return formData;
};
