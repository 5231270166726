export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { fontWeightMedium },
  shape: { borderRadius }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    border: `1px solid ${palette.divider}`,
    borderRadius,
    overflow: 'hidden'
  },

  filePreview: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    cursor: 'pointer'
  },

  filePreview__image: {
    alignSelf: 'flex-start'
  },

  filePreview__icon: {
    fontSize: 80
  },

  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '2.5rem',
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
    paddingLeft: spacing(1.5),
    paddingRight: 0,
    backgroundColor: grey[100],
    color: grey[500]
  },

  fileInfo__icon: {
    fontSize: 30
  },

  fileInfo__name: {
    flexGrow: 1,
    marginLeft: spacing(),
    fontWeight: fontWeightMedium
  }
});
