export const styles = ({
  spacing,
  breakpoints,
  palette: { grey, darkgreen },
  typography: { fontWeightMedium },
  shape: { borderRadius }
}) => ({
  form: {
    display: 'flex',
    height: '100%'
  },

  mainInfo: {
    marginBottom: spacing(10),

    [breakpoints.down(breakpoints.values.lg)]: {
      marginBottom: spacing(5)
    },

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      marginBottom: spacing(2)
    }
  },

  loader: {
    paddingTop: spacing(2)
  },

  incomes: {
    marginBottom: spacing(3),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      marginBottom: spacing(1)
    }
  },

  expenses: {
    marginBottom: spacing(3),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      marginBottom: spacing(1)
    }
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: spacing(4),

    [breakpoints.down(breakpoints.values.lg)]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginBottom: spacing(2),
      width: '100%'
    }
  },

  note: {
    flexGrow: 1,

    [breakpoints.down(breakpoints.values.lg)]: {
      width: '100%'
    }
  },

  total: {
    width: 384,
    minHeight: 145,
    marginTop: spacing(2),
    padding: spacing(2),
    border: `1px dashed ${darkgreen}`,
    borderRadius,

    [breakpoints.down(breakpoints.values.lg)]: {
      width: '100%',
      minHeight: 80
    }
  },

  attachmentContentHeader: {
    backgroundColor: grey[100]
  },

  attachmentContentTitle: {
    textTransform: 'uppercase',
    fontWeight: fontWeightMedium,
    color: grey[600]
  },

  handleButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },

  actionAddButton: {
    marginLeft: spacing(2)
  },

  actionAddButtonLabel: {
    paddingRight: spacing(),
    paddingLeft: spacing()
  }
});
