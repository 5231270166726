import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import cn from 'classnames';
import { makeStyles, SvgIcon, Typography, Button } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/PhotoCameraOutlined';
import TheatersIcon from '@material-ui/icons/Theaters';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideoOutlined';
import AudiotrackIcon from '@material-ui/icons/AudiotrackOutlined';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import { downloadFile as download } from '../../../../helpers/files';
import { amFormatDuration } from '../../../../helpers/dates';
import { useModal } from '../../../../components/ModalsProvider';
import * as videoApi from '../../../../api/videos';
import { Loader } from '../../../../components/Loader';
import FragmentIcon from '../../../../components/icons/video-icon.svg';
import { CreateFragmentModal } from './CreateFragmentModal';
import { FragmentsContextProvider } from './FragmentsContext';
import { ScreenshotsContextProvider } from './ScreenshotsContext';
import { FragmentsGrid } from './FragmentsGrid';
import { ScreenshotsGrid } from './ScreenshotsGrid';
import { Notes } from './Notes';

import { styles } from './styles';

const useStyles = makeStyles(styles);

export const VideosItem = ({ match: { params } }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const [ video, setVideo ] = useState();
  const [ isFetched, setIsFetched ] = useState(false);
  const [ isShot, setIsShot ] = useState(false);
  const [ lastCreatedFragment, setLastCreatedFragment ] = useState(null);
  const [ lastCreatedScreenshot, setLastCreatedScreenshot ] = useState(null);
  const [ time, setTime ] = useState();
  const [ endDuration, setEndDuration ] = useState();
  const [ playerConfig, setPlayerConfig ] = useState({
    pip: true,
    playing: false,
    volume: 1,
    duration: 0,
    playedSeconds: 0
  });

  const createFragment = () => {
    openModal(CreateFragmentModal, {
      onModalResolved: (fragment) => {
        setLastCreatedFragment(fragment);
      },
      payload: {
        video,
        endDuration
      }
    });
  };

  const createScreenshot = () => {
    const dataScreenshots = { time: amFormatDuration(time) };

    handlePause();

    videoApi.createScreenshot(params.id, dataScreenshots).then((data) => {
      setIsShot(true);
      setLastCreatedScreenshot(data);

      setTimeout(() => {
        setIsShot(false);
      }, 800);
    });
  };

  const createAudio = () => {
    videoApi.createAudio(params.id, {}).then((data) => {
      download({ src: data.url, name: data.original_filename });
    });
  };

  const handlePlay = () => {
    setPlayerConfig({ playing: true });
  };

  const handleDuration = (seconds) => {
    setPlayerConfig({ duration: Math.floor(seconds) });
    setEndDuration(Math.floor(seconds));
  };

  const handleClickOnSeconds = () => {
    setPlayerConfig({ playing: false });
  };

  const handlePause = (seconds) => {
    setPlayerConfig({ playing: false });
    setTime(Math.floor(seconds));
  };

  const handleSeekChange = (seconds) => {
    setPlayerConfig({ seeking: false, playing: false, duration: Math.floor(seconds) });
    setTime(Math.floor(seconds));
  };

  const { playing, pip } = playerConfig;

  useEffect(() => {
    videoApi.fetchVideo(params.id).then(({ data }) => {
      setVideo(data);
      setIsFetched(true);
    });
  }, []);

  return (
    <div className={classes.root}>
      <Loader
        className={classes.loader}
        loading={!isFetched}
        render={
          () => (
            <>
              <div className={classes.videoItem}>
                <div className={classes.videoTitle}>
                  <Typography className={classes.videoTitle__name}>
                    {video.original_filename}
                  </Typography>

                  <Typography className={classes.videoTitle__subName}>
                    {video.video_fragments_count} fragments
                  </Typography>
                </div>

                <div className={classes.videoContent}>
                  <ReactPlayer
                    controls
                    light
                    url={video.url}
                    pip={pip}
                    playing={playing}
                    width="100%"
                    height="100%"
                    className={
                      cn(
                        classes.videoPlayer,
                        isShot && classes.videoPlayer_active
                      )
                    }
                    onPlay={handlePlay}
                    onPause={handlePause}
                    onDuration={handleDuration}
                    onSeek={handleSeekChange}
                  />
                </div>

                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={createFragment}
                  >
                    <TheatersIcon className={classes.icon}/>
                    VIDEO FRAGMENTS
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={createAudio}
                  >
                    <AudiotrackIcon className={classes.icon}/>
                    EXTRACT AUDIO
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={createScreenshot}
                  >
                    <AspectRatioIcon className={classes.icon}/>
                    MAKE SCREENSHOT
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <FeaturedVideoIcon className={classes.icon}/>
                    CONVERT
                  </Button>
                </div>

                <Notes video={video} onClickSecond={handleClickOnSeconds} seconds={time}/>
              </div>

              <div className={classes.videoItem}>
                <div className={classes.videoItemContent}>
                  <div className={classes.videoItemTitle}>
                    <CameraIcon className={classes.videoItemTitle__icon}/>

                    <Typography variant="h3" className={classes.videoItemTitle__title}>
                      Screenshots
                    </Typography>
                  </div>

                  <ScreenshotsContextProvider lastCreatedScreenshot={lastCreatedScreenshot} video={video}>
                    <ScreenshotsGrid/>
                  </ScreenshotsContextProvider>
                </div>

                <div className={classes.videoItemContent}>
                  <div className={classes.videoItemTitle}>
                    <SvgIcon className={cn(classes.videoItemTitle__icon, classes.fragmentIcon)}>
                      <FragmentIcon/>
                    </SvgIcon>

                    <Typography variant="h3" className={classes.videoItemTitle__title}>
                      Video fragments
                    </Typography>
                  </div>

                  <FragmentsContextProvider lastCreatedFragment={lastCreatedFragment} video={video}>
                    <FragmentsGrid/>
                  </FragmentsContextProvider>
                </div>
              </div>
            </>
          )}
      />
    </div>
  );
};
