export const styles = ({ palette: { grey, ...palette } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    border: `1px solid ${palette.divider}`,
    overflow: 'hidden',
    cursor: 'pointer'
  },

  grow: {
    flexGrow: 1
  }
});
