import { transformToOption } from '../../../../../../helpers/fetchDataForAsyncSelect';
import { orderByOptions } from '../../../../../../components/claims';

export const transformRelationsForFilterToOptions = (relationsForFilter) => {
  const orderByValue = relationsForFilter?.order_by?.value ?? relationsForFilter?.order_by;

  return {
    ...relationsForFilter,

    statuses: relationsForFilter?.statuses?.map(transformToOption),
    order_by: orderByOptions.find(({ value }) => value === orderByValue)
  };
};
