import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { Contact as ContactPhone } from '../../../../../../../../components/Contact';
import { VerticalDivider } from '../../../../../../../../components/VerticalDivider';

export const UserInfo = ({ user }) => {
  return (
    <Box display="flex" alignItems="center">
      <ContactPhone
        needIcon
        variant="caption"
        color="textSecondary"
        type="tel"
        contact={user.phone}
      />

      {user.dob &&
        <>
          <VerticalDivider horizontalGutters={0.5} verticalGutters={0.5} />
          <Typography variant="caption">DOB:&nbsp;</Typography>
          <Typography variant="caption" color="textSecondary">
            {moment(user.dob).format('L')}
          </Typography>
        </>
      }
    </Box>
  );
};
