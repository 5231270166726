export const styles = ({ spacing }) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    paddingTop: spacing(4),
    paddingBottom: spacing(3),
    paddingLeft: spacing(3),
    paddingRight: spacing()
  }
});
