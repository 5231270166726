import { Allergies, Medications } from '../../../Diagnosis/steps';
import { CervicalSpine, LumbarSpine } from '../../../Diagnosis/steps';
import { sectionsKeysMap } from '../sectionsOptions';
import {
  ChiefComplaint,
  GeneralAppearence,
  HistoryIllness,
  PastMedicalHistory,
  PastSurgicalHistory,
  PersonalHistory,
  Assessment,
  DiagnosticStudies,
  Impression,
  TreatmentPlan,
  Discussion,
  MedicalCausation,
  CurrentSimptomsBack,
  CurrentSimptomsNeck
} from './selectedComponents';

export const templateComponents = {
  [sectionsKeysMap.chief_complaint]: ChiefComplaint,
  [sectionsKeysMap.history_of_present_illness]: HistoryIllness,
  [sectionsKeysMap.past_medical_history]: PastMedicalHistory,
  [sectionsKeysMap.medications]: Medications,
  [sectionsKeysMap.allergies]: Allergies,
  [sectionsKeysMap.personal_history]: PersonalHistory,
  [sectionsKeysMap.past_surgical_history]: PastSurgicalHistory,
  [sectionsKeysMap.cervical]: CervicalSpine,
  [sectionsKeysMap.lumbar]: LumbarSpine,
  [sectionsKeysMap.current_symptoms_back]: CurrentSimptomsBack,
  [sectionsKeysMap.current_symptoms_neck]: CurrentSimptomsNeck,
  [sectionsKeysMap.general_appearance]: GeneralAppearence,
  [sectionsKeysMap.diagnostic_studies]: DiagnosticStudies,
  [sectionsKeysMap.assessment]: Assessment,
  [sectionsKeysMap.impression]: Impression,
  [sectionsKeysMap.treatment_plan]: TreatmentPlan,
  [sectionsKeysMap.discussion]: Discussion,
  [sectionsKeysMap.medical_causation]: MedicalCausation
};
