export const styles = ({ spacing, palette: { divider }, breakpoints }) => ({
  root: {
    boxShadow: 'none'
  },

  tabPanel: {
    overflow: 'auto',
    padding: spacing(2, 3),
    borderBottom: `1px dashed ${divider}`
  },

  tabPanelActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(2, 3),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      padding: spacing(1),
      flexWrap: 'wrap'
    }
  }
});
