import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { columnsWidths } from '../PrintersConfigurationsList';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          Printer configuration name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.desc}>
          Description
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.directory}>
          Directory
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.date}>
          Date of created
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.staff}>
          Staff
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.manager}>
          Manager
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
