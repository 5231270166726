export const styles = ({ spacing, palette: { grey, ...palette } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  header: {
    display: 'flex',
    backgroundColor: grey[600],
    color: palette.common.white
  },

  list: {
    paddingTop: spacing(),
    paddingBottom: spacing()
  },

  filesNotFound: {
    padding: spacing(1, 2)
  }
});
