export const styles = () => ({
  root: {
    maxWidth: ({ maxWidth }) => maxWidth || '100%'
  }
});

export const getOverriderStyles = ({ zIndex }) => ({
  input: (provided) => ({
    ...provided,
    margin: 0,
    flexGrow: 1,

    '& > div': {
      display: 'block !important'
    }
  }),

  menuPortal: (provided) => ({
    ...provided,
    zIndex: zIndex.modal
  })
});
