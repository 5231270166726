export const CASES_FETCH_REQUEST = 'CASES_FETCH_REQUEST';
export const APPLY_FILTER = 'APPLY_FILTER';
export const CASES_FETCH_SUCCESS = 'CASES_FETCH_SUCCESS';
export const ADD_CASE = 'ADD_CASE';
export const UPDATE_CASE = 'UPDATE_CASE';
export const UPDATE_CASE_IN_LIST = 'UPDATE_CASE_IN_LIST';
export const DELETE_CASE_SUCCESS = 'DELETE_CASE_SUCCESS';
export const RESET_CASES = 'RESET_CASES';
export const TOGGLE_CASE_SELECTION = 'TOGGLE_CASE_SELECTION';
export const ADD_CASES_TO_SELECTED = 'ADD_CASES_TO_SELECTED';
export const DELETE_CASES_FROM_SELECTED = 'DELETE_CASES_FROM_SELECTED';
