import { InputAdornment } from '@material-ui/core';
import { NumberMaskField } from '../NumberMaskField';

export const CurrencyField = (props) => {
  return (
    <NumberMaskField
      thousandSeparator
      fixedDecimalScale
      allowNegative={false}
      decimalScale={2}
      placeholder="0.00"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">$</InputAdornment>
        )
      }}

      {...props}
    />
  );
};
