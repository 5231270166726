import { Box, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';

export const Symptoms = ({ name, symptoms }) => {
  return (
    <Box pt={1}>
      <Typography gutterBottom color="textSecondary">{symptoms?.[name]?.text}</Typography>

      {!!symptoms?.[name]?.location &&
        <Box my={2}>
          <Typography gutterBottom variant="h5">Location:</Typography>

          <Box display="flex" alignItems="flex-start">
            <NotesIcon fontSize="small" />

            <Box mr={1.5} ml={0.5}>
              <Typography variant="subtitle2">
                Note
              </Typography>
            </Box>

            <Typography color="textSecondary">
              {symptoms?.[name]?.location}
            </Typography>
          </Box>
        </Box>
      }

      {!!symptoms?.[name]?.character_of_pain &&
        <Box my={2}>
          <Typography gutterBottom variant="h5">Character of Pain:</Typography>

          <Box display="flex" alignItems="flex-start">
            <NotesIcon fontSize="small" />

            <Box mr={1.5} ml={0.5}>
              <Typography variant="subtitle2">
                Note
              </Typography>
            </Box>

            <Typography color="textSecondary">
              {symptoms?.[name]?.character_of_pain}
            </Typography>
          </Box>
        </Box>
      }

      {!!symptoms?.[name]?.radiation &&
        <Box my={2}>
          <Typography gutterBottom variant="h5">Radiation:</Typography>

          <Box display="flex" alignItems="flex-start">
            <NotesIcon fontSize="small" />

            <Box mr={1.5} ml={0.5}>
              <Typography variant="subtitle2">
                Note
              </Typography>
            </Box>

            <Typography color="textSecondary">
              {symptoms?.[name]?.radiation}
            </Typography>
          </Box>
        </Box>
      }

      {!!symptoms?.[name]?.weakness &&
        <Box my={2}>
          <Typography gutterBottom variant="h5">Weakness:</Typography>

          <Box display="flex" alignItems="flex-start">
            <NotesIcon fontSize="small" />

            <Box mr={1.5} ml={0.5}>
              <Typography variant="subtitle2">
                Note
              </Typography>
            </Box>

            <Typography color="textSecondary">
              {symptoms?.[name]?.weakness}
            </Typography>
          </Box>
        </Box>
      }

      {!!symptoms?.[name]?.stiffness &&
        <Box my={2}>
          <Typography gutterBottom variant="h5">Stiffness:</Typography>

          <Box display="flex" alignItems="flex-start">
            <NotesIcon fontSize="small" />

            <Box mr={1.5} ml={0.5}>
              <Typography variant="subtitle2">
                Note
              </Typography>
            </Box>

            <Typography color="textSecondary">
              {symptoms?.[name]?.stiffness}
            </Typography>
          </Box>
        </Box>
      }

      {!!symptoms?.[name]?.tingling_and_numbness &&
        <Box my={2}>
          <Typography gutterBottom variant="h5">Tingling and Numbness:</Typography>

          <Box display="flex" alignItems="flex-start">
            <NotesIcon fontSize="small" />

            <Box mr={1.5} ml={0.5}>
              <Typography variant="subtitle2">
                Note
              </Typography>
            </Box>

            <Typography color="textSecondary">
              {symptoms?.[name]?.tingling_and_numbness}
            </Typography>
          </Box>
        </Box>
      }

      {!!symptoms?.[name]?.note &&
        <Box mt={4} mb={2}>
          <Box display="flex" alignItems="flex-start" mb={1}>
            <Box component="span" mr={0.5}>
              <NotesIcon fontSize="small" />
            </Box>

            <Typography variant="h5">Note</Typography>
          </Box>

          <Typography color="textSecondary">
            {symptoms?.[name]?.note}
          </Typography>
        </Box>
      }
    </Box>
  );
};
