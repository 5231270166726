import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { fetchCaseRemindersStatistics } from '../../../../../api/cases/reminders/case-reminders';
import { Loader } from '../../../../../components/Loader';
import { Indicator } from './Indicator';

export const Indicators = () => {
  const [ indicators, setIndicators ] = useState(null);

  useEffect(() => {
    fetchCaseRemindersStatistics().then((indicators) => {
      setIndicators(indicators);
    });
  }, []);

  return !indicators ? <Loader /> : (
    <Grid container spacing={3}>
      <Grid item xs>
        <Indicator
          label="Today"
          amount={indicators.today}
          color="info"
        />
      </Grid>

      <Grid item xs>
        <Indicator
          label="Upcoming in 7 days"
          amount={indicators.upcoming}
          color="warning"
        />
      </Grid>

      <Grid item xs>
        <Indicator
          label="Notifying"
          amount={indicators.notifying}
          color="success"
        />
      </Grid>

      <Grid item xs>
        <Indicator
          label="Expired"
          amount={indicators.expired}
          color="error"
        />
      </Grid>
    </Grid>
  );
};
