import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { MessageContext } from '../../../../../../../components/chat';
import { UserAvatar } from '../../../../../../../components/users';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const MessageAvatar = () => {
  const classes = useStyles();
  const { isAvatarShown, isCurrentUser, message } = useContext(MessageContext);

  return !isCurrentUser && isAvatarShown && (
    <div className={classes.avatar}>
      <UserAvatar disableOnlineIndicator user={message.user} size="lg" />
    </div>
  );
};
