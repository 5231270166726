import { api } from '../api';

export const fetchSpecialProgramCodes = (config) => {
  return api.get('/special-program-codes', config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const fetchSpecialProgramCode = (id, config) => {
  return api.get(`/special-program-codes/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createSpecialProgramCode = (data) => {
  return api.post('/special-program-codes', data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateSpecialProgramCode = ({ id, ...data }) => {
  return api.put(`/special-program-codes/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteSpecialProgramCode = (id) => {
  return api.delete(`/special-program-codes/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
