import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as appointmentTypesApi from '../../../../../api/codes/appointments/appointment-types';
import * as testsApi from '../../../../../api/profile';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { AppointmentForm, apptInitialValues } from '../../../../../components/appointments';
import { Loader } from '../../../../../components/Loader';
import { testInitialValues, TestsBody, testsInitialValues } from './TestsBody';
import { bulkValidationSchema } from './bulkValidationSchema';
import { validationSchema } from './validationSchema';
import { TestsBodySingle } from './TestsBodySingle';
import { ApptInfo } from './ApptInfo';

export const CreateTestModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    appointmentTypeID,
    apptValues = null,
    initialValues = {},
    insurance = {},
    onUpdate = () => {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ appointmentType, setAppointmentType ] = useState(null);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const defaultInitialValues = apptValues ? {
    ...testsInitialValues,

    patient_id: initialValues?.patient_id,
    appointment_id: { id: apptValues?.appointment_id, ...apptValues },
    approved_id: apptValues?.approved_id,
    office_id: apptValues?.office
  } : {
    ...apptInitialValues,
    ...initialValues,

    patient_insurance_pcp: insurance?.pcp,
    patient_insurance_spc: insurance?.spc,
    insurance_details: {
      company: insurance?.insurance_company
    },
    appointment_type_id: appointmentType,
    appointment_type: appointmentType,
    time: appointmentType?.time || 0,
    lab_tests: [ testInitialValues ]
  };

  const createTest = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const items = values.items.filter((child) => !!child?.is_checked);

    if (values?.items?.length && !items.length) {
      enqueueSnackbar('Components are required', { variant: 'warning' });
      setSubmitting(false);
    } else {
      return testsApi.createTestReport({ ...values, items }).then(({ data }) => {
        enqueueSnackbar('Successfully created', { variant: 'success' });
        onUpdate();
        handleModalResolve(data);
      }).catch(({ errors }) => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });

        if (errors) {
          setErrors(errors);
        }
      });
    }
  };

  const massCreateTests = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.lab_tests = values.lab_tests.map((test) => ({
      ...test,
      items: test.items.filter((child) => !!child?.is_checked)
    }));

    return testsApi.massCreateTestReports(values).then(({ data }) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      onUpdate();
      handleModalResolve(data);
    }).catch(({ errors }) => {
      enqueueSnackbar('Something went wrong', { variant: 'error' });

      if (errors) {
        setErrors(errors);
      }
    });
  };

  useEffect(() => {
    appointmentTypeID && appointmentTypesApi.fetchAppointmentType(appointmentTypeID).then(setAppointmentType);
  }, []);

  return (
    <Dialog fullScreen={isTablet} PaperProps={{ style: { maxWidth: 1100 } }} {...DialogProps}>
      <Loader p={3} loading={!appointmentType} render={
        () => (
          <Formik
            enableReinitialize
            initialValues={defaultInitialValues}
            validationSchema={!apptValues ? bulkValidationSchema : validationSchema}
            onSubmit={!apptValues ? massCreateTests : createTest}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarPlus} /></SvgIcon>} onClose={handleModalReject}>
                  Add New Test
                </ModalHeader>

                <ModalBody>
                  {apptValues ?
                    <>
                      <ApptInfo />
                      <TestsBodySingle />
                    </>
                    :
                    <>
                      <AppointmentForm appointmentType={appointmentType} />
                      <TestsBody />
                    </>
                  }
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
