import Color from 'color';

const buttonSmallHeight = 24;
const buttonMediumHeight = 30;
const buttonLargeHeight = 36;
const buttonSmallMinWidth = 24;
const buttonMediumMinWidth = 30;
const buttonLargeMinWidth = 36;

export const overrides = ({
  spacing,
  palette: { background, grey, ...palette },
  shape: { borderRadius },
  typography: { pxToRem, fontWeightMedium, fontWeightNormal }
}) => ({
  MuiAppBar: {
    root: {
      borderBottom: `1px solid ${palette.divider}`
    },

    colorSecondary: {
      backgroundColor: background.default,
      color: palette.action.active
    },

    colorDefault: {
      color: palette.action.active
    }
  },

  MuiAlert: {
    root: {
      fontSize: pxToRem(14)
    }
  },

  MuiDivider: {
    root: {
      backgroundColor: grey[300]
    }
  },

  MuiFormControl: {
    root: {
      minWidth: 140,
      maxWidth: '100%'
    }
  },

  MuiFormControlLabel: {
    label: {
      flexGrow: 1
    }
  },

  MuiFormLabel: {
    root: {
      fontSize: pxToRem(14),
      fontWeight: fontWeightMedium,
      color: palette.text.primary,

      '&$focused': {
        color: null
      }
    }
  },

  MuiInputBase: {
    root: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(21)
    },

    input: {
      height: null,
      minHeight: '1.1876em',
      textOverflow: 'ellipsis',
      padding: spacing(0.5, 0, 0.5)
    }
  },

  MuiInput: {
    input: {
      textAlign: 'left'
    },

    underline: {
      '&:before': {
        borderBottomColor: `${grey[300]}`
      }
    }
  },

  MuiInputLabel: {
    root: {
      color: palette.text.secondary,
      fontWeight: fontWeightNormal,

      '&$focused': {
        color: palette.primary.main
      },

      '&$error': {
        color: palette.error.main
      }
    }
  },

  MuiInputAdornment: {
    root: {
      height: '100%',
      maxHeight: '1.25em'
    }
  },

  MuiFilledInput: {
    multiline: {
      paddingTop: 12,
      marginTop: 5,
      borderRadius
    }
  },

  MuiToolbar: {
    regular: {
      '@media (min-width: 600px)': {
        minHeight: 50
      }
    }
  },

  MuiDialog: {
    paper: {
      borderRadius: borderRadius
    },

    paperWidthMd: {
      maxWidth: 900
    },

    paperWidthXs: {
      maxWidth: 340
    }
  },

  MuiDialogContent: {
    root: {
      overflowY: null,

      '&:first-child': null
    }
  },

  MuiDialogActions: {
    root: {
      '& > *': {
        extend: 'spacing'
      }
    }
  },

  MuiFab: {
    root: {
      width: null,
      height: buttonLargeHeight,
      minWidth: buttonLargeMinWidth,
      backgroundColor: grey[100],

      '&:hover': {
        backgroundColor: grey[300]
      },

      '&, &:hover, &:active, &:focus': {
        boxShadow: 'none'
      }
    },

    label: {
      lineHeight: 1
    },

    sizeSmall: {
      width: null,
      height: buttonSmallHeight,
      minHeight: buttonSmallHeight,
      fontSize: pxToRem(12),
      minWidth: buttonSmallMinWidth,

      '& > $label > svg': {
        fontSize: pxToRem(16)
      }
    },

    sizeMedium: {
      width: null,
      height: buttonMediumHeight,
      minHeight: buttonMediumHeight,
      minWidth: buttonMediumMinWidth
    },

    extended: {
      width: null,
      height: null,

      '&$sizeSmall': {
        height: buttonSmallHeight,
        padding: spacing(0, 1),
        minWidth: buttonSmallMinWidth,

        '& * > * > *:first-child ': {
          fontSize: pxToRem(16)
        }
      },

      '&$sizeMedium': {
        height: buttonMediumHeight,
        padding: spacing(0, 2),

        minWidth: buttonMediumMinWidth
      }
    }
  },

  MuiTabs: {
    root: {
      minHeight: 0
    },

    scrollButtons: {
      '&:not(.MuiButtonBase-root)': {
        width: 0
      }
    },

    indicator: {
      height: 4
    }
  },

  MuiTab: {
    root: {
      minWidth: '0 !important',
      paddingLeft: spacing(3),
      paddingRight: spacing(3)
    },

    labelIcon: {
      minHeight: null,
      paddingTop: null,

      '& $wrapper > *:first-child': {
        marginBottom: null,
        marginRight: spacing(1.5)
      }
    },

    wrapper: {
      flexDirection: 'row'
    },

    textColorPrimary: {
      color: grey[600]
    }
  },

  MuiListItem: {
    button: {
      '&:focus, &$selected, &$selected:hover, &$selected:focus': {
        background: Color(palette.primary.light).alpha(0.2).string(),
        color: `${palette.primary.main}`
      },

      // Style for svg in ListItemIcon, and svg in MuiInputAdornment
      [`
        &:focus > div,
        &$selected > div,
        &$secondaryAction$selected + div,
        &$secondaryAction:focus + div
      `]: {
        color: 'inherit'
      }
    }
  },

  MuiListItemAvatar: {
    root: {
      minWidth: 0
    }
  },

  MuiListItemText: {
    root: {
      paddingLeft: spacing(1.25),
      paddingRight: spacing(1.25)
    },

    primary: {
      color: 'inherit',
      fontWeight: 'inherit'
    }
  },

  MuiListItemIcon: {
    root: {
      minWidth: 0,
      marginRight: spacing(),
      color: palette.text.primary,
      fontSize: 22
    }
  },

  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: null,
        borderRadius: null
      }
    }
  },

  MuiCircularProgress: {
    root: {
      display: 'block',
      margin: 'auto',
      borderRadius: '50%'
    }
  },

  MuiButton: {
    root: {
      color: null
    },

    contained: {
      color: null,
      backgroundColor: null,

      '&, &:hover, &:active, &:focus': {
        boxShadow: 'none'
      },

      '&$disabled': {
        backgroundColor: grey[200]
      }
    },

    textSizeSmall: {
      padding: spacing(0.5, 1.25)
    },

    containedSizeSmall: {
      padding: spacing(0.5, 1.25)
    }
  },

  MuiToggleButton: {
    root: {
      lineHeight: null
    }
  },

  MuiIconButton: {
    root: {
      padding: spacing()
    },

    sizeSmall: {
      padding: spacing(0.5),

      '&$edgeStart': {
        marginRight: spacing(0.5)
      },

      '&$edgeEnd': {
        marginRight: spacing(-0.5)
      }
    },

    edgeStart: {
      marginLeft: spacing(-1)
    },

    edgeEnd: {
      marginRight: spacing(-1)
    }
  },

  MuiSwitch: {
    switchBase: {
      margin: 0
    }
  },

  MuiRadio: {
    root: {
      margin: 0
    }
  },

  MuiChip: {
    root: {
      maxWidth: '100%',
      height: 'auto',
      minHeight: 28,
      backgroundColor: grey[200],
      color: grey[500],

      '& $avatar': {
        marginLeft: spacing(0.25)
      }
    },

    avatar: {
      marginLeft: 0
    },

    label: {
      whiteSpace: 'normal'
    },

    icon: {
      fontSize: '1rem'
    },

    deleteIcon: {
      marginRight: 1
    }
  },

  MuiTableRow: {
    hover: {
      cursor: 'pointer'
    }
  },

  MuiTableCell: {
    root: {
      borderBottomColor: grey[200]
    }
  },

  MuiTablePagination: {
    root: {
      overflow: null
    }
  },

  MuiPickersModal: {
    dialog: {
      display: 'flex',
      flexDirection: 'column'
    }
  },

  MuiPickersBasePicker: {
    pickerView: {
      height: '100%',
      minHeight: 0,
      overflowX: 'hidden',
      overflowY: 'auto',
      justifyContent: 'flex-start'
    }
  },

  MuiPickersCalendarHeader: {
    iconButton: {
      '& > *': {
        backgroundColor: 'none'
      }
    }
  },

  MuiPickersToolbarText: {
    'toolbarTxt': {
      'h3&': {
        fontSize: pxToRem(48),
        lineHeight: 1.04
      },

      'h4&': {
        fontSize: pxToRem(34),
        lineHeight: 1.17
      }
    }
  },

  MuiPickerDTTabs: {
    tabs: {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,

      '& .MuiTabs-indicator': {
        backgroundColor: palette.primary.contrastText
      }
    }
  },

  MuiSvgIcon: {
    root: {
      verticalAlign: 'middle',
      fontSize: pxToRem(20)
    },

    fontSizeSmall: {
      fontSize: pxToRem(16)
    },

    fontSizeLarge: {
      fontSize: pxToRem(36)
    }
  },

  MuiTypography: {
    gutterBottom: {
      marginBottom: '0.5em'
    },

    noWrap: {
      maxWidth: '100%'
    },

    subtitle2: {
      fontSize: pxToRem(12)
    }
  },

  MuiTooltip: {
    tooltip: {
      fontSize: pxToRem(14)
    }
  },

  MuiLink: {
    root: {
      cursor: 'pointer'
    }
  },

  MuiAutocomplete: {
    inputRoot: {
      flexWrap: null,

      '& $input': {
        minWidth: null
      },

      '&$focused': {
        flexWrap: 'wrap'
      }
    }
  }
});
