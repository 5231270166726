import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { columnsWidths } from './Procedures';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.cpt}>
          CPT
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.pos}>
          POS
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dx}>
          DX Sequence
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.modifiers}>
          Modifiers
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.unit}>
          Unit
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.price}>
          Price
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
