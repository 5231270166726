import { Typography } from '@material-ui/core';

export const SelectedItemsCount = ({ length, total }) => {
  return (
    <div>
      <Typography component="b" variant="subtitle2">
        {length}
      </Typography>
      &nbsp;
      <Typography component="span">
        out of
      </Typography>
      &nbsp;
      <Typography component="b" variant="subtitle2">
        {total}
      </Typography>
      &nbsp;
      <Typography component="span">
        selected
      </Typography>
    </div>
  );
};
