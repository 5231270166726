import { useState, useContext } from 'react';
import { CaseRemindersFilterContext } from '../../CaseRemindersFiltersProvider';
import { CaseReminderCasesProvider } from '../../../cases';
import { ReminderGroupsList } from './ReminderGroupsList';

export const CasesGroups = () => {
  const { filter } = useContext(CaseRemindersFilterContext);
  const [ expandedGroupID, setExpandedGroupID ] = useState();

  const handleChange = (groupID = null, isExpanded) => {
    setExpandedGroupID(isExpanded ? groupID : false);
  };

  return (
    <CaseReminderCasesProvider filter={filter}>
      <ReminderGroupsList
        GroupProps={{
          expandedGroupID: expandedGroupID,
          onSelectedPanelChange: handleChange
        }}
      />
    </CaseReminderCasesProvider>
  );
};
