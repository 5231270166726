import { Fragment } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { generateAddress } from '../../../../utils/generateAddress';
import { AddressLink } from '../../../AddressLink';
import { OfficeLink } from '../../../OfficeLink';
import { Contact } from '../../../Contact';
import { Divider } from '../../../Divider';

export const WorkOfficesContent = ({ offices = [] }) => {
  return (!!offices.length ? offices.map((office, index) => (
    <Fragment key={office.id}>
      <Grid item xs={4}>
        <Typography noWrap color="textSecondary">
          Office
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Box display="flex" flexDirection="column" mb={1}>
          <OfficeLink variant="h5" office={office} />

          <Box display="flex" alignItems="flex-start" mt={1}>
            <Box mr={0.5}>
              <LocationOnIcon color="inherit" />
            </Box>

            <AddressLink variant="h5" address={office?.full_address || generateAddress(office)}>
              {office?.full_address || generateAddress(office)}
            </AddressLink>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Typography noWrap color="textSecondary">
          Phone
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Contact
          needIcon
          noWrap
          variant="h5"
          title={office.phone}
          type="tel"
          contact={office.phone}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography noWrap color="textSecondary">
          Fax
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Contact
          needIcon
          noWrap
          variant="h5"
          title={office.fax}
          type="tel"
          contact={office.fax}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography noWrap color="textSecondary">
          Email
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Contact
          noWrap
          variant="h5"
          title={office.email}
          type="mailto"
          contact={office.email}
        />
      </Grid>

      {index !== offices.length - 1 && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </Fragment>
  )) : (
    <>
      <Grid item xs={4}>
        <Typography noWrap color="textSecondary">
          Offices Address
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography color="textSecondary">No info.</Typography>
      </Grid>
    </>
  ));
};
