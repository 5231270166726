import { Link as RouterLink } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import { Link, makeStyles, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const OverdraftBody = ({ payment }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>
        <div className={classes.content}>
          {!payment.causer_invoice ? '-' :
            <Link
              noWrap
              target="_blank"
              component={RouterLink}
              color="textSecondary"
              to={itemsRoutesByModels.invoice(payment.causer_invoice.id)}
              title={payment.causer_invoice.number}
            >
              {payment.causer_invoice.number}
            </Link>
          }

          <div className={classes.header}>
            <Typography className={classes.balanceTotal}>
              <CurrencyFormat
                value={payment?.amount}
                className={classes.balance}
              />
            </Typography>

            <Typography color="textSecondary" className={classes.balanceDue}>
              Amount
            </Typography>
          </div>
        </div>

        <div className={classes.content}>
          <Typography color="textSecondary" component="div" className={classes.contentDate}>
            <CalendarIcon fontSize="small" className={classes.contentDateIcon}/>

            {moment(payment.date).format('L')}
          </Typography>

          <div className={classes.header}>
            {!!payment.causer_invoice?.due_date ?
              <>
                <Typography className={cn(classes.balanceTotal, classes.dueDate)}>
                  {moment(payment.causer_invoice.due_date).format('DD MMM, YY')}
                </Typography>

                <Typography color="textSecondary" className={classes.balanceDue}>
                  Due Date
                </Typography>
              </>
              :
              <>
                <Typography className={cn(classes.balanceTotal, classes.dueDate)}>
                  {moment(payment.date).format('DD MMM, YY')}
                </Typography>

                <Typography color="textSecondary" className={classes.balanceDue}>
                  Paid Date
                </Typography>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
