import { useEffect, useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { Loader } from '../../../../../components/Loader';
import { PageBody } from '../../../../../components/Page';
import { TablePagination } from '../../../../../components/TablePagination';
import { NotesContext } from '../NotesProvider';
import { Header } from './Header';
import { Row } from './Row';

export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1150;
export const widthBreakpointLarge = 1375;
export const minRowHeight = 58;
export const columnsWidths = {
  name: 250,
  doi: 140,
  dor: 140,
  provider: 220,
  location: 200,
  actions: 60
};

export const List = () => {
  const { isFetched, notes, meta, resetNotes } = useContext(NotesContext);
  const ref = useRef();
  const { width } = useResizeObserver({ ref });

  useEffect(() => {
    resetNotes();
  }, []);

  return (
    <Box ref={ref} height="100%" display="flex" flexDirection="column" overflow="hidden">
      <Loader p={3} loading={!isFetched} render={
        () => !notes.length ? (
          <Box p={3}>
            <Typography align="center">No notes found</Typography>
          </Box>
        ) : (
          <>
            <Header width={width} />

            <PageBody disablePaddings>
              <MuiList disablePadding>
                {notes.map((note) => (
                  <Row
                    key={note.id}
                    width={width}
                    note={note}
                  />
                ))}
              </MuiList>
            </PageBody>

            <TablePagination borderTop pagination={meta} onChange={resetNotes} />
          </>
        )}
      />
    </Box>
  );
};
