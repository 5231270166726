import { Yup } from '../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  relationship: Yup.string().nullable().required(),
  user_id: Yup.mixed().when('is_checked', {
    is: (val) => !val,
    then: Yup.mixed().nullable().required()
  }),
  note: Yup.string().nullable().max(255),
  first_name: Yup.string().nullable().when('is_checked', {
    is: (val) => val,
    then: Yup.string().nullable().required()
  }),
  last_name: Yup.string().nullable().when('is_checked', {
    is: (val) => val,
    then: Yup.string().nullable().required()
  }),
  email: Yup.string().nullable().when('is_checked', {
    is: (val) => val,
    then: Yup.string().email().nullable().required()
  }),
  is_notify: Yup.boolean()
});
