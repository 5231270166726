import { useContext, useEffect, useRef } from 'react';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { CaseReminderCaseGroupsContext } from '../../../../groups';
import { ScrollbarsCustom } from '../../../../../../ScrollbarsCustom';
import { Loader } from '../../../../../../Loader';
import { Group } from '../Group';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ReminderGroupsList = ({ GroupProps }) => {
  const classes = useStyles();
  const {
    isFetched,
    reminderGroups,
    resetReminderGroups
  } = useContext(CaseReminderCaseGroupsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetReminderGroups();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef}>
      <div className={classes.root}>
        <Loader loading={!isFetched} p={2} render={
          () => !reminderGroups.length ? (
            <Typography align="center">No groups found</Typography>
          ) : (
            <MuiList disablePadding>
              {reminderGroups.map(({ group, count }) =>
                <Group
                  key={group.id}
                  group={group}
                  eventsAmount={count}

                  {...GroupProps}
                />
              )}
            </MuiList>
          )}
        />
      </div>
    </ScrollbarsCustom>
  );
};
