import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Radio, FormControlLabel, FormLabel, Typography } from '@material-ui/core';
import * as formsApi from '../../../../../../../api/profile';
import { RadioGroup, TextField, Radio as MuiRadio } from '../../../../../../../components/FormField';
import { Divider } from '../../../../../../../components/Divider';
import { Loader } from '../../../../../../../components/Loader';
import { ActionButtons } from '../../../../../../../components/medical/forms';
import {
  currentSymptomsValidationSchema
} from '../../../../../../../components/medical/forms/privatePacket';
import { getTextColor } from '../../../../../../../components/medical/getTextColor';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import * as types from '../../MedicalFormsProvider/types';
import { BodyParts } from '../../BodyParts';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CurrentSymptoms = () => {
  const classes = useStyles();
  const params = useParams();
  const dispatchRedux = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    form,
    isFetched,
    handleComplete,
    handleBack
  } = useContext(MedicalFormItemContext);

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updateCurrentSymptoms(values).then(({ data }) => {
      dispatchRedux({ type: types.UPDATE_FORM, payload: data });
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleComplete(form.id);
    }).catch(({ errors }) => {
      setErrors(errors);
      enqueueSnackbar('Form not created', { variant: 'error' });
    });
  };

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            form_id: form.id,
            tab_name: 'current_symptoms',
            forms: {
              current_symptoms: {
                ...form?.forms?.current_symptoms,
                where_is_pain: form?.forms?.current_symptoms?.where_is_pain
              }
            }
          }}
          validationSchema={currentSymptomsValidationSchema}
          onSubmit={handleCreateStep}
        >
          {({ errors, handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <div className={classes.mainContent}>
                <Typography className={classes.formTitle}>
                  Current Symptoms
                </Typography>

                <div className={classes.content}>
                  <Typography
                    className={classes.title}
                    color={getTextColor(errors?.forms?.current_symptoms?.where_is_pain)}
                  >
                    1. Where is your pain? *
                  </Typography>

                  <BodyParts/>
                </div>

                <div className={classes.content}>
                  <Typography className={classes.title}>
                    2. Describe the circumstances around the onset of your pain:
                  </Typography>

                  <TextField
                    fullWidth
                    multiline
                    disableUnderline
                    name="forms.current_symptoms.describe_circumstances_pain"
                    margin="normal"
                    variant="filled"
                    rows={6}
                  />
                </div>

                <div className={classes.content}>
                  <Typography className={classes.title}>
                    3. How often is your pain?
                  </Typography>

                  <div className={classes.contentInfo}>
                    <RadioGroup name="forms.current_symptoms.how_often_your_pain">
                      <FormControlLabel
                        value="occasional"
                        label={<Typography>Occasional</Typography>}
                        control={<Radio color="primary" />}
                      />

                      <FormControlLabel
                        value="frequent"
                        label={<Typography>Frequent</Typography>}
                        control={<Radio color="primary" />}
                      />

                      <FormControlLabel
                        value="constant"
                        label={<Typography>Constant</Typography>}
                        control={<Radio color="primary" />}
                      />
                    </RadioGroup>
                  </div>
                </div>

                <div className={classes.content}>
                  <Typography className={classes.title}>
                    4. Have you avoided strenuous lifting, carrying, pushing,
                    pulling, stooping, and  bending because of your injury?
                  </Typography>

                  <div className={classes.contentInfo}>
                    <RadioGroup name="forms.current_symptoms.have_you_avoided_strenuous_lifting">
                      <FormControlLabel
                        value="1"
                        label={<Typography>Yes</Typography>}
                        control={<Radio color="primary" />}
                      />

                      <FormControlLabel
                        value="0"
                        label={<Typography>No</Typography>}
                        control={<Radio color="primary"/>}
                      />
                    </RadioGroup>
                  </div>
                </div>

                <div className={classes.content}>
                  <Typography className={classes.title}>
                    5. How do the following factors affect your pain?
                  </Typography>

                  <div className={classes.painContent}>
                    <div className={classes.painInfo}>
                      <FormLabel component="legend" className={classes.label}>1. Heat</FormLabel>

                      <RadioGroup name="forms.current_symptoms.following_factors_your_pain.heat">
                        <FormControlLabel
                          value="better"
                          label={<Typography>Better</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.heat"
                              color="success"
                            />
                          }
                        />

                        <FormControlLabel
                          value="worse"
                          label={<Typography>Worse</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.heat"
                              color="error"
                            />
                          }
                        />

                        <FormControlLabel
                          value="no_effect"
                          label={<Typography>No Effect</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.heat"
                            />
                          }
                        />
                      </RadioGroup>
                    </div>

                    <div className={classes.painInfo}>
                      <FormLabel component="legend" className={classes.label}>2. Cold</FormLabel>

                      <RadioGroup name="forms.current_symptoms.following_factors_your_pain.cold">
                        <FormControlLabel
                          value="better"
                          label={<Typography>Better</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.cold"
                              color="success"
                            />
                          }
                        />

                        <FormControlLabel
                          value="worse"
                          label={<Typography>Worse</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.cold"
                              color="error"
                            />
                          }
                        />

                        <FormControlLabel
                          value="no_effect"
                          label={<Typography>No Effect</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.cold"
                            />
                          }
                        />
                      </RadioGroup>
                    </div>

                    <div className={classes.painInfo}>
                      <FormLabel component="legend" className={classes.label}>3. Lying down</FormLabel>

                      <RadioGroup name="forms.current_symptoms.following_factors_your_pain.lying_down">
                        <FormControlLabel
                          value="better"
                          label={<Typography>Better</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.lying_down"
                              color="success"
                            />
                          }
                        />

                        <FormControlLabel
                          value="worse"
                          label={<Typography>Worse</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.lying_down"
                              color="error"
                            />
                          }
                        />

                        <FormControlLabel
                          value="no_effect"
                          label={<Typography>No Effect</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.lying_down"
                            />
                          }
                        />
                      </RadioGroup>
                    </div>

                    <div className={classes.painInfo}>
                      <FormLabel component="legend" className={classes.label}>4. Sitting</FormLabel>

                      <RadioGroup name="forms.current_symptoms.following_factors_your_pain.sitting">
                        <FormControlLabel
                          value="better"
                          label={<Typography>Better</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.sitting"
                              color="success"
                            />
                          }
                        />

                        <FormControlLabel
                          value="worse"
                          label={<Typography>Worse</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.sitting"
                              color="error"
                            />
                          }
                        />

                        <FormControlLabel
                          value="no_effect"
                          label={<Typography>No Effect</Typography>}
                          control={<MuiRadio
                            size="small"
                            name="forms.current_symptoms.following_factors_your_pain.sitting"
                          />
                          }
                        />
                      </RadioGroup>
                    </div>

                    <div className={classes.painInfo}>
                      <FormLabel component="legend" className={classes.label}>5. Walking</FormLabel>

                      <RadioGroup name="forms.current_symptoms.following_factors_your_pain.walking">
                        <FormControlLabel
                          value="better"
                          label={<Typography>Better</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.walking"
                              color="success"
                            />
                          }
                        />

                        <FormControlLabel
                          value="worse"
                          label={<Typography>Worse</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.walking"
                              color="error"
                            />
                          }
                        />

                        <FormControlLabel
                          value="no_effect"
                          label={<Typography>No Effect</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.walking"
                            />
                          }
                        />
                      </RadioGroup>
                    </div>

                    <div className={classes.painInfo}>
                      <FormLabel component="legend" className={classes.label}>6. Climate</FormLabel>

                      <RadioGroup name="forms.current_symptoms.following_factors_your_pain.climate">
                        <FormControlLabel
                          value="better"
                          label={<Typography>Better</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.climate"
                              color="success"
                            />
                          }
                        />

                        <FormControlLabel
                          value="worse"
                          label={<Typography>Worse</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.climate"
                              color="error"
                            />
                          }
                        />

                        <FormControlLabel
                          value="no_effect"
                          label={<Typography>No Effect</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.climate"
                            />
                          }
                        />
                      </RadioGroup>
                    </div>

                    <div className={classes.painInfo}>
                      <FormLabel component="legend" className={classes.label}>7. Fatigue</FormLabel>

                      <RadioGroup name="forms.current_symptoms.following_factors_your_pain.fatigue">
                        <FormControlLabel
                          value="better"
                          label={<Typography>Better</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.fatigue"
                              color="success"
                            />
                          }
                        />

                        <FormControlLabel
                          value="worse"
                          label={<Typography>Worse</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.fatigue"
                              color="error"
                            />
                          }
                        />

                        <FormControlLabel
                          value="no_effect"
                          label={<Typography>No Effect</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.fatigue"
                            />
                          }
                        />
                      </RadioGroup>
                    </div>

                    <div className={classes.painInfo}>
                      <FormLabel component="legend" className={classes.label}>8. Coughing</FormLabel>

                      <RadioGroup name="forms.current_symptoms.following_factors_your_pain.coughing">
                        <FormControlLabel
                          value="better"
                          label={<Typography>Better</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.coughing"
                              color="success"
                            />
                          }
                        />

                        <FormControlLabel
                          value="worse"
                          label={<Typography>Worse</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.coughing"
                              color="error"
                            />
                          }
                        />

                        <FormControlLabel
                          value="no_effect"
                          label={<Typography>No Effect</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.coughing"
                            />
                          }
                        />
                      </RadioGroup>
                    </div>

                    <div className={classes.painInfo}>
                      <FormLabel component="legend" className={classes.label}>9. Alcohol</FormLabel>

                      <RadioGroup name="forms.current_symptoms.following_factors_your_pain.alcohol">
                        <FormControlLabel
                          value="better"
                          label={<Typography>Better</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.alcohol"
                              color="success"
                            />
                          }
                        />

                        <FormControlLabel
                          value="worse"
                          label={<Typography>Worse</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.alcohol"
                              color="error"
                            />
                          }
                        />

                        <FormControlLabel
                          value="no_effect"
                          label={<Typography>No Effect</Typography>}
                          control={
                            <MuiRadio
                              size="small"
                              name="forms.current_symptoms.following_factors_your_pain.alcohol"
                            />
                          }
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </div>

              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
