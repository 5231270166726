import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { uploadFiles } from '../../../../../store/dashboard/files/uploads';
import * as appointmentTypesApi from '../../../../../api/codes/appointments/appointment-types';
import * as mrisApi from '../../../../../api/profile';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { validationSchema as MRIValidationSchema } from './MRIValidationSchema';
import { mriInitialValues } from './MRIInitialValues';
import { MRIBody } from './MRIBody';

export const CreateMRIModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    appointmentTypeID,
    initialValues = {},
    onUpdate = () => {}
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [ attachments, setAttachments ] = useState([]);
  const [ appointmentType, setAppointmentType ] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // TODO: Hide for future settings
  // const [ isToggleSwitch, setIsToggleSwitch ] = useState(false);
  // const [ apptValues, setApptValues ] = useState(null);
  // const defaultInitialValues = useMemo(() => {
  //   if (!isToggleSwitch) {
  //     return mriInitialValues;
  //   }
  //
  //   return apptValues ? {
  //     ...mriInitialValues,
  //
  //     appointment_id: { id: apptValues.appointment_id, ...apptValues },
  //     physician_id: apptValues?.approved_id,
  //     office_id: apptValues?.office
  //   } : {
  //     ...apptInitialValues,
  //     ...initialValues,
  //
  //     patient_insurance_pcp: insurance?.pcp,
  //     patient_insurance_spc: insurance?.spc,
  //     insurance_details: {
  //       company: insurance?.insurance_company
  //     },
  //     appointment_type_id: appointmentType,
  //     time: appointmentType?.time || 0,
  //     mri: [ MRIsInitialValues ]
  //   };
  // }, [ isToggleSwitch ]);


  // TODO: Hide for future settings
  // const handleSwitchChange = (event) => {
  //   if (isToggleSwitch) {
  //     setIsToggleSwitch(event.target.checked);
  //   } else {
  //     openModal(SelectApptModal, {
  //       payload: {
  //         appointmentType: apptTypesMap.mri,
  //         patientID: initialValues?.patient?.id
  //       },
  //       onModalResolved: (data) => {
  //         setApptValues(!data ? null : {
  //           office: data.office,
  //           approved_id: data?.appointment_book?.provider,
  //           appointment_at: data?.appointment_at
  //         });
  //
  //         setIsToggleSwitch(true);
  //       }
  //     });
  //   }
  // };

  const createMRI = ({ media, ...values }, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.patient_id = values?.patient_id?.id || values.patient_id;

    return mrisApi.createMRI(values).then(({ data }) => {
      if(attachments.length) {
        dispatch(uploadFiles({
          files: attachments,
          owner_type: 'mri',
          owner_id: data?.id
        }));
      }

      enqueueSnackbar('Successfully created', { variant: 'success' });
      onUpdate();
      handleModalResolve(data);
    }).catch(({ message, errors }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  // TODO: Hide for future settings
  // const massCreateMRIs = (values, { isSubmitting, setErrors }) => {
  //   if (isSubmitting) {
  //     return;
  //   }
  //
  //   values.patient_id = values?.patient_id?.id || values.patient_id;
  //   values.mri = values.mri.map((item) => ({
  //     name: item.name,
  //     note: item.note,
  //     date: item.date
  //   }));
  //
  //   return mrisApi.massCreateMRI(values).then(({ data }) => {
  //     if (!!attachments.length) {
  //       attachments.forEach((attachment, i) => {
  //         dispatch(uploadFiles({
  //           files: attachment,
  //           owner_type: 'mri',
  //           owner_id: data?.mri[i].id
  //         }));
  //       });
  //
  //       handleModalResolve(data);
  //       enqueueSnackbar('Successfully created', { variant: 'success' });
  //     } else {
  //       handleModalResolve(data);
  //       enqueueSnackbar('Successfully created', { variant: 'success' });
  //       onUpdate();
  //     }
  //   }).catch(({ message, errors }) => {
  //     if (errors) {
  //       const errorMessage = Object.values(errors);
  //
  //       enqueueSnackbar(message + ', ' + errorMessage, { variant: 'error' });
  //       setErrors(errors);
  //     }
  //   });
  // };

  useEffect(() => {
    if (appointmentTypeID) {
      appointmentTypesApi.fetchAppointmentType(appointmentTypeID).then(setAppointmentType);
    }
  }, []);

  return (
    <Dialog fullScreen={isMobile} PaperProps={{ style: { maxWidth: 1100 } }} {...DialogProps}>
      <Loader p={2} loading={appointmentTypeID && !appointmentType} render={
        () => (
          <Formik
            enableReinitialize
            initialValues={{
              ...mriInitialValues,

              patient_id: initialValues?.patient_id
            }}
            validationSchema={MRIValidationSchema}
            onSubmit={createMRI}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader
                  // TODO: Hide for future settings
                  // actionButton={
                  //   <FormControlLabel
                  //     label="Add a new MRI as a appt"
                  //     control={
                  //       <Switch
                  //         color="primary"
                  //         checked={isToggleSwitch}
                  //         onChange={handleSwitchChange}
                  //       />
                  //     }
                  //   />
                  // }
                  onClose={handleModalReject}
                >
                  Add New MRI
                </ModalHeader>

                <ModalBody>
                  {/* TODO: Hide for future settings */}
                  {/*{isToggleSwitch ? apptValues ? <MRIBody isApptExist /> : (*/}
                  {/*  <>*/}
                  {/*    <AppointmentForm />*/}
                  {/*    <MRIs onAttachmentsUpdate={setAttachments} />*/}
                  {/*  </>*/}
                  {/*) : (*/}
                  {/*  <MRIBody onAttachmentsUpdate={setAttachments} />*/}
                  {/*)}*/}

                  <MRIBody onAttachmentsUpdate={setAttachments} />
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
