import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { columnsWidths } from '../SavedFiltersList';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell flexBasis={columnsWidths.code}>
          Code
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.type}>
          Type
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.year}>
          Year
        </ListRowCell>

        <ListRowCell grow flexBasis={columnsWidths.description}>
          Description
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.orderBy}>
          Order By
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
