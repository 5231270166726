export const styles = ({
  spacing,
  palette: { grey, primary },
  typography: { pxToRem }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: grey[200],
    minHeight: 40,
    paddingLeft: spacing(2)
  },

  header__counter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: `${primary.main}`,
    fontSize: pxToRem(12),
    color: `${primary.contrastText}`,
    width: 22,
    height: 22,
    marginLeft: spacing(3)
  },

  mainContent: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(),
    flexWrap: 'wrap'
  }
});
