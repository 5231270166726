import { useContext } from 'react';
import { get } from 'lodash';
import { Box, SvgIcon } from '@material-ui/core';
import { mdiAccountHeartOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { ContentCard } from '../../../../../../../../components/ContentCard';
import { MedicalInfo as MedicalInfoComponent } from '../../../../../../../../components/users/MedicalInfo';
import { WidgetsActionButtons } from '../../../../../../../../components/WidgetsActionButtons';
import { MedicalFormsProvider } from '../../../../../../ProfilePage/MedicalInfo/MedicalForms/MedicalFormsProvider';
import { UserContext } from '../../../../UserProvider';

export const MedicalInfo = ({
  isOpen,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { user, medicalInfoPathname } = useContext(UserContext);
  const initialState = {
    filter: {
      medical_id: get(user, 'medical.id')
    },
    medicalInfoPathname
  };

  return (
    <ContentCard
      fullHeight
      isMovable
      disableContentPaddings
      isResizable={isOpen}
      title="Medical Info"
      leftActions={[
        <Box mr={1}>
          <SvgIcon fontSize="small">
            <Icon path={mdiAccountHeartOutline} />
          </SvgIcon>
        </Box>
      ]}
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          isOpenDefault={props.isCollapsed || isOpen?.[props?.item?.i]}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
    >
      <MedicalFormsProvider initialState={initialState}>
        <MedicalInfoComponent />
      </MedicalFormsProvider>
    </ContentCard>
  );
};
