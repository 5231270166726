import { isEqual } from 'lodash';
import { createContext, useEffect, useReducer, useRef } from 'react';
import { api } from '../../../../../api';
import * as printersApi from '../../../../../api/printers';
import { usePrevious } from '../../../../../helpers/hooks';
import * as types from './types';
import { initialState } from './initialState';
import { reducer } from './reducer';

export const FilesFromPrintersContext = createContext(null);

export const FilesFromPrintersContextProvider = ({ children, printerId }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    isFetched,
    isFetching,
    filter,
    files,
    selectedFiles
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchFiles = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FILES_REQUEST });

    printersApi.fetchFilesFromPrinter(printerId, {
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_FILES_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const deleteFiles = (files) => {
    dispatch({ type: types.DELETE_FILES, payload: files });
  };

  const addFilesToSelected = ((files) => {
    dispatch({ type: types.ADD_FILES_TO_SELECTED, payload: files });
  });

  const deleteFilesFromSelected = ((files) => {
    dispatch({ type: types.DELETE_FILES_FROM_SELECTED, payload: files });
  });

  const toggleFileSelection = (file) => {
    if (selectedFiles.includes(file)) deleteFilesFromSelected([ file ]);
    else addFilesToSelected([ file ]);
  };

  const allFilesIsSelected = () => {
    return selectedFiles.length === files.length;
  };

  const toggleAllFilesSelection = () => {
    const names = files.map(({ name }) => name);

    allFilesIsSelected() ? deleteFilesFromSelected(names) : addFilesToSelected(names);
  };

  useEffect(() => {
    fetchFiles();
  }, [ printerId ]);

  useEffect(() => {
    if (!isEqual(prevFilter, filter)) {
      fetchFiles(filter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  const providerValue = {
    isFetched,
    isFetching,
    files,
    selectedFiles,
    filter,
    printerId,

    fetchFiles,
    applyFilter,
    deleteFiles,
    toggleFileSelection,
    allFilesIsSelected,
    toggleAllFilesSelection
  };

  return (
    <FilesFromPrintersContext.Provider value={providerValue}>
      {children}
    </FilesFromPrintersContext.Provider>
  );
};
