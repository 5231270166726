import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import * as eventTypesApi from '../../../../../api/schedule-events/schedule-event-types';
import { errorsMap } from '../../../../../utils/validation';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { ScheduleEventTypeForm, validationSchema, initialValues } from './ScheduleEventTypeForm';

export const ScheduleEventTypeFormModal = ({
  payload: {
    initialValues: initialValuesProp = {}
  },
  DialogProps,
  handleModalReject,
  handleModalResolve
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const duration = moment.duration(initialValuesProp.duration || 0, 'seconds');

  const createScheduleEventType = (values, { setErrors }) => {
    return eventTypesApi.createEventType({
      ...values,

      duration: moment.duration(`${values._hours || 0}:${values._minutes || 0}`).asSeconds() || null
    }).then((data) => {
      enqueueSnackbar('Schedule event type created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }

      if (errors?.name?.includes(errorsMap.unique)) {
        setErrors({ name: 'Type with the same name already exists' });
      }

      enqueueSnackbar('Schedule event type not created', { variant: 'error' });
    });
  };

  const updateScheduleEventType = (values, { setErrors }) => {
    return eventTypesApi.updateEventType({
      ...values,

      duration: moment.duration(`${values._hours || 0}:${values._minutes || 0}`).asSeconds() || null
    }).then((data) => {
      enqueueSnackbar('Schedule event type updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }

      if (errors?.name?.includes(errorsMap.unique)) {
        setErrors({ name: 'Type with the same name already exists' });
      }

      enqueueSnackbar('Schedule event type not updated', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...initialValuesProp,

        _hours: duration.hours() || null,
        _minutes: duration.minutes() || null
      }}
      validationSchema={validationSchema}
      onSubmit={initialValuesProp?.id ? updateScheduleEventType : createScheduleEventType}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              {initialValuesProp?.id ? 'Edit' : 'Add'} Schedule Event Type
            </ModalHeader>

            <ModalBody>
              <ScheduleEventTypeForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {initialValuesProp?.id ? 'Save' : 'Create'}
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
