import { useMemo } from 'react';
import { Typography, Box } from '@material-ui/core';
import { PhoneContact } from '../../Contact/PhoneContact';
import { Tooltip } from '../../Tooltip';

export const parseFaxContact = (contact) => {
  const parts = contact?.split(/[.:;?!~,`"&|()<>{}\s]+/g).filter((item) => item?.length);
  const phone = parts?.filter((item) => !/[a-zA-Z]/g.test(item))?.join('');
  const name = parts?.filter((item) => /[a-zA-Z]/g.test(item))?.join(' ');

  return {
    phone,
    name
  };
};

export const FaxNumber = ({ contact }) => {
  const { phone, name } = useMemo(() => {
    return parseFaxContact(contact);
  }, [ contact ]);

  return (
    !contact ? (
      <Typography color="textSecondary">No info.</Typography>
    ) : (
      <Typography noWrap component="div">
        {!!phone && (
          <PhoneContact value={phone} />
        )}

        <Box overflow="hidden" textOverflow="ellipsis">
          <Tooltip isExistTooltip={!phone} title={contact}>
            <Typography
              noWrap
              color={!phone ? 'textPrimary' : 'textSecondary'}
              variant={!phone ? 'body1' : 'caption'}
            >
              {!phone ? contact : name}
            </Typography>
          </Tooltip>
        </Box>
      </Typography>
    )
  );
};
