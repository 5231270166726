import { createHookReducer } from '../../../helpers/createHookReducer';
import { moveArrayItem } from '../../../helpers/moveArrayItem';
import * as types from './types';

const addTasks = (state, payload) => {
  const { pagination, filter: { statuses, ...filter }, tasks, ...otherState } = state;
  let suitableTasks = payload;

  if (statuses && statuses.length) {
    suitableTasks = payload.filter(({ status }) => statuses.find((id) => id === status.id));
  }

  if (!suitableTasks.length) {
    return state;
  }

  const total = pagination.total + suitableTasks.length;
  const page = Math.ceil((tasks.length + suitableTasks.length) / filter.per_page) - 1;
  const last_page = Math.ceil(total / filter.per_page);

  return {
    ...otherState,

    pagination: { ...pagination, total, last_page },
    filter: { ...filter, statuses, page },
    tasks: suitableTasks.concat(tasks)
  };
};

const deleteTask = ({ pagination, filter, tasks, selectedTasksIDs, ...state }, taskId) => {
  const remainingTasks = tasks.filter((task) => task.id !== taskId);
  const deletedTasksCount = tasks.length - remainingTasks.length;
  const total = pagination.total - deletedTasksCount;
  const page = Math.ceil(deletedTasksCount / filter.per_page);
  const last_page = Math.ceil(total / filter.per_page);

  return {
    ...state,

    pagination: { ...pagination, total, last_page },
    filter: { ...filter, page },
    selectedTasksIDs: selectedTasksIDs.filter((id) => taskId !== id),
    tasks: remainingTasks
  };
};

export const reducer = createHookReducer({
  [types.TASKS_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.TASKS_FETCH_SUCCESS]: (
    { filter, tasks, ...state },
    { data: newTasks, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      tasks: (page > 1 ?
        tasks.filter(({ id }) => !newTasks.find((loadedTask) => id === loadedTask.id)).concat(newTasks)
        :
        newTasks
      )
    };
  },

  [types.ADD_TASKS]: addTasks,

  [types.DELETE_TASK_SUCCESS]: deleteTask,

  [types.UPDATE_TASK_IN_LIST]: (state, task) => {
    const { filter, tasks } = state;
    const getStateWithUpdatedTasks = () => {
      return {
        ...state,

        tasks: tasks.map((item) => {
          return item.id === task.id ? { ...item, ...task } : item;
        })
      };
    };

    if (!(filter.statuses && filter.statuses.length)) {
      return getStateWithUpdatedTasks();
    }

    if (filter.statuses.find((status) => status === task.status.id)) {
      if (!tasks.find(({ id }) => id === task.id)) {
        return addTasks(state, [ task ]);
      }

      return getStateWithUpdatedTasks();
    }

    return deleteTask(state, task.id);
  },

  [types.TOGGLE_ALL_TASKS_SELECTION]: ({ tasks, selectedTasksIDs, ...state }) => {
    const allTasksIsSelected = tasks.length === selectedTasksIDs.length;

    return {
      ...state,

      tasks,
      selectedTasksIDs: allTasksIsSelected ? [] : tasks.map((item) => item.id)
    };
  },

  [types.TOGGLE_TASK_SELECTION]: ({ selectedTasksIDs, ...state }, id) => {
    return {
      ...state,

      selectedTasksIDs: selectedTasksIDs.indexOf(id) !== -1 ?
        selectedTasksIDs.filter((item) => item !== id)
        :
        selectedTasksIDs.concat(id)
    };
  },

  [types.RESET_TASKS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.MASS_DELETE_TASK]: ({ tasks, selectedTasksIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedTasksIDs: [],
      pagination: { ...pagination, total, last_page },
      tasks: tasks.filter((task) => IDs.indexOf(task.id) < 0)
    };
  },

  [types.UPDATE_TASK_POSITION_SUCCESS]: ({ tasks, ...state }, positions) => {
    const { oldIndex, newIndex } = positions;

    return {
      ...state,

      tasks: moveArrayItem(tasks, oldIndex, newIndex)
    };
  }
});
