import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { unixToEndOfDayUnix, unixToStartOfDayUnix } from '../../../../../../../../helpers/dates';
import { saveFilter } from '../../../../../../../../store/lastFilters';
import {
  Autocomplete,
  CompaniesAutocomplete,
  KeyboardDatePicker
} from '../../../../../../../../components/FormField';
import { FiltersBar as FiltersBarComponent } from '../../../../../../../../components/FiltersBar';
import { CaseMembersSelect } from '../../../../../../../../components/users/CaseMembersSelect';
import { IconComponent } from '../../../../../../../../components/saved-filters';
import { CaseDiscoveriesContext } from '../CaseDiscoveriesProvider';
import { discoveryTypeOptions } from '../DiscoveryForm';
import { CardContent } from './CardContent';
import { List } from './List';
import { filterFieldsMap, filterFieldsLabels } from './filterFieldsMap';

const MODAL_WIDTH = 710;

const initialValues = {
  type: null,
  users: null,
  companies: null,
  due_date: null,
  send_date: null,
  received_date: null
};

export const FiltersBar = ({ caseItem, filterKey, hiddenFields = [] }) => {
  const theme = useTheme();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const { applyFilter } = useContext(CaseDiscoveriesContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState(initialValues);

  const handleDiscoveryTypeChange = (type) => {
    applyFilter({ type: type?.value });
    setRelationsForFilter((state) => ({ ...state, type }));
  };

  const handleUsersSelectChange = (users) => {
    applyFilter({ users: users?.map(({ user }) => user?.id) });
    setRelationsForFilter((state) => ({ ...state, users }));
  };

  const handleCompaniesSelectChange = (companies) => {
    applyFilter({ companies: companies?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, companies }));
  };

  const handleDatePickerChange = (name, transformer) => (date) => {
    applyFilter({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);

    applyFilter({
      ...filter,

      started_from: filter?.started_from,
      started_to: filter?.started_to,
      users: filter?.users?.map(({ user }) => user?.id),
      order_by: filter?.order_by?.value
    });
  };

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableTopPaddings
      formikRef={formikRef}
      filterKey={filterKey}
      disableIcon={!filterKey}
      initialValues={initialValues}
      onReset={() => applySavedFilter(initialValues)}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      hiddenFields={hiddenFields}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.type,
          label: filterFieldsLabels[filterFieldsMap.type],
          field: (
            <Autocomplete
              name="type"
              label="Discovery Type"
              placeholder="Select discovery type..."
              options={discoveryTypeOptions}
              onChange={handleDiscoveryTypeChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.users,
          label: filterFieldsLabels[filterFieldsMap.users],
          field: (
            <CaseMembersSelect
              multiple
              name="users"
              label="Users"
              options={caseItem?.case_users}
              minWidth={isMobile ? '100%' : 210}
              onChange={handleUsersSelectChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.companies,
          label: filterFieldsLabels[filterFieldsMap.companies],
          field: (
            <CompaniesAutocomplete
              multiple
              name="companies"
              label="Companies"
              minWidth={isMobile ? '100%' : 210}
              onChange={handleCompaniesSelectChange}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.dueDate,
          label: filterFieldsLabels[filterFieldsMap.dueDate],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                disableFuture
                clearable
                name="due_date"
                label={filterFieldsLabels[filterFieldsMap.dueDate]}
                outputFormat="YYYY-MM-DD"
                onChange={handleDatePickerChange('due_date', unixToStartOfDayUnix)}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.sendDate,
          label: filterFieldsLabels[filterFieldsMap.sendDate],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                clearable
                name="send_date"
                label={filterFieldsLabels[filterFieldsMap.sendDate]}
                outputFormat="YYYY-MM-DD"
                onChange={handleDatePickerChange('send_date', unixToEndOfDayUnix)}
              />
            </Box>
          )
        },
        {
          fieldKey: filterFieldsMap.receivedDate,
          label: filterFieldsLabels[filterFieldsMap.receivedDate],
          field: (
            <Box minWidth={140}>
              <KeyboardDatePicker
                clearable
                name="received_date"
                label={filterFieldsLabels[filterFieldsMap.receivedDate]}
                outputFormat="YYYY-MM-DD"
                onChange={handleDatePickerChange('received_date', unixToEndOfDayUnix)}
              />
            </Box>
          )
        }
      ]}
    />
  );
};
