import {
  socialHistoryTypes
  // eslint-disable-next-line max-len
} from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/FormPreviewModal/PastMedicalHistory/socialHistoryTypes';
import { Yup } from '../../../../utils/validation';

export const carAccidentValidationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    accident_information_ortho: Yup.object().shape({
      type: Yup.string().nullable(),
      car_accident: Yup.object().shape({
        were_you_the_driver_passenger_front_or_back: Yup.string().nullable().required(),
        were_you_the_driver_description: Yup.string().nullable().when(
          'were_you_the_driver_passenger_front_or_back',
          (driverOrPassenger, schema) => driverOrPassenger === 'Passenger' ? schema.required() : schema
        ),
        were_you_driving_or_a_passenger_for_uber_or_lyft: Yup.string().nullable().required(),
        did_you_go_to_an_emergency_or_urgent_care_after_the_accident: Yup.string().nullable().required(),
        if_so_when: Yup.string().nullable()
          .when('did_you_go_to_an_emergency_or_urgent_care_after_the_accident', {
            is: (value) => value === socialHistoryTypes.yes,
            then: Yup.string().nullable().required()
          }),
        what_did_they_do: Yup.string().nullable()
          .when('did_you_go_to_an_emergency_or_urgent_care_after_the_accident', {
            is: (value) => value === socialHistoryTypes.yes,
            then: Yup.string().nullable().required()
          }),
        were_you_prescribed_any_medications: Yup.string().nullable()
          .when('did_you_go_to_an_emergency_or_urgent_care_after_the_accident', {
            is: (value) => value === socialHistoryTypes.yes,
            then: Yup.string().nullable().required()
          }),
        was_a_police_report_filed: Yup.string().nullable().required(),
        briefly_explain_how_the_accident_occurred: Yup.string().nullable().required()
      })
    })
  })
});

export const slipAndFallValidationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    accident_information_ortho: Yup.object().shape({
      type: Yup.string().nullable(),
      slip_and_fall: Yup.object().shape({
        where_did_the_accident_occur: Yup.string().nullable().required(),
        was_a_police_report_filed: Yup.string().nullable().required(),
        did_you_go_to_an_emergency_or_urgent_care_after_the_accident: Yup.string().nullable().required(),
        if_so_when: Yup.string().nullable()
          .when('did_you_go_to_an_emergency_or_urgent_care_after_the_accident', {
            is: (value) => value === socialHistoryTypes.yes,
            then: Yup.string().nullable().required()
          }),
        what_did_they_do: Yup.string().nullable()
          .when('did_you_go_to_an_emergency_or_urgent_care_after_the_accident', {
            is: (value) => value === socialHistoryTypes.yes,
            then: Yup.string().nullable().required()
          }),
        were_you_prescribed_any_medications: Yup.string().nullable()
          .when('did_you_go_to_an_emergency_or_urgent_care_after_the_accident', {
            is: (value) => value === socialHistoryTypes.yes,
            then: Yup.string().nullable().required()
          }),
        briefly_explain_how_the_accident_occurred: Yup.string().nullable().required()
      })
    })
  })
});

export const otherAccidentValidationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    accident_information_ortho: Yup.object().shape({
      type: Yup.string().nullable(),
      other: Yup.object().shape({
        text: Yup.string().nullable().required(),
        describe_the_circumstances_around_the_onset_of_your_pain: Yup.string().nullable().required()
      })
    })
  })
});
