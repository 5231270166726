import { useRef } from 'react';
import { useFormikContext } from 'formik';
import { omit, uniqBy } from 'lodash';
import moment from 'moment';
import { FormControlLabel, Grid } from '@material-ui/core';
import { apiModelsMap } from '../../dataMaps/apiModels';
import { CasesSelect } from '../cases/CasesSelect';
import { ConfirmationModal } from '../ConfirmationModal';
import {
  Editor,
  TextField,
  Select,
  KeyboardDateTimePicker,
  Checkbox,
  TimeZoneSelect,
  TasksSelect,
  OfficesLocationSelect,
  ScheduleEventTypesSelect,
  transformScheduleEventTypeToOption
} from '../FormField';
import { useModal } from '../ModalsProvider';
import { RecurrenceSelect } from '../RecurrenceSelect';
import { UsersSelect } from '../users';
import { eventsParentsTypesOptions } from './eventsParentsTypesOptions';
import { ScheduleEventTemplatesSelect } from './templates';

export const ScheduleEventForm = ({ hideApplyFields = false }) => {
  const { openModal } = useModal();
  const { values, setFieldValue, setValues } = useFormikContext();
  const editorRef = useRef();

  const handleTemplateChange = (template) => {
    openModal(ConfirmationModal, {
      payload: {
        content: 'All form data will be replaced from the template'
      },

      onModalResolved: () => {
        editorRef.current.setData(template.description);

        setValues({
          ...values,
          ...omit(template, [ 'started_at', 'finished_at' ]),

          type_id: transformScheduleEventTypeToOption(template.type || null),
          office_id: template.office || null
        });
      }
    });
  };

  const handleStartDateChange = (date, isDurationCorrespondsToType = values.is_duration_corresponds_to_type) => {
    if (!date) {
      return;
    }

    if (isDurationCorrespondsToType && values.type?.duration) {
      setFieldValue('finished_at', moment.unix(date).add(values.type?.duration, 'seconds'));

      return;
    }

    if (values.finished_at < date) {
      setFieldValue('finished_at', moment.unix(date).add(5, 'minutes'));
    }
  };

  const bindEventDurationToType = ({ target: { checked } }) => {
    if (checked) {
      handleStartDateChange(values.started_at, checked);
    }
  };

  const handleParentTypeChange = () => {
    setFieldValue('parent_id', null);
  };

  const handleEventTypeChange = (option) => {
    setFieldValue('type', option?.data);

    if (values.started_at && option?.data?.duration) {
      setFieldValue('finished_at', moment.unix(values.started_at).add(option.data?.duration, 'seconds'));
    }
  };

  const handleCaseChange = (caseItem) => {
    if (!values.users?.length) {
      setFieldValue('users', uniqBy(
        caseItem?.case_users
          ?.filter(({ user }) => user)
          ?.map(({ user }) => user),
        'id'
      ));
    }
  };

  const handleTaskChange = (task) => {
    if (!values.users?.length) {
      setFieldValue('users', task?.users);
    }
  };

  return (
    <>
      {!values?.id &&
        <ScheduleEventTemplatesSelect
          withoutFormik
          disableClearable
          onChange={handleTemplateChange}
        />
      }

      <TextField
        required
        name="title"
        label="Title"
        placeholder="Enter event title..."
        margin="dense"
      />

      <Editor
        ref={editorRef}
        name="description"
        label="Description"
        placeholder="Enter description..."
        margin="dense"
      />

      <ScheduleEventTypesSelect
        isClearable
        name="type_id"
        label="Event type"
        TextFieldProps={{ margin: 'dense' }}
        onChange={handleEventTypeChange}
      />

      {!!values.type?.duration &&
        <FormControlLabel
          label="Bind event duration to event type"
          control={<Checkbox name="is_duration_corresponds_to_type" onChange={bindEventDurationToType} />}
        />
      }

      <Grid container spacing={2}>
        <Grid item sm={6}>
          <KeyboardDateTimePicker
            required
            name="started_at"
            label="Started"
            margin="dense"
            onChange={handleStartDateChange}
          />
        </Grid>

        <Grid item sm={6}>
          <KeyboardDateTimePicker
            required
            disabled={!!values.is_duration_corresponds_to_type}
            name="finished_at"
            label="Ended"
            margin="dense"
            minDate={moment.unix(values.started_at).isValid() ? values.started_at : null}
          />
        </Grid>

        <Grid item sm={6}>
          <RecurrenceSelect
            name="recurring_rule"
            label="Recurrence"
            margin="dense"
          />
        </Grid>

        <Grid item sm={6}>
          <TimeZoneSelect name="timezone" margin="dense" />
        </Grid>
      </Grid>

      {!hideApplyFields &&
        <>
          <Select
            isClearable
            formattedValue
            name="parent_type"
            isSearchable={false}
            label="Apply to"
            options={eventsParentsTypesOptions}
            onChange={handleParentTypeChange}
            TextFieldProps={{ margin: 'dense' }}
          />

          {values.parent_type === apiModelsMap.case &&
            <CasesSelect
              required
              name="parent_id"
              label="Select case"
              placeholder="Search case by name..."
              margin="dense"
              onChange={handleCaseChange}
            />
          }

          {values.parent_type === apiModelsMap.task &&
            <TasksSelect
              required
              name="parent_id"
              label="Select task"
              margin="dense"
              onChange={handleTaskChange}
            />
          }
        </>
      }

      <UsersSelect
        multiple
        name="users"
        label="Select users"
        margin="dense"
      />

      <OfficesLocationSelect
        name="office_id"
        label="Office"
        margin="dense"
      />

      <FormControlLabel
        label="Send them e-mail with notification"
        control={<Checkbox name="email_notification"/>}
      />
    </>
  );
};
