import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { merge } from 'lodash';
import { api } from '../../../../api';
import * as formsApi from '../../../../api/profile';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { formTypesMap } from '../../../../components/medical/forms';
import { useModal } from '../../../../components/ModalsProvider';
import { setTokenData } from '../../../../store/dashboard/token';
import { getParams } from '../getParams';
import { initialState } from './initialState';
import { getFormRoutes } from './helpers';
import { reducer } from './reducer';
import {
  steps,
  getExistingFormSteps,
  getExistingInjuryFormSteps,
  getExistingInjuryOrthoFormSteps
} from './steps';
import * as types from './types';

export const FormContext = createContext(null);

export const FormProvider = ({
  initialState: initialStateProp = {},
  children
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const {
    form,
    isFetched,
    medicalInfoPathname
  } = state;
  const location = useLocation();
  const history = useHistory();
  const { openModal } = useModal();
  const [ isDirty, setIsDirty ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const reduxDispatch = useDispatch();
  const currentToken = useSelector(({ token }) => token);
  const [ activeStep, setActiveStep ] = useState(0);
  const [ completed, setCompleted ] = useState({});
  const [ type, setType ] = useState(getParams(location));
  const [ initialFormValue, setInitialFormValue ] = useState(location?.state?.form || {});
  const cancelFetch = useRef(() => {});

  const fetchForm = ({ id = null }) => {
    cancelFetch.current();
    dispatch({ type: types.FETCH_FORM_REQUEST });

    if (id) {
      formsApi.fetchGuestMedicalForm(id, {
        params: { token: currentToken?.token || null },
        cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
      }).then((data) => {
        setType(data.form_type);

        setCompleted(data.form_type === formTypesMap.private || data.form_type === formTypesMap.compensation
          ? getExistingFormSteps(data)
          : data.form_type === formTypesMap.ortho
            ? getExistingInjuryOrthoFormSteps(data)
            : getExistingInjuryFormSteps(data)
        );

        dispatch({
          type: types.FETCH_FORM_SUCCESS,
          payload: { ...initialFormValue, ...data }
        });

        setInitialFormValue({ ...initialFormValue, ...data });
      }).catch(() => {
        enqueueSnackbar('Sorry, you do not have permission to view the form', { variant: 'error' });
        history.push('/');
      });
    } else {
      dispatch({
        type: types.FETCH_FORM_SUCCESS,
        payload: location?.state || {}
      });

      setInitialFormValue({ ...initialFormValue, ...location?.state });
      reduxDispatch(setTokenData(null));
      setActiveStep(0);
      setCompleted({});
    }
  };

  const handleBack = ({ dirty, id }) => {
    if (dirty) {
      openModal(ConfirmationModal, {
        payload: {
          content: 'Do you want to leave the page without saving?'
        },
        onModalResolved: () => {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          history.push(getFormRoutes({ id })[activeStep - 1]);
        }
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      history.push(getFormRoutes({ id })[activeStep - 1]);
    }
  };

  const handleReset = () => {
    openModal(ConfirmationModal, {
      payload: {
        content: 'Submit your form?'
      },
      onModalResolved: () => {
        formsApi.submitForm(form.id, {
          params: { token: currentToken?.token || null }
        });

        setActiveStep(0);
        setTokenData(null);
        setCompleted({});
        enqueueSnackbar(
          'Form is completed, we will notify you soon about the status of your request',
          { variant: 'success' }
        );
        history.push('/');
      }
    });
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === steps.length;
  };

  const handleNext = (id) => {
    const newActiveStep = isLastStep() && !allStepsCompleted()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;

    setActiveStep(newActiveStep);
    history.push(getFormRoutes({ id, medicalInfoPathname })[activeStep + 1]);
  };

  const handleComplete = (form) => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;

    if (form.token) {
      reduxDispatch(setTokenData(form.token));
    }

    setCompleted(newCompleted);
    handleNext(form.id);
  };

  const providerValue = {
    ...state,
    form,
    isDirty,
    isFetched,
    completed,
    activeStep,
    medicalInfoPathname,
    initialFormValue,
    type,

    // functions
    setType,
    fetchForm,
    setIsDirty,
    handleBack,
    handleReset,
    setCompleted,
    setActiveStep,
    handleComplete,
    setInitialFormValue
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <FormContext.Provider value={providerValue}>
      {children}
    </FormContext.Provider>
  );
};
