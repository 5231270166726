import { useContext, useRef } from 'react';
import { debounce } from 'lodash';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import { TextField } from '../../../../../components/FormField';
import { statusesOptions, StatusesSelect } from '../../../CodesPage/StatusesSelect';
import { TypesContext } from '../TypesProvider';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';

const typesOptions = [
  {
    label: 'All types',
    value: null
  },
  {
    label: 'System',
    value: 1
  },
  {
    label: 'Public',
    value: 0
  }
];

export const initialValues = {
  name: null,
  is_system: typesOptions[0],
  is_active: statusesOptions[0]
};

export const FiltersBar = ({ hiddenFields = [], filterKey }) => {
  const formikRef = useRef();
  const { filter, applyFilter } = useContext(TypesContext);

  const handleFieldChange = debounce((event) => {
    const value = event.target.value;

    applyFilter(({ ...filter, name: value }));
  }, 600);

  const handleStatusSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.value });
  };

  return (
    <FiltersBarComponent
      isResetForm
      enableSettings
      disableTopPaddings
      formikRef={formikRef}
      filterKey={filterKey}
      initialValues={initialValues}
      hiddenFields={hiddenFields}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.name,
          label: filterFieldsLabels[filterFieldsMap.name],
          field: <TextField
            name="name"
            label="Search"
            placeholder="Search..."
            value={filter.name}
            onChange={handleFieldChange}
          />
        },
        {
          fieldKey: filterFieldsMap.is_system,
          label: filterFieldsLabels[filterFieldsMap.is_system],
          field: (
            <StatusesSelect
              disableClearable
              name="is_system"
              label="Type"
              placeholder="Select type..."
              options={typesOptions}
              onChange={handleStatusSelectChange('is_system')}
            />
          )
        },
        {
          fieldKey: filterFieldsMap.is_active,
          label: filterFieldsLabels[filterFieldsMap.is_active],
          field: (
            <StatusesSelect
              disableClearable
              name="is_active"
              label="Status"
              onChange={handleStatusSelectChange('is_active')}
            />
          )
        }
      ]}
    />
  );
};
