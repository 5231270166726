import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow header>
      <ListRowCell flexBasis={columnsWidths.dateFrom}>
        From
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateTo}>
        To
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.age}>
        Age
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.pressure}>
        Pressure
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.temperature}>
        Body temperature
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.weight}>
        Weight
      </ListRowCell>


      <ListRowCell flexBasis={columnsWidths.bmi}>
        BMI
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
