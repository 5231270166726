import { useContext, useRef } from 'react';
import { Box, List, Typography } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Sticky } from '../../../../../components/Sticky';
import { PrintersConfigurationContext } from '../PrintersConfigurationProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 50;

export const columnsMap = {
  name: 'name',
  desc: 'desc',
  directory: 'directory',
  date: 'date',
  staff: 'staff',
  manager: 'manager',
  action: 'action'
};

export const columnsWidths = {
  [columnsMap.name]: 100,
  [columnsMap.desc]: 150,
  [columnsMap.directory]: 150,
  [columnsMap.date]: 150,
  [columnsMap.staff]: 160,
  [columnsMap.manager]: 180,
  [columnsMap.action]: 80
};

export const PrintersConfigurationsList = () => {
  const { isFetched, printers } = useContext(PrintersConfigurationContext);
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader loading={!isFetched} p={2} render={
        () => !printers.length ? (
          <Box p={2}>
            <Typography align="center">There is no printer configurations</Typography>
          </Box>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader />
            </Sticky>

            <List disablePadding>
              {printers.map((printer) => (
                <Row key={printer.id} printer={printer} />
              ))}
            </List>
          </>
        )}
      />
    </CustomScrollbars>
  );
};
