export const styles = ({ palette, transitions, zIndex }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: ({ width }) => width,
    minWidth: ({ width }) => width,
    overflow: 'hidden',
    border: '1px solid',
    borderRightWidth: 0,
    borderColor: palette.grey[200],
    flexShrink: 0,
    transition: transitions.create()
  },

  rootMobile: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: palette.primary.contrastText,
    zIndex: zIndex.appBar + 2
  },

  root_collapsed: {
    marginLeft: ({ width }) => -width
  }
});
