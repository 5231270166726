import { Box, Grid } from '@material-ui/core';
import { TextField, SsnField, KeyboardDatePicker } from '../../../../../../components/FormField';
import { Divider } from '../../../../../../components/Divider';

export const Footer = () => {
  return (
    <Grid container spacing={2} component={Box} pt={2}>
      <Grid item xs={5}>
        <SsnField isEditable={false} name="ssn" />
      </Grid>

      <Grid item xs={7}>
        <TextField
          fullWidth
          isEditable={false}
          label="Occupation & Group number"
          name="occupation_group_number"
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          isEditable={false}
          label="DOB"
          name="dob"
        />
      </Grid>

      <Grid item xs={12}>
        <Divider gutter={1} type="dashed" />
      </Grid>

      <Grid item xs={6}>
        <KeyboardDatePicker
          isEditable={false}
          label="Date File Received"
          name="date_file_received"
        />
      </Grid>
    </Grid>
  );
};
