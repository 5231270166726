import { orderDirectionsMap } from '../../../../../components/FormField';

export const initialState = {
  filter: {
    providers: [],
    appointment_books: [],
    appointment_types: [],
    visit_reasons: [],
    order_direction: orderDirectionsMap.asc
  }
};
