import {
  makeStyles,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { UserLink } from '../../../../../../../../../../components/UserLink';
import { Popper } from '../../../../../../../../../../components/Popper';
import { rolesMap } from '../../../../../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../../../../../utils/hasRole';
import { CaseUserMenu } from './CaseUserMenu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Item = ({ caseItem, user, onCaseItemUpdate }) => {
  const classes = useStyles();
  const caseUser = user.user;

  return caseUser && (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs>
        <UserLink
          variant="h5"
          user={caseUser}
          caseUser={user}
        >
          <Typography color="textSecondary" variant="caption">
            {caseUser?.work?.position || 'No position info'}
          </Typography>
        </UserLink>
      </Grid>

      {!!user?.user?.id && !hasRole(rolesMap.patient, rolesMap.client) && (
        <Grid item>
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton
                  buttonRef={anchorRef}
                  onClick={handleToggle}
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <CaseUserMenu
                caseItem={caseItem}
                onCaseItemUpdate={onCaseItemUpdate}
                user={user}
                onClose={handleClose}
              />
            )}
          </Popper>
        </Grid>
      )}
    </Grid>
  );
};
