import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import {
  DatePicker,
  ImmunizationsSelect,
  ApptBooksSelect,
  OfficesLocationSelect
} from '../../../../../components/FormField';
import { IconComponent } from '../../../../../components/saved-filters';
import { saveFilter } from '../../../../../store/lastFilters';
import { transformRelationsForFilterToOptions } from './transformRelationsForFilterToOptions';
import { ImmunizationContext } from '../ImmunizationProvider';
import {
  filterFieldsLabels,
  filterFieldsMap
} from './filterFieldsMap';
import { initialValues } from './initialValues';
import { List } from './List';

const MODAL_WIDTH = 850;

export const FiltersBar = ({ filterKey, hiddenFilterFields = [] }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { applyFilter } = useContext(ImmunizationContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);

  const handleDatePickerChange = (name, transformer) => (date) => {
    applyFilter({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option ? option.value : null });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data }));
  };

  const handleOfficeChange = (office) => {
    applyFilter({ office_id: office?.id });
    setRelationsForFilter((state) => ({ ...state, office_id: office }));
  };

  const handleApptBookChange = (option) => {
    applyFilter({ appointment_book_id: option ? option.id : null });
    setRelationsForFilter((state) => ({ ...state, appointment_book_id: option }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(transformRelationsForFilterToOptions(filter));

    applyFilter({
      ...filter,
      office_id: filter?.office_id?.id,
      appointment_book_id: filter?.appointment_book_id?.id,
      immunization_id: filter?.immunization_id
    });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    applySavedFilter({
      ...relationsForFilter,
      ...lastFilters
    });
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={transformRelationsForFilterToOptions(initialValues)}
      filterKey={filterKey}
      hiddenFields={hiddenFilterFields}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      onReset={() => {
        applySavedFilter(initialValues);
      }}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.dateFrom,
          label: filterFieldsLabels[filterFieldsMap.dateFrom],
          field: (
            <DatePicker
              zeroMinWidth
              clearable
              minWidth={140}
              name="date_from"
              label="From"
              onChange={handleDatePickerChange('date_from')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.dateTo,
          label: filterFieldsLabels[filterFieldsMap.dateTo],
          field: (
            <DatePicker
              zeroMinWidth
              clearable
              minWidth={140}
              name="date_to"
              label="To"
              onChange={handleDatePickerChange('date_to')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.immunizationId,
          label: filterFieldsLabels[filterFieldsMap.immunizationId],
          field: (
            <ImmunizationsSelect
              name="immunization_id"
              onChange={handleSelectChange('immunization_id')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.appointmentBookId,
          label: filterFieldsLabels[filterFieldsMap.appointmentBookId],
          field: (
            <ApptBooksSelect
              name="appointment_book_id"
              onChange={handleApptBookChange}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.office,
          label: filterFieldsLabels[filterFieldsMap.office],
          field: (
            <OfficesLocationSelect
              name="office_id"
              onChange={handleOfficeChange}
            />
          )
        }
      ]}
    />
  );
};
