import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { api } from '../../../api';
import * as chatsApi from '../../../api/chat';
import { useThread } from '../utils/useThread';
import { ThreadMessagesFilterContext } from './ThreadMessagesFilterContext';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const ThreadMessagesContext = createContext(null);

export const ThreadMessagesContextProvider = ({ threadId, children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const currentUser = useSelector(({ profile }) => profile.user);
  const cancelFetch = useRef(() => {});
  const scrollerRef = useRef();
  const {
    threadMessages,
    pagination,
    isFetching,
    isFetched,
    filter
  } = state;
  const { filter: commonFilter } = useContext(ThreadMessagesFilterContext);

  const fetchMessages = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.MESSAGES_FETCH_REQUEST });

    chatsApi.fetchMessages({
      params: { 'thread[id]': threadId, ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.MESSAGES_FETCH_SUCCESS, payload: data });
    });
  };

  const addMessage = (message) => {
    dispatch({ type: types.ADD_MESSAGE, payload: message });
    scrollerRef?.current?.scrollToBottom();
  };

  const deleteMessages = (messagesIds) => {
    dispatch({ type: types.DELETE_MESSAGE, payload: messagesIds });
  };

  const updateMessage = (updatedMessage) => {
    dispatch({ type: types.UPDATE_MESSAGE, payload: updatedMessage });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchMessages({ page: filter.page + 1 });
    }
  };

  const resetMessages = (newFilter) => {
    dispatch({ type: types.RESET_MESSAGES, payload: newFilter });

    fetchMessages({ page: 1, ...newFilter });
  };

  const providerValue = {
    threadMessages,
    pagination,
    isFetching,
    isFetched,
    filter: {
      ...filter,
      ...pagination
    },
    scrollerRef,

    fetchMessages,
    addMessage,
    deleteMessages,
    updateMessage,
    loadNextPage,
    resetMessages
  };

  useThread({
    threadId,

    onMessageCreated: ({ message }) => {
      addMessage(message);

      if (message?.user?.id !== currentUser.id) {
        chatsApi.threadSeen(threadId);
      }
    },

    onMessageUpdated: ({ message }) => {
      if (message?.user?.id !== currentUser.id) {
        updateMessage(message);
      }
    },

    onMessageDeleted: ({ message_id }) => {
      deleteMessages([ message_id ]);
    },

    onThreadSeen: () => {
      dispatch({ type: types.MARK_THREAD_AS_SEEN, payload: { currentUser } });
    }
  });

  useEffect(() => {
    if (!isEqual(filter, { ...filter, ...commonFilter })) {
      resetMessages({ ...filter, ...commonFilter });
    }
  }, [ commonFilter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <ThreadMessagesContext.Provider value={providerValue}>
      {children}
    </ThreadMessagesContext.Provider>
  );
};
