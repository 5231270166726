import { createContext, useEffect, useReducer, useRef } from 'react';
import { api } from '../../../../../api';
import * as videoApi from '../../../../../api/videos';
import { useModal } from '../../../../../components/ModalsProvider';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { RenameVideoModal } from '../../RenameVideoModal';
import { UploadVideoModal } from '../../UploadVideoModal';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const FragmentsContext = createContext(null);

export const FragmentsContextProvider = ({ children, video, lastCreatedFragment }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    isFetched,
    isFetching,
    filter,
    pagination,
    fragments
  } = state;
  const { openModal } = useModal();
  const cancelFetch = useRef(() => {});

  const fetchFragments = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_FRAGMENTS_REQUEST });

    videoApi.fetchFragments(video.id, {
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_FRAGMENTS_SUCCESS, payload: data });
    });
  };

  const resetFragments = (newFilter) => {
    dispatch({ type: types.RESET_FRAGMENTS, payload: newFilter });

    fetchFragments({ page: 1, ...newFilter });
  };


  const deleteFragment = (video) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        videoApi.deleteFragment(video.media, video.id).then(() => {
          dispatch({ type: types.DELETE_FRAGMENT, payload: video });
        });
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchFragments({ page: filter.page + 1 });
    }
  };

  const uploadFragment = () => {
    openModal(UploadVideoModal, {
      onModalResolved: () => {
        resetFragments({ page: 1 });
      }
    });
  };

  const renameFragment = (file) => {
    openModal(RenameVideoModal, {
      payload: { file }, onModalResolved: () => {
        resetFragments({ page: 1 });
      }
    });
  };

  const providerValue = {
    fragments,
    isFetching,
    isFetched,
    filter,
    pagination,

    // functions
    fetchFragments,
    resetFragments,
    loadNextPage,
    deleteFragment,
    uploadFragment,
    renameFragment
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  useEffect(() => {
    if (lastCreatedFragment) {
      dispatch({ type: types.ADD_FRAGMENT, payload: lastCreatedFragment });
    }
  }, [ lastCreatedFragment ]);

  return (
    <FragmentsContext.Provider value={providerValue}>
      {children}
    </FragmentsContext.Provider>
  );
};
