import _ from 'lodash';
import { Fab, List, ListItemIcon, ListItemText, MenuItem, SvgIcon } from '@material-ui/core';
import { mdiFileMultipleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { FileDropZoneWindow } from '../../../../components/FileDropZoneWindow';
import { Popper } from '../../../../components/Popper';
import { ContentCard } from '../../../../components/ContentCard';
import { useModal } from '../../../../components/ModalsProvider';
import { WidgetsActionButtons } from '../../../../components/WidgetsActionButtons';
import {
  FilesFilterContextProvider,
  FilesContextProvider,
  SelectAvailableFilesModal,
  FileUploadModal,
  Attachments
} from '../../files-common';

export const FilesCardWidget = ({
  height = null,
  page,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  disableRightActions,
  onCaseItemUpdate = () => {},
  onCaseUsersChange = () => {},
  caseUsers = [],
  setCaseUsers = () => {},
  title = 'Files',
  payload: {
    files = [],
    filter = {},
    ownerType = null,
    ownerID = null
  },
  ...props
}) => {
  const { openModal } = useModal();

  const initialStateOfFiles = {
    isFetched: true,
    files,
    filter
  };

  const uploadFiles = (files = []) => {
    openModal(FileUploadModal, {
      payload: {
        files,
        owner_type: ownerType,
        owner_id: ownerID
      }
    });
  };

  const handleUploadFilesButtonClicked = () => {
    uploadFiles();
  };

  const openAvailableFilesModal = () => {
    openModal(SelectAvailableFilesModal, {
      payload: {
        owner_type: ownerType,
        owner_id: ownerID
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!disableRightActions || !props.autoHeightMax}
      disableScrollbars
      disableContentPaddings
      title={title}
      icon={
        <Popper
          anchorRenderer={({ anchorRef, handleToggle }) => (
            <Fab
              buttonRef={anchorRef}
              color="primary"
              size="small"
              onClick={handleToggle}
            >
              <AddIcon fontSize="small" />
            </Fab>
          )}
        >
          {({ handleClose }) => (
            <List>
              <MenuItem onClick={_.flow(handleUploadFilesButtonClicked, handleClose)}>
                <ListItemIcon>
                  <CloudUploadIcon fontSize="inherit" />
                </ListItemIcon>

                <ListItemText primary="Upload new file" />
              </MenuItem>

              <MenuItem onClick={_.flow(openAvailableFilesModal, handleClose)}>
                <ListItemIcon>
                  <FileCopyIcon fontSize="inherit" />
                </ListItemIcon>

                <ListItemText primary="Select from available" />
              </MenuItem>
            </List>
          )}
        </Popper>
      }
      leftActions={[
        <SvgIcon fontSize="small"><Icon path={mdiFileMultipleOutline} /></SvgIcon>
      ]}
      rightActions={[ !disableRightActions &&
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <FilesFilterContextProvider>
        <FileDropZoneWindow onDropAccepted={uploadFiles}>
          <FilesContextProvider initialState={initialStateOfFiles}>
            <Attachments height={height} />
          </FilesContextProvider>
        </FileDropZoneWindow>
      </FilesFilterContextProvider>
    </ContentCard>
  );
};
