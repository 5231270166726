import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { usePrevious } from '../../../../../helpers/hooks';
import * as waitingListApi from '../../../../../api/appointments';
import { api } from '../../../../../api';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { WaitingListFilterContext } from '../WaitingListFilterProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const WaitingListContext = createContext();

export const WaitingListProvider = ({ children, initialState: initialStateProp = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { filter: commonFilter } = useContext(WaitingListFilterContext);
  const { waiting_list, pagination, filter } = state;
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchWaitingList = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.LIST_FETCH_REQUEST });

    waitingListApi.fetchWaitingList({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.LIST_FETCH_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchWaitingList({ page: filter.page + 1 });
    }
  };

  const updateWaitingListItem = (item) => {
    dispatch({ type: types.UPDATE_ITEM, payload: item });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetWaitingList = (newFilter = {}) => {
    dispatch({ type: types.RESET_LIST, payload: newFilter });

    fetchWaitingList({ page: 1, ...newFilter });
  };

  const deleteWaitingListItem = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        waitingListApi.deleteWaitingListItem(id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchWaitingList({ page: filter.page });
          } else if (waiting_list.length - 1 === 0 && filter.page !== 1) {
            fetchWaitingList({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_ITEM, payload: id });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Item is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const providerValue = {
    ...state,
    waiting_list,
    pagination,
    filter,
    meta: {
      ...pagination,
      ...filter
    },

    // functions
    applyFilter,
    fetchWaitingList,
    resetWaitingList,
    loadNextPage,
    updateWaitingListItem,
    deleteWaitingListItem
  };

  useEffect(() => {
    const newFilter = { ...filter, ...commonFilter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, commonFilter ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetWaitingList();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <WaitingListContext.Provider value={providerValue}>
      {children}
    </WaitingListContext.Provider>
  );
};
