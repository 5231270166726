import { makeStyles } from '@material-ui/core';
import { TextField } from '../../../../TextField';
import { styles } from './styles';

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const useStyles = makeStyles(styles);

export const Control = ({
  selectProps: { label, value, error, isSearchable, ...selectProps },
  innerProps,
  isFocused,
  innerRef,
  hasValue,
  children,
  isMulti
}) => {
  const { InputProps, InputLabelProps, ...TextFieldProps } = selectProps.TextFieldProps || {};
  const classes = useStyles({ isMulti, hasValue, isSearchable, TextFieldProps });

  return (
    <TextField
      fullWidth
      label={label}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          inputRef: innerRef,
          children: children,
          ...innerProps,
          value: selectProps.inputValue
        },
        ...InputProps
      }}
      InputLabelProps={{
        shrink: isFocused || hasValue,
        classes: {
          root: classes.label,
          shrink: classes.label_shrink
        },
        title: label,
        ...InputLabelProps
      }}
      error={!!error}
      helperText={error}

      {...TextFieldProps}
    />
  );
};
