import moment from 'moment';
import { Typography, makeStyles, Box, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { stopPropagation } from '../../../../../helpers/dom';
import { workClockTypesMap } from '../../../../../store/dashboard/workClock/workClockTypesMap';
import { useWorkSession } from '../../../useWorkSession';
import { VerticalDivider } from '../../../../VerticalDivider';
import { IconButton } from '../../../../IconButton';
import { UserLink } from '../../../../UserLink';
import { Tooltip } from '../../../../Tooltip';
import { Divider } from '../../../../Divider';
import { CardItem } from '../../../../Cards';
import { Date } from '../../../../Date';
import { TracksTooltip } from '../../TracksTooltip';
import { useLiveSessionDuration } from '../../useLiveSessionDuration';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ item: workSession }) => {
  const classes = useStyles();
  const {
    hasAccess,
    addTrack,
    getTracksByType,
    openWorkingSessionInfoModal,
    deleteWorkSession
  } = useWorkSession(workSession);
  const liveDuration = useLiveSessionDuration(workSession.session_tracks, workSession.active_track);

  return (
    <CardItem onClick={openWorkingSessionInfoModal}>
      <div className={classes.body}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography>User:</Typography>
          </Grid>

          <Grid item xs={8}>
            <UserLink
              size="sm"
              variant="h5"
              user={workSession.user}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography>Start:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Date disableIcon date={workSession.started_at} format="L LT" />
          </Grid>

          <Grid item xs={4}>
            <Typography>End:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Date disableIcon date={workSession.finished_at} format="L LT" />
          </Grid>

          <Grid item xs={4}>
            <Typography>Duration:</Typography>
          </Grid>

          <Grid item xs={8}>
            {workSession.finished_at ? (
              <Tooltip
                isExistTooltip={getTracksByType(workClockTypesMap.work).length}
                title={<TracksTooltip tracks={getTracksByType(workClockTypesMap.work)} />}
              >
                <Typography>
                  {moment.duration(workSession.time_info.work, 'seconds').format('h[h] m[min]')}
                </Typography>
              </Tooltip>
            ) : (
              <Box color="success.main">
                {liveDuration}
              </Box>
            )}
          </Grid>

          <Grid item xs={4}>
            <Typography>Breaks:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Tooltip
              isExistTooltip={getTracksByType(workClockTypesMap.break).length}
              title={<TracksTooltip tracks={getTracksByType(workClockTypesMap.break)} />}
            >
              <Typography>
                {moment.duration(workSession.time_info.break, 'seconds').format('h[h] m[min]')}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid item xs={4}>
            <Typography>Lunches:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Tooltip
              isExistTooltip={getTracksByType(workClockTypesMap.lunch).length}
              title={<TracksTooltip tracks={getTracksByType(workClockTypesMap.lunch)} />}
            >
              <Typography>
                {moment.duration(workSession.time_info.lunch, 'seconds').format('h[h] m[min]')}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </div>

      <Divider />

      <Box display="flex" alignItems="center" alignContent="center" height={46}>
        <Box width="50%" display="flex" justifyContent="center">
          <Tooltip title="Add track">
            <div>
              <IconButton
                disabled={!hasAccess}
                color="primary"
                onClick={stopPropagation(addTrack)}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Box>

        <VerticalDivider />

        <Box width="50%" display="flex" justifyContent="center">
          <Tooltip title="Delete session">
            <IconButton
              color="error"
              onClick={stopPropagation(deleteWorkSession)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </CardItem>
  );
};
