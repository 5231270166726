import { Box } from '@material-ui/core';
import { TextField } from '../../../../../../../../../components/FormField';
import { UserInfo } from '../UserInfo';

export const Employer = () => {
  return (
    <Box my={2}>
      <UserInfo />

      <TextField
        required
        margin="dense"
        name="position"
        label="Position"
      />
    </Box>
  );
};
