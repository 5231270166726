import { fieldsKeysMap } from './FiltersBar/filterKeysMap';

export const filterFieldsMap = {
  search: 'search',
  types: 'types',
  statuses: 'statuses',
  created_from: 'created_from',
  created_to: 'created_to',
  due_from: 'due_from',
  due_to: 'due_to',
  users: 'users',
  priority: 'priority'
};

export const filterFieldsLabels = {
  [filterFieldsMap.search]: 'Search',
  [filterFieldsMap.types]: 'Filter by types',
  [filterFieldsMap.statuses]: 'Filter by statuses',
  [filterFieldsMap.created_from]: 'Create date from',
  [filterFieldsMap.created_to]: 'Create date to',
  [filterFieldsMap.due_from]: 'Due date from',
  [filterFieldsMap.due_to]: 'Due date to',
  [fieldsKeysMap.users]: 'Users',
  [fieldsKeysMap.priority]: 'Priority'
};
