import { SvgIcon } from '@material-ui/core';
import { mdiCircleMedium, mdiFax } from '@mdi/js';
import { Icon } from '@mdi/react';

export const faxes = {
  name: 'Faxes',
  path: '/faxes/income',
  icon: (props) => <SvgIcon {...props}><Icon path={mdiFax} /></SvgIcon>,
  routes: [
    {
      exact: true,
      name: 'Received Faxes',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiCircleMedium} />
        </SvgIcon>
      ),
      path: '/faxes/income'
    },
    {
      name: 'Sent Faxes',
      icon: (props) => (
        <SvgIcon {...props}>
          <Icon path={mdiCircleMedium} />
        </SvgIcon>
      ),
      path: '/faxes/outcome'
    }
  ]
};
