export const levelsOptions = [
  { value: 'C1-C2', label: 'C1-C2' },
  { value: 'C2-C3', label: 'C2-C3' },
  { value: 'C3-C4', label: 'C3-C4' },
  { value: 'C4-C5', label: 'C4-C5' },
  { value: 'C5-C6', label: 'C5-C6' },
  { value: 'C6-C7', label: 'C6-C7' },
  { value: 'C7-T1', label: 'C7-T1' },
  { value: 'T1-T2', label: 'T1-T2' },
  { value: 'T2-T3', label: 'T2-T3' },
  { value: 'T3-T4', label: 'T3-T4' },
  { value: 'T4-T5', label: 'T4-T5' },
  { value: 'T5-T6', label: 'T5-T6' },
  { value: 'T6-T7', label: 'T6-T7' },
  { value: 'T7-T8', label: 'T7-T8' },
  { value: 'T8-T9', label: 'T8-T9' },
  { value: 'T9-T10', label: 'T9-T10' },
  { value: 'T10-T11', label: 'T10-T11' },
  { value: 'T11-T12', label: 'T11-T12' },
  { value: 'T12-L1', label: 'T12-L1' },
  { value: 'L1-L2', label: 'L1-L2' },
  { value: 'L2-L3', label: 'L2-L3' },
  { value: 'L3-L4', label: 'L3-L4' },
  { value: 'L4-L5', label: 'L4-L5' },
  { value: 'L5-S1', label: 'L5-S1' },
  { value: 'S1', label: 'S1' }
];
