import { useContext, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import { filtersKeysMap, saveFilter } from '../../../store/lastFilters';
import { FiltersBar as FiltersBarComponent } from '../../../components/FiltersBar';
import { TextField, KeyboardDatePicker } from '../../../components/FormField';
import { OrderBy } from '../../../components/FormField';
import { FilesFilterContext } from '../../Dashboard/files-common';
import { orderByOptions } from './orderByOptions';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FiltersBar = () => {
  const classes = useStyles();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filtersKeysMap.files_myFiles]);
  const { filter, applyFilter } = useContext(FilesFilterContext);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const toggleOrderDirection = (orderDirection) => {
    applyFilter({ order_direction: orderDirection });
  };

  const handleFieldChange = (name) => debounce((event) => {
    const value = event.target.value;

    applyFilter({ [name]: value });
    setRelationsForFilter((state) => ({ ...state, [name]: value }));
  }, 600);

  const handleSelectChange = (name) => (option) => {
    applyFilter({ [name]: option?.value });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data }));
  };

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues({
      ...filter,

      order_by: orderByOptions.find(({ value } ) => value === filter.order_by)
    });
  };

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter(lastFilters);
    }
  }, []);

  useEffect(() => {
    dispatch(saveFilter({
      key: filtersKeysMap.files_myFiles,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  return (
    <FiltersBarComponent
      isResetForm
      className={classes.root} ToolbarProps={{ className: classes.toolbar }}
      formikRef={formikRef}
      initialValues={{
        order_by: orderByOptions.find(({ value }) => value === filter.order_by)
      }}
      fields={[
        <TextField
          name="original_filename"
          label="Search"
          placeholder="Search..."
          onChange={handleFieldChange('original_filename')}
        />,

        <Box minWidth={140}>
          <KeyboardDatePicker
            clearable
            disableFuture
            name="updated_after"
            label="Updated after"
            onChange={handleDatePickerChange('updated_after')}
          />
        </Box>,

        <Box minWidth={140}>
          <KeyboardDatePicker
            clearable
            disableFuture
            name="updated_before"
            label="Updated before"
            onChange={handleDatePickerChange('updated_before')}
          />
        </Box>,

        <OrderBy
          isSearchable={false}
          name="order_by"
          value={orderByOptions.find(({ value }) => value === filter.order_by)}
          options={orderByOptions}
          orderDirection={filter.order_direction}
          onOptionChange={handleSelectChange('order_by')}
          onOrderDirectionChange={toggleOrderDirection}
        />
      ]}
    />
  );
};
