import { Typography, Box } from '@material-ui/core';
import { Contact } from '../../../../../../../../components/Contact';
import { Tooltip } from '../../../../../../../../components/Tooltip';

export const Body = ({ contact }) => {
  const { contact: user } = contact;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px={1}
    >
      <Tooltip
        isExistTooltip={!!user?.office?.venue?.full_address}
        title={user?.office?.venue?.full_address}
      >
        <Typography noWrap align="center">
          {user?.office?.venue?.full_address || '-'}
        </Typography>
      </Tooltip>

      <Contact
        noWrap
        type="mailto"
        color="primary"
        contact={user.email}
      />

      <Contact
        noWrap
        type="tel"
        contact={user.phone}
      />
    </Box>
  );
};
