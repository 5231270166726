import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_CASES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_CASES_SUCCESS]: (
    { filter, cases, ...state },
    { data: newCases, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      cases: page > 1 ? (
        cases.filter(({ id }) => {
          return !newCases.find((loadedReminderEvent) => id === loadedReminderEvent.id);
        }).concat(newCases)
      ) : (
        newCases
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_CASES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  }
});
