import { makeStyles } from '@material-ui/core';
import * as pages from '../../../../../../store/dashboard/layout/pages';
import { Widgets } from '../../../../../../components/Widgets';
import { widgetsMap } from '../Widgets/widgetsMap';
import { cardsLayout, checkedWidgetsTypes, extraTypes } from './ClientLayouts';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ClientCase = ({
  widgetFilters = {},
  caseItem,
  updateFilters = () => {},
  onCaseItemUpdate
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Widgets
        onCaseItemUpdate={onCaseItemUpdate}
        caseItem={caseItem}
        widgetFilters={widgetFilters}
        updateFilters={updateFilters}

        // widgets
        rowHeight={25}
        page={pages.clientCases}
        extraTypes={extraTypes}
        checkedWidgetsTypes={checkedWidgetsTypes}
        widgetsMap={widgetsMap}
        cardsLayout={cardsLayout}
      />
    </div>
  );
};
