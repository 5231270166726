import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';

import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SignatureImage = ({ url = null, classname }) => {
  const classes = useStyles();

  return url && (
    <img
      src={url}
      alt="signature"
      className={classnames(classes.image, classname)}
    />
  );
};
