import { useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  makeStyles,
  List,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SvgIcon
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { downloadFile as download } from '../../../../helpers/files';
import { VideosContext } from '../VideosContext';

const styles = () => ({
  root: {
    minWidth: 172
  }
});

const useStyles = makeStyles(styles);

export const VideosMenu = withRouter(({ video }) => {
  const classes = useStyles();

  const { deleteVideo, renameVideo } = useContext(VideosContext);

  const downloadVideo = () => {
    download({ src: video.url, name: video.original_filename });
  };

  const handleDeleteVideo = () => {
    deleteVideo(video);
  };

  const handleRenameVideo = () => {
    renameVideo(video);
  };

  return (
    <List className={classes.root}>
      <MenuItem component={Link} to={`/videos/${video.id}`}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Open" />
      </MenuItem>

      <MenuItem onClick={downloadVideo}>
        <ListItemIcon>
          <DownloadIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Download" />
      </MenuItem>

      <MenuItem onClick={handleRenameVideo}>
        <ListItemIcon>
          <EditIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Rename" />
      </MenuItem>

      <MenuItem onClick={handleDeleteVideo}>
        <ListItemIcon>
          <SvgIcon fontSize="inherit">
            <Icon path={mdiTrashCanOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
});
