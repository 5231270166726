import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { mdiCalendarEdit } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as immunizationApi from '../../../../../api/profile';
import { ApptInfo } from '../../../../../components/appointments';
import { Loader } from '../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../components/Modal';
import { transformImmunizationToOption } from '../../../../../components/FormField';
import { validationSchema as ImmunizationsValidationSchema } from '../CreateImmunizationModal';
import { ApptImmunization } from '../CreateImmunizationModal/ApptImmunization';

export const EditImmunizationModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { ID, onUpdate = () => {} }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ initialValues, setInitialValues ] = useState(null);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const updateImmunizationReport = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return immunizationApi.updateImmunizationReport({ id: ID, ...values }).then((data) => {
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      onUpdate();
      handleModalResolve(data);
    }).catch(({ errors }) => {
      if (errors) {
        enqueueSnackbar('Immunization report not updated', { variant: 'error' });
        setErrors(errors);
      }
    });
  };

  useEffect(() => {
    immunizationApi.fetchImmunizationReport(ID).then(setInitialValues);
  }, []);

  return (
    <Dialog fullScreen={isTablet} PaperProps={{ style: { maxWidth: 1100 } }} {...DialogProps}>
      <Loader p={3} loading={!initialValues} render={
        () => (
          <Formik
            enableReinitialize
            initialValues={{
              ...initialValues,

              patient_id: initialValues?.patient,
              approved_id: initialValues?.approved,
              appointment_id: initialValues?.appointment,
              office_id: initialValues?.office,
              immunization_id: transformImmunizationToOption(initialValues?.immunization),
              vaccine_description: initialValues?.immunization_id?.description
            }}
            validationSchema={ImmunizationsValidationSchema}
            onSubmit={updateImmunizationReport}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader icon={<SvgIcon><Icon path={mdiCalendarEdit} /></SvgIcon>} onClose={handleModalReject}>
                  Edit Immunization
                </ModalHeader>

                <ModalBody>
                  <ApptInfo />
                  <ApptImmunization />
                </ModalBody>

                <ModalFooter>
                  <Button type="reset" onClick={handleModalReject}>
                    Cancel
                  </Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
