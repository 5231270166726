export const trackTypesMap = {
  work: 'work',
  break: 'break',
  lunch: 'lunch'
};

export const trackTypesLabelsMap = {
  [trackTypesMap.work]: 'Work',
  [trackTypesMap.break]: 'Break',
  [trackTypesMap.lunch]: 'Lunch'
};

export const trackTypesOptions = [
  {
    value: trackTypesMap.work,
    label: trackTypesLabelsMap[trackTypesMap.work]
  },
  {
    value:  trackTypesMap.break,
    label: trackTypesLabelsMap[trackTypesMap.break]
  },
  {
    value:  trackTypesMap.lunch,
    label: trackTypesLabelsMap[trackTypesMap.lunch]
  }
];
