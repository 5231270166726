import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { omit } from 'lodash';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../../utils/hasRole';
import { saveFilter } from '../../../../../../store/lastFilters';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../../../helpers/dates';
import { IconComponent } from '../../../../../../components/saved-filters';
import { FiltersBar } from '../../../../../../components/FiltersBar';
import {
  OrderBy,
  TextField,
  KeyboardDatePicker,
  InsurancesSelect,
  MedicalFormsTypesSelect
} from '../../../../../../components/FormField';
import { UsersSelect } from '../../../../../../components/users';
import { MedicalFormsContext } from '../MedicalFormsProvider';
import { columnsWidths, SavedFiltersList } from './SavedFiltersList';
import { transformRelationsForFilterToOptions } from './utils';
import { fieldsKeysMap, filterLabels } from './filterKeysMap';
import { orderByOptions } from './orderByOptions';
import { CardContent } from './CardContent';

const MODAL_WIDTH = 1450;

export const initialValues = {
  order_by: orderByOptions[0]
};

export const FormFiltersBar = ({
  filterKey,
  hiddenFilterFields = [],
  resetForms = () => {}
}) => {
  const formikRef = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { filter } = useContext(MedicalFormsContext);
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const isDoctor = pathname === '/medical-forms' && hasRole(rolesMap.doctor);
  const currentUser = useSelector(({ profile }) => profile.user);
  const [ relationsForFilter, setRelationsForFilter ] = useState({
    order_by: orderByOptions[0]
  });

  const filtersModalWidth = hiddenFilterFields.includes(fieldsKeysMap.formType)
    ? MODAL_WIDTH - columnsWidths.medicalFormType
    : MODAL_WIDTH;

  const handleFieldChange = (name) => (event) => {
    resetForms({ [name]: event.target.value });
    setRelationsForFilter((state) => ({ ...state, [name]: event.target.value }));
  };

  const handleSelectChange = (name) => (option) => {
    resetForms({ [name]: option?.value });
    setRelationsForFilter((state) => ({ ...state, [name]: option?.data || option }));
  };

  const handleUserSelect = (name) => (option) => {
    resetForms({ [name]: option?.id });
    setRelationsForFilter((state) => ({ ...state, [name]: option }));
  };

  const handleUsersMultiSelectChange = (options) => {
    const users = options?.map(({ id }) => id) || null;

    resetForms({ users });
    setRelationsForFilter((state) => ({ ...state, users: options }));
  };

  const handleInsuranceSelect = (option) => {
    resetForms({ 'insuranceId': option?.insuranceId });
    setRelationsForFilter((state) => ({ ...state, 'insuranceId': option }));
  };

  const handleDatePickerChange = (name, transformer) => (date) => {
    resetForms({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const toggleOrderDirection = (orderDirection) => {
    resetForms({ order_direction: orderDirection });
    setRelationsForFilter((state) => ({ ...state, order_direction: orderDirection }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(transformRelationsForFilterToOptions(omit(filter), hiddenFilterFields));

    resetForms(omit({
      ...filter,

      form_type: filter?.form_type?.value,
      treating_physician_id: filter?.treating_physician_id?.id,
      insuranceId: filter?.insuranceId?.insuranceId,
      users: filter?.users?.map(({ id }) => id),
      order_by: filter?.order_by?.value
    }, hiddenFilterFields));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter({
        ...lastFilters,
        ...relationsForFilter,
        treating_physician_id: isDoctor &&
          !lastFilters?.treating_physician_id?.id ? currentUser : lastFilters?.treating_physician_id,
        order_by: lastFilters?.order_by
          ? orderByOptions.find(({ value }) => lastFilters?.order_by === value)
          : orderByOptions[0]
      });

    } else {
      isDoctor && applySavedFilter({
        treating_physician_id: currentUser,
        order_by: orderByOptions.find(({ value }) => lastFilters?.order_by === value)
      });
    }
  }, []);

  return (
    <FiltersBar
      isResetForm
      disableTopPaddings
      enableSettings
      formikRef={formikRef}
      initialValues={initialValues}
      hiddenFields={hiddenFilterFields}
      filterKey={filterKey}
      iconComponent={
        <IconComponent
          modalWidth={filtersModalWidth}
          filterKey={filterKey}
          ListComponent={SavedFiltersList}
          CardContent={CardContent}
          hiddenFields={hiddenFilterFields}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      onReset={() => applySavedFilter(initialValues)}
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.search,
          label: filterLabels[fieldsKeysMap.search],
          field: (
            <TextField
              name="search"
              label="Patient name"
              placeholder="Search..."
              margin="dense"
              onChange={handleFieldChange('search')}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.users,
          label: filterLabels[fieldsKeysMap.users],
          field: (
            <UsersSelect
              multiple
              name="users"
              label="Patients"
              params={{ is_patient: 1 }}
              margin="dense"
              minWidth={210}
              onChange={handleUsersMultiSelectChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.dateFrom,
          label: filterLabels[fieldsKeysMap.dateFrom],
          field: (
            <KeyboardDatePicker
              disableFuture
              clearable
              name="from"
              label="From"
              margin="dense"
              onChange={handleDatePickerChange('from', unixToStartOfDayUnix)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.dateTo,
          label: filterLabels[fieldsKeysMap.dateTo],
          field: (
            <KeyboardDatePicker
              disableFuture
              clearable
              name="to"
              label="To"
              margin="dense"
              onChange={handleDatePickerChange('to', unixToEndOfDayUnix)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.formType,
          label: filterLabels[fieldsKeysMap.formType],
          field: (
            <Box minWidth={200}>
              <MedicalFormsTypesSelect
                isClearable
                name="form_type"
                label="Type of Medical Form"
                TextFieldProps={{ margin: 'dense' }}
                onChange={handleSelectChange('form_type')}
              />
            </Box>
          )
        },
        {
          fieldKey: fieldsKeysMap.treatingPhysicianId,
          label: filterLabels[fieldsKeysMap.treatingPhysicianId],
          field: (
            <UsersSelect
              name="treating_physician_id"
              label="Physician"
              params={{ role: 'doctor' }}
              onChange={handleUserSelect('treating_physician_id')}
              margin="dense"
              minWidth={210}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.insuranceId,
          label: filterLabels[fieldsKeysMap.insuranceId],
          field: (
            <Box minWidth={200}>
              <InsurancesSelect
                name="insuranceId"
                label="Insurance ID"
                margin="dense"
                params={{ hasInsuranceId: 1 }}
                onChange={handleInsuranceSelect}
              />
            </Box>
          )
        },
        {
          fieldKey: fieldsKeysMap.fromDoi,
          label: filterLabels[fieldsKeysMap.fromDoi],
          field: (
            <KeyboardDatePicker
              disableFuture
              clearable
              name="from_doi"
              label="From DOI"
              margin="dense"
              onChange={handleDatePickerChange('from_doi', unixToStartOfDayUnix)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.toDoi,
          label: filterLabels[fieldsKeysMap.toDoi],
          field: (
            <KeyboardDatePicker
              disableFuture
              clearable
              name="to_doi"
              label="To DOI"
              margin="dense"
              onChange={handleDatePickerChange('to_doi', unixToEndOfDayUnix)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.orderBy,
          label: filterLabels[fieldsKeysMap.orderBy],
          field: (
            <Box minWidth={isMobile ? '100%' : 160}>
              <OrderBy
                isSearchable={false}
                name="order_by"
                options={orderByOptions}
                orderDirection={filter.order_direction}
                TextFieldProps={{ margin: 'dense' }}
                onOptionChange={handleSelectChange('order_by')}
                onOrderDirectionChange={toggleOrderDirection}
              />
            </Box>
          )
        }
      ]}
    />
  );
};
