import { Select } from '../Select';
import { Option } from './Option';
import { SingleValue } from './SingleValue';

const components = {
  SingleValue,
  Option
};

export const LabelSelect = (props) => {
  return (
    <Select components={components} {...props} />
  );
};
