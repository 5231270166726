import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../SavedFiltersList';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.phone}>
          Phone
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.address}>
          Address
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.doi}>
          DOI
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
