import { SvgIcon } from '@material-ui/core';
import { Icon } from '@mdi/react';
import {
  mdiHeartPulse,
  mdiFileAccountOutline,
  mdiFileDocumentOutline,
  mdiFileMultipleOutline,
  mdiAccountGroupOutline,
  mdiFileDocumentEditOutline,
  mdiCellphoneMessage
} from '@mdi/js';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import { Members } from '../../../../components/users/Members';
import { CallReports } from '../../Members/User/MainContent/CallReports';
import { MedicalProfile } from '../../Members/User/MainContent/MedicalInfo/MainInfo';
import { MedicalForms } from '../../ProfilePage/MedicalInfo/MedicalForms';
import { Appointments } from '../../Members/User/MainContent/Appointments';
import { NotificationsPage } from '../NotificationsPage';
import { Claims } from './Claims';
import { UserComments } from './UserComments';
import { AuthorizationsPage } from './AuthorizationsPage';
import { Emails } from './Emails';
import { Files } from './Files';

export const routes = [
  {
    path: '/patients/:patientID/medical-info/medical-forms',
    icon: <SvgIcon><Icon path={mdiFileAccountOutline} /></SvgIcon>,
    label: 'Medical Forms',
    component: MedicalForms
  },
  {
    path: '/patients/:patientID/medical-info/medical-report',
    icon: <SvgIcon><Icon path={mdiFileDocumentOutline} /></SvgIcon>,
    label: 'Medical Report'
  },
  {
    exact: true,
    path: '/patients/:patientID/medical-info',
    icon: <SvgIcon><Icon path={mdiHeartPulse} /></SvgIcon>,
    label: 'Medical Info',
    component: MedicalProfile
  },
  {
    path: '/patients/:patientID/medical-info/appointments',
    icon: <InsertInvitationOutlinedIcon />,
    label: 'Appointments',
    component: Appointments
  },
  {
    exact: true,
    path: '/patients/:patientID/medical-info/call-reports',
    icon: <PhoneOutlinedIcon />,
    label: 'Call Reports',
    component: CallReports
  },
  {
    path: '/patients/:patientID/medical-info/authorizations',
    icon: <LocalHospitalOutlinedIcon />,
    label: 'Authorizations',
    component: AuthorizationsPage
  },
  {
    path: '/patients/:patientID/medical-info/files',
    icon: <SvgIcon><Icon path={mdiFileMultipleOutline} /></SvgIcon>,
    label: 'Files',
    component: Files
  },
  {
    path: '/patients/:patientID/medical-info/emails',
    icon: <EmailIcon />,
    label: 'Emails',
    component: Emails
  },
  {
    path: '/patients/:patientID/medical-info/claims',
    icon: <SvgIcon><Icon path={mdiFileDocumentEditOutline} /></SvgIcon>,
    label: 'Claims',
    component: (props) => <Claims view="modal" {...props} />
  },
  {
    path: '/patients/:patientID/medical-info/members',
    icon: <SvgIcon><Icon path={mdiAccountGroupOutline} /></SvgIcon>,
    label: 'Members',
    component: Members
  },
  {
    path: '/patients/:patientID/medical-info/comments',
    icon: <CommentOutlinedIcon />,
    label: 'Comments',
    component: UserComments
  },
  {
    path: '/patients/:patientID/medical-info/notifications',
    icon: <SvgIcon><Icon path={mdiCellphoneMessage} /></SvgIcon>,
    label: 'Notifications',
    component: NotificationsPage
  }
];

export const getRoute = (path, params = {}) => ({ params, path })?.path;
