import { PaymentHistory } from '../PaymentHistory';
import { TaskPriorityStatistics } from '../TaskPriorityStatistics';
import { CasesStatusStatistics } from '../CasesStatusStatistics';
import { TaskStatusStatistics } from '../TaskStatusStatistics';
import { CasesUsersStatistics } from '../CasesUsersStatistics';
import { TasksTimeStatistics } from '../TasksTimeStatistics';
import { TaskTypeStatistics } from '../TaskTypeStatistics';
import { UpcomingPayments } from '../UpcomingPayments';
import { UserRegistration } from '../UserRegistration';
import { ExpensesAnalysis } from '../ExpensesAnalysis';
import { CaseStatistics } from '../CaseStatistics';
import { Bills } from '../Bills';
import { TimeCase } from '../TimeCase';
import { TimeTask } from '../TimeTask';
import { Invoices } from '../Invoices';
import { Files } from '../Files';
import * as cardsTypes from '../Layout/cardsTypes';

export const widgetsMap = {
  [cardsTypes.TASKS_STATUS_STATISTICS]: {
    title: 'Task statistics by status',
    component: TaskStatusStatistics
  },

  [cardsTypes.TASKS_TYPE_STATISTICS]: {
    title: 'Task statistics by type',
    component: TaskTypeStatistics
  },

  [cardsTypes.TASKS_TIME_STATISTICS]: {
    title: 'Tasks Time Statistics',
    component: TasksTimeStatistics
  },

  [cardsTypes.ANALYTICS_DATA]: {
    title: 'Bills',
    component: Bills
  },

  [cardsTypes.USER_REGISTRATION_STATISTICS]: {
    title: 'User statistics/registration',
    component: UserRegistration
  },

  [cardsTypes.TASKS_PRIORITY_STATISTICS]: {
    title: 'Task statistics by priority',
    component: TaskPriorityStatistics
  },

  [cardsTypes.TIME_TASK]: {
    title: 'Time spent on tasks',
    component: TimeTask
  },

  [cardsTypes.TIME_CASE]: {
    title: 'Time spent on cases',
    component: TimeCase
  },

  [cardsTypes.CASE_STATISTICS]: {
    title: 'Cases graph',
    component: CaseStatistics
  },

  [cardsTypes.CASES_USERS_STATISTICS]: {
    title: 'Case user statistics',
    component: CasesUsersStatistics
  },

  [cardsTypes.CASES_STATUS_STATISTICS]: {
    title: 'Сase status statistics',
    component: CasesStatusStatistics
  },

  [cardsTypes.FILES]: {
    title: 'Files',
    component: Files
  },

  [cardsTypes.INVOICES]: {
    title: 'Invoices',
    component: Invoices
  },

  [cardsTypes.EXPENSES_ANALYSIS]: {
    title: 'Expenses analysis',
    component: ExpensesAnalysis
  },

  [cardsTypes.UPCOMING_PAYMENTS]: {
    title: 'Upcoming Payments',
    component: UpcomingPayments
  },

  [cardsTypes.PAYMENT_HISTORY]: {
    title: 'Payment History',
    component: PaymentHistory
  }
};
