import { Autocomplete } from '../../../../components/FormField';

export const statusesOptions = [
  {
    value: null,
    label: 'All'
  },
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'Inactive'
  }
];

export const StatusesSelect = (props) => {
  return (
    <Autocomplete
      placeholder="Select status..."
      options={statusesOptions}

      {...props}
    />
  );
};
