import bg from './bg.png';

export const styles = ({
  spacing,
  palette: { grey, primary, lightgreen },
  typography: { fontWeightMedium },
  shape: { borderRadius }
}) => ({
  root: {
    position: 'relative',
    minHeight: 210,
    color: primary.contrastText,
    background: `${grey[500]} url(${bg}) center / cover`,
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    minHeight: 210,
    padding: spacing(2, 2.5, 1.5, 2.5)
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  header__name: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflow: 'hidden',
    marginBottom: spacing()
  },

  header__icon: {
    color: primary.contrastText,
    marginRight: spacing(-2),
    marginTop: -spacing()
  },

  header__subTitle: {
    marginLeft: spacing(2),
    marginRight: spacing(3)
  },

  claimContent: {
    display: 'flex',
    alignItems: 'center'
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
    marginTop: spacing(2),
    paddingTop: spacing(1.5)
  },

  contentInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    paddingTop: spacing(0.5),
    paddingBottom: spacing(),
    width: '100%'
  },

  contentInfo_type: {
    alignItems: 'flex-start'
  },

  contentInfo__title: {
    marginRight: spacing(1.5)
  },

  contentInfo__date: {
    marginRight: spacing(1.5),
    fontWeight: fontWeightMedium,
    color: primary.main
  },

  contentType: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1
  },

  contentType__title: {
    fontWeight: fontWeightMedium,
    marginRight: spacing(0.5)
  },

  contentLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: fontWeightMedium,
    minHeight: 20,
    color: primary.contrastText,
    paddingLeft: spacing(),
    paddingRight: spacing(),
    borderRadius
  },

  contentLabel_claim: {
    backgroundColor: lightgreen,
    cursor: 'pointer'
  },

  contentLabel_status: {
    backgroundColor: primary.main
  }
});
