import { Box } from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { ContentCard } from '../../../../components/ContentCard';
import { TasksProvider, TasksFilterContextProvider } from '../../../../components/tasks/TasksProvider';
import { Tasks as TasksComponent } from '../../../../components/contacts/Tasks';

export const Tasks = ({ filter }) => {
  const initialState = { filter };

  return (
    <TasksFilterContextProvider>
      <TasksProvider initialState={initialState}>
        <ContentCard
          fullHeight
          disableContentPaddings
          title="Tasks"
          component={Box}
          minHeight={630}
          leftActions={[
            <Box mr={1}>
              <PlaylistAddCheckIcon color="inherit" fontSize="small" />
            </Box>
          ]}
        >
          <TasksComponent />
        </ContentCard>
      </TasksProvider>
    </TasksFilterContextProvider>
  );
};
