import { useContext, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  List as MuiList,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { InfiniteGridLoader, InfiniteListLoader } from '../InfiniteLoader';
import { CustomScrollbars } from '../CustomScrollbars';
import { Card } from '../Widgets/TimeTracking/Grid/Card';
import { Header } from '../Widgets/TimeTracking/Header';
import { Row } from '../Widgets/TimeTracking/Row';
import { useModal } from '../ModalsProvider';
import { LogTimeModal } from '../LogTime';
import { Loader } from '../Loader';
import { Fab } from '../Fab';
import { TimeTrackingContext } from './TimeTrackingProvider';

export const minRowHeight = 64;
export const widthBreakpointSmall = 480;
export const widthBreakpointLarge = 830;
export const columnsWidths = {
  parent: 70,
  parent_title: 150,
  parent_note: 300,
  actionIcon: 50,
  startedTime: 160,
  finishedTime: 160,
  total: 80,
  note: 200,
  action: 60
};

export const TimeTracking = ({ parentType, parentID, onTrackAdd }) => {
  const {
    isFetching,
    isFetched,
    tracks,
    meta,
    loadNextPage,
    resetTimeTracking
  } = useContext(TimeTrackingContext);
  const { openModal } = useModal();
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const openLogTimeModal = () => {
    openModal(LogTimeModal, {
      payload: {
        initialValues: {
          time_track_model: parentType,
          time_track_model_id: parentID
        }
      },
      onModalResolved: (track) => {
        onTrackAdd && onTrackAdd(track);
        resetTimeTracking();
      }
    });
  };

  useEffect(() => {
    resetTimeTracking();
  }, []);

  return (
    <Box display="flex" flexDirection="column" minHeight={500}>
      <Box ml="auto">
        <Fab
          color="primary"
          variant="extended"
          startIcon={<AddIcon/>}
          onClick={openLogTimeModal}
        >
          Add track
        </Fab>
      </Box>

      <Loader p={3} loading={!isFetched} render={
        () => (!tracks.length ?
          <Box p={3}>
            <Typography align="center">No tracks found</Typography>
          </Box>
          : isMobile ? (
            <CustomScrollbars style={{ flexGrow: 1 }}>
              <Box pt={1}>
                <InfiniteGridLoader
                  items={tracks}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={{ last_page: meta.last_page, page: meta.page }}
                  CellComponent={Card}
                  onNeedNextPage={loadNextPage}
                  scrollElementRef={scrollElementRef}
                  cellWidth={370}
                  cellHeight={280}
                />
              </Box>
            </CustomScrollbars>
          ) : (
            <>
              <Header />

              <CustomScrollbars style={{ flexGrow: 1 }}>
                <MuiList disablePadding>
                  <InfiniteListLoader
                    items={tracks}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    RowComponent={Row}
                    RowProps={{ tracks }}
                    pagination={meta}
                    scrollElementRef={scrollElementRef}
                    onNeedNextPage={loadNextPage}
                    minRowHeight={minRowHeight}
                  />
                </MuiList>
              </CustomScrollbars>
            </>
          )
        )}
      />
    </Box>
  );
};
