import { Box, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useState } from 'react';
import { MembersModal } from '../../../app/Dashboard/CasesPage/Cases/MembersModal';
import { caseUserRolesTypesMap } from '../../../dataMaps';
import { getUserFullName } from '../../../helpers/users';
import { useModal } from '../../ModalsProvider';
import { Autocomplete } from '../../FormField';
import { Tooltip } from '../../Tooltip';
import { UserAvatar } from '../UserAvatar';

const renderOption = (option) => {
  return !option?.isCreatableOption ? (
    <Grid container wrap="nowrap" spacing={1} alignItems="center">
      <Grid item>
        <UserAvatar size="xs" user={option?.user || option} />
      </Grid>

      <Grid item>
        <Tooltip
          isExistTooltip={!!option?.case_role}
          title={caseUserRolesTypesMap?.[option?.case_role]}
        >
          <div>
            {getUserFullName(option)}
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  ) : (
    <>
      <Box display="flex" mr={1}>
        <AddIcon color="primary" />
      </Box>

      Add member
    </>
  );
};

export const CaseMembersSelect = ({
  formattedValue = true,
  multiple = false,
  params = {},
  creatablePayload,
  ...props
}) => {
  const { openModal } = useModal();
  const [ members, setMembers ] = useState(creatablePayload?.caseItem?.case_users || []);

  const handleAddMembers = (values) => {
    creatablePayload.onCaseItemUpdate(values);
    setMembers(values?.case_users);
  };

  const addUser = () => {
    return new Promise((resolve, reject) => {
      openModal(MembersModal, {
        payload: {
          ...creatablePayload,
          caseItem: { ...creatablePayload.caseItem, case_users: members },
          onCaseItemUpdate: handleAddMembers
        },
        onModalResolved: resolve,
        onModalRejected: reject
      });
    });
  };

  return (
    <Autocomplete
      multiple={multiple}
      label="User"
      filterSelectedOptions
      placeholder="Search user..."
      renderOption={renderOption}
      getInputProps={(value) => !(!multiple && value) ? null : ({
        startAdornment: (
          <UserAvatar user={value?.user || value} size="xs" />
        )
      })}
      getOptionLabel={getUserFullName}
      getOptionValue={(user) => !formattedValue ? user : user?.user?.id || user?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}
      onCreate={addUser}

      {...props}
    />
  );
};
