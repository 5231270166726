import { useState } from 'react';
import { Formik } from 'formik';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Loader } from '../../../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../../../components/Modal';
import { validationSchema } from './validationSchema';
import { Body } from './Body';

export const TemplateModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { age, reportType }
}) => {
  const [ isFetching, setIsFetching ] = useState(false);

  const addTemplate = (values, { isSubmitting, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    handleModalResolve(values);
    setSubmitting(true);
  };

  return (
    <Formik
      initialValues={{
        template_id: null
      }}
      validationSchema={validationSchema}
      onSubmit={addTemplate}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>} onClose={handleModalReject}>
              Add template
            </ModalHeader>

            <ModalBody>
              <Body age={age} report_type={reportType} onFetchingUpdate={setIsFetching} />
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting || isFetching} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
