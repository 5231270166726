import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CaseReminderEventsContext } from '../../../../../../components/cases/reminders/events';
import { InfiniteGridLoader } from '../../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../../components/Loader';
import { ScrollbarsCustom } from '../../../../../../components/ScrollbarsCustom';
import { Card } from './Card';

export const Grid = ({ autoHeight, hiddenColumns, Cell = Card, CellProps = {}, ...props }) => {
  const {
    reminderEvents,
    isFetching,
    isFetched,
    loadNextPage,
    resetReminderEvents,
    filter: { per_page, last_page, page }
  } = useContext(CaseReminderEventsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetReminderEvents();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} autoHeight={autoHeight}>
      <Box p={2}>
        <Loader loading={!isFetched} render={
          () => !reminderEvents.length ? (
            <Typography align="center">No events found</Typography>
          ) : (
            <MuiList disablePadding>
              <InfiniteGridLoader
                autoSize
                cellWidth={380}
                items={reminderEvents}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={{ per_page, last_page, page }}
                CellComponent={Cell}
                CellProps={{ hiddenColumns, ...CellProps }}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
