import { useState, useEffect, useContext, useMemo, useRef } from 'react';
import {
  makeStyles,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Box
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import cn from 'classnames';
import { api } from '../../../../../../../api';
import * as emailsApi from '../../../../../../../api/emails';
import { Badge } from '../../../../../../../components/Badge';
import { Loader } from '../../../../../../../components/Loader';
import { AccountsContext } from '../../../../AccountsContext';
import { FolderItem } from './FolderItem';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const AccountItem = ({ selected, account }) => {
  const classes = useStyles();
  const { counters, setActiveAccountID } = useContext(AccountsContext);
  const [ itemOpen, setItemOpen ] = useState(selected);
  const [ folders, setFolders ] = useState([]);
  const [ error, setError ] = useState({});
  const cancelFetch = useRef(() => {});
  const totalCounter = useMemo(() => {
    if (counters[account.id]) {
      return Object.values(counters[account.id]).reduce((total, folderCounter) => total + folderCounter, 0);
    }
  }, [ counters, account ]);

  useEffect(() => {
    cancelFetch.current();

    emailsApi.getEmailFolders(account.id, {
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then(setFolders).catch(setError);

    if (selected) {
      setActiveAccountID(account.id);
    }
  }, []);

  const toggleItemNav = () => {
    setItemOpen((prevItemOpen) => !prevItemOpen);
    setActiveAccountID(account.id);
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <>
      <ListItem button onClick={toggleItemNav}>
        <ExpandMore fontSize="small" className={cn(classes.itemExpand, { [classes.itemExpandOpened]: itemOpen })} />

        <ListItemText primary={account.email} className={classes.itemText} />

        <ListItemSecondaryAction>
          <Badge badgeContent={totalCounter} max={999} color="default" className={classes.badge} />
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={itemOpen}>
        <List disablePadding component="nav" className={classes.subList}>
          <Loader
            loading={!folders.length && !error?.message}
            p={2}
            render={() => folders.length ? (
              folders.map((folder) => {
                const isInbox = folder.name.toLowerCase() === 'inbox';

                return (
                  <FolderItem selected={selected && isInbox} accountId={account.id} folder={folder} key={folder.id} />
                );
              })
            ) : (
              <Box p={3}>
                <Typography color="error">No folders fetched</Typography>
              </Box>
            )}
          />
        </List>
      </Collapse>
    </>
  );
};
