import { Link, Typography } from '@material-ui/core';
import { CaseViewModal } from '../../../app/Dashboard/CasesPage/Cases/CaseViewModal';
import { useModal } from '../../ModalsProvider';

export const Case = ({ before, after, fieldLabel }) => {
  const { openModal } = useModal();

  const openCasePreview = (id) => () => {
    if (id) {
      openModal(CaseViewModal, {
        payload: { id }
      });
    }
  };

  if (!before && after) {
    return (
      <>
        Added {fieldLabel}&nbsp;
        <Link variant="subtitle1" onClick={openCasePreview(after?.id)}>{after?.name}</Link>
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed {fieldLabel}&nbsp;
        <Link variant="subtitle1" onClick={openCasePreview(before?.id)}>{before?.name}</Link>
      </>
    );
  } else if (!before && !after) {
    return (
      <>
        Empty {fieldLabel}
      </>
    );
  } else {
    return (
      <>
        Changed
        &nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <Link onClick={openCasePreview(before?.id)}>{before?.name}</Link>
        &nbsp;to&nbsp;
        <Link onClick={openCasePreview(after?.id)}>{after?.name}</Link>
      </>
    );
  }
};
