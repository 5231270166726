import { SvgIcon } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HistoryIcon from '@material-ui/icons/History';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import {
  mdiCogs,
  mdiCircleMedium,
  mdiMicrophoneMessage,
  mdiTrashCanOutline,
  mdiFileReplaceOutline,
  mdiFileMultipleOutline
} from '@mdi/js';
import { Icon } from '@mdi/react';
import SearchFileSvg from '../../../../../../components/icons/file-search.svg';
import TemplateSvg from '../../../../../../components/icons/template.svg';

export const filesMenuList = [
  {
    name: 'Files',
    icon: FolderIcon,
    path: '/files',
    routes: [
      {
        exact: true,
        name: 'My Files',
        icon: (props) => (
          <SvgIcon {...props}>
            <Icon path={mdiFileMultipleOutline} />
          </SvgIcon>
        ),
        path: '/files'
      },
      {
        path: '/files/shared',
        icon: (props) => <PeopleOutlineOutlinedIcon {...props} />,
        name: 'Shared with me'
      },
      {
        path: '/files/recent',
        icon: (props) => <HistoryIcon {...props} />,
        name: 'Recent'
      },
      {
        path: '/files/search',
        icon: (props) => <SvgIcon><SearchFileSvg {...props} /></SvgIcon>,
        name: 'Search Documents'
      },
      {
        path: '/files/merge-pdf',
        icon: (props) => <SvgIcon {...props}><Icon path={mdiFileReplaceOutline} /></SvgIcon>,
        name: 'Merge PDF'
      },
      {
        path: '/files/transcribes',
        icon: (props) => <SvgIcon {...props}><Icon path={mdiMicrophoneMessage} /></SvgIcon>,
        name: 'Transcribes'
      },
      {
        path: '/files/recycle-bin',
        icon: (props) => <SvgIcon {...props}><Icon path={mdiTrashCanOutline} /></SvgIcon>,
        name: 'Recycle Bin'
      },
      {
        name: 'Templates',
        icon: (props) => (
          <SvgIcon {...props}>
            <TemplateSvg />
          </SvgIcon>
        ),
        path: '/files/templates'
      },
      {
        icon: (props) => (
          <PrintOutlinedIcon {...props} />
        ),
        path: '/files/from-printers',
        name: 'Files from Printers'
      },
      {
        path: '/files/access-control',
        icon: (props) => <MailOutlinedIcon {...props} />,
        name: 'Access Control'
      },
      {
        icon: (props) => (
          <SvgIcon {...props}>
            <Icon path={mdiCogs} />
          </SvgIcon>
        ),
        name: 'Settings',
        path: '/files/system-settings',
        routes: [
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            name: 'Tags management',
            path: '/files/system-settings/tags'
          },
          {
            icon: (props) => (
              <SvgIcon {...props}>
                <Icon path={mdiCircleMedium} />
              </SvgIcon>
            ),
            name: 'Printer Configuration Name',
            path: '/files/system-settings/configuration'
          }
        ]
      }
    ]
  }
];
