import { chessBackground } from '../../../../../../../assets/chessBackground';

export const styles = {
  root: {
    '& svg': {
      ...chessBackground,

      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto'
    }
  }
};
