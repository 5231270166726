export const styles = ({ spacing, palette, breakpoints }) => ({
  root: {
    height: '100%',
    padding: spacing(2, 3),

    [breakpoints.down('md')]: {
      padding: spacing()
    }
  },

  list: {
    border: `1px solid ${palette.divider}`
  }
});
