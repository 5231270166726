import { useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../InfiniteLoader';
import { Loader } from '../../../Loader';
import { ScrollbarsCustom } from '../../../ScrollbarsCustom';
import { ScheduleContext } from '../../ScheduleProvider';
import { Row } from './Row';

export const List = ({ hideApplyFields, onEventUpdate, onEventDelete }) => {
  const { isFetched, isFetching, events, filter, loadNextPage } = useContext(ScheduleContext);
  const scrollElementRef = useRef();

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef}>
      <Box height="100%">
        <Loader loading={!isFetched} render={
          () => !filter.total ? (
            <Typography align="center">No events</Typography>
          ) : (
            <MuiList disablePadding>
              <InfiniteListLoader
                items={events}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                RowProps={{ hideApplyFields, onEventUpdate, onEventDelete }}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
              />
            </MuiList>
          )}
        />
      </Box>
    </ScrollbarsCustom>
  );
};
