import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { NestedList } from '../../../../../../../components/NestedList';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import {
  symptomChildrenMap,
  symptomsTypesList,
  symptomsTypesMap
} from '../../PersonalInjuryOrtho/ReviewSystems/symptomsTypesMap';

export const ReviewOfSymptoms = ({ isOpenSwitches, form }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const symptoms = form?.forms?.review_of_orthopedic_symptoms || {};
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmpty(symptoms) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Review of Symptoms"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      {symptomsTypesList.map((symptomType, index) => symptoms?.[symptomType] && (
        <Box py={1} px={isSmallScreen ? 1 : 2} key={index}>
          <Box display="flex" alignItems="flex-start">
            <Box clone mr={1}>
              {symptomsTypesMap[symptomType].icon}
            </Box>

            <Typography variant="h5">
              {symptomsTypesMap[symptomType].name}
            </Typography>
          </Box>

          {Object.keys(symptoms?.[symptomType]).map((symptom, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              flexWrap="wrap"
            >
              <Box key={index} display="flex" alignItems="center">
                <Box component="span" mr={2}>
                  <ReadonlyCheckbox
                    key={index}
                    title={symptomChildrenMap[symptom]}
                    checked={
                      symptom === 'other'
                        ? symptoms?.[symptomType]?.other?.value
                        : symptoms?.[symptomType]?.[symptom]
                    }
                  />
                </Box>

                {symptom === 'other' && !!symptoms?.[symptomType]?.other?.text &&
                  <Box minWidth={250} borderBottom={1}>
                    <Typography>{symptoms?.[symptomType]?.other?.text}</Typography>
                  </Box>
                }
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </NestedList>
  );
};

