import { useContext } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ColorPreview } from '../../../../components/ColorPreview';
import { TaskPreviewContext } from '../../../../components/tasks/TasksProvider';
import { ProgressBar } from '../MainWindow/ProgressBar';
import { Label } from '../MainWindow/Label';
import { DueDate } from '../DueDate';
import { PreviewTaskActivity } from './PreviewTaskActivity';
import { PreviewRightSidebar } from './PreviewRightSidebar';
import { PreviewTaskBody } from './PreviewTaskBody';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PreviewTask = ({ count }) => {
  const classes = useStyles();
  const { selectedTask } = useContext(TaskPreviewContext);

  return (
    <div className={classes.root}>
      <div className={classes.leftSidebar}>
        <Grid container className={classes.preview__header}>
          <Grid
            container
            item
            xs={4}
            alignItems="center"
            className={classes.preview__grid}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Type
              </Typography>
            </Grid>

            <Grid item container xs={8} spacing={1}>
              <Grid item>
                <ColorPreview color={selectedTask.type?.color} />
              </Grid>

              <Grid item>
                <Typography color="textSecondary">
                  {selectedTask.type?.name || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Estimated
              </Typography>
            </Grid>

            <Grid item xs={7}>
              <ProgressBar logged={selectedTask.logged_time} estimate={selectedTask.estimated_time} />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={4}
            alignItems="center"
            className={classes.preview__grid}
          >
            <Grid item xs={selectedTask.priority ? 4 : 12}>
              <Typography variant="subtitle1">
                Priority
              </Typography>
            </Grid>

            {selectedTask.priorityLabel &&
              <Grid item xs={8}>
                <Label name={selectedTask.priorityLabel} color={selectedTask.priority} />
              </Grid>
            }

            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Due date
              </Typography>
            </Grid>

            <Grid item xs={7}>
              {selectedTask.due_at &&
                <DueDate task={selectedTask}/>
              }
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={4}
            alignItems="center"
            className={classes.preview__grid}
          >
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Status
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className={classes.preview__subTitle}>
                {selectedTask?.status?.name || '-'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="subtitle1">
                My work status
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className={classes.preview__subTitle}>
                {selectedTask?.work_status || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <PreviewTaskBody />
        <PreviewTaskActivity count={count} />
      </div>

      <div className={classes.rightSidebar}>
        <PreviewRightSidebar classes={classes} />
      </div>
    </div>
  );
};
