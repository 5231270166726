import { useEffect, useContext, useRef } from 'react';
import { Typography, useTheme, makeStyles } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { Route } from '../../../../../components/router';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { FilesContext } from '../../../files-common';
import { FilesList } from '../../../files-common/FilesList';
import { RecentlyOpened } from '../RecentlyOpened';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const List = ({ hiddenColumns }) => {
  const theme = useTheme();
  const classes = useStyles();
  const filesContext = useContext(FilesContext);
  const { resetFiles } = filesContext;
  const scrollElementRef = useRef();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const isMobile = width <= theme.breakpoints.values.mobileLg;

  useEffect(() => {
    resetFiles();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ flexGrow: 1 }}>
      <div ref={rootRef} className={classes.root}>
        {!isMobile && (
          <Route exact path="/files">
            <RecentlyOpened />
            <Typography variant="h3" gutterBottom>Files</Typography>
          </Route>
        )}

        <FilesList
          scrollElementRef={scrollElementRef}
          hiddenColumns={hiddenColumns}
          filesContext={filesContext}
          isMobile={isMobile}
          RowProps={{ isMobile }}
          Row={Row}
        />
      </div>
    </CustomScrollbars>
  );
};
