import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, SvgIcon, Dialog, Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { mdiAccountPlusOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as usersApi from '../../../../api/users';
import { usersRolesOptions } from '../../../../dataMaps';
import { dataURItoBlob } from '../../../../helpers/files';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContainer
} from '../../../../components/Modal';
import { Loader } from '../../../../components/Loader';
import { Divider } from '../../../../components/Divider';
import { errorsMap } from '../../../../utils/validation';
import { AddressContent } from './AddressContent';
import { AvatarContent } from './AvatarContent';
import { InfoContent } from './InfoContent';
import { MainContent } from './MainContent';
import { WorkInfo } from './WorkInfo';
import { validationSchema } from './validationSchema';
import { initialValues } from './initialValues';

export const CreateCustomerModal = ({
  DialogProps,
  payload: {
    company = null,
    office = [],
    role = '',
    disableRoleField = false
  },
  handleModalResolve,
  handleModalReject
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const [ avatarDataURL, setAvatarDataURL ] = useState(null);

  const createUser = (userData, setSubmitting, setErrors) => {
    usersApi.createUser(userData).then((data) => {
      enqueueSnackbar('User successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ data: { errors } }) => {
      if (errors?.email?.includes(errorsMap.unique)) {
        setErrors({ email: 'User with the same email already exists' });
      } else if (errors) {
        setErrors(errors);
      }

      setSubmitting(false);
      enqueueSnackbar('User not created', { variant: 'error' });
    });
  };

  const addCustomer = (userData, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (avatarDataURL) {
      const formData = new FormData();

      formData.append('file', dataURItoBlob(avatarDataURL));

      usersApi.uploadAvatar(formData).then((avatar) => {
        userData.avatar_id = avatar.id;

        createUser(userData, setSubmitting, setErrors);
        enqueueSnackbar('Avatar successfully upload', { variant: 'success' });
      }).catch(() => {
        enqueueSnackbar('Avatar not upload', { variant: 'error' });
      });
    } else {
      createUser(userData, setSubmitting, setErrors);
    }
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,

        role: role ? usersRolesOptions.find(({ value }) => value === role) : null,
        work: {
          company_id: company,
          office_id: office,
          position: null,
          department: null
        }
      }}
      validationSchema={validationSchema}
      onSubmit={addCustomer}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiAccountPlusOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Add User
            </ModalHeader>

            <ModalBody>
              <Grid
                container
                spacing={isMobile ? 1 : 2}
                justify="space-between"
                component={Box}
                pb={isMobile ? 1 : 3}
                alignItems="flex-start"
              >
                <Grid item xs={isMobile ? 12 : 4}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <AvatarContent align="right" onAvatarUrlChange={setAvatarDataURL} />
                  </Box>
                </Grid>

                <Grid item xs={isMobile ? 12 : 8} container spacing={isMobile ? 1 : 3} alignItems="center">
                  <MainContent />
                </Grid>
              </Grid>

              <Divider type="dashed" gutter={3} />
              <InfoContent disableRoleField={disableRoleField} />

              <Divider type="dashed" gutter={3} />
              <AddressContent />

              <Divider type="dashed" gutter={3} />
              <WorkInfo />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
