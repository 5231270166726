import { useContext, useEffect } from 'react';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../../components/UserLink';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { SavedFiltersContext } from '../../../../../../../components/saved-filters';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../components/IconButton';
import { Loader } from '../../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: filter = {},
  isLoaded,
  recalculateHeight,
  onApplySavedFilter
}) => {
  const { deleteSavedFilter } = useContext(SavedFiltersContext);
  const params = filter?.params;

  const handleSetFieldsFromSaved = () => {
    onApplySavedFilter(filter?.params);
  };

  const removeSavedFilter = () => {
    deleteSavedFilter(filter);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={1} /> : (
    <ListRow button onClick={handleSetFieldsFromSaved}>
      <ListRowCell flexBasis={columnsWidths.users}>
        <Box display="flex" flexDirection="column">
          {!params?.users_id?.length ? '-' : params?.users_id?.map((user) => (
            <Box display="flex" key={user.id}>
              <UserLink
                noWrap
                size="sm"
                user={user}
              />
            </Box>
          ))}
        </Box>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.cases}>
        <Typography noWrap>
          {params?.case_id?.name || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.company}>
        <CompanyLink
          noWrap
          variant="h5"
          company={params?.company_id}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateFrom}>
        <Typography noWrap>
          {params.from ? moment.unix(params?.from).format('L') : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateTo}>
        <Typography noWrap>
          {params.to ? moment.unix(params?.to).format('L') : '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          edge="end"
          color="error"
          title="Delete filter"
          onClick={stopPropagation(removeSavedFilter)}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
