import * as types from './cardsTypes';

export const extraTypes = [
  types.ALL_SCHEDULES,
  types.WORK_INFO,
  types.MEDICAL_INFO,
  types.FILES
];

export const checkedWidgetsTypes = {
  [types.ALL_SCHEDULES]: true,
  [types.WORK_INFO]: true,
  [types.MEDICAL_INFO]: true,
  [types.FILES]: true
};

export const cardsLayout = {
  breakpoints: { xl: 1440, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  cols: { xl: 24, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  toolbox: { xl: [], lg: [], md: [], sm: [], xs: [], xxs: [] },
  layouts: {
    xl: [
      {
        w: 12,
        h: 10,
        x: 0,
        y: 0,
        i: types.WORK_INFO,
        minW: 12,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 12,
        h: 10,
        x: 12,
        y: 0,
        i: types.ALL_SCHEDULES,
        minW: 12,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 12,
        h: 10,
        x: 0,
        y: 10,
        i: types.MEDICAL_INFO,
        minW: 12,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 12,
        h: 10,
        x: 12,
        y: 10,
        i: types.FILES,
        minW: 12,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    lg: [
      {
        w: 6,
        h: 10,
        x: 0,
        y: 0,
        i: types.WORK_INFO,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 6,
        y: 0,
        i: types.ALL_SCHEDULES,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 10,
        i: types.MEDICAL_INFO,
        minW: 6,
        maxW: 12,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 6,
        y: 10,
        i: types.FILES,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    md: [
      {
        w: 10,
        h: 10,
        x: 0,
        y: 0,
        i: types.WORK_INFO,
        minW: 10,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 10,
        i: types.ALL_SCHEDULES,
        minW: 10,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 20,
        i: types.MEDICAL_INFO,
        minW: 10,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 10,
        h: 10,
        x: 0,
        y: 30,
        i: types.FILES,
        minW: 10,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    sm: [
      {
        w: 6,
        h: 10,
        x: 0,
        y: 0,
        i: types.WORK_INFO,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 10,
        i: types.ALL_SCHEDULES,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 20,
        i: types.MEDICAL_INFO,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 6,
        h: 10,
        x: 0,
        y: 30,
        i: types.FILES,
        minW: 6,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    xs: [
      {
        w: 4,
        h: 10,
        x: 0,
        y: 0,
        i: types.WORK_INFO,
        minW: 4,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 10,
        i: types.ALL_SCHEDULES,
        minW: 4,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 20,
        i: types.MEDICAL_INFO,
        minW: 4,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 4,
        h: 10,
        x: 0,
        y: 30,
        i: types.FILES,
        minW: 4,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ],
    xxs: [
      {
        w: 2,
        h: 10,
        x: 0,
        y: 0,
        i: types.WORK_INFO,
        minW: 2,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 10,
        i: types.ALL_SCHEDULES,
        minW: 2,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 20,
        i: types.MEDICAL_INFO,
        minW: 2,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      },
      {
        w: 2,
        h: 10,
        x: 0,
        y: 30,
        i: types.FILES,
        minW: 2,
        maxW: 24,
        minH: 10,
        maxH: 25,
        moved: false,
        isDraggable: true,
        isResizable: true
      }
    ]
  }
};
