import { Box, FormControlLabel, FormLabel, makeStyles, Radio, Typography } from '@material-ui/core';
import { ErrorMessage, useFormikContext } from 'formik';
import { styles } from '../../../app/Dashboard/GuestMedicalProfile/styles';
import { BodyParts } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/BodyParts';
import { CheckboxesComponent } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/CheckboxesComponent';
import { Radio as MuiRadio, RadioGroup, TextField } from '../../FormField';
import { getTextColor } from '../getTextColor';

const useStyles = makeStyles(styles);

export const CurrentSymptomsBody = ({ type, symptoms }) => {
  const { errors } = useFormikContext();
  const classes = useStyles();

  return (
    <Box flexGrow={1}>
      <Typography variant="h5" color="secondary">
        Current Symptoms
      </Typography>

      <Box mt={2} mb={4}>
        <Typography variant="h5" color={getTextColor(errors?.forms?.current_symptoms_pi?.where_is_pain)}>
          1. Where is your pain? *
        </Typography>

        <BodyParts/>

        <Typography color="error">
          <ErrorMessage name="forms.current_symptoms_pi.where_is_pain" />
        </Typography>
      </Box>

      <Box mb={4}>
        <CheckboxesComponent type={type} symptoms={symptoms} />
      </Box>

      <Box mb={4}>
        <Typography variant="h5">
          2. Describe the circumstances around the onset of your pain:
        </Typography>

        <TextField
          fullWidth
          multiline
          disableUnderline
          name="forms.current_symptoms_pi.describe_circumstances_pain"
          margin="normal"
          variant="filled"
          rows={6}
        />
      </Box>

      <Box mb={4}>
        <Typography variant="h5">
          3. How often is your pain?
        </Typography>

        <Box pl={2}>
          <RadioGroup row name="forms.current_symptoms_pi.how_often_your_pain">
            <FormControlLabel
              value="occasional"
              label={<Typography>Occasional</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="frequent"
              label={<Typography>Frequent</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="constant"
              label={<Typography>Constant</Typography>}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </Box>
      </Box>

      <Box mb={4}>
        <Typography variant="h5">
          4. Have you avoided strenuous lifting, carrying, pushing,
          pulling, stooping, and  bending because of your injury?
        </Typography>

        <Box pl={2}>
          <RadioGroup row name="forms.current_symptoms_pi.have_you_avoided_strenuous_lifting">
            <FormControlLabel
              value="1"
              label={<Typography>Yes</Typography>}
              control={<Radio color="primary" />}
            />

            <FormControlLabel
              value="0"
              label={<Typography>No</Typography>}
              control={<Radio color="primary"/>}
            />
          </RadioGroup>
        </Box>
      </Box>

      <Box mb={4}>
        <Typography variant="h5">
          5. How do the following factors affect your pain?
        </Typography>

        <div className={classes.painContent}>
          <div className={classes.painInfo}>
            <FormLabel component="legend" className={classes.label}>1. Heat</FormLabel>

            <RadioGroup name="forms.current_symptoms_pi.following_factors_your_pain.heat">
              <FormControlLabel
                value="better"
                label={<Typography>Better</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.heat"
                    color="success"
                  />
                }
              />

              <FormControlLabel
                value="worse"
                label={<Typography>Worse</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.heat"
                    color="error"
                  />
                }
              />

              <FormControlLabel
                value="no_effect"
                label={<Typography>No Effect</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.heat"
                  />
                }
              />
            </RadioGroup>
          </div>

          <div className={classes.painInfo}>
            <FormLabel component="legend" className={classes.label}>2. Cold</FormLabel>

            <RadioGroup name="forms.current_symptoms_pi.following_factors_your_pain.cold">
              <FormControlLabel
                value="better"
                label={<Typography>Better</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.cold"
                    color="success"
                  />
                }
              />

              <FormControlLabel
                value="worse"
                label={<Typography>Worse</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.cold"
                    color="error"
                  />
                }
              />

              <FormControlLabel
                value="no_effect"
                label={<Typography>No Effect</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.cold"
                  />
                }
              />
            </RadioGroup>
          </div>

          <div className={classes.painInfo}>
            <FormLabel component="legend" className={classes.label}>3. Lying down</FormLabel>

            <RadioGroup name="forms.current_symptoms_pi.following_factors_your_pain.lying_down">
              <FormControlLabel
                value="better"
                label={<Typography>Better</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.lying_down"
                    color="success"
                  />
                }
              />

              <FormControlLabel
                value="worse"
                label={<Typography>Worse</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.lying_down"
                    color="error"
                  />
                }
              />

              <FormControlLabel
                value="no_effect"
                label={<Typography>No Effect</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.lying_down"
                  />
                }
              />
            </RadioGroup>
          </div>

          <div className={classes.painInfo}>
            <FormLabel component="legend" className={classes.label}>4. Sitting</FormLabel>

            <RadioGroup name="forms.current_symptoms_pi.following_factors_your_pain.sitting">
              <FormControlLabel
                value="better"
                label={<Typography>Better</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.sitting"
                    color="success"
                  />
                }
              />

              <FormControlLabel
                value="worse"
                label={<Typography>Worse</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.sitting"
                    color="error"
                  />
                }
              />

              <FormControlLabel
                value="no_effect"
                label={<Typography>No Effect</Typography>}
                control={<MuiRadio
                  size="small"
                  name="forms.current_symptoms_pi.following_factors_your_pain.sitting"
                />
                }
              />
            </RadioGroup>
          </div>

          <div className={classes.painInfo}>
            <FormLabel component="legend" className={classes.label}>5. Walking</FormLabel>

            <RadioGroup name="forms.current_symptoms_pi.following_factors_your_pain.walking">
              <FormControlLabel
                value="better"
                label={<Typography>Better</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.walking"
                    color="success"
                  />
                }
              />

              <FormControlLabel
                value="worse"
                label={<Typography>Worse</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.walking"
                    color="error"
                  />
                }
              />

              <FormControlLabel
                value="no_effect"
                label={<Typography>No Effect</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.walking"
                  />
                }
              />
            </RadioGroup>
          </div>

          <div className={classes.painInfo}>
            <FormLabel component="legend" className={classes.label}>6. Climate</FormLabel>

            <RadioGroup name="forms.current_symptoms_pi.following_factors_your_pain.climate">
              <FormControlLabel
                value="better"
                label={<Typography>Better</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.climate"
                    color="success"
                  />
                }
              />

              <FormControlLabel
                value="worse"
                label={<Typography>Worse</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.climate"
                    color="error"
                  />
                }
              />

              <FormControlLabel
                value="no_effect"
                label={<Typography>No Effect</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.climate"
                  />
                }
              />
            </RadioGroup>
          </div>

          <div className={classes.painInfo}>
            <FormLabel component="legend" className={classes.label}>7. Fatigue</FormLabel>

            <RadioGroup name="forms.current_symptoms_pi.following_factors_your_pain.fatigue">
              <FormControlLabel
                value="better"
                label={<Typography>Better</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.fatigue"
                    color="success"
                  />
                }
              />

              <FormControlLabel
                value="worse"
                label={<Typography>Worse</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.fatigue"
                    color="error"
                  />
                }
              />

              <FormControlLabel
                value="no_effect"
                label={<Typography>No Effect</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.fatigue"
                  />
                }
              />
            </RadioGroup>
          </div>

          <div className={classes.painInfo}>
            <FormLabel component="legend" className={classes.label}>8. Coughing</FormLabel>

            <RadioGroup name="forms.current_symptoms_pi.following_factors_your_pain.coughing">
              <FormControlLabel
                value="better"
                label={<Typography>Better</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.coughing"
                    color="success"
                  />
                }
              />

              <FormControlLabel
                value="worse"
                label={<Typography>Worse</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.coughing"
                    color="error"
                  />
                }
              />

              <FormControlLabel
                value="no_effect"
                label={<Typography>No Effect</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.coughing"
                  />
                }
              />
            </RadioGroup>
          </div>

          <div className={classes.painInfo}>
            <FormLabel component="legend" className={classes.label}>9. Alcohol</FormLabel>

            <RadioGroup name="forms.current_symptoms_pi.following_factors_your_pain.alcohol">
              <FormControlLabel
                value="better"
                label={<Typography>Better</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.alcohol"
                    color="success"
                  />
                }
              />

              <FormControlLabel
                value="worse"
                label={<Typography>Worse</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.alcohol"
                    color="error"
                  />
                }
              />

              <FormControlLabel
                value="no_effect"
                label={<Typography>No Effect</Typography>}
                control={
                  <MuiRadio
                    size="small"
                    name="forms.current_symptoms_pi.following_factors_your_pain.alcohol"
                  />
                }
              />
            </RadioGroup>
          </div>
        </div>
      </Box>
    </Box>
  );
};
