export const styles = ({ palette: { divider }, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRight: `1px solid ${divider}`
  },

  groups: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',

    [breakpoints.down('md')]: {
      overflowX: 'hidden'
    }
  }
});
