export const styles = ({
  spacing,
  palette: { grey, darkgreen },
  typography: { fontWeightMedium }
}) => ({
  mainContent: {
    border: `1px solid ${grey[200]}`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: 300
  },

  totalRoot: {
    display: 'flex',
    width: 370,
    padding: spacing(2, 0),
    marginLeft: 'auto'
  },

  totalRoot_budget: {
    borderBottom: `1px solid ${grey[300]}`
  },

  totalContent: {
    color: grey[600],
    fontWeight: fontWeightMedium,
    minWidth: 150,
    textAlign: 'left',
    paddingLeft: spacing(4)
  },

  totalContent_budget: {
    display: 'flex',
    alignItems: 'center',
    color: darkgreen,
    paddingLeft: 0
  },

  totalContent__icon: {
    marginLeft: spacing()
  },

  totalContent_amount: {
    paddingLeft: 0
  }
});
