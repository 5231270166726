import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow, isEmpty } from 'lodash';
import { Box } from '@material-ui/core';
import { ActivityContext, activitySessionTypesMap, activityTypesMap } from '../../../../components/Activities';
import { FiltersBar as MuiFiltersBar } from '../../../../components/FiltersBar';
import { Autocomplete, KeyboardDatePicker } from '../../../../components/FormField';
import { SavedFiltersProvider, SavedFiltersWidget } from '../../../../components/saved-filters';
import { UsersSelect } from '../../../../components/users';
import { saveFilter } from '../../../../store/lastFilters';
import { getActivityOptions } from './getActivityOptions';
import { transformRelationsToReset, transformRelationsForFilterToOptions } from './utils';
import {
  ActivityModelField,
  fieldsMapTypes,
  modelTypeOptions
} from './activityDataMaps';
import { fieldsKeysMap, filterLabels } from './filterKeysMap';
import { List } from './List';

export const initialValues = {
  date: null,
  userIDs: [],
  model_id: null,
  model: null,
  log_name: null,
  subject_type: null
};

const IconComponent = ({ relationsForFilter, onApplySavedFilter, filterKey }) => {
  return (
    <SavedFiltersProvider filter={{ entity: filterKey }}>
      <SavedFiltersWidget
        relationsForFilter={relationsForFilter}
        modalWidth={900}
      >
        {({ handleClose }) => (
          <List onApplySavedFilter={flow(onApplySavedFilter, handleClose)} />
        )}
      </SavedFiltersWidget>
    </SavedFiltersProvider>
  );
};

const logNameOptions = getActivityOptions({ ...activityTypesMap, ...activitySessionTypesMap });
const subTypeOptions = getActivityOptions(activityTypesMap);

export const FiltersBar = ({ filterKey }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { filter, resetActivities, applyFilter } = useContext(ActivityContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const modelFilters = !filter?.model_id && filter.model
    ? { model: null, model_id: null }
    : {};

  const updateFilters = (newFilters) => {
    dispatch(saveFilter({
      key: filterKey,
      filter: { ...lastFilters, ...newFilters }
    }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(transformRelationsForFilterToOptions(filter));
    resetActivities(transformRelationsToReset(filter));
  };

  const handleUsersMultiSelect = (name) => (options) => {
    if (!filter?.model_id && filter.model) {
      formikRef.current.setFieldValue(fieldsKeysMap.model, null);
    }

    const users = options?.length ? options.map(({ id }) => id) : null;

    resetActivities({ [name]: users });
    updateFilters({ [name]: options, ...modelFilters });
  };

  const handleDatePickerChange = (name) => (date) => {
    if (!filter?.model_id && filter.model) {
      formikRef.current.setFieldValue(fieldsKeysMap.model, null);
    }

    resetActivities({ [name]: date, ...modelFilters });
    updateFilters({ [name]: date, ...modelFilters });
  };

  const handleSelectChange = (name) => (option) => {
    if (!filter?.model_id && filter.model) {
      formikRef.current.setFieldValue(fieldsKeysMap.model, null);
    }

    resetActivities({ [name]: option?.value, ...modelFilters });
    updateFilters({ [name]: option, ...modelFilters });
  };

  const handleModelChange = (option) => {
    if (option?.value === filter?.model) return;

    formikRef.current.setFieldValue(fieldsKeysMap.modelID, null);

    if (option?.value) {
      applyFilter({ model: option?.value });
    } else {
      resetActivities({ model: null, model_id: null });
    }
    updateFilters({ model: null, model_id: null });
  };

  const handleModelIDChange = (option) => {
    const id = option?.value || option?.id || option;

    if (id) {
      resetActivities({ model_id: id });
    }

    updateFilters({
      model_id: option?.data || option,
      model: id ? filter?.model : null
    });
  };

  useEffect(() => {
    if (!isEmpty(lastFilters)) {
      applySavedFilter(lastFilters);
    } else {
      resetActivities();
    }
  }, []);

  return (
    <MuiFiltersBar
      isResetForm
      disableTopPaddings
      enableSettings
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      onReset={() => applySavedFilter(initialValues)}
      iconComponent={(
        <IconComponent
          filterKey={filterKey}
          relationsForFilter={lastFilters}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.userIDs,
          label: filterLabels[fieldsKeysMap.userIDs],
          field: (
            <UsersSelect
              multiple
              name={fieldsKeysMap.userIDs}
              label={filterLabels[fieldsKeysMap.userIDs]}
              onChange={handleUsersMultiSelect(fieldsKeysMap.userIDs)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.date,
          label: filterLabels[fieldsKeysMap.date],
          field: (
            <KeyboardDatePicker
              clearable
              outputFormat="YYYY-MM-DD"
              name={fieldsKeysMap.date}
              label={filterLabels[fieldsKeysMap.date]}
              onChange={handleDatePickerChange(fieldsKeysMap.date)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.logName,
          label: filterLabels[fieldsKeysMap.logName],
          field: (
            <Autocomplete
              name={fieldsKeysMap.logName}
              label={filterLabels[fieldsKeysMap.logName]}
              options={logNameOptions}
              onChange={handleSelectChange(fieldsKeysMap.logName)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.subjectType,
          label: filterLabels[fieldsKeysMap.subjectType],
          field: (
            <Autocomplete
              name={fieldsKeysMap.subjectType}
              label={filterLabels[fieldsKeysMap.subjectType]}
              options={subTypeOptions}
              onChange={handleSelectChange(fieldsKeysMap.subjectType)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.model,
          label: filterLabels[fieldsKeysMap.model],
          field: (
            <Autocomplete
              name={fieldsKeysMap.model}
              label={filterLabels[fieldsKeysMap.model]}
              options={modelTypeOptions}
              onChange={handleModelChange}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.modelID,
          label: filterLabels[fieldsKeysMap.modelID],
          field: (
            <Box minWidth={220}>
              <ActivityModelField
                isClearable
                modelType={fieldsMapTypes?.[filter?.model] || null}
                name={fieldsKeysMap.modelID}
                onChange={handleModelIDChange}
              />
            </Box>
          )
        }
      ]}
    />
  );
};
