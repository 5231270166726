import { useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { FileTypeIcon } from '../../../../FileTypeIcon';
import { FilePreviewContext } from '../../../FilePreviewContext';
import { PreviewContent } from '../PreviewContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const PreviewNotFound = () => {
  const { selectedFile } = useContext(FilePreviewContext);
  const classes = useStyles();

  return (
    <PreviewContent>
      <div className={classes.root}>
        <Typography variant="h2" className={classes.title}>
          This file has no preview
        </Typography>

        <FileTypeIcon className={classes.icon} file={selectedFile}/>
      </div>
    </PreviewContent>
  );
};
