import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Checkbox, TextField } from '../../../../../../components/FormField';

export const PhysicalExamination = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!values?.forms?.physical_examination?.check) {
      setFieldValue('forms.physical_examination.text', null);
    }
  }, [ values?.forms?.physical_examination?.check ]);

  return (
    <Grid container spacing={0} component={Box} px={2}>
      <Grid item xs={12}>
        <Box display="flex" flexWrap="wrap" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={150} width="20%">
            <Box mr={1}>
              <Typography variant="h5">BP:</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.bp"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={150} width="20%">
            <Box mr={1}>
              <Typography variant="h5">HR</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.hr"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={150} width="20%">
            <Box mr={1}>
              <Typography variant="h5">RR</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.rr"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={150} width="20%">
            <Box mr={1}>
              <Typography variant="h5">Temp</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.temp"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={150} width="20%">
            <Box mr={1}>
              <Typography variant="h5">VAS</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.vas"
            />
          </Box>
        </Box>
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Alert"
          control={
            <Checkbox
              color="primary"
              name="forms.physical_examination.alert"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="No distress"
          control={
            <Checkbox
              color="primary"
              name="forms.physical_examination.no_distress"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Normal Speech"
          control={
            <Checkbox
              color="primary"
              name="forms.physical_examination.normal_speech"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Normal Mood/affect"
          control={
            <Checkbox
              color="primary"
              name="forms.physical_examination.normal_mood_affect"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="PERRLA"
          control={
            <Checkbox
              color="primary"
              name="forms.physical_examination.perrla"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Chest clear"
          control={
            <Checkbox
              color="primary"
              name="forms.physical_examination.chest_clear"
            />
          }
        />
      </Grid>

      <Grid item sm={4} xs={12}>
        <FormControlLabel
          label="Heart Reg."
          control={
            <Checkbox
              color="primary"
              name="forms.physical_examination.heart_reg"
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={320} width="30%">
            <Box minWidth={100} mr={1}>
              <Typography variant="h5">Motor Strength:</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.motor_strength"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={320} width="30%">
            <Box mr={1}>
              <Typography variant="h5">Reflexes:</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.reflexes"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={320} width="30%">
            <Box mr={1}>
              <Typography variant="h5">Sensory:</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.sensory"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={270} width="25%">
            <Box minWidth={100} mr={1}>
              <Typography variant="h5">
                <Box component="span" color="info.main">Back-</Box>
                Flexion:
              </Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.back_flexion"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={270} width="25%">
            <Box mr={1}>
              <Typography variant="h5">Extension:</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.back_extension"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={270} width="25%">
            <Box minWidth={100} mr={1}>
              <Typography variant="h5">
                <Box component="span" color="info.main">Neck-</Box>
                Flexion:
              </Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.neck_flexion"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={270} width="25%">
            <Box mr={1}>
              <Typography variant="h5">Extension:</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.neck_extension"
            />
          </Box>
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <Box display="flex" flexWrap="wrap" alignItems="flex-end">
          <Box pr={4} mt={2} minWidth={40} width="10%">
            <Typography variant="h5">SLR:</Typography>
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={175} width="45%">
            <Box mr={1}>
              <Typography variant="h5">(R)</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.slr_r"
            />
          </Box>

          <Box display="flex" alignItems="center" pr={4} mt={2} minWidth={175} width="45%">
            <Box mr={1}>
              <Typography variant="h5">(L)</Typography>
            </Box>

            <TextField
              zeroMinWidth
              name="forms.physical_examination.slr_l"
            />
          </Box>
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <Box minWidth={210}>
            <FormControlLabel
              label="Paravertebral Tenderness"
              control={
                <Checkbox
                  color="primary"
                  name="forms.physical_examination.check"
                />
              }
            />
          </Box>

          <TextField
            zeroMinWidth
            disabled={!values?.forms?.physical_examination?.check}
            name="forms.physical_examination.text"
          />
        </Box>
      </Grid>

      <Grid item sm={6} xs={12}>
        <Box display="flex" alignItems="center" mt={2} pr={4}>
          <Box mr={1}>
            <Typography variant="h5">Other:</Typography>
          </Box>

          <TextField
            zeroMinWidth
            name="forms.physical_examination.other"
          />
        </Box>
      </Grid>
    </Grid>
  );
};
