import moment from 'moment';
import { Box, Chip, Badge, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CalendarIcon from '@material-ui/icons/Event';
import { preventDefault, stopPropagation } from '../../../../helpers/dom';

export const MultiValue = ({ events = [], onDelete = () => {}, maxVisible = 4 }) => {
  const visibleEvents = events?.slice(0, maxVisible);
  const hiddenEvents = events.length > maxVisible ? events.slice(visibleEvents.length, events.length) : [];

  return (
    <Box display="flex" alignItems="center">
      {!!visibleEvents?.length && visibleEvents?.map((eventItem) => (
        <Box key={eventItem.id} m={0.25} maxWidth={180}>
          <Chip
            tabIndex={-1}
            icon={<CalendarIcon />}
            deleteIcon={<CancelIcon fontSize="small" />}
            onDelete={stopPropagation(preventDefault(onDelete(eventItem.id)))}
            label={(
              <Typography
                noWrap
                title={
                  `${eventItem?.title || ''} at ${moment.unix(eventItem?.started_at).format('L LT')}`
                }
              >
                {`${eventItem?.title || ''} at ${moment.unix(eventItem?.started_at).format('L LT')}`}
              </Typography>
            )}
          />
        </Box>
      ))}

      {!!hiddenEvents?.length && (
        <Box mx={1}>
          <Badge badgeContent={hiddenEvents?.length} color="primary">
            <CalendarIcon />
          </Badge>
        </Box>
      )}
    </Box>
  );
};
