import { Formik } from 'formik';
import { Dialog, Button } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import { Yup } from '../../../utils/validation';
import { updateUser } from '../../../api/users';
import { TextField } from '../../FormField';
import { ModalHeader, ModalBody, ModalFooter } from '../../Modal';
import { Loader } from '../../Loader';

export const AddEmailModal = ({
  DialogProps,
  payload: { userID },
  handleModalResolve,
  handleModalReject
}) => {
  const handleSubmit = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return updateUser(values).then(handleModalResolve).catch(({ status, data: { errors } = {} }) => {
      if (status === 422 && errors) {
        setErrors(errors);
      }
    });
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{ id: userID, email: null }}
        validationSchema={Yup.object().shape({ email: Yup.string().email().nullable().required() })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject} icon={<EmailIcon />}>
              Add Email
            </ModalHeader>

            <ModalBody>
              <TextField
                required
                type="email"
                name="email"
                label="Email"
                placeholder="Enter email..."
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
