import { useState } from 'react';
import { useFormikContext } from 'formik';
import { InputAdornment } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { IconButton } from '../../IconButton';
import { Tooltip } from '../../Tooltip';
import { NumberMaskField } from '../NumberMaskField';

export const SsnField = ({ disableTransform = false, name, ...props }) => {
  const { values } = useFormikContext();
  const [ ssnIsVisible, setSsnIsVisible ] = useState(disableTransform);

  const toggleSsnVisibility = () => {
    setSsnIsVisible((isVisible) => !isVisible);
  };

  return (
    <NumberMaskField
      name={name}
      format={ssnIsVisible ? '###-##-####' : '#########'}
      label="SSN"
      InputProps={hasRole(rolesMap.admin, rolesMap.supervisor) && {
        inputProps: ssnIsVisible ? {} : {
          style: {
            WebkitTextSecurity: 'disc'
          }
        },

        endAdornment: (
          <InputAdornment position="end">
            <Tooltip isExistTooltip={!!values?.ssn} title={ssnIsVisible ? 'Hide SSN' : 'Show SSN'}>
              <IconButton disabled={!values?.ssn} color="primary" onClick={toggleSsnVisibility}>
                {ssnIsVisible ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        )
      }}

      {...props}
    />
  );
};
