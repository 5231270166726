import moment from 'moment';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import filesize from 'filesize';
import { Formik } from 'formik';
import {
  makeStyles,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Dialog,
  Grid,
  Box
} from '@material-ui/core';
import { getAggregateTypeFromMIME } from '../../../../../../helpers/files';
import * as codesApi from '../../../../../../api/codes/procedures';
import { DatePicker, Select, ICDCodesTypesAutocomplete } from '../../../../../../components/FormField';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../../../components/Modal';
import { FileDropZone } from '../../../../../../components/FileDropZone';
import { Loader } from '../../../../../../components/Loader';
import { FileTypeIcon } from '../../../../files-common';
import { validationSchema } from './validationSchema';
import { typesDelimiter } from './typesDelimiter';
import { typesEnclosure } from './typesEnclosure';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ImportCodesModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [ selectedFile, setSelectedFile ] = useState();
  const [ fields, setFields ] = useState({});

  const handleFieldChange = (name) => (option) => {
    setFields({ ...fields, [name]: option.value });
  };

  const uploadFiles = (values, { setErrors, setSubmitting }) => {
    if (!selectedFile) {
      setSubmitting(false);

      return;
    }

    const formData = new FormData();

    formData.append('file', selectedFile, selectedFile.name);
    formData.append('year', values.year);
    formData.append('icd_type_id', values.icd_type_id);

    if (selectedFile.type === 'text/csv') {
      formData.append('enclosure', fields.enclosure);
      formData.append('delimiter', fields.delimiter);
    }

    return codesApi.importICDCode(formData).then(() => {
      enqueueSnackbar(`File "${selectedFile.name}" uploaded`, { variant: 'success' });
      handleModalResolve();
    }).catch(({ errors, message }) => {

      if (errors) {
        errors && setErrors(errors);
        message && enqueueSnackbar(message, { variant: 'error' });
      }
    });
  };

  const handleDropAccepted = (files) => {
    files[0].original_filename = files[0].original_filename || files[0].name;
    files[0].aggregate_type = getAggregateTypeFromMIME(files[0].type);

    setSelectedFile(files[0]);
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        validateOnMount
        initialValues={{
          year: null,
          icd_type_id: null
        }}
        validationSchema={validationSchema}
        onSubmit={uploadFiles}
      >
        {({ errors, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>Upload files</ModalHeader>

            <ModalBody>
              <Grid container spacing={2} component={Box} pb={3}>
                <Grid item sm={6} xs={12}>
                  <ICDCodesTypesAutocomplete
                    required
                    name="icd_type_id"
                    label="Procedure Code Type"
                    margin="dense"
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <DatePicker
                    required
                    clearable
                    name="year"
                    views={[ 'year' ]}
                    format="YYYY"
                    outputFormat="YYYY"
                    label="Year"
                    minDate={moment().subtract(10, 'years').unix()}
                    margin="dense"
                  />
                </Grid>
              </Grid>

              <FileDropZone onDropAccepted={handleDropAccepted}/>

              {errors.file && (
                <>
                  <Typography paragraph color="error">
                    {errors.file}
                  </Typography>

                  <Divider/>
                </>
              )}

              {selectedFile && selectedFile.type === 'text/csv' && (
                <>
                  <Select
                    formattedValue
                    name="delimiter"
                    label="Delimiter"
                    options={typesDelimiter}
                    onChange={handleFieldChange('delimiter')}
                  />

                  <Select
                    formattedValue
                    name="enclosure"
                    label="Enclosure"
                    options={typesEnclosure}
                    onChange={handleFieldChange('enclosure')}
                  />
                </>
              )}

              {!!selectedFile && (
                <div className={classes.filesList}>
                  {!!selectedFile && (
                    <Typography variant="h2" gutterBottom>
                      File selected for upload
                    </Typography>
                  )}

                  <List>
                    <ListItem>
                      <FileTypeIcon file={selectedFile}/>

                      <ListItemText primary={selectedFile.name} primaryTypographyProps={{ noWrap: true }}/>

                      <ListItemText className={classes.fileSize} primary={filesize(selectedFile.size)}/>
                    </ListItem>
                  </List>
                </div>
              )}
            </ModalBody>

            <ModalFooter>
              <Loader
                surface
                loading={isSubmitting}
                render={() => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
