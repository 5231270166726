export const maritalStatusesMap = {
  single: 'single',
  married: 'married',
  divorced: 'divorced',
  domestic_partner: 'domestic_partner',
  separated: 'separated',
  widow: 'widow',
  unknown: 'unknown',
  other: 'other'
};

export const maritalStatusesLabelsMap = {
  [maritalStatusesMap.single]: 'Single',
  [maritalStatusesMap.married]: 'Married',
  [maritalStatusesMap.divorced]: 'Divorced',
  [maritalStatusesMap.domestic_partner]: 'Domestic partner',
  [maritalStatusesMap.separated]: 'Separated',
  [maritalStatusesMap.widow]: 'Widow',
  [maritalStatusesMap.unknown]: 'Unknown',
  [maritalStatusesMap.other]: 'Other'
};
