import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { itemsRoutesByModels } from '../../../../../../../../dataMaps/apiModels';
import { ReadonlyCheckbox } from '../../../../../../../../components/ReadonlyCheckbox';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { TextLabel } from '../../../../../../../../components/TextLabel';
import { statusTypesColors } from '../../../../InvoicesContext/statusTypes';
import { minRowHeight, columnsWidths } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ invoice }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const status = invoice?.status?.name || invoice?.status;

  const getInvoicePage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.invoice(invoice.id));
    }
  };

  return (
    <ListRow
      selected={location.pathname === itemsRoutesByModels.invoice(invoice.id)}
      className={classes.root}
      title="Open invoice page"
      minHeight={minRowHeight}
      onClick={getInvoicePage}
    >
      <ListRowCell grow noWrap flexBasis={columnsWidths.number}>
        <ReadonlyCheckbox fullWidth title={invoice.number} checked={invoice.is_confirmed} />
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.status}>
        <TextLabel color={statusTypesColors[status && status.toLowerCase()]}>
          {status}
        </TextLabel>
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.due_date}>
        <Typography noWrap className={classes.dueDate}>
          {!invoice.due_date ? '-' : moment(invoice.due_date).format('L')}
        </Typography>
      </ListRowCell>
    </ListRow>
  );
};
