import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { makeStyles, Button, Dialog } from '@material-ui/core';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { ModalHeader, ModalBody, ModalFooter } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { Select, TasksSelect } from '../../../../../components/FormField';
import { UsersSelect } from '../../../../../components/users';
import { filesOwnersTypesMap, filesOwnersTypesOptions } from '../../filesOwnersTypesOptions';
import { paperSizesOptions } from '../../paperSizesOptions';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const DocumentSettingsModal = ({
  DialogProps,
  payload,
  handleModalResolve,
  handleModalReject
}) => {
  const classes = useStyles();
  const currentUser = useSelector(({ profile: { user } }) => user);
  const availablePaperSizes = useSelector(({ apiConfig }) => apiConfig.config.html_documents.page_sizes);
  const paperSizes = useMemo(() => {
    return paperSizesOptions.filter(({ value }) => Object.keys(availablePaperSizes).includes(value));
  }, [ availablePaperSizes ]);
  const [ owner, setOwner ] = useState(payload.owner || currentUser);

  const handleModelChange = (setFieldValue) => ({ value }) => {
    setFieldValue('model_id', null);

    if (value === filesOwnersTypesMap.user) {
      setOwner(currentUser);
    }
  };

  const handleUserChange = (user) => {
    setOwner(user);
  };

  const handleSubmit = (values) => {
    const data = {
      ...values,

      owner_type: values.model,
      owner
    };

    handleModalResolve(data);
  };

  return (
    <Dialog maxWidth="xs" {...DialogProps}>
      <Formik
        initialValues={{
          model: filesOwnersTypesOptions[0],
          model_id: null,
          page_size: paperSizes[0],
          page_fields: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          },

          ...payload
        }}
        onSubmit={handleSubmit}
      >
        {({ values: { model, model_id }, handleSubmit, isSubmitting, setFieldValue }) => (
          <form noValidate className={classes.form} onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Document settings
            </ModalHeader>

            <ModalBody>
              {(payload.model && payload.model_id) ? null :
                <>
                  <Select
                    formattedValue
                    name="model"
                    isSearchable={false}
                    label="File destination"
                    options={filesOwnersTypesOptions}
                    onChange={handleModelChange(setFieldValue)}
                    TextFieldProps={{ margin: 'dense' }}
                  />

                  {model === filesOwnersTypesMap.profile &&
                    <UsersSelect
                      isCreatable
                      name="model_id"
                      label="Select user"
                      placeholder="Search user..."
                      TextFieldProps={{ margin: 'dense' }}
                      onChange={handleUserChange}
                      getOptionValue={(user) => user?.profile_id}
                    />
                  }

                  {model === filesOwnersTypesMap.case &&
                    <CasesSelect
                      name="model_id"
                      label="Select case"
                      placeholder="Search case by name..."
                      margin="dense"
                      onChange={setOwner}
                    />
                  }

                  {model === filesOwnersTypesMap.task &&
                    <TasksSelect
                      name="model_id"
                      label="Select task"
                      placeholder="Search task by name..."
                      margin="dense"
                      onChange={setOwner}
                    />
                  }
                </>
              }

              <Select
                formattedValue
                isSearchable={false}
                name="page_size"
                label="Paper size"
                options={paperSizes}
                TextFieldProps={{ margin: 'dense' }}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    disabled={isSubmitting || (model !== filesOwnersTypesMap.user && !model_id)}
                    variant="contained"
                    color="primary"
                  >
                    Apply
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
