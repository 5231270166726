import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import { Button } from '../../../../../../../../components/Button';
import { IconButton } from '../../../../../../../../components/IconButton';
import { ColorPreview } from '../../../../../../../../components/ColorPreview';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const Row = ({ isActive, appointmentType, onToggleActive, onTypeDelete }) => {
  const deleteAppointmentType = () => {
    onTypeDelete(appointmentType);
  };

  const toggleActiveAppointmentType = () => {
    onToggleActive(appointmentType.id);
  };

  return (
    <ListRow disabled={!isActive}>
      <ListRowCell grow actions flexBasis={columnsWidths.code}>
        <ColorPreview color={appointmentType.color} />

        <span>
          {appointmentType.code}
        </span>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.description}>
        {appointmentType.description}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.duration}>
        {moment.duration(appointmentType.time, 'seconds').format('h[h] m[min]')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.claimRequired}>
        {appointmentType.claim_required ? 'Yes' : 'No'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.ordering}>
        {appointmentType.ordering}
      </ListRowCell>

      <ListRowCell actions noDisable justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Button
          variant="contained"
          color={isActive ? 'warning' : 'success'}
          size="small"
          onClick={toggleActiveAppointmentType}
        >
          {isActive ? 'Inactive' : 'Active'}
        </Button>

        <IconButton
          variant="contained"
          color="error"
          edge="end"
          title="Remove from this appt. book"
          onClick={deleteAppointmentType}
        >
          <ClearIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
