import { makeStyles, Typography, Button } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { FileOwner } from '../../FileOwner';
import { styles } from './styles';
import { paperSizesOptions } from '../../paperSizesOptions';

const useStyles = makeStyles(styles);

export const InfoBar = ({ isSubmitting, documentSettings }) => {
  const classes = useStyles();
  const paperSize = paperSizesOptions.find(({ value }) => value === documentSettings.page_size).label;

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <div className={classes.info}>
          <Typography className={classes.info__label}>Paper size</Typography>
          <Typography component="b" variant="subtitle2">{paperSize}</Typography>
        </div>

        <div className={classes.info}>
          <Typography className={classes.info__label}>File owner</Typography>

          <Typography component="b" variant="subtitle2">
            <FileOwner
              owner_type={documentSettings.owner_type}
              owner={documentSettings.owner}
              classes={{ label__icon: classes.ownerIcon }}
            />
          </Typography>
        </div>
      </div>

      <Loader surface loading={isSubmitting} render={
        () => (
          <Button
            type="submit"
            disabled={isSubmitting}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        )}
      />
    </div>
  );
};
