import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { Box } from '@material-ui/core';
import { FiltersBar as MuiFiltersBar } from '../../../../components/FiltersBar';
import {
  TextField,
  Autocomplete,
  CompaniesAutocomplete,
  KeyboardDatePicker
} from '../../../../components/FormField';
import { SavedFiltersProvider, SavedFiltersWidget } from '../../../../components/saved-filters';
import { saveFilter } from '../../../../store/lastFilters';
import {
  InsurancesContext,
  statusTypeCaseOptions,
  insuranceStatusTypesMap,
  caseInsuranceTypesOptions,
  medicInsuranceTypesOptions,
  lawOfficeInsuranceTypesOptions
} from '../../ProfilePage/insurance';
import { transformRelationsForFilterToOptions, transformRelationsToReset } from './utils';
import { fieldsKeysMap, filterLabels } from './filterKeysMap';
import { orderByOptions } from './orderByOptions';
import { List } from './List';

export const initialValues = {
  order_by: orderByOptions[0]
};

const IconComponent = ({ relationsForFilter, onApplySavedFilter, filterKey }) => {
  return (
    <SavedFiltersProvider filter={{ entity: filterKey }}>
      <SavedFiltersWidget
        relationsForFilter={relationsForFilter}
        modalWidth={900}
      >
        {({ handleClose }) => (
          <List onApplySavedFilter={flow(onApplySavedFilter, handleClose)} />
        )}
      </SavedFiltersWidget>
    </SavedFiltersProvider>
  );
};

export const FiltersBar = ({ filterKey }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { filter, resetInsurances } = useContext(InsurancesContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({
    order_by: orderByOptions[0]
  });

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(transformRelationsForFilterToOptions(filter));
    resetInsurances(transformRelationsToReset(filter));
  };

  const handleFieldChange = (name) => (event) => {
    resetInsurances({ [name]: event.target.value });
    setRelationsForFilter((state) => ({ ...state, [name]: event.target.value }));
  };

  const handleSelectChange = (name, resetType = false) => (option) => {
    let type = {};

    if (resetType) {
      type = { [fieldsKeysMap.typeInsurance]: undefined };
      formikRef.current.setFieldValue(fieldsKeysMap.typeInsurance, undefined);
    }

    resetInsurances({ [name]: option?.value || option, ...type });
    setRelationsForFilter((state) => ({
      ...state,

      [name]: option?.data || option,

      ...type
    }));
  };

  const handleCompanyChange = (company) => {
    resetInsurances({ insurance_company_id: company?.id });
    setRelationsForFilter((state) => ({ ...state, insurance_company_id: company }));
  };

  const handleDatePickerChange = (name, transformer) => (date) => {
    resetInsurances({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    if (lastFilters) {
      applySavedFilter({
        ...lastFilters,
        ...relationsForFilter
      });
    }
  }, []);

  return (
    <MuiFiltersBar
      isResetForm
      disableTopPaddings
      enableSettings
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      onReset={() => applySavedFilter(initialValues)}
      iconComponent={(
        <IconComponent
          filterKey={filterKey}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      fieldsList={[
        {
          fieldKey: fieldsKeysMap.insuranceCompanyId,
          label: filterLabels[fieldsKeysMap.insuranceCompanyId],
          field: (
            <Box minWidth={210}>
              <CompaniesAutocomplete
                name={fieldsKeysMap.insuranceCompanyId}
                label={filterLabels[fieldsKeysMap.insuranceCompanyId]}
                placeholder="Search company..."
                margin="dense"
                onChange={handleCompanyChange}
              />
            </Box>
          )
        },
        {
          fieldKey: fieldsKeysMap.insuranceId,
          label: filterLabels[fieldsKeysMap.insuranceId],
          field: (
            <TextField
              name={fieldsKeysMap.insuranceId}
              label={filterLabels[fieldsKeysMap.insuranceId]}
              placeholder="Search..."
              margin="dense"
              onChange={handleFieldChange(fieldsKeysMap.insuranceId)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.groupId,
          label: filterLabels[fieldsKeysMap.groupId],
          field: (
            <TextField
              name={fieldsKeysMap.groupId}
              label={filterLabels[fieldsKeysMap.groupId]}
              placeholder="Search..."
              margin="dense"
              onChange={handleFieldChange(fieldsKeysMap.groupId)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.statusType,
          label: filterLabels[fieldsKeysMap.statusType],
          field: (
            <Autocomplete
              name={fieldsKeysMap.statusType}
              label={filterLabels[fieldsKeysMap.statusType]}
              margin="dense"
              options={statusTypeCaseOptions}
              onChange={handleSelectChange(fieldsKeysMap.statusType, true)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.typeInsurance,
          label: filterLabels[fieldsKeysMap.typeInsurance],
          field: (
            <Autocomplete
              name={fieldsKeysMap.typeInsurance}
              label={filterLabels[fieldsKeysMap.typeInsurance]}
              margin="dense"
              options={filter.status_type === insuranceStatusTypesMap.law_office
                ? lawOfficeInsuranceTypesOptions
                : filter.status_type === insuranceStatusTypesMap.case_insurance
                  ? caseInsuranceTypesOptions
                  : medicInsuranceTypesOptions
              }
              onChange={handleSelectChange(fieldsKeysMap.typeInsurance)}
            />
          )
        },
        {
          fieldKey: fieldsKeysMap.expirationDay,
          label: filterLabels[fieldsKeysMap.expirationDay],
          field: (
            <KeyboardDatePicker
              clearable
              name={fieldsKeysMap.expirationDay}
              label={filterLabels[fieldsKeysMap.expirationDay]}
              margin="dense"
              onChange={handleDatePickerChange(fieldsKeysMap.expirationDay)}
            />
          )
        }
      ]}
    />
  );
};
