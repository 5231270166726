import { Grid } from '@material-ui/core';
import * as billingApi from '../../../../api/billing';
import { getUserFullName } from '../../../../helpers/users';
import { Avatar } from '../../../Avatar';
import { billingParticipantsTypesMap } from '../../../billing';
import { UserAvatar } from '../../../users';
import { Autocomplete } from '../../Autocomplete';

const fetchBillingParticipants = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return billingApi.fetchBillingParticipants({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => {
  const isUser = option?.participant_type === billingParticipantsTypesMap.user;

  return (
    <Grid container wrap="nowrap" spacing={1} alignItems="center">
      <Grid item>
        {isUser ? (
          <UserAvatar size="xs" user={option?.participant} />
        ) : (
          <Avatar
            size="xs"
            src={option?.participant?.avatar}
            alt={(option?.participant || option?.info)?.name?.[0]}
          />
        )}
      </Grid>

      <Grid item>
        {isUser ? getUserFullName(option?.participant) : option?.info?.name}
      </Grid>
    </Grid>
  );
};

const getOptionLabel = (option) => {
  return option?.participant_type === billingParticipantsTypesMap.user
    ? getUserFullName(option?.participant) || option?.info?.name
    : (option?.participant || option?.info)?.name;
};

export const BillingParticipantsSelect = ({
  multiple = false,
  formattedValue = true,
  params = {},

  ...props
}) => {
  return (
    <Autocomplete
      isAsync
      multiple={multiple}
      placeholder="Search participant..."
      onNeedFetch={fetchBillingParticipants(params)}
      renderOption={renderOption}
      getInputProps={(value) => !(!multiple && value) ? null : ({
        startAdornment: value.participant_type === billingParticipantsTypesMap.user ? (
          <UserAvatar user={value} size="xs" />
        ) : (
          <Avatar
            size="xs"
            alt={(value?.participant || value?.info)?.[0]}
            src={value?.participant?.avatar}
          />
        )
      })}
      getOptionLabel={getOptionLabel}
      getOptionValue={(option) => formattedValue ? option?.id : option}
      getOptionSelected={(option, value) => option?.id === value?.id}

      {...props}
    />
  );
};
