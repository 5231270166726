import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { makeStyles, List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import * as documentsApi from '../../../../../../../../api/profile';
import { Loader } from '../../../../../../../../components/Loader';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { SvgIcon } from '../../../../../../../../components/SvgIcon';
import { rolesMap } from '../../../../../../../../dataMaps/rolesMap';
import { generateAggregateFileType } from '../../../../../../../../utils/generateAggregateFileType';
import { hasRole } from '../../../../../../../../utils/hasRole';
import { EmailsSendModal } from '../../../../../../EmailsPage/EmailsClient/EmailsSendModal';
import { DocumentsContext } from '../../DocumentsProvider';
import { DocumentsModal } from '../../DocumentsModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const actionsMap = {
  send: 'send',
  download: 'download'
};

export const DocumentsMenu = ({ document }) => {
  const classes = useStyles();
  const currentUser = useSelector(({ profile }) => profile.user);
  const { openModal } = useModal();
  const { fetchDocuments } = useContext(DocumentsContext);
  const [ activeAction, setActiveAction ] = useState(null);

  const openDocumentModal = () => {
    openModal(DocumentsModal, {
      payload: {
        documentID: document.id,
        fetchDocuments
      }
    });
  };

  const handleDownloadPDF = () => {
    setActiveAction(actionsMap.download);

    documentsApi.getPDF(document.id).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ]));

      saveAs(url, `${document.name}.pdf`);
    }).finally(() => {
      setActiveAction(null);
    });
  };

  const sendByEmail = () => {
    setActiveAction(actionsMap.send);

    documentsApi.getPDF(document.id).then((blob) => {
      const name = `${document.name}.pdf`;
      const file = new File([ blob ], name, { type: blob.type });

      file.aggregate_type = generateAggregateFileType(file.type);

      openModal(EmailsSendModal, {
        payload: {
          accounts: currentUser.email_accounts,
          file
        }
      });
    }).finally(() => {
      setActiveAction(null);
    });
  };

  return (
    <List className={classes.root}>
      <MenuItem onClick={openDocumentModal}>
        <ListItemIcon>
          <EyeIcon />
        </ListItemIcon>

        <ListItemText primary={!document.need_you_sign ? 'Preview' : 'Preview/Sign'} />
      </MenuItem>

      {!hasRole(rolesMap.client, rolesMap.patient) && !!currentUser?.email_accounts?.length &&
        <Loader surface loading={activeAction === actionsMap.send} render={
          () => (
            <MenuItem onClick={sendByEmail}>
              <ListItemIcon>
                <SvgIcon color="info"><EmailIcon /></SvgIcon>
              </ListItemIcon>

              <ListItemText primary="Send by email" />
            </MenuItem>
          )}
        />
      }

      <Loader surface loading={activeAction === actionsMap.download} render={
        () => (
          <MenuItem onClick={handleDownloadPDF}>
            <ListItemIcon>
              <SvgIcon color="secondary"><DownloadIcon /></SvgIcon>
            </ListItemIcon>

            <ListItemText primary="Download" />
          </MenuItem>
        )}
      />
    </List>
  );
};
