export const chatWidgetMinWidth = 72;
export const chatWidgetMaxWidth = 274;

export const styles = ({ palette, zIndex, shadows, transitions }) => ({
  sidebar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: zIndex.appBar,
    width: chatWidgetMaxWidth,
    backgroundColor: palette.common.white,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: shadows[2],
    transform: `translateX(-${chatWidgetMaxWidth - chatWidgetMinWidth}px)`,
    transition: ({ isMinimized }) => transitions.create([ 'transform' ], {
      duration: isMinimized ? transitions.duration.enteringScreen : transitions.duration.leavingScreen
    })
  },

  minimized: {
    transform: 'translateX(0)'
  }
});
