import {
  makeStyles,
  List as MuiList,
  Typography,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../ListRow';
import { spinalExaminationMap } from '../../../../spinalExaminationMap';
import { columnsWidthsSm, columnsWidthsLg } from '../columnsWidths';
import { MotorStrength } from './MotorStrength';
import { RangeMotion } from './RangeMotion';
import { ReflexLower } from './ReflexLower';
import { SpecialTest } from './SpecialTest';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CervicalSpine = ({ report }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columnsWidths = isMobile ? columnsWidthsSm : columnsWidthsLg;

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        Palpation of the spine reveals tenderness (graded 0=none; 1+=mild; 2+=moderate; and
        3+=marked) in the following regions:
      </Typography>

      <Box my={2}>
        <div className={classes.root}>
          <ListRow header>
            <ListRowCell noWrap grow flexBasis={columnsWidths.name} />

            <ListRowCell flexBasis={columnsWidths.right}>
              <Typography variant="subtitle2">
                Right
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.midline}>
              <Typography variant="subtitle2">
                Midline
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.left}>
              <Typography variant="subtitle2">
                Left
              </Typography>
            </ListRowCell>
          </ListRow>

          <MuiList disablePadding>
            {Object.keys(spinalExaminationMap)?.map((item, i) => (
              <ListRow key={i}>
                <ListRowCell noWrap grow flexBasis={columnsWidths.name}>
                  <Typography noWrap variant="h5">
                    {spinalExaminationMap[item]}:
                  </Typography>
                </ListRowCell>

                <ListRowCell noWrap flexBasis={columnsWidths.right}>
                  {!!report?.spinal_examination_cervical_spine[item]
                    ? report?.spinal_examination_cervical_spine[item]?.right
                    : '-'
                  }
                </ListRowCell>

                <ListRowCell noWrap flexBasis={columnsWidths.midline}>
                  {report?.spinal_examination_cervical_spine[item]?.midline || '-'}
                </ListRowCell>

                <ListRowCell noWrap flexBasis={columnsWidths.left}>
                  {report?.spinal_examination_cervical_spine[item]?.left || '-'}
                </ListRowCell>
              </ListRow>
            ))}
          </MuiList>
        </div>

        <RangeMotion report={report?.range_of_motion_cervical_spine || {}} />
        <MotorStrength report={report?.motor_strength_upper_extremities || {}} />
        <ReflexLower report={report?.reflexes_lower_extremities || {}} />
        <SpecialTest report={report?.special_tests} />
      </Box>
    </>
  );
};
