import { Fragment } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Date } from '../../Date';
import { ReadonlyCheckbox } from '../../ReadonlyCheckbox';

export const DurableMedicalEquipment = ({ serviceLine }) => {
  return <>
    <Box mb={3}>
      <Typography variant="h3">
        Durable Medical Equipment
      </Typography>
    </Box>

    <Grid container spacing={2}>
      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          Length of Medical Necessity
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="subtitle2">
          {serviceLine.length_medical_necessity || '-'}
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          Rental Price
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="subtitle2">
          {serviceLine.rental_price || '-'}
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          Purchase Price
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="subtitle2">
          {serviceLine.purchase_price || '-'}
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          Purchase Type
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="subtitle2">
          {serviceLine.rental_type?.label || '-'}
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          DMERC Condition Indicator
        </Typography>
      </Grid>

      <Grid item md={6}>
        <ReadonlyCheckbox
          title={(serviceLine.dmerc_condition_indicator === 'n/a') && 'N/A'}
          iconColor="success"
          checked={[ 'yes', 'n/a' ].includes(serviceLine.dmerc_condition_indicator)}
        />
      </Grid>

      {serviceLine.condition_indicators?.length ? (
        serviceLine.condition_indicators.map(({ value, label }, index) => (
          <Fragment key={value}>
            <Grid item md={6}>
              <Typography variant="body2" color="textSecondary">
                Condition Indicator {index + 1}
              </Typography>
            </Grid>

            <Grid item md={6}>
              <Typography variant="subtitle2">{label}</Typography>
            </Grid>
          </Fragment>
        ))
      ) : (
        <>
          <Grid item md={6}>
            <Typography variant="body2" color="textSecondary">
              Condition Indicators
            </Typography>
          </Grid>

          <Grid item md={6}>
            <Typography variant="subtitle2">-</Typography>
          </Grid>
        </>
      )}

      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          Certification Type Code
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="subtitle2">
          {serviceLine.certification_type_code?.label || '-'}
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          DME Duration
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="subtitle2">
          {serviceLine.dme_duration || '-'}
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          Certification Revision Date
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Date
          disableMoment
          variant="subtitle2"
          iconFontSize="small"
          date={serviceLine.certification_revision_date}
        />
      </Grid>

      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          Begin Therapy Date
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Date
          disableMoment
          variant="subtitle2"
          iconFontSize="small"
          date={serviceLine.begin_therapy_date}
        />
      </Grid>

      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          Last Certification Date
        </Typography>
      </Grid>

      <Grid item md={6}>
        <Date
          disableMoment
          variant="subtitle2"
          iconFontSize="small"
          date={serviceLine.last_certification_date}
        />
      </Grid>
    </Grid>
  </>;
};
