import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import { isEmptyDeep } from '../../../../helpers/isEmptyDeep';
import { getPropertiesMap, getUpdatedKeys } from '../../helpers';

export const WidgetActivity = ({ before, after, fieldLabel, fieldsMap }) => {
  const isBefore = !isEmpty(before);
  const isAfter = !isEmpty(after);
  const [ content, setContent ] = useState([]);

  useEffect(() => {
    const updatedProperties = getUpdatedKeys(before || {}, after || {});
    const propertiesMap = getPropertiesMap(fieldsMap);
    const newChecklistContent = Object.keys(updatedProperties)?.reduce((messages, key) => {
      return propertiesMap[key] ? messages.concat(propertiesMap[key](updatedProperties)) : messages;
    }, []);

    setContent(newChecklistContent);
  }, []);

  if (!isBefore && !isAfter) {
    return (
      <>
        Added empty&nbsp;<Typography variant="subtitle1">{fieldLabel};&nbsp;</Typography>
      </>
    );
  } else if (isBefore && !isAfter) {
    return (
      <>
        Update information in&nbsp;<Typography variant="subtitle1">{fieldLabel}</Typography>
      </>
    );
  } else {
    return !!content?.length && (
      <Box fontSize={14} display="flex" alignItems="center" flexWrap="wrap">
        {content?.map((action, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            mr={1}
            flexWrap="wrap"
            {...(action?.props?.boxProps || {})}
          >
            {action}
            {index !== content.length - 1 && !isEmptyDeep(action?.props) && !action?.props?.disableSemicolon && ';'}
          </Box>
        ))}
      </Box>
    );
  }
};
