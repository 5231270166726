import { DOC } from './DOC';
import { PDF } from './PDF';
import { CSV } from './CSV';
import { Image } from './Image';
import { Sharepoint } from './Sharepoint';
import { SVG } from './SVG';
import { VideoPlayer } from './VideoPlayer';
import { AudioPlayer } from './AudioPlayer';
import { Text } from './Text';
import { NoFileSelected } from './PreviewNotFound';

export const previewVariants = [
  {
    component: Sharepoint,
    condition: (file) => {
      return file.disk === 'sharepoint';
    }
  },
  {
    component: NoFileSelected,
    condition: (file) => {
      return !file.id;
    }
  },
  {
    component: Text,
    condition: ({ extension }) => {
      return extension === 'txt';
    }
  },
  {
    component: AudioPlayer,
    condition: ({ aggregate_type }) => {
      return aggregate_type === 'audio';
    }
  },
  {
    component: VideoPlayer,
    condition: ({ aggregate_type }) => {
      return aggregate_type === 'video';
    }
  },
  {
    component: PDF,
    condition: ({ aggregate_type, previews = {} }) => {
      return aggregate_type === 'pdf' || previews?.pdf;
    }
  },
  {
    component: CSV,
    condition: ({ extension }) => {
      return extension === 'csv';
    }
  },
  {
    component: DOC,
    condition: ({ extension }) => {
      return [ 'xls', 'xlsx' ].find((ext) => ext === extension);
    }
  },
  {
    component: PDF,
    condition: ({ extension }) => {
      return [ 'doc', 'docx' ].find((ext) => ext === extension);
    }
  },
  {
    component: Image,
    condition: ({ aggregate_type }) => {
      return aggregate_type === 'image';
    }
  },
  {
    component: SVG,
    condition: ({ aggregate_type }) => {
      return aggregate_type === 'vector';
    }
  }
];
