import { Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell flexBasis={columnsWidths.invoiceNumber}>
        <Typography noWrap variant="h5">
          Inv Number
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.user}>
        <Typography noWrap variant="h5">
          User
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.cases}>
        <Typography noWrap variant="h5">
          Cases
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateFrom}>
        <Typography noWrap variant="h5">
          Date From
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.dateTo}>
        <Typography noWrap variant="h5">
          Date To
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.expenseCategory}>
        <Typography noWrap variant="h5">
          Expense Category
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
