import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FETCH_SUCCESS]: (
    { filter, medicalHistory, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      medicalHistory: page > 1
        ? medicalHistory.filter(({ id }) => !data.find((loadedHistory) => id === loadedHistory.id)).concat(data)
        : data
    };
  },

  [types.RESET_HISTORY]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter }
    };
  },

  [types.TOGGLE_ITEM_SELECTION]: ({ selectedItem, ...state }, item) => {
    return {
      ...state,

      selectedItem: selectedItem?.id !== item?.id ? item : null
    };
  }
});
