import moment from 'moment';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ discovery }) => {
  const classes = useStyles();
  const daysLeft = discovery.due_date && moment(discovery.due_date).diff(moment(), 'days');

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>
            Date Sent
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h4">
            {discovery.send_date ? moment(discovery.send_date).format('L') : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>
            Date Received
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h4">
            {discovery.received_date ? moment(discovery.received_date).format('L') : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>
            Date End
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography color={daysLeft <= 5 ? 'error' : 'textPrimary'}>
            {discovery.due_date ? moment(discovery.due_date).fromNow() : '-'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
