import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../helpers/createReduxReducer';

// Action types
const SET_FONTSIZE = 'SET_FONTSIZE';

export const setFontSize = (fontSize) => ({ type: SET_FONTSIZE, payload: fontSize });

// Reducer
const initialState = {
  fontSize: 100
};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [SET_FONTSIZE]: (state, payload) => {
    return { ...state, fontSize: payload };
  }
});
