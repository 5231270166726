import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Grid, Typography, Box } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { generateAddress } from '../../../../utils/generateAddress';
import * as usersApi from '../../../../api/users';
import { AddressLink } from '../../../AddressLink';
import { Button } from '../../../Button';
import { Contact } from '../../../Contact';
import { Date } from '../../../Date';
import { Loader } from '../../../Loader';
import { useModal } from '../../../ModalsProvider';
import { Tooltip } from '../../../Tooltip';
import { UserLink } from '../../../UserLink';
import { PatientsSelect } from '../../../users/PatientsSelect';
import { VerticalDivider } from '../../../VerticalDivider';
import { AppointmentsHistoryModal } from '../../AppointmentsHistoryModal';
import { AuthorizationsSelect } from '../../AuthorizationsSelect';
import { InsuranceDetails } from '../InsuranceDetails';

export const PatientDetails = () => {
  const { openModal } = useModal();
  const [ patientIsFetching, setPatientIsFetching ] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const address = (
    values?.patient_appointment_details?.full_address
    || values?.patient_appointment_details?.home?.full_address
    || generateAddress(values?.patient_appointment_details?.home)
  );
  const patientID =  values?.patient_id?.id || values?.patient_id?.id || values?.patient_id;

  const showHistory = () => {
    openModal(AppointmentsHistoryModal, {
      payload: { patientID }
    });
  };

  const handlePatientChange = (patient) => {
    if (patient?.id) {
      setPatientIsFetching(true);
      setFieldValue('patient_insurance_id', null);
      setFieldValue('patient', null);

      usersApi.fetchUser(patient?.id).then((user) => {
        user.patient = { ...patient, ...user };

        setPatientIsFetching(false);
        setFieldValue('patient', user);
        setFieldValue('patient_appointment_details', user);

        if (user?.medical?.id !== values?.medical_id) {
          setFieldValue('patient_insurance_id', user?.medical?.main_insurance);
          setFieldValue('patient_insurance_pcp', user?.medical?.main_insurance?.pcp);
          setFieldValue('patient_insurance_spc', user?.medical?.main_insurance?.spc);
        }
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {(!values.id || (!!values.id && !values.patient?.id)) &&
          <Grid item sm={3} xs={12}>
            <PatientsSelect
              name="patient_id"
              label="Select patient"
              TextFieldProps={{ required: true }}
              onChange={handlePatientChange}
            />
          </Grid>
        }
      </Grid>

      {!!values.patient_id &&
        <>
          <Box display="flex" alignItems="center" mt={3} mb={2} color="info.main">
            <Typography variant="h3" color="inherit">Patient Details</Typography>

            <VerticalDivider verticalGutters={1} horizontalGutters={3} />

            <Button
              edge="start"
              color="primary"
              startIcon={<EyeIcon />}
              onClick={showHistory}
            >
              Appt. history
            </Button>
          </Box>

          <Loader loading={patientIsFetching} render={
            () => (
              <Grid container spacing={4}>
                <Grid item sm={6} xs={12}>
                  <Grid container spacing={1} alignItems="flex-start">
                    <Grid item container spacing={1}>
                      <Grid item xs={5}>
                        <Typography noWrap color="textSecondary">
                          Patient Name
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <UserLink
                          size="sm"
                          variant="body1"
                          user={values?.patient_appointment_details?.patient}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={1}>
                      <Grid item xs={5}>
                        <Typography noWrap color="textSecondary">
                          DOB
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <Date date={values?.patient_appointment_details?.birthday} />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={1}>
                      <Grid item xs={5}>
                        <Typography noWrap color="textSecondary">
                          Pri. Phone
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <Contact
                          needIcon
                          variant="body1"
                          type="tel"
                          contact={
                            values?.patient_appointment_details?.patient?.phone_number ||
                              values?.patient_appointment_details?.patient?.mobile_phone_number ||
                                values?.patient_appointment_details?.phone_number
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={1}>
                      <Grid item xs={5}>
                        <Typography noWrap color="textSecondary">
                          MRN
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <Typography>
                          {values?.patient_appointment_details?.medical?.med_rec ||
                            values?.patient_appointment_details?.med_rec ||
                              '-'
                          }
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container spacing={1}>
                      <Grid item xs={5}>
                        <Typography noWrap color="textSecondary">
                          Ext. MRN
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <Typography>
                          {values?.patient_appointment_details?.medical?.ext_med_rec ||
                            values?.patient_appointment_details?.ext_med_rec ||
                              '-'
                          }
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container spacing={1}>
                      <Grid item xs={5}>
                        <Typography noWrap color="textSecondary">
                          Address
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <Box display="flex" overflow="hidden">
                          <Box clone mr={0.5}>
                            <LocationOnIcon />
                          </Box>

                          <AddressLink variant="body1" address={address} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sm={6}>
                  <Grid container spacing={1} alignItems="flex-start">
                    <Grid item container spacing={1}>
                      <Grid item xs={5}>
                        <Typography noWrap color="textSecondary">
                          Referral Name
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <UserLink
                          size="sm"
                          user={
                            values?.patient_appointment_details?.medical?.preferred ||
                            values?.patient_appointment_details?.preferred
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={1}>
                      <Grid item xs={5}>
                        <Typography noWrap color="textSecondary">
                          Referring Physician
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <UserLink
                          size="sm"
                          user={
                            values?.patient_appointment_details?.medical?.referring_physician ||
                              values?.patient_appointment_details?.referring_physician
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={1}>
                      <Grid item xs={5}>
                        <Typography noWrap color="textSecondary">
                          Treating Physician
                        </Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <UserLink
                          size="sm"
                          user={
                            values?.patient_appointment_details?.medical?.treating_physician ||
                              values?.patient_appointment_details?.treating_physician
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Tooltip isExistTooltip={!values.patient?.id} title="Select patient firstly">
                        <div>
                          <AuthorizationsSelect
                            patientID={values.patient?.id}
                            disabled={!values.patient?.id}
                            name="authorization_id"
                            label="Authorization #"
                            params={{
                              users: values.patient?.id && [ values.patient?.id ]
                            }}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          />

          <InsuranceDetails />
        </>
      }
    </>
  );
};
