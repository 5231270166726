import { Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap variant="h5">
          User name
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.email}>
        <Typography noWrap variant="h5">
          Email
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.role}>
        <Typography noWrap variant="h5">
          User role
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>

      </ListRowCell>
    </ListRow>
  );
};
