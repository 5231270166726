import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { every, get, isEmpty } from 'lodash';
import { Formik } from 'formik';
import { makeStyles, Button, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as codesApi from '../../../../../../api/codes/procedures';
import {
  Page,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle,
  PageBody
} from '../../../../../../components/Page';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Loader } from '../../../../../../components/Loader';
import { Body } from '../Create';
import { scheduleValidationSchema } from './scheduleValidationSchema';
import { validationSchema } from '../validationSchema';
import { Footer } from './Footer';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Edit = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [ initialCodeData, setInitialCodeData ] = useState();
  const [ NDCCodes, setNDCCodes ] = useState([]);
  const [ isEmptySchedule, setIsEmptySchedule ] = useState(true);

  const createFeeSchedule = ({ values, setSubmitting, setErrors }) => {
    codesApi.createFeeSchedule(values.schedule).then(() => {
      enqueueSnackbar('Code successfully updated', { variant: 'success' });
      history.push('/codes/procedure-codes/cpt');
    }).catch((errors) => {
      if (errors) {
        setSubmitting(false);
        setErrors({ ...errors, schedule: { ...errors.schedule,  payer_id: 'The payer has already been taken' } });
        enqueueSnackbar('Code not updated', { variant: 'error' });
      }
    });
  };

  const handleCPTCodeUpdate = ({ values, setSubmitting, setErrors }) => {
    codesApi.updateCode(values).then((data) => {
      if (!isEmptySchedule) {
        values.schedule.procedure_code_id = data.id;

        createFeeSchedule({ values, setSubmitting, setErrors });
      } else {
        enqueueSnackbar('Code successfully updated', { variant: 'success' });
        history.push('/codes/procedure-codes/cpt');
      }
    }).catch((errors) => {
      if (errors) {
        setErrors(errors);
        setSubmitting(false);
        enqueueSnackbar('Code not updated', { variant: 'error' });
      }
    });
  };

  const updateCPTCode = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    values.office_id = values?.office_id?.value || values?.office_id || null;
    values.provider_id = values?.provider_id?.value || values?.provider_id?.id || values?.provider_id || null;
    values.region_code_id = values?.region_code_id?.value || values?.region_code_id || null;
    values.tos_id = values?.tos_id?.value || values?.tos_id || null;
    values.ndc_codes = !!NDCCodes.length ? NDCCodes.map(({ ndc_code_name, ...item }) => {
      return {
        ndc_code_id: get(item, 'ndc_code.id', item.ndc_code_id),
        ...item
      };
    }) : [];

    if (values.ndc && !every(values.ndc, isEmpty)) {
      openModal(ConfirmationModal, {
        DialogProps: {
          PaperProps: { style: { maxWidth: 650 } }
        },
        payload: {
          title: 'Warning',
          content: 'You have unsaved NDC codes. If you leave before saving, ' +
            'your changes will be lost. Do you want to continue?'
        },
        onModalResolved: () => {
          handleCPTCodeUpdate({ values, setSubmitting, setErrors });
        },
        onModalRejected: () => {
          setSubmitting(false);
        }
      });
    } else {
      handleCPTCodeUpdate({ values, setSubmitting, setErrors });
    }

  };

  useEffect(() => {
    const fetchCode = () => {
      codesApi.fetchCode(params.id)
        .then((data) => {
          setNDCCodes(data.ndc_codes);
          setInitialCodeData(data);
        })
        .catch(() => {
          history.push('/codes/procedure-codes/cpt');
        });
    };

    fetchCode();
  }, []);

  const handleNdcCodesUpdate = (codes) => {
    setNDCCodes(codes);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialCodeData,

        provider_id: initialCodeData?.provider,
        office_id: initialCodeData?.office || null,
        region_code_id: initialCodeData?.region_code,
        is_all_offices: !initialCodeData?.office,
        is_all_providers: !initialCodeData?.provider,
        add_on: !!initialCodeData?.add_on,
        enable_crosswalk: !!initialCodeData?.enable_crosswalk,
        hcpcs_exceptions: !!initialCodeData?.hcpcs_exceptions,
        enable_cross_codes: !!initialCodeData?.enable_cross_codes,
        do_not_bill_claim: !!initialCodeData?.do_not_bill_claim,
        tos_id: initialCodeData?.tos,
        schedule: {
          payer_id: null,
          charge_amount: null,
          default_units: null,
          expected_amount: null,
          modifier_id: null,
          work_rvu: null,
          moldx_code: null,
          dx_code: null,
          total_rvu: null,
          is_all_payers: false
        }
      }}
      validationSchema={isEmptySchedule ? validationSchema : scheduleValidationSchema}
      onSubmit={updateCPTCode}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form noValidate className={classes.root} onSubmit={handleSubmit}>
          <Page>
            <PageHeader>
              <IconButton
                component={Link}
                to="/codes/procedure-codes/cpt"
                color="primary"
                edge="start"
              >
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>

              <PageHeaderTitle>
                Edit CPT
              </PageHeaderTitle>

              <PageHeaderActions>
                <Button
                  size={isMobile ? 'small' : 'medium'}
                  component={Link}
                  to="/codes/procedure-codes/cpt"
                  color="primary"
                >
                  Cancel
                </Button>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      size={isMobile ? 'small' : 'medium'}
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />
              </PageHeaderActions>
            </PageHeader>

            <PageBody>
              <Loader loading={!initialCodeData} render={
                () => (
                  <>
                    <Body />
                    <Footer
                      NDCCodes={NDCCodes}
                      onNdcCodesUpdate={handleNdcCodesUpdate}
                      setIsEmptySchedule={setIsEmptySchedule}
                    />
                  </>
                )}
              />
            </PageBody>
          </Page>
        </form>
      )}
    </Formik>
  );
};
