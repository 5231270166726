export const initialValues = {
  user_id: null,
  meds_amount: null,
  demand_sent_date: null,
  demand_due_date: null,
  demand_amount: null,
  demand_um_date: null,
  demand_um_due_date: null,
  demand_um_amount: null,
  counter_date: null,
  counter_amount: null,
  offer_date: null,
  offer_amount: null,
  settled_date: null,
  settled_third_party_amount: null,
  settled_um_date: null,
  settled_um_amount: null,
  received_founds: [
    {
      date: null,
      amount: null,
      name: 'Medpay Rec'
    },
    {
      date: null,
      amount: null,
      name: '3rd Party'
    },
    {
      date: null,
      amount: null,
      name: 'UM'
    },
    {
      date: null,
      amount: null,
      name: null
    }
  ],
  total_founds_received: 0,
  atty_costs_five: false,
  atty_costs_six: false,
  atty_costs: 0,
  atty_fee_legal_costs: 0,
  net_atty_fees: 0,
  total_to_providers: 0,
  total_to_client: 0
};
