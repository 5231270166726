import { Typography } from '@material-ui/core';
import { NewFileActivity } from '../../Activities/fields';
import { getPropertiesMap } from '../../Activities/helpers/getPropertiesMap';
import { fieldsMap } from './fieldsMap';

export const activityActionTypesMap = {
  'CREATED@APPOINTMENT': () => [
    <Typography variant="subtitle1">Created appointment</Typography>
  ],

  'UPDATED@APPOINTMENT': (payload) => {
    const properties = payload?.properties;
    const propertiesMap = getPropertiesMap(fieldsMap);

    return Object.keys(properties)?.reduce((messages, key) => {
      return propertiesMap[key] ? messages.concat(propertiesMap[key](properties)) : messages;
    }, []);
  },

  'CREATED@APPOINTMENT:FILE': ({ file }) => {
    return [ <NewFileActivity file={file} /> ];
  }
};

export const getActionMessages = ({ log_data }) => {
  return activityActionTypesMap[log_data?.type]?.(log_data?.payload);
};
