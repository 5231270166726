import cn from 'classnames';
import { makeStyles, Box, Typography, Tooltip, Grid } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Date } from '../../../../../../../components/Date';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Body = ({ item }) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" fontWeight={500} mb={0.5}>
        <NotesIcon />

        <Box ml={1}>
          <Typography variant="h6">Clinical Note</Typography>
        </Box>
      </Box>

      {item.note ?
        <Tooltip arrow leaveDelay={200} title={item.note}>
          <Box
            fontWeight="normal"
            height={38}
            className={cn(classes.noteContent, { [classes.noteContent_cropped]: item.note.length > 120 })}
          >
            <Typography color="textSecondary" component="p" variant="caption" align="justify">
              {item.note}
            </Typography>
          </Box>
        </Tooltip>
        :
        <Typography color="textSecondary">-</Typography>
      }

      <Box className={classes.root}>
        <Grid container>
          <Grid container>
            <Grid item xs={7}>
              {item.doi_from ? (
                <>
                  <Date
                    disableIcon
                    variant="h4"
                    format="MMM D, YYYY"
                    color="textSecondary"
                    date={item.doi_from}
                  />
                  &nbsp;-&nbsp;
                  <Date
                    disableIcon
                    variant="h4"
                    format="MMM D, YYYY"
                    color="textSecondary"
                    date={item.doi_to}
                  />
                </>
              ) : (
                <Date
                  variant="h4"
                  color="textSecondary"
                  format="MMM D, YYYY"
                  date={item?.doi_string || item.doi}
                  iconFontSize="small"
                />
              )}
            </Grid>

            <Grid item xs={5}>
              <Date
                disableIcon
                variant="h4"
                format="MMM D, YYYY"
                color="textSecondary"
                date={item.dor}
              />
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Typography variant="caption" color="textSecondary">
              DOI
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant="caption" color="textSecondary">
              Date of Report (DOR)
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
