import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, List } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { getUserFullName, getUserNameAbbr } from '../../../../../helpers/users';
import { hasRole } from '../../../../../utils/hasRole';
import { fetchUserStorage, updateUserStorage } from '../../../../../store/globalUser/operations';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { Divider } from '../../../../../components/Divider';
import { Avatar } from '../../../../../components/Avatar';
import { ClientSideBarContent } from './ClientSideBarContent';
import { PatientSidebarContent } from './PatientSidebarContent';
import { LogoLink } from './LogoLink';
import { MedicSideBarContent } from './MedicSideBarContent';
import {
  patientMenuList,
  patientsMenuList,
  caseMenuList,
  caseRemindersMenuList,
  casesMenuList,
  filesMenuList,
  membersMenu,
  codesMenu,
  menuList,
  medicalFormsMenu,
  servicesMenu
} from './menuLists';
import { generateRoutesPaths } from './generateRoutesPaths';
import { MenuItems } from './MenuItems';
import { CaseInfo } from './CaseInfo';
import { UserInfo } from './UserInfo';
import { Copyright } from './Copyright';

export const SidebarContent = ({ sidebarIsOpen }) => {
  const dispatch = useDispatch();
  const usersInfo = useSelector(({ globalUser }) => globalUser?.data?.users) || [];
  const casesInfo = useSelector(({ globalUser }) => globalUser?.data?.cases) || [];
  const userGlobalStore = useSelector(({ globalUser }) => globalUser);

  useEffect(() => {
    if (!userGlobalStore.isFetched) {
      dispatch(fetchUserStorage());
    }

    if (hasRole(rolesMap.client, rolesMap.patient)) {
      dispatch(updateUserStorage({
        cases: []
      }));
    }
  }, []);

  if (hasRole(rolesMap.client)) {
    return <ClientSideBarContent sidebarIsOpen={sidebarIsOpen} />;
  }

  if (hasRole(rolesMap.patient)) {
    return <PatientSidebarContent sidebarIsOpen={sidebarIsOpen} />;
  }

  if (hasRole(rolesMap.doctor, rolesMap.medic)) {
    return <MedicSideBarContent isClosableByButton sidebarIsOpen={sidebarIsOpen} />;
  }

  return (
    <>
      <LogoLink isClosableByButton sidebarIsOpen={sidebarIsOpen} />

      <Scrollbars>
        <Box display="flex" flexDirection="column" height="100%">
          <List>
            {hasRole(rolesMap.admin, rolesMap.medic, rolesMap.doctor, rolesMap.supervisor) && (
              <MenuItems isCollapsed={!sidebarIsOpen} routes={medicalFormsMenu} />
            )}

            <MenuItems isCollapsed={!sidebarIsOpen} routes={patientsMenuList} />

            {sidebarIsOpen && (
              <Box px={2} py={1}>
                <UserInfo />
              </Box>
            )}

            {usersInfo?.map((user) => (
              <MenuItems
                isDeleted
                name="users"
                item={user}
                key={user?.id}
                isCollapsed={!sidebarIsOpen}
                routes={[ {
                  name: getUserFullName(user),
                  icon: () => (
                    <Avatar size="md" src={user.avatar} alt={getUserNameAbbr(user)} />
                  ),
                  path: `/patients/${user.id}`,
                  routes: generateRoutesPaths({ list: patientMenuList, params: { patientID: user.id } })
                } ]}
              />
            ))}

            <Divider gutter={2} />
            <MenuItems isCollapsed={!sidebarIsOpen} routes={casesMenuList} />
            <MenuItems isCollapsed={!sidebarIsOpen} routes={caseRemindersMenuList} />

            {sidebarIsOpen && (
              <Box px={2} py={1}>
                <CaseInfo />
              </Box>
            )}

            {casesInfo?.map((caseItem) => (
              <MenuItems
                isDeleted
                name="cases"
                item={caseItem}
                key={caseItem?.id}
                isCollapsed={!sidebarIsOpen}
                routes={[
                  {
                    name: caseItem.name,
                    icon: () => <WorkIcon color="secondary" />,
                    path: `/cases/${caseItem?.id}`,
                    routes: generateRoutesPaths({ list: caseMenuList, params: { caseID: caseItem.id } })
                  }
                ]}
              />
            ))}

            <Divider gutter={2} />

            <MenuItems isCollapsed={!sidebarIsOpen} routes={filesMenuList} />
            <MenuItems isCollapsed={!sidebarIsOpen} routes={menuList} />

            <Box px={2}>
              <Divider gutter={2} />
            </Box>

            <MenuItems isCollapsed={!sidebarIsOpen} routes={codesMenu} />

            <Box px={2}>
              <Divider gutter={2} />
            </Box>

            <MenuItems isCollapsed={!sidebarIsOpen} routes={membersMenu} />

            <Box px={2}>
              <Divider gutter={2} />
            </Box>

            <MenuItems isCollapsed={!sidebarIsOpen} routes={servicesMenu} />
          </List>

          {sidebarIsOpen &&
            <Copyright />
          }
        </Box>
      </Scrollbars>
    </>
  );
};
