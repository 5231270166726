import { makeStyles } from '@material-ui/core';
import { Row } from '../Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const List = ({ data, labels }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {labels.map((item, i) => (
        <Row
          key={i}
          index={i}
          item={item}
          data={data}
        />
      ))}
    </div>
  );
};
