import { useState, createContext } from 'react';
import { initialState } from './initialState';

export const StatisticsFilterContext = createContext();

export const StatisticsFilterProvider = ({ children }) => {
  const [ filter, setFilter ] = useState(initialState.filter);

  const applyFilter = (newFilter) => {
    setFilter((filter) => ({ ...filter, ...newFilter }));
  };

  const providerValue = {
    filter,
    applyFilter
  };

  return (
    <StatisticsFilterContext.Provider value={providerValue}>
      {children}
    </StatisticsFilterContext.Provider>
  );
};
