import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { Employer } from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/LeftSidebar/Contacts/Employer';
import {
  Contact as ContactsInfo
} from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MainInfo/LeftSidebar/Contacts/Contact';
import { AddressLink } from '../../../AddressLink';
import { CompanyLink } from '../../../CompanyLink';
import { Contact } from '../../../Contact';
import { Date } from '../../../Date';
import { Divider } from '../../../Divider';
import { UserSSN } from '../../UserSSN';
import { OfficesContent } from './OfficesContent';

export const CaseUserBody = ({ user }) => {
  return (
    <div>
      <Grid container spacing={1} component={Box} pb={2} justify="space-between" alignItems="flex-start">
        <Grid item xs={12}>
          <Box color="info.main">
            <Typography noWrap color="inherit" variant="button">
              Main info
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            DOB
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {user?.birthday ? moment(user.birthday).format('L') : '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Age
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {user?.birthday ? moment().diff(moment(user.birthday), 'years') + ' y.o.' : '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">
            SSN
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Box mr="auto" maxWidth="60%">
            <UserSSN
              isShowable={hasRole(rolesMap.admin, rolesMap.supervisor)}
              user={user}
              mr={-1.25}
              mt={-0.5}
            />
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">
            Driver’s License
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {user?.dl}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">
            EXP
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Date disableIcon disableMoment date={user?.exp} variant="h5" />
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">
            Health Insurance#
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {user?.medical?.main_insurance?.insuranceId}
          </Typography>
        </Grid>
      </Grid>

      <Divider gutter={2} />

      <Grid container spacing={1} component={Box} pb={2} justify="space-between" alignItems="flex-start">
        <Grid item xs={12}>
          <Box color="info.main">
            <Typography noWrap color="inherit" variant="button">
              Address
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Home Address
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <AddressLink variant="h5" address={user?.home?.full_address}>
            {user?.home?.full_address || '-'}
          </AddressLink>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Phone
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact type="tel" variant="h5" contact={user?.phone_number} />
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">
            Mobile Phone
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            type="tel"
            variant="h5"
            contact={user?.mobile_phone_number}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">
            Fax
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant="h5">
            {user?.fax || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">
            Email
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact noWrap variant="h5" type="mailto" contact={user?.email} />
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary">
            Contact
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {user?.medical?.contacts ? (
            <>
              <Employer
                size="md"
                contacts={{ id: user?.medical?.id, ...user?.medical?.contacts }}
                users={user?.medical?.contacts?.employer || []}
              />

              <ContactsInfo
                size="md"
                contacts={{ id: user?.medical?.id, ...user?.medical?.contacts }}
                users={user?.medical?.contacts?.contact || []}
              />
            </>
          ) : <Typography color="textSecondary">No contacts found</Typography>}
        </Grid>
      </Grid>

      <Divider gutter={2} />

      <Grid container spacing={1} component={Box} pb={2} justify="space-between" alignItems="flex-start">
        <Grid item xs={12}>
          <Box color="info.main">
            <Typography noWrap color="inherit" variant="button">
              Work Info
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap color="textSecondary">
            Company
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {user?.work?.company?.name ?
            <CompanyLink
              variant="h5"
              company={user?.work?.company}
            />
            :
            <Typography color="textSecondary">No info.</Typography>
          }
        </Grid>

        <OfficesContent disableOfficeVenue user={user} />
      </Grid>
    </div>
  );
};
