import axios from 'axios';
import { store } from '../store';
import { logout } from '../store/auth/actions';
import { enqueueSnackbar } from '../store/snackbars';

export const handleSuccessResponse = (response) => {
  const { data } = response;

  if (data.hasOwnProperty('current_page')) {
    data.pagination = {
      page: data.current_page,
      per_page: data.per_page,
      total: data.total,
      last_page: data.last_page
    };
  } else if (data.pagination) {
    data.pagination = {
      ...data.pagination,

      page: data.pagination.current_page
    };
  } else if (data.meta) {
    data.pagination = {
      page: data.meta.current_page,
      per_page: data.meta.per_page,
      total: data.meta.total,
      last_page: data.meta.last_page
    };
  }

  return response;
};

export const handleErrorResponse = (thrown) => {
  if (axios.isCancel(thrown)) {
    return new Promise(() => {});
  } else {
    const { response = { status: 500 } } = thrown;

    switch (response.status) {
      case 403:
        if (response.data?.code) {
          break;
        }

      // eslint-disable-next-line no-fallthrough
      case 401:
        store.dispatch(logout());
        break;

      case 500:
        store.dispatch(enqueueSnackbar('Something went wrong. Try again later...', {
          variant: 'error'
        }));
        break;

      // no default
    }

    return Promise.reject(response);
  }
};
