import { useContext } from 'react';
import { Checkbox, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { CodesContext } from '../../../CodesContext';
import { columnsWidths } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const TableHeader = () => {
  const classes = useStyles();
  const { selectedCodesIDs, allCodesIsSelected, toggleAllCodesSelection } = useContext(CodesContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  return (
    <ListRow header className={classes.root}>
      <Checkbox
        className={classes.checkbox}
        onClick={toggleAllCodesSelection}
        checked={allCodesIsSelected()}
        indeterminate={!!selectedCodesIDs.length && !allCodesIsSelected()}
      />

      <ListRowCell grow={isMobile} flexBasis={columnsWidths.code}>
        {isMobile ? 'Code' : 'Code / Claim filing indicator'}
      </ListRowCell>

      {!isMobile && (
        <>
          <ListRowCell grow flexBasis={columnsWidths.description}>
            Description
          </ListRowCell>

          <ListRowCell grow flexBasis={columnsWidths.indicator}>
            Claim filing indicator
          </ListRowCell>
        </>
      )}

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
