import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dialog } from '@material-ui/core';
import { ImageTransformCropModal } from '../../../../../components/ImageTransformCropModal';
import { Loader } from '../../../../../components/Loader';
import { FileDropZone } from '../../../../../components/FileDropZone';
import { ModalBody, ModalFooter } from '../../../../../components/Modal';
import { useModal } from '../../../../../components/ModalsProvider';
import { enqueueSnackbar } from '../../../../../store/snackbars';

export const UploadCustomerAvatarModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const { openModal } = useModal();
  const maxFileSize = useSelector(({ apiConfig }) => apiConfig.config.files.max_size);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const handleSelectedFilesAccepted = ([ file ]) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      setIsSubmitting(true);

      openModal(ImageTransformCropModal, {
        payload: {
          cropShape: 'round',
          parentPageUser: true,
          src: reader.result,
          fileType: file.type,
          minWidth: 100,
          minHeight: 100
        },
        onModalResolved: (data) => {
          handleModalResolve(data);
        }
      });
    });

    reader.readAsDataURL(file);
  };

  const handleSelectedFilesRejected = ([ file ]) => {
    enqueueSnackbar(`File "${file.name}" is not valid`, { variant: 'error' });
  };

  return (
    <Dialog maxWidth="sm" {...DialogProps}>
      <ModalBody>
        <FileDropZone
          maxSize={maxFileSize}
          accept="image/*"
          onDropAccepted={handleSelectedFilesAccepted}
          onDropRejected={handleSelectedFilesRejected}
        />
      </ModalBody>

      <ModalFooter>
        <Loader
          surface
          loading={isSubmitting}
          render={
            () => (
              <Button
                color="primary"
                onClick={handleModalReject}
              >
                Cancel
              </Button>
            )
          }
        />
      </ModalFooter>
    </Dialog>
  );
};
