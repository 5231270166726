import { Box, Typography } from '@material-ui/core';
import { caseUserRolesTypesMap } from '../../../dataMaps';
import { getUserFullName } from '../../../helpers/users';
import { UserLink } from '../../UserLink';
import { roleLabelsMap } from '../../users';
import { Owner } from '../Owner';
import { ParentAssignments } from '../ParentAssignments';

export const Assignments = ({
  isParent,
  noWrap = false,
  owner,
  space = -0.5,
  users,
  MenuComponent,
  menuProps
}) => {
  return (
    <Box display="inline-flex" flexWrap={noWrap ? 'nowrap' : 'wrap'} pr={1} py={0.5}>
      {owner && (
        <Owner
          role={owner?.case_role && caseUserRolesTypesMap[owner?.case_role]}
          space={space}
          user={owner?.user || owner}
        />
      )}

      {isParent && MenuComponent ? (
        <ParentAssignments
          noWrap={noWrap}
          space={space}
          users={users}
          MenuComponent={MenuComponent}
          menuProps={menuProps}
        />
      ) : users?.map((user, index) => user && (
        <Box ml={space} key={index}>
          <UserLink
            disableName
            user={user?.user || user}
            size={space > 0 ? 'lg' : 'md'}
            tooltipTitle={
              <Box textAlign="center" minWidth={100}>
                <Typography variant="h5" color="inherit">
                  {getUserFullName(user?.user || user)}
                </Typography>

                {user?.case_role ? (
                  <Typography variant="body2">
                    Case Role: {caseUserRolesTypesMap[user?.case_role] || 'No info.'}
                  </Typography>
                ) : (
                  <Typography variant="body2">Role: {roleLabelsMap[user?.role]|| 'No info.'}</Typography>
                )}
              </Box>
            }
          />
        </Box>
      ))}
    </Box>
  );
};
