export const styles = ({ palette, typography: { fontWeightMedium } }) => ({
  root: {
    width: 320,

    '& .elastic-highlight': {
      color: palette.darkgreen,
      fontWeight: fontWeightMedium
    }
  }
});
