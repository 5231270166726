import { useState } from 'react';
import { List, ListItemIcon, ListItemText, makeStyles, MenuItem } from '@material-ui/core';
import { Checkbox } from '../../FormField';
import { styles } from '../WidgetsMenu/styles';

const useStyles = makeStyles(styles);

export const WidgetsMenu = ({ widgetsMap, checkedWidgetsTypes, onToggleItem = () => {} }) => {
  const classes = useStyles();
  const [ checkedWidgets, setCheckedWidgets ] = useState(checkedWidgetsTypes);

  const handleItemSelection = (key) => () => {
    const checked = !checkedWidgets[key];

    setCheckedWidgets((widgets) => ({ ...widgets, [key]: checked }));
    onToggleItem(key, checked);
  };

  return (
    <List className={classes.root}>
      {Object.keys(widgetsMap).map((key) => (
        <MenuItem
          key={key}
          disabled={!checkedWidgetsTypes.hasOwnProperty(key)}
          onClick={handleItemSelection(key)}
        >
          <ListItemIcon>
            <Checkbox
              withoutFormik
              edge="start"
              value={checkedWidgets[key]}
              checked={checkedWidgets[key]}
              onChange={handleItemSelection(key)}
            />
          </ListItemIcon>

          <ListItemText primary={widgetsMap[key].title} />
        </MenuItem>
      ))}
    </List>
  );
};
