import Color from 'color';

export const styles = ({ spacing, palette }) => ({
  root: {
    flexDirection: 'row',
    gap: `${spacing(2)}px`
  },

  notFound: {
    paddingTop: spacing(4)
  },

  column: {
    minWidth: 320
  },

  activeSession: {
    backgroundColor:  Color(palette.primary.main).alpha(0.1).string()
  }
});
