import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, SvgIcon, Dialog } from '@material-ui/core';
import { mdiAccountMultiplePlusOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as casesApi from '../../../../../api/cases';
import { caseUserSingleRoles } from '../../../../../dataMaps';
import { ModalBody, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { Loader } from '../../../../../components/Loader';
import { Body } from './Body';
import { validationSchema } from './validationSchema';

export const MembersModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: {
    role,
    caseItem,
    onCaseItemUpdate
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const assignUserToCase = (values, users, setSubmitting, setErrors) => {
    const user = users?.find((caseUser) => (
      caseUser.case_role === values?.case_role && caseUser?.user?.id === values?.user_id)
    );

    if (user) {
      setSubmitting(false);

      return enqueueSnackbar(
        'This user with this role has already been added to this case',
        { variant: 'error' }
      );
    }

    return casesApi.assignUsersToCase(values).then((data) => {
      onCaseItemUpdate({
        ...caseItem,
        case_users: [ ...users, { ...data.user, ...data?.user?.profile, ...data } ]
      });
      handleModalResolve(data);
      enqueueSnackbar('User successfully added', { variant: 'success' });
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        setSubmitting(false);
      }
    });
  };

  const updateCase = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const caseUserRole = caseItem.case_users.find(({ case_role }) => case_role === values.case_role)?.case_role;
    const role = caseUserRole && caseUserSingleRoles.find(({ value }) => value === caseUserRole);

    if (role) {
      openModal(ConfirmationModal, {
        payload: {
          disableSubmit: true,
          title: `User with this role ${role.label.toLowerCase()} already exists`
        },
        onModalRejected: () => {
          setSubmitting(false);
        }
      });
    } else {
      return assignUserToCase(values, caseItem.case_users, setSubmitting, setErrors);
    }
  };

  return (
    <Dialog {...DialogProps}>
      <Formik
        initialValues={{
          case_id: caseItem?.id,
          user_id: null,
          case_role: role
        }}
        validationSchema={validationSchema}
        onSubmit={updateCase}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiAccountMultiplePlusOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Members
            </ModalHeader>

            <ModalBody>
              <Body />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Ok
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
