import { forwardRef } from 'react';
import cn from 'classnames';
import { makeStyles, Badge as MuiBadge } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Badge = forwardRef(({
  isStatic = false,
  color,
  classes: classesProp = {},
  children,
  ...props
}, ref) => {
  const classes = useStyles({ isStatic, color });

  Object.keys(classesProp).forEach((key) => {
    if (classes[key]) {
      classes[key] = cn(classes[key], classesProp[key]);
    }
  });

  return (
    <MuiBadge ref={ref} classes={classes} {...props}>
      {children}
    </MuiBadge>
  );
});
