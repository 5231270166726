import { useContext } from 'react';
import {
  makeStyles,
  List,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SvgIcon
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Icon } from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { downloadFile } from '../../../../../helpers/files';
import { FragmentsContext } from '../FragmentsContext';

const styles = () => ({
  root: {
    minWidth: 172
  }
});

const useStyles = makeStyles(styles);

export const FragmentsMenu = ({ fragment }) => {
  const classes = useStyles();

  const { deleteFragment, renameFragment } = useContext(FragmentsContext);

  const downloadFragment = () => {
    downloadFile({ src: fragment.url, name: fragment.original_filename });
  };

  const handleDeleteFragment = () => {
    deleteFragment(fragment);
  };

  const handleRenameFragment = () => {
    renameFragment(fragment);
  };

  return (
    <List className={classes.root}>
      <MenuItem>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Open" />
      </MenuItem>

      <MenuItem onClick={downloadFragment}>
        <ListItemIcon>
          <DownloadIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Download" />
      </MenuItem>

      <MenuItem onClick={handleRenameFragment}>
        <ListItemIcon>
          <EditIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Rename" />
      </MenuItem>

      <MenuItem onClick={handleDeleteFragment}>
        <ListItemIcon>
          <SvgIcon fontSize="inherit">
            <Icon path={mdiTrashCanOutline} />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
