import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SortableHandle } from 'react-sortable-hoc';
import { makeStyles } from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import * as tagsApi from '../../../../../../api/files/tags';
import { IconButton } from '../../../../../../components/IconButton';
import { ListRow, ListRowCell, ListRowCellActions } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import { Tag } from '../../../../../../components/Tag';
import { Tooltip } from '../../../../../../components/Tooltip';
import { setTagsLastGlobalAction } from '../../../../../../store/globalActions';
import { TagsContext } from '../../../../files-common';
import * as types from '../../../../files-common/TagsContext/types';
import { columnsWidths } from '../List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const RowDragHandle = SortableHandle(({ tag, className }) => {
  const classes = useStyles();

  return (
    <ListRowCell grow flexBasis={columnsWidths.name} className={className}>
      <div className={classes.dragHandleContent}>
        <DragIndicatorIcon className={classes.dragHandleIcon} />

        <Tag disableTooltip tag={tag} />
      </div>
    </ListRowCell>
  );
});

const actionsMap = {
  delete: 'delete',
  toggleActive: 'toggleActive'
};

export const Row = ({
  item: tag = {},
  isLoaded,
  recalculateHeight
}) => {
  const reduxDispatch = useDispatch();
  const [ activeAction, setActiveAction ] = useState(null);
  const classes = useStyles();
  const { tags, deleteTag, updateTag } = useContext(TagsContext);

  const handleTagDelete = () => {
    setActiveAction(actionsMap.delete);

    deleteTag(tag).then(() => {
      setActiveAction(null);
    }).catch(() => {
      setActiveAction(null);
    });
  };

  const handleTagUpdate = () => {
    updateTag(tag);
  };

  const toggleTagActive = () => {
    setActiveAction(actionsMap.toggleActive);

    tagsApi.updateTag({ ...tag, is_active: !tag.is_active }).then(({ data }) => {
      reduxDispatch(setTagsLastGlobalAction({ type: types.UPDATE_TAG, payload: data }));
    }).finally(() => {
      setActiveAction(null);
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded, tags.length ]);

  return (
    <ListRow button className={classes.root}>
      <RowDragHandle
        tag={tag}
        className={classes.dragHandle}
      />

      {tag.id !== 1 &&
        <ListRowCell flexBasis={columnsWidths.actions}>
          <ListRowCellActions>
            <Loader surface loading={activeAction === actionsMap.toggleActive} render={
              () => (
                <Tooltip title={tag.is_active ? 'Deactivate tag' : 'Activate tag'}>
                  <IconButton color={tag.is_active ? 'success' : 'warning'} onClick={toggleTagActive}>
                    {tag.is_active ? <LockOpenIcon /> : <LockIcon />}
                  </IconButton>
                </Tooltip>
              )}
            />

            <Tooltip title="Edit tag">
              <IconButton color="info" onClick={handleTagUpdate}>
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Loader surface loading={activeAction === actionsMap.delete} render={
              () => (
                <Tooltip title="Delete tag">
                  <IconButton edge="end" color="error" onClick={handleTagDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            />
          </ListRowCellActions>
        </ListRowCell>
      }
    </ListRow>
  );
};
