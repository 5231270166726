import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as appointmentBooksApi from '../../../../../../api/codes/appointments/appointment-books';
import { Loader } from '../../../../../../components/Loader';
import {
  Page,
  PageBody,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../../../components/Page';
import { initialValues } from '../initialValues';
import { validationSchema } from '../validationSchema';
import { ApptBookForm } from '../ApptBookForm';

export const Create = ({ baseUrl }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const createAppointmentBook = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return appointmentBooksApi.createAppointmentBook(values).then(() => {
      enqueueSnackbar(
        'Appointment book successfully created',
        { variant: 'success' }
      );

      history.push(`${baseUrl}/appointment-books`);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validateOnTouched={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createAppointmentBook}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Page noValidate component="form" onSubmit={handleSubmit}>
          <PageHeader>
            <IconButton
              component={Link}
              to={`${baseUrl}/appointment-books`}
              color="primary"
              edge="start"
            >
              <KeyboardBackspaceOutlinedIcon />
            </IconButton>

            <PageHeaderTitle>
              {isMobile ? 'Create appt. book' : 'Create appointment book'}
            </PageHeaderTitle>

            <PageHeaderActions>
              <Button
                size={isMobile ? 'small' : 'medium'}
                component={Link}
                to={`${baseUrl}/appointment-books`}
                color="primary"
              >
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    size={isMobile ? 'small' : 'medium'}
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Create
                  </Button>
                )}
              />
            </PageHeaderActions>
          </PageHeader>

          <PageBody>
            <ApptBookForm />
          </PageBody>
        </Page>
      )}
    </Formik>
  );
};
