import { api } from '../api';

export const fetchCaseDiscoveries = (caseID, config = {}) => {
  return api.get(`/cases/${caseID}/discovery`, config)
    .then(({ data }) => ({ data }))
    .catch((error) => { throw error; });
};

export const createCaseDiscovery = (caseID, data) => {
  return api.post(`/cases/${caseID}/discovery`, data).then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateCaseDiscovery = (caseID, { id, ...data }) => {
  return api.put(`/cases/${caseID}/discovery/${id}`, data).then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const deleteCaseDiscovery = (caseID, discoveryID) => {
  return api.delete(`/cases/${caseID}/discovery/${discoveryID}`).then(({ data }) => data)
    .catch((error) => { throw error; });
};
