import { createContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { usePrevious } from '../../../helpers/hooks';
import * as timeApi from '../../../api/time-tracks';
import { api } from '../../../api';
import { rolesMap } from '../../../dataMaps/rolesMap';
import { hasRole } from '../../../utils/hasRole';
import { ConfirmationModal } from '../../ConfirmationModal';
import { useModal } from '../../ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const TimeTrackingContext = createContext();

export const TimeTrackingProvider = ({ children, filter: filterProp }) => {
  const [ state, dispatch ] = useReducer(reducer, {
    ...initialState,
    filter: merge({}, initialState.filter, filterProp)
  });
  const { filter, pagination } = state;
  const tracksLastGlobalAction = useSelector(({ globalActions }) => globalActions.tracksLastGlobalAction);
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const isAdmin = hasRole(rolesMap.admin, rolesMap.supervisor);

  const fetchTimeTracking = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_TRACKS_REQUEST });

    timeApi.fetchTracks({
      params: {
        ...filter,
        ...newFilter
      },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_TRACKS_SUCCESS, payload: data });
    }).catch(({ status }) => {
      if (status === 422) {
        dispatch({ type: types.FETCH_TRACKS_ERROR });
      }
    });
  };

  const fetchAdminTimeTracking = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_TRACKS_REQUEST });

    timeApi.fetchAdminTracks({
      params: {
        ...filter,
        ...newFilter
      },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_TRACKS_SUCCESS, payload: data });
    }).catch(({ status }) => {
      if (status === 422) {
        dispatch({ type: types.FETCH_TRACKS_ERROR });
      }
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      if (isAdmin) {
        fetchAdminTimeTracking({ page: filter.page + 1 });
      } else {
        fetchTimeTracking({ page: filter.page + 1 });
      }
    }
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetTimeTracking = (newFilter) => {
    dispatch({ type: types.RESET_TRACKING, payload: newFilter });

    if (isAdmin) {
      fetchAdminTimeTracking({ ...newFilter, page: 1 });
    } else {
      fetchTimeTracking({ ...newFilter, page: 1 });
    }
  };

  const deleteTrack = (id, callback) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        timeApi.deleteTrack(id).then(() => {
          dispatch({ type: types.DELETE_TRACK, payload: id });
          callback();
          enqueueSnackbar('Track successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Track not deleted', { variant: 'error' });
        });
      }
    });
  };

  const providerValue = {
    ...state,

    meta: {
      ...pagination,
      ...filter
    },

    // functions
    applyFilter,
    loadNextPage,
    deleteTrack,
    resetTimeTracking
  };

  useEffect(() => {
    if (tracksLastGlobalAction) {
      resetTimeTracking();
    }
  }, [ tracksLastGlobalAction ]);

  useEffect(() => {
    const newFilter = omit({ ...filter, ...filterProp }, [ 'page' ]);

    if (!isEqual(omit(filter, [ 'page' ]), newFilter)) {
      applyFilter(newFilter);
    }
  }, [ filter, filterProp ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetTimeTracking(filter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <TimeTrackingContext.Provider value={providerValue}>
      {children}
    </TimeTrackingContext.Provider>
  );
};
