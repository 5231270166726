import { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FormContext } from '../../../app/Dashboard/GuestMedicalProfile/FormProvider';
import { MedicalFormItemContext } from '../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalFormItemProvider';
import { Loader } from '../../Loader';

export const ActionButtons = ({ buttonTilte = 'Back', onNext, onBack }) => {
  const { isSubmitting, dirty, handleSubmit } = useFormikContext();
  const formContext = useContext(FormContext);
  const medicalFormItemContext = useContext(MedicalFormItemContext);
  const context = formContext || medicalFormItemContext;

  useEffect(() => {
    context?.setIsDirty(dirty);
  }, [ dirty ]);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" pb={2}>
      <Box pr={2} flexGrow={1}>
        <Alert severity="error">
          <Typography>
            All fields marked with an asterisk are required
          </Typography>
        </Alert>
      </Box>

      <Box clone mr={1}>
        <Button
          color="primary"
          onClick={onBack(dirty)}
        >
          {buttonTilte}
        </Button>
      </Box>

      {onNext ? (
        <Button
          variant="contained"
          color="primary"
          onClick={onNext}
        >
          Next
        </Button>
      ) : (
        <Loader surface loading={isSubmitting} render={
          () => (
            <Button
              disabled={isSubmitting}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Next
            </Button>
          )}
        />
      )}
    </Box>
  );
};
