import { useSelector } from 'react-redux';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useMessageContent } from '../../../../../helpers/hooks';
import { getThreadName, getMessageAuthorName } from '../../../utils';
import { Loader } from '../../../../Loader';
import { ThreadAvatar } from '../../../ThreadAvatar';
import { MessageContent } from '../../../MessageContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({
  isLoaded,
  item: thread = {},
  selectedThreadsIDs,
  onToggleThreadIDSelection
}) => {
  const classes = useStyles();
  const currentUser = useSelector(({ profile }) => profile.user);
  const messageContent = useMessageContent(thread?.last_message);
  const lastMessage = !thread?.last_message
    ? null
    : thread?.last_message?.type === 'system'
      ? messageContent
      : `${getMessageAuthorName(thread?.last_message)}: ${messageContent}`;

  const toggleThreadSelection = () => {
    onToggleThreadIDSelection(thread.id);
  };

  return !isLoaded ? <Loader p={1} /> : (
    <ListItem className={classes.listItem}>
      <ThreadAvatar thread={thread} />

      <ListItemText
        className={classes.listItemText}
        primary={
          <Typography noWrap variant="h5">
            {getThreadName({ thread, currentUser })}
          </Typography>
        }
        secondary={lastMessage && (
          <Typography noWrap variant="body1" component="div">
            <MessageContent message={lastMessage} />
          </Typography>
        )}
      />

      <ListItemSecondaryAction className={classes.secondaryAction}>
        <Loader loading={!isLoaded} p={1} render={
          () => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedThreadsIDs.includes(thread.id)}
                  value="secondary"
                  color="primary"
                  onChange={toggleThreadSelection}
                />
              }
              className={classes.controlLabel}
            />
          )}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
