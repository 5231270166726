import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import { ThreadsContextProvider } from '../../ThreadsContext';
import { ChatsNavigation } from './ChatsNavigation';
import { ThreadsList } from './ThreadsList';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Chat = ({ isMinimized, onWidgetClose, toggleMinimized }) => {
  const classes = useStyles({ isMinimized });

  return (
    <div className={cn(classes.sidebar, isMinimized && classes.minimized)}>
      <ThreadsContextProvider>
        <ChatsNavigation
          isMinimized={isMinimized}
          toggleMinimized={toggleMinimized}
          onWidgetClose={onWidgetClose}
        />

        <ThreadsList isMinimized={isMinimized} />
      </ThreadsContextProvider>
    </div>
  );
};
