import { useContext, useEffect } from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useMobileFilterCollapse } from '../../../../../../utils/useMobileFilterCollapse';
import { Page, PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Fab } from '../../../../../../components/Fab';
import { PosCodeFormModal } from '../PosCodeFormModal';
import { HeaderActions } from '../../HeaderActions';
import { CodesContext } from '../CodesContext';
import { FiltersBar } from './FiltersBar';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainContent = ({ filterKey, hiddenFilterFields = [] }) => {
  const classes = useStyles();
  const {
    codes,
    selectedCodesIDs,
    filter,
    pagination,
    fetchCodes,
    resetCodes,
    massDeleteCodes,
    massActiveCodes,
    massInactiveCodes
  } = useContext(CodesContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { openModal } = useModal();
  const {
    filterOpenButton,
    FilterBarWrapper,
    filterBarWrapperProps,
    ScrollWrapper,
    scrollWrapperProps
  } = useMobileFilterCollapse();

  const toggleMassActivatedItems = () => {
    massActiveCodes(selectedCodesIDs);
  };

  const toggleMassInactivatedItems = () => {
    massInactiveCodes(selectedCodesIDs);
  };

  const handleMassDelete = () => {
    massDeleteCodes(selectedCodesIDs);
  };

  const openCodeCreationModal = () => {
    openModal(PosCodeFormModal, {
      payload: {
        codesContext: {
          fetchCodes,
          codes,
          filter
        }
      }
    });
  };

  useEffect(() => {
    resetCodes();
  }, []);

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle>
          POS codes
        </PageHeaderTitle>
      </PageHeader>

      <PageHeader>
        <PageHeaderActions startPosition>
          <Fab
            size={isMobile ? 'small' : 'medium'}
            color="primary"
            variant="extended"
            startIcon={<AddIcon />}
            onClick={openCodeCreationModal}
          >
            Add code
          </Fab>
        </PageHeaderActions>

        <HeaderActions
          filterOpenButton={filterOpenButton}
          selectedCodesIDs={selectedCodesIDs}
          pagination={pagination}
          toggleMassInactivatedItems={toggleMassInactivatedItems}
          toggleMassActivatedItems={toggleMassActivatedItems}
          onMassDelete={handleMassDelete}
        />
      </PageHeader>

      <Page variant={isMobile ? 'elevation' : 'outlined'} square className={classes.mainContent}>
        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <FiltersBar filterKey={filterKey} hiddenFields={hiddenFilterFields} />
          </FilterBarWrapper>
        </ScrollWrapper>

        <List/>
      </Page>
    </Page>
  );
};
