import { useSnackbar } from 'notistack';
import { Dialog, Tooltip, Box, Typography, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import * as testsApi from '../../../../../api/profile';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Loader } from '../../../../../components/Loader';
import { Date } from '../../../../../components/Date';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderActions
} from '../../../../../components/Modal';
import {
  ReportsPreviewContext,
  ReportsPreviewProvider
} from '../../ReportsPreviewProvider';
import { EditTestModal } from '../EditTestModal';
import { MainInfo } from './MainInfo';

export const TestPreviewModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { testId, onTestUpdate, onListUpdate }
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleDeleteTest = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        testsApi.deleteTestReport(testId).then(() => {
          onListUpdate();
          enqueueSnackbar('Item successfully deleted', { variant: 'success' });
          handleModalResolve();
        });
      }
    });
  };

  const handleUpdateTest = (initialValues, setInitialValues) => () => {
    openModal(EditTestModal, {
      payload: { id: testId },
      onModalResolved: (data) => {
        setInitialValues({ ...initialValues, ...data });
        onTestUpdate(data);
        onListUpdate();
      }
    });
  };

  const fetchTestReport = () => {
    return testsApi.fetchTestReport(testId);
  };

  return (
    <ReportsPreviewProvider fetchItem={fetchTestReport}>
      <ReportsPreviewContext.Consumer>
        {({ isFetched, selectedItem, setSelectedItem }) => (
          <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
            <ModalContainer>
              <ModalHeader onClose={handleModalReject}>
                View Test: {selectedItem?.test?.name || ''}

                <ModalHeaderActions disableGutters>
                  {!hasRole(rolesMap.client, rolesMap.patient) &&
                    <>
                      <Tooltip arrow title="Edit test">
                        <IconButton
                          color="primary"
                          onClick={handleUpdateTest(selectedItem, setSelectedItem)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip arrow title="Delete test">
                        <IconButton
                          color="error"
                          onClick={handleDeleteTest}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                </ModalHeaderActions>
              </ModalHeader>

              <Loader p={3} loading={!isFetched} render={
                () => (
                  <ModalBody>
                    <MainInfo />

                    <Grid container spacing={2} alignItems="center" component={Box} pb={3}>
                      <Grid item xs={12}>
                        <Typography variant="h4">General Information</Typography>
                      </Grid>

                      <Grid item sm={2} xs={6}>
                        <Typography color="textSecondary">Collected</Typography>
                      </Grid>

                      <Grid item sm={10} xs={6}>
                        <Date
                          date={selectedItem.date_collected}
                          format="L, LT"
                          iconFontSize="small"
                          variant="h5"
                        />
                      </Grid>

                      <Grid item sm={2} xs={6}>
                        <Typography color="textSecondary">Resulted</Typography>
                      </Grid>

                      <Grid item sm={10} xs={6}>
                        <Date
                          date={selectedItem.date_resulted}
                          format="L, LT"
                          iconFontSize="small"
                          variant="h5"
                        />
                      </Grid>
                    </Grid>
                  </ModalBody>
                )}
              />
            </ModalContainer>
          </Dialog>
        )}
      </ReportsPreviewContext.Consumer>
    </ReportsPreviewProvider>
  );
};
