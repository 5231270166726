import { Switch } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { LayoutContextProvider } from '../../../components/LayoutContext';
import { Route } from '../../../components/router';
import { Page } from '../../../components/Page';
import { MedicalFormItemProvider } from '../ProfilePage/MedicalInfo/MedicalFormItemProvider';
import { orderByMap } from '../ProfilePage/MedicalInfo/MedicalForms/FormFiltersBar/orderByOptions';
import { FormItemComponent } from '../ProfilePage/MedicalInfo/MedicalForms/FormItemComponent';
import { MainContent } from './MainContent';

export const MedicalFormsContent = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const initialState = {
    filter: {
      medical_id: null,
      order_by: orderByMap.updated_at
    },
    medicalInfoPathname: ''
  };

  return (
    <LayoutContextProvider>
      <Box width="100%" height="100%" bgcolor="grey.100" p={isTablet ? 1 : 2} pb={0}>
        <Page>
          <Switch>
            <Route
              exact
              path="/medical-forms"
              component={MainContent}
            />

            <Route
              strict
              path="/medical-forms/"
              render={() => (
                <MedicalFormItemProvider initialState={initialState}>
                  <FormItemComponent />
                </MedicalFormItemProvider>
              )}
            />
          </Switch>
        </Page>
      </Box>
    </LayoutContextProvider>
  );
};
