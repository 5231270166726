import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles, Paper, Typography, IconButton, Collapse } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { downloadFile } from '../../../../helpers/files';
import { usePrevious } from '../../../../helpers/hooks';
import { jobsTypes } from '../../../../store/socketJobs';
import { downloadArchive } from '../../../../api/files';
import { Echo } from '../../../../utils/echo';
import { AppBar } from '../../../../components/AppBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ArchiveDownloadWidget = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const currentUser = useSelector(({ profile: { user } }) => user);
  const trackedJobs = useSelector(({ socketJobs }) => socketJobs[jobsTypes.archives]);
  const [ isCollapsed, setIsCollapsed ] = useState(false);
  const [ isOpenWidget, setIsOpenWidget ] = useState(false);
  const prevTrackedJobsCount = usePrevious(trackedJobs.length);

  const toggleCollapse = () => {
    setIsCollapsed((isCollapsed) => !isCollapsed);
  };

  const closeWidget = () => {
    setIsOpenWidget(false);
  };

  useEffect(() => {
    if (trackedJobs.length > prevTrackedJobsCount) {
      setIsOpenWidget(true);
      setIsCollapsed(false);
    }
  }, [ trackedJobs ]);

  useEffect(() => {
    const jobsChanel = Echo.private(`jobs.${currentUser.id}`);

    const handleJobFinished = ({ job_id, archive_id, name }) => {
      if (trackedJobs.find((id) => id === job_id)) {
        downloadArchive(archive_id, {
          responseType: 'arraybuffer'
        }).then(({ data }) => {
          setIsOpenWidget(false);

          downloadFile({
            src: new Blob([ data ], { type: 'application/octet-stream' }),
            name
          });
        });
      }
    };

    const handleJobFailed = ({ error: { status } = {}, job_id }) => {
      if (trackedJobs.find((id) => id === job_id)) {
        if (status === 404) {
          enqueueSnackbar('No files for archiving', {
            variant: 'error'
          });
        }
      }
    };

    jobsChanel
      .listen('.finished', handleJobFinished)
      .listen('.failed', handleJobFailed);

    return () => {
      jobsChanel
        .stopListening('.finished', handleJobFinished)
        .stopListening('.failed', handleJobFailed);
    };
  }, [ trackedJobs ]);

  return (
    <Collapse in={isOpenWidget}>
      <Paper className={classes.root}>
        <AppBar
          color="default"
          ToolbarProps={{ disableGutters: true, className: classes.header }}
        >
          <Typography className={classes.header__title}>
            Preparing download
            {/*Download canceled*/}
            {/*Download ready*/}
          </Typography>

          <IconButton onClick={toggleCollapse} className={classes.header__button}>
            {isCollapsed ? <ExpandLessIcon /> :  <ExpandMoreIcon />}
          </IconButton>

          <IconButton onClick={closeWidget} className={classes.header__button}>
            <CloseIcon />
          </IconButton>
        </AppBar>

        <Collapse
          in={!isCollapsed}
          classes={{
            container: classes.bodyWrapper,
            wrapperInner: classes.body
          }}
        >
          Zipping files...
        </Collapse>
      </Paper>
    </Collapse>
  );
};
