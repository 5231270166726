import { forwardRef } from 'react';
import T from 'prop-types';
import { useFormikField } from '../useFormikField';

const propTypes = {
  Component: T.elementType.isRequired,
  withoutFormik: T.bool,
  error: T.bool,
  helperText: T.string
};

export const SwitchBase = forwardRef(({
  Component,
  withoutFormik,
  name,
  checked = false,
  onChange,
  ...props
}, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField({ name, type: 'checkbox' });
  const { isFormikField, fieldProps } = formikFieldProps;
  const [ field = {}, , { setValue } = {} ] = fieldProps || [];

  const handleChange = (event) => {
    const { target: { checked, value } } = event;

    onChange && onChange(event);
    isFormikField && setValue(checked && (value || checked));
  };

  return (
    <Component
      ref={ref}
      name={name}
      checked={field.checked || checked}
      onChange={handleChange}

      {...props}
    />
  );
});

SwitchBase.propTypes = propTypes;
