import { Yup } from '../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  payer_id: Yup.mixed().nullable().when('is_all_payers', {
    is: (value) => !value,
    then: Yup.mixed().nullable().required(),
    otherwise: Yup.string()
  }),
  modifier_id: Yup.mixed().nullable().required(),
  default_units: Yup.number().nullable().required(),
  charge_amount: Yup.number().nullable().min(1).max(99999).required(),
  expected_amount: Yup.number().nullable().min(1).max(99999).required(),
  work_rvu: Yup.string().nullable().required().max(255),
  moldx_code: Yup.string().nullable().required().max(255),
  dx_code: Yup.string().nullable().required().max(255),
  total_rvu: Yup.string().nullable().required().max(255)
});
