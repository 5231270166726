import { useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Grid, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { Fab } from '../../../../../../components/Fab';
import {
  TextField,
  CPTCodesSelect,
  IcdCodesSelect,
  ModifiersSelect,
  PosCodesSelect,
  Autocomplete,
  CurrencyField,
  transformModifiersCodesToOption
} from '../../../../../../components/FormField';
import { LevelsSelect } from '../../../../../../components/FormField/selects/LevelsSelect';
import { IconButton } from '../../../../../../components/IconButton';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Attachments } from '../../../ClinicalNotes/Attachments';
import { initialValues, sidesOptions } from '../../../../../../components/appointments/procedures';

export const Procedures = ({ setAttachments = () => {} }) => {
  const { values, setFieldValue } = useFormikContext();
  const { openModal } = useModal();

  const handleCptChange = (i) => (cpt) => {
    if (cpt) {
      setFieldValue(
        `procedures[${i}].modifiers`,
        cpt?.fee_schedule?.modifier
          ? [ transformModifiersCodesToOption(cpt?.fee_schedule?.modifier) ]
          : null
      );
      setFieldValue(`procedures[${i}].price`, cpt?.fee_schedule?.charge_amount || 0);
      setFieldValue(`procedures[${i}].unit`, cpt?.fee_schedule?.default_units || 1);
      setFieldValue(`procedures[${i}].cpt_description`, cpt?.description);
    }
  };

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers(index);
      }
    });
  };

  useEffect(() => {
    setAttachments(values?.procedures?.map(({ files }) => files));
  }, [ values.procedures ]);

  return (
    <Box my={6}>
      <FieldArray
        name="procedures"
        render={({ remove, push }) => (
          <>
            <Box mb={2} color="info.main" display="flex" alignItems="center">
              <Typography variant="h3" color="inherit">
                Select Surgeries/Procedures
              </Typography>

              <Box component="span" ml={2}>
                <Fab
                  size="small"
                  color="primary"
                  variant="extended"
                  startIcon={<AddIcon/>}
                  onClick={() => push(initialValues)}
                >
                  Add
                </Fab>
              </Box>
            </Box>

            {values?.procedures?.map((procedure, i) => (
              <Box display="flex" flexWrap="nowrap" mb={3} key={i}>
                <Box mt={3.5} mr={0.5} alignSelf="flex-start">
                  <Typography component="span">{i + 1}.</Typography>
                </Box>

                <Box flex={1}>
                  <Grid container spacing={2}>
                    <Grid item sm={3} xs={12}>
                      <Autocomplete
                        disableClearable
                        name={`procedures.${i}.side`}
                        label="Side"
                        options={sidesOptions}
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={9} xs={12}>
                      <LevelsSelect
                        isMulti
                        name={`procedures.${i}.levels`}
                        TextFieldProps={{ margin: 'dense', required: true }}
                      />
                    </Grid>

                    <Grid item sm={3} xs={12}>
                      <CPTCodesSelect
                        required
                        name={`procedures.${i}.cpt_id`}
                        label="Procedure Code #"
                        margin="dense"
                        params={{ schedule_payer_id: values?.insurance_details?.company?.id }}
                        onChange={handleCptChange(i)}
                      />
                    </Grid>

                    <Grid item sm={9} xs={12}>
                      {values.procedures[i].cpt_id &&
                        <Box mt={1}>
                          <Typography variant="body2" color="textSecondary">
                            Procedure description
                          </Typography>
                        </Box>
                      }

                      <Typography style={{ textTransform: 'uppercase' }}>
                        {values.procedures[i]?.cpt_description}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows={2}
                        rowsMax={8}
                        disableUnderline
                        variant="filled"
                        name={`procedures.${i}.surgery`}
                        label="Surgery"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={3} xs={12}>
                      <PosCodesSelect
                        required
                        name={`procedures.${i}.pos_id`}
                        label="POS"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={9} xs={12}>
                      <IcdCodesSelect
                        multiple
                        name={`procedures.${i}.icd`}
                        label="DX Sequence/Diagnosis"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <ModifiersSelect
                        isMulti
                        formattedValue
                        name={`procedures.${i}.modifiers`}
                        label="Modifiers"
                        TextFieldProps={{ margin: 'dense' }}
                      />
                    </Grid>

                    <Grid item sm={3} xs={12}>
                      <TextField
                        required
                        type="number"
                        name={`procedures.${i}.unit`}
                        label="Unit"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={3} xs={12}>
                      <CurrencyField
                        required
                        name={`procedures.${i}.price`}
                        label="Price"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Attachments name={`procedures.${i}.files`} />
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={3} alignSelf="flex-start">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={handleClearField(remove, i)}
                  >
                    <ClearIcon fontSize="small"/>
                  </IconButton>
                </Box>
              </Box>
            ))}
          </>
        )}
      />
    </Box>
  );
};
