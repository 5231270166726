import { useContext } from 'react';
import moment from 'moment';
import { makeStyles, Box, Typography, SvgIcon, useTheme } from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventNoteIcon from '@material-ui/icons/EventNote';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { mdiAlarm } from '@mdi/js';
import { Icon } from '@mdi/react';
import { apiModelsMap } from '../../../dataMaps/apiModels';
import { ScheduleContext, ViewScheduleEventModal } from '../../schedule-events';
import { CalendarContext } from '../../schedule-events/Schedule/CalendarProvider';
import { Tooltip } from '../../Tooltip';
import { UserAvatar, UserPreviewModal } from '../../users';
import { VerticalDivider } from '../../VerticalDivider';
import { ListRow, ListRowCell } from '../../ListRow';
import { useModal } from '../../ModalsProvider';
import { Date } from '../../Date';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const columnsWidths = {
  content: 200,
  date: 90,
  icon: 36
};

const parentType = {
  [apiModelsMap.case]: WorkIcon,
  [apiModelsMap.task]: PlaylistAddCheckIcon,
  [apiModelsMap.appointment]: EventNoteIcon
};

export const Row = ({ event, width }) => {
  const { updateEvent, deleteEvent } = useContext(ScheduleContext);
  const { fetchCalendarEvents } = useContext(CalendarContext);
  const classes = useStyles();
  const ParentIcon = parentType?.[event.parent_type];
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobileXS = width <= theme.breakpoints.values.mobileSm;

  const openUserPreview = (user) => () => {
    if (user?.id) {
      openModal(UserPreviewModal, {
        payload: { id: user?.id }
      });
    }
  };

  const handleDelete = (event) => {
    deleteEvent(event.id);
    fetchCalendarEvents();
  };

  const handleEventClick = () => {
    openModal(ViewScheduleEventModal, {
      payload: {
        event,
        onUpdate: updateEvent,
        onDelete: handleDelete
      }
    });
  };

  return (
    <ListRow
      button
      className={classes.root}
      onClick={handleEventClick}
    >
      <ListRowCell flexBasis={columnsWidths.icon}>
        <SvgIcon><Icon path={mdiAlarm} /></SvgIcon>
      </ListRowCell>

      <ListRowCell column flexBasis={columnsWidths.date}>
        <Date disableIcon date={event.started_at} format="LT" />

        <Box mt={0.5} color="info.main">
          <Typography color="inherit" variant="h5">
            {moment.duration(event.finished_at - event.started_at, 'seconds').humanize()}
          </Typography>
        </Box>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.content}>
        <Box display="flex" alignItems="flex-start" flexWrap="noWrap" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="flex-start" flexDirection="column">
            <Typography noWrap variant="h5">
              {event.title}
            </Typography>

            <Box display="flex" alignItems="center" mt={1} flexWrap="nowrap">
              {!!event.parent_type && (
                <>
                  <ParentIcon />
                  <VerticalDivider horizontalGutters={0.75} verticalGutters={0.5} />
                </>
              )}

              <LocationOnIcon />
              <VerticalDivider horizontalGutters={0.75} verticalGutters={0.5} />

              {event?.users?.map((user, index) => (
                <Box key={user.id} clone ml={index !== 0 ? -0.5 : 0}>
                  <UserAvatar size="xs" user={user} onClick={openUserPreview(user)} />
                </Box>
              ))}
            </Box>
          </Box>

          {!isMobileXS && (
            <Box pl={1} flexGrow={1} maxWidth={400} overflow="hidden">
              <Tooltip
                isExistTooltip={!!event.description?.length}
                title={<div dangerouslySetInnerHTML={{ __html: event?.description || '-' }} />}
              >
                <Typography noWrap dangerouslySetInnerHTML={{ __html: event?.description || '-' }} />
              </Tooltip>
            </Box>
          )}
        </Box>
      </ListRowCell>
    </ListRow>
  );
};
