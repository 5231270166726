import { createContext, useEffect, useReducer, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../../../../api';
import * as templatesApi from '../../../../../../api/profile';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { usePrevious } from '../../../../../../helpers/hooks';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const TemplatesContext = createContext();

export const TemplatesProvider = ({ children, initialState: initialStateProp = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const { templates, selectedIDs, pagination, filter } = state;
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchTemplates = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_REQUEST });

    templatesApi.fetchTemplates({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const updateReport = (template) => {
    dispatch({ type: types.UPDATE_TEMPLATE_IN_LIST, payload: template });
  };

  const resetTemplates = (newFilter = {}) => {
    dispatch({ type: types.RESET_TEMPLATES, payload: newFilter });

    fetchTemplates({ page: 1, ...newFilter });
  };

  const allItemsIsSelected = () => {
    return templates.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_ITEM_SELECTION, payload: id });
  };

  const toggleAllItemsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_ITEMS_SELECTION });
  };

  const deleteTemplate = (id) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        templatesApi.deleteTemplate(id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchTemplates({ page: filter.page });
          } else if (templates.length - 1 === 0 && filter.page !== 1) {
            fetchTemplates({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_TEMPLATE_SUCCESS, payload: id });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Template is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const massDeleteItems = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        templatesApi.massDeleteTemplates(IDs).then(() => {
          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            fetchTemplates({ page: filter.page });
          } else if (templates.length - selectedIDs.length === 0 && filter.page !== 1) {
            fetchTemplates({ page: filter.page - 1 });
          }

          dispatch({ type: types.MASS_DELETE_TEMPLATES, payload: IDs });
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Items are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const providerValue = {
    ...state,
    pagination,
    selectedIDs,
    templates,
    filter,
    meta: {
      ...pagination,
      ...filter
    },

    // functions
    fetchTemplates,
    updateReport,
    applyFilter,
    resetTemplates,
    allItemsIsSelected,
    toggleItemSelection,
    toggleAllItemsSelection,
    deleteTemplate,
    massDeleteItems
  };

  useEffect(() => {
    const newFilter = { ...filter, ...initialStateProp.filter };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, initialStateProp.filter ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetTemplates();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <TemplatesContext.Provider value={providerValue}>
      {children}
    </TemplatesContext.Provider>
  );
};
