export const styles = ({ spacing, palette }) => ({
  sliderRoot: {
    cursor: 'default'
  },

  height: {
    height: ({ height }) => `${height}px !important`
  },

  width: {
    height: ({ width }) => `${width}px !important`
  },

  track: {
    color: 'transparent',
    backgroundImage: ({ colorIndex, levelColors }) => (
      `linear-gradient(to right, ${levelColors.slice(0, colorIndex + 1)})`
    )
  },

  rail: {
    opacity: 1,
    color: palette.grey[200]
  },

  markLabel: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: spacing()
  },

  thumb: {
    transform: 'translate(0%, 22%)'
  }
});
