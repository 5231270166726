import { Yup } from '../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    review_of_symptoms: Yup.object().shape({
      constitutional: Yup.object().shape({
        fever: Yup.boolean().nullable(),
        weight_loss: Yup.boolean().nullable(),
        fatigue: Yup.boolean().nullable()
      }),
      respiratory: Yup.object().shape({
        indigestion: Yup.boolean().nullable(),
        abdominal_pain: Yup.boolean().nullable(),
        nausea: Yup.boolean().nullable(),
        vomiting: Yup.boolean().nullable(),
        regurgitation: Yup.boolean().nullable(),
        diarrhea: Yup.boolean().nullable(),
        bloody_stools: Yup.boolean().nullable()
      }),
      neurological: Yup.object().shape({
        headache: Yup.boolean().nullable(),
        face_pain: Yup.boolean().nullable(),
        face_numbness: Yup.boolean().nullable(),
        weakness: Yup.boolean().nullable(),
        tremors: Yup.boolean().nullable(),
        blackouts: Yup.boolean().nullable(),
        seizures: Yup.boolean().nullable(),
        troubles_with_memory: Yup.boolean().nullable()
      }),
      endocrine: Yup.object().shape({
        excessive_rash: Yup.boolean().nullable(),
        excessive_urination: Yup.boolean().nullable(),
        heat_or_cold_intolerance: Yup.boolean().nullable()
      }),
      eye_problems: Yup.object().shape({
        blurred_vision: Yup.boolean().nullable(),
        double_vision: Yup.boolean().nullable(),
        loss_of_vision: Yup.boolean().nullable(),
        eye_pain: Yup.boolean().nullable(),
        eye_redness: Yup.boolean().nullable(),
        eye_dryness: Yup.boolean().nullable()
      }),
      genitourinary: Yup.object().shape({
        incontinence: Yup.boolean().nullable(),
        pain_on_urination: Yup.boolean().nullable(),
        blood_in_urine: Yup.boolean().nullable()
      }),
      psychiatric: Yup.object().shape({
        hallucinations: Yup.boolean().nullable(),
        feeling_depressed: Yup.boolean().nullable(),
        trouble_sleeping: Yup.boolean().nullable(),
        suicidal_thoughts: Yup.boolean().nullable(),
        inappropriate_crying: Yup.boolean().nullable()
      }),
      ear_nose_throat: Yup.object().shape({
        trouble_hearing: Yup.boolean().nullable(),
        ringing_in_ears: Yup.boolean().nullable(),
        dizziness: Yup.boolean().nullable(),
        loss_of_balance: Yup.boolean().nullable(),
        ear_pain: Yup.boolean().nullable(),
        ear_discharge: Yup.boolean().nullable()
      }),
      musculoskeletal: Yup.object().shape({
        muscle_pain: Yup.boolean().nullable(),
        muscle_cramp: Yup.boolean().nullable(),
        loss_of_vision: Yup.boolean().nullable(),
        muscle_twitches: Yup.boolean().nullable(),
        loss_of_muscle: Yup.boolean().nullable(),
        neck_pain: Yup.boolean().nullable(),
        back_pain: Yup.boolean().nullable(),
        joint_swelling: Yup.boolean().nullable()
      }),
      hematological: Yup.object().shape({
        abnormal_bleeding: Yup.boolean().nullable(),
        anemia: Yup.boolean().nullable(),
        lumps_or_swelling: Yup.boolean().nullable()
      }),
      cardiovascular: Yup.object().shape({
        chest_pain: Yup.boolean().nullable(),
        irregular_heart_beat: Yup.boolean().nullable(),
        high_blood_pressure: Yup.boolean().nullable(),
        limb_pain_walking: Yup.boolean().nullable(),
        fainting: Yup.boolean().nullable()
      }),
      skin_breast: Yup.object().shape({
        numbness: Yup.boolean().nullable(),
        tingling: Yup.boolean().nullable(),
        discoloration: Yup.boolean().nullable(),
        hair_loss: Yup.boolean().nullable(),
        nail_change: Yup.boolean().nullable(),
        sweating_change: Yup.boolean().nullable()
      }),
      immunologic: Yup.object().shape({
        skin_rash: Yup.boolean().nullable(),
        joint_pain: Yup.boolean().nullable(),
        dry_mouth_eyes: Yup.boolean().nullable()
      })
    }).notRequired()
  })
});
