import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as officesApi from '../../../../api/companies';
import { CreateOfficeModal } from '../../../../app/Dashboard/Contacts/Offices/CreateOfficeModal';
import { generateAddress } from '../../../../utils/generateAddress';
import { useModal } from '../../../ModalsProvider';
import { Autocomplete } from '../../Autocomplete';
import { Label } from './Label';

const fetchOffices = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return officesApi.fetchOffices({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => {
  return !option?.isCreatableOption ? (
    <Label office={option} />
  ) : (
    <>
      <Box display="flex" mr={1}>
        <AddIcon color="primary" />
      </Box>

      Add office
    </>
  );
};

export const OfficesLocationSelect = ({
  formattedValue = true,
  isCreatable = false,
  multiple = false,
  params = {},
  name = '',
  attorneyInfo = {},
  companyPayload = {},

  ...props
}) => {
  const { openModal } = useModal();

  const addOffice = () => {
    return new Promise((resolve, reject) => {
      openModal(CreateOfficeModal, {
        payload: {
          disableCompany: params?.without_company,
          company: companyPayload,
          attorneyInfo
        },
        onModalResolved: resolve,
        onModalRejected: reject
      });
    });
  };

  return (
    <Autocomplete
      isAsync
      multiple={multiple}
      isCreatable={isCreatable}
      name={name}
      label="Office"
      placeholder="Select office..."
      onNeedFetch={fetchOffices(params)}
      renderOption={renderOption}
      getOptionLabel={(office) => {
        const fullAddress = office?.full_address || generateAddress(office);
        const address = !!fullAddress ? `, ${fullAddress}` : '';

        return office?.name ? `${office?.name}${address}` : '';
      }}
      getOptionValue={(option) => formattedValue ? option?.id : option}
      getOptionFormattedValue={(option) => option?.id}
      getOptionSelected={(option, value) => option?.id === value?.id}
      onCreate={addOffice}
      {...props}
    />
  );
};
