export const addressPurposeOptions = [
  {
    label: 'PRIMARY',
    value: 'PRIMARY'
  },
  {
    label: 'LOCATION',
    value: 'LOCATION'
  },
  {
    label: 'MAILING',
    value: 'MAILING'
  },
  {
    label: 'SECONDARY',
    value: 'SECONDARY'
  }
];
