import {
  makeStyles,
  SvgIcon,
  Typography,
  Box,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Icon } from '@mdi/react';
import { mdiDomain } from '@mdi/js';
import { IconButton } from '../../../../../../components/IconButton';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ office, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="flex-start" flexGrow={1}>
        <SvgIcon fontSize="large"><Icon path={mdiDomain} /></SvgIcon>

        <Box ml={2} mt={1} display="flex" flexDirection="column" flexGrow={1}>
          <Typography
            title={office.name}
            variant={isMobile ? 'h4' : 'h2'}
            className={classes.title}
          >
            {office.name}
          </Typography>

          <Box my={1}>
            <Typography color="textSecondary">
              {office?.practice || 'No practice info'}
            </Typography>
          </Box>
        </Box>
      </Box>

      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};
