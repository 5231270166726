import { api } from '../../api';

export const fetchModifierCodes = (config) => {
  return api.get('/procedure-codes/modifier', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchModifierCode = (id, config) => {
  return api.get(`/procedure-codes/modifier/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createModifierCode = (data) => {
  return api.post('/procedure-codes/modifier/', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateModifierCode = ({ id, ...data }) => {
  return api.put(`/procedure-codes/modifier/${id}`, data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteModifierCode = (id) => {
  return api.delete(`/procedure-codes/modifier/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massActiveCodes = (IDs) => {
  return api.post('/procedure-codes/modifier/active', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massInactiveCodes = (IDs) => {
  return api.post('/procedure-codes/modifier/inactive', { id: IDs })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteModefierCodes = (IDs) => {
  return api.delete('procedure-codes/modifier', { params: { id: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
