import { createContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { merge } from 'lodash';
import { api } from '../../../../../../../api';
import * as schedulesApi from '../../../../../../../api/codes/procedures';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { setSchedulesLastGlobalAction } from '../../../../../../../store/globalActions';
import { useModal } from '../../../../../../../components/ModalsProvider';
import * as types from './types';
import { initialState } from './initialState';
import { reducer } from './reducer';

export const SchedulesContext = createContext();

export const SchedulesContextProvider = ({ initialState: initialStateProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const schedulesLastGlobalAction = useSelector(({ globalActions }) => globalActions.schedulesLastGlobalAction);
  const dispatchRedux = useDispatch();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isFetched,
    isFetching,
    filter,
    pagination,
    schedules,
    selectedSchedulesIDs
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchFeeSchedules = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.SCHEDULES_FETCH_REQUEST });

    schedulesApi.getFeeSchedules({
      params: {
        ...filter,
        ...newFilter
      },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.SCHEDULES_FETCH_SUCCESS, payload: data });
    });
  };

  const deleteFeeSchedule = (schedule) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        schedulesApi.deleteFeeSchedule(schedule.id).then(() => {
          dispatchRedux(setSchedulesLastGlobalAction({ type: types.DELETE_SCHEDULE, payload: schedule.id }));
        });
      }
    });
  };

  const massDeleteSchedules = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        schedulesApi.massDeleteFeeSchedules(IDs)
          .then(() => {
            IDs.forEach((item) => toggleItemSelection(item));
            dispatchRedux(setSchedulesLastGlobalAction({ type: types.MASS_DELETE_SCHEDULES, payload: IDs }));
            enqueueSnackbar('Schedules successfully deleted', { variant: 'success' });
          })
          .catch(() => {
            enqueueSnackbar('Schedules are not deleted', { variant: 'error' });
          });
      }
    });
  };

  const massActiveFeeSchedules = (IDs) => {
    schedulesApi.massActiveFeeSchedules(IDs)
      .then((data) => {
        dispatchRedux(setSchedulesLastGlobalAction({ type: types.MASS_ACTIVE_SCHEDULES, payload: data }));
        enqueueSnackbar('Successfully activated', { variant: 'success' });
      });
  };

  const massInActiveFeeSchedules = (IDs) => {
    schedulesApi.massInactiveFeeSchedules(IDs)
      .then((data) => {
        dispatchRedux(setSchedulesLastGlobalAction({ type: types.MASS_INACTIVE_SCHEDULES, payload: data }));
        enqueueSnackbar('Successfully deactivated', { variant: 'success' });
      });
  };

  const resetSchedules = (newFilter) => {
    dispatch({ type: types.RESET_SCHEDULES, payload: newFilter });

    fetchFeeSchedules({ page: 1, ...newFilter });
  };

  const allSchedulesIsSelected = () => {
    return schedules.length === selectedSchedulesIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_SCHEDULE_SELECTION, payload: id });
  };

  const toggleAllSchedulesSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_SCHEDULES_SELECTION });
  };

  useEffect(() => {
    schedulesLastGlobalAction && dispatch(schedulesLastGlobalAction);
  }, [ schedulesLastGlobalAction ]);

  const providerValue = {
    schedules,
    isFetched,
    isFetching,
    filter,
    pagination,
    meta: {
      ...filter,
      ...pagination
    },
    selectedSchedulesIDs,

    // functions
    deleteFeeSchedule,
    resetSchedules,
    allSchedulesIsSelected,
    toggleItemSelection,
    toggleAllSchedulesSelection,
    massDeleteSchedules,
    massActiveFeeSchedules,
    massInActiveFeeSchedules
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <SchedulesContext.Provider value={providerValue}>
      {children}
    </SchedulesContext.Provider>
  );
};
