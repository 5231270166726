import T from 'prop-types';
import cn from 'classnames';
import { makeStyles, IconButton, InputAdornment } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import { VerticalDivider } from '../../../VerticalDivider';
import { Select } from '../Select';
import { orderDirectionsMap } from './orderDirectionsMap';
import { styles } from './styles';

const propTypes = {
  orderDirection: T.oneOf(Object.values(orderDirectionsMap)).isRequired
};

const useStyles = makeStyles(styles);

export const OrderBy = ({
  options,
  orderDirection,
  onOptionChange,
  onOrderDirectionChange,
  TextFieldProps,
  ...props
}) => {
  const classes = useStyles();

  const handleOrderDirectionChange = () => {
    const direction = (orderDirection === orderDirectionsMap.asc) ? (
      orderDirectionsMap.desc
    ) : (
      orderDirectionsMap.asc
    );

    onOrderDirectionChange(direction);
  };

  const handleOptionChange = (option) => {
    onOptionChange(option && {
      ...option,

      data: option.value
    });
  };

  return (
    <Select
      isSearchable={false}
      name="order_by"
      label="Order by"
      onChange={handleOptionChange}
      options={options}
      TextFieldProps={{
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <VerticalDivider />

              <IconButton
                title={
                  orderDirection === orderDirectionsMap.asc ?
                    'Order descending'
                    :
                    'Order ascending'
                }
                onClick={handleOrderDirectionChange}
              >
                <SortIcon
                  fontSize="small"
                  className={
                    cn(
                      classes.sortIcon,
                      orderDirection === orderDirectionsMap.asc ?
                        [ classes.sortIcon_asc ]
                        :
                        [ classes.sortIcon_desc ]
                    )
                  }
                />
              </IconButton>
            </InputAdornment>
          )
        },
        ...TextFieldProps
      }}
      {...props}
    />
  );
};

OrderBy.propTypes = propTypes;
