export const fieldsKeysMap = {
  model: 'model',
  modelID: 'model_id',
  date: 'date',
  userIDs: 'user_ids',
  logName: 'log_name',
  subjectType: 'subject_type'
};

export const filterLabels = {
  [fieldsKeysMap.model]: 'Model Type',
  [fieldsKeysMap.date]: 'Date',
  [fieldsKeysMap.modelID]: 'Model',
  [fieldsKeysMap.userIDs]: 'Users',
  [fieldsKeysMap.logName]: 'Activity Name',
  [fieldsKeysMap.subjectType]: 'Subject Type'
};
