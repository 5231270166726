export const styles = ({ spacing, zIndex }) => ({
  root: {
    position: 'absolute',
    left: spacing(2),
    top: spacing(2),
    zIndex: zIndex.modal
  },

  tabs: {
    padding: spacing(1)
  },

  content: {
    padding: spacing(2, 2)
  }
});
