import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isEqual, omit } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import { api } from '../../../../../api';
import * as emailsApi from '../../../../../api/emails';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const SavedEmailsContext = createContext();

export const SavedEmailsContextProvider = ({
  filter: filterProp = {},
  caseItem = null,
  patientItem = null,
  children
}) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ isOpenView, setIsOpenView ] = useState(false);
  const { pagination, messages, selectedMessages } = state;
  const filter = { ...state.filter, ...filterProp };
  const { user } = useSelector(({ profile }) => profile);
  const cancelFetch = useRef(() => {});
  const prevFilter = usePrevious(filter);
  const user_id = !caseItem && !patientItem ? user.id : null;
  const [ relationsForFilter, setRelationsForFilter ] = useState({
    case_id: caseItem || null,
    patient_id: patientItem || null
  });

  const fetchSavedMessages = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_MESSAGES_REQUEST });

    emailsApi.fetchSavedMessages({
      params: { user_id, ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_MESSAGES_SUCCESS, payload: data });
    });
  };

  const resetSavedMessages = (newFilter) => {
    dispatch({ type: types.RESET_MESSAGES, payload: newFilter });

    fetchSavedMessages({ page: 1, ...newFilter });
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const allItemsIsSelected = () => {
    return messages.length === selectedMessages.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_ITEM_SELECTION, payload: id });
  };

  const toggleAllItemsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_ITEMS_SELECTION });
  };

  const addCaseToMessages = (messageIDs, caseItem) => {
    dispatch({ type: types.ADD_CASE_TO_MESSAGES, payload: [ messageIDs, caseItem ] });
  };

  const deleteCaseFromMessages = (messageIDs) => {
    dispatch({ type: types.DELETE_CASE_FROM_MESSAGES, payload: messageIDs });
  };

  const addPatientToMessages = (messageIDs, patient) => {
    dispatch({ type: types.ADD_PATIENT_TO_MESSAGES, payload: [ messageIDs, patient ] });
  };

  const deletePatientFromMessages = (messageIDs) => {
    dispatch({ type: types.DELETE_PATIENT_FROM_MESSAGES, payload: messageIDs });
  };

  const addTagsToMessages = (messageIDs, tags) => {
    dispatch({ type: types.ADD_TAGS_TO_MESSAGES, payload: [ messageIDs, tags ] });
  };

  const deleteTagsFromMessages = (messageIDs, tagsIDs) => {
    dispatch({ type: types.DELETE_TAGS_FROM_MESSAGES, payload: [ messageIDs, tagsIDs ] });
  };

  const replaceTagsToMessages = (messageIDs, tags) => {
    dispatch({ type: types.REPLACE_TAGS_TO_MESSAGES, payload: [ messageIDs, tags ] });
  };

  const fetchMessage = (message) => {
    dispatch({ type: types.SET_ONE_MESSAGE, payload: message });
  };

  const deleteMessages = (messageIDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        emailsApi.deleteSavedMessages(messageIDs).then(() => {
          dispatch({ type: types.DELETE_MESSAGE, payload: messageIDs });
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
          fetchSavedMessages();
        }).catch(() => {
          enqueueSnackbar('Email is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const toggleEmailView = () => {
    setIsOpenView((state) => !state);
  };

  const contextValue = {
    ...state,
    filter,
    pagination,
    messages,
    selectedMessages,
    caseItem,
    patientItem,
    meta: {
      ...pagination,
      ...filter
    },
    relationsForFilter,
    isOpenView,

    // functions
    setIsOpenView,
    toggleEmailView,
    setRelationsForFilter,
    fetchMessage,
    fetchSavedMessages,
    resetSavedMessages,
    applyFilter,
    allItemsIsSelected,
    toggleItemSelection,
    toggleAllItemsSelection,
    addCaseToMessages,
    deleteCaseFromMessages,
    addPatientToMessages,
    deletePatientFromMessages,
    addTagsToMessages,
    deleteTagsFromMessages,
    replaceTagsToMessages,
    deleteMessages
  };

  useEffect(() => {
    const newFilter = { ...filter, ...filterProp };

    if (!isEqual(omit(filter, [ 'page' ]), omit(newFilter, [ 'page' ]))) {
      applyFilter(newFilter);
    }
  }, [ filter, filterProp ]);

  useEffect(() => {
    if (!isEqual(omit(prevFilter, [ 'page' ]), omit(filter, [ 'page' ]))) {
      resetSavedMessages();
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    resetSavedMessages();

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <SavedEmailsContext.Provider value={contextValue}>
      {children}
    </SavedEmailsContext.Provider>
  );
};
