import { Typography } from '@material-ui/core';
import { CurrencyFormat } from '../../../../../../../../../components/CurrencyFormat';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { columnsWidths } from './Procedures';
import { ServiceLines } from './ServiceLines';

export const Row = ({ appointment, procedure }) => {
  return (
    <div>
      <ListRow>
        <ListRowCell grow column flexBasis={columnsWidths.cpt}>
          <Typography noWrap>
            {procedure.cpt?.procedure_code}
          </Typography>

          <Typography noWrap color="textSecondary" title={procedure.cpt?.description}>
            {procedure.cpt?.description}
          </Typography>
        </ListRowCell>

        <ListRowCell column flexBasis={columnsWidths.pos}>
          <Typography noWrap>
            {procedure.pos?.code}
          </Typography>

          <Typography noWrap color="textSecondary" title={procedure.pos?.description}>
            {procedure.pos?.description}
          </Typography>
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dx}>
          {procedure.icd?.length || '-'}
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.modifiers}>
          {procedure.modifiers?.length || '-'}
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.unit}>
          {procedure.unit || '-'}
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.price}>
          <CurrencyFormat value={procedure.price} />
        </ListRowCell>
      </ListRow>

      <ServiceLines appointment={appointment} serviceLines={procedure.service_line} />
    </div>
  );
};
