import * as templatesApi from '../../../../api/schedule-events/schedule-event-templates';
import { Autocomplete } from '../../../FormField';

const fetchTemplates = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return templatesApi.fetchEventTemplates({
    params: {
      search,
      page: page + 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const getOptionValue = (option) => option?.id;
const getOptionSelected = (option, value) => option.id === value?.id;
const getOptionLabel = (option) => option?.name;

export const ScheduleEventTemplatesSelect = ({ params = {}, ...props }) => {
  return (
    <Autocomplete
      isAsync
      label="Event template"
      placeholder="Select template..."
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      getOptionSelected={getOptionSelected}
      onNeedFetch={fetchTemplates(params)}

      {...props}
    />
  );
};
