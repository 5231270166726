import { useEffect, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { NestedList } from '../../../../../../../components/NestedList';
import { typesNamesMap } from '../AccidentInfo/typesMap';
import { socialHistoryTypes } from '../PastMedicalHistory/socialHistoryTypes';

export const AccidentInfoOrtho = ({ isOpenSwitches, form }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const accident = form?.forms?.accident_information_ortho || {};
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Accident information"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Grid container component={Box} p={isSmallScreen ? 1 : 3} spacing={3} alignItems="flex-start">
        {form?.accident_type === typesNamesMap.car_accident && (
          <>
            <Grid item md={3} xs={6}>
              <Typography variant="h5">Type</Typography>
            </Grid>

            <Grid item md={9} xs={6}>
              <ReadonlyCheckbox checked title={form?.accident_type} />
            </Grid>

            {!!accident?.car_accident?.were_you_the_driver_passenger_front_or_back && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Were you the driver, passenger (front or back)?
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <ReadonlyCheckbox
                    checked
                    title={accident?.car_accident?.were_you_the_driver_passenger_front_or_back}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography>{accident?.car_accident?.were_you_the_driver_description}</Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h5">
                Were you driving or a passenger for UBER or LYFT?
              </Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <ReadonlyCheckbox
                checked
                title={
                  accident?.car_accident?.were_you_driving_or_a_passenger_for_uber_or_lyft === socialHistoryTypes.yes
                    ? 'Yes' : 'No'
                }
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h5">
                Did you go to an emergency or urgent care after the accident?
              </Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <ReadonlyCheckbox
                checked
                title={
                  // eslint-disable-next-line max-len
                  accident?.car_accident?.did_you_go_to_an_emergency_or_urgent_care_after_the_accident === socialHistoryTypes.yes
                    ? 'Yes' : 'No'
                }
              />
            </Grid>

            {!!accident?.car_accident?.if_so_when && (
              <>
                <Grid item md={6} xs={12}>
                  <Typography variant="h5">If so when?</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography>{accident?.car_accident?.if_so_when}</Typography>
                </Grid>
              </>
            )}

            {!!accident?.car_accident?.what_did_they_do && (
              <>
                <Grid item md={6} xs={12}>
                  <Typography variant="h5">What did they do?</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography>{accident?.car_accident?.what_did_they_do}</Typography>
                </Grid>
              </>
            )}

            {!!accident?.car_accident?.were_you_prescribed_any_medications && (
              <>
                <Grid item md={6} xs={12}>
                  <Typography variant="h5">Were you prescribed any medications?</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography>{accident?.car_accident?.were_you_prescribed_any_medications}</Typography>
                </Grid>
              </>
            )}

            <Grid item md={6} xs={12}>
              <Typography variant="h5">
                Was a police report filed?
              </Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <ReadonlyCheckbox
                checked
                title={
                  accident?.car_accident?.was_a_police_report_filed === socialHistoryTypes.yes
                    ? 'Yes' : 'No'
                }
              />
            </Grid>

            {!!accident?.car_accident?.briefly_explain_how_the_accident_occurred && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">Briefly explain how the accident occurred:</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>{accident?.car_accident?.briefly_explain_how_the_accident_occurred}</Typography>
                </Grid>
              </>
            )}
          </>
        )}

        {form?.accident_type === typesNamesMap.slip_and_fall && (
          <>
            <Grid item md={3} xs={6}>
              <Typography variant="h5">Type</Typography>
            </Grid>

            <Grid item md={9} xs={6}>
              <ReadonlyCheckbox checked title={form?.accident_type} />
            </Grid>

            {!!accident?.slip_and_fall?.where_did_the_accident_occur && (
              <>
                <Grid item md={6} xs={12}>
                  <Typography variant="h5">Where did the accident occur:</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography>{accident?.slip_and_fall?.where_did_the_accident_occur}</Typography>
                </Grid>
              </>
            )}

            <Grid item md={6} xs={12}>
              <Typography variant="h5">Was a police report filed?</Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <ReadonlyCheckbox
                checked
                title={
                  accident?.slip_and_fall?.was_a_police_report_filed === socialHistoryTypes.yes
                    ? 'Yes' : 'No'
                }
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h5">
                Did you go to an emergency or urgent care after the accident?
              </Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <ReadonlyCheckbox
                checked
                title={
                  // eslint-disable-next-line max-len
                  accident?.slip_and_fall?.did_you_go_to_an_emergency_or_urgent_care_after_the_accident === socialHistoryTypes.yes
                    ? 'Yes' : 'No'
                }
              />
            </Grid>

            {!!accident?.slip_and_fall?.if_so_when && (
              <>
                <Grid item md={6} xs={12}>
                  <Typography variant="h5">If so when?</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography>{accident?.slip_and_fall?.if_so_when}</Typography>
                </Grid>
              </>
            )}

            {!!accident?.slip_and_fall?.what_did_they_do && (
              <>
                <Grid item md={6} xs={12}>
                  <Typography variant="h5">What did they do?</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography>{accident?.slip_and_fall?.what_did_they_do}</Typography>
                </Grid>
              </>
            )}

            {!!accident?.slip_and_fall?.were_you_prescribed_any_medications && (
              <>
                <Grid item md={6} xs={12}>
                  <Typography variant="h5">Were you prescribed any medications?</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography>{accident?.slip_and_fall?.were_you_prescribed_any_medications}</Typography>
                </Grid>
              </>
            )}

            {!!accident?.slip_and_fall?.briefly_explain_how_the_accident_occurred && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">Briefly explain how the accident occurred:</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>{accident?.slip_and_fall?.briefly_explain_how_the_accident_occurred}</Typography>
                </Grid>
              </>
            )}
          </>
        )}

        {form?.accident_type === typesNamesMap.other && (
          <>
            <Grid item md={3} xs={6}>
              <Typography variant="h5">Type</Typography>
            </Grid>

            <Grid item md={9} xs={6}>
              <ReadonlyCheckbox checked title={form?.accident_type} />
            </Grid>

            <Grid item xs={12}>
              <Typography>{accident?.other?.text}</Typography>
            </Grid>

            {accident?.other?.describe_the_circumstances_around_the_onset_of_your_pain && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Describe the circumstances around the onset of your pain:
                  </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography>
                    {accident?.other?.describe_the_circumstances_around_the_onset_of_your_pain}
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </NestedList>
  );
};
