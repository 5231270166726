export const styles = ({ spacing, palette }) => ({
  listContent: {
    border: `solid ${palette.divider}`,
    borderWidth: '0 1px 1px 1px'
  },

  toolbar: {
    marginRight: -spacing(0.5),
    marginLeft: -spacing(),
    padding: 0,
    paddingRight: spacing(0.5)
  }
});
