import { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Box, Link, Typography } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import LockIcon from '@material-ui/icons/LockOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import WorkIcon from '@material-ui/icons/WorkOutline';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../../helpers/dom';
import { hasRole } from '../../../../../../utils/hasRole';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { CurrencyFormat } from '../../../../../../components/CurrencyFormat';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Label } from '../../../../../../components/Label';
import { PreviewCompanyModal } from '../../../../Contacts/Companies/PreviewCompanyModal';
import { statusTypesColors, statusTypesNames } from '../../../../Members';
import { CaseViewModal } from '../../../../CasesPage/Cases/CaseViewModal';
import { CategoriesContext } from '../../CategoriesProvider';
import { minRowHeight, columnsWidths } from '../List';

export const Row = ({ item: category = {}, isLoaded, recalculateHeight }) => {
  const {
    updateCategory,
    deleteCategory,
    activateCategory,
    deactivateCategory
  } = useContext(CategoriesContext);
  const [ time, setTime ] = useState('');
  const { openModal } = useModal();
  const categoryRate = ~~(category.rate / 100);

  const openPreview = () => {
    openModal(CaseViewModal, {
      payload: { id: category?.case?.id }
    });
  };

  const openCompanyViewModal = (companyID) => () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID }
    });
  };

  const handleCategoryDelete = () => {
    deleteCategory(category.id);
  };

  const handleCategoryDeactivate = () => {
    deactivateCategory(category);
  };

  const handleCategoryActivate = () => {
    activateCategory(category);
  };

  const caseProps = !category?.case?.file_number ? {
    component: Link,
    onClick: stopPropagation(openPreview)
  } : {};

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ recalculateHeight, isLoaded ]);

  useEffect(() => {
    setTime(`${moment.duration(category.time, 'seconds').format('h[h] m[min]')}`);
  }, [ category ]);

  return !isEmpty(category) && (
    <ListRow minHeight={minRowHeight}>
      <ListRowCell grow noWrap flexBasis={columnsWidths.name}>
        <Typography noWrap color="textPrimary">
          {category.name}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.case}>
        {category?.case?.id ?
          <>
            <WorkIcon color="secondary" />

            <Box ml={1} overflow="hidden" textOverflow="ellipsis">
              {category?.case?.name &&
                <Typography
                  noWrap
                  variant="body2"
                  color="textSecondary"
                  {...caseProps}
                >
                  {category?.case?.name}
                </Typography>
              }

              {category?.case?.file_number &&
                <Typography
                  noWrap
                  color="textPrimary"
                  component={Link}
                  onClick={stopPropagation(openPreview)}
                >
                  {category?.case?.file_number}
                </Typography>
              }
            </Box>
          </>
          :
          <Typography color="textSecondary">No info.</Typography>
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.company}>
        <CompanyLink
          noWrap
          company={category?.company}
          onClick={stopPropagation(openCompanyViewModal(category?.company?.id))}
        />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.time}>
        <Typography color="textSecondary">
          {time}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.rate}>
        {categoryRate
          ? <CurrencyFormat value={categoryRate} />
          : 'n/a'
        }
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.isActive}>
        <Label
          color={statusTypesColors[+category.is_active]}
          label={statusTypesNames[+category.is_active]}
        />
      </ListRowCell>

      {hasRole(rolesMap.admin, rolesMap.supervisor) &&
        <ListRowCell flexBasis={columnsWidths.actions}>
          <IconButton edge="end" color="primary" onClick={updateCategory(category)}>
            <EditIcon/>
          </IconButton>

          <Tooltip title={category.is_active ? 'Deactivate category' : 'Activate category'}>
            <IconButton
              variant="contained"
              color={category.is_active ? 'success' : 'warning'}
              onClick={category.is_active ? handleCategoryDeactivate : handleCategoryActivate}
            >
              {category.is_active ? <LockOpenIcon/> : <LockIcon/>}
            </IconButton>
          </Tooltip>

          <IconButton color="error" onClick={handleCategoryDelete}>
            <DeleteIcon/>
          </IconButton>
        </ListRowCell>
      }
    </ListRow>
  );
};
