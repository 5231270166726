import { useContext, useEffect, useRef } from 'react';
import { makeStyles, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { TagsContext } from '../../../files-common';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const columnsWidths = {
  name: 200,
  actions: 116
};

export const List = () => {
  const classes = useStyles();
  const {
    isFetched,
    isFetching,
    tags,
    filter,
    resetTags,
    loadNextPage,
    updateTagsPositions
  } = useContext(TagsContext);
  const scrollElementRef = useRef();

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const item = tags[oldIndex];

      updateTagsPositions({ id: item.id, oldIndex, newIndex });
    }
  };

  useEffect(() => {
    resetTags();
  }, []);

  return (
    <Scrollbars viewRef={scrollElementRef}>
      <div ref={scrollElementRef} className={classes.root}>
        <Loader loading={!isFetched} className={classes.loader} render={
          () => !tags.length ? (
            <Typography align="center">No tags found</Typography>
          ) : (
            <MuiList disablePadding className={classes.list}>
              <InfiniteListLoader
                isSortable
                useDragHandle
                items={tags}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                scrollElementRef={scrollElementRef}
                onSortEnd={handleSortEnd}
                lockAxis="y"
                helperClass={classes.draggableRow}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          )}
        />
      </div>
    </Scrollbars>
  );
};
