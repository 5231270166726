import { createHookReducer } from '../../../../../helpers/createHookReducer';
import { moveArrayItem } from '../../../../../helpers/moveArrayItem';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_REMINDER_GROUPS_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_REMINDER_GROUPS_SUCCESS]: (
    { filter, reminderGroups, ...state },
    { data: newReminderGroups, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      reminderGroups: page > 1 ? (
        reminderGroups.filter(({ id }) => {
          return !newReminderGroups.find((loadedReminderGroup) => id === loadedReminderGroup.id);
        }).concat(newReminderGroups)
      ) : (
        newReminderGroups
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_REMINDER_GROUPS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.ADD_REMINDER_GROUP]: (state, reminderGroup) => {
    const { pagination, filter, reminderGroups, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((reminderGroups.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      reminderGroups: [ reminderGroup, ...reminderGroups ]
    };
  },

  [types.UPDATE_REMINDER_GROUP]: ({ reminderGroups, ...state }, updatedReminderGroup) => {
    return {
      ...state,

      reminderGroups: reminderGroups.map((reminderGroup) => {
        return updatedReminderGroup.id === reminderGroup.id ? updatedReminderGroup : reminderGroup;
      })
    };
  },

  [types.UPDATE_REMINDER_GROUPS_POSITIONS]: ({ reminderGroups, ...state }, positions) => {
    const { oldIndex, newIndex } = positions;

    return {
      ...state,

      reminderGroups: moveArrayItem(reminderGroups, oldIndex, newIndex)
    };
  },

  [types.DELETE_REMINDER_GROUP]: (state, reminderGroupID) => {
    const { pagination, filter, reminderGroups } = state;
    const isExistedReminderGroup = !!reminderGroups.find((reminderGroup) => reminderGroupID === reminderGroup.id);

    if (!isExistedReminderGroup) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((reminderGroups.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      reminderGroups: reminderGroups.filter((reminderGroup) => reminderGroupID !== reminderGroup.id)
    };
  }
});
