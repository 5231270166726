import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import * as contactApi from '../../../../../api/contacts';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { Cases } from '../../Cases';
import { Files } from './Files';
import { ReputationInfo } from './ReputationInfo';
import { Tasks } from '../../Tasks';
import { UserInfo } from './UserInfo';

export const widthBreakpointLarge = 1370;

export const UserItem = () => {
  const params = useParams();
  const history = useHistory();
  const [ contact, setContact ] = useState(null);
  const scrollElementRef = useRef();
  const { width } = useResizeObserver({ ref: scrollElementRef });

  useEffect(() => {
    contactApi.fetchContact(params.contactID)
      .then(setContact)
      .catch(() => history.push('/contacts/contacts'));
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={2}>
        <Loader loading={!contact} render={
          () => (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <UserInfo contact={contact} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <ReputationInfo contact={contact} />
              </Grid>

              <Grid item xs={12} md={12} lg={width > widthBreakpointLarge ? 7 : 12}>
                <Cases filter={{ contacts: [ contact.id ] }} />
              </Grid>

              <Grid item xs={12} md={12} lg={width > widthBreakpointLarge ? 5 : 12}>
                <Files contact={contact} />
              </Grid>

              <Grid item xs={12}>
                <Tasks filter={{ contacts: [ contact.id ] }} />
              </Grid>
            </Grid>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
