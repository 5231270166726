import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../../../components/Button';
import { useModal } from '../../../../../components/ModalsProvider';
import { TimeReportCreationModal } from '../../../../../components/time-reports/TimeReportCreationModal';
import { fieldsMap } from '../../../../../components/time-reports/TimeReportForm';
import { TimeReportsContext } from '../../../../../components/time-reports/TimeReportsProvider';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';

export const CreateReportButton = () => {
  const timeReportsContext = useContext(TimeReportsContext);
  const currentUser = useSelector(({ profile }) => profile.user);
  const { openModal } = useModal();

  const createReport = () => {
    openModal(TimeReportCreationModal, {
      payload: {
        initialValues: {
          user_id: currentUser.id,
          company_id: currentUser.work?.company_id,
          supervisor_id: hasRole(rolesMap.supervisor) ? currentUser.id : null
        },
        hiddenFields: [
          fieldsMap.user
        ]
      },

      onModalResolved: () => {
        timeReportsContext.resetTimeReports();
      }
    });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={createReport}
    >
      Create report
    </Button>
  );
};
