import { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { AppointmentsContext } from '../../../../../../components/appointments';
import { Divider } from '../../../../../../components/Divider';
import { columnsWidths } from '../SideBar';
import { FiltersBar } from './FiltersBar';
import { List } from './List';

export const Appointments = ({ selectedAppointment, onAppointmentSelect }) => {
  const { meta, filter, appointments } = useContext(AppointmentsContext);
  const isOnlySelectedAppointment = (meta.total === 1) && selectedAppointment?.id === appointments?.[0]?.id;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box py={1} px={2} bgcolor="grey.50">
        <FiltersBar appointment={selectedAppointment} />
      </Box>

      <Divider />

      {!!meta.total && !isOnlySelectedAppointment &&
        <Box display="flex" py={1.5} px={2}>
          <Box flexBasis={columnsWidths.date}>
            <Typography variant="subtitle1">Appt. Date</Typography>
          </Box>

          <Box flexBasis={columnsWidths.user}>
            <Typography variant="subtitle1">
              {filter?.patients?.length ? 'Doctor' : 'Patient'}
            </Typography>
          </Box>
        </Box>
      }

      <Divider />

      <Box flexGrow={1}>
        <List selectedAppointment={selectedAppointment} onAppointmentSelect={onAppointmentSelect} />
      </Box>
    </Box>
  );
};
