export const styles = ({ typography: { pxToRem } }) => ({
  actionButton: {
    fontSize: pxToRem(14),
    marginRight: 0,
    marginLeft: 0,
    padding: 0,
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});
