import { Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import {
  columnsWidths,
  widthBreakpointMedium,
  widthBreakpointLarge,
  widthBreakpointSmall
} from '../List';

export const Header = ({ width }) => {
  return (
    <ListRow header>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap variant="h5">
          Patient Name / Med Rec
        </Typography>
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.phone}>
        Phone
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.location}>
        Appt. Book / Location
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.appt}>
        Appointment
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell flexBasis={columnsWidths.duration}>
          Appt. Type / Duration
        </ListRowCell>
      }

      {width > widthBreakpointMedium &&
        <ListRowCell grow flexBasis={columnsWidths.notes}>
          Notes
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.waitingTime}>
        Waiting
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions} />
    </ListRow>
  );
};
