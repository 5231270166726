export const USERS_FETCH_REQUEST = 'USERS_FETCH_REQUEST';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER_IN_LIST = 'UPDATE_USER_IN_LIST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const RESET_USERS = 'RESET_USERS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const UPLOAD_USER_AVATAR = 'UPLOAD_USER_AVATAR';
export const MASS_DELETE_USERS = 'MASS_DELETE_USERS';
export const TOGGLE_USER_SELECTION = 'TOGGLE_USER_SELECTION';
export const TOGGLE_ALL_USERS_SELECTION = 'TOGGLE_ALL_USERS_SELECTION';
