import { Yup } from '../../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  model_id: Yup.string().required(),
  terms: Yup.string().required(),
  bill_from: Yup.object().shape({
    type: Yup.mixed().nullable().required(),
    id: Yup.mixed().nullable().required()
  }).required(),
  bill_to: Yup.object().shape({
    type: Yup.mixed().nullable().required(),
    id: Yup.mixed().nullable().required()
  }).required(),
  date: Yup.mixed().required(),
  due_date: Yup.mixed().required(),
  number: Yup.string().nullable().max(20),
  po: Yup.string().nullable().max(20),
  notes: Yup.string().nullable().max(1000)
});
