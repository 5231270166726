import { useContext } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  makeStyles,
  IconButton,
  ListItem,
  ListItemText
} from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import { SvgIcon } from '../../../../../../../components/SvgIcon';
import { MessagesContext } from '../../../../MessagesContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MessageItem = ({ message }) => {
  const { toggleItemSelection } = useContext(MessagesContext);
  const classes = useStyles();

  const deleteFromChecked = () => {
    toggleItemSelection(message);
  };

  return (
    <ListItem className={classes.root}>
      <ListItemText
        primary={message.from.name}
        primaryTypographyProps={{ noWrap: true }}
        classes={{ root: classes.name, primary: classes.nameText }}
      />

      <ListItemText
        primary={
          <>
            {!isEmpty(message.attachments) &&
            <>
              <AttachmentIcon className={classes.filesIcon}/>
              <span className={classes.filesDivider} />
            </>
            }
            {message.subject}
          </>
        }
        primaryTypographyProps={{ noWrap: true }}
        classes={{ root: classes.subject }}
      />

      <ListItemText
        primary={moment.unix(message.date).format('L')}
        classes={{ root: classes.date }}
      />

      <IconButton size="small" className={classes.remove} onClick={deleteFromChecked}>
        <SvgIcon color="error">
          <CloseIcon />
        </SvgIcon>
      </IconButton>
    </ListItem>
  );
};
