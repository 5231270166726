export const styles = ({
  spacing,
  palette: { grey, primary, ...palette },
  transitions,
  shadows
}) => ({
  imageCroppedContent: {
    width: ({ size = 180 }) => size,
    height: ({ size = 180 }) => size,
    margin: `${spacing(2)}px auto`,
    borderRadius: '100%',
    boxShadow: shadows[2],
    transition: transitions.create()
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block',
    borderRadius: '100%'
  },

  badge: ({ align }) => {
    switch (align) {
      case 'left' :
        return {
          left: 25,
          bottom: 40
        };
      default:
        return {
          right: 25,
          bottom: 40
        };
    }
  },

  badgeButton: {
    boxShadow: shadows[2],
    background: palette.background.default
  }
});
