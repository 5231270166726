import { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/Event';
import { NestedList } from '../../../../../NestedList';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { isEmptyDeep } from '../../../../../../helpers/isEmptyDeep';

export const RadiologyExams = ({ isOpenSwitches, report }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return !isEmptyDeep(report) && (
    <NestedList
      disablePadding
      isOpen={isOpen}
      title="Radiology exams/Lab results"
      TypographyProps={{ fontSize: 'large' }}
      onOpen={handleOpenListItem}
    >
      <Grid container spacing={0} component={Box} py={2} m="0 !important">
        {report?.no_new_data &&
          <Grid item xs={4}>
            <ReadonlyCheckbox
              checked={report?.no_new_data}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="No new data"
            />
          </Grid>
        }

        {report?.mri &&
          <Grid item xs={4}>
            <ReadonlyCheckbox
              checked={report?.mri}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="MRI"
            />
          </Grid>
        }

        {report?.ct &&
          <Grid item xs={4}>
            <ReadonlyCheckbox
              checked={report?.ct}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="CT"
            />
          </Grid>
        }

        {report?.x_ray &&
          <Grid item xs={4}>
            <ReadonlyCheckbox
              checked={report?.x_ray}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="X-ray"
            />
          </Grid>
        }

        {report?.labs &&
          <Grid item xs={4}>
            <ReadonlyCheckbox
              checked={report?.labs}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="Labs"
            />
          </Grid>
        }

        {report?.emg &&
          <Grid item xs={4}>
            <ReadonlyCheckbox
              checked={report?.emg}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="EMG"
            />
          </Grid>
        }

        {report?.other &&
          <Grid item xs={4}>
            <ReadonlyCheckbox
              checked={report?.other}
              BoxProps={{ px: 1, mb: 1, color: 'text.secondary' }}
              iconColor="success"
              title="Other"
            />
          </Grid>
        }

        <Grid item xs={8} />

        <Grid item xs={4}>
          {report.date &&
            <Box display="flex" alignItems="center" pt={2} pr={4} pl={1} color="text.secondary">
              <Box mr={3}>
                <Typography variant="h5">Date:</Typography>
              </Box>

              <Box mr={1}>
                <CalendarIcon fontSize="small" color="inherit" />
              </Box>

              <Typography color="textPrimary">
                {moment(report.date).format('L LT')}
              </Typography>
            </Box>
          }
        </Grid>

        <Grid item xs={8}>
          {report?.findings &&
            <Box display="flex" alignItems="flex-start" pr={4} pl={1} pt={2}>
              <Box mr={3}>
                <Typography variant="h5">Findings:</Typography>
              </Box>

              <Typography color="textSecondary">{report?.findings}</Typography>
            </Box>
          }
        </Grid>
      </Grid>
    </NestedList>
  );
};
