export const styles = ({ spacing, palette, shape: { borderRadius }, shadows }) => ({
  root: {
    width: 350,
    margin: spacing(0, 1),
    border: `1px solid ${palette.divider}`,
    borderRadius: borderRadius,
    boxShadow: shadows[4]
  },

  header: {
    display: 'flex',
    color: palette.primary.contrastText,
    backgroundColor: palette.grey[700],
    padding: spacing(1, 2)
  },

  threadTypeIndicator: {
    color: palette.text.primary
  },

  cardHeaderContent: {
    overflow: 'auto'
  },

  headerFocused: {
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main
  },

  action: {
    marginTop: 0
  },

  iconButton: {
    padding: 0,
    marginRight: 5
  },

  badge: {
    right: 4,
    bottom: 6
  },

  badgeIcon: {
    color: palette.success.main
  },

  body: {
    padding: spacing(1, 0)
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto'
  },

  expandOpen: {
    transform: 'rotate(180deg)'
  }
});
