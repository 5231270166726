import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import { useDebounce } from '../../../../../helpers/hooks';
import { Popper } from '../../../../../components/Popper';
import { Input } from './Input';
import { List } from './List';
import { Row } from './List/Row';
import { SearchContext } from './SearchProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Search = () => {
  const [ search, setSearch ] = useState('');
  const searchContext = useContext(SearchContext);
  const debouncedSearch = useDebounce(search);
  const classes = useStyles({ rowsCount: searchContext?.searchResults });

  const closeResults = () => {
    setSearch('');
  };

  useEffect(() => {
    if (debouncedSearch?.length > 2) {
      searchContext?.resetSearch({ q: debouncedSearch });
    }
  }, [ debouncedSearch ]);

  return (
    <Popper
      open={debouncedSearch?.length > 2}
      arrow={false}
      placement="bottom-start"
      anchorRenderer={({ anchorRef }) => (
        <Input
          withoutFormik
          variant="outlined"
          size="small"
          anchorRef={anchorRef}
          value={search}
          onChange={setSearch}
        />
      )}
    >
      <Paper elevation={4} className={classes.root}>
        <List
          row={Row}
          searchContext={searchContext}
          onResultClick={closeResults}
        />
      </Paper>
    </Popper>
  );
};
