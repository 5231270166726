import filesize from 'filesize';
import {
  makeStyles,
  Box,
  Card as MuiCard,
  CardActionArea,
  CardMedia,
  CardActions,
  IconButton,
  Typography
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useModal } from 'react-modal-hook';
import { Popper } from '../../Popper';
import { FilePreviewModal, FileTypeIcon } from '../../../app/Dashboard/files-common';
import { AttachmentsMenu } from '../AttachmentsMenu';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Card = ({ file, files }) => {
  const classes = useStyles();

  const [ openFilesPreview, closeFilesPreview ] = useModal(({ in: open, onExited }) => (
    <FilePreviewModal
      DialogProps={{
        open,
        onExited,
        onCancel: closeFilesPreview
      }}
      payload={{
        file,
        files,
        isFetched: true,
        isFetching: false
      }}
    />
  ), [ file, files.length ]);

  return (
    <MuiCard className={classes.root}>
      <CardActionArea onClick={openFilesPreview}>
        <CardMedia
          className={classes.media}
          image={file.previews['280'] || file.url}
          title={file.extension}
        />
      </CardActionArea>

      <CardActions disableSpacing className={classes.cardActions}>
        <FileTypeIcon className={classes.icon} file={file} />

        <Box ml={0.5} flexGrow={1} overflow="hidden">
          <Typography noWrap variant="h5" title={file.original_filename}>
            {file.original_filename}
          </Typography>

          <Typography color="textSecondary" className={classes.fileSize}>
            {filesize(file.size)}
          </Typography>
        </Box>

        <Box ml="auto">
          <Popper
            placement="left-start"
            anchorRenderer={
              ({ anchorRef, handleToggle }) => (
                <IconButton
                  buttonRef={anchorRef}
                  onClick={handleToggle}
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              )
            }
          >
            {({ handleClose }) => (
              <AttachmentsMenu file={file} openFilesPreview={openFilesPreview} onClose={handleClose} />
            )}
          </Popper>
        </Box>
      </CardActions>
    </MuiCard>
  );
};
