export const styles = ({
  spacing,
  palette: { grey, ...palette },
  typography: { fontWeightMedium, pxToRem },
  shape: { borderRadius }
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    border: `1px solid ${palette.divider}`,
    borderRadius,
    overflow: 'hidden',
    backgroundColor: grey[100]
  },

  filePreview: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    cursor: 'pointer'
  },

  filePreview__image: {
    alignSelf: 'flex-start'
  },

  filePreview__icon: {
    fontSize: 80
  },

  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
    paddingLeft: spacing(),
    paddingRight: spacing(0.5),
    borderTop: `1px solid ${palette.divider}`,
    backgroundColor: grey[100]
  },

  fileInfo__icon: {
    marginRight: spacing(),
    fontSize: 36
  },

  fileInfo__main: {
    flexGrow: 1,
    overflow: 'hidden'
  },

  fileInfo__top: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '1.85rem',
    color: grey[500]
  },

  fileInfo__name: {
    fontSize: pxToRem(13),
    fontWeight: fontWeightMedium
  },

  fileInfo__date: {
    fontSize: pxToRem(12),
    color: grey[400],
    fontWeight: fontWeightMedium
  },

  menuButton: {
    margin: 0
  }
});
