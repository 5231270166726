import { fade } from '@material-ui/core/styles';

export const styles = ({
  spacing,
  palette: { grey, ...palette },
  shape: { borderRadius },
  typography: { pxToRem },
  transitions,
  breakpoints
}) => ({
  root: {
    display: 'inline-block',
    width: 156,
    margin: spacing(0, 2, 2, 0),
    verticalAlign: 'top',

    [breakpoints.down('md')]: {
      width: '100%'
    }
  },

  imageWrap: {
    height: 96,
    position: 'relative',
    marginBottom: spacing(0.5),
    border: `1px solid ${grey[400]}`,
    borderRadius,

    '&:hover $previewIndicator': {
      opacity: 0
    },

    [breakpoints.down('md')]: {
      height: '100%'
    }
  },

  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },

  previewIndicator: {
    position: 'absolute',
    top: -6,
    right: -6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: pxToRem(24),
    height: pxToRem(24),
    color: palette.primary.contrastText,
    background: palette.primary.main,
    borderRadius,
    transition: transitions.create(),

    [breakpoints.down('md')]: {
      display: 'none'
    }
  },

  previewIndicatorIcon: {
    fontSize: pxToRem(14)
  },

  previewBackdrop: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.primary.contrastText,
    background: fade(palette.primary.main, 0.5),
    opacity: 0,
    cursor: 'pointer',
    transition: transitions.create(),

    '&:hover': {
      opacity: 1
    },

    [breakpoints.down('md')]: {
      display: 'none'
    }
  },

  name: {
    fontSize: pxToRem(11),
    color: palette.text.secondary
  }
});
