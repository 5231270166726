import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  Link,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/EditOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { stopPropagation } from '../../../../../helpers/dom';
import { getUserFullName } from '../../../../../helpers/users';
import { getUserOldName } from '../../../../../helpers/users/getUserOldName';
import { hasRole } from '../../../../../utils/hasRole';
import { fetchProfile, setProfileData } from '../../../../../store/dashboard/profile';
import { updateUserStorage } from '../../../../../store/globalUser/operations';
import { setUsersLastGlobalAction } from '../../../../../store/globalActions';
import * as insuranceApi from '../../../../../api/profile/insurances';
import * as profileApi from '../../../../../api/profile';
import * as userApi from '../../../../../api/users';
import { AddressLink } from '../../../../../components/AddressLink';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { Divider } from '../../../../../components/Divider';
import { ExpirationDate } from '../../../../../components/ExpirationDate';
import { Tooltip } from '../../../../../components/Tooltip';
import { DriverLicensesPreview } from '../../../../../components/users/DriverLicensesPreview';
import { ShortInsuranceInfo } from '../../../../../components/users/ShortInsuranceInfo';
import { InviteButton } from '../../../../../components/users/InviteButton';
import { useModal } from '../../../../../components/ModalsProvider';
import { UserSSN, Avatar } from '../../../../../components/users';
import { IconButton } from '../../../../../components/IconButton';
import { Contact } from '../../../../../components/Contact';
import { Date } from '../../../../../components/Date';
import { Fab } from '../../../../../components/Fab';
import { AddEmailModal } from '../../../../../components/users/AddEmailModal';
import { UserContext } from '../../../Members/User/UserProvider';
import * as types from '../../../Members/User/UserProvider/types';
import { AddInsuranceInfoModal } from '../../insurance';
import { ViewInsuranceInfoModal } from '../../insurance/ViewInsuranceInfoModal';
import { SipInfo } from '../SipInfo';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const UserInfo = ({ openUserInfo = false, toggleInfoOpen = () => {} }) => {
  const classes = useStyles();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const dispatch = useDispatch();
  const currentUser = useSelector(({ profile }) => profile);
  const { user } = userContext || currentUser;
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const storageInfo = useSelector(({ globalUser }) => globalUser?.data);
  const [ insurance, setInsurance ] = useState(user?.medical?.main_insurance || {});
  const mrn = user?.medical?.med_rec;

  const deleteInsurance = (id, callback = () => {}) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        insuranceApi.deleteInsurance(id).then(() => {
          enqueueSnackbar('Insurance deleted', { variant: 'success' });
          setInsurance({});
          callback();

          if (!userContext) {
            dispatch(setProfileData({
              medical: {
                ...user?.medical,
                main_insurance: null
              }
            }));
          }
        }).catch(() => {
          enqueueSnackbar('Insurance not deleted', { variant: 'error' });
        });
      }
    });
  };

  const openPreview = () => {
    openModal(ViewInsuranceInfoModal, {
      payload: {
        insurance,
        onEdit: setInsurance,
        onDelete: deleteInsurance
      }
    });
  };

  useEffect(() => {
    setInsurance(user?.medical?.main_insurance);
  }, [ user?.medical?.main_insurance ]);

  const addInsurance = () => {
    openModal(AddInsuranceInfoModal, {
      payload: { user, onEdit: setInsurance }
    });
  };

  const goEditUserPage = () => {
    userContext
      ? history.push(`/patients/${user.id}/edit`)
      : history.push('/profile/edit');
  };

  const handleUploadProfileAvatar = (formData) => {
    return profileApi.updateAvatar(formData).then((avatar) => {
      dispatch(setProfileData({ avatar }));
    });
  };

  const handleUploadAvatar = (formData) => {
    return userApi.uploadAvatar(formData).then((avatar) => {
      userApi.updateUser({ ...user, avatar_id: avatar.id }).then((data) => {
        const user = storageInfo?.users?.find(({ id }) => id === data?.id);

        user && dispatch(updateUserStorage({
          users: storageInfo?.users?.map((item) => (item.id === data?.id ? data : item))
        }));
        dispatch(setUsersLastGlobalAction({ type: types.SET_PROFILE_DATA, payload: data }));
      }).catch(({ data: { message } }) => {
        message && enqueueSnackbar(message, { variant: 'error' });
      });
    });
  };

  const fetchUser = () => {
    dispatch(fetchProfile());
  };

  const addEmail = () => {
    openModal(AddEmailModal, {
      payload: {
        userID: user.id
      },

      onModalResolved: (user) => {
        if (userContext) {
          userContext.updateUser(user);
        } else {
          dispatch(setProfileData(user));
        }
      }
    });
  };

  const openLicensesPreview = () => {
    openModal(DriverLicensesPreview, {
      payload: {
        user,
        front: user?.driver_license?.front?.url || null,
        back: user?.driver_license?.back?.url || null,
        onLicensesUpdate: userContext?.fetchUser || fetchUser
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify="space-between" alignItems="flex-start" className={classes.userHeader}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between" color="info.contrastText">
            {!openUserInfo && isMobile ? (
              <Typography variant="h4" color="inherit">{getUserFullName(user)}</Typography>
            ) : (
              <Typography variant="h6" color="inherit">{!!mrn && `MRN ${mrn}`}</Typography>
            )}

            <Box display="flex" alignItems="center">
              <IconButton color="inherit" onClick={goEditUserPage}>
                <EditIcon fontSize="small" />
              </IconButton>

              {isMobile && (
                <Box px={1}>
                  <Tooltip title="Toggle user info">
                    <IconButton
                      color="primary"
                      onClick={toggleInfoOpen}
                    >
                      {openUserInfo ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box position="relative">
            <div className={classes.avatar}>
              <Avatar
                user={user}
                onSubmit={userContext ? handleUploadAvatar : handleUploadProfileAvatar}
              />
            </div>
          </Box>
        </Grid>

        <Grid item xs={7} container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <Box color="info.contrastText" mb={2}>
              <Typography color="inherit" variant="h5">
                DL
              </Typography>
            </Box>

            <Box color="info.contrastText" mb={1}>
              <Typography color="inherit" variant="h5">
                EXP
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box color="info.contrastText" mb={2}>
              {user?.dl
                ? <Typography noWrap color="inherit" variant="h5">{user?.dl}</Typography>
                : <Typography noWrap color="textSecondary">No info.</Typography>
              }
            </Box>

            <Box color="info.contrastText" mb={1}>
              <Date disableIcon disableMoment date={user?.exp} variant="h5" color="inherit" />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        justify="space-between"
        alignItems="flex-start"
        component={Box}
        m="0 !important"
        width="100% !important"
      >
        <Grid item xs={7} />

        <Grid item xs={5}>
          <Box py={1}>
            <Fab variant="extended" color="primary" onClick={openLicensesPreview}>
              View ID
            </Fab>
          </Box>
        </Grid>
      </Grid>

      <Box pt={3} pl={isMobile ? 1 : 3} pr={1} pb={3}>
        <Grid container spacing={2} justify="space-between" alignItems="flex-start">
          <Grid item sm={6} xs={12} container spacing={1} className={classes.content}>
            <Grid item xs={4}>
              <Typography color="textSecondary">
                FN
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h5">
                {user?.first_name || '-'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">
                LN
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h5">
                {user?.last_name || '-'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">
                SSN
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <UserSSN
                isShowable={hasRole(rolesMap.admin, rolesMap.supervisor)}
                user={user}
                mr={-1.25}
                mt={-0.5}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">
                Pre.N
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h5" color="textSecondary">
                {getUserOldName(user) || '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item sm={6} xs={12} container spacing={1} alignItems="center">
            <Grid item xs={4}>
              <Typography color="textSecondary">
                Gender
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h5">
                {user.gender || '-'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">
                DOB
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h5">
                {user?.birthday ? moment(user.birthday).format('L') : '-'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary">
                Age
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h5">
                {user?.birthday ? moment().diff(moment(user.birthday), 'years') + ' y.o.' : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box pt={3} pl={isMobile ? 1 : 3} pr={1} pb={2}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={8}>
            <Typography variant="h5">
              Main insurance
            </Typography>
          </Grid>

          {!insurance?.id && (
            <Grid item xs={3}>
              <Fab
                size="small"
                color="primary"
                variant="extended"
                startIcon={<AddIcon />}
                onClick={addInsurance}
              >
                Add
              </Fab>
            </Grid>
          )}

          <Grid item xs={12}>
            {!!insurance?.id ?
              <ShortInsuranceInfo
                component={Link}
                insurance={insurance}
                onClick={stopPropagation(openPreview)}
              />
              :
              <Typography color="textSecondary">No info.</Typography>
            }
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">
              Effective day
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Date variant="h5" date={insurance?.effective_day} iconFontSize="small" />
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">
              Expiration day
            </Typography>
          </Grid>

          <Grid item xs={6}>
            {!insurance?.expiration_day ?
              <Typography color="textSecondary">No info.</Typography>
              :
              <Typography variant="h5">
                <ExpirationDate date={insurance?.expiration_day} />
              </Typography>
            }
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={4}>
            <Typography color="textSecondary">
              Address
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <AddressLink variant="h5" address={user?.home?.full_address}>
              {user?.home?.full_address || '-'}
            </AddressLink>
          </Grid>

          <Grid item xs={4}>
            <Typography color="textSecondary">
              Email
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
              <Contact noWrap variant="h5" type="mailto" contact={user?.email} />

              {user?.email ? (
                <InviteButton user={user} />
              ) : !userContext?.user?.email && (
                <Box m={1}>
                  <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={addEmail}
                  >
                    Add
                  </Fab>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Typography color="textSecondary">
              Prev. Email
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h5" color="textSecondary">
              {user.old_email || '-'}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography color="textSecondary">
              Phone
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Contact
              type="tel"
              variant="h5"
              contact={user?.phone_number}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography color="textSecondary">
              Mobile Phone
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Contact
              type="tel"
              variant="h5"
              contact={user?.mobile_phone_number}
            />
          </Grid>

          <SipInfo user={user} />
        </Grid>
      </Box>

      <Box px={isMobile ? 1 : 3} pt={2} pb={3}>
        <Typography className={classes.note}>
          {user?.home?.note || 'No info.'}
        </Typography>
      </Box>
    </div>
  );
};
