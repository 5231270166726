import { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Date } from '../../../../components/Date';
import { onAllImagesReady } from '../../../../helpers/dom';
import { emailsToString } from '../../../../helpers/emailsToString';
import { useResizeObserver } from '../../../../helpers/hooks';
import { Attachments } from '../Attachments';
import { SavedEmailsAttachments } from '../Attachments/SavedEmailsAttachments';
import { styles } from './styles';
import { ScheduleContent } from '../EmailsClient/EmailView/ScheduleContent';

const useStyles = makeStyles(styles);

export const EmailContent = ({ filter = {}, messageItem = {} }) => {
  const classes = useStyles();
  const [ height, setHeight ] = useState(0);
  const rootRef = useRef();
  const iframeRef = useRef();
  const { width } = useResizeObserver({ ref: rootRef });
  const to = emailsToString(messageItem?.to || []);
  const cc = emailsToString(messageItem?.cc || []);
  const bcc = emailsToString(messageItem?.bcc || []);
  const from = emailsToString(messageItem?.from || []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const adjustHeight = () => {
    const htmlElement = get(iframeRef, 'current.node.contentDocument.documentElement');
    const bodyElement = get(iframeRef, 'current.node.contentDocument.body');

    if (htmlElement && bodyElement) {
      htmlElement.style.overflow = 'hidden';
      bodyElement.style.overflow = 'hidden';
      bodyElement.style.margin = 0;
      setHeight(bodyElement.scrollHeight || 'auto');

      onAllImagesReady(bodyElement).then(() => {
        setHeight(bodyElement.scrollHeight || 'auto');
      });
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [ messageItem, width ]);

  return (
    <div ref={rootRef} className={classes.root}>
      <Typography variant="h2" className={classes.title}>{messageItem?.subject}</Typography>

      <Box mb={2}>
        {!!from &&
          <Box display="flex" alignItems="center" color="grey.400" mt={1} mb={0.5}>
            <Typography variant="subtitle2" color="inherit">From:</Typography>

            <Box ml={2} display="flex" alignItems="center">
              <Typography variant="subtitle2" color="textSecondary">{from}</Typography>

              {isMobile && (
                <Box ml={2}>
                  <Date variant="subtitle2" disableIcon date={messageItem?.date} format="L, LT" />
                </Box>
              )}
            </Box>
          </Box>
        }

        {!!to &&
          <Box display="flex" alignItems="center" color="grey.400" mb={0.5}>
            <Typography variant="subtitle2" color="inherit">To:</Typography>

            <Box ml={2}>
              <Typography variant="subtitle2" color="textSecondary">{to}</Typography>
            </Box>
          </Box>
        }

        {!!cc &&
          <Box display="flex" alignItems="center" color="grey.400" mb={0.5}>
            <Typography variant="subtitle2" color="inherit">CC:</Typography>

            <Box ml={2}>
              <Typography variant="subtitle2" color="textSecondary">{cc}</Typography>
            </Box>
          </Box>
        }

        {!!bcc &&
          <Box display="flex" alignItems="center" color="grey.400">
            <Typography variant="subtitle2" color="inherit">BCC:</Typography>

            <Box ml={2}>
              <Typography variant="subtitle2" color="textSecondary">{bcc}</Typography>
            </Box>
          </Box>
        }
      </Box>

      {!!messageItem?.attachments?.length &&
        <Attachments
          filter={filter}
          messageItem={messageItem}
          attachmentsLength={messageItem?.attachments?.length}
          attachments={messageItem?.attachments}
        />
      }

      {!!messageItem?.files?.length &&
        <SavedEmailsAttachments
          filter={filter}
          messageItem={messageItem}
          attachmentsLength={messageItem?.files?.length}
          attachments={messageItem?.files}
        />
      }

      <ScheduleContent
        messageItem={messageItem}
        schedule={messageItem?.calendar || []}
      />

      <Frame
        ref={iframeRef}
        width="100%"
        height={height}
        frameBorder="0"
        className={classes.body}
      >
        <FrameContextConsumer>
          {({ document }) => {
            document.querySelectorAll('a').forEach((a) => {
              if (a.target !== '_blank') {
                a.setAttribute('target', '_blank');
              }
            });

            return <div dangerouslySetInnerHTML={{ __html: messageItem?.body }} />;
          }}
        </FrameContextConsumer>
      </Frame>
    </div>
  );
};
