import moment from 'moment';
import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as appointmentTypesApi from '../../../../../../api/codes/appointments/appointment-types';
import { Loader } from '../../../../../../components/Loader';
import {
  Page,
  PageBody,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../../../components/Page';
import { ApptTypeForm } from '../ApptTypeForm';
import { AvailableApptBooks } from '../AvailableApptBooks';
import { validationSchema } from '../validationSchema';

export const Edit = ({ baseUrl }) => {
  const history = useHistory();
  const params = useParams();
  const [ appointmentType, setAppointmentType ] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const duration = moment.duration(appointmentType?.time || 0, 'seconds');

  const updateAppointmentType = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return appointmentTypesApi.updateAppointmentType({
      ...values,

      time: moment.duration(`${values._hours || 0}:${values._minutes || 0}`).asSeconds() || null
    }).then(() => {
      history.push(`${baseUrl}/appointment-types`);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  useEffect(() => {
    const fetchAppointmentType = () => {
      appointmentTypesApi.fetchAppointmentType(params.id)
        .then(setAppointmentType)
        .catch(() => {
          history.push(`${baseUrl}/appointment-types`);
        });
    };

    fetchAppointmentType();
  }, []);

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        ...(appointmentType || {}),

        _hours: duration.hours() || null,
        _minutes: duration.minutes() || null
      }}
      onSubmit={updateAppointmentType}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Page component="form" noValidate onSubmit={handleSubmit}>
          <PageHeader>
            <IconButton
              component={Link}
              to={`${baseUrl}/appointment-types`}
              color="primary"
              edge="start"
            >
              <KeyboardBackspaceOutlinedIcon />
            </IconButton>

            <PageHeaderTitle>
              {isMobile ? 'Edit appt. type' : 'Edit appointment type'}
            </PageHeaderTitle>

            <PageHeaderActions>
              <Button
                component={Link}
                size={isMobile ? 'small' : 'medium'}
                to={`${baseUrl}/appointment-types`}
                color="primary"
              >
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    size={isMobile ? 'small' : 'medium'}
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </PageHeaderActions>
          </PageHeader>

          <PageBody>
            <Loader loading={!appointmentType} render={
              () => (
                <>
                  <ApptTypeForm />
                  <AvailableApptBooks formFieldName="appointment_books" />
                </>
              )}
            />
          </PageBody>
        </Page>
      )}
    </Formik>
  );
};
