export const fieldsKeysMap = {
  search: 'search',
  dateFrom: 'from',
  dateTo: 'to',
  dorFrom: 'dor_from',
  dorTo: 'dor_to',
  type: 'type',
  icdId: 'icd_id',
  providerId: 'provider_id',
  patients: 'patients',
  officeId: 'office_id',
  name: 'name',
  formType: 'form_type',
  treatingPhysicianId: 'treating_physician_id',
  users: 'users',
  insuranceId: 'insuranceId',
  fromDoi: 'from_doi',
  toDoi: 'to_doi',
  orderBy: 'order_by'
};

export const filterLabels = {
  [fieldsKeysMap.name]: 'Name',
  [fieldsKeysMap.search]: 'Patient Name',
  [fieldsKeysMap.patients]: 'Patients',
  [fieldsKeysMap.dateFrom]: 'Date From',
  [fieldsKeysMap.dorFrom]: 'DOR From',
  [fieldsKeysMap.icdId]: 'Diagnosis(DX)',
  [fieldsKeysMap.providerId]: 'Provider',
  [fieldsKeysMap.officeId]: 'Office',
  [fieldsKeysMap.type]: 'Type of med.report',
  [fieldsKeysMap.dorTo]: 'DOR To',
  [fieldsKeysMap.dateTo]: 'Date To',
  [fieldsKeysMap.formType]: 'Form Type',
  [fieldsKeysMap.treatingPhysicianId]: 'Physician',
  [fieldsKeysMap.users]: 'Patients',
  [fieldsKeysMap.insuranceId]: 'Insurance ID',
  [fieldsKeysMap.fromDoi]: 'From DOI',
  [fieldsKeysMap.toDoi]: 'To DOI',
  [fieldsKeysMap.orderBy]: 'Order By'
};
