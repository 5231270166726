import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { stopPropagation } from '../../../../../../helpers/dom';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { ColorPreview } from '../../../../../../components/ColorPreview';
import { IconButton } from '../../../../../../components/IconButton';
import { UserLink } from '../../../../../../components/UserLink';
import { Contact } from '../../../../../../components/Contact';
import { Popper } from '../../../../../../components/Popper';
import { Date } from '../../../../../../components/Date';
import { Menu } from '../../Menu';
import {
  minRowHeight,
  columnsWidths,
  widthBreakpointSmall,
  widthBreakpointMedium,
  widthBreakpointLarge
} from '../List';

export const Row = ({
  width,
  item,
  time,
  waitingTime,
  onAppointmentPreviewOpen
}) => {
  return (
    <ListRow
      button
      minHeight={minRowHeight}
      onClick={stopPropagation(onAppointmentPreviewOpen)}
    >
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <UserLink
          noWrap
          size="md"
          variant="h5"
          user={item.patient}
        >
          <Box mt={-0.5} display="flex" alignItems="center" wrap="nowrap">
            <Typography color="textSecondary" variant="caption">
              {item?.patient?.birthday
                ? moment().diff(moment(item?.patient?.birthday), 'years') + ' y.o.'
                : '0 y.o.'
              }
            </Typography>

            {!!item?.patient_appointment_details?.med_rec && (
              <>
                <VerticalDivider verticalGutters={0.5} horizontalGutters={0.5} />

                <Typography noWrap color="textSecondary" variant="caption">
                  MRN {item?.patient_appointment_details?.med_rec}
                </Typography>
              </>
            )}
          </Box>
        </UserLink>
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.phone}>
        <Contact
          needIcon
          type="tel"
          contact={item?.patient?.phone}
        />
      </ListRowCell>

      <ListRowCell grow={width < widthBreakpointSmall} flexBasis={columnsWidths.location}>
        <UserLink
          noWrap
          size="md"
          variant="h5"
          user={item.appointment_book?.provider}
        >
          <Typography noWrap color="textSecondary" variant="caption">
            {item?.appointment_book?.office?.name || 'No info'}
          </Typography>
        </UserLink>
      </ListRowCell>

      <ListRowCell actions flexBasis={columnsWidths.appt}>
        <Date date={item?.appointment_at} format="L, LT" />
      </ListRowCell>

      {width > widthBreakpointLarge &&
        <ListRowCell column flexBasis={columnsWidths.duration}>
          {!item?.appointment_type ? '-' : (
            <Box display="flex" alignItems="center">
              <ColorPreview color={item?.appointment_type?.color} />

              <Box component="span" ml={0.5}>
                <Typography noWrap variant="h5">
                  {item?.appointment_type?.code}
                </Typography>
              </Box>
            </Box>
          )}

          <Typography noWrap>
            {moment.duration(item.time, 'seconds').format('h[h] m[min]')}
          </Typography>
        </ListRowCell>
      }

      {width > widthBreakpointMedium &&
        <ListRowCell noWrap grow flexBasis={columnsWidths.notes}>
          {item?.note || <Typography noWrap variant="caption" color="textSecondary">No info</Typography>}
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.waitingTime}>
        <Typography noWrap color={time < 0 ? 'error' : 'textPrimary'}>
          {waitingTime}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <Menu appointment={item} onClose={handleClose} />
          )}
        </Popper>
      </ListRowCell>
    </ListRow>
  );
};
