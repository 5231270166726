import { Formik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery } from '@material-ui/core';
import ListAddIcon from '@material-ui/icons/PlaylistAdd';
import * as taskTemplatesApi from '../../../../../api/cases/task-templates';
import { Loader } from '../../../../../components/Loader';
import { ModalContainer, ModalBody, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { TaskTemplateForm, initialValues, validationSchema } from '../TaskTemplateForm';

export const CreateTaskTemplateModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { enqueueSnackbar } = useSnackbar();

  const addTask = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const estimated_time = moment.duration(`${values.hours || 0}:${values.minutes || 0}`).asSeconds();
    const due_at_interval = moment.duration(values.due_at_interval, 'days').asSeconds();

    return taskTemplatesApi.createTaskTemplate({
      ...values,

      due_at_interval: due_at_interval || null,
      estimated_time: estimated_time || null
    }).then((template) => {
      enqueueSnackbar('Template successfully created', { variant: 'success' });
      handleModalResolve(template);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Template not created', { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={addTask}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog fullScreen={isMobile} maxWidth="sm" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<ListAddIcon />} onClose={handleModalReject}>
              Create Task Template
            </ModalHeader>

            <ModalBody>
              <TaskTemplateForm />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
