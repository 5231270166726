import { useParams } from 'react-router-dom';
import { MainContent } from './MainContent';
import { UserProvider } from './UserProvider';

export const User = () => {
  const params = useParams();

  return (
    <UserProvider userID={params.userID}>
      <MainContent />
    </UserProvider>
  );
};
