export const transcribeStatusesMap = {
  pending: 'pending',
  done: 'done',
  failed: 'failed'
};

export const transcribeStatusLabelsMap = {
  [transcribeStatusesMap.pending]: 'Pending',
  [transcribeStatusesMap.done]: 'Done',
  [transcribeStatusesMap.failed]: 'Failed'
};

export const transcribeStatusOptions = [
  {
    value: transcribeStatusesMap.pending,
    label: transcribeStatusLabelsMap.pending
  },
  {
    value: transcribeStatusesMap.done,
    label: transcribeStatusLabelsMap.done
  },
  {
    value: transcribeStatusesMap.failed,
    label: transcribeStatusLabelsMap.failed
  }
];
