import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Button, Collapse, makeStyles } from '@material-ui/core';
import { TextField } from '../../../../../components/FormField';
import { styles } from '../../Checklist/EditForm/styles';

const useStyles = makeStyles(styles);

export const EditForm = ({
  isEditable,
  listItem,
  setIsEditable,
  onUpdate
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const disabled = !values?.updatedText || values?.updatedText?.trim() === listItem?.text.trim();

  const handleFormReset = () => {
    setFieldValue('updatedText', null);
    setIsEditable(false);
  };

  const handleUpdateItem = () => {
    setIsEditable(false);
    onUpdate(listItem, values.updatedText);
  };

  useEffect(() => {
    if (listItem) {
      setFieldValue('updatedText', listItem.text);
    }
  }, [ listItem ]);

  return (
    <Collapse in={isEditable} className={classes.root}>
      <div className={classes.form}>
        <TextField
          autoFocus
          multiline
          name="updatedText"
          placeholder="Add item"
          variant="outlined"
          margin="dense"
        />
      </div>

      <div className={classes.actions}>
        <Button
          size="small"
          className={classes.cancelButton}
          onClick={handleFormReset}
        >
          Cancel
        </Button>

        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handleUpdateItem}
        >
          Save
        </Button>
      </div>
    </Collapse>
  );
};
