export const styles = ({ spacing, palette, transitions }) => ({
  expanded: {
    paddingBottom: spacing(3)
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(),
    borderBottom: `1px solid ${palette.divider}`,
    cursor: 'pointer',
    transition: transitions.create([ 'backgroundColor' ]),

    '&:hover': {
      backgroundColor: palette.grey[50]
    }
  },

  body: {
    display: 'block',
    height: 340,
    overflow: 'hidden',
    padding: 0,
    backgroundColor: palette.grey[50]
  }
});
