import { useSelector } from 'react-redux';
import { Switch, useParams } from 'react-router-dom';
import { Route } from '../../../../components/router';
import { Page } from '../../../../components/Page';
import {
  ReportsContext,
  ReportsProvider
} from '../../../../components/medical/reports';
import { StepsComponent } from '../StepsComponent';
import { Consultation } from './Consultation';
import { MainContent } from './MainContent';
import { FormWrapper } from './FormWrapper';

export const Diagnosis = () => {
  const params = useParams();
  const { user } = useSelector(({ profile }) => profile);

  const filter = {
    patient_id: params?.userID || params.patientID || user?.id
  };

  return (
    <Page>
      <ReportsProvider paginateByPage filter={filter}>
        <ReportsContext.Consumer>
          {({ pathname }) => (
            <Switch>
              <Route exact path={pathname} component={MainContent}/>

              <FormWrapper>
                <Route exact path={`${pathname}/:reportID/edit`} component={Consultation}/>
                <Route exact path={`${pathname}/:reportID/first-step`} component={StepsComponent}/>
                <Route exact path={`${pathname}/:reportID/second-step`} component={StepsComponent}/>
                <Route exact path={`${pathname}/:reportID/third-step`} component={StepsComponent}/>
                <Route exact path={`${pathname}/:reportID/fourth-step`} component={StepsComponent}/>
                <Route exact path={`${pathname}/:reportID/fifth-step`} component={StepsComponent}/>
                <Route exact path={`${pathname}/:reportID/six-step`} component={StepsComponent}/>
              </FormWrapper>
            </Switch>
          )}
        </ReportsContext.Consumer>
      </ReportsProvider>
    </Page>
  );
};
