import { useContext, useRef } from 'react';
import { makeStyles, List as MuiList, Typography, Paper, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Sticky } from '../../../../../components/Sticky';
import { TasksContext } from '../../../../../components/tasks/TasksProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 65;
export const columnsWidths = {
  name: 280,
  parent: 120,
  status: 100,
  type: 140,
  progress: 160,
  activity: 120,
  assigned: 215,
  priority: 80,
  action: 60
};

export const List = ({ isTabPage, addTask }) => {
  const classes = useStyles();
  const {
    tasks,
    isFetching,
    isFetched,
    loadNextPage,
    filter: { per_page, last_page, page, total }
  } = useContext(TasksContext);
  const scrollElementRef = useRef();

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader loading={!isFetched} className={classes.loader} render={
        () => (
          <>
            {isTabPage &&
              <div className={classes.actionContent}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addTask}
                >
                  <AddIcon className={classes.icon}/> Add new task
                </Button>
              </div>
            }

            {(!tasks.length || total === 0) &&
              <Typography align="center" className={classes.contentMessage}>No tasks found</Typography>
            }

            {(!!tasks.length || total > 0) &&
              <Paper elevation={0}>
                <Sticky containerElement={scrollElementRef.current}>
                  <TableHeader isTabPage={!!isTabPage} tasks={tasks} addTask={addTask}/>
                </Sticky>

                <MuiList disablePadding>
                  <InfiniteListLoader
                    items={tasks}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    pagination={{ per_page, last_page, page }}
                    RowComponent={Row}
                    RowProps={{ isTabPage }}
                    onNeedNextPage={loadNextPage}
                    scrollElementRef={scrollElementRef}
                    minRowHeight={minRowHeight}
                  />
                </MuiList>
              </Paper>
            }
          </>
        )}
      />
    </CustomScrollbars>
  );
};
