import { Yup } from '../../../../../../utils/validation';
import { validationSchema as CPTValidationSchema } from '../validationSchema';

export const scheduleValidationSchema = CPTValidationSchema.shape({
  schedule: Yup.object().shape({
    payer_id: Yup.mixed().nullable().when('is_all_payers', {
      is: (value) => !value,
      then: Yup.mixed().nullable().required(),
      otherwise: Yup.string()
    }),
    charge_amount: Yup.string().nullable().required(),
    default_units: Yup.mixed().nullable().required(),
    expected_amount: Yup.string().nullable().required(),
    modifier_id: Yup.mixed().nullable().required(),
    work_rvu: Yup.string().nullable().required().max(255),
    moldx_code: Yup.string().nullable().required().max(255),
    dx_code: Yup.string().nullable().required().max(255),
    total_rvu: Yup.string().nullable().required().max(255)
  })
});
