import { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { BodyPartsContext } from '../BodyPartsContext';
import { Front } from './Front';
import { Back } from './Back';
import { styles } from './styles';
const useStyles = makeStyles(styles);

export const Body = () => {
  const classes = useStyles();
  const { painfulParts, addPainfulPart, setSelectedPart } = useContext(BodyPartsContext);

  const handleClick = (part) => () => {
    if (!painfulParts[part]) {
      addPainfulPart(part);
    }

    setSelectedPart(part);
  };

  return (
    <div className={classes.container}>
      <Front onClick={handleClick} />
      <Back onClick={handleClick} />
    </div>
  );
};
