import { Yup } from '../../../../utils/validation';

export const currentSymptomsValidationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    current_symptoms: Yup.object().shape({
      describe_circumstances_pain: Yup.string().nullable().max(1000),
      how_often_your_pain: Yup.string().nullable(),
      where_is_pain: Yup.mixed().required(),
      how_long_until_the_pain_interferes: Yup.object().shape({
        sit: Yup.number().nullable(),
        stand: Yup.number().nullable(),
        walk: Yup.number().nullable()
      }),
      have_you_avoided_strenuous_lifting: Yup.boolean(),
      following_factors_your_pain: Yup.object().shape({
        heat: Yup.string().nullable(),
        cold: Yup.string().nullable(),
        lying_down: Yup.string().nullable(),
        sitting: Yup.string().nullable(),
        walking: Yup.string().nullable(),
        climate: Yup.string().nullable(),
        fatigue: Yup.string().nullable(),
        coughing: Yup.string().nullable(),
        alcohol: Yup.string().nullable()
      }).notRequired()
    })
  })
});
