import { createHookReducer } from '../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.INVOICES_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.INVOICES_FETCH_SUCCESS]: (
    { filter, invoices, selectedInvoicesIDs, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      selectedInvoicesIDs: [],
      filter: { ...filter, page, per_page },
      invoices: data
    };
  },

  [types.ADD_INVOICE]: (state, invoice) => {
    const { pagination, filter, invoices, ...otherState } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((invoices.length + 1) / filter.per_page) - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...otherState,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page: page === 0 ? 1 : page },
      invoices: [ invoice, ...invoices ]
    };
  },

  [types.UPDATE_INVOICE_IN_LIST]: (
    { filter, invoices, ...state },
    invoice
  ) => {
    return {
      ...state,

      filter,
      invoices: invoices.map((item) => {
        return item.id === invoice.id ? invoice : item;
      })
    };
  },

  [types.DELETE_INVOICE]: ({ pagination, filter, invoices, ...state }, invoiceId) => {
    const total = pagination.total - 1;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      pagination: { ...pagination, total, last_page },
      invoices: invoices.filter((invoice) => invoice.id !== invoiceId)
    };
  },

  [types.MASS_DELETE_INVOICES]: ({ pagination, filter, selectedInvoicesIDs, invoices, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedInvoicesIDs: [],
      pagination: { ...pagination, total, last_page },
      invoices: invoices.filter((invoice) => IDs.indexOf(invoice.id) < 0)
    };
  },

  [types.TOGGLE_ALL_INVOICES_SELECTION]: ({ invoices, selectedInvoicesIDs, ...state }) => {
    const allInvoicesIsSelected = invoices.length === selectedInvoicesIDs.length;

    return {
      ...state,

      invoices,
      selectedInvoicesIDs: allInvoicesIsSelected ? [] : invoices.map((item) => item.id)
    };
  },

  [types.TOGGLE_INVOICE_SELECTION]: ({ selectedInvoicesIDs, ...state }, id) => {
    return {
      ...state,

      selectedInvoicesIDs: selectedInvoicesIDs.indexOf(id) !== -1 ?
        selectedInvoicesIDs.filter((item) => item !== id)
        :
        selectedInvoicesIDs.concat(id)
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_INVOICES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      invoices: [],
      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  }
});
