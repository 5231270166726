import { useContext } from 'react';
import { Box } from '@material-ui/core';
import { LayoutContext, viewVariantsMap } from '../../LayoutContext';
import { FiltersBar } from './FiltersBar';
import { Grid } from './Grid';
import { List } from './List';

export const CallReports = ({
  disableFiltersBar = false,
  hiddenColumns,
  filterKey,
  isTablet = false,
  hiddenFields = [],
  filterBarWrapperProps = {},
  ScrollWrapper = <></>,
  FilterBarWrapper = <></>
}) => {
  const { viewVariant } = useContext(LayoutContext);
  const scrollWrapperProps = !isTablet ? {} : {
    autoHeight: true,
    autoHeightMax: '100%'
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%" overflow="hidden">
      {!disableFiltersBar && (
        <ScrollWrapper {...scrollWrapperProps}>
          <FilterBarWrapper {...filterBarWrapperProps}>
            <FiltersBar filterKey={filterKey} hiddenFields={hiddenFields} />
          </FilterBarWrapper>
        </ScrollWrapper>
      )}

      <Box flexGrow={1} pt={viewVariantsMap.grid ? 0 : 2}>
        {isTablet || viewVariant === viewVariantsMap.grid
          ? <Grid />
          : viewVariant === viewVariantsMap.list
            ? <List hiddenColumns={hiddenColumns} />
            : null
        }
      </Box>
    </Box>
  );
};
