import { useContext, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { TablePagination } from '../../../../../components/TablePagination';
import { Loader } from '../../../../../components/Loader';
import { Cards } from '../../../../../components/Cards';
import { UsersContext } from '../../UsersContext';
import { Card } from './Card';

export const Grid = () => {
  const {
    users,
    isFetched,
    meta,
    resetUsers
  } = useContext(UsersContext);
  const scrollElementRef = useRef();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={isTablet ? 1 : 0}>
        <Loader loading={!isFetched} p={3} render={
          () => !users.length ? (
            <Typography align="center">No users found</Typography>
          ) : (
            <>
              <Cards size={isTablet ? 280 : 300} list={users} component={Card} />
              <TablePagination pagination={meta} onChange={resetUsers} />
            </>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
