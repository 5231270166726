import { createContext, useEffect, useReducer, useRef } from 'react';
import { isEqual, omit } from 'lodash';
import { api } from '../../../../api';
import * as accountsApi from '../../../../api/login-queues/accounts';
import { usePrevious } from '../../../../helpers/hooks';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const LoginAccountsContext = createContext(null);

export const LoginAccountsProvider = ({ children, service }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const {
    loginAccounts,
    pagination,
    isFetching,
    isFetched,
    filter
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});

  const fetchLoginAccounts = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.LOGIN_ACCOUNTS_FETCH_REQUEST });

    accountsApi.fetchAccounts({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.LOGIN_ACCOUNTS_FETCH_SUCCESS, payload: data });
    });
  };

  const addLoginAccount = (loginAccount) => {
    dispatch({ type: types.ADD_LOGIN_ACCOUNT, payload: loginAccount });
  };

  const deleteLoginAccount = (loginAccount) => {
    dispatch({ type: types.DELETE_LOGIN_ACCOUNT, payload: loginAccount });
  };

  const updateLoginAccount = (loginAccount) => {
    dispatch({ type: types.UPDATE_LOGIN_ACCOUNT, payload: loginAccount });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchLoginAccounts({ page: filter.page + 1 });
    }
  };

  const resetLoginAccounts = (newFilter) => {
    dispatch({ type: types.RESET_LOGIN_ACCOUNTS, payload: newFilter });

    fetchLoginAccounts({ page: 1, ...newFilter });
  };

  const providerValue = {
    loginAccounts,
    isFetched,
    isFetching,
    service,
    meta: {
      ...filter,
      ...pagination
    },
    fetchLoginAccounts,
    addLoginAccount,
    updateLoginAccount,
    deleteLoginAccount,
    resetLoginAccounts,
    loadNextPage
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      fetchLoginAccounts(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <LoginAccountsContext.Provider value={providerValue}>
      {children}
    </LoginAccountsContext.Provider>
  );
};
