export const tracksTypesMap = {
  work: 'work',
  break: 'break',
  lunch: 'lunch'
};

export const tracksTypesLabelsMap = {
  [tracksTypesMap.work]: 'Work',
  [tracksTypesMap.break]: 'Break',
  [tracksTypesMap.lunch]: 'Lunch'
};
