import { useContext } from 'react';
import { stopPropagation } from '../../../../../../helpers/dom';
import { CardHeader, CardItem } from '../../../../../../components/Cards';
import { Divider } from '../../../../../../components/Divider';
import { useModal } from '../../../../../../components/ModalsProvider';
import { PreviewCompanyModal } from '../../PreviewCompanyModal';
import { CompaniesContext } from '../../CompaniesContext';
import { CompaniesMenu } from '../../CompaniesMenu';
import { companiesMap } from '../../List';
import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';

export const Card = ({ item: company, hiddenColumns, onCompanySelect }) => {
  const { openModal } = useModal();
  const { selectedIDs, toggleItemSelection } = useContext(CompaniesContext);
  const isSelected = selectedIDs.indexOf(company.id) !== -1;
  const cardHeaderProps = !hiddenColumns.includes(companiesMap.actions) ? {
    menuComponent: CompaniesMenu,
    menuComponentProps: { company }
  } : {
    disableCardMenu: true
  };

  const openPreview = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: company?.id }
    });
  };

  const handleItemSelection = () => {
    if (onCompanySelect) {
      onCompanySelect(company);
    } else {
      toggleItemSelection(company.id);
    }
  };

  return (
    <CardItem selected={isSelected} onClick={stopPropagation(openPreview)}>
      <CardHeader
        px={2}
        pt={2}
        alignItems="flex-start"
        isSelected={isSelected}
        onItemSelect={handleItemSelection}
        {...cardHeaderProps}
      >
        <Header company={company}/>
      </CardHeader>

      <Divider gutter={1.5} disableBottomGutter />
      <Body company={company} />
      <Footer company={company} />
    </CardItem>
  );
};
