import moment from 'moment';
import { Yup } from '../../../utils/validation';

export const validationSchema = Yup.object().shape({
  model: Yup.string().nullable().required(),
  model_id: Yup.mixed().nullable().required(),
  started_at: Yup.date().format('X').nullable().required(),
  finished_at: Yup
    .date()
    .format('X')
    .nullable()
    .required()
    .test('maxDate', 'Should not be more than now', (value) => !value || moment(value).isSameOrBefore(moment()))
    .when('started_at', (startedAt, schema) => {
      const startMomentDate = moment(startedAt);

      return startMomentDate.isValid()
        ? schema.min(startMomentDate.add(1, 'minute'), 'Must be after the start date')
        : schema;
    }),
  description: Yup.string().nullable().max(1000),
  billing_time: Yup.number().nullable().min(60).max(600000),
  category_id: Yup.mixed().nullable(),
  isAdmin: Yup.bool().nullable(),
  user_id: Yup.mixed().nullable().when('isAdmin', (isAdmin, schema) => {
    return isAdmin ? schema.required() : schema;
  })
});
