import { Box, Grid, FormControlLabel, InputAdornment } from '@material-ui/core';
import { Checkbox, ColorPicker, NumberMaskField, TextField } from '../../../../../../components/FormField';

export const ScheduleEventTypeForm = () => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <TextField
          required
          name="name"
          label="Name"
          placeholder="Enter type name..."
        />
      </Grid>

      <Grid item sm={12}>
        <TextField
          required
          multiline
          rows={5}
          variant="filled"
          name="description"
          label="Description"
          placeholder="Enter description..."
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <NumberMaskField
          name="_hours"
          label="Hours"
          placeholder="Enter hours..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">h</InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid xs={6} item sm={4}>
        <NumberMaskField
          required
          name="_minutes"
          label="Minutes"
          placeholder="Enter minutes..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">min</InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item xs={6} sm={4}>
        <ColorPicker
          required
          name="color"
          label="Color"
          placeholder="Select color..."
        />
      </Grid>

      <Box clone alignSelf="center">
        <Grid item sm={4}>
          <FormControlLabel
            control={<Checkbox name="is_active" />}
            label="Is active"
          />
        </Grid>
      </Box>
    </Grid>
  );
};
