import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../List';

export const ListHeader = () => {
  return (
    <ListRow header>
      <ListRowCell noWrap flexBasis={columnsWidths.invoiceNumber}>
        Invoice number
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.users}>
        Users
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.companies}>
        Companies
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.case}>
        Case
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateFrom}>
        Date From
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.dateTo}>
        Date To
      </ListRowCell>

      <ListRowCell noWrap flexBasis={columnsWidths.method}>
        Method
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action} />
    </ListRow>
  );
};
