import { makeStyles } from '@material-ui/core';
import { Grid } from './Grid';
import { FiltersBar } from './FiltersBar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainWindow = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FiltersBar/>

      <div className={classes.videoWindow}>
        <Grid/>
      </div>
    </div>
  );
};
