import { api } from '../../api';

export const getApptTypeById = (config) => {
  return api.get('/appointment-types', config)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => { throw error; });
};

export const fetchAppointmentType = (id, config) => {
  return api.get(`/appointment-types/${id}`, config)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const createAppointmentType = (data) => {
  return api.post('/appointment-types', data)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};

export const updateAppointmentType = ({ id, ...data }) => {
  return api.put(`/appointment-types/${id}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => { throw error; });
};

export const deleteAppointmentType = (id) => {
  return api.delete(`/appointment-types/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error; });
};
