import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { get } from 'lodash';
import { Link, makeStyles } from '@material-ui/core';
import { CurrencyFormat } from '../../../../../../../../components/CurrencyFormat';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths, minRowHeight, widthBreakpointXXS } from '../FilteredMainContent';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Row = ({ track, width }) => {
  const classes = useStyles();

  return (
    <ListRow
      minHeight={minRowHeight}
      className={classes.root}
    >
      <ListRowCell grow noWrap flexBasis={columnsWidths.client}>
        {!!get(track, 'models.case') ?
          <Link
            noWrap
            target="_blank"
            component={RouterLink}
            to={`/cases/${get(track, 'models.case.id')}`}
            title={get(track, 'models.case.name')}
            className={classes.userInfoLink}
          >
            {get(track, 'models.case.name')}
          </Link>
          :
          '-'
        }
      </ListRowCell>

      {width > widthBreakpointXXS &&
        <ListRowCell flexBasis={columnsWidths.date}>
          {moment.unix(track.started_at).format('L')}
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.hours}>
        {moment.unix(track.started_at).format('HH:mm')}
      </ListRowCell>

      <ListRowCell grow noWrap flexBasis={columnsWidths.parent}>
        {!!get(track, 'models.task.name') ?
          <Link
            noWrap
            target="_blank"
            component={RouterLink}
            to={`/tasks/${get(track, 'models.task.id')}`}
            title={get(track, 'models.task.name')}
            className={classes.userInfoLink}
          >
            {get(track, 'models.task.name')}
          </Link>
          :
          '-'
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        <CurrencyFormat value={track.total || 0}/>
      </ListRowCell>
    </ListRow>
  );
};
