import { useFormikContext } from 'formik';
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Typography } from '@material-ui/core';
import {
  insuranceFormTypesMap
} from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/insuranceFormTypesMap';
import { CurrencyFormat } from '../../../CurrencyFormat';
import { InsurancesSelect, RadioGroup, Radio, CurrencyField } from '../../../FormField';
import { formTypesMap } from '../../../medical/forms';

export const InsuranceDetails = () => {
  const { values, setFieldValue } = useFormikContext();
  const statusType = values?.form_type
    ? (values?.form_type === formTypesMap.private ? 'primary' : 'law_office')
    : null;

  const handleInsuranceChange = (insurance) => {
    const copayOptionToPriceMap = {
      PCP: insurance?.pcp || 0,
      SPC: insurance?.spc || 0
    };

    setFieldValue('price', copayOptionToPriceMap[values.copay_type] || values.price);
    setFieldValue('patient_insurance_pcp', copayOptionToPriceMap.PCP);
    setFieldValue('patient_insurance_spc', copayOptionToPriceMap.SPC);
    setFieldValue('insurance_details.company', insurance?.insurance_company || null);
  };

  const handleCopayOptionChange = (event, newValue) => {
    const copayOptionToPriceMap = {
      PCP: values.patient_insurance_pcp || 0,
      SPC: values.patient_insurance_spc || 0
    };

    setFieldValue('price', copayOptionToPriceMap[newValue]);
  };

  return (
    <>
      <Box mt={4} mb={1} color="info.main">
        <Typography gutterBottom variant="h3" color="inherit">
          Insurance Details
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <InsurancesSelect
            name="patient_insurance_id"
            user={values.patient_appointment_details}
            params={{
              only_active: 1,
              type_insurance: values?.form_type !== formTypesMap.private
                ? insuranceFormTypesMap?.[values?.form_type]
                : null,
              status_type: statusType,
              medical_id:
                values?.patient_appointment_details?.profile?.medical?.id ||
                values?.patient_appointment_details?.medical?.id ||
                values?.patient_appointment_details?.medical_id ||
                values?.medical_id
            }}
            onChange={handleInsuranceChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          {values.patient_insurance_id ? (
            <FormControl required fullWidth component="fieldset">
              <FormLabel component="legend">Copay option</FormLabel>

              <RadioGroup row name="copay_type" onChange={handleCopayOptionChange}>
                <FormControlLabel
                  value="PCP"
                  label={<>PCP, <CurrencyFormat value={values.patient_insurance_pcp} /></>}
                  control={<Radio name="copay_type" color="primary" />}
                />

                <FormControlLabel
                  value="SPC"
                  label={<>SPC, <CurrencyFormat value={values.patient_insurance_spc} /></>}
                  control={<Radio name="copay_type" color="primary" />}
                />
              </RadioGroup>
            </FormControl>
          ) : (
            <CurrencyField required name="price" label="Copay Price" />
          )}
        </Grid>
      </Grid>
    </>
  );
};
