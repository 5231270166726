import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import printJS from 'print-js';
import {
  Box,
  Dialog,
  FormControlLabel,
  SvgIcon,
  Switch,
  Tooltip,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  Grid
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { mdiTextBoxCheckOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import {
  FormPreviewModal
} from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/FormPreviewModal';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import * as reportsApi from '../../../../api/profile';
import { api } from '../../../../api';
import { Date } from '../../../Date';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderActions
} from '../../../Modal';
import { useModal } from '../../../ModalsProvider';
import { IconButton } from '../../../IconButton';
import { Loader } from '../../../Loader';
import { previewTitleMap, reportTypesMap } from '../diagnosis';
import { InitialReport } from './InitialReport';
import { Signature } from './Signature';
import { FollowUp } from './FollowUp';
import { Header } from './Header';

export const PreviewReportModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { reportID, pathname, onDelete, onUpdate = () => {} }
}) => {
  const history = useHistory();
  const [ report, setReport ] = useState(null);
  const cancelFetch = useRef(() => {});
  const [ isOpenSwitches, setIsOpenSwitches ] = useState(true);
  const { openModal } = useModal();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const openFormPreviewModal = () => {
    openModal(FormPreviewModal, {
      payload: {
        formID: report?.medical_form?.id
      }
    });
  };

  const handleSwitchChange = (event) => {
    setIsOpenSwitches(event.target.checked);
  };

  const handleDeleteReport = () => {
    onDelete(report.id, handleModalResolve);
  };

  const handleUpdateReport = () => {
    if (report.type === reportTypesMap.new) {
      history.push(pathname + `/${reportID}/first-step`);
    } else {
      history.push(pathname + `/${reportID}/edit`);
    }

    handleModalReject();
  };

  const handleDownloadPDF = () => {
    reportsApi.generatePDFDiagnosisReport(reportID).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      saveAs(url, `${previewTitleMap[report.type] || reportTypesMap.new }.pdf`);
    });
  };

  const handlePrintReport = () => {
    reportsApi.generatePDFDiagnosisReport(reportID).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      printJS({ printable: url, type: 'pdf' });
    });
  };

  useEffect(() => {
    cancelFetch.current();

    reportsApi.fetchDiagnosisReport(reportID, {
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then(setReport);

    return ()  => {
      cancelFetch.current();
    };
  }, []);

  return (
    <Dialog fullScreen={isTablet} maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader
          icon={<SvgIcon><Icon path={mdiTextBoxCheckOutline} /></SvgIcon>}
          onClose={handleModalReject}
        >
          View Initial Report

          {!isTablet && (
            <ModalHeaderActions disableGutters>
              <FormControlLabel
                label="Open all info"
                control={
                  <Switch
                    color="primary"
                    checked={isOpenSwitches}
                    onChange={handleSwitchChange}
                  />
                }
              />

              {hasRole(rolesMap.doctor, rolesMap.admin, rolesMap.medic, rolesMap.supervisor) &&
                <Tooltip arrow title="Edit Report">
                  <IconButton
                    color="primary"
                    onClick={handleUpdateReport}
                  >
                    <EditIcon/>
                  </IconButton>
                </Tooltip>
              }

              <Tooltip arrow title="Download Report">
                <IconButton
                  color="primary"
                  onClick={handleDownloadPDF}
                >
                  <DownloadIcon/>
                </IconButton>
              </Tooltip>

              <Tooltip arrow title="Print Report">
                <IconButton
                  color="primary"
                  onClick={handlePrintReport}
                >
                  <PrintIcon/>
                </IconButton>
              </Tooltip>

              {!hasRole(rolesMap.client, rolesMap.patient, rolesMap.medic) &&
                <Tooltip arrow title="Delete Report">
                  <IconButton
                    color="error"
                    onClick={handleDeleteReport}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </Tooltip>
              }
            </ModalHeaderActions>
          )}
        </ModalHeader>

        <Loader p={3} loading={!report} render={
          () => (
            <ModalBody disablePaddings={isTablet}>
              <Box mb={3}>
                {isTablet && (
                  <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={1}>
                    <FormControlLabel
                      label="Open all info"
                      control={
                        <Switch
                          color="primary"
                          checked={isOpenSwitches}
                          onChange={handleSwitchChange}
                        />
                      }
                    />

                    {hasRole(rolesMap.doctor, rolesMap.admin, rolesMap.medic, rolesMap.supervisor) &&
                      <Tooltip arrow title="Edit Report">
                        <IconButton
                          color="primary"
                          onClick={handleUpdateReport}
                        >
                          <EditIcon/>
                        </IconButton>
                      </Tooltip>
                    }
                  </Box>
                )}

                <Box px={isTablet ? 2 : 0} mb={!isTablet ? 2 : 0}>
                  <Header report={report} />
                </Box>

                {report?.medical_form?.id && (
                  <Grid
                    container
                    spacing={2}
                    component={Box}
                    px={2}
                    width="100% !important"
                    mx="0 !important"
                  >
                    <Grid item md={2} sm={3} xs={12}>
                      <Typography variant="h5">Medical form:</Typography>
                    </Grid>

                    <Grid item md={4} sm={9} xs={12}>
                      <Typography component={Link} variant="h5" onClick={openFormPreviewModal}>
                        {report?.medical_form?.form_type}
                      </Typography>
                    </Grid>

                    <Grid item md={2} sm={3} xs={6}>
                      <Typography>Created at:</Typography>
                    </Grid>

                    <Grid item md={4} sm={9} xs={6}>
                      <Date date={report?.medical_form?.created_at} />
                    </Grid>
                  </Grid>
                )}

                {report.type === reportTypesMap.new ?
                  <InitialReport
                    report={report}
                    isOpenSwitches={isOpenSwitches}
                  />
                  :
                  <FollowUp
                    report={report}
                    isOpenSwitches={isOpenSwitches}
                  />
                }
              </Box>

              <Signature report={report} setReport={setReport} onUpdate={onUpdate} />
            </ModalBody>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
