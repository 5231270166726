import { flow } from 'lodash';
import { SavedFiltersProvider } from '../SavedFiltersProvider';
import { SavedFiltersWidget } from '../SavedFiltersWidget';

export const IconComponent = ({
  ListComponent,
  CardContent,
  filterKey,
  modalWidth,
  hiddenFields,
  relationsForFilter,
  onApplySavedFilter,
  onOpenModal
}) => {
  return (
    <SavedFiltersProvider filter={{ entity: filterKey }}>
      <SavedFiltersWidget
        filterKey={filterKey}
        CardContent={CardContent}
        handleOpenModal={onOpenModal}
        onApplySavedFilter={onApplySavedFilter}
        relationsForFilter={relationsForFilter}
        modalWidth={modalWidth}
      >
        {({ handleClose }) => (
          <ListComponent hiddenColumns={hiddenFields} onApplySavedFilter={flow(onApplySavedFilter, handleClose)} />
        )}
      </SavedFiltersWidget>
    </SavedFiltersProvider>
  );
};
