import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { Box, Button, Dialog } from '@material-ui/core';
import * as chatsApi from '../../../api/chat';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { ConfirmationModal } from '../../ConfirmationModal';
import { TextField } from '../../FormField';
import { Loader } from '../../Loader';
import { useModal } from '../../ModalsProvider';
import { UsersSelect } from '../../users';
import { ChatUsersList } from '../UpdateChatMembersModal/ChatUsersList';

export const UpdateChatModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: { thread }
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ chatUsers, setChatUsers ] = useState(thread.users);
  const [ newChatUsers, setNewChatUsers ] = useState([]);
  const currentUser = useSelector(({ profile }) => profile.user);
  const isCreator = currentUser.id === thread.user.id;

  const changeNewUsers = (users) => {
    setNewChatUsers(users);
  };

  const deleteChatUsers = (user) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        setChatUsers((users) => users.filter(({ id }) => id !== user.id));
      }
    });
  };

  const updateChat = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const updatedChatUsers = uniqBy([ ...chatUsers, ...newChatUsers ], 'id');

    const updatedThread = {
      id: thread.id,
      name: values.name,
      description: values.description,
      users: updatedChatUsers,
      user: {
        id: currentUser.id
      }
    };

    return chatsApi.updateThread(updatedThread).then((data) => {
      enqueueSnackbar('Chat successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      enqueueSnackbar('Something goes wrong!', { variant: 'error' });

      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Dialog PaperProps={{ style: { maxWidth: 1100 } }} {...DialogProps}>
      <Formik
        enableReinitialize
        initialValues={{
          name: thread.name,
          description: thread.description,
          users: []
        }}
        onSubmit={updateChat}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ModalContainer
            component="form"
            onSubmit={handleSubmit}
          >
            <ModalHeader icon={<CreateOutlinedIcon />} onClose={handleModalReject}>
              Edit chat
            </ModalHeader>

            <ModalBody>
              <TextField
                name="name"
                label="Name"
                placeholder="Case chat"
              />

              <TextField
                multiline
                rows={8}
                name="description"
                label="Description"
                margin="dense"
                variant="filled"
                helperText="What`s this channel about?"
                InputProps={{
                  disableUnderline: true
                }}
              />

              <UsersSelect
                multiple
                name="users"
                label="Invite people"
                hiddenOptions={chatUsers}
                onChange={changeNewUsers}
              />

              {!!chatUsers?.length &&
                <Box mt={2}>
                  <ChatUsersList
                    isCreator={isCreator}
                    currentThread={thread}
                    users={chatUsers}
                    handleUserDelete={deleteChatUsers}
                  />
                </Box>
              }
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
