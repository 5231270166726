import { Button, Dialog } from '@material-ui/core';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../../../../../../components/Modal';
import {
  AppointmentBooksContext,
  AppointmentBooksProvider
} from '../../../../AppointmentBooks/AppointmentBooksContext';
import { List } from './List';

export const AddBooksModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const assignBooks = (books) => () => {
    handleModalResolve(books);
  };

  return (
    <Dialog maxWidth="md" {...DialogProps}>
      <AppointmentBooksProvider>
        <AppointmentBooksContext.Consumer>
          {({ selectedBooks }) => (
            <ModalContainer>
              <ModalHeader onClose={handleModalReject}>
                Select books to assign
              </ModalHeader>

              <ModalBody disablePaddings disableScrollbars>
                <List/>
              </ModalBody>

              <ModalFooter>
                <Button onClick={handleModalReject}>Cancel</Button>

                <Button
                  disabled={!selectedBooks?.length}
                  variant="contained"
                  color="primary"
                  onClick={assignBooks(selectedBooks)}
                >
                  Assign
                </Button>
              </ModalFooter>
            </ModalContainer>
          )}
        </AppointmentBooksContext.Consumer>
      </AppointmentBooksProvider>
    </Dialog>
  );
};
