import { Box, List as MuiList, Typography } from '@material-ui/core';
import { useContext, useEffect, useRef } from 'react';
import { AppointmentsContext, AppointmentsFilterProvider } from '../../../appointments';
import { InfiniteListLoader } from '../../../InfiniteLoader';
import { Loader } from '../../../Loader';
import { ScrollbarsCustom } from '../../../ScrollbarsCustom';
import { Row  } from './Row';

export const List = ({ period, applyFilter, width, hiddenColumns, RowProps = {} }) => {
  const {
    isFetched,
    isFetching,
    appointments,
    meta,
    loadNextPage,
    filter,
    reloadAppointments
  } = useContext(AppointmentsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    reloadAppointments();
  }, []);

  useEffect(() => {
    applyFilter({ newPeriod: period });
  }, []);

  return (
    <AppointmentsFilterProvider filter={filter}>
      <ScrollbarsCustom scrollElementRef={scrollElementRef}>
        <Box height="100%" p={1}>
          <Loader p={2} loading={!isFetched} render={
            () => !meta.total ? (
              <Box p={2}>
                <Typography align="center">No appointments found</Typography>
              </Box>
            ) : (
              <MuiList disablePadding>
                <InfiniteListLoader
                  items={appointments}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={meta}
                  RowComponent={Row}
                  RowProps={{ hiddenColumns, ...RowProps, width }}
                  onNeedNextPage={loadNextPage}
                  scrollElementRef={scrollElementRef}
                />
              </MuiList>
            )}
          />
        </Box>
      </ScrollbarsCustom>
    </AppointmentsFilterProvider>
  );
};
