import * as materialColors from '@material-ui/core/colors';

export const grey = {
  25: '#FBFBFB',
  50: '#F7F7F7',
  100: '#F0F0F0',
  200: '#E0E0E0',
  300: '#CECECE',
  400: '#C4C4C4',
  500: '#797979',
  600: '#4C4C4C',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  A100: '#d5d5d5',
  A200: '#aaaaaa',
  A400: '#303030',
  A700: '#616161'
};

export const mainColors = {
  primary: {
    main: materialColors.cyan[500],
    dark: materialColors.cyan[700],
    light: materialColors.cyan[300],
    contrastText: '#fff'
  },

  secondary: {
    main: materialColors.teal[500],
    dark: materialColors.teal[700],
    light: materialColors.teal[300],
    contrastText: '#fff'
  },

  success: {
    main: materialColors.lightGreen[500],
    dark: materialColors.lightGreen[700],
    light: materialColors.lightGreen[300],
    contrastText: '#fff'
  },

  info: {
    main: materialColors.blue[500],
    dark: materialColors.blue[700],
    light: materialColors.blue[300],
    contrastText: '#fff'
  },

  warning: {
    main: materialColors.amber[500],
    dark: materialColors.amber[700],
    light: materialColors.amber[300],
    contrastText: '#fff'
  },

  white: {
    main: '#fff'
  },

  error: {
    main: '#DA495B'
  }
};

export const palette = ({ background, text = {}, ...palette } = {}) => ({
  ...mainColors,

  divider: grey[200],

  background,
  text,
  grey,

  slategray: '#697689',
  lightgreen: '#48D2A0',
  darkgreen: materialColors.teal[500],
  indigo: materialColors.indigo[500],
  deeporange: '#FF5722',
  purple: '#9C27B0',
  pink: materialColors.pink[500],
  lightPurple: '#C584D0',
  violet: '#673AB7',
  lightorange: '#FF6900',

  ...palette
});
