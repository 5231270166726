import { CasesSelect } from '../../cases/CasesSelect';
import { TasksSelect } from '../../FormField/selects/TasksSelect';
import { ScheduleEventsField } from '../../FormField/selects/ScheduleEventsField';

export const tracksTypes = {
  case: {
    value: 'case',
    label: 'Case',
    select: CasesSelect,
    transform: (caseItem) => caseItem
  },
  task: {
    value: 'task',
    label: 'Task',
    select: TasksSelect,
    transform: (task) => task
  },
  schedule_event: {
    value: 'schedule_event',
    label: 'Schedule Event',
    select: ScheduleEventsField,
    transform: (event) => event && ({ ...event, label: event?.name })
  }
};

export const tracksTypesOptions = [
  {
    value: tracksTypes.case.value,
    label: tracksTypes.case.label
  },
  {
    value: tracksTypes.task.value,
    label: tracksTypes.task.label
  },
  {
    value: tracksTypes.schedule_event.value,
    label: tracksTypes.schedule_event.label
  }
];
