import { accidentTypesTitles } from '../../accidentTypes';

export const carAccidentOrtho = {
  type: accidentTypesTitles.car_accident,
  were_you_the_driver_passenger_front_or_back: null,
  were_you_the_driver_description: null,
  were_you_driving_or_a_passenger_for_uber_or_lyft: null,
  did_you_go_to_an_emergency_or_urgent_care_after_the_accident: null,
  if_so_when: null,
  what_did_they_do: null,
  were_you_prescribed_any_medications: null,
  was_a_police_report_filed: null,
  briefly_explain_how_the_accident_occurred: null
};

export const slipAndFallOrtho = {
  type: accidentTypesTitles.slip_and_fall,
  where_did_the_accident_occur: null,
  was_a_police_report_filed: null,
  did_you_go_to_an_emergency_or_urgent_care_after_the_accident: null,
  if_so_when: null,
  what_did_they_do: null,
  were_you_prescribed_any_medications: null,
  briefly_explain_how_the_accident_occurred: null
};

export const otherOrtho = {
  type: accidentTypesTitles.other,
  text: null,
  describe_the_circumstances_around_the_onset_of_your_pain: null
};

export const generateAccidentInitialOrthoValues = (form) => ({
  forms: !!form?.forms?.accident_information_ortho ? {
    accident_information_ortho: {
      doi: form.doi,
      ...form?.forms?.accident_information_ortho,
      car_accident: form?.accident_type === accidentTypesTitles.car_accident ? {
        were_you_the_driver_passenger_front_or_back:
          form?.forms?.accident_information_ortho?.car_accident?.were_you_the_driver_passenger_front_or_back
          || null,
        were_you_the_driver_description:
          form?.forms?.accident_information_ortho?.car_accident?.were_you_the_driver_description
          || null,
        were_you_driving_or_a_passenger_for_uber_or_lyft:
          form?.forms?.accident_information_ortho?.car_accident?.were_you_driving_or_a_passenger_for_uber_or_lyft
          || null,
        did_you_go_to_an_emergency_or_urgent_care_after_the_accident:
        // eslint-disable-next-line max-len
          form?.forms?.accident_information_ortho?.car_accident?.did_you_go_to_an_emergency_or_urgent_care_after_the_accident
          || null,
        if_so_when:
          form?.forms?.accident_information_ortho?.car_accident?.if_so_when
          || null,
        what_did_they_do:
          form?.forms?.accident_information_ortho?.car_accident?.what_did_they_do
          || null,
        were_you_prescribed_any_medications:
          form?.forms?.accident_information_ortho?.car_accident?.were_you_prescribed_any_medications
          || null,
        was_a_police_report_filed:
          form?.forms?.accident_information_ortho?.car_accident?.was_a_police_report_filed
          || null,
        briefly_explain_how_the_accident_occurred:
        // eslint-disable-next-line max-len
          form?.forms?.accident_information_ortho?.car_accident?.briefly_explain_how_the_accident_occurred
          || null
      } : null,
      slip_and_fall: form?.accident_type === accidentTypesTitles.slip_and_fall ? {
        where_did_the_accident_occur:
          form?.forms?.accident_information_ortho?.slip_and_fall?.where_did_the_accident_occur
          || null,
        was_a_police_report_filed:
          form?.forms?.accident_information_ortho?.slip_and_fall?.was_a_police_report_filed
          || null,
        did_you_go_to_an_emergency_or_urgent_care_after_the_accident:
        // eslint-disable-next-line max-len
          form?.forms?.accident_information_ortho?.slip_and_fall?.did_you_go_to_an_emergency_or_urgent_care_after_the_accident
          || null,
        if_so_when:
          form?.forms?.accident_information_ortho?.slip_and_fall?.if_so_when
          || null,
        what_did_they_do:
          form?.forms?.accident_information_ortho?.slip_and_fall?.what_did_they_do
          || null,
        were_you_prescribed_any_medications:
          form?.forms?.accident_information_ortho?.slip_and_fall?.were_you_prescribed_any_medications
          || null,
        briefly_explain_how_the_accident_occurred:
        // eslint-disable-next-line max-len
          form?.forms?.accident_information_ortho?.slip_and_fall?.briefly_explain_how_the_accident_occurred
          || null
      } : null,
      other: form?.accident_type === accidentTypesTitles.other ? {
        text: form?.forms?.accident_information_ortho?.other?.text || null,
        describe_the_circumstances_around_the_onset_of_your_pain:
        // eslint-disable-next-line max-len
          form?.forms?.accident_information_ortho?.other?.describe_the_circumstances_around_the_onset_of_your_pain
          || null
      } : null
    }
  } : {
    accident_information_ortho: {
      car_accident: form?.accident_type === accidentTypesTitles.car_accident ? carAccidentOrtho : null,
      slip_and_fall: form?.accident_type === accidentTypesTitles.slip_and_fall ? slipAndFallOrtho : null,
      other: form?.accident_type === accidentTypesTitles.other ? otherOrtho : null
    }
  }
});
