export const styles = ({
  spacing,
  palette: { grey, getContrastText },
  typography: { pxToRem, fontWeightMedium }
}) => ({
  root: {
    backgroundColor: ({ color = grey[500] }) => color,
    color: ({ color }) => getContrastText(color || grey[100]),
    borderRadius: 50,
    padding: spacing(0, 1),
    minWidth: 52,
    textAlign: 'center',
    fontWeight: fontWeightMedium,
    fontSize: pxToRem(13),
    marginLeft: spacing(),
    marginRight: spacing()
  }
});
