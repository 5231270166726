import { Yup } from '../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  patient_id: Yup.mixed().nullable().required(),
  appointment_book_id: Yup.mixed().nullable().required(),
  treating_physician_id: Yup.mixed().nullable().required(),
  appointment_type_id: Yup.mixed().nullable().required(),
  appointment_date: Yup.mixed().nullable().required(),
  appointment_time: Yup.mixed().nullable().required(),
  _hours: Yup.number().integer().positive().nullable().typeError('Must be a number'),
  _minutes: Yup
    .number()
    .integer()
    .nullable()
    .typeError('Must be a number')
    .when('_hours', (hours, schema) => {
      return hours ? schema : schema.required().min(15);
    }),
  note: Yup.string().nullable().max(1000)
});
