import cn from 'classnames';
import T from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  disableAlignSelf: T.bool,
  verticalGutters: T.number,
  horizontalGutters: T.number
};

export const VerticalDivider = ({
  className,
  disableAlignSelf,
  verticalGutters = 0,
  horizontalGutters = 0,
  ...props
}) => {
  const classes = useStyles({ disableAlignSelf, verticalGutters, horizontalGutters });

  return (
    <div className={cn(classes.root, className)} {...props} />
  );
};

VerticalDivider.propTypes = propTypes;
