import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { columnsWidths } from '../MainContent';

export const TableHeader = () => {
  return (
    <ListRow header px={1}>
      <ListRowCell grow flexBasis={columnsWidths.client}>
        Adjuster
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.billable}>
        Billable
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.unbillable}>
        Unbillable
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.total}>
        Total
      </ListRowCell>
    </ListRow>
  );
};

