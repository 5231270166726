import { Typography } from '@material-ui/core';
import * as caseApi from '../../../../api/cases';
import { Autocomplete } from '../../index';

const fetchBodyCodes = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return caseApi.fetchCodesBodyParts({
    params: {
      search,
      page: page + 1,
      active: 1,

      ...params
    }
  }).then(({ data, pagination }) => {

    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => {
  return (
    <Typography>{option?.code} - {option?.description}</Typography>
  );
};

export const BodyPartsSelect = ({ params = {}, creatablePayload, ...props }) => {
  return (
    <Autocomplete
      isAsync
      label="Body part"
      placeholder="Search part..."
      onNeedFetch={fetchBodyCodes(params)}
      renderOption={renderOption}
      getOptionLabel={((option) => option && `${option?.code} - ${option?.description}`)}
      getOptionValue={(option) => option?.code}
      getOptionSelected={(option, value) => option?.code === value?.code}

      {...props}
    />
  );
};
