import { useEffect } from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import { columnsWidths } from '../List';

export const Row = ({
  item: template = {},
  isLoaded,
  recalculateHeight,
  selectedTemplate,
  setSelectedTemplate
}) => {
  const handleClickOnRow = () => {
    setSelectedTemplate(template);
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return !isLoaded ? <Loader p={2} /> : (
    <ListRow button px={2} selected={selectedTemplate.id === template.id} onClick={handleClickOnRow}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <Typography noWrap title={template.name}>
          {template.name}
        </Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.type}>
        {template.type}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.isSystem}>
        {template.is_system ? 'Yes' : 'No'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {moment.unix(template.created_at).format('L LT')}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        {moment.unix(template.updated_at).format('L LT')}
      </ListRowCell>
    </ListRow>
  );
};
