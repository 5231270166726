import { Box, Grid, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import { Autocomplete, TextField } from '../../../FormField';
import { noteReferenceCodesOptions } from './data';

export const ClaimNote = () => {
  return (
    <>
      <Box mb={1}>
        <Typography variant="h3">
          MISC (Claim Note)
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Autocomplete
            name="note_reference_code"
            label="Note Reference Code"
            options={noteReferenceCodesOptions}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box display="flex" alignItems="center" mt={2} mb={0.5} color="text.secondary">
            <Box display="flex" mr={1}>
              <NotesIcon fontSize="small" color="inherit" />
            </Box>

            <Typography color="inherit">Note</Typography>
          </Box>

          <TextField
            multiline
            disableUnderline
            rows={3}
            rowsMax={8}
            variant="filled"
            name="note"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Autocomplete
            name="note_reference_code"
            label="Note Reference Code"
            options={noteReferenceCodesOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};
