import { useState } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@material-ui/core';
import {
  MedicalFormsSelect
} from '../../../FormField';
import { UsersSelect } from '../../../users';
import { formTypesMap } from '../formTypesMap';
import { FormInfo } from './FormInfo';

export const MedicalFormContent = () => {
  const { values, setFieldValue } = useFormikContext();
  const [ form, setForm ] = useState(null);

  const handleChangeMedicalForm = (option) => {
    setForm(option?.data || null);
    setFieldValue('doi', option?.data?.doi || null);
    setFieldValue('treating_physician_id', option?.data?.treating_physician);
  };

  return (
    <Box pb={2}>
      <MedicalFormsSelect
        cacheUniq={values.patient_id}
        params={{
          medical_id: values?.medical_id,
          users: values?.patient_id ?  [ values?.patient_id || values?.patient_id?.id ] : null
        }}
        TextFieldProps={{ required: true, margin: 'dense' }}
        onChange={handleChangeMedicalForm}
      />

      <UsersSelect
        required={values.medical_form === formTypesMap.follow_up}
        name="treating_physician_id"
        label="Physician"
        margin="dense"
        params={{ role: 'doctor' }}
      />

      <FormInfo form={form} />
    </Box>
  );
};
