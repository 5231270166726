import { useEffect, useContext, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../../../components/InfiniteLoader';
import { CustomScrollbars } from '../../../../../../../components/CustomScrollbars';
import { AppointmentsContext } from '../../../../../../../components/appointments';
import { Row } from './Row';

export const List = ({ isOnlySelectedAppointment, selectedAppointment, onAppointmentSelect }) => {
  const {
    appointments,
    isFetched,
    isFetching,
    loadNextPage,
    meta,
    reloadAppointments
  } = useContext(AppointmentsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    reloadAppointments();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader p={2} loading={!isFetched} render={
        () => !meta.total || isOnlySelectedAppointment ? (
          <Box p={2}>
            <Typography>Appointments not found</Typography>
          </Box>
        ) : (
          <MuiList disablePadding>
            <InfiniteListLoader
              items={appointments}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={meta}
              RowComponent={Row}
              RowProps={{ selectedAppointment, onAppointmentSelect }}
              scrollElementRef={scrollElementRef}
              onNeedNextPage={loadNextPage}
            />
          </MuiList>
        )}
      />
    </CustomScrollbars>
  );
};
