import { generatePath, Link, useLocation, useParams } from 'react-router-dom';
import { makeStyles, Paper, Tab, Tabs } from '@material-ui/core';
import { Trash } from '../../../../FilesPage/Trash';
import { FilesPage } from '../../../../files-common/FilesPage';
import { Route } from '../../../../../../components/router';
import { apiModelsMap } from '../../../../../../dataMaps/apiModels';
import { columnsMap } from '../../../../files-common/FilesList/listConfig';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const tabsRoutes = {
  files: '/cases/:caseID/files',
  recycleBin: '/cases/:caseID/files/recycle-bin'
};

export const Files = ({ caseItem }) => {
  const location = useLocation();
  const classes = useStyles();
  const params = useParams();

  return (
    <Paper elevation={0} variant="outlined" className={classes.root}>
      <Tabs value={location.pathname}>
        <Tab
          component={Link}
          value={generatePath(tabsRoutes.files, params)}
          to={generatePath(tabsRoutes.files, params)}
          label="Files"
        />

        <Tab
          component={Link}
          value={generatePath(tabsRoutes.recycleBin, params)}
          to={generatePath(tabsRoutes.recycleBin, params)}
          label="Recycle Bin"
        />
      </Tabs>

      <Route exact path={tabsRoutes.files}>
        <FilesPage
          owner={caseItem}
          ownerType={apiModelsMap.case}
          ownerID={caseItem.id}
          filter={{
            cases: [ caseItem.id ]
          }}
          hiddenColumns={[ columnsMap.owner ]}
        />
      </Route>

      <Route path={tabsRoutes.recycleBin}>
        <Trash
          ownerType={apiModelsMap.case}
          ownerID={caseItem.id}
        />
      </Route>
    </Paper>
  );
};
