import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { flow } from 'lodash';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import * as depositionsApi from '../../../../../../../../../api/cases/depositions';
import { ConfirmationModal } from '../../../../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import { CaseDepositionsContext } from '../../CaseDepositionsProvider';
import { EditDepositionModal } from '../../EditDespositionModal/EditDespositionModal';

export const Menu = ({ deposition, onClose = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const { caseID, updateDeposition, deleteDeposition } = useContext(CaseDepositionsContext);

  const handleDepositionUpdate = () => {
    openModal(EditDepositionModal, {
      payload: {
        caseID,
        deposition
      },
      onModalResolved: (data) => {
        updateDeposition(data);
        onClose();
      }
    });
  };

  const handleDepositionDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        depositionsApi.deleteCaseDepositions(caseID, deposition.id).then(() => {
          deleteDeposition(deposition);
          enqueueSnackbar('Case deposition successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Case deposition not deleted', { variant: 'error' });
        });
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={handleDepositionUpdate}>
        <ListItemIcon>
          <EditIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={flow(handleDepositionDelete, onClose)}>
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
