import cn from 'classnames';
import { makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const AttachmentIndicator = ({ icon, title, className, classes: classesProp = {} }) => {
  const classes = useStyles();

  return(
    <div className={cn(classes.root, className, classesProp.root)}>
      <SvgIcon className={cn(classes.icon, classesProp.icon)}>{icon}</SvgIcon>

      <Typography className={cn(classes.title, classesProp.title)}>
        {title}
      </Typography>
    </div>
  );
};
