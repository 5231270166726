import { useState } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import { AppointmentCreateModal, arrivalTypesMap, SelectApptModal } from '../../../appointments';
import { ProcedureCreateModal } from '../../../appointments/procedures';
import { Fab } from '../../../Fab';
import { IconButton } from '../../../IconButton';
import { useModal } from '../../../ModalsProvider';
import { SvgIcon } from '../../../SvgIcon';
import { ContentCard } from '../../../ContentCard';
import ProcedureCodesSvg from '../../../icons/procedure-codes.svg';
import { Tooltip } from '../../../Tooltip';
import { handleAppointmentChange } from '../handleAppointmentChange';
import { Appointment } from './Appointment';

export const AppointmentSection = () => {
  const { values: { patient, office, appointment }, setFieldValue } = useFormikContext();
  const [ isCollapsed, setIsCollapsed ] = useState(false);
  const { openModal } = useModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleCollapsed = () => {
    setIsCollapsed((state) => !state);
  };

  const addProcedure = () => {
    openModal(ProcedureCreateModal, {
      payload: {
        initialValues: {
          appointment_id: appointment.id
        }
      },

      onModalResolved: (procedure) => {
        setFieldValue('appointment', {
          ...appointment,

          procedures: (appointment.procedures || []).concat(procedure)
        });
      }
    });
  };

  const handleAppointmentSelected = (appointment) => {
    handleAppointmentChange(appointment, { setFieldValue });
  };

  const addAppointment = () => {
    openModal(SelectApptModal, {
      payload: {
        patientID: patient?.id,
        filter: {
          has_claims: 0,
          appointment_to: moment().unix(),
          arrival_types: [ arrivalTypesMap.arrived ],
          relations: [ 'medicalProcedures' ]
        }
      },

      onModalResolved: (selectedAppt) => {
        if (selectedAppt) {
          handleAppointmentSelected(selectedAppt);
        } else {
          openModal(AppointmentCreateModal, {
            payload: {
              initialValues: {
                patient,
                patient_id: patient,
                patient_appointment_details: { patient, ...patient },
                patient_insurance_id: patient?.medical?.main_insurance,
                office_id: office
              }
            },
            onModalResolved: (appointment) => {
              handleAppointmentSelected(appointment);
            }
          });
        }
      }
    });
  };

  return (
    <ContentCard
      disableScrollbars
      variant="outlined"
      color="info"
      isCollapsed={!patient || isCollapsed}
      title="Appointment & Procedures"
      leftActions={[
        <Box mr={1}>
          <SvgIcon color="info"><ProcedureCodesSvg /></SvgIcon>
        </Box>
      ]}
      rightActions={[
        !!appointment && (
          <Box mr={1}>
            {!isMobile ? (
              <Fab
                variant="extended"
                color="primary"
                startIcon={<AddIcon />}
                onClick={addProcedure}
              >
                Add procedure
              </Fab>
            ) : (
              <IconButton color="primary" onClick={addProcedure}>
                <AddIcon />
              </IconButton>
            )}
          </Box>
        ),

        !appointment && (
          <Box mr={1}>
            <Tooltip
              title={patient ? 'Add appointment' : 'Select patient firstly'}
            >
              <div>
                {!isMobile ? (
                  <Fab
                    disabled={!patient}
                    variant="extended"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={addAppointment}
                  >
                    Add appointment
                  </Fab>
                ) : (
                  <IconButton disabled={!patient} color="primary" onClick={addAppointment}>
                    <AddIcon />
                  </IconButton>
                )}
              </div>
            </Tooltip>
          </Box>
        ),

        <IconButton disabled={!patient} edge="end" color="info" onClick={toggleCollapsed}>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      ]}
    >
      <Box display="flex" flexDirection="column">
        {appointment ? (
          <Appointment appointment={appointment} />
        ) : (
          <Typography align="center">Appointment not added</Typography>
        )}
      </Box>
    </ContentCard>
  );
};
