import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {
  Checkbox,
  Radio as MuiRadio,
  RadioGroup,
  TextField
} from '../../../../../../../components/FormField';

export const MainContent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display="flex" flexDirection="column" p={isSmallScreen ? 1 : 2} pt={0} overflow="hidden">
      <Grid
        container
        spacing={isSmallScreen ? 2 : 3}
        component={Box}
        alignItems="center"
        justify="flex-start"
        py={2}
        m="0 !important"
      >
        <Grid item xs={12}>
          <Typography paragraph variant="h4" color="secondary">
            Patient Comfort Assesment Guid
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h5">
            1. Where is your primary pain location?
          </Typography>
        </Grid>

        <Grid item lg={6} xs={11}>
          <TextField name="forms.where_is_your_primary_pain_location" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">
            2. Check <u>two words</u> that best describe your pain
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" justifyContent="space-around" px={2}>
            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Aching</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.circle_two_words_that_best_describe_your_pain.aching"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Sharp</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.circle_two_words_that_best_describe_your_pain.sharp"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Dull</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.circle_two_words_that_best_describe_your_pain.dull"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Nagging</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.circle_two_words_that_best_describe_your_pain.nagging"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Shooting</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.circle_two_words_that_best_describe_your_pain.shooting"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Burning</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.circle_two_words_that_best_describe_your_pain.burning"
                  />
                }
              />
            </Box>

            <Box component="span" mr={2}>
              <FormControlLabel
                label={<Typography>Stabbing</Typography>}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    name="forms.circle_two_words_that_best_describe_your_pain.stabbing"
                  />
                }
              />
            </Box>
          </Box>
        </Grid>

        <Grid item>
          <Typography variant="h5">
            3. What makes the pain <u>worse</u>?
          </Typography>
        </Grid>

        <Grid item lg={9} xs={11}>
          <TextField name="forms.what_makes_the_pain_worse" />
        </Grid>

        <Grid item lg={4} md={6} xs={10}>
          <FormLabel component="legend">
            4. How has your sleep been?
          </FormLabel>
        </Grid>

        <Grid item lg={3} md={6} xs={12}>
          <RadioGroup name="forms.how_has_your_sleep_been" style={{ flexDirection: 'row' }}>
            <FormControlLabel
              value="disturbed"
              label={<Typography>Disturbed</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.how_has_your_sleep_been"
                />
              }
            />

            <FormControlLabel
              value="not_disturbed"
              label={<Typography>Not Disturbed</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.how_has_your_sleep_been"
                />
              }
            />
          </RadioGroup>
        </Grid>

        <Grid item lg={3} xs={6}>
          <Box pl={2}>
            <FormLabel component="legend">
              Awaken with pain?
            </FormLabel>
          </Box>
        </Grid>

        <Grid item lg={2} xs={6}>
          <RadioGroup name="forms.awaken_with_pain" style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.awaken_with_pain"
                />
              }
            />

            <FormControlLabel
              value="no"
              label={<Typography>No</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.awaken_with_pain"
                />
              }
            />
          </RadioGroup>
        </Grid>

        <Grid item>
          <Typography variant="h5">
            5. What makes your pain better?
          </Typography>
        </Grid>

        <Grid item lg={8} xs={11}>
          <Box pl={2}>
            <TextField name="forms.what_makes_your_pain_better" />
          </Box>
        </Grid>

        <Grid item lg={4} md={6} xs={10}>
          <FormLabel component="legend">
            6. Do you have any <u>new</u> areas of pain?
          </FormLabel>
        </Grid>

        <Grid item lg={2} md={6} xs={6}>
          <RadioGroup name="forms.do_you_have_any_new_areas_of_pain" style={{ flexDirection: 'row' }}>
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.do_you_have_any_new_areas_of_pain"
                />
              }
            />

            <FormControlLabel
              value="no"
              label={<Typography>No</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.do_you_have_any_new_areas_of_pain"
                />
              }
            />
          </RadioGroup>
        </Grid>

        <Grid item>
          <Box pl={2}>
            <Typography variant="h5">
              Where?
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={5} xs={11}>
          <Box pl={2}>
            <TextField name="forms.where" />
          </Box>
        </Grid>

        <Grid item>
          <Box pl={2}>
            <Typography variant="h5">
              How long have you had the new pain?
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={6} xs={11}>
          <Box pl={2}>
            <TextField name="forms.how_long_have_you_had_the_new_pain" />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">
            7. Constipation?
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <RadioGroup name="forms.constipation" style={{ flexDirection: 'row' }}>
            <FormControlLabel
              value="Mild"
              label={<Typography>Mild</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.constipation"
                />
              }
            />

            <FormControlLabel
              value="Moderate"
              label={<Typography>Moderate</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.constipation"
                />
              }
            />

            <FormControlLabel
              value="Severe"
              label={<Typography>Severe</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.constipation"
                />
              }
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12}>
          <Box pl={2} pt={1}>
            <FormLabel component="legend">
              Use of laxative?
            </FormLabel>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <RadioGroup name="forms.use_of_laxative" style={{ flexDirection: 'row' }}>
            <FormControlLabel
              value="yes"
              label={<Typography>Yes</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.use_of_laxative"
                />
              }
            />

            <FormControlLabel
              value="no"
              label={<Typography>No</Typography>}
              control={
                <MuiRadio
                  size="small"
                  name="forms.use_of_laxative"
                />
              }
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </Box>
  );
};
