import { Link } from '@material-ui/core';
import { getUserFullName } from '../../../helpers/users';
import { useModal } from '../../ModalsProvider';
import { UserPreviewModal } from '../../users';

export const UsersActivity = ({ users: { attached = [], detached = [] } }) => {
  const { openModal } = useModal();

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <>
      {!!attached?.length &&
        <>
          Attached users:&nbsp;
          {attached.map((user, i) => (
            <Link key={user.id} variant="subtitle1" onClick={openUserPreview(user.id)}>
              {getUserFullName(user)}
              {i !== attached.length - 1 && ', '}
            </Link>
          ))}
          &nbsp;
        </>
      }

      {!!detached?.length &&
        <>
          Detached users:&nbsp;
          {detached.map((user, i) => (
            <Link key={user.id} variant="subtitle1" onClick={openUserPreview(user.id)}>
              {getUserFullName(user)}
              {i !== detached.length - 1 && ', '}
            </Link>
          ))}
          &nbsp;
        </>
      }
    </>
  );
};
