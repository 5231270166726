import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { InfiniteListLoader } from '../../../InfiniteLoader';
import { Loader } from '../../../Loader';
import { ScrollbarsCustom } from '../../../ScrollbarsCustom';
import { FeedsContext } from '../../FeedsProvider';
import { Row } from './Row';

export const List = ({ autoHeight, RowProps }) => {
  const {
    isFetching,
    isFetched,
    meta,
    feeds,
    loadNextPage,
    resetFeeds
  } = useContext(FeedsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetFeeds();
  }, []);

  return (
    <ScrollbarsCustom scrollElementRef={scrollElementRef} autoHeight={autoHeight}>
      <Loader p={2} loading={!isFetched} render={
        () => !meta.total ? (
          <Box p={2}>
            <Typography align="center">Feed is empty</Typography>
          </Box>
        ) : (
          <MuiList disablePadding>
            <InfiniteListLoader
              items={feeds}
              isFetching={isFetching}
              isFetched={isFetched}
              pagination={meta}
              RowComponent={Row}
              RowProps={RowProps}
              scrollElementRef={scrollElementRef}
              onNeedNextPage={loadNextPage}
            />
          </MuiList>
        )}
      />
    </ScrollbarsCustom>
  );
};
