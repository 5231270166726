import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as arrivalStatusesApi from '../../../../../../api/codes/appointments/arrival-statuses';
import {
  Page,
  PageBody,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../../../components/Page';
import { Loader } from '../../../../../../components/Loader';
import { validationSchema } from '../validationSchema';
import { ArrivalStatusForm } from '../ArrivalStatusesForm';

export const Create = ({ baseUrl }) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const createArrivalStatus = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return arrivalStatusesApi.createArrivalStatus(values).then(() => {
      history.push(`${baseUrl}/arrival-statuses`);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        code: null,
        arrival_type: null,
        description: null,
        ordering: null
      }}
      validationSchema={validationSchema}
      onSubmit={createArrivalStatus}
    >
      {({ errors, handleSubmit, isSubmitting }) => (
        <Page noValidate component="form" onSubmit={handleSubmit}>
          <PageHeader>
            <IconButton
              component={Link}
              to={`${baseUrl}/arrival-statuses`}
              color="primary"
              edge="start"
            >
              <KeyboardBackspaceOutlinedIcon />
            </IconButton>

            <PageHeaderTitle>
              Create arrival status
            </PageHeaderTitle>

            <PageHeaderActions>
              <Button
                component={Link}
                size={isMobile ? 'small' : 'medium'}
                to={`${baseUrl}/arrival-statuses`}
                color="primary"
              >
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    size={isMobile ? 'small' : 'medium'}
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Create
                  </Button>
                )}
              />
            </PageHeaderActions>
          </PageHeader>

          <PageBody>
            <ArrivalStatusForm />
          </PageBody>
        </Page>
      )}
    </Formik>
  );
};
