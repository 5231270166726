import { createHookReducer } from '../../../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_SUCCESS]: (state, data) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      negotiates: data
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_FILTERS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_ITEM]: (state, updatedItem) => {
    return {
      ...state,

      negotiates: state.negotiates.map((negotiation) => {
        return negotiation.id === updatedItem.id ? { ...negotiation, ...updatedItem } : negotiation;
      })
    };
  },

  [types.ADD_ITEM]: (state, negotiation) => {
    const { pagination, filter, negotiates } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((negotiates.length + 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      negotiates: [ ...negotiates, negotiation ]
    };
  },

  [types.DELETE_ITEM]: (state, negotiateID) => {
    const filteredNegotiations = state?.negotiates?.filter(({ id }) => negotiateID !== id);

    if (filteredNegotiations.length === state?.negotiates?.length) {
      return state;
    }

    return {
      ...state,

      negotiates: filteredNegotiations
    };
  }
});
