import moment from 'moment';
import { Yup } from '../../../../../../../utils/validation';

export const validationSchema = Yup.object().shape({
  forms: Yup.object().shape({
    past_medical_history: Yup.object().shape({
      check_if_you_currently_suffer_or_have_previously_suffered_from: Yup.object().shape({
        high_blood_pressure: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        deep_vein_thrombosis: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        liver_disease: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        heart_disease_or_attach: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        stroke: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        cancer_where: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        elevated_cholesterol: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        ulcer_disease: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        gastritis: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        reflux_disease: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        asthma: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        osteoporosis: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        kidney_disease_problem: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        seizures: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        arthritis: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        thyroid_hyper_or_hypo: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        tuberculosis: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        pulmonary_embolism: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        polio: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        rheumatic_fever: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        gout: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        depression: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        diabetes: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        }),
        other_please_list: Yup.object().shape({
          value: Yup.boolean(),
          text: Yup.string().nullable()
        })
      }),
      have_you_ever_had_a_blood_transfusion: Yup.string().nullable(),
      if_yes_when: Yup.string().nullable(),
      gastrointestinal_history: Yup.object().shape({
        do_you_have_a_history_of_peptic_ulcer_disease: Yup.string().nullable(),
        do_you_have_a_history_of_gi_stomach_bleed: Yup.string().nullable(),
        do_you_take_any_medications_for_your_stomach: Yup.string().nullable()
      }),
      past_surgical_history: Yup.array().of(Yup.object().shape({
        surgery: Yup.string().nullable(),
        date: Yup.date().format('YYYY-MM-DD').nullable()
          .max(moment().add(1, 'day'), 'Date must be earlier than today'),
        surgeon: Yup.string().nullable().when('date', {
          is: (value) => !!value,
          then: Yup.string().nullable().required(),
          otherwise: Yup.string().nullable()
        })
      })),
      medications: Yup.array().of(Yup.object()),
      have_you_had_any_problems_with_anesthesia: Yup.string().nullable(),
      please_explain_if_yes: Yup.string().nullable(),
      social_history: Yup.object().shape({
        occupation: Yup.string().nullable(),
        tobacco_use: Yup.string().nullable(),
        alcohol_use: Yup.string().nullable(),
        recreational_drug_use: Yup.string().nullable(),
        yes_frequency: Yup.string().nullable()
      }),
      family_history: Yup.object().shape({
        please_check_family_history_conditions: Yup.object().shape({
          blood_clots: Yup.boolean().nullable(),
          diabetes: Yup.boolean().nullable(),
          hypertension: Yup.boolean().nullable(),
          cancer: Yup.boolean().nullable(),
          heart_disease: Yup.boolean().nullable(),
          osteoporosis: Yup.boolean().nullable(),
          rheumatoid_arthritis: Yup.boolean().nullable(),
          stroke: Yup.boolean().nullable(),
          seizures: Yup.boolean().nullable(),
          anesthetic_problems: Yup.boolean().nullable()
        }),
        please_describe_any_immediate_family_history_medical_problems: Yup.string().nullable()
      })
    })
  })
});
