import { Formik } from 'formik';
import { Button, Collapse, Dialog, FormControlLabel, SvgIcon } from '@material-ui/core';
import ApptIcon from '../../icons/appt-structure-icon.svg';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { RadioGroup, Radio } from '../../FormField';
import { Loader } from '../../Loader';
import { AppointmentsSelect } from '../AppointmentsSelect';
import { apptTypesCreationMap } from './apptTypesCreationMap';
import { validationSchema } from './validationSchema';

export const SelectApptModal = ({
  DialogProps,
  handleModalReject,
  handleModalResolve,
  payload: {
    appointmentType = null,
    patientID,
    isMulti,
    filter = {}
  }
}) => {
  const handleSubmit = (values, { isSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    handleModalResolve(values.appt_type === apptTypesCreationMap.exist ? values.appointment : null);
  };

  const handleAppointmentChange = (setFieldValue) => (appointment) => {
    setFieldValue('appointment', appointment);
  };

  return (
    <Formik
      initialValues={{
        appt_type: apptTypesCreationMap.new,
        appointment_id: null,
        approved_by: null,
        location: null,
        appointment_at: null
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader icon={<SvgIcon><ApptIcon /></SvgIcon>} onClose={handleModalReject}>
              Add new
            </ModalHeader>

            <ModalBody>
              <RadioGroup name="appt_type">
                <FormControlLabel
                  value={apptTypesCreationMap.new}
                  label="Create a new appointment"
                  control={<Radio name="appt_type" color="primary" />}
                />

                <FormControlLabel
                  value={apptTypesCreationMap.exist}
                  label="Choose from existing appointments"
                  control={<Radio name="appt_type" color="primary" />}
                />
              </RadioGroup>

              {values.appt_type === apptTypesCreationMap.exist &&
                <Collapse unmountOnExit in={values.appt_type === apptTypesCreationMap.exist} timeout="auto">
                  <AppointmentsSelect
                    required
                    name="appointment_id"
                    multiple={isMulti}
                    params={{
                      appointment_types: appointmentType && [ appointmentType ],
                      patients: [ patientID ],
                      relations: [ 'medicalProcedures' ],

                      ...filter
                    }}
                    onChange={handleAppointmentChange(setFieldValue)}
                  />
                </Collapse>
              }
            </ModalBody>

            <ModalFooter>
              <Button type="reset" onClick={handleModalReject}>
                Cancel
              </Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {(values.appt_type === apptTypesCreationMap.new) ? 'Create' : 'Add'}
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
