export const styles = ({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),

    [breakpoints.down(breakpoints.values.md)]: {
      padding: spacing(0, 1)
    }
  },

  mainContent: {
    display: 'flex',
    alignItems: 'flex-start',
    height: 360,

    [breakpoints.down(breakpoints.values.md)]: {
      flexDirection: 'column-reverse',
      height: '100%'
    }
  },

  contentLeft: {
    flexGrow: 0,
    marginRight: spacing(2),
    width: 520,
    height: 360,

    [breakpoints.down(breakpoints.values.md)]: {
      height: 400,
      width: '100%',
      maxWidth: '100%'
    }
  },

  contentRight: {
    flexGrow: 1,
    minWidth: 350,
    height: 360,

    [breakpoints.down(breakpoints.values.md)]: {
      height: 400,
      width: '100%',
      maxWidth: '100%'
    }
  }
});
