import { replaceableElementAttrName } from './replaceableDataTypePlugin';

export const getEditorStyles = ({
  theme: { spacing, palette: { grey, ...palette }, shape: { borderRadius } },
  paperSize,
  paperMargins
}) => `
  [${replaceableElementAttrName}] {
    display: inline-block;
    padding: ${spacing(0.375, 0.5)};
    line-height: 1;
    background: ${palette.primary.main};
    color: ${palette.primary.contrastText};
    border-radius: ${borderRadius}px;
  }

  * {
    box-sizing: border-box;
  }

  html {
    padding-left: 0.5cm;
    padding-right: 0.5cm;
    cursor: auto;
    background: ${grey[100]};
  }

  @media print {
    html {
      padding: 0;
      background: none;
    }
  }

  body {
    width: ${paperSize.width}mm;
    min-height: ${paperSize.height}mm;
    padding: ${paperMargins.top}mm ${paperMargins.right}mm ${paperMargins.bottom}mm ${paperMargins.left}mm;
    margin: 0.5cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    line-height: 24px;
    font-family: Verdana, Helvetica, sans-serif;
  }

  @media print {
    body {
      padding: 0;
      background: none;
      border: 1px #D3D3D3 solid;
      border-radius: 0;
      background: none;
      box-shadow: none;
    }
  }

  body * {
    max-width: 100%;
  }

  body td, body th {
    font-size: 0.9em;
  }

  body h1 {
    margin-bottom: 1cm;
  }

  body table {
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;
    border-collapse: collapse;
  }

  body table td {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  body table td:first-of-type {
    border-left: 1px solid #000;
  }

  body table tr:first-of-type td {
    border-top: 1px solid #000;
  }

  body table thead th {
    border-top: 1px solid #000;
    border-right: 1px solid #000;
  }

  body table thead th:first-of-type {
    border-left: 1px solid #000;
  }
  
  pre {
   white-space: normal;
  }
`;
