export const getFormRoutes = ({ id }) => {
  return {
    0: `/medical-forms/${id}/first-step`,
    1: `/medical-forms/${id}/second-step`,
    2: `/medical-forms/${id}/third-step`,
    3: `/medical-forms/${id}/fourth-step`,
    4: `/medical-forms/${id}/fifth-step`,
    5: `/medical-forms/${id}/six-step`,
    6: `/medical-forms/${id}/preview`
  };
};
