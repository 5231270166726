import { useParams } from 'react-router-dom';
import { columnsMap } from '../../../../components/claims';
import { ClaimsPage } from '../../BillingPage/ClaimsPage';
import { filterFieldsMap } from '../../BillingPage/ClaimsPage/Claims/FiltersBar/filterFieldsMap';

const hiddenFilterFields = [ filterFieldsMap.patients ];

export const Claims = ({ view = 'page' }) => {
  const { patientID } = useParams();

  return (
    <ClaimsPage
      view={view}
      hiddenFilterFields={hiddenFilterFields}
      hiddenColumns={[
        columnsMap.patient,
        columnsMap.caseNo,
        columnsMap.totalAmount
      ]}
      initialFilter={{
        patients: [ patientID ]
      }}
    />
  );
};
