import { useContext, useEffect } from 'react';
import { List as MuiList, makeStyles, Typography } from '@material-ui/core';
import { Scrollbars } from '../../../../../../../../components/Scrollbars';
import { TablePagination } from '../../../../../../../../components/TablePagination';
import { PaymentsContext } from '../../../../../../../../components/billing/payments';
import { Loader } from '../../../../../../../../components/Loader';
import { TableHeader } from './TableHeader';
import { Row } from './Row';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const minRowHeight = 54;
export const columnsWidths = {
  number: 150,
  due_on: 100,
  total: 100,
  amount: 100,
  action: 70
};

export const Payments = ({ commonFilter }) => {
  const {
    payments,
    isFetched,
    meta,
    resetPayments
  } = useContext(PaymentsContext);
  const classes = useStyles();

  useEffect(() => {
    resetPayments({ ...commonFilter });
  }, [ commonFilter ]);

  return (
    <div className={classes.root}>
      <Loader loading={!isFetched} className={classes.loader} render={
        () => (!payments.length ?
          <Typography
            align="center"
            className={classes.title}
          >
            No payments found
          </Typography>
          :
          <>
            <TableHeader/>

            <Scrollbars>
              <MuiList disablePadding>
                {payments.map((payment) => (
                  <Row
                    key={payment.id}
                    payment={payment}
                  />
                ))}
              </MuiList>
            </Scrollbars>

            <TablePagination disablePerPage pagination={meta} onChange={resetPayments} />
          </>
        )}
      />
    </div>
  );
};
