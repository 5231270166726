import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Typography, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { ActionButtons } from '../../../../../components/medical/forms';
import * as formsApi from '../../../../../api/profile';
import { Divider } from '../../../../../components/Divider';
import { Loader } from '../../../../../components/Loader';
import { CheckboxesComponent } from '../../../ProfilePage/MedicalInfo/MedicalForms/CheckboxesComponent';
import { FormContext } from '../../FormProvider';
import { validationSchema } from './validationSchema';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ReviewContent = () => {
  const classes = useStyles();
  const params = useParams();
  const {
    form,
    type,
    isFetched,
    handleComplete,
    handleBack
  } = useContext(FormContext);
  const { enqueueSnackbar } = useSnackbar();
  const [ symptoms, setSymptoms ] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updateGuestReviewOfSymptoms(values).then(({ data }) => {
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleComplete(data);
    }).catch(({ errors }) => {
      setErrors(errors);
      enqueueSnackbar('Form not updated', { variant: 'error' });
    });
  };

  useEffect(() => {
    formsApi.getGuestFormSymptoms().then(setSymptoms);
  }, []);

  return (
    <Loader loading={!symptoms && !isFetched} render={
      () => (
        <Formik
          enableReinitialize
          initialValues={{
            form_id: form.id,
            token: form?.token || null,
            forms: {
              review_of_symptoms: form?.forms?.review_of_symptoms || {}
            }
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <Box flexGrow={1} overflow="auto" pb={2}>
                <Typography variant="h5" color="secondary">
                  Review of Symptoms
                </Typography>

                <Box py={2}>
                  <Typography gutterBottom variant={isSmallScreen ? 'h5' : 'h4'}>
                    1. If you currently have any of the following symptoms, please check mark any that apply:
                  </Typography>
                </Box>

                <CheckboxesComponent type={type} symptoms={symptoms} />
              </Box>

              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
