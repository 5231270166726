export const styles = ({ spacing, breakpoints }) => ({
  mainContent: {
    margin: spacing(2, 3, 0, 3),

    [breakpoints.down('md')]: {
      margin: 0
    }
  },

  loader: {
    padding: spacing(2)
  }
});
