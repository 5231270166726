import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { Select } from '../../../FormField';
import { PatientsSelect } from '../../../users/PatientsSelect';
import { formTypesMap } from '../formTypesMap';
import { formTypesOptions } from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/formTypesOptions';
import { MedicalFormContent } from './MedicalFormContent';
import { FormContent } from './FormContent';

export const Form = ({ isPatientExist = false }) => {
  const { values, setFieldValue } = useFormikContext();

  const handlePatientChange = () => {
    setFieldValue('medical_form_id', null);
  };

  useEffect(() => {
    if (values.medical_form !== formTypesMap.follow_up) {
      setFieldValue('medical_form_id', null);
      setFieldValue('treating_physician_id', null);
    }
  }, [ values?.medical_form ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PatientsSelect
          required
          isCreatable={hasRole(rolesMap.medic, rolesMap.doctor, rolesMap.admin, rolesMap.supervisor)}
          disabled={isPatientExist}
          name="patient_id"
          margin="dense"
          onChange={handlePatientChange}
        />
      </Grid>

      <Grid item xs={12}>
        <Select
          formattedValue
          name="medical_form"
          options={formTypesOptions}
          TextFieldProps={{ required: true }}
        />
      </Grid>

      <Grid item xs={12}>
        {values.medical_form === formTypesMap.follow_up ?
          <MedicalFormContent />
          :
          <FormContent />
        }
      </Grid>
    </Grid>
  );
};
