import { SvgIcon } from '@material-ui/core';
import { store } from '../../../store';
import AudioSvg from '../../../components/icons/mimetypes/audio.svg';
import VideoSvg from '../../../components/icons/mimetypes/video.svg';
import ArchiveSvg from '../../../components/icons/mimetypes/archive.svg';
import DocumentSvg from '../../../components/icons/mimetypes/document.svg';
import TxtSvg from '../../../components/icons/mimetypes/txt.svg';
import ImageSvg from '../../../components/icons/mimetypes/image.svg';
import PdfSvg from '../../../components/icons/mimetypes/pdf.svg';
import HtmlSvg from '../../../components/icons/mimetypes/html.svg';
import PresentationSvg from '../../../components/icons/mimetypes/presentation.svg';
import SpreadsheetSvg from '../../../components/icons/mimetypes/spreadsheet.svg';
import VectorSvg from '../../../components/icons/mimetypes/vector.svg';
import UnknownSvg from '../../../components/icons/mimetypes/unknown.svg';
import TemplateSvg from '../../../components/icons/mimetypes/template.svg';

const isAllowedExtension = (aggregateType, extension) => {
  const aggregateTypes = store.getState().apiConfig.config.files?.aggregate_types;

  return aggregateTypes?.[aggregateType]?.extensions?.includes(extension);
};

export const fileTypesIconsVariants = [
  {
    icon: TemplateSvg,
    condition: ({ html_document_id }) => {
      return !!html_document_id;
    }
  },
  {
    icon: AudioSvg,
    condition: ({ aggregate_type, extension }) => {
      return aggregate_type === 'audio' || isAllowedExtension('audio', extension);
    }
  },
  {
    icon: VideoSvg,
    condition: ({ aggregate_type, extension }) => {
      return aggregate_type === 'video' || isAllowedExtension('video', extension);
    }
  },
  {
    icon: ArchiveSvg,
    condition: ({ aggregate_type, extension }) => {
      return aggregate_type === 'archive' || isAllowedExtension('archive', extension);
    }
  },
  {
    icon: HtmlSvg,
    condition: ({ extension }) => {
      return extension === 'html' || isAllowedExtension('html', extension);
    }
  },
  {
    icon: TxtSvg,
    condition: ({ extension }) => {
      return extension === 'txt' || isAllowedExtension('txt', extension);
    }
  },
  {
    icon: DocumentSvg,
    condition: ({ aggregate_type, extension }) => {
      return aggregate_type === 'document' || isAllowedExtension('document', extension);
    }
  },
  {
    icon: ImageSvg,
    condition: ({ aggregate_type, extension }) => {
      return aggregate_type === 'image' || isAllowedExtension('image', extension);
    }
  },
  {
    icon: PdfSvg,
    condition: ({ aggregate_type, extension }) => {
      return aggregate_type === 'pdf' || isAllowedExtension('pdf', extension);
    }
  },
  {
    icon: PresentationSvg,
    condition: ({ aggregate_type, extension }) => {
      return aggregate_type === 'presentation' || isAllowedExtension('presentation', extension);
    }
  },
  {
    icon: SpreadsheetSvg,
    condition: ({ aggregate_type, extension }) => {
      return aggregate_type === 'spreadsheet' || isAllowedExtension('spreadsheet', extension);
    }
  },
  {
    icon: VectorSvg,
    condition: ({ aggregate_type, extension }) => {
      return aggregate_type === 'vector' || isAllowedExtension('vector', extension);
    }
  }
];

export const FileTypeIcon = ({ file, className, ...props }) => {
  const variant = file && fileTypesIconsVariants.find(({ condition }) => condition(file));
  const Icon = variant ? variant.icon : UnknownSvg;

  return (
    <SvgIcon className={className} {...props}>
      <Icon />
    </SvgIcon>
  );
};
