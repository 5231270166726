export const styles = ({ spacing, breakpoints, typography: { fontWeightMedium } }) => ({
  root: {
    overflow: 'hidden'
  },

  actionsBackButton: {
    marginRight: spacing(1.5)
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: spacing(1, 4, 3, 4),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      padding: spacing(),
      flexDirection: 'column'
    }
  },

  footerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    maxHeight: 300,
    flexGrow: 1,
    maxWidth: '50%',

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      maxWidth: 'max-content'
    }
  },

  footerContent: {
    width: '100%',
    marginTop: spacing(2)
  },

  footerTitle: {
    paddingLeft: spacing(3),
    paddingBottom: spacing()
  },

  list: {
    flexGrow: 1,
    overflow: 'auto'
  },

  noteField: {
    flexGrow: 1,
    alignSelf: 'flex-end',
    margin: spacing(2, 2, 0, 4),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      width: '100%',
      margin: 0
    }
  },

  noteIcon: {
    display: 'flex',
    alignItems:'center',
    fontWeight: fontWeightMedium
  },

  filesList: {
    flexGrow: 1,
    paddingTop: 0
  },

  filesListItem: {
    marginLeft: 'auto',
    textAlign: 'right'
  },

  filesListItemText: {
    cursor: 'pointer'
  },

  invoiceContent: {
    margin: spacing(1, 6, 4, 4),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      margin: spacing()
    }
  }
});
