import { useContext } from 'react';
import T from 'prop-types';
import cn from 'classnames';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { ListRowContext } from '../ListRow';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const propTypes = {
  children: T.node,
  actions: T.bool,
  grow: T.bool,
  noDisable: T.bool,
  column: T.bool,
  className: T.string
};

export const ListRowCell = ({
  children,
  actions,
  grow,
  noDisable,
  column,
  className,

  ...props
}) => {
  const { header, disabled } = useContext(ListRowContext);
  const classes = useStyles({ cursor: props?.cursor });

  return (
    <Typography
      color="textPrimary"
      component={Box}
      p={1}
      className={cn(
        className,
        classes.cell,
        {
          [classes.cell_header]: header,
          [classes.cell_grow]: grow,
          [classes.cell_column]: column,
          [classes.cell_disabled]: disabled && !noDisable,
          [classes.cell_actions]: actions,
          [classes.cell_noWrap]: props.noWrap
        }
      )}
      {...props}
    >
      {children}
    </Typography>
  );
};

ListRowCell.propTypes = propTypes;
