import Typography from '@material-ui/core/Typography/Typography';
import moment from 'moment';
import cn from 'classnames';
import { makeStyles, Toolbar as MuiToolbar, Button } from '@material-ui/core';
import { getWeekLabelByDate } from './WeekPicker';

const useStyles = makeStyles(({ palette, typography: { pxToRem } }) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 100,
    backgroundColor:
      palette.type === 'light'
        ? palette.primary.main
        : palette.background.default
  },

  label: {
    color:
      palette.type === 'light'
        ? palette.primary.contrastText
        : palette.background.default
  },

  label_week: {
    fontSize: pxToRem(18)
  }
}));

export const Toolbar = ({ setOpenView, date }) => {
  const classes = useStyles();
  const today = moment(date);
  const weekNumber = today.week();
  const year = today.year();

  const handleViewChange = (view) => () => {
    setOpenView(view);
  };

  return (
    <MuiToolbar className={classes.toolbar}>
      <Button onClick={handleViewChange('year')}>
        <span className={classes.label}>
          <h6 className="MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiTypography-subtitle1">
            {year} Week {weekNumber}
          </h6>
        </span>

        <span className="MuiTouchRipple-root" />
      </Button>

      <Button onClick={handleViewChange('date')}>
        <span className={cn(classes.label, classes.label_week)}>
          <Typography
            variant="h4"
            className={cn(classes.label, classes.label_week)}
          >
            {getWeekLabelByDate(date)}
          </Typography>
        </span>

        <span className="MuiTouchRipple-root" />
      </Button>
    </MuiToolbar>
  );
};
