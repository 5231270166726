import { Box } from '@material-ui/core';
import { Category } from '../../../app/Dashboard/BillingPage/BillingSettings/Category';
import { expenseFieldsMap } from './fieldsMaps';
import { WidgetActivity } from './WidgetActivity';

export const Expense = ({
  isDeleted = false,
  isNew = false,
  payload
}) => {
  if (isNew) {
    return (
      <Box component="span" display="flex" alignItems="center">
        Added new expense
        &nbsp;
        <Category category={payload?.properties?.after?.category} />
      </Box>
    );
  } else if (isDeleted) {
    return (
      <Box component="span" display="flex" alignItems="center">
        Removed expense
        &nbsp;
        <Category category={payload?.properties?.after?.category} />
      </Box>
    );
  } else {
    return (
      <WidgetActivity
        {...payload?.properties}
        fieldLabel="Schedule Event"
        activityLabel="Schedule Event"
        fieldsMap={expenseFieldsMap}
      />
    );
  }
};
