import { useRef } from 'react';
import { Box } from '@material-ui/core';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { useResizeObserver } from '../../../../../../../helpers/hooks/useResizeObserver';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { CaseReOpenContent } from './CaseReOpenContent';

export const CaseReOpened = ({
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });

  return (
    <ContentCard
      ref={rootRef}
      fullHeight={!props.autoHeightMax}
      title="Case re-opened/re-closed"
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}
      {...props}
    >
      <Box height={height || '100%'}>
        <CaseReOpenContent width={width} caseItem={caseItem} />
      </Box>
    </ContentCard>
  );
};
