import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import { Icon } from '@mdi/react';
import { mdiOcr } from '@mdi/js';
import { Badge } from '../../../../components/Badge';
import { SvgIcon } from '../../../../components/SvgIcon';
import { FileTypeIcon } from '../FileTypeIcon';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FilePreviewIcon = ({ file, className, ...props }) => {
  const hasPreviewImage = file?.previews?.['64'] || file.aggregate_type === 'vector';
  const classes = useStyles({ hasPreviewImage });

  return (
    <Badge
      badgeContent={file?.pages}
      max={999}
      color="primary"
      overlap="circle"
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      classes={{ badge: classes.badge }}
    >
      <div className={cn(classes.root, className)} {...props}>

        {hasPreviewImage ? (
          <img
            src={file?.previews?.['64'] || file.url}
            alt={file.extension}
            className={classes.previewImage}
          />
        ) : (
          <FileTypeIcon file={file} className={classes.previewIcon} />
        )}

        <div
          title={(!file.deleted_at && !file.opened_at && 'File not viewed') || null}
          className={classes.previewBackdrop}
        >
          <EyeIcon/>
        </div>

        {!file.deleted_at && !file.opened_at &&
          <div title="File not viewed" className={cn(classes.indicator, classes.indicator_view)}>
            <EyeIcon className={classes.indicator__icon} />
          </div>
        }

        {file.aggregate_type === 'pdf' &&
          <div
            title={file.has_text ? 'OCR is available' : 'OCR in progress'}
            className={cn(
              classes.indicator,
              classes.indicator_ocr,
              file.has_text && classes.indicator_ocr_active
            )}
          >
            <SvgIcon className={classes.indicator__icon}>
              <Icon path={mdiOcr} />
            </SvgIcon>
          </div>
        }
      </div>
    </Badge>
  );
};
