import { Autocomplete } from '../../Autocomplete';
import { trackTypesOptions } from './trackTypesMap';

export const TrackTypesSelect = (props) => {
  return (
    <Autocomplete
      label="Track type"
      placeholder="Search track type..."
      options={trackTypesOptions}

      {...props}
    />
  );
};
