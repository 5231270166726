import { debounce } from 'lodash';
import { useContext } from 'react';
import { Formik } from 'formik';
import { makeStyles, InputAdornment, FormControlLabel, Grid, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { AppBar } from '../../../../../components/AppBar';
import { TextField, Switch } from '../../../../../components/FormField';
import { SearchFilesContext } from '../SearchFilesContext';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const FiltersBar = () => {
  const classes = useStyles();
  const { filter, applyFilter } = useContext(SearchFilesContext);

  const handleFieldChange = debounce((event) => {
    applyFilter({ q: event.target.value });
  }, 600);

  const handleSwitchChange = (name) => ({ target: { checked, value } }) => {
    applyFilter({ [name]: checked ? value : null });
  };

  return (
    <AppBar className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <Formik initialValues={filter} onSubmit={null}>
        <Grid container spacing={2} alignItems="center">
          <Grid item component={Box} flexGrow={1}>
            <TextField
              autoFocus
              name="q"
              placeholder="Search..."
              onChange={handleFieldChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              label="With no exact matches"
              control={
                <Switch
                  name="correctionValue"
                  color="primary"
                  value={1}
                  onChange={handleSwitchChange('correctionValue')}
                />
              }
            />
          </Grid>
        </Grid>
      </Formik>
    </AppBar>
  );
};
