import moment from 'moment';
import Papa from 'papaparse';
import printJS from 'print-js';
import { getUserFullName } from '../../../helpers/users';

const fieldsMap = {
  user: 'user',
  date: 'date',
  duration: 'duration',
  lunch: 'lunch'
};

const fieldsLabelsMap = {
  user: 'User',
  date: 'Date',
  duration: 'Duration',
  lunch: 'Lunch'
};

const transformWorkSessionForView = (workSession) => {
  return {
    [fieldsMap.user]: getUserFullName(workSession.user) || '-',
    [fieldsMap.date]: moment.unix(workSession.started_at).format('L LT'),
    [fieldsMap.duration]: moment.duration(workSession.time_info.work, 'seconds').format('h[h] m[min]'),
    [fieldsMap.lunch]:moment.duration(workSession.time_info.lunch, 'seconds').format('h[h] m[min]')
  };
};

export const exportWorkSessionsAsCSV = (workSessions) => {
  return Papa.unparse({
    fields: Object.values(fieldsLabelsMap),
    data: workSessions.map((workSession) => Object.values(transformWorkSessionForView(workSession)))
  });
};

export const printWorkSessions = (workSessions) => {
  printJS({
    printable: workSessions.map(transformWorkSessionForView),
    properties: [
      { field: fieldsMap.user, displayName: fieldsLabelsMap.user },
      { field: fieldsMap.date, displayName: fieldsLabelsMap.date },
      { field: fieldsMap.duration, displayName: fieldsLabelsMap.duration },
      { field: fieldsMap.lunch, displayName: fieldsLabelsMap.lunch }
    ],
    style: 'table { font-size: 10pt };',
    type: 'json'
  });
};
