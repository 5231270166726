import { preventDefault } from '../../../../../../../helpers/dom';
import { Box, Link } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { CurrencyFormat } from '../../../../../../../components/CurrencyFormat';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { IconButton } from '../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../components/UserLink';
import { Date } from '../../../../../../../components/Date';
import { Category } from '../../../../BillingSettings/Category';
import { columnsWidths, minRowHeight, widthBreakpointMedium } from '../List';

export const Row = ({
  parentPage,
  width,
  index,
  expense,
  files,
  openFilesPreview,
  onUpdateExpense,
  onDeleteExpense
}) => {
  return (
    <ListRow px={2} minHeight={minRowHeight}>
      <ListRowCell grow flexBasis={columnsWidths.name}>
        <UserLink
          size="md"
          path={`/members/users/${expense?.user?.id}`}
          user={expense?.user}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.date}>
        <Date disableIcon date={expense.date} format="L, LT" />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.category}>
        {!expense?.category ? '-' :
          <Category category={expense?.category} />
        }
      </ListRowCell>

      {width > widthBreakpointMedium &&
        <ListRowCell noWrap title={expense.notes} flexBasis={columnsWidths.notes}>
          {expense.notes}
        </ListRowCell>
      }

      <ListRowCell flexBasis={columnsWidths.attachments}>
        {!files?.length ? '-' :
          <Box
            component={Link}
            underline="none"
            display="flex"
            alignItems="center"
            color="primary.main"
            onClick={openFilesPreview}
          >
            <AttachFileIcon fontSize="inherit"/>

            {files.length}
          </Box>
        }
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.budgeted}>
        <CurrencyFormat
          value={expense.budget}
          TypographyProps={{
            variant: 'h5',
            color: 'secondary'
          }}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.amount}>
        <CurrencyFormat
          value={expense.amount}
          TypographyProps={{ variant: 'h5' }}
        />
      </ListRowCell>

      <ListRowCell actions justifyContent="flex-end" flexBasis={columnsWidths.action}>
        {parentPage &&
          <IconButton
            color="primary"
            title="Edit expense"
            onClick={preventDefault(onUpdateExpense(expense))}
          >
            <EditIcon />
          </IconButton>
        }

        <IconButton
          edge="end"
          color="error"
          title="Delete expense"
          onClick={preventDefault(onDeleteExpense(index, expense))}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
