export const SET_FILES_LAST_GLOBAL_ACTION = 'SET_FILES_LAST_GLOBAL_ACTION';
export const SET_APPOINTMENTS_LAST_GLOBAL_ACTION = 'SET_APPOINTMENTS_LAST_GLOBAL_ACTION';
export const SET_TAGS_LAST_GLOBAL_ACTION = 'SET_TAGS_LAST_GLOBAL_ACTION';
export const SET_TASKS_LAST_GLOBAL_ACTION = 'SET_TASKS_LAST_GLOBAL_ACTION';
export const SET_COMMENTS_LAST_GLOBAL_ACTION = 'SET_COMMENTS_LAST_GLOBAL_ACTION';
export const SET_COMPANIES_LAST_GLOBAL_ACTION = 'SET_COMPANIES_LAST_GLOBAL_ACTION';
export const SET_COMPANIES_TYPES_LAST_GLOBAL_ACTION = 'SET_COMPANIES_TYPES_LAST_GLOBAL_ACTION';
export const SET_OFFICES_LAST_GLOBAL_ACTION = 'SET_OFFICES_LAST_GLOBAL_ACTION';
export const SET_CASES_LAST_GLOBAL_ACTION = 'SET_CASES_LAST_GLOBAL_ACTION';
export const SET_TRACKS_LAST_GLOBAL_ACTION = 'SET_TRACKS_LAST_GLOBAL_ACTION';
export const SET_USERS_LAST_GLOBAL_ACTION = 'SET_USERS_LAST_GLOBAL_ACTION';
export const SET_INVOICES_LAST_GLOBAL_ACTION = 'SET_INVOICES_LAST_GLOBAL_ACTION';
export const SET_INCOMES_LAST_GLOBAL_ACTION = 'SET_INCOMES_LAST_GLOBAL_ACTION';
export const SET_EXPENSES_LAST_GLOBAL_ACTION = 'SET_EXPENSES_LAST_GLOBAL_ACTION';
export const SET_PAYMENTS_LAST_GLOBAL_ACTION = 'SET_PAYMENTS_LAST_GLOBAL_ACTION';
export const SET_CATEGORIES_LAST_GLOBAL_ACTION = 'SET_CATEGORIES_LAST_GLOBAL_ACTION';
export const SET_CPT_CODES_LAST_GLOBAL_ACTION = 'SET_CPT_CODES_LAST_GLOBAL_ACTION';
export const SET_SCHEDULES_LAST_GLOBAL_ACTION = 'SET_SCHEDULES_LAST_GLOBAL_ACTION';
export const SET_MEDICAL_PROFILE_LAST_GLOBAL_ACTION = 'SET_MEDICAL_PROFILE_LAST_GLOBAL_ACTION';
export const SET_HISTORY_LAST_GLOBAL_ACTION = 'SET_HISTORY_LAST_GLOBAL_ACTION';
