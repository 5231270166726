export const PRESCRITIONS_FETCH_REQUEST = 'PRESCRITIONS_FETCH_REQUEST';
export const PRESCRITIONS_FETCH_SUCCESS = 'PRESCRITIONS_FETCH_SUCCESS';
export const ADD_PRESCRITION = 'ADD_PRESCRITION';
export const UPDATE_PRESCRITION = 'UPDATE_PRESCRITION';
export const DELETE_PRESCRITION_SUCCESS = 'DELETE_PRESCRITION_SUCCESS';
export const RESET_PRESCRITIONS = 'RESET_PRESCRITIONS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const MASS_DELETE_PRESCRITIONS = 'MASS_DELETE_PRESCRITIONS';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
