export const styles = ({
  palette: { text },
  typography: { pxToRem, fontWeightMedium },
  shape: { borderRadius },
  spacing,
  breakpoints
}) => ({
  body: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3)
  },

  body_inModal: {
    marginLeft: spacing(5),
    marginRight: spacing(5),

    [breakpoints.down('md')]: {
      margin: spacing(0, 2)
    }
  },

  body__item: {
    marginBottom: spacing(5)
  },

  body__title: {
    color: text.caption,
    fontWeight: fontWeightMedium,
    paddingBottom: spacing(1),

    [breakpoints.down('md')]: {
      paddingBottom: 0
    }
  },

  body__input: {
    lineHeight: pxToRem(21),
    paddingTop: spacing(1),
    borderRadius,

    [breakpoints.down('md')]: {
      paddingTop: 0
    }
  }
});
