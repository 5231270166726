import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import * as formsApi from '../../../../../../../api/profile';
import { Divider } from '../../../../../../../components/Divider';
import { Loader } from '../../../../../../../components/Loader';
import { ActionButtons, CurrentSymptomsBody } from '../../../../../../../components/medical/forms';
import {
  validationSchema
} from '../../../../../GuestMedicalProfile/PersonalInjury/CurrentSymptoms/validationSchema';
import { MedicalFormItemContext } from '../../../MedicalFormItemProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const CurrentSymptoms = () => {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    form,
    type,
    isFetched,
    handleComplete,
    handleBack
  } = useContext(MedicalFormItemContext);
  const [ symptoms, setSymptoms ] = useState();

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleCreateStep = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return formsApi.updateCurrentSymptomsPI(values).then(() => {
      enqueueSnackbar('Form successfully updated', { variant: 'success' });
      handleComplete(form.id);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Appointment not updated', { variant: 'error' });
    });
  };

  useEffect(() => {
    formsApi.getFormSymptoms().then((data) => setSymptoms(data));
  }, []);

  return (
    <Loader loading={!isFetched} render={
      () => (
        <Formik
          initialValues={{
            form_id: form?.id,
            forms: {
              current_symptoms_pi: {
                ...form?.forms?.current_symptoms_pi,
                where_is_pain: form?.forms?.current_symptoms_pi?.where_is_pain
              }
            }
          }}
          validationSchema={validationSchema}
          onSubmit={handleCreateStep}
        >
          {({ handleSubmit }) => (
            <form className={classes.root} noValidate onSubmit={handleSubmit}>
              <CurrentSymptomsBody symptoms={symptoms} type={type} />
              <Divider gutter={2} />
              <ActionButtons onBack={handleBackStep} />
            </form>
          )}
        </Formik>
      )}
    />
  );
};
