
export const styles = ({ spacing }) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column'
  },

  main: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    padding: spacing(2)
  }
});
