import { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Loader } from '../../../../components/Loader';
import { AccountsContext } from '../AccountsContext';
import { RulesEmailsContextProvider } from './RulesEmailsContext';
import { MainContent } from './MainContent';

export const Rules = () => {
  const { isFetched, accounts } = useContext(AccountsContext);

  const filter = {
    config_id: accounts?.map((item) => item.id)
  };

  return (
    <Loader p={3} loading={!isFetched} render={
      () => !accounts.length ? (
        <Box maxWidth={975}>
          <Typography color="textSecondary">You haven't created any email account.</Typography>
        </Box>
      ) : (
        <RulesEmailsContextProvider filter={filter}>
          <MainContent />
        </RulesEmailsContextProvider>
      )}
    />
  );
};
