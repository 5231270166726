import { api } from '../api';

export const fetchFiles = (config = {}) => {
  return api.get('/billing_files', config).then(({ data }) => {
    return data;
  }).catch((error) => {
    throw error;
  });
};

export const uploadFile = (data, options) => {
  return api.post('/billing_files', data, options)
    .then(({ data: { data } }) => data)
    .catch((data) => { throw data; });
};

export const restoreFiles = (IDs) => {
  return api.post('/billing_files/restore', { files: IDs });
};

export const deleteFiles = (IDs) => {
  return api.post('/billing_files/delete', { files: IDs });
};

export const destroyFiles = (IDs) => {
  return api.delete('/billing_files', { params: { files: IDs } });
};
