import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { FormHeader } from '../../../../../../components/medical/forms';
import { steps as unconfirmedSteps } from '../../../../GuestMedicalProfile/FormProvider';
import { MedicalFormItemContext, steps, stepsTitle } from '../../MedicalFormItemProvider';
import { FormStepper } from '../FormStepper';
import { getFormRoutes } from '../MedicalFormsProvider';
import { formStepComponents, formStepUnconfirmedComponents } from './formStepComponents';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const PrivatePacket = () => {
  const classes = useStyles();
  const params = useParams();
  const {
    fetchForm,
    form,
    type,
    isConfirmed,
    initialFormValue,
    completed,
    activeStep,
    setActiveStep,
    ...props
  } = useContext(MedicalFormItemContext);
  const Component = isConfirmed ? formStepComponents[activeStep] : formStepUnconfirmedComponents[activeStep];

  useEffect(() => {
    if (!params.medicalFormID) {
      fetchForm({ id: null });
    }
  }, []);

  return (
    <div className={classes.formRoot}>
      <FormHeader
        form={form}
        type={type}
        activeStep={activeStep}
        initialFormValue={initialFormValue}
      />

      <FormStepper
        steps={isConfirmed ? steps : unconfirmedSteps}
        stepsTitle={stepsTitle}
        completed={completed}
        activeStep={activeStep}
        onActiveStepChange={setActiveStep}
        getFormRoutes={getFormRoutes}
        component={<Component />}

        { ...props }
      />
    </div>
  );
};
