import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { fieldsKeysMap } from '../../filterKeysMap';
import { columnsWidths } from '../SavedFiltersList';

export const TableHeader = ({ hiddenColumns }) => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        {!hiddenColumns.includes(fieldsKeysMap.formType) &&
          <ListRowCell grow flexBasis={columnsWidths.medicalFormType}>
            Type of Medical Form
          </ListRowCell>
        }

        {!hiddenColumns.includes(fieldsKeysMap.search) &&
          <ListRowCell grow flexBasis={columnsWidths.search}>
            Search
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.dateFrom}>
          From
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dateTo}>
          To
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.provider}>
          Provider
        </ListRowCell>

        {!hiddenColumns.includes(fieldsKeysMap.users) &&
          <ListRowCell flexBasis={columnsWidths.users}>
            Patients
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.insuranceId}>
          Insurance ID
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.fromDOI}>
          From DOI
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.toDOI}>
          To DOI
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.orderBy}>
          Order By
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
