import { useEffect, useContext, useRef } from 'react';
import { List as MuiList, Box, Typography } from '@material-ui/core';
import { Loader } from '../../../Loader';
import { InfiniteListLoader } from '../../../InfiniteLoader';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { ActivityContext } from '../../../Activities';
import { Row } from './Row';

export const List = ({ appointment }) => {
  const {
    activities,
    isFetched,
    isFetching,
    loadNextPage,
    filter,
    error,
    resetActivities
  } = useContext(ActivityContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetActivities();
  }, [ appointment ]);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box py={1} px={2}>
        <Loader loading={!isFetched} render={
          () => !error ? (
            <MuiList disablePadding>
              <InfiniteListLoader
                items={activities}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={filter}
                RowComponent={Row}
                scrollElementRef={scrollElementRef}
                onNeedNextPage={loadNextPage}
              />
            </MuiList>
          ) : (
            <Typography align="center">{error}</Typography>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
