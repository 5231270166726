import { FieldArray, useFormikContext } from 'formik';
import {
  makeStyles,
  Box,
  Fab,
  Grid,
  Paper,
  useTheme,
  Typography,
  useMediaQuery,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import NotesIcon from '@material-ui/icons/Notes';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Checkbox,
  TextField,
  BodyPartsSelect,
  KeyboardDatePicker,
  Switch
} from '../../../../../../components/FormField';
import { IconButton } from '../../../../../../components/IconButton';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../../components/ModalsProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const GeneralInfo = ({ parentIndex, defaultText }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { openModal } = useModal();
  const { values, setFieldValue } = useFormikContext();
  const isAdjVisible = !values.ins_claims[parentIndex].disable_adj_number;

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  const handleChangeCode = (index) => (option) => {
    setFieldValue(`ins_claims.${parentIndex}.body_parts.${index}.code`, option?.code || null);
    setFieldValue(
      `ins_claims.${parentIndex}.body_parts.${index}.description`,
      option?.description || null
    );
  };

  const toggleRange = (parentIndex, index) => (event) => {
    const { checked } = event.target;

    if (checked) {
      setFieldValue(`ins_claims.${parentIndex}.doi.${index}.date`, null);
    } else {
      setFieldValue(`ins_claims.${parentIndex}.doi.${index}.from`, null);
      setFieldValue(`ins_claims.${parentIndex}.doi.${index}.to`, null);
    }
  };

  return (
    <Paper
      square={false}
      component={Box}
      my={1.5}
      py={3.5}
      px={isMobile ? 0 : 2}
      elevation={0}
      bgcolor={isMobile ? 'inherit' : 'grey.100'}
      display="flex"
      flexDirection="column"
    >
      <FieldArray
        name={`ins_claims.${parentIndex}.adj`}
        render={(arrayHelpers) => (
          <div className={classes.adjNumberContent}>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="h3">ADJ number</Typography>

              <Fab
                disabled={!isAdjVisible}
                type="button"
                color="primary"
                size="small"
                className={classes.addButton}
                onClick={() => arrayHelpers.push({ date: '', number: '' })}
              >
                <AddIcon fontSize="small" />
              </Fab>

              <FormControlLabel
                label={isAdjVisible ? 'Disable ADJ number' : 'Enable ADJ number'}
                className={classes.toggleAdjButton}
                control={
                  <Switch
                    name={`ins_claims.${parentIndex}.disable_adj_number`}
                    color="primary"
                    onClick={() => arrayHelpers.pop()}
                  />
                }
              />
            </Box>

            {isAdjVisible &&
              <div className={classes.adjNumberContent__list}>
                {!values?.ins_claims[parentIndex]?.adj?.length && (
                  <Typography align="center">
                    No ADJ numbers
                  </Typography>
                )}

                {values?.ins_claims[parentIndex]?.adj?.map((adj_number, index) => (
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    direction="row"
                    alignItems="flex-end"
                    wrap={isMobile ? 'wrap' : 'nowrap'}
                  >
                    <Grid item>
                      {index + 1}.
                    </Grid>

                    <Grid item xs={isMobile ? 10 : 4}>
                      <KeyboardDatePicker
                        required
                        zeroMinWidth
                        label="Date"
                        name={`ins_claims.${parentIndex}.adj.${index}.date`}
                        placeholder="Select a date"
                        outputFormat="YYYY-MM-DD"
                      />
                    </Grid>

                    <Grid
                      item
                      xs={isMobile ? 11 : 7}
                      container
                      direction="row"
                      alignItems="center"
                      wrap="nowrap"
                      className={classes.adjContent}
                    >
                      <TextField
                        multiline
                        required
                        label="EAMS number"
                        name={`ins_claims.${parentIndex}.adj.${index}.number`}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              disableTypography
                              position="start"
                              className={classes.defaultText}
                            >
                              {defaultText}
                            </InputAdornment>
                          )
                        }}
                      />

                      <IconButton
                        size="small"
                        color="error"
                        onClick={handleClearField(arrayHelpers, index)}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </div>
            }
          </div>
        )}
      />

      <FieldArray
        name={`ins_claims.${parentIndex}.doi`}
        render={() => (
          <>
            <Box display="flex" alignItems="center" mx={0} mb={2}>
              <Typography variant="h3">DOI</Typography>
            </Box>

            <div className={classes.painContent__list}>
              <div className={classes.contentRow}>
                <Box flexGrow={1}>
                  <FormControlLabel
                    label="Range"
                    control={
                      <Checkbox
                        color="primary"
                        name={`ins_claims.${parentIndex}.doi.0.is_range`}
                        onChange={toggleRange(parentIndex, 0)}
                      />
                    }
                  />

                  <Box display="flex" justifyContent="space-between" alignItems="flex-start" pb={2}>
                    {values?.ins_claims[parentIndex]?.doi[0]?.is_range ? (
                      <Grid container spacing={isMobile ? 1 : 2} justify="space-between" alignItems="flex-end">
                        <Grid item>
                          <KeyboardDatePicker
                            required
                            zeroMinWidth
                            label="From"
                            outputFormat="YYYY-MM-DD"
                            name={`ins_claims.${parentIndex}.doi.0.from`}
                            placeholder="Select a date"
                          />
                        </Grid>

                        <Grid item>
                          <KeyboardDatePicker
                            required
                            label="To"
                            zeroMinWidth
                            outputFormat="YYYY-MM-DD"
                            name={`ins_claims.${parentIndex}.doi.0.to`}
                            placeholder="Select a date"
                          />
                        </Grid>

                        <Grid item>
                          <KeyboardDatePicker
                            required
                            label="Statute of Limitations"
                            outputFormat="YYYY-MM-DD"
                            name={`ins_claims.${parentIndex}.doi.0.start_of_limitation`}
                            placeholder="Select a date"
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Box width={isMobile ? '100%' : '45%'}>
                          <KeyboardDatePicker
                            required
                            label="Date"
                            outputFormat="YYYY-MM-DD"
                            name={`ins_claims.${parentIndex}.doi.0.date`}
                            placeholder="Select a date"
                          />
                        </Box>

                        <Box width={isMobile ? '100%' : '45%'}>
                          <KeyboardDatePicker
                            required
                            label="Statute of Limitations"
                            outputFormat="YYYY-MM-DD"
                            name={`ins_claims.${parentIndex}.doi.0.start_of_limitation`}
                            placeholder="Select a date"
                          />
                        </Box>
                      </>
                    )}
                  </Box>

                  <TextField
                    multiline
                    rowsMax={5}
                    label={
                      <div className={classes.notesIcon}>
                        <NotesIcon fontSize="small"/>

                        {!values?.ins_claims[parentIndex]?.doi[0]?.note ? 'Note' : 'Add note'}
                      </div>
                    }
                    InputProps={{
                      className: classes.notesInput
                    }}
                    name={`ins_claims.${parentIndex}.doi.0.note`}
                  />
                </Box>
              </div>
            </div>
          </>
        )}
      />

      <FieldArray
        name={`ins_claims.${parentIndex}.body_parts`}
        render={(arrayHelpers) => (
          <Box pt={3}>
            <Box display="flex" alignItems="center">
              <Typography variant="h3">Injured body parts</Typography>

              <Fab
                type="button"
                color="primary"
                size="small"
                className={classes.addButton}
                onClick={() => arrayHelpers.push({ id: '', note: '' })}
              >
                <AddIcon fontSize="small" />
              </Fab>
            </Box>

            {!!values?.ins_claims[parentIndex]?.body_parts?.length &&
              <Box pt={2}>
                {values?.ins_claims[parentIndex]?.body_parts?.map((item, index) => (
                  <div key={index} className={classes.contentRow}>
                    <Typography className={classes.contentRowIndex}>{index + 1}.</Typography>

                    <Box flexGrow={1} overflow="hidden">
                      <BodyPartsSelect
                        name={`ins_claims.${parentIndex}.body_parts.${index}.id`}
                        onChange={handleChangeCode(index)}
                      />

                      <TextField
                        multiline
                        rowsMax={5}
                        disabled={!values?.ins_claims[parentIndex]?.body_parts[index].id}
                        label={
                          <div className={classes.notesIcon}>
                            <NotesIcon fontSize="small"/>

                            {values?.ins_claims[parentIndex]?.body_parts[index]?.notes ? 'Note' : 'Add note'}
                          </div>
                        }
                        name={`ins_claims.${parentIndex}.body_parts.${index}.note`}
                      />
                    </Box>

                    <IconButton
                      size="small"
                      color="error"
                      onClick={handleClearField(arrayHelpers, index)}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </div>
                ))}
              </Box>
            }
          </Box>
        )}
      />
    </Paper>
  );
};
