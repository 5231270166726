import * as types from './types';

export const openWidget = () => ({ type: types.FILES_UPLOADS_OPEN_WIDGET });
export const closeWidget = () => ({ type: types.FILES_UPLOADS_CLOSE_WIDGET });

export const addFile = (payload) => ({ type: types.FILES_UPLOADS_ADD_FILE, payload });
export const updateFile = (payload) => ({ type: types.FILES_UPLOADS_UPDATE_FILE, payload });
export const fileUploadSuccess = (payload) => ({ type: types.FILE_UPLOAD_SUCCESS, payload });
export const fileUploadFail = (payload) => ({ type: types.FILE_UPLOAD_FAIL, payload });
export const cancelUpload = (payload) => ({ type: types.FILE_UPLOAD_CANCEL, payload });
export const refreshUpload = (payload) => ({ type: types.FILE_UPLOAD_REFRESH, payload });

export const cancelOwnUpload = () => ({ type: types.FILES_UPLOADS_CANCEL_OWN_UPLOAD });
export const cancelAllUploads = () => ({ type: types.FILES_UPLOADS_CANCEL_ALL });
export const setError = () => ({ type: types.FILES_UPLOADS_SET_ERROR });
