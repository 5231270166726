export const styles = ({ palette, spacing, shape: { borderRadius } }) => ({
  root: {
    display: 'flex',
    margin: spacing(1, 2),
    border: `1px solid ${palette.divider}`,
    borderRadius
  },

  searchIcon: {
    marginRight: spacing(0.5),
    marginLeft: spacing(-0.5)
  }
});
