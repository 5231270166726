import { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { NestedList } from '../../../../../../../components/NestedList';
import { reliefSliderMarks, sliderMarks } from '../../FollowUp/sliderMarks';
import { AppointmetsInfo } from '../AppointmetsInfo';
import { Footer } from './Footer';
import { MainContent } from './MainContent';
import { PainContent } from './PainContent';

export const FollowUpFormPreview = ({ isOpenSwitches, form, toggleSwitches }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenListItem = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    setIsOpen(isOpenSwitches);
  }, [ isOpenSwitches ]);

  return (
    <>
      <AppointmetsInfo isOpenSwitches={isOpenSwitches} form={form} toggleSwitches={toggleSwitches} />

      <NestedList
        disablePadding
        isOpen={isOpen}
        title="Patient Comfort Assesment Guid"
        TypographyProps={{ fontSize: 'large' }}
        onOpen={handleOpenListItem}
      >
        <Box p={!isMobile ? 3 : 1}>
          <MainContent form={form?.forms || {}} />

          <PainContent
            title={
              <Typography variant="h5">
                Choose a number that describes your pain on <u>average in the week</u> with medication use
              </Typography>
            }
            sliderValue={
              sliderMarks?.find(({ label }) => (
                label === form?.forms?.describes_your_pain_on_average_in_the_week_with_medication_use)
              )?.value || 0
            }
            marks={sliderMarks}
          />

          <PainContent
            title={
              <Typography variant="h5">
                Rate your pain by circling the number that best describes your <u>pain at its worst</u>
              </Typography>
            }
            sliderValue={
              sliderMarks?.find(({ label }) => (
                label === form?.forms?.best_describes_your_pain_at_its_worst)
              )?.value || 0
            }
            marks={sliderMarks}
          />

          <PainContent
            title={
              <Typography variant="h5">
                Rate your pain relief with the treatment or medicine we provided you
              </Typography>
            }
            sliderValue={
              reliefSliderMarks?.find(({ label }) => (
                label === form?.forms?.pain_relief_with_the_treatment_or_medicine_we_provided_you)
              )?.value || 0
            }
            marks={reliefSliderMarks}
          />

          <Footer form={form?.forms} />
        </Box>
      </NestedList>
    </>
  );
};
