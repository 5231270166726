import { useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { uniqBy } from 'lodash';
import { FieldArray, useFormikContext } from 'formik';
import { Box, Button, Grid, Hidden, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Icon } from '@mdi/react';
import { mdiClipboardPulseOutline } from '@mdi/js';
import * as patientsApi from '../../../api/users/patients';
import { ProcedureCreateModal } from '../../appointments/procedures';
import { Fab } from '../../Fab';
import { IcdCodesSelect } from '../../FormField';
import { IconButton } from '../../IconButton';
import { Loader } from '../../Loader';
import { useModal } from '../../ModalsProvider';
import { SvgIcon } from '../../SvgIcon';
import { ContentCard } from '../../ContentCard';
import { Tooltip } from '../../Tooltip';
import { DiagnosisSelectModal } from '../../medical/reports';

export const Diagnosis = () => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ isCollapsed, setIsCollapsed ] = useState(false);
  const [ isLastDXLoading, setIsLastDXLoading ] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const ICDs = useMemo(() => {
    return uniqBy(
      values.appointment?.procedures?.map(({ icd }) => icd || []).flat(),
      ({ id }) => id
    );
  }, [ values.appointment ]);

  const options = ICDs.filter((item) => {
    return !values?.icd_codes?.find((option) => option && item.id === option.id);
  });

  const applyLastDiagnosis = () => {
    setIsLastDXLoading(true);

    patientsApi.fetchLastDiagnosis(values.patient?.id).then((ICDs) => {
      if (ICDs?.length) {
        setFieldValue('icd_codes', ICDs);
      } else {
        enqueueSnackbar('The patient has no diagnoses', { variant: 'warning' });
      }
    }).finally(() => {
      setIsLastDXLoading(false);
    });
  };

  const applyDiagnoseFromReport = () => {
    openModal(DiagnosisSelectModal, {
      payload: {
        filter: {
          patient_id: values?.patient_id?.id || values.patient_id
        }
      },

      onModalResolved: ({ diagnosis }) => {
        setFieldValue('icd_codes', diagnosis || []);
      }
    });
  };

  const toggleCollapsed = () => {
    setIsCollapsed((state) => !state);
  };

  const addProcedure = () => {
    openModal(ProcedureCreateModal, {
      payload: {
        initialValues: {
          appointment_id: values.appointment_id
        }
      },

      onModalResolved: (procedure) => {
        setFieldValue('appointment', {
          ...values.appointment,

          procedures: (values.appointment?.procedures || []).concat(procedure)
        });
      }
    });
  };

  return (
    <ContentCard
      fullHeight
      disableScrollbars
      variant="outlined"
      color="info"
      isCollapsed={isCollapsed}
      title="Diagnosis"
      leftActions={[
        <Box mr={1}>
          <SvgIcon color="info"><Icon path={mdiClipboardPulseOutline} /></SvgIcon>
        </Box>
      ]}
      rightActions={[
        <Grid mr={1}>
          <Grid container spacing={2} justify="flex-end" wrap="wrap">
            <Grid item>
              <Tooltip
                isExistTooltip={!values.appointment}
                title="Select appointment firstly"
              >
                <div>
                  <Fab
                    disabled={!values.appointment}
                    variant={isMobile ? 'round' : 'extended'}
                    color="primary"
                    startIcon={!isMobile && <AddIcon />}
                    onClick={addProcedure}
                  >
                    {isMobile ? <AddIcon /> : 'Add'}
                  </Fab>
                </div>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip
                isExistTooltip={!values.patient}
                title="Select patient firstly"
              >
                <div>
                  <Button
                    disabled={!values.patient}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={applyDiagnoseFromReport}
                  >
                    From report
                  </Button>
                </div>
              </Tooltip>
            </Grid>

            <Grid item>
              <Loader surface loading={isLastDXLoading} render={
                () => (
                  <Tooltip
                    isExistTooltip={!values.patient}
                    title="Select patient firstly"
                  >
                    <div>
                      <Button
                        disabled={!values.patient}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={applyLastDiagnosis}
                      >
                        Use Last DX
                      </Button>
                    </div>
                  </Tooltip>
                )}
              />
            </Grid>

            {/*<Grid item>*/}
            {/*  <Tooltip*/}
            {/*    isExistTooltip={!values.patient}*/}
            {/*    title="Select patient firstly"*/}
            {/*  >*/}
            {/*    <div>*/}
            {/*      <Button*/}
            {/*        disabled={!values.patient}*/}
            {/*        variant="outlined"*/}
            {/*        color="primary"*/}
            {/*        size="small"*/}
            {/*      >*/}
            {/*        Show all DX*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Tooltip>*/}
            {/*</Grid>*/}
          </Grid>
        </Grid>,

        <IconButton edge="end" color="info" onClick={toggleCollapsed}>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      ]}
    >
      {!ICDs?.length &&
        <Typography>Diagnoses not found</Typography>
      }

      <FieldArray
        name="icd_codes"
        render={({ remove, push }) => (
          <>
            {values.icd_codes?.map((option, index) => (
              <Grid container spacing={2} key={index} mb={3} alignItems="flex-start">
                <Grid item>
                  <Typography component="span">{index + 1}.</Typography>
                </Grid>

                <Grid item sm={4} xs={9}>
                  <IcdCodesSelect
                    required
                    disableValueTransform
                    margin="dense"
                    options={options}
                    name={`icd_codes.${index}`}
                    label={`DX ${index + 1}`}
                  />
                </Grid>

                <Hidden lgUp>
                  <Grid item>
                    <IconButton
                      color="error"
                      title="Remove diagnose from claim"
                      onClick={() => remove(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Hidden>

                <Grid item sm={6} xs={12}>
                  <Box mt={1}>
                    <Typography variant="body2" color="textSecondary">
                      Diagnose description
                    </Typography>
                  </Box>

                  <Typography>
                    {option?.description || '-'}
                  </Typography>
                </Grid>

                <Hidden lgDown>
                  <Grid item>
                    <IconButton
                      color="error"
                      title="Remove diagnose from claim"
                      onClick={() => remove(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Hidden>
              </Grid>
            ))}

            <Box mt={2}>
              {values.icd_codes?.length < ICDs?.length && (
                <Box component="span" ml={1}>
                  <Button
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => push(null)}
                  >
                    Add diagnose
                  </Button>
                </Box>
              )}
            </Box>
          </>
        )}
      />
    </ContentCard>
  );
};
