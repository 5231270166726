import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { List as MuiList, Typography, Box } from '@material-ui/core';
import { useResizeObserver } from '../../../../../helpers/hooks/useResizeObserver';
import { objectKeysToMap } from '../../../../../helpers/objectKeysToMap';
import { filtersKeysMap } from '../../../../../store/lastFilters';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { Sticky } from '../../../../../components/Sticky';
import { CompaniesContext } from '../CompaniesContext';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const widthBreakpointSmall = 950;
export const widthBreakpointMedium = 1150;
export const widthBreakpointLarge = 1375;
export const minRowHeight = 54;
export const columnsWidths = {
  name: 250,
  address: 200,
  venue: 200,
  phone: 150,
  email: 180,
  contact: 170,
  offices: 100,
  actions: 52
};

export const companiesMap = objectKeysToMap(columnsWidths);

export const List = ({ hiddenColumns = [], onCompanySelect }) => {
  const {
    isFetched,
    isFetching,
    companies,
    meta: { per_page, last_page, page },
    loadNextPage,
    resetCompanies
  } = useContext(CompaniesContext);
  const scrollElementRef = useRef();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filtersKeysMap.contacts_companies]);
  const { width } = useResizeObserver({ ref: scrollElementRef });

  useEffect(() => {
    if (isEmpty(lastFilters)) {
      resetCompanies();
    }
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Loader p={3} loading={!isFetched} render={
        () => !companies.length ? (
          <Box p={3}>
            <Typography align="center">No companies found</Typography>
          </Box>
        ) : (
          <>
            <Sticky containerElement={scrollElementRef.current}>
              <TableHeader
                disableToggle={!!onCompanySelect}
                hiddenColumns={hiddenColumns}
                width={width}
              />
            </Sticky>

            <MuiList disablePadding>
              <InfiniteListLoader
                items={companies}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={{ per_page, last_page, page }}
                RowComponent={Row}
                RowProps={{ hiddenColumns, width, onCompanySelect }}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
                minRowHeight={minRowHeight}
              />
            </MuiList>
          </>
        )}
      />
    </CustomScrollbars>
  );
};
