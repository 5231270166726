export const styles = ({
  spacing,
  palette,
  typography: { fontWeightMedium, pxToRem },
  shape: { borderRadius }
}) => ({
  root: {
    border: 0
  },

  label: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 20,
    paddingLeft: spacing(),
    paddingRight: spacing(),
    borderRadius,
    fontSize: pxToRem(12),
    fontWeight: fontWeightMedium,
    color: palette.error.contrastText,
    backgroundColor: palette.lightgreen
  }
});
