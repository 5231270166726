import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { Label } from '../../../../../../../components/Label';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { CardItem, CardHeader } from '../../../../../../../components/Cards';
import { UserLink } from '../../../../../../../components/UserLink';
import { ClaimsContext, ClaimMenu } from '../../../../../../../components/claims';
import { ClaimPreviewModal } from '../../../ClaimPreviewModal';
import { Body } from './Body';

export const Card = ({ item: claim = {}, view= 'page' }) => {
  const history = useHistory();
  const { toggleClaimSelected } = useContext(ClaimsContext);
  const { openModal } = useModal();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const match = useRouteMatch();

  const openClaimInfo = () => {
    if (isMobile || view === 'modal') {
      openModal(ClaimPreviewModal, {
        payload: {
          id: claim.id
        }
      });
    } else {
      history.push(`${match.url}/${claim.id}`);
    }
  };

  const handleCheckClaim = () => {
    toggleClaimSelected(claim);
  };

  return (
    <CardItem py={1} px={2} onClick={stopPropagation(openClaimInfo)}>
      <CardHeader
        menuComponent={ClaimMenu}
        menuComponentProps={{ claim, view }}
        onItemSelect={handleCheckClaim}
      >
        <Typography noWrap variant="h6">Claim no. {claim.claim_number || '-'}</Typography>
      </CardHeader>

      <Box mt={0.5} mb={1.5}>
        <Body claim={claim} />
      </Box>

      <Box display="flex" alignItems="center">
        <UserLink
          noWrap
          size="lg"
          variant="h5"
          user={claim?.provider_referring}
        >
          {!!claim.office?.full_address &&
            <Box display="flex" alignItems="center" color="textSecondary">
              <Box clone mr={0.5}>
                <LocationOnIcon fontSize="small" />
              </Box>

              <AddressLink
                noWrap
                stopPropagation
                variant="body2"
                name={claim.office?.name}
                address={claim.office?.full_address}
              >
                {claim.office?.full_address || '-'}
              </AddressLink>
            </Box>
          }
        </UserLink>

        <Box ml="auto" pl={2}>
          <Label
            label={claim.billing_info?.total_balance === 0
              ? 'Paid'
              : claim.billing_info?.total_balance < 0
                ? 'Overdue'
                : 'Credit'
            }
          />
        </Box>
      </Box>
    </CardItem>
  );
};
