import { Yup } from '../../../../utils/validation';
import { measureTypes } from '../../../../helpers/measureDataMap';

export const validationSchema = Yup.object().shape({
  medical_id: Yup.mixed().nullable().required(),
  weight: Yup.number().positive().nullable().min(0).max(750).required(),
  height: Yup.mixed().nullable().when('height_unit', {
    is: (value) => value === measureTypes.feet,
    then: Yup.mixed().nullable().required(),
    otherwise: Yup.number().positive().nullable().required()
  }),
  height_mask: Yup.number().nullable().max(300),
  bmi: Yup.number().nullable().max(220),
  height_unit: Yup.string().nullable().required(),
  weight_unit: Yup.string().nullable().required(),
  temperature: Yup.number().positive().nullable().min(60),
  blood_type: Yup.string().nullable().max(10),
  heart_rate: Yup.number().integer().positive().nullable().min(30).max(200),
  systolic_blood_pressure: Yup.number().integer().positive().nullable().min(30).max(300),
  diastolic_blood_pressure: Yup.number().integer().positive().nullable().min(30).max(300)
});
