import { Box, Grid, Typography } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../../../../../components/ReadonlyCheckbox';
import { socialHistoryTypes } from './socialHistoryTypes';

export const SocialHistory = ({ history }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary">
          SOCIAL HISTORY
        </Typography>
      </Grid>

      {!!history?.social_history?.occupation && (
        <>
          <Grid item md={3} xs={12}>
            <Typography variant="h5">Occupation:</Typography>
          </Grid>

          <Grid item md={9} xs={12}>
            <Typography>{history?.social_history?.occupation}</Typography>
          </Grid>
        </>
      )}

      {!!history?.social_history?.tobacco_use && (
        <>
          <Grid item md={3} xs={12}>
            <Typography variant="h5">Tobacco use:</Typography>
          </Grid>

          <Grid item md={9} xs={12}>
            <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
              {history?.social_history?.tobacco_use === socialHistoryTypes.everyday && (
                <>
                  <ReadonlyCheckbox checked title="Everyday" />&nbsp;&nbsp;
                </>
              )}

              {history?.social_history?.tobacco_use === socialHistoryTypes.some_days && (
                <>
                  <ReadonlyCheckbox checked title="Some days" />&nbsp;&nbsp;
                </>
              )}

              {history?.social_history?.tobacco_use === socialHistoryTypes.former_smoker && (
                <>
                  <ReadonlyCheckbox checked title="Former smoker" />&nbsp;&nbsp;
                </>
              )}

              {history?.social_history?.tobacco_use === socialHistoryTypes.never_smoke && (
                <>
                  <ReadonlyCheckbox checked title="Never smoke" />&nbsp;&nbsp;
                </>
              )}

              {history?.social_history?.tobacco_use === socialHistoryTypes.unsure_how_often && (
                <>
                  <ReadonlyCheckbox checked title="Unsure how often" />&nbsp;&nbsp;
                </>
              )}

              {history?.social_history?.tobacco_use === socialHistoryTypes.unknown && (
                <>
                  <ReadonlyCheckbox checked title="Unknown" />&nbsp;&nbsp;
                </>
              )}
            </Box>
          </Grid>
        </>
      )}

      {history?.social_history?.alcohol_use && (
        <>
          <Grid item md={3} xs={12}>
            <Typography variant="h5">Alcohol Use (drinks per day):</Typography>
          </Grid>

          <Grid item md={9} xs={12}>
            <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
              {history?.social_history?.alcohol_use === '6' && (
                <>
                  <ReadonlyCheckbox checked title="6 or more" />&nbsp;&nbsp;
                </>
              )}

              {history?.social_history?.alcohol_use === '4' && (
                <>
                  <ReadonlyCheckbox checked title="4" />&nbsp;&nbsp;
                </>
              )}

              {history?.social_history?.alcohol_use === '2' && (
                <>
                  <ReadonlyCheckbox checked title="2" />&nbsp;&nbsp;
                </>
              )}

              {history?.social_history?.alcohol_use === '1' && (
                <>
                  <ReadonlyCheckbox checked title="1" />&nbsp;&nbsp;
                </>
              )}

              {history?.social_history?.alcohol_use === 'less' && (
                <>
                  <ReadonlyCheckbox checked title="Less than 1" />&nbsp;&nbsp;
                </>
              )}

              {history?.social_history?.alcohol_use === 'not_drink' && (
                <>
                  <ReadonlyCheckbox checked title="Don’t drink" />&nbsp;&nbsp;
                </>
              )}
            </Box>
          </Grid>
        </>
      )}

      {!!history?.social_history?.recreational_drug_use && (
        <>
          <Grid item md={3} xs={12}>
            <Typography variant="h5">Recreational Drug Use:</Typography>
          </Grid>

          <Grid item md={9} xs={12}>
            <ReadonlyCheckbox
              checked
              title={
                history?.social_history?.recreational_drug_use === socialHistoryTypes.yes
                  ? 'Yes' : 'No'
              }
            />
          </Grid>
        </>
      )}

      {history?.social_history?.recreational_drug_use === socialHistoryTypes.yes
        && !!history?.social_history?.yes_frequency && (
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5">Frequency:</Typography>
          <Typography>{history?.social_history?.yes_frequency}</Typography>
        </Grid>
      )}
    </>
  );
};
