const scrollBarWidth = 6;

export const styles = ({ palette: { grey }, transitions }) => ({
  track: {
    borderRadius: `${scrollBarWidth / 2}px !important`,
    opacity: 0.6,
    cursor: 'pointer',
    transition: transitions.create([ 'opacity', 'background' ]),

    '&:hover': {
      opacity: 1,
      background: grey[300]
    }
  },

  track_horizontal: {
    height: `${scrollBarWidth}px !important`,
    width: `calc(100% - ${scrollBarWidth * 2}px) !important`,
    left: `${scrollBarWidth}px !important`
  },

  track_vertical: {
    width: `${scrollBarWidth}px !important`,
    height: `calc(100% - ${scrollBarWidth * 2}px) !important`,
    top: `${scrollBarWidth}px !important`
  },

  thumb: {
    background: `${grey[500]} !important`,
    borderRadius: `${scrollBarWidth / 2}px !important`,
    transition: transitions.create('background'),

    '&:hover': {
      background: grey[600]
    }
  }
});
