import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import * as incomesApi from '../../../../../../api/billing';
import { billingParticipantsTypesMap, billingParticipantsTypesOptions } from '../../../../../../components/billing';
import { ModalFooter, ModalHeader, ModalBody, ModalContainer } from '../../../../../../components/Modal';
import { Loader } from '../../../../../../components/Loader';
import { IncomeForm, validationSchema } from '../CreateIncomeModal';

export const EditIncomeModal = ({
  DialogProps,
  payload: {
    model = {},
    income = {},
    invoiceID = null,
    parentPage = null,
    incomes = []
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ selectedParent, setSelectedParent ] = useState({});

  const updateIncome = (values, { isSubmitting, setSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    if (!values.time_tracks_id.length) {
      enqueueSnackbar('Please, choose tracks', { variant: 'error' });
      setSubmitting(false);

      return;
    }

    values.invoice_id = invoiceID;
    values.participant_type = values.participant_type?.value || values.participant_type;
    values.participant_id = values?.participant_id?.value || values?.participant_id;

    return incomesApi.updateIncome(values).then((data) => {
      enqueueSnackbar('Income successfully updated', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Income not updated', { variant: 'error' });
    });
  };

  useEffect(() => {
    setSelectedParent({
      model: 'case',
      model_id: model?.id
    });
  }, []);

  return (
    <Formik
      initialValues={{
        ...income,
        income: {
          id: income?.id || null,
          time_tracks_id: income?.time_tracks_id || []
        },
        participant_type: billingParticipantsTypesOptions?.find(({ value }) => (
          value === (!income?.participant?.role
            ? billingParticipantsTypesMap.company : billingParticipantsTypesMap.user
          )
        )),
        participant_id: income?.participant,
        caseInfo: {
          ...model,

          file_number: model?.file_number || model?.name
        },
        flat_fee: !!income.hours && !!income.rate,
        incomes
      }}
      validationSchema={validationSchema}
      onSubmit={updateIncome}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="lg" {...DialogProps}>
          <ModalContainer component="form" noValidate onSubmit={handleSubmit}>
            <ModalHeader onClose={handleModalReject}>
              Edit Income
            </ModalHeader>

            <ModalBody>
              <IncomeForm
                model={model}
                parentPage={parentPage}
                selectedParent={selectedParent}
                onSelectedParentUpdate={setSelectedParent}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
