import { useEffect, useState } from 'react';
import cn from 'classnames';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Box, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { IconButton } from '../../../components/IconButton';
import {
  ChatThreadContextProvider,
  MessengerContextProvider
} from '../../../components/chat';
import { MessengerSidebar } from './MessengerSidebar';
import { ChatThread } from './ChatThread';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MessengerPage = () => {
  const threadId = +useParams().threadId;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ isSidebarShown, setIsSidebarShown ] = useState(!isMobile);

  const hideSidebar = () => {
    setIsSidebarShown(false);
  };

  const showSidebar = () => {
    setIsSidebarShown(true);
  };

  const toggleSidebar = () => {
    setIsSidebarShown((isShown) => !isShown);
  };

  useEffect(() => {
    if (isMobile) {
      setIsSidebarShown(false);
    }
  }, [ isMobile ]);

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        {isSidebarShown && isMobile && <div className={classes.chatOverlay} onClick={hideSidebar} />}

        <MessengerContextProvider currentThreadId={threadId}>
          <div
            className={cn({
              [classes.sidebar]: !isMobile,
              [classes.mobileSidebar]: isMobile,
              [classes.mobileSidebarClosed]: !isSidebarShown
            })}
          >
            <MessengerSidebar handleToggleSidebar={toggleSidebar} onHideSideBar={hideSidebar} />
          </div>

          {threadId ? (
            <div className={classes.thread}>
              <ChatThreadContextProvider key={threadId}>
                <ChatThread onShowSidebar={showSidebar}/>
              </ChatThreadContextProvider>
            </div>
          ) : (
            <Box display="flex" flexDirection="column" flexGrow={1}>
              {isMobile &&
                <Box p={1}>
                  <IconButton
                    color="primary"
                    onClick={showSidebar}
                    className={classes.badgeButton}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Box>
              }

              <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
                <Typography variant="h3" align="center">
                  Select chat to start
                </Typography>
              </Box>
            </Box>
          )}
        </MessengerContextProvider>
      </div>
    </div>
  );
};
