export const FETCH_FILES_REQUEST = 'FETCH_FILES_REQUEST';
export const FETCH_FILES_SUCCESS = 'FETCH_FILES_SUCCESS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const RESET_FILES = 'RESET_FILES';
export const ADD_FILES = 'ADD_FILES';
export const DELETE_FILES = 'DELETE_FILES';
export const CHANGE_FILES_TAGS = 'CHANGE_FILES_TAGS';
export const UPDATE_TAG = 'UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const UPDATE_FILE = 'UPDATE_FILE';
export const ADD_FILE_SHARED_USERS = 'ADD_FILE_SHARED_USERS';
export const DELETE_FILE_SHARED_USERS = 'DELETE_FILE_SHARED_USERS';

// Used with FilesFilterContext
export const ADD_FILES_TO_SELECTED = 'ADD_FILES_TO_SELECTED';
export const DELETE_FILES_FROM_SELECTED = 'DELETE_FILES_FROM_SELECTED';
