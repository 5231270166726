import { useContext } from 'react';
import { Grid, Typography, Box, makeStyles } from '@material-ui/core';
import { CardItem, CardHeader } from '../../../../../../../../../components/Cards';
import { CurrencyFormat } from '../../../../../../../../../components/CurrencyFormat';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../../../../components/UserLink';
import { Divider } from '../../../../../../../../../components/Divider';
import { PreviewNegotiationModal } from '../../PreviewNegotiationModal';
import { NegotiationsContext } from '../../NegotiationsProvider';
import { MenuList } from '../MenuList';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

export const Card = ({ negotiation = {} }) => {
  const { openModal } = useModal();
  const { caseItem, updateNegotiation, deleteNegotiation } = useContext(NegotiationsContext);
  const classes = useStyles();

  const openPreview = () => {
    openModal(PreviewNegotiationModal, {
      payload: {
        caseItem,
        initialValues: negotiation,
        deleteNegotiation,
        updateNegotiation
      }
    });
  };

  return (
    <CardItem borderWidth={2} onClick={openPreview}>
      <CardHeader
        size="small"
        disableCheckBox
        childrenWidth={11}
        p={1}
        menuComponent={MenuList}
        menuComponentProps={{ negotiation }}
      >
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={8}>
            <UserLink
              size="xs"
              variant="subtitle2"
              user={negotiation?.user}
              className={classes.text}
            />
          </Grid>

          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" alignItems="center" fontSize={10}>
              <CurrencyFormat
                TypographyProps={{ noWrap: true, variant: 'subtitle2' }}
                value={negotiation?.total_to_providers || 0}
              />

              <Typography color="textSecondary" className={classes.text}>
                Meds
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardHeader>

      <Divider />

      <Grid container spacing={2} component={Box} p={1} py={2}>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={7}>
            <Typography gutterBottom variant="subtitle2">
              Total Funds Received
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <CurrencyFormat
              value={negotiation?.total_founds_received || 0}
              TypographyProps={{ gutterBottom: true, noWrap: true, variant: 'subtitle2' }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={1} justify="flex-end" xs={12}>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Atty Fees ({negotiation?.atty_costs_custom || 0}%)
            </Typography>
          </Grid>

          <Grid item xs={5} />
        </Grid>

        <Grid item xs={12} container spacing={1} justify="flex-end">
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Atty’s Costs
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <CurrencyFormat
              value={negotiation?.atty_costs || 0}
              TypographyProps={{ noWrap: true, variant: 'subtitle2' }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={1} justify="flex-end">
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Legal Costs
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <CurrencyFormat
              value={negotiation?.atty_fee_legal_costs || 0}
              TypographyProps={{ noWrap: true, variant: 'subtitle2' }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={1}>
          <Grid item xs={7}>
            <Typography variant="body2" color="textSecondary">
              Net Atty Fees
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <CurrencyFormat
              value={negotiation?.net_atty_fees || 0}
              TypographyProps={{ noWrap: true, variant: 'subtitle2' }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={1}>
          <Grid item xs={7}>
            <Typography gutterBottom variant="subtitle2">
              Total to Providers
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <CurrencyFormat
              value={negotiation.total_to_providers || 0}
              TypographyProps={{ gutterBottom: true, noWrap: true, variant: 'h5' }}
            />
          </Grid>

          <Grid item xs={7}>
            <Typography gutterBottom variant="subtitle2">
              Total to Adjuster
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <CurrencyFormat
              value={negotiation.total_to_client || 0}
              TypographyProps={{ gutterBottom: true, noWrap: true, variant: 'h5' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardItem>
  );
};
