/* eslint-disable */

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Web, SessionState, Inviter } from 'sip.js';
import { makeStyles } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { IconButton } from '../../../components/IconButton';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SIP = () => {
  const currentUser = useSelector(({ profile }) => profile.user);
  const caller = currentUser.id === 13 ? 'sip:000000112233@cloud.oxeltech.com:8089' : 'sip:000000445566@cloud.oxeltech.com:8089';
  const callee = currentUser.id === 13 ? 'sip:000000445566@cloud.oxeltech.com:8089' : 'sip:000000112233@cloud.oxeltech.com:8089';
  const classes = useStyles();
  const [ isOnCall, setIsOnCall ] = useState(false);
  const [ isIncomingCall, setIsIncomingCall ] = useState(false);
  const sipUserRef = useRef(null);

  const call = () => {
    sipUserRef.current.call(callee, undefined, {
      // An example of how to get access to a SIP response message for custom handling
      requestDelegate: {
        onReject: (response) => {
          console.warn(`[ INVITE rejected`);
          let message = `Session invitation to "${1}" rejected.\n`;
          message += `Reason: ${response.message.reasonPhrase}\n`;
          message += `Perhaps "${1}" is not connected or registered?\n`;
          message += `Or perhaps "${1}" did not grant access to video?\n`;
          alert(message);
        }
      },
      withoutSdp: false
    }).catch((error) => {
      console.error(` failed to begin session`);
      console.error(error);
      alert(` Failed to begin session.\n` + error);
    });
  };

  const endCall = () => {
    const { session } = sipUserRef.current;

    setIsOnCall(false);

    switch(session?.state) {
      case SessionState.Initial:
      case SessionState.Establishing:
        if (session instanceof Inviter) {
          // An unestablished outgoing session
          console.log(1)
          session.cancel();
        } else {
          // An unestablished incoming session
          console.log(2)
          session.reject();
        }
        break;
      case SessionState.Established:
        // An established session
        console.log(3)

        session.bye();
        break;
      case SessionState.Terminating:
      case SessionState.Terminated:
        // Cannot terminate a session that is already terminated
        break;
    }
  }

  useEffect(async () => {
    sipUserRef.current = new Web.SimpleUser('wss://cloud.oxeltech.com:8089/ws', {
      aor: caller,
      userAgentOptions: {
        authorizationUsername: currentUser.id === 13 ? '000000112233' : '000000445566',
        authorizationPassword: 'ShabbatNonStop',
      },
      delegate: {
        onCallReceived: () => {
          console.log('Incoming Call!');
          setIsIncomingCall(true);
        },
        onInvite: () => {
          console.log('onInvite');
          setIsIncomingCall(true);
        },
        onCallCreated: () => {
          console.log('onCallCreated');
          setIsIncomingCall(false);
        },
        onCallAnswered: () => {
          console.log('onCallAnswered');
          setIsIncomingCall(false);
          setIsOnCall(true);
        },
        onCallHangup: () => {
          console.log('onCallHangup');
          setIsOnCall(false);
          setIsIncomingCall(false);
        }
      },
      media: {
        constraints: { audio: true, video: true },
        local: {
          video: document.getElementById('videoLocal')
        },
        remote: {
          video: document.getElementById('videoRemote')
        }
      }
    });

    console.log(sipUserRef.current)

    await sipUserRef.current.connect();
    await sipUserRef.current.register();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.video}>
        <video id="videoRemote" width="100%" muted="muted">
          <p>Your browser doesn't support HTML5 video.</p>
        </video>

        <div className={classes.videoLocal}>
          <video id="videoLocal" width="100%" muted="muted">
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </div>
      </div>

      <div>
        {!isOnCall && isIncomingCall &&
          <IconButton color="error" onClick={endCall}>
            <CallEndIcon />
          </IconButton>
        }

        <IconButton
          color={isOnCall && !isIncomingCall ? 'error' : 'success'}
          onClick={isOnCall && !isIncomingCall ? endCall : isIncomingCall ? () => sipUserRef.current.answer() : call}
        >
          {isOnCall ? <CallEndIcon /> : <CallIcon />}
        </IconButton>
      </div>
    </div>
  );
};
