import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { InsuranceClaimsTooltipCounter } from '../../../../../../components/claims/InsuranceClaimsTooltipCounter';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Divider } from '../../../../../../components/Divider';
import { Label } from '../../../../../../components/Label';
import { Date } from '../../../../../../components/Date';

export const Body = ({ caseItem }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const types = caseItem?.case_type?.map(({ name }) => name).join(', ');

  return (
    <Grid container spacing={2} component={Box} p={2}>
      <Grid item xs={isMobile ? 6 : 3}>
        <Typography variant="h5">Case Open</Typography>
      </Grid>

      <Grid item xs={isMobile ? 6 : 3}>
        <Date disableIcon date={caseItem.created_at} />
      </Grid>

      <Grid item xs={isMobile ? 6 : 3}>
        <Typography variant="h5">Case status</Typography>
      </Grid>

      <Grid item xs={isMobile ? 6 : 3}>
        {caseItem.case_status ?
          <Label label={caseItem.case_status.name} color={theme.palette.primary.main} />
          :
          <Typography color="textSecondary">No info.</Typography>
        }
      </Grid>

      <Grid item xs={isMobile ? 6 : 3}>
        <Typography variant="h5">Case type</Typography>
      </Grid>

      <Grid item xs={isMobile ? 6 : 3}>
        <Tooltip
          isExistTooltip={!!types?.length}
          leaveDelay={200}
          placement="bottom-start"
          title={types}
        >
          <Typography noWrap>{types}</Typography>
        </Tooltip>
      </Grid>

      <Grid item xs={isMobile ? 6 : 3}>
        <Typography variant="h5"> Claims#</Typography>
      </Grid>

      <Grid item xs={isMobile ? 6 : 3}>
        <InsuranceClaimsTooltipCounter caseItem={caseItem} />
      </Grid>

      <Grid item xs={12}>
        <Divider gutter={1} />
      </Grid>
    </Grid>
  );
};
