import React from 'react';
import { Formik } from 'formik';
import { Box, Button, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { Loader } from '../../../../../components/Loader';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeaderActions
} from '../../../../../components/Modal';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { InsuranceForm } from '../AddInsuranceInfoModal';
import { IsMainInsuranceToggler } from '../AddInsuranceInfoModal/IsMainInsuranceToggler';
import { validationSchema } from '../validationSchema';

export const EditInsuranceForm = ({
  initialValues,
  user,
  mainPage,
  insurance,
  editInsurance,
  onClose,
  onCaseItemUpdate = () => {},
  onDelete = () => {}
}) => {
  return (
    <Formik
      initialValues={{ ...initialValues, mainPage }}
      validationSchema={validationSchema}
      onSubmit={editInsurance}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <ModalHeader onClose={onClose}>
            Edit Insurance Card

            <ModalHeaderActions disableGutters>
              <IsMainInsuranceToggler />

              {!hasRole(rolesMap.client, rolesMap.patient) && (
                <Tooltip title="Delete">
                  <IconButton onClick={onDelete}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </ModalHeaderActions>
          </ModalHeader>

          <ModalBody>
            <InsuranceForm
              user={user}
              mainPage={mainPage}
              insurance={insurance}
              onCaseItemUpdate={onCaseItemUpdate}
            />
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>

            <Loader surface loading={isSubmitting} render={
              () => (
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              )}
            />
          </ModalFooter>
        </Box>
      )}
    </Formik>
  );
};
