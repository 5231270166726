export const agesOptions = [
  {
    value: '0-30',
    label: '0-30 yrs'
  },
  {
    value: '30-60',
    label: '30-60 yrs'
  },
  {
    value: '60+',
    label: '60+ yrs'
  }
];
