import { useContext } from 'react';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { useModal } from '../../../../../../../components/ModalsProvider';
import {
  PreviewReportModal,
  ReportsContext
} from '../../../../../../../components/medical/reports';
import {
  DiagnoseRow
} from '../../../../../../../components/medical/reports/diagnosis/DiagnosisList/DiagnoseRow';

export const Row = ({ item: report, ...props }) => {
  const { openModal } = useModal();
  const { pathname, resetReports, deleteReport } = useContext(ReportsContext);

  const openPreview = () => {
    openModal(PreviewReportModal, {
      payload: {
        pathname,
        reportID: report.id,
        onDelete: deleteReport,
        onUpdate: resetReports
      }
    });
  };

  return (
    <DiagnoseRow
      report={report}
      onClick={stopPropagation(openPreview)}

      {...props}
    />
  );
};
