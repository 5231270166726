export const claimsStatusesMap = {
  hold: 'hold',
  open: 'open',
  void: 'void',
  rejected: 'rejected',
  electronic_submit: 'electronic_submit'
};

export const creationClaimsStatuses = [
  {
    value: claimsStatusesMap.open,
    label: 'Open'
  },
  {
    value: claimsStatusesMap.hold,
    label: 'Hold'
  },
  {
    value: claimsStatusesMap.electronic_submit,
    label: 'Electronic submit'
  }
];

export const allClaimsStatuses = [
  {
    value: claimsStatusesMap.hold,
    label: 'Hold'
  },
  {
    value: claimsStatusesMap.void,
    label: 'Void'
  },
  {
    value: claimsStatusesMap.open,
    label: 'Open'
  },
  {
    value: claimsStatusesMap.rejected,
    label: 'Rejected'
  },
  {
    value: claimsStatusesMap.electronic_submit,
    label: 'Electronic submit'
  }
];
