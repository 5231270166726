import { Box, Grid, Typography } from '@material-ui/core';
import { AddressLink } from '../../../../../../../components/AddressLink';
import { CompanyLink } from '../../../../../../../components/CompanyLink';
import { Divider } from '../../../../../../../components/Divider';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { PreviewCompanyModal } from '../../../../Companies/PreviewCompanyModal';
import { ContactInfo } from './ContactInfo';
import { EmailInfo } from './EmailInfo';

export const MainContent = ({ office }) => {
  const { openModal } = useModal();

  const openPreview = () => {
    openModal(PreviewCompanyModal, {
      payload: { companyID: office.company?.id }
    });
  };

  return (
    <Box pt={3} px={2}>
      <Box color="info.main">
        <Typography variant="button" component="h3" color="inherit">
          Main info
        </Typography>
      </Box>

      <Grid container spacing={1} component={Box} pt={3} pb={2}>
        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Company Name
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <CompanyLink
            variant="h5"
            company={office?.company}
            onClick={openPreview}
          />
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Country
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">{office.country}</Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Practice
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h5">{office.practice}</Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography noWrap color="textSecondary">
            Office Address
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <AddressLink variant="h5" address={office.full_address}>
            {office.full_address}
          </AddressLink>
        </Grid>
      </Grid>

      <Divider />
      <ContactInfo office={office} />
      <Divider />
      <EmailInfo office={office} />
    </Box>
  );
};
