import { Box } from '@material-ui/core';
import { WidgetsActionButtons } from '../../../../../../../components/WidgetsActionButtons';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../../components/ContentCard';
import { PlaintiffsPDContent } from './PlaintiffsPDContent';
import { EditPlaintiffsPDModal } from './EditPlaintiffsPDModal';

export const PlaintiffsPD = ({
  caseItem,
  page,
  height = null,
  isDisabledOpen = false,
  onCaseItemUpdate = () => {},
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  ...props
}) => {
  const { openModal } = useModal();

  const editChecklist = () => {
    openModal(EditPlaintiffsPDModal, {
      payload: {
        caseID: caseItem.id,
        plaintiffsPD: caseItem.plaintiff
      },
      onModalResolved: (plaintiff) => {
        onCaseItemUpdate({ ...caseItem, plaintiff });
      }
    });
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      title="Plaintiff's PD"
      rightActions={[
        <WidgetsActionButtons
          isEditable
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
          openWidgetModal={editChecklist}
        />
      ]}
      {...props}
    >
      <Box height={height || '100%'}>
        <PlaintiffsPDContent plaintiff={caseItem.plaintiff} />
      </Box>
    </ContentCard>
  );
};
