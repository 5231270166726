export const filterFieldsMap = {
  usersId: 'users_id',
  caseId: 'case_id',
  companyId: 'company_id',
  from: 'from',
  to: 'to',
  orderBy: 'order_by'
};

export const filterFieldsLabels = {
  [filterFieldsMap.usersId]: 'Users',
  [filterFieldsMap.caseId]: 'Cases',
  [filterFieldsMap.companyId]: 'Company',
  [filterFieldsMap.from]: 'Date from',
  [filterFieldsMap.to]: 'Date to',
  [filterFieldsMap.orderBy]: 'Order By'
};
