import { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { makeStyles, Box, Paper } from '@material-ui/core';
import * as claimsApi from '../../../../../api/claims';
import { useResizeObserver } from '../../../../../helpers/hooks';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import {
  ClaimForm,
  validationSchema,
  claimInitialValues,
  transformClaimReq
} from '../../../../../components/claims/ClaimForm';
import { SideBar } from '../SideBar';
import { styles } from '../ClaimPreview/styles';
import { ActionsBar } from './ActionsBar';

const useStyles = makeStyles(styles);

export const Create = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState(false);
  const scrollerRef = useRef();
  const classes = useStyles();
  const rootRef = useRef(null);
  const { width = window.innerWidth } = useResizeObserver({ ref: rootRef });

  const createClaim = (values, { isSubmitting, setErrors, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    if (!values.appointment?.procedures?.length) {
      enqueueSnackbar('The claim must have an appointment with procedures', {
        variant: 'warning'
      });

      setSubmitting(false);

      return;
    }

    return claimsApi.createClaim(transformClaimReq(values)).then(() => {
      history.push('/billing/claims');
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={claimInitialValues}
      validationSchema={validationSchema}
      onSubmit={createClaim}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <form noValidate className={classes.root} onSubmit={handleSubmit}>
          <Paper
            ref={rootRef}
            elevation={2}
            component={Box}
            display="flex"
            position="relative"
            width="100%"
            height="100%"
            overflow="hidden"
          >
            <SideBar width={width} claim={values} sidebarIsOpen={sidebarIsOpen} />

            <div className={classes.main}>
              <ActionsBar
                isSubmitting={isSubmitting}
                sidebarIsOpen={sidebarIsOpen}
                setSidebarIsOpen={setSidebarIsOpen}
              />

              <Box flexGrow={1}>
                <CustomScrollbars scrollerRef={scrollerRef} style={{ height: '100%' }}>
                  <Box p={2}>
                    <ClaimForm />
                  </Box>
                </CustomScrollbars>
              </Box>
            </div>
          </Paper>
        </form>
      )}
    </Formik>
  );
};
