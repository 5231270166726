import moment from 'moment';
import { useContext, useState } from 'react';
import { Box, Button, FormControlLabel, Grid, makeStyles, Switch, Typography } from '@material-ui/core';
import { accidentTypesTitlesMap } from '../../../../components/medical/accidentTypes';
import { Date } from '../../../../components/Date';
import { Loader } from '../../../../components/Loader';
import { formTypesMap } from '../../../../components/medical/forms';
import { MedicalFormItemContext } from '../../ProfilePage/MedicalInfo/MedicalFormItemProvider';
import { FormContext } from '../FormProvider';
import { styles } from '../PrivatePacket/ReviewContent/styles';
import {
  componentsMapInjury,
  componentsMapInjuryOrtho,
  componentsMapPrivate
} from './componentsMap';

const useStyles = makeStyles(styles);

export const PreviewContent = () => {
  const classes = useStyles();
  const [ isOpenSwitches, setIsOpenSwitches ] = useState(true);
  const formContext = useContext(FormContext);
  const medicalFormItemContext = useContext(MedicalFormItemContext);
  const context = medicalFormItemContext || formContext;
  const {
    form,
    isFetched,
    handleReset,
    handleBack
  } = context;

  const handleSwitchChange = (event) => {
    setIsOpenSwitches(event.target.checked);
  };

  const handleBackStep = () => {
    handleBack({ dirty: false, id: form.id });
  };

  const handleResetStep = () => {
    handleReset(form.id);
  };

  return (
    <Loader loading={!isFetched} p={2} render={
      () => (
        <div className={classes.root}>
          <Box display="flex" justifyContent="flex-end" mr={1} pb={2}>
            <FormControlLabel
              label="Open all info"
              control={
                <Switch
                  color="primary"
                  checked={isOpenSwitches}
                  onChange={handleSwitchChange}
                />
              }
            />
          </Box>

          <Grid
            container
            component={Box}
            spacing={2}
            flexGrow={1}
            pb={2}
            px={0}
            m="0 !important"
          >
            {form?.form_type !== formTypesMap.compensation && form?.form_type !== formTypesMap.private &&
              <>
                <Grid item xs={6} sm={4}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography color="textSecondary">
                        Accident Type
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="h5">
                        {accidentTypesTitlesMap[form?.accident_type] || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6} sm={4}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography color="textSecondary">
                        Accident State
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="h5">
                        {form?.auto_accident_state || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6} sm={4}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography color="textSecondary">
                        Accident Country
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="h5">
                        {form?.auto_accident_country || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            }

            {form?.form_type === formTypesMap.compensation &&
              <>
                <Grid item xs={12}>
                  <Box color="info.main">
                    <Typography variant="h5" color="inherit">
                      Unable to Work
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Typography color="textSecondary">From</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Date
                    disableMoment
                    variant="h5"
                    iconFontSize="small"
                    date={form?.unable_work_date_from && moment(form?.unable_work_date_from).format('L')}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography color="textSecondary">To</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Date
                    disableMoment
                    variant="h5"
                    iconFontSize="small"
                    date={form?.unable_work_date_to && moment(form?.unable_work_date_to).format('L')}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography color="textSecondary">Last Work Date</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Date
                    disableMoment
                    variant="h5"
                    iconFontSize="small"
                    date={form?.last_work_date && moment(form?.last_work_date).format('L')}
                  />
                </Grid>
              </>
            }

            <Grid item xs={12}>
              {form.form_type === formTypesMap.injury ?
                componentsMapInjury.map((Component, i) => (
                  <Component
                    key={i}
                    isOpenSwitches={isOpenSwitches}
                    form={form}
                    toggleSwitches={setIsOpenSwitches}
                  />
                ))
                : form.form_type === formTypesMap.ortho ?
                  componentsMapInjuryOrtho.map((Component, i) => (
                    <Component
                      key={i}
                      isOpenSwitches={isOpenSwitches}
                      form={form}
                      toggleSwitches={setIsOpenSwitches}
                    />
                  )) : componentsMapPrivate.map((Component, i) => (
                    <Component
                      key={i}
                      isOpenSwitches={isOpenSwitches}
                      form={form}
                      toggleSwitches={setIsOpenSwitches}
                    />
                  ))
              }
            </Grid>
          </Grid>

          <Box flexGrow={0} ml="auto" pb={2}>
            <Box clone mr={1}>
              <Button
                onClick={handleBackStep}
                color="primary"
              >
                Back
              </Button>
            </Box>

            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleResetStep}
            >
              {!!medicalFormItemContext ? 'Approve' : 'Save'}
            </Button>
          </Box>
        </div>
      )}
    />
  );
};
