import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useContext, useEffect, useState } from 'react';
import { IconButton } from '../../../../../../components/IconButton';
import { ListRow, ListRowCell } from '../../../../../../components/ListRow';
import { Loader } from '../../../../../../components/Loader';
import { useModal } from '../../../../../../components/ModalsProvider';
import { ReadonlyCheckbox } from '../../../../../../components/ReadonlyCheckbox';
import {
  ScheduleEventTemplatesContext,
  EditEventTemplateModal, ViewEventTemplateModal
} from '../../../../../../components/schedule-events/templates';
import { Tooltip } from '../../../../../../components/Tooltip';
import { stopPropagation } from '../../../../../../helpers/dom';
import { columnsWidths } from '../List';

export const Row = ({ item: eventTemplate, isLoaded, recalculateHeight }) => {
  const { openModal } = useModal();
  const eventTemplatesContext = useContext(ScheduleEventTemplatesContext);
  const [ isBusy, setIsBusy ] = useState(false);

  const openPreview = () => {
    openModal(ViewEventTemplateModal, {
      payload: {
        template: eventTemplate
      },

      onModalResolved: (eventTemplate) => {
        eventTemplatesContext.updateActiveEventTemplate(eventTemplate);
      }
    });
  };

  const editEventTemplate = () => {
    openModal(EditEventTemplateModal, {
      payload: {
        initialValues: eventTemplate
      },

      onModalResolved: (eventTemplate) => {
        eventTemplatesContext.updateActiveEventTemplate(eventTemplate);
      }
    });
  };

  const deleteEventTemplate = () => {
    setIsBusy(true);

    eventTemplatesContext.deleteEventTemplate(eventTemplate).then(() => {
      setIsBusy(false);
    });
  };

  useEffect(() => {
    isLoaded && recalculateHeight();
  }, [ isLoaded ]);

  return (
    <Loader fullWidth p={isBusy ? 0 : 2} surface={isBusy} loading={!isLoaded || isBusy} render={
      () => (
        <ListRow button onClick={openPreview}>
          <ListRowCell grow flexBasis={columnsWidths.name}>
            {eventTemplate.name}
          </ListRowCell>

          <ListRowCell flexBasis={columnsWidths.isPublic}>
            <ReadonlyCheckbox checked={eventTemplate.is_public} />
          </ListRowCell>

          <ListRowCell justifyContent="flex-end" flexBasis={columnsWidths.actions}>
            <Tooltip title="Edit template">
              <IconButton
                color="info"
                onClick={stopPropagation(editEventTemplate)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete template">
              <IconButton
                color="error"
                onClick={stopPropagation(deleteEventTemplate)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </ListRowCell>
        </ListRow>
      )}
    />
  );
};
