import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Button, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import * as codesApi from '../../../../../../api/codes/procedures';
import { Loader } from '../../../../../../components/Loader';
import {
  Page,
  PageBody,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle
} from '../../../../../../components/Page';
import { validationSchema } from '../validationSchema';
import { initialValues } from './initialValue';
import { Body } from './Body';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Create = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const createCPTCode = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return codesApi.createCode(values).then(() => {
      enqueueSnackbar('Code successfully created', { variant: 'success' });
      history.push('/codes/procedure-codes/cpt');
    }).catch(({ errors, message }) => {
      if (errors) {
        const errorMessage = Object.values(errors);

        setErrors(errors);
        message && enqueueSnackbar(message + ' ' + errorMessage, { variant: 'error' });
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createCPTCode}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form noValidate className={classes.root} onSubmit={handleSubmit}>
          <Page>
            <PageHeader>
              <IconButton
                component={Link}
                to="/codes/procedure-codes/cpt"
                color="primary"
                edge="start"
              >
                <KeyboardBackspaceOutlinedIcon />
              </IconButton>

              <PageHeaderTitle>
                Add CPT
              </PageHeaderTitle>

              <PageHeaderActions>
                <Button
                  size={isMobile ? 'small' : 'medium'}
                  component={Link}
                  to="/codes/procedure-codes/cpt"
                  color="primary"
                >
                  Cancel
                </Button>

                <Loader surface loading={isSubmitting} render={
                  () => (
                    <Button
                      autoFocus
                      size={isMobile ? 'small' : 'medium'}
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                />
              </PageHeaderActions>
            </PageHeader>

            <PageBody>
              <Body />
            </PageBody>
          </Page>
        </form>
      )}
    </Formik>
  );
};
