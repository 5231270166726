import { Box, Grid, InputAdornment, Typography } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import {
  returnWorkIndicatorsOptions
} from '../../../../../../components/claims/SituationalNumberFormModal/SituationalNumberForm/data';
import { Autocomplete, IcdCodesSelect, TextField } from '../../../../../../components/FormField';

export const Diagnostic = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <IcdCodesSelect
          multiple
          disableValueTransform
          name="forms.diagnostic_impression.icd"
          label="Diagnosis(DX)"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="forms.diagnostic_impression.note"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="subtitle2">
                  <Box component="span" mr={0.5}>
                    <NotesIcon fontSize="inherit" />
                  </Box>
                  Add Note
                </Typography>
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          label="Disability"
          name="forms.diagnostic_impression.disability"
          margin="dense"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <Autocomplete
          name="forms.diagnostic_impression.return_work_indicator"
          label="Return To Work Indicator"
          margin="dense"
          options={returnWorkIndicatorsOptions}
        />
      </Grid>
    </Grid>
  );
};
