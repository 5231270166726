import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { columnsWidths } from '../SavedFiltersList';

export const TableHeader = () => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell flexBasis={columnsWidths.patients}>
          Patients
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.providers}>
          Providers
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.apptBooks}>
          Appt. Books
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.apptTypes}>
          Appt. Types
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.visitReasons}>
          Visit Reasons
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.appointmentDayFrom}>
          Appt. Day From
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.appointmentDayTo}>
          Appt. Day To
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.orderBy}>
          Order By
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
