import { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../helpers/dom';
import { hasRole } from '../../../../../../utils/hasRole';
import { UserPreviewModal } from '../../../../../../components/users';
import { CardItem, CardHeader, CardFooter } from '../../../../../../components/Cards';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../../components/UserLink';
import { Divider } from '../../../../../../components/Divider';
import { Date } from '../../../../../../components/Date';
import { PreviewImmunizationModal } from '../../PreviewImmunizationModal';
import { ImmunizationContext } from '../../ImmunizationProvider';
import { ImmunizationsMenu } from '../../ImmunizationsMenu';
import { Body } from './Body';

export const Card = ({ item: immunization = {} }) => {
  const { openModal } = useModal();
  const {
    selectedIDs,
    toggleItemSelection,
    fetchImmunizationReports
  } = useContext(ImmunizationContext);
  const isSelected = selectedIDs.indexOf(immunization.id) !== -1;

  const handleItemSelection = () => {
    toggleItemSelection(immunization.id);
  };

  const openPreview = () => {
    openModal(PreviewImmunizationModal, {
      payload: {
        immunization,
        onUpdate: fetchImmunizationReports
      }
    });
  };

  const openUserPreview = (id) => () => {
    if (id) {
      openModal(UserPreviewModal, {
        payload: { id }
      });
    }
  };

  return (
    <CardItem selected={isSelected} py={1} px={2} onClick={stopPropagation(openPreview)}>
      <CardHeader
        isSelected={isSelected}
        disableCheckBox={hasRole('client', 'patient')}
        disableCardMenu={hasRole('client', 'patient')}
        onItemSelect={handleItemSelection}
        menuComponent={ImmunizationsMenu}
        menuComponentProps={{ immunization }}
      >
        <Date date={immunization.date} iconFontSize="small" variant="subtitle2" />
      </CardHeader>

      <Body immunization={immunization} />
      <Divider gutter={1} disableBottomGutter />

      <CardFooter
        pb={0.5}
        countMedia={immunization?.count_media || 0}
        countComments={immunization?.count_comments || 0}
      >
        <UserLink
          size="lg"
          variant="h5"
          user={immunization.approved}
          onClick={stopPropagation(openUserPreview(immunization?.approved?.id))}
        >
          <Box mt={-0.5}>
            <Typography color="textSecondary" variant="caption">Provider/Rendering</Typography>
          </Box>
        </UserLink>
      </CardFooter>
    </CardItem>
  );
};
