import { orderByOptions } from '../MainWindow/FiltersBar/orderByOptions';
import { orderDirectionsMap } from '../../../../../components/FormField';

export const initialState = {
  isFetching: false,
  isFetched: false,
  cases: [],
  pagination: {
    total: 0,
    last_page: 1
  },
  filter: {
    page: 1,
    per_page: 15,
    order_by: orderByOptions[0].value,
    order_direction: orderDirectionsMap.desc
  },
  selectedCasesIDs: [],
  selectedCases: []
};
