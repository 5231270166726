import { useMemo } from 'react';
import { Box, FormControlLabel, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {
  Checkbox,
  EmailsSelect,
  PhoneField,
  Select
} from '../../../../../components/FormField';
import { usersRolesOptions } from '../../../../../dataMaps';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';

export const InfoContent = ({ disableRoleField }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const filteredUserRolesOptions = useMemo(() => {
    return !hasRole(rolesMap.admin)
      ? usersRolesOptions.filter((role) => role.value !== rolesMap.admin)
      : usersRolesOptions;
  }, []);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h3">Select User Role</Typography>
      </Grid>

      <Grid item xs={isMobile ? 12 : 8}>
        <Select
          isDisabled={disableRoleField}
          formattedValue
          isClearable
          name="role"
          label="Role"
          options={filteredUserRolesOptions}
          TextFieldProps={{ required: true }}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <FormControlLabel
          label="Is active"
          control={<Checkbox name="is_active" />}
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 8}>
        <EmailsSelect
          required
          name="email"
          label="Profile email"
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <FormControlLabel
          label={<Typography>Send register email</Typography>}
          control={
            <Checkbox
              color="primary"
              name="is_notify"
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Box mt={2}>
          <Typography gutterBottom variant="h3">Phone</Typography>
        </Box>
      </Grid>

      <Grid item sm={4}>
        <PhoneField
          name="phone_number"
          label="Phone number"
        />
      </Grid>

      <Grid item sm={4}>
        <PhoneField
          name="mobile_phone_number"
          label="Mobile phone number"
        />
      </Grid>

      <Grid item sm={4}>
        <PhoneField
          name="fax"
          label="Fax"
        />
      </Grid>
    </Grid>
  );
};
