import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Box, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { moveArrayItem } from '../../../../../helpers/moveArrayItem';
import { ChecklistForm } from '../../Checklist/ChecklistForm';
import { List } from '../../Checklist/List';
import { EditForm } from './EditForm';

export const Checklist = () => {
  const { values, setFieldValue } = useFormikContext();
  const [ checklist, setChecklist ] = useState(values?.todo_items || []);

  const addTodoItem = (text) => () => {
    const isExist = !!checklist?.find((item) => item?.text === text.trim());

    if (!isExist) {
      setFieldValue('text', null);

      setChecklist([ ...checklist,  { text: text.trim(), is_checked: 0 } ]);
    }
  };

  const handleListItemDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    const newChecklist = moveArrayItem(
      checklist,
      source.index,
      destination.index
    );

    setChecklist(newChecklist);
  };

  const updateChecklistItem = (listItem, newValue = null) => {
    const newChecklist = checklist?.map((item) => (item?.text === listItem.text
      ? { ...listItem, text: newValue || listItem.text }
      : item
    ));

    setChecklist(newChecklist);
  };

  const onDelete = (listItem) => {
    const newChecklist = checklist.filter((item) => item.text !== listItem.text);

    setChecklist(newChecklist);
  };

  const reset = () => {
    setFieldValue('text', null);
  };

  useEffect(() => {
    setFieldValue('todo_items', checklist);
  }, [ checklist ]);

  return (
    <Box mb={5}>
      <Typography variant="h4">Checklist</Typography>

      <ChecklistForm
        disableSubmit
        buttonSize="small"
        onAddItem={addTodoItem}
        onReset={reset}
      />

      <DragDropContext onDragEnd={handleListItemDragEnd}>
        <List
          checklist={checklist}
          EditForm={EditForm}
          onUpdate={updateChecklistItem}
          onDelete={onDelete}
        />
      </DragDropContext>
    </Box>
  );
};
