import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../components/ListRow';
import { filterFieldsMap } from '../../filterFieldsMap';
import { columnsWidths } from '../List';

export const TableHeader = ({ hiddenColumns = [] }) => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell flexBasis={columnsWidths.claimNo}>
          Claim #
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.providers}>
          Providers
        </ListRowCell>

        {!hiddenColumns.includes(filterFieldsMap.patients) &&
          <ListRowCell flexBasis={columnsWidths.patients}>
            Patients
          </ListRowCell>
        }

        {!hiddenColumns.includes(filterFieldsMap.adjusters) &&
          <ListRowCell flexBasis={columnsWidths.adjusters}>
            Adjusters
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.dosFrom}>
          DOS From
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.dosTo}>
          DOS To
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.offices}>
          Offices
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.claimStatus}>
          Claim Status
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.orderBy}>
          Order By
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
