import * as templatesApi from '../../../../api/profile/medical-reports';
import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import { Select } from '../Select';

export const transformTemplateToOption = (template) => template && ({
  label: template.name,
  value: template.id,
  data: template
});

const fetchPrescriptionsTemplates = (params) => fetchDataForAsyncSelect((name, { page }) => {
  return templatesApi.fetchPrescriptionTemplates({ params: { name, page, ...params } });
}, {
  transformDataToOption: transformTemplateToOption
});

export const PrescriptionsTemplatesSelect = ({ params = {}, ...props }) => {
  return (
    <Select
      isAsync
      formattedValue
      name="template_id"
      label="Search template..."
      promiseWithOptions={fetchPrescriptionsTemplates(params)}
      {...props}
    />
  );
};
