import { useContext } from 'react';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import { IconButton } from '../../../../../IconButton';
import { ListRow, ListRowCell } from '../../../../../ListRow';
import { useModal } from '../../../../../ModalsProvider';
import { generateDescription } from '../../../../options';
import { EditRuleFormModal } from '../../../EditRuleFormModal';
import { RuleTemplatesContext } from '../../../RuleTemplatesProvider';
import { minRowHeight, columnsWidths } from '../Rules';

export const RuleItem = ({ item: rule = {} }) => {
  const { deleteRule, fetchRules } = useContext(RuleTemplatesContext);
  const { openModal } = useModal();

  if (rule) {
    rule.description = generateDescription(rule.conditions, 'condition');
    rule.description += `, ${generateDescription(rule.actions, 'action', undefined, !rule.stop_processing)}`;
    rule.description += rule.stop_processing ? ' and stop processing more rules on this message' : '';
  }

  const handleEditEmailRule = () => {
    openModal(EditRuleFormModal, {
      payload: {
        initialRule: rule
      },
      onModalResolved: () => {
        fetchRules();
      }
    });
  };

  const handleDeleteEmailRule = () => {
    deleteRule(rule.id);
  };

  return !!rule && (
    <ListRow minHeight={minRowHeight} py={3} px={2}>
      <ListRowCell grow column flexBasis={columnsWidths.description}>
        <Typography gutterBottom noWrap variant="h4">{rule.name}</Typography>
        <Typography noWrap>{rule.description || '-'}</Typography>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.action}>
        <IconButton
          variant="contained"
          color="primary"
          onClick={handleEditEmailRule}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          variant="contained"
          color="error"
          onClick={handleDeleteEmailRule}
        >
          <DeleteIcon />
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
