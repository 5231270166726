import { flow } from 'lodash';
import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { List, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { hasRole } from '../../../../../utils/hasRole';
import * as apptApi from '../../../../../api/appointments';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { useModal } from '../../../../../components/ModalsProvider';
import {
  AppointmentsContext,
  AppointmentEditModal,
  AppointmentViewModal
} from '../../../../../components/appointments';

export const ConsultationsMenu = ({ appointment, onClose }) => {
  const { openModal } = useModal();
  const { reloadAppointments, deleteAppointment, updateAppointment } = useContext(AppointmentsContext);

  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteConsultation = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        apptApi.deleteAppointment(appointment.id).then(() => {
          deleteAppointment(appointment.id);
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        });
      }
    });
  };

  const handleUpdateConsultation = () => {
    openModal(AppointmentEditModal, {
      payload: {
        disableAttachments: true,
        appointmentID: appointment.id
      },
      onModalResolved: (data) => {
        updateAppointment(data);
      }
    });
  };

  const openPreviewModal = () => {
    openModal(AppointmentViewModal, {
      payload: {
        appointmentID: appointment.id,
        onAppointmentUpdate: reloadAppointments,
        onAppointmentDelete: reloadAppointments
      }
    });
  };

  return (
    <List>
      <MenuItem onClick={flow(openPreviewModal, onClose)}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      {!hasRole('client', 'patient') &&
        <>
          <MenuItem onClick={flow(handleUpdateConsultation, onClose)}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="inherit" />
            </ListItemIcon>

            <ListItemText primary="Edit" />
          </MenuItem>

          <MenuItem onClick={flow(handleDeleteConsultation, onClose)}>
            <ListItemIcon>
              <DeleteIcon color="error" fontSize="inherit"/>
            </ListItemIcon>

            <ListItemText primary="Delete" />
          </MenuItem>
        </>
      }
    </List>
  );
};
