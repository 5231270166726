import { ErrorMessage, useFormikContext } from 'formik';
import { Box, FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import {
  orthoSliderMarks
} from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/FollowUp/sliderMarks';
import {
  PainContent
} from '../../../../app/Dashboard/ProfilePage/MedicalInfo/MedicalForms/FollowUp/PainContent';
import {
  Checkbox,
  KeyboardDatePicker,
  RadioGroup,
  TextField
} from '../../../FormField';

export const HistoryInjury = () => {
  const { values } = useFormikContext();

  return (
    <>
      <Grid item xs={12}>
        <Typography paragraph variant="h4" color="secondary">
          History of Injury
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          1. Please rate your pain on a scale of 1 to 10 (10 being the worst pain)*
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <PainContent
          title={<Typography>At rest:</Typography>}
          nameValue="forms.patient_history.at_rest"
          sliderValue={values?.forms?.patient_history?.please_rate_your_pain?.at_rest}
          name="forms.patient_history.please_rate_your_pain.at_rest"
          marks={orthoSliderMarks}
          sliderProps={{ max: 10 }}
        />
      </Grid>

      <Grid item xs={12}>
        <PainContent
          title={<Typography>At worst:</Typography>}
          nameValue="forms.patient_history.at_worst"
          sliderValue={values?.forms?.patient_history?.please_rate_your_pain?.at_worst}
          name="forms.patient_history.please_rate_your_pain.at_worst"
          marks={orthoSliderMarks}
          sliderProps={{ max: 10 }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          2. Is the pain:*
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
          <FormControlLabel
            label={<Typography>Worsening</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.is_the_pain.worsening"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Occasional</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.is_the_pain.occasional"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Stabbing</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.is_the_pain.stabbing"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Stable</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.is_the_pain.stable"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Sharp</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.is_the_pain.sharp"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Throbbing</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.is_the_pain.throbbing"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Improving</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.is_the_pain.improving"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Dull</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.is_the_pain.dull"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Constant</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.is_the_pain.constant"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Aching</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.is_the_pain.aching"
              />
            }
          />
        </Box>

        <Typography color="error">
          <ErrorMessage name="forms.patient_history.is_the_pain" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          3. What symptoms are you experiencing?*
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="flex-end" justifyContent="flex-start" flexWrap="wrap">
          <FormControlLabel
            label={<Typography>Locking</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_symptoms_are_you_experiencing.locking"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Catching</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_symptoms_are_you_experiencing.catching"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Giving away</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_symptoms_are_you_experiencing.giving_away"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Popping</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_symptoms_are_you_experiencing.popping"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Grinding</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_symptoms_are_you_experiencing.grinding"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Bruising</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_symptoms_are_you_experiencing.bruising"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Numbness</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_symptoms_are_you_experiencing.numbness"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Tingling</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_symptoms_are_you_experiencing.tingling"
              />
            }
          />

          {!values?.forms.patient_history?.what_symptoms_are_you_experiencing?.other?.value ? (
            <FormControlLabel
              label={<Typography>Other</Typography>}
              control={
                <Checkbox
                  color="primary"
                  name="forms.patient_history.what_symptoms_are_you_experiencing.other.value"
                />
              }
            />
          ) : (
            <Box display="flex" alignItems="flex-end" flexWrap="wrap" minWidth={300}>
              <Box minWidth={146}>
                <FormControlLabel
                  label={<Typography>Other (describe)</Typography>}
                  control={
                    <Checkbox
                      color="primary"
                      name="forms.patient_history.what_symptoms_are_you_experiencing.other.value"
                    />
                  }
                />
              </Box>

              <Box minWidth={300}>
                <TextField
                  fullWidth
                  multiline
                  name="forms.patient_history.what_symptoms_are_you_experiencing.other.description"
                />
              </Box>
            </Box>
          )}
        </Box>

        <Typography color="error">
          <ErrorMessage name="forms.patient_history.what_symptoms_are_you_experiencing" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          4. What, if anything, makes your symptoms <u>better?</u>*
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="flex-end" justifyContent="flex-start" flexWrap="wrap">
          <FormControlLabel
            label={<Typography>Rest</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_if_anything_makes_your_symptoms_better.rest"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Cold Therapy</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_if_anything_makes_your_symptoms_better.cold_therapy"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Activity</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_if_anything_makes_your_symptoms_better.activity"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Heat Therapy</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_if_anything_makes_your_symptoms_better.heat_therapy"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Medication</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_if_anything_makes_your_symptoms_better.medication"
              />
            }
          />

          {!values?.forms.patient_history?.what_if_anything_makes_your_symptoms_better?.other?.value ? (
            <FormControlLabel
              label={<Typography>Other</Typography>}
              control={
                <Checkbox
                  color="primary"
                  name="forms.patient_history.what_if_anything_makes_your_symptoms_better.other.value"
                />
              }
            />
          ) : (
            <Box display="flex" alignItems="flex-end" flexWrap="wrap" minWidth={300}>
              <Box minWidth={100}>
                <FormControlLabel
                  label={<Typography>Other</Typography>}
                  control={
                    <Checkbox
                      color="primary"
                      name="forms.patient_history.what_if_anything_makes_your_symptoms_better.other.value"
                    />
                  }
                />
              </Box>

              <Box minWidth={300}>
                <TextField
                  fullWidth
                  multiline
                  name="forms.patient_history.what_if_anything_makes_your_symptoms_better.other.description"
                />
              </Box>
            </Box>
          )}
        </Box>

        <Typography color="error">
          <ErrorMessage name="forms.patient_history.what_if_anything_makes_your_symptoms_better" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          5. What, if anything, makes your symptoms <u>worse?</u>*
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="flex-end" justifyContent="flex-start" flexWrap="wrap">
          <FormControlLabel
            label={<Typography>Inactivity</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_if_anything_makes_your_symptoms_worse.inactivity"
              />
            }
          />

          {!values?.forms.patient_history?.what_if_anything_makes_your_symptoms_worse?.exercise?.value ? (
            <FormControlLabel
              label={<Typography>Exercise (describe)</Typography>}
              control={
                <Checkbox
                  color="primary"
                  name="forms.patient_history.what_if_anything_makes_your_symptoms_worse.exercise.value"
                />
              }
            />
          ) : (
            <Box display="flex" alignItems="flex-end" flexWrap="wrap" minWidth={300}>
              <Box minWidth={160}>
                <FormControlLabel
                  label={<Typography>Exercise (describe)</Typography>}
                  control={
                    <Checkbox
                      color="primary"
                      name="forms.patient_history.what_if_anything_makes_your_symptoms_worse.exercise.value"
                    />
                  }
                />
              </Box>

              <Box minWidth={300}>
                <TextField
                  fullWidth
                  multiline
                  name="forms.patient_history.what_if_anything_makes_your_symptoms_worse.exercise.description"
                />
              </Box>
            </Box>
          )}

          {!values?.forms.patient_history?.what_if_anything_makes_your_symptoms_worse?.other?.value ? (
            <FormControlLabel
              label={<Typography>Other</Typography>}
              control={
                <Checkbox
                  color="primary"
                  name="forms.patient_history.what_if_anything_makes_your_symptoms_worse.other.value"
                />
              }
            />
          ) : (
            <Box display="flex" alignItems="flex-end" flexWrap="wrap" minWidth={300}>
              <Box minWidth={100}>
                <FormControlLabel
                  label={<Typography>Other</Typography>}
                  control={
                    <Checkbox
                      color="primary"
                      name="forms.patient_history.what_if_anything_makes_your_symptoms_worse.other.value"
                    />
                  }
                />
              </Box>

              <Box minWidth={300}>
                <TextField
                  fullWidth
                  multiline
                  name="forms.patient_history.what_if_anything_makes_your_symptoms_worse.other.description"
                />
              </Box>
            </Box>
          )}
        </Box>

        <Typography color="error">
          <ErrorMessage name="forms.patient_history.what_if_anything_makes_your_symptoms_worse" />
        </Typography>
      </Grid>

      <Grid item md={6} xs={12}>
        <Typography gutterBottom variant="h5">
          6. Have you seen another physician for this injury?*
        </Typography>
      </Grid>

      <Grid item md={6} xs={12}>
        <RadioGroup
          row
          name="forms.patient_history.have_you_seen_another_physician_for_this_injury"
        >
          <FormControlLabel
            value="yes"
            label={<Typography>Yes</Typography>}
            control={<Radio color="primary" />}
          />

          <FormControlLabel
            value="no"
            label={<Typography>No</Typography>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </Grid>

      <Grid item md={3} xs={12}>
        <Box ml={2}>
          <Typography>
            If yes, who?
          </Typography>
        </Box>
      </Grid>

      <Grid item md={9} xs={12}>
        <TextField
          fullWidth
          required={values.forms.patient_history.have_you_seen_another_physician_for_this_injury === 'yes'}
          label="Describe"
          placeholder="Enter description..."
          name="forms.patient_history.if_yes_who"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          7. What treatments have you tried?*
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="flex-end" justifyContent="flex-start" flexWrap="wrap">
          <FormControlLabel
            label={<Typography>Nothing</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_treatments_have_you_tried.nothing"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Physical therapy</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_treatments_have_you_tried.physical_therapy"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Exercise</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_treatments_have_you_tried.exercise"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Acupuncture</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_treatments_have_you_tried.acupuncture"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Decreased activity</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_treatments_have_you_tried.decreased_activity"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Injections</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_treatments_have_you_tried.injections"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Bracing</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_treatments_have_you_tried.bracing"
              />
            }
          />

          {!values?.forms.patient_history?.what_treatments_have_you_tried?.medications?.value ? (
            <FormControlLabel
              label={<Typography>Medications</Typography>}
              control={
                <Checkbox
                  color="primary"
                  name="forms.patient_history.what_treatments_have_you_tried.medications.value"
                />
              }
            />
          ) : (
            <Box display="flex" alignItems="flex-end" flexWrap="wrap" minWidth={300}>
              <Box minWidth={160}>
                <FormControlLabel
                  label={<Typography>Medications</Typography>}
                  control={
                    <Checkbox
                      color="primary"
                      name="forms.patient_history.what_treatments_have_you_tried.medications.value"
                    />
                  }
                />
              </Box>

              <Box minWidth={300}>
                <TextField
                  fullWidth
                  multiline
                  name="forms.patient_history.what_treatments_have_you_tried.medications.description"
                />
              </Box>
            </Box>
          )}

          <FormControlLabel
            label={<Typography>Chiropractic</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_treatments_have_you_tried.chiropractic"
              />
            }
          />

          <FormControlLabel
            label={<Typography>Ice</Typography>}
            control={
              <Checkbox
                color="primary"
                name="forms.patient_history.what_treatments_have_you_tried.ice"
              />
            }
          />

          {!values?.forms.patient_history?.what_treatments_have_you_tried?.other?.value ? (
            <FormControlLabel
              label={<Typography>Other</Typography>}
              control={
                <Checkbox
                  color="primary"
                  name="forms.patient_history.what_treatments_have_you_tried.other.value"
                />
              }
            />
          ) : (
            <Box display="flex" alignItems="flex-end" flexWrap="wrap" minWidth={300}>
              <Box minWidth={100}>
                <FormControlLabel
                  label={<Typography>Other</Typography>}
                  control={
                    <Checkbox
                      color="primary"
                      name="forms.patient_history.what_treatments_have_you_tried.other.value"
                    />
                  }
                />
              </Box>

              <Box minWidth={300}>
                <TextField
                  fullWidth
                  multiline
                  name="forms.patient_history.what_treatments_have_you_tried.other.description"
                />
              </Box>
            </Box>
          )}
        </Box>

        <Typography color="error">
          <ErrorMessage name="forms.patient_history.what_treatments_have_you_tried" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">
          8. Have you had any of the following tests/studies?
        </Typography>
      </Grid>

      <Grid item md={2} xs={4}>
        <FormControlLabel
          label={<Typography>X-rays</Typography>}
          control={
            <Checkbox
              color="primary"
              name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.x_rays.value"
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={8}>
        <KeyboardDatePicker
          clearable
          disabled={!values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.x_rays?.value}
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.x_rays.date"
          label="Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required={!!values.forms.patient_history.have_you_had_any_of_the_following_tests_studies.x_rays.value}
          disabled={!values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.x_rays?.value}
          label="What facility (clinic/hospital)"
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.x_rays.what_facility"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required={!!values.forms.patient_history.have_you_had_any_of_the_following_tests_studies.x_rays.value}
          disabled={!values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.x_rays?.value}
          label="Body parts"
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.x_rays.body_parts"
        />
      </Grid>

      <Grid item md={2} xs={4}>
        <FormControlLabel
          label={<Typography>MRI scan</Typography>}
          control={
            <Checkbox
              color="primary"
              name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.mri_scan.value"
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={8}>
        <KeyboardDatePicker
          clearable
          disabled={
            !values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.mri_scan?.value
          }
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.mri_scan.date"
          label="Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required={!!values.forms.patient_history.have_you_had_any_of_the_following_tests_studies.mri_scan.value}
          disabled={
            !values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.mri_scan?.value
          }
          label="What facility (clinic/hospital)"
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.mri_scan.what_facility"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required={!!values.forms.patient_history.have_you_had_any_of_the_following_tests_studies.mri_scan.value}
          disabled={!values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.mri_scan?.value}
          label="Body parts"
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.mri_scan.body_parts"
        />
      </Grid>

      <Grid item md={2} xs={4}>
        <FormControlLabel
          label={<Typography>CT scan</Typography>}
          control={
            <Checkbox
              color="primary"
              name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.ct_scan.value"
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={8}>
        <KeyboardDatePicker
          clearable
          disabled={
            !values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.ct_scan?.value
          }
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.ct_scan.date"
          label="Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required={!!values.forms.patient_history.have_you_had_any_of_the_following_tests_studies.ct_scan.value}
          disabled={
            !values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.ct_scan?.value
          }
          label="What facility (clinic/hospital)"
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.ct_scan.what_facility"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required={!!values.forms.patient_history.have_you_had_any_of_the_following_tests_studies.ct_scan.value}
          disabled={!values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.ct_scan?.value}
          label="Body parts"
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.ct_scan.body_parts"
        />
      </Grid>

      <Grid item md={2} xs={4}>
        <FormControlLabel
          label={<Typography>EMG/NCV</Typography>}
          control={
            <Checkbox
              color="primary"
              name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.emgncv.value"
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={8}>
        <KeyboardDatePicker
          clearable
          disabled={
            !values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.emgncv?.value
          }
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.emgncv.date"
          label="Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required={!!values.forms.patient_history.have_you_had_any_of_the_following_tests_studies.emgncv.value}
          disabled={
            !values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.emgncv?.value
          }
          label="What facility (clinic/hospital)"
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.emgncv.what_facility"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required={!!values.forms.patient_history.have_you_had_any_of_the_following_tests_studies.emgncv.value}
          disabled={!values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.emgncv?.value}
          label="Body parts"
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.emgncv.body_parts"
        />
      </Grid>

      <Grid item md={2} xs={4}>
        <FormControlLabel
          label={<Typography>Other</Typography>}
          control={
            <Checkbox
              color="primary"
              name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.other.value"
            />
          }
        />
      </Grid>

      <Grid item md={3} xs={8}>
        <KeyboardDatePicker
          clearable
          disabled={!values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.other?.value}
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.other.date"
          label="Date"
          outputFormat="YYYY-MM-DD"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required={!!values.forms.patient_history.have_you_had_any_of_the_following_tests_studies.other.value}
          disabled={
            !values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.other?.value
          }
          label="What facility (clinic/hospital)"
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.other.what_facility"
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required={!!values.forms.patient_history.have_you_had_any_of_the_following_tests_studies.other.value}
          disabled={!values?.forms.patient_history?.have_you_had_any_of_the_following_tests_studies?.other?.value}
          label="Body parts"
          name="forms.patient_history.have_you_had_any_of_the_following_tests_studies.other.body_parts"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Recreational activities:</Typography>

        <TextField
          fullWidth
          multiline
          disableUnderline
          name="forms.patient_history.recreational_activities"
          variant="filled"
          rows={3}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Current, regular exercise program (if any)</Typography>

        <TextField
          fullWidth
          multiline
          disableUnderline
          name="forms.patient_history.current_regular_exercise_program"
          variant="filled"
          rows={3}
        />
      </Grid>
    </>
  );
};
