export const styles = ({ spacing, breakpoints }) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    paddingTop: spacing(2),
    paddingLeft: spacing(2),
    paddingRight: spacing(3),

    [breakpoints.down(breakpoints.values.mobileLg)]: {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
      paddingTop: spacing(1)
    }
  }
});
