import { PURGE } from 'redux-persist';
import { createReduxReducer } from '../../../../helpers/createReduxReducer';
import * as types from './types';

export const uploadStatuses = {
  progress: 'progress',
  success: 'success',
  error: 'error',
  canceled: 'canceled'
};

const initialState = {
  widgetIsOpened: false,
  files: [],
  lastUploadedFile: null,
  totalProgress: 0,
  totalSize: 0,
  erroredCount: 0
};

export const reducer = createReduxReducer(initialState, {
  [PURGE]: () => initialState,

  [types.FILES_UPLOADS_OPEN_WIDGET]: (state) => {
    return { ...state, widgetIsOpened: true };
  },

  [types.FILES_UPLOADS_CLOSE_WIDGET]: (state) => {
    return { ...state, widgetIsOpened: false };
  },

  [types.FILES_UPLOADS_ADD_FILE]: ({ files, ...state }, payload) => {
    payload.uploadStatus = uploadStatuses.progress;

    return { ...state, files: [ payload, ...files ] };
  },

  [types.FILES_UPLOADS_UPDATE_FILE]: ({ files, ...state }, payload) => {
    return { ...state, files: files.map((file) => file.localID === payload.localID ? payload : file) };
  },

  [types.FILE_UPLOAD_SUCCESS]: ({ files, ...state }, payload) => {
    payload.uploadStatus = uploadStatuses.success;

    return {
      ...state,
      lastUploadedFile: payload,
      files: files.map((file) => file.localID === payload.localID ? payload : file)
    };
  },

  [types.FILE_UPLOAD_FAIL]: ({ files, ...state }, payload) => {
    payload.uploadStatus = uploadStatuses.error;

    return {
      ...state,
      erroredCount: state.erroredCount + 1,
      files: files.map((file) => file.localID === payload.localID ? payload : file)
    };
  },

  [types.FILE_UPLOAD_CANCEL]: ({ files, ...state }, payload) => {
    payload.progress = 0;
    payload.uploadStatus = uploadStatuses.canceled;

    return {
      ...state,
      files: files.map((file) => file.localID === payload.localID ? payload : file)
    };
  },

  [types.FILE_UPLOAD_REFRESH]: ({ files, ...state }, payload) => {
    payload.uploadStatus = uploadStatuses.progress;

    return {
      ...state,
      files: files.map((file) => file.localID === payload.localID ? payload : file)
    };
  }
});
