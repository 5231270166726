import { debounce } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconComponent } from '../../../../../components/saved-filters';
import { UsersSelect } from '../../../../../components/users';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { unixToStartOfDayUnix, unixToEndOfDayUnix } from '../../../../../helpers/dates';
import { FiltersBar as FiltersBarComponent } from '../../../../../components/FiltersBar';
import {
  DatePicker,
  TextField,
  OfficesLocationSelect
} from '../../../../../components/FormField';
import { saveFilter } from '../../../../../store/lastFilters';
import { List } from './List';
import { filterFieldsLabels, filterFieldsMap } from './filterFieldsMap';
import { MRIsContext } from '../MRIsProvider';
import { initialValues } from './initialValues';

const MODAL_WIDTH = 820;

export const FiltersBar = ({ filterKey, hiddenFields = [] }) => {
  const formikRef = useRef();
  const { applyFilter } = useContext(MRIsContext);
  const dispatch = useDispatch();
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleDatePickerChange = (name, transformer) => (date) => {
    applyFilter({ [name]: transformer?.(date) || date });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const handleOfficeChange = (office) => {
    applyFilter({ office_id: office?.id });
    setRelationsForFilter((state) => ({ ...state, office_id: office }));
  };

  const handleUserSelect = (name) => (option) => {
    applyFilter({ [name]: option?.id });
    setRelationsForFilter((state) => ({ ...state, [name]: option }));
  };

  const handleNameChange = debounce((event) => {
    const name = event.target.value;

    applyFilter({ name });
    setRelationsForFilter((state) => ({ ...state, name }));
  }, 600);

  const applySavedFilter = (filter) => {
    formikRef?.current?.setValues(filter);
    applyFilter({
      ...filter,

      office_id: filter?.office_id?.id,
      physician_id: filter?.physician_id?.id,
      date_from: filter?.date_from,
      date_to: filter?.date_to,
      name: filter?.name
    });
  };

  useEffect(() => {
    dispatch(saveFilter({
      key: filterKey,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  useEffect(() => {
    applySavedFilter({
      ...relationsForFilter,
      ...lastFilters
    });
  }, []);

  return (
    <FiltersBarComponent
      isResetForm
      disableTopPaddings
      enableSettings
      border={0}
      formikRef={formikRef}
      initialValues={initialValues}
      filterKey={filterKey}
      hiddenFields={hiddenFields}
      iconComponent={(
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          ListComponent={List}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      )}
      onReset={() => {
        applySavedFilter(initialValues);
      }}
      fieldsList={[
        {
          fieldKey: filterFieldsMap.dateFrom,
          label: filterFieldsLabels[filterFieldsMap.dateFrom],
          field: (
            <DatePicker
              zeroMinWidth
              minWidth={140}
              clearable
              name="date_from"
              label="From"
              onChange={handleDatePickerChange('date_from', unixToStartOfDayUnix)}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.dateTo,
          label: filterFieldsLabels[filterFieldsMap.dateTo],
          field: (
            <DatePicker
              minWidth={140}
              zeroMinWidth
              clearable
              name="date_to"
              label="To"
              onChange={handleDatePickerChange('date_to', unixToEndOfDayUnix)}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.name,
          label: filterFieldsLabels[filterFieldsMap.name],
          field: (
            <TextField
              name="name"
              label="Name MRI scan"
              onChange={handleNameChange}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.physicianId,
          label: filterFieldsLabels[filterFieldsMap.physicianId],
          field: (
            <UsersSelect
              minWidth={160}
              name="physician_id"
              label="Prim. Care Physician"
              params={{ role: rolesMap.doctor }}
              onChange={handleUserSelect('physician_id')}
            />
          )
        },

        {
          fieldKey: filterFieldsMap.officeId,
          label: filterFieldsLabels[filterFieldsMap.officeId],
          field: (
            <OfficesLocationSelect
              name="office_id"
              onChange={handleOfficeChange}
            />
          )
        }
      ]}
    />
  );
};
