export const styles = ({ spacing, palette: { primary }, transitions }) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(0, 2, 3, 2)
  },

  actionLink: {
    transition: transitions.create(),

    '&:hover': {
      color: primary.main
    }
  },

  actionIcon: {
    marginRight: spacing(0.5)
  }
});
