import { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { fetchCaseReminderEvent } from '../../../../../api/cases/reminders/events';
import { Loader } from '../../../../Loader';
import { ModalBody } from '../../../../Modal';
import { Card } from '../ReminderEventForm/Card';

export const ReminderEventViewModal = ({
  DialogProps,
  payload: {
    reminderEventId
  }
}) => {
  const [ reminderEvent, setReminderEvent ] = useState(null);

  useEffect(() => {
    fetchCaseReminderEvent(reminderEventId).then(setReminderEvent);
  }, []);

  return (
    <Dialog maxWidth="xs" {...DialogProps}>
      <ModalBody disablePaddings>
        {reminderEvent
          ? <Card event={reminderEvent}/>
          : <Loader p={2} />
        }
      </ModalBody>
    </Dialog>
  );
};
