import { useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, SvgIcon } from '@material-ui/core';
import { mdiClipboardAccountOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { dataURItoBlob } from '../../../../../helpers/files';
import * as usersApi from '../../../../../api/companies';
import * as userAvatarApi from '../../../../../api/users';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../../../components/Modal';
import { Loader } from '../../../../../components/Loader';
import { errorsMap } from '../../../../../utils/validation';
import { MainInfo } from './MainInfo';
import { initialValues } from './initialValues';
import { getValidationSchema } from './validationSchema';

export const CreateUserModal = ({
  DialogProps,
  payload: {
    hideWorkOffice = false,
    initialValues: initialValuesProp = {}
  },
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ avatarDataURL, setAvatarDataURL ] = useState(null);

  const createUser = (userData, setErrors, setSubmitting) => {
    usersApi.createContactsUser(userData).then((data) => {
      enqueueSnackbar('User successfully created', { variant: 'success' });
      handleModalResolve({ ...data, office: data?.work?.office, position: data?.work?.position });
    }).catch(({ data: { errors } }) => {
      if (errors?.email?.includes(errorsMap.unique)) {
        setErrors({ email: 'User with the same email already exists' });
      } else if (errors) {
        setErrors(errors);
      }

      setSubmitting(false);
      enqueueSnackbar('User not created', { variant: 'error' });
    });
  };

  const handleUserCreate = (values, { isSubmitting, setErrors, setSubmitting }) => {
    if (isSubmitting) {
      return;
    }

    if (avatarDataURL) {
      const formData = new FormData();

      formData.append('file', dataURItoBlob(avatarDataURL));

      userAvatarApi.uploadAvatar(formData).then((avatar) => {
        values.avatar_id = avatar.id;

        createUser(values, setErrors, setSubmitting);
      }).catch(({ data: { message } }) => {
        setSubmitting(false);
        enqueueSnackbar(message, { variant: 'error' });
      });
    } else {
      createUser(values, setErrors, setSubmitting);
    }
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...initialValuesProp
      }}
      validationSchema={getValidationSchema(hideWorkOffice)}
      onSubmit={handleUserCreate}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog maxWidth="md" {...DialogProps}>
          <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
            <ModalHeader
              icon={<SvgIcon><Icon path={mdiClipboardAccountOutline} /></SvgIcon>}
              onClose={handleModalReject}
            >
              Add User
            </ModalHeader>

            <ModalBody>
              <MainInfo hideWorkOffice={hideWorkOffice} onAvatarUrlChange={setAvatarDataURL} />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </ModalContainer>
        </Dialog>
      )}
    </Formik>
  );
};
