export const styles = ({
  spacing,
  palette,
  typography: { pxToRem },
  transitions
}) => ({
  listItem: {
    height: '100%',
    borderBottom: `1px solid ${palette.divider}`,
    paddingLeft: 0,
    backgroundColor: palette.grey[100],
    transition: transitions.create(),

    '&:active, &:focus': {
      borderBottom: `1px solid ${palette.primary.main}`
    }
  },

  listItem_action: {
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5)
  },

  listItem_background: {
    backgroundColor: palette.common.white
  },

  title: {
    fontSize: ({ TypographyProps }) => {
      switch (TypographyProps.fontSize) {
        case 'large':
          return pxToRem(20);
        case 'medium':
          return pxToRem(18);

        default:
          return pxToRem(16);
      }
    }
  }
});
