import { useState } from 'react';
import { useFormikContext } from 'formik';
import {
  makeStyles,
  Grid,
  Typography,
  Collapse,
  Paper,
  Box,
  useTheme
} from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { Date } from '../../../../../../../components/Date';
import { Divider } from '../../../../../../../components/Divider';
import { IconButton } from '../../../../../../../components/IconButton';
import { Label } from '../../../../../../../components/Label';
import { Tooltip } from '../../../../../../../components/Tooltip';
import { InsuranceClaimRowInfo } from './InsuranceClaimRowInfo';
import { styles } from './styles';

const useStyle = makeStyles(styles);

export const Info = () => {
  const classes = useStyle();
  const theme = useTheme();
  const { values } = useFormikContext();
  const [ openClaim, setOpenClaim ] = useState({});

  const hadleOpenClaim = (id) => () => {
    setOpenClaim({ [id]: !openClaim[id] });
  };

  return !values?.ins_claims?.length ? (
    <Typography align="center">No insurance claim found</Typography>
  ) : (values?.ins_claims?.map((claim) => (
    <Box key={claim.id} mb={2}>
      <Grid
        container
        spacing={2}
        component={Paper}
        square={false}
        variant="outlined"
        alignItems="flex-start"
        className={!!openClaim[claim.id] ? classes.activeBorder : undefined}
      >
        <Grid item xs={10} container>
          <Grid item xs={7}>
            <Typography noWrap gutterBottom variant="h4">Ins. Claim #</Typography>
          </Grid>

          <Grid item xs={5}>
            <Tooltip title={claim?.number || '-'}>
              <Typography noWrap gutterBottom variant="h4">{claim?.number || '-'}</Typography>
            </Tooltip>
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap color="textSecondary">DOS</Typography>
          </Grid>

          <Grid item xs={5}>
            <Date noWrap date={claim?.date} color="textSecondary" />
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap color="textSecondary">DOI</Typography>
          </Grid>

          <Grid item xs={5}>
            <Date noWrap date={claim?.doi[0]?.date} color="textSecondary" />
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap color="textSecondary">Statute of Limitations</Typography>
          </Grid>

          <Grid item xs={5}>
            <Date noWrap date={claim?.doi[0]?.start_of_limitation} color="error" />
          </Grid>

          <Grid item xs={7}>
            <Typography noWrap color="textSecondary">Status</Typography>
          </Grid>

          <Grid item xs={5}>
            <Label
              color={theme.palette.lightgreen}
              label={claim?.status}
            />
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Box
            display="flex"
            justifyContent="flex-end"
            color="info.main"
          >
            <Tooltip isExistTooltip title="Show claim">
              <IconButton
                size="small"
                color="inherit"
                onClick={hadleOpenClaim(claim.id)}
              >
                <EyeIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={!!openClaim[claim.id]}>
            <Divider type="dashed" color={theme.palette.info.main} />
            <InsuranceClaimRowInfo claim={claim} />
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  )));
};
