import { Box, Grid, Typography } from '@material-ui/core';
import { OfficeLink } from '../../../../../../components/OfficeLink';
import { UserLink } from '../../../../../../components/UserLink';

export const CardContent = ({ params }) => {
  return (
    <Grid container spacing={1}>
      {params?.patient_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Patient:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <UserLink noWrap user={params.patient_id} />
            </Box>
          </Grid>
        </>
      }

      {params?.dor_from &&
        <>
          <Grid item xs={4}>
            <Typography>
              DOR From:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.dor_from}
            </Typography>
          </Grid>
        </>
      }

      {params?.dor_to &&
        <>
          <Grid item xs={4}>
            <Typography>
              DOR To:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.dor_to}
            </Typography>
          </Grid>
        </>
      }

      {params?.type &&
        <>
          <Grid item xs={4}>
            <Typography>
              Type of med.report:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.type?.label}
            </Typography>
          </Grid>
        </>
      }

      {params?.icd_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Diagnosis(DX):
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {params?.icd_id?.code} - {params?.icd_id?.description}
            </Typography>
          </Grid>
        </>
      }

      {params?.provider_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Provider:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <UserLink noWrap user={params?.provider_id} />
            </Box>
          </Grid>
        </>
      }

      {params?.office_id &&
        <>
          <Grid item xs={4}>
            <Typography>
              Office:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <OfficeLink noWrap variant="h5" office={params?.office_id} />
            </Box>
          </Grid>
        </>
      }
    </Grid>
  );
};
