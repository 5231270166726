import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, Dialog, SvgIcon, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { mdiCommentMultipleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import * as proceduresApi from '../../../../../api/profile';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalHeaderActions
} from '../../../../../components/Modal';
import { Comments, CommentsContextProvider } from '../../../../../components/Comments';
import { MedicalAttachments } from '../../../../../components/MedicalAttachments';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { AppointmentEditModal } from '../../../../../components/appointments';
import { VerticalDivider } from '../../../../../components/VerticalDivider';
import { useModal } from '../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../components/IconButton';
import { Loader } from '../../../../../components/Loader';
import { Button } from '../../../../../components/Button';
import { ReportsPreviewContext, ReportsPreviewProvider } from '../../ReportsPreviewProvider';
import { EditProceduresModal } from '../EditProceduresModal';
import { MainContent } from './MainContent';

export const PreviewProceduresModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { procedure, appointmentType, onUpdate }
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const commentsLastGlobalAction = useSelector(({ globalActions }) => globalActions.commentsLastGlobalAction);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const commentsInitialState = {
    filter: {
      parent_type: 'medical_procedure',
      parent_id: procedure?.id
    }
  };

  const handleProcedureUpdate = (value, callback) => () => {
    openModal(EditProceduresModal, {
      payload: {
        id: procedure.id,
        onUpdate
      },
      onModalResolved: (data) => {
        callback({ ...value, ...data });
      }
    });
  };

  const handleAppointmentUpdate = ({ setAppointment }) => () => {
    openModal(AppointmentEditModal, {
      payload: {
        appointmentType,
        disableAttachments: true,
        appointmentID: procedure.appointment_id
      },
      onModalResolved: (data) => {
        setAppointment(data);
        onUpdate();
      }
    });
  };

  const handleProcedureDelete = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        proceduresApi.deleteMedicalReport(procedure?.id).then(() => {
          onUpdate();
          enqueueSnackbar('Item successfully deleted', { variant: 'success' });
          handleModalResolve();
        });
      }
    });
  };

  const fetchProcedures = () => {
    return proceduresApi.fetchMedicalReport(procedure.id);
  };

  useEffect(() => {
    if (commentsLastGlobalAction) {
      onUpdate();
    }
  }, [ commentsLastGlobalAction ]);

  return (
    <ReportsPreviewProvider
      appointment_id={procedure.appointment_id}
      fetchItem={fetchProcedures}
      onUpdate={onUpdate}
    >
      <ReportsPreviewContext.Consumer>
        {({ isFetched, selectedItem, setSelectedItem, setAppointment }) => (
          <Dialog fullScreen={isTablet} maxWidth="md" {...DialogProps}>
            <Loader p={3} loading={!isFetched} render={
              () => (
                <ModalContainer>
                  <ModalHeader onClose={handleModalReject}>
                    {isTablet ? 'View' : 'View Surgeries/Procedures'}

                    <ModalHeaderActions disableGutters>
                      {!hasRole(rolesMap.client, rolesMap.patient) &&
                        <>
                          <Tooltip arrow title="Edit procedure">
                            <Button
                              size="small"
                              color="primary"
                              startIcon={<EditIcon />}
                              onClick={handleProcedureUpdate(selectedItem, setSelectedItem)}
                            >
                              {isTablet ? 'Procedure' : 'Edit procedure'}
                            </Button>
                          </Tooltip>

                          <VerticalDivider verticalGutters={2} horizontalGutters={2}/>

                          <Tooltip arrow title="Edit appointment">
                            <Button
                              size="small"
                              color="primary"
                              startIcon={<EditIcon />}
                              onClick={handleAppointmentUpdate({ setSelectedItem, setAppointment })}
                            >
                              {isTablet ? 'Appt.' : 'Edit appt.'}
                            </Button>
                          </Tooltip>

                          <Tooltip arrow title="Delete procedure">
                            <IconButton
                              color="error"
                              onClick={handleProcedureDelete}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    </ModalHeaderActions>
                  </ModalHeader>

                  <ModalBody>
                    <MainContent />

                    <MedicalAttachments
                      owner_type="medical_procedure"
                      owner_id={procedure.id}
                      files={selectedItem.media || []}
                    />

                    <Box display="flex" alignItems="center" mt={1} mb={2}>
                      <SvgIcon><Icon path={mdiCommentMultipleOutline} /></SvgIcon>

                      <Box ml={1}>
                        <Typography variant="h3">
                          Comments
                        </Typography>
                      </Box>
                    </Box>

                    <Box minHeight={300}>
                      <CommentsContextProvider initialState={commentsInitialState}>
                        <Comments
                          parentType="medical_procedure"
                          parentID={procedure.id}
                        />
                      </CommentsContextProvider>
                    </Box>
                  </ModalBody>
                </ModalContainer>
              )}
            />
          </Dialog>
        )}
      </ReportsPreviewContext.Consumer>
    </ReportsPreviewProvider>
  );
};
