import { useContext } from 'react';
import {
  List,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useModal } from '../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../components/users';
import { ContactsContext } from '../../ContactsProvider';
import { EditContactModal } from '../../EditContactModal';

export const ContactMenu = ({ contact, onClose }) => {
  const contactsContext = useContext(ContactsContext);
  const { openModal } = useModal();

  const openUserPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: contact.contact_id }
    });
  };

  const editContact = () => {
    openModal(EditContactModal, {
      payload: {
        contact
      },
      onModalResolved: (contact) => {
        contactsContext.updateContact(contact);
      }
    });
  };

  const deleteContact = () => {
    contactsContext.deleteContact(contact);
  };

  return (
    <List onClick={onClose}>
      <MenuItem onClick={openUserPreview}>
        <ListItemIcon>
          <EyeIcon fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="View" />
      </MenuItem>

      <MenuItem onClick={editContact}>
        <ListItemIcon>
          <EditIcon color="primary" fontSize="inherit" />
        </ListItemIcon>

        <ListItemText primary="Edit" />
      </MenuItem>

      <MenuItem onClick={deleteContact}>
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="inherit"/>
        </ListItemIcon>

        <ListItemText primary="Delete" />
      </MenuItem>
    </List>
  );
};
