import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import { makeStyles, Typography } from '@material-ui/core';
import { ImageCropModal } from '../../../../../../../../components/ImageCropModal';
import { useModal } from '../../../../../../../../components/ModalsProvider';
import { enqueueSnackbar } from '../../../../../../../../store/snackbars';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Preview = ({ label, onInit, onChange }) => {
  const maxFileSize = useSelector(({ apiConfig }) => apiConfig.config.files.max_size);
  const { openModal } = useModal();
  const classes = useStyles();
  const [ preview, setPreview ] = useState();

  const handleSelectedFilesAccepted = ([ file ]) => {
    const reader = new FileReader();

    reader.addEventListener('load',() => {
      openModal(ImageCropModal, {
        payload: {
          src: reader.result,
          fileType: file.type,
          minWidth: 100,
          minHeight: 100
        },
        onModalResolved: ({ dataURL }) => {
          setPreview(dataURL);
          onChange(dataURL);
        }
      });
    });

    reader.readAsDataURL(file);
  };

  const handleSelectedFilesRejected = ([ file ]) => {
    enqueueSnackbar(`File "${file.name}" is not valid`, { variant: 'error' });
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <div>
      <Typography gutterBottom>{label}</Typography>

      <Dropzone
        maxSize={maxFileSize}
        accept="image/*"
        onDropAccepted={handleSelectedFilesAccepted}
        onDropRejected={handleSelectedFilesRejected}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.uploadTrigger}>
            <input {...getInputProps()} />

            {preview ?
              <img src={preview} alt=""/>
              :
              <Typography>Drag 'n' drop some files here, or click to select files</Typography>
            }
          </div>
        )}
      </Dropzone>
    </div>
  );
};
