import { Dialog } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ModalHeader, ModalBody } from '../../../../../components/Modal';
import { FileDropZone } from '../../../../../components/FileDropZone';
import { Divider } from '../../../../../components/Divider';

export const FilesUploadModal = ({
  DialogProps,
  payload: {
    multiple
  },
  handleModalResolve,
  handleModalReject
}) => {
  return (
    <Dialog {...DialogProps}>
      <ModalHeader onClose={handleModalReject}>
        Upload files
      </ModalHeader>

      <ModalBody>
        <Alert severity="info">
          Only PDF files up to 50MB are allowed
        </Alert>

        <Divider gutter={1} />

        <FileDropZone
          multiple={multiple}
          // 50MB
          maxSize={52428800}
          accept={[ '.pdf' ]}
          onDropAccepted={handleModalResolve}
        />
      </ModalBody>
    </Dialog>
  );
};
