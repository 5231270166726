export const styles = ({ spacing, palette }) => ({
  root: {
    color: palette.primary.contrastText,
    background: palette.grey[800],
    margin: spacing(0.5, 0)
  },

  body: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    wordBreak: 'break-all'
  },

  messageHeader: {
    color: palette.primary.contrastText,
    maxWidth: '240px'
  },

  messageText: {
    paddingTop: spacing(0.5),
    wordBreak: 'break-all',
    color: palette.grey[300],
    maxWidth: '260px'
  },

  iconButton: {
    color: palette.primary.contrastText,
    padding: spacing(0.5)
  },

  replyButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(1)
  },

  replyIcon: {
    marginRight: spacing(1)
  },

  textInput: {
    color: palette.primary.contrastText
  }
});
