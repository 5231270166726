import { useContext, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { List as MuiList, Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { unixToStartOfDayUnix } from '../../../../helpers/dates';
import { InfiniteGridLoader, InfiniteListLoader } from '../../../InfiniteLoader';
import { Card } from '../../../Widgets/TimeTracking/Grid/Card';
import { Header } from '../../../Widgets/TimeTracking/Header';
import { CustomScrollbars } from '../../../CustomScrollbars';
import { TimeTrackingContext } from '../../../TimeTracking';
import { Row } from '../../../Widgets/TimeTracking/Row';
import { minRowHeight } from '../../../TimeTracking';
import { Loader } from '../../../Loader';

export const UsedTimeTracks = () => {
  const { values } = useFormikContext();
  const {
    isFetching,
    isFetched,
    tracks,
    meta,
    loadNextPage,
    resetTimeTracking
  } = useContext(TimeTrackingContext);
  const scrollElementRef = useRef();
  const isValid = moment(values.started_at, 'X', true)?.isValid();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  useEffect(() => {
    if (isValid) {
      resetTimeTracking({
        started_after: unixToStartOfDayUnix(values.started_at)
      });
    }
  }, [ isValid ]);

  return (
    <Box py={!isFetched ? 3 : 0}>
      <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: 600 }}>
        <Loader loading={!isFetched} render={
          () => (!tracks.length ?
            <Box py={3}>
              <Typography align="center">No tracks found</Typography>
            </Box>
            : isMobile ? (
              <InfiniteGridLoader
                items={tracks}
                isFetching={isFetching}
                isFetched={isFetched}
                pagination={{ last_page: meta.last_page, page: meta.page }}
                CellComponent={Card}
                CellProps={{ disableActions: true }}
                onNeedNextPage={loadNextPage}
                scrollElementRef={scrollElementRef}
                cellWidth={370}
                cellHeight={280}
              />
            ) : (
              <>
                <Header isEditable={false} />

                <MuiList disablePadding>
                  <InfiniteListLoader
                    items={tracks}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    RowComponent={Row}
                    RowProps={{ isEditable: true, disableActions: true }}
                    pagination={meta}
                    scrollElementRef={scrollElementRef}
                    onNeedNextPage={loadNextPage}
                    minRowHeight={minRowHeight}
                  />
                </MuiList>
              </>
            )
          )}
        />
      </CustomScrollbars>
    </Box>
  );
};
