import { useContext, useState } from 'react';
import moment from 'moment';
import { makeStyles, Button, Fab, Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import * as documentsApi from '../../../../../../../api/files/html-documents';
import { AppBar } from '../../../../../../../components/AppBar';
import { Loader } from '../../../../../../../components/Loader';
import { VersionPreviewContext } from '../../VersionPreviewContext';
import { styles } from '../../../../FilePreviewModal/PreviewWindow/ActionsBar/styles';

const useStyles = makeStyles(styles);

export const ActionsBar = ({ onClickModalClose, onVersionChange }) => {
  const { selectedVersion, sidebarIsOpen, toggleSidebarIsOpen } = useContext(VersionPreviewContext);
  const [ versionIsFetching, setVersionIsFetching ] = useState(false);
  const classes = useStyles();

  const handleChangeVersion = () => {
    setVersionIsFetching(true);

    documentsApi.getDocumentVersion(selectedVersion.id).then((version) => {
      onVersionChange(version);
      setVersionIsFetching(false);
    });
  };

  return (
    <AppBar position="absolute" className={classes.root} ToolbarProps={{ className: classes.toolbar }}>
      <div className={classes.fileName}>
        <IconButton
          title="Close window"
          className={classes.fileName__arrow}
          onClick={onClickModalClose}
        >
          <ArrowBackIcon color="inherit" />
        </IconButton>

        <Typography noWrap className={classes.fileName__name}>
          {moment.unix(selectedVersion.created_at).format('L LT')}
        </Typography>

        {!!selectedVersion.diff &&
          <Loader
            surface
            loading={versionIsFetching}
            render={
              () => (
                <Button
                  disabled={versionIsFetching}
                  variant="contained"
                  color="primary"
                  onClick={handleChangeVersion}
                >
                  Restore this version
                </Button>
              )
            }
          />
        }
      </div>

      <Fab
        size="small"
        title={sidebarIsOpen ? 'Hide versions sidebar' : 'Show versions sidebar'}
        className={classes.sidebarToggleButton}
        onClick={toggleSidebarIsOpen}
      >
        {sidebarIsOpen ?
          <FastForwardIcon fontSize="small" />
          :
          <FastRewindIcon fontSize="small" />
        }
      </Fab>
    </AppBar>
  );
};
