import { fetchDataForAsyncSelect } from '../../../../helpers/fetchDataForAsyncSelect';
import * as tagsApi from '../../../../api/files/tags';
import { MultiValue } from './MultiValue';
import { Select } from '../Select';
import { Option } from './Option';

const overridesComponents = {
  MultiValue,
  Option
};

export const transformTagToOption = (tag) => tag && ({
  value: tag?.id,
  label: tag,
  data: tag
});

const fetchTags = (params) => fetchDataForAsyncSelect((search, { page }) => {
  return tagsApi.fetchTags({ params: { search, page, ...params } });
}, {
  transformDataToOption: transformTagToOption
});

export const TagsSelect = ({ params = {}, components, ...props }) => {
  return (
    <Select
      isAsync
      name="tag_id"
      label="Tag"
      placeholder="Search tag..."
      components={{ ...overridesComponents, ...components }}
      promiseWithOptions={fetchTags(params)}
      {...props}
    />
  );
};
