export const columnsWidthsLg = {
  name: 180,
  right: 180,
  midline: 180,
  left: 180,
  special_right: 250,
  special_left: 250,
  degrees_normal: 180,
  range_of_motion: 180,
  fraction_of_normal: 180,
  pain: 100,
  cell: 180
};

export const columnsWidthsSm = {
  name: 100,
  right: 80,
  midline: 80,
  left: 80,
  special_right: 250,
  special_left: 250,
  degrees_normal: 60,
  range_of_motion: 60,
  fraction_of_normal: 60,
  pain: 60,
  cell: 80
};
