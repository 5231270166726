import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../ListRow';
import { columnsWidths } from '../List';

export const Header = () => {
  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCell grow flexBasis={columnsWidths.name}>
          Patient
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.medRecNo}>
          MRN
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.phone}>
          Phone
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.email}>
          Email
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.coincidence}>
          Coincidence
        </ListRowCell>
      </ListRow>
    </Paper>
  );
};
