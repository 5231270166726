import { Typography } from '@material-ui/core';
import { CurrencyFormat as MuiCurrencyFormat } from '../../CurrencyFormat';

export const CurrencyFormat = ({ before = null, after = null, fieldLabel }) => {
  if (!before && after) {
    return (
      <>
        Set&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;to&nbsp;
        <MuiCurrencyFormat value={after} TypographyProps={{ variant: 'subtitle1' }} />
      </>
    );
  } else if (before && !after) {
    return (
      <>
        Removed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;
        <MuiCurrencyFormat value={before} TypographyProps={{ variant: 'subtitle1' }} />
      </>
    );
  } else if (before && after && before !== after) {
    return (
      <>
        Changed&nbsp;
        <Typography variant="subtitle1">{fieldLabel}</Typography>
        &nbsp;from&nbsp;
        <MuiCurrencyFormat value={before} TypographyProps={{ variant: 'subtitle1' }} />
        &nbsp;to&nbsp;
        <MuiCurrencyFormat value={after} TypographyProps={{ variant: 'subtitle1' }} />
      </>
    );
  } else {
    return (
      <>Empty&nbsp;<Typography variant="subtitle1">{fieldLabel} option</Typography></>
    );
  }
};
