import { FileRow } from '../../../../files-common/FilesList/FileRow';
import { FileRowMobile } from '../../../../files-common/FilesList/FileRowMobile';

export const Row = ({ isMobile, item: file = {}, isLoaded, ...props }) => {
  return (
    isMobile ?
      <FileRowMobile isLoaded={isLoaded} file={file} {...props} /> :
      <FileRow isLoaded={isLoaded} file={file} {...props} />
  );
};
