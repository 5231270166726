export const initialValues = {
  first_name: null,
  last_name: null,
  email: null,
  is_active: true,
  is_notify: false,
  work: {
    position: null,
    company_id: null,
    office_id: []
  },
  rate: 1
};
