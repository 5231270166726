import { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { api } from '../../../../api';
import * as messagesApi from '../../../../api/emails';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { useModal } from '../../../../components/ModalsProvider';
import { getAggregateTypeFromExtension, getFileNameExtension } from '../../../../helpers/files';
import { usePrevious } from '../../../../helpers/hooks';
import { eventBus, eventBusEvents } from '../../../../utils/eventBus';
import { AccountsContext } from '../AccountsContext';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const MessagesContext = createContext();

export const MessagesProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState));
  const [ isOpenView, setIsOpenView ] = useState(false);
  const { messageItem, isFirstLoaded, messages, selectedMessages, filter, pagination } = state;
  const { openModal } = useModal();
  const { accounts, setCounters, fetchCounters, activeAccountID, setActiveAccountID } = useContext(AccountsContext);
  const { enqueueSnackbar } = useSnackbar();
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});
  const cancelPostFetch = useRef(() => {});
  const intervalRef = useRef();
  const [ isFetchedMedia, setIsFetchedMedia ] = useState(false);
  const [ media, setMedia ] = useState([]);

  const fetchMessages = (newFilter = {}) => {
    cancelFetch.current();
    dispatch({ type: types.FETCH_MESSAGES_REQUEST });

    messagesApi.getMessages({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_MESSAGES_SUCCESS, payload: data });
    });
  };

  const postFetchMessages = () => {
    cancelPostFetch.current();

    messagesApi.getMessages({
      params: { ...filter, page: 1 },
      cancelToken: new api.CancelToken((cancel) => cancelPostFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.POST_FETCH_MESSAGES_SUCCESS, payload: data });
    });
  };

  const toggleMessageFlagged = (uid, flaggedValue) => {
    dispatch({ type: types.TOGGLE_MESSAGE_FLAGGED, payload: uid });

    messagesApi.markMessage({
      folder_id: filter.folder_id,
      message_uid: uid,
      flags: [ flaggedValue ]
    });
  };

  const setMessageSeen = (message) => {
    if (message.flags.seen) return;

    dispatch({ type: types.TOGGLE_MESSAGE_SEEN, payload: message.uid });

    messagesApi.markMessage({
      folder_id: filter.folder_id,
      message_uid: message.uid,
      flags: [ 'seen' ]
    }).then(() => setCounters());
  };

  const fetchMessage = (message) => {
    if (!message?.flags?.seen) {
      setMessageSeen(message);
    }

    dispatch({ type: types.SET_ONE_MESSAGE, payload: message });
  };

  const fetchMedia = (messageItem) => {
    setIsFetchedMedia(false);
    setMedia(messageItem?.attachments || []);

    if (messageItem?.attachments?.length) {
      messagesApi.fetchMessage({
        params: {
          folder_id: filter.folder_id,
          message_uid: messageItem.uid
        }
      }).then((data) => {
        const attachments = data?.attachments?.map((attachment, i) => {
          return {
            ...attachment,

            id: i + 1,
            isAttachment: true,
            aggregate_type: (
              attachment.aggregate_type
              || getAggregateTypeFromExtension(getFileNameExtension(attachment.file_name))
            ),
            original_filename: attachment.original_filename || attachment.file_name,
            extension: attachment.extension || getFileNameExtension(attachment.file_name),
            url: attachment.url_path
          };
        });

        setMedia(attachments);
        setIsFetchedMedia(true);
        fetchMessage({
          ...data,

          attachments
        });
      });
    }
  };

  const applyFilter = (newFilter = {}) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const resetMessages = (newFilter = {}) => {
    dispatch({ type: types.RESET_MESSAGES, payload: newFilter });

    fetchMessages({ page: 1, ...newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchMessages({ page: filter.page + 1 });
    }
  };

  const allItemsIsSelected = () => {
    return !!messages.length && (messages.length === selectedMessages.length);
  };

  const toggleItemSelection = (message) => {
    dispatch({ type: types.TOGGLE_ITEM_SELECTION, payload: message });
  };

  const toggleAllItemsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_ITEMS_SELECTION });
  };

  const deleteMessages = (folderID, IDs) => {
    const unseenMessages = selectedMessages.filter((item) => item.flags.unseen);

    openModal(ConfirmationModal, {
      onModalResolved: () => {
        messagesApi.deleteMessages({ folder_id: folderID, message_uid: IDs }).then(() => {
          dispatch({ type: types.MASS_DELETE_ITEMS, payload: IDs });
          setCounters(unseenMessages?.length);
          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Email is not deleted', { variant: 'error' });
        });
      }
    });
  };

  const deleteMessage = (IDs) => {
    dispatch({ type: types.MASS_DELETE_ITEMS, payload: IDs });
  };

  const toggleEmailView = () => {
    setIsOpenView((state) => !state);
  };

  const providerValue = {
    ...state,

    activeAccountID,
    messages,
    media,
    messageItem,
    selectedMessages,
    isFetchedMedia,
    filter,
    meta: {
      ...pagination,
      ...filter
    },
    isOpenView,

    // functions
    setIsOpenView,
    toggleEmailView,
    fetchMessage,
    deleteMessage,
    toggleMessageFlagged,
    setMessageSeen,
    applyFilter,
    resetMessages,
    loadNextPage,
    allItemsIsSelected,
    toggleItemSelection,
    toggleAllItemsSelection,
    deleteMessages
  };

  useEffect(() => {
    messageItem && fetchMedia(messageItem);
  }, [ messageItem?.uid ]);


  useEffect(() => {
    if (!isFirstLoaded) {
      return;
    }

    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      postFetchMessages();

      accounts.forEach((account) => {
        fetchCounters(account.id);
      });
    }, 60 * 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [ isFirstLoaded, filter, messages, accounts ]);

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetMessages(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    const deleteAccount = (account) => {
      setActiveAccountID((accountID) => {
        const isCurrentAccount = accountID === account.id;

        if (isCurrentAccount) {
          dispatch({ type: types.DELETE_ACCOUNT, payload: account });

          return null;
        }

        return accountID;
      });
    };

    eventBus.on(eventBusEvents.emailAccountDeleted, deleteAccount);

    return () => {
      cancelFetch.current();
      cancelPostFetch.current();
      eventBus.remove(eventBusEvents.emailAccountDeleted, deleteAccount);
    };
  }, []);

  return (
    <MessagesContext.Provider value={providerValue}>
      {children}
    </MessagesContext.Provider>
  );
};
