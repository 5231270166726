import { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { makeStyles, Button, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { tasksPrioritiesOptions } from '../../../../dataMaps';
import { setTasksLastGlobalAction } from '../../../../store/globalActions';
import * as tasksApi from '../../../../api/tasks';
import { caseInitialValues, TaskPreviewContext } from '../../../../components/tasks/TasksProvider';
import {
  TextField,
  transformTaskStatusToOption
} from '../../../../components/FormField';
import * as taskActionTypes from '../../../../components/tasks/TasksProvider/types';
import { PreviewTaskHeader } from '../PreviewTaskModal/PreviewTaskHeader';
import { validationSchema } from '../CreateTaskModal/validationSchema';
import { Header } from './Header';
import { Body } from './Body';
import { RightSidebar } from './RightSidebar';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const EditTaskModal = ({
  handleModalResolve,
  handleModalReject
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const lastUploadedFile = useSelector(({ uploads: { lastUploadedFile } }) => lastUploadedFile);
  const { selectedTask, caseFields, setCaseFields, setSelectedTask } = useContext(TaskPreviewContext);
  const { enqueueSnackbar } = useSnackbar();
  const [ initialData, setInitialData ] = useState(selectedTask);
  const duration = selectedTask.estimated_time && moment.duration(selectedTask.estimated_time, 'seconds');

  useEffect(() => {
    if (initialData.files && lastUploadedFile) {
      setInitialData(({ files, ...state }) => ({
        ...state,
        files: [ lastUploadedFile, ...files ]
      }));
    }
  }, [ lastUploadedFile ]);

  const handleCancel = () => {
    setCaseFields(caseInitialValues);
    handleModalReject();
  };

  const updateTask = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const estimated_time = moment.duration(`${values.hours || 0}:${values.minutes || 0}`).asSeconds();
    values.parent_type = values.parent_id && 'case';
    values.users = [ ...caseFields.users, ...selectedTask.users ]?.map(({ id }) => id);

    return tasksApi.updateTask({
      id: selectedTask.id,
      ...values,
      estimated_time: estimated_time > 0 ? estimated_time : null
    }).then((data) => {
      dispatch(setTasksLastGlobalAction({
        type: taskActionTypes.UPDATE_TASK_IN_LIST,
        payload: { ...data, owner_id: values.owner_id }
      }));

      enqueueSnackbar('Task successfully updated', { variant: 'success' });
      setSelectedTask(data);
      setCaseFields(caseInitialValues);
      handleModalResolve(data);
    }).catch(({ status, data: { errors } = {} }) => {
      if (status === 422) {
        errors && setErrors(errors);
        enqueueSnackbar('Task not updated', { variant: 'error' });
      }
    });
  };

  const resetTaskData = (setFieldValue) => () => {
    setFieldValue('name', selectedTask.name);
    setFieldValue('description', selectedTask.description);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...selectedTask,

        name: selectedTask.name,
        owner_id: selectedTask.owner,
        description: selectedTask.description,
        type_id: selectedTask.type,
        status_id: transformTaskStatusToOption(selectedTask.status),
        priority: tasksPrioritiesOptions.find(({ value }) => value === selectedTask.priority),
        hours: duration?.hours() || 0,
        minutes: duration?.minutes() || 0,
        due_at: initialData.due_at,
        parent_type: (caseFields.parent_type || selectedTask.parent_type) && 'case',
        parent_id: caseFields.parent_id?.id || selectedTask.parent?.id
      }}
      validationSchema={validationSchema}
      onSubmit={updateTask}
    >
      {
        ({ isSubmitting, handleSubmit, setFieldValue, handleBlur }) => (
          <form noValidate onSubmit={handleSubmit}>
            <PreviewTaskHeader
              isUpdateCase
              onRestore={resetTaskData(setFieldValue)}
              onClose={handleCancel}
              onUpdate={setInitialData}
            >
              <TextField
                multiline
                name="name"
                margin="dense"
                placeholder="Enter task name..."
                onBlur={handleBlur}
                InputProps={{
                  className: classes.input,
                  disableUnderline: true
                }}
              />
            </PreviewTaskHeader>

            <Grid container>
              <Grid
                item
                xs={isMobile ? 12 : 9}
                className={cn(classes.root, classes.preview__item)}
              >
                <Header />
                <Body />
              </Grid>

              <Grid
                container
                item
                xs={isMobile ? 12 : 3}
                alignContent="flex-start"
                alignItems="flex-start"
                className={cn(classes.preview__item, classes.preview__tempo)}
              >
                <RightSidebar task={initialData} />
              </Grid>
            </Grid>

            <div className={classes.preview__submit}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Save
              </Button>

              <Button className={classes.preview__cancelBtn} onClick={handleModalReject}>Cancel</Button>
            </div>
          </form>
        )
      }
    </Formik>
  );
};
