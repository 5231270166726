import { useContext, useEffect } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { Loader } from '../../../../../../components/Loader';
import { ScrollbarsLoader } from '../../../../../../components/ScrollbarsLoader';
import { FeedsContext } from '../../../../../../components/feeds/FeedsProvider';
import { styles } from '../styles';
import { Row } from './Row';

const useStyles = makeStyles({
  root: {
    width: '100% !important'
  }
});

export const List = ({ onClose }) => {
  const { feeds, meta, isFetching, isFetched, loadNextPage, resetFeeds } = useContext(FeedsContext);
  const classes = useStyles(styles);

  useEffect(() => {
    resetFeeds();
  }, []);

  return (
    <ScrollbarsLoader
      translateContentSizesToHolder
      className={classes.root}
      total={meta.total}
      rowsCount={feeds.length}
      isFetched={isFetched}
      isFetching={isFetching}
      onNeedNextPage={loadNextPage}
      style={{ maxHeight: 400 }}
    >
      <Loader p={2} fullWidth loading={!isFetched} render={
        () => !meta.total ? (
          <Box p={2}>
            <Typography align="center">No search result</Typography>
          </Box>
        ) : (
          feeds.map((item, index) => (
            <Row
              key={index}
              item={item}
              onClose={onClose}
            />
          ))
        )}
      />
    </ScrollbarsLoader>
  );
};
