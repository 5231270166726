import { isString } from 'lodash';
import * as usersApi from '../../../../api/users';
import { Autocomplete } from '../../Autocomplete';
import { AddOption } from './AddOption';
import { Label } from './Label';

const fetchUsers = (params) => ({ search, loadedOptions = [], additionalData: { page = 0 } }) => {
  return usersApi.fetchUsers({
    params: {
      search,
      page: page + 1,
      is_active: 1,

      ...params
    }
  }).then(({ data, pagination }) => {
    return {
      hasMore: pagination.page < pagination.last_page && pagination.total > 0,
      options: loadedOptions.concat(data?.filter(({ email }) => email)),
      additionalData: {
        page: pagination.page
      }
    };
  });
};

const renderOption = (option) => {
  return !option?.isCreatableOption ? (
    <Label user={option} />
  ) : (
    <AddOption option={option} />
  );
};

export const EmailsSelect = ({
  isAsync = true,
  multiple = false,
  name,
  params = {},

  ...props
}) => {
  return (
    <Autocomplete
      freeSolo
      isAsync={isAsync}
      multiple={multiple}
      name={name}
      label="Email"
      placeholder="Enter email..."
      onNeedFetch={fetchUsers(params)}
      renderOption={renderOption}
      getOptionLabel={(option) => isString(option) ? option : (option?.email || '')}
      getOptionValue={(option) => option?.email || option}
      getOptionSelected={(option, value) => option?.email === (value?.email || value)}

      {...props}
    />
  );
};
