import React from 'react';
import cn from 'classnames';
import { Box, makeStyles, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { AddressLink } from '../../../../../../components/AddressLink';
import { CompanyLink } from '../../../../../../components/CompanyLink';
import { UserLink } from '../../../../../../components/UserLink';
import { Contact } from '../../../../../../components/Contact';
import {
  billingParticipantsTypesMap,
  invoicesTypesMap
} from '../../../../../../components/billing';
import { ApptModel } from '../ApptModel';
import { CaseModel } from '../CaseModel';
import { ClaimModel } from '../ClaimModel';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Header = ({ invoice }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.syncedInfo}>
        <Typography color="textSecondary">Synced with quickbook</Typography>
      </div>

      <div className={classes.rootInfo}>
        <div className={cn(classes.content, classes.leftContent)}>
          <div className={classes.titleContent}>
            <Typography variant="h2">Invoice#</Typography>

            <Box ml={2}>
              <Typography variant="h2">{invoice.number}</Typography>
            </Box>
          </div>

          <div className={cn(classes.titleContent, classes.titleContent_leftSide)}>
            <Typography variant="h5">P.O.#</Typography>

            <Box ml={2}>
              <Typography variant="h5" color="textSecondary">
                {invoice.po || '-'}
              </Typography>
            </Box>
          </div>

          <div className={classes.mainContent}>
            {invoice?.model_type === invoicesTypesMap.case
              ? <CaseModel model={invoice?.model || {}} />
              : invoice?.model_type === invoicesTypesMap.appointment
                ? <ApptModel model={invoice?.model || {}} />
                : invoice?.model_type === invoicesTypesMap.claim
                  ? <ClaimModel model={invoice?.model || {}} />
                  : null
            }
          </div>
        </div>

        <Box className={cn(classes.content, classes.rightContent)}>
          <Typography variant="h3" className={classes.billFromTitle}>Bill From</Typography>

          <Box display="flex" alignItems="center" mb={1}>
            <Box mr={1}>
              <PersonIcon />
            </Box>

            {invoice?.bill_from?.participant_type === billingParticipantsTypesMap.user ? (
              <UserLink
                size="sm"
                user={invoice?.bill_from?.participant}
              />
            ) : (
              <CompanyLink
                size="sm"
                variant="body1"
                company={invoice?.bill_from?.participant}
              />
            )}
          </Box>

          <div className={cn(classes.titleContent, classes.titleContent_contactInfo)}>
            <RoomOutlinedIcon />

            <Box ml={1.5}>
              <AddressLink address={invoice?.bill_from?.participant?.full_address} />
            </Box>
          </div>

          <div className={cn(classes.titleContent, classes.titleContent_contactInfo)}>
            <Contact
              needIcon
              type="tel"
              size="default"
              boxProps={{ mr: 2 }}
              contact={invoice?.bill_from?.participant?.phone}
            />
          </div>

          <div className={cn(classes.titleContent, classes.titleContent_contactInfo)}>
            <Contact
              needIcon
              type="mailto"
              size="default"
              boxProps={{ mr: 2 }}
              contact={invoice?.bill_from?.participant?.email}
            />
          </div>
        </Box>
      </div>
    </div>
  );
};
