import { useState, createContext, useEffect } from 'react';
import { isEqual } from 'lodash';

export const ThreadMessagesFilterContext = createContext(null);

export const ThreadMessagesFilterContextProvider = ({ children, ...props }) => {
  const [ filter, setFilter ] = useState({});

  const providerValue = {
    filter,

    setFilter
  };

  useEffect(() => {
    if (!isEqual(filter, { ...filter, ...props.filter })) {
      setFilter({ ...filter, ...props.filter });
    }
  }, [ props.filter ]);

  return (
    <ThreadMessagesFilterContext.Provider value={providerValue}>
      {children}
    </ThreadMessagesFilterContext.Provider>
  );
};
