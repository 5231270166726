import { createHookReducer } from '../../../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_SEARCH_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_SEARCH_SUCCESS]: (
    { filter, searchResults, ...state },
    { data: newResults, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      searchResults: page === 1 ? newResults : searchResults.concat(newResults)
    };
  },

  [types.RESET_SEARCH]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      searchResults: [],
      filter: { ...filter, ...newFilter, page: 1 }
    };
  }
});
