import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, SvgIcon, Link } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { Icon } from '@mdi/react';
import { mdiCalendarArrowRight } from '@mdi/js';
import { stopPropagation } from '../../../../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../../../../dataMaps/apiModels';
import { AppointmentsFilterContext, AppointmentViewModal } from '../../../../../../../components/appointments';
import { ListRow, ListRowCell } from '../../../../../../../components/ListRow';
import { useModal } from '../../../../../../../components/ModalsProvider';
import { UserPreviewModal } from '../../../../../../../components/users';
import { IconButton } from '../../../../../../../components/IconButton';
import { UserLink } from '../../../../../../../components/UserLink';
import { Contact } from '../../../../../../../components/Contact';
import { Popper } from '../../../../../../../components/Popper';
import { UserSSN } from '../../../../../../../components/users';
import { Date } from '../../../../../../../components/Date';
import { PatientsMenu } from '../../PatientsMenu';
import { columnsWidths } from '../List';

export const Row = ({ user }) => {
  const history = useHistory();
  const { openModal } = useModal();
  const appointmentsFilterContext = useContext(AppointmentsFilterContext);
  const isSelectedPatientForAppointments = !!appointmentsFilterContext?.filter?.patients?.find((id) => id === user.id);

  const getUserPage = (event) => {
    if (!event.target.closest('[href]')) {
      history.push(itemsRoutesByModels.patient(user.id) + '/medical-info/medical-forms');
    }
  };

  const filterAppointments = () => {
    appointmentsFilterContext.changeFilter({ patients: isSelectedPatientForAppointments ? [] : [ user.id ] });
    appointmentsFilterContext.setRelationsForFilter({ patients: isSelectedPatientForAppointments ? [] : [ user ] });
  };

  const openPreview = (id) => () => {
    openModal(UserPreviewModal, {
      payload: { id }
    });
  };

  const openAppointmentPreview = () => {
    if (user.last_visited_appointment_id) {
      openModal(AppointmentViewModal, {
        payload: {
          appointmentID: user.last_visited_appointment_id,
          disableUpdate: true
        }
      });
    }
  };

  return (
    <ListRow
      button
      selected={isSelectedPatientForAppointments}
      onClick={getUserPage}
    >
      <ListRowCell flexBasis={columnsWidths.medRecNo}>
        <Typography noWrap title={user.med_rec}>
          {user.med_rec || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.name}>
        <UserLink
          noWrap
          user={user}
          size="md"
          variant="h5"
          onClick={stopPropagation(openPreview(user.id))}
        >
          <UserSSN
            user={user}
            TypographyProps={{
              variant: 'caption',
              color: 'textSecondary'
            }}
          />
        </UserLink>
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.appointmentDate}>
        {user.last_visited_appointment_id ? (
          <Link onClick={stopPropagation(openAppointmentPreview)}>
            <Date iconFontSize="small" date={user.last_visited_appointment} />
          </Link>
        ) : '-'}
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.phone}>
        <Contact
          noWrap
          color="primary"
          type="tel"
          contact={user.phone || user.mobile_phone_number || user.phone_number}
        />
      </ListRowCell>

      <ListRowCell flexBasis={columnsWidths.email}>
        <Contact
          noWrap
          color="primary"
          type="mailto"
          contact={user.email}
        />
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.typeIns}>
        <Typography noWrap title={user.type_insurance}>
          {user.type_insurance || '-'}
        </Typography>
      </ListRowCell>

      <ListRowCell grow flexBasis={columnsWidths.expirationDay}>
        <Date date={user.expiration_day} />
      </ListRowCell>

      <ListRowCell  justifyContent="flex-end" flexBasis={columnsWidths.actions}>
        <Popper
          placement="left-start"
          anchorRenderer={
            ({ anchorRef, handleToggle }) => (
              <IconButton buttonRef={anchorRef} onClick={stopPropagation(handleToggle)}>
                <MoreVertIcon/>
              </IconButton>
            )
          }
        >
          {({ handleClose }) => (
            <PatientsMenu user={user} onClose={handleClose} />
          )}
        </Popper>

        <IconButton
          color={isSelectedPatientForAppointments ? 'error' : 'info'}
          edge="end"
          title={isSelectedPatientForAppointments ? 'Clear appointments filter' : 'Show patient appointments'}
          onClick={stopPropagation(filterAppointments)}
        >
          {isSelectedPatientForAppointments ? (
            <EventBusyIcon />
          ) : (
            <SvgIcon>
              <Icon path={mdiCalendarArrowRight} />
            </SvgIcon>
          )}
        </IconButton>
      </ListRowCell>
    </ListRow>
  );
};
