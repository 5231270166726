import { createHookReducer } from '../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FILTERS_FETCH_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [types.FILTERS_FETCH_SUCCESS]: (
    { filter, savedFilters, ...state },
    { data, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,

      isFetched: true,
      isFetching: false,
      pagination,
      filter: { ...filter, page, per_page },
      savedFilters: page === 1 ? data : savedFilters.concat(data.filter(({ id }) => {
        return !data.find((savedFilter) => id === savedFilter.id);
      }))
    };
  },

  [types.ADD_FILTER]: ({ pagination, savedFilters, ...state }, savedFilter) => {
    return {
      ...state,

      savedFilters: [ savedFilter, ...savedFilters ],
      pagination: {
        ...pagination,
        total: pagination.total + 1
      }
    };
  },

  [types.DELETE_FILTER]: ({ pagination, filter, savedFilters, ...state }, deletedFilterID) => {
    const total = pagination.total - 1;
    const page = Math.ceil((savedFilters.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      savedFilters: savedFilters.filter((savedFilter) => savedFilter.id !== deletedFilterID)
    };
  },

  [types.UPDATE_FILTER]: ({ savedFilters, ...state }, updatedFilter) => {
    return {
      ...state,

      savedFilters: savedFilters.map((savedFilter) => updatedFilter.id === savedFilter.id ? updatedFilter : savedFilter)
    };
  },

  [types.RESET_FILTERS]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  }
});
