import { useContext, useRef, useState } from 'react';
import moment from 'moment';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import { Box, Button, ButtonGroup, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { orderDirectionsMap } from '../../../../../../components/FormField';
import { IconButton } from '../../../../../../components/IconButton';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import { ScheduleEventsWidgetCalendar } from '../../../../../../components/Widgets/ScheduleEventsWidgetCalendar';
import { useResizeObserver } from '../../../../../../helpers/hooks';
import { ScheduleEventsWidget } from '../../../../../../components/Widgets/ScheduleEventsWidget';
import { WidgetsActionButtons } from '../../../../../../components/WidgetsActionButtons';
import {
  CalendarContext
} from '../../../../../../components/schedule-events/Schedule/CalendarProvider';
import { ScheduleEventsFilterContext } from '../../../../../../components/schedule-events';
import { useModal } from '../../../../../../components/ModalsProvider';
import { ContentCard } from '../../../../../../components/ContentCard';
import { Fab } from '../../../../../../components/Fab';
import {
  ScheduleContext,
  CreateScheduleEventModal
} from '../../../../../../components/schedule-events';

export const periodsMap = {
  upcoming: orderDirectionsMap.asc,
  past: orderDirectionsMap.desc
};

export const Card = ({
  page,
  createScheduleEventModalProps,
  isCreatable = false,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  hideApplyFields,

  ...props
}) => {
  const { openModal } = useModal();
  const rootRef = useRef(null);
  const { width } = useResizeObserver({ ref: rootRef });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const [ period, setPeriod ] = useState(periodsMap.upcoming);
  const calendarContext = useContext(CalendarContext);
  const scheduleContext = useContext(ScheduleContext);
  const { changeFilter } = useContext(ScheduleEventsFilterContext);

  const createEvent = ({ calendarContext, scheduleContext }) => () => {
    openModal(CreateScheduleEventModal, {
      payload: {
        hideApplyFields,

        ...createScheduleEventModalProps,

        initialValues: createScheduleEventModalProps?.initialValues
      },

      onModalResolved: (event) => {
        if (event.recurring_rule) {
          calendarContext.reloadCalendar();
        } else {
          calendarContext.addEvent(event);
        }
        scheduleContext.reloadEvents();
      }
    });
  };

  const applyFilter = ({ newPeriod } = {}) => {
    const fromDate = newPeriod === periodsMap.upcoming
      ? moment().unix()
      : moment().subtract(1, 'year').startOf('year').unix();
    const toDate = newPeriod === periodsMap.upcoming
      ? moment().add(1, 'year').endOf('year').unix()
      : moment().unix();

    changeFilter({
      order_by: 'started_to',
      order_direction: newPeriod || period,
      started_from: fromDate,
      started_to: toDate
    });
  };

  const handlePeriodChange = (newPeriod) => () => {
    if ( period !== newPeriod) {
      setPeriod(newPeriod);
      applyFilter({ newPeriod });
    }
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      ref={rootRef}
      disableContentPaddings
      disableScrollbars
      title="Schedule"
      icon={
        <>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Tooltip title="Add Event">
              <Fab
                color="primary"
                size="small"
                onClick={
                  createEvent({
                    calendarContext,
                    scheduleContext
                  })
                }
              >
                <AddIcon fontSize="small"/>
              </Fab>
            </Tooltip>

            {viewVariant !== viewVariantsMap.calendar && (
              <Box pl={isMobile ? 1 : 2} pr={isMobile ? 0 : 2}>
                <ButtonGroup color="primary" size="small">
                  <Button
                    variant={period === periodsMap.upcoming ? 'contained' : 'outlined'}
                    onClick={handlePeriodChange(periodsMap.upcoming)}
                  >
                    Upcoming
                  </Button>

                  <Button
                    variant={period === periodsMap.past ? 'contained' : 'outlined'}
                    onClick={handlePeriodChange(periodsMap.past)}
                  >
                    Past
                  </Button>
                </ButtonGroup>
              </Box>
            )}
          </Box>
        </>
      }
      leftActions={[
        <DateRangeOutlinedIcon fontSize="small" />
      ]}
      endIcon={
        <>
          {!isMobile &&
            <>
              <Tooltip title="List view">
                <IconButton
                  color={viewVariant === viewVariantsMap.list ? 'primary' : 'white'}
                  onClick={() => setViewVariant(viewVariantsMap.list)}
                >
                  <ViewListIcon/>
                </IconButton>
              </Tooltip>

              <Tooltip title="Calendar view">
                <IconButton
                  color={viewVariant === viewVariantsMap.calendar ? 'primary' : 'white'}
                  onClick={() => setViewVariant(viewVariantsMap.calendar)}
                >
                  <EventNoteOutlinedIcon/>
                </IconButton>
              </Tooltip>
            </>
          }
        </>
      }
      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}

      {...props}
    >
      {viewVariant === viewVariantsMap.calendar && (
        <ScheduleEventsWidgetCalendar width={width} height={isMobile ? height : '100%'}/>
      )}

      {viewVariant === viewVariantsMap.list && (
        <ScheduleEventsWidget
          period={period}
          height={isMobile ? height : '100%'}
          isMobile={isMobile}
          applyFilter={applyFilter}
        />
      )}
    </ContentCard>
  );
};
