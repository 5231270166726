import WebFont from 'webfontloader';
import { createReduxReducer } from '../../../../helpers/createReduxReducer';

const fonts = [ 'Allura', 'Dancing Script', 'Reenie Beanie', 'Parisienne', 'Lovers Quarrel' ];

const LOAD_SIGNATURE_FONTS_REQUEST = 'LOAD_SIGNATURE_FONTS_REQUEST';
const LOAD_SIGNATURE_FONTS_SUCCESS = 'LOAD_SIGNATURE_FONTS_SUCCESS';

export const loadFontsRequest = () => ({ type: LOAD_SIGNATURE_FONTS_REQUEST });
export const loadFontsSuccess = () => ({ type: LOAD_SIGNATURE_FONTS_SUCCESS });
export const loadFonts = () => (dispatch, getState) => {
  const { signatureFonts: { isFetching, isFetched } } = getState();

  if (!isFetched && !isFetching) {
    dispatch(loadFontsRequest());

    WebFont.load({
      classes: false,
      google: {
        families: fonts
      },
      active: () => {
        dispatch(loadFontsSuccess());
      }
    });
  }
};

const initialState = {
  fonts,
  isFetching: false,
  isFetched: false
};

export const reducer = createReduxReducer(initialState, {
  [LOAD_SIGNATURE_FONTS_REQUEST]: (state) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [LOAD_SIGNATURE_FONTS_SUCCESS]: (state) => {
    return {
      ...state,
      isFetching: false,
      isFetched: true
    };
  }
});
