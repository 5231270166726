import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { rolesMap } from '../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../utils/hasRole';
import { TagsContext, TagsProvider } from '../../files-common';
import { Fab } from '../../../../components/Fab';
import {
  Page,
  PageHeader,
  PageHeaderActions,
  PageHeaderTitle,
  PageBody
} from '../../../../components/Page';
import { FiltersBar } from './FiltersBar';
import { List } from './List';

const initialFilter = {
  is_system: 1
};

export const TagsPage = () => {
  return (
    <Box display="flex" height="100%">
      <TagsProvider initialFilter={initialFilter}>
        <Page>
          <PageHeader>
            <PageHeaderTitle>Tags</PageHeaderTitle>

            <PageHeaderActions>
              <TagsContext.Consumer>
                {({ createTag }) => (
                  !hasRole(rolesMap.advocate) && (
                    <Fab
                      variant="extended"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={createTag}
                    >
                      Add tag
                    </Fab>
                  )
                )}
              </TagsContext.Consumer>
            </PageHeaderActions>
          </PageHeader>

          <PageBody disablePaddings disableScrollbars fullHeight>
            <FiltersBar />
            <List />
          </PageBody>
        </Page>
      </TagsProvider>
    </Box>
  );
};
