import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { CasesSelect } from '../../../../../components/cases/CasesSelect';
import { filtersKeysMap, saveFilter } from '../../../../../store/lastFilters';
import { IconComponent } from '../../../../../components/saved-filters';
import { CaseUsersSelect } from '../../../../../components/cases/CaseUsersSelect';
import {
  CompaniesAutocomplete,
  KeyboardDatePicker
} from '../../../../../components/FormField';
import { FiltersBar as CommonFiltersBar } from '../../../../../components/FiltersBar';
import { initialState, StatisticsFilterContext } from '../StatisticsFilterProvider';
import { filterFieldsMap, filterFieldsLabels } from './filterFieldsMap';
import { CardContent } from './CardContent';
import { List } from './List';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const MODAL_WIDTH = 1010;

export const FiltersBar = ({ filterKey, hiddenFields, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobileLg));
  const { filter, applyFilter } = useContext(StatisticsFilterContext);
  const lastFilters = useSelector(({ lastFilters }) => lastFilters[filterKey]);
  const [ relationsForFilter, setRelationsForFilter ] = useState({});

  const handleCompanyChange = (company) => {
    applyFilter({ company_id: company?.id });
    setRelationsForFilter((state) => ({ ...state, company_id: company || null }));
  };

  const handleCaseChange = (caseItem) => {
    applyFilter({ case_id: caseItem?.id });
    setRelationsForFilter((state) => ({ ...state, case_id: caseItem || null }));
  };

  const handleUsersSelectChange = (users) => {
    applyFilter({ users_id: users?.map(({ id }) => id) });
    setRelationsForFilter((state) => ({ ...state, users_id: users }));
  };

  const handleDatePickerChange = (name) => (date) => {
    applyFilter({ [name]: date || initialState.filter[name] });
    setRelationsForFilter((state) => ({ ...state, [name]: date }));
  };

  const applySavedFilter = (filter) => {
    formikRef.current.setValues(filter);
  };

  useEffect(() => {
    if (lastFilters) {
      formikRef.current.setValues(lastFilters);
    }
  }, []);

  useEffect(() => {
    dispatch(saveFilter({
      key: filtersKeysMap.billing_dashboard_main,
      filter: relationsForFilter
    }));
  }, [ relationsForFilter ]);

  return (
    <CommonFiltersBar
      isResetForm
      enableSettings
      disableTopPaddings
      formikRef={formikRef}
      className={classes.appBar}
      ToolbarProps={{
        className: classes.toolBar
      }}
      color="transparent"
      initialValues={filter}
      filterKey={filterKey}
      iconComponent={
        <IconComponent
          modalWidth={MODAL_WIDTH}
          filterKey={filterKey}
          hiddenFields={hiddenFields}
          ListComponent={List}
          CardContent={CardContent}
          relationsForFilter={relationsForFilter}
          onApplySavedFilter={applySavedFilter}
        />
      }
      fieldsList={[
        {
          fieldKey: filterFieldsMap.usersId,
          label: filterFieldsLabels[filterFieldsMap.usersId],
          field: <Box minWidth={isMobile ? '100%' : 180}>
            <CaseUsersSelect
              multiple
              name="users_id"
              label="Users"
              onChange={handleUsersSelectChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.caseId,
          label: filterFieldsLabels[filterFieldsMap.caseId],
          field: <Box minWidth={isMobile ? '100%' : 160}>
            <CasesSelect
              name="case_id"
              label="All cases"
              placeholder="Search case by name..."
              onChange={handleCaseChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.companyId,
          label: filterFieldsLabels[filterFieldsMap.companyId],
          field: <Box minWidth={isMobile ? '100%' : 210}>
            <CompaniesAutocomplete
              name="company_id"
              label="Company"
              placeholder="Search company..."
              onChange={handleCompanyChange}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.from,
          label: filterFieldsLabels[filterFieldsMap.from],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              name="from"
              label="From"
              onChange={handleDatePickerChange('from')}
            />
          </Box>
        },
        {
          fieldKey: filterFieldsMap.to,
          label: filterFieldsLabels[filterFieldsMap.to],
          field: <Box minWidth={140}>
            <KeyboardDatePicker
              clearable
              name="to"
              label="To"
              onChange={handleDatePickerChange('to')}
            />
          </Box>
        }
      ]}

      {...props}
    />
  );
};
