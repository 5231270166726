import { useContext, useEffect, useRef } from 'react';
import { Box, List as MuiList, Typography } from '@material-ui/core';
import { CustomScrollbars } from '../../../../../components/CustomScrollbars';
import { InfiniteListLoader } from '../../../../../components/InfiniteLoader';
import { Loader } from '../../../../../components/Loader';
import { Sticky } from '../../../../../components/Sticky';
import { CategoriesContext } from '../CategoriesProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const minRowHeight = 54;
export const columnsWidths = {
  name: 200,
  case: 200,
  company: 200,
  isActive: 100,
  time: 200,
  rate: 150,
  actions: 120
};

export const List = () => {
  const scrollElementRef = useRef();
  const {
    categories,
    meta,
    isFetching,
    isFetched,
    resetCategories,
    loadNextPage
  } = useContext(CategoriesContext);

  useEffect(() => {
    resetCategories();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={2}>
        <Loader loading={!isFetched} render={
          () => !categories.length ? (
            <Typography align="center">No categories found</Typography>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader/>
              </Sticky>

              <MuiList disablePadding>
                <InfiniteListLoader
                  items={categories}
                  isFetching={isFetching}
                  isFetched={isFetched}
                  pagination={meta}
                  RowComponent={Row}
                  minRowHeight={minRowHeight}
                  scrollElementRef={scrollElementRef}
                  onNeedNextPage={loadNextPage}
                />
              </MuiList>
            </>
          )}
        />
      </Box>
    </CustomScrollbars>
  );
};
