import { Grid, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../../../../helpers/dom';
import { ReadonlyCheckbox } from '../../../../../ReadonlyCheckbox';
import { UserLink } from '../../../../../UserLink';
import { Contact } from '../../../../../Contact';
import { CardItem } from '../../../../../Cards';
import { UserSSN } from '../../../../../users';

export const Card = ({ item: user = {}, onUserSelect = () => {} }) => {
  const handleUserClick = () => {
    onUserSelect(user);
  };

  return (
    <CardItem py={1} px={2} onClick={stopPropagation(handleUserClick)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UserLink
            user={user}
            size="md"
            variant="h5"
          >
            <UserSSN
              user={user}
              TypographyProps={{
                variant: 'caption',
                color: 'textSecondary'
              }}
            />
          </UserLink>
        </Grid>

        <Grid item xs={4}>
          <Typography>MRN</Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography noWrap title={user.med_rec}>
            {user.medical?.med_rec || '-'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography>Phone</Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            noWrap
            color="primary"
            type="tel"
            contact={user.phone || user.mobile_phone_number || user.phone_number}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>Email</Typography>
        </Grid>

        <Grid item xs={8}>
          <Contact
            noWrap
            color="primary"
            type="mailto"
            contact={user.email}
          />
        </Grid>

        <Grid item xs={12} container spacing={1} wrap="wrap">
          <Grid item>
            <ReadonlyCheckbox title="Name" checked={user.check_fields?.name} />
          </Grid>

          <Grid item>
            <ReadonlyCheckbox title="Email" checked={user.check_fields?.email} />
          </Grid>

          <Grid item>
            <ReadonlyCheckbox title="Birthday" checked={user.check_fields?.birthday} />
          </Grid>

          <Grid item>
            <ReadonlyCheckbox title="Phone" checked={user.check_fields?.phone} />
          </Grid>

          <Grid item>
            <ReadonlyCheckbox title="SSN" checked={user.check_fields?.ssn} />
          </Grid>
        </Grid>
      </Grid>
    </CardItem>
  );
};
