import { useContext, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { TablePagination } from '../../../../../../../components/TablePagination';
import { Loader } from '../../../../../../../components/Loader';
import { PageBody } from '../../../../../../../components/Page';
import { Cards } from '../../../../../../../components/Cards';
import { CodesContext } from '../../CodesContext';
import { Card } from './Card';

export const Grid = ({ baseUrl }) => {
  const {
    codes,
    isFetched,
    filter,
    pagination: { total },
    resetCodes
  } = useContext(CodesContext);

  useEffect(() => {
    resetCodes();
  }, []);

  return (
    <PageBody fullHeight>
      <Loader
        p={2}
        loading={!isFetched}
        render={() =>
          !codes.length ? (
            <Typography align="center">
              No codes found
            </Typography>
          ) : (
            <>
              <Cards
                size={300}
                list={codes}
                component={Card}
                cardProps={{ baseUrl }}
              />

              <TablePagination pagination={{ ...filter, total }} onChange={resetCodes} />
            </>
          )
        }
      />
    </PageBody>
  );
};
