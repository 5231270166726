export const orderByMap = {
  updated_at: 'updated_at',
  created_at: 'created_at'
};

export const orderByLabelsMap = {
  created_at: 'Creation date',
  updated_at: 'Updated date'
};

export const orderByOptions = [
  { label: orderByLabelsMap.updated_at, value: orderByMap.updated_at },
  { label: orderByLabelsMap.created_at, value: orderByMap.created_at }
];
