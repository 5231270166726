import { Box, Tooltip, Typography } from '@material-ui/core';
import { caseUserRolesTypesMap } from '../../../../dataMaps';
import { stopPropagation } from '../../../../helpers/dom';
import { getUserFullName } from '../../../../helpers/users';
import { Fab } from '../../../Fab';
import { Popper } from '../../../Popper';
import { UserAvatar } from '../../../users';

export const UserCard = ({
  user,
  space,
  MenuComponent,
  menuProps
}) => {
  const userInfo = user.user || user;

  return !!userInfo?.id && (
    <Popper
      placement="bottom"
      key={user.id}
      arrow={false}
      anchorRenderer={
        ({ anchorRef, handleToggle }) => (
          <Tooltip
            arrow
            title={
              <Box textAlign="center" minWidth={100}>
                <Typography variant="h5" color="inherit">
                  {getUserFullName(userInfo)}
                </Typography>

                <Typography variant="body2">
                  {user?.case_role && caseUserRolesTypesMap[user.case_role]}
                </Typography>
              </Box>
            }
          >
            <Fab
              size="small"
              buttonRef={anchorRef}
              onClick={stopPropagation(handleToggle)}
            >
              <UserAvatar
                size={space > 0 ? 'lg' : 'md'}
                user={userInfo}
              />
            </Fab>
          </Tooltip>
        )
      }
    >
      {({ handleClose }) => (
        <MenuComponent
          user={userInfo}
          onClose={handleClose}
          {...menuProps}
        />
      )}
    </Popper>
  );
};
