import { Grid, Typography } from '@material-ui/core';
import { CardItem } from '../../../../../../../../../components/Cards';
import { CurrencyFormat } from '../../../../../../../../../components/CurrencyFormat';
import { ServiceLines } from './ServiceLines';

export const Card = ({ appointment = {}, procedure }) => {
  return (
    <>
      <CardItem p={1}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography noWrap variant="h5">CPT</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {procedure.cpt?.procedure_code}
            </Typography>

            <Typography color="textSecondary">
              {procedure.cpt?.description}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography noWrap variant="h5">POS</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography>
              {procedure.pos?.code}
            </Typography>

            <Typography color="textSecondary">
              {procedure.pos?.description}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography noWrap variant="h5">DX Sequence</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {procedure.icd?.length || '-'}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography noWrap variant="h5">Modifiers</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {procedure.modifiers?.length || '-'}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography noWrap variant="h5">Unit</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              {procedure.unit || '-'}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography noWrap variant="h5">Price</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography noWrap>
              <CurrencyFormat value={procedure.price} />
            </Typography>
          </Grid>
        </Grid>
      </CardItem>

      <ServiceLines appointment={appointment} serviceLines={procedure.service_line} />
    </>
  );
};
