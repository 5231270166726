import moment from 'moment';
import { RRule } from 'rrule';
import { Formik } from 'formik';
import {
  makeStyles,
  Dialog,
  Button,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  FormGroup
} from '@material-ui/core';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../Modal';
import { TextField } from '../../FormField';
import { Weekdays } from './Weekdays';
import { Ends } from './Ends';
import { frequencyOptions } from './frequencyOptions';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const initialValues = {
  interval: 1,
  freq: RRule.WEEKLY,
  ends: 'never',
  until: moment().add(3, 'months').unix(),
  count: 2,
  byweekday: [ RRule[moment().format('dd').toUpperCase()] ]
};

export const CustomRecurrenceModal = ({ DialogProps, handleModalResolve, handleModalReject }) => {
  const classes = useStyles();

  const handleSubmit = ({ ends, until, count, byweekday, ...values }) => {
    const rruleOptions = { ...values };

    if (values.freq === RRule.WEEKLY) {
      rruleOptions.byweekday = byweekday;
    }

    if (ends === 'until') {
      rruleOptions.until = moment.unix(until).toDate();
    } else if (ends ===  'count') {
      rruleOptions.count = count;
    }

    const rrule = new RRule(rruleOptions);
    const label = rrule.toText();

    handleModalResolve({
      label: label[0].toUpperCase() + label.substr(1),
      value: rrule.toString()
    });
  };

  return (
    <Dialog {...DialogProps} maxWidth="xs">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, handleChange, handleSubmit }) => (
          <ModalContainer component="form" onSubmit={handleSubmit}>
            <ModalHeader>
              Custom recurrence
            </ModalHeader>

            <ModalBody>
              <FormControl fullWidth component="fieldset" margin="dense">
                <FormLabel component="legend">Repeat every</FormLabel>

                <FormControl fullWidth margin="dense">
                  <FormGroup row className={classes.intervalGroup}>
                    <TextField name="interval" className={classes.intervalField} />

                    <Select fullWidth name="freq" value={values.freq} onChange={handleChange}>
                      {frequencyOptions.map(({ label, value }) => (
                        <MenuItem key={value} value={value}>{label}</MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                </FormControl>
              </FormControl>

              {values.freq === RRule.WEEKLY && <Weekdays />}

              <Ends />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Done
              </Button>
            </ModalFooter>
          </ModalContainer>
        )}
      </Formik>
    </Dialog>
  );
};
