import { useEffect } from 'react';
import moment from 'moment';
import { FieldArray, useFormikContext } from 'formik';
import { makeStyles, Typography, Collapse } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import NotesIcon from '@material-ui/icons/Notes';
import AddIcon from '@material-ui/icons/Add';
import { ConfirmationModal } from '../../../../../../../../../components/ConfirmationModal';
import { Fab } from '../../../../../../../../../components/Fab';
import { DatePicker, TextField } from '../../../../../../../../../components/FormField';
import { IconButton } from '../../../../../../../../../components/IconButton';
import { useModal } from '../../../../../../../../../components/ModalsProvider';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const NotesContent = () => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { values, setFieldValue } = useFormikContext();

  const handleClearField = (helpers, index) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        helpers.remove(index);
      }
    });
  };

  useEffect(() => {
    if (!!values.notes && values.notes.length > 0) {
      values.notes.forEach((item, i) => {
        !values.notes[i].date && setFieldValue(`notes.${i}.date`, moment().unix());
      });
    }
  }, [ values.notes ]);

  return (
    <FieldArray
      name="notes"
      render={(arrayHelpers) => (
        <div className={classes.root}>
          <Collapse
            unmountOnExit
            timeout="auto"
            in={values.notes && !!values.notes.length}
            className={classes.collapse}
          >
            {values.notes && !!values.notes.length &&
              <Typography variant="h3">Notes</Typography>
            }

            {values.notes && !!values.notes.length && (
              values.notes.map((note, index) => (
                <div key={index} className={classes.notesContent}>
                  <DatePicker
                    disabled
                    label="Date"
                    name={`notes.${index}.date`}
                    minDate={moment().startOf('day').unix()}
                    maxDate={moment().endOf('day').unix()}
                    margin="dense"
                    InputProps={{ endAdornment: false }}
                    className={classes.notesContentDatepicker}
                  />

                  <div className={classes.notesContentInfo}>
                    <TextField
                      fullWidth
                      multiline
                      isEditable
                      margin="dense"
                      name={`notes.${index}.description`}
                      label={
                        <div className={classes.noteIcon}>
                          <NotesIcon color="primary" fontSize="small"/>

                          <Typography color="primary">Add Note</Typography>
                        </div>
                      }
                    />

                    <IconButton
                      size="small"
                      color="error"
                      onClick={handleClearField(arrayHelpers, index)}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </div>
                </div>
              ))
            )}
          </Collapse>

          <Fab
            size="small"
            color="primary"
            variant="extended"
            startIcon={<AddIcon />}
            onClick={() => arrayHelpers.push({ date: '', description: '' })}
            className={classes.actionButton}
          >
            Add note
          </Fab>
        </div>
      )}
    />
  );
};
