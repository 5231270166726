import moment from 'moment';

export const initialState = {
  isFetched: false,
  isFetching: false,
  filter: {
    from: moment().utc().startOf('month').unix(),
    to: moment().utc().endOf('month').unix(),
    period: 'month'
  },
  statistics: []
};
