import { Paper } from '@material-ui/core';
import { ListRow, ListRowCell } from '../../../../../../../../../components/ListRow';
import { columnsMap, columnsWidths } from '../SavedFiltersList';

export const TableHeader = ({ hiddenColumns }) => {
  return (
    <Paper elevation={0}>
      <ListRow header px={0}>
        <ListRowCell flexBasis={columnsWidths.startedAfter}>
          Started After
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.startedBefore}>
          Started Before
        </ListRowCell>

        {!hiddenColumns.includes(columnsMap.cases) &&
          <ListRowCell flexBasis={columnsWidths.cases}>
            Cases
          </ListRowCell>
        }

        <ListRowCell flexBasis={columnsWidths.tasks}>
          Tasks
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.scheduleEvents}>
          Schedule Events
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.actions} />
      </ListRow>
    </Paper>
  );
};
