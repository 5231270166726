import { useFormikContext } from 'formik';
import { Grid, Typography, Box } from '@material-ui/core';
import { ReadonlyCheckbox } from '../../../ReadonlyCheckbox';

export const Venue = () => {
  const { values } = useFormikContext();
  const venue = values?.work?.office?.venue?.find((item) => item.is_main);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box pt={2}>
          <Typography variant="h6">Venue</Typography>
        </Box>
      </Grid>

      <Grid item sm={3} xs={12}>
        <Typography color="textSecondary">Address</Typography>
      </Grid>

      <Grid item sm={9} xs={12}>
        {(values?.work?.office?.venue_full_address || venue?.full_address) ? (
          <ReadonlyCheckbox
            checked
            fullWidth
            noWrap
            iconColor="success"
            position="flex-start"
            title={values?.work?.office?.venue_full_address || venue?.full_address}
          />
        ) : (
          <Typography color="textSecondary">No main venue</Typography>
        )}
      </Grid>
    </Grid>
  );
};
