export const styles = ({ spacing }) => ({
  autoSizer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    width: '100% !important'
  },

  loader: {
    padding: spacing(2)
  },

  contentMessage: {
    padding: spacing(3)
  }
});
