import { Autocomplete } from '../../Autocomplete';
import { payPeriodsLabelsMap, payPeriodsOptions } from './payPeriodsMap';

export const transformPayPeriodToOption = (payPeriod) => payPeriod && ({
  value: payPeriod,
  label: payPeriodsLabelsMap[payPeriod]
});

export const PayPeriodsSelect = (props) => {
  return (
    <Autocomplete
      label="Pay period"
      placeholder="Search pay period..."
      options={payPeriodsOptions}

      {...props}
    />
  );
};
