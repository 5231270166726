import { useContext, useEffect, useRef } from 'react';
import {
  List as MuiList,
  Paper,
  Typography,
  Box
} from '@material-ui/core';
import { CaseReminderEventsContext } from '../../../../../../components/cases/reminders/events';
import { CustomScrollbars } from '../../../../../../components/CustomScrollbars';
import { Loader } from '../../../../../../components/Loader';
import { InfiniteListLoader } from '../../../../../../components/InfiniteLoader';
import { Sticky } from '../../../../../../components/Sticky';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidths = {
  eventNumber: 80,
  type: 150,
  group: 220,
  description: 200,
  interval: 150,
  relative: 200,
  actions: 80
};

export const List = () => {
  const {
    reminderEvents,
    isFetching,
    isFetched,
    loadNextPage,
    resetReminderEvents,
    filter: { per_page, last_page, page }
  } = useContext(CaseReminderEventsContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetReminderEvents();
  }, []);

  return (
    <CustomScrollbars scrollerRef={scrollElementRef} style={{ height: '100%' }}>
      <Box p={!isFetched ? 2 : 0}>
        <Loader loading={!isFetched} render={
          () => (!reminderEvents.length ? (
            <Box p={3}>
              <Typography align="center">No events found</Typography>
            </Box>
          ) : (
            <>
              <Paper elevation={0}>
                <Sticky containerElement={scrollElementRef.current}>
                  <TableHeader />
                </Sticky>

                <MuiList disablePadding>
                  <InfiniteListLoader
                    items={reminderEvents}
                    isFetching={isFetching}
                    isFetched={isFetched}
                    pagination={{ per_page, last_page, page }}
                    RowComponent={Row}
                    onNeedNextPage={loadNextPage}
                    scrollElementRef={scrollElementRef}
                  />
                </MuiList>
              </Paper>
            </>
          ))}
        />
      </Box>
    </CustomScrollbars>
  );
};
