import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Formik } from 'formik';
import { makeStyles, Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import * as waitingListApi from '../../../../../../api/appointments';
import { hasRole } from '../../../../../../utils/hasRole';
import { ActionButtons, FormsCalendarInfo } from '../../../../../../components/medical/forms';
import { AppointmentCreateModal } from '../../../../../../components/appointments';
import { useModal } from '../../../../../../components/ModalsProvider';
import { Divider } from '../../../../../../components/Divider';
import { Loader } from '../../../../../../components/Loader';
import { Fab } from '../../../../../../components/Fab';
import { rolesMap } from '../../../../../../dataMaps/rolesMap';
import { MedicalFormItemContext } from '../../MedicalFormItemProvider';
import { ApptList } from '../ApptList';
import { validationSchema } from './validationSchema';
import { ApptBody } from './ApptBody';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const ApptFormContent = () => {
  const classes = useStyles();
  const params = useParams();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isFetched,
    form,
    fetchForm,
    handleBack,
    handleComplete
  } = useContext(MedicalFormItemContext);
  const theme = useTheme();
  const apptAt = moment.unix(form?.appointment?.appointment_at).format(moment.HTML5_FMT.DATE);
  const apptAtMoment = moment(apptAt, moment.HTML5_FMT.DATE);
  const isBefore = apptAtMoment.isBefore(moment());
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const hasRoleForEdit = hasRole(rolesMap.doctor, rolesMap.medic, rolesMap.admin, rolesMap.supervisor);
  const office = form?.appointment?.appointment_book?.office || form?.office || form?.appointment?.office;

  const addAppointment = () => {
    openModal(AppointmentCreateModal, {
      payload: {
        title: form?.appointment?.id ? 'Edit appointment' : null,
        initialValues: {
          insurance_info: {
            insurance_details: form?.primary_insurance ? {
              ...form?.primary_insurance,
              company: form?.primary_insurance?.insurance_company || null
            } : null,
            patient_insurance_pcp: form?.primary_insurance?.pcp || null,
            patient_insurance_spc: form?.primary_insurance?.spc || null
          },
          form_type: form?.form_type,
          medical_id: form?.medical?.id,
          medical_forms_id: [ form?.id ],
          patient: form?.patient,
          patient_id: form?.patient,
          patient_appointment_details: {
            patient: form?.patient,
            full_address: form.full_address,
            ...form?.patient,
            ...form?.medical
          },
          patient_insurance_id: form?.patient?.medical?.main_insurance
        }
      },
      onModalResolved: () => {
        fetchForm({ id: form.id });
      }
    });
  };

  const handleBackStep = (dirty) => () => {
    handleBack({ dirty, id: params.medicalFormID });
  };

  const handleNextStep = () => {
    handleComplete(form.id);
  };

  const createAppt = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return waitingListApi.createWaitingListItem({ medical_form_id: form?.id, ...values }).then(() => {
      enqueueSnackbar('Appointment successfully created', { variant: 'success' });
      handleComplete(form.id);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Appointment not created', { variant: 'error' });
    });
  };

  const updateAppt = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const data = {
      id: form?.appointment?.id,
      medical_form_id: form?.id,
      patient_insurance_id: values.patient_insurance_id || null,
      appointment_book_id: values.appointment_book_id?.id || values.appointment_book_id,
      office_id: values.office_id,
      appointment_type_id: values.appointment_type_id,
      appointment_time: values.appointment_time,
      time: values.time
    };

    return waitingListApi.updateWaitingListItem(data).then(() => {
      enqueueSnackbar('Appointment successfully updated', { variant: 'success' });
      handleComplete(form.id);
    }).catch(({ errors }) => {
      errors && setErrors(errors);
      enqueueSnackbar('Appointment not updated', { variant: 'error' });
    });
  };

  return (
    <Box height="100%">
      <Loader loading={!isFetched} render={
        () => (
          <Formik
            enableReinitialize
            initialValues={{
              ...form.appointment,

              patient_insurance_id: form?.primary_insurance?.id,
              offices: !!office?.id
                ? [ office ]
                : [],
              appointment_book_id: form.appointment?.appointment_book || null,
              treating_physician_id: (
                form?.treating_physician || form?.medical?.treating_physician
              ),
              appointment_date: form.appointment?.appointment_at
                ? moment.unix(form.appointment?.appointment_at).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
              appointment_time: form?.appointment?.appointment_time || null,
              time: form?.appointment?.time || 15 * 60 // 15 minutes
            }}
            validationSchema={validationSchema}
            onSubmit={form?.appointment?.id ? updateAppt : createAppt}
          >
            {({ handleSubmit }) => (
              <form className={classes.root} noValidate onSubmit={handleSubmit}>
                <div className={classes.mainContent}>
                  <Box px={2} pb={1}>
                    <Alert severity="info">
                      <Typography>
                        {`You have successfully completed ${form?.filling} of 7 steps.`}
                      </Typography>

                      <Typography>
                        Then you <strong>can book the date and time</strong>&nbsp;
                        of the doctor's visit or <strong>save the form at this stage</strong> and
                        go to the main menu for further actions.
                      </Typography>
                    </Alert>
                  </Box>

                  <Box px={2} pb={2}>
                    <Alert severity="warning">
                      <Typography>
                        We do not guarantee that it will be your time and your doctor.
                        We will send you email with the final information
                      </Typography>
                    </Alert>
                  </Box>

                  {!!form.is_confirmed && hasRoleForEdit ? (
                    !!form?.appointment?.id ?
                      <Box px={isSmallScreen ? 1 : 2}>
                        <ApptList isUpdatable={!!form.is_confirmed} initialValues={form} />
                      </Box>
                      :
                      <Box py={1} px={isSmallScreen ? 1 : 2}>
                        {!!form.is_confirmed && (
                          <Fab
                            variant="extended"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={addAppointment}
                          >
                            Add appointment
                          </Fab>
                        )}

                        <Typography align="center">No appointments found</Typography>
                      </Box>
                  ) : hasRole(rolesMap.patient, rolesMap.advocate) ? (
                    <FormsCalendarInfo isBefore={isBefore} />
                  ) : (
                    <ApptBody
                      appointmentDate={
                        form?.appointment?.appointment_at
                          ? moment.unix(form?.appointment?.appointment_at).format('YYYY-MM-DD')
                          : moment().format('YYYY-MM-DD')
                      }
                    />
                  )}
                </div>

                <Divider gutter={2} />

                <ActionButtons
                  onNext={
                    form.is_confirmed && (hasRoleForEdit || (hasRole(rolesMap.patient) && isBefore))
                      ? handleNextStep
                      : undefined
                  }
                  onBack={handleBackStep}
                />
              </form>
            )}
          </Formik>
        )}
      />
    </Box>
  );
};
