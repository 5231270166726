import { Page, PageBody, PageHeader } from '../../Page';
import { AuthorizationsProvider } from './AuthorizationsProvider';
import { Header } from './Header';
import { List } from './List';

export const AuthorizationsPage = ({ userID }) => {
  return (
    <AuthorizationsProvider filter={{ users: [ userID ] }}>
      <Page>
        <PageHeader>
          <Header userID={userID} />
        </PageHeader>

        <PageBody disableScrollbars disablePaddings style={{ flexGrow: 1 }}>
          <List />
        </PageBody>
      </Page>
    </AuthorizationsProvider>
  );
};
