import moment from 'moment';
import { api } from '../../api';

export const transformReport = (data) => {
  if (!data) {
    return data;
  }

  if (data?.appointment?.time) {
    data.appointment.time = moment.duration(+data.appointment.time, 's').asMinutes();
  }

  return data;
};

export const fetchProcedures = (config) => {
  return api.get('/medical-report/procedures', config)
    .then(({ data }) => {
      data.data.forEach(transformReport);

      return data;
    })
    .catch((error) => { throw error.data; });
};

export const fetchMedicalReports = (config) => {
  return api.get('/medical-report', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const fetchMedicalReport = (id, config) => {
  return api.get(`/medical-report/procedures/${id}`, config)
    .then(({ data }) => transformReport(data))
    .catch((error) => { throw error.data; });
};

export const createMedicalReport = (data) => {
  return api.post('/medical-report/procedures', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massCreateMedicalReports = (data) => {
  return api.post('/medical-report/procedures/bulk', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMedicalReport = ({ id, ...data }) => {
  return api.put(`/medical-report/procedures/${id}`, data)
    .then(({ data: { data } }) => transformReport(data))
    .catch((error) => { throw error.data; });
};

export const deleteMedicalReport = (id) => {
  return api.delete(`/medical-report/procedures/${id}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const massDeleteProcedures = (IDs) => {
  return api.delete('/medical-report/procedures/', { params: { ids: IDs } })
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
