import { api } from '../api';

export const fetchMessages = (data = {}) => {
  return api.get('/chat/messages', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const createMessage = (data) => {
  return api.post('/chat/messages', data)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const updateMessage = (message) => {
  return api.put(`/chat/messages/${message.id}`, message)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteMessage = (messageId) => {
  return api.delete(`/chat/messages/${messageId}`)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};

export const deleteSelectedMessages = (config) => {
  return api.delete('/chat/messages/bulk', config)
    .then(({ data }) => data)
    .catch((error) => { throw error.data; });
};
