import { Box, Button, ButtonGroup, SvgIcon, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { useResizeObserver } from '../../../helpers/hooks';
import {
  AppointmentCreateModal,
  AppointmentsFilterContext
} from '../../appointments';
import { ContentCard } from '../../ContentCard';
import { Fab } from '../../Fab';
import { orderDirectionsMap } from '../../FormField';
import { useModal } from '../../ModalsProvider';
import { WidgetsActionButtons } from '../../WidgetsActionButtons';
import { List } from './List';

export const periodsMap = {
  upcoming: orderDirectionsMap.asc,
  past: orderDirectionsMap.desc
};

export const AppointmentsWidget = ({
  isMovable,
  page,
  height = null,
  isDisabledOpen = false,
  onClose,
  onOpen,
  onMinimize,
  onMaximized,
  onPutItem,
  patient,

  ...props
}) => {
  const { openModal } = useModal();
  const rootRef = useRef(null);
  const { changeFilter } = useContext(AppointmentsFilterContext);
  const { width } = useResizeObserver({ ref: rootRef });
  const [ period, setPeriod ] = useState(periodsMap.upcoming);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  const addAppointment = () => {
    openModal(AppointmentCreateModal, {
      payload: {
        initialValues: {
          patient,
          patient_id: patient,
          patient_appointment_details: { patient, ...patient },
          patient_insurance_id: patient?.medical?.main_insurance
        }
      }
    });
  };

  const applyFilter = ({ newPeriod, date } = {}) => {
    const fromDate = date ? date.startOf('day').unix() : null;
    const toDate = date ? date.endOf('day').unix() : null;

    changeFilter({
      order_by: 'appointment_from',
      order_direction: newPeriod || period,
      appointment_from: newPeriod === periodsMap.upcoming ? moment().unix() : fromDate,
      appointment_to: newPeriod === periodsMap.past ? moment().unix() : toDate
    });
  };

  const handlePeriodChange = (newPeriod) => () => {
    if ( period !== newPeriod) {
      setPeriod(newPeriod);
      applyFilter({ newPeriod });
    }
  };

  return (
    <ContentCard
      fullHeight={!props.autoHeightMax}
      isMovable={!props.autoHeightMax}
      ref={rootRef}
      disableContentPaddings
      disableScrollbars
      title="Appt."
      icon={(
        <>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Tooltip title="Add Appointment">
              <Fab
                color="primary"
                size="small"
                onClick={addAppointment}
              >
                <AddIcon fontSize="small"/>
              </Fab>
            </Tooltip>

            <Box pl={isMobile ? 1 : 1} pr={isMobile ? 0 : 0}>
              <ButtonGroup color="primary" size="small">
                <Button
                  variant={period === periodsMap.upcoming ? 'contained' : 'outlined'}
                  onClick={handlePeriodChange(periodsMap.upcoming)}
                >
                  Upcoming
                </Button>

                <Button
                  variant={period === periodsMap.past ? 'contained' : 'outlined'}
                  onClick={handlePeriodChange(periodsMap.past)}
                >
                  Past
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </>
      )}

      leftActions={[
        <SvgIcon fontSize="small">
          <InsertInvitationOutlinedIcon />
        </SvgIcon>
      ]}

      rightActions={[
        <WidgetsActionButtons
          isMinimizable
          page={page}
          type={props?.item?.i}
          isDisabledOpen={isDisabledOpen}
          onMinimize={onMinimize}
          onMaximized={onMaximized}
          onClose={onClose}
          onOpen={onOpen}
          onPutItem={onPutItem}
        />
      ]}

      {...props}
    >
      <List
        width={width}
        period={period}
        applyFilter={applyFilter}
      />
    </ContentCard>
  );
};
