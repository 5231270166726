import { forwardRef, useState, useRef } from 'react';
import moment from 'moment';
import { KeyboardDateTimePicker as MuiKeyboardDateTimePicker } from '@material-ui/pickers';
import { useFormikField } from '../useFormikField';
import { DatePickerBase } from './DatePickerBase';

export const KeyboardDateTimePicker = forwardRef(({
  withoutFormik,
  name,
  outputFormat = 'X',
  format = 'MM/DD/YYYY hh:mm a',
  mask = '__/__/____ __:__ _m',
  onChange = () => {},

  ...props
}, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikFieldProps = withoutFormik ? {} : useFormikField(name);
  const { isFormikField, fieldProps: [ field, , formikHelpers = {} ] = [] } = formikFieldProps;
  const [ value, setValue ] = useState(field?.value ? moment(field?.value, outputFormat) : null);
  const formikValue = useRef(field?.value);

  const handleChange = (date, value = null) => {
    const isValid = !value || moment(value, format, true)?.isValid();
    const formattedDate = outputFormat === 'X' ? date?.startOf('minutes')?.unix() : date?.format(outputFormat);

    if (isFormikField) {
      const newValue = isValid ? formattedDate : value;

      formikValue.current = newValue;
      formikHelpers.setValue(newValue);
    }

    setValue(date);
    onChange(isValid ? formattedDate : null);
  };

  return (
    <DatePickerBase
      withoutFormik={withoutFormik}
      formikValue={formikValue.current}
      Component={MuiKeyboardDateTimePicker}
      name={name}
      outputFormat={outputFormat}
      format={format}
      mask={mask}
      placeholder="MM/DD/YYYY hh:mm"
      value={value}
      onChange={handleChange}

      {...props}

      ref={ref}
    />
  );
});
