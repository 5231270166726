import { useContext, useEffect, useRef } from 'react';
import { List as MuiList, Typography, Box } from '@material-ui/core';
import { Loader } from '../../../../../components/Loader';
import { Scrollbars } from '../../../../../components/Scrollbars';
import { Sticky } from '../../../../../components/Sticky';
import { TypesContext } from '../TypesProvider';
import { TableHeader } from './TableHeader';
import { Row } from './Row';

export const columnsWidths = {
  name: 250,
  createdDate: 180,
  status: 180,
  actions: 120
};

export const List = () => {
  const {
    isFetched,
    types,
    resetTypes
  } = useContext(TypesContext);
  const scrollElementRef = useRef();

  useEffect(() => {
    resetTypes();
  }, []);

  return (
    <Scrollbars viewRef={scrollElementRef}>
      <Box ref={scrollElementRef} height="100%" p={2}>
        <Loader loading={!isFetched} p={2} render={
          () => !types.length ? (
            <Typography align="center">No types found</Typography>
          ) : (
            <>
              <Sticky containerElement={scrollElementRef.current}>
                <TableHeader />
              </Sticky>

              <MuiList disablePadding>
                {types.map((type) => (
                  <Row key={type.id} type={type} />
                ))}
              </MuiList>
            </>
          )}
        />
      </Box>
    </Scrollbars>
  );
};
