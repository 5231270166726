import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import printJS from 'print-js';
import moment from 'moment';
import {
  Box,
  Grid,
  Typography,
  Dialog,
  SvgIcon,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { mdiFileAccountOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { UserLink } from '../../../../../../components/UserLink';
import { getUserFullName } from '../../../../../../helpers/users';
import * as formsApi from '../../../../../../api/profile';
import { ReadonlyCheckbox } from '../../../../../../components/ReadonlyCheckbox';
import { CovidFormModal, FormHeader } from '../../../../../../components/medical/forms';
import { SignatureImage } from '../../../../../../components/UserSignature';
import { useModal } from '../../../../../../components/ModalsProvider';
import { IconButton } from '../../../../../../components/IconButton';
import { Tooltip } from '../../../../../../components/Tooltip';
import { Loader } from '../../../../../../components/Loader';
import { Date } from '../../../../../../components/Date';
import {
  ModalBody,
  ModalHeader,
  ModalContainer,
  ModalHeaderActions
} from '../../../../../../components/Modal';

const actionsMap = {
  save: 'save',
  print: 'print',
  download: 'download'
};

export const CovidPreviewFormModal = ({
  DialogProps,
  handleModalReject,
  payload: { formID, onUpdateForm = () => {} }
}) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [ activeAction, setActiveAction ] = useState(null);
  const [ form, setForm ] = useState(null);
  const staffSignature = form?.signatures?.find(({ type }) => type === 'worker');
  const patientSignature = form?.signatures?.find(({ type }) => type === 'patient');
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const fetchForm = () => {
    return formsApi.fetchMedicalForm(formID).then(setForm);
  };

  const downloadPDF = () => {
    setActiveAction(actionsMap.download);

    formsApi.downloadPdf(formID).then((data) => {
      const blob = new Blob([ data ]);

      saveAs(blob, `${form.form_type}-${form.doi ? moment.unix(form.doi).format('L')  : ''}.pdf`);
    }).finally(() => {
      setActiveAction(null);
    });
  };

  const saveToPatientFiles = () => {
    setActiveAction(actionsMap.save);

    formsApi.savePdfToProfileFiles(formID).then(() => {
      enqueueSnackbar('Form saved to profile files as PDF', { variant: 'success' });
    }).finally(() => {
      setActiveAction(null);
    });
  };

  const printForm = () => {
    setActiveAction(actionsMap.print);

    formsApi.downloadPdf(formID).then((data) => {
      const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/pdf' }));

      printJS({ printable: url, type: 'pdf' });
    }).finally(() => {
      setActiveAction(null);
    });
  };

  const handleEditCovidForm = () => {
    openModal(CovidFormModal, {
      payload: { initialValues: form },
      onModalResolved: () => {
        fetchForm();
        onUpdateForm();
      }
    });
  };

  useEffect(() => {
    fetchForm();
  }, []);

  return (
    <Dialog fullScreen={isTablet} maxWidth="lg" {...DialogProps}>
      <ModalContainer>
        <ModalHeader
          icon={<SvgIcon><Icon path={mdiFileAccountOutline} /></SvgIcon>}
          onClose={handleModalReject}
        >
          COVID-19

          {!!form &&
            <ModalHeaderActions disableGutters>
              {!isTablet && (
                <>
                  <Loader
                    surface
                    loading={activeAction === actionsMap.save}
                    render={() => (
                      <Tooltip title="Save to patient files">
                        <IconButton onClick={saveToPatientFiles}>
                          <SaveOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  />

                  <Loader
                    surface
                    loading={activeAction === actionsMap.print}
                    render={() => (
                      <Tooltip title="Print form">
                        <IconButton onClick={printForm}>
                          <PrintOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  />

                  <Loader
                    surface
                    loading={activeAction === actionsMap.download}
                    render={() => (
                      <Tooltip title="Download form as PDF">
                        <IconButton onClick={downloadPDF}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  />
                </>
              )}

              <Tooltip title="Edit form">
                <IconButton color="primary" onClick={handleEditCovidForm}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </ModalHeaderActions>
          }
        </ModalHeader>

        <Loader loading={!form} p={2} render={
          () => (
            <ModalBody disablePaddings={isTablet}>
              <Box p={isTablet ? 2 : 0} pb={0}>
                <FormHeader
                  form={form}
                  type={form.form_type}
                  activeStep={0}
                />
              </Box>

              <Grid
                container
                component={Box}
                p={3}
                spacing={3}
                alignItems="center"
                width="100% !important"
                mx="0 !important"
              >
                <Grid item xs={12}>
                  <Typography variant="h4">
                    THE SAFETY OF OUR PATIENTS AND STAFF IS OUR PRIORITY DURING THE MONITORIG PHASE OF COVID-19
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" color="secondary">
                    PLEASE COMPLETE QUESTIONS BELOW:
                  </Typography>
                </Grid>

                <Grid item sm={2} xs={4}>
                  <Typography color="textSecondary">User</Typography>
                </Grid>

                <Grid item sm={4} xs={8}>
                  <Typography noWrap variant="h5">
                    {getUserFullName(form?.forms)}
                  </Typography>
                </Grid>

                <Grid item sm={2} xs={4}>
                  <Typography color="textSecondary">DOB</Typography>
                </Grid>

                <Grid item sm={4} xs={8}>
                  {form?.forms?.dob ?
                    <Typography variant="h5">
                      {form?.forms?.dob ? moment(form?.forms?.dob).format('L') : 'No info'}
                    </Typography>
                    :
                    <Typography color="textSecondary">No info</Typography>
                  }
                </Grid>

                <Grid item sm={4} xs={12}>
                  <Typography variant="h5">
                    Do you have a cough?
                  </Typography>
                </Grid>

                <Grid item sm={8} xs={12}>
                  <ReadonlyCheckbox checked={form?.forms?.is_cough === 'yes'} />
                </Grid>

                {form?.forms?.is_cough === 'yes' && (
                  <>
                    <Grid item sm={4} xs={12}>
                      <Typography variant="h5">
                        If yes:
                      </Typography>
                    </Grid>

                    <Grid item sm={8} xs={12}>
                      <ReadonlyCheckbox checked title={form?.forms?.if_yes} />
                    </Grid>
                  </>
                )}

                <Grid item md={4} sm={10} xs={12}>
                  <Typography variant="h5">
                    Do you have difficulty breathing? (without history of asthma)
                  </Typography>
                </Grid>

                <Grid item md={8} sm={2} xs={12}>
                  <ReadonlyCheckbox checked={form?.forms?.is_difficulty_breathing === 'yes'} />
                </Grid>

                <Grid item md={4} sm={10} xs={12}>
                  <Typography variant="h5">
                    Do you have chills, loss of taste or smell
                  </Typography>
                </Grid>

                <Grid item md={8} sm={2} xs={12}>
                  <ReadonlyCheckbox checked={form?.forms?.is_chills === 'yes'} />
                </Grid>

                <Grid item md={4} xs={10}>
                  <Typography variant="h5">
                    Do you have or have had a fever in the past 24 hours?
                  </Typography>
                </Grid>

                <Grid item md={8} sm={2} xs={12}>
                  <ReadonlyCheckbox checked={form?.forms?.is_fever === 'yes'} />
                </Grid>

                <Grid item md={4} xs={10}>
                  <Typography variant="h5">
                    Have you traveled in the past 3 months?
                  </Typography>
                </Grid>

                <Grid item md={8} sm={2} xs={12}>
                  <ReadonlyCheckbox checked={form?.forms?.is_traveled === 'yes'} />
                </Grid>

                {form?.forms?.is_traveled === 'yes' && (
                  <>
                    <Grid item md={4} xs={12}>
                      <Typography variant="h5">
                        If yes, please discribe when and where you traveled to?
                      </Typography>
                    </Grid>

                    <Grid item md={8} xs={12}>
                      {form?.forms?.description ?
                        <Typography>
                          {form?.forms?.description}
                        </Typography>
                        :
                        <Typography color="textSecondary">
                          No info
                        </Typography>
                      }
                    </Grid>
                  </>
                )}

                <Grid item md={4} sm={10} xs={12}>
                  <Typography variant="h5">
                    Have you been to the airport in the past 6 months?
                  </Typography>
                </Grid>

                <Grid item md={8} sm={2} xs={12}>
                  <ReadonlyCheckbox checked={form?.forms?.is_airport === 'yes'} />
                </Grid>

                <Grid item sm={3} xs={12}>
                  <Typography variant="h5">Temperature</Typography>
                </Grid>

                <Grid item sm={9} xs={12}>
                  {form?.forms?.temperature ? (
                    <Typography>{form?.forms?.temperature} &deg;F</Typography>
                  ) : (
                    <Typography color="textSecondary">No info.</Typography>
                  )}
                </Grid>

                <Grid item sm={3} xs={12}>
                  <Typography variant="h5">Date / Time</Typography>
                </Grid>

                <Grid item sm={9} xs={12}>
                  <Date date={form?.forms?.date} format="L, LT" />
                </Grid>

                <Grid item md={2} xs={12}>
                  <Typography variant="h5" color="secondary">Patient Signature:</Typography>
                </Grid>

                <Grid item sm={3} xs={12}>
                  <SignatureImage url={patientSignature?.signature?.electronic_signature_file} />
                </Grid>

                <Grid item md={6} sm={9} xs={12}>
                  <UserLink
                    variant="h5"
                    user={patientSignature?.user}
                  >
                    <Date color="textSecondary" variant="caption" date={patientSignature?.date} />
                  </UserLink>
                </Grid>

                <Grid item md={2} sm={12} xs={12}>
                  <Typography variant="h5" color="secondary">Staff Initials:</Typography>
                </Grid>

                <Grid item sm={3} xs={12}>
                  <SignatureImage url={staffSignature?.signature?.fax_signature_file} />
                </Grid>

                <Grid item md={6} sm={9} xs={12}>
                  <UserLink
                    variant="h5"
                    user={staffSignature?.user}
                  >
                    <Date color="textSecondary" variant="caption" date={staffSignature?.date} />
                  </UserLink>

                </Grid>
              </Grid>
            </ModalBody>
          )}
        />
      </ModalContainer>
    </Dialog>
  );
};
