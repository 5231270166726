import { Box } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const ActivityIndicator = () => {
  return (
    <Box display="flex" alignItems="center">
      <Box color="success.main">
        <FiberManualRecordIcon fontSize="small" color="inherit" />
      </Box>
    </Box>
  );
};
