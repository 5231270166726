import { createContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { merge } from 'lodash';
import { api } from '../../../../../../api';
import * as codesApi from '../../../../../../api/codes/payer';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { setCPTCodesLastGlobalAction } from '../../../../../../store/globalActions';
import { useModal } from '../../../../../../components/ModalsProvider';
import { reducer } from './reducer';
import { initialState } from './initialState';
import * as types from './types';

export const CodesContext = createContext();

export const CodesContextProvider = ({ initialState: initialStateProp = {}, children }) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, initialStateProp));
  const cptCodesLastGlobalAction = useSelector(({ globalActions }) => globalActions.cptCodesLastGlobalAction);
  const dispatchRedux = useDispatch();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isFetched,
    isFetching,
    filter,
    pagination,
    codes,
    selectedCodesIDs
  } = state;
  const cancelFetch = useRef(() => {});

  const fetchCodes = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_CODES_REQUEST });

    codesApi.fetchFinancialClasses({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_CODES_SUCCESS, payload: data });
    });
  };

  const deleteCode = (code) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        codesApi.deleteFinancialClass(code.id).then(() => {
          if (pagination.last_page > filter.page) {
            fetchCodes({ page: filter.page });
          } else if (codes.length - 1 === 0 && filter.page !== 1) {
            fetchCodes({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.DELETE_CODE_SUCCESS, payload: code });
          }
        });
      }
    });
  };

  const allCodesIsSelected = () => {
    return codes.length === selectedCodesIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_CODE_SELECTION, payload: id });
  };

  const toggleAllCodesSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_CODES_SELECTION });
  };

  const massActiveFinancialClass = (IDs) => {
    codesApi.massActiveFinancialClass(IDs).then(() => {
      dispatchRedux(setCPTCodesLastGlobalAction({ type: types.MASS_ACTIVE_CODES, payload: IDs }));
      enqueueSnackbar('Successfully activated', { variant: 'success' });
    });
  };

  const massInactiveFinancialClass = (IDs) => {
    codesApi.massInactiveFinancialClass(IDs).then(() => {
      dispatchRedux(setCPTCodesLastGlobalAction({ type: types.MASS_INACTIVE_CODES, payload: IDs }));
      enqueueSnackbar('Successfully deactivated', { variant: 'success' });
    });
  };

  const massDeleteCodes = (IDs) => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        codesApi.massDeleteFinancialClasses(IDs).then(() => {
          if (pagination.last_page > filter.page && selectedCodesIDs.length < filter.per_page) {
            fetchCodes({ page: filter.page });
          } else if (codes.length - selectedCodesIDs.length === 0 && filter.page !== 1) {
            fetchCodes({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.MASS_DELETE_CODES, payload: IDs });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Classes are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const resetCodes = (newFilter) => {
    dispatch({ type: types.RESET_CODES, payload: newFilter });

    fetchCodes({ page: 1, ...newFilter });
  };

  useEffect(() => {
    cptCodesLastGlobalAction && dispatch(cptCodesLastGlobalAction);
  }, [ cptCodesLastGlobalAction ]);

  const providerValue = {
    codes,
    isFetched,
    isFetching,
    filter,
    pagination,
    selectedCodesIDs,

    // functions
    deleteCode,
    fetchCodes,
    resetCodes,
    massDeleteCodes,
    allCodesIsSelected,
    toggleItemSelection,
    toggleAllCodesSelection,
    massActiveFinancialClass,
    massInactiveFinancialClass
  };

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <CodesContext.Provider value={providerValue}>
      {children}
    </CodesContext.Provider>
  );
};
