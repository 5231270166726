import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { stopPropagation } from '../../../../helpers/dom';
import { itemsRoutesByModels } from '../../../../dataMaps/apiModels';
import { useModal } from '../../../../components/ModalsProvider';
import { setTasksLastGlobalAction } from '../../../../store/globalActions';
import * as taskApi from '../../../../api/tasks';
import { Divider } from '../../../../components/Divider';
import { UserLink } from '../../../../components/UserLink';
import { Loader } from '../../../../components/Loader';
import * as taskActionTypes from '../../../../components/tasks/TasksProvider/types';

export const PreviewTaskMember = ({ user, parent, onUpdate, onClose }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const updateTask = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    const users = values ? parent.users.filter((user) => user.id !== values.user).map(({ id }) => id) : [];

    openModal(ConfirmationModal, {
      onModalResolved: () => {
        return taskApi.updateTask({ id: parent.id, name: parent.name, users }).then((data) => {
          dispatch(setTasksLastGlobalAction({ type: taskActionTypes.UPDATE_TASK_IN_LIST, payload: data }));
          onUpdate && onUpdate(data);
          enqueueSnackbar('User successfully deleted', { variant: 'success' });
          onClose();
        }).catch(({ status, data: { errors } = {} }) => {
          if (status === 422) {
            setErrors(errors);
          }
        });
      }
    });
  };

  return (
    <Box minWidth={300} maxWidth={600} py={0.5}>
      <Box display="flex" justifyContent="flex-end" m={0.5}>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box px={2}>
        <UserLink
          size="xl"
          variant="h6"
          user={user}
          path={itemsRoutesByModels.user(user.id)}
        >
          <Typography>{user.role}</Typography>
        </UserLink>
      </Box>

      <Divider disableBottomGutter gutter={1}/>

      <Box p={2}>
        <Formik
          initialValues={{
            user: user.id
          }}
          onSubmit={updateTask}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form noValidate onSubmit={stopPropagation(handleSubmit)}>
              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Remove from task
                  </Button>
                )}
              />
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
