export const initialState = {
  isFetching: false,
  isFetched: false,
  pagination: {
    total: 0,
    last_page: 1
  },
  filter: {
    page: 1,
    per_page: 30
  },
  accounts: [],
  counters: {},
  error: null,
  activeAccountId: null
};
