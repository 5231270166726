import { matchPath } from 'react-router';

export const getActiveRoute = (routes, exact = false, path = null) => {
  const { pathname } = window.location;

  const route = routes.findLast((route) => {
    const match = matchPath(pathname, { path: route.path, exact });

    return route.exact ? match?.isExact && match : match;
  });

  return route ? route.path : path || routes[0].path;
};
