import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { api } from '../../../../api';
import * as apptsApi from '../../../../api/appointments';
import { initialState as initialStateConfig } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const MedicalHistoryContext = createContext(null);

export const MedicalHistoryProvider = ({ children, userID, initialState = {} }) => {
  const [ state, dispatch ] = useReducer(reducer, { ...initialStateConfig, ...initialState });
  const cancelFetch = useRef(() => {});
  const {
    filter,
    medicalHistory,
    pagination,
    isFetching,
    isFetched
  } = state;
  const [ widgetFilters, setWidgetFilters ] = useState({});

  const fetchMedicalHistory = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_REQUEST });

    apptsApi.fetchMedicalHistory({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_SUCCESS, payload: data });
    });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchMedicalHistory({ page: filter.page + 1 });
    }
  };

  const resetHistory = (filter) => {
    dispatch({ type: types.RESET_HISTORY, payload: filter });

    fetchMedicalHistory({ ...filter, page: 1 });
  };

  const toggleItemSelection = (item) => {
    dispatch({ type: types.TOGGLE_ITEM_SELECTION, payload: item });
  };

  const providerValue = {
    ...state,

    isFetched,
    isFetching,
    filter,
    medicalHistory,
    widgetFilters,
    setWidgetFilters,
    meta: {
      ...filter,
      ...pagination
    },

    toggleItemSelection,
    fetchMedicalHistory,
    resetHistory,
    loadNextPage
  };

  useEffect(() => {
    resetHistory({ patients: [ userID ] });
  }, [ userID ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <MedicalHistoryContext.Provider value={providerValue}>
      {children}
    </MedicalHistoryContext.Provider>
  );
};
