import { useState } from 'react';
import { useFormikContext } from 'formik';
import { useModal } from '../../../../../components/ModalsProvider';
import { getCroppedImg } from '../../../../../components/ImageTransformCropModal/getCroppedImg';
import { UploadCustomerAvatarModal } from '../UploadCustomerAvatarModal';
import { Avatar } from './Avatar';
import { EmptyAvatar } from './EmptyAvatar';

export const AvatarContent = ({
  align = null,
  size,
  onAvatarUrlChange
}) => {
  const { values } = useFormikContext();
  const { openModal } = useModal();
  const [ croppedImageUrl, setCroppedImageUrl ] = useState(values.avatar || null);

  const uploadAvatar = () => {
    openModal(UploadCustomerAvatarModal, {
      onModalResolved: (data) => {
        setCroppedImageUrl(getCroppedImg(data));
        onAvatarUrlChange(getCroppedImg(data));
      }
    });
  };

  return (
    croppedImageUrl ?
      <Avatar
        size={size}
        align={align}
        croppedImageUrl={croppedImageUrl}
        onUploadAvatar={uploadAvatar}
      />
      :
      <EmptyAvatar size={size} onUploadAvatar={uploadAvatar} />
  );
};
